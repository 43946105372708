import store from "../../../../shared/libraries/store";
import { initCmcListPagerType, CmcListFilterType, CmcListPagerType } from "../types";
import { listSources } from "../../../../shared/store";
import { SearchBarFilterType } from "../../../../components/AppGlobal";
import httpClient from '../../../../shared/libraries/httpClient';
import { HttpStatusEnum } from '../../../../shared/enums';
import { handleUnexpectedStatusCode } from '../../../../shared/services';

/** URLS */
const urls = Object.freeze({
  BASE: "/technology/mix/commercial-reference-resume",
});

/** KEYS */
const keys = Object.freeze({
    LISTED: "CMC_LISTED",
    IS_LISTING: "CMC_IS_LISTING",
    PAGE_STATES: "CMC_PAGE_STATE",
});

const getters = {
    /** @returns {CmcListPagerType} */
    getListed: function () {
        return store.getState(keys.LISTED) ?? initCmcListPagerType();
    },

    /** @returns {boolean} */
    getIsListing: function () {
        return store.getState(keys.IS_LISTING) ?? false;
    },

    /** 
     * @returns {{ 
     *   searchBarFilter: SearchBarFilterType, 
     *   filter: CmcListFilterType
     * }}
    */
    getPageState: function () {
        return store.getState(keys.PAGE_STATES);
    },
};


/** MUTATIONS */
const mutations = {
    /**
     * @param {CmcListPagerType} listed
     * @returns {CmcListPagerType}
     */
    setListed: function (listed) {
        return store.commit(keys.LISTED, listed);
    },

    /**
     * @param {boolean} isListing
     * @returns {boolean}
     */
    setIsListing: function (isListing) {
        return store.commit(keys.IS_LISTING, isListing);
    },

    /**
     * @param {CmcListPagerType} listed
     * @returns {CmcListPagerType}
     */
    setListMerge: function (listed) {
        return store.commitListMerge(keys, listed);
    },

    /** @param {{ searchBarFilter: SearchBarFilterType, filter: CmcListFilterType }} filters */
    setPageState: function (filters) {
        return store.commit(keys.PAGE_STATES, filters);
    },

    /** Resetar chaves de estado */
    resetStates: function () {
        return store.resetStates(Object.values(keys));
    }
};

/** ACTIONS */
const actions = {
    /**
     * Listar
     * @param {CmcListFilterType} filter - Filtro de listagem
     * @param {boolean} isAccumulateItems - Incluir mais itens na lista existente
     * @returns {Promise<?CmcListPagerType>} - Retorno paginado
     */
    list: async function (filter, isAccumulateItems = false) {
        return await listSources(filter, isAccumulateItems, mutations, getters, urls.BASE);
    },

    /**
     * Download excel
     * @param {initCmcExcelFilterType} data
     * @returns {Promise<string>}
     */
    downloadExcel: async function (data) {
        const isAsync = 1;
        const res = await httpClient.post(urls.BASE + '/excel/' + isAsync, data);

        switch (res.status) {
            case HttpStatusEnum.HTTP_CREATED: return res.data.data;
            default: handleUnexpectedStatusCode(res);
        }
    },
};

export default {
  actions,
  getters,
  mutations,
};
