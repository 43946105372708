<template>
  <div>
    <modal @close="close" size="md" :show.sync="show">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">{{ title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <div class="row" v-if="show">
            <div class="col-md-12">
              <span>Número: <span class="font-weight-3400">{{ selected.number }}</span></span>
            </div>
            <div class="col-md-12">
              <span>Série: <span class="font-weight-300">{{ selected.series }}</span></span>
            </div>
            <div class="col-md-12">
              <span>Chave: <span class="font-weight-300">{{ selected.key }}</span></span>
            </div>
            <div class="col-md-12 mt-2">
              <label>Motivo
                <span class="text-danger"> * </span>
              </label>
              <validation-provider rules="required|min:15">
                <base-input input-group-classes="input-group-sm">
              <textarea
                v-model="reason"
                rows="5"
                type="text"
                placeholder="Informe o motivo do cancelamento com no mínimo 15 caracteres."
                class="form-control form-control-sm"
              />
                </base-input>
              </validation-provider>
            </div>
          </div>
          <div class="p-3">
            <div class="modal-footer pb-0 pr-0">
              <base-button type="secondary" @click="close()">
                Fechar
              </base-button>
              <base-button :disabled="invalid" :loading="isLoading" type="danger" @click.native="cancel()">
                <span v-show="!isLoading" class="btn-label"><i class="fa-solid fa-ban"></i></span>
                Concluir cancelamento
              </base-button>
            </div>
          </div>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: "ModalCancelNfe",
  props: ['show', 'selected'],
  data() {
    return {
      title: 'Cancelar nota fiscal',
      isLoading: false,
      reason: null,
    }
  },
  methods: {
    close() {
      this.reason = null
      this.$emit('close')
    },
    cancel() {
      this.$Progress.start();
      this.isLoading = true
      this.$Swal.confirmAction('Deseja realmente cancelar a Nota selecionada?', ['Sim', 'Não'])
        .then((result) => {
          if (result.isConfirmed) {
            this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
            this.$store.dispatch('xmlIssuedes/postCancelNfeByKey', {
              reason: this.reason,
              key: this.selected.key
            })
              .then(() => {
                this.isLoading = false
                this.$parent.init()
                this.$notify({type: 'success', message: 'Solicitação de cancelamento enviado com sucesso!'});
                this.close()
              }).catch(() => {
              this.isLoading = false
            })
          }
          else {
            this.isLoading = false
            this.$Progress.finish()
          }
        }).catch(() => {
        this.isLoading = false
        this.$Progress.finish()
      })
    }
  },
};
</script>

<style>
</style>
