import operationSourceStoreActions from "./operationSourceStoreActions";
import operationSourceStoreGetters from "./operationSourceStoreGetters";
import operationSourceStoreMutations from "./operationSourceStoreMutations";
import { operationSourceStoreStateKeys } from "./operationSourceStoreStateKeys";

const operationSourceStore = {
  ...operationSourceStoreActions,
  ...operationSourceStoreGetters,
  ...operationSourceStoreMutations,
  operationSourceStoreStateKeys,
};

export default operationSourceStore;
