<template>
<div>
    <div class="container-fluid">
        <multi-filter ref="multi-filter" @fetch="init" :filter="filter" :status="multi_filter" />
        <div class="row card-wrapper" v-show="loadingSkeleton">
            <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
        </div>

        <PuzlEmptyData v-if="!$_tutorials.length && !loadingSkeleton"></PuzlEmptyData>

        <div class="row card-wrapper" v-if="$_tutorials.length && !loadingSkeleton">
            <div class="col-lg-4" v-for="(tutorial, index) in $_tutorials" :key="index">
                    <card :class="[
                        tutorial.modules_id === module_id['Cadastros'] ? 'card-border-top-light' : '',
                        tutorial.modules_id === module_id['Tecnologia'] ? 'card-border-top-info' : '',
                        tutorial.modules_id === module_id['Comercial'] ? 'card-border-top-warning' : '',
                        tutorial.modules_id === module_id['Operacional'] ? 'card-border-top-success' : '',
                        tutorial.modules_id === module_id['Financeiro'] ? 'card-border-top-danger' : '',
                        tutorial.modules_id === module_id['Cliente'] ? 'card-border-top-dark' : '',
                        tutorial.modules_id === module_id['Equipamento'] ? 'card-border-top-indigo' : '',
                    ]">
                    <!-- Card header -->
                    <div class="row align-items-center mb-3">
                        <div class="col-8" :class="[
                            tutorial.modules_id === module_id['Cadastros'] ? 'text-light' : '',
                            tutorial.modules_id === module_id['Tecnologia'] ? 'text-info' : '',
                            tutorial.modules_id === module_id['Comercial'] ? 'text-warning' : '',
                            tutorial.modules_id === module_id['Operacional'] ? 'text-success' : '',
                            tutorial.modules_id === module_id['Financeiro'] ? 'text-danger' : '',
                            tutorial.modules_id === module_id['Cliente'] ? 'text-dark' : '',
                            tutorial.modules_id === module_id['Equipamento'] ? 'text-indigo' : '',
                        ]">
                           <b> {{ tutorial.module_name }} </b>
                        </div>
                        <div class="col-4 text-right" v-if="$_user.internal_type === 1">
                            <base-dropdown menuOnRight>
                                <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                                    <i class="fas fa-cog"></i>
                                </base-button>
                                 <a class="dropdown-item" @click="handleEdit(tutorial.id)">
                                    <img width="22" src="/img/icons/edit.png">
                                    Editar
                                </a>
                                <div class="dropdown-divider p-0 m-0"></div>
                                <a class="dropdown-item" @click.prevent="handleDelete(tutorial.id)">
                                    <img width="22" src="/img/icons/delete.png">
                                    Excluir
                                </a>
                            </base-dropdown>
                        </div>
                    </div>
                    <!-- Card body -->
                    <div class="mb-2">
                        <b> Tela: </b>  <b class="text-dark">{{tutorial.screen}}</b>
                    </div>
                    <div class="mb-2">
                        <b> Assunto: </b>  <b class="text-dark">{{ tutorial.topic }}</b>
                    </div>
                    <div class="mb-2">
                        <b style="font-size: 11px;"> Descrição: </b>  <b class="text-dark" style="font-size: 11px;">{{ tutorial.description }}</b>
                    </div>

                    <div class="row d-flex justify-content-center">
                        <base-button type="success" @click="handleView(tutorial.id)">
                            <i class=" fa-regular fa-eye"></i>
                            Visualizar
                        </base-button>
                        <a target="_blank" :href="tutorial.video">
                            <base-button type="primary">
                                <i class="fa-solid fa-arrow-up-right-from-square"></i>
                                Nova página
                            </base-button>
                        </a>
                    </div>
                </card>
            </div>
        </div>
        <ModalViewTutorial ref="modelViewTutorial" />
        <ModalEditTutorial ref="ModalEditTutorial"/>
        <LoadingPagination :show="loading && !loadingSkeleton"/>
    </div>
</div>
</template>

<script>
import {mapGetters} from "vuex";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import MultiFilter from "@/components/Utils/MultiFilterV2";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import ModalEditTutorial from './_ModalEdit';
import ModalViewTutorial from './_ModalView';

export default {
    name: "ListTutorial",
    mixins: [cursorPaginate],
    components: {
        PuzlEmptyData,
        SkeletonPuzlGrid,
        MultiFilter,
        LoadingPagination,
        ModalViewTutorial,
        ModalEditTutorial,
    },
    data() {
        return {
            loadingSkeleton: false,
            filter: {},
            multi_filter: {
                null: {
                name: "Todos",
                type: "primary"
                },
                '2': {
                name: "Cadastros",
                type: "light"
                },
                '3': {
                name: "Tecnologia",
                type: "info"
                },
                '4': {
                name: "Comercial",
                type: "warning"
                },
                '5': {
                name: "Operacional",
                type: "success"
                },
                '6': {
                name: "Financeiro",
                type: "danger"
                },
                '7': {
                name: "Cliente",
                type: "dark"
                },
                '8': {
                name: "Equipamento",
                type: "indigo"
                },
            },
            module_id: {
                'Cadastros': 2,
                'Tecnologia': 3,
                'Comercial': 4,
                'Operacional': 5,
                'Financeiro': 6,
                'Cliente': 7,
                'Equipamento': 8,
            },
        };
    },
    computed: {
        ...mapGetters({
            $_tutorials: "tutorial/fetch",
            $_modules: 'module/fetch',
            $_user: 'auth/getUser',
        }),
    },
    mounted() {
        this.$refs['multi-filter']._self.$forceUpdate();
        this.$store.dispatch('module/fetchItems');
        this.init({});
    },
    methods: {
        init(filter = null) {
            this.startCursor(filter)
            this.$Progress.start()
            this.$store
                .dispatch("tutorial/fetchItemsPaginate", {
                    filter: this.filter,
                    next_page: this.paginate.nextUrl
                })
                .then(response => {
                    this.$Progress.finish()
                    this.resolveCursor(response)
                })
                .catch(error => {
                    this.resolveCursor()
                    this.$Progress.finish()
                });
        },
        handleView(id) {
            this.$refs.modelViewTutorial.openModal(id);
        },
        handleEdit(id) {
            this.$refs.ModalEditTutorial.handleEditModal(id)
        },
        handleDelete(id) {
            this.$Swal.confirmDelete().then((result) => {
                if (result.isConfirmed) {
                    this.$Progress.start();
                    this.$notify({
                        type: 'info',
                        message: 'Estamos trabalhando em sua solicitação.'
                    });
                    this.$store.dispatch('tutorial/destroy', id)
                        .then((response) => {
                            this.$notify({
                                type: 'success',
                                message: response.message
                            });
                        })
                        .catch(error => {
                            this.$notify({
                                type: error.data.error_type,
                                message: error.data.message
                            })
                        })
                    this.$Progress.finish();
                }
            }).catch(() => this.$Progress.finish())
        }
    },
};
</script>

<style></style>
