<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">{{ title }}</h5>
      </template>
      <div>
        <div class="p-3" v-if="mixDesign">
          <div class="p-2 border-left border-3 border-primary">
            <div class="row">
              <div class="col-md-12">
                <h5>
                  <badge type="default">V.{{ mixDesign.version }}</badge>
                </h5>
                <span class="h4">{{ mixDesign.date_at | parseDate('DD MMM YYYY') }}</span>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <span class="h5">{{ mixDesign.plant.name }}</span>
              </div>
            </div>

            <div class="mt-3">
              <div
                class="row"
                v-for="feature in mixDesign.features"
                :key="feature.id"
              >
                <div class="col-md-12">
                  <h5 class="h4 mb-0">
                    {{ feature.feature.short_description }}
                    {{ format_mixe(feature.feature.period, feature.feature.period_type) }}
                    {{ feature.description }}
                    {{ feature.letter }}
                  </h5>
                </div>
              </div>

              <div class="mt-2">
                <h5 class="small">
                  {{ mixDesign.type.technical_description }}
                  {{ mixDesign.subtype ? '|' + mixDesign.subtype.technical_description : '' }}
                  |
                  {{ mixDesign.slump_flow.nomenclature }}
                  |
                  {{ mixDesign.maximum_size.description }}
                </h5>
                <h5 class="small">
                  {{ mixDesign.footer}}
                </h5>
              </div>
            </div>
          </div>
<!--          ADIÇÃO PUZL-->
          <div class="mt-4 border-left border-3 border-indigo p-2" v-if="mixDesign.puzl_additions.length">
            <div v-for="(puzl_addtion, index) in mixDesign.puzl_additions"
                 :key="puzl_addtion.id">
              <h5 class="text-indigo">
                {{ puzl_addtion.name }}
                ({{ puzl_addtion.volume }} kg/<span style="text-transform: none">m</span><sup>3</sup>)
                <span class="text-indigo" v-if="index != mixDesign.puzl_additions.length -1">+</span>
              </h5>
            </div>
          </div>
          <div class="mt-2 border-left border-3 border-primary p-2">
            <div class="d-flex justify-content-between">
              <div>
                    <span class="h4">
                      Preço de venda
                    </span>
              </div>
              <div>
                    <span class="h4">
                      {{
                        $helper.toMoney(mixDesign.value)
                      }}
                    </span>
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <div>
                    <span class="h4">
                      Margem
                    </span>
              </div>
              <div>
                    <span class="h4">
                      {{
                        calculateGrossMargin(mixDesign.value, mixDesign.concrete_price.mcc_price, unformatTax(mixDesign.concrete_price.tax_name)) | currency()
                      }}
                    </span>
              </div>
            </div>
          </div>
          <div class="mt-2 border-left border-3 border-danger p-2">
            <div class="d-flex justify-content-between">
              <div>
                    <span class="h4">
                      Preço mínimo de venda
                    </span>
              </div>
              <div>
                    <span class="h4">
                     {{
                        $helper.toMoney(mixDesign.minimum_price)
                      }}
                    </span>
              </div>
            </div>
          </div>
          <div class="mt-2 p-2">
            <div class="d-flex justify-content-between">
              <div>
                    <span class="h4">
                      Retira de terceiros
                    </span>
              </div>
              <div>
                    <span class="h4">
                   {{mixDesign.concrete_removal ? 'SIM' : 'NÃO'}}
                    </span>
              </div>
            </div>
          </div>
          <template v-if="mixDesign.situation">
            <div class="mt-2 p-2">
              <div class="d-flex justify-content-center">
                <div>
                  <span class="h4" :class="'text-'+status[mixDesign.situation.situation].class">
                    {{ status[mixDesign.situation.situation].name }}
                  </span>
                </div>
              </div>
            </div>
            <div class="p-2">
              <div class="d-flex justify-content-center text-center">
                <div>
                  <span class="h5 text-gray text-center">
                  {{ mixDesign.situation.user.name }} <br> {{ mixDesign.situation.created_at | parseDate }}
                </span>
                </div>
              </div>
            </div>
            <div class="p-2">
              <div class="d-flex justify-content-center">
                <div>
                  <span class="text-body">
                    {{ mixDesign.situation.observation }}
                  </span>
                </div>
              </div>
            </div>
          </template>
          <collapse v-if="$hasPermission('additional_permissions.contract-proposal-formulation.details.view')" class="border rounded p-0 mt-3">
            <collapse-item name="1" class="header-gray" back-ground="border-header-success-bottom">
              <h5 slot="title" class="mb-0 ">Parâmetro de preço mínimo</h5>

              <div class="border-left border-3 p-2 border-primary mt-2 d-flex justify-content-between">
                <div>
                  <h5>MCC</h5>
                </div>
                <div>
                 <span class="btn base-button p-1 btn-block btn-outline-primary">
                        {{ $helper.toMoney(mixDesign.concrete_price.mcc_price) }}
                      </span>
                </div>
              </div>

              <div class="border-left border-3 p-2 border-success mt-2">

                <div class="d-flex justify-content-between">
                  <div>
                    <h5>Usinagem</h5>
                  </div>
                  <div>
                    <h5>{{ $helper.toMoney(mixDesign.concrete_price.machining_price) }}</h5>
                  </div>
                </div>
                <div class="d-flex justify-content-between">
                  <div>
                    <h5>{{ mixDesign.concrete_price.transport_name }}</h5>
                  </div>
                  <div>
                    <h5>{{ $helper.toMoney(mixDesign.concrete_price.transport_price) }}</h5>
                  </div>
                </div>

                <div class="d-flex justify-content-between">
                  <div>
                    <h5>{{ mixDesign.concrete_price.cycle_name }}</h5>
                  </div>
                  <div>
                    <h5>{{ $helper.toMoney(mixDesign.concrete_price.cycle_price) }}</h5>
                  </div>
                </div>

                <div class="d-flex justify-content-between">
                  <div>
                    <h5>{{ mixDesign.concrete_price.type_name }}</h5>
                  </div>
                  <div>
                    <h5>{{ $helper.toMoney(mixDesign.concrete_price.type_price) }}</h5>
                  </div>
                </div>

                <div class="d-flex justify-content-between">
                  <div>
                    <h5>{{ mixDesign.concrete_price.tax_name }}</h5>
                  </div>
                  <div>
                    <h5>{{ $helper.toMoney(mixDesign.concrete_price.tax_price) }}</h5>
                  </div>
                </div>
                <div class="d-flex justify-content-between">
                  <div>
                    <h5>&nbsp;</h5>
                  </div>
                  <div>
                      <span class="btn base-button p-1 btn-block btn-outline-success">
                        {{
                          sumMcc(mixDesign) | currency()
                        }}
                      </span>
                  </div>
                </div>

              </div>

              <div class="border-left border-3 p-2 border-warning mt-2"
                   v-if="mixDesign.additional_distance || mixDesign.additional_cycle || mixDesign.additional_concrete_piece || mixDesign.additional_level_responsibility || mixDesign.additional_slump_final">

                <div class="d-flex justify-content-between" v-if="mixDesign.additional_distance">
                  <div>
                    <h5>Distância ({{ mixDesign.additional_distance.diff }} km)</h5>
                  </div>
                  <div>
                    <h5>{{ $helper.toMoney(mixDesign.additional_distance.value) }}</h5>
                  </div>
                </div>

                <div class="d-flex justify-content-between" v-if="mixDesign.additional_cycle">
                  <div>
                    <h5>Ciclo ({{ mixDesign.additional_cycle.diff }} min)</h5>
                  </div>
                  <div>
                    <h5>{{ $helper.toMoney(mixDesign.additional_cycle.value) }}</h5>
                  </div>
                </div>

                <div class="d-flex justify-content-between" v-if="mixDesign.additional_concrete_piece">
                  <div>
                    <h5>Peça a concretar - {{ mixDesign.additional_concrete_piece.name }}
                      ({{ mixDesign.additional_concrete_piece.percentage }} %)</h5>
                  </div>
                  <div>
                    <h5>{{ $helper.toMoney(mixDesign.additional_concrete_piece.value) }}</h5>
                  </div>
                </div>

                <div class="d-flex justify-content-between" v-if="mixDesign.additional_slump_final">
                  <div>
                    <h5>Slump final - {{ mixDesign.additional_slump_final.name }}
                      ({{ mixDesign.additional_slump_final.percentage }} %)</h5>
                  </div>
                  <div>
                    <h5>{{ $helper.toMoney(mixDesign.additional_slump_final.value) }}</h5>
                  </div>
                </div>
                <div class="d-flex justify-content-between" v-if="mixDesign.additional_level_responsibility">
                  <div>
                    <h5>Nível de aplicação - {{ mixDesign.additional_level_responsibility.name }}
                      ({{ mixDesign.additional_level_responsibility.percentage }} %)</h5>
                  </div>
                  <div>
                    <h5>{{ $helper.toMoney(mixDesign.additional_level_responsibility.value) }}</h5>
                  </div>
                </div>

                <div class="d-flex justify-content-between">
                  <div>
                    <h5>&nbsp;</h5>
                  </div>
                  <div>
                      <span class="btn base-button p-1 btn-block btn-outline-warning">
                        {{ $helper.toMoney(calcAdittionals(mixDesign)) }}
                      </span>
                  </div>
                </div>
              </div>
              <div class="border-left border-3 p-2 border-indigo mt-2" v-if="mixDesign.puzl_additions.length">
                <div v-for="item in mixDesign.puzl_additions" :key="item.id">
                  <div class="d-flex justify-content-between">
                    <div>
                      <h5>{{ item.name }} ({{ item.volume }} kg/<span style="text-transform: none">m</span><sup>3</sup>)
<!--                        <small class="text-muted">{{ item.technical_explanation }}</small>-->
                      </h5>
                    </div>
                    <div>
                      <h5>{{ item.log_price | currency() }}</h5>
                    </div>
                  </div>
                  <div v-if="item.percentage" class="d-flex justify-content-between">
                    <div>
                      <h5>Acréscimo ({{item.percentage}} %)</h5>
                    </div>
                    <div>
                      <h5>{{ calcPercentage(item.percentage, item.log_price).toFixed(2) | currency()}}</h5>
                    </div>
                  </div>
                </div>
                <div v-if="mixDesign.puzl_additions.length" class="d-flex justify-content-between">
                  <div>
                    <h5></h5>
                  </div>
                  <div>
                    <span class="btn base-button p-1 btn-block btn-outline-indigo">
                       {{ mixDesign.puzl_addition_price | currency()}}
                      </span>
                  </div>
                </div>

              </div>
            </collapse-item>
          </collapse>

          <collapse v-if="$hasPermission('additional_permissions.contract-proposal-formulation.details.view')" class="border rounded p-0 mt-3" v-show="showMostDetails">
            <collapse-item name="1" class="header-gray" back-ground="border-header-primary-bottom">
              <h5 slot="title" class="mb-0 ">Previsto x Realizado</h5>

              <span class="h5">Previsto</span>
              <div class="border-left border-3 p-1 border-primary mt-2">

                <div class="d-flex justify-content-between">
                  <div>
                    <span class="h5 small">Volume</span>
                    <br/>
                    <span class="h5">{{
                        parseFloat(mixDesign.expected_volume).toLocaleString('pt-BR', {minimumFractionDigits: 1})
                      }} <span style="text-transform: none">m</span><sup>3</sup></span>
                  </div>
                </div>

                <div class="d-flex justify-content-between mt-1">
                  <div>
                    <span class="h5 small">Faturamento</span>
                    <br/>
                    <span class="h5">{{ calcBilling(mixDesign.expected_volume, mixDesign.value) }}</span>
                  </div>
                </div>

                <div class="d-flex justify-content-between mt-1">
                  <div>
                    <span class="h5 small">Vol. Médio por BT</span>
                    <br/>
                    <span class="h5">{{ mixDesign.cycle.average_volume_per_bt }} <span style="text-transform: none">m</span><sup>3</sup></span>
                  </div>
                </div>

                <div class="d-flex justify-content-between mt-1">
                  <div>
                    <span class="h5 small">Ciclo total</span>
                    <br/>
                    <span class="h5">{{ mixDesign.cycle.total_cycle }} minutos</span>
                  </div>
                </div>

                <div class="d-flex justify-content-between mt-1">
                  <div>
                    <span class="h5 small">MCC</span>
                    <br/>
<!--                    mcc + adicao puzl sem acrescimo -->
                    <span class="h5">{{ (parseFloat(mixDesign.concrete_price.mcc_price) + parseFloat(mixDesign.puzl_addition_price_without_addition))  | currency }} /<span style="text-transform: none">m</span><sup>3</sup></span>
                  </div>
                </div>

                <div class="d-flex justify-content-between mt-1">
                  <div>
                    <span class="h5 small">Margem bruta de serviço</span>
                    <br/>
                    <span class="h5">{{ $helper.toMoney(mixDesign.value - (parseFloat(mixDesign.concrete_price.mcc_price) + parseFloat(mixDesign.puzl_addition_price_without_addition))) }} /<span style="text-transform: none">m</span><sup>3</sup></span>
                  </div>
                </div>

                <div class="d-flex justify-content-between mt-1">
                  <div>
                    <span class="h5 small">Faturamento por hora</span>
                    <br/>
                    <span class="h5">{{ calcBillingHour(mixDesign) }} /hr</span>
                  </div>
                </div>

                <div class="d-flex justify-content-between mt-1">
                  <div>
                    <span class="h5 small">Serviço por hora</span>
                    <br/>
                    <span class="h5">{{ calcServiceHour(mixDesign) }} /hr</span>
                  </div>
                </div>

              </div>

              <div class="mt-3"></div>
              <span class="h5">Realizado</span>
              <div class="border-left border-3 p-1 border-success mt-1">

                <div class="row d-flex justify-content-between">
                  <div class="col-11">
                    <span class="h5 small">Volume</span>
                    <br/>
                    <span class="h5">{{ mixDesign.accomplished.volume }} <span style="text-transform: none">m</span><sup>3</sup></span>
                  </div>
                  <div class="col-1 p-0 d-flex justify-content-end">
                    &nbsp;
                  </div>
                </div>

                <div class="row d-flex justify-content-between mt-1">
                  <div class="col-11">
                    <span class="h5 small">Faturamento</span>
                    <br/>
                    <span class="h5">{{ mixDesign.accomplished.billing | currency()}}</span>
                  </div>
                </div>

                <div class="row d-flex justify-content-between mt-1">
                  <div class="col-11">
                    <span class="h5 small">Adicionais</span>
                    <br/>
                    <span class="h5">N/A</span>
                  </div>
                </div>

                <div class="row d-flex justify-content-between mt-1">
                  <div class="col-11">
                    <span class="h5 small">Volume Médio por BT</span>
                    <br/>
                    <span class="h5">{{ mixDesign.accomplished.avg_volume.toFixed(2) }} <span style="text-transform: none">m</span><sup>3</sup></span>
                  </div>
                  <div v-if="mixDesign.accomplished.avg_volume > mixDesign.expected_volume" class="col-1 p-0 d-flex justify-content-end align-items-center">
                    <i class="fa fa-long-arrow-alt-up fa-2x text-success strong"></i>
                  </div>
                  <div v-else class="col-1 p-0 d-flex justify-content-end align-items-center">
                    <i class="fa fa-long-arrow-alt-down fa-2x text-danger strong"></i>
                  </div>
                </div>

                <div class="row d-flex justify-content-between mt-1">
                  <div class="col-11">
                    <span class="h5 small">Ciclo de viagem</span>
                    <br/>
                    <span class="h5">{{ mixDesign.accomplished.total_cycle }} minutos</span>
                  </div>
                  <div v-if="mixDesign.accomplished.total_cycle < mixDesign.cycle.total_cycle" class="col-1 p-0 d-flex justify-content-end align-items-center">
                    <i class="fa fa-long-arrow-alt-down fa-2x text-success strong"></i>
                  </div>
                  <div v-else class="col-1 p-0 d-flex justify-content-end align-items-center">
                    <i class="fa fa-long-arrow-alt-up fa-2x text-danger strong"></i>
                  </div>
                </div>

                <div class="row d-flex justify-content-between mt-1">
                  <div class="col-11">
                    <span class="h5 small">MCC</span>
                    <br/>
                    <span class="h5">{{ (mixDesign.accomplished.avg_mcc)  | currency()}} /<span style="text-transform: none">m</span><sup>3</sup></span>
                  </div>
                  <div v-if="mixDesign.accomplished.avg_mcc < mixDesign.concrete_price.mcc_price" class="col-1 p-0 d-flex justify-content-end align-items-center">
                    <i class="fa fa-long-arrow-alt-down fa-2x text-success strong"></i>
                  </div>
                  <div v-else class="col-1 p-0 d-flex justify-content-end align-items-center">
                    <i class="fa fa-long-arrow-alt-up fa-2x text-danger strong"></i>
                  </div>
                </div>

                <div class="row d-flex justify-content-between mt-1">
                  <div class="col-11">
                    <span class="h5 small">Margem bruta de serviço</span>
                    <br/>
                    <span class="h5">{{ (mixDesign.value - mixDesign.accomplished.avg_mcc) | currency() }} /<span style="text-transform: none">m</span><sup>3</sup></span>
                  </div>
                  <div v-if="(mixDesign.value - mixDesign.accomplished.avg_mcc) > (mixDesign.value - mixDesign.concrete_price.mcc_price)" class="col-1 p-0 d-flex justify-content-end align-items-center">
                    <i class="fa fa-long-arrow-alt-up fa-2x text-success strong"></i>
                  </div>
                  <div v-else class="col-1 p-0 d-flex justify-content-end align-items-center">
                    <i class="fa fa-long-arrow-alt-down fa-2x text-danger strong"></i>
                  </div>
                </div>

                <div class="row d-flex justify-content-between mt-1">
                  <div class="col-11">
                    <span class="h5 small">Faturamento por hora</span>
                    <br/>
                    <span class="h5">{{ (mixDesign.value * mixDesign.accomplished.avg_volume) / (mixDesign.accomplished.total_cycle / 60)
                      | currency() }} /hr</span>
                  </div>
                  <div v-if="(mixDesign.value * mixDesign.accomplished.avg_volume) / mixDesign.accomplished.total_cycle > calcBillingHour(mixDesign, true)" class="col-1 p-0 d-flex justify-content-end align-items-center">
                    <i class="fa fa-long-arrow-alt-up fa-2x text-success strong"></i>
                  </div>
                  <div v-else class="col-1 p-0 d-flex justify-content-end align-items-center">
                    <i class="fa fa-long-arrow-alt-down fa-2x text-danger strong"></i>
                  </div>
                </div>

                <div class="row d-flex justify-content-between mt-1">
                  <div class="col-11">
                    <span class="h5 small">Adicionais por hora</span>
                    <br/>
                    <span class="h5">N/A</span>
                  </div>

                  <div class="col-1 p-0 d-flex justify-content-end align-items-center">
                    &nbsp;
                  </div>
                </div>

                <div class="row d-flex justify-content-between mt-1">
                  <div class="col-11">
                    <span class="h5 small">Serviço por hora</span>
                    <br/>
                    <span class="h5">{{ ((mixDesign.value - mixDesign.accomplished.avg_mcc) * mixDesign.accomplished.avg_volume)
                    / (mixDesign.accomplished.total_cycle / 60) | currency()}} /hr</span>
                  </div>
                  <div v-if="((mixDesign.value - mixDesign.accomplished.avg_mcc) * mixDesign.accomplished.avg_volume)
                    / mixDesign.accomplished.total_cycle > calcServiceHour(mixDesign, true)" class="col-1 p-0 d-flex justify-content-end align-items-center">
                    <i class="fa fa-long-arrow-alt-up fa-2x text-success strong"></i>
                  </div>
                  <div v-else class="col-1 p-0 d-flex justify-content-end align-items-center">
                    <i class="fa fa-long-arrow-alt-down fa-2x text-danger strong"></i>
                  </div>
                </div>

              </div>

            </collapse-item>
          </collapse>

          <collapse v-if="$hasPermission('additional_permissions.contract-proposal-formulation.details.view')" class="border rounded p-0 mt-3" v-show="showMostDetails">
            <collapse-item name="1" class="header-gray" back-ground="border-header-indigo-bottom">
              <h5 slot="title" class="mb-0 ">PUZL</h5>

              <div class="border-left border-3 p-1 border-indigo mt-2">
                <div class="row d-flex justify-content-between">
                  <div class="col-7 d-flex justify-content-start">
                    <span class="h5 small">Puzl Admix</span>
                  </div>
                  <div class="col-5 p-0 d-flex justify-content-end">
                    <span class="h5">R$ 25,48</span>
                  </div>
                </div>
                <div class="row d-flex justify-content-between">
                  <div class="col-7 d-flex justify-content-start">
                    <span class="h5 small">Puzl Connect (5%)</span>
                  </div>
                  <div class="col-5 p-0 d-flex justify-content-end">
                    <span class="h5">R$ 441,00</span>
                  </div>
                </div>
              </div>

              <div class="border-left border-3 p-1 border-success mt-2">
                <div class="row d-flex justify-content-between">
                  <div class="col-7 d-flex justify-content-start">
                    <span class="h5 small">Reaproveitamento</span>
                  </div>
                  <div class="col-5 p-0 d-flex justify-content-end">
                    <span class="h5">R$ 662,65,00</span>
                  </div>
                </div>
                <div class="row d-flex justify-content-between">
                  <div class="col-7 d-flex justify-content-start">
                    <span class="h5 small">-</span>
                  </div>
                  <div class="col-5 p-0 d-flex justify-content-end">
                    <span class="h5">5,6 <span style="text-transform: none">m</span><sup>3</sup></span>
                  </div>
                </div>
              </div>

            </collapse-item>
          </collapse>

        </div>

        <div class="modal-footer justify-content-center">
          <base-button type="secondary" @click="closeModal()">fechar</base-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
const status = {
  0: {
    name: 'Reprovado',
    class: 'danger',
  },
  1: {
    name: 'Aprovado',
    class: 'success',
  },
  2: {
    name: 'Revisão',
    class: 'warning',
  }
}
import {
  period_types
} from "@/views/Modules/Technology/Mix/helpers/mixe";

export default {
  components: {},
  name: "ModalDetailHistory",
  data() {
    return {
      modal: false,
      title: 'Detalhe',
      validated: false,
      loading: false,
      mixDesign: null,
      status: status,
      showMostDetails: true,
      format_mixe: period_types,
    }
  },
  methods: {
    closeModal() {
      this.modal = false
    },
    calcPercentage(percentage, value) {
      return Number(value) * (Number(percentage) / 100)
    },
    sumMcc(mixDesign) {
      let keys =
        [
          'machining_price',
          'transport_price',
          'cycle_price',
          'type_price',
          'tax_price',
        ]
      let value = 0;
      keys.forEach(function (key) {
        value += parseFloat(mixDesign.concrete_price[key])
      })
      return value
    },
    openModal(mix_design, showMostDetails) {
      this.mixDesign = mix_design
      this.showMostDetails = showMostDetails
      this.modal = true
    },
    calculateGrossMargin: function (minimumPrice, mcc, tax) {
      const price = minimumPrice
      const cmc = mcc
      if (price > 0) {
        if (Number(tax) > 0) {
          let tax_value = price * (tax / 100)
          return price - tax_value - cmc
        } else {
          return price - cmc
        }
      }
      return 0
    },
    calcAdittionals(mixDesign) {
      const distance = mixDesign.additional_distance && mixDesign.additional_distance.value || 0
      const cycle = mixDesign.additional_cycle && mixDesign.additional_cycle.value || 0
      const concretedPiece = mixDesign.additional_concrete_piece && mixDesign.additional_concrete_piece.value || 0
      const slumpFinal = mixDesign.additional_slump_final && mixDesign.additional_slump_final.value || 0
      const featureLevel = mixDesign.additional_level_responsibility && mixDesign.additional_level_responsibility.value || 0
      return Number(distance) + Number(cycle) + Number(concretedPiece) + Number(slumpFinal) + Number(featureLevel)
    },
    calcBilling(volume, value) {
      volume = volume.replace('.', '').replace(',', '.')
      volume = parseFloat(volume)
      value = parseFloat(value)
      return this.$helper.toMoney(volume * value)
    },
    calcBillingHour(mixDesign, without_format = false) {
      const val1 = mixDesign.value * mixDesign.cycle.average_volume_per_bt
      const val2 = mixDesign.cycle.total_cycle / 60
      if (without_format) {
        return val1 / val2
      }
      return this.$helper.toMoney(val1 / val2)
    },
    unformatTax(tax) {
      const regex = /\d+\.?\d*/;
      return tax.match(regex)[0];
    },
    calcServiceHour(mixDesign, without_format = false) {
      const billing = mixDesign.value - (parseFloat(mixDesign.concrete_price.mcc_price) + parseFloat(mixDesign.puzl_addition_price_without_addition))
      const service = billing * mixDesign.cycle.average_volume_per_bt
      const cycle = mixDesign.cycle.total_cycle / 60
      if (without_format) {
        return service / cycle
      }
      return this.$helper.toMoney(service / cycle)
    },
    handleHistory(contractProposalUuid) {
      this.modal = true
    },
    save() {
    },
  },
};
</script>

<style>
</style>
