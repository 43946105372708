import OperationSourceListPage from "../views/list/OperationSourceListPage.vue";

export default [
  {
    path: "/fiscal/operation-sources",
    name: "fiscal.operation-source.view",
    component: OperationSourceListPage,
    meta: {
      title: "Fiscal",
      description: "Tipos de Operação PUZL",
      icon: "fa fa-home",
      permission: "fiscal.operation-source.index.view",
      breadcrumbs: [
        {
          title: "Dashboard",
          name: "fiscal.operation-source.dashboard.index",
        },
        { title: "Tipos de Operação PUZL", name: null },
      ],
    },
  },
];
