<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">

            <div class="form-group row m-0 p-0">
              <label class="col-md-12 pb-0 mb-1 col-form-label form-control-label">
                Informa uma justificativa para desabilitar o adicional
              </label>
              <div class="col-md-12 mb-1 pr-4">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                        <textarea v-model="justification" type="text" rows="4" class="form-control form-control-sm">
                        </textarea>
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="modal-footer">
              <base-button type="secondary" @click="cancelUpdate()">
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid"
                           :loading="loadingUpdateAdditional">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: "ModalJustification",
  data() {
    return {
      modal: {
        title: 'Justificativa',
        create: false,
      },
      additionalSelected: null,
      loadingUpdateAdditional: false,
      justification: null,
    };
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    cancelUpdate() {
      this.closeModal();
    },
    handleCreateModal(item) {
      this.additionalSelected = item
      this.justification = null;
      this.modal.create = true;
    },
    save() {
      this.additionalSelected.status = !this.additionalSelected.status;
      let params = {
        justification: this.justification,
        status: this.additionalSelected.status ? 1 : 3,
        uuid: this.additionalSelected.uuid
      }
      this.loadingUpdateAdditional = true
      this.$Progress.start();
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch('scheduleAdditional/update', params)
        .then(response => {
          this.$Progress.finish();
          this.showJustification = false
          this.closeModal()
          this.loadingUpdateAdditional = false
          this.$emit("updatedJustification", response.data.justification)
          this.$notify({type: 'success', message: response.message});
        }).catch(error => {
        this.$notify({type: 'danger', message: error.data.message});
        this.loadingUpdateAdditional = false
        this.$Progress.finish();
      })
    },
  },
};
</script>

<style scoped></style>
