import Swal from 'sweetalert2'

export const confirmDelete = (
  text = 'Você não poderá recuperar este registro!',
  confirmButtonText = 'Sim, apague isto!'
) =>  {
  return Swal.fire({
    title: 'Você tem certeza?',
    text: text,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: confirmButtonText,
    cancelButtonText: 'Não, mantenha',
    customClass: {
      confirmButton: 'btn btn-success btn-fill',
      cancelButton: 'btn btn-danger btn-fill',
    },
    buttonsStyling: false,
  })
}

export const confirmAction = (text, options = null) =>  {
  return Swal.fire({
    title: 'Você tem certeza?',
    text: text,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: options ? options[0] : 'Tenho certeza e desejo continuar',
    cancelButtonText: options ? options[1] : 'Fechar',
    customClass: {
      confirmButton: 'btn btn-success btn-fill',
      cancelButton: 'btn btn-danger btn-fill',
    },
    buttonsStyling: false,
  })
}
export const confirmActionWithDenied = (text, options = null, title = 'Você tem certeza?') =>  {
  return Swal.fire({
    title: title,
    text: text,
    icon: 'warning',
    showDenyButton: true,
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonText: options ? options[0] : 'Tenho certeza e desejo continuar',
    denyButtonText: options ? options[1] : 'Não',
    cancelButtonText: options ? options[2] : 'Cancelar',
    customClass: {
      confirmButton: 'btn btn-success btn-fill',
      cancelButton: 'btn btn-secondary btn-fill',
      denyButton: 'btn btn-danger btn-fill',
    },
    buttonsStyling: false,
  })
}




