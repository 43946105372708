<template>
  <!-- NOVO TEMPLATE -->
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-md-4">
          <h2 style="font-size: 1.2rem !important;" class="text-primary">
            <img height="30px" src="/img/icons/physics.png" class="mr-3"/>
            NOVO MCC
          </h2>
        </div>
        <div class="col-md-8 pr-4">
          <div class="row d-flex justify-content-end">
            <div class="mb-md-0 mb-2 col-md-3">
              <router-link :to="{ path:'/technology/cmc/grid'}">
                <base-button
                  block
                  size="md"
                  type="light"
                  class="pb-2 pt-2 text-uppercase"
                >
                  voltar
                </base-button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <hr class="blue-divider mt-2 mb-4">
    </base-header>
      <!-- Header Menus -->

    <div class="container-fluid">
      <card v-if="cmc">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
            <div class="row mb-3">
              <div class="col-12 col-md-6">
                <div class="card p-4">
                  <div class="row">
                    <div class="col-10">
                      <h5 class="h3 new-default-black-font p-1 mb-0">
                        <img height="30px" src="/img/icons/icons8/ios/info-squared_warning.png" class="mr-3"/>
                        IDENTIFICAÇÃO
                      </h5>
                    </div>
                    <div class="col-2">
                      <base-dropdown class="float-right">
                        <button style="margin: 0;width: 95px; justify-content: center; height: 25px;display: flex; align-items: center;" slot="title-container"
                          class="btn dropdown-toggle text-uppercase colorize-btn-img"
                          type="button"
                          :class="status ? 'btn-outline-success' : 'btn-outline-danger'">
                          <img class="mr-1" v-if="status" src="/img/icons/icons8/ios/thumb-up_success.png" width="16px">
                          <img class="mr-1" v-else src="/img/icons/icons8/ios/thumbs-down_danger.png" width="16px">
                          {{ status ? 'ATIVO' : 'INATIVO' }}
                        </button>
                        <a @click.prevent="changeStatus()" class="dropdown-item text-uppercase new-default-black-font" style="font-size: 12px;">
                          {{ status ? 'INATIVO' : 'ATIVO' }}
                        </a>
                      </base-dropdown>
                    </div>
                  </div>
                  <hr class="new-default-black mt-2 mb-2">
                  <div class="row my-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        CATEGORIA MCC
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-classes="form-control-sm">
                          <puzl-select
                            v-model="cmc.cmc_category_id"
                            :items="categories"
                            :loading="loadingCategory"
                            :disabled="loadingCategory" />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row my-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 mb-1 col-form-label form-control-label">
                        <div class="row align-items-center">
                          <div class="col-6 new-default-black-font">
                            Produto <span class="text-danger">&nbsp;*&nbsp;</span>
                          </div>
                          <div class="col-6">
                            <span style="width: 80px;display: flex; align-items: center;"
                              class="btn btn-sm btn-outline-success p-1 text-center float-right"
                              @click.prevent="handleCreateProduct()"><i
                              class="fas fa-plus ml-2 mr-2"></i> NOVO
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-classes="form-control-sm">
                          <puzl-select
                            v-model="cmc.cmc_product_id"
                            :items="products"
                            label="product_name"
                            @input="getExternalCode()"
                            :loading="loadingProduct"
                            :disabled="checkProduct" />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row my-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 mb-1 col-form-label form-control-label">
                        <div class="row align-items-center">
                          <div class="col-6 new-default-black-font">
                            Fornecedor <span class="text-danger">&nbsp;*&nbsp;</span>
                          </div>
                          <div class="col-6">
                            <span style="width: 80px;display: flex; align-items: center;"
                              class="btn btn-sm btn-outline-success p-1 text-center float-right"
                              @click.prevent="handleCreateSupplier()"><i
                              class="fas fa-plus ml-2 mr-2"></i> NOVO
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-classes="form-control-sm">
                          <puzl-select
                            v-model="cmc.cmc_supplier_id"
                            :items="suppliers"
                            label="supplier_name"
                            :loading="loadingSupplier"
                            :disabled="loadingSupplier" />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row my-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 mb-1 col-form-label form-control-label">
                        <div class="row align-items-center">
                          <div class="col-6 new-default-black-font">
                            PROCEDÊNCIA <span class="text-danger">&nbsp;*&nbsp;</span>
                          </div>
                          <div class="col-6">
                            <span style="width: 80px;display: flex; align-items: center;"
                              class="btn btn-sm btn-outline-success p-1 text-center float-right"
                              @click.prevent="handleCreateSupplierOrigin()"><i
                              class="fas fa-plus ml-2 mr-2"></i> NOVO
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-classes="form-control-sm">
                          <puzl-select
                            v-model="cmc.cmc_supplier_origin_id"
                            :items="supplier_origins"
                            label="origin_name"
                            :loading="loadingOrigin"
                            :disabled="checkOrigin" />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row my-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 mb-1 col-form-label form-control-label">
                        <div class="row align-items-center">
                          <div class="col-6 new-default-black-font">
                            CLASS. ADICIONAL
                          </div>
                          <div class="col-6">
                            <span style="width: 80px;display: flex; align-items: center;"
                              class="btn btn-sm btn-outline-success p-1 text-center float-right"
                              @click.prevent="handleCreateAdditionalClassification()"><i
                              class="fas fa-plus ml-2 mr-2"></i> NOVO
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input input-classes="form-control-sm">
                        <puzl-select
                            v-model="cmc.cmc_additional_classification_id"
                            :items="additional_classifications"
                            label="additional_classification"
                            :loading="loadinAdditionalClassifications"
                            :disabled="isSelectedCategory" />
                      </base-input>
                    </div>
                  </div>
                  <div class="row my-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 col-form-label new-default-black-font form-control-label">
                        CÓDIGO EXTERNO
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input input-classes="form-control-sm">
                        <input
                          v-model="productExternalCode"
                          type="text"
                          disabled
                          class="form-control form-control-sm" />
                      </base-input>
                    </div>
                  </div>
                </div>
                <div class="card p-4">
                  <h5 class="h3 new-default-black-font p-1 mb-0">
                    <img height="30px" src="/img/icons/icons8/ios/ruler_success.png" class="mr-3"/>
                    UNIDADE DE MEDIDA
                  </h5>
                  <hr class="new-default-black mt-2 mb-2">
                  <div class="row my-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 col-form-label new-default-black-font form-control-label">
                        COMPRA
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-classes="form-control-sm">
                          <puzl-select
                            @change="setMeasuredUnitBuy"
                            v-model="cmc.measured_unit_id_buy"
                            :items="list_measured_unit_buy"
                            label="letter"
                            :loading="loadingMeasuredUnit"
                            :disabled="loadingMeasuredUnit" />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row my-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 col-form-label new-default-black-font form-control-label">
                        DOSAGEM
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-classes="form-control-sm">
                          <puzl-select
                            v-model="cmc.measured_unit_id_dosage"
                            :items="list_measured_unit_dosage"
                            label="letter"
                            :loading="loadingMeasuredUnit"
                            :disabled="loadingMeasuredUnit" />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row my-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 col-form-label new-default-black-font form-control-label">
                        ESTOQUE
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-classes="form-control-sm">
                          <puzl-select
                            v-model="cmc.measured_unit_id_stock"
                            :items="list_measured_unit_stock"
                            label="letter"
                            :loading="loadingMeasuredUnit"
                            :disabled="loadingMeasuredUnit" />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                </div>
                <div class="card p-4">
                  <h5 class="h3 new-default-black-font p-1 mb-0">
                    <img height="30px" src="/img/icons/open-box.png" class="mr-3"/>
                    CONVERSÃO DE ENTRADA PARA ESTOQUE
                  </h5>
                  <hr class="new-default-black mt-2 mb-2">
                  <div class="row my-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 col-form-label new-default-black-font form-control-label">
                        GRUPO DE ESTOQUE
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-classes="form-control-sm">
                          <puzl-select
                            v-model="cmc.stock_group_id"
                            :items="groups"
                            label="group_name"
                            :loading="loadingCmcGroup"/>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row my-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 col-form-label new-default-black-font form-control-label">
                        FATOR DE CONVERSÃO
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-classes="form-control-sm">
                          <input
                            v-mask="['#,##', '##,##', '###,##', '####,##', '#####,##']"
                            v-model.lazy="cmc.conversation_factor"
                            inputmode="numeric"
                            type="text"
                            class="form-control form-control-sm"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row my-2 px-3 align-items-center">
                    <div class="col-12" style="border: 1px solid #bcb5b5;border-radius: 8px;">
                      <h5 class="new-default-black-font mt-1">EXEMPLO</h5>
                      <h5 class="text-muted mt-n1" v-show="measured_unit_buy_selected">
                        23,50 {{ measured_unit_buy_selected }} = 23,350 KG
                      </h5>
                      <h5 class="text-muted mt-n2">
                        CONTROLE DE ESTOQUE DE MCC SEMPRE SERÁ KG
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="card p-4">
                  <h5 class="h3 new-default-black-font p-1 mb-0">
                    <img height="30px" src="/img/icons/icons8/ios/data-configuration_primary.png" class="mr-3"/>
                    DADOS TÉCNICOS
                  </h5>
                  <hr class="new-default-black mt-2 mb-2">
                  <div class="row my-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 col-form-label new-default-black-font form-control-label">
                        MASSA ESPECÍFICA
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-group-classes="input-group-sm">
                          <input inputmode="numeric" v-model="cmc.specific_gravity" v-mask="['#,##']"
                            class="form-control form-control-sm" input-group-classes="input-group-sm"
                            :class="errors[0] ? 'is-invalid' : 'is-valid'">
                          <template slot="append">
                            <small class="input-group-sm p-0 m-0" style="text-transform: none">
                              g/cm<sup>3</sup>
                            </small>
                          </template>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row my-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 col-form-label new-default-black-font form-control-label">
                        MASSA UNITÁRIA
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-group-classes="input-group-sm">
                          <input inputmode="numeric" v-model="cmc.bulk_density" v-mask="['#,##']"
                            class="form-control form-control-sm" input-group-classes="input-group-sm"
                            :class="errors[0] ? 'is-invalid' : 'is-valid'">
                          <template slot="append">
                            <small class="input-group-sm p-0 m-0" style="text-transform: none">
                              g/cm<sup>3</sup>
                            </small>
                          </template>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div v-if="verifyType() && verifyType() == 'UMIDADE MÁXIMA'" class="row my-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 col-form-label new-default-black-font form-control-label">
                        UMIDADE MÉDIA
                        <span class="text-danger">&nbsp;*</span>
                        <el-popover trigger="click" placement="right">
                          <div class="new-default-black-font" style="width: 343px;word-break: break-word;">
                            <div class="row">
                              <div class="col-12">
                                <nav class="new-default-black-font font-weight-500 text-uppercase" style="font-size: 12px;">
                                  Umidade utilizada apenas para ajuste de preço por unidade de compra em ton (tonelada). Para as demais unidades serve tão somente como referência.
                                </nav>
                              </div>
                            </div>
                          </div>
                          <img slot="reference" role="button" class="mt-n1 ml-2" width="24px" src="/img/icons/info.png"/>
                        </el-popover>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-group-classes="input-group-sm">
                          <input
                            inputmode="numeric"
                            v-model="cmc.average_humidity" v-mask="['#,#', '##,#']"
                            class="form-control form-control-sm"
                            input-group-classes="input-group-sm"
                            :class="errors[0] ? 'is-invalid' : 'is-valid'">
                          <template slot="append">
                            <small class="input-group-sm p-0 m-0">
                              %
                            </small>
                          </template>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div v-if="verifyType()" class="row my-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 col-form-label new-default-black-font form-control-label">
                        {{ verifyType() }}
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-group-classes="input-group-sm">
                          <input
                            inputmode="numeric"
                            v-model="cmc.max_humidity" v-mask="['#,#', '##,#']"
                            class="form-control form-control-sm"
                            input-group-classes="input-group-sm"
                            :class="errors[0] ? 'is-invalid' : 'is-valid'"
                          >
                          <template slot="append">
                            <small class="input-group-sm p-0 m-0">
                              %
                            </small>
                          </template>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div v-if="verifyType() && verifyType() == 'UMIDADE MÁXIMA'" class="row mt-1 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 col-form-label new-default-black-font form-control-label">
                        MÓDULO DE FINURA
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-group-classes="input-group-sm">
                          <input
                            inputmode="numeric"
                            v-model="cmc.fineness_modulus" v-mask="['#,##']"
                            class="form-control form-control-sm"
                            input-group-classes="input-group-sm"
                            :class="errors[0] ? 'is-invalid' : 'is-valid'"
                          >
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                </div>
                <div class="card p-4">
                  <h5 class="h3 new-default-black-font p-1 mb-0">
                    <img height="30px" src="/img/icons/icons8/ios/parallel-tasks-blue.png" class="mr-3"/>
                    GRUPO
                  </h5>
                  <hr class="new-default-black mt-2 mb-2">
                  <div class="row my-1 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 mb-1 col-form-label form-control-label">
                        <div class="row align-items-center">
                          <div class="col-6 new-default-black-font">
                            GRUPO <span class="text-danger">&nbsp;*&nbsp;</span>
                          </div>
                          <div class="col-6">
                            <span style="width: 80px;display: flex; align-items: center;"
                              class="btn btn-sm btn-outline-success p-1 text-center float-right"
                              @click.prevent="handleCreateGroup()"><i
                              class="fas fa-plus ml-2 mr-2"></i> NOVO
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-classes="form-control-sm">
                          <puzl-select
                            v-model="cmc.cmc_group_id"
                            :items="groups"
                            :multiple="true"
                            label="group_name"
                            :loading="loadingCmcGroup"
                            :disabled="isSelectedCategory" />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                </div>
                <div class="card p-4">
                  <h5 class="h3 new-default-black-font p-1 mb-0">
                    <img height="30px" src="/img/icons/icons8/ios/about_warning.png" class="mr-3"/>
                    DESCRIÇÕES COMPLEMENTARES
                  </h5>
                  <hr class="new-default-black mt-2 mb-2">
                  <div class="row my-1 align-items-center">
                    <div class="col-12">
                      <base-input input-group-classes="input-group-sm">
                        <textarea
                          type="text"
                          v-model="cmc.description"
                          maxlength="300"
                          rows="5"
                          class="form-control form-control-sm">
                        </textarea>
                      </base-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <router-link :to="{ path:'/technology/cmc/grid'}">
                <base-button
                  type="danger"
                  style="width: 200px"
                  size="lg">
                    <img class="mr-2" src="/img/icons/icons8/ios/cancel.png" width="18px" alt="">
                    CANCELAR
                </base-button>
              </router-link>
              <base-button
                type="success"
                style="width: 200px"
                native-type="submit"
                size="lg"
                v-bind:disabled="invalid"
                :loading="loadingSave">
                  <img class="mr-2" src="/img/icons/save.png" width="18px" alt="">
                  SALVAR
              </base-button>
            </div>
          </form>
        </validation-observer>
      </card>
    </div>
    <modal-create-product :changeProduct="changeProduct" ref="createProduct"></modal-create-product>
    <modal-create-supplier :changeSupplier="changeSupplier" ref="createSupplier"></modal-create-supplier>
    <modal-create-supplier-origin :changeSupplierOrigin="changeSupplierOrigin" ref="createSupplierOrigin"></modal-create-supplier-origin>
    <modal-create-additional-classification :changeAdditionalClassification="changeAdditionalClassification"
      ref="createAdditionalClassification"></modal-create-additional-classification>
    <modal-create-group :changeGroup="changeGroup" ref="createGroup"></modal-create-group>
  </div>
  <!-- FIM DO NOVO TEMPLATE -->
</template>

<style></style>

<script>
import {
  mapGetters
} from "vuex"
import ModalCreateProduct from '../Product/Shared/_Create'
import ModalCreateSupplier from '../Supplier/Shared/_Create'
import ModalCreateSupplierOrigin from '../Supplier/Origin/Shared/_Create'
import ModalCreateAdditionalClassification from '../AdditionalClassification/Shared/_Create'
import ModalCreateGroup from '../Group/Shared/_Create'
import PuzlSelect from "@/components/PuzlSelect"

export default {
  name: 'CreateCmc',
  components: {
    ModalCreateProduct,
    ModalCreateSupplier,
    ModalCreateSupplierOrigin,
    ModalCreateAdditionalClassification,
    ModalCreateGroup,
    PuzlSelect,
  },
  data() {
    return {
      supplier_origins: [],
      useChangeOptions: true,
      selectNewProduct: false,
      selectNewGroup: false,
      status: true,
      selectNewAdditionalClassification: false,
      measured_unit_buy_selected: null,
      cmc: {
        status: true,
        cmc_category_id: '',
        cmc_product_id: '',
        cmc_supplier_id: '',
        cmc_supplier_origin_id: '',
        cmc_group_id: '',
        cmc_additional_classification_id: '',
        specific_gravity: '',
        bulk_density: '',
        average_humidity: 0.0,
        measured_unit_id_buy: '',
        measured_unit_id_dosage: '',
        measured_unit_id_stock: '',
        description: '',
        fineness_modulus: 0.0,
        max_humidity: 0.0,
        stock_group_id: null,
        conversation_factor: null
      },
      listCategories: [],
      productExternalCode: null,
      isSelectedCategory: true,
      loadinAdditionalClassifications: false,
      loadingCmcGroup: false,
      loadingSave: false,
      loadingCategory: true,
      checkProduct: true,
      loadingProduct: false,
      loadingSupplier: true,
      checkOrigin: true,
      loadingOrigin: false,
      loadingMeasuredUnit: true,
    }
  },
  computed: {
    ...mapGetters({
      'categories': 'category/fetch',
      'products': 'cmcProduct/showByCategory',
      'suppliers': 'cmcSupplier/fetch',
      'origins': 'cmcSupplierOrigin/fetch',
      'additional_classifications': 'cmcAdditionalClassification/showByCategory',
      'groups': 'cmcGroup/showGroupByCategory',
      'list_measured_unit_buy': 'measuredunit/measured_unit_buy',
      'list_measured_unit_dosage': 'measuredunit/measured_unit_dosage',
      'list_measured_unit_stock': 'measuredunit/measured_unit_stock',
    }),
  },
  watch: {
    'cmc.cmc_category_id': function () {
      this.isSelectedCategory = false
      this.getProductByCategory()
      this.getClassificationByCategory()
      this.getGroupByCategory()

      if(!this.selectNewGroup) this.cmc.cmc_group_id = null
      if(!this.selectNewProduct) this.cmc.cmc_product_id = null;
      if(!this.selectNewAdditionalClassification) this.cmc.cmc_additional_classification_id = null;

      this.selectNewGroup = false;
      this.selectNewProduct = false;
      this.selectNewAdditionalClassification = false;
    },
    'cmc.cmc_supplier_id': function () {
      this.getCmcSupplierOrigin()
    },
  },
  methods: {
    changeStatus()
    {
      this.cmc.status = !this.cmc.status;
      this.status = this.cmc.status;
    },
    changeProduct(product){
      this.cmc.cmc_category_id = product.cmc_category_id;
      this.cmc.cmc_product_id = product.id;
      this.selectNewProduct = true;
    },
    changeGroup(group){
      if(this.cmc.cmc_category_id != group.cmc_category_id){
        this.cmc.cmc_group_id = group.id;
      } else {
        this.cmc.cmc_group_id.push(group.id);
      }

      this.cmc.cmc_category_id = group.cmc_category_id;
      this.selectNewGroup = true;
    },
    changeAdditionalClassification(additional_classification){
      this.cmc.cmc_category_id = additional_classification.cmc_category_id;
      this.cmc.cmc_additional_classification_id = additional_classification.id;;
      this.selectNewAdditionalClassification = true;
    },
    changeSupplierOrigin(supplier_origin_id){
      this.cmc.cmc_supplier_origin_id = supplier_origin_id;
    },
    changeSupplier(supplier_id){
      this.cmc.cmc_supplier_id = supplier_id;
    },
    getCategoryName(){
      if(!this.loadingProduct && this.listCategories.length) return this.listCategories[0].cmc_category.name;

      return null;
    },
    setMeasuredUnitBuy() {
      let measured_unit_buy_selected = this.list_measured_unit_buy.find((item) => item.id === this.cmc.measured_unit_id_buy)
      if (measured_unit_buy_selected) {
        measured_unit_buy_selected = measured_unit_buy_selected.split(" ")[0];
        this.measured_unit_buy_selected = measured_unit_buy_selected.letter
      }
      else {
        this.measured_unit_buy_selected = null
      }
    },
    verifyType()
    {
      let type = null;
      let categoryName = this.getCategoryName();

      if(categoryName)
      {
        if(categoryName.toLowerCase().includes("agregado")){
          type = "UMIDADE MÁXIMA";
        } else if(categoryName.toLowerCase().includes("água")) {
          type = "CORTE MÁXIMO DE ÁGUA";
        }
      }

      return type;
    },
    save() {
      this.$Progress.start()
      this.loadingSave = true
      this.$store.dispatch('cmc/add', this.cmc)
        .then(response => {
          this.loadingSave = false
          this.modal = false
          this.$Progress.finish()
          this.$notify({
            type: response.error_type,
            message: response.message
          })
          this.$router.push({
            name: "MCC",
            path: '/technology/cmc/grid',
          })
        })
        .catch(error => {
          if (error.status) {
            this.$Progress.finish()
            this.loadingSave = false
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            })
          } else {
            if (error.response.status === 422) {
              let message = formatErrorValidation(error.response.data.errors)
              this.$notify({
                type: 'danger',
                message: message
              })
              this.$Progress.finish()
              this.loadingSave = false
            } else {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              })
              this.$Progress.finish()
              this.loadingSave = false
            }
          }
        })
    },
    getProductByCategory() {
      this.checkProduct = true
      this.loadingProduct = true
      this.$store.dispatch('cmcProduct/fetchByCategory', this.cmc.cmc_category_id)
        .then((response) => {
          this.listCategories = response.data;
          this.checkProduct = false
          this.loadingProduct = false
        })
    },
    getClassificationByCategory() {
      this.loadinAdditionalClassifications = true;
      this.loadingCmcGroup = true;
      this.$store.dispatch('cmcAdditionalClassification/fetchByCategory', this.cmc.cmc_category_id)
        .then(() => {
          this.loadinAdditionalClassifications = false;
          this.loadingCmcGroup = false;
        });
    },
    getGroupByCategory() {
      this.$store.dispatch('cmcGroup/fetchGroupByCategory', this.cmc.cmc_category_id)
        .then(() => {
        })
    },
    getCmcSupplierOrigin() {
      this.checkOrigin = true
      this.loadingOrigin = true
      this.cmc.cmc_supplier_origin_id = ''
      let [supplier] = this.suppliers.filter((item) => item.id === this.cmc.cmc_supplier_id)
      if(supplier)
      {
        this.$store.dispatch('cmcSupplierOrigin/fetchItems', supplier.uuid).then((response) => {
          this.checkOrigin = false
          this.loadingOrigin = false
          this.supplier_origins = response.data.supplier_origins;
        });
      } else {
        this.checkOrigin = true;
        this.loadingOrigin = false;
        this.supplier_origins = [];
      }
    },
    getExternalCode() {
      this.productExternalCode = this.products.find((item) => item.id === this.cmc.cmc_product_id).external_code;
    },
    handleCreateProduct() {
      this.$refs.createProduct.openModal(true, this.cmc.cmc_category_id);
    },
    handleCreateSupplier() {
      this.$refs.createSupplier.openModal(true)
    },
    changeOptions(type = null) {
      if (this.cmc.cmc_category_id == "") {
        return
      }
      switch (type) {
        case 'product':
          this.getProductByCategory()
          break;
        case 'additional_classification':
          this.getClassificationByCategory()
          break;
        case 'group':
          this.getGroupByCategory()
          break;
      }

    },
    handleCreateSupplierOrigin() {
      let supplierId = this.cmc.cmc_supplier_id

      if (supplierId) {
        this.$refs.createSupplierOrigin.openModal(true, supplierId)
      } else {
        this.$notify({type: 'danger', message: 'Você precisa escolher um Fornecedor primeiro!'})
      }
    },
    handleCreateAdditionalClassification() {
      this.$refs.createAdditionalClassification.openModal(true)
    },
    handleCreateGroup() {
      this.$refs.createGroup.openModal(true)
    }
  },
  mounted() {
    this.$refs.formValidator.validate();
    this.$store.dispatch('category/fetchItems').then(() => {
      this.loadingCategory = false
    })
    this.$store.dispatch('cmcSupplier/fetchItems').then((response) => {
      this.loadingSupplier = false
      if(response.data.supplier_origins) this.supplier_origins = response.data.supplier_origins;
    })
    this.$store.dispatch('measuredunit/fetchItems').then(() => {
      this.loadingMeasuredUnit = false
    })
  }
};
</script>
