<template>
  <div>
    <base-header class="bg-gray-content">
      <puzl-breadcrumb />
    </base-header>
    <List />
  </div>
</template>

<script>
import List from "./Shared/_List";
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";

export default {
  name: "Index",
  components: {
    PuzlBreadcrumb,
    List,
  },
};
</script>

<style></style>
