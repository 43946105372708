<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <div class="col-12">
          <h4>Parcela {{ item.index }}</h4>
        </div>
        <div class="col-12 mt-1">
          <div class="border-left border-3 border-primary p-1 mb-2">
            <div class="ml-2">
              <h4 class="numeric mb-0">{{ item.total_value | currency }}</h4>
              <h4 v-if="item.payment_method_name" class="mt-2">{{ item.payment_method_name }}</h4>
            </div>
          </div>
        </div>
        <div class="col-12 mt-3">
          <h4 class="text-success">Novo pagamento</h4>
        </div>
        <div class="form-group row m-0 mb-1">
          <label class="col-md-6 pb-0 mt-n1 col-form-label form-control-label">
            Valor
            <span class="text-danger">&nbsp;*</span>
          </label>
          <div class="col-md-6 mt-1">
            <base-input input-group-classes="input-group-sm">
              <input
                v-money="money"
                maxlength="13"
                @keydown="$event.key === '-' ? $event.preventDefault() : null"
                inputmode="numeric"
                v-model.lazy="new_installment.value"
                type="text"
                class="form-control form-control-sm numeric"
              />
              <template slot="prepend">
                <small class="input-group-sm p-0 m-0"> R$ </small>
              </template>
            </base-input>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <base-button type="secondary" @click="closeModal()"> Cancelar </base-button>
        <base-button
          @click.native="store()"
          type="success"
          native-type="submit"
          title="Salvar"
          class="btn-custom"
          :class="loading && 'btn-loading'"
          :disabled="new_installment.value === '0,00'"
        >
          <span v-show="!loading" class="btn-label span-custom"><i class="fas fa-save text-white icon-custom"></i></span>
          <span v-show="loading" class="btn-label span-custom"><i class="fas fa-spinner text-white fa-spin icon-custom"></i></span>
          Salvar
        </base-button>
      </div>
    </modal>
  </div>
</template>

<script>
import { VMoney } from "v-money";
import { formatErrorValidation } from "@/plugins";

export default {
  name: "ModalShareInstallment",
  data() {
    return {
      modal: {
        title: "Dividir Pagamento",
        create: false,
      },
      item: {
        index: 1,
        total_value: 0,
        payment_method_name: null,
      },
      new_installment: {
        value: 0,
        reference_installment_id: null,
      },
      loading: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
    };
  },
  directives: {
    money: VMoney,
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    store() {
      this.loading = true;
      const loader = this.$loading.show();
      this.$store
        .dispatch(`billPay/shareInstallment`, this.new_installment)
        .then((response) => {
          this.$emit('updated');
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          } else {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message,
            });
          }
        })
        .finally(() => {
          this.loading = false;
          this.closeModal();
          loader.hide();
        });
    },
    openModal(item) {
      this.item = item;
      this.new_installment = {
        value: 0,
        reference_installment_id: item.id,
      };
      this.modal.create = true;
    },
  },
};
</script>
