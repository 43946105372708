<template>
<div>
    <modal :show.sync="modal.create">
        <template slot="header">
            <img src="/img/icons/icons8/ios/change-white.png" width="23px" height="23px" class="mr-2" />
            <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
        </template>
        <div class="p-2">
            <div class="row mt-n3">
          <div class="col-12">
            <div class="d-flex align-items-center">
              <img height="23px" src="/img/icons/icons8/ios/info-squared.png" class="mr-3" />
              <b class="text-dark mr-3">Substituir por</b>
              <hr class="bg-secondary flex-grow-1 ml-2 mr-2">
            </div>
            <div style="font-size:8.4px;" class="mt-n3 ml-3">
                <img src="/img/icons/icons8/ios/help.png" width="16px" height="16px" class="ml-n3" />
                Todos os usuários que pertenciam a esse grupo serão realocados para o novo grupo de alerta
            </div>
          </div>
        </div>
            <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
                <form class="needs-validation" @submit.prevent="handleSubmit(selectNewPermissionGroup)" autocomplete="off">
                    <div class="form-group row m-0 p-0 mt-4">
                        <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                            Grupo de alerta
                        </label>
                        <div class="col-md-7 mb-1 pr-4">
                            <validation-provider rules="required" v-slot="{errors}">
                                <base-input input-classes="form-control-sm">
                                    <PuzlSelect 
                                        v-model="selectedAlertGroupId" 
                                        :items="$_alertGroups" 
                                        :disabled="loadingAlertGroup" 
                                        :loading="loadingAlertGroup" />
                                </base-input>
                            </validation-provider>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <base-button
                            type="primary"
                            native-type="submit"
                            :loading="loadingAlertGroup"
                            :disabled="invalid"
                        >
                            <img height="17px" src="/img/icons/icons8/ios/right-squared.png" class="mr-2" />
                            Continuar
                        </base-button>
                    </div>
                </form>
            </validation-observer>
        </div>
    </modal>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import PuzlSelect from "@/components/PuzlSelect";

export default {
    name: "ModalReplaceAlertGroup",
    components: { PuzlSelect },
    data() {
        return {
            modal: {
                title: 'Substituir grupo de alerta',
                create: false,
            },
            selectedAlertGroupId: null,
            loadingAlertGroup: true,
        };
    },
    computed: {
        ...mapGetters({
            $_alertGroups: 'alertGroup/fetch',
        }),
    },
    methods: {
        closeModal() {
            this.modal.create = false;
        },
        openModal() {
            this.modal.create = true;
            this.selectedAlertGroupId = null;
            this.loadData();
        },
        selectNewPermissionGroup() {
            this.$emit('selectNewAlertGroup', this.selectedAlertGroupId);
            this.closeModal();
        },
        loadData() {
            this.$store.dispatch('alertGroup/getAllSimplified').then(() => {
                this.loadingAlertGroup = false;
            });
        },
    },
};
</script>

<style scoped></style>
