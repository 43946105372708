import { dreUrls } from '.';
import httpClient from '../../../../shared/libraries/httpClient';
import { handleUnexpectedStatusCode } from '../../../../shared/services';
import { HttpStatusEnum } from '../../../../shared/enums';

const execute = async (id, params) => {
  const res = await httpClient.put(`${dreUrls.CONFIGURATION_UPDATE_PERSONALIZED}/${id}`, params);

  switch (res.status) {
    case HttpStatusEnum.HTTP_OK: return res.data.data;
    default: handleUnexpectedStatusCode(res);
  }
};

export default { execute };
