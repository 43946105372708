export { default as adjustmentTypeListService } from "./adjustmentTypeListService";
export { default as adjustmentTypeFindService } from "./adjustmentTypeFindService";
export { default as adjustmentTypeUpdateService } from "./adjustmentTypeUpdateService";
export { default as adjustmentTypeFormatEmptyAgentService } from "./adjustmentTypeFormatEmptyAgentService";
export { default as adjustmentTypeCreateService } from "./adjustmentTypeCreateService";
export { default as adjustmentTypeDeleteService } from "./adjustmentTypeDeleteService";
export { default as adjustmentTypeCreateHistoryService } from "./adjustmentTypeCreateHistoryService";

/** Urls para acesso externo */
export const adjustmentTypeUrls = Object.freeze({
  BASE: "/technology/adjustments-type",
});
