const billPayInstallmentMixin = {
  data() {
    return {
      source: {
        has_more: true,
        meta: {
          current_page: 1,
          last_page: 1,
          to: 1,
          from: 1,
        },
      },
    };
  },
  /**
   * Navegação entre as páginas
   * 
   * @param {integer} page
   * @param {array} items
   */
  methods: {
    navegate(page, items) {
      const from = page > 1 ? page - 1 : 0;
      this.source.meta.current_page = page;
      this.source.meta.from = page > 1 ? from : 1;
      this.source.meta.to = this.paginatedInstallment.length + this.source.meta.from;
      this.source.has_more = items.slice(from + 1, page + 1).length > 0;
      this.source.meta.last_page = Math.ceil(items.length);
    },
    /**
     * Reseta a paginação das parcelas
     * 
     * @param {array} items
     */
    resetInstallmentPage(items) {
      this.source.meta = {
        current_page: 1,
        last_page: Math.ceil(items.length),
        to: 1,
        from: 1,
      };
      this.source.has_more = true;
    }

  },
};
export default billPayInstallmentMixin;
