<template>
  <div class="row mt-2">
    <div class="col-md-6 text-left">
       <small v-if="source && source.total_count > 0">
        <small>Total de {{ source.total_count }} registros.</small>
      </small>
    </div>
    <div class="col-md-3"></div>
    <div class="col-md-3">
      <div class="float-right">
        <nav aria-label="Page navigation example" v-if="source && source.total_count > 0">
          <ul class="pagination">
            <li class="page-item" :class="source.current_page == 1 || loading || disabledJumpControls ? 'disabled' : ''">
              <a title="Primeira página" @click.prevent="firstPage" class="page-link" href="#" tabindex="-1"><i
                :class="source.current_page > 1 && !loading && !disabledJumpControls ? 'text-primary' : ''"
                class="fas fa-angle-double-left"></i></a>
            </li>
            <li class="page-item" :class="source.current_page == 1 || loading? 'disabled' : ''">
              <a title="Página anterior" @click.prevent="preventPage" class="page-link" href="#" tabindex="-1"><i
                :class="source.current_page > 1 && !loading ? 'text-primary' : ''"
                class="fas fa-chevron-left"></i></a>
            </li>
            <li class="page-item" :class="!source.has_next_page || loading ? 'disabled' : ''">
              <a title="Próxima página" @click.prevent="nextPage" class="page-link" href="#" tabindex="-1"><i
                :class="source.has_next_page && !loading ? 'text-primary' : ''" class="fas fa-chevron-right"></i></a>
            </li>
            <li v-show="source.total_count" class="page-item" :class="!source.has_next_page || loading || disabledJumpControls ? 'disabled' : ''">
              <a title="Última página" @click.prevent="lastPage" class="page-link" href="#" tabindex="-1"><i
                :class="source.has_next_page && !loading && !disabledJumpControls ? 'text-primary' : ''" class="fas fa-angle-double-right"></i></a>
            </li>
          </ul>
        </nav>
        <nav aria-label="Page navigation example" v-else>
          <ul class="pagination">
            <li class="page-item disabled">
              <a class="page-link" href="#" tabindex="-1">
                <i class="fas fa-chevron-left"></i></a>
            </li>
            <li class="page-item disabled">
              <a class="page-link" href="#" tabindex="-1">
                <i class="fas fa-chevron-right"></i></a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    source: {
      type: [Array, Object],
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabledJumpControls: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      pages: []
    }
  },
  methods: {
    navigate(page) {
      this.$emit('navegate', page)
    },
    preventPage() {
      this.navigate(this.source.current_page - 1)
    },
    nextPage() {
      this.navigate(this.source.current_page + 1)
    },
    lastPage() {
      this.navigate(this.source.last_page)
    },
    firstPage() {
      this.navigate(1)
    },
  }
}
</script>

<style scoped>
</style>
