var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('modal',{attrs:{"show":_vm.modal,"size":"lg"},on:{"update:show":function($event){_vm.modal=$event}}},[_c('template',{slot:"header"},[_c('h5',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.title))])]),_c('div',[_c('validation-observer',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function({ invalid, handleSubmit }){return [_c('multi-filter',{ref:"contract_filter",attrs:{"filter":_vm.filter,"type":2,"status":null},on:{"fetch":_vm.fetch}}),_c('div',{staticClass:"container-fluid"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingSkeleton),expression:"loadingSkeleton"}],staticClass:"row card-wrapper"},_vm._l((3),function(index){return _c('SkeletonPuzlFullWidth',{key:index})}),1),(!_vm.entities.length && !_vm.loadingSkeleton)?_c('PuzlEmptyData'):_vm._e(),_c('form',{staticClass:"needs-validation",attrs:{"autocomplete":"off"}},[_vm._l((_vm.entities),function(entity,index){return (_vm.entities.length && !_vm.loadingSkeleton)?_c('div',{key:index},[_c('div',{staticClass:"border-left border-3 p-2 mb-2",class:entity.status
                      ? 'border-success'
                      : 'border-danger'},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10"},[_c('h5',{staticClass:"h3 mb-0"},[_vm._v(_vm._s(entity.entity_name))]),_c('h6',{staticClass:"h3 mb-0 small"},[_vm._v(_vm._s(entity.document))])]),_c('div',{staticClass:"col-2 d-flex justify-content-center align-items-center"},[_c('base-button',{attrs:{"size":"sm","type":"success"},on:{"click":function($event){return _vm.handleSelect(entity)}}},[_c('i',{staticClass:"fa fa-check text-white"})])],1),_c('div',{staticClass:"col-12 mt-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10"},[_c('div',{staticClass:"mb-2",staticStyle:{"padding-top":"15px"},on:{"click":function($event){$event.preventDefault();return _vm.getDetailsEntity(index, entity.id)}}},[_c('collapse',{staticClass:"border rounded"},[_c('collapse-item',{staticClass:"header-gray",attrs:{"name":"1","back-ground":"border-header-primary-bottom"}},[_c('h5',{staticClass:"mb-0",attrs:{"slot":"title","id":entity.id},slot:"title"},[_vm._v("Detalhes")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(entity.loading),expression:"entity.loading"}],staticClass:"row"},[_c('SkeletonPuzlFullWidth')],1),(
                  entity.postal_code &&
                  entity.address &&
                  entity.number &&
                  entity.district)?_c('div',[_c('h5',{staticClass:"mb-0 text-dark",attrs:{"slot":"title"},slot:"title"},[_vm._v("Endereço")]),_c('div',[_vm._v(" "+_vm._s(entity.postal_code)+" ")]),_c('div',[_vm._v(" "+_vm._s(entity.address)+", "+_vm._s(entity.number)+", ")]),(entity.complement)?_c('div',[_vm._v(" "+_vm._s(entity.complement)+", ")]):_vm._e(),_c('div',[_vm._v(" "+_vm._s(entity.district)+", ")]),_c('div',[_vm._v(" "+_vm._s(entity.state)+" / "+_vm._s(entity.city)+" ")]),(entity.state_registration)?_c('h5',{staticClass:"mb-0 mt-2 text-dark",attrs:{"slot":"title"},slot:"title"},[_vm._v("Insc. Estadual")]):_vm._e(),_c('div',[_vm._v(_vm._s(entity.state_registration))]),(entity.state_registration === 'Contribuinte Isento'
                    || entity.state_registration === 'Contribuinte')?_c('div',[_c('h5',{staticClass:"mb-0 mt-2 text-dark",attrs:{"slot":"title"},slot:"title"},[_vm._v("N"),_c('sup',[_vm._v("o")]),_vm._v(". Contribuinte")]),_vm._v(" "+_vm._s(entity.contributing_number)+" ")]):_vm._e(),(entity.email)?_c('div',[_c('h5',{staticClass:"mb-0 mt-2 text-dark",attrs:{"slot":"title"},slot:"title"},[_vm._v("E-mail")]),_c('div',{staticClass:"text-lowercase"},[_vm._v(_vm._s(entity.email))])]):_vm._e(),(entity.phone)?_c('div',[_c('h5',{staticClass:"mb-0 mt-2 text-dark",attrs:{"slot":"title"},slot:"title"},[_vm._v("Telefone")]),_c('div',[_vm._v(_vm._s(entity.phone))])]):_vm._e()]):_vm._e()])],1)],1)])])])])])]):_vm._e()}),_c('div',{staticClass:"d-flex justify-content-center m-3"},[_c('base-button',{attrs:{"type":"secondary"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("Cancelar ")])],1)],2),_c('pagination',{attrs:{"source":_vm.source},on:{"navegate":_vm.navegate}})],1)]}}])})],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }