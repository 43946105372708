import { AdjustmentHistoryListType } from "./AdjustmentHistoryListType";
import { PagerType, initPagerType } from "../../../../shared/types";

/**
 * Type para retorno paginado da listagem
 *
 * @typedef {(
 *   PagerType &
 *   { items: Array<AdjustmentHistoryListType> }
 * )} AdjustmentHistoryListPagerType
 */
export const AdjustmentHistoryListPagerType = {};

/**
 * Inicializar AdjustmentHistoryListPagerType
 *
 * @returns {AdjustmentHistoryListPagerType}
 */
export const initAdjustmentHistoryListPagerType = () => ({
  ...initPagerType(),
  items: [],
});
