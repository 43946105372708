<template>
<div>
  <ListContractProposalServices />
</div>

</template>
<script>
import ListContractProposalServices from './Shared/_List.vue'

export default {
  name: 'IndexContractProposalServices',
  components: {
    ListContractProposalServices,
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
}
</script>
