<template>
  <div class="container-fluid">
    <Cards
      v-if="listType === 'cards' && bill_receives.length"
      :bill_receives="bill_receives"
      :loadingSkeleton="loadingSkeleton"
      @handleModalEntityHistory="handleModalEntityHistory"
      @downloadInvoice="downloadInvoice"
      @nfseButtonClick="nfseButtonClick"
      @downloadBilletBatch="downloadBilletBatch"
      @handleShowAttachments="handleShowAttachments"
      @showModalEntityPayment="showModalEntityPayment"
      @handlePayOffPayment="handlePayOffPayment"
      @changeInstallmentStatus="changeInstallmentStatus"
      @handleEditBillReceive="handleEditBillReceive"
      @handleOpenModalInfo="handleOpenModalInfo"
      @handleShowHistory="handleShowHistory"
      @handleIdentifyEntity="handleIdentifyEntity"
      @showModalGenerateBillet="showModalGenerateBillet"
      @showModalGenerateBilletHasGenerated="showModalGenerateBilletHasGenerated"
      @postBatchUpdate="postBatchUpdate"
      @handleModalEntityBillReceiveIntegrationInfo="handleModalEntityBillReceiveIntegrationInfo"
      @handleDeleteBillReceive="handleDeleteBillReceive"
      @showModalEditGenerateBilletHasGenerated="showModalEditGenerateBilletHasGenerated"
      @showModalChangeDueDate="showModalChangeDueDate"
      :buildDropdownPossibleActions="buildDropdownPossibleActions"
      :showCompensationButtons="showCompensationButtons"
      :compareBalance="compareBalance"
    />
    <Table
      v-if="listType === 'table' && bill_receives.length"
      :bill_receives="bill_receives"
      :loadingSkeleton="loadingSkeleton"
      @handleModalEntityHistory="handleModalEntityHistory"
      @downloadInvoice="downloadInvoice"
      @nfseButtonClick="nfseButtonClick"
      @downloadBilletBatch="downloadBilletBatch"
      @handleShowAttachments="handleShowAttachments"
      @showModalEntityPayment="showModalEntityPayment"
      @handlePayOffPayment="handlePayOffPayment"
      @changeInstallmentStatus="changeInstallmentStatus"
      @handleEditBillReceive="handleEditBillReceive"
      @handleOpenModalInfo="handleOpenModalInfo"
      @handleShowHistory="handleShowHistory"
      @handleIdentifyEntity="handleIdentifyEntity"
      @showModalGenerateBillet="showModalGenerateBillet"
      @showModalGenerateBilletHasGenerated="showModalGenerateBilletHasGenerated"
      @postBatchUpdate="postBatchUpdate"
      @handleModalEntityBillReceiveIntegrationInfo="handleModalEntityBillReceiveIntegrationInfo"
      @handleDeleteBillReceive="handleDeleteBillReceive"
      @showModalEditGenerateBilletHasGenerated="showModalEditGenerateBilletHasGenerated"
      @showModalChangeDueDate="showModalChangeDueDate"
      :buildDropdownPossibleActions="buildDropdownPossibleActions"
      :showCompensationButtons="showCompensationButtons"
      :compareBalance="compareBalance"
    />
    <PuzlEmptyData v-if="!bill_receives.length && !loadingSkeleton"/>
    <!-- Modais de ListEntityBillReceive -->
    <ModalEntityHistory @updatedAfterAnticipationBalanceTransfer="updateAfterAnticipationBalanceTransfer" ref="modalEntityHistory" />
    <ModalEditEntity @updated="load()" ref="modalEditEntity"/>
    <ModalInfo ref="modalInfo"/>
    <ModalEntityRegistry ref="modalEntityRegistry" @storedEntityRegistry="storedAfterEntityRegistration" />
    <ModalHistory ref="ModalHistory"/>
    <ModalSearchEntity @setEntity="setEntity" ref="modalSearchEntity"/>
    <ModalGenerateBilletHasGenerated @fetch="load()" ref="modalGenerateBilletHasGenerated"/>
    <ModalEntityBillReceiveIntegrationInfo ref="modalEntityBillReceiveIntegrationInfo" />
    <ModalAttachment ref="modalAttachment"/>
    <ModalListContacts ref="listContacts"/>
    <ModalChangeDueDate @updated="load()" ref="ModalChangeDueDate"/>
    <ModalGenerateBillet ref="modalGenerateBillet"/>
    <EditBillReceive @updated="updateBillReceive" ref="editBillReceive"/>
    <ModalConfirmPayment :return_payment="false" @updateInstallment="updateInstallment" ref="modalConfirmPayment" />
    <ModalAlterStatus ref="ModalAlterStatus" :show="showModalAlterStatus" @close="closeModalAlterStatus" @saveAndClose="saveAndCloseAlterInstallment" />
  </div>
</template>
<script>
import Cards from "./Cards/_Cards";
import Table from "./Table/_Table";
import ModalEntityHistory from "@/views/Modules/Configuration/Entity/Shared/_ModalHistory";
import ModalAttachment from "../../../Shared/Modals/_ModalAttachment";
import ModalListContacts from "@/views/Modules/Operational/Schedule/Weighing/Shared/_ModalListContacts";
import ModalGenerateBillet from '../../../Shared/Modals/_ModalGenerateBillet';
import {BillReceiveInstallmentEnum} from "@/enum/BillReceiveInstallmentEnum";
import ModalConfirmPayment from "../Modals/_ModalEntityBillReceiveConfirmPayment";
import ModalAlterStatus from "../../../Shared/Modals/_ModalAlterStatus";
import EditBillReceive from "../../../Shared/Modals/_Edit";
import ModalEditEntity from '../Modals/_ModalEditEntity'
import ModalInfo from '../../../Shared/Modals/_ModalInfo.vue';
import ModalEntityRegistry from "@/views/Modules/Configuration/Entity/Shared/_ModalEntityRegistry";
import ModalHistory from "../../../Shared/Modals/_ModalHistory";
import ModalSearchEntity from '../Modals/_ModalSearchEntity';
import ModalGenerateBilletHasGenerated from '../../../Shared/Modals/_ModalGenerateBilletHasGenerated';
import ModalEntityBillReceiveIntegrationInfo from "@/views/Modules/Totvs/EntityBillReceive/_ModalEntityBillReceiveIntegrationInfo";
import ModalChangeDueDate from '../../../Shared/Modals/_ModalChangeDueDate.vue';
import PuzlEmptyData from "@/components/PuzlEmptyData";

export default {
  name: "ListingManager",
  props: {
    bill_receives: Array,
    loadingSkeleton: Boolean,
    listType: String,
  },
  components: {
    Cards,
    Table,
    ModalEntityHistory,
    ModalAttachment,
    ModalListContacts,
    ModalGenerateBillet,
    ModalConfirmPayment,
    ModalAlterStatus,
    EditBillReceive,
    ModalEditEntity,
    ModalInfo,
    ModalEntityRegistry,
    ModalHistory,
    ModalSearchEntity,
    ModalGenerateBilletHasGenerated,
    PuzlEmptyData,
    ModalEntityBillReceiveIntegrationInfo,
    ModalChangeDueDate
  },
  data(){
    return {
      BillReceiveInstallmentEnum: BillReceiveInstallmentEnum,
      showModalAlterStatus: false,
    }
  },
  methods: {
    load(){
      this.$emit('listItems');
    },
    setEntity(entity) {
      let loader = this.$loading.show()
      this.$Progress.start()
      let params = {
        entity_bill_receive_id: this.bill_receive_to_identify.entity_bill_receive_id,
        id: this.bill_receive_to_identify.id,
        entity_id: entity.entity.id
      }
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.$store.dispatch('billReceive/postIdentify', params).then(response => {
        loader.hide()
        this.$notify({type: response.error_type, message: response.message});
      }).catch((error) => {
        let errors = error.status
          ? error.data.message
          : formatErrorValidation(error.response.data.errors);
        this.$notify({type: "danger", message: errors});
      }).finally(() => {
        loader.hide()
        this.$Progress.finish()
      })
    },
    /**
     * Atualiza total do ícone de registros do cliente após realizar um cadastro.
     * @param {object} entityRegistry
     */
    storedAfterEntityRegistration(entityRegistry) {
      this.$store.dispatch('entity/getTotalRegistriesByEntityId', entityRegistry.entity_id).then(response => {
        let foundEntity = this.bill_receives.find((item) => item.entity_id == entityRegistry.entity_id);
        foundEntity.total_entity_registries = response.data.total_entity_registries;
      });
    },
    handleDeleteBillReceive(id) {
      this.$swal
        .fire({
          title: "Você tem certeza?",
          text:
            "Esta exclusão se remete a todas as parcelas desta solicitação, os registros não poderão ser recuperados!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, prosseguir!",
          cancelButtonText: "Não, mantenha",
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill"
          },
          buttonsStyling: false
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação."
            });
            this.$store
              .dispatch("billReceive/destroy", id)
              .then(response => {
                this.$Progress.finish();
                this.$notify({
                  type: response.error_type,
                  message: response.message
                });
                this.fetch();
              })
              .catch(error => {
                if (error.status == 200) {
                  this.$notify({
                    type: "danger",
                    message: error.data.message
                  });
                }
                this.$Progress.finish();
              });
          }
        });
    },
    showModalChangeDueDate(bill_receive){
      this.$refs.ModalChangeDueDate.handleCreateModal(bill_receive.installment_id, bill_receive.original_due_date)
    },
    showModalEditGenerateBilletHasGenerated(id, total_index, type, index) {
      let loader = this.$loading.show()
      this.$store.dispatch("billReceive/getInfoBillet", {id: id, type: type}).then(response => {
        const billet = response.data
        this.$store.dispatch("billReceive/show", id).then(response => {
          const bill_receive = response.data
          bill_receive.total_index = total_index
          this.$refs.modalGenerateBilletHasGenerated.openModal(id, billet, bill_receive, index)
          loader.hide()
        });
      });
    },
    handleModalEntityBillReceiveIntegrationInfo(entity_bill_receive_id) {
      this.$refs.modalEntityBillReceiveIntegrationInfo.openModal(entity_bill_receive_id);
    },
    /**
     * Requisitar atualização de lote de boletos
     * Método utilizado quando lote de boleto é gerado erroneamente
     * @param {*} bank_billet_batch_id
     * @returns {Promise}
    */
    postBatchUpdate(bank_billet_batch_id) {
      this.$Progress.start()
      let loader = this.$loading.show()
      this.$store.dispatch('bankBillet/postBatchUpdate', bank_billet_batch_id)
        .then((response) => {
          this.$notify({
            type: "info",
            message: "Requisição para atualizar lote enviada. Aguarde alguns minutos e efetue o download do lote."
          });
          loader.hide()
          this.$Progress.finish()
        }).catch((error) => {
        let errors = error.status
          ? error.data.message
          : formatErrorValidation(error.response.data.errors);
        this.$notify({type: "danger", message: errors});
        loader.hide();
        this.$Progress.finish();
      })
    },
    showModalGenerateBilletHasGenerated(id, total_index, type) {
      let loader = this.$loading.show()
      this.$store.dispatch("billReceive/getInfoBillet", {id: id, type: type}).then(response => {
        const billet = response.data
        this.$store.dispatch("billReceive/show", id).then(response => {
          const bill_receive = response.data
          bill_receive.total_index = total_index
          this.$refs.modalGenerateBilletHasGenerated.openModal(id, billet, bill_receive)
          loader.hide()
        });
      });
    },
    /**
     * Identifica depositos não identificados
     * @param bill_receive
     */
    handleIdentifyEntity(bill_receive) {
      this.bill_receive_to_identify = bill_receive
      this.$refs.modalSearchEntity.openModal(false)
    },
    handleShowHistory(bill_receive) {
      this.$refs.ModalHistory.openModal(bill_receive)
    },
    /**
     * Abre a modal registros do cliente
     * @param {number} entity_id
     * @param {string} entity_uuid
     * @param {string} name
     * @param {string} document
     */
    handleModalEntityRegistry(entity_id, entity_uuid, name, document) {
      this.$refs.modalEntityRegistry.handleCreateModal(entity_id, entity_uuid, name, document);
    },
    handleOpenModalInfo(bill_receive) {
      this.$refs.modalInfo.handleOpenModal(bill_receive)
    },
    handleEditBillReceive(bill_receive_id) {
      const bill_receive = this.bill_receives.find((item) => item.id === bill_receive_id)
      this.$refs.modalEditEntity.handleCreateModal(
        {
          id: bill_receive.entity_id,
          entity_name: bill_receive.entity_name,
          document: bill_receive.document
        },
        bill_receive_id
      )
    },
    /**
     * Abre a modal histórico do cliente
     * @param {number} entity_id
     * @param {string} entity_uuid
     * @param {string} entity_document
     * @param {string} entity_name
     */
     handleModalEntityHistory(entity_id, entity_uuid, entity_document, entity_name) {
      this.$refs.modalEntityHistory.openModal(
        entity_id,
        {
          id: entity_id,
          uuid: entity_uuid,
          document: entity_document,
          entity_name : entity_name,
        },
      );
    },
    /**
     * Atualiza os dados após a transferência de saldo de antecipação entre clientes/fornecedores (entity).
     * @param {number} from_entity_id - ID do cliente/fornecedor de origem.
     * @param {number} to_entity_id - ID do cliente/fornecedor de destino.
     */
     updateAfterAnticipationBalanceTransfer(from_entity_id, to_entity_id) {
      Promise.all([
        this.$store.dispatch('entity/getAnticipationBalanceById', from_entity_id),
        this.$store.dispatch('entity/getAnticipationBalanceById', to_entity_id),
      ]).then((response) => {
        // Atualiza saldo de antecipação dos cards
        const foundBillReceivesFromEntity = this.bill_receives.filter((item) => item.entity_id == from_entity_id);
        foundBillReceivesFromEntity.forEach((billReceive) => {
          billReceive.anticipation_balance = response[0].data;
        });

        const foundBillReceivesToEntity = this.bill_receives.filter((item) => item.entity_id == to_entity_id);
        foundBillReceivesToEntity.forEach((billReceive) => {
          billReceive.anticipation_balance = response[1].data;
        });
      }).catch((error) => {
        this.$refs.modalEntityHistory.closeModal();
        this.init({});
      });
    },
    /**
     * Faz o download da fatura.
     * @param {number} id - ID da primeira invoice.
     */
    downloadInvoice(id) {
      this.$Progress.start()
      let loader = this.$loading.show()
      this.$store.dispatch('billingInvoice/download', {
          id: id
        }
      ).then(response => {
        let blob = new Blob([response],
          {type: 'application/pdf'})
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute("download", 'report.pdf');
        window.open(link, '_blank')
        loader.hide()
        this.$Progress.finish()
      }).catch(error => {
        loader.hide()
        this.$Progress.finish()
      })
    },
    /**
     * Faz o download do PDF da NFSe.
     * @param {string} link - Link de download da NFSe.
     */
    nfseButtonClick(link) {
      this.$notify({type: 'success', message: 'Download iniciado com sucesso!'});
      window.open(link, '_parent');
    },
    downloadBilletBatch(bank_billet_batch_id, last_billet) {
      if (!bank_billet_batch_id) {
        return this.$notify({
          type: 'warning',
          message: 'Lote em processamento. Entre em contato com nosso suporte para mais informações!'
        });
      }
      if (last_billet.status === 0) {
       return this.$notify({
          type: 'warning',
          message: "Boleto em processo de emissão. Aguarde alguns instantes."
        });
      }
      this.$Progress.start()
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      let loader = this.$loading.show()
      this.$store.dispatch('bankBillet/getPdfFileUrl', bank_billet_batch_id)
        .then(async (response) => {
          let blob = new Blob([response], {type: "application/pdf"});
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", `Lote ${bank_billet_batch_id}.pdf`);
          await link.click();
          this.$snotify.success('Download iniciado com sucesso!', {
            timeout: 1000,
            icon: false,
            position: "centerBottom",
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false
          });
          loader.hide()
        });
    },
    showModalGenerateBillet(id, total_index, type) {
      let loader = this.$loading.show()
      this.$store.dispatch("billReceive/getInfoBillet", {id: id, type: type}).then(response => {
        const billet = response.data
        this.$store.dispatch("billReceive/show", id).then(response => {
          const bill_receive = response.data
          bill_receive.total_index = total_index
          this.$refs.modalGenerateBillet.openModal(id, billet, bill_receive)
          loader.hide()
        });
      });
    },
    async handleShowAttachments(bill_receive, index) {
      let loader = this.$loading.show()
      await this.getAttachments(bill_receive.id, bill_receive.type, index)
      const item = this.bill_receives[index]
      loader.hide()
      this.$refs.modalAttachment.openAttachment(item.attachments)
    },
    async getAttachments(id, type, index) {
      if (!this.bill_receives[index].attachments) {
        this.bill_receives[index].attachments = 1;
        await this.$store
          .dispatch("billReceive/getAttachments", {
            id: id,
            type: type
          })
          .then(response => {
            this.bill_receives[index].attachments = response.data;
            this.$forceUpdate();
          });
      }
    },
    /**
     * @param {string} construction_uuid
    */
    handleListContacts(construction_uuid) {
      this.$refs.listContacts.openModal(construction_uuid);
    },
    showCompensationButtons(bill_receive) {
      return [this.BillReceiveInstallmentEnum.PENDING, this.BillReceiveInstallmentEnum.OVERDUE].includes(bill_receive.status) &&
      (
        (
          bill_receive.type === 1 &&
          this.compareBalance(bill_receive.anticipation_balance, bill_receive.total_value) &&
          this.$hasPermission('bill_receive.receive_installment')
        ) || (
          this.$hasPermission('additional_permissions.financial_approvation') &&
          this.$helper.strToNum(bill_receive.anticipation_balance) > 0
        )
      );
    },
    /**
     * Compara o saldo de antecipação com o valor da fatura e retorna se o saldo é maior ou igual.
     * @param {string} anticipationBalance
     * @param {string} value
     * @returns {boolean}
     */
     compareBalance(anticipationBalance, value) {
      return this.$helper.strToNum(anticipationBalance) >= this.$helper.strToNum(value);
    },
    async showModalEntityPayment(id, type, status, installment_id) {
      await this.$refs.editBillReceive.handleCreateModal(id, type, status)
      const installment = this.$refs.editBillReceive.payload.installments.find((item) => item.id === installment_id)
      this.$refs.editBillReceive.showModalEntityPayment(installment)
    },
    async handlePayOffPayment(bill_receive) {
      let loader = this.$loading.show()
      const payment = await this.$store.dispatch('contractProposalPayment/show', bill_receive.payment_uuid).then(response => {
        return response.data
      });
      const entity = await this.$store.dispatch('entity/show', bill_receive.entity_uuid)
        .then(response => response.data)
      loader.hide()
      let use_parcial = null
      let sum_pending = payment.installments.filter((item) => item.status === 0 || item.status === 2)
      sum_pending = sum_pending.reduce((accumulator, currentValue) => {
        return Number(accumulator) + Number(currentValue.total_value);
      }, 0);
      if (sum_pending > Number(entity.anticipation_balance)) {
        use_parcial = 1
      }
      let has_balance_to_paid = payment.installments
        .filter((item) => [0, 2].includes(item.status) && Number(item.value) < Number(entity.anticipation_balance))

      if (!has_balance_to_paid) {
        this.$notify({
          type: "danger",
          message: "Não há saldo de antecipação suficiente para recebimento das parcelas."
        });
      }
      if (has_balance_to_paid && !use_parcial) {
        use_parcial = 0
      }
      let text = undefined
      if (use_parcial) {
        text = 'Não há saldo total para recebimento de todas as parcelas, deseja continuar? o recebimento será feito parcialmente'
      } else {
        text = 'Todas as parcelas pendentes serão recebidas, deseja continuar?'
      }
      this.$Swal.confirmAction(`${text}`, ['Sim', 'Não'])
        .then(async (result) => {
          if (result.isConfirmed) {
            let loader = this.$loading.show()
            this.$store.dispatch('contractProposalPayment/postPayOffPayment', payment.id).then(async response => {
              this.updateBillReceive(bill_receive.id)
              loader.hide()
            }).catch(error => {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              });
              loader.hide()
            })
          }
        })
    },
    async updateBillReceive(id) {
      const index = this.bill_receives.findIndex(bill_receive => bill_receive.id === id)
      const updated = await this.$store.dispatch('billReceive/showEqualList', id)
      this.bill_receives[index] = updated.data
      this.$forceUpdate()
    },
    buildDropdownPossibleActions(bill_receive){
      if(!bill_receive) return;

      const billReceiveActionsEnum = {
        [this.BillReceiveInstallmentEnum.PENDING]: {
          name: "PENDENTE",
          action: "PENDENTE"
        },
        [this.BillReceiveInstallmentEnum.RECEIVED]: {
          name: "RECEBIDO",
          action: "RECEBER"
        },
        [this.BillReceiveInstallmentEnum.OVERDUE]: {
          name: "ATRASADO",
          action: ""
        },
        [this.BillReceiveInstallmentEnum.CANCELED]: {
          name: "CANCELADO",
          action: "CANCELAR"
        },
        [this.BillReceiveInstallmentEnum.SERASA]: {
          name: "SERASA",
          action: "SERASA"
        },
        [this.BillReceiveInstallmentEnum.REGISTRY]: {
          name: "CARTÓRIO",
          action: "CARTÓRIO"
        },
        [this.BillReceiveInstallmentEnum.PROTESTED]: {
          name: "PROTESTADO",
          action: "PROTESTAR"
        },
        [this.BillReceiveInstallmentEnum.LEGAL]: {
          name: "JURÍDICO",
          action: "JURÍDICO"
        },
        [this.BillReceiveInstallmentEnum.APPROVED]: {
          name: "APROVADO",
          action: "APROVAR"
        },
        [this.BillReceiveInstallmentEnum.DENIED]: {
          name: "NEGADO",
          action: "NEGAR"
        },
        [this.BillReceiveInstallmentEnum.REVERSED]: {
          name: "",
          action: "ESTORNAR"
        },
      };

      let dropdown = {
        bill_receive_status: {
          id: bill_receive.status,
          name: billReceiveActionsEnum[bill_receive.status].name
        },
        items: []
      };

      const addItem = (id, permission) => {
        if (this.$hasPermission(permission) || !permission) {
          dropdown.items.push({ id, name: billReceiveActionsEnum[id].action });
        }
      };

      switch (bill_receive.status) {
        // Status RECEBIDO, mostra ações: [ESTORNAR]
        case this.BillReceiveInstallmentEnum.RECEIVED:
          addItem(this.BillReceiveInstallmentEnum.REVERSED, 'bill_receive.reverse_installment');
          break;

        // Status APROVADO, mostra ações: [RECEBER, CANCELAR]
        case this.BillReceiveInstallmentEnum.APPROVED:
          addItem(this.BillReceiveInstallmentEnum.RECEIVED, 'bill_receive.receive_installment');
          addItem(this.BillReceiveInstallmentEnum.CANCELED, 'bill_receive.cancel_installment');
          break;

        // Status NEGADO ou CANCELADO, mostra ações: []
        case this.BillReceiveInstallmentEnum.DENIED:
        case this.BillReceiveInstallmentEnum.CANCELED:
          dropdown.items = [];
          break;

        default:
          if (
              ![
                this.BillReceiveInstallmentEnum.APPROVED,
                this.BillReceiveInstallmentEnum.DENIED,
                this.BillReceiveInstallmentEnum.CANCELED,
                this.BillReceiveInstallmentEnum.RECEIVED
              ].includes(bill_receive.status)
            )
          {

            // Se tiver ATRASADO, esconde opção PENDENTE
            if (bill_receive.status !== this.BillReceiveInstallmentEnum.OVERDUE && bill_receive.status !== this.BillReceiveInstallmentEnum.PENDING) {
              addItem(this.BillReceiveInstallmentEnum.PENDING, null);
            }

            // Disponível para todos os status diferente de APROVADO, NEGADO, CANCELADO e RECEBIDO
            // Disponível também para tanto tipo 0 (ñ identificado) ou 1 (identificado)
            addItem(this.BillReceiveInstallmentEnum.RECEIVED, 'bill_receive.receive_installment');
            addItem(this.BillReceiveInstallmentEnum.CANCELED, 'bill_receive.cancel_installment');

            if(bill_receive.type === 0)
            {
              // RESULTADO FINAL, se STATUS for diferente de APROVADO, NEGADO, CANCELADO e RECEBIDO e TIPO 0 (ñ identificado)
              // exibe ações: RECEBER, CANCELAR e APROVAR (PENDENTE tb caso ñ esteja atrasado)
              if(
                [
                  this.BillReceiveInstallmentEnum.PENDING,
                  this.BillReceiveInstallmentEnum.OVERDUE
                ].includes(bill_receive.status)
                && bill_receive.allow_approve
              ){
                addItem(this.BillReceiveInstallmentEnum.APPROVED, 'bill_receive.approve_installment');
                addItem(this.BillReceiveInstallmentEnum.DENIED, 'bill_receive.approve_installment');
              }
            } else {
              // RESULTADO FINAL, se STATUS for diferente de APROVADO, NEGADO, CANCELADO e RECEBIDO e TIPO 1 (identificado)
              // exibe ações: RECEBER, CANCELAR, SERASA, CARTÓRIO, PROTESTAR E JURÍDICO (PENDENTE tb caso ñ esteja atrasado)
              addItem(this.BillReceiveInstallmentEnum.SERASA, 'bill_receive.receive_installment');
              addItem(this.BillReceiveInstallmentEnum.REGISTRY, 'bill_receive.receive_installment');
              addItem(this.BillReceiveInstallmentEnum.PROTESTED, 'bill_receive.receive_installment');
              addItem(this.BillReceiveInstallmentEnum.LEGAL, 'bill_receive.receive_installment');
            }
          }
          break;
      }

      return dropdown;
    },
    changeInstallmentStatus(id, type, status, index, unique = 0) {
      status = parseInt(status)
      if (this.bill_receives[index].last_billet && this.bill_receives[index].last_billet.status === 1 && status === 1) {
        return this.$Swal.confirmAction('O Boleto já foi emitido para esta parcela, se continuar, o boleto será cancelado.', ['Sim', 'Não'])
          .then((result) => {
            if (result.isConfirmed) {
              let loader = this.$loading.show()
              return Promise.all([
                this.$store.dispatch("billReceive/showLight", this.bill_receives[index].id),
                this.$store.dispatch("companyPlantBankAccount/getByCompanyPlant", {allocated: 1, company_plant_id: this.bill_receives[index].company_plant_id})
              ]).then(() => {
                loader.hide()
                const total_value = this.bill_receives[index].total_value
                return this.$refs.modalConfirmPayment.handleCreateModal(id, null, total_value, true, 1)
              })
            }
          }).catch()
      }
      if (this.loadingInstallmentStatus) {
        return;
      }
      // if (this.bill_receives[index].last_billet.status === 3 && status != 1) {
      //   this.$notify({
      //     type: "warning",
      //     message: "Não foi possível alterar o status. Há uma parcela com pagamento confirmado para este pagamento"
      //   });
      //   return;
      // }
      if (parseInt(status) === 1) {
        let loader = this.$loading.show()
        return Promise.all([
          this.$store.dispatch("billReceive/showLight", this.bill_receives[index].id),
          this.$store.dispatch("companyPlantBankAccount/getByCompanyPlant", {allocated: 1, company_plant_id: this.bill_receives[index].company_plant_id})
        ]).then(() => {
          loader.hide()
          const total_value = this.bill_receives[index].total_value;
          const disabledChangeBankAccount = (this.bill_receives[index].billets.length > 0);
          return this.$refs.modalConfirmPayment.handleCreateModal(id, null, total_value, disabledChangeBankAccount)
        })
      }
      this.loadingInstallmentStatus = true;
      if (unique || ![1, 8, 9].includes(parseInt(status))) {
        this.showModalAlterStatus = true
        return this.$refs.ModalAlterStatus.setBillReceive({
          id: id,
          type: type,
          status: status,
          index: index,
          received_at: this.bill_receives[index].paid_at ? this.bill_receives[index].paid_at : null
        })
      }
      this.$Progress.start();
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      let params = {
        id: id,
        status: status,
        type: type
      };
      this.$store
        .dispatch("billReceive/changeInstallmentStatus", params)
        .then(response => {
          this.bill_receives[index].status = parseInt(status);
          if (status == 1) {
            this.bill_receives[index].paid_at = new Date();
          }
          this.loadingInstallmentStatus = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.init({})
        })
        .catch(error => {
          this.$notify({
            type: error.data.error_type,
            message: error.data.message
          });
          if (error.data.open_modal_edit) {
            const bill_receive = this.bill_receives.find((item) => item.installment_id == id)
            this.handleEditBillReceive(
              bill_receive.contract_proposal_payment_uuid, bill_receive.contract_proposal_uuid,
            )
          }
          this.loadingInstallmentStatus = false;
          this.$Progress.finish();
        });
    },
    updateInstallment(installment) {
      const index = this.bill_receives.findIndex(item => item.installment_id === installment.data.installment_id)
      this.bill_receives[index].status = installment.data.status
      this.bill_receives[index].bank_account_name = installment.data.bank_account_name
      this.bill_receives[index].paid_at = installment.data.paid_at
      this.$forceUpdate()
    },
    closeModalAlterStatus() {
      this.loadingInstallmentStatus = false
      this.showModalAlterStatus = false
    },
    saveAndCloseAlterInstallment(params) {
      this.loadingInstallmentStatus = false
      if (params.status == 3) {
        this.init({})
      }
      this.bill_receives[params.index].status = parseInt(params.status);
      this.showModalAlterStatus = false
    },
  },
  mounted() {}
}
</script>
<style>

</style>
