<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <div class="p-2">
          <!-- Campos -->
          <section>
            <div class="form-group row m-0 p-0 mb-4">
              <label class="col-md-5 pl-0 pt-2 pb-0 col-form-label form-control-label">
                Adquirente <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7 p-0">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                    <puzl-select
                      :loading="loadingPaymentIntermediary"
                      :clearable="false"
                      v-model="payment_intermediary_id"
                      :items="$_payment_intermediares"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <InputFile
              ref="file"
              errorTextType="Tipo de arquivo inválido. Apenas arquivos de texto com extensão .txt são permitidos"
              accept=".txt"
              :multiple="false"
            />
          </section>

          <div class="modal-footer p-2 mt-3">
            <base-button type="secondary" @click="closeModal()">Cancelar</base-button>
            <base-button :disabled="!payment_intermediary_id" type="success" @click.prevent="store" native-type="button"
              >Enviar arquivo</base-button
            >
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import InputFile from "@/components/Utils/InputFile";
import { formatErrorValidation } from "@/plugins";
import PuzlSelect from "@/components/PuzlSelect";
import { mapGetters } from "vuex";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzl from "@/components/SkeletonPuzl.vue";
import Pagination from "@/components/Utils/Pagination";

export default {
  name: "ModalImportTextFile",
  components: { InputFile, PuzlSelect, PuzlEmptyData, SkeletonPuzl, Pagination },
  data() {
    return {
      modal: {
        title: "Arquivo de extrato eletrônico",
        create: false,
      },
      payment_intermediary_id: null,
      loadingPaymentIntermediary: true,
    };
  },
  computed: {
    ...mapGetters({
      $_payment_intermediares: "paymentIntermediary/fetch",
    }),
  },
  methods: {
    /**
     * Fecha modal
     *
     * @returns void
     */
    closeModal() {
      this.modal.create = false;
    },

    /**
     * Abre modal
     *
     * @returns void
     */
    openModal() {
      this.payment_intermediary_id = null;
      this.modal.create = true;
    },

    /**
     * Persiste os dados
     *
     * @returns void
     */
    store() {
      if (!this.$refs.file || !this.$refs.file.fileRecords[0] || !this.$refs.file.fileRecords[0].file) {
        return this.$notify({
          type: "danger",
          message: "Anexe um arquivo para continuar!",
        });
      }
      const loader = this.$loading.show();
      const formData = new FormData();
      formData.append("txt_file", this.$refs.file.fileRecords[0].file);
      formData.append("payment_intermediary_id", this.payment_intermediary_id);
      this.$store
        .dispatch("electronicBankStatement/add", formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$refs.file.fileRecords = [];
          this.closeModal();
          this.$emit('created');
        })
        .catch((error) => {
          if (error.status) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response && error.response.status && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
  mounted() {
    this.loadingPaymentIntermediary = true;
    this.$store.dispatch("paymentIntermediary/fetchItems").then(() => {
      this.loadingPaymentIntermediary = false;
    });
  },
};
</script>
