<template>
  <div>
    <modal :borderInfo="'2px solid #1b6eba'" :show.sync="modal" size="md">
      <template slot="header">
        <div style="display: flex;align-items: center;">
          <img style="display: inline-flex;" src="/img/icons/icons8/ios/rubber-stamp.png" width="22">
          <h5 style="display: inline-flex;" class="modal-title p-0 m-0 ml-3 text-white">{{ title }}</h5>
        </div>
      </template>
      <div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12" style="display: flex; align-items: center;">
              <img src="/img/icons/icons8/ios/info-squared.png" width="18">
              <h4 class="new-default-black-font m-0 mx-3 ">
                Informação
              </h4>
            </div>
          </div>
          <div class="row mt-3 align-items-center">
            <div class="col-6">
              <h4 class="new-default-black-font m-0">
                {{ contract_proposal.code }}
              </h4>
            </div>
            <div class="col-6 text-right">
              <el-popover trigger="click" placement="bottom" class="p-0">
                <span style="width: 180px;" class="row">
                  <div class="col-12 mb-2" style="display: flex; align-items: center;">
                    <img class="mr-2" src="/img/icons/salesman.png" width="22">
                    <h4 style="font-size: 16px;" class="p-0 m-0">Vendedor</h4>
                  </div>
                  <div class="col-12 mt-1">
                    <h5 class="font-weight-normal">{{ contract_proposal.seller_name }}</h5>
                  </div>
                </span>
                <base-button outline slot="reference" size="sm" type="secundary"
                             class="p-0 ml-2 shadow-none text-indigo">
                  <img src="/img/icons/salesman.png" width="26">
                </base-button>
              </el-popover>
              <el-popover trigger="click" placement="bottom" class="p-0">
                <div class="row" v-if="contract_proposal.service_receiver">
                  <div class="mt-n2 p-4">
                    <h3 slot="title">
                      <img class="mr-1" src="/img/icons/icons8/ios/contract-job_primary.png" width="23">
                      Dados do cliente
                    </h3>
                    <div class="row align-items-center pb-0 mb-3">
                      <div class="col-md-12">
                        <span class="mt-4 p">
                          <span class="h4 small">
                            NOME: {{ contract_proposal.service_receiver.entity_name || '' }}
                          </span><br/>
                          <span class="h4 small">
                            CNPJ/CPF: {{ contract_proposal.service_receiver.document || '' }}
                          </span>
                        </span>
                        <br/>
                        <span class="mt-4 p">
                          <span class="h4 small">
                            ENDEREÇO: {{ contract_proposal.service_receiver.address.city }}
                            /
                            {{ contract_proposal.service_receiver.address.state }}
                          </span>
                          <br/>
                          <span class="h4 small">
                            {{ contract_proposal.service_receiver.address.address || '' }},
                            {{ contract_proposal.service_receiver.address.number || '' }},
                            {{ contract_proposal.service_receiver.address.district || '' }}
                          </span>
                        </span>
                        <br/>
                        <span class="mt-4 p">
                          <span class="h4 small">
                          {{ contract_proposal.service_receiver.address.postal_code || '' }}
                          </span>
                        </span>
                      </div>
                    </div>
                    <hr class="blue-divider my-0">
                    <h3 slot="title" class="mt-2">
                      <img class="mr-1" src="/img/icons/icons8/ios/brick-wall_warning.png" width="23">
                      Dados da Obra
                    </h3>
                    <div class="row align-items-center pb-0">
                      <div class="col-md-12">
                        <span class="mt-4 p">
                          <span class="h4 small">
                            NOME: {{ contract_proposal.code }}
                          </span>
                        </span>
                        <br/>
                        <span v-if="contract_proposal.construction.estimated_volume" class="mt-4 p">
                          <span class="h4 small">
                            VOLUME PREVISTO: {{ contract_proposal.construction.estimated_volume || '-' }}
                            <small>m<sup>3</sup></small>
                          </span>
                        </span>
                        <br v-if="contract_proposal.construction.estimated_volume"/>
                        <span class="mt-4 p">
                          <span class="h4 small">
                            ENDEREÇO: {{ contract_proposal.construction.default_address.city }}
                            /
                            {{ contract_proposal.construction.default_address.state }}
                          </span>
                          <br/>
                          <span class="h4 small">
                            {{ contract_proposal.construction.default_address.address || '' }},
                            {{ contract_proposal.construction.default_address.number || '' }},
                            {{ contract_proposal.construction.default_address.district || '' }}
                          </span>
                        </span>
                        <br/>
                        <span class="mt-4 p">
                          <span class="h4 small">
                          {{ contract_proposal.construction.default_address.postal_code || '' }}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <base-button outline slot="reference" size="sm" type="secundary"
                             class="p-0 ml-2 shadow-none text-indigo">
                  <img src="/img/icons/icons8/ios/info-circle_warning.png" width="26">
                </base-button>
              </el-popover>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <h4 class="new-default-gray-font m-0 font-weight-normal">
                {{ contract_proposal.construction.customer_construction.customer_name }}
              </h4>
              <h4 class="new-default-gray-font m-0 font-weight-normal">
                {{ contract_proposal.construction.construction_name }}
              </h4>
            </div>
          </div>
          <hr class="my-0">
          <div class="row mt-3">
            <div class="col-12" style="display: flex; align-items: center;">
              <img src="/img/icons/icons8/ios/test-partial-passed_primary.png" width="20">
              <h4 class="new-default-black-font m-0 mx-3 ">
                Aprovar todos
              </h4>
            </div>
          </div>
          <div class="row mt-4">
            <div
              class="col pointer pr-1">
              <a class="card-with-box-shadow text-center" style="display: inherit;"
                 :class="approveAll === 1 && 'card-wbs-success'"
                 @click.prevent="!validApproval() ? approveAll = 1 : false">
                <div class="py-2" style="display: inline-flex;">
                  <div style="display: flex;align-items: center;">
                    <img class="mr-1" width="16"
                         src="/img/icons/icons8/ios/thumb-up_success.png"/>
                    <i :class="approveAll === 1 ? 'text-success' : 'new-default-black-font'"
                       style="font-style: normal;font-size: 11px;">
                      Aprovar
                    </i>
                  </div>
                </div>
              </a>
            </div>
            <div
              class="col pointer px-1">
              <a class="card-with-box-shadow text-center" style="display: inherit;"
                 :class="approveAll === 0 && 'card-wbs-danger'"
                 @click.prevent="!validApproval() ? approveAll = 0 : false">
                <div class="py-2" style="display: inline-flex;">
                  <div style="display: flex;align-items: center;">
                    <img class="mr-1" width="16"
                         src="/img/icons/icons8/ios/thumbs-down_danger.png"/>
                    <i :class="approveAll === 0 ? 'text-danger' : 'new-default-black-font'"
                       style="font-style: normal;font-size: 11px;">
                      Reprovar
                    </i>
                  </div>
                </div>
              </a>
            </div>
            <div
              class="col pointer pl-1">
              <a class="card-with-box-shadow text-center" style="display: inherit;"
                 :class="approveAll === 2 && 'card-wbs-warning'"
                 @click.prevent="!validApproval() ? approveAll = 2 : false">
                <div class="py-2" style="display: inline-flex;">
                  <div style="display: flex;align-items: center;">
                    <img class="mr-1" width="16"
                         src="/img/icons/icons8/ios/thumbs-up-down_warning.png"/>
                    <i :class="approveAll === 2 ? 'text-warning' : 'new-default-black-font'"
                       style="font-style: normal;font-size: 11px;">
                      Revisar
                    </i>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="row mt-2" v-if="approveAll !== false">
            <div class="col-12">
              <h4 class="new-default-black-font">
                Observações
              </h4>
            </div>
            <div class="col-12">
              <base-input input-group-classes="input-group-sm">
                <textarea type="text" v-model="observation" rows="4"
                          class="form-control form-control-sm" placeholder=" ...">
                </textarea>
              </base-input>
            </div>
            <div class="col-12 mt-3 text-right">
              <base-button type="danger"
                           size="sm"
                           style="width: 150px;" @click.prevent="approveAll = false">
                <div style="display: inline-flex; align-items: center;">
                  <img src="/img/icons/cancel-white.png" width="20">
                  <i class="m-0 ml-1" style="font-style: normal;">
                    Cancelar
                  </i>
                </div>
              </base-button>
              <base-button type="success"
                           size="sm"
                           style="width: 150px;" @click.prevent="updateAllSituation(approveAll)">
                <div style="display: inline-flex; align-items: center;">
                  <img src="/img/icons/save.png" width="20">
                  <i class="m-0 ml-1" style="font-style: normal;">
                    Salvar
                  </i>
                </div>
              </base-button>
            </div>
          </div>
          <div class="row mt-3" v-if="without_status.formulations.length">
            <div class="col-12" style="display: flex; align-items: center;">
              <img src="/img/icons/test-tube.png" width="20">
              <h4 class="new-default-black-font m-0 mx-3 ">
                Concreto
              </h4>
            </div>
          </div>
          <div class="row mt-3 align-items-center" v-if="without_status.formulations.length">
            <div class="col-12">
              <div class="card main-card" v-for="(mix_design, indexFormulation) in without_status.formulations"
                   style="overflow: hidden;border-radius: 20px !important">
                <div class="p-3 px-4">
                  <div class="row">
                    <div class="col-8">
                      <div class="row">
                        <div class="col-12" v-if="mix_design.delivery_type !== null">
                          <h4 class="new-default-black-font m-0">
                            {{ delivery_types[mix_design.delivery_type - 1].label }}
                          </h4>
                        </div>
                        <div class="col-12">
                          <h5 class="new-default-black-font font-weight-normal m-0">
                            {{ contract_proposal.plant.name }}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="float-right" style="display: flex; align-items: center;">
                        <h5 class="font-weight-normal new-default-black-font m-0 mr-2" style="font-size: 11px;">
                          Retira:
                        </h5>
                        <h5 class="m-0" :class="mix_design.concrete_removal ? 'text-success' : 'text-danger'">
                          {{ mix_design.concrete_removal ? 'Sim' : 'Não' }}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12">
                      <div class="mt-n1 mb-1">
                        <button type="button"
                                class="btn base-button fs-11 new-btn-light new-default-black-font btn-light btn-sm"
                                style="background: #1b52bad1 !important;color: white !important;border: 1px solid #dcd5d5;margin-top: -5px">
                          V.{{ mix_design.version }}
                        </button>
                        <button v-if="mix_design.mix_design_integration_mixe_id" type="button"
                                class="btn base-button fs-11 new-btn-light new-default-black-font btn-light btn-sm"
                                style="background: #1b52bad1 !important;color: white !important;border: 1px solid #dcd5d5;margin-top: -5px">
                          TR-{{ mix_design.mix_design_integration_mixe_id }}
                        </button>
                      </div>
                      <h4 v-for="feature in mix_design.features" :key="feature.id"
                          class="new-default-black-font m-0">
                        {{ feature.feature.short_description }}
                        {{ format_mixe(feature.feature.period, feature.feature.period_type) }}
                        {{ feature.description }}
                        {{ feature.letter }}
                      </h4>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <h4 class="new-default-black-font font-weight-normal m-0">
                        {{ mix_design.type.technical_description }}
                        {{ mix_design.subtype ? '|' + mix_design.subtype.technical_description : '' }}
                        |
                        {{ mix_design.slump_flow.nomenclature }}
                        |
                        {{ mix_design.maximum_size.description }}
                      </h4>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-12">
                      <h5 class="new-default-black-font font-weight-normal m-0">
                        {{ mix_design.footer ? mix_design.footer : 'n/a' }}
                      </h5>
                    </div>
                  </div>
                  <div class="row mt-3 align-items-center">
                    <div class="col-8">
                      <h5 class="font-weight-normal new-default-gray-font m-0">
                        Ciclo total: {{ mix_design.cycle.total_cycle }} Minutos | {{ mix_design.cycle.distance }} Km
                      </h5>
                    </div>
                    <div class="col-4 text-right"
                         v-if="$hasPermission('additional_permissions.contract-proposal-formulation.details.view')">
                      <el-popover trigger="click" placement="left" class="p-0 pr-0">
                        <span style="width: 290px; word-break: break-word;"
                              class="row">
                          <div class="col-12 mb-2" style="display: flex; align-items: center;">
                            <img class="mr-2" src="/img/icons/icons8/puzzle.png" width="22">
                            <h4 style="font-size: 16px;" class="p-0 m-0">Peças</h4>
                          </div>
                          <div class="col-12 mt-1">
                            <h5 class="font-weight-normal new-default-gray-font m-0"
                                style="font-size: 11px;"
                                v-for="(item, index) in mix_design.concretedPiece">
                              {{ index + 1 }}.
                              {{ item.description }}
                            </h5>
                          </div>
                        </span>
                        <base-button outline slot="reference" size="sm" type="secundary"
                                     class="p-0 ml-1 shadow-none text-indigo">
                          <img src="/img/icons/icons8/puzzle.png" width="22">
                        </base-button>
                      </el-popover>
                      <el-popover trigger="click" placement="left" class="p-0 pr-0">
                        <span style="width: 290px; word-break: break-word;"
                              class="row">
                          <div class="col-12 mb-2" style="display: flex; align-items: center;">
                            <h4 style="font-size: 16px;" class="p-0 m-0">Dados técnicos</h4>
                          </div>
                          <div class="col-12 mt-1">
                            <h5 class="font-weight-normal new-default-gray-font m-0"
                                style="font-size: 11px;"
                            >
                              {{ mix_design.thecnical_data }}
                            </h5>
                          </div>
                        </span>
                        <base-button v-if="mix_design.thecnical_data" outline slot="reference" size="sm"
                                     type="secundary"
                                     class="p-0 ml-1 shadow-none text-indigo">
                          <img src="/img/icons/physics.png" width="22">
                        </base-button>
                      </el-popover>
                      <el-popover v-if="mix_design" trigger="click" placement="bottom"
                                  class="p-0">
                        <h5 class="text-dark">
                          Preço mínimo atual:
                        </h5>
                        <h4 class="text-default" v-if="mix_design.minimum_price_atual">
                          <span class="">
                            {{ toMoney(mix_design.minimum_price_atual) }} /
                            <span style="text-transform: none">m</span><sup>3</sup>
                          </span>
                          <span class="small"
                                :class="mix_design.minimum_price_atual_pct > 0 ? 'text-success' : 'text-danger'">
                            ({{
                              toMoney(mix_design.minimum_price_atual_pct)
                            }})
                          </span>
                        </h4>
                        <h4 v-else class="text-muted">
                          Traço não encontrado
                        </h4>
                        <h4 class="text-left mt-n2">
                          {{
                            calculateGrossMargin(mix_design.value, mix_design.mcc_atual, unformatTax(mix_design.concrete_price.tax_name)) | currency()
                          }}
                          <small>(Margem c/imposto)</small>
                        </h4>
                        <h4 class="text-left mt-n2" :class="validAtualPrice(mix_design)">
                          {{
                            mix_design.value - mix_design.mcc_atual - mix_design.puzl_addition_price_without_addition | currency
                          }} <small>/ m³ (Margem bruta)</small></h4>
                        <h4 v-if="mix_design" class="text-left text-primary mt-n2"
                            :class="validAtualPriceByHour(mix_design)">
                          {{ calcServiceHour(mix_design, mix_design.mcc_atual) | currency }} <small>/ hora
                          (Margem
                          atual)</small></h4>
                        <hr v-if="mix_design.feature_level_selected" class="mb-2 mt-1">
                        <base-input input-group-classes="input-group-sm">
                          <puzl-select :clearable="false"
                                       @change="changeFeatureLevel(mix_design)"
                                       :loading="loadingFeatureLevels"
                                       v-model="mix_design.feature_level"
                                       :items="getFeatureLevels(mix_design.level_features)"
                                       customKey="value" label="label"/>
                        </base-input>
                        <base-button outline slot="reference" size="sm" type="secundary"
                                     class="p-0 ml-1 shadow-none text-indigo">
                          <img src="/img/icons/price-tag-usd-green.png" width="22">
                        </base-button>
                      </el-popover>
                    </div>
                  </div>
                  <div class="row mt-4 mb-3">
                    <div class="col-6">
                      <h5 class="font-weight-normal new-default-gray-font m-0"
                          style="font-size: 10px;">
                        Venda
                      </h5>
                    </div>
                    <div class="col-6 text-right">
                      <h5 class="font-weight-normal new-default-gray-font m-0"
                          style="font-size: 10px;">
                        Volume previsto total
                      </h5>
                    </div>
                    <div class="col-6 pr-0">
                      <h3 class="new-default-black-font m-0">
                        {{ $helper.toMoney(mix_design.value) }} /m<sup>3</sup>
                      </h3>
                    </div>
                    <div class="col-6 text-right pl-0">
                      <h3 class="new-default-black-font m-0">
                        {{ parseFloat(mix_design.expected_volume).toLocaleString('pt-BR', {minimumFractionDigits: 1}) }}
                        m³
                        |
                        {{ parseFloat(mix_design.value * mix_design.expected_volume) | currency() }}
                      </h3>
                    </div>
                  </div>
                  <hr class="m-0">
                  <div class="row mt-3">
                    <div @click.prevent="approveFormulationSet(mix_design.uuid, 1)"
                         class="col pointer pr-1">
                      <a class="card-with-box-shadow text-center" style="display: inherit;"
                         :class="approveFormulation.hasOwnProperty(mix_design.uuid) && approveFormulation[mix_design.uuid] === 1 && 'card-wbs-success'">
                        <div class="py-2" style="display: inline-flex;">
                          <div style="display: flex;align-items: center;">
                            <img class="mr-1" width="16"
                                 src="/img/icons/icons8/ios/thumb-up_success.png"/>
                            <i
                              :class="approveFormulation.hasOwnProperty(mix_design.uuid) && approveFormulation[mix_design.uuid] === 1 ? 'text-success' : 'new-default-black-font'"
                              style="font-style: normal;font-size: 11px;">
                              Aprovar
                            </i>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div @click.prevent="approveFormulationSet(mix_design.uuid, 0)"
                         class="col pointer px-1">
                      <a class="card-with-box-shadow text-center" style="display: inherit;"
                         :class="approveFormulation.hasOwnProperty(mix_design.uuid) && approveFormulation[mix_design.uuid] === 0 && 'card-wbs-danger'">
                        <div class="py-2" style="display: inline-flex;">
                          <div style="display: flex;align-items: center;">
                            <img class="mr-1" width="16"
                                 src="/img/icons/icons8/ios/thumbs-down_danger.png"/>
                            <i
                              :class="approveFormulation.hasOwnProperty(mix_design.uuid) && approveFormulation[mix_design.uuid] === 0 ? 'text-danger' : 'new-default-black-font'"
                              style="font-style: normal;font-size: 11px;">
                              Reprovar
                            </i>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div @click.prevent="approveFormulationSet(mix_design.uuid, 2)"
                         class="col pointer pl-1">
                      <a class="card-with-box-shadow text-center" style="display: inherit;"
                         :class="approveFormulation.hasOwnProperty(mix_design.uuid) && approveFormulation[mix_design.uuid] === 2 && 'card-wbs-warning'">
                        <div class="py-2" style="display: inline-flex;">
                          <div style="display: flex;align-items: center;">
                            <img class="mr-1" width="16"
                                 src="/img/icons/icons8/ios/thumbs-up-down_warning.png"/>
                            <i
                              :class="approveFormulation.hasOwnProperty(mix_design.uuid) && approveFormulation[mix_design.uuid] === 2 ? 'text-warning' : 'new-default-black-font'"
                              style="font-style: normal;font-size: 11px;">
                              Revisar
                            </i>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div v-if="approveFormulation.hasOwnProperty(mix_design.uuid)" class="row mt-2">
                    <div class="col-12">
                      <h4 class="new-default-black-font">
                        Observações
                      </h4>
                    </div>
                    <div class="col-12">
                      <base-input input-group-classes="input-group-sm">
                        <textarea type="text" v-model="observationFormulation" rows="4"
                                  class="form-control form-control-sm" placeholder=" ...">
                        </textarea>
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3" v-if="approveFormulation.hasOwnProperty(mix_design.uuid)">
                    <div class="col-6 pr-1">
                      <base-button type="danger" block
                                   size="sm" @click.prevent="removeApprovationFormulation()">
                        <div style="display: inline-flex; align-items: center;">
                          <img src="/img/icons/cancel-white.png" width="20">
                          <i class="m-0 ml-1" style="font-style: normal;">
                            Cancelar
                          </i>
                        </div>
                      </base-button>
                    </div>
                    <div class="col-6 pl-1">
                      <base-button type="success" block
                                   size="sm"
                                   @click.prevent="updateFormulation(mix_design, approveFormulation[mix_design.uuid], indexFormulation)">
                        <div style="display: inline-flex; align-items: center;">
                          <img src="/img/icons/save.png" width="20">
                          <i class="m-0 ml-1" style="font-style: normal;">
                            Salvar
                          </i>
                        </div>
                      </base-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" :class="without_status.formulations.length ? 'mt-0' : 'mt-3'"
               v-if="without_status.services.length">
            <div class="col-12" style="display: flex; align-items: center;">
              <img src="/img/icons/todo-list-dark.png" width="20">
              <h4 class="new-default-black-font m-0 mx-3 ">
                Serviços
              </h4>
            </div>
          </div>
          <div class="row mt-3 align-items-center" v-if="without_status.services.length">
            <div class="col-12">
              <div class="card main-card" v-for="(service, indexService) in without_status.services"
                   style="overflow: hidden;border-radius: 20px !important">
                <div class="p-3 px-4">
                  <div class="row">
                    <div class="col-12">
                      <h4 class="new-default-black-font font-weight-500 mb-0">
                        {{ service.service.name }}
                      </h4>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 my-1">
                      <h5 class="new-default-gray-font font-weight-normal" style="display: flex;align-items: center;">
                        {{ service.service.description }}
                      </h5>
                      <i style="font-style: normal; font-size: 13px;" v-if="service.expected_volume">
                        {{ service.expected_volume }}
                        <span style="text-transform: none">m</span><sup>3</sup>
                        (previsto)
                      </i>
                      <i class="mx-2" v-if="service.expected_hour"
                         style="font-style: normal;font-size: 13px;">
                        |
                      </i>
                      <i style="font-style: normal; font-size: 13px;" v-if="service.expected_hour">
                        {{ service.expected_hour }} {{ service.expected_hour > 1 ? 'horas' : 'hora' }} (previsto)
                      </i>
                    </div>
                  </div>
                  <div class="row mt-2 align-items-end">
                    <div class="col-4">
                      <h4 class="new-default-gray-font">
                        Fixo
                      </h4>
                      <h4 class="new-default-gray-font">
                        Por M³
                      </h4>
                      <h4 class="new-default-gray-font">
                        Por hora
                      </h4>
                    </div>
                    <div class="col-4 text-center">
                      <div style="width: fit-content; display: inline-block;">
                        <h6 class="new-default-gray-font text-left">
                          Mínimo
                        </h6>

                        <h4 class="new-default-gray-font text-left" v-if="service.service.price_fixed">
                          {{ service.service.price_fixed | currency() }}
                        </h4>
                        <h4 class="new-default-gray-font text-left" v-else>
                          -
                        </h4>

                        <h4 class="new-default-gray-font text-left" v-if="service.service.by_meters">
                          {{ service.service.by_meters | currency() }}
                        </h4>
                        <h4 class="new-default-gray-font text-left" v-else>
                          -
                        </h4>

                        <h4 class="new-default-gray-font text-left" v-if="service.service.by_hour">
                          {{ service.service.by_hour | currency() }}
                        </h4>
                        <h4 class="new-default-gray-font text-left" v-else>
                          -
                        </h4>

                      </div>
                    </div>
                    <div class="col-4 text-right">
                      <div style="width: fit-content; display: inline-block;">
                        <h6 class="new-default-gray-font text-left">
                          Venda
                        </h6>
                        <h4 class="text-left"
                            v-if="service.proposal_price_fixed"
                            :class="typeBetween(service.proposal_price_fixed, service.service.price_fixed)">
                          {{ service.proposal_price_fixed | currency() }}
                        </h4>
                        <h4 class="new-default-gray-font text-left" v-else>
                          -
                        </h4>

                        <h4 class="text-left" v-if="service.proposal_by_meters"
                            :class="typeBetween(service.proposal_by_meters, service.service.by_meters)">
                          {{ service.proposal_by_meters | currency() }}
                        </h4>
                        <h4 class="new-default-gray-font text-left" v-else>
                          -
                        </h4>

                        <h4 class="text-left" v-if="service.proposal_by_hours"
                            :class="typeBetween(service.proposal_by_hours, service.service.by_hour)">
                          {{ service.proposal_by_hours | currency() }}
                        </h4>
                        <h4 class="new-default-gray-font text-left" v-else>
                          -
                        </h4>

                      </div>
                    </div>
                  </div>
                  <hr class="m-0">
                  <div class="row mt-3">
                    <div @click.prevent="approveServiceSet(service.uuid, 1)"
                         class="col pointer pr-1">
                      <a class="card-with-box-shadow text-center" style="display: inherit;"
                         :class="approveService.hasOwnProperty(service.uuid) && approveService[service.uuid] === 1 && 'card-wbs-success'">
                        <div class="py-2" style="display: inline-flex;">
                          <div style="display: flex;align-items: center;">
                            <img class="mr-1" width="16"
                                 src="/img/icons/icons8/ios/thumb-up_success.png"/>
                            <i
                              :class="approveService.hasOwnProperty(service.uuid) && approveService[service.uuid] === 1 ? 'text-success' : 'new-default-black-font'"
                              style="font-style: normal;font-size: 11px;">
                              Aprovar
                            </i>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div @click.prevent="approveServiceSet(service.uuid, 0)"
                         class="col pointer px-1">
                      <a class="card-with-box-shadow text-center" style="display: inherit;"
                         :class="approveService.hasOwnProperty(service.uuid) && approveService[service.uuid] === 0 && 'card-wbs-danger'">
                        <div class="py-2" style="display: inline-flex;">
                          <div style="display: flex;align-items: center;">
                            <img class="mr-1" width="16"
                                 src="/img/icons/icons8/ios/thumbs-down_danger.png"/>
                            <i
                              :class="approveService.hasOwnProperty(service.uuid) && approveService[service.uuid] === 0 ? 'text-danger' : 'new-default-black-font'"
                              style="font-style: normal;font-size: 11px;">
                              Reprovar
                            </i>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div @click.prevent="approveServiceSet(service.uuid, 2)"
                         class="col pointer pl-1">
                      <a class="card-with-box-shadow text-center" style="display: inherit;"
                         :class="approveService.hasOwnProperty(service.uuid) && approveService[service.uuid] === 2 && 'card-wbs-warning'">
                        <div class="py-2" style="display: inline-flex;">
                          <div style="display: flex;align-items: center;">
                            <img class="mr-1" width="16"
                                 src="/img/icons/icons8/ios/thumbs-up-down_warning.png"/>
                            <i
                              :class="approveService.hasOwnProperty(service.uuid) && approveService[service.uuid] === 2 ? 'text-warning' : 'new-default-black-font'"
                              style="font-style: normal;font-size: 11px;">
                              Revisar
                            </i>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div v-if="approveService.hasOwnProperty(service.uuid)" class="row mt-2">
                    <div class="col-12">
                      <h4 class="new-default-black-font">
                        Observações
                      </h4>
                    </div>
                    <div class="col-12">
                      <base-input input-group-classes="input-group-sm">
                        <textarea type="text" v-model="observationService" rows="4"
                                  class="form-control form-control-sm" placeholder=" ...">
                        </textarea>
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3" v-if="approveService.hasOwnProperty(service.uuid)">
                    <div class="col-6 pr-1">
                      <base-button type="danger" block
                                   size="sm" @click.prevent="removeApprovationService()">
                        <div style="display: inline-flex; align-items: center;">
                          <img src="/img/icons/cancel-white.png" width="20">
                          <i class="m-0 ml-1" style="font-style: normal;">
                            Cancelar
                          </i>
                        </div>
                      </base-button>
                    </div>
                    <div class="col-6 pl-1">
                      <base-button type="success" block
                                   size="sm"
                                   @click.prevent="updateService(service, approveService[service.uuid], indexService)">
                        <div style="display: inline-flex; align-items: center;">
                          <img src="/img/icons/save.png" width="20">
                          <i class="m-0 ml-1" style="font-style: normal;">
                            Salvar
                          </i>
                        </div>
                      </base-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {
  period_types
} from "@/views/Modules/Technology/Mix/helpers/mixe";
import PuzlSelect from "@/components/PuzlSelect.vue";

export default {
  components: {PuzlSelect},
  name: "ModalContractProposalApprovals",
  data() {
    return {
      modal: false,
      title: 'Aprovações pendentes',
      validated: false,
      loading: false,
      loadingFeatureLevels: false,
      format_mixe: period_types,
      approveAll: 99,
      observation: '',
      approveFormulation: {},
      approveService: {},
      contract_proposal_uuid: null,
      observationFormulation: '',
      observationService: '',
      delivery_types: [
        {
          label: 'Venda de concreto',
          id: 1,
        },
        {
          label: 'Doação',
          id: 2,
        },
        {
          label: 'TRANSFERÊNCIA',
          id: 3,
        },
        {
          label: 'Uso interno',
          id: 4,
        },
        {
          label: 'Reparo de concretagem',
          id: 5,
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      $_commercial_permission: "commercialPermission/show",
      without_status: "contractProposal/getListApprovals",
      contract_proposal: "contractProposal/show",
      $_feature_levels: 'mixConcretePiece/feature_levels',
    })
  },
  methods: {
    changeFeatureLevel(mix_design) {
      this.$Progress.start()
      this.$Swal.confirmAction('Deseja realmente alterar o nível de aplicação?', ['Sim', 'Não'])
        .then((result) => {
          if (result.isConfirmed) {
            let loader = this.$loading.show()
            this.$store.dispatch('formulations/changeFeatureLevel', {
              formulation_uuid: mix_design.uuid,
              feature_level: mix_design.feature_level
            }).then(async response => {
              await this.$store.dispatch('contractProposal/getCommercialWithoutStatus', this.contract_proposal_uuid)
                .then(async response => {
                  this.$notify({
                    type: response.error_type,
                    message: response.message
                  });
                  loader.hide()
                  this.$Progress.finish()
                })
              this.init()
            }).catch(error => {
              loader.hide()
              this.$Progress.finish()
            })
          } else {
            mix_design.feature_level = mix_design.old_feature_level
          }
        }).catch(() => this.$Progress.finish())
    },
    getFeatureLevels(level_features) {
      return this.$_feature_levels.filter((item) => level_features.includes(item.value))
    },
    fetchCommercialPermissions() {
      this.$store.dispatch('commercialPermission/getByUserId', this.$user.id);
    },
    closeModal() {
      this.modal = false
    },
    removeApprovationFormulation() {
      this.approveFormulation = {}
      this.observationFormulation = ''
      this.$forceUpdate()
    },
    removeApprovationService() {
      this.approveService = {}
      this.observationService = ''
      this.$forceUpdate()
    },
    typeBetween(a, b) {
      if (Number(a) >= Number(b)) {
        return 'text-success'
      }
      if (Number(a) < Number(b)) {
        return 'text-danger'
      }
      return 'light'
    },
    calculateGrossMargin: function (minimumPrice, mcc, tax) {
      const price = minimumPrice
      const cmc = mcc
      if (price > 0) {
        if (Number(tax) > 0) {
          let tax_value = price * (tax / 100)
          return price - tax_value - cmc
        } else {
          return price - cmc
        }
      }
      return 0
    },
    unformatTax(tax) {
      const regex = /\d+\.?\d*/;
      return tax.match(regex)[0];
    },
    validApproveFormulation(uuid) {
      let mix_design = this.without_status.formulations.find((item) => item.uuid == uuid)
      if (this.$_commercial_permission.limit_variation) {
        let margin = mix_design.minimum_price_atual - mix_design.mcc_atual - mix_design.concrete_price.tax_price

        let monetary_sell = 0
        let monetary_percentage = 0
        let bigger_monetary = 0

        let monetary_margin = 0
        let monetary_margin_percentage = 0
        let bigger_margin = 0

        if (Number(this.$_commercial_permission.margin_sell_limit) > 0 || Number(this.$_commercial_permission.margin_sell_limit_percentage) > 0) {
          if (Number(this.$_commercial_permission.margin_sell_limit) > 0) {
            monetary_sell = mix_design.minimum_price - this.$_commercial_permission.margin_sell_limit
          }
          if (Number(this.$_commercial_permission.margin_sell_limit_percentage) > 0) {
            monetary_percentage = mix_design.minimum_price * (1 - (this.$_commercial_permission.margin_sell_limit_percentage / 100))
          }
          bigger_monetary = Math.max(monetary_sell, monetary_percentage)
        }

        let gross_margin = this.calculateGrossMargin(mix_design.value, mix_design.mcc_atual, this.unformatTax(mix_design.concrete_price.tax_name))

        if (Number(this.$_commercial_permission.margin_machining_limit) > 0 || Number(this.$_commercial_permission.margin_machining_limit_percentage) > 0) {
          if (Number(this.$_commercial_permission.margin_machining_limit) > 0) {
            monetary_margin = margin - this.$_commercial_permission.margin_machining_limit
          }
          if (Number(this.$_commercial_permission.margin_machining_limit_percentage) > 0) {
            monetary_margin_percentage = margin * (1 - (this.$_commercial_permission.margin_machining_limit_percentage / 100))
          }
          bigger_margin = Math.max(monetary_margin, monetary_margin_percentage)
        }
        if (Number(mix_design.value) < Number(bigger_monetary)) {
          return false
        }
        if (bigger_margin > 0 && gross_margin < bigger_margin) {
          return false
        }
      }
      return true
    },
    approveFormulationSet(uuid, type) {
      if (!this.$_commercial_permission.minimum_price_approval) {
        return false
      }
      if (!this.validApproveFormulation(uuid)) {
        return this.$notify({
          type: 'danger',
          message: `Este usuário não possui limite de desconto suficiente para realizar essa aprovação.`
        });
      }
      this.approveFormulation[uuid] = type
      this.$forceUpdate()
    },
    validApproveService(uuid) {
      let service = this.without_status.services.find((item) => item.uuid == uuid)
      let service_price = {...service.service}
      if (this.$_commercial_permission.limit_variation) {
        let fixed_value = 0
        let m3_value = 0
        let hour_value = 0

        let monetary_fixed_value = 0
        let monetary_m3_value = 0
        let monetary_hour_value = 0

        let fixed_value_pct = 0
        let m3_value_pct = 0
        let hour_value_pct = 0
        if (service_price.price_fixed > 0) {
          if (Number(this.$_commercial_permission.minimum_price_service_limit) > 0) {
            if (Number(this.$_commercial_permission.minimum_price_service_limit) > 0) {
              monetary_fixed_value = service_price.price_fixed - this.$_commercial_permission.minimum_price_service_limit
            }
            if (Number(this.$_commercial_permission.minimum_price_hour_service_limit_percentage) > 0) {
              fixed_value_pct = service_price.price_fixed * (1 - (this.$_commercial_permission.minimum_price_service_limit_percentage / 100))
            }
            fixed_value = Math.max(monetary_fixed_value, fixed_value_pct)
          }

          if (service_price.by_meters > 0) {
            if (Number(this.$_commercial_permission.minimum_price_m3_service_limit) > 0) {
              monetary_m3_value = service_price.by_meters - this.$_commercial_permission.minimum_price_m3_service_limit
            }
            if (Number(this.$_commercial_permission.minimum_price_m3_service_limit_percentage) > 0) {
              m3_value_pct = service_price.by_meters * (1 - (this.$_commercial_permission.minimum_price_m3_service_limit_percentage / 100))
            }
            m3_value = Math.max(monetary_m3_value, m3_value_pct)
          }

          if (service_price.by_hour > 0) {
            if (Number(this.$_commercial_permission.minimum_price_hour_service_limit) > 0) {
              monetary_hour_value = service_price.by_hour - this.$_commercial_permission.minimum_price_hour_service_limit
            }
            if (Number(this.$_commercial_permission.minimum_price_hour_service_limit_percentage) > 0) {
              hour_value_pct = service_price.by_hour * (1 - (this.$_commercial_permission.minimum_price_hour_service_limit_percentage / 100))
            }
            hour_value = Math.max(monetary_hour_value, hour_value_pct)
          }
          if (service.proposal_price_fixed < fixed_value || service.proposal_by_meters < m3_value || service.proposal_by_hours < hour_value) {
            return false
          }
        }
      }
      return true
    },
    approveServiceSet(uuid, type) {
      if (!this.$_commercial_permission.minimum_price_approval) {
        return false
      }
      if (!this.validApproveService(uuid)) {
        return this.$notify({
          type: 'danger',
          message: `Este usuário não possui limite de desconto suficiente para realizar essa aprovação.`
        });
      }
      this.approveService[uuid] = type
      this.$forceUpdate()
    },
    calcServiceHour(mixDesign, mcc) {
      const billing = mixDesign.value - mcc
      const service = billing * mixDesign.cycle.average_volume_per_bt
      const cycle = mixDesign.cycle.total_cycle / 60
      return service / cycle
    },
    validApproval() {
      if (this.without_status.formulations) {
        if (this.without_status.formulations.length < 1 &&
          this.without_status.services.length < 1) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    validAtualPrice(mixDesign) {
      if (mixDesign.value - mixDesign.mcc_atual > mixDesign.value - mixDesign.concrete_price.mcc_price) {
        return 'text-success'
      }
      if (mixDesign.value - mixDesign.mcc_atual < mixDesign.minimum_price_atual_pct) {
        return 'text-danger'
      }
      return 'text-default'
    },
    validAtualPriceByHour(mixDesign) {
      const atual = this.calcServiceHour(mixDesign, mixDesign.mcc_atual);
      const defined = this.calcServiceHour(mixDesign, mixDesign.concrete_price.mcc_price)

      if (atual > defined) {
        return 'text-sucess'
      }
      if (atual < defined) {
        return 'text-danger'
      }
      return 'text-default'
    },
    updateFormulation(formulation, type, index) {
      this.loading = true
      this.$Progress.start();
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      });
      let params = {
        formulation_uuid: formulation.uuid,
        situation: type,
        observation: this.observationFormulation,
        volume: formulation.expected_volume,
        price: formulation.value,
        is_bigger: parseFloat(formulation.value) > parseFloat(formulation.minimum_price) ? 1 : 0
      }
      this.$store.dispatch('formulations/createSituation', params).then(response => {
        this.$Progress.finish();
        this.$notify({
          type: response.error_type,
          message: response.message
        })
        this.$Progress.start()
        this.$store.dispatch('contractProposal/getCommercialWithoutStatus', this.contract_proposal_uuid)
          .then(() => {
            this.fetchContractProposals()
            this.$Progress.finish()
          })
        this.loading = false
        this.without_status.formulations.splice(index, 1)
      })
    },
    updateService(service, type, index) {
      this.loading = true
      this.$Progress.start();
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      });
      let params = {
        service_uuid: service.uuid,
        situation: type,
        observation: this.observationService,
      }
      this.$store.dispatch('contractService/createSituation', params).then(response => {
        this.$Progress.finish();
        this.$notify({
          type: response.error_type,
          message: response.message
        })
        this.$Progress.start()
        this.$store.dispatch('contractProposal/getCommercialWithoutStatus', this.contract_proposal_uuid)
          .then(() => {
            this.fetchContractProposals()
            this.$Progress.finish()
          })
        this.modal = false;
        this.loading = false
        this.without_status.formulations.splice(index, 1)
      })
    },
    toMoney(value) {
      value = Number(value)
      value = parseFloat(value)
      value = value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
      return value
    },
    async updateAllSituation(type) {
      if (!this.$_commercial_permission.minimum_price_approval) {
        return this.$notify({
          type: 'danger',
          message: `Este usuário não possui permissão de aprovação de preço mínimo.`
        });
      }
      const serviceUuIds = this.without_status.services.map((item) => item.uuid)
      const formulationUuIds = this.without_status.formulations.map((item) => item.uuid)
      for (let service_uuid of serviceUuIds) {
        let approve = await this.validApproveService(service_uuid)
        if (!approve) {
          return this.$notify({
            type: 'danger',
            message: `Este usuário não possui limite de desconto suficiente para realizar essa aprovação.`
          });
        }
      }
      for (let formulation_uuid of formulationUuIds) {
        let approve = await this.validApproveFormulation(formulation_uuid)
        if (!approve) {
          return this.$notify({
            type: 'danger',
            message: `Este usuário não possui limite de desconto suficiente para realizar essa aprovação.`
          });
        }
      }
      let params = {
        contract_proposal_uuid: this.contract_proposal_uuid,
        observation: this.observation,
        formulation_uuids: formulationUuIds,
        service_uuids: serviceUuIds,
        type: type
      }
      this.$Progress.start()
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      });
      this.$store.dispatch('contractProposal/ApprovalAllotments', params)
        .then(response => {
          this.modal = false
          this.$Progress.finish()
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.fetchContractProposals()
          this.$Progress.start()
        })
    },
    fetchContractProposals() {
      this.$emit('fetch')
    },
    async openModal(uuid) {
      this.loadingFeatureLevels = true;
      await this.$store.dispatch('mixConcretePiece/getFeatureLevels')
      this.loadingFeatureLevels = false;
      this.$Progress.start()
      this.observation = '';
      this.observationFormulation = '';
      this.observationService = '';
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      });
      await this.fetchCommercialPermissions()
      this.contract_proposal_uuid = uuid
      this.approveAll = false
      this.$store.dispatch('contractProposal/getCommercialWithoutStatus', this.contract_proposal_uuid)
        .then(response => {
          this.modal = true
          this.$notify({
            type: response.error_type,
            message: response.message
          });
        })
      this.$store
        .dispatch(
          "contractProposal/show",
          this.contract_proposal_uuid
        )
        .then(response => {
          this.$Progress.finish()
          this.loading = false;
        });

    },
    save() {
    },
  },
  created() {
  },
  mounted() {
  }
};
</script>

<style scoped>
.card-with-box-shadow {
  border-radius: 10px !important;
}

.card-wbs-danger {
  background-color: #f459592e;
}

.card-wbs-warning {
  background-color: #f4dc592e;
}

.card-wbs-success {
  background-color: #8bd5af52;
}
</style>

<style>
.border-3 {
  border-width: 3px !important;
}
</style>
