export function generateFirstMonths(selectedYear) {
  return [
    // Array para os primeiros seis meses do ano.
    {
      id: null,
      type: null,
      is_closed: null,
      month: 1,
      year: selectedYear,
      created_by_user_id: null,
      updated_by_user_id: null,
      created_by_user_name: null,
      updated_by_user_name: null,
    },
    {
      id: null,
      type: null,
      is_closed: null,
      month: 2,
      year: selectedYear,
      created_by_user_id: null,
      updated_by_user_id: null,
      created_by_user_name: null,
      updated_by_user_name: null,
    },
    {
      id: null,
      type: null,
      is_closed: null,
      month: 3,
      year: selectedYear,
      created_by_user_id: null,
      updated_by_user_id: null,
      created_by_user_name: null,
      updated_by_user_name: null,
    },
    {
      id: null,
      type: null,
      is_closed: null,
      month: 4,
      year: selectedYear,
      created_by_user_id: null,
      updated_by_user_id: null,
      created_by_user_name: null,
      updated_by_user_name: null,
    },
    {
      id: null,
      type: null,
      is_closed: null,
      month: 5,
      year: selectedYear,
      created_by_user_id: null,
      updated_by_user_id: null,
      created_by_user_name: null,
      updated_by_user_name: null,
    },
    {
      id: null,
      type: null,
      is_closed: null,
      month: 6,
      year: selectedYear,
      created_by_user_id: null,
      updated_by_user_id: null,
      created_by_user_name: null,
      updated_by_user_name: null,
    },
  ];
}
  
export function generateLastMonths(selectedYear) {
  return [
    // Array para os últimos seis meses do ano.
    {
      id: null,
      type: null,
      is_closed: null,
      month: 7,
      year: selectedYear,
      created_by_user_id: null,
      updated_by_user_id: null,
      created_by_user_name: null,
      updated_by_user_name: null,
    },
    {
      id: null,
      type: null,
      is_closed: null,
      month: 8,
      year: selectedYear,
      created_by_user_id: null,
      updated_by_user_id: null,
      created_by_user_name: null,
      updated_by_user_name: null,
    },
    {
      id: null,
      type: null,
      is_closed: null,
      month: 9,
      year: selectedYear,
      created_by_user_id: null,
      updated_by_user_id: null,
      created_by_user_name: null,
      updated_by_user_name: null,
    },
    {
      id: null,
      type: null,
      is_closed: null,
      month: 10,
      year: selectedYear,
      created_by_user_id: null,
      updated_by_user_id: null,
      created_by_user_name: null,
      updated_by_user_name: null,
    },
    {
      id: null,
      type: null,
      is_closed: null,
      month: 11,
      year: selectedYear,
      created_by_user_id: null,
      updated_by_user_id: null,
      created_by_user_name: null,
      updated_by_user_name: null,
    },
    {
      id: null,
      type: null,
      is_closed: null,
      month: 12,
      year: selectedYear,
      created_by_user_id: null,
      updated_by_user_id: null,
      created_by_user_name: null,
      updated_by_user_name: null,
    },
  ];
}
  