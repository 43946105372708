<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row pt-0" style="margin-top: -0.5rem">
        <div class="col-md-12 p-1 card-row">
          <multi-filter-v2 ref="multi-filter" @fetch="load" @clearFilters="clearFilters" :filter="filter" :status="multi_filter">
            <div class="col-md-3 p-2">
              <input-date-picker :filterable="4" size="small" ref="date-picker" @handleFilterDate="handleFilterDate" :default-range="defaultRange" />
            </div>
            <div class="col-md-3 p-2 mb-1 custom-dashboard">
              <puzl-select
                class="select-lg"
                v-model="selectedBanksAccount"
                :items="[{ id: 'all', name: 'Todos' }, ...$_bank_accounts]"
                :multiple="true"
                placeholder="Conta Bancária de Crédito"
                @input="handleFilterBankAccounts"
              />
            </div>
            <div class="col-md-3 p-2 mb-1 custom-dashboard">
              <puzl-select
                v-model="selectedCompanyPlants"
                :items="[{ id: 'all', name: 'Todos' }, ...$_plants]"
                customKey="id"
                :multiple="true"
                class="select-lg"
                @input="handleFilterCompanyPlants"
                :loading="loadingCompanyPlants"
                :disabled="loadingCompanyPlants"
                placeholder="Central"
              />
            </div>
            <div slot="after">
              <div class="row" style="margin-left: -22px">
                <div class="col-md-3 mt-1">
                  <base-button
                    :class="{active: !filter.advanced || filter.advanced !== 1}"
                    @click="handleFilterAdvancedStatus(1)"
                    type="danger"
                    outline
                    block
                    class="button-text"
                  >
                    <i class="fas fa-calendar"></i> ATRASADOS
                  </base-button>
                </div>
              </div>
            </div>
          </multi-filter-v2>
        </div>
      </div>
    </base-header>
    <div class="container-fluid">
      <div class="row card-row" v-show="loadingBalance">
        <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
      </div>

      <div v-show="!loadingBalance" class="row card-row">
        <div class="col-md-4 p-1">
          <div class="card card-stats" style="border: 0.5px solid">
            <div class="card-body">
              <div class="row">
                <div class="col-12 text-center">
                  <h5 class="card-title text-uppercase mb-0 font-weight-700">Saldo Inicial</h5>
                </div>
                <div class="col-12 text-center">
                  <h5 class="text-uppercase mb-0 font-weight-400">{{ range.start | parseDate("DD/MMM") }}</h5>
                </div>
                <div class="col-12 text-center">
                  <h3 :class="$_balance.initial >= 0 ? 'text-success' : 'text-danger'" class="text-uppercase mb-0">
                    {{ $_balance.initial | currency() }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 p-1">
          <div class="card card-stats" style="border: 0.5px solid">
            <div class="card-body">
              <div class="row">
                <div class="col-12 text-center">
                  <h5 class="card-title text-uppercase mb-0 font-weight-700">Saldo Atual</h5>
                </div>
                <div class="col-12 text-center">
                  <h5 class="text-uppercase mb-0 font-weight-400">{{ new Date() | parseDate("DD/MMM") }}</h5>
                </div>
                <div class="col-12 text-center">
                  <h3 :class="$_balance.current >= 0 ? 'text-success' : 'text-danger'" class="text-uppercase mb-0">
                    {{ $_balance.current | currency() }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 p-1">
          <div class="card card-stats" style="border: 0.5px solid">
            <div class="card-body">
              <div class="row">
                <div class="col-12 text-center">
                  <h5 class="card-title text-uppercase mb-0 font-weight-700">Saldo Final</h5>
                </div>
                <div class="col-12 text-center">
                  <h5 class="text-uppercase mb-0 font-weight-400">{{ range.end | parseDate("DD/MMM") }}</h5>
                </div>
                <div class="col-12 text-center">
                  <h3 :class="$_balance.final >= 0 ? 'text-success' : 'text-danger'" class="text-uppercase mb-0">
                    {{ $_balance.final | currency() }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row pt-0 card-row">
        <div class="col-md-12 p-1">
          <div class="card">
            <div class="card-body mt-n2">
              <div class="row">
                <div class="col-md-12 table-responsive">
                  <table class="table table-sm table-bordered">
                    <thead>
                      <tr class="fixed">
                        <th class="text-center">
                          <span>Descrição</span>
                        </th>
                        <th class="text-center"><span>Conta</span></th>
                        <th class="text-center"><span>Data</span></th>
                        <th class="text-center">
                          <span>Valor</span>
                        </th>
                        <th class="text-center">
                          <span>Saldo</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="loadingSkeleton">
                        <tr v-for="(item, index) in 10" :key="index">
                          <th colspan="12">
                            <skeleton-puzl type="button" />
                          </th>
                        </tr>
                      </template>
                      <template v-if="!loadingSkeleton">
                        <tr v-for="(item, index) in $_account_statements" :key="index">
                          <th class="text-left">
                            <span class="style-table">{{ item.name || 'Depósito não identificado' }}</span>
                          </th>
                          <th class="text-left">
                            <span class="style-table">{{ item.bank_account_name }}</span>
                          </th>
                          <th class="text-left">
                            <span class="style-table">
                              <el-popover trigger="hover" placement="bottom" class="p-0">
                                <span :class="`text-${getType(item.type, item.status).color}`">
                                  {{ getType(item.type, item.status).label }}
                                </span>
                                <h4 slot="reference" class="mb-0">
                                  <span class="font-weight-normal">
                                    <i :class="getIcon(item.type, item.status)" class="mr-2" />{{ item.date | parseDate("DD/MMM") }}
                                  </span>
                                </h4>
                              </el-popover>
                            </span>
                          </th>
                          <th class="text-left">
                            <span
                              class="style-table numeric font-weight-bold"
                              :class="isPositive(item.type, item.status) ? 'text-success' : 'text-danger'"
                            >
                              {{ (isPositive(item.type, item.status) ? item.final_value : item.final_value * -1) | currency }}
                            </span>
                          </th>
                          <th class="text-left numeric font-weight-bold">
                            <span v-show="item.status !== 2 && item.is_overdue === 0 && !filter.status" class="style-table numeric font-weight-bold">
                              {{ item.balance || 0 | currency() }}
                            </span>
                          </th>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                  <PuzlEmptyData v-if="!$_account_statements.length && !loadingSkeleton" />
                </div>
              </div>
              <pagination :disabledJumpControls="true" @navegate="navegate" :source="source" :loading="loadingSkeleton" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import { SkeletonPuzl } from "@/components";
import Pagination from "@/components/Utils/Pagination";
import InputDatePicker from "@/components/InputDatePicker";
import PuzlSelect from "@/components/PuzlSelect";
import SpinnerPuzl from "@/components/SpinnerPuzl";
import moment from "moment";
import MultiFilterV2 from "@/components/Utils/MultiFilterV2";
import { PuzlEmptyData } from "@/components";

export default {
  name: "ListAccountStatements",
  components: {
    SkeletonPuzlGrid,
    SkeletonPuzl,
    Pagination,
    MultiFilterV2,
    InputDatePicker,
    PuzlSelect,
    SpinnerPuzl,
    PuzlEmptyData,
  },
  data() {
    return {
      loadingSkeleton: true,
      loadingBalance: true,
      generalBalance: 0,
      pageBalances: [],
      source: null,
      params: {
        page: 1,
        per_page: 30,
      },
      fetchAccountStatement: null,
      loadingCompanyPlants: true,
      selectedBanksAccount: [],
      selectedCompanyPlants: [],
      filter: {},
      multi_filter: {
        null: {
          name: "Todos",
          type: "primary",
        },
        1: {
          name: "Contas a Receber",
          type: "success",
        },
        2: {
          name: "Contas a Pagar",
          type: "danger",
        },
      },
      range: {
        start: null,
        end: null,
      },
      defaultRange: {
        start: moment().format("YYYY-MM-DD hh:mm:ss"),
        end: moment().format("YYYY-MM-DD hh:mm:ss"),
      },
    };
  },
  computed: {
    ...mapGetters({
      $_balance: "accountStatement/balance",
      $_account_statements: "accountStatement/fetch",
      $_bank_accounts: "bankAccount/fetch",
      $_plants: "plant/activeItems",
    }),
  },
  methods: {
    load(filter = {}) {
      this.filter = filter;
      this.params.page = 1;
      this.init();
    },

    init() {
      this.fetchItemsPaginateAccountStatement();
    },

    handleFilterDate(filter) {
      if (moment(filter.start).isAfter(moment())) {
        this.$refs["date-picker"].resetDate();
        return this.$notify({
          type: "danger",
          message: "A data inicial não pode ser maior que hoje.",
        });
      }

      this.range = {
        start: moment(filter.start).format("YYYY-MM-DD"),
        end: moment(filter.end).format("YYYY-MM-DD"),
      };

      this.params.page = 1;
      this.fetchItemsPaginateAccountStatement();
    },

    handleFilterBankAccountsAndPlants() {
      this.filter.company_plants = !this.selectedCompanyPlants.includes("all") ? this.selectedCompanyPlants : [];
      this.filter.bank_accounts = !this.selectedBanksAccount.includes("all") ? this.selectedBanksAccount : [];
    },

    fetchItemsPaginateAccountStatement() {
      this.loadingSkeleton = true;
      this.loadingBalance = true;
      this.filter.range = this.range;

      this.handleFilterBankAccountsAndPlants();
      this.$store
        .dispatch("accountStatement/getBalance", {
          filter: this.filter,
        })
        .then(() => {
          this.loadingBalance = false;
          this.$store
            .dispatch("accountStatement/getItems", {
              filter: this.filter,
              page: this.params.page,
            })
            .then((response) => {
              this.source = response;

              switch (true) {
                case this.params.page === 1:
                  this.generalBalance = this.$_balance.initial;
                  this.pageBalances = [this.$_balance.initial];
                  break;
                case typeof this.pageBalances[this.params.page - 1] !== "undefined":
                  this.generalBalance = this.pageBalances[this.params.page - 1];
                  break;
                default:
                  this.pageBalances.push(this.generalBalance);
              }

              this.$_account_statements.map(function (item) {
                let balance = 0;
                if (item.status !== 2 && item.is_overdue === 0) {
                  balance =
                    parseFloat(this.generalBalance) +
                    (this.isPositive(item.type, item.status) ? parseFloat(item.final_value) : parseFloat(item.final_value) * -1);
                  this.generalBalance = balance;
                }
                item.balance = balance;
              }, this);

              this.loadingSkeleton = false;
            });
        });
    },

    handleFilterBankAccounts() {
      const index = this.selectedBanksAccount.findIndex((item) => item == "all");
      if (this.selectedBanksAccount.length > 1 && index === 0) {
        return this.selectedBanksAccount.splice(0, 1);
      }
      if (this.selectedBanksAccount.length > 1 && this.selectedBanksAccount.includes("all")) {
        this.selectedBanksAccount = [];
        this.selectedBanksAccount.push("all");
      }

      this.params.page = 1;
      this.fetchItemsPaginateAccountStatement();
    },

    handleFilterCompanyPlants() {
      const index = this.selectedCompanyPlants.findIndex((item) => item == "all");
      if (this.selectedCompanyPlants.length > 1 && index === 0) {
        return this.selectedCompanyPlants.splice(0, 1);
      }
      if (this.selectedCompanyPlants.length > 1 && this.selectedCompanyPlants.includes("all")) {
        this.selectedCompanyPlants = [];
        this.selectedCompanyPlants.push("all");
      }
      this.params.page = 1;
      this.fetchItemsPaginateAccountStatement();
    },

    navegate(page) {
      this.params.page = page;
      this.fetchItemsPaginateAccountStatement();
    },

    clearFilters() {
      this.filter = {};
      this.selectedCompanyPlants = [];
      this.selectedBanksAccount = [];
      this.params.page = 1;
      this.fetchItemsPaginateAccountStatement();
    },

    getIcon(type, status) {
      switch (true) {
        case type === "source_bank_transfer":
          return "fas fa-arrow-down text-danger";
        case type === "target_bank_transfer":
          return "fas fa-arrow-up text-success";
        case type !== "bill_pay_history" && status === 2:
          return "fas fa-calendar text-danger";
        case type !== "bill_pay_history" && [0, 6].includes(status):
          return "fas fa-calendar text-warning";
        case type !== "bill_pay_history" && status === 4:
          return "fas fa-calendar text-info";
        case type !== "bill_pay_history" && status === 5:
          return "fas fa-calendar text-default";
        case type !== "bill_pay_history" && status === 7:
          return "fas fa-calendar text-indigo";
        case type !== "bill_pay_history" && status === 8:
          return "fas fa-calendar text-approved";
        case type === "bill_pay_history" && status === 1:
          return "fa fa-check text-primary";
        case ["bill_receive_history", "entity_bill_receive_history"].includes(type) && status === 1:
          return "fa fa-check text-success";
        case type === "bill_pay_history" && status === 9:
          return "fas fa-arrow-up text-primary";
        case ["bill_receive_history", "entity_bill_receive_history"].includes(type) && status === 10:
          return "fas fa-arrow-down text-warning";
      }
    },

    isPositive(type, status) {
      switch (true) {
        case type === "source_bank_transfer":
        case type === "bill_pay":
          return false;
        case type === "bill_pay_history" && status === 1:
          return false;
        case ["bill_receive_history", "entity_bill_receive_history"].includes(type) && status === 10:
          return false;
        default:
          return true;
      }
    },

    getType(type, status) {
      switch (true) {
        case type === "source_bank_transfer":
          return {
            color: "danger",
            label: "Envio de transferência",
          };
        case type === "target_bank_transfer":
          return {
            color: "success",
            label: "Recebimento de transferência",
          };
        case type !== "bill_pay_history" && status === 0:
          return {
            color: "warning",
            label: "Pendente",
          };
        case type !== "bill_pay_history" && status === 2:
          return {
            color: "danger",
            label: "Atrasado",
          };
        case type !== "bill_pay_history" && status === 4:
          return {
            color: "info",
            label: "Serasa",
          };
        case type !== "bill_pay_history" && status === 5:
          return {
            color: "default",
            label: "Cartório",
          };
        case type !== "bill_pay_history" && status === 6:
          return {
            color: "warning",
            label: "Protestado",
          };
        case type !== "bill_pay_history" && status === 7:
          return {
            color: "indigo",
            label: "Jurídico",
          };
        case type !== "bill_pay_history" && status === 8:
          return {
            color: "approved",
            label: "Aprovado",
          };
        case type === "bill_pay_history" && status === 1:
          return {
            color: "primary",
            label: "Pago",
          };
        case ["bill_receive_history", "entity_bill_receive_history"].includes(type) && status === 1:
          return {
            color: "success",
            label: "Recebido",
          };
        case type === "bill_pay_history" && status === 9:
          return {
            color: "primary",
            label: "Estorno de pagamento",
          };
        case ["bill_receive_history", "entity_bill_receive_history"].includes(type) && status === 10:
          return {
            color: "danger",
            label: "Estorno de recebível",
          };
        default:
          return {
            color: "default",
            label: "",
          };
      }
    },
    handleFilterAdvancedStatus(filter) {
      this.params.page = 1;
      this.filter.advanced = this.filter.advanced === filter ? null : filter;
      this.fetchItemsPaginateAccountStatement();
    },
  },
  mounted() {
    this.$store.dispatch("plant/fetchItems").then(() => {
      this.loadingCompanyPlants = false;
    });
    this.$refs["multi-filter"]._self.$forceUpdate();
    this.range = this.defaultRange;
    this.init();
  },
};
</script>

<style scoped>
.style-table {
  font-weight: 400;
  width: 15px;
}

.w-outline {
  background-color: white !important;
  color: #000000 !important;
}

.text-approved {
  color: rgb(79, 64, 255);
}
</style>
