<template>
  <div>
    <modal :show.sync="modal.create" size="md">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(confirm)" autocomplete="off">
            <div>
              <!-- Central -->
              <div class="form-group row m-1 p-0">
                <label class="col-md-5 pt-2 pb-0 mb-1 col-form-label form-control-label"
                  >Central <span class="text-danger">&nbsp;*</span></label
                >
                <div class="col-md-7">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <el-select v-model="form.company_plant_id" size="mini" placeholder="Selecione" filterable>
                        <el-option
                          v-for="company_plant in company_plants"
                          :key="company_plant.id"
                          :label="company_plant.name"
                          :value="company_plant.id"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="modal-footer d-flex justify-content-center">
                <base-button type="secondary" @click="closeModal('create')"> Cancelar </base-button>
                <base-button type="success" native-type="submit" v-bind:disabled="invalid"> Confirmar </base-button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      modal: {
        title: "Transferência de MCC",
        create: false,
      },
      form: {
        company_plant_id: null,
        id: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      company_plants: "plant/activeItems",
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal(id) {
      let loader = this.$loading.show();
      this.form.id = id;
      this.$store.dispatch("plant/fetchItemsActive").then(() => {
        loader.hide();
        this.modal.create = true;
      });
    },
    confirm() {
      let loader = this.$loading.show();
      this.$store.dispatch("entryLaunch/entryCompanyPlantTransfer", this.form).then((response) => {
        loader.hide();
        this.$notify({ type: response.error_type, message: response.message });
        this.$emit("transfered");
        this.closeModal();
      });
    },
  },
};
</script>

<style></style>
