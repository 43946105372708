/**
 * Type criação de tipo
 *
 * @typedef {Object} AdjustmentTypeCreateType
 * @property {?number} id
 * @property {number} company_plant_id
 * @property {number} mix_type_id
 * 
*/
export const AdjustmentTypeCreateType = {};

/**
 * Inicializar AdjustmentTypeCreateType
 *
 * @returns {AdjustmentTypeCreateType}
 */
export const initAdjustmentTypeCreateType = () => ({
  id: null,
  company_plant_id: null,
  mix_type_id: null,
});