<template>
  <div>
    <modal size="lg" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <div>
        <div class="container-fluid">
          <div v-if="loadingBillPay === false">
            <div class="border-left border-3 border-danger mt-3 mb-2 ml-1">
              <h4 class="mb-0 ml-2">{{ $_bill_pay.entity.entity_name }}</h4>
              <h4 class="m-0 font-weight-normal ml-2">{{ $_bill_pay.entity.document }}</h4>
            </div>
            <div class="row mb-3">
              <h4 style="padding-top: 0.4rem !important" class="col-md-4 pl-4 mb-0 align-middle align-items-center d-flex">
                <i class="fas fa-layer-group mr-2 text-primary" /> Agrupar por
              </h4>
              <div class="col-md-4">
                <base-button
                  outline
                  block
                  size="sm"
                  type="primary"
                  :class="{ active: !filter_by_due_date }"
                  @click.prevent="changeGrouping(false)"
                >
                  EMISSÃO
                </base-button>
              </div>
              <div class="col-md-4">
                <base-button
                  outline
                  block
                  size="sm"
                  type="primary"
                  :class="{ active: filter_by_due_date }"
                  @click.prevent="changeGrouping(true)"
                >
                  VENCIMENTO
                </base-button>
              </div>
            </div>
          </div>

          <card>
            <div class="row m-0 mb-4">
              <div class="col-10 pl-0">
                <input-date-picker ref="date-picker" @handleFilterDate="handleFilterDate" />
              </div>
              <div class="col-2 pr-0">
                <base-button
                  outline
                  title="Limpar agrupamento"
                  :disabled="!agroup.bill_pays.length"
                  @click.prevent="removeAll"
                  block
                  :type="agroup.bill_pays.length > 0 ? 'danger' : 'light'"
                >
                  <i :style="agroup.bill_pays.length === 0 && 'color: #615656'" class="fas fa-1x fa-eraser"></i>
                </base-button>
              </div>
            </div>
            <div>
              <h4 class="m-0 font-weight-normal">
                Valor atual : <span class="font-weight-bold">{{ $_bill_pay.final_value | currency }} </span>
              </h4>
              <h4 class="m-0 font-weight-normal">
                Valor a adicionar : <span class="font-weight-bold">{{ agroup.value_to_add | currency }} </span>
              </h4>
              <h4 class="m-0 font-weight-normal">
                Núm. de Documentos : <span class="font-weight-bold">{{ agroup.bill_pays.length }} </span>
              </h4>
              <h4 class="m-0 font-weight-normal">
                Valor total : <span class="font-weight-bold">{{ agroup.total_value | currency }} </span>
              </h4>
            </div>
          </card>
          <div class="row card-wrapper" v-show="loadingSkeleton">
            <SkeletonPuzlGrid v-for="index in 3" :key="index" />
          </div>
          <div class="row mb-4">
            <div class="col-md-12">
              <base-button
                :disabled="loadingSkeleton"
                :class="selectAll ? 'active' : ''"
                type="primary"
                @click.prevent="selectAllItems"
                outline
              >
                Selecionar todos
                <span class="badge badge-light text-dark"> {{ selectedItems }} / {{ $_items.length }} </span>
              </base-button>
            </div>
          </div>
          <!-- Tabela de serviços -->
          <section>
            <div v-if="$_items.length && !loadingSkeleton" class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-body mt-n2">
                    <div class="col-md-12 pl-md-0 table-responsive" style="overflow-y: scroll; max-height: 500px">
                      <table class="table table-sm table-bordered">
                        <thead>
                          <tr class="fixed">
                            <th class="text-center">Selec.</th>
                            <th class="text-center">Data</th>
                            <th class="text-center">Doc.</th>
                            <th class="text-center">Valor</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in $_items" :key="index">
                            <td class="text-center">
                              <i role="button" v-if="agroup.bill_pays.includes(item.id)" @click="handleRemove(item)" class="fas fa-check-square fa-2x text-primary"></i>
                              <i v-else @click="handleSelect(item)" role="button" class="fas fa-square fa-2x"></i>
                            </td>
                            <td class="text-center">{{ item.date | parseDate("DD MMM YYYY") }}</td>
                            <td class="text-center">
                              {{ item.all_document_numbers || "" }}
                            </td>
                            <td class="text-center numeric">
                              {{ item.final_value | currency }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-md-12">
                      <pagination @navegate="navegate" :source="source" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <puzl-empty-data v-if="!$_items.length && !loadingSkeleton" />
            <div v-show="loadingSkeleton" class="card-row row p-1 mt-4">
              <SkeletonPuzlFullWidth />
            </div>
          </section>
        </div>
        <div class="d-flex justify-content-center row m-3 pb-1">
          <div class="col-md-3" />
          <div class="col-md-3">
            <base-button block type="secondary" @click="closeModal()"> Fechar </base-button>
          </div>
          <div class="col-md-3 mt-3 mt-md-0">
            <base-button :loading="loading" :disabled="agroup.bill_pays.length === 0" block type="success" @click="store()">
              Salvar
            </base-button>
          </div>
          <div class="col-md-3" />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import Pagination from "@/components/Utils/Pagination.vue";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzlFullWidth from "@/components/SkeletonPuzlFullWidth";
import InputDatePicker from "@/components/InputDatePicker";

export default {
  name: "ModalAgroup",
  data() {
    return {
      modal: {
        title: "Buscar Contas a pagar",
        create: false,
      },
      filter: {},
      paginate: {
        page: null,
      },
      filter_by_due_date: false,
      loadingSkeleton: true,
      loadingBillPay: true,
      loading: false,
      multi_filter: {
        null: {
          name: "Todos",
          type: "primary",
        },
      },
      range: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        end: new Date(),
      },
      entity_id: null,
      bill_pay_id: null,
      source: null,
      agroup: {
        value_to_add: 0,
        total_value: 0,
        bill_pays: [],
      },
      selectAll: false,
    };
  },
  components: { SkeletonPuzlGrid, Pagination, PuzlEmptyData, SkeletonPuzlFullWidth, InputDatePicker },
  computed: {
    ...mapGetters({
      $_items: "billPay/agroup",
      $_bill_pay: "billPay/show",
    }),
    selectedItems() {
      return this.$_items.filter((item) => this.agroup.bill_pays.includes(item.id)).length;
    },
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    async openModal(bill_pay_id, entity_id) {
      let loader = this.$loading.show();
      this.loadingBillPay = true;
      this.entity_id = entity_id;
      this.bill_pay_id = bill_pay_id;
      this.selectAll = false;
      this.paginate.page = 1;
      this.filter_by_due_date = false;
      this.$refs["date-picker"].resetDate();
      this.range = {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        end: new Date(),
      };
      this.$store.dispatch("billPay/show", bill_pay_id).then((response) => {
        this.agroup = {
          value_to_add: 0,
          total_value: parseFloat(this.$_bill_pay.final_value),
          bill_pays: [],
        };
        this.loadingBillPay = false;
      });
      this.filter = {
        status: null,
        page: 1,
      };
      await this.init();
      loader.hide();
      this.modal.create = true;
    },
    selectAllItems() {
      this.selectAll = !this.selectAll;
      if (this.selectAll) {
        this.$_items.map(function (item) {
          if (!this.agroup.bill_pays.includes(item.id)) {
            this.handleSelect(item);
          }
        }, this);
      } else {
        this.$_items.map(function (item) {
          if (this.agroup.bill_pays.includes(item.id)) {
            this.handleRemove(item);
          }
        }, this);
      }
    },
    init(filter = null) {
      this.filter = filter ?? this.filter;
      this.filter.range = this.range;
      this.loadingSkeleton = true;
      return this.$store
        .dispatch("billPay/getAgroup", {
          entity_id: this.entity_id,
          bill_pay_id: this.bill_pay_id,
          filter: this.filter,
          page: this.paginate.page,
          filter_by_due_date: this.filter_by_due_date ? 1 : null,
        })
        .then((response) => {
          this.source = response;
          this.loadingSkeleton = false;
          this.selectAll = false;
          if (this.selectAll === true && this.selectedItems !== this.$_items.length) {
            this.selectAll = false;
          } else if(this.selectAll === false && this.selectedItems > 0 && this.selectedItems === this.$_items.length ) {
            this.selectAll = true;
          }
        });
    },
    navegate(page) {
      this.paginate.page = page;
      this.selectAll = false;
      this.init();
    },
    getBillPayInstallments(bill_pay) {
      if (bill_pay.installments === undefined) {
        this.loading = true;
        this.$store.dispatch("billPay/getByBillPay", bill_pay.id).then((response) => {
          bill_pay.installments = response.data;
          this.loading = false;
        });
      }
    },
    handleFilterDate(filter) {
      this.range = filter;
      this.init({});
    },
    handleSelect(item) {
      this.agroup.value_to_add += parseFloat(item.final_value);
      this.agroup.total_value += parseFloat(item.final_value);
      this.agroup.bill_pays.push(item.id);
    },
    handleRemove(item) {
      this.agroup.value_to_add = parseFloat((this.agroup.value_to_add - item.final_value).toFixed(2));
      if (this.agroup.value_to_add < 0) this.agroup.value_to_add = 0;
      this.agroup.total_value -= parseFloat(item.final_value);
      this.agroup.bill_pays.splice(
        this.agroup.bill_pays.findIndex((to_remove) => to_remove === item.id),
        1
      );
      if (this.agroup.bill_pays.length === 0) {
        this.selectAll = false;
      }
    },
    store() {
      this.agroup.bill_pay_id = this.bill_pay_id;
      this.loading = true;
      this.$store
        .dispatch("billPay/agroup", this.agroup)
        .then((response) => {
          this.loading = false;
          this.$emit("grouped");
          this.closeModal();
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
        })
        .catch((error) => {
          if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
          this.loading = false;
        });
    },
    changeGrouping(value) {
      this.filter_by_due_date = value;
      this.init({});
    },
    removeAll() {
      this.selectAll = false;
      this.agroup = {
        value_to_add: 0,
        total_value: parseFloat(this.$_bill_pay.final_value),
        bill_pays: [],
      };
    },
  },
};
</script>

<style scoped>
td,
th {
  font-size: 13px !important;
  vertical-align: middle!important; 
}
</style>
