<template>
  <div>
    <modal size="lg" class="bill-modal-create" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <div class="p-2">
          <!-- Produto/MCC -->
          <section>
            <div v-if="product_service_hub" class="border-left border-3 border-primary ml-1 mb-3">
              <div class="col-md-12">
                <h4 class="m-0">{{ `${product_service_hub.product_name} ${product_service_hub.cmc_name} ` }}</h4>
              </div>
            </div>
          </section>

          <!-- Tabela -->
          <section>
            <div class="row m-0 pt-0">
              <div class="col-md-12 p-1">
                <div class="card">
                  <div class="card-body mt-3">
                    <div class="row">
                      <div class="col-md-12 table-responsive" style="overflow-y: scroll; max-height: 500px">
                        <table class="table table-sm table-bordered">
                          <thead>
                            <tr class="fixed">
                              <th class="text-center">Data</th>
                              <th class="text-center">Emissão</th>
                              <th class="text-left">Central</th>
                              <th class="text-center">Doc.</th>
                              <th class="text-center">Quant.</th>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-if="$_items.length">
                              <tr v-for="(item, index) in $_items" :key="index">
                                <td class="text-center">{{ item.entry_date | parseDate("DD/MM/YYYY") }}</td>
                                <td class="text-center">{{ item.issue_date | parseDate("DD/MM/YYYY") }}</td>
                                <td class="text-left">
                                  {{ item.company_plant_name }}
                                </td>
                                <td class="text-center">
                                  {{ item.document_number }}
                                </td>
                                <td class="text-center font-weight-bold">
                                  {{ item.quantity }}
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                        <puzl-empty-data v-show="!$_items.length" />
                        <pagination :source="source" @navegate="navegate" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import Pagination from "@/components/Utils/Pagination";

export default {
  name: "ModalEntries",
  components: {
    PuzlEmptyData,
    Pagination,
  },

  data() {
    return {
      modal: {
        title: "Entradas",
        create: false,
      },
      product_service_hub: null,
      source: null,
      params: {
        page: 1,
      },
    };
  },
  computed: {
    ...mapGetters({
      $_items: "operationalStockResume/getProductServiceHubEntries",
    }),
  },
  props: {
    filter: {
      type: [Object],
      default: {},
      description: "Filtro",
    },
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },

    openModal(product_service_hub) {
      this.product_service_hub = product_service_hub;
      this.params.page = 1;
      this.init();
    },

    init() {
      const loader = this.$loading.show();
      const payload = {
        product_service_hub_id: this.product_service_hub.product_service_hub_id,
        filter: this.filter,
        page: this.params.page,
      };
      this.$store
        .dispatch("operationalStockResume/getProductServiceHubEntries", payload)
        .then((response) => {
          this.source = response;
          this.modal.create = true;
        })
        .finally(() => {
          loader.hide();
        });
    },

    navegate(page) {
      this.params.page = page;
      this.init();
    },
  },
};
</script>
