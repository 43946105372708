<template>
  <div>
    <modal :borderInfo="'2px solid #1b6eba'" :show.sync="modal" size="lg">
      <template slot="header">
        <div style="display: flex;align-items: center;">
          <img style="display: inline-flex;" src="/img/icons/icons8/ios/cash-receipt_white.png" width="22">
          <h5 style="display: inline-flex;" class="modal-title p-0 m-0 ml-3 text-white">{{ title }}</h5>
        </div>          
      </template>
      <div>
        <div class="container-fluid">
          <div class="row mt-2">
            <div class="col-12" style="display: flex; align-items: center;">
              <img src="/img/icons/icons8/ios/info-squared_warning.png" width="20">
              <h4 class="ml-2 new-default-black-font m-0">
                Informação
              </h4>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <h4 class="m-0 new-default-black-font">
                {{ tribute.city }}
              </h4>
              <h4 class="m-0 new-default-black-font font-weight-normal">
                {{ tribute.regime }}
              </h4>
              <h4 class="m-0 new-default-black-font font-weight-normal">
                Dedução
              </h4>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <div @click.prevent="newValidity(tribute, index)" class="pointer card-with-box-shadow text-center" style="border-radius: 5px !important;">
                <div class="mt-1" style="width: fit-content;display: inline-flex;align-items: center;">
                  <img src="/img/icons/icons8/ios/plus-math-success.png" width="22">
                  <h4 class="new-default-gray-font font-weight-normal m-0 ml-2">
                    Nova vigência de {{ tribute.tax_name }}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4" v-if="deduction_rules.length">
            <div class="col-12" style="display: flex; align-items: center;">
              <img src="/img/icons/order-history.png" width="20">
              <h4 class="ml-2 new-default-black-font m-0">
                Histórico
              </h4>
            </div>
          </div>
          <div class="row mt-3 mb-4" v-if="deduction_rules.length">
            <div class="col-12">
              <div class="card-with-box-shadow gray px-4 pb-4">
                <div class="mx-2 mt-4" v-for="(deductionRule, index) in deduction_rules" :key="index">
                  <div class="row align-items-center">
                    <div class="mx-md-2 p-2 w-100">
                      <div class="row align-items-center">
                        <div class="col-auto" style="display: flex; align-items: center;">
                          <img src="/img/icons/icons8/ios/general-ledger_default.png" width="22">
                          <h4 class="new-default-black-font m-0 ml-2">
                            {{ tribute.tax_name }} - {{ deductionRule.date_at | parseDate("DD MMM YYYY HH:mm") }}
                          </h4>
                        </div>
                        <div class="col">
                          <hr class="m-0">
                        </div>  
                      </div>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="mx-md-3 card-with-box-shadow p-2 w-100">
                      <div class="row align-items-center py-2 px-3">
                        <div class="col-8">
                          <div class="row">
                            <div class="col-12" style="display: flex; align-items: center;">
                              <h4 class="new-default-black-font m-0">
                                {{ tribute.tax_name }}
                              </h4>
                              <el-popover v-if="deductionRule.updated_by || deductionRule.created_by" trigger="click" placement="right">
                                <span style="width: 200px;"
                                  class="row">
                                  <div class="col-12 mb-1" style="display: flex; align-items: center;">
                                    <img class="mr-2" src="/img/icons/icons8/ios/user-male-circle--v1.png" width="22">
                                    <h4 class="p-0 m-0">Usuário</h4>
                                  </div>
                                  <div class="col-12">
                                    <h5 class="font-weight-normal" style="word-break: break-word;">
                                      {{ deductionRule.updated_by ? deductionRule.updated_by.name : deductionRule.created_by.name }}
                                    </h5>
                                  </div>  
                                </span>
                                <base-button outline slot="reference" size="sm" type="secundary"
                                        class="p-0 m-0 ml-2 shadow-none text-indigo">
                                    <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="22">
                                </base-button>
                              </el-popover>
                              <el-popover trigger="click" placement="left">
                                <span style="width: 370px;"
                                  class="row">
                                  <div class="col-12 mb-1" style="display: flex; align-items: center;">
                                    <img class="mr-2" src="/img/icons/info.png" width="22">
                                    <h4 class="p-0 m-0">Info alíquotas</h4>
                                  </div>
                                  <div class="col-12">
                                    <div class="row align-items-center mt-3">
                                      <div class="col-auto pr-0">
                                        <h5 class="font-weight-normal text-primary m-0">
                                          Condição
                                        </h5>
                                      </div>
                                      <div class="col px-3">
                                        <hr class="m-0">
                                      </div>
                                      <div class="col-auto pl-0 text-right">
                                        <h5 class="font-weight-normal text-primary m-0">
                                          {{ deductionRule.condition ? deductionRule.condition : 'Nenhuma' }}
                                        </h5>
                                      </div>
                                    </div>
                                    <div class="row align-items-center mt-3">
                                      <div class="col-auto pr-0">
                                        <h5 class="font-weight-normal new-default-black-font m-0">
                                          Deduzir custo de MCC
                                        </h5>
                                      </div>
                                      <div class="col px-3">
                                        <hr class="m-0">
                                      </div>
                                      <div class="col-auto pl-0 text-right">
                                        <h5 class="font-weight-normal new-default-black-font m-0">
                                          {{ transformBooleanToText(deductionRule.deduct_mcc_cost) }}
                                        </h5>
                                      </div>
                                    </div>
                                    <div class="row align-items-center mt-3">
                                      <div class="col-auto pr-0">
                                        <h5 class="font-weight-normal new-default-black-font m-0">
                                          Considerar Reuso
                                        </h5>
                                      </div>
                                      <div class="col px-3">
                                        <hr class="m-0">
                                      </div>
                                      <div class="col-auto pl-0 text-right">
                                        <h5 class="font-weight-normal new-default-black-font m-0">
                                          {{ transformBooleanToText(deductionRule.consider_reuse) }}
                                        </h5>
                                      </div>
                                    </div>
                                    <div class="row align-items-center mt-3">
                                      <div class="col-auto pr-0">
                                        <h5 class="font-weight-normal new-default-black-font m-0">
                                          Dedução
                                        </h5>
                                      </div>
                                      <div class="col px-3">
                                        <hr class="m-0">
                                      </div>
                                      <div class="col-auto pl-0 text-right">
                                        <h5 class="font-weight-normal new-default-black-font m-0">
                                          {{ deductionRule.condition === 'Adotar sempre o MCC' ? 'n/a' : deductionRule.deduct + '%'  }}
                                        </h5>
                                      </div>
                                    </div>
                                    <div class="row align-items-center mt-3">
                                      <div class="col-auto pr-0">
                                        <h5 class="new-default-black-font m-0">
                                          Base cálculo
                                        </h5>
                                      </div>
                                      <div class="col px-3">
                                        <hr class="m-0">
                                      </div>
                                      <div class="col-auto pl-0 text-right mt-2 mb-1">
                                        <h5 class="new-default-black-font m-0">
                                          {{ deductionRule.condition === 'Adotar sempre o MCC' ? 'n/a' : deductionRule.base + '%'  }}
                                        </h5>
                                      </div>
                                    </div>
                                    <div class="row align-items-center mt-3">
                                      <div class="col-auto pr-0">
                                        <h5 class="text-success font-weight-normal m-0">
                                          Alíquota total
                                        </h5>
                                      </div>
                                      <div class="col px-3">
                                        <hr class="m-0">
                                      </div>
                                      <div class="col-auto pl-0 text-right">
                                        <h5 class="font-weight-normal text-success m-0">
                                          {{ Number(deductionRule.total_rate) }} %
                                        </h5>
                                      </div>
                                    </div>
                                    <div class="row align-items-center mt-3">
                                      <div class="col-auto pr-0">
                                        <h5 class="new-default-black-font font-weight-normal">
                                          Alíquota retida
                                        </h5>
                                      </div>
                                      <div class="col px-3">
                                        <hr class="m-0">
                                      </div>
                                      <div class="col-auto pl-0 text-right">
                                        <h5 class="font-weight-normal new-default-black-font m-0">
                                          {{ Number(deductionRule.retained_rate) }} %
                                        </h5>
                                      </div>
                                    </div>
                                  </div>  
                                </span>
                                <base-button outline slot="reference" size="sm" type="secundary"
                                        class="p-0 m-0 ml-1 shadow-none text-indigo">
                                    <img src="/img/icons/info.png" width="22">
                                </base-button>
                              </el-popover>
                              <div class="ml-2" 
                                :class="getStatus(deductionRule.status.status).background"
                                style="display: inline-flex;">
                                <h4 class="font-weight-normal m-0 p-0">
                                  {{ getStatus(deductionRule.status.status).text }}
                                </h4>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-12">
                              <h4 class="font-weight-normal m-0 new-default-black-font">
                                Dedução: {{ deductionRule.condition === 'Adotar sempre o MCC' ? 'n/a' : Number(deductionRule.deduct) + '%'  }}
                              </h4>
                            </div>
                            <div class="col-12">
                              <h4 class="font-weight-normal m-0 new-default-black-font">
                                Base cálculo: {{ deductionRule.condition === 'Adotar sempre o MCC' ? 'n/a' : Number(deductionRule.base) + '%'  }}
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div class="col-4 text-right">
                          <div class="square-div">
                            <div class="mini-card-green text-center" style="height: 100%; display: grid; align-items: center;">
                              <h2 class="m-0">{{ Number(deductionRule.total_rate) }}%</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <pagination @navegate="navegate" :source="source"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Pagination from "@/components/Utils/Pagination.vue";

export default {
  components: {
    Pagination,
  },
  name: "ModalTaxRates",
  props: {
    newValidity: { type: Function },
  },
  data() {
    return {
      modal: false,
      title: "Alíquotas",
      tribute: {},
      loadingSkeleton: true,
      source: null,
      loadingSave: false,
      invalid: false,
      types: ["mini-card-red", "mini-card-green", "mini-card-yellow", "mini-card-yellow", "mini-card-blue"],
      types_description: ["Inativo", "Ativo", "Pendente Revisão", "Pendente Aprovação", "Rascunho", "Concluído"],
      index: null,
      params: {
        page: 1,
        per_page: 3,
      }
    };
  },
  computed: {
    ...mapGetters({
      deduction_rules: "deductionRules/show",
    }),
  },
  methods: {
    getStatus(status){
      switch(status){
        case 0: 
          return {
            textColor: "text-danger",
            background: "mini-card-red",
            text: "Inativo"
          };
        case 1: 
          return {
            textColor: "text-success",
            background: "mini-card-green",
            text: "Ativo"
          };
        default:
          return {
            textColor: "text-primary",
            background: "mini-card-blue",
            text: "Futuro"
          };
      }
    },
    closeModal() {
      this.modal = false;
    },
    openModalWithId(uuid){
      this.$store
        .dispatch("TaxLocality/show", uuid)
        .then((response) => {
          this.tribute = response.data;
          this.modal = true;
        })
        .catch((error) => {
          this.loadingSkeleton = false;
        });
    },
    transformBooleanToText(bool){
      return bool ? 'Sim' : 'Não';
    },
    async openModal(tribute, index) {
      this.modal = true;
      this.tribute = tribute;
      this.index = index;

      this.params.page = 1;
      this.params.uuid = 1;

      this.$store
        .dispatch("deductionRules/show", tribute.uuid)
        .then((response) => {
          this.source = response;
          this.loadingSkeleton = false;
        })
        .catch((error) => {
          this.loadingSkeleton = false;
        });
    },
    navegate(page) {
      this.params.page = page
      this.fetch()
    },
    save() {
    }
  },
};
</script>

<style scoped>
.card-with-box-shadow {
  box-shadow: 0 2px 4px 0 #f3f3f3;
  border: 1px solid #E8E8E8;
  box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15);
  border-radius: 15px !important;
}

.gray {
  background-color: #f5f5f5;
}

.new-default-gray-font {
  color: #606062;
}

.square-div {
  min-width: 55px;
  min-height: 55px;
  width: 55px;
  height: 55px;
  display: inline-block !important;
}

.mini-card-green h2 {
  color: #149e57;
}

.mini-card-green {
  background-color: #8bd5af52;
  border-radius: 5px;
  padding: 1px 5px;
  font-size: 12px;
  box-shadow: 0 0 5px 0 #149e5752;
}

.mini-card-red {
  background-color: #f459592e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #d2272752;
}

.mini-card-yellow {
  background-color: #f4dc592e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #fdef0b52;
}

.mini-card-blue {
  background-color: #4182e52e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #0b7bfd52;
}

.mini-card-gray {
  background-color: #646e7d2e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #5d636a52;
}

.mini-card-green h4 {
  color: #149e57;
}

.mini-card-red h4 {
  color: #db4539;
}

.mini-card-yellow h4 {
  color: #f2b532;
}

.mini-card-blue h4 {
  color: #1b6eba;
}

.mini-card-gray h4 {
  color: #66696c;
}

.mini-card-red h4, .mini-card-green h4, .mini-card-yellow h4, .mini-card-blue h4, .mini-card-gray h4 {
  font-size: 12px;
}
</style>