<template>
  <div>
    <modal :show.sync="modal.create" size="lg">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <div class="text-dark">
          {{ entity.name }}
        </div>
        <div class="text-dark">
          {{ entity.document }}
        </div>
        <div class="card-header border-header-primary-bottom mb-3 p-2"></div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <!-- CAMPO CONTRATO -->
            <div class="form-group row m-0 p-0">
              <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                Contrato
              </label>
              <div class="col-md-8 mb-1 pr-4">
                <base-input input-classes="form-control-sm">
                  <puzl-select
                    v-model="entityHistory.contract_proposal_id"
                    :items="$_contractProposals"
                    :labelMask="true"
                    label="code$ | $construction_name"
                    @input="getInvoicesByContractProposal(entityHistory.contract_proposal_id)"
                    :loading="loadingContractProposals"
                    :disabled="loadingContractProposals"
                  />
                </base-input>
              </div>
            </div>
            <!-- CAMPO DOCUMENTO -->
            <div class="form-group row m-0 p-0">
              <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                Documento
              </label>
              <div class="col-md-8 mb-1 pr-4">
                <base-input input-classes="form-control-sm">
                  <puzl-select
                    v-model="entityHistory.invoice_id"
                    :items="listInvoices"
                    :loading="loadingInvoices"
                    :disabled="loadingInvoices"
                  />
                </base-input>
              </div>
            </div>
            <!-- CAMPO DESCRIÇÃO -->
            <div class="form-group row m-0 p-0">
              <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                Descrição <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-8 mb-1 pr-4">
                <validation-provider rules="required" v-slot="{ errors }">
                  <base-input input-group-classes="input-group-sm">
                    <textarea
                      type="text"
                      rows="4"
                      class="form-control form-control-sm"
                      maxlength="1000"
                      v-model="entityHistory.description"
                    >
                    </textarea>
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')">
                Cancelar
              </base-button>
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                :loading="loadingStore"
              >
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "ModalCreateEntityHistory",
  components: {
    PuzlSelect,
  },
  data() {
    return {
      modal: {
        title: "Lançamento de registros do cliente",
        create: false,
      },
      entityHistory: {
        entity_id: null,
        contract_proposal_id: null,
        invoice_id: null,
        description: "",
      },
      entity: {
        id: null,
        uuid: '',
        name: '',
        document: '',
      },
      loadingStore: false,
      loadingContractProposals: true,
      loadingInvoices: false,
    };
  },
  computed: {
    ...mapGetters({
      $_contractProposals: "contractProposal/fetch",
      $_invoices: "billingInvoice/fetch",
    }),
    listInvoices() {
      if (this.entityHistory.contract_proposal_id) {
        return this.$_invoices.map(item => {
          item.name = item.nfse_number ? 'Nfs-e: ' + item.nfse_number + ' | ' + 'Fatura: ' + item.id 
          : 'Fatura: ' + item.id;
          return item;
        }, this);
      }
    }
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    /**
     * @param {number} entityId
     * @param {string} entityUuid
     * @param {string} name
     * @param {string} document
     */
    handleCreateModal(entityId, entityUuid, name, document) {
      this.entity = {
        id: entityId,
        uuid: entityUuid,
        name: name,
        document: document,
      };
      this.entityHistory = {
        entity_id: entityId,
        contract_proposal_id: null,
        invoice_id: null,
        description: null,
      };
      this.modal.create = true;
      
      this.$store.dispatch("contractProposal/getActiveContractsByEntity", this.entity.uuid).then((response) => {
        this.loadingContractProposals = false;
      });
    },
    /**
     * @param {number} contractProposalId
     */
    getInvoicesByContractProposal(contractProposalId) {
      if (contractProposalId) {
        this.loadingInvoices = true;
        this.$store
          .dispatch(
            "billingInvoice/getInvoiceByContractProposal",
            contractProposalId
          )
          .then(() => {
            this.loadingInvoices = false;
          });
      } else {
        this.entityHistory.invoice_id = null;
      }
    },
    store() {
      this.$Progress.start();
      this.loadingStore = true;
      this.$store
        .dispatch("entityRegistry/add", this.entityHistory)
        .then((response) => {
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.closeModal();
          this.$emit('storedEntityRegistry', response.data);
        })
        .catch((error) => {
          if (error.status === 200) {
            this.$notify({ type: "danger", message: error.data.message });
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({ type: "danger", message: errors });
          }
        })
        .finally(() => {
          this.loadingStore = false;
          this.$Progress.finish();
        });
    },
  },
};
</script>

<style scoped></style>
