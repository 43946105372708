<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-gray d-inline-block mb-0">{{ $route.meta.title }}</h6>
          <nav
            aria-label="breadcrumb"
            class="d-none d-md-inline-block ml-md-4 text-gray"
          >
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i></a></li>
              <li class="breadcrumb-item"><a href="#">Dashboard</a></li>
              <li class="breadcrumb-item" aria-current="page">{{ $route.meta.title }}</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-2 mb-2">
          <base-button block size="lg" type="success" @click.prevent="showModalCreate = true">
            <i class="fas fa-plus"></i> NOVO
          </base-button>
        </div>
      </div>
    </base-header>
    <multi-filter @fetch="init" :filter="filter"/>
    <modal-create @close="showModalCreate = false" :show="showModalCreate"/>
    <list-example ref="listExample"/>
  </div>
</template>

<script>
import MultiFilter from "@/components/Utils/MultiFilter";
import ModalCreate from "./Shared/_ModalCreate";
import ListExample from './Shared/_ListExample'
export default {
  name: "Index",
  components: {
    MultiFilter,
    ModalCreate,
    ListExample
  },
  data() {
    return {
      filter: {},
      showModalCreate: false,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {},
  }
}
</script>

<style scoped>

</style>
