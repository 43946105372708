<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-4">
          <h2 style="font-size: 1.2rem !important;" class="text-primary">
            <img height="30px" src="/img/icons/check.png" class="mr-3" />
            ESTOQUE MCC
          </h2>
        </div>
        <div class="col-md-8 pr-3">
          <div class="row d-flex justify-content-end show-md">
            <div class="col-md-3 px-1">
              <base-button @click.prevent="handleCreateModal" class="text-uppercase button-border" block type="success">
                <img height="19" src="/img/icons/icons8/ios/plus-math--v1-white.png" alt="new" class="mr-1" />
                NOVO
              </base-button>
            </div>
            <div class="col-md-3 pl-1">
              <router-link :to="{
                path: '/operational/stock/adjustment',
                name: 'operational.stock.adjustment.view',
              }">
                <base-button class="text-uppercase button-border" block type="dark">
                  <img height="19" src="/img/icons/icons8/ios/graph-report.png" alt="graph-report" class="mr-1" />
                  Ajustes
                </base-button>
              </router-link>
            </div>
          </div>
          <div class="row d-flex justify-content-end show-mobile">
            <div class="col-md-2 mb-2">
              <base-button @click.prevent="handleCreateModal" class="text-uppercase button-border" block type="success">
                <img height="19" src="/img/icons/icons8/ios/plus-math--v1-white.png" alt="new" class="mr-1" />
                NOVO
              </base-button>
            </div>
            <div class="col-md-2">
              <router-link :to="{
                path: '/operational/stock/adjustment',
                name: 'operational.stock.adjustment.view',
              }">
                <base-button class="text-uppercase button-border" block type="dark">
                  <img height="19" src="/img/icons/icons8/ios/graph-report.png" alt="graph-report" class="mr-1" />
                  Ajustes
                </base-button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </base-header>
    <list ref="list" />
    <CreateStock ref="modalCreate" @updatedPreviousStock="updatedPreviousStock" />
  </div>
</template>

<script>
import List from "./Shared/_List";
import CreateStock from "./Shared/_ModalCreate";

export default {
  name: "Index",
  components: { List, CreateStock },
  methods: {
    handleCreateModal() {
      this.$refs.modalCreate.handleCreateModal();
    },
    /**
     * @param {number} previousStockId
     */
    updatedPreviousStock(previousStockId) {
      this.$refs.list.updatedPreviousStock(previousStockId);
    }
  },
}
</script>

<style scoped>
.button-border {
  border-radius: 8px !important;
}
</style>
