import { ProductServiceHubTypeListType } from "./ProductServiceHubTypeListType";
import { PagerType, initPagerType } from "../../../../shared/types";

/**
 * Type para retorno paginado da listagem
 *
 * @typedef {(
 *   PagerType &
 *   { items: Array<ProductServiceHubTypeListType> }
 * )} ProductServiceHubTypeListPagerType
 */
export const ProductServiceHubTypeListPagerType = {};

/**
 * Inicializar ProductServiceHubTypeListPagerType
 *
 * @returns {ProductServiceHubTypeListPagerType}
 */
export const initProductServiceHubTypeListPagerType = () => ({
  ...initPagerType(),
  items: [],
});
