const keys = Object.freeze({
  ADJUST: 1,
  REMOVE: 2,
  EDIT: 3
});

/**
 * 
 * @param {number} value
 * @returns {string}
 */
const getTitle = (value) => {
  switch (value) {
    case keys.ADJUST:
      return "Ajuste";
    case keys.REMOVE:
      return "Remoção";
    case keys.EDIT:
      return "Edição";
    default:
      return "";
  }
};

export default {
  keys,
  getTitle
};
