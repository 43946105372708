<template>
<div>
    <modal :show.sync="modal.open">
        <template slot="header">
            <h5 class="modal-title">{{ modal.title }}</h5>
        </template>
        <div>
            <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
                <form class="needs-validation" @submit.prevent="handleSubmit(handleChargePointAdjustmentUpdate)" autocomplete="off">
                      <div>
                        <div class="form-group row m-0 p-0 pt-2">
                            <label class="col-md-5 pt-1 pb-3 mb-1 col-form-label form-control-label text-left">
                                Central
                                <span class="text-danger">&nbsp;*</span>
                            </label>
                            <div class="col-md-7 pb-3  pr-4">
                                <validation-provider rules="required" v-slot="{errors}">
                                    <base-input input-classes="form-control-sm">
                                        <PuzlSelect 
                                            v-model="secondAdjustment.company_plant.uuid" 
                                            :items="plants" 
                                            customKey="uuid" 
                                            @input="getChargePoints" />
                                    </base-input>
                                </validation-provider>
                            </div>
                        </div>
                        <div class="form-group row m-0 p-0">
                            <label class="col-md-5 pt-1 pb-3 mb-0 col-form-label form-control-label text-left pr-0">
                                Ponto de carga
                                <span class="text-danger">&nbsp;*</span>
                             </label>
                            <div class="col-md-7 pr-4">
                                <validation-provider rules="required" v-slot="{errors}">
                                    <base-input input-group-classes="input-group-sm">
                                        <PuzlSelect 
                                            v-model="secondAdjustment.charge_point.uuid" 
                                            :items="chargePoints" 
                                            customKey="uuid" 
                                            :disabled="!secondSelect || !secondAdjustment.company_plant.uuid" />
                                    </base-input>
                                </validation-provider>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <base-button type="secondary" @click="closeModal()">
                            Cancelar
                        </base-button>
                        <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                            Salvar
                        </base-button>
                    </div>
                </form>
            </validation-observer>
        </div>
    </modal>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { checkNumberValue } from '@/helpers'
import { mask1,formatErrorValidation } from '@/plugins'
import PuzlSelect from "@/components/PuzlSelect"
export default {
    name: "EditChargePointAdjustment",
    components: { PuzlSelect },
    data() {
        return {
            modal: {
                title: 'Ajuste Ponto de Carga',
                open: false,
            },
            secondAdjustment:{
                company_plant: {
                    uuid:null,
                    name:null,
                },
                charge_point: {
                    uuid:null,
                    name:null,
                },
                uuid:null,
            },
            adjustment: {
                plant_uuid: null,
                charge_point_uuid:null,
                uuid:null,
            },
            loadingStore: false,
            secondSelect: false,
        }
    },
    computed: {
        ...mapGetters({
            'listAdjustment': 'adjustmentChargePoint/fetch',
            'plants': 'plant/activeItems',
            'chargePoints': 'plantChargePoint/fetch'
        })
    },
    methods: {
        formatString(value, min, max, decimals = "") {
                if (decimals) {
                    value = mask1(checkNumberValue(value, min, max).toString(), decimals, '.', true)
                    return checkNumberValue(value, min, max)
                }
                return checkNumberValue(value, min, max)
        },
        closeModal() {
            this.modal.open = false
        },
        handleEditModal(uuid) {
            this.$notify({
                type: 'info',
                message: 'Estamos trabalhando em sua solicitação.'
            })
            this.$Progress.start()
            this.loadData()
            this.$store.dispatch('adjustmentChargePoint/show', uuid)
                .then((response) => {
                    this.secondAdjustment = {
                        company_plant: {
                            uuid:response.data.company_plant.uuid || '',
                            name:response.data.company_plant.name || '',
                        },
                        charge_point: {
                            uuid:response.data.charge_point.uuid || '',
                            name:response.data.charge_point.name || '',
                        },
                        uuid: response.data.uuid || ''
                    }
                    this.$store.dispatch('plantChargePoint/getByPlant',this.secondAdjustment.company_plant.uuid).then(() => {
                        this.$Progress.finish()
                        this.modal.open = true
                        this.$notify({
                            type: response.error_type,
                            message: response.message
                        })
                    }) 
                }).catch((error) => {
                    if (error.response.status === 422) {
                        let errors = formatErrorValidation(error.response.data.errors)
                        this.$notify({
                            type: 'danger',
                            message: errors
                        })
                    }
                    this.$Progress.finish()
                    this.loadingStore = false
                })
        },
        handleChargePointAdjustmentUpdate() {
            this.$Progress.start()
            this.loadingStore = true
            this.adjustment.plant_uuid = this.secondAdjustment.company_plant.uuid
            this.adjustment.charge_point_uuid = this.secondAdjustment.charge_point.uuid
            this.adjustment.uuid = this.secondAdjustment.uuid
            this.$store.dispatch('adjustmentChargePoint/update', this.adjustment)
                .then(response => {
                    this.loadingStore = false
                    this.modal.open = false
                    this.$Progress.finish()
                    this.$notify({
                        type: response.error_type,
                        message: response.message
                    })
                    this.$emit("updatedChargePointAdjustment");
                })
                .catch(error => {
                    if (error.status == 200) {
                        this.$notify({
                            type: 'danger',
                            message: error.data.message
                        })
                        this.$Progress.finish();
                        this.loadingStore = false
                    } else if (error.response && error.response.status === 422) {
                        let errors = formatErrorValidation(error.response.data.errors)
                        this.$notify({
                            type: 'danger',
                            message: errors
                        })
                    }
                    this.$Progress.finish()
                    this.loadingStore = false
                })
        },
        getChargePoints(){
            if(this.secondAdjustment.company_plant.uuid){
                this.secondAdjustment.charge_point.uuid = null
                this.secondSelect = false
                this.loadingStore = true
                this.$store.dispatch('plantChargePoint/getByPlant',this.secondAdjustment.company_plant.uuid).then(() => {
                    this.loadingStore = false
                    this.secondSelect = true
                }) 
            } else {
                this.secondAdjustment.charge_point.uuid = null
            }
            this.$forceUpdate()
        },
        loadData() {
            this.loadingPlants = true;
            this.$store.dispatch('plant/getPlantsSimplified').then(() => {
                this.loadingPlants = false;
            });
        },
    },
    mounted() {
        this.$refs.formValidator.validate();
    },
}
</script>

<style scoped>

</style>
