<template>
  <div>
    <PageHeader
      title="Plano de Contas Contábil"
      icon="/img/icons/icons8/ios/increase_success.png"
      type="success"
      isBackButtonEnabled="true">
        <PageHeaderButton @click="handleCreateModal"
          text="Novo"
          type="success"
          icon="/img/icons/plus-math--v1-white.png" />
    </PageHeader>

    <div class="container-fluid">
      <div class="mt-5">
        <TreeView :items="$_accounts" @init="init" />
      </div>
    </div>
    <ModalCreateAccount ref="modalCreateAccount" @init="init" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { PageHeader, PageHeaderButton } from "@/components";
import ModalCreateAccount from "./_ModalCreateAccount.vue";
import TreeView from './_TreeView.vue';

export default {
  name: "ListAccoutingChartAccount",
  components: {
    PageHeader,
    PageHeaderButton,
    ModalCreateAccount,
    TreeView
  },
  async mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      $_accounts: "accountingChartAccount/fetch",
    })
  },
  methods: {
    async init() {
      await this.$store.dispatch('accountingChartAccount/fetchItems', {
      }).then((response) => {
      }).catch((error) => {
        this.$notify({
          type: 'danger',
          message: 'Erro ao carregar tasks do dashboard'
        })
      });
    },
    handleCreateModal() {
      this.$refs.modalCreateAccount.handleCreateModal();
    },
  }
}

</script>