<template>
  <div>
    <AppFixedPageTitle
      title="TIPOS DE OPERAÇÃO PUZL"
      icon="/img/icons/ledger-white.png"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          :isLoading="isListing"
          @onSearchClick="listItems"
          @onClearClick="clearFilter"
        >
          <AppSearchBarFilterSection
              name="status"
              icon="/img/icons/icons8/ios/progress-indicator_grey.png"
          >
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label
                  class="form-control-label fs-11 new-default-gray-font font-weight-400"
                >
                Selecionar
              </label>
              <PuzlSelect
                style="width: 100%"
                v-model.lazy="filter.status"
                :items="statusToSelect"
                :disableBoxShadow="true"
                placeholder="Status"
                label="label"
                class="select-sm col-md-12 px-0 new-default-black-font"
              />
            </div>
          </AppSearchBarFilterSection>
          <AppSearchBarFilterSection
              name="dados"
              icon="/img/icons/icons8/ios/info-squared_gray.png"
          >
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label
                  class="form-control-label fs-11 new-default-gray-font font-weight-400"
                >
                Selecionar
              </label>
              <PuzlSelect
                style="width: 100%"
                v-model.lazy="filter.product_service_hub_type_id"
                :items="[{ id: 2, name: 'MCC' }, ...filteredHubTypes]"
                :disableBoxShadow="true"
                placeholder="Categoria"
                class="select-sm col-md-12 px-0 new-default-black-font"
              />
            </div>
          </AppSearchBarFilterSection>
        </AppSearchBar>
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions
          :isBackButtonEnabled="true"
        >
          <AppPageHeaderActionsButton
            @click="openOperationSourceCreateModal"
            text="novo"
            type="success"
            icon="/img/icons/plus-math--v1-white.png"
          />
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>
    <AppTabSelect
      :items="tabSelectItems"
      :isShowViewTypes="false"
      @onTabSelectItemClick="onTabSelectItemClick"
      @onViewTypeChange="(type) => listType = type"
    >
      <AppSelect
        v-model="orderBy.selected"
        :items.sync="orderBy.items"
        @onSelectItemClick="listItems(false)"
        variant="text-only"
      />
    </AppTabSelect>

    <div class="container-fluid">
      <OperationSourceListPageCards
        @onEditItemClick="openOperationSourceEditModal"
        @onRemoveItemClick="removeOperationSource"
        @onUpdateStatusItemClick="updateStatusOperationSource"
        @onOperationNatureClick="openOperationNatureModal"
      />
      <AppViewTrigger v-if="!isListing" @onIntersected="listItems(true)" />
      <PuzlEmptyData v-if="!isListing && !listed.items.length"/>
    </div>
    <OperationSourceCreateModal ref="operationSourceCreateModal" />
    <OperationSourceEditModal ref="operationSourceEditModal" />
    <OperationNatureModal ref="operationNatureModal"/>
  </div>
</template>

<script setup>
import { reactive, ref, computed, onMounted, onUnmounted } from 'vue';
import operationSourceStore from "../../store";
import { useOperationSourceHook } from "../../hooks/useOperationSourceHook";
import { initOperationSourceListFilterType, OperationSourceListFilterType } from '../../types';
import OperationSourceStatusEnum from "../../enums/OperationSourceStatusEnum";
import OperationSourceOperationNatureEnum from "../../enums/OperationSourceOperationNatureEnum";
import { 
  AppFixedPageTitle, 
  AppPageHeader, 
  initSearchBarFilterType,
  AppSearchBarFilterSection,
  SearchBarFilterType,
  AppSearchBar,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  AppViewTrigger,
  AppTabSelect,
  AppSelect,
  TabSelectItemType,
} from '../../../../../components/AppGlobal';
import { dialogs } from '../../../../../helpers';
import { Ref } from 'vue';
import OperationSourceListPageCards from './OperationSourceListPageCards.vue';
import PuzlEmptyData from "@/components/PuzlEmptyData";
import PuzlSelect from "@/components/PuzlSelect";
import OperationSourceCreateModal from "../create/OperationSourceCreateModal.vue";
import { OperationSourceCreateModalExpose } from "../create/OperationSourceCreateModal.vue";
import OperationSourceEditModal from "../edit/OperationSourceEditModal.vue";
import { OperationSourceEditModalExpose } from "../edit/OperationSourceEditModal.vue";
import OperationNatureModal from '../operation-nature/OperationNatureModal.vue';

const statusToSelect = OperationSourceStatusEnum.getToSelect;

/** Store Getters && Computeds */
const listed = computed(() => operationSourceStore.getListed());
const isListing = computed(() => operationSourceStore.getIsListing());

const { filteredHubTypes } = useOperationSourceHook();

const tabSelectItems = computed(() => {
  return [
    {
      id: OperationSourceOperationNatureEnum.keys.ALL,
      name: 'Todos',
      selected: filter.operation_nature === OperationSourceOperationNatureEnum.keys.ALL,
    },
    {
      id: OperationSourceOperationNatureEnum.keys.IS_ENTRY_VALUE,
      name: 'Entradas',
      selected: filter.operation_nature === OperationSourceOperationNatureEnum.keys.IS_ENTRY_VALUE,
    },
    {
      id: OperationSourceOperationNatureEnum.keys.IS_OUTPUT_VALUE,
      name: 'Saídas',
      selected: filter.operation_nature === OperationSourceOperationNatureEnum.keys.IS_OUTPUT_VALUE,
    },
  ];
});

/** Refs */
/** @type {Ref<OperationSourceCreateModalExpose>} */
const operationSourceCreateModal = ref(null);

/** @type {Ref<OperationSourceEditModalExpose>} */
const operationSourceEditModal = ref(null);

/** @type {Ref<OperationNatureModal>} */
const operationNatureModal = ref(null);

const listType = ref('cards');

/** Reactives */
/** @type {SearchBarFilterType} - Filtro do AppSearchBar */
const searchBarFilter = reactive(initSearchBarFilterType());

/** @type {OperationSourceListFilterType} - Filtro principal */
const filter = reactive(initOperationSourceListFilterType());

const orderBy = reactive({
  items: [
    {
      id: 0,
      name: "PADRÃO",
      selected_name: "ORDENAR",
      filter: [
        {
          column: "operation_sources.name",
          is_desc: false,
        },
        {
          column: "operation_sources.id",
          is_desc: true,
        }
      ],
    },
    {
      id: 1,
      name: "A-Z",
      selected_name: "A-Z",
      icon: "/img/icons/icons8/ios/double-down.png",
      filter: [{
        column: "operation_sources.name",
        is_desc: false,
      }],
    },
    {
      id: 2,
      name: "Z-A",
      selected_name: "Z-A",
      icon: "/img/icons/icons8/ios/double-up.png",
      filter: [{
        column: "operation_sources.name",
        is_desc: true,
      }],
    },
    {
      id: 3,
      name: "DO MAIS VELHO PARA O MAIS NOVO",
      selected_name: "MAIS VELHO",
      icon: "/img/icons/icons8/ios/double-down.png",
      filter: [{
        column: "operation_sources.created_at",
        is_desc: true,
      }],
    },
    {
      id: 4,
      name: "DO MAIS NOVO PARA O MAIS VELHO",
      selected_name: "MAIS NOVO",
      icon: "/img/icons/icons8/ios/double-up.png",
      filter: [{
        column: "operation_sources.created_at",
        is_desc: false,
      }],
    },
  ],
  selected: 0,
});

onMounted(() => {
  clearFilter(true, true);
});

onUnmounted(() => {
  searchBarFilter.custom_search_values = [];
  operationSourceStore.resetStates();
  operationSourceStore.setPageState({ filter, searchBarFilter });
});

/**
 * @param {TabSelectItemType} item
 */
function onTabSelectItemClick(item) {
  if (filter.operation_nature !== item.id) {
    filter.operation_nature = item.id;
    listItems();
  };
};

/**
 * Limpa os filtros e atualiza a lista de itens.
 *
 * @param {boolean} isRefreshList - Atualiza a lista após limpar os filtros.
 * @param {boolean} withStoreFilters - Usa filtros da store se verdadeiro.
 */
function clearFilter(isRefreshList = true, withStoreFilters = false) {
  // Inicializa filtros com os valores padrão
  let searchBarFilterValue = defaultSearchBarFilter();
  let filterValue = defaultFilter();
  
  // Caso `withStoreFilters` seja verdadeiro, obter filtros armazenados na store
  const storeFilters = withStoreFilters ? operationSourceStore.getPageState() : null;
  if (storeFilters) {
    searchBarFilterValue = storeFilters.searchBarFilter;
    filterValue = storeFilters.filter;
  }

  // Aplica os filtros
  Object.assign(searchBarFilter, searchBarFilterValue);
  Object.assign(filter, filterValue);
  
  // Listar itens
  if (isRefreshList) {
    listItems();
  }
};

/**
 * Padrão do filtro AppSearchBar
 * @returns {SearchBarFilterType}
 */
function defaultSearchBarFilter() {
  return initSearchBarFilterType();
};

/**
 * Padrão do filtro principal
 * @returns {OperationSourceListFilterType}
 */
function defaultFilter() {
  return {
    ...initOperationSourceListFilterType(),
    status: null,
    operation_nature: 0
  };
};

/**
 * Listar itens
 * @param {boolean} isAccumulateItems
 */
function listItems(isAccumulateItems = false) {
  prepareFilter();
  operationSourceStore.list(filter, isAccumulateItems);
  //productServiceHubTypeStore.list(filter, isAccumulateItems);
};

/**
 * Preparar filtro principal para listagem
 */
function prepareFilter() {
  filter.page = "";
  filter.custom_search.values = searchBarFilter.custom_search_values;
  filter.order_by = orderBy.items[orderBy.selected].filter;
};

/** 
 * Abrir modal para criar registro
 */
function openOperationSourceCreateModal() {
  operationSourceCreateModal.value.openModal();
}

/**
 * Abrir modal para editar registro
 * @param {number} id
 */
function openOperationSourceEditModal(id) {
  operationSourceEditModal.value.openModal(id);
}

const openOperationNatureModal = (id) => {
  operationNatureModal.value.handleOpenModal(id);
};

/**
 * Remover registro
 * @param {number} id
 */
async function removeOperationSource(id) {
  const isConfirmed = await dialogs.confirmRemove();
  if (isConfirmed) {
    await operationSourceStore.remove(id);
    dialogs.notifyRemoved();
  }
};

/**
 * Atualizar status do registro
 * @param {boolean} status
 * @param {number} id
 */
async function updateStatusOperationSource(status, id) {
  await operationSourceStore.updateStatus(status, id);
  dialogs.notify();
};


</script>

<style scoped>
</style>