<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(save)"
            autocomplete="off"
          >
            <div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 col-form-label form-control-label">
                  Status
                </label>
                <div class="col-md-7 pt-2">
                  <base-switch
                    v-model="type.status"
                    type="success"
                    offText="inativo"
                    onText="ativo"
                    class="success"
                  >
                  </base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-5 pt-1 pb-0 col-form-label form-control-label"
                >
                  Descrição
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="type.description"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">
                Cancelar
              </base-button>
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                :loading="loadingSave"
              >
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
const {formatErrorValidation} = require("@/plugins")

export default {
  name: "ModalCreateType",
  data () {
    return {
      modal: false,
      title: 'Tipo de obra',
      type: {
        status: true,
        description: '',
      },
      loadingSave: false,
    }
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
  methods: {
    closeModal () {
      this.modal = false
    },
    handleCreateModal () {
      this.type = {
        status: true,
        description: '',
      }
      this.modal = true
    },
    save () {
      this.$Progress.start()
      this.loadingSave = true
      this.$store.dispatch('customerConstructionType/add', this.type)
      .then(response => {
        this.loadingSave = false
        this.modal = false
        this.$Progress.finish()
        this.$notify({ type: response.error_type, message: response.message })
      })
      .catch(error =>{
        if(error.response.status===422){
          let message = formatErrorValidation(error.response.data.errors)
          this.$notify({ type: 'danger', message: message })
        }
        this.$Progress.finish()
        this.loadingSave = false
      })
    },
  },
}
</script>

<style scoped>

</style>
