import { createAxios } from "@/plugins/axios";
import * as types from './mutation_types'
import {cursorPaginate, formatResponseData} from '@/store/baseStore'

const endPoint = '/purchase'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
  },
  getters:{
    show: state => state.item,
    fetch: state => state.items,
  },
  mutations: {
  },
  actions: {
    fetchItemsPaginate({state}, params) {
      return createAxios().get(`${endPoint}/purchase_requests`, {params: params})
        .then(({data}) => {
          const formattedData = formatResponseData(data);
          cursorPaginate(state, {data : formattedData.data, next_page: params.page})
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchRequestItems({commit, state}, params) {
      return createAxios().get(`${endPoint}/purchase_request_items`, { params: params })
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchRequestAttachments({commit, state}, params) {
      return createAxios().get(`${endPoint}/purchase_request_attachments`, { params: params })
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getHistory({}, id) {
      return createAxios().get(`${endPoint}/purchase_request_history/${id}`)
        .then(({data}) => {
          const formattedData = formatResponseData(data);
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    add({ commit }, params) {
      let formData = new FormData();
      // Adiciona todos os campos de params ao FormData
      for (let key in params) {
        if (key !== 'attachments' && key !== 'items') {
          formData.append(key, params[key]);
        }
      }
      // Adiciona os anexos, se existirem
      if (params.attachments && params.attachments.length) {
        params.attachments.forEach((el, index) => {
          formData.append(`files[${index}]`, el.file);
        });
      }

      // Adiciona os itens, se existirem
      if (params.items && params.items.length) {
        params.items.forEach((item, index) => {
          for (let key in item) {
            let value = item[key];
            if (key === 'quantity') {
              value = parseFloat(value.replace(',', '.'));
            }
            formData.append(`items[${index}][${key}]`, value);
          }
        });
      }

      return createAxios().post(`${endPoint}/purchase_requests`, formData)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    update({ commit }, params) {
      let formData = new FormData();
      // Adiciona todos os campos de params ao FormData
      for (let key in params) {
        if (key !== 'attachments' && key !== 'items') {
          formData.append(key, params[key]);
        }
      }
      // Adiciona os anexos, se existirem
      if (params.attachments && params.attachments.length) {
        params.attachments.forEach((el, index) => {
          formData.append(`files[${index}]`, el.file);
        });
      }

      // Adiciona os itens, se existirem
      if (params.items && params.items.length) {
        params.items.forEach((item, index) => {
          for (let key in item) {
            let value = item[key];
            if (key === 'quantity') {
              if (typeof value === 'string' && value.includes(',')) {
                value = parseFloat(value.replace(',', '.'));
              } else {
                value = parseFloat(value);
              }
            }
            formData.append(`items[${index}][${key}]`, value);
          }
        });
      }
      // Realiza a solicitação
      return createAxios().post(`${endPoint}/purchase_requests/${params.id}`, formData)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    destroy({commit}, id) {
      return createAxios().delete(`${endPoint}/purchase_request_items/${id}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    show({commit}, id) {
      return createAxios().get(`${endPoint}/purchase_request/${id}`)
        .then(({data}) =>{
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
