/**
 * Enum para representar diferentes conjuntos de filtros de barra de pesquisa.
 * @readonly
 * @enum {number}
 */
export const BOTTOM_FILTER_ENUM = Object.freeze({
  START_TODAY_MONTH: 1, // Início, Hoje e Mês
  ALL_CURRENT_MONTH_PREVIOUS_MONTH: 2, // Todos, Mês atual, Mês anterior
  YESTERDAY_TODAY_TOMORROW: 3, // Ontem, Hoje, Amanhã
  TODAY_CURRENT_MONTH_PREVIOUS_MONTH: 4, // Hoje, Mês atual, Mês anterior
  TODAY_TOMORROW_CURRENT_MONTH: 5, // Hoje, Amanhã, Mês atual
  YESTERDAY_CURRENT_MONTH_PREVIOUS_MONTH: 6, // Ontem, Mês atual, Mês anterior
});