<template>
<div>
  <multi-filter ref="multi-filter" @fetch="init" :filter="filter" />
  <div class="container-fluid">
    <div class="row card-wrapper" v-show="loadingSkeleton">
      <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
    </div>
    <PuzlEmptyData v-if="!customers.length && !loadingSkeleton"></PuzlEmptyData>
    <div class="row card-wrapper" v-show="customers.length && !loadingSkeleton">
      <div class="col-lg-4"
           v-for="(customer, index) in customers"
           :key="index"
      >
        <!-- Basic with action button -->
        <card :class="customer.status ? 'card-border-top-success' : 'card-border-top-danger'">
          <!-- Card header -->
          <div class="row align-items-center mb-3">
            <div class="col-8">
              <!-- Title -->
              <h5 class="h4 mb-0">
                {{ customer.customer_name }}
              </h5>
              <h4>
                <small v-if="customer.document">{{customer.document}}</small>
                <br v-if="customer.document">
                <small>Cód.: {{customer.id}}</small>
              </h4>
            </div>

            <div class="col-4 text-right">
              <base-dropdown menuOnRight>
                <base-button
                  slot="title-container"
                  type="primary"
                  class="dropdown-toggle p-2 rounded m-0"
                >
                  <i class="fas fa-cog"></i>
                </base-button>
                <div class="dropdown-divider p-0 m-0"></div>
                <router-link :to="{
                    path:'/commercial/customer-construction/constructions',
                    name: 'commercial.customer-construction.constructions',
                    params: { customer_construction_uuid: customer.uuid }}"
                >
                  <a class="dropdown-item">
                    <i class="fas fa-building text-primary"></i>
                    Obras
                  </a>
                </router-link>
                <div class="dropdown-divider p-0 m-0"></div>
                <a
                  class="dropdown-item"
                  @click="handleEditCustomer(customer.uuid)"
                >
                  <i class="fas fa-edit text-warning"></i>
                  Editar
                </a>
                <div class="dropdown-divider p-0 m-0"></div>
                <a class="dropdown-item" @click="handleDeleteCustomer(customer.uuid)">
                  <i class="fas fa-times text-danger"></i>
                  Excluir
                </a>
              </base-dropdown>
            </div>
          </div>

          <!-- Card body -->
          <div  @click.prevent="getConstruction(index, customer.uuid)">
            <collapse class="border rounded p-0 mt-3" v-if="!loadingSkeleton && !loading">
              <collapse-item
                class="header-gray"
                back-ground="border-header-primary-bottom"
              >
                <h5 slot="title" class="mb-0">Obras</h5>
                <div v-show="customer.loading" class="row">
                  <SkeletonPuzlFullWidth/>
                </div>
                <collapse class="border rounded p-0 mt-2"
                          v-for="(construction, indexConstruction) in customer.construction"
                          :key="indexConstruction"
                >
                  <collapse-item
                    class="header-gray"
                    :is-active="true"
                    back-ground="border-header-primary-bottom"
                  >
                     <h5 slot="title" class="mb-0 ">
                      {{construction.construction_name}} <small>(Cód. {{construction.id}})</small>
                    </h5>
                  <h5 class="h3" v-if="construction.contractProposal"
                      :class="[
                    construction.contractProposal.contract_proposal_status===1 ? 'text-success' : '',
                    construction.contractProposal.contract_proposal_status===2 ? 'text-warning' : '',
                    construction.contractProposal.contract_proposal_status===3 ? 'text-orange' : '',
                    construction.contractProposal.contract_proposal_status===4 ? 'text-muted' : '',
                    ]"
                  >
                    <badge  tag="a" href="#" :type="types[construction.contractProposal.contract_proposal_status]">
                      {{ statusName[construction.contractProposal.contract_proposal_status] }}
                    </badge>
                  </h5>
                  <div class="row" v-if="construction.defaultAddress">
                    <div class="col-md-12">
                      <span class="mt-4 p">
                        <h5 class="h4">
                          {{ construction.defaultAddress.city }}
                          /
                          {{ construction.defaultAddress.state }}
                        </h5>
                        <span class="h4 small">
                          {{ construction.defaultAddress.address || '' }},
                          {{ construction.defaultAddress.number || '' }},
                          {{ construction.defaultAddress.district || '' }}
                        </span>
                        <br/>
                      </span>
                      <span class="mt-4 p">
                        <span class="h4 small">
                          {{ construction.defaultAddress.postal_code || '' }}
                        </span>
                      </span>
                    </div>
                  </div>
                  </collapse-item>
                </collapse>
              </collapse-item>
              <collapse-item
                class="header-gray"
                back-ground="border-header-primary-bottom"
                v-if="loadingSkeleton || loading"
              >
                <h5 slot="title" class="mb-0">Aguarde...</h5>
              </collapse-item>
            </collapse>
          </div>
        </card>
      </div>
    </div>
  </div>
  <loading-pagination :show="loading && !loadingSkeleton"/>
  <modal-edit-customer ref="editCustomer"></modal-edit-customer>
</div>
</template>
<script>
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import {mapGetters} from 'vuex'
import PuzlEmptyData from "@/components/PuzlEmptyData";
import ModalEditCustomer from './_Edit'
import MultiFilter from '@/components/Utils/MultiFilter.vue';
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import SkeletonPuzlFullWidth from "@/components/SkeletonPuzlFullWidth";
export default {
  name: "ListCustomerConstruction",
  mixins: [cursorPaginate],
  components: {
    PuzlEmptyData,
    SkeletonPuzlGrid,
    ModalEditCustomer,
    MultiFilter,
    LoadingPagination,
    SkeletonPuzlFullWidth,
  },
  data () {
    return {
      loadingSkeleton: false,
      statusName: [
        'Inativo',
        'Ativo',
        'Pendente Revisão',
        'Pendente Aprovação',
        'Rascunho',
      ],
      types: [
        'danger',
        'success',
        'yellow',
        'warning',
        'primary',
      ],
      filter: {},
    }
  },
  computed: {
    ...mapGetters({
      'customers': 'customerConstruction/fetch',
    }),
  },
  methods:{
    init (filter = null) {
      this.startCursor(filter)
      this.$store.dispatch('customerConstruction/fetchItemsPaginate', {
          filter: this.filter,
          next_page: this.paginate.nextUrl
      })
        .then(response => {
          this.resolveCursor(response)
        })
        .catch( error => {
          this.resolveCursor()
        })
    },
    handleEditCustomer(uuid){
      this.$refs.editCustomer.handleEditModal(uuid)
    },
    handleDeleteCustomer(uuid){
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed){
          this.$Progress.start();
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          this.$store.dispatch('customerConstruction/destroy', uuid)
          .then((response) => {
             this.$notify({
                type: response.error_type,
                message: response.message
              });
              this.$Progress.finish();
          })
          .catch(error => {
              this.$notify({
                type: "danger",
                message: error.data.message
              });
              this.$Progress.finish();
          });
        }
      }).catch(() => this.$Progress.finish())
    },
    async getConstruction(indexCustomer, uuid) {
      if (!this.customers[indexCustomer].construction) {
        this.$set(this.customers[indexCustomer], "loading", true);
        this.$store.dispatch('customerConstructionConstructions/getConstructionsByCustomerConstruction', {uuid: uuid})
        .then((response) => {
          this.customers[indexCustomer].construction = response.data;
          this.$forceUpdate();
          this.customers[indexCustomer].loading = false;
        })
      }
    },
  },
  mounted() {
    this.init({})
  },
  created () {

  },
}
</script>

<style scoped>

</style>
