<template>
  <div class="container-fluid">
    <div class="mt-5">
      <MultiFilter
        :showSlotDates="false"
        :showSlotData="false"
        :showSlotBookmark="true"
        :showSlotStatus="false"
        @clearFilter="clearFilter()"
        @fetch="handleFetch"
        @additionalFiltersToggled="additionalFiltersToggled"
        @handleFilterDate="handleFilterDate"
      >
        <template slot="bookmarks">
          <div class="col-12 mt-1 mb-1">
            <base-button
              :class="filter.external == false && 'active'"
              outline block type="success" 
              @click="
                filter.external = filter.external == false ? null : 0;
                init(filter, true)
              "
            >
              <img 
                :src="
                  filter.external == false
                    ? '/img/icons/icons8/ios/internal-white.png'
                    : '/img/icons/icons8/ios/internal.png'
                " 
                width="21px" 
                height="21px" 
                class="invert-on-hover" 
              />
              <b :class="filter.external == false ? 'text-white' : ''"> 
                Interno 
              </b>
            </base-button>
          </div>
          <div class="col-12 mt-1">
            <base-button
              :class="filter.external == true && 'active'"
              outline block type="danger" 
              @click="
                filter.external = filter.external == true ? null : 1;
                init(filter, true)
              "
            >              
            <img 
              :src="
                filter.external == true
                  ? '/img/icons/icons8/ios/external-white.png'
                  : '/img/icons/icons8/ios/external.png'
              " 
              width="21px" 
              height="21px" 
              class="invert-on-hover" 
            />
             <b :class="filter.external == true ? 'text-white' : ''"> 
                Externo 
              </b>
            </base-button>
          </div>
        </template>
        <template slot="order-by">
          <el-popover
            trigger="click"
            placement="bottom"
            class="p-0 float-right pr-0 ml-3"
          >
            <h6>
              <a 
                href="#" class="font-weight-400" style="font-size: 12px"
                @click.prevent="
                  filter.order_by = 'equipments.asc', 
                  init(filter, true)
                "
              >
                <i
                  class="text-primary fa-solid fa-angles-down mr-1"
                  style="font-size: 12px"
                />
                Equipamento: a-z
              </a>
            </h6>
            <hr class="mb-1 mt-n2" />
            <h6>
              <a 
                href="#" class="font-weight-400" style="font-size: 12px"
                @click.prevent="
                  filter.order_by = 'equipments.desc', 
                  init(filter, true)
                "
              >
                <i
                  class="text-primary fa-solid fa-angles-up mr-1"
                  style="font-size: 12px"
                />
                Equipamento: z-a
              </a>
            </h6>
            <hr class="mb-1 mt-n2" />
            <h6>
              <a 
                href="#" class="font-weight-400" style="font-size: 12px"
                @click.prevent="
                  filter.order_by = 'volume.desc', 
                  init(filter, true)
                "
              >
                <i
                  class="text-primary fa-solid fa-angles-down mr-1"
                  style="font-size: 12px"
                />
                Volume: do maior para o menor
              </a>
            </h6>
            <hr class="mb-1 mt-n2" />
            <h6>
              <a 
                href="#" class="font-weight-400" style="font-size: 12px"
                @click.prevent="
                  filter.order_by = 'volume.asc', 
                  init(filter, true)
                "
              >
                <i
                  class="text-primary fa-solid fa-angles-up mr-1"
                  style="font-size: 12px"
                />
                Volume: do menor para o maior
              </a>
            </h6>
            <hr class="mb-1 mt-n2" />
            <h6>
              <a 
                href="#" class="font-weight-400" style="font-size: 12px"
                @click.prevent="
                  filter.order_by = 'kilometers.desc', 
                  init(filter, true)
                "
              >
                <i
                  class="text-primary fa-solid fa-angles-down mr-1"
                  style="font-size: 12px"
                />
                Km: do maior para o menor
              </a>
            </h6>
            <hr class="mb-1 mt-n2" />
            <h6>
              <a 
                href="#" class="font-weight-400" style="font-size: 12px"
                @click.prevent="
                  filter.order_by = 'kilometers.asc', 
                  init(filter, true)
                "
              >
                <i
                  class="text-primary fa-solid fa-angles-up mr-1"
                  style="font-size: 12px"
                />
                Km: do menor para o maior
              </a>
            </h6>
            <hr class="mb-1 mt-n2" />
            <h6>
              <a 
                href="#" class="font-weight-400" style="font-size: 12px"
                @click.prevent="
                  filter.order_by = 'liters.desc', 
                  init(filter, true)
                "
              >
                <i
                  class="text-primary fa-solid fa-angles-down mr-1"
                  style="font-size: 12px"
                />
                Litros: do maior para o menor
              </a>
            </h6>
            <hr class="mb-1 mt-n2" />
            <h6>
              <a 
                href="#" class="font-weight-400" style="font-size: 12px"
                @click.prevent="
                  filter.order_by = 'liters.asc', 
                  init(filter, true)
                "
              >
                <i
                  class="text-primary fa-solid fa-angles-up mr-1"
                  style="font-size: 12px"
                />
                Litros: do menor para o maior
              </a>
            </h6>
            <hr class="mb-1 mt-n2" />
            <h6>
              <a 
                href="#" class="font-weight-400" style="font-size: 12px"
                @click.prevent="
                  filter.order_by = 'total.desc', 
                  init(filter, true)
                "
              >
                <i
                  class="text-primary fa-solid fa-angles-down mr-1"
                  style="font-size: 12px"
                />
                Total: do maior para o menor
              </a>
            </h6>
            <hr class="mb-1 mt-n2" />
            <h6>
              <a 
                href="#" class="font-weight-400" style="font-size: 12px"
                @click.prevent="
                  filter.order_by = 'total.asc', 
                  init(filter, true)
                "
              >
                <i
                  class="text-primary fa-solid fa-angles-up mr-1"
                  style="font-size: 12px"
                />
                Total: do menor para o maior
              </a>
            </h6>
            <a href="#" slot="reference">
              <h5 slot="reference" class="pt-1">
                ORDENAR <i class="fa-solid fa-chevron-down"></i>
              </h5>
            </a>
          </el-popover>
        </template>
      </MultiFilter>
    </div>

    <div v-if="!loadingWidgets" class="row row-cols-1 row-cols-lg-2 row-cols-xl-5">
      <div class="col card-margin-top card-margin-top-mobile">
        <div class="card height-card">
          <div class="card-body">
            <img src="/img/icons/bullish-yellow.png" width="26px" height="26px" class="mr-2" />
            <span class="card-title text-dark">Volume</span>
            <div class="text-dark card-text-border mb-2 mt-2 font-size-card">
              R$ / Volume
              <span class="mr-1 float-right font-size-card">
                {{ $_widgets.total_value_sum_divided_by_total_volume_sum | currency() }}
              </span>
            </div>
            <div class="text-dark card-text-border font-size-card">
              Volume
              <span class="mr-1 float-right font-size-card">
                {{ $_widgets.total_volume_sum }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col card-margin-top card-margin-top-mobile">
        <div class="card height-card">
          <div class="card-body">
            <img src="/img/icons/clock--v1.png" width="26px" height="26px" class="mr-2" />
            <span class="card-title text-dark">Horas</span>
            <div class="text-dark card-text-border mb-2 mt-2 font-size-card">
              R$ / Horas
              <span class="mr-1 float-right font-size-card">
                {{ $_widgets.total_value_sum_divided_by_total_hour_meter_sum | currency() }}
              </span>
            </div>
            <div class="text-dark card-text-border font-size-card">
              Horas
              <span class="mr-1 float-right font-size-card">
                {{ $_widgets.total_hour_meter_sum }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col card-margin-top card-margin-top-mobile">
        <div class="card height-card">
          <div class="card-body">
            <img src="/img/icons/interstate-truck.png" width="26px" height="26px" class="mr-2" />
            <span class="card-title text-dark">Quilômetro</span>
            <div class="text-dark card-text-border mb-2 mt-2 font-size-card">
              R$ / Km
              <span class="mr-1 float-right font-size-card">
                {{ $_widgets.total_value_sum_divided_by_total_km_panel_sum | currency() }}
              </span>
            </div>
            <div class="text-dark card-text-border font-size-card">
              Km
              <span class="mr-1 float-right font-size-card">
                {{ formatNumber($_widgets.total_km_panel_sum) }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col card-margin-top card-margin-top-mobile">
        <div class="card height-card">
          <div class="card-body">
            <img src="/img/icons//icons8/ios/gas-station.png" width="26px" height="26px" class="mr-2" />
            <span class="card-title text-dark">Litros</span>
            <div class="text-dark card-text-border mb-2 mt-2 font-size-card">
              R$ / Litros
              <span class="mr-1 float-right font-size-card">
                {{ $_widgets.total_value_sum_divided_by_total_total_liter_sum | currency() }}
              </span>
            </div>
            <div class="text-dark card-text-border font-size-card">
              Litros
              <span class="mr-1 float-right font-size-card">
                {{ $_widgets.total_total_liter_sum }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col card-margin-top card-margin-top-mobile">
        <div class="card height-card bg-primary">
          <div class="card-body">
            <img src="/img/icons//icons8/ios/estimate.png" width="26px" height="26px" class="mr-2" />
            <span class="card-title text-white">Total</span>
            <div class="text-dark card-text-border mb-2 mt-2 font-size-card text-white">
              R$
              <span class="mr-1 float-right font-size-card text-white">
                {{ $_widgets.total_value_sum | currency() }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="row mb-3" style="margin-top: -45px;">
      <div v-for="item in 5" class="col">
        <div class="mb-3 card" style="height: 135px">
          <div class="card-body">
            <div class="row">
              <div class="col-8" style="margin-top: -5px">
                <SkeletonPuzl type="small"/>
              </div>
              <div class="col-2 text-right float-right" style="margin-top: -10px">
                <SkeletonPuzl type="circle"/>
              </div>
              <div class="col-2" style="margin-top: -17px">
                <SkeletonPuzl type="small"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Tabelas -->
    <div class="row pt-0" style="margin-top: -22px;">
      <div class="col-md-12 p-1">
        <!-- Abas -->
        <div>
          <ul role="tablist" class="nav nav-tabs nav-justified">
            <li role="presentation" class="nav-item">
              <a
                @click.prevent="ListOwnFleet(), init({}, true)"
                role="tab"
                aria-selected="true"
                aria-setsize="4"
                aria-posinset="1"
                href="#"
                target="_self"
                class="nav-link"
                style="
                  padding: 8px 0; 
                  border: 1px solid #DCDFE6;
                "
                :style="
                  selectOwnFleet
                    ? 'background-color: #1a70b7; color: white !important;'
                    : 'background-color: white; color: black;'
                "
                :class="selectOwnFleet && 'active'"
              >
                <b class="font-weight-500"> 
                  <img 
                    :src="
                      selectOwnFleet
                        ? '/img/icons/icons8/ios/exterior.png'
                        : '/img/icons/icons8/ios/exterior-dark.png'
                    " 
                    width="22px" 
                    height="22px" 
                    class="mr-1" 
                  />
                  Frota própria 
                </b>
              </a>
            </li>
            <li role="presentation" class="nav-item">
              <a
                @click.prevent="ListThirdPartyFleet(), init({}, true)"
                role="tab"
                tabindex="-1"
                aria-selected="false"
                aria-setsize="4"
                aria-posinset="2"
                href="#"
                target="_self"
                class="nav-link"
                style="padding: 8px 0; border: 1px solid #DCDFE6"
                :style="
                  selectThirdPartyFleet
                    ? 'background-color: #1a70b7; color: white !important;'
                    : 'background-color: white; color: black;'
                "
                :class="selectThirdPartyFleet && 'active'"
              >
                <b class="font-weight-500"> 
                  <img 
                    :src="
                      selectThirdPartyFleet
                        ? '/img/icons/icons8/ios/key-exchange.png'
                        : '/img/icons/icons8/ios/key-exchange-dark.png'
                    " 
                    width="22px" 
                    height="22px" 
                    class="mr-1" 
                  />
                  Frota de terceiros 
                </b>
              </a>
            </li>
          </ul>
        </div>
        <div 
          class="card"
          style="
            border-top: 0px !important;
            border-top-left-radius: 0px !important;
            border-top-right-radius: 0px !important;
          "
        >
          <div class="card-body">
            <hr style="margin-top: -0.5rem">
            <!-- Agrupamentos -->
            <div class="mt-n3 ml-n1">
              <span class="px-1">
                <base-button
                  style="
                    border-radius: 5px !important; 
                    background-color: white !important; 
                    color: #1f3a68 !important;
                  "
                  title="Agrupamento por local"
                  size="sm"
                  class="w-outline font-weight-500 pt-2 mb-2"
                  @click="activeGroupingByLocation(), init({}, true)"
                >
                  <div class="row row-cols-12 ml-1 mr-1">
                    <img src="/img/icons/chemical-plant-black.png" width="23px" height="23px" class="mr-2" />
                      LOCAL
                    <i style="font-size: 12px" v-if="!filter.group_by_location" class="far fa-square mt-1"></i>
                    <i style="font-size: 12px" v-else class="far fa-check-square mt-1"></i>
                  </div>
                </base-button>
              </span>
            </div>
            <!-- Frota própria -->
            <div class="row pt-0" v-if="selectOwnFleet">
              <div class="col-md-12 p-1">
                <div class="card">
                  <div class="card-body mt-n2">
                    <div class="row">
                      <div class="col-md-12 table-responsive" style="overflow-y: scroll; max-height: 500px">
                        <table class="table table-sm table-bordered">
                          <thead>
                            <tr class="fixed">
                              <th class="text-center table-column-light-gray text-dark">Equipamento</th>
                              <th 
                                class="text-center table-column-light-gray text-dark"
                                v-if="filter.group_by_location"
                              >
                                Local
                              </th>
                              <th class="text-center table-column-light-gray text-dark">Volume</th>
                              <th class="text-center table-column-light-gray text-dark">R$/Volume</th>
                              <th class="text-center table-column-light-gray text-dark">Horas</th>
                              <th class="text-center table-column-light-gray text-dark">R$/HORAS</th>
                              <th class="text-center table-column-light-gray text-dark">KM</th>
                              <th class="text-center table-column-light-gray text-dark">R$/KM</th>
                              <th class="text-center table-column-light-gray text-dark">KM/Litro</th>
                              <th class="text-center table-column-light-gray text-dark">Litros</th>
                              <th class="text-center table-column-light-gray text-dark">R$/Litros</th>
                              <th class="text-center table-column-total-color text-white">Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="item in 10" v-show="loadingTable">
                            <th colspan="12">
                              <SkeletonPuzl type="button"/>
                            </th>
                          </tr>
                            <tr v-for="item in $_ownFleets">
                              <td class="text-left">
                                {{ item.equipment_code }}
                              </td>
                              <td class="text-left" v-if="filter.group_by_location">
                                <div v-if="item.own_fleet" style="font-weight: normal;">
                                  {{ item.fuel_tank_name }}
                                </div>
                                <div v-if="item.external" style="font-weight: normal;">
                                  Externo
                                </div>
                              </td>
                              <td class="text-center">
                                {{ item.total_volume_sum }}
                              </td>
                              <td class="text-center">
                                {{ item.total_value_sum_divided_by_total_volume_sum | currency() }}
                              </td>
                              <td class="text-center">
                                {{ item.total_hour_meter_sum }}
                              </td>
                              <td class="text-center">
                                {{ item.total_value_sum_divided_by_total_hour_meter_sum | currency()  }}
                              </td>
                              <td class="text-center">
                                {{ formatNumberToTotalKmPanelSum(item.total_km_panel_sum) }}
                              </td>
                              <td class="text-center">
                                {{ item.total_value_sum_divided_by_total_km_panel_sum | currency()  }}
                              </td>
                               <td class="text-center">
                                {{ formatNumberWithTwoDecimals(item.km_panel_divided_by_total_liter) }}
                              </td>
                              <td class="text-center">
                                {{ formatNumber(item.total_total_liter_sum) }}
                              </td>
                              <td class="text-center">
                                {{ item.total_value_sum_divided_by_total_total_liter_sum | currency()  }}
                              </td>
                              <td class="text-center" style="background-color: #1A70B7">
                                <span class="text-white"> {{ item.total_value_sum | currency()  }} </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <PuzlEmptyData v-show="$_ownFleets && !$_ownFleets.length && !loadingSkeleton"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Frota de terceiros -->
            <div class="row pt-0" v-if="selectThirdPartyFleet">
              <div class="col-md-12 p-1">
                <div class="card">
                  <div class="card-body mt-n2">
                    <div class="row">
                      <div class="col-md-12 table-responsive" style="overflow-y: scroll; max-height: 500px">
                        <table class="table table-sm table-bordered">
                          <thead>
                            <tr class="fixed">
                              <th class="text-center"></th>
                              <th class="text-center"></th>
                              <th class="text-center"></th>
                              <th class="text-center"></th>
                              <th class="text-center"></th>
                              <th class="text-center"></th>
                              <th class="text-center"></th>
                              <th class="text-center"></th>
                              <th class="text-center"></th>
                              <th class="text-center"></th>
                              <th class="text-center"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <!-- <tr>
                              <th colspan="12">
                                <SkeletonPuzl type="button" />
                              </th>
                            </tr> -->
                            <tr>
                              <td class="text-left">

                              </td>
                              <td class="text-center">

                              </td>
                              <td class="text-center">

                              </td>
                              <td class="text-center">

                              </td>
                              <td class="text-center">
      
                              </td>
                              <td class="text-center">

                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <!-- puzl-empty-data -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LoadingPagination :show="loading && !loadingSkeleton" />
  </div>
</template>

<script>
import MultiFilter from "@/components/Utils/MultiFilterV3";
import InputDatePicker from "@/components/InputDatePicker";
import { mapGetters } from "vuex";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import PuzlSelect from "@/components/PuzlSelect";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import globalFilter from "@/mixins/globalFilter";

export default {
  name: "ListFuelResume",
  mixins: [cursorPaginate, globalFilter],
  components: {
    PuzlEmptyData,
    SkeletonPuzl,
    InputDatePicker,
    MultiFilter,
    PuzlSelect,
    LoadingPagination,
  },
  data() {
    return {
      loadingSkeleton: false,
      range: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        end: new Date(),
      },
      filter: {
        range: {
          start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
          end: new Date(),
        },
        group_by_location: 0,
        external: null, // local: interno ou externo
        order_by: null,
        export_type: null
      },
      selectOwnFleet: true,
      selectThirdPartyFleet: false,
      loadingTable: false,
      initTabLoad: true,
      groupByEquipment: true,
      groupByLocation: false,
      isAdditionalFiltersOpen: false,
      loadingWidgets: false,
    };
  },
  computed: {
    ...mapGetters({
      $_widgets: "fuelResume/getWidgets",
      $_ownFleets: "fuelResume/getOwnFleetItems",
    }),
  },
  mounted() {
    this.init(this.filter, true);
  },
  methods: {
    /**
     * @param {object} filter
     */
    handleFetch(filter) {
      this.init(filter, true);
    },
    /**
     * @param {object|null} filter,
     * @param {bool|null} initTabLoad // apenas ao iniciar a tela ou trocar de aba.
     */
    init(filter = null, initTabLoad = null) {
      if (initTabLoad) {
        this.initTabLoad = false;
        this.loadingTable = true;
        this.getWidgets(filter);
      }
      if (this.selectOwnFleet) {
        this.getOwnFleet(filter);
      } else {
        
      }
    },
    getWidgets(filter) {
      this.loadingWidgets = true;
      this.filter.range = this.range;
      if (filter && filter.global) {
        this.filter.global = filter.global;
      }
      this.$store.dispatch("fuelResume/getWidgets", { filter: this.filter }).then(() => {
        this.loadingWidgets = false; 
      });
    },
    /**
     * @param {object} filter
     */
    getOwnFleet(filter) {
      this.startCursor(filter);
      this.$store.dispatch("fuelResume/fetchOwnFleetItemsPaginate", {
          filter: this.filter,
          next_page: this.paginate.nextUrl
        })
        .then((response) => {
          this.resolveCursor(response);
          this.loadingTable = false;
        })
        .catch((error) => {
          this.resolveCursor();
        });
    },
    ListOwnFleet() {
      this.selectOwnFleet = !this.selectOwnFleet;
      this.selectThirdPartyFleet = false;
    },
    ListThirdPartyFleet() {
      this.selectThirdPartyFleet = !this.selectThirdPartyFleet;
      this.selectOwnFleet = false;
    },
    activeGroupingByLocation() {
      this.filter.group_by_location = +!this.filter.group_by_location;
    },
    /**
     * @param {object} filter
     */
    handleFilterDate(filter) {
      this.range = filter;
      this.init({}, true);
    },
    clearFilter() {
      this.filter = {
        range: null,
        group_by_location: 0,
        external: null,
        order_by: null,
        export_type: null,
      };
    },
    /**
     * Indica que a modal de filtros foi aberta.
     * @param {string} value
     */
    additionalFiltersToggled(value) {
      this.isAdditionalFiltersOpen = value;
    },
    /**
     * Formata um número para exibir separadores de milhares.
     * @param {string} value
     * @returns {string}
     */
    formatNumber(value) {
      return parseInt(value).toLocaleString();
    },
    /**
     * Formata um número para exibir separadores de milhares e uma casa decimal, após dividir por 1000.
     * @param {string} value
     * @returns {string}
     */
     formatNumberToTotalKmPanelSum(value) {
      let adjustedValue = value / 1000;
      return adjustedValue.toLocaleString('pt-BR', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
    },
    /**
     * Formata um número para exibir separadores de milhares e 2 casas decimais.
     * @param {string} value
     * @returns {string}
     */
    formatNumberWithTwoDecimals(value) {
      return parseFloat(value).toFixed(2).toLocaleString();
    },
    /**
     * @param {string} export_type
     */
    downloadFuelResumePdf(export_type) {
      const loader = this.$loading.show();
      this.filter.export_type = export_type;
      this.$store
        .dispatch("fuelResume/getReportPdf", this.filter).then((response) => {
          this.$notify({
            type: "success",
            message: "Solicitação realizada com sucesso! O relatório será exportado em segundo plano.",
          });
        }).catch(error => {
          const errors = error && error.response && error.response.status === 422
            ? formatErrorValidation(error.response.data.data)
            : error.response.data.message;
          this.$notify({ type: "danger", message: errors });
        }).finally(() => {
          loader.hide();
        });
    },
    /**
     * @param {string} export_type
     */
    downloadFuelResumeExcel(export_type) {
      const loader = this.$loading.show();
      this.filter.export_type = export_type;
      this.$store
        .dispatch("fuelResume/getReportExcel", this.filter).then(() => {
          this.$notify({
            type: "success",
            message: "Solicitação realizada com sucesso! O relatório será exportado em segundo plano.",
          });
        }).catch(error => {
          const errors = error && error.response && error.response.status === 422
            ? formatErrorValidation(error.response.data.data)
            : error.response.data.message;
          this.$notify({ type: "danger", message: errors });
        }).finally(() => {
          loader.hide();
        });
    },
  },
};
</script>

<style scoped>
.base-button:hover .invert-on-hover {
  filter: brightness(0) invert(1);
}

.table-column-total-color {
  background-color: #1A70B7
}

.card-text-border {
  border: 2px solid #D3D3D3; 
  border-radius: 10px; 
  padding: 5px;
}

.card-margin-top {
  margin-top: -14px;
}
@media (max-width: 1190px) {
  .card-margin-top-mobile {
    margin-top: -19px;
  }
}
.card {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.height-card {
    height: 160px;
}
.font-size-card {
  font-size: 10px;
}
</style>