import CmcAdjustmentListPage from "../views/list/CmcAdjustmentListPage.vue";

export default [
  {
    path: "/technology/adjustments",
    name: "Mapa de ponto de carga",
    meta: {
      permission: "technology.puzl-adjustment.index.view",
      description: "Mapa de ponto de carga",
    },
    component: CmcAdjustmentListPage,
  },
];
