<template>
  <div>
    <base-header class="bg-gray-content">
      <puzl-breadcrumb/>
      <div class="row">
        <div class="col-md-3 p-1">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-9">
                  <h5
                    class="card-title text-uppercase text-muted mb-0 ">Viagens em andamento</h5>
                  <span class="numeric font-weight-600">
                  {{
                      $_widgets.total_volume_in_progress_travels ? $_widgets.total_volume_in_progress_travels.replace('.', ',') : 0
                    }}
                ({{ $_widgets.total_in_progress_travels || 0 }})
                </span>
                </div>
                <div class="col-3 text-left">
                  <i style="font-size: 2.2rem;margin-bottom: 5px;color: #ffd600 !important"
                     class="fa-2x fa-solid fa-calendar-minus"></i><span
                  class="dashboard-text card-stats-title h3 font-weight-bold mb-0 text-center"
                >
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets-sm col-md-3 p-1">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-9">
                  <h5
                    class="card-title text-uppercase text-muted mb-0 ">Viagens realizadas</h5>
                  <span class="numeric font-weight-600">
                  {{
                      $_widgets.total_volume_finished_travels ? $_widgets.total_volume_finished_travels.replace('.', ',') : 0
                    }}
                ({{ $_widgets.total_finished_travels || 0 }})
                </span>
                </div>
                <div class="col-3 text-left">
                  <i style="font-size: 2.2rem;margin-bottom: 5px;color: rgb(38, 133, 184)"
                     class="fa-2x fa-solid fa-calendar-check"></i><span
                  class="dashboard-text card-stats-title h3 font-weight-bold mb-0 text-center"
                >
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets-sm col-md-3 p-1">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-9">
                  <h5
                    class="card-title text-uppercase text-muted mb-0 ">Viagens programadas</h5>
                  <span class="numeric font-weight-600">
                  {{
                      $_widgets.total_programmed_volume ? $_widgets.total_programmed_volume.replace('.', ',') : 0
                    }}
                   ({{ $_widgets.total_programmed_travels || 0 }})
                </span>
                </div>
                <div class="col-3 text-left">
                  <i style="font-size: 2.2rem;margin-bottom: 5px;rgb(127, 146, 190)"
                     class="fa-2x fa-solid fa-calendar"></i><span
                  class="dashboard-text card-stats-title h3 font-weight-bold mb-0 text-center"
                >
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets-sm col-md-3 p-1">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-9">
                  <h5
                    class="card-title text-uppercase text-muted mb-0 ">Viagens canceladas</h5>
                  <span class="numeric font-weight-600">
                  {{
                      $_widgets.total_volume_canceled_travels ? $_widgets.total_volume_canceled_travels.replace('.', ',') : 0
                    }}
                   ({{ $_widgets.total_canceled_travels || 0 }})
                </span>
                </div>
                <div class="col-3 text-left">
                  <i style="font-size: 2.2rem;margin-bottom: 5px"
                     class=" fa-solid fa-calendar-xmark text-danger"></i><span
                  class="dashboard-text card-stats-title h3 font-weight-bold mb-0 text-center"
                >
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row pt-n1">
        <div class="col-md-12 p-1">
          <multi-filter :filter="{}" @fetch="init" :without_filter="true">
            <div class="col-md-3 p-2">
              <label>&nbsp;Data de filtragem </label>
              <input-date-picker
                ref="input-date-picker"
                @handleFilterDate="handleFilterDate"
                :defaultRange="range"
              />
            </div>
            <div class="col-md-4 p-2">
              <label>Central</label>
              <base-input input-classes="form-control">
                <puzl-select
                  @input="init(filter)"
                  class="select-xl"
                  v-model="filter.company_plant_id"
                  :items="$_plants"
                  :loading="loadingPlant"
                  :disabled="loadingPlant"/>
              </base-input>
            </div>
          </multi-filter>
        </div>
      </div>
    </base-header>
    <div class="container-fluid">
      <div class="row card-wrapper p-1 pt-n1">
        <card class="col-md-12 show-md">
          <div class="row">
            <div class="col-md-4 p-1 text-center">
              <a @click.prevent="setView(0)" :style="view.includes(0) && 'background: #525f7f'" href="#"
                 class="ml-1 avatar avatar-sm">
                <i class="fa-solid fa-calendar text-white"></i>
              </a>
              <!--              <el-popover-->
              <!--                title="Filtragem de status"-->
              <!--                placement="bottom"-->
              <!--                class="pl-1"-->
              <!--              >-->
              <!--                <template #default>-->
              <!--                    <input class="inline pl-2" type="checkbox" value=""/> Pendente-->
              <!--                    <br>-->
              <!--                    <input class="inline pl-2" type="checkbox" value=""/> Liberado-->
              <!--                    <br>-->
              <!--                    <input class="inline pl-2" type="checkbox" value=""/> Não realizado-->
              <!--                    <br>-->
              <!--                    <input class="inline pl-2" type="checkbox" value=""/> Cancelado-->
              <!--                </template>-->
              <!--                <base-button-->
              <!--                  style="background: rgb(131 127 127);border: none"-->
              <!--                  slot="reference"-->
              <!--                  size="sm"-->
              <!--                  class="p-0 m-0 avatar avatar-sm rounded-circle"-->
              <!--                >-->
              <!--                  <i class="text-white fa-solid fa-filter"></i>-->
              <!--                </base-button>-->
              <!--              </el-popover>-->
            </div>
            <div class="col-md-4 p-1 text-center">
              <a @click.prevent="setView(1)" :style="view.includes(1) && 'background: rgb(248 215 38) !important'"
                 href="#"
                 class="ml-1 avatar avatar-sm">
                <i class="fa-solid fa-solid fa-calendar-minus text-white"></i>
              </a>
              <!--              <el-popover-->
              <!--                title="Filtragem de status"-->
              <!--                placement="bottom"-->
              <!--                class="pl-1"-->
              <!--              >-->
              <!--                <template #default>-->
              <!--                  <input class="inline pl-2" type="checkbox" value=""/> Pendente-->
              <!--                  <br>-->
              <!--                  <input class="inline pl-2" type="checkbox" value=""/> Liberado-->
              <!--                  <br>-->
              <!--                  <input class="inline pl-2" type="checkbox" value=""/> Não realizado-->
              <!--                  <br>-->
              <!--                  <input class="inline pl-2" type="checkbox" value=""/> Cancelado-->
              <!--                </template>-->
              <!--                <base-button-->
              <!--                  style="background: rgb(131 127 127);border: none"-->
              <!--                  slot="reference"-->
              <!--                  size="sm"-->
              <!--                  class="p-0 m-0 avatar avatar-sm rounded-circle"-->
              <!--                >-->
              <!--                  <i class="text-white fa-solid fa-filter"></i>-->
              <!--                </base-button>-->
              <!--              </el-popover>-->
            </div>
            <div class="col-md-4 p-1 text-center">
              <a @click.prevent="setView(2)" :style="view.includes(2) && 'background: rgb(38 133 184)'" href="#"
                 class="ml-1 avatar avatar-sm">
                <i class="fa-solid fa-calendar-check text-white"></i>
              </a>
              <!--              <el-popover-->
              <!--                title="Filtragem de status"-->
              <!--                placement="bottom"-->
              <!--                class="pl-1"-->
              <!--              >-->
              <!--                <template #default>-->
              <!--                  <input class="inline pl-2" type="checkbox" value=""/> Pendente-->
              <!--                  <br>-->
              <!--                  <input class="inline pl-2" type="checkbox" value=""/> Liberado-->
              <!--                  <br>-->
              <!--                  <input class="inline pl-2" type="checkbox" value=""/> Não realizado-->
              <!--                  <br>-->
              <!--                  <input class="inline pl-2" type="checkbox" value=""/> Cancelado-->
              <!--                </template>-->
              <!--                <base-button-->
              <!--                  style="background: rgb(131 127 127);border: none"-->
              <!--                  slot="reference"-->
              <!--                  size="sm"-->
              <!--                  class="p-0 m-0 avatar avatar-sm rounded-circle"-->
              <!--                >-->
              <!--                  <i class="text-white fa-solid fa-filter"></i>-->
              <!--                </base-button>-->
              <!--              </el-popover>-->
            </div>
          </div>
        </card>
        <card class="col-md-12 show-mobile">
          <div class="row">
            <div class="col-4 p-1 text-left">
              <i class="fa-2x fa-solid fa-arrow-left hover-icon"></i>
            </div>
            <div class="col-4 p-1 text-center" style="margin-top: -10px">
              <a href="#"
                 class="ml-1 avatar avatar rounded-circle">
                <i style="font-size: 1.25rem" class="fa-solid fa-calendar text-white"></i>
              </a>
            </div>
            <div class="col-4 p-1 text-right">
              <i class="fa-2x fa-solid fa-arrow-right hover-icon"></i>
            </div>
          </div>
        </card>
      </div>
      <div style="margin-top: -30px !important;" class="row card-wrapper pt-n1">
        <!--        CARD 1 - PROGRAMADO-->
        <transition name="slide-fade">
          <div v-if="view.includes(0) && showCard" :class="classCard" class="p-1">
            <!--            <div class="row">-->
            <!--              <div class="col-md-12 float-right text-left" style="margin-bottom: -30px;z-index: 999999;">-->
            <!--                <el-popover v-if="!loadingProgrammed && $_finished.length"-->
            <!--                            title="Filtragem de status"-->
            <!--                            placement="bottom"-->
            <!--                            class="pl-1"-->
            <!--                >-->
            <!--                  <template #default>-->
            <!--                    <input class="inline pl-2" type="checkbox" value=""/> Pendente-->
            <!--                    <br>-->
            <!--                    <input class="inline pl-2" type="checkbox" value=""/> Liberado-->
            <!--                    <br>-->
            <!--                    <input class="inline pl-2" type="checkbox" value=""/> Não realizado-->
            <!--                    <br>-->
            <!--                    <input class="inline pl-2" type="checkbox" value=""/> Cancelado-->
            <!--                  </template>-->
            <!--                  <i slot="reference" style="margin-left: 10px"-->
            <!--                     class="text-muted fa-3x fa-solid fa-filter"></i>-->
            <!--                </el-popover>-->
            <!--              </div>-->
            <!--            </div>-->
            <card id="programmed" class="mb-2 table-responsive" style="height: 820px">
              <div class="row mt-n3" v-if="!loadingProgrammed && $_programmed.length">
                <div v-for="item in $_programmed" :class="classCardBody" class="kanban p-1">
                  <card style="border: 1px solid #b3b3b3">
                    <div class="card-header p-0">
                      <div class="row">
                        <div class="col-8">
                          <h5 v-if="[16, 15, 13, 0].includes(item.schedule.status)" class="h4"
                              :class="getScheduleStatus[item.schedule.status]"> {{ item.schedule.label }}
                          </h5>
                          <h5 v-else class="h4 text-green"> Liberado
                          </h5>
                        </div>
                        <div class="col-4 text-right">
                          <router-link
                            :to="{
                        path: '/operational/schedule/charging',
                        name: 'operational.schedule.charging.index',
                        params: { travel_uuid: item.uuid }
                        }">
                            <i class="fa-2x fas fa-balance-scale text-indigo"/>
                          </router-link>
                        </div>
                        <div class="col-md-12">
                          <small class="mb-1">
                            {{ item.date_at | parseDate('DD/MM/YYYY') }}
                          </small>
                        </div>
                        <div class="col-md-12">
                        <span class="h4 mb-1">
                          {{ item.contract_proposal_code }}
                        </span>
                          <div class="row pl-3" v-if="item.send_plant_uuid === item.return_plant_uuid">
                            <i class="fas fa-long-arrow-alt-right text-success">
                              <br/>
                              <i class="fas fa-long-arrow-alt-left text-danger"></i
                              ></i>
                            <div class="my-auto">
                  <span class="h4 small d-flex pl-2">
                    {{ item.company_plant_name }}
                    <span class="pl-1" v-if="item.charge_point_name"> ({{ item.charge_point_name }})</span>
                  </span>
                            </div>
                          </div>
                          <div v-if="item.send_plant_uuid !== item.return_plant_uuid">
                            <div class="row pl-3">
                              <i style="margin-top: 0.15rem !important;"
                                 class="fas fa-long-arrow-alt-right text-success"> </i>
                              <div class="">
                    <span class="h4 small d-flex pl-2">
                      {{ item.company_plant_name }}
                      <span v-if="item.charge_point_name"> ({{ item.charge_point_name }})</span>
                    </span>
                              </div>
                            </div>
                            <div class="row pl-3">
                              <i style="margin-top: 0.15rem !important;"
                                 class="fas fa-long-arrow-alt-left text-danger"> </i>
                              <div class="">
                    <span class="h4 small d-flex pl-2">
                      {{ item.company_plant_name }}
                    </span>
                              </div>
                            </div>
                          </div>
                          <h4 class="">
                            <small>{{ item.construction_name }}</small>
                          </h4>
                          <h4 class="mt-n2">
                            <small>{{ item.customer_name }}</small>
                          </h4>
                        </div>
                        <div class="col-12 mt-2 mb-2">
                        <span class="h5 mb-1">
                          {{ item.code }} <span class="h5 font-weight-300" v-if="item.code">({{ item.plate }})</span>
                          <span style="font-size: 12px"
                                class="float-right font-weight-500">{{ item.volume.replace('.', ',') }} <span
                            style="text-transform: none">m</span> <sup>3</sup> ({{ item.index }})</span>
                        </span>
                          <h5 class="h3 font-weight-300 small">
                            {{ item.name }}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div class="card-body p-0" style="height: 20px">
                      <div class="row">
                        <div class="col-md-12 mt-2">
                          <div class="float-left">
                            <span style="text-transform: none">{{ item.predicted_cycle }}</span>
                          </div>
                          <div class="float-right">{{ item.load_truck_time }} <i
                            class="fa-solid fa-clock text-success"></i></div>
                        </div>
                      </div>
                    </div>
                  </card>
                </div>
              </div>
              <div class="row card-wrapper mt-n3" v-if="loadingProgrammed">
                <skeleton-puzl-grid style="margin-bottom: -1.4rem !important;" class="p-1"
                                    v-for="item in general_per_page" :classCard="classCardBody"/>
              </div>
              <puzl-empty-data v-if="!loadingProgrammed && !$_programmed.length" :with-card="false"/>
            </card>
          </div>
        </transition>
        <!--        CARD 2 EM ANDAMENTO-->
        <transition name="slide-fade">
          <div v-if="view.includes(1) && showCard" :class="classCard" class="p-1">
            <!--            <div class="row mb-1">-->
            <!--              <div class="col-md-12 float-right text-left" style="margin-bottom: -30px;z-index: 999999;">-->
            <!--                <el-popover v-if="!loadingInProgress && $_inProgress.length"-->
            <!--                            title="Filtragem de status"-->
            <!--                            placement="bottom"-->
            <!--                            class="pl-1"-->
            <!--                >-->
            <!--                  <template #default>-->
            <!--                    <input class="inline pl-2" type="checkbox" value=""/> Pendente-->
            <!--                    <br>-->
            <!--                    <input class="inline pl-2" type="checkbox" value=""/> Liberado-->
            <!--                    <br>-->
            <!--                    <input class="inline pl-2" type="checkbox" value=""/> Não realizado-->
            <!--                    <br>-->
            <!--                    <input class="inline pl-2" type="checkbox" value=""/> Cancelado-->
            <!--                  </template>-->
            <!--                  <i slot="reference" style="margin-left: 10px"-->
            <!--                     class="text-muted fa-3x fa-solid fa-filter"></i>-->
            <!--                </el-popover>-->
            <!--              </div>-->
            <!--            </div>-->
            <card id="in_progress" class="mb-2 table-responsive" style="height: 820px">
              <div class="row mt-n3" v-if="!loadingInProgress && $_inProgress.length">
                <div v-for="item in $_inProgress" :class="classCardBody" class="kanban p-1">
                  <card style="border: 1px solid #b3b3b3">
                    <div class="card-header p-0">
                      <div class="row">
                        <div class="col-8">
                          <h5 class="h4" :class="item.sub_status.class"> {{ item.sub_status.text }}
                          </h5>
                        </div>
                        <div class="col-4 text-right">
                          <router-link
                            :to="{
                        path: '/operational/schedule/charging',
                        name: 'operational.schedule.charging.index',
                        params: { travel_uuid: item.uuid }
                        }">
                            <i class="fa-2x fas fa-balance-scale text-indigo"/>
                          </router-link>
                        </div>
                        <div class="col-md-12">
                          <small class="mb-1">
                            {{ item.start_at | parseDate('DD/MM/YYYY') }}
                          </small>
                        </div>
                        <div class="col-md-12">
                        <span class="h4 mb-1">
                          {{ item.contract_proposal_code }}
                        </span>
                          <div class="row pl-3" v-if="item.send_plant_uuid === item.return_plant_uuid">
                            <i class="fas fa-long-arrow-alt-right text-success">
                              <br/>
                              <i class="fas fa-long-arrow-alt-left text-danger"></i
                              ></i>
                            <div class="my-auto">
                  <span class="h4 small d-flex pl-2">
                    {{ item.company_plant_name }}
                    <span class="pl-1" v-if="item.charge_point_name"> ({{ item.charge_point_name }})</span>
                  </span>
                            </div>
                          </div>
                          <div v-if="item.send_plant_uuid !== item.return_plant_uuid">
                            <div class="row pl-3">
                              <i style="margin-top: 0.15rem !important;"
                                 class="fas fa-long-arrow-alt-right text-success"> </i>
                              <div class="">
                    <span class="h4 small d-flex pl-2">
                      {{ item.company_plant_name }}
                      <span v-if="item.charge_point_name"> ({{ item.charge_point_name }})</span>
                    </span>
                              </div>
                            </div>
                            <div class="row pl-3">
                              <i style="margin-top: 0.15rem !important;"
                                 class="fas fa-long-arrow-alt-left text-danger"> </i>
                              <div class="">
                    <span class="h4 small d-flex pl-2">
                      {{ item.company_plant_name }}
                    </span>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div v-show="item.number" class="col-6">
                              <div class="text-center"><a href="#"><img
                                width="40px" src="/img/icons/os.png"><span
                                class="text-default"
                                style="text-transform: none;"><br> nº </span><b
                                class="text-info money" style="font-size: 1.2rem;">{{ item.number }}</b></a></div>
                            </div>
                            <div class="col-6" v-if="item.xml_number && item.schedule_travel_status === 1"
                                 style="margin-top: 7px;"><span class="text-center"
                            ><img width="25px"
                                  src="/img/nfe.png"
                                  style="cursor: pointer;"><span
                              class="text-default"
                              style="text-transform: none;"> <br>nº </span><b
                              class="money text-info" style="font-size: 1.2rem;">{{ item.xml_number }}</b></span>
                            </div>
                            <div class="col-6" v-if="item.xml_number && item.schedule_travel_status === 6"
                                 style="margin-top: 7px;"><span class="text-center"
                            ><img width="25px"
                                  src="/img/nfe%20cancelada%20icon.svg"
                                  style="cursor: pointer;"><span
                              class="text-default"
                              style="text-transform: none;"> <br>nº </span><b
                              class="money text-danger" style="font-size: 1.2rem;">{{ item.xml_number }}</b></span>
                            </div>
                          </div>
                          <h4 class="">
                            <small>{{ item.construction_name }}</small>
                          </h4>
                          <h4 class="mt-n2">
                            <small>{{ item.customer_name }}</small>
                          </h4>
                        </div>
                        <div class="col-12 mt-2 mb-2">
                        <span class="h5 mb-1" v-show="item.code">
                          {{ item.code }} <span class="h5 font-weight-300">({{ item.plate }})</span>
                          <span style="font-size: 12px"
                                class="float-right font-weight-500">{{ item.volume.replace('.', ',') }} <span
                            style="text-transform: none">m</span> <sup>3</sup> ({{ item.index }})</span>
                        </span>
                          <h5 class="h3 font-weight-300 small">
                            {{ item.name }}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div class="card-body p-0" style="height: 50px">
                      <div class="row">
                        <div class="col-md-12 mt-2">
                          <div class="float-left">
                            <span style="text-transform: none">{{ item.perfomed_time }} / {{
                                item.predicted_travel_cycle
                              }}</span>
                          </div>
                          <div class="float-right">{{ item.load_truck_time }}
                            <a href="#" @click.prevent="changeCycle(item.uuid, item.address_construction_uuid)">
                              <i class="fa-solid fa-clock text-success"></i>
                            </a></div>
                          <br>
                          <div class="progress" style="height: 1.5rem;color: gray">
                            <div
                              role="progressbar"
                              class="progress-bar progress-bar-animated"
                              :class="item.perfomed_minutes < item.predicted_minutes ? 'bg-primary' :'bg-danger'"
                              :style="'width:' + (item.percentage < 10 ? 10 : item.percentage) + '%'"
                            >
                              {{ item.percentage }}%
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </card>
                </div>
              </div>
              <div class="row card-wrapper mt-n3" v-if="loadingInProgress">
                <skeleton-puzl-grid style="margin-bottom: -1.4rem !important;" class="p-1"
                                    v-for="item in general_per_page" :classCard="classCardBody"/>
              </div>
              <puzl-empty-data v-if="!loadingInProgress && !$_inProgress.length" :with-card="false"/>
            </card>
          </div>
        </transition>
        <transition name="slide-fade">
          <div v-if="view.includes(2) && showCard" :class="classCard" class="p-1">
            <!--            <div class="row mb-2">-->
            <!--              <div class="col-md-12 float-right text-left" style="margin-bottom: -30px;z-index: 999999;">-->
            <!--                <el-popover v-if="!loadingFinished && $_finished.length"-->
            <!--                            title="Filtragem de status"-->
            <!--                            placement="bottom"-->
            <!--                            class="pl-1"-->
            <!--                >-->
            <!--                  <template #default>-->
            <!--                    <input class="inline pl-2" type="checkbox" value=""/> Pendente-->
            <!--                    <br>-->
            <!--                    <input class="inline pl-2" type="checkbox" value=""/> Liberado-->
            <!--                    <br>-->
            <!--                    <input class="inline pl-2" type="checkbox" value=""/> Não realizado-->
            <!--                    <br>-->
            <!--                    <input class="inline pl-2" type="checkbox" value=""/> Cancelado-->
            <!--                  </template>-->
            <!--                  <i slot="reference" style="margin-left: 10px"-->
            <!--                     class="text-muted fa-3x fa-solid fa-filter"></i>-->
            <!--                </el-popover>-->
            <!--              </div>-->
            <!--            </div>-->
            <card id="finished" class="mb-2 table-responsive" style="height: 820px">
              <div class="row mt-n3" v-if="!loadingFinished && $_finished.length">
                <div v-for="item in $_finished" :class="classCardBody" class="kanban p-1">
                  <card style="border: 1px solid #b3b3b3">
                    <div class="card-header p-0">
                      <div class="row">
                        <div class="col-8">
                          <h5 class="h4" :class="item.schedule_travel_status === 1 ? 'text-primary' : 'text-danger'">
                            {{ item.schedule_travel_status === 1 ? 'Concluído' : 'Cancelado' }}
                          </h5>
                        </div>
                        <div class="col-4 text-right">
                          <base-dropdown menuOnRight>
                            <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                              <i class="fas fa-cog"></i>
                            </base-button>
                            <router-link
                              class="dropdown-item"
                              :to="{
                      path: '/operational/schedule/charging',
                      name: 'operational.schedule.charging.index',
                      params: { travel_uuid: item.uuid }
                    }"
                            >
                              <i class="fa fa-balance-scale text-primary"></i>
                              <a href="#" class="text-default">Carregamento</a>
                            </router-link>
                            <a
                              class="dropdown-item"
                              href="javascript:void(0)"
                              v-if="[1, 6].includes(item.travel_status)"
                              @click.prevent="handleConcreteLeftover(travel)"
                            >
                              <i class="fa fa-recycle text-success"></i>
                              <span class="text-right">Sobra de Concreto</span>
                            </a>
                            <a class="dropdown-item" href="javascript:void(0)" v-if="item.travel_status === 1"
                               @click.prevent="handleCancelTravel(travel)">
                              <i class="far fa-times-circle text-danger"></i>
                              <span class="text-right">Cancelar</span>
                            </a>
                          </base-dropdown>
                        </div>
                        <div class="col-md-12">
                          <small class="mb-1">
                            {{ item.date_at | parseDate('DD/MM/YYYY') }}
                          </small>
                        </div>
                        <div class="col-md-12">
                        <span class="h4 mb-1">
                          {{ item.contract_proposal_code }}
                        </span>
                          <div class="row pl-3" v-if="item.send_plant_uuid === item.return_plant_uuid">
                            <i class="fas fa-long-arrow-alt-right text-success">
                              <br/>
                              <i class="fas fa-long-arrow-alt-left text-danger"></i
                              ></i>
                            <div class="my-auto">
                           <span class="h4 small d-flex pl-2">
                    {{ item.company_plant_name }}
                    <span class="pl-1" v-if="item.charge_point_name"> ({{ item.charge_point_name }})</span>
                  </span>
                            </div>
                          </div>
                          <div v-if="item.send_plant_uuid !== item.return_plant_uuid">
                            <div class="row pl-3">
                              <i style="margin-top: 0.15rem !important;"
                                 class="fas fa-long-arrow-alt-right text-success"> </i>
                              <div class="">
                    <span class="h4 small d-flex pl-2">
                      {{ item.company_plant_name }}
                      <span v-if="item.charge_point_name"> ({{ item.charge_point_name }})</span>
                    </span>
                              </div>
                            </div>
                            <div class="row">
                              <i style="margin-top: 0.15rem !important;"
                                 class="fas fa-long-arrow-alt-left text-danger"> </i>
                              <div class="">
                    <span class="h4 small d-flex pl-2">
                      {{ item.company_plant_name }}
                    </span>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div v-show="item.number" class="col-6">
                              <div class="text-center"><a href="#"><img
                                width="40px" src="/img/icons/os.png"><span
                                class="text-default"
                                style="text-transform: none;"><br> nº </span><b
                                class="text-info money" style="font-size: 1.2rem;">{{ item.number }}</b></a></div>
                            </div>
                            <div class="col-6" v-if="item.xml_number && item.schedule_travel_status === 1"
                                 style="margin-top: 7px;"><span class="text-center"
                            ><img width="25px"
                                  src="/img/nfe.png"
                                  style="cursor: pointer;"><span
                              class="text-default"
                              style="text-transform: none;"> <br>nº </span><b
                              class="money text-info" style="font-size: 1.2rem;">{{ item.xml_number }}</b></span>
                            </div>
                            <div class="col-6" v-if="item.xml_number && item.schedule_travel_status === 6"
                                 style="margin-top: 7px;"><span class="text-center"
                            ><img width="25px"
                                  src="/img/nfe%20cancelada%20icon.svg"
                                  style="cursor: pointer;"><span
                              class="text-default"
                              style="text-transform: none;"> <br>nº </span><b
                              class="money text-danger" style="font-size: 1.2rem;">{{ item.xml_number }}</b></span>
                            </div>
                          </div>
                          <h4 class="">
                            <small>{{ item.construction_name }}</small>
                          </h4>
                          <h4 class="mt-n2">
                            <small>{{ item.customer_name }}</small>
                          </h4>
                        </div>
                        <div class="col-12 mt-2 mb-2">
                        <span class="h5 mb-1" v-show="item.code">
                          {{ item.code }} <span class="h5 font-weight-300">({{ item.plate }})</span>
                          <span style="font-size: 12px"
                                class="float-right font-weight-500">{{ item.volume.replace('.', ',') }} <span
                            style="text-transform: none">m</span> <sup>3</sup> ({{ item.index }})</span>
                        </span>
                          <h4 class="mt-n1">
                            <small>{{ item.name }}</small>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div class="card-body p-0" style="height: 50px">
                      <div class="row">
                        <div class="col-md-12 mt-2">
                          <div class="float-left">
                            <span style="text-transform: none">{{ item.perfomed_time }} / {{
                                item.predicted_travel_cycle
                              }}</span>
                          </div>
                          <div class="float-right font-">{{ item.start_at }} até {{ item.finished_at }}
                            <a href="#" @click.prevent="changeCycle(item.uuid, item.address_construction_uuid)">
                              <i class="fa-solid fa-clock text-success"></i>
                            </a>
                          </div>
                          <br>
                          <div class="progress" style="height: 1.5rem;color: gray">
                            <div
                              role="progressbar"
                              class="progress-bar progress-bar-animated"
                              :class="item.perfomed_minutes < item.predicted_minutes ? 'bg-primary' :'bg-danger'"
                              :style="'width:' + (item.percentage < 10 ? 10 : item.percentage) + '%'"
                            >
                              {{ item.percentage }}%
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </card>
                </div>
              </div>
              <div class="row card-wrapper mt-n3" v-if="loadingFinished">
                <skeleton-puzl-grid style="margin-bottom: -1.4rem !important;" class="p-1"
                                    v-for="item in general_per_page" :classCard="classCardBody"/>
              </div>
              <puzl-empty-data v-if="!loadingFinished && !$_finished.length" :with-card="false"/>
            </card>
          </div>
        </transition>
      </div>
    </div>
    <ModalTravelCycle @updatedCycle="init({})" ref="travelCycle"/>
  </div>
</template>

<script>
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import MultiFilter from "@/components/Utils/MultiFilterV2";
import InputDatePicker from "@/components/InputDatePicker";
import {mapGetters} from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import ModalTravelCycle from "@/views/Modules/Operational/Schedule/Weighing/Shared/ScheduleTravelCycle/_ModalNewTravelCycle";
import PuzlSelect from "../../../../components/PuzlSelect";

export default {
  name: "Index",
  components: {
    PuzlSelect,
    PuzlEmptyData,
    SkeletonPuzlGrid,
    InputDatePicker,
    MultiFilter,
    PuzlBreadcrumb,
    ModalTravelCycle
  },
  data() {
    return {
      showCard: true,
      view: [0, 1, 2],
      getScheduleStatus: {
        0: 'text-danger',
        15: 'text-danger',
        13: 'text-success',
        16: 'text-warning',
      },
      classCard: 'col-md-4', /** coluna principal flexivel card individual */
      classCardBody: 'col-md-12' /** coluna interna flexivel dentro do card */,
      loadingProgrammed: false,
      loadingInProgress: false,
      loadingFinished: false,
      loadingPlant: false,
      loading_more_programmed: false,
      loading_more_in_progress: false,
      loading_more_finished: false,
      range: {
        start: new Date(),
        end: new Date(),
      },
      filter: {},
      general_per_page: 3,
      programmed_params: {
        hasMore: false,
        per_page: 3,
        next_page: '',
        filter: {}
      },
      in_progress_params: {
        hasMore: false,
        per_page: 3,
        next_page: '',
        filter: {}
      },
      finished_params: {
        hasMore: false,
        per_page: 3,
        next_page: '',
        filter: {}
      },
    }
  },
  computed: {
    ...mapGetters({
      $_programmed: "kanban/getProgrammed",
      $_inProgress: "kanban/getInProgress",
      $_finished: "kanban/getFinished",
      $_widgets: "kanban/getWidgets",
      $_plants: 'plant/activeItems',
    })
  },
  beforeMount() {
    this.loadingPlant = true
    this.$store.dispatch('plant/fetchItemsActive').then(() => {
      this.loadingPlant = false
    })
    this.filter.company_plant_id = null
  },
  mounted() {
    this.init({})
    this.mountListeners()
  },
  methods: {
    handleFilterDate(range) {
      this.range = range
      this.init({})
    },
    getWidgets() {
      this.loadingWidgetsSkeleton = true
      this.$store.dispatch('kanban/getWidgets', {filter: this.filter}).then(() => {
        this.loadingWidgetsSkeleton = false
      })
    },
    sendToCharging(travel_uuid) {
      this.$router.push('/operational/schedule/charging/' + travel_uuid)
    },
    navigate(e) {
      const screenHeight = e.target.clientHeight;
      const scrolled = e.target.scrollTop;
      const scrollHeight = e.target.scrollHeight;
      if (scrollHeight - (screenHeight + scrolled) < 213) {
        return true
      }
      return false
    },
    navigateProgrammed(e) {
      if (!this.loading_more_programmed && this.navigate(e)) {
        if (this.programmed_params.hasMore) {
          this.getProgrammed()
        }
      }
    },
    navigateInProgress(e) {
      if (!this.loading_more_in_progress && this.navigate(e)) {
        if (this.in_progress_params.hasMore) {
          this.getInProgress()
        }
      }
    },
    navigateFinished(e) {
      if (!this.loading_more_finished && this.navigate(e)) {
        if (this.finished_params.hasMore) {
          this.getFinished()
        }
      }
    },
    mountListeners() {
      const programmed = document.getElementById('programmed')
      const in_progress = document.getElementById('in_progress')
      const finished = document.getElementById('finished')
      if (programmed) {
        programmed.addEventListener("scroll", this.navigateProgrammed)
      }
      if (in_progress) {
        in_progress.addEventListener("scroll", this.navigateInProgress)
      }
      if (finished) {
        finished.addEventListener("scroll", this.navigateFinished)
      }
    },
    changeCycle(uuid, address_construction_uuid) {
      this.$refs.travelCycle.openModal(uuid, address_construction_uuid);
    },
    getPerPage() {
      switch (this.view.length) {
        case 1:
          return 12
        case 2:
          return 6
        case 3:
          return 4
      }
    },
    /**
     * Busca card programados
     */
    getProgrammed() {
      this.programmed_params.filter = {...this.filter}
      this.programmed_params.per_page = this.getPerPage()
      this.loading_more_programmed = true
      this.$store.dispatch('kanban/getProgrammed', this.programmed_params).then(response => {
        this.programmed_params.hasMore = response.has_more
        this.programmed_params.next_page = response.next_page
        this.loadingProgrammed = false
        this.loading_more_programmed = false
      })
    },
    /**
     * Busca card em andamento
     */
    getInProgress() {
      this.in_progress_params.filter = {...this.filter}
      this.in_progress_params.per_page = this.getPerPage()
      this.loading_more_in_progress = true
      this.$store.dispatch('kanban/getInProgress', this.in_progress_params).then(response => {
        this.in_progress_params.hasMore = response.has_more
        this.in_progress_params.next_page = response.next_page
        this.loading_more_in_progress = false
        this.loadingInProgress = false
      })
    },
    /**
     * Busca card concluído
     */
    getFinished() {
      this.finished_params.filter = {...this.filter}
      this.finished_params.per_page = this.getPerPage()
      this.loading_more_finished = true
      this.$store.dispatch('kanban/getFinished', this.finished_params).then(response => {
        this.finished_params.hasMore = response.has_more
        this.finished_params.next_page = response.next_page
        this.loading_more_finished = false
        this.loadingFinished = false
      })
    },
    setClass() {
      this.showCard = false
      setTimeout(() => {
        this.showCard = true
        this.$nextTick(function () {
          this.mountListeners()
        })
      }, 100)
      /**
       * Define a separação por coluna conforme o tipo de visualização selecionada pelo usuário
       */
      switch (this.view.length) {
        case 1:
          this.classCard = 'col-md-12'
          this.classCardBody = 'col-md-4'
          break;
        case 2:
          this.classCard = 'col-md-6'
          this.classCardBody = 'col-md-6'
          break;
        case 3:
          this.classCard = 'col-md-4'
          this.classCardBody = 'col-md-12'
          break;
      }
    },
    /**
     * reseta o paginador conforme tipo
     */
    resetParams(type) {
      switch (type) {
        case 0:
          this.programmed_params = {
            hasMore: false,
            next_page: '',
            per_page: this.getPerPage(),
            filter: {}
          }
          const programmed = document.getElementById('programmed')
          if (programmed) {
            programmed.scrollTop = 0
          }
          this.loadingProgrammed = true
          this.getProgrammed()
          break;
        case 1:
          this.in_progress_params = {
            hasMore: false,
            next_page: '',
            per_page: this.getPerPage(),
            filter: {}
          }
          const in_progress = document.getElementById('programmed')
          if (in_progress) {
            in_progress.scrollTop = 0
          }
          this.loadingInProgress = true
          this.getInProgress()
          break;
        case 2:
          this.finished_params = {
            hasMore: false,
            next_page: '',
            per_page: this.getPerPage(),
            filter: {}
          }
          const finished = document.getElementById('finished')
          if (finished) {
            finished.scrollTop = 0
          }
          this.loadingFinished = true
          this.getFinished()
          break;
      }
    },
    init(filter = {}) {
      this.filter = filter
      this.filter.range = this.range
      this.getWidgets()
      this.resetParams(0)
      this.resetParams(1)
      this.resetParams(2)
    },
    /**
     * Insere visualização de cards
     * @param type
     */
    setView(type) {
      if (this.view.includes(type)) {
        const indexOf = this.view.indexOf(type)
        this.view.splice(indexOf, 1)
        this.resetParams(type)
      } else {
        this.view.push(type)
      }
      this.init()
      this.general_per_page = this.getPerPage()
      return this.setClass()
    },
  }
}
</script>

<style scoped>
* {
  scroll-behavior: smooth;
}

.kanban .card {
  margin-bottom: 8px !important;
}

.hover-icon {
  opacity: 50%;
}

.hover-icon:hover {
  opacity: 100%;
}
</style>
