export const SET = 'SET'
export const PUSH = 'PUSH'
export const SHOW = 'SHOW'
export const DESTROY = 'DESTROY'
export const UPDATE = 'UPDATE'
export const SET_NEXT_PAGE = 'SET_NEXT_PAGE'
export const SET_NEXT_PAGE_DATA = 'SET_NEXT_PAGE_DATA'
export const SET_ENTITY_ACCOUNT = 'SET_ENTITY_ACCOUNT'
export const SET_SETTINGS = 'SET_SETTINGS'
export const SET_ENTITY_BILL_RECEIVE_ITEMS = 'SET_ENTITY_BILL_RECEIVE_ITEMS'
export const SET_PROGRAMMED_VALUE = 'SET_PROGRAMMED_VALUE'
export const SET_WIDGETS = 'SET_WIDGETS'