<template>
  <div>
    <modal @close="close" size="sm" :show.sync="show">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">{{ title }}</h5>
      </template>
      <div class="row">
        <div class="col-md-12 mt-2">
          <div class="timeline timeline-one-side">
            <div class="timeline-block"><span class="timeline-step badge-primary">
              <i class="fa-solid fa-arrow-rotate-left"></i>
            </span>
              <div v-if="billet && billet.index" class="timeline-content">
                <small class="text-muted font-weight-bold" style="font-family: Gilroy,sans-serif !important">({{billet.index}}/{{billet.total_index}})</small>
                <h4 class=" mb-0" style="font-family: Gilroy,sans-serif !important">{{ billet.amount | currency() }}</h4>
                <div class="row mr-1">
                  <div class="col-md-12">
                    <hr style="margin-bottom: -2px;margin-top: 10px">
                    <label style="font-family: Gilroy,sans-serif !important;font-size: 0.8rem" class="col-form-label form-control-label">
                      Data de vencimento <span class="text-danger">&nbsp;*</span></label
                    >
                    <div class="w-100 pr-3 pr-md-0">
                      <base-input input-group-classes="input-group-sm">
                        <input-date-time-picker :disableDate="new Date()" :mode="'date'" v-model="expires_at" />
                      </base-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div v-if=installment class="form-group row m-2 p-0 mb-1 limited-row">
        <label class="col-md-6 pt-2 pb-0 col-form-label form-control-label">
          Descontos
        </label>
        <div class="col-md-6">
          <base-input input-group-classes="input-group-sm">
            <input
              @keydown="$event.key === '-' ? $event.preventDefault() : null"
              @blur="calcFinalValue()"
              v-money="money"
              maxlength="13"
              inputmode="numeric"
              v-model="installment.discounts"
              class="form-control form-control-sm"
            />
            <template slot="prepend">
              <small class="input-group-sm p-0 m-0">
                R$
              </small>
            </template>
          </base-input>
        </div>
      </div>

      <div v-if=installment class="form-group row m-2 p-0 mb-1 limited-row">
        <label class="col-md-6 pt-2 pb-0 col-form-label form-control-label">
          Juros/Multa
        </label>
        <div class="col-md-6">
          <base-input input-group-classes="input-group-sm">
            <input
              @keydown="$event.key === '-' ? $event.preventDefault() : null"
              @blur="calcFinalValue()"
              v-money="money"
              maxlength="13"
              inputmode="numeric"
              v-model="installment.fees_fines"
              class="form-control form-control-sm"
            />
            <template slot="prepend">
              <small class="input-group-sm p-0 m-0">
                R$
              </small>
            </template>
          </base-input>
        </div>
      </div>

      <div v-if=installment class="form-group row m-2 p-0 mb-1 limited-row">
        <label class="col-md-6 pt-2 pb-0 col-form-label form-control-label">
          Valor Final <span class="text-danger">&nbsp;*</span>
        </label>
        <div class="col-md-6">
          <base-input input-group-classes="input-group-sm">
            <input 
              disabled 
              v-money="money" 
              maxlength="13" 
              inputmode="numeric"
              v-model.lazy="installment.total_value" 
              type="text"
              class="form-control form-control-sm"
            />
            <template slot="prepend">
              <small class="input-group-sm p-0 m-0">
                R$
              </small>
            </template>
          </base-input>
        </div>
      </div>

      <div class="modal-footer">
        <base-button type="secondary" @click="close()">
          Cancelar
        </base-button>
        <base-button :disabled="!expires_at || loadingGenerating" type="success" @click="generate()">
          Emitir
        </base-button>
      </div>
    </modal>
  </div>
</template>

<script>
import PuzlSelect from "@/components/PuzlSelect";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import {VMoney} from "v-money";
export default {
  name: "ModalGenerateIndividualBillet",
  components: {
    PuzlSelect,
    InputDateTimePicker,
    VMoney,
  },
  data() {
    return {
      title: 'Emitir boleto',
      show: false,
      billet: null,
      expires_at: null,
      loading: false,
      indexInstallment: null,
      installment: null,
      loadingGenerating: null,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false
      },
    }
  },
  methods: {
    close() {
      this.show = false
    },
    calcFinalValue() {
      // Parse para evitar erros
      const value = this.$helper.strToNum(this.installment.value);
      let discounts = this.$helper.strToNum(this.installment.discounts);
      const feesFines = this.$helper.strToNum(this.installment.fees_fines);

      // Não permitir desconto maior que total
      if (discounts >= (value + feesFines)) {
        discounts = value + feesFines - 0.01;
        this.installment.discounts = discounts.toFixed(2);
      }

      // Calcular total
      const totalValue = value - discounts + feesFines;
      this.installment.total_value = totalValue.toFixed(2);
      this.$forceUpdate();
    },
    generate() {
      this.loadingGenerating = true;
      this.calcFinalValue();
      let loader = this.$loading.show();
      let params = {
        id: this.billet.installment_id,
        expires_at: this.expires_at,
        installment_changed: true,
        installment_discounts: this.$helper.strToNum(this.installment.discounts),
        installment_fees_fines: this.$helper.strToNum(this.installment.fees_fines),
      }
      this.$store.dispatch("billReceive/generateIndividualBillet", params).then(response => {
        const billet = {
          status: 0,
          expired_at: this.expires_at,
          id: response.data
        }
        this.$notify({
          type: response.error_type,
          message: response.message
        })
        billet.amount = this.installment.total_value;
        this.$emit('setBillet', {billet: billet, index: this.indexInstallment});
        this.close();
      }).finally(() => {
        loader.hide();
        this.loadingGenerating = false;
      });
    },
    openModal(billet, indexInstallment, installment) {
      this.indexInstallment = indexInstallment;
      this.billet = billet;
      this.installment = this.$helper.cloneObject(installment);
      this.show = true;
    }
  },
};
</script>

<style>
</style>
