<template>
  <modal :show.sync="show">
<template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(submitAndClose)"
            autocomplete="off"
          >
            <!-- FIELD STATUS -->
            <div>
              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-4 pb-0 mb-1 col-form-label form-control-label"
                >
                  Status
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 pt-2">
                  <base-switch
                    type="success"
                    offText="inativo"
                    onText="ativo"
                    class="success"
                    v-model="category.status"
                  ></base-switch>
                </div>
              </div>
            </div>

            <!-- FIELD NAME -->
            <div class="form-group row m-0 p-0">
              <label
                class="col-md-4 pb-0 mb-1 col-form-label form-control-label"
              >
                Categoria
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-8 mb-1 pr-4">
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-classes="form-control-sm">
                    <input 
                      type="text" 
                      class="form-control form-control-sm" 
                      v-model="category.name"
                      :class="errors[0] ? 'is-invalid' : 'is-valid'"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <!-- FIELD OBSERVATION -->
            <div class="form-group row m-0 p-0">
              <label
                class="col-md-4 pb-0 mb-1 col-form-label form-control-label"
              >
                Observações
              </label>
              <div class="col-md-8 mb-1 pr-4">
                <base-input input-group-classes="input-group-sm">
                  <textarea
                    type="text"
                    rows="2"
                    class="form-control form-control-sm"
                    v-model="category.observation"
                  >
                  </textarea>
                </base-input>
              </div>
            </div>

            <!-- MODAL FOOTER -->
            <div class="modal-footer">
              <base-button 
                type="secondary" 
                @click="close()"
              >
                Cancelar
              </base-button>
              <base-button
                type="success"
                native-type="submit"
                :disabled="invalid"
                :loading="isLoading"
              >
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>    
  </modal>
</template>

<script>
const {formatErrorValidation} = require("@/plugins")

export default {
  name: "ModalCreate",
  props: ["show"],
  data () {
    return {
      title: "Cadastrar Categoria",
      category: {
        name: '',
        status: true,
        observation: ''
      },
      isLoading: false,
    };
  },
  computed: {},
  methods: {
    cleanCategory () {
      this.category = {
        name: '',
        status: true,
        observation: ''
      }
    },
    close () {
      this.cleanCategory();
      this.$emit("close");
    },
    submitAndClose () {
      this.$Progress.start()
      this.isLoading = true
      this.$store.dispatch('entityCategory/add', this.category)
        .then(response => {
          this.isLoading = false
          this.close();
          this.$Progress.finish()
          this.$notify({ type: response.error_type, message: response.message })
        })
        .catch(error => {
          if (error.response.status === 422) {
            let message = formatErrorValidation(error.response.data.errors)
            this.$notify({ type: 'danger', message: message })
          }
          this.$Progress.finish()
          this.isLoading = false
        })
    },
  },
};
</script>

<style scoped></style>
