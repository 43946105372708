<template>
  <td v-if="isVisible" :style="'text-align: ' + align">
    <nav :style="formatStyle()"
      style="align-items: center;">
      <slot />
    </nav>
  </td>
</template>

<script>
import SkeletonPuzl from "@/components/SkeletonPuzl.vue";
export default {
  name: 'shaded-col',
  props: {
    align: {
      type: String,
      default: 'left',
    },
    customStyle: {
      type: String,
      default: ''
    },
    isVisible: {
      type: Boolean,
      default: true
    }
  },
  components: {},
  computed: {},
  methods: {
    formatStyle(){
      let style = this.align !== 'left' ? 'display: inline-flex !important;' : '';
      style += `;${this.customStyle}`;
      return style;
    }
  },
};
</script>
