<template>
  <div>
    <modal :show.sync="modal.open">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
            <div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 col-form-label form-control-label">
                  Status
                </label>
                <div class="col-md-7 pt-2">
                  <base-switch
                    v-model="feature.status"
                    type="success"
                    offText="inativo"
                    onText="ativo"
                    class="success"
                  ></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                >
                  Tipo
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <el-select v-model="feature.type"
                                 filterable
                                 disabled
                                 readonly
                                 size="mini"
                      >
                        <el-option v-for="type in types"
                                   :key="type.value"
                                   :label="type.label"
                                   :value="type.value">
                        </el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0" v-if="feature.type!==null">
                <label
                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                >
                  Descrição curta
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="feature.short_description"
                        class="form-control form-control-sm"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0" v-if="feature.type!==null">
                <label
                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                >
                  Descrição
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      v-model="feature.description"
                      class="form-control form-control-sm"
                    />
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0" v-if="feature.type!==null && feature.type===1">
                <label
                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                >
                  Unidade de medida
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <el-select v-model="feature.measured_unit_id"
                               placeholder="Selecione"
                               filterable
                               disabled
                               readonly
                               size="mini"
                    >
                      <el-option v-for="measuredunit in measuredunits"
                                 :key="measuredunit.id"
                                 :label="measuredunit.letter"
                                 :value="measuredunit.id">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0" v-if="feature.type!==null && feature.type===1">
                <label
                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                >
                  Idade Tipo
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <el-select v-model="feature.period_type"
                               placeholder="Selecione"
                               filterable
                               disabled
                               readonly
                               size="mini"
                    >
                      <el-option v-for="period_type in period_types"
                                 :key="period_type.value"
                                 :label="period_type.label"
                                 :value="period_type.value">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0" v-if="feature.type!==null && feature.type===1">
                <label
                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                >
                  Idade valor
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      v-model="feature.period"
                      class="form-control form-control-sm"
                      v-mask="['####']"
                      readonly
                      disabled
                    />
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0" v-if="feature.type!==null && feature.type===1">
                <label
                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                >
                  Tolerância (horas)
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      v-model="feature.tolerance"
                      class="form-control form-control-sm"
                      v-mask="['#,#','##,#']"
                    />
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0" v-if="feature.type!==null">
                <label
                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                >
                  Ensaio
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <el-select v-model="feature.hardened_state_id"
                               placeholder="Selecione"
                               filterable
                               disabled
                               readonly
                               size="mini"
                    >
                      <el-option v-for="hardened_state in hardened_states"
                                 :key="hardened_state.id"
                                 :label="hardened_state.short_description+' ('+hardened_state.measured_unit.letter+')'"
                                 :value="hardened_state.id">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0" v-if="feature.type!==null && feature.type===1">
                <label
                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                >
                  Sinal
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <el-select v-model="feature.operator"
                               placeholder="Selecione"
                    >
                      <el-option v-for="operator in operators"
                                 :key="operator.value"
                                 :label="operator.label"
                                 :value="operator.value">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0" v-if="feature.type!==null && feature.type===1">
                <label
                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                >
                  Casas Decimais
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <el-select v-model="feature.decimal_places"
                               placeholder="Selecione"
                    >
                      <el-option v-for="place in places"
                                 :key="place.value"
                                 :label="place.label"
                                 :value="place.value">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>

            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')"
              >Cancelar</base-button
              >
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingSave">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "Edit",
  data () {
    return {
      modal: {
        title: 'Responsabilidade',
        open: false,
      },
      feature: {
        status: true,
        short_description: null,
        description: null,
        measured_unit_id: null,
        period_type: null,
        period: null,
        tolerance: null,
        hardened_state_id: null,
        operator: null,
        type: null,
        decimal_places: null,
      },
      loadingSave: false,
    }
  },
  computed: {
    ...mapGetters({
      'places': 'mixFeature/places',
      'period_types': 'mixFeature/period_types',
      'types': 'mixFeature/types',
      'operators': 'mixFeature/operators',
      'measuredunits': 'measuredunit/fetch',
      'hardened_states': 'testHardenedState/fetch',
    }),
  },
  methods: {
    closeModal () {
      this.modal.open = false
    },
    handleEditModal (uuid) {
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'})
      this.$Progress.start()
      const convertDecimals = (str) => {
        return str.toString().replace('.', ',')
      }
      this.$store.dispatch('mixFeature/show', uuid)
        .then( (response) => {
          this.feature = {
            uuid: response.data.uuid || null,
            status: response.data.status || false,
            short_description: response.data.short_description || null,
            description: response.data.description || null,
            measured_unit_id: response.data.measured_unit.id || null,
            period_type: response.data.period_type || null,
            period: response.data.period || null,
            tolerance: convertDecimals(response.data.tolerance) || null,
            hardened_state_id: response.data.hardened_state.id || null,
            operator: response.data.operator || null,
            type: response.data.type || null,
            decimal_places: response.data.decimal_places || null,
          }

          this.modal.open = true
          this.$Progress.finish()
        }).catch((error) => {
          if(error.response.status===422){
            let errors = Object.values(error.response.data.errors)
            errors = errors.flat().toString()
            this.$notify({ type: 'danger', message: errors })
          }
          this.$Progress.finish()
        this.loadingSave = false
      })
    },
    save () {
      this.$Progress.start()
      this.loadingSave = true
      const convertDecimals = (str) => {
        return str.replace(',', '.')
      }
      if (this.feature.tolerance) {
        this.feature.tolerance = convertDecimals(this.feature.tolerance)
      }
      this.$store.dispatch('mixFeature/update', this.feature)
        .then(response => {
          this.loadingSave = false
          this.modal.open = false
          this.$Progress.finish()
          this.$notify({ type: response.error_type, message: response.message })
        })
        .catch(error =>{
          this.$notify({ type: error.data.error_type, message: error.data.message })
          this.$Progress.finish()
          this.loadingSave = false
        })
    },
  },
  mounted () {
    this.$store.dispatch('measuredunit/fetchItems')
    this.$store.dispatch('testHardenedState/fetchItems')
  },
  created () {
  }
}
</script>

<style scoped>

</style>
