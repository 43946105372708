<template>
  <div v-show="props.show" style="border-radius: 12px;">
    <div class="app-search-bar-filter">
      <div class="app-search-bar-filter-header">
        <h2 class="m-0 new-default-black-font">Filtros</h2>
        <div class="app-search-bar-filter-closer">
          <img
            src="/img/icons/close-window.png"
            class="pointer"
            @click.prevent="onCloseClick" width="16"
          />
        </div>
      </div>
      <div class="app-search-bar-filter-content">
        <!-- INÍCIO de uma Seção - SERÁ UM COMPONENTE -->
        <slot />
        <!-- FINAL de uma Seção - SERÁ UM COMPONENTE -->
      </div>
      <!-- INÍCIO das actions -->
      <div class="app-search-bar-filter-actions">
        <div class="ef-actions-section">
          <div class="ef-action-buttons">
            <div
              @click.prevent="onResetClick"
              class="ef-button ef-btn-gray pointer"
            >
              <img src="/img/icons/icons8/ios/restart_secondary.png" width="16">
              <h4>Redefinir</h4>
            </div>
            <div
              @click.prevent="onApplyClick"
              class="ef-button ef-btn-blue pointer"
            >
              <img src="/img/brand/logo.png" width="16">
              <h4>Aplicar</h4>
            </div>
          </div>
        </div>
      </div>
      <!-- FINAL das actions -->
    </div>
    <nav class="app-search-bar-filter-blur" @click.prevent="onCloseClick"></nav>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, watch } from 'vue'

/**
 * Propriedades para o componente
 * @typedef {Object} Props
 * @property {Boolean} show - Exibir modal
 * @property {Boolean} isCloseOnReset - Fechar modal ao redefinir filtros
 */
/** @type {Props} */
const props = defineProps({
  show: {
    type: Boolean,
    default: () => true,
    required: false,
  },
  isCloseOnReset: {
    type: Boolean,
    default: () => true,
    required: false,
  },
});

const emit = defineEmits([
  'handleClose',
  'handleClear',
  'handleSearch'
]);

function onCloseClick() {
  emit('handleClose');
};

function onApplyClick() {
  emit('handleSearch');
  emit('handleClose');
};

function onResetClick() {
  emit('handleClear');
  if (props.isCloseOnReset) {
    emit('handleClose');
  }
};

/** Esconder scroll do html quando props.show for verdadeiro */
watch(() => props.show, (newValue) => {
  if (newValue) {
    document.documentElement.style.height = '100%';
    document.body.style.height = '100%';
    document.documentElement.style.overflow = 'hidden';
  } else {
    document.documentElement.style.height = '';
    document.body.style.height = '';
    document.documentElement.style.overflow = '';
  }
});

</script>

<style scoped>
.app-search-bar-filter {
  position: absolute;
  background: white;
  border-radius: 12px;
  overflow: hidden;
  max-height: 800px;
  top: 50px;
  right: -100%;
  margin-left: -38px;
  z-index: 99999;
}

@media (max-width: 767px) {
  .app-search-bar-filter {
    min-width: 340px;
  }
}

@media (min-width: 768px) {
  .app-search-bar-filter {
    min-width: 420px;
  }
}

@media (min-width: 1200px) {
  .app-search-bar-filter {
    margin-left: -38px;
    right: unset;
  }
}

.app-search-bar-filter-header {
  background-color: #F2F4F9;
  padding: 16px;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.app-search-bar-filter-closer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.app-search-bar-filter-content {
  padding: 16px 16px 0px 16px;
  max-height: 600px;
  overflow: auto;
}

.app-search-bar-filter-actions {
  padding: 16px 16px 10px 16px;
}

.ef-actions-section {
  padding-left: 0;
  padding-right: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: right;
}

.ef-action-buttons {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.ef-button {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 2px 9px 2px 9px;
}

.ef-button h4 {
  margin: 0;
  font-weight: 400;
}

.ef-btn-gray {
  border: 1px solid #606062;
  border-radius: 16px;
}

.ef-btn-gray:hover {
  background-color: #606062;
}

.ef-btn-gray h4 {
  color: #606062;
}

.ef-btn-blue {
  border: 1px solid #1A70B7;
  border-radius: 16px;
}

.ef-btn-blue:hover {
  background-color: #1A70B7;
}

.ef-btn-blue:hover h4, .ef-btn-blue:hover img, .ef-btn-gray:hover h4, .ef-btn-gray:hover img {
  filter: brightness(0) invert(1);
}

.ef-btn-blue h4 {
  color: #1A70B7;
}

.app-search-bar-filter-blur {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.63);
  top: 0;
  left: 0;
  z-index: 9999;
}
</style>
