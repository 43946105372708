<template>
  <div>
    <base-header class="bg-gray-content mb-n4">
      <div class="row align-items-center py-4">
        <div class="col-md-4">
          <h2 style="font-size: 1.2rem !important;color: #6c757d;">
            <img height="30px" src="/img/icons/icons8/ios/general-ledger_default.png" class="mr-2"/>
            Taxas e tributos
          </h2>
        </div>
        <div class="col-md-8 pr-4">
          <div class="row d-flex justify-content-end">
            <div v-if="$hasPrivilege(1)" class="mb-md-0 mt-2 mt-md-0 mb-2 col-md-3 px-1">
              <base-button type="success" block style="border-radius: 4px !important;"
                @click="handleCreate()">
                <img src="/img/icons/plus-math--v1-white.png" width="19">
                novo
              </base-button>
            </div>
            <div class="mb-md-0 mt-2 mt-md-0 mb-2 col-md-3 px-1">
              <base-button type="primary" block style="border-radius: 4px !important;"
                @click.prevent="handleCreateEntity">
                <img src="/img/icons/icons8/ios/new-store.png" width="19">
                FORNECEDOR
              </base-button>
            </div>
          </div>
        </div>
      </div>
      <hr class="mt-1">
    </base-header>
    <List />    
    <modal-create ref="ModalCreate" />
    <ModalCreateEntity ref="modalCreateEntity" />
  </div>
</template>

<script>
import List from "./Shared/_List";
import ModalCreate from "./Shared/_ModalCreate";
import ModalCreateEntity from "../Entity/Shared/_Create";
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";

export default {
  components: {
    List,
    ModalCreate,
    ModalCreateEntity,
    PuzlBreadcrumb,
  },
  methods: {
    handleCreate() {
      this.$refs.ModalCreate.handleCreateModal();
    },
    handleCreateEntity() {
      this.$refs.modalCreateEntity.openModal(true);
    },
  },
};
</script>
