<template>
  <div>
    <modal size="md" :show.sync="show">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="checklist-title mb-0">
              <b
                class="display-5" style="font-size: 1.2rem;color: #32325d">{{ contract_proposal.code }}
                <base-button @click.prevent="redirectToInvoices(contract_proposal.uuid)" size="sm" type="danger"
                             outline>
                  <i class="fas fa-external-link-alt"></i>
                  IR PARA FATURAS DO CONTRATO
                </base-button>
              </b>
            </div>
            <div class="checklist-title mb-0">
              <b
                class="">
                {{
                  contract_proposal.construction
                  && contract_proposal.construction.customer_construction.customer_name
                }}
              </b>
            </div>
            <div class="checklist-title mb-0">
                  <span
                    class="">
                  {{
                      contract_proposal &&
                      contract_proposal.construction &&
                      contract_proposal.construction.construction_name
                    }}
                </span>
            </div>
          </div>
          <div class="col-md-12 text-center">
            <h4 class="checklist-title mb-0 text-center">
              <span class="display-5 text-center">Fatura {{ invoice.id }}</span>
            </h4>
            <!--          <base-button type="danger" outline @click.prevent="">-->
            <!--            IR PARA PAGAMENTOS-->
            <!--          </base-button>-->
          </div>
          <div v-if="$_payments.length > 0" class="col-md-12 pt-0 p-0" style="margin-top: -20px"
               v-for="item in $_payments">
            <hr>
            <div style="margin-top: -20px" class=" border-success border-3 border-left">
              <!--              <div class="ml-2">-->
              <!--                <b>{{ item.payment_method.name }} |  <span class="text-blue">{{item.total_value | currency() }}</span>-->
              <!--                </b>-->
              <!--                <br>-->
              <!--                <span>{{ item.payment_term.name }}-->
              <!--                      </span>-->
              <!--              </div>-->
              <div class="row pl-2">
                <div class="col-md-8"><b>{{ item.payment_method.name }}
                </b>
                  <badge style="font-family: FreeMono, monospace;font-size: 17px" type="secondary">
                    {{ item.total_value | currency() }}
                  </badge>
                  <br>
                  <span>{{ item.payment_term.name }}</span>
                </div>
                <div class="col-md-4 text-center">
                  <base-button
                    v-if="!item.all_have_billet
                      && item.payment_method.uuid === '947bf938-e098-4d1e-965a-c33fc9aa05d5'
                      && !hasAnyPaymentAsReceived()"
                    @click.prevent="showBillet(item)" size="sm" block type="primary">
                    <i class="fas fa-solid fa-print "></i>
                    Emitir boleto
                  </base-button>
                  <span>
                  <img v-if="item.all_have_billet"
                       style="width: 65px;cursor: pointer"
                       @click.prevent="downloadBilletBatch(item.bank_billet_batch_id)"
                       src="/img/anapuena-boleto.webp"/>
                      </span>
                </div>
              </div>
            </div>
            <div class="mb-0 ml-4" v-for="installment in item.installments">
              <div class="row pt-1">
                <div class="col-8">
                  {{ installment.index }}. {{ installment.value | currency() }} | {{ installment.due_date | parseDate('DD/MM/YYYY') }}
                </div>
                <div class="col-4">
                  <base-button
                    block
                    size="sm"
                    :type="getColor(Number(installment.status))"
                    outline
                    slot="title-container"
                    class="active text-uppercase"
                  >
                    {{ getTitle(Number(installment.status)) }}
                  </base-button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="$_payments.length === 0" class="col-md-12" style="margin-top: -18px">
            <hr>
            <puzl-empty-data style="margin-top: -35px" msg="Não há pagamentos vinculados" :withCard="false"/>
          </div>
        </div>
      </div>
      <div class="col-md-12 mt-4 text-right p-0">
        <base-button @click.prevent="close" type="secondary" class="text-capitalize">
          Fechar
        </base-button>
      </div>
      <modal-generate-billet @fetch="fetch" ref="modalGenerateBillet"/>
      <modal-generate-billet-has-generated ref="modalGenerateBilletHasGenerated"/>
    </modal>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import ModalGenerateBillet from "../../BillReceive/Shared/Modals/_ModalGenerateBillet";
import ModalGenerateBilletHasGenerated from "../../BillReceive/Shared/Modals/_ModalGenerateBilletHasGenerated";
import {base_url_ms} from '@/plugins'

export default {
  props: ['show'],
  name: "ModalPaymentTerms",
  components: {
    PuzlEmptyData,
    ModalGenerateBillet,
    ModalGenerateBilletHasGenerated,
  },
  computed: {
    ...mapGetters({
      $_payments: 'billingInvoice/getListPayments',
    }),
  },
  data() {
    return {
      base_url_ms: base_url_ms(),
      contract_proposal: null,
      invoice: null,
      title: 'Recebimentos',
    };
  },
  methods: {
    /**
     * @description
     * Verificar se existe algum pagamento como o status recebido
     *
     * @returns {boolean}
     */
    hasAnyPaymentAsReceived(){
      return this.$_payments[0].installments.filter(item => item.status === 1).length > 0;
    },
    /**
     * Setar invoice em modal
     * @param invoice_id
     * @param showBilletIfSinglePayment Abrir modal de emissão de boleto se for um único pagamento
     */
    setInvoice(invoice, showBilletIfSinglePayment) {
      this.invoice = invoice
      this.contract_proposal = invoice.contract_proposal
      if (showBilletIfSinglePayment && (this.$_payments.length === 1)){
        this.showBillet(this.$_payments[0]);
      }
    },
    redirectToInvoices(contract_proposal_uuid) {
      this.$router.push('/commercial/contract-proposal/invoices/' + contract_proposal_uuid)
    },
    fetch() {
      this.$emit('fetch', this.invoice.id)
    },
    close() {
      this.$emit('close')
    },
    showBillet(payment) {
      if (!payment.any_has_billet) {
        let loader = this.$loading.show()
        return this.$store.dispatch("billReceive/getInfoBillet", {id: payment.bill_receive_id, type: 1}).then(response => {
          const billet = response.data
          this.$store.dispatch("billReceive/show", payment.bill_receive_id).then(response => {
            const bill_receive = response.data
            this.$refs.modalGenerateBillet.openModal(payment.bill_receive_id, billet, bill_receive)
            loader.hide()
          });
        });
      }
      let loader = this.$loading.show()
      return this.$store.dispatch("billReceive/getInfoBillet", {id: payment.bill_receive_id, type: 1}).then(response => {
        const billet = response.data
        this.$store.dispatch("billReceive/show", payment.bill_receive_id).then(response => {
          const bill_receive = response.data
          this.$refs.modalGenerateBilletHasGenerated.openModal(payment.bill_receive_id, billet, bill_receive)
          loader.hide()
        });
      });
    },
    downloadBilletBatch(bank_billet_batch_id) {
      if (!bank_billet_batch_id) {
        this.$notify({
          type: 'warning',
          message: 'O lote ainda não foi gerado. Favor entre em contato com o suporte.'
        });
      }
      this.$Progress.start()
      let loader = this.$loading.show()
      this.$store.dispatch('bankBillet/getPdfFileUrl', bank_billet_batch_id)
        .then(async (response) => {
          let blob = new Blob([response], {type: "application/pdf"});
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", `Lote ${bank_billet_batch_id}.pdf`);
          await link.click();
          this.$snotify.success('Download iniciado com sucesso!', {
            timeout: 1000,
            icon: false,
            position: "centerBottom",
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false
          });
          loader.hide()
        });
    },
    getColor(status) {
      switch (status) {
        case 0:
          return "yellow";
        case 1:
          return "success";
        case 2:
        case 3:
          return "danger";
        case 4:
          return "info";
        case 5:
          return "default";
        case 6:
          return "warning";
        case 7:
          return "indigo";
        case 8:
          return "primary";
        case 9:
          return "danger";
      }
    },
    getTitle(status) {
      switch (status) {
        case 0:
          return "Pendente";
        case 1:
          return "Recebido";
        case 2:
          return "Atrasado";
        case 3:
          return "Cancelado";
        case 4:
          return "Serasa";
        case 5:
          return "Cartório";
        case 6:
          return "Protestado";
        case 7:
          return "Jurídico";
        case 8:
          return "Aprovado";
        case 9:
          return "Negado";
      }
    },
  },
};
</script>

<style></style>
