var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('multi-filter',{ref:"multi-filter",attrs:{"filter":_vm.filter},on:{"fetch":_vm.init}}),_c('div',{staticClass:"container-fluid"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingSkeleton),expression:"loadingSkeleton"}],staticClass:"row card-wrapper"},_vm._l((3),function(index){return _c('SkeletonPuzlGrid',{key:index})}),1),(!_vm.customers.length && !_vm.loadingSkeleton)?_c('PuzlEmptyData'):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.customers.length && !_vm.loadingSkeleton),expression:"customers.length && !loadingSkeleton"}],staticClass:"row card-wrapper"},_vm._l((_vm.customers),function(customer,index){return _c('div',{key:index,staticClass:"col-lg-4"},[_c('card',{class:customer.status ? 'card-border-top-success' : 'card-border-top-danger'},[_c('div',{staticClass:"row align-items-center mb-3"},[_c('div',{staticClass:"col-8"},[_c('h5',{staticClass:"h4 mb-0"},[_vm._v(" "+_vm._s(customer.customer_name)+" ")]),_c('h4',[(customer.document)?_c('small',[_vm._v(_vm._s(customer.document))]):_vm._e(),(customer.document)?_c('br'):_vm._e(),_c('small',[_vm._v("Cód.: "+_vm._s(customer.id))])])]),_c('div',{staticClass:"col-4 text-right"},[_c('base-dropdown',{attrs:{"menuOnRight":""}},[_c('base-button',{staticClass:"dropdown-toggle p-2 rounded m-0",attrs:{"slot":"title-container","type":"primary"},slot:"title-container"},[_c('i',{staticClass:"fas fa-cog"})]),_c('div',{staticClass:"dropdown-divider p-0 m-0"}),_c('router-link',{attrs:{"to":{
                    path:'/commercial/customer-construction/constructions',
                    name: 'commercial.customer-construction.constructions',
                    params: { customer_construction_uuid: customer.uuid }}}},[_c('a',{staticClass:"dropdown-item"},[_c('i',{staticClass:"fas fa-building text-primary"}),_vm._v(" Obras ")])]),_c('div',{staticClass:"dropdown-divider p-0 m-0"}),_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.handleEditCustomer(customer.uuid)}}},[_c('i',{staticClass:"fas fa-edit text-warning"}),_vm._v(" Editar ")]),_c('div',{staticClass:"dropdown-divider p-0 m-0"}),_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.handleDeleteCustomer(customer.uuid)}}},[_c('i',{staticClass:"fas fa-times text-danger"}),_vm._v(" Excluir ")])],1)],1)]),_c('div',{on:{"click":function($event){$event.preventDefault();return _vm.getConstruction(index, customer.uuid)}}},[(!_vm.loadingSkeleton && !_vm.loading)?_c('collapse',{staticClass:"border rounded p-0 mt-3"},[_c('collapse-item',{staticClass:"header-gray",attrs:{"back-ground":"border-header-primary-bottom"}},[_c('h5',{staticClass:"mb-0",attrs:{"slot":"title"},slot:"title"},[_vm._v("Obras")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(customer.loading),expression:"customer.loading"}],staticClass:"row"},[_c('SkeletonPuzlFullWidth')],1),_vm._l((customer.construction),function(construction,indexConstruction){return _c('collapse',{key:indexConstruction,staticClass:"border rounded p-0 mt-2"},[_c('collapse-item',{staticClass:"header-gray",attrs:{"is-active":true,"back-ground":"border-header-primary-bottom"}},[_c('h5',{staticClass:"mb-0",attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(construction.construction_name)+" "),_c('small',[_vm._v("(Cód. "+_vm._s(construction.id)+")")])]),(construction.contractProposal)?_c('h5',{staticClass:"h3",class:[
                    construction.contractProposal.contract_proposal_status===1 ? 'text-success' : '',
                    construction.contractProposal.contract_proposal_status===2 ? 'text-warning' : '',
                    construction.contractProposal.contract_proposal_status===3 ? 'text-orange' : '',
                    construction.contractProposal.contract_proposal_status===4 ? 'text-muted' : '',
                    ]},[_c('badge',{attrs:{"tag":"a","href":"#","type":_vm.types[construction.contractProposal.contract_proposal_status]}},[_vm._v(" "+_vm._s(_vm.statusName[construction.contractProposal.contract_proposal_status])+" ")])],1):_vm._e(),(construction.defaultAddress)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('span',{staticClass:"mt-4 p"},[_c('h5',{staticClass:"h4"},[_vm._v(" "+_vm._s(construction.defaultAddress.city)+" / "+_vm._s(construction.defaultAddress.state)+" ")]),_c('span',{staticClass:"h4 small"},[_vm._v(" "+_vm._s(construction.defaultAddress.address || '')+", "+_vm._s(construction.defaultAddress.number || '')+", "+_vm._s(construction.defaultAddress.district || '')+" ")]),_c('br')]),_c('span',{staticClass:"mt-4 p"},[_c('span',{staticClass:"h4 small"},[_vm._v(" "+_vm._s(construction.defaultAddress.postal_code || '')+" ")])])])]):_vm._e()])],1)})],2),(_vm.loadingSkeleton || _vm.loading)?_c('collapse-item',{staticClass:"header-gray",attrs:{"back-ground":"border-header-primary-bottom"}},[_c('h5',{staticClass:"mb-0",attrs:{"slot":"title"},slot:"title"},[_vm._v("Aguarde...")])]):_vm._e()],1):_vm._e()],1)])],1)}),0)],1),_c('loading-pagination',{attrs:{"show":_vm.loading && !_vm.loadingSkeleton}}),_c('modal-edit-customer',{ref:"editCustomer"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }