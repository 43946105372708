/**
 * Type para Incluir/Editar/Visualizar
 *
 * @typedef {Object} StockLocationType
 * @property {?number} id // [V]
 * @property {string} name
 * @property {boolean} status
 * @property {string} observation
 * @property {?number} created_by_user_id // [V]
 * @property {?number} updated_by_user_id // [V]
 * @property {?string} created_at // [V]
 * @property {?string} updated_at // [V]
 */
export const StockLocationType = {};

/**
 * Inicializar StockLocationType
 * 
 * @returns {StockLocationType}
 */
export const initStockLocationType = () => {
  return {
    id: null,
    name: '',
    status: true,
    observation: '',
    created_by_user_id: null,
    updated_by_user_id: null,
    created_at: null,
    updated_at: null, 
  }
};