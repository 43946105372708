<template>
  <div>
    <modal v-if="show" size="md" :show.sync="show">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ title }}</h5>
      </template>
      <div class="container">
        <div class="form-group row m-0 p-0 mb-1 mt-3">
          <div class="col-md-12">
            <InputDatePicker
              ref="datePicker"
              @handleFilterDate="handleFilterDate"
              :default-range="defaultRange"
            />
          </div>
        </div>

        <div class="form-group row m-0 p-0 mb-1 mt-3">
          <label class="col-md-2 form-control-label mt-1"> Central </label>
          <div class="col-md-10">
            <PuzlSelect
              v-model="selectedCompanyPlants"
              :items="[{id: 'all', name: 'Todos'}, ...$_plants]"
              customKey="id"
              :multiple="true"
              :loading="loadingCompanyPlants"
              :disabled="loadingCompanyPlants"
              @input="setAll"
            />
          </div>
        </div>
      </div>

      <div class="col-md-12 mt-2 text-right p-0 mt-4">
        <base-button
          @click.prevent="close"
          type="secondary"
          class="text-capitalize"
        >
          Cancelar
        </base-button>
        <base-button
          @click.prevent="submitAndClose"
          type="success"
          native-type="submit"
          :loading="isLoading"
        >
          <span class="btn-label"><i class="fa-solid fa-file-excel"></i></span>
          Download
        </base-button>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import InputDatePicker from "@/components/InputDatePicker";
import PuzlSelect from "@/components/PuzlSelect";
import moment from 'moment';
import { date } from "../../../../../helpers";

export default {
  props: ["show"],
  name: "ModalResumeFinishedTravelsExcelReport",
  components: {
    InputDatePicker,
    PuzlSelect,
  },
  data() {
    return {
      title: "Gerar Relatório Resumo Geral (Excel)",
      filter: {
        range: null,
        company_plants: [],
      },
      range: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      },
      defaultRange: {
        start: null,
        end: null,
      },
      selectedCompanyPlants: [],
      isLoading: false,
      loadingCompanyPlants: true,
    };
  },
  computed: {
    ...mapGetters({
      $_plants: "plant/activeItems",
    }),
  },
  mounted() {
    this.$watch("show", function (value) {
      if (value) {
        this.init();
      }
    });
  },
  methods: {
    init() {
      this.defaultRange = {
        start: date
          .make()
          .setStartOf(date.UNIT_OF_TIME.MONTH)
          .format(date.FORMAT.ISO_8601),
        end: date
          .make()
          .setEndOf(date.UNIT_OF_TIME.MONTH)
          .format(date.FORMAT.ISO_8601),
      };
      this.$refs.datePicker.resetDate();
      this.loadData();
      this.range = this.defaultRange;
      this.selectedCompanyPlants = [];
    },
    close() {
      this.$emit("close");
    },
    async submitAndClose() {
      // Filtro de dados
      this.filter.range = this.range;
      this.filter.company_plants = !this.selectedCompanyPlants.includes("all")
        ? this.selectedCompanyPlants
        : []

      // Baixar Relatório em Excel
      let loader = this.$loading.show();
      this.isLoading = true;
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store
        .dispatch("scheduleResume/getResumeFinishedTravelsExcelReport", {filter: this.filter})
        .then(async (response) => {
          let blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "Relatório de resumo geral.xlsx");
          await link.click();
          loader.hide();
          this.isLoading = false;
          this.close();
          this.$notify({
            type: "success",
            message: "Solicitação realizada com sucesso!",
          });
        })
        .catch((error) => {
          loader.hide();
          this.isLoading = false;
          this.$notify({
            type: "danger",
            message: "Erro ao realizar o download do arquivo excel!",
          });
        });
    },
    /**
     * Verifica se o intervalo é maior que 31 dias.
     * @param {object} range
     * @returns {boolean}
     */
    checkDateRangeExceedsLimit(range) {
      let startDate = moment(range.start);
      let endDate = moment(range.end);
      if (endDate.diff(startDate, 'days') > 30) {
        // Seta data inicial da tela
        this.range = {
          start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
        };
        this.$refs.datePicker.resetDate();
        this.$notify({
          type: "warning",
          message: "O intervalo de datas não pode ser maior que 31 dias",
        });
        return false;
      }
      return true;
    },
    handleFilterDate(filter) {
      this.range = filter;
      if (!this.checkDateRangeExceedsLimit(this.range)) {
        return;
      }
    },
    setAll() {
      const index = this.selectedCompanyPlants.findIndex((item) => item == 'all');
      if (this.selectedCompanyPlants.length > 1 && index === 0) {
        return this.selectedCompanyPlants.splice(0, 1)
      }
      if (this.selectedCompanyPlants.length > 1 && this.selectedCompanyPlants.includes("all")) {
        this.selectedCompanyPlants = []
        this.selectedCompanyPlants.push("all")
      }
    },
    loadData() {
      this.loadingCompanyPlants = true;
      this.$store.dispatch('plant/getPlantsSimplified').then(() => {
        this.loadingCompanyPlants = false;
      });
    },
  },
};
</script>

<style></style>
