<template>
  <div>
    <div class="row card-wrapper" v-show="loading">
      <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
    </div>
    <div class="row card-wrapper" v-if="tributes.length && !loading">
      <div class="col-lg-4" v-for="(tribute, index) in tributes" :key="index">
        <card>
          <div class="row mb-1">
            <div class="col-10" style="display: flex; align-items: center;">
              <h6 class="new-default-gray-font m-0">
                {{ tribute.updated_at | parseDate("DD MMM YYYY") }}
              </h6>
              <el-popover v-if="tribute.updated_by" trigger="click" placement="right">
                <span style="width: 200px;"
                  class="row">
                  <div class="col-12 mb-1" style="display: flex; align-items: center;">
                    <img class="mr-2" src="/img/icons/icons8/ios/user-male-circle--v1.png" width="22">
                    <h4 class="p-0 m-0">Usuário</h4>
                  </div>
                  <div class="col-12">
                    <h5 class="font-weight-normal" style="word-break: break-word;">
                      {{ tribute.updated_by.name }}
                    </h5>
                  </div>
                </span>
                <base-button outline slot="reference" size="sm" type="secundary"
                        class="p-0 m-0 ml-2 shadow-none text-indigo">
                    <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="22">
                </base-button>
              </el-popover>
            </div>
            <div class="col-2 text-right">
              <base-dropdown menuOnRight>
                <div slot="title-container" class="dropdown-toggle rounded m-0">
                  <img width="32" src="/img/icons/icons8/ios/settings--v1_primary.png" />
                </div>
                <a class="dropdown-item font-weight-500 new-default-black-font"
                  style="align-items: center; display: flex"
                  @click="handleEditTax(tribute)">
                  <img width="22" src="/img/icons/create-new.png" />
                  Editar
                </a>
                <div class="dropdown-divider p-0 m-0"></div>
                <a class="dropdown-item font-weight-500 new-default-black-font"
                  style="align-items: center; display: flex"
                  @click="handleListTaxRates(tribute, index)">
                  <img width="22" src="/img/icons/icons8/ios/cash-receipt.png" />
                  Alíquotas
                </a>
                <div class="dropdown-divider p-0 m-0"></div>
                <a class="dropdown-item font-weight-500 new-default-black-font"
                  style="align-items: center; display: flex"
                  @click="handleLocateCpfCnpj(tribute)">
                  <img width="22" src="/img/icons/icons8/ios/city-hall.png" />
                  CNPJ
                </a>
                <div class="dropdown-divider p-0 m-0"></div>
                <a class="dropdown-item font-weight-500 new-default-black-font"
                  style="align-items: center; display: flex"
                  @click="handleDeleteTax(tribute.uuid)">
                  <img width="22" src="/img/icons/icons8/ios/delete--v1_danger.png" />
                  Excluir
                </a>
              </base-dropdown>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <h3 class="new-default-black-font m-0">
                <i style="font-style: normal;">{{ tribute.city }}</i>
                <i style="font-style: normal;" class="mx-1" v-if="tribute.state">/</i>
                <i style="font-style: normal;">{{ tribute.state }}</i>
              </h3>
              <h4 class="font-weight-normal new-default-black-font m-0">
                {{ tribute.regime }}
              </h4>
            </div>
          </div>
          <hr class="mt-3 mb-3">
          <div class="row align-items-center">
            <div class="col-9 pr-0">
              <div class="row">
                <div class="col-12 mb-2" style="display: flex; align-items: center;">
                  <h4 class="font-weight-normal new-default-black-font m-0">
                    {{ verifyText(getLastValitity(tribute), "condition") }}
                  </h4>
                  <el-popover v-if="getLastValitity(tribute)" trigger="click" placement="left">
                    <span style="width: 370px;"
                      class="row">
                      <div class="col-12 mb-1" style="display: flex; align-items: center;">
                        <img class="mr-2" src="/img/icons/info.png" width="22">
                        <h4 class="p-0 m-0">Info alíquotas</h4>
                      </div>
                      <div class="col-12">
                        <div class="row align-items-center mt-3">
                          <div class="col-auto pr-0">
                            <h5 class="font-weight-normal text-primary m-0">
                              Condição
                            </h5>
                          </div>
                          <div class="col px-3">
                            <hr class="m-0">
                          </div>
                          <div class="col-auto pl-0 text-right">
                            <h5 class="font-weight-normal text-primary m-0">
                              {{ getLastValitity(tribute).condition }}
                            </h5>
                          </div>
                        </div>
                        <div class="row align-items-center mt-3">
                          <div class="col-auto pr-0">
                            <h5 class="font-weight-normal new-default-black-font m-0">
                              Deduzir custo de MCC
                            </h5>
                          </div>
                          <div class="col px-3">
                            <hr class="m-0">
                          </div>
                          <div class="col-auto pl-0 text-right">
                            <h5 class="font-weight-normal new-default-black-font m-0">
                              {{ getLastValitity(tribute).deduct_mcc_cost ? 'Sim' : 'Não' }}
                            </h5>
                          </div>
                        </div>
                        <div class="row align-items-center mt-3">
                          <div class="col-auto pr-0">
                            <h5 class="font-weight-normal new-default-black-font m-0">
                              Considerar Reuso
                            </h5>
                          </div>
                          <div class="col px-3">
                            <hr class="m-0">
                          </div>
                          <div class="col-auto pl-0 text-right">
                            <h5 class="font-weight-normal new-default-black-font m-0">
                              {{ getLastValitity(tribute).consider_reuse ? 'Sim' : 'Não' }}
                            </h5>
                          </div>
                        </div>
                        <div class="row align-items-center mt-3">
                          <div class="col-auto pr-0">
                            <h5 class="font-weight-normal new-default-black-font m-0">
                              Dedução
                            </h5>
                          </div>
                          <div class="col px-3">
                            <hr class="m-0">
                          </div>
                          <div class="col-auto pl-0 text-right">
                            <h5 class="font-weight-normal new-default-black-font m-0">
                              {{ verifyCondition(getLastValitity(tribute), "deduct") }}
                            </h5>
                          </div>
                        </div>
                        <div class="row align-items-center mt-3">
                          <div class="col-auto pr-0">
                            <h5 class="new-default-black-font m-0">
                              Base cálculo
                            </h5>
                          </div>
                          <div class="col px-3">
                            <hr class="m-0">
                          </div>
                          <div class="col-auto pl-0 text-right mt-2 mb-1">
                            <h5 class="new-default-black-font m-0">
                              {{ verifyCondition(getLastValitity(tribute), "base") }}
                            </h5>
                          </div>
                        </div>
                        <div class="row align-items-center mt-3">
                          <div class="col-auto pr-0">
                            <h5 class="text-success font-weight-normal m-0">
                              Alíquota total
                            </h5>
                          </div>
                          <div class="col px-3">
                            <hr class="m-0">
                          </div>
                          <div class="col-auto pl-0 text-right">
                            <h5 class="font-weight-normal text-success m-0">
                              {{ getLastValitity(tribute).total_rate }}%
                            </h5>
                          </div>
                        </div>
                        <div class="row align-items-center mt-3">
                          <div class="col-auto pr-0">
                            <h5 class="new-default-black-font font-weight-normal">
                              Alíquota retida
                            </h5>
                          </div>
                          <div class="col px-3">
                            <hr class="m-0">
                          </div>
                          <div class="col-auto pl-0 text-right">
                            <h5 class="font-weight-normal new-default-black-font m-0">
                              {{ getLastValitity(tribute).retained_rate }}%
                            </h5>
                          </div>
                        </div>
                      </div>
                    </span>
                    <base-button outline slot="reference" size="sm" type="secundary"
                            class="p-0 m-0 ml-2 shadow-none text-indigo">
                        <img src="/img/icons/info.png" width="20">
                    </base-button>
                  </el-popover>
                  <el-popover v-if="tribute.entity" trigger="click" placement="left">
                    <span style="width: 250px;"
                      class="row">
                      <div class="col-12 mb-1" style="display: flex; align-items: center;">
                        <img class="mr-2" src="/img/icons/icons8/ios/city-hall.png" width="22">
                        <h4 class="p-0 m-0">CNPJ</h4>
                      </div>
                      <div class="col-12">
                        <h5 class="font-weight-normal">
                          {{ tribute.entity.entity_name }}
                        </h5>
                        <h5 class="font-weight-normal" style="word-break: break-word;">
                          {{ tribute.entity.document }}
                        </h5>
                      </div>
                    </span>
                    <base-button outline slot="reference" size="sm" type="secundary"
                            class="p-0 m-0 ml-1 shadow-none text-indigo">
                        <img src="/img/icons/icons8/ios/city-hall.png" width="20">
                    </base-button>
                  </el-popover>
                </div>
              </div>
              <div class="row">
                <div class="col-12 pr-0">
                  <h4 class="font-weight-normal new-default-gray-font m-0">
                    DEDUÇÃO: {{ verifyCondition(getLastValitity(tribute), "deduct") }}
                  </h4>
                  <h4 class="font-weight-normal new-default-gray-font m-0">
                    BASE DE CÁLCULO: {{ verifyCondition(getLastValitity(tribute), "base") }}
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-3 text-right">
              <div class="square-div">
                <div class="mini-card-green text-center" style="height: 100%; display: grid; align-items: center;">
                  <h2 class="m-0">
                    {{ getLastValitity(tribute) ? Number(getLastValitity(tribute).total_rate) + '%' : 'n/a'}}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import { mapGetters } from "vuex";

export default {
  props: {
    tributes: Array,
    loading: Boolean,
    tributeSelected: null,
    newValidity: { type: Function },
    getLastValitity: { type: Function },
    handleDeleteTax: { type: Function },
    handleDetailDeductionRules: { type: Function },
    handleEditTax: { type: Function },
    handleListTaxRates: { type: Function },
    handleLocateCpfCnpj: { type: Function },
    updateTributeWithselectedEntity: { type: Function },
    verifyText: { type: Function },
    verifyCondition: { type: Function },
    tributeSelected: Object,
  },
  name: "ListTributes",
  components: {
    SkeletonPuzlGrid,
  },
  methods: {
  },
};
</script>

<style scoped>
.square-div {
  min-width: 60px;
  min-height: 60px;
  width: 60px;
  height: 60px;
  display: inline-block !important;
}

.mini-card-green h2 {
  color: #149e57;
}
</style>
