<template>
  <div class="row mx-1" style="overflow-y: scroll; max-height: 500px">
    <table class="table table-custom-shaded table-sm mb-9 pb-3">
      <thead>
        <tr class="fixed">
          <th class="text-left new-default-black-font">Status</th>
          <th class="text-left new-default-black-font">Central</th>
          <th class="text-left new-default-black-font">Prazo</th>
          <th class="text-left new-default-black-font">Código</th>
          <th class="text-left new-default-black-font">Tipo</th>
          <th class="text-left new-default-black-font">Icones</th>
          <th class="text-left new-default-black-font">Ação</th>
        </tr>
      </thead>
      <tbody>
        <tr  v-for="(item, index) in requests">
          <!-- Status -->
          <td style="width: 6rem;">
            <div class="align-self-center tabele-status mr-3 mb-1" :class="getStatusClass(item.status)">
                <img ref="icon" height="17" :src="getStatusIcon(item.status)"/>
                {{ getStatusText(item.status) }}
              </div>
          </td>
          <td style="width: 15%;">{{ item.input_company_plant_name }}</td>
          <!-- Data -->
          <td style="width: 20%;">
            {{ item.deadline | parseDate("DD MMM YYYY") }}
          </td>
          <!-- Codigo do contrato -->
          <td style="width: 15%;">{{ item.code }}</td>
          <!-- Tipo -->
          <td>{{ item.cost_center_name }}</td>
          <!-- Icones -->
          <td>
            <div class="d-flex pointer">
              <div v-if="item.has_attachments" class="mr-2" @click.prevent="handleShowAttachments(item.id)">
                <img src="/img/icons/paperclip-green.png" width="22">
              </div>
              <div class="mr-2" @click="handleShowModal({ ref: 'modalListItemsApproval', data: item })">
                <img src="/img/icons/icons8/ios/menu-squared-2-dark.png" width="22" />
              </div>
              <div class="mr-2">
                <el-popover trigger="click" placement="bottom" class="p-0">
                  <span class="font-weight-500">Solicitante:</span>
                  <div style="font-size: 11px">{{ item.created_by_user_name }}</div>
                  <div style="font-size: 11px">{{ item.created_by_user_email }}</div>
                  <span slot="reference">
                    <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="22">
                  </span>
                </el-popover>
              </div>
              <div class="mr-2">
                <el-popover trigger="click" placement="bottom" class="p-0">
                  <div class="p-0" style="max-width: 14rem;">
                    <span style="font-size: 14px; font-weight: 500;">Observações</span>
                    <div class="my-1" style="height: 1px; width: 100%; background-color: #E8E8E8"/>
                    <div class="d-block mt-2">
                      <div style="font-size: 12px;" class="font-weight-500">Para a requisição de compras</div>
                      <div style="font-size: 12px;" class="mt-1">{{ item.note }}</div>
                    </div>
                    <div class="my-1" style="height: 1px; width: 100%; background-color: #E8E8E8"/>
                    <div class="d-block mt-2">
                      <div style="font-size: 12px;" class="font-weight-500">Necessidade da compra</div>
                      <div style="font-size: 12px;" class="mt-1">{{ item.purchase_justification }}</div>
                    </div>
                  </div>
                  <span slot="reference">
                    <img src="/img/icons/speech-bubble-with-dots--v1-black.png" height="22"/>
                  </span>
                </el-popover>
              </div>
            </div>
          </td>
          <!-- Menu engrenagem -->
          <td>
            <base-dropdown menuOnRight class="d-flex justify-content-center" style="height: fit-content">
              <div slot="title-container" class="dropdown-toggle rounded">
                <img width="30" src="/img/icons/icons8/ios/settings--v1_primary.png">
              </div>
              <div  v-if="item.status != purchaseRequestEnum.CANCELED && item.status != purchaseRequestEnum.APPROVED">
                <a @click="redirectToEdit(item.id)" class="dropdown-item d-flex" style="font-size: 12px;">
                  <img width="18" src="https://img.icons8.com/ios/100/f2b532/create-new.png" alt="create-new"/>
                  Editar
                </a>
                <div style="border-bottom: 1px solid hsl(0, 0%, 0%, 10%);"></div>
              </div>
              <a @click="handleShowModal({ ref: 'modalHistory', data: item })" class="dropdown-item d-flex"
                  style="font-size: 12px;">
                  <img width="18" src="/img/icons/icons8/ios/order-history_secondary.png">
                  Histórico
              </a>
              <div @click="removePurchaseRequest(item)"  v-if="item.status != purchaseRequestEnum.CANCELED">
                <div style="border-bottom: 1px solid hsl(0, 0%, 0%, 10%);"></div>
                <a class="dropdown-item d-flex"
                  style="font-size: 12px;">
                  <img width="18" src="/img/icons/icons8/ios/delete_danger.png">
                  Cancelar
                </a>
              </div>
            </base-dropdown>
          </td>
        </tr>
      </tbody>
    </table>
    <ModalListItemsApproval @addContact="listContacts" ref="modalListItemsApproval"/>
    <ModalAttachment @addContact="listContacts" ref="modalAttachment"/>
    <ModalPurchaseRequestHistory ref="modalHistory"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {PurchaseRequestEnum} from "@/enum/PurchaseRequestEnum";
import ModalListItemsApproval from "./Shared/_ModalListItemsApproval.vue";
import ModalAttachment from "@/views/Modules/Financial/BillReceive/Shared/Modals/_ModalAttachment";
import ModalPurchaseRequestHistory from "./Shared/_ModalPurchaseRequestHistory.vue";

export default {
  name: "PurchaseRequestTable",
  props: {
    requests: Array,
  },
  components: {
    ModalListItemsApproval,
    ModalAttachment,
    ModalPurchaseRequestHistory,
  },
  computed: {
    ...mapGetters({
      $_user: "auth/getUser",
    }),
  },
  data() {
    return {
      purchaseRequestEnum: PurchaseRequestEnum,
    }
  },
  methods: {
    redirectToEdit(id) {
      this.$router.push('/purchase/request/edit/' + id)
    },
    handleShowModal({ ref, data }) {
      this.$refs[ref].handleCreateModal(data);
    },
    removePurchaseRequest(purchaseRequest) {
      this.$swal
          .fire({
            title: "Você tem certeza?",
            text: "Tem certeza que deseja CANCELAR essa requisição?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Continuar!",
            cancelButtonText: "Cancelar",
            customClass: {
              confirmButton: "btn btn-success btn-fill",
              cancelButton: "btn btn-danger btn-fill",
            },
            buttonsStyling: false,
          })
          .then(result => {
            if (result.isConfirmed) {
              purchaseRequest.status = this.purchaseRequestEnum.CANCELED;
              purchaseRequest.updated_by_user_id = this.$_user.id;
              this.$store.dispatch('purchaseRequest/update', purchaseRequest)
              .then(() => {
                this.$notify({type: 'success', message: 'Requisição cancelada!'});
                this.$emit('fetch');
              });
            } else {
              this.modal.create = false;
            }
          });
    },
    getStatusText(status){
      switch(status){
        case this.purchaseRequestEnum.PENDING:  return "Pendente";
        case this.purchaseRequestEnum.APPROVED: return "Aprovado";
        case this.purchaseRequestEnum.DENIED: return "Negado";
        default: return "Cancelado";
      }
    },
    getStatusClass(status){
      switch(status){
        case this.purchaseRequestEnum.PENDING:  return "pending-status";
        case this.purchaseRequestEnum.APPROVED: return "approved-status";
        case this.purchaseRequestEnum.DENIED: return "denied-status";
        default: return "denied-status";
      }
    },
    getStatusIcon(status){
      switch(status){
        case this.purchaseRequestEnum.PENDING:  return "/img/icons/icons8/ios/hourglass_warning.png";
        case this.purchaseRequestEnum.APPROVED: return "/img/icons/icons8/ios/thumb-up_primary.png";
        case this.purchaseRequestEnum.DENIED: return "/img/icons/icons8/ios/thumbs-down_danger.png";
        default: return "/img/icons/icons8/ios/cancel_danger.png";
      }
    },
  },
};

</script>
<style scoped>
.table-custom-shaded thead th {
  background-color: #CCCCCC !important;
  font-size: 14px !important;
}

.table-custom-shaded tr td {
  padding-left: 1rem !important;
}
.table-custom-shaded td, .table-custom-shaded th {
  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15) !important;
  border-radius: 5px !important;
  border: 0 !important;
}
.table-custom-shaded td:last-child, .table-custom-shaded th:last-child, .table-custom-shaded th:first-child {
  text-align: center !important;
  padding-left: 0.5rem !important;
}
.table-custom-shaded th {
  padding-left: 1.2rem !important;
}
.table-custom-shaded td {
  font-weight: 700 !important;
  padding-top: 0.85rem;
}
.table-custom-shaded td:last-child {
  padding-top: 0.8rem !important;
}
.table-custom-shaded td:first-child {
  font-weight: 500 !important;
  padding-top: 0.5rem !important;
}
.tabele-status {
  border-radius: 12px;
  font-size: 0.8rem;
  text-align: center;
  font-weight: 500 !important;
  padding: 0.3rem 0.8rem;
  margin-top: 0.1rem;
}
.pending-status {
  background-color: #fdf2db;
  color: #F2B532;
}
.approved-status {
  background-color: #d2e3f1;
  color: #1A70B7;
}
.denied-status {
  background-color: #f8dad7;
  color: #DB4539;
}
</style>
