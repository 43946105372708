const productServiceMixin = {
  data() {
    return {
      loadingProductService: false,
    };
  },
  methods: {
    getProductServiceByCategory(product_service_category_id) {
      this.loadingProductService = true;
      return this.$store
        .dispatch("productService/getByType", { type: 3, product_service_category_id : product_service_category_id})
        .then(() => this.loadingProductService = false);
    },
  },
};
export default productServiceMixin;
