<template>
  <div class="d-flex flex-column flex-md-row mb-3" style="gap: 10px;">
    <div class="widget-card w-100 authorized-section">
      <div class="mx-3 my-4">
        <section class="mb-2 top-section">
          <img src="/img/icons/icons8/ios/checked_success.png" alt="checked_success" width="24px">
        </section>
        <section class="mid-section">
          Notas Autorizadas
        </section>
        <section class="bottom-section d-flex">
          <span>
            {{ widgets.authorizeds || 0 }}
          </span>
        </section>
      </div>
    </div>
    <div class="widget-card w-100 sequence-break-section pointer" @click.prevent="showBrokenSequence()">
      <div class="mx-2 my-4">
        <section class="mb-2 top-section">
          <img src="/img/icons/icons8/ios/numeric_warning.png" alt="numeric_warning" width="24px">
        </section>
        <section class="mid-section">
          Quebra de Sequência
        </section>
        <section class="bottom-section d-flex">
          <span>
            {{ widgets.broken_sequence || 0 }}
          </span>
        </section>
      </div>
    </div>
    <div class="widget-card w-100 useless-numbers-section">
      <div class="mx-2 my-4">
        <section class="mb-2 top-section">
          <img src="/img/icons/icons8/ios/shutdown--v1_grey.png" alt="shutdown--v1_grey" width="24px">
        </section>
        <section class="mid-section">
          Números Inutilizados
        </section>
        <section class="bottom-section d-flex">
          <span>
            {{ widgets.unusables || 0 }} m<sup>3</sup>
          </span>
        </section>
      </div>
    </div>
    <div class="widget-card w-100 cancelled-notes-section">
      <div class="mx-2 my-4">
        <section class="mb-2 top-section">
          <img src="/img/icons/icons8/ios/unavailable_danger.png" alt="unavailable_danger" width="24px">
        </section>
        <section class="mid-section">
          Notas Canceladas
        </section>
        <section class="bottom-section d-flex">
          <span>
            {{ widgets.canceleds || 0 }}
          </span>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Widgets',
  props: {
    widgets: {
      type: Object,
      required: false
    }
  },
  methods: {
    showBrokenSequence() {
      this.$emit('showBrokenSequence');
    }
  }
};
</script>

<style scoped>
.widget-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 5px 10px 0px #0000000D;
}
.mid-section {
  font-size: 14px;
  font-weight: 400;
  color: #606062;
}
.bottom-section span{
  font-size: 18px;
  font-weight: 500;
  color: #2B2D32;
}
.authorized-section {
  background-color: #F2F7F3;
}
.sequence-break-section {
  background-color: #FEF9F2;
}
.useless-numbers-section {
  background-color: #E8E8E8;
}
.cancelled-notes-section {
  background-color: #FCF3F3;
}
</style>