<template>
  <div>
    <modal size="md" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
        <form v-if="item" class="needs-validation" @submit.prevent="handleSubmit(update)" autocomplete="off">
          <div class="form-group row m-0 p-0">
            <label class="col-md-12 pb-0 mb-1 col-form-label form-control-label">
              Justificativa
            </label>
            <div class="col-md-12 mb-1 pr-4">
              <validation-provider rules="required" v-slot="{ errors }">
                <base-input input-group-classes="input-group-sm">
                  <textarea 
                    type="text"
                    rows="4"
                    class="form-control form-control-sm"                    
                    v-model="item.automatic_closing_note"
                  >
                  </textarea>
                </base-input>
              </validation-provider>
            </div>
          </div>
          <div class="modal-footer">
            <base-button type="danger" @click="closeModal()">
              <img src="/img/icons/cancel-white.png" width="22px" height="22px"/>
              Cancelar
            </base-button>
            <base-button
              type="success"
              native-type="submit"
              :disabled="invalid"              
              :loading="loadingStore"
            >
              <img src="/img/icons/save.png" width="22px" height="22px" />
              Salvar
            </base-button>
          </div>
        </form>
      </validation-observer>
    </modal>
  </div>
</template>

<script>

export default {
  name: "ModalAutomaticClosingNote",
  components: {
  },
  data() {
    return {
      modal: {
        title: "Baixa Automática",
        create: false,
      },
      item: null,      
      loadingStore: false,
    };
  },
  methods: {
    openModal(item) {      
      this.item = item;
      this.item.automatic_closing_note = null;
      this.modal.create = true;
    },
    closeModal() {
      this.modal.create = false;
    },
    update() {
      this.$Progress.start();
      this.loadingStore = true;
      const payload = {
        id: this.item.id,
        automatic_closing_set: true,
        automatic_closing_note: this.item.automatic_closing_note
      };
      this.$store
        .dispatch('bankReconciliationTransaction/putAutoClosing', payload)
        .then((response) => {
          this.loadingStore = false;
          this.item.automatic_closing_set = 1;
          this.$notify({ type: response.error_type, message: response.message });
        })
        .catch((error) => {
          if (error.status === 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
        })
        .finally(() => {
          this.$Progress.finish();          
          this.closeModal();
        });
    },
  },
};
</script>

<style scoped>
</style>
