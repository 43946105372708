<template>
  <div style="min-height: 800px;">
    <AppFixedPageTitle title="Cotação" icon="/img/icons/cart.png" />
    <AppPageHeader class="mb-3">
      <template slot="search-bar">
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          :showCompanyPlants="true"
          :isLoading="isLoading"
          @onSearchClick="listItems"
          @onClearClick="clearFilter">
        </AppSearchBar>
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions>
          <AppPageHeaderActionsButton
            @click.prevent="$router.push('/purchase/request/create')"
            text="novo"
            type="success"
            icon="/img/icons/plus-math--v1-white.png">
          </AppPageHeaderActionsButton>
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>
    <AppTabSelect :items="tabSelectItems" @onTabSelectItemClick="onTabSelectItemClick" :isShowViewTypes="false" />
    <div class="container-fluid">
      <!-- CARDS -->
      <Cards
        class="mt-4"
        v-if="listType === 'cards' && !isLoading" :requests="$_purchaseBudgets"
        @fetch="listItems"
      />
      <AppViewTrigger v-if="!isLoading" @onIntersected="listItems(true)"/>
        <!-- TABELA -->
        <Table
        v-if="listType === 'table' && $_purchaseBudgets.length > 0 && !isLoading" 
        :requests="$_purchaseBudgets" 
        @fetch="listItems"
      />
      
      <!-- <LoadingPagination :show="loadingPagination"/> -->
      <div class="d-flex"v-if="isLoading">
        <SkeletonPuzlGrid class="p-2"  v-for="index in 3" />
      </div>
      <PuzlEmptyData v-if="!isLoading &&  $_purchaseBudgets.length <= 0" />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Table from './Shared/_Table';
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import Cards from './Shared/_Cards'
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import PuzlSelect from "@/components/PuzlSelect";
import InputDatePicker from "@/components/InputDatePicker";
import {PurchaseBudgetStatusEnum} from "@/enum/PurchaseBudgetStatusEnum";
import { 
  AppSearchBar, 
  initSearchBarFilterType, 
  SearchBarFilterType, 
  AppViewTrigger, 
  AppFixedPageTitle,
  AppPageHeader,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  AppTabSelect,

} from "../../../../../components/AppGlobal";
import { date } from "../../../../../helpers";
import { PurchaseBudgetListFilterType, initPurchaseBudgetListFilterType } from "./types";

export default {
  mixins: [cursorPaginate],
  components: {
    LoadingPagination,
    SkeletonPuzlGrid,
    cursorPaginate,
    PuzlEmptyData,
    Cards,
    Table,
    BaseButtonHoverable,
    PuzlSelect,
    InputDatePicker,
    AppSearchBar,
    AppViewTrigger,
    AppFixedPageTitle,
    AppPageHeader,
    AppPageHeaderActions,
    AppPageHeaderActionsButton,
    AppTabSelect,
  },
  computed: {
    ...mapGetters({
        $_purchaseBudgets: "purchaseBudget/fetch",
    }),
    tabSelectItems() {
      return [
        {
          id: null, 
          name: 'Todos', 
          selected: this.filter.in_status.length === 0,
        },
        {
          id: PurchaseBudgetStatusEnum.PENDING, 
          name: 'Pendentes', 
          selected: this.filter.in_status.includes(PurchaseBudgetStatusEnum.PENDING),
        },
        {
          id: PurchaseBudgetStatusEnum.COMPLETED, 
          name: 'Concluídos', 
          selected: this.filter.in_status.includes(PurchaseBudgetStatusEnum.COMPLETED),
        },
      ];
    },
  },
  data() {
    return {
      isLoading: true,
      listType: 'cards',
      filter: initPurchaseBudgetListFilterType(),
      searchBarFilter: initSearchBarFilterType(),
      currentDate: date.make().format(date.FORMAT.ISO_8601),
      PurchaseBudgetStatusEnum: PurchaseBudgetStatusEnum,
    }
  },
  methods: {
    /**
     * Listar itens
     * @param {boolean} isAccumulateItems
     */
     listItems(isAccumulateItems = false) {
      this.isLoading = true;
      if (!this.startCursor(this.filter, isAccumulateItems)) {
        this.isLoading = false;
        return;
      }
      this.prepareFilter();
      this.$store
        .dispatch("purchaseBudget/fetchItemsPaginate", this.filter)
        .then((res) => this.resolveCursor(res, this.filter))
        .finally(() => this.isLoading = false);
    },
    /**
     * Preparar filtro antes da listagem
     */
    prepareFilter() {
      this.filter.input_company_plant_id = this.searchBarFilter.company_plant_selected;
      this.filter.custom_search.values = this.searchBarFilter.custom_search_values;
      this.filter.deadline_range = this.searchBarFilter.range;
    },
    /**
     * Limpar os filtros e listar os itens caso especificado
     * @param {boolean} isRefreshList - Indica se deve listar os itens
     */
    clearFilter(isRefreshList = true) {
      Object.assign(this.searchBarFilter, this.defaultSearchBarFilter());
      Object.assign(this.filter, this.defaultFilter());
      if (isRefreshList) {
        this.listItems();
      }
    },
    /**
     * Padrão de filtro da barra de pesquisa
     * @returns {SearchBarFilterType}
     */
    defaultSearchBarFilter() {
      return {
        ...initSearchBarFilterType(),
        range: {
          start: this.currentDate,
          end: date.make(this.currentDate)
            .add(1, date.DURATION_INPUT.YEARS)
            .format(date.FORMAT.ISO_8601),
        },
      };
    },
    /**
     * Padrão do filtro principal
     * @returns {PurchaseBudgetListFilterType}
     */
    defaultFilter() {
      return {
        ...initPurchaseBudgetListFilterType(),
        deadline_range: {
          start: this.currentDate,
          end: date.make(this.currentDate)
            .add(1, date.DURATION_INPUT.YEARS)
            .format(date.FORMAT.ISO_8601),
        },
      };
    },
    onTabSelectItemClick(item) {
      const isAlreadyFiltered = this.filter.in_status.length === 1 && this.filter.in_status.includes(item.id);
      if (isAlreadyFiltered) {
        return;
      };
      this.filter.in_status = item.id === null ? [] : [item.id];
      this.listItems();
    },
  },
  mounted() {
    this.clearFilter();
  },
};
</script>
<style scoped>
.status-button {
  border-radius: 8px;
  height: 30px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  min-width: 10rem;
  margin-right: 1rem;
  background-color: #ffff;
  font-weight: 500;
}
.header-button .base-button {
  width: 200px !important;
  height: 6px;
}
#customButton button {
  color: #4b4b50;border: 1px solid #DCDFE6;
  background-color: white;
}
#customButton .btn-active {
  color: white!important;
}
.green-button {
  border-radius: 10px;
  border: 1px solid #149E57;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  color: #149E57;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
