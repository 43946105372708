<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div v-if="payload.product_name" class="border-left border-3 border-success mt-0 mb-3">
        <h4 class="mb-0 ml-2">{{ payload.product_name }}</h4>
      </div>
      <div v-if="payload.entity_id" class="border-left border-3 border-danger pr-1 mt-2 mb-3">
        <a href="#" @click.prevent="handleModalSearchEntity(initial_payload)">
          <div class="ml-2">
            <h4 class="m-0">{{ entity.entity_name }}</h4>
            <h4 class="m-0 font-weight-normal">{{ entity.document }}</h4>
          </div>
        </a>
      </div>
      <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
        <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
          <div class="card-header border-header-success-bottom p-2 grid-col--fixed-left mb-2">
            <h5 class="mb-0" slot="title">Identificação</h5>
          </div>
          <div class="p-2">
            <!-- Data -->
            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pl-md-2 pt-2 pb-0 col-form-label form-control-label">
                Data
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-1">
                <span 
                  class="badge ml-n3 text-dark" 
                  style="background-color: #ffff; border: 1px solid #ccc; padding: 5px; border-radius: 5px; cursor: pointer;"
                  @click.prevent="setCurrentTime()"
                >
                  Agora
                </span>
              </div>
              <div class="col-md-6">
                <validation-provider rules="required">  
                  <base-input input-group-classes="input-group-sm">
                    <InputDateTimePicker
                      @input="getFuelDataByDateAndEquipment"
                      :disableMaxDate="new Date()"
                      mode="dateTime"
                      ref="datePicker"
                      v-model="payload.date" />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!-- Central -->
            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pl-md-2 pt-2 pb-0 col-form-label form-control-label">
                Central
                <span class="text-danger mr-2">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                    <PuzlSelect
                      @input="changedCompanyPlant"
                      v-model="payload.company_plant_id"
                      :items="$_company_plants" />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!-- Motorista -->
            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pl-md-2 pt-2 pb-0 col-form-label form-control-label">
                Motorista
                <span class="text-danger mr-2">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                    <PuzlSelect
                      v-if="type !== 'internal_others'"
                      :disabled="loadingDriver"
                      v-model="payload.driver_id"
                      :items="$_drivers"
                    />
                    <input 
                      v-else
                      v-model="payload.driver_observations"
                      type="text"
                      class="form-control form-control-sm" maxlength="80" />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!-- Equipamento | Placa -->
            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pl-md-2 pt-2 pb-0 col-form-label form-control-label">
                {{ type !== "internal_others" ? "Equipamento" : "Placa" }}
                <span class="text-danger mr-2">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                    <PuzlSelect
                      @input="getFuelDataByDateAndEquipment"
                      v-if="type !== 'internal_others'"
                      :disabled="loadingEquipment"
                      v-model="payload.equipment_id"
                      :items="$_equipments"
                      label="$code$ - $plate$"
                      :labelMask="true"
                    />
                    <input v-else v-model="payload.plate" type="text" class="form-control form-control-sm" maxlength="7" />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!-- Km Painel -->
            <div v-if="type !== 'internal_others'" class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pl-md-2 pt-2 pb-0 col-form-label form-control-label">
                Km Painel
                <span v-show="!payload.hour_meter" class="text-danger mr-4">&nbsp;*</span>
                <el-popover v-show="previousKmPanel" trigger="hover" placement="bottom" class="p-0 float-right pr-0 ml-3">
                  <span> {{ previousKmPanel }} Km </span>
                  <base-button outiline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none">
                   <i style="font-size: 17px !important" class="fas fa-angles-left p-0 text-primary" />
                  </base-button>
                </el-popover>
              </label>
              <div class="col-md-7">
                <validation-provider :rules="{required : !payload.hour_meter}">
                  <InputLimit
                    @change="getTravelledDistance"
                    :options="{
                      min: 0,
                    }"
                    max_length="9"
                    :start_value="payload.km_panel"
                    v-model="payload.km_panel"
                    input_group_classes="input-group-sm pb-0"
                    @value="payload.km_panel = $event === '-' ? null : $event"
                  >
                    <small class="input-group-sm p-0 m-0"> Km </small>
                  </InputLimit>
                </validation-provider>
              </div>
            </div>
            <!-- Horímetro -->
            <div v-if="type !== 'internal_others'" class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pl-md-2 pt-2 pb-0 col-form-label form-control-label">
                Horímetro
                <span v-show="!payload.km_panel" class="text-danger mr-4">&nbsp;*</span>
                <el-popover v-show="previousHourMeter" trigger="hover" placement="bottom" class="p-0 float-right pr-0 ml-3">
                  <span> {{ previousHourMeter }} Hora(s) </span>
                  <base-button outiline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none">
                   <i style="font-size: 17px !important" class="fas fa-angles-left p-0 text-primary" />
                  </base-button>
                </el-popover>
              </label>
              <div class="col-md-7">
                <validation-provider :rules="{required : !payload.km_panel}">
                  <InputLimit
                    @change="getTravelledHour"
                    :options="{
                      min: 0,
                      decimals: 1
                    }"
                    max_length="12"
                    :start_value="payload.hour_meter"
                    v-model="payload.hour_meter"
                    @value="payload.hour_meter = $event === '-' ? null : $event"
                  >
                    <small class="input-group-sm p-0 m-0"> Horas </small>
                  </InputLimit>
                </validation-provider>
              </div>
            </div>
            <div class="card-header border-header-indigo-bottom p-2 grid-col--fixed-left mb-2 mt-2">
              <h5 class="mb-0" slot="title">Abastecimento</h5>
            </div>
            <!-- Combustível -->
            <div v-if="type === 'external'" class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pl-md-2 pt-2 pb-0 col-form-label form-control-label">
                Combustível
                <span class="text-danger mr-2">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                    <PuzlSelect v-model="payload.product_service_hub_id" :items="$_products_services" />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!-- Reservatório | Preço  -->
            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pl-md-2 pt-2 pb-0 col-form-label form-control-label">
                {{ type !== "external" ? "Reservatório" : "Preço / Litro" }}
                <span class="text-danger mr-2">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required">
                  <base-input v-if="type !== 'external'" input-group-classes="input-group-sm">
                    <PuzlSelect
                      @input="fillStartLiter"
                      :disabled="loadingFuelTank"
                      v-model="payload.fuel_tank_id"
                      :items="$_fuel_tanks" />
                  </base-input>
                  <div v-else>
                    <base-input input-group-classes="input-group-sm">
                      <input
                        @blur="getTotal"
                        v-money="{decimal: ',', thousands: '.', precision: 3}"
                        maxlength="10"
                        inputmode="numeric"
                        v-model.lazy="payload.price_per_liter"
                        @keydown="$event.key === '-' ? $event.preventDefault() : null"
                        class="form-control form-control-sm"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                </validation-provider>
              </div>
            </div>
             <!-- Fornecedor -->
            <div v-if="type !== 'external'" class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pl-md-2 pt-2 pb-0 col-form-label form-control-label">
                Fornecedor
                <span class="text-danger mr-2">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                    <PuzlSelect
                      :disabled="!payload.fuel_tank_id"
                      v-model="payload.entry_entity_id"
                      :items="entities"
                      label="entity_name" />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!-- Leitor Inicial | Total Litros -->
            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pl-md-2 pt-2 pb-0 col-form-label form-control-label">
                {{ type !== "external" ? "Leitor Inicial" : "Total (Litros)" }}
                <span class="text-danger mr-2">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required|min_value:0">
                  <InputLimit
                    v-if="type !== 'external'"
                    :options="{
                      decimals: 2
                    }"
                    @change="getTotal"
                    :disabled="hasDefaultFuelTankValue || !payload.fuel_tank_id"
                    input_group_classes="input-group-sm pb-0"
                    max_length="9"
                    :start_value="payload.start_liter"
                    v-model="payload.start_liter"
                    @value="payload.start_liter = $event === '-' ? null : $event"
                  >
                    <small class="input-group-sm p-0 m-0"> Litros </small>
                  </InputLimit>
                  <InputLimit
                    v-else
                    :options="{
                      min: 0,
                      decimals: 3
                    }"
                    @change="getTotal"
                    input_group_classes="input-group-sm pb-0"
                    max_length="9"
                    :disabled="!payload.fuel_tank_id && type !== 'external'"
                    :start_value="payload.total_liter"
                    v-model="payload.total_liter"
                    @value="payload.total_liter = $event === '-' ? null : $event"
                  >
                    <small class="input-group-sm p-0 m-0"> Litros </small>
                  </InputLimit>
                </validation-provider>
              </div>
            </div>
            <!-- Leitor Final -->
            <div v-if="type !== 'external'" class="form-group row m-0 p-0 mb-0">
              <label class="col-md-5 pl-md-2 pt-2 pb-0 col-form-label form-control-label">
                Leitor Final
                <span class="text-danger mr-2">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required|min_value:1">
                  <base-input input-group-classes="input-group-sm">
                    <InputLimit
                      :options="{
                        decimals: 2
                      }"
                      max_length="9"
                      @change="getTotal"
                      :start_value="payload.end_liter"
                      input_group_classes="input-group-sm pb-1"
                      v-model="payload.end_liter"
                      @value="payload.end_liter = $event === '-' ? null : $event"
                    >
                      <small class="input-group-sm p-0 m-0"> Litros </small>
                    </InputLimit>
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!-- Total -->
            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pl-md-2 pt-2 pb-0 col-form-label form-control-label">
                Total
                <span class="text-danger mr-2">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider v-if="type !== 'external'" rules="required|min_value:0|max_value:999.9">
                  <base-input input-group-classes="input-group-sm">
                    <input @change="getFinalValue" v-model="payload.total_liter" type="text" class="form-control form-control-sm" />
                  </base-input>
                </validation-provider>
                <div v-else>
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-money="money"
                      maxlength="10"
                      inputmode="numeric"
                      disabled
                      v-model.lazy="payload.total_value"
                      class="form-control form-control-sm"
                    />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0"> R$ </small>
                    </template>
                  </base-input>
                </div>
              </div>
            </div>
            <!-- Observações -->
            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pl-md-2 pt-2 pb-0 col-form-label form-control-label"> Observações </label>
              <div class="col-md-7">
                <base-input input-group-classes="input-group-sm">
                  <input v-model="payload.observations" type="text" class="form-control form-control-sm" maxlength="200" />
                </base-input>
              </div>
            </div>
            <div v-if="type === 'external'">
              <div class="card-header border-header-danger-bottom p-2 grid-col--fixed-left mb-2">
                <h5 class="mb-0" slot="title">Documento</h5>
              </div>
              <!-- Número do Documento  -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pl-md-2 pt-2 pb-0 col-form-label form-control-label">
                  Núm. do Documento
                  <span class="text-danger mr-2">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required">
                    <InputLimit
                      :options="{
                        min: 0,
                      }"
                      max_length="15"
                      input_group_classes="input-group-sm pb-0"
                      :start_value="payload.document_number"
                      v-model="payload.document_number"
                      @value="payload.document_number = $event === '-' ? null : $event"
                    />
                  </validation-provider>
                </div>
              </div>
              <!-- Série -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pl-md-2 pt-2 pb-0 col-form-label form-control-label">
                  Série
                  <span class="text-danger mr-2">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required">
                    <input v-model="payload.series" type="text" class="form-control form-control-sm" maxlength="30" />
                  </validation-provider>
                </div>
              </div>
              <!-- Imagem -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pl-md-2 pt-2 pb-0 col-form-label form-control-label">
                  Imagem
                </label>
                <div class="col-md-8">
                  <InputFile :multiple="false" ref="fuelControlAttachment" />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')"> Cancelar </base-button>
              <base-button
                type="success"
                native-type="submit"
                :disabled="invalid || (payload.status === 2 && payload.price_per_liter === '0,00') || payload.total_value === '0,00'"
                :loading="loadingStore"
              >
                Salvar
              </base-button>
            </div>
          </div>
        </form>
      </validation-observer>
    </modal>
    <ModalSearchEntity @selected="openModal" ref="modalSearchEntity" />
  </div>
</template>

<script>
import { formatErrorValidation } from "@/plugins";
import PuzlSelect from "@/components/PuzlSelect";
import { mapGetters } from "vuex";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import InputLimit from "@/components/Utils/InputLimit";
import { VMoney } from "v-money";
import moment from "moment";
import fuelControlMixin from "../Mixins/fuel_control_mixin";
import ModalSearchEntity from "../../../Configuration/Entity/Shared/_ModalSearchEntity.vue";
import InputFile from "@/components/Utils/InputFile";

export default {
  name: "ModalCreate",
  mixins: [fuelControlMixin],
  components: { PuzlSelect, InputDateTimePicker, InputLimit, ModalSearchEntity, InputFile },
  data() {
    return {
      modal: {
        title: "Controle de Abastecimento",
        create: false,
      },
      type: null,
      payload: {
        status: null,
        external: null,
        own_fleet: null,
        date: null,
        company_plant_id: null,
        driver_id: null,
        equipment_id: null,
        km_panel: null,
        fuel_tank_id: null,
        start_liter: null,
        end_liter: null,
        total_liter: null,
        total_value: null,
        price_per_liter: null,
        hour_meter: null,
        travelled_distance: null,
        product_service_hub_id: null,
        volume: null,
        product_name:null,
        observations: null,
        driver_observations: null,
        plate: null,
        entity_id: null,
        document_number: null,
        serie: null,
        photo: null,
      },
      entity: {
        entity_name: null,
        document: null,
      },
      entities:[],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      initial_payload: null,
    };
  },
  directives: { money: VMoney },
  computed: {
    ...mapGetters({
      $_company_plants: "plant/activeItems",
      $_drivers: "user/getDrivers",
      $_equipments: "equipment/fetch",
      $_fuel_tanks: "fuelTank/fetch",
      $_products_services: "productService/fetch",
      $_user: "auth/getUser",
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    /**
     * @param {object} initial_payload
     * @param {boolean} entity_payload
     */
    openModal(initial_payload, entity_payload = false) {
      this.loadingDriver = true;
      this.loadingEquipment = true;
      this.loadingFuelTank = true;
      this.entities = [];
      this.previousKmPanel = null;
      this.previousHourMeter = null;
      this.hasDefaultFuelTankValue = false;
      this.payload = {
        status: null,
        external: null,
        own_fleet: null,
        date: null,
        company_plant_id: null,
        driver_id: null,
        equipment_id: null,
        km_panel: null,
        fuel_tank_id: null,
        start_liter: null,
        end_liter: null,
        total_liter: null,
        total_value: "0",
        price_per_liter: null,
        hour_meter: null,
        travelled_distance: null,
        product_service_hub_id: null,
        product_name:null,
        volume: null,
        observations: null,
        driver_observation: null,
        plate: null,
        entity_id: null,
        document_number: null,
        serie: null,
        photo: null,
      };

      /* Se vier pelo modal de entidade */
      if (entity_payload !== false) {
        this.initial_payload = initial_payload;
        this.entity = initial_payload;
        if(entity_payload.external === 1 && initial_payload.entity_id) {
          this.payload.entity_id
        } else {
          this.payload.entity_id = initial_payload.id;
        }
        this.payload.external = entity_payload.external;
        this.payload.own_fleet = entity_payload.own_fleet;
      } else {
        /* Se vier pelo modal simples */
        this.payload.external = initial_payload.external;
        this.payload.own_fleet = initial_payload.own_fleet;
        if(initial_payload.external === 1) initial_payload.is_fuel = true; 
      }

      switch (true) {
        case this.payload.external === 0 && this.payload.own_fleet === 1:
          /* tipo interno e próprio */
          this.type = "internal_own";
          this.payload.status = 0;
          break;
        case this.payload.external === 0 && this.payload.own_fleet === 0:
          /* tipo interno e terceiros */
          this.type = "internal_others";
          this.payload.status = 1;
          if (!this.payload.entity_id) {
            return this.handleModalSearchEntity(initial_payload);
          }
          break;
        default:
          /* tipo externo */
          this.type = "external";
          this.payload.status = 2;
          if (!this.payload.entity_id) {
            return this.handleModalSearchEntity(initial_payload);
          }
          this.getProductServiceHub();
      }

      /* Se tiver somente uma central já virá preenchida. */
      if (this.$_company_plants.length === 1) {
        this.payload.company_plant_id = this.$_company_plants[0].id;
        this.changedCompanyPlant();
      }

      this.modal.create = true;
    },
    store() {
      this.loadingStore = true;
      this.$store
        .dispatch("fuelControl/add", this.finalFormat(), {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.loadingStore = false;
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.closeModal();
        })
        .catch((error) => {
          if (error.status === 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
          this.loadingStore = false;
        });
    },
    /**
     * @param {object} initial_payload
     */
    handleModalSearchEntity(initial_payload) {
      this.$refs.modalSearchEntity.openModal(initial_payload);
    },
    /** Se tiver somente um reservatório, já virá preenchido. */
    selectedPlant() {
      this.payload.fuel_tank_id = null;
      if (this.$_fuel_tanks.length === 1) {
        this.payload.fuel_tank_id = this.$_fuel_tanks[0].id;
      }
    },
    setCurrentTime() {
      this.payload.date = moment().format("YYYY-MM-DD HH:mm:ss");
    },
  },
};
</script>
