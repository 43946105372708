<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
            <div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 col-form-label form-control-label">
                  Status
                </label>
                <div class="col-md-7 pt-2">
                  <base-switch v-model="payload.status" type="success" offText="inativo" onText="ativo" class="success"></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-5 col-form-label form-control-label">
                  Padrão
                </label>
                <div class="col-md-7 pt-2">
                  <base-switch v-model="payload.default" type="primary" offText="inativo" onText="ativo" class="success"></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Descrição curta
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7 pl-2">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="payload.short_description"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Descrição completa
                </label>
                <div class="col-md-7 pl-2">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      v-model="payload.full_description"
                      class="form-control form-control-sm"
                    />
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Unidade de Medida
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7 pl-2">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                    <puzl-select 
                      v-model="payload.measured_unit_uuid" 
                      :items="measuredunits" 
                      customKey="uuid"  
                      label="letter" 
                      :loading="loadingMeasuredunit" 
                      :disabled="loadingMeasuredunit" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Local de amostragem
                </label>
                <div class="col-md-7 pl-2">
                  <base-input input-classes="form-control-sm">
                    <puzl-select 
                      v-model="payload.molding_location" 
                      :items="molding_locations" 
                      :multiple="true"
                      :loading="loadingMoldingLocation" 
                      :disabled="loadingMoldingLocation" />
                  </base-input>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
const {formatErrorValidation} = require("@/plugins")
export default {
  name: "Edit",
  components: { PuzlSelect },
  data () {
    return {
      modal: false,
      title: 'Editar Ensaio Estado Fresco',
      payload: {
        status: true,
        default: false,
        short_description: null,
        full_description: null,
        measured_unit_uuid: null,
        molding_location: null,
      },
      loadingMoldingLocation: false,
      loadingMeasuredunit: false,
      loadingStore: false,
    }
  },
  computed: {
    ...mapGetters({
      measuredunits: "measuredunit/fetch",
      molding_locations: "moldingLocation/fetch",
    })
  },
  methods: {
    closeModal () {
      this.modal = false
    },
    handleEditModal (uuid) {
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'})
      this.$Progress.start()
      this.$store.dispatch('testFreshState/show', uuid)
        .then( ({data}) => {
          this.payload = {
            uuid: data.uuid || '',
            status: data.status || false,
            default: data.default || false,
            short_description: data.short_description || null,
            full_description: data.full_description || null,
            measured_unit_uuid: data.measured_unit.uuid || null,
            molding_location: data.location_select || null,
          }
          this.modal = true
          this.$notify({type: response.error_type, message: response.message})
          this.$Progress.finish()
        })
        .catch(error =>{
          if (error.status) {
            this.$Progress.finish()
            this.loadingStore = false
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            })
          }
          this.$Progress.finish()
        })
    },
    save () {
      this.$Progress.start()
      this.loadingStore = true
      this.$store.dispatch('testFreshState/update', this.payload)
        .then(response => {
          this.loadingStore = false
          this.modal = false
          this.$Progress.finish()
          this.$notify({ type: response.error_type, message: response.message })
        })
        .catch(error =>{
          if (error.status) {
            this.$Progress.finish()
            this.loadingStore = false
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            })
          } else {
            if (error.response.status === 422) {
              let message = formatErrorValidation(error.response.data.errors)
              this.$notify({
                type: 'danger',
                message: message
              })
              this.$Progress.finish()
              this.loadingStore = false
            } else {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              })
              this.$Progress.finish()
              this.loadingStore = false
            }
          }
        })
    },
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
}
</script>

<style scoped>

</style>
