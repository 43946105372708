<template>
  <div>
    <modal :show.sync="modal.create" size="md">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form v-if="form_data" class="needs-validation" @submit.prevent="handleSubmit(saveFiles)" autocomplete="off">
            <div class="form-group row m-0 p-0">
              <label class="col-md-6 pb-0 pb-2 mb-1 col-form-label form-control-label">
                Número da NFS-e
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-6">
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-classes="form-control-sm">
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      v-model="form_data.nfse.nfse_number"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <label class="col-md-6 pb-0 pb-2 mb-1 col-form-label form-control-label">
                Data de Autorização
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-6">
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-group-classes="input-group-sm">
                    <input-date-time-picker v-model="form_data.nfse.authorization_date" />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <label class="col-md-6 pb-0 pb-2 mb-1 col-form-label form-control-label">
                Número do RPS
              </label>
              <div class="col-md-6">
                <base-input input-classes="form-control-sm">
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    v-model="form_data.nfse.rps_number"
                  />
                </base-input>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <label class="col-md-6 pb-0 pb-2 mb-1 col-form-label form-control-label">
                Série do RPS
              </label>
              <div class="col-md-6">
                <base-input input-classes="form-control-sm">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="form_data.nfse.rps_series"
                  />
                </base-input>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <label
                class="col-md-12 pb-2 mb-1 col-form-label form-control-label">
                Arquivo XML
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-12 text-right">
                <base-input input-group-classes="input-group-sm">
                  <InputFile
                    :accept="'.xml'"
                    :multiple="false"
                    :helpText="'Escolha o arquivo ou arraste e solte aqui'"
                    :errorTextType="'Tipo de arquivo inválido. Apenas arquivo com extensão .xml permitido'"
                    ref="inputXmlFile"
                  />
                </base-input>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <label
                class="col-md-12 pb-2 mb-1 col-form-label form-control-label">
                Arquivo PDF
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-12 text-right">
                <base-input input-group-classes="input-group-sm">
                  <InputFile
                    :accept="'.pdf'"
                    :multiple="false"
                    :helpText="'Escolha o arquivo ou arraste e solte aqui'"
                    :errorTextType="'Tipo de arquivo inválido. Apenas arquivo com extensão .pdf permitido'"
                    ref="inputPdfFile"
                  />
                </base-input>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">
                Cancelar
              </base-button>
              <base-button
                type="success"
                native-type="submit"
                :disabled="invalid"
                :loading="isLoading">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import InputFile from "@/components/Utils/InputFile";
import InputDateTimePicker from "@/components/InputDateTimePickerV2";
import { strToNum, percentageFrom } from "@/helpers";
import { formatErrorValidation } from "@/plugins";

export default {
  name: "ModalNfseAuthorizedImport",
  components: {
    InputDateTimePicker,
    InputFile,
  },
  data() {
    return {
      modal: {
        title: "Substituir NFS-e",
        create: false,
      },
      current_invoice: null,
      mentionRefToService: "@CONCRETO_COM_NFE | @SERVIÇO_COM_DATA",
      mentionRefToComments: "CONTRATO @CONTRATO | FATURA @FATURA | OBRA @OBRA | @CONCRETO_SIMPLIFICADO | @SERVIÇO | @PAGAMENTO | @DATAS | @COMPLEMENTO | @IBPT | @OBSERVACAO",
      current_taxes_types: ["issqn", "cofins", "csll", "inss", "ir", "pis"],
      current_taxes_default: {
        base: "0,00",
        condition_id: 1,
        condition: "",
        consider_reuse: false,
        deduct: "0,00",
        deduct_mcc_cost: false,
        retained_rate: "0,00",
        total_rate: "0,00",
        full_coverage: false,
        allow_issqn_retention_of_legal_person: 0,
        allow_issqn_retention_of_natural_person: 0,
        allow_issuance_without_customer_municipal_registration: 0,
      },
      condition_options: [
        { id: 1, text: "Adotar sempre o MCC" },
        { id: 2, text: "Adotar sempre maior dedução" },
        { id: 3, text: "Adotar sempre menor dedução" },
      ],
      form_data: null,
      form_data_default: {
        invoice_id: null,
        xml_file: null,
        pdf_file: null,
        nfse: {
          status: 'Autorizada',
          iss_nfse: {
            base: 0,
            deduct: 0,
            base_perc: 0,
            iss_value: 0,
            deduct_perc: 0,
            retained_rate: 0,
            retained_rate_flag: false
          },
          link_pdf: '',
          link_xml: '',
          rps_number: null,
          rps_series: '',
          nfse_number: null,
          observation: '',
          status_reason: '',
          nfse_external_id: '',
          cancellation_date: null,
          authorization_date: null,
          federal_taxes_nfse: {
            ir_rate: 0,
            ir_value: 0,
            pis_rate: 0,
            csll_rate: 0,
            inss_rate: 0,
            pis_value: 0,
            csll_value: 0,
            inss_value: 0,
            cofins_rate: 0,
            cofins_value: 0,
            ir_retained_flag: false,
            pis_retained_flag: false,
            csll_retained_flag: false,
            inss_retained_flag: false,
            cofins_retained_flag: false
          },
          service_description: '',
          deduction_rules_nfse: {
            condition: {
              id: 1,
              options: [
                {
                  id: 1,
                  text: 'Adotar sempre o MCC'
                },
                {
                  id: 2,
                  text: 'Adotar sempre maior dedução'
                },
                {
                  id: 3,
                  text: 'Adotar sempre menor dedução'
                }
              ]
            },
            consider_reuse: false,
            deduct_mcc_cost: false
          },
          contract_proposal_cno: null
        },
      },
      isLoading: false,
    };
  },
  methods: {
    openModal(invoice){
      if (this.$refs["inputPdfFile"]) this.$refs["inputPdfFile"].fileRecords = []
      if (this.$refs["inputXmlFile"]) this.$refs["inputXmlFile"].fileRecords = []
      this.form_data = this.$helper.cloneObject(this.form_data_default);
      this.current_invoice = invoice;
      this.loadFormData();
      this.modal.create = true;
    },
    async loadFormData(){
      this.form_data.invoice_id = this.current_invoice.id;
      this.form_data.nfse.service_description = await this.loadDescription(true);
      this.form_data.nfse.observation = await this.loadDescription();
      const retained_rates_between_entity_and_issuer = await this.getRetainedRatesBetweenEntityAndIssuer(
        this.current_invoice.entity.id,
        this.current_invoice.company_plant_issuer.id
      );
      const current_taxes = await this.getAllCurrentTaxes(
        this.current_invoice.contract_proposal.construction.default_address.city,
        this.current_invoice.company_plant_issuer_tax_regime,
      );
      const chosenDeductPerc = percentageFrom(this.current_invoice.total, strToNum(this.current_invoice.deductions_value));
      const iss_nfse_deduct = strToNum(this.current_invoice.deductions_value);
      const iss_nfse_base = this.current_invoice.total - strToNum(iss_nfse_deduct);
      const iss_nfse_base_perc = percentageFrom(this.current_invoice.total, iss_nfse_base);
      const iss_rate = strToNum(this.current_invoice.iss_rate);
      const iss_withheld_at_source = !!this.current_invoice.iss_retain;

      // ISS
      let iss_value = 0;
      if (iss_rate > 0)
        iss_value = iss_nfse_base * (iss_rate / 100);

      // IR
      let ir_value = 0;
      if (retained_rates_between_entity_and_issuer.ir && strToNum(current_taxes.ir.retained_rate) > 0)
        ir_value = strToNum(this.current_invoice.total) * (strToNum(current_taxes.ir.retained_rate) / 100);

      // PIS
      let pis_value = 0;
      if (retained_rates_between_entity_and_issuer.pis && strToNum(current_taxes.pis.retained_rate) > 0)
        pis_value = strToNum(this.current_invoice.total) * (strToNum(current_taxes.pis.retained_rate) / 100);

      // CSLL
      let csll_value = 0;
      if (retained_rates_between_entity_and_issuer.csll && strToNum(current_taxes.csll.retained_rate) > 0)
        csll_value = strToNum(this.current_invoice.total) * (strToNum(current_taxes.csll.retained_rate) / 100);

      // INSS
      let inss_value = 0;
      if (retained_rates_between_entity_and_issuer.inss && strToNum(current_taxes.inss.retained_rate) > 0)
        inss_value = strToNum(this.current_invoice.total) * (strToNum(current_taxes.inss.retained_rate) / 100);

      // COFINS
      let cofins_value = 0;
      if (retained_rates_between_entity_and_issuer.cofins && strToNum(current_taxes.cofins.retained_rate) > 0)
        cofins_value = strToNum(this.current_invoice.total) * (strToNum(current_taxes.cofins.retained_rate) / 100);

      this.form_data.nfse.iss_nfse = {
        base: iss_nfse_base,
        deduct: iss_nfse_deduct,
        base_perc: iss_nfse_base_perc,
        iss_value: iss_value,
        deduct_perc: chosenDeductPerc,
        retained_rate: iss_rate,
        retained_rate_flag: iss_withheld_at_source,
        total_rate: strToNum(current_taxes.issqn.total_rate),
      };
      this.form_data.nfse.federal_taxes_nfse = {
        ir_rate: strToNum(current_taxes.ir.retained_rate),
        ir_value: ir_value,
        ir_retained_flag: retained_rates_between_entity_and_issuer.ir,

        pis_rate: strToNum(current_taxes.pis.retained_rate),
        pis_value: strToNum(pis_value),
        pis_retained_flag: retained_rates_between_entity_and_issuer.pis,

        csll_rate: strToNum(current_taxes.csll.retained_rate),
        csll_value: strToNum(csll_value),
        csll_retained_flag: retained_rates_between_entity_and_issuer.csll,

        inss_rate: strToNum(current_taxes.inss.retained_rate),
        inss_value: strToNum(inss_value),
        inss_retained_flag: retained_rates_between_entity_and_issuer.inss,

        cofins_rate: strToNum(current_taxes.cofins.retained_rate),
        cofins_value: strToNum(cofins_value),
        cofins_retained_flag: retained_rates_between_entity_and_issuer.cofins,
      };
      this.form_data.nfse.deduction_rules_nfse = {
        condition: {
          id: current_taxes.issqn.condition_id,
          options: this.condition_options,
        },
        consider_reuse: current_taxes.issqn.consider_reuse,
        deduct_mcc_cost: current_taxes.issqn.deduct_mcc_cost,
      };
      this.form_data.nfse.contract_proposal_cno = this.current_invoice.contract_proposal_cno;
    },
    closeModal() {
      this.modal.create = false;
      this.$emit("close");
    },
    async loadDescription(forService) {
      let useMention = (forService === true)
        ? this.mentionRefToService
        : this.mentionRefToComments;

      return await this.$store.dispatch("nfse/getObservationByMention", {
        mention: useMention,
        invoice_id: this.current_invoice.id,
      }).then(response => {
        let description = response.data;
        if (response.data == ' | ') description = null;
        if (response.data.substr(-2).trim() === '|') description = response.data.substring(0, response.data.length - 2);
        if (response.data.substr(0, 2).trim() === '|') description = response.data.substring(2, response.data.length);
        return description;
      });
    },
    async getRetainedRatesBetweenEntityAndIssuer(entityId, issuerId) {
      let params = { entityId: entityId, issuerId: issuerId };
      return await this.$store
        .dispatch("FeesAndTaxes/getActiveRetainedRatesBetweenEntityAndIssuer", params)
        .then((response) => {
          let retained_rates_between_entity_and_issuer = {};
          this.current_taxes_types.map(function (obj, index) {
            retained_rates_between_entity_and_issuer[obj] = response.data.filter((item) => item.name.toLowerCase() === obj)[0]
              ? true
              : false;
          }, this);
          return retained_rates_between_entity_and_issuer;
        })
        .catch((error) => {
          let errors = error.status
            ? error.data.message
            : formatErrorValidation(error.response.data.errors);
          this.$notify({ type: "danger", message: errors });
        });
    },
    async getAllCurrentTaxes(constructionCity, issuerRegime) {
      let params = {
        constructionCity: constructionCity,
        issuerRegime: issuerRegime,
      };
      return await this.$store
        .dispatch("deductionRules/getAllCurrentTaxes", params)
        .then((response) => {
          let current_taxes = {};
          this.current_taxes_types.map(function (obj, index) {
            current_taxes[obj] = response.data.filter((item) => item.name.toLowerCase() === obj)[0] ?? this.current_taxes_default;
            const conditionFound = this.condition_options.find((element) => element.text === current_taxes[obj].condition);
            current_taxes[obj].condition_id = conditionFound ? conditionFound.id : 1;
          }, this);
          return current_taxes;
        })
        .catch((error) => {
          let errors = error.status
            ? error.data.message
            : formatErrorValidation(error.response.data.errors);
          this.$notify({ type: "danger", message: errors });
        });
    },
    saveFiles(){
      const informedFiles = this.$refs["inputPdfFile"].fileRecords.length > 0;
      if (!informedFiles) {
        this.$notify({ type: "danger", message: "O arquivo PDF é obrigatório."});
        return;
      }
      this.form_data.pdf_file = this.$refs["inputPdfFile"].fileRecords[0].file;
      this.form_data.xml_file = this.$refs["inputXmlFile"].fileRecords.length > 0 ? this.$refs["inputXmlFile"].fileRecords[0].file : '';
      this.isLoading = true
      this.$Progress.start()
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      })
      this.$store
        .dispatch("nfse/changeInvoiceNfse", this.form_data)
        .then((response) => {
          this.isLoading = false
          this.$Progress.finish()
          this.$notify({
            type: "success",
            message: "Solicitação realizada com sucesso!",
          })
          this.current_invoice.nfse = this.form_data.nfse
          this.closeModal()
        })
        .catch((error) => {
          let errors = error.status
            ? error.data.message
            : formatErrorValidation(error.response.data.errors);
          this.$notify({ type: "danger", message: errors });
          this.isLoading = false;
          this.$Progress.finish();
        })
    }
  },
};
</script>

<style scoped>

</style>
