<template>
    <div>
        <modal size="lg" :show.sync="modal.create">
            <template slot="header">
                <div style="display: flex;align-items: center;">
                    <img style="display: inline-flex;" src="/img/icons/icons8/ios/briefcase.png" width="22">
                    <h5 style="display: inline-flex;" class="modal-title p-0 m-0 ml-3 text-white"> NOVA TAREFA </h5>
                </div>      
            </template>
            <div>
                <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
                    <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-12" style="display: flex; align-items: center;">
                                    <img src="/img/icons/icons8/ios/info-squared.png" width="22">
                                    <h4 class="new-default-black-font m-0 mx-3 ">
                                        Identificação
                                    </h4>
                                    <hr class="ml-3" style="width: 100%;">
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="form-group row m-0 p-0">
                                    <div class="col-md-5 mb-1 pr-4">
                                        <div class="pb-0 mb-1 form-control-label">
                                            Responsável
                                            <span class="text-danger"
                                            >&nbsp;*</span
                                            >
                                        </div>
                                    </div>
                                    <div class="col-md-7 mb-2 pr-4">
                                        <validation-provider rules="required">
                                            <base-input input-classes="form-control-sm">
                                                <PuzlSelect
                                                v-model="task.receiver_user_id"
                                                :items="$_users"
                                                :loading="loadingUsers"
                                                :disabled="loadingUsers"/>
                                            </base-input>
                                        </validation-provider>
                                    </div>
                                </div>
                                <div class="form-group row m-0 p-0">
                                    <div class="col-md-5 mb-1 pr-4">
                                        <div class="pb-0 mb-1 form-control-label">
                                            Data Limite
                                            <span class="text-danger"
                                            >&nbsp;*</span
                                            >
                                        </div>
                                    </div>
                                    <div class="col-md-7 mb-2 pr-4">
                                        <validation-provider rules="required">
                                            <base-input input-group-classes="input-group-sm">
                                            <InputDateTimePicker
                                                v-model="task.limit_date"
                                                :mode="'date'"
                                                :disableMaxDate="false"
                                                :disableDate="new Date()"/>
                                            </base-input>
                                        </validation-provider>
                                    </div>
                                </div>
                                <div class="form-group row m-0 mb-4 p-0">
                                    <div class="col-md-5 mb-1 pr-4">
                                        <div class="pb-0 mb-1 form-control-label">
                                            Urgência
                                            <span class="text-danger">&nbsp;*</span>
                                        </div>
                                    </div>
                                    <div class="col-md-7 mb-2 pr-4">
                                        <validation-provider rules="required">
                                            <base-input input-classes="form-control-sm">
                                                <PuzlSelect
                                                v-model="task.priority"
                                                :items="priority"
                                                />
                                            </base-input>
                                        </validation-provider>
                                    </div>
                                </div>
                                <div class="form-group row m-0 p-0">
                                    <div class="col-md-5 mb-1 pr-4">
                                        <div class="pb-0 mb-1 form-control-label">
                                            Título da Tarefa
                                            <span class="text-danger"
                                            >&nbsp;*</span
                                            >
                                        </div>
                                    </div>
                                    <div class="col-md-7 mb-2 pr-4">
                                        <validation-provider rules="required">
                                            <base-input input-classes="form-control-sm">
                                            <input
                                                v-model="task.description"
                                                type="text"
                                                class="form-control form-control-sm"
                                            />
                                            </base-input>
                                        </validation-provider>
                                    </div>
                                </div>
                                <div class="form-group row m-0 p-0">
                                    <div class="col-md-5 mb-1 pr-4">
                                        <div class="pb-0 mb-1 form-control-label">
                                            Resumo da Tarefa
                                        </div>
                                    </div>
                                    <div class="col-md-7 mb-2 pr-4">
                                        <base-input input-group-classes="input-group-sm">
                                            <textarea
                                            v-model="task.observation"
                                            rows="4"
                                            type="text"
                                            class="form-control form-control-sm"
                                            @input="checkText"
                                            />
                                            <span class="float-right">
                                              {{ typedCharacters() }} / {{ characterLimit }}
                                            </span>
                                        </base-input>
                                    </div>
                                </div>
                                <div class="form-group row m-0 p-0 mb-4">
                                    <div class="col-md-12 mt-2">
                                        <label class="form-control-label">Anexos</label>
                                        <InputFile :maxFiles="3" ref="files"/>
                                    </div>
                                </div>

                                <div class="modal-footer">
                                    <base-button type="danger" @click="closeModal">
                                        <img class="mr-2" src="/img/icons/cancel-white.png" width="18px" height="18px" />
                                        Cancelar
                                    </base-button>
                                    <base-button type="success" native-type="submit" :loading="loadingStore" v-bind:disabled="invalid">
                                        <img class="mr-2" src="/img/icons/save.png" width="18px" height="18px" />
                                        Salvar
                                    </base-button>
                                </div>
                            </div>
                        </div>
                    </form>
                </validation-observer>
            </div>
        </modal>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
const { formatErrorValidation } = require("@/plugins");
import InputDateTimePicker from "@/components/InputDateTimePicker";
import InputFile from "@/components/Utils/InputFile";
export default {
  name: "ModalCreateTask",
  components: {
    PuzlSelect,
    InputDateTimePicker,
    InputFile,
  },
  data() {
    return {
      modal: {
        title: "Nova Tarefa",
        create: false,
      },
      task: {
        description: null,
        priority: null,
        limit_date: null,
        receiver_user_id: null,
        observation: null,
        status: null,
        form_data: [],
      },
      priority: [
        { id: 1, name: "Baixa" },
        { id: 2, name: "Média" },
        { id: 3, name: "Alta" },
      ],
      loadingStore: false,
      loadingUsers: true,
      userId: null,
      characterLimit: 500,
    };
  },
  computed: {
    ...mapGetters({
      $_users: "user/getAllActiveUsersSimplified",
    }),
  },

  methods: {
    closeModal() {
      this.modal.create = false;
    },
    handleCreateModal(userId) {
      this.refreshFields();
      this.modal.create = true;
      this.userId = userId;
      this.task.receiver_user_id = userId;
    },
    store() {
      this.$Progress.start();
      this.loadingStore = true;
      if (this.$refs["files"].fileRecords.length) {
        this.$refs["files"].fileRecords.forEach((item, index) => {
          this.task.form_data.push({ file: item.file, index: index });
        });
      }
      this.$store
        .dispatch("task/add", this.task)
        .then((response) => {
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.closeModal();
        })
        .catch((error) => {
          if (error.status === 200) { 
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          } else if(error.response.status === 413) {
            this.$notify({
              type: "danger",
              message: "Os arquivos enviados devem possuir no máximo 5MB"
            })
          }
        }).finally(() => {
          this.loadingStore = false;
          this.$Progress.finish();
        });
    },
    refreshFields() {
      this.task.description = null;
      this.task.priority = null;
      this.task.limit_date = null;
      this.task.receiver_user_id = null;
      this.task.observation = null;
      this.task.status = null;
      this.task.form_data = [];
    },
    checkText() {
      if(this.task.observation.length >= this.characterLimit) {
        this.task.observation = this.task.observation.substring(0, this.characterLimit);
      }
    },
    typedCharacters() {
      return this.task.observation == null ? 0 : this.task.observation.length;
    }
  },
  mounted() {
    this.$store
      .dispatch("user/getAllActiveUsersSimplified")
      .then((response) => {
        this.loadingUsers = false;
      });
  },
};
</script>
