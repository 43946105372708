<template>
  <div>
    <div class="container-fluid">
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
      </div>

      <PuzlEmptyData v-if="!data.length && !loadingSkeleton"> </PuzlEmptyData>

      <div class="row card-wrapper" v-if="data.length && !loadingSkeleton">
        <div
          class="col-lg-4"
          v-for="(menu, index) in data"
          :key="index"
        >
          <card>
            <!-- Card header -->
            <div class="row align-items-center mb-3">
              <div class="col-9 justify-content-center pr-0">
                <div v-for="(name, index_name) in menu.names" :key="index_name">
                  <h5 class="h3 mb-0" :class="index_name > 0 ? 'small mb-1':'' ">{{ name }}</h5>
                </div>
                

              </div>
              <div class="col-3 text-right pl-0">
                <base-dropdown menuOnRight>
                  <base-button
                    slot="title-container"
                    type="primary"
                    class="dropdown-toggle p-2 rounded m-0"
                  >
                    <i class="fas fa-cog"></i>
                  </base-button>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleEditMenu(menu.uuid)">
                    <i class="fas fa-edit text-warning main-icon-menu"></i>
                    Editar
                  </a>
                </base-dropdown>
              </div>
            </div>
            <collapse class="border rounded p-0 mt-3">
              <collapse-item
                name="1"
                class="header-gray"
                :back-ground="menu.tags.length ? 'border-header-primary-bottom' : 'border-header-default-bottom'"
              >
                <h5 slot="title" class="mb-0 ">Tags</h5>
                <!-- Title -->
                <div v-for="(tag, index_tag) in menu.tags" :key="index_tag">
                  <h5 slot="title" class="mb-0 ">{{ tag.name }}</h5>
                </div>
                 <div v-if="!menu.tags.length">
                  <small class="mb-0 ">Nenhuma</small>
                </div>
              </collapse-item>
            </collapse>
          </card>
        </div>
      </div>
    </div>
    <modal-edit-menu @updatedMenu="fetch" ref="ModalEditMenu"></modal-edit-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import ModalEditMenu from "./_ModalEdit";
import PuzlEmptyData from "@/components/PuzlEmptyData";
export default {
  name: "ListMenus",
  components: {
    SkeletonPuzlGrid,
    ModalEditMenu,
    PuzlEmptyData
  },
  data() {
    return {
      loadingSkeleton: false,
      data : [],
    };
  },
  computed: {
    ...mapGetters({
      menus: "menu/fetch"
    })
  },
  watch: {
    added() {
      this.fetch();
    }
  },
  props: {
    added: Boolean
  },
  methods: {
    fetch() {
      this.$Progress.start();
      this.loadingSkeleton = true;
      this.$store
        .dispatch("menu/fetchItems")
        .then(response => {
        this.data = this.menus
          this.data.map(function(obj, key) {
            obj.names = obj.name.split('-') 
          });
          this.$Progress.finish();
          this.loadingSkeleton = false;
        })
        .catch(error => {
          this.$Progress.finish();
          this.loadingSkeleton = false;
        });
    },
    handleEditMenu(uuid) {
      this.$refs.ModalEditMenu.handleEditModal(uuid);
    }
  },
  created() {
    this.fetch();
  }
};
</script>

<style scoped>
.main-icon-menu {
  min-width: 18px;
}
</style>
