<template>
  <div>
    <modal size="md" :show.sync="show">
      <template  slot="header">
        <h5 style="text-transform: none" class="modal-title">{{ title }}</h5>
      </template>
      <div class="row">
        <div class="container">
          <div class="col-md-12 mb-2">
            <span style="text-transform: none">Envio de e-mail realizado <span
              class="font-weight-400">{{ new Date($_show.created_at).toLocaleDateString('pt-br') }}</span> às <span
              class="font-weight-400">{{
                new Date($_show.created_at).getHours()
              }}: {{ new Date().getMinutes() }}</span>.</span>
          </div>
          <div class="col-md-12">
            <span style="text-transform: none" class="font-weight-600">Destinatários: <p class="mb-n1 text-lowercase font-weight-300" v-for="item in $_show.mails">
              {{ item }}
            </p>
            </span>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import MultiFilter from "@/components/Utils/MultiFilterV2";
import InputDatePicker from "@/components/InputDatePicker";
import SkeletonPuzl from '@/components/SkeletonPuzl'
import PuzlEmptyData from "@/components/PuzlEmptyData";
import PuzlSelect from "@/components/PuzlSelect";
import moment from 'moment'

export default {
  props: ['show'],
  name: "ModalShowMailHistory",
  components: {},
  computed: {
    ...mapGetters({
      $_show: "billingInvoice/showMailHistory",
    }),
  },
  data() {
    return {
      title: "Envio automático de e-mail detalhado",
      loading: false,
    };
  },
  mounted() {
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
};
</script>

<style></style>
