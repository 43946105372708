<template>
<div>
    <modal :show.sync="modal.create">
        <template slot="header">
            <h5 class="modal-title pl-3">{{ modal.title }}</h5>
        </template>
        <div>
            <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
                <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
                    <div>

                        <div class="form-group row m-0 p-0">
                            <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label">
                                Descrição
                                <span class="text-danger">&nbsp;*</span>
                            </label>
                            <div class="col-md-6">
                                <validation-provider rules="required" v-slot="{errors}">
                                    <base-input input-group-classes="input-group-sm">
                                        <input 
                                            type="text" 
                                            v-model="adjustment.description" 
                                            class="form-control form-control-sm" 
                                            :class="errors[0] ? 'is-invalid' : 'is-valid'"/>
                                    </base-input>
                                </validation-provider>
                            </div>
                        </div>

                        <div class="form-group row m-0 p-0">
                            <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label">
                                Peça a Concretar
                                <span class="text-danger">&nbsp;*</span>
                              <span class="btn btn-sm btn-success ml-1" @click.prevent="handleCreateConcretePiece()"><i class="fas fa-plus"></i> Novo</span>
                            </label>
                            <div class="col-md-6 mt-2">
                                <validation-provider rules="required" v-slot="{errors}">
                                    <base-input input-classes="form-control-sm">
                                        <PuzlSelect 
                                            v-model="adjustment.pieces_uuid" 
                                            :items="concrete_pieces" 
                                            customKey="uuid" 
                                            label="description"  
                                            :multiple="true"
                                            :loading="loadingConcretePiece" 
                                            :disabled="loadingConcretePiece" />
                                    </base-input>
                                </validation-provider>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <base-button type="secondary" @click="closeModal('create')">
                            Cancelar
                        </base-button>
                        <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                            Salvar
                        </base-button>
                    </div>
                </form>
            </validation-observer>
        </div>
    </modal>
  <create-concrete-piece ref="createConcretePiece"></create-concrete-piece>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import CreateConcretePiece from '@/views/Modules/Technology/Mix/ConcretePiece/Shared/_Create'
import PuzlSelect from "@/components/PuzlSelect"
export default {
    name: "ModalCreateAdjustment",
  components: {
    CreateConcretePiece,
    PuzlSelect,
  },
    data() {
        return {
            modal: {
                title: 'Ajuste Peça a Concretar',
                create: false,
            },
            adjustment: {
                description: null,
                pieces_uuid: [],
            },
            loadingConcretePiece: true,
            loadingStore: false,
        }
    },
    computed: {
        ...mapGetters({
            'concrete_pieces': 'mixConcretePiece/activeItems',
        }),
    },
    methods: {
        closeModal() {
            this.adjustment = {
            description: null,
            pieces_uuid: [],
            }
            this.modal.create = false
        },
        handleCreateModal() {
            this.loadData()
            this.modal.create = true
        },
        handleCreateConcretePiece(){
            this.$refs.createConcretePiece.handleCreateModal()
        },
        store() {
            this.$Progress.start()
            this.loadingStore = true
            this.$store.dispatch('adjustmentConcretePiece/add', this.adjustment)
                .then(response => {
                    this.adjustment = {
                        description: null,
                        pieces_uuid: [],
                    };
                    this.closeModal('create');
                    EventBus.$emit("getPieceAdjustments");
                    this.loadingStore = false
                    this.$Progress.finish();
                    this.$notify({
                        type: response.error_type,
                        message: response.message
                    })
                })
                .catch((error) => {
                    if (error.status == 200) {
                        this.$notify({
                            type: 'danger',
                            message: error.data.message
                        })
                        this.$Progress.finish();
                        this.loadingStore = false
                    } else if (error.response.status === 422) {
                        let errors = Object.values(error.response.data.errors)
                        errors = errors.flat().toString().split(',').join("<br />")
                        this.$notify({
                            type: 'danger',
                            message: errors
                        })
                    }
                })
        },
        loadData() {
            this.loadingConcretePiece = true;
            this.$store.dispatch('mixConcretePiece/fetchItems').then(() => {
                this.loadingConcretePiece = false
            });
        },
    },
    mounted() {
        this.$refs.formValidator.validate();
    },
}
</script>

<style scoped>

</style>
