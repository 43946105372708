import * as types from './mutation_types'
import { createAxios } from "@/plugins/axios";
const endPoint = '/molding-resume'
export default {
  namespaced: true,
  state: {
    items: [],
    itemsGroupByContract: [],
  },
  getters:{
    fetch: state => state.items,
    fetchGroupByContract: state => state.itemsGroupByContract,
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
    [types.SET_GROUP_BY_CONTRACT] (state, payload){
      state.itemsGroupByContract = payload
    },
  },
  actions: {
    fetchItemsPaginate({commit}, params) {
      return createAxios().get(`${endPoint}/index`, {params: params})
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchGroupByContractItemsPaginate({commit}, params) {
      return createAxios().get(`${endPoint}/group-by-contract`, {params: params})
        .then(({data}) =>{
          commit(types.SET_GROUP_BY_CONTRACT, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    countTotalMoldingResume({commit}, params) {
      return createAxios().get(`${endPoint}/count-total-molding-resume`, {params: params})
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
