<template>
  <div>
    <modal
      :show.sync="modal"
      :backgroundColor="'#FEF9F2'"
    >
      <template slot="header">
        <div style="display: flex;align-items: center;">
          <img style="display: inline-flex;" src="/img/icons/create-new.png" width="24">
          <h5 style="display: inline-flex;" class="modal-title p-0 m-0 ml-3 new-default-black-font fs-18">{{ title }}</h5>
        </div>
      </template>
      <template slot="close-button">
        <img class="pointer" @click="closeModal" src="/img/icons/icons8/ios/close-window.png" alt="close-window" width="17px">
      </template>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-auto pr-0">
            <div style="display: flex;align-items: center;">
              <img
                class="mr-3"
                src="/img/icons/icons8/ios/info-squared_warning.png"
                height="20"/>
              <h4 class="new-default-black-font m-0">
                Informações
              </h4>
            </div>
          </div>
          <div class="col">
            <hr class="gray-divider">
          </div>
        </div>
      </div>
      <div class="container">
        <div
          v-show="cycle.distance == 0 || cycle.send_duration == 0 || cycle.return_duration == 0"
          class="row align-items-center mb-2"
        >
          <div class="col-12">
            <i class="fa-solid fa-triangle-exclamation text-danger mx-1"></i>
            <b style="font-size: 10px;color: #e25757">
              Distância e/ou tempos de trajeto não podem ser iguais a zero
            </b>
          </div>
        </div>
        <div class="row my-3">
          <div class="col-12">
            <div style="display: flex; align-items: center;">
              <img
                src="/img/icons/icons8/ios/chemical-plant.png"
                width="20"
                slot="reference"
              >
              <h5 class="new-default-gray-font font-weight-400 m-0 ml-2">
                {{ cycle.plant.plant_name }}
              </h5>
            </div>
          </div>
        </div>

        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(save)"
            autocomplete="off"
          >
          <div class="row align-items-center mt-3">
            <div class="col-md-6">
              <h5 class="m-0 mr-2 new-default-black-font font-weight-400">
                Volume Médio por BT
                <span class="text-danger">&nbsp;*</span>
              </h5>
            </div>
            <div class="col-md-6">
              <validation-provider rules="required">
                <base-input>
                  <div class="input-custom-group">
                    <input
                      class="form-control form-control-sm"
                      v-mask="['#.#', '##.#', '#']"
                      v-model="cycle.average_volume_per_bt"
                      type="text"
                    >
                    <div>
                      <nav>
                        <p>
                          m
                        </p>
                        <sup>
                          3
                        </sup>
                      </nav>
                    </div>
                  </div>
                </base-input>
              </validation-provider>
            </div>
          </div>
          <div class="row align-items-center mt-3">
            <div class="col-md-6">
              <div style="display: flex; align-items: center;">
                <h5 class="m-0 mr-2 new-default-black-font font-weight-400">
                  Distância
                  <span class="text-danger">&nbsp;*</span>
                </h5>
                <img
                  v-if="cycle.distance == 0"
                  src="/img/icons/icons8/ios/box-important--v1_danger.png"
                  class="pointer"
                  width="16"
                  slot="reference"
                >
              </div>
            </div>
            <div class="col-md-6">
              <validation-provider rules="required">
                <base-input>
                  <div class="input-custom-group">
                    <input
                      class="form-control form-control-sm"
                      maxlength="10"
                      inputmode="numeric"
                      v-model.lazy="cycle.distance"
                      type="text"
                      v-mask="'##########'"
                    >
                    <div>
                      km
                    </div>
                  </div>
                </base-input>
              </validation-provider>
            </div>
          </div>
          <div class="row align-items-center mt-3">
            <div class="col-md-6">
              <div style="display: flex; align-items: center;">
                <h5 class="m-0 mr-2 new-default-black-font font-weight-400">
                  Tempo (ida)
                  <span class="text-danger">&nbsp;*</span>
                </h5>
                <img
                  v-if="cycle.send_duration == 0"
                  src="/img/icons/icons8/ios/box-important--v1_danger.png"
                  class="pointer"
                  width="16"
                  slot="reference"
                >
              </div>
            </div>
            <div class="col-md-6">
              <validation-provider rules="required">
                <base-input>
                  <div class="input-custom-group">
                    <input
                      class="form-control form-control-sm"
                      v-model.number="cycle.send_duration"
                      v-mask="['#############']"
                    >
                    <div>
                      Minutos
                    </div>
                  </div>
                </base-input>
              </validation-provider>
            </div>
          </div>
          <div class="row align-items-center mt-3">
            <div class="col-md-6">
              <div style="display: flex; align-items: center;">
                <h5 class="m-0 mr-2 new-default-black-font font-weight-400">
                  Acréscimo trajeto (ida)
                  <span class="text-danger">&nbsp;*</span>
                </h5>
              </div>
            </div>
            <div class="col-md-6">
              <validation-provider rules="required">
                <base-input>
                  <div class="input-custom-group">
                    <input
                      v-model.number="cycle.send_addition"
                      v-mask="['#############']"
                      class="form-control form-control-sm"
                      disabled
                    >
                    <div>
                      Minutos
                    </div>
                  </div>
                </base-input>
              </validation-provider>
            </div>
          </div>
          <div class="row align-items-center mt-3">
            <div class="col-md-6">
              <div style="display: flex; align-items: center;">
                <h5 class="m-0 mr-2 new-default-black-font font-weight-400">
                  Tempo de carregamento
                  <span class="text-danger">&nbsp;*</span>
                </h5>
              </div>
            </div>
            <div class="col-md-6">
              <validation-provider rules="required">
                <base-input>
                  <div class="input-custom-group">
                    <input
                      v-model.number="cycle.load_truck_time"
                      v-mask="['#############']"
                      class="form-control form-control-sm"
                    >
                    <div>
                      Minutos
                    </div>
                  </div>
                </base-input>
              </validation-provider>
            </div>
          </div>
          <div class="row align-items-center mt-3">
            <div class="col-md-6">
              <div style="display: flex; align-items: center;">
                <h5 class="m-0 mr-2 new-default-black-font font-weight-400">
                  Tempo de mistura/dosagem
                  <span class="text-danger">&nbsp;*</span>
                </h5>
              </div>
            </div>
            <div class="col-md-6">
              <validation-provider rules="required">
                <base-input>
                  <div class="input-custom-group">
                    <input
                      v-model.number="cycle.new_time_mixing"
                      class="form-control form-control-sm"
                      disabled
                    >
                    <div>
                      Minutos
                    </div>
                  </div>
                </base-input>
              </validation-provider>
            </div>
          </div>
          <div class="row align-items-center mt-3">
            <div class="col-md-6">
              <div style="display: flex; align-items: center;">
                <h5 class="m-0 mr-2 new-default-black-font font-weight-400">
                  Permanência em obra
                  <span class="text-danger">&nbsp;*</span>
                </h5>
              </div>
            </div>
            <div class="col-md-6">
              <validation-provider rules="required">
                <base-input>
                  <div class="input-custom-group">
                    <input
                      v-model.number="cycle.time_construction_stay"
                      v-mask="['#############']"
                      class="form-control form-control-sm"
                    >
                    <div>
                      Minutos
                    </div>
                  </div>
                </base-input>
              </validation-provider>
            </div>
          </div>
          <div class="row align-items-center mt-3">
            <div class="col-md-6">
              <div style="display: flex; align-items: center;">
                <h5 class="m-0 mr-2 new-default-black-font font-weight-400">
                  Intervalo entre descargas
                  <span class="text-danger">&nbsp;*</span>
                </h5>
              </div>
            </div>
            <div class="col-md-6">
              <validation-provider rules="required">
                <base-input>
                  <div class="input-custom-group">
                    <input
                      v-model.number="cycle.interval_releases"
                      v-mask="['#############']"
                      class="form-control form-control-sm"
                    >
                    <div>
                      Minutos
                    </div>
                  </div>
                </base-input>
              </validation-provider>
            </div>
          </div>
          <div class="row align-items-center mt-3">
            <div class="col-md-6">
              <div style="display: flex; align-items: center;">
                <h5 class="m-0 mr-2 new-default-black-font font-weight-400">
                  Trajeto (volta)
                  <span class="text-danger">&nbsp;*</span>
                </h5>
                <img
                  v-if="cycle.return_duration == 0"
                  src="/img/icons/icons8/ios/box-important--v1_danger.png"
                  class="pointer"
                  width="16"
                  slot="reference"
                >
              </div>
            </div>
            <div class="col-md-6">
              <validation-provider rules="required">
                <base-input>
                  <div class="input-custom-group">
                    <input
                      maxlength="10"
                      inputmode="numeric"
                      v-mask="'##########'"
                      v-model.lazy="cycle.return_duration"
                      type="text"
                      class="form-control form-control-sm"
                    >
                    <div>
                      Minutos
                    </div>
                  </div>
                </base-input>
              </validation-provider>
            </div>
          </div>
          <div class="row align-items-center mt-3">
            <div class="col-md-6">
              <div style="display: flex; align-items: center;">
                <h5 class="m-0 mr-2 new-default-black-font font-weight-400">
                  Acréscimo trajeto (volta)
                  <span class="text-danger">&nbsp;*</span>
                </h5>
              </div>
            </div>
            <div class="col-md-6">
              <validation-provider rules="required">
                <base-input>
                  <div class="input-custom-group">
                    <input
                      v-model.number="cycle.return_addition"
                      type="number"
                      class="form-control form-control-sm"
                      disabled
                    >
                    <div>
                      Minutos
                    </div>
                  </div>
                </base-input>
              </validation-provider>
            </div>
          </div>
          <div class="row align-items-center mt-3">
            <div class="col-md-6">
              <div style="display: flex; align-items: center;">
                <h5 class="m-0 mr-2 new-default-black-font font-weight-400">
                  Tempo de limpeza/Lastro
                  <span class="text-danger">&nbsp;*</span>
                </h5>
              </div>
            </div>
            <div class="col-md-6">
              <validation-provider rules="required">
                <base-input>
                  <div class="input-custom-group">
                    <input
                      v-model.number="cycle.time_clear"
                      type="number"
                      class="form-control form-control-sm"
                      disabled
                    >
                    <div>
                      Minutos
                    </div>
                  </div>
                </base-input>
              </validation-provider>
            </div>
          </div>
          <div class="row align-items-center mt-4">
            <div class="col-md-6">
              <h5 class="m-0 mr-2 new-default-black-font font-weight-500">
                Ciclo Total:
              </h5>
            </div>
            <div class="col-md-6">
              <h5 class="m-0 mr-2 new-default-black-font font-weight-400">
                {{ cycle.total_cycle }} minutos
              </h5>
            </div>
          </div>
          <div class="row align-items-center mt-2">
            <div class="col-md-6">
              <h5 class="m-0 mr-2 text-warning font-weight-500">
                Adicional de ciclo:
              </h5>
            </div>
            <div class="col-md-6">
              <h5 class="m-0 mr-2 text-warning font-weight-400">
                {{ cycle.additional_cycle }} minutos
              </h5>
            </div>
          </div>
          <div class="row align-items-center my-3 mt-4">
            <div class="col-12">
              <div class="actions-section">
                <base-button
                  outline
                  slot="reference"
                  size="sm"
                  type="secundary"
                  class="p-0 m-0 shadow-none action-custom-button action-custom-button-red"
                  @click="closeModal()"
                >
                  <div>
                    <img src="/img/icons/icons8/ios/cancel_danger.png" width="16">
                    <h4>Cancelar</h4>
                  </div>
                </base-button>
                <base-button
                  outline
                  slot="reference"
                  size="sm"
                  type="secundary"
                  class="p-0 m-0 shadow-none action-custom-button action-custom-button-green"
                  native-type="submit"
                  :disabled="invalid"
                  :loading="loading"
                >
                  <div>
                    <img src="/img/icons/icons8/ios/save_success.png" width="16">
                    <h4>Salvar</h4>
                  </div>
                </base-button>
              </div>
            </div>
          </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  debounce,
} from "@/plugins";
export default {
  components: {},
  name: "ModalEditCycle",
  data() {
    return {
      modal: false,
      title: "Editar ciclo de viagem",
      validated: false,
      loading: false,
      once: true,
      oldcycle: {},
      cycle: {
        average_volume_per_bt: null,
        cycle_additional: null,
        new_time_mixing: 0,
        distance: null,
        send_duration: null,
        send_addition: null,
        return_addition: null,
        return_duration: null,
        time_clear: null,
        time_mixing: null,
        time_construction_stay: null,
        interval_releases: null,
        load_truck_time: null,
        plant: {
          id: "",
          name: null
        },
        total_cycle: 0,
        additional_cycle: 0
      }
    };
  },
  methods: {
    closeModal() {
      this.cycle = {...this.oldcycle};
      this.modal = false;
    },
    openModal() {
      this.modal = true;
    },
    mountData(cycle) {
      this.once = true;
      this.cycle = {...cycle};
      const newMixing = parseInt(this.cycle.time_mixing) * parseFloat(this.cycle.average_volume_per_bt)
      this.cycle.new_time_mixing = Math.round(newMixing)
    },
    teste2: debounce(function () {
      this.$notify({type: 'danger', message: `O Volume médio por BT não pode ser superior a 49,9.`})
      this.$forceUpdate();
    }, 200),
    save() {
      this.modal = false;
      this.$emit("addCycle", this.cycle);
    }
  },
  mounted() {
    this.$watch("cycle.average_volume_per_bt", function (after, before){
      if (!after) {
        return
      }
      if (after > 49.9) {
        this.teste2()
        const newMixing = parseInt(this.cycle.time_mixing) * parseFloat(before || 0)
        this.cycle.new_time_mixing = Math.round(newMixing)
        this.cycle.average_volume_per_bt = before
      } else {
        const newMixing = parseInt(this.cycle.time_mixing) * parseFloat(after || 0)
        this.cycle.new_time_mixing = Math.round(newMixing)
        this.cycle.average_volume_per_bt = after
      }
    })
    this.$watch('cycle.send_duration', function (val) {
      if (! val) {
        val = 0
      }
      this.cycle.return_duration = val
    })
    this.$watch("cycle.send_duration", function (val){
        this.cycle.send_addition = Math.round(val * this.cycle.increse_route_truck_full_send / 100)
    })
    this.$watch("cycle.return_duration", function (val){
        this.cycle.return_addition = Math.round(val * this.cycle.increse_route_truck_empty_return / 100)
    })
    this.$watch(
      "cycle",
      function (newVal) {
        let notIn = [
          "plant",
          "has_minimum_cost",
          "plant_id",
          "increse_route_truck_empty_return",
          "distance",
          "average_volume_per_bt",
          "increse_route_truck_full_send",
          "total_cycle",
          "cycle_additional",
          "cycle_additional_cost",
          "transport_maximum_distance",
          "transport_additional_cost",
          "time_mixing",
          "interval_releases",
          "additional_cycle"
        ];
        let totalCycle = 0;
        for (var item in newVal) {
          if (notIn.includes(item)) {
            continue;
          }
          if (!newVal[item]) {
            continue;
          }
          totalCycle += parseFloat(newVal[item]);
        }
        this.cycle.total_cycle = parseInt(totalCycle);
        if (this.cycle.total_cycle < newVal.cycle_additional) {
          this.cycle.additional_cycle = 0;
        } else {
          this.cycle.additional_cycle =
            parseInt(totalCycle) - newVal.cycle_additional;
        }
        if (this.once) {
          this.oldcycle = {...this.cycle};
          this.$emit("addCycle", this.oldcycle);
          this.once = false;
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
  }
};
</script>

<style scoped>
.gray-divider {
  background-color: #E8E8E8;
  color: #E8E8E8;
  margin: 0;
  opacity: 0.4;
}

.input-custom-group {
	display: flex;
	align-items: center;
	border-radius: 4px;
  overflow: hidden;
  height: 30px;
  border: 1px solid #ECECEC;
  background-color: #ECECEC;
}

.input-custom-group:has(.border-danger) {
  border-color: #db4539;
}

.input-custom-group:has(:disabled) {
  border: 1px solid #f4f4f4;
  background-color: #e1e1e1;;
}

.input-custom-group:has(:disabled) input, .input-custom-group:has(:disabled) div {
  background-color: #e9ecef;
}
.input-custom-group input {
	box-shadow: none !important;
  border: none;
  padding-left: 5px;
  border-radius: 0;
}

.input-custom-group div {
	padding: 0px 10px;
}

.input-custom-group input, .input-custom-group div {
	display: inline-flex;
	height: 30px;
	background: white;
	align-items: center;
	font-size: 12px;
}

.input-custom-group div, .input-custom-group div nav p {
	color: #7d7d7e;
  font-size: 12px;
}

.input-custom-group > div:first-child {
  margin-right: 1px;
}

.input-custom-group > div:last-child {
  margin-left: 1px;
}

.input-custom-group div nav {
  display: flex;
  align-items: center;
}

.input-custom-group div nav p {
  text-transform: lowercase;
  margin: 0;
}

.input-custom-group input {
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}

.input-custom-group input:focus {
	outline: none;
}

.actions-section {
  display: flex;
  gap: 10px;
}

.actions-section button:first-child {
  margin-left: auto !important;
}

.action-custom-button {
  display: flex;
  flex-flow: column;
  width: 130px;
  align-items: center;
  padding: 8px 12px 8px 12px !important;
  background-color: white;
  border-radius: 8px !important;
}

.action-custom-button div {
  display: inline-flex;
  gap: 10px;
  align-items: center;
}

.action-custom-button h4 {
  margin: 0;
  font-weight: 400;
}

.action-custom-button-red {
  border: 1px solid #DB4539;
}

.action-custom-button-green {
  border: 1px solid #149E57;
}

.action-custom-button-red:hover {
  background-color: #DB4539;
}

.action-custom-button-green:hover {
  background-color: #149E57;
}

.action-custom-button-red h4 {
  color: #DB4539;
}

.action-custom-button-green h4 {
  color: #149E57;
}

.action-custom-button-red:hover h4, .action-custom-button-red:hover img, .action-custom-button-green:hover h4, .action-custom-button-green:hover img {
  filter: brightness(0) invert(1);
}
</style>
