<template>
  <div class="d-flex flex-column flex-md-row mb-3" style="gap: 10px;">
    <div class="widget-card w-100">
      <div class="mx-3 my-1">
        <section class="mb-2 top-section">
          <img src="/img/icons/icons8/ios/signal_primary.png" alt="signal_primary" width="24px">
        </section>
        <section class="mid-section">
          Programado
        </section>
        <section class="bottom-section d-flex">
          <span>
            {{ items.total_volume ? items.total_volume.toFixed(1) : 0 }} m<sup>3</sup>
          </span>
          <span class="ml-auto">
            {{ items.total_travels ? items.total_travels : 0 }} VIAGENS
          </span>
        </section>
      </div>
    </div>
    <div class="widget-card w-100">
      <div class="mx-2 my-1">
        <section class="mb-2 top-section">
          <img src="/img/icons/icons8/ios/signal_success.png" alt="signal_success" width="24px">
        </section>
        <section class="mid-section">
          Realizado
        </section>
        <section class="bottom-section d-flex">
          <span>
            {{ items.performed_volume ? items.performed_volume.toFixed(1) : 0 }} m<sup>3</sup>
          </span>
          <span class="ml-auto">
            {{ items.performed_travels ? items.performed_travels : 0 }} VIAGENS
          </span>
        </section>
      </div>
    </div>
    <div class="widget-card w-100">
      <div class="mx-2 my-1">
        <section class="mb-2 top-section">
          <img src="/img/icons/hourglass-yellow.png" alt="hourglass-yellow" width="24px">
        </section>
        <section class="mid-section">
          Pendente
        </section>
        <section class="bottom-section d-flex">
          <span>
            {{ items.unrealized_volume ? items.unrealized_volume.toFixed(1) : 0 }} m<sup>3</sup>
          </span>
          <span class="ml-auto">
            {{ items.unrealized_travels ? items.unrealized_travels : 0 }} VIAGENS
          </span>
        </section>
      </div>
    </div>
    <div class="widget-card w-100">
      <div class="mx-2 my-1">
        <section class="mb-2 top-section">
          <img src="/img/icons/icons8/ios/unavailable_danger.png" alt="unavailable_danger" width="24px">
        </section>
        <section class="mid-section">
          Cancelamento
        </section>
        <section class="bottom-section d-flex">
          <span>
            {{ items.canceled_volume ? items.canceled_volume.toFixed(1) : 0 }} m<sup>3</sup>
          </span>
          <span class="ml-auto">
            {{ items.canceled_travels ? items.canceled_travels : 0 }} VIAGENS
          </span>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScheduleWidgets',
  props: {
    items: {
      type: Object,
      required: true
    }
  },
};
</script>

<style scoped>
.widget-card {
  background-color: #fff;
  border-radius: 8px;
}
.mid-section {
  font-size: 12px;
  font-weight: 400;
  color: #606062;
}
.bottom-section span{
  font-size: 18px;
  font-weight: 500;
  color: #2B2D32;
}

</style>