<template>
  <div>
    <modal :show.sync="modal.create" size="xl">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <iframe
          width="100%"
          height="400px"
          :src="$_tutorial.video"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        >
        </iframe>
        <div class="modal-footer">
          <base-button type="secondary" native-type="submit" @click="closeModal">
            Fechar
          </base-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: "ModalViewTutorial",
    data() {
      return {
        modal: {
          title: 'Passo-a-passo',
          create: false,
        },
      };
    },
    computed: {
      ...mapGetters({
        $_tutorial: 'tutorial/show'
      }),
    },
    methods: {
      closeModal() {
        this.modal.create = false;
      },
      /**
       * @param {integer} id
       */
      openModal(id) {
        this.modal.create = true;
        let loader = this.$loading.show();
        this.$store.dispatch('tutorial/show', id)
          .catch((error) => {
            this.$notify({type: error.data.error_type, message: error.data.message});
          }).finally(() => {
            loader.hide();
          });
      },
    },
};
</script>

<style scoped></style>
