<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-12">
          <h6 class="h2 text-gray d-inline-block mb-0">{{ $route.name }}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4 text-gray">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i></a></li>
              <li class="breadcrumb-item"><a href="#">Cadastro</a></li>
              <li class="breadcrumb-item" aria-current="page">{{ $route.name }}</li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- Header Menus -->
      <div class="row pl-2">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-2 col-xlg-2 mb-3 pr-3 pl-2">
              <router-link :to="{
                path: '/commercial/concrete-price/parameter/create',
                name: 'Parâmetros de preços mínimo - formulação'
              }">
                <base-button block size="lg" type="success">
                  <img src="/img/icons/plus-math--v1-white.png" width="20px" height="20px" class="imgBaseButton"> NOVO
                </base-button>
              </router-link>
            </div>
            <div class="col-md-2 col-xlg-2 mb-3 pr-3 pl-2">
              <router-link :to="{
                path: '/commercial/concrete-price',
                name: 'commercial.concrete-price.index'
              }">
                <base-button block size="lg" type="back" class="text-uppercase">
                  <img src="/img/icons/circled-left.png" width="20px" height="20px" class="imgBaseButton">
                  VOLTAR
                </base-button>
              </router-link>
            </div>

            <div class="col-md-2 col-xlg-2 mb-3">
              <div class="row">
                <base-dropdown class="col-md-12 ml-2 mr-3 mb-3 ml-0 p-0">
                  <base-button block size="lg" type="primary" slot="title-container"
                    class="dropdown-toggle text-uppercase">
                    <img src="/img/icons/plus--v1.png" width="20px" height="20px" class="imgBaseButton">
                    ACRÉSCIMOS
                  </base-button>
                  <div>
                    <a class="dropdown-item" @click="handleEdit">
                      Tipo de formulação
                    </a>
                    <div class="dropdown-divider p-0 m-0"></div>
                    <a class="dropdown-item" href="#" @click="handleCreateConcretedPiece">
                      Peça a concretar
                    </a>
                    <div class="dropdown-divider p-0 m-0"></div>
                    <a class="dropdown-item" href="#" @click="handleCreatePuzlAdmixture">
                      Puzl Admix
                    </a>
                    <div class="dropdown-divider p-0 m-0"></div>
                    <a class="dropdown-item" href="#" @click="handleCreateSlumpFinal">
                      Slump final
                    </a>
                    <div class="dropdown-divider p-0 m-0"></div>
                    <a class="dropdown-item" href="#" @click="handleCreateLevelResponsibility">
                      Nível de aplicação
                    </a>
                  </div>
                </base-dropdown>
              </div>
            </div>

          </div>
        </div>
      </div>
    </base-header>
    <list-service-price></list-service-price>
    <modal-create-mix-type></modal-create-mix-type>
    <modal-create-mix-concreted-pieces></modal-create-mix-concreted-pieces>
    <modal-create-mix-puzl-admixture></modal-create-mix-puzl-admixture>
    <modal-create-mix-slump-flow />
    <modal-create-level-responsibility/>
  </div>
</template>
<script>
import ListServicePrice from './Shared/_List.vue';
import ModalCreateMixType from './Shared/_ModalCreateMixType.vue';
import ModalCreateMixConcretedPieces from './Shared/_ModalCreateMixConcretedPieces.vue';
import ModalCreateMixPuzlAdmixture from './Shared/_ModalCreateMixPuzlAdmixture.vue';
import ModalCreateMixSlumpFlow from './Shared/_ModalCreateMixSlumpFlow.vue';
import ModalCreateLevelResponsibility from './Shared/_ModalCreateLevelResponsibility.vue'
export default {
  components: {
    ListServicePrice,
    ModalCreateMixType,
    ModalCreateMixConcretedPieces,
    ModalCreateMixPuzlAdmixture,
    ModalCreateMixSlumpFlow,
    ModalCreateLevelResponsibility
  },
  data() {
    return {}
  },
  methods: {
    handleEdit() {
      EventBus.$emit("handleCreateMixTypePercentage");
    },
    handleCreateConcretedPiece() {
      EventBus.$emit("handleCreateMixConcretedPiecesPercentage");
    },
    handleCreatePuzlAdmixture() {
      EventBus.$emit("handleCreateMixPuzlAdmixturePercentage");
    },
    handleCreateSlumpFinal() {
      EventBus.$emit("handleCreateSlumpFinal");
    },
    handleCreateLevelResponsibility() {
      EventBus.$emit("handleCreateLevelResponsibility");
    },
  },
  created() {
  },
  mounted() {
  }
};
</script>
<style scoped>
.imgBaseButton {
  margin-bottom: 2px;
  margin-left: -10px;
  margin-right: 5px;
}
</style>
