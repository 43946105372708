<template>
  <div>

    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>

      <div class="row mt-1 mb-3 m-0 p-0">
        <div class="col pr-0">
          <base-button @click.prevent="tab = 0"
                       :class="tab === 0 && 'active'"
                       style="border-top-left-radius: 1.5rem !important;border-bottom-left-radius: 1.5rem !important;border-top-right-radius: 0 !important;border-bottom-right-radius: 0 !important;"
                       class="base-button border-new-default-gray"
                       outline
                       type="primary"
                       block>
            <span :class="tab === 0 ? 'text-white' : 'text-dark'" style="font-size: 11px;">
              <i class="fas fa-cube"></i> Ensaios</span>
          </base-button>
        </div>
        <div class="col pl-0">
          <base-button @click.prevent="tab = 1"
                       :class="tab === 1 && 'active'"
                       style="border-top-left-radius: 0 !important;border-bottom-left-radius: 0 !important;border-top-right-radius: 1.5rem !important;border-bottom-right-radius: 1.5rem !important"
                       class="base-button border-new-default-gray"
                       outline
                       type="primary"
                       block>
            <span :class="tab === 1 ? 'text-white' : 'text-dark'" style="font-size: 11px;"><i
              class="fa-solid fa-circle-info"></i>Detalhes</span>
          </base-button>
        </div>
      </div>
      <div v-if="tab === 0">
        <transition name="slide-fade">
          <div class="p-3">
            <div>
              <validation-observer
                v-slot="{ invalid, handleSubmit }"
                ref="formValidator"
              >
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                  >
                    Ensaio
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-md-7">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <el-select
                          v-model="hardened_state_test.hardened_state_id"
                          size="mini"
                          filterable
                          @change="setHardenedState"
                          placeholder="Selecione"
                        >
                          <el-option
                            v-for="test in hardened_states"
                            :key="test.uuid"
                            :label="test.short_description"
                            :value="test.id"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                  >
                    Responsabilidade
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-md-7">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <el-select
                          @change="setFeature"
                          :disabled="!selected.features.length"
                          v-model="hardened_state_test.feature_id"
                          size="mini"
                          filterable
                          placeholder="Selecione"
                        >
                          <el-option
                            v-for="feature in selected.features"
                            :key="feature.id"
                            :label="feature.label"
                            :value="feature.id"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                  >
                    Item
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-md-7">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <el-select
                          :disabled="!selectedFeature.item.length"
                          v-model="hardened_state_test.item_id"
                          size="mini"
                          filterable
                          placeholder="Selecione"
                        >
                          <el-option
                            v-for="item in selectedFeature.item"
                            :key="item.id"
                            :label="item.description"
                            :value="item.id"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                  >
                    Molde
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-md-7">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <el-select
                          :disabled="!selected.uuid"
                          v-model="hardened_state_test.mold_id"
                          size="mini"
                          filterable
                          placeholder="Selecione"
                        >
                          <el-option
                            v-for="mold in selected.molds"
                            :key="mold.uuid"
                            :label="mold.description"
                            :value="mold.id"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                  >
                    Idade
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-md-3 text-right">
                    <validation-provider rules="required">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          v-model="hardened_state_test.period"
                          v-mask="'##'"
                          inputmode="numeric"
                          class="form-control form-control-sm"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                  <div class="col-md-4 text-right">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <el-select
                          v-model="hardened_state_test.period_type"
                          size="mini"
                          filterable
                          placeholder="Selecione"
                        >
                          <el-option
                            v-for="days_or_hour in days_or_hours"
                            :key="days_or_hour.id"
                            :label="days_or_hour.description"
                            :value="days_or_hour.id"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                  >
                    Data da amostragem
                  </label>
                  <div class="col-md-7 text-left">
                    <base-input input-group-classes="input-group-sm">
                      <el-date-picker
                        v-model="hardened_state_test.sampling_at"
                        size="mini"
                        type="datetime"
                        format="dd/MM/yyyy HH:mm"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        block
                        :picker-options="pickerOptions"
                      >
                      </el-date-picker>
                    </base-input>
                  </div>
                </div>
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                  >
                    Local Amostragem
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-md-7">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <el-select size="mini" v-model="hardened_state_test.molding_location_id" filterable
                                   placeholder="Selecione"
                                   :selected="false">
                          <el-option class="text-normal" v-for="item in molding_locations"
                                     :key="item.id" :label="item.name"
                                     :value="item.id">
                          </el-option>
                        </el-select>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                  >
                    Código
                  </label>
                  <div class="col-md-7">
                    <input
                      v-model="hardened_state_test.code"
                      v-mask="'##########'"
                      inputmode="numeric"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
                <div class="form-group row m-0 p-0">
                  <div
                    class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                  >
                    <base-button
                      v-show="!canEdit"
                      :loading="loadingSave"
                      :disabled="invalid"
                      @click.prevent="save()"
                      size="small"
                      type="success">
                      incluir
                    </base-button>
                  </div>
                </div>
                <div class="col-12" v-if="canEdit">
                  <div class="row">
                    <div class="col-4"></div>
                    <div class="col-8">
                      <div class="float-right">
                        <base-button @click.prevent="cancelEdit" type="secondary">
                          Cancelar
                        </base-button>
                        <base-button
                          @click.prevent="updateHardenedState"
                          type="success"
                          native-type="submit"
                          v-bind:disabled="invalid"
                          :loading="loadingSave"
                        >
                          Salvar
                        </base-button>
                      </div>
                    </div>
                  </div>
                </div>
              </validation-observer>
            </div>
            <div @click.prevent="setEdit(item)" class="card border p-2 card--hover mt-2"
                 v-for="item in schedule_hardened_states">
              <div class="d-flex justify-content-between">
                <div class="col-10">
                  <div class="d-flex justify-content-between">
                    <div>
                  <span class="h5 d-block">
                    {{ item.short_description }}
                  </span>
                    </div>
                    <div>
                  <span class="h5 d-block">
                   {{ item.description }}
                  </span>
                    </div>
                    <div>
                  <span class="h5 d-block">
                   {{
                      item.schedule_period
                    }} {{
                      days_or_hours.find((day_or_hour) => day_or_hour.id == item.schedule_period_type).description
                    }}
                  </span>
                    </div>
                  </div>
                  <div>
                    <div>
                  <span v-show="item.code" class="text-body small text-wrap">
                     Código: {{ item.code }}
                  </span>
                    </div>
                  </div>
                </div>
                <div class="col-2 d-flex align-items-center">
                  <a href="#" @click.prevent="remove(item.uuid)">
                    <i class="fa fa-times text-danger"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="modal-footer pb-0 pr-0">
              <base-button type="secondary" @click="closeModal()">
                Cancelar
              </base-button>
            </div>
          </div>
        </transition>
      </div>
      <div v-if="tab === 1">
        <div class="row">
          <div class="col-12">
            <validation-observer
              v-slot="{ invalid }"
              ref="formValidator"
            >
              <form @submit.prevent="saveDetail()">
                <div class="form-group row m-0 p-0 mb-1">
                  <label
                    class="col-md-12 pb-0 pt-1 col-form-label form-control-label"
                  >
                    Moldador
                  </label>
                  <div class="col-md-12">
                    <validation-provider rules="required">
                      <puzl-select
                        placeholder="Selecionar moldador"
                        v-model="report.molder_user_id"
                        :items="users"
                        :loading="loadingUsers"
                        :disabled="loadingUsers"/>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-1">
                  <label
                    class="col-md-12 pb-0 pt-1 col-form-label form-control-label"
                  >
                    Coleta
                  </label>
                  <div class="col-md-12">
                    <validation-provider rules="required">
                      <base-input input-group-classes="input-group-sm">
                        <input-date-time-picker v-model="report.date_at" :mode="'date'"/>

                        <!--                <el-date-picker-->
                        <!--                  :disabled="loadingUsers"-->
                        <!--                  @change="teste(report)"-->
                        <!--                  v-model="report.collect"-->
                        <!--                  size="mini"-->
                        <!--                  type="datetime"-->
                        <!--                  format="dd/MM/yyyy HH:mm"-->
                        <!--                  value-format="yyyy-MM-dd HH:mm:ss"-->
                        <!--                  block-->
                        <!--                  :picker-options="pickerOptions"-->
                        <!--                >-->
                        <!--                </el-date-picker>-->
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-1">
                  <label
                    class="col-md-12 pb-0 pt-1 col-form-label form-control-label"
                  >
                    Responsável
                  </label>
                  <div class="col-md-12">
                    <validation-provider rules="required">
                      <puzl-select
                        placeholder="Selecionar responsável"
                        v-model="report.user_id"
                        :items="users"
                        :loading="loadingUsers"
                        :disabled="loadingUsers"/>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-1">
                  <label
                    class="col-md-12 pb-0 pt-1 col-form-label form-control-label"
                  >
                    Observações
                  </label>
                  <div class="col-md-12">
                    <validation-provider rules="required">
                      <base-input input-group-classes="input-group-sm">
                        <textarea
                          v-model="report.observation"
                          type="text"
                          rows="4"
                          class="form-control form-control-sm">
                          </textarea>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="modal-footer pr-4">
                  <base-button type="secondary" @click="closeModal('create')">
                    Fechar
                  </base-button>
                  <base-button type="success" native-type="submit" :loading="loadingStore">
                    <i class="fa-regular fa-floppy-disk"></i> Salvar
                  </base-button>
                </div>
              </form>
            </validation-observer>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import PuzlSelect from "@/components/PuzlSelect";
import InputDateTimePicker from "@/components/InputDateTimePicker.vue";

export default {
  components: {PuzlSelect, InputDateTimePicker},
  name: "ModalHardenedStateTest",
  data() {
    return {
      features: [],
      modal: false,
      tab: 0,
      title: 'Ensaios - Estado endurecido',
      loadingSave: false,
      loadingHardenedState: false,
      hardened_state_test: {},
      pickerOptions: {
        selectableRange: this.range,
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      report: {
        molder_user_id: null,
        observation: null,
        date_at: null,
        user_id: null,
        schedule_travel_id: null,
        schedule_travel_uuid: null
      },
      loadingUsers: false,
      loadingStore: false,
      canEdit: false,
      selectedFeature: {
        item: []
      },
      selected: {
        molds: [],
        features: [],
      },
      schedule_travel_uuid: null,
      company_plant_id: null,
      loadingMoldingLocation: false,
      days_or_hours: [
        {
          id: 1,
          description: 'Dias'
        },
        {
          id: 2,
          description: 'Horas'
        }
      ],
    }
  },
  computed: {
    ...mapGetters({
      'hardened_states': 'testHardenedState/fetch',
      'schedule_hardened_states': 'travelConfiguration/showHardenedStates',
      molding_locations: "moldingLocation/fetch",
      users: "user/fetch",
    }),
  },
  methods: {
    closeModal() {
      this.modal = false
    },
    setEdit(item) {
      if (!this.removing) {
        this.hardened_state_test = {...item}
        this.hardened_state_test.period = 2
        this.canEdit = true
        this.selectedFeature = this.features.find(feature => feature.feature_id === item.feature_id).feature
        this.selected = item
        this.setHardenedState()
        this.setFeature()
        this.hardened_state_test.period = item.schedule_period
        this.hardened_state_test.period_type = item.schedule_period_type
      }
    },
    cancelEdit() {
      this.canEdit = false
      this.hardened_state_test = {}
    },
    updateHardenedState() {
      this.loadingSave = true
      let params = {...this.hardened_state_test}
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch('travelConfiguration/updateHardenedState', params)
        .then(response => {
            this.loadingSave = false
            this.hardened_state_test = {}
            this.canEdit = false
            this.$notify({type: 'success', message: response.message});
            if (this.schedule_hardened_states.length > 1) {
              if (!this.schedule_hardened_states[1].code && this.schedule_hardened_states[0].code) {
                this.$Swal.confirmAction('Deseja gerar sequencial automático do código de ensaio?', ['Sim', 'Não'])
                  .then((result) => {
                    if (result.isConfirmed) {
                      let code = Number(this.schedule_hardened_states[0].code)
                      for (let index in this.schedule_hardened_states) {
                        if (this.schedule_hardened_states[index].code) {
                          continue;
                        }
                        code += 1
                        this.schedule_hardened_states[index].code = code
                        this.$store.dispatch('travelConfiguration/updateHardenedState', {...this.schedule_hardened_states[index]})
                      }
                      this.$forceUpdate()
                    } else {
                      let code = Number(this.schedule_hardened_states[0].code)
                      for (let index in this.schedule_hardened_states) {
                        if (this.schedule_hardened_states[index].code) {
                          continue;
                        }
                        this.schedule_hardened_states[index].code = code
                        this.$store.dispatch('travelConfiguration/updateHardenedState', {...this.schedule_hardened_states[index]})
                      }
                      this.$forceUpdate()
                    }
                  })
              }
            }
          }
        ).catch(() => this.loadingSave = false)
    },
    remove(uuid) {
      this.removing = true
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$store.dispatch('travelConfiguration/deleteRemoveHardenedState', uuid)
            .then(response => {
              this.removing = false
              this.$notify({type: 'success', message: response.message});
              this.$Progress.finish()
            })
            .catch(() => {
              this.$Progress.finish()
              this.removing = false
            })
        }
      }).catch(() => this.$Progress.finish())
    },
    setHardenedState() {
      this.hardened_state_test.feature_id = null
      this.hardened_state_test.item_id = null
      this.selectedFeature = {
        item: []
      }
      if (this.hardened_state_test.hardened_state_id) {
        this.selected = this.hardened_states.find((item) => item.id === this.hardened_state_test.hardened_state_id)
      } else {
        this.hardened_state_test.mold_id = null
        this.selected = {
          molds: [],
          features: [],
        }
      }
      const moldDefault = this.selected.molds.find(item => item.default == true)
      if (moldDefault) {
        this.hardened_state_test.mold_id = moldDefault.id
      }
      // adiciona automaticamente o local de moldagem
      if (this.selected.default_molding_locations) {
        const moldLocationDefault = this.selected.default_molding_locations.find(item => item.company_plant_id == this.company_plant_id)
        if (moldLocationDefault) {
          this.hardened_state_test.molding_location_id = moldLocationDefault.molding_location_id
        }
        for (var feature of this.selected.features) {
          for (var item of this.features) {
            if (feature.id == item.feature_id && !this.hardened_state_test.feature_id) {
              this.hardened_state_test.feature_id = feature.id
              this.setFeature()
              this.hardened_state_test.item_id = item.id
            }
          }
        }
      }
    },
    setFeature() {
      if (this.hardened_state_test.feature_id) {
        this.selectedFeature = this.selected.features.find((item) => item.id === this.hardened_state_test.feature_id)
        this.hardened_state_test.period = this.selectedFeature.period
        this.hardened_state_test.period_type = this.selectedFeature.period_type
      } else {
        this.hardened_state_test.item_id = null
        this.selectedFeature = {
          item: []
        }
      }
    },
    async openModal(scheduleTravelUuid) {
      let plantId = null
      let features = null
      this.tab = 0
      await this.$store.dispatch('travels/showLight', {uuid: scheduleTravelUuid}).then(async response => {
        plantId = response.data.company_plant_id
        this.report.date_at = response.data.date_at
        this.report.user_id = response.data.user_id
        this.report.molder_user_id = response.data.molder_user_id
        this.report.observation = response.data.observation
        this.report.schedule_travel_id = response.data.id
        this.report.schedule_travel_uuid = scheduleTravelUuid
        await this.$store.dispatch('schedule/show', response.data.schedule_uuid).then(response => {
          this.features = response.data.contract_proposal_formulation.features
        })
      })
      this.$store.dispatch("user/fetchItems").then(() => {
        this.loadingUsers = false;
      }).catch(() => this.loadingUsers = false);
      this.modal = true
      this.canEdit = false
      this.loadingMoldingLocation = true;
      this.$store.dispatch("moldingLocation/fetchItems").then(() => {
        this.loadingMoldingLocation = false;
      });
      this.loadingMoldingLocation = false;
      this.company_plant_id = plantId
      this.selected = {
        molds: [],
        features: [],
      }
      this.selectedFeature = {
        item: []
      }
      this.hardened_state_test = {}
      this.schedule_travel_uuid = scheduleTravelUuid
      this.loadingHardenedState = true
      this.$store.dispatch('testHardenedState/fetchItems')
        .then(response => {
          this.loadingHardenedState = false
        })
        .catch(error => {
          this.loadingHardenedState = false
        })
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch('travelConfiguration/fetchHardenedStates',
        {
          schedule_travel_uuid: this.schedule_travel_uuid,
          only_hardened_states: true
        })
        .then(response => {
          for (var index in this.schedule_hardened_states) {
            this.schedule_hardened_states[index].period = this.schedule_hardened_states[index].schedule_period
          }
          this.modal = true
          this.$notify({type: 'success', message: response.message});
        })
        .catch(error => {
        })
    },
    async saveDetail() {
      let params = {
        molder_user_id: this.report.molder_user_id,
        observation: this.report.observation,
        date_at: this.report.date_at,
        user_id: this.report.user_id,
        schedule_travel_id: this.report.schedule_travel_id,
        schedule_travel_uuid: this.schedule_travel_uuid
      }
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.loadingStore = true
      await this.$store.dispatch('scheduleTravelMolding/store', params)
      await this.$store.dispatch("testReport/add", params).then(response => {
        this.$notify({type: 'success', message: response.message});
      });
      this.loadingStore = false
    },
    save() {
      this.loadingSave = true
      let params = {...this.hardened_state_test}
      params.schedule_travel_uuid = this.schedule_travel_uuid
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch('travelConfiguration/createHardenedState', params)
        .then(response => {
            this.loadingSave = false
            this.$notify({type: 'success', message: response.message});
            this.hardened_state_test.code = null
          }
        ).catch(() => this.loadingSave = false)
    },
  },
};
</script>

<style></style>
