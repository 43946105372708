import { createAxios } from "@/plugins/axios";
import * as types from "./mutation_types";
const endPoint = '/configuration/entity/credit'
export default {
  namespaced: true,
  state: {
    item: null,
    programmed_value: 0,
    items: [],
  },
  getters: {
    show: state => state.item,
    fetch: (state) => state.items,
    getProgrammedValue: (state) => state.programmed_value,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload;
    },
    [types.SHOW](state, payload) {
      state.item = payload;
    },
    [types.SET_PROGRAMMED_VALUE](state, payload) {
      state.programmed_value = payload;
    },
  },
  actions: {
    add({ }, payload) {
      return createAxios().post(endPoint, payload)
        .then(({ data }) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    show({ commit }, payload) {
      return createAxios().get(`${endPoint}/${payload}`)
        .then(({ data }) => {
          commit(types.SHOW, data.data || {});
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getHistory({ commit }, payload) {
      return createAxios().get(`${endPoint}/history/${payload.id}`, { params: payload })
        .then(({ data }) => {
          commit(types.SET, data.data);
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getProgrammedValue({ commit }, payload) {
      return createAxios().get(`${endPoint}/programmed-value/${payload}`)
        .then(({ data }) => {
          commit(types.SET_PROGRAMMED_VALUE, data.data);
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
