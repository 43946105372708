<template>
   <CreateEntryLaunch :byReplication="true"/>
</template>

<script>
import CreateEntryLaunch from "./_Create.vue";

export default {
  name: "CreateEntryLaunchByReplication",
  components: {
    CreateEntryLaunch,
  },
};
</script>