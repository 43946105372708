<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(save)"
            autocomplete="off"
          >
            <div>
              <div class="form-group row m-0 p-0 m-1">
                <label class="col-md-5 col-form-label form-control-label">
                  Status
                </label>
                <div class="col-md-7 pt-2">
                  <base-switch
                    v-model="group.status"
                    type="success"
                    offText="inativo"
                    onText="ativo"
                    class="success"
                  ></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-5 pb-0 mb-2 col-form-label form-control-label"
                >
                  Categoria MCC
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-classes="form-control-sm">
                      <PuzlSelect
                        v-model="group.cmc_category_id"
                        :items="categories"
                        :disabled="loadingCategory"
                        :loading="loadingCategory"
                        disabled="true"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pb-0 col-form-label form-control-label">
                  Nome do Grupo
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="group.group_name"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pb-0 col-form-label form-control-label">
                  Descrição:
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <textarea
                      type="text"
                      v-model="group.description"
                      class="form-control form-control-sm"
                    >
                    </textarea>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pb-0 col-form-label form-control-label">
                  Código de automação:
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <textarea
                      type="text"
                      v-model="group.external_code"
                      class="form-control form-control-sm"
                    >
                    </textarea>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pb-0 col-form-label form-control-label">
                  Código de integração:
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      v-model="group.integration_code"
                      class="form-control form-control-sm"
                    />
                  </base-input>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <base-button type="secondary" @click="openModal(false)">
                Cancelar
              </base-button>
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                :loading="loadingSave"
              >
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
const { formatErrorValidation } = require("@/plugins");
export default {
  name: "EditGroup",
  components: { PuzlSelect },
  data() {
    return {
      title: "Grupo MCC",
      modal: false,
      group: {
        status: true,
        cmc_category_id: "",
        group_name: "",
        description: "",
      },
      loadingSave: false,
      loadingCategory: true,
    };
  },
  computed: {
    ...mapGetters({
      categories: "category/fetch",
    }),
  },
  methods: {
    openModal(open) {
      this.modal = open;
    },
    handleEditModal(uuid) {
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });
      this.$Progress.start();
      this.loadData();
      this.$store
        .dispatch("cmcGroup/show", uuid)
        .then((response) => {
          this.group = {
            uuid: response.data.uuid || null,
            status: response.data.status || false,
            cmc_category_id: response.data.cmc_category_id || null,
            group_name: response.data.group_name || null,
            description: response.data.description || null,
            external_code: response.data.external_code || null,
            integration_code: response.data.integration_code || null,
          };
          this.modal = true;
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.$Progress.finish();
        })
        .catch((error) => {
          if (error.status === 422) {
            let message = formatErrorValidation(error.response.data.errors);
            this.$notify({ type: "danger", message: message });
          } else {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message,
            });
          }
          this.$Progress.finish();
          this.loadingSave = false;
        });
    },
    save() {
      this.$Progress.start();
      this.loadingSave = true;
      this.$store
        .dispatch("cmcGroup/update", this.group)
        .then((response) => {
          this.modal = false;
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.$notify({ type: "danger", message: error.response.data.message });
          }
        }).finally(() => {
          this.$Progress.finish();
          this.loadingSave = false;
        });
    },
    loadData() {
      this.loadingCategory = true;
      this.$store.dispatch("category/fetchItems").then(() => {
        this.loadingCategory = false;
      });
    },
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
};
</script>

<style scoped></style>
