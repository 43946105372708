<template>
  <div>
    <PageHeader title="SEFAZ"
    icon="/img/icons/login-rounded.png"
    type="danger">
      <PageHeaderButton @click="handleModalInitialFilterSefaz"
          text="Selecionar CNPJ"
          type="success"
          icon="/img/icons/icons8/ios/search--v1.png" />
      <!-- <PageHeaderButton @click="handlePutInNote"
          text="LANÇAR EM LOTE"
          type="warning"
          icon="/img/icons/icons8/ios/choose.png" /> -->
      <PageHeaderButton @click="handleModalSearchEntryLaunchByAccessKey"
          text="Importar XML"
          type="primary"
          icon="/img/icons/icons8/ios/downloading--v1.png" />
      <PageHeaderButton
        :isDropdown="true"
        text="Relatórios"
        type="dark"
        icon="/img/icons/icons8/ios/graph-report.png">
        <PageHeaderButtonDropItem text="Notas da SEFAZ" icon="/img/icons/excel.png" @click="handleModalExportEntryLaunch" />
      </PageHeaderButton>
    </PageHeader>

    <div class="container-fluid">
      <div class="mt-4 ml-2 mb-n2">
        <div style="display: flex; align-items: center">
          <i :class="params.type !== 0 && 'opacity-25'" @click.prevent="listCard"
           class="pointer fa-regular fa-hard-drive fa-2x"></i>
          <i :class="params.type !== 1 && 'opacity-25'" @click.prevent="listTable"
           class="pointer fa-solid fa-table-list fa-2x ml-1"></i>
        </div>
      </div>
      <Filters
        @handleFilterDate="handleFilterDate"
        @fetch="init"
        @clearFilter="clearFilter()"
        @handleFilterAdvancedStatus="handleFilterAdvancedStatus"
        @resetAdvancedFilter="resetAdvancedFilter"
        :dontHasCompanyPlant="dontHasCompanyPlant"
        :EntryLaunchStatusEnum="EntryLaunchStatusEnum"
      />
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="index in 3" :key="index" />
      </div>
      <!-- Dados vazios -->
      <PuzlEmptyData v-if="!$_items.length && !loadingSkeleton" />
      <Cards v-if="$_items.length && !loadingSkeleton && params.type === 0"
        :listType="params.type"
        :items="$_items"
        :company_plant_buyer="$_company_plant_buyer"
        @handleModalImportEntryLaunch="handleModalImportEntryLaunch"
        @handleCancellation="handleCancellation"
      />
      <div class="row card-wrapper" v-if="params.type === 1 && !loadingSkeleton && $_items.length">
        <Table
          :listType="params.type"
          :items="$_items"
          :company_plant_buyer="$_company_plant_buyer"
          @handleModalImportEntryLaunch="handleModalImportEntryLaunch"
          @handleCancellation="handleCancellation"
          @selectAll="selectAll"
          @deselectAll="deselectAll"
        />
      </div>
      <loading-pagination :show="loading && !loadingSkeleton" />
      <div class="floating-button" v-show="xmlsToDownload.length">
        <base-button style="border-radius: 0px !important; background: #F2F4F9;"
          block
          class="border-0 text-primary">
          <div class="mt--2">
            <img @click.prevent="handleModalImportEntryLaunch(false)" class="mb-1 mr-2" src="/img/icons/icons8/ios/downloading-updates.png" width="16">
            <span @click.prevent="handleModalImportEntryLaunch(false)" style="font-size: 14px; font-weight: 400;"> Lançar Selecionadas </span>
            <span @click.prevent="handleModalImportEntryLaunch(false)" class="badge text-white" style="background: #1A70B7; font-size: 14px;">{{ xmlsToDownload.length }} / {{ $_items.length }}</span>
            <span class="mx-1" style="font-size: 22px;"> | </span>
            <img @click="deselectAll" class="ml-2 mb-1" src="/img/icons/icons8/ios/close-window.png" width="16">
          </div>
        </base-button>
      </div>
    </div>
    <ModalImportEntryLaunch ref="modalImportEntryLaunch" />
    <ModalInitialFilterSefaz @selected="selectedCompanyPlant" ref="modalInitialFilterSefaz" />
    <ModalSearchEntryLaunchByAccessKey ref="modalSearchEntryLaunchByAccessKey" />
    <ModalFilterExportSefaz ref="modalFilterExportSefaz" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { PageHeader, PageHeaderButton, PageHeaderButtonDropItem } from "@/components";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import SkeletonPuzlFullWidth from "@/components/SkeletonPuzlFullWidth";
import moment from "moment";
import ModalImportEntryLaunch from "./_ModalImportEntryLaunch.vue";
import ModalSearchEntryLaunchByAccessKey from "./_ModalSearchEntryLaunchByAccessKey.vue";
import ModalInitialFilterSefaz from "./_ModalInitialFilterSefaz";
import ModalFilterExportSefaz from "./_ModalFilterExportSefaz";
import { formatErrorValidation } from "@/plugins";
import {EntryLaunchStatusEnum} from "@/enum/EntryLaunchStatusEnum";
import Table from "./_Table";
import Cards from "./_Cards";
import Filters from './Filters/_Filters';

export default {
  name: "ListExternalEntryLaunch",
  mixins: [cursorPaginate],
  components: {
    SkeletonPuzlGrid,
    PuzlEmptyData,
    LoadingPagination,
    SkeletonPuzlFullWidth,
    ModalImportEntryLaunch,
    ModalInitialFilterSefaz,
    ModalSearchEntryLaunchByAccessKey,
    ModalFilterExportSefaz,
    Table,
    Cards,
    PageHeader,
    PageHeaderButton,
    PageHeaderButtonDropItem,
    Filters,
  },
  data() {
    return {
      loadingSkeleton: true,
      loadingCompanyPlantBuyer: true,
      loading: false,
      loadingDetails: false,
      loadingStore: false,
      filter: {},
      paginate: {
        hasMore: false,
        cursor: 0,
      },
      range: {
        start: moment().startOf("month").format("YYYY-MM-DD hh:mm:ss"),
        end: moment().endOf("month").format("YYYY-MM-DD hh:mm:ss"),
      },
      params: {
        per_page: 3,
        type: 0,
      },
      EntryLaunchStatusEnum: EntryLaunchStatusEnum,
    };
  },
  computed: {
    ...mapGetters({
      $_items: "entryLaunchExternal/fetch",
      $_company_plant_buyer: "companyPlantBuyer/show",
    }),
    xmlsToDownload() {
      return this.$_items.filter((item) => item.checked === 1);
    },
  },
  methods: {
    init(filter = null) {
      if(this.dontHasCompanyPlant()) {
        return;
      }
      this.startCursor(filter);
      this.$store
        .dispatch("entryLaunchExternal/fetch", {
          company_plant_buyer_ein: this.$_company_plant_buyer.issuer.ein
            .replaceAll(".", "")
            .replace("-", "")
            .replace("-", "")
            .replace("/", ""),
          next_page: this.paginate.nextUrl,
          filter: this.filter.range ? this.filter : { range: this.range },
        })
        .then((response) => {
          this.resolveCursor(response);
        });
    },
    selectAll() {
      this.$_items.map(function (item) {
        if(item.not_registered_items === 0 && ![EntryLaunchStatusEnum.SEFAZ_CANCELLATION, EntryLaunchStatusEnum.CANCELLED].includes(item.status)) {
          item.checked = 1;
        }
      });
    },
    deselectAll() {
      this.$_items.map(function (item) {
        item.checked = 0;
      });
    },
    handleFilterDate(filter) {
      this.range = filter;
      this.init({});
    },
    clearFilter() {
      if(this.dontHasCompanyPlant()) {
        return;
      }
      this.loadingSkeleton = true;
      this.paginate = {
        hasMore: false,
        nextUrl: null,
      };
      this.filter = {};
      this.range = {
        start: moment().startOf("month").format("YYYY-MM-DD hh:mm:ss"),
        end: moment().endOf("month").format("YYYY-MM-DD hh:mm:ss"),
      };
      this.init();
    },
    handleModalImportEntryLaunch(item = null) {
      if (item.not_registered_items > 0) {
        return this.store(item);
      }
      this.$refs.modalImportEntryLaunch.openModal(item ? item : this.xmlsToDownload);
    },
    store(item, custom_entry_date = null) {
      this.loadingStore = true;
      item.company_plant_buyer_id = this.$_company_plant_buyer.id || this.$route.params.company_plant_buyer_id;
      item.company_plant_id = this.$_company_plant_buyer.company_plant.id;
      item.custom_entry_date = custom_entry_date ? custom_entry_date : item.entry_date;
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });
      this.$store
        .dispatch("entryLaunchExternal/add", item)
        .then((response) => {
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
        })
        .catch((error) => {
          if (error.status) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
            if (error.data.message.includes("não conciliados")) {
              this.$router.push(
                `/configuration/entry-launch/external-create/${item.company_plant_id}/${item.company_plant_buyer_id}/${item.id}`
              );
            }
          } else if (error.response && error.response.status && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
          this.loadingStore = false;
          this.$Progress.finish();
        });
    },
    storeMany(custom_entry_date = null) {
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });
      let payload = {
        custom_entry_date: custom_entry_date,
        xmls: this.xmlsToDownload,
        company_plant_buyer_id: this.$_company_plant_buyer.id || this.$route.params.company_plant_buyer_id,
        company_plant_id: this.$_company_plant_buyer.company_plant.id,
      };
      this.$store
        .dispatch("entryLaunchExternal/addMany", payload)
        .then((response) => {
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.init({});
        })
        .catch((error) => {
          if (error.status) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          }
          else if (error.response && error.response.status && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
        });
    },
    getDetails(item) {
      this.loadingDetails = true;
      this.$store.dispatch("entryLaunchExternal/getDetails", item.access_key).then((response) => {
        this.loadingDetails = false;
        item.details = {
          manifest: response.data,
        };
      });
    },
    handleCancellation(id, index) {
      return this.$Swal
        .confirmAction(`Deseja ${!this.filter.advanced ? "cancelar" : "restaurar"} este registro?`, ["Sim", "Não"])
        .then((result) => {
          if (result.isConfirmed) {
            let loader = this.$loading.show();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação.",
            });
            this.$store.dispatch("entryLaunch/cancellation", id).then((response) => {
              this.$notify({ type: response.error_type, message: response.message });
              this.$_items.splice(index, 1);
            }).catch((error) => {
                this.$notify({
                  type: "danger",
                  message: error.data.message,
                });
            }).finally(() => {
              loader.hide();
            });
        }
      });
    },
    handleFilterAdvancedStatus(filter) {
      if(this.dontHasCompanyPlant()) {
        return true;
      }
      this.loadingSkeleton = true;
      this.paginate = {
        hasMore: false,
        nextUrl: null,
      };
      this.filter.advanced = filter;
      this.init({});
      return false;
    },
    resetAdvancedFilter() {
      delete this.filter.advanced;
      this.init({});
    },
    getColor(item) {
      switch (true) {
        case item.status === EntryLaunchStatusEnum.CANCELLED || item.status === EntryLaunchStatusEnum.SEFAZ_CANCELLATION:
          return "danger";
        case (item.status !== EntryLaunchStatusEnum.CANCELLED || item.status !== EntryLaunchStatusEnum.SEFAZ_CANCELLATION) && item.not_registered_items !== EntryLaunchStatusEnum.PENDING:
          return "warning";
        case (item.status !== EntryLaunchStatusEnum.CANCELLED || item.status !== EntryLaunchStatusEnum.SEFAZ_CANCELLATION) && item.not_registered_items === EntryLaunchStatusEnum.PENDING:
          return "success";
      }
    },
    handleModalInitialFilterSefaz() {
      this.$refs.modalInitialFilterSefaz.openModal(true);
    },
    selectedCompanyPlant(value) {
      this.loadingCompanyPlantBuyer = true;
      this.loadingSkeleton = true;
      this.$store.dispatch("companyPlantBuyer/show", value).then(() => {
        this.init({});
        this.loadingCompanyPlantBuyer = false;
      });
    },

    /**
     * Checa se não há central/cnpj comprador selecionado
     *
     * @returns void
     */
    dontHasCompanyPlant() {
      if(!this.$_company_plant_buyer || !this.$_company_plant_buyer.issuer) {
        this.$notify({
          type: 'danger',
          message: 'É necessário selecionar uma central para continuar!',
        });
        return true;
      }
      return false;
    },

    handleModalSearchEntryLaunchByAccessKey() {
      this.$refs.modalSearchEntryLaunchByAccessKey.openModal();
    },

    handleModalExportEntryLaunch() {
      this.$refs.modalFilterExportSefaz.openModal();
    },
    listCard() {
      this.params.type = 0;
      this.deselectAll();
    },
    listTable() {
      this.params.type = 1;
      this.deselectAll();
    }
  },
  mounted() {
    if(this.$route.params.company_plant_buyer_id > 0) {
      this.$store.dispatch("companyPlantBuyer/show", this.$route.params.company_plant_buyer_id).then(() => {
        this.init();
        this.loadingCompanyPlantBuyer = false;
      });
    } else {
      this.$store.commit("companyPlantBuyer/SHOW", {});
      this.$store.commit("entryLaunchExternal/SET", []);
      this.loadingCompanyPlantBuyer = false;
      this.loadingSkeleton = false;
    }
  },
};
</script>

<style scoped>
.main-icon-menu {
  min-width: 18px;
}
.entry-launch-custom-float {
  position: fixed;
  bottom: 260px;
  right: 40px;
  background-color: #1a70b7 !important;
  text-align: center;
  -webkit-box-shadow: 2px 2px 3px #999;
  box-shadow: 2px 2px 3px #999;
}
.gray-divider {
  background-color: #2b2d32;
  opacity: 0.1;
  color: #2b2d32;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}
.floating-button {
  position: fixed;
  z-index: 3;
  overflow: hidden;
  min-width: 250px;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  max-height: 38px;
}
.floating-button div.card-with-box-shadow {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
</style>
