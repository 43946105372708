<template>
  <div>
    <!-- Header -->
    <div class="header py-2">

    </div>
    <!-- Page content -->
    <div class="container pt-lg-8">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">

            <div class="card-body px-lg-5">
              <div class="text-center text-muted">
                <img src="/img/brand/logo-puzl-horizontal-cores.png" height="90">
              </div>
              <div class="text-center my-3">Recuperar Senha</div>
              <h4 class="text-center mt-2 mb-0 small">
                <span :class="this.flag ? 'text-success' : 'text-danger'"> {{message}} </span> 
              </h4>
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input alternative
                              class="mb-3 m-3"
                              name="Email"
                              :rules="{required: true, email: true}"
                              prepend-icon="ni ni-email-83"
                              placeholder="Email"
                              v-model="form.email">
                  </base-input>

                  <base-input alternative
                              class="mb-3 m-3"
                              name="Password"
                              :rules="{required: true, min: 6}"
                              prepend-icon="ni ni-lock-circle-open"
                              type="password"
                              placeholder="Password"
                              v-model="form.password">
                  </base-input>
                  <div class="text-center">
                    <base-button type="primary" v-show="loading" :loading="loading">Carregando</base-button>
                    <base-button type="primary" native-type="submit" v-show="!loading">Recuperar Senha</base-button>
                  </div>
                  <div class="text-center my-3">
                    <router-link :to="{
                    path:'/login',
                    name:'Login',}" class="text-dark">
                      <small>Login</small>
                    </router-link>
                  </div>
                </form>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        form: {
          email: '',
          password: '',
          token: '',
        },
        message:'',
        loading:false,
        flag: false,
      };
    },
    beforeRouteEnter(to, from, next) {
        if (!to?.query?.token) {
            next({ name: 'login' });
         }
        next();
    },
    created() {
        this.form.token = this.$route?.query?.token || '';
    },
    methods: {
      onSubmit () {
        this.loading = true
        this.$store.dispatch('auth/resetPassword', this.form)
          .then(response => {
            this.flag = true
            this.loading = false
            this.message='Senha alterada com sucesso.'
        }).catch(response =>{
          this.flag = false
          this.loading = false
          this.message='Token de redefinição de senha ou e-mail inválido.'
        })
      },
    }
  };
</script>
