export const getPuzlToken = () => sessionStorage.getItem('puzl_token');
export const setPuzlToken = token => sessionStorage.setItem('puzl_token', token);
export const setExpiresIn = expiresIn => sessionStorage.setItem('expires_in', expiresIn);
export const getExpiresIn = () => sessionStorage.getItem('expires_in');
export const setPreferredViewMode = preferredViewMode => localStorage.setItem('preferred_view_mode', preferredViewMode);
export const getPreferredViewMode = () => localStorage.getItem('preferred_view_mode');
export const clearStorage = () => {
  sessionStorage.removeItem('puzl_token');
  sessionStorage.removeItem('expires_in');
  sessionStorage.removeItem('puzl_user');
  localStorage.removeItem('preferred_view_mode');
}
