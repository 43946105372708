/**
 * Type para incluir
 *
 * @typedef {Object} OperationSourceCreateType
 * @property {?number} id
 * @property {string} name
 * @property {boolean} status - [false-INATIVO, true-ATIVO]
 * @property {number} type
 * @property {boolean} is_generate_stock
 * @property {boolean} is_generate_financial
 * @property {boolean} is_entry_value
 * @property {boolean} is_computable
 * @property {boolean} is_generate_tax_book
 * @property {?Array<string>} tax_regimes
 * @property {?Array<number>} chart_accounts
 * @property {?number} product_service_hub_type_id
 * @property {?number} document_template_id
 */
export const OperationSourceCreateType = {};

/**
 * Inicializar OperationSourceCreateType
 *
 * @returns {OperationSourceCreateType}
 */
export const initOperationSourceCreateType = () => {
  return {
    name: "",
    status: true,
    type: 0,
    is_generate_stock: false,
    is_generate_financial: false,
    is_entry_value: false,
    is_computable: false,
    is_generate_tax_book: false,
    tax_regimes: [],
    chart_accounts: [],
    product_service_hub_type_id: null,
    document_template_id: null
  };
};
