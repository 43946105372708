import { cmcAdjustmentUrls } from ".";
import httpClient from "../../../../shared/libraries/httpClient";
import { handleUnexpectedStatusCode } from "../../../../shared/services";
import { HttpStatusEnum } from "../../../../shared/enums";
import { CmcAdjustmentViewType } from "../types";

/**
 * Localizar por ID
 *
 * @param {number|string} id
 * @returns {Promise<?CmcAdjustmentViewType>}
 */
const execute = async (id) => {
  const url = `${cmcAdjustmentUrls.BASE}/${id}`;
  const res = await httpClient.get(url);

  switch (res.status) {
    case HttpStatusEnum.HTTP_OK:
      return res.data.data;
    case HttpStatusEnum.HTTP_NOT_ACCEPTABLE:
      return null;
    default:
      handleUnexpectedStatusCode(res);
  }
};

export default { execute };
