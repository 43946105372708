<template>
<div>
    <modal :show.sync="modal.create">
        <template slot="header">
            <h5 class="modal-title pl-3">{{ modal.title }}</h5>
        </template>
        <div>
            <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
                <form class="needs-validation" @submit.prevent="handleSubmit(update)" autocomplete="off">
                    <div class="form-group row m-0 p-0">
                        <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                            Status
                            <span class="text-danger">&nbsp;*</span>
                        </label>
                        <div class="col-md-7 pt-2">
                            <base-switch type="success" offText="inativo" onText="ativo" class="success"
                                v-model="$_entityBankAccount.status"></base-switch>
                        </div>
                    </div>

                    <div class="form-group row m-0 p-0">
                        <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                            Nome <span class="text-danger">&nbsp;*</span>
                        </label>
                        <div class="col-md-7 mb-1">
                            <validation-provider rules="required" v-slot="{errors}">
                            <base-input input-group-classes="input-group-sm">
                                <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    v-model="$_entityBankAccount.name"
                                    :class="errors[0] ? 'is-invalid' : 'is-valid'" />
                            </base-input>
                            </validation-provider>
                        </div>
                    </div> 

                    <div class="form-group row m-0 p-0">
                        <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                            Chave <span class="text-danger">&nbsp;*</span>
                        </label>
                        <div class="col-md-7 mb-1">
                            <validation-provider rules="required" v-slot="{errors}">
                            <base-input input-group-classes="input-group-sm">
                                <puzl-select 
                                    v-model="$_entityBankAccount.key_type" 
                                    :items="[
                                        {id: 0, name: 'Cpf/Cnpj'}, 
                                        {id: 1, name: 'Telefone'},
                                        {id: 2, name: 'E-mail'},
                                        {id: 3, name: 'Aleatória'},
                                    ]"
                                    @change="clearKeyValue()"
                                />
                            </base-input>
                            </validation-provider>
                        </div>
                    </div> 

                    <div class="form-group row m-0 p-0">
                        <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                            Código <span class="text-danger">&nbsp;*</span>
                        </label>
                        <div class="col-md-7 mb-1">
                            <validation-provider rules="required" v-slot="{errors}">
                            <base-input input-classes="form-control-sm">
                                <div v-if="$_entityBankAccount.key_type == keyType['ein']['type']">
                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        v-model="$_entityBankAccount.key_value"
                                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                                        v-mask="['###.###.###-##', '##.###.###/####-##']"
                                    />
                                </div>
                                <div v-else-if="$_entityBankAccount.key_type == keyType['phone']['type']">
                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        v-model="$_entityBankAccount.key_value"
                                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                                        v-mask="['(##) ####-####', '(##) #####-####']"
                                    />
                                </div>
                                <div v-else-if="$_entityBankAccount.key_type == keyType['email']['type']">
                                    <input
                                        type="email"
                                        class="form-control form-control-sm"
                                        v-model="$_entityBankAccount.key_value"
                                        v-mask=""
                                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                                    />
                                </div>
                                <div v-else-if="$_entityBankAccount.key_type == keyType['random']['type']">
                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        v-model="$_entityBankAccount.key_value"
                                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                                        v-mask=""
                                        maxlength="80"
                                    />
                                </div>
                            </base-input>
                            </validation-provider>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <base-button type="secondary" @click="closeModal()">
                            Cancelar
                        </base-button>
                        <base-button native-type="submit" type="success" :loading="loadingStore">
                            Salvar
                        </base-button>
                    </div>
                </form>
            </validation-observer>
        </div>
    </modal>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import PuzlSelect from "@/components/PuzlSelect";
const { formatErrorValidation } = require("@/plugins");

export default {
    name: "ModalEditPix",
    components: { PuzlSelect },
    data() {
        return {
            modal: {
                title: 'Editar Pix',
                create: false,
            },
            keyType: {
                'ein': {
                    name: "Cpf/cnpj",
                    type: 0,
                },
                'phone': {
                    name: "Telefone",
                    type: 1,
                },
                'email': {
                    name: "E-mail",
                    type: 2,
                },
                'random': {
                    name: "Aleatória",
                    type: 3,
                },
            },
            loadingStore: false,
            loadingBanks: true,
        };
    },
    computed: {
        ...mapGetters({
            $_banks: 'bank/fetch',
            $_entityBankAccount: 'entityPixAccount/show'
        }),
    },
    mounted() {
        this.$refs.formValidator.validate();
        this.$store.dispatch('bank/fetchItems').then(() => {
            this.loadingBanks = false;
        });
    },
    methods: {
        closeModal() {
            this.modal.create = false;
        },
        handleEditModal(id) {
            this.$Progress.start()
            this.modal.create = true;
            this.$store.dispatch('entityPixAccount/show', id)
                .then((response) => {
                    this.modal.create = true;
                    this.$Progress.finish();
                }).catch((error) => {
                    this.$notify({
                        type: error.data.error_type,
                        message: error.data.message
                    })
                    this.$Progress.finish();
                })
        },
        update() {
            if (this.$_entityBankAccount.key_type == this.keyType.ein.type && this.$_entityBankAccount.key_value.length < 14) {
                this.$notify({
                    type: 'warning',
                    message: 'Código do tipo Cpf/cnpj deve ter no mínimo 11 dígitos'
                });
                return;
            }
            this.$Progress.start()
            this.loadingStore = true;
            this.$store.dispatch('entityPixAccount/update', this.$_entityBankAccount)
                .then(response => {
                    this.loadingStore = false;
                    this.modal.create = false;
                    this.$Progress.finish();
                    this.$emit('fetchEntityAccount');
                    this.$notify({
                        type: response.error_type,
                        message: response.message
                    })
                })
                .catch(error => {
                    if (error.status == 200) {
                        this.$notify({
                            type: 'danger',
                            message: error.data.message
                        })
                    } else if (error.response && error.response.status === 422) {
                        let errors = formatErrorValidation(error.response.data.errors)
                        this.$notify({
                            type: 'danger',
                            message: errors
                        })
                    }
                    this.$Progress.finish();
                    this.loadingStore = false;
                })
        },
        clearKeyValue() {
            this.$_entityBankAccount.key_value = null;
        },
    },
};
</script>

<style scoped></style>
