import { createAxios } from "../../../../plugins/axios";
import * as types from "./mutation_types";

const endPoint = "/administrative/dre";
export default {
  namespaced: true,
  state: {
    items: [],
    chart_accounts: [],
  },
  getters: {
    fetch: (state) => state.items,
    /**
     * plano de contas exibidos no modal de detalhamento
     * @param state
     * @returns {[]}
     */
    getListChartAccounts: (state) => state.chart_accounts,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload;
    },
    [types.SET_CHART_ACCOUNTS](state, payload) {
      state.chart_accounts = payload;
    },
  },
  actions: {
    fetchItems({commit}, year) {
      return createAxios()
        .patch(`${endPoint}/${year}`)
        .then(({data}) => {
          commit(types.SET, data.data)
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    postUpdateChartAccount({commit}, params) {
      return createAxios()
        .post(`${endPoint}/chart-account/details/${params.entry_launch_item_id}/${params.chart_account_id}`)
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getStatistics({commit}, params) {
      return createAxios()
        .patch(`${endPoint}/statistics/${params.month}/${params.year}`, params)
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getChartAccountDetails({commit}, params) {
      return createAxios()
        .patch(`${endPoint}/chart-account/details`, params)
        .then(({data}) => {
          commit(types.SET_CHART_ACCOUNTS, data.data)
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
};
