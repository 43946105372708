import * as types from './mutation_types'
import {destroy, update} from '@/store/baseStore'
import { createAxios } from "@/plugins/axios";
const endPoint = '/configuration/user/alert-group'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
  },
  getters:{
    show: state => state.item,
    fetch: state => state.items,
  },
  mutations: {
    [types.SET] (state, payload) {
      state.items = payload
    },
    [types.SHOW] (state, payload) {
      state.item = payload
    },
    [types.PUSH] (state, payload){
      state.items.push(payload)
    },
    [types.UPDATE] (state, payload) {
      update(state, payload)
    },
    [types.DESTROY] (state, id) {
      destroy(state,id)
    },
  },
  actions: {
    fetchItems({commit}) {
      return createAxios().get(endPoint)
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getAlertGroupsWithAlerts({commit, _}, id) {
      return createAxios().get(`${endPoint}/with-alerts/${id}`)
        .then(({data}) =>{
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getAllSimplified({commit}) {
      return createAxios().get(`${endPoint}/get-all-simplified`)
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    show({commit, _}, id) {
      return createAxios().get(`${endPoint}/${id}`)
        .then(({data}) =>{
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    add({commit, _}, payload) {
      return createAxios().post(endPoint, payload)
        .then(({data}) =>{
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    update({commit}, payload) {
      return createAxios().put(`${endPoint}/${payload.id}`,  payload)
        .then(({data}) =>{
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    destroy({commit, _}, payload) {
      return createAxios().delete(`${endPoint}/${payload.id}/${payload.replacementId}`)
        .then(({data}) =>{
          commit(types.DESTROY, payload.id)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    showUserAlertGroup({commit, _}, id) {
      return createAxios().get(`${endPoint}/show/user-alert-group/${id}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    storeUserAlertGroup({_}, payload) {
      return createAxios().post(`${endPoint}/add-user-alert-group`, payload)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
  }
}
