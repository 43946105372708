import { BaseListFilterType, initBaseListFilterType } from "../../../../shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} CmcListFilterExtType
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & CmcListFilterExtType)} CmcListFilterType
 */
export const CmcListFilterType = {};

/**
 * Inicializar CmcListFilterType
 *
 * @returns {CmcListFilterType}
 */
export const initCmcListFilterType = () => {
  return {
    ...initBaseListFilterType(),
    order_by: [{ column: "cmcs.id", is_desc: false }],
    custom_search: {
      columns: [
        "cmc_categories.name",
        "cmc_products.product_name",
        "cmc_suppliers.supplier_name",
        "cmc_groups.group_name",
        "cmc_supplier_origins.origin_name",
      ],
    },
  };
}
