<template>
  <div class="container-fluid">
    <div class="row card-wrapper" v-show="loading">
      <SkeletonPuzlGrid v-for="(skeletons, index) in 3" :key="index"/>
    </div>
    <div v-if="!loading">
      <div class="row card-row">
        <div class="col-md-4 p-1">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-12 text-center">
                  <i class="fa-2x fa-solid fa-coins text-primary"></i>
                </div>
                <div class="col-12 text-center">
                  <h5
                    class="card-title text-uppercase text-muted mb-0 ">Venda total</h5>
                  <span class="numeric font-weight-600">
                  {{ cards.general.total_value | currency() }}
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets-sm col-md-2 p-1">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-12 text-center">
                  <i class="fa-2x fa-solid fa-coins" style="color: orange"></i>
                </div>
                <div class="col-12 text-center">
                  <h5
                    class="card-title text-uppercase text-muted mb-0 ">Preço médio</h5>
                  <span class="numeric font-weight-600">
                  {{ cards.general.average_price | currency() }} / <span style="text-transform: none">m³</span>
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets-sm col-md-2 p-1">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-12 text-center">
                  <i class="fa-2x fa-solid fa-person-digging" style="color: #4d4d4e"></i>
                </div>
                <div class="col-12 text-center">
                  <h5
                    class="card-title text-uppercase text-muted mb-0 ">Obras</h5>
                  <span class="numeric font-weight-600">
                  {{ cards.general.total_contracts }}
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets-sm col-md-4 p-1">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-12 text-center">
                  <i class="fa-2x fa-solid fa-tarp"></i>
                </div>
                <div class="col-12 text-center">
                  <h5
                    class="card-title text-uppercase text-muted mb-0 ">Ticket médio</h5>
                  <span class="numeric font-weight-600">
                  {{ cards.general.average_selling_price | currency() }}
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row card-row">
        <div class="col-md-4 p-1">
          <div class="pointer card card-stats card-max-height-1" @click.prevent="show_concrete = !show_concrete"
               style="min-height: 118px;border: 1px solid #4f40ff">
            <div class="card-body">
              <div class="row">
                <div class="col-12 text-right">
                  <i class=" fa-regular fa-eye"></i>
                </div>
                <div class="col-12 text-center" style="margin-top: -1.6rem">
                  <i class="fa-solid fa-truck-ramp-box fa-2x "></i>
                </div>
                <div class="col-12 text-center">
                  <h5
                    class="card-title text-uppercase text-primary mb-0 font-weight-700">Concreto</h5>
                </div>
                <div class="col-6 text-center">
                  <h6
                    class="card-title text-uppercase text-muted mb-0 ">Total</h6>
                  <span class="numeric font-weight-600">
                  {{ cards.concrete.total_billing | currency() }} /<span style="text-transform: none">m³</span>
                </span>
                </div>
                <div class="col-6 text-center">
                  <h6
                    class="card-title text-uppercase text-muted mb-0 ">Volume produzido</h6>
                  <span class="numeric font-weight-600">
                  {{ cards.concrete.total_volume | toHuman() }} /<span
                    style="text-transform: none">m³</span> ({{ cards.concrete.total_performed_travels }})
                </span>
                </div>
              </div>
              <hr v-if="show_concrete">
              <div class="row" style="margin-top: -20px">
                <div v-if="show_concrete" class="col-md-6 p-1">
                  <div class="card card-stats">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 text-center">
                          <h6
                            class="card-title text-uppercase text-muted mb-0 ">PREÇO MÉDIO DE VENDA</h6>
                          <span class="numeric font-weight-600">
                  {{ cards.concrete.average_selling_price | currency() }} /<span style="text-transform: none">m³</span>
                </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="show_concrete" class="col-md-6 widgets-sm p-1">
                  <div class="card card-stats">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 text-center">
                          <h6
                            class="card-title text-uppercase text-muted mb-0 ">PREÇO MÍNIMO TEÓRICO</h6>
                          <span class="numeric font-weight-600"
                                :class="validIsBigger(cards.concrete.theorical_minimum_price - cards.concrete.average_selling_price)">
                  {{
                              (cards.concrete.theorical_minimum_price - cards.concrete.average_selling_price) | currency()
                            }} /<span style="text-transform: none">m³</span>
                </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row card-row">
                <div v-if="show_concrete" class="col-md-6 p-1">
                  <div class="card card-stats">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 text-center">
                          <h6
                            class="card-title text-uppercase text-muted mb-0 ">Ato da venda</h6>
                          <span class="numeric font-weight-600"
                                :class="validIsBigger(cards.concrete.average_minimum_selling_price - cards.concrete.average_selling_price)">
                    {{
                              cards.concrete.average_minimum_selling_price
                              -
                              cards.concrete.average_selling_price  | currency()
                            }} /<span style="text-transform: none">m³</span>
                </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="show_concrete" class="col-md-6 widgets-sm p-1">
                  <div class="card card-stats">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 text-center">
                          <h6
                            class="card-title text-uppercase text-muted mb-0 ">Referência comercial</h6>
                          <span class="numeric font-weight-600"
                                :class="validIsBigger(cards.concrete.predicted_minimum_price - cards.concrete.average_selling_price)">
                    {{
                              cards.concrete.predicted_minimum_price
                              -
                              cards.concrete.average_selling_price  | currency()
                            }} /<span style="text-transform: none">m³</span>
                </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets-sm col-md-4 p-1">
          <div class="pointer card card-stats card-max-height" @click="show_brutal_margin = !show_brutal_margin"
               style="min-height: 118px;border: 1px solid green">
            <div class="card-body">
              <div class="row">
                <div class="col-12 text-right">
                  <i class="fa-regular fa-eye "></i>
                </div>
                <div class="col-12 text-center" style="margin-top: -1.6rem">
                  <i class="fa-solid fa-money-bill-trend-up fa-2x"></i>
                </div>
                <div class="col-12 text-center">
                  <h5
                    class="card-title text-uppercase text-success mb-0 font-weight-700">Margem bruta</h5>
                </div>
                <div class="col-12 text-center">
                  <h6
                    class="card-title text-uppercase text-muted mb-0 ">Teórico com puzl adjust</h6>
                </div>
                <div class="col-6 text-center">
                  <span class="numeric font-weight-600">
                  {{ cards.margin.theorical_per_m3 | currency() }} /<span style="text-transform: none">m³</span>
                </span>
                </div>
                <div class="col-6 text-center">
                  <span class="numeric font-weight-600">
                  {{ cards.margin.theorical_per_hour | currency() }} /<span style="text-transform: none">h</span>
                </span>
                </div>
              </div>
              <hr v-if="show_brutal_margin">
              <div class="row" style="margin-top: -20px">
                <div v-if="show_brutal_margin" class="col-md-6 p-1">
                  <div class="card card-stats">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 text-center">
                          <h6
                            class="card-title text-uppercase text-muted mb-0 ">PUZL ADJUST</h6>
                          <span class="numeric font-weight-600"
                                :class="validIsBigger( (cards.concrete.average_selling_price - cards.concrete.theorical_not_adjusted) - cards.margin.theorical_per_m3)">
                  {{cards.margin.theorical_per_m3 - (cards.concrete.average_selling_price - cards.concrete.theorical_not_adjusted) | currency() }} /<span
                            style="text-transform: none">m³</span>
                </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="show_brutal_margin" class="col-md-6 widgets-sm p-1">
                  <div class="card card-stats">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 text-center">
                          <h6
                            class="card-title text-uppercase text-muted mb-0 ">REAL CARREGADO</h6>
                          <span class="numeric font-weight-600"
                                :class="validIsBigger( cards.margin.real_per_m3 - cards.margin.theorical_per_m3)">
                  {{ cards.margin.theorical_per_m3 - cards.margin.real_per_m3 | currency() }} /<span
                            style="text-transform: none">m³</span>
                </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row card-row">
                <div v-if="show_brutal_margin" class="col-md-6 p-1">
                  <div class="card card-stats">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 text-center">
                          <h6
                            class="card-title text-uppercase text-muted mb-0 ">Ato da venda</h6>
                          <span class="numeric font-weight-600"
                                :class="validIsBigger((cards.concrete.average_selling_price - cards.concrete.mcc_sale_act).toFixed(2) - (cards.margin.theorical_per_m3))">
                  {{  (cards.margin.theorical_per_m3) - (cards.concrete.average_selling_price - cards.concrete.mcc_sale_act).toFixed(2) | currency() }} /<span
                            style="text-transform: none">m³</span>
                </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="show_brutal_margin" class="col-md-6 widgets-sm p-1">
                  <div class="card card-stats">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 text-center">
                          <h6
                            class="card-title text-uppercase text-muted mb-0 ">Referência comercial</h6>
                          <span class="numeric font-weight-600"
                                :class="validIsBigger( cards.margin.predicted_per_m3 - cards.margin.theorical_per_m3)">
                  {{ cards.margin.theorical_per_m3 - cards.margin.predicted_per_m3 | currency() }} /<span
                            style="text-transform: none">m³</span>
                </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets-sm col-md-4 p-1">
          <div class="pointer card card-stats card-max-height" @click="show_mcc = !show_mcc"
               style="min-height: 118px;border: 1px solid darkorange">
            <div class="card-body">
              <div class="row">
                <div class="col-12 text-right">
                  <i class="fa-regular fa-eye "></i>
                </div>
                <div class="col-12 text-center" style="margin-top: -1.6rem">
                  <i class="fa-2x fa fa-puzzle-piece"></i>
                </div>
                <div class="col-12 text-center">
                  <h5
                    class="card-title text-uppercase mb-0 font-weight-700" style="color: darkorange">MCC</h5>
                </div>
                <div class="col-12 text-center">
                  <h6
                    class="card-title text-uppercase text-muted mb-0 ">Teórico com puzl adjust</h6>
                </div>
                <div class="col-12 text-center">
                  <span class="numeric font-weight-600">
                  {{ cards.mcc.theorical | currency() }} /<span style="text-transform: none">m³</span>
                </span>
                </div>
              </div>
              <hr v-if="show_mcc"/>
              <div class="row" style="margin-top: -20px">
                <div v-if="show_mcc" class="col-md-6 p-1">
                  <div class="card card-stats">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 text-center">
                          <h6
                            class="card-title text-uppercase text-muted mb-0 ">PUZL ADJUST</h6>
                          <span class="numeric font-weight-600" :class="validIsBigger(   cards.mcc.theorical - cards.concrete.theorical_not_adjusted )">
                  {{ (cards.mcc.theorical - Number(cards.concrete.theorical_not_adjusted)).toFixed(2) | currency() }} /<span style="text-transform: none">m³</span>
                </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="show_mcc" class="col-md-6 widgets-sm p-1">
                  <div class="card card-stats">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 text-center">
                          <h6
                            class="card-title text-uppercase text-muted mb-0 ">REAL CARREGADO</h6>
                          <span class="numeric font-weight-600" :class="validIsBigger(cards.mcc.theorical - cards.mcc.real )">
                  {{ (cards.mcc.theorical - cards.mcc.real).toFixed(2) | currency() }} /<span style="text-transform: none">m³</span>
                </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row card-row">
                <div v-if="show_mcc" class="col-md-6 p-1">
                  <div class="card card-stats">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 text-center">
                          <h6
                            class="card-title text-uppercase text-muted mb-0 ">Ato da venda</h6>
                          <span class="numeric font-weight-600"
                                :class="validIsBigger( cards.mcc.theorical - cards.concrete.mcc_sale_act)">
                     {{ cards.mcc.theorical - cards.concrete.mcc_sale_act | currency() }} /<span
                            style="text-transform: none">m³</span>
                </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="show_mcc" class="col-md-6 widgets-sm p-1">
                  <div class="card card-stats">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 text-center">
                          <h6
                            class="card-title text-uppercase text-muted mb-0 ">Referência comercial</h6>
                          <span class="numeric font-weight-600"
                                :class="validIsBigger(cards.mcc.theorical - cards.mcc.predicted)">
                     {{ cards.mcc.theorical - cards.mcc.predicted | currency() }} /<span
                            style="text-transform: none">m³</span>
                </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row card-row" >
        <div class="col-md-4 p-1">
          <div class="card card-stats" style="border: 0.5px solid indigo">
            <div class="card-body">
              <div class="row">
                <div class="col-12 text-center">
                  <i class="fa-regular fa-window-maximize"></i>
                </div>
                <div class="col-12 text-center">
                  <h5
                    class="card-title text-uppercase text-indigo mb-0 font-weight-700">Valores avulsos</h5>
                </div>
                <div class="col-12 text-center">
                  <span class="numeric font-weight-600">
                  {{ cards.additional.single_value | currency() }}
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 widgets-sm p-1">
          <div class="card card-stats" style="border: 0.5px solid red">
            <div class="card-body">
              <div class="row">
                <div class="col-12 text-center">
                  <i class="fa-solid fa-percent"></i>
                </div>
                <div class="col-12 text-center">
                  <h5
                    class="card-title text-uppercase text-danger mb-0 font-weight-700">Adicionais de concreto</h5>
                </div>
                <div class="col-12 text-center">
                  <span class="numeric font-weight-600">
                  {{ cards.additional.total_travels_price | currency() }} ({{ cards.additional.total_travels }})
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 widgets-sm p-1">
          <div class="card card-stats" style="border: 0.5px solid orange">
            <div class="card-body">
              <div class="row">
                <div class="col-12 text-center">
                  <i class="fa-solid fa-percent"></i>
                </div>
                <div class="col-12 text-center">
                  <h5
                    class="card-title text-uppercase text-warning mb-0 font-weight-700">Adicionais de serviços</h5>
                </div>
                <div class="col-12 text-center">
                  <span class="numeric font-weight-600">
                  {{ 0 | currency() }}
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="loadingPayloadService"  class="card-row row p-1">
          <SkeletonPuzlFullWidth/>
      </div>
      <div class="row card-row p-1">
        <div v-if="!loadingPayloadService" class="col-md-12 card bg-gradient-dark border-0 mb-30">
          <!-- Serviços -->
          <div class="card-body pb-2 row">
            <div class="col-md-3">
              <base-dropdown>
                <base-button
                  :loading="loadingPayloadService"
                  style="font-size: 1.25rem"
                  size="md"
                  type="secondary"
                  slot="title-container"
                  class="active dropdown-toggle bg-transparent text-white border-0 pl-0 pt-0 pb-1"
                >
                  {{ currentService }}
                </base-button>
                <a @click.prevent="handlePayloadService('Serviços')" v-if="currentService !== 'Serviços'"
                   class="dropdown-item"> Serviços </a>
                <div @click.prevent="handlePayloadService(service.service_name)" v-for="(service, index) in services"
                     :key="index">
                  <a v-if="currentService !== service.service_name" class="dropdown-item"> {{ service.service_name }} </a>
                </div>
              </base-dropdown>
              <h2 class="mb-2">{{ cards.service.total_cost | currency() }}</h2>
              <h4 class="mb-0 font-weight-normal">{{ cards.service.total_services }} serviços</h4>
              <h4 class="mb-0 font-weight-normal">{{ cards.service.total_volume ? cards.service.total_volume : 0 }}
                m³</h4>
              <h4 class="mb-0 font-weight-normal">{{ cards.service.total_performed_cycle }}</h4>
              <div class="card bg-gradient-light border-0 text-center mt-2 shadow-none">
                <div class="card-body pt-2 pb-2">
                  <h4 class="mb-0">{{ cards.service.average_total_cost | currency() }}</h4>
                  <h4 class="mb-0">{{ cards.service.average_total_m3_cost | currency() }} / m³</h4>
                  <h4 class="mb-0">{{ cards.service.average_total_hour_cost | currency() }} / hr</h4>
                </div>
              </div>
            </div>

            <!-- Mínimo -->
            <div class="col-md-3">
              <h2 class="mb-1">Mínimo</h2>
              <h2 class="mb-2">{{ cards.service.total_minimum_cost | currency() }}</h2>
              <h4 class="mb-5 font-weight-normal">{{ cards.service.total_minimum_services }} serviços</h4>
              <div class="text-center">
                <h4 style="margin-bottom: 0.57rem" class="font-weight-normal">{{ cards.service.minimum_percentage }}%</h4>
                <base-progress type="success" :value="cards.service.minimum_percentage"></base-progress>
              </div>
              <div class="card bg-gradient-light border-0 text-center mt-2 shadow-none">
                <div class="card-body pt-2 pb-2">
                  <h4 class="mb-0 ">{{ cards.service.average_minimum_cost | currency() }}</h4>
                </div>
              </div>
            </div>

            <!-- m3 -->
            <div class="col-md-3">
              <h2 class="card-title mb-1">/ m³</h2>
              <h2 class="mb-2">{{ cards.service.total_m3_cost | currency() }}</h2>
              <h4 class="mb-5 font-weight-normal">{{ cards.service.total_m3_volume }} m³</h4>
              <div class="text-center">
                <h4 style="margin-bottom: 0.57rem" class="font-weight-normal">{{ cards.service.m3_percentage }}%</h4>
                <base-progress type="success" :value="cards.service.m3_percentage"></base-progress>
              </div>
              <div class="card bg-gradient-light border-0 text-center shadow-none">
                <div class="card-body pt-2 pb-2">
                  <h4 class="mb-0 ">{{ cards.service.average_m3_cost | currency() }} / m³</h4>
                </div>
              </div>
            </div>

            <!-- hora -->
            <div class="col-md-3">
              <h2 class="card-title mb-1">/ hora</h2>
              <h2 class="mb-2">{{ cards.service.total_hour_cost | currency() }}</h2>
              <h4 class="mb-5 font-weight-normal">{{ cards.service.total_hour_services }}</h4>
              <div class="text-center">
                <h4 style="margin-bottom: 0.57rem" class="font-weight-normal">{{ cards.service.hour_percentage }}%</h4>
                <base-progress type="success" :value="cards.service.hour_percentage"></base-progress>
              </div>
              <div class="card bg-gradient-light border-0 text-center shadow-none">
                <div class="card-body pt-2 pb-2">
                  <h4 class="mb-0 ">{{ cards.service.average_hour_cost | currency() }} / hr</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import SkeletonPuzlFullWidth from "@/components/SkeletonPuzlFullWidth";

export default {
  name: "DashboardCards",
  components: {
    SkeletonPuzlGrid,
    SkeletonPuzlFullWidth
  },
  computed: {
    ...mapGetters({
      loading: "commercialDashboard/loading",
      cards: "commercialDashboard/cards",
      services: "service/activeItems"
    })
  },
  data() {
    return {
      loadingPayloadService: true,
      currentService: "Serviços",
      show_concrete: false,
      show_brutal_margin: false,
      show_mcc: false,
    };
  },
  methods: {
    validIsBigger(value) {
      if (value.toFixed(2) == 0) {
        return 'text-muted'
      }
      if (value < 0) {
        return 'text-success'
      }
      return 'text-danger'
    },
    handlePayloadService(val) {
      this.currentService = val;
      val = val === "Serviços" ? null : val;
      this.loadingPayloadService = true;
      this.$emit("fetchServices", val);
    }
  },
  mounted() {
    this.$store.dispatch("service/fetchServices").then(() => {
      this.loadingPayloadService = false;
    });
  }
};
</script>

<style scoped>
@media only screen and (min-width: 600px) {
  .card-max-height {
    max-height: 305px
  }
}
@media only screen and (max-width: 600px) {
  .card-max-height {
    max-height: 460px
  }
}
@media only screen and (max-width: 376px) {
  .card-max-height-1 {
    max-height: 480px
  }
}
@media only screen and (min-width: 376px) {
  .card-max-height-1 {
    max-height: 460px
  }
}
@media only screen and (min-width: 600px) {
  .card-max-height-1 {
    max-height: 305px
  }
}
h3,
h1,
h2,
h4,
h5 {
  color: white;
}

.card-row-static {
  margin-top: 30px;
}

.bg-teal {
  background-color: #3c8ccf !important;
}

.card-inside {
  margin-top: -1rem !important;
}

.bg-light {
  background-color: #373f48 !important;
}

.bg-danger {
  background-color: #e94545 !important;
}

.bg-gradient-info {
  background: linear-gradient(87deg, #308af7 0, #0052bf 100%) !important;
}

.bg-gradient-danger {
  background: linear-gradient(87deg, #f74c3e 0, #cf0909 100%) !important;
}

.bg-gradient-dark {
  background: linear-gradient(87deg, #2f3336 0, #0c0c10 100%) !important;
}

.bg-gradient-indigo-alternative {
  background: linear-gradient(87deg, #9430fd 0, #a904bb 100%) !important;
}

.bg-gradient-orange {
  background: linear-gradient(100deg, #f2b532 0, #f27932 100%) !important;
}

.bg-gradient-light {
  background: linear-gradient(87deg, #959595 0, #4d4d4e 100%) !important;
}
</style>
>
