<template>
  <div>
    <modal :backgroundColor="'#e8e8e8'" :show.sync="isOpen">
      <template slot="header">
        <div class="d-flex align-items-center">
          <h5 class="text-title mb-0">Revalidar conciliação de cartões</h5>
        </div>
      </template>
      <template slot="close-button">
        <img class="pointer mt-2" @click="closeModal" src="/img/icons/icons8/ios/close-window.png" alt="close-window" width="17px" />
      </template>
      <div class="mb-2 px-2 mx-1">
        <div class="row mt-3 pl-1">
          <label class="col-md-5 new-default-black-font"> Ponto de venda</label>
          <div class="col-md-7">
            <PuzlSelect
              v-model="paymentIntermediaryMerchantId"
              label="$description$ ($code$)"
              :labelMask="true"
              :items="items"
              class="select-sm col-md-12 pr-1 new-default-black-font"
            />
          </div>
        </div>

        <div class="row d-flex justify-content-end mt-4 mb-4 mr-1">
          <div @click="closeModal" class="d-flex align-items-center button-cancel col-auto px-4" style="gap: 5px">
            <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="16px" />
            <span style="font-weight: 500"> Cancelar </span>
          </div>
          <div @click="save" class="d-flex align-items-center button-save col-auto py-1 ml-3 px-4" style="gap: 5px">
            <img src="/img/icons/icons8/ios/save_success.png" alt="save_success" width="16px" />
            <span style="font-weight: 500"> Salvar </span>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance } from "vue";
import PuzlSelect from "@/components/PuzlSelect";
import { dialogs, progress } from "../../../../../helpers";
import electronicBankStatementStore from "../../store";

const isOpen = ref(false);
const id = ref(0);
const paymentIntermediaryMerchantId = ref(null);
const items = ref([]);
const store = getCurrentInstance().proxy.$store;

async function openModal(entityBillReceiveId, paymentIntermediaryId) {
  id.value = entityBillReceiveId;
  paymentIntermediaryMerchantId.value = null;
  const filter = {
    payment_intermediary_id: paymentIntermediaryId,
    status: true,
  };
  const response = await store.dispatch("paymentIntermediaryMerchant/fetchItems", filter);
  items.value = response.data;
  isOpen.value = true;
}

async function save() {
  if (!paymentIntermediaryMerchantId.value) {
    return dialogs.notify("danger", "Selecione um ponto de venda para continuar.");
  }
  const loader = progress.showLoader();
  electronicBankStatementStore.actions
    .redeRevalidate(id.value, paymentIntermediaryMerchantId.value)
    .then(() => {
      dialogs.notify("success", "Registro revalidado com sucesso.");
      closeModal();
    })
    .catch((error) => {
      dialogs.notify("danger", error.getFormatedError());
    })
    .finally(() => {
      loader.hide();
    });
  isOpen.value = false;
}

function closeModal() {
  isOpen.value = false;
}

defineExpose({
  openModal,
});
</script>
