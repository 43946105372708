import store from "../../../../shared/libraries/store";
import { dreStateKeys } from "./dreStateKeys";
import { DreListType } from "../types";
/**
 * @param {DreListType} listed
 * @returns {DreListType}
 */
const setListed = (listed) => store.commit(dreStateKeys.LISTED, listed);

/**
 * @param {boolean} isListing
 * @returns {boolean}
 */
const setIsListing = (isListing) => store.commit(dreStateKeys.IS_LISTING, isListing);
const setConfiguration = (data) => {
  store.commit(dreStateKeys.DRE_CONFIGURATION, data)
};
export default {
  setListed,
  setIsListing,
  setConfiguration,
};
