export const SET = 'SET'
export const PUSH = 'PUSH'
export const SHOW = 'SHOW'
export const SHOW_LATEST_PAGE = 'SHOW_LATEST_PAGE'
export const DESTROY = 'DESTROY'
export const UPDATE = 'UPDATE'
export const FETCH_CODE = 'FETCH_CODE'
export const FETCH_CUSTOMER_CONSTRUCTION = 'FETCH_CUSTOMER_CONSTRUCTION'
export const FETCH_ENTITIES = 'FETCH_ENTITIES'
export const PUSH_COMMENT = 'PUSH_COMMENT'
export const DELETE_COMMENT = 'DELETE_COMMENT'
export const SET_MIX_DESIGNS = 'SET_MIX_DESIGNS'
export const SET_GENERAL_RESUME = 'SET_MIX_DESIGNS'
export const FETCH_PRICE_VALIDITY = 'FETCH_PRICE_VALIDITY'
export const FETCH_CONCRETED_PIECE = 'FETCH_CONCRETED_PIECE'
export const FETCH_MINIMUM_COST = 'FETCH_MINIMUM_COST'
export const FETCH_CYCLE_VALUES = 'FETCH_CYCLE_VALUES'
export const FETCH_SITUATION = 'FETCH_SITUATION'
export const FETCH_SITUATIONS = 'FETCH_SITUATIONS'
export const FETCH_COMMERCIAL_APPROVALS = 'FETCH_COMMERCIAL_APPROVALS'



