<template>
  <div>
  <div class="card-body mt-n2 p-0">
    <div class="row">
      <ShadedTable
        :length="$_concretes.length"
        :headers="headers"
        :loading="loadingSkeleton"
      >
        <template v-for="(item, index) in concretesWithAccumulated" :slot="index">
          <ShadedCol align="center">
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1" :title="item.company_plant_name">
              {{ index + 1 }}
            </h5>
          </ShadedCol>
          <ShadedCol align="center">
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.finished_at | parseDate("HH:mm") }}
              <a
                href="#"
                @click.prevent="handleTravelCycle(item)"
                :title="item.finished_at | parseDate('DD MMM YYYY - HH:mm')"
              >
              <img class="mr-1" width="20" src="/img/icons/clock--v1.png"/>
              </a>
            </h5>
          </ShadedCol>
          <ShadedCol>
            <div>
              <h5 class="ml-3">
                <span 
                  class="new-default-black-font font-weight-normal m-0 mt-1"
                  :style="`color: ${item.status === ScheduleTravelEnum.CANCELED ? 'red' : 'black'} !important`"
                >
                  {{ item.sequencial_number_plant_number }}
                </span>
                <a
                  href="#"
                  @click.prevent="$emit('downloadProof', item.uuid)"
                >
                  <img width="28px" src="/img/icons/os.png" />
                </a>
              </h5>
            </div>
          </ShadedCol>
          <ShadedCol align="center">
            <div
              @click.prevent="
                $emit(
                  'downloadDanfe',
                  item.xml_travel_status,
                  item.xml_travel_key,
                  item.uuid,
                )
              "
              class="text-center"
              v-if="item.xml_travel_id"
            >
              <h5>
                <span class="float-left font-weight-400 text-dark" style="font-size: 12.5px">
                  {{ item.xml_travel_number }}
                </span>
                <span class="ml-2">
                  <img
                    style="cursor: pointer"
                    v-if="item.status !== ScheduleTravelEnum.CANCELED"
                    width="19px"
                    src="/img/nfe.png"
                  />
                  <img
                    style="cursor: pointer"
                    v-if="
                      item.status === ScheduleTravelEnum.CANCELED &&
                      item.xml_travel_status !== XmlTravelEnum.NFE_IN_CANCELLATION_PROCESS
                    "
                    width="19px"
                    src="/img/nfe%20cancelada%20icon.svg"
                  />
                  <img
                    style="cursor: pointer"
                    v-if="
                      item.status === ScheduleTravelEnum.CANCELED &&
                      item.xml_travel_status === XmlTravelEnum.NFE_IN_CANCELLATION_PROCESS
                    "
                    width="19px"
                    src="/img/nfe_in_cancelation_progress.png"
                  />
                </span>
              </h5>
            </div>
          </ShadedCol>
          <ShadedCol>
            <div>
              <span
                :title="item.entity_name"
                class="new-default-black-font font-weight-normal m-0 mt-1"
              >
                {{ truncateString(item.entity_name, 20) }}
              </span>
            </div>
          </ShadedCol>
          <ShadedCol>
            <h5
              :title="item.construction_name"
              class="new-default-black-font font-weight-normal m-0 mt-1"
            >
              <span
                class="btn btn-sm btn-primary p-1 mr-2"
                @click.prevent="$emit('copyContractProposal', item.contract_proposal_code)"
              >
                {{ item.contract_proposal_code }}
              </span>
              {{ truncateString(item.construction_name, 15) }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.contract_proposal_formulation_title }}

              <el-popover class="p-0" placement="right" trigger="click">
                <div class="p-2 mt-1">
                  <h3 class="mb-2"> Formulação </h3>
                  <h5 class="small style-contract-proposal-formulation">
                    {{ item.contract_proposal_formulation_title }}
                  </h5>
                  <div class="mt-2 mb-3">
                    <h5 class="small style-contract-proposal-formulation">
                      {{ item.contract_proposal_formulation_body }}
                    </h5>
                  </div>
                  <div class="mt-2 mb-3" v-if="item.contract_proposal_formulation_footer">
                    <h5 class="small style-contract-proposal-formulation">
                      {{ item.contract_proposal_formulation_footer }}
                    </h5>
                  </div>
                  <div class="mt-2 mb-3 mt-n2" v-if="item.schedule_travel_reference_level_feature">
                    <h5 class="small style-contract-proposal-formulation" v-if="item.schedule_travel_reference_level_feature === 1">
                      Aplicação Baixa
                    </h5>
                    <h5 class="small style-contract-proposal-formulation" v-else-if="item.schedule_travel_reference_level_feature === 2">
                      Aplicação Média
                    </h5>
                    <h5 class="small style-contract-proposal-formulation " v-else-if="item.schedule_travel_reference_level_feature === 3">
                      Aplicação Alta
                    </h5>
                  </div>
                  <div class="mt-2">
                    
                    <h5 class="small style-contract-proposal-formulation">
                      <b class="text-dark" style="font-size: 11px;">
                        Peça:
                      </b>
                      {{ item.mix_concreted_piece_description }}
                    </h5>
                    
                  </div>
                </div>
                <base-button 
                  outline
                  slot="reference"
                  size="sm"
                  type="secundary"
                  class="p-0 m-0 mr-2 ml-2 shadow-none text-indigo"
                >
                  <img src="/img/icons/test-tube.png" width="25">
                </base-button>
              </el-popover>

            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.equipment_code }}
            </h5>
          </ShadedCol>
          <ShadedCol align="center">
            <div>
              <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
                {{ item.schedule_travel_volume }}
              </h5>
            </div>
            <div>
              <a
                v-if="item.schedule_travel_cycle_attachment_id"
                @click.prevent="handleModalScheduleTravelCycleAttchment(item.schedule_travel_cycle_id)"
                href="#"
                class="ml-1"
              >
                <img class="mt-n2" src="/img/icons/attach-green.png" height="22px" />
              </a>
            </div>
          </ShadedCol>
          <ShadedCol align="center">
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.accumulated }}
            </h5>
          </ShadedCol>
          <ShadedCol align="center">
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              <base-dropdown menuOnRight>
                <div slot="title-container"
                  class="dropdown-toggle rounded m-0">
                  <img width="25" src="/img/icons/icons8/ios/settings--v1_primary.png">
                </div>
                <router-link
                  :to="{
                    path: '/operational/schedule/charging',
                    name: 'operational.schedule.charging.index',
                    params: { travel_uuid: item.uuid }
                  }"
                >
                  <a
                    class="dropdown-item"
                    style="align-items: center;display: flex;"
                  >
                    <img src="/img/icons/icons8/ios/external-link-squared.png" width="22" alt="">
                    Ir para carregamento
                  </a>
                </router-link>
                <a
                  class="dropdown-item"
                  style="align-items: center;display: flex;"
                  @click.prevent="downloadReport(item.uuid)"
                >
                  <img src="/img/icons/icons8/ios/print-green.png" width="22" alt="">
                  Imprimir
              </a>
              </base-dropdown>
            </h5>
          </ShadedCol>
        </template>
      </ShadedTable>
    </div>
  </div>
    <ModalTravelCycle @updatedCycle="$parent.init({})" ref="travelCycle" />
    <ModalScheduleTravelCycleAttachment ref="modalScheduleTravelCycleAttchment" />
  </div>
</template>

<script>
import SkeletonPuzl from "@/components/SkeletonPuzl";
import ShadedTable from "@/components/ShadedTable.vue";
import ShadedCol from "@/components/ShadedCol.vue";
import { mapGetters } from "vuex";
import { floatToHuman, truncateString } from "@/helpers";
import { ScheduleTravelEnum } from "@/enum/ScheduleTravelEnum";
import { XmlTravelEnum } from "@/enum/XmlTravelEnum";
import ModalTravelCycle from "@/views/Modules/Operational/Schedule/Weighing/Shared/ScheduleTravelCycle/_ModalNewTravelCycle";
import {ContractProposalEnum} from "@/enum/ContractProposalEnum";
import ModalScheduleTravelCycleAttachment from "../../../Operational/Schedule/Weighing/Shared/ScheduleTravelCycle/_ModalAttachmentView.vue";

export default {
  name: "TableConcretesResume",
  components: {
    ModalTravelCycle,
    SkeletonPuzl,
    ShadedTable,
    ShadedCol,
    ModalScheduleTravelCycleAttachment,
  },
  data() {
    return {
      floatToHuman: floatToHuman,
      truncateString: truncateString,
      ScheduleTravelEnum: ScheduleTravelEnum,
      XmlTravelEnum: XmlTravelEnum,
      ContractProposalEnum: ContractProposalEnum,
      headers: [
        "N.",
        "Emissão",
        "O.S.",
        "NFE",
        "Cliente",
        "Obra",
        "Traço",
        "Equip.",
        "Vol.",
        "Acumulado",
        "Ação"
      ],
    };
  },
  props: {
    loadingSkeleton: Boolean,
    activateGroupingByPlant: Boolean,
    hasDriver: Boolean,
  },
  computed: {
    ...mapGetters({
      $_concretes: "chargeResume/fetch",
      $_setting: "generalSetting/show",
    }),
    /**
     * Adiciona coluna 'acumulado' aos dados existentes.
     */
    concretesWithAccumulated() {
      let accumulated = 0;
      return this.$_concretes.map(item => {
        accumulated += parseFloat(item.schedule_travel_volume); // Atualiza o valor acumulado.
        return { ...item, accumulated: accumulated.toFixed(1) }; // Retorna o novo objeto.
      });
    },
  },
  mounted() {
    this.$store.dispatch('generalSetting/show');
  },
  methods: {
    handleTravelCycle(schedule_travel) {
      this.$refs.travelCycle.openModal(
        schedule_travel.uuid,
        schedule_travel.address_construction_uuid
      );
    },
     /**
     * Realiza o download do relatório de carregamento.
     * @param {string} shcedule_travel_uuid
     */
    downloadReport(shcedule_travel_uuid) {
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'})
      let loader = this.$loading.show();
      let params = {
        uuid: shcedule_travel_uuid,
        type: "report"
      };
      this.$store
        .dispatch("travels/download", params)
        .then(response => {
          let blob = new Blob([response], {type: "application/pdf"});
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "custom_name.pdf");
          window.open(link, "_blank");
          loader.hide();
        })
        .catch(error => {
          loader.hide();
        });
    },
    handleModalScheduleTravelCycleAttchment(schedule_travel_cycle_id) {
      this.$refs.modalScheduleTravelCycleAttchment.openModal(schedule_travel_cycle_id);
    },
  }
};
</script>

<style>
.style-contract-proposal-formulation {
  font-size: 10px;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: normal !important;
}
</style>