<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">{{ title }}</h5>
      </template>
      <div>
        <div class="row">
          <div class="col-md-12">
            <template v-for="(item, index) in histories">
              <div class="border-left border-3 p-1 mb-2" :class="'border-'+border[item.situation].class">
                <h4 :class="'text-'+border[item.situation].class">{{ border[item.situation].name }}</h4>
                <span>{{ item.observation || '-' }}</span>
                <br>
                <span v-if="item.user">{{ item.user.name }} |</span> <span>{{ item.created_at | parseDate }}</span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

const borders = {
  0: {
    name: 'Reprovado',
    class: 'danger',
  },
  1: {
    name: 'Aprovado',
    class: 'success',
  },
  2: {
    name: 'Revisão',
    class: 'warning',
  }
}
export default {
  components: {},
  name: "ModalSituationHistories",
  data() {
    return {
      histories: [],
      modal: false,
      title: 'Histórico de aprovações',
      border: borders
    }
  },
  computed: {
    ...mapGetters({
      'situations': 'formulations/getSituation',
    }),
  },
  methods: {
    close() {
      this.modal = false
    },
    openModal(histories) {
      this.histories = histories
      this.modal = true
    },
  },
};
</script>

<style>
</style>
