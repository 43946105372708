<template>
<div>
    <modal :show.sync="modal.create">
        <template slot="header">
            <h5 class="modal-title pl-3">{{ modal.title }}</h5>
        </template>
        <div>
            <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
                <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
                    <div>
                        <!-- Status -->
                        <div class="form-group row m-0 p-0">
                            <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label">
                                Status
                                <span class="text-danger">&nbsp;*</span>
                            </label>
                            <div class="col-md-6 pt-2">
                                <base-switch v-model="adjustment.status" type="success" offText="inativo" onText="ativo" class="success"></base-switch>
                            </div>
                        </div>
                        <!-- Motivo -->
                        <div class="form-group row m-0 p-0">
                            <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label">
                                Motivo
                                <span class="text-danger">&nbsp;*</span>
                            </label>
                            <div class="col-md-6 mb-1 pr-4">
                                <validation-provider rules="required" v-slot="{errors}">
                                    <base-input input-group-classes="input-group-sm">
                                        <input 
                                            type="text" 
                                            v-model="adjustment.reason" 
                                            class="form-control form-control-sm" 
                                            :class="errors[0] ? 'is-invalid' : 'is-valid'"
                                            maxlength="250" />
                                    </base-input>
                                </validation-provider>
                            </div>
                        </div>
                        <!-- Observações -->
                        <div class="form-group row m-0 p-0">
                            <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label">
                                Observações
                            </label>
                            <div class="col-md-6 mb-1 pr-4">
                                <validation-provider>
                                    <base-input input-group-classes="input-group-sm">
                                        <input 
                                            type="text"
                                            v-model="adjustment.observation" 
                                            class="form-control form-control-sm"
                                            maxlength="250" />
                                    </base-input>
                                </validation-provider>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <base-button type="secondary" @click="closeModal('create')">
                                Cancelar
                            </base-button>
                            <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                                Salvar
                            </base-button>
                        </div>
                    </div>
                </form>
            </validation-observer>
        </div>

    </modal>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'
import {
    formatErrorValidation
} from '@/plugins'

export default {
    name: "ModalCreateAdjustment",
    data() {
        return {
            modal: {
                title: 'Motivos de sobra/Descarte de concreto',
                create: false,
            },
            adjustment: {
                status: null,
                reason: null,
                observation: null,
            },
            loadingStore: false,
        }
    },
    methods: {
        closeModal() {
            this.modal.create = false
        },
        handleCreateModal() {
            this.modal.create = true
            this.adjustment = {
                status: true,
                reason: null,
                observation: null,
            }
        },
        store() {
            this.$Progress.start()
            this.loadingStore = true
            this.adjustment.status = this.adjustment.status ? 1 : 0
            this.$store.dispatch('adjustmentLeftOver/add', this.adjustment)
                .then((response) => {
                    this.closeModal('create');
                    this.loadingStore = false
                    this.$Progress.finish();
                    this.$notify({
                        type: response.error_type,
                        message: response.message
                    })
                })
                .catch((error) => {
                    if (error.status === 200) {
                        this.$notify({
                            type: 'danger',
                            message: error.data.message
                        })
                        this.$Progress.finish();
                        this.loadingStore = false
                    } else if (error.response.status === 422) {
                        let errors = formatErrorValidation(error.response.data.errors)
                        this.$notify({
                            type: 'danger',
                            message: errors
                        })
                        this.$Progress.finish();
                        this.loadingStore = false
                    }
                })
        }
    },
    mounted() {
        this.$refs.formValidator.validate();
    },
}
</script>

<style scoped>

</style>
