<template>
  <div title="Filtro" style="margin-top: -3.2rem" class="col-md-12 text-right p-2">
    <component
      :is="tag"
      :class="[{ show: isOpen }, `drop${direction}`]"
      @click="toggleDropDown"
      v-click-outside="closeDropDown"
    >
      <slot name="title-container" :is-open="isOpen">
        <component
          :is="titleTag"
          class="btn-rotate"
          :class="[{'dropdown-toggle': hasToggle}, titleClasses]"
          :aria-expanded="isOpen"
          data-toggle="dropdown"
        >
          <slot name="title" :is-open="isOpen">
            <i style="font-size: 2.5rem;margin-right: -20px" class="icon-hover fa-2x fa-solid fa-filter"></i> {{ title }}
          </slot>
        </component>
      </slot>
      <ul :style="'width:'+ (width_ul) + 'px'"
          class="dropdown-menu"
          :class="[
        { show: isOpen },
        { 'dropdown-menu-right': menuOnRight },
        menuClasses,
       'dropdown-menu-right'
      ]"
      >
        <slot></slot>
      </ul>
    </component>
  </div>

</template>
<script>
export default {
  name: 'filter-dropdown',
  props: {
    tag: {
      type: String,
      default: 'div',
      description: 'Dropdown html tag (e.g div, ul etc)'
    },
    titleTag: {
      type: String,
      default: 'button',
      description: 'Dropdown title (toggle) html tag'
    },
    title: {
      type: String,
      description: 'Dropdown title'
    },
    direction: {
      type: String,
      default: 'down', // up | down
      description: 'Dropdown menu direction (up|down)'
    },
    icon: {
      type: String,
      description: 'Dropdown icon'
    },
    titleClasses: {
      type: [String, Object, Array],
      description: 'Title css classes'
    },
    menuClasses: {
      type: [String, Object],
      description: 'Menu css classes'
    },
    menuOnRight: {
      type: Boolean,
      description: 'Whether menu should appear on the right',
    },
    hasToggle: {
      type: Boolean,
      description: 'Whether dropdown has arrow icon shown',

    }
  },
  data() {
    return {
      windowWidth: null,
      width_ul: null,
      isOpen: false
    };
  },
  updated() {
    this.windowWidth = window.innerWidth
  },
  mounted() {
    this.windowWidth = window.innerWidth
    this.resize()
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
      this.resize()
    })
  },
  methods: {
    resize() {
      if (this.windowWidth < 768) {
        this.width_ul = this.windowWidth - 64.5
      }
      if (this.windowWidth > 768) {
        this.width_ul = this.windowWidth / 2
      }
    },
    toggleDropDown() {
      this.isOpen = !this.isOpen
      this.$emit('change', true);
    },
    closeDropDown() {
      this.isOpen = false;
      this.$emit('change', false);
    }
  }
};
</script>
<style lang="scss">
.dropdown {
  cursor: pointer;
  user-select: none;
}
.icon-hover:hover {
  transform: scale(1.2);
}
</style>
