<template>
<div>
    <modal :show.sync="modal.create">
        <template slot="header">
            <h5 class="modal-title pl-4">{{ modal.title }}</h5>
        </template>
        <div>
            <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
                <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
                    <div>
                        <!-- Central -->
                        <div class="form-group row m-0 p-0 ">
                            <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label pt-0 pb-3">
                                <div class="border-left border-primary border-3 pl-2  ">
                                    {{user.name}}
                                </div>
                            </label>
                        </div>
                        <!-- Central -->
                        <div class="form-group row m-0 p-0">
                            <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label pl-4">
                                Central padrão <span class="text-danger">&nbsp;*</span>
                            </label>
                            <div class="col-md-6 mb-1 pr-4">
                                <validation-provider rules="required">
                                    <base-input input-classes="form-control-sm">
                                        <PuzlSelect 
                                            v-model="water.company_plant_id"
                                            :items="plants"  
                                            @change="getChargePoint(water.company_plant_id)"
                                            :disabled="loadingPlants"
                                            :loading="loadingPlants" />
                                    </base-input>
                                </validation-provider>
                            </div>
                        </div>
                        <!-- Ponto de carga -->
                        <div class="form-group row m-0 p-0">
                            <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label pl-4">
                                Ponto de carga padrão <span class="text-danger">&nbsp;*</span>
                            </label>
                            <div class="col-md-6 mb-1 pr-4">
                                <validation-provider rules="required">
                                    <base-input input-classes="form-control-sm">
                                        <puzl-select 
                                            v-model="water.company_plant_charge_point_id"
                                            :items="chargePointItems"  
                                            :disabled="!charge_point_select || !water.company_plant_id" 
                                            :loading="loadingCompanyPlantChargePoint" />
                                    </base-input>
                                </validation-provider>
                            </div>
                        </div>
                        <!-- Solicitação automática  -->
                        <div class="form-group row m-0 p-0">
                            <label class="col-md-6 pb-0 mb-1 pt-2 col-form-label form-control-label pl-4">
                                Solicitação automática a cada <span class="text-danger">&nbsp;*</span>
                            </label>
                            <div class="col-md-6 mb-1 pr-4">
                                <validation-provider rules="required">
                                    <base-input input-group-classes="input-group-sm">
                                        <input inputmode="numeric" v-model="water.auto_request" class="form-control form-control-sm" />
                                        <template slot="append">
                                            <small class="input-group-sm p-0 m-0">
                                                horas
                                            </small>
                                        </template>
                                    </base-input>
                                </validation-provider>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <base-button type="secondary" @click="closeModal('create')">
                                Cancelar
                            </base-button>
                            <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                                Salvar
                            </base-button>
                        </div>
                    </div>
                </form>
            </validation-observer>
        </div>
    </modal>
</div>
</template>

<script>
import {mapGetters} from 'vuex'
import moment from 'moment';
import PuzlSelect from "@/components/PuzlSelect";
import {checkNumberValue} from '@/helpers';
import {mask1, formatErrorValidation} from '@/plugins';

export default {
    name: "ModalCreateWaterSetting",
    components: { PuzlSelect },
    data() {
        return {
            exists: false,
            status: true,
            modal: {
                title: 'Automatizações',
                create: false,
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                },
            },
            charge_point_select: false,
            water: {
                uuid: null,
                company_plant_id: null,
                company_plant_charge_point_id: null,
                auto_request: null,
            },
            loadingStore: false,
            loadingPlants: true,
            loadingCompanyPlantChargePoint: false,
            selectedGroups: [],
            chargePointItems: [],
        }
    },
    computed: {
        ...mapGetters({
            'plants': 'plant/activeItems',
            'chargePoints': 'plantChargePoint/fetch',
            'user': 'auth/getUser',
        }),
    },
    methods: {
        closeModal() {
            this.modal.create = false
        },
        handleCreateModal() {
            let loader = this.$loading.show()
            this.loadData()
            this.water = {
                uuid: null,
                company_plant_id: null,
                company_plant_charge_point_id: null,
                auto_request: null
            };
            this.exists = false,
                this.chargePointItems = [],
                this.charge_point_select = false,
                this.$store.dispatch('waterSetting/getByUser')
                .then((response) => {
                    this.water = {
                        company_plant_id: response.data.company_plant.id,
                        company_plant_charge_point_id: response.data.company_plant_charge_point.id,
                        auto_request: response.data.auto_request,
                        uuid: response.data.uuid,
                    }
                    this.exists = response.data.uuid ? true : false
                    let uuid_charge_point = this.plants.filter(item => item.id == this.water.company_plant_id)[0].uuid
                    this.$store.dispatch('plantChargePoint/getByPlant', uuid_charge_point).then((response) => {
                        this.charge_point_select = true
                        this.chargePointItems = response.data
                        loader.hide()
                        this.$notify({
                            type: response.error_type,
                            message: response.message
                        })
                        this.modal.create = true
                    })
                }).catch((error) => {
                    if (error.response && error.response.status === 422) {
                        let errors = formatErrorValidation(error.response.data.errors)
                        this.$notify({
                            type: 'danger',
                            message: errors
                        })
                    }
                    loader.hide()
                    this.modal.create = true
                })
        },
        getChargePoint() {
            if (this.water.company_plant_id) {
                this.charge_point_select = false
                this.water.company_plant_charge_point_id = null
                let uuid = this.plants.filter(item => item.id == this.water.company_plant_id)[0].uuid
                this.loadingCompanyPlantChargePoint = true
                this.$store.dispatch('plantChargePoint/getByPlant', uuid).then((response) => {
                    this.charge_point_select = true
                    this.chargePointItems = response.data
                    this.loadingCompanyPlantChargePoint = false;
                })
            } else {
                this.charge_point_select = false
                this.water.company_plant_charge_point_id = null
            }
        },
        store() {
            this.$Progress.start()
            this.loadingStore = true
            this.$notify({
                type: 'info',
                message: 'Estamos trabalhando em sua solicitação.'
            })
            if (this.exists) {
                this.$store.dispatch('waterSetting/update', this.water)
                    .then(response => {
                        this.$Progress.finish()
                        this.$notify({
                            limit: response.error_type,
                            message: response.message
                        })
                        this.loadingStore = false
                        this.modal.create = false
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            let errors = formatErrorValidation(error.response.data.errors)
                            this.$notify({
                                limit: 'danger',
                                message: errors
                            })
                        }
                        this.$Progress.finish()
                        this.loadingStore = false
                    })
            } else {
                this.$store.dispatch('waterSetting/add', this.water)
                    .then(response => {
                        this.loadingStore = false
                        this.modal.create = false
                        this.$Progress.finish()
                        this.$notify({
                            limit: response.error_type,
                            message: response.message
                        })
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            let errors = formatErrorValidation(error.response.data.errors)
                            this.$notify({
                                limit: 'danger',
                                message: errors
                            })
                        }
                        this.$Progress.finish()
                        this.loadingStore = false
                    })
            }
        },
        loadData() {
            this.loadingPlants = true;
            this.$store.dispatch('plant/getPlantsSimplified').then(() => {
                this.loadingPlants = false;
            });
        },
    },
}
</script>

<style scoped>
</style>
