import AdjustmentHistoryActionsEnum from "../../adjustment-history/enums/AdjustmentHistoryActionsEnum";
import AdjustmentHistoryTypesEnum from "../../adjustment-history/enums/AdjustmentHistoryTypesEnum";
import AdjustmentEnum from "../../adjustment-history/enums/AdjustmentEnum";
import { CmcAdjustmentListType } from "../types";
import { AdjustmentHistoryCreateType } from "../../adjustment-history/types";
import adjustmentHistoryStore from "../../adjustment-history/store";
import { cmcAdjustmentCreateHistoryService } from "./";

/**
 * Serviço que faz a manipulação dos objetos
 * para criação de histórico de teor (ajuste e remoção)
 * do Mapa do Ponto de Carga
 *
 * @param {number} action_id
 * @param {CmcAdjustmentListType} cmcAdjustment
 */
const execute = (action_id, cmcAdjustment) => {
  
  /** @type {string} */
  const title = cmcAdjustmentCreateHistoryService.formatTitle(
    cmcAdjustment.group_name,
    cmcAdjustment.cmc_product,
    cmcAdjustment.cmc_supplier,
    cmcAdjustment.supplier_origin,
    cmcAdjustment.category_name
  );

  switch (action_id) {
    case AdjustmentHistoryActionsEnum.keys.ADJUST:
      /** @type {AdjustmentHistoryCreateType} */
      const historyObjectAdjust = {
        action: action_id,
        type: AdjustmentHistoryTypesEnum.keys.INDIVIDUAL,
        adjust: AdjustmentEnum.keys.MCC_CONTENT,
        title: title,
        company_plant_ids: [cmcAdjustment.company_plant_id],
        content: cmcAdjustment.content,
        content_min: cmcAdjustment.content_min,
        content_max: cmcAdjustment.content_max,
        observation_content: cmcAdjustment.observation_content,
      };

      adjustmentHistoryStore.create(historyObjectAdjust);
    break;

    case AdjustmentHistoryActionsEnum.keys.REMOVE:
      /** @type {AdjustmentHistoryCreateType} */
      const historyObjectRemove = {
        action: action_id,
        type: AdjustmentHistoryTypesEnum.keys.INDIVIDUAL,
        adjust: AdjustmentEnum.keys.MCC_CONTENT,
        title: title,
      };

      adjustmentHistoryStore.create(historyObjectRemove);
    break;

    default:
      break;
  }
}

export default { execute };