<template>
  <base-input :input-group-classes="input_group_classes">
    <input style="height: 28px !important;"
            inputmode="numeric"
            @change="$emit('change', $event)"
            :disabled="disabled"
            v-model="value"
            v-on:blur="onBlur($event)"
            class="form-control form-control-sm"
            :class="value == 0 ? '' : value > 0 ? 'is-valid': value && value < 0 ? 'is-invalid' : ''"
    />
    <template slot="append">
      <slot></slot>
    </template>
  </base-input>
</template>

<script>
import {mask1} from "@/plugins";

export default {
  name: "InputLimitV2",
  props: {
    options: {
      description: 'Opções de limite',
    },
    disabled: {
      type: Boolean,
      default: false
    },
    start_value: {
      type: [Number, String],
    },
    input_group_classes: {
      type: String,
      default: () => "input-group-sm pb-2",
    },
  },
  data() {
    return {
      value: 0,
    }
  },
  methods: {
    onBlur(event) {
      const newValue = parseFloat(event.target.value);
      const min = this.options.min !== null ? this.options.min : 0;
      const max = this.options.max !== null ? this.options.max : 0;

      if (!newValue) {
        this.value = min;
        this.notifyWrongValue();
        return
      }

      const decimals = this.options.decimals || 0;
      let currentValue = newValue ? mask1(newValue.toString(), decimals) : newValue;

      let revertedValue = null;
      if ((min !== null && newValue < min) || (max !== null && currentValue > max) || currentValue < min) {
        this.notifyWrongValue();
        revertedValue = min;
      }

      this.value = revertedValue !== null ? revertedValue : currentValue;
      this.$emit('value', this.value);
    },
    notifyWrongValue() {
      this.$notify({
        type: 'danger',
        message: `Valor fora do limite permitido!`
      });
    },
  },
  mounted() {
    this.$watch('start_value', function (value) {
      if (!this.value || this.value != value) {
        this.value = value
      }
    }, {
      immediate: true,
      deep: true
    });
    this.$emit('value', this.value);
  },
}
</script>

<style scoped>

</style>
