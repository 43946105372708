<template>
  <div>
    <base-header class="bg-gray-content">
      <puzl-breadcrumb />
    </base-header>
    <DashboardFilter ref="filter" @fetch="fetch" @changeTheme="changeTheme" />
    <Cards @fetch="fetch"/>
    <Chart ref="charts" :theme="config.dark_mode" />
  </div>
</template>

<script>
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import DashboardFilter from "./Shared/_Filter.vue";
import Chart from "./Shared/_Chart.vue";
import Cards from "./Shared/_Cards.vue";

export default {
  name: "Index",
  components: {
    DashboardFilter,
    Chart,
    Cards,
    PuzlBreadcrumb,
  },
  data() {
    return {
      config: {
        dark_mode: false,
      },
    };
  },
  methods: {
    changeTheme() {
      this.config.dark_mode = !this.config.dark_mode;
    },
    fetch(filters = []) {
      this.triggerValueType();
      this.$store.dispatch("financialDashboard/getGeneralTotal", filters);
      this.$store.dispatch("financialDashboard/getTreemap", filters);
      this.$store.dispatch("financialDashboard/getBarBrush", filters);
    },
    triggerValueType() {
      this.$store.dispatch("financialDashboard/triggerValueType");
    },
  },
  mounted() {
    this.$refs.filter.handleFilter();
  },
};
</script>

<style></style>
