export const SET = 'SET'
export const SET_FORMULATIONS_ADJUSTMENTS = 'SET_FORMULATIONS_ADJUSTMENTS'
export const SET_FORMULATIONS = 'SET_FORMULATIONS'
export const SHOW_FORMULATION = 'SHOW_FORMULATION'
export const SET_ADDITIONS = 'SET_ADDITIONS'
export const SET_GROUP_CATEGORY = 'SET_GROUP_CATEGORY'
export const PUSH = 'PUSH'
export const SHOW = 'SHOW'
export const DESTROY = 'DESTROY'
export const UPDATE = 'UPDATE'
