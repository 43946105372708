/*jshint esversion: 9 */
const responsive = {
  data() {
    return {
      windowWidth: null,
    }
  },
  beforeUpdate() {
    this.windowWidth = window.innerWidth
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
  methods: {}
}
export default responsive
