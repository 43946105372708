<template>
  <div>
      <base-button
        block
        type="light"
        style="border-radius: 4px !important;"
        class="text-uppercase"
        :disabled="disabled"
        @click.prevent="$router.go(-1)">
          <img width="19" src="/img/icons/circled-left.png" class="mr-1"/>
          VOLTAR
      </base-button>
  </div>
</template>

<script>
export default {
  name: "BackButton",
  props: ['disabled'],
  data() {
    return {
      prevRoute: null,
    }
  },
  mounted() {
    this.prevRoute = this.$router.history._startLocation
  }
}
</script>

<style scoped>

</style>
