<template>
  <div>
    <modal size="lg" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(update)" autocomplete="off">

            <div class="form-group row m-0 p-0">
                <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                    Conta Bancária
                    <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 mb-1 pr-4">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <puzl-select
                        v-model="$_bank_balances.bank_account_id"
                        :items="$_bank_accounts"
                        :loading="loadingBankAccount"
                        :disabled="loadingBankAccount" />
                    </base-input>
                  </validation-provider>
                </div>
            </div>

            <div class="form-group row m-0 p-0">
                <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                    Data
                    <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 mb-1 pr-4">
                    <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-classes="form-control-sm">
                            <el-date-picker
                                size="mini"
                                v-model="$_bank_balances.date_bank_balance"
                                type="date"
                                format="dd/MM/yyyy"
                                value-format="yyyy-MM-dd"
                                @input="checkDate()" >
                            </el-date-picker>
                        </base-input>
                    </validation-provider>
                </div>
            </div>

            <div class="form-group row m-0 p-0">
                <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                    Saldo inicial do dia
                    <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 mb-1 pr-4">
                    <validation-provider rules="required">
                        <base-input input-classes="form-control-sm">
                            <input
                                type="text"
                                class="form-control form-control-sm"
                                v-model="$_bank_balances.balance_initial_day"
                                @blur="calculateAdjustment"
                                @keydown="$event.keyCode == 8 && $_bank_balances.balance_initial_day == '-0,00' ? $_bank_balances.balance_initial_day = null : ''"
                                v-money="money"
                            />
                        </base-input>
                    </validation-provider>
                </div>
            </div>

            <div class="form-group row m-0 p-0">
                <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                    Ajuste de saldo
                </label>
                <div class="col-md-8 mb-1 pr-4">
                    <validation-provider>
                        <base-input input-classes="form-control-sm">
                            <input
                                type="text"
                                class="form-control form-control-sm"
                                v-model="$_bank_balances.balance_adjustment"
                                @blur="calculateAdjustment"
                                @keydown="$event.keyCode == 8 && $_bank_balances.balance_adjustment == '-0,00' ? $_bank_balances.balance_adjustment = null : ''"
                                v-money="money"
                            />
                        </base-input>
                    </validation-provider>
                </div>
            </div>

            <div class="form-group row m-0 p-0">
                <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                    Saldo inicial do dia
                    <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 mb-1 pr-4">
                    <validation-provider rules="required">
                        <base-input input-classes="form-control-sm">
                            <input
                                :class="[
                                    checkTotal === 0 ? 'text-danger' : '',
                                    checkTotal === 1 ? 'text-success' : '',
                                    checkTotal === 2 ? 'text-light' : '',
                                ]"
                                type="text"
                                class="form-control form-control-sm"
                                v-model="$_bank_balances.total"
                                v-money="money"
                                disabled
                            />
                        </base-input>
                    </validation-provider>
                </div>
            </div>

            <div class="form-group row m-0 p-0">
                <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                    Observações
                </label>
                <div class="col-md-8 mb-1 pr-4">
                    <base-input input-group-classes="input-group-sm">
                        <textarea 
                            type="text" 
                            rows="2" 
                            class="form-control form-control-sm" 
                            v-model="$_bank_balances.observation"
                            maxlength="250"
                        >
                        </textarea>
                    </base-input>
                </div>
            </div>

            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')">
                  Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                  Salvar
              </base-button>
            </div>

          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import PuzlSelect from "@/components/PuzlSelect";
import { VMoney } from "v-money";
import moment from "moment";
import { moneyToFloat, checkNumberValue } from "@/helpers";
const { formatErrorValidation } = require("@/plugins");
export default {
    name: "ModalEdit",
    components: { PuzlSelect },
    data() {
        return {
            modal: {
                title: 'Editar Saldo Diário',
                create: false,
            },
            loadingStore: false,
            loadingBankAccount: true,
            checkTotal: 2,
            money: {
                decimal: ",",
                thousands: ".",
                prefix: "",
                suffix: "",
                precision: 2,
                masked: false
            },
            loadingBankAccount: true,
        };
    },
    computed: {
        ...mapGetters({
            $_bank_balances: 'bankBalance/show',
            $_bank_accounts: 'bankAccount/fetch',
        }),
    },
    mounted() {
        this.$refs.formValidator.validate();
    },
    beforeUpdate() {
        this.calculateAdjustment();
    },
    directives: {
        money: VMoney
    },
    methods: {
        closeModal() {
            this.modal.create = false;
        },
        handleEditModal(id) {
            this.loadData();
            let loader = this.$loading.show();
            this.$store.dispatch('bankBalance/show', id)
                .then((response) => {
                    this.modal.create = true;
                    loader.hide();
                    this.$notify({
                        type: "success",
                        message: "Solicitação realizada com sucesso!",
                    });
                }).catch((error) => {
                    this.$notify({
                        type: error.data.error_type,
                        message: error.data.message
                    })
                    loader.hide();
                })
        },
        update() {
            this.$Progress.start()
            this.loadingStore = true;
            this.$_bank_balances = {... this.$_bank_balances};
            let payload =  {... this.$_bank_balances};
            payload.balance_initial_day = this.$helper.moneyToFloat(payload.balance_initial_day);
            payload.balance_adjustment = this.$helper.moneyToFloat(payload.balance_adjustment);
            payload.total = this.$helper.moneyToFloat(payload.total);
            this.$store.dispatch('bankBalance/update', payload)
                .then(response => {
                    this.loadingStore = false;
                    this.modal.create = false;
                    this.$Progress.finish();
                    this.$notify({
                        type: response.error_type,
                        message: response.message
                    })
                })
                .catch(error => {
                    if (error.status == 200) {
                        this.$notify({
                            type: 'danger',
                            message: error.data.message
                        })

                    } else if (error.response && error.response.status === 422) {
                        let errors = formatErrorValidation(error.response.data.errors)
                        this.$notify({
                            type: 'danger',
                            message: errors
                        })
                    }
                    this.$Progress.finish();
                    this.loadingStore = false;
                })
        },
        calculateAdjustment() {
            const balance_initial_day = moneyToFloat(this.$_bank_balances.balance_initial_day);
            const balance_adjustment = moneyToFloat(this.$_bank_balances.balance_adjustment);
            if (this.$_bank_balances.balance_initial_day.length && this.$_bank_balances.balance_adjustment.length) {
                this.$_bank_balances.total = (balance_initial_day + balance_adjustment).toFixed(2);
                if (this.$_bank_balances.total > 0) {
                    this.checkTotal = 1;
                } else {
                    this.checkTotal = 0;
                }
            }
            if (balance_adjustment === 0) {
                this.$_bank_balances.total = (balance_initial_day).toFixed(2);
                if (this.$_bank_balances.total > 0) {
                    this.checkTotal = 1;
                } else {
                    this.checkTotal = 0;
                }
            }
        },
        checkDate() {
            if (moment(this.$_bank_balances.date_bank_balance).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD")) {
                this.$_bank_balances.date_bank_balance = moment().format("YYYY-MM-DD");
                this.$notify({
                    type: 'danger',
                    message: 'Data não pode ser maior que hoje'
                })
            }
        },
        loadData() {
            this.loadingBankAccount = true;
            this.$store.dispatch('bankAccount/fetchItems').then(() => {
                this.loadingBankAccount = false;
            });
        },
    },
};
</script>

<style scoped></style>
