<template>
  <div>
    <modal :show.sync="modal"
    	headerClasses="custom-reconciliation-hash0905242234"
      bodyClasses="px-4"
    	size="lg">
      <template slot="header">
        <div style="display: flex;align-items: center;">
          <img style="display: inline-flex;" src="/img/icons/icons8/ios/link_primary.png" width="24">
          <h5 style="display: inline-flex;" class="modal-title p-0 m-0 ml-2 text-primary fs-18">{{ title }}</h5>
        </div>
      </template>
      <template slot="close-button">
        <img class="pointer" @click="closeModal" src="/img/icons/icons8/ios/close-window.png" alt="close-window" width="17px">
      </template>
      <div class="container">
        <div class="row">
          <div class="col-12 px-1 text-right">
            <div class=""new-puzl-default>
              <base-button type="danger" block class="new-puzl-default"
                @click.prevent="removeAssociations"
                style="border-radius: 30px !important;">
                <img src="/img/icons/icons8/ios/remove_link.png" width="16">
                Remover conc.
              </base-button>
            </div>
          </div>
        </div>
      </div>
      <Cards :bankReconciliationTransactionAssociations="bankReconciliationTransactionAssociations" />
    </modal>
  </div>
</template>

<script>
import cursorPaginate from "@/mixins/cursorPaginate";
import Cards from "./Lists/_Cards"
import { formatErrorValidation } from "@/plugins";

export default {
  name: "ModalAssociations",
  mixins: [cursorPaginate],
  components: {
    Cards
  },
  data () {
    return {
      title: 'Conciliação',
      modal: false,
      bankReconciliationTransactionAssociations: [],
    };
  },
  methods: {
    removeAssociations(){
      this.$store
        .dispatch("bankReconciliationTransactionAssociation/removeAssociateFinanceTransaction", this.filter.bank_reconciliation_transaction_id)
        .then(response => {
          this.$emit('init');
          this.modal = false;
        }).catch(error => {
          this.$notify({ type: "danger", message: error.data.message });
        });
    },
  	openModal(item){
  		this.modal = true;
      this.filter = {
        bank_reconciliation_transaction_id: item.id,
      };

      this.startCursor(this.filter);
      this.$store
        .dispatch("bankReconciliationTransactionAssociation/fetchItems", {
          page: this.paginate.nextUrl,
          bank_reconciliation_transaction_id: this.filter.bank_reconciliation_transaction_id,
          order_by: [
            {
              column: "finance_transactions.id"
            },
          ],
        })
        .then(response => {
          this.loadingSkeleton = false;
          this.bankReconciliationTransactionAssociations = response.data;
          this.resolveCursor(response);
        })
        .catch(error => {
          this.resolveCursor();
          this.loadingSkeleton = false;
        });
  	},
    closeModal(){
      this.modal = false;
    },
  },
}
</script>

<style>
.custom-reconciliation-hash0905242234 {
	background-color: #F2F4F9 !important;
	height: 56px !important;
}
</style>
<style scoped>
div.new-puzl-default {
  margin-left: 0px;
  margin-top: 0px;
  margin-top: 5px;
  display: block;
}

.new-puzl-default {
  font-family: Fredoka;
  font-weight: 400 !important;
  font-size: 12px !important;
  min-height: 35px !important;
  max-height: 35px !important;
  min-width: 205px;
  max-width: 205px;
  display: flex;
}

button.new-puzl-default {
  display: inline-flex;
  align-items: center;
}

.new-puzl-default img {
  margin-right: 8px;
}
</style>
