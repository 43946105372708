import * as types from './mutation_types'
import {destroy, update} from '@/store/baseStore'
import { createAxios } from "@/plugins/axios";

const endPoint = '/financial/bank-reconciliation/transaction'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
    associations: [],
    to_associate: [],
  },
  getters: {
    show: state => state.item,
    fetch: state => state.items,
    getToAssociate: state => state.to_associate,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload
    },
    [types.SET_ASSOCIATIONS](state, payload) {
      state.to_associate = payload
    },
    [types.PUSH](state, payload) {
      state.items.push(payload)
    },
    [types.SHOW](state, payload) {
      state.item = payload
    },
    [types.DESTROY](state, id) {
      destroy(state, id)
    },
    [types.UPDATE](state, payload) {
      update(state, payload)
    },
  },
  actions: {
    showByReconciliation({commit}, params) {
      return createAxios().get(`${endPoint}/reconciliation/${params.id}`, {params: params})
        .then(({data}) => {
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    nextOrPreviousReconciliation({commit}, params) {
      return createAxios().get(`${endPoint}/reconciliation/${params.id}/${params.next}/transactions`, {params: params})
        .then(({data}) => {
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    show({commit}, params) {
      return createAxios().get(`${endPoint}/${params.id}`, {params: params})
        .then(({data}) => {
          commit(types.SET_ASSOCIATIONS, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getBillReceives({commit}, params) {
      return createAxios().get(`${endPoint}/reconciliation/transaction/${params.id}/bill-receives/`, {params: params})
        .then(({data}) => {
          commit(types.SET_ASSOCIATIONS, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getBillReceivesToIdentify({commit}, params) {
      return createAxios().get(`${endPoint}/reconciliation/transaction/${params.id}/bill-receives/to-identify`, {params: params})
        .then(({data}) => {
          commit(types.SET_ASSOCIATIONS, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getBillPay({commit}, params) {
      return createAxios().get(`${endPoint}/reconciliation/transaction/${params.id}/bill-pay/`, {params: params})
        .then(({data}) => {
          commit(types.SET_ASSOCIATIONS, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getBillPayToIdentify({commit}, params) {
      return createAxios().get(`${endPoint}/reconciliation/transaction/${params.id}/bill-pay/to-identify`, {params: params})
        .then(({data}) => {
          commit(types.SET_ASSOCIATIONS, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    postAssociate({commit}, params) {
      return createAxios().post(`${endPoint}/associate`, params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    postIdentify({commit}, params) {
      return createAxios().post(`${endPoint}/identify`, params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    postConciliation({commit}, params) {
      return createAxios().post(`${endPoint}/conciliation`, params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    postAGroup({commit}, params) {
      return createAxios().post(`${endPoint}/agroup`, params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    postAdjustInstallment({commit}, params) {
      return createAxios().post(`${endPoint}/adjustment/installment`, params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    postRemoveConciliation({commit}, params) {
      return createAxios().post(`${endPoint}/remove-conciliation`, params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    postRemoveIdentification({commit}, params) {
      return createAxios().post(`${endPoint}/remove-identification`, params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    putAutoClosing({commit}, payload) {
      return createAxios().put(`${endPoint}/${payload.id}/auto-closing`, payload)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
