<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-gray d-inline-block mb-0">{{ this.$route.meta.breadcrumbs[1].title }}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4 text-gray">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i :class="this.$route.meta.icon"></i></a></li>
              <li class="breadcrumb-item"><a href="#">{{ this.$route.meta.breadcrumbs[0].title }}</a></li>
              <li class="breadcrumb-item active" aria-current="page">{{ this.$route.meta.breadcrumbs[1].title }}</li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- Header Menus -->
      <div class="row">
        <div class="col-md-2 mb-3">
          <router-link :to="{
            path: '/equipment/equipment/create',
            name: 'equipment.equipment.create',
          }">
            <base-button class="pb-3 pt-3 text-uppercase" block size="lg" type="success">
              <img src="/img/icons/plus-math--v1-white.png" width="20px" height="20px" class="imgBaseButton">
              novo
            </base-button>
          </router-link>
        </div>
        <div class="col-md-2 mb-3">
          <base-dropdown class="col-md-12 p-0">
            <base-button slot="title-container" block type="warning" class="dropdown-toggle p-3 text-uppercase">
              <img src="/img/icons/product-documents.png" width="20px" height="20px" class="imgBaseButton">
              Cadastros
            </base-button>
            <div>
              <router-link :to="{
                path: '/equipment/equipment/category',
                name: 'equipment.category.index',
              }">
                <a class="dropdown-item">Categoria</a>
                <div class="dropdown-divider p-0 m-0"></div>
              </router-link>

              <router-link :to="{
                path: '/equipment/equipment/type',
                name: 'equipment.type.index',
              }">
                <a class="dropdown-item">Tipo de Equipamento</a>
                <div class="dropdown-divider p-0 m-0"></div>
              </router-link>

              <router-link :to="{
                path: '/equipment/equipment/situation',
                name: 'equipment.situation.index',
              }">
                <a class="dropdown-item">Situação de Equipamento</a>
              </router-link>

            </div>
          </base-dropdown>
        </div>
      </div>
    </base-header>
    <List />
  </div>
</template>

<script>
import List from './Shared/_List'
export default {
  name: "IndexEquipment",
  components: {
    List,
  },
  data() {
    return {
    }
  },
};
</script>

<style scoped>
.imgBaseButton {
  margin-bottom: 2px;
}
</style>
