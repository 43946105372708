<template>
  <div>
    <div class="row card-wrapper" v-show="loadingSkeleton">
      <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
    </div>
    <div class="row card-wrapper" v-if="finances.length && !loadingSkeleton">
      <div class="col-lg-4" v-for="(item, index) in finances" :key="index">
        <div class="card main-card mb-3">
          <div class="p-3 px-4">
            <div class="row align-items-center">
              <div class="col-10" style="display: flex;">
                <base-dropdown v-if="item.status !== statusEnum.CANCELED">
                  <div :class="item.status ? 'new-minicard-green' : 'new-minicard-yellow'"
                    slot="title-container"
                    style="display: inline-flex;align-items: center;">
                    <img class="mr-2" width="16"
                      :src="getStatus(item.status, item.finance_type_mode).icon">
                    <h4 class="font-weight-normal m-0 p-0 mr-2">
                      {{ getStatus(item.status, item.finance_type_mode).text }}
                    </h4>
                    <img :src="getStatus(item.status, item.finance_type_mode).dropdown" width="12" alt="">
                  </div>
                  <div v-if="item.status == statusEnum.PENDING">
                    <a @click.prevent="$emit('handleChangeStatus', item, statusAction.POST)"
                      class="dropdown-item">
                      {{ item.finance_type_mode ? "Pagar" : "Receber" }}
                    </a>
                    <div class="dropdown-divider p-0 m-0"></div>
                    <a @click.prevent="$emit('handleChangeStatus', item, statusAction.CANCEL)"
                      class="dropdown-item">
                      Cancelar
                    </a>
                  </div>
                  <div v-if="item.status == statusEnum.FINISHED">
                    <a @click.prevent="$emit('handleChangeStatus', item, statusAction.REVERT)"
                      class="dropdown-item">
                      Estornar
                    </a>
                  </div>
                </base-dropdown>
                <div v-else class="new-minicard-red"
                  style="display: inline-flex;align-items: center;">
                  <img class="mr-2" width="16"
                  :src="getStatus(item.status, item.finance_type_mode).icon">
                  <h4 class="font-weight-normal m-0 p-0 mr-2">
                    Cancelado
                  </h4>
                </div>
                <el-popover v-if="item.finance_note" trigger="click" placement="right">
                  <span style="width: 290px;"
                        class="row">
                    <div class="col-12 mb-2" style="display: flex; align-items: center;">
                      <img class="mr-2" src="/img/icons/icons8/ios/chat-bubble_black.png" width="22">
                      <h4 style="font-size: 14px;" class="p-0 m-0 font-weight-500">Observações</h4>
                    </div>
                    <div class="col-12 mt-0 mb-n2">
                      <h5 class="font-weight-normal">{{ item.finance_note }}</h5>
                    </div>
                  </span>
                  <base-button outline slot="reference" size="sm" type="secundary"
                               class="p-0 m-0 ml-2 shadow-none text-indigo">
                    <img src="/img/icons/icons8/ios/chat-bubble_black.png" width="25">
                  </base-button>
                </el-popover>
              </div>
              <div class="col-2 text-right">
                <base-dropdown menuOnRight>
                  <div slot="title-container" class="dropdown-toggle rounded m-0">
                    <img width="32" src="/img/icons/icons8/ios/settings--v1_primary.png" />
                  </div>
                  <a v-if="item.status !== statusEnum.FINISHED" @click.prevent="$emit('handleEditFinance', item.finance_id)"
                    class="dropdown-item font-weight-500 new-default-black-font"
                    style="align-items: center; display: flex">
                    <img width="18" src="/img/icons/edit.png" />
                    EDITAR
                  </a>
                  <div v-if="item.status !== statusEnum.FINISHED" class="dropdown-divider p-0 m-0"></div>
                  <a @click.prevent="$emit('handleHistoryOperationsModal', item)"
                    class="dropdown-item font-weight-500 new-default-black-font"
                    style="align-items: center; display: flex">
                    <img width="18" src="/img/icons/icons8/ios/order-history_secondary.png" />
                    HISTÓRICO
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a @click.prevent="$emit('handleDeleteFinance', item.finance_id)"
                    class="dropdown-item font-weight-500 new-default-black-font"
                    style="align-items: center; display: flex">
                    <img width="18" src="/img/icons/delete.png" />
                    EXCLUIR
                  </a>
                </base-dropdown>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12" style="display: flex; align-items: center;">
                <h4 class="new-default-black-font m-0">
                  {{ item.bank_account_name }}
                </h4>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-12" style="display: flex; align-items: baseline;">
                <h3 v-if="!item.finance_type_mode" class="m-0 mr-1 p-0 text-success">+</h3>
                <h3 v-else class="m-0 mr-1 p-0 text-danger">-</h3>
                <h3 class="m-0 p-0 mr-1 new-default-black-font">R$</h3>
                <h1 class="m-0 p-0 mr-3">
                  {{ item.amount | currency('') }}
                </h1>
              </div>
            </div>
            <div class="row mt-0 mb-2 align-items-center">
              <div class="col-12">
                <h5 class="font-weight-normal new-default-gray-font m-0 mr-2">
                  {{ item.expire_at | parseDate("DD MMM YYYY") }}
                </h5>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <hr class="m-0">
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <h5 class="new-default-gray-font font-weight-normal m-0">
                  {{ item.finance_type_name }}
                </h5>
                <h4 class="new-default-black-font font-weight-400 m-0 mt-1">
                  {{ item.entity_name }}
                </h4>
                <h4 class="font-weight-normal new-default-black-font m-0 mt-1 mr-2">
                  {{ item.entity_document }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { STATUS_ENUM } from "./Enums/StatusEnum";
import { STATUS_ACTION } from "./Enums/StatusAction";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";

export default {
  name: "CardsFinances",
  props: {
    finances: Array,
    loadingSkeleton: Boolean,
    getStatus: { type: Function },
  },
  components: {
    SkeletonPuzlGrid
  },
  data() {
    return {
      statusEnum: STATUS_ENUM,
      statusAction: STATUS_ACTION,
    };
  },
  methods: {},
};
</script>

<style scoped>
* {
  font-family: Fredoka;
}

.card {
  box-shadow: 0px 5px 10px 0px #0000000D !important;
  border-radius: 8px !important;
  border: 1px solid #ECECEC;
}

.outline-success-button {
  border: 1px solid #149e57;
  color: #149e57;
  font-size: 10px;
  padding: 1px 5px;
  border-radius: 10px;
}

.card-with-box-shadow{
  box-shadow: 0 2px 4px 0 #f3f3f3;
  border: 1px solid #E8E8E8;
  box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15);
  border-radius: 10px !important;
}

.grid-card {
  display: grid;
  grid-template-columns: 60% 40%;
  overflow: hidden;
  height: 70px;
  margin-bottom: 10px;
}

.gray-bg {
  background-color: #e6e6e6;
  box-shadow: 0 0 5px 0 #6a6a6a52;;
}

.blue-bg {
  background-color: #4182e52e;
  box-shadow: 0 0 5px 0 #0b7bfd52;
}

.red-bg {
  background-color: #f459592e;
  box-shadow: 0 0 5px 0 #d2272752;
}

.invisible-text {
  color: rgba(0,0,0,0);
}

.new-cardwithbox-shadow {
  text-align: center;
  box-shadow: 0 5px 10px 0 #f3f3f3;
  display: flex;
  border: 1px solid #E8E8E8;
  border-radius: 5px !important;
  align-items: center;
  padding: 4px 0px;
}

.new-minicard-green {
  background-color: #F2F7F3;
  border-radius: 15px;
  padding: 5px 12px;
  font-size: 12px;
}

.new-minicard-red {
  background-color: #FCF3F3;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-yellow {
  background-color: #FEF9F2;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-blue {
  background-color: #F2F4F9;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-gray {
  background-color: #646e7d2e;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-green h4 {
  color: #149e57;
}

.new-minicard-red h4 {
  color: #db4539;
}

.new-minicard-yellow h4 {
  color: #f2b532;
}

.new-minicard-blue h4 {
  color: #1b6eba;
}

.new-minicard-gray h4 {
  color: #66696c;
}

.new-minicard-red, .new-minicard-green, .new-minicard-yellow, .new-minicard-blue, .new-minicard-gray {
  align-items: center;
  display: inline-flex;
  text-align: center;
}

.new-minicard-red img:first-child, .new-minicard-green img:first-child, .new-minicard-yellow img:first-child, .new-minicard-blue img:first-child, .new-minicard-gray img:first-child {
  width: 16px;
}

.new-minicard-red h4, .new-minicard-green h4, .new-minicard-yellow h4, .new-minicard-blue h4, .new-minicard-gray h4 {
  font-size: 12px;
  margin: 0 6px 0 6px;
  font-weight: normal !important;
}
</style>
