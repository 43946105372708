import { render, staticRenderFns } from "./_ModalEditDosage.vue?vue&type=template&id=13f44028&scoped=true"
import script from "./_ModalEditDosage.vue?vue&type=script&lang=js"
export * from "./_ModalEditDosage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13f44028",
  null
  
)

export default component.exports