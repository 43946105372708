<template>
  <div>
    <modal :show.sync="modal.create" size="lg">
      <template slot="header">
        <h5 class="modal-title pl-2 ml-1">{{ modal.title }}</h5>
      </template>
      <multi-filter :status="multi_filter" @fetch="init" :filter="{}" />
      <div class="container-fluid">
        <div class="row card-wrapper">
          <SkeletonPuzlGrid v-for="index in 3" v-show="loading" :key="index" />
        </div>
        <div class="row align-items-center" v-if="!$_bank_accounts.length && !loading">
          <div class="col-md-12 text-center">
            <div class="card p-4">Sem dados cadastrados!</div>
          </div>
        </div>
        <div class="row" v-if="$_bank_accounts.length && !loading">
          <div class="col-md-12" v-for="(item, index) in $_bank_accounts" :key="item.id">
            <div
              class="border-left border-3 d-flex justify-content-between p-2 mb-4"
              :class="item.status ? 'border-success' : 'border-danger'"
            >
              <div class="col-10">
                <div class="mt-2">
                  <h5 class="p-0 m-0">
                    {{ item.name || item.business_name }}
                  </h5>
                  <h5 class="font-weight-normal m-0">{{ item.code }} | {{ item.bank_name }}</h5>
                  <h5 class="font-weight-normal m-0">Ag. {{ item.number_without_digit_agency }}-{{ item.digit_agency }}</h5>
                  <h5 class="font-weight-normal m-0">
                    C/{{ item.account_type }}: {{ item.number_without_digit_account }}-{{ item.digit_account }}
                  </h5>
                </div>
                <collapse class="border rounded p-0 mt-3">
                  <collapse-item class="header-gray" back-ground="border-header-primary-bottom">
                    <h5 slot="title" class="mb-0">Detalhes</h5>
                    <h5 class="mb-0">Ajuste de saldo</h5>
                    <p class="p-0 m-0">
                      {{ item.balance | currency() }}
                    </p>
                    <div v-show="item.observation">
                      <h5 class="mb-0">Observações</h5>
                      <p class="p-0 m-0">
                        {{ item.observation }}
                      </p>
                    </div>
                  </collapse-item>
                </collapse>
              </div>
              <div class="col-2 d-flex justify-content-center align-items-center">
                <base-button size="sm" type="success" @click="handleSelect(item.id, index)">
                  <i class="fa fa-check text-white"></i>
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import MultiFilter from "@/components/Utils/MultiFilterV2.vue";
import { mapGetters } from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";

export default {
  name: "ModalAddBankAccount",
  components: { MultiFilter, SkeletonPuzlGrid },
  data() {
    return {
      modal: {
        create: false,
        title: "Buscar Conta Bancária",
      },
      multi_filter: {
        null: {
          name: "Todos",
          type: "default",
        },
      },
      loading: false,
      company_plant_id: null,
    };
  },
  computed: {
    ...mapGetters({
      $_bank_accounts: "bankAccount/fetch",
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal(company_plant_id) {
      this.company_plant_id = company_plant_id;
      this.modal.create = true;
      this.init();
    },
    handleSelect(id, index) {
      let loader = this.$loading.show();
      this.loading = true;
      this.$notify({ type: "info", message: "Estamos trabalhando em sua solicitação." });
      this.$store
        .dispatch("companyPlantBankAccount/add", { company_plant_id: this.company_plant_id, bank_account_id: id })
        .then((response) => {
          this.loading = false;
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.$emit("addedBankAccount", response.data);
          this.$_bank_accounts.splice(index, 1);
          loader.hide();
        });
    },
    init(filter = null) {
      let loader = this.$loading.show();
      this.loading = true;
      let params = {
        filter: {
          not_in_company_plant_has_bank_accounts: true,
          company_plant_id: this.company_plant_id,
          global: filter ? filter.global : [],
          status: true,
        },
      };
      this.$notify({ type: "info", message: "Estamos trabalhando em sua solicitação." });
      this.$store.dispatch("bankAccount/fetchItems", params).then((response) => {
        loader.hide();
        this.loading = false;
        this.$notify({
          type: response.error_type,
          message: response.message,
        });
      });
    },
  },
};
</script>

<style scoped></style>
