import CmcListPage from "../views/list/CmcListPage.vue";

export default [
  {
    path: '/technology/mix/commercial-reference-resume',
    name: 'technology.mix.commercial-reference-resume.view',
    component: CmcListPage,
    meta: {
      title: 'Refência comercial',
      description: 'Refência comercial',
      icon: 'fa fa-home',
      permission: 'technology.mix.commercial-reference-resume.index.view',
      breadcrumbs: [
        {title: 'Dashboard', name: 'technology.mix.commercial-reference-resume.dashboard.index'},
        {title: 'Refência comercial', name: null},
      ]
    },
  },
];
