<template>
  <div>
    <modal size="sm" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div v-show="isLoading">
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 m-0 p-0 mb-1 col-form-label form-control-label">
                  <SkeletonPuzl type="button"></SkeletonPuzl>
                </label>
                <div class="col-md-3">
                  <SkeletonPuzl type="button"></SkeletonPuzl>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 m-0 p-0 mb-1 col-form-label form-control-label">
                  <SkeletonPuzl type="button"></SkeletonPuzl>
                </label>
                <div class="col-md-3">
                  <SkeletonPuzl type="button"></SkeletonPuzl>
                </div>
              </div>
            </div>
            <div style="height: 40vh;overflow-x: auto">
              <div v-for="(item, index) in list" :key="index">
                <div class="form-group row m-0 p-0 mb-1">
                  <div class="col-8">
                    <div class="row">
                      <div class="col-12">
                        <label class="text-center mt-n3 col-form-label form-control-label">
                          {{ item.label }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 text-center">
                    <base-input input-group-classes="input-group-sm">
                      <input-limit
                        :start_value="item.percentage"
                        :options="{
                          min: -50,
                          max: 99,
                        }"
                        v-model="item.percentage"
                        @value="item.percentage = $event"
                      ><small>%</small>
                      </input-limit>
                    </base-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')">
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loading">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>
<script>
import SkeletonPuzl from '@/components/SkeletonPuzl';
import {checkNumberValue} from "@/helpers";
import {mask1} from "@/plugins";
import InputLimit from "@/components/Utils/InputLimit.vue";

export default {
  components: {
    InputLimit,
    SkeletonPuzl,
  },
  name: "ModalCreateLevelResponsibility",
  data() {
    return {
      modal: {
        title: '',
        create: false,
      },
      filter: {},
      validated: false,
      list: {},
      percentage: {},
      isLoading: false,
      loading: false,
    };
  },
  methods: {
    closeModal(name) {
      this.modal[name] = false
    },
    openModal(name) {
      this.modal[name] = true
    },
    handleCreate() {
      this.init();
      this.modal.title = 'Acréscimo sobre Nível de responsabilidade';
    },
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        return checkNumberValue(mask1(value.toString(), decimals, '.', true), min, max);
      }
      return checkNumberValue(value, min, max);
    },
    store() {
      const percentages = {};
      const lists = this.list;
      lists.forEach(function (item, index) {
        percentages[item.value] = item.percentage || 0
      });
      this.$Progress.start();
      this.loading = true;
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch('mixConcretePiece/updateLevelResponsibilityPercentage', {percentages: percentages})
        .then((response) => {
          this.$notify({
            type: response.error_type,
            message: response.message
          })
          this.closeModal('create');
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.$notify({type: error.response.data.error_type, message: error.response.data.message});
          this.loading = false;
        });
    },
    init(filter = {}) {
      this.filter = filter;
      this.$Progress.start();
      this.isLoading = true;
      let loader = this.$loading.show()
      this.$store.dispatch('mixConcretePiece/getFeatureLevels').then(response => {
        this.isLoading = false
        this.list = response.data
        loader.hide()
        this.openModal('create');
        this.$Progress.finish()
      })
    },
  },
  mounted() {
    this.$refs.formValidator.validate();
    EventBus.$on("handleCreateLevelResponsibility", () => {
      this.handleCreate();
    });
  }
};
</script>
<style></style>

