<template>
  <div>
    <li :class="isRoot ? 'root-node' : 'child-node'">
      <div class="d-flex align-items-center" style="gap: 5px;">
        <span v-if="hasChildren" @click="toggle">
          <img v-if="expanded" src="/img/icons/icons8/ios/minus_key.png" alt="minus_key" width="24px">
          <img v-else src="/img/icons/icons8/ios/plus_key.png" alt="plus_key" width="24px">
        </span>
        <span @click="handleEditModal(node.id)" :class="isRoot ? 'font-root' : 'font-child'">
          {{ node.ordered_code }} {{ node.name }}
        </span>
        <span v-if="!node.dre" class="dre-flag align-self-center">DRE</span>
      </div>
      <hr v-if="isRoot && expanded && hasChildren">
      <ul v-if="expanded && hasChildren" class="">
        <tree-node v-for="child in node.childrens" :key="child.id" :node="child" @init="init"></tree-node>
      </ul>
    </li>
    <ModalEditChartAccount ref="modalEditChartAccount" @init="init" />
  </div>
</template>

<script>
import ModalEditChartAccount from "@/views/Modules/Configuration/ChartAccount/Shared/_ModalEdit";

export default {
  name: 'TreeNode',
  components: {
    ModalEditChartAccount,
  },
  props: {
    node: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      expanded: false
    };
  },
  computed: {
    hasChildren() {
      return this.node.childrens && this.node.childrens.length;
    },
    isRoot() {
      return this.node.chart_account_parent_uuid == null;
    }
  },
  methods: {
    toggle() {
      if (this.hasChildren) {
        this.expanded = !this.expanded;
      }
    },
    handleEditModal(id) {
      this.$refs.modalEditChartAccount.handleEditModal(id, this.hasChildren ? true : false);
    },
    init() {
      this.$emit('init');
    }
  }
};
</script>

<style scoped>
ul {
  list-style-type: none;
  margin:0;
}
li {
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 16px;
}
hr {
  border: 0;
  border-top: 0.5px solid #E8E8E8;
  margin: 13px 0;
}
.font-root {
  font-size: 16px;
  font-weight: 500;
  color: #2B2D32;
}
.font-child {
  font-size: 14px;
  font-weight: 400;
  color: #2B2D32;
}
.dre-flag {
  padding: 2px 8px;
  border: 0.5px solid #DB4539;
  border-radius: 8px;
  color: #DB4539;
  font-size: 9px;
  font-weight: 400;
}
.root-node {
  padding: 15px;
  margin: 8px 0;
}
.child-node {
  padding: 5px;
  margin: 3px 0;
}
</style>
