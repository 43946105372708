<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div class="pt-3 pr-4 pl-4 pb-3" :style="{height: (modalSize-150) + 'px', maxHeight: (modalSize-150) + 'px'}" v-if="cmc != null">
        <div class="row">
          <div class="col-12">
            <!-- Title -->
            <h5 class="h2 mb-0 new-default-black-font">{{ cmc.cmc_product.product_name }}
              <small v-if="cmc.cmc_additional_classification">
                ({{cmc.cmc_additional_classification}})
              </small>
            </h5>
            <h5 class="mt-1 mb-0 new-default-black-font">{{ cmc.cmc_category.name }}</h5>
          </div>
        </div>
        <h4 class="mt-1 mb-0 new-default-gray">{{ cmc.cmc_supplier.supplier_name }} | {{ cmc.cmc_supplier_origin.origin_name }}</h4>
        <hr class="blue-divider mt-2 mb-2">
        <div class="d-flex w-100 mt-2 mb-3" style="height: 2.7rem;">
          <input @input="searchItems"
            v-model="query"
            placeholder="Pesquisar"
            class="form-control search-input" />
          <img @click.prevent="searchItems" src="/img/icons/icons8/ios/search.png" height="32"
            class="default-search zoom pointer">
        </div>
        <div class="plant-boxes" :style="{height: (modalSize-330) + 'px', maxHeight: (modalSize-330) + 'px'}">
          <div v-if="filteredItems.cmc_price_current && filteredItems.cmc_price_next" v-for="(itemCmcPrice, index) in filteredItems.cmc_plant" :key="index"
            class="card main-card" style="overflow: hidden;">
            <div class="card-header pointer"
              style="padding: 0px !important;"
              @click.prevent="
                filteredItems.cmc_price_current[index] &&
                handleEnableDisable(
                  cmc.id,
                  filteredItems.cmc_price_current[index][0].plant_id,
                  filteredItems.cmc_price_current[index][0].is_active,
                  index,
                  indexCmc
                );
                closeModal()
                ">
              <div class="pt-1 pb-1 btn-header text-center text-white text-uppercase"
                :style="filteredItems.cmc_price_current[index] && Number(filteredItems.cmc_price_current[index][0].is_active) == 1 ? 'background: #149E57' : 'background: #DB4539'"
                style="opacity: 92%;color: #4f40ff block;width: 100%;border: none;">
                <img style="margin-bottom: 3px;" class="mr-1" v-if="filteredItems.cmc_price_current[index] && Number(filteredItems.cmc_price_current[index][0].is_active) == 1" width="18" src="/img/icons/icons8/ios/ok--v1.png" />
                <img v-else-if="filteredItems.cmc_price_current[index]" style="margin-bottom: 3px;" class="mr-1" width="18" src="/img/icons/icons8/ios/cancel.png" />
                {{ index }}
                <span v-if="filteredItems.cmc_price_current[index]">{{ Number(filteredItems.cmc_price_current[index][0].is_active) == 1 ? "(ATIVO)" : "(INATIVO)" }} </span>
              </div>
            </div>
            <div v-for="(itemCmcPriceCurrent,
                indexPriceCurrent) in filteredItems.cmc_price_current"
                :key="indexPriceCurrent">
              <div v-for="listCurrent in itemCmcPriceCurrent"
                :key="listCurrent.id"
                class="mt-2 mr-4 ml-4 mb-3"
                v-if="index === indexPriceCurrent">
                <div class="row mt-1 mb-3 align-items-center">
                  <div class="col-12 font-weight-600 text-success" style="display: flex;align-items: center;">
                    VALOR VIGENTE
                    <el-popover class="pb-1 ml-2" placement="right" trigger="hover">
                      <span>
                        <div class="text-center">{{ listCurrent.user_name }}</div>
                        <div class="text-center">{{ listCurrent.created_at }}</div>
                      </span>
                      <span class="pointer" slot="reference">
                        <img width="18" src="/img/icons/icons8/ios/user-male-circle--v1.png">
                      </span>
                    </el-popover>
                  </div>
                </div>
                <div
                  class="card mini-card">
                  <div>
                    <h2 class="checklist-title mb-0 ml-2 mt-1 new-default-black-font">
                      {{ listCurrent.total_price | currency() }} /{{
                      cmc.measured_unit_id_buy.initials
                      }}
                    </h2>
                    <h6 class="ml-2 new-default-black-font">
                      {{ listCurrent.validity_start }}
                    </h6>
                  </div>
                  <div class="p-3">
                    <small class="font-weight-bold new-default-black-font">
                      PREÇO (SECO) R$ {{ pricePerKg(
                      listCurrent.total_price,
                      cmc.measured_unit_id_buy.id,
                      $helper.strToNum(cmc.bulk_density),
                      cmc.average_humidity | 0
                    ).toFixed(5).toString().replace('.', ',') }} /kg
                    </small>
                    <small class="new-default-black-font font-weight-bold">
                      UMIDADE: <i v-if="cmc.average_humidity > 0" class="fa-solid fa-droplet text-info"></i> {{ cmc.average_humidity }}%
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="filteredItems.cmc_price_next" v-for="(itemCmcPriceNext,
              indexPriceNext) in filteredItems.cmc_price_next"
              >
              <div v-for="listNext in itemCmcPriceNext"
                :key="listNext.id"
                class="mt-2 mr-4 ml-4 mb-3"
                v-if="index === indexPriceNext">
                <hr v-if="filteredItems.cmc_price_current[index]" class="blue-divider mt-2 mb-2">
                <div class="row mt-3 mb-3 align-items-center">
                  <div class="col-8 font-weight-600 text-warning" style="display: flex;align-items: center;">
                    VALOR FUTURO
                    <el-popover class="pb-1 ml-2" placement="right" trigger="hover">
                      <span>
                        <div class="text-center">{{ listNext.user_name }}</div>
                        <div class="text-center">{{ listNext.updated_at }}</div>
                      </span>
                      <span class="pointer" slot="reference">
                        <img width="18" src="/img/icons/icons8/ios/user-male-circle--v1.png">
                      </span>
                    </el-popover>
                  </div>
                  <div class="col-4 text-right">
                    <span style="width: 31px;display: flex; align-items: center;
                      border: 0.02em solid rgba(219, 69, 57, 0.6);;border-radius: 16px;"
                      class="btn btn-sm text-center float-right"
                      @click="handleDeleteCmcPrice(listNext.id, index, indexCmc); closeModal()">
                      <img width="14" src="/img/icons/icons8/ios/delete_danger.png">
                    </span>
                  </div>
                </div>
                <div
                  class="card mini-card">
                  <div>
                    <h2
                      class="checklist-title mb-0 ml-2 mt-1 new-default-black-font">
                      {{ listNext.total_price | currency() }} /{{
                      cmc.measured_unit_id_buy.initials
                      }}
                    </h2>
                    <h6 class="ml-2 new-default-black-font">
                      {{ listNext.validity_start }}
                    </h6>
                  </div>
                  <div class="p-3">
                    <small class="font-weight-bold new-default-black-font">
                      PREÇO (SECO) R$ {{ pricePerKg(
                      listNext.total_price,
                      cmc.measured_unit_id_buy.id,
                      $helper.strToNum(cmc.bulk_density),
                      cmc.average_humidity | 0
                    ).toFixed(5).toString().replace('.', ',') }} /kg
                    </small>
                    <small class="new-default-black-font font-weight-bold">
                      UMIDADE: <i v-if="cmc.average_humidity > 0" class="fa-solid fa-droplet text-info"></i> {{ cmc.average_humidity }}%
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import PuzlSelect from "@/components/PuzlSelect";
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";

export default {
  name: "ModalShowCmcPrice",
  props: {
    pricePerKg: { type: Function },
    handleDeleteCmcPrice: { type: Function },
    handleEnableDisable: { type: Function },
  },
  components: {
    PuzlSelect,
    BaseButtonHoverable
  },
  data() {
    return {
      modal: false,
      title: 'PREÇO MCC',
      cmc: null,
      query: "",
      modalSize: 0,
      filteredItems: [],
      indexCmc: null,
    };
  },
  methods: {
    openModal(open) {
      this.modal = open;
      this.modalSize = window.innerHeight;
    },
    closeModal() {
      this.modal = false;
    },
    searchItems(){
      let newFilteredItems = {
        cmc_plant: {},
        cmc_price_current: {},
        cmc_price_next: {}
      };

      for(let plant in this.cmc.cmc_plant){
        if(plant.toLowerCase().includes(this.query.toLowerCase()))
        {
          newFilteredItems.cmc_plant[plant] = null;
          if (this.cmc.cmc_price_current[plant]) {
            newFilteredItems.cmc_price_current[plant] = this.cmc.cmc_price_current[plant];
          }
          if(this.cmc.cmc_price_next[plant]) {
            newFilteredItems.cmc_price_next[plant] = this.cmc.cmc_price_next[plant];
          }
        } 
      }
      this.filteredItems = newFilteredItems;
    },
    updateModal(cmc, indexCmc){
      this.cmc = cmc;
      this.filteredItems = {
        cmc_price_current: cmc.cmc_price_current,
        cmc_price_next: cmc.cmc_price_next,
        cmc_plant: cmc.cmc_plant
      };
      this.indexCmc = indexCmc;
    },
    handleCreate(cmc, indexCmc) {
      this.updateModal(cmc, indexCmc);
      this.openModal(true);
    },
  },
};

</script>
<style scoped>
.new-default-gray {
  opacity: 0.5;
}

.plant-boxes {
  overflow: auto;
}

.blue-divider {
  background-color: #1b6eba;
  color: #1b6eba;
  height: 0.1px;
}

.main-card {
  border-radius: 10px;
  border: 1px solid #E8E8E8;
  box-shadow: none !important;
}
.card {
  border-radius: 10px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
}

.search-input {
  width: 100%;
  height: 2.7rem;
  border-radius: 4px !important;
  -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.mini-card {
    display: grid;
    grid-template-columns: 46% 55%;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    padding: 0;
  }

  .mini-card div:first-child {
    background-color: rgba(43, 45, 50, 0.10);
    color: #2B2D32;
  }

  .mini-card div:first-child small, .mini-card div:first-child h5 {
    color: #2B2D32;
  }

  .mini-card div {
    display: inline-grid;
    padding: 8px;
    padding-top: 9.14px;
    padding-bottom: 9.14px;
  }

  .mini-card div h5 {
    font-weight: 600 !important;
    font-size: 14px;
  }

  .mini-card div:first-child h5 {
    font-size: 16px !important;
  }

  .mini-card div:first-child small {
    font-size: 9px !important;
  }

  .mini-card div small {
    font-size: 10px !important;
    font-weight: 500 !important;
  }
</style>
