<template>
  <div>
    <div class="container-fluid">
      <i
        class="pointer fa-regular fa-hard-drive fa-2x"
        :class="!listCard && 'opacity-25'"
        @click="listCard = true, listTable = false"
      ></i>
      <i
        class="pointer fa-solid fa-table-list fa-2x ml-1 mr-2"
        :class="!listTable && 'opacity-25'"
        @click="listTable = true, listCard = false"
      ></i>
      <MultiFilter 
        :status="multi_filter"
        @clearFilters="clearFilters"
        ref="multi-filter"
        @fetch="init"
        :filter="filter"
      >
        <div class="col-md-4 p-2">
          <InputDatePicker
            :filterable="4"
            ref="date-picker"
            @handleFilterDate="handleFilterDate"
            :default-range="range" />
        </div>
      </MultiFilter>
      <div class="row card-wrapper" v-show="loadingSkeleton && listCard">
        <SkeletonPuzlGrid v-for="index in 3" :key="index" />
      </div>
      <!-- Dados vazios -->
      <PuzlEmptyData v-if="!$_items.length && !loadingSkeleton" />
      <div class="row card-wrapper" v-if="$_items.length && !loadingSkeleton && listCard">
        <div class="col-lg-4" v-for="(item, index) in $_items" :key="index">
          <card :class="`card-border-top-${multi_filter[item.status].type}`">
            <h3 class="mb-3">{{ item.company_plant_name }}</h3>
            <h3 class="mb-0">{{ item.category_name }}</h3>
            <h4 class="mb-0 font-weight-normal">{{ item.product_name }}</h4>
            <h4 class="mb-3 font-weight-normal">{{ item.supplier_name }} ( {{ item.origin_name }} )</h4>
            <div class="border-left border-success border-3 p-2 pb-0 mb-2">
              <h3 class="mb-0 font-weight-normal">{{ item.cmc_price_updated_at | parseDate("DD MMM YYYY") }}</h3>
              <h3 class="mb-0 font-weight-normal">{{ item.cmc_price | currency }} / {{ item.cmc_purchase_unit }}</h3>
            </div>
            <div class="border-left border-danger border-3 p-2 pb-0 row m-0">
              <div class="col-10 p-0">
                <h3 class="mb-0 font-weight-normal">{{ item.created_at | parseDate("DD MMM YYYY") }}</h3>
                <h3 class="mb-0 font-weight-normal">{{ item.quantity_of_items }} NFe</h3>
                <h3 class="mb-0 font-weight-normal">{{ item.entry_launch_item_price | currency }} / {{ item.cmc_purchase_unit }}</h3>
              </div>
              <div class="col-2 d-flex justify-content-center align-items-center">
                <a @click.prevent="handleModalCmcDivergentDocument(item)" role="button" class="custom-border-radius text-center">
                  <i class="fas fa-bars text-white text-center bg-indigo p-2 custom-border-radius" />
                </a>
              </div>
            </div>
            <div v-if="item.status === 0" class="row m-0 align-items-center mt-3">
              <div class="col-md-6 d-flex justify-content-center">
                <base-button
                  @click.prevent="openModalObservation(item, 2)"
                  outline
                  block
                  type="danger"
                  :disabled="loadingUpdatePrice"
                  size="sm"
                  class="text-uppercase mb-1 pl-2 pr-2 pt-2 pb-2"
                >
                  Ignorar
                </base-button>
              </div>
              <div class="col-md-6 d-flex justify-content-center">
                <base-button
                  @click.prevent="openModalObservation(item, 1)" 
                  outline
                  block
                  type="primary"
                  :disabled="loadingUpdatePrice"
                  size="sm"
                  class="text-uppercase mb-1 pl-2 pr-2 pt-2 pb-2"
                >
                  Atualizar
                </base-button>
              </div>
            </div>
            <div class="mt-3 text-center" v-else-if="item.user_name && item.divergent_price">
              <h5 class="font-weight-normal mb-0">
                {{ `${item.status === 1 ? 'Atualizado' : 'Ignorado'} por `}} <b>{{ item.user_name }} </b>
              </h5>
              <h5 class="font-weight-normal mb-0">
                {{ item.updated_at | parseDate }} 
                <el-popover v-if="item.observation" trigger="hover" placement="bottom" class="p-0">
                  {{ item.observation_creator_name }} | {{ item.observation_creation_timestamp | parseDate('DD/MM/YYYY HH:mm') }}
                  <hr class="bg-ligth m-0 mt-2 mb-2">
                  <div style="font-size: 12px">{{ item.observation }}</div>
                  <span slot="reference">
                    <img width="22px" height="22px" src="/img/icons/info.png" class="ml-2" />
                  </span>
                </el-popover>
              </h5>
            </div>
          </card>
        </div>
      </div>
      <div v-if="listTable">
        <Table
          :loadingSkeleton="loadingSkeleton"
          @handleModalCmcDivergentDocument="handleModalCmcDivergentDocument"
          @openModalObservation="openModalObservation"
        />
      </div>
      <LoadingPagination :show="loading && !loadingSkeleton" />
    </div>
    <ModalCmcDivergentPriceDocument ref="modalCmcDivergentPriceDocument" />
    <ModalObservation @updateObservation="updateObservation" ref="modalObservation" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import MultiFilter from "@/components/Utils/MultiFilterV2.vue";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import InputDatePicker from "@/components/InputDatePicker";
import moment from "moment";
import ModalCmcDivergentPriceDocument from "./_ModalDocument.vue";
import ModalObservation from "./_ModalObservation";
import Table from "./_Table";

export default {
  name: "ListEntryLaunchPrice",
  mixins: [cursorPaginate],
  components: {
    SkeletonPuzlGrid,
    PuzlEmptyData,
    MultiFilter,
    LoadingPagination,
    InputDatePicker,
    ModalCmcDivergentPriceDocument,
    ModalObservation,
    Table,
  },
  data() {
    return {
      loadingSkeleton: true,
      loading: false,
      loadingUpdatePrice: false,
      filter: {},
      selectedCmcDivergent: null,
      selectedStatus: null,
      multi_filter: {
        null: {
          name: "Todos",
          type: "primary",
        },
        0: {
          name: "Pendente",
          type: "warning",
        },
        1: {
          name: "Atualizado",
          type: "success",
        },
        2: {
          name: "Ignorado",
          type: "danger",
        },
      },
      range: {
        start: new Date(),
        end: new Date(),
        default_filterable: false,
      },
      listCard: true,
      listTable: false,
    };
  },
  computed: {
    ...mapGetters({
      $_items: "cmcDivergentPrice/fetch",
    }),
  },
  methods: {
    handleModalCmcDivergentDocument(cmc) {
      this.$refs.modalCmcDivergentPriceDocument.openModal(cmc);
    },
    async updatePrice(cmc, status) {
      this.loadingUpdatePrice = true;
      this.$Progress.start();
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });
      if (status === 1) {
        let payload = {
          cmc_id: cmc.cmc_id,
          company_plant_id: cmc.company_plant_id,
          type: 1,
          price: cmc.entry_launch_item_price,
          validity_start: moment().format("YYYY-MM-DD"),
        };
        await this.$store.dispatch("cmc/addPrice", payload).then(async () => {
          await this.storeCmcDivergentPrice(
            { id: cmc.id, divergent_price: cmc.cmc_price, status: status, observation: cmc.observation }
          );
        });
      } else {
        await this.storeCmcDivergentPrice(
          { id: cmc.id, divergent_price: cmc.cmc_price, status: status, observation: cmc.observation }
        );
      }
      this.$refs.modalObservation.closeModal();
      this.loadingUpdatePrice = false;
      this.$Progress.finish();
      this.init({});
    },
    init(filter = null) {
      this.startCursor(filter);
      this.$store
        .dispatch("cmcDivergentPrice/fetch", {
          filter: this.filter,
          next_page: this.paginate.nextUrl,
        })
        .then((response) => {
          this.resolveCursor(response);
        })
        .catch((error) => {
          this.resolveCursor();
        });
    },
    handleFilterDate(filter) {
      this.range = filter;
      this.init({});
    },
    clearFilters() {
      this.loadingSkeleton = true;
      this.filter = {};
      this.$refs["date-picker"].resetDate();
      this.range = {
        start: moment().startOf("month").format("YYYY-MM-DD hh:mm:ss"),
        end: moment().endOf("month").format("YYYY-MM-DD hh:mm:ss"),
      };
      this.init();
    },
    storeCmcDivergentPrice(data) {
      return this.$store.dispatch("cmcDivergentPrice/update", data)
       .then(response => this.$notify({ type: response.error_type, message: response.message }));
    },
    /**
     * Abre modal para informar justificativa.
     * @param {string} selectedCmcDivergent
     * @param {number} selectedStatus
     */
    openModalObservation(selectedCmcDivergent, selectedStatus) {
      this.selectedCmcDivergent = selectedCmcDivergent;
      this.selectedStatus = selectedStatus;
      this.$refs.modalObservation.openModal(selectedStatus);
    },
    /**
     * Atualiza com justificativa da modal.
     * @param {string} observation
     */
    updateObservation(observation) {
      this.selectedCmcDivergent.observation = observation;
      this.updatePrice(this.selectedCmcDivergent, this.selectedStatus);
    },
  },
  mounted() {
    this.filter.status = 0;
    this.init();
  },
};
</script>

<style scoped>
.custom-border-radius .fa-bars {
  border-radius: 1.375rem !important;
}
</style>
