<template>
  <div>
    <div class="container-fluid">
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <PuzlEmptyData v-if="!contracts.length && !loadingSkeleton"></PuzlEmptyData>
      <div class="row card-wrapper" v-if="contracts.length && !loadingSkeleton">
        <div class="col-lg-4" v-for="(contract, index) in contracts" :key="index">
          <card :class="contract.status ? 'card-border-top-success' : 'card-border-top-danger'">
            <div class="row align-items-center mb-3">
              <div class="col-5">
                <base-button 
                  :class="{active: contract.puzl}"
                  @click.prevent="updateDefaultPuzl(contract)" 
                  class="text-uppercase" 
                  type="primary" 
                  outline
                >
                  <span style="font-size: 11px;" class="text-white"> 
                    Padrão puzl 
                  </span>
                </base-button>
              </div>
              <div class="col-6 ml-n4 d-flex align-items-center">
                <input 
                  v-model="contract.is_hide_total_amount" 
                  type="checkbox"
                  @click="updateHideTotalAmount(contract)" /> 
                <span 
                  style="font-size: 9.9px;"
                  class="ml-1"
                  @click="updateHideTotalAmount(contract)"
                > 
                  Ocultar valor total 
                </span>
              </div>
              <div class="col-1 ml-n3">
                <base-dropdown menuOnRight>
                  <base-button
                    slot="title-container"
                    type="primary"
                    class="dropdown-toggle p-2 rounded m-0"
                  >
                    <i class="fas fa-cog"></i>
                  </base-button>
                  <router-link
                    :to="{
                  path: '/commercial/contract-proposal/contracts/pages',
                  name: 'commercial.contract-proposal.pages.index',
                  params: { contract_uuid: contract.uuid }
                }"
                  >
                    <a class="dropdown-item">
                      <i class="fas fa-file text-primary"></i>
                      Revisões
                    </a>
                  </router-link>

                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click.prevent="handleEdit(contract.uuid)">
                    <i class="fas fa-edit text-warning"></i>
                    Editar
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item"
                     @click.prevent="handleDelete(contract.uuid)"
                  >
                    <i class="fas fa-times text-danger"></i>
                    Excluir
                  </a>
                </base-dropdown>
              </div>

              <div class="col-12 mt-2">
                <h5 class="h3 mb-0 m-0 p-0">{{ contract.name }}</h5>
              </div>
            </div>
            <!-- Card body -->
            <h5>{{ contract.user }} - <small>{{ contract.updated_at }}</small></h5>
            <collapse class="border rounded p-0 mt-3" v-if="contract.pages && contract.pages.length">
              <collapse-item
                class="header-gray"
                back-ground="border-header-primary-bottom"
              >
                <h5 slot="title" class="mb-0 ">Revisões</h5>
                <div
                  class="row align-items-center pb-0 mb-3"
                  v-for="(page, indexPage) in contract.pages"
                  :key="indexPage"
                >
                  <div class="col-md-12">
                    <div class="checklist-item checklist-item-primary">
                      <div class="checklist-info">
                        <div class="row">
                          <div class="col-md-12">
                            <h5 class="h4">
                              <badge type="default">v.{{ page.version }}</badge>
                            </h5>
                            <small class="h4 small">Início da Vigência: {{ page.date_validity }}</small>
                            <h5 class="h4 small">Editado por: {{ page.user }}</h5>
                            <h5 class="h4 small">{{ page.updated_at }}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </collapse-item>
            </collapse>
          </card>
        </div>
      </div>
    </div>
    <EditContract ref="editContract"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import EditContract from './_Edit';

export default {
  name: "List",
  components: {
    SkeletonPuzlGrid,
    PuzlEmptyData,
    EditContract,
  },
  data() {
    return {
      loadingSkeleton: false,
      isHideTotalAmount: false,
    }
  },
  computed: {
    ...mapGetters({
      'contracts': 'contractContracts/fetch',
    }),
  },
  methods: {
    fetch() {
      this.loadingSkeleton = true
      this.$store.dispatch('contractContracts/fetchItems')
        .then(response => {
          this.loadingSkeleton = false
        })
        .catch(error => {
          this.loadingSkeleton = false
        })
    },
    handleEdit(uuid) {
      this.$refs.editContract.handleEditModal(uuid)
    },
    updateDefaultPuzl(contract) {
      let loader = this.$loading.show()
      this.$Progress.start()
      let item = {...contract}
      item.puzl = !contract.puzl
      this.$store.dispatch('contractContracts/update', item)
        .then(response => {
          loader.hide()
          this.$Progress.finish()
          this.$notify({type: response.error_type, message: response.message})
        })
    },
    handleDelete(uuid) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          this.$store.dispatch('contractContracts/destroy', uuid).then(response => {
            this.$notify({
              type: 'success',
              message: response.message
            });
          }).catch(error => {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            });
          })
          this.$Progress.finish();
        }
      }).catch(() => this.$Progress.finish())
    },
    /**
     * Desc
     * @param {integer} contract
     */
    updateHideTotalAmount(contract) {
      contract.is_hide_total_amount = +!contract.is_hide_total_amount;
      this.$store.dispatch('contractContracts/updateHideTotalAmount', {
        'is_hide_total_amount': contract.is_hide_total_amount,
        'uuid': contract.uuid,
      })
      .then(response => {
        this.$notify({
          type: 'success',
          message: 'Valor alterado com sucesso!'
        });
      });
    },
  },
  mounted() {
    this.fetch()
  },
}
</script>

<style scoped>

</style>
