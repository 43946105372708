import { render, staticRenderFns } from "./_ModalTaskAttachment.vue?vue&type=template&id=b1e23752&scoped=true"
import script from "./_ModalTaskAttachment.vue?vue&type=script&lang=js"
export * from "./_ModalTaskAttachment.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1e23752",
  null
  
)

export default component.exports