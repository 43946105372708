import { initAgentType, AgentType } from "../../cmc-adjustment/types";

/**
 * Serviço criado para retornar uma formatação
 * zerada de aditivos, necessário para não
 * conter aditivos nulos na listagem
 * 
 * @returns {Array<AgentType>}
 */
const execute = () => {
  return [
    {
      ...initAgentType(),
    },
    {
      ...initAgentType(),
    }
  ]
}

export default { execute };