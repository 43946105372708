<template>
    <div class="card-body mt-n2 p-0">
        <div class="row">
            <ShadedTable :length="items.length" :headers="headers" :loading="loadingSkeleton" :customHeaders="[0]">
                <template slot="header_0">
                  <input type="checkbox" @change="handleSelectAllItems($event)">
                </template>
                <template v-for="(item, index) in items" :slot="index">
                    <ShadedCol align="center" customStyle="margin-top: 8px;">
                      <div v-if="isClassified(item)">
                        <input type="checkbox" @click="item.checked = item.checked === 1 ? 0 : 1" :checked="item.checked" style="box-shadow: none !important;">
                      </div>
                    </ShadedCol>
                    <ShadedCol>
                      <span v-if="getColor(item) == 'success'" class="badge-custom badge-custom-success">
                      <img src="/img/icons/icons8/ios/star--v1_success.png" width="19px" height="19px">
                      <b>Classificado</b>
                    </span>
                    <span v-if="getColor(item) == 'warning'" class="badge-custom badge-warning">
                      <img src="/img/icons/icons8/ios/ratting-off.png" width="19px" height="19px">
                      <b>Sem Classif.</b>
                    </span>
                    <span v-if="getColor(item) == 'danger'" class="badge-custom badge-danger">
                      <img src="/img/icons/icons8/ios/cancel_danger.png" width="19px" height="19px">
                      <b>Cancelado</b>
                    </span>
                    </ShadedCol>
                    <ShadedCol style="width: 15px;" customStyle="margin-top: 7px;">
                      <div v-if="item.product_service_hub_type_id === ProductServiceHubTypeEnum.FREIGHT" style="display: flex; align-items: center;">
                        <div class="card-outline-grey text-dark mx-1">
                          CTE
                        </div>
                        <div v-if="item.referenced_entry_launch_is_imported" class="card-outline-success text-success mx-1">
                          NFE
                        </div>
                        <div v-else class="card-outline-warning text-warning mx-1">
                          NFE
                        </div>
                      </div>
                      <div style="display: flex; align-items: center;" v-else>
                        <div class="card-outline-grey text-dark mx-1">
                          NFE
                        </div>
                        <div v-if="item.referenced_entry_launch_is_imported" class="card-outline-success text-success mx-1">
                          CTE
                        </div>
                        <div v-else class="card-outline-warning text-warning mx-1">
                          CTE
                        </div>
                      </div>
                    </ShadedCol>
                    <ShadedCol customStyle="margin-top: 10px;">
                        <div class="text-center">
                            <h5 class="new-default-black-font font-weight-normal m-0">
                              {{ item.entry_date | parseDate('DD MMM YYYY') }}
                            </h5>
                        </div>
                    </ShadedCol>
                    <ShadedCol customStyle="margin-top: 10px;">
                        <div class="text">
                            <h5 class="new-default-black-font font-weight-normal m-0">
                              {{ item.document_number }}
                            </h5>
                        </div>
                    </ShadedCol>
                    <ShadedCol customStyle="margin-top: 10px;">
                        <h5 class="new-default-black-font font-weight-normal m-0">
                          {{ item.entity_name }}
                        </h5>
                    </ShadedCol>
                    <ShadedCol customStyle="margin-top: 8px;">
                        <div class="text-center">
                            <span class="text-dark" style="font-weight: 400; font-size: 12px;">
                                {{ parseEin(item.document) }}
                            </span>
                        </div>
                    </ShadedCol>
                    <ShadedCol customStyle="margin-top: 8px;">
                        <div class="text-center">
                            <b class="text-dark">
                                {{ item.final_value | currency() }}
                            </b>
                        </div>
                    </ShadedCol>
                    <ShadedCol customStyle="margin-top: 8px;">
                        <div class="text-center">
                            <base-dropdown>
                                <base-button slot="title-container" type="empty" size="sm" class="dropdown-toggle shadow-none mt--2 mr--2" style="border-radius: 4px !important;">
                                    <img height="30px" width="30px" src="/img/icons/icons8/ios/settings--v1_primary.png" class="mr-1 mt-n1"/>
                                </base-button>
                                <span v-if="getColor(item) == 'success' || getColor(item) == 'warning'" @click.prevent="$router.push(`/configuration/entry-launch/external-create/${company_plant_buyer.company_plant.id}/${company_plant_buyer.id}/${item.id}`);" class="dropdown-item">
                                    <img src="/img/icons/create-new.png" width="19px" height="19px" class="imgBaseButton">
                                    EDITAR
                                </span>
                                <div class=" dropdown-divider p-0 m-0"></div>
                                <!-- <span v-show="!filter.advanced" @click.prevent="handleModalImportEntryLaunch(item)" class="dropdown-item">
                                <img src="/img/icons/icons8/ios/downloading-updates.png" width="19px" height="19px" class="imgBaseButton">
                                Baixar Nota
                                </span> -->
                                <div v-if="!filter.advanced" class=" dropdown-divider p-0 m-0"></div>
                                <span v-if="getColor(item) == 'success' || getColor(item) == 'warning'" @click.prevent="$emit('handleCancellation', item.id, index)" class="dropdown-item">
                                  <img src="/img/icons/icons8/ios/cancel-2_danger.png" width="19px" height="19px" class="imgBaseButton">
                                  Cancelar
                                </span>
                                <span v-else @click.prevent="$emit('handleCancellation', item.id, index)" class="dropdown-item">
                                  <img src="/img/icons/icons8/ios/refresh--v1_success.png" width="19px" height="19px" class="imgBaseButton">
                                  Restaurar
                                </span>
                            </base-dropdown>
                        </div>
                    </ShadedCol>
                </template>
            </ShadedTable>
        </div>
    </div>
</template>
<script>
import SkeletonPuzl from "@/components/SkeletonPuzl";
import ShadedTable from "@/components/ShadedTable.vue";
import ShadedCol from "@/components/ShadedCol.vue";
import {ProductServiceHubTypeEnum} from "@/enum/ProductServiceHubTypeEnum";
import {EntryLaunchStatusEnum} from "@/enum/EntryLaunchStatusEnum";

export default {
  name: "Table",
  props: {
    loadingSkeleton: Boolean,
    items: Array,
    company_plant_buyer: Object
  },
  data() {
    return {
      headers: [
        "",
        "Status",
        "Validação",
        "Data da Nota",
        "N. do Documento",
        "Fornecedor",
        "CNPJ/CPF",
        "Valor",
        "Ação",
      ],
      filter:{},
      ProductServiceHubTypeEnum: ProductServiceHubTypeEnum,
      EntryLaunchStatusEnum: EntryLaunchStatusEnum,
      checkedItems: [],
    };
  },
  components: {
    SkeletonPuzl,
    ShadedTable,
    ShadedCol,
  },
  methods: {
    /**
     * Função que recebe um item e retorna seu status
     *
     * @param {object} item
     * @returns {boolean}
     */
    getColor(item) {
      switch (true) {
        case item.status === EntryLaunchStatusEnum.CANCELLED || item.status === EntryLaunchStatusEnum.SEFAZ_CANCELLATION:
          return "danger";
        case (item.status !== EntryLaunchStatusEnum.CANCELLED || item.status !== EntryLaunchStatusEnum.SEFAZ_CANCELLATION) && item.not_registered_items !== EntryLaunchStatusEnum.PENDING:
          return "warning";
        case (item.status !== EntryLaunchStatusEnum.CANCELLED || item.status !== EntryLaunchStatusEnum.SEFAZ_CANCELLATION) && item.not_registered_items === EntryLaunchStatusEnum.PENDING:
          return "success";
      }
    },
     /**
     * Função que recebe um número de identificação sem formatação e o formata para melhor visualização com barra e traços.
     *
     * @param {string} str
     * @returns {string}
     */
    parseEin(str) {
      return str.toString().replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    },
    /**
     * Função que recebe um item e informa se está classificado ou não
     *
     * @param {object} item
     * @returns {boolean}
     */
    isClassified(item) {
      if(item.not_registered_items === 0 && ![EntryLaunchStatusEnum.SEFAZ_CANCELLATION, EntryLaunchStatusEnum.CANCELLED].includes(item.status)) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * Função que seleciona/deseleciona todos os checkboxes
     *
     * @param {object} event
     */
    handleSelectAllItems(event) {
      if(event.target.checked) {
        this.$emit('selectAll');
      } else {
        this.$emit('deselectAll');
      }
    }
  },
  watch: {
    putInNote(newValue) {
      if(newValue === true) {
        this.headers.unshift("");
      } else {
        this.headers.shift();
      }
    }
  }
};
</script>
<style>
.card-outline-grey {
  border: 1px solid #606062;
  color: #606062;
  padding: 10px;
  border-radius: 5px;
  height: 14px;
  display: flex;
  align-items: center;
  font-size: 14px;
}
.card-outline-warning {
  border: 1px solid #F2B532;
  border-radius: 5px;
  height: 14px;
  padding: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
}
.card-outline-success {
  border: 1px solid #2AA767;
  padding: 10px;
  border-radius: 5px;
  height: 14px;
  display: flex;
  align-items: center;
  font-size: 14px;
}
.font-badge {
   font-size: 12px;
   font-weight: 500;
}
.gray-divider {
  background-color: #2b2d32;
  opacity: 0.1;
  color: #2b2d32;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}
.badge-custom {
  padding: 6px 19px;
  border-radius: 16px;
  cursor: pointer;
  align-items: center;
  text-align: center;
}
.badge-custom b {
  font-weight: 400;
  font-size: 10px;
}
.badge-custom img {
  margin-right: 5px;
  margin-bottom: 2px;
}
.badge-danger {
  background-color: #F9E3E1;
  color: #DB4539;
}
.badge-warning {
  background-color: #FEF9F2;
  color: #F2B532;
}
.badge-custom-success {
  background: #F2F7F3;
  color: #149E57;
}
</style>