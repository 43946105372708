<template>
  <div>
    <div class="container-fluid">
      <multi-filter 
        :status="multi_filter" 
        ref="multi-filter" 
        @fetch="init"
        :filter="filter"
      >
        <div class="col-md-4 p-2">
          <InputDatePicker @handleFilterDate="handleFilterDate" :filterable="4" />
        </div>
        <div class="col-md-2 p-2">
          <base-button
            :class="filter.readed && 'active'"
            @click="filter.readed = Number(!filter.readed);init({})"
            block outline type="light">NÃO LIDO
          </base-button>
        </div>
        <div class="col-md-2 p-2">
          <base-button
            :class="filter.dont_readed && 'active'"
            @click="filter.dont_readed = Number(!filter.dont_readed);init({})"
            block outline type="primary"><i class="fa-regular fa-circle-check"></i>
            LIDO
          </base-button>
        </div>
      </multi-filter>
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="index in 3" :key="index"/>
      </div>
      <PuzlEmptyData v-if="!$_items.length && !loadingSkeleton"/>
      <div class="row card-wrapper" v-if="$_items.length && !loadingSkeleton">
        <div class="col-lg-4" v-for="(item, index) in $_items" :key="index">
          <div class="card">
            <base-dropdown style="width: 100%" menuOnRight>
              <base-button 
                size="sm" 
                slot="title-container"
                class="btn-header dropdown-toggle text-white text-uppercase"
                :type="item.read_at ? 'primary' : 'light'"
                style="opacity: 92%;color: #4f40ff" 
                block
              >
                {{ item.read_at ? 'Lido' : 'Não lido' }}
              </base-button>
              <div v-for="(dropDown, index) in dropDownData" :key="index">
                <a 
                  @click.prevent="updateReadAt(item.id)"
                  class="dropdown-item text-uppercase"
                > 
                  {{ dropDown.name }}
                </a>
              </div>
            </base-dropdown>
            <div class="card-body">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <h4 class="mt-n1" :class="'text-'+$_types[item.type].type">{{ $_types[item.type].name }}</h4>
                </div>
              </div>
              <h4 v-for="data in item.details.data" class="mt-n1 mb-0 text-sm font-weight-400">{{ data }}</h4>
              <div class="mt-n1 d-flex justify-content-between align-items-center">
                <div>
                  <h4 class="mb-0 text-sm font-weight-400">{{ item.details.user_name }}</h4>
                </div>
              </div>
              <div class="mt-n1 d-flex justify-content-between align-items-center">
                <div style="margin-bottom: -10px">
                  <small class="">{{ item.details.date_at }}</small>
                </div>
                <div class="d-flex">
                  <el-popover v-if="item.details.observations" trigger="hover" placement="bottom" class="p-0">
                    <h4 class="mt-n1 mb-0 text-sm font-weight-400">{{ item.details.observations }}</h4>
                    <img slot="reference" class="pr-2" src="/img/icons/speech-bubble-with-dots--v1-black.png"
                         height="27px"/>
                  </el-popover>
                  <el-popover trigger="hover" placement="bottom" class="p-0">
                    <h4 v-for="detail in item.details.details" class="mt-n1 mb-0 text-sm font-weight-400">
                      {{ detail }}
                    </h4>
                    <img slot="reference" src="/img/icons/info.png" height="27px"/>
                  </el-popover>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid.vue";
import PuzlEmptyData from "@/components/PuzlEmptyData.vue";
import MultiFilter from "@/components/Utils/MultiFilterV2.vue";
import cursorPaginate from "@/mixins/cursorPaginate";
import InputDatePicker from '@/components/InputDatePicker';

export default {
  name: "List",
  mixins: [cursorPaginate],
  components: {
    PuzlEmptyData,
    SkeletonPuzlGrid,
    MultiFilter,
    InputDatePicker,
  },
  computed: {
    ...mapGetters({
      $_items: "notification/getNotifications",
      $_types: "notification/getTypes"
    }),
  },
  data() {
    return {
      loadingSkeleton: false,
      filter: {
        readed: 1,
        dont_readed: 1,
      },
      multi_filter: {
        null: {
          name: "Todos",
          type: "primary",
        },
        5: {
          name: "Operacional",
          type: "warning",
        },
      },
      range: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        end: new Date(),
      },
      dropDownData: [
        {id: 1, name: 'Lido'},
        {id: 2, name: 'Não lido'},
      ],
    }
  },
  methods: {
    init(filter = null) {
      this.startCursor(filter);
      this.$store
        .dispatch("notification/getNotificationsByNotifiable", {
          notifiable_id: this.$user.id,
          filter: this.filter,
          next_page: this.paginate.nextUrl
        })
        .then((response) => {
          this.resolveCursor(response);
        })
        .catch(() => {
          this.resolveCursor();
        });
    },
    handleFilterDate(filter) {
      this.range = filter
      this.init(this.range)
    },
    /**
     * @param {number} id
     */
    updateReadAt(id) {
      this.$store.dispatch("notification/updateReadAt", id).then((response) => {
        const found = this.$_items.find((item) => item.id === response.data.id);
        found.read_at = response.data.read_at;

        this.$notify({
          type: 'success',
          message: response.message
        });
      });
    },
  },
  mounted() {
    this.init({});
  }
}
</script>

<style scoped>

</style>
