<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
      <transition name="el-zoom-in-center">
        <div class="row" >
          <div class="col-md-12">
            <label class="form-control-label">
              DADOS TÉCNICOS PARA EXIBIR NO COMPROVANTE DE ENTREGA E DANFE
            </label>
            <validation-provider rules="required">
              <base-input input-group-classes="input-group-sm">
                  <textarea maxlength="100" v-model="params.thecnical_data" type="text" rows="2" class="form-control form-control-sm">
                  </textarea>
              </base-input>
            </validation-provider>
          </div>
          <div class="col-md-12 text-right">
            {{ params.thecnical_data && params.thecnical_data.length ? params.thecnical_data.length : 0}} / 100
          </div>
        </div>
      </transition>
      <div class="modal-footer mt-n2">
        <base-button type="secondary" @click="closeModal()">
          fechar
        </base-button>
        <base-button
          type="success"
          native-type="submit"
          title="Salvar"
          @click.prevent="handleSave"
          :disabled="(invalid || !params.formulation_uuid) || loadingSave"
          class="btn-custom"
          :class="loadingSave && 'btn-loading'">
              <span v-show="!loadingSave" class="btn-label span-custom"><i
                class="fas fa-save text-white icon-custom"></i></span>
          <span v-show="loadingSave" class="btn-label span-custom"><i
            class="fas fa-spinner text-white fa-spin icon-custom"></i></span>
          Salvar
        </base-button>
      </div>
      </validation-observer>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
  name: "ModalCreateThecnicalData",
  data() {
    return {
      modal: false,
      loadingSave: false,
      title: "Dados técnicos",
      selected: null,
      params: {
        formulation_uuid: null,
        thecnical_data: '',
      }
    };
  },
  computed: {
    ...mapGetters({
      $_formulation: 'formulations/show',
    }),
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    handleSave() {
      this.$Progress.start()
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.loadingSave = true
      this.$store.dispatch('formulations/postUpdateThecnicalData', this.params).then((response) => {
        this.loadingSave = false
        this.$notify({
          type: response.error_type,
          message: response.message
        });
        this.$emit('updateThecnicalData', this.params)
        this.$Progress.finish()
        this.closeModal()
      })
    },
    async openModal(formulation_uuid = null) {
      let loader = this.$loading.show()
      this.params.formulation_uuid = formulation_uuid
      await this.$store.dispatch('formulations/show', formulation_uuid)
      this.params.thecnical_data = this.$_formulation.thecnical_data || ''
      loader.hide()
      this.modal = true
    },
  },
};
</script>

<style></style>
