<template>
  <div>
    <modal size="lg" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(update)" autocomplete="off">

            <div class="form-group row m-0 p-0">
                <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                    Conta Bancária (Origem)
                    <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 mb-1 pr-4">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <puzl-select
                        v-model="$_bank_transfers.bank_account_id"
                        :items="$_bank_accounts"
                        :loading="loadingBankAccount"
                        :disabled="loadingBankAccount" />
                    </base-input>
                  </validation-provider>
                </div>
            </div>

            <div class="form-group row m-0 p-0">
                <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                    Conta Bancária (Destino)
                    <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 mb-1 pr-4">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <puzl-select
                        v-model="$_bank_transfers.bank_account_target_id"
                        :items="$_bank_accounts"
                        :loading="loadingBankAccount"
                        :disabled="loadingBankAccount" />
                    </base-input>
                  </validation-provider>
                </div>
            </div>

            <div class="form-group row m-0 p-0">
                <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                    Data
                    <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 mb-1 pr-4">
                    <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-classes="form-control-sm">                        
                            <el-date-picker
                                size="mini"
                                v-model="$_bank_transfers.date"
                                type="date"
                                format="dd/MM/yyyy"
                                value-format="yyyy-MM-dd" 
                                @input="checkDate()"
                            >
                            </el-date-picker>                            
                        </base-input>
                    </validation-provider>
                </div>
            </div>

            <div class="form-group row m-0 p-0">
                <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                    Valor
                    <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 mb-1 pr-4">
                    <validation-provider rules="required">
                        <base-input input-classes="form-control-sm">
                            <input
                                type="text"
                                class="form-control form-control-sm"
                                v-model="$_bank_transfers.amount"                                
                                v-money="money"
                            />
                        </base-input>
                    </validation-provider>
                </div>
            </div>

            <div class="form-group row m-0 p-0">
                <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                    Observações
                </label>
                <div class="col-md-8 mb-1 pr-4">
                    <base-input input-group-classes="input-group-sm">
                        <textarea 
                            type="text" 
                            rows="2" 
                            class="form-control form-control-sm" 
                            v-model="$_bank_transfers.note"
                            maxlength="250"
                        >
                        </textarea>
                    </base-input>
                </div>
            </div>

            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')">
                  Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                  Salvar
              </base-button>
            </div>

          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import PuzlSelect from "@/components/PuzlSelect";
import { VMoney } from "v-money";
import moment from "moment";
import { moneyToFloat, checkNumberValue } from "@/helpers";
const { formatErrorValidation } = require("@/plugins");
export default {
    name: "ModalEdit",
    components: { PuzlSelect },
    data() {
        return {
            modal: {
                title: 'Editar Transferência',
                create: false,
            },
            loadingStore: false,
            loadingBankAccount: true,
            money: {
                decimal: ",",
                thousands: ".",
                prefix: "",
                suffix: "",
                precision: 2,
                masked: false
            },
        };
    },
    computed: {
        ...mapGetters({
            $_bank_transfers: 'bankTransfer/show',
            $_bank_accounts: 'bankAccount/fetch',
        }),
    },
    mounted() {
        this.$refs.formValidator.validate();
        this.$store.dispatch('bankAccount/fetchItems').then(() => {
            this.loadingBankAccount = false;
        });
    },
    directives: {
        money: VMoney
    },
    methods: {
        closeModal() {
            this.modal.create = false;
        },
        handleEditModal(id) {
            this.$Progress.start()
            this.$store.dispatch('bankTransfer/show', id)
                .then((response) => {
                    this.modal.create = true;
                    this.$Progress.finish();
                }).catch((error) => {
                    this.$notify({
                        type: error.data.error_type,
                        message: error.data.message
                    })
                    this.$Progress.finish();
                })
        },
        update() {
            this.$Progress.start()
            this.loadingStore = true;
            let payload =  {... this.$_bank_transfers};
            payload.amount = this.$helper.moneyToFloat(payload.amount);
            payload.date = moment(payload.date).format("YYYY-MM-DD");
            if (payload.amount <= 0){
                this.$notify({ 
                    type: 'danger', 
                    message: 'Valor não pode ser menor ou igual a zero'
                });
                this.loadingStore = false;
                this.$Progress.finish();
                return
            }
            this.$store.dispatch('bankTransfer/update', payload)
                .then(response => {
                    this.loadingStore = false;
                    this.modal.create = false;
                    this.$Progress.finish();
                    this.$notify({
                        type: response.error_type,
                        message: response.message
                    })
                })
                .catch(error => {
                    if (error.status == 200) {
                        this.$notify({
                            type: 'danger',
                            message: error.data.message
                        })

                    } else if (error.response && error.response.status === 422) {
                        let errors = formatErrorValidation(error.response.data.errors)
                        this.$notify({
                            type: 'danger',
                            message: errors
                        })
                    }
                    this.$Progress.finish();
                    this.loadingStore = false;
                })
        },
        checkDate() {
            if (moment(this.$_bank_transfers.date).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD")) {
                this.$_bank_transfers.date = moment().format("YYYY-MM-DD");
                this.$notify({
                    type: 'danger',
                    message: 'Data não pode ser maior que hoje'
                })
            }
        },
    },
};
</script>

<style scoped></style>
