<template>
  <base-dropdown menuOnRight :style="listType === 'cards' ? 'position: absolute; right: 20px;' : ''">
    <div slot="title-container"
      class="dropdown-toggle rounded m-0">
      <img :width="listType === 'table' ? 25 : 35" src="/img/icons/icons8/ios/settings--v1_primary.png">
    </div>
    <a class="dropdown-item new-default-black-font" style="align-items: center; display: flex"
      @click.prevent="$emit('handleEditBillReceive')">
      <img src="/img/icons/create-new.png" width="22"/>
      Editar
    </a>
    <div class="dropdown-divider p-0 m-0"></div>
    <a class="dropdown-item new-default-black-font" style="align-items: center; display: flex"
      @click.prevent="$emit('handleShowHistory')">
      <img src="/img/icons/order-history.png" width="22"/>
      Histórico
    </a>
    <div class="dropdown-divider p-0 m-0"></div>
    <a class="dropdown-item new-default-black-font" style="align-items: center; display: flex"
      @click.prevent="$emit('handleOpenModalInfo')">
      <img src="/img/icons/info.png" width="22" />
      Informações
    </a>
    <div v-if="bill_receive.type === 0 && !bill_receive.entity_id" class="dropdown-divider p-0 m-0"></div>
    <a v-if="bill_receive.type === 0 && !bill_receive.entity_id"
      class="dropdown-item new-default-black-font" style="align-items: center; display: flex"
      @click.prevent="$emit('handleIdentifyEntity')">
      <img src="/img/icons/icons8/ios/search.png" width="22" />
      Identificar
    </a>
    <div v-if="bill_receive.last_billet === false
      && bill_receive.payment_method_uuid === '947bf938-e098-4d1e-965a-c33fc9aa05d5'
      && parseInt(bill_receive.status) !== 3
      && parseInt(bill_receive.status) !== 1"
      class="dropdown-divider p-0 m-0"></div>
    <a v-if="bill_receive.last_billet === false
      && bill_receive.payment_method_uuid === '947bf938-e098-4d1e-965a-c33fc9aa05d5'
      && parseInt(bill_receive.status) !== 3
      && parseInt(bill_receive.status) !== 1"
      class="dropdown-item new-default-black-font" style="align-items: center; display: flex"
      @click="$emit('showModalGenerateBillet')">
      <img src="/img/icons/barcode.png" width="22" />
      Boleto
    </a>
    <div v-if="bill_receive.last_billet !== false
      && bill_receive.payment_method_uuid === '947bf938-e098-4d1e-965a-c33fc9aa05d5'
      && parseInt(bill_receive.status) !== 3"
      class="dropdown-divider p-0 m-0"></div>
    <a v-if="bill_receive.last_billet !== false
      && bill_receive.payment_method_uuid === '947bf938-e098-4d1e-965a-c33fc9aa05d5'
      && parseInt(bill_receive.status) !== 3"
      class="dropdown-item new-default-black-font" style="align-items: center; display: flex"
      @click="$emit('showModalGenerateBilletHasGenerated')">
      <img src="/img/icons/barcode.png" width="22" />
      Boleto
    </a>
    <div v-if="bill_receive.bank_billet_batch_id" class="dropdown-divider p-0 m-0"></div>
    <a v-if="bill_receive.bank_billet_batch_id"
      class="dropdown-item new-default-black-font" style="align-items: center; display: flex"
      @click.prevent="$emit('postBatchUpdate')">
      <img src="/img/icons/connection-sync.png" width="22" />
      Atualizar Lote
    </a>
    <div class="dropdown-divider p-0 m-0"></div>
    <a class="dropdown-item new-default-black-font" style="align-items: center; display: flex"
      :title="`Total de registros do cliente: ${bill_receive.total_entity_registries}`"
      @click.prevent="$emit('handleModalEntityRegistry')">
      <img :src="bill_receive.total_entity_registries > 0 ? '/img/icons/info.png' : '/img/icons/signing-a-document.png'" width="22" />
      Registros do cliente
    </a>
    <div v-if="$hasPermission('bill_receive.change_due_date') && bill_receive.type === 1 && (validFinancialLimit()) && !([1, 3].includes(bill_receive.status) || bill_receive.identified || bill_receive.reconciled) && validBillet(bill_receive)"
      class="dropdown-divider p-0 m-0"></div>
    <a v-if="$hasPermission('bill_receive.change_due_date') && bill_receive.type === 1 && (validFinancialLimit()) && !([1, 3].includes(bill_receive.status) || bill_receive.identified || bill_receive.reconciled) && validBillet(bill_receive)"
      class="dropdown-item new-default-black-font" style="align-items: center; display: flex"
      @click.prevent="$emit('openModalChangeDueDate')">
      <img src="/img/icons/icons8/ios/overtime_warning.png" width="22" />
      Alterar vencimento
    </a>
    <div v-if="$hasPermission('bill_receive.change_due_date') && bill_receive.type === 1 && (validFinancialLimit()) && !([1, 3].includes(bill_receive.status) || bill_receive.identified || bill_receive.reconciled) && (bill_receive.payment_method_uuid === '947bf938-e098-4d1e-965a-c33fc9aa05d5' && bill_receive.last_billet !== false)"
      class="dropdown-divider p-0 m-0"></div>
    <a v-if="$hasPermission('bill_receive.change_due_date') && bill_receive.type === 1 && (validFinancialLimit()) && !([1, 3].includes(bill_receive.status) || bill_receive.identified || bill_receive.reconciled) && (bill_receive.payment_method_uuid === '947bf938-e098-4d1e-965a-c33fc9aa05d5' && bill_receive.last_billet !== false)"
      class="dropdown-item new-default-black-font" style="align-items: center; display: flex"
      @click.prevent="$emit('showModalEditGenerateBilletHasGenerated')">
      <img src="/img/icons/icons8/ios/overtime_warning.png" width="22" />
      Alterar vencimento
    </a>
    <div v-if="![paymentInstallmentStatusEnum.CANCELED.ID,paymentInstallmentStatusEnum.APPROVED.ID,paymentInstallmentStatusEnum.DENIED.ID].includes(bill_receive.status) && bill_receive.last_billet === false"
      class="dropdown-divider p-0 m-0"></div>
    <a v-if="![paymentInstallmentStatusEnum.CANCELED.ID,paymentInstallmentStatusEnum.APPROVED.ID,paymentInstallmentStatusEnum.DENIED.ID].includes(bill_receive.status) && bill_receive.last_billet === false"
      class="dropdown-item new-default-black-font" style="align-items: center; display: flex"
      @click.prevent="$emit('handleDeleteBillReceive')">
      <img src="/img/icons/delete.png" width="22" />
      Excluir
    </a>
  </base-dropdown>
</template>
<script>
export default {
  name: "SettingOptions",
  props: {
    bill_receive: Object,
    paymentInstallmentStatusEnum: Object,
    listType: String,
  },
  methods: {
    validFinancialLimit() {
      return ((this.$user.financial_limit && this.$user.financial_limit.disable_max_extension_due_date && this.$user.financial_limit.general_extension_due_date > 0) || !this.$user.financial_limit || !this.$user.financial_limit.disable_max_extension_due_date)
    },
    /**
     * Descrição: Verifica se o método de pagamento é Boleto
     * @param {object} bill_receive
     */
    validBillet(bill_receive) {
      if (bill_receive.payment_method_uuid === '947bf938-e098-4d1e-965a-c33fc9aa05d5') {
        if (bill_receive.last_billet === false) {
          return true
        } else {
          return false
        }
      }
      return true
    },
  },
};
</script>