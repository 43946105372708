import { ValidationException, LoginUnauthorizedException } from "../exceptions";
import { HttpResponseType } from "../types";
import { HttpStatusEnum } from "../enums";

/**
 * Tratar status code inesperados dos serviços
 *
 * @param {HttpResponseType} httpResponse
 * @throws {ValidationException}
 * @throws {LoginUnauthorizedException}
 * @throws {Error}
 */
export const handleUnexpectedStatusCode = (httpResponse) => {
  /** Validação de dados de entrada */
  const validationStatus = [
    HttpStatusEnum.HTTP_BAD_REQUEST, 
    HttpStatusEnum.HTTP_NOT_FOUND, 
    HttpStatusEnum.HTTP_NOT_ACCEPTABLE, 
    HttpStatusEnum.HTTP_UNPROCESSABLE_ENTITY,
  ];
  if (validationStatus.includes(httpResponse.status)) {
    throw new ValidationException(httpResponse.data?.message, httpResponse.data?.data);
  }
  
  /** Acesso não autorizado */
  if (httpResponse.status === HttpStatusEnum.HTTP_UNAUTHORIZED) {
    throw new LoginUnauthorizedException(httpResponse.data?.message, httpResponse.data?.data);
  }
  
  /** Erro não catalogado */
  const errorMsg = httpResponse.data?.message || httpResponse.statusText || 'this.ERROR_MESSAGE';
  throw new Error(errorMsg);
};