<template>
  <div>
    <modal style="z-index: 999999999999 !important;" size="md" :show.sync="modal">
      <div class="row" style="height: 92vh">
        <div class="col-md-12 text-center mt-2">
          <img style="width: 95%;height: 85vh" src="/img/brand/news.jpg"/>
        </div>
        <div class="col-md-12 text-center pr-3">
          <base-button type="secondary" @click="closeModal()">Ótimo, continuar!</base-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: "ModalCreateEquipmentCategory",
  data () {
    return {
      modal: false,
    }
  },
  computed: {
  },
  methods: {
    closeModal () {
      this.modal = false
    },
    handleCreateModal () {
      this.modal = true
    },
  },
}
</script>

<style scoped>

</style>
