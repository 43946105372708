<template>
  <div v-show="scannerActive" class="puzl-read-barcode-component">
    <div class="form-group row m-0 p-0 mb-1">
      <label class="col-md-5 pl-2 pt-2 pb-0 col-form-label form-control-label">
        Dispositivo <span class="text-danger">&nbsp;*</span>
      </label>
      <div class="col-md-7">
        <puzl-select placeholder="Padrão" @input="start" v-model="selectedDevice" :items="devices" />
      </div>
    </div>
    <div id="videoWindow" class="video mt-2 position-relative col-12"></div>
  </div>
</template>

<script>
import PuzlSelect from "@/components/PuzlSelect";
import Quagga from "quagga";

export default {
  name: "PuzlBarCodeReader",
  components: { PuzlSelect },
  props: {
    readers: {
      type: Array,
      default: () => ["code_128_reader"],
      description: "Tipos de código de barras aceitos.",
    },
    onDecode: {
      type: Function,
      default: () => {},
      description: "Função disparada ao ler resultado.",
    },
  },
  data() {
    return {
      selectedDevice: null,
      scannerActive: false,
      devices: [],
    };
  },
  methods: {
    start() {
      if (this.scannerActive === true) Quagga.stop();
      this.scannerActive = true;
      Quagga.init(this.getConfig(), (err) => {
        Quagga.start();
      });
    },
    detected(value) {
      this.onDecode(value.codeResult.code);
    },
    stop() {
      this.scannerActive = false;
      Quagga.stop();
    },
    getDevices() {
      let self = this;
      navigator.mediaDevices.enumerateDevices().then(function (devices) {
        devices.map(function (item) {
          if (item.deviceId && item.label) {
            self.devices.push({
              id: item.deviceId,
              name: item.label,
            });
          }
        });
      });
    },
    getConfig() {
      let config = {
        numOfWorkers: 4,
        locate: true,
        inputStream: {
          name: "live",
          type: "LiveStream",
          target: "#videoWindow",
          constraints: {},
        },
        decoder: {
          readers: this.readers,
          multiple: false,
        },
        locator: {
          halfSample: false,
          patchSize: "medium",
        },
        frequency: 10,
      };
      if (this.selectedDevice) {
        config.inputStream.constraints.deviceId = this.selectedDevice;
      } else {
        delete config.inputStream.constraints.deviceId;
      }
      return config;
    },
    onProcess(result) {
      var drawingCtx = Quagga.canvas.ctx.overlay,
        drawingCanvas = Quagga.canvas.dom.overlay;

      if (result) {
        if (result.boxes) {
          drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
          result.boxes
            .filter(function (box) {
              return box !== result.box;
            })
            .forEach(function (box) {
              Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: "green", lineWidth: 2 });
            });
        }

        if (result.box) {
          Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: "#00F", lineWidth: 2 });
        }

        if (result.codeResult && result.codeResult.code) {
          Quagga.ImageDebug.drawPath(result.line, { x: "x", y: "y" }, drawingCtx, { color: "red", lineWidth: 3 });
        }
      }
    },
  },
  mounted() {
    Quagga.onDetected(this.detected);
    Quagga.onProcessed(this.onProcess);
    this.getDevices();
  },
  beforeDestroy() {
    if (this.scannerActive === true) Quagga.stop();
  }
};
</script>

<style>
.puzl-read-barcode-component .video,
.puzl-read-barcode-component .drawingBuffer,
.puzl-read-barcode-component video {
  width: 100% !important;
}
.puzl-read-barcode-component .drawingBuffer {
  position: absolute;
  left: 0;
  top: 0;
}
</style>
