<template>
  <div>
    <modal v-if="!loadingTaxRegimes && taxRegimes" size="md" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title ml-2">
          {{ modal.title }} 
          <span><div class="text-left h3 small" style="color: white;">{{ modal.detail }}</div></span> 
        </h5>
      </template>

      <div>
        <form class="needs-validation">
          <div>
            <breadcrumb list-classes="bg-success border-header-indigo-bottom">
              Características da Prefeitura
            </breadcrumb>
            <div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">Suporta cancelamento?</label>
                <div class="col-md-3 text-right">
                  <base-switch
                    v-model="taxRegimes.suportaCancelamento"
                    type="success"
                    offText="Não"
                    onText="Sim"
                    class="success"
                    :disabled="true"
                  ></base-switch>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">Usa AEDF?</label>
                <div class="col-md-3 text-right">
                  <base-switch
                    v-model="taxRegimes.usaAEDF"
                    type="success"
                    offText="Não"
                    onText="Sim"
                    class="success"
                    :disabled="true"
                  ></base-switch>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">Usa regime especial de tributação?</label>
                <div class="col-md-3 text-right">
                  <base-switch
                    v-model="taxRegimes.usaRegimeEspecialTributacao"
                    type="success"
                    offText="Não"
                    onText="Sim"
                    class="success"
                    :disabled="true"
                  ></base-switch>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">Usa código de serviço municipal?</label>
                <div class="col-md-3 text-right">
                  <base-switch
                    v-model="taxRegimes.usaCodigoServicoMunicipal"
                    type="success"
                    offText="Não"
                    onText="Sim"
                    class="success"
                    :disabled="true"
                  ></base-switch>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">Usa descrição do serviço?</label>
                <div class="col-md-3 text-right">
                  <base-switch
                    v-model="taxRegimes.usaDescricaoServico"
                    type="success"
                    offText="Não"
                    onText="Sim"
                    class="success"
                    :disabled="true"
                  ></base-switch>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">Usa CNAE?</label>
                <div class="col-md-3 text-right">
                  <base-switch
                    v-model="taxRegimes.usaCNAE"
                    type="success"
                    offText="Não"
                    onText="Sim"
                    class="success"
                    :disabled="true"
                  ></base-switch>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">Usa item da lista de serviço (LC116)?</label>
                <div class="col-md-3 text-right">
                  <base-switch
                    v-model="taxRegimes.usaItemListaServico"
                    type="success"
                    offText="Não"
                    onText="Sim"
                    class="success"
                    :disabled="true"
                  ></base-switch>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">Suporta emissão sem cliente?</label>
                <div class="col-md-3 text-right">
                  <base-switch
                    v-model="taxRegimes.suportaEmissaoNFeSemCliente"
                    type="success"
                    offText="Não"
                    onText="Sim"
                    class="success"
                    :disabled="true"
                  ></base-switch>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">Suporta emissão sem CPF do cliente?</label>
                <div class="col-md-3 text-right">
                  <base-switch
                    v-model="taxRegimes.suportaEmissaoNFeClienteSemCpf"
                    type="success"
                    offText="Não"
                    onText="Sim"
                    class="success"
                    :disabled="true"
                  ></base-switch>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">Suporta emissão sem endereço do cliente?</label>
                <div class="col-md-3 text-right">
                  <base-switch
                    v-model="taxRegimes.suportaEmissaoNFeClienteSemEndereco"
                    type="success"
                    offText="Não"
                    onText="Sim"
                    class="success"
                    :disabled="true"
                  ></base-switch>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">Suporta cancelamento sem cliente?</label>
                <div class="col-md-3 text-right">
                  <base-switch
                    v-model="taxRegimes.suportaCancelamentoNFeSemCliente"
                    type="success"
                    offText="Não"
                    onText="Sim"
                    class="success"
                    :disabled="true"
                  ></base-switch>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">Suporta cancelamento sem cpf do cliente?</label>
                <div class="col-md-3 text-right">
                  <base-switch
                    v-model="taxRegimes.suportaCancelamentoNFeClienteSemCpf"
                    type="success"
                    offText="Não"
                    onText="Sim"
                    class="success"
                    :disabled="true"
                  ></base-switch>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">Tipo de autenticação</label>
                <div class="col-md-3">
                  <base-input group class="mb-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="taxRegimes.tipoAutenticacao"
                      :disabled="true"
                    />
                  </base-input>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">Assinatura digital</label>
                <div class="col-md-3">
                  <base-input group class="mb-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="taxRegimes.assinaturaDigital"
                      :disabled="true"
                    />
                  </base-input>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">Campo login provedor</label>
                <div class="col-md-3">
                  <base-input group class="mb-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="taxRegimes.campoLoginProvedor"
                      :disabled="true"
                    />
                  </base-input>
                </div>
              </div>              
              <div v-if="taxRegimes.helpRegimeEspecialTributacao" class="form-group row m-0 p-0">
                <label class="col-md-12 col-form-label form-control-label">Auxílio para regime especial de tributação</label>
                <div class="col-md-12 mb-2">
                  <base-input group class="mb-3">
                    <textarea 
                      type="text" 
                      rows="4" 
                      class="form-control form-control-sm" 
                      :value="taxRegimes.helpRegimeEspecialTributacao"
                      :disabled="true"
                    >
                    </textarea>
                  </base-input>
                </div>
              </div>              
              <div v-if="taxRegimes.helpCNAE" class="form-group row m-0 p-0">
                <label class="col-md-12 col-form-label form-control-label">Auxílio para CNAE</label>
                <div class="col-md-12 mb-2">
                  <base-input group class="mb-3">
                    <textarea 
                      type="text" 
                      rows="4" 
                      class="form-control form-control-sm" 
                      :value="taxRegimes.helpCNAE"
                      :disabled="true"
                    >
                  </base-input>
                </div>
              </div>
              <div v-if="taxRegimes.helpItemListaServico" class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">Auxílio para item da lista de serviço (LC116)</label>
                <div class="col-md-3 mb-2">
                  <base-input group class="mb-3">
                  <textarea 
                    type="text" 
                    rows="4" 
                    class="form-control form-control-sm" 
                    :value="taxRegimes.helpItemListaServico"
                    :disabled="true"
                  >
                  </base-input>
                </div>
              </div>
              <div v-if="taxRegimes.helpTipoAutenticacao" class="form-group row m-0 p-0">
                <label class="col-md-12 col-form-label form-control-label">Auxílio para tipo de autenticação</label>
                <div class="col-md-12 mb-2">
                  <base-input group class="mb-3">
                    <textarea 
                      type="text" 
                      rows="4" 
                      class="form-control form-control-sm" 
                      :value="handleField(taxRegimes.helpTipoAutenticacao)"
                      :disabled="true"
                    >
                    </textarea>
                  </base-input>
                </div>
              </div>
              <div v-if="taxRegimes.helpInscricaoMunicipal" class="form-group row m-0 p-0">
                <label class="col-md-12 col-form-label form-control-label">Auxílio para inscrição municipal</label>
                <div class="col-md-12 mb-2">
                  <base-input group class="mb-3">
                    <textarea 
                      type="text" 
                      rows="4" 
                      class="form-control form-control-sm" 
                      :value="taxRegimes.helpInscricaoMunicipal"
                      :disabled="true"
                    >
                    </textarea>                    
                  </base-input>
                </div>
              </div>
              <div v-if="taxRegimes.helpCodigoServicoMunicipal" class="form-group row m-0 p-0">
                <label class="col-md-12 col-form-label form-control-label">Auxílio para código do serviço municipal</label>
                <div class="col-md-12 mb-2">
                  <base-input group class="mb-3">
                  <textarea 
                      type="text" 
                      rows="4" 
                      class="form-control form-control-sm" 
                      :value="taxRegimes.helpCodigoServicoMunicipal"
                      :disabled="true"
                    >
                    </textarea> 
                  </base-input>
                </div>
              </div>
              <div v-if="taxRegimes.helpDescricaoServico" class="form-group row m-0 p-0">
                <label class="col-md-12 col-form-label form-control-label">Auxílio para descrição do serviço</label>
                <div class="col-md-12 mb-2">
                  <base-input group class="mb-3">
                    <textarea 
                      type="text" 
                      rows="4" 
                      class="form-control form-control-sm" 
                      :value="taxRegimes.helpDescricaoServico"
                      :disabled="true"
                    >
                    </textarea> 
                  </base-input>
                </div>
              </div>              
            </div>
          </div>
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import Pagination from "@/components/Utils/Pagination";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import {formatErrorValidation} from "@/plugins";
export default {
  name: "ModalTaxRegimesByIbgeCode",
  components: {
    Pagination,
    SkeletonPuzl,
  },
  data() {
    return {
      modal: {
        title: "REGRAS DO MUNICÍPIO",
        detail: null,
        create: false,
      },
      cityName: null,
      ibgecode: null,
      taxRegimes: null,
      loadingTaxRegimes: false,      
    };
  },
  methods: {
    close() {
      this.modal.create = false;
      this.$emit("close");
    },
    /**
     * Abrir modal com os dados do IbgeCode informado
     *
     * @param {string} cityName Nome da Cidade
     * @param {string} ibgeCode Código do IBGE da Cidade
     *
     */
    handleCreateModal(cityName, ibgeCode) {
      this.cityName = cityName;
      this.ibgeCode = ibgeCode;
      this.modal.detail = `Cidade: ${cityName}, IBGE CODE: ${ibgeCode}`;
      this.modal.create = true;
      this.init();
    },
    init() {
      this.fetchTaxRegimes();
    },
    fetchTaxRegimes() {
      this.loadingTaxRegimes = true;
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      let loader = this.$loading.show()
      this.$store
        .dispatch("nfse/getTaxRegimesByIbgeCode", this.ibgeCode)
        .then((response) => {
          this.$notify({
            type: response.error_type,
            message: "solicitação realizada com sucesso."
          });
          this.taxRegimes = response.data;
        })
        .catch((error) => {
          let errors = error.status
            ? error.data.message
            : formatErrorValidation(error.response.data.errors);
          this.$notify({ type: "danger", message: errors });
        })
        .finally(() => {
          loader.hide();
          this.loadingTaxRegimes = false
        });
    },
    handleField(field) {
      const parsedContent = Object.entries(JSON.parse(JSON.stringify(field)));
      return parsedContent.join('\n').replace(/,/g, ": ");
    },
  },
};
</script>

<style scoped></style>
