<template>
  <div>
    <modal :borderInfo="'2px solid #1b6eba'" :show.sync="modal" size="lg">
      <template slot="header">
        <div style="display: flex;align-items: center;">
          <img style="display: inline-flex;" src="/img/icons/icons8/ios/plus-math--v1-white.png" width="22">
          <h5 style="display: inline-flex;" class="modal-title p-0 m-0 ml-3 text-white">{{ title }}</h5>
        </div>          
      </template>
      <div v-if="service.service">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
            <div class="container-fluid">
              <div class="row mt-2 mb-3">
                <div class="col-12" style="display: flex; align-items: center;">
                  <img src="/img/icons/icons8/ios/info-squared_warning.png" width="18">
                  <h4 class="new-default-black-font m-0 mx-3 ">
                    Identificação
                  </h4>
                  <hr class="ml-3 m-0" style="width: 100%;">
                </div>             
              </div>
              <div class="row">
                <div class="col-8" style="display: flex; align-items: center;">
                  <h4 class="new-default-black-font m-0">
                    {{ service.service && service.service.name }}
                  </h4>
                </div>
                <div class="col-4">
                  <el-popover trigger="click" placement="left">
                    <h5 class="font-weight-normal m-0">{{ service.plant && service.plant }}</h5>
                    <base-button outline slot="reference" size="sm" type="secundary"
                      class="p-0 ml-2 float-right shadow-none text-indigo">
                      <img src="/img/icons/chemical-plant-black.png" width="26">
                    </base-button>
                  </el-popover>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <h4 class="new-default-black-font font-weight-normal">
                    {{ service.service && service.service.description }}
                  </h4>
                </div>
              </div>
              <div class="row mt-4 align-items-center">
                <div class="col-6">
                  <h4 class="new-default-black-font font-weight-normal m-0">
                    Central
                  </h4>
                </div>
                <div class="col-6">
                  <validation-provider rules="required">
                    <base-input input-classes="form-control-sm">
                      <el-select
                        v-model="service.company_plant_id"
                        size="mini"
                        placeholder="Selecione"
                        filterable
                      >
                        <el-option label="Selecione" value=""></el-option>
                        <el-option v-for="plant in service.service_price.company_plants" :key="plant.id"
                                   :label="plant.name"
                                   :value="plant.id"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="row mt-1 align-items-center">
                <div class="col-6">
                  <h4 class="new-default-black-font font-weight-normal m-0">
                    Data
                  </h4>
                </div>
                <div class="col-6">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <el-date-picker
                        v-model="service.date_at"
                        size="mini"
                        type="date"
                        format="dd/MM/yyyy"
                        :picker-options="pickerOptions"
                        value-format="yyyy-MM-dd"
                        block
                      >
                      </el-date-picker>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div v-if="allowExpectedVolume || allowExpectedHour" class="row mt-4 align-items-center">
                <div class="col-6 text-center offset-6">
                  <h6 class="new-default-gray-font font-weight-normal m-0">
                    Informe ao menos um campo de previsão
                  </h6>
                </div>
              </div>
              <div v-if="allowExpectedVolume" class="row mt-1 align-items-center">
                <div class="col-6">
                  <h4 class="new-default-black-font font-weight-normal m-0">
                    Volume previsto
                  </h4>
                </div>
                <div class="col-6">
                  <validation-provider :rules="priceRequired(service)">
                    <base-input input-group-classes="input-group-sm" appendClass="appendorprepend-bg-to-gray">
                      <input
                        inputmode="numeric"
                        v-model="service.expected_volume"
                        type="text"
                        v-mask="['#', '##', '#,#', '##,#', '###,#','####,#']"
                        class="form-control form-control-sm"
                        disabled
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          m<sup>3</sup>
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div v-if="allowExpectedVolume" class="row mt-1 align-items-center">
                <div class="col-6 mt-1">
                  <h4 class="new-default-black-font font-weight-normal m-0">
                    Volume complementar
                  </h4>
                </div>
                <div class="col-6">
                  <validation-provider :rules="priceRequired(service)">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        inputmode="numeric"
                        v-model="service.additional_volume"
                        type="text"
                        class="form-control form-control-sm"
                        v-mask="['#,#', '##,#', '###,#', '#.###,#', '##.###,#','###.###,#']"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          m<sup>3</sup>
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div v-if="allowExpectedHour" class="row mt-4 align-items-center">
                <div class="col-6">
                  <h4 class="new-default-black-font font-weight-normal m-0">
                    Horas previstas
                  </h4>
                </div>
                <div class="col-6">
                  <validation-provider :rules="priceRequired(service)">
                    <base-input input-group-classes="input-group-sm" appendClass="appendorprepend-bg-to-gray">
                      <input
                        inputmode="numeric"
                        v-model="service.expected_hour"
                        type="text"
                        maxlength="4"
                        class="form-control form-control-sm"
                        disabled
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          horas
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div v-if="allowExpectedHour" class="row mt-1 align-items-center">
                <div class="col-6">
                  <h4 class="new-default-black-font font-weight-normal m-0">
                    Horas complementares
                  </h4>
                </div>
                <div class="col-6">
                  <validation-provider :rules="priceRequired(service)">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        inputmode="numeric"
                        v-model="service.additional_hour"
                        type="text"
                        class="form-control form-control-sm"
                        maxlength="4"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          horas
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-12" style="display: flex; align-items: center;">
                  <img src="/img/icons/price-tag-usd-green.png" width="18">
                  <h4 class="new-default-black-font m-0 mx-3 ">
                    Preço
                  </h4>
                  <hr class="ml-3 my-0" style="width: 100%;">
                </div>             
              </div>
              <div class="row mt-2 align-items-center">
                <div class="col-6 col-md-4 text-center offset-md-4">
                  <h6 class="new-default-gray-font font-weight-normal m-0">
                    Preços mínimos
                  </h6>
                </div>
                <div class="col-1 px-0">
                  <img @click.prevent="copyAllValues" 
                    src="/img/icons/icons8/ios/forward-arrow.png" 
                    class="pointer" 
                    width="16">
                </div>
                <div class="col-5 col-md-3">
                  <h6 class="new-default-gray-font font-weight-normal m-0">
                    Preços de venda
                  </h6>
                </div>
              </div>
              <div class="row mt-1 align-items-center">
                <div class="col-md-4 pr-1 pr-md-0 new-default-black-font font-weight-500" style="display: flex;">
                  <div style="width: 100%;">
                    <div style="display: inline-flex;align-items: center;">
                      <h4 class="new-default-black-font m-0">
                        Fixo
                      </h4>
                      <el-popover trigger="click"
                        placement="right"
                        v-show="priceIsLower(
                          service.service.price_fixed,
                          service.proposal_price_fixed
                        )">
                        <h5 class="font-weight-normal m-0">Preço fixo abaixo do mínimo estipulado.</h5>
                        <base-button outline slot="reference" size="sm" type="secundary"
                          class="ml-2 shadow-none text-indigo">
                          <img src="/img/icons/icons8/ios/error--v1_danger.png" width="18">
                        </base-button>
                      </el-popover>
                    </div>
                  </div>
                  <div style="display: flex;align-items: center;">
                    <img @click.prevent="copySingleValue('fixo')"
                      class="mr-2 pointer" 
                      src="/img/icons/icons8/ios/copy-2_primary.png" width="16">
                  </div>
                </div>
                <div class="col-6 col-md-4 px-md-0">
                  <base-input input-group-classes="input-group-sm" prependClass="appendorprepend-bg-to-gray">
                    <input
                      v-model="service.service_price.price_fixed"
                      inputmode="numeric"
                      class="form-control form-control-sm"
                      maxlength="10"
                      disabled
                    />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0">
                        R$
                      </small>
                    </template>
                  </base-input>
                </div>
                <div class="col-6 col-md-4">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-model.lazy="service.proposal_price_fixed"
                      inputmode="numeric"
                      class="form-control form-control-sm"
                      maxlength="10"
                      v-money="money"
                      @keydown="$event.key === '-' ? $event.preventDefault() : null"
                    />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0">
                        R$
                      </small>
                    </template>
                  </base-input>
                </div>
              </div>
              <div v-if="service.service_price.by_meters" class="row mt-2 align-items-center">
                <div class="col-md-4 pr-1 pr-md-0 new-default-black-font font-weight-500" style="display: flex;">
                  <div style="width: 100%;">
                    <div style="display: inline-flex;align-items: center;">
                      <h4 class="new-default-black-font m-0">
                        Por m³
                      </h4>
                      <el-popover trigger="click"
                        placement="right"
                        v-show="priceIsLower(
                          service.service.by_meters,
                          service.proposal_by_meters
                        )">
                        <h5 class="font-weight-normal m-0">Preço por m³ abaixo do mínimo estipulado.</h5>
                        <base-button outline slot="reference" size="sm" type="secundary"
                          class="ml-2 shadow-none text-indigo">
                          <img src="/img/icons/icons8/ios/error--v1_danger.png" width="18">
                        </base-button>
                      </el-popover>
                    </div>
                  </div>
                  <div style="display: flex;align-items: center;">
                    <img @click.prevent="copySingleValue('metros')"
                      class="mr-2 pointer" 
                      src="/img/icons/icons8/ios/copy-2_primary.png" width="16">
                  </div>
                </div>
                <div class="col-6 col-md-4 px-md-0">
                  <base-input input-group-classes="input-group-sm" 
                    appendClass="appendorprepend-bg-to-gray"
                    prependClass="appendorprepend-bg-to-gray">
                    <input
                      v-model="service.service_price.by_meters"
                      class="form-control form-control-sm"
                      maxlength="8"
                      disabled
                    />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0">
                        R$
                      </small>
                    </template>
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0">
                        m<sup>3</sup>
                      </small>
                    </template>
                  </base-input>
                </div>
                <div class="col-6 col-md-4">
                  <validation-provider :rules="validAdditionals(service)">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model.lazy="service.proposal_by_meters"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                        v-money="money"
                        maxlength="8"
                        @keydown="$event.key === '-' ? $event.preventDefault() : null"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0">
                          R$
                        </small>
                      </template>
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          m<sup>3</sup>
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="row mt-2 align-items-center">
                <div class="col-md-4 pr-1 pr-md-0 new-default-gray-font" style="font-size: 13px;">
                  Volume mínimo
                </div>
                <div class="col-6 col-md-4">
                  <h6 class="new-default-gray-font font-weight-normal m-0 ml-4" style="font-size: 13px;">
                    {{ calcMinimumVolume() }}
                  </h6>
                </div>
                <div class="col-6 col-md-4">
                  <h6 class="new-default-gray-font font-weight-normal m-0" style="font-size: 13px; margin-left: 37px !important;">
                    {{ calcMinimumVolumeProposal() }}
                  </h6>
                </div>
              </div>
              <div v-if="service.service_price.by_hour" class="row mt-2 align-items-center">
                <div class="col-md-4 pr-1 pr-md-0 new-default-black-font font-weight-500" style="display: flex;">
                  <div style="width: 100%;">
                    <div style="display: inline-flex;align-items: center;">
                      <h4 class="new-default-black-font m-0">
                        Por hora
                      </h4>
                      <el-popover trigger="click"
                        placement="right"
                        v-show="priceIsLower(
                          service.service.by_hour,
                          service.proposal_by_hours
                        )">
                        <h5 class="font-weight-normal m-0">Preço por hora abaixo do mínimo estipulado.</h5>
                        <base-button outline slot="reference" size="sm" type="secundary"
                          class="ml-2 shadow-none text-indigo">
                          <img src="/img/icons/icons8/ios/error--v1_danger.png" width="18">
                        </base-button>
                      </el-popover>
                    </div>
                  </div>
                  <div style="display: flex;align-items: center;">
                    <img @click.prevent="copySingleValue('horas')"
                      class="mr-2 pointer" 
                      src="/img/icons/icons8/ios/copy-2_primary.png" width="16">
                  </div>
                </div>
                <div class="col-6 col-md-4 px-md-0">
                  <base-input input-group-classes="input-group-sm"
                    appendClass="appendorprepend-bg-to-gray"
                    prependClass="appendorprepend-bg-to-gray">
                    <input
                      v-model="service.service_price.by_hour"
                      class="form-control form-control-sm"
                      disabled
                      maxlength="8"
                    />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0">
                        R$
                      </small>
                    </template>
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0">
                        hora
                      </small>
                    </template>
                  </base-input>
                </div>
                <div class="col-6 col-md-4">
                  <validation-provider :rules="validAdditionals(service)">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model.lazy="service.proposal_by_hours"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                        v-money="money"
                        maxlength="8"
                        @keydown="$event.key === '-' ? $event.preventDefault() : null"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0">
                          R$
                        </small>
                      </template>
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          hora
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-12">
                  <h4 class="new-default-black-font">
                    Observações
                  </h4>
                </div>
                <div class="col-12">
                  <base-input input-group-classes="input-group-sm">
                    <textarea type="text" v-model="service.observation" rows="4"
                      class="form-control form-control-sm" placeholder=" ...">
                    </textarea>
                  </base-input>
                </div>
              </div>
              <div class="row mt-4 mb-3">
                <div class="col-12 text-right">
                  <base-button type="danger" 
                    size="sm"
                    style="width: 150px;" @click.prevent="closeModal">
                    <div style="display: inline-flex; align-items: center;">
                      <img src="/img/icons/cancel-white.png" width="20">
                      <i class="m-0 ml-1" style="font-style: normal;">
                        Cancelar
                      </i>
                    </div>
                  </base-button>
                  <base-button type="success"
                    size="sm"
                    style="width: 150px;" 
                    native-type="submit" 
                    :disabled="invalid"
                    :loading="loadingSave">
                    <div style="display: inline-flex; align-items: center;">
                      <img src="/img/icons/save.png" width="20">
                      <i class="m-0 ml-1" style="font-style: normal;">
                        Salvar
                      </i>
                    </div>
                  </base-button>
                </div>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {VMoney} from "v-money";
export default {
  components: {},
  name: "ModalNewValidity",
  data() {
    return {
      modal: false,
      title: 'Gerar nova vigência',
      validated: false,
      loadingSituation: false,
      loading: false,
      service: {},
      allowSave: false,
      allowExpectedVolume: false,
      allowExpectedHour: false,
      loadingSave: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false
      },
    }
  },
  directives: {money: VMoney},
  methods: {
    closeModal() {
      this.modal = false
    },
    calcMinimumVolume(){
      let minimumVolume = '';
      if(this.service.service_price.price_fixed && this.service.service_price.by_meters){
        minimumVolume = (this.service.service_price.price_fixed / this.service.service_price.by_meters).toFixed(2);
        minimumVolume += " m³";
      }
      return minimumVolume;
    },
    calcMinimumVolumeProposal(){
      let minimumVolume = '';
      if(this.service.proposal_price_fixed && this.service.proposal_by_meters)
      {
        const proposal_price_fixed = Number(this.service.proposal_price_fixed.replace('.', '').replace(',', '.'));
        const proposal_by_meters = Number(this.service.proposal_by_meters.replace('.', '').replace(',', '.'));
        if(proposal_price_fixed && proposal_by_meters){
          minimumVolume = (Number(proposal_price_fixed) / Number(proposal_by_meters)).toFixed(2);
          minimumVolume += " m³";
        }
      }
      return minimumVolume;
    },
    copyAllValues(){
      this.service.proposal_by_hours = this.service.service_price.by_hour;
      this.service.proposal_by_meters = this.service.service_price.by_meters;
      this.service.proposal_price_fixed = this.service.service_price.price_fixed;
    },
    copySingleValue(type){
      switch(type){
        case "fixo":
          this.service.proposal_price_fixed = this.service.service_price.price_fixed;
          break;
        case "metros":
          this.service.proposal_by_meters = this.service.service_price.by_meters;
          break;
        case "horas":
          this.service.proposal_by_hours = this.service.service_price.by_hour;
          break;
      }
    },
    validAdditionals(service){
      if (!service.proposal_by_hours && !service.proposal_by_meters && !service.proposal_price_fixed) {
        return 'required'
      }
      return ''
    },
    priceIsLower(a, b) {
      if (!a || !b) {
        return false
      }
      b = b.replace('.', '').replace(',', '.')
      return (Number(a) > Number(b))
        && Number(b) > 0
    },
    openModal(service) {
      service.contract_proposal_uuid = this.$route.params.contract_proposal_uuid
      this.service = {...service}
      let data = new Date();
      this.service.additional_hour = ''
      this.service.additional_volume = ''
      if (!this.service.expected_volume) {
        this.service.expected_volume = 0
      }
      if (!this.service.expected_hour) {
        this.service.expected_hour = 0
      }
      this.service.date_at = data.getFullYear() + "-" + (data.getMonth() + 1) + "-" + data.getDate();
      this.modal = true
    },
    priceRequired(service) {
      if (!service.expected_volume && !service.expected_hour) {
        return 'required'
      }
      return ''
    },
    save() {
      this.service.price_fixed = this.service.service.price_fixed
      this.service.by_hour = this.service.service.by_hour
      this.service.by_meters = this.service.service.by_meters
      this.service.service_name = this.service.service.name
      this.service.service_description = this.service.service.description
      this.loading = true
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.$Progress.start();
      this.$store.dispatch('contractService/createNewValidity', this.service)
        .then(response => {
          this.$Progress.finish();
          this.loading = false
          this.$emit('close')
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.modal = false
        })
    },
  },
  created() {
  },
  mounted() {
    this.$watch("service.proposal_by_meters", function (val) {
      this.allowExpectedVolume = false
      if (val && val !== '0,00') {
        this.allowExpectedVolume = true
      }
    })
    this.$watch("service.proposal_by_hours", function (val) {
      this.allowExpectedHour = false
      if (val && val !== '0,00') {
        this.allowExpectedHour = true
      }
    })
    this.$watch('service', function () {
      this.allowSave = false
      if (this.service.proposal_by_hours && this.service.proposal_by_hours !== '0,00'
        || this.service.proposal_by_meters && this.service.proposal_by_meters !== '0,00'
        || this.service.proposal_price_fixed && this.service.proposal_price_fixed !== '0,00') {
        this.allowSave = true
      }
    }, {
      immediate: true,
      deep: true
    })
  }
};
</script>

<style></style>
