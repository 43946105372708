<template>
  <div class="card-body mt-n2 p-0">
    <div class="row">
      <ShadedTable
        :length="$_items.length"
        :headers="headers"
        :loading="loadingSkeleton"
      >
        <template v-for="(item, index) in $_items" :slot="index">
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.company_plant_name }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.category_name }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.product_name }} | {{ item.supplier_name }} ({{ item.origin_name }})
            </h5>
          </ShadedCol>
          <ShadedCol>
            <div>
              <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
                {{ item.cmc_price | currency }} / {{ item.cmc_purchase_unit }}
              </h5>
            </div>
            <div>
              <el-popover
                trigger="hover"
                placement="right"
                class="p-0 pr-0 mr-2"
              >
                {{ item.cmc_price_updated_at | parseDate("DD MMM YYYY") }}
                <base-button
                  outline
                  slot="reference"
                  size="sm"
                  type="secundary"
                  class="p-0 m-0 ml-1 shadow-none text-indigo col-2"
                >
                  <img src="/img/icons/calendar-green.png" width="19" />
                </base-button>
              </el-popover>
            </div>
          </ShadedCol>
          <ShadedCol>
            <div>
              <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
                {{ item.entry_launch_item_price | currency }} /
                {{ item.cmc_purchase_unit }}
              </h5>
            </div>
            <div>
              <el-popover
                trigger="hover"
                placement="right"
                class="p-0 pr-0 mr-2"
              >
                {{ item.created_at | parseDate("DD MMM YYYY") }}
                <base-button
                  outline
                  slot="reference"
                  size="sm"
                  type="secundary"
                  class="p-0 m-0 ml-1 shadow-none text-indigo col-2"
                >
                  <img src="/img/icons/calendar-danger.png" width="19" />
                </base-button>
              </el-popover>
              <a
                @click.prevent="$emit('handleModalCmcDivergentDocument', item)"
                role="button"
                class="custom-border-radius text-center ml-2"
              >
                <i
                  class="fas fa-bars text-white text-center bg-indigo p-2 custom-border-radius"
                  style="font-size: 10px"
                />
              </a>
            </div>
          </ShadedCol>
          <ShadedCol align="center">
            <div
              v-if="item.status === 0"
              class="row m-0 align-items-center"
              style="gap: 0.5rem"
            >
              <div
                class="col-md-6 d-flex justify-content-center"
                style="padding: 0; flex: 0 0 auto; width: auto"
              >
                <base-button
                  @click.prevent="$emit('openModalObservation', item, 2)"
                  outline
                  block
                  type="danger"
                  :disabled="loadingUpdatePrice"
                  size="sm"
                  class="text-uppercase"
                  style="margin: 0; padding: 0.25rem 0.5rem"
                >
                  Ignorar
                </base-button>
              </div>
              <div
                class="col-md-6 d-flex justify-content-center"
                style="padding: 0; flex: 0 0 auto; width: auto"
              >
                <base-button
                  @click.prevent="$emit('openModalObservation', item, 1)"
                  outline
                  block
                  type="primary"
                  :disabled="loadingUpdatePrice"
                  size="sm"
                  class="text-uppercase"
                  style="margin: 0; padding: 0.25rem 0.5rem"
                >
                  Atualizar
                </base-button>
              </div>
            </div>
            <span v-if="item.status == 1" class="font-weight-normal text-success">
              Atualizado
            </span>
            <span v-if="item.status == 2" class="font-weight-normal text-danger">
              Ignorado
            </span>
            <el-popover v-if="item.observation" trigger="hover" placement="bottom" class="p-0">
              {{ item.observation_creator_name }} | {{ item.observation_creation_timestamp | parseDate('DD/MM/YYYY HH:mm') }}
              <hr class="bg-ligth m-0 mt-2 mb-2">
              <div style="font-size: 12px">{{ item.observation }}</div>
              <span slot="reference">
                <img width="22px" height="22px" src="/img/icons/info.png" class="ml-2" />
              </span>
            </el-popover>
          </ShadedCol>
        </template>
      </ShadedTable>
    </div>
  </div>
</template>

<script>
import SkeletonPuzl from "@/components/SkeletonPuzl";
import ShadedTable from "@/components/ShadedTable.vue";
import ShadedCol from "@/components/ShadedCol.vue";
import { mapGetters } from "vuex";

export default {
  name: "TableConcretesResume",
  data() {
    return {
      headers: [
        "Central",
        "Categoria",
        "Mcc",
        "Preço vigente",
        "Preço de nota",
        "Ações",
      ],
    };
  },
  props: {
    loadingSkeleton: Boolean,
  },
  computed: {
    ...mapGetters({
      $_items: "cmcDivergentPrice/fetch",
    }),
  },
  components: {
    SkeletonPuzl,
    ShadedTable,
    ShadedCol,
  },
};
</script>

<style scoped>
.custom-border-radius .fa-bars {
  border-radius: 1.375rem !important;
}
</style>
