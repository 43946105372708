<template>
<div>
    <base-header class="bg-gray-content">
        <div class="row align-items-center py-4">
            <div class="col-lg-12 col-12">
                <PuzlBreadcrumb/>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-2 mb-2">
                <base-button 
                    @click.prevent="handleCreateModal" 
                    class="text-uppercase" 
                    block size="lg" 
                    type="success"
                >
                    <i class="fas fa-plus"></i> novo
                </base-button>
            </div>
            <div class="col-md-2 mb-2">
                <BackButton />
            </div>
        </div>
    </base-header>
    <ListStockAdjustment />
    <CreateStockAdjustment ref="modalCreate" />
</div>
</template>

<script>
import {mapGetters} from "vuex";
import ListStockAdjustment from "./Shared/_List";
import CreateStockAdjustment from "./Shared/_ModalCreate";
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import BackButton from "@/components/Utils/BackButton.vue";
export default {
    name: "Index",
    components: {
        ListStockAdjustment,
        CreateStockAdjustment,
        PuzlBreadcrumb,
        BackButton,
    },
    methods: {
        handleCreateModal() {
            this.$refs.modalCreate.handleCreateModal();
        },
    },
}
</script>

<style></style>
