<template>
<div>
    <modal :show.sync="modal.create">
        <template slot="header">
            <h5 class="modal-title pl-3">{{ modal.title }}</h5>
        </template>
        <div>
            <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
                <form class="needs-validation" @submit.prevent="handleSubmit(update)" autocomplete="off">

                    <!-- CAMPO MÓDULO -->
                    <div class="form-group row m-0 p-0">
                        <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                            Módulo
                            <span class="text-danger">&nbsp;*</span>
                        </label>
                        <div class="col-md-8 mb-1 pr-4">
                        <validation-provider rules="required" v-slot="{errors}">
                            <base-input input-classes="form-control-sm">
                            <PuzlSelect
                                v-model="$_tutorial.modules_id"
                                :items="$_modules"
                                :loading="loadingModules"
                                :disabled="loadingModules" />
                            </base-input>
                        </validation-provider>
                        </div>
                    </div>

                    <!-- CAMPO TELA -->
                    <div class="form-group row m-0 p-0">
                        <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                            Tela
                            <span class="text-danger">&nbsp;*</span>
                        </label>
                        <div class="col-md-8 mb-1 pr-4">
                            <validation-provider rules="required" v-slot="{errors}">
                                <base-input input-classes="form-control-sm">
                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        v-model="$_tutorial.screen"
                                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                                    />
                                </base-input>
                            </validation-provider>
                        </div>
                    </div>

                     <!-- CAMPO NAME -->
                    <div class="form-group row m-0 p-0">
                        <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                            Nome
                            <span class="text-danger">&nbsp;*</span>
                        </label>
                        <div class="col-md-8 mb-1 pr-4">
                            <validation-provider rules="required" v-slot="{errors}">
                                <base-input input-classes="form-control-sm">
                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        v-model="$_tutorial.name"
                                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                                    />
                                </base-input>
                            </validation-provider>
                        </div>
                    </div>

                    <!-- CAMPO VíDEO -->
                    <div class="form-group row m-0 p-0">
                        <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                            Vídeo
                            <span class="text-danger">&nbsp;*</span>
                        </label>
                        <div class="col-md-8 mb-1 pr-4">
                            <validation-provider rules="required" v-slot="{errors}">
                                <base-input input-classes="form-control-sm">
                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        v-model="$_tutorial.video"
                                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                                    />
                                </base-input>
                            </validation-provider>
                        </div>
                    </div>

                    <!-- CAMPO DESCRIÇÃO -->
                    <div class="form-group row m-0 p-0">
                        <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                            Descrição
                        </label>
                        <div class="col-md-8 mb-1 pr-4">
                            <base-input input-group-classes="input-group-sm">
                                <textarea type="text" rows="2" class="form-control form-control-sm" v-model="$_tutorial.description">
                                </textarea>
                            </base-input>
                        </div>
                    </div>

                    <!-- MODAL FOOTER -->
                    <div class="modal-footer">
                            <base-button type="secondary" @click="closeModal('create')">
                                Cancelar
                            </base-button>
                            <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                                Salvar
                            </base-button>
                        </div>
                </form>
            </validation-observer>
        </div>
    </modal>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import PuzlSelect from "@/components/PuzlSelect";
const { formatErrorValidation } = require("@/plugins");

export default {
    name: "ModalCreate",
    components: { PuzlSelect },
    data() {
        return {
            modal: {
                title: 'Editar Vídeo de Tutorial',
                create: false,
            },
            loadingStore: false,
            loadingModules: true,
        };
    },
    computed: {
        ...mapGetters({
            $_tutorial: 'tutorial/show',
            $_modules: 'module/fetch',
        }),
    },
    mounted() {
        this.$refs.formValidator.validate();
    },
    methods: {
        closeModal() {
            this.modal.create = false;
        },
        /**
         * @param {integer} id
         */
        handleEditModal(id) {
            this.$Progress.start();
            this.loadData();
            this.$store.dispatch('tutorial/show', id)
                .then((response) => {
                    this.modal.create = true;
                }).catch((error) => {
                    this.$notify({
                        type: error.data.error_type,
                        message: error.data.message
                    });
                }).finally(() => {
                    this.$Progress.finish();
                });
        },
        update() {
            this.$Progress.start();
            this.$_tutorial.status = this.$_tutorial.modules_id;
            this.loadingStore = true;
            this.$store.dispatch('tutorial/update', this.$_tutorial)
                .then(response => {
                    this.modal.create = false;
                    this.$notify({
                        type: response.error_type,
                        message: response.message
                    })
                })
                .catch(error => {
                    if (error.status == 200) {
                        this.$notify({
                            type: 'danger',
                            message: error.data.message
                        });
                    } else if (error.response && error.response.status === 422) {
                        let errors = formatErrorValidation(error.response.data.errors)
                        this.$notify({
                            type: 'danger',
                            message: errors
                        });
                    }
                }).finally(() => {
                    this.loadingStore = false;
                    this.$Progress.finish();
                });
        },
        loadData() {
            this.loadingModules = true;
            this.$store.dispatch('module/getModules').then(() => {
                this.loadingModules = false;
            });
        },
    },
};
</script>

<style scoped></style>
