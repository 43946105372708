const keys = Object.freeze({
  BINDER: 1,
  WATER: 2,
  CONTENT_MORTAR: 3,
  TOTAL_AIR: 4,
  AGENT: 5,
  CONTENT: 6,
  CONTENT_MIN: 7,
  CONTENT_MAX: 8
});

export default {
  keys,
};
