<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center pl-4 py-4">
        <div class="col-md-4">
          <h6 class="h2 new-default-black-font d-inline-block mb-0">
            <img width="30px" class="mr-1" src="/img/icons/card-in-use.png" alt=""> RECEBIMENTOS
          </h6>
        </div>
        <div class="col-md-8 pr-4">
          <div class="row d-flex justify-content-end">
            <div class="mb-md-0 mb-2 col-md-3 px-1">
              <base-button @click.prevent="handleCreateEntity()" type="success" block>
                <img height="19" src="/img/icons/plus-math--v1-white.png" class="mr-1 mt-n1"/>
                NOVO
              </base-button>
            </div>
            <div class="mb-md-0 mb-2 col-md-3 px-1">
              <base-button type="primary"
                :disabled="Object.values(contract_proposal).length == 0"
                @click="handleEntityHistory(contract_proposal.entity.id)"
                block>
                <img height="19" src="/img/icons/wallet-white.png" class="mr-1 mt-n1"/>
                SALDOS
              </base-button>
            </div>
          </div>
        </div>
      </div>
      <!-- Header Menus -->
      <div class="ml-3 mb-1 mt-n3">
        <div v-if="$refs.list && $refs.list.type === listingType['Recebimentos']" class="checklist-info">
          <div v-if="loadingSkeleton">
            <div v-for="item in 3">
              <div class="col-3 ml-n4">
                <SkeletonPuzl type="button" />
              </div>
            </div>
          </div>
          <div v-else>
            <h3 class="new-default-black-font checklist-title mb-0">
              Obra:
              <span class="small">
                {{ contract_proposal.construction && contract_proposal.construction.construction_name }}
              </span>
            </h3>
            <h3 class="new-default-black-font checklist-title mb-0">
              Cliente:
              <span class="small">
                {{
                  contract_proposal.construction && contract_proposal.construction.customer_construction.customer_name
                }}
              </span>
            </h3>
            <h3 class="new-default-black-font checklist-title mb-0">
              Cód. Contrato:
              <span class="small">{{ contract_proposal.code }}</span>
            </h3>
          </div>
        </div>
        <div v-else class="checklist-info mb-3 mt-n2">
          <div v-if="loadingSkeleton">
            <div v-for="item in 2">
              <div class="col-3 ml-n4">
                <SkeletonPuzl type="button" />
              </div>
            </div>
          </div>
          <div v-else>
            <h3 class="new-default-black-font checklist-title mb-0">
              Cliente:
              <span class="small">
                {{
                  contract_proposal.entity && contract_proposal.entity.entity_name
                }}
              </span>
            </h3>
            <h3 class="new-default-black-font checklist-title mb-0">
              CPF/CNPJ:
              <span class="small">
                {{
                  contract_proposal.entity && contract_proposal.entity.document
                }}
              </span>
            </h3>
          </div>
        </div>
      </div>

      <div :class="contract_proposal.entity ? '' : 'd-flex justify-content-center'">
        <ContractProposalNavigation
          v-if="contract_proposal"
          :contractUuid="contract_proposal.uuid"
          :contractStatus="contract_proposal.status"
          :entity="contract_proposal.entity"
          :activePayments="true"
        />
      </div>

      <div class="row align-items-center p-0">
        <div class="container-fluid">
          <div class="mt-4 mb-2" style="display: flex; align-items: center;">
            <i class="pointer fa-regular fa-hard-drive fa-2x"></i>
            <i class="opacity-25 pointer fa-solid fa-table-list fa-2x ml-1"></i>
            <button style="margin: 0;width: 115px; justify-content: center; min-height: 28px; padding: 0; font-size: 11px; display: flex; align-items: center;" slot="title-container"
              @click="handleFilterAdvancedStatus('cancelled')"
              :class="{ active: filter.advanced === 'cancelled' }"
              class="ml-2 btn text-uppercase btn-outline-danger colorize-btn-img"
              type="button">
              <img v-bind:src="filter.advanced === 'cancelled' ? '/img/icons/close-white.png' : '/img/icons/close.png'" width="16px" class="mr-1" />
              Cancelada
            </button>
          </div>
        </div>
      </div>
    </base-header>
    <ListPayment ref="list"/>
    <CreatePayment ref="createPayment"/>
    <ModalGeneralResume ref="modalGeneralResume"/>
    <ModalListContacts ref="listContacts"/>
    <float-button :contract_proposal="contract_proposal"/>
    <modal-create-entity @added="$refs.list.getBillReceives()" ref="modalCreateEntity"/>
    <ModalEntityHistory ref="modalEntityHistory"/>
  </div>
</template>

<script>
import ListPayment from "./Shared/_List";
import CreatePayment from "./Shared/_Create";
import {mapGetters} from "vuex";
import BackButton from "../../../../../components/Utils/BackButton";
import MultiFilter from "../../../../../components/Utils/MultiFilterV2";
import ModalGeneralResume from "../Shared/_ModalGeneralResume.vue";
import ModalListContacts from "../../../Operational/Schedule/Weighing/Shared/_ModalListContacts";
import FloatButton from "../components/FloatButton/Index.vue";
import CreateNew from "../components/FloatButton/CreateNew/Index.vue";
import ModalCreateEntity from '../../../Financial/BillReceive/Entity/Shared/Modals/_ModalCreateEntity'
import ModalEntityHistory from "../../../Configuration/Entity/Shared/_ModalHistory";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import ContractProposalNavigation from "@/components/ContractProposalNavigation";

export default {
  components: {
    MultiFilter,
    BackButton,
    ListPayment,
    CreatePayment,
    ModalGeneralResume,
    ModalListContacts,
    FloatButton,
    CreateNew,
    ModalCreateEntity,
    ModalEntityHistory,
    SkeletonPuzl,
    ContractProposalNavigation,
  },
  computed: {
    ...mapGetters({
      contract_proposal: "contractProposal/show",
    }),
  },
  data() {
    return {
      filter: {
        advanced: null,
      },
      listingType: {
        'Antecipação': 0,
        'Recebimentos': 1,
      },
      loadingSkeleton: true,
    };
  },
  methods: {
    handleCreateEntity() {
      this.$refs.modalCreateEntity.handleCreateModal(this.contract_proposal.entity, this.contract_proposal.company_plant_id)
    },
    handleFilterAdvancedStatus(key) {
      this.filter.advanced = this.filter.advanced === key ? null : key;
      this.$refs.list.load(this.filter);
    },
    load() {
      this.$refs.list.load(this.filter);
    },
    handleCreate(default_value = false) {
      this.$refs.createPayment.handleCreateModal(this.contract_proposal.plant, default_value);
    },
    handleGeneralResume() {
      this.$refs.modalGeneralResume.openModal(this.contract_proposal.id);
    },
    handleListContacts() {
      this.$refs.listContacts.openModal(this.contract_proposal.construction.uuid);
    },
    handleEntityHistory(entity_id) {
      this.$refs.modalEntityHistory.openModal(entity_id);
    },
    getContractProposal() {
      this.$store.dispatch("contractProposal/show", this.$route.params.contract_proposal_uuid).then(() => {})
    }
  },
  mounted() {
    this.$refs.list.loadingSkeleton = true
    this.loadingSkeleton = true
    this.$store.dispatch("contractProposal/show", this.$route.params.contract_proposal_uuid).then(() => {
      this.loadingSkeleton = false
      this.$refs.list.getBillReceives({})
      this.$store.dispatch("contractProposalCredit/getBalance", this.contract_proposal.id).then(response => {
        this.contract_proposal.balance = response.data
      });
    });
  },
};
</script>

<style>
.custom-pending-card .card-body {
  padding: 0.6rem !important
}
.main-menu {
  flex-wrap: nowrap !important;
  display: flex;
  justify-content: space-between;
}
.rounded-circle {
  border: 1px solid #D3D3D3;
}
.background-color-circle {
  background-color: #f3f4fa;
}
.icon:hover {
  box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.5);
  transform: scale(1.1);
  background-color: #D3D3D3;
}
.base-button:hover .invert-on-hover {
  filter: brightness(0) invert(1);
}
.base-button:hover .invert-on-hover {
  filter: brightness(0) invert(1);
}
.base-button:hover .text-dark {
  color: white !important;
}
</style>

<style scoped>
.colorize-btn-img:hover img, .show img {
  filter: brightness(0) invert(1);
}
</style>
