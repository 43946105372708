<template>
  <div>
    <modal class="bill-modal-create" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <div class="p-2">
          <div v-if="margin.user_name" class="border-left border-3 border-primary pr-1 mt-0 mb-4">
            <h4 class="m-0 pl-2">{{ margin.user_name }}</h4>
            <h4 class="m-0 font-weight-normal pl-2">{{ margin.updated_at | parseDate }}</h4>
          </div>
          <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
            <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
              <div>
                <div class="row">
                  <div class="col-md-12 table-responsive">
                    <table class="table table-sm table-bordered">
                      <thead>
                        <tr class="fixed">
                          <th class="text-center">
                            <span class="h5">Categoria</span>
                          </th>
                          <th class="text-center">
                            <span class="h5">Inferior</span>
                          </th>
                          <th class="text-center">
                            <span class="h5">Superior</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- Aglomerante -->
                        <tr>
                          <th class="text-center align-middle">
                            <span class="style-table h5 font-weight-normal"> Aglomerante </span>
                          </th>
                          <th class="text-left">
                            <validation-provider rules="required">
                              <input-limit
                                :options="{
                                  min: -999.99,
                                  decimals: 2,
                                }"
                                max_length="7"
                                input_group_classes="input-group-sm pb-0"
                                :start_value="margin.minimum_binder"
                                v-model="margin.minimum_binder"
                                @value="margin.minimum_binder = -Math.abs($event)"
                              >
                                <small class="input-group-sm p-0 m-0"> % </small>
                              </input-limit>
                            </validation-provider>
                          </th>
                          <th class="text-left">
                            <validation-provider rules="required">
                              <input-limit
                                :options="{
                                  min: 0,
                                  max: 999.99,
                                  decimals: 2,
                                }"
                                max_length="6"
                                input_group_classes="input-group-sm pb-0"
                                :start_value="margin.maximum_binder"
                                v-model="margin.maximum_binder"
                                @value="margin.maximum_binder = $event === '-' ? null : $event"
                              >
                                <small class="input-group-sm p-0 m-0"> % </small>
                              </input-limit>
                            </validation-provider>
                          </th>
                        </tr>
                        <!-- Agregado Graúdo -->
                        <tr>
                          <th class="text-center align-middle">
                            <span class="style-table h5 font-weight-normal"> Agregado Graúdo </span>
                          </th>
                          <th class="text-left">
                            <validation-provider rules="required">
                              <input-limit
                                :options="{
                                  min: -999.99,
                                  decimals: 2,
                                }"
                                max_length="7"
                                input_group_classes="input-group-sm pb-0"
                                :start_value="margin.minimum_large_aggregate"
                                v-model="margin.minimum_large_aggregate"
                                @value="margin.minimum_large_aggregate = -Math.abs($event)"
                              >
                                <small class="input-group-sm p-0 m-0"> % </small>
                              </input-limit>
                            </validation-provider>
                          </th>
                          <th class="text-left">
                            <validation-provider rules="required">
                              <input-limit
                                :options="{
                                  min: 0,
                                  max: 999.99,
                                  decimals: 2,
                                }"
                                max_length="6"
                                input_group_classes="input-group-sm pb-0"
                                :start_value="margin.maximum_large_aggregate"
                                v-model="margin.maximum_large_aggregate"
                                @value="margin.maximum_large_aggregate = $event === '-' ? null : $event"
                              >
                                <small class="input-group-sm p-0 m-0"> % </small>
                              </input-limit>
                            </validation-provider>
                          </th>
                        </tr>
                        <!-- Agregado Miúdo -->
                        <tr>
                          <th class="text-center align-middle">
                            <span class="style-table h5 font-weight-normal"> Agregado Miúdo </span>
                          </th>
                          <th class="text-left">
                            <validation-provider rules="required">
                              <input-limit
                                :options="{
                                  min: -999.99,
                                  decimals: 2,
                                }"
                                max_length="7"
                                input_group_classes="input-group-sm pb-0"
                                :start_value="margin.minimum_small_aggregate"
                                v-model="margin.minimum_small_aggregate"
                                @value="margin.minimum_small_aggregate = -Math.abs($event)"
                              >
                                <small class="input-group-sm p-0 m-0"> % </small>
                              </input-limit>
                            </validation-provider>
                          </th>
                          <th class="text-left">
                            <validation-provider rules="required">
                              <input-limit
                                :options="{
                                  min: 0,
                                  max: 999.99,
                                  decimals: 2,
                                }"
                                max_length="6"
                                input_group_classes="input-group-sm pb-0"
                                :start_value="margin.maximum_small_aggregate"
                                v-model="margin.maximum_small_aggregate"
                                @value="margin.maximum_small_aggregate = $event === '-' ? null : $event"
                              >
                                <small class="input-group-sm p-0 m-0"> % </small>
                              </input-limit>
                            </validation-provider>
                          </th>
                        </tr>
                        <!-- Aditivo -->
                        <tr>
                          <th class="text-center align-middle">
                            <span class="style-table h5 font-weight-normal"> Aditivo </span>
                          </th>
                          <th class="text-left">
                            <validation-provider rules="required">
                              <input-limit
                                :options="{
                                  min: -999.99,
                                  decimals: 2,
                                }"
                                max_length="7"
                                input_group_classes="input-group-sm pb-0"
                                :start_value="margin.minimum_additive"
                                v-model="margin.minimum_additive"
                                @value="margin.minimum_additive = -Math.abs($event)"
                              >
                                <small class="input-group-sm p-0 m-0"> % </small>
                              </input-limit>
                            </validation-provider>
                          </th>
                          <th class="text-left">
                            <validation-provider rules="required">
                              <input-limit
                                :options="{
                                  min: 0,
                                  max: 999.99,
                                  decimals: 2,
                                }"
                                max_length="6"
                                input_group_classes="input-group-sm pb-0"
                                :start_value="margin.maximum_additive"
                                v-model="margin.maximum_additive"
                                @value="margin.maximum_additive = $event === '-' ? null : $event"
                              >
                                <small class="input-group-sm p-0 m-0"> % </small>
                              </input-limit>
                            </validation-provider>
                          </th>
                        </tr>
                        <!-- Adição -->
                        <tr>
                          <th class="text-center align-middle">
                            <span class="style-table h5 font-weight-normal"> Adição </span>
                          </th>
                          <th class="text-left">
                            <validation-provider rules="required">
                              <input-limit
                                :options="{
                                  min: -999.99,
                                  decimals: 2,
                                }"
                                max_length="7"
                                input_group_classes="input-group-sm pb-0"
                                :start_value="margin.minimum_addition"
                                v-model="margin.minimum_addition"
                                @value="margin.minimum_addition = -Math.abs($event)"
                              >
                                <small class="input-group-sm p-0 m-0"> % </small>
                              </input-limit>
                            </validation-provider>
                          </th>
                          <th class="text-left">
                            <validation-provider rules="required">
                              <input-limit
                                :options="{
                                  min: 0,
                                  max: 999.99,
                                  decimals: 2,
                                }"
                                max_length="6"
                                input_group_classes="input-group-sm pb-0"
                                :start_value="margin.maximum_addition"
                                v-model="margin.maximum_addition"
                                @value="margin.maximum_addition = $event === '-' ? null : $event"
                              >
                                <small class="input-group-sm p-0 m-0"> % </small>
                              </input-limit>
                            </validation-provider>
                          </th>
                        </tr>
                        <!-- Água -->
                        <tr>
                          <th class="text-center align-middle">
                            <span class="style-table h5 font-weight-normal"> Água </span>
                          </th>
                          <th class="text-left">
                            <validation-provider rules="required">
                              <input-limit
                                :options="{
                                  min: -999.99,
                                  decimals: 2,
                                }"
                                max_length="7"
                                input_group_classes="input-group-sm pb-0"
                                :start_value="margin.minimum_water"
                                v-model="margin.minimum_water"
                                @value="margin.minimum_water = -Math.abs($event)"
                              >
                                <small class="input-group-sm p-0 m-0"> % </small>
                              </input-limit>
                            </validation-provider>
                          </th>
                          <th class="text-left">
                            <validation-provider rules="required">
                              <input-limit
                                :options="{
                                  min: 0,
                                  max: 999.99,
                                  decimals: 2,
                                }"
                                max_length="6"
                                input_group_classes="input-group-sm pb-0"
                                :start_value="margin.maximum_water"
                                v-model="margin.maximum_water"
                                @value="margin.maximum_water = $event === '-' ? null : $event"
                              >
                                <small class="input-group-sm p-0 m-0"> % </small>
                              </input-limit>
                            </validation-provider>
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <base-button type="secondary" @click="closeModal()"> Cancelar </base-button>
                <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loading"> Salvar </base-button>
              </div>
            </form>
          </validation-observer>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import InputLimit from "@/components/Utils/InputLimit";

export default {
  name: "ModalCmcDivergentPriceMargin",
  components: { InputLimit },
  data() {
    return {
      modal: {
        title: "Margem",
        create: false,
      },
      loading: false,
      margin: {
        margin: 0,
        user_name: 0,
        updated_at: 0,
        minimum_water: 0,
        maximum_water: 0,
        minimum_binder: 0,
        maximum_binder: 0,
        minimum_large_aggregate: 0,
        maximum_large_aggregate: 0,
        minimum_small_aggregate: 0,
        maximum_small_aggregate: 0,
        minimum_additive: 0,
        maximum_additive: 0,
        minimum_addition: 0,
        maximum_addition: 0,
        user_name:null,
        updated_at:null,
      },
    };
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal() {
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });
      this.$store
        .dispatch("cmcDivergentPrice/getMargin")
        .then((response) => {
          this.margin = response.data ? response.data : this.margin;
          this.$notify({ type: response.error_type, message: response.message });
          this.modal.create = true;
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: error.data.message,
          });
        });
    },
    store() {
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });
      this.loading = true;
      this.$store.dispatch("cmcDivergentPrice/storeMargin", this.margin).then((response) => {
        this.loading = false;
        this.modal.create = false;
        this.$notify({
          type: response.error_type,
          message: response.message,
        });
      });
    },
  },
};
</script>
