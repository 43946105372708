import Auth from './auth/routes';
import Commercial from './commercial/routes';
import Configuration from './configuration/routes';
import Equipment from './equipment/routes';
import Fiscal from './fiscal/routes';
import Dashboard from './dashboard/routes';
import Logout from '@/views/Pages/Logout.vue';
import Example from '@/views/Modules/Example/Index.vue';
import Profile from '@/views/Pages/UserProfile/UserCard.vue';
import Technology from './technology/routes';
import Operational from './operational/routes';
import Financial from './financial/routes';
import Administrative from './administrative/routes';
import Driver from './driver/routes';
import Support from './support/routes';
import Totvs from './totvs/routes';
import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import auth from "@/middleware/auth";
import ForgotPassword from '@/views/Pages/ForgotPassword.vue';
import ResetPassword from '@/views/Pages/ResetPassword.vue';
import WithoutPermission from "@/views/GeneralViews/400";
import EntityLogin from '@/views/Modules/Commercial/Purchases/Entity/Login.vue';
import EntityBudget from '@/views/Modules/Commercial/Purchases/Entity/EntityBudget.vue';
import Services from './services/routes';

const routes = [
  {
    path: '/entity-login/:code',
    name: 'Entity Login',
    name: 'entity.login',
    component: EntityLogin,
    meta: {
      middleware: false
    },
  },
  {
    path: '/entity-budget/:token',
    name: 'entity.budget',
    component: EntityBudget,
  },
  {
    path: '/',
    redirect: '/login',
    name: 'login'
  },
  { path: '/400', component: WithoutPermission },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/forgot-password',
    name: 'forgot.password',
    component: ForgotPassword,
    meta: {
      middleware: false
    },
  },
  {
    path: '/reset-password',
    name: 'reset.password',
    component: ResetPassword,
    meta: {
      middleware: false
    },
  },
  {
    path: '/example',
    name: 'example',
    meta: {
      middleware: auth,
    },
    component: DashboardLayout,
    children: [
      {
        path: '/',
        name: 'example.view',
        component: Example,
        meta: {
          title: 'Exemplo',
          description: 'Exemplo',
        },
      },
    ]
  },
  Dashboard,
  Configuration,
  Equipment,
  Fiscal,
  Technology,
  Commercial,
  Operational,
  Services,
  Financial,
  Driver,
  Support,
  Administrative,
  Totvs,
  Auth,
];

export default routes;
