import { mask1 } from "@/plugins";
import { checkNumberValue } from "@/helpers";

const entryLaunchItemMixin = {
  methods: {
    getTotalValue() {
      const price = parseFloat(this.item.price.replaceAll(".", "").replace(",", "."));
      const discount = (typeof this.item.discount === 'string')
        ? parseFloat(this.item.discount.replaceAll(".", "").replace(",", "."))
        : 0;
      const quantity = this.item.quantity ? parseFloat(this.item.quantity.replace(',','.')) : 0;
      this.item.total_value = (price * quantity) - discount;
    },
    formatString(value, min, max, decimals = "") {
      return checkNumberValue(mask1(value.toString(), decimals, ".", true), min, max).replace(".", ",");
    },
  },
};
export default entryLaunchItemMixin;
