<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-gray d-inline-block mb-0">{{ $route.name }}</h6>
          <nav
            aria-label="breadcrumb"
            class="d-none d-md-inline-block ml-md-4 text-gray"
          >
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i></a></li>
              <li class="breadcrumb-item"><a href="#">Cadastro</a></li>
              <li class="breadcrumb-item" aria-current="page">{{ $route.name }}</li>
            </ol>
          </nav>
        </div>
      </div>
<!-- pr-6 pl-6 pt-3 pb-3 -->
      <!-- Header Menus -->
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div v-if="$hasPrivilege(1)" class="col-md-2  col-xlg-2 mb-3">
              <base-button block size="lg" type="success" @click="handleCreate()">
                <i class="fas fa-plus"></i> NOVO
              </base-button>
            </div>
            <div class="col-md-2 col-xlg-2 mb-3">
              <back-button/>
            </div>
          </div>
        </div>
      </div>

      <div class="row align-items-center pb-0">
        <div class="col-md-12">
          <div class="card p-2">
            <base-input placeholder="Pesquisar">
              <template slot="append">
                <i class="fas fa-search"></i>
              </template>
            </base-input>
          </div>
        </div>
      </div>
    </base-header>


<list-additional></list-additional>
<create-additional ref="createAdditional"></create-additional>
</div>
</template>
<script>
import ListAdditional from './Shared/List'
import CreateAdditional from './Shared/Create';
import BackButton from "../../../../components/Utils/BackButton";

export default {
  components: {
    BackButton,
    ListAdditional,
    CreateAdditional,
  },
  data() {
    return {
      listPriceAdditional: {},
      itemPriceAdditional: {
        status: true,
      },
    }
  },
  methods: {
    handleCreate(){
      this.$refs.createAdditional.handleCreateModal()
    }
  },
  created(){
  },
  mounted() {
  }
};
</script>
<style></style>
