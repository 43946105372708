 <template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-md-4">
          <h2 style="font-size: 1.2rem !important" class="text-success">
            <img height="30px" src="/img/icons/icons8/ios/client-company_success.png" class="mr-2 mb-1" />
            Preço de serviço
          </h2>
        </div>
        <div class="col-md-8 pr-4">
          <div class="row d-flex justify-content-end">
            <div class="mb-md-0 mb-2 col-md-3 px-1">
              <base-button type="success" block @click="handleCreate()">
                <img height="19" src="/img/icons/plus-math--v1-white.png" class="mr-1 mt-n1" />
                NOVO
              </base-button>
            </div>
            <base-dropdown class="mb-md-0 mb-2 col-md-3 px-1">
              <base-button block slot="title-container" type="warning" class="dropdown-toggle text-uppercase" style="font-size: 0.8rem">
                <img height="19" src="/img/icons/product-documents.png" class="mr-1 mt-n1" />
                Cadastros
              </base-button>

              <div>
                <router-link :to="{ path: '/configuration/service' }">
                  <a class="dropdown-item new-default-black-font">
                    <img height="19" src="/img/icons/todo-list.png" class="mr-1 mt-n1" /> Serviços
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                </router-link>

                <router-link :to="{ path: '/commercial/service-description' }">
                  <a class="dropdown-item new-default-black-font" href="#">
                    <img height="19" src="/img/icons/icons8/ios/inscription_primary.png" class="mr-1 mt-n1" /> Descrição do Serviço
                  </a>
                </router-link>
              </div>
            </base-dropdown>
<!--             <div class="mb-md-0 mb-2 col-md-3 px-1">
              <base-button block class="new-default-black text-uppercase" style="font-size: 0.8rem">
                <img height="19" src="/img/icons/rating.png" class="mr-1 mt-n1" />
                Relatórios
              </base-button>
            </div> -->
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid">
      <div>
        <multi-filter 
          :type="3"
          ref="multi-filter"
          @fetch="init"
          :showSlotBookmark="false"
          :showSlotData="false"
          :showSlotDates="false"
          :filter="filter">
          <template slot="status">
            <div class="col-12">
              <base-button-hoverable
                :active="filter.status == 1"
                @click="setStatus(1)"
                size="sm"
                type="success"
                icon="ok--v1"
                platform="ios"
              >
                ATIVO
              </base-button-hoverable>
            </div>
            <div class="col-12 mt-1">
              <base-button-hoverable
                size="sm"
                :active="filter.status == 0"
                @click="setStatus(0)"
                type="danger"
                icon="cancel"
                platform="ios"
              >
                INATIVO
              </base-button-hoverable>
            </div>
            <div class="col-12 mt-1 mb-5">
              <base-button-hoverable
                size="sm"
                :active="filter.status === null"
                @click="setStatus(0)"
                type="dark"
                icon="cancel"
                platform="ios"
              >
                Todos
              </base-button-hoverable>
            </div>
          </template>
        </multi-filter>
      </div>
      <list-service-price
        :handleDelete="handleDelete"
        :handleServicePriceEdit="handleServicePriceEdit"
        :changeStatus="changeStatus"
        :servicesPrice="servicesPrice"
        :loadingSkeleton="loadingSkeleton"
        :loading="loading"
        />
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="(index) in 3" :key="index" />
      </div>
      <PuzlEmptyData v-show="!loadingGrid" v-if="servicesPrice == ''" />
      <loading-pagination :show="loading && !loadingSkeleton" />
      <add-service-price></add-service-price>
      <edit-service-price></edit-service-price>
    </div>
  </div>
</template>

<script>
import AddServicePrice from './Shared/_ModalCreate.vue';
import EditServicePrice from './Shared/_ModalEdit.vue';
import ListServicePrice from './Shared/_List.vue';
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import MultiFilter from '@/components/Utils/MultiFilterV3.vue';
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import { mapGetters } from 'vuex';
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";

export default {
  mixins: [cursorPaginate],
  components: {
    SkeletonPuzlGrid,
    LoadingPagination,
    PuzlEmptyData,
    MultiFilter,
    AddServicePrice,
    ListServicePrice,
    EditServicePrice,
    BaseButtonHoverable
  },
  data() {
    return {
      loadingGrid: true,
      loadingSkeleton: false,
      filter: {
        status: null,
      }
    }
  },
  computed: {
    ...mapGetters({
      'servicesPrice': 'servicePrice/fetch',
    }),
  },
  methods: {
    setStatus(status) {
      if (this.filter.status == status) {
        this.filter.status = null;
      } else {
        this.filter.status = status;
      }
      this.init(this.filter);
    },
    handleCreate() {
      EventBus.$emit("handleCreateServicePrice");
    },
    handleChangePuzlCustomerServicePrice(item) {
      this.$Progress.start();
      this.$notify({ type: 'info', message: 'Estamos trabalhando em sua solicitação.' });
      this.$axios.put("/commercial/service/price/puzl-customer/" + item.uuid, { puzl_customer: !item.puzl_customer })
        .then(({ data }) => {
          this.$notify({ type: data.error_type, message: data.message });
          this.init();
          this.$Progress.finish();
        })
        .catch((error) => {
          this.$notify({ type: error.response.data.error_type, message: error.response.data.message });
          this.$Progress.finish();
        });
    },
    init(filter = null) {
      this.startCursor(filter);
      this.$Progress.start();
      this.$store.dispatch('servicePrice/fetch', {
        filter: this.filter,
        next_page: this.paginate.nextUrl
      })
        .then(response => {
          this.resolveCursor(response);
        })
        .catch((error) => {
          this.resolveCursor();
        })
        .finally(() => {
          this.$Progress.finish();
          this.loadingGrid = false;
        });
    },
    handleDelete(uuid) {
      this.$Progress.start();
      this.$swal.fire({
        title: 'Você tem certeza?',
        text: 'Você não poderá recuperar este registro!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, apague isto!',
        cancelButtonText: 'Não, mantenha',
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$notify({ type: 'info', message: 'Estamos trabalhando em sua solicitação.' });
          this.$axios.delete("/commercial/service/price/" + uuid)
            .then(({ data }) => {
              this.$notify({ type: data.error_type, message: data.message });
              this.init()
              this.$Progress.finish();
            })
            .catch((error) => {
              this.$notify({ type: error.response.data.error_type, message: error.response.data.message });
            });
        }
        this.$Progress.finish();
      });
    },
    handleServicePriceEdit(uuid) {
      EventBus.$emit("handleEditServicePrice", uuid);
    },
    /**
     * @param {object} servicePrice
     */
    changeStatus(servicePrice) {
      const previousStatus = servicePrice.status;
      servicePrice.status = +!servicePrice.status;
      this.$store.dispatch("servicePrice/updateStatus", servicePrice).then((response) => {
        if (response.data) {
          this.$notify({
            type: "success",
            message: "Status atualizado com sucesso!"
          });
        } else {
          servicePrice.status = previousStatus;
          this.$notify({
            type: "danger",
            message: "Erro ao atualizar status!"
          });
        }
      }).catch((error) => {
        servicePrice.status = previousStatus;
        this.$notify({
          type: "danger",
          message: "Erro ao atualizar status!"
        });
      });
    },
  },
  created() {
  },
  mounted() {
    this.init({})
    EventBus.$on("getServicePrice", () => {
      this.init();
    });
  }
};
</script>
<style scoped>
.imgBaseButton {
  margin-bottom: 2px;
}
</style>
