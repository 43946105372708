<template>
<div>
    <modal :show.sync="modal.create">
        <template slot="header">
            <h5 class="modal-title pl-3">{{ modal.title }}</h5>
        </template>
        <div>
            <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
                <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
                    <div>
                        <!-- Tempo de estabilização -->
                        <div class="form-group row m-0 p-0">
                            <div class="col-md-12 pr-0 pl-0">
                                <div class="form-group row m-0 p-0 ">
                                    <label class="col-md-6 pb-0 col-form-label form-control-label mb-1">
                                        Tempo de estabilização
                                    </label>
                                    <div class="col-md-6 pr-4 mb-1">
                                        <validation-provider rules="required">
                                            <base-input input-group-classes="input-group-sm">
                                                <input inputmode="numeric" v-model="dosage.stabilization_time" class="form-control form-control-sm" v-mask="['####']" />
                                                <template slot="append">
                                                    <small class="input-group-sm p-0 m-0">
                                                        horas
                                                    </small>
                                                </template>
                                            </base-input>
                                        </validation-provider>

                                    </div>
                                </div>
                                <!-- Teor de dosagem -->
                                <div class="row m-0 p-0">
                                    <label class="col-md-6 pb-0 col-form-label form-control-label mb-1">
                                        Teor de dosagem
                                    </label>
                                    <div class="col-md-6 pb-3 pr-4">
                                        <validation-provider rules="required">
                                            <base-input input-group-classes="input-group-sm">
                                                <input inputmode="numeric" v-model="dosage.dosage_content" class="form-control form-control-sm" v-mask="['#.##','##.##','###.##']" />
                                                <template slot="append">
                                                    <small class="input-group-sm p-0 m-0">
                                                        %(S.P.A.)
                                                    </small>
                                                </template>
                                            </base-input>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <base-button type="secondary" @click="closeModal('create')">
                                Cancelar
                            </base-button>
                            <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                                Salvar
                            </base-button>
                        </div>
                    </div>
                </form>
            </validation-observer>
        </div>

    </modal>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'
import {
    formatErrorValidation
} from '@/plugins'

export default {
    name: "ModalCreateDosage",
    data() {
        return {
            modal: {
                title: 'Dosagem',
                create: false,
            },
            dosage: {
                uuid:null,
                stabilization_time: null,
                dosage_content: null,
            },
            loadingStore: false,
        }
    },
    methods: {
        closeModal() {
            this.modal.create = false
        },
        handleCreateModal(uuid) {
            this.$notify({
                type: 'info',
                message: 'Estamos trabalhando em sua solicitação.'
            })
            this.$Progress.start()
            this.$store.dispatch('adjustmentStabilizingAdditiveDosage/show', uuid)
                .then((response) => {
                    this.dosage = {
                        uuid:response.data.uuid || '',
                        stabilization_time: response.data.stabilization_time || '',
                        dosage_content: response.data.dosage_content || '',
                    }
                    this.$Progress.finish()
                    this.$notify({
                        type: response.error_type,
                        message: response.message
                    })
                    this.modal.create = true
                }).catch((error) => {
                    if (error.response.status === 422) {
                        let errors = formatErrorValidation(error.response.data.errors)
                        this.$notify({
                            type: 'danger',
                            message: errors
                        })
                    }
                    this.$Progress.finish()
                    this.loadingStore = false
                })
        },
        store() {
            this.$Progress.start()
            this.loadingStore = true
            this.$store.dispatch('adjustmentStabilizingAdditiveDosage/update', this.dosage)
                .then((response) => {
                    this.closeModal('create');
                    this.$emit("editedDosage");
                    this.loadingStore = false
                    this.$Progress.finish();
                    this.$notify({
                        type: response.error_type,
                        message: response.message
                    })
                })
                .catch((error) => {
                    if (error.status === 200) {
                        this.$notify({
                            type: 'danger',
                            message: error.data.message
                        })
                        this.$Progress.finish();
                        this.loadingStore = false
                    } else if (error.response.status === 422) {
                        let errors = formatErrorValidation(error.response.data.errors)
                        this.$notify({
                            type: 'danger',
                            message: errors
                        })
                        this.$Progress.finish();
                        this.loadingStore = false
                    }
                })
        }
    }
}
</script>

<style scoped>

</style>
