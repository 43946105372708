<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <div class="border-left border-3 border-primary p-1">
          <h4>{{ cycle.plant }}</h4>
        </div>

        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(save)"
            autocomplete="off"
          >
            <div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-7 pb-0 pt-1 col-form-label form-control-label"
                >
                  Volume médio por BT
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-5">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        disabled
                        v-mask="['#,#', '##,#', '#']"
                        v-model.number="cycle.average_volume_per_bt"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          <span style="text-transform: none">m</span><sup>3</sup>
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-7 pb-0 pt-1 col-form-label form-control-label"
                >
                  Distância
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-5">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        disabled
                        v-model="cycle.distance"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          km
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-7 pb-0 pt-1 col-form-label form-control-label"
                >
                  Tempo (ida)
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-5">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        disabled
                        v-model.number="cycle.send_duration"
                        v-mask="['#############']"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          minutos
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-7 pb-0 pt-1 col-form-label form-control-label"
                >
                  Acréscimo trajeto (ida)
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-5">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        disabled
                        v-model.number="cycle.send_addition"
                        v-mask="['#############']"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          minutos
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-7 pb-0 pt-1 col-form-label form-control-label"
                >
                  Tempo de carregamento
                  <span class="text-danger"></span>
                </label>
                <div class="col-md-5">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      disabled
                      v-model.number="cycle.load_truck_time"
                      v-mask="['#############']"
                      class="form-control form-control-sm"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0">
                        minutos
                      </small>
                    </template>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-7 pb-0 pt-1 col-form-label form-control-label"
                >
                  Tempo de mistura/dosagem
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-5">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model.number="cycle.time_mixing"
                        v-mask="['#############']"
                        class="form-control form-control-sm"
                        disabled
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          minutos
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-7 pb-0 pt-1 col-form-label form-control-label"
                >
                  Permanência em obra
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-5">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        disabled
                        v-model.number="cycle.time_construction_stay"
                        v-mask="['#############']"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          minutos
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-7 pb-0 pt-1 col-form-label form-control-label"
                >
                  Intervalo entre descargas
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-5">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        disabled
                        v-model.number="cycle.interval_releases"
                        v-mask="['#############']"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          minutos
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-7 pb-0 pt-1 col-form-label form-control-label"
                >
                  Trajeto (volta)
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-5">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        disabled
                        v-model.number="cycle.return_duration"
                        v-mask="['#############']"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          minutos
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-7 pb-0 pt-1 col-form-label form-control-label"
                >
                  Acréscimo trajeto (volta)
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-5">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model.number="cycle.return_addition"
                        type="number"
                        class="form-control form-control-sm"
                        disabled
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          minutos
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-7 pb-0 pt-1 col-form-label form-control-label"
                >
                  Tempo de limpeza/Lastro
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-5">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model.number="cycle.time_clear"
                        type="number"
                        class="form-control form-control-sm"
                        disabled
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          minutos
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1 mt-4">
                <label
                  class="col-md-7 pb-0 pt-1 col-form-label form-control-label"
                >
                  Ciclo total:
                </label>
                <div class="col-md-5">{{ cycle.total_cycle }} minutos</div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-7 pb-0 pt-1 col-form-label form-control-label text-warning"
                >
                  Adicional de ciclo:
                </label>
                <div class="col-md-5 text-warning">
                  {{ cycle.additional_cycle }} minutos
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1 mt-4">
                <div class="col-md-12">
                  <!--                  <span class="text-body small">Usuário - data - hora</span>-->
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()"
              >Fechar</base-button
              >
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  components: {},
  name: "ModalDetailCycle",
  data() {
    return {
      modal: false,
      title: "Ciclo de viagem",
      validated: false,
      loading: false,
      once: true,
      cycle: {
        average_volume_per_bt: null,
        cycle_additional: null,
        distance: null,
        send_duration: null,
        send_addition: null,
        return_addition: null,
        return_duration: null,
        time_clear: null,
        time_mixing: null,
        time_construction_stay: null,
        interval_releases: null,
        load_truck_time: null,
        plant: {
          id: "",
          name: null
        },
        total_cycle: 0,
        additional_cycle: 0
      }
    };
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    openModal(cycle) {
      this.cycle = cycle;
      this.cycle.time_mixing = this.cycle.time_mixing * this.cycle.average_volume_per_bt
      this.modal = true;
    },
  },
  created() {},
};
</script>

<style></style>
