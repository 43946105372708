<template>
  <div>
    <div class="p-2 bg-header-gray">
      <SkeletonPuzl type="button"></SkeletonPuzl>
    </div>
    <div class="form-group row m-0 p-0">
      <label class="col-md-6 col-form-label form-control-label">
        <SkeletonPuzl type="button"></SkeletonPuzl>
      </label>
      <div class="col-md-6 pt-2">
        <SkeletonPuzl type="button"></SkeletonPuzl>
      </div>
    </div>

    <div class="form-group row m-0 p-0 mb-1">
      <label class="col-md-5 pt-1 pb-0 col-form-label form-control-label">
        <SkeletonPuzl type="button"></SkeletonPuzl>
      </label>
      <div class="col-md-7">
        <SkeletonPuzl type="button"></SkeletonPuzl>
      </div>
    </div>
    <div class="form-group row m-0 p-0 mb-1">
      <label class="col-md-3 pt-1 pb-0 col-form-label form-control-label">
        <SkeletonPuzl type="button"></SkeletonPuzl>
      </label>
      <div class="col-md-9">
        <SkeletonPuzl type="button"></SkeletonPuzl>
      </div>
    </div>


    <div class="p-2 bg-header-gray">
      <SkeletonPuzl type="button"></SkeletonPuzl>
    </div>
    <div class="form-group row m-0 p-0 mb-1">
      <label class="col-md-2 pt-1 pb-0 col-form-label form-control-label">
        <SkeletonPuzl type="button"></SkeletonPuzl>
      </label>
      <div class="col-md-10">
        <SkeletonPuzl type="button"></SkeletonPuzl>
      </div>
    </div>

    <div class="form-group row m-0 p-0 mb-1">
      <label class="col-md-3 pt-1 pb-0 col-form-label form-control-label">
        <SkeletonPuzl type="button"></SkeletonPuzl>
      </label>
      <div class="col-md-9">
        <SkeletonPuzl type="button"></SkeletonPuzl>
      </div>
    </div>

    <div class="form-group row m-0 p-0 mb-1">
      <label class="col-md-5 pt-1 pb-0 col-form-label form-control-label">
        <SkeletonPuzl type="button"></SkeletonPuzl>
      </label>
      <div class="col-md-7">
        <SkeletonPuzl type="button"></SkeletonPuzl>
      </div>
    </div>
  </div>
</template>
<script>
import SkeletonPuzl from './SkeletonPuzl'

export default {
  name: 'SkeletonPuzlModalMd',
  components: {
    SkeletonPuzl,
  },
  data(){
    return {
    }
  },
  updated () {
  },
}
</script>
<style>


</style>
