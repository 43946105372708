<template>
  <div style="min-height: 1000px;background-color: #ffff;">
    <div class="mb-4" style="margin: 0 2rem;">
      <div class="row align-items-center justify-content-between align-items-center pb-2" style="padding-top: 1.3rem;">
        <div class="d-flex align-items-center">
          <img height="30px" src="/img/icons/cart-warning.png" class="mr-2" />
          <h2 style="font-size: 1.2rem !important;color: #F2B532;" class="nowrap mb-0 mt-1">
            Requisição de compra
          </h2>
        </div>
        <div class="d-flex align-content-center">
          <div class="mr-3 d-none d-sm-block" @click="handleShowModal({ ref: 'modalCreateProductService' })">
            <base-button type="success" block style="border-radius: 4px !important; width: 13rem;">
              <img src="/img/icons/plus-math--v1-white.png" width="19">
              Cadastro de itens
            </base-button>
          </div>
          <div class="mr-3 d-none d-sm-block" @click="handleShowModal({ ref: 'modalCreatePurchaseCategory' })">
            <base-button type="warning" block style="border-radius: 4px !important">
              <img src="/img/icons/product-documents.png" width="19">
              Cadastro de categorias
            </base-button>
          </div>
          <div class="back-button mb-2 mr-3" style="width: 14rem;">
            <BackButton />
          </div>
        </div>
      </div>
      <!-- Botao Switch -->
      <div class="dropdown-divider p-0 m-0"></div>
      <div class="d-flex justify-content-center justify-content-md-end r mt-3">
        <div class="switch-button d-flex">
          <div @click="changeStep('document')" class="w-100 d-flex justify-content-center"
            :class="requestStep === 'document' ? 'selected-switch-button' : 'disabled-switch-button'">
            <img v-if="requestStep === 'document'" width="20" src="/img/icons/icons8/ios/info-squared_white.png"
              class="mr-2" />
            <img v-if="requestStep === 'items'" width="20" src="/img/icons/icons8/ios/info-squared_warning.png"
              class="mr-2" />
            Documento
          </div>
          <div @click="changeStep('items')" class="w-100 d-flex justify-content-center"
            :class="requestStep === 'items' ? 'selected-switch-button' : 'disabled-switch-button'">
            <img v-if="requestStep === 'document'" width="22" src="/img/icons/big-parcel-primary.png" class="mr-2" />
            <img v-if="requestStep === 'items'" width="22" src="/img/icons/big-parcel-white.png" class="mr-2" />
            Itens
          </div>
        </div>
      </div>

      <!-- Etapas de documentacao e itens -->
      <DocumentRequest v-show="requestStep === 'document'" @purchaseRequestDocument="purchaseRequestDocument"
        :requestDocument="this.requestDocument" />
      <ItemsRequest v-show="requestStep === 'items'" @purchaseRequestItem="purchaseRequestItem"
        :productServiceTypeId="this.requestDocument.product_service_hub_type_id" :requestItem="items" />

      <ModalPurchaseCategory @fetchCategories="handleFetchCategories" ref="modalCreatePurchaseCategory" />

      <div class="w-100 d-flex justify-content-end my-4">
        <base-button type="success" native-type="submit" @click.prevent="save()" title="Salvar">
          <img src="/img/icons/save.png" width="22px" height="22px" class="mr-2" />
          Salvar
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from '@/components/Utils/BackButton';
import DocumentRequest from "./Shared/_Document";
import ItemsRequest from "./Shared/_Items";
import ModalPurchaseCategory from './_ModalPurchaseCategory.vue'
import {mapGetters} from "vuex";

export default {
  name: "PurchasesRequestCreate",
  components: {
    BackButton,
    ItemsRequest,
    DocumentRequest,
    ModalPurchaseCategory,
  },
  computed: {
    ...mapGetters({
        $_user: 'auth/getUser',
    }),
  },
  data() {
    return {
      filter: {
        id: this.$route.params.id,
        order_by: [
          {
            column: 'purchase_requests.id',
          }
        ],
      },
      loadingSkeleton: false,
      requestStep: 'document',
      requestDocument: {},
      requestItem: {},
      items: [],
      can_edit_category: false,
    }
  },
  methods: {
    init() {
      this.$Progress.start();
      this.loadingSkeleton = true;
      this.setDocument();
      this.setItems();
    },
    setDocument() {
      let loader = this.$loading.show();
      this.$store.dispatch('purchaseRequest/show', this.$route.params.id)
        .then((response) => {
          this.requestDocument = response.data;
        }).finally(() => {
          this.loadingSkeleton = false;
          loader.hide()
        });
    },
    setItems() {
      let filter = {
        order_by: [{ column: 'purchase_request_items.id' }],
        purchase_request_id: this.$route.params.id
      }
      this.$store.dispatch('purchaseRequest/fetchRequestItems', filter)
        .then((response) => {
          this.items = response.data.items;
        });
    },
    setViewType(productServiceTypeId) {
      this.listType = productServiceTypeId;
    },
    purchaseRequestDocument(updatedValue) {
      this.requestDocument = updatedValue;
    },
    purchaseRequestItem(updatedValue) {
      this.requestItem = updatedValue;
    },
    changeStep(step) {
      if (this.requestDocument?.product_service_hub_type_id) {
        this.requestStep = step;
      } else {
        this.$notify({ type: 'warning', message: 'Selecione um tipo de item antes de prosseguir!' });
      }
    },
    save() {
      if (this.requiredFieldsIsFilled()) {
        let loader = this.$loading.show()
        this.$store.dispatch('purchaseRequest/update', { ...this.requestDocument, ...this.requestItem })
          .then(() => {
            loader.hide();
            this.$notify({ type: 'success', message: 'Requisição salva!' });
            this.$router.push('/purchase/request');
          });
      }
    },
    requiredFieldsIsFilled() {
      const requiredFields = ['deadline', 'requester_id', 'cost_center_id', 'input_company_plant_id', 'company_plant_buyer_id', 'product_service_hub_type_id'];
      for (const field of requiredFields) {
        if (!this.requestDocument.hasOwnProperty(field) || !this.requestDocument[field]) {
          this.$notify({ type: 'warning', message: 'Preencha todos os campos obrigatórios!' });
          this.changeStep('document');
          return false;
        }
      }
      if (!this.requestItem.hasOwnProperty('items') || !Array.isArray(this.requestItem.items) || this.requestItem.items.length === 0) {
        this.$notify({ type: 'warning', message: 'Adicione pelo menos um item!' });
        this.changeStep('items');
        return false;
      }
      return true;
    },
    handleShowModal({ ref, data }) {
      if (ref === 'modalCreatePurchaseCategory' && !this.can_edit_category) {
        this.$notify({type: 'warning', message: 'Usuário não tem permissão para criar categorias!'});
        return
      }
      this.$refs[ref].handleCreateModal(data);
    },
    handleFetchCategories() {
      this.$refs.documentRequest.listCategories();
    },
    getPurchasePermission() {
      this.$store.dispatch('user/getPurchasePermission', this.$_user.id)
        .then((response) => {
          this.can_edit_category = response.data.purchase_category_edit
        }).finally(() => {
      });
    },
  },
  mounted() {
    this.init();
    this.getPurchasePermission();
  }
};
</script>
<style scoped>
@media (max-width: 768px) {
  .back-button {
    width: 6rem !important;
  }
}

/* Botao switch */
.switch-button {
  height: 2.5rem;
  border: 0.5px solid #DCDFE6;
  box-shadow: 0px 4px 2px 0px #00000040;
  border-radius: 20px;
  align-items: center;
  width: 24rem;
  cursor: pointer;
  font-weight: 600;
  font-size: 8px !important;
}

.switch-button>div {
  font-size: 12px;
  height: 100%;
  align-items: center;
}

.switch-button>div:first-of-type {
  align-items: center;
  border-radius: 20px 0 0 20px;
}

.switch-button>div:nth-child(2) {
  border-radius: 0 20px 20px 0;
}

.selected-switch-button {
  background-color: #1a70b7;
  color: #ffffff;
}

.green-button {
  border-radius: 10px;
  border: 1px solid #149E57;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  color: #149E57;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
