import * as types from "./mutation_types";
import { createAxios } from "@/plugins/axios";;
const endPoint = "/operational/schedule/schedule-gantt-setting+microservice";
export default {
  namespaced: true,
  state: {
    item: {},
    items:[],
  },
  getters: {
    show: state => state.item,
  },
  mutations: {
    [types.SHOW](state, payload) {
      state.item = payload;
    },
    [types.PUSH](state, payload) {
      state.items.push(payload);
    },
  },
  actions: {
    add({ commit }, payload) {
      return createAxios()
        .post(endPoint, payload)
        .then(({ data }) => {
          commit(types.PUSH, data.data);
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    show({ commit }) {
      return createAxios()
        .get(endPoint)
        .then(({ data }) => {
          commit(types.SHOW, data.data);
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
  }
};
