<template>
  <div>
    <modal size="lg" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title mb-4">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(storeCreate)"
            autocomplete="off"
          >
            <div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-3 mb-0"
                >
                  <div class="pt-1">
                    Status
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-6 pr-4 mb-0">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <base-switch
                        v-model="addService.status"
                        type="success"
                        offText="inativo"
                        onText="ativo"
                        class="success"
                      >
                      </base-switch>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                >
                  <div class="pt-1">
                    Serviço
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-6 pr-4 mb-2">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model="addService.service_name"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                >
                  <div class="pt-1">
                    Categoria de equipamento
                  </div>
                </label>
                <div class="col-md-6 pr-4 mb-2">
                  <validation-provider>
                    <base-input input-group-classes="input-group-sm">
                      <puzl-select 
                        v-model="addService.equipment_category_uuid" 
                        :items="categories"  
                        label="category_name" 
                        customKey="uuid" 
                        :loading="loadingCategories" 
                        :disabled="loadingCategories" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-3 mb-0"
                >
                  <div class="pt-1">
                    Contabilizar a partir da conclusão de viagens
                  </div>
                </label>
                <div class="col-md-6 pr-4 mb-0">
                    <base-input input-group-classes="input-group-sm">
                      <base-switch
                        v-model="addService.count_from_the_completed_schedule_travels"
                        type="success"
                        offText="não"
                        onText="sim"
                        class="success"
                      >
                      </base-switch>
                    </base-input>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')"
                >Cancelar</base-button
              >
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                :loading="loading"
                >Salvar</base-button
              >
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>

    <modal size="lg" :show.sync="modal.update">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(update)"
            autocomplete="off"
          >
            <div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-3 mb-0"
                >
                  <div class="pt-1">
                    Status
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-6 pr-4 mb-0">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <base-switch
                        v-model="editService.status"
                        type="success"
                        offText="inativo"
                        onText="ativo"
                        class="success"
                      >
                      </base-switch>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                >
                  <div class="pt-1">
                    Serviço
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-6 pr-4 mb-2">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model="editService.service_name"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                >
                  <div class="pt-1">
                    Categoria de equipamento
                  </div>
                </label>
                <div class="col-md-6 pr-4 mb-2">
                  <validation-provider>
                    <base-input input-group-classes="input-group-sm">
                      <puzl-select 
                        v-model="editService.equipment_category_uuid" 
                        :items="categories"  
                        label="category_name" 
                        customKey="uuid" 
                        :loading="loadingCategories" 
                        :disabled="loadingCategories" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-3 mb-0"
                >
                  <div class="pt-1">
                    Contabilizar a partir da conclusão de viagens
                  </div>
                </label>
                <div class="col-md-6 pr-4 mb-0">
                    <base-input input-group-classes="input-group-sm">
                      <base-switch
                        v-model="editService.count_from_the_completed_schedule_travels"
                        type="success"
                        offText="não"
                        onText="sim"
                        class="success"
                      >
                      </base-switch>
                    </base-input>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('update')"
                >Cancelar</base-button
              >
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid || loadingCategories"
                :loading="loading"
                >Salvar</base-button
              >
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {  formatErrorValidation } from "@/plugins";
import PuzlSelect from "@/components/PuzlSelect";
export default {
  components: { PuzlSelect },
  name: "AddService",
  data() {
    return {
      modal: {
        title: "",
        create: false,
        update: false
      },
      loadingCategories: true,
      validated: false,
      addService: {
        status: true,
        service_name: "",
        equipment_category_uuid: null,
        count_from_the_completed_schedule_travels: false,
      },
      editService: {
        id: "",
        status: "",
        service_name: "",
        equipment_category_uuid: null,
        count_from_the_completed_schedule_travels: false,
      },
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      categories: "equipmentCategory/fetch"
    })
  },
  methods: {
    closeModal(name) {
      this.modal[name] = false;
    },
    openModal(name) {
      this.loadingCategories = true;
      this.modal[name] = true;
      this.getCategories();
    },
    handleCreate() {
      this.addService = {
        status: true,
        service_name: "",
        equipment_category_uuid: null
      };
      this.modal.title = "Adicionar Serviço";

      this.openModal("create");
    },
    handleUpdate(itemUpdate) {
      this.editService = {
        id: itemUpdate.id,
        status: itemUpdate.status,
        service_name: itemUpdate.service_name,
        equipment_category_uuid: itemUpdate.equipment_category_uuid,
        count_from_the_completed_schedule_travels: itemUpdate.count_from_the_completed_schedule_travels,
      };
      this.modal.title = "Editar Serviço";
      this.openModal("update");
    },
    getCategories(type) {
      this.$Progress.start();
      this.$store
        .dispatch("equipmentCategory/fetchItems")
        .then(response => {
          this.$Progress.finish();
          this.loadingCategories = false;
        })
        .catch(error => {
          this.$Progress.finish();
          this.loadingCategories = false;
        });
    },
    async storeCreate() {
      this.$Progress.start();
      this.loading = true;
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.$axios
        .post("configuration/service", this.addService)
        .then(({ data }) => {
          this.$notify({ type: data.error_type, message: data.message });
          this.loading = false;
          this.addService = {
            status: true,
            service_name: "",
            equipment_category_uuid: null,
            count_from_the_completed_schedule_travels: false,
          };
          EventBus.$emit("getService");
          this.closeModal("create");
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(error => {
          if (error.status) {
            this.$notify({
              type: "danger",
              message: error.data.message
            });
            this.$Progress.finish();
            this.loading = false;
          } else if (
            error.response &&
            error.response.status &&
            error.response.status === 422
          ) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors
            });
            this.$Progress.finish();
            this.loading = false;
          }
        });
    },
    async update() {
      this.$Progress.start();
      this.loading = true;
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.$axios
        .put("configuration/service/" + this.editService.id, this.editService)
        .then(({ data }) => {
          this.$notify({ type: data.error_type, message: data.message });
          this.loading = false;
          this.editService = {
            id: "",
            status: "",
            service_name: "",
            equipment_category_uuid: null
          };
          EventBus.$emit("getService");
          this.closeModal("update");
          this.loading = false;
          this.$Progress.finish();
        })
       .catch(error => {
          if (error.status) {
            this.$notify({
              type: "danger",
              message: error.data.message
            });
            this.$Progress.finish();
            this.loading = false;
          } else if (
            error.response &&
            error.response.status &&
            error.response.status === 422
          ) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors
            });
            this.$Progress.finish();
            this.loading = false;
          }
        });
    },
    deleteItem(id) {
      this.$Progress.start();
      this.$swal
        .fire({
          title: "Você tem certeza?",
          text: "Você não poderá recuperar este registro!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, apague isto!",
          cancelButtonText: "Não, mantenha",
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill"
          },
          buttonsStyling: false
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação."
            });
            this.$axios
              .delete("/configuration/service/" + id)
              .then(({ data }) => {
                this.$notify({ type: data.error_type, message: data.message });
                EventBus.$emit("getService");
                this.$Progress.finish();
              })
              .catch(error => {
                this.$notify({ type: error.data.error_type, message: error.data.message });
                if (error) {
                  Exception.handle(error);
                }
              });
          }
          this.$Progress.finish();
        });
    }
  },
  created() {},
  mounted() {
    this.$refs.formValidator.validate();
    EventBus.$on("handleCreateService", () => {
      this.handleCreate();
    });
    EventBus.$on("handleUpdateService", itemUpdate => {
      this.handleUpdate(itemUpdate);
    });
    EventBus.$on("handleDeleteService", id => {
      this.deleteItem(id);
    });
  }
};
</script>
<style></style>
