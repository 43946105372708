<template>
<div>
    <base-header class="bg-gray-content">
        <div class="row align-items-center py-4">
            <div class="col-lg-12 col-12">
                <h6 class="h2 text-gray d-inline-block mb-0">{{ this.$route.meta.breadcrumbs[1].title }}</h6>
                <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4 text-gray">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="#"><i :class="this.$route.meta.icon"></i></a></li>
                        <li class="breadcrumb-item"><a href="#">{{ this.$route.meta.breadcrumbs[0].title }}</a></li>
                        <li class="breadcrumb-item" aria-current="page">{{ this.$route.meta.breadcrumbs[1].title }}</li>
                    </ol>
                </nav>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-2 mb-2">
                <base-dropdown>
                    <base-button slot="title-container" block type="dark" class="dropdown-toggle p-3 text-uppercase">
                      <i class="fas fa-external-link-alt"></i> Cadastros
                    </base-button>
                    <div>
                        <router-link :to="{ path:'/financial/cash-flow/bank-account'}">
                            <a class="dropdown-item">Contas</a>
                            <div class="dropdown-divider p-0 m-0"></div>
                        </router-link>
                    </div>
                </base-dropdown>
            </div>
        </div>
    </base-header>
</div>
</template>

<script>
export default {
    name: "Index",
}
</script>

<style scoped>

</style>
