<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col">
          <h2 style="font-size: 1.2rem !important" class="text-danger mb-0 d-flex align-items-center mb-2 mb-md-0">
            <img height="30px" src="/img/icons/login-rounded.png" class="mr-3" />
            Lançamento de despesa
          </h2>
        </div>
        <div class="col-md-2 mb-md-0 mb-2">
          <base-button
            @click.prevent="handleCreateProductService"
            block
            type="success"
            :disabled="
              !['Serviço', 'Produtos e Serviços', 'Taxas e Tributos'].includes(entry_launch_types[entry_launch.product_service_hub_type_id])
            "
            class="text-uppercase"
          >
            <i class="fas fa-plus"></i> Itens
          </base-button>
        </div>
        <div v-show="entry_launch.payment_method_id && [1, 4].includes(entry_launch.payment_method_id)" class="col-md-2 mb-md-0 mb-2">
          <base-button @click.prevent="handleEntityAccount" block type="success" class="text-uppercase"
            ><i class="fas fa-plus" /> CONTA
          </base-button>
        </div>
        <div class="col-md-2 mb-0">
          <back-button />
        </div>
      </div>
      <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black mt-0 mb-4 mx-1" />
    </base-header>
    <div class="container-fluid">
      <div>
        <card>
          <!-- Navegação -->
          <Navegation
            v-if="!loadingEntity"
            :entity_name="entity.entity_name ? entity.entity_name : entry_launch.entity.entity_name"
            :entity_document="entity.document ? entity.document : entry_launch.entity.document"
            :document_number="entry_launch.document_number"
            :series="entry_launch.series"
            :tab_value="tab_value"
            @input="tab_value = $event"
            :disabledPayment="bill_pay_installments.length < 1 || entry_launch.total_value <= 0 || dontHasFinancial"
          />
          <!-- Aba documento -->
          <div class="mt-4" v-show="tab_value === 'document'">
            <!-- Identificação -->
            <div class="row">
              <div class="col-md-6">
                <div class="card py-4 px-3">
                  <h3 style="line-height: normal !important" class="new-default-black-font mb-0 d-flex align-items-center">
                    <img height="30px" src="/img/icons/icons8/ios/info-squared_warning.png" class="mr-3" />
                    IDENTIFICAÇÃO
                  </h3>
                  <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black mt-3 mb-4" />

                  <!-- Central de entrada -->
                  <div class="row">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      Central de Entrada
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <PuzlSelect
                        @input="
                          getStockLocations();
                          getEquipments();
                          fillCompanyPlant($event);
                        "
                        v-model="entry_launch.entry_company_plant_id"
                        :items="company_plants"
                        :clearable="false"
                      />
                    </div>
                  </div>

                  <!-- Central de custo -->
                  <div class="row mt-2">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      Central de custo
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <PuzlSelect
                        v-model="entry_launch.company_plant_id"
                        :items="company_plants"
                        :clearable="false"
                        @change="getCompanyPlantBuyers"
                      />
                    </div>
                  </div>

                  <!-- CNPJ Comprador -->
                  <div class="row mt-2">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      CNPJ Comprador
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <PuzlSelect
                        v-model="entry_launch.company_plant_buyer_id"
                        :items="company_plant_buyers"
                        :clearable="false"
                        :disabled="loadingCompanyPlantBuyer"
                        :labelMask="true"
                        :label="['issuer', 'ein']"
                      />
                    </div>
                  </div>

                  <!-- Documento -->
                  <div class="row mt-2">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      Documento
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <PuzlSelect
                        v-model="entry_launch.document_template_id"
                        :items="formattedDocumentTemplates"
                        label="label"
                        :loading="loadingDocumentTemplate"
                        :disabled="loadingDocumentTemplate"
                      />
                    </div>
                  </div>

                  <!-- Tipo de itens -->
                  <div class="row mt-2">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      Tipo de itens
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <PuzlSelect
                        v-model="entry_launch.product_service_hub_type_id"
                        :items="[{ id: 2, name: 'MCC' }, ...product_service_hub_types]"
                        @change="checkProductServiceHubType"
                        :loading="loadingProductServiceHubType"
                        :disabled="loadingProductServiceHubType"
                        :clearable="false"
                      />
                    </div>
                  </div>
                </div>

                <!-- Operação -->
                <div class="card py-4 px-3">
                  <div class="row">
                    <div class="col-6">
                      <h3 style="line-height: normal !important" class="new-default-black-font mb-0 d-flex align-items-center">
                        <img height="30px" src="/img/icons/icons8/ios/upload-link-document_primary.png" class="mr-3" />
                        Operação
                      </h3>
                    </div>
                    <div v-if="operation_source" class="col-6 d-flex justify-content-end">
                      <base-button
                        v-if="operation_source.is_entry_value"
                        class="rounded-pill text-uppercase d-flex align-items-center"
                        type="success"
                        outline
                        size="sm"
                        disabled
                      >
                        <img height="18px" src="/img/icons/icons8/ios/working-mom_success.png" class="mr-2" />
                        <span class="fs-12">Nota mãe </span>
                      </base-button>
                      <base-button
                        v-else-if="!operation_source.is_entry_value && !operation_source.is_generate_financial"
                        class="rounded-pill text-uppercase d-flex align-items-center"
                        type="success"
                        outline
                        size="sm"
                        disabled
                      >
                        <img height="18px" src="/img/icons/icons8/ios/women-age-group-2_success.png" class="mr-2" />
                        <span class="fs-12">Nota filha </span>
                      </base-button>
                      <base-button
                        class="rounded-pill text-uppercase d-flex align-items-center"
                        type="danger"
                        v-if="operation_source.is_generate_financial"
                        outline
                        size="sm"
                        disabled
                      >
                        <img height="18px" src="/img/icons/icons8/ios/duration-finance--v1_danger.png" class="mr-2" />
                        Financeiro
                      </base-button>
                      <base-button
                        v-if="operation_source.is_generate_stock"
                        class="rounded-pill text-uppercase d-flex align-items-center"
                        type="primary"
                        outline
                        size="sm"
                        disabled
                      >
                        <img height="18px" src="/img/icons/open-box.png" class="mr-2" />
                        Estoque
                      </base-button>
                    </div>
                  </div>
                  <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black mt-3 mb-4" />
                  <!-- Natureza de operação -->
                  <div class="row">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center"> Natureza de operação </label>
                    <div class="col-md-6">
                      <input maxlength="30" v-model="entry_launch.nature_operation" class="form-control form-control-sm" />
                    </div>
                  </div>

                  <!-- Tipo de operação puzl -->
                  <div class="row mt-2">
                    <label class="col-md-6 new-default-black-font d-flex align-items-center mb-2 mb-md-0">
                      Tipo de operação puzl
                      <span class="text-danger">&nbsp;*</span>
                      <base-button
                        class="text-uppercase ml-2"
                        size="sm"
                        type="success"
                        @click.prevent="handleModalOperationSourceCreate"
                        :disabled="loadingOperationSource || !entry_launch.product_service_hub_type_id"
                      >
                        <i class="fas fa-plus"></i> Novo
                      </base-button>
                    </label>
                    <div class="col-md-6">
                      <PuzlSelect
                        @input="checkFinancial"
                        v-model="entry_launch.operation_source_id"
                        :items="$_operation_sources"
                        :loading="loadingOperationSource"
                        :disabled="loadingOperationSource || !entry_launch.product_service_hub_type_id"
                        :clearable="false"
                      />
                    </div>
                  </div>

                  <!-- Nota filha -->
                  <div
                    v-if="operation_source && !operation_source.is_entry_value && !operation_source.is_generate_financial"
                    class="row mt-2"
                  >
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center"> Nota mãe </label>
                    <div class="col-md-6">
                      <PuzlSelect label="document_number" v-model="entry_launch.parent_id" :items="$_parents" :disabled="loadingParents" />
                    </div>
                  </div>
                </div>
              </div>

              <!-- Dados -->
              <div class="col-md-6">
                <div class="card py-4 px-3">
                  <h3 style="line-height: normal !important" class="new-default-black-font mb-0 d-flex align-items-center">
                    <img height="30px" src="/img/icons/icons8/ios/data-arrived_primary.png" class="mr-3" />
                    DADOS
                  </h3>
                  <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black mt-3 mb-4" />

                  <!-- Contabilidade -->
                  <div class="row">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      Contabilidade
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6 d-flex align-items-center justify-content-md-end">
                      <base-switch v-model="entry_launch.accounting" type="success" class="success ml-2" offText="Não" onText="Sim" />
                    </div>
                  </div>

                  <!-- Data de entrada -->
                  <div class="row mt-2">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      Data de entrada
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6 d-flex align-items-center justify-content-md-end">
                      <InputDateTimePicker
                        :disableDate="min_entry_date"
                        :disableMaxDate="max_date"
                        @input="changeBillPayInstallmentDates"
                        :mode="'dateTime'"
                        :size="'lg'"
                        v-model="entry_launch.entry_date"
                      />
                    </div>
                  </div>

                  <!-- Data de emissão -->
                  <div class="row mt-2">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      Data de emissão
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6 d-flex align-items-center justify-content-md-end">
                      <InputDateTimePicker
                        :disableDate="min_date"
                        :disableMaxDate="max_date"
                        :mode="'date'"
                        v-model="entry_launch.issue_date"
                      />
                    </div>
                  </div>

                  <!-- Nº do documento -->
                  <div class="row mt-2">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      Nº do documento
                      <span class="text-danger">&nbsp;*</span>
                      <i @click.prevent="getDocumentNumber" role="button" class="fas fa-download ml-2 fs-16 text-primary" />
                    </label>
                    <div class="col-md-6">
                      <InputLimit
                        :start_value="entry_launch.document_number"
                        :options="{
                          min: 0,
                        }"
                        max_length="15"
                        input_group_classes="input-group-sm pb-0"
                        v-model="entry_launch.document_number"
                        @value="entry_launch.document_number = $event"
                      />
                    </div>
                  </div>

                  <!-- Série -->
                  <div class="row mt-2">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      Série
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <input maxlength="30" v-model="entry_launch.series" class="form-control form-control-sm" />
                    </div>
                  </div>

                  <!-- Observações -->
                  <div class="row mt-2">
                    <label class="col-12 new-default-black-font mb-2 d-flex align-items-center"> Observações </label>
                    <div class="col-12">
                      <textarea v-model="entry_launch.observations" type="text" rows="3" class="form-control form-control-sm" />
                    </div>
                  </div>

                  <!-- Foto do documento -->
                  <div class="row mt-3">
                    <label class="col-12 new-default-black-font mb-2 d-flex align-items-center">
                      Foto do documento
                      <span class="text-danger"> {{ entry_launch.accounting ? "&nbsp;*" : "" }}</span>
                    </label>
                    <div class="col-12">
                      <InputFile :multiple="false" ref="inputEntryLaunchFile" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Aba itens -->
          <div class="mt-4" v-show="tab_value === 'items'">
            <div class="row">
              <!-- Incluir -->
              <div
                v-show="
                  !(default_product_service_hub_types.includes(entry_launch.product_service_hub_type_id) && items.length) &&
                  entry_launch.product_service_hub_type_id
                "
                class="col-md-2 pr-md-1"
              >
                <base-button-hoverable
                  @click="
                    default_product_service_hub_types.includes(entry_launch.product_service_hub_type_id)
                      ? checkProductServiceHubType(entry_launch.product_service_hub_type_id)
                      : handleModalCreateEntryLaunchItem()
                  "
                  size="sm"
                  type="success"
                  icon="plus--v1"
                  platform="ios"
                >
                  Incluir
                </base-button-hoverable>
              </div>

              <!-- Selecionar -->
              <div v-show="items.length" class="col-md-2 pl-md-1 pr-md-1">
                <base-button-hoverable
                  @click="setSelectedItems"
                  :active="selected_items"
                  size="sm"
                  type="primary"
                  icon="choose"
                  platform="ios"
                >
                  Selecionar
                </base-button-hoverable>
              </div>

              <!-- Selecionar todos -->
              <div v-show="selected_items" class="col-md-2 pl-md-1 pr-md-1">
                <base-button-hoverable @click="addAllToSelectedItems" size="sm" type="primary" icon="hand-drag" platform="ios">
                  Selecionar todos
                </base-button-hoverable>
              </div>

              <!-- Desmarcar todos -->
              <div v-show="selected_items" class="col-md-2 pl-md-1 pr-md-1">
                <base-button-hoverable @click="removeAllFromSelectedItems" size="sm" type="light" icon="undo" platform="ios">
                  Desmarcar todos
                </base-button-hoverable>
              </div>
            </div>

            <!-- Itens -->
            <div class="mt-4">
              <PuzlEmptyData v-show="!items.length" />
              <div class="row card-wrapper" v-if="items.length">
                <div class="col-lg-4" v-for="(item, index) in items" :key="index">
                  <card>
                    <div class="row align-items-center">
                      <div class="col-auto">
                        <span
                          class="badge font-weight-400 text-success fs-14 py-2 px-3 d-flex align-items-center justify-content-center"
                          style="background-color: #d0ecdd"
                        >
                          <img height="18px" src="/img/icons/icons8/ios/open-box_success.png" class="mr-1" />
                          Cadastrado
                        </span>
                      </div>
                      <el-popover v-if="item.code" class="col-auto" placement="right" trigger="hover">
                        <h4 class="new-default-black-font">Código do fornecedor</h4>
                        <h5 class="mb-0 new-default-black-font">
                          Código : <span class="font-weight-normal"> {{ item.code }} </span>
                        </h5>
                        <span slot="reference">
                          <img src="/img/icons/info.png" width="26px" class="float-right" />
                        </span>
                      </el-popover>
                      <!-- Impostos -->
                      <div v-if="['Produtos e Serviços', 'MCC'].includes(entry_launch_types[entry_launch.product_service_hub_type_id])" class="col-auto px-1">
                        <span @click="handleOpenModalTaxes(item, index)" role="button">
                          <img height="27px" src="/img/icons/icons8/ios/general-ledger_default.png"/>
                        </span>
                      </div>
                      <div v-if="!selected_items" class="col text-right pl-0">
                        <base-dropdown menuOnRight>
                          <div slot="title-container" class="dropdown-toggle rounded m-0">
                            <img width="32" src="/img/icons/icons8/ios/settings--v1_primary.png" />
                          </div>
                          <a @click.prevent="handleModalEditEntryLaunchItem(item, index)" class="dropdown-item">
                            <i class="fas fa-edit text-warning main-icon-menu"></i>
                            Editar
                          </a>
                          <a @click.prevent="deleteItem(index)" class="dropdown-item">
                            <i class="fas fa-times text-danger main-icon-menu"></i>
                            Excluir
                          </a>
                          <a
                            v-show="
                              entry_launch.entry_company_plant_id &&
                              [
                                'Serviço',
                                'Produtos e Serviços',
                                'Financiamento',
                                'Frete',
                                'Tributos Automáticos',
                                'Empréstimo',
                                'Taxas e Tributos',
                              ].includes(entry_launch_types[entry_launch.product_service_hub_type_id])
                            "
                            @click="handleModalEquipmentApportionment(item, index)"
                            class="dropdown-item"
                          >
                            <img height="20px" src="/img/icons/icons8/ios/split_success.png" class="mr-3 ml-n1" />
                            Rateio
                          </a>
                        </base-dropdown>
                      </div>
                      <div v-else class="col-3 text-right pl-0">
                        <base-button
                          style="padding: 0.32rem 0.5rem !important"
                          v-if="selected_items.includes(index)"
                          @click.prevent="removeFromSelectedItems(index)"
                          class="float-right"
                          type="success"
                        >
                          <i class="fas fa-check text-white" />
                        </base-button>
                        <base-button
                          style="padding: 0.32rem 0.5rem !important; background-color: white"
                          v-else
                          @click.prevent="addToSelectedItems(index)"
                          class="float-right"
                        >
                          <i class="fas fa-check text-white" />
                        </base-button>
                      </div>

                      <div class="col-12 justify-content-center pr-0 mt-2">
                        <h3 class="new-default-black-font mb-0 fs-16 font-weight-500">
                          {{ item.product_service_hub.product_name }}
                        </h3>
                        <div v-if="item.product_service_hub.product_service_hub_type_id === 2">
                          <h3 class="mb-0 new-default-black-font fs-16 font-weight-400">
                            {{ item.product_service_hub.supplier_name }}
                          </h3>
                          <h3 class="mb-1 new-default-black-font fs-16 font-weight-400">({{ item.product_service_hub.origin_name }})</h3>
                        </div>
                      </div>

                      <div class="row col-12 p-0 m-0 mt-3">
                        <div class="col-4">
                          <h5 class="mb-0 new-default-black-font font-weight-400 fs-12">Quantidade</h5>
                          <h5 class="mb-0 new-default-black-font font-weight-500 fs-20">
                            {{ item.quantity }} {{ item.product_service_hub.purchase_unit }}
                          </h5>
                        </div>
                        <div class="col-4 p-0">
                          <h5 class="mb-0 new-default-black-font font-weight-400 fs-12">Valor unitário</h5>
                          <h5 class="mb-0 new-default-black-font font-weight-500 fs-20">
                            {{ toMoneyWithThreeDecimals(item.price) }} / {{ item.product_service_hub.purchase_unit }}
                          </h5>
                        </div>
                        <div class="col-4 text-right">
                          <h5 class="mb-0 new-default-black-font font-weight-400 fs-12">Valor final</h5>
                          <h5 class="mb-0 new-default-black-font font-weight-500 fs-20">{{ item.total_value | currency }}</h5>
                        </div>
                        <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black mx-3 mt-3 mb-1 col" />
                      </div>
                    </div>
                    <div>
                      <div v-show="[2, 3].includes(entry_launch.product_service_hub_type_id)" class="w-100 mt-3">
                        <base-input input-classes="form-control-sm">
                          <puzl-select
                            @input="applyToAllItems($event, 'stock_location_id', item.product_service_hub.is_fuel)"
                            v-if="entry_launch.product_service_hub_type_id !== 2"
                            placeholder="Local de Estoque"
                            v-model="item.stock_location_id"
                            :items="item.product_service_hub.is_fuel ? fuel_tank_stock_locations : stock_locations"
                            :clearable="false"
                            :loading="loadingStockLocation"
                            :disabled="loadingStockLocation"
                          />
                          <puzl-select
                            v-else
                            @input="applyToAllItems($event, 'stock_location_id')"
                            placeholder="Local de Estoque"
                            v-model="item.stock_location_id"
                            :items="getStockLocationsByCmcCategory(item.product_service_hub.cmc_category_id)"
                            :clearable="false"
                            :loading="loadingStockLocation"
                            :disabled="loadingStockLocation"
                          />
                        </base-input>
                      </div>
                      <div class="w-100 mt-2">
                        <base-input input-classes="form-control-sm">
                          <puzl-select
                            @input="applyToAllItems($event, 'chart_account_id')"
                            placeholder="Plano de Contas"
                            v-model="item.chart_account_id"
                            :items="$_chart_accounts"
                            :clearable="false"
                            :labelMask="true"
                            label="$full_code$ $name$"
                            :selectable="(i) => i.extra_field !== null"
                            :disabled="loadingChartAccount"
                            extraField="chart_account_parent_uuid"
                          />
                        </base-input>
                      </div>
                    </div>
                  </card>
                </div>
              </div>
            </div>
          </div>
          <div>
            <!-- Documento -->
            <div v-show="tab === 0">
              <!-- Estatísticas -->
              <!-- <div class="border-left border-danger border-3 mt-3 pl-3">
                  <div class="col-md-4 pl-1">
                    <label class="col-12 pl-0 pb-0 mb-1 col-form-label form-control-label font-weight-normal"> Valor
                      total </label>
                    <base-input input-group-classes="input-group-sm">
                      <input disabled v-model="human_total_value" class="form-control form-control-sm"/>
                    </base-input>
                  </div>
                  <div class="col-md-4 pl-1 pb-2">
                    <label class="col-12 pl-0 pb-0 mb-1 col-form-label form-control-label"> Valor total da nota </label>
                    <base-input input-group-classes="input-group-sm mb-2">
                      <input disabled v-model="human_final_value" class="form-control form-control-sm"/>
                    </base-input>
                  </div>
                </div> -->
            </div>

            <!-- Items -->
            <div v-show="tab === 1">
              <div class="mt-5">
                <PuzlEmptyData v-show="!items.length" />
                <div class="row card-wrapper" v-if="items.length">
                  <div class="col-lg-4" v-for="(item, index) in items" :key="index">
                    <card>
                      <div class="row align-items-center">
                        <div class="col-9 justify-content-center pr-0">
                          <h3 class="mb-0">
                            {{ item.product_service_hub.product_name }}
                          </h3>
                          <div v-if="item.product_service_hub.product_service_hub_type_id === 2">
                            <h3 class="mb-0">
                              {{ item.product_service_hub.supplier_name }}
                            </h3>
                            <h3 class="mb-1">({{ item.product_service_hub.origin_name }})</h3>
                          </div>
                        </div>
                        <div v-if="!selected_items" class="col-3 text-right pl-0">
                          <base-dropdown menuOnRight>
                            <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                              <i class="fas fa-cog"></i>
                            </base-button>
                            <a @click.prevent="handleModalEditEntryLaunchItem(item, index)" class="dropdown-item">
                              <i class="fas fa-edit text-warning main-icon-menu"></i>
                              Editar
                            </a>
                            <a @click.prevent="deleteItem(index)" class="dropdown-item">
                              <i class="fas fa-times text-danger main-icon-menu"></i>
                              Excluir
                            </a>
                            <a
                              v-show="
                                entry_launch.entry_company_plant_id &&
                                [
                                  'Serviço',
                                  'Produtos e Serviços',
                                  'Financiamento',
                                  'Frete',
                                  'Tributos Automáticos',
                                  'Empréstimo',
                                  'Taxas e Tributos',
                                ].includes(entry_launch_types[entry_launch.product_service_hub_type_id])
                              "
                              @click="handleModalEquipmentApportionment(item, index)"
                              class="dropdown-item"
                            >
                              <img height="20px" src="/img/icons/icons8/ios/split_success.png" class="mr-3 ml-n1" />
                              Rateio
                            </a>
                          </base-dropdown>
                        </div>
                        <div v-else class="col-3 text-right pl-0">
                          <base-button
                            style="padding: 0.32rem 0.5rem !important"
                            v-if="selected_items.includes(index)"
                            @click.prevent="removeFromSelectedItems(index)"
                            class="float-right"
                            type="success"
                          >
                            <i class="fas fa-check text-white" />
                          </base-button>
                          <base-button
                            style="padding: 0.32rem 0.5rem !important; background-color: white"
                            v-else
                            @click.prevent="addToSelectedItems(index)"
                            class="float-right"
                          >
                            <i class="fas fa-check text-white" />
                          </base-button>
                        </div>
                        <div class="col-12 pt-0">
                          <h4 class="mb-0 font-weight-normal">{{ item.type_name }}</h4>
                        </div>
                        <div class="col-12 mt-2">
                          <h5 v-if="item.product_service_hub.ncm" class="h3 mb-0 font-weight-normal">
                            NCM : {{ item.product_service_hub.ncm }}
                          </h5>
                          <h5 class="h3 mb-0 font-weight-normal">{{ item.quantity }} {{ item.product_service_hub.purchase_unit }}</h5>
                          <h5 class="h3 mb-0 font-weight-normal">
                            {{ toMoneyWithThreeDecimals(item.price) }} / {{ item.product_service_hub.purchase_unit }}
                          </h5>
                          <h5 class="h3 mb-0">{{ item.total_value | currency }}</h5>
                        </div>
                      </div>
                      <div>
                        <div v-show="[2, 3].includes(entry_launch.product_service_hub_type_id)" class="w-100 mt-3">
                          <base-input input-classes="form-control-sm">
                            <puzl-select
                              @input="applyToAllItems($event, 'stock_location_id', item.product_service_hub.is_fuel)"
                              v-if="entry_launch.product_service_hub_type_id !== 2"
                              placeholder="Local de Estoque"
                              v-model="item.stock_location_id"
                              :items="item.product_service_hub.is_fuel ? fuel_tank_stock_locations : stock_locations"
                              :clearable="false"
                              :loading="loadingStockLocation"
                              :disabled="loadingStockLocation"
                            />
                            <puzl-select
                              v-else
                              @input="applyToAllItems($event, 'stock_location_id')"
                              placeholder="Local de Estoque"
                              v-model="item.stock_location_id"
                              :items="getStockLocationsByCmcCategory(item.product_service_hub.cmc_category_id)"
                              :clearable="false"
                              :loading="loadingStockLocation"
                              :disabled="loadingStockLocation"
                            />
                          </base-input>
                        </div>
                        <div class="w-100 mt-2">
                          <base-input input-classes="form-control-sm">
                            <puzl-select
                              @input="applyToAllItems($event, 'chart_account_id')"
                              placeholder="Plano de Contas"
                              v-model="item.chart_account_id"
                              :items="$_chart_accounts"
                              :clearable="false"
                              :labelMask="true"
                              label="$full_code$ $name$"
                              :selectable="(i) => i.extra_field !== null"
                              :disabled="loadingChartAccount"
                              extraField="chart_account_parent_uuid"
                            />
                          </base-input>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
              </div>
            </div>

            <!-- Frete -->
            <div v-show="tab_value === 'transport'">
              <div class="row">
                <div class="col-md-6">
                  <h3 style="line-height: normal !important" class="new-default-black-font mb-0 d-flex align-items-center">
                    <img height="30px" src="/img/icons/icons8/ios/in-transit_primary.png" class="mr-3" />
                    Transporte
                  </h3>
                </div>
                <div class="col-md-6">
                  <base-button-hoverable v-if="freight && freight.observations" size="sm" type="success" icon="plus--v1" platform="ios">
                  </base-button-hoverable>
                </div>
                <div class="col-12">
                  <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black mt-3 mb-4" />
                </div>
              </div>
              <div
                v-if="
                  entry_launch.product_service_hub_type_id &&
                  (entry_launch.product_service_hub_type_id === 2 || entry_launch.product_service_hub_type_id === 3) &&
                  entry_launch.company_plant_buyer_id &&
                  entry_launch.company_plant_id
                "
              >
                <div class="row">
                  <div class="col-md-6">
                    <div class="card py-4 px-3">
                      <div class="row m-0">
                        <div class="col-6">
                          <h3 style="line-height: normal !important" class="new-default-black-font mb-0 d-flex align-items-center">
                            Transportadora
                            <base-button
                              v-if="freight.freight_modality_id"
                              @click.prevent="getFreight"
                              size="sm"
                              type="primary"
                              class="text-uppercase ml-2"
                            >
                              <i class="fas fa-search" />
                            </base-button>
                          </h3>
                        </div>
                        <div class="col-6 d-flex justify-content-end">
                          <div v-if="['MCC' , 'Produtos e Serviços'].includes(entry_launch_types[entry_launch.product_service_hub_type_id]) && !freight.entity_id" class="col-6 p-0">
                            <base-button-hoverable
                              @click="handleModalSearchEntryLaunch"
                              size="sm"
                              type="success"
                              icon="plus--v1"
                              platform="ios"
                            >
                              Incluir
                            </base-button-hoverable>
                          </div>
                          <div v-if="freight.entity_id" class="col-6 p-0 ml-2">
                            <base-button-hoverable
                              @click="handleFreightDelete(entry_launch.id)"
                              size="sm"
                              type="danger"
                              icon="delete"
                              platform="ios"
                            >
                              Excluir
                            </base-button-hoverable>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black mt-3 mb-4" />
                      </div>
                      <div v-if="freight.freight_modality_id" class="border-left border-indigo border-3 mx-3 mt-1">
                        <h4 class="mb-0 col-12 pl-2">{{ freight_entity.entity_name }}</h4>
                        <h4 class="mb-0 col-12 font-weight-normal pl-2">{{ freight_entity.document }}</h4>
                      </div>
                    </div>
                  </div>
                  <div v-if="freight.freight_modality_id" class="col-md-6">
                    <div class="px-4">
                      <div class="row">
                        <label class="col-6 mb-1 col-form-label form-control-label"> Frete </label>
                        <div class="col-6 pr-md-0">
                          <el-select
                            @change="getFreight"
                            v-model="freight.freight_modality_id"
                            size="mini"
                            placeholder="Selecione"
                            filterable
                            class="w-100"
                            disabled
                          >
                            <el-option
                              v-for="freight_modality in freight_modalities"
                              :key="freight_modality.id"
                              :label="`${freight_modality.code} - ${freight_modality.name}`"
                              :value="freight_modality.id"
                            >
                            </el-option>
                            <el-option :value="null" label="9 - Sem frete"> </el-option>
                          </el-select>
                        </div>
                      </div>
                      <div v-if="freight.freight_modality_id">
                        <div class="row">
                          <label class="col-6 mb-1 col-form-label form-control-label">
                            Quantidade <span class="text-danger">&nbsp;*</span></label
                          >
                          <div class="col-6 pr-md-0">
                            <div class="w-100 pr-md-0">
                              <base-input input-group-classes="input-group-sm">
                                <input
                                  inputmode="numeric"
                                  v-on:input="freight.quantity = formatString($event.target.value, '0', '99999999')"
                                  v-model="freight.quantity"
                                  class="form-control form-control-sm"
                                />
                              </base-input>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <label class="col-6 mb-1 col-form-label form-control-label">
                            Valor do frete <span class="text-danger">&nbsp;*</span></label
                          >
                          <div class="col-6 pr-md-0">
                            <div class="w-100 pr-md-0">
                              <base-input input-group-classes="input-group-sm">
                                <input
                                  :disabled="entry_launch.external_type === 1"
                                  v-money="money"
                                  v-model.lazy="freight.total_value"
                                  maxlength="10"
                                  inputmode="numeric"
                                  class="form-control form-control-sm"
                                />
                                <template slot="prepend">
                                  <small> R$ </small>
                                </template>
                              </base-input>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <label class="col-6 mb-1 col-form-label form-control-label"> Foto do frete </label>
                          <div class="col-6 pr-md-0">
                            <div class="w-100 pr-md-0">
                              <base-input input-group-classes="input-group-sm">
                                <InputFile :multiple="false" ref="inputFreightFile" />
                              </base-input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Motorista -->
              <div class="row">
                <div class="col-md-6">
                  <div class="card py-3 px-3">
                    <div class="row m-0"> 
                      <label class="col-6 mb-1 mb-md-0 col-form-label form-control-label"> Motorista (Insumos) </label>
                      <div class="col-md-6 d-flex align-items-center w-100"> 
                        <div class="w-100">
                          <PuzlSelect v-model="entry_launch.driver_id" :items="$_drivers" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Pagamento -->
            <div v-show="tab_value === 'payment'">
              <div class="row mt-4">
                <div class="col-md-6">
                  <div class="card py-4 px-3">
                    <h3 style="line-height: normal !important" class="new-default-black-font mb-0 d-flex align-items-center">
                      <img height="30px" src="/img/icons/icons8/ios/info-squared_warning.png" class="mr-3" />
                      IDENTIFICAÇÃO
                    </h3>
                    <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black mt-3 mb-4" />

                    <!-- Conta Bancária -->
                    <div class="row">
                      <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                        Conta Bancária <span class="text-danger">&nbsp;*</span>
                      </label>
                      <div class="col-md-6">
                        <el-select
                          class="w-100"
                          :disabled="loadingBankAccount"
                          v-model="entry_launch.bank_account_id"
                          @change="$forceUpdate()"
                          size="mini"
                          placeholder="Selecione"
                          filterable
                        >
                          <el-option
                            v-for="bank_account in bank_accounts"
                            :key="bank_account.bank_account_id"
                            :label="bank_account.bank_account.name"
                            :value="bank_account.bank_account_id"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>

                    <!-- Método de pagamento -->
                    <div class="row mt-2">
                      <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                        Método de pagamento <span class="text-danger">&nbsp;*</span>
                      </label>
                      <div class="col-md-6">
                        <el-select
                          class="w-100"
                          :disabled="loadingPaymentMethod"
                          v-model="entry_launch.payment_method_id"
                          size="mini"
                          placeholder="Selecione"
                          filterable
                        >
                          <el-option v-for="method in $_payment_methods" :key="method.id" :label="method.name" :value="method.id">
                          </el-option>
                        </el-select>
                      </div>
                    </div>

                    <!-- Valor total da nota -->
                    <div class="row mt-4">
                      <h3 class="col-6">Valor total da nota</h3>
                      <h3 class="col-6 text-right">{{ entry_launch.total_value | currency() }}</h3>
                    </div>

                    <!-- Valor final -->
                    <div class="row mt-2">
                      <h3 class="col-6 text-primary">Valor final</h3>
                      <h3 class="col-6 text-right text-primary">{{ entry_launch.final_value | currency() }}</h3>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card py-4 px-3">
                    <div class="row">
                      <h3 style="line-height: normal !important" class="new-default-black-font mb-0 d-flex align-items-center col-md-6">
                        <img height="30px" src="/img/icons/icons8/ios/how-many-quest--v2_success.png" class="mr-3" />
                        PARCELAS
                      </h3>
                      <div class="col-md-3" />
                      <div class="col-md-3">
                        <input
                          @change="changeInstallments"
                          v-on:input="installment_quantity = formatString($event.target.value, '0', '99')"
                          v-model="installment_quantity"
                          inputmode="numeric"
                          class="form-control form-control-sm"
                        />
                      </div>
                    </div>
                    <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black mt-3 mb-4" />
                    <Installments
                      @applyDueDate="applyDueDateToAllInstallments"
                      ref="installments"
                      @getFinalValue="getFinalValue"
                      :items="bill_pay_installments"
                      :entry_launch="entry_launch"
                      :must_copy_due_date="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="entry_launch.product_service_hub_type_id && entry_launch.company_plant_buyer_id">
            <base-button
              class="col-2"
              type="primary"
              :loading="loadingStore"
              style="bottom: 130px !important; right: 3.4rem !important; position: fixed; z-index: 9999; max-width: 11%"
              @click.prevent="goToNextTab"
              v-if="!getNextTabButtonStatus()"
            >
              <img height="30px" src="/img/icons/icons8/ios/right-squared.png" class="mr-md-3 ml-n2 ml-md-0" />
              <span class="show-md" style="font-size: 0.75rem">PRÓXIMO</span>
            </base-button>
            <base-button
              class="col-2"
              v-else
              type="success"
              :loading="loadingStore"
              style="bottom: 130px !important; right: 50px !important; position: fixed; z-index: 9999; max-width: 11%"
              @click.prevent="store"
            >
              <img height="30px" src="/img/icons/save.png" class="mr-md-3 ml-n2 ml-md-0" />
              <span class="show-md" style="font-size: 0.75rem">SALVAR</span>
            </base-button>
          </div>
          <div v-if="selected_items">
            <base-button
              class="col-2"
              type="warning"
              :disabled="!selected_items.length"
              style="bottom: 195px !important; right: 2.95rem !important; position: fixed; z-index: 9999; max-width: 11%"
              @click.prevent="handleModalSetChartAccount"
            >
              <img height="30px" src="/img/icons/icons8/ios/accounting.png" class="mr-3" />
              <span style="font-size: 0.75rem">PLANO DE CONTAS</span>
            </base-button>
            <base-button
              class="col-2"
              :disabled="
                !entry_launch.entry_company_plant_id ||
                ![1, 3, 7].includes(entry_launch.product_service_hub_type_id) ||
                !selected_items.length
              "
              type="success"
              style="bottom: 265px !important; right: 3rem !important; position: fixed; z-index: 9999; max-width: 11%"
              @click.prevent="handleModalSetEquipmentApportionment"
            >
              <img height="30px" src="/img/icons/icons8/ios/split.png" class="mr-3" />
              <span style="font-size: 0.75rem">RATEIO</span>
            </base-button>
            <base-button
              class="col-2"
              :disabled="entry_launch.product_service_hub_type_id !== 3 || !selected_items.length"
              type="primary"
              style="bottom: 330px !important; right: 3.5rem !important; position: fixed; z-index: 9999; max-width: 11%"
              @click.prevent="handleModalSetStockLocation"
            >
              <img height="30px" src="/img/icons/open-box-white.png" class="mr-3" />
              <span style="font-size: 0.75rem">LOCAL DE ESTOQUE</span>
            </base-button>
          </div>
        </card>
      </div>
    </div>

    <ModalCreateEntryLaunchItem @added="addedItem" ref="modalCreateEntryLaunchItem" />
    <ModalEditEntryLaunchItem @updated="updatedItem" ref="modalEditEntryLaunchItem" />
    <ModalSearchEntity @selected="selectedEntity" ref="modalSearchEntity" />
    <ModalEntityAccount ref="entityAccount" />
    <ModalEquipmentApportionment @added="setEquipmentApportionments" ref="modalEquipmentApportionment" />
    <ModalSetChartAccount @store="applyToSelectedItems($event, 'chart_account_id')" ref="modalSetChartAccount" />
    <ModalSetEquipmentApportionment
      @store="applyToSelectedItems($event, 'equipment_apportionments')"
      ref="modalSetEquipmentApportionment"
    />
    <ModalSearchEntryLaunch :must_save="false" @selected="storeSelectedFreight" ref="modalSearchEntryLaunch" />
    <ModalSetStockLocation
      :items="stock_locations"
      @store="applyToSelectedItems($event, 'stock_location_id')"
      ref="modalSetStockLocation"
    />
    <CreateProductService :product_service_hub_type_id="entry_launch.product_service_hub_type_id" ref="modalCreateProductService" />
    <ModalOperationSourceCreate @created="getOperationSources" ref="modalOperationSourceCreate" />
    <ModalTaxes :disabled="false" @onSave="setTax" ref="modalTaxes" />
  </div>
</template>

<script>
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import { mapGetters } from "vuex";
import BackButton from "@/components/Utils/BackButton";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import moment from "moment";
import InputFile from "@/components/Utils/InputFile";
import { checkNumberValue, moneyToFloat } from "@/helpers";
import { mask1, formatErrorValidation } from "@/plugins";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import ModalCreateEntryLaunchItem from "../Item/_ModalCreate";
import ModalEditEntryLaunchItem from "../Item/_ModalEdit";
import SkeletonPuzlFullWidth from "@/components/SkeletonPuzlFullWidth";
import ModalSearchEntity from "../../Entity/Shared/_ModalSearchEntity";
import { VMoney } from "v-money";
import billPayMixin from "../../../Financial/BillPay/Mixins/bill_pay_mixin";
import entryLaunchMixin from "../Mixins/entry_launch_mixin";
import PuzlSelect from "@/components/PuzlSelect";
import ModalEntityAccount from "@/views/Modules/Configuration/Entity/EntityAccount/Index";
import ModalEquipmentApportionment from "../Item/_ModalEquipmentApportionment.vue";
import InputLimit from "@/components/Utils/InputLimit";
import Installments from "./_Installments";
import ModalSetChartAccount from "./_ModalSetChartAccount";
import ModalSetEquipmentApportionment from "./_ModalSetEquipmentApportionment";
import ModalSearchEntryLaunch from "./_ModalSearchEntryLaunch";
import ModalSetStockLocation from "./_ModalSetStockLocation";
import CreateProductService from "@/views/Modules/Configuration/ProductService/Shared/_ModalCreate";
import Navegation from "./_Navegation";
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import ModalOperationSourceCreate from "@/views/Modules/Fiscal/OperationSource/Shared/_ModalCreate";
import ModalTaxes from "../../../../../modules/configuration/entry-launch/aggregates/entry-launch-item-tax/views/shared/ModalTaxes.vue";

export default {
  name: "CreateEntryLaunch",
  mixins: [billPayMixin, entryLaunchMixin],
  components: {
    BackButton,
    PuzlBreadcrumb,
    InputDateTimePicker,
    InputFile,
    ModalCreateEntryLaunchItem,
    PuzlEmptyData,
    ModalEditEntryLaunchItem,
    SkeletonPuzlFullWidth,
    ModalSearchEntity,
    PuzlSelect,
    ModalEntityAccount,
    ModalEquipmentApportionment,
    InputLimit,
    Installments,
    ModalSetChartAccount,
    ModalSetEquipmentApportionment,
    ModalSearchEntryLaunch,
    ModalSetStockLocation,
    CreateProductService,
    Navegation,
    BaseButtonHoverable,
    ModalOperationSourceCreate,
    ModalTaxes,
  },
  data() {
    return {
      tab: 0,
      entry_launch: {
        status: 1,
        product_service_hub_type_id: null,
        company_plant_id: null,
        entry_date: moment().toString(),
        issue_date: null,
        document_template_id: null,
        observations: null,
        final_value: 0,
        total_value: 0,
        cost_center_id: null,
        series: null,
        nature_operation: null,
        payment_method_id: null,
        access_key: null,
        bank_account_id: null,
        accounting: true,
        company_plant_buyer_id: null,
        driver_id: null,
      },
      loadingStore: false,
      loadingDocumentTemplate: true,
      loadingEntity: true,
      loadingCompanyPlantBuyer: true,
      loadingProductServiceHubType: true,
      loadingCostCenter: true,
      loadingPaymentMethod: true,
      loadingBankAccount: true,
      company_plant_buyers: [],
      items: [],
      freight: {
        total_value: 0,
        photo: null,
        quantity: null,
        gross_weight: null,
        net_weight: null,
        plate: null,
        model: null,
        species: null,
        entity_id: null,
        freight_modality_id: null,
      },
      bill_pay_installments: [],
      bank_accounts: [],
      installment_quantity: null,
      freight_entity: null,
      loadingChartAccount: false,
      loadingParents: true,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      min_entry_date: null,
      min_date: null,
    };
  },
  props: {
    byReplication: {
      type: Boolean,
      default: false,
    },
    byContractProposal: {
      type: Boolean,
      default: false,
    },
    byEntity: {
      type: Boolean,
      default: false,
    },
  },
  directives: { money: VMoney },
  computed: {
    ...mapGetters({
      company_plants: "plant/activeItems",
      $_document_templates: "documentTemplate/fetch",
      entity: "entity/show",
      $_product_service_hub_types: "productServiceHubType/fetch",
      $_cost_centers: "costCenter/fetch",
      $_payment_methods: "paymentMethod/fetch",
      $_chart_accounts: "managerialChartAccount/fetch",
      $_operation_sources: "operationSource/fetch",
      $_latest_closing: "stockClosing/getLatestClosingAt",
      $_parents: "entryLaunch/getParents",
      $_drivers: "user/getEntryDrivers",
    }),
    human_final_value() {
      return `${this.entry_launch.final_value.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}`;
    },
    human_total_value() {
      return `${this.entry_launch.total_value.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}`;
    },
    product_service_hub_types() {
      return this.$_product_service_hub_types.filter((item) => !["Não informado", "Serviço"].includes(this.entry_launch_types[item.id]));
    },
  },
  watch: {
    items() {
      this.entry_launch.total_value = this.items.reduce((partialSum, a) => partialSum + a.total_value, 0);
      this.getFinalValue();
    },
  },
  methods: {
    async updateLatestStock() {
      await this.$store.dispatch("stockClosing/getLatestClosingAt");
      this.min_entry_date = new Date(moment(this.$_latest_closing.closing_at).add(1, "days"));
    },
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        return checkNumberValue(mask1(value.toString(), decimals, ".", true), min, max);
      }
      return checkNumberValue(value, min, max);
    },
    handleModalCreateEntryLaunchItem() {
      this.$refs.modalCreateEntryLaunchItem.openModal(this.entry_launch.product_service_hub_type_id, this.entry_launch.entity_id);
    },
    handleModalEditEntryLaunchItem(item, index) {
      this.$refs.modalEditEntryLaunchItem.openModal(item, index);
    },
    addedItem(item) {
      /* Se já existir e for do tipo padrão só substitui o item */
      if (
        this.checkItemAlreadyExists(item) &&
        this.default_product_service_hub_types.includes(this.entry_launch.product_service_hub_type_id)
      ) {
        this.items[0] = item;
        this.entry_launch.total_value = item.total_value;
        return this.getFinalValue();
      }
      /* Barra tipos diferentes */
      if (
        this.items &&
        this.items[0] &&
        this.items[0].product_service_hub.product_service_hub_type_id !== item.product_service_hub.product_service_hub_type_id
      ) {
        return this.$notify({ type: "danger", message: "Operação não permitida!" });
      }

      /* Se o item for do tipo combustível será marcado */
      if (item.product_service_hub.is_fuel) {
        this.getFuelTankStockLocations();
      }

      this.items.push(item);
    },
    updatedItem(item, index) {
      delete item.tax;
      this.items.splice(index, 1, item);
    },
    checkProductServiceHubType(value, reset_operation_source = true) {
      if (reset_operation_source) {
        this.entry_launch.operation_source_id = null;
      }
      this.operation_source = false;
      /* Caso seja dos tipos padrão */
      if (this.default_product_service_hub_types.includes(value)) {
        let type = this.$_product_service_hub_types.filter((item) => item.id === value)[0].name.toUpperCase();
        type = type === "TRANSPORTE" ? "FRETE" : type;
        this.$store.dispatch("productService/getDefaultProductServiceHubByType", type).then((response) => {
          let product = response.data;
          product.quantity = "1.000";
          product.category_name = product.product_name;
          this.$refs.modalCreateEntryLaunchItem.selectedProductServiceHub(product);
        });
      } else {
        this.getStockLocations();
      }
      this.items = [];
      this.getOperationSources();
      this.getParentsWithChildrenBalance();
    },
    checkItemAlreadyExists(item) {
      return this.items.findIndex((payload) => payload.product_service_hub_id === item.product_service_hub_id) >= 0;
    },
    getFreight() {
      let current_freight_modality_id = this.freight.freight_modality_id;
      switch (true) {
        case current_freight_modality_id &&
          this.freight_modalities.filter(function (item) {
            return item.id === current_freight_modality_id && parseInt(item.code) === 0;
          }).length === 1:
          this.freight_entity = this.entity;
          this.freight.entity_id = this.entity.id;
          break;
        case current_freight_modality_id !== null:
          this.handleSelectEntity();
          break;
      }
    },
    deleteItem(index) {
      this.items.splice(index, 1);
    },
    async changeInstallments(show_message = true) {
      this.bill_pay_installments = [];
      let val = parseInt(this.installment_quantity);
      switch (true) {
        /* caso não haja parcelas a serem manipuladas */
        case !val:
          this.installment_quantity = 1;
          if (show_message) {
            this.$notify({ type: "danger", message: "Deve haver ao menos uma parcela!" });
          }
          await this.generateInstallments(1);
          break;
        default:
          await this.generateInstallments(val);
      }
      this.$refs.installments.resetPage();
    },
    generateInstallments(to_generate) {
      /* valor total informado pelo usuário */
      let bill_pay_total_value = this.entry_launch.total_value;
      /* Valor por parcela */
      let value_per_installment = bill_pay_total_value ? (bill_pay_total_value / to_generate).toFixed(2) : 0;
      for (let i = 0; i < to_generate; i++) {
        this.bill_pay_installments.push({
          status: 0,
          index: i + 1,
          due_date: null,
          original_due_date: null,
          paid_at: null,
          discounts_fees: null,
          fees_fines: null,
          final_value: value_per_installment,
          total_value: value_per_installment,
        });
      }
      let real_total_value = this.bill_pay_installments.reduce(function (a, item) {
        return a + parseFloat(item.total_value);
      }, 0);

      /** Diferença de valor real e teórico */
      let difference = this.$helper.toDecimal(real_total_value - bill_pay_total_value, 2, true);

      if (difference !== 0) {
        /** Checa integridade do valor */
        switch (true) {
          case difference > 0:
            this.bill_pay_installments[0].total_value = Number(parseFloat(this.bill_pay_installments[0].total_value) - difference).toFixed(
              2
            );
            this.bill_pay_installments[0].final_value = Number(parseFloat(this.bill_pay_installments[0].final_value) - difference).toFixed(
              2
            );
            break;
          case difference < 0:
            this.bill_pay_installments[0].total_value = Number(
              parseFloat(this.bill_pay_installments[0].total_value) + Math.abs(difference)
            ).toFixed(2);
            this.bill_pay_installments[0].final_value = Number(
              parseFloat(this.bill_pay_installments[0].final_value) + Math.abs(difference)
            ).toFixed(2);
            break;
        }
      }
      return difference;
    },
    getFinalValue(regenerate_installments = true) {
      /** Iguala valor total á valor final. */
      this.entry_launch.final_value = this.entry_launch.total_value;

      /* Valores a partir da parcela */
      this.bill_pay_installments.map(function (item) {
        let final_value = item.total_value.includes(",") ? moneyToFloat(item.total_value) : parseFloat(item.total_value);

        /** Caso haja taxas á retirar do valor total */
        if (item.discounts_fees && item.discounts_fees !== "0,00") {
          final_value -= moneyToFloat(item.discounts_fees);
          if (final_value < 0) {
            this.$notify({ type: "danger", message: "O valor do desconto deve ser menor que o valor total da parcela." });
            final_value += moneyToFloat(item.discounts_fees);
            item.discounts_fees = 0;
          } else {
            this.entry_launch.final_value -= moneyToFloat(item.discounts_fees);
          }
        }

        /** Caso haja juros á acrescentar do valor total */
        if (item.fees_fines && item.fees_fines !== "0,00") {
          final_value += moneyToFloat(item.fees_fines);
          this.entry_launch.final_value += moneyToFloat(item.fees_fines);
        }

        item.final_value = final_value.toFixed(2);
      }, this);

      if (regenerate_installments) {
        this.installment_quantity = 1;
        this.changeInstallments(false);
      }
    },
    finalFormat() {
      let payload = {
        entry_launch: { ...this.entry_launch },
        items: JSON.parse(JSON.stringify(this.items)),
        freight: { ...this.freight },
        bill_pay_installments: JSON.parse(JSON.stringify(this.bill_pay_installments)),
      };
      let formData = new FormData();
      payload.items.filter(function (obj) {
        obj.price = obj.price.toString().includes(",") ? moneyToFloat(obj.price) : parseFloat(obj.price);
      });
      if (payload.freight && payload.freight.entity_id) {
        payload.freight.total_value = payload.freight.total_value.replaceAll(".", "").replace(",", ".");
        for (let key of Object.keys(payload.freight)) {
          formData.append(`freight[${key}]`, payload.freight[key] ? payload.freight[key] : "");
        }
        if (this.$refs.inputFreightFile.fileRecords.length) {
          formData.append("freight[photo]", this.$refs.inputFreightFile.fileRecords[0].file);
        }
      }
      payload.entry_launch.entry_date = moment(this.entry_launch.entry_date).format("YYYY-MM-DD HH:mm:ss");
      payload.entry_launch.pending_freight = this.entry_launch.pending_freight ? 1 : 0;
      payload.entry_launch.accounting = this.entry_launch.accounting ? 1 : 0;
      if (!payload.entry_launch.entry_company_plant_id) payload.entry_launch.entry_company_plant_id = payload.entry_launch.company_plant_id;
      for (let key of Object.keys(payload.entry_launch)) {
        formData.append(`entry_launch[${key}]`, payload.entry_launch[key] !== null ? payload.entry_launch[key] : "");
      }
      if (this.$refs.inputEntryLaunchFile.fileRecords.length) {
        formData.append("entry_launch[photo]", this.$refs.inputEntryLaunchFile.fileRecords[0].file);
      }

      payload.items.map(function (obj, index) {
        obj.quantity = obj.quantity.replace(",", ".");
        for (let key of Object.keys(payload.items[index])) {
          formData.append(`items[${index}][${key}]`, payload.items[index][key] !== null ? payload.items[index][key] : "");
        }
        if (obj.equipment_apportionments) {
          obj.equipment_apportionments.map(function (item, itemIndex) {
            if (item.value.includes(",")) item.value = moneyToFloat(item.value);
            for (let key of Object.keys(obj.equipment_apportionments[itemIndex])) {
              formData.append(
                `items[${index}][equipment_apportionments][${itemIndex}][${key}]`,
                obj.equipment_apportionments[itemIndex][key] !== null ? obj.equipment_apportionments[itemIndex][key] : ""
              );
            }
          });
        }
        if(obj.tax) {
          obj.tax = this.getEntryLaunchItemTaxTransformed(obj.tax);
          for (let key of Object.keys(obj.tax)) {
            formData.append(`items[${index}][tax][${key}]`, obj.tax[key] !== null ? obj.tax[key] : "");
          }
        }
      }, this);

      payload.bill_pay_installments.map(function (obj, index) {
        obj.bank_account_id = obj.bank_account_id ? obj.bank_account_id : payload.entry_launch.bank_account_id;
        obj.due_date = obj.due_date ? moment(obj.due_date).format("YYYY-MM-DD") : null;
        payload.bill_pay_installments[index].original_due_date = payload.bill_pay_installments[index].original_due_date
          ? moment(payload.bill_pay_installments[index].original_due_date).format("YYYY-MM-DD")
          : null;
        obj.final_value = this.normalizeValue(obj.final_value);
        obj.total_value = this.normalizeValue(obj.total_value);
        obj.discounts_fees = obj.discounts_fees !== "0,00" ? this.normalizeValue(obj.discounts_fees) : null;
        obj.fees_fines = obj.fees_fines !== "0,00" ? this.normalizeValue(obj.fees_fines) : null;
        for (let key of Object.keys(payload.bill_pay_installments[index])) {
          formData.append(
            `bill_pay_installments[${index}][${key}]`,
            payload.bill_pay_installments[index][key] !== null ? payload.bill_pay_installments[index][key] : ""
          );
        }
      }, this);
      formData.append("dont_has_financial", this.dontHasFinancial ? 1 : 0);
      formData.append("freight_by_entry_launch", this.freight_by_entry_launch ? 1 : 0);
      return formData;
    },
    async store() {
      this.loadingStore = true;
      let payload = await this.finalFormat();
      if (!this.formDataLengthIsValid(payload)) {
        return this.$notify({
          type: "danger",
          message: "Nota indisponível, favor entrar em contato com o suporte.",
        });
      }
      this.$store
        .dispatch("entryLaunch/add", payload, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.loadingStore = false;
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.$router.push("/configuration/entry-launch");
        })
        .catch((error) => {
          if (error.status) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response && error.response.status && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
          this.loadingStore = false;
        });
    },
    handleSelectEntity() {
      this.$refs.modalSearchEntity.openModal();
    },
    selectedEntity(entity) {
      this.freight_entity = entity;
      this.freight.entity_id = entity.id;
    },
    changeBillPayInstallmentDates() {
      this.bill_pay_installments.map(function (obj, index) {
        let date = moment(this.entry_launch.entry_date).add(index, "months").format("YYYY-MM-DD") + " 00:00:00";
        obj.due_date = date;
        obj.original_due_date = date;
      }, this);
    },
    getCompanyPlantBuyers() {
      this.loadingCompanyPlantBuyer = true;
      this.getBankAccounts(this.entry_launch);
      this.$store.dispatch("companyPlantBuyer/getByCompanyPlant", this.entry_launch.company_plant_id).then((response) => {
        this.company_plant_buyers = response.data || [];
        if (!this.entry_launch.entry_company_plant_id) this.entry_launch.entry_company_plant_id = this.entry_launch.company_plant_id;
        if (this.company_plant_buyers.length) {
          let default_company_plant_buyer =
            this.company_plant_buyers.filter((item) => item.default === 1)[0] || this.company_plant_buyers[0];
          this.entry_launch.company_plant_buyer_id = default_company_plant_buyer.id;
        } else {
          this.entry_launch.company_plant_buyer_id = null;
        }
        this.loadingCompanyPlantBuyer = false;
        this.getParentsWithChildrenBalance();
      });
    },
    handleEntityAccount() {
      this.$refs.entityAccount.handleCreateModal(this.byReplication ? this.entry_launch.entity.id : this.entity.id);
    },

    /**
     * @description Preenche segunda central automaticamente
     *
     * @param {integer} value
     */
    fillCompanyPlant(value) {
      if (!this.entry_launch.company_plant_id) {
        this.entry_launch.company_plant_id = value;
        this.getCompanyPlantBuyers(true);
      }
    },

    /**
     * @description Se for uma criação por replicação
     */
    getReplicationData() {
      if (this.byReplication) {
        let loader = this.$loading.show();
        this.$store.dispatch("entryLaunch/show", this.$route.params.id).then((response) => {
          /* Fornecedor */
          this.entry_launch.entity = response.data.entity;
          this.loadingEntity = false;

          /* Despesa */
          this.entry_launch.entry_company_plant_id = response.data.entry_company_plant_id;
          this.entry_launch.company_plant_id = response.data.company_plant_id;
          this.entry_launch.entity_id = response.data.entity_id;
          this.entry_launch.entry_date = response.data.entry_date;
          this.entry_launch.company_plant_buyer_id = response.data.company_plant_buyer_id;
          this.entry_launch.discount = response.data.discount;
          this.entry_launch.document_template_id = response.data.document_template_id;
          this.entry_launch.due_date = response.data.due_date;
          this.entry_launch.final_value = parseFloat(response.data.final_value);
          this.entry_launch.entry_date = this.$helper.parseDate(moment());
          this.entry_launch.issue_date = this.$helper.parseDate(moment().toString(), "YYYY-MM-DD HH:mm:ss");
          this.entry_launch.nature_operation = response.data.nature_operation;
          this.entry_launch.observations = response.data.observations;
          this.entry_launch.operation_source_id = response.data.operation_source_id;
          this.entry_launch.payment_method_id = response.data.payment_method_id;
          this.entry_launch.product_service_hub_type_id = response.data.product_service_hub_type_id;
          this.entry_launch.total_value = parseFloat(response.data.total_value);
          this.getCompanyPlantBuyers();
          this.checkProductServiceHubType(this.entry_launch.product_service_hub_type_id, false);

          /* Itens */
          this.$store.dispatch("entryLaunchItem/getByEntryLaunch", this.$route.params.id).then((response) => {
            this.items = response.data;
            this.items.map(function (item, key) {
              item.quantity = item.quantity.replace(".", ",");
              item.total_value = parseFloat(item.total_value);
              this.items[key].product_service_hub = {
                product_service_hub_type_id: item.product_service_hub_type_id,
                category_name: item.category_name,
                product_name: item.product_name,
                purchase_unit: item.purchase_unit,
                ncm: item.ncm,
                supplier_name: item.supplier_name,
                origin_name: item.origin_name,
                type_name: item.type_name,
              };
            }, this);
            this.getEquipments();
            this.checkFinancial();
            loader.hide();
          });
        });
      }
    },

    /**
     * Busca dados iniciais de contrato
     *
     * @returns void
     */
    getInitialDataByContractProposal() {
      let loader = this.$loading.show();
      this.$store.dispatch("entryLaunch/getInitialDataByContractProposal", this.$route.params.contract_proposal_id).then((response) => {
        this.entry_launch.entry_date = this.$helper.parseDate(moment(), "YYYY-MM-DD HH:mm:ss");
        this.entry_launch.issue_date = this.$helper.parseDate(moment(), "YYYY-MM-DD HH:mm:ss");
        this.entry_launch.series = this.entry_launch.issue_date.substring(0, 4);
        this.entry_launch.product_service_hub_type_id = 11;
        this.entry_launch.entry_company_plant_id = response.data.contract_proposal_data.company_plant_id;
        this.entry_launch.company_plant_id = response.data.contract_proposal_data.company_plant_id;
        this.entry_launch.entity_id = response.data.contract_proposal_data.entity_id;
        this.entry_launch.document_number = response.data.document_number;
        this.entry_launch.document_template_id = 1;
        this.entity.entity_name = response.data.contract_proposal_data.entity_name;
        this.entity.document = response.data.contract_proposal_data.document;
        this.getCompanyPlantBuyers();
        this.checkProductServiceHubType(this.entry_launch.product_service_hub_type_id, false);
        this.loadingEntity = false;
        loader.hide();
      });
    },

    handleModalSetChartAccount() {
      this.$refs.modalSetChartAccount.openModal();
    },

    handleModalSetEquipmentApportionment() {
      this.$refs.modalSetEquipmentApportionment.openModal();
    },

    handleModalSetStockLocation() {
      this.$refs.modalSetStockLocation.openModal();
    },

    handleCreateProductService() {
      this.$refs.modalCreateProductService.handleCreateModal();
    },

    /**
     * Gera número de documento
     *
     * @returns void
     */
    getDocumentNumber() {
      this.entry_launch.document_number = moment().format("YYMMDDHHmm");
      this.$forceUpdate();
    },

    async getInitialDataByEntity() {
      const loader = this.$loading.show();
      const response = await this.$store.dispatch('entity/show', this.$route.params.entity_uuid);
      this.entry_launch.entry_date = this.$helper.parseDate(moment(), "YYYY-MM-DD HH:mm:ss");
      this.entry_launch.issue_date = this.$helper.parseDate(moment(), "YYYY-MM-DD HH:mm:ss");
      this.entry_launch.series = this.entry_launch.issue_date.substring(0, 4);
      this.entry_launch.product_service_hub_type_id = 11; // Devolução de receita
      this.entry_launch.entity_id = response.data.id;
      this.entry_launch.document_template_id = 1; // Recibo
      this.entity.entity_name = response.data.entity_name;
      this.entity.document = response.data.document;
      this.loadingEntity = false;
      loader.hide();
    },
    setTax(tax, totalValue) {
      this.items[tax.line_number].tax = tax;
      this.items[tax.line_number].total_value = totalValue;
      this.entry_launch.total_value = this.items.reduce((partialSum, a) => partialSum + a.total_value, 0);
      this.getFinalValue();
    },
  },
  async mounted() {
    await this.updateLatestStock();
    this.$store.dispatch("documentTemplate/fetch").then(() => {
      this.loadingDocumentTemplate = false;
    });
    if (!this.byReplication && !this.byContractProposal) {
      this.$store.dispatch("entity/show", this.$route.params.entity_uuid).then(() => {
        this.entry_launch.entity_id = this.entity.id;
        this.loadingEntity = false;
      });
    }
    this.$store.dispatch("productServiceHubType/fetch").then(() => {
      this.loadingProductServiceHubType = false;
    });
    this.$store.dispatch("costCenter/fetchItems").then(() => {
      this.loadingCostCenter = false;
    });
    this.$store.dispatch("paymentMethod/fetchItems").then(() => {
      this.loadingPaymentMethod = false;
    });
    this.$store.dispatch("user/getEntryDrivers");
    this.getReplicationData();
    if (this.byContractProposal) {
      this.getInitialDataByContractProposal();
    } else if(this.byEntity) {
      this.getInitialDataByEntity();
    }
    this.min_date = new Date(moment().subtract(365, "days").format("YYYY-MM-DD HH:mm:ss"));
  },
};
</script>

<style>
.custom-entry-launch-prepend-input .form-group .input-group .input-group-prepend .input-group-text {
  padding: 0.35rem !important;
}
</style>
