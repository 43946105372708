<template>
  <div>
    <modal
      :show.sync="modal.create"
    >
      <template slot="header">
        <h5 class="modal-title ">{{ modal.title }}</h5>
      </template>
      <div class="row align-items-center">
        <div class="col-md-12 mb-2">
          <label class="form-control-label">
            Descrição da transação
          </label>
          <base-input input-classes="form-control-sm" v-model="description"/>
        </div>
        <label class="form-control-label col-md-6">
          Valor de agrupamento
        </label>
        <div class="col-md-6">
          <base-input input-group-classes="input-group-sm">
            <input
              disabled
              type="text"
              inputmode="numeric"
              v-money="money"
              :value="params.total_value"
              class="form-control form-control-sm "
            />
            <template slot="prepend">
              <small class="input-group-sm p-0 m-0"> R$ </small>
            </template>
          </base-input>
        </div>
      </div>
      <div class="modal-footer">
        <base-button type="secondary" @click="closeModal()"> Cancelar</base-button>
        <base-button @click.prevent="save()" type="success" native-type="submit" v-bind:disabled="!description"
                     :loading="loading"> Salvar
        </base-button>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {VMoney} from "v-money";

export default {
  name: "ModalAGroup",
  components: {},
  data() {
    return {
      modal: {
        title: 'Agrupamento de transações',
        create: false,
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false
      },
      loading: false,
      description: null,
      params: {
        ids: [],
        type: 0,
        total_value: 0
      }
    };
  },
  directives: {money: VMoney},
  computed: {
    ...mapGetters({}),
  },
  methods: {
    save() {
      this.$Swal.confirmAction('Essa ação não pode ser revertida!')
        .then((result) => {
          if (result.isConfirmed) {
            const params = {...this.params}
            params.description = this.description
            this.loading = true
            this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
            this.$store.dispatch('bankReconciliationTransaction/postAGroup', params).then((response) => {
              this.$notify({
                type: response.error_type,
                message: response.message,
              });
              this.loading = false
              this.$parent.clearSelecteds()
              this.$parent.init()
              this.closeModal()
            })
          }
        })
    },
    closeModal() {
      this.modal.create = false
    },
    handleCreateModal(params) {
      this.description = null
      this.params = params
      this.modal.create = true
    }
  },
}
</script>

<style scoped></style>
