import { extend } from "vee-validate";
import {verifyDocument} from "../helpers";
extend('document', {
  message: "Document not found",
  validate: value => {
    return verifyDocument(value)
  }
})
/**
 * Valida numerico mínimo
 */
extend('min_numeric', {
  message: "Out min numeric",
  validate: (value, params) => {
    const decimal = Number(params)
    if (Number(value) < decimal) {
      return false
    }
    return true
  }
})
export const rules = {
  document: {
    custom: true
  },
}
