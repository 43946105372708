<template>
  <div>

    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-gray d-inline-block mb-0">
            {{ this.$route.meta.breadcrumbs[2].title }}</h6>
          <nav aria-label="breadcrumb"
               class="d-none d-md-inline-block ml-md-4 text-gray">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i
                :class="this.$route.meta.icon"></i></a></li>
              <li class="breadcrumb-item"><a
                href="#">{{ this.$route.meta.breadcrumbs[0].title }}</a></li>
              <li class="breadcrumb-item"><a
                href="#">{{ this.$route.meta.breadcrumbs[1].title }}</a></li>
              <li class="breadcrumb-item" aria-current="page">
                {{ this.$route.meta.breadcrumbs[2].title }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- Header Menus -->
      <div class="row mb-3">
        <div class="col-md-2 mb-2">
          <router-link :to="{
            path: '/commercial/contract-proposal/formulation',
            name: 'commercial.contract-proposal.formulation',
            params: { contract_proposal_uuid: contract_proposal.uuid}
          }">
            <base-button block size="lg" type="light" class="text-uppercase">
              cancelar
            </base-button>
          </router-link>
        </div>

        <div class="col-md-2 mb-2">
          <router-link :to="{
          path: '/commercial/contract-proposal/services',
          name: 'commercial.contract-proposal.services.view',
          params: { contract_proposal_uuid: contract_proposal.uuid }
          }">
            <base-button block size="lg" type="info" class="text-uppercase">
              serviços
            </base-button>
          </router-link>
        </div>

        <div class="col-md-2 mb-2">
          <base-button block size="lg" type="warning" class="text-uppercase">
            adicionais
          </base-button>
        </div>
        <div class="col-md-2 mb-2">
          <base-button block size="lg" type="default" class="text-uppercase">
            pagamentos
          </base-button>
        </div>
        <div class="col-md-2 mb-2">
          <router-link :to="{
            path: '/commercial/contract-proposal/formulation',
            name: 'commercial.contract-proposal.formulation',
            params: { contract_proposal_uuid: contract_proposal.uuid}
          }">
            <base-button block size="lg" type="light" class="text-uppercase">
              voltar
            </base-button>
          </router-link>
        </div>
        <div class="col-md-2 mb-2">
          <router-link :to="{
          name:'commercial.contract-proposal.index',
          path:'/commercial/contract',
        }">
            <base-button block size="lg" type="light" class="text-uppercase">
              propostas / contratos
            </base-button>
          </router-link>
        </div>
      </div>

      <div>
        <div class="row align-items-center pb-0 mb-3">
          <div class="col-md-12">
            <div class="checklist-item checklist-item-primary">
              <div class="checklist-info">
                <h2 class="checklist-title mb-0">
                  Proposta/Contrato: <span
                  class="small">{{ contract_proposal.code }}</span>
                </h2>
                <h2 class="checklist-title mb-0">
                  Cliente: <span
                  class="small">{{ contract_proposal.construction.customer_construction.customer_name }}</span>
                </h2>
                <h2 class="checklist-title mb-0">
                  Obra: <span
                  class="small">{{ contract_proposal.construction.construction_name }}</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

    </base-header>

    <div class="container-fluid">
      <!-- Start Card Set Price Minimum -->
      <card>
        <!-- Card body -->
        <div>
          <!-- Start Create Concurrent -->
          <div>
            <div class="row d-flex justify-content-center">
              <div class="col-10">
                <div class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                    Concorrente
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-8">
                    <base-input input-classes="form-control-sm">
                      <el-select
                        v-model="concurrent.concurrent_ids"
                        size="mini"
                        filterable
                        placeholder="Selecione"
                        :loading="loadingConcurrent"
                        :disabled="loadingConcurrent"
                      >
                        <el-option v-for="concurrent in concurrents"
                                   :key="concurrent.uuid"
                                   :label="concurrent.name"
                                   :value="concurrent.uuid"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                </div>

                <div class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                    Data
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-8">
                    <base-input input-group-classes="input-group-sm">
                      <el-date-picker
                        v-model="concurrent.date"
                        size="mini"
                        type="date"
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd"
                        block
                      >
                      </el-date-picker>
                    </base-input>
                  </div>
                </div>

                <div class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                    Preço por mínimo
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-5 mb-2">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model="concurrent.price"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0">
                          R$
                        </small>
                      </template>
                    </base-input>
                  </div>
                </div>

                <div class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                    Preço por m<sup>3</sup>
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-5 mb-2">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model="concurrent.price"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0">
                          R$
                        </small>
                      </template>
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          /m<sup>3</sup>
                        </small>
                      </template>
                    </base-input>
                  </div>
                </div>

                <div class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                    Preço por hora
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-5 mb-2">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model="concurrent.price"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0">
                          R$
                        </small>
                      </template>
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          /hora
                        </small>
                      </template>
                    </base-input>
                  </div>
                  <div class="col-md-3">
                    <base-button
                      size="sm"
                      type="success"
                      :disabled="invalid"
                      :loading="loadingSave"
                    >
                      incluir
                    </base-button>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </card>
      <!-- End Card Set Price Minimum -->

      <!-- Start List Concurrent -->
      <card v-if="contract_proposal.service.concurrents.length">
        <!-- Card body -->
        <div class="row d-flex justify-content-center mt-2" v-for="(concurrent, indexConcurrent) in contract_proposal.service.concurrents" :key="indexConcurrent">
          <div class="col-10">
            <div class="row border-left border-3 border-danger">
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-12">
                    <h5 class="h4 mb-0">
                      {{concurrent.concurrent_name}}
                    </h5>
                    <h4 class="mb-0 small mt-1">
                      {{concurrent.by_fixed | currency()}} mínimo
                    </h4>
                    <h4 class="mb-0 small mt-1">
                      {{concurrent.by_meters | currency()}} /m<sup>3</sup>
                    </h4>
                    <h4 class="h4 mb-0 small mt-1">
                      {{concurrent.by_hour | currency()}} /hora
                    </h4>
                  </div>
                </div>

              </div>

              <div class="col-md-2 d-flex justify-content-center align-items-center">
                <span class="h4 text-gray mb-0">
                 {{concurrent.date}}
                </span>
              </div>

              <div class="col-md-1 col-sm-12 d-flex justify-content-center align-items-center">
                <base-button
                  block
                  size="sm"
                  type="danger"
                  @click.prevent="handleDelteConcurrent(concurrent.uuid)"
                >
                  <i class="fa fa-trash text-white"></i>
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </card>
      <!-- End List Concurrent -->
    </div>
  </div>

</template>
<script>
import {mapGetters} from 'vuex'

export default {
  name: 'CreateContractProposalServicesConcurrent',
  components: {
  },
  data() {
    return {
      loadingConcurrent: true,
      loadingSave: false,
      invalid: false,
      concurrent:{
        concurrent_ids: null,
        date: Date(),
        price: null,
      },
      concurrents: [
        {
          id: '1',
          uuid: '2276d54d-7396-44ea-9db6-046133b2dab8',
          name: 'BetoMix',
        },
        {
          id: '2',
          uuid: '24880f62-5fda-46f9-bb4a-eea55925682e',
          name: 'TopCom',
        }
      ],
      contract_proposal: {
        uuid: this.$route.params.contract_proposal_uuid,
        code: 'ABF-1283764',
        created_at: '03-mai-2021',
        status: 1,
        construction: {
          id: 2,
          uuid: '9357c860-cad2-482f-a1ff-cdb4b4bca17e',
          construction_name: 'Obra teste',
          status: true,
          customer_construction: {
            customer_name: 'nome do cliente',
          },
        },
        service: {
          status: 1,
          uuid: '727c4b28-11c6-4ebc-8bf4-0f046266e046',
          price: {
            value: 258.91,
            unit_letter: 'm³',
          },
          plant: {
            uuid: '',
            plant_name: 'Lagoa Santa',
          },
          concurrents: [
            {
              uuid: 'ab99b3d9-7eec-4798-931e-01cba4fd63f3',
              concurrent_name: 'Altec Mix',
              by_fixed: 900.00,
              by_meters: 25.00,
              by_hour: 360.00,
              date: '01/jan/2021',
            }
          ]
        }
      },
    }
  },
  computed: {
  },
  methods: {
    save(){

    },
    handleDelteConcurrent(concurrentUuid){
      let items = this.contract_proposal.mix_design.concurrents
      let index = items.findIndex((item) => item.uuid === concurrentUuid)
      if (index > -1) {
        this.contract_proposal.mix_design.concurrents.splice(index, 1)
      }
    },
  },
  mounted() {
    this.loadingConcurrent = false
  }
}
</script>
