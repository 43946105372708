<template>
  <div>
    <base-header class="bg-gray-content">
      <puzl-breadcrumb/>
    </base-header>
    <list-resume/>
  </div>
</template>

<script>
import ListResume from './Shared/_ListResume'
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
export default {
  name: "Index",
  components : {
    ListResume,
    PuzlBreadcrumb,
  }
}
</script>

<style scoped>

</style>
