<template>
  <div>
    <div class="row card-wrapper" v-if="hardened_states.length && !loadingSkeleton">
      <div class="col-lg-4" v-for="(item, index) in hardened_states" :key="index">
        <div class="card main-card" style="overflow: hidden;border-radius: 20px !important">
          <div class="p-3 px-4">
            <div class="row align-items-start">
              <div class="col-9">
                <div class="row">
                  <div class="col-12">
                    <p class="font-weight-600 new-default-black-font my-0" style="font-size: 16px;">
                      {{ item.contract_proposal_code }}</p>
                  </div>
                  <div class="col-12" v-if="item.schedule">
                    <p class="font-weight-400 new-default-black-font my-0" style="font-size: 14px;">
                      {{ item.schedule.customer }}</p>
                  </div>
                </div>
              </div>
              <div class="col-3 d-flex justify-content-end align-items-end">
                <el-popover v-if="item.reuse_number"
                            :title="setTitleReuse(`${item.reuse_category}${item.reuse_category_id}`)" trigger="click"
                            placement="right" class="p-0 pr-1">
                  <div>
                    <h3 class="text-info pl-1">
                      <small class="text-default"
                      ><img style="margin-left: -4px; margin-top: -12px" width="32px" src="/img/icons/os.png"/>
                        <span class="text-default" style="text-transform: none !important"> n° </span>
                      </small>
                      <b class="money">{{ item.reuse_number }}</b>
                    </h3>
                  </div>
                  <div style="margin-top: -5px !important">
                    <h6 class="h5 new-default-black-font p-1 mb-0 font-weight-400"> Quantidade:
                      <b>{{ item.reuse_volume.replace(".", ",") }} </b>m<sup>3</sup></h6>
                  </div>
                  <div style="margin-top: -5px !important">
                    <div class="row align-items-center">
                      <div class="col-12">
                        <hr class="mt-2 mb-2">
                      </div>
                      <div class="col-2 mt-n3" style="display: flex; align-items: center;">
                       <span style="border: 1px solid #1a70b7;border-radius: 35px;padding: 10px;">
                         <img src="/img/icons/test-tube.png" style="width: 22px;"></span>
                      </div>
                      <div class="col-10">
                        <h6 class="h5 new-default-black-font p-1 mb-0 font-weight-400"> {{ item.reuse_title }} </h6>
                        <h6 class="h5 mt-n2 new-default-black-font p-1 mb-0 font-weight-400">
                          {{ item.reuse_body }} </h6>
                        <h6 class="h5 mt-n2 new-default-black-font p-1 mb-0 font-weight-400">
                          {{ item.reuse_footer }} </h6>
                      </div>
                    </div>
                  </div>
                  <base-button outiline slot="reference" size="sm" type="secundary" class="p-0 m-0 btn-rounded">
                    <i style="font-size: 27px" class="fa-solid fa-recycle"
                       :class="item.reuse_category === 1 ? 'text-success' : 'text-warning'"> </i>
                  </base-button>
                </el-popover>
                <div class="pointer m-0 pr-1 pt-1">
                  <el-popover v-if="item.anomalies_extract" trigger="click" placement="left" class="p-0 pr-0 mr-1">
                    <h5 class="mb-n1" v-for="anomaly in item.anomalies_extract.split(',')">{{ anomaly }}</h5>
                    <img slot="reference" width="30" src="/img/icons/icons8/ios/question--v1.png">
                  </el-popover>
                </div>
                <div class="pointer m-0 pt-1" @click.prevent="$emit('handleEdit', item)">
                  <img width="30" src="/img/icons/create-new.png">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12" v-if="item.schedule">
                <p class="font-weight-400 new-default-gray-font my-0">{{ item.schedule.construction }}</p>
              </div>
            </div>
            <div class="row mt-3 px-2">
              <div class="col p-1 px-2">
                <a class="card-with-box-shadow text-center" style="display: inherit;">
                  <div class="py-2">
                    <img class="mr-1" width="26" src="/img/icons/os.png"/>
                    <b class="new-default-black-font numeric" v-if="item.number">{{ item.number }}</b>
                  </div>
                </a>
              </div>
              <div v-if="item.xml_travel_number" class="col p-1 px-2">
                <a class="card-with-box-shadow text-center" style="display: inherit;">
                  <div class="py-2">
                    <img class="mr-2" width="18" src="/img/icons/nfe.png"/>
                    <b class="new-default-black-font numeric">{{ item.xml_travel_number }}</b>
                  </div>
                </a>
              </div>
              <div class="col p-1 px-2" v-if="item.code">
                <a class="card-with-box-shadow text-center" style="display: inherit;"
                   href="#">
                  <div class="py-2">
                    <img class="mr-2" width="26" src="/img/icons/icons8/ios/source-code.png"/>
                    <b class="new-default-black-font numeric">{{ item.code }}</b>
                  </div>
                </a>
              </div>
            </div>
            <hr class="mt-2"/>
            <!-- LAYOUT MD -->
            <div class="row mt-n4 pt-1 align-items-center">
              <div class="col-6" style="display: flex; align-items: center;">
                <h4 class="m-0 p-0 mr-3" style="font-size: 16px;"
                    v-if="technicalPermissions.is_view_technical_data_of_traces_in_test_results_launch">
                  {{ item.feature }}
                  {{ format_mixe(item.period, item.period_type) }}
                  {{ item.item_description }}
                </h4>
                <div style="display: inline-flex;"
                     :class="item.molder && item.result ? 'mini-card-blue' : item.molder && !item.result ? 'mini-card-yellow' : 'mini-card-red'">
                  <h4 class="font-weight-normal m-0 p-0">
                    {{
                      item.molder && item.result ? 'Ensaiado' : item.molder && !item.result ? 'Pendente' : 'Descartado'
                    }}
                  </h4>
                </div>
              </div>
              <div class="col-6 text-right">
                <el-popover trigger="click" placement="left">
                  <div
                    class="mt-2 border-left border-3 border-indigo p-2"
                  >
                    <div>
                                    <span>
                                      <span class="text-center text-dark">
                                        {{ item.equipment_code }}
                                      </span>
                                      <br/>
                                      <span class="text-center">
                                        {{ item.driver_name }}
                                      </span>
                                    </span>
                    </div>
                  </div>
                  <base-button outline slot="reference" size="sm" type="secundary"
                               class="p-0 m-0 mr-2 shadow-none text-indigo">
                    <img src="/img/icons/interstate-truck.png" width="25">
                  </base-button>
                </el-popover>
                <el-popover trigger="click" placement="left">
                  <span style="width: 290px;"
                        class="row">
                    <div class="col-12 mb-2" style="display: flex; align-items: center;">
                      <img class="mr-2" src="/img/icons/chemical-plant-black.png" width="22">
                      <h4 style="font-size: 14px;" class="p-0 m-0 font-weight-500">Central e ponto de carga</h4>
                    </div>
                    <div class="col-12 mt-0 mb-n2">
                      <h5 class="font-weight-normal">{{ item.plant }}</h5>
                    </div>
                    <div class="col-12">
                      <h5 class="font-weight-normal" v-if="item.charge_point">PC ({{ item.charge_point }})</h5>
                    </div>
                  </span>
                  <base-button outline slot="reference" size="sm" type="secundary"
                               class="p-0 m-0 mr-2 shadow-none text-indigo">
                    <img src="/img/icons/chemical-plant-black.png" width="25">
                  </base-button>
                </el-popover>
                <el-popover trigger="click" placement="bottom">
                  <span style="width: 290px;"
                        class="row">
                    <div class="col-12 mb-3" style="display: flex; align-items: center;">
                      <img class="mr-2" src="/img/icons/icons8/ios/info-circle_warning.png" width="22">
                      <h4 style="font-size: 16px;" class="p-0 m-0">Detalhes</h4>
                    </div>
                      <div class="col-12" v-if="item.user_name">
                      <h5 class="font-weight-normal"><b class="mr-2">Responsável:</b> {{ item.user_name }}</h5>
                    </div>
                    <div class="col-12">
                      <h5 class="font-weight-normal"><b class="mr-2">M.E:</b>{{ item.me }} KG/M3</h5>
                    </div>
                    <div class="col-12">
                      <h5 class="font-weight-normal"><b
                        class="mr-2">Cura:</b>{{ item.health ? healths[item.health] : '-' }}</h5>
                    </div>
                    <div class="col-12">
                      <h5 class="font-weight-normal"><b class="mr-2">Amostragem:</b>{{
                          item.molding_location_name
                        }}</h5>
                    </div>
                    <div class="col-12">
                      <h5 class="font-weight-normal"><b class="mr-2">Base inferior:</b>
                        <i style="font-style: normal;" v-if="item.bottom_base"
                           v-for="(base, key) in item.bottom_base">
                          {{ base }} <span v-if="item.bottom_base.length -1 != key">+</span>
                        </i>
                        <i style="font-style: normal;" v-if="item.bottom_base < 1">-</i>
                      </h5>
                    </div>
                    <div class="col-12">
                      <h5 class="font-weight-normal"><b class="mr-2">Base superior:</b>
                        <i style="font-style: normal;" v-for="(base, key) in item.top_base">
                          {{ base }} <span v-if="item.top_base.length -1 != key">+</span>
                        </i>
                        <i style="font-style: normal;" v-if="item.top_base < 1"> - </i>
                      </h5>
                    </div>
                    <div class="col-12">
                      <h5 class="font-weight-normal"><b class="mr-2">Padrão de rompimento:</b>
                        {{ item.disruption ? disruptions.find(disrupt => disrupt.id === item.disruption).text : '-' }}
                      </h5>
                    </div>
                    <div class="col-12">
                      <h5 class="font-weight-normal"><b
                        class="mr-2">Observações:</b>{{ item.observation ? item.observation : '-' }}</h5>
                    </div>
                  </span>
                  <base-button outline slot="reference" size="sm" type="secundary"
                               class="p-0 m-0 mr-2 shadow-none text-indigo">
                    <img src="/img/icons/icons8/ios/info-circle_warning.png" width="25">
                  </base-button>
                </el-popover>
                <el-popover
                  class="p-0"
                  placement="right"
                  trigger="click">
                  <div v-if="!technicalPermissions.is_view_technical_data_of_traces_in_test_results_launch"
                       style="width: 170px; word-break: break-word;">
                    <h5 class="text-danger">
                      Sem permissão
                    </h5>
                    <div class="new-default-black-font" style="font-size: 12px;">
                      Visualização indisponível, solicite permissão ao gestor.
                    </div>
                  </div>
                  <div v-if="technicalPermissions.is_view_technical_data_of_traces_in_test_results_launch"
                       class="border-left border-3 border-primary p-2 mt-1">
                    <h5 class="h4 mb-0">
                      {{ item.schedule.contract_proposal_formulation.title }}
                    </h5>
                    <div class="mt-2 mb-3">
                      <h5 class="small">
                        {{ item.schedule.contract_proposal_formulation.body }}
                      </h5>
                    </div>
                    <div class="mt-2 mb-3" v-if="item.schedule.contract_proposal_formulation.footer">
                      <h5 class="small">
                        {{ item.schedule.contract_proposal_formulation.footer }}
                      </h5>
                    </div>
                    <div class="mt-2 mb-3" v-if="item.schedule.level_feature">
                      <h5 class="small" v-if="item.schedule.level_feature === 1">
                        Aplicação Baixa
                      </h5>
                      <h5 class="small" v-else-if="item.schedule.level_feature === 2">
                        Aplicação Média
                      </h5>
                      <h5 class="small" v-else-if="item.schedule.level_feature === 3">
                        Aplicação Alta
                      </h5>
                    </div>
                  </div>
                  <div v-if="technicalPermissions.is_view_technical_data_of_traces_in_test_results_launch"
                       class="border-left border-3 border-info p-1 mt-2">
                    <span style="font-size: 12px;" class="numeric m-0 pl-1">
                      {{ item.schedule.piece }}
                    </span>
                  </div>
                  <base-button outline slot="reference" size="sm" type="secundary"
                               class="p-0 m-0 mr-2 shadow-none text-indigo">
                    <img src="/img/icons/test-tube.png" width="25">
                  </base-button>
                </el-popover>
                <el-popover trigger="click" placement="left">
                  <div
                    class="mt-2 p-2"
                  >
                    <div>
                                    <span>
                                       <template v-if="item.equipment_integrated_id">
                                         <span class="text-center text-primary font-weight-600">
                                        RESULTADO IMPORTADO
                                      </span>
                                      <br/>
                                      </template>
                                      <span class="text-center text-dark">
                                        {{ item.updated_user_name }}
                                      </span>
                                      <br/>
                                      <span class="text-center">
                                        {{ item.updated_at | parseDate }}
                                      </span>
                                    </span>
                    </div>
                  </div>
                  <base-button v-if="item.updated_user_name" outline slot="reference" size="sm" type="secundary"
                               class="p-0 m-0 mr-2 shadow-none text-indigo">
                    <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="25">
                  </base-button>
                </el-popover>
              </div>
            </div>
            <div class="row mt-4 align-items-center">
              <div class="col-xl-6" v-if="item.provided_at">
                <div class="card grid-card">
                  <div style="display: inline-grid; align-items: center;">
                    <div class="p-2">
                      <h4 class="new-default-black-font m-0">Ensaio</h4>
                      <h6 class="new-default-gray-font m-0 font-weight-normal" style="font-size: 12px;">
                        {{ item.provided_at | parseDate }}</h6>
                    </div>
                  </div>
                  <div class="text-center gray-bg new-default-black-font font-weight-500"
                       style="display: inline-grid; align-items: center;">
                    <div>
                      {{ format_mixe(item.schedule_period, item.schedule_period_type, true) }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div v-if="item.molder" class="new-default-black-font card grid-card ">
                  <div style="display: inline-grid; align-items: center;">
                    <div class="p-2">
                      <h4 class="text-primary m-0 p-0">Resultado</h4>
                      <h6 v-if="item.date_at" class="new-default-gray-font m-0 p-0 font-weight-normal"
                          style="font-size: 9px;">
                        {{ item.date_at | parseDate }}
                      </h6>
                      <h6 v-if="item.real_period" class="new-default-gray-font m-0 p-0 mt-n1 font-weight-normal"
                          style="font-size: 9px;">
                        {{ formatPeriod(item.real_period) }}
                      </h6>
                    </div>
                  </div>
                  <div class="text-center blue-bg font-weight-500" style="display: inline-grid; align-items: center;">
                    <div>
                      <h3 class="text-primary m-0 p-0" v-if="item.result">
                        {{ item.result.toString().replace('.', ',') }}
                        {{ item.letter }}
                      </h3>
                      <h3 v-else class="invisible-text m-0 p-0">
                        s/ result
                      </h3>
                    </div>
                  </div>
                </div>
                <div v-if="!item.molder" style="min-height: 70px; max-height: 70px;"
                     class="new-default-black-font card red-bg">
                  <div class="pl-3 pt-3 py-3 px-1">
                    <h4 class="text-danger m-0 p-0">Motivo</h4>
                    <h6 class="new-default-black-font m-0 p-0 font-weight-normal" style="font-size: 10px;">
                      {{ item.reason }}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {period_types} from "@/views/Modules/Technology/Mix/helpers/mixe";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";

export default {
  name: "CardsTests",
  mixins: [cursorPaginate],
  props: {
    hardened_states: Array,
    loadingSkeleton: Boolean,
    technicalPermissions: Object,
  },
  components: {
    LoadingPagination,
  },
  data() {
    return {
      disruptions: [
        {
          id: 'A',
          text: 'Cônica e cônica afastada 25mm do capeamento'
        },
        {
          id: 'B',
          text: 'Cônica e bipartida e cônica e com mais de uma partição'
        },
        {
          id: 'C',
          text: 'Colunar com formação de cones'
        },
        {
          id: 'D',
          text: 'Cônica e cisalhada'
        },
        {
          id: 'E',
          text: 'Cisalhada'
        },
        {
          id: 'F',
          text: 'Fraturas no topo e/ou na base abaixo do capeamento'
        },
        {
          id: 'G',
          text: 'Similar ao tipo f com fraturas próximas ao topo'
        },
      ],
      healths: [
        'Total',
        'Parcial',
        'Nenhuma'
      ],
      format_mixe: period_types,
      categories: {
        11: "Reaproveitamento - Reuso",
        12: "Redirecionamento - Reuso",
        21: "Sobra de Concreto - descarte",
        22: "Perda de Concreto - descarte",
      },
    };
  },
  methods: {
    setTitleReuse(type) {
      return this.categories[Number(type)];
    },
    formatPeriod(period) {
      const date_splited = period.split(' ')
      for (let date of date_splited) {
        if (date.includes('d')) {
          date = date.replace('d', '')
          return Math.round(date) > 1 ? `${date} dias` : `${date} dia`
        }
        if (date.includes('h')) {
          date = date.replace('h', '')
          return date > 1 ? `${date} horas` : `${date} hora`
        }
        if (date.includes('m')) {
          date = date.replace('min', '')
          date = date.replace('m', '')
          date = Math.round(date) / 60
          if (date < 1) {
            date = Math.round(date * 60)
            return `${date} minutos`
          }
          return date > 1 ? `${date} horas` : `${date} hora`
        }
      }
    },
  },
};
</script>

<style scoped>
.new-default-gray-font {
  color: #606062;
}

.card-with-box-shadow {
  box-shadow: 0 2px 4px 0 #f3f3f3;
  border: 1px solid #E8E8E8;
  box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15);
  border-radius: 10px !important;
}

.mini-card-green {
  background-color: #8bd5af52;
  border-radius: 5px;
  padding: 1px 5px;
  font-size: 12px;
  box-shadow: 0 0 5px 0 #149e5752;
}

.mini-card-red {
  background-color: #f459592e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #d2272752;
}

.mini-card-yellow {
  background-color: #f4dc592e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #fdef0b52;
}

.mini-card-blue {
  background-color: #4182e52e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #0b7bfd52;
}

.mini-card-gray {
  background-color: #646e7d2e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #5d636a52;
}

.mini-card-green h4 {
  color: #149e57;
}

.mini-card-red h4 {
  color: #db4539;
}

.mini-card-yellow h4 {
  color: #f2b532;
}

.mini-card-blue h4 {
  color: #1b6eba;
}

.mini-card-gray h4 {
  color: #66696c;
}

.mini-card-red h4, .mini-card-green h4, .mini-card-yellow h4, .mini-card-blue h4, .mini-card-gray h4 {
  font-size: 12px;
}

.grid-card {
  display: grid;
  grid-template-columns: 60% 40%;
  overflow: hidden;
  height: 70px;
  margin-bottom: 10px;
}

.gray-bg {
  background-color: #e6e6e6;
  box-shadow: 0 0 5px 0 #6a6a6a52;;
}

.blue-bg {
  background-color: #4182e52e;
  box-shadow: 0 0 5px 0 #0b7bfd52;
}

.red-bg {
  background-color: #f459592e;
  box-shadow: 0 0 5px 0 #d2272752;
}

.invisible-text {
  color: rgba(0, 0, 0, 0);
}
</style>
