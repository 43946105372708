<template>
  <div>
    <div class="container-fluid">
      <multi-filter
        :showSlotDates="false"
        :showSlotData="false"
        :showSlotBookmark="false"
        :showSlotStatus="false"
        :hiddenFilterButton="true"
        :type="3"
        @fetch="init"
      />

      <div class="row card-wrapper mt-3" v-show="loadingSkeleton">
        <!-- Skeleton de Carregamento -->
        <SkeletonPuzlGrid v-for="index in 3" :key="index" />
      </div>

      <!-- Nenhuma informação encontrada -->
      <PuzlEmptyData v-if="!$_items.length && !loadingSkeleton"></PuzlEmptyData>

      <!-- Lista de Cards -->
      <div
        class="row card-wrapper mt-3"
        v-if="$_items.length && !loadingSkeleton"
      >
        <div class="col-lg-4" v-for="(item, index) in $_items" :key="index">
          <card>
            <!-- Banco -->
            <div
              class="row md-12 pl-2 mt-n0 mb-2 d-flex justify-content-between"
            >
              <div class="closing-history d-flex align-items-center">
                <img
                  src="/img/icons/no-credit-cards.png"
                  width="16px"
                  height="16px"
                /><span
                  class="d-flex align-items-center justify-content-center"
                  style="font-size: 12px"
                  >fechado até:
                  {{
                    item.account_closing_history_closure
                      | parseDate("DD/MM/YYYY")
                  }}</span
                >
              </div>
              <base-dropdown menuOnRight>
                <div slot="title-container" class="dropdown-toggle rounded m-0">
                  <img
                    width="32"
                    src="/img/icons/icons8/ios/settings--v1_primary.png"
                  />
                </div>
                <a
                  class="dropdown-item"
                  style="display: flex; align-items: center"
                  @click.prevent="handleModalBankAccountSettings(item.id)"
                >
                  <img src="/img/icons/gear.png" width="22" alt="" />
                  <span class="text-right">Configurações</span>
                </a>
                <div class="dropdown-divider p-0 m-0"></div>
                <a
                  class="dropdown-item"
                  style="display: flex; align-items: center"
                  @click.prevent="handleModalAccountClosingHistory(item.id)"
                >
                  <img
                    src="/img/icons/no-credit-cards-blue.png"
                    width="22"
                    alt=""
                  />
                  <span class="text-right">Fechar conta</span>
                </a>
              </base-dropdown>
            </div>
            <div class="row align-items-center mt-n2">
              <div class="col-10">
                <small
                  class="d-flex mt-3"
                  style="font-size: 16px; color: #2b2d32"
                >
                  <img
                    width="19px"
                    height="19px"
                    class="mr-1"
                    :src="'/img/icons/banks/' + item.code + '.png'"
                  />
                  <b>{{ item.name }}</b>
                </small>
                <h3 class="mt-0">
                  <small class="bank-information">
                    Agência: {{ item.number_without_digit_agency }}-{{
                      item.digit_agency
                    }}
                  </small>
                </h3>
                <h3 class="mt-n3 mb-3">
                  <small class="bank-information">
                    Conta: {{ item.number_without_digit_account }}-{{
                      item.digit_account
                    }}
                  </small>
                </h3>
              </div>
            </div>
            <div class="row align-items-center">
              <div
                class="col-md-12 text-primary text-center"
                style="padding-right: 7px"
              >
                <div
                  class="card mb-0 d-flex align-items-center justify-content-center"
                  style="min-height: 59.5px"
                >
                  <h1>
                    <small
                      style="font-size: 13px; font-weight: bold !important"
                      class="numeric font-weight-600"
                      :class="balanceClass(item.calculated_balance)"
                    >
                      <b>{{
                        item.calculated_balance
                          ? item.calculated_balance
                          : 0 | currency()
                      }}</b>
                    </small>
                  </h1>
                  <h2 class="mt-n4">
                    <span
                      class="numeric text-dark font-weight-400"
                      style="font-size: 10px !important; font-family: Fredoka"
                      >Saldo atual</span
                    >
                  </h2>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
    <ModalBankAccountSettings ref="modalBankAccountSettings" />
    <ModalAccountClosingHistory ref="modalAccountClosingHistory" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import ModalBankAccountSettings from "@/views/Modules/Financial/CashFlow/BankAccount/Shared/_ModalBankAccountSettings";
import ModalAccountClosingHistory from "./_ModalAccountClosingHistory";
import MultiFilter from "@/components/Utils/MultiFilterV3";

export default {
  name: "List",
  components: {
    PuzlEmptyData,
    SkeletonPuzlGrid,
    ModalBankAccountSettings,
    ModalAccountClosingHistory,
    MultiFilter,
  },
  data() {
    return {
      interval_id: null,
      loadingSkeleton: true,
      filter: {},
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      $_items: "bankAccount/fetch",
    }),
  },
  methods: {
    balanceClass(value) {
      const val = Number(value);
      if (val === 0.0) {
        return "text-grey2";
      }
      if (val > 0) {
        return "text-success";
      } else {
        return "text-danger";
      }
    },
    predictedClass(value) {
      const val = Number(value);
      if (val === 0.0) {
        return "text-default";
      }
      if (val > 0) {
        return "text-success";
      } else {
        return "text-danger";
      }
    },
    init(filter = {}) {
      this.filter = filter;
      this.filter.has_allocated_plant = true;
      this.loadingSkeleton = true;
      this.$store
        .dispatch("bankAccount/fetchItems", {
          filter: {...this.filter, status: true},
        })
        .then((response) => {
          this.loadingSkeleton = false;
        })
        .catch((error) => {
          this.loadingSkeleton = false;
        });
    },
    /**
     * Abre modal de configurações da conta bancária.
     * @param {number} id
     */
    handleModalBankAccountSettings(id) {
      this.$refs.modalBankAccountSettings.openModal(id);
    },
    /**
     * @param {number} id
     */
    handleModalAccountClosingHistory(id) {
      this.$refs.modalAccountClosingHistory.openModal(id);
    },
  },
};
</script>

<style scoped>
div.closing-history {
  border-radius: 16px;
  background: #e8e8e8;
  gap: 8px;
  height: 24px;
  padding: 8px;
}
div.closing-history > span {
  text-align: center;
  color: #606062;
  font-family: "Fredoka";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
h3 > span.bank-information {
  color: #606062;
  font-family: "Fredoka";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.blue-divider {
  background-color: #1b6eba;
  color: #1b6eba;
  height: 0.1px;
}
.card {
  border-radius: 10px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
}

.card-blue-bottom {
  border-top-left-radius: 35px;
  border-bottom-right-radius: 10px;
  background-color: #1b6eba;
  color: white;
  max-width: 180px;
  min-width: 180px;
  float: right;
}

.white-image img {
  filter: brightness(0) invert(1);
}
</style>
