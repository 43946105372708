<template>
  <div>
    <modal size="md" :useRerender="false" class="bill-modal-create" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <div v-if="payload.bill_receive.type === null">
          <div class="form-group row m-2 p-0 pl-1 mb-3">
            <div class="col-md-6 mb-1">
              <base-button
                @click.prevent="initContractProposal()"
                type="success"
                outline
                size="md"
                style=""
                block
                :class="payload.bill_receive.type === 1 ? 'active' : ''"
              >
                Proposta/Contrato
              </base-button>
            </div>
            <div class="col-md-6">
              <base-button
                @click.prevent="initEntity"
                type="primary"
                outline
                size="md"
                style=""
                block
                :class="payload.bill_receive.type === 0 ? 'active' : ''"
              >
                Tomador
              </base-button>
            </div>
          </div>
          <div v-if="loadingSpinner" class="mt-4 mb-3 row">
            <SpinnerPuzl/>
          </div>
          <div class="modal-footer">
            <base-button type="secondary" @click="closeModal('create')">
              Cancelar
            </base-button>
          </div>
        </div>
        <div v-if="payload.bill_receive.type === 1">
          <div
            class="border-left border-3 border-primary pr-1 mt-0 mb-2"
            v-if="contract_proposal"
          >
            <div class="col-md-12">
             <span class="display-5 mb-0">
                    <b>
                     {{
                        (contract_proposal &&
                          contract_proposal.construction &&
                          contract_proposal.construction.construction_name) ||
                        ""
                      }}
                    </b>
                  </span>
              <br>
              <span class="display-5 mb-0">
                    <b>
                       {{
                        (contract_proposal &&
                          contract_proposal.construction &&
                          contract_proposal.construction.customer_construction &&
                          contract_proposal.construction.customer_construction
                            .customer_name) ||
                        ""
                      }}
                    </b>
                  </span>
              <br>
              <span class="display-5 mb-0">
                    <b>
                     {{
                        (contract_proposal &&
                          contract_proposal.service_receiver &&
                          contract_proposal.service_receiver.document) ||
                        ""
                      }}
                    </b>
                  </span>
            </div>
          </div>
          <div class="row mb-2 m-0 p-0">
            <div class="col-md-3 mb-1 p-1">
              <base-button size="sm"
                           :class="type === 1 && 'active'"
                           @click.prevent="type = 1" block
                           outline
                           type="primary">Pagamento
              </base-button>
            </div>
            <div class="col-md-3 mb-1 p-1">
              <base-button size="sm"
                           :class="type === 2 && 'active'"
                           @click.prevent="type = 2" block outline
                           type="primary">Faturas
              </base-button>
            </div>
            <div class="col-md-3 p-1">
              <base-button size="sm"
                           :class="type === 3 && 'active'"
                           :disabled="payload.installments.length < 1"
                           @click.prevent="type = 3" block outline
                           type="primary">Parcelas
              </base-button>
            </div>
            <div class="col-md-3 p-1">
              <base-button size="sm"
                           :class="type === 4 && 'active'"
                           @click.prevent="type = 4" block outline
                           type="primary">Anexos
              </base-button>
            </div>
          </div>
          <validation-observer
            v-slot="{ invalid, handleSubmit }"
            ref="formValidator"
          >
            <form
              class="needs-validation"
              @submit.prevent="handleSubmit(store)"
              autocomplete="off"
            >
              <div>
                <section v-show="type == 1">
                  <div class="form-group row m-0 p-0 mb-1 limited-row">
                    <label
                      class="col-md-6 pb-1 pt-2  col-form-label form-control-label"
                    >
                      Competência
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <validation-provider rules="required">
                        <base-input input-group-classes="input-group-sm">
                          <input-date-time-picker
                            @input="generateInstallments(0,false,true)"
                            :disabled="hasInstallmentPaid || payload.bill_receive.any_has_billet"
                            v-model="payload.bill_receive.competence"
                            :mode="'date'"/>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-2 limited-row">
                    <label
                      class="col-md-6 pt-2 pb-1 col-form-label form-control-label"
                    >
                      Valor total de crédito
                      <span class="text-danger">&nbsp;*&nbsp;</span>
                    </label>
                    <div class="col-md-6">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          disabled
                          @blur="generateInstallments(1, true)"
                          v-money="money"
                          @keydown="$event.key === '-' ? $event.preventDefault() : null"
                          maxlength="13"
                          inputmode="numeric"
                          v-model.lazy="payload.bill_receive.total_value"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <template slot="prepend">
                          <small class="input-group-sm p-0 m-0">
                            R$
                          </small>
                        </template>
                      </base-input>
                    </div>
                  </div>
                  <!--                  <div class="form-group row m-0 p-0 mb-2 limited-row">-->
                  <!--                    <label-->
                  <!--                      class="col-md-6 pt-2 pb-1 col-form-label form-control-label"-->
                  <!--                    >-->
                  <!--                      Retenção ISS-->
                  <!--                    </label>-->
                  <!--                    <div class="col-md-6">-->
                  <!--                      <base-input input-group-classes="input-group-sm">-->
                  <!--                        <input-->
                  <!--                          disabled-->
                  <!--                          @blur="generateInstallments(1, true)"-->
                  <!--                          v-money="money"-->
                  <!--                          @keydown="$event.key === '-' ? $event.preventDefault() : null"-->
                  <!--                          maxlength="10"-->
                  <!--                          inputmode="numeric"-->
                  <!--                          v-model.lazy="payload.bill_receive.iss_retain"-->
                  <!--                          type="text"-->
                  <!--                          class="form-control form-control-sm"-->
                  <!--                        />-->
                  <!--                        <template slot="prepend">-->
                  <!--                          <small class="input-group-sm p-0 m-0">-->
                  <!--                            R$-->
                  <!--                          </small>-->
                  <!--                        </template>-->
                  <!--                      </base-input>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <div class="form-group row m-0 p-0 mb-2 limited-row">
                    <label
                      class="col-md-6  pt-2 pb-0 col-form-label form-control-label"
                    >
                      Valor final a receber
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          disabled
                          v-money="money"
                          @keydown="$event.key === '-' ? $event.preventDefault() : null"
                          maxlength="13"
                          inputmode="numeric"
                          v-model.lazy="payload.bill_receive.final_value"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <template slot="prepend">
                          <small class="input-group-sm p-0 m-0">
                            R$
                          </small>
                        </template>
                      </base-input>
                    </div>
                  </div>

                  <div class="card-header mt-n4 mb-3"></div>

                  <div class="form-group row m-0 p-0 mb-2 limited-row">
                    <label
                      class="col-md-6 pt-2 pb-1 col-form-label form-control-label"
                    >
                      Método de pagamento
                      <span class="text-danger">&nbsp;*&nbsp;</span>
                    </label>
                    <div class="col-md-6">
                      <validation-provider rules="required">
                        <base-input input-classes="form-control-sm">
                          <el-select
                            @change="getPaymentTerms(true)"
                            @input="setRequireNsuCode()"
                            v-model="payload.bill_receive.payment_method_uuid"
                            size="mini"
                            placeholder="Selecione"
                            filterable
                            :loading="loadingPlant"
                            :disabled="hasInstallmentPaid ||
                          loadingPlant || payload.bill_receive.total_value === '0,00' || payload.bill_receive.any_has_billet
                        "
                          >
                            <el-option
                              v-for="method in payment_methods_selected"
                              :key="method.uuid"
                              :label="method.name"
                              :value="method.uuid"
                            >
                            </el-option>
                          </el-select>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-2 limited-row">
                    <label
                      class="col-md-6 pt-2 pb-1 col-form-label form-control-label"
                    >
                      Condição de pagamento
                      <span class="text-danger" v-show="hasInstallmentPaid">&nbsp;*&nbsp;</span>
                    </label>
                    <div class="col-md-6">
                      <validation-provider rules="required">
                        <base-input input-classes="form-control-sm">
                          <el-select
                            @change="generateInstallments(0,false,true)"
                            v-model="payload.bill_receive.payment_term_uuid"
                            size="mini"
                            placeholder="Selecione"
                            filterable
                            :disabled="
                            hasInstallmentPaid ||
                          loadingPlant || payload.bill_receive.total_value === '0,00' || !payload.bill_receive.payment_method_uuid ||  payload.bill_receive.any_has_billet"
                          >
                            <el-option
                              v-for="term in payment_terms_selected"
                              :key="term.uuid"
                              :label="term.name"
                              :value="term.uuid"
                            >
                            </el-option>
                          </el-select>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <!--                  <div class="form-group row m-0 p-0 mb-2 limited-row">-->
                  <!--                    <label-->
                  <!--                      class="col-md-6 pt-2 pb-1 col-form-label form-control-label"-->
                  <!--                    >-->
                  <!--                      Mediador de pagamento-->
                  <!--                      <span class="text-danger" v-show="payment_intermediaries_exists">&nbsp;*&nbsp;</span>-->
                  <!--                    </label>-->
                  <!--                    <div class="col-md-6">-->
                  <!--                      <validation-provider-->
                  <!--                        :rules="(payment_intermediaries_exists) && 'required' || ''">-->
                  <!--                        <base-input input-classes="form-control-sm">-->
                  <!--                          <PuzlSelect-->
                  <!--                            placeholder="Nenhum"-->
                  <!--                            @input="generateInstallments(0,false,true)"-->
                  <!--                            @change="getPaymentIntermediaryMerchants()"-->
                  <!--                            :items="payment_intemerdiaries_selected"-->
                  <!--                            :disabled="hasInstallmentPaid || loadingPaymentIntermediaries || !payload.bill_receive.payment_method_uuid ||  payload.bill_receive.any_has_billet"-->
                  <!--                            v-model="payload.bill_receive.payment_intermediary_id"-->
                  <!--                          />-->
                  <!--                        </base-input>-->
                  <!--                      </validation-provider>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <!--                  <div-->
                  <!--                    v-if="isDisplayPaymentIntermediaryMerchants(payload.bill_receive.payment_intermediary_id)"-->
                  <!--                    class="form-group row m-0 p-0 mt-1 mb-2 limited-row"-->
                  <!--                  >-->
                  <!--                    <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label">-->
                  <!--                      Ponto de Venda (PV)-->
                  <!--                      <span class="text-danger">&nbsp;*&nbsp;</span>-->
                  <!--                    </label>-->
                  <!--                    <div class="col-md-6">-->
                  <!--                      <base-input input-classes="form-control-sm">-->
                  <!--                        <PuzlSelect-->
                  <!--                          placeholder="Nenhum"-->
                  <!--                          :items="payment_intermediary_merchants"-->
                  <!--                          label="description"-->
                  <!--                          v-model="payload.bill_receive.payment_intermediary_merchant_id"-->
                  <!--                        />-->
                  <!--                      </base-input>-->
                  <!--                    </div>-->
                  <!--                  </div>-->

                  <!--                  <div class="form-group row m-0 p-0 mb-2 limited-row">-->
                  <!--                    <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label">-->
                  <!--                      NSU da transação-->
                  <!--                      <span-->
                  <!--                        class="text-danger"-->
                  <!--                        v-show="require_nsu_code"-->
                  <!--                        >&nbsp;*&nbsp;</span-->
                  <!--                      >-->
                  <!--                      <span v-if="-->
                  <!--                        modalCardIntegrations &&-->
                  <!--                        payload.bill_receive.payment_intermediary_id &&-->
                  <!--                        payload.bill_receive.payment_intermediary_merchant_id &&-->
                  <!--                        (payload.bill_receive.payment_method_uuid === '947bf91c-abd0-43d4-a0b7-97a50a481cdd' ||-->
                  <!--                        payload.bill_receive.payment_method_uuid === '947bf924-efbd-4263-809a-b2105f6674e2')"-->
                  <!--                      >-->
                  <!--                        &lt;!&ndash; <a href="javascript:void(0)"-->
                  <!--                          @click.prevent="handleCreateModalCardIntegration"-->
                  <!--                          class="fa-regular fa-credit-card text-success fa-xl">-->
                  <!--                        </a> &ndash;&gt;-->
                  <!--                      </span>-->
                  <!--                    </label>-->
                  <!--                    <div class="col-md-6">-->
                  <!--                      <validation-provider-->
                  <!--                        :rules="(require_nsu_code && 'required') || ''"-->
                  <!--                      >-->
                  <!--                        <base-input input-classes="form-control-sm">-->
                  <!--                          <input-->
                  <!--                            inputmode="numeric"-->
                  <!--                            v-mask="'####################'"-->
                  <!--                            v-model="payload.bill_receive.nsu_code"-->
                  <!--                            class="form-control form-control-sm"-->
                  <!--                          />-->
                  <!--                        </base-input>-->
                  <!--                      </validation-provider>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <div class="card-header mt-n4 mb-3"></div>

                  <div class="form-group row m-0 p-0 mb-2 limited-row">
                    <label
                      class="col-md-6  pt-2 pb-0 col-form-label form-control-label"
                    >
                      Central
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <base-input input-group-classes="input-group-sm">
                        <el-select
                          @input="changeBankAccount()"
                          @change="setInvoice()"
                          :disabled="hasInstallmentPaid || payload.bill_receive.any_has_billet || payload.invoices.length > 0"
                          v-model="payload.bill_receive.company_plant_id"
                          size="mini"
                          placeholder="Selecione"
                          filterable
                          :loading="loadingCompanyPlants"
                        >
                          <el-option
                            v-for="(item, index) in $_company_plants"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-2 limited-row">
                    <label
                      class="col-md-6  pt-2 pb-0 col-form-label form-control-label"
                    >
                      CNPJ Beneficiário
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <base-input input-group-classes="input-group-sm">
                        <el-select
                          @change="setInvoice()"
                          :disabled="hasInstallmentPaid || payload.bill_receive.any_has_billet || payload.invoices.length > 0"
                          v-model="payload.bill_receive.company_plant_issuer_id"
                          size="mini"
                          placeholder="Selecione"
                          filterable
                          :loading="loadingCompanyPlantIssuers"
                        >
                          <el-option
                            v-for="(item, index) in company_plant_issuers"
                            :key="index"
                            :label="item.alias_name"
                            :value="item.id"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-2 limited-row">
                    <label
                      class="col-md-6 pt-2 pb-1 col-form-label form-control-label"
                    >
                      Conta bancária de crédito
                      <span class="text-danger">&nbsp;*&nbsp;</span>
                    </label>
                    <div class="col-md-6">
                      <validation-provider
                        rules="required">
                        <base-input input-classes="form-control-sm">
                          <el-select
                            v-model="payload.bill_receive.bank_account_id"
                            size="mini"
                            placeholder="Selecione"
                            filterable
                            :disabled="
                          loadingBankAccounts || hasInstallmentPaid || payload.bill_receive.any_has_billet || payload.bill_receive.has_any_history"
                          >
                            <el-option
                              v-for="item in bank_accounts"
                              :key="item.bank_account.id"
                              :label="formatAccountLabel(item.bank_account)"
                              :value="item.bank_account.id"
                            >
                            </el-option>
                          </el-select>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0 ">
                    <label
                      class="col-md-6 pt-2 pb-1 col-form-label form-control-label"
                    >
                      Observações
                    </label>
                    <div class="col-md-6">
                      <base-input input-group-classes="input-group-sm">
                          <textarea
                            v-model="payload.bill_receive.observations"
                            rows="3"
                            type="text"
                            class="form-control form-control-sm"
                          />
                      </base-input>
                    </div>
                  </div>
                </section>
                <section v-show="type == 2">
                  <div class="form-group row m-0 p-0 mb-1">
                    <label
                      class="col-md-12 pt-2 pb-1 col-form-label form-control-label"
                    >
                      Faturas
                    </label>
                    <div class="col-md-12">
                      <base-input input-classes="form-control-sm">
                        <el-select
                          @change="calcInvoicedAmount()"
                          disabled
                          v-model="payload.invoices"
                          size="mini"
                          placeholder="Selecione"
                          filterable
                          multiple
                        >
                          <el-option
                            v-for="invoice in invoices"
                            :key="invoice.id"
                            :label="`Fatura ${invoice.id} (${invoice.pending_to_paid.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} /
                          ${invoice.total.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})})`"
                            :value="invoice.id"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-2 limited-row">
                    <label
                      class="col-md-6  pt-2 pb-0 col-form-label form-control-label"
                    >
                      Valor faturado
                    </label>
                    <div class="col-md-6">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          disabled
                          v-money="money"
                          maxlength="13"
                          inputmode="numeric"
                          v-model="invoiced_amount"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <template slot="prepend">
                          <small class="input-group-sm p-0 m-0">
                            R$
                          </small>
                        </template>
                      </base-input>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-1 limited-row">
                    <label
                      class="col-md-6  pt-2 pb-0 col-form-label form-control-label"
                    >
                      Saldo
                    </label>
                    <div class="col-md-6">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          disabled
                          v-money="money"
                          maxlength="13"
                          inputmode="numeric"
                          v-model.lazy="balance"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <template slot="prepend">
                          <small class="input-group-sm p-0 m-0">
                            R$
                          </small>
                        </template>
                      </base-input>
                    </div>
                  </div>
                </section>
                <section v-show="type == 3">
                  <div v-if="payload.installments.length">
                    <div class="border-header-danger-bottom mb-2 mt-3">
                      <h5 class="h3 p-1 mb-0">Pagamentos</h5>
                    </div>
                    <div class="row pr-3" v-if="has_changed_installment">
                      <div class="col-md-12 mb-2 text-right float-right">
                        <base-button @click.prevente="resetInstallments" size="sm">
                          <i class="fa-solid fa-window-restore"></i> Restaurar parcelas
                        </base-button>
                      </div>
                    </div>
                    <!-- Start Orders-->
                    <div
                      v-for="(installment,
                    indexInstallment) in payload.installments"
                      :key="installment.index"
                    >
                      <div
                        class="form-group row m-0  mb-1 limited-row"
                        :class="installment.index > 1 ? 'mt-4' : ''"
                      >
                        <label class="col-md-6 pb-0 mt-n2 col-form-label form-control-label">
                          <h5 class="h4  mb-1">
                            Parcela {{ installment.index }}
                            <a
                              v-show="[0, 2].includes(installment.status) && $hasPermission('additional_permissions.financial_approvation')"
                              href="#"
                              @click.prevent="showModalShareInstallment(installment)"
                              class="avatar avatar-sm rounded-circle bg-success">
                              <i class="text-white fa-solid fa-arrows-split-up-and-left"></i>
                            </a>
                            <a
                              v-show="[0, 2, 8].includes(installment.status) && $hasPermission('additional_permissions.financial_approvation')"
                              href="#"
                              @click.prevent="showModalEntityPayment(installment)"
                              class="avatar ml-1 avatar-sm rounded-circle bg-indigo">
                              <i class="text-white fa-solid fa-dollar-sign"></i>
                            </a>
                          </h5>
                        </label>
                        <div class="col-md-6">
                          <!--                          <base-dropdown class="col-md-12 p-0 m-0">-->
                          <base-button
                            disabled
                            slot="title-container"
                            block
                            size="sm"
                            :class="[Number(installment.status) === 0 && 'text-white']"
                            :type="getColor(installment.status)"
                            class="text-uppercase"
                          >
                            {{ getTitle(installment.status) }}
                          </base-button>
                          <!--                            <div>-->
                          <!--                              <a v-show="installment.status !== statusIndex && parseInt(statusIndex) !== 3"-->
                          <!--                                 v-for="(item, statusIndex) in BillReceiveStatus"-->
                          <!--                                 class="dropdown-item"-->
                          <!--                                 @click.prevent="setStatus({-->
                          <!--                               installment : installment,-->
                          <!--                               status : statusIndex,-->
                          <!--                               index: indexInstallment-->
                          <!--                               })"-->
                          <!--                              >-->
                          <!--                                {{ item }}-->
                          <!--                              </a>-->
                          <!--                            </div>-->
                          <!--                          </base-dropdown>-->
                        </div>
                      </div>
                      <div class="form-group row m-0  mb-1 limited-row">
                        <label
                          class="col-md-6 pt-2  pb-0  col-form-label form-control-label"
                        >
                          Vencimento
                          <span class="text-danger">&nbsp;*</span>
                        </label>
                        <div class="col-md-6">
                          <validation-provider rules="required">
                            <base-input input-group-classes="input-group-sm">
                              <input-date-time-picker
                                disabled
                                v-model="installment.due_date"
                                :mode="'date'"/>
                            </base-input>
                          </validation-provider>
                        </div>
                      </div>
                      <!--                      <div class="form-group row m-0  mb-1 limited-row">-->
                      <!--                        <label-->
                      <!--                          class="col-md-6 pt-2  pb-0  col-form-label form-control-label"-->
                      <!--                        >-->
                      <!--                          Vencimento original-->
                      <!--                          <span class="text-danger">&nbsp;*</span>-->
                      <!--                        </label>-->
                      <!--                        <div class="col-md-6">-->
                      <!--                          <validation-provider rules="required">-->
                      <!--                            <base-input input-group-classes="input-group-sm">-->
                      <!--                              <input-date-time-picker-->
                      <!--                                :disabled="installment.old_status === 1-->
                      <!--                                || payload.bill_receive.any_has_billet-->
                      <!--                                || parseInt(installment.status) === 3 || parseInt(installment.status) === 11"-->
                      <!--                                v-model="installment.original_due_date"-->
                      <!--                                :mode="'date'"/>-->
                      <!--                            </base-input>-->
                      <!--                          </validation-provider>-->
                      <!--                        </div>-->
                      <!--                      </div>-->
                      <div class="form-group row m-0 mb-1 limited-row"
                           v-show="installment.status === 1 || installment.status === 11">
                        <label
                          class="col-md-6 pt-2 pb-0 col-form-label form-control-label"
                        >
                          Data do recebimento
                        </label>
                        <div class="col-md-6">
                          <base-input input-group-classes="input-group-sm">
                            <input-date-time-picker
                              v-model="installment.paid_at"
                              :mode="'date'"/>
                          </base-input>
                        </div>
                      </div>
                      <div class="form-group row m-0 p-0 mb-1 limited-row">
                        <label
                          class="col-md-6  pt-2 pb-0 col-form-label form-control-label"
                        >
                          Valor
                          <span class="text-danger">&nbsp;*</span>
                        </label>
                        <div class="col-md-6">
                          <base-input input-group-classes="input-group-sm">
                            <input
                              @blur="
                              calcTotalValue(installment, indexInstallment)
                            "
                              @keydown="$event.key === '-' ? $event.preventDefault() : null"
                              v-money="money"
                              disabled
                              maxlength="13"
                              inputmode="numeric"
                              v-model.lazy="installment.value"
                              type="text"
                              class="form-control form-control-sm"
                            />
                            <template slot="prepend">
                              <small class="input-group-sm p-0 m-0">
                                R$
                              </small>
                            </template>
                          </base-input>
                        </div>
                      </div>
                      <div class="form-group row m-0 p-0 mb-1 limited-row">
                        <label
                          class="col-md-6 pt-2 pb-0 col-form-label form-control-label"
                        >
                          Descontos
                        </label>
                        <div class="col-md-6">
                          <base-input input-group-classes="input-group-sm">
                            <input
                              @blur="calcTotalValue(installment, indexInstallment)"
                              @keydown="$event.key === '-' ? $event.preventDefault() : null"
                              maxlength="13"
                              v-money="money"
                              inputmode="numeric"
                              :disabled="installment.status === 1 ||
parseInt(installment.status) === 3 || parseInt(installment.status) === 11 || !$hasPermission('bill_receive.edit_discounts_and_taxes')"
                              v-model="installment.discounts"
                              class="form-control form-control-sm"
                            />
                            <template slot="prepend">
                              <small class="input-group-sm p-0 m-0">
                                R$
                              </small>
                            </template>
                          </base-input>
                        </div>
                      </div>
                      <div class="form-group row m-0 p-0 mb-1 limited-row">
                        <label
                          class="col-md-6 pt-2 pb-0 col-form-label form-control-label"
                        >
                          Taxas
                        </label>
                        <div class="col-md-6">
                          <base-input input-group-classes="input-group-sm">
                            <input
                              @blur="
                              calcTotalValue(installment, indexInstallment)
                            "
                              @keydown="$event.key === '-' ? $event.preventDefault() : null"
                              maxlength="13"
                              v-money="money"
                              inputmode="numeric"
                              :disabled="installment.status === 1 ||
parseInt(installment.status) === 3 || parseInt(installment.status) === 11 || !$hasPermission('bill_receive.edit_discounts_and_taxes')"
                              v-model="installment.discounts_fees"
                              class="form-control form-control-sm"
                            />
                            <template slot="prepend">
                              <small class="input-group-sm p-0 m-0">
                                R$
                              </small>
                            </template>
                          </base-input>
                        </div>
                      </div>
                      <div class="form-group row m-0 p-0 mb-1 limited-row">
                        <label
                          class="col-md-6  pt-2 pb-0 col-form-label form-control-label"
                        >
                          Juros/Multa
                        </label>
                        <div class="col-md-6">
                          <base-input input-group-classes="input-group-sm">
                            <input
                              @keydown="$event.key === '-' ? $event.preventDefault() : null"
                              ref="feesInput"
                              @blur="
                              calcTotalValue(installment, indexInstallment)
                            "
                              v-money="money"
                              maxlength="13"
                              inputmode="numeric"
                              v-model="installment.fees_fines"
                              :disabled="installment.status === 1 ||
parseInt(installment.status) === 3 || parseInt(installment.status) === 11"
                              class="form-control form-control-sm"
                            />
                            <template slot="prepend">
                              <small class="input-group-sm p-0 m-0">
                                R$
                              </small>
                            </template>
                          </base-input>
                        </div>
                      </div>
                      <div class="form-group row m-0 p-0 mb-1 limited-row">
                        <label
                          class="col-md-6 pt-2 pb-0 col-form-label form-control-label"
                        >
                          Valor final
                          <span class="text-danger">&nbsp;*</span>
                        </label>
                        <div class="col-md-6">
                          <base-input input-group-classes="input-group-sm">
                            <input
                              disabled
                              v-money="money"
                              maxlength="13"
                              inputmode="numeric"
                              v-model.lazy="installment.total_value"
                              type="text"
                              class="form-control form-control-sm"
                            />
                            <template slot="prepend">
                              <small class="input-group-sm p-0 m-0">
                                R$
                              </small>
                            </template>
                          </base-input>
                        </div>
                      </div>
                      <!--                      ANEXO-->
                      <!--                      <div class="form-group row m-0 p-0 mb-4">-->
                      <!--                        <div class="col-md-12 mt-2">-->
                      <!--                          <InputFile :ref="`input-file-${installment.uuid}`"/>-->
                      <!--                        </div>-->
                      <!--                      </div>-->
                    </div>
                    <!-- End Orders-->
                  </div>
                </section>
                <section v-show="type === 4">
                  <div class="form-group row m-0 p-0 mb-4">
                    <div class="col-md-12 mt-2">
                      <label class="form-control-label">Anexos</label>
                      <InputFile :maxFiles="10" ref="files"/>
                    </div>
                  </div>
                </section>
                <!-- Start Payments -->
                <!-- End Payments -->
              </div>
              <div class="modal-footer">
                <base-button type="secondary" @click="closeModal('create')">
                  Cancelar
                </base-button>
                <base-button type="success"
                             native-type="submit"
                             v-bind:disabled="invalid || disableSave"
                             :loading="loadingStore">
                  <span v-show="!loadingStore" class="btn-label"><i class="fas fa-save"></i></span>
                  Salvar
                </base-button>
              </div>
            </form>
          </validation-observer>
        </div>
        <!--        <div v-if="payload.bill_receive.type === 0">-->
        <!--          <validation-observer-->
        <!--            v-slot="{ invalid, handleSubmit }"-->
        <!--            ref="formValidator"-->
        <!--          >-->
        <!--            <form-->
        <!--              class="needs-validation"-->
        <!--              @submit.prevent="handleSubmit(store)"-->
        <!--              autocomplete="off"-->
        <!--            >-->
        <!--              <div>-->
        <!--                <div class="form-group row m-0 p-0 mb-1 limited-row">-->
        <!--                  <label-->
        <!--                    class="col-md-6 pt-2 pb-0 col-form-label form-control-label"-->
        <!--                  >-->
        <!--                    Tomador do serviço-->
        <!--                    <span class="text-danger">&nbsp;*&nbsp;</span>-->
        <!--                  </label>-->
        <!--                  <div class="col-md-6">-->
        <!--                    <validation-provider rules="required">-->
        <!--                      <base-input input-classes="form-control-sm">-->
        <!--                        <el-select-->
        <!--                          v-model="payload.bill_receive.entity_id"-->
        <!--                          size="mini"-->
        <!--                          placeholder="Selecione"-->
        <!--                          filterable-->
        <!--                          :loading="loadingPlant"-->
        <!--                          :disabled="loadingPlant"-->
        <!--                        >-->
        <!--                          <el-option-->
        <!--                            v-for="entity in entities"-->
        <!--                            :key="entity.uuid"-->
        <!--                            :label="entity.entity_name"-->
        <!--                            :value="entity.id"-->
        <!--                          >-->
        <!--                          </el-option>-->
        <!--                        </el-select>-->
        <!--                      </base-input>-->
        <!--                    </validation-provider>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--                <div class="form-group row m-0 p-0 mb-1 limited-row">-->
        <!--                  <label-->
        <!--                    class="col-md-6 pt-2 pb-0 col-form-label form-control-label"-->
        <!--                  >-->
        <!--                    Central-->
        <!--                    <span class="text-danger">&nbsp;*&nbsp;</span>-->
        <!--                  </label>-->
        <!--                  <div class="col-md-6">-->
        <!--                    <validation-provider rules="required">-->
        <!--                      <base-input input-classes="form-control-sm">-->
        <!--                        <el-select-->
        <!--                          v-model="payload.bill_receive.company_plant_uuid"-->
        <!--                          size="mini"-->
        <!--                          placeholder="Selecione"-->
        <!--                          filterable-->
        <!--                          :loading="loadingPlant"-->
        <!--                          :disabled="loadingPlant"-->
        <!--                        >-->
        <!--                          <el-option-->
        <!--                            v-for="plant in plants"-->
        <!--                            :key="plant.uuid"-->
        <!--                            :label="plant.name"-->
        <!--                            :value="plant.uuid"-->
        <!--                          >-->
        <!--                          </el-option>-->
        <!--                        </el-select>-->
        <!--                      </base-input>-->
        <!--                    </validation-provider>-->
        <!--                  </div>-->
        <!--                </div>-->

        <!--                <div class="form-group row m-0 p-0 mb-1 limited-row">-->
        <!--                  <label-->
        <!--                    class="col-md-6 pb-1 pt-2  col-form-label form-control-label"-->
        <!--                  >-->
        <!--                    Competência-->
        <!--                    <span class="text-danger">&nbsp;*</span>-->
        <!--                  </label>-->
        <!--                  <div class="col-md-6">-->
        <!--                    <validation-provider rules="required">-->
        <!--                      <base-input input-group-classes="input-group-sm">-->
        <!--                        <el-date-picker-->
        <!--                          v-model="payload.bill_receive.competence"-->
        <!--                          size="mini"-->
        <!--                          type="date"-->
        <!--                          format="dd/MM/yyyy"-->
        <!--                          value-format="yyyy-MM-dd"-->
        <!--                        >-->
        <!--                        </el-date-picker>-->
        <!--                      </base-input>-->
        <!--                    </validation-provider>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--                <div class="form-group row m-0 p-0 mb-1 limited-row">-->
        <!--                  <label-->
        <!--                    class="col-md-6 pt-2 pb-1 col-form-label form-control-label"-->
        <!--                  >-->
        <!--                    Valor total-->
        <!--                    <span class="text-danger">&nbsp;*&nbsp;</span>-->
        <!--                  </label>-->
        <!--                  <div class="col-md-6">-->
        <!--                    <base-input input-group-classes="input-group-sm">-->
        <!--                      <input-->
        <!--                        @blur="generateInstallments(1, true)"-->
        <!--                        v-money="money"-->
        <!--                        maxlength="10"-->
        <!--                        inputmode="numeric"-->
        <!--                        v-model.lazy="payload.bill_receive.total_value"-->
        <!--                        type="text"-->
        <!--                        class="form-control form-control-sm"-->
        <!--                      />-->
        <!--                      <template slot="prepend">-->
        <!--                        <small class="input-group-sm p-0 m-0">-->
        <!--                          R$-->
        <!--                        </small>-->
        <!--                      </template>-->
        <!--                    </base-input>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--                <div class="form-group row m-0 p-0 mb-1 limited-row">-->
        <!--                  <label-->
        <!--                    class="col-md-6  pt-2 pb-0 col-form-label form-control-label"-->
        <!--                  >-->
        <!--                    Valor Final-->
        <!--                    <span class="text-danger">&nbsp;*</span>-->
        <!--                  </label>-->
        <!--                  <div class="col-md-6">-->
        <!--                    <base-input input-group-classes="input-group-sm">-->
        <!--                      <input-->
        <!--                        disabled-->
        <!--                        v-money="money"-->
        <!--                        maxlength="10"-->
        <!--                        inputmode="numeric"-->
        <!--                        v-model.lazy="payload.bill_receive.final_value"-->
        <!--                        type="text"-->
        <!--                        class="form-control form-control-sm"-->
        <!--                      />-->
        <!--                      <template slot="prepend">-->
        <!--                        <small class="input-group-sm p-0 m-0">-->
        <!--                          R$-->
        <!--                        </small>-->
        <!--                      </template>-->
        <!--                    </base-input>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--                <div class="form-group row m-0 p-0 mb-1 limited-row">-->
        <!--                  <label-->
        <!--                    class="col-md-6 pt-2 pb-1 col-form-label form-control-label"-->
        <!--                  >-->
        <!--                    Método de pagamento-->
        <!--                  </label>-->
        <!--                  <div class="col-md-6">-->
        <!--                    <base-input input-classes="form-control-sm">-->
        <!--                      <el-select-->
        <!--                        v-model="payload.bill_receive.payment_method_uuid"-->
        <!--                        size="mini"-->
        <!--                        placeholder="Selecione"-->
        <!--                        filterable-->
        <!--                        :loading="loadingPlant"-->
        <!--                        :disabled="-->
        <!--                          loadingPlant || payload.bill_receive.total_value === '0,00'-->
        <!--                        "-->
        <!--                      >-->
        <!--                        <el-option-->
        <!--                          v-for="method in payment_methods"-->
        <!--                          :key="method.uuid"-->
        <!--                          :label="method.name"-->
        <!--                          :value="method.uuid"-->
        <!--                        >-->
        <!--                        </el-option>-->
        <!--                      </el-select>-->
        <!--                    </base-input>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--                <div class="form-group row m-0 p-0 mb-1 limited-row">-->
        <!--                  <label-->
        <!--                    class="col-md-6 pt-2 pb-1 col-form-label form-control-label"-->
        <!--                  >-->
        <!--                    Condição de pagamento-->
        <!--                    <span class="text-danger">&nbsp;*&nbsp;</span>-->
        <!--                  </label>-->
        <!--                  <div class="col-md-6">-->
        <!--                    <validation-provider rules="required">-->
        <!--                      <base-input input-classes="form-control-sm">-->
        <!--                        <el-select-->
        <!--                          @change="generateInstallments(0,true,true)"-->
        <!--                          v-model="payload.bill_receive.payment_term_uuid"-->
        <!--                          size="mini"-->
        <!--                          placeholder="Selecione"-->
        <!--                          filterable-->
        <!--                          :disabled="-->
        <!--                          loadingPlant || payload.bill_receive.total_value === '0,00'"-->
        <!--                        >-->
        <!--                          <el-option-->
        <!--                            v-for="term in payment_terms"-->
        <!--                            :key="term.uuid"-->
        <!--                            :label="term.name"-->
        <!--                            :value="term.uuid"-->
        <!--                          >-->
        <!--                          </el-option>-->
        <!--                        </el-select>-->
        <!--                      </base-input>-->
        <!--                    </validation-provider>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--                <div class="form-group row m-0 p-0 ">-->
        <!--                  <label-->
        <!--                    class="col-md-6 pt-2 pb-1 col-form-label form-control-label"-->
        <!--                  >-->
        <!--                    Observações-->
        <!--                  </label>-->
        <!--                  <div class="col-md-6">-->
        <!--                    <base-input input-group-classes="input-group-sm">-->
        <!--                      <input-->
        <!--                        type="text"-->
        <!--                        v-model="payload.bill_receive.observations"-->
        <!--                        class="form-control form-control-sm"-->
        <!--                      />-->
        <!--                    </base-input>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--                &lt;!&ndash; Start Payments &ndash;&gt;-->
        <!--                <div v-if="payload.installments.length">-->
        <!--                  <div class="border-header-danger-bottom mb-2 mt-3">-->
        <!--                    <h5 class="h3 p-1 mb-0">Pagamentos</h5>-->
        <!--                  </div>-->
        <!--                  &lt;!&ndash; Start Orders&ndash;&gt;-->
        <!--                  <div-->
        <!--                    v-for="(installment,-->
        <!--                    indexInstallment) in payload.installments"-->
        <!--                    :key="installment.index"-->
        <!--                  >-->
        <!--                    <div-->
        <!--                      class="form-group row m-0  mb-1 limited-row"-->
        <!--                      :class="installment.index > 1 ? 'mt-4' : ''"-->
        <!--                    >-->
        <!--                      <label-->
        <!--                        class="col-md-6 pt-2 pb-0 pt-1 col-form-label form-control-label font-weight-bold"-->
        <!--                      >-->
        <!--                        <h5 class="h4  mb-1">-->
        <!--                          Parcela {{ installment.index }}-->
        <!--                          <span class="text-danger">&nbsp;*</span>-->
        <!--                        </h5>-->
        <!--                      </label>-->
        <!--                      <div class="col-md-6">-->
        <!--                        <base-dropdown class="col-md-12 p-0 m-0">-->
        <!--                          <base-button-->
        <!--                            slot="title-container"-->
        <!--                            block-->
        <!--                            size="sm"-->
        <!--                            :type="getColor(installment.status)"-->
        <!--                            class="dropdown-toggle text-uppercase"-->
        <!--                          >-->
        <!--                            {{ getTitle(installment.status) }}-->
        <!--                          </base-button>-->
        <!--                          <div>-->
        <!--                            <a-->
        <!--                              @click.prevent="installment.status = 1"-->
        <!--                              v-if="installment.status !== 1"-->
        <!--                              class="dropdown-item"-->
        <!--                            >Pago</a-->
        <!--                            >-->
        <!--                            <div-->
        <!--                              v-if="installment.status !== 1"-->
        <!--                              class="dropdown-divider p-0 m-0"-->
        <!--                            ></div>-->
        <!--                            <a-->
        <!--                              @click.prevent="installment.status = 3"-->
        <!--                              v-if="installment.status !== 3"-->
        <!--                              class="dropdown-item"-->
        <!--                            >Cancelado</a-->
        <!--                            >-->
        <!--                            <div-->
        <!--                              v-if="installment.status !== 3"-->
        <!--                              class="dropdown-divider p-0 m-0"-->
        <!--                            ></div>-->
        <!--                            <a-->
        <!--                              @click.prevent="installment.status = 0"-->
        <!--                              v-if="installment.status !== 0"-->
        <!--                              class="dropdown-item"-->
        <!--                            >Pendente</a-->
        <!--                            >-->
        <!--                          </div>-->
        <!--                        </base-dropdown>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                    <div class="form-group row m-0  mb-1 limited-row">-->
        <!--                      <label-->
        <!--                        class="col-md-6 pt-2  pb-0  col-form-label form-control-label"-->
        <!--                      >-->
        <!--                        Vencimento-->
        <!--                        <span class="text-danger">&nbsp;*</span>-->
        <!--                      </label>-->
        <!--                      <div class="col-md-6">-->
        <!--                        <validation-provider rules="required">-->
        <!--                          <base-input input-group-classes="input-group-sm">-->
        <!--                            <el-date-picker-->
        <!--                              v-model="installment.due_date"-->
        <!--                              size="mini"-->
        <!--                              type="date"-->
        <!--                              format="dd/MM/yyyy"-->
        <!--                              value-format="yyyy-MM-dd"-->
        <!--                            >-->
        <!--                            </el-date-picker>-->
        <!--                          </base-input>-->
        <!--                        </validation-provider>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                    <div class="form-group row m-0 p-0 mb-1 limited-row">-->
        <!--                      <label-->
        <!--                        class="col-md-6  pt-2 pb-0 col-form-label form-control-label"-->
        <!--                      >-->
        <!--                        Valor-->
        <!--                        <span class="text-danger">&nbsp;*</span>-->
        <!--                      </label>-->
        <!--                      <div class="col-md-6">-->
        <!--                        <base-input input-group-classes="input-group-sm">-->
        <!--                          <input-->
        <!--                            @blur="-->
        <!--                              calcTotalValue(installment, indexInstallment)-->
        <!--                            "-->
        <!--                            v-money="money"-->
        <!--                            maxlength="10"-->
        <!--                            inputmode="numeric"-->
        <!--                            v-model.lazy="installment.value"-->
        <!--                            type="text"-->
        <!--                            class="form-control form-control-sm"-->
        <!--                          />-->
        <!--                          <template slot="prepend">-->
        <!--                            <small class="input-group-sm p-0 m-0">-->
        <!--                              R$-->
        <!--                            </small>-->
        <!--                          </template>-->
        <!--                        </base-input>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                    <div class="form-group row m-0 p-0 mb-1 limited-row">-->
        <!--                      <label-->
        <!--                        class="col-md-6 pt-2 pb-0 col-form-label form-control-label"-->
        <!--                      >-->
        <!--                        Descontos/taxas-->
        <!--                      </label>-->
        <!--                      <div class="col-md-6">-->
        <!--                        <base-input input-group-classes="input-group-sm">-->
        <!--                          <input-->
        <!--                            @blur="-->
        <!--                              calcTotalValue(installment, indexInstallment)-->
        <!--                            "-->
        <!--                            v-on:input="-->
        <!--                              installment.discounts_fees = formatString(-->
        <!--                                $event.target.value,-->
        <!--                                '0',-->
        <!--                                '100.0',-->
        <!--                                '2'-->
        <!--                              )-->
        <!--                            "-->
        <!--                            maxlength="10"-->
        <!--                            inputmode="numeric"-->
        <!--                            v-model="installment.discounts_fees"-->
        <!--                            class="form-control form-control-sm"-->
        <!--                          />-->
        <!--                          <template slot="prepend">-->
        <!--                            <small class="input-group-sm p-0 m-0">-->
        <!--                              %-->
        <!--                            </small>-->
        <!--                          </template>-->
        <!--                        </base-input>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                    <div class="form-group row m-0 p-0 mb-1 limited-row">-->
        <!--                      <label-->
        <!--                        class="col-md-6  pt-2 pb-0 col-form-label form-control-label"-->
        <!--                      >-->
        <!--                        Juros/Multa-->
        <!--                      </label>-->
        <!--                      <div class="col-md-6">-->
        <!--                        <base-input input-group-classes="input-group-sm">-->
        <!--                          <input-->
        <!--                            @keydown="$event.key === '-' ? $event.preventDefault() : null"-->
        <!--                            ref="feesInput"-->
        <!--                            @blur="-->
        <!--                              calcTotalValue(installment, indexInstallment)-->
        <!--                            "-->
        <!--                            v-money="money"-->
        <!--                            maxlength="10"-->
        <!--                            inputmode="numeric"-->
        <!--                            v-model="installment.fees_fines"-->
        <!--                            class="form-control form-control-sm"-->
        <!--                          />-->
        <!--                          <template slot="prepend">-->
        <!--                            <small class="input-group-sm p-0 m-0">-->
        <!--                              R$-->
        <!--                            </small>-->
        <!--                          </template>-->
        <!--                        </base-input>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                    <div class="form-group row m-0 p-0 mb-1 limited-row">-->
        <!--                      <label-->
        <!--                        class="col-md-6 pt-2 pb-0 col-form-label form-control-label"-->
        <!--                      >-->
        <!--                        Valor total-->
        <!--                        <span class="text-danger">&nbsp;*</span>-->
        <!--                      </label>-->
        <!--                      <div class="col-md-6">-->
        <!--                        <base-input input-group-classes="input-group-sm">-->
        <!--                          <input-->
        <!--                            disabled-->
        <!--                            v-money="money"-->
        <!--                            maxlength="10"-->
        <!--                            inputmode="numeric"-->
        <!--                            v-model.lazy="installment.total_value"-->
        <!--                            type="text"-->
        <!--                            class="form-control form-control-sm"-->
        <!--                          />-->
        <!--                          <template slot="prepend">-->
        <!--                            <small class="input-group-sm p-0 m-0">-->
        <!--                              R$-->
        <!--                            </small>-->
        <!--                          </template>-->
        <!--                        </base-input>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <div class="row">-->
        <!--                    <label-->
        <!--                      class="col-md-6 col-form-label form-control-label"-->
        <!--                    >-->
        <!--                      Anexo-->
        <!--                    </label>-->
        <!--                    <div class="col-md-6">-->
        <!--                      <base-input input-group-classes="input-group-sm">-->
        <!--                        <PuzlFile-->
        <!--                          v-model="installment.attachment"-->
        <!--                          title="anexo"-->
        <!--                        />-->
        <!--                      </base-input>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  &lt;!&ndash; End Orders&ndash;&gt;-->
        <!--                </div>-->
        <!--                &lt;!&ndash; End Payments &ndash;&gt;-->
        <!--              </div>-->
        <!--              &lt;!&ndash;              <div class="modal-footer">&ndash;&gt;-->
        <!--              &lt;!&ndash;                <base-button type="secondary" @click="closeModal('create')">&ndash;&gt;-->
        <!--              &lt;!&ndash;                  Cancelar&ndash;&gt;-->
        <!--              &lt;!&ndash;                </base-button>&ndash;&gt;-->
        <!--              &lt;!&ndash;                <base-button type="success"&ndash;&gt;-->
        <!--              &lt;!&ndash;                             native-type="submit"&ndash;&gt;-->
        <!--              &lt;!&ndash;                             v-bind:disabled="invalid"&ndash;&gt;-->
        <!--              &lt;!&ndash;                             :loading="loadingStore">&ndash;&gt;-->
        <!--              &lt;!&ndash;                  <span v-show="!loadingStore" class="btn-label"><i class="fas fa-save"></i></span>&ndash;&gt;-->
        <!--              &lt;!&ndash;                  Salvar&ndash;&gt;-->
        <!--              &lt;!&ndash;                </base-button>&ndash;&gt;-->
        <!--              &lt;!&ndash;              </div>&ndash;&gt;-->
        <!--            </form>-->
        <!--          </validation-observer>-->
        <!--        </div>-->
      </div>
      <modal-share-installment @updateInstallments="updateInstallments" ref="modalShareInstallment"/>
      <modal-entity-payment @updateInstallments="updateInstallmentsEntity" ref="modalEntityPayment"/>
    </modal>
    <ModalCardIntegration ref="ModalCardIntegration" @selectedTransactionProps="setTransactionProps"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import SpinnerPuzl from "@/components/SpinnerPuzl";
import PuzlFile from "@/components/PuzlFile";
import {VMoney} from "v-money";
import {checkNumberValue} from "@/helpers";
import {formatErrorValidation, mask1} from "@/plugins";
import moment from "moment";
import InputFile from '@/components/Utils/InputFile'
import PuzlSelect from "@/components/PuzlSelect";
import InstallmentMixin from "../../Mixins/installmentMixin";
import InputDateTimePicker from "@/components/InputDateTimePickerV2";
import ModalShareInstallment from '../../../../Commercial/ContractProposal/Payments/Shared/_ModalShareInstallment';
import ModalEntityPayment from "../../Entity/Shared/Modals/_ModalEntityPayment"
import {moneyToFloat} from "../../../../../../helpers";
import ModalCardIntegration from "./_ModalCardIntegration";

export default {
  name: "ModalEditBillReceive",
  mixins: [InstallmentMixin],
  components: {
    PuzlFile,
    SpinnerPuzl,
    InputFile,
    PuzlSelect,
    InputDateTimePicker,
    ModalShareInstallment,
    ModalEntityPayment,
    ModalCardIntegration,
  },
  data() {
    return {
      modal: {
        title: "Créditos e antecipações",
        create: false
      },
      initial_iss: 0,
      old_installments: [],
      payment_methods_selected: [],
      payment_terms_selected: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      type: 1,
      filelist_name: {},
      loadingSpinner: false,
      loadingCompanyPlantIssuers: false,
      loadingPaymentIntermediaries: false,
      loadingPaymentIntermediaryMerchants: false,
      hasInstallmentPaid: false,
      attachments: {},
      payment_intemerdiaries_selected: [],
      payment_intermediary_merchants: [],
      loadingCompanyPlants: false,
      range_frequency_options: [
        {
          id: 1,
          name: 'Diariamente'
        },
        {
          id: 2,
          name: 'Semanalmente'
        },
        {
          id: 3,
          name: 'Mensalmente'
        },
        {
          id: 4,
          name: 'Personalizado'
        },
      ],
      payload: {
        bill_receive: {
          id: null,
          type: null,
          competence: null,
          name: null,
          total_value: null,
          observations: null,
          company_plant_uuid: null,
          final_value: null,
          payment_term_uuid: null,
          payment_method_uuid: null,
          billable_id: null,
          payment_intermediary_merchant_id: null,
        },
        installments: [],
        invoices: []
      },
      has_changed_installment: false,
      invoices_paid: [],
      contract_proposal: {},
      loadingStore: false,
      bank_accounts: [],
      loadingBankAccounts: false,
      loadingPlant: false,
      loadingMethod: true,
      loadingTerm: true,
      loading: true,
      payment_intermediaries_exists: false,
      require_nsu_code: false,
      blockFields: true,
      disableSave: false,
      file: null,
      BillReceiveStatus: {
        '0': "Pendente",
        '1': "Recebido",
        '3': "Cancelado",
        '4': "Serasa",
        '5': "Cartório",
        '6': "Protestado",
        '7': "Jurídico",
        '11': "Antecipação",
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false
      },
      invoiced_amount: null,
      balance: null,
      modalCardIntegrations: false,
    };
  },
  directives: {
    money: VMoney
  },
  computed: {
    ...mapGetters({
      plants: "plant/activeItems",
      payment_methods: "paymentMethod/fetch",
      payment_terms: "paymentTerm/fetch",
      entities: "entity/fetch",
      invoices: "billingInvoice/invoices",
      $_company_plants: "plant/activeItems",
      company_plant_issuers: "companyPlantIssuer/fetch",
      $_payment_intermediaries: "paymentIntermediary/fetch"
    })
  },
  methods: {
    /**
     * @param {object} selectedTransactionProps
     * @param {integer} selectedTransactionProps.payment_intermediary_id
     * @param {integer} selectedTransactionProps.paymentIntermediaryMerchantId
     * @param {string} selectedTransactionProps.nsu_code
     */
    setTransactionProps(selectedTransactionProps) {
      this.payload.bill_receive.payment_intermediary_id = selectedTransactionProps.paymentIntermediaryId;
      this.payload.bill_receive.payment_intermediary_merchant_id = selectedTransactionProps.paymentIntermediaryMerchantId;
      this.payload.bill_receive.nsu_code = selectedTransactionProps.nsu_code;
      this.payload.bill_receive.competence = selectedTransactionProps.competence;
    },
    handleCreateModalCardIntegration() {
      this.$refs.ModalCardIntegration.handleCreateModal(
        this.payload.bill_receive.payment_intermediary_id,
        this.payload.bill_receive.payment_intermediary_merchant_id,
        this.payload.bill_receive.competence,
      )
    },
    /**
     * Exibir campos de ponto de venda de acordo com ID do Mediador de Pagamento
     *
     * @param {integer} paymentIntermediaryId Id de mediador de pagamento
     * @returns {boolean} Exibir/Não exibir pontos de venda
     */
    isDisplayPaymentIntermediaryMerchants(paymentIntermediaryId) {
      const paymentIntermediaryIdFound = this.payment_intemerdiaries_selected.find((item) => item.id === paymentIntermediaryId);
      return paymentIntermediaryIdFound
        && paymentIntermediaryIdFound.has_credentials
        && (this.payment_intermediary_merchants.length > 0);
    },
    getPaymentIntermediaryMerchants() {
      this.payload.bill_receive.payment_intermediary_merchant_id = null
      this.loadingPaymentIntermediaryMerchants = true
      const params = {
        filter: {
          payment_intermediary_id: this.payload.bill_receive.payment_intermediary_id,
          status: true,
        }
      };
      this.$store
        .dispatch("paymentIntermediaryMerchant/fetchItems", params)
        .then(response => {
          this.payment_intermediary_merchants = response.data;
          if (this.payment_intermediary_merchants.length === 1) {
            this.payload.bill_receive.payment_intermediary_merchant_id = this.payment_intermediary_merchants[0].id;
          }
          this.loadingPaymentIntermediaryMerchants = false;
        });
    },
    closeModal() {
      this.modal.create = false;
      this.$emit('updated', this.payload.bill_receive.billable_id)
    },
    resetInstallments() {
      return this.$Swal.confirmAction('Deseja realmente restaurar as parcelas?', ['Sim', 'Não'])
        .then((result) => {
          if (result.isConfirmed) {
            this.payload.installments = Object.values(this.old_installments)
            this.has_changed_installment = false
            this.$notify({
              type: 'success',
              message: 'Parcelas restauradas com sucesso!'
            })
            this.$forceUpdate()
          }
        }).catch(() => this.$Progress.finish())
    },
    showModalShareInstallment(installment) {
      this.$refs.modalShareInstallment.openModal(installment)
    },
    disableMaxDateInstallment(installment) {
      const due_date = this.$helper.parseDate(installment.due_date, 'YYYY-MM-DD')
      const date = this.$helper.parseDate(new Date(), 'YYYY-MM-DD')
      if (date > due_date) {
        return true
      }
      return false
    },
    showModalEntityPayment(installment) {
      const total_value = this.payload.installments
        .reduce(function (a, item) {
          return a + (item.status === 11 ? moneyToFloat(item.total_value) : 0)
        }, 0)
        .toFixed(2);
      this.$refs.modalEntityPayment.openModal({
        installment: installment,
        balance: (this.contract_proposal.entity.anticipation_balance - total_value).toString()
      })
    },
    async updateInstallmentsEntity(params) {
      this.has_changed_installment = true
      let installments = {...this.payload.installments}
      let latest = this.payload.installments.at(-1)
      if (latest.index === params.latest_index) {
        let index = -1
        for (var i = 0; i < this.payload.installments.length; i++) {
          if (this.payload.installments[i].index === params.latest_index) {
            index = i
          }
        }
        delete installments[index]
        installments = Object.values(installments)
        for (let i of params.installments) {
          let fees_fines = 0
          let discounts_fees = 0
          let discounts = 0
          let total_value = i.value
          if (i.index === params.latest_index) {
            fees_fines = this.payload.installments[index].fees_fines.replaceAll(".", "").replace(",", ".")
            discounts_fees = this.payload.installments[index].discounts_fees.replaceAll(".", "").replace(",", ".")
            discounts = this.payload.installments[index].discounts.replaceAll(".", "").replace(",", ".")
            total_value = Number((Number(i.value) - Number(discounts_fees) - Number(discounts)) + Number(fees_fines)).toFixed(2)
            installments.push(
              {
                attachments: [],
                value: i.value,
                index: i.index,
                shared: true,
                entity: true,
                shared_count: params.shared_count,
                id: this.payload.installments[index].id,
                total_value: total_value,
                due_date: params.due_date,
                original_due_date: params.due_date,
                status: 11,
                paid_at: moment(new Date()).format('YYYY-MM-DD'),
                fees_fines: fees_fines,
                discounts: discounts,
                discounts_fees: discounts_fees
              }
            )
          } else {
            installments.push(
              {
                attachments: [],
                value: i.value,
                index: i.index,
                entity: true,
                total_value: total_value,
                due_date: params.due_date,
                original_due_date: params.due_date,
                status: 0,
                paid_at: null,
                fees_fines: fees_fines,
                discounts: discounts,
                discounts_fees: discounts_fees
              }
            )
          }
          this.payload.installments = installments
        }
      } else {
        let previous_index = params.latest_index - 1

        let next_index = params.latest_index + 1
        let previous_installments = this.payload.installments.filter((item) => item.index <= previous_index)
        let next_installments = this.payload.installments.filter((item) => item.index >= next_index)

        let installments = []
        let atual_index = 0;

        for (let i in previous_installments) {
          atual_index += 1
          installments.push(previous_installments[i])
        }
        let index = -1
        for (var i = 0; i < this.payload.installments.length; i++) {
          if (this.payload.installments[i].index === params.latest_index) {
            index = i
          }
        }
        for (let i of params.installments) {
          let fees_fines = 0
          let discounts_fees = 0
          let discounts = 0
          let total_value = i.value
          atual_index += 1
          if (i.index === params.latest_index) {
            fees_fines = this.payload.installments[index].fees_fines.replaceAll(".", "").replace(",", ".")
            discounts = this.payload.installments[index].discounts.replaceAll(".", "").replace(",", ".")
            discounts_fees = this.payload.installments[index].discounts_fees.replaceAll(".", "").replace(",", ".")
            total_value = Number((Number(i.value) - Number(discounts_fees) - Number(discounts)) + Number(fees_fines)).toFixed(2)
            installments.push(
              {
                attachments: [],
                value: i.value,
                index: i.index,
                shared: true,
                entity: true,
                shared_count: params.shared_count,
                id: this.payload.installments[index].id,
                total_value: total_value,
                due_date: params.due_date,
                original_due_date: params.due_date,
                status: 11,
                paid_at: moment(new Date()).format('YYYY-MM-DD'),
                fees_fines: fees_fines,
                discounts: discounts,
                discounts_fees: discounts_fees
              })
          } else {
            installments.push(
              {
                attachments: [],
                value: i.value,
                index: i.index,
                entity: true,
                total_value: total_value,
                due_date: params.due_date,
                original_due_date: params.due_date,
                status: 0,
                paid_at: null,
                fees_fines: fees_fines,
                discounts: discounts,
                discounts_fees: discounts_fees
              }
            )
          }
        }
        for (let i in next_installments) {
          atual_index += 1
          next_installments[i].index = atual_index
          installments.push(next_installments[i])
        }
        this.payload.installments = installments
      }
      this.$refs.modalEntityPayment.updatedInstallments()
    },
    async updateInstallments(params) {
      this.has_changed_installment = true
      if (params.installments.length === 1 || (params.installments.length === this.old_installments.length)) {
        return this.$refs.modalShareInstallment.updatedInstallments()
      }
      let installments = {...this.payload.installments}
      let latest = this.payload.installments.at(-1)
      if (latest.index === params.latest_index) {
        let index = -1
        for (var i = 0; i < this.payload.installments.length; i++) {
          if (this.payload.installments[i].index === params.latest_index) {
            index = i
          }
        }
        delete installments[index]
        installments = Object.values(installments)
        for (let i of params.installments) {
          let fees_fines = 0
          let discounts_fees = 0
          let discounts = 0
          let total_value = i.value
          if (i.index === params.latest_index) {
            fees_fines = this.payload.installments[index].fees_fines.replaceAll(".", "").replace(",", ".")
            discounts_fees = this.payload.installments[index].discounts_fees.replaceAll(".", "").replace(",", ".")
            discounts = this.payload.installments[index].discounts.replaceAll(".", "").replace(",", ".")
            total_value = Number((Number(i.value) - Number(discounts_fees) - Number(discounts)) + Number(fees_fines)).toFixed(2)
            installments.push(
              {
                attachments: [],
                value: i.value,
                index: i.index,
                shared: true,
                shared_count: params.shared_count,
                id: this.payload.installments[index].id,
                total_value: total_value,
                due_date: params.due_date,
                original_due_date: params.due_date,
                status: 0,
                paid_at: null,
                fees_fines: fees_fines,
                discounts: discounts,
                discounts_fees: discounts_fees
              }
            )
          } else {
            installments.push(
              {
                attachments: [],
                value: i.value,
                index: i.index,
                total_value: total_value,
                due_date: params.due_date,
                original_due_date: params.due_date,
                status: 0,
                paid_at: null,
                fees_fines: fees_fines,
                discounts: discounts,
                discounts_fees: discounts_fees
              }
            )
          }
          this.payload.installments = installments
        }
      } else {
        let previous_index = params.latest_index - 1

        let next_index = params.latest_index + 1

        let previous_installments = this.payload.installments.filter((item) => item.index <= previous_index)
        let next_installments = this.payload.installments.filter((item) => item.index >= next_index)

        let installments = []
        let atual_index = 0;

        for (let i in previous_installments) {
          atual_index += 1
          installments.push(previous_installments[i])
        }
        let index = -1
        for (var i = 0; i < this.payload.installments.length; i++) {
          if (this.payload.installments[i].index === params.latest_index) {
            index = i
          }
        }

        for (let i of params.installments) {
          let fees_fines = 0
          let discounts_fees = 0
          let discounts = 0
          let total_value = i.value
          atual_index += 1
          if (i.index === params.latest_index) {
            fees_fines = this.payload.installments[index].fees_fines.replaceAll(".", "").replace(",", ".")
            discounts_fees = this.payload.installments[index].discounts_fees.replaceAll(".", "").replace(",", ".")
            discounts = this.payload.installments[index].discounts.replaceAll(".", "").replace(",", ".")
            total_value = Number((Number(i.value) - Number(discounts_fees) - Number(discounts)) + Number(fees_fines)).toFixed(2)
            installments.push(
              {
                attachments: [],
                value: i.value,
                index: i.index,
                shared: true,
                shared_count: params.shared_count,
                id: this.payload.installments[index].id,
                total_value: total_value,
                due_date: params.due_date,
                original_due_date: params.due_date,
                status: 0,
                paid_at: null,
                fees_fines: fees_fines,
                discounts: discounts,
                discounts_fees: discounts_fees
              })
          } else {
            installments.push(
              {
                attachments: [],
                value: i.value,
                index: i.index,
                total_value: total_value,
                due_date: params.due_date,
                original_due_date: params.due_date,
                status: 0,
                paid_at: null,
                fees_fines: fees_fines,
                discounts: discounts,
                discounts_fees: discounts_fees
              }
            )
          }
        }
        for (let i in next_installments) {
          atual_index += 1
          next_installments[i].index = atual_index
          installments.push(next_installments[i])
        }
        this.payload.installments = installments
      }
      this.$refs.modalShareInstallment.updatedInstallments()
    },
    getBankAccounts() {
      this.loadingBankAccounts = true
      this.$store.dispatch("companyPlantBankAccount/getByCompanyPlant", {allocated: 1, company_plant_id: this.payload.bill_receive.company_plant_id}).then((response) => {
        this.loadingBankAccounts = false
        this.bank_accounts = response.data
      })
    },
    changeBankAccount() {
      this.getBankAccounts()
    },
    /** validações por método de pagamento */
    setRequireNsuCode(set_intermediary_id = true, clear_installments = true) {
      this.modalCardIntegrations = true;
      if (set_intermediary_id) {
        this.payload.bill_receive.payment_intermediary_id = null
      }
      this.payment_intemerdiaries_selected = []
      let payment_method = this.payment_methods.find((item) => item.uuid === this.payload.bill_receive.payment_method_uuid)
      if (!payment_method) {
        this.payment_intermediaries_exists = false
        return this.require_nsu_code = false
      }
      this.payment_intermediaries_exists = payment_method.payment_intermediaries_exists
      // if (payment_method.payment_intermediaries_exists) {
      //   if (clear_installments) {
      //     this.payload.installments = []
      //     if (payment_method.payment_intermediaries.length === 1) {
      //       this.payload.bill_receive.payment_intermediary_id =
      //         payment_method.payment_intermediaries[0];
      //     }
      //   }
      //   this.payment_intemerdiaries_selected = this.$_payment_intermediaries.filter(function (item) {
      //     if (payment_method.payment_intermediaries.includes(item.id)) {
      //       return item
      //     }
      //   })
      // }
      return this.require_nsu_code = Boolean(payment_method.transaction_code)
    },
    setInvoice() {
      if (this.payload.bill_receive.company_plant_issuer_id === null ||
        this.payload.bill_receive.company_plant_id === null) {
        return;
      }
      let loader = this.$loading.show()
      this.payload.invoices = []
      this.$store
        .dispatch("billingInvoice/init", {
          filter: {
            pending_payment: 1,
            bill_receive_id: this.payload.bill_receive.billable_id,
            contract_proposal_id: this.payload.bill_receive.contract_proposal_id,
            company_plant_id: this.payload.bill_receive.company_plant_id,
            company_plant_issuer_id: this.payload.bill_receive.company_plant_issuer_id,
            not_canceled: true,
          }
        })
        .then(response => {
          loader.hide()
        })
    },
    InputFileClick(index) {
      this.$refs['attachments' + index][0].click()
    },
    setStatus(params) {
      if (!params.installment.paid_at) {
        this.payload.installments[params.index].paid_at = moment(new Date()).format('YYYY-MM-DD')
      }
      if (Number(params.status) !== 1) {
        this.payload.installments[params.index].paid_at = null
      }
      this.payload.installments[params.index].status = Number(params.status)
    },
    calcTotalValueToReceive() {
      const sum = this.payload.installments.reduce(function (new_value, obj) {
        new_value += parseFloat(obj.value.includes(",") ? obj.value.replaceAll(".", "").replace(",", ".") : obj.value);
        return new_value;
      }, 0);
      return this.payload.bill_receive.total_value = Number(sum).toFixed(2)
    },
    calcFinalValue() {
      let total = 0;
      let total_value = 0;
      let final_value = 0
      for (let i in this.payload.installments) {
        const day = Number(moment(this.payload.installments[i].due_date).day());
        if ([0, 6].includes(day)) {
          if (day === 0) {
            const due_date = moment(this.payload.installments[i].due_date)
              .add(1, "days")
              .format("YYYY/MM/DD");
            this.payload.installments[i].due_date = due_date;
            this.payload.installments[i].original_due_date = due_date;
          }
          if (day === 6) {
            const due_date = moment(this.payload.installments[i].due_date)
              .add(2, "days")
              .format("YYYY/MM/DD");
            this.payload.installments[i].due_date = due_date;
            this.payload.installments[i].original_due_date = due_date;
          }
        }
        total += Number(this.$helper.strToNum(this.payload.installments[i].value))
        final_value += Number(this.$helper.strToNum(this.payload.installments[i].total_value))
      }
      total_value = this.payload.bill_receive.total_value
        .replace(".", "")
        .replace(",", ".");
      if (this.payload.installments.length) {
        if (Number(total_value).toFixed(2) > Number(total).toFixed(2)) {
          const diff = (Number(total_value) - total).toFixed(2);
          this.payload.installments[0].value = Number(
            Number(this.$helper.strToNum(this.payload.installments[0].value)) + Number(diff)
          ).toFixed(2);
        }
        if (Number(total_value).toFixed(2) < Number(total).toFixed(2)) {
          const diff = Number(total).toFixed(2) - Number(total_value).toFixed(2)
          this.payload.installments[0].value = Number(
            Number(this.$helper.strToNum(this.payload.installments[0].value)) - Number(diff)
          ).toFixed(2);
        }
      }
      this.payload.bill_receive.final_value = Number(final_value).toFixed(2);
    },
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        this.$forceUpdate();
        return checkNumberValue(
          mask1(value.toString(), decimals, ".", true),
          min,
          max
        );
      }
      return checkNumberValue(value, min, max);
    },
    getColor(status) {
      switch (status) {
        case 0:
          return "yellow";
        case 1:
          return "success";
        case 2:
        case 3:
          return "danger";
        case 4:
          return "info";
        case 5:
          return "default";
        case 6:
          return "warning";
        case 7:
        case 11:
          return "indigo";
        case 8:
          return "primary";
        case 9:
          return "danger";
      }
    },
    getTitle(status) {
      switch (status) {
        case 0:
          return "Pendente";
        case 1:
          return "Recebido";
        case 2:
          return "Atrasado";
        case 3:
          return "Cancelado";
        case 4:
          return "Serasa";
        case 5:
          return "Cartório";
        case 6:
          return "Protestado";
        case 7:
          return "Jurídico";
        case 8:
          return "Aprovado";
        case 9:
          return "Negado";
        case 11:
          return "Antecipado";
      }
    },
    async handleCreateModal(id, type, status) {
      this.modalCardIntegrations = false;
      this.has_changed_installment = false
      this.loadingCompanyPlants = true;
      this.$store.dispatch("plant/fetchItemsActive").then(() => {
        this.loadingCompanyPlants = false;
      });
      this.loadingPaymentIntermediaries = true;
      // const installmentUuids = this.payload.installments.map(function (item) {
      //   return item.uuid
      // });
      // const self = this
      // installmentUuids.map(function (uuid) {
      //   for (let indexFile in self.$refs[`input-file-${uuid}`][0].fileRecords) {
      //     self.$refs[`input-file-${uuid}`][0].fileRecords = []
      //   }
      // });
      this.filelist_name = {}
      this.disableSave = false
      let loader = this.$loading.show()
      this.$Progress.start();
      await this.$store.dispatch("billReceive/show", id).then(async response => {
        this.attachments = response.data.bill_receive.attachments
        this.invoices_paid = response.data.invoices_paid
        this.initial_iss = response.data.bill_receive.iss_retain
        this.payload.bill_receive.company_plant_id = response.data.bill_receive.company_plant_id
        this.getBankAccounts()
        this.payload = response.data;
        this.payload.installments = this.payload.installments
          .sort((a, b) => a.index - b.index);
        this.old_installments = {...this.payload.installments}
        this.payload.bill_receive.competence = new Date(moment(this.payload.bill_receive.competence))
        await this.$store.dispatch("contractProposal/show", this.payload.bill_receive.contract_proposal_uuid)
          .then((response) => {
            this.contract_proposal = response.data
            Promise.all([
              this.$store.dispatch("paymentMethod/fetchItems"),
              this.$store.dispatch("paymentIntermediary/fetchItems"),
              this.$store.dispatch("paymentTerm/fetchItems"),
            ]).then((response) => {
              this.loadingPaymentIntermediaries = false;
              this.payment_methods_selected =
                this.payment_methods.filter((item) => this.contract_proposal.payment_methods_uuids.includes(item.uuid))
              this.loadingMethod = false;
              this.loadingTerm = false;
              this.setRequireNsuCode(false, false)
              this.getPaymentTerms(false, false)
              this.modal.create = true;
              this.$refs.files.setFilesByUrl(this.attachments)
              this.$refs.files.removedFiles = []
              // this.payload.installments.map(function (item) {
              //   self.$refs[`input-file-${item.uuid}`][0].setFilesByUrl(item.attachments)
              // })
              loader.hide()
            })
          }).catch(() => {
          })
        const {contract_proposal_status} = this.payload.bill_receive
        // desabilita o salvar se a fatura for concluida ou inativa
        if (contract_proposal_status === 5 || contract_proposal_status === 0) {
          this.disableSave = true
        }
        if ([1, 3].includes(status)) {
          this.blockFields = true
        }
        this.payload.bill_receive.type = type;
        this.payload.bill_receive.billable_id = id
        if (type == 1) {
          await this.$store.dispatch("billingInvoice/init", {
            filter: {
              pending_payment: 1,
              bill_receive_id: this.payload.bill_receive.billable_id,
              contract_proposal_id: this.payload.bill_receive.contract_proposal_id,
              company_plant_id: this.payload.bill_receive.company_plant_id,
              company_plant_issuer_id: this.payload.bill_receive.company_plant_issuer_id,
              not_canceled: true,
            }
          }).then(response => {
            this.$Progress.finish();
            this.calcInvoicedAmount()
          });
        } else {
          let payment_method_id = this.payload.bill_receive
            .payment_method_id;
          this.payload.bill_receive.payment_method_uuid = this.payment_methods.filter(
            function (item) {
              return item.id == payment_method_id;
            }
          )[0].uuid;
          this.$store
            .dispatch(
              "paymentTerm/getByMethod",
              this.payload.bill_receive.payment_method_uuid
            )
            .then(response => {
              let payment_term_id = this.payload.bill_receive
                .payment_term_id;
              this.payload.bill_receive.payment_term_uuid = this.payment_terms.filter(
                function (item) {
                  return item.id == payment_term_id;
                }
              )[0].uuid;
              let company_plant_id = this.payload.bill_receive
                .company_plant_id;

              this.payload.bill_receive.company_plant_uuid = this.plants.filter(
                function (item) {
                  return item.id == company_plant_id;
                }
              )[0].uuid;
              this.$forceUpdate()
              this.modal.create = true;
              loader.hide()
              this.$Progress.finish();
            });
        }
      });
    },
    finalFormat() {
      let payload = JSON.parse(JSON.stringify(this.payload));
      payload.bill_receive.total_value = payload.bill_receive.total_value
        .replaceAll(".", "")
        .replace(",", ".");
      payload.bill_receive.final_value = payload.bill_receive.final_value
        .replaceAll(".", "")
        .replace(",", ".");
      payload.bill_receive.iss_retain = Number(payload.bill_receive.iss_retain
        .replaceAll(".", "")
        .replace(",", "."));
      payload.bill_receive.balance = this.balance
        .replaceAll(".", "")
        .replace(",", ".");
      payload.installments.map(function (obj, key) {
        obj.value = obj.value.replaceAll(".", "").replace(",", ".");
        obj.total_value = obj.total_value.replaceAll(".", "").replace(",", ".");
        delete obj.attachments
      });
      if (payload.bill_receive.type === 0) {
        let company_plant_uuid = payload.bill_receive.company_plant_uuid;
        payload.bill_receive.company_plant_id = this.plants.filter(function (
          item
        ) {
          return item.uuid == company_plant_uuid;
        })[0].id;
        delete payload.bill_receive.company_plant_uuid;
        let payment_term_uuid = payload.bill_receive.payment_term_uuid;
        payload.bill_receive.payment_term_id = this.payment_terms.filter(
          function (item) {
            return item.uuid == payment_term_uuid;
          }
        )[0].id;
        delete payload.bill_receive.payment_term_uuid;
        let payment_method_uuid = payload.bill_receive.payment_method_uuid;
        payload.bill_receive.payment_method_id = this.payment_methods.filter(
          function (item) {
            return item.uuid == payment_method_uuid;
          }
        )[0].id;
        delete payload.bill_receive.payment_method_uuid;
      } else {
        payload.bill_receive.contract_proposal_uuid = this.contract_proposal.uuid;
      }
      payload.bill_receive.billable_id = this.payload.bill_receive.billable_id
      return payload;
    },
    formatAccountLabel(item) {
      return item.name
    },
    validHasSufficientTotalValue() {
      const sumWithInitial = Object.values(this.invoices_paid).reduce(
        (previousValue, currentValue) => Number(previousValue) + Number(currentValue),
        0
      );
      let total = this.payload.bill_receive.total_value
        .replaceAll(".", "")
        .replace(",", ".");
      if (total < sumWithInitial.toFixed(2)) {
        return false
      }
      return true
    },
    validHasSufficientBalance() {
      if (this.balance.replaceAll(".", "")
        .replace(",", ".") > 0) {
        return true
      }
      let balance = 0
      let total = this.payload.bill_receive.total_value
        .replaceAll(".", "")
        .replace(",", ".");
      total = parseFloat(total)
      balance += total
      for (let id of this.payload.invoices) {
        if (balance <= 0) {
          return false
        }
        const invoice = this.invoices.find(item => item.id === id)
        const value = Number(invoice.pending_billing)
        if (balance > value) {
          balance -= value
        } else {
          balance = 0
        }
      }
      return true
    },
    validInstallmentPaid() {
      let pending_before_paid = false
      let has_pending = false
      for (let item of this.payload.installments) {
        if (item.status != 1) {
          has_pending = true
          continue;
        }
        if (item.status === 1 && has_pending) {
          pending_before_paid = true
          break;
        }
      }
      return pending_before_paid
    },
    /**
     * Validar pagamento de comerciante (Transações de cartão de crédito/Débito)
     * @returns boolean
     */
    async isValidPaymentIntermediaryMerchant() {
      // Campos obrigatórios para consulta
      const paymentIntermediaryMerchantIsFilled = this.payload.bill_receive.payment_intermediary_id
        && this.payload.bill_receive.payment_intermediary_merchant_id
        && this.payload.bill_receive.nsu_code;
      if (!paymentIntermediaryMerchantIsFilled) {
        this.$notify({
          type: "danger",
          message: `Quando NSU da transação é informado, é necessário preencher "Mediador de Pagamento" e "Ponto de Venda (PV)".`
        });
        return false;
      }

      // Verificar existência da transação
      // const params = {
      //   id: this.payload.bill_receive.payment_intermediary_merchant_id,
      //   saleDate: this.$helper.parseDate(this.payload.bill_receive.competence, 'YYYY-MM-DD'),
      //   nsuCode: this.payload.bill_receive.nsu_code,
      // };
      // return await this.$store
      //   .dispatch("paymentIntermediaryMerchant/getSaleFromCardIntegrationByNsuCode", params)
      //   .then((response) => {
      //     if (response.data.length === 0){
      //       this.$notify({
      //         type: "danger",
      //         message: `NSU da transação "${params.nsuCode}" não foi encontrado em competência ${this.$helper.parseDate(params.saleDate, 'DD/MM/YYYY')}`,
      //       });
      //       return false
      //     }
      //     let isValid = true;
      //     const {credentialTypeName, amount, installmentQuantity, typeName, tid} = response.data;
      //     const payment_method = this.payment_methods.find((item) => item.uuid === this.payload.bill_receive.payment_method_uuid)
      //     const isCreditTypeName = payment_method && payment_method.id == 2;
      //     this.payload.bill_receive.transaction_code = tid;

      //     // Comparar valor com API de Mediador de Pagamento
      //     if (this.$helper.strToNum(this.payload.bill_receive.total_value) !== amount){
      //       isValid = false;
      //       this.$notify({
      //         type: "danger",
      //         message: `Valor Total (A Receber) cadastrado é diferente do encontrado em ${credentialTypeName}: R$ ${amount.toFixed(2)}.`
      //       });
      //     };

      //     // Comparar quantidade de parcelas com API de Mediador de Pagamento
      //     if (this.payload.installments.length !== installmentQuantity){
      //       isValid = false;
      //       this.$notify({
      //         type: "danger",
      //         message: `Quantidade de Parcelas cadastrada é diferente do encontrado em ${credentialTypeName}: ${installmentQuantity} Parcelas.`
      //       });
      //     }

      //     // Compararar tipo de cartão (Crédito ou Débito)
      //     if (isCreditTypeName && (typeName.toUpperCase() !== 'CREDIT')){
      //       isValid = false;
      //       this.$notify({
      //         type: "danger",
      //         message: `Tipo de Cartão [Crédito/Débito] não confere com o encontrado em ${credentialTypeName}: ${typeName.toUpperCase()}O.`
      //       });
      //     }

      //     return isValid;
      //   }).catch(error => {
      //     const technicalMessage = error.status ? error.data.message : formatErrorValidation(error.response.data.errors);
      //     this.$notify({type: "danger", message: `Transação: ${params.nsuCode} não encontrada.`});
      //     this.$notify({type: "danger", message: `Mensagem técnica: ${technicalMessage}.`});
      //     return false;
      //   });
      return true;
    },
    async store() {
      const has_final_less = this.payload.installments.some((item) => Number(item.total_value.replaceAll(".", "").replace(",", ".")) < 0)
      const has_total_less = this.payload.installments.some((item) => Number(item.value.replaceAll(".", "").replace(",", ".")) <= 0)
      if (has_total_less) {
        this.$notify({
          type: "danger",
          message: 'O valor total da parcela não pode ser igual ou menor a R$ 0,00.'
        });
      }
      if (has_final_less) {
        this.$notify({
          type: "danger",
          message: 'O valor total da parcela não pode ser menor a R$ 0,00.'
        });
      }
      if (has_final_less || has_total_less) {
        return;
      }
      // Verificar NSU da transação quando obrigatório
      const payment_intermediary = this.payment_intemerdiaries_selected.find((item) => item.id === this.payload.bill_receive.payment_intermediary_id);
      const hasCredentials = payment_intermediary && payment_intermediary.has_credentials;
      const payment_method = this.payment_methods.find((item) => item.uuid === this.payload.bill_receive.payment_method_uuid)
      const nsuCodeIsRequired = payment_method && [2, 3].includes(payment_method.id) && hasCredentials;
      if (nsuCodeIsRequired) {
        if (!this.payload.bill_receive.nsu_code) {
          this.$notify({type: "danger", message: `NSU da transação é obrigatório.`});
          return;
        }
        if (!await this.isValidPaymentIntermediaryMerchant()) {
          return;
        }
      }
      const self = this
      const has = this.validHasSufficientBalance()
      if (!has) {
        return this.$notify({type: 'danger', message: 'Não há saldo suficiente para pagamento das faturas.'})
      }
      if (!this.validHasSufficientTotalValue()) {
        return this.$notify({
          type: 'warning',
          message: 'O valor total do pagamento não deve ser inferior ao valor mínimo faturado.'
        })
      }
      if (this.payload.installments.length > 12) {
        return this.$notify({
          type: 'warning',
          message: 'O pagamento deve ter no máximo 12 parcelas. Verifique as parcelas geradas e tente novamente.'
        })
      }
      /** Regra removida pois não há mais validação de ordem de pagamento e nem pagamento no editar */
      // const correctInstallmentPaid = this.validInstallmentPaid()
      // if (correctInstallmentPaid) {
      //   return this.$notify({
      //     type: 'danger',
      //     message: 'Para pagamento das demais parcelas, a parcela anterior deve estar paga.'
      //   })
      // }
      this.payload.bill_receive.competence = new moment(this.payload.bill_receive.competence).format('YYYY-MM-DD')
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$Progress.start();
      this.loadingStore = true;
      let formData = new FormData();
      let removedItems = []
      self.$refs.files.removedFiles.map(function (url) {
        if (url !== null) {
          removedItems.push(url)
        }
      })
      for (let indexFile in self.$refs.files.fileRecords) {
        formData.append(indexFile, self.$refs.files.fileRecords[indexFile]['file']);
      }
      formData.append('removed_files', JSON.stringify(removedItems))
      const payload = this.finalFormat()
      for (let index in payload) {
        formData.append(index, JSON.stringify(payload[index]))
      }
      formData.append('id', payload.bill_receive.id)
      formData.append('has_changed_installment', +this.has_changed_installment)
      formData.append('entity_id', this.contract_proposal.entity.id)
      this.$store
        .dispatch("billReceive/update", formData, {
          headers: {
            "content-type": "multipart/form-data"
          }
        })
        .then(response => {
          this.loadingStore = false;
          this.closeModal();
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.$emit('updated', this.payload.bill_receive.billable_id)
        })
        .catch(error => {
          if (error && error.response && error.response.status === 422) {
            let message = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: message
            });
          } else {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            });
          }
          this.$Progress.finish();
          this.loadingStore = false;
        })
    },
    handleFileChange(index) {
      const files = this.$refs['attachments' + index][0].files
      this.attachments[index] = files
      switch (files.length) {
        case 0:
          this.filelist_name[index] = 'Nenhum arquivo selecionado'
          break;
        case 1:
          this.filelist_name[index] = files[0].name
          break;
        default:
          this.filelist_name[index] = files.length + ' arquivos selecionados'
          break;
      }
    },
    getPaymentTerms(set_installments = false, clear_payment_term_uuid = true) {
      if (clear_payment_term_uuid) {
        this.payload.bill_receive.payment_term_uuid = null
      }
      const payment_methods_selected = [this.payload.bill_receive.payment_method_uuid]
      const self = this
      this.payment_terms_selected = this.payment_terms.filter(function (item) {
        for (var payment_method of item.payment_methods) {
          if (payment_methods_selected.includes(payment_method.uuid)
            && self.contract_proposal.payment_terms_uuids.includes(item.uuid)) {
            return item;
          }
        }
      })
      if (this.contract_proposal.payment_terms_uuids.length === 1 && clear_payment_term_uuid) {
        const payment_term_uuid = this.contract_proposal.payment_terms_uuids[0]
        const exists = this.payment_terms_selected.find((item) => item.uuid = payment_term_uuid)
        if (exists) {
          this.payload.bill_receive.payment_term_uuid = payment_term_uuid
        }
      }
      if (set_installments) {
        this.generateInstallments(0, true, true)
      }
    },
    generateInstallments(recalc = 0, msg = false, keep_original_due_date = false) {
      if (msg) {
        this.$swal
          .fire({
            title: "Você tem certeza?",
            text: "Essa modificação afeta as parcelas existentes, ao alterar esta informação você perderá os registros das parcelas desta solicitação!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim, apague as parcelas!",
            cancelButtonText: "Não, mantenha",
            customClass: {
              confirmButton: "btn btn-success btn-fill",
              cancelButton: "btn btn-danger btn-fill"
            },
            buttonsStyling: false
          })
          .then(result => {
            if (!result.isConfirmed) {
              this.payload.bill_receive.iss_retain = this.initial_iss
              return
            } else {
              this.has_changed_installment = false
              if (recalc === 1) {
                const installmentUuids = this.payload.installments.map(function (item) {
                  return item.uuid
                });
                const self = this
                installmentUuids.map(function (uuid) {
                  for (let indexFile in self.$refs[`input-file-${uuid}`][0].fileRecords) {
                    self.$refs[`input-file-${uuid}`][0].fileRecords = []
                  }
                });
                this.payload.bill_receive.final_value = this.payload.bill_receive.total_value;
              }
              if (this.payload.bill_receive.payment_term_uuid && this.payload.bill_receive.payment_method_uuid) {
                let self = this;
                let uuid = this.payload.bill_receive.payment_term_uuid;
                let payment_term = this.payment_terms.filter(
                  item => item.uuid === uuid
                )[0];

                let payment_method = this.payment_methods.find(
                  item => item.uuid === self.payload.bill_receive.payment_method_uuid
                );
                /*
                 * Buscamos as parcelas da condição de pagamento
                 */
                let installments = payment_term.installments;
                let payload_installments = [];
                /*
                 * Percorremos as parcelas de condição de pagamento
                 * e montamos as nossas para pagamento de proposta contrato.
                 */
                // valor total
                const total = Number(self.payload.bill_receive.total_value
                  .replaceAll(".", "")
                  .replace(",", "."))
                // valor retido
                const iss_retain = Number(self.payload.bill_receive.iss_retain
                  .replaceAll(".", "")
                  .replace(",", "."))
                const total_value = total - iss_retain

                const payment_intermediary = this.$_payment_intermediaries.find((item) => item.id === this.payload.bill_receive.payment_intermediary_id)
                if (payment_intermediary) {
                  let total_percentage_tax = 0
                  // SE FOR CRÉDITO PARCELADO ACUMULADO
                  /** checked */
                  if (payment_intermediary.credit_in_installments.receipt_method === 0 &&
                    payment_method.payment_type === 1 && payment_term.total_installment > 1) {
                    // Object.values(payment_intermediary.additional_installments).map(function (obj) {
                    //   if (obj.installment <= payment_term.total_installment) {
                    //     total_percentage_tax += Number(obj.tax_percentage.toString().replace(',', '.'))
                    //   }
                    // })
                    const deadline = payment_intermediary.credit_in_installments.deadline
                    total_percentage_tax = Object.values(payment_intermediary
                      .additional_installments).find((item) => item.installment === payment_term.total_installment).tax_percentage
                    total_percentage_tax = Number(total_percentage_tax.toString().replace(',', '.'))

                    let date = moment(self.payload.bill_receive.competence)
                      .add("days", deadline)
                      .format("YYYY-MM-DD");

                    let discounts = parseFloat(total_percentage_tax).toFixed(2);
                    let tax_percentage_intermediary = parseFloat(payment_intermediary.credit_in_installments.tax_percentage.toString().replace(',', '.'))
                    discounts = parseFloat(discounts) + tax_percentage_intermediary
                    discounts = (discounts / 100) * total_value.toFixed(2);
                    discounts += Number(payment_intermediary.credit_in_installments.tax_monetary.toString().replace(',', '.'))
                    let installment = {
                      value: total_value.toFixed(2),
                      index: 1,
                      total_value: (total_value - discounts).toFixed(2),
                      due_date: date,
                      original_due_date: this.getOriginalDueDate(keep_original_due_date, 0, date),
                      status: 0,
                      paid_at: null,
                      fees_fines: null,
                      discounts: null,
                      discounts_fees: discounts.toFixed(2)
                    };
                    this.payload.installments = [installment]
                    return this.calcFinalValue()
                  }
                  // SE FOR CRÉDITO PARCELADO COM RECEBIMENTO PARCELADO
                  /** checked */
                  if (payment_intermediary.credit_in_installments.receipt_method === 1 &&
                    payment_method.payment_type === 1 && payment_term.total_installment > 1) {
                    // Object.values(payment_intermediary.additional_installments).map(function (obj) {
                    //   if (obj.installment <= payment_term.total_installment) {
                    //     total_percentage_tax += Number(obj.tax_percentage.toString().replace(',', '.'))
                    //   }
                    // })
                    total_percentage_tax = Object.values(payment_intermediary
                      .additional_installments).find((item) => item.installment === payment_term.total_installment).tax_percentage
                    total_percentage_tax = Number(total_percentage_tax.toString().replace(',', '.'))

                    let discounts = parseFloat(total_percentage_tax).toFixed(2);
                    let tax_percentage_intermediary = parseFloat(payment_intermediary.credit_in_installments.tax_percentage.toString().replace(',', '.'))
                    discounts = parseFloat(discounts) + tax_percentage_intermediary
                    discounts = (discounts / 100) * total_value.toFixed(2);
                    discounts += Number(payment_intermediary.credit_on_sight.tax_monetary.toString().replace(',', '.'))
                    installments.map(function (obj, key) {
                      let date = moment(self.payload.bill_receive.competence)
                        .add("days", obj.deadline)
                        .format("YYYY-MM-DD");

                      let percentage = parseFloat(obj.percentage);
                      let value = (percentage / 100) * total_value;

                      let installment = {
                        value: value.toFixed(2),
                        index: obj.index,
                        total_value: (value - (discounts / payment_term.total_installment)).toFixed(2),
                        due_date: date,
                        original_due_date: this.getOriginalDueDate(keep_original_due_date, key, date),
                        status: 0,
                        paid_at: null,
                        fees_fines: null,
                        discounts: null,
                        discounts_fees: (discounts / payment_term.total_installment).toFixed(2)
                      };
                      payload_installments.push(installment);
                    }, this)
                    this.payload.installments = payload_installments
                    return this.calcFinalValue()
                  }
                  // SE FOR CRÉDITO A VISTA
                  if (payment_method.payment_type === 1 && payment_term.total_installment === 1) {
                    const deadline = payment_intermediary.credit_on_sight.deadline
                    total_percentage_tax = Number(payment_intermediary.credit_on_sight.tax_percentage.toString().replace(',', '.'))
                    let date = moment(self.payload.bill_receive.competence)
                      .add("days", deadline)
                      .format("YYYY-MM-DD");

                    let discounts = parseFloat(total_percentage_tax).toFixed(2);
                    discounts = (discounts / 100) * total_value.toFixed(2);
                    discounts += Number(payment_intermediary.credit_on_sight.tax_monetary.toString().replace(',', '.'))
                    let installment = {
                      value: total_value.toFixed(2),
                      index: 1,
                      total_value: (total_value - discounts).toFixed(2),
                      due_date: date,
                      original_due_date: this.getOriginalDueDate(keep_original_due_date, 0, date),
                      status: 0,
                      paid_at: null,
                      fees_fines: null,
                      discounts: null,
                      discounts_fees: discounts.toFixed(2)
                    };
                    this.payload.installments = [installment]
                    return this.calcFinalValue()
                  }
                  // SE FOR DÉBITO A VISTA
                  if (payment_method.payment_type === 0 && payment_term.total_installment === 1) {
                    total_percentage_tax = Number(payment_intermediary.debit.tax_percentage.toString().replace(',', '.'))
                    const deadline = payment_intermediary.debit.deadline
                    let date = moment(self.payload.bill_receive.competence)
                      .add("days", parseInt(deadline) + installments[0].deadline)
                      .format("YYYY-MM-DD");
                    let discounts = total_percentage_tax.toFixed(2);
                    discounts = (discounts / 100) * total_value.toFixed(2);
                    discounts += Number(payment_intermediary.debit.tax_monetary.toString().replace(',', '.'))
                    let installment = {
                      value: total_value.toFixed(2),
                      index: 1,
                      total_value: (total_value - discounts).toFixed(2),
                      due_date: date,
                      original_due_date: this.getOriginalDueDate(keep_original_due_date, 0, date),
                      status: 0,
                      paid_at: null,
                      fees_fines: null,
                      discounts: null,
                      discounts_fees: discounts.toFixed(2)
                    };
                    this.payload.installments = [installment]
                    return this.calcFinalValue()
                  }
                  // SE FOR DÉBITO PARCELADO
                  if (payment_method.payment_type === 0 && payment_term.total_installment > 1) {
                    total_percentage_tax = Number(payment_intermediary.debit.tax_percentage.toString().replace(',', '.'))
                    let discounts = total_percentage_tax.toFixed(2);
                    discounts = (discounts / 100) * total_value.toFixed(2);
                    discounts += Number(payment_intermediary.debit.tax_monetary.toString().replace(',', '.'))
                    installments.map(function (obj, key) {
                      let date = moment(self.payload.bill_receive.competence)
                        .add("days", obj.deadline)
                        .format("YYYY-MM-DD");

                      let percentage = parseFloat(obj.percentage);
                      let value = (percentage / 100) * total_value;

                      let installment = {
                        value: value.toFixed(2),
                        index: obj.index,
                        total_value: (value - discounts).toFixed(2),
                        due_date: date,
                        original_due_date: this.getOriginalDueDate(keep_original_due_date, key, date),
                        status: 0,
                        paid_at: null,
                        fees_fines: null,
                        discounts: null,
                        discounts_fees: discounts.toFixed(2)
                      };
                      payload_installments.push(installment);
                    }, this)
                    this.payload.installments = payload_installments
                    return this.calcFinalValue()
                  }
                }

                installments.map(function (obj, key) {
                  /*
                   * Calculamos a data de vencimento a atribuir.
                   */
                  let date = moment(self.payload.bill_receive.competence)
                    .add("days", obj.deadline)
                    .format("YYYY-MM-DD");

                  /*
                   * Calculamos o valor da parcela.
                   */
                  let payment_total_value = total_value
                  let percentage = parseFloat(obj.percentage);
                  let value = (percentage / 100) * payment_total_value;

                  let installment = {
                    value: value.toFixed(2),
                    index: obj.index,
                    total_value: value.toFixed(2),
                    due_date: date,
                    original_due_date: this.getOriginalDueDate(keep_original_due_date, key, date),
                    status: 0,
                    paid_at: null,
                    fees_fines: null,
                    discounts: null,
                    discounts_fees: null
                  };
                  payload_installments.push(installment);
                }, this);
                this.payload.installments = payload_installments;
              }
              // if (this.payment_intermediaries_exists) {
              //   this.payload.installments = []
              // }
              this.$forceUpdate();
              this.calcFinalValue()
              this.calcInvoicedAmount()
            }
          })
      } else {
        this.has_changed_installment = false
        if (recalc === 1) {
          const installmentUuids = this.payload.installments.map(function (item) {
            return item.uuid
          });
          const self = this
          installmentUuids.map(function (uuid) {
            for (let indexFile in self.$refs[`input-file-${uuid}`][0].fileRecords) {
              self.$refs[`input-file-${uuid}`][0].fileRecords = []
            }
          });
          this.payload.bill_receive.final_value = this.payload.bill_receive.total_value;
        }
        if (this.payload.bill_receive.payment_term_uuid && this.payload.bill_receive.payment_method_uuid) {
          let self = this;
          let uuid = this.payload.bill_receive.payment_term_uuid;
          let payment_term = this.payment_terms.filter(
            item => item.uuid === uuid
          )[0];

          let payment_method = this.payment_methods.find(
            item => item.uuid === self.payload.bill_receive.payment_method_uuid
          );
          /*
           * Buscamos as parcelas da condição de pagamento
           */
          let installments = payment_term.installments;
          let payload_installments = [];
          /*
           * Percorremos as parcelas de condição de pagamento
           * e montamos as nossas para pagamento de proposta contrato.
           */
          // valor total
          const total = Number(self.payload.bill_receive.total_value
            .replaceAll(".", "")
            .replace(",", "."))
          // valor retido
          const iss_retain = Number(self.payload.bill_receive.iss_retain
            .replaceAll(".", "")
            .replace(",", "."))
          const total_value = total - iss_retain

          const payment_intermediary = this.$_payment_intermediaries.find((item) => item.id === this.payload.bill_receive.payment_intermediary_id)
          if (payment_intermediary) {
            let total_percentage_tax = 0
            // SE FOR CRÉDITO PARCELADO ACUMULADO
            /** checked */
            if (payment_intermediary.credit_in_installments.receipt_method === 0 &&
              payment_method.payment_type === 1 && payment_term.total_installment > 1) {
              // Object.values(payment_intermediary.additional_installments).map(function (obj) {
              //   if (obj.installment <= payment_term.total_installment) {
              //     total_percentage_tax += Number(obj.tax_percentage.toString().replace(',', '.'))
              //   }
              // })
              const deadline = payment_intermediary.credit_in_installments.deadline
              total_percentage_tax = Object.values(payment_intermediary
                .additional_installments).find((item) => item.installment === payment_term.total_installment).tax_percentage
              total_percentage_tax = Number(total_percentage_tax.toString().replace(',', '.'))

              let date = moment(self.payload.bill_receive.competence)
                .add("days", deadline)
                .format("YYYY-MM-DD");

              let discounts = parseFloat(total_percentage_tax).toFixed(2);
              let tax_percentage_intermediary = parseFloat(payment_intermediary.credit_in_installments.tax_percentage.toString().replace(',', '.'))
              discounts = parseFloat(discounts) + tax_percentage_intermediary
              discounts = (discounts / 100) * total_value.toFixed(2);
              discounts += Number(payment_intermediary.credit_in_installments.tax_monetary.toString().replace(',', '.'))
              let installment = {
                value: total_value.toFixed(2),
                index: 1,
                total_value: (total_value - discounts).toFixed(2),
                due_date: date,
                original_due_date: this.getOriginalDueDate(keep_original_due_date, 0, date),
                status: 0,
                paid_at: null,
                fees_fines: null,
                discounts: null,
                discounts_fees: discounts.toFixed(2)
              };
              this.payload.installments = [installment]
              return this.calcFinalValue()
            }
            // SE FOR CRÉDITO PARCELADO COM RECEBIMENTO PARCELADO
            /** checked */
            if (payment_intermediary.credit_in_installments.receipt_method === 1 &&
              payment_method.payment_type === 1 && payment_term.total_installment > 1) {
              // Object.values(payment_intermediary.additional_installments).map(function (obj) {
              //   if (obj.installment <= payment_term.total_installment) {
              //     total_percentage_tax += Number(obj.tax_percentage.toString().replace(',', '.'))
              //   }
              // })
              total_percentage_tax = Object.values(payment_intermediary
                .additional_installments).find((item) => item.installment === payment_term.total_installment).tax_percentage
              total_percentage_tax = Number(total_percentage_tax.toString().replace(',', '.'))

              let discounts = parseFloat(total_percentage_tax).toFixed(2);
              let tax_percentage_intermediary = parseFloat(payment_intermediary.credit_on_sight.tax_percentage.toString().replace(',', '.'))
              discounts = parseFloat(discounts) + tax_percentage_intermediary
              discounts = (discounts / 100) * total_value.toFixed(2);
              discounts += Number(payment_intermediary.credit_on_sight.tax_monetary.toString().replace(',', '.'))
              installments.map(function (obj, key) {
                let date = moment(self.payload.bill_receive.competence)
                  .add("days", obj.deadline)
                  .format("YYYY-MM-DD");

                let percentage = parseFloat(obj.percentage);
                let value = (percentage / 100) * total_value;

                let installment = {
                  value: value.toFixed(2),
                  index: obj.index,
                  total_value: (value - (discounts / payment_term.total_installment)).toFixed(2),
                  due_date: date,
                  original_due_date: this.getOriginalDueDate(keep_original_due_date, key, date),
                  status: 0,
                  paid_at: null,
                  fees_fines: null,
                  discounts: null,
                  discounts_fees: (discounts / payment_term.total_installment).toFixed(2)
                };
                payload_installments.push(installment);
              }, this)
              this.payload.installments = payload_installments
              return this.calcFinalValue()
            }
            // SE FOR CRÉDITO A VISTA
            if (payment_method.payment_type === 1 && payment_term.total_installment === 1) {
              const deadline = payment_intermediary.credit_on_sight.deadline
              total_percentage_tax = Number(payment_intermediary.credit_on_sight.tax_percentage.toString().replace(',', '.'))
              let date = moment(self.payload.bill_receive.competence)
                .add("days", deadline)
                .format("YYYY-MM-DD");

              let discounts = parseFloat(total_percentage_tax).toFixed(2);
              discounts = (discounts / 100) * total_value.toFixed(2);
              discounts += Number(payment_intermediary.credit_on_sight.tax_monetary.toString().replace(',', '.'))
              let installment = {
                value: total_value.toFixed(2),
                index: 1,
                total_value: (total_value - discounts).toFixed(2),
                due_date: date,
                original_due_date: this.getOriginalDueDate(keep_original_due_date, 0, date),
                status: 0,
                paid_at: null,
                fees_fines: null,
                discounts: null,
                discounts_fees: discounts.toFixed(2)
              };
              this.payload.installments = [installment]
              return this.calcFinalValue()
            }
            // SE FOR DÉBITO A VISTA
            if (payment_method.payment_type === 0 && payment_term.total_installment === 1) {
              const deadline = payment_intermediary.debit.deadline
              total_percentage_tax = Number(payment_intermediary.debit.tax_percentage.toString().replace(',', '.'))
              let date = moment(self.payload.bill_receive.competence)
                .add("days", parseInt(deadline) + installments[0].deadline)
                .format("YYYY-MM-DD");

              let discounts = parseFloat(total_percentage_tax).toFixed(2);
              discounts = (discounts / 100) * total_value.toFixed(2);
              discounts += Number(payment_intermediary.debit.tax_monetary.toString().replace(',', '.'))
              let installment = {
                value: total_value.toFixed(2),
                index: 1,
                total_value: (total_value - discounts).toFixed(2),
                due_date: date,
                original_due_date: this.getOriginalDueDate(keep_original_due_date, 0, date),
                status: 0,
                paid_at: null,
                fees_fines: null,
                discounts: null,
                discounts_fees: discounts.toFixed(2)
              };
              this.payload.installments = [installment]
              return this.calcFinalValue()
            }
            // SE FOR DÉBITO PARCELADO
            if (payment_method.payment_type === 0 && payment_term.total_installment > 1) {
              total_percentage_tax = Number(payment_intermediary.debit.tax_percentage.toString().replace(',', '.'))

              let discounts = parseFloat(total_percentage_tax).toFixed(2);
              discounts = (discounts / 100) * total_value.toFixed(2);
              discounts += Number(payment_intermediary.debit.tax_monetary.toString().replace(',', '.'))
              installments.map(function (obj, key) {
                let date = moment(self.payload.bill_receive.competence)
                  .add("days", obj.deadline)
                  .format("YYYY-MM-DD");

                let percentage = parseFloat(obj.percentage);
                let value = (percentage / 100) * total_value;

                let installment = {
                  value: value.toFixed(2),
                  index: obj.index,
                  total_value: (value - discounts).toFixed(2),
                  due_date: date,
                  original_due_date: this.getOriginalDueDate(keep_original_due_date, key, date),
                  status: 0,
                  paid_at: null,
                  fees_fines: null,
                  discounts: null,
                  discounts_fees: discounts.toFixed(2)
                };
                payload_installments.push(installment);
              }, this)
              this.payload.installments = payload_installments
              return this.calcFinalValue()
            }
          }

          installments.map(function (obj, key) {
            /*
             * Calculamos a data de vencimento a atribuir.
             */
            let date = moment(self.payload.bill_receive.competence)
              .add("days", obj.deadline)
              .format("YYYY-MM-DD");

            /*
             * Calculamos o valor da parcela.
             */
            let payment_total_value = total_value
            let percentage = parseFloat(obj.percentage);
            let value = (percentage / 100) * payment_total_value;

            let installment = {
              value: value.toFixed(2),
              index: obj.index,
              total_value: value.toFixed(2),
              due_date: date,
              original_due_date: this.getOriginalDueDate(keep_original_due_date, key, date),
              status: 0,
              paid_at: null,
              fees_fines: null,
              discounts: null,
              discounts_fees: null
            };
            payload_installments.push(installment);
          }, this);
          this.payload.installments = payload_installments;
        }
        // if (this.payment_intermediaries_exists) {
        //   this.payload.installments = []
        // }
        this.$forceUpdate();
        this.calcFinalValue()
        this.calcInvoicedAmount()
      }
    },
    async calcTotalValue(installment, index) {
      let discounts = 0;
      let fees = 0;
      let current_total_value = 0;
      let current_value = 0;
      current_total_value = parseFloat(
        installment.value.replaceAll(".", "").replace(",", ".")
      );
      current_value = parseFloat(
        installment.value.replaceAll(".", "").replace(",", ".")
      );
      if (installment.fees_fines) {
        fees = parseFloat(installment.fees_fines);
        fees = (fees / 100) * current_value;
      }
      current_total_value -= Number(installment.discounts_fees.replaceAll(".", "").replace(",", "."));
      current_total_value -= Number(installment.discounts.replaceAll(".", "").replace(",", "."));
      current_total_value += Number(installment.fees_fines.replaceAll(".", "").replace(",", "."));
      this.payload.installments[
        index
        ].total_value = current_total_value.toFixed(2);
      this.$forceUpdate();
      await this.calcTotalValueToReceive();
      this.calcFinalValue();
    },
    initEntity() {
      /* this.loadingSpinner = true;
       this.$store
         .dispatch("entity/fetchItems")
         .then(response => {
           this.loadingSpinner = false;
           this.payload.bill_receive.type = 0;
         })
         .catch(() => {
         }); */
    },
    initContractProposal() {
      this.$refs.modalSearchContract.openModal();
    },
    calcInvoicedAmount() {
      const self = this;
      let total_value = 0;
      this.payload.invoices.map(function (id) {
        let invoice = self.invoices.find(function (item) {
          return item.id === id;
        });
        const paid_for_this_payment = self.invoices_paid[invoice.id] || 0
        total_value += Number(invoice.pending_billing) + Number(paid_for_this_payment);
      });
      // VALOR TOTAL DAS FATURAS
      this.invoiced_amount = total_value.toFixed(2);
      const payment_total_value = this.payload.bill_receive.total_value.includes(",") ?
        this.payload.bill_receive.total_value.replaceAll(".", "").replace(",", ".") :
        this.payload.bill_receive.total_value;
      // SALDO
      this.balance = parseFloat(payment_total_value)
        - parseFloat(this.invoiced_amount.replace('.', '').replace(',', ''))
      if (this.balance < 0) {
        this.balance = 0
      }
    },
  },
  mounted() {
    this.loadingCompanyPlantIssuers = true
    this.$store.dispatch('companyPlantIssuer/fetchItems')
      .then(response => {
        this.loadingCompanyPlantIssuers = false
      })
      .catch(() => {
        this.loadingCompanyPlantIssuers = false
      });
    this.$watch('payload.installments', function () {
      const self = this
      this.hasInstallmentPaid = false
      self.payload.installments.forEach(function (item) {
        if ([1, 9, 8, 3].includes(Number(item.status))) {
          self.hasInstallmentPaid = true
        }
      })
    }, {
      immediate: true,
      deep: true
    })
    /*this.$store
      .dispatch("entity/fetchItems") */
  }
};
</script>

<style scoped>
.bill-modal-create {
  overflow-y: scroll !important;
}
</style>
