var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('base-header',{staticClass:"bg-gray-content"},[_c('div',{staticClass:"row align-items-center py-4"},[_c('div',{staticClass:"col-lg-12"},[_c('h6',{staticClass:"h2 text-gray d-inline-block mb-0"},[_vm._v(_vm._s(_vm.$route.name))]),_c('nav',{staticClass:"d-none d-md-inline-block ml-md-4 text-gray",attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-home"})])]),_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Cadastro")])]),_c('li',{staticClass:"breadcrumb-item",attrs:{"aria-current":"page"}},[_vm._v(_vm._s(_vm.$route.name))])])])])]),_c('div',{staticClass:"row pl-2"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-2 col-xlg-2 mb-3 pr-3 pl-2"},[_c('router-link',{attrs:{"to":{
              path: '/commercial/concrete-price/parameter/create',
              name: 'Parâmetros de preços mínimo - formulação'
            }}},[_c('base-button',{attrs:{"block":"","size":"lg","type":"success"}},[_c('img',{staticClass:"imgBaseButton",attrs:{"src":"/img/icons/plus-math--v1-white.png","width":"20px","height":"20px"}}),_vm._v(" NOVO ")])],1)],1),_c('div',{staticClass:"col-md-2 col-xlg-2 mb-3 pr-3 pl-2"},[_c('router-link',{attrs:{"to":{
              path: '/commercial/concrete-price',
              name: 'commercial.concrete-price.index'
            }}},[_c('base-button',{staticClass:"text-uppercase",attrs:{"block":"","size":"lg","type":"back"}},[_c('img',{staticClass:"imgBaseButton",attrs:{"src":"/img/icons/circled-left.png","width":"20px","height":"20px"}}),_vm._v(" VOLTAR ")])],1)],1),_c('div',{staticClass:"col-md-2 col-xlg-2 mb-3"},[_c('div',{staticClass:"row"},[_c('base-dropdown',{staticClass:"col-md-12 ml-2 mr-3 mb-3 ml-0 p-0"},[_c('base-button',{staticClass:"dropdown-toggle text-uppercase",attrs:{"slot":"title-container","block":"","size":"lg","type":"primary"},slot:"title-container"},[_c('img',{staticClass:"imgBaseButton",attrs:{"src":"/img/icons/plus--v1.png","width":"20px","height":"20px"}}),_vm._v(" ACRÉSCIMOS ")]),_c('div',[_c('a',{staticClass:"dropdown-item",on:{"click":_vm.handleEdit}},[_vm._v(" Tipo de formulação ")]),_c('div',{staticClass:"dropdown-divider p-0 m-0"}),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":_vm.handleCreateConcretedPiece}},[_vm._v(" Peça a concretar ")]),_c('div',{staticClass:"dropdown-divider p-0 m-0"}),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":_vm.handleCreatePuzlAdmixture}},[_vm._v(" Puzl Admix ")]),_c('div',{staticClass:"dropdown-divider p-0 m-0"}),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":_vm.handleCreateSlumpFinal}},[_vm._v(" Slump final ")]),_c('div',{staticClass:"dropdown-divider p-0 m-0"}),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":_vm.handleCreateLevelResponsibility}},[_vm._v(" Nível de aplicação ")])])],1)],1)])])])])]),_c('list-service-price'),_c('modal-create-mix-type'),_c('modal-create-mix-concreted-pieces'),_c('modal-create-mix-puzl-admixture'),_c('modal-create-mix-slump-flow'),_c('modal-create-level-responsibility')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }