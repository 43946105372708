<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div class="form-group row m-0 p-0">
                <label class="col-md-12 pb-0 mb-1 col-form-label form-control-label">
                    Intermediário
                    <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-12 mb-1 pr-4">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <PuzlSelect
                        v-model="user_intermediary_id" 
                        :items="$_userIntermediaries"
                        :loading="loadingUserIntermediaries" 
                        :disabled="loadingUserIntermediaries" />
                    </base-input>
                  </validation-provider>
                </div>
            </div>

            <div class="modal-footer">
              <base-button type="danger" @click="closeModal('create')">
                <img src="/img/icons/cancel-white.png" width="22px" height="22px" />
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                <img src="/img/icons/save.png" width="22px" height="22px" />
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "ModalEditIntermediary",
  components: { PuzlSelect },
  data() {
      return {
        modal: {
          title: 'Alterar intermediador da fatura',
          create: false,
        },
        user_intermediary_id: null,
        invoiceId: null,
        loadingStore: false,
        loadingUserIntermediaries: true,
      };
  },
  computed: {
    ...mapGetters({
      $_userIntermediaries: 'user/getActiveUsersIntermediaries',
    }),
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal(invoiceId) {
      this.invoiceId = invoiceId;
      this.user_intermediary_id = null;
      this.loadData();
      this.modal.create = true;
    },
    store() {
      this.$Progress.start();
      this.loadingStore = true;
      const params = {
        id: this.invoiceId,
        user_intermediary_id: this.user_intermediary_id,
      };
      this.$store
        .dispatch("billingInvoice/updateUserIntermediaryInvoice", params)
        .then(response => {
            this.$emit("updatedUserIntermediary", response.data);
            this.$notify({ type: "success", message: response.message });
            this.closeModal();
          }).catch((error) => {
            const errors = error && error.response && error.response.status === 422
              ? formatErrorValidation(error.response.data.errors)
              : error.data.message;
            this.$notify({ type: "danger", message: errors });            
          }).finally(() => {
            this.loadingStore = false;
            this.$Progress.finish();
          });
    },
    loadData() {
      this.loadingUserIntermediaries = true;
      this.$store.dispatch("user/userGetAllIntermediary").then(() => {
        this.loadingUserIntermediaries = false;
      });
    },
  },
};
</script>

<style scoped></style>
