<template>
  <div>
    <modal :useRerender="false" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
            <div>
              <div class="border-left border-3 border-primary pr-1 mt-0 mb-2" v-if="contract_proposal">
                <div class="col-md-12">
                  <span class="display-5 mb-0">
                    <b>
                    {{
                        (contract_proposal && contract_proposal.construction && contract_proposal.construction.construction_name) || ""
                      }}
                    </b>
                  </span>
                  <br>
                  <span class="display-5 mb-0">
                    <b>
                      {{
                        (contract_proposal &&
                          contract_proposal.construction &&
                          contract_proposal.construction.customer_construction &&
                          contract_proposal.construction.customer_construction.customer_name) ||
                        ""
                      }}                    </b>
                  </span>
                  <br>
                  <span class="display-5 mb-0">
                    <b>
                    {{
                        (contract_proposal && contract_proposal.service_receiver && contract_proposal.service_receiver.document) || ""
                      }}                </b>
                  </span>
                </div>
              </div>
              <div class="row mb-2 m-0 p-0">
                <div class="col-md-3 mb-1 p-1">
                  <base-button size="sm"
                               :class="type === 1 && 'active'"
                               @click.prevent="type = 1" block
                               outline
                               type="primary">Pagamento
                  </base-button>
                </div>
                <div class="col-md-3 mb-1 p-1">
                  <base-button size="sm"
                               :disabled="!$hasPermission('additional_permissions.financial_approvation')"
                               :class="type === 2 && 'active'"
                               @click.prevent="type = 2" block outline
                               type="primary">Faturas
                  </base-button>
                </div>
                <div class="col-md-3 p-1">
                  <base-button size="sm"
                               :class="type === 3 && 'active'"
                               :disabled="payload.installments.length < 1"
                               @click.prevent="type = 3" block outline
                               type="primary">Parcelas
                  </base-button>
                </div>
                <div class="col-md-3 p-1">
                  <base-button size="sm"
                               :class="type === 4 && 'active'"
                               @click.prevent="type = 4" block outline
                               type="primary">Anexos
                  </base-button>
                </div>
              </div>
              <section v-show="type == 1">
                <div class="form-group row m-0 p-0 mb-1 limited-row">
                  <label class="col-md-6 pb-1 pt-2 col-form-label form-control-label">
                    Competência
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-6">
                    <validation-provider rules="required">
                      <base-input input-group-classes="input-group-sm">
                        <input-date-time-picker
                          @input="generateInstallments(0, false, true)"
                          :disabled="payload.payment.started || payload.payment.any_has_billet"
                          :mode="'date'"
                          v-model="payload.payment.competence"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-2 limited-row">
                  <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label">
                    Valor total (a receber)
                    <span class="text-danger">&nbsp;*&nbsp;</span>
                  </label>
                  <div class="col-md-6">
                    <base-input input-group-classes="input-group-sm">
                      <input disabled
                             @blur="generateInstallments(1, true)" v-money="money"
                             @keydown="$event.key === '-' ? $event.preventDefault() : null"
                             maxlength="13" inputmode="numeric" v-model.lazy="payload.payment.total_value" type="text"
                             class="form-control form-control-sm"/>
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0">
                          R$
                        </small>
                      </template>
                    </base-input>
                  </div>
                </div>
                <!--                <div class="form-group row m-0 p-0 mb-2 limited-row">-->
                <!--                  <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label">-->
                <!--                    Retenção ISS-->
                <!--                  </label>-->
                <!--                  <div class="col-md-6">-->
                <!--                    <base-input input-group-classes="input-group-sm">-->
                <!--                      <input-->
                <!--                        disabled-->
                <!--                        @keydown="$event.key === '-' ? $event.preventDefault() : null"-->
                <!--                        @blur="generateInstallments(1, true)"-->
                <!--                        v-money="money"-->
                <!--                        maxlength="10" inputmode="numeric"-->
                <!--                        v-model.lazy="payload.payment.iss_retain" type="text"-->
                <!--                        class="form-control form-control-sm"/>-->
                <!--                      <template slot="prepend">-->
                <!--                        <small class="input-group-sm p-0 m-0">-->
                <!--                          R$-->
                <!--                        </small>-->
                <!--                      </template>-->
                <!--                    </base-input>-->
                <!--                  </div>-->
                <!--                </div>-->
                <div class="form-group row m-0 p-0 mb-2 limited-row">
                  <label class="col-md-6 pt-2 pb-0 col-form-label form-control-label">
                    Valor Final
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-6">
                    <base-input input-group-classes="input-group-sm">
                      <input disabled v-money="money" maxlength="13" inputmode="numeric"
                             @keydown="$event.key === '-' ? $event.preventDefault() : null"
                             v-model.lazy="payload.payment.final_value" type="text"
                             class="form-control form-control-sm"/>
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0">
                          R$
                        </small>
                      </template>
                    </base-input>
                  </div>
                </div>

                <div class="card-header mt-n4 mb-3"></div>
                <div class="form-group row m-0 p-0 mb-1 limited-row">
                  <label
                    class="col-md-6 pt-2 pb-1 col-form-label form-control-label"
                  >
                    Método de pagamento
                    <span class="text-danger">&nbsp;*&nbsp;</span>
                  </label>
                  <div class="col-md-6">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <el-select
                          @change="getPaymentTerms(true);handleRelytoInstallments('payment_method')"
                          @input="setRequireNsuCode()"
                          v-model="payload.payment.payment_method_uuid"
                          size="mini"
                          placeholder="Selecione"
                          filterable
                          :loading="loadingPlant"
                          :disabled="
                          loadingPlant || payload.payment.total_value === '0,00'
                          || payload.payment.any_has_billet || payload.payment.started
                        ">
                          <el-option
                            v-for="method in payment_methods_selected"
                            :key="method.uuid"
                            :label="method.name"
                            :value="method.uuid"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-1 limited-row">
                  <label
                    class="col-md-6  pb-1 col-form-label form-control-label"
                  >
                    Condição de pagamento
                    <span class="text-danger">&nbsp;*&nbsp;</span>
                  </label>
                  <div class="col-md-6">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <el-select
                          @change="generateInstallments(0, false, true)"
                          v-model="payload.payment.payment_term_uuid"
                          size="mini"
                          placeholder="Selecione"
                          filterable
                          :disabled="
                          loadingPlant || payload.payment.total_value === '0,00'
                          || !payload.payment.payment_method_uuid ||
                           payload.payment.any_has_billet || payload.payment.started"
                        >
                          <el-option
                            v-for="term in payment_terms_selected"
                            :key="term.uuid"
                            :label="term.name"
                            :value="term.uuid"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>

                <div class="card-header mt-n4 mb-3"></div>
                <div class="form-group row m-0 p-0 mb-2 limited-row">
                  <label
                    class="col-md-6 pt-2 pb-0 col-form-label form-control-label"
                  >
                    Central
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-6">
                    <validation-provider rules="required">
                      <base-input input-group-classes="input-group-sm">
                        <puzl-select
                          @change="changeBankAccount()"
                          :clearable="false"
                          :disabled="payload.payment.any_has_billet || payload.payment.started"
                          v-model="payload.payment.company_plant_id"
                          :items="$_company_plants"
                          :loading="loadingCompanyPlants"/>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-2 limited-row">
                  <label
                    class="col-md-6 pt-2 pb-0 col-form-label form-control-label"
                  >
                    CNPJ Beneficiário
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-6">
                    <validation-provider rules="required">
                      <base-input input-group-classes="input-group-sm">
                        <puzl-select
                          :clearable="false"
                          :disabled="payload.payment.any_has_billet || payload.payment.started"
                          v-model="payload.payment.company_plant_issuer_id"
                          :items="company_plant_issuers"
                          label="alias_name"
                          :loading="loadingCompanyPlantIssuers"/>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-2 limited-row">
                  <label
                    class="col-md-6 pt-2 pb-1 col-form-label form-control-label"
                  >
                    Conta bancária de crédito
                    <span class="text-danger">&nbsp;*&nbsp;</span>
                  </label>
                  <div class="col-md-6">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <el-select
                          v-model="payload.payment.bank_account_id"
                          size="mini"
                          @change="handleRelytoInstallments('bank_account')"
                          placeholder="Selecione"
                          filterable
                          :disabled="
                          loadingBankAccounts || payload.payment.any_has_billet || payload.payment.started || payload.payment.has_any_history"
                        >
                          <el-option
                            v-for="item in bank_accounts"
                            :key="item.bank_account.id"
                            :label="formatAccountLabel(item.bank_account)"
                            :value="item.bank_account.id"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 ">
                  <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label">
                    Observações
                  </label>
                  <div class="col-md-6">
                    <base-input input-group-classes="input-group-sm">
                         <textarea
                           v-model="payload.payment.observations"
                           rows="3"
                           type="text"
                           class="form-control form-control-sm"
                         />
                    </base-input>
                  </div>
                </div>
              </section>
              <section v-show="type == 2">
                <div class="form-group row m-0 p-0 mb-1">
                  <label
                    class="col-md-12 pt-2 pb-1 col-form-label form-control-label"
                  >
                    Faturas
                  </label>
                  <div class="col-md-12">
                    <base-input input-classes="form-control-sm">
                      <el-select
                        disabled
                        @change="calcInvoicedAmount"
                        v-model="payload.invoices"
                        size="mini"
                        placeholder="Selecione"
                        filterable
                        multiple
                        ref="invoice-select"
                      >
                        <el-option
                          v-for="invoice in invoices"
                          :key="invoice.id"
                          :label="`Fatura ${invoice.id} (${invoice.pending_to_paid.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} /
                          ${invoice.total.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})})`"
                          :value="invoice.id"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-2 limited-row">
                  <label
                    class="col-md-6  pt-2 pb-0 col-form-label form-control-label"
                  >
                    Valor faturado
                  </label>
                  <div class="col-md-6">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        disabled
                        v-money="money"
                        maxlength="13"
                        inputmode="numeric"
                        v-model="invoiced_amount"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0">
                          R$
                        </small>
                      </template>
                    </base-input>
                  </div>
                </div>
              </section>
              <section v-show="type == 3">
                <!-- Start Payments -->
                <div v-if="payload.installments.length">
                  <div class="border-header-danger-bottom mb-3 mt-3">
                    <h5 class="h3 p-1 mb-0">Pagamentos</h5>
                  </div>
                  <div class="row pr-3" v-if="has_changed_installment">
                    <div class="col-md-12 mb-2 text-right float-right">
                      <base-button @click.prevent="resetInstallments" size="sm">
                        <i class="fa-solid fa-window-restore"></i> Restaurar parcelas
                      </base-button>
                    </div>
                  </div>
                  <!-- Start Orders-->
                  <div v-for="(installment, indexInstallment) in payload.installments" :key="installment.index">
                    <div class="form-group row m-0 mb-1 limited-row" :class="installment.index > 1 ? 'mt-4' : ''">
                      <label class="col-md-6 pb-0 mt-n3 col-form-label form-control-label">
                        Parcela {{ installment.index }}
                        <a
                          v-show="[0, 2].includes(installment.status) && $hasPermission('additional_permissions.financial_approvation')"
                          href="#"
                          @click.prevent="showModalShareInstallment(installment)"
                          class="avatar avatar-sm ml-2 rounded-circle bg-success">
                          <i class="text-white fa-solid fa-arrows-split-up-and-left"></i>
                        </a>
                        <a v-show="[0, 2, 8].includes(installment.status)" href="#"
                           @click.prevent="showModalEntityPayment(installment) && $hasPermission('additional_permissions.financial_approvation')"
                           class="avatar ml-1 avatar-sm rounded-circle bg-indigo">
                          <i class="text-white fa-solid fa-dollar-sign"></i>
                        </a>
                      </label>
                      <div class="col-md-6">
                        <!--                        <base-dropdown class="col-md-12 p-0 m-0">-->
                        <base-button slot="title-container"
                                     disabled
                                     block size="sm"
                                     :type="getColor(installment.status)" class="text-uppercase">
                          {{ getTitle(installment.status) }}
                        </base-button>
                      </div>
                    </div>
                    <div class="form-group row m-0  mb-1 limited-row">
                      <label class="col-md-6 pt-1  pb-0  col-form-label form-control-label">
                        Vencimento
                        <span class="text-danger">&nbsp;*</span>
                      </label>
                      <div class="col-md-6">
                        <validation-provider rules="required">
                          <base-input input-group-classes="input-group-sm">
                            <input-date-time-picker
                              disabled
                              v-model="installment.due_date"
                              :mode="'date'"
                            />
                          </base-input>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="form-group row m-0 p-0 mb-1 limited-row">
                      <label class="col-md-6  pt-2 pb-0 col-form-label form-control-label">
                        Valor
                        <span class="text-danger">&nbsp;*</span>
                      </label>
                      <div class="col-md-6">
                        <base-input input-group-classes="input-group-sm">
                          <input disabled
                                 @blur="calcTotalValue(installment,indexInstallment);"
                                 v-money="money" maxlength="13"
                                 @keydown="$event.key === '-' ? $event.preventDefault() : null"
                                 inputmode="numeric" v-model.lazy="installment.value" type="text"
                                 class="form-control form-control-sm"/>
                          <template slot="prepend">
                            <small class="input-group-sm p-0 m-0">
                              R$
                            </small>
                          </template>
                        </base-input>
                      </div>
                    </div>
                    <div class="form-group row m-0 p-0 mb-1 limited-row">
                      <label
                        class="col-md-6 pt-2 pb-0 col-form-label form-control-label"
                      >
                        Descontos
                      </label>
                      <div class="col-md-6">
                        <base-input input-group-classes="input-group-sm">
                          <input
                            @keydown="$event.key === '-' ? $event.preventDefault() : null"
                            @blur="
                              calcTotalValue(installment, indexInstallment)
                            "
                            :disabled="blockInstallmentEdit(installment) || !$hasPermission('bill_receive.edit_discounts_and_taxes')"
                            v-money="money"
                            maxlength="13"
                            inputmode="numeric"
                            v-model="installment.discounts"
                            class="form-control form-control-sm"
                          />
                          <template slot="prepend">
                            <small class="input-group-sm p-0 m-0">
                              R$
                            </small>
                          </template>
                        </base-input>
                      </div>
                    </div>
                    <div class="form-group row m-0 p-0 mb-1 limited-row">
                      <label
                        class="col-md-6 pt-2 pb-0 col-form-label form-control-label"
                      >
                        Taxas
                      </label>
                      <div class="col-md-6">
                        <base-input input-group-classes="input-group-sm">
                          <input
                            @keydown="$event.key === '-' ? $event.preventDefault() : null"
                            @blur="
                              calcTotalValue(installment, indexInstallment)
                            "
                            :disabled="blockInstallmentEdit(installment) || !$hasPermission('bill_receive.edit_discounts_and_taxes')"
                            v-money="money"
                            maxlength="13"
                            inputmode="numeric"
                            v-model="installment.discounts_fees"
                            class="form-control form-control-sm"
                          />
                          <template slot="prepend">
                            <small class="input-group-sm p-0 m-0">
                              R$
                            </small>
                          </template>
                        </base-input>
                      </div>
                    </div>
                    <div class="form-group row m-0 p-0 mb-1 limited-row">
                      <label
                        class="col-md-6  pt-2 pb-0 col-form-label form-control-label"
                      >
                        Juros/Multa
                      </label>
                      <div class="col-md-6">
                        <base-input input-group-classes="input-group-sm">
                          <input
                            @keydown="$event.key === '-' ? $event.preventDefault() : null"
                            ref="feesInput"
                            @blur="
                              calcTotalValue(installment, indexInstallment)
                            "
                            :disabled="blockInstallmentEdit(installment)"
                            v-money="money"
                            maxlength="13"
                            inputmode="numeric"
                            v-model="installment.fees_fines"
                            class="form-control form-control-sm"
                          />
                          <template slot="prepend">
                            <small class="input-group-sm p-0 m-0">
                              R$
                            </small>
                          </template>
                        </base-input>
                      </div>
                    </div>
                    <div class="form-group row m-0 p-0 mb-1 limited-row">
                      <label class="col-md-6 pt-2 pb-0 col-form-label form-control-label">
                        Valor final
                        <span class="text-danger">&nbsp;*</span>
                      </label>
                      <div class="col-md-6">
                        <base-input input-group-classes="input-group-sm">
                          <input disabled v-money="money" maxlength="13" inputmode="numeric"
                                 v-model.lazy="installment.total_value" type="text"
                                 class="form-control form-control-sm"/>
                          <template slot="prepend">
                            <small class="input-group-sm p-0 m-0">
                              R$
                            </small>
                          </template>
                        </base-input>
                      </div>
                    </div>

                    <div class="form-group row m-0 p-0 mb-1 limited-row">
                      <label
                        class="col-md-6  pt-2 pb-0 col-form-label form-control-label"
                      >
                        Método de pagamento
                      </label>
                      <div class="col-md-6">
                        <validation-provider rules="required">
                          <base-input input-classes="form-control-sm">
                            <el-select
                              v-model="installment.payment_method_id"
                              size="mini"
                              placeholder="Selecione"
                              filterable
                              :disabled="blockInstallmentEdit(installment) || !$hasPermission('bill_receive.edit_discounts_and_taxes')">
                              <el-option
                                v-for="method in payment_methods"
                                :key="method.id"
                                :label="method.name"
                                :value="method.id"
                              >
                              </el-option>
                            </el-select>
                          </base-input>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="form-group row m-0 p-0 mb-1 limited-row">
                      <label
                        class="col-md-6  pt-2 pb-0 col-form-label form-control-label"
                      >
                        Conta bancária
                      </label>
                      <div class="col-md-6">
                        <validation-provider rules="required">
                          <base-input input-classes="form-control-sm">
                            <puzl-select
                              :disabled="blockInstallmentEdit(installment)"
                              :clearable="false"
                              :items="$_bank_accounts"
                              v-model="installment.bank_account_id"
                            />
                          </base-input>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <!-- End Orders-->
                </div>
                <!-- End Payments -->
              </section>
              <section v-show="type === 4">
                <div class="form-group row m-0 p-0 mb-4">
                  <div class="col-md-12 mt-2">
                    <label class="form-control-label">Anexos</label>
                    <InputFile :maxFiles="10" ref="files"/>
                  </div>
                </div>
              </section>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')">
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit"
                           v-bind:disabled="invalid || payload.invoices.length === 0 || payload.installments.length === 0"
                           :loading="loadingStore">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
      <modal-share-installment @updateInstallments="updateInstallments" ref="modalShareInstallment"/>
      <modal-entity-payment @updateInstallments="updateInstallmentsEntity" ref="modalEntityPayment"/>
      <ModalCardIntegration ref="ModalCardIntegration" @selectedTransactionProps="setTransactionProps"/>
    </modal>
  </div>
</template>

<script>
import {
  mapGetters
} from "vuex";
import {
  VMoney
} from "v-money";
import {
  checkNumberValue
} from "@/helpers";
import {
  mask1,
  formatErrorValidation
} from "@/plugins";
import moment from "moment";
import InputFile from "@/components/Utils/InputFile";
import PuzlSelect from "@/components/PuzlSelect";
import InstallmentMixin from "../../../../Financial/BillReceive/Mixins/installmentMixin";
import InputDateTimePicker from "@/components/InputDateTimePickerV2";
import ModalShareInstallment from './_ModalShareInstallment'
import ModalEntityPayment from '../../../../Financial/BillReceive/Entity/Shared/Modals/_ModalEntityPayment'
import {moneyToFloat} from "../../../../../../helpers";
import ModalCardIntegration from "@/views/Modules/Financial/BillReceive/Shared/Modals/_ModalCardIntegration";
import {BankBilletStatusEnum} from "../../../../../../enum/BankBilletStatusEnum";

export default {
  name: "ModalEditPayment",
  mixins: [InstallmentMixin],
  components: {
    InputFile,
    PuzlSelect,
    InputDateTimePicker,
    ModalShareInstallment,
    ModalEntityPayment,
    ModalCardIntegration,
  },
  data() {
    return {
      modal: {
        title: 'Recebimentos',
        create: false,
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      entity_balance: 0,
      attachments: [],
      initial_iss: 0,
      bank_accounts: [],
      invoices_paid: [],
      Payment_methods: [],
      old_installments: [],
      hasInstallmentPaid: false,
      payment_intemerdiaries_selected: [],
      loadingPaymentIntermediaries: false,
      loadingCompanyPlants: false,
      loadingPaymentIntermediaryMerchants: false,
      payment_terms_by_method: [],
      payment_methods_selected: [],
      payment_terms_selected: [],
      require_nsu_code: false,
      payment_intermediary_merchants: [],
      payment_intermediaries_exists: false,
      invoiced_amount: 0,
      has_changed_installment: false,
      balance: 0,
      type: 1,
      payload: {
        payment: {
          competence: null,
          name: null,
          total_value: null,
          observations: null,
          company_plant_uuid: null,
          final_value: null,
          payment_term_uuid: null,
          payment_method_uuid: null,
          contract_proposal_uuid: this.$route.params.contract_proposal_uuid,
          bank_account_id: null,
          payment_intermediary_id: null,
          payment_intermediary_merchant_id: null,
          transaction_code: null,
          nsu_code: null,
        },
        invoices: [],
        installments: []
      },
      loadingCompanyPlantIssuers: false,
      loadingBankAccounts: false,
      loadingStore: false,
      loadingPlant: false,
      loadingMethod: true,
      loading: true,
      file: null,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false
      },
      modalCardIntegrations: false,
      BankBilletStatusEnum: BankBilletStatusEnum,
    }
  },
  directives: {
    money: VMoney
  },
  computed: {
    ...mapGetters({
      plants: "plant/activeItems",
      payment_methods: "paymentMethod/fetch",
      contract_proposal: "contractProposal/show",
      payment_terms: "paymentTerm/fetch",
      contract_proposal_payment: "contractProposalPayment/show",
      invoices: "billingInvoice/invoices",
      $_company_plants: "plant/activeItems",
      company_plant_issuers: "companyPlantIssuer/fetch",
      $_payment_intermediaries: "paymentIntermediary/fetch",
      $_bank_accounts: 'bankAccount/fetch',
    })
  },
  methods: {
    /**
     * Exibir campos de ponto de venda de acordo com ID do Mediador de Pagamento
     *
     * @param {integer} paymentIntermediaryId Id de mediador de pagamento
     * @returns {boolean} Exibir/Não exibir pontos de venda
     */
    isDisplayPaymentIntermediaryMerchants(paymentIntermediaryId) {
      const paymentIntermediaryIdFound = this.payment_intemerdiaries_selected.find((item) => item.id === paymentIntermediaryId);
      return paymentIntermediaryIdFound
        && paymentIntermediaryIdFound.has_credentials
        && (this.payment_intermediary_merchants.length > 0);
    },
    disableMaxDateInstallment(installment) {
      const due_date = this.$helper.parseDate(installment.due_date, 'YYYY-MM-DD')
      const date = this.$helper.parseDate(new Date(), 'YYYY-MM-DD')
      if (date > due_date) {
        return true
      }
      return false
    },
    handleCreateModalCardIntegration() {
      this.$refs.ModalCardIntegration.handleCreateModal(
        this.payload.payment.payment_intermediary_id,
        this.payload.payment.payment_intermediary_merchant_id,
        this.payload.payment.competence,
      );
    },
    /**
     * @param {object} selectedTransactionProps
     * @param {integer} selectedTransactionProps.payment_intermediary_id
     * @param {integer} selectedTransactionProps.paymentIntermediaryMerchantId
     * @param {string} selectedTransactionProps.nsu_code
     */
    setTransactionProps(selectedTransactionProps) {
      this.payload.payment.payment_intermediary_id = selectedTransactionProps.paymentIntermediaryId;
      this.payload.payment.payment_intermediary_merchant_id = selectedTransactionProps.paymentIntermediaryMerchantId;
      this.payload.payment.nsu_code = selectedTransactionProps.nsu_code;
      this.payload.payment.competence = selectedTransactionProps.competence;
    },
    getPaymentIntermediaryMerchants(setFirstIdIfExist = false) {
      if (setFirstIdIfExist) {
        this.payload.payment.payment_intermediary_merchant_id = null
      }
      this.loadingPaymentIntermediaryMerchants = true
      const params = {
        filter: {
          payment_intermediary_id: this.payload.payment.payment_intermediary_id,
          status: true,
        }
      };
      this.$store
        .dispatch("paymentIntermediaryMerchant/fetchItems", params)
        .then(response => {
          this.payment_intermediary_merchants = response.data;
          if (setFirstIdIfExist && (this.payment_intermediary_merchants.length === 1)) {
            this.payload.payment.payment_intermediary_merchant_id = this.payment_intermediary_merchants[0].id;
          }
          this.loadingPaymentIntermediaryMerchants = false;
        });
    },
    showModalShareInstallment(installment) {
      this.$refs.modalShareInstallment.openModal(installment)
    },
    showModalEntityPayment(installment) {
      this.type = 3
      const total_value = this.payload.installments
        .reduce(function (a, item) {
          return a + (item.status === 11 ? moneyToFloat(item.total_value) : 0)
        }, 0)
        .toFixed(2);
      this.$refs.modalEntityPayment.openModal({
        installment: installment,
        balance: (this.contract_proposal.entity.anticipation_balance - total_value).toString()
      })
    },
    changeBankAccount() {
      this.getBankAccounts()
    },
    /** regras após inserção mediador de pagamento */
    /** validações por método de pagamento */
    setRequireNsuCode(set_intermediary_id = true, clear_installments = true) {
      this.modalCardIntegrations = true;
      if (set_intermediary_id) {
        this.payload.payment.payment_intermediary_id = null
      }
      this.payment_intemerdiaries_selected = []
      let payment_method = this.payment_methods.find((item) => item.uuid === this.payload.payment.payment_method_uuid)
      if (!payment_method) {
        this.payment_intermediaries_exists = false
        return this.require_nsu_code = false
      }
      this.payment_intermediaries_exists = payment_method.payment_intermediaries_exists
      return this.require_nsu_code = Boolean(payment_method.transaction_code)
    },
    formatAccountLabel(item) {
      return item.name
    },
    getPaymentTerms(set_installments = false, clear_payment_term_uuid = true) {
      if (clear_payment_term_uuid) {
        this.payload.payment.payment_term_uuid = null
      }
      const payment_methods_selected = [this.payload.payment.payment_method_uuid]
      const self = this
      this.payment_terms_selected = this.payment_terms.filter(function (item) {
        for (var payment_method of item.payment_methods) {
          if (payment_methods_selected.includes(payment_method.uuid)
            && self.contract_proposal.payment_terms_uuids.includes(item.uuid)) {
            return item;
          }
        }
      })
      if (this.contract_proposal.payment_terms_uuids.length === 1 && clear_payment_term_uuid) {
        const payment_term_uuid = this.contract_proposal.payment_terms_uuids[0]
        const exists = this.payment_terms_selected.find((item) => item.uuid = payment_term_uuid)
        if (exists) {
          this.payload.payment.payment_term_uuid = payment_term_uuid
        }
      }
      if (set_installments) {
        this.generateInstallments(0, true, true)
      }
    },
    /** fim */
    closeModal() {
      this.modal.create = false
    },
    calcInvoicedAmount() {
      const self = this;
      let total_value = 0;
      this.payload.invoices.map(function (id) {
        let invoice = self.invoices.find(function (item) {
          return item.id === id;
        });
        const paid_for_this_payment = self.invoices_paid[invoice.id] || 0
        total_value += Number(invoice.pending_billing) + Number(paid_for_this_payment);
      });
      // VALOR TOTAL DAS FATURAS
      this.invoiced_amount = total_value.toFixed(2);
      const payment_total_value = this.payload.payment.total_value.includes(",") ?
        this.payload.payment.total_value.replaceAll(".", "").replace(",", ".") :
        this.payload.payment.total_value;
      // SALDO
      this.balance = parseFloat(payment_total_value)
        - parseFloat(this.invoiced_amount.replace('.', '').replace(',', ''))
      if (this.balance < 0) {
        this.balance = 0
      }
    },
    setMethods(setTerm = false) {
      let Payment_methods = []
      for (var i of this.contract_proposal.payment_terms_uuids) {
        const payment_methods = this.payment_terms.find(item => item.uuid === i).payment_methods
        Payment_methods = [...Payment_methods, ...payment_methods]
      }
      const objectMap = Payment_methods.reduce((map, object) => {
        map.set(object.id, object);
        return map
      }, new Map())
      this.Payment_methods = Array.from(objectMap, ([_, value]) => value)
      if (setTerm) {
        this.setPaymentTerms()
      }
    },
    setPaymentTerms() {
      let paymentMethod = this.payment_methods.find(item => item.uuid === this.payload.payment.payment_method_uuid)
      this.payment_terms_by_method = this.payment_terms.filter(function (item) {
        let has_method = item.payment_methods.find(method => method.uuid === paymentMethod.uuid)
        if (has_method) {
          return item
        }
      })
    },
    calcTotalValueToReceive() {
      const sum = this.payload.installments.reduce(function (new_value, obj) {
        new_value += parseFloat(obj.value.includes(",") ? obj.value.replaceAll(".", "").replace(",", ".") : obj.value);
        return new_value;
      }, 0);
      return this.payload.payment.total_value = Number(sum).toFixed(2)
    },
    calcFinalValue() {
      let total = 0;
      let total_value = 0;
      let final_value = 0
      for (let i in this.payload.installments) {
        const day = Number(moment(this.payload.installments[i].due_date).day());
        if ([0, 6].includes(day)) {
          if (day === 0) {
            const due_date = moment(this.payload.installments[i].due_date)
              .add(1, "days")
              .format("YYYY/MM/DD");
            this.payload.installments[i].due_date = due_date;
            this.payload.installments[i].original_due_date = due_date;
          }
          if (day === 6) {
            const due_date = moment(this.payload.installments[i].due_date)
              .add(2, "days")
              .format("YYYY/MM/DD");
            this.payload.installments[i].due_date = due_date;
            this.payload.installments[i].original_due_date = due_date;
          }
        }
        total += Number(this.$helper.strToNum(this.payload.installments[i].value))
        final_value += Number(this.$helper.strToNum(this.payload.installments[i].total_value))
      }
      total_value = this.payload.payment.total_value.includes(",") ?
        this.payload.payment.total_value.replaceAll(".", "").replace(",", ".") :
        this.payload.payment.total_value;
      if (this.payload.installments.length) {
        if (Number(total_value).toFixed(2) > Number(total).toFixed(2)) {
          const diff = (Number(total_value) - total).toFixed(2);
          this.payload.installments[0].value = Number(
            Number(this.$helper.strToNum(this.payload.installments[0].value)) + Number(diff)
          ).toFixed(2);
        }
        if (Number(total_value).toFixed(2) < Number(total).toFixed(2)) {
          const diff = Number(total).toFixed(2) - Number(total_value).toFixed(2)
          this.payload.installments[0].value = Number(
            Number(this.$helper.strToNum(this.payload.installments[0].value)) - Number(diff)
          ).toFixed(2);
        }
      }
      this.payload.payment.final_value = Number(final_value).toFixed(2);
    },
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        this.$forceUpdate();
        return checkNumberValue(
          mask1(value.toString(), decimals, ".", true),
          min,
          max
        );
      }
      return checkNumberValue(value, min, max);
    },
    getColor(status) {
      switch (status) {
        case 0:
          return "warning";
        case 1:
          return "success";
        case 2:
        case 3:
        case 9:
          return "danger";
        case 8:
          return "primary";
        case 11:
          return "indigo"
      }
    },
    getTitle(status) {
      switch (status) {
        case 0:
          return "Pendente";
        case 1:
          return "Recebido";
        case 2:
          return "Atrasado";
        case 3:
          return "Cancelado";
        case 8:
          return "Aprovado";
        case 9:
          return "Negado";
        case 11:
          return "Antecipação"
      }
    },
    init() {
      this.$store.dispatch("paymentMethod/fetchItems")
        .then(() => {
          this.payment_methods_selected =
            this.payment_methods.filter((item) => this.contract_proposal.payment_methods_uuids.includes(item.uuid))
          this.loadingMethod = false;
        }).catch(() => {
      })
    },
    async getBankAccounts() {
      await this.$store.dispatch('bankAccount/fetchItems', {
        filter: {
          has_allocated_plant: true
        }
      })
      this.loadingBankAccounts = true
      this.$store.dispatch("companyPlantBankAccount/getByCompanyPlant", {company_plant_id: this.payload.payment.company_plant_id}).then((response) => {
        this.loadingBankAccounts = false
        this.bank_accounts = response.data
      })
    },
    resetInstallments() {
      return this.$Swal.confirmAction('Deseja realmente restaurar as parcelas?', ['Sim', 'Não'])
        .then((result) => {
          if (result.isConfirmed) {
            this.payload.installments = Object.values(this.old_installments)
            this.has_changed_installment = false
            this.$notify({
              type: 'success',
              message: 'Parcelas restauradas com sucesso!'
            })
            this.$forceUpdate()
          }
        }).catch(() => this.$Progress.finish())
    },
    async handleCreateModal(uuid, contract_proposal_uuid = null) {
      let contract_proposal_uuid_param = this.$route.params.contract_proposal_uuid ? this.$route.params.contract_proposal_uuid : contract_proposal_uuid
      this.payload.payment.contract_proposal_uuid = contract_proposal_uuid_param
      this.modalCardIntegrations = false;
      await this.$store.dispatch('contractProposal/show', contract_proposal_uuid_param)
      this.has_changed_installment = false
      this.type = 1
      this.$Progress.start()
      await this.getPayment(uuid);
      let loader = this.$loading.show()
      this.payload.payment = JSON.parse(JSON.stringify(this.contract_proposal_payment));
      this.payload.invoices = Object.values(this.contract_proposal_payment.invoices)
      this.payload.installments = JSON.parse(JSON.stringify(this.contract_proposal_payment.installments))
        .sort((a, b) => a.index - b.index);
      this.old_installments = {...this.payload.installments}
      this.payload.payment.reseted_installments = 0;
      this.getBankAccounts()
      await this.$store.dispatch("billingInvoice/init", {
        filter: {
          pending_payment: 1,
          bill_receive_id: this.payload.payment.billable_id,
          contract_proposal_id: this.contract_proposal.id,
          company_plant_id: this.payload.payment.company_plant_id,
          company_plant_issuer_id: this.payload.payment.company_plant_issuer_id,
          not_canceled: true,
        }
      })
      loader.hide()
      await Promise.all([
        this.$store.dispatch("paymentTerm/fetchItems"),
        this.$store.dispatch('companyPlantIssuer/fetchItems', {
          allocated: true,
          company_plant_id: this.payload.payment.company_plant_id
        }),
        this.$store.dispatch("paymentIntermediary/fetchItems"),
        this.$store.dispatch("plant/fetchItemsActive", {}),
        this.$store.dispatch("paymentMethod/fetchItems"),
      ]).then((response) => {
        this.loadingBankAccounts = false
        this.loadingPaymentIntermediaries = false;
        this.loadingCompanyPlants = false;
        this.payload.payment.competence = new Date(moment(this.payload.payment.competence))
        this.modal.create = true;
        this.payment_methods_selected =
          this.payment_methods.filter((item) => this.contract_proposal.payment_methods_uuids.includes(item.uuid))
        if (this.contract_proposal.payment_methods_uuids.length === 1) {
          this.payload.payment.payment_method_uuid = this.contract_proposal.payment_methods_uuids[0]
        }
        this.setRequireNsuCode(false, false)
        this.getPaymentTerms(false, false)
        this.calcInvoicedAmount()
        this.$refs.files.setFilesByUrl(this.attachments)
        this.getPaymentIntermediaryMerchants();
      })
      this.payload.installments.forEach(function (item, key) {
        if (!item.bank_account_id) {
          item.bank_account_id = this.payload.payment.bank_account_id
        }
        if (!item.payment_method_id) {
          let payment_method_id = this.payment_methods.find((item) => item.uuid === this.payload.payment.payment_method_uuid).id
          item.payment_method_id = payment_method_id
        }
      }, this)
      this.$Progress.finish()
    },
    handleRelytoInstallments(type) {
      this.payload.installments.forEach(function (item, key) {
        if (type === 'bank_account') {
          item.bank_account_id = this.payload.payment.bank_account_id
        }
        if (type === 'payment_method') {
          let payment_method_id = this.payment_methods.find((item) => item.uuid === this.payload.payment.payment_method_uuid).id
          item.payment_method_id = payment_method_id
        }
      }, this)
    },
    getPayment(uuid) {
      return this.$store.dispatch('contractProposalPayment/show', uuid).then(response => {
        this.initial_iss = response.data.iss_retain
        this.invoices_paid = response.data.invoices_paid
        this.attachments = response.data.attachments
        return response.data
      })
    },
    async updateInstallmentsEntity(params) {
      this.has_changed_installment = true
      let installments = {...this.payload.installments}
      let latest = this.payload.installments.at(-1)
      if (latest.index === params.latest_index) {
        let index = -1
        for (var i = 0; i < this.payload.installments.length; i++) {
          if (this.payload.installments[i].index === params.latest_index) {
            index = i
          }
        }
        delete installments[index]
        installments = Object.values(installments)
        for (let i of params.installments) {
          let fees_fines = 0
          let discounts_fees = 0
          let discounts = 0
          let total_value = i.value
          if (i.index === params.latest_index) {
            fees_fines = this.payload.installments[index].fees_fines.replaceAll(".", "").replace(",", ".")
            discounts_fees = this.payload.installments[index].discounts_fees.replaceAll(".", "").replace(",", ".")
            discounts = this.payload.installments[index].discounts.replaceAll(".", "").replace(",", ".")
            total_value = Number((Number(i.value) - Number(discounts_fees) - Number(discounts)) + Number(fees_fines)).toFixed(2)
            installments.push(
              {
                attachments: [],
                value: i.value,
                index: i.index,
                shared: true,
                entity: true,
                shared_count: params.shared_count,
                id: this.payload.installments[index].id,
                total_value: total_value,
                due_date: params.due_date,
                original_due_date: params.due_date,
                status: 11,
                paid_at: moment(new Date()).format('YYYY-MM-DD'),
                fees_fines: fees_fines,
                discounts: discounts,
                payment_method_id: i.payment_method_id || this.payment_methods.find((item) => item.uuid == this.payload.payment.payment_method_uuid).id,
                bank_account_id: i.bank_account_id || this.payload.payment.bank_account_id,
                discounts_fees: discounts_fees
              }
            )
          } else {
            installments.push(
              {
                attachments: [],
                value: i.value,
                index: i.index,
                entity: true,
                total_value: total_value,
                due_date: params.due_date,
                original_due_date: params.due_date,
                status: 0,
                paid_at: null,
                fees_fines: fees_fines,
                discounts: discounts,
                discounts_fees: discounts_fees,
                payment_method_id: i.payment_method_id || this.payment_methods.find((item) => item.uuid == this.payload.payment.payment_method_uuid).id,
                bank_account_id: i.bank_account_id || this.payload.payment.bank_account_id,
              }
            )
          }
          this.payload.installments = installments
        }
      } else {
        let previous_index = params.latest_index - 1

        let next_index = params.latest_index + 1
        let previous_installments = this.payload.installments.filter((item) => item.index <= previous_index)
        let next_installments = this.payload.installments.filter((item) => item.index >= next_index)

        let installments = []
        let atual_index = 0;

        for (let i in previous_installments) {
          atual_index += 1
          installments.push(previous_installments[i])
        }
        let index = -1
        for (var i = 0; i < this.payload.installments.length; i++) {
          if (this.payload.installments[i].index === params.latest_index) {
            index = i
          }
        }
        for (let i of params.installments) {
          let fees_fines = 0
          let discounts_fees = 0
          let discounts = 0
          let total_value = i.value
          atual_index += 1
          if (i.index === params.latest_index) {
            fees_fines = this.payload.installments[index].fees_fines.replaceAll(".", "").replace(",", ".")
            discounts = this.payload.installments[index].discounts.replaceAll(".", "").replace(",", ".")
            discounts_fees = this.payload.installments[index].discounts_fees.replaceAll(".", "").replace(",", ".")
            total_value = Number((Number(i.value) - Number(discounts_fees) - Number(discounts)) + Number(fees_fines)).toFixed(2)
            installments.push(
              {
                attachments: [],
                value: i.value,
                index: i.index,
                shared: true,
                entity: true,
                shared_count: params.shared_count,
                id: this.payload.installments[index].id,
                total_value: total_value,
                due_date: params.due_date,
                original_due_date: params.due_date,
                status: 11,
                paid_at: moment(new Date()).format('YYYY-MM-DD'),
                fees_fines: fees_fines,
                discounts: discounts,
                discounts_fees: discounts_fees,
                payment_method_id: i.payment_method_id || this.payment_methods.find((item) => item.uuid == this.payload.payment.payment_method_uuid).id,
                bank_account_id: i.bank_account_id || this.payload.payment.bank_account_id,
              })
          } else {
            installments.push(
              {
                attachments: [],
                value: i.value,
                index: i.index,
                entity: true,
                total_value: total_value,
                due_date: params.due_date,
                original_due_date: params.due_date,
                status: 0,
                paid_at: null,
                fees_fines: fees_fines,
                discounts: discounts,
                discounts_fees: discounts_fees,
                payment_method_id: i.payment_method_id || this.payment_methods.find((item) => item.uuid == this.payload.payment.payment_method_uuid).id,
                bank_account_id: i.bank_account_id || this.payload.payment.bank_account_id,
              }
            )
          }
        }
        for (let i in next_installments) {
          atual_index += 1
          next_installments[i].index = atual_index
          installments.push(next_installments[i])
        }
        this.payload.installments = installments
      }
      this.$refs.modalEntityPayment.updatedInstallments()
    },
    async updateInstallments(params) {
      this.has_changed_installment = true
      if (params.installments.length === 1 || (params.installments.length === this.old_installments.length)) {
        return this.$refs.modalShareInstallment.updatedInstallments()
      }
      let installments = {...this.payload.installments}
      let latest = this.payload.installments.at(-1)
      if (latest.index === params.latest_index) {
        let index = -1
        for (var i = 0; i < this.payload.installments.length; i++) {
          if (this.payload.installments[i].index === params.latest_index) {
            index = i
          }
        }
        delete installments[index]
        installments = Object.values(installments)
        for (let i of params.installments) {
          let fees_fines = 0
          let discounts_fees = 0
          let discounts = 0
          let total_value = i.value
          if (i.index === params.latest_index) {
            fees_fines = this.payload.installments[index].fees_fines.replaceAll(".", "").replace(",", ".")
            discounts_fees = this.payload.installments[index].discounts_fees.replaceAll(".", "").replace(",", ".")
            total_value = Number((Number(i.value) - Number(discounts_fees) - Number(discounts)) + Number(fees_fines)).toFixed(2)
            installments.push(
              {
                attachments: [],
                shared: true,
                shared_count: params.shared_count,
                value: i.value,
                index: i.index,
                id: this.payload.installments[index].id,
                total_value: total_value,
                due_date: params.due_date,
                original_due_date: params.due_date,
                status: 0,
                paid_at: null,
                fees_fines: fees_fines,
                discounts: discounts,
                discounts_fees: discounts_fees,
                payment_method_id: i.payment_method_id || this.payment_methods.find((item) => item.uuid == this.payload.payment.payment_method_uuid).id,
                bank_account_id: i.bank_account_id || this.payload.payment.bank_account_id,
              }
            )
          } else {
            installments.push(
              {
                attachments: [],
                value: i.value,
                index: i.index,
                total_value: total_value,
                due_date: params.due_date,
                original_due_date: params.due_date,
                status: 0,
                paid_at: null,
                fees_fines: fees_fines,
                discounts: discounts,
                discounts_fees: discounts_fees,
                payment_method_id: i.payment_method_id || this.payment_methods.find((item) => item.uuid == this.payload.payment.payment_method_uuid).id,
                bank_account_id: i.bank_account_id || this.payload.payment.bank_account_id,
              }
            )
          }
          this.payload.installments = installments
        }
      } else {
        let previous_index = params.latest_index - 1

        let next_index = params.latest_index + 1

        let previous_installments = this.payload.installments.filter((item) => item.index <= previous_index)
        let next_installments = this.payload.installments.filter((item) => item.index >= next_index)

        let installments = []
        let atual_index = 0;

        for (let i in previous_installments) {
          atual_index += 1
          installments.push(previous_installments[i])
        }
        let index = -1
        for (var i = 0; i < this.payload.installments.length; i++) {
          if (this.payload.installments[i].index === params.latest_index) {
            index = i
          }
        }
        for (let i of params.installments) {
          let fees_fines = 0
          let discounts_fees = 0
          let discounts = 0
          let total_value = i.value
          atual_index += 1
          if (i.index === params.latest_index) {
            fees_fines = this.payload.installments[index].fees_fines.replaceAll(".", "").replace(",", ".")
            discounts_fees = this.payload.installments[index].discounts_fees.replaceAll(".", "").replace(",", ".")
            discounts = this.payload.installments[index].discounts.replaceAll(".", "").replace(",", ".")
            total_value = Number((Number(i.value) - Number(discounts_fees) - Number(discounts)) + Number(fees_fines)).toFixed(2)
            installments.push(
              {
                attachments: [],
                value: i.value,
                index: i.index,
                shared: true,
                shared_count: params.shared_count,
                id: this.payload.installments[index].id,
                total_value: total_value,
                due_date: params.due_date,
                original_due_date: params.due_date,
                status: 0,
                paid_at: null,
                fees_fines: fees_fines,
                discounts: discounts,
                discounts_fees: discounts_fees,
                payment_method_id: i.payment_method_id || this.payment_methods.find((item) => item.uuid == this.payload.payment.payment_method_uuid).id,
                bank_account_id: i.bank_account_id || this.payload.payment.bank_account_id,
              })
          } else {
            installments.push(
              {
                attachments: [],
                value: i.value,
                index: i.index,
                total_value: total_value,
                due_date: params.due_date,
                original_due_date: params.due_date,
                status: 0,
                paid_at: null,
                fees_fines: fees_fines,
                discounts: discounts,
                discounts_fees: discounts_fees,
                payment_method_id: i.payment_method_id || this.payment_methods.find((item) => item.uuid == this.payload.payment.payment_method_uuid).id,
                bank_account_id: i.bank_account_id || this.payload.payment.bank_account_id,
              }
            )
          }
        }
        for (let i in next_installments) {
          atual_index += 1
          next_installments[i].index = atual_index
          installments.push(next_installments[i])
        }
        this.payload.installments = installments
      }
      this.$refs.modalShareInstallment.updatedInstallments()
    },
    getConditions(uuid) {
      return this.$store.dispatch("paymentTerm/getByMethod", uuid)
        .then(response => {
          this.setMethods(true)
          this.$notify({
            type: response.error_type,
            message: response.message
          })
        });
    },
    finalFormat() {
      let payload = JSON.parse(JSON.stringify(this.payload));
      payload.payment.total_value = payload.payment.total_value.replaceAll(".", "").replace(",", ".");
      payload.payment.final_value = payload.payment.final_value.replaceAll(".", "").replace(",", ".");
      payload.payment.iss_retain = Number(payload.payment.iss_retain.replaceAll(".", "").replace(",", "."));
      payload.installments.map(function (obj, key) {
        obj.value = obj.value.replaceAll(".", "").replace(",", ".");
        obj.total_value = obj.total_value.replaceAll(".", "").replace(",", ".");
      });
      payload.payment.balance =
        typeof this.balance === 'string' ? this.balance.replaceAll(".", "").replace(",", ".") : this.balance;
      return payload;
    },
    validHasSufficientTotalValue() {
      const sumWithInitial = Object.values(this.invoices_paid).reduce(
        (previousValue, currentValue) => Number(previousValue) + Number(currentValue),
        0
      );
      let total = this.payload.payment.total_value
        .replaceAll(".", "")
        .replace(",", ".");
      if (total < sumWithInitial.toFixed(2)) {
        return false
      }
      return true
    },
    validHasSufficientBalance() {
      if ((typeof this.balance === 'string' ? this.balance.replaceAll(".", "").replace(",", ".") : this.balance) > 0) {
        return true
      }
      let balance = 0
      let total = this.payload.payment.total_value
        .replaceAll(".", "")
        .replace(",", ".");
      total = parseFloat(total)
      balance += total
      for (let id of this.payload.invoices) {
        if (balance <= 0) {
          return false
        }
        const invoice = this.invoices.find(item => item.id === id)
        const value = Number(invoice.pending_billing)
        if (balance > value) {
          balance -= value
        } else {
          balance = 0
        }
      }
      return true
    },
    /**
     * Validar pagamento de comerciante (Transações de cartão de crédito/Débito)
     * @returns boolean
     */
    async isValidPaymentIntermediaryMerchant() {
      // Campos obrigatórios para consulta
      const paymentIntermediaryMerchantIsFilled = this.payload.payment.payment_intermediary_id
        && this.payload.payment.payment_intermediary_merchant_id
        && this.payload.payment.nsu_code;
      if (!paymentIntermediaryMerchantIsFilled) {
        this.$notify({
          type: "danger",
          message: `Quando NSU da transação é informado, é necessário preencher "Mediador de Pagamento" e "Ponto de Venda (PV)".`
        });
        return false;
      }
      return true;
    },
    hasInconstant() {
    },
    async save() {
      const has_final_less = this.payload.installments.some((item) => Number(item.total_value.replaceAll(".", "").replace(",", ".")) < 0)
      const has_total_less = this.payload.installments.some((item) => Number(item.value.replaceAll(".", "").replace(",", ".")) <= 0)
      if (has_total_less) {
        this.$notify({
          type: "danger",
          message: 'O valor total da parcela não pode ser igual ou menor a R$ 0,00.'
        });
      }
      if (has_final_less) {
        this.$notify({
          type: "danger",
          message: 'O valor total da parcela não pode ser menor a R$ 0,00.'
        });
      }
      if (has_final_less || has_total_less) {
        return;
      }
      // Verificar NSU da transação quando obrigatório
      const has = this.validHasSufficientBalance()
      if (!has) {
        return this.$notify({type: 'danger', message: 'Não há saldo suficiente para pagamento das faturas.'})
      }
      if (!this.validHasSufficientTotalValue()) {
        return this.$notify({
          type: 'warning',
          message: 'O valor total do pagamento não deve ser inferior ao valor mínimo faturado.'
        })
      }
      if (this.payload.installments.length > 12) {
        return this.$notify({
          type: 'warning',
          message: 'O pagamento deve ter no máximo 12 parcelas. Verifique as parcelas geradas e tente novamente.'
        })
      }
      this.$Progress.start()
      this.payload.payment.competence = this.$helper.parseDate(this.payload.payment.competence, 'YYYY-MM-DD');
      this.loadingStore = true
      let formData = new FormData();
      let removedItems = []
      this.$refs.files.removedFiles.map(function (url) {
        if (url !== null) {
          removedItems.push(url)
        }
      })
      for (let indexFile in this.$refs.files.fileRecords) {
        formData.append(indexFile, this.$refs.files.fileRecords[indexFile]['file']);
      }
      formData.append('removed_files', JSON.stringify(removedItems))
      const payload = this.finalFormat()
      payload['payment'].installments = {...this.payload.installments}
      for (let index in payload) {
        formData.append(index, JSON.stringify(payload[index]))
      }
      formData.append('uuid', payload.payment.uuid)
      formData.append('has_changed_installment', +this.has_changed_installment)
      formData.append('entity_id', this.contract_proposal.entity.id)
      this.$store.dispatch("contractProposalPayment/update", formData, {
        headers: {
          "content-type": "multipart/form-data"
        }
      })
        .then(response => {
          this.loadingStore = false
          this.closeModal();
          this.$Progress.finish()
          this.$emit('showInvoices', response.data)
          this.$notify({
            type: response.error_type,
            message: response.message
          })
          this.$store.dispatch("contractProposal/show", this.payload.payment.contract_proposal_uuid);
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors
            });
          } else {
            this.$notify({type: 'danger', message: error.data.message})
          }
          this.$Progress.finish()
          this.loadingStore = false
        })
    },
    /**
     * Bloquear edição de parcela
     * @param {object} installment
     * @param {boolean} installment.status status da parcela
     * @param {boolean} installment.bank_billet_status status do boleto
     */
    blockInstallmentEdit(installment) {
      const bankBilletStatus = [BankBilletStatusEnum.OPENED, BankBilletStatusEnum.PAID, BankBilletStatusEnum.OVERDUE];
      const isBankBilletStatusBlocked = bankBilletStatus.includes(installment.bank_billet_status);
      const isStatusBlocked = [1, 3, 11].includes(installment.status);

      return isStatusBlocked || isBankBilletStatusBlocked;
    },
    generateInstallments(recalc = 0, msg = false, keep_original_due_date = false) {
      if (msg) {
        this.$swal
          .fire({
            title: "Você tem certeza?",
            text: "Essa modificação afeta as parcelas existentes, ao alterar esta informação você perderá os registros das parcelas desta solicitação!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim, apague as parcelas!",
            cancelButtonText: "Não, mantenha",
            customClass: {
              confirmButton: "btn btn-success btn-fill",
              cancelButton: "btn btn-danger btn-fill"
            },
            buttonsStyling: false
          })
          .then(result => {
            if (!result.isConfirmed) {
              this.payload.payment.iss_retain = this.initial_iss
            } else {
              this.has_changed_installment = false
              if (recalc === 1) {
                this.payload.payment.final_value = this.payload.payment.total_value;
              }
              if (this.payload.payment.payment_term_uuid && this.payload.payment.payment_method_uuid) {
                let self = this;
                let uuid = this.payload.payment.payment_term_uuid;
                let payment_term = this.payment_terms.filter(
                  item => item.uuid === uuid
                )[0];

                let payment_method = this.payment_methods.find(
                  item => item.uuid === self.payload.payment.payment_method_uuid
                );
                /*
                 * Buscamos as parcelas da condição de pagamento
                 */
                let installments = payment_term.installments;
                let payload_installments = [];
                /*
                 * Percorremos as parcelas de condição de pagamento
                 * e montamos as nossas para pagamento de proposta contrato.
                 */
                const total = Number(self.payload.payment.total_value
                  .replaceAll(".", "")
                  .replace(",", "."))
                // valor retido
                const iss_retain = Number(self.payload.payment.iss_retain
                  .replaceAll(".", "")
                  .replace(",", "."))
                const total_value = total - iss_retain
                const payment_intermediary = this.$_payment_intermediaries.find((item) => item.id === this.payload.payment.payment_intermediary_id)
                if (payment_intermediary) {
                  let total_percentage_tax = 0
                  // SE FOR CRÉDITO PARCELADO ACUMULADO
                  /** checked */
                  if (payment_intermediary.credit_in_installments.receipt_method === 0 &&
                    payment_method.payment_type === 1 && payment_term.total_installment > 1) {
                    const deadline = payment_intermediary.credit_in_installments.deadline
                    total_percentage_tax = Object.values(payment_intermediary
                      .additional_installments).find((item) => item.installment === payment_term.total_installment).tax_percentage
                    total_percentage_tax = Number(total_percentage_tax.toString().replace(',', '.'))

                    let date = moment(self.payload.payment.competence)
                      .add("days", deadline)
                      .format("YYYY-MM-DD");

                    let discounts = parseFloat(total_percentage_tax).toFixed(2);
                    let tax_percentage_intermediary = parseFloat(payment_intermediary.credit_in_installments.tax_percentage.toString().replace(',', '.'))
                    discounts = parseFloat(discounts) + tax_percentage_intermediary
                    discounts = (discounts / 100) * total_value.toFixed(2);
                    discounts += Number(payment_intermediary.credit_in_installments.tax_monetary.toString().replace(',', '.'))
                    let installment = {
                      value: total_value.toFixed(2),
                      index: 1,
                      total_value: (total_value - discounts).toFixed(2),
                      due_date: date,
                      original_due_date: this.getOriginalDueDate(keep_original_due_date, 0, date),
                      status: 0,
                      paid_at: null,
                      fees_fines: null,
                      discounts: null,
                      discounts_fees: discounts.toFixed(2)
                    };
                    this.payload.installments = [installment]
                    return this.calcFinalValue()
                  }
                  // SE FOR CRÉDITO PARCELADO COM RECEBIMENTO PARCELADO
                  /** checked */
                  if (payment_intermediary.credit_in_installments.receipt_method === 1 &&
                    payment_method.payment_type === 1 && payment_term.total_installment > 1) {
                    total_percentage_tax = Object.values(payment_intermediary
                      .additional_installments).find((item) => item.installment === payment_term.total_installment).tax_percentage
                    total_percentage_tax = Number(total_percentage_tax.toString().replace(',', '.'))

                    let discounts = parseFloat(total_percentage_tax).toFixed(2);
                    let tax_percentage_intermediary = parseFloat(payment_intermediary.credit_on_sight.tax_percentage.toString().replace(',', '.'))
                    discounts = parseFloat(discounts) + tax_percentage_intermediary
                    discounts = (discounts / 100) * total_value.toFixed(2);
                    discounts += Number(payment_intermediary.credit_on_sight.tax_monetary.toString().replace(',', '.'))
                    installments.map(function (obj, key) {
                      let date = moment(self.payload.payment.competence)
                        .add("days", obj.deadline)
                        .format("YYYY-MM-DD");

                      let percentage = parseFloat(obj.percentage);
                      let value = (percentage / 100) * total_value;

                      let installment = {
                        value: value.toFixed(2),
                        index: obj.index,
                        total_value: (value - (discounts / payment_term.total_installment)).toFixed(2),
                        due_date: date,
                        original_due_date: this.getOriginalDueDate(keep_original_due_date, key, date),
                        status: 0,
                        paid_at: null,
                        fees_fines: null,
                        discounts: null,
                        discounts_fees: (discounts / payment_term.total_installment).toFixed(2)
                      };
                      payload_installments.push(installment);
                    }, this)
                    this.payload.installments = payload_installments
                    return this.calcFinalValue()
                  }
                  // SE FOR CRÉDITO A VISTA
                  if (payment_method.payment_type === 1 && payment_term.total_installment === 1) {
                    const deadline = payment_intermediary.credit_on_sight.deadline
                    total_percentage_tax = Number(payment_intermediary.credit_on_sight.tax_percentage.toString().replace(',', '.'))
                    let date = moment(self.payload.payment.competence)
                      .add("days", deadline)
                      .format("YYYY-MM-DD");

                    let discounts = parseFloat(total_percentage_tax).toFixed(2);
                    discounts = (discounts / 100) * total_value.toFixed(2);
                    discounts += Number(payment_intermediary.credit_on_sight.tax_monetary.toString().replace(',', '.'))
                    let installment = {
                      value: total_value.toFixed(2),
                      index: 1,
                      total_value: (total_value - discounts).toFixed(2),
                      due_date: date,
                      original_due_date: this.getOriginalDueDate(keep_original_due_date, 0, date),
                      status: 0,
                      paid_at: null,
                      fees_fines: null,
                      discounts: null,
                      discounts_fees: discounts.toFixed(2)
                    };
                    this.payload.installments = [installment]
                    return this.calcFinalValue()
                  }
                  // SE FOR DÉBITO A VISTA
                  if (payment_method.payment_type === 0 && payment_term.total_installment === 1) {
                    const deadline = payment_intermediary.debit.deadline
                    total_percentage_tax = Number(payment_intermediary.debit.tax_percentage.toString().replace(',', '.'))
                    let date = moment(self.payload.payment.competence)
                      .add("days", parseInt(deadline) + installments[0].deadline)
                      .format("YYYY-MM-DD");

                    let discounts = parseFloat(total_percentage_tax).toFixed(2);
                    discounts = (discounts / 100) * total_value.toFixed(2);
                    discounts += Number(payment_intermediary.debit.tax_monetary.toString().replace(',', '.'))
                    let installment = {
                      value: total_value.toFixed(2),
                      index: 1,
                      total_value: (total_value - discounts).toFixed(2),
                      due_date: date,
                      original_due_date: this.getOriginalDueDate(keep_original_due_date, 0, date),
                      status: 0,
                      paid_at: null,
                      fees_fines: null,
                      discounts: null,
                      discounts_fees: discounts.toFixed(2)
                    };
                    this.payload.installments = [installment]
                    return this.calcFinalValue()
                  }
                  // SE FOR DÉBITO PARCELADO
                  if (payment_method.payment_type === 0 && payment_term.total_installment > 1) {
                    total_percentage_tax = Number(payment_intermediary.debit.tax_percentage.toString().replace(',', '.'))
                    let discounts = parseFloat(total_percentage_tax).toFixed(2);
                    discounts = (discounts / 100) * total_value.toFixed(2);
                    discounts += Number(payment_intermediary.debit.tax_monetary.toString().replace(',', '.'))
                    installments.map(function (obj, key) {
                      let date = moment(self.payload.payment.competence)
                        .add("days", obj.deadline)
                        .format("YYYY-MM-DD");

                      let percentage = parseFloat(obj.percentage);
                      let value = (percentage / 100) * total_value;

                      let installment = {
                        value: value.toFixed(2),
                        index: obj.index,
                        total_value: (value - discounts).toFixed(2),
                        due_date: date,
                        original_due_date: this.getOriginalDueDate(keep_original_due_date, key, date),
                        status: 0,
                        paid_at: null,
                        fees_fines: null,
                        discounts: null,
                        discounts_fees: discounts.toFixed(2)
                      };
                      payload_installments.push(installment);
                    }, this)
                    this.payload.installments = payload_installments
                    return this.calcFinalValue()
                  }
                }

                installments.map(function (obj, key) {
                  /*
                   * Calculamos a data de vencimento a atribuir.
                   */
                  let date = moment(self.payload.payment.competence)
                    .add("days", obj.deadline)
                    .format("YYYY-MM-DD");

                  /*
                   * Calculamos o valor da parcela.
                   */
                  let payment_total_value = total_value
                  let percentage = parseFloat(obj.percentage);
                  let value = (percentage / 100) * payment_total_value;

                  let installment = {
                    value: value.toFixed(2),
                    index: obj.index,
                    total_value: value.toFixed(2),
                    due_date: date,
                    original_due_date: this.getOriginalDueDate(keep_original_due_date, key, date),
                    status: 0,
                    paid_at: null,
                    fees_fines: null,
                    discounts: null,
                    discounts_fees: null
                  };
                  payload_installments.push(installment);
                }, this);
                this.payload.installments = payload_installments;
              }
              this.$forceUpdate();
              this.calcFinalValue()
              this.calcInvoicedAmount()
            }
          })
      } else {
        this.has_changed_installment = false
        if (recalc === 1) {
          this.payload.payment.final_value = this.payload.payment.total_value;
        }
        if (this.payload.payment.payment_term_uuid && this.payload.payment.payment_method_uuid) {
          let self = this;
          let uuid = this.payload.payment.payment_term_uuid;
          let payment_term = this.payment_terms.filter(
            item => item.uuid === uuid
          )[0];

          let payment_method = this.payment_methods.find(
            item => item.uuid === self.payload.payment.payment_method_uuid
          );
          /*
           * Buscamos as parcelas da condição de pagamento
           */
          let installments = payment_term.installments;
          let payload_installments = [];
          /*
           * Percorremos as parcelas de condição de pagamento
           * e montamos as nossas para pagamento de proposta contrato.
           */
          const total = Number(self.payload.payment.total_value
            .replaceAll(".", "")
            .replace(",", "."))
          // valor retido
          const iss_retain = Number(self.payload.payment.iss_retain
            .replaceAll(".", "")
            .replace(",", "."))
          const total_value = total - iss_retain

          const payment_intermediary = this.$_payment_intermediaries.find((item) => item.id === this.payload.payment.payment_intermediary_id)
          if (payment_intermediary) {
            let total_percentage_tax = 0
            // SE FOR CRÉDITO PARCELADO ACUMULADO
            /** checked */
            if (payment_intermediary.credit_in_installments.receipt_method === 0 &&
              payment_method.payment_type === 1 && payment_term.total_installment > 1) {
              const deadline = payment_intermediary.credit_in_installments.deadline
              total_percentage_tax = Object.values(payment_intermediary
                .additional_installments).find((item) => item.installment === payment_term.total_installment).tax_percentage
              total_percentage_tax = Number(total_percentage_tax.toString().replace(',', '.'))

              let date = moment(self.payload.payment.competence)
                .add("days", deadline)
                .format("YYYY-MM-DD");

              let discounts = parseFloat(total_percentage_tax).toFixed(2);
              let tax_percentage_intermediary = parseFloat(payment_intermediary.credit_in_installments.tax_percentage.toString().replace(',', '.'))
              discounts = parseFloat(discounts) + tax_percentage_intermediary
              discounts = (discounts / 100) * total_value.toFixed(2);
              discounts += Number(payment_intermediary.credit_in_installments.tax_monetary.toString().replace(',', '.'))
              let installment = {
                value: total_value.toFixed(2),
                index: 1,
                total_value: (total_value - discounts).toFixed(2),
                due_date: date,
                original_due_date: this.getOriginalDueDate(keep_original_due_date, 0, date),
                status: 0,
                paid_at: null,
                fees_fines: null,
                discounts: null,
                discounts_fees: discounts.toFixed(2)
              };
              this.payload.installments = [installment]
              return this.calcFinalValue()
            }
            // SE FOR CRÉDITO PARCELADO COM RECEBIMENTO PARCELADO
            /** checked */
            if (payment_intermediary.credit_in_installments.receipt_method === 1 &&
              payment_method.payment_type === 1 && payment_term.total_installment > 1) {
              total_percentage_tax = Object.values(payment_intermediary
                .additional_installments).find((item) => item.installment === payment_term.total_installment).tax_percentage
              total_percentage_tax = Number(total_percentage_tax.toString().replace(',', '.'))

              let discounts = parseFloat(total_percentage_tax).toFixed(2);
              let tax_percentage_intermediary = parseFloat(payment_intermediary.credit_on_sight.tax_percentage.toString().replace(',', '.'))
              discounts = parseFloat(discounts) + tax_percentage_intermediary
              discounts = (discounts / 100) * total_value.toFixed(2);
              discounts += Number(payment_intermediary.credit_on_sight.tax_monetary.toString().replace(',', '.'))
              installments.map(function (obj, key) {
                let date = moment(self.payload.payment.competence)
                  .add("days", obj.deadline)
                  .format("YYYY-MM-DD");

                let percentage = parseFloat(obj.percentage);
                let value = (percentage / 100) * total_value;

                let installment = {
                  value: value.toFixed(2),
                  index: obj.index,
                  total_value: (value - (discounts / payment_term.total_installment)).toFixed(2),
                  due_date: date,
                  original_due_date: this.getOriginalDueDate(keep_original_due_date, key, date),
                  status: 0,
                  paid_at: null,
                  fees_fines: null,
                  discounts: null,
                  discounts_fees: (discounts / payment_term.total_installment).toFixed(2)
                };
                payload_installments.push(installment);
              }, this)
              this.payload.installments = payload_installments
              return this.calcFinalValue()
            }
            // SE FOR CRÉDITO A VISTA
            if (payment_method.payment_type === 1 && payment_term.total_installment === 1) {
              total_percentage_tax = Number(payment_intermediary.credit_on_sight.tax_percentage.toString().replace(',', '.'))
              const deadline = payment_intermediary.credit_on_sight.deadline
              let date = moment(self.payload.payment.competence)
                .add("days", deadline)
                .format("YYYY-MM-DD");
              let discounts = total_percentage_tax.toFixed(2);
              discounts = (discounts / 100) * total_value.toFixed(2);
              discounts += Number(payment_intermediary.credit_on_sight.tax_monetary.toString().replace(',', '.'))
              let installment = {
                value: total_value.toFixed(2),
                index: 1,
                total_value: (total_value - discounts).toFixed(2),
                due_date: date,
                original_due_date: this.getOriginalDueDate(keep_original_due_date, 0, date),
                status: 0,
                paid_at: null,
                fees_fines: null,
                discounts: null,
                discounts_fees: discounts.toFixed(2)
              };
              this.payload.installments = [installment]
              return this.calcFinalValue()
            }
            // SE FOR DÉBITO A VISTA
            if (payment_method.payment_type === 0 && payment_term.total_installment === 1) {
              total_percentage_tax = Number(payment_intermediary.debit.tax_percentage.toString().replace(',', '.'))
              const deadline = payment_intermediary.debit.deadline
              let date = moment(self.payload.payment.competence)
                .add("days", parseInt(deadline) + installments[0].deadline)
                .format("YYYY-MM-DD");
              let discounts = total_percentage_tax.toFixed(2);
              discounts = (discounts / 100) * total_value.toFixed(2);
              discounts += Number(payment_intermediary.debit.tax_monetary.toString().replace(',', '.'))
              let installment = {
                value: total_value.toFixed(2),
                index: 1,
                total_value: (total_value - discounts).toFixed(2),
                due_date: date,
                original_due_date: this.getOriginalDueDate(keep_original_due_date, 0, date),
                status: 0,
                paid_at: null,
                fees_fines: null,
                discounts: null,
                discounts_fees: discounts.toFixed(2)
              };
              this.payload.installments = [installment]
              return this.calcFinalValue()
            }
            // SE FOR DÉBITO PARCELADO
            if (payment_method.payment_type === 0 && payment_term.total_installment > 1) {
              total_percentage_tax = Number(payment_intermediary.debit.tax_percentage.toString().replace(',', '.'))
              const total_value = Number(self.payload.payment.total_value
                .replaceAll(".", "")
                .replace(",", "."))

              let discounts = total_percentage_tax.toFixed(2);
              discounts = (discounts / 100) * total_value.toFixed(2);
              discounts += Number(payment_intermediary.debit.tax_monetary.toString().replace(',', '.'))
              installments.map(function (obj, key) {
                let date = moment(self.payload.payment.competence)
                  .add("days", obj.deadline)
                  .format("YYYY-MM-DD");

                let percentage = parseFloat(obj.percentage);
                let value = (percentage / 100) * total_value;

                let installment = {
                  value: value.toFixed(2),
                  index: obj.index,
                  total_value: (value - discounts).toFixed(2),
                  due_date: date,
                  original_due_date: this.getOriginalDueDate(keep_original_due_date, key, date),
                  status: 0,
                  paid_at: null,
                  fees_fines: null,
                  discounts: null,
                  discounts_fees: discounts.toFixed(2)
                };
                payload_installments.push(installment);
              }, this)
              this.payload.installments = payload_installments
              return this.calcFinalValue()
            }
          }

          installments.map(function (obj, key) {
            /*
             * Calculamos a data de vencimento a atribuir.
             */
            let date = moment(self.payload.payment.competence)
              .add("days", obj.deadline)
              .format("YYYY-MM-DD");

            /*
             * Calculamos o valor da parcela.
             */
            let payment_total_value = total_value
            let percentage = parseFloat(obj.percentage);
            let value = (percentage / 100) * payment_total_value;

            let installment = {
              value: value.toFixed(2),
              index: obj.index,
              total_value: value.toFixed(2),
              due_date: date,
              original_due_date: this.getOriginalDueDate(keep_original_due_date, key, date),
              status: 0,
              paid_at: null,
              fees_fines: null,
              discounts: null,
              discounts_fees: null
            };
            payload_installments.push(installment);
          }, this);
          this.payload.installments = payload_installments;
        }
        this.$forceUpdate();
        this.calcFinalValue()
        this.calcInvoicedAmount()
      }
    },
    async calcTotalValue(installment, index) {
      let discounts = 0;
      let fees = 0;
      let current_total_value = 0;
      let current_value = 0;
      current_total_value = parseFloat(
        installment.value.replaceAll(".", "").replace(",", ".")
      );
      current_value = parseFloat(
        installment.value.replaceAll(".", "").replace(",", ".")
      );
      current_total_value -= Number(installment.discounts_fees.replaceAll(".", "").replace(",", "."));
      current_total_value -= Number(installment.discounts.replaceAll(".", "").replace(",", "."));
      current_total_value += Number(installment.fees_fines.replaceAll(".", "").replace(",", "."));
      this.payload.installments[
        index
        ].total_value = current_total_value.toFixed(2);
      this.$forceUpdate();
      await this.calcTotalValueToReceive();
      this.calcInvoicedAmount();
      this.calcFinalValue();
    },
  },
}
</script>

<style scoped>
@media (min-width: 768px) {
  .limited-row {
    max-height: 28px !important;
  }
}
</style>
