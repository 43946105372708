import adjustmentTypeStoreActions from "./adjustmentTypeStoreActions";
import adjustmentTypeStoreGetters from "./adjustmentTypeStoreGetters";
import adjustmentTypeStoreMutations from "./adjustmentTypeStoreMutations";
import { adjustmentTypeStoreStateKeys } from "./adjustmentTypeStoreStateKeys";

const adjustmentTypeStore = {
  ...adjustmentTypeStoreActions,
  ...adjustmentTypeStoreGetters,
  ...adjustmentTypeStoreMutations,
  adjustmentTypeStoreStateKeys,
};

export default adjustmentTypeStore;
