import * as types from './mutation_types'
import {destroy, update} from '@/store/baseStore'
import { createAxios } from "@/plugins/axios";
const endPoint = '/configuration/entity-contact'
export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters:{
    fetch: state => state.items,
  },
  mutations: {
    [types.PUSH] (state, payload) {
      state.items.unshift(payload)
    },
    [types.SET] (state, payload) {
      state.items = payload
    },
  },
  actions: {
    add({commit}, payload) {
      return createAxios().post(endPoint, payload)
        .then(({data}) => {
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    show({commit}, params) {
      return createAxios().get(`${endPoint}/${params.id}`)
        .then(({data}) => {
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    update(_, payload) {
      return createAxios().put(`${endPoint}/${payload.id}`,  payload)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
      })
    },
    destroy(_, id) {
      return createAxios().delete(`${endPoint}/${id}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
