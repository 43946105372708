const keys = Object.freeze({
  INDIVIDUAL: 1,
  GENERAL: 2,
});

/**
 * 
 * @param {number} value
 * @returns {string}
 */
const getTitle = (value) => {
  switch (value) {
    case keys.INDIVIDUAL:
      return "Individual";
    case keys.GENERAL:
      return "Geral";
    default:
      return "";
  }
};

export default {
  keys,
  getTitle
};
