<template>
  <div>
    <modal :show.sync="modal.create" size="lg">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <div>
          <div class="form-group row m-2 p-0 pl-1 mb-4 limited-row">
            <div class="col-md-3 mb-2">
              <base-button style="padding:0.7rem" type="primary" @click.prevent="selectType(0)" size="md" block> <i class="fas fa-search" /> Sefaz </base-button>
            </div>
            <div class="col-md-3 mb-2">
              <base-button class="pt-1 pb-2" type="warning" @click.prevent="selectType(1)" size="md" block> 
                <i style="padding: 0.3rem" class="fas fa-barcode border mt-1 mr-1"/> 
                Cód. barras
              </base-button>
            </div>
             <div class="col-md-3 mb-2">
              <base-button class="h-100" type="light" @click.prevent="selectType(3)" size="md" block> 
                <i style="font-size:18px" class="fas fa-download"/> 
                Xml
              </base-button>
            </div>
            <div class="col-md-3 mb-2">
              <base-button style="padding:0.8rem" type="success" @click.prevent="selectType(2)" size="md" block> <i class="fas fa-plus" /> AVULSO </base-button>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-center p-1 m-0">
          <base-button type="secondary" @click.prevent="closeModal"> Cancelar </base-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: {
        title: "Adicionar Lançamento de Despesa",
        create: false,
      },
      type: null,
    };
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal() {
      this.modal.create = true;
    },
    selectType(type) {
      this.$emit("selected", type);
      this.closeModal();
    },
  },
};
</script>

<style></style>
