import { mapGetters } from "vuex";

const operationSourceMixin = {
  data() {
    return {
      operation_source_types: [
        {
          value: 0,
          text: "ENTRADA",
          color: "success",
        },
        {
          value: 1,
          text: "SAÍDA",
          color: "danger",
        },
        {
          value: 2,
          text: "DEVOLUÇÃO",
          color: "indigo",
        },
        {
          value: 3,
          text: "TRANSFERÊNCIA",
          color: "warning",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      $_tax_regimes: 'entity/option_tax_regimes',
      $_document_templates: "documentTemplate/fetch",
      $_product_service_hub_types: "productServiceHubType/fetch",
    }),
    product_service_hub_types() {
      return this.$_product_service_hub_types.filter((item) =>  !['Não informado', 'Serviço'].includes(item.name));
    },
  },
  methods: {
    formattedDocumentTemplates() {
      if (!this.$_document_templates) return [];
      return [{ id: null, label: 'Não' }, ...this.$_document_templates.map(function (item) {
        item.label = `${item.code ? item.code + ' - ' : ''}${item.name}`
        return item;
      })];
    },
    /**
     * Valida regras para regimes
     * 
     * @param {array} value 
     * @param {object} payload 
     */
    changedTaxRegimes(value, payload) {
      if (value.length > 1 && (value.includes('Simples Nacional') || value.includes('MEI'))) {
        const is_valid = value.filter(item => item === 'Simples Nacional' || item === 'MEI').length === value.length; ;
        if (!is_valid) {
          payload.tax_regimes = [];
          this.$notify({
            type: 'danger',
            message: 'Não é permitido associar os regimes Simples Nacional e MEI aos demais regimes.'
          });
        }
      }

    }
  },
};
export default operationSourceMixin;
