<template>
  <div>
    <modal @close="close" size="xl" :show.sync="show">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">{{ title }}</h5>
      </template>
      <div class="row">
        <div class="col-md-12">
          <multi-filter :without_filter="true" :filter="{}" @fetch="load" :status="multi_filter"/>
        </div>
        <div class="col-md-12" style="margin-top: -1.4rem">
          <div class="card">
            <div style="height: 300px" class="card-body table-responsive">
              <table class="table table-sm table-bordered">
                <thead>
                <tr class="fixed">
                  <th class="text-center">Ação</th>
                  <th class="text-center">Status</th>
                  <th class="text-center">Central</th>
                  <th class="text-center">Período</th>
                  <th class="text-center">Processamento</th>
                  <th class="text-center">Data de criação</th>
                </tr>
                </thead>
                <tbody>
                <tr v-show="loadingSkeleton" v-for="item in 4">
                  <th colspan="4">
                    <skeleton-puzl type="button"/>
                  </th>
                </tr>
                <tr v-show="!loadingSkeleton" v-for="item in $_items.data">
                  <td class="text-center">
                    <base-dropdown>
                      <a
                        style="text-transform: none"
                        slot="title-container"
                        class="text-default dropdown-toggle font-weight-300"
                      >
                        Selecione
                      </a>
                      <div>
                        <a @click.prevent="download(item.file_path, item.name)">
                              <span class="dropdown-item">
                            <i class="fa-solid fa-cloud-arrow-down text-muted" style="margin-right: 2px"></i>
                                Baixar arquivos </span>
                        </a>
                        <div class="dropdown-divider p-0 m-0"></div>
                        <a @click.prevent="destroy(item.id)">
                              <span class="dropdown-item">
                  <i class="fas fa-times text-danger" style="margin-left: 2px;margin-right: 10px"></i>
                                Excluir </span>
                        </a>
                      </div>
                    </base-dropdown>
                  </td>
                  <td class="text-center">
                    <span v-show="item.status === 0" class="text-yellow font-weight-500" style="font-size: 0.75rem">Pendente</span>
                    <span v-show="item.status === 1" class="text-warning font-weight-500" style="font-size: 0.75rem">Em andamento</span>
                    <span v-show="item.status === 2" class="text-danger font-weight-500" style="font-size: 0.75rem">Cancelado</span>
                    <span v-show="item.status === 3" class="text-success font-weight-500" style="font-size: 0.75rem">Finalizado</span>
                  </td>
                  <td class="text-center">
                    {{ item.company_plant_name }}
                  </td>
                  <td class="text-center" style="text-transform: none">
                    {{ item.filter.range.start | parseDate('DD/MM/YYYY') }} a {{ item.filter.range.end | parseDate('DD/MM/YYYY') }}
                  </td>
                  <td class="text-center">
                    ({{ item.processed }}/{{ item.total }})
                  </td>
                  <td class="text-center">{{ item.created_at | parseDate('DD/MM/YYYY') }}</td>
                </tr>
                </tbody>
              </table>
              <puzl-empty-data v-if="!loadingSkeleton && ($_items.data && !$_items.data.length) ||
!loadingSkeleton && !$_items.data" :with-card="false"/>
            </div>
          </div>
        </div>
      </div>
      <pagination v-show="$_items.data" @navegate="navegate" :source="$_items"
                  :loading="loadingSkeleton"/>
      <div class="modal-footer">
        <base-button type="secondary" @click="close()">
          Fechar
        </base-button>
      </div>
    </modal>
  </div>
</template>

<script>
import MultiFilter from "@/components/Utils/MultiFilterV2";
import {mapGetters} from "vuex";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import Pagination from "@/components/Utils/Pagination";
import SkeletonPuzl from "../../../../../../components/SkeletonPuzl";
import {base_url_ms} from '@/plugins'

export default {
  name: "ModalFiles",
  components: {SkeletonPuzl, Pagination, PuzlEmptyData, MultiFilter},
  data() {
    return {
      title: 'Arquivos',
      isLoading: false,
      loadingSkeleton: false,
      show: false,
      base_url_ms: base_url_ms(),
      params: {
        page: 1,
        per_page: 5,
      },
      multi_filter: {
        null: {
          name: 'Todos',
          type: 'default',
        },
        0: {
          name: 'Em andamento',
          type: 'warning'
        },
        1: {
          name: 'Concluído',
          type: 'success',
        },
        4: {
          name: 'Com falha',
          type: 'danger',
        },
      }
    }
  },
  computed: {
    ...mapGetters({
      $_items: "exports/fetch",
    })
  },
  methods: {
    navegate(page) {
      this.params.page = page
      this.load()
    },
    close() {
      this.show = false
    },
    async load() {
      this.loadingSkeleton = true
      this.params.filter = this.filter
      await this.$store.dispatch('exports/fetchItems', this.params)
      this.loadingSkeleton = false
    },
    destroy(id) {
      let loader = this.$loading.show()
      this.$store.dispatch('exports/destroy', id).then((response) => {
        this.$notify({
          type: response.error_type,
          message: response.message
        });
        this.load()
        loader.hide()
      })
    },
    async download(s3_path, name) {
      let loader = this.$loading.show()
      const url = this.base_url_ms + 'download-s3?url=' + s3_path + '&name=' + name
      this.$store.dispatch('exports/download', url).then(async response => {
        let blob = new Blob([response], {type: "application/pdf"});
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", `${name}`);
        await link.click()
      });
      loader.hide()
    },
    async openModal() {
      let loader = this.$loading.show()
      await this.load()
      this.show = true
      loader.hide()
    }
  },
};
</script>

<style>
</style>
