<template>
  <div class="custom-card-reconciliation mt-3">
  	<div class="container px-0">
	    <div class="row">
	    	<div class="col-12 col-lg-6 px-3 pl-lg-1 pr-lg-0">
          <PuzlSelect
            :disableBoxShadow="true"
            @change="$emit('handleChangeFilters')"
            v-model="filter.payment_method_id"
            :items="payment_methods"
            label="name"
            :multiple="false"
            placeholder="Método de pagamento"
            class="select-xl new-default-black-font"
          />
	    	</div>
	    	<div class="col-10 mt-2 px-0 col-lg-5 mt-lg-0">
	    		<div class="container">
	    			<div class="row">
				    	<div class="col-6 pr-1 pl-lg-2 pr-lg-1">
								<div class="input-custom-group">
				          <div>
				            R$
				          </div>
				          <input inputmode="numeric"
                    v-model="range_amount_min"
                    @input="changeAmounts()"
				            placeholder="de">
				          </input>
			          </div>
				    	</div>
				    	<div class="col-6 pl-1 pr-2 px-lg-2">
								<div class="input-custom-group">
				          <div>
				            R$
				          </div>
				          <input inputmode="numeric"
                    v-model="range_amount_max"
                    @input="changeAmounts()"
				            placeholder="até">
				          </input>
			          </div>
				    	</div>
			    	</div>
		    	</div>
	    	</div>
	    	<div class="col-2 pl-0 mt-2 col-lg-1 mt-lg-0 px-lg-1">
	    		<!-- @click.prevent="global = []; clearFilter(); push();" -->
          <base-button
            @click.prevent="clearFilters()"
            style="border: 1px solid #E8E8E8;height: 2.7rem;border-radius: 8px !important;box-shadow: none; width: 100%; padding: 0;" type="white">
              <img height="21" src="/img/icons/icons8/ios/synchronize.png" alt="synchronize" />
          </base-button>
	    	</div>
	  	</div>
  	</div>
	</div>
</template>
<script>
import InputDatePicker from '@/components/InputDatePicker';
import PuzlSelect from "@/components/PuzlSelect";

export default {
	name: "Filters",
  props: {
  	filter: {
  		type: Object,
  		default: {
  			payment_method_id: null,
        range_amount: {}
  		}
  	}
  },
  components: {
  	InputDatePicker,
  	PuzlSelect
  },
  data() {
    return {
    	payment_methods: [],
      range_amount_min: null,
      range_amount_max: null,
    }
  },
  methods: {
    changeAmounts(){
      this.$emit('handleChangeAmounts', this.range_amount_min, this.range_amount_max);
    },
    clearFilters(){
      this.range_amount_min = null;
      this.range_amount_max = null;
      this.$emit('clearFilters');
    }
  },
  mounted(){
    this.$store
      .dispatch("paymentMethod/fetchItems")
      .then((response) => {
        this.payment_methods = response.data.filter(item => item.display_in_anticipation);
      });
  },
}
</script>
<style scoped>
.custom-card-reconciliation {
	border-radius: 8px;
	border: 1px solid #E8E8E8;
	padding: 20px 24px;
	box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.07) !important;
}

.input-custom-group {
	display: flex;
	align-items: center;
	border-radius: 8px;
  border: 1px solid #E8E8E8;
  overflow: hidden;
  height: 41px;
}

.input-custom-group input {
	box-shadow: none !important;
  border: none;
  padding-left: 5px;
  border-left: 1px solid #E8E8E8;
  border-left-color: #E8E8E8;
}

.input-custom-group div {
	padding: 0px 10px;
}

.input-custom-group input, .input-custom-group div {
	display: inline-flex;
	height: 41px;
	background: white;
	align-items: center;
	font-size: 12px;
}

.input-custom-group div {
	color: #606062;
}

.input-custom-group input {
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}

.input-custom-group input:focus {
	outline: none;
}
</style>
