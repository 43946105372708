<template>
  <div>
    <div 
      role="tab"
      class="card-header p-2"
      style="border-top-right-radius: 8px !important;border-top-left-radius: 8px !important;"
      :class="backGround"
      :aria-expanded="active"
      :style="{ backgroundColor: headerBackgroundColor }"
      @click.prevent="activate"
    >
      <div>
        <a
          data-toggle="collapse"
          data-parent="#accordion"
          :href="`#${itemId}`"
          :aria-controls="`content-${itemId}`"
        >
          <slot name="title"> {{ title }} </slot>
          <i class="tim-icons icon-minimal-down"></i>
        </a>
      </div>
    </div>
    <collapse-transition :duration="animationDuration" style="box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.3);">
      <div
        v-show="active"
        :id="`content-${itemId}`"
        role="tabpanel"
        :aria-labelledby="title"
        class="collapsed"
      >
        <div class="card-body" :class="classBody">
          <slot></slot>
        </div>
      </div>
    </collapse-transition>
  </div>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions';

export default {
  name: 'collapse-item',
  components: {
    CollapseTransition
  },
  props: {
    title: {
      type: String,
      default: '',
      description: 'Collapse item title'
    },
    isActive: {
      type: Boolean,
      default: false,
      description: 'Collapsed item'
    },
    classBody: {
      type: String,
      default: '',
      description: 'remove padding body'
    },
    dynamicMinimizer: {
      type: Boolean,
      default: false,
      description: 'Caso ativada minimiza automaticamente.'
    },
    total: {
      type: Number,
    },
    selected: {
      type: Number,
    },
    backGround: String,
    id: String
  },
  inject: {
    animationDuration: {
      default: 250
    },
    multipleActive: {
      default: false
    },
    addItem: {
      default: () => {}
    },
    removeItem: {
      default: () => {}
    },
    deactivateAll: {
      default: () => {}
    }
  },
  computed: {
    itemId() {
      return this.id || this.title;
    },
    headerBackgroundColor() {
      if (this.selected === 0) {
        return this.convertHexToRGBA("#606062", 0.25);
      } else if (this.selected < this.total) {
        return "white";
      } else {
        return this.convertHexToRGBA("#149E57", 0.25);
      }
    },
  },
  watch: {
    'dynamicMinimizer'() {
      this.active = this.dynamicMinimizer;
    }
  },
  data() {
    return {
      active: this.isActive,
    };
  },
  methods: {
    activate() {
      let wasActive = this.active;
      if (!this.multipleActive) {
        this.deactivateAll();
      }
      this.active = !wasActive;
    },
    /**
     * Converte uma cor em formato hexadecimal para o formato rgba.
     * Dessa forma a opacidade não afeta o texto e a imagem, apenas a cor do fundo.
     * @param {string} hex - A cor em formato hexadecimal.
     * @param {number} opacity - A opacidade da cor (entre 0 e 1).
     * @returns {string} A cor convertida em formato rgba.
     */
    convertHexToRGBA(hex, opacity) {
      hex = hex.replace('#', '');
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);

      return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    },
  },
  mounted() {
    this.addItem(this);
  },
  destroyed() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    this.removeItem(this);
  }
};
</script>
<style></style>
