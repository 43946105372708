<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-gray d-inline-block mb-0">
            {{ this.$route.meta.breadcrumbs[2].title }}</h6>
          <nav aria-label="breadcrumb"
               class="d-none d-md-inline-block ml-md-4 text-gray">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i
                :class="this.$route.meta.icon"></i></a></li>
              <li class="breadcrumb-item"><a
                href="#">{{ this.$route.meta.breadcrumbs[0].title }}</a></li>
              <li class="breadcrumb-item"><a
                href="#">{{ this.$route.meta.breadcrumbs[1].title }}</a></li>
              <li class="breadcrumb-item" aria-current="page">
                {{ this.$route.meta.breadcrumbs[2].title }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- Header Menus -->
      <div class="row">
        <div class="col-md-12">
          <div class="row">
              <div class="col-md-3 col-lg-2 col-xlg-2 ml-3 mr-3 mb-3 ml-0 p-0">
                <base-button class="pb-3 pt-3" block size="lg" type="success" @click="handleCreate()">
                  <i class="fas fa-plus"></i> NOVO
                </base-button>
              </div>

              <div class="col-md-3 col-lg-2 col-xlg-2 ml-3 mr-3 mb-3 ml-0 p-0">
                <router-link :to="{ path:'/commercial/contract'}">
                  <base-button block size="lg" type="light" class="p-3">
                    VOLTAR
                  </base-button>
                </router-link>
              </div>
          </div>
        </div>
      </div>
    </base-header>
    <list-situation></list-situation>
    <create-situation ref="createSituation"></create-situation>
  </div>
</template>
<script>
import ListSituation from './Shared/_List'
import CreateSituation from './Shared/_Create'
export default {
  components: {
    ListSituation,
    CreateSituation,
  },
  data() {
    return {
    }
  },
  methods: {
    handleCreate(){
      this.$refs.createSituation.handleCreateModal()
    },
  },
  created(){
  },
  mounted() {
  }
};
</script>
<style></style>
