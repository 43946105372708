<template>
  <div>
    <modal :show.sync="modal" size="lg">
      <template slot="header">
        <div style="display: flex;align-items: center;">
          <img style="display: inline-flex;" src="/img/brand/logo_white.png" width="22">
          <h5 style="display: inline-flex;" class="modal-title p-0 m-0 ml-3 text-white">{{ title }}</h5>
        </div>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form @submit.prevent="handleSubmit(generatePdfReport)" class="needs-validation" autocomplete="off">
            <div class="container-fluid">
              <div class="row mt-3 mb-4">
                <div class="col-12" style="display: flex; align-items: center;">
                  <img src="/img/icons/icons8/ios/filter_primary.png" width="20">
                  <h3 class="new-default-black-font m-0 mx-3 ">
                    Filtros
                  </h3>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-6">
                  <h4 class="new-default-black-font font-weight-normal m-0">
                    Status
                  </h4>
                </div>
                <div class="col-6">
                  <base-input input-classes="form-control-sm">
                    <PuzlSelect
                      placeholder="Selecione"
                      v-model="status"
                      :items="statusArray"
                      :clearable="false"
                    />
                  </base-input>
                </div>
              </div>
              <div class="row align-items-center mt-2">
                <div class="col-6">
                  <h4 class="new-default-black-font font-weight-normal m-0">
                    Período
                  </h4>
                </div>
                <div class="col-6">
                  <base-input input-group-classes="input-group-sm">
                    <InputDatePicker
                      :isRange="true"
                      @handleFilterDate="handleFilterDate"
                      size="small"
                      :filterable="2" />
                  </base-input>
                </div>
              </div>
              <div class="row align-items-center mt-2">
                <div class="col-6">
                  <h4 class="new-default-black-font font-weight-normal m-0">
                    Conta bancária
                  </h4>
                </div>
                <div class="col-6">
                  <base-input input-classes="form-control-sm">
                    <PuzlSelect
                      placeholder="Selecione"
                      v-model="bank_account_id"
                      :items="$_bank_accounts"
                      :clearable="false"
                    />
                  </base-input>
                </div>
              </div>
              <div class="row mt-5 mb-4">
                <div class="col-12 text-right">
                  <base-button type="danger"
                    size="sm"
                    style="width: 130px;" @click.prevent="closeModal">
                    <div style="display: inline-flex; align-items: center;">
                      <img src="/img/icons/cancel-white.png" width="20">
                      <i class="m-0 ml-1" style="font-style: normal;">
                        Cancelar
                      </i>
                    </div>
                  </base-button>
                  <base-button type="success"
                    size="sm"
                    :disabled="!bank_account_id"
                    style="width: 130px;"
                    native-type="submit">
                    <div style="display: inline-flex; align-items: center;">
                      <img src="/img/icons/icons8/ios/checked.png" width="20">
                      <i class="m-0 ml-1" style="font-style: normal;">
                        Exportar
                      </i>
                    </div>
                  </base-button>
                </div>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import moment from "moment";
import { formatErrorValidation } from "@/plugins";
import PuzlSelect from "@/components/PuzlSelect";
import InputDatePicker from "@/components/InputDatePicker";
import { mapGetters } from "vuex";

export default {
  components: {
    PuzlSelect,
    InputDatePicker
  },
  name: "ModalConciliationReport",
  data() {
    return {
      modal: false,
      title: 'Extrato Puzl',
      loadingBankAccounts: true,
      bank_account_id: null,
      status: 1,
      range_discharge_at: {
        start: moment().format("YYYY/MM/DD"),
        end: moment().format("YYYY/MM/DD"),
      },
      statusArray: [
        {
          id: 0,
          name: "Não conciliados"
        },
        {
          id: 1,
          name: "Conciliados"
        },
        {
          id: 2,
          name: "Justificados"
        }
      ],
    }
  },
  computed: {
    ...mapGetters({
      $_bank_accounts: "bankAccount/fetch",
    })
  },
  methods: {
    handleFilterDate(filter) {
      this.range_discharge_at = filter;
    },
    generatePdfReport(){
      const loader = this.$loading.show();

      this.$store
        .dispatch("financeTransactions/generatePdfReport", {
          range_discharge_at: {
            start: this.range_discharge_at.start,
            end: this.range_discharge_at.end,
          },
          bank_account_id: this.bank_account_id,
          status: this.status
        }).then(() => {
          loader.hide();
          this.closeModal();
          this.$notify({
            type: "success",
            message: "Solicitação realizada com sucesso! O relatório será exportado em segundo plano.",
          });
        }).catch(error => {
          loader.hide();
          const errors = error && error.response && error.response.status === 422
            ? formatErrorValidation(error.response.data.data)
            : error.response.data.message;
          this.$notify({ type: "danger", message: errors });
        });
    },
    closeModal() {
      this.modal = false;
    },
    openModal(){
      this.modal = true;

      this.$store.dispatch("bankAccount/fetchItems", {filter:{status:true}})
        .then(response => {
            this.loadingBankAccounts = false;
        });
    },
  },
};
</script>

<style scoped>
.divider-header {
  background: #e1e1e1;
  padding: 3px 20px;
  color: #db4539;
/*  color: #6b8184;*/
  font-size: 11px;
  width: fit-content;
  display: inline-flex;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
</style>
