<template>
<div>
    <base-header class="bg-gray-content">
        <div class="row align-items-center py-4">
            <div class="col-lg-6 col-7">
                <h6 class="h2 text-gray d-inline-block mb-0">{{ $route.name }}</h6>
                <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4 text-gray">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i></a></li>
                        <li class="breadcrumb-item"><a href="#">Tecnologia</a></li>
                        <li class="breadcrumb-item" aria-current="page">{{ $route.name }}</li>
                    </ol>
                </nav>
            </div>
        </div>
        <!-- Header Menus -->
        <div class="row">
            <div class="col-md-2 mb-3">
                <base-button class="pb-3 pt-3 text-uppercase" block size="lg" type="success" @click.prevent="handleCreateCommission">
                  <i class="fas fa-plus"></i> novo
                </base-button>
            </div>
        </div>
    </base-header>

    <list-commission v-bind:added="this.added"></list-commission>
    <modal-create ref="ModalCreate"></modal-create>
</div>
</template>

<script>
import ListCommission from './Shared/_List'
import ModalCreate from './Shared/_ModalCreate'
export default {
    name: "IndexCommission",
    components: {
        ListCommission,
        ModalCreate
    },
    data() {
        return {
            added: false,
        }
    },
    methods: {
        handleCreateCommission() {
            this.$refs.ModalCreate.handleCreateModal()
        },
        handleCreatedCommission() {
            this.added = !this.added
        }
    }
};
</script>

<style></style>
