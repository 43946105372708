<template>
  <div>
    <div class="container-fluid">
      <multi-filter @fetch="init" :filter="filter" :status="multi_filter"/>
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <PuzlEmptyData v-if="!listAdjustment.length && !loadingSkeleton && !loading"/>
      <div class="row card-wrapper" v-if="listAdjustment.length && !loadingSkeleton">
        <div class="col-lg-4" v-for="(adjustment, index) in listAdjustment" :key="index">
          <!-- Basic with action button -->
          <card
            :class="adjustment.color === 0 ? 'card-border-top-success' : adjustment.color === 1? 'card-border-top-warning' : 'card-border-top-primary'">
            <!-- Card header -->
            <div class="row align-items-center mb-3">
              <div class="col-9 justify-content-center pr-0">
                <p class="pb-0 mb-0 pr-1">
                  Maior que <b>{{ adjustment.hour_min }}</b>
                  <br>
                  Menor ou igual a <b>{{ adjustment.hour_max }}</b>
                </p>
              </div>
              <div class="col-3 text-right pl-0">
                <base-dropdown menuOnRight>
                  <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                    <i class="fas fa-cog"></i>
                  </base-button>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleEditAdjustment(adjustment.uuid)">
                    <i class="fas fa-edit text-warning"></i>
                    Editar
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleDeleteAdjustment(adjustment.uuid)">
                    <i class="fas fa-times text-danger"></i>
                    Excluir
                  </a>
                </base-dropdown>
              </div>
            </div>
            <!-- Card body -->
            <!-- Centrais Adjustment -->
            <collapse class="border rounded p-0 mt-3">
              <collapse-item
                class="header-gray card-body-molde"
                back-ground="border-header-success-bottom"
              >
                <h5 slot="title" class="mb-0 ">Centrais</h5>
                <div v-for="(plant, indexPlant) in adjustment.company_plant" :key="indexPlant">
                  <div class="checklist-item checklist-item-primary mb-1">
                    <div class="checklist-info ">
                      <h4 class="checklist-title small">
                        {{ plant.name }}
                      </h4>
                    </div>
                  </div>
                </div>
              </collapse-item>
            </collapse>

            <!-- Card Adjustment -->
            <collapse class="border rounded p-0 mt-3">
              <collapse-item v-if="!loadinfAdjustmentLimit" name="1" class="header-gray card-body-molde" back-ground="border-header-primary-bottom">
                <h5 slot="title" class="mb-0 ">Ajustes</h5>
                <div class="row">
                  <div class="col-md-12 justify-content-center">
                    <base-button block slot="title-container" type="dark" size="sm" class="rounded" outline
                                 @click="resetAll(adjustment)">
                      zerar todas
                    </base-button>
                  </div>
                </div>

                <div class="row justify-content-md-center">
                  <div class="col-md-6 text-center pt-2">
                    <h5
                      :class="adjustment.binder > 0 ? 'text-uppercase text-success' : adjustment.binder <0 ? 'text-uppercase text-danger': 'text-uppercase text-light' ">
                      aglomerante</h5>
                    <base-input input-group-classes="input-group-sm">
                      <input
                        maxlength="4"
                        inputmode="numeric"
                        v-model="adjustment.binder"
                        v-on:input="adjustment.binder = formatString($event.target.value,limit.binder.bottom,limit.binder.top,'',adjustment)"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          kg/m<sup>3</sup>
                        </small>
                      </template>
                    </base-input>
                    <base-slider
                      @input="updatedAgent(adjustment)"
                      v-model="adjustment.binder"
                      :connect="true"
                      :options="{step: 1, range : {min: parseInt(limit.binder.bottom), max: parseInt(limit.binder.top)}}"
                      :decimals="0"
                    >
                    </base-slider>
                  </div>
                  <div class="col-md-6 text-center pt-2">
                    <h5
                      :class="adjustment.water >0 ? 'text-uppercase text-success' : adjustment.water <0 ? 'text-uppercase text-danger': 'text-uppercase text-light' ">
                      água</h5>
                    <base-input input-group-classes="input-group-sm">
                      <input
                        maxlength="4"
                        v-on:input="adjustment.water = formatString($event.target.value,limit.water.bottom,limit.water.top,'',adjustment)"
                        inputmode="numeric"
                        v-model="adjustment.water"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          kg/m<sup>3</sup>
                        </small>
                      </template>
                    </base-input>
                    <base-slider @input="updatedAgent(adjustment)" v-model="adjustment.water" :connect="true"
                                 :options="{step: 1, range: {min: parseInt(limit.water.bottom), max: parseInt(limit.water.top)}}"
                                 :decimals="0">
                    </base-slider>
                  </div>
                </div>

                <div class="row justify-content-md-center">
                  <div class="col-md-6 text-center pt-2">
                    <h5
                      :class="adjustment.content_mortar >0 ? 'text-uppercase text-success' : adjustment.content_mortar <0 ? 'text-uppercase text-danger': 'text-uppercase text-light' ">
                      teor de argamassa</h5>
                    <base-input input-group-classes="input-group-sm">
                      <input
                        maxlength="6"
                        v-on:input="adjustment.content_mortar = formatString($event.target.value,limit.content_mortar.bottom,limit.content_mortar.top,1,adjustment)"
                        inputmode="numeric"
                        v-model="adjustment.content_mortar"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          %
                        </small>
                      </template>
                    </base-input>
                    <base-slider
                      @input="updatedAgent(adjustment)"
                      v-model="adjustment.content_mortar"
                      :connect="true"
                      :options="{step: 0.1, range: {min: parseInt(limit.content_mortar.bottom), max: parseInt(limit.content_mortar.top)}}"
                      :decimals="1"
                    >
                    </base-slider>
                  </div>
                  <div class="col-md-6 text-center pt-2">
                    <h5
                      :class="adjustment.total_air >0 ? 'text-uppercase text-success' : adjustment.total_air <0 ? 'text-uppercase text-danger': 'text-uppercase text-light' ">
                      ar total</h5>
                    <base-input input-group-classes=" input-group-sm">
                      <input
                        maxlength="6"
                        v-on:input="adjustment.total_air = formatString($event.target.value,limit.total_air.bottom,limit.total_air.top,1,adjustment)"
                        inputmode="numeric"
                        v-model="adjustment.total_air"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          %
                        </small>
                      </template>
                    </base-input>
                    <base-slider
                      @input="updatedAgent(adjustment)"
                      v-model="adjustment.total_air"
                      :connect="true"
                      :options="{step: 0.1, range: {min: parseInt(limit.total_air.bottom), max: parseInt(limit.total_air.top)}}"
                      :decimals="1">
                    </base-slider>
                  </div>
                </div>
                <!-- aditivo a -->
                <div class="row justify-content-md-center">
                  <div class="col-md-6 text-center pt-2">
                    <h5
                      :class="adjustment.agent && adjustment.agent[0].value> 0 ? 'text-uppercase text-success' : adjustment.agent &&  adjustment.agent[0].value <0 ? 'text-uppercase text-danger': 'text-uppercase text-light' ">
                      Aditivo A</h5>
                    <base-input input-classes="form-control-sm">
                      <el-select @change="forceUpdate(adjustment)" filterable v-model="adjustment.agent[0].id"
                                 size="mini" placeholder="Selecione">
                        <el-option label="Selecione" value=""></el-option>
                        <el-option v-if="!adjustment.agent || agent.id !== adjustment.agent[1].id"
                                   v-for="agent in listGroups" :key="agent.id" :label="agent.group_name"
                                   :value="agent.id">
                        </el-option>
                      </el-select>
                    </base-input>
                    <base-input v-if="adjustment.agent && adjustment.agent[0].id"
                                input-group-classes="input-group-sm mt-3">
                      <input
                        maxlength="7"
                        v-on:input="adjustment.agent[0].value= formatDecimals($event.target.value,limit.agent.bottom,limit.agent.top,2,adjustment)"
                        v-model="adjustment.agent[0].value"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          %
                        </small>
                      </template>
                    </base-input>
                    <base-slider
                      v-if="adjustment.agent[0].id"
                      @input="updatedAgent(adjustment,true)"
                      v-model="adjustment.agent[0].value"
                      :connect="true"
                      :options="{step: 0.01, range: {min: parseInt(limit.agent.bottom), max: parseInt(limit.agent.top)}}"
                      :decimals="2">
                    </base-slider>
                  </div>

                  <div class="col-md-6 text-center pt-2">
                    <h5
                      :class="adjustment.agent && adjustment.agent[1].value >0 ? 'text-uppercase text-success' : adjustment.agent &&  adjustment.agent[1].value <0 ? 'text-uppercase text-danger': 'text-uppercase text-light' ">
                      Aditivo B</h5>
                    <base-input input-classes="form-control-sm">
                      <el-select @change="$forceUpdate()" filterable v-model="adjustment.agent[1].id" size="mini"
                                 placeholder="Selecione">
                        <el-option label="Selecione" value=""></el-option>
                        <el-option v-if="!adjustment.agent || agent.id !== adjustment.agent[0].id"
                                   v-for="agent in listGroups" :key="agent.id" :label="agent.group_name"
                                   :value="agent.id">
                        </el-option>
                      </el-select>
                    </base-input>
                    <base-input
                      v-if="adjustment.agent && adjustment.agent[1].id"
                      input-group-classes="input-group-sm mt-3"
                    >
                      <input
                        maxlength="7"
                        v-on:input="adjustment.agent[1].value = formatDecimals($event.target.value,limit.agent.bottom,limit.agent.top,2,adjustment)"
                        v-model="adjustment.agent[1].value"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          %
                        </small>
                      </template>
                    </base-input>
                    <div>
                      <base-slider
                        v-if="adjustment.agent[1].id"
                        @input="updatedAgent(adjustment,true)"
                        v-model="adjustment.agent[1].value"
                        :connect="true"
                        :options="{step: 0.01, range: {min: parseInt(limit.agent.bottom), max: parseInt(limit.agent.top)}}"
                        :decimals="2">
                      </base-slider>
                    </div>
                  </div>
                </div>
                <!--Start Observation-->
                <div class="row">
                  <div class="col-md-12 pt-4">
                    <base-input input-group-classes="input-group-sm">
                                        <textarea
                                          v-model="adjustment.observation"
                                          type="text"
                                          rows="4"
                                          class="form-control form-control-sm"
                                          v-on:input="forceUpdate(adjustment)"
                                        >
                                        </textarea>
                    </base-input>
                  </div>
                </div>
                <div v-if="adjustment.controls == 1" class="row">
                  <div class="col-md-6 mt-4">
                    <base-button block type="secondary" @click="restoreData(adjustment)">Cancelar</base-button>
                  </div>
                  <div class="col-md-6 mt-4">
                    <base-button block type="primary" @click="handleChargePointAdjustmentUpdate(adjustment,index)">
                      Salvar
                    </base-button>
                  </div>
                </div>

                <!--Start Last Updated At-->
                <div class="row mt-4 justify-content-sm-center">
                  <div class="col-md-6 text-center">
                    <small>{{adjustment.updated_at | parseDate('DD MMM YYYY HH:mm:ss') }}</small>
                  </div>
                  <div class="col-md-6 text-center">
                    <small>{{ adjustment.user }}</small>
                  </div>
                </div>
              </collapse-item>
            </collapse>
          </card>
        </div>
      </div>
    </div>
    <LoadingPagination :show="loading && !loadingSkeleton"/>
    <ModalEditAdjustment @updatedAdjustment="init" ref="editChargePoint"/>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex'
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import ModalEditAdjustment from './_Edit'
import {
  checkNumberValue
} from '@/helpers'
import {
  mask1, formatErrorValidation
} from '@/plugins'
import MultiFilter from "@/components/Utils/MultiFilterV2";
import cursorPaginate from "@/mixins/cursorPaginate"
import LoadingPagination from "@/components/LoadingPagination";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import adjustmentMixin from "@/views/Modules/Technology/Adjustment/Mixins/adjustment";

export default {
  name: "ListContractHourAdjustment",
  mixins: [cursorPaginate, adjustmentMixin],
  components: {
    SkeletonPuzlGrid,
    ModalEditAdjustment,
    MultiFilter,
    LoadingPagination,
    PuzlEmptyData
  },
  data() {
    return {
      filter: {
        status: null,
      },
      multi_filter: {
        null: {
          name: "Todos",
          type: "primary",
        },
        1: {
          name: "Com ajustes",
          type: "primary",
        },
        2: {
          name: "Sem ajustes",
          type: "success",
        },
      },
      loadingSkeleton: false,
      loadinfAdjustmentLimit: true,
      limit: {
        binder: {
          top: 100,
          bottom: -50
        },
        adjustment_type: null,
        uuid: null,
        water: {
          top: 100,
          bottom: -50
        },
        content_mortar: {
          top: 100,
          bottom: -50
        },
        total_air: {
          top: 100,
          bottom: -50
        },
        agent: {
          top: 100,
          bottom: -50
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      'listAdjustment': 'adjustmentContractHour/fetchFormulationAdjustments',
      'listGroups': 'cmcGroup/showGroupByCategory',
      'contract_formulation': 'adjustmentContractHour/formulation',
    }),
  },
  watch: {
    'added'() {
      this.init()
    }
  },
  props: {
    added: Boolean
  },
  methods: {
    forceUpdate(adjustment) {
      this.$forceUpdate()
      this.changeColor(adjustment, 1, 1)
    },
    changeColor(adjustment, color, controls = 0) {
      adjustment.color = color;
      adjustment.controls = controls;
    },
    restoreData(adjustment) {
      this.$Progress.start();
      this.$store.dispatch('adjustmentContractHour/show', adjustment.uuid)
        .then(response => {
          adjustment.binder = response.data.binder
          adjustment.water = response.data.water
          adjustment.content_mortar = response.data.content_mortar
          adjustment.total_air = response.data.total_air
          if (response.data.agent) {
            adjustment.agent = response.data.agent
          } else {
            adjustment.agent[0].value = null
            adjustment.agent[1].value = null
            adjustment.agent[0].id = null
            adjustment.agent[1].id = null
          }
          if (response.data.binder && response.data.binder != 0 || response.data.water && response.data.water != 0 || response.data.content_mortar && response.data.content_mortar != 0 || response.data.total_air && response.data.total_air != 0 || (response.data.agent && (response.data.agent[0].value && response.data.agent[0].value != 0 || response.data.agent[1].value && response.data.agent[1].value))) {
            adjustment.color = 2
          } else {
            adjustment.color = 0
          }
          adjustment.controls = 0
          this.$forceUpdate()
          this.$Progress.finish();
        })
    },
    updatedAgent(adjustment = "", forceUpdate = false) {
      if (adjustment) {
        this.changeColor(adjustment, 1, 1)
      }
      if (forceUpdate) {
        if (this.listAdjustment[0].binder || this.listAdjustment[0].binder === 0) {
          this.listAdjustment[0].binder += 'a'
          this.listAdjustment[0].binder = this.listAdjustment[0].binder.slice(0, -1)
        } else {
          this.listAdjustment[0].binder = 'a'
          this.listAdjustment[0].binder = this.listAdjustment[0].binder.slice(0, -1)
        }
      }
    },
    formatList() {
      this.listAdjustment.map(function (obj, key) {
        const forbidden_options = ['id', 'uuid', 'observation', 'updated_at', 'user', 'company_plant', 'contract_proposal_formulation_uuid', 'contract_proposal_uuid', 'mix_code', 'is_day', 'hour_min', 'hour_max']
        const decimal_options = ['total_air', 'content_mortar']
        // Tratamento dos aditivos.
        // Caso não esteja setado, definimos a estrutura para não termos problemas na listagem.
        if (!obj.agent) {
          obj.agent = []
          obj.agent[0] = {
            id: null,
            value: null
          }
          obj.agent[1] = {
            id: null,
            value: null
          }
        }
        // Tratamento de cores dos cards.
        // Caso algum dos valores esteja definido e seja diferente de 0 nós setamos a cor para azul
        for (var property in obj) {

          if (!Array.isArray(obj[property]) && obj[property] && decimal_options.includes(property)) {
            obj[property] = parseFloat(obj[property])
          }
          if (!forbidden_options.includes(property)) {
            if ((obj[property] && !Array.isArray(obj[property]) && obj[property] !== 0) ||
              (obj[property] && obj[property][0] && obj[property][0].value && obj[property][0].value != 0) ||
              (obj[property] && obj[property][1] && obj[property][1].value && obj[property][1].value != 0)) {
              obj.color = 2
            }
          }
        }
        // caso não tenha nenhuma configuração salva passamos a cor cinza
        if (!obj.color) {
          obj.color = 0
        }
        // Tratamento dos controles, sempre virão definidos para não aparecerem
        obj.controls = 0
      });
    },
    getAdjustmentLimit() {
      this.$store.dispatch('adjustmentLimitSetting/getByType', 6)
        .then(response => {
          this.loadinfAdjustmentLimit = false;
          this.limit = {
            binder: {
              top: response.data.binder.top,
              bottom: response.data.binder.bottom
            },
            adjustment_type: response.data.adjustment_type,
            uuid: response.data.uuid,
            water: {
              top: response.data.water.top,
              bottom: response.data.water.bottom
            },
            content_mortar: {
              top: response.data.content_mortar.top,
              bottom: response.data.content_mortar.bottom
            },
            total_air: {
              top: response.data.total_air.top,
              bottom: response.data.total_air.bottom
            },
            agent: {
              top: response.data.agent.top,
              bottom: response.data.agent.bottom
            }
          }
        }).catch(error => {
        if (error.response && error.response.status === 422) {
          let errors = formatErrorValidation(error.response.data.errors)
          this.$notify({
            type: 'danger',
            message: errors
          })
        }
      })
    },
    formatString(value, min, max, decimals = "", adjustment) {
      this.changeColor(adjustment, 1, 1)
      this.updatedAgent('', true)
      if (decimals) {
        return checkNumberValue(mask1(value.toString(), decimals, '.', true), min, max);
      }
      return checkNumberValue(value, min, max)
    },
    formatDecimals(value, min, max, decimals = "", adjustment) {
      this.changeColor(adjustment, 1, 1)
      this.updatedAgent('', true)
      max = parseFloat(max).toFixed(decimals)
      min = parseFloat(min).toFixed(decimals)
      return checkNumberValue(mask1(value.toString(), decimals, '.', true), min, max);
    },
    init(filter = null) {
      this.startCursor(filter);
      this.getAdjustmentLimit();
      this.$store.dispatch('adjustmentContractHour/fetchAdjustmentsItems', {
        type: this.type,
        filter: this.filter,
        next_page: this.paginate.nextUrl,
        uuid: this.contract_formulation.first_formulation_uuid
      })
        .then((response) => {
          this.formatList()
          this.resolveCursor(response);
        })
        .catch((error) => {
          this.formatList()
          this.resolveCursor();
        })
    },
    handleChargePointAdjustmentUpdate(adjustment, index) {
      adjustment.company_plant_uuid = [];
      adjustment.company_plant.map(function (value, key) {
        adjustment.company_plant_uuid.push(value.uuid);
      });
      let has_agent_value = false;
      if (adjustment.agent) {
        adjustment.agent.map(function (obj) {
          if (obj.value && parseFloat(obj.value) !== null) {
            has_agent_value = true;
          }
        })
      }
      adjustment.agent = has_agent_value ? adjustment.agent : null;
      this.$Progress.start();
      this.loadingSkeleton = true;
      this.$store.dispatch('adjustmentContractHour/update', adjustment)
        .then(response => {
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.init()
        })
        .catch(error => {
          if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors)
            this.$notify({
              type: 'danger',
              message: errors
            })
          }
        })
    },
    handleDeleteAdjustment(uuid) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.loadingSkeleton = true;
          this.$notify({
            type: 'info',
            message: 'Estamos trabalhando em sua solicitação.'
          });
          this.$store.dispatch('adjustmentContractHour/destroy', uuid).then((response) => {
            this.$notify({type: response.error_type, message: response.message});
            this.init()
          })
        }
      }).catch(() => this.$Progress.finish())
    },
    handleEditAdjustment(uuid) {
      this.$refs.editChargePoint.handleEditModal(uuid)
    }
  },
  mounted() {
    this.getAdjustmentLimit()
    this.$store.dispatch('cmcGroup/fetchGroupByCategory', 4)
    this.init({})
  },
}
</script>

<style scoped>

</style>
