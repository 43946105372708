<template>
<div>

<modal :show.sync="modal">
  <template slot="header">
    <h5 class="modal-title">{{ title }}</h5>
  </template>
  <div>
    <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
      <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
          <div>
            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-3 pb-0 pt-1 col-form-label form-control-label">
                Título
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-9">
                <validation-provider rules="required" v-slot="{errors}">
                <base-input input-group-classes="input-group-sm">
                  <input
                    v-model="additional_informattion.title"
                    type="text"
                    class="form-control form-control-sm"
                    :class="errors[0] ? 'is-invalid' : 'is-valid'"
                  />
                </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-3 pb-0 pt-1 col-form-label form-control-label">
                Observações
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-9">
                <validation-provider rules="required" v-slot="{errors}">
                <base-input input-group-classes="input-group-sm">
                  <textarea 
                    type="text" 
                    v-model="additional_informattion.observation" 
                    rows="2" 
                    class="form-control form-control-sm"
                    :class="errors[0] ? 'is-invalid' : 'is-valid'">
                  </textarea>
                </base-input>
                </validation-provider>
              </div>
            </div>
          </div>
        <div class="modal-footer">
          <base-button type="secondary" @click="closeModal()">Cancelar</base-button>
          <base-button type="success" native-type="submit" :disabled="invalid" :loading="loading">Salvar</base-button>
        </div>
      </form>
    </validation-observer>
  </div>
</modal>
</div>
</template>

<script>
export default {
  components: {},
  name: "ModalChangeStatus",
  data() {
    return {
      modal: false,
      title: 'Adicionar informação complementar',
      validated: false,
      loading: false,
      additional_informattion: {
        contract_proposal_uuid: null,
        title: null,
        observation: null,
      },
    }
  },
  methods: {
    closeModal () {
      this.modal = false
    },
    openModal (contratctProposalUuid) {
      this.additional_informattion = {
        contract_proposal_uuid: contratctProposalUuid,
        title: null,
        observation: null,
      }
      this.modal = true
    },
    save(){
      this.loading = true
      this.$store.dispatch('contractProposal/add_comment', this.additional_informattion).then(response => {
        this.loading = false
        this.$Progress.finish()
        this.$notify({type: response.error_type, message: response.message})
        this.modal = false
      }).catch(error => {
        this.loading = false
        this.$Progress.finish()
        this.$notify({type: error.error_type, message: error.message})
      })
    },
  },
  created() {},
  mounted() {
    this.$refs.formValidator.validate();
  }
};
</script>

<style></style>
