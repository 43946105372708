import { createAxios } from "@/plugins/axios";
import axios from "axios";
import { HttpResponseType } from "../../types";

/**
 * @typedef {import("axios").AxiosRequestConfig} AxiosRequestConfig
*/

/** Tipos de requisições HTTP */
export const HTTP_TYPE = Object.freeze({
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
});

/**
 * Executa requisição HTTP utilizando o axios
 * 
 * @param {Object} params - Os parâmetros da requisição.
 * @param {string} params.httpType - O tipo de requisição HTTP. O valor padrão é 'GET'.
 * @param {string} params.url - A URL para a requisição.
 * @param {?Object} params.data - Os dados para a requisição.
 * @param {?AxiosRequestConfig} params.config - A configuração adicional para a requisição.
 * @returns {Promise<HttpResponseType>} A resposta da requisição.
 * @throws {Error} Se o tipo de requisição HTTP não for suportado.
 */
const makeRequest = async ({ httpType = HTTP_TYPE.GET, url, data = null, config = null }) => {
  try {
    const axiosInstance = createAxios();
    let axiosResponse;

    switch (httpType.toLowerCase()) {
      case HTTP_TYPE.GET:
        axiosResponse = await axiosInstance.get(url, config);
        break;
      case HTTP_TYPE.POST:
        axiosResponse = await axiosInstance.post(url, data, config);
        break;
      case HTTP_TYPE.PUT:
        axiosResponse = await axiosInstance.put(url, data, config);
        break;
      case HTTP_TYPE.DELETE:
        axiosResponse = await axiosInstance.delete(url, config);
        break;
      default:
        throw new Error(`Unsupported HTTP type: ${httpType}`);
    }
    return {
      data: axiosResponse.data,
      status: axiosResponse.status,
      statusText: axiosResponse.statusText,
      headers: axiosResponse.headers,
    };
  } catch (error) {
    return handleAxiosError(error);
  }
};

/**
 * Tratamento de erros do axios
 * 
 * @param {unknown} error
 * @returns {HttpResponseType} response
 */
const handleAxiosError = (error) => {
  if (axios.isAxiosError(error)) {
    // A requisição foi feita e o servidor respondeu com um código de status
    // que é superior a 2xx
    if (error.response) {
      return {
        data: error.response?.data,
        status: error.response?.status,
        statusText: error.response?.statusText,
        headers: error.response?.headers,
      };  
    }
    // A requisição foi feita mas nenhuma resposta foi recebida
    if (error.request) {
      return {
        data: null,
        status: null,
        statusText: "The request was made but no response was received",
        headers: null,
      };
    }
  }
  return {
    data: null,
    status: null,
    statusText: "Unknown error occurred",
    headers: null,
  };
}

/**
 * Faz uma requisição GET utilizando o axios.
 * 
 * @param {string} url - A URL para a requisição.
 * @param {?AxiosRequestConfig} config - A configuração adicional para a requisição.
 * @returns {Promise<HttpResponseType>} A resposta da requisição.
 */
const get = async (url, config = null) => await makeRequest({ url, config });

/**
 * Remove um recurso utilizando o método DELETE.
 * 
 * @param {string} url - A URL para a requisição.
 * @param {?AxiosRequestConfig} config - A configuração adicional para a requisição.
 * @returns {Promise<HttpResponseType>} A resposta da requisição.
 */
const remove = async (url, config = null) => await makeRequest({ httpType: HTTP_TYPE.DELETE, url, config });

/**
 * Envia uma requisição POST.
 * 
 * @param {string} url - A URL para a requisição.
 * @param {?Object} data - Os dados para a requisição.
 * @param {?AxiosRequestConfig} config - A configuração adicional para a requisição.
 * @returns {Promise<HttpResponseType>} A resposta da requisição.
 */
const post = async (url, data = null, config = null) => await makeRequest({ httpType: HTTP_TYPE.POST, url, data, config });

/**
 * Envia uma requisição PUT.
 * 
 * @param {string} url - A URL para a requisição.
 * @param {?Object} data - Os dados para a requisição.
 * @param {?AxiosRequestConfig} config - A configuração adicional para a requisição.
 * @returns {Promise<HttpResponseType>} A resposta da requisição.
 */
const put = async (url, data = null, config = null) => await makeRequest({ httpType: HTTP_TYPE.PUT, url, data, config });

/**
 * Envia uma requisição PATCH.
 * 
 * @param {string} url - A URL para a requisição.
 * @param {?Object} data - Os dados para a requisição.
 * @param {?AxiosRequestConfig} config - A configuração adicional para a requisição.
 * @returns {Promise<HttpResponseType>} A resposta da requisição.
 */
const patch = async (url, data = null, config = null) => await makeRequest({ httpType: HTTP_TYPE.PATCH, url, data, config });

export default {
  get,
  remove,
  post,
  put,
  patch,
};