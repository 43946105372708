<template>
<div>
    <div class="mt-5">
        <TreeView :items="$_accounts" @init="init" />
    </div>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import MultiFilter from '@/components/Utils/MultiFilter.vue';
import PuzlEmptyData from "@/components/PuzlEmptyData";
import TreeView from './_TreeView.vue';

export default {
    name: "ListChartAccountResume",
    components: {
        SkeletonPuzlGrid,
        MultiFilter,
        PuzlEmptyData,
        TreeView,
    },
    data() {
        return {
            loadingSkeleton: false,
            childrensChartAccount : [],
        }
    },
    computed: {
        ...mapGetters({
            $_accounts: 'managerialChartAccount/fetch',
        }),
    },
    watch: {
        'added'() {
            this.init({})
        }
    },
    props: {
        added: Boolean
    },
    methods: {
        init() {
            this.$store.dispatch('managerialChartAccount/fetchItems', {
                }).then((response) => {
                }).catch((error) => {
                    this.$notify({
                        type: response.error_type,
                        message: response.message
                    })
                });
        },
        handleDeleteChartAccount(uuid) {
            this.$Swal.confirmDelete().then((result) => {
                if (result.isConfirmed) {
                    this.$Progress.start();
                    this.$notify({
                        type: 'info',
                        message: 'Estamos trabalhando em sua solicitação.'
                    });
                    this.$store.dispatch('managerialChartAccount/destroy', uuid).then((response) => {
                        this.$notify({
                            type: response.error_type,
                            message: response.message
                        })
                        this.$Progress.finish()
                        this.fetch()
                    })
                }
            }).catch(() => this.$Progress.finish())
        },
        handleEditChartAccount(uuid) {
            this.$refs.modalEditChartAccount.handleEditModal(uuid)
        },
        handleCreateChartAccount(uuid) {
            this.$emit('createdChartAccount', uuid)
        },
    },
    created() {
        this.init({})
    }
}
</script>

<style scoped>
</style>
