<template>
  <div>
    <modal :borderInfo="'2px solid #1b6eba'" :show.sync="modal" size="sm">
      <template slot="header">
        <div style="display: flex;align-items: center;">
          <img style="display: inline-flex;" src="/img/icons/icons8/ios/link.png" width="22">
          <h5 style="display: inline-flex;" class="modal-title p-0 m-0 ml-3 text-white">{{ title }}</h5>
        </div>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form @submit.prevent="handleSubmit(save)" class="needs-validation" autocomplete="off">
            <div class="container-fluid">
              <div class="row mt-3 mb-4">
                <div class="col-12" style="display: flex; align-items: center;">
                  <img src="/img/icons/icons8/ios/checked_primary.png" width="20">
                  <h4 class="new-default-black-font m-0 mx-3 ">
                    {{ action ? "Finalizar conciliação" : "Confirmar conciliação" }}
                  </h4>
                </div>
              </div>
              <div v-if="action" class="row mb-3 align-items-center">
                <div class="col-12 mb-1">
                  <h6 style="font-size: 11.5px;" class="new-default-black-font font-weight-normal m-0">
                    Digite o saldo final para confirmar a operação
                  </h6>
                </div>
                <div class="col-12">
                  <!-- <validation-provider rules="required" v-slot="{errors}"> -->
                    <base-input input-group-classes="input-group-sm">
                      <input
                        class="form-control form-control-sm"
                        v-model="amount"
                        v-money="money"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0">
                          R$
                        </small>
                      </template>
                    </base-input>
                  <!-- </validation-provider> -->
                </div>
              </div>
              <div class="row mt-5 mb-4">
                <div class="col-12 text-center">
                  <base-button type="danger"
                    size="sm"
                    style="width: 130px;" @click.prevent="closeModal">
                    <div style="display: inline-flex; align-items: center;">
                      <img src="/img/icons/cancel-white.png" width="20">
                      <i class="m-0 ml-1" style="font-style: normal;">
                        Cancelar
                      </i>
                    </div>
                  </base-button>
                  <base-button type="success"
                    size="sm"
                    style="width: 130px;"
                    v-bind:disabled="invalid"
                    native-type="submit">
                    <div style="display: inline-flex; align-items: center;">
                      <img src="/img/icons/save.png" width="20">
                      <i class="m-0 ml-1" style="font-style: normal;">
                        {{ action ? "Finalizar" : "Confirmar" }}
                      </i>
                    </div>
                  </base-button>
                </div>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { formatErrorValidation } from "@/plugins";
import moment from "moment";
import { SIMPLE_CONCILIATION_ENUM } from "./Enums/SimpleConciliationEnum";
import {VMoney} from "v-money";
import { strToNum } from "@/helpers";

export default {
  components: {},
  name: "ModalConfirmOrFinishConciliation",
  data() {
    return {
      modal: false,
      title: 'Conciliação Financeira',
      loading: false,
      amount: null,
      action: null,
      discharge_at: null,
      reconciledItems: [],
      bank_account_id: null,
      simpleConciliationEnum: SIMPLE_CONCILIATION_ENUM,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false
      },
    }
  },
  directives: {money: VMoney},
  methods: {
    save(){
      if(this.action){
        this.finishConciliation();
      } else {
        this.confirmConciliation();
      }
    },
    finishConciliation(){
      const payload = {
        discharge_at: this.discharge_at,
        bank_account_id: this.bank_account_id,
        final_balance: strToNum(this.amount, 0, true),
      };

      this.$store.dispatch("financeTransactions/close", payload)
      .then(() => {
        this.$emit('updateScreen');
        this.closeModal();
      }).catch(error => {
        const errors = error && error.response && error.response.status === 422
            ? formatErrorValidation(error.response.data.data)
            : error.response.data.message;
        this.$notify({ type: "danger", message: errors });
      });
      this.$Progress.finish();
    },
    confirmConciliation(){
      const items = this.formatReconciledItems();
      this.$store.dispatch("financeTransactions/reconcile", { items })
      .then(() => {
        this.$emit('updateScreen');
        this.closeModal();
      }).catch(error => {
        const errors = error && error.response && error.response.status === 422
            ? formatErrorValidation(error.response.data.data)
            : error.response.data.message;
        this.$notify({ type: "danger", message: errors });
      });
      this.$Progress.finish();
    },
    closeModal() {
      this.modal = false;
    },
    openModal(reconciledItems, action, filter){
      this.action = action;
      this.bank_account_id = filter.bank_account_id;
      this.discharge_at = moment(filter.range_discharge_at.start).format("YYYY/MM/DD");
      this.reconciledItems = reconciledItems;
      this.amount = null;
      this.modal = true;
    },
    formatReconciledItems() {
      let newReconciledItems = [];
      this.reconciledItems.map(reconciledItem => {
        if([this.simpleConciliationEnum.UNRECONCILE, this.simpleConciliationEnum.SELECTED, this.simpleConciliationEnum.CANCEL].includes(reconciledItem.status)){
          newReconciledItems.push({
            id: reconciledItem.id,
            status: Number(![this.simpleConciliationEnum.UNRECONCILE, this.simpleConciliationEnum.CANCEL].includes(reconciledItem.status)),
          });
        }
      });
      return newReconciledItems;
    },
  },
};
</script>

<style scoped>
.divider-header {
  background: #e1e1e1;
  padding: 3px 20px;
  color: #db4539;
/*  color: #6b8184;*/
  font-size: 11px;
  width: fit-content;
  display: inline-flex;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
</style>
