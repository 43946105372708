import { adjustmentHistoryUrls } from ".";
import httpClient from "../../../../shared/libraries/httpClient";
import { handleUnexpectedStatusCode } from "../../../../shared/services";
import { HttpStatusEnum } from "../../../../shared/enums";
import {
  AdjustmentHistoryListFilterType,
  AdjustmentHistoryListPagerType,
} from "../types";

/**
 * Listar
 *
 * @param {?AdjustmentHistoryListFilterType} filter
 * @returns {Promise<AdjustmentHistoryListPagerType}
 */
const execute = async (filter = null) => {
  const res = await httpClient.get(adjustmentHistoryUrls.BASE, {
    params: filter,
  });

  switch (res.status) {
    case HttpStatusEnum.HTTP_OK:
      return res.data.data;
    default:
      handleUnexpectedStatusCode(res);
  }
};

export default { execute };
