<template>
  <div>
    <div>
      <div class="card mt-3" :class="!show_additional_filters && 'mb-4'" :style="'margin-bottom: 3.22rem !important'"
        v-show="show_filters">
        <div class="card-body">
          <div class="row show-md">
            <div class="offset-md-8 col-md-4 p-1 text-right">
              <h5 v-show="show_filters" @click.prevent="show_filters = false" class="pointer font-weight-normal new-default-gray-font"
                style="margin-top: -3.3rem !important">OCULTAR FILTROS <i class="fa-regular fa-eye-slash fs-11"></i></h5>
            </div>
            <div class="col-md-3 p-1 mt-n3">
              <div class="new-default-borders"
                style="display: grid;align-items: center;text-align: center;grid-template-columns: 10% 80% 10%;"
                type="white"
                block>
                <div class="text-left pl-3">
                  <img height="19" @click.prevent="$emit('previousFile')"
                    src="/img/icons/icons8/ios/arrow-left-secondary.png"
                    class="pointer" />
                </div>
                <span class="new-default-gray-font fs-12">
                  {{  range.start | parseDate('DD/MM/YYYY')  }}
                  <i class="fs-10 mx-2"
                    style="font-style: normal;">
                    à
                  </i>
                  {{  range.end | parseDate('DD/MM/YYYY')  }}
                </span>
                <div class="text-right pr-3">
                  <img height="19" @click.prevent="$emit('nextFile')"
                    src="/img/icons/icons8/ios/arrow-right-secondary.png"
                    class="pointer" />
                </div>
              </div>
            </div>
            <div class="col-md-6 p-1 mt-n3">
              <div class="d-flex w-100" style="height: 2.7rem;">
                <input @keydown.enter="push" @keypress.space="push" v-model="filtered" placeholder="Pesquisar"
                  style="width: 50vw;"
                  class="form-control new-default-borders" />
                <img @click.prevent="push()" src="/img/icons/icons8/ios/search.png" height="18"
                  style="margin-top: 10px" class="default-search zoom pointer">
              </div>
            </div>
            <div class="col-md-3 p-1 mt-n3" style="margin-bottom: -20px;padding-left: 1.1rem !important">
              <div class="row">
                <div class="col-md-4 pr-1 text-right">
                  <base-button @click.prevent="global = []; clearFilter(); push();"
                    class="new-default-borders"
                    type="white" block>
                    <div style="margin-top: -4px" class="d-flex justify-content-center">
                      <img height="21" src="/img/icons/icons8/ios/synchronize.png" alt="synchronize" />
                    </div>
                  </base-button>
                </div>
                <div class="col-md-8 px-1 pr-3 text-right">
                  <base-button class="new-default-borders"
                    @click="show_additional_filters = true" type="white" block>
                    <div style="margin-top: -2px;font-size: 13px;color: #6d6e74">
                      <img height="19" style="margin-top: -4px" src="/img/icons/icons8/ios/filter.png" alt="filter--v1" />
                      <span>
                        Filtros
                      </span>
                    </div>
                  </base-button>
                </div>
              </div>
            </div>
            <div class="col-md-8 pl-1 pt-3 mt-n2" style="margin-bottom: -0.9rem !important">
              <transition name="el-zoom-in-center">
                <h3 v-if="global && global.length"><span class="badge badge-primary text-white mr-2"
                    style="background: #1A70B7" v-for="(item, key) in global"> {{ item }} <a href="#">
                      <i @click.prevent="removeSelected(key)" class="fa-solid fa-xmark text-white"
                        style="font-size: 16px;opacity: 50%;margin-left: 2px;margin-right: 2px"></i>
                    </a>
                  </span>
                </h3>
              </transition>
            </div>
            <div v-if="showSlotOrderBy" class="col-md-4" style="margin-bottom: -1.2rem">
              <slot name="order-by"></slot>
            </div>
          </div>
          <div class="row form-row show-mobile">
            <div class="offset-6 col-6 p-1 text-right">
              <h5 v-show="show_filters" @click.prevent="show_filters = false" class="pointer font-weight-normal new-default-gray-font"
                style="margin-top: -3.3rem !important">OCULTAR FILTROS <i class="fa-regular fa-eye-slash fs-11"></i></h5>
            </div>
            <div class="col-md-4 p-1 mt-n3 mb-1">

            </div>
            <div class="col-md-12 mt-2">
              <div class="d-flex w-100" style="height: 2.7rem;">
                <input @keydown.enter="push" @keypress.space="push" v-model="filtered" placeholder="Pesquisar"
                  style="width: 100%;"
                  class="form-control new-default-borders" />
                <img @click.prevent="push()" src="/img/icons/icons8/ios/search.png" height="32"
                  class="default-search zoom pointer">
              </div>
            </div>
            <div class="col-md-4 pt-n0 p-1 mt-0">
              <div class="row">
                <div class="col-4 pr-1">
                  <base-button @click.prevent="global = []; clearFilter(); push();"
                    class="new-default-borders"
                    type="white" block>
                    <img height="19" src="/img/icons/icons8/ios/synchronize.png" alt="synchronize" />
                  </base-button>
                </div>
                <div class="col-8 pl-1">
                  <base-button @click="show_additional_filters = true"
                    class="new-default-borders"
                    type="white"
                    block>
                    <img height="19" src="/img/icons/icons8/ios/filter.png" alt="filter" />
                    <span class="new-default-gray-font">
                      FILTROS
                    </span>
                  </base-button>
                </div>
              </div>
            </div>
            <div class="col-md-8 pl-1 pt-3 mt-1" style="margin-bottom: -0.9rem !important">
              <transition name="el-zoom-in-center">
                <h3 v-if="global && global.length"><span class="badge badge-primary text-white mr-2"
                    style="background: #1A70B7" v-for="(item, key) in global"> {{ item }} <a href="#">
                      <i @click.prevent="removeSelected(key)" class="fa-solid fa-xmark text-white"
                        style="font-size: 16px;opacity: 50%;margin-left: 2px;margin-right: 2px"></i>
                    </a>
                  </span>
                </h3>
              </transition>
            </div>
            <div v-if="showSlotOrderBy" class="col-md-4" style="margin-bottom: -1.2rem">
              <slot name="order-by"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin-top: -7px;margin-bottom: 20px" v-show="!show_filters">
      <div class="col-md-12 text-right" style="padding-right: 2rem">
        <h5 class="pointer" @click="show_filters = true">MOSTRAR FILTROS <i class="fa-regular fa-eye"></i></h5>
      </div>
      <div class="col-md-12 mb-3">
        <div style="border-top: 1px solid #1A70B7;margin-bottom: 10px" />
      </div>
    </div>
    <div class="scrolling-box" style="margin-top: -2%">
      <transition name="slide-fade">
        <div @mouseleave="show_additional_filters = false" v-if="show_additional_filters" class="float-filter float-filter-vw"
          style="overflow: hidden">
          <div style="width: 100%;background: #1A70B7;height: 69px">
            <div class="row">
              <div @click.prevent="show_additional_filters = false" class="col-8 pl-0 ml-n2" style="margin-top: 24px">
                <h3 class="text-white pointer">
                  <a href="#" class="show-mobile">
                    <img width="22" height="22" src="https://img.icons8.com/ios/50/FFFFFF/circled-chevron-left--v1.png"
                      alt="circled-chevron-right--v1" />
                  </a>
                  FILTROS DE PESQUISA
                </h3>
              </div>
              <div class="col-4" style="margin-top: 24px">
                <a href="#" @click.prevent="clearFilter()">
                  <img height="24" src="/img/icons/icons8/ios/erase.png" alt="synchronize">
                </a>
              </div>
            </div>
          </div>
          <div style="overflow: auto;scroll-behavior: smooth;max-height: 84vh" class="container-fluid">
            <div v-if="showSlotData" class="row pr-4 pl-4" style="margin-top: 27px">
              <div class="col-4 text-left pr-0 mb-n1">
                <h3 class="text-primary">
                  <img width="100" height="100" src="/img/icons/icons8/ios/folder-invoices.png" alt="calendar--v1"
                    style="width: 25px;height: 22px;padding-right: 5px;"> DADOS
                </h3>
              </div>
              <div class="col-8 pl-0">
                <div
                  style="border-top: 1px solid rgb(26, 112, 183);padding-top: 10px !important;margin-top: 10px;margin-left: 6px">
                </div>
              </div>
              <slot name="data" />
            </div>
            <div v-if="showSlotStatus" class="row mt-4 pr-4 pl-4">
              <div class="col-4 text-left pr-0 mb-1">
                <h3 class="text-primary">
                  <img width="100" height="100" src="/img/icons/icons8/ios/progress-indicator.png"
                    style="width: 25px;height: 22px;padding-right: 5px;" alt="progress-indicator" />
                  STATUS
                </h3>
              </div>
              <div class="col-8 pl-0">
                <div
                  style="border-top: 1px solid rgb(26, 112, 183);padding-top: 10px !important;margin-top: 10px;margin-left: 7px">
                </div>
              </div>
              <slot name="status" />
            </div>
            <div v-if="showSlotBookmark" class="row pr-4 pl-4" style="margin-top: 21px">
              <div class="col-6 text-left pr-0 mb-2">
                <h3 class="text-primary">
                  <img width="100" height="100" src="/img/icons/icons8/ios/rfid-signal.png"
                    style="width: 25px;height: 22px;padding-right: 5px;" alt="rfid-signal" /> MARCADORES
                </h3>
              </div>
              <div class="col-6 pl-0">
                <div style="border-top: 1px solid rgb(26, 112, 183);padding-top: 10px !important;margin-top: 10px;"></div>
              </div>
              <slot name="bookmarks" />
              <div style="margin-bottom: 80px !important;"></div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  name: "Filters",
  data() {
    return {
      show_filters: true,
      show_additional_filters: false,
      global: [],
      filtered: null,
      period: [],
    };
  },
  watch: {
    show_additional_filters(isAdditionalFiltersOpen) {
      this.$emit('additionalFiltersToggled', isAdditionalFiltersOpen);
    },
  },
  props: {
    range: {
      type: Object,
      default: {
        start: new Date(),
        end: new Date(),
      }
    },
    showSlotOrderBy: {
      type: Boolean,
      default: true,
      description: "Exibir slot de ordenação",
    },
    showSlotData: {
      type: Boolean,
      default: true,
      description: "Exibir slot de dados",
    },
    showSlotStatus: {
      type: Boolean,
      default: true,
      description: "Exibir slot de status",
    },
    showSlotBookmark: {
      type: Boolean,
      default: true,
      description: "Exibir slot de marcadores",
    },
  },
  computed: {},
  methods: {
    push() {
      if (!this.filtered) {
        return this.handleFilterClick();
      }
      this.filtered.split(' ').map(function (item) {
        item = item.replaceAll('(', '')
        item = item.replaceAll(')', '')
        if (item != '') {
          this.global.push(item);
        }
      }, this)
      this.filtered = null;
      this.handleFilterClick();
    },
    clearFilter() {
      this.$emit('clearFilter')
    },
    handleFilterClick() {
      const filters = { ...this.filter };
      filters.global = this.global;
      if (filters.status == null) {
        delete filters.status;
      }
      this.$emit("fetch", filters);
    },
    removeSelected(key) {
      this.global.splice(key, 1);
      this.handleFilterClick();
    },
  }
};
</script>
<style scoped>
.card {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.07) !important;
  border-radius: 8px !important;
  border: 1px solid #E8E8E8;
}

.new-default-borders, .new-default-borders:hover {
  height: 2.7rem;
  border-radius: 8px !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #E8E8E8;
}

input {
  display: block;
  width: 50vw;
  padding: 0 1.25rem;
}

input,
input::placeholder {
  font: 1rem/3 Fredoka;
  font-weight: 400;
}
</style>
<style>
.default-search {
  margin-left: -34px;
  height: 20px;
  margin-top: 7px;
}

.btn-success-light {
  background-color: #68b879;
  border-color: #68b879;
}

@media only screen and (min-width: 600px) {
  .float-filter-vw {
    width: 28vw;
  }
}

@media only screen and (max-width: 600px) {
  .float-filter-vw {
    width: calc(100vw - 30px);
  }
}

.float-filter {
  z-index: 99999999999;
  position: fixed;
  top: 5px;
  height: calc(100vh - 10px);
  max-height: 100%;
  right: 15px;
  background-color: white;
  border: 1px solid #606602;
  color: #FFF;
  border-radius: 4px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
}

</style>

