<template>
<div>
    <div class="container-fluid">
        <multi-filter ref="multi-filter" @fetch="init" :filter="filter" /> 
        <div class="row card-wrapper" v-show="loadingSkeleton">
            <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
        </div>

        <PuzlEmptyData v-if="!stabilizingAdditives.length && !loadingSkeleton"></PuzlEmptyData>

        <div class="row card-wrapper" v-if="stabilizingAdditives.length && !loadingSkeleton">
            <div class="col-lg-4" v-for="(stabilizingAdditive, index) in stabilizingAdditives" :key="index">
                <!-- Basic with action button -->
                <card :class="stabilizingAdditive.status === 1 ? 'card-border-top-success' : 'card-border-top-light'">
                    <!-- Card header -->
                    <div class="row align-items-center mb-3">
                        <div class="col-9 justify-content-center pr-0">
                            <p class="pb-0 mb-0 pr-1">
                                <b v-if="stabilizingAdditive.product"> {{stabilizingAdditive.product.name}}</b>
                                <br v-if="stabilizingAdditive.supplier">{{stabilizingAdditive.supplier.name}} ({{stabilizingAdditive.supplier_origin.name}})
                            </p>
                        </div>
                        <div class="col-3 text-right pl-0">
                            <base-dropdown menuOnRight>
                                <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                                    <i class="fas fa-cog"></i>
                                </base-button>
                                <div class="dropdown-divider p-0 m-0"></div>
                                <a class="dropdown-item" @click="handleEditStabilizingAdditiveAdjustment(stabilizingAdditive.uuid)">
                                    <i class="fas fa-edit text-warning"></i>
                                    Editar
                                </a>
                                <div class="dropdown-divider p-0 m-0"></div>
                                <a class="dropdown-item text-center" @click="handleCreateStabilizingAdditiveDosage(stabilizingAdditive.uuid)">
                                    <i class="fas fa-plus text-success"></i>
                                    Incluir dosagem
                                </a>
                                <div class="dropdown-divider p-0 m-0"></div>
                                <a class="dropdown-item" @click="handleDeleteStabilizingAdditiveAdjustment(stabilizingAdditive.uuid)">
                                    <i class="fas fa-times text-danger"></i>
                                    Excluir
                                </a>
                            </base-dropdown>
                        </div>
                    </div>

                    <!-- Observações-->
                    <collapse v-if="stabilizingAdditive.observation" class="border rounded p-0 mt-3">
                        <collapse-item name="1" class="header-gray card-body-molde" back-ground="border-header-primary-bottom">
                            <h5 slot="title" class="mb-0">Observações</h5>
                            <!--Start Observation-->
                            <div class="row">
                                <div class="col-md-12 text-center">
                                    <small> {{stabilizingAdditive.observation}} </small>
                                </div>
                            </div>
                        </collapse-item>
                    </collapse>
                    <!-- Dosagens-->
                    <collapse v-if="stabilizingAdditive.dosages && stabilizingAdditive.dosages[0]" class="border rounded p-0 mt-3">
                        <collapse-item name="1" class="header-gray card-body-molde" back-ground="border-header-danger-bottom">
                            <h5 slot="title" class="mb-0">Dosagens</h5>
                            <div class="pl-4 pr-4">
                                <!-- Titles -->
                                <div class="row pt-2">
                                    <div class="col-4 text-center pt-2 pr-1">
                                        <h5>Prazo</h5>
                                    </div>
                                    <div class="col-4 text-center pt-2 pl-1 pr-0">
                                        <h5 class="text-uppercase">%(S.P.A.)</h5>
                                    </div>
                                    <div class="col-4 text-center pt-2 pl-1 pr-3">
                                        <i class="fas fa-cog "></i>
                                    </div>
                                </div>
                                <hr class="m-0 pb-1" type="primary">
                                <!-- Body -->
                                <div class="pb-3">
                                    <div class="row" v-for="(dosage, index) in stabilizingAdditive.dosages" :key="index">
                                        <div class="col-4 text-center pr-1">
                                            <small>{{dosage.stabilization_time}} {{dosage.stabilization_time && dosage.stabilization_time >1? 'horas' : 'hora'}}</small>
                                        </div>
                                        <div class="col-4 text-center pl-1 pr-0">
                                            <small>{{dosage.dosage_content}} %</small>
                                        </div>
                                        <div class="col-4 pl-1 pr-3 justify-content-center">
                                            <div class="d-flex justify-content-center">
                                                <a href="#">
                                                    <i @click="handleEditStabilizingAdditiveDosage(dosage.uuid)" class="fas fa-edit text-warning mr-1"></i>
                                                </a>
                                                <a @click="handleDeleteStabilizingDosage(dosage.uuid)" href="#">
                                                    <i class="fas fa-times text-danger"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </collapse-item>
                    </collapse>
                </card>
            </div>
        </div>
    </div>
    <modal-edit-dosage @editedDosage="init" ref="editDosage"> </modal-edit-dosage>
    <modal-create-dosage @createdDosage="init" ref="createDosage"> </modal-create-dosage>
    <modal-edit-stabilizing-additive ref="editStabilizingAdditive"></modal-edit-stabilizing-additive>
    <loading-pagination :show="loading && !loadingSkeleton"/>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import modalEditStabilizingAdditive from './_ModalEditStabilizingAdditive'
import modalCreateDosage from '../Dosage/_ModalCreateDosage'
import modalEditDosage from '../Dosage/_ModalEditDosage'
import PuzlEmptyData from "@/components/PuzlEmptyData"
import MultiFilter from "@/components/Utils/MultiFilterV2"
import cursorPaginate from "@/mixins/cursorPaginate"
import LoadingPagination from "@/components/LoadingPagination"
import {
    formatErrorValidation
} from '@/plugins'
export default {
    name: "ListStabilizingAdditiveAdjustment",
    mixins: [cursorPaginate],
    components: {
        SkeletonPuzlGrid,
        modalEditStabilizingAdditive,
        modalCreateDosage,
        modalEditDosage,
        PuzlEmptyData,
        MultiFilter,
        cursorPaginate,
        LoadingPagination,
    },
    data() {
        return {
            loadingSkeleton: false,
            filter: {},
        }
    },
    computed: {
        ...mapGetters({
            'stabilizingAdditives': 'adjustmentStabilizingAdditive/fetch',
        }),
    },
    watch: {
        'added'() {
            this.init()
        }
    },
    props: {
        added: Boolean
    },
    methods: {
        init(filter = null) {
            this.startCursor(filter)
            this.$Progress.start();
            this.$store.dispatch('adjustmentStabilizingAdditive/fetchItemsPaginate', {
                    filter: this.filter,
                    next_page: this.paginate.nextUrl
                })
                .then((response) => {
                    this.resolveCursor(response)
                    this.$Progress.finish()
                    this.stabilizingAdditives.map(function (obj, key) {
                        obj.dosages.sort((a, b) => parseInt(a.stabilization_time) - parseInt(b.stabilization_time));
                    })
                })
                .catch((error) => {
                    this.resolveCursor()
                    this.$Progress.finish()
                })
        },
        handleDeleteStabilizingAdditiveAdjustment(uuid) {
            this.$Swal.confirmDelete().then((result) => {
                if (result.isConfirmed) {
                    this.$Progress.start();
                    this.$notify({
                        type: 'info',
                        message: 'Estamos trabalhando em sua solicitação.'
                    });
                    this.$store.dispatch('adjustmentStabilizingAdditive/destroy', uuid).then(() => this.$Progress.finish())
                }
            }).catch(() => this.$Progress.finish())
        },
        handleDeleteStabilizingDosage(uuid) {
            this.$Swal.confirmDelete().then((result) => {
                if (result.isConfirmed) {
                    this.$Progress.start();
                    this.$notify({
                        type: 'info',
                        message: 'Estamos trabalhando em sua solicitação.'
                    });
                    this.$store.dispatch('adjustmentStabilizingAdditiveDosage/destroy', uuid).then(() => {
                        this.$Progress.finish()
                        this.init()
                    })
                }
            }).catch(() => this.$Progress.finish())
        },
        handleEditStabilizingAdditiveAdjustment(uuid) {
            this.$refs.editStabilizingAdditive.handleEditModal(uuid)
        },
        handleCreateStabilizingAdditiveDosage(uuid) {
            this.$refs.createDosage.handleCreateModal(uuid)
        },
        handleEditStabilizingAdditiveDosage(uuid) {
            this.$refs.editDosage.handleCreateModal(uuid)
        }
    },
    created() {
        this.init({})
    }
}
</script>

<style scoped>

</style>
