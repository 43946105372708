<template>
  <div class="container-fluid">
    <div class="row pr-0">
      <div class="my-2 my-lg-0 col-lg-3">
        <div class="info-view-card success">
          <div>
            <img src="/img/icons/icons8/ios/recycling_success.png" width="30">
          </div>
          <div v-if="loadingWidgets"><SpinnerPuzl/></div>
          <div v-else>
            <h5 class="m-0 font-weight-normal new-default-gray-font mt-2">Reaproveitamento</h5>
            <h3 class="m-0 new-default-black-font">
              {{ floatToHuman(widgets.recycling_volume) }} m<sup>3</sup> ({{ Math.round(widgets.recycling) }})
            </h3>
          </div>
        </div>
      </div>
      <div class="mb-2 mb-lg-0 col-lg-3">
        <div class="info-view-card primary">
          <div>
            <img src="/img/icons/icons8/ios/curved_arrow_primary.png" width="30">
          </div>
          <div v-if="loadingWidgets"><SpinnerPuzl/></div>
          <div v-else>
            <h5 class="m-0 font-weight-normal new-default-gray-font mt-2">Redirecionamento</h5>
            <h3 class="m-0 new-default-black-font">
              {{ floatToHuman(widgets.redirection_volume) }} m<sup>3</sup> ({{ Math.round(widgets.redirection) }})
            </h3>
          </div>
        </div>
      </div>
      <div class="mb-2 mb-lg-0 col-lg-3">
        <div class="info-view-card warning">
          <div>
            <img src="/img/icons/icons8/ios/trash_can_warning.png" width="30">
          </div>
          <div v-if="loadingWidgets"><SpinnerPuzl/></div>
          <div v-else>
            <h5 class="m-0 font-weight-normal new-default-gray-font mt-2">Descarte</h5>
            <h3 class="m-0 new-default-black-font">
              {{ floatToHuman(widgets.disposal_volume) }} m<sup>3</sup> ({{ Math.round(widgets.disposal) }})
            </h3>
          </div>
        </div>
      </div>
      <div class="mb-2 mb-lg-0 col-lg-3">
        <div class="info-view-card danger">
          <div>
            <img src="/img/icons/icons8/ios/trash_can.png" width="30">
            </div>
            <div v-if="loadingWidgets"><SpinnerPuzl/></div>
            <div v-else>
              <h5 class="m-0 font-weight-normal new-default-gray-font mt-2">Perda</h5>
              <h3 class="m-0 new-default-black-font">
                {{ floatToHuman(widgets.lost_volume) }} m<sup>3</sup> ({{ Math.round(widgets.loss) }})
              </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {floatToHuman} from "@/helpers";
import SpinnerPuzl from "@/components/SpinnerPuzl.vue";

export default {
  name: "MiniDashboard",
  props: {
    widgets: Object,
    loadingWidgets: Boolean
  },
  components: {
    SpinnerPuzl
  },
  data() {
    return {
      floatToHuman: floatToHuman,
    }
  }
}

</script>
<style scoped>
.info-view-card {
  padding: 16px 16px 16px 16px;
  box-shadow: 0px 5px 10px 0px #0000000D;
  border-radius: 8px; background: white; gap: 16px;
  min-height: 112px;
}

.info-view-card.success {
  background: #F2F7F3;
}

.info-view-card.primary {
  background: #F2F4F9;
}

.info-view-card.warning {
  background: #FEF9F2;
}

.info-view-card.danger {
  background: #FCF3F3;
}
</style>
