<template>
  <div>
    <div class="container-fluid">
      <card>
        <div class="row">
          <div class="col-md-4 p-2">
            <input-date-picker
              ref="date-picker"
              @handleFilterDate="handleFilterDate"
              :default-range="defaultRange"
            />
          </div>
          <div class="col-md-4 p-2">
            <puzl-select
              class="select-xl"
              v-model="selectedBankAccountId"
              :items="$_bank_accounts"
              placeholder="Conta Bancária (Origem)"
              @input="init"
              :loading="loadingBankAccount"
              :disabled="loadingBankAccount"
            />
          </div>
          <div class="col-md-4 p-2">
            <puzl-select
              class="select-xl"
              v-model="selectedBankAccountTargetId"
              :items="$_bank_accounts"
              placeholder="Conta Bancária (Destino)"
              @input="init"
              :loading="loadingBankAccount"
              :disabled="loadingBankAccount"
            />
          </div>
        </div>
      </card>
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
      </div>

      <PuzlEmptyData
        v-if="!$_bank_transfers.length && !loadingSkeleton"
      ></PuzlEmptyData>

      <div
        class="row card-wrapper"
        v-if="$_bank_transfers.length && !loadingSkeleton"
      >
        <div
          class="col-lg-4"
          v-for="(bankTransfer, index) in $_bank_transfers"
          :key="index"
        >
          <card>
            <!-- Card header -->
            <div class="d-flex card-bank-transfer-header" style="gap: 12px">
              <div class="container-scheduled d-flex align-items-center">
                <img
                  src="/img/icons/calendar-gray.png"
                  width="16px"
                  height="16px"
                /><span
                  class="d-flex align-items-center justify-content-center"
                  style="font-size: 12px"
                >
                  {{ bankTransfer.date | parseDate("DD/MM/YYYY") }}</span
                >
              </div>
              <div class="d-flex" style="gap: 8px">
                <el-popover trigger="click" placement="bottom" class="p-0">
                  <div slot="reference">
                    <img
                      src="/img/icons/icons8/ios/user-male-circle--v1.png"
                      width="20px"
                      height="20px"
                    />
                  </div>
                  <div class="popover-content">
                    <span class="new-default-black-font fs-12 my-2 px-3"
                      >USUÁRIO</span
                    >
                    <hr class="gray-divider" />
                    <span
                      class="font-weight-normal fs-12 new-default-gray-font m-0 px-3"
                      >{{ bankTransfer.created_by_user_name }} -
                      {{
                        bankTransfer.created_at | parseDate("DD/MM/YYYY hh:mm")
                      }}</span
                    >
                  </div>
                </el-popover>
                <el-popover
                  trigger="click"
                  placement="bottom"
                  class="p-0"
                  v-if="bankTransfer.note"
                >
                  <div slot="reference">
                    <img
                      src="/img/icons/icons8/ios/chat-bubble.png"
                      width="20px"
                      height="20px"
                    />
                  </div>
                  <div class="popover-content">
                    <span class="new-default-black-font fs-12 my-2 px-3"
                      >OBSERVAÇÃO</span
                    >
                    <hr class="gray-divider" />
                    <span
                      class="font-weight-normal fs-12 new-default-gray-font m-0 px-3"
                      >{{ bankTransfer.note }}</span
                    >
                  </div>
                </el-popover>
              </div>
            </div>
            <div class="row">
              <div class="card-bank-transfer-body mt-3 pl-1 col-8">
                <div class="d-flex col-md-12" style="gap: 16px">
                  <div class="d-flex align-items-center justify-content-center">
                    <img
                      style="transform: rotate(-90deg)"
                      src="/img/icons/icons8/ios/circled-left-2_danger.png"
                      width="20px"
                      height="20px"
                    />
                  </div>
                  <div class="d-flex flex-column">
                    <h4 class="m-0 bank-name">
                      {{ bankTransfer.bank_account_name }}
                    </h4>
                    <span
                      >AG: {{ bankTransfer.bank_agency }} | CC:
                      {{ bankTransfer.account_number }}</span
                    >
                  </div>
                </div>
                <div class="d-flex mt-3 col-md-12" style="gap: 16px">
                  <div class="d-flex align-items-center justify-content-center">
                    <img
                      src="/img/icons/icons8/ios/circled-up-green.png"
                      width="20px"
                      height="20px"
                    />
                  </div>
                  <div class="d-flex flex-column">
                    <h4 class="m-0">
                      {{ bankTransfer.bank_account_target_name }}
                    </h4>
                    <span
                      >AG: {{ bankTransfer.target_bank_agency }} | CC:
                      {{ bankTransfer.target_account_number }}</span
                    >
                  </div>
                </div>
              </div>
              <div
                class="d-flex align-items-center justify-content-center"
                style="flex: 1"
              >
                <h4 style="font-size: 22px">
                  {{ bankTransfer.amount | currency }}
                </h4>
              </div>
            </div>

            <!-- <div class="row align-items-center mb-3">
                      <div class="col-9">
                        <small style="font-size: 1rem">
                          {{ bankTransfer.bank_account_name }}
                        </small>
                      </div>
                      <div class="col-3 text-right">
                        <img style="width: 60px"
                             :src="'/img/icons/banks/'+bankTransfer.code+ '.png'"/>
                      </div>
                    </div> -->
            <!-- <br class="mt-1 mb-1" /> -->
            <!-- Card body -->

            <!-- <h4 class="mb-0 mt-n2 text-center text-grey2 numeric">
              <span class="font-weight-400" style="font-size: 0.7rem !important"
                >Transferencia enviada para</span
              >
              <br />
              {{ bankTransfer.bank_account_target_name }}
            </h4> -->
            <!-- <h2 class="mb-0 display-5 text-center text-grey2 numeric">
              {{ bankTransfer.amount | currency }}
            </h2> -->
            <!--                    <div class="text-center display-5 text-dark numeric">-->
            <!--                        {{ bankTransfer.date | parseDate("DD/MM/YYYY") }}-->
            <!--                    </div>-->
            <!-- <hr class="mb-2 mt-2" /> -->
            <!-- <h3>
              <small class="numeric">
                <i class="fa-solid fa-note-sticky"></i>
                {{ bankTransfer.note || "Sem observação" }}
                <br class="mt-n2" />
                {{ bankTransfer.date | parseDate("DD/MM/YYYY") }}
              </small>
            </h3> -->
            <div class="text-center">
              <span style="font-size: 12px">
                <!-- {{ bankTransfer.created_by_user_name }} - -->
                <!-- {{ bankTransfer.created_at | parseDate("DD/MM/YYYY hh:mm") }} -->
              </span>
            </div>
          </card>
        </div>
      </div>
      <ModalEdit ref="ModalEdit" />
      <loading-pagination :show="loading && !loadingSkeleton" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import ModalEdit from "./_ModalEdit";
import InputDatePicker from "@/components/InputDatePicker";
import PuzlSelect from "@/components/PuzlSelect";
export default {
  name: "ListbankTransfer",
  mixins: [cursorPaginate],
  components: {
    PuzlEmptyData,
    SkeletonPuzlGrid,
    LoadingPagination,
    ModalEdit,
    InputDatePicker,
    PuzlSelect,
  },
  data() {
    return {
      loadingSkeleton: false,
      loadingBankAccount: true,
      selectedBankAccountId: null,
      selectedBankAccountTargetId: null,
      filter: {},
      range: {
        start: null,
        end: null,
      },
      defaultRange: {
        start: new Date(),
        end: new Date(),
      },
    };
  },
  computed: {
    ...mapGetters({
      $_bank_transfers: "bankTransfer/fetch",
      $_bank_accounts: "bankAccount/fetch",
    }),
  },
  mounted() {
    this.$store.dispatch("bankAccount/fetchItems", {filter:{status:true}}).then(() => {
      this.loadingBankAccount = false;
    });
    this.fetchItemsPaginate();
  },
  methods: {
    fetchItemsPaginate() {
      this.range = this.defaultRange;
      this.init({});
    },
    init(filter = null) {
      this.startCursor(filter);
      this.$Progress.start();
      this.filter.range = this.range;
      this.filter.bank_account_id = this.selectedBankAccountId;
      this.filter.bank_account_target_id = this.selectedBankAccountTargetId;
      this.$store
        .dispatch("bankTransfer/fetchItemsPaginate", {
          filter: this.filter,
          next_page: this.paginate.nextUrl,
        })
        .then((response) => {
          this.$Progress.finish();
          this.resolveCursor(response);
        })
        .catch((error) => {
          this.resolveCursor();
          this.$Progress.finish();
        });
    },
    handleFilterDate(filter) {
      this.range = filter;
      this.init({});
    },
    handleEdit(id) {
      this.$refs.ModalEdit.handleEditModal(id);
    },
    handleDelete(id) {
      this.$Swal
        .confirmDelete()
        .then((result) => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação.",
            });
            this.$store
              .dispatch("bankTransfer/destroy", id)
              .then((response) => {
                this.$notify({
                  type: "success",
                  message: response.message,
                });
              })
              .catch((error) => {
                this.$notify({
                  type: error.data.error_type,
                  message: error.data.message,
                });
              });
            this.$Progress.finish();
          }
        })
        .catch(() => this.$Progress.finish());
    },
  },
};
</script>

<style scoped lang="scss">
.el-popover {
  padding: 0 !important;
}
</style>
<style lang="scss" scoped>
div.container-scheduled {
  border-radius: 16px;
  background: #e8e8e8;
  gap: 8px;
  height: 24px;
  padding: 8px;
}
div.container-scheduled > span {
  text-align: center;
  color: #606062;
  font-family: "Fredoka";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
div.popover-content {
  min-width: 180px;

  h4,
  span,
  label {
    font-family: "Fredoka";
  }

  .gray-divider {
    background-color: #2b2d32;
    opacity: 0.1;
    color: #2b2d32;
    margin: 5px 0;
  }
}
div.card-bank-transfer-body {
  & h4.bank-name {
    font-family: "Fredoka";
    font-size: 16px;
    font-weight: 500;
  }
}
</style>
