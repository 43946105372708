import * as types from './address-construction-type'
import {cursorPaginate, destroy, setUrlWithNextPage, update} from '@/store/baseStore'
import { createAxios } from "@/plugins/axios";

const endPoint = '/commercial/customer-construction/constructions/addresses/'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
    states: [],
    cities: []
  },
  getters: {
    show: state => state.item,
    fetch: state => state.items,
    getCities: state => state.cities,
    getStates: state => state.states
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload
    },
    [types.PUSH](state, payload) {
      state.items.push(payload)
    },
    [types.SHOW](state, payload) {
      state.item = payload
    },
    [types.DESTROY](state, constructionUuid) {
      destroy(state, constructionUuid)
    },
    [types.UPDATE](state, payload) {
      update(state, payload)
    },
    [types.SET_CITIES](state, payload) {
      state.cities = payload
    },
    [types.SET_STATES](state, payload) {
      state.states = payload
    }
  },
  actions: {
    fetchItems({commit, dispatch}, constructionUuid) {
      return createAxios().get(`${endPoint}address-constructions/${constructionUuid}`)
        .then(({data}) => {
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchItemsPaginate({commit, state}, params) {
      return createAxios().get(setUrlWithNextPage(`${endPoint}address-constructions/${params.construction_uuid}`, params),
        {params: params})
        .then(({data}) => {
          cursorPaginate(state, {data: data.data, next_page: params.next_page})
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    update({commit}, payload) {
      return createAxios().put(`${endPoint}${payload.uuid}`, payload)
        .then(({data}) => {
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    show({commit, dispatch}, constructionUuid) {
      return createAxios().get(`${endPoint}${constructionUuid}`)
        .then(({data}) => {
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    add({commit, state}, payload) {
      return createAxios().post(`${endPoint}`, payload)
        .then(({data}) => {
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    destroy({commit, dispatch}, constructionUuid) {
      return createAxios().delete(`${endPoint}${constructionUuid}`)
        .then(({data}) => {
          commit(types.DESTROY, constructionUuid)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    changeDefaultAddress({commit}, payload) {
      return createAxios().put(`${endPoint}default-address`, payload)
        .then(({data}) => {
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getStates({commit}) {
      return createAxios().get('customer-construction/states')
        .then(({data}) => {
          commit(types.SET_STATES, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getCities({commit}) {
      return createAxios().get('customer-construction/cities')
        .then(({data}) => {
          commit(types.SET_CITIES, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
