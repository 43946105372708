<template>
  <div>
    <div class="container-fluid">
      <div class="row card-wrapper" v-if="loading">
        <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <div
        class="row align-items-center"
        v-if="!additionals.length && !loading"
      >
        <div class="col-md-12 text-center">
          <div class="card p-4">
            Sem dados cadastrados!
          </div>
        </div>
      </div>
      <div class="row card-wrapper" v-if="!loading && additionals">
        <div class="col-lg-4" v-for="(item, index) in additionals" :key="index">
          <!-- Basic with action button -->
          <card

            :class="
              item.status ? 'card-border-top-success' : 'card-border-top-danger'
            "
          >
            <!-- Card header -->
            <div class="row mb-2">
              <div class="col-8">
                <!-- Title -->
                <h5 class="h3 mb-0">
                  {{ item.short_description || "-" }}
                </h5>
              </div>
              <div class="col-4 text-right">
                <base-dropdown menuOnRight>
                  <base-button
                    slot="title-container"
                    type="primary"
                    class="dropdown-toggle p-2 rounded m-0"
                  >
                    <i class="fas fa-cog"></i>
                  </base-button>
                  <a
                    class="dropdown-item"
                    @click.prevent="handleOpenModalPlant(item)"
                  >
                    <i class="ni ni-building text-default"></i>
                    Centrais
                  </a>
                  <a
                    class="dropdown-item"
                    @click.prevent="handleEditModal(item.uuid)"
                  >
                    <i class="fas fa-edit text-warning"></i>
                    Editar
                  </a>
                  <!--                  <a-->
                  <!--                    class="dropdown-item"-->
                  <!--                    @click.prevent="handleDeleteAdditional(item.uuid)"-->
                  <!--                  >-->
                  <!--                    <i class="fas fa-times text-danger"></i>-->
                  <!--                    Excluir-->
                  <!--                  </a>-->
                </base-dropdown>
              </div>
              <div class="col-12 mt-2">
                <!-- Title -->
                <h5 class="h3">
                  <small>
                    {{ item.description || "-" }}
                  </small>
                </h5>
              </div>
            </div>
            <!-- Card body -->
            <div class="row">
              <div class="col-md-6 text-left">
                <h5 class="h4 mb-0">Valor Padrão</h5>
              </div>
              <div class="col-md-6 text-center">
                <h5 class="h4 mb-0">
                  {{ item.standard_value || "-" }}
                </h5>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 text-left">
                <h5 class="h4 mb-0">Unid. de Cobrança</h5>
              </div>
              <div class="col-md-6 text-center">
                <h5 class="h4 mb-0">
                  {{ item.billing_unit_description || "-" }}
                </h5>
              </div>
            </div>
            <collapse
              class="border rounded p-0 mt-3"
              v-if="item.services_description.length"
            >
              <collapse-item
                class="header-gray"
                back-ground="border-header-primary-bottom"
              >
                <h5 slot="title" class="mb-0 ">Serviços</h5>
                <div class="row align-items-center pb-0 mb-3">
                  <div class="col-md-12">
                    <div class="row">
                      <ul class="list-group list-group-flush" data-toggle="checklist">
                        <li class="list-group-item px-0"
                            v-for="(itemService,
                        index) in item.services_description"
                            :key="index"
                        >
                          <div class="checklist-item">
                            <div class="checklist-info">
                              <span> {{ itemService }}
                              </span>
                            </div>
                            <div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </collapse-item>
            </collapse>
            <collapse
              class="border rounded p-0 mt-3"
              v-if="item.default || item.editable || item.automatic_payment"
            >
              <collapse-item
                class="header-gray"
                back-ground="border-header-default-bottom"
              >
                <h5 slot="title" class="mb-0 ">Regras</h5>
                <div class="row align-items-center pb-0 mb-3">
                  <div class="col-md-12">
                    <div class="mb-4">
                      <div class="">
                        <span class="border-3 border-left border-primary p-2 mb-3" v-show="item.default">
                          Padrão em Proposta/Contrato
                        </span>
                      </div>
                    </div>
                    <div class="mb-4">
                      <div class="checklist-info">
                        <span class="border-3 border-left border-primary p-2 mb-3" v-show="item.editable">
                          Permite edição de valores
                        </span>
                      </div>
                    </div>
                    <div class="">
                      <div class="">
                        <span
                          class="border-3 border-left border-primary p-2 mb-3"
                          v-show="item.automatic_payment"
                        >
                          Cobrança Automática
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </collapse-item>
            </collapse>

            <collapse class="border rounded p-0 mt-3">
              <collapse-item
                class="header-gray"
                back-ground="border-header-success-bottom"
              >
                <h5 slot="title" class="mb-0 ">Datas</h5>
                <div class="row align-items-center pb-0 mb-3">
                  <div class="col-md-12">
                    <div class="checklist-item checklist-item-primary">
                      <div class="checklist-info">
                        <span v-for="date in item.dates" :key="date">
                          <p class="checklist-title mb-0" v-if="date === 0">
                            Dia útil
                          </p>
                          <p class="checklist-title mb-0" v-if="date === 1">
                            Sábado
                          </p>
                          <p class="checklist-title mb-0" v-if="date === 2">
                            Domingo
                          </p>
                          <p class="checklist-title mb-0" v-if="date === 3">
                            Feriado
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </collapse-item>
            </collapse>

            <collapse class="border rounded p-0 mt-3">
              <collapse-item
                class="header-gray"
                back-ground="border-header-danger-bottom"
              >
                <h5 slot="title" class="mb-0 ">Prazos e Limites</h5>

                <div class="row">
                  <div class="col-md-6 text-left">
                    <h5 class="h4 mb-0">Parâmetro A</h5>
                  </div>
                  <div class="col-md-6 text-center">
                    <h5 class="h4 mb-0">
                      {{
                        (item.params_description &&
                          item.params_description["a"] &&
                          item.params_description["a"].param) ||
                        "-"
                      }}
                    </h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 text-left">
                    <h5 class="h4 mb-0">Valor A</h5>
                  </div>
                  <div class="col-md-6 text-center">
                    <h5 class="h4 mb-0">
                      {{
                        (item.params_description &&
                          item.params_description["a"] &&
                          item.params_description["a"].values) ||
                        "-"
                      }}
                    </h5>
                  </div>
                </div>

                <div class="dropdown-divider mt-2 mb-2 p-0 m-0"></div>

                <div class="row">
                  <div class="col-md-6 text-left">
                    <h5 class="h4 mb-0">Parâmetro B</h5>
                  </div>
                  <div class="col-md-6 text-center">
                    <h5 class="h4 mb-0">
                      {{
                        (item.params_description &&
                          item.params_description["b"] &&
                          item.params_description["b"].param) ||
                        "-"
                      }}
                    </h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 text-left">
                    <h5 class="h4 mb-0">Valor B</h5>
                  </div>
                  <div class="col-md-6 text-center">
                    <h5 class="h4 mb-0">
                      {{
                        (item.params_description &&
                          item.params_description["b"] &&
                          item.params_description["b"].values) ||
                        "-"
                      }}
                    </h5>
                  </div>
                </div>
              </collapse-item>
            </collapse>
          </card>
        </div>
      </div>
    </div>
    <edit-additional @close="close" ref="editAdditional"></edit-additional>
    <modal-plant @close="close" ref="modalPlant"></modal-plant>
  </div>
</template>
<script>
import {mapState, mapGetters, mapActions} from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import EditAdditional from "./Edit";
import ModalPlant from './ModalPlant'

export default {
  name: "ListAdditional",
  components: {
    SkeletonPuzlGrid,
    EditAdditional,
    ModalPlant
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapState({
      additionals: state => state.additional.items
    })
  },
  methods: {
    ...mapActions({
      fetchAdditionals: "additional/fetchAdditionals",
      destroy: "additional/destroy"
    }),
    handleEditModal(uuid) {
      this.$refs.editAdditional.handleEditModal(uuid);
    },
    handleOpenModalPlant(additional) {
      this.$refs.modalPlant.openModal(additional);
    },
    close() {
      this.loading = true;
      this.fetchAdditionals().then(() => (this.loading = false));
    },
    handleDeleteAdditional(uuid) {
      this.$Swal
        .confirmDelete()
        .then(result => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação."
            });
            this.destroy(uuid);
            this.$Progress.finish();
          }
        })
        .catch(() => this.$Progress.finish());
    }
  },
  created() {
    this.loading = true;
    this.fetchAdditionals().then(() => (this.loading = false));
  }
};
</script>

<style>
.list-group-flush > .list-group-item {
  border-width: 0 0 0px !important;
}
</style>
