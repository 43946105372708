export { default as cmcAdjustmentListService } from "./cmcAdjustmentListService";
export { default as cmcAdjustmentShowDosageAdjustmentService } from "./cmcAdjustmentShowDosageAdjustmentService";
export { default as cmcAdjustmentUpdateDosageService } from "./cmcAdjustmentUpdateDosageService";
export { default as cmcAdjustmentUpdateContentService } from "./cmcAdjustmentUpdateContentService";
export { default as cmcAdjustmentDeleteService } from "./cmcAdjustmentDeleteService";
export { default as cmcAdjustmentFindService } from "./cmcAdjustmentFindService";
export { default as cmcAdjustmentCreateHistoryService } from "./cmcAdjustmentCreateHistoryService";
export { default as cmcAdjustmentCreateContentHistoryService } from "./cmcAdjustmentCreateContentHistoryService";

/** Urls para acesso externo */
export const cmcAdjustmentUrls = Object.freeze({
  BASE: "/technology/cmc-adjustments",
});
