<template>
  <div>
    <div class="d-flex align-items-center">
      <img class="mr-1" width="25" height="25" src="/img/icons/info-squared-yellow.png" alt="info-squared" />
      <span style="font-size: 18px; color: #2b2d32; font-weight: 500" class="ml-2 mr-3">IDENTIFICAÇÃO</span>
      <div class="gray-divider mb-2" />
    </div>

    <div class="form-group row mt-4 mb-3 mx-1 p-0 d-flex justify-content-between align-items-center">
      <label> Nome da bandeira </label>
      <div class="col-md-7 pr-0">
        <input type="text" v-model="newItem.name" maxlength="150" placeholder="NOME DA BANDEIRA" class="form-control" rows="3" />
      </div>
    </div>
    <div class="form-group row mt-3 mb-2 mx-1 p-0 d-flex justify-content-between align-items-center">
      <label> Código Externo </label>
      <div class="col-md-7 pr-0">
        <input type="text" v-model="newItem.external_code" maxlength="15" placeholder="CÓDIGO EXTERNO" class="form-control" rows="3" />
      </div>
    </div>

    <!-- Botões de ação -->
    <div v-if="newItem.name" class="justify-content-end d-flex mb-4">
      <base-button size="sm" type="danger" @click="cancel" style="width: 150px">
        <img src="/img/icons/cancel-white.png" width="20px" height="20px" class="mr-1" />
        Cancelar
      </base-button>
      <base-button size="sm" style="width: 150px" type="success" @click="add()">
        <img src="/img/icons/save.png" width="20px" height="20px" class="mr-1" />
        Salvar
      </base-button>
    </div>
  </div>
</template>

<script>
import { formatErrorValidation } from "@/plugins";

export default {
  name: "AddItem",
  data() {
    return {
      newItem: {
        name: null,
        external_code: null,
      },
    };
  },
  methods: {
    cancel() {
      this.newItem = {
        name: null,
        external_code: null,
      };
    },
    add() {
      const loader = this.$loading.show();
      this.$store
        .dispatch("paymentBrand/add", this.newItem)
        .then(() => {
          this.$notify({ type: "success", message: "Bandeira adicionada com sucesso!" });
          this.cancel();
        })
        .catch((error) => {
          let errors = "";
          if (error && error.response && error.response.status === 422) {
            errors = formatErrorValidation(error.response.data.data);
          } else {
            errors = error.response.data.message;
          }
          this.$notify({ type: "danger", message: errors });
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};
</script>

<style scoped>
.gray-divider {
  background-color: #2b2d32;
  opacity: 0.1;
  color: #2b2d32;
  height: 1px;
  width: 100%;
}
</style>
