<template>
  <div>
    <multi-filter-v2 ref="filter" @fetch="init" :filter="{}">
      <div slot="after">
        <div class="row">
          <div class="col-md-6 p-2">
            <base-button @click="type = 1" :class="type === 1 && 'active'" class="text-uppercase" outline block
                         type="warning">
              <i class="ni ni-delivery-fast"></i>Viagens
            </base-button>
          </div>
          <div class="col-md-6 p-2">
            <base-button @click="type = 2" :class="type === 2 && 'active'" class="text-uppercase" outline block
                         type="primary">
              <i class="fas fa-hammer"></i>Serviços
            </base-button>
          </div>
        </div>
      </div>
    </multi-filter-v2>
    <!--    viagens-->
    <div v-if="type === 1 && items.length && !loadingSkeleton" class="row card-wrapper">
      <div class="col-md-4" v-for="(item, index) in items" :key="index">
        <card>
          <div class="">
            <div class="row mb-2">
              <div class="col-7">
                <span class="h4" :class="item.status.class">{{ item.status.text }}</span>
              </div>
              <div class="col-5 text-right">
                <el-popover
                  trigger="hover"
                  placement="bottom"
                  class="p-0"
                >
                  <span> {{`${item.address}, ${item.number}, ${item.district} ${item.city}/${item.state}-${item.country}`}} </span>
                  <base-button outiline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none">
                    <i style="font-size: 25px !important" class="fas fa-map-marked-alt text-primary text-center"/>
                  </base-button>
                </el-popover>
                <el-popover trigger="hover" placement="bottom">
                  <div class="border-left border-3 border-primary p-2">
                    <h5 class="h4 mb-0">
                      {{ item.title }}
                    </h5>
                    <div class="mt-2 mb-3">
                      <h5 class="small">
                        {{ item.body }}
                      </h5>
                    </div>
                  </div>
                  <div
                    class="mt-2 border-left border-3 border-indigo p-2 "
                    v-if="item.puzl_additions.length"
                  >
                    <div v-for="(puzl_addition, index) in item.puzl_additions"
                         :key="puzl_addition.id">
                      <h5 class="text-indigo mb-0">
                        {{ puzl_addition.name }}
                        ({{ puzl_addition.volume }} <span style="text-transform: none">kg/m</span><sup>3</sup>)
                        <span class="text-indigo"
                              v-if="index != item.puzl_additions.length - 1">+</span>
                      </h5>
                    </div>
                  </div>
                  <base-button outiline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none">
                    <i style="font-size: 25px !important" class="fas fa-flask text-success text-center"/>
                  </base-button>
                </el-popover>
                <a>
                  <img width="32px"
                       class="pl-1"
                       style="margin-top: -7px"
                       src="https://seeklogo.com/images/N/new-google-maps-icon-logo-263A01C734-seeklogo.com.png"/>
                </a>
              </div>
            </div>
            <div class="row ">
              <div class="col-md-12">
                <span style="font-size: 0.9rem" class="display-5 mb-1 text-uppercase">
                  <b style="font-size: 0.9rem">
                    {{ item.plant_name }}
                  </b>
                  | {{ item.charge_point_name }}
                </span>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-12">
                <span style="font-size: 0.9rem" class="display-5 mb-1 text-uppercase">
                  <b>
                    {{ item.code }}
                  </b>
                </span>
                <br/>
                <span style="font-size: 0.9rem">
                  {{ item.driver_name }}
                </span>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-12">
                <span style="font-size: 0.9rem" class="display-5 mb-1 text-uppercase">
                  <b>
                    {{ item.construction_name }}
                  </b>
                </span>
                <br/>
                <span style="font-size: 0.9rem">
                  {{ item.customer_name }}
                </span>
              </div>
            </div>
            <div class="row ">
              <div v-if="item.number" class="col-12 p-0 d-flex justify-content-center text-center ">
                <h2 class="text-center mb-0">
                  <img role="button" @click.prevent="downloadProof(item.uuid)" width="50px" src="/img/icons/os.png"/>
                  <span class="font-weight-300 text-default" style="text-transform: none"> nº </span>
                  <b class="text-info money" style="font-size: 1.4rem">{{ item.number }}</b>
                </h2>
              </div>
              <div class="col-12 text-center" style="margin-top: -1.8rem">
                <br/>
                <span class="h2 money">
                  {{ item.volume.toString().replace(".", ",") }} <small
                  style="text-transform: none"><span style="text-transform: none">m</span><sup>3</sup></small>
                </span>
              </div>
            </div>
            <div class="mt-2">
              <base-button
                :type="item.status.status === 7 ? 'primary' : 'success'"
                block
                :disabled="loadingFillCycle || item.status.status === 1"
                size="sm"
                @click.prevent="fillTravelCycle(item, index)"
                class="text-uppercase pl-2 pr-2 pt-2 pb-2">
                {{ item.status.status === 6 && item.construction_exit_time === null ? 'Chegada na Central' : travel_types_fills[item.status.status - 1] }}
              </base-button>
            </div>
            <hr class="mt-4 mb-3"/>
            <div class="row">
              <div class="col-12 text-center ">
                <span class="" style="font-size: 1.1rem;text-transform: none">
                 {{ item.performed_travel_cycle }} / {{ item.predicted_travel_cycle }}
                  <div class="progress" style="height: 1.5rem;color: gray">
                    <div
                      style="font-size: 1rem"
                      role="progressbar"
                      class="progress-bar progress-bar-animated money"
                      :class="item.predicted_minutes > item.performed_minutes ? 'bg-success' : 'bg-danger'"
                      :style="'width:' + item.percentage + '%'"
                    >
                      {{ parseInt(item.percentage) }}%
                    </div>
                  </div>
                </span>
              </div>
            </div>
            <div class="row ">
              <div class="col-4 text-center">
                <a href="#" @click.prevent="handleTravelCycle(item)"> <i class="fa fa-clock fa-2x text-success"></i>
                </a>
              </div>
              <div class="col-4 text-center">
                <a href="#" @click.prevent="handleWaterControl(item.uuid, index)">
                  <i class="fa fa-tint text-info fa-2x"></i>
                  <span v-if="!item.water_control || emptyWaterControl(item.water_control)"
                        style="position: relative;margin-left: -5px"
                        class="badge badge-md badge-circle badge-floating badge-warning border-white">
                    <i class="fas fa-exclamation"/>
                  </span>
                </a>
              </div>
              <div class="col-4 text-center">
                <a href="#" @click.prevent="handleFreshStateTest(item.uuid, index)">
                  <i class="fa fa-flask text-primary fa-2x"></i>
                  <span v-if="item.has_empty_fresh_test" style="position: relative;margin-left: -5px"
                        class="badge badge-md badge-circle badge-floating badge-warning border-white">
                        <i class="fas fa-exclamation"/>
                   </span>
                </a>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <!--    serviços-->
    <div v-if="type === 2 && items.length && !loadingSkeleton" v-show="2 && items.length && !loadingSkeleton"
         class="row card-wrapper">
      <div class="col-lg-4" v-for="(item, index) in items" :key="index">
        <card>
          <div class="">
            <div class="row">
              <div class="col-9">
                <span class="h3 text-primary">{{ item.service_name }}</span>
              </div>
              <div class="col-3">
                <a>
                  <img width="50px"
                       src="https://seeklogo.com/images/N/new-google-maps-icon-logo-263A01C734-seeklogo.com.png"/>
                </a>
              </div>
            </div>
            <div class="row ">
              <div class="col-md-12">
                <span class="display-5 mb-1 text-uppercase">
                  <b>
                    {{ item.company_plant_name }}
                  </b>
                </span>
              </div>
            </div>
            <div v-if="item.equipment_code || item.employers.length" class="row mt-2">
              <div class="col-md-12">
                <span v-if="item.equipment_code" class="display-5 mb-1 text-uppercase">
                  <b>
                    {{ item.equipment_code }}
                  </b>
                </span>
                <br/>
                <span v-for="(employer, index) in item.employers" :key="'A' + index">
                  {{ employer.name }}
                  <br/>
                </span>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-12">
                <span class="display-5 mb-1 text-uppercase">
                  <b>
                    {{ item.construction_name }}
                  </b>
                </span>
                <br/>
                <span>
                  {{ item.customer_name }}
                </span>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12 d-flex justify-content-center">
              <h2 class="text-center mb-2">
                <img role="button" @click.prevent="getReport(item.order_uuid)" width="50px" src="/img/icons/os.png"/>
                <span class="font-weight-300 text-default" style="text-transform: none"> nº </span>
                <b class="text-info money" style="font-size: 1.4rem"> {{ item.code }} </b>
              </h2>
              </div>
              <div class="mt-0 col-12 pt-0 mb-1 pb-3">
                <base-button
                  :type="item.arrival_time ? 'primary' : 'success'"
                  block
                  :disabled="loadingFillCycle"
                  size="sm"
                  @click.prevent="fillServiceCycle(item)"
                  class="text-uppercase pl-2 pr-2 pt-2 pb-2">
                  {{ item.arrival_time ? 'Concluir Serviço' : 'Iniciar Serviço' }}
                </base-button>
              </div>
              <div class="w-100 text-center">
                <h3 class="mb-0 text-center"> <span class="text-success"> {{ item.performed_volume }} m³</span> / {{ item.total_volume }} m³ </h3>
              </div>
              <div class="col-12">
                <div class="progress ml-4 mr-4 mb-4 mt-1" style="height: 1.5rem;color: gray">
                  <div
                    role="progressbar"
                    class="progress-bar bg-success  progress-bar-animated"
                    :style="'width:' + item.volume_percentage + '%'"
                  >
                    {{ parseInt(item.volume_percentage) }}%
                  </div>
                </div>
              </div>
            </div>
            <hr class="mt-0 mb-3"/>
            <div class="row">
              <div class="col-12 text-center ">
                <span class="" style="font-size: 1.2rem">
                  {{ item.perfomed_time }} / {{ item.predicted_time }}
                  <div class="progress" style="height: 1.5rem;color: gray">
                    <div
                      role="progressbar"
                      class="progress-bar progress-bar-animated"
                      :class="item.perfomed_minutes < item.predicted_minutes ? 'bg-primary' :'bg-danger'"
                      :style="'width:' + item.time_percentage + '%'"
                    >
                      {{ item.time_percentage }}%
                    </div>
                  </div>
                </span>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-4 text-right">
                <a role="button" @click.prevent="handleServiceCycle(item)"> <i
                  class="text-success fa fa-clock fa-2x m-0"></i> </a>
              </div>
              <div class="col-4 text-center">
                <a role="button" @click.prevent="handleEditService(item)"> <i
                  class="text-warning fa-2x fas fa-edit"></i> </a>
              </div>
              <div class="col-4 text-left">
                <a role="button" @click.prevent="cancelServiceOrder(item.uuid)"> <i
                  class="text-danger fa-2x fas fa-ban"></i> </a>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <div class="row card-wrapper" v-if="loadingSkeleton">
      <SkeletonPuzlGrid v-for="index in 3" v-show="true" :key="index"/>
    </div>
    <PuzlEmptyData v-if="!loadingSkeleton && !items.length"/>
    <loading-pagination :show="loading && !loadingSkeleton"/>
    <ModalWaterControl @setWaterControl="init" ref="waterControl"/>
    <ModalFreshStateTest @updatedFreshStateTest="init" ref="freshStateTest"/>
    <ModalTravelCycle @updatedCycle="init(1)" ref="travelCycle"/>
    <ModalServiceCycle @updatedCycle="init(2)" ref="serviceCycle"/>
    <ModalServiceCancellation @updatedService="init(2)" ref="serviceCancellation"/>
    <ModalEditService @updatedService="init(2)" ref="editService"/>
  </div>
</template>

<script>
import MultiFilterV2 from "@/components/Utils/MultiFilterV2";
import {mapGetters} from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import LoadingPagination from "@/components/LoadingPagination";
import cursorPaginate from "@/mixins/cursorPaginate";
import ModalWaterControl from "../../Schedule/Weighing/Shared/_ModalWaterControl";
import ModalFreshStateTest from "../../Schedule/Weighing/Shared/_ModalFreshStateTest";
import ModalTravelCycle from "@/views/Modules/Operational/Schedule/Weighing/Shared/ScheduleTravelCycle/_ModalNewTravelCycle";
import ModalServiceCycle from "../../Schedule/Service/_ModalServiceCycle";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import ModalServiceCancellation from "../../Schedule/Service/_ModalServiceCancellation";
import ModalEditService from "../../Schedule/Service/_Edit.vue";
import moment from "moment"

export default {
  name: "List",
  mixins: [cursorPaginate],
  components: {
    PuzlEmptyData,
    MultiFilterV2,
    LoadingPagination,
    ModalWaterControl,
    ModalFreshStateTest,
    ModalTravelCycle,
    SkeletonPuzlGrid,
    ModalServiceCycle,
    ModalServiceCancellation,
    ModalEditService
  },
  data() {
    return {
      type: 1,
      loadingSkeleton: true,
      filter: {},
      loadingFillCycle: false,
      travel_types: [
        "Carregando",
        "Dosando",
        "A caminho",
        "Esperando",
        "Descarregando",
        "Retornando",
        "Lastro",
        "Concluir",
        "Saindo da Obra"
      ],
      travel_types_fills: [
        "Carregando",
        "Saída da Central",
        "Chegada na Obra",
        "Início de Descarga",
        "Término de Descarga",
        "Chegada na Central",
        "Concluir Viagem",
        "Saída da Obra",
      ],
      travel_types_colors: [
        "text-indigo",
        "text-info",
        "text-warning",
        "text-danger",
        "text-success",
        "text-default",
        "text-pink",
      ]
    };
  },
  computed: {
    ...mapGetters({
      items: "inCourse/fetch"
    })
  },
  watch: {
    type() {
      this.loadingSkeleton = true;
      this.paginate.nextUrl = null;
      this.$refs.filter.clearFilters();
    }
  },
  methods: {
    init(filter = null) {
      this.startCursor(filter);
      this.$store
        .dispatch("inCourse/fetchItems", {
          type: this.type,
          filter: this.filter,
          next_page: this.paginate.nextUrl
        })
        .then(response => {
          this.resolveCursor(response);
        })
        .catch(error => {
          this.resolveCursor();
        });
    },
    handleFreshStateTest(uuid, index) {
      this.$refs.freshStateTest.openModal(uuid);
    },
    handleTravelCycle(travel) {
      this.$refs.travelCycle.openModal(travel.uuid, travel.address_construction_uuid);
    },
    handleEditService(service) {
      this.$Progress.start();
      this.$store.dispatch("schedule/show", service.schedule_uuid).then(response => {
        this.$refs.editService.handleCreate(service, response.data.company_plants);
      });
    },
    emptyWaterControl(water) {
      return !water.company_plant_liters || !water.construction_liters;
    },
    handleServiceCycle(service) {
      let loader = this.$loading.show();
      this.$store.dispatch("schedule/show", service.schedule_uuid).then(response => {
        let schedule = response.data;
        loader.hide();
        this.$refs.serviceCycle.handleCreate(schedule, service);
      });
    },
    cancelServiceOrder(service_uuid) {
      this.$swal
        .fire({
          title: "Confirmar cancelamento da O.S?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill"
          },
          buttonsStyling: false
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$refs.serviceCancellation.handleCreate(service_uuid);
          }
        });
    },
    getReport(service_order_uuid) {
      let loader = this.$loading.show();
      this.$store
        .dispatch("scheduleService/generateReport", service_order_uuid)
        .then(response => {
          let blob = new Blob([response], {
            type: "application/pdf"
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          window.open(link, "_blank");
          loader.hide();
        })
        .catch(error => {
          loader.hide();
        });
    },
    handleWaterControl(uuid, index) {
      let params = {
        uuid: uuid,
        index: index,
        water_control: this.items.length && this.items[index].water_control,
        retained_water: this.items[index].retained_water
      };
      this.$refs.waterControl.openModal(params);
    },
    async fillTravelCycle(item, index) {
      const status = item.status.status
      let travel_cycle = {};
      const now = `${moment().format('YYYY-MM-DD HH:mm')}:00`
      switch (true) {
        case status === 2 :
          travel_cycle.plant_exit_time = now;
          if (this.emptyWaterControl) {
            this.handleWaterControl(item.uuid, index)
          }
          break;
        case status === 3 :
          travel_cycle.arrival_time = now;
          break;
        case status === 4 :
          /* Abrir caso tenha estado endurecido */
          if (item.hardened_state) {
            this.handleFreshStateTest(item.uuid)
          }
          travel_cycle.start_discharge_time = now;
          break;
        case status === 5:
          /* Abrir caso campo obra do controle água não esteja preenchido.*/
          if (!item.water_control.construction_liters) {
            this.handleWaterControl(item.uuid, index)
          }
          travel_cycle.end_discharge_time = now;
          break;
        case status === 6 :
          travel_cycle.plant_arrival_time = now;
          break;
        case status === 7 && item.cycle_free_for_travel === null :
          travel_cycle.free_for_travel = now;
          break;
        case status === 7 && item.cycle_free_for_travel !== null :
          this.$refs.travelCycle.openModal(item.uuid, item.address_construction_uuid);
          return;
        case status === 8 && item.construction_exit_time === null :
          travel_cycle.construction_exit_time = now;
          item.construction_exit_time = now;
          break;
      }
      this.loadingFillCycle = true;
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch("travels/fillCycle", {id: item.id, travel_cycle: travel_cycle})
        .then(response => {
          switch(true) {
            /* Caso tenha finalizado todos os horários */
            case status === 7 && !item.cycle_free_for_travel:
              item.cycle_free_for_travel = now;
              this.$refs.travelCycle.openModal(item.uuid, item.address_construction_uuid);
              break;
            /* Descarregando */
            case status === 5:
              item.status.status += 3;
              item.status.text = this.travel_types[item.status.status]
              item.status.class = this.travel_types_colors[item.status.status -1];
              break;
            /* Caso seja diferente de saíndo da obra*/
            case status !== 8:
              item.status.status += 1;
              item.status.text = this.travel_types[item.status.status - 1]
              item.status.class = this.travel_types_colors[item.status.status - 1];
              break;
            /* Se for saíndo da obra */
            default :
              item.status.status -= 2;
              item.status.text = this.travel_types[item.status.status - 1]
              item.status.class = this.travel_types_colors[item.status.status - 1];
          }
          this.$notify({type: response.error_type, message: response.message});
          this.loadingFillCycle = false;
        });
    },
    fillServiceCycle(item) {
      let service_cycle = {};
      const now = `${moment().format('YYYY-MM-DD HH:mm')}:00`

      switch (true) {
        case item.arrival_time === null :
          service_cycle.arrival_time = now;
          item.arrival_time = now;
          break;
        case item.construction_exit_time === null :
          service_cycle.construction_exit_time = now;
          item.construction_exit_time = now;
          break;
        default :
          this.handleServiceCycle(item)
          return;
      }

      this.loadingFillCycle = true;
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch("scheduleService/fillCycle", {id: item.id, service_cycle: service_cycle})
        .then(response => {
          this.$notify({type: response.error_type, message: response.message});
          if (item.construction_exit_time !== null) {
            this.handleServiceCycle(item);
          }
          this.loadingFillCycle = false;
        });
    },
    downloadProof(uuid) {
      let params = {
        uuid: uuid,
        type: "proof"
      };
      let loader = this.$loading.show();
      this.$store
        .dispatch("travels/download", params)
        .then(response => {
          let blob = new Blob([response], {type: "application/pdf"});
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "custom_name.pdf"); // Added Line
          window.open(link, "_blank");
          loader.hide();
        })
        .catch(error => {
          loader.hide();
        });
    },
  },
  mounted() {
    this.init();
  }
};
</script>

<style scoped></style>
