<template>
  <v-select
    :multiple="multiple"
    :clearable="clearable"
    :reduce="(item) => item[customKey]"
    :loading="loading"
    :value="loading && multiple ? [] : loading ? null : value"
    @input="handleInput"
    @search="fetchOptions"
    :placeholder="placeholder"
    :options="items"
  >
    <span slot="no-options">Não há dados</span>
  </v-select>
</template>
<script>
export default {
  name: 'PuzlSelectSearch',
  data() {
    return {
      enabled: true,
    }
  },
  methods: {
    /**
     * Ao pesquisar dentro do vue-select, irá chamar este método
     * @param search
     * @param loading
     */
    fetchOptions (search, loading) {
      this.emit('search', search);
    },
    emit(event, e) {
      return new Promise((resolve, reject) => {
        this.$emit(event, e)
        this.$nextTick(resolve)
      })
    },
    async handleInput(e) {
      await this.emit('input', e)
      this.$emit("change", e);
    },
  },
  props: {
    value: {
      type: [Array, String, Number],
      default: null,
      description: "Dados selecionados",
    },
    customKey: {
      type: String,
      default: "id",
      description: "Chave primária",
    },
    clearable: {
      type: Boolean,
      default: true,
      description: "Limpar select após seleção",
    },
    placeholder: {
      type: String,
      default: 'Selecione',
    },
    loading: {
      type: Boolean,
      default: false,
      description: "Loading",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Permitir múltiplos dados",
    },
    items: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
      description: "Dados a selecionar",
    },
  },
};
</script>
