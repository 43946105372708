<template>
  <div>
    <CreateCharging />
  </div>
</template>

<script>
import CreateCharging from './Shared/_Create'
export default {
  name: 'IndexCharging',
  components: {
    CreateCharging
  },
  data() {
    return {}
  },
  methods: {
  }
}
</script>

<style></style>
