import {
  CmcAdjustmentListFilterType,
  CmcAdjustmentListPagerType,
  CmcAdjustmentListType,
  DosageAdjustmentByIdType,
  CmcAdjustmentUpdateDosagesType,
  CmcAdjustmentUpdateContentsType,
  CmcAdjustmentViewType
} from "../types";
import {
  cmcAdjustmentListService,
  cmcAdjustmentShowDosageAdjustmentService,
  cmcAdjustmentUpdateDosageService,
  cmcAdjustmentUpdateContentService,
  cmcAdjustmentDeleteService,
  cmcAdjustmentFindService
} from "../services";
import mutations from "./cmcAdjustmentStoreMutations";
import getters from "./cmcAdjustmentStoreGetters";
import { listItems } from "../../../../helpers";
import {
  listResources,
  findResource,
  updateResource,
  removeResource,
} from "../../../../shared/store";

/**
 * Listar
 *
 * @param {CmcAdjustmentListFilterType} filter - Filtro de listagem
 * @param {boolean} isAccumulateItems - Incluir mais itens na lista existente
 * @returns {Promise<?CmcAdjustmentListPagerType>} - Retorno paginado
 */
const list = async (filter, isAccumulateItems = false) => {
  return await listResources(
    filter,
    isAccumulateItems,
    mutations,
    getters,
    cmcAdjustmentListService
  );
};

/**
 * Localizar por ID
 *
 * @param {number|string} id
 * @returns {Promise<?CmcAdjustmentViewType>}
 */
const find = async (id) => await findResource(id, mutations, cmcAdjustmentFindService);

/**
 * Atualizar dosagens por ID
 *
 * @param {CmcAdjustmentUpdateDosagesType} data
 * @param {number} id
 * @returns {Promise<?DosageAdjustmentByIdType>}
 */
const updateDosages = async (data, id) => await updateResource(data, id, mutations, cmcAdjustmentUpdateDosageService);

/**
 * Atualizar teores por ID
 *
 * @param {CmcAdjustmentUpdateContentsType} data
 * @param {number} id
 * @returns {Promise<?CmcAdjustmentViewType>}
 */
const updateContents = async (data, id) => await updateResource(data, id, mutations, cmcAdjustmentUpdateContentService);

/**
 * Localizar por ID
 *
 * @param {number|string} id
 * @returns {Promise<?DosageAdjustmentByIdType>}
 */
const showDosageAdjustment = async (id) => await findResource(id, mutations, cmcAdjustmentShowDosageAdjustmentService);

/**
 * Deletar por ID
 *
 * @param {number} id
 * @returns {Promise<void>}
 */
const remove = async (id) => await removeResource(id, mutations, cmcAdjustmentDeleteService);

export default {
  list,
  updateDosages,
  updateContents,
  showDosageAdjustment,
  remove,
  find
};
