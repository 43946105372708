import * as types from './mutation_types'
import { createAxios } from "@/plugins/axios";
import * as storage from '@/plugins/storage'
import * as session from '@/plugins/session'
import router from '@/routes'
import store from '@/store'

const state = {
  user: storage.getPuzlUser(),
  access_token: storage.getPuzlToken() || session.getPuzlToken(),
}
const getters = {
  getUser: state => state.user,
  getToken: state => state.access_token,
}
const mutations =  {
  [types.SET_USER] (state, payload){
    state.user = payload
    storage.setPuzlUser(payload)
  },
  [types.SET_TOKEN] (state, payload){
    state.access_token = payload
    storage.setPuzlToken(payload)
    session.setPuzlToken(payload)
  },
}
//actions
const actions = {
  login({commit}, payload){
    return createAxios().post('/auth/login', payload)
      .then(({data}) =>{
        commit(types.SET_USER, data.user)
        commit(types.SET_TOKEN, data.access_token)
        return Promise.resolve(data)
      }).catch(error =>{
        return Promise.reject(error)
      })
  },
  forceRefresh({commit}) {
    return createAxios().post('/auth/refresh-token')
      .then(response => {
        commit('refresh', response.data)
        return Promise.resolve(response.data)
      })
      .catch(error => {
        store.dispatch("auth/signOut");
        router.push({name: 'Login'})
        return Promise.reject(error)
      })
  },
  signOut({ commit }){
    storage.clearStorage()
    session.clearStorage()
    commit(types.SET_USER,'')
    commit(types.SET_TOKEN,'')
  },
  checkToken({ commit, dispatch, state }){
    if (state.access_token) {
      return Promise.resolve(state.access_token)
    }
    const token = storage.getPuzlToken()
    if (!token) {
      return Promise.reject(new Error('Token Inválido'))
    }
    commit(types.SET_TOKEN, token)
    return dispatch('loadSession')
  },
  loadSession({ commit, dispatch }){
    return createAxios().post('/auth/me')
      .then(({data}) =>{
        commit(types.SET_USER, data.data.user)
        return Promise.resolve(data)
      }).catch(error =>{
        dispatch('signOut')
        return Promise.reject(error)
      })
  },
  me({ commit, dispatch }){
    return createAxios().post('/auth/me+microservice')
      .then(({data}) =>{
        return Promise.resolve(data)
      }).catch(error =>{
        dispatch('signOut')
        return Promise.reject(error)
      })
  },
  forgotPassword({ commit, dispatch }, payload){
    return createAxios().post('/auth/forgot-password', {email: payload.email})
      .then(({data}) =>{
        return Promise.resolve(data)
      }).catch(error =>{
        return Promise.reject(error)
      })
  },
  resetPassword({ commit, dispatch }, payload){
    return createAxios().post('auth/reset-password', {email: payload.email, password: payload.password, token: payload.token})
      .then(({data}) =>{
        return Promise.resolve(data)
      }).catch(error =>{
        return Promise.reject(error)
      })
  },
  updateProfile({ commit, dispatch }, payload){
    return createAxios().put('/configuration/update-profile', payload)
      .then(({data}) =>{
        commit(types.SET_USER, data.data)
        return Promise.resolve(data)
      }).catch(error =>{
        return Promise.reject(error)
      })
  },
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
