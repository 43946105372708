import { DATE_ENUM } from "../Enums";
import DateMoment from "../Adapter/DateMoment";
import DateDay from "../Adapter/DateDay";
import DateBase from "../Base/DateBase";

/**
 * Classe responsável por criar instâncias de adaptadores de data.
 */
class DateFactory {
  /**
   * Cria uma instância de adaptador de data com base na biblioteca selecionada.
   *
   * @param {Date|string|number|null} date - A data inicial a ser utilizada pelo adaptador. Se não for informada, será gerada a data atual.
   * @param {number} adapter - O adaptador a ser utilizado.
   * @returns {DateBase} A instância do adaptador selecionado.
   */
  static make(date = null, adapter = DATE_ENUM.MOMENT) {
    const param = date ?? new Date();
    switch (adapter) {
      case DATE_ENUM.MOMENT: return new DateMoment(param);
      case DATE_ENUM.DAY: return new DateDay(param);
      default: throw new Error("Unsupported library");
    }
  }
}

export default DateFactory;
