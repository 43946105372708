<template>
  <div>
    <modal size="lg" :show.sync="modal.create" :backgroundColor="'#F2F7F3'" :showClose="false">
      <template>
        <template slot="header">
          <div class="d-flex align-items-center container-fluid" style="gap: 15px">
            <div>
              <img src="/img/icons/icons8/ios/refresh--v1_success.png" width="24px" />
            </div>
            <span class="text-title">
              {{ modal.title }}
            </span>
            <div class="ml-auto">
              <img class="pointer" @click="closeModal" src="/img/icons/icons8/ios/close-window.png" alt="close-window" width="17px" />
            </div>
          </div>
        </template>
        <div>
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div class="container-fluid">
              <div class="form-group row m-0 p-0 mb-2">
                <div class="col-md-5 my-1 pr-4">
                  <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400">ATUAL</div>
                </div>
                <div class="col-md-7 mb-2 pr-4">
                  <validation-provider rules="required">
                    <base-input input-classes="form-control-sm">
                      <PuzlSelect
                        v-model="payload.from_id"
                        :items="$_chart_accounts"
                        :disableBoxShadow="true"
                        :clearable="false"
                        disabled
                        label="$code$ $name$"
                        :labelMask="true"
                        :selectable="(i) => i.extra_field.length"
                        extraField="parents"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                <div class="col-md-5 my-1 pr-4">
                  <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400">NOVO</div>
                </div>
                <div class="col-md-7 mb-2 pr-4">
                  <validation-provider rules="required">
                    <base-input input-classes="form-control-sm">
                      <PuzlSelect
                        v-model="payload.to_id"
                        :items="$_chart_accounts"
                        :disableBoxShadow="true"
                        :clearable="false"
                        label="$code$ $name$"
                        :labelMask="true"
                        :selectable="(i) => i.extra_field.length"
                        extraField="parents"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="modal-footer">
                <div class="d-flex pr-3" style="gap: 19px">
                  <div class="d-flex align-items-center button-cancel" style="gap: 5px" @click="closeModal">
                    <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="16px" />
                    <span style="font-weight: 500"> Cancelar </span>
                  </div>
                  <div class="d-flex align-items-center button-save" style="gap: 5px" @click.prevent="store">
                    <img src="/img/icons/icons8/ios/save_success.png" alt="save_success" width="16px" />
                    <span style="font-weight: 500"> Salvar </span>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import PuzlSelect from "@/components/PuzlSelect";
import { mapGetters } from "vuex";

export default {
  name: "ModalCreateAccount",
  components: {
    PuzlSelect,
  },
  computed: {
    ...mapGetters({
      $_chart_accounts: "chartAccount/fetch",
    }),
  },
  data() {
    return {
      modal: {
        title: "Redirecionar despesas",
        create: false,
      },
      payload: {
        from_id: null,
        to_id: null,
      },
    };
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    async openModal(from_id) {
      const loader = this.$loading.show();
      this.payload = {
        from_id: from_id,
        to_id: null,
      };
      await this.$store.dispatch("chartAccount/fetchItems");
      this.$_chart_accounts.sort((a, b) => parseInt(a.code.substring(0, 1)) - parseInt(b.code.substring(0, 1)));
      this.modal.create = true;
      loader.hide();
    },

    store() {
      const loader = this.$loading.show();
      this.$store
        .dispatch("entryLaunchItem/changeChartAccount", this.payload)
        .then((response) => {
          this.$notify({
            type: "success",
            message: response.message,
          });
          this.closeModal();
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};
</script>

<style scoped>
.text-title {
  font-size: 18px;
  font-weight: 500;
  color: #2b2d32;
}
.button-dre-success {
  background-color: #f2f7f3;
  border-radius: 8px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #149e57;
  font-weight: 400;
}
.button-dre-danger {
  background-color: #f4c7c3;
  border-radius: 8px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #db4539;
  font-weight: 400;
}
.account-ordered-code {
  padding: 3px 10px;
  background-color: #e8e8e8;
  border: 0.5px solid #cfcfcf;
  border-radius: 4px;
  color: #606062;
}
.button-cancel {
  cursor: pointer;
  border: 0.5px solid #db4539;
  border-radius: 8px;
  color: #db4539;
  padding: 4px 20px;
}
.button-save {
  cursor: pointer;
  border: 0.5px solid #149e57;
  border-radius: 8px;
  color: #149e57;
  padding: 4px 30px;
}
</style>
