import * as types from './mutation_types'
import {destroy} from '@/store/baseStore'
import { createAxios } from "@/plugins/axios";
const endPoint = '/configuration/card/'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
  },
  getters:{
    fetch: state => state.items,
  },
  mutations: {
    [types.SET] (state, payload) {
      state.items = payload
    },
    [types.PUSH] (state, payload) {
      state.items.push(payload)
    },
    [types.DESTROY] (state, id) {
      destroy(state,id)
    },
  },
  actions: {
    fetchItems({commit}, params) {
      return createAxios().get(endPoint, { params: params })
        .then(({data}) => {
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    add({commit, state}, payload) {
      return createAxios().post(endPoint, payload)
        .then(({data}) => {
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
      })
    },
    destroy({commit, dispatch}, id) {
      return createAxios().delete(endPoint+id)
        .then(({data}) => {
          commit(types.DESTROY, id)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
