<template>
  <div class="container-fluid px-0 mt-5">
    <div class="row px-3">
      <div class="col-12 card-table">
        <div class="container-fluid" style="padding-left: 0 !important;padding-right: 0 !important">
          <div class="row align-items-center">
            <div class="col-auto">
              <img src="/img/icons/icons8/ios/cancel_danger.png" width="30">
            </div>
            <div class="col">
              <hr>
            </div>
            <div class="col-auto">
              <img
                src="/img/icons/excel.png"
                width="30"
                class="pointer"
                @click="$emit('getReportCanceledsExcel')"
              >
            </div>
          </div>
        </div>
        <div class="container-fluid mt-1" style="padding-left: 0 !important;padding-right: 0 !important">
          <div class="row">
            <ShadedTable
              :length="items.length"
              :headers="headers"
              :headersAligns="headersAligns"
              :loading="false">
              <template v-for="(item, itemIndex) in items" :slot="itemIndex">
                <ShadedCol align="center" style="width: 20px;">
                  <h4
                    class="new-default-black-font font-weight-normal m-0 mt-1 text-right">
                    {{ item.date_at | parseDate('DD MMM YYYY') }}
                  </h4>
                  <el-popover trigger="click" placement="top">
                    <span style="width: 200px;"
                      class="row">
                      <div class="col-12 mb-1" style="display: flex; align-items: center;">
                        <img class="mr-1" src="/img/icons/icons8/ios/user-male-circle--v1.png" width="22">
                        <h4 class="p-0 m-0">Usuário</h4>
                      </div>
                      <div class="col-12">
                        <h5 class="font-weight-normal" style="word-break: break-word;">
                          {{ item.user }}
                        </h5>
                      </div>
                    </span>
                    <base-button
                      outline slot="reference"
                      size="sm" type="secundary"
                      class="shadow-none text-indigo">
                      <img
                        class="pointer mt-1 ml-1"
                        src="/img/icons/icons8/ios/user-male-circle--v1.png"
                        width="22"
                      />
                    </base-button>
                  </el-popover>
                </ShadedCol>
                <ShadedCol>
                  <h4 class="new-default-black-font font-weight-normal m-0 mt-1">
                    {{ item.company_plant }}
                  </h4>
                </ShadedCol>
                <ShadedCol align="center">
                  <h4
                    class="new-default-black-font font-weight-normal m-0 mt-1 text-right">
                    {{ item.number }}
                  </h4>
                  <el-popover
                    trigger="click"
                    placement="bottom"
                  >
                    <span style="width: 250px;"
                      class="row">
                      <div class="col-12 mb-1" style="display: flex; align-items: center;">
                        <img class="mr-2" src="/img/icons/icons8/ios/info-circle_warning.png" width="22">
                        <h4 class="p-0 m-0">Informações</h4>
                      </div>
                      <div class="col-12">
                        <div class="text-center" v-if="!item.info">
                          <img
                            width="50"
                            src="/img/icons/loading_line.gif"
                          />
                        </div>
                        <h5
                          v-else
                          class="font-weight-normal"
                          style="white-space: pre-line"
                        >
                          {{ item.info }}
                        </h5>
                      </div>
                    </span>
                    <base-button
                      outline slot="reference"
                      size="sm" type="secundary"
                      class="shadow-none text-indigo"
                      @click.prevent="$emit('getInfo', item)"
                    >
                      <img
                        class="pointer mt-1 ml-1"
                        src="/img/icons/icons8/ios/os.png"
                        width="22"
                      />
                    </base-button>
                  </el-popover>
                </ShadedCol>
                <ShadedCol align="center">
                  <h4 class="new-default-black-font font-weight-normal m-0 mt-1">
                    {{ item.volume }}
                  </h4>
                </ShadedCol>
                <ShadedCol align="left">
                  <h4
                    class="new-default-black-font font-weight-normal m-0 mt-1">
                    {{ item.reason_for_cancellation }}
                  </h4>
                </ShadedCol>
              </template>
            </ShadedTable>
          </div>
        </div>
        <div class="container-fluid mt-2" style="padding-left: 0 !important; padding-right: 0 !important;">
          <pagination
            @navegate="navegateCanceleds"
            :source="$_canceled_items"
            :loading="loadingSkeleton"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ShadedTable from "@/components/ShadedTable.vue";
import ShadedCol from "@/components/ShadedCol.vue";
import Pagination from '@/components/Utils/Pagination';
import {mapGetters} from "vuex";

export default {
  name: "Table",
  props: {
    loadingSkeleton: Boolean,
    items: Array,
  },
  components: {
    ShadedTable,
    ShadedCol,
    Pagination
  },
  computed: {
    ...mapGetters({
      $_canceled_items: 'reuseControl/fetchCanceleds',
    }),
  },
  data() {
    return {
      headers: ["Data", "Central", "O.S", "Volume", "Motivo"],
      headersAligns: ["left", "left", "center", "center", "left"]
    }
  },
  methods: {
    navegateCanceleds(page){
      this.$emit('navegateCanceleds', page);
    }
  }
}

</script>
<style scoped>
.card-table {
  background: #FFFFFF;
  border-radius: 8px;
  gap: 40px;
  padding: 24px;
}
</style>
