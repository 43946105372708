<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid }">
          <div>
            <!-- Local de Estoque -->
            <div class="form-group row m-0 p-0">
              <label class="col-md-5 pb-0 pt-2 mb-0 col-form-label form-control-label pr-0">
                Local de Estoque
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7 pr-4">
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm">
                    <puzl-select placeholder="Local de Estoque" v-model="stock_location_id" :items="items" :clearable="false" />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()"> Cancelar </base-button>
              <base-button type="success" @click="store()" v-bind:disabled="invalid"> Salvar </base-button>
            </div>
          </div>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import PuzlSelect from "@/components/PuzlSelect";
export default {
  name: "ModalSetStockLocation",
  components: { PuzlSelect },
  props: {
    items: {
      type: Array,
    },
  },
  data() {
    return {
      modal: {
        title: "Local de Estoque",
        create: false,
      },
      stock_location_id: null,
    };
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal() {
      this.modal.create = true;
    },
    store() {
      this.$emit("store", this.stock_location_id);
      this.closeModal();
    },
  },
};
</script>
