<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title mb-0">{{ modal.title }}</h5>
      </template>
      <div class="px-3">
        <validation-observer v-slot="{ invalid }" ref="formValidator">
          <form class="needs-validation" autocomplete="off">
            <!-- Viagem -->
            <div class="row">
              <div class="col-4">
                <span class="new-default-black-font font-weight-500 d-flex align-items-center fs-16">
                  <img class="mr-3 mt" src="/img/icons/icons8/ios/info-squared.png" width="21" />
                  Viagem
                </span>
              </div>
              <div style="display: grid" class="col-8 align-items-center">
                <hr style="border-top: 1.5px solid rgba(0, 0, 0, 0.1)" class="m-0" />
              </div>
            </div>

            <!-- Volume -->
            <div class="row mt-3">
              <div class="col-6 d-flex align-items-center">
                <span class="new-default-black-font fs-13"> Volume </span>
              </div>
              <div style="display: grid" class="col-6 pl-0">
                <validation-provider rules="required">
                  <InputLimit
                    :disabled="
                      travel.has_mcc ||
                      ['finished', 'in_progress', 'canceled', 'unrealized'].includes(schedule_travel_status[item.status]) ||
                      travel.number
                    "
                    input_group_classes=" input-group-sm"
                    :start_value="item.volume"
                    :options="{
                      min: 0,
                      max: 29,
                      decimals: 1,
                    }"
                    v-model="item.volume"
                    @value="item.volume = $event"
                    ><small>m³</small>
                  </InputLimit>
                </validation-provider>
              </div>
            </div>

            <!-- Horário de chegada na obra -->
            <div class="row mt-2">
              <div class="col-6 d-flex align-items-center">
                <span class="new-default-black-font fs-13"> Horário de chegada na obra </span>
              </div>
              <div class="col-6 pl-0">
                <validation-provider rules="required">
                  <InputDateTimePicker :disableMaxDate="max_date" :disableDate="disable_date" :disabled="loading_arrival_time" v-model="item.arrival_time" size="lg" />
                </validation-provider>
              </div>
            </div>

            <!-- Central -->
            <div class="row mt-4">
              <div class="col-4">
                <span class="new-default-black-font font-weight-500 d-flex align-items-center fs-16">
                  <img class="mr-3 mt" src="/img/icons/chemical-plant-black.png" width="21" />
                  Central
                </span>
              </div>
              <div style="display: grid" class="col-8 align-items-center">
                <hr style="border-top: 1.5px solid rgba(0, 0, 0, 0.1)" class="m-0" />
              </div>
            </div>

            <!-- Central de carregamento -->
            <div class="row mt-3">
              <div class="col-6 pr-0 d-flex align-items-center">
                <span class="new-default-black-font fs-13"> Central de carregamento </span>
              </div>
              <div class="col-6 pl-0">
                <validation-provider rules="required">
                  <PuzlSelect
                    :disabled="
                      travel.has_mcc ||
                      travel.number ||
                      travel.xml ||
                      ['finished', 'in_progress', 'canceled', 'unrealized'].includes(schedule_travel_status[item.status])
                    "
                    @change="getDataByCompanyPlant($event, true)"
                    customKey="uuid"
                    :clearable="false"
                    v-model="item.send_plant_uuid"
                    :items="$_company_plants"
                  />
                </validation-provider>
              </div>
            </div>
              <div class="row mt-2">
                <div class="col-6 pr-0 d-flex align-items-center">
                  <span class="new-default-black-font fs-13"> CNPJ Emissor  <el-popover trigger="click"
                                                                                        placement="right"
                                                                                        class="mb-n4"
                                                                                        v-if="company_plant_fixed_issuer_not_found"
                  >
                  <h5 class="font-weight-normal m-0">CNPJ Emissor fixo não encontrado para central
                    selecionada.</h5>
                  <base-button outline slot="reference" size="sm" type="secundary"
                               class="ml-2 mb-n2 mt-n2 shadow-none text-indigo">
                    <img src="/img/icons/icons8/ios/error--v1_danger.png" width="18">
                  </base-button>
                </el-popover> </span>
                </div>
              <div class="col-md-6 pl-0">
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm">
                    <el-select
                      :disabled="$_schedule.contract_proposal.issuer_fixed || !item.send_plant_uuid"
                      v-model="item.company_plant_issuer_id" size="mini" placeholder="Selecione"
                      filterable
                      :loading="loadingCompanyPlantIssuer">
                      <el-option label="Selecione" value=""></el-option>
                      <el-option v-for="(item, index) in $_company_plant_issuers" :key="index"
                                 :label="item.alias_name"
                                 :value="item.id">
                      </el-option>
                    </el-select>
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!-- Ponto de carga -->
            <div class="row mt-2">
              <div class="col-6 pr-0 d-flex align-items-center">
                <span class="new-default-black-font fs-13"> Ponto de carga </span>
              </div>
              <div class="col-6 pl-0">
                <PuzlSelect
                  :disabled="
                    travel.has_mcc ||
                    travel.number ||
                    ['finished', 'in_progress', 'canceled', 'unrealized'].includes(schedule_travel_status[item.status])
                  "
                  :loading="loading_charge_points"
                  customKey="uuid"
                  :clearable="false"
                  v-model="item.charge_point_uuid"
                  :items="$_charge_points"
                />
              </div>
            </div>

            <!-- Central de retorno -->
            <div class="row mt-2">
              <div class="col-6 pr-0 d-flex align-items-center">
                <span class="new-default-black-font fs-13"> Central de retorno </span>
              </div>
              <div class="col-6 pl-0">
                <validation-provider rules="required">
                  <PuzlSelect
                    :disabled="
                      travel.has_mcc ||
                      travel.number ||
                      travel.xml ||
                      ['finished', 'in_progress', 'canceled', 'unrealized'].includes(schedule_travel_status[item.status])
                    "
                    customKey="uuid"
                    :clearable="false"
                    v-model="item.return_plant_uuid"
                    :items="$_company_plants"
                  />
                </validation-provider>
              </div>
            </div>

            <!-- Central -->
            <div class="row mt-4">
              <div class="col-4">
                <span class="new-default-black-font font-weight-500 d-flex align-items-center fs-16">
                  <img class="mr-3 mt" src="/img/icons/interstate-truck.png" width="21" />
                  Alocação
                </span>
              </div>
              <div style="display: grid" class="col-8 align-items-center">
                <hr style="border-top: 1.5px solid rgba(0, 0, 0, 0.1)" class="m-0" />
              </div>
            </div>

            <!-- Equipamento -->
            <div class="row mt-3">
              <div class="col-6 pr-0 d-flex align-items-center">
                <span class="new-default-black-font fs-13"> Equipamento </span>
              </div>
              <div class="col-6 pl-0">
                <PuzlSelect
                  :disabled="!$_operational_permission.is_user_allowed_to_change_driver_and_equip && travel.was_billed"
                  :loading="loading_equipments"
                  customKey="uuid"
                  label="$code$ - $plate$"
                  :clearable="false"
                  v-model="item.equipment_uuid"
                  :items="$_equipments"
                  :labelMask="true"
                />
              </div>
            </div>

            <!-- Motorista -->
            <div class="row mt-2">
              <div class="col-6 pr-0 d-flex align-items-center">
                <span class="new-default-black-font fs-13"> Motorista </span>
              </div>
              <div class="col-6 pl-0">
                <PuzlSelect
                  :disabled="!$_operational_permission.is_user_allowed_to_change_driver_and_equip && travel.was_billed"
                  :loading="loading_drivers"
                  customKey="uuid"
                  :clearable="false"
                  v-model="item.driver_uuid"
                  :items="$_drivers"
                />
              </div>
            </div>

            <div class="modal-footer mt-4">
              <base-button class="d-flex align-items-center" type="danger" @click="closeModal()">
                <img src="/img/icons/cancel-white.png" width="20" />
                <span class="font-weight-500 fs-13 ml-2"> Fechar </span>
              </base-button>
              <base-button
                :disabled="invalid"
                @click.prevent="update"
                class="d-flex align-items-center"
                type="success"
                native-type="submit"
              >
                <img src="/img/icons/save.png" width="20" />
                <span class="font-weight-500 fs-13 ml-2"> Salvar </span>
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { formatErrorValidation, formatDate } from "@/plugins";
import { mapGetters } from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
import InputLimit from "@/components/Utils/InputLimit.vue";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import moment from "moment";

export default {
  name: "ModalEditTravel",
  components: {
    InputLimit,
    InputDateTimePicker,
    PuzlSelect,
  },
  data() {
    return {
      modal: {
        title: "Editar viagem",
        create: false,
      },
      item: {
        status: null,
        volume: null,
        arrival_time: null,
        send_plant_uuid: null,
        return_plant_uuid: null,
        company_plant_issuer_id: null,
        charge_point_uuid: null,
        equipment_uuid: null,
        driver_uuid: null,
        schedule_uuid: null,
      },
      loading_arrival_time: false,
      loading_drivers: false,
      loading_equipments: false,
      loading_charge_points: false,
      disable_date: false,
      interval_releases: null,
      loadingCompanyPlantIssuer: false,
      old_data: {
        volume: null,
        send_plant_uuid: null,
        arrival_time: null,
      },
      schedule_travel_status: {
        0: "released", // liberado
        1: "finished", // concluído
        2: "loading", // carregando
        4: "waiting_for_release", // aguardando liberação
        5: "in_progress", // em andamento
        6: "canceled", // cancelado
        7: "unrealized", // não realizado
        8: "has_pendency", // tem pendência
        9: "seller_request", // solicitação de vendedor
      },
      company_plant_fixed_issuer_not_found: false,
      default_plant_issuer: null,
      travel: {
        was_billed: false,
      },
      max_date: false,
    };
  },
  computed: {
    ...mapGetters({
      $_company_plants: "plant/activeItems",
      $_charge_points: "plantChargePoint/fetch",
      $_drivers: "user/getDrivers",
      $_equipments: "equipment/fetch",
      $_operational_permission: "operationalPermission/show",
      $_company_plant_issuers: "plant/getCompanyPlantIssuers",
      $_schedule: "schedule/show",
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    async getCompanyPlantIssuers() {
      this.loadingCompanyPlantIssuer = true
      await this.$store.dispatch('plant/fetchCompanyPlantIssuersByPlantByUuid', {
        uuid: this.item.send_plant_uuid
      }).then(async response => {
        if (![0].includes(this.travel.status)) {
          return;
        }
        let default_plant_issuer = response.data.find(item => item.default)
        /**
         * verifica se possui CNPJ Emissor fixo
         */
        if (this.$_schedule.contract_proposal.issuer_fixed) {
          let fixed_issuer_id = this.$_schedule.contract_proposal.company_plant_issuer_id_fixed
          /**
           * verifica se o CNPJ Emissor fixo pertence a central selecionada
           */
          let has_issuer = response.data.find((item) => item.id === fixed_issuer_id)
          if (has_issuer) {
            default_plant_issuer = {
              id: fixed_issuer_id
            }
            let has_issuer_filled = !!this.travel.company_plant_issuer_id
            this.item.company_plant_issuer_id = fixed_issuer_id
            if (has_issuer_filled) {
              this.save()
            }
            this.company_plant_fixed_issuer_not_found = false
          } else {
            this.company_plant_fixed_issuer_not_found = true
            this.item.company_plant_issuer_id = null
          }
        } else {
          if (default_plant_issuer && !this.item.company_plant_issuer_id) {
            this.item.company_plant_issuer_id = default_plant_issuer.id
          }
        }
        this.default_plant_issuer = default_plant_issuer
        this.loadingCompanyPlantIssuer = false
      }).catch(error => {
        this.loadingCompanyPlantIssuer = false
      })
    },
    async openModal(schedule_travel, interval_releases = 30) {
      const arrival_time = formatDate(schedule_travel.arrival_time);
      this.getDataByCompanyPlant(schedule_travel.send_plant.uuid);
      await this.$store.dispatch("operationalPermission/getByUserId", this.$user.id);
      this.travel = schedule_travel;
      this.item = {
        uuid: schedule_travel.uuid,
        status: schedule_travel.status,
        volume: schedule_travel.volume,
        arrival_time: arrival_time,
        construction_exit_time: formatDate(schedule_travel.construction_exit_time),
        load_truck_time: formatDate(schedule_travel.load_truck_time),
        send_plant_uuid: schedule_travel.send_plant.uuid,
        return_plant_uuid: schedule_travel.return_plant.uuid,
        charge_point_uuid: schedule_travel.charge_point_uuid,
        equipment_uuid: schedule_travel.equipment_uuid,
        driver_uuid: schedule_travel.driver_uuid,
        schedule_uuid: schedule_travel.schedule_uuid,
      };
      this.getCompanyPlantIssuers()
      this.old_data = {
        volume: schedule_travel.volume,
        send_plant_uuid: schedule_travel.send_plant.uuid,
        return_plant_uuid: schedule_travel.return_plant.uuid,
        arrival_time: arrival_time,
      };
      this.interval_releases = interval_releases;
      this.max_date = new Date(moment().add(1, "days").format("YYYY-MM-DD 00:00:00"));
      this.modal.create = true;
    },
    async update() {
      const loader = this.$loading.show();
      await this.finalFormat();
      this.$store
        .dispatch("travels/update", this.item)
        .then(() => {
          this.$emit("updatedTravel");
          this.closeModal();
        })
        .catch((error) => {
          if (error.status === 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response && error.response.status === 422) {
            const errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
        })
        .finally(() => {
          loader.hide();
        });
    },
    getDataByCompanyPlant(company_plant_uuid, columns_reset = false) {
      const company_plant = this.$_company_plants.find((item) => item.uuid === company_plant_uuid);
      this.loading_drivers = true;
      this.loading_equipments = true;
      this.loading_charge_points = true;
      if (columns_reset) {
        this.item.charge_point_uuid = null;
        this.item.equipment_uuid = null;
        this.item.driver_uuid = null;
      }
      const filter = {
        company_plant_uuid: company_plant_uuid,
        category_id: 1, // transporte de concreto
        status: 1, // ativo
        is_concrete: 1, // concreto
      };
      if (!this.item.return_plant_uuid) {
        this.item.return_plant_uuid = company_plant_uuid;
      }
      this.$store.dispatch("user/fetchDrivers", { filter: filter }).then(() => (this.loading_drivers = false));
      this.$store.dispatch("plantChargePoint/getActiveByPlant", company_plant.id).then((response) => {
        if(columns_reset) {
          this.item.charge_point_uuid = response.data.length ? response.data[0].uuid : null;
        }
        this.loading_charge_points = false;
      });
      this.$store.dispatch("equipment/getByCompanyPlantAndService", { filter: filter }).then(() => (this.loading_equipments = false));
      this.$store
        .dispatch("travels/getLastArrivalTimeByScheduleAndSendPlant", {
          send_plant_uuid: company_plant_uuid,
          schedule_uuid: this.item.schedule_uuid,
        })
        .then((response) => {
          if (!this.item.arrival_time) {
            this.item.arrival_time = moment(response.data).add(this.interval_releases, "minutes").format("YYYY-MM-DD HH:mm");
          }

          this.disable_date = new Date(this.item.arrival_time + ":00");
        });
      this.item.company_plant_issuer_id = null
      this.getCompanyPlantIssuers()
    },
    finalFormat() {
      if (
        this.item.volume !== this.old_data.volume ||
        this.item.send_plant_uuid !== this.old_data.send_plant_uuid ||
        this.item.return_plant_uuid !== this.old_data.return_plant_uuid ||
        this.item.arrival_time !== this.old_data.arrival_time
      ) {
        return (this.item.core_changes = 2); // gera histórico
      }
      return delete this.item.core_changes;
    },
  },
};
</script>
