<template>
  <div>
    <base-header class="bg-gray-content mt-2">
      <!-- <puzl-breadcrumb /> -->
    </base-header>
    <ListEntryLaunchExternal />
  </div>
</template>

<script>
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import ListEntryLaunchExternal from "./_List";

export default {
  name: "Index",
  components: { PuzlBreadcrumb, ListEntryLaunchExternal },
  methods: {
  },
};
</script>

<style></style>
