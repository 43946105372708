<template>
  <div>
    <modal size="md" :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ title }}</h5>
      </template>
      <div class="container">
        <div class="form-group row m-0 p-0 mb-1 mt-3">
          <div class="col-md-12">
            <label for="date-of-issue">
              <input
                @click="handleFilterType('date_of_issue')"
                class="form-check-input"
                type="radio"
                id="date-of-issue"
                name="flexRadioDefault"
                checked
              />
              <b>Data de emissão</b>
            </label>
            <InputDatePicker ref="input-date-picker" @handleFilterDate="handleFilterDate" :default-range="filter.range" :filterable="4" />
          </div>
        </div>
        <div class="form-group row m-0 p-0 mb-1 mt-4">
          <div class="col-md-12">
            <label for="entry-stock-date">
              <input
                @click="handleFilterType('entry_stock_date')"
                class="form-check-input"
                type="radio"
                id="entry-stock-date"
                name="flexRadioDefault"
              />
              <b>Data de entrada/estoque</b>
            </label>
            <InputDatePicker ref="input-date-picker" @handleFilterDate="handleFilterDate" :default-range="filter.range" :filterable="4" />
          </div>
        </div>
        <div class="form-group row m-0 p-0 mb-1 mt-4">
          <div class="col-md-12">
            <label for="release-date-of">
              <input
                @click="handleFilterType('filter_type')"
                class="form-check-input"
                type="radio"
                id="release-date-of"
                name="flexRadioDefault"
              />
              <b>Data de lançamento</b>
            </label>
            <InputDatePicker ref="input-date-picker" @handleFilterDate="handleFilterDate" :default-range="filter.range" :filterable="4" />
          </div>
        </div>
        <div class="form-group row m-0 p-0 mb-1 mt-5">
          <label class="col-md-2 form-control-label mt-1"> Central </label>
          <div class="col-md-10">
            <puzl-select
              @input="handleFilterCompanyPlant"
              v-model="filter.company_plant_ids"
              :items="[{ id: 'all', name: 'Todos' }, ...$_company_plants]"
              :multiple="true"
            />
          </div>
        </div>
      </div>

      <div class="col-md-12 mt-2 text-right p-0 mt-4">
        <base-button @click.prevent="close" type="secondary" class="text-capitalize"> Cancelar </base-button>
        <base-button @click.prevent="store" type="success" native-type="submit" :loading="loading">
          <span class="btn-label"><i class="fa-solid fa-file-excel"></i></span>
          Download
        </base-button>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InputDatePicker from "@/components/InputDatePicker";
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "ModalEquipmentApportionmentReport",
  components: {
    InputDatePicker,
    PuzlSelect,
  },
  data() {
    return {
      title: "Gerar Relatório de Despesas (Excel)",
      modal: false,
      filter: {
        range: {
          start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
        },
        company_plant_ids: ["all"],
        filter_type: {
          date_of_issue: 1, // data de emissão
          entry_stock_date: null, // data de entrada/estoque
          release_date_of: null, // data de lançamento
        },
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      $_company_plants: "plant/activeItems",
    }),
  },
  methods: {
    openModal() {
      this.filter = {
        range: {
          start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
        },
        company_plant_ids: ["all"],
        filter_type: {
          date_of_issue: 1, // data de emissão
          entry_stock_date: null, // data de entrada/estoque
          release_date_of: null, // data de lançamento
        },
      };
      this.modal = true;
      this.loading = false;
    },
    close() {
      this.modal = false;
    },
    store() {
      const loader = this.$loading.show();
      this.loading = true;
      let filter = this.$helper.cloneObject(this.filter);
      filter.company_plant_ids = filter.company_plant_ids && filter.company_plant_ids.includes("all") ? [] : filter.company_plant_ids;
      this.$store
        .dispatch("entryLaunch/getEquipmentApportionmentReport", { filter: filter })
        .then(async (response) => {
          const blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "Relatório de despesas.xlsx");
          await link.click();
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: "Erro ao realizar o download do arquivo excel!",
          });
        })
        .finally(() => {
          loader.hide();
          this.loading = false;
          this.close();
        });
    },
    /**
     * @param {object} filter
     */
    handleFilterDate(filter) {
      this.filter.range = filter;
    },
    /**
     * @param {object} filter
     */
    handleFilterCompanyPlant(value) {
      if (value.includes("all")) {
        const last_value = value[value.length - 1];
        if (last_value === "all") {
          this.filter.company_plant_ids = ["all"];
          return;
        }
        this.filter.company_plant_ids = value.filter((item) => item !== "all");
      }
    },
    /**
     * @param {string} filter
     */
    handleFilterType(type) {
      this.filter.filter_type = {
        date_of_issue: type === "date_of_issue" ? 1 : null, // data de emissão
        entry_stock_date: type === "entry_stock_date" ? 1 : null, // data de entrada/estoque
        release_date_of: type === "release_date_of" ? 1 : null, // data de lançamento
      };
    },
  },
};
</script>

<style></style>
