/**
 * Definição das chaves únicas usadas para armazenar o estado.
 *
 * Cada chave deve ser única em todo o projeto.
 * Não deve haver duplicação de chaves em nenhum lugar do código.
 */
export const adjustmentHistoryStoreStateKeys = Object.freeze({
  ITEM: "ADJUSTMENT_HISTORY_ITEM",
  LISTED: "ADJUSTMENT_HISTORY_LISTED",
  IS_LISTING: "ADJUSTMENT_HISTORY_IS_LISTING",
  IS_FINDING: "ADJUSTMENT_HISTORY_IS_FINDING",
  IS_SAVING: "ADJUSTMENT_HISTORY_IS_SAVING",
  IS_REMOVING: "ADJUSTMENT_HISTORY_IS_REMOVING",
  PAGE_STATES: "ADJUSTMENT_HISTORY_PAGE_STATE",
});
