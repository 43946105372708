<template>
  <div>
    <div class="container-fluid">
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="index in 3" :key="index" />
      </div>
      <!-- Dados vazios -->
      <PuzlEmptyData v-if="!$_items.length && !loadingSkeleton" />

      <div class="row card-wrapper" v-if="!loadingSkeleton">
        <div class="col-lg-4" v-for="(item, index) in $_items" :key="index">
          <div class="card main-card">
            <div class="py-3 px-4">
              <div class="row align-items-center">
                <div class="col-10">
                  <h4 v-if="![5, 6].includes(item.status)" class="new-default-gray-font font-weight-600 mb-0 fs-14 pt-0">
                    {{ item.type_name }}
                  </h4>
                </div>
                <div style="z-index: 99" :class="{ 'mb-2': [5, 6].includes(item.status) }" class="col-2 text-right align-items-top">
                  <base-dropdown menuOnRight>
                    <div slot="title-container" class="dropdown-toggle rounded m-0">
                      <img width="32" src="/img/icons/icons8/ios/settings--v1_primary.png" />
                    </div>
                    <router-link
                      :to="{
                        name: 'configuration.entry-launch.edit',
                        params: { id: item.id },
                      }"
                      v-if="![5, 6].includes(item.status)"
                    >
                      <a class="dropdown-item d-flex align-items-center">
                        <img src="/img/icons/create-new.png" width="22px" height="22px" />
                        Editar
                      </a>
                    </router-link>
                    <a
                      v-else-if="[5, 6].includes(item.status) && item.created_by_barcode === 1"
                      @click.prevent="handleModalCreateByAccessKey(item)"
                      class="dropdown-item d-flex align-items-center"
                    >
                      <img src="/img/icons/create-new.png" width="22px" height="22px" />
                      Editar
                    </a>
                    <a
                      v-else-if="[5, 6].includes(item.status) && item.created_by_entity === 1"
                      @click.prevent="handleModalCreateByEntity(item)"
                      class="dropdown-item d-flex align-items-center"
                    >
                      <img src="/img/icons/create-new.png" width="22px" height="22px" />
                      Editar
                    </a>
                    <div v-show="item.status === 3" class="dropdown-divider p-0 m-0"></div>
                    <a v-if="item.status === 3" @click.prevent="billPayGenerator(item.id)" class="dropdown-item d-flex align-items-center">
                      <img src="/img/icons/icons8/ios/duration-finance_success.png" width="22px" height="22px" />
                      Gerar cap
                    </a>
                    <a
                      v-if="item.external_type === 0 && item.created_by_barcode === 0 && item.created_by_entity === 0"
                      @click.prevent="$router.push(`/configuration/entry-launch/replication/${item.id}`)"
                      class="dropdown-item d-flex align-items-center"
                    >
                      <img src="/img/icons/icons8/ios/copy--v1.png" width="22px" height="22px" />
                      Replicar
                    </a>
                    <div v-show="item.status === 6" class="dropdown-divider p-0 m-0"></div>
                    <a
                      v-if="item.status === 6"
                      :href="`/configuration/entry-launch/external-create/${item.company_plant_id}/${item.company_plant_buyer_id}/${item.id}`"
                      @click.prevent="
                        $router.push(
                          `/configuration/entry-launch/external-create/${item.company_plant_id}/${item.company_plant_buyer_id}/${item.id}`
                        )
                      "
                      class="dropdown-item d-flex align-items-center"
                    >
                      <img src="/img/icons/icons8/ios/download--v1_primary.png" width="22px" height="22px" />
                      Baixar xml
                    </a>
                    <div v-show="item.status !== 1 && item.product_service_hub_type_id === 2" class="dropdown-divider p-0 m-0"></div>
                    <a
                      v-if="item.status !== 1 && item.product_service_hub_type_id === 2"
                      @click.prevent="handleModalEntryCompanyPlantTransfer(item.id)"
                      class="dropdown-item d-flex align-items-center"
                    >
                      <img src="/img/icons/icons8/ios/data-in-both-directions_danger.png" width="22px" height="22px" />
                      Transferir
                    </a>
                    <div
                      v-show="!item.freight_id && item.product_service_hub_type_id !== 4 && ![5, 6].includes(item.status)"
                      class="dropdown-divider p-0 m-0"
                    ></div>
                    <a
                      v-if="!item.freight_id && item.product_service_hub_type_id !== 4 && ![5, 6].includes(item.status)"
                      @click.prevent="handleEntryLaunchToFreight(item)"
                      class="dropdown-item d-flex align-items-center"
                    >
                      <img src="/img/icons/icons8/ios/in-transit_primary.png" width="22px" height="22px" />
                      Frete
                    </a>
                    <!-- Reassociar frete -->
                    <div
                      v-show="item.freight_id"
                      class="dropdown-divider p-0 m-0"
                    ></div>
                    <a
                      v-if="item.freight_id"
                      @click.prevent="handleModalGetFreightToReassociate(item.id, item.old_issue_date)"
                      class="dropdown-item d-flex align-items-center"
                    >
                      <img src="/img/icons/icons8/ios/in-transit_primary.png" width="22px" height="22px" />
                      Trocar CTE-E
                    </a>
                    <div v-show="item.access_key && !item.danfe_url" class="dropdown-divider p-0 m-0"></div>
                    <a
                      v-if="item.access_key && !item.danfe_url && item.external_type === 1"
                      @click.prevent="getDanfe(item.id)"
                      class="dropdown-item d-flex align-items-center"
                    >
                      <img src="/img/icons/pdf-v2.png" width="22px" height="22px" />
                      Solicitar pdf
                    </a>
                    <div v-if="item.is_entry_value" class="dropdown-divider p-0 m-0"></div>
                    <a
                      v-if="item.is_entry_value"
                      @click.prevent="handleModalSearchChildren(item.id)"
                      class="dropdown-item d-flex align-items-center"
                    >
                      <img src="/img/icons/icons8/ios/upload-link-document_primary.png" width="22px" height="22px" />
                      Notas filhas
                    </a>
                    <div
                      v-if="item.status === EntryLaunchStatusEnum.VERIFICATION && item.created_by_barcode === 1 && item.external_type === 0"
                      class="dropdown-divider p-0 m-0"
                    ></div>
                    <a
                      v-if="item.status === EntryLaunchStatusEnum.VERIFICATION && item.created_by_barcode === 1 && item.external_type === 0"
                      @click.prevent="handleCheckStatus(item.id)"
                      class="dropdown-item d-flex align-items-center"
                    >
                      <img src="/img/icons/icons8/ios/evidence_primary.png" width="22px" height="22px" />
                      Consultar Xml
                    </a>
                    <div v-show="item.status !== 1" class="dropdown-divider p-0 m-0"></div>
                    <a
                      v-if="item.status !== 1"
                      @click.prevent="handleDeleteEntryLaunch(item.id)"
                      class="dropdown-item d-flex align-items-center"
                    >
                      <img src="/img/icons/delete.png" width="22px" height="22px" />
                      Excluir
                    </a>
                  </base-dropdown>
                </div>

                <div :class="[5, 6].includes(item.status) ? 'mt-n5' : 'mt-n2'" class="col-12">
                  <h1
                    v-if="![5, 6].includes(item.status)"
                    class="new-default-black-font fs-30 mb-0"
                    style="display: flex; align-items: center"
                  >
                    {{ item.final_value | currency() }}
                    <div class="ml-3" style="display: inline-flex">
                      <el-popover trigger="hover" placement="bottom" class="p-0 text-center">
                        <span v-if="item.near_due_date"> {{ item.near_due_date | parseDate("DD MMM YYYY") }} </span>
                        <span v-else> {{ getText(item.status) }} </span>
                        <h4 slot="reference" class="font-weight-normal m-0 p-0">
                          <a class="m-0">
                            <badge :class="`mini-card-${getColor(item.status)}`" tag="a" class="mini-card" style="">
                              {{ getText(item.status) }}
                            </badge>
                          </a>
                        </h4>
                      </el-popover>
                    </div>
                  </h1>
                  <h1 v-else class="new-default-black-font fs-30 mb-0" style="display: flex; align-items: center">
                    <div class="mb-2 mt-1" style="display: inline-flex">
                      <el-popover trigger="hover" placement="bottom" class="p-0 text-center">
                        <h4 slot="reference" class="font-weight-normal m-0 p-0">
                          <a class="m-0">
                            <badge :class="`mini-card-${getColor(item.status)}`" tag="a" class="mini-card" style="">
                              {{ getText(item.status) }}
                            </badge>
                          </a>
                        </h4>
                      </el-popover>
                    </div>
                  </h1>
                </div>

                <div class="col-9 mt-n2">
                  <h4 class="new-default-gray-font font-weight-400 mb-0 fs-14 d-flex align-items-center pt-1">
                    <span v-if="item.issue_date"> {{ item.issue_date }}&nbsp;</span>
                    <span v-if="item.entry_date">|&nbsp; {{ item.entry_date | parseDate("DD MMM YYYY") }} (Entrada)</span>
                  </h4>
                </div>
                <div class="col-3 text-right mt-n2">
                  <el-popover trigger="hover" placement="bottom" class="p-0">
                    <div v-if="item.operation_source_id">
                      <h4 class="new-default-black-font">
                        <img width="20px" height="20px" src="/img/brand/logo.png" class="mr-2 mt-n1" />
                        Tipo de Operação Puzl(TOP)
                      </h4>
                      <h4 class="mb-0 new-default-black-font fs-12 font-weight-normal">
                        {{ item.operation_source_name }}
                      </h4>
                    </div>
                    <hr v-if="item.operation_source_id" style="border-top: 2px solid rgba(0, 0, 0, 0.1)" class="mt-2 mb-2" />

                    <div v-if="item.entry_company_plant_id">
                      <h4 class="new-default-black-font">
                        <img width="20px" height="20px" src="/img/icons/chemical-plant-black.png" class="mr-2 mt-n1" />
                        Central entrada/estoque
                      </h4>
                      <h4 class="mb-1 new-default-black-font fs-12 font-weight-normal">
                        {{ item.entry_company_plant_name }}
                      </h4>
                    </div>
                    <hr style="border-top: 2px solid rgba(0, 0, 0, 0.1)" class="mt-2 mb-2" />

                    <div v-if="item.entry_company_plant_id">
                      <h4 class="new-default-black-font">
                        <img width="20px" height="20px" src="/img/icons/icons8/ios/low-price.png" class="mr-2 mt-n1" />
                        Central de custo
                      </h4>
                      <h4 class="mb-1 new-default-black-font fs-12 font-weight-normal">
                        {{ item.company_plant_name }}
                      </h4>
                    </div>
                    <hr style="border-top: 2px solid rgba(0, 0, 0, 0.1)" class="mt-2 mb-2" />

                    <div v-if="item.entry_company_plant_id">
                      <h4 class="new-default-black-font">
                        <img width="20px" height="20px" src="/img/icons/icons8/ios/market-square.png" class="mr-2 mt-n1" />
                        Cnpj comprador
                      </h4>
                      <h4 class="mb-1 new-default-black-font fs-12 font-weight-normal">
                        {{ item.company_plant_issuer_business_name }}
                      </h4>
                      <h4 class="mb-0 new-default-black-font fs-12 font-weight-normal">
                        {{ item.buyer_ein }}
                      </h4>
                    </div>
                    <hr style="border-top: 2px solid rgba(0, 0, 0, 0.1)" class="mt-2 mb-2" />

                    <div>
                      <h4 v-if="item.external_type === 1 || [5, 6].includes(item.status)" class="new-default-black-font">
                        <img width="20px" height="20px" src="/img/icons/icons8/ios/downloading-updates-primary.png" class="mr-2 mt-n1" />
                        Sefaz
                      </h4>
                      <h4 v-else class="new-default-black-font">
                        <img width="20px" height="20px" src="/img/icons/icons8/ios/plus-math-success.png" class="mr-2 mt-n1" />
                        Manual
                      </h4>
                      <h4 class="mb-1 new-default-black-font fs-12 font-weight-normal">
                        {{ item.importer_user_name }}
                      </h4>
                      <h4 class="new-default-gray-font font-weight-400 mb-0 2 d-flex align-items-center">
                        <span class="fs-12" v-if="item.imported_at"> {{ item.imported_at | parseDate("DD MMM YYYY") }}&nbsp;</span>
                      </h4>
                    </div>

                    <a role="button" slot="reference" class="ml-1">
                      <img src="/img/icons/info.png" width="20px" height="20px" />
                    </a>
                  </el-popover>

                  <a v-if="item.photo" @click.prevent="handleModalAttachment(item.photo)" role="button" class="ml-1">
                    <img src="/img/icons/attach-green.png" width="20px" height="20px" />
                  </a>
                  <a
                    @click.prevent="handleModalListEntryLaunchItems(item.id)"
                    v-show="![5, 6].includes(item.status)"
                    role="button"
                    class="ml-1"
                  >
                    <img src="/img/icons/icons8/ios/menu-squared-2-dark.png" width="20px" height="20px" />
                  </a>
                </div>
              </div>

              <hr style="border-top: 2px solid rgba(0, 0, 0, 0.1)" class="mt-2 mb-2" />

              <div class="row align-items-center">
                <div class="col-12">
                  <h4 class="new-default-gray-font font-weight-600 mb-0 fs-14">{{ item.entity_name }}</h4>
                  <h4 class="new-default-gray-font font-weight-500 mb-0 fs-12">{{ item.document }}</h4>
                </div>
                <div class="col row m-0">
                  <base-button
                    @click.prevent="item.danfe_url && downloadDanfe(item.danfe_url)"
                    class="new-default-black-font d-flex align-items-center mt-2 text-truncate"
                    style="border: none; width: 135px"
                    size="md"
                    type="secondary"
                    outline
                  >
                    <img class="mr-2" height="20px" src="/img/icons/pdf-v2.png" />

                    <span class="text-truncate fs-14"> {{ item.document_number }} </span>
                    <img
                      class="mt-n3 mr-n3 ml-2"
                      v-if="item.access_key"
                      src="/img/icons/icons8/ios/copy-2_primary.png"
                      width="16px"
                      height="16px"
                      @click.stop="copyAccessKey(item.access_key)"
                    />
                  </base-button>
                  <base-button
                    @click.prevent="handleModalFreight(item)"
                    v-if="item.freight_id"
                    class="new-default-black-font d-flex align-items-center mt-2 text-truncate"
                    style="border: none; width: 135px"
                    size="md"
                    type="secondary"
                    outline
                  >
                    <img class="mr-2" height="20px" src="/img/icons/icons8/ios/in-transit_primary.png" />

                    <span class="text-truncate fs-14"> N.{{ item.referenced_entry_launch.slice(0, -13) }} </span>
                  </base-button>
                  <el-popover
                    v-else-if="item.product_service_hub_type_id === 4 && item.referenced_entry_launch"
                    trigger="hover"
                    placement="bottom"
                    class="p-0 float-right pr-0 text-truncate"
                    style="width: 135px"
                  >
                    <h4 class="new-default-black-font mb-1">
                      <img width="20px" height="20px" src="/img/icons/info.png" class="mr-2 mt-n1" />
                      Nota vinculada
                    </h4>
                    <h4 class="new-default-black-font font-weight-normal mb-0">
                      <span class="fs-12 font-weight-600"> N. Nota:</span>
                      <span class="fs-12"> {{ item.referenced_entry_launch.slice(0, -13) }}</span>
                    </h4>
                    <h4 class="new-default-black-font font-weight-normal mb-0">
                      <span class="fs-12 font-weight-600"> Data da emissão:</span>
                      <span class="fs-12"> {{ item.referenced_entry_launch.slice(-11) }}</span>
                    </h4>

                    <base-button
                      slot="reference"
                      class="new-default-black-font d-flex align-items-center mt-2"
                      style="border: none; box-shadow: 0 8px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)"
                      size="md"
                      type="secondary"
                      outline
                    >
                      <img class="mr-2" height="20px" src="/img/icons/icons8/ios/remove-tag-primary.png" /> N.{{
                        item.referenced_entry_launch.slice(0, -13)
                      }}
                    </base-button>
                  </el-popover>
                </div>
                <div class="col-auto text-right">
                  <!-- Nota mãe -->
                  <el-popover
                    v-if="item.status !== EntryLaunchStatusEnum.WAITING && (item.is_entry_value || item.parent_id)"
                    trigger="hover"
                    placement="bottom"
                    class="p-0"
                  >
                    <!-- Com saldo -->
                    <h4
                      v-if="item.is_entry_value > 0 && (Number(item.children_balance) > 0 || item.children_balance === null)"
                      class="new-default-black-font font-weight-normal"
                    >
                      Saldo : {{ (item.children_balance !== null ? item.children_balance : item.total_value) | currency() }}
                    </h4>
                    <!-- Sem saldo -->
                    <h4
                      v-else-if="item.is_entry_value > 0 && Number(item.children_balance) <= 0 && item.children_balance !== null"
                      class="new-default-black-font font-weight-normal"
                    >
                      NOTA MAE S/ SALDO
                    </h4>
                    <a slot="reference" role="button" class="ml-1">
                      <img :src="getParentOrChildrenIcon(item)" width="22px" />
                    </a>
                  </el-popover>
                  <el-popover
                    v-if="item.has_cmc_without_mix_reference || item.has_cmc_adjustment_without_dosage_location"
                    trigger="hover"
                    placement="bottom"
                    class="p-0"
                  >
                    <div v-show="item.has_cmc_without_mix_reference">
                      <h4 class="new-default-black-font">
                        <img width="20px" height="20px" src="/img/icons/icons8/ios/star--v1_success.png" class="mr-2 mt-n2" />
                        Referência comercial
                      </h4>
                      <h4 class="mb-0 new-default-black-font fs-12 font-weight-normal">Mcc não estava definido como referência</h4>
                    </div>
                    <hr
                      v-show="item.has_cmc_adjustment_without_dosage_location && item.has_cmc_without_mix_reference"
                      style="border-top: 2px solid rgba(0, 0, 0, 0.1)"
                      class="mt-2 mb-2"
                    />
                    <div v-show="item.has_cmc_adjustment_without_dosage_location">
                      <h4 class="new-default-black-font">
                        <img width="20px" height="20px" src="/img/icons/icons8/ios/chemical-plant-v1.png" class="mr-2 mt-n2" />
                        Mapa de ponto de carga
                      </h4>
                      <h4 class="mb-0 new-default-black-font fs-12 font-weight-normal">Mcc não estava disponível</h4>
                    </div>

                    <a slot="reference" role="button" class="ml-1">
                      <img src="/img/icons/icons8/ios/break--v2_warning.png" width="20px" height="20px" />
                    </a>
                  </el-popover>
                  <el-popover
                    v-if="item.entry_company_plant_id && item.company_plant_id !== item.entry_company_plant_id"
                    trigger="hover"
                    placement="bottom"
                    class="p-0 float-right pr-0"
                  >
                    <h4 class="new-default-black-font mb-0">Central de Entrada</h4>
                    <h4 class="new-default-black-font font-weight-normal fs-12 mb-0">
                      {{ item.entry_company_plant_name }}
                    </h4>

                    <a slot="reference" role="button" class="ml-1">
                      <img src="/img/icons/sorting-arrows-horizontal-red.png" width="20px" height="20px" />
                    </a>
                  </el-popover>

                  <el-popover trigger="hover" placement="bottom" class="p-0">
                    <div v-if="item.observations">
                      <h4 class="new-default-black-font mb-0">Observações</h4>
                      <h4 class="new-default-black-font fon-weight-normal fs-12 mb-0">
                        {{ item.observations }}
                      </h4>
                    </div>

                    <a slot="reference" role="button" class="ml-1">
                      <img src="/img/icons/speech-bubble-with-dots--v1-black.png" width="20px" height="20px" />
                    </a>
                  </el-popover>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import { EntryLaunchStatusEnum } from "@/enum/EntryLaunchStatusEnum";

export default {
  components: { SkeletonPuzlGrid, PuzlEmptyData },
  props: {
    loadingSkeleton: Boolean,
    getColor: Function,
    getText: Function,
    downloadDanfe: Function,
    handleModalAttachment: Function,
    handleModalListEntryLaunchItems: Function,
    handleDeleteEntryLaunch: Function,
    handleModalCreateByAccessKey: Function,
    handleModalCreateByEntity: Function,
    billPayGenerator: Function,
    handleModalEntryCompanyPlantTransfer: Function,
    handleModalFreight: Function,
    handleEntryLaunchToFreight: Function,
    getDanfe: Function,
    handleModalSearchChildren: Function,
    getParentOrChildrenIcon: Function,
    handleModalGetFreightToReassociate: Function,
  },
  data() {
    return {
      EntryLaunchStatusEnum: EntryLaunchStatusEnum,
    };
  },
  methods: {
    async copyAccessKey(accessKey) {
      await navigator.clipboard.writeText(accessKey);
      this.$notify({
        type: "success",
        message: "Código copiado",
      });
    },
  },
  computed: {
    ...mapGetters({
      $_items: "entryLaunch/fetch",
    }),
  },
};
</script>

<style scoped>
.mini-card {
  font-weight: normal !important;
  line-height: 1.5;
}

.mini-card-success {
  color: #149e57 !important;
}

.mini-card-danger {
  color: #db4539 !important;
}

.mini-card-warning {
  color: #f2b532 !important;
  background-color: #f4dc592e !important;
  border-radius: 5px !important;
  padding: 1px 5px !important;
  font-size: 12px !important;
}

.mini-card-light {
  color: #fff !important;
}

.mini-card-success {
  background-color: #8bd5af52 !important;
  border-radius: 5px !important;
  padding: 1px 5px !important;
  font-size: 12px !important;
  box-shadow: 0 0 5px 0 #149e5752 !important;
}
</style>
