<template>
  <div v-bind="$attrs">
    <modal
      size="md-2"
      :show.sync="modal.show"
      data-class="programming-weighing-info"
    >
      <template slot="header">
        <h5 class="modal-title">
          <img
            src="/img/icons/icons8/ios/icon-plus-success.png"
            width="25"
            class="mt-n2 mr-2"
          />
          <span>{{ modal.title }}</span>
        </h5>
      </template>
      <validation-observer
        v-slot="{ invalid, handleSubmit }"
        ref="formValidator"
      >
        <form
          class="needs-validation"
          ref="myForm"
          @submit.prevent
          autocomplete="off"
        >
          <div class="pl-3">
            <div class="row">
              <div class="col-md-12 mb-3 info-section">
                <img
                  src="/img/icons/icons8/ios/info-squared.png"
                  width="20"
                  height="20"
                  style="margin-right: 1.5%"
                />
                <strong class="mb-3">Informação</strong>
                <div class="pzl-container-divider">
                  <div></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <!-- <div class="form-group row m-0 p-0 mb-1">
                  <label
                    class="col-md-4 pt-1 pb-0 col-form-label form-control-label pl-0"
                  >
                    Nome do checklist
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </label>
                  <div class="col-md-8 pr-4">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <input
                          v-model="checklist.name"
                          type="text"
                          class="form-control form-control-sm"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div> -->
                <div class="form-group row m-0 p-0 mb-1">
                  <label
                    class="col-md-4 pt-1 pb-0 col-form-label form-control-label pl-0"
                  >
                    Vincular
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </label>
                  <div class="col-md-8 pr-4">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <PuzlSelect
                          style="width: 100%"
                          v-model.lazy="sendObject.optionSelected"
                          :items="optionsList"
                          :disableBoxShadow="true"
                          placeholder="Selecione"
                          @change="optionSelectedChange"
                          class="select-xl col-md-12 px-0 new-default-black-font"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-1">
                  <label
                    class="col-md-4 pt-1 pb-0 col-form-label form-control-label pl-0"
                    v-if="sendObject.listSelected"
                  >
                    {{ sendObject.listSelected }}
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </label>
                  <label
                    class="col-md-4 pt-1 pb-0 col-form-label form-control-label pl-0"
                    v-else
                  >
                    Equipamento ou usuário
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </label>
                  <div class="col-md-8 pr-4">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <PuzlSelect
                          style="width: 100%"
                          v-model.lazy="sendObject.idSelected"
                          :items="itemsList"
                          :disableBoxShadow="true"
                          placeholder="Selecione"
                          class="select-xl col-md-12 px-0 new-default-black-font"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-1">
                  <label
                    class="col-md-4 pt-1 pb-0 col-form-label form-control-label pl-0"
                  >
                    Data do agendamento
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </label>
                  <div class="col-md-8 pr-4">
                    <base-input
                      input-group-classes="input-group-sm"
                      rules="required"
                    >
                      <input-date-time-picker
                        v-model="sendObject.appointmentDate"
                        :mode="'date'"
                      />
                    </base-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="d-flex pr-2" style="gap: 5px">
              <div
                class="d-flex align-items-center button-cancel"
                style="gap: 5px"
                @click="closeModal()"
              >
                <img
                  src="/img/icons/icons8/ios/cancel_danger.png"
                  alt="cancel_danger"
                  width="16px"
                />
                <span class="font-weight-500"> Cancelar </span>
              </div>
              <button
                v-show="!loading"
                class="d-flex align-items-center button-save"
                type="submit"
                style="gap: 5px"
                @click="handleSave()"
              >
                <img
                  src="/img/icons/icons8/ios/save_success.png"
                  alt="save_success"
                  width="16px"
                />
                <span class="font-weight-500"> Salvar </span>
              </button>
            </div>
          </div>
        </form>
      </validation-observer>
    </modal>
  </div>
</template>

<script>
import PuzlSelect from "@/components/PuzlSelect";
import { mapGetters } from "vuex";
import InputDateTimePicker from "@/components/InputDateTimePickerV2";
export default {
  name: "ModalGenerate",
  inheritAttrs: false,
  components: {
    PuzlSelect,
    InputDateTimePicker,
  },
  data() {
    return {
      modal: {
        title: "Gerar checklist",
        show: false,
      },
      loading: false,
      optionsList: [
        { id: 0, name: "Equipamento ou usuário" },
        { id: 1, name: "Equipamento" },
        { id: 2, name: "Usuário" },
      ],
      itemsList: [],
      sendObject: {
        uuid: null,
        optionSelected: 0,
        idSelected: null,
        listSelected: null,
        appointmentDate: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      $_users: "user/fetch",
    }),
  },
  mounted() {
    this.init();
  },
  methods: {
    /**
     * @returns {void}
     */
    optionSelectedChange() {
      this.itemsList = [];
      this.sendObject.idSelected = null;
      if (this.sendObject.optionSelected === this.optionsList[2].id) {
        this.sendObject.listSelected = "usuário";
        this.itemsList = this.$_users.map((user) => {
          return {
            id: user.id,
            name: user.name,
          };
        });
        return;
      }
      this.sendObject.listSelected = "equipamento";
    },
    /**
     * @param {string} uuid
     * @returns {void}
     */
    openModal(uuid) {
      this.objectReset(uuid);
      this.modal.show = true;
    },
    /**
     * @param {string} uuid
     * @returns {void}
     */
    objectReset(uuid) {
      this.sendObject = {
        uuid: uuid,
        optionSelected: 0,
        idSelected: null,
        listSelected: null,
        appointmentDate: null,
      };
    },
    /**
     * @returns {void}
     */
    closeModal() {
      this.modal.show = false;
    },
    /**
     * @returns {Promise<Object>}
     */
    getUsers() {
      const promise = new Promise((resolve, reject) => {
        this.$store
          .dispatch("user/fetchItemsPaginate", {})
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
      return promise;
    },
    /**
     * @returns {void}
     */
    handleSave() {
      this.$refs.formValidator.validate().then((isValid) => {
        if (isValid) {
          this.save();
        }
      });
    },
    /**
     * @returns {void}
     */
    save() {
      this.$Progress.start();
      let loader = this.$loading.show();
      const obj = {
        scheduling_date: this.sendObject.appointmentDate,
        checklist_uuid: this.sendObject.uuid,
      };
      this.$store
        .dispatch("checklistActivity/add", obj)
        .then((response) => {
          this.$notify({
            type: "success",
            message: response.message,
          });
          this.closeModal();
          this.reset();
          this.$emit("refresh");
        })
        .catch((error) => {
          if (error.data && error.data.message) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          }
        })
        .finally(() => {
          this.$Progress.finish();
          loader.hide();
        });
    },
    /**
     * @returns {void}
     */
    async init() {
      this.$Progress.start();
      let loader = this.$loading.show();
      await this.getUsers();
      this.$Progress.finish();
      loader.hide();
    },
  },
};
</script>

<style scoped>
/deep/ div[data-class="programming-weighing-info"] div.modal-header.pb-1 {
  background: #fef9f2 !important;
}

/deep/
  div[data-class="programming-weighing-info"]
  div.modal-header.pb-1
  > button
  i {
  border: 1px solid #000 !important;
  color: #000;
  padding: 3%;
  font-size: 14px !important;
}

h5.modal-title > span,
h5.modal-title > small {
  color: #2b2d32;
  font-size: 18px !important;
}

div.info-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 23px;
}

div.info-section > div.pzl-container-divider {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2%;
}

div.info-section > div.pzl-container-divider > div {
  width: 100%;
  border: 0.5px solid #e8e8e8;
}

div.close-btn {
  border-radius: 8px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 115px;
  border: 0.5px solid #e8e8e8;
  gap: 10px;
}

div.close-btn > i {
  border: 1px solid #000;
  color: #000;
  padding: 3%;
  font-size: 8px !important;
}

div.close-btn > span {
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  font-family: "Fredoka";
}

.read-info {
  border: 0.5px solid #e8e8e8;
  height: 31px;
  padding: 8px 2px 8px 23px;
  border-radius: 4px;
  width: 100%;
}
textarea.read-info {
  height: 51px;
}
.container-icon {
  width: 25px;
  height: 31px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-cancel {
  cursor: pointer;
  border: 0.5px solid #db4539;
  border-radius: 8px;
  color: #db4539;
  padding: 4px 20px;
  height: 32px;
}
button.button-save {
  cursor: pointer;
  border: 0.5px solid #149e57;
  border-radius: 8px;
  color: #149e57;
  padding: 4px 30px;
  background-color: #fff;
  height: 32px !important;
  min-height: 32px !important;
}
.green-text-badge {
  color: #149e57;
}
.red-text-badge {
  color: #db4539;
}
.green-backgroud-light {
  background: #f2f7f3;
}
.red-backgroud-light {
  background: #fcf3f3;
}
label.app-badge {
  display: flex;
  max-width: 100%;
  height: 24px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  cursor: pointer;
}
label.app-badge > input[type="checkbox"] {
  display: none;
}
label.app-badge > span {
  font-family: "Fredoka";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
div.alternative-add-btn {
  border: 0.5px solid #149e57;
  border-radius: 8px;
  width: 121px;
  gap: 4px;
  padding: 4px 12px 4px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  cursor: pointer;
}
div.alternative-add-btn > span {
  color: #149e57;
}
div.trash-input {
  position: absolute;
  height: 20px;
  width: 30px;
  cursor: pointer;
  z-index: 1;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 25px;
}
</style>
