<template>
  <div>
    <modal size="lg" :show.sync="modal.show">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <validation-observer ref="formValidator">
          <form class="needs-validation" @submit.prevent="submit()" autocomplete="off">
            <!-- <div class="form-group row m-0 p-0">
              <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                Status
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7 pt-2">
                <base-switch type="success" offText="inativo" onText="ativo" class="success"
                  v-model="entityBankAccount.status"></base-switch>
              </div>
            </div> -->
            <div class="form-group row m-0 p-0">
              <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                Client Secret <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7 mb-1">
                <validation-provider rules="required" v-slot="{ errors }">
                  <base-input input-group-classes="input-group-sm">
                    <input type="password" class="form-control form-control-sm"
                      v-model="bankAccountPixParam.client_secret" :class="errors[0] ? 'is-invalid' : 'is-valid'" />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                Client ID <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7 mb-1">
                <validation-provider rules="required" v-slot="{ errors }">
                  <base-input input-group-classes="input-group-sm">
                    <input type="password" class="form-control form-control-sm" v-model="bankAccountPixParam.client_id"
                      :class="errors[0] ? 'is-invalid' : 'is-valid'" />
                  </base-input>
                </validation-provider>
              </div>
            </div>


            <div class="form-group row m-0 p-0">
              <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                Chave <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7 mb-1">
                <validation-provider rules="required" v-slot="{ errors }">
                  <base-input input-group-classes="input-group-sm">
                    <puzl-select v-model="entityBankAccount.key_type" :items="[
                      { id: 0, name: 'Cpf/Cnpj' },
                      { id: 1, name: 'Telefone' },
                      { id: 2, name: 'E-mail' },
                      { id: 3, name: 'Aleatória' },
                    ]" @change="clearKeyValue()" />
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                Código <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7 mb-1">
                <validation-provider rules="required" v-slot="{ errors }">
                  <base-input input-classes="form-control-sm">
                    <div v-if="entityBankAccount.key_type == keyType['ein']['type']">
                      <input type="text" class="form-control form-control-sm" v-model="bankAccountPixParam.pix_key"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                        v-mask="['###.###.###-##', '##.###.###/####-##']" />
                    </div>
                    <div v-else-if="entityBankAccount.key_type == keyType['phone']['type']">
                      <input type="text" class="form-control form-control-sm" v-model="bankAccountPixParam.pix_key"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'" v-mask="['(##) #####-####']" />
                    </div>
                    <div v-else-if="entityBankAccount.key_type == keyType['email']['type']">
                      <input type="email" class="form-control form-control-sm" v-model="bankAccountPixParam.pix_key"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'" />
                    </div>
                    <div v-else-if="entityBankAccount.key_type == keyType['random']['type']">
                      <input type="text" class="form-control form-control-sm" v-model="bankAccountPixParam.pix_key"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'" maxlength="80" />
                    </div>
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                Certificado CRT <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7 mb-1">
                <InputFile :maxFiles="1" :multiple="false" :accept="'.crt'" ref="certificate" />
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                Chave Privada <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7 mb-1">
                <InputFile :maxFiles="1" :multiple="false" :accept="'.key'" ref="private_key" />
              </div>
            </div>

            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()"> Cancelar </base-button>
              <base-button type="success" native-type="submit" :disabled="invalid" :loading="isLoadingStore"> Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
const { formatErrorValidation } = require("@/plugins");
import { mapGetters } from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
import InputFile from "@/components/Utils/InputFile";


export default {
  name: "ModalPixParamCreateEdit",
  components: {
    PuzlSelect,
    InputFile
  },
  data() {
    return {
      modal: {
        title: "Configurações do Pix",
        show: false,
      },
      isLoadingStore: false,
      bankAccountId: null,
      bankAccountPixParam: {
        client_id: null,
        client_secret: null,
        bank_account_id: null,
        pix_key: null,
      },
      entityBankAccount: {
        entity_id: null,
        name: '',
        status: true,
        key_type: null,
        key_value: null,
      },
      keyType: {
        'ein': {
          name: "Cpf/cnpj",
          type: 0,
        },
        'phone': {
          name: "Telefone",
          type: 1,
        },
        'email': {
          name: "E-mail",
          type: 2,
        },
        'random': {
          name: "Aleatória",
          type: 3,
        },
      },
    };
  },
  computed: {

  },
  mounted() {
    this.$refs.formValidator.validate();
  },
  methods: {
    setPrivateKeyFile() {
      this.bankAccountPixParam.private_key = this.$refs.private_key.files[0];
    },
    setCertificateFile() {
      this.bankAccountPixParam.certificate = this.$refs.certificate.files[0];
    },
    clearKeyValue() {
      this.entityBankAccount.key_value = null;
      this.bankAccountPixParam.pix_key = null;
    },
    closeModal() {
      this.modal.show = false;
    },
    handleCreateModal(bankAccountId) {
      this.bankAccountId = bankAccountId;
      this.$Progress.start();
      this.$store
        .dispatch("bankAccountPixParam/showByBankAccountId", this.bankAccountId)
        .then(response => {
          this.bankAccountPixParam = response.data;
          this.entityBankAccount.key_type = response.data.key_type;
          this.isDataCreating = false;
          this.modal.show = true;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.clearData();
          this.isDataCreating = true;
          this.modal.show = true;
          this.$Progress.finish();
        });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (cb) {
          var base64String = reader.result
            .replace('data:', '')
            .replace(/^.+,/, '');
          resolve(base64String);
        };
        reader.onerror = function (error) {
          reject('Error: ', error);
        };
      })
    },
    clearData() {
      this.bankAccountPixParam = {
        cliente_id: null,
        cliente_secret: null,
        bank_account_id: null
      };
    },
    async submit() {
      this.isLoadingStore = true;
      this.bankAccountId = this.bankAccountId;
      this.$Progress.start();

      let certificate = null;
      let private_key = null;
      if (this.$refs.certificate.fileRecords.length > 0) {
        certificate = await this.getBase64(this.$refs.certificate.fileRecords[0].file);
      }
      if(this.$refs.private_key.fileRecords.length > 0) {
        private_key = await this.getBase64(this.$refs.private_key.fileRecords[0].file);
      }

      let payload = {
        bank_account_id: this.bankAccountPixParam.bank_account_id ?? this.bankAccountId,
        data: {
          client_id: this.bankAccountPixParam.client_id,
          client_secret: this.bankAccountPixParam.client_secret,
          pix_key: this.bankAccountPixParam.pix_key,
          erp_account_bank_id: this.bankAccountPixParam.uuid_pix ?? "",
          key_type: this.entityBankAccount.key_type,
          certificate: certificate,
          private_key: private_key
        }
      }
      this.$store
        .dispatch("bankAccountPixParam/addOrUpdate", payload)
        .then((response) => {
          this.isLoadingStore = false;
          this.$Progress.finish();
          this.$notify({
            type: 'success',
            message: response.message
          });
          this.closeModal();
          this.$emit('refresh', this.bankAccountId)
        })
        .catch(error => {
          if (error.status === 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
            this.$Progress.finish();
            this.isLoadingStore = false;
            } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
            this.$Progress.finish();
            this.isLoadingStore = false;
            }
          this.$emit('refresh', this.bankAccountId)
        })
    }
  },
};
</script>

<style scoped></style>
