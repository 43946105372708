<template>
  <div>
    <modal :show.sync="modal">

      <template slot="header">
        <h5 class="modal-title">Replicar formulação</h5>
      </template>

      <div v-if="item">

        <div class="row align-items-center pb-0 mb-3">
          <div class="col-md-12">
            <div class="checklist-item checklist-item-primary mb-2">
              <div class="checklist-info pb-1">
                <h3 class="checklist-title mb-0" v-if="item.plant">
                  {{ item.plant.name || '' }}
                </h3>
                <h4 class="checklist-title mb-0 small">
                                <span v-if="item.type">
                                    {{ item.type.technical_description || '' }}
                                    <span v-if="item.subtype">
                                        {{ nextHeader(item.subtype.technical_description) }}
                                    </span>
                                </span>
                  <span v-if="item.subtype">
                                    {{ item.subtype.technical_description || '' }}
                                    <span v-if="item.classification">
                                        {{ nextHeader(item.classification.technical_description) }}
                                    </span>
                                </span>
                  <span v-if="item.classification">
                                    {{ item.classification.technical_description || '' }}
                                </span>
                </h4>
                <h4 class="checklist-title mb-2 small mt-2">
                                <span v-if="item.slumpFlow">
                                    {{ item.slumpFlow.nomenclature || '' }}
                                    <span v-if="item.maximum_size">
                                        {{ nextHeader(item.maximum_size.description) }}
                                    </span>
                                </span>
                  <span v-if="item.maximum_size">
                                    {{ item.maximum_size.description || '' }}
                                </span>
                </h4>
                <div class="row" v-for="feature in item.feature" :key="feature.id">
                  <div class="col-md-12">
                    <h5 class="h3 mb-0">
                      {{ feature.short_description }}
                      {{ format_mixe(feature.period, feature.period_type) }}
                      {{ feature.measured_unit ? feature.measured_unit.letter : '' }}
                    </h5>
                  </div>
                </div>

              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-left">
                <base-button outline
                             :class="binder_fixed && 'active'"
                             @click="binder_fixed = !binder_fixed">
                  <i v-if="!binder_fixed" class="far fa-square"></i>
                  <i v-else class="far fa-check-square"></i>
                  Fixar A/Agl.
                </base-button>
              </div>
            </div>
            <div class="border-header-default-bottom mt-3 mb-2 p-2">
              <h5 class="h4 mb-0 text-center">Ajustes (Parâmetros Técnicos)</h5>
            </div>
            <div class="p-2" v-if="technical_parameter">
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 m-0 p-0 col-form-label form-control-label">
                  <span class="text-default">Aglomerante</span>
                </label>
                <div class="col-md-5 m-0 p-0">
                  <base-input input-group-classes="input-group-sm">
                    <input class="form-control form-control-sm" inputmode="numeric" v-model="technical_parameter.binder"
                           ref="binder"/>
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0">%</small>
                    </template>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 m-0 p-0 col-form-label form-control-label">
                  <span class="text-default">Água</span>
                </label>
                <div class="col-md-5 m-0 p-0">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="technical_parameter.water" inputmode="numeric" class="form-control form-control-sm"
                           ref="water"/>
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0">
                        kg/m<sup>3</sup>
                      </small>
                    </template>
                  </base-input>
                </div>
              </div>

              <!--            <div class="form-group row m-0 p-0 mb-1">-->
              <!--              <label-->
              <!--                class="col-md-6 m-0 p-0 col-form-label form-control-label"-->
              <!--              >-->
              <!--                <span class="text-default">A/Agl.</span>-->
              <!--              </label>-->
              <!--              <div class="col-md-5 m-0 p-0">-->
              <!--                <base-input input-group-classes="input-group-sm">-->
              <!--                  <input-->
              <!--                    inputmode="numeric"-->
              <!--                    v-model.number="technical_parameter.water_binder"-->
              <!--                    class="form-control form-control-sm"-->
              <!--                    v-mask="['#.###']"-->

              <!--                  />-->
              <!--                </base-input>-->
              <!--              </div>-->
              <!--            </div>-->

              <div class="form-group row m-0 p-0 mb-1 mt-4">
                <label class="col-md-6 m-0 p-0 col-form-label form-control-label">
                  <span class="text-default">T. Arg. (v)</span>
                </label>
                <div class="col-md-5 m-0 p-0">
                  <base-input input-group-classes="input-group-sm">
                    <input inputmode="numeric" v-model="technical_parameter.content_mortar"
                           class="form-control form-control-sm" ref="content_mortar"/>
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0">
                        %
                      </small>
                    </template>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 m-0 p-0 col-form-label form-control-label">
                  <span class="text-default">Ar total</span>
                </label>
                <div class="col-md-5 m-0 p-0">
                  <base-input input-group-classes="input-group-sm">
                    <input inputmode="numeric" v-model="technical_parameter.total_air"
                           class="form-control form-control-sm" ref="total_air"/>
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0">
                        %
                      </small>
                    </template>
                  </base-input>
                </div>
              </div>
            </div>

            <div class="border-header-primary-bottom mt-3 mb-2 p-2">
              <h5 class="h4 mb-0 text-center">Ajustes (Teores e Quantidades)</h5>
            </div>

            <form class="mt-4 needs-validation p-2">
              <div v-for="(category, indexCategory) in item.groups" v-if="handleValidGroupChecks(category)"
                   :key="indexCategory">
                <h4 lass="mt-3" :class="errors.includes(category.id) ? 'text-danger' : ''">
                  {{ category.category_name }}
                </h4>
                <div class="form-group row m-0 p-0 mb-1" v-for="(group, index) in category.group_categories"
                     v-if="handleValidateCalculable(index, category)" :key="index">
                  <label class="col-md-6 m-0 p-0 col-form-label form-control-label">
                                    <span class="text-default h4">
                                        <small>{{ group.name }}</small>

                                    </span>
                  </label>
                  <div class="col-md-5 m-0 p-0 text-left">
                    <base-input input-group-classes="input-group-sm">
                      <input :ref="group.id" :value="group.value"
                             @input="group.value = teste($event.target.value, category.id, group.value, group.id)"
                             class="form-control form-control-sm"/>
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          {{ category_calculable[category.id] || '%' }}
                        </small>
                      </template>
                    </base-input>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <base-button type="secondary" @click.prevent="closeModal()">
          Cancelar
        </base-button>
        <base-button type="success" native-type="submit" :disabled="false" :loading="loadingStore"
                     @click.prevent="handleReplicate()">
          Salvar
        </base-button>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  nextHeader,
  period_types
} from "@/views/Modules/Technology/Mix/helpers/mixe"
import {
  formatErrorValidation
} from "@/plugins"
import {
  mask
} from "vue-the-mask";
import {
  checkNumberValue
} from '@/helpers'
import {
  mask1
} from '@/plugins'

export default {
  props: {
    item: {
      required: true,
    }
  },
  name: "ModalReplicateMixDesigns",
  data() {
    return {
      nextHeader: nextHeader,
      format_mixe: period_types,
      loadingStore: false,
      modal: false,
      binder_fixed: true,
      category_calculable: {
        5: "kg/m³",
        4: "% (S.P.A)"
      },
      errors: [],
      technical_parameter: {
        binder: "0",
        content_mortar: "0",
        total_air: "0",
        water: "0",
      },
      old_technical_parameter: {
        binder: "0",
        content_mortar: "0",
        total_air: "0",
        water: "0",
      },
      limits: {
        binder: {
          value: 50.0,
          decimal: 1
        },
        water: {
          value: 99,
          decimal: 0
        },
        total_air: {
          value: 20.0,
          decimal: 1
        },
        content_mortar: {
          value: 20.0,
          decimal: 1
        },
      },
      group_limits: {
        default: {
          value: 100,
          decimal: 0
        },
        1: {
          value: "100.0",
          decimal: 1
        },
        4: {
          value: "9.99",
          decimal: 2
        },
        5: {
          value: "99.99",
          decimal: 2
        },
      },
      limitable: {
        4: true,
        5: true
      },
      older_technical: null,
    }
  },
  computed: {
    GroupCategories: function () {
      return this.group_categories
    }

    // teste: function () {
    //     return JSON.stringify(this.group_categories)
    // }
  },
  mounted() {
    this.$watch('group_categories', function (newVal, oldVal) {
      this.$forceUpdate()
    }, {
      immediate: true,
      deep: true
    })
  },
  watch: {
    technical_parameter: {
      handler: function (after, before) {
        for (let index in after) {
          if (!this.$refs[index]) {
            continue
          }
          if (!before) {
            continue;
          }
          if (after[index] != "0" && after[index] != 0) {
            if (after[index].toString().charAt(0) == "-") {
              this.$refs[index].classList.remove('is-valid')
              this.$refs[index].classList.add('is-invalid')
            } else {
              this.$refs[index].classList.remove('is-invalid')
              this.$refs[index].classList.add('is-valid')
            }
          } else {
            this.$refs[index].classList.remove('is-invalid')
            this.$refs[index].classList.remove('is-valid')
          }
          const decimal = this.limits[index].decimal
          this.technical_parameter[index] = this.checkValueAndLimit(
            after[index],
            before[index],
            this.limits[index].value,
            decimal
          )
        }
      },
      deep: true
    },
  },
  methods: {
    closeModal() {
      this.modal = false
    },
    handleReplicateModal() {
      this.modal = true
    },
    teste(value, categoryId, oldvalue, ref) {
      if (value != "0" && value != 0) {
        if (value.toString().charAt(0) == "-") {
          this.$refs[ref][0].classList.remove('is-valid')
          this.$refs[ref][0].classList.add('is-invalid')
        } else {
          this.$refs[ref][0].classList.remove('is-invalid')
          this.$refs[ref][0].classList.add('is-valid')
        }
      } else {
        this.$refs[ref][0].classList.remove('is-invalid')
        this.$refs[ref][0].classList.remove('is-valid')
      }

      const decimal = this.group_limits[categoryId] && this.group_limits[categoryId].decimal || this.group_limits['default'].decimal
      const limit = this.group_limits[categoryId] && this.group_limits[categoryId].value || this.group_limits['default'].value

      const val = mask1(checkNumberValue(value), decimal)

      this.$refs[ref][0].value = val

      if (val > parseFloat(limit)) {
        this.$notify({
          type: 'danger',
          message: 'O valor do ajuste nao pode ser superior a ' + limit + '!'
        })
        this.$refs[ref][0].value = checkNumberValue(mask1(oldvalue, decimal))
        return checkNumberValue(mask1(oldvalue, decimal))
      }
      if (val < -parseInt(limit)) {
        this.$notify({
          type: 'danger',
          message: 'O valor do ajuste nao pode ser inferior a ' + limit + '!'
        })
        this.$refs[ref][0].value = checkNumberValue(mask1(oldvalue, decimal))
        return checkNumberValue(mask1(oldvalue, decimal))
      }

      return val
    },
    checkValueAndLimit(value, oldvalue, limit, decimal) {
      const str = limit.toString().length + decimal

      if (value > limit) {
        this.$notify({
          type: 'danger',
          message: 'O valor do ajuste nao pode ser superior a ' + limit + '!'
        })
        return checkNumberValue(mask1(oldvalue.substr(0, str), decimal, '.', true))
      }
      if (value < -limit) {
        this.$notify({
          type: 'danger',
          message: 'O valor do ajuste nao pode ser inferior a -' + limit + '!'
        })
        return checkNumberValue(mask1(oldvalue.substr(0, str + 1), decimal, '.', true))
      } else {
        return checkNumberValue(mask1(value, decimal, '.', true))
      }
    },
    handleValidGroupChecks(category) {
      // valida eh calculavel e se tem mais de 1 opcao
      if (!this.category_calculable[category.id] && category.groups.length > 1) {
        return true
      }
      // valida se nao eh calculavel
      return !!this.category_calculable[category.id];
    },
    handleValidateCalculable(index, category) {
      if (category.id === 99) {
        return false
      }
      // VALIDA SE É CALCULAVEL, SE FOR, REMOVE O PRIMEIRO INPUT
      if (category.calculable) {
        if (index === 0) {
          return false
        }
      }

      return true
    },
    handleReplicate() {
      let technical_parameter = null

      if (!this.$helper.Object.isEqual(this.old_technical_parameter,
        this.technical_parameter)) {
        technical_parameter = this.technical_parameter
      }
      this.loadingStore = true
      this.$Progress.start()

      let params = {
        uuid: this.item.uuid,
        technical_parameter: technical_parameter,
        group_categories: this.item.groups,
        binder_fixed : this.binder_fixed
      }

      this.$store.dispatch('MixDesigns/replicate', params).then(response => {
        // this.$store.dispatch('MixDesigns/fetchItems')
        this.loadingStore = false
        this.modal = false
        this.$Progress.finish()
        this.binder_fixed = true
        this.technical_parameter = {...this.old_technical_parameter}
        this.$emit('editFormulation', {uuid: response.data.uuid})
        this.$notify({
          type: response.error_type,
          message: response.message
        })
      }).catch(error => {
        if (error.status === 422) {
          let message = formatErrorValidation(error.response.data.errors)
          this.$notify({
            type: 'danger',
            message: message
          })
        } else {
          const {
            data
          } = error.data

          this.$notify({
            type: error.data.error_type,
            message: error.data.message
          })
          this.errors = data
        }
        this.loadingStore = false
        this.$Progress.finish()
      })
    },
  },
}
</script>

<style scoped>

</style>
