import moment from "moment";

export const headers = Object.freeze({
  taskMapping: {
    progress: "percent",
  },
  maxRows: 100,
  maxHeight: 500,
  title: {
    label: "Gantt - Viagens",
    html: false,
  },
  row: {
    height: 24,
  },
  scope: {
    before: 0,
    after: 0,
  },
  calendar: {
    workingDays: [1, 2, 3, 4, 5, 6, 7],
    hour: {
      height: 25,
      display: true,
      format: {
        long(date) {
          return generateTimeIntervals(date.format("HH:mm"));
        },
        medium(date) {
          return generateTimeIntervals(date.format("HH:mm"));
        },
        short(date) {
          return generateTimeIntervals(date.format("HH:mm"));
        },
      },
    },
    month: {
      display: false,
    },
  },
  chart: {
    progress: {
      bar: false,
    },
    expander: {
      display: true,
    },
    text: {
      xPadding: 25,
    },
  },
  times: {
    timeScale: 60 * 300,
    timeZoom: 2, //*
    timePerPixel: 0,
    firstTime: null,
    lastTime: null,
    firstTaskTime: 0,
    lastTaskTime: 0,
    totalViewDurationMs: 0,
    totalViewDurationPx: 0,
    stepDuration: "day",
    steps: [],
  },
  taskList: {
    expander: {
      straight: false,
    },
    columns: [
      {
        id: 1,
        label: "*",
        value: "index",
        width: 50,
        events: {
          click({ data }) {
            var current_url = window.location.href;
            var charging_url = current_url.split("schedule-travel-gantt")[0];
            charging_url = `${charging_url}schedule/charging/${data.uuid}`;
            window.open(charging_url);
          },
        },
      },
      {
        id: 2,
        label: "Descrição",
        value: "description",
        width: 200,
        html: true,
        events: {
          click({ data }) {
            EventBus.$emit("apply_contract_proposal_filter", data.contract_proposal_code);
          },
        },
      },
      {
        id: 3,
        label: "Peça",
        value: "concreted_piece_and_schedule_id",
        width: 100,
        html: true,
        events: {
          click({ data }) {
            EventBus.$emit("apply_schedule_filter", data.schedule_id);
          },
        },
      },
      {
        id: 4,
        label: "Equip.",
        value: "equipment_name",
        width: 60,
        html: true,
        events: {
          click({ data }) {
            EventBus.$emit("handle_modal_edit_schedule_travel", data);
          },
        },
      },
    ],
  },
  locale: {
    name: "pt-br",
    Now: "Agora",
    "X-Scale": "Zoom Horizontal",
    "Y-Scale": "Zoom Vertical",
    "Task list width": "Largura",
    "Before/After": "Expandir",
    "Display task list": "Mostrar dados",
    weekdays: "Domingo_Segunda_Terça_Quarta_Quinta_Sexta_Sábado".split("_"),
    months: "Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro".split("_"),
  },
});

function generateTimeIntervals(time) {
  let startTime = moment(time, "HH:mm");
  let intervals = [];

  for (let i = 0; i < 4; i++) {
    intervals.push(startTime.format("HH:mm"));
    startTime.add(15, "minutes");
  }

  return intervals.join(" | ");
}
