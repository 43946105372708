<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(update)" autocomplete="off">
            <div>
              <!-- Status -->
              <div>
                <div class="form-group row m-1 p-0">
                    <label class="col-md-6 pl-2 pt-2 pb-0 col-form-label form-control-label">Status </label>
                  <div class="col-md-6">
                    <base-switch type="success" offText="inativo" onText="ativo" class="success" v-model="item.status"></base-switch>
                  </div>
                </div>
              </div>
              <!-- Nome -->
              <div class="form-group row m-1 p-0">
                <label class="col-md-6 pl-2 pt-2 pb-0 col-form-label form-control-label">
                  Nome
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-6">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input 
                        v-model="item.name" 
                        type="text" 
                        class="form-control form-control-sm" 
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="mt-2">
                <div class="form-group row m-1 p-0">
                  <label class="col-md-6 pl-2 pt-2 pb-0 col-form-label form-control-label">
                    Tipo
                    <span class="text-danger mr-2">&nbsp;*</span>
                  </label>
                  <div class="col-md-6">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <base-input input-group-classes="input-group-sm">
                        <puzl-select v-model="item.product_service_hub_type_id" :items="[{id:3, name:'Produtos e Serviços'}, {id: 10, name: 'Taxas e Tributos'}]" :disabled="true" />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
              </div>
              <!-- Exige rateio de equipamento -->
              <div class="mt-2">
                <div class="form-group row m-1 p-0">
                  <label class="col-md-6 pl-2 pt-2 pb-0 col-form-label form-control-label">Exige rateio de equipamento </label>
                  <div class="col-md-6">
                    <base-switch type="success" offText="não" onText="sim" class="success" v-model="item.require_equipment_apportionment"></base-switch>
                  </div>
                </div>
              </div>
               <!-- Itens de abastecimento -->
              <div>
                <div class="form-group row m-1 p-0">
                  <label class="col-md-6 pl-2 pt-2 pb-0 col-form-label form-control-label">Itens de abastecimento </label>
                  <div class="col-md-6">
                    <base-switch type="success" offText="não" onText="sim" class="success" v-model="item.is_fuel"></base-switch>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <base-button type="secondary" @click="closeModal('create')"> Cancelar </base-button>
                <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore"> Salvar </base-button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatErrorValidation } from "@/plugins";
import PuzlSelect from "@/components/PuzlSelect";
export default {
  name: "EditProductServiceCategory",
  components: { PuzlSelect },
  data() {
    return {
      modal: {
        title: "Categoria de Despesas",
        create: false,
      },
      loadingStore: false,
    };
  },
  computed: {
    ...mapGetters({
      item: "productServiceCategory/show",
    }),
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    handleEditModal(id) {
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });
      let loader = this.$loading.show();
      this.$store.dispatch("productServiceCategory/show", id).then((response) => {
        loader.hide();
        this.$notify({
          type: response.error_type,
          message: response.message,
        });
        this.modal.create = true;
      }).catch((error) => {
        loader.hide();
      });
    },
    update() {
      this.$Progress.start();
      this.loadingStore = true;
      this.$store
        .dispatch("productServiceCategory/update", this.item)
        .then((response) => {
          this.loadingStore = false;
          this.modal.create = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
        })
        .catch((error) => {
          if (error.status == 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
          this.$Progress.finish();
          this.loadingStore = false;
        });
    },
  },
};
</script>

<style scoped></style>
