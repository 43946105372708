<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div v-if="payload.product_name" class="border-left border-3 border-success pr-1 mt-0 mb-3">
        <h4 class="mb-0 ml-2">{{ payload.product_name }}</h4>
      </div>
      <div v-if="payload.entity_id" class="border-left border-3 border-danger pr-1 mt-2 mb-3">
        <div class="ml-2">
          <h4 class="m-0">{{ payload.entity_name }}</h4>
          <h4 class="m-0 font-weight-normal">{{ payload.entity_document }}</h4>
        </div>
      </div>
      <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
        <form class="needs-validation" @submit.prevent="handleSubmit(update)" autocomplete="off">
          <div class="card-header border-header-success-bottom p-2 grid-col--fixed-left mb-2">
            <h5 class="mb-0" slot="title">Identificação</h5>
          </div>
          <div class="p-2">
            <!-- Data -->
            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pl-md-2 pt-2 pb-0 col-form-label form-control-label">
                Data
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                    <InputDateTimePicker
                      @input="getFuelDataByDateAndEquipment"
                      :disabled="true"
                      mode="dateTime"
                      ref="datePicker"
                      v-model="payload.date" />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!-- Central -->
            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pl-md-2 pt-2 pb-0 col-form-label form-control-label">
                Central
                <span class="text-danger mr-2">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                    <puzl-select @input="changedCompanyPlant" v-model="payload.company_plant_id" :items="$_company_plants" />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!-- Motorista -->
            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pl-md-2 pt-2 pb-0 col-form-label form-control-label">
                Motorista
                <span class="text-danger mr-2">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                    <puzl-select
                      v-if="type !== 'internal_others'"
                      :disabled="loadingDriver"
                      v-model="payload.driver_id"
                      :items="$_drivers"
                    />
                    <input v-else v-model="payload.driver_observations" type="text" class="form-control form-control-sm" maxlength="80" />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!-- Equipamento | Placa -->
            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pl-md-2 pt-2 pb-0 col-form-label form-control-label">
                {{ type !== "internal_others" ? "Equipamento" : "Placa" }}
                <span class="text-danger mr-2">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                    <puzl-select
                      v-if="type !== 'internal_others'"
                      :disabled="loadingEquipment"
                      @input="getFuelDataByDateAndEquipment"
                      v-model="payload.equipment_id"
                      :items="$_equipments"
                      label="$code$ - $plate$"
                      :labelMask="true"
                    />
                    <input v-else v-model="payload.plate" type="text" class="form-control form-control-sm" maxlength="7" />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!-- Km Painel -->
            <div v-if="type !== 'internal_others'" class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pl-md-2 pt-2 pb-0 col-form-label form-control-label">
                Km Painel
                <span v-show="!payload.hour_meter" class="text-danger mr-4">&nbsp;*</span>
                <el-popover v-show="previousKmPanel" trigger="hover" placement="bottom" class="p-0 float-right pr-0 ml-3">
                  <span> {{ previousKmPanel }} Km </span>
                  <base-button outiline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none">
                   <i style="font-size: 17px !important" class="fas fa-angles-left p-0 text-primary" />
                  </base-button>
                </el-popover>
              </label>
              <div class="col-md-7">
                <validation-provider :rules="{required : !payload.hour_meter}">
                  <input-limit
                    :options="{
                      min: 0,
                    }"
                    max_length="9"
                    :start_value="payload.km_panel"
                    v-model="payload.km_panel"
                    input_group_classes="input-group-sm pb-0"
                    @value="payload.km_panel = $event === '-' ? null : $event"
                  >
                    <small class="input-group-sm p-0 m-0"> Km </small>
                  </input-limit>
                </validation-provider>
              </div>
            </div>
            <!-- Horímetro -->
            <div v-if="type !== 'internal_others'" class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pl-md-2 pt-2 pb-0 col-form-label form-control-label">
                Horímetro
                <span v-show="!payload.km_panel" class="text-danger mr-4">&nbsp;*</span>
                <el-popover v-show="previousHourMeter" trigger="hover" placement="bottom" class="p-0 float-right pr-0 ml-3">
                  <span> {{ previousHourMeter }} Hora(s) </span>
                  <base-button outiline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none">
                   <i style="font-size: 17px !important" class="fas fa-angles-left p-0 text-primary" />
                  </base-button>
                </el-popover>
              </label>
              <div class="col-md-7">
                <validation-provider :rules="{required : !payload.km_panel}">
                  <input-limit
                    @change="getTravelledHour"
                    :options="{
                      min: 0,
                      decimals: 1,
                    }"
                    max_length="12"
                    :start_value="payload.hour_meter"
                    v-model="payload.hour_meter"
                    @value="payload.hour_meter = $event === '-' ? null : $event"
                  >
                    <small class="input-group-sm p-0 m-0"> Horas </small>
                  </input-limit>
                </validation-provider>
              </div>
            </div>
            <div class="card-header border-header-indigo-bottom p-2 grid-col--fixed-left mb-2 mt-2">
              <h5 class="mb-0" slot="title">Abastecimento</h5>
            </div>
            <!-- Combustível -->
            <div v-if="type === 'external'" class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pl-md-2 pt-2 pb-0 col-form-label form-control-label">
                Combustível
                <span class="text-danger mr-2">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                    <puzl-select v-model="payload.product_service_hub_id" :items="$_products_services" />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!-- Reservatório | Preço  -->
            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pl-md-2 pt-2 pb-0 col-form-label form-control-label">
                {{ type !== "external" ? "Reservatório" : "Preço / Litro" }}
                <span class="text-danger mr-2">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required">
                  <base-input v-if="type !== 'external'" input-group-classes="input-group-sm">
                    <puzl-select @input="fillStartLiter" :disabled="loadingFuelTank" v-model="payload.fuel_tank_id" :items="$_fuel_tanks" />
                  </base-input>
                  <div v-else>
                    <base-input input-group-classes="input-group-sm">
                      <input
                        @blur="getTotal"
                        v-money="{decimal: ',', thousands: '.', precision: 3}"
                        maxlength="10"
                        inputmode="numeric"
                        v-model.lazy="payload.price_per_liter"
                        @keydown="$event.key === '-' ? $event.preventDefault() : null"
                        class="form-control form-control-sm"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                </validation-provider>
              </div>
            </div>
            <!-- Fornecedor -->
            <div v-if="type !== 'external'" class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pl-md-2 pt-2 pb-0 col-form-label form-control-label">
                Fornecedor
                <span class="text-danger mr-2">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                    <puzl-select
                      :disabled="!payload.fuel_tank_id"
                      v-model="payload.entry_entity_id"
                      :items="entities"
                      label="entity_name"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!-- Leitor Inicial | Total Litros -->
            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pl-md-2 pt-2 pb-0 col-form-label form-control-label">
                {{ type !== "external" ? "Leitor Inicial" : "Total (Litros)" }}
                <span class="text-danger mr-2">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required|min_value:0">
                  <input-limit
                    v-if="type !== 'external'"
                    :options="{
                      min: 0,
                      decimals: 2,
                    }"
                    disabled
                    @change="getTotal"
                    input_group_classes="input-group-sm pb-0"
                    max_length="9"
                    :start_value="payload.start_liter"
                    v-model="payload.start_liter"
                    @value="payload.start_liter = $event === '-' ? null : $event"
                  >
                    <small class="input-group-sm p-0 m-0"> Litros </small>
                  </input-limit>
                  <input-limit
                    v-else
                    :options="{
                      min: 0,
                      decimals: 3,
                    }"
                    @change="getTotal"
                    input_group_classes="input-group-sm pb-0"
                    max_length="9"
                    :start_value="payload.total_liter"
                    v-model="payload.total_liter"
                    @value="payload.total_liter = $event === '-' ? null : $event"
                  >
                    <small class="input-group-sm p-0 m-0"> Litros </small>
                  </input-limit>
                </validation-provider>
              </div>
            </div>
            <!-- Leitor Final -->
            <div v-if="type !== 'external'" class="form-group row m-0 p-0 mb-0">
              <label class="col-md-5 pl-md-2 pt-2 pb-0 col-form-label form-control-label">
                Leitor Final
                <span class="text-danger mr-2">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required|min_value:1">
                  <base-input input-group-classes="input-group-sm">
                    <input-limit
                      :options="{
                        min: 0,
                        decimals: 2,
                      }"
                      max_length="9"
                      @change="getTotal"
                      :start_value="payload.end_liter"
                      input_group_classes="input-group-sm pb-1"
                      v-model="payload.end_liter"
                      @value="payload.end_liter = $event === '-' ? null : $event"
                      disabled
                    >
                      <small class="input-group-sm p-0 m-0"> Litros </small>
                    </input-limit>
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!-- Total -->
            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pl-md-2 pt-2 pb-0 col-form-label form-control-label">
                Total
                <span class="text-danger mr-2">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider v-if="type !== 'external'" rules="required|min_value:0|max_value:999.9">
                  <base-input input-group-classes="input-group-sm">
                    <input @change="getFinalValue" v-model="payload.total_liter" disabled type="text" class="form-control form-control-sm" />
                  </base-input>
                </validation-provider>
                <div v-else>
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-money="money"
                      maxlength="10"
                      inputmode="numeric"
                      disabled
                      v-model.lazy="payload.total_value"
                      class="form-control form-control-sm"
                    />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0"> R$ </small>
                    </template>
                  </base-input>
                </div>
              </div>
            </div>
            <!-- Observações -->
            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pl-md-2 pt-2 pb-0 col-form-label form-control-label"> Observações </label>
              <div class="col-md-7">
                <base-input input-group-classes="input-group-sm">
                  <input v-model="payload.observations" type="text" class="form-control form-control-sm" maxlength="200" />
                </base-input>
              </div>
            </div>
            <div v-if="type === 'external'">
              <div class="card-header border-header-danger-bottom p-2 grid-col--fixed-left mb-2">
                <h5 class="mb-0" slot="title">Documento</h5>
              </div>
              <!-- Número do Documento  -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pl-md-2 pt-2 pb-0 col-form-label form-control-label">
                  Núm. do Documento
                  <span class="text-danger mr-2">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required">
                    <input-limit
                      :options="{
                        min: 0,
                      }"
                      max_length="15"
                      input_group_classes="input-group-sm pb-0"
                      :start_value="payload.document_number"
                      v-model="payload.document_number"
                      @value="payload.document_number = $event === '-' ? null : $event"
                    />
                  </validation-provider>
                </div>
              </div>
              <!-- Série -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pl-md-2 pt-2 pb-0 col-form-label form-control-label">
                  Série
                  <span class="text-danger mr-2">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required">
                    <input v-model="payload.series" type="text" class="form-control form-control-sm" maxlength="30" />
                  </validation-provider>
                </div>
              </div>
              <!-- Imagem -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pl-md-2 pt-2 pb-0 col-form-label form-control-label"> Imagem </label>
                <div class="col-md-8">
                  <InputFile :multiple="false" ref="fuelControlAttachment" />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')"> Cancelar </base-button>
              <base-button
                type="success"
                native-type="submit"
                :disabled="invalid || (payload.status === 2 && payload.price_per_liter === '0,00') || payload.total_value === '0,00'"
                :loading="loadingStore"
              >
                Salvar
              </base-button>
            </div>
          </div>
        </form>
      </validation-observer>
    </modal>
  </div>
</template>

<script>
import { formatErrorValidation } from "@/plugins";
import PuzlSelect from "@/components/PuzlSelect";
import { mapGetters } from "vuex";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import InputLimit from "@/components/Utils/InputLimit";
import { VMoney } from "v-money";
import fuelControlMixin from "../Mixins/fuel_control_mixin";
import InputFile from "@/components/Utils/InputFile";

export default {
  name: "ModalEdit",
  components: { PuzlSelect, InputDateTimePicker, InputLimit, InputFile },
  mixins: [fuelControlMixin],
  data() {
    return {
      modal: {
        title: "Controle de Abastecimento",
        create: false,
      },
      type: null,
      payload: {
        status: null,
        external: null,
        own_fleet: null,
        date: null,
        company_plant_id: null,
        driver_id: null,
        equipment_id: null,
        km_panel: null,
        fuel_tank_id: null,
        start_liter: null,
        end_liter: null,
        total_liter: null,
        total_value: null,
        price_per_liter: null,
        hour_meter: null,
        travelled_distance: null,
        product_service_hub_id: null,
        product_name: null,
        volume: null,
        observations: null,
        driver_observations: null,
        plate: null,
        entity_id: null,
        document_number: null,
        serie: null,
        photo: null,
      },
      entities: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
    };
  },
  directives: { money: VMoney },
  computed: {
    ...mapGetters({
      $_company_plants: "plant/activeItems",
      $_drivers: "user/getDrivers",
      $_equipments: "equipment/fetch",
      $_fuel_tanks: "fuelTank/fetch",
      $_user: "auth/getUser",
      $_products_services: "productService/fetch",
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal(id) {
      let loader = this.$loading.show();
      this.loadingDriver = true;
      this.loadingEquipment = true;
      this.loadingFuelTank = true;
      this.type = "external";
      this.previousKmPanel = null;
      this.previousHourMeter = null;
      this.entities = [];
      this.$store.dispatch("fuelControl/show", id).then((response) => {
        loader.hide();
        this.$refs.fuelControlAttachment.fileRecords = [];
        this.payload = response.data;
        this.$notify({
          type: response.error_type,
          message: response.message,
        });
        let fuel_tank = null;
        switch (true) {
          case this.payload.external === 0 && this.payload.own_fleet === 1:
            /* tipo interno e próprio */
            this.type = "internal_own";
            fuel_tank = this.$_fuel_tanks.find(item => item.id === this.payload.fuel_tank_id);
            this.entities = fuel_tank ? fuel_tank.entities : [];
            break;
          case this.payload.external === 0 && this.payload.own_fleet === 0:
            /* tipo interno e terceiros */
            this.type = "internal_others";
            fuel_tank = this.$_fuel_tanks.find(item => item.id === this.payload.fuel_tank_id);
            this.entities = fuel_tank ? fuel_tank.entities : [];
            break;
          default:
            /* tipo externo */
            this.type = "external";
            if(response.data.photo) {
               this.$refs.fuelControlAttachment.setFileByObject(JSON.parse(response.data.photo), true);
            }
            this.getProductServiceHub();
        }
        this.changedCompanyPlant(false);
        this.modal.create = true;
      });
    },
    update() {
      this.loadingStore = true;
      this.$store
        .dispatch("fuelControl/update", this.finalFormat('put'), {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.loadingStore = false;
          this.modal.create = false;
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
        })
        .catch((error) => {
          if (error.status == 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
          this.$Progress.finish();
          this.loadingStore = false;
        });
    },
  },
};
</script>
