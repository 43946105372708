import { computed, getCurrentInstance } from "vue";
import { DosageAdjustmentByIdType, initAgentType, AgentType, CmcAdjustmentListType } from "../types";
import CmcAdjustmentLimitTypeEnum from "../enums/CmcAdjustmentLimitTypeEnum";

export function useCmcAdjustmentHook() {
  const store = getCurrentInstance().proxy.$store;

  /**
   * SEÇÃO DE FILTROS
   * Grupos mcc
   */
  store.dispatch("cmcGroup/fetchItems");
  const cmcGroups = computed(() => store.getters["cmcGroup/fetch"]);

  /**
   * Categorias mcc
   */
  store.dispatch("category/fetchItems");
  const cmcCaterories = computed(() => store.getters["category/fetch"]);

  /**
   * Produtos mcc
   */
  store.dispatch("cmcProduct/fetchItems");
  const cmcProducts = computed(() => store.getters["cmcProduct/fetch"]);

  /**
   * Centrais
   */
  store.dispatch("plant/fetchItemsActive");
  const companyPlants = computed(() => store.getters["plant/activeShortItems"]);

  /**
   * SEÇÃO DE AJUSTES
   * Limites de Mapa do Ponto de Carga
   */
  store.dispatch("adjustmentLimitSetting/getByType", 6);
  const allLimits = computed(
    () => store.getters["adjustmentLimitSetting/show"]
  );

  /**
   * Grupos de Aditivos
   */
  store.dispatch("cmcGroup/fetchGroupByCategory", 4);
  const agentGroups = computed(
    () => store.getters["cmcGroup/showGroupByCategory"]
  );

  /**
   * Validação dos valores de ajuste
   * @param {number} limit_type
   * @param {number} value
   */
  function validateValueFromLimit(limit_type, value) {
    if (limit_type === CmcAdjustmentLimitTypeEnum.keys.BINDER) {
      if (value < parseInt(allLimits.value.binder.bottom)) {
        return parseInt(allLimits.value.binder.bottom);
      } else if (value > parseInt(allLimits.value.binder.top)) {
        return parseInt(allLimits.value.binder.top);
      } else {
        return value;
      }
    } else if (limit_type === CmcAdjustmentLimitTypeEnum.keys.WATER) {
      if (value < parseInt(allLimits.value.water.bottom)) {
        return parseInt(allLimits.value.water.bottom);
      } else if (value > parseInt(allLimits.value.water.top)) {
        return parseInt(allLimits.value.water.top);
      } else {
        return value;
      }
    } else if (limit_type === CmcAdjustmentLimitTypeEnum.keys.CONTENT_MORTAR) {
      if (value < parseFloat(allLimits.value.content_mortar.bottom)) {
        return parseFloat(allLimits.value.content_mortar.bottom);
      } else if (value > parseFloat(allLimits.value.content_mortar.top)) {
        return parseFloat(allLimits.value.content_mortar.top);
      } else {
        return value;
      }
    } else if (limit_type === CmcAdjustmentLimitTypeEnum.keys.TOTAL_AIR) {
      if (value < parseFloat(allLimits.value.total_air.bottom)) {
        return parseFloat(allLimits.value.total_air.bottom);
      } else if (value > parseFloat(allLimits.value.total_air.top)) {
        return parseFloat(allLimits.value.total_air.top);
      } else {
        return value;
      }
    } else if (limit_type === CmcAdjustmentLimitTypeEnum.keys.AGENT) {
      if (value < parseFloat(allLimits.value.agent.bottom)) {
        return parseFloat(allLimits.value.agent.bottom);
      } else if (value > parseFloat(allLimits.value.agent.top)) {
        return parseFloat(allLimits.value.agent.top);
      } else {
        return value;
      }
    } else if (limit_type === CmcAdjustmentLimitTypeEnum.keys.CONTENT) {
      if (value < parseInt(-100)) {
        return parseInt(-100);
      } else if (value > parseInt(100)) {
        return parseInt(100);
      } else {
        return value;
      }
    } else if (limit_type === CmcAdjustmentLimitTypeEnum.keys.CONTENT_MIN) {
      if (value < parseInt(0)) {
        return parseInt(0);
      } else if (value > parseInt(100)) {
        return parseInt(100);
      } else {
        return value;
      }
    } else if (limit_type === CmcAdjustmentLimitTypeEnum.keys.CONTENT_MAX) {
      if (value < parseInt(0)) {
        return parseInt(0);
      } else if (value > parseInt(100)) {
        return parseInt(100);
      } else {
        return value;
      }
    }
  }

  /**
   * @param {DosageAdjustmentByIdType} dosages
   */
  function resetAdjustmentValues(dosages) {
    dosages.binder = 0;
    dosages.water = 0;
    dosages.content_mortar = 0;
    dosages.total_air = 0;
    dosages.agent = formatNewAgent();
    dosages.observation = null;
  }

  /**
   * @param {CmcAdjustmentListType} adjustment
   */
  function resetContentValues(adjustment) {
    adjustment.content = null;
    adjustment.content_min = null;
    adjustment.content_max = null;
    adjustment.observation_content = null;
  }

  /**
   *
   * @returns {Array<AgentType>}
   */
  function formatNewAgent() {
    return [
      {
        ...initAgentType(),
      },
      {
        ...initAgentType(),
      },
    ];
  }

  /**
   * @param {number} value
   * @param {number} limit_type
   * @returns {number} value
   */
  function toNegative(limit_type, value) {
    return validateValueFromLimit(limit_type, -Math.abs(value));
  };

  /**
   * @param {number} value
   * @param {number} limit_type
   * @returns {number} value
   */
  function toPositive(limit_type, value) {
    return validateValueFromLimit(limit_type, Math.abs(value));
  };

  return {
    cmcGroups,
    cmcCaterories,
    cmcProducts,
    companyPlants,
    validateValueFromLimit,
    allLimits,
    agentGroups,
    resetAdjustmentValues,
    resetContentValues,
    formatNewAgent,
    toNegative,
    toPositive
  };
}