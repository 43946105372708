<template>
  <div>
    <div>
      <AppFixedPageTitle
        title="Carteira de clientes"
        icon="/img/icons/client-company-white.png"
      />
      <AppPageHeader class="ml-4 mb-n3 mt-5">
        <template slot="search-bar">
          <AppSearchBar
            :searchBarFilter.sync="searchBarFilter"
            :showCompanyPlants="true"
            :allowClearCompanyPlantFilter="false"
            @onSearchClick="init(false)"
            @onClearClick="clearFilter"
          >
            <AppSearchBarFilterSection
              style="height: 370px !important"
              name="Dados"
              icon="/img/icons/icons8/ios/info-squared_gray.png"
            >
              <div class="col-md-12 mt-1 mb-2 px-0 text-left">
                <base-input label="Data de criação" input-classes="form-control-sm">
                  <input-date-picker
                    :hideAllStyles="true"
                    v-model="filter.created_at"
                    size="small"
                  />
                </base-input>
              </div>
              <div class="col-md-12 mt-1 mb-2 px-0 text-left">
                <base-input label="Vendedor" input-classes="form-control-sm">
                  <PuzlSelectSearch
                    :value="filter.seller_id"
                    :loading="loadingSellers"
                    v-model="filter.seller_id"
                    @search="handleFilterSeller"
                    @input="init(false)"
                    clearable
                    class="select-xl new-default-black-font"
                    :items="$_sellers"
                  />
                </base-input>
              </div>
            </AppSearchBarFilterSection>
          </AppSearchBar>
        </template>
      </AppPageHeader>
    </div>
    <div class="container-fluid mt-2">
      <div class="row">
        <div class="col-md-12">
          <div class="card" style="z-index: 0 !important;margin-top: -75px;">
            <div class="card-body">
              <template>
                <div v-if="items.length > 0" class="row">
                  <div class="col-md-7 pl-3">
                  </div>
                  <div class="col-md-5 px-1 pr-3">
                    <table class=" table table-sm padding-th">
                      <thead>
                      <tr style="border: none;">
                        <th class="text-table-entity-wallet"
                            style="border-left: none;border-top: none;border-top-left-radius: 10px;background: #f5f5f5 !important;!i;!;border-right: 1px solid rgba(211, 202, 202, 0.55);">
                          <a href="#" @click.prevent="handleGoBackMonth()">
                            <i v-if="validMonthGoBack()" class="fa-solid fa-arrow-left"
                               style="font-size: 12px;margin-left: 20px"></i>
                            <i v-if="!validMonthGoBack()" class="text-muted fa-solid fa-arrow-left"
                               style="font-size: 12px;margin-left: 20px"></i>
                          </a>
                        </th>
                        <th class="text-right text-table-entity-wallet"
                            style="border-right: none;border-top: none;border-top-right-radius: 10px;background: #f5f5f5 !important;border-right: 1px solid rgba(211, 202, 202, 0.55);border: none;">
                          <a href="#" @click.prevent="handleAdvanceMonth()">
                            <i v-if="validMonthAdvance()" class="fa-solid fa-arrow-right"
                               style="font-size: 12px;margin-right: 20px"></i>
                            <i v-if="!validMonthAdvance()" class="text-muted fa-solid fa-arrow-right"
                               style="font-size: 12px;margin-right: 20px"></i>
                          </a>
                        </th>
                      </tr>
                      </thead>
                    </table>
                  </div>
                </div>
                <div v-show="items.length > 0" class="row " style="overflow-y: scroll;max-height: 70vh">
                  <div
                    class="col-md-7 table-responsive px-1 pl-3">
                    <table class="table table-bordered table-sm padding-th">
                      <thead>
                      <tr style="border: none">
                        <th rowspan="1" colspan="5"
                            style="border: none">
                          &nbsp;
                        </th>
                      </tr>
                      <div style="margin-top: 2px"></div>
                      <tr style="width: 100%">
                        <th style="border: none;border-top-left-radius: 5px;width: 30%"
                            class=" text-uppercase bg-aqua text-table-entity-wallet ">
                          TOTAL
                        </th>
                        <th style="width: 28%" class="text-uppercase bg-aqua text-table-entity-wallet">
                          {{ $_widgets.constructions_count }} CONTRATOS
                        </th>
                        <th style="width: 18%" class=" text-uppercase bg-aqua text-table-entity-wallet">
                          {{ $_widgets.sellers_count }} VENDEDORES
                        </th>
                        <th style="width: 12%" class="text-uppercase text-center bg-aqua text-table-entity-wallet ">
                          {{ $_widgets.estimated_volume || 0 }}
                        </th>
                        <th style="width: 12%;border: none;border-top-right-radius: 5px"
                            class=" text-uppercase text-center bg-aqua text-table-entity-wallet">
                          {{ $_widgets.schedule_travel_volume_sum || 0 }}
                        </th>
                      </tr>
                      <tr>
                        <th style="width: 30%;max-width: 0;border: none;border-bottom-left-radius: 5px"
                            class=" text-uppercase bg-grey text-table-entity-wallet">
                          CLIENTE
                        </th>
                        <th style="width: 28%;max-width: 0" class=" text-uppercase bg-grey text-table-entity-wallet">
                          OBRA
                        </th>
                        <th style="width: 18%;max-width: 0" class=" text-uppercase bg-grey text-table-entity-wallet">
                          VENDEDOR
                        </th>
                        <th class="text-uppercase text-center bg-grey text-table-entity-wallet"
                            style=";display: flex">
                          <div @click.prevent="setOrderBy('order_by_original')"
                               class="order pointer d-flex text-table-entity-wallet"
                               style="font-weight: 500 !important;">
                            ORIGINAL
                            <i style="font-size: 12px" v-if="!filter.order_by_original"
                               class="fa-solid fa-arrow-up-9-1"></i>
                            <i v-if="filter.order_by_original === 'asc'"
                               class="fa-solid fa-arrow-up"
                               style="font-size: 12px;opacity: 60% !important;"></i>
                            <i v-if="filter.order_by_original === 'desc'"
                               class="fa-solid fa-arrow-down"
                               style="font-size: 12px;opacity: 60% !important;"></i>
                          </div>
                        </th>
                        <th style="width: 12%;max-width: 0;border: none;border-bottom-right-radius: 5px"
                            class=" text-uppercase text-center bg-grey text-table-entity-wallet">
                          SALDO
                        </th>
                      </tr>
                      </thead>
                    </table>
                  </div>
                  <div v-for="(item, key) in months"
                       class="col-md table-responsive px-1 "
                       style="margin-top: -1px !important"
                       :class="months[months.length -1].id === item.id && 'pr-3'">
                    <table class="table table-bordered table-sm padding-th">
                      <thead>
                      <tr>
                        <td class="td-truncate text-center pointer text-table-entity-wallet"
                            style="width: 10%; max-width: 0px;"><i class="fa-solid fa-calendar-day"></i>
                        </td>
                        <td class="td-truncate text-center pointer text-table-entity-wallet"
                            style="width: 90%; max-width: 0px;"> {{ item.name }}
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 50%" class="td-truncate  text-center pointer text-table-entity-wallet bg-aqua"
                        > {{ getPredictedTotalByMonth(item.id) }}
                        </td>
                        <td class="td-truncate text-center text-table-entity-wallet bg-aqua"
                        > {{ getAccomplishedOrBalance(item.id) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="td-truncate text-center  text-table-entity-wallet bg-grey">
                          <div
                            @click.prevent="setOrderByPredicted(item.id)"
                            class="order pointer d-flex text-table-entity-wallet" style="font-weight: 500 !important;">
                            PREVISTO
                            <i style="font-size: 12px" v-if="!filter.order_by_predicted[item.id]"
                               class="fa-solid fa-arrow-up-9-1"></i>
                            <i v-if="filter.order_by_predicted[item.id] === 'asc'"
                               class="fa-solid fa-arrow-up"
                               style="font-size: 12px;opacity: 60% !important;"></i>
                            <i v-if="filter.order_by_predicted[item.id] === 'desc'"
                               class="fa-solid fa-arrow-down"
                               style="font-size: 12px;opacity: 60% !important;"></i>
                          </div>
                        </td>

                        <td v-if="item.id == new Date().getMonth() + 1 || item.id < new Date().getMonth() + 1"
                            class="td-truncate text-center  text-table-entity-wallet bg-grey"
                        > REALIZADO
                        </td>
                        <td v-else class="td-truncate  text-center text-table-entity-wallet bg-grey"
                        > SALDO
                        </td>
                      </tr>
                      </thead>
                    </table>
                  </div>
                </div>
                <div v-show="items.length > 0" id="items" class="mb-2 table-responsive"
                     style="margin-top: 0px;height: 52vh;overflow-x: hidden !important;">
                  <div class="row mt-1">
                    <div class="col-md-7 table-responsive px-1 pl-3">
                      <table class="table table-bordered table-sm padding-th ">
                        <thead>
                        <tr style="width: 100% !important;" v-for="(item, key) in items" :key="key">
                          <td style="width: 30%;max-width: 0;border-bottom-left-radius: 5px"
                              class="td-truncate text-table-entity-wallet">
                            {{
                              item.entity_name
                            }}
                          </td>
                          <td @click.prevent="handlePortfolioProjection(
                      item.id,
                      [
                        item.contract_proposal_code,
                        item.customer_name,
                        item.construction_name,
                        item.company_plant_id
                      ]
                    )" style="width: 28%;max-width: 0;" class="td-truncate pointer text-table-entity-wallet">
                            {{
                              item.contract_proposal_code
                            }} | {{ item.construction_name }}
                          </td>
                          <td @click.prevent="setSellerFilter(item.seller_id, item.seller_name)" style="width: 18%;max-width: 0;"
                              class="pointer td-truncate text-table-entity-wallet">
                            {{
                              item.seller_name
                            }}
                          </td>
                          <td @click="handleEditConstruction(item.construction_uuid)"
                              style="width: 12%;max-width: 0;"
                              class="td-truncate text-center pointer bg-grey text-table-entity-wallet">
                            {{
                              item.estimated_volume || 0
                            }}
                          </td>
                          <td style="width: 12%;max-width: 0;"
                              class="td-truncate text-center bg-grey text-table-entity-wallet">
                            {{
                              item.performed_volume || 0
                            }}
                          </td>
                        </tr>
                        </thead>
                      </table>
                    </div>
                    <div v-for="(item, key) in months"
                         class="col-md table-responsive px-1"
                         :class="months[months.length -1].id === item.id && 'pr-3'">
                      <table class="table table-bordered table-sm padding-th">
                        <thead>
                        <tr v-for="month in items">
                          <td @click.prevent="handleCreateProjectionModal(month, item.id)"
                              style="width: 50%;max-width: 0;"
                              class="td-truncate text-center pointer text-table-entity-wallet">
                            {{ getPredictionByMonthOnItems(month, item.id) }}
                          </td>
                          <td style="width: 50%;max-width: 0;" class="td-truncate text-center text-table-entity-wallet">
                            {{ getAccomplishedOrBalanceItem(month, item.id) }}
                          </td>
                        </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="loading">
                <div class="row mt-6">
                  <div class="col-md-7 px-2">
                    <SkeletonPuzl type="button"></SkeletonPuzl>
                    <SkeletonPuzl type="button"></SkeletonPuzl>
                    <br>
                    <SkeletonPuzl class="mt-n4" type="button"></SkeletonPuzl>
                    <SkeletonPuzl type="button"></SkeletonPuzl>
                    <SkeletonPuzl type="button"></SkeletonPuzl>
                    <SkeletonPuzl type="button"></SkeletonPuzl>
                    <SkeletonPuzl type="button"></SkeletonPuzl>
                  </div>
                  <div class="col-md-5 px-2">
                    <SkeletonPuzl type="button"></SkeletonPuzl>
                    <SkeletonPuzl type="button"></SkeletonPuzl>
                    <br>
                    <SkeletonPuzl class="mt-n4" type="button"></SkeletonPuzl>
                    <SkeletonPuzl type="button"></SkeletonPuzl>
                    <SkeletonPuzl type="button"></SkeletonPuzl>
                    <SkeletonPuzl type="button"></SkeletonPuzl>
                    <SkeletonPuzl type="button"></SkeletonPuzl>
                  </div>
                </div>
              </template>
              <template v-if="(!items.length || !filter.company_plant_id) && !loading">
                <PuzlEmptyData :with-card="false" class="mt-6" msg="Selecione uma central para filtrar os dados" v-if="!filter.company_plant_id"/>
                <PuzlEmptyData :with-card="false" class="mt-6" v-else/>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalEditProjection @update="init(false)" ref="modalEditProjection"/>
    <ModalCreateProjection @update="init(false)" ref="modalCreateProjection"/>
    <ModalEditConstruction @update="init(false)" ref="editConstruction"></ModalEditConstruction>
    <ModalPortfolioProjection ref="modalPortfolioProjection"></ModalPortfolioProjection>
  </div>
</template>
<script>
import MultiFilter from "@/components/Utils/MultiFilterV3.vue";
import PuzlSelect from "@/components/PuzlSelect.vue";
import cursorPaginate from "@/mixins/cursorPaginate";
import {mapGetters} from "vuex";
import {MonthsEnum} from "@/enum/MonthsEnum";
import ModalEditProjection from '@/views/Modules/Commercial/ContractProposal/Shared/_ModalEditProjection'
import SkeletonPuzl from "../../../../../components/SkeletonPuzl.vue";
import ModalEdit from "../../../Fiscal/OperationSource/Shared/_ModalEdit.vue";
import ModalCreateProjection from '@/views/Modules/Commercial/ContractProposal/Shared/_ModalCreateProjection'
import ModalEditConstruction from "../../../Commercial/CustomerConstruction/Constructions/Shared/_Edit.vue";
import ModalPortfolioProjection from "@/views/Modules/Commercial/ContractProposal/Shared/_ModalPortfolioProjection.vue";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import Vue from "vue";
import {
  AppFixedPageTitle,
  AppPageHeader,
  AppSearchBar,
  AppSearchBarFilterSection,
  initSearchBarFilterType
} from "@/components/AppGlobal";
import PuzlSelectSearch from "@/components/PuzlSelectSearch.vue";
import {debounce} from "@/plugins";
import InputDatePicker from "@/components/InputDatePicker.vue";

export default {
  components: {
    InputDatePicker,
    PuzlSelectSearch,
    AppFixedPageTitle, AppSearchBarFilterSection, AppPageHeader,
    AppSearchBar,
    ModalEditConstruction,
    ModalCreateProjection,
    ModalEdit,
    SkeletonPuzl,
    PuzlSelect,
    MultiFilter,
    ModalEditProjection,
    ModalPortfolioProjection,
    PuzlEmptyData
  },
  mixins: [cursorPaginate],
  data() {
    return {
      loading: false,
      loadingSkeleton: false,
      loading_widgets: false,
      loadingSellers: false,
      searchBarFilter: initSearchBarFilterType(),
      filter: {},
      paginateItems: {
        nextUrl: null,
      },
      paginateItemsMounth: {
        nextUrl: null,
      },
      MonthsEnum: MonthsEnum,
      months: [],
      month_items: [],
      atual_month: new Date().getMonth() + 1
    }
  },
  computed: {
    ...mapGetters({
      $_short_plants: "plant/activeShortItems",
      $_widgets: "entityWallet/getWidgets",
      items: "entityWallet/fetch",
      $_sellers: "user/fetch",
    })
  },
  methods: {
    setOrderBy(field) {
      this.filter.order_by_predicted = {}
      if (!this.filter[field]) {
        this.filter[field] = 'asc'
        return this.init(false)
      }
      if (this.filter[field] === 'asc') {
        this.filter[field] = 'desc'
        return this.init(false)
      }
      if (this.filter[field] === 'desc') {
        this.filter[field] = 'asc'
        return this.init(false)
      }
    },
    setSellerFilter(seller_id, seller_name) {
      this.filter.seller_id = seller_id
      this.debouncedFilterSeller(seller_name)
      this.init(false)
    },
    setOrderByPredicted(month) {
      this.filter.order_by_original = null
      if (!this.filter.order_by_predicted[month]) {
        this.filter.order_by_predicted[month] = 'asc'
        return this.init(false)
      }
      if (this.filter.order_by_predicted[month] === 'asc') {
        this.filter.order_by_predicted[month] = 'desc'
        return this.init(false)
      }
      if (this.filter.order_by_predicted[month] === 'desc') {
        this.filter.order_by_predicted[month] = 'asc'
        return this.init(false)
      }
    },
    /**
     * Busca os dados com base no texto digitado pelo usuário.
     * @param {string} label
     */
    debouncedFilterSeller: debounce(function (label) {
      this.$store.dispatch('user/getSearch', {
        search: label
      }).then(() => {
        this.loadingSellers = false;
      });
    }, 300),
    /**
     * Inicia o loading e chama a função responsável por trazer os dados.
     * @param {string} label - Pesquisa do usuário
     */
    handleFilterSeller: function (label) {
      this.loadingSellers = true;
      this.debouncedFilterSeller(label);
    },
    /**
     * Padrão de filtro da barra de pesquisa
     * @returns {SearchBarFilterType}
     */
    defaultSearchBarFilter() {
      return {
        ...initSearchBarFilterType(),
        range: {
          items: [],
          selected: null,
          start: null,
          end: null,
        },
      };
    },
    /**
     * Padrão do filtro principal
     * @returns {FinanceListFilterType}
     */
    defaultFilter() {
      return {
        custom_search: {
          columns: [
            'entities.entity_name',
            'contract_proposals.code',
            'constructions.construction_name',
            'users.name'
          ],
          values: [],
        },
        order_by_original: null,
        order_by_predicted: {},
        order_by: 'latest_updated_at.desc',
        created_at: {
          start: null,
          end: null,
        },
        seller_id: null,
        range: {
          start: null,
          end: null,
        },
      };
    },
    async clearFilter(isRefreshList = true, withStoreFilters = false) {
      // Inicializa filtros com os valores padrão
      let searchBarFilterValue = this.defaultSearchBarFilter();
      let filterValue = this.defaultFilter();

      // Aplicar os filtros
      Object.assign(this.searchBarFilter, searchBarFilterValue);
      Object.assign(this.filter, filterValue);

      // Listar itens
      if (isRefreshList) {
        this.init();
      }
    },
    validMonthGoBack() {
      let selected_month = this.atual_month - 1
      let atual_month = new Date().getMonth() + 1
      if ((atual_month - selected_month) > 1) {
        return false
      }
      return true
    },
    handleEditConstruction(uuid) {
      this.$refs.editConstruction.handleEditModal(uuid)
    },
    handleCreateProjectionModal(month_item, month) {
      let months = this.items.find((item) => item.id == month_item.id)
      let selected = months.months.find((item) => item.month === month)
      if (selected.projection_id) {
        return this.$refs.modalEditProjection.handleEditProjectionModal(
          selected.projection_id
        );
      } else {
        this.$refs.modalCreateProjection.openModal(
          months.id,
          months.company_plant_id,
          selected.prediction,
          selected.month
        );
      }
    },
    validMonthAdvanceBack() {
      if (!this.months.length) {
        return false
      }
      let selected_month = this.months[this.months.length - 1].id
      let atual_month = new Date().getMonth() + 1
      if ((selected_month - atual_month) > 3) {
        return false
      }
      return true
    },
    validMonthAdvance() {
      let selected_month = this.months[this.months.length - 1].id
      if (selected_month === 12) {
        return false
      }
      return true
    },
    async handleGoBackMonth() {
      if (!this.validMonthGoBack()) {
        return
      }
      let loader = this.$loading.show()
      this.atual_month = this.atual_month - 1
      await this.getMonths()
      loader.hide()
    },
    async handleAdvanceMonth() {
      if (!this.validMonthAdvance()) {
        return
      }
      let loader = this.$loading.show()
      this.atual_month = this.atual_month + 1
      await this.getMonths()
      loader.hide()
    },
    invalidGlobalFilter() {
      return typeof Vue.prototype.$filterable !== "number"
    },
    handlePortfolioProjection(id, contract) {
      this.$refs.modalPortfolioProjection.openModal(id, contract)
    },
    /**
     *
     * @param month
     * @returns {*}
     */
    getPredictedTotalByMonth(month) {
      if (!this.$_widgets) {
        return
      }
      if (!this.$_widgets.months) {
        return
      }
      let selected = this.$_widgets.months.find((item) => item.month == month)
      return selected ? selected.prediction : 0
    },
    /**
     *
     * @param month
     * @returns {*}
     */
    getAccomplishedOrBalance(month) {
      if (!this.$_widgets) {
        return
      }
      if (!this.$_widgets.months) {
        return
      }
      let selected = this.$_widgets.months.find((item) => item.month == month)
      let atual_month = new Date().getMonth() + 1
      if (selected.month <= atual_month) {
        return selected.accomplished
      }
      let total_volume = this.$_widgets.schedule_travel_volume_sum.replace(',', '.')
      let sum_predictions = 0;

      this.$_widgets.months.forEach(item => {
        if (item.month <= month && item.month > atual_month) {
          sum_predictions += Number(item.prediction);
        }
      });
      return Number(total_volume) - sum_predictions
    },
    /**
     * Busca o saldo ou realizado por MES do contrato percorrido
     * * @param month_item
     * @param month
     * @returns {*}
     */
    getAccomplishedOrBalanceItem(month_item, month) {
      let months = this.items.find((item) => item.id == month_item.id).months
      let selected = months.find((item) => item.month === month)
      let atual_month = new Date().getMonth() + 1
      return selected.month <= atual_month ? selected.accomplished : selected.balance
    },
    /**
     * Busca o previsto por MES do contrato percorrido
     * @param month_item
     * @param month
     * @returns {*}
     */
    getPredictionByMonthOnItems(month_item, month) {
      let months = this.items.find((item) => item.id == month_item.id).months
      let selected = months.find((item) => item.month === month)
      return selected.prediction
    },
    async getWidgets(filter = null) {
      if (filter) {
        this.filter = {...this.filter, ...filter}
      }
      this.filter.months = this.getFilterableMonth()
      this.loading_widgets = true
      await this.$store.dispatch('entityWallet/getWidgets', {
        filter: this.filter,
        months: this.filter.months
      }).then(response => {
      }).catch(error => {
      }).finally(() => this.loading_widgets = false)
    },
    async getItems(filter = null) {
      this.loadingSkeleton = true
      if (filter) {
        this.filter = {...this.filter, ...filter}
        this.paginateItems.nextUrl = null
      }
      this.filter.months = this.getFilterableMonth()
      await this.$store.dispatch('entityWallet/fetch', {
        filter: this.filter,
        next_page: this.paginateItems.nextUrl
      }).then(response => {
        this.paginateItems.nextUrl = response.next_page
        this.loading = false
        this.loadingSkeleton = false
      })
    },
    getFilterableMonth() {
      let months = this.months.map((item) => item.id)
      // corrigir
      return {
        start: months.shift() - 1,
        end: months.pop() + 2 > 12 ? 12 : months.pop() + 2
      }
    },
    getMonths() {
      this.months = []
      const month = this.atual_month
      const year = new Date().getFullYear()
      for (let i of this.MonthsEnum) {
        i.year = year
        if (i.id > month && i.id < (month + 3)) {
          this.months.push(i)
        }
        if (i.id == month) {
          this.months.push(i)
        }
      }
    },
    handleFilterOrderBy(order_by) {
      this.filter.order_by = order_by
      this.init({})
    },
    validateScroll(e) {
      const screenHeight = e.target.clientHeight;
      const scrolled = e.target.scrollTop;
      const scrollHeight = e.target.scrollHeight;
      if (scrollHeight - (screenHeight + scrolled) < 213) {
        return true
      }
      return false
    },
    navigate(e) {
      if (!this.loadingSkeleton && this.validateScroll(e)) {
        if (this.paginateItems.nextUrl) {
          this.scrollable()
        }
      }
    },
    prepareFilter() {
      this.filter.company_plant_id = this.searchBarFilter.company_plant_selected;
      this.filter.custom_search.values = this.searchBarFilter.custom_search_values;
    },
    async init(clearOrder = true) {
      if (!this.searchBarFilter.company_plant_selected) {
        this.loadingSkeleton = false
        this.loading_widgets = false
        this.loading = false
        return
      }
      this.prepareFilter()
      let loader = this.$loading.show()
      this.atual_month = new Date().getMonth() + 1
      await this.getMonths()
      Promise.all([
          this.getWidgets(this.filter),
          this.scrollable(this.filter)
        ]
      ).finally(() => {
        loader.hide()
        this.loadingSkeleton = false
        this.loading_widgets = false
        this.loading = false
        if (clearOrder) {
          this.filter.order_by_original = null
          this.filter.order_by_predicted = {}
        }
      })
    },
    async scrollable(filter = null) {
      let loader = this.$loading.show()
      await this.getItems(filter)
      loader.hide()
    },
  },
  async mounted() {
    this.loading = true
    this.loadingSkeleton = true
    this.loading_widgets = true
    if (!this.$_short_plants.length) {
      await this.$store.dispatch("plant/fetchItemsActive");
      if (this.searchBarFilter.company_plant && this.searchBarFilter.company_plant_selected) {
        this.filter.company_plant_id = this.searchBarFilter.company_plant_selected
      }
    }
    const list = document.getElementById('items')
    if (list) {
      list.addEventListener("scroll", this.navigate)
    }
    await this.getMonths()
    this.$store.dispatch('user/getSearch', {
      search: ''
    }).then(() => {
      this.loadingSellers = false;
    });
    await this.clearFilter(true, true);
  }
}
</script>
<style scoped>
.padding-th > thead > tr > th, td {
  padding: 8px !important;
}

.padding-th > thead > tr > th:last-child, td:last-child {
  padding: 8px !important;
  border-right: 1px solid #dedede;
}

.padding-th > thead > tr > th:first-child, td:first-child {
  padding: 8px !important;
  border-left: 1px solid #dedede;
}

.numeric-custom {
  font-family: Quicksand;
  font-weight: 700 !important;
}

html {
  overflow: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: transparent;
}

table {
  width: 100%
}

td {
  white-space: nowrap;
}

.td-truncate {
  overflow: hidden;
  text-overflow: ellipsis;

}

.bg-grey {
  background: rgb(245, 245, 245) !important;
}

.bg-aqua {
  background: #64baed4f !important;
}

.order:hover > i {
  opacity: 0.6 !important;
}

.order > i {
  margin-left: 5px;
  opacity: 0.2;
}

.text-table-entity-wallet {
  font-size: 10px !important;
  font-weight: 600 !important;
}
</style>
