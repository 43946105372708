<template>
  <div>
    <div class="row align-items-center pb-0">
      <!-- Barra de busca -->
      <div class="col-md-11 mb-3">
        <autocomplete
          :class="'bg-white'"
          :style="hasStyle && 'border: 1px solid grey'"
          :search="search"
          aria-label="Pesquisar"
          placeholder="Pesquisar"
          default-value=""
          :value="searchBar"
          @submit="handleSubmitSearch"
        >
        </autocomplete>
      </div>
      <!-- Botao de ativos -->
      <!-- Tipo 1 -->
      <div v-if="type == 1" class="col-md-1 mb-3 text-center">
        <base-button
          @click="changeActive"
          :type="active  ? 'success' : 'primary'"
          class="p-2"
        >
          {{ active ? "Ativos" : "Todos" }}
        </base-button>
      </div>
      <!-- Tipo 2 -->
      <div v-if="type == 2" class="col-md-1 mb-3 text-center">
        <base-button
          @click="changeActive"
          :type="active ? 'success' : 'primary'"
          class="p-2"
        >
           {{ active ? "Padrão" : "Todos" }}
        </base-button>
      </div>
      <!-- Tipo 3 -->
      <div v-if="type == 3" class="col-md-1 mb-3 text-center">
        <base-button
          @click="changeActive"
          :type="active ? 'indigo' : 'primary'"
          class="p-2"
        >
           {{ active ? "PUZL" : "Todos" }}
        </base-button>
      </div>
    </div>
    <!-- filtros -->
    <div class="row align-items-center pb-0">
      <div class="col-md-12 filters mb-3">
        <badge
          v-for="(filter, index) in filters"
          :key="index"
          rounded
          class="text-capitalize mr-1 ml-1"
          tag="a"
          type="default"
        >
          {{ filter }}
          <i
            @click.prevent="handleDeleteFilter(index)"
            class="fas fa-times pl-1"
            style="font-size: 11px;"
          ></i
        ></badge>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchBarPuzl",
  components: {},
  props: {
    active: [Boolean, Number],
    filters: Array,
    type: Number,
    hasStyle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchBar: null
    };
  },
  methods: {
    handleDeleteFilter(index) {
      this.filters.splice(index, 1);
      this.$emit("getData");
    },
    search(value) {
      this.searchBar = value;
      return [];
    },
    handleSubmitSearch() {
      if (!this.searchBar) return false;
      this.filters.push(this.searchBar);
      this.searchBar = null;
      this.$emit("getData");
    },
    changeActive() {
      this.$emit("changeState");
    }
  }
};
</script>

<style scoped>
.receive-content-button {
  padding-top: 0.2rem !important;
}

.badge-default i:hover {
  cursor: pointer;
}

.badge-default:hover {
  color: #fff !important;
}

@media (max-width: 767px) {
  .filters {
    text-align: center !important;
  }
}
</style>
