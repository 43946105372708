<template>
  <div>
    <modal :show.sync="modal" size="md" data-class="programming-weighing">
      <template slot="header">
        <div style="display: flex;align-items: center;">
          <img style="display: inline-flex;" src="/img/icons/icons8/ios/plus--v1_success.png" width="24">
          <h5 style="display: inline-flex;" class="modal-title p-0 m-0 ml-3 new-default-black-font fs-18">{{ title }}</h5>
        </div>
      </template>
      <template slot="close-button">
        <img class="pointer" @click="closeModal" src="/img/icons/icons8/ios/close-window.png" alt="close-window" width="17px">
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
            <div class="container px-1">
              <div class="form-row row">
                <div class="col-md-12">
                  <multi-filter
                    multiFilterInlineStyles="box-shadow: 0px 5px 10px 0px #0000000D !important; border: 1px solid #E8E8E8 !important;margin-bottom: 0 !important;"
                    ref="multiFilter"
                    @fetch="fetch"
                    :filter="filter"
                    without_filter
                    without_bar_filter
                  >
                    <div class="col-md-12 mt-n2 mb-2 new-default-black-font font-weight-500">
                      <div class="container px-0">
                        <div class="row align-items-center">
                          <div class="col-auto pr-0">
                            <div style="display: flex;align-items: center;">
                              <img
                                class="mr-3"
                                src="/img/icons/icons8/ios/filter.png"
                                height="20"/>
                              <h4 class="new-default-black-font m-0">
                                Filtros
                              </h4>
                            </div>
                          </div>
                          <div class="col">
                            <hr class="gray-divider">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 px-2 ">
                      <label style="font-size: 10px"
                        class="new-default-gray-font">
                          Tipo
                      </label>
                      <PuzlSelect
                        class="mt-n2"
                        :clearable="true"
                        v-model="filter.type"
                        :disableBoxShadow="true"
                        label="technical_description"
                        :items="$_types"
                        @change="handleSearchSubtype"
                        @input="handleSearchMixDesign"
                        :loading="loadingTypes"
                        :disabled="loadingTypes || loading"/>
                    </div>
                    <div class="col-md-4 px-2">
                      <label
                        style="font-size: 10px"
                        class="new-default-gray-font">
                          Slump/Flow
                      </label>
                      <base-input input-classes="form-control" class="mt-n2">
                        <PuzlSelect
                          :clearable="true"
                          v-model="filter.slump_flow"
                          :items="slump_flows"
                          label="nomenclature"
                          customKey="nomenclature"
                          :disableBoxShadow="true"
                          @input="handleSearchMixDesign"
                          :loading="loadingSlumpFlow"
                          :disabled="loadingSlumpFlow || loading"/>
                      </base-input>
                    </div>
                    <div class="col-md-4 px-2">
                      <label style="font-size: 10px"
                        class="new-default-gray-font">
                        Dimensão Máxima
                      </label>
                      <base-input input-classes="form-control" class="mt-n2">
                        <PuzlSelect
                          :clearable="true"
                          v-model="filter.maximum_size"
                          :items="maximum_sizes"
                          label="description"
                          customKey="description"
                          :disableBoxShadow="true"
                          @input="handleSearchMixDesign"
                          :loading="loadingMaximumSize"
                          :disabled="loadingMaximumSize || loading"/>
                      </base-input>
                    </div>
                    <div class="col-md-4 px-2 mt-n3">
                      <label>&nbsp;</label>
                      <base-input>
                        <div class="input-custom-group">
                          <div>
                            TR
                          </div>
                          <input
                            class="form-control form-control-sm"
                            @input="handleSearchMixDesignCode()"
                            v-model="filter.mix_design_code"
                            inputmode="numeric"
                            v-mask="'#########'"
                          >
                        </div>
                      </base-input>
                    </div>
                    <div class="col-md-8 px-2 mb-n3 mt-n3">
                      <label>&nbsp;</label>
                      <!--                    <div class="d-flex w-100 mt-n2" style="height: 1.95rem;"><input placeholder="Pesquisar"-->
                      <!--                                                                                    class="form-control"-->
                      <!--                                                                                    style="height: 1.95rem; border-radius: 4px !important; box-shadow: none !important; border: 1px solid rgb(232, 232, 232);">-->
                      <!--                      <img-->
                      <!--                        src="/img/icons/icons8/ios/search.png" height="18" class="default-search zoom pointer"-->
                      <!--                        style="margin-top: 5px;"></div>-->

                      <div class="form-group m-0" style="width: 100%;">
                        <div class="input-group">
                          <input placeholder="Pesquisar"
                            @keyup.enter="handleSearchMixDesign"
                            v-model="filter.global"
                            class="form-control new-default-placeholder-color"
                            style="height: 1.95rem;border-radius: 4px !important;border-top-right-radius: 0px !important;border-bottom-right-radius: 0px !important;box-shadow: none !important;border: 1px solid #ECECEC;border-right: none;">
                          <div class="input-group-append" style="border-left: none !important;">
                            <span
                              class="input-group-text"
                              style="height: 1.95rem !important; box-shadow: none !important;border-color: #ECECEC !important;"
                            >
                              <span
                                style="width: 30px !important;"
                              >
                              </span>
                              <img
                                @click="handleSearchMixDesign"
                                src="/img/icons/icons8/ios/search.png"
                                class="default-search zoom pointer"
                                style="height: 16px;margin-top: 0px;"
                              >
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </multi-filter>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="row align-items-center my-3">
                <div class="col-auto">
                  <div style="display: flex; align-items: center;">
                    <img width="20"
                      src="/img/icons/test-tube.png"
                      class="mr-2"
                    />
                    <h4 class="m-0">Traços</h4>
                  </div>
                </div>
                <div class="col">
                  <hr class="gray-divider">
                </div>
              </div>
            </div>
            <div v-if="loadingSkeleton">
              <div v-for="item in 3">
                <div class="row m-0 p-0">
                  <div class="col-md-9">
                    <skeleton-puzl type="text"></skeleton-puzl>
                  </div>
                </div>
                <div class="row m-0 p-0">
                  <div class="col-md-9">
                    <skeleton-puzl type="text"></skeleton-puzl>
                  </div>
                </div>
                <div class="row m-0 p-0">
                  <div class="col-md-7">
                    <skeleton-puzl type="text"></skeleton-puzl>
                  </div>
                </div>
                <br>
              </div>
            </div>
            <div v-if="!loadingSkeleton && mix_designs.data && !mix_designs.data.length &&
(filter.plant)">
              <puzl-empty-data
                msg="Não existem traços cadastrados a partir dos parâmetros selecionados, entre em contato com o setor de tecnologia."
              />
            </div>
            <div class="container px-2" v-else>
              <div class="row mix-design-scrollview" v-if="!loadingSkeleton">
                <div class="col-lg-12"
                  v-for="(mix_design, indexMixDesign) in mix_designs.data">
                  <div class="minicard-mix-design mb-3">
                    <div class="container p-3">
                      <div class="row align-items-center">
                        <div class="col-auto">
                          <div class="mix-design-codebadge">
                            {{ mix_design.code }}
                          </div>
                        </div>
                        <div class="col pl-0">
                          <h4 class="new-default-black-font m-0">
                            <template v-for="(feature, index) in mix_design.features">
                              {{ feature.short_description }}
                              {{ format_mixe(feature.period, feature.period_type) }}
                              {{ feature.item_description }}
                              {{ feature.letter }}
                              <template v-if="mix_design.features[index + 1]"> + </template>
                            </template>
                          </h4>
                        </div>
                      </div>
                      <div class="row my-2">
                        <div class="col py-1">
                          <hr class="gray-divider">
                        </div>
                      </div>
                      <div class="row align-item-center">
                        <div class="col-12">
                          <h5 class="new-default-black-font m-0 font-weight-400">
                            {{ mix_design.type.technical_description }}
                            |
                            {{ mix_design.subtype && mix_design.subtype.technical_description + " |" }}
                            {{
                              mix_design.classification && mix_design.classification.technical_description + " |"
                            }}
                            {{ mix_design.slump_flow.nomenclature }}
                            |
                            {{ mix_design.maximum_size.description }}
                          </h5>
                        </div>
                      </div>
                      <div class="row align-item-center mt-2">
                        <div class="col-12">
                          <div style="display: flex; align-items: center">
                            <img src="/img/icons/icons8/ios/wheelbarrow_dark.png" width="15">
                            <h6 class="new-default-black-font m-0 font-weight-400 ml-3">
                              <template v-for="(group, index) in mix_design.content_groups">
                                {{
                                  group.name
                                }} ({{ group.percentage }})
                                <template v-if="mix_design.content_groups[index + 1]"> + </template>
                              </template>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="select-button-placement">
                      <div
                        class="custom-select-object"
                        @click.prevent="handleSetMixDesign(mix_design)"
                      >
                        <div class="custom-select-object-text">
                          <h4>Selecionar</h4>
                          <img src="/img/icons/lessThan.png" width="13">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="container px-2"
              v-show="mix_designs.data && mix_designs.data.length"
            >
              <div class="row">
                <div class="col-12">
                  <pagination
                    @navegate="navegate"
                    :loading="loadingSkeleton"
                    :source="mix_designs"
                  />
                </div>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {
  period_types
} from "@/views/Modules/Technology/Mix/helpers/mixe";
import SkeletonPuzl from "../../../../../../components/SkeletonPuzl";
import Pagination from '@/components/Utils/Pagination'
import MultiFilter from "@/components/Utils/MultiFilterV2";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import PuzlSelect from "@/components/PuzlSelect";
import {debounce} from "@/plugins";

export default {
  components: {PuzlEmptyData, MultiFilter, SkeletonPuzl, Pagination, PuzlSelect},
  name: "ModalAddMixe",
  data() {
    return {
      format_mixe: period_types,
      modal: false,
      title: 'Incluir traço',
      loadingSkeleton: false,
      validated: false,
      loadingPlant: true,
      loadingSave: false,
      loadingSlumpFlow: false,
      loadingMaximumSize: false,
      selectedSlumpFlow: null,
      selectedMaximumSize: null,
      loading: false,
      invalid: false,
      loadingTypes: false,
      loadingSubtypes: false,
      loadingClassification: false,
      filter: {
        plant: null,
        global: '',
        mix_design_code: null,
        type: null,
        subtype: null,
        additional_classification: null,
      },
      params: {
        page: 1,
        per_page: 3,
      },
      mixe: null,
    }
  },
  computed: {
    ...mapGetters({
      $_general_settings: "generalSetting/show",
      'plants': 'plant/activeItems',
      'mix_designs': 'formulations/getMixDesigns',
      'slump_flows': 'mixSlumpFlow/activeItems',
      'maximum_sizes': 'mixMaximumSize/activeItems',
      '$_subtypes': 'mixSubtype/fetchByType',
      '$_additional_classifications': 'mixAdditionalClassifications/fetchBySubtype',
      '$_types': 'mixType/activeItems',
      contract_proposal: "contractProposal/show",
    }),
  },
  methods: {
    closeModal() {
      this.modal = false
    },
    copy(val) {
      this.$helper.copy(val)
      this.$notify({type: 'success', message: 'Código do traço copiado com sucesso!'})
    },
    navegate(page) {
      this.params.page = page
      this.fetch()
    },
    handleSearchMixDesignCode: debounce(function () {
      this.handleSearchMixDesign()
    }, 600),
    handleSearchSubtype() {
      this.filter.subtype = null
      const type = this.$_types.find(item => item.id === this.filter.type)
      if (type) {
        this.loadingSubtypes = true
        this.$store.dispatch('mixSubtype/fetchItemsByUuidType', type.uuid).then(() => {
          this.loadingSubtypes = false
        })
      }
    },
    handleSearchAdditionalClassification() {
      this.filter.additional_classification = null
      const subtype = this.$_subtypes.find(item => item.id === this.filter.subtype)
      this.$store.dispatch('mixAdditionalClassifications/fetchItemsByUuidSubtype', subtype.uuid)
        .then(() => {
          this.loadingClassification = false
        })
    },
    handleSetMixDesign(mix_design) {
      this.$emit('set_mix_design', mix_design)
      this.modal = false
    },
    handleSearchMixDesign() {
      if (!(this.filter.plant)) {
        return
      }
      this.loadingSkeleton = true
      this.$Progress.start()
      this.loading = true
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      });
      this.params.page = 1
      this.fetch()
    },
    fetch(filter = {}) {
      this.loading = true
      this.loadingSkeleton = true
      let param_filter = {...this.filter}
      param_filter.global = param_filter.global.split(" ")
      param_filter.proposal = this.contract_proposal.entity ? 0 : 1
      this.params.filter = {...filter, ...param_filter}
      this.$store.dispatch('formulations/fetchMixDesigns', this.params).then(response => {
        this.$Progress.finish()
        this.loading = false
        this.loadingSkeleton = false
        this.$notify({type: response.error_type, message: response.message})
      }).catch(error => {
        this.$Progress.finish()
        this.loading = false
        this.loadingSkeleton = false
        this.loadingSave = false
      })
    },
    openModal(plant) {
      this.loading = true
      this.$store.state.formulations.mix_designs.data = []
      this.filter.slump_flow = null
      this.filter.maximum_size = null
      this.loadingSlumpFlow = true
      this.loadingMaximumSize = true
      this.loadingTypes = true
      this.$store.dispatch('generalSetting/show')
      this.$store.dispatch('plant/fetchItemsActive').then(() => {
        this.filter.plant = plant
        this.$store.dispatch('mixSlumpFlow/fetchItems', {
          filter: {
            company_plant_id: plant,
            exists_in_mix: true
          }
        }).then(() => {
          this.loadingSlumpFlow = false
        })
        this.$store.dispatch('mixMaximumSize/fetchItems', {
          filter: {
            company_plant_id: plant,
            exists_in_mix: true
          }
        }).then(() => {
          this.loadingMaximumSize = false
        })
        this.$store.dispatch('mixType/fetchItems', {
          filter: {
            company_plant_id: plant,
            exists_in_mix: true
          }
        }).then(() => {
          this.loadingTypes = false
        })
        this.handleSearchMixDesign()
        this.modal = true
        this.loadingPlant = false
        this.loading = false
      })
      this.mixe = {
        comapny_plant_ids: null,
      }
    },
  },
};
</script>

<style scoped>
/deep/ div[data-class="programming-weighing"] div.modal-header.pb-1 {
  background: #F2F7F3 !important;
}

/deep/ div[data-class="programming-weighing"] div.modal-header.pb-1 > button i {
  border: 1px solid #000 !important;
  color: #000;
  padding: 3%;
  font-size: 14px !important;
}

.input-custom-group {
	display: flex;
	align-items: center;
	border-radius: 4px;
  overflow: hidden;
  height: 30px;
  border: 1px solid #ECECEC;
  background-color: #ECECEC;
}

.input-custom-group:has(.border-danger) {
  border-color: #db4539;
}

.input-custom-group input {
	box-shadow: none !important;
  border: none;
  padding-left: 5px;
  border-radius: 0;
}

.input-custom-group div {
	padding: 0px 10px;
}

.input-custom-group input, .input-custom-group div {
	display: inline-flex;
	height: 30px;
	background: white;
	align-items: center;
	font-size: 12px;
}

.input-custom-group div, .input-custom-group div nav p {
	color: #2B2D32;
  font-size: 12px;
  font-weight: 500;
}

.input-custom-group > div:first-child {
  margin-right: 1px;
}

.input-custom-group > div:last-child {
  margin-left: 1px;
}

.input-custom-group div nav {
  display: flex;
  align-items: center;
}

.input-custom-group div nav p {
  text-transform: lowercase;
  margin: 0;
}

.input-custom-group input {
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}

.input-custom-group input:focus {
	outline: none;
}

.gray-divider {
  background-color: #E8E8E8;
  color: #E8E8E8;
  margin: 0;
  opacity: 0.4;
}

.minicard-mix-design {
  border: 1px solid #E8E8E8 !important;
  border-radius: 16px !important;
  background: #F4F4F4;
  position: relative;
}

.mix-design-codebadge {
  color: white;
  font-weight: 500;
  background: #1A70B7;
  padding: 2px 4px 2px 4px;
  border-radius: 4px;
}

.mix-design-scrollview {
  max-height: 500px;
  overflow: auto;
}

.custom-select-object {
  width: 150px;
  height: 27px;
  border-bottom-right-radius: 16px;
  border-top-left-radius: 16px;
  background-color: #3A83BF;;
  position: relative;
  cursor: pointer;

}

.custom-select-object::before {
  content: '';
  position: absolute;
  left: -17.3px;
  top: 0px;
  border-top: 27px solid transparent;
  border-bottom: 0px solid transparent;
  border-right: 27px solid #3A83BF;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
}

.custom-select-object-text {
  padding: 3px;
  padding-left: 25px;
  color: white;
  display: flex;
  align-items: center;
}

.custom-select-object-text h4 {
  padding: 0;
  margin: 0;
  color: white;
}

.custom-select-object-text img {
  transform: scaleX(-1);
  margin: 1px;
  margin-left: 8px;
}

.select-button-placement {
  bottom: 0;
  right: 0;
  width: 150px;
  position: absolute;
}
</style>
<style>
.new-default-placeholder-color::placeholder {
  color: #a1a8b9 !important;
}
</style>
