<template>
<div>
  <multi-filter ref="multi-filter" @fetch="load" :filter="filter" />
  <div class="container-fluid">

    <div class="row card-wrapper" v-show="loadingSkeleton">
      <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
    </div>

    <div class="row align-items-center"  v-if="!types.length && !loadingSkeleton">
      <div class="col-md-12 text-center">
        <div class="card p-4">
          Sem dados cadastrados!
        </div>
      </div>
    </div>


    <div class="row card-wrapper" v-if="types.length && !loadingSkeleton">
      <div class="col-lg-4" v-for="(type, index) in types" :key="index">
        <!-- Basic with action button -->
        <card :class="type.status ? 'card-border-top-success' : 'card-border-top-danger'">
          <!-- Card header -->
          <div class="row align-items-center mb-3">
            <div class="col-8">
              <!-- Title -->
              <h5 class="h3 mb-0">{{ type.technical_description }}</h5>
              <h6 class="h3 mb-0 small">{{ type.commercial_description }}</h6>
            </div>

            <div class="col-4 text-right">
              <base-dropdown menuOnRight>
                <base-button
                  slot="title-container"
                  type="primary"
                  class="dropdown-toggle p-2 rounded m-0"
                >
                  <i class="fas fa-cog"></i>
                </base-button>
                <div class="dropdown-divider p-0 m-0"></div>
                <a class="dropdown-item" @click="handleEditType(type.uuid)">
                  <i class="fas fa-edit text-warning"></i>
                  Editar
                </a>
                <div class="dropdown-divider p-0 m-0"></div>
                <a class="dropdown-item" @click="handleDeleteType(type.uuid)">
                  <i class="fas fa-times text-danger"></i>
                  Excluir
                </a>
              </base-dropdown>
            </div>
          </div>
          <!-- Card body -->


          <p v-if="type.specific_gravity_start || type.specific_gravity_start ">
            {{ type.specific_gravity_start }} até
            {{ type.specific_gravity_end }}
            (kg/m<sup>3</sup>)
          </p>
        </card>
      </div>
    </div>
  </div>
  <edit-type ref="editType"></edit-type>
</div>
</template>

<script>
import {mapGetters} from 'vuex'
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import EditType from './_Edit'
import { formatErrorValidation } from "@/plugins";
import MultiFilter from "@/components/Utils/MultiFilter";
export default {
  name: "List",
  components: {
    MultiFilter,
    SkeletonPuzlGrid,
    EditType,
  },
  data () {
    return {
      loadingSkeleton: false,
      filter: {},
    }
  },
  computed: {
    ...mapGetters({
      'types': 'mixType/fetch',
    }),
  },
  methods:{
    load(filter = {}) {
      this.filter = filter
      this.init();
    },
    init () {
      this.loadingSkeleton = true
      this.$store.dispatch('mixType/fetchItems', { filter: this.filter })
        .then( response =>{
          this.loadingSkeleton = false
        })
        .catch( error => {
          this.loadingSkeleton = false
        })
    },
    handleEditType(uuid){
      this.$refs.editType.handleEditModal(uuid)
    },
    handleDeleteType(uuid){
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed){
          this.$Progress.start();
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          this.$store.dispatch('mixType/destroy', uuid)
            .then(response => {
              this.$Progress.finish()
              this.$notify({ type: response.error_type, message: response.message })
            })
            .catch(error => {
              if (error.status == 200) {
                this.$notify({
                  type: "danger",
                  message: error.data.message
                });
                this.$Progress.finish();
              } else if (error.response && error.response.status === 422) {
                let errors = formatErrorValidation(error.response.data.errors);
                this.$notify({
                  type: "danger",
                  message: errors
                });
              }
              this.$Progress.finish();
            });
          this.$Progress.finish();
        }
      }).catch(() => this.$Progress.finish())
    }
  },
  mounted() {
    this.filter.status = 1
    this.$refs['multi-filter']._self.$forceUpdate()
    this.init()
  },
  created () {

  }
}
</script>

<style scoped>

</style>