import { render, staticRenderFns } from "./_CreateMold.vue?vue&type=template&id=6349cd06&scoped=true"
import script from "./_CreateMold.vue?vue&type=script&lang=js"
export * from "./_CreateMold.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6349cd06",
  null
  
)

export default component.exports