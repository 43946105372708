<template>
<div>
  <div class="row">
    <div class="col-md-12 p-0 m-0 table-responsive">
      <table class="table table-striped table-sm">
        <thead>
        <tr>
          <th class="text-center p-1 m-0">{{ feature.short_description }}</th>
          <th class="text-center p-1 m-0">Cons. mín.<br />(kg/m³)</th>
          <th class="text-center p-1 m-0">Reuso</th>
          <th class="text-center p-1 m-0">&nbsp;</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in items" :key="item.id">
          <td class="text-center">{{ item.description}}</td>
          <td class="text-center">{{ item.consumption}}</td>
          <td class="text-center col-xs-2 p-0 m-0">
            <span class="text-success" v-if="item.reuse">sim</span>
            <span class="text-danger" v-else>não</span>
          </td>
          <td class="text-center col-xs-2 pr-0 mr-0 pl-0 ml-0">
            <a @click.prevent="handleEditFeatureItem(feature.uuid, item.uuid)" href="#">
              <i class="fas fa-edit text-warning"></i>
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a @click.prevent="handleDeleteFeatureItem(item.uuid)" href="#">
              <i class="fas fa-times text-danger"></i>
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <edit-feature-item ref="editFeatureItem"></edit-feature-item>
</div>
</template>

<script>
import EditFeatureItem from './_Edit'
import { formatErrorValidation } from "@/plugins";

export default {
  name: "ItemFeature",
  components: {
    EditFeatureItem,
  },
  props: {
    items:{},
    feature:{}
  },
  data () {
    return {
      loadingSkeleton: false,
    }
  },
  computed: {
  },
  methods:{
    handleEditFeatureItem(featureUuid, featureItemUuid){
      this.$refs.editFeatureItem.handleEditModal(featureUuid, featureItemUuid)
    },
    handleDeleteFeatureItem(uuid){
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed){
          this.$Progress.start();
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          this.$store.dispatch('mixFeatureItem/destroy', uuid)
            .then(response => {
              this.$Progress.finish()
              this.$notify({ type: response.error_type, message: response.message })
            })
            .catch(error => {
              if (error.status == 200) {
                this.$notify({
                  type: "danger",
                  message: error.data.message
                });
                this.$Progress.finish();
              } else if (error.response && error.response.status === 422) {
                let errors = formatErrorValidation(error.response.data.errors);
                this.$notify({
                  type: "danger",
                  message: errors
                });
              }
              this.$Progress.finish();
            });
          this.$store.dispatch('mixFeature/fetchItems')
          this.$Progress.finish();
        }
      }).catch(() => this.$Progress.finish())
    }
  },
  mounted() {
  },
  created () {
  }
}
</script>

<style scoped>

</style>
