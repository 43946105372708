<template>
  <div>
    <modal :closeDown="false" :showClose="false" :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
      <div class="row">
        <div class="col-md-12 ">
          <label>Motivo
            <span class="text-danger"> * </span>
          </label>
          <validation-provider rules="required|min:10">
          <base-input input-group-classes="input-group-sm">
              <textarea
                v-model="reason"
                rows="5"
                type="text"
                placeholder="Informe o motivo do carregamento manual"
                class="form-control form-control-sm"
              />
          </base-input>
          </validation-provider>
          <small style="text-transform: none">Mínimo de 10 caracteres.</small>
        </div>
      </div>
      <div class="p-3">
        <div class="modal-footer pb-0 pr-0">
          <base-button :disabled="invalid" :loading="loading" type="primary" @click.native="completeCharging()">
            <i class="fa-regular fa-circle-check"></i> Concluir carregamento
          </base-button>
        </div>
      </div>
      </validation-observer>
    </modal>
  </div>
</template>

<script>
export default {
  components: {},
  name: "ModalCancelTravel",
  data() {
    return {
      modal: false,
      reason: null,
      title: 'Carregamento manual',
      loading: false,
      travel: null,
    }
  },
  methods: {
    completeCharging() {
      this.$emit('completeCharging', {reason: this.reason})
    },
    openModal(travel) {
      this.travel = travel
      this.modal = true
    },
    closeModal() {
      this.modal = false
    },
  },
};
</script>

<style></style>
