/**
 * Definição das chaves únicas usadas para armazenar o estado.
 *
 * Cada chave deve ser única em todo o projeto.
 * Não deve haver duplicação de chaves em nenhum lugar do código.
 */
export const cmcAdjustmentStoreStateKeys = Object.freeze({
  ITEM: "CMC_ADJUSTMENT_ITEM",
  LISTED: "CMC_ADJUSTMENT_LISTED",
  IS_LISTING: "CMC_ADJUSTMENT_IS_LISTING",
  IS_FINDING: "CMC_ADJUSTMENT_IS_FINDING",
  IS_SAVING: "CMC_ADJUSTMENT_IS_SAVING",
  IS_REMOVING: "CMC_ADJUSTMENT_IS_REMOVING",
  PAGE_STATES: "CMC_ADJUSTMENT_PAGE_STATE",
});
