<template>
  <div>
    <PageHeader title="CONTAS A RECEBER"
    icon="/img/icons/finance.png"
    type="danger">
      <PageHeaderButton @click="handleCreateModal"
        text="Novo"
        type="success"
        icon="/img/icons/plus-math--v1-white.png" />
      <PageHeaderButton
        :isDropdown="true"
        text="Relatórios"
        type="dark"
        icon="/img/icons/icons8/ios/graph-report.png">
        <PageHeaderButtonDropItem text="XLSX" icon="/img/icons/excel.png" @click="showModalBillReceiveExcelReport" />
        <PageHeaderButtonDropItem text="PDF" icon="/img/icons/pdf-v2.png" @click="handleShowModalReport()" />
      </PageHeaderButton>
    </PageHeader>
    <div class="container-fluid">
      <div class="mt-4 ml-2 mb-n2">
        <div style="display: flex; align-items: center">
          <i :class="listType !== 'cards' && 'opacity-25'"
            style="font-size: 24px;"
            @click.prevent="setViewType('cards')"
            class="pointer fa-regular fa-hard-drive fa-2x">
          </i>
          <i :class="listType !== 'table' && 'opacity-25'"
            style="font-size: 24px;"
            @click.prevent="setViewType('table')"
            class="pointer fa-solid fa-table-list fa-2x ml-1">
          </i>
        </div>
      </div>
      <div>
        <multi-filter
          :type="5"
          @handleFilterDate="handleFilterDate"
          @clearFilter="clearFilter()" @fetch="init">
          <template slot="dropdown-instead-input">
            <puzl-select
              style="width: 100%"
              v-model.lazy="filter.bank_account_id"
              :items="$_bank_accounts"
              placeholder="Contas"
              @change="init({})"
              multiple
              class="select-xl"
              :loading="loadingBankAccounts"
              :disabled="loadingBankAccounts"/>
          </template>
          <template slot="dates">
            <div class="row w-100 m-0">
              <div class="col-12 mt-2 text-left">
                <label class="form-control-label">
                  Vencimento
                </label>
                <input-date-picker @handleFilterDate="handleFilterDueDate" visibility="focus"/>
              </div>
              <div class="col-12 mt-4 text-left">
                <label class="form-control-label">
                  Vencimento original
                </label>
                <input-date-picker @handleFilterDate="handleFilterOriginalDate" visibility="focus"/>
              </div>
              <div class="col-12 mt-4 mb-2 text-left">
                <label class="form-control-label">
                  Data do crédito
                </label>
                <input-date-picker @handleFilterDate="handleFilterPaidDate" visibility="focus"/>
              </div>
            </div>
          </template>
          <template slot="data">
            <div class="col-md-12 mt-3 text-left">
              <label class="form-control-label">Método de pagamento</label>
              <puzl-select
                :items="$_payment_methods"
                v-model="filter.payment_method_ids"
                label="name"
                :multiple="true"
                class="select-xl new-default-black-font"
                @input="init({})"
              />
            </div>
            <div class="col-md-12 mt-2 text-left">
              <label class="form-control-label">Vendedor</label>
              <puzl-select
                :items="users"
                v-model="filter.seller_ids"
                label="name"
                :multiple="true"
                class="select-xl new-default-black-font"
                @input="init({})"
              />
            </div>
            <div class="row pr-3 mt-2 pl-3">
              <div class="col-12 text-left">
                <label class="form-control-label">
                  Valor da parcela
                </label>
                <div class="row">
                  <div class="col-6 text-left pr-1">
                    <base-input
                      inputmode="numeric"
                      class=""
                      placeholder="de"
                      @blur="init({})"
                      @keyup.enter="init({})"
                      @keyup="validInput($event, 'min')"
                      v-model="filter.range_min_value"
                      input-group-classes="input-group">
                      <template slot="prepend">
                        <small class="input-group p-0 m-0">
                          R$
                        </small>
                      </template>
                    </base-input>
                  </div>
                  <div class="col-6 pl-1">
                    <base-input
                      inputmode="numeric"
                      class=""
                      placeholder="até"
                      @blur="init({})"
                      @keyup.enter="init({})"
                      @keyup="validInput($event, 'max')"
                      v-model="filter.range_max_value"
                      input-group-classes="input-group">
                      <template slot="prepend">
                        <small class="input-group p-0 m-0">
                          R$
                        </small>
                      </template>
                    </base-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="row pr-3 mt-2 pl-3">
              <div class="col-12 text-left">
                <label class="form-control-label">
                  Número da fatura
                </label>
                <div class="row">
                  <div class="col-6 text-left pr-1">
                    <input @blur="init({})" v-model="filter.range_min_invoice" placeholder="de" class="form-control btn-first-child"/>
                  </div>
                  <div class="col-6 pl-1">
                    <input @blur="init({})" v-model="filter.range_max_invoice" placeholder="até" class="form-control btn-first-child" />
                  </div>
                </div>
              </div>
            </div>
<!--             <div class="row pr-3 mt-2 pl-3">
              <div class="col-12 text-left">
                <label class="form-control-label">
                  Número da NFs-e
                </label>
                <div class="row">
                  <div class="col-6 text-left pr-1">
                      @input="updated" v-model="filter.test_age.min"
                    <input placeholder="de" class="form-control btn-first-child"/>
                  </div>
                  <div class="col-6 pl-1">
                      @input="updated" v-model="filter.test_age.max"
                    <input placeholder="até" class="form-control btn-first-child" />
                  </div>
                </div>
              </div>
            </div> -->
          </template>
          <template slot="status">
            <div class="col-md-12 mt-3 text-left">
              <puzl-select
                :items="status"
                v-model="filter.status"
                class="select-xl new-default-black-font"
                :multiple="true"
                @input="init({})"
              />
            </div>
          </template>
          <template slot="bookmarks">
            <div class="col-12 mt-1">
              <base-button-hoverable :active="filter.compensate"
                @click="filter.compensate = Number(!filter.compensate);init({})"
                type="indigo"
                icon="us-dollar-circled"
                platform="ios">
                Compensação
              </base-button-hoverable>
            </div>
            <div class="col-12 mt-2">
              <base-button-hoverable :active="filter.pending"
                @click="filter.pending = Number(!filter.pending);init({})"
                type="danger"
                icon="error--v1"
                platform="ios">
                Inadimplência
              </base-button-hoverable>
            </div>
            <div class="col-12 mt-2 mb-6">
              <base-button @click.prevent="sendToEmail()"
                type="primary"
                outline
                block
                class="new-default-black-font colorize-on-hover"
                style="border: 1px solid #E8E8E8 !important;box-shadow: none;"
                :class="send_to_email && 'active'">
                <img :src="send_to_email ? '/img/icons/upload-mail-white.png' : '/img/icons/upload-mail-blue.png'"
                  width="19" class="imgBaseButton invert-on-hover">
                Envio por e-mail
              </base-button>
            </div>
          </template>
          <template slot="order-by">
            <el-popover trigger="click" placement="bottom" class="p-0 float-right pr-0 ml-3">
              <div class="card" style="margin: -14px; border-radius: 4px !important;">
                <div @click.prevent="handleFilterOrderBy('due_date.asc')"
                  :class="filter.order_by === 'due_date.asc' && 'text-primary'"
                  class="pointer px-3 pb-2 pt-3 new-default-black-font font-weight-500"
                  style="font-size: 11px;">
                  <i class="fa-solid fa-angles-down"
                    style="font-size: 12px;margin-right: 5px"></i>
                  DATA DE VENCIMENTO: DA MAIS NOVA PARA A MAIS VELHA
                </div>
                <hr class="gray-divider p-0">
                <div @click.prevent="handleFilterOrderBy('due_date.desc')"
                  :class="filter.order_by === 'due_date.desc' && 'text-primary'"
                  class="pointer px-3 py-2 new-default-black-font font-weight-500"
                  style="font-size: 11px;">
                  <i class="fa-solid fa-angles-up"
                    style="font-size: 12px;margin-right: 5px"></i>
                  DATA DE VENCIMENTO: DA MAIS VELHA PARA A MAIS NOVA
                </div>
                <hr class="gray-divider p-0">
                <div @click.prevent="handleFilterOrderBy('total_value.desc')"
                  :class="filter.order_by === 'total_value.desc' && 'text-primary'"
                  class="pointer px-3 py-2 new-default-black-font font-weight-500"
                  style="font-size: 11px;">
                  <i class="fa-solid fa-angles-down"
                    style="font-size: 12px;margin-right: 5px"></i>
                  VALOR: DO MAIOR PARA O MENOR
                </div>
                <hr class="gray-divider p-0">
                <div @click.prevent="handleFilterOrderBy('total_value.asc')"
                  :class="filter.order_by === 'total_value.asc' && 'text-primary'"
                  class="pointer px-3 py-2 new-default-black-font font-weight-500"
                  style="font-size: 11px;">
                  <i class="fa-solid fa-angles-up"
                    style="font-size: 12px;margin-right: 5px"></i>
                  VALOR: DO MENOR PARA O MAIOR
                </div>
              </div>
              <a href="#" slot="reference">
                <h5 slot="reference" class="pt-1">ORDENAR <i class="fa-solid fa-chevron-down"></i></h5>
              </a>
            </el-popover>
          </template>
        </multi-filter>
        <loading-pagination :show="loading && !loadingSkeleton"/>
      </div>
      <Cards v-if="listType === 'cards'"
        :listType="listType"
        :bill_receives="bill_receives"
        :send_to_email="send_to_email"
        :send_emails="send_emails"
        :paymentInstallmentStatusEnum="paymentInstallmentStatusEnum"
        @handleEditBillReceive="handleEditBillReceive"
        @handleShowHistory="handleShowHistory"
        @handleOpenModalInfo="handleOpenModalInfo"
        @handleIdentifyEntity="handleIdentifyEntity"
        @handleModalEntityRegistry="handleModalEntityRegistry"
        @handleDeleteBillReceive="handleDeleteBillReceive"
        @handleListContacts="handleListContacts"
        @handleModalEntityHistory="handleModalEntityHistory"
        @handlePayOffPayment="handlePayOffPayment"
        @showModalEntityPayment="showModalEntityPayment"
        @downloadInvoice="downloadInvoice"
        @nfseButtonClick="nfseButtonClick"
        @downloadBilletBatch="downloadBilletBatch"
        @openModalChangeDueDate="openModalChangeDueDate"
        @showModalEditGenerateBilletHasGenerated="showModalEditGenerateBilletHasGenerated"
        @showModalGenerateBillet="showModalGenerateBillet"
        @showModalGenerateBilletHasGenerated="showModalGenerateBilletHasGenerated"
        @postBatchUpdate="postBatchUpdate"
        @handleShowAttachments="handleShowAttachments"
        @addSendToEmails="addSendToEmails"
        :showCompensationButtons="showCompensationButtons"
        :compareBalance="compareBalance"
        :changeInstallmentStatus="changeInstallmentStatus"
        :formatCurrency="formatCurrency"
        :loadingSkeleton="loadingSkeleton" />


      <Table v-if="listType === 'table'"
        :listType="listType"
        :bill_receives="bill_receives"
        :paymentInstallmentStatusEnum="paymentInstallmentStatusEnum"
        @handleEditBillReceive="handleEditBillReceive"
        @handleShowHistory="handleShowHistory"
        @handleOpenModalInfo="handleOpenModalInfo"
        @handleIdentifyEntity="handleIdentifyEntity"
        @handleModalEntityRegistry="handleModalEntityRegistry"
        @handleDeleteBillReceive="handleDeleteBillReceive"
        @handleListContacts="handleListContacts"
        @handleModalEntityHistory="handleModalEntityHistory"
        @handlePayOffPayment="handlePayOffPayment"
        @showModalEntityPayment="showModalEntityPayment"
        @downloadInvoice="downloadInvoice"
        @nfseButtonClick="nfseButtonClick"
        @downloadBilletBatch="downloadBilletBatch"
        @openModalChangeDueDate="openModalChangeDueDate"
        @showModalEditGenerateBilletHasGenerated="showModalEditGenerateBilletHasGenerated"
        @showModalGenerateBillet="showModalGenerateBillet"
        @showModalGenerateBilletHasGenerated="showModalGenerateBilletHasGenerated"
        @postBatchUpdate="postBatchUpdate"
        @handleShowAttachments="handleShowAttachments"
        :showCompensationButtons="showCompensationButtons"
        :compareBalance="compareBalance"
        :changeInstallmentStatus="changeInstallmentStatus"
        :formatCurrency="formatCurrency"
        :loadingSkeleton="loadingSkeleton" />

      <PuzlEmptyData v-if="!bill_receives.length && !loadingSkeleton"></PuzlEmptyData>
      <div v-if="send_to_email" class="bg-white floating-button">
        <base-button @click.prevent="sendSelectedToEmail()"
          style="border-radius: 0px !important;background: white; border: none; align-items: center;display: flex;"
          block
          class="text-primary">
          <img class="mr-2" src="/img/icons/icons8/ios/mail_primary--v1.png" width="19" style="display: inline-flex;">
          Enviar selecionadas
          <div class="no-shadow-card-blue ml-2" style="display: inline-flex;">
            <h4 class="m-0 p-0" style="display: inline-flex;">
              {{ send_emails.length }} / {{ bill_receives.length }}
            </h4>
          </div>
        </base-button>
      </div>
      <CreateBillReceive @added="addedBillReceive" ref="modalCreate"/>
      <ModalBankBilletRemittance ref="ModalBankBilletRemittance" :show="showModaModalBankBilletRemittance"
                                 @close="showModaModalBankBilletRemittance = false"/>
      <ModalBankBilletDischarge ref="ModalBankBilletDischarge" :show="showModaModalBankBilletDischarge"
                                @close="showModaModalBankBilletDischarge = false"/>
      <ModalBillReceiveReport ref="modalBillReceiveReport"/>
      <modal-search-entity @setEntity="setEntity" ref="modalSearchEntity"/>
      <modal-create-entity @added="addedBillReceive" ref="modalCreateEntity"/>
      <modal-report ref="modalReport"/>


      <EditBillReceive @updated="updateBillReceive" ref="editBillReceive"/>
      <ModalAttachment ref="modalAttachment"/>
      <ModalHistory ref="ModalHistory"/>
      <ModalAlterStatus
        ref="ModalAlterStatus"
        :show="showModalAlterStatus"
        @close="closeModalAlterStatus"
        @saveAndClose="saveAndCloseAlterInstallment"/>
      <ModalListContacts ref="listContacts"/>
      <modal-generate-billet ref="modalGenerateBillet"/>
      <modal-generate-billet-has-generated @fetch="load()" ref="modalGenerateBilletHasGenerated"/>
      <modal-add-invoice ref="modalAddInvoice"/>
      <modal-search-entity @setEntity="setEntity" ref="modalSearchEntity"/>
      <modal-edit-entity @updated="load()" ref="modalEditEntity"/>
      <modal-info ref="modalInfo"/>
      <modal-edit @showInvoices="load(filter)" ref="modalEdit"/>
     <modal-change-due-date @updated="load()" ref="ModalChangeDueDate"/>
      <modal-confirm-payment
        :return_payment="false"
        @updateInstallment="updateInstallment"
        ref="modalConfirmPayment"
      />
      <ModalEntityHistory @updatedAfterAnticipationBalanceTransfer="updateAfterAnticipationBalanceTransfer"
                          ref="modalEntityHistory"/>
      <ModalEntityRegistry ref="modalEntityRegistry" @storedEntityRegistry="storedAfterEntityRegistration"/>
    </div>
  </div>
</template>

<script>
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import { PageHeader, PageHeaderButton, PageHeaderButtonDropItem } from "@/components";
import Cards from "./Shared/Lists/_Cards";
import Table from "./Shared/Lists/_Table";
import CreateBillReceive from "./Shared/Modals/_Create";
import ModalBankBilletRemittance from "./Shared/Modals/_ModalBankBilletRemittance";
import ModalBankBilletDischarge from "./Shared/Modals/_ModalBankBilletDischarge";
import ModalBillReceiveReport from "./Shared/Modals/_ModalBillReceiveReport";
import ModalSearchEntity from "./Entity/Shared/Modals/_ModalSearchEntity";
import ModalCreateEntity from './Entity/Shared/Modals/_ModalCreateEntity';
import ModalReport from './Shared/Modals/_ModalReport';
import MultiFilter from "@/components/Utils/MultiFilterV3";
import ModalHistory from "./Shared/Modals/_ModalHistory";
import ModalAlterStatus from "./Shared/Modals/_ModalAlterStatus";
import ModalConfirmPayment from "./Shared/Modals/_ModalConfirmPayment";
import ModalGenerateBillet from './Shared/Modals/_ModalGenerateBillet';
import ModalGenerateBilletHasGenerated from './Shared/Modals/_ModalGenerateBilletHasGenerated';
import ModalAddInvoice from '../../Commercial/ContractProposal/Payments/Shared/_ModalAddInvoice';
import ModalListContacts from "@/views/Modules/Operational/Schedule/Weighing/Shared/_ModalListContacts";
import {mapGetters} from "vuex";
import ModalAttachment from "./Shared/Modals/_ModalAttachment";
import ModalInfo from './Shared/Modals/_ModalInfo.vue';
import ModalChangeDueDate from './Shared/Modals/_ModalChangeDueDate.vue';
import ModalEntityHistory from "@/views/Modules/Configuration/Entity/Shared/_ModalHistory";
import ModalEntityRegistry from "@/views/Modules/Configuration/Entity/Shared/_ModalEntityRegistry";
import ModalEditEntity from './Entity/Shared/Modals/_ModalEditEntity';
import EditBillReceive from "./Shared/Modals/_Edit";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SpinnerPuzl from "@/components/SpinnerPuzl";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import {base_url_ms, debounce} from '@/plugins';
import {formatErrorValidation} from "@/plugins";
import PuzlSelect from "@/components/PuzlSelect";
import Pagination from '@/components/Utils/Pagination'
import SkeletonPuzl from "@/components/SkeletonPuzl.vue";
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import moment from "moment";
import InputDatePicker from '@/components/InputDatePicker';
import {setPreferredViewMode, getPreferredViewMode} from '@/plugins/session';
import {BillReceiveInstallmentEnum} from "@/enum/BillReceiveInstallmentEnum";
import ModalEdit from '@/views/Modules/Commercial/ContractProposal/Payments/Shared/_Edit.vue'

const PAYMENT_INSTALLMENT_STATUS_ENUM = Object.freeze({
  PENDING: {
    ID: 0,
    NAME: 'Pendente',
  },
  RECEIVED: {
    ID: 1,
    NAME: 'Recebido',
  },
  LATE: {
    ID: 2,
    NAME: 'Atrasado',
  },
  CANCELED: {
    ID: 3,
    NAME: 'Cancelado',
  },
  SERASA: {
    ID: 4,
    NAME: 'Serasa',
  },
  CARTORIO: {
    ID: 5,
    NAME: 'Cartório'
  },
  PROTESTED: {
    ID: 6,
    NAME: 'Protestado'
  },
  LEGAL: {
    ID: 7,
    NAME: 'Jurídico',
  },
  APPROVED: {
    ID: 8,
    NAME: 'Aprovado',
  },
  DENIED: {
    ID: 9,
    NAME: 'Negado'
  },
});

export default {
  name: "IndexBillReceive",
  mixins: [cursorPaginate],
  components: {
    ModalSearchEntity,
    PuzlBreadcrumb,
    PageHeader,
    PageHeaderButton,
    PageHeaderButtonDropItem,
    Cards,
    Table,
    CreateBillReceive,
    ModalBankBilletRemittance,
    ModalBankBilletDischarge,
    ModalBillReceiveReport,
    ModalCreateEntity,
    ModalReport,
    ModalAddInvoice,
    ModalListContacts,
    ModalAttachment,
    ModalSearchEntity,
    ModalInfo,
    EditBillReceive,
    PuzlEmptyData,
    BaseButtonHoverable,
    SkeletonPuzl,
    ModalAlterStatus,
    ModalConfirmPayment,
    LoadingPagination,
    ModalGenerateBillet,
    ModalGenerateBilletHasGenerated,
    ModalHistory,
    EditBillReceive,
    PuzlEmptyData,
    SpinnerPuzl,
    MultiFilter,
    ModalEditEntity,
    PuzlSelect,
    Pagination,
    ModalInfo,
    ModalChangeDueDate,
    ModalEntityHistory,
    ModalEntityRegistry,
    InputDatePicker,
    ModalEdit,
  },
  computed: {
    ...mapGetters({
      bill_receives_settled: "billReceive/fetch",
      $_bank_accounts: "bankAccount/fetch",
      $_payment_methods: "paymentMethod/fetch",
    })
  },
  data() {
    return {
      added: false,
      showModaModalBankBilletRemittance: false,
      showModaModalBankBilletDischarge: false,
      loadingBankAccounts: true,
      base_url_ms: base_url_ms(),
      loadingSkeleton: false,
      loadingInstallmentStatus: false,
      hasMore: null,
      listType: 'cards',
      bill_receives: [],
      loadingUsers: true,
      loadingPaymentMethods: true,
      users: [],
      filter: {
        status: [],
        order_by: null,
        range_min_value: null,
        range_max_value: null,
        number_nfse_min: null,
        number_nfse_max: null,
        number_invoice_min: null,
        number_invoice_max: null,
      },
      type: 1,
      send_to_email: false,
      range: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        end: new Date(),
      },
      params: {
        page: 1,
        per_page: 5,
      },
      disableScrollPaginate: false,
      view_type: 2,
      bill_receive_to_identify: null,
      loadingSkeletonTable: false,
      send_emails: [],
      scrolledCurrent: false,
      loadingSpinner: false,
      loadingDetails: false,
      showModalAlterStatus: false,
      paymentInstallmentStatusEnum: PAYMENT_INSTALLMENT_STATUS_ENUM,
      BillReceiveInstallmentEnum: BillReceiveInstallmentEnum,
      status: [
        {
          id: 2,
          name: "Pendente/Atrasado"
        },
        {
          id: 1,
          name: "Recebido"
        },
        {
          id: 3,
          name: "Cancelado"
        },
        {
          id: 4,
          name: "Serasa"
        },
        {
          id: 5,
          name: "Cartório"
        },
        {
          id: 6,
          name: "Protestado"
        },
        {
          id: 7,
          name: "Jurídico"
        },
      ],
      multi_filter: {
        null: {
          name: "Todos",
          type: "primary"
        },
        '0': {
          name: "Pendente",
          type: "warning"
        },
        '1': {
          name: "Recebido",
          type: "success"
        },
        '2': {
          name: "Atrasado",
          type: "danger"
        },
        '3': {
          name: "Cancelado",
          type: "danger"
        },
        '8': {
          name: "Aprovado",
          type: "primary"
        },
        '9': {
          name: "Negado",
          type: "danger"
        },
        '10': {
          name: "Outros",
          type: "info"
        }
      },
    };
  },
  watch: {
    added() {
      this.init();
    }
  },
  methods: {
  /**
   * Descrição: Identifica qual input está sendo modificado, range_min_value ou range_max_value
   * @param {object} event
   * @param {string} inputType
   */
    validInput(event, inputType) {
      const targetProperty = inputType === 'min' ? 'range_min_value' : 'range_max_value';
      this.filter[targetProperty] = this.formatBRL(event, this.filter[targetProperty]);
    },
    /**
     * Descrição: formata os inputs range_min_value e range_max_value para currency
     * Obs.: Função criada devido problema com vmoney
     * @param {object} event
     * @param {string} val
     * @return {string}
     */
    formatBRL(event, val) {
      let newValue = val;

      let key = (!event) ? window.event.keyCode : event.which;

      if (key === 45 && newValue.indexOf('-') === -1 && newValue.length === 0) {
        newValue = '-';
        return;
      }

      let isNegative = false;

      if(newValue.includes("-") && newValue.length > 1){
        isNegative = true;
        newValue = newValue.replace("-","");
      }

      let value = this.reverseString(newValue.replace(/[^\d\-]+/gi, ''));
      let result = "";

      let mask = this.reverseString("##.###.###,##");

      for (var x = 0, y = 0; x < mask.length && y < value.length;) {
        if (mask.charAt(x) !== '#') {
          result += mask.charAt(x);
          x++;
        } else {
          result += value.charAt(y);
          y++;
          x++;
        }
      }

      newValue = isNegative ? "-" + this.reverseString(result) : this.reverseString(result);
      return newValue;
    },
    reverseString(str) {
      return str.split('').reverse().join('');
    },
    setViewType(type){
      this.listType = type;
      setPreferredViewMode(type);
    },
    isEqual(a, b, c = null) {
      a = a.toString().toLowerCase().trim()
      b = b.toString().toLowerCase().trim()
      if (a && b && c) {
        c = c.toString().toLowerCase().trim()
        return a === b && b === c && a === c
      }
      return a === b
    },
    formatDate(date) {
      moment.locale("pt-br");
      return moment(date)
        .format("ll")
        .replaceAll("de", "");
    },
    formatDateTime(date) {
      moment.locale("pt-br");
      return moment(date)
        .format("lll")
        .replaceAll("de", "");
    },
    showCompensationButtons(bill_receive) {
      return [this.BillReceiveInstallmentEnum.PENDING, this.BillReceiveInstallmentEnum.OVERDUE].includes(bill_receive.status) &&
        (
          (
            bill_receive.type === 1 &&
            this.compareBalance(bill_receive.anticipation_balance, bill_receive.total_value) &&
            this.$hasPermission('bill_receive.receive_installment')
          ) || (
            this.$hasPermission('additional_permissions.financial_approvation') &&
            this.$helper.strToNum(bill_receive.anticipation_balance) > 0
          )
        );
    },
    /**
     * Compara o saldo de antecipação com o valor da fatura e retorna se o saldo é maior ou igual.
     * @param {string} anticipationBalance
     * @param {string} value
     * @returns {boolean}
     */
    compareBalance(anticipationBalance, value) {
      return this.$helper.strToNum(anticipationBalance) >= this.$helper.strToNum(value);
    },
    /**
     * Formata um valor como moeda brasileira
     * @param {string} value
     * @returns {string}
     */
    formatCurrency(value) {
      if (!value) {
        let val = 0
        return val.toString().toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
      }
      return this.$helper.strToNum(value).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
    },
    sendToEmail() {
      this.send_to_email = !this.send_to_email
      if (!this.send_to_email) {
        this.send_emails = []
      }
    },
    handleDeleteBillReceive(id) {
      this.$swal
        .fire({
          title: "Você tem certeza?",
          text:
            "Esta exclusão se remete a todas as parcelas desta solicitação, os registros não poderão ser recuperados!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, prosseguir!",
          cancelButtonText: "Não, mantenha",
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill"
          },
          buttonsStyling: false
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação."
            });
            this.$store
              .dispatch("billReceive/destroy", id)
              .then(response => {
                this.$Progress.finish();
                this.$notify({
                  type: response.error_type,
                  message: response.message
                });
                this.fetch();
              })
              .catch(error => {
                if (error.status == 200) {
                  this.$notify({
                    type: "danger",
                    message: error.data.message
                  });
                }
                this.$Progress.finish();
              });
          }
        });
    },
    /**
     * @param {string} construction_uuid
     */
    handleListContacts(construction_uuid) {
      this.$refs.listContacts.openModal(construction_uuid);
    },
    checkCardIntegration(competence) {
      let loader = this.$loading.show();
      const params = {startAt: competence, endAt: competence};
      this.$store.dispatch("billReceive/checkCardIntegration", params)
        .then(() => {
          this.$notify({
            type: "success",
            message: "Solicitação concluída com sucesso! Este processo será realizado em segundo plano.",
          });
        }).catch((error) => {
        const errors = error.status
          ? error.data.message
          : formatErrorValidation(error.response.data.errors);
        this.$notify({type: "danger", message: errors});
      }).finally(() => {
        loader.hide();
      });
    },
    /**
     * Abre a modal histórico do cliente
     * @param {number} entity_id
     * @param {string} entity_uuid
     * @param {string} entity_document
     * @param {string} entity_name
     */
    handleModalEntityHistory(entity_id, entity_uuid, entity_document, entity_name) {
      this.$refs.modalEntityHistory.openModal(
        entity_id,
        {
          id: entity_id,
          uuid: entity_uuid,
          document: entity_document,
          entity_name: entity_name,
        },
      );
    },
    /**
     * Abre a modal registros do cliente
     * @param {number} entity_id
     * @param {string} entity_uuid
     * @param {string} name
     * @param {string} document
     */
    handleModalEntityRegistry(entity_id, entity_uuid, name, document) {
      this.$refs.modalEntityRegistry.handleCreateModal(entity_id, entity_uuid, name, document);
    },
    fetchNextPageData() {
      this.scrolledCurrent = true;
      let params = {
        paginate: this.paginate
      };
      this.$store
        .dispatch("billReceive/fetchNextPage", params)
        .then(response => {
          this.hasMore = response.has_more;
          this.loadingSkeleton = false;
          this.scrolledCurrent = false;
          this.loadingSpinner = false;
        });
    },
    async getAttachments(id, type, index) {
      if (!this.bill_receives[index].attachments) {
        this.bill_receives[index].attachments = 1;
        await this.$store
          .dispatch("billReceive/getAttachments", {
            id: id,
            type: type
          })
          .then(response => {
            this.bill_receives[index].attachments = response.data;
            this.$forceUpdate();
          });
      }
    },
    sendSelectedToEmail() {
      this.$Progress.start()
      this.$Swal.confirmActionWithDenied('DESEJA RECEBER O E-MAIL COMO CÓPIA?', ['Sim', 'Não', 'Cancelar'], '', true, true)
        .then((result) => {
          if (result.isConfirmed) {
            this.sendToMail(true)
          }
          if (result.isDenied) {
            this.sendToMail(false)
          }
          if (result.isDismissed) {
            this.$Progress.finish()
          }
        }).catch(() => this.$Progress.finish())
    },
    /**
     * Requisitar atualização de lote de boletos
     * Método utilizado quando lote de boleto é gerado erroneamente
     * @param {*} bank_billet_batch_id
     * @returns {Promise}
     */
    postBatchUpdate(bank_billet_batch_id) {
      this.$Progress.start()
      let loader = this.$loading.show()
      this.$store.dispatch('bankBillet/postBatchUpdate', bank_billet_batch_id)
        .then((response) => {
          this.$notify({
            type: "info",
            message: "Requisição para atualizar lote enviada. Aguarde alguns minutos e efetue o download do lote."
          });
          loader.hide()
          this.$Progress.finish()
        }).catch((error) => {
        let errors = error.status
          ? error.data.message
          : formatErrorValidation(error.response.data.errors);
        this.$notify({type: "danger", message: errors});
        loader.hide();
        this.$Progress.finish();
      })
    },
    downloadBilletBatch(bank_billet_batch_id, last_billet) {
      if (!bank_billet_batch_id) {
        return this.$notify({
          type: 'warning',
          message: 'Lote em processamento. Entre em contato com nosso suporte para mais informações!'
        });
      }
      if (last_billet.status === 0) {
        return this.$notify({
          type: 'warning',
          message: "Boleto em processo de emissão. Aguarde alguns instantes."
        });
      }
      this.$Progress.start()
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      let loader = this.$loading.show()
      this.$store.dispatch('bankBillet/getPdfFileUrl', bank_billet_batch_id)
        .then(async (response) => {
          let blob = new Blob([response], {type: "application/pdf"});
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", `Lote ${bank_billet_batch_id}.pdf`);
          await link.click();
          this.$snotify.success('Download iniciado com sucesso!', {
            timeout: 1000,
            icon: false,
            position: "centerBottom",
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false
          });
          loader.hide()
        });
    },
    showModalGenerateBilletHasGenerated(id, total_index, type) {
      let loader = this.$loading.show()
      this.$store.dispatch("billReceive/getInfoBillet", {id: id, type: type}).then(response => {
        const billet = response.data
        this.$store.dispatch("billReceive/show", id).then(response => {
          const bill_receive = response.data
          bill_receive.total_index = total_index
          this.$refs.modalGenerateBilletHasGenerated.openModal(id, billet, bill_receive)
          loader.hide()
        });
      });
    },
    showModalEditGenerateBilletHasGenerated(id, total_index, type, index) {
      let loader = this.$loading.show()
      this.$store.dispatch("billReceive/getInfoBillet", {id: id, type: type}).then(response => {
        const billet = response.data
        this.$store.dispatch("billReceive/show", id).then(response => {
          const bill_receive = response.data
          bill_receive.total_index = total_index
          this.$refs.modalGenerateBilletHasGenerated.openModal(id, billet, bill_receive, index)
          loader.hide()
        });
      });
    },
    showModalGenerateBillet(id, total_index, type) {
      let loader = this.$loading.show()
      this.$store.dispatch("billReceive/getInfoBillet", {id: id, type: type}).then(response => {
        const billet = response.data
        this.$store.dispatch("billReceive/show", id).then(response => {
          const bill_receive = response.data
          bill_receive.total_index = total_index
          this.$refs.modalGenerateBillet.openModal(id, billet, bill_receive)
          loader.hide()
        });
      });
    },
    downloadInvoice(id) {
      this.$Progress.start()
      let loader = this.$loading.show()
      this.$store.dispatch('billingInvoice/download', {
          id: id
        }
      ).then(response => {
        let blob = new Blob([response],
          {type: 'application/pdf'})
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute("download", 'report.pdf');
        window.open(link, '_blank')
        loader.hide()
        this.$Progress.finish()
      }).catch(error => {
        loader.hide()
        this.$Progress.finish()
      })
    },
    downloadAdvanceReceipt(id) {
      this.$Progress.start()
      let loader = this.$loading.show()
      this.$store.dispatch('billingInvoice/downloadAdvanceReceipt', {
          entity_bill_receive_id: id
        }
      ).then(response => {
        let blob = new Blob([response],
          {type: 'application/pdf'})
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute("download", 'report.pdf');
        window.open(link, '_blank')
        loader.hide()
        this.$Progress.finish()
      }).catch(error => {
        loader.hide()
        this.$Progress.finish()
      })
    },
    handleFilterDate(filter) {
      this.range = filter
      this.filter.date_type = null
      if (this.view_type === 1) {
        return this.getPaginate({})
      }
      return this.init({})
    },
    handleFilterDueDate(filter) {
      this.range = filter
      this.filter.date_type = 'due_date'
      if (this.view_type === 1) {
        return this.getPaginate({})
      }
      return this.init({})
    },
    handleFilterPaidDate(filter) {
      this.range = filter
      this.filter.date_type = 'paid_at'
      if (this.view_type === 1) {
        return this.getPaginate({})
      }
      return this.init({})
    },
    handleFilterOriginalDate(filter) {
      this.range = filter
      this.filter.date_type = 'original_due_date'
      if (this.view_type === 1) {
        return this.getPaginate({})
      }
      return this.init({})
    },
    handleFilterAdvancedStatus(key) {
      if (this.filter[key]) {
        delete this.filter[key]
      } else {
        this.filter[key] = true
      }
      this.load({})
    },
    getPaginate(filter = null) {
      if (filter) {
        this.filter = {...this.filter, ...filter};
      }
      this.filter.type = this.type
      this.filter.view_type = this.view_type
      this.filter.range = this.range
      this.startCursor(filter)
      this.$store.dispatch('billReceive/fetch',
        {
          filter: this.filter,
          next_page: this.paginate.nextUrl
        }).then(response => {
        this.loadingSkeletonTable = false
        this.resolveCursor(response)
      }).catch(error => {
      })
    },
    init(filter = null) {
      if (this.disableScrollPaginate) {
        return this.getPaginate(filter)
      }

      let hideCancelled = false;
      let needPendingToBeAdded = false;

      if(!this.filter.status.length) {
        this.filter.status = [
          this.paymentInstallmentStatusEnum.RECEIVED.ID,
          this.paymentInstallmentStatusEnum.CARTORIO.ID,
          this.paymentInstallmentStatusEnum.APPROVED.ID,
          this.paymentInstallmentStatusEnum.PROTESTED.ID,
          this.paymentInstallmentStatusEnum.PENDING.ID,
          this.paymentInstallmentStatusEnum.LATE.ID,
          this.paymentInstallmentStatusEnum.SERASA.ID,
          this.paymentInstallmentStatusEnum.DENIED.ID,
          this.paymentInstallmentStatusEnum.LEGAL.ID,
        ];
        hideCancelled = true;
      } else {
        if(this.filter.status.includes(this.paymentInstallmentStatusEnum.LATE.ID)) {
          this.filter.status.push(this.paymentInstallmentStatusEnum.PENDING.ID);
          needPendingToBeAdded = true;
        }
      }

      this.startCursor(filter)
      this.filter.type = this.type
      this.$store.dispatch('billReceive/fetch',
        {
          filter: this.filter,
          page: this.params.page,
          next_page: this.paginate.nextUrl
        }).then(response => {
        this.bill_receives = this.bill_receives_settled;
        this.resolveCursor(response)
      }).catch(error => {
        this.resolveCursor()
      })

      if(hideCancelled) this.filter.status = [];
      else if(needPendingToBeAdded) this.filter.status = this.filter.status.slice(0, -1);
    },
    saveAndCloseAlterInstallment(params) {
      this.loadingInstallmentStatus = false
      if (params.status == 3) {
        this.init({})
      }
      this.bill_receives[params.index].status = parseInt(params.status);
      this.showModalAlterStatus = false
    },
    closeModalAlterStatus() {
      this.loadingInstallmentStatus = false
      this.showModalAlterStatus = false
    },
    handleShowHistory(bill_receive) {
      this.$refs.ModalHistory.openModal(bill_receive)
    },
    handleEditBillReceive(payment_uuid, contract_proposal_uuid) {
        this.$refs.modalEdit.handleCreateModal(payment_uuid, contract_proposal_uuid)
    },
    changeInstallmentStatus(id, type, status, index, unique = 0) {
      status = parseInt(status)
      if (this.bill_receives[index].last_billet && this.bill_receives[index].last_billet.status === 1 && status === 1) {
        return this.$Swal.confirmAction('O Boleto já foi emitido para esta parcela, se continuar, o boleto será cancelado.', ['Sim', 'Não'])
          .then((result) => {
            if (result.isConfirmed) {
              let loader = this.$loading.show()
              return Promise.all([
                this.$store.dispatch("billReceive/showLight", this.bill_receives[index].id),
                this.$store.dispatch("companyPlantBankAccount/getByCompanyPlant", {
                  allocated: 1,
                  company_plant_id: this.bill_receives[index].company_plant_id
                })
              ]).then(() => {
                loader.hide()
                return this.$refs.modalConfirmPayment.handleCreateModal(this.bill_receives[index], disabledChangeBankAccount)
              })
            }
          }).catch()
      }
      if (this.loadingInstallmentStatus) {
        return;
      }
      // if (this.bill_receives[index].last_billet.status === 3 && status != 1) {
      //   this.$notify({
      //     type: "warning",
      //     message: "Não foi possível alterar o status. Há uma parcela com pagamento confirmado para este pagamento"
      //   });
      //   return;
      // }
      if (parseInt(status) === 1) {
        let loader = this.$loading.show()
        return Promise.all([
          this.$store.dispatch("billReceive/showLight", this.bill_receives[index].id),
          this.$store.dispatch("companyPlantBankAccount/getByCompanyPlant", {
            allocated: 1,
            company_plant_id: this.bill_receives[index].company_plant_id
          })
        ]).then(() => {
          loader.hide()
          const total_value = this.bill_receives[index].total_value
          const disabledChangeBankAccount = (this.bill_receives[index].billets.length > 0);
          return this.$refs.modalConfirmPayment.handleCreateModal(this.bill_receives[index], disabledChangeBankAccount)
        })
      }
      this.loadingInstallmentStatus = true;
      if (unique || ![1, 8, 9].includes(parseInt(status))) {
        this.showModalAlterStatus = true
        return this.$refs.ModalAlterStatus.setBillReceive({
          id: id,
          type: type,
          status: status,
          index: index,
          received_at: this.bill_receives[index].paid_at ? this.bill_receives[index].paid_at : null
        })
      }
      this.$Progress.start();
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      let params = {
        id: id,
        status: status,
        type: type
      };
      this.$store
        .dispatch("billReceive/changeInstallmentStatus", params)
        .then(response => {
          this.bill_receives[index].status = parseInt(status);
          if (status == 1) {
            this.bill_receives[index].paid_at = new Date();
          }
          this.loadingInstallmentStatus = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.init({})
        })
        .catch(error => {
          this.$notify({
            type: error.data.error_type,
            message: error.data.message
          });
          if (error.data.open_modal_edit) {
            const bill_receive = this.bill_receives.find((item) => item.installment_id == id)
            this.handleEditBillReceive(
              bill_receive.id,
              bill_receive.type,
              bill_receive.status
            )
          }
          this.loadingInstallmentStatus = false;
          this.$Progress.finish();
        });
    },
    load(filter = null) {
      if (this.view_type === 1) {
        return this.getPaginate(filter)
      }
      return this.init(filter)
    },
    addSendToEmails(id) {
      if (this.send_emails.includes(id)) {
        const index = this.send_emails.findIndex(item => item === id)
        this.send_emails.splice(index, 1)
      } else {
        this.send_emails.push(id)
      }
    },
    async handleOpenAddInvoice(uuid) {
      let loader = this.$loading.show()
      const payment = await this.$store.dispatch('contractProposalPayment/show', uuid).then(response => {
        return response.data
      })
      this.$store.dispatch("billingInvoice/init", {
        filter: {
          pending_payment: 1,
          bill_receive_id: payment.billable_id,
          contract_proposal_id: payment.contract_proposal_id,
          company_plant_id: payment.company_plant_id,
          company_plant_issuer_id: payment.company_plant_issuer_id,
          not_canceled: true,
        }
      }).then(response => {
        const all_invoices = response.data
        let invoices_paid = payment.invoices_paid
        let total_value = 0;
        const invoices = payment.invoices
        invoices.map(function (id) {
          let invoice = all_invoices.find(function (item) {
            return item.id === id;
          });
          const paid_for_this_payment = invoices_paid[invoice.id] || 0
          total_value += Number(invoice.pending_billing) + Number(paid_for_this_payment);
        });
        let invoiced_amount = total_value.toFixed(2);
        let payment_total_value = payment.total_value
          .replace('.', '').replace(',', '')
        let balance = parseFloat(payment_total_value)
          - parseFloat(invoiced_amount.replace('.', '')
            .replace(',', ''))
        if (balance <= 0) {
          loader.hide()
          return this.$notify({
            type: "warning",
            message: "Não há saldo disponível para inserção de novas faturas."
          });
        }
        loader.hide()
        this.$refs.modalAddInvoice.openModal({
          balance: balance,
          initial_balance: balance,
          uuid: uuid,
          bill_receive_id: payment.billable_id,
          invoiced_amount: invoiced_amount,
          contract_proposal_id: payment.contract_proposal_id,
          company_plant_id: payment.company_plant_id,
          company_plant_issuer_id: payment.company_plant_issuer_id,
        })
      })
    },
    /**
     * Identifica depositos não identificados
     * @param bill_receive
     */
    handleIdentifyEntity(bill_receive) {
      this.bill_receive_to_identify = bill_receive
      this.$refs.modalSearchEntity.openModal(false)
    },
    setEntity(entity) {
      let loader = this.$loading.show()
      this.$Progress.start()
      let params = {
        entity_bill_receive_id: this.bill_receive_to_identify.entity_bill_receive_id,
        id: this.bill_receive_to_identify.id,
        entity_id: entity.entity.id
      }
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.$store.dispatch('billReceive/postIdentify', params).then(response => {
        loader.hide()
        this.$notify({type: response.error_type, message: response.message});
      }).catch((error) => {
        let errors = error.status
          ? error.data.message
          : formatErrorValidation(error.response.data.errors);
        this.$notify({type: "danger", message: errors});
      }).finally(() => {
        loader.hide()
        this.$Progress.finish()
      })
    },
    sendToMail(copy_to_user) {
      this.$store.dispatch("billReceive/postSendToEmail", {
        send_emails: this.send_emails,
        copy: copy_to_user,
      }).then(response => {
        this.send_emails = []
        this.send_to_email = false
        return this.$notify({
          type: 'success',
          message: 'Solicitação concluída com sucesso! Este processo será realizado em segundo plano.'
        })
      })
    },
    nfseButtonClick(link) {
      this.$notify({type: 'success', message: 'Download iniciado com sucesso!'});
      window.open(link, '_parent');
    },
    async handleShowAttachments(bill_receive, index) {
      let loader = this.$loading.show()
      await this.getAttachments(bill_receive.id, bill_receive.type, index)
      const item = this.bill_receives[index]
      loader.hide()
      this.$refs.modalAttachment.openAttachment(item.attachments)
    },
    async handlePayOffPayment(bill_receive) {
      let loader = this.$loading.show()
      const payment = await this.$store.dispatch('contractProposalPayment/show', bill_receive.payment_uuid).then(response => {
        return response.data
      });
      const entity = await this.$store.dispatch('entity/show', bill_receive.entity_uuid)
        .then(response => response.data)
      loader.hide()
      let use_parcial = null
      let sum_pending = payment.installments.filter((item) => item.status === 0 || item.status === 2)
      sum_pending = sum_pending.reduce((accumulator, currentValue) => {
        return Number(accumulator) + Number(currentValue.total_value);
      }, 0);
      if (sum_pending > Number(entity.anticipation_balance)) {
        use_parcial = 1
      }
      let has_balance_to_paid = payment.installments
        .filter((item) => [0, 2].includes(item.status) && Number(item.value) < Number(entity.anticipation_balance))

      if (!has_balance_to_paid) {
        this.$notify({
          type: "danger",
          message: "Não há saldo de antecipação suficiente para recebimento das parcelas."
        });
      }
      if (has_balance_to_paid && !use_parcial) {
        use_parcial = 0
      }
      let text = undefined
      if (use_parcial) {
        text = 'Não há saldo total para recebimento de todas as parcelas, deseja continuar? o recebimento será feito parcialmente'
      } else {
        text = 'Todas as parcelas pendentes serão recebidas, deseja continuar?'
      }
      this.$Swal.confirmAction(`${text}`, ['Sim', 'Não'])
        .then(async (result) => {
          if (result.isConfirmed) {
            let loader = this.$loading.show()
            this.$store.dispatch('contractProposalPayment/postPayOffPayment', payment.id).then(async response => {
              this.updateBillReceive(bill_receive.id)
              loader.hide()
            }).catch(error => {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              });
              loader.hide()
            })
          }
        })
    },
    handleOpenModalInfo(bill_receive) {
      this.$refs.modalInfo.handleOpenModal(bill_receive)
    },
    async showModalEntityPayment(uuid, installment_id, contract_proposal_uuid) {
      await this.$refs.modalEdit.handleCreateModal(uuid, contract_proposal_uuid);
      const installment = this.$refs.modalEdit.payload.installments.find((item) => item.id === installment_id)
      this.$refs.modalEdit.showModalEntityPayment(installment)
    },
    async updateBillReceive(id) {
      let newBillReceives = this.bill_receives;
      const index = newBillReceives.findIndex(bill_receive => bill_receive.id === id)

      const updated = await this.$store.dispatch('billReceive/showEqualList', id)
      newBillReceives[index].status = updated.data.status;
      newBillReceives[index] = updated.data;

      this.bill_receives = newBillReceives;
    },
    setRange: debounce(function () {
      this.init({})
    }, 100),
    handleFilterOrderBy(type) {
      this.filter.order_by = type
      this.init({})
    },
    clearFilter() {
      this.filter = {
        status: [],
        order_by: null,
        range_min_value: null,
        range_max_value: null,
        number_nfse_min: null,
        number_nfse_max: null,
        number_invoice_min: null,
        number_invoice_max: null,
      };
      this.init({});
    },
    handleFilterStatus(status) {
      // Encontra o índice do valor a ser removido
      let index = this.filter.status.indexOf(status);
      // Verifica se o valor foi encontrado
      if (index !== -1) {
        // Remove o elemento com base no índice encontrado
        this.filter.status.splice(index, 1);
      } else {
        this.filter.status.push(status)
      }
      this.init({})
    },
    // setViewType(type) {
    //   this.view_type = type
    //   if (type === 1) {
    //     this.disableScrollPaginate = true
    //     this.loadingSkeletonTable = true
    //     this.getPaginate({})
    //   } else {
    //     this.disableScrollPaginate = false
    //     this.init({})
    //   }
    // },
    navegate(page) {
      this.params.page = page
      this.getPaginate()
    },
    openModalChangeDueDate(installment_id, original_due_date){
      this.$refs.ModalChangeDueDate.handleCreateModal(installment_id, original_due_date);
    },
    handleCreateModal() {
      this.$refs.modalCreate.handleCreateModal();
    },
    updateInstallment(installment) {
      const index = this.bill_receives.findIndex(item => item.installment_id === installment.data.installment_id)
      this.bill_receives[index].status = installment.data.status
      this.bill_receives[index].bank_account_name = installment.data.bank_account_name
      this.bill_receives[index].paid_at = installment.data.paid_at
      this.$forceUpdate()
    },
    /**
     * Atualiza total do ícone de registros do cliente após realizar um cadastro.
     * @param {object} entityRegistry
     */
    storedAfterEntityRegistration(entityRegistry) {
      this.$store.dispatch('entity/getTotalRegistriesByEntityId', entityRegistry.entity_id).then(response => {
        let foundEntity = this.bill_receives.find((item) => item.entity_id == entityRegistry.entity_id);
        foundEntity.total_entity_registries = response.data.total_entity_registries;
      });
    },
    /**
     * Atualiza os dados após a transferência de saldo de antecipação entre clientes/fornecedores (entity).
     * @param {number} from_entity_id - ID do cliente/fornecedor de origem.
     * @param {number} to_entity_id - ID do cliente/fornecedor de destino.
     */
    updateAfterAnticipationBalanceTransfer(from_entity_id, to_entity_id) {
      Promise.all([
        this.$store.dispatch('entity/getAnticipationBalanceById', from_entity_id),
        this.$store.dispatch('entity/getAnticipationBalanceById', to_entity_id),
      ]).then((response) => {
        // Atualiza saldo de antecipação dos cards
        const foundBillReceivesFromEntity = this.bill_receives.filter((item) => item.entity_id == from_entity_id);
        foundBillReceivesFromEntity.forEach((billReceive) => {
          billReceive.anticipation_balance = response[0].data;
        });

        const foundBillReceivesToEntity = this.bill_receives.filter((item) => item.entity_id == to_entity_id);
        foundBillReceivesToEntity.forEach((billReceive) => {
          billReceive.anticipation_balance = response[1].data;
        });
      }).catch((error) => {
        this.$refs.modalEntityHistory.closeModal();
        this.init({});
      });
    },
    handleCreateModalEntity(has_identification = true) {
      if (has_identification) {
        return this.$refs.modalSearchEntity.openModal()
      }
      this.$refs.modalCreateEntity.handleCreateModal(null)
    },
    setEntity(params) {
      this.$refs.modalCreateEntity.handleCreateModal(params.entity)
    },
    addedBillReceive() {
      this.added = !this.added;
    },
    handleShowModalReport() {
      this.$refs.modalReport.openModal()
    },
    showModalBillReceiveExcelReport() {
      this.$refs.modalBillReceiveReport.openModal();
    },
  },
  mounted() {
    this.listType = getPreferredViewMode() ?? 'cards';

    this.$store.dispatch("paymentMethod/fetchItems")
      .then((response) => {
        this.loadingPaymentMethods = false;
      });

    this.$store.dispatch("user/fetchItems", {
      filter: {
        is_seller: 1
      }
    }).then((response) => {
      this.users = response.data;
      this.loadingUsers = false;
    });

    this.loadingBankAccounts = true;
    this.$store.dispatch("bankAccount/fetchItems", {filter:{status:true}}).then((response) => {
      this.loadingBankAccounts = false;
    });
    this.loadingSkeleton = true;
    this.init({});
  },
  beforeUpdate() {
    this.$helper.keepGlobalUser(this);
  }
};
</script>

<style scoped>
.imgBaseButton {
  margin-bottom: 2px;
}

.colorize-on-hover:hover {
  color: white !important;
}

.floating-button {
  position: fixed;
  z-index: 3;
  overflow: hidden;
  min-width: 250px;
  bottom: 95px;
  right: 30px;
  border-radius: 10px;
  color: #000000;
  box-shadow: 0 5px 10px 0 #ccc !important;
}

.card {
  border-radius: 10px !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3) !important;
}

.gray-divider {
  background-color: #E8E8E8;
  opacity: 0.1;
  color: #E8E8E8;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}

.no-shadow-card-blue {
  border-radius: 5px !important;
  background-color: #1b6eba;
  padding: 0px 5px;
  box-shadow: 0 0 2px 0 #0b7bfd52;
}

.no-shadow-card-blue h4 {
  color: white;
  padding: 0;
  font-size: 12px;
  letter-spacing: 1px;
}
</style>
