<template>
  <div>
    <modal :show.sync="modal" size="lg">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
            <div>

              <breadcrumb
                list-classes="bg-success border-header-success-bottom"
              >
                Parâmetros
              </breadcrumb>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-5 pb-0 col-form-label form-control-label"
                >
                  Capacidade de produção
                  <span class="text-danger">&nbsp;*</span>
                </label>

                <div class="col-md-7 justify-content-between">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input type="text" v-model="parameter.production_capacity" v-mask="['####']"
                             class="form-control form-control-sm" input-group-classes="input-group-sm"
                             :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      >
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          <span style="text-transform: none">m</span><sup>3</sup>/hora
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                  <small class="input-group-sm text-gray">
                    <span>{{ capacity }}</span>
                    <span style="text-transform: none">min/m</span><sup>3</sup>
                  </small>
                </div>

              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-5 pb-0  col-form-label form-control-label">
                  Tempo mínimo de carregamento
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="parameter.time_min_charge"
                        v-mask="['####']"
                        class="form-control form-control-sm" input-group-classes="input-group-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      >
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          minutos
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Data da última aferição
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <el-date-picker
                      size="mini"
                      v-model="parameter.date_last_check"
                      type="date"
                      placeholder=""
                      format="dd/MM/yyyy"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                >
                  Prazo de aferição
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      aria-describedby="button-addon4"
                      v-model="parameter.months_limit_check"
                      v-mask="['####']"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0">
                        meses
                      </small>
                    </template>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-5 pb-0 col-form-label form-control-label">
                  Próxima aferição
                </label>

                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <el-date-picker
                      size="mini"
                      disabled
                      v-model="parameter.date_next_check"
                      type="date"
                      placeholder=""
                      format="dd/MM/yyyy"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </base-input>
                </div>
              </div>

            </div>
<!--            Desvios-->
            <div>
              <breadcrumb
                list-classes="bg-success border-header-success-bottom"
              >
                Desvios de pesagem
              </breadcrumb>
              <div class="form-group row m-0 p-0" v-for="(item, categoryName) in limits">
                <label
                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                >
                  {{item.name}}
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      aria-describedby="button-addon4"
                      v-model="limits[categoryName].value"
                      v-mask="['#,##','##,##']"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0">
                        %
                      </small>
                    </template>
                  </base-input>
                </div>
              </div>

            </div>

            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingSave">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
const {formatErrorValidation} = require("@/plugins")
import moment from 'moment';

export default {
  name: "ModalChargePointParameter",
  data() {
    return {
      title: 'Parâmetros de Ponto de Carga',
      modal: false,
      limits: {
        binder: {
          name : 'Aglomerante',
          value: '1,0',
        },
        large_aggregate: {
          name : 'Agregado graúdo',
          value: '3,0',
        },
        small_aggregate: {
          name : 'Agregado miúdo',
          value: '3,0',
        },
        additive: {
          name : 'Aditivo',
          value: '5,0',
        },
        adiction: {
          name : 'Adição',
          value: '5,0',
        },
        water: {
          name : 'Água',
          value: '3,0',
        },
      },
      parameter: {
        production_capacity: null,
        time_min_charge: '',
        date_last_check: '',
        months_limit_check: '',
        date_next_check: '',
      },
      loadingSave: false,
    }
  },
  computed: {
    capacity: function () {
      let capacity = this.parameter.production_capacity
      if (capacity === null) {
        return 0;
      } else if (capacity <= 0) {
        return 0;
      } else {
        return parseFloat(60 / capacity).toFixed(2);
      }
    },
  },
  watch: {
    'parameter.date_last_check': function () {
      this.getLastDate()
    },
    'parameter.months_limit_check': function () {
      this.getLastDate()
    },
  },
  methods: {
    closeModal() {
      this.modal = false
    },
    openModal(open, chargePoint) {
      this.parameter = {
        company_plant_charge_point_id: chargePoint.id || '',
        company_id: chargePoint.company_id || '',
        company_plant_id: chargePoint.company_plant_id || '',
        production_capacity: chargePoint.parameter ? chargePoint.parameter.production_capacity : null,
        time_min_charge: chargePoint.parameter ? chargePoint.parameter.time_min_charge : '',
        date_last_check: chargePoint.parameter ? chargePoint.parameter.date_last_check : '',
        months_limit_check: chargePoint.parameter ? chargePoint.parameter.months_limit_check : '',
        date_next_check: chargePoint.parameter ? chargePoint.parameter.date_next_check : '',
      }
      for (let index in chargePoint.variation) {
        if (this.limits.hasOwnProperty(index)) {
          this.limits[index].value = chargePoint.variation[index]
        }
      }
      this.modal = open
    },
    getLastDate() {
      let month = this.parameter.months_limit_check;
      let date = this.parameter.date_last_check;

      if (this.parameter.months_limit_check === null) {
        month = 0;
      }
      date = moment(this.parameter.date_last_check).format('YYYY-MM-DD');
      if (date != 'Invalid date') {
        this.parameter.date_next_check = moment(date).add(month, 'months').format('YYYY-MM-DD');
      } else {
        this.parameter.date_next_check = null
      }
    },
    save() {
      this.$Progress.start()
      this.loadingSave = true
      const params = {
        parameter : this.parameter,
        limits : this.limits,
      }
      this.$store.dispatch('plantChargePoint/addParameter', params)
        .then(response => {
          this.$notify({type: response.error_type, message: response.message})
          this.$store.dispatch('plantChargePoint/fetchItems', this.parameter.company_plant_id)
          this.$Progress.finish()
          this.loadingSave = false
          this.modal = false
        })
        .catch(error => {
          if (error.response.status === 422) {
            let message = formatErrorValidation(error.response.data.errors)
            this.$notify({type: 'danger', message: message})
          } else {
            this.$notify({type: error.data.error_type, message: error.data.message})
          }
          this.$Progress.finish()
          this.loadingSave = false
        })
    },
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
}
</script>

<style scoped>

</style>
