var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('base-header',{staticClass:"bg-gray-content"},[_c('div',{staticClass:"row align-items-center py-4"},[_c('div',{staticClass:"col-lg-4"},[_c('h2',{staticClass:"text-primary",staticStyle:{"font-size":"1.2rem !important"}},[_c('img',{staticClass:"mr-3",attrs:{"height":"30px","src":"/img/icons/check.png"}}),_vm._v(" ESTOQUE MCC ")])]),_c('div',{staticClass:"col-md-8 pr-3"},[_c('div',{staticClass:"row d-flex justify-content-end show-md"},[_c('div',{staticClass:"col-md-3 px-1"},[_c('base-button',{staticClass:"text-uppercase button-border",attrs:{"block":"","type":"success"},on:{"click":function($event){$event.preventDefault();return _vm.handleCreateModal.apply(null, arguments)}}},[_c('img',{staticClass:"mr-1",attrs:{"height":"19","src":"/img/icons/icons8/ios/plus-math--v1-white.png","alt":"new"}}),_vm._v(" NOVO ")])],1),_c('div',{staticClass:"col-md-3 pl-1"},[_c('router-link',{attrs:{"to":{
              path: '/operational/stock/adjustment',
              name: 'operational.stock.adjustment.view',
            }}},[_c('base-button',{staticClass:"text-uppercase button-border",attrs:{"block":"","type":"dark"}},[_c('img',{staticClass:"mr-1",attrs:{"height":"19","src":"/img/icons/icons8/ios/graph-report.png","alt":"graph-report"}}),_vm._v(" Ajustes ")])],1)],1)]),_c('div',{staticClass:"row d-flex justify-content-end show-mobile"},[_c('div',{staticClass:"col-md-2 mb-2"},[_c('base-button',{staticClass:"text-uppercase button-border",attrs:{"block":"","type":"success"},on:{"click":function($event){$event.preventDefault();return _vm.handleCreateModal.apply(null, arguments)}}},[_c('img',{staticClass:"mr-1",attrs:{"height":"19","src":"/img/icons/icons8/ios/plus-math--v1-white.png","alt":"new"}}),_vm._v(" NOVO ")])],1),_c('div',{staticClass:"col-md-2"},[_c('router-link',{attrs:{"to":{
              path: '/operational/stock/adjustment',
              name: 'operational.stock.adjustment.view',
            }}},[_c('base-button',{staticClass:"text-uppercase button-border",attrs:{"block":"","type":"dark"}},[_c('img',{staticClass:"mr-1",attrs:{"height":"19","src":"/img/icons/icons8/ios/graph-report.png","alt":"graph-report"}}),_vm._v(" Ajustes ")])],1)],1)])])])]),_c('list',{ref:"list"}),_c('CreateStock',{ref:"modalCreate",on:{"updatedPreviousStock":_vm.updatedPreviousStock}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }