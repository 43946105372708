var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('modal',{attrs:{"show":_vm.modal,"size":"sm"},on:{"update:show":function($event){_vm.modal=$event}}},[_c('template',{slot:"header"},[_c('h5',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col ml-4 border-3 border-left border-success"},[_c('h4',{staticClass:"mb-0"},[_c('a',{attrs:{"href":"#!"}},[_vm._v(_vm._s(_vm.selected.cmc_group.name))])]),_c('p',{staticClass:"text-sm text-muted mb-0"},[_vm._v(_vm._s(_vm.selected.company_plant.name))])]),_c('div',{staticClass:"col-md-12 mt-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('span',{staticClass:"display-4 text-primary",staticStyle:{"font-size":"0.9rem"}},[_vm._v(" Referência técnica ")]),_c('base-input',{attrs:{"input-classes":"form-control-sm"}},[_c('el-select',{attrs:{"size":"mini","disabled":_vm.disable_technical_reference,"placeholder":"Selecione","filterable":""},on:{"change":_vm.setTechnical},model:{value:(_vm.reference.technical),callback:function ($$v) {_vm.$set(_vm.reference, "technical", $$v)},expression:"reference.technical"}},_vm._l((_vm.technical_reference),function(cmc){return _c('el-option',{key:cmc.cmc_id,attrs:{"label":cmc.product_name+
                                  ' - '+cmc.supplier_name+
                                  ' ('+cmc.origin_name+')  -  R$ '+
                                  cmc.price+
                                  ' / '+cmc.unit_buy,"value":cmc.cmc_id}})}),1)],1),_c('hr'),_c('span',{staticClass:"display-4 text-warning",staticStyle:{"font-size":"0.9rem"}},[_vm._v(" Referência comercial ")]),_c('base-input',{attrs:{"input-classes":"form-control-sm"}},[_c('el-select',{attrs:{"size":"mini","disabled":_vm.disable_commercial_reference,"placeholder":"Selecione","filterable":""},on:{"change":_vm.setCommercial},model:{value:(_vm.reference.commercial),callback:function ($$v) {_vm.$set(_vm.reference, "commercial", $$v)},expression:"reference.commercial"}},_vm._l((_vm.commercial_reference),function(cmc){return _c('el-option',{key:cmc.cmc_id,attrs:{"label":cmc.product_name+
                                  ' - '+cmc.supplier_name+
                                  ' ('+cmc.origin_name+')  -  R$ '+
                                  cmc.price+
                                  ' / '+cmc.unit_buy,"value":cmc.cmc_id}})}),1)],1)],1)])])]),_c('div',{staticClass:"modal-footer",staticStyle:{"margin-top":"-20px"}},[_c('base-button',{attrs:{"type":"secondary"},on:{"click":function($event){return _vm.close()}}},[_vm._v("fechar")])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }