import { sapConfigurationUrls } from '.';
import httpClient from '../../../../shared/libraries/httpClient';
import { handleUnexpectedStatusCode } from '../../../../shared/services';
import { SapConfigurationSyncType } from '../types';
import { HttpStatusEnum } from '../../../../shared/enums';

/**
 * @param {SapConfigurationSyncType} data
 * @returns {Promise}
 */
const execute = async (data) => {
  data.is_active = data.is_active_control ? 'y' : 'n';
  const res = await httpClient.post(sapConfigurationUrls.BASE, data);

  switch (res.status) {
    case HttpStatusEnum.HTTP_OK: return res.data.data;
    default: handleUnexpectedStatusCode(res);
  }
};

export default { execute };