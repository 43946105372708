<template>
  <label class="file-select  col-form-label form-control-label">
    <div class="select-button">
      <span v-if="value">{{value.name}}</span>
      <span v-else>{{ title }}</span>
    </div>
    <input type="file" :accept="accept" @change="handleFileChange" class="form-control-sm"/>
  </label>
</template>

<script>
export default {
  props: {
    value: File,
    title: String,
    accept: {
      type: String,
      default: '*.*',
      description: "Extensões de arquivos permitidas",
    },
  },
  methods: {
    handleFileChange(e) {
      this.$emit('input', e.target.files[0])
    }
  }
}
</script>

<style scoped>
.file-select > .select-button {
  padding: 2px 20px 4px 20px;
  cursor: pointer;
  color: white;
  background-color: #1a70b7;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
}
.file-select > input[type="file"] {
  display: none;
}
</style>
