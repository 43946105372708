<template>
  <div>
    <multi-filter ref="multi-filter" @fetch="load" :filter="filter" />

    <div class="container-fluid">

    <div class="row card-wrapper" v-show="loadingSkeleton">
      <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
    </div>

    <div class="row align-items-center"  v-if="!fresh_states.length && !loadingSkeleton">
      <div class="col-md-12 text-center">
        <div class="card p-4">
          Sem dados cadastrados!
        </div>
      </div>
    </div>


    <div class="row card-wrapper" v-if="fresh_states.length && !loadingSkeleton">
      <div class="col-lg-4" v-for="(fresh_state, index) in fresh_states" :key="index">
        <!-- Basic with action button -->
        <card
          :class="[
              (fresh_state.status===true && fresh_state.default===true) ? 'card-border-top-primary' : '',
              (fresh_state.status===true && fresh_state.default===false) ? 'card-border-top-success' : '',
              (fresh_state.status===false && fresh_state.default===false) ? 'card-border-top-danger' : ''
            ]"
        >
          <!-- Card header -->
          <div class="row align-items-center mb-3">
            <div class="col-8">
              <!-- Title -->
              <h5 class="h3 mb-0">{{ fresh_state.short_description }}</h5>
              <span class="h6 small">{{ fresh_state.full_description}}</span>
            </div>

            <div class="col-4 text-right">
              <base-dropdown menuOnRight>
                <base-button
                  slot="title-container"
                  type="primary"
                  class="dropdown-toggle p-2 rounded m-0"
                >
                  <i class="fas fa-cog"></i>
                </base-button>
                <div class="dropdown-divider p-0 m-0"></div>
                <a class="dropdown-item" @click="handleEdit(fresh_state.uuid)">
                  <i class="fas fa-edit text-warning"></i>
                  Editar
                </a>
                <div v-show="false">
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleDelete(fresh_state.uuid)">
                    <i class="fas fa-times text-danger"></i>
                    Excluir
                  </a>
                </div>
              </base-dropdown>
            </div>
          </div>
          <!-- Card body -->
          <collapse class="border rounded">
            <collapse-item name="1" class="header-gray" back-ground="border-header-primary-bottom">
              <h5 slot="title" class="mb-0 ">Local de Amostragem</h5>
              <ul
                class="list-unstyled"
                v-for="(molding_location, indexMolding_location) in fresh_state.molding_locations"
                :key="indexMolding_location"
              >
                <li class="text-capitalize">{{molding_location.name}}</li>
              </ul>
            </collapse-item>
          </collapse>
        </card>
      </div>
    </div>
  </div>
  <Edit ref="editFreshSate" />
</div>
</template>

<script>
import {mapGetters} from 'vuex'
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import Edit from './_Edit'
import MultiFilter from '@/components/Utils/MultiFilter.vue';

export default {
  name: "List",
  components: {
    SkeletonPuzlGrid,
    Edit,
    MultiFilter
  },
  data () {
    return {
      loadingSkeleton: false,
      filter: {}
    }
  },
  computed: {
    ...mapGetters({
      'fresh_states': 'testFreshState/fetch',
    }),
  },
  methods:{
    load(filter = {}) {
      this.filter = filter
      this.init();
    },

    init() {
      this.loadingSkeleton = true;
      this.$store.dispatch('testFreshState/fetchItems', { filter: this.filter })
        .then( response =>{
          this.loadingSkeleton = false
        })
        .catch( error => {
          this.loadingSkeleton = false
        })
    },
    
    handleEdit(uuid){
      this.$refs.editFreshSate.handleEditModal(uuid)
    },
    
    handleDelete(uuid){
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed){
          this.$Progress.start();
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          this.$store.dispatch('testFreshState/destroy', uuid).then(response => {
            this.$notify({
              type: 'success',
              message: response.message
            });
          }).catch(error => {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            });
          })
          this.$Progress.finish();
        }
      }).catch(() => this.$Progress.finish())
    }
  },

  mounted() {
    this.filter.status = 1;
    this.$refs['multi-filter']._self.$forceUpdate();
    this.init();
  },
}
</script>

<style scoped>

</style>
