<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-gray d-inline-block mb-0">Obras</h6>
          <nav
            aria-label="breadcrumb"
            class="d-none d-md-inline-block ml-md-4 text-gray"
          >
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i></a></li>
              <li class="breadcrumb-item"><a href="#">{{ this.$route.meta.breadcrumbs[0].title }}</a></li>
              <li class="breadcrumb-item" aria-current="page">{{ this.$route.meta.breadcrumbs[1].title }}</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-2 mb-2">
          <base-button
            block size="lg"
            type="success"
            class="text-uppercase"
            :disabled="loadingSkeleton"
            @click="handleCreate(customer_construction.uuid)"
          >
            <i class="fas fa-plus"></i> novo
          </base-button>
        </div>

        <div class="col-md-2 col-xlg-2 mb-2">
          <router-link :to="{ path:'/commercial/customer-construction'}">
            <base-button block size="lg" type="light" class="text-uppercase">
              voltar
            </base-button>
          </router-link>
        </div>
      </div>
      <div class="row" v-if="customer">
        <div class="col-md-12 border-3 border-left border-primary ml-3">
          <span class="h4">{{ customer.customer_name }}</span>
          <template v-if="customer.document">
            <br>
            <small>{{ customer.document }}</small>
          </template>
          <br>
          <small>Cód .: {{ customer.id }}</small>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-md-3">
          <SkeletonPuzl class="mb-2" type="button"/>
        </div>
        <div class="col-md-9"/>
        <div class="col-md-3">
          <SkeletonPuzl class="mb-2" type="button"/>
        </div>
        <div class="col-md-9"/>
        <div class="col-md-3">
          <SkeletonPuzl class="mb-2" type="button"/>
        </div>
        <div class="col-md-9"/>
      </div>
      <div class="row align-items-center pb-0 mb-3" v-if="customer_construction">
        <div class="col-md-12">
          <div class="checklist-item checklist-item-primary">
            <div class="checklist-info">
              <h1 class="checklist-title mb-0">
                {{ customer_construction.customer_name }}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </base-header>

    <multi-filter ref="multi-filter" @fetch="init" :filter="filter"/>
    <div class="container-fluid">
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <PuzlEmptyData v-if="!this.$helper.Array.isEmpty(customer_construction) && !loadingSkeleton"></PuzlEmptyData>

      <div class="row card-wrapper" v-if="this.$helper.Array.isEmpty(customer_construction) && !loadingSkeleton">
        <div class="col-lg-4"
             v-for="(construction, index) in customer_construction"
             :key="index"
        >

          <!-- Basic with action button -->
          <card :class="construction.status ? 'card-border-top-success' : 'card-border-top-danger'">
            <!-- Card header -->
            <div class="row align-items-center mb-3">
              <div class="col-7 mr-2">
                <!-- Title -->
                <h5 class="h4 mb-0">{{ construction.construction_name }}</h5>
                <h5 class="h3" v-if="construction.contract_proposal_status !== null"
                    :class="[
                    construction.contract_proposal_status===1 ? 'text-success' : '',
                    construction.contract_proposal_status===0 ? 'text-danger' : '',
                    construction.contract_proposal_status===2 ? 'text-warning' : '',
                    construction.contract_proposal_status===3 ? 'text-orange' : '',
                    construction.contract_proposal_status===4 ? 'text-muted' : '',
                    ]"
                >
                  <badge tag="a" href="#" :type="types[construction.contract_proposal_status]">
                    {{ statusName[construction.contract_proposal_status] }}
                  </badge>
                </h5>
              </div>
              <div class="col-2 mr-n3 text-right">
                <a href="#" @click.prevent="handleShowAttachments(construction.id, construction.construction_name)">
                  <img
                    :src="
                      construction.total_construction_attachments > 0
                        ? '/img/icons/paperclip-green.png'
                        : '/img/icons/paperclip.png'
                    "
                    width="25px"
                    :title="`Total de anexos: ${construction.total_construction_attachments}`" 
                  />
                </a>
              </div>
              <div class="col-3 text-right">
                <base-dropdown menuOnRight>
                  <base-button
                    slot="title-container"
                    type="primary"
                    class="dropdown-toggle p-2 rounded m-0"
                  >
                    <i class="fas fa-cog"></i>
                  </base-button>
                  <router-link :to="{
                      path:'/commercial/customer-construction/contact',
                      name: 'commercial.customer-construction.contact',
                      params: {construction_uuid: construction.uuid}}"
                  >
                    <a class="dropdown-item">
                      <i class="fas fa-users text-primary"></i>
                      Acessos / Contatos
                    </a>
                  </router-link>
                  <div class="dropdown-divider p-0 m-0"></div>

<!--                  <router-link :to="{-->
<!--                      path:'/commercial/customer-construction/address',-->
<!--                      name: 'commercial.customer-construction.address',-->
<!--                      params: {construction_uuid: construction.uuid}}"-->
<!--                  >-->
<!--                    <a class="dropdown-item">-->
<!--                      <i class="fas fa-address-card text-indigo"></i>-->
<!--                      Endereços-->
<!--                    </a>-->
<!--                  </router-link>-->

<!--                  <div class="dropdown-divider p-0 m-0"></div>-->

                  <a class="dropdown-item" @click="handleEditConstruction(construction.uuid)">
                    <i class="fas fa-edit text-warning"></i>
                    Editar
                  </a>

                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleDeleteConstruction(construction.uuid)">
                    <i class="fas fa-times text-danger"></i>
                    Excluir
                  </a>
                </base-dropdown>
              </div>
            </div>
            <!-- Card body -->
            <p class="mt-2 mb-0" v-if="construction.default_address">
                      <span>
                        <span class="h4">
                        {{ construction.default_address.city }} /
                        {{ construction.default_address.state }}
                        </span>
                        <br/>
                      <span class="h4 small">
                        {{ construction.default_address.address }} -
                        {{ construction.default_address.district }} -
                        {{ construction.default_address.postal_code }}
                      </span>
                      </span>
            </p>
            <collapse class="border rounded p-0 mt-3">
              <collapse-item
                class="header-gray"
                back-ground="border-header-primary-bottom"
              >
                <h5 slot="title" class="mb-0 ">Dados da Obra</h5>
                <div class="row">
                  <div class="col-md-12">
                    <h5 class="h4 mb-0">Tipo</h5>
                    <h5 class="h4 mb-0 small">
                      {{
                        construction.type_construction &&
                        construction.type_construction.description ||
                        '-'
                      }}
                    </h5>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-12">
                    <h5 class="h4 mb-0">Método de Prospecção</h5>
                    <h5 class="h4 mb-0 small">
                      {{
                        construction.prospection_method_construction &&
                        construction.prospection_method_construction.description ||
                        '-'
                      }}
                    </h5>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-12">
                    <h5 class="h4 mb-0">Volume Total Estimado</h5>
                    <h5 class="h4 mb-0 small">
                      {{ construction.estimated_volume || '-' }}
                      <small v-if="construction.estimated_volume"><span style="text-transform: none">m</span><sup>3</sup></small>
                    </h5>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-12">
                    <h5 class="h4 mb-0">Início Previsto</h5>
                    <h5 class="h4 mb-0 small">
                      {{ construction.scheduled_beginning || '-' }}
                    </h5>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-12">
                    <h5 class="h4 mb-0">Término Previsto:</h5>
                    <h5 class="h4 mb-0 small">
                      {{ construction.scheduled_ending || '-' }}
                    </h5>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-12">
                    <h5 class="h4 mb-0">Tempo de obra (dias)</h5>
                    <h5 class="h4 mb-0 small">
                      {{ construction.total_days || '-' }}
                    </h5>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-12">
                    <h5 class="h4 mb-0">Restrição de Acesso:</h5>
                    <h5 class="h4 mb-0 small">
                      {{ construction.access_restriction || '-' }}
                    </h5>
                  </div>
                </div>
              </collapse-item>
            </collapse>

            <collapse class="border rounded p-0 mt-3" v-if="construction.contact!=''">
              <collapse-item
                class="header-gray"
                back-ground="border-header-default-bottom"
              >
                <h5 slot="title" class="mb-0 ">Contatos / Acessos</h5>
                <collapse class="border rounded p-0 mt-3"
                          v-for="(contact, indexContact) in construction.contact"
                          :key="indexContact"
                >
                  <collapse-item
                    class="header-gray"
                    back-ground="border-header-default-bottom"
                  >
                    <h5 slot="title" class="mb-0">
                      {{ contact.name }}
                    </h5>
                    <div class="row" v-show="contact.office">
                      <div class="col-md-12">
                        <h5 class="h4 mb-0">Cargo:</h5>
                        <h5 class="h4 mb-0 small">
                          {{ contact.office }}
                        </h5>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-md-12">
                        <h5 class="h4 mb-0">Contatos:</h5>
                        <h5 class="h4 mb-0 small mt-1">{{ contact.cell_phone }}</h5>
                        <h5 class="h4 mb-0 small mt-1">{{ contact.phone }}</h5>
                      </div>
                    </div>
                  </collapse-item>
                </collapse>
              </collapse-item>
            </collapse>
<!--            <collapse class="border rounded p-0 mt-3">-->
<!--              <collapse-item-->
<!--                class="header-gray"-->
<!--                back-ground="border-header-default-bottom"-->
<!--              >-->
<!--                <h5 slot="title" class="mb-0 ">Endereços</h5>-->
<!--                <div v-if="construction.default_address">-->
<!--                  <div class="checklist-item"-->
<!--                       :class="[construction.default_address.default ? 'checklist-item-primary' : 'checklist-item-gray']"-->
<!--                  >-->
<!--                    <div class="checklist-info">-->
<!--                          <span class="mt-2 p">-->
<!--                            <h5 class="h4">-->
<!--                              {{ construction.default_address.alias || '' }}-->
<!--                            </h5>-->
<!--                          </span>-->
<!--                      <span class="mt-0 pt-0 p">-->
<!--                            <h5 class="h4 small">-->
<!--                              {{ construction.default_address.city || '' }}-->
<!--                              /-->
<!--                              {{ construction.default_address.state || '' }}-->
<!--                            </h5>-->
<!--                            <span class="h4 small">-->
<!--                              {{ construction.default_address.address || '' }},-->
<!--                              {{ construction.default_address.number || '' }},-->
<!--                              {{ construction.default_address.district || '' }}-->
<!--                            </span>-->
<!--                          </span><br/>-->
<!--                      <span class="mt-4 p">-->
<!--                              <span class="h4 small">-->
<!--                              {{ construction.default_address.postal_code || '' }}-->
<!--                              </span>-->
<!--                          </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div>-->
<!--                    <div>-->
<!--                      <a href="#" @click.prevent="handleEditAddress(construction.default_address.uuid)">-->
<!--                        <i class="fas fa-edit text-warning mr-1"></i>-->
<!--                      </a>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </collapse-item>-->
<!--            </collapse>-->
          </card>
        </div>
      </div>
    </div>
    <loading-pagination :show="loading && !loadingSkeleton"/>
    <modal-create-construction ref="createConstruction"></modal-create-construction>
    <modal-edit-construction ref="editConstruction"></modal-edit-construction>
    <ModalEditConstructionAddress @updateAddress="init" ref="modalEditConstructionAddress"/>
    <ModalConstructionAttachmentList 
      ref="modalConstructionAttachmentList" 
      @updatedTotalAttachmentAfterUpload="updatedTotalAttachmentAfterUpload" 
      @updateTotalAttachmentAfterDeletion="updateTotalAttachmentAfterDeletion" />
  </div>
</template>
<script>
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import {mapGetters} from 'vuex'
import PuzlEmptyData from "@/components/PuzlEmptyData";
import ModalCreateConstruction from './_Create'
import ModalEditConstructionAddress from './_EditAddress'
import ModalEditConstruction from './_Edit'
import {formatErrorValidation} from "@/plugins";
import MultiFilter from '@/components/Utils/MultiFilter.vue';
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import SkeletonPuzl from "../../../../../../components/SkeletonPuzl";
import ModalConstructionAttachmentList from "./_ModalConstructionAttachmentList";

export default {
  name: "ListConstruction",
  mixins: [cursorPaginate],
  components: {
    SkeletonPuzl,
    PuzlEmptyData,
    SkeletonPuzlGrid,
    ModalCreateConstruction,
    ModalEditConstruction,
    ModalEditConstructionAddress,
    MultiFilter,
    LoadingPagination,
    ModalConstructionAttachmentList,
  },
  data() {
    return {
      loadingSkeleton: false,
      customer: null,
      statusName: [
        'Inativo',
        'Ativo',
        'Pendente Revisão',
        'Pendente Aprovação',
        'Rascunho',
        'Concluído',
      ],
      types: [
        'danger',
        'success',
        'yellow',
        'warning',
        'primary',
      ],
      filter: {},
    }
  },
  computed: {
    ...mapGetters({
      'customer_construction': 'customerConstructionConstructions/fetch',
    }),
  },
  methods: {
    init(filter = null) {
      this.startCursor(filter)
      this.$store.dispatch('customerConstructionConstructions/getByCustomerConstruction', {
        uuid: this.$route.params.customer_construction_uuid,
        filter: this.filter,
        next_page: this.paginate.nextUrl,
      })
        .then(response => {
          this.resolveCursor(response)
        })
        .catch(error => {
          this.resolveCursor()
        })
    },
    handleCreate(uuid) {
      this.$refs.createConstruction.handleCreateModal(uuid)
    },
    handleEditAddress(uuidAddress) {
      this.$refs.modalEditConstructionAddress.handleEditModal(uuidAddress)
    },
    handleEditConstruction(uuid) {
      this.$refs.editConstruction.handleEditModal(uuid)
    },
    handleDeleteConstruction(uuid) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          this.$store.dispatch('customerConstructionConstructions/destroy', uuid)
            .then(response => {
              this.$Progress.finish()
              this.$notify({type: response.error_type, message: response.message})
            })
            .catch(error => {
              if (error.status == 200) {
                this.$notify({
                  type: "danger",
                  message: error.data.message
                });
                this.$Progress.finish();
              } else if (error.response && error.response.status === 422) {
                let errors = formatErrorValidation(error.response.data.errors);
                this.$notify({
                  type: "danger",
                  message: errors
                });
              }
              this.$Progress.finish();
            });
          this.$Progress.finish();
        }
      }).catch(() => this.$Progress.finish())
    },
    /**
     * Abre modal de listagem de anexos da obra.
     * @param {number} constructionId
     * @param {string} constructionName
     */
    handleShowAttachments(constructionId, constructionName) {
      this.$refs.modalConstructionAttachmentList.handleCreateModal(constructionId, constructionName);
    },
    /**
     * Atualiza total de anexos após realizar o upload.
     *  @param {number} constructionId
     */
    updatedTotalAttachmentAfterUpload(constructionId) {
      this.$store.dispatch('constructionAttachment/getTotalAttachmentByConstructionId', constructionId)
        .then(response => {
          let foundConstruction = this.customer_construction.find((item) => item.id == constructionId);
          foundConstruction.total_construction_attachments = response.data.total_construction_attachments;
      });
    },
    /**
     * Atualiza total de anexos após deletar um anexo.
     * @param {number} constructionId
     */
    updateTotalAttachmentAfterDeletion(constructionId) {
      let foundConstruction = this.customer_construction.find((item) => item.id == constructionId);
      foundConstruction.total_construction_attachments --;
    },
  },
  mounted() {
    this.init({})
    this.$store.dispatch('customerConstruction/show', this.$route.params.customer_construction_uuid)
      .then((response) => {
        this.customer = response.data
      })
  },
}
</script>

<style scoped>
</style>
