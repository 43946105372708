<template>
  <div>
    <modal @close="close" size="md" :show.sync="show">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">{{ title }}</h5>
      </template>
      <div class="row">
        <div class="col-md-12 table-responsive-sm ">
          <table style="width: 100%" class=" table-sm table-bordered">
            <tr>
              <th class="text-center">Série</th>
              <th class="text-center">Sequência</th>
              <th class="text-center">Ausências</th>
            </tr>
            <tr v-for="serie in $_broken_sequence">
              <td class="text-center">{{ serie.serie }}</td>
              <td class="text-center text-nowrap">
                <small class="font-weight-semibold">Inicial e Final: {{ serie.min_number }} - {{
                    serie.max_number
                  }}</small><br/>
                <small class="font-weight-semibold">Período: {{ moment(serie.start_period).format('DD/MM/YYYY') }} -
                  {{ moment(serie.end_period).format('DD/MM/YYYY') }}</small>
              </td>
              <td class="text-center">
                {{ serie.numbers }}
              </td>
            </tr>
            <!--            <tr>-->
            <!--              <td>Data Structures and Algorithms</td>-->
            <!--              <td>-->
            <!--                It includes pre-recorded premium-->
            <!--                <span style="color: red;">-->
            <!--                    Videolectures&programmingquestions-->
            <!--                </span> for practice.-->
            <!--              </td>-->
            <!--              <td>4 months</td>-->
            <!--            </tr>-->
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <base-button
          type="secondary" @click="close()">
          Cancelar
        </base-button>
        <base-button
          @click.prevent="save()"
          type="danger"
          native-type="submit"
          :loading="isLoading"
        >
          Inutilizar faixa
        </base-button>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import moment from 'moment'

export default {
  name: "ModalBrokenSequence",
  props: ['show', 'selected'],
  data() {
    return {
      moment: moment,
      title: 'Quebra de sequência',
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      $_broken_sequence: "xmlIssuedes/fetchBrokenSequence",
    })
  },
  methods: {
    close() {
      this.$emit('close')
    },
    save() {
      this.$Progress.start();
      this.isLoading = true
      this.$Swal.confirmAction('Deseja realmente inutilizar a faixa?', ['Sim', 'Não'])
        .then((result) => {
          if (result.isConfirmed) {
            this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
            this.$store.dispatch('xmlIssuedes/postUnusable', {sequence: this.$_broken_sequence})
              .then(() => {
                this.$notify({
                  type: 'success',
                  message: 'Solicitação concluída com sucesso! O processo será realizado em segundo plano.'
                });
              })
            this.isLoading = false
            this.$Progress.finish()
            this.close()
          } else {
            this.isLoading = false
            this.$Progress.finish()
          }
        }).catch(() => {
        this.isLoading = false
        this.$Progress.finish()
      })
    }
  },
};
</script>
<style scoped>
td {
  white-space: pre-wrap;
}

th {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: 1px solid #e9ecef;
  border-top: 1px solid #e9ecef;
}

td {
  font-size: 0.75rem;
}
</style>
