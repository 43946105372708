<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div class="p-3">
        <div>
          <validation-observer
            v-slot="{ invalid, handleSubmit }"
            ref="formValidator"
          >
            <div class="form-group row m-0 p-0">
              <label
                class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
              >
                Ensaio
                <span class="text-danger">*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm">
                    <puzl-select 
                      v-model="freshState.fresh_state_id" 
                      :items="fresh_states"  
                      label="short_description" 
                      :loading="loadingFreshState"
                      :disabled="loadingFreshState" />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <label
                class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
              >
                Local Amostragem
                <span class="text-danger">*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm">
                    <puzl-select 
                      v-model="freshState.mold_id" 
                      :items="molding_locations"  
                      :loading="loadingMoldingLocation"
                      :disabled="loadingMoldingLocation" />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <label
                class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
              >
                Horário
                <span class="text-danger">*</span>
              </label>
              <div class="col-md-7 text-left">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                    <input-date-time-picker
                      v-model="freshState.hour"
                      :disableDate="pickerOptions"/>
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pb-0 pt-1 col-form-label form-control-label">
                Resultado <span class="text-danger">*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required">
                <base-input input-group-classes="input-group-sm pb-2">
                  <input maxlength="4"
                         v-model="freshState.result"
                         inputmode="numeric"
                         v-mask="'####'"
                         class="form-control form-control-sm"/>
                  <template slot="append">
                    <small v-show="unit() != false" class="input-group-sm p-0 m-0">
                      {{ measuredunit }}
                    </small>
                  </template>
                </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <label
                class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
              >
                Observações
              </label>
              <div class="col-md-7">
                <base-input input-group-classes="input-group-sm">
                      <textarea
                        v-model="freshState.observation"
                        type="text"
                        rows="3"
                        class="form-control form-control-sm"
                      >
                      </textarea>
                </base-input>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <div
                class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
              >
                <base-button
                  v-show="!canEdit"
                  :loading="loadingSave"
                  :disabled="invalid"
                  @click.prevent="save()"
                  size="small"
                  type="success">
                  incluir
                </base-button>
              </div>
            </div>
            <div class="col-12" v-if="canEdit">
              <div class="row">
                <div class="col-4"></div>
                <div class="col-8">
                  <div class="float-right">
                    <base-button @click.prevent="cancelEdit" type="secondary">
                      Cancelar
                    </base-button>
                    <base-button
                      @click.prevent="updateFreshState"
                      type="success"
                      native-type="submit"
                      v-bind:disabled="invalid"
                      :loading="loadingSave"
                    >
                      Salvar
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
          </validation-observer>
        </div>
        <div @click.prevent="setEdit(item)" class="card border p-2 card--hover mt-2"
             v-for="item in schedule_fresh_states">
          <div class="d-flex justify-content-between">
            <div class="col-10">
              <div class="d-flex justify-content-between">
                <div>
                  <span class="h5 d-block">
                    {{ item.short_description }} ({{ item.name }})
                  </span>
                </div>
                <div>
                  <span class="h5 d-block">
                   {{ item.hour }}
                  </span>
                </div>
                <div>
                  <span class="h5 d-block">
                   {{ item.result }}
                  </span>
                </div>
              </div>
              <div>
                <div>
                  <span class="text-body small text-wrap">
                    {{ item.observation }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-2 d-flex align-items-center">
              <a href="#" @click.prevent="remove(item.uuid)">
                <i class="fa fa-times text-danger"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="modal-footer pb-0 pr-0">
          <base-button type="secondary" @click="closeModal()">
            Cancelar
          </base-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import InputDateTimePicker from "@/components/InputDateTimePicker"
import PuzlSelect from "@/components/PuzlSelect"

export default {
  components: {InputDateTimePicker, PuzlSelect},
  name: "ModalFreshStateTest",
  data() {
    return {
      modal: false,
      title: 'Ensaios - Estado fresco',
      loadingSave: false,
      canEdit: false,
      loadingFreshState: false,
      schedule_travel_uuid: null,
      loadingMoldingLocation: false,
      freshState: {
        fresh_state_id: null,
        mold_id: null,
        hour: null,
        result: null,
        observation: null,
        schedule_travel_uuid: null,
      },
      pickerOptions: {
        selectableRange: this.range,
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      measuredunit: null,
      removing: false,
    }
  },
  computed: {
    ...mapGetters({
      'fresh_states': 'testFreshState/fetch',
      molding_locations: "moldingLocation/fetch",
      schedule_fresh_states: "travelConfiguration/showFreshStates",
    }),
  },
  methods: {
    closeModal() {
      this.modal = false
    },
    setEdit(item) {
      if (!this.removing) {
        this.freshState = {...item}
        this.canEdit = true
      }
    },
    cancelEdit() {
      this.canEdit = false
      this.freshState = {
        fresh_state_id: null,
        mold_id: null,
        hour: null,
        result: null,
        observation: null,
        schedule_travel_uuid: this.freshState.schedule_travel_uuid
      }
    },
    unit() {
      const measuredunit = this.fresh_states.find(item => item.id == this.freshState.fresh_state_id)
      if (typeof measuredunit === 'object') {
        this.measuredunit = measuredunit.measured_unit.letter
        return true
      }
      return false
    },
    openModal(travelUuid) {
      this.loadingFreshState = true;
      this.$store.dispatch("testFreshState/fetchItems").then(() => {
        this.loadingFreshState = false;
      });
      this.loadingMoldingLocation = true
      this.$store.dispatch("moldingLocation/fetchItems").then(() => {
        this.loadingMoldingLocation = false;
      });
      let params = {
        schedule_travel_uuid: travelUuid
      }
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch('travelConfiguration/fetchFreshStates', params)
        .then(response => {
          this.schedule_travel_uuid = travelUuid
          this.modal = true
          this.$notify({type: 'success', message: response.message});
        })
        .catch()
    },
    remove(uuid) {
      this.removing = true
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$store.dispatch('travelConfiguration/deleteRemoveFreshState', uuid)
            .then(response => {
              this.removing = false
              this.$notify({type: 'success', message: response.message});
              this.$Progress.finish()
            })
            .catch(() => {
              this.$Progress.finish()
              this.removing = false
            })
        }
      }).catch(() => this.$Progress.finish())
    },
    updateFreshState() {
      this.loadingSave = true
      let params = {...this.freshState}
      params.schedule_travel_uuid = this.schedule_travel_uuid
      if (params.result) {
        params.result = params.result + ' ' + this.measuredunit
        params.result = params.result.toString()
      }
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch('travelConfiguration/updateFreshState', params)
        .then(response => {
            this.loadingSave = false
            this.freshState = {
              fresh_state_id: null,
              mold_id: null,
              hour: null,
              result: null,
              observation: null,
              schedule_travel_uuid: this.freshState.schedule_travel_uuid
            }
            this.canEdit = false
            this.$notify({type: 'success', message: response.message});
            this.$emit('updatedFreshStateTest');
          }
        ).catch(() => this.loadingSave = false)
    },
    save() {
      this.loadingSave = true
      let params = {...this.freshState}
      params.schedule_travel_uuid = this.schedule_travel_uuid
      if (params.result) {
        params.result = params.result + ' ' + this.measuredunit
        params.result = params.result.toString()
      }
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch('travelConfiguration/createFreshTest', params)
        .then(response => {
            this.loadingSave = false
            this.freshState = {
              fresh_state_id: null,
              mold_id: null,
              hour: null,
              result: null,
              observation: null,
              schedule_travel_uuid: this.freshState.schedule_travel_uuid
            }
            this.$notify({type: 'success', message: response.message});
            this.$emit('updatedFreshStateTest');
          }
        ).catch(() => this.loadingSave = false)
    },
  },
};
</script>

<style></style>
