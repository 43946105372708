<template>
  <div>
    <modal @close="close" size="md" :show.sync="show">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">{{ title }}</h5>
      </template>
      <validation-observer
        v-slot="{ invalid }"
        ref="formValidator"
      >
        <div>
          <div class="row" v-if="show">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 ml-2">
                  <div class="checklist-title mb-1">
                    <b>
                      {{ header.hardened_state.short_description }}
                    </b>
                  </div>
                  <div class="checklist-title mb-0" style="text-transform: none">
                    <b>
                      {{ header.mix_feature.name }}
                    </b>
                  </div>
                </div>
                <div class="col-md-12" style="margin-top: -20px">
                  <hr>
                </div>
              </div>
              <div style="margin-top: -20px">
                <div class="form-group row p-0 mb-0">
                  <label class="col-md-5   col-form-label form-control-label">
                    Rm (ref) :
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-7">
                    <validation-provider rules="required|min_numeric:1.0" v-slot="{errors}">
                      <base-input input-group-classes="input-group-sm">
                        <input-limit
                          @change="changeFr()"
                          :options="{
                            min: 0,
                            max: 199.9,
                            decimals: 1,
                          }" :start_value="statistical_reference.rm"
                          v-model="statistical_reference.rm"
                          @value="statistical_reference.rm = $event"
                        >
                          <small>{{ header.hardened_state.measured_unit.letter }}</small>
                        </input-limit>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row p-0 mb-0">
                  <label class="col-md-5   col-form-label form-control-label">
                    Sd (ref) :
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-7">
                    <validation-provider rules="required|min_numeric:1.0" v-slot="{errors}">
                      <base-input input-group-classes="input-group-sm">
                        <input-limit
                          @change="changeFr()"
                          :options="{
                            min: 0,
                            max: 9.9,
                            decimals: 1,
                          }" :start_value="statistical_reference.sd"
                          v-model="statistical_reference.sd"
                          @value="statistical_reference.sd = $event"
                        >
                          <small>{{ header.hardened_state.measured_unit.letter }}</small>
                        </input-limit>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row p-0 mb-0">
                  <label class="col-md-5   col-form-label form-control-label">
                    N.C.
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-7">
                    <validation-provider rules="required" v-slot="{errors}">
                      <base-input input-group-classes="input-group-sm">
                        <puzl-select
                          v-model="statistical_reference.nc"
                          :items="$_reliability_levels"
                          @change="changeValueZ()"
                          customKey="value_z"
                          :labelMask="true"
                          label="$reliability$%"
                          :loading="loadingReliability"
                          :disabled="loadingReliability"/>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row p-0 mb-0">
                  <label class="col-md-5   col-form-label form-control-label">
                    Z
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-7">
                    <validation-provider rules="required" v-slot="{errors}">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          disabled
                          type="text"
                          v-model="statistical_reference.z"
                          v-mask="['#.##']"
                          class="form-control form-control-sm"
                          :class="errors[0] ? 'is-invalid' : 'is-valid'"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row p-0 mb-0">
                  <label class="col-md-5   col-form-label form-control-label">
                    FR
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-7">
                    <validation-provider rules="required" v-slot="{errors}">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          disabled
                          v-model="statistical_reference.fr"
                          type="text"
                          class="form-control form-control-sm"
                          :class="errors[0] ? 'is-invalid' : 'is-valid'"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="row" style="margin-top: -20px">
                  <div class="col-md-12">
                    <hr>
                  </div>
                </div>
              </div>
              <div style="margin-top: -20px">
                <div class="form-group row p-0 mb-0">
                  <label class="col-md-5   col-form-label form-control-label">
                    Sd (máx.)
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-7">
                    <validation-provider rules="required|min_numeric:0.1" v-slot="{errors}">
                      <base-input input-group-classes="input-group-sm">
                        <input-limit :options="{
                            min: 0,
                            max: 9.9,
                            decimals: 1,
                          }" :start_value="statistical_reference.max_sd"
                                     v-model="statistical_reference.max_sd"
                                     @value="statistical_reference.max_sd = $event"
                        >
                          <small>{{ header.hardened_state.measured_unit.letter }}</small>

                        </input-limit>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row p-0 mb-0">
                  <label class="col-md-5 col-form-label form-control-label">
                    C.V. (máx.)
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-7">
                    <validation-provider rules="required|min_numeric:0.1" v-slot="{errors}">
                      <base-input input-group-classes="input-group-sm">
                        <input-limit :options="{
                            min: 0,
                            max: 29.9,
                            decimals: 1,
                          }" :start_value="statistical_reference.max_cv"
                                     v-model="statistical_reference.max_cv"
                                     @value="statistical_reference.max_cv = $event"
                        >
                          <small>%</small>
                        </input-limit>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="row" style="margin-top: -20px">
                  <div class="col-md-12">
                    <hr>
                  </div>
                </div>
              </div>
              <div style="margin-top: -20px">
                <div class="form-group row p-0 mb-0">
                  <label class="col-md-5   col-form-label form-control-label">
                    Corte inferior
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-7">
                    <validation-provider rules="required" v-slot="{errors}">
                      <base-input input-group-classes="input-group-sm">
                        <input-limit :options="{
                            min: 0,
                            max: 100,
                          }" :start_value="statistical_reference.undercut"
                                     v-model="statistical_reference.undercut"
                                     @value="statistical_reference.undercut = $event"
                        >
                          <small>%</small>
                        </input-limit>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row p-0 mb-0">
                  <label class="col-md-5   col-form-label form-control-label">
                    Corte superior
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-7">
                    <validation-provider rules="required" v-slot="{errors}">
                      <base-input input-group-classes="input-group-sm">
                        <input-limit :options="{
                            min: 0,
                            max: 100,
                          }" :start_value="statistical_reference.top_cut"
                                     v-model="statistical_reference.top_cut"
                                     @value="statistical_reference.top_cut = $event"
                        >
                          <small>%</small>
                        </input-limit>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <base-button type="secondary" @click="close()">
            Cancelar
          </base-button>
          <base-button
            @click.prevent="save()"
            type="success"
            :disabled="invalid"
            native-type="submit"
            :loading="isLoading"
          >
            <i class="fas fa-save"></i>
            Salvar
          </base-button>
        </div>
      </validation-observer>
    </modal>
  </div>
</template>

<script>
import InputLimit from "@/components/Utils/InputLimit";
import puzlSelect from "@/components/PuzlSelect";
import {mapGetters} from "vuex";
import {formatErrorValidation} from "@/plugins";

export default {
  name: "ModalCreateStatisticalReference",
  props: ['show'],
  components: {
    InputLimit,
    puzlSelect
  },
  data() {
    return {
      title: 'Referências estatísticas',
      isLoading: false,
      header: null,
      loadingReliability: false,
      statistical_reference: {
        hardened_state_id: null,
        mix_feature_id: null,
        rm: null,
        sd: null,
        nc: null,
        z: null,
        fr: null,
        max_sd: null,
        max_cv: null,
        undercut: null,
        top_cut: null
      }
    }
  },
  computed: {
    ...mapGetters({
      $_reliability_levels: 'reliability_level/fetch',
    }),
  },
  mounted() {
    this.loadingReliability = true
    this.$store.dispatch('reliability_level/fetchItems').then(() => this.loadingReliability = false)
  },
  methods: {
    close() {
      this.$emit('close')
    },
    changeValueZ() {
      this.$nextTick(() => {
        this.statistical_reference.z = this.statistical_reference.nc
        this.changeFr()
      })
    },
    changeFr() {
      this.$nextTick(() => {
        const rm = +this.statistical_reference.rm
        const sd = +this.statistical_reference.sd
        const z = +this.statistical_reference.z
        this.statistical_reference.fr = Math.round(rm + sd * z).toFixed(1)
      })
    },
    setHeader(params) {
      if (params.statistical_reference) {
        this.statistical_reference = params.statistical_reference
      } else {
        this.statistical_reference = {
          hardened_state_id: params.hardened_state.id,
          mix_feature_id: params.mix_feature.id,
          rm: null,
          sd: null,
          nc: null,
          z: null,
          fr: null,
          max_sd: null,
          max_cv: null,
          undercut: null,
          top_cut: null
        }
      }
      this.header = params
    },
    save() {
      this.$Progress.start();
      this.isLoading = true
      this.$store.dispatch('DashboardTechnology/postStorStatisticalReference', this.statistical_reference).then(response => {
        this.isLoading = false
        this.$notify({
          type: response.error_type,
          message: response.message,
        });
        this.close()
        this.$Progress.finish()
      }).catch(error => {
        let message = formatErrorValidation(error.response.data.errors);
        this.$notify({type: "danger", message: message});
        this.$Progress.finish()
        this.isLoading = false
      })
    }
  },
};
</script>

<style>
</style>
