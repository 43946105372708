<template>
  <div class="row mx-0">
    <div class="col-lg-3 px-0 pr-1">
      <div class="card p-3">
        <div class="row mb-1">
          <div class="col-12 mb-1">
            <img src="/img/icons/icons8/ios/bank-building_success.png" width="24">
          </div>
          <div class="col-12 mt-1">
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              Saldo inicial
            </h5>
            <h3 class="new-default-black-font m-0">
              {{ openingBalance | currency }}
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 px-1">
      <div class="card p-3">
        <div class="row mb-1">
          <div class="col-12 mb-1">
            <img src="/img/icons/icons8/ios/login-rounded-up_success.png" width="24">
          </div>
          <div class="col-12 mt-1">
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              Créditos
            </h5>
            <h3 class="new-default-black-font m-0">
              {{ creditBalance | currency }}
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 px-1">
      <div class="card p-3">
        <div class="row mb-1">
          <div class="col-12 mb-1">
            <img src="/img/icons/icons8/ios/login-rounded-down_danger.png" width="24">
          </div>
          <div class="col-12 mt-1">
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              Débitos
            </h5>
            <h3 class="new-default-black-font m-0">
              {{ debitBalance | currency }}
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 px-0 pl-1">
      <div class="card p-3">
        <div class="row mb-1">
          <div class="col-12 mb-1">
            <img src="/img/icons/icons8/ios/calculator_warning.png" width="24">
          </div>
          <div class="col-12 mt-1">
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              Saldo Final
            </h5>
            <h3 class="new-default-black-font m-0">
              {{ finalBalance | currency }}
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CalcHeader",
  data(){
    return {}
  },
  props: {
    openingBalance: Number,
    creditBalance: Number,
    debitBalance: Number,
    finalBalance: Number,
  },
  components: {},
  methods: {},
};
</script>
<style scoped>
.card {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.07) !important;
  border-radius: 8px !important;
  border: 1px solid #ECECEC !important;
}
</style>
