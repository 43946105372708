<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div>
              <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
                <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
                  <div>
                    <div class="form-group row m-0 mb-1">
                      <div class="col-6 text-center">
                        <label class=" col-form-label form-control-label text-danger">
                          Inferior
                        </label>
                      </div>
                      <div class="col-6 text-center">
                        <label class=" col-form-label form-control-label form-group m-0 text-success">
                          Superior
                        </label>
                      </div>
                    </div>

                    <!-- Aglomerante -->
                    <div class="form-group row m-0 mb-1">
                      <div class="col-12">
                        <label class="form-control-label">
                          Aglomerante
                          <span class="text-danger">&nbsp;*</span>
                        </label>
                      </div>

                      <div class="col-6 text-center">
                        <validation-provider rules="required">
                          <input-limit :options="{
                            min: -999,
                            max: 0,

                          }" :start_value="limit.binder.bottom"
                                       v-model="limit.binder.bottom"
                                       @value="limit.binder.bottom = $event"
                          >
                            <small class="input-group-sm p-0 m-0" style="text-transform: none">
                              kg/m<sup>3</sup>
                            </small>
                          </input-limit>
                        </validation-provider>
                      </div>
                      <div class="col-6 text-center">
                        <validation-provider rules="required">
                          <input-limit :options="{
                            min: 0,
                            max: 999,
                          }" :start_value="limit.binder.top"
                                       v-model="limit.binder.top"
                                       @value="limit.binder.top = $event"
                          >
                            <small class="input-group-sm p-0 m-0" style="text-transform: none">
                              kg/m<sup>3</sup>
                            </small>
                          </input-limit>
                        </validation-provider>
                      </div>
                    </div>
                    <!-- Àgua -->
                    <div class="form-group row m-0 mb-1">
                      <div class="col-12">
                        <label class="form-control-label">
                          Água
                          <span class="text-danger">&nbsp;*</span>
                        </label>
                      </div>

                      <div class="col-6 text-center">
                        <validation-provider rules="required">
                          <input-limit :options="{
                            min: -999,
                            max: 0,
                          }" :start_value="limit.water.bottom"
                                       v-model="limit.water.bottom"
                                       @value="limit.water.bottom = $event"
                          >
                            <small class="input-group-sm p-0 m-0" style="text-transform: none">
                              kg/m<sup>3</sup>
                            </small>
                          </input-limit>
                        </validation-provider>
                      </div>
                      <div class="col-6 text-center">
                        <validation-provider rules="required">
                          <input-limit :options="{
                            min: 0,
                            max: 999,
                          }" :start_value="limit.water.top"
                                       v-model="limit.water.top"
                                       @value="limit.water.top = $event"
                          >
                            <small class="input-group-sm p-0 m-0" style="text-transform: none">
                              kg/m<sup>3</sup>
                            </small>
                          </input-limit>
                        </validation-provider>
                      </div>
                    </div>

                    <div class="form-group row m-0 mb-1">
                      <div class="col-12">
                        <label class="form-control-label">
                          Teor de argamassa

                          <span class="text-danger">&nbsp;*</span>
                        </label>
                      </div>

                      <div class="col-6 text-center">
                        <validation-provider rules="required">
                          <input-limit :options="{
                            min: -100.0,
                            max: 0,
                            decimals: 1,
                          }" :start_value="limit.content_mortar.bottom"
                                       v-model="limit.content_mortar.bottom"
                                       @value="limit.content_mortar.bottom = $event"
                          >
                            <small class="input-group-sm p-0 m-0">
                              %
                            </small>
                          </input-limit>
                        </validation-provider>
                      </div>
                      <div class="col-6 text-center">
                        <validation-provider rules="required">
                          <input-limit :options="{
                            min: 0,
                            max: 100.0,
                            decimals: 1,

                          }" :start_value="limit.content_mortar.top"
                                       v-model="limit.content_mortar.top"
                                       @value="limit.content_mortar.top = $event"
                          >
                            <small class="input-group-sm p-0 m-0">
                              %
                            </small>
                          </input-limit>
                        </validation-provider>
                      </div>
                    </div>

                    <!-- Ar total -->
                    <div class="form-group row m-0 mb-1">
                      <div class="col-12">
                        <label class="form-control-label">
                          Ar Total
                          <span class="text-danger">&nbsp;*</span>
                        </label>
                      </div>

                      <div class="col-6 text-center">
                        <validation-provider rules="required">
                          <input-limit :options="{
                            min: -99.9,
                            max: 0,
                            decimals: 1,
                          }" :start_value="limit.total_air.bottom"
                                       v-model="limit.total_air.bottom"
                                       @value="limit.total_air.bottom = $event"
                          >
                            <small class="input-group-sm p-0 m-0">
                              %
                            </small>
                          </input-limit>
                        </validation-provider>
                      </div>
                      <div class="col-6 text-center">
                        <validation-provider rules="required">
                          <input-limit :options="{
                            min: 0,
                            max: 99.9,
                            decimals: 1,
                          }" :start_value="limit.total_air.top"
                                       v-model="limit.total_air.top"
                                       @value="limit.total_air.top = $event"
                          >
                            <small class="input-group-sm p-0 m-0">
                              %
                            </small>
                          </input-limit>
                        </validation-provider>
                      </div>
                    </div>
                    <!-- Aditivos -->
                    <div class="form-group row m-0 mb-1">
                      <div class="col-12">
                        <label class="form-control-label">
                          Aditivos

                          <span class="text-danger">&nbsp;*</span>
                        </label>
                      </div>
                      <div class="col-6 text-center">
                        <validation-provider rules="required">
                          <input-limit :options="{
                            min: -99.9,
                            max: 0,
                            decimals: 1,
                          }" :start_value="limit.agent.bottom"
                                       v-model="limit.agent.bottom"
                                       @value="limit.agent.bottom = $event"
                          >
                            <small class="input-group-sm p-0 m-0">
                              %
                            </small>
                          </input-limit>
                        </validation-provider>
                      </div>
                      <div class="col-6 text-center">
                        <validation-provider rules="required">
                          <input-limit :options="{
                            min: 0,
                            max: 99.9,
                            decimals: 1,
                          }" :start_value="limit.agent.top"
                                       v-model="limit.agent.top"
                                       @value="limit.agent.top = $event"
                          >
                            <small class="input-group-sm p-0 m-0">
                              %
                            </small>
                          </input-limit>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                </form>
              </validation-observer>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')">
                Cancelar
              </base-button>
              <base-button :disabled="invalid" type="success" native-type="submit">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex'

import {
  formatErrorValidation, mask1
} from '@/plugins'
import InputLimit from "@/components/Utils/InputLimit";

export default {
  name: "ModalAllLimitsSettingCreate",
  components: {
    InputLimit
  },
  data() {
    return {
      exist: false,
      modal: {
        title: 'Ajustes totais',
        create: false,
      },
      limit: {
        binder: {
          top: null,
          bottom: null
        },
        adjustment_type: 8,
        uuid: null,
        water: {
          top: null,
          bottom: null
        },
        content_mortar: {
          top: null,
          bottom: null
        },
        total_air: {
          top: null,
          bottom: null
        },
        agent: {
          top: null,
          bottom: null
        }
      },
      loadingStore: false,
    }
  },
  computed: {},
  methods: {
    closeModal() {
      this.modal.create = false
    },
    checkLimitSetting() {
      if (this.limit.binder.bottom) {
        this.exist = true
      } else {
        this.limit.adjustment_type = 8
      }
    },
    handleCreateModal() {
      this.$Progress.start()
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      })
      this.modal.create = true
      this.loadingStore = true
      this.$store.dispatch('adjustmentLimitSetting/getByType', 8)
        .then((response) => {
          this.limit = {
            binder: {
              top: response.data.binder.top,
              bottom: response.data.binder.bottom
            },
            adjustment_type: response.data.adjustment_type,
            uuid: response.data.uuid,
            water: {
              top: response.data.water.top,
              bottom: response.data.water.bottom
            },
            content_mortar: {
              top: response.data.content_mortar.top,
              bottom: response.data.content_mortar.bottom
            },
            total_air: {
              top: response.data.total_air.top,
              bottom: response.data.total_air.bottom
            },
            agent: {
              top: response.data.agent.top,
              bottom: response.data.agent.bottom
            }
          }
          this.$Progress.finish()
          this.loadingStore = false
          this.$notify({
            type: response.error_type,
            message: response.message
          })
          this.checkLimitSetting()
          this.modal.open = true
        }).catch((error) => {
        if (error.response && error.response.status === 422) {
          let errors = formatErrorValidation(error.response.data.errors)
          this.$notify({
            type: 'danger',
            message: errors
          })
        }
        this.$Progress.finish();
        this.loadingStore = false
      })
    },
    store() {
      this.$Progress.start()
      this.loadingStore = true
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      })
      if (this.exist) {
        this.$store.dispatch('adjustmentLimitSetting/update', this.limit)
          .then(response => {
            this.loadingStore = false
            this.modal.create = false
            this.$Progress.finish()
            this.$notify({
              type: response.error_type,
              message: response.message
            })
            EventBus.$emit("getPieceAdjustments");
          })
          .catch(error => {
            if (error.response.status === 422) {
              let errors = formatErrorValidation(error.response.data.errors)
              this.$notify({
                limit: 'danger',
                message: errors
              })
            }
            this.$Progress.finish()
            this.loadingStore = false
          })
      } else {
        this.$store.dispatch('adjustmentLimitSetting/add', this.limit)
          .then(response => {
            this.loadingStore = false
            this.modal.create = false
            this.$Progress.finish()
            this.$notify({
              type: response.error_type,
              message: response.message
            })
            EventBus.$emit("getPieceAdjustments");
          })
          .catch(error => {
            if (error.response.status === 422) {
              let errors = formatErrorValidation(error.response.data.errors)
              this.$notify({
                type: 'danger',
                message: errors
              })
            }
            this.$Progress.finish()
            this.loadingStore = false
          })
      }
    }
  },
}
</script>

<style scoped>

</style>
