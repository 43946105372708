<template>
  <div>
    <div class="row card-wrapper" v-show="loadingSkeleton">
      <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
    </div>
    <div class="row card-wrapper" v-if="$_productServices.length && !loadingSkeleton">
      <div class="col-lg-4" v-for="(item, index) in $_productServices" :key="index">
        <div class="card main-card mb-3" style="border-radius: 20px !important"> 
          <div class="p-3 px-4">
            <div class="row">
              <div class="col-10">
                <h6 class="new-default-gray-font p-0 m-0 mb-2">
                  {{ item.type_name }}
                </h6>
                <h4 class="new-default-black-font m-0 p-0">
                  {{ item.product_name }}
                </h4>
              </div>
              <div class="col-2 text-right">
                <base-dropdown menuOnRight>
                  <div slot="title-container" class="dropdown-toggle rounded m-0">
                    <img width="32" src="/img/icons/icons8/ios/settings--v1_primary.png" />
                  </div>
                  <a 
                    v-if="item.product_service_hub_type_id !== ProductServiceHubTypeEnum.MCC"
                    @click.prevent="$emit('handleEditProductService', item.hubable_id, item.id)"
                    class="dropdown-item text-dark"
                    style="align-items: center; display: flex">
                    <img width="22" src="/img/icons/edit.png" />
                    EDITAR
                  </a>
                  <div 
                    v-if="item.product_service_hub_type_id !== ProductServiceHubTypeEnum.MCC"
                    class="dropdown-divider p-0 m-0" />
                  <a 
                    @click.prevent="$emit('handleSearchEntity', item)"
                    class="dropdown-item text-dark"
                    style="align-items: center; display: flex">
                    <img width="22" src="/img/icons/icons8/ios/place-marker--v1.png" />
                    Fornecedores
                  </a>
                  <div
                    v-if="item.product_service_hub_type_id !== ProductServiceHubTypeEnum.MCC"
                    class="dropdown-divider p-0 m-0" />
                  <a 
                    v-if="item.product_service_hub_type_id !== ProductServiceHubTypeEnum.MCC"
                    @click.prevent="$emit('handleDeleteProductService', item.hubable_id)"
                    class="dropdown-item text-dark"
                    style="align-items: center; display: flex"
                  >
                    <img width="22" src="/img/icons/delete.png" />
                    EXCLUIR
                  </a>
                </base-dropdown>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-12" v-if="item.product_service_hub_type_id === ProductServiceHubTypeEnum.MCC">
                <h4 class="new-default-black-font m-0 font-weight-normal">
                  {{ item.supplier_name }} ({{ item.origin_name }})
                </h4>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <h6 class="new-default-gray-font p-0 m-0 mt-3 font-weight-400"
                  style="font-size: 11.5px;">
                  {{ item.category_name }}
                </h6>
              </div>
              <div class="col-auto">
                <base-button
                  outline
                  slot="reference"
                  size="sm"
                  type="secundary"
                  @click.prevent="$emit('handleListPrices', item)"
                  class="p-0 m-0 mr-2 shadow-none text-indigo"
                >
                  <img src="/img/icons/icons8/ios/new-store_primary.png" width="25">
                </base-button>
                <el-popover
                  v-if="
                    item.product_service_hub_type_id !== ProductServiceHubTypeEnum.MCC &&
                    (item.ncm || item.cfop || item.cnae || item.observation)
                  "
                  trigger="click"
                  placement="bottom"
                >
                  <span style="width: 290px;"
                    class="row">
                    <div class="col-12 mb-2" style="display: flex; align-items: center;">
                      <img class="mr-2" src="/img/icons/icons8/ios/info-circle_warning.png" width="22">
                      <h4 style="font-size: 16px;" class="p-0 m-0">Detalhes</h4>
                    </div>
                    <div class="col-12 mt-1">
                      <h5 v-if="item.ncm" class="font-weight-normal m-0">
                        NCM: <b>{{item.ncm}}</b> 
                      </h5>
                      <h5 v-if="item.cfop" class="font-weight-normal m-0">
                        CFOP: <b>{{item.cfop}}</b>
                      </h5>
                      <h5 v-if="item.cnae" class="font-weight-normal m-0"> 
                        CNAE: <b>{{item.cnae}}</b>
                      </h5>
                      <h5 v-if="item.observation" class="font-weight-normal m-0"> 
                        {{ item.observation }}
                      </h5>
                    </div>  
                  </span>
                  <base-button 
                    outline
                    slot="reference"
                    size="sm"
                    type="secundary"
                    class="p-0 m-0 mr-2 shadow-none text-indigo"
                  >
                    <img src="/img/icons/icons8/ios/info-circle_warning.png" width="25">
                  </base-button>
                </el-popover>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import SkeletonPuzlFullWidth  from "@/components/SkeletonPuzlFullWidth";
import {ProductServiceHubTypeEnum} from "@/enum/ProductServiceHubTypeEnum";

export default {
  name: "ListCardsProductService",
  props: {
    loadingSkeleton: Boolean,
  },
  components: {
    SkeletonPuzlFullWidth,
    SkeletonPuzlGrid,
  },
  data() {
    return {
      ProductServiceHubTypeEnum: ProductServiceHubTypeEnum,
    };
  },
  computed: {
    ...mapGetters({
      $_productServices: 'productService/fetch',
    }),
  },
}
</script>

<style scoped>
.new-default-gray-font {
  color: #606062;
}
</style>