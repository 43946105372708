<template>
  <div>
    <div class="container-fluid">
      <div class="mt-4 mb-n5 ml-2">
        <i :class="params.type !== 0 && 'opacity-25'" @click.prevent="params.type = 0"
           class="pointer fa-regular fa-hard-drive fa-2x"></i>
        <i :class="params.type !== 1 && 'opacity-25'" @click.prevent="params.type = 1"
           class="pointer fa-solid fa-table-list fa-2x ml-1"></i>
      </div>
      <div class="mt-5">
        <multi-filter @clearFilter="clearFilter()" @handleFilterDate="handleFilterDate" @fetch="init">
          <template slot="dates">
            <div class="col-md-12 text-left">
              <label class="form-control-label">
                EMISSÃO DA FATURA
              </label>
              <input-date-picker @handleFilterDate="handleFilterDefaultDate" visibility="focus"/>
            </div>
            <div class="col-md-12 mt-3 text-left">
              <label class="form-control-label">
                Entrega
              </label>
              <input-date-picker @handleFilterDate="handleFilterDate" visibility="focus"/>
            </div>
          </template>
          <template slot="data">
            <div class="row pr-3 mt-1 pl-3">
              <div class="col-12 text-left">
                <label class="form-control-label">
                  Nº da fatura
                </label>
                <div class="row">
                  <div class="col-6 text-left pr-1">
                    <input @change="setRange()" v-model="filter.range_invoice_number.min" placeholder="de"
                           class="form-control form-control-sm btn-first-child"></input>
                  </div>
                  <div class="col-6 pl-1">
                    <input @change="setRange()" v-model="filter.range_invoice_number.max" placeholder="até"
                           class="form-control form-control-sm"></input>
                  </div>
                </div>
              </div>
            </div>
            <div class="row pr-3 mt-2 pl-3">
              <div class="col-12 text-left">
                <label class="form-control-label">
                  Valor da fatura
                </label>
                <div class="row">
                  <div class="col-6 text-left pr-1">
                    <base-input v-mask="['#,##', '##,##', '###,##', '#,###.##', '##,###.##', '###,###.##']"
                                inputmode="numeric" @change="setRange()" v-model="filter.range_invoice_value.min"
                                input-group-classes="input-group-sm" placeholder="de" size="sm">
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0">
                          R$
                        </small>
                      </template>
                    </base-input>
                  </div>
                  <div class="col-6 pl-1">
                    <base-input v-mask="['#,##', '##,##', '###,##', '#,###.##', '##,###.##', '###,###.##']"
                                inputmode="numeric" @change="setRange()" v-model="filter.range_invoice_value.max"
                                input-group-classes="input-group-sm" placeholder="até" size="sm">
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0">
                          R$
                        </small>
                      </template>
                    </base-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="row pr-3 mt-2 pl-3">
              <div class="col-12 text-left">
                <label class="form-control-label">
                  Nº da NFS-e
                </label>
                <div class="row">
                  <div class="col-6 text-left pr-1">
                    <input @change="setRange()" v-model="filter.range_nfse_number.min" placeholder="de"
                           class="form-control form-control-sm btn-first-child"></input>
                  </div>
                  <div class="col-6 pl-1">
                    <input @change="setRange()" v-model="filter.range_nfse_number.max" placeholder="até"
                           class="form-control form-control-sm"></input>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 text-left">
              <label class="form-control-label">
                Vendedor
              </label>
              <div class="row">
                <div class="col-12 text-left">
                  <base-input @blur="init({})" v-on:keyup.enter="init({})" inputmode="numeric" v-model="filter.seller"
                              input-group-classes="input-group-sm" size="sm">
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0 pointer" @click.prevent="init({})">
                        <i class="fa-solid fa-magnifying-glass"></i>
                      </small>
                    </template>
                  </base-input>
                </div>
              </div>
            </div>
          </template>
          <template slot="status">
            <div class="col-12">
              <base-button-hoverable size="sm" :active="filter.status_paid === 1" @click="handleFilterAdvancedStatus(1)"
                                     type="success" icon="recieve" platform="ios">
                Recebido
              </base-button-hoverable>
            </div>
            <div class="col-12 mt-1">
              <base-button-hoverable size="sm" :active="filter.status_paid === 0" @click="handleFilterAdvancedStatus(0)"
                                     type="warning" icon="hourglass" platform="ios">
                Pendente
              </base-button-hoverable>
            </div>
            <div class="col-12 mt-1">
              <base-button-hoverable size="sm" :active="filter.canceled === 1" @click="handleFilterCanceledStatus(1)"
                                     type="danger" icon="cancel" platform="ios">
                CANCELADO
              </base-button-hoverable>
            </div>
            <div class="col-12 mt-1">
              <base-button :class="filter.reversed && 'active'" style="border: 1px solid #DCDFE6 !important;" size="sm"
                           block type="danger" outline
                           @click="handleFilterReversedStatus(1)">
                <i class="fa-solid fa-rotate-left"></i> ESTORNADO
              </base-button>
            </div>
          </template>
          <template slot="bookmarks">
            <div class="col-12">
              <base-button-hoverable size="sm" :active="filter.without_car"
                                     @click="filter.without_car = Number(!filter.without_car); init({})" type="danger"
                                     icon="duration-finance"
                                     platform="ios">
                FATURA SEM CONTAS A RECEBER
              </base-button-hoverable>
            </div>
            <div class="col-12 mt-1">
              <base-button-hoverable size="sm" :active="filter.email_dont_sended"
                                     @click="filter.email_dont_sended = Number(!filter.email_dont_sended); init({})"
                                     type="success"
                                     icon="new-post--v1" platform="ios">
                E-MAIL NÃO ENVIADO
              </base-button-hoverable>
            </div>
            <div class="col-12 mt-1">
              <base-button-hoverable size="sm" :active="filter.with_iss"
                                     @click="filter.with_iss = Number(!filter.with_iss); init({})" type="default"
                                     icon="general-ledger"
                                     platform="ios">
                ISS RETIDO
              </base-button-hoverable>
            </div>
            <div class="col-12 mt-1">
              <base-button-hoverable size="sm" :active="filter.nfse_pending_issue"
                                     @click="filter.nfse_pending_issue = Number(!filter.nfse_pending_issue); init({})"
                                     type="primary"
                                     icon="nfse" platform="custom">
                NOTAS A EMITIR
              </base-button-hoverable>
            </div>
            <div class="col-12 mt-1">
              <base-button-hoverable size="sm" :active="filter.nfse_denied"
                                     @click="filter.nfse_denied = Number(!filter.nfse_denied); init({})" type="primary"
                                     icon="nfse"
                                     platform="custom">
                NOTAS NEGADAS
              </base-button-hoverable>
            </div>
            <div class="col-12 mt-1">
              <base-button-hoverable size="sm" :active="filter.nfse_issuing"
                                     @click="filter.nfse_issuing = Number(!filter.nfse_issuing); init({})"
                                     type="primary" icon="nfse"
                                     platform="custom">
                NOTAS EMITINDO
              </base-button-hoverable>
            </div>
          </template>
          <template slot="order-by">
            <el-popover trigger="click" placement="bottom" class="p-0 float-right pr-0 ml-3">
              <h6><a @click.prevent="handleFilterOrderBy(1)" href="#" class="font-weight-400" style="font-size: 12px">
                <i :class="filter.order_by === 1 && 'text-primary'" class="fa-solid fa-angles-down"
                   style="font-size: 12px;margin-right: 5px"></i>
                Valor: do menor para o maior</a></h6>
              <hr class="mb-1 mt-n2">
              <h6><a @click.prevent="handleFilterOrderBy(2)" href="#" class="font-weight-400" style="font-size: 12px">
                <i :class="filter.order_by === 2 && 'text-primary'" class="fa-solid fa-angles-up"
                   style="font-size: 12px;margin-right: 5px"></i> Valor: do maior para o menor</a></h6>
              <hr class="mb-1 mt-n2">
              <!--            <h6 style="text-transform: none"><a @click.prevent="handleFilterOrderBy(3)" href="#" class="font-weight-400" style="font-family: Quicksand !important;">-->
              <!--              <i :class="filter.order_by === 3 && 'text-primary'" class="fa-solid fa-angles-down"></i> Data de vencimento: da mais nova para a mais velha</a> </h6>-->
              <!--            <hr class="mb-1 mt-n2">-->
              <!--            <h6 style="text-transform: none"><a @click.prevent="handleFilterOrderBy(4)" href="#" class="font-weight-400" style="font-family: Quicksand !important;">-->
              <!--              <i :class="filter.order_by === 4 && 'text-primary'" class="fa-solid fa-angles-up"></i> Data de vencimento: da mais velha para a mais nova</a> </h6>-->
              <!--            <hr class="mb-1 mt-n2">-->
              <h6><a @click.prevent="handleFilterOrderBy(5)" href="#" class="font-weight-400" style="font-size: 12px">
                <i :class="filter.order_by === 5 && 'text-primary'" class="fa-solid fa-angles-down"
                   style="font-size: 12px;margin-right: 5px"></i> Número da fatura: da mais nova para a mais velha</a>
              </h6>
              <hr class="mb-1 mt-n2">
              <h6><a @click.prevent="handleFilterOrderBy(6)" href="#" class="font-weight-400" style="font-size: 12px">
                <i :class="filter.order_by === 6 && 'text-primary'" class="fa-solid fa-angles-up"
                   style="font-size: 12px;margin-right: 5px"></i> Número da fatura: da mais velha para a mais nova</a>
              </h6>
              <a href="#" slot="reference">
                <h5 slot="reference" class="pt-1">ORDENAR <i class="fa-solid fa-chevron-down"></i></h5>
              </a>
            </el-popover>
          </template>
          <div class="col-md-2 p-2">
            <base-button :class="filter.status_paid === 1 && 'active'" @click="handleFilterAdvancedStatus(1)" outline
                         block type="success"><i class="fas fa-dollar-sign"></i> RECEBIDO
            </base-button>
          </div>
          <div class="col-md-2 p-2">
            <base-button :class="filter.status_paid === 0 && 'active'" @click="handleFilterAdvancedStatus(0)" outline
                         block type="warning"><i class="fas fa-dollar-sign"></i> PENDENTE
            </base-button>
          </div>
          <div class="col-md-2 p-2">
            <base-button :class="filter.canceled === 1 && 'active'" @click="handleFilterCanceledStatus" outline block
                         type="danger">
              <i class="fa-solid fa-ban"></i>CANCELADA
            </base-button>
          </div>
          <div class="col-md-2 p-2">
            <base-button :class="filter.verify_status === 1 && 'active'" outline @click="handleFilterVerifyStatus" block
                         type="light">
              <i class="fa-solid fa-ban"></i> VERIFICAR
            </base-button>
          </div>
        </multi-filter>
      </div>
      <div class="row card-wrapper" v-if="params.type === 0 && !loadingSkeleton && invoices.length">
        <div class="col-md-4" v-for="(item, index) in invoices" :key="index">
          <card>
            <div class="row mt-2">
              <div class="col-4 text-left pr-1 mt-n2">
                <h4 style="font-size: 1.2rem;padding-top: -5px" class="font-weight-600">{{
                    item.contract_proposal.code
                  }}
                  <el-popover trigger="click" placement="right" class="p-0">
                    <div class="row">
                      <div class="mt-n2 p-4">
                        <div>
                          <h3 slot="title">
                            <img src="/img/icons/salesman.png" width="20">
                            Vendedor
                          </h3>
                          <span class="h4 small"> {{ item.seller }} </span>
                          <a
                            href="#"
                            v-if="$_commercial_permission.contract_editor_and_seller"
                            @click.prevent="handleCreateModalEditSeller(item.id)"
                          >
                            <img src="/img/icons/edit.png" width="24" class="ml-2"/>
                          </a>
                        </div>
                        <hr class="bg-secondary" style="margin-top: 12px;">
                        <div class="mt-n3">
                          <h3 slot="title">
                            <img src="/img/icons/icons8/ios/user-male-blue.png" width="20">
                            Intermediador
                          </h3>
                          <span class="h4 small"> {{ item.user_intermediary_name  }} </span>
                          <a
                            href="#"
                            v-if="$_commercial_permission.contract_editor_and_seller"
                            @click.prevent="handleEditIntermediaryModal(item.id)"
                          >
                            <img src="/img/icons/edit.png" width="24" class="ml-2"/>
                          </a>
                        </div>                                                
                      </div>
                    </div>
                    <span slot="reference">
                      <img src="/img/icons/salesman.png" width="19px">
                    </span>
                  </el-popover>
                  <el-popover v-if="hasTotvs && item.is_integrated_on_totvs === TotvsStatusEnum.INTEGRATED" trigger="hover" placement="right" class="p-0 ml-2">
                    <span>
                      <span class="text-center">Integrado com a totvs</span>
                    </span>
                    <span slot="reference">
                      <img src="/img/icons/icons8/ios/connected_success.png" width="19px">
                    </span>
                  </el-popover>
                  <el-popover v-else-if="hasTotvs && item.is_integrated_on_totvs === TotvsStatusEnum.ERROR" trigger="hover" placement="right" class="p-0 ml-2">
                    <span>
                      <span class="text-center">Erro de integração com a totvs</span>
                    </span>
                    <span slot="reference">
                      <img src="/img/icons/icons8/ios/disconnected_danger.png" width="19px">
                    </span>
                  </el-popover>
                </h4>
              </div>
              <div class="col-5 mb-2 mt-n1">
                <template
                  v-if="new Date(item.created_at).toDateString() === new Date().toDateString() &&
  item.auto_mail !== 2 && !item.reversed"
                >
                  <div class="pointer" @click.prevent="setSendToMail(item, 1)" v-if="!item.auto_mail">
                    <badge
                      style="background: #FFFFFF;border: 1px solid green;width: 100%" type="success">
                      <i class="text-success fa-regular fa-envelope mr-1"></i>
                      Não enviar
                    </badge>
                  </div>
                  <div class="pointer" @click.prevent="setSendToMail(item, 0)" v-if="item.auto_mail === 1">
                    <badge
                      style="background: #149e57;width: 100%" type="success text-white ">
                      <i class="fa-regular fa-envelope mr-1"></i>
                      Enviar
                    </badge>
                  </div>
                </template>
                <template v-else>
                  <div class="pointer" @click.prevent="handleShowMailHistory(item.id)"
                       v-if="item.auto_mail === 2 && !item.reversed">
                    <badge
                      style="background: #1A70B7;width: 100%" type="success  text-white">
                      <i class="fa-regular fa-envelope mr-1"></i>
                      Enviado
                    </badge>
                  </div>
                  <div class="pointer" @click.prevent="handleShowMailHistory(item.id)"
                       v-if="item.auto_mail === 3 && !item.reversed">
                    <badge
                      style="background: #d03d35;opacity: 90%;width: 100%" type="success text-white">
                      <i class="fa-regular fa-envelope mr-1"></i>
                      Falha
                    </badge>
                  </div>
                  <div class="pointer" @click.prevent="handleShowMailHistory(item.id)"
                       v-if="item.auto_mail === 4 && !item.reversed">
                    <badge
                      style="background: #00ABE7;opacity: 90%;width: 100%" type="success text-white">
                      <i class="fa-regular fa-envelope mr-1"></i>
                      Enviando
                    </badge>
                  </div>
                </template>
              </div>
              <div class="col-3 text-right mt-n2">
                <template v-if="item.reversed">
                  <base-dropdown-v2>
                    <div>
                      <base-dropdown-item @click="handleInvoiceHistory(item.id)">
                        <img src="/img/icons/order-history.png" class="mr-2" width="19px" height="19px"/>Histórico
                      </base-dropdown-item>
                    </div>
                  </base-dropdown-v2>
                </template>
                <template v-else>
                  <base-dropdown-v2>
                    <div v-if="item.method_is_billet && item.is_pending && $_setting.allow_bank_billet_as_attachment">
                      <base-dropdown-item @click="handleShowInvoiceAttachment(item)">
                        <img width="19px" height="19px" class="mr-2" src="/img/icons/attach-blue-50.png">
                        Anexar Boleto
                      </base-dropdown-item>
                    </div>
                    <div
                      v-if="((!item.nfse) || (item.nfse && (item.nfse.status === 'Cancelada' || item.nfse.status === 'Negada'))) && !item.has_any_dont_pending && !item.all_pending && !item.reversed">
                      <base-dropdown-item @click="reverseReceivedes(item.id)">
                        <i class="fa-solid fa-rotate-left text-danger" style="margin-right: 11px"></i>
                        ESTORNAR FATURA
                      </base-dropdown-item>
                    </div>
                    <div v-if="((!item.nfse) || (item.nfse && item.nfse.status === 'Negada'))">
                      <base-dropdown-item @click="handleOpenNfseModal(item)">
                        <img width="19px" height="19px" class="mr-2" src="/img/icons/nfe_blue.png">
                        Emitir nota fiscal
                      </base-dropdown-item>
                      <div class="dropdown-divider p-0 m-0"/>
                      <base-dropdown-item @click="handleOpenNfseAuthorizedImportModal(item)">
                        <img width="19px" height="19px" class="mr-2"
                             src="/img/icons/icons8/ios/export-pdf-2_secondary.png">
                        Importar nota fiscal
                      </base-dropdown-item>
                      <div class="dropdown-divider p-0 m-0"/>
                      <base-dropdown-item @click="handleOpenEmail(item)">
                        <img width="19px" height="19px" class="mr-2"
                             src="/img/icons/icons8/ios/new-post--v1_success.png">
                        Envio por e-mail
                      </base-dropdown-item>
                      <div class="dropdown-divider p-0 m-0"/>
                      <base-dropdown-item @click="handleInvoiceHistory(item.id)">
                        <img src="/img/icons/order-history.png" class="mr-2" width="19px" height="19px"/>Histórico
                      </base-dropdown-item>
                      <!--                      <div class="dropdown-divider p-0 m-0"/>-->
                      <!--                      <base-dropdown-item @click="removeInvoice(item.id)">-->
                      <!--                        <img width="19px" height="19px" class="mr-2" src="/img/icons/icons8/ios/delete&#45;&#45;v1_danger.png">-->
                      <!--                        Excluir-->
                      <!--                      </base-dropdown-item>-->
                    </div>
                    <div v-if="(item.nfse) && (item.nfse.status === 'Autorizada')">
                      <base-dropdown-item @click="handleCancelNfse(item, true)">
                        <img width="19px" height="19px" class="mr-2" src="/img/icons/icons8/ios/cancel-2_danger.png">
                        Cancelar NFS-e
                      </base-dropdown-item>
                      <base-dropdown-item v-show="!item.has_any_dont_pending"
                                          @click="handleCancelNfse(item, false, true)">
                        <img width="19px" height="19px" class="mr-2" src="/img/icons/icons8/ios/delete--v1_danger.png">
                        Estornar e Cancelar NFS-e
                      </base-dropdown-item>
                    </div>
                    <div v-if="(item.nfse) && (item.nfse.status === 'Pendente')">
                      <base-dropdown-item @click="handleOpenEmail(item)">
                        <img width="19px" height="19px" class="mr-2"
                             src="/img/icons/icons8/ios/new-post--v1_success.png">
                        Envio por e-mail
                      </base-dropdown-item>
                      <div class="dropdown-divider p-0 m-0"/>
                      <base-dropdown-item @click="handleInvoiceHistory(item.id)">
                        <img src="/img/icons/order-history.png" class="mr-2" width="19px" height="19px"/>Histórico
                      </base-dropdown-item>
                      <!--                      <div class="dropdown-divider p-0 m-0"/>-->
                      <!--                      <base-dropdown-item @click="removeInvoice(item.id)">-->
                      <!--                        <img width="19px" height="19px" class="mr-2" src="/img/icons/icons8/ios/delete&#45;&#45;v1_danger.png">-->
                      <!--                        Excluir-->
                      <!--                      </base-dropdown-item>-->
                    </div>
                    <div
                      v-if="(item.nfse) && ((item.nfse.status === 'Autorizada') || (item.nfse.status === 'Cancelada'))">
                      <base-dropdown-item @click="downloadNfseXml(item)">
                        <img width="19px" height="19px" class="mr-2" src="/img/icons/icons8/ios/xml.png"> XML
                      </base-dropdown-item>
                      <base-dropdown-item @click="handleInvoiceHistory(item.id)">
                        <img src="/img/icons/order-history.png" class="mr-2" width="19px" height="19px"/>Histórico
                      </base-dropdown-item>
                      <base-dropdown-item @click="handleOpenNfseSent(item)">
                        <img width="19px" height="19px" class="mr-2" src="/img/icons/icons8/ios/view-file_primary.png">
                        Visualizar
                      </base-dropdown-item>
                      <base-dropdown-item @click="handleOpenEmail(item)">
                        <img width="19px" height="19px" class="mr-2" src="/img/icons/icons8/ios/mail-primary.png"> Envio
                        por
                        e-mail
                      </base-dropdown-item>
                      <base-dropdown-item @click="handleChangeNfseModal(item)">
                        <img width="19px" height="19px" class="mr-2"
                             src="/img/icons/icons8/ios/refresh--v1_warning.png">
                        Substituir NFS-e
                      </base-dropdown-item>
                      <base-dropdown-item @click="handleModalInvoiceIntegrationInfo(item.id)" v-if="hasTotvs">
                        <img width="19px" height="19px" class="mr-2"
                             src="/img/icons/icons8/ios/downloading-updates-primary.png">
                        Totvs
                      </base-dropdown-item>
                    </div>
                  </base-dropdown-v2>
                </template>
              </div>
            </div>
            <div class="row mt-n25">
              <div class="col-12">
                <h5 class="font-weight-400 ">
                  {{ item.contract_proposal_situation }}
                </h5>
                <h4 class="mt-2">
                  {{
                    item.contract_proposal && item.contract_proposal.construction
                    && item.contract_proposal.construction.customer_construction.customer_name
                  }}
                </h4>
                <h4 class="mt-n25 font-weight-400" v-if="!isEqual(
                      item.contract_proposal.construction.customer_construction.customer_name,
                      item.contract_proposal.construction.construction_name
                    )">
                  {{
                    item.contract_proposal &&
                    item.contract_proposal.construction &&
                    item.contract_proposal.construction.construction_name
                  }} </h4>
                <h4 class="mt-n25 font-weight-400">
                  {{ item.company_plant.name }}
                </h4>
              </div>
              <!--              <div class="col-7 float-right text-right mt-4">-->
              <!--                <div>-->
              <!--                  <base-button style="width: 100px;font-weight: 400" type="danger" size="sm">-->
              <!--                    <i class="fa-solid fa-file-pdf"></i> Fatura-->
              <!--                  </base-button>-->
              <!--                </div>-->
              <!--                <br>-->
              <!--                <div style="margin-top: -17px">-->
              <!--                  <a class="avatar avatar-sm-2 bg-success mr-1">-->
              <!--                    <i class="fa-solid fa-robot text-white"></i>-->
              <!--                  </a>-->
              <!--                  <base-button style="width: 100px;font-weight: 500" type="white" size="sm">-->
              <!--                    <i class="fa-regular fa-file"></i> n° 3320-->
              <!--                  </base-button>-->
              <!--                </div>-->
              <!--                <br>-->
              <!--                <div style="margin-top: -17px">-->
              <!--                  <a class="avatar avatar-sm-2 bg-success mr-1">-->
              <!--                    <i class="fa-solid fa-robot text-white"></i>-->
              <!--                  </a>-->
              <!--                  <base-button style="width: 100px;font-weight: 400" type="light" size="sm">-->
              <!--                    <i class="fa-solid fa-barcode"></i> Boleto-->
              <!--                  </base-button>-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
            <div class="row">
              <div class="col-12 mb-1 mt-1">
                <hr class="mt-n1 mb-1">
              </div>
              <div class="col-6 pr-1">
                <div class="d-flex">
                  <h5 class="h5 new-default-black-font mr-1 font-weight-400 mb-2"> FATURA {{ item.id }} </h5>


                  <el-popover v-if="item.iss_retain" trigger="hover" placement="top" class="p-0">
  <span>
    Valor retido: <b class="numeric">{{ item.iss_value | currency() }}</b>
  </span>
                    <span slot="reference" class="mr-1 badge badge-danger badge-pill"
                          style="font-size: 10px;height: 19px;background: #e76c6c;color: white !important;border-radius: 6px !important;">
                    ISS </span>
                  </el-popover>
                </div>
                <h4 style="margin-top: -0.7rem !important" class="numeric">{{ item.net_value | currency() }}
                </h4>
                <h5 class="h5 new-default-black-font mr-1 font-weight-400 mt-n25"> {{
                    item.created_at | parseDate
                  }} </h5>
              </div>
              <div class="col-6 text-right pl-1 pr-3 p-0 m-0 mt-1">
                <h5 class="mt-n1 d-flex align-items-center justify-content-end">
                  <a @click.prevent="openDanfseOnNewTab(item.replaced_nfse.link_pdf)" v-if="item.replaced_nfse"
                     class="avatar avatar-sm bg-white mr-2"
                     style="width: auto;min-width:115px;opacity: 95%;border: 1px solid grey" href="#">
                    <img class="pointer mr-1" style="height: 22px; width:22px;"
                         src="/img/icons/sorting-arrows-horizontal-blue.png"/>
                    <small style="font-size: 12px;text-transform: none" class="ml-1 text-dark">
                      n° {{ item.replaced_nfse.nfse_number }}
                    </small>
                  </a>
                  <el-popover v-if="item.billing_guidelines" trigger="click" placement="right" class="p-0 mr-1">
                    <span>{{ item.billing_guidelines }}</span>
                    <span slot="reference">
                      <img class="pointer" src="/img/icons/break-v2.png" width="22px">
                    </span>
                  </el-popover>
                  <a href="#">
                    <img v-if="!item.show_comment" class="pointer"
                         @click.prevent="item.show_comment = !item.show_comment"
                         style="height: 22px" src="https://img.icons8.com/ios/50/speech-bubble--v1.png">
                    <img v-else class="pointer" @click.prevent="item.show_comment = !item.show_comment"
                         style="height: 22px" src="https://img.icons8.com/ios/50/1a70b7/speech-bubble--v1.png"/>
                  </a>
                  <span v-if="item.nfse && item.nfse.nfse_external_id && $hasPrivilege(1)">
                    <a href="#" @click.prevent="copyNfseValuesToClipboard(item)">
                      <i style="font-size: 20px !important;" class="ml-2 fa-regular fa-clipboard fa-2x"></i>
                    </a>
                  </span>
                </h5>
              </div>
            </div>
            <div class="row" style="margin-bottom: -10px !important;">
              <div class="col-md-12 mt-n1 mb-2" v-show="item.show_comment">
                <textarea @input="setComment(item)" v-model="item.comments" type="text" maxlength="500" rows="4"
                          class="form-control form-control-sm">
                </textarea>
              </div>
            </div>
            <div class="row mb-0 mt-2">
              <div class="col-4 pr-0 pl-3">
                <div @click.prevent="downloadInvoice(item.id)" class="pointer new-default-button bg-white"
                     style="width: 100%;opacity: 95%;border: 1px solid grey">
                  <img src="/img/icons/icons8/ios/pdf-2_danger.png" style="width: 19px; height: 19px"/>
                  <h6 :title="item.id" class="text-truncate h6 new-default-black-font mt-2 font-weight-400"
                      style="color: #656363 !important;font-size: 12px;margin-left: 4px">
                    n. {{ item.id }}
                  </h6>
                </div>
              </div>
              <div class="col-4 pr-0 px-1 text-center">
                <div v-if="!item.reversed" @click.prevent="nfseButtonClick(item)" class="pointer   new-default-button"
                     style="width: 100%;opacity: 95%;background: white;border: 1px solid grey">
                  <img style="width: 30px;" :src="backgroundNfse(item).icon"/>
                  <small class="ml-1" style="font-size: 12px;" :style="'color: '+ backgroundNfse(item).classText">
                    {{ backgroundNfse(item).text }} </small>
                </div>
                <div v-if="item.nfse && item.reversed"
                     @click.prevent="nfseButtonClick(item)"
                     class="pointer   new-default-button"
                     style="width: 100%;opacity: 95%;background: white;border: 1px solid grey">
                  <img style="width: 30px;" :src="backgroundNfse(item).icon"/>
                  <small class="ml-1" style="font-size: 12px;" :style="'color: '+ backgroundNfse(item).classText">
                    {{ backgroundNfse(item).text }} </small>
                </div>
                <div v-if="item.nfse && item.nfse.status === 'Autorizada' && item.nfse.status_reason"
                     style="position: absolute; top: 0.7rem; left: 0; margin-top: -1.5rem; width: 100%;">
                  <i style="font-size: 1.5rem" class="pointer text-warning fa-solid fa-circle-exclamation"
                     @click.prevent="showNfseStatusReason(item.nfse.status_reason)"></i>
                </div>
              </div>
              <div
                v-show="!item.reversed && ((item.nfse && item.nfse.status !== 'Cancelada') || !item.nfse) && item.method_is_billet"
                class="col-4 pr-3 pl-0 text-right">
                <div @click.prevent="verifyBankBillet(item)" class="pointer   new-default-button"
                     style="width: 100%;opacity: 95%;background: white;border: 1px solid grey">
                  <i style="font-size: 19px" :class="backgroundBillet(item)"></i>
                  <small style="font-size: 12px" class="ml-1 text-dark">
                    <template v-if="item.every_paid">
                      Recebido
                    </template>
                    <template v-else-if="item.every_canceled">
                      Cancelado
                    </template>
                    <template v-else-if="item.is_pending && item.invoice_attachments.length == 0"
                              @click.prevent="handleOpenModalPaymentTerms(item)">
                      Emitir
                    </template>
                    <template v-else-if="item.is_pending && item.invoice_attachments.length > 0"
                              @click.prevent="handleShowInvoiceAttachment(item)">
                      Anexo
                    </template>
                    <template v-else class="text-warning">
                      Boleto
                    </template>
                  </small>
                </div>
              </div>
            </div>
            <div class="row mx-0 mt-2 mb-n3">
              <div class="text-left col-6 pl-0 mb-n1">
                <h5 v-show="item.paid > 0" class="h5 new-default-black-font mt-0 font-weight-400"
                    style="color: #656363 !important">
                  {{ item.paid | currency() }}
                </h5>
              </div>
              <div class="text-right col-6 pr-0 mb-n1">
                <h5 v-show="(item.net_value - item.paid) > 0" class="h5 new-default-black-font mt-0 font-weight-400"
                    style="color: #656363 !important">
                  {{ (item.net_value - item.paid) | currency() }} EM ABERTO
                </h5>
              </div>
              <template v-if="!item.reversed">
                <div style="width: 33.33%;" :style="calcPaidValue(item)">
                  <hr class="green-divider"
                      style="border-top-right-radius: 0px; border-bottom-right-radius: 0px;">
                </div>
                <div style="width: 66.67%;" :style="calcOpenedValue(item)">
                  <hr class="grey-divider"
                      style="border-top-left-radius: 0px; border-bottom-left-radius: 0px;">
                </div>
              </template>
              <template v-else>
                <div style="width: 100%;">
                  <hr class="danger-divider"
                      style="border-top-left-radius: 0px; border-bottom-left-radius: 0px;">
                </div>
              </template>
            </div>
            <div class="row align-items-center mt-2"
                 style="margin-bottom: -24px; margin-right: -24px;">
              <div @click.prevent="getBillReceives(item.id)"
                   v-if="item.has_bill_receive && !(item.paid < item.net_value) && !item.reversed"
                   class="offset-6 col-md-6 text-center" style="text-align: right !important; padding: 0px;">
                <div class="card-blue-bottom text-right card-bottom"><a href="#" style="height: 35px; display: block;">
                  <h3 style="text-transform: none; padding: 3px 10px 3px 3px;"><span class="white-image mr-3"><img
                    src="/img/icons/finance-white.png" width="18px" height="17px"></span>
                    <small class="mr-1 text-white" style="font-size: 11px;"> CAR </small>
                  </h3>
                </a>
                </div>
              </div>
              <div @click.prevent="getBillReceives(item.id)"
                   v-if="item.has_bill_receive && item.open_payment > 0 && !item.reversed "
                   class="offset-6 col-md-6 text-right" style="text-align: right !important; padding: 0px;">
                <div class="card-blue-bottom text-center bg-warning card-bottom"><a href="#"
                                                                                    style="height: 35px; display: block;">
                  <h3 style="text-transform: none; padding: 3px 10px 3px 3px;"><span class="white-image mr-3"><img
                    src="/img/icons/finance-white.png" width="18px" height="17px"></span>
                    <small class="mr-1 text-white" style="font-size: 11px;"> CAR </small>
                  </h3>
                </a>
                </div>
              </div>
              <div @click.prevent="getBillReceives(item.id)" v-if="item.reversed "
                   class="offset-6 col-md-6 text-right" style="text-align: right !important; padding: 0px;">
                <div style="min-width: 190px !important" class="card-blue-bottom text-center bg-danger card-bottom"><a
                  href="#"
                  style="height: 35px; display: block;">
                  <h3 style="text-transform: none; padding: 3px 10px 3px 3px;"><span class="white-image mr-3"><img
                    src="/img/icons/finance-white.png" width="18px" height="17px"></span>
                    <small class="mr-1 text-white" style="font-size: 11px;"> ESTORNADO </small>
                  </h3>
                </a>
                </div>
              </div>
              <div @click.prevent="getBillReceives(item.id)" v-else-if="!item.has_bill_receive"
                   class="offset-6 col-md-6 text-right" style="text-align: right !important; padding: 0px;">
                <div class="card-blue-bottom text-center bg-grey card-bottom"><a href="#"
                                                                                 style="height: 35px; display: block;">
                  <h3 style="text-transform: none; padding: 3px 10px 3px 3px;"><span class="white-image mr-3"><img
                    src="/img/icons/finance-white.png" width="18px" height="17px"></span>
                    <small class="mr-1 text-white" style="font-size: 11px;"> SEM CAR </small>
                  </h3>
                </a>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
      <div class="row card-wrapper" v-if="params.type === 1 && !loadingSkeleton && invoices.length">
        <div class="col-md-12">
          <card class="table-responsive">
            <table class="table table-sm table-bordered">
              <thead>
              <tr>
                <th style="width: 0px" class="text-center">Emissão</th>
                <th style="width: 0px" class="text-center">Competência</th>
                <th style="width: 0px" class="text-center">Fatura</th>
                <th style="width: 0px" class="text-center">NFS-e</th>
                <th style="width: 0px" class="text-center">BOLETO</th>
                <th style="width: 30px !important;" class="text-truncate">CLIENTE</th>
                <th style="width: 0px">VALOR LÍQUIDO</th>
                <th style="width: 0px">VALOR PENDENTE</th>
                <th style="width: 0px" class="text-center">ÍCONES</th>
                <th style="width: 0px">AÇÕES</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in invoices">
                <td class="text-center">{{ item.created_at | parseDate('DD/MM/YYYY') }}</td>
                <td class="text-center">{{ item.competence_at | parseDate('DD/MM/YYYY') }}</td>
                <td class="text-center">
                    <span style="font-size: 14px !important" @click.prevent="downloadInvoice(item.id)" class="pointer">
                      {{ item.id }}
                    </span>
                </td>
                <td class="text-center">
                  <template v-if="!item.reversed">
                    <div @click.prevent="nfseButtonClick(item)" class="pointer  "
                         style="width: 100% !important;opacity: 95%;background: white">
                      <span v-if="item.nfse" style="font-size: 14px;text-transform: none" class="text-truncate"
                            :title="backgroundNfse(item).text">
                        {{ backgroundNfse(item).text }}
                      </span>
                      <span v-else style="font-size: 12px;text-transform: none" class="text-dark font-weight-400"
                            :title="backgroundNfse(item).text">
                        EMITIR
                      </span>
                    </div>
                    <div v-if="item.nfse && item.nfse.status === 'Autorizada' && item.nfse.status_reason"
                         style="position: absolute; top: 0.7rem; left: 0; margin-top: -1.5rem; width: 100%;">
                      <i style="font-size: 1.5rem" class="pointer text-warning fa-solid fa-circle-exclamation"
                         @click.prevent="showNfseStatusReason(item.nfse.status_reason)"></i>
                    </div>
                  </template>
                </td>
                <td class="text-center">
                  <div
                    v-if="!item.reversed && ((item.nfse && item.nfse.status !== 'Cancelada') || !item.nfse) && item.method_is_billet"
                    class="">
                    <div @click.prevent="verifyBankBillet(item)" class="pointer avatar avatar-sm-4"
                         style="width: 100% !important;opacity: 95%;background: white;border: 1px solid grey">
                      <i style="font-size: 15px" :class="backgroundBillet(item)"></i>
                      <small style="font-size: 12px" class="ml-1 text-dark">
                        <template v-if="item.every_paid">
                          Recebido
                        </template>
                        <template v-else-if="item.every_canceled">
                          Cancelado
                        </template>
                        <template v-else-if="item.is_pending && item.invoice_attachments.length == 0"
                                  @click.prevent="handleOpenModalPaymentTerms(item)">
                          Emitir
                        </template>
                        <template v-else-if="item.is_pending && item.invoice_attachments.length > 0"
                                  @click.prevent="handleShowInvoiceAttachment(item)">
                          Anexo
                        </template>
                        <template v-else class="text-warning">
                          Boleto
                        </template>
                      </small>
                    </div>
                  </div>
                  <div v-else>
                    -
                  </div>
                </td>
                <td class="text-truncate">
                    <span style="font-weight: normal; width: 15px">
                      <badge style="font-size: 10px" type="primary" class="font-weight-500 bg-primary text-white">{{
                          item.contract_proposal.code
                        }}</badge>
                      <span :title="item.entity.entity_name"> {{
                          firstTwoEntityNameWords(item.entity.entity_name)
                        }} </span>
                    </span>
                </td>
                <td class="numeric">{{ item.net_value | currency() }}
                  <el-popover v-if="item.iss_retain" trigger="hover" placement="top" class="p-0">
                      <span>
                        Valor retido: <b class="numeric">{{ item.iss_value | currency() }}</b>
                      </span>
                    <span slot="reference">
                        <badge style="background: #DB4539;opacity: 80%" type=" text-white">ISS RETIDO</badge>
                      </span>
                  </el-popover>
                </td>
                <td class="numeric">{{ (item.net_value - item.paid) | currency() }}
                  <badge v-if="!item.has_bill_receive" type="secondary" :class="item.iss_retain && 'ml-1'"
                         style="background: #606062;opacity: 80%" class="text-white">
                    SEM CAR
                  </badge>
                </td>
                <td>
                  <a @click.prevent="$refs.modalInfo.handleCreateModal(item)" href="#">
                    <img src="/img/icons/info.png" width="19px">
                  </a>
                  <el-popover trigger="click" placement="right" class="px-1">
                      <span>
                        <span class="text-center">{{ item.seller }}</span>
                      </span>
                    <span slot="reference">
                        <img class="pointer" src="/img/icons/salesman.png" width="19px">
                      <!--                                                <i style="cursor: pointer"-->
                      <!--                                                   class="fa-solid fa-circle-user text-success"/>-->
                      </span>
                  </el-popover>
                  <el-popover trigger="click" placement="right" class="px-1">
                      <span>
                        <span class="text-center">{{ item.company_plant.name }}</span>
                      </span>
                    <span slot="reference"><img src="/img/icons/icons8/ios/chemical-plant-v1.png" width="19px"/>
                      </span>
                  </el-popover>
                  <template v-if="new Date(item.created_at).toDateString() === new Date().toDateString() &&
                      item.auto_mail !== 2">
                      <span class="pointer" @click.prevent="setSendToMail(item, 1)" v-if="!item.auto_mail">
                        <img src="/img/icons/icons8/ios/mail-muted.png" width="19"/>
                      </span>
                    <span class="pointer" @click.prevent="setSendToMail(item, 0)" v-if="item.auto_mail === 1">
                        <img src="/img/icons/icons8/ios/mail-primary.png" width="19"/>
                      </span>
                  </template>
                  <template v-else>
                      <span class="pointer" @click.prevent="handleShowMailHistory(item.id)" v-if="item.auto_mail === 2">
                        <img src="/img/icons/icons8/ios/mail-success.png" width="19"/>
                      </span>
                    <span class="pointer" @click.prevent="handleShowMailHistory(item.id)" v-if="item.auto_mail === 3">
                        <img src="/img/icons/icons8/ios/mail-danger.png" width="19"/>
                      </span>
                    <span class="pointer" @click.prevent="handleShowMailHistory(item.id)" v-if="item.auto_mail === 4">
                        <img src="/img/icons/icons8/ios/mail-info.png" width="19"/>
                      </span>
                  </template>
                </td>
                <td>
                  <base-dropdown menuOnRight>
                    <div slot="title-container" class="dropdown-toggle rounded m-0">
                      <img width="25" src="/img/icons/icons8/ios/settings--v1_primary.png">
                    </div>
                    <a class="dropdown-item" @click.prevent="handleOpenModalPaymentTerms(item)">
                      <i class="fa-solid fa-dollar-sign text-danger"></i> Recebimentos
                    </a>
                    <div v-if="item.method_is_billet && item.is_pending && $_setting.allow_bank_billet_as_attachment">
                      <a class="dropdown-item" @click="handleShowInvoiceAttachment(item)">
                        <i class="text-center fa-solid fa-paperclip text-success"></i>Anexar Boleto
                      </a>
                    </div>
                    <div v-if="((!item.nfse) || (item.nfse && item.nfse.status === 'Negada'))">
                      <a class="dropdown-item" @click="handleOpenNfseModal(item)">
                        <i class="text-center fa-solid fa-file text-primary"></i>&nbsp;Emitir nota fiscal
                      </a>
                      <a class="dropdown-item" @click="handleOpenNfseAuthorizedImportModal(item)">
                        <i class="text-center fa-solid fa-file-arrow-up text-primary"></i>&nbsp;Importar nota fiscal
                      </a>
                      <a class="dropdown-item" @click="handleOpenEmail(item)">
                        <i class="text-center fa-solid fa-envelope"></i>Envio por e-mail
                      </a>
                      <a class="dropdown-item" @click="removeInvoice(item.id)">
                        <i class="text-center fas fa-times text-danger"></i>&nbsp;&nbsp;Estornar
                      </a>
                    </div>
                    <div
                      v-if="(item.nfse) && ((item.nfse.status === 'Autorizada') || (item.nfse.status === 'Cancelada'))">
                      <a class="dropdown-item" @click="downloadNfseXml(item)">
                        <i class="fa-solid fa-file-code text-primary main-icon-menu mr-3"></i>
                        <span style="margin-left: -0.4rem">
                            XML
                          </span>
                      </a>
                      <a class="dropdown-item" @click="handleOpenNfseSent(item)">
                        <i class="text-center fa-solid fa-file text-primary"></i>Visualizar
                      </a>
                      <a class="dropdown-item" @click="handleOpenEmail(item)">
                        <i class="text-center fa-solid fa-envelope mr-3"></i>Enviar ao e-mail
                      </a>
                      <a class="dropdown-item" @click="handleChangeNfseModal(item)">
                        <i class="fas fa-exchange-alt text-primary"></i>
                        Substituir NFS-e
                      </a>
                    </div>
                    <div v-if="(item.nfse) && (item.nfse.status === 'Autorizada')">
                      <a class="dropdown-item" @click="handleCancelNfse(item, true)">
                        <i class="text-center text-danger fa-solid fa-ban"></i>Cancelar NFS-e
                      </a>
                      <a v-show="!item.has_any_dont_pending && item.has_bill_receive" class="dropdown-item"
                         @click="handleCancelNfse(item, false, true)">
                        <i class="text-center fa-solid fa-trash"></i>Estornar e Cancelar NFS-e
                      </a>
                    </div>
                  </base-dropdown>
                </td>
              </tr>
              </tbody>
            </table>
          </card>
        </div>
      </div>
      <div class="row card-wrapper" v-if="params.type === 1 && loadingSkeleton">
        <div class="col-md-12">
          <card class="table-responsive">
            <table class="table table-sm table-bordered">
              <thead>
              <tr>
                <th style="width: 0px" class="text-center">Emissão</th>
                <th style="width: 0px" class="text-center">Competência</th>
                <th style="width: 0px" class="text-center">Fatura</th>
                <th style="width: 0px" class="text-center">NFS-e</th>
                <th style="width: 0px" class="text-center">BOLETO</th>
                <th style="width: 30px !important;" class="text-truncate">CLIENTE</th>
                <th style="width: 0px">VALOR LÍQUIDO</th>
                <th style="width: 0px">VALOR PENDENTE</th>
                <th style="width: 0px" class="text-center">ÍCONES</th>
                <th style="width: 0px">AÇÕES</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in invoices">
                <td colspan="10">
                  <skeleton-puzl type="button"/>
                </td>
              </tr>
              </tbody>
            </table>
          </card>
        </div>
      </div>
      <div class="row card-wrapper" v-if="params.type === 0 && loadingSkeleton">
        <SkeletonPuzlGrid v-for="(index) in 3" v-show="true" :key="index"></SkeletonPuzlGrid>
      </div>
      <puzl-empty-data v-if="!loadingSkeleton && !invoices.length"/>
      <loading-pagination :show="loading && !loadingSkeleton"/>
    </div>
    <ModalNfseAuthorizedImport ref="ModalNfseAuthorizedImport"/>
    <ModalNfseCanceledImport ref="ModalNfseCanceledImport" @wasCanceled="removeInvoiceFromList"/>
    <ModalNfseCreate ref="ModalNfseCreate"/>
    <ModalNfseSent ref="ModalNfseSent"/>
    <ModalSendToEmail ref="ModalSendEmail"/>
    <modal-payment-terms @fetch="handleUpdateAfterSendBankBillet" ref="modalPaymentTerms" :show="showModalPaymentTerms"
                         @close="showModalPaymentTerms = false"/>
    <modal-show-mail-history :show="showModalMailHistory" @close="showModalMailHistory = false"/>
    <modal-info ref="modalInfo"/>
    <ModalChangeNfse ref="modalChangeNfse"/>
    <ModalInvoiceAttachmentList ref="modalInvoiceAttachmentList"/>
    <modal-invoice-history ref="modalInvoiceHistory"/>
    <slide-fade-modal size="md" ref="slide-fade-modal" title="CONTAS A RECEBER" platform="default"
                      icon="get-cash-white">
      <div class="row px-3" v-if="invoice_selected">
        <div class="col text-left mt-2 p-1">
          <h5 class="h4 new-default-black-font mt-0 font-weight-500">
            {{ invoice_selected.contract_proposal.code }}
          </h5>
          <h5 class="h4 new-default-black-font mt-n25 font-weight-400" style="color: #656363 !important">
            {{
              invoice_selected.contract_proposal.construction.construction_name
            }}
          </h5>
          <h5 class="h4 new-default-black-font mt-n25 font-weight-400" style="color: #656363 !important">
            {{
              invoice_selected.contract_proposal && invoice_selected.contract_proposal.construction
              && invoice_selected.contract_proposal.construction.customer_construction.customer_name
            }}
          </h5>
          <h5 class="h4 new-default-black-font mt-n2 font-weight-500" style="color: #656363 !important">
            {{
              invoice_selected.payment_method.name
            }}
          </h5>
          <h5 class="h4 new-default-black-font mt-n25 font-weight-400" style="color: #656363 !important">
            {{
              invoice_selected.payment_term.name
            }}
          </h5>
        </div>
        <div class="col-4 text-right mt-3 p-0">
          <router-link :to="{
                name: 'commercial.contract-proposal.invoices.view',
                params: {
                  contract_proposal_uuid: invoice_selected.contract_proposal.uuid,
                  construction_name: invoice_selected.contract_proposal.construction.construction_name,
                  customer_name: invoice_selected.contract_proposal.construction.customer_construction.customer_name
                }
              }">
            <base-button type="light" size="sm"
                         style="background: white !important;border: 1px solid black;color: black;font-size: 10px;border-radius: 4px !important;">
              <img src="/img/icons/icons8/ios/external-link-squared.png" width="19" height="19"/>
              ACESSAR CONTRATO
            </base-button>
          </router-link>
        </div>
        <div class="col-md-12 px-1 mb-2"
             v-if="((!invoice_selected.nfse) || (invoice_selected.nfse && (invoice_selected.nfse.status === 'Cancelada' || invoice_selected.nfse.status === 'Negada'))) && !invoice_selected.has_any_dont_pending && !invoice_selected.all_pending && !invoice_selected.reversed">
          <base-button block type="danger" @click.prevent="reverseReceivedes()" outline>
            <i class="fa-solid fa-rotate-left"></i> ESTORNAR FATURA
          </base-button>
        </div>
        <div class="col-md-12 px-1 mb-2"
             v-if="(invoice_selected.nfse) && (invoice_selected.nfse.status === 'Autorizada')">
          <base-button type="danger" block @click="handleCancelNfse(invoice_selected, false, false)" outline>
            <img width="19px" height="19px" class="mr-2" src="/img/icons/icons8/ios/delete--v1_danger.png"> Cancelar
            NFS-e
          </base-button>
        </div>
        <div class="col-md-12 px-1 mb-2"
             v-if="!invoice_selected.has_any_dont_pending && (invoice_selected.nfse) && (invoice_selected.nfse.status === 'Autorizada')">
          <base-button type="danger" block @click="handleCancelNfse(invoice_selected, false, true)" outline>
            <img width="19px" height="19px" class="mr-2" src="/img/icons/icons8/ios/delete--v1_danger.png"> Estornar e
            Cancelar NFS-e
          </base-button>
        </div>
        <div class="col-md-12 px-1 mb-2"
             v-if="!invoice_selected.has_bill_receive && !invoice_selected.reversed && invoice_selected.net_value > 0">
          <base-button type="success" block @click.prevent="regenerateBillReceive" outline>
            <i class="fa-solid fa-newspaper"></i> GERAR NOVAMENTE AS PARCELAS EM CONTAS A RECEBER
          </base-button>
        </div>
      </div>
      <div class="row" style="overflow: auto;height: calc(100vh - 165px);">
        <div class="col-md-12">
          <div class="container px-0">
            <h1 class="mt-4 text-center" v-if="!installments.length" style="color: #d2d0d0;">NENHUM CARD VINCULADO
            </h1>
            <div style="border-radius: 15px !important;" class="px-4 py-3 mb-1 card-with-box-shadow" v-for="(installment,
              indexInstallment) in installments" :key="indexInstallment">
              <div class="row">
                <div class="col-6">
                  <div class="row">
                    <div class="col-12">
                      <div class="no-break-md">
                        <h4 style="display: inline-flex;" class="new-default-black-font">Parcela {{ installment.index }}
                        </h4>
                        <!--                        CANCELADO-->
                        <div style="display: inline;" v-if="installment.status !== 8 && installment.status === 1">
                          <base-dropdown v-if="!checkIfDisable(installment, 1)">
                            <div class="ml-sm-3 mb-2 mb-md-0" slot="title-container"
                                 style="display: inline-flex;align-items: center;"
                                 :class="getColor(installment.status)">
                              <h4 class="font-weight-normal m-0 p-0 mr-2">
                                {{ getTitle(installment.status) }}
                              </h4>
                              <img :src="getDropdownColor(installment.status)" width="12" alt="">
                            </div>
                            <div>
                              <a @click.prevent="changeInstallmentStatus(installment, 10, index, indexInstallment)"
                                 class="dropdown-item">
                                Estornar
                              </a>
                            </div>
                          </base-dropdown>
                          <!--  SE NÃO TIVER PERMISSÃO, MOSTRA SOMENTE TEXTO COM SOMBRA -->
                          <div v-else class="ml-sm-3 mb-2 mb-md-0" style="display: inline-flex;align-items: center;"
                               :class="getColor(installment.status)">
                            <h4 class="font-weight-normal m-0 p-0 mr-2">
                              {{ getTitle(installment.status) }}
                            </h4>
                          </div>
                        </div>
                        <div style="display: inline;" v-if="installment.status === 3">
                          <div class="ml-sm-3 mb-2 mb-md-0" style="display: inline-flex;align-items: center;"
                               :class="getColor(installment.status)">
                            <h4 class="font-weight-normal m-0 p-0 mr-2">
                              {{ getTitle(installment.status) }}
                            </h4>
                          </div>
                        </div>
                        <!--  CASO NÃO ESTEJA RECEBIDO E NEM APROVADO, ENVIA O TYPE 2 PARA VERIFICAR PERMISSÃO cancel e receive -->
                        <div style="display: inline;"
                             v-if="installment.status !== 8 && installment.status !== 1 && installment.status !== 3">
                          <base-dropdown v-if="!checkIfDisable(installment, 2)">
                            <div slot="title-container" class="ml-sm-3 mb-2 mb-md-0"
                                 style="display: inline-flex;align-items: center;"
                                 :class="getColor(installment.status)">
                              <h4 class="font-weight-normal m-0 p-0 mr-2">
                                {{ getTitle(installment.status) }}
                              </h4>
                              <img :src="getDropdownColor(installment.status)" width="12">
                            </div>
                            <div>
                              <a @click.prevent="changeInstallmentStatus(installment, 1, indexInstallment)"
                                 v-if="installment.status !== 3 && installment.status !== 1 && $hasPermission('bill_receive.receive_installment')"
                                 class="dropdown-item">
                                Receber
                              </a>
                              <a @click.prevent="
                                changeInstallmentStatus(installment, 0, indexInstallment)"
                                 v-if="installment.status !== 3 && installment.status !== 0 && installment.status !== 2"
                                 class="dropdown-item">
                                Pendente
                              </a>
                              <a @click.prevent="changeInstallmentStatus(installment, 3, indexInstallment)"
                                 v-if="installment.status !== 3 && $hasPermission('bill_receive.cancel_installment')"
                                 class="dropdown-item">
                                Cancelar
                              </a>
                            </div>
                          </base-dropdown>
                          <!--  SE NÃO TIVER PERMISSÃO, MOSTRA SOMENTE TEXTO COM SOMBRA -->
                          <div v-else class="ml-sm-3 mb-2 mb-md-0" style="display: inline-flex;align-items: center;"
                               :class="getColor(installment.status)">
                            <h4 class="font-weight-normal m-0 p-0 mr-2">
                              {{ getTitle(installment.status) }}
                            </h4>
                          </div>
                        </div>
                        <!-- CASO ESTEJA APROVADO -->
                        <div style="display: inline;" v-if="installment.status === 8">
                          <!--  VERIFICA SOMENTE PERMISSÃO cancel e receive -->
                          <base-dropdown
                            v-if="loadingInstallmentStatus || ($hasPermission('bill_receive.cancel_installment') && $hasPermission('bill_receive.receive_installment'))">
                            <div class="ml-sm-3 mb-2 mb-md-0" slot="title-container"
                                 style="display: inline-flex;align-items: center;"
                                 :class="getColor(installment.status)">
                              <h4 class="font-weight-normal m-0 p-0 mr-2">
                                {{ getTitle(installment.status) }}
                              </h4>
                              <img :src="getDropdownColor(installment.status)" width="12">
                            </div>
                            <div>
                              <a @click.prevent="changeInstallmentStatus(installment, 1, indexInstallment)"
                                 v-if="installment.status !== 1 && $hasPermission('bill_receive.receive_installment')"
                                 class="dropdown-item">
                                Receber
                              </a>
                              <a @click.prevent="changeInstallmentStatus(installment, 3, indexInstallment)"
                                 v-if="installment.status !== 3 && $hasPermission('bill_receive.cancel_installment')"
                                 class="dropdown-item">
                                Cancelar
                              </a>
                            </div>
                          </base-dropdown>
                          <!--  SE NÃO TIVER PERMISSÃO, MOSTRA SOMENTE TEXTO COM SOMBRA -->
                          <div v-else class="ml-sm-3 mb-2 mb-md-0" style="display: inline-flex;align-items: center;"
                               :class="getColor(installment.status)">
                            <h4 class="font-weight-normal m-0 p-0 mr-2">
                              {{ getTitle(installment.status) }}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <img class="mr-1 pointer" src="/img/icons/order-history.png"
                           @click="handleShowHistory({ installment_id: installment.id, type: 1 })" width="25" alt="">
                      <img class="mr-1 pointer"
                           v-show="[0, 2].includes(installment.status) && $hasPermission('additional_permissions.financial_approvation')"
                           @click="showModalEntityPayment(installment.payment_uuid, installment.id, installment.contract_proposal.contract_proposal_uuid)"
                           src="/img/icons/icons8/ios/refund-2_primary.png" width="25" alt="">
                      <img class="mr-1 pointer"
                           @click="showModalShareInstallment(installment.payment_uuid, installment.id, installment.contract_proposal.contract_proposal_uuid)"
                           v-show="[0, 2].includes(installment.status) && $hasPermission('additional_permissions.financial_approvation')
                          && !((installment.contract_proposal.any_has_billet && !installment.contract_proposal.any_has_pending_billet) && (installment.contract_proposal.installment_statistics.pending_value > 0 || installment.contract_proposal.installment_statistics.late_value > 0))"
                           src="/img/icons/divide.png" width="25" alt="">
                      <!-- Dois tipos de botão para alterar o vencimento -->
                      <img class="mr-1 pointer" src="/img/icons/icons8/ios/overtime_warning.png"
                           @click="$refs.ModalChangeDueDate.handleCreateModal(installment.id, installment.original_due_date, { payment: null, installment: indexInstallment })"
                           v-if="$hasPermission('bill_receive.change_due_date') && ![1, 3].includes(installment.status) && !installment.reconciled && !installment.identified && !installment.billet && validFinancialLimit()"
                           width="25" alt="">
                      <img class="mr-1 pointer" src="/img/icons/icons8/ios/overtime_warning.png"
                           @click.prevent="showModalEditGenerateBilletHasGenerated(installment.contract_proposal.bill_receive_id, installments.lenght, 1, installment.index)"
                           v-if="$hasPermission('bill_receive.change_due_date') && ![1, 3].includes(installment.status) && !installment.reconciled && !installment.identified && installment.billet && [1, 4].includes(installment.billet.status) && validFinancialLimit()"
                           width="25" alt="">
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row" style="float: right;display: block;">
                    <div class="col-12">
                      <div style="display: inline-block;">
                        <h5 style="display: inline;font-size: 11px"
                            class="mr-1 my-0 font-weight-normal new-default-gray-font">
                          Valor Total
                        </h5>
                        <el-popover v-if="installment.total_value != installment.value" trigger="click"
                                    placement="left">
                          <div class="row" style="max-width: 250px;">
                            <div class="col-12 mb-2" style="display: flex; align-items: center;">
                              <img src="/img/icons/icons8/ios/info-squared.png" class="mr-2" width="20"> <b
                              class="new-default-black-font">Info Parcela {{ installment.index }}</b>
                            </div>
                            <div class="col-6">
                              <span class="h5 font-weight-normal">
                                Valor total:
                              </span>
                            </div>
                            <div class="col-6">
                              <span class="h5 font-weight-normal">
                                {{ installment.value | currency() }}
                              </span>
                            </div>
                            <div class="col-6">
                              <span class="h5 font-weight-normal">
                                Descontos:
                              </span>
                            </div>
                            <div class="col-6">
                              <span v-if="installment.discounts === 'N/A' || !installment.discounts"
                                    class="h5 font-weight-normal">
                                N/A
                              </span>
                              <span v-else class="h5 font-weight-normal text-danger">
                                {{ parseFloat(installment.discounts) | currency() }}
                              </span>
                            </div>
                            <div class="col-6">
                              <span class="h5 font-weight-normal">
                                Taxas:
                              </span>
                            </div>
                            <div class="col-6 ">
                              <span v-if="installment.discounts_fees === 'N/A' || !installment.discounts_fees"
                                    class="h5 font-weight-normal">
                                N/A
                              </span>
                              <span v-else class="h5 font-weight-normal">
                                {{ parseFloat(installment.discounts_fees) | currency() }}
                              </span>
                            </div>
                            <div class="col-6">
                              <span class="h5 font-weight-normal">
                                Juros/multa:
                              </span>
                            </div>
                            <div class="col-6">
                              <span v-if="installment.fees_fines === 'N/A' || !installment.fees_fines"
                                    class="h5 font-weight-normal">
                                N/A
                              </span>
                              <span v-else class="h5 font-weight-normal">
                                {{ parseFloat(installment.fees_fines) | currency() }}
                              </span>
                            </div>
                            <div class="col-12">
                              <hr class="mb-2 mt-2">
                            </div>
                            <div class="col-6">
                              <span class="h5 font-weight-normal">
                                Valor final:
                              </span>
                            </div>
                            <div class="col-6 ">
                              <span class="h5 font-weight-normal">
                                <b>{{ installment.total_value | currency() }}</b>
                              </span>
                            </div>
                          </div>
                          <img v-if="installment.fees_fines > 0 || installment.discounts_fees > 0" slot="reference"
                               style="display: inline;" src="/img/icons/discount_success.png" class="pointer" width="20"
                               alt="">
                          <img v-else slot="reference" style="display: inline;" src="/img/icons/discount--v1_danger.png"
                               class="pointer" width="20" alt="">
                        </el-popover>
                      </div>
                    </div>
                    <div class="col-12">
                      <h2 class="my-0 mt-n1" style="font-size: 18px;">{{ installment.value | currency() }}</h2>
                    </div>
                    <div class="col-12">
                      <h5 style="font-size: 11px" class="my-0 mt-n1 font-weight-normal new-default-gray-font">{{
                          installment.due_date | parseDate('DD MMM YYYY')
                        }}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </slide-fade-modal>
    <EditPayment @showInvoices="updateInstallment" ref="editPayment"/>
    <ModalHistory ref="ModalHistory"/>
    <modal-change-due-date @updated="updateInstallment" ref="ModalChangeDueDate"/>
    <modal-confirm-payment @updateInstallment="updateInstallment" ref="modalConfirmPayment"/>
    <ModalAlterStatus ref="ModalAlterStatus" :show="showModalAlterStatus" @close="closeModalAlterStatus"
                      @saveAndClose="saveAndCloseAlterInstallment"/>
    <ModalWhatsapp ref="ModalWhatsapp" @downloadInvoiceWhatsappModal="downloadInvoice"
                   @nfseButtonClickWhatsappModal="nfseButtonClick"/>
    <ModalInvoiceIntegrationInfo ref="modalInvoiceIntegrationInfo"/>
    <ModalEditSeller ref="modalEditSeller" @updatedSeller="updatedSeller"/>
    <ModalEditIntermediary ref="modalEditIntermediary" @updatedUserIntermediary="updatedUserIntermediary"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import MultiFilter from "@/components/Utils/MultiFilterV3";
import InputDatePicker from '@/components/InputDatePicker';
import ModalNfseAuthorizedImport from "./_ModalNfseAuthorizedImport";
import ModalChangeNfse from "./_ModalChangeNfse";
import ModalNfseCanceledImport from "./_ModalNfseCanceledImport";
import ModalNfseCreate from "./_ModalNfseCreate";
import ModalNfseSent from "./_ModalNfseSent";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import ModalSendToEmail from "@/views/Modules/Financial/Billing/Shared/_ModalSendToEmail";
import ModalPaymentTerms from "./_ModalPaymentTerms";
import PuzlEmptyData from "../../../../../components/PuzlEmptyData";
import ModalShowMailHistory from './_ModalShowMailHistory'
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import ModalInvoiceHistory from "./_ModalInvoiceHistory";
import BaseDropdownV2 from '@/components/Utils/BaseDropdown.vue'
import SlideFadeModal from "@/components/SlideFadeModal.vue";
import {
  debounce,
} from "@/plugins";
import {base_url_ms} from '@/plugins'
import {background} from "quill/ui/icons";
import ModalInfo from './_ModalInfo.vue'
import {VMoney} from "v-money";
import moment from 'moment'
import ModalInvoiceAttachmentList from "./_ModalInvoiceAttachmentList";
import SkeletonPuzl from "../../../../../components/SkeletonPuzl.vue";
import BaseDropdownItem from "@/components/Utils/BaseDropdownItem.vue";
import ModalConfirmPayment from '../../../Financial/BillReceive/Shared/Modals/_ModalConfirmPayment.vue'
import ModalAlterStatus from '../../../Financial/BillReceive/Shared/Modals/_ModalAlterStatus'
import ModalHistory from "../../../Financial/BillReceive/Shared/Modals/_ModalHistory";
import EditPayment from "../../../Commercial/ContractProposal/Payments/Shared/_Edit.vue";
import ModalChangeDueDate from '../../../Financial/BillReceive/Shared/Modals/_ModalChangeDueDate.vue';
import ModalWhatsapp from "./_ModalWhatsapp";
import {hasTotvs} from "@/plugins/microservices/totvs";
import ModalInvoiceIntegrationInfo from "@/views/Modules/Totvs/Invoice/_ModalInvoiceIntegrationInfo";
import ModalEditSeller from "./_ModalEditSeller";
import ModalEditIntermediary from "./_ModalEditIntermediary";
import { TotvsStatusEnum } from "@/enum/TotvsStatusEnum";

const filters = {
  range_nfse_number: {
    min: null,
    max: null
  },
  seller: null,
  range_invoice_value: {
    min: null,
    max: null
  },
  range_invoice_number: {
    min: null,
    max: null
  },
  order_by: null,
  without_car: 0,
  nfse_pending_issue: 0,
  nfse_denied: 0,
  nfse_issuing: 0,
  email_dont_sended: 0,
  with_iss: 0,
}

export default {
  name: "List",
  mixins: [cursorPaginate],
  components: {
    BaseDropdownItem,
    SlideFadeModal,
    BaseDropdownV2,
    SkeletonPuzl,
    PuzlEmptyData,
    EditPayment,
    ModalPaymentTerms,
    ModalSendToEmail,
    LoadingPagination,
    MultiFilter,
    ModalNfseAuthorizedImport,
    ModalNfseCanceledImport,
    ModalNfseCreate,
    ModalNfseSent,
    SkeletonPuzlGrid,
    InputDatePicker,
    ModalShowMailHistory,
    ModalInfo,
    ModalHistory,
    ModalChangeNfse,
    BaseButtonHoverable,
    ModalInvoiceAttachmentList,
    ModalInvoiceHistory,
    ModalConfirmPayment,
    ModalAlterStatus,
    ModalChangeDueDate,
    ModalWhatsapp,
    ModalInvoiceIntegrationInfo,
    ModalEditSeller,
    ModalEditIntermediary,
  },
  data() {
    return {
      base_url_ms: base_url_ms(),
      hasTotvs: hasTotvs(),
      loadingSkeleton: false,
      invoice_selected: null,
      showModalAlterStatus: false,
      installments: [],
      filter: {},
      loadingInstallmentStatus: false,
      showModalPaymentTerms: false,
      showModalMailHistory: false,
      isMinimized: false,
      reverse: 0,
      params: {
        per_page: 3,
        type: 0,
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      multi_filter: {
        null: {
          name: "Todos",
          type: "primary"
        },
      },
      range: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        end: new Date(),
      },
      TotvsStatusEnum: TotvsStatusEnum,
    }
  },
  directives: {
    money: VMoney
  },
  async mounted() {
    this.filter = {...filters}
    this.filter.canceled = 0
    this.filter.reversed = 0
    this.filter.not_canceled = 1
    this.filter.verify_status = 0
    this.filter.filter_by_default = 1
    this.filter.seller = null
    this.loadingSkeleton = true
    await this.$store.dispatch('generalSetting/show');
    await this.fetchPermission();
    this.init({})
    EventBus.$on("isMinimized", (val) => {
      this.isMinimized = val
    });
  },
  computed: {
    ...mapGetters({
      invoices: "billingInvoice/fetch",
      $_setting: "generalSetting/show",
      $_general_settings: "generalSetting/show",
      $_commercial_permission: "commercialPermission/show",
      $_financial_limit: "permissionFinancialLimit/show",
    }),
  },
  methods: {
    calcPaidValue(item) {
      let percentage = (item.paid / item.net_value) * 100
      return 'width: ' + percentage + '%'
    },
    calcOpenedValue(item) {
      let percentage = ((item.net_value - item.paid) / item.net_value) * 100
      return 'width: ' + percentage + '%'
    },
    closeModalAlterStatus() {
      this.loadingInstallmentStatus = false
      this.showModalAlterStatus = false
    },
    async saveAndCloseAlterInstallment() {
      this.loadingInstallmentStatus = false
      this.showModalAlterStatus = false
      await this.$store.dispatch('billingInvoice/show', this.invoice_selected.id).then(async response => {
        let index = this.invoices.findIndex((item) => item.id === this.invoice_selected.id)
        this.$set(this, 'invoice_selected', response.data)
        this.$set(this.invoices, index, response.data)

        await this.getBillReceives(this.invoice_selected.id)
      })
    },
    async updateInstallment() {
      await this.$store.dispatch('billingInvoice/show', this.invoice_selected.id).then(async response => {
        let index = this.invoices.findIndex((item) => item.id === this.invoice_selected.id)
        this.$set(this, 'invoice_selected', response.data)
        this.$set(this.invoices, index, response.data)
        await this.getBillReceives(this.invoice_selected.id)
      })
    },
    showModalEditGenerateBilletHasGenerated(id, total_index, type, index) {
      let loader = this.$loading.show()
      this.$store.dispatch("billReceive/getInfoBillet", {id: id, type: type}).then(response => {
        const billet = response.data
        this.$store.dispatch("billReceive/show", id).then(response => {
          const bill_receive = response.data
          bill_receive.total_index = total_index
          this.$refs.modalGenerateBilletHasGenerated.openModal(id, billet, bill_receive, index)
          loader.hide()
        });
      });
    },
    getInvoices() {
      this.$forceUpdate()
      this.init(this.filter)
    },
    validFinancialLimit() {
      return ((this.$user.financial_limit && this.$user.financial_limit.disable_max_extension_due_date && this.$user.financial_limit.general_extension_due_date > 0) || !this.$user.financial_limit || !this.$user.financial_limit.disable_max_extension_due_date)
    },
    async showModalShareInstallment(uuid, installment_id, contract_proposal_uuid) {
      await this.$refs.editPayment.handleCreateModal(uuid, contract_proposal_uuid);
      const installment = this.$refs.editPayment.payload.installments.find((item) => item.id === installment_id)
      this.$refs.editPayment.showModalShareInstallment(installment)
    },
    async showModalEntityPayment(uuid, installment_id, contract_proposal_uuid) {
      await this.$refs.editPayment.handleCreateModal(uuid, contract_proposal_uuid);
      const installment = this.$refs.editPayment.payload.installments.find((item) => item.id === installment_id)
      this.$refs.editPayment.showModalEntityPayment(installment)
    },
    handleShowHistory(payment) {
      this.$refs.ModalHistory.openModal(payment)
    },
    checkIfDisable(installment, type) {
      return false
      if (type !== 1) {
        return (
          this.loadingInstallmentStatus
          || !this.$hasPermission('bill_receive.reverse_installment')
          || ['canceled', 'approved', 'denied'].includes(this.PaymentInstallmentStatus[installment.status])
          || installment.reconciled
        );
      } else {
        return (
          this.loadingInstallmentStatus
          || (!this.$hasPermission('bill_receive.receive_installment') && !this.$hasPermission('bill_receive.cancel_installment'))
          || ['canceled', 'approved', 'denied'].includes(this.PaymentInstallmentStatus[installment.status])
          || installment.reconciled
        );
      }
    },
    getColor(status) {
      switch (status) {
        case 0:
          return "mini-card-yellow";
        case 1:
          return "mini-card-green";
        case 2:
        case 9:
        case 3:
          return "mini-card-red";
        case 8:
          return 'mini-card-blue';
      }
    },
    getDropdownColor(status) {
      switch (status) {
        case 0:
          return "/img/icons/expand-arrow--v2_warning.png";
        case 1:
          return "/img/icons/expand-arrow--v2_success.png";
        case 2:
        case 3:
        case 9:
          return "/img/icons/expand-arrow--v2_danger.png";
        case 8:
          return "/img/icons/expand-arrow--v2_primary.png";
      }
    },
    getTitle(status) {
      switch (status) {
        case 0:
          return "Pendente";
        case 1:
          return "Recebido";
        case 2:
          return "Atrasado";
        case 3:
          return "Cancelado";
        case 8:
          return "Aprovado";
        case 9:
          return "Negado";
      }
    },
    getBillReceives(invoice_id) {
      this.invoice_selected = this.invoices.find((item) => item.id === invoice_id)
      let loader = this.$loading.show()
      this.$store.dispatch('contractProposalPayment/getInstallments', invoice_id).then(response => {
        loader.hide()
        this.installments = response.data
        this.$refs["slide-fade-modal"].handleOpenModal()
      })
    },
    /**
     * @param {string} entityName
     * @returns {string}
     */
    firstTwoEntityNameWords(entityName) {
      return entityName.split(" ").slice(0, 2).join(" ");
    },
    regenerateBillReceive() {
      this.$Swal.confirmAction(`Deseja realmente gerar os recebimentos da fatura selecionada?`, ['Sim', 'Não'])
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação."
            });
            let loader = this.$loading.show()
            this.$store.dispatch('billingInvoice/regenerateBillReceive', this.invoice_selected.id)
              .then(async (response) => {
                this.$notify({
                  type: response.error_type,
                  message: response.message
                });
                await this.$store.dispatch('billingInvoice/show', this.invoice_selected.id).then(async response => {
                  let index = this.invoices.findIndex((item) => item.id === this.invoice_selected.id)
                  this.$set(this, 'invoice_selected', response.data)
                  this.$set(this.invoices, index, response.data)
                  await this.getBillReceives(this.invoice_selected.id)
                })
              }).catch((error) => {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              })
            }).finally(() => {
              loader.hide();
            });
          }
        })
    },
    reverseReceivedes(invoice_id = null) {
      if (! this.$_financial_limit.invoice_can_reverse) {
        return this.$swal.fire({
          icon: 'error',
          title: 'Aviso',
          text: 'Usuário não possui permissão para estornar fatura',
        })
      }
      if (invoice_id) {
        this.invoice_selected = this.invoices.find((item) => item.id === invoice_id)
      }
      this.$Swal.confirmAction(`Deseja realmente estornar a fatura selecionada?`, ['Sim', 'Não'])
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação."
            });
            let loader = this.$loading.show()
            this.$store.dispatch('billingInvoice/reverseReceivedes', this.invoice_selected.id)
              .then(async (response) => {
                this.$notify({
                  type: response.error_type,
                  message: response.message
                });
                const invoice_selected = {...this.invoice_selected}
                if (!this.reverse && this.invoice_selected.has_bill_receive) {
                  this.invoice_selected.has_any_dont_pending = true
                }
                await this.$store.dispatch('billingInvoice/show', this.invoice_selected.id).then(async response => {
                  let index = this.invoices.findIndex((item) => item.id === this.invoice_selected.id)
                  this.$set(this, 'invoice_selected', response.data)
                  this.$set(this.invoices, index, response.data)
                  await this.getBillReceives(this.invoice_selected.id)
                })
              }).catch((error) => {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              })
            }).finally(() => {
              loader.hide();
            });
          }
        })
    },
    changeInstallmentStatus(installment, status, indexInstallment) {
      status = parseInt(status)
      // if (installment.billet && installment.billet.status === 1 && status === 1) {
      //   return this.$Swal.confirmAction('O Boleto já foi emitido para esta parcela, se continuar, o boleto será cancelado.', ['Sim', 'Não'])
      //     .then((result) => {
      //       if (result.isConfirmed) {
      //         let loader = this.$loading.show()
      //         return Promise.all([
      //           this.$store.dispatch("billReceive/showLight", this.payments[indexPayment].bill_receive_id),
      //           this.$store.dispatch("companyPlantBankAccount/getByCompanyPlant", {allocated: 1, company_plant_id: this.payments[indexPayment].company_plant_id})
      //         ]).then(() => {
      //           loader.hide()
      //           let disabled_change_bank_account = (
      //             this.payments[indexPayment].installment_statistics.pay_value > 0
      //             || ((this.payments[indexPayment].any_has_billet && !this.payments[indexPayment].any_has_pending_billet)
      //               && (this.payments[indexPayment].installment_statistics.pending_value > 0 || this.payments[indexPayment].installment_statistics.late_value > 0)));
      //           return this.$refs.modalConfirmPayment.handleCreateModal(installment.id, indexPayment, installment.total_value, disabled_change_bank_account, 1)
      //         })
      //       }
      //     }).catch()
      // }
      // if (this.loadingInstallmentStatus) {
      //   return;
      // }
      if (parseInt(status) === 1) {
        let loader = this.$loading.show()
        return Promise.all([
          this.$store.dispatch("billReceive/showLight", installment.bill_receive_id),
          this.$store.dispatch("companyPlantBankAccount/getByCompanyPlant", {
            allocated: 1,
            company_plant_id: installment.company_plant_id
          })
        ]).then(() => {
          let installment_payload = {... installment};
          installment_payload.installment_id = installment_payload.id;

          loader.hide()
          let disabled_change_bank_account = (
            installment.contract_proposal.installment_statistics.pay_value > 0
            || ((installment.contract_proposal.any_has_billet && !installment.contract_proposal.any_has_pending_billet)
              && (installment.contract_proposal.installment_statistics.pending_value > 0 || installment.contract_proposal.installment_statistics.late_value > 0)));

          return this.$refs.modalConfirmPayment.handleCreateModal(installment_payload, disabled_change_bank_account)
        })
      }
      this.loadingInstallmentStatus = true;
      this.$Progress.start();
      if (status != 1) {
        this.showModalAlterStatus = true
        return this.$refs.ModalAlterStatus.setBillReceive({
          id: installment.id,
          type: 1,
          status: status,
          indexPayment: null,
          index: indexInstallment,
          received_at: installment.paid_at ? installment.paid_at : null
        })
      }
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.$store
        .dispatch("billReceive/changeInstallmentStatus", {
          id: installment.id,
          status: status,
          type: 1
        })
        .then(async response => {
          installment.status = status;
          this.loadingInstallmentStatus = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          await this.$store.dispatch('billingInvoice/show', this.invoice_selected.id).then(response => {
            this.invoice_selected = response.data
          })
          this.payments[indexPayment] = response.data
        })
        .catch(error => {
          this.$notify({
            type: error.data.error_type,
            message: error.data.message
          });
          this.loadingInstallmentStatus = false;
          this.$Progress.finish();
        });
    },
    handleFilterOrderBy(type) {
      this.filter.order_by = type
      this.init({})
    },
    isEqual(a, b) {
      a = a.toString().toLowerCase().trim()
      b = b.toString().toLowerCase().trim()
      return a === b
    },
    clearFilter() {
      this.filter = {
        range_nfse_number: {
          min: null,
          max: null
        },
        range_invoice_value: {
          min: null,
          max: null
        },
        range_invoice_number: {
          min: null,
          max: null
        },
        seller: null,
        reversed: null,
        order_by: null,
        without_car: 0,
        nfse_pending_issue: 0,
        nfse_denied: 0,
        nfse_issuing: 0,
        email_dont_sended: 0,
        with_iss: 0,
      }
      this.filter.canceled = 0
      this.filter.not_canceled = 1
      this.filter.verify_status = 0
      this.filter.filter_by_default = 1
      this.init({})
    },
    handleOpenNfseSent(item) {
      this.$refs.ModalNfseSent.handleCreateModal(item)
    },
    handleUpdateAfterSendBankBillet(id) {
      this.invoices.map(function (item) {
        if (item.id === id) {
          item.is_pending = 0
          item.has_any_pending = 1
        }
      })
      this.showModalPaymentTerms = false
    },
    setSendToMail(item, value) {
      this.$snotify.success('Solicitação realizada com sucesso!', {
        timeout: 1000,
        icon: false,
        position: "leftBottom",
        showProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true
      });
      item.auto_mail = value
      let loader = this.$loading.show()
      this.$store.dispatch('billingInvoice/postSetSetSendToMail', {
        id: item.id,
        value: item.auto_mail,
      }).then(() => {
        loader.hide()
      })
    },
    handleOpenEmail(item) {
      this.$refs.ModalSendEmail.openModal(item)
    },
    handleShowIssueBillet(invoice) {
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      let loader = this.$loading.show()
      this.$store.dispatch('billingInvoice/getPaymentTerms',
        {invoice_id: invoice.id})
        .then(response => {
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.$refs.modalPaymentTerms.setInvoice(invoice)
          this.showModalPaymentTerms = true
          loader.hide()
        })
        .catch(() => loader.hide())
    },
    /**
     * Busca os pagamentos agrupados por condição de pagamento
     * @param invoice_id
     * @param showBilletIfSinglePayment Abrir modal de emissão de boleto se for um único pagamento
     */
    handleOpenModalPaymentTerms(invoice, showBilletIfSinglePayment) {
      // Não exibir boleto para emissão se parte do pagamento já estiver como recebido
      if (Number(invoice.paid) > 0) {
        showBilletIfSinglePayment = false;
      }
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      let loader = this.$loading.show()
      this.$store.dispatch('billingInvoice/getPaymentTerms',
        {invoice_id: invoice.id})
        .then(response => {
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.$refs.modalPaymentTerms.setInvoice(invoice, showBilletIfSinglePayment)
          this.showModalPaymentTerms = true
          loader.hide()
        })
        .catch(() => loader.hide())
    },
    formatNfse(nfse) {
      // Supondo que $treatedNfseRow seja um objeto em JavaScript
      const nfseNumber = nfse.nfse_number.toString();
      const lastFiveDigits = nfseNumber.slice(-5);
      const formatedNumber = lastFiveDigits.padStart(5, '0');

      // Supondo que $treatedNfseRow.authorization_date seja uma data válida em JavaScript
      const currentDate = new Date(nfse.authorization_date);
      const currentYear = currentDate.getFullYear();
      return `${currentYear}${formatedNumber}`;
    },
    backgroundNfse(item) {
      if (!item.nfse) {
        return {
          text: 'EMITIR',
          classText: '#606062',
          icon: "/img/icons/nfe_grey.png",
        }
      }
      switch (item.nfse.status.trim()) {
        case 'Cancelada':
          return {
            class: 'text-lowercase',
            text: this.formatNfse(item.nfse),
            classText: 'rgb(219, 69, 57)',
            icon: "/img/icons/nfe_red.png",
          }
        case 'Cancelando':
          return {
            text: 'CANCELANDO',
            classText: 'rgb(180, 40, 50)',
            icon: "/img/icons/nfe_yellow.png",
          }
        case 'Negada':
          return {
            text: 'NEGADA',
            classText: 'rgb(219, 69, 57)',
            icon: "/img/icons/nfe_red.png",
          }
        case 'Autorizada':
          return {
            class: 'text-lowercase',
            text: this.formatNfse(item.nfse),
            classText: 'rgb(26, 112, 183)',
            icon: "/img/icons/nfe_blue.png",
          }
        case 'Pendente':
          return {
            text: 'EMITINDO',
            classText: '#f2b532',
            icon: "/img/icons/nfe_yellow.png",
          }
      }
    },
    backgroundBillet(item) {
      if (item.every_paid) {
        return 'text-success fa-solid fa-barcode'
      }
      if (item.every_canceled) {
        return 'text-danger fa-solid fa-barcode'
      }
      if (item.is_pending) {
        return (item.invoice_attachments.length == 0)
          ? 'text-warning fa-solid fa-barcode'
          : 'text-success fa-solid fa-paperclip'
      }
      return 'text-primary fa-solid fa-barcode'
    },
    async downloadInvoice(id) {
      this.$notify({type: 'success', message: 'Download iniciado com sucesso!'});
      this.$Progress.start()
      let loader = this.$loading.show()
      this.$store.dispatch('billingInvoice/download', {
          id: id
        }
      ).then(async response => {
        let blob = new Blob([response], {type: "application/pdf"});
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", `Fatura-${id}.pdf`);
        await link.click();
        loader.hide()
        this.$Progress.finish()
      }).catch(error => {
        loader.hide()
        this.$Progress.finish()
      })
    },
    handleFilterDate(filter, default_filter = 1) {
      this.filter_by_default = default_filter
      this.range = filter
      this.init({})
    },
    handleFilterDefaultDate(filter) {
      this.filter_by_default = 0
      this.range = filter
      this.init({})
    },
    handleOpenNfseAuthorizedImportModal(item) {
      this.$refs.ModalNfseAuthorizedImport.handleCreateModal(item);
    },
    handleChangeNfseModal(item) {
      this.$refs.modalChangeNfse.openModal(item);
    },
    handleOpenWhatsappModal(item) {
      this.$refs.ModalWhatsapp.openModal(item);
    },
    handleOpenNfseModal(item) {
      // Impedir Emissão se contrato concluído ou inativo
      if ([0, 5].includes(item.contract_proposal.status)) {
        this.$notify({
          type: 'warning',
          message: 'Emissão não pode ser realizada. Contrato com status Concluído/Inativo.'
        })
        return
      }
      this.$refs.ModalNfseCreate.handleCreateModal(item)
    },
    setRange: debounce(function () {
      this.init({})
    }, 100),
    setComment: debounce(function (item) {
      let params = {
        comments: item.comments,
        id: item.id
      }
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      let loader = this.$loading.show()
      this.$store.dispatch("billingInvoice/postSetComment", params).then(response => {
        loader.hide()
        this.$notify({type: 'success', message: response.message});
      }).catch(() => loader.hide());
    }, 500),
    handleFilterVerifyStatus() {
      this.filter.verify_status = +!this.filter.verify_status
      if (!this.filter.verify_status) {
        this.filter.verify_status = null
      }
      if (this.filter.verify_status) {
        this.filter.status_paid = null
        this.filter.canceled = null
      }
      this.init({})
    },
    /**
     * Busca histórico de faturas enviadas
     * @param invoice_id
     */
    handleShowMailHistory(invoice_id) {
      let loader = this.$loading.show()
      this.$store.dispatch('billingInvoice/getMailHistory', {
        invoice_id: invoice_id
      }).then(response => {
        this.showModalMailHistory = true
        loader.hide()
      })
    },
    downloadBilletBatch(bank_billet_batch_id) {
      if (!bank_billet_batch_id) {
        this.$notify({
          type: 'warning',
          message: 'O lote ainda não foi gerado. Favor entre em contato com o suporte.'
        });
      }
      this.$Progress.start()
      let loader = this.$loading.show()
      this.$store.dispatch('bankBillet/getPdfFileUrl', bank_billet_batch_id)
        .then(async (response) => {
          let blob = new Blob([response], {type: "application/pdf"});
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", `Lote ${bank_billet_batch_id}.pdf`);
          await link.click();
          this.$snotify.success('Download iniciado com sucesso!', {
            timeout: 1000,
            icon: false,
            position: "centerBottom",
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false
          });
          loader.hide()
        });
    },
    verifyBankBillet(item) {
      if (item.has_any_billet_generated) {
        return this.downloadBilletBatch(item.bank_billet_batch_id)
      }
      // Quando boleto for anexado, ao invés de ter sido emitido
      if (item.is_pending && item.invoice_attachments.length > 0) {
        return this.handleShowInvoiceAttachment(item)
      }
      return this.handleOpenModalPaymentTerms(item, true)
    },
    async removeInvoiceFromList(invoiceId) {
      this.reverse = 0
      await this.$store.dispatch('billingInvoice/show', this.invoice_selected.id).then(async response => {
        let index = this.invoices.findIndex((item) => item.id === this.invoice_selected.id)
        this.$set(this, 'invoice_selected', response.data)
        if (this.reverse) {
          this.invoice_selected.reversed = 1
        }
        await this.getBillReceives(this.invoice_selected.id)
        this.$set(this.invoices, index, response.data)
      })
    },
    async handleCancelNfse(item, allowReissueNfse, reversing = 0) {
      if (! this.$_financial_limit.invoice_can_cancel_nfse) {
        return this.$swal.fire({
          icon: 'error',
          title: 'Aviso',
          text: 'Usuário não possui permissão para cancelar NFS-e',
        })
      }
      // Refresh nos dados da nota para evitar erro
      await this.refreshItemNfse(item);
      if (item.nfse.status !== 'Autorizada') {
        return;
      }

      let loader = this.$loading.show()
      const companyPlantIssuer = await this.getCompanyPlantIssuer(item.company_plant_issuer.id);
      loader.hide();

      // Cancelamento manual configurado em Emissor
      if (companyPlantIssuer.nfse_allow_manual_cancellation) {
        this.reverse = reversing
        this.$refs.ModalNfseCanceledImport.handleCreateModal(item, reversing);
        return;
      }

      // Não permitir cancelamento de notas autorizadas a mais de 30 dias
      const currentDate = moment();
      const authorizationDate = moment(item.nfse.authorization_date).format('YYYY-MM-DD H:mm:ss')
      const daysDifference = currentDate.diff(authorizationDate, 'days');
      if (daysDifference > 30) {
        this.$notify({type: 'warning', message: 'Notas emitidas há mais de 30 dias não podem ser canceladas.'});
        return;
      }

      // Não permitir cancelamento por API se nota importada
      if (!item.nfse.nfse_external_id) {
        this.$notify({
          type: 'danger',
          message: 'Esta nota foi importada! Não é possível efetuar o cancelamento por webservice.'
        });
        return;
      }

      // Cancelamento por API
      this.$swal.fire({
        title: `Deseja cancelar NFS-e: ${item.nfse.nfse_number}`,
        text: 'Você não poderá desfazer esta ação!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, cancelar NFS-e!',
        cancelButtonText: 'Não, mantenha',
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          let loader = this.$loading.show();
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          const cancelWithEnotasProvider = item.nfse_provider == 0;
          const action = cancelWithEnotasProvider
            ? 'nfse/destroyByNfseExternalId'
            : 'nfse/cancelMigrateNfse';
          const params = {
            ms_company_id: companyPlantIssuer.nfse_ms_company_id,
            nfse_external_id: item.nfse.nfse_external_id
          };
          this.$store.dispatch(action, params)
            .then((response) => {
              this.$notify({
                type: "success",
                message: "Solicitação concluída com sucesso! Este processo será realizado em segundo plano.",
              });
              let current_invoice = {
                company_plant_id: item.company_plant.id,
                is_reversing: reversing,
                contract_proposal_id: item.contract_proposal.id,
                company_plant_issuer_id: item.company_plant_issuer.id,
                payment_term_id: item.payment_term.id,
                formulation_value: item.formulation_value,
                single_value: item.single_value,
                mcc_value: item.mcc_value,
                service_value: item.service_value,
                additional_value: item.additional_value,
                total: item.total,
                nfse: item.nfse,
                allow_reissue_nfse: allowReissueNfse,
              };
              current_invoice.nfse.status = "Cancelando";
              let params = {id: item.id, data: current_invoice};
              this.$store.dispatch("nfse/updateInvoiceNfse", params)
                .then(() => {
                  item.nfse.status = current_invoice.nfse.status;
                })
                .catch((error) => {
                  this.$notify({type: "danger", message: error.data.message});
                });
            })
            .catch((error) => {
              this.$notify({type: error.data.error_type, message: error.data.message});
            })
            .finally(() => {
              loader.hide();
            });
        }
      });
    },
    handleFilterAdvancedStatus(filter) {
      if (this.filter.status_paid === filter) {
        this.filter.status_paid = null
        this.filter.verify_status = null
        return this.init({})
      }
      this.filter.status_paid = filter
      this.filter.canceled = null
      this.filter.verify_status = null
      this.filter.not_canceled = 1
      this.init({})
    },
    handleFilterCanceledStatus() {
      this.filter.canceled = +!this.filter.canceled
      if (!this.filter.canceled) {
        this.filter.not_canceled = 1
      } else {
        this.filter.status_paid = null
        this.filter.verify_status = null
        this.filter.not_canceled = 0
      }
      this.init({})
    },
    handleFilterReversedStatus() {
      this.filter.reversed = +!this.filter.reversed
      if (!this.filter.reversed) {
        this.filter.not_canceled = 1
      } else {
        this.filter.status_paid = null
        this.filter.verify_status = null
        this.filter.not_canceled = 0
      }
      this.init({})
    },
    init(filter = null) {
      this.startCursor(filter)
      this.$store.dispatch('billingInvoice/fetchPaginate',
        {
          filter: this.filter,
          per_page: this.params.type === 0 ? 3 : 10,
          next_page: this.paginate.nextUrl
        }).then(response => {
        this.resolveCursor(response)
        // Comentário sempre expandido quando houverem dados cadastrados.
        response.data.forEach(item => {
          item.show_comment = item.comments && item.comments.length > 0;
        });
      }).catch(error => {
        this.resolveCursor()
      })
    },
    removeInvoice(id) {
      let loader = this.$loading.show()
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          this.$store.dispatch('billingInvoice/destroy', id).then(response => {
            this.$notify({type: response.error_type, message: response.message})
            loader.hide()
          }).catch(error => {
            this.$notify({type: error.data.error_type, message: error.data.message})
            loader.hide()
          })
        } else {
          loader.hide()
        }
      }).catch(() => loader.hide())
    },
    async nfseButtonClick(item) {
      // Refresh nos dados da nota para evitar erro
      await this.refreshItemNfse(item);

      // Abrir modal de emissão da nfs-e
      if (!item.nfse) {
        this.handleOpenNfseModal(item)
        return
      }

      // Informar Status da NFSe ou Abrir PDF
      switch (item.nfse.status) {
        case 'Pendente':
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          break
        case 'Autorizada':
        case 'Cancelada':
          this.openDanfseOnNewTab(item.nfse.link_pdf)
          break
        case 'Negada':
          this.$swal.fire({
            icon: 'error',
            title: 'NFS-e Negada!',
            text: item.nfse.status_reason,
          })
          break
      }
    },
    async refreshItemNfse(item) {
      let loader = this.$loading.show()
      item.nfse = await this.$store.dispatch('nfse/showInvoiceNfse', item.id).then(res => {
        return res.data.nfse
      }).catch(error => {
        this.$notify({type: error.data.error_type, message: error.data.message})
      })
      loader.hide()
    },
    async openDanfseOnNewTab(pathFile) {
      let loader = this.$loading.show();
      const urlFromAmazon = pathFile.includes('s3');
      if (urlFromAmazon) {
        const url = this.base_url_ms + 'download-s3?url=' + pathFile
        this.$store.dispatch('exports/download', url).then(async response => {
          let blob = new Blob([response], {type: "application/pdf"});
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", 'key.pdf');
          await link.click()
        });
      } else {
        await window.open(pathFile, '_parent');
      }
      this.$snotify.success('Download iniciado com sucesso!', {
        timeout: 1000,
        icon: false,
        position: "centerBottom",
        showProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false
      });
      loader.hide()
    },
    async downloadNfseXml(item) {
      const pathFile = item.nfse.link_xml;
      let loader = this.$loading.show();
      const urlFromAmazon = pathFile.includes('s3');
      if (urlFromAmazon) {
        const url = this.base_url_ms + 'download-s3?url=' + pathFile
        this.$store.dispatch('exports/download', url).then(async response => {
          let blob = new Blob([response], {type: "application/pdf"});
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", 'key.xml');
          await link.click()
        });
      } else {
        await window.open(pathFile, '_parent');
      }
      this.$snotify.success('Download iniciado com sucesso!', {
        timeout: 1000,
        icon: false,
        position: "centerBottom",
        showProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false
      });
      loader.hide();
    },
    async getCompanyPlantIssuer(id) {
      return await this.$store
        .dispatch("companyPlantIssuer/show", id)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: error.data.message,
          });
        });
    },
    /**
     * Verificar se baixa do pagamento de boleto foi efetuado sem a emissão do boleto
     * @param {object} item
     * @returns {boolean} true/false
     */
    isPaymentWithBankBilletReceivedWithoutIssue(item) {
      const received = Number(item.open_payment) === 0;
      const withoutBankBilletIssue = Number(item.bank_billet_batch_id) === 0;
      return received && withoutBankBilletIssue;
    },
    cardBorderTopColor(item) {
      let cardBorderTopColor = (item.open_payment > 0) ? 'card-border-top-yellow' : 'card-border-top-success'
      if (item.nfse && item.nfse.status === 'Cancelada') {
        cardBorderTopColor = 'card-border-top-danger'
      }
      return cardBorderTopColor
    },
    async copyNfseValuesToClipboard(item) {
      const companyPlantIssuer = await this.getCompanyPlantIssuer(item.company_plant_issuer.id);
      this.$helper.copy(
        `ID DO MICROSERVIÇO: ${companyPlantIssuer.nfse_ms_company_id}; ID DA NFS-E: ${item.nfse.nfse_external_id}`
      )
      this.$notify({type: 'success', message: `Chave da NFS-e copiada com sucesso!`})
    },
    showNfseStatusReason(nfseStatusReason) {
      this.$swal.fire({
        icon: 'error',
        title: 'Cancelamento Negado!',
        text: nfseStatusReason,
      });
    },
    handleShowInvoiceAttachment(item) {
      this.$refs.modalInvoiceAttachmentList.handleCreateModal(item.id);
    },
    handleInvoiceHistory(InvoiceId) {
      this.$refs.modalInvoiceHistory.openModal(InvoiceId);
    },
    handleModalInvoiceIntegrationInfo(invoice_id) {
      this.$refs.modalInvoiceIntegrationInfo.openModal(invoice_id);
    },
    fetchPermission() {
      this.$store.dispatch('commercialPermission/getByUserId', this.$user.id);
      this.$store.dispatch('permissionFinancialLimit/show', this.$user.id);
    },
    /**
     * @param {number} invoiceId
     */
    handleCreateModalEditSeller(invoiceId) {
      this.$refs.modalEditSeller.openModal(invoiceId);
    },
    /**
     * @param {number} invoiceId
     */
    handleEditIntermediaryModal(invoiceId) {
      this.$refs.modalEditIntermediary.openModal(invoiceId);
    },
    /**
     * Atualiza nome do vendedor após a edição na modal.
     * @param {object} seller
     */
    updatedSeller(seller) {
      let foundInvoice = this.invoices.find((item) => item.id == seller.invoice_id);
      foundInvoice.seller = seller.seller_name;
    },
    /**
     * Atualiza nome do intermediário após a edição na modal.
     * @param {object} intermediary
     */
    updatedUserIntermediary(intermediary) {
      const foundInvoice = this.invoices.find((item) => item.id == intermediary.invoice_id);
      foundInvoice.user_intermediary_name = intermediary.user_intermediary_name;
    }
  }
}
</script>

<style></style>
