<template>
  <div>
    <base-header class="bg-gray-content">
      <PuzlBreadcrumb />
      <!-- Header Menus -->
      <div class="row">
        <div class="col-md-12">
          <div class="row">
              <div class="col-md-3 col-lg-2 col-xlg-2 ml-3 mr-3 mb-3 ml-0 p-0">
                <base-button class="pb-3 pt-3" block size="lg" type="success" @click="handleCreate()">
                  <i class="fas fa-plus"></i> NOVO
                </base-button>
              </div>

              <div class="col-md-3 col-lg-2 col-xlg-2 ml-3 mr-3 mb-3 ml-0 p-0">
                <router-link :to="{ path:'/commercial/contract-proposal/contracts'}">
                  <base-button block size="lg" type="light" class="p-3">
                    VOLTAR
                  </base-button>
                </router-link>
              </div>
          </div>
        </div>
      </div>
    </base-header>
    <List ref="listRef"/>
  </div>
</template>
<script>
import List from './Shared/_List'
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
export default {
  components: {
    PuzlBreadcrumb,
    List,
  },
  data() {
    return {
    }
  },
  methods: {
    handleCreate(){
      this.$refs.listRef.handleCreate()
    },
  },
  mounted() {
  }
};
</script>
<style></style>
