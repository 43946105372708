<template>
  <div class="col-12 pr-0 pl-0">
    <div>
      <card class="card-border-top-light bg-gray-content">
        <div class="row">
          <div class="col-md-5">
            <skeleton-puzl  type="button"></skeleton-puzl>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import SkeletonPuzl from "./SkeletonPuzl";

export default {
  name: "SkeletonPuzlFullWidth",
  components: {
    SkeletonPuzl,
  },
  data() {
    return {};
  },
  updated() {},
};
</script>
<style></style>
