/**
 * Type para item da listagem
 *
 * @typedef {Object} ProductServiceHubTypeListType
 * @property {number} id
 * @property {string} name
 * @property {boolean} default
 * @property {boolean} is_entry
 * @property {boolean} is_exit
 * @property {boolean} is_devolution
 * @property {boolean} is_transfer
 * @property {?string} created_at
 * @property {?string} updated_at
 * @property {?string} sequence
 */
export const ProductServiceHubTypeListType = {};

/**
 * Inicializar ProductServiceHubTypeListType
 *
 * @returns {ProductServiceHubTypeListType}
 */
export const initProductServiceHubTypeListType = () => {
  return {
    id: 0,
    name: "",
    default: false,
    is_entry: false,
    is_exit: false,
    is_devolution: false,
    is_transfer: false,
    created_at: null,
    updated_at: null,
    sequence: null,
  };
};
