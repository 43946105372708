<template>
  <div>
    <modal size="lg" :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
            <div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 col-form-label form-control-label">
                  Status
                </label>
                <div class="col-md-7 pt-2">
                  <base-switch v-model="charge_point.status" type="success" offText="inativo" onText="ativo"
                               class="success"></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pb-0 col-form-label form-control-label">
                  Nome
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input type="text" v-model="charge_point.name" class="form-control form-control-sm"/>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pb-0 col-form-label form-control-label">
                  Configuração de pesagem
                </label>
                <div class="col-md-7">
                  <base-input input-classes="form-control form-control-sm">
                    <puzl-select 
                      v-model="charge_point.profile_uuid" 
                      :items="charging_types" 
                      customKey="uuid"  
                      label="description" />
                  </base-input>
                </div>
              </div>

              <breadcrumb
                list-classes="bg-success border-header-warning-bottom mt-4"
              >
                Automação
              </breadcrumb>
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pb-0 col-form-label form-control-label">
                  Tipo
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <puzl-select 
                      v-model="charge_point.automation.id" 
                      :items="$_automations.data" 
                      @input="setAutomation()" />
                  </base-input>
                </div>
              </div>
              <template v-if="selected_automation">
                <div v-for="(item, index) in selected_automation.payload">
                  <div  class="form-group row m-0 p-0 mb-1">
                    <label class="col-md-5 pb-0 col-form-label form-control-label">
                      {{ item.title }}
                      <span v-if="item.required" class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-7">
                      <validation-provider :rules="item.required ? 'required' : ''">
                        <base-input v-if="item.type === 'input'" input-group-classes="input-group-sm">
                          <input type="text" v-model="charge_point.automation.payload[index]" class="form-control form-control-sm"/>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                </div>
              </template>
            </div>

            <div class="modal-footer">
              <base-button type="secondary" @click="openModal(false)">
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingSave">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
const {formatErrorValidation} = require("@/plugins")

export default {
  name: "ModalCreateChargePoint",
  components: { PuzlSelect },
  data() {
    return {
      title: 'Ponto de Carga',
      modal: false,
      selected_automation: null,
      charge_point: {
        status: true,
        company_id: '',
        company_plant_id: '',
        profile_uuid: '',
        name: '',
        type: '',
        ip: '',
        send: '',
        return: '',
        automation: {
          id: null,
          payload: {}
        }
      },
      loadingSave: false,
    }
  },
  computed: {
    ...mapGetters({
      'charging_types': 'chargingTypeProfile/fetch',
      '$_automations': 'automation/getAutomations',
    }),
  },
  methods: {
    openModal(open, companyId, plantId) {
      if (open) {
        let loader = this.$loading.show()
        this.$store.dispatch('chargingTypeProfile/fetchItems').then(() =>
          loader.hide()
        ).catch(() =>
          loader.hide()
        )
      }
      this.charge_point = {
        status: true,
        company_id: companyId,
        company_plant_id: plantId,
        name: '',
        type: '',
        ip: '',
        send: '',
        return: '',
        automation: {
          id: null,
          payload: {}
        }
      }
      this.modal = open
    },
    setAutomation() {
      this.selected_automation = this.$_automations.data.find(item => item.id === this.charge_point.automation.id)
    },
    save() {
      this.$Progress.start()
      this.loadingSave = true
      this.$store.dispatch('plantChargePoint/add', this.charge_point)
        .then(response => {
          this.loadingSave = false
          this.modal = false
          this.$Progress.finish()
          this.$notify({type: response.error_type, message: response.message})
        })
        .catch(error => {
          if (error.response.status === 422) {
            let message = formatErrorValidation(error.response.data.errors)
            this.$notify({type: 'danger', message: message})
          } else {
            this.$notify({type: error.data.error_type, message: error.data.message})
          }
          this.$Progress.finish()
          this.loadingSave = false
        })
    },
  },
}
</script>

<style scoped>

</style>