<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5> 
      </template>
      <div>

        <div class="row m-0 p-0" v-show="loading">
          <div class="col-md-8  m-0 p-0">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
          <div class="col-md-4">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
        </div>

        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator" v-show="!loading">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div>
              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-8 pb-0 mb-1 col-form-label form-control-label"
                >
                  Aprovação financeira de propostas/contratos
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-4 pt-2">
                  <base-switch
                    v-model="user.can_financial_proposal_contract"
                    type="primary"
                    class="success"
                  ></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-8 pb-0 mb-1 col-form-label form-control-label"
                >
                  Aprovação comercial de propostas/contratos
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-4 pt-2">
                  <base-switch
                    v-model="user.can_commercial_proposal_contract"
                    type="primary"
                    class="success"
                  ></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-8 pb-0 mb-1 col-form-label form-control-label"
                >
                  Aprovação comercial
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-4 pt-2">
                  <base-switch
                    v-model="user.can_commercial_proposal_contract"
                    type="primary"
                    class="success"
                  ></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-8 pb-0 mb-1 col-form-label form-control-label"
                >
                  Visualizar todos contratos
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-4 pt-2">
                  <base-switch
                    v-model="user.can_commercial_proposal_contract"
                    type="primary"
                    class="success"
                  ></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-8 pb-0 mb-1 col-form-label form-control-label"
                >
                  Aprovação financeira
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-4 pt-2">
                  <base-switch
                    v-model="user.can_commercial_proposal_contract"
                    type="primary"
                    class="success"
                  ></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-8 pb-0 mb-1 col-form-label form-control-label"
                >
                  Aprovação técnica
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-4 pt-2">
                  <base-switch
                    v-model="user.can_commercial_proposal_contract"
                    type="primary"
                    class="success"
                  ></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-8 pb-0 mb-1 col-form-label form-control-label"
                >
                  Aprovação operacional
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-4 pt-2">
                  <base-switch
                    v-model="user.can_commercial_proposal_contract"
                    type="primary"
                    class="success"
                  ></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-8 pb-0 mb-1 col-form-label form-control-label"
                >
                  Cadastro de propostas/contratos
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-4 pt-2">
                  <base-switch
                    v-model="user.can_register_proposal_contract"
                    type="primary"
                    class="success"
                  ></base-switch>
                </div>
              </div>

            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import SkeletonPuzl from "@/components/SkeletonPuzl";
const {formatErrorValidation} = require("@/plugins")

export default {
  name: "ModalSetUserPermission",
  components: {
    SkeletonPuzl
  },
  data() {
    return {
      modal: false,
      title: 'Permissão de usuário para contrato',
      loadingSave: false,
      loading: true,
      user: {
        user_uuid: null,
        can_financial_proposal_contract: false,
        can_commercial_proposal_contract: false,
        can_register_proposal_contract: false,
      },
      loadingStore: false,
    };
  },
  methods: {
    closeModal () {
      this.modal = false
    },
    openModal (userUuid) {
      this.fetch(userUuid)
      this.modal = true
    },
    fetch(userUuid){
      this.$Progress.start();
      this.loading = true
      this.$store.dispatch('userPermission/show', userUuid).then(({data}) => {
        this.user = {
          user_uuid: userUuid,
          can_financial_proposal_contract: data ? data.can_financial_proposal_contract : false,
          can_commercial_proposal_contract: data ? data.can_commercial_proposal_contract: false,
          can_register_proposal_contract: data ? data.can_register_proposal_contract : false,
        }
        this.$Progress.finish();
        this.loading = false
      })
    },
    store() {
      this.$Progress.start();
      this.loadingStore = true;
      this.$store.dispatch("userPermission/add", this.user).then(response => {
          this.loadingStore = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.$store.dispatch('user/fetchItems')
        this.modal = false
        }).catch(error => {
          if (error.status) {
            this.$Progress.finish()
            this.loadingStore = false
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            })
          } else {
            if (error.response.status === 422) {
              let message = formatErrorValidation(error.response.data.errors)
              this.$notify({
                type: 'danger',
                message: message
              })
              this.$Progress.finish()
              this.loadingStore = false
            } else {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              })
              this.$Progress.finish()
              this.loadingStore = false
            }
          }
        });
    },
  },
}
</script>
