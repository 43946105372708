<template>
  <div>
    <VueFileAgent
      ref="vueFileAgent"
      :theme="'list'"
      :multiple="multiple"
      :deletable="true"
      :meta="true"
      :accept="accept"
      :maxSize="'10MB'"
      :maxFiles="maxFiles"
      :helpText="helpText"
      :errorText="{
        type: errorTextType,
        size: 'Os arquivos não devem exceder 10 MB de tamanho',
      }"
      @select="filesSelected($event)"
      @beforedelete="onBeforeDelete($event)"
      @delete="fileDeleted($event)"
      v-model="fileRecords"
    ></VueFileAgent>
  </div>
</template>

<script>
import { base_url_ms } from "@/plugins";

export default {
  data: function () {
    return {
      fileRecords: [],
      uploadUrl: "https://www.mocky.io/v2/5d4fb20b3000005c111099e3",
      uploadHeaders: {"X-Test-Header": "vue-file-agent"},
      removedFiles: [],
      fileRecordsForUpload: [], // maintain an upload queue
      base_url_ms: base_url_ms(),
    };
  },
  props: {
    multiple: {
      type: Boolean,
      default: true,
      description: "Permite múltiplos arquivos",
    },
    maxFiles: {
      type: Number,
      default: 3,
      description: "Máximo de arquivos",
    },
    accept: {
      type: String,
      default: 'image/*,.pdf',
      description: "Extensões de arquivos permitidas",
    },
    errorTextType: {
      type: String,
      default: 'Tipo de arquivo inválido. Apenas imagens ou pdf permitido',
      description: '',
    },
    helpText: {
      type: String,
      default: 'Escolha os arquivos ou arraste e solte aqui',
      description: "Informação antes de informar o(s) arquivo(s)",
    },
  },
  mounted() {
    this.fileRecords = []
    this.removedFiles = []
  },
  methods: {
    uploadFiles: function () {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.upload(this.uploadUrl, this.uploadHeaders, this.fileRecordsForUpload);
      this.fileRecordsForUpload = [];
    },
    async setFileByObject(item, byExport = false) {
      if(byExport) {
        const url = this.base_url_ms + 'download-s3?url=' + item.url;
        const response = await this.$store.dispatch('exports/download', url);
        let blob = new Blob([response], {type: item.type});
        item.url = URL.createObjectURL(blob);
        this.fileRecords.push(item);
        return ;
      }

      this.fileRecords.push(item);
    },
    deleteUploadedFile: function (fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
      this.$emit('input', this.multiple ? validFileRecords : validFileRecords[0])
    },
    onBeforeDelete: function (fileRecord) {
      let url = undefined
      if (typeof fileRecord.url === 'function') {
        url = fileRecord.url()
      } else {
        url = fileRecord.url
      }
      if (url !== undefined) {
        this.removedFiles.push(url);
      }
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      // queued file, not yet uploaded. Just remove from the arrays
      this.fileRecordsForUpload.splice(i, 1);
      var k = this.fileRecords.indexOf(fileRecord);
      if (k !== -1) this.fileRecords.splice(k, 1);
      this.$emit('input', null)
    },
    fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
    setFilesByUrl(files) {
      this.fileRecords = []
      const self = this;
      if (typeof files == "object") {
        const arrFiles = Object.values(files);
        arrFiles.map(function (file) {
          const item = {
            name: file.file_name,
            size: file.file_size,
            type: file.type,
            ext: file.ext,
            url: file.url,
          };
          self.fileRecords.push(item);
        });
      } else {
        const item = {
          name: files.file_name,
          size: files.file_size,
          type: files.type,
          ext: files.ext,
          url: files.url,
        };
        self.fileRecords.push(item);
      }
    },
  },
};
</script>

<style scoped></style>
