import { createAxios } from "@/plugins/axios";;
const endPoint = "operational/schedule/schedule-travel-cycle/attachment+microservice";
export default {
  namespaced: true,
  actions: {
    updateOrCreate({}, params) {
      return createAxios()
        .post(`${endPoint}/${params.get("schedule_travel_cycle_id")}`, params, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    show({}, schedule_travel_cycle_id) {
      return createAxios()
        .get(`${endPoint}/${schedule_travel_cycle_id}`)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
};
