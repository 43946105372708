<template>
  <div>
    <modal @close="close" size="md" :show.sync="show">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">{{ title }}</h5>
      </template>
      <div>
        <div class="row" v-if="show && params">
          <div class="col-md-12">
            <h1 class="text-center">
              <i class="fas fa-exclamation-circle fa-2x text-orange"></i>
            </h1>
            <h5 class="text-center text-danger" v-if="params.status == 3">A AÇÃO DE CANCELAR UM PAGAMENTO NÃO PODE SER DESFEITA</h5>
            <h4 class="text-center mb-2" style="font-weight: normal">
              Alterar status para
              <span class="ml-4 text-uppercase font-weight-bold" :class="'text-'+ BillReceiveColor[params.status]"
              >
                {{ BillReceiveStatus[params.status] }}
                          </span>
            </h4>
            <div v-if="params.status === 10" class="form-group row m-0 p-0 mb-1">
              <div class="col-md-3">
                <div class="pb-1 col-form-label form-control-label">
                  Data
                </div>
              </div>
              <div class="col-md-9">
                <base-input input-group-classes="input-group-sm">
                 <input-date-time-picker
                  mode="date"
                  v-model="params.received_at"
                  :disableDate="minDate"
                  :disableMaxDate="new Date()"
                 />
                </base-input>
              </div>
            </div>
            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-3 pb-0 pt-1 col-form-label form-control-label">
                Observações
              </label>
              <div class="col-md-9" v-if="show">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                  <textarea v-model="params.observation" type="text" style="height: 100px" rows="2" class="form-control form-control-sm">
                  </textarea>
                  </base-input>
                </validation-provider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <base-button type="secondary" @click="close()">
          Cancelar
        </base-button>
        <base-button
          @click.prevent="save()"
          type="success"
          native-type="submit"
          :loading="isLoading"
        >
          Salvar
        </base-button>
      </div>
    </modal>
  </div>
</template>

<script>
import moment from "moment";
import InputDateTimePicker from "@/components/InputDateTimePicker";

export default {
  name: "ModalAlterStatus",
  props: ['show'],
  components: {
    InputDateTimePicker
  },
  data() {
    return {
      title: 'Alterar Status',
      params: null,
      isLoading: false,
      minDate:null,
      BillReceiveStatus: {
        0 : "Pendente",
        1 : "Recebido",
        3 : "Cancelado",
        4 : "Serasa",
        5 : "Cartório",
        6 : "Protestado",
        7 : "Jurídico",
        8 : "Aprovado",
        10 : "Estornado",
      },
      BillReceiveColor: {
        0 : "yellow",
        1 : "success",
        3 : "danger",
        4 : "info",
        5 : "primary",
        6 : "warning",
        7 : "indigo",
        8 : "primary",
        10 : "orange",
      }
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    setBillReceive(params) {
     this.params = params
      this.params.is_reversed = 0
      if (this.params.status === 10) {
        this.params.is_reversed = 1
        this.params.received_at = moment(this.params.received_at).format('YYYY-MM-DD 00:00:00');
        this.minDate = new Date(this.params.received_at)
      }
    },
    save() {
      this.$Progress.start();
      const params = {...this.params}
      if (params.status === 10) {
        params.status = 0
      }
      if (params.received_at) {
        params.received_at = moment(params.received_at).format('YYYY-MM-DD')
      }
      params.unique = 1
      this.isLoading = true
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.$store
        .dispatch("billReceive/changeInstallmentStatus", params)
        .then(response => {
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.isLoading = false
          params.response = response.data
          this.$emit('saveAndClose', params)
        })
        .catch(error => {
          this.$notify({
            type: error.data.error_type,
            message: error.data.message
          });
          this.isLoading = false
          this.$Progress.finish();
        });
    }
  },
};
</script>

<style>
</style>
