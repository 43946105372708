<template>
<div>
 <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(storeCreate)"
            autocomplete="off"
          >
            <div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-4 col-form-label form-control-label">
                  Status
                </label>
                <div class="col-md-8 pt-2">
                  <base-switch
                    v-model="addServiceDescription.status"
                    type="success"
                    offText="inativo"
                    onText="ativo"
                    class="success"
                  ></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-4 pt-1 pb-0 col-form-label form-control-label"
                >
                  Serviço
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-classes="form-control form-control-sm">
                          <puzl-select 
                            v-model="addServiceDescription.service_id" 
                            :items="listServices"  
                            label="service_name" 
                            :loading="isloadingListServices" 
                            :disabled="isloadingListServices"
                            @input="changeService()" />
                        </base-input>
                      </validation-provider>
                  </div>
              </div>

            <div class="form-group row m-0 p-0">
                <label
                  class="col-md-4 pt-1 pb-0 mb-1 col-form-label form-control-label"
                >
                  Descrição
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="addServiceDescription.description"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')"
                >Cancelar</base-button
              >
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                :loading="loading"
                >Salvar</base-button
              >
            </div>
          </form>
        </validation-observer>
      </div>
  </modal>
</div>
</template>
<script>
import PuzlSelect from "@/components/PuzlSelect";
export default {
  components: { PuzlSelect },
  name: "AddServiceDescription",
  data() {
    return {
      modal: {
        title: '',
        create: false,
        update: false,
      },
      validated: false,
      listServices: {},
      addServiceDescription: {
        status: true,
        description:'',
        service_uuid: '',
        service_id: '',
      },
      loading: false,
      isloadingListServices: false,
    };
  },
  methods: {
    closeModal (name) {
      this.modal[name] = false
    },
    openModal (name) {
      this.modal[name] = true
    },
    handleCreate(){
      this.addServiceDescription = {
        status: true,
        description:'',
        service_uuid: '',
        service_id: '',
      };
      this.modal.title = 'Adicionar Descrição do Serviço';
      this.openModal('create');
    },
    changeService(){
      let serviceId = this.addServiceDescription.service_id;
      let service = this.listServices.filter(function(item) {
        return item.id == serviceId;
      });
      this.addServiceDescription.service_uuid = service[0].uuid;
    },
    storeCreate(){
        this.$Progress.start();
        this.loading = true;
        this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
        this.$axios.post("/commercial/service/description", this.addServiceDescription)
          .then(({data}) => {
            this.$notify({ type: data.error_type, message: data.message });
            this.loading = false;
            this.addServicePrice = {
              status: true,
              description:'',
              service_id:'',
            };
            EventBus.$emit("getServiceDescription");
            this.$emit("getDescription");
            this.closeModal('create');
            this.loading = false;
            this.$Progress.finish();
          })
          .catch((error) => {
            if (error) {
              Exception.handle(error);
            }
            this.$notify({ type: error.response.data.error_type, message: error.response.data.message });
            this.loading = false;
          });
    },
    getServices(){
      this.$Progress.start();
      this.isloadingListServices=true;
      this.listServices = [''];
      this.$axios.get("/configuration/service/active")
        .then(({data}) => {
          this.listServices = data.data;
          this.$Progress.finish();
          this.isloadingListServices=false;
        })
        .catch((error) => {
          if (error) {
            Exception.handle(error);
            this.isloadingListServices=false;
          }
        });
    },
  },
  created(){
    this.getServices();
  },
  mounted() {
    this.$refs.formValidator.validate();
    EventBus.$on("handleCreateServiceDescription", () => {
      this.handleCreate();
    });
  }
};
</script>
<style></style>
