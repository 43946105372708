<template>
<div>
    <modal :show.sync="modal.open">
        <template slot="header">
            <h5 class="modal-title pl-3">{{ modal.title }}</h5>
        </template>
        <div>
            <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
                <form class="needs-validation" @submit.prevent="handleSubmit(handleConcretePieceAdjustmentUpdate)" autocomplete="off">
                    <div>

                        <div class="form-group row m-0 p-0">
                            <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                                Descrição
                                <span class="text-danger">&nbsp;*</span>
                            </label>
                            <div class="col-md-7">
                                <validation-provider rules="required" v-slot="{errors}">
                                    <base-input input-group-classes="input-group-sm">
                                        <input 
                                            type="text" 
                                            v-model="adjustment.description" 
                                            class="form-control form-control-sm" 
                                            :class="errors[0] ? 'is-invalid' : 'is-valid'"/>
                                    </base-input>
                                </validation-provider>
                            </div>
                        </div>

                        <div class="form-group row m-0 p-0">
                            <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                                Peça a Concretar
                                <span class="text-danger">&nbsp;*</span>
                            </label>
                            <div class="col-md-7">
                                <validation-provider rules="required" v-slot="{errors}">
                                    <base-input input-classes="form-control-sm">
                                        <PuzlSelect 
                                            v-model="adjustment.pieces_uuid" 
                                            :items="concrete_pieces" 
                                            customKey="uuid" 
                                            label="description"  
                                            :multiple="true"
                                            :loading="loadingConcretePiece" 
                                            :disabled="loadingConcretePiece" />
                                    </base-input>
                                </validation-provider>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <base-button type="secondary" @click="closeModal()">
                            Cancelar
                        </base-button>
                        <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                            Salvar
                        </base-button>
                    </div>
                </form>
            </validation-observer>
        </div>
    </modal>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import PuzlSelect from "@/components/PuzlSelect"
export default {
    name: "EditConcretePieceAdjustment",
    components: { PuzlSelect },
    data() {
        return {
            modal: {
                title: 'Ajuste Peça a Concretar',
                open: false,
            },
            adjustment: {
                uuid: null,
                description: null,
                pieces_uuid: [],
            },
            loadingConcretePiece: false,
            loadingStore: false,
        }
    },
    computed: {
        ...mapGetters({
            'listAdjustment': 'adjustmentConcretePiece/fetch',
            'concrete_pieces': 'mixConcretePiece/activeItems',
        })
    },
    methods: {
        closeModal() {
            this.modal.open = false
        },
        handleEditModal(uuid) {
            this.$notify({
                type: 'info',
                message: 'Estamos trabalhando em sua solicitação.'
            })
            this.$Progress.start()
            this.loadData()
            this.$store.dispatch('adjustmentConcretePiece/show', uuid)
                .then((response) => {
                    this.adjustment = {
                        uuid: response.data.uuid || '',
                        description: response.data.description || '',
                        pieces_uuid: response.data.pieces || ''
                    }
                    let pieces = []
                    this.adjustment.pieces_uuid.map(function (value, key) {
                        pieces.push(value.uuid)
                    });
                    this.adjustment.pieces_uuid = pieces
                    this.modal.open = true
                    this.$notify({
                        type: response.error_type,
                        message: response.message
                    })
                    this.$Progress.finish()
                }).catch((error) => {
                    if (error.response.status === 422) {
                        let errors = Object.values(error.response.data.errors)
                        errors = errors.flat().toString().split(',').join("<br />")
                        this.$notify({
                            type: 'danger',
                            message: errors
                        })
                    }
                    this.$Progress.finish()
                    this.loadingStore = false
                })
        },
        handleConcretePieceAdjustmentUpdate() {
            this.$Progress.start()
            this.loadingStore = true
            this.$store.dispatch('adjustmentConcretePiece/update', this.adjustment)
                .then(response => {
                    this.loadingStore = false
                    this.modal.open = false
                    this.$Progress.finish()
                    this.$notify({
                        type: response.error_type,
                        message: response.message
                    })
                    EventBus.$emit("updatedConcretePieceAdjustment");
                })
                .catch(error => {
                    if (error.status == 200) {
                        this.$notify({
                            type: 'danger',
                            message: error.data.message
                        })
                        this.$Progress.finish();
                        this.loadingStore = false
                    } else if (error.response && error.response.status === 422) {
                        let errors = Object.values(error.response.data.errors)
                        errors = errors.flat().toString().split(',').join("<br />")
                        this.$notify({
                            type: 'danger',
                            message: errors
                        })
                    }
                    this.$Progress.finish()
                    this.loadingStore = false
                })
        },
        loadData() {
            this.loadingConcretePiece = true;
            this.$store.dispatch('mixConcretePiece/fetchItems').then(() => {
                this.loadingConcretePiece = false;
            });
        },
    },
    mounted() {
        this.$refs.formValidator.validate();
    },
}
</script>

<style scoped>

</style>
