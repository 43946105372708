<template>
  <div v-if="path === 'back'">
    <base-button block :size="size || 'lg'" type="back" class="text-uppercase" :disabled="disabled"
      @click.prevent="$router.go(getGoValue())">
      <img src="/img/icons/circled-left.png" width="20px" height="20px" class="imgBaseButton">
      voltar
    </base-button>
  </div>
  <div v-else>
    <router-link :to="{ path: this.path, }">
      <base-button block :size="size || 'lg'" type="back" class="text-uppercase" :disabled="disabled">
        <img src="/img/icons/circled-left.png" width="20px" height="20px" class="imgBaseButton">
        voltar
      </base-button>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "BackButton",
  props: ['replyBack'],
  props: {
    path: {
      type: String,
      default: "back"
    }, 
    size: {
      type: String,
      default: 'lg',
      description: "Tamanho",
    },
    disabled: {
      type: Boolean,
      default: false,
      description: "Desabilitar",
    },
  },
  data() {
    return {
      prevRoute: null,
    }
  },
  mounted() {
    this.prevRoute = this.$router.history._startLocation
  },
  methods: {
    getGoValue() {
      return (this.replyBack !== undefined)
        ? -parseInt(this.replyBack)
        : -1;
    }
  },
}
</script>

<style scoped></style>
