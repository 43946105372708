<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div class="card-header border-header-success-bottom mt-3 mb-2 p-2">
              <h5 class="h4 mb-0" slot="title">Identificação</h5>
            </div>
            <div>
              <!-- Status -->
              <div>
                <div class="form-group row m-0 p-0">
                  <label class="col-md-5 pb-1 col-form-label form-control-label">
                    Status
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-7 mt-1">
                    <base-switch v-model="item.status" type="success" offText="inativo" onText="ativo"
                      class="success"></base-switch>
                  </div>
                </div>
              </div>
              <!-- Tipo -->
              <div>
                <div class="form-group row m-0 p-0">
                  <label class="col-md-5 pb-1 pr-0 col-form-label form-control-label">
                    Tipo
                    <span class="text-danger mr-2">&nbsp;*</span>
                  </label>
                  <div class="col-md-7 mt-1 pr-4">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <base-input input-group-classes="input-group-sm">
                        <puzl-select
                          :items="[
                            {id: 3, name: 'Produtos e Serviços'},
                            {id: 10, name: 'Taxas e Tributos'},
                          ]" 
                          v-model="item.product_service_hub_type_id"
                          @change="handleChangeType" />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
              </div>
              <!-- Descrição -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-1 col-form-label form-control-label">
                  Descrição
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7 pr-4 mt-1">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-group-classes="input-group-sm">
                      <input v-model="item.description" type="text" class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Categoria -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 col-form-label form-control-label text-left pr-0">
                  Categoria
                  <span class="text-danger">&nbsp;*</span>
                  <span class="btn btn-sm btn-success p-1 ml-1" @click.prevent="handleCreateCategory()"><i
                      class="fas fa-plus"></i> Novo</span>
                </label>
                <div class="col-md-7 pr-4 mt-1">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-group-classes="input-group-sm">
                      <puzl-select :items="categories" v-model="item.category_id" :loading="loadingCategory"
                        :disabled="loadingCategory" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Unid. de Entrada -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-2 col-form-label form-control-label">
                  Unid. de Entrada
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7 pr-4 mt-1">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-group-classes="input-group-sm">
                      <input v-model="item.input_unit" type="text" class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Observação -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-1 col-form-label form-control-label"> Observação </label>
                <div class="col-md-7 pr-4 mt-0">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="item.observation" type="text" class="form-control form-control-sm" maxlength="250" />
                  </base-input>
                </div>
              </div>
              <div>
                <div class="card-header border-header-danger-bottom mt-3 mb-2 p-2">
                  <h5 class="h4 mb-0" slot="title">Dados complementares</h5>
                </div>
                <div>
                  <!-- NCM -->
                  <div class="form-group row m-0 p-0">
                    <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label"> NCM </label>
                    <div class="col-md-7 mb-1 pr-4">
                      <base-input input-group-classes="input-group-sm">
                        <input v-model="item.ncm" type="text" class="form-control form-control-sm" />
                      </base-input>
                    </div>
                  </div>
                  <!-- CFOP -->
                  <div class="form-group row m-0 p-0">
                    <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label"> CFOP </label>
                    <div class="col-md-7 mb-1 pr-4">
                      <base-input input-group-classes="input-group-sm">
                        <input v-model="item.cfop" type="text" class="form-control form-control-sm" />
                      </base-input>
                    </div>
                  </div>
                </div>
                <div>
                  <!-- CNAE -->
                  <div class="form-group row m-0 p-0">
                    <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label"> CNAE </label>
                    <div class="col-md-7 mb-1 pr-4">
                      <base-input input-group-classes="input-group-sm">
                        <input v-model="item.cnae" type="text" class="form-control form-control-sm" />
                      </base-input>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <base-button type="secondary" @click="closeModal('create')"> Cancelar </base-button>
                <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore"> Salvar
                </base-button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
    <CreateProductServiceCategory @createdCategory="setCategory" ref="modalCreateCategory" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
("@/helpers");

import { formatErrorValidation } from "@/plugins";
import CreateProductServiceCategory from "../Category/Shared/_ModalCreate.vue";
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "ModalCreateProductService",
  components: { CreateProductServiceCategory, PuzlSelect },
  props: {
    product_service_hub_type_id: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      modal: {
        title: "Adicionar Despesa",
        create: false,
      },
      item: {
        product_service_hub_type_id: null,
        status: true,
        code: null,
        description: "",
        category_id: null,
        input_unit: "",
        observation: "",
        ncm: "",
        cfop: "",
        cnae: "",
      },
      loadingCategory: false,
      loadingStore: false,
      loadingChartAccount: true,
      code: null,
      entity_ein: null,
      price: null,
    };
  },
  computed: {
    ...mapGetters({
      categories: "productServiceCategory/fetch",
    })
  },
  mounted() {
    this.$refs.formValidator.validate();
    this.loadingChartAccount = true;
    this.$store.dispatch("chartAccount/fetchItems").then((response) => {
      this.loadingChartAccount = false;
    });
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    handleCreateModal() {
      this.code = null;
      this.entity_ein = null;
      this.price = null;
      this.item = {
        // 1: Serviço 3: Produto 10: Taxa
        product_service_hub_type_id: [1, 3, 10].includes(this.product_service_hub_type_id)
          ? this.product_service_hub_type_id
          : null,
        status: true,
        code: null,
        description: "",
        category_id: null,
        input_unit: "",
        observation: "",
        ncm: "",
        cfop: "",
        cnae: "",
      };
      this.getCode();
      if(this.product_service_hub_type_id) {
        this.handleChangeType();
      }
      this.modal.create = true;
    },
    store() {
      this.$Progress.start();
      this.loadingStore = true;
      /* Caso seja um produto avulso */
      if (!this.code) {
        this.$store
          .dispatch("productService/add", this.item)
          .then((response) => {
            this.loadingStore = false;
            this.$Progress.finish();
            this.$notify({
              type: response.error_type,
              message: response.message,
            });
            this.closeModal();
          })
          .catch((error) => {
            if (error.status === 200) {
              this.$notify({
                type: "danger",
                message: error.data.message,
              });
              this.$Progress.finish();
              this.loadingStore = false;
            } else if (error.response.status === 422) {
              let errors = formatErrorValidation(error.response.data.errors);
              this.$notify({
                type: "danger",
                message: errors,
              });
              this.$Progress.finish();
              this.loadingStore = false;
            }
          });
      } else {
        /* Caso seja um produto de nota fiscal */
        this.$store
          .dispatch("productService/createAndRegister", { product: this.item, code: this.code, entity_ein: this.entity_ein, price: this.price })
          .then((response) => {
            this.loadingStore = false;
            this.$Progress.finish();
            this.$notify({
              type: response.error_type,
              message: response.message,
            });
            this.$emit("created", response.data, this.item.index);
            this.closeModal();
          })
          .catch((error) => {
            if (error.status === 200) {
              this.$notify({
                type: "danger",
                message: error.data.message,
              });
              this.$Progress.finish();
              this.loadingStore = false;
            } else if (error.response.status === 422) {
              let errors = formatErrorValidation(error.response.data.errors);
              this.$notify({
                type: "danger",
                message: errors,
              });
              this.$Progress.finish();
              this.loadingStore = false;
            }
          });
      }
    },
    handleChangeType() {
      this.loadingCategory = true;
      this.item.category_id = null;
      this.item.ncm = null;
      this.item.cfop = null;
      this.item.cnae = null;
      this.$store.dispatch("productServiceCategory/getCategoriesByType", this.item.product_service_hub_type_id).then((response) => {
        this.loadingCategory = false;
      });
    },
    handleChangeStatus(status) {
      this.item.status = status;
    },
    handleCreateCategory() {
      this.$refs.modalCreateCategory.handleCreateModal();
    },
    setCategory(category) {
      this.loadingCategory = true;
      this.$store.dispatch("productServiceCategory/getCategoriesByType", this.item.product_service_hub_type_id).then((response) => {
        if (this.item.product_service_hub_type_id === category.product_service_hub_type_id) {
          this.item.category_id = category.id;
        }
        this.loadingCategory = false;
      });
    },
    getCode() {
      this.$store.dispatch("productService/getCode").then((response) => {
        if (response.data != null) {
          this.item.code = parseInt(response.data) + 1;
        } else {
          this.item.code = 1;
        }
      });
    },
  },
};
</script>

<style scoped></style>
