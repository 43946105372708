<template>
  <div>
    <modal :show.sync="modal.create" size="md">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div v-if="$_company_plant_buyer.company_plant">
        <div class="pr-2 pl-2 mb-1">
          <div class="border-left border-success border-3 mt-2 pl-2">
            <h4 class="mb-0 col-12 pl-2">{{ $_company_plant_buyer.company_plant.name }}</h4>
            <h4 class="mb-0 col-12 font-weight-normal pl-2">{{ $_company_plant_buyer.issuer.ein }}</h4>
          </div>
        </div>
        <div class="pr-2 pl-2 mb-3">
          <div class="border-left border-primary border-3 mt-2 pl-2">
            <h4 v-show="nfe_quantity > 0" class="mb-0 col-12 pl-2">{{ nfe_quantity }} NF-e</h4>
            <h4 v-show="cte_quantity > 0" class="mb-0 col-12 font-weight-normal pl-2">{{ cte_quantity }} CTe</h4>
          </div>
        </div>

        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div class="p-2">
              <!-- Adotar saída da NFe -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label">
                  Adotar saída da NFe
                  <!--<span class="text-danger">&nbsp;*</span>-->
                </label>
                <div class="col-md-6 pt-2">
                  <base-switch v-model="keep_exit_date" type="success" offText="inativo" onText="ativo" class="success"></base-switch>
                </div>
              </div>

              <!-- Data de entrada -->
              <div v-if="!keep_exit_date" class="form-group row m-0 p-0">
                <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label">
                  Data de entrada
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-6 pt-2">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input-date-time-picker :disableMaxDate="max_date" v-model="entry_date" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="modal-footer d-flex justify-content-center mt-2">
                <base-button type="secondary" @click="closeModal('create')"> CANCELAR </base-button>
                <base-button type="success" native-type="submit" v-bind:disabled="invalid"> BAIXAR </base-button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import moment from "moment";

export default {
  components: { InputDateTimePicker },
  name: "ModalImportEntryLaunch",
  data() {
    return {
      modal: {
        title: "Lançar xml",
        create: false,
      },
      payload: null,
      keep_exit_date: true,
      entry_date: null,
      nfe_quantity: 0,
      cte_quantity: 0,
      max_date : false,
    };
  },
  computed: {
    ...mapGetters({
      $_company_plant_buyer: "companyPlantBuyer/show",
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal(payload) {
      this.payload = { ...payload };
      payload = Array.isArray(payload) ? payload : [payload];
      this.cte_quantity = payload.filter((item) => item.product_service_hub_type_id === 4).length;
      this.nfe_quantity = payload.length - this.cte_quantity;
      this.keep_exit_date = true;
      this.entry_date = null;
      this.max_date = new Date(this.$helper.parseDate(moment(), 'YYYY-MM-DD HH:mm:ss'));
      this.modal.create = true;
    },
    store() {
      this.entry_date = !this.keep_exit_date ? this.entry_date : null;
      if (this.cte_quantity + this.nfe_quantity === 1) {
        this.$parent.store(this.payload, this.entry_date);
      } else {
        this.$parent.storeMany(this.entry_date);
      }
      this.closeModal();
    },
  },
};
</script>

<style></style>
