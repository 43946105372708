<template>
  <div class="row align-items-center pb-0 w-100">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  name: "SpinnerPuzl",
  components: {},
  data() {
    return {};
  },
  updated() {}
};
</script>

<style scoped>
.loader {
  border: 4px solid #f3f3f3;
  /* Light grey */
  border-top: 4px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: relative;
  left: 50%;
  top: 50%;
  z-index: 1;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
