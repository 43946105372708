<template>
  <div>
    <modal class="bill-modal-create" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div v-if="entity">
        <div class="p-2">
          <h4 class="mb-3 text-danger">
            Total :&nbsp;
            <b class="numeric h3 text-danger">{{ total_value | currency }} </b>
          </h4>
          <div class="border-left border-3 border-light pr-1 mt-0 mb-4">
            <div class="col-md-12">
              <h4 class="m-0">{{ entity.entity_name }}</h4>
              <h4 class="m-0 font-weight-normal">{{ entity.document }}</h4>
            </div>
          </div>
          <div v-for="(item, index) in $_items" :key="index">
            <div class="border-left border-3 border-danger pr-1 mt-3 mb-3">
              <div class="row m-0">
                <h4 class="col-6 m-0">{{ item.code }}</h4>
                <h4 class="col-6 m-0 text-right">
                  <span class="numeric">{{ item.value | currency }}</span>
                  <small> {{ `(${item.index}/${item.last_index})` }}</small>
                </h4>
                <div class="col-6">
                  <h5 class="m-0">Vencimento : {{ item.due_date | parseDate("DD MMM YYYY") }}</h5>
                  <h5 class="m-0">{{ item.payment_method_name }} | {{ item.payment_term_name }}</h5>
                </div>
                <div class="col-6 d-flex justify-content-end">
                  <template v-if="item.invoices && item.invoices.length">
                    <div v-for="(invoice, invoiceIndex) in item.invoices" :key="invoiceIndex">
                      <a href="#" @click.prevent="downloadInvoice(invoice.id)">
                        <i style="font-size: 24px !important" class="text-danger fas fa-file-pdf" />
                        <small class="text-default ml-1" style="text-transform: none">
                          nº <b class="text-default numeric pr-2">{{ invoice.id }}</b>
                        </small>
                      </a>
                      <a
                        @click.prevent="nfseButtonClick(invoice.link_pdf)"
                        href="#"
                        :title="invoice.number"
                        v-if="invoice.nfse_status && invoice.nfse_status === 'Autorizada'"
                      >
                        <img style="height: 35px" src="/img/icons/nfe_blue.png" />
                        <small class="text-default" style="text-transform: none">
                          nº <b class="text-primary numeric">{{ invoice.number }}</b>
                        </small>
                      </a>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ModalOverdueInstallments",
  data() {
    return {
      modal: {
        title: "Recebimentos atrasados",
        create: false,
        total_value: 0,
        entity: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      $_items: "billReceive/fetch",
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal(entity) {
      let loader = this.$loading.show();
      this.total_value = 0;
      this.entity = entity;
      this.$store.dispatch("billReceive/getOverdueInstallmentsByEntity", entity.id).then(() => {
        this.modal.create = true;
        this.total_value = this.$_items.reduce(function (a, item) {
          return a + parseFloat(item.value);
        }, 0);
        loader.hide();
      });
    },
    nfseButtonClick(link) {
      this.$notify({ type: "success", message: "Download iniciado com sucesso!" });
      window.open(link, "_parent");
    },
    downloadInvoice(id) {
      let loader = this.$loading.show();
      this.$store
        .dispatch("billingInvoice/download", {
          id: id,
        })
        .then((response) => {
          let blob = new Blob([response], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "report.pdf");
          window.open(link, "_blank");
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};
</script>
