<template>
  <div>
    <modal size="lg"
      :show.sync="modal.create"
      :backgroundColor="'#FEF9F2'"
      :showClose="false"
    >
      <template>
        <template slot="header">
          <div class="d-flex align-items-center container-fluid" style="gap: 15px;">
            <div>
              <img src="/img/icons/create-new.png" alt="create-new" width="24px">
            </div>
            <span class="text-title">
              {{ modal.title }}
            </span>
            <div class="ml-auto">
              <img class="pointer" @click="closeModal" src="/img/icons/icons8/ios/close-window.png" alt="close-window" width="17px">
            </div>
          </div>
        </template>
        <div>
          <form class="needs-validation" @submit.prevent="handleSubmit(update)" autocomplete="off">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12" style="display: flex; align-items: center;">
                  <h4 class="m-0 mx-3" style="font-size: 16px; font-weight: 500; color: #2B2D32;">
                      Identificação
                  </h4>
                  <hr class="ml-3" style="width: 100%;">
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                  <div class="col-md-5 my-1 pr-4">
                    <div class="d-flex">
                      <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                          CONTA
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7 mb-2 pr-4">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm" :disableBoxShadow="true">
                        <input
                            @keyup="getCodeRealtime"
                            v-model="$_account.ordered_code_name"
                            type="text"
                            class="form-control form-control-sm"
                            disabled
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
              <div class="row">
                <div class="col-12" style="display: flex; align-items: center;">
                  <h4 class="m-0 mx-3" style="font-size: 16px; font-weight: 500; color: #2B2D32;">
                      Modificação
                  </h4>
                  <hr class="ml-3" style="width: 100%;">
                </div>
              </div>
              <div class="form-group">
                <div class="form-group row m-0 p-0">
                  <div class="col-md-5 my-1 pr-4">
                    <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                        COMPÕE O DRE
                    </div>
                  </div>
                  <div v-if="$_account.dre" class="col-md-7 mb-2 pr-4">
                    <span class="d-flex float-right align-items-center button-dre-success py-2 px-4" @click="$_account.dre = false;">
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </span>
                  </div>
                  <div v-else class="col-md-7 mb-2 pr-4">
                    <span class="d-flex float-right align-items-center button-dre-danger py-2 px-4" @click="$_account.dre = true;">
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </span>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-2">
                  <div class="col-md-5 my-1 pr-4">
                    <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                        PLANO DE CONTAS
                    </div>
                  </div>
                  <div class="col-md-7 mb-2 pr-4">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                          <PuzlSelect
                            v-model="$_account.chart_account_parent_uuid"
                            :items="grouped_accounts"
                            customKey="uuid"
                            @change="getLastCode"
                            :disableBoxShadow="true"
                          />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-2">
                  <div class="col-md-5 my-1 pr-4">
                    <div class="d-flex">
                      <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                          CÓDIGO
                      </div>
                      <span v-if="ordered_code != 0" class="ml-auto align-self-center account-ordered-code mb-1">
                        {{ ordered_code }}
                      </span>
                    </div>
                  </div>
                  <div class="col-md-7 mb-2 pr-4">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm" :disableBoxShadow="true">
                        <input
                            @keyup="getCodeRealtime"
                            v-model="$_account.code"
                            type="text"
                            class="form-control form-control-sm"
                            :disableBoxShadow="true"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0">
                  <div class="col-md-5 my-1 pr-4">
                    <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                        NOME
                    </div>
                  </div>
                  <div class="col-md-7 mb-2 pr-4">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm" :disableBoxShadow="true">
                        <input
                            v-model="$_account.name"
                            type="text"
                            class="form-control form-control-sm"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0">
                  <div class="col-md-5 my-1 pr-4">
                      <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                          CÓDIGO DE INTEGRAÇÃO
                      </div>
                  </div>
                    <div class="col-md-7 mb-2 pr-4">
                      <validation-provider rules="required">
                        <base-input input-classes="form-control-sm" :disableBoxShadow="true">
                            <input
                                v-model="$_account.integration_code"
                                inputmode="numeric"
                                v-mask="['####']"
                                class="form-control form-control-sm"
                            />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                <div class="row">
                  <div class="col-12" style="display: flex; align-items: center;">
                    <h4 class="m-0 mx-3" style="font-size: 16px; font-weight: 500; color: #2B2D32;">
                        Histórico
                    </h4>
                    <hr class="ml-3" style="width: 100%;">
                  </div>
                </div>
                <div v-show="$_account.updated_by_user_id" class="form-group row m-0 p-0">
                  <div class="col-md-5" style="display: flex; align-items: center;">
                    <div class="pb-0 form-control-label" style="font-size: 14px; font-weight: 400;color: #2B2D32;">
                        ÚLTIMA ALTERAÇÃO
                    </div>
                      <hr class="ml-2" style="width: 90%;">
                  </div>
                  <div class="row col-md-7 align-items-center">
                    <span class="ml-2" style="font-size: 14px; font-weight: 400;color: #2B2D32;">
                      {{ $_account.updated_at | parseDate('DD MMM YYYY - HH:mm') }}
                    </span>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mt-n2">
                  <div class="col-md-5" style="display: flex; align-items: center;">
                    <div class="pb-0 form-control-label" style="font-size: 14px; font-weight: 400;color: #2B2D32;">
                        CRIAÇÃO
                    </div>
                      <hr class="ml-3 mr-n3" style="width: 100%;">
                  </div>
                  <div class="row col-md-7 align-items-center">
                    <span class="ml-3" style="font-size: 14px; font-weight: 400;color: #2B2D32;">
                      {{ $_account.created_at | parseDate('DD MMM YYYY - HH:mm') }}
                    </span>
                  </div>
                </div>
                <div class="modal-footer">
                  <div class="d-flex pr-3" style="gap: 18px;">
                    <div class="d-flex align-items-center py-1 px-4 button-cancel" style="gap: 5px;" @click="handleDelete($_account.id)">
                      <img src="/img/icons/icons8/ios/trash_can.png" alt="trash_can" width="16px">
                      <span style="font-weight: 500;">
                        Excluir
                      </span>
                    </div>
                    <div class="d-flex align-items-center py-1 px-4 button-cancel" style="gap: 5px;" @click="closeModal">
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="16px">
                      <span style="font-weight: 500;">
                        Cancelar
                      </span>
                    </div>
                    <div class="d-flex align-items-center py-1 px-4 button-save" style="gap: 5px;" @click.prevent="update">
                      <img src="/img/icons/icons8/ios/save_success.png" alt="save_success" width="16px">
                      <span style="font-weight: 500;">
                        Salvar
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </template>
    </modal>
    <ModalRedirectEntryLaunch ref="modalRedirectEntryLaunch"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
import ModalRedirectEntryLaunch from './_ModalRedirectEntryLaunch'

export default {
  name: "ModalEdit",
  components: {
    PuzlSelect,
    ModalRedirectEntryLaunch,
  },
  computed: {
    ...mapGetters({
      $_account: "managerialChartAccount/show",
    })
  },
  data() {
    return {
      modal: {
        title: "Editar Plano de Conta",
        create: false,
      },
      ordered_code: "",
      grouped_accounts: [],
      isParent: false
    }
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    handleEditModal(id, isParent) {
      this.modal.create = true;
      this.refreshFields();
      this.getAccount(id);
      this.loadGroupedAccounts();
      this.isParent = isParent;
    },
    loadGroupedAccounts() {
      this.$store.dispatch('managerialChartAccount/getGrouped',{}).then((response) => {
        response.data.forEach(item => this.grouped_accounts.push(item));
      })
    },
    getCodeRealtime(event) {

      if(event) {
        const code = event.key;

        // verifica se tecla pressionada é um número, ignorando a tecla backspace
        if(!this.isValidCode(code) && (code !== 'Backspace' || event.keyCode !== 8)) {
            event.preventDefault();
            this.$_account.code = this.$_account.code.substring(0, this.$_account.code.length - 1);
            this.$notify({
              type: "danger",
              message: "Favor Informar Apenas Números!"
            });
        }
      }

      this.$store.dispatch('managerialChartAccount/getOrderedCode', {
        uuid: this.$_account.chart_account_parent_uuid,
        code: this.$_account.code
      }).then((response) => {
        this.ordered_code = response.data
      }).catch((error) => {
        this.ordered_code = "";
      });
    },
    getLastCode() {
      this.$store.dispatch('managerialChartAccount/getLastCode', {
        uuid: this.$_account.chart_account_parent_uuid,
      }).then((response) => {
        this.$_account.code = response.data
        this.getCodeRealtime();
      }).catch((error) => {
        this.ordered_code = "";
      });
    },
    update() {
      this.$Progress.start();
      this.$store
        .dispatch('managerialChartAccount/update', this.$_account)
        .then((response) => {
          if(this.$_account.dre && this.isParent) {
            this.$swal.fire({
              title: 'Você gostaria de atualizar todos os sub grupos?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Sim, atualizar!',
              cancelButtonText: 'Não, mantenha',
              customClass: {
                confirmButton: 'btn btn-success btn-fill',
                cancelButton: 'btn btn-danger btn-fill',
              },
              buttonsStyling: false,
            })
            .then((result) => {
              if (result.isConfirmed) {
                this.updateChildrens(this.$_account.id);
              }
              this.$notify({
                type: "success",
                message: response.message
              });
              this.$emit('init');
            });
          } else {
            this.$notify({
              type: "success",
              message: response.message
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 406) {
            this.$notify({ type: "danger", message: error.response.data.message });
          } else if (error.response.status === 422) {
            this.$notify({ type: "danger", message: error.response.data.message });
          }
        }).finally(() => {
          this.$Progress.finish();
          this.$emit('init');
          this.closeModal();
        });
    },
    getAccount(id) {
      this.$Progress.start();
      this.$store.dispatch('managerialChartAccount/show', id)
        .then((response) => {
          this.modal.create = true;
        }).catch((error) => {
          this.$notify({
            type: error.data.error_type,
            message: error.data.message,
          });
        }).finally(() => {
            this.$Progress.finish();
        });
    },
    handleDelete(id){
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed){
          this.$Progress.start();
          this.$store.dispatch('managerialChartAccount/destroy', id)
            .then((response) => {
              this.closeModal();
              this.$notify({
                type: "success",
                message: response.message
              });
              this.$emit('init');
            }).catch((error) => {
              if(error.response.status === 406) {
                this.$notify({ type: "danger", message: error.response.data.message });
                if(error.response.data.message.includes('despesas vinculadas')) {
                  this.handleRedirectEntryLaunch(id);
                }
              }
            })
        }
      }).finally(() => this.$Progress.finish())
    },
    updateChildrens(id) {
      this.$store.dispatch('managerialChartAccount/updateDreChildrensTrue', id);
    },
    refreshFields() {
      this.ordered_code = "";
      this.isParent = null;
      this.grouped_accounts = [{
        id: 0,
        uuid: null,
        name: 'INICIAL',
        code: 0,
        chart_account_parent_uuid: null,
        integration_code: null,
      }];
    },
    isValidCode(code) {
      // validação regex para permitir apenas números
      const regex = /^[0-9]*$/;
      return regex.test(code);
    },
    handleRedirectEntryLaunch(id) {
      this.$refs.modalRedirectEntryLaunch.openModal(id);
    }
  }
}
</script>
<style scoped>
.text-title {
  font-size: 18px;
  font-weight: 500;
  color: #2B2D32;
}
.button-dre-success {
  background-color: #F2F7F3;
  border-radius: 8px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #149E57;
  font-weight: 400;
}
.button-dre-danger {
  background-color: #F4C7C3;
  border-radius: 8px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #DB4539;
  font-weight: 400;
}
.account-ordered-code {
  padding: 3px 10px;
  background-color: #E8E8E8;
  border: 0.5px solid #CFCFCF;
  border-radius: 4px;
  color: #606062;
}
.button-cancel {
  cursor: pointer;
  border: 0.5px solid #DB4539;
  border-radius: 8px;
  color: #DB4539;
}
.button-save {
  cursor: pointer;
  border: 0.5px solid #149E57;
  border-radius: 8px;
  color: #149E57;
}
</style>