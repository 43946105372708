<template>
<div>
  <div class="container-fluid">

    <div class="row card-wrapper" v-show="loadingSkeleton">
      <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
    </div>

    <div class="row align-items-center"  v-if="!group_permissions.length && !loadingSkeleton">
      <div class="col-md-12 text-center">
        <div class="card p-4">
          Sem dados cadastrados!
        </div>
      </div>
    </div>


    <div class="row card-wrapper" v-if="group_permissions.length && !loadingSkeleton">
      <div class="col-lg-4" v-for="(group_permission, index) in group_permissions" :key="index">
        <!-- Basic with action button -->
        <card class="card-border-top-success">
          <!-- Card header -->
          <div class="row align-items-center mb-3">
            <div class="col-8">
              <!-- Title -->
              <h5 class="h3 mb-0">{{ group_permission.name }}</h5>
            </div>

            <div class="col-4 text-right">
              <base-dropdown menuOnRight>
                <base-button
                  slot="title-container"
                  type="primary"
                  class="dropdown-toggle p-2 rounded m-0"
                >
                  <i class="fas fa-cog"></i>
                </base-button>
                <div class="dropdown-divider p-0 m-0"></div>
                <a class="dropdown-item" @click="handleEdit(group_permission.uuid)">
                  <i class="fas fa-edit text-warning"></i>
                  Editar
                </a>
                <div class="dropdown-divider p-0 m-0"></div>
                <a class="dropdown-item" @click="handleDelete(group_permission.uuid)">
                  <i class="fas fa-times text-danger"></i>
                  Excluir
                </a>
              </base-dropdown>
            </div>
          </div>
          <!-- Card body -->
        </card>
      </div>
    </div>
  </div>
  <Edit ref="editGroupPermission" />
</div>
</template>

<script>
import {mapGetters} from 'vuex'
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import Edit from './_Edit'
export default {
  name: "ListGroupPermission",
  components: {
    SkeletonPuzlGrid,
    Edit,
  },
  data () {
    return {
      loadingSkeleton: false,
    }
  },
  computed: {
    ...mapGetters({
      'group_permissions': 'authGroupPermission/fetch',
    }),
  },
  methods:{
    fetch () {
      this.loadingSkeleton = true
      this.$store.dispatch('authGroupPermission/fetchItems')
        .then( response =>{
          this.loadingSkeleton = false
        })
        .catch( error => {
          this.loadingSkeleton = false
        })
    },
    handleEdit(uuid){
      this.$refs.editGroupPermission.handleEditModal(uuid)
    },
    handleDelete(uuid){
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed){
          this.$Progress.start();
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          this.$store.dispatch('authGroupPermission/destroy', uuid)
          this.$Progress.finish();
        }
      }).catch(() => this.$Progress.finish())
    }
  },
  mounted() {
    this.fetch()
  },
}
</script>

<style scoped>

</style>
