export const ScheduleTravelEnum = Object.freeze({
    RELEASED: 0, // Liberada
    FINISHED: 1, // Concluída
    LOADING: 2, // Carregando
    WAITING_FOR_RELEASE: 4, // Aguardando liberação do cliente através da regra da programação
    IN_PROGRESS: 5, // Em andamento
    CANCELED: 6, // Cancelada
    UNREALIZED: 7, // Não realizada
    HAS_PENDENCY: 8, // Possui pendência
    SELLER_REQUEST: 9, // Solicitação do vendedor
});
