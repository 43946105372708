<template>
<div>
  <div class="container-fluid">

    <div class="row card-wrapper" v-show="loadingSkeleton">
      <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
    </div>

    <div class="row align-items-center"  v-if="!puzl_admixtures.length && !loadingSkeleton">
      <div class="col-md-12 text-center">
        <div class="card p-4">
          Sem dados cadastrados!
        </div>
      </div>
    </div>


    <div class="row card-wrapper" v-if="puzl_admixtures.length && !loadingSkeleton">
      <div class="col-lg-4" v-for="(puzl_admixture, index) in puzl_admixtures" :key="index">
        <!-- Basic with action button -->
        <card :class="puzl_admixture.status ? 'card-border-top-success' : 'card-border-top-danger'">
          <!-- Card header -->
          <div class="row align-items-center mb-3">
            <div class="col-8">
              <!-- Title -->
              <h5 class="h3 mb-0">
                {{ puzl_admixture.cmc_group.group_name }}

                <el-popover title="" placement="right" class="p-2">
                  <img
                    class="photo"
                    :src="image(puzl_admixture.url_image)"
                  />
                  <base-button outiline slot="reference" size="md" type="secundary" class="p-0 m-0 btn-rounded">
                    <i class="fas fa-image"></i>
                  </base-button>
                </el-popover>
              </h5>
              <h6 class="h3  small">{{ puzl_admixture.technical_explanation }}</h6>
            </div>

            <div class="col-4 text-right">
              <base-dropdown menuOnRight>
                <base-button
                  slot="title-container"
                  type="primary"
                  class="dropdown-toggle p-2 rounded m-0"
                >
                  <i class="fas fa-cog"></i>
                </base-button>
                <a
                  class="dropdown-item"
                  @click="handleEditPuzlAdmixture(puzl_admixture.uuid)"
                >
                  <i class="fas fa-edit text-warning"></i>
                  Editar
                </a>
<!--                <div class="dropdown-divider p-0 m-0"></div>-->
<!--                <a-->
<!--                  class="dropdown-item"-->
<!--                  @click="handleDeletePuzlAdmixture(puzl_admixture.uuid)"-->
<!--                >-->
<!--                  <i class="fas fa-times text-danger"></i>-->
<!--                  Excluir-->
<!--                </a>-->
              </base-dropdown>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <h4 class="h3 lead">
                De: {{ puzl_admixture.dosage_range_start | formatQuantity }}&nbsp;kg/m<sup>3</sup>
              </h4>

              <h4 class="h3 m-0 lead p-0">
                Até: {{ puzl_admixture.dosage_range_end | formatQuantity }}&nbsp;kg/m<sup>3</sup>
              </h4>
            </div>
          </div>
          <!-- Card body -->
        </card>
      </div>
    </div>
  </div>
  <edit-puzl-admixture ref="editPuzlAdmixture"></edit-puzl-admixture>
</div>
</template>

<script>
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import {mapGetters} from 'vuex'
import EditPuzlAdmixture from './_Edit'
export default {
  name: "List",
  components: {
    SkeletonPuzlGrid,
    EditPuzlAdmixture,
  },
  data () {
    return {
      loadingSkeleton: false,
    }
  },
  filters: {
    formatQuantity: function (value) {
      return value.toString().replace('.', ',')
    },
  },
  computed: {
    ...mapGetters({
      'puzl_admixtures': 'mixPuzlAdmixture/fetch',
    }),
  },
  methods:{
    image(image){
      return image
    },
    fetch () {
      this.loadingSkeleton = true
      this.$store.dispatch('mixPuzlAdmixture/fetchItems')
        .then( response =>{
          this.loadingSkeleton = false
        })
        .catch( error => {
          this.loadingSkeleton = false
        })
    },
    handleEditPuzlAdmixture(uuid){
      this.$refs.editPuzlAdmixture.handleEditModal(uuid)
    },
    handleDeletePuzlAdmixture(uuid){
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed){
          this.$Progress.start();
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          this.$store.dispatch('mixPuzlAdmixture/destroy', uuid)
          .then(() => {})
          .catch(error => {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            });
          })
          this.$Progress.finish();
        }
      }).catch(() => this.$Progress.finish())
    }
  },
  mounted() {
    this.fetch()
  },
  created () {

  }
}
</script>

<style scoped>

</style>
