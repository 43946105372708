<template>
  <div>
    <div class="container-fluid">
      <div class="row card-wrapper mt-5" v-if="loading">
        <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <div class="row card-wrapper mt-5">
        <div
          v-if="!loading"
          class="col-lg-4 col-md-6"
          v-for="(item, index) in items"
          :key="index"
        >
          <div class="card main-card mb-3">
            <div class="p-3 px-4">
              <div class="row">
                <div
                  class="col-10 d-flex justify-content-start align-items-center"
                >
                  <div class="mr-2">
                    <label
                      class="app-badge"
                      :class="{
                        'green-backgroud-light': item.active,
                        'red-backgroud-light': !item.active,
                      }"
                      style="width: 130px"
                    >
                      <input
                        type="checkbox"
                        v-model="item.active"
                        @input="handleUpdateStatus(item.uuid, $event)"
                      />
                      <span
                        style="gap: 4px"
                        class="d-flex justify-content-center align-items-center"
                        :class="{
                          'green-text-badge': item.active,
                          'red-text-badge': !item.active,
                        }"
                      >
                        <img
                          :src="
                            item.active
                              ? '/img/icons/ok--v1.png'
                              : '/img/icons/icons8/ios/cancel_danger.png'
                          "
                          alt="..."
                          width="14px"
                        />
                        {{ item.active ? "Ativo" : "Inativo" }}
                      </span>
                    </label>
                  </div>
                  <div class="mt-n2 mr-2">
                    <el-popover
                      class="p-0 pr-0 mr-1"
                      placement="right"
                      trigger="click"
                    >
                      <div class="pt-2 pl-3 pr-2 pb-4">
                        <span class="text-left text-dark">
                          <strong>Criação -</strong> {{ item.created_at | parseDate('DD/MM/YYYY H:mm') }} |
                          {{ item.created_by_user_name }}</span
                        >
                        <br />
                        <span class="text-left text-dark"
                          ><strong>Última alteração -</strong>
                          {{ item.created_at | parseDate('DD/MM/YYYY H:mm') }} |
                          {{ item.updated_by_user_name }}</span
                        >
                      </div>
                      <base-button
                        slot="reference"
                        class="p-0 m-0 shadow-none"
                        outiline
                        size="sm"
                        type="secundary"
                      >
                        <img
                          src="/img/icons/icons8/ios/user-male-circle--v1.png"
                          width="24px"
                          height="24px"
                        />
                      </base-button>
                    </el-popover>
                  </div>
                  <div class="mt-n2 mr-2">
                    <el-popover
                      class="p-0 pr-0 mr-1"
                      placement="right"
                      trigger="click"
                      style=""
                    >
                      <div class="pt-2 pl-3 pr-2 pb-4">
                        <b class="text-dark"> Informações</b>
                        <ul>
                          <li>
                            <span>Informação de KM</span>
                            <div class="ck-divider"></div>
                            <input
                              type="checkbox"
                              :checked="item.km_information"
                              disabled
                            />
                          </li>
                          <li>
                            <span>Informação de Horímetro</span>
                            <div class="ck-divider"></div>
                            <input
                              type="checkbox"
                              :checked="item.hour_meter_information"
                              disabled
                            />
                          </li>
                          <li>
                            <span>Informação de Motorista</span>
                            <div class="ck-divider"></div>
                            <input
                              type="checkbox"
                              :checked="item.driver_information"
                              disabled
                            />
                          </li>
                        </ul>
                      </div>
                      <base-button
                        slot="reference"
                        class="p-0 m-0 shadow-none"
                        outiline
                        size="sm"
                        type="secundary"
                      >
                        <img
                          src="/img/icons/info.png"
                          width="24px"
                          height="24px"
                        />
                      </base-button>
                    </el-popover>
                  </div>
                </div>
                <div
                  class="col-2 d-flex justify-content-end align-items-center"
                >
                  <base-dropdown menuOnRight>
                    <div
                      slot="title-container"
                      class="dropdown-toggle rounded m-0"
                    >
                      <img
                        width="32"
                        src="/img/icons/icons8/ios/settings--v1_primary.png"
                      />
                    </div>
                    <a
                      class="dropdown-item text-dark"
                      style="align-items: center; display: flex"
                      @click.prevent="handleUpdate(item.uuid)"
                    >
                      <img width="18" src="/img/icons/edit.png" />
                      Editar
                    </a>
                    <div class="dropdown-divider p-0 m-0" />
                    <a
                      class="dropdown-item text-dark"
                      style="align-items: center; display: flex"
                      @click.prevent="handleGenerateChecklist(item.uuid)"
                    >

                      <img
                        width="18"
                        src="/img/icons/icons8/ios/add-primary.png"
                      />
                      Gerar checklist
                    </a>
                    <div class="dropdown-divider p-0 m-0" />
                    <a
                      class="dropdown-item text-dark"
                      style="align-items: center; display: flex"
                      @click.prevent="handleItemChecklistRedirect(item.uuid)"
                    >
                      <img
                        width="18"
                        src="/img/icons/icons8/ios/checklist-success.png"
                      />
                      Itens de checklist
                    </a>
                    <div class="dropdown-divider p-0 m-0" />
                    <a
                      class="dropdown-item text-dark"
                      style="align-items: center; display: flex"
                      @click.prevent="handleRemove(item.uuid)"
                    >
                      <img width="18" src="/img/icons/delete.png" />
                      Excluir
                    </a>
                  </base-dropdown>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-10">
                  <h4
                    class="new-default-black-font m-0 p-0"
                    style="font-size: 14px"
                  >
                    {{ item.name }}
                  </h4>
                  <h6
                    class="new-default-gray-font p-0 m-0 mb-2"
                    style="font-size: 13px"
                  >
                    {{ item.equipment_category_name }}
                  </h6>
                </div>
                <div class="col-2 text-right"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PuzlEmptyData
        v-if="!loading && (!items || !items.length)"
      ></PuzlEmptyData>
    </div>
  </div>
</template>

<script>
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import SkeletonPuzlFullWidth from "@/components/SkeletonPuzlFullWidth";

export default {
  name: "List",
  inheritAttrs: false,
  components: {
    SkeletonPuzlGrid,
    SkeletonPuzlFullWidth,
    PuzlEmptyData,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleUpdate(uuid) {
      this.$emit("update-checklist", uuid);
    },
    handleRemove(uuid) {
      this.$emit("remove-checklist", uuid);
    },
    handleItemChecklistRedirect(uuid) {
      this.$emit("checklist-item-redirect", uuid);
    },
    handleGenerateChecklist(uuid) {
      this.$emit("generate-checklist", uuid);
    },
    handleUpdateStatus(uuid, event) {
      this.$emit("checklist-update-status", {
        uuid: uuid,
        active: event.target.checked,
      });
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
span {
  font-family: "Fredoka";
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul > li {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 20px;
  min-width: 250px;
}
ul > li > span {
  font-weight: 300;
  font-size: 13px;
  line-height: 12.1px;
  white-space: nowrap;
}
div.ck-divider {
  margin-left: 2%;
  margin-right: 2%;
  flex: 1;
  min-width: 30px;
  border: 0.5px solid #e8e8e8;
}
.green-text-badge {
  color: #149e57;
}
.red-text-badge {
  color: #db4539;
}
.green-backgroud-light {
  background: #f2f7f3;
}
.red-backgroud-light {
  background: #fcf3f3;
}
label.app-badge {
  display: flex;
  max-width: 100%;
  height: 24px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  cursor: pointer;
}

label.app-badge > input[type="checkbox"] {
  display: none;
}
label.app-badge > span {
  font-family: "Fredoka";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
