<template>
  <div>
    <base-header class="bg-gray-content mb-n4">
      <div class="row align-items-center py-4 p-2">
        <div class="col-md-4">
          <h2 style="font-size: 1.2rem !important;" class="text-gray">
            <img height="30px" src="/img/icons/concrete-pump.png" class="mr-3"/>
            Resumo de abastecimento
          </h2>
        </div>
        <div class="col-md-8">
          <div class="row d-flex justify-content-end">
            <div class="mb-2 mb-md-0 col-md-3 px-1">
              <base-button
                @click="downloadFuelResumeExcel('excel')"
                type="success"
                block
                :disabled="!$_ownFleets.length"
                style="border-radius: 8px !important;"
              >
                <img height="19px" src="/img/icons/icons8/ios/excel-white.png" alt="note" class="mr-1 mt-n1"/> 
                Exportar excel
              </base-button>
            </div>
            <div class="mb-2 mb-md-0 col-md-3 px-1">
              <base-button
                @click="downloadFuelResumePdf('pdf')"
                type="primary"
                block
                :disabled="!$_ownFleets.length"
                style="border-radius: 8px !important;"
              >
                <img height="19px" src="/img/icons/icons8/ios/pdf-white.png" alt="note" class="mr-1 mt-n1"/> 
								Exportar pdf
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </base-header>
    <ListFuelResume ref="listFuelResume" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ListFuelResume from "./Shared/_List";

export default {
  name: "Index",
  components: {
    ListFuelResume,
  },
  computed: {
    ...mapGetters({
      $_ownFleets: "fuelResume/getOwnFleetItems",
    }),
  },
  methods: {
    /**
     * @param {string} export_type
     */
    downloadFuelResumeExcel(export_type) {
      this.$refs.listFuelResume.downloadFuelResumeExcel(export_type);
    },
    /**
     * @param {string} export_type
     */
    downloadFuelResumePdf(export_type) {
      this.$refs.listFuelResume.downloadFuelResumePdf(export_type);
    },
  },
};
</script>

<style></style>