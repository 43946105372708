/**
 * Definição das chaves únicas usadas para armazenar o estado.
 *
 * Cada chave deve ser única em todo o projeto.
 * Não deve haver duplicação de chaves em nenhum lugar do código.
 */
export const operationSourceStoreStateKeys = Object.freeze({
  ITEM: "OPERATION_SOURCE_ITEM",
  LISTED: "OPERATION_SOURCE_LISTED",
  IS_LISTING: "OPERATION_SOURCE_IS_LISTING",
  IS_FINDING: "OPERATION_SOURCE_IS_FINDING",
  IS_SAVING: "OPERATION_SOURCE_IS_SAVING",
  IS_REMOVING: "OPERATION_SOURCE_IS_REMOVING",
  PAGE_STATES: "OPERATION_SOURCE_PAGE_STATE",
});
