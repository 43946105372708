<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-gray d-inline-block mb-0">{{ $route.name }}</h6>
          <nav
            aria-label="breadcrumb"
            class="d-none d-md-inline-block ml-md-4 text-gray"
          >
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i></a>
              </li>
              <li class="breadcrumb-item"><a href="#">Tecnologia</a></li>
              <li class="breadcrumb-item" aria-current="page">
                {{ $route.name }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- Header Menus -->
      <div class="row" v-if="company_plant">
        <div class="col-md-12">
          <div class="row">
            <div v-if="$hasPrivilege(1)" class="col-md-3 col-lg-2 col-xlg-2 ml-3 mr-3 mb-3 ml-0 p-0">
              <base-button
                class="pb-3 pt-3 text-uppercase" block size="lg"
                type="success"
                @click.prevent="handleCreate(company_plant.company_id, company_plant.id)">
                <i class="fas fa-plus"></i> novo
              </base-button>
            </div>
            <div class="col-md-3 col-lg-2 col-xlg-2 ml-3 mr-3 mb-3 ml-0 p-0">
              <router-link :to="{ name:'plant', params: { company_id: company_plant.company_id}}">
                <base-button
                  block size="lg"
                  type="light"
                  class="p-3 text-uppercase"
                >
                  voltar
                </base-button>
              </router-link>
            </div>
          </div>

          <div class="row align-items-center pb-0 mb-3">
            <div class="col-md-12">
              <div class="checklist-item checklist-item-primary">
                <div class="checklist-info">
                  <h1 class="checklist-title mb-0">
                    Central: {{ company_plant.name }}
                  </h1>
                  <small>
                    Empresa: {{ company_plant.company_name }}
                  </small>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="row align-items-center pb-0">
        <div class="col-md-12">
          <div class="card p-2">
            <base-input placeholder="Pesquisar">
              <template slot="append">
                <i class="fas fa-search"></i>
              </template>
            </base-input>
          </div>
        </div>
      </div>

    </base-header>

    <div class="container-fluid">
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <!-- Empty Data -->
      <PuzlEmptyData v-if="!charge_points && !loadingSkeleton"></PuzlEmptyData>

      <div class="row card-wrapper" v-if="charge_points && !loadingSkeleton">
        <div
          class="col-lg-4"
          v-for="(charge_point, indexChargePoint) in charge_points"
          :key="indexChargePoint"
        >
          <!-- Basic with action button -->
          <card :class="charge_point.status ? 'card-border-top-success' : 'card-border-top-danger'">
            <!-- Card header -->
            <div class="row align-items-center mb-3">
              <div class="col-8">
                <!-- Title -->
                <h5 class="h3 mb-0">{{ charge_point.name }}</h5>
              </div>

              <div class="col-4 text-right">
                <base-dropdown menuOnRight>
                  <base-button
                    slot="title-container"
                    type="primary"
                    class="dropdown-toggle p-2 rounded m-0"
                  >
                    <i class="fas fa-cog"></i>
                  </base-button>
                  <a class="dropdown-item" @click="handleChargePointParameter(charge_point)">
                    <i class="ni ni-building"></i>
                    Parâmetros
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleCreateChargePointLocation(charge_point)">
                    <i class="ni ni-building"></i>
                    Incluir Locais
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>

                  <a class="dropdown-item" @click="handleEditChargePoint(charge_point.uuid)">
                    <i class="fas fa-edit text-warning"></i>
                    Editar
                  </a>
                  <a
                    class="dropdown-item"
                    @click="handleDeleteChargePoint(charge_point.uuid)"
                    v-show="false"
                  >
                    <i class="fas fa-times text-danger"></i>
                    Excluir
                  </a>
                </base-dropdown>
              </div>
            </div>
            <!-- Card body -->

            <collapse class="border rounded" v-if="charge_point.parameter">
              <collapse-item
                name="1"
                class="header-gray"
                back-ground="border-header-primary-bottom"
              >
                <h5 slot="title" class="mb-0">Parâmetros</h5>
                <ul class="list-unstyled">
                  <li>Capacidade de produção:
                    <strong>{{ charge_point.parameter.production_capacity }}&nbsp;m<sup>3</sup>/hora</strong></li>
                  <li>Tempo mínimo de carregamento:
                    <strong>{{ charge_point.parameter.time_min_charge }}&nbsp;minutos</strong></li>
                </ul>
              </collapse-item>
            </collapse>

            <collapse class="border rounded mt-2" v-if="charge_point.dosage_location!=''">
              <collapse-item
                name="1"
                class="header-gray"
                back-ground="border-header-success-bottom"
              >
                <h5 slot="title" class="mb-0">Locais</h5>

                <ul class="list-group"
                    v-for="(location, indexLocation) in charge_point.dosage_location" :key="indexLocation"
                >
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center mb-2 border-top-0 border-left-0 border-right-0 shadow--hover"
                  >
                  <div class="row">
                    <div class="col-md-12">
                      {{ location.dosage_name }}-{{ location.code }} {{ location.name }}&nbsp;({{
                        location.cmc_category
                      }})
                    </div>
                    <div class="col-md-12">
                      <a :title="location.uuid" v-if="$hasPrivilege(1)" class="mr-1 text-muted" @click.prevent="copyCode(location.uuid)" href="#">
                        <i class="fa-solid fa-clipboard"></i>
                      </a>
                      <a @click.prevent="handleEditChargePointLocation(location.uuid)" href="#">
                        <i class="fas fa-edit text-warning mr-1"></i>
                      </a>
                      <a 
                        v-if="$hasPrivilege(1)"
                        @click.prevent="handleDeleteChargePointLocation(location.uuid)" href="#"
                      >
                        <i class="fas fa-times text-danger"></i>
                      </a>
                    </div>
                  </div>
                  </li>
                </ul>
              </collapse-item>
            </collapse>

          </card>

        </div>
      </div>
      <modal-create-charge-point ref="createChargePoint"></modal-create-charge-point>
      <modal-edit-charge-point ref="editChargePoint"></modal-edit-charge-point>
      <modal-charge-point-parameter ref="chargePointParameter"></modal-charge-point-parameter>
      <modal-create-charge-point-location ref="createChargePointLocation"></modal-create-charge-point-location>
      <modal-edit-charge-point-location ref="editChargePointLocation"></modal-edit-charge-point-location>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid"
import PuzlEmptyData from "@/components/PuzlEmptyData"
import ModalCreateChargePoint from './_Create'
import ModalEditChargePoint from './_Edit'
import ModalChargePointParameter from './_ModalChargePointParameter'
import ModalCreateChargePointLocation from './_ModalCreateChargePointLocation'
import ModalEditChargePointLocation from './_ModalEditChargePointLocation'

export default {
  name: "ListChargePoint",
  components: {
    PuzlEmptyData,
    SkeletonPuzlGrid,
    ModalEditChargePoint,
    ModalCreateChargePoint,
    ModalChargePointParameter,
    ModalCreateChargePointLocation,
    ModalEditChargePointLocation,
  },
  data() {
    return {
      loadingSkeleton: true,
    }
  },
  computed: {
    ...mapGetters({
      'company_plant': 'plant/show',
      'charge_points': 'plantChargePoint/fetch',
    }),
  },
  methods: {
    fetch() {
      this.$store.dispatch('plantChargePoint/fetchItems', this.$route.params.company_plant_id).then(() => {
        this.loadingSkeleton = false
      })
    },
    handleCreate(companyId, plantId) {
      this.$refs.createChargePoint.openModal(true, companyId, plantId)
    },
    handleEditChargePoint(uuid) {
      this.$refs.editChargePoint.handleEditModal(uuid)
    },
    handleChargePointParameter(chargePoint) {
      this.$refs.chargePointParameter.openModal(true, chargePoint)
    },
    handleCreateChargePointLocation(chargePoint) {
      this.$refs.createChargePointLocation.openModal(true, chargePoint)
    },
    handleEditChargePointLocation(uuid) {
      this.$refs.editChargePointLocation.handleEditModal(uuid)
    },
    handleDeleteChargePoint(uuid) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$notify({
            type: 'info',
            message: 'Estamos trabalhando em sua solicitação.'
          });
          this.$store.dispatch('plantChargePoint/destroy', uuid)
            .then((response) => {
              this.$notify({type: response.error_type, message: response.message})
              this.$Progress.finish()
              this.fetch()
            })
        }
      }).catch(() => this.$Progress.finish())
    },
    copyCode(code) {
      const el = document.createElement('textarea');
      el.value = code;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
      this.$notify({ type: 'success', message: 'Código copiado com sucesso!' })
    },
    handleDeleteChargePointLocation(uuid) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$notify({
            type: 'info',
            message: 'Estamos trabalhando em sua solicitação.'
          });
          this.$store.dispatch('plantChargePointLocation/destroy', uuid)
            .then((response) => {
              this.$notify({type: response.data.error_type, message: response.data.message})
              this.$Progress.finish()
            })
            .catch(error => {
              if (error.status) {
                this.$Progress.finish()
                this.$notify({
                  type: "danger",
                  message: error.data.message
                });
              }
            });
          this.fetch()
        }
      }).catch(() => this.$Progress.finish())
    },
  },
  mounted() {
    this.$store.dispatch('plant/show', this.$route.params.company_plant_id)
    this.fetch()
  },
}
</script>

<style scoped>

</style>
