<template>
<div>
  <modal :show.sync="modal.open">
    <template slot="header">
      <h5 class="modal-title">{{ modal.title }}</h5>
    </template>
    <div>
      <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
        <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
          <div>
            <div class="form-group row m-0 p-0">
              <label class="col-md-5 col-form-label form-control-label">
                Status
              </label>
              <div class="col-md-7 pt-2">
                <base-switch v-model="slumpflow.status" type="success" offText="inativo" onText="ativo" class="success"></base-switch>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                Nomenclatura
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      v-model="slumpflow.nomenclature"
                      class="form-control form-control-sm"
                      :class="errors[0] ? 'is-invalid' : 'is-valid'"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                Mínimo <small>&nbsp;(mm)</small>
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      inputmode="numeric"
                      v-model="slumpflow.minimum"
                      class="form-control form-control-sm"
                      :class="errors[0] ? 'is-invalid' : 'is-valid'"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                Máximo <small>&nbsp;(mm)</small>
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      inputmode="numeric"
                      v-model="slumpflow.maximum"
                      class="form-control form-control-sm"
                      :class="errors[0] ? 'is-invalid' : 'is-valid'"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>



          </div>
          <div class="modal-footer">
            <base-button type="secondary" @click="closeModal()">
              Cancelar
            </base-button>
            <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingSave">
              Salvar
            </base-button>
          </div>
        </form>
      </validation-observer>
    </div>
  </modal>
</div>
</template>

<script>
const {formatErrorValidation} = require("@/plugins")
export default {
  name: "Create",
  data () {
    return {
      modal: {
        title: 'Slump/Flow',
        open: false,
      },
      slumpflow: {
        status: true,
        nomenclature: null,
        minimum: null,
        maximum: null,
      },
      loadingSave: false,
    }
  },
  computed: {
  },
  methods: {
    closeModal () {
      this.modal.open = false
    },
    handleCreateModal () {
      this.modal.open = true
    },
    save () {
      this.$Progress.start()
      this.loadingSave = true
      this.$store.dispatch('mixSlumpFlow/add', this.slumpflow)
        .then(response => {
          this.loadingSave = false
          this.modal.open = false
          this.slumpflow = { status: true, nomenclature: null, minimum: null, maximum: null,
          }
          this.$Progress.finish()
          this.$notify({ type: response.error_type, message: response.message })
        })
        .catch(error =>{
          if (error.status) {
            this.$Progress.finish()
            this.loadingSave = false
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            })
          } else {
            if (error.response.status === 422) {
              let message = formatErrorValidation(error.response.data.errors)
              this.$notify({
                type: 'danger',
                message: message
              })
              this.$Progress.finish()
              this.loadingSave = false
            } else {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              })
              this.$Progress.finish()
              this.loadingSave = false
            }
          }
        })
    },
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
  created () {
  }
}
</script>

<style scoped>

</style>
