<template>
  <div>
    <div class="row card-wrapper" v-if="$_cmcs.length && !loadingSkeleton">
      <div class="col-lg-4" v-for="(cmc, cmcIndex) in $_cmcs" :key="cmcIndex">
        <div class="card main-card" style="overflow: hidden">
          <div class="card-header" style="padding: 0px !important">
            <div
              class="pt-1 pb-1 btn-header dropdown-toggle text-center text-white text-uppercase"
              :style="Number(cmc.status) == 1 ? 'background: #149E57' : 'background: #DB4539'"
              style="opacity: 92%; color: #4f40ff block; width: 100%; border: none"
            >
              <img
                style="margin-bottom: 3px"
                class="mr-1"
                v-if="Number(cmc.status) == 1"
                width="18"
                src="/img/icons/icons8/ios/ok--v1.png"
              />
              <img style="margin-bottom: 3px" class="mr-1" v-else width="18" src="/img/icons/icons8/ios/cancel.png" />
              {{ Number(cmc.status) == 1 ? "ATIVO" : "INATIVO" }}
            </div>
          </div>
          <div class="pt-3 pr-4 pl-4 pb-3">
            <!-- Card header -->
            <div class="row">
              <div class="col-8">
                <!-- Title -->
                <h5 class="h2 mb-0 new-default-black-font">
                  {{ cmc.product_name }}
                  <small v-if="cmc.additional_classification_name"> ({{ cmc.additional_classification_name }}) </small>
                </h5>
                <h5 class="mt-1 mb-0 new-default-black-font">{{ cmc.category_name }}</h5>
              </div>
              <!-- Menu on Right -->
              <div class="col-4 text-right">
                <base-dropdown menuOnRight>
                  <div slot="title-container" class="dropdown-toggle rounded m-0">
                    <img width="32" src="/img/icons/icons8/ios/settings--v1_primary.png" />
                  </div>
                  <router-link
                    :to="{
                      path: '/technology/cmc/edit',
                      name: 'Editar MCC',
                      params: { cmc_id: cmc.id },
                    }"
                  >
                    <a class="dropdown-item font-weight-500 new-default-black-font" style="align-items: center; display: flex">
                      <img width="22" src="/img/icons/edit.png" />
                      EDITAR
                    </a>
                  </router-link>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a
                    class="dropdown-item font-weight-500 new-default-black-font"
                    style="align-items: center; display: flex"
                    @click="handleFormatToCreateCmcPrice(cmc)"
                  >
                    <img width="22" src="/img/icons/price-tag-usd-green.png" />
                    PREÇO MCC 
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a
                    class="dropdown-item font-weight-500 new-default-black-font"
                    style="align-items: center; display: flex"
                    @click="handleDelete(cmc.uuid)"
                  >
                    <img width="22" src="/img/icons/delete.png" />
                    EXCLUIR
                  </a>
                </base-dropdown>
              </div>
            </div>
            <!-- Card body -->
            <h4 class="mt-1 mb-0 new-default-gray">{{ cmc.supplier_name }} | {{ cmc.supplier_origin_name }}</h4>
            <hr class="blue-divider mt-2 mb-2" />

            <div class="row align-items-center">
              <div class="col-7">
                <h5 class="h3 mb-0 new-default-black-font">
                  {{ cmc.specific_gravity.toFixed(2) }}&nbsp;<span style="text-transform: none">g/cm</span><sup>3</sup> (M.E.)
                </h5>

                <h5 class="h3 mb-0 new-default-black-font" v-if="cmc.bulk_density != ''">
                  {{ cmc.bulk_density.toFixed(2) }}&nbsp;g/cm<sup>3</sup> (M.U.)
                  <span class="ml-2" v-if="cmc.average_humidity > 0">
                    <i class="fa-solid fa-droplet text-info"></i> <b class="text-primary">{{ cmc.average_humidity.replace(".", ",") }} %</b>
                  </span>
                </h5>
              </div>
              <div class="col-5 text-right">
                <el-popover  class="border-none" trigger="hover" placement="left">
                  <div class="new-default-black-font" style="width: 220px">
                    <div class="row">
                      <div class="col-12">
                        <h4 class="new-default-black-font mb-0" style="font-weight: normal !important">
                          <b>{{ cmc.group_names }} </b>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <img slot="reference" role="button" class="mt-n1 ml-2 rounded-0" width="24px" src="/img/icons/icons8/ios/parallel-tasks_primary.png" />
                </el-popover>
                <el-popover trigger="click" placement="left">
                  <div class="new-default-black-font" style="width: 220px">
                    <div class="row">
                      <div class="col-12">
                        <h4 class="new-default-black-font" style="font-weight: normal !important">
                          <b>COMPRA: </b>{{ cmc.buy_measured_unit_letter }}
                        </h4>
                      </div>
                      <div class="col-12">
                        <h4 class="new-default-black-font" style="font-weight: normal !important">
                          <b>DOSAGEM: </b>{{ cmc.dosage_measured_unit_letter }}
                        </h4>
                      </div>
                      <div class="col-12">
                        <h4 class="new-default-black-font" style="font-weight: normal !important">
                          <b>ESTOQUE: </b>{{ cmc.stock_measured_unit_letter }}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <img slot="reference" role="button" class="mt-n1 ml-2" width="24px" src="/img/icons/info.png" />
                </el-popover>
                <img
                  @click="handleFormatToShowCmcPrice(cmc, cmcIndex)"
                  role="button"
                  class="mt-n1 ml-2"
                  width="24px"
                  src="/img/icons/price-tag-usd-green.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row card-wrapper" v-show="loadingSkeleton">
      <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
    </div>
  </div>
</template>

<script>
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import PuzlSelect from "@/components/PuzlSelect";
import { mapGetters } from "vuex";

export default {
  name: "CardsCmc",
  props: {
    handleFilterCmcGroup: { type: Function },
    handleFilterCmcProduct: { type: Function },
    handleFilterCmcSupplier: { type: Function },
    handleFilterCmcCategory: { type: Function },
    setStatus: { type: Function },
    showGroups: { type: Function },
    handleDeleteCmc: { type: Function },
    handleEnableDisable: { type: Function },
    handleCreateCarrierPrice: { type: Function },
    handleCreateCmcPrice: { type: Function },
    handleShowCmcPrice: { type: Function },
    handleDeleteCarrierPrice: { type: Function },
    handleDeleteCmcPrice: { type: Function },
    pricePerKg: { type: Function },
    handleDelete: { type: Function },
    loadingSkeleton: Boolean,
  },
  components: {
    SkeletonPuzlGrid,
    BaseButtonHoverable,
    PuzlSelect,
  },
  computed: {
    ...mapGetters({
      $_cmcs: "cmc/fetch",
    }),
  },
  methods: {
    handleFormatToCreateCmcPrice(cmc) {
      const payload = {
        id: cmc.id,
        measured_unit_buy_initials: cmc.buy_measured_unit_letter,
        product_name: cmc.product_name,
        supplier_name: cmc.supplier_name,
        supplier_origin_name: cmc.supplier_origin_name,
        params: {
          average_humidity: cmc.average_humidity,
          measured_unit_id: cmc.buy_measured_unit_id,
          bulk_density: this.$helper.strToNum(cmc.bulk_density),
        },
      };

      this.handleCreateCmcPrice(cmc, payload);
    },
    async handleFormatToShowCmcPrice(cmc, cmc_index) {
      const loader = this.$loading.show();
      const response = await this.$store.dispatch("cmc/show", cmc.id)
      loader.hide();
      this.handleShowCmcPrice(response.data, cmc_index);
    }
  }
};
</script>

<style scoped>
.main-card {
  border-radius: 10px;
  border: 1px solid #e8e8e8;
  box-shadow: none !important;
}
.card {
  border-radius: 10px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
}

.new-default-gray {
  opacity: 0.5;
}

.blue-divider {
  background-color: #1b6eba;
  color: #1b6eba;
  height: 0.1px;
}

.card-blue-bottom {
  border-top-left-radius: 35px;
  border-bottom-right-radius: 10px;
  background-color: #1b6eba;
  color: white;
  max-width: 140px;
  min-width: 140px;
  float: right;
}

.groups-disabled {
  height: 0px;
  overflow: hidden;
}

.groups-list {
  -webkit-transition: all 3s ease;
  -moz-transition: all 3s ease;
  -o-transition: all 3s ease;
  -ms-transition: all 3s ease;
  transition: all 3s ease;
}
</style>
