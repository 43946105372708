import { render, staticRenderFns } from "./_Edit.vue?vue&type=template&id=252a7754&scoped=true"
import script from "./_Edit.vue?vue&type=script&lang=js"
export * from "./_Edit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "252a7754",
  null
  
)

export default component.exports