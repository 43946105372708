<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
            <div class="card-header border-header-default-bottom p-2 grid-col--fixed-left">
                <h5 class="mb-0 text-center" slot="title">Filtros</h5>
            </div>

            <div class="form-group row m-0 p-0 mb-1 mt-3">
                <label class="col-md-6 pt-1 pb-0 col-form-label form-control-label">
                  Competência
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-6">
                  <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-group-classes="input-group-sm">
                            <input-date-time-picker
                                v-model="cardIntegration.competence" 
                                :mode="'date'"
                                @input="getSalesFromCardIntegration()"
                            />
                        </base-input>
                    </validation-provider>
                </div>
            </div>

            <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pt-1 pb-0 col-form-label form-control-label">
                  Mediador de pagamento
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-6">
                  <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-group-classes="input-group-sm">
                            <puzl-select
                                v-model="cardIntegration.paymentIntermediaryId"
                                :items="$_payment_intermediaries" 
                                :disabled="true"
                                :loading="loadingPaymentIntermediary"/>
                        </base-input>
                    </validation-provider>
                </div>
            </div>

            <div class="form-group row m-0 p-0 mb-3">
                <label class="col-md-6 pt-1 pb-0 col-form-label form-control-label">
                  Ponto de venda (PV)
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-6">
                  <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-group-classes="input-group-sm">
                            <puzl-select
                                v-model="cardIntegration.paymentIntermediaryMerchantId"
                                :items="$_payment_intermediary_merchants"
                                label="description"
                                :disabled="loadingPaymentIntermediaryMerchantId"
                                :loading="loadingPaymentIntermediaryMerchantId"
                                @input="getSalesFromCardIntegration()"
                             />
                        </base-input>
                    </validation-provider>
                </div>
            </div>

            <div class="card-header border-header-default-bottom p-2 mb-2 grid-col--fixed-left">
                <h5 class="mb-0 text-center" slot="title">Registros</h5>
            </div>
            
            <PuzlEmptyData v-if="!$_sales.length && !loadingSales"> </PuzlEmptyData>
             
            <div class="m-3" v-if="$_sales.length && !loadingSales" v-for="(sale, index) in $_sales" :key="index">
                <div class="row">
                    <div class="col-12 text-dark">{{ sale.amount | currency }}</div>
                    <div class="col-10">{{ sale.typeName }}O | {{ sale.installmentQuantity }}x</div>
                    <div class="col-2 ml-n3 mt-n1">
                        <base-button @click.prevent="selectPaymentIntermediaryMerchantId(sale.nsu)" block size="sm" type="primary" outline>
                            <i class="fa-regular fa-circle-check"></i>
                        </base-button>
                    </div>
                    <div class="col-12">NSU: {{ sale.nsu }}</div>
                </div>
                <hr class="mt-1 mb-1">
            </div>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import InputDateTimePicker from "@/components/InputDateTimePicker";
import PuzlSelect from "@/components/PuzlSelect";
import PuzlEmptyData from "@/components/PuzlEmptyData";
const { formatErrorValidation } = require("@/plugins");

export default {
    name: "ModalCardIntegration",
    components: { 
        InputDateTimePicker, 
        PuzlSelect,
        PuzlEmptyData
    },
    data() {
        return {
            modal: {
                title: 'Integração de cartões',
                create: false,
            },
            cardIntegration: {
                competence: '',
                paymentIntermediaryId: '',
                paymentIntermediaryMerchantId: '',
            },
            loadingPaymentIntermediary: true,
            loadingPaymentIntermediaryMerchantId: true,
            loadingSales: false,
        };
    },
    computed: {
        ...mapGetters({
            $_payment_intermediaries: "paymentIntermediary/fetch",
            $_payment_intermediary_merchants: "paymentIntermediaryMerchant/fetch",
            $_sales: "paymentIntermediaryMerchant/getSales",
        }),
    },
    mounted() {
        this.$refs.formValidator.validate();
        this.$store.dispatch("paymentIntermediary/fetchItems").then(() => {
            this.loadingPaymentIntermediary = false;
        });
        this.$store.dispatch("paymentIntermediaryMerchant/fetchItems").then(() => {
            this.loadingPaymentIntermediaryMerchantId = false;
        });
    },
    methods: {
        /**
         * @param {string} nsu_code
         */
        selectPaymentIntermediaryMerchantId(nsu_code) {
            const selectedTransactionProps = {
                nsu_code: nsu_code,
                paymentIntermediaryId: this.cardIntegration.paymentIntermediaryId,
                paymentIntermediaryMerchantId: this.cardIntegration.paymentIntermediaryMerchantId,
                competence: this.cardIntegration.competence,
            }
            this.$emit('selectedTransactionProps', selectedTransactionProps);
            this.closeModal();
        },
        closeModal() {
            this.modal.create = false;
        },
        /**
         * @param {number} paymentIntermediaryId
         * @param {number} paymentIntermediaryMerchantId
         */
        handleCreateModal(paymentIntermediaryId, paymentIntermediaryMerchantId, competence) {
            this.modal.create = true;
            this.cardIntegration.paymentIntermediaryId = paymentIntermediaryId;
            this.cardIntegration.paymentIntermediaryMerchantId = paymentIntermediaryMerchantId;
            this.cardIntegration.competence = competence;
            this.getSalesFromCardIntegration();
        },
        getSalesFromCardIntegration(){
            if (!this.cardIntegration.paymentIntermediaryMerchantId){
                return;
            }
            this.loadingSales = true;
            let loader = this.$loading.show()
            let payload = {
                id: this.cardIntegration.paymentIntermediaryMerchantId,
                startDate: this.$helper.parseDate(this.cardIntegration.competence, 'YYYY-MM-DD'),
                endDate: this.$helper.parseDate(this.cardIntegration.competence, 'YYYY-MM-DD'),
            }
            this.$store.dispatch('paymentIntermediaryMerchant/getSalesFromCardIntegration', payload)
                .then((response) => {
                    loader.hide();                
                    this.loadingSales = false;
                }).catch(error => {
                    loader.hide();
                    this.loadingSales = false;
                    let errors = error.status ? error.data.message : formatErrorValidation(error.response.data.errors);
                    this.$notify({ type: "danger", message: errors });
                });
        },         
    },
};
</script>

<style scoped></style>
