<template>
<div>
    <modal :show.sync="modal.open">
        <template slot="header">
            <h5 class="modal-title pl-3">{{ modal.title }}</h5>
        </template>
        <div>
            <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
                <form class="needs-validation" @submit.prevent="handleSubmit(handleConcreteCycleAdjustmentUpdate)" autocomplete="off">
                    <div>
                        <div class="form-group row m-0 p-0 pt-2">
                             <label class="col-md-4 pt-1 pb-3 mb-1 col-form-label form-control-label text-left pr-0">
                                Maior que
                                <span class="text-danger">&nbsp;*</span>
                            </label>
                            <div class="col-md-7 pb-3 pl-0">
                                <validation-provider rules="required|min_value:0|max_value:999" v-slot="{errors}">
                                    <base-input input-group-classes="input-group-sm">
                                        <input
                                            inputmode="numeric"
                                            v-model="adjustment.from"
                                            class="form-control form-control-sm"
                                            :class="errors[0] ? 'is-invalid' : 'is-valid'"
                                            v-mask="['###']"
                                        />
                                        <template slot="append">
                                            <small class="input-group-sm p-0 m-0">
                                                minutos
                                            </small>
                                        </template>
                                    </base-input>
                                </validation-provider>
                            </div>
                        </div>
                        <div class="form-group row m-0 p-0">
                            <label class="col-md-4 pt-1 pb-3 mb-0 col-form-label form-control-label text-left pr-0">
                                Menor ou igual a
                                <span class="text-danger">&nbsp;*</span>
                             </label>
                            <div class="col-md-7 pl-0">
                                <validation-provider rules="required|min_value:0|max_value:999" v-slot="{errors}">
                                    <base-input input-group-classes="input-group-sm">
                                        <input
                                            inputmode="numeric"
                                            v-model="adjustment.until"
                                            class="form-control form-control-sm"
                                            :class="errors[0] ? 'is-invalid' : 'is-valid'"
                                            v-mask="['###']"
                                        />
                                        <template slot="append">
                                            <small class="input-group-sm p-0 m-0">
                                                minutos
                                            </small>
                                        </template>
                                    </base-input>
                                </validation-provider>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <base-button type="secondary" @click="closeModal()">
                            Cancelar
                        </base-button>
                        <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                            Salvar
                        </base-button>
                    </div>
                </form>
            </validation-observer>
        </div>
    </modal>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'

export default {
    name: "EditConcreteCycleAdjustment",
    data() {
        return {
            modal: {
                title: 'Ajuste Ciclo do Concreto',
                open: false,
            },
            adjustment: {
                uuid: null,
                from: null,
                until:null,
            },
            loadingStore: false,
        }
    },
    computed: {
        ...mapGetters({
            'listAdjustment': 'adjustmentConcreteCycle/fetch',
        })
    },
    methods: {
        closeModal() {
            this.modal.open = false
        },
        handleEditModal(uuid) {
            this.$notify({
                type: 'info',
                message: 'Estamos trabalhando em sua solicitação.'
            })
            this.$Progress.start()
            this.$store.dispatch('adjustmentConcreteCycle/show', uuid)
                .then((response) => {
                    this.adjustment = {
                        uuid: response.data.uuid || '',
                        from: response.data.from || '',
                        until: response.data.until || ''
                    }
                    this.modal.open = true
                    this.$notify({
                        type: response.error_type,
                        message: response.message
                    })
                    this.$Progress.finish()
                }).catch((error) => {
                    if (error.response.status === 422) {
                        let errors = Object.values(error.response.data.errors)
                        errors = errors.flat().toString().split(',').join("<br />")
                        this.$notify({
                            type: 'danger',
                            message: errors
                        })
                    }
                    this.$Progress.finish()
                    this.loadingStore = false
                })
        },
        handleConcreteCycleAdjustmentUpdate() {
            this.$Progress.start()
            this.loadingStore = true
            this.$store.dispatch('adjustmentConcreteCycle/update', this.adjustment)
                .then(response => {
                    this.loadingStore = false
                    this.modal.open = false
                    this.$Progress.finish()
                    this.$notify({
                        type: response.error_type,
                        message: response.message
                    })
                    EventBus.$emit("updatedConcreteCycleAdjustment");
                })
                .catch(error => {
                    if (error.status == 200) {
                        this.$notify({
                            type: 'danger',
                            message: error.data.message
                        })
                        this.$Progress.finish();
                        this.loadingStore = false
                    } else if (error.response && error.response.status === 422) {
                        let errors = Object.values(error.response.data.errors)
                        errors = errors.flat().toString().split(',').join("<br />")
                        this.$notify({
                            type: 'danger',
                            message: errors
                        })
                    }
                    this.$Progress.finish()
                    this.loadingStore = false
                })
        },
    },
    mounted() {
        this.$refs.formValidator.validate();
    },
    created() {}
}
</script>

<style scoped>

</style>
