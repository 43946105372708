<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-gray d-inline-block mb-0">
            {{ this.$route.meta.breadcrumbs[1].title }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4 text-gray">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#"><i :class="this.$route.meta.icon"></i></a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">{{ this.$route.meta.breadcrumbs[0].title }}</a>
              </li>
              <li class="breadcrumb-item" aria-current="page">
                {{ this.$route.meta.breadcrumbs[1].title }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- Header Menus -->
      <div class="row mb-3">
        <div class="col-md-3 mb-2">
          <BackButton/>
        </div>
        <div class="col-md-3 mb-2">
          <base-button
            @click.prevent="cancelSchedule"
            v-if="schedule.uuid"
            class="pb-3 pt-3 text-uppercase"
            block
            size="lg"
            :type="schedule.status != 0 ? 'danger' : 'success'"
          >
            {{ schedule.status != 0 ? "cancelar programação" : "retomar" }}
          </base-button>
        </div>
      </div>

      <div>
        <div class="row align-items-center pb-0 mb-3">
          <div class="col-md-12">
            <div class="checklist-item checklist-item-primary">
              <div class="checklist-info">
                <h2 class="checklist-title mb-0">
                  Obra:
                  <span v-if="schedule.contract_proposal" class="small">{{ schedule.contract_proposal.construction_name }}</span>
                </h2>
                <h2 class="checklist-title mb-0">
                  Cliente:
                  <span v-if="schedule.contract_proposal" class="small">{{ schedule.contract_proposal.customer_name }}</span>
                </h2>
                <h2 class="checklist-title mb-0">
                  Cód. Contrato:
                  <span v-if="schedule.contract_proposal" class="small">{{ schedule.contract_proposal.code }}</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid">
      <div>
        <!-- Start Card Choose Mixe -->
        <card>
          <!-- Start Schedule -->
          <div>
            <div class="row align-items-center mb-3">
              <div class="col-12">
                <div class="border-header-warning-bottom">
                  <h5 class="h4 p-1 mb-0">Programação</h5>
                </div>
              </div>
            </div>

            <div class="form-group row m-0 p-0 mb-1">
              <div class="col-md-4 mb-1">
                <div class="pb-0 col-form-label form-control-label pb-2 pt-1">
                  Data da concretagem
                  <span class="text-danger">&nbsp;*&nbsp;</span>
                  <a v-if="!loadingPlant" href="javascript:void(0)" class="ml-1 btn btn-sm btn-success p-1" @click.prevent="handleReschedule"
                    >Reagendar</a
                  >
                  <!-- Aviso -->
                  <el-popover trigger="hover" placement="bottom" class="p-0 float-right pr-0">
                    Programação deve ser definida considerando o horário de chegada do primeiro caminhão na obra.
                    <base-button outiline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none">
                      <i style="font-size: 20px !important" class="fas fa-info-circle p-1" />
                    </base-button>
                  </el-popover>
                </div>
                <base-input input-group-classes="input-group-sm">
                  <input-date-time-picker :disableDate="new Date()" :spaceMinutes="15" v-model="schedule.concreting_date" :size="'lg'" @input="setNewConcreteDate" />
                </base-input>
              </div>

              <div class="col-md-4 mb-2">
                <div class="pt-1 pb-2 col-form-label form-control-label">
                  Centrais
                  <span class="text-danger">&nbsp;*&nbsp;</span>
                  <base-button
                    v-if="schedule.companies_distances && schedule.companies_distances.length > 0 && !loadingData"
                    size="sm"
                    type="warning"
                    class="text-uppercase"
                    @click.prevent="handleDistanceTravelPlant"
                  >
                    <i class="fa fa-clock"></i>
                  </base-button>
                  <span v-show="incompleteCycle" class="ml-2"><i class="fas fa-exclamation-triangle text-danger mr-2"></i>
                    <strong class="" style="color: #7e7e7e">Ciclo incompleto!</strong>
                  </span>
                  <span v-show="!incompleteCycle && highTravelDuration" class="ml-2"><i class="fas fa-exclamation-triangle text-danger mr-2"></i>
                    <strong style="color: #7e7e7e">Conferir tempos de trajeto!</strong>
                  </span>
                </div>
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm">
                    <el-select
                      @change="getDistance();setScheduleServiceCompanyPlant();"
                      v-model="schedule.company_plants_uuid"
                      size="mini"
                      placeholder="Selecione"
                      filterable
                      multiple
                      :loading="loadingPlant"
                      :loading-text="'Aguarde...'"
                      :disabled="loadingPlant"
                    >
                      <el-option v-for="plant in plants" :key="plant.uuid" :label="plant.name" :value="plant.uuid"> </el-option>
                    </el-select>
                  </base-input>
                </validation-provider>
              </div>

              <div class="col-md-4">
                <div class="pb-0 col-form-label form-control-label pb-2 pt-2">
                  Data do pedido
                  <span class="text-danger">&nbsp;*&nbsp;</span>
                </div>
                <base-input input-group-classes="input-group-sm">
                  <el-date-picker
                    v-model="schedule.request_date"
                    size="mini"
                    type="datetime"
                    format="dd/MM/yyyy HH:mm"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    block
                    disabled
                  >
                  </el-date-picker>
                </base-input>
              </div>
            </div>
            <div class="form-group row m-0 p-0 mb-1">
              <div class="col-md-6">
                <div class="pt-1 pb-2 col-form-label form-control-label">
                  Endereço da obra
                  <span class="text-danger">&nbsp;*&nbsp;</span>
                </div>
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm">
                    <el-select
                      @change="getDistance"
                      v-model="schedule.address_construction_uuid"
                      size="mini"
                      placeholder="Selecione"
                      filterable
                      :loading="loadingPlant"
                      :disabled="loadingPlant"
                    >
                      <el-option
                        v-for="address in addresses"
                        :key="address.uuid"
                        :label="
                          address.address +
                            ', ' +
                            address.number +
                            ', ' +
                            address.district +
                            ', ' +
                            address.city +
                            '/' +
                            address.state +
                            ' - ' +
                            address.country
                        "
                        :value="address.uuid"
                        selected
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </validation-provider>
              </div>
              <div class="col-md-3">
                <div class="pt-1 pb-2 col-form-label form-control-label">
                  Aguardar liberação do cliente
                </div>
                <base-switch
                  v-model="schedule.await_customer_release"
                  type="primary"
                  offText="não"
                  onText="sim"
                  class="primary"
                ></base-switch>
              </div>
              <div class="col-md-3">
                <div class="pt-1 pb-2 col-form-label form-control-label">
                  Vistoria
                </div>
                <base-switch v-model="schedule.survey" type="primary" offText="não" onText="sim" class="primary"></base-switch>
              </div>
            </div>

            <div class="form-group row m-0 p-0 mb-1" v-if="schedule.await_customer_release">
              <div class="col-md-6"></div>
              <div class="col-md-6">
                <div class="pt-1 pb-2 col-form-label form-control-label">
                  Viagens liberadas
                </div>
                <base-input input-group-classes="input-group-sm" group class="mb-2">
                  <input
                    inputmode="numeric"
                    v-on:input="schedule.free_travels = formatString($event.target.value, '0', '999', '')"
                    v-model="schedule.free_travels"
                    type="text"
                    class="form-control form-control-sm"
                  />
                </base-input>
              </div>
            </div>

            <!--Start Contact Construction -->
            <div>
              <div class="form-group row m-0 p-0 mb-1">
                <div class="col-md-6">
                  <div class="pb-0 pt-1 mb-1 col-form-label form-control-label">
                    Responsável pelo Recebimento
                    <span class="text-danger">&nbsp;*</span>
                    <a href="javascript:void(0)" @click.prevent="handleAddResponsible" class="ml-1 btn btn-sm btn-success p-1">Novo</a>
                  </div>
                  <el-select
                    v-model="schedule.responsible_receiver_uuid"
                    size="mini"
                    placeholder="Selecione"
                    filterable
                    @change="setContact"
                    :loading="loadingContact"
                    :disabled="loadingContact || loadingPlant"
                  >
                    <el-option v-for="contact in contacts" :key="contact.uuid" :label="contact.name" :value="contact.uuid" selected>
                    </el-option>
                  </el-select>
                </div>

                <!-- Start Contact Details -->
                <div class="col-md-6 mb-1 pb-1">
                  <div class="pb-1 pt-1 col-form-label form-control-label">
                    &nbsp;
                  </div>
                  <collapse class="border rounded p-0">
                    <collapse-item name="1" class="header-gray" back-ground="border-header-default-bottom">
                      <h5 slot="title" class="mb-0">Contato</h5>
                      <div class="form-group row m-0 p-0 mb-1">
                        <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                          Nome:
                        </label>
                        <div class="col-md-8">
                          <span class="form-control form-control-sm">{{ schedule.contact.name }}</span>
                        </div>
                      </div>
                      <div class="form-group row m-0 p-0 mb-1">
                        <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                          Celular:
                        </label>
                        <div class="col-md-8">
                          <span class="form-control form-control-sm"> {{ schedule.contact.cell_phone }}</span>
                        </div>
                      </div>
                      <div class="form-group row m-0 p-0 mb-1">
                        <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                          E-mail:
                        </label>
                        <div class="col-md-8">
                          <span class="form-control form-control-sm">{{ schedule.contact.email }}</span>
                        </div>
                      </div>
                    </collapse-item>
                  </collapse>
                </div>
                <!-- End Contact Details -->
              </div>
            </div>

            <div class="form-group row m-0 p-0 mb-1">
              <div class="col-md-12">
                <div class="pb-0 pt-1 col-form-label form-control-label">
                  Orientações para produção
                </div>
                <base-input input-group-classes="input-group-sm">
                  <textarea v-model="schedule.orientations" type="text" rows="3" maxlength="300" class="form-control form-control-sm"> </textarea>
                </base-input>
              </div>
            </div>
          </div>
          <!-- End Schedule -->
        </card>

        <card>
          <!-- Start Formulation -->
          <div>
            <div class="row align-items-center mb-3">
              <div class="col-12">
                <!-- Title -->
                <div class="border-header-primary-bottom">
                  <h5 class="h4 p-1 mb-0">Formulação</h5>
                </div>
              </div>
            </div>

            <div class="form-group row m-0 p-0 mb-1">
              <div class="col-md-6">
                <div class="pt-1 pb-2 col-form-label form-control-label">
                  Peça a concretar
                  <span class="text-danger">&nbsp;*&nbsp;</span>
                </div>
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm">
                    <el-select
                      @change="resetMixDesign(true)"
                      v-model="schedule.piece_id"
                      size="mini"
                      placeholder="Selecione"
                      filterable
                      :loading="loadingPlant"
                      :disabled="loadingPlant"
                    >
                      <el-option v-for="piece in concreted_pieces" :key="piece.uuid" :label="piece.description" :value="piece.id">
                      </el-option>
                    </el-select>
                  </base-input>
                </validation-provider>
              </div>

              <div class="col-md-6">
                <div class="pb-0 pt-1 col-form-label form-control-label">
                  Identificação da peça
                </div>
                <base-input input-group-classes="input-group-sm pt-2" group class="mb-2">
                  <input v-model="schedule.piece_identification" type="text" class="form-control form-control-sm" />
                </base-input>
              </div>
            </div>

            <div class="d-flex justify-content-between mt-3 mb-3">
              <div v-if="!mix_design || !mix_design.uuid" class="checklist-item border-primary p-2">
                <base-button :disabled="!schedule.piece_id" block type="primary" @click.prevent="addMixe">
                  incluir traço
                </base-button>
              </div>
              <div class="col-12 pl-0" v-if="mix_design.uuid">
                <div class="col-12 border-left border-3 border-primary p-1 mt-2">
                  <div class="row">
                    <div class="col-md-11">
                      <div class="row">
                        <div class="col-md-12">
                          <h5 class="h mb-0">
                            {{ mix_design.title }}
                          </h5>
                        </div>
                      </div>
                      <div class="mt-2">
                        <span class="p small">
                          {{ mix_design.body }}
                        </span>
                      </div>
                    </div>

                    <div class="col-md-1 col-sm-12 d-flex justify-content-center align-items-center pt-1">
                      <base-button class="p-1" block size="sm" type="danger" @click="resetMixDesign">
                        <i class="fa fa-trash text-white"></i>
                      </base-button>
                    </div>
                  </div>
                </div>
                <div
                  class="mt-2 border-left border-3 border-indigo p-2 "
                  v-if="mix_design.puzl_additions && mix_design.puzl_additions.length"
                  style="padding-left: 4px!important;"
                >
                  <div v-for="(puzl_addition, index) in mix_design.puzl_additions" :key="puzl_addition.id">
                    <h5 class="text-indigo mb-0">
                      {{ puzl_addition.name }}
                      ({{ puzl_addition.volume }} kg/m<sup>3</sup>)
                      <span class="text-indigo" v-if="index != mix_design.puzl_additions.length - 1">+</span>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <!-- Início centrais -->
            <div
              class="border-left border-light border-3 mb-3"
              v-for="(companyPlant, index) in schedule.companies_distances"
              :key="companyPlant.uuid"
            >
              <div class="form-group row m-0 p-0 mb-1">
                <div class="col-md-5 pt-1 pb-0 col-form-label form-control-label">
                  <h4>{{ companyPlant.label }}</h4>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <!-- Volume -->
                <div class="col-md-4 pr-0">
                  <div class="pt-1 pb-0 col-form-label form-control-label">
                    Volume
                    <span class="text-danger">&nbsp;*&nbsp;</span>
                  </div>

                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-on:input="companyPlant.volume = formatString($event.target.value, '0', '999.9', 1)"
                      v-on:blur="volumeCalc($event.target.value, index)"
                      @change="getTravelQuantity(index)"
                      v-model="companyPlant.volume"
                      inputmode="numeric"
                      class="form-control form-control-sm"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0"> m<sup>3</sup> </small>
                    </template>
                  </base-input>
                </div>

                <!-- Max. por viagem -->
                <div class="col-md-4 pr-0">
                  <div class="pt-1 pb-0 col-form-label form-control-label">
                    Volume máx. por viagem
                    <span class="text-danger">&nbsp;*&nbsp;</span>
                  </div>
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-on:input="companyPlant.max_volumes_per_travel = formatString($event.target.value, '0', '12.0', 1)"
                      @change="getTravelQuantity(index)"
                      v-model="companyPlant.max_volumes_per_travel"
                      inputmode="numeric"
                      class="form-control form-control-sm"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0"> m<sup>3</sup> </small>
                    </template>
                  </base-input>
                </div>

                <!-- Número de viagens -->
                <div class="col-md-4 pr-0">
                  <div class="pt-1 pb-0 col-form-label form-control-label">
                    Número de viagens
                    <span class="text-danger">&nbsp;*&nbsp;</span>
                  </div>
                  <base-input input-group-classes="input-group-sm">
                    <input disabled v-model="companyPlant.travels" inputmode="numeric" class="form-control form-control-sm" />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0"> m<sup>3</sup> </small>
                    </template>
                  </base-input>
                </div>
              </div>
            </div>
            <!-- Totais-->
            <div class="border-left border-light border-3">
              <div class="form-group row m-0 p-0 mb-1">
                <div class="col-md-5 pt-1 pb-0 col-form-label form-control-label">
                  <h4>Total</h4>
                </div>
              </div>
              <!-- Volume -->
              <div class="form-group row m-0 p-0 mb-1">
                <div class="col-md-6 pr-0">
                  <div class="pt-1 pb-0 col-form-label form-control-label">
                    Volume
                    <span class="text-danger">&nbsp;*&nbsp;</span>
                  </div>
                  <base-input input-group-classes="input-group-sm">
                    <input
                      disabled
                      v-model="schedule.total_volume"
                      v-mask="['#.#', '##.#', '###.#', '####.#', '#####.#', '######.#', '#######.#', '########.#']"
                      inputmode="numeric"
                      class="form-control form-control-sm"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0"> m<sup>3</sup> </small>
                    </template>
                  </base-input>
                </div>
                <div class="col-md-6 pr-0">
                  <div class="pt-1 pb-0 col-form-label form-control-label">
                    Número de viagens
                    <span class="text-danger">&nbsp;*&nbsp;</span>
                  </div>
                  <base-input input-group-classes="input-group-sm">
                    <input disabled v-model="schedule.total_travels" inputmode="numeric" class="form-control form-control-sm" />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0"> m<sup>3</sup> </small>
                    </template>
                  </base-input>
                </div>
              </div>
            </div>

            <div class="col-12 row mt-3 w-100">
              <div class="col-md-5"></div>
              <div v-if="stayTimeGreater" class="col-md-6 pl-5">
                <p class="p-3 schedule_danger_message_stay_time ml-1 rounded text-center">
                  Atenção, a partir do intervalo entre <br />
                  descargas informado, haverá mais de um equipamento em obra.
                </p>
              </div>
              <div class="col-md-1"></div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="border-left border-warning border-3 p-2 mt-2 col-10">
                <div class="form-group row m-0 p-0 mb-1">
                  <div class="col-md-4 pr-0">
                    <div class="pt-1 pb-0 col-form-label form-control-label">
                      Tempo de permanência em obra
                      <span class="text-danger">&nbsp;*&nbsp;</span>
                    </div>
                    <base-input input-group-classes="input-group-sm">
                      <input
                        @change="checkStayTime"
                        v-model="mix_design.formulation_cycle.time_construction_stay"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> minutos </small>
                      </template>
                    </base-input>
                  </div>
                  <div class="col-md-4 pr-0">
                    <div class="pt-1 pb-0 col-form-label form-control-label">
                      Intervalo entre descargas
                      <span class="text-danger">&nbsp;*&nbsp;</span>
                    </div>
                    <base-input input-group-classes="input-group-sm">
                      <input
                        @change="checkStayTime"
                        v-model="mix_design.formulation_cycle.interval_releases"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> minutos </small>
                      </template>
                    </base-input>
                  </div>
                  <div class="col-md-4 pr-0">
                    <div class="pt-1 pb-0 col-form-label form-control-label">
                      Ajuste de intervalo
                    </div>
                    <base-input input-group-classes="input-group-sm">
                      <input
                        @change="checkStayTime"
                        v-model="mix_design.formulation_cycle.interval_adjustment"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> minutos </small>
                      </template>
                    </base-input>
                  </div>
                </div>

                <div class="form-group row m-0 p-0 mb-1">
                  <div class="col-md-4 pr-0">
                    <div class="pt-1 pb-0 col-form-label form-control-label">
                      Caminhões necessários
                    </div>
                    <base-input input-group-classes="input-group-sm mt-2">
                      <input v-model="schedule.equipment" type="text" class="form-control form-control-sm" disabled />
                    </base-input>
                  </div>

                  <div class="col-md-8 pr-0">
                    <div class="pb-0 pt-1 col-form-label form-control-label">
                      Tipo de distribuição
                      <span class="text-danger">&nbsp;*&nbsp;</span>
                    </div>
                    <div class="col-md-12 d-flex justify-content-between p-0">
                      <base-button
                        block
                        size="sm"
                        type="success"
                        outline
                        class="text-capitalize mt-2"
                        :class="schedule.distribution_type === 1 ? 'active' : ''"
                        @click.prevent="changeDistributionType(1)"
                      >
                        Sequencial
                      </base-button>

                      <base-button
                        block
                        size="sm"
                        type="primary"
                        outline
                        class="text-capitalize"
                        :class="schedule.distribution_type === 2 ? 'active' : ''"
                        @click.prevent="changeDistributionType(2)"
                      >
                        Alternada
                      </base-button>
                    </div>
                  </div>
                </div>

                <div class="form-group row m-0 p-0 mb-1 mt-2">
                  <div class="col-md-12 pr-0 text-right d-flex justify-content-between">
                    <base-button
                      @click.prevent="handleEditTravelDistribution"
                      type="danger"
                      outline
                      size="sm"
                      style="margin-top: 0.5rem;"
                      block
                      :disabled="schedule.reseted_travels"
                    >
                      Visualizar viagens
                    </base-button>
                    <base-button
                      :disabled="
                        !this.schedule.company_plants_uuid ||
                          !this.schedule.concreting_date ||
                          !this.mix_design.formulation_cycle.time_construction_stay ||
                          !this.schedule.total_volume ||
                          !this.schedule.total_travels
                      "
                      type="danger"
                      outline
                      size="sm"
                      block
                      @click.prevent="handleTravelDistribution"
                    >
                      Gerar distribuição das viagens
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <base-button
              type="success"
              native-type="submit"
              :disabled="!schedule.piece_id || !mix_design.uuid || schedule.status === 0 || incompleteCycle"
              :loading="loadingSave"
              @click.prevent="save(0)"
              >Salvar</base-button
            >
          </div>
        </card>

        <card>
          <!-- Start Formulation || -->
          <div>
            <div class="row align-items-center mb-3 pb-1">
              <div class="col-12">
                <!-- Title -->
                <div class="border-header-success-bottom">
                  <h5 class="h4 p-1 mb-0">Serviços</h5>
                </div>
              </div>
            </div>

            <div :class="{['mb-3'] : schedule.services.length}" class="row">
              <div class="col-md-2 mb-2 mb-md-0">
                <base-button
                  @click.prevent="handleSearchService(schedule.services);changeScheduleServiceType(null)"
                  outline
                  block
                  size="lg"
                  type="success"
                  :class="{['active']: schedule.services.length}"
                  :disabled="loadingData"
                >
                  <i class="fas fa-plus"></i>
                  NOVO
                </base-button>
              </div>
              <div class="col-md-2 mb-2 mb-md-0">
                <base-button
                  @click.prevent="changeScheduleServiceType(0)"
                  :class="{['active']: schedule.service_type === 0}"
                  outline
                  block
                  size="lg"
                  type="primary"
                  :disabled="schedule.services.length || loadingData"
                >
                  <img class="mr-1" :src="`/img/icons/concrete-pump-${schedule.service_type === 0 ? 'white' : 'blue'}.png`" width="21px" height="21px" />
                  BOMBA EXTERNA
                </base-button>
              </div>
              <div @click.prevent="changeScheduleServiceType(1)" class="col-md-2 mb-2 mb-md-0">
                <base-button
                  :class="{['active']: schedule.service_type === 1}"
                  outline
                  block
                  size="lg"
                  type="light"
                  :disabled="schedule.services.length || loadingData"
                >
                  <i style="height: 21px;display: inline-block;margin-bottom: -5px;" class="sidebar-icon-concrete-pump mr-1"></i>
                  CONVENCIONAL
                </base-button>
              </div>
            </div>

            <div
              class="border-left border-warning border-3 p-2 d-flex justify-content-between mb-4"
              v-for="(service, indexService) in schedule.services"
              :key="indexService"
            >
              <div class="col-11">
                <div class="form-group row m-0 mb-4">
                 <div class="col-md-4 d-flex align-items-center pr-0">
                   <div class="col-9 p-0">
                      <h4 class="mb-0">{{ service.service.name }}</h4>
                      <h4 class="small mb-0">
                        {{ service.service.description }}
                      </h4>
                    </div>

                  <div class="col-3 mt-1 pr-0">
                    <base-button block @click.prevent="removeService(indexService, service.uuid)" size="sm" type="danger">
                      <i class="fa fa-trash text-white"></i>
                    </base-button>
                  </div>
                </div>
                <div v-if="schedule.companies_distances && schedule.companies_distances.length" class="col-md-4 pr-0 mt-2 mt-md-0">
                  <div class="pt-1 pb-0 col-form-label form-control-label">
                    Central
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                    <validation-provider rules="required">
                      <base-input input-group-classes="input-group-sm">
                        <el-select class="form-control-sm p-0" @change="getEquipmentsAndUsers(service)"  :disabled="service.status !== 0 && service.status !== 5" v-model="service.company_plant_uuid" size="mini" placeholder="Selecione" filterable>
                          <el-option
                            v-for="plant in schedule.companies_distances"
                            :key="plant.company_plant_uuid"
                            :label="plant.company_plant_name ? plant.company_plant_name : plant.label"
                            :value="plant.company_plant_uuid"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </validation-provider>
                </div>
                <div v-if="schedule.companies_distances && schedule.companies_distances.length" class="col-md-4 pr-0">
                  <div>
                    <div class="pt-1 pb-0 col-form-label form-control-label">
                      Cnpj emissor
                      <template v-if="service.company_plant_fixed_issuer_not_found">
                        <el-popover trigger="click"
                                    placement="right"
                        >
                          <h5 class="font-weight-normal m-0">CNPJ Emissor fixo não encontrado para central
                            selecionada.</h5>
                          <base-button outline slot="reference" size="sm" type="secundary"
                                       class="shadow-none text-indigo ml-n2" style="margin-top: -10px">
                            <img src="/img/icons/icons8/ios/error--v1_danger.png" width="18">
                          </base-button>
                        </el-popover>
                      </template>
                      <template v-else>
                        <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                      </template>
                    </div>
                      <validation-provider rules="required">
                        <base-input input-group-classes="input-group-sm">
                          <input
                            v-if="!service.issuers"
                            readonly="readonly"
                            v-model="service.issuer_business_name"
                            type="text"
                            class="form-control form-control-sm"
                          />
                          <el-select
                              @change="$forceUpdate()"
                              v-else
                              v-model="service.company_plant_issuer_id"
                              class="form-control-sm p-0"
                              size="mini"
                              placeholder="Selecione"
                              filterable
                              :disabled="loadingPlantIssuer || schedule.contract_proposal.issuer_fixed"
                            >
                            <el-option
                              v-for="issuer in service.issuers"
                              :key="issuer.id"
                              :label="issuer.business_name"
                              :value="issuer.id"
                            />
                          </el-select>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                </div>

                <div>
                  <div>
                    <div class="form-group row m-0 p-0 mb-1 mt-n3">
                      <div class="col-md-4 pr-0">
                        <div class="pt-1 pb-0 col-form-label form-control-label">
                          Volume previsto
                          <span class="text-danger">&nbsp;*&nbsp;</span>
                        </div>
                        <base-input input-group-classes="input-group-sm">
                          <input
                            v-on:input="service.volume = formatString($event.target.value, '0', '999.9', '1')"
                            v-model="service.volume"
                            :disabled="service.status !== 0 && service.status !== 5"
                            type="text"
                            class="form-control form-control-sm"
                          />
                          <template slot="append">
                            <small class="input-group-sm p-0 m-0"> m<sup>3</sup> </small>
                          </template>
                        </base-input>
                      </div>

                      <div class="col-md-4 pr-0">
                        <div class="pb-0 pt-1 col-form-label form-control-label">
                          Início previsto
                          <span class="text-danger">&nbsp;*&nbsp;</span>
                        </div>
                        <base-input input-group-classes="input-group-sm">
                          <input-date-time-picker :disabled="service.status !== 0 && service.status !== 5" v-model="service.start_at" />
                        </base-input>
                      </div>

                      <div class="col-md-4 pr-0">
                        <div class="pb-0 pt-1 col-form-label form-control-label">
                          Fim previsto
                          <span class="text-danger">&nbsp;*&nbsp;</span>
                        </div>
                        <base-input input-group-classes="input-group-sm">
                          <input-date-time-picker  :disabled="service.status !== 0 && service.status !== 5" v-model="service.end_at" />
                        </base-input>
                      </div>
                    </div>
                  </div>

                  <div v-show="!bySeller" class="form-group row m-0 p-0 mb-1">
                    <div class="col-md-6 pr-0">
                      <div class="pb-1 col-form-label form-control-label">
                        Equipamento
                      </div>
                      <validation-provider rules="required">
                        <base-input input-classes="form-control-sm">
                          <el-select
                            v-model="service.equipment_uuid"
                            size="mini"
                            placeholder="Selecione"
                            filterable
                            :disabled="loadingEquipment || service.status !== 0 && service.status !== 5"
                          >
                            <el-option label="Selecione" value=""> </el-option>
                            <el-option
                              v-if="
                                !service.service_price ||
                                  !service.service_price.service.equipment_category_uuid ||
                                  service.service_price.service.equipment_category_uuid === equipment.category.uuid
                              "
                              v-for="equipment in service.equipments_reference"
                              :key="equipment.uuid"
                              :label="equipment.code + ' - ' + equipment.plate"
                              :value="equipment.uuid"
                            ></el-option>
                          </el-select>
                        </base-input>
                      </validation-provider>
                    </div>

                    <div class="col-md-6 pr-0">
                      <div class="pb-1 col-form-label form-control-label">
                        Responsáveis
                      </div>
                      <validation-provider>
                        <base-input input-classes="form-control-sm">
                          <el-select
                            v-model="service.employers"
                            size="mini"
                            placeholder="Selecione"
                            filterable
                            multiple
                            @change="$forceUpdate()"
                            :disabled="loadingUser || service.status !== 0 && service.status !== 5 "
                          >
                            <el-option
                              v-for="employer in service.employers_reference"
                              :key="employer.uuid"
                              :label="employer.name"
                              :value="employer.user_uuid"
                            ></el-option>
                          </el-select>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>

                  <!-- Observaçoes -->
                  <div class="form-group row m-0 p-0 mb-1">
                  <div class="col-md-12 pr-0">
                    <div class="pt-1 pb-0 col-form-label form-control-label">
                      <div class="">
                        Observações
                      </div>
                    </div>
                      <validation-provider>
                        <base-input input-classes="form-control-sm">
                          <textarea v-model="service.observations" type="text" rows="2" class="form-control form-control-sm"> </textarea>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="schedule.services.length" class="modal-footer">
            <base-button
              type="success"
              native-type="submit"
              @click.prevent="save(0)"
              :loading="loadingSave"
              :disabled="!schedule.piece_id || !mix_design.uuid || schedule.status === 0 || incompleteCycle"
              >Salvar</base-button
            >
          </div>
        </card>
      </div>
    </div>
    <ModalAddMixe ref="addMixe" @setMixDesign="getMixDesign" />
    <ModalAddService ref="addService" @setServices="setServices" />
    <ModalTravelDistribution @distributionGenerated="setTravels" ref="travelDistribution" @endLoad="endLoading" />
    <ModalDistanceTravelPlant ref="distanceTravelPlant" @setDistances="saveDistances" />
    <ModalEditTravel @updatedTravels="updatedTravels" ref="editTravel" />
    <ModalAddResponsible @setContact="setContact" ref="addResponsible" />
    <ModalReschedule ref="reschedule" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { period_types } from "@/views/Modules/Technology/Mix/helpers/mixe";
import moment from "moment";
import ModalAddMixe from "./_ModalAddMixe";
import ModalAddService from "./_ModalAddService";
import ModalTravelDistribution from "./_ModalTravelDistribution";
import ModalEditTravel from "./_ModalEditTravel";
import ModalDistanceTravelPlant from "./_ModalDistanceTravelPlant";
import ModalReschedule from "./_ModalReschedule";
import ModalAddResponsible from "./_ModalAddResponsible";
import { checkNumberValue } from "@/helpers";
import { mask1, formatErrorValidation, formatDate } from "@/plugins";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import BackButton from "@/components/Utils/BackButton";

export default {
  name: "EditSchedule",
  components: {
    ModalTravelDistribution,
    ModalAddMixe,
    ModalAddService,
    ModalDistanceTravelPlant,
    ModalAddResponsible,
    ModalReschedule,
    ModalEditTravel,
    InputDateTimePicker,
    BackButton
  },
  data() {
    return {
      format_mixe: period_types,
      schedule: {
        company_plants_uuid: [],
        piece_id: null,
        request_date: null,
        concreting_date: null,
        address_construction_uuid: null,
        mix_concreted_piece_uuid: null,
        contact: {},
        orientations: null,
        piece_identification: null,
        companies_distances: [],
        responsible_receiver_uuid: null,
        max_volumes_per_travel: [],
        travels_quantity: [],
        total_volume: null,
        total_travels: null,
        interval_adjustment: null,
        contract_proposal_uuid: null,
        contract_proposal_formulation_uuid: null,
        distribution_type: 1,
        services: [],
        status: 14,
        travels: [],
        free_travels: 0,
        survey: false,
        interval_releases: null,
        await_customer_release: false,
        reseted_travels: false,
        service_type: null,
      },
      old_company_plants_uuid: [],
      old_concrete_date: null,
      concreted_pieces: [],
      loadingSave: false,
      loadingPlant: true,
      loadingContact: true,
      loadingData: true,
      stayTimeGreater: false,
      loadingEquipment: true,
      loadingUser: true,
      loadingPlantIssuer:false,
      addresses: [],
      old_plants: [],
      old_formulation_uuid: null,
      contract_proposal: {
        uuid: null,
        construction: {
          uuid: null,
          construction_name: null,
          customer_construction: {
            customer_name: null
          }
        },
        code: null
      },
      integrity_services: true,
      mix_design: {
        uuid: null,
        formulation_cycle: {
          time_construction_stay: null,
          interval_releases: null,
          interval_adjustment: null
        }
      },
      reschedule_travels: [],
      schedule_service_types: {
        '0': 'Bomba externa',
        '1': 'Convencional',
      },
    };
  },
  props:{
     bySeller: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      plants: "plant/activeItems",
      contacts: "customerConstructionConstructionsContact/fetch",
      contract_proposal_formulations: "formulations/show"
    }),
    incompleteCycle() {
     return this.schedule.companies_distances.findIndex(item => item.distance < 2 || item.send_duration < 2 || item.return_duration < 2) >= 0
    },
    highTravelDuration() {
      return this.schedule.companies_distances.findIndex(item =>  item.send_duration > 100 || item.return_duration > 100) >= 0
    }
  },
  watch: {
    /* Responsável por manipular os horários dos serviços de acordo com a data de concretagem */
    'schedule.concreting_date'(new_value, old_value) {
      if(new_value && old_value && new_value !== old_value) {
        const difference_minutes = this.$helper.dateDiff(old_value, new_value, false) / 60;
        if(difference_minutes !== 0) {
          const method_name = difference_minutes > 0 ? 'add' : 'subtract';
          this.schedule.services.map(function(item){
            item.start_at =  moment(item.start_at)[method_name](Math.abs(difference_minutes), 'minutes').format('YYYY-MM-DD HH:mm:ss');
            item.end_at =  moment(item.end_at)[method_name](Math.abs(difference_minutes), 'minutes').format('YYYY-MM-DD HH:mm:ss');
          })
        }
      }
    }
  },
  methods: {
    addMixe() {
      this.$refs.addMixe.openModal(this.schedule.contract_proposal_uuid, this.schedule.piece_id);
    },
    removeService(index, uuid) {
      if (this.schedule.services[index].code) {
        this.schedule.services.splice(index, 1);
        if(!this.schedule.services.length) {
          this.schedule.service_type = null;
        }
        return;
      }

      this.$Swal
        .confirmDelete()
        .then(result => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação."
            });

            this.$store
              .dispatch("scheduleService/destroy", uuid)
              .then(response => {
                this.$notify({
                  type: response.error_type,
                  message: response.message
                });
                this.schedule.services.splice(index, 1);
                if(!this.schedule.services.length) {
                  this.schedule.service_type = null;
                }
                this.$Progress.finish();
              })
              .catch(error => {
                this.$notify({
                  type: error.data.error_type,
                  message: error.data.message
                });
                this.$Progress.finish();
              });
          }
        })
        .catch(() => this.$Progress.finish());
    },
    handleSearchService(services) {
      this.$refs.addService.openModal(this.schedule.contract_proposal_uuid, services, 1);
    },
    handleAddResponsible() {
      this.$refs.addResponsible.openModal(this.schedule.construction_uuid);
    },
    endLoading() {
      this.loadingSave = false;
    },
    generateDistributionData() {
      let company_plants = [];
      let time_clear = this.mix_design.formulation_cycle.time_clear;
      let new_time_mixing = this.mix_design.formulation_cycle.time_mixing;
      let load_truck_time = this.mix_design.formulation_cycle.load_truck_time;
      this.schedule.companies_distances.map(function(item) {
        let data = {
          company_plant_uuid: item.company_plant_uuid,
          volume: item.volume ?? 0,
          max_volume_per_travel: item.max_volumes_per_travel ?? 0,
          travels: item.travels ?? 0,
          send_duration: item.send_duration ?? 0,
          return_duration: item.return_duration ?? 0,
          time_clear: time_clear ?? 0,
          new_time_mixing: new_time_mixing ?? 0,
          load_truck_time: load_truck_time ?? 0
        };
        company_plants.push(data);
      });
      let params = {
        distribution_type: this.schedule.distribution_type,
        time_construction_stay: this.mix_design.formulation_cycle.time_construction_stay,
        unload_interval: this.mix_design.formulation_cycle.interval_releases,
        concreting_date: this.schedule.concreting_date,
        total_travels: this.schedule.total_travels,
        interval_releases: this.mix_design.formulation_cycle.interval_adjustment,
        company_plants: company_plants
      };
      return params;
    },
    async handleTravelDistribution() {
      if (!this.schedule.reseted_travels) {
        this.$swal
          .fire({
            title: "Você tem certeza?",
            text: "Ao alterar esta informação você perderá os registros de viagens desta solicitação!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim, apague as viagens!",
            cancelButtonText: "Não, mantenha",
            customClass: {
              confirmButton: "btn btn-success btn-fill",
              cancelButton: "btn btn-danger btn-fill"
            },
            buttonsStyling: false
          })
          .then(result => {
            if (result.isConfirmed) {
              let params = this.generateDistributionData();
              this.$refs.travelDistribution.openModal(params, this.schedule.companies_distances);
            }
          });
      } else {
        let params = await this.generateDistributionData();
        this.$refs.travelDistribution.openModal(params, this.schedule.companies_distances);
      }
    },
    handleDistanceTravelPlant() {
       if (!this.schedule.reseted_travels) {
        this.$swal
          .fire({
            title: "Você tem certeza?",
            text: "Ao alterar esta informação você perderá os registros de viagens desta solicitação!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim, apague as viagens!",
            cancelButtonText: "Não, mantenha",
            customClass: {
              confirmButton: "btn btn-success btn-fill",
              cancelButton: "btn btn-danger btn-fill"
            },
            buttonsStyling: false
          })
          .then(result => {
            if (result.isConfirmed) {
              this.$refs.distanceTravelPlant.openModal(this.schedule.companies_distances);
              this.$forceUpdate();
            }
          });
       } else {
         this.$refs.distanceTravelPlant.openModal(this.schedule.companies_distances);
       }
    },
    /** Verifica se google conseguiu achar o endereço */
    checkDistances() {
     let required = ['distance','return_duration','send_duration'];
     let validated = true
     this.schedule.companies_distances.map(function(obj){
       for(var prop in obj) {
         if(required.includes(prop) && parseInt(obj[prop]) === 0) {
            validated = false;
            break;
         }
       }
     },this)
     return validated;
    },
    handleSearchResponsible(uuid) {
      this.$refs.searchResponsible.openModal(uuid);
    },
    changeDistributionType(value) {
      this.schedule.distribution_type = value;
    },
    saveDistances(distances) {
      this.schedule.companies_distances = distances;
      this.schedule.reseted_travels = true;
    },
    async handleReschedule() {
      let params = await this.generateDistributionData();
      this.$refs.reschedule.openModal(this.schedule, params, this.schedule.companies_distances, this.mix_design, this.concreted_pieces);
    },
    getMixDesign(mix_design) {
      this.mix_design = mix_design;
    },
    checkCompaniesIntegrity(companies) {
      let companies_uuid = [];
      let new_companies = [];
      companies.map(function(obj) {
        if (!companies_uuid.includes(obj.company_plant_uuid)) {
          companies_uuid.push(obj.company_plant_uuid);
          new_companies.push(obj);
        }
      });
      return new_companies;
    },
    updatedTravels(travels) {
      this.schedule.travels = travels;
      var self = this;
      this.schedule.companies_distances.map(function(obj) {
        obj.travels = self.schedule.travels.filter(item => item.send_plant_uuid === obj.company_plant_uuid).length;
      });
      this.$forceUpdate();
    },
    async handleEditTravelDistribution() {
      this.$refs.editTravel.openModal(this.schedule.uuid);
    },
    volumeCalc(value, index) {
      if (value % 1 != 0) {
        let decimals = value.split(".")[1].trim();
        let num = value.split(".")[0].trim();
        if (decimals > 5) {
          value = num + "." + "5";
        } else if (decimals < 5) {
          value = num + "." + "0";
        }
        this.schedule.companies_distances[index].volume = value;
      }

      this.getVolume(index);
      this.getTravelQuantity(index);
      this.$forceUpdate();
    },
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        this.$forceUpdate();
        return checkNumberValue(mask1(value.toString(), decimals, ".", true), min, max);
      }
      return checkNumberValue(value, min, max);
    },
    getVolume(index) {
      var soma = 0;
      this.schedule.companies_distances.map(function(item) {
        if (item.volume) {
          var value = parseFloat(item.volume);
          soma += value;
        }
      });
      this.schedule.total_volume = soma.toFixed(1);
      this.$forceUpdate();
    },
    /**
     * @description Função realiza a divisão de volumes pela quantidade max de volume por viagem
     * , setando arredondando para cima o valor de viagens no respectivo index.
     * @author {Gustavo Pontes}
     * @copyright Puzl © 2021
     * @version {0.1}
     * @param   {index} obrigatório   index dos volumes
     * @returns {}
     */
    getTravelQuantity(index) {
      this.getVolume(index);
      if (
        this.schedule.companies_distances[index] &&
        this.schedule.companies_distances[index].max_volumes_per_travel &&
        this.schedule.companies_distances[index].max_volumes_per_travel > 0 &&
        this.schedule.companies_distances[index].volume > 0
      ) {
        let value = this.schedule.companies_distances[index].volume / this.schedule.companies_distances[index].max_volumes_per_travel;
        // caso seja decimal, removemos as casas após a virgula e somamos 1 ao valor final.
        if (Number.isInteger(value) === false) {
          value = Math.trunc(value);
          value += 1;
        }
        this.schedule.companies_distances[index].travels = value;
        // calculando total de viagens
        let total_value = 0;
        this.schedule.companies_distances.map(function(item) {
          let travel_value = parseInt(item.travels);
          if (travel_value) {
            total_value += travel_value;
          }
        });
        this.schedule.total_travels = total_value;
        this.$forceUpdate();
      }
    },
    getDistance() {
      if (!this.schedule.reseted_travels) {
        this.$swal
          .fire({
            title: "Você tem certeza?",
            text:
              "Essa modificação afeta a distribuição das viagens, ao alterar esta informação você perderá os registros de viagens desta solicitação!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim, apague as viagens!",
            cancelButtonText: "Não, mantenha",
            customClass: {
              confirmButton: "btn btn-success btn-fill",
              cancelButton: "btn btn-danger btn-fill"
            },
            buttonsStyling: false
          })
          .then(result => {
            if (result.isConfirmed) {
              this.$Progress.start();
              this.loadingPlant = true;
              this.loadingData = true;
              this.$notify({
                type: "info",
                message: "Estamos trabalhando em sua solicitação."
              });
              var self = this;
              var allDistances = [];
              this.schedule.total_volume = null;
              this.schedule.total_travels = null;
              this.schedule.travels_quantity = [];
              this.schedule.max_volumes_per_travel = [];
              this.schedule.travels = [];
              self.schedule.company_plants_uuid.map(function(value, key) {
                let params = {
                  address_construction_uuid: self.schedule.address_construction_uuid,
                  company_uuid: value
                };
                allDistances.push(params);
              });
              self.$store
                .dispatch("customerConstruction/getDistanceByCompany", {
                  distances: allDistances
                })
                .then(response => {
                  self.schedule.companies_distances = response.data;
                  self.schedule.companies_distances.map(function(item, key) {
                    item.volume = null;
                    item.max_volume_per_travel = null;
                    item.travels = null;
                    item.new_time_mixing = null;
                    item.time_clear = null;
                  });
                  self.$Progress.finish();
                  this.loadingData = false;
                  this.loadingPlant = false;
                  this.$notify({
                    type: response.error_type,
                    message: response.message
                  });
                  this.old_company_plants_uuid = this.schedule.company_plant_uuid;
                  this.schedule.reseted_travels = true;
                });
            } else {
              this.schedule.company_plants_uuid = this.old_company_plants_uuid;
            }
          });
      } else {
        this.$Progress.start();
        this.loadingData = true;
        this.loadingPlant = true;
        this.$notify({
          type: "info",
          message: "Estamos trabalhando em sua solicitação."
        });
        var self = this;
        var allDistances = [];
        this.schedule.total_volume = null;
        this.schedule.total_travels = null;
        this.schedule.travels_quantity = [];
        this.schedule.max_volumes_per_travel = [];
        this.schedule.travels = [];
        self.schedule.company_plants_uuid.map(function(value, key) {
          let params = {
            address_construction_uuid: self.schedule.address_construction_uuid,
            company_uuid: value
          };
          allDistances.push(params);
        });
        self.$store
          .dispatch("customerConstruction/getDistanceByCompany", {
            distances: allDistances
          })
          .then(response => {
            self.schedule.companies_distances = response.data;
            self.schedule.companies_distances.map(function(item, key) {
              item.volume = null;
              item.max_volume_per_travel = null;
              item.travels = null;
              item.new_time_mixing = null;
              item.time_clear = null;
            });
            self.$Progress.finish();
            this.loadingData = false;
            this.loadingPlant = false;
            this.$notify({
              type: response.error_type,
              message: response.message
            });
          });
      }
    },
    setTravels(travels, time, condition) {
      this.schedule.travels = travels;
      this.schedule.reseted_travels = true;
      if (condition === false) {
        this.save(1);
      }
    },
    setServices(selected_service) {
      let service = {... selected_service}
      service.service.uuid = service.uuid;
      service.uuid = null;
      this.schedule.services.push(service);
      this.integrity_services = false;
      this.schedule.services.map(function(obj) {
        if (!obj.start_at) {
          obj.start_at = this.schedule.first_arrival_time;
          obj.end_at = this.schedule.last_exit_time;
        }
        if (!obj.volume) {
          obj.volume = this.schedule.total_volume;
        }
      }, this);
    },
    setNewConcreteDate() {
      if (!this.schedule.reseted_travels) {
        this.$swal
          .fire({
            title: "Você tem certeza?",
            text:
              "Essa modificação afeta a distribuição das viagens, ao alterar esta informação você perderá os registros de viagens desta solicitação!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim, apague as viagens!",
            cancelButtonText: "Não, mantenha",
            customClass: {
              confirmButton: "btn btn-success btn-fill",
              cancelButton: "btn btn-danger btn-fill"
            },
            buttonsStyling: false
          })
          .then(result => {
            if (result.isConfirmed) {
              this.schedule.reseted_travels = true;
            } else {
              this.schedule.concreting_date = this.old_concrete_date;
            }
          });
      }
    },
    finalFormat(schedule) {
      schedule.mix_concreted_piece_uuid = this.concreted_pieces.filter(item => item.id === schedule.piece_id)[0].uuid;
      schedule.time_construction_stay = this.mix_design.formulation_cycle.time_construction_stay;
      schedule.interval_releases = this.mix_design.formulation_cycle.interval_releases;
      schedule.interval_adjustment = this.mix_design.formulation_cycle.interval_adjustment;
      schedule.contract_proposal_formulation_uuid = this.mix_design.uuid;
      schedule.company_plants = this.checkCompaniesIntegrity(this.schedule.companies_distances);
      schedule.service_type = schedule.services.length ? null : schedule.service_type;
      let self = this;
      let new_services = [];
      schedule.services.map(function(obj, key) {
        let start_at = obj.start_at ? moment(obj.start_at).format("YYYY-MM-DD HH:mm:ss") : "";
        let end_at = obj.end_at ? moment(obj.end_at).format("YYYY-MM-DD HH:mm:ss") : "";
          let service = {
            uuid: obj.uuid,
            volume: obj.volume,
            start_at: start_at,
            end_at: end_at,
            service_uuid: obj.service.uuid,
            schedule_uuid: schedule.uuid,
            equipment_uuid: obj.equipment_uuid ?? null,
            employers: obj.employers,
            observations: obj.observations,
            company_plant_uuid: obj.company_plant_uuid,
            company_plant_issuer_id: obj.company_plant_issuer_id
          };
          new_services.push(service);
        if(self.bySeller) {
          service.status = 5;
        }
      });
      schedule.services_uuid = new_services;
      schedule.travels.map(function(obj, key) {
        obj.status = self.bySeller ? 9 : 0;
        obj.arrival_time = formatDate(obj.arrival_time);
        obj.construction_exit_time = formatDate(obj.construction_exit_time);
        obj.free_for_travel = formatDate(obj.free_for_travel);
        obj.load_truck_time = formatDate(obj.load_truck_time);
        obj.construction_id = schedule.contract_proposal.construction_id;
        obj.customer_construction_id = schedule.contract_proposal.customer_id;
      });
      schedule.changed_mix = this.old_formulation_uuid !== this.mix_design.uuid
      schedule.old_formulation_uuid = this.old_formulation_uuid;
      return schedule;
    },
    save(forwarded) {
       if(this.checkDistances() === false) {
         this.$notify({
            type: "danger",
            message: 'É necessário preencher todos parâmetros de viagens para Central!'
          });
        this.$refs.distanceTravelPlant.openModal(this.schedule.companies_distances);
        return false;
      }
      if (this.schedule.reseted_travels === false && this.objectsEqual(this.old_plants, this.schedule.companies_distances) === false) {
        this.$swal
          .fire({
            title: "Você tem certeza?",
            text:
              "Você realizou realizou alterações que impactam na distribuição das viagens, essa mudança irá gerar uma nova distribuição e não será possível rescuperar a anterior!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim, prosseguir!",
            cancelButtonText: "Não, mantenha",
            customClass: {
              confirmButton: "btn btn-success btn-fill",
              cancelButton: "btn btn-danger btn-fill"
            },
            buttonsStyling: false
          })
          .then(result => {
            if (result.isConfirmed) {
              this.schedule.reseted_travels = true;
              this.store(forwarded);
            }
          });
      } else {
        this.store(forwarded);
      }
    },
    async store(forwarded) {
      if (forwarded === 0) {
        this.$Progress.start();
        this.loadingSave = true;
      }
      if (this.schedule.travels.length === 0 && this.schedule.reseted_travels) {
        let params = await this.generateDistributionData();
        await this.$refs.travelDistribution.onlyCalcTravels(params, this.schedule.companies_distances);
        return false;
      }
      let schedule = this.finalFormat(this.schedule);
      this.$store
        .dispatch("schedule/update", schedule)
        .then(response => {
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.$router.push(this.bySeller ? "/operational/contract-proposal/schedule/" + this.schedule.contract_proposal_uuid : "/operational/schedule");
        })
        .catch(error => {
          if (error.status) {
            this.$notify({
              type: "danger",
              message: error.data.message
            });
            this.$Progress.finish();
            this.loadingSave = false;
          } else if (error.response && error.response.status && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors
            });
            this.$Progress.finish();
            this.loadingPlant = false;
            this.loadingSave = false;
          }
        });
    },
    objectsEqual(obj, compare) {
      var objectsAreSame = true;
      obj.map(function(item, key) {
        for (var propertyName in item) {
          if (propertyName !== "distance" && propertyName !== "send_duration" && propertyName !== "return_duration") {
            if (item[propertyName] !== compare[key][propertyName]) {
              objectsAreSame = false;
              break;
            }
          }
        }
      });
      return objectsAreSame;
    },
     async getEquipmentsAndUsers(service) {
      this.loadingEquipment = true;
      this.loadingUser = true;
      this.integrity_services = false;
      service.equipment = null;
      service.employers = [];
      this.getCompanyPlantIssuers(service);
      const equipment_category_id = await this.getEquipmentCategoryIdByContractProposalServiceUuid(service.service.uuid);
      this.$store.dispatch("equipment/getByCompanyPlantAndService", {
            filter : {
              company_plant_uuid : service.company_plant_uuid,
              status : 1, // ativo,
              with_equipment_restriction : service.service.description,
              category_id : equipment_category_id
            }
        }).then(async(response) => {
        service.equipments_reference = response.data
          await this.$store.dispatch("user/getByCompanyPlant", {company_plant_uuid : service.company_plant_uuid, type : 'service_driver'}).then(response => {
            service.employers_reference = response.data;
            this.loadingEquipment = false;
            this.loadingUser = false;
          });
      });
    },
    async getAllEquipmentsAndUsers() {
      this.loadingEquipment = true;
      this.loadingUser = true;
      for (let i =0; i < this.schedule.services.length; i++) {
        let already_exists = this.schedule.services.findIndex(item => item.company_plant_uuid === this.schedule.services[i].company_plant_uuid && typeof this.schedule.services[i].equipments_reference !== 'undefined');
        if(already_exists !== -1) {
          this.schedule.services[i].equipments_reference = this.schedule.services[already_exists].equipments_reference;
          this.schedule.services[i].employers_reference = this.schedule.services[already_exists].employers_reference;
        }
        else {
          const equipment_category_id = await this.getEquipmentCategoryIdByContractProposalServiceUuid(this.schedule.services[i].service.uuid);
          await this.$store.dispatch("equipment/getByCompanyPlantAndService", { filter : {
              company_plant_uuid : this.schedule.services[i].company_plant_uuid,
              status : 1, // ativo,
              with_equipment_restriction : this.schedule.services[i].service.description,
              category_id : equipment_category_id
            }}).then(response => {
            this.schedule.services[i].equipments_reference = response.data
          });
          await this.$store.dispatch("user/getByCompanyPlant", {company_plant_uuid : this.schedule.services[i].company_plant_uuid, type : 'service_driver'}).then(response => {
            this.schedule.services[i].employers_reference = response.data;
          });
        }
      }
      this.loadingEquipment = false;
      this.loadingUser = false;
    },
    cancelSchedule() {
      let string = this.schedule.status != 0 ? "cancelar programação" : "retomar";
      this.$swal
        .fire({
          title: "Você tem certeza?",
          text: "Você tem certeza que deseja " + string + " esta programação?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, " + string + "!",
          cancelButtonText: "Não, mantenha",
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill"
          },
          buttonsStyling: false
        })
        .then(result => {
          if (result.isConfirmed) {
            this.schedule.status = this.schedule.status != 0 ? 0 : 14;
            this.save(0);
          }
        });
    },
    resetMixDesign(openable = false) {
      this.mix_design = {
        uuid: null,
        formulation_cycle: {
          time_construction_stay: null,
          interval_releases: null,
          interval_adjustment: null
        }
      };
      if(openable === true) {
        this.addMixe();
      }
    },
    setContact(contact) {
      if(contact && contact.uuid) {
        this.schedule.responsible_receiver_uuid = contact.uuid
        this.schedule.contact = contact;
        return ;
      }
      this.schedule.contact = this.contacts.filter(item => item.uuid === this.schedule.responsible_receiver_uuid, this)[0];
    },
    async generateTravels() {
      let params = await this.generateDistributionData();
      this.$store.dispatch("scheduleTravelDistribution/fetchItems", params).then(response => {
        return response.data;
      });
    },
    checkStayTime() {
      let time_stay = this.mix_design.formulation_cycle.time_construction_stay ?? 0;
      let interval_releases = this.mix_design.formulation_cycle.interval_releases ?? 0;
      let interval_adjustment = this.mix_design.formulation_cycle.interval_adjustment ?? 0;
      if (time_stay > interval_releases + interval_adjustment && this.mix_design.formulation_cycle.interval_releases) {
        this.stayTimeGreater = true;
      } else {
        this.stayTimeGreater = false;
      }
    },
    formatCompanyPlants(plants) {
      var self = this;
      plants.map(function(item) {
        self.schedule.company_plants_uuid.push(item.company_plant_uuid);
      });
      this.old_company_plants_uuid = this.schedule.company_plants_uuid;
    },
    getCompanyPlantIssuers(service) {
      this.loadingPlantIssuer = true;
      service.company_plant_issuer_id = null;
      let id = this.plants.filter(item => item.uuid === service.company_plant_uuid)[0].id
      this.$store
        .dispatch(
          "plant/fetchCompanyPlantIssuersByPlant",
          {id:id}
        ).then(response => {
          service.issuers = response.data;
        if (response.data.length) {
          let default_plant_issuer = response.data.find((item) => item.default === 1) || response.data[0]
          /**
           * verifica se possui CNPJ Emissor fixo
           */
          if (this.schedule.contract_proposal.issuer_fixed) {
            let fixed_issuer_id = this.schedule.contract_proposal.company_plant_issuer_id_fixed
            /**
             * verifica se o CNPJ Emissor fixo pertence a central selecionada
             */
            let has_issuer = response.data.find((item) => item.id === fixed_issuer_id)

            if (has_issuer) {
              service.company_plant_issuer_id = fixed_issuer_id
              service.company_plant_fixed_issuer_not_found = false
            } else {
              service.company_plant_fixed_issuer_not_found = true
              service.company_plant_issuer_id = null
            }
          } else {
            if (default_plant_issuer && !service.company_plant_issuer_id) {
              service.company_plant_issuer_id = default_plant_issuer.id
            }
          }
        }
         this.loadingPlantIssuer = false;
      });
    },

    /**
     * Altera tipo de serviço da programação
     *
     * @param {integer|null} type
     */
    changeScheduleServiceType(type) {
      if(['Bomba externa', 'Convencional'].includes(this.schedule_service_types[type])) {
        this.schedule.services = [];
      }

      this.schedule.service_type = type;
    },
    /**
     * Altera central dos serviços
     */
    setScheduleServiceCompanyPlant() {
      if(this.schedule.services && this.schedule.services.length && this.schedule.company_plants_uuid && this.schedule.company_plants_uuid.length === 1) {
        const company_plant = this.plants.find(item => item.uuid === this.schedule.company_plants_uuid[0]);
        const company_plant_issuer = company_plant.company_issuers.find(item => item.default === 1);
        if(!company_plant || !company_plant_issuer) {
          return;
        }
        this.schedule.services.map(function(item) {
          item.issuers = company_plant.company_issuers;
          item.issuer_business_name = company_plant_issuer.business_name;
          item.company_plant_uuid = company_plant.uuid;
          item.company_plant_issuer_id = company_plant_issuer.id;
        }, this);
      }
    },
    async getEquipmentCategoryIdByContractProposalServiceUuid(contract_proposal_service_uuid) {
      const response = await this.$store.dispatch("contractService/getEquipmentCategoryIdByContractProposalServiceUuid", contract_proposal_service_uuid);
      return response.data;
    },
  },
  mounted() {
    this.$Progress.start();
    this.$notify({
      type: "info",
      message: "Estamos trabalhando em sua solicitação."
    });
    this.$store.dispatch("plant/fetchItemsActive").then(response => {
      this.$store.dispatch("schedule/show", this.$route.params.schedule_uuid).then(response => {
        let concreting_date = moment(formatDate(response.data.concreting_date)).format("YYYY-MM-DD HH:mm");
        this.schedule = {
          uuid: response.data.uuid ?? "",
          company_plants_uuid: [],
          piece_id: response.data.concreted_piece.id ?? "",
          request_date: formatDate(response.data.request_date) ?? "",
          concreting_date: concreting_date ?? "",
          address_construction_uuid: response.data.address_construction ?? "",
          mix_concreted_piece_uuid: response.data.concreted_piece.uuid ?? "",
          contact: {
            name: response.data.responsible_receiver.name,
            email: response.data.responsible_receiver.email,
            phone: response.data.responsible_receiver.phone
          },
          construction_uuid: response.data.contract_proposal.construction_uuid ?? "",
          orientations: response.data.orientations ?? "",
          piece_identification: response.data.piece_identification ?? "",
          companies_distances: response.data.company_plants ?? [],
          responsible_receiver_uuid: response.data.responsible_receiver.uuid ?? "",
          max_volumes_per_travel: [],
          travels_quantity: [],
          total_volume: response.data.total_volume ?? "",
          total_travels: response.data.total_travels ?? "",
          interval_adjustment: null,
          contract_proposal_uuid: response.data.contract_proposal.uuid ?? "",
          contract_proposal: response.data.contract_proposal ?? "",
          contract_proposal_formulation_uuid: response.data.contract_proposal_formulation_uuid ?? "",
          contract_proposal_formulation: response.data.contract_proposal_formulation ?? {},
          distribution_type: response.data.distribution_type ?? "",
          services: response.data.services ?? [],
          status: response.data.status.status ?? "",
          travels: response.data.travels ?? [],
          survey: response.data.survey ?? "",
          first_arrival_time: response.data.first_arrival_time ?? "",
          last_exit_time: response.data.last_exit_time ?? "",
          interval_releases: response.data.interval_releases ?? "",
          await_customer_release: response.data.await_customer_release ?? "",
          reseted_travels: false,
          free_travels: response.data.free_travels ?? "",
          service_type: response.data.service_type
        };

        if (this.bySeller && this.schedule.status !== 2 && this.schedule.status !== 3) {
          this.$notify({
            type: 'warning',
            message: 'A programação já foi aprovada, operação não permitida'
          })
          this.$Progress.finish();
          return this.$router.push({
            name: 'operational.contract_proposal_schedule.view',
            params: {
              contract_proposal_uuid: this.schedule.contract_proposal_uuid
            }
          })
        }
        (this.old_concrete_date = concreting_date ?? ""), (this.old_formulation_uuid = response.data.contract_proposal_formulation_uuid);
        this.concreted_pieces = response.data.pieces ?? [];
        this.formatCompanyPlants(response.data.company_plants);
        this.addresses = response.data.addresses;
        this.mix_design = this.schedule.contract_proposal_formulation;
        this.schedule.companies_distances.map(function(item) {
          item.label = item.company_plant_name;
        });
        var older_pieces = this.concreted_pieces;
        this.concreted_pieces = [];
        var self = this;
        var pieces_uuid = [];
        older_pieces.map(function(obj, key) {
          if (!pieces_uuid.includes(obj.uuid)) {
            self.concreted_pieces.push(obj);
            pieces_uuid.push(obj.uuid);
          }
        });
        // Se não vier ciclo na requisição
        if(!this.mix_design.formulation_cycle) {
          this.mix_design.formulation_cycle = {
            time_construction_stay: 0,
            interval_releases: 0,
            interval_adjustment: 0
          };
        }
        this.mix_design.formulation_cycle.time_construction_stay = response.data.time_construction_stay;
        this.mix_design.formulation_cycle.interval_releases = response.data.interval_releases;
        this.mix_design.formulation_cycle.interval_adjustment = response.data.interval_adjustment;
        this.mix_design.puzl_additions = this.mix_design.puzl_additions;
        this.old_plants = JSON.parse(JSON.stringify(this.schedule.companies_distances));
        if (!response.data.original_pieces_id.includes(this.schedule.piece_id)) {
          let older_piece = {
            id: response.data.concreted_piece.id,
            uuid: response.data.concreted_piece.uuid,
            description: response.data.concreted_piece.description
          };
          this.concreted_pieces.push(older_piece);
        }
        this.$notify({
          type: response.error_type,
          message: response.message
        });
        this.loadingEquipment = false;
        this.schedule.services.map(function(obj, key) {
          obj.start_at = moment(obj.start_at).format("YYYY-MM-DDTHH:mm");
          obj.end_at = moment(obj.end_at).format("YYYY-MM-DDTHH:mm");
        });
        this.getAllEquipmentsAndUsers();
        this.loadingContact = true;
        this.$store
          .dispatch("customerConstructionConstructionsContact/getByConstruction",
            { 
              uuid: this.schedule.construction_uuid,
              filter: false,
              is_active_contacts: true, 
            }
          )
          .then(response => {
            this.loadingContact = false;
          });
        this.loadingPlant = false;
        this.loadingData = false;
        this.$Progress.finish();
      });
    });
  }
};
</script>

<style>
body {
  overflow-y: scroll !important;
}

.el-picker-panel__footer {
  display: none !important;
}
.el-date-range-picker .el-picker-panel__footer {
  display: inherit !important;
  font-size: 13px;
}
.el-date-range-picker .el-picker-panel__footer .el-picker-panel__link-btn {
  color: #fff;
  background-color: #149e57;
  margin-right: 3px !important;
  box-shadow: none !important;
  border: none !important;
  padding: 0 10px !important;
}
.el-date-range-picker .el-picker-panel__footer .el-button--text {
  display: none !important;
}
.swal2-container.swal2-center {
  z-index: 10000000009936 !important;
}
.schedule_danger_message_stay_time {
  color: #ca2517;
  background-color: #f4c7c3;
  font-weight: 600;
  font-size: 11px;
  text-transform: uppercase;
  max-width: 490px !important;
}
</style>
