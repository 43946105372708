<template>
  <div class="row d-flex justify-content-end mt-1 mb-1">
    <div class="col-md-12 text-right">
      <a v-show="left" @click.prevent="swipeLeft" href="javascript:void(0)"
         class="float-scroll-left float-responsive-scroll-left"
         style="z-index: 2;background: transparent;border-radius: 30px;border: 1px solid #a19393;bottom: 250px !important;"><i
        class="fa-solid fa-chevron-left text-grey2" style="font-size: 20px;margin-top: 15px;"></i></a>

      <a v-show="right" @click.prevent="swipeRight" href="javascript:void(0)"
         class="float-scroll-right float-responsive-scroll"
         style="z-index: 2;background: transparent;border-radius: 30px;border: 1px solid #a19393;bottom: 250px !important;"><i
        class="fa-solid fa-chevron-right text-grey2" style="font-size: 20px;margin-top: 15px;"></i></a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HorizontalScrolling',
  props: ['parentRefs'],
  data() {
    return {
      left: true,
      right: true
    }
  },
  mounted() {
    if (this.parentRefs.content.scrollLeft === 0) {
      this.left = false
    }
  },
  methods: {
    /**
     * scrollTo - Horizontal Scrolling
     * @param {(HTMLElement ref)} element - Scroll Container
     * @param {number} scrollPixels - pixel to scroll
     * @param {number} duration -  Duration of scrolling animation in millisec
     */
    scrollTo(element, scrollPixels, duration) {
      const scrollPos = element.scrollLeft
      if (scrollPos === 0 && scrollPixels > 0) {
        this.left = true
      }
      if (scrollPos === 1000 && scrollPixels > 0) {
        this.right = false
      }
      if (scrollPixels < 0) {
        this.right = true
      }
      if (scrollPos === 100 && scrollPixels < 0) {
        this.left = false
      }
      // Condition to check if scrolling is required
      if (!((scrollPos === 0 || scrollPixels > 0) && (element.clientWidth + scrollPos === element.scrollWidth || scrollPixels < 0))) {
        // Get the start timestamp
        const startTime =
          'now' in window.performance
            ? performance.now()
            : new Date().getTime()

        const self = this
        function scroll(timestamp) {
          //Calculate the timeelapsed
          const timeElapsed = timestamp - startTime
          //Calculate progress
          const progress = Math.min(timeElapsed / duration, 1)
          //Set the scrolleft
          element.scrollLeft = scrollPos + scrollPixels * progress
          //Check if elapsed time is less then duration then call the requestAnimation, otherwise exit
          if (timeElapsed < duration) {
            //Request for animation
            window.requestAnimationFrame(scroll)
          } else {
            return
          }
        }

        //Call requestAnimationFrame on scroll function first time
        window.requestAnimationFrame(scroll)
      }
    },
    swipeLeft() {
      this.scrollTo(this.parentRefs.content, -100, 0)
    },
    swipeRight() {
      this.scrollTo(this.parentRefs.content, 100, 0)
    }
  }
}
</script>
<style>
.float-scroll-right {
  right: 5%;
}

.float-responsive-scroll {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 70px;
  background-color: #1a70b7;
  color: #FFF;
  border-radius: 10%;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
}

.float-responsive-scroll-left {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 70px;
  background-color: #1a70b7;
  color: #FFF;
  border-radius: 10%;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
}

.float-scroll-left {
  left: 10% !important;
}

.float-scroll-left-minimized {
  left: 20% !important;
}
</style>
