import * as types from './mutation_types'
import {destroy, update, order} from '@/store/baseStore'
import { createAxios } from "@/plugins/axios";

const endPoint = '/mix/concrete-piece/'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
    feature_levels: []
  },
  getters: {
    show: state => state.item,
    fetch: state => state.items,
    feature_levels: state => state.feature_levels.length ? state.feature_levels : [
      {
        value: 1,
        label: 'Baixa'
      },
      {
        value: 2,
        label: 'Média'
      },
      {
        value: 3,
        label: 'Alta'
      },
    ],
    activeItems(state) {
      return state.items.filter(item => item.status === true).sort((a, b) => a.description > b.description)
    },
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload
    },
    [types.SET_FEATURE_LEVEL](state, payload) {
      state.feature_levels = payload
    },
    [types.PUSH](state, payload) {
      state.items.push(payload)
    },
    [types.SHOW](state, payload) {
      state.item = payload
    },
    [types.DESTROY](state, uuid) {
      destroy(state, uuid)
    },
    [types.UPDATE](state, payload) {
      update(state, payload)
    },
  },
  actions: {
    fetchItems({commit}, params) {
      return createAxios().get(endPoint, {params: params})
        .then(({data}) => {
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchHistory({commit}, params) {
      return createAxios().get(`${endPoint}fetch-history`, {params: params})
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },
    getFeatureLevels({commit}, params) {
      return createAxios().get(`${endPoint}feature-level/formatted`)
        .then(({data}) => {
          commit(types.SET_FEATURE_LEVEL, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    add({commit, state}, payload) {
      return createAxios().post(endPoint, payload)
        .then(({data}) => {
          commit(types.PUSH, data.data)
          order(state, 'description')
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    update({commit}, payload) {
      return createAxios().put(endPoint + payload.uuid, payload)
        .then(({data}) => {
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    updateLevelResponsibilityPercentage({commit}, payload) {
      return createAxios().post(`${endPoint}feature-level/percentage`, payload)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    show({commit, dispatch}, uuid) {
      return createAxios().get(endPoint + uuid)
        .then(({data}) => {
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    destroy({commit, dispatch}, uuid) {
      return createAxios().delete(endPoint + uuid)
        .then(({data}) => {
          commit(types.DESTROY, uuid)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    updateStatus(_, payload) {
      return createAxios().put(`${endPoint}update-status/${payload.id}/${payload.status}`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    updateLevelFeature({commit, getters, state}, payload) {
      const index = state.items.findIndex((item) => item.id === payload.id);
      if (payload.addOrRemove == 0) {
        state.items[index].level_feature.push(payload.levelFeature)
      }
      if (payload.addOrRemove == 1) {
        if (state.items[index].level_feature.length < 2) {
          return Promise.reject('Não é possível remover todos os items!.');
        }
        const index_level_feature = state.items[index].level_feature.findIndex((item, index) => item === payload.levelFeature);
        delete state.items[index].level_feature[index_level_feature];
      }
      return createAxios().put(`${endPoint}update-level-feature/${payload.id}/${payload.levelFeature}/${payload.addOrRemove}/${payload.user}`)
        .then(({data}) => {
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
