export const destroy = (state, primary, key = 'items') => {
  if(!isNaN(primary))
  {
    return state[key].splice(state[key].findIndex((item) => item.id === primary), 1);
  }
  state[key].splice(state[key].findIndex((item) => item.uuid === primary), 1);
}
/**
 *
 * @param state
 * @param payload Resposta do servidor
 * @param key Chave de busca do store
 * @param search_key Chave de busca dos dados de comparação no array
 */
export const update = (state, payload, key = 'items', search_key = 'uuid') => {
  if (payload[search_key]) {
    state[key].splice(state[key].findIndex((item) => item[search_key] === payload[search_key]), 1, payload);
    return;
  }
  state[key].splice(state[key].findIndex((item) => item.id === payload.id), 1, payload)
}
export const order = (state, index, key = 'items', type = 'asc') => {
  state[key] = type === 'asc' ? state[key].sort((a,b) => (a[index].toString().toLocaleLowerCase() > b[index].toString().toLocaleLowerCase()))
    : state[key].sort((a,b) => ( b[index].toString().toLocaleLowerCase() - a[index].toString().toLocaleLowerCase()))
}
/**
 * Função para mesclar o array do cursorPaginate
 *
 * @param {object} state
 * @param {{next_page, data}} data array de items api
 * @param {string} key nome do state na store
 * @param {object} check_key campo de validação de duplicidade
 */
export const cursorPaginate = (state, data, key = 'items', check_key = null) => {
  let newState = data.data
  if (data.next_page) {
    newState = [...state[key], ...data.data]
    /* Tratamento de dados duplicados */
    if (!check_key) {
      check_key = newState[0].uuid === undefined ? 'id' : 'uuid';
    }
    newState =  [... new Map(newState.map((item) => [item[check_key], item])).values()]
  }
  state[key] = newState
}
export const paginate = (state, data, key = 'items', check_key = null, page = null) => {
  let newState = data.data
  if (data.next_page) {
    newState = [...state[key], ...data.data]
    /* Tratamento de dados duplicados */
    if (!check_key) {
      check_key = newState[0].uuid === undefined ? 'id' : 'uuid';
    }
    newState =  [... new Map(newState.map((item) => [item[check_key], item])).values()]
  }
  state[key] = newState
}

export const setUrlWithNextPage = (url, params) => {
  return params.next_page || url
}

export const formatResponseData = (data) => {
  return {
    code: 200,
    data: data.data.items,
    error: data.error,
    message: data.message,
    has_more: data.data.has_next_page,
    next_page: data.data.next_page,
  };
}


