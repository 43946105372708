<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(save)"
            autocomplete="off"
          >
            <div>
              <div class="form-group row m-0 p-0 mb-1">
                <div class="col-md-12">
                  <base-input
                    input-group-classes="input-group-sm"
                    group
                    class="mb-2"
                  >
                    <input
                      v-model="filter"
                      type="text"
                      class="form-control form-control-sm"
                    />
                    <div class="input-group-append" id="button-addon4">
                      <button
                        :disabled="!loading"
                        class="btn btn-outline-primary btn-sm form-control-sm p-1"
                        type="button"
                        @click.prevent="handleSearch()"
                      >
                        Pesquisar
                      </button>
                    </div>
                  </base-input>
                </div>
              </div>
            </div>

            <div>
              <div
                class="pl-3 pr-3 pt-2"
                v-for="(contact, indexContact) in contacts"
                :key="indexContact"
              >
                <div class="row d-flex justify-content-between">
                  <div class="col-9">
                    <h4 class="p-0">
                      {{ contact.name }}
                    </h4>
                    <h5 class="small p-0">
                      {{ contact.email }}
                    </h5>
                  </div>
                  <div
                    class="col-3 d-flex align-items-center justify-content-end"
                  >
                    <base-button
                      size="sm"
                      type="success"
                      @click.prevent="setConstruction(contact)"
                    >
                      <i class="ni ni-check-bold mt-1"></i>
                    </base-button>
                  </div>
                </div>
                <div class="dropdown-divider p-0 m-0"></div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()"
                >Cancelar</base-button
              >
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  components: {},
  name: "ModalSearchContact",
  data() {
    return {
      modal: false,
      title: "Pesquisar contatos",
      validated: false,
      loading: true,
      uuid: null,
      filter: null,
      selected_construction: null,
      contacts: []
    };
  },
  methods: {
    init() {
      this.$Progress.start();
      this.selected_construction = null;
      this.loading = false;
      let params = {
        uuid: this.uuid,
        filter: this.filter ? this.filter : false
      };
      return this.$store
        .dispatch(
          "customerConstructionConstructionsContact/getByConstruction",
          params
        )
        .then(response => {
          this.contacts = response.data;
          this.loading = true;
          this.$Progress.finish();
        });
    },
    handleSearch() {
      this.init();
    },
    closeModal() {
      this.modal = false;
    },
    async openModal(uuid) {
      this.uuid = uuid;
      await this.init();
      this.modal = true;
    },
    handleSearchConstruction(search_name) {
      this.modal = true;
    },
    setConstruction(contact) {
      this.$notify({
        type: "success",
        message: "Contato selecionado com sucesso!"
      });
      this.$emit("setContact", contact);
      this.filter = null;
      this.modal = false;
    }
  },
  created() {},
  mounted() {}
};
</script>

<style></style>
