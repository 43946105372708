<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(save)"
            autocomplete="off"
          >
            <div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 col-form-label form-control-label">
                  Status
                </label>
                <div class="col-md-7 pt-2">
                  <base-switch
                    v-model="customer.status"
                    type="success"
                    offText="inativo"
                    onText="ativo"
                    class="success"
                  >
                  </base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-5 pt-1 pb-0 col-form-label form-control-label"
                >
                  Cliente
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="customer.customer_name"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  CPF/CNPJ
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider :rules="'required|document'" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm" group class="mb-2">
                      <input type="text" v-model="customer.document" class="form-control form-control-sm"
                             v-mask="['###.###.###-##', '##.###.###/####-##']"
                             :class="customer.document.length > 0 && ! verifyDocument(customer.document) && 'is-invalid' || errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                    <small v-show="customer.document.length > 0 && ! verifyDocument(customer.document)"><i
                      class="fas fa-exclamation-triangle text-danger mr-2"></i>
                      <strong class="" style="color: #7e7e7e">CPF/CNPJ Inválido!</strong>
                    </small>
                  </validation-provider>
                </div>
              </div>

            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">
                Cancelar
              </base-button>
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                :loading="loadingSave"
              >
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
const {formatErrorValidation} = require("@/plugins")
import {verifyDocument} from "@/helpers";

export default {
  name: "ModalCreateCustomer",
  data() {
    return {
      modal: false,
      title: 'Cliente',
      verifyDocument: verifyDocument,
      customer: {
        status: true,
        customer_name: '',
        document: '',
      },
      loadingSave: false,
    }
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
  methods: {
    closeModal() {
      this.modal = false
    },
    handleCreateModal() {
      this.customer = {
        status: true,
        customer_name: '',
        document: '',
      }
      this.modal = true
    },
    save() {
      this.$Progress.start()
      this.loadingStore = true
      this.$store.dispatch('customerConstruction/add', this.customer)
        .then(response => {
          this.loadingStore = false
          this.modal = false
          this.$Progress.finish()
          this.$notify({type: response.error_type, message: response.message})
          this.$router.push({
            path: '/commercial/customer-construction/constructions',
            name: 'commercial.customer-construction.constructions',
            params: {customer_construction_uuid: response.data.uuid}
          })
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            let message = formatErrorValidation(error.response.data.errors)
            this.$notify({type: 'danger', message: message})
          }
          else {
            this.$notify({type: 'warning', message: error.data.exception})
            this.$store.dispatch('customerConstruction/getByDocument', {document: this.customer.document})
              .then((response) => {
                this.$router.push({
                  path: '/commercial/customer-construction/constructions',
                  name: 'commercial.customer-construction.constructions',
                  params: {customer_construction_uuid: response.data}
                })
              })
          }
          this.$Progress.finish()
          this.loadingStore = false
        })
    },
  },
}
</script>

<style scoped>

</style>
