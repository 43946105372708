<template>
  <div>
    <modal size="md" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">{{ modal.title }}</h5>
      </template>
      <div>
        <div class="row">
          <div class="col-md-12">
            <h1 class="text-center">
              <i class="fas fa-exclamation-circle fa-2x text-orange"></i>
            </h1>
            <h4 v-if="payload.status !== null" class="text-center mb-4" style="font-weight: normal">
              Alterar status para
              <span class=" text-uppercase font-weight-bold" :class="'text-' + bill_pay_types[payload.status].color">
                {{ bill_pay_types[payload.status].text }}
              </span>
            </h4>
            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-3 pb-0 pt-1 col-form-label form-control-label"> Observações </label>
              <div class="col-md-9">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                    <textarea v-model="payload.observation" type="text" style="height: 100px" rows="2" class="form-control form-control-sm">
                    </textarea>
                  </base-input>
                </validation-provider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <base-button type="secondary" @click="closeModal()"> Cancelar </base-button>
        <base-button @click.prevent="save()" type="success" native-type="submit" :loading="loading"> Salvar </base-button>
      </div>
    </modal>
  </div>
</template>

<script>
import billPayMixin from "../Mixins/bill_pay_mixin";
export default {
  name: "ModalChangeStatus",
  mixins: [billPayMixin],
  data() {
    return {
      modal: {
        title: "Alterar Status",
        create: false,
      },
      payload: {
        id: null,
        status: null,
        observation: null,
      },
      loading: false,
    };
  },
  methods: {
    openModal(id, status) {
      this.payload = {
        id: id,
        status: status,
        observation: null,
      };
      this.modal.create = true;
    },
    closeModal() {
      this.modal.create = false;
    },
    save() {
      let loader = this.$loading.show();
      this.loading = true;
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });
      this.$store
        .dispatch("billPay/changeInstallmentStatus", this.payload)
        .then((response) => {
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          loader.hide();
          this.loading = false;
          this.closeModal();
        })
        .catch((error) => {
          this.$notify({
            type: error.data.error_type,
            message: error.data.message,
          });
          loader.hide();
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
