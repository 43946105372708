<template>
  <div>
    <AppPageHeader>
      <template slot="search-bar">
        <div>
          <h3
            class="new-default-black-font m-0 mb-2 font-weight-normal"
          >
            <b class="h3">CÓDIGO:</b> {{ contract_proposal.code }}
          </h3>
          <h3
            class="new-default-black-font m-0 mb-2 font-weight-normal"
          >
            <b class="h3">CLIENTE:</b>
            {{
              contract_proposal &&
              contract_proposal.construction &&
              contract_proposal.construction.customer_construction.customer_name
            }}
          </h3>
          <h3
            class="new-default-black-font m-0 mb-2 font-weight-normal"
          >
            <b class="h3">OBRA:</b>
            {{
              contract_proposal && contract_proposal.construction && contract_proposal.construction.construction_name
            }}
          </h3>
        </div>
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions :isBackButtonEnabled="true">
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>
    <FormulationForm />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import FormulationForm from "./Forms/_FormulationForm.vue";
import {
  AppPageHeader,
  AppPageHeaderActions,
} from "../../../../../../components/AppGlobal";

export default {
  name: "CreateContractProposalFormulation",
  components: {
    AppPageHeader,
    AppPageHeaderActions,
    FormulationForm
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      contract_proposal: "contractProposal/show",
    }),
  },
  methods: {},
};
</script>
