<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <div class="row mt-n3">
          <div class="col-12">
            <div class="d-flex align-items-center">
              <img height="23px" src="/img/icons/icons8/ios/info-squared.png" class="mr-3" />
              <b class="text-dark mr-3">Identificação</b>
              <hr class="bg-secondary flex-grow-1 ml-2 mr-2">
            </div>
          </div>
        </div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(getEntityByDocument)"
            autocomplete="off"
          >
            <div class="form-group row m-0 p-0">
              <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                Cpf/Cnpj
              </label>
              <div class="col-md-8 mb-1 pr-4">
                <validation-provider :rules="'required|document'" v-slot="{errors}">
                  <base-input input-classes="form-control-sm">
                    <input
                      v-model="entityDocument"
                      type="text"
                      class="form-control form-control-sm"
                      :class="
                        entityDocument.length > 0 &&
                        ! verifyDocument(entityDocument) &&
                        'is-invalid' || errors[0] ? 'is-invalid' : 'is-valid'
                      "
                      v-mask="['###.###.###-##', '##.###.###/####-##']"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="modal-footer">
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
              >
                <img src="/img/icons/icons8/ios/search.png" height="22" class="mr-1" />
                Pesquisar
              </base-button>
            </div>
          </form>
        </validation-observer>
        <div v-if="entity">
          <div class="col-12 mt-n3">
            <div class="d-flex align-items-center">
              <img src="/img/icons/icons8/ios/settings-3--v1_gray.png" height="25px" class="mr-2">
              <b class="text-dark mr-3">Tomador</b>
              <hr class="bg-secondary flex-grow-1 ml-2 mr-2">
            </div>
            <div class="card mb-3">
              <div class="card-body">
                <b class="card-title text-dark" style="font-size: 12px;">
                  {{ entity.entity_name }}
                </b>
                <badge 
                  :style="
                    entity.status
                    ? 'background-color: #daf1e5; color: #149e57' :
                    'background-color: #fde1e1; color: #e05a50'
                  "
                  style="padding: 6px 18px;"
                  class="mt-n1 ml-2"
                >
                  <span style="font-size: 10.3px;" v-if="entity.status">Ativo</span>
                  <span style="font-size: 10.3px;" v-else>Bloqueado</span>
                </badge>
                <div class="text-dark" style="font-size: 11px;">
                  {{ entity.document }}
                </div>
              </div>
              <div class="row align-items-center" style="margin-bottom: 0px; margin-right: 0px;">
                <div class="col-md-12" style="text-align: right !important; padding: 0px">
                  <div class="card-blue-bottom">
                    <a 
                      style="height: 45px; display: block;" 
                      href="#" 
                      @click.prevent="setEntity(entity)"
                    >
                      <h3 style="padding: 8px; padding-right: 17px;">
                        <img src="/img/icons/icons8/ios/right-squared.png" height="17px" class="mr-1" />
                        <span :disabled="invalid" class="mr-1 text-white" style="font-size: 11px !important;">
                          Continuar
                        </span>
                      </h3>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="loadingData" class="row align-items-center">
          <div class="col-12">
            <div class="mb-3 card" style="height: 80px">
              <div class="card-body">
                <div class="row">
                  <div class="col-9" style="margin-top: -10px">
                    <SkeletonPuzl type="small"/>
                  </div>
                  <div class="col-2 text-right float-right" style="margin-top: -15px">
                    <SkeletonPuzl type="circle"/>
                  </div>
                  <div class="col-5" style="margin-top: -22px">
                    <SkeletonPuzl type="small"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PuzlEmptyData v-if="searchPerformed && !foundEntityData && !loadingData" />
      </div>
    </modal>
    <ModalCreateEntity ref="createEntity" @set_entity="setEntity" />
  </div>
</template>

<script>
import SkeletonPuzl from "@/components/SkeletonPuzl";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import ModalCreateEntity from "@/views/Modules/Configuration/Entity/Shared/_Create";
import {verifyDocument} from "@/helpers";

export default {
  name: "ModalSearchEntity",
  components: {
    SkeletonPuzl,
    PuzlEmptyData,
    ModalCreateEntity,
  },
  data() {
    return {
      modal: {
        title: "Pesquisa por tomador",
        create: false,
      },
      entityDocument: '',
      entity: null,
      
      contract_proposal: null,
      customer_construction_uuid: '',

      loadingData: false,
      verifyDocument: verifyDocument,
      foundEntityData: false,
      searchPerformed: false,
      invalid: false,
    };
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    /**
     * @param {object} contract_proposal
     */
    openModal(contract_proposal) {
      this.entityDocument = '';
      this.entity = null;
      this.contract_proposal = contract_proposal;
      this.foundEntityData = false;
      this.searchPerformed = false;
      this.modal.create = true;
    },
    getEntityByDocument() {
      this.entity = null;
      this.loadingData = true;
      this.searchPerformed = true;
      this.foundEntityData = false;
      this.$store.dispatch('entity/getEntityByDocument', {document: this.entityDocument}).then(response => {
        if (response.data) {
          this.entity = response.data;
          this.foundEntityData = true;
        } else {
          this.openModalCreateEntity();
        }
        this.loadingData = false;
      });
    },
    /**
     * Ao selecionar o tomador, seus dados serão preenchidos na edição da proposta/contrato.
     * @param {object} entity
     */
    setEntity(entity) {
      this.$emit('setEntity', entity);
      this.fetchAndUpdateConstructionData(entity);
      this.closeModal();
    },
    /**
     * Busca e atualiza as informações da obra de um cliente/obra com base em um documento fornecido.
     * @param {object} entity
     */
    fetchAndUpdateConstructionData(entity) {
      // Inicia a busca pelo cliente/obra pelo documento do tomador.
      this.$store.dispatch('customerConstruction/getByDocument', {document: entity.document}).then((response) => {
        const customerConstructionUuid = response.data;

        // Caso não encontre nenhum cliente/obra com o mesmo documento do tomador que foi cadastrado ou selecionado.
        if (customerConstructionUuid === null) {
          return;
        }

        // Recupera as obras relacionadas ao cliente/obra associado à proposta em edição.
        this.$store.dispatch(
          'customerConstructionConstructions/getConstructionsByCustomerConstruction',
          { uuid: this.contract_proposal.construction.customer_construction.uuid }
        )
        .then((response) => {
          const constructionUuidArray = response.data.map(item => item.uuid);

          // Atualiza do cliente/obra da obra com base na pesquisa por documento.
          this.$store.dispatch('customerConstructionConstructions/updateCustomerConstructionUUIDs', {
            construction_uuids: constructionUuidArray,
            customer_construction_uuid: customerConstructionUuid
          })
          .then((response) => {
            // Deleta o cliente/obra temporário.
            this.$store.dispatch('customerConstruction/destroy',this.contract_proposal.construction.customer_construction.uuid).then(() => {
              this.$notify({
                type: 'success',
                message: 'Contrato gerado com sucesso.'
              });
            });
          });
        });
      });
    },
    openModalCreateEntity() {
      let paramsForModal = {
        document: this.entityDocument
      };

      const open = true;
      const customer = false;
      const params = paramsForModal;
      const isDocumentDisabled = null;
      const customerConstruction = null;
      const construnctionAddress = this.contract_proposal.construction.default_address;

      // Caso o documento utilizado na pesquisa não exista, passamos o cliente/obra pra modal de cadastro do tomador.
      if (!this.foundEntityData) {
        var customerConstructionDocumentPending = this.contract_proposal.construction.customer_construction;
      }

      this.$refs.createEntity.openModal(
        open,
        customer,
        params,
        isDocumentDisabled,
        customerConstruction,
        construnctionAddress,
        customerConstructionDocumentPending || null,
      );
    },
  },
};
</script>

<style scoped>
.card-blue-bottom {
  border-top-left-radius: 35px;
  border-bottom-right-radius: 10px;
  background-color: #1b6eba;
  color: white;
  max-width: 140px;
  min-width: 140px;
  float: right;
}
.card {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
</style>
