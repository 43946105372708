<template>
<div>
    <modal :show.sync="modal">
        <template slot="header">
            <h5 class="modal-title">{{ title }}</h5>
        </template>
        <div>
            <div class="row m-0 p-0" v-show="loading">
                <div class="col-md-8  m-0 p-0">
                    <SkeletonPuzl type="button" />
                </div>
                <div class="col-md-4">
                    <SkeletonPuzl type="button" />
                </div>
            </div>

            <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator" v-show="!loading">
                <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
                    <div>
                        <div class="form-group row m-0 p-0 mb-1">
                            <label class="col-md-4 pb-1 col-form-label form-control-label">
                                Central
                            </label>
                            <div class="col-md-8">
                                <base-input input-classes="form-control-sm">
                                    <PuzlSelect
                                        v-model="equipment.company_plant_uuids"
                                        :items="[{uuid: 'all', name: 'Todos'}, ...$_companyPlants]"
                                        customKey="uuid"
                                        :multiple="true"
                                        :loading="loadingPlants"
                                        :disabled="loadingPlants"
                                        @input="setAll" />
                                </base-input>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <base-button type="secondary" @click="closeModal()">
                            Cancelar
                        </base-button>
                        <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                            Salvar
                        </base-button>
                    </div>
                </form>
            </validation-observer>
        </div>
    </modal>
</div>
</template>

<script>
import {
    mapGetters
} from "vuex";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import PuzlSelect from "@/components/PuzlSelect";
const {formatErrorValidation} = require("@/plugins")

export default {
    name: "ModalSetPlant",
    components: {
        SkeletonPuzl,
        PuzlSelect,
    },
    data() {
        return {
            modal: false,
            title: 'Alocação de central',
            loadingPlants: true,
            loadingSave: false,
            loading: true,
            equipment: {
                equipment_uuid: null,
                company_plant_uuids: null,
            },
            loadingStore: false,
            dispatch: null,
            equipment_plant: {},
            value: 1,
            flag: 0,
        };
    },
    computed: {
        ...mapGetters({
            $_companyPlants: "plant/activeItems",
        })
    },
    methods: {
        closeModal() {
            this.modal = false
        },
        openModal(uuid, dispatch) {
            this.loadData();
            this.flag = 1;
            this.dispatch = dispatch.toString()
            this.fetch(uuid)
            this.modal = true
        },
        fetch(uuid) {
            this.$Progress.start()
            this.loading = true
            this.$store.dispatch(this.dispatch + '/show', uuid).then(response => {
                this.equipment_plant = response.data
                let plants = this.equipment_plant.allocation.map(item => item.uuid)
                this.equipment = {
                    equipment_uuid: uuid,
                    company_plant_uuids: this.$route.query.modal ? null : plants,
                }
                this.loadingPlants = false
                this.loading = false
                this.$Progress.finish()
            })
        },
        setAll() {
            this.flag = this.value++;
            if (this.equipment.company_plant_uuids.includes('all')) {
                if (this.flag % 2 == 1) {
                    this.equipment.company_plant_uuids = []
                    for (let plant of this.$_companyPlants) {
                        this.equipment.company_plant_uuids.push(plant.uuid)
                    }
                }
                if (this.flag % 2 == 0) {
                    this.equipment.company_plant_uuids = []
                    for (let plant of this.$_companyPlants) {
                        this.equipment.company_plant_uuids.pop()
                    }
                }
            }
        },
        store() {
            this.$Progress.start()
            this.loadingStore = true
            this.$store.dispatch(this.dispatch + "/allocation", this.equipment).then(response => {
                this.loadingStore = false
                this.$Progress.finish()
                this.$notify({
                    type: response.error_type,
                    message: response.message
                });
                this.modal = false
            }).catch(error => {
                if (error.status) {
                    this.$Progress.finish()
                    this.loadingStore = false
                    this.$notify({
                        type: error.data.error_type,
                        message: error.data.message
                    })
                } else {
                    if (error.response.status === 422) {
                        let message = formatErrorValidation(error.response.data.errors)
                        this.$notify({
                            type: 'danger',
                            message: message
                        })
                        this.$Progress.finish()
                        this.loadingStore = false
                    } else {
                        this.$notify({
                            type: error.data.error_type,
                            message: error.data.message
                        })
                        this.$Progress.finish()
                        this.loadingStore = false
                    }
                }
            });
        },
        loadData() {
            this.loadingPlants = true;
            this.$store.dispatch('plant/getPlantsSimplified').then(() => {
                this.loadingPlants = false;
            });
        },
    },
}
</script>
