<template>
  <div>
    <modal :show.sync="modal.open">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div v-for="(item, index) in history" :key="index">
        <div class="row m-0 p-0">
            <div class="row col-md-12 mt-2 border-left border-3 border-success ml-1 p-1">
                <div class="row col-md-12 ml-0 pl-0 p-0">
                  <div class="col-md-2
                   pl-0">
                    <h4 class="text-success">Aplicações:</h4>
                  </div>
                  <div class="col-1" v-for="(item, index) in JSON.parse(item.status)" :key="index">
                    <span class="text-default font-weight-normal small numeric">{{ item == 1 ? 'BAIXA'
                    : item == 2 ? 'MÉDIA'
                    : item == 3 ? 'ALTA' : '' }}</span>
                  </div>

                </div>
                <div class="row col-md-12 text-left h3 mt-n2 small ml-0 pl-0">
                  {{ item.users.name }} | {{ $helper.parseDate(item.created_at) }}
                </div>
            </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PuzlSelect from "@/components/PuzlSelect";
export default {
  name: "History",
  components: { PuzlSelect },
  data() {
    return {
      modal: {
        title: 'Histórico de alterações',
        open: false,
      },
      history: [],
      loadingSave: false,
    }
  },
  computed: {

  },
  methods: {
    closeModal() {
      this.modal.open = false
    },
    handleHistoryModal(id) {
      this.$notify({ type: 'info', message: 'Estamos trabalhando em sua solicitação.' })
      this.$Progress.start()
      this.$store.dispatch('mixConcretePiece/fetchHistory', {id: id})
        .then((response) => {
          this.history = response.data
          this.modal.open = true
          this.$notify({ type: response.error_type, message: response.message })
          this.$Progress.finish()
        }).catch((error) => {
          if (error.response.status === 422) {
            let errors = Object.values(error.response.data.errors)
            errors = errors.flat().toString()
            this.$notify({ type: 'danger', message: errors })
          }
          this.$Progress.finish()
          this.loadingSave = false
        })
    }
  }
}
</script>

<style scoped></style>
