import * as types from './mutation_types'
import {destroy, update, cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'
import { createAxios } from "@/plugins/axios";

const endPoint = '/technology/test/report/+microservice'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
  },
  getters: {
    show: state => state.item,
    fetch: state => state.items,
    activeItems(state) {
      return state.items.filter(item => item.status === true)
    },
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload
    },
    [types.SHOW](state, payload) {
      state.item = payload
    },
  },
  actions: {
    fetchItems({commit, state}, params) {
      return createAxios().get(setUrlWithNextPage(endPoint, params),
        {params: params})
        .then(({data}) => {
          cursorPaginate(state, {data: data.data, next_page: params.next_page})
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    show({commit, dispatch}, uuid) {
      return createAxios().get(`${endPoint}${uuid}`)
        .then(({data}) => {
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getShowReport({commit, dispatch}, params) {
      return createAxios().get(`${endPoint}download/report`, {
        responseType: "blob",
        timeout: 30000,
        params: params
      })
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    add({commit, state}, payload) {
      return createAxios().post(endPoint, payload)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
