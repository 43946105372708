<template>
  <div>
    <base-header class="bg-gray-content">
      <puzl-breadcrumb />
      <!-- Header Menus -->
      <div class="row mb-3">
        <div class="col-md-2 mb-2">
          <base-button @click.prevent="handleModalCreate" block type="success" class="text-uppercase">
            <i class="fas fa-plus"></i> novo
          </base-button>
        </div>
        <div class="col-md-2 mb-2">
          <base-dropdown style="width: 100%;">
            <base-button slot="title-container" class="text-uppercase d-flex justify-content-center col-12" type="warning">
              <img width="16" src="/img/icons/product-documents.png"  class="mr-2"/>
                Cadastros
              <img width="16" style="transform:rotate(270deg);" src="/img/icons/lessThan.png" class="ml-2"/>
            </base-button>
              <span @click="handleOpenModalPaymentBrand" class="dropdown-item">
                <img src="/img/icons/icons8/ios/flag--v1_success.png" width="19px" height="19px" class="imgBaseButton ml-1">
                Bandeiras
              </span>
            </base-dropdown>
          </div>
        <div class="col-md-2" aria-current="page">
          <BackButton/>
        </div>
      </div>
    </base-header>
    <list />
    <modal-create ref="modalCreate"/>
    <ModalPaymentBrand ref="modalPaymentBrand" />
  </div>
</template>

<script>
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import List from "./_List.vue";
import ModalCreate from "./_ModalCreate.vue";
import BackButton from '@/components/Utils/BackButton'
import ModalPaymentBrand from "@/views/Modules/Financial/PaymentBrand/ModalPaymentBrand/_ModalPaymentBrand";

export default {
  name: "IndexPaymentIntermediary",
  components: { PuzlBreadcrumb, List, ModalCreate, BackButton, ModalPaymentBrand },
  data() {
    return {};
  },
  methods: {
     handleModalCreate() {
      this.$refs.modalCreate.openModal();
    },
    handleOpenModalPaymentBrand() {
      this.$refs.modalPaymentBrand.openModal();
    }
  },
  mounted() {
    this.$store.dispatch("user/getAllWithFilter", { filter: { is_user: 1, status : 1 }});
  }
};
</script>

<style></style>
