import * as types from './mutation_types'
import {cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'
import { createAxios } from "@/plugins/axios";;
const endPoint = '/commercial/general-resume'
export default {
  namespaced: true,
  state: {
    itemsByPlant: [],
    itemsBySeller: [],
    itemsByContract: [],
    itemsByCity: [],
    itemsByDay: [],
    widgets: []
  },
  getters:{
    fetchByPlant: state => state.itemsByPlant,
    fetchBySeller: state => state.itemsBySeller,
    fetchByContract: state => state.itemsByContract,
    fetchByCity: state => state.itemsByCity,
    fetchByDay: state => state.itemsByDay,
    getWidgets: state => state.widgets
  },
  mutations: {
    [types.SET_BY_PLANT] (state, payload){
      state.itemsByPlant = payload
    },
    [types.SET_BY_SELLER] (state, payload){
      state.itemsBySeller = payload
    },
    [types.SET_BY_CONTRACT] (state, payload){
      state.itemsByContract = payload
    },
    [types.SET_BY_CITY] (state, payload){
      state.itemsByCity = payload
    },
    [types.SET_BY_DAY] (state, payload){
      state.itemsByDay = payload
    },
    [types.SET_WIDGETS](state, payload) {
      state.widgets = payload
    }
  },
  actions: {
    fetchByPlantItemsPaginate({commit, state}, params) {
      return createAxios().get(setUrlWithNextPage(`${endPoint}/plant`, params),
        {params: params})
        .then(({data}) =>{
          cursorPaginate(state, {data : data.data, next_page: params.next_page}, 'itemsByPlant')
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchBySellerItemsPaginate({commit, state}, params) {
      return createAxios().get(setUrlWithNextPage(`${endPoint}/seller`, params),
        {params: params})
        .then(({data}) =>{
          cursorPaginate(state, {data : data.data, next_page: params.next_page}, 'itemsBySeller')
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchByContractItemsPaginate({commit, state}, params) {
      return createAxios().get(setUrlWithNextPage(`${endPoint}/contract`, params),
        {params: params})
        .then(({data}) =>{
          cursorPaginate(state, {data : data.data, next_page: params.next_page}, 'itemsByContract')
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchByCityItemsPaginate({commit, state}, params) {
      return createAxios().get(setUrlWithNextPage(`${endPoint}/city`, params),
        {params: params})
        .then(({data}) =>{
          cursorPaginate(state, {data : data.data, next_page: params.next_page}, 'itemsByCity')
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchByDay({commit}, params) {
      return createAxios().get(`${endPoint}/day`, {params: params})
        .then(({data}) => {
          commit(types.SET_BY_DAY, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getWidgets({commit}, params) {
      return createAxios().get(`${endPoint}/general-resume-totals`, {params: params})
        .then(({data}) => {
          commit(types.SET_WIDGETS, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getReportPdf({_}, params) {
      return createAxios().get(endPoint + '/report-pdf', {
        responseType: "blob",
        timeout: 60000,
        params: params
      })
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getReportExcel({_}, params) {
      return createAxios().get(endPoint + '/report-excel', {
        params: params
      })
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
