<template>
<div>
    <modal :show.sync="modal.create">
        <template slot="header">
            <h5 class="modal-title">{{ modal.title }}</h5>
        </template>
        <div>
            <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
                <form 
                class="needs-validation" 
                @submit.prevent="handleSubmit(storeCreate)" 
                autocomplete="off"
                >
                    <div>
                        <div class="card-header border-header-default-bottom p-2 grid-col--fixed-left">
                            <h5 class="mb-0" slot="title">Identificação</h5>
                        </div>
                        <div class="form-group row m-0 p-0">
                            <div class="col-md-6 pt-2">
                                <div class="col-form-label form-control-label">
                                    Status
                                </div>
                                <base-switch v-model="addServicePrice.status" type="success" offText="inativo" onText="ativo" class="success mt-n2"></base-switch>
                            </div>
                            <div class="col-md-6">
                                <div class="pt-1 pb-0 mb-1 mt-3 col-form-label form-control-label">
                                    Central
                                </div>
                                <base-input input-classes="form-control form-control-sm">
                                    <puzl-select 
                                        v-model="addServicePrice.company_plant_ids" 
                                        :items="listCompanyPlant" 
                                        :multiple="true"
                                        :loading="isloadingListCompanyPlant" 
                                        :disabled="isloadingListCompanyPlant" />
                                </base-input>
                            </div>
                        </div>
                        <div class="form-group row m-0 p-0 mb-1">
                            <div class="col-md-12">
                                <div class="pt-1 pb-0 mt-2 col-form-label form-control-label">
                                    Serviço
                                    <span class="text-danger">&nbsp;*</span>
                                </div>
                                <validation-provider rules="required" v-slot="{errors}">
                                    <div class="mt-1">
                                        <base-input input-classes="form-control form-control-sm">
                                            <puzl-select 
                                                v-model="addServicePrice.service_id" 
                                                :items="listServices" 
                                                label="service_name"
                                                 @input="showDescriptionByService"
                                                :loading="isloadingListServices" 
                                                :disabled="isloadingListServices" />
                                        </base-input>
                                    </div>
                                </validation-provider>
                            </div>
                        </div>
                        <div class="form-group row m-0 p-0 mb-1">
                            <div class="col-md-12">
                                <div class="pt-1 pb-0 col-form-label form-control-label">
                                    Descrição
                                    <span class="text-danger">&nbsp;*</span>
                                    <span class="btn btn-sm btn-success p-1 mb-1 ml-1" @click.prevent="handleCreateDescription()">
                                        <i class="fas fa-plus"></i> Novo
                                    </span>
                                </div>
                                <validation-provider rules="required" v-slot="{errors}">
                                    <base-input input-classes="form-control form-control-sm">
                                        <puzl-select 
                                            v-model="addServicePrice.description_id" 
                                            :items="listDescriptions" 
                                            label="description"
                                            :loading="isloadingDescriptions" 
                                            :disabled="isDisabledDescriptions" />
                                    </base-input>
                                </validation-provider>
                            </div>
                        </div>
                        <div class="form-group row m-0 p-0">
                            <div class="col-md-6 pt-2">
                                <div class="col-form-label form-control-label">
                                    Puzl Cliente
                                </div>
                                <base-switch v-model="addServicePrice.puzl_customer" offText="não" onText="sim" class="success mt-n2"></base-switch>
                            </div>
                            <div class="col-md-6">
                                <div class="pt-1 pb-0 mt-3 col-form-label form-control-label">
                                    <span class="text-purple">
                                        Acrescimo Puzl Cliente
                                    </span>
                                </div>
                                <base-input input-group-classes="input-group-sm">
                                    <input inputmode="numeric" v-model="addServicePrice.addition_puzl_customer" class="form-control form-control-sm" v-mask="['##']" />
                                    <template slot="append">
                                        <small class="input-group-sm p-0 m-0">
                                            %
                                        </small>
                                    </template>
                                </base-input>
                            </div>
                        </div>
                        <div class="border-header-danger-bottom mb-2 p-2">
                            <h5 class="mb-0" slot="title">Preço Mínimo</h5>
                        </div>             
                        <div class="form-group row m-0 p-0 mb-1">
                            <div class="col-md-4">
                                <div class="pt-1 pb-0 col-form-label form-control-label">
                                    Fixo
                                </div>
                                <base-input input-group-classes="input-group-sm">
                                    <input inputmode="numeric" v-model="addServicePrice.price_fixed" class="form-control form-control-sm" v-mask="['#.##','##.##', '###.##', '####.##']">
                                    <template slot="prepend">
                                        <small class="input-group-sm p-0 m-0">
                                            R$
                                        </small>
                                    </template>
                                </base-input>
                            </div>
                            <div class="col-md-4">
                                <div class="pt-1 pb-0 col-form-label form-control-label">
                                    Por m<sup>3</sup>
                                </div>
                                <base-input input-group-classes="input-group-sm">
                                    <input inputmode="numeric" v-model="addServicePrice.by_meters" class="form-control form-control-sm" v-mask="['#.##','##.##', '###.##', '####.##']" />
                                    <template slot="append">
                                        <small class="input-group-sm p-0 m-0">
                                            /m<sup>3</sup>
                                        </small>
                                    </template>
                                    <template slot="prepend">
                                        <small class="input-group-sm p-0 m-0">
                                            R$
                                        </small>
                                    </template>
                                </base-input>
                            </div>
                            <div class="col-md-4">
                                <div class="pt-1 pb-0 col-form-label form-control-label">
                                    Por Hora
                                </div>
                                <base-input input-group-classes="input-group-sm">
                                    <input inputmode="numeric" v-model="addServicePrice.by_hour" class="form-control form-control-sm" v-mask="['#.##','##.##', '###.##', '####.##']" />
                                    <template slot="append">
                                        <small class="input-group-sm p-0 m-0">
                                            /hora
                                        </small>
                                    </template>
                                    <template slot="prepend">
                                        <small class="input-group-sm p-0 m-0">
                                            R$
                                        </small>
                                    </template>
                                </base-input>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <base-button type="secondary" @click="closeModal('create')">Cancelar</base-button>
                        <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loading">Salvar</base-button>
                    </div>
                </form>
            </validation-observer>
        </div>
    </modal>
    <ModalCreateDescriptionService ref="modalCreateDescriptionService" @getDescription="showDescriptionByService" />
</div>
</template>
<script>
import ModalCreateDescriptionService from '../../ServiceDescription/Shared/_ModalCreate'
import PuzlSelect from "@/components/PuzlSelect"
const { formatErrorValidation } = require("@/plugins")
export default {
    components: {
        ModalCreateDescriptionService,
        PuzlSelect,
    },
    name: "AddServicePrice",
    data() {
        return {
            modal: {
                title: '',
                create: false,
                update: false,
            },
            validated: false,
            listServices: {},
            listDescriptions: {},
            listCompanyPlant: {},
            addServicePrice: {
                status: true,
                puzl_customer: false,
                increment_puzl_customer: '',
                price_fixed: '',
                by_meters: '',
                by_hour: '',
                description_id: '',
                service_id: '',
                company_plant_ids: [],
            },
            loading: false,
            isloadingListServices: false,
            isloadingDescriptions: false,
            isDisabledDescriptions: true,
            placeholderDescription: 'Selecione...',
            placeholderCompanyPlant: 'Selecione...',
            isloadingListCompanyPlant: false,
        };
    },
    methods: {
        closeModal(name) {
            this.modal[name] = false
        },
        openModal(name) {
            this.modal[name] = true
            this.loadData()
        },
        handleCreateDescription() {
            this.$refs.modalCreateDescriptionService.handleCreate()
        },
        handleCreateServicePrice() {

            this.addServicePrice = {
                status: true,
                puzl_customer: false,
                increment_puzl_customer: '',
                price_fixed: '',
                by_meters: '',
                by_hour: '',
                description_id: '',
                service_id: '',
            };

            this.modal.title = 'Adicionar Preço Mínimo Serviço';
            this.openModal('create');
        },
        showDescriptionByService() {
            this.placeholderDescription = 'Aguarde...'
            this.isloadingDescriptions = true;
            this.addServicePrice.description_id = '';
            let serviceId = this.addServicePrice.service_id;
            let service = this.listServices.filter(function (item) {
                return item.id == serviceId;
            });
            this.$Progress.start();

            this.$axios.get("/commercial/service/description/service/" + service[0].uuid)
                .then(({
                    data
                }) => {
                    this.listDescriptions = data.data;
                    this.placeholderDescription = 'Selecione'
                    this.isloadingDescriptions = false;
                    this.isDisabledDescriptions = false;
                    this.$Progress.finish();
                })
                .catch((error) => {});
        },
        storeCreate() {
            this.$Progress.start();
            this.loading = true;
            this.$notify({
                type: 'info',
                message: 'Estamos trabalhando em sua solicitação.'
            });
            this.$axios.post("/commercial/service/price", this.addServicePrice)
                .then(({
                    data
                }) => {
                    this.$notify({
                        type: data.error_type,
                        message: data.message
                    });
                    this.loading = false;
                    this.addServicePrice = {
                        status: true,
                        puzl_customer: false,
                        increment_puzl_customer: '',
                        price_fixed: '',
                        by_meters: '',
                        by_hour: '',
                        description_id: '',
                        service_id: '',
                    };
                    EventBus.$emit("getServicePrice");
                    this.closeModal('create');
                    this.loading = false;
                    this.$Progress.finish();
                })
                .catch(error => {
                    if (error.status) {
                        this.$Progress.finish()
                        this.loading = false
                        this.$notify({
                            type: error.data.error_type,
                            message: error.data.message
                        })
                    } else {
                        if (error.response.status === 422) {
                            let message = formatErrorValidation(error.response.data.errors)
                            this.$notify({
                                type: 'danger',
                                message: message
                            })
                            this.$Progress.finish()
                            this.loading = false
                        } else {
                            this.$notify({
                                type: error.data.error_type,
                                message: error.data.message
                            })
                            this.$Progress.finish()
                            this.loading = false
                        }
                    }
                });
        },
        getServices() {
            this.$Progress.start();
            this.isloadingListServices = true;
            this.listServices = [''];
            this.$axios.get("/configuration/service/active")
                .then(({
                    data
                }) => {
                    this.listServices = data.data;
                    this.$Progress.finish();
                    this.isloadingListServices = false;
                })
                .catch((error) => {
                    this.isloadingListServices = false;
                });
        },
        getCompanyPlant() {
            this.$Progress.start();
            this.placeholderCompanyPlant = "Aguarde..."
            this.isloadingListCompanyPlant = true;
            this.listCompanyPlant = [''];
            this.$axios.get("/company/plant/active")
                .then(({
                    data
                }) => {
                    this.listCompanyPlant = data.data;
                    this.$Progress.finish();
                    this.placeholderCompanyPlant = "Selecione"
                    this.isloadingListCompanyPlant = false;
                })
                .catch((error) => {
                    this.isloadingListCompanyPlant = false;
                });
        },
        loadData() {
            this.getServices();
            this.getCompanyPlant();
        },
    },
    mounted() {
        this.$refs.formValidator.validate();
        EventBus.$on("handleCreateServicePrice", () => {
            this.handleCreateServicePrice();
        });
        this.listDescriptions = [''];
    },
};
</script>

<style></style>