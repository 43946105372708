<template>
  <div>
  <base-header class="bg-gray-content">
    <PuzlBreadcrumb/>
  </base-header>
    <div class="container-fluid">
      <div class="row mb-3">
        <div class="col-md-2 mb-2">
          <back-button/>
        </div>
      </div>
      <list/>
    </div>
  </div>
</template>

<script>
import List from "./Shared/_List";
import BackButton from "@/components/Utils/BackButton";
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";

export default {
  name: "Index",
  components: {
    BackButton,
    List,
    PuzlBreadcrumb
  }
}
</script>

<style scoped>

</style>
