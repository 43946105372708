<template>
  <div>
    <div class="row card-wrapper" v-if="!loadingSkeleton">
      <div
        class="col-lg-12"
        v-for="mix_design in mix_designs"
        :key="mix_design.id"
      >
        <div class="card mb-3">
          <div class="card-body pb-1 pr-0">
            <div class="row align-items-center mb-2 mt-n3" v-if="mix_design">
              <div class="col-2 ml-2 mt-1">
                <div class="row">
                  <div
                    style="display: inline-flex; align-items: center"
                    class="col-5"
                  >
                    <img
                      class="pointer"
                      :src="getStatusIcon(mix_design.status)"
                      width="16px"
                      height="16px"
                    />
                    <h4
                      :style="getStatusColor(mix_design.status)"
                      class="font-weight-normal m-0 p-0 ml-1"
                    >
                      {{ getText(mix_design.status) }}
                    </h4>
                  </div>
                  <el-popover
                    v-if="!mix_design.last_updated.is_today"
                    trigger="click"
                    placement="bottom"
                    class="p-0 col-1 ml-2 mr-2"
                  >
                    <div>
                      <div class="col-12">
                        <span>
                          <span class="h4"> ÚLTIMA ALTERAÇÃO </span>
                          <br />
                          <br />
                          <span>
                            {{ mix_design.last_updated.user }}
                          </span>
                          <br />
                          <span>
                            {{ mix_design.last_updated.updated_at | parseDate }}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div
                      slot="reference"
                      size="sm"
                      type="secundary"
                      style="box-shadow: none"
                    >
                      <img
                        class="pointer"
                        src="/img/icons/icons8/ios/user-male-circle--v1.png"
                        width="26px"
                        height="26px"
                      />
                    </div>
                  </el-popover>
                  <el-popover
                    trigger="click"
                    placement="bottom"
                    class="p-0 col-1 mr-3 ml-2"
                  >
                    <span class="h4"> GRUPOS </span>
                    <br />
                    <br />
                    <div v-if="mix_design.groups">
                      <div
                        class="row align-items-center pb-0 mb-3"
                        v-for="(
                          category_group, indexCategoryGroup
                        ) in mix_design.groups"
                        :key="indexCategoryGroup"
                      >
                        <div class="col-md-12">
                          <div class="checklist-item checklist-item-primary">
                            <div class="checklist-info">
                              <h4 class="checklist-title mb-0">
                                {{ category_group.category_name }}
                              </h4>
                              <div
                                v-for="(
                                  group, indexGroup
                                ) in category_group.groups"
                                :key="indexGroup"
                              >
                                <h5 class="checklist-title small">
                                  {{ group }}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      slot="reference"
                      size="sm"
                      type="secundary"
                      style="box-shadow: none"
                    >
                      <img
                        class="pointer"
                        src="/img/icons/icons8/ios/parallel-tasks-blue.png"
                        width="26px"
                        height="26px"
                      />
                    </div>
                  </el-popover>
                  <el-popover
                    trigger="click"
                    placement="bottom"
                    class="p-0 col-1"
                  >
                    <div class="col-md-12 mb-3">
                      <h3>TRAÇOS</h3>
                    </div>
                    <div
                      v-for="(mixes, index) in mix_design.mix_design_mixes"
                      :key="index"
                    >
                      <div class="row align-items-center pb-0">
                        <div class="col-md-12">
                          <div class="" :class="checkStatus(mixes)">
                            <h4
                              class="checklist-title"
                              v-for="(mixe, indexItem, mixeIndex) in mixes"
                              v-if="
                                mixe &&
                                indexItem != 'binder' &&
                                mixe.status === 1
                              "
                              :key="mixeIndex"
                            >
                              <div class="row">
                                <div class="col-9">
                                  <span class="ml-4">
                                    {{ mixe.short_description }}
                                    {{
                                      format_mixe(mixe.period, mixe.period_type)
                                    }}
                                    {{ mixe.item_description }}
                                    {{ mixe.letter }}
                                  </span>
                                </div>
                                <a
                                  v-if="mixeIndex === 0"
                                  @click.prevent="copy(`TR-${mixe.code_id}`)"
                                  href="#"
                                  class="col-3"
                                >
                                  <badge
                                    v-if="mixe.code_id"
                                    style="font-size: 12px !important"
                                    title="Clique aqui para copiar"
                                    class="pointer font-weight-500 float-right mr-4"
                                    type="default"
                                  >
                                    TR-{{ mixe.code_id }}
                                  </badge>
                                </a>
                              </div>
                              <hr
                                v-if="
                                  index !==
                                    mix_design.mix_design_mixes.length - 1 &&
                                  mixe.status === 1
                                "
                                class="col-12 mb-2 mt-2"
                              />
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      slot="reference"
                      size="sm"
                      type="secundary"
                      style="box-shadow: none"
                    >
                      <img
                        class="pointer"
                        src="/img/icons/icons8/ios/test-tube-blue.png"
                        width="24px"
                        height="24px"
                      />
                    </div>
                  </el-popover>
                  <el-popover
                    v-if="
                      mix_design.adjustment_blocks &&
                      mix_design.adjustment_blocks.length
                    "
                    trigger="click"
                    placement="bottom"
                    class="p-0 col-1"
                  >
                    <div>
                      <h5
                        v-for="item in mix_design.adjustment_blocks_formatted"
                      >
                        {{ item }}
                      </h5>
                    </div>
                    <div
                      slot="reference"
                      size="sm"
                      type="secundary"
                      style="box-shadow: none"
                    >
                      <img
                        class="pointer ml-2"
                        src="/img/icons/icons8/ios/cancel-2_danger.png"
                        width="26px"
                        height="26px"
                      />
                    </div>
                  </el-popover>
                  <el-popover
                    v-if="mix_design.last_updated.is_today"
                    trigger="click"
                    placement="bottom"
                    class="p-0 col-1 ml-3"
                  >
                    <div>
                      <div class="row" v-if="mix_design.last_updated.user">
                        <div
                          class="col-lg-12"
                          v-if="mix_design.last_updated.is_today"
                        >
                          <span class="h4">{{
                            mix_design.last_updated.user
                          }}</span>
                          -
                          <badge type="success">Hoje</badge>
                          <br />
                          <span style="color: #149e57">{{
                            mix_design.last_updated.updated_at | parseDate
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div
                      slot="reference"
                      size="sm"
                      type="secundary"
                      style="box-shadow: none"
                    >
                      <img
                        class="pointer"
                        src="/img/icons/icons8/ios/checked--v1.png"
                        width="26px"
                        height="26px"
                      />
                    </div>
                  </el-popover>
                </div>
                <div class="row mt-4">
                  <div class="col-12 row">
                    <h5 class="col-12 ml-2 font-weight-400">
                      {{ mix_design.plant.name }}
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-5">
                <div class="row">
                  <div class="col-12 mt-2 ml-2">
                    <span class="h3" v-if="mix_design.type">
                      {{ mix_design.type.technical_description || "" }}
                    </span>
                    <span v-if="mix_design.subtype">
                      | {{ mix_design.subtype.technical_description || "" }}
                    </span>
                    <span v-if="mix_design.classification">
                      |
                      {{
                        mix_design.classification.technical_description || ""
                      }}
                    </span>
                  </div>
                </div>
                <div class="ml-2 mt-3">
                  <h5
                    class="h3 mb-1"
                    v-for="(feature, index) in mix_design.feature"
                    :key="index"
                    style="display: inline-block"
                  >
                    <span
                      class="mt-n1 ml-1 mr-1"
                      v-if="feature.short_description && index !== 0"
                      style="font-size: 20px !important"
                    >
                      +</span
                    >
                    {{ feature.short_description }}
                    {{ format_mixe(feature.period, feature.period_type) }}
                    {{ feature.measured_unit && feature.measured_unit.letter }}
                  </h5>
                </div>
              </div>
              <div class="col-4 row">
                <div v-if="!mix_design.slump_final.length" class="col-6">
                  <div class="card mb-n1 align-items-center">
                    <div class="card-body">
                      <div class="row">
                        <b
                          class="col-12 text-center responsive-font"
                          style="
                            white-space: nowrap;
                            display: flex;
                            align-items: center;
                          "
                        >
                          <img
                            class="mr-2"
                            src="/img/icons/icons8/ios/vlc.png"
                            height="23px"
                          />
                          {{ mix_design.slumpFlow.nomenclature }}
                        </b>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="col-6">
                  <div class="card mb-n2" style="overflow: hidden">
                    <div class="card-body">
                      <div class="custom-card"></div>
                      <div class="row">
                        <b
                          class="col-lg-8 col-7 text-center responsive-font"
                          style="white-space: nowrap"
                        >
                          <img
                            class="mr-2"
                            src="/img/icons/icons8/ios/vlc.png"
                            height="24px"
                          />
                          {{ mix_design.slumpFlow.nomenclature }}
                        </b>
                        <div
                          class="col-lg-4 col-5 text-center align-items-center"
                        >
                          <el-popover
                            v-if="mix_design.slump_final.length"
                            trigger="click"
                            placement="bottom"
                            class="text-center"
                          >
                            <h5
                              class="text-center"
                              v-for="item in mix_design.slump_final"
                            >
                              {{ item.nomenclature }}
                            </h5>
                            <div
                              slot="reference"
                              size="sm"
                              type="secundary"
                              style="box-shadow: none"
                            >
                              <img
                                class="ml-2 mb-n1 align-items-center"
                                src="/img/icons/banks/0.png"
                                height="23px"
                              />
                            </div>
                          </el-popover>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="card mb-n2 align-items-center">
                    <div class="card-body">
                      <div class="row">
                        <b
                          class="col-12 responsive-font"
                          style="
                            white-space: nowrap;
                            display: flex;
                            align-items: center;
                          "
                        >
                          <img
                            src="/img/icons/icons8/ios/wheelbarrow.png"
                            height="25px"
                            class="mr-1 mt-n1"
                          />
                          {{ mix_design.maximum_size.description || "" }}
                        </b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-1 row">
                <div class="col-12">
                  <base-dropdown menuOnRight style="display: block">
                    <base-button
                      style="
                        border: none !important;
                        box-shadow: none !important;
                      "
                      slot="title-container"
                      type="invisible"
                      class="mr-0 pr-0 float-right dropdown-toggle rounded"
                    >
                      <img
                        src="/img/icons/icons8/ios/settings--v1.png"
                        width="25px"
                        height="25px"
                      />
                    </base-button>
                    <router-link
                      :to="{
                        name: 'Formulações Edição',
                        path: '/technology/mix/mixdesigns/edit',
                        params: { mix_design_uuid: mix_design.uuid },
                      }"
                    >
                      <a class="dropdown-item">
                        <img
                          src="/img/icons/icons8/ios/create-new-yellow.png"
                          width="21px"
                          height="21px"
                        />
                        Editar
                      </a>
                    </router-link>
                    <div class="dropdown-divider p-0 m-0"></div>
                    <router-link
                      class="float-right mr-1"
                      :to="{
                        path: '/technology/mix/resume/' + mix_design.uuid,
                      }"
                    >
                      <a class="dropdown-item">
                        <img
                          src="/img/icons/icons8/ios/test-tube-blue.png"
                          width="21px"
                          height="21px"
                        />
                        Traços
                      </a>
                    </router-link>
                    <div class="dropdown-divider p-0 m-0"></div>

                    <a
                      class="dropdown-item"
                      @click.prevent="handleReplicate(mix_design)"
                    >
                      <img
                        src="/img/icons/icons8/ios/copy--v1-green.png"
                        width="21px"
                        height="21px"
                      />
                      Replicar
                    </a>
                    <div
                      v-if="mix_design.status == 2"
                      class="dropdown-divider p-0 m-0"
                    ></div>

                    <a
                      v-if="mix_design.status == 2"
                      class="dropdown-item mb-n1"
                      @click="handleDeleteMixDesign(mix_design.uuid)"
                    >
                      <img
                        src="/img/icons/icons8/ios/delete-red.png"
                        width="21px"
                        height="21px"
                      />
                      Excluir
                    </a>
                  </base-dropdown>
                </div>
                <div class="col-12 mt-2 mb-2">
                  <router-link
                    class="ml-5"
                    :to="{
                      path: '/technology/mix/resume/' + mix_design.uuid,
                    }"
                  >
                    <img
                      src="/img/icons/icons8/ios/health-graph-green.png"
                      width="25px"
                      height="25px"
                    />
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TableMix",
  props: {
    mix_designs: null,
    loadingSkeleton: Boolean,
    format_mixe: Function,
  },
  components: {},
  data() {
    return {};
  },
  methods: {
    checkStatus(mixe) {
      let status = null;
      if (mixe[0]) {
        status = mixe[0].status;
      }
      switch (status) {
        case 2:
          return "checklist-item-gray";
        case 1:
          return "checklist-item-success";
        case 0:
          return "checklist-item-danger";
        case 5:
          return "checklist-item-purple";
        default:
          return "checklist-item-info";
          break;
      }
    },

    getStatusIcon(status) {
      switch (status) {
        case 0:
          return "/img/icons/icons8/ios/shutdown--v1_danger.png";
        case 1:
          return "/img/icons/icons8/ios/ok-grenn.png";
        case 2:
          return "/img/icons/icons8/ios/vector-gray.png";
        default:
          return "/img/icons/icons8/ios/ok-grenn.png";
      }
    },

    getStatusColor(status) {
      switch (status) {
        case 0:
          return "color: #db4539";
        case 1:
          return "color: #149e57";
        case 2:
          return "color: #525f7f";
        default:
          return "color: #149e57";
      }
    },

    getText(status) {
      switch (status) {
        case 1:
          return "Ativo";
        case 2:
          return "rascunho";
        default:
          return "inativo";
      }
    },

    copy(val) {
      this.$helper.copy(val);
      this.$notify({
        type: "success",
        message: "Código do traço copiado com sucesso!",
      });
    },
  },
};
</script>
<style scoped>
.card-blue-bottom {
  border-top-left-radius: 35px;
  background-color: #1b6eba;
  color: white;
  max-width: 140px;
  min-width: 140px;
  float: right;
}

.card {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) !important;
}

.card-head {
  width: 100%;
  border: none;
  font-size: 0.75rem;
  padding: 3px;
  padding-bottom: 4px;
  letter-spacing: 0.025em;
}

.custom-card {
  position: absolute;
  top: 0;
  right: 0;
  width: 35%;
  height: 100%;
  background-color: #d1e3f1;
  filter: blur(1px);
}

/** Diminuí a fonte em telas medias e deixa tamanho padrão em telas grandes ou celular */
@media (min-width: 767px) and (max-width: 1317px) {
  .responsive-font {
    font-size: 11px;
  }
}
@media (max-width: 766px), (min-width: 1318px) {
  .responsive-font {
    font-size: 13px;
  }
}
</style>
