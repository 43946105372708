import { PagerType, initPagerType } from "../../../../shared/types";
import { CmcListType } from "./CmcListType";

/**
 * Type para retorno paginado da listagem
 *
 * @typedef {(
 *   PagerType &
 *   { items: Array<CmcListType> }
 * )} CmcListPagerType
 */
export const CmcListPagerType = {};

/**
 * Inicializar CmcListPagerType
 * 
 * @returns {CmcListPagerType}
 */
export const initCmcListPagerType = () => {
  return {
    ...initPagerType(),
    items: [],
  }
};

