<template>
<div>
	<base-header class="bg-gray-content">
		<div class="row align-items-center py-4">
			<div class="col-lg-12 col-12">
					<PuzlBreadcrumb/>
			</div>
			<div class="col-md-2 mb-2 p-1">
				<back-button/>
			</div>			
		</div>
	</base-header>
    <ListAntecipationBalances />
</div>
</template>

<script>
import ListAntecipationBalances from "./Shared/_List";
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import BackButton from "@/components/Utils/BackButton";

export default {
	name: "Index",
	components: {
			ListAntecipationBalances,
			PuzlBreadcrumb,
			BackButton,
	},
}
</script>

<style></style>
