<template>
  <div class="row card-wrapper" v-show="servicesPrice.length && !loadingSkeleton">
    <div class="col-lg-4" v-for="(item, index) in servicesPrice" :key="index">
      <!-- Basic with action button -->
      <div class="card">
        <div class="card-header pointer" style="padding: 0px !important; height: 22px">
          <div class="row">
            <div class="col-md-12">
              <base-button @click="changeStatus(item)" size="sm"
                :style="{ backgroundColor: item.status ? '#149E57' : '#DB4539' }"
                class="text-white text-uppercase btn-header" style="opacity: 100%; color: #4f40ff; border: none;"
                block>
                {{ item.status ? "Ativo" : "Inativo" }}
              </base-button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <!-- Card header -->
          <div class="row">
            <div class="col-8">
              <el-popover trigger="click" placement="right" class="p-0 pr-1">
                <h5 slot="title" class="mb-0 ">Centrais</h5>
                <div class="row align-items-center pb-0 mb-3">
                  <div class="col-md-12">
                    <div class="checklist-item checklist-item-primary"
                      v-for="(itemPlant, indexPlant) in item.company_plants" :key="indexPlant">
                      <div class="checklist-info">
                        <h5 class="h4">
                          {{ itemPlant.name }}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <base-button outline slot="reference" size="sm" type="secundary"
                  class="p-0 m-0 shadow-none text-indigo">
                  <img src="/img/icons/chemical-plant-grey.png" width="30px" height="30px" />
                </base-button>
              </el-popover>
              <el-popover trigger="click" placement="right" class="p-0 pr-1">
                <h5 slot="title" class="mb-0 ">Preços</h5>
                <div class="row align-items-center pb-0 mb-3">
                  <div class="col-md-12">
                    <div class="checklist-item checklist-item-primary">
                      <div class="checklist-info">
                        <div class="row" v-if="item.price_fixed">
                          <div class="col-md-12">
                            <h5 class="h4 small">
                              Preço mínimo <small>(fixo)</small>
                              <span class="h4">
                                {{ item.price_fixed | currency('R$') }}
                              </span>
                            </h5>
                          </div>
                        </div>

                        <div class="row" v-if="item.by_meters">
                          <div class="col-md-12">
                            <h5 class="h4 small">
                              Preço por /m<sup>3</sup>
                              {{ item.by_meters | currency('R$') }}
                            </h5>
                          </div>
                        </div>

                        <div class="row" v-if="item.by_hour">
                          <div class="col-md-12">
                            <h5 class="h4 small">
                              Preço por /hr
                              {{ item.by_hour | currency('R$') }}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <base-button outline slot="reference" size="sm" type="secundary"
                  class="p-0 m-0 shadow-none text-indigo">
                  <img src="/img/icons/price-tag-usd-green.png" width="30px" height="30px" />
                </base-button>
              </el-popover>
            </div>
            <div class="col-4 text-right">
              <base-dropdown menuOnRight>
                <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                  <i class="fas fa-cog"></i>
                </base-button>
                <a class="dropdown-item" @click="handleServicePriceEdit(item.uuid)">
                  <i class="fas fa-edit text-warning"></i>
                  Editar
                </a>
                <div class="dropdown-divider p-0 m-0"></div>
                <a class="dropdown-item" @click="handleDelete(item.uuid)">
                  <i class="fas fa-times text-danger"></i>
                  Excluir
                </a>
              </base-dropdown>
            </div>
          </div>
        </div>
        <div class="col-8 mt-n3 mb-1">
          <!-- Title -->
          <h5 class="h4 mb-0">
            {{ item.service.service_name }}
          </h5>
        </div>
        <!-- Card body -->
        <div class="col-md-12">
          <h5 class="h4 mb-0">
            {{ item.description.description }}
          </h5>
        </div>
        <div class="mb-3"></div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "ListServicePrice",
  props: {
    handleDelete: { type: Function },
    handleServicePriceEdit: { type: Function },
    changeStatus: { type: Function },
    loadingSkeleton: Boolean,
    loading: Boolean,
    servicesPrice: Array
  },
  components: {},
  data() {
    return {}
  },
  mounted() {
  },
  methods: {},
};
</script>
