<template>
  <div>
    <modal :show.sync="modal.open">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(update)" autocomplete="off">
            <div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Nome
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="$_alertGroup.name"
                        class="form-control form-control-sm"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: "EditAlertGroup",
  data () {
    return {
      modal: {
        title: 'Editar grupo de alerta',
        open: false,
      },
      alertGroup: {
        id: null,
        name: '',
      },
      loadingStore: false,
    }
  },
  computed: {
    ...mapGetters({
        $_alertGroup: 'alertGroup/show',
    }),
    },
  methods: {
    closeModal () {
      this.modal.open = false;
    },
    handleEditModal (id) {
      this.$store.dispatch('alertGroup/show', id)
        .then((response) => {
          this.modal.open = true;
        }).catch((error) => {
          this.$notify({
              type: error.data.error_type,
              message: error.data.message
          });
        }).finally(() => {
          this.$Progress.finish();
        })
    },
    update() {
      this.$Progress.start();
      this.loadingStore = true;
      this.$store.dispatch('alertGroup/update', this.$_alertGroup).then(response => {
        this.$notify({
          type: response.error_type,
          message: response.message
        });
      }).catch(error => {
        if (error.status == 200) {
          this.$notify({
            type: 'danger',
            message: error.data.message
          });
        } else if (error.response && error.response.status === 422) {
          let errors = formatErrorValidation(error.response.data.errors)
          this.$notify({
            type: 'danger',
            message: errors
          });
        }
      }).finally(() => {
        this.$Progress.finish();
        this.loadingStore = false;
        this.modal.open = false;
      });
    },
  }
}
</script>

<style scoped>

</style>
