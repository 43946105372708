<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-md-4">
          <h2 style="font-size: 1.2rem !important;" class="text-primary">
            <img height="30px" src="/img/icons/physics.png" class="mr-3"/>
            Lançamento de ensaios
          </h2>
        </div>
        <div class="col-md-8 pr-4">
          <div class="row d-flex justify-content-end">
            <div class="mb-md-0 mb-2 col-md-3">
              <base-button type="success"
                           block
                           @click.prevent="handleShowModalCreate()">
                <img height="19" src="/img/icons/plus-math--v1-white.png" class="mr-1 mt-n1"/>
                NOVO
              </base-button>
            </div>
            <div class="mb-md-0 mb-2 col-md-3">
              <router-link :to="{ path:'/technology/test/report'}">
                <base-button
                  block
                  size="md"
                  type="light"
                  class="pb-2 pt-2 text-uppercase"
                >
                  voltar
                </base-button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <hr class="blue-divider mt-2 mb-4">
    </base-header>
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-6">
          <h4 class="new-default-black-font">
            Contrato: <i class="font-weight-normal" style="font-style: normal;"
                         v-if="report.schedule">{{ report.schedule.contract_proposal_code }}</i>
          </h4>
          <h4 class="new-default-black-font" v-if="report.schedule">
            Cliente: <i class="font-weight-normal" style="font-style: normal;">{{ report.schedule.customer }}</i>
          </h4>
          <h4 class="new-default-black-font" v-if="report.schedule">
            Obra: <i class="font-weight-normal" style="font-style: normal;">{{ report.schedule.construction }}</i>
          </h4>
          <h4 class="new-default-black-font"
              style="display: flex; align-items: flex-start;">
            Data da concretagem: <i class="font-weight-normal"
                                    style="font-style: normal;">{{ report.updated_at | parseDate }}</i>
            <el-popover placement="right" trigger="click">
              <img slot="reference"
                   class="pointer ml-3" style="border-radius: 0px !important;" src="/img/icons/chemical-plant-black.png"
                   width="20">
              <div>
                {{ report.plant }}
              </div>
            </el-popover>
          </h4>
        </div>
        <div class="col-lg-6">
          <div style="border-radius: 7px !important;" class="card py-3 px-4">
            <div class="row align-items-center">
              <div class="col-9" style="display: flex;align-items: center;">
                <div class="circle-border-image mr-2" style="display: inline-block;">
                  <img src="/img/icons/test-tube.png"/>
                </div>
                <div style="display: inline-block;"
                     v-if="technicalPermissions.is_view_technical_data_of_traces_in_test_results_launch">
                  <h4 class="mb-0" v-if="report.contract_proposal_formulation">
                    {{ report.contract_proposal_formulation.title }}</h4>
                  <h4 class="font-weight-normal mb-0" v-if="report.contract_proposal_formulation">
                    {{ report.contract_proposal_formulation.body }}</h4>
                  <h4 class="font-weight-normal" v-if="report.contract_proposal_formulation">
                    {{ report.contract_proposal_formulation.footer }}</h4>
                </div>
                <div v-else>
                  <h5 class="text-danger mb-0">
                    Sem permissão
                  </h5>
                  <div class="new-default-black-font" style="font-size: 12px;">
                    Visualização indisponível, solicite permissão ao gestor.
                  </div>
                </div>
              </div>
              <div class="col-3">
                <a class="card-with-box-shadow text-center mb-2" style="display: inherit;">
                  <div class="py-1">
                    <img class="mr-1" width="26" src="/img/icons/os.png"/>
                    <b class="new-default-black-font numeric">N. {{ report.index }}</b>
                  </div>
                </a>
                <a v-if="hardened_states.length && hardened_states[0].xml_travel_number"
                   class="card-with-box-shadow text-center" style="display: inherit;">
                  <div class="py-1">
                    <img class="mr-2" width="18" src="/img/icons/nfe.png"/>
                    <b class="new-default-black-font numeric">N. {{ hardened_states[0].xml_travel_number }}</b>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="hardened_states.length && !loadingSkeleton" class="row card card-wrapper pt-4 px-2"
           style="flex-direction: unset;">
        <div class="col-lg-4" v-for="(item, index) in hardened_states" :key="index">
          <div class="card main-card" style="overflow: hidden;border-radius: 20px !important">
            <div class="p-3 px-4">
              <div class="row mb-2">
                <div class="col-9" style="align-items: center; display: flex;">
                  <a class="card-with-box-shadow text-center"
                     style="width: 130px; display: inline-block;">
                    <div class="py-1" style="display: flex;align-items: center;justify-content: center;">
                      <img class="mr-2" width="22" src="/img/icons/icons8/ios/source-code.png"/>
                      <b class="new-default-black-font numeric"
                         style="font-size: 12px;"
                         v-if="item.code">N. {{ item.code }}
                      </b>
                      <b class="new-default-black-font numeric"
                         style="font-size: 12px;"
                         v-else>
                        n/a
                      </b>
                    </div>
                  </a>
                  <el-popover placement="right" trigger="click">
                    <base-button outline slot="reference" size="sm" type="secundary"
                                 class="shadow-none text-indigo">
                      <img src="/img/icons/icons8/ios/info-circle_warning.png" width="25">
                    </base-button>
                    <span style="width: 290px;word-break: break-word;"
                          class="row">
                      <div class="col-12 mb-3" style="display: flex; align-items: center;">
                        <img class="mr-2" src="/img/icons/icons8/ios/info-circle_warning.png" width="22">
                        <h4 style="font-size: 16px;" class="p-0 m-0">Detalhes</h4>
                      </div>
                      <div class="col-12" v-if="item.user_name">
                      <h5 class="font-weight-normal"><b class="mr-2">Responsável:</b>{{
                          item.user_name
                        }}</h5>
                    </div>
                      <div class="col-12">
                        <h5 class="font-weight-normal"><b class="mr-2">M.E:</b>{{ item.me }} KG/M3</h5>
                      </div>
                      <div class="col-12">
                        <h5 class="font-weight-normal"><b
                          class="mr-2">Cura:</b>{{ item.health ? healths[item.health] : '-' }}</h5>
                      </div>
                      <div class="col-12">
                        <h5 class="font-weight-normal"><b class="mr-2">Amostragem:</b>{{
                            item.molding_location_name
                          }}</h5>
                      </div>
                      <div class="col-12">
                        <h5 class="font-weight-normal"><b class="mr-2">Base inferior:</b>
                          <i style="font-style: normal;" v-if="item.bottom_base"
                             v-for="(base, key) in item.bottom_base">
                            {{ base }} <span v-if="item.bottom_base.length -1 != key">+</span>
                          </i>
                          <i style="font-style: normal;" v-if="item.bottom_base < 1">-</i>
                        </h5>
                      </div>
                      <div class="col-12">
                        <h5 class="font-weight-normal"><b class="mr-2">Base superior:</b>
                          <i style="font-style: normal;" v-for="(base, key) in item.top_base">
                            {{ base }} <span v-if="item.top_base.length -1 != key">+</span>
                          </i>
                          <i style="font-style: normal;" v-if="item.top_base < 1"> - </i>
                        </h5>
                      </div>
                      <div class="col-12">
                        <h5 class="font-weight-normal"><b class="mr-2">Padrão de rompimento:</b>
                          {{ item.disruption ? disruptions.find(disrupt => disrupt.id === item.disruption).text : '-' }}
                        </h5>
                      </div>
                      <div class="col-12">
                        <h5 class="font-weight-normal"><b
                          class="mr-2">Observações:</b>{{ item.observation ? item.observation : '-' }}</h5>
                      </div>
                    </span>
                  </el-popover>
                </div>
                <div class="col-3 d-flex justify-content-end align-items-end">
                  <el-popover v-if="item.reuse_number"
                              :title="setTitleReuse(`${item.reuse_category}${item.reuse_category_id}`)" trigger="click"
                              placement="right" class="p-0 pr-1">
                    <div>
                      <h3 class="text-info pl-1">
                        <small class="text-default"
                        ><img style="margin-left: -4px; margin-top: -12px" width="32px" src="/img/icons/os.png"/>
                          <span class="text-default" style="text-transform: none !important"> n° </span>
                        </small>
                        <b class="money">{{ item.reuse_number }}</b>
                      </h3>
                    </div>
                    <div style="margin-top: -5px !important">
                      <h6 class="h5 new-default-black-font p-1 mb-0 font-weight-400"> Quantidade:  <b>{{ item.reuse_volume.replace(".", ",") }} </b>m<sup>3</sup></h6>
                    </div>
                    <div style="margin-top: -5px !important">
                      <div class="row align-items-center">
                        <div class="col-12">
                          <hr class="mt-2 mb-2">
                        </div>
                        <div class="col-2 mt-n3" style="display: flex; align-items: center;">
                       <span style="border: 1px solid #1a70b7;border-radius: 35px;padding: 10px;">
                         <img src="/img/icons/test-tube.png" style="width: 22px;"></span>
                        </div>
                        <div class="col-10">
                          <h6 class="h5 new-default-black-font p-1 mb-0 font-weight-400"> {{item.reuse_title}} </h6>
                          <h6 class="h5 mt-n2 new-default-black-font p-1 mb-0 font-weight-400"> {{item.reuse_body}} </h6>
                          <h6 class="h5 mt-n2 new-default-black-font p-1 mb-0 font-weight-400"> {{item.reuse_footer}} </h6>
                        </div>
                      </div>
                    </div>
                    <base-button outiline slot="reference" size="sm" type="secundary" class="p-0 m-0 btn-rounded">
                      <i style="font-size: 27px" class="fa-solid fa-recycle"
                         :class="item.reuse_category === 1 ? 'text-success' : 'text-warning'"> </i>
                    </base-button>
                  </el-popover>
                  <div class="pointer m-0">
                    <el-popover v-if="item.anomalies_extract" trigger="hover" placement="left" class="p-0 pr-0 mr-1">
                      <h5 class="mb-n1" v-for="anomaly in item.anomalies_extract.split(',')">{{ anomaly }}</h5>
                      <img slot="reference" width="30" src="/img/icons/icons8/ios/question--v1.png">
                    </el-popover>
                  </div>
                  <base-dropdown menuOnRight>
                    <div slot="title-container" class="dropdown-toggle rounded m-0">
                      <img width="32" src="/img/icons/icons8/ios/settings--v1_primary.png"/>
                    </div>
                    <a class="dropdown-item font-weight-500 new-default-black-font"
                       style="align-items: center; display: flex"
                       @click="handleEdit(item)">
                      <img width="22" src="/img/icons/edit.png"/>
                      Editar
                    </a>
                    <div class="dropdown-divider p-0 m-0"></div>
                    <a
                      class="dropdown-item font-weight-500 new-default-black-font"
                      style="align-items: center; display: flex"
                      @click.prevent="remove(item.uuid)">
                      <img width="22" src="/img/icons/delete.png"/>
                      Excluir
                    </a>
                  </base-dropdown>
                </div>
              </div>
              <!-- LAYOUT MD -->
              <div class="row pt-1 align-items-center">
                <div class="col-12" style="display: flex; align-items: center;">
                  <h4 class="m-0 p-0 mr-3" style="font-size: 16px;"
                      v-if="technicalPermissions.is_view_technical_data_of_traces_in_test_results_launch">
                    {{ item.feature }}
                    {{ format_mixe(item.period, item.period_type) }}
                    {{ item.item_description }}
                  </h4>
                  <div style="display: inline-flex;"
                       :class="item.molder && item.result ? 'mini-card-blue' : item.molder && !item.result ? 'mini-card-yellow' : 'mini-card-red'">
                    <h4 class="font-weight-normal m-0 p-0">
                      {{
                        item.molder && item.result ? 'Ensaiado' : item.molder && !item.result ? 'Pendente' : 'Descartado'
                      }}
                    </h4>
                  </div>
                </div>
              </div>
              <div class="row mt-4 align-items-center">
                <div class="col-xl-6" v-if="item.provided_at">
                  <div class="card grid-card">
                    <div style="display: inline-grid; align-items: center;">
                      <div class="p-2">
                        <h4 class="new-default-black-font m-0">Ensaio</h4>
                        <h6 class="new-default-gray-font m-0 font-weight-normal" style="font-size: 12px;">
                          {{ item.provided_at | parseDate }}</h6>
                      </div>
                    </div>
                    <div class="text-center gray-bg new-default-black-font font-weight-500"
                         style="display: inline-grid; align-items: center;">
                      <div>
                        {{ format_mixe(item.schedule_period, item.schedule_period_type, true) }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div v-if="item.molder" class="new-default-black-font card grid-card ">
                    <div style="display: inline-grid; align-items: center;">
                      <div class="p-2">
                        <h4 class="text-primary m-0 p-0">Resultado</h4>
                        <h6 v-if="item.date_at" class="new-default-gray-font m-0 p-0 font-weight-normal"
                            style="font-size: 9px;">
                          {{ item.date_at | parseDate }}
                        </h6>
                        <h6 v-if="item.real_period" class="new-default-gray-font m-0 p-0 mt-n1 font-weight-normal"
                            style="font-size: 9px;">
                          {{ formatPeriod(item.real_period) }}
                        </h6>
                      </div>
                    </div>
                    <div class="text-center blue-bg font-weight-500" style="display: inline-grid; align-items: center;">
                      <div>
                        <h3 class="text-primary m-0 p-0" v-if="item.result">
                          {{ item.result.toString().replace('.', ',') }}
                          {{ item.letter }}
                        </h3>
                        <h3 v-else class="invisible-text m-0 p-0">
                          s/ result
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div v-if="!item.molder" style="min-height: 70px; max-height: 70px;"
                       class="new-default-black-font card red-bg">
                    <div class="pl-3 pt-3 py-3 px-1">
                      <h4 class="text-danger m-0 p-0">Motivo</h4>
                      <h6 class="new-default-black-font m-0 p-0 font-weight-normal" style="font-size: 10px;">
                        {{ item.reason }}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <puzl-empty-data v-if="!loadingSkeleton && -hardened_states.length == 0"/>
      <modal-create ref="modalCreate"/>
      <modal-edit @fetch="init()" ref="modalEdit"/>
    </div>


  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import ModalCreate from "./Shared/ModalCreate";
import ModalEdit from "./Shared/ModalEdit";
import SkeletonPuzl from "../../../../../../../components/SkeletonPuzl";
import {period_types} from "@/views/Modules/Technology/Mix/helpers/mixe";
import SkeletonPuzlGrid from "../../../../../../../components/SkeletonPuzlGrid";
import MultiFilter from "@/components/Utils/MultiFilterV2";
import moment from "moment";
import PuzlEmptyData from "../../../../../../../components/PuzlEmptyData";

export default {
  name: "HardenedState",
  components: {
    PuzlEmptyData,
    MultiFilter,
    SkeletonPuzlGrid,
    ModalCreate,
    ModalEdit,
    SkeletonPuzl,
    PuzlBreadcrumb,
  },
  computed: {
    ...mapGetters({
      'report': 'testReport/show',
      '$_hardened_states': 'travelConfiguration/showHardenedStates',
      $_user: 'auth/getUser',
    }),
    /** ordenação básica para não refletir no store */
    hardened_states() {
      return this.$_hardened_states.sort((a, b) => a.schedule_period - b.schedule_period)
    }
  },
  data() {
    return {
      format_mixe: period_types,
      loadingSkeleton: false,
      added: false,
      technicalPermissions: {},
      disruptions: [
        {
          id: 'A',
          text: 'Cônica e cônica afastada 25mm do capeamento'
        },
        {
          id: 'B',
          text: 'Cônica e bipartida e cônica e com mais de uma partição'
        },
        {
          id: 'C',
          text: 'Colunar com formação de cones'
        },
        {
          id: 'D',
          text: 'Cônica e cisalhada'
        },
        {
          id: 'E',
          text: 'Cisalhada'
        },
        {
          id: 'F',
          text: 'Fraturas no topo e/ou na base abaixo do capeamento'
        },
        {
          id: 'G',
          text: 'Similar ao tipo f com fraturas próximas ao topo'
        },
      ],
      healths: [
        'Total',
        'Parcial',
        'Nenhuma'
      ],
      categories: {
        11: "Reaproveitamento - Reuso",
        12: "Redirecionamento - Reuso",
        21: "Sobra de Concreto - descarte",
        22: "Perda de Concreto - descarte",
      },
    }
  },
  methods: {
    setTitleReuse(type) {
      return this.categories[Number(type)];
    },
    fetchTechnicalPermissions(userId) {
      this.$store.dispatch('technicalPermission/getByUserId', userId).then((response) => {
        this.technicalPermissions = response.data
      });
    },
    classResultExpected(item) {
      if (!item.provided_at || !item.result_expected) {
        return
      }
      const date = moment(item.sampling_at).format('YYYY-MM-DD H:mm:ss')
      const date2 = moment(item.provided_at).format('YYYY-MM-DD H:mm:ss')
      const diff = this.$helper.dateDiff(date, date2)
      /** idade real **/
      let real_period = diff.replace(/[a-zA-Z]{1,3}/, '')
      real_period = real_period.slice(0, 2)
      real_period = real_period.split(' ')
      real_period = Number(real_period[0])
      /** resultado **/
      const result = Number(item.result_expected)
      /** item da responsabilidade **/
      let item_period = item.item_description.replace(/[a-zA-Z]{1,3}/, '')
      item_period = Number(item_period.trim())
      /** ${item.period} idade da responsabilidade **/
      if (real_period < item.period) {
        // item da responsabilidade
        if (result > item_period) {
          return 'text-primary'
        }
        return null
      }
      if (real_period >= item.period) {
        if (result >= item_period && real_period >= item.period) {
          return 'text-success'
        }
        if (result < item_period && real_period >= item.period) {
          return 'text-danger'
        }
      }
    },
    classResult(item) {
      if (!item.real_period || !item.result) {
        return
      }
      /** idade real **/
      let real_period = item.real_period.replace(/[a-zA-Z]{1,3}/, '')
      real_period = real_period.slice(0, 2)
      real_period = real_period.split(' ')
      real_period = Number(real_period[0])
      /** resultado **/
      const result = Number(item.result)
      /** item da responsabilidade **/
      let item_period = item.item_description.replace(/[a-zA-Z]{1,3}/, '')
      item_period = Number(item_period.trim())
      /** ${item.period} idade da responsabilidade **/
      if (real_period < item.period) {
        // item da responsabilidade
        if (result > item_period) {
          return 'text-primary'
        }
        return null
      }
      if (real_period >= item.period) {
        if (result >= item_period && real_period >= item.period) {
          return 'text-success'
        }
        if (result < item_period && real_period >= item.period) {
          return 'text-danger'
        }
      }
    },
    formatPeriod(period) {
      const date_splited = period.split(' ')
      for (let date of date_splited) {
        if (date.includes('d')) {
          date = date.replace('d', '')
          return Math.round(date) > 1 ? `${date} dias` : `${date} dia`
        }
        if (date.includes('h')) {
          date = date.replace('h', '')
          return date > 1 ? `${date} horas` : `${date} hora`
        }
        if (date.includes('m')) {
          date = date.replace('min', '')
          date = date.replace('m', '')
          date = Math.round(date) / 60
          if (date < 1) {
            date = Math.round(date * 60)
            return `${date} minutos`
          }
          return date > 1 ? `${date} horas` : `${date} hora`
        }
      }
    },
    updatePuzlClient(item) {
      let params = {
        uuid: item.uuid,
        puzl_client: !item.puzl_client
      }
      let loader = this.$loading.show()
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch('travelConfiguration/updateHardenedState', params)
        .then(response => {
            loader.hide()
            this.$notify({type: 'success', message: response.message});
          }
        ).catch(() => loader.hide())
    },
    remove(uuid) {
      let loader = this.$loading.show()
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$notify({
            type: "info",
            message: "Estamos trabalhando em sua solicitação."
          });
          this.$store.dispatch('travelConfiguration/deleteRemoveHardenedState', uuid)
            .then(response => {
              loader.hide()
              this.$notify({type: 'success', message: response.message});
              this.$Progress.finish()
            })
            .catch(() => {
              loader.hide()
              this.$Progress.finish()
              this.removing = false
            })
        } else {
          loader.hide()
        }
      }).catch(() => this.$Progress.finish())
    },
    handleShowModalCreate() {
      this.report.contract_proposal_formulation.features = JSON.parse(this.report.contract_proposal_formulation.encoded).items
      this.$refs.modalCreate.openModal(this.report.contract_proposal_formulation, this.report.company_plant_id, this.technicalPermissions)
    },
    handleEdit(item) {
      this.report.contract_proposal_formulation.features = JSON.parse(this.report.contract_proposal_formulation.encoded).items
      this.$refs.modalEdit.openModal(item, this.report.contract_proposal_formulation, true, this.technicalPermissions)
    },
    init() {
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch('travelConfiguration/fetchHardenedStates',
        {schedule_travel_uuid: this.$route.params.travel_uuid})
        .then(response => {
          this.modal = true
          this.$notify({type: 'success', message: response.message});
        })
        .catch(error => {
        })
    }
  },
  mounted() {
    this.loadingSkeleton = true
    this.$store.dispatch("testReport/show",
      this.$route.params.travel_uuid).then(() => {
      this.loadingSkeleton = false
    }).catch(() => this.loadingSkeleton = false);
    this.init()
    this.fetchTechnicalPermissions(this.$_user.id);
  }
};
</script>

<style scoped>
.circle-border-image {
  border: 1px solid #1a70b7;
  border-radius: 35px;
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-border-image img {
  padding: 10px;
  width: 55px;
  height: 55px;
}

.card-with-box-shadow {
  box-shadow: 0 2px 4px 0 #f3f3f3;
  border: 1px solid #E8E8E8;
  box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15);
  border-radius: 10px !important;
}

.new-default-gray-font {
  color: #606062;
}

.card-with-box-shadow {
  box-shadow: 0 2px 4px 0 #f3f3f3;
  border: 1px solid #E8E8E8;
  box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15);
  border-radius: 10px !important;
}

.mini-card-green {
  background-color: #8bd5af52;
  border-radius: 5px;
  padding: 1px 5px;
  font-size: 12px;
  box-shadow: 0 0 5px 0 #149e5752;
}

.mini-card-red {
  background-color: #f459592e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #d2272752;
}

.mini-card-yellow {
  background-color: #f4dc592e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #fdef0b52;
}

.mini-card-blue {
  background-color: #4182e52e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #0b7bfd52;
}

.mini-card-gray {
  background-color: #646e7d2e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #5d636a52;
}

.mini-card-green h4 {
  color: #149e57;
}

.mini-card-red h4 {
  color: #db4539;
}

.mini-card-yellow h4 {
  color: #f2b532;
}

.mini-card-blue h4 {
  color: #1b6eba;
}

.mini-card-gray h4 {
  color: #66696c;
}

.mini-card-red h4, .mini-card-green h4, .mini-card-yellow h4, .mini-card-blue h4, .mini-card-gray h4 {
  font-size: 12px;
}

.grid-card {
  display: grid;
  grid-template-columns: 60% 40%;
  overflow: hidden;
  height: 70px;
  margin-bottom: 10px;
}

.gray-bg {
  background-color: #e6e6e6;
  box-shadow: 0 0 5px 0 #6a6a6a52;;
}

.blue-bg {
  background-color: #4182e52e;
  box-shadow: 0 0 5px 0 #0b7bfd52;
}

.red-bg {
  background-color: #f459592e;
  box-shadow: 0 0 5px 0 #d2272752;
}

.invisible-text {
  color: rgba(0, 0, 0, 0);
}
</style>
