export const XmlTravelEnum = Object.freeze({
    NFE_IN_PROGRESS: 0, // NFe em progresso
    NFE_COMPLETED: 1, // NFe concluída
    NFE_REJECTED: 2, // NFe rejeitada
    NFE_IN_CANCELLATION_PROCESS: 3, // NFe em processo de cancelamento
    NFE_CANCELED: 4, // NFe cancelada
    NFE_DUPLICATED: 5, // NFe duplicada
    NFE_SENDING: 7, // NFe enviando
    NFE_DENEGADA: 6, // NFe denegada
    NFE_EVENT_ERROR: 99, // Erro de evento na NFe
    NFE_INTERNAL_ERROR: 97, // Algum erro interno e a nota precisou ser desvinculada da viagem
});
