<template>
<div>
    <modal :show.sync="modal.create">
        <template slot="header">
            <h5 class="modal-title pl-3">{{ modal.title }}</h5>
        </template>
        <div>
            <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
                <form class="needs-validation" @submit.prevent="handleSubmit(handleStabilizingAdditiveAdjustmentUpdate)" autocomplete="off">
                    <div>
                        <!-- Status -->
                        <div class="form-group row m-0 p-0">
                            <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                                Status
                                <span class="text-danger">&nbsp;*</span>
                            </label>
                            <div class="col-md-6 pt-2">
                                <base-switch v-model="status" type="success" offText="inativo" onText="ativo" class="success"></base-switch>
                            </div>
                        </div>
                        <!-- Fornecedor -->
                        <div class="form-group row m-0 p-0">
                            <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                                Fornecedor
                                <span class="text-danger">&nbsp;*</span>
                            </label>
                            <div class="col-md-6 mb-1 pr-4">
                                <validation-provider rules="required" v-slot="{errors}">
                                    <base-input input-classes="form-control-sm">
                                        <puzl-select
                                            v-model="adjustment.supplier_id"
                                            @input="getSupplierOrigin(adjustment.supplier_id)"
                                            :items="suppliers"
                                            customKey="uuid"
                                            label="supplier_name" />
                                    </base-input>
                                </validation-provider>
                            </div>
                        </div>
                        <!-- Procedência -->
                        <div class="form-group row m-0 p-0">
                            <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                                Procedência
                                <span class="text-danger">&nbsp;*</span>
                            </label>
                            <div class="col-md-6 mb-1 pr-4">
                                <validation-provider rules="required" v-slot="{errors}">
                                    <base-input input-classes="form-control-sm">
                                        <puzl-select
                                            v-model="adjustment.supplier_origin_id"
                                            :items="supplierOrigins.supplier_origins"
                                            @input="getProduct(adjustment.supplier_origin_id)"
                                            :disabled="!supplier_origin_select || !adjustment.supplier_id" 
                                            :loading="loadingSuppliersOrigin"
                                            label="origin_name" />
                                    </base-input>
                                </validation-provider>
                            </div>
                        </div>
                        <!-- Produto -->
                        <div class="form-group row m-0 p-0">
                            <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                                Produto
                                <span class="text-danger">&nbsp;*</span>
                            </label>
                            <div class="col-md-6 mb-1 pr-4">
                                <validation-provider rules="required" v-slot="{errors}">
                                    <base-input input-classes="form-control-sm">
                                        <puzl-select
                                            v-model="adjustment.product_id"
                                            :items="listProducts"
                                            :value="getCmcProductId"
                                            :disabled="!productselect || !adjustment.supplier_origin_id"
                                            :loading="loadingProducts" />
                                    </base-input>
                                </validation-provider>
                            </div>
                        </div>
                        <!-- Observações -->
                        <div class="form-group row m-0 mb-3 p-0">
                            <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                                Observações
                            </label>
                            <div class="col-md-6 mb-1 pr-4">
                                <validation-provider>
                                    <base-input input-group-classes="input-group-sm">
                                        <input 
                                            type="text" 
                                            v-model="adjustment.observation"
                                            class="form-control form-control-sm"
                                            maxlength="250" />
                                    </base-input>
                                </validation-provider>
                            </div>
                        </div>
                        <!-- Tempo de estabilização -->
                        <div class="form-group row m-0 p-0">
                            <div class="col-md-10 pr-0 pl-0">
                                <div class="form-group row m-0 p-0 ">
                                    <label class="col-md-6 pb-0 col-form-label form-control-label mb-1">
                                        Tempo de estabilização
                                    </label>
                                    <div class="col-md-5">
                                        <validation-provider>
                                            <base-input input-group-classes="input-group-sm">
                                                <input inputmode="numeric" v-model="currentDosage.stabilization_time" class="form-control form-control-sm" v-mask="['####']" />
                                                <template slot="append">
                                                    <small class="input-group-sm p-0 m-0">
                                                        horas
                                                    </small>
                                                </template>
                                            </base-input>
                                        </validation-provider>

                                    </div>
                                </div>
                                <!-- Teor de dosagem -->
                                <div class="row m-0 p-0">
                                    <label class="col-md-6 pb-0 col-form-label form-control-label mb-1">
                                        Teor de dosagem
                                    </label>
                                    <div class="col-md-5 pb-3">
                                        <validation-provider>
                                            <base-input input-group-classes="input-group-sm">
                                                <input inputmode="numeric" v-model="currentDosage.dosage_content" class="form-control form-control-sm" v-mask="['#.##','##.##','###.##']" />
                                                <template slot="append">
                                                    <small class="input-group-sm p-0 m-0">
                                                        %(S.P.A.)
                                                    </small>
                                                </template>
                                            </base-input>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 d-flex justify-content-center align-items-center">
                                <base-button @click="addDosage" type="success" native-type="button" class="btn btn-sm align-items-center mr-2" block>
                                    <i class="fas fa-plus"></i>
                                </base-button>
                            </div>
                        </div>
                        <!-- lista de dosagens -->
                        <div class="m-2 mt-3 mb-1" v-for="(dosage, index)  in adjustment.dosages" :key="index">
                            <card class="border card--hover card-body-molde mb-0" :bodyClasses="'p-2'">
                                <div class="row align-items-center">
                                    <div class="col-5 text-right pr-1">
                                        <h6 class="mb-0">{{ dosage.stabilization_time }}{{ dosage.stabilization_time > 1 ? ' horas' : ' hora' }}</h6>
                                    </div>
                                    <div class="col-4 pl-0">
                                        <h6 class="mb-0">({{ dosage.dosage_content}} % S.P.A.)</h6>
                                    </div>
                                    <div class="col-3">
                                        <button type="button" class="btn btn-sm rounded-circle btn-danger" @click="removeDosage(index)">
                                            <i class="fas fa-times"></i>
                                        </button>
                                    </div>
                                </div>
                            </card>
                        </div>
                        <div class="modal-footer">
                            <base-button type="secondary" @click="closeModal('create')">
                                Cancelar
                            </base-button>
                            <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                                Salvar
                            </base-button>
                        </div>
                    </div>
                </form>
            </validation-observer>
        </div>
    </modal>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'

import {
    formatErrorValidation
} from '@/plugins'
import PuzlSelect from "@/components/PuzlSelect"

export default {
    name: "EditStabilizingAdditiveAdjustment",
    components: { PuzlSelect },
    data() {
        return {
            modal: {
                title: 'Aditivos estabilizadores',
                create: false,
            },
            supplier_origin_select: true,
            productselect: true,
            status: true,
            adjustment: {
                uuid: null,
                status: null,
                supplier_id: null,
                observation: null,
                supplier_origin_id: null,
                product_id: null,
                dosages: [],
            },
            currentDosage: {
                stabilization_time: null,
                dosage_content: null,
            },
            supplier_id: null,
            loadingStore: false,
            loadingSuppliersOrigin: false,
            loadingProducts: false,
        }
    },
    computed: {
        ...mapGetters({
            'suppliers': 'cmcSupplier/fetch',
            'supplierOrigins': 'cmcSupplierOrigin/fetch',
            'products': 'cmc/fetch',
        }),
        listProducts() {
            return this.products.map(item => {
                item.name = item.cmc_product.product_name
                return item
            }, this)
        },
        getCmcProductId() {
            return this.products.find(item => {
                item.id = item.cmc_product.id
                return item
            }, this)
        },
    },
    methods: {
        closeModal() {
            this.modal.create = false
        },
        addDosage() {
            let dosages = this.adjustment.dosages
            if (this.currentDosage.stabilization_time && this.currentDosage.dosage_content) {
                if (dosages && dosages[0] && dosages.filter(item => item.stabilization_time == this.currentDosage.stabilization_time)[0]) {
                    this.$notify({
                        type: 'danger',
                        message: 'Tempo de estabilização já cadastrado!'
                    })
                } else {
                    let dosage = {}
                    dosage.stabilization_time = this.currentDosage.stabilization_time
                    dosage.dosage_content = this.currentDosage.dosage_content
                    this.adjustment.dosages.push(dosage)
                }
            }
        },
        removeDosage(index) {
            this.adjustment.dosages.splice(index, 1);
        },
        getProduct(id) {
            if (this.adjustment.supplier_origin_id) {
                this.productselect = false
                this.adjustment.product_id = null
                this.loadingProducts = true
                let params = {
                    supplierId: this.supplier_id,
                    supplierOriginId: this.adjustment.supplier_origin_id,
                }
                this.$store.dispatch('cmc/fetchBySupplierIdAndSupplierOriginId', params).then(() => {
                    this.productselect = true
                    this.loadingProducts = false
                })
            } else {
                this.adjustment.product_id = null
            }
        },
        getSupplierOrigin(uuid) {
            if (this.adjustment.supplier_id) {
                this.supplier_origin_select = false
                this.adjustment.supplier_origin_id = null
                this.adjustment.product_id = null
                this.loadingSuppliersOrigin = true
                this.$store.dispatch('cmcSupplierOrigin/fetchItems', uuid).then(() => {
                    this.supplier_origin_select = true
                    this.loadingSuppliersOrigin = false
                    this.supplier_id = this.suppliers.filter(item => item.uuid == this.adjustment.supplier_id)[0].id
                })
            } else {
                this.adjustment.supplier_origin_id = null
            }
        },
        handleEditModal(uuid) {
            let loader = this.$loading.show();
            this.currentDosage = {
                stabilization_time: null,
                dosage_content: null,
            }
            this.supplier_id = null
            this.$store.dispatch('adjustmentStabilizingAdditive/show', uuid)
                .then((response) => {
                    if (response.data.status) {
                        this.status = true
                    } else {
                        this.status = false
                    }
                    this.adjustment = {
                        uuid: response.data.uuid || '',
                        status: response.data.status || '',
                        supplier_id: response.data.supplier.id || '',
                        observation: response.data.observation || '',
                        supplier_origin_id: response.data.supplier_origin.id || '',
                        product_id: response.data.product.id || '',
                        dosages: response.data.dosages || []
                    }
                    this.supplier_id = this.adjustment.supplier_id
                    this.adjustment.supplier_id = this.suppliers.filter(item => item.id == this.adjustment.supplier_id)[0].uuid
                    this.$store.dispatch('cmcSupplierOrigin/fetchItems', this.adjustment.supplier_id).then(() => {
                        let params = {
                            supplierId: this.supplier_id,
                            supplierOriginId: this.adjustment.supplier_origin_id,
                        }
                        this.$store.dispatch('cmc/fetchBySupplierIdAndSupplierOriginId', params).then(() => {
                            loader.hide();
                            this.modal.create = true
                        })
                    })
                }).catch((error) => {
                    if (error.response.status === 422) {
                        let errors = formatErrorValidation(error.response.data.errors)
                        this.$notify({
                            type: 'danger',
                            message: errors
                        })
                    }
                    this.loadingStore = false
                    loader.hide();
                })
        },
        handleStabilizingAdditiveAdjustmentUpdate() {
            this.$Progress.start()
            this.loadingStore = true
            if (this.status) {
                this.adjustment.status = 1
            } else {
                this.adjustment.status = 0
            }
            this.adjustment.supplier_id = this.supplier_id
            this.$store.dispatch('adjustmentStabilizingAdditive/update', this.adjustment)
                .then(response => {
                    this.loadingStore = false
                    this.modal.create = false
                    this.$Progress.finish()
                    this.$notify({
                        type: response.error_type,
                        message: response.message
                    })
                })
                .catch(error => {
                    if (error.status == 200) {
                        this.$notify({
                            type: 'danger',
                            message: error.data.message
                        })
                        this.$Progress.finish();
                        this.loadingStore = false
                    } else if (error.response && error.response.status === 422) {
                        let errors = formatErrorValidation(error.response.data.errors)
                        this.$notify({
                            type: 'danger',
                            message: errors
                        })
                    }
                    this.$Progress.finish()
                    this.loadingStore = false
                })
        }
    },
    mounted() {
        this.$refs.formValidator.validate();
    },
}
</script>

<style scoped>

</style>
