<template>
<div>
  <div class="container-fluid">

    <div class="row card-wrapper" v-show="loadingSkeleton">
      <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
    </div>

    <div class="row align-items-center"  v-if="!features.length && !loadingSkeleton">
      <div class="col-md-12 text-center">
        <div class="card p-4">
          Sem dados cadastrados!
        </div>
      </div>
    </div>


    <div class="row card-wrapper" v-if="features.length && !loadingSkeleton">
      <div class="col-lg-4" v-for="(feature, index) in features" :key="index">
        <!-- Basic with action button -->
        <card :class="feature.status ? 'card-border-top-success' : 'card-border-top-danger'">
          <!-- Card header -->
          <div class="row align-items-center mb-3">
            <div class="col-8">
              <!-- Title -->
              <h5 class="h3 mb-0">
                {{ feature.short_description }}
                <span class="h3 mb-0 small" v-if="feature.measured_unit.id!=99">
                    ({{ feature.measured_unit.initials }})
                  </span>
              </h5>
            </div>

            <div class="col-4 text-right">
              <base-dropdown menuOnRight>
                <base-button
                  slot="title-container"
                  type="primary"
                  class="dropdown-toggle p-2 rounded m-0"
                >
                  <i class="fas fa-cog"></i>
                </base-button>
                <a class="dropdown-item" @click="handleCreateFeatureItem(feature.uuid)">
                  <i class="fas fa-plus text-primary"></i>
                  Item
                </a>
                <div class="dropdown-divider p-0 m-0"></div>
                <a class="dropdown-item" @click="handleEditFeature(feature.uuid)">
                  <i class="fas fa-edit text-warning"></i>
                  Editar
                </a>
                <div class="dropdown-divider p-0 m-0"></div>
                <a class="dropdown-item" @click="handleDeleteFeature(feature.uuid)">
                  <i class="fas fa-times text-danger"></i>
                  Excluir
                </a>
              </base-dropdown>
            </div>
          </div>
          <!-- Card body -->
          <span class="h3 mb-4 small">{{ feature.description }}</span>
          <h5 class="h3 mb-0 mt-2 mb-2">
            <span class="h3 mb-0 mt-4">{{ feature.period }}&nbsp;</span>

            <span v-if="feature.period_type===1 && feature.period<=1">Dia</span>
            <span v-if="feature.period_type===1 && feature.period>1">Dias</span>

            <span v-if="feature.period_type===2 && feature.period<=1">Hora</span>
            <span v-if="feature.period_type===2 && feature.period>1">Horas</span>

            <span class="h3 mb-0 small" v-if="feature.tolerance">
                    (+- {{ feature.tolerance }}
                    <span v-if="feature.tolerance<=1">Hora</span>
                    <span v-else>Horas</span>
                    )
                  </span>
          </h5>
          <h6 class="h4 mb-0" v-if="feature.hardened_state">
            {{ feature.hardened_state.short_description }}
          </h6>

          <collapse class="border rounded p-0 mt-3" v-if="feature.itens">
            <collapse-item name="1" class="header-gray" back-ground="border-header-success-bottom">
              <h5 slot="title" class="mb-0 ">Itens</h5>
              <item-feature v-bind:feature="feature" v-bind:items="feature.itens" ref="createItemFeature"></item-feature>
            </collapse-item>
          </collapse>
        </card>
      </div>
    </div>
  </div>
  <edit-feature ref="editFeature"></edit-feature>
  <create-item-feature ref="itemFeature"></create-item-feature>
</div>
</template>

<script>
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import {mapGetters} from 'vuex'
import EditFeature from './_Edit'
import ItemFeature from '../Item/Shared/_List'
import CreateItemFeature from '../Item/Shared/_Create'
import { formatErrorValidation } from "@/plugins";
export default {
  name: "List",
  components: {
    SkeletonPuzlGrid,
    EditFeature,
    ItemFeature,
    CreateItemFeature,
  },
  data () {
    return {
      loadingSkeleton: false,
    }
  },
  computed: {
    ...mapGetters({
      'features': 'mixFeature/fetch',
    }),
  },
  methods:{
    fetch () {
      this.loadingSkeleton = true
      this.$store.dispatch('mixFeature/fetchItems')
        .then( response =>{
          this.loadingSkeleton = false
        })
        .catch( error => {
          this.loadingSkeleton = false
        })
    },
    handleCreateFeatureItem(uuid){
      this.$refs.itemFeature.handleCreateItemFeatureModal(uuid)
    },
    handleEditFeature(uuid){
      this.$refs.editFeature.handleEditModal(uuid)
    },
    handleDeleteFeature(uuid){
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed){
          this.$Progress.start();
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          this.$store.dispatch('mixFeature/destroy', uuid)
            .then(response => {
              this.$Progress.finish()
              this.$notify({ type: response.error_type, message: response.message })
            })
            .catch(error => {
              if (error.status == 200) {
                this.$notify({
                  type: "danger",
                  message: error.data.message
                });
                this.$Progress.finish();
              } else if (error.response && error.response.status === 422) {
                let errors = formatErrorValidation(error.response.data.errors);
                this.$notify({
                  type: "danger",
                  message: errors
                });
              }
              this.$Progress.finish();
            });
          this.$Progress.finish();
        }
      }).catch(() => this.$Progress.finish())
    }
  },
  mounted() {
    this.fetch()
  },
  created () {

  }
}
</script>

<style scoped>

</style>
