import { BaseListFilterType, initBaseListFilterType, RangeDateType } from "../../../../../shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} ReuseControlListFilterExtType
 * @property {Array<Number>} company_plants - IDs das centrais
 * @property {RangeDateType|null} range - Intervalo entre datas
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & ReuseControlListFilterExtType)} ReuseControlListFilterType
 */
export const ReuseControlListFilterType = {};

// ARRUMAR FILTROS ESPECÍFICOS

/**
 * Inicializar ReuseControlListFilterType
 *
 * @returns {ReuseControlListFilterType}
 */
export const initReuseControlListFilterType = () => ({
  ...initBaseListFilterType(),
  company_plants: [],
  range: {
    start: null,
    end: null,
  },
});
