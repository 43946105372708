<template>
  <div>
    <modal :borderInfo="'2px solid #1b6eba'" :show.sync="modal" size="sm">
      <template slot="header">
        <div style="display: flex;align-items: center;">
          <img style="display: inline-flex;" src="/img/icons/icons8/ios/link.png" width="22">
          <h5 style="display: inline-flex;" class="modal-title p-0 m-0 ml-3 text-white">{{ title }}</h5>
        </div>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form @submit.prevent="handleSubmit(save)" class="needs-validation" autocomplete="off">
            <div class="container-fluid">
              <div class="row mt-3 mb-4">
                <div class="col-12" style="display: flex; align-items: center;">
                  <img src="/img/icons/icons8/ios/checked_primary.png" width="20">
                  <h4 class="new-default-black-font m-0 mx-3 ">
                    Justificar reconciliação
                  </h4>
                </div>
              </div>
              <div class="row mb-3 align-items-center">
                <div class="col-12 mb-1">
                  <h6 style="font-size: 11.5px;" class="new-default-black-font font-weight-normal m-0">
                    Observação
                  </h6>
                </div>
                <div class="col-12">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <textarea type="text" rows="4"
                        maxlength="60"
                        v-model="status_reason"
                        :class="errors[0] ? 'is-invalid' : ''"
                        class="form-control form-control-sm" placeholder=" ...">
                      </textarea>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="row mt-5 mb-4">
                <div class="col-12 text-center">
                  <base-button type="danger"
                    size="sm"
                    style="width: 130px;" @click.prevent="closeModal">
                    <div style="display: inline-flex; align-items: center;">
                      <img src="/img/icons/cancel-white.png" width="20">
                      <i class="m-0 ml-1" style="font-style: normal;">
                        Cancelar
                      </i>
                    </div>
                  </base-button>
                  <base-button type="success"
                    size="sm"
                    style="width: 130px;"
                    v-bind:disabled="invalid"
                    native-type="submit">
                    <div style="display: inline-flex; align-items: center;">
                      <img src="/img/icons/save.png" width="20">
                      <i class="m-0 ml-1" style="font-style: normal;">
                        Justificar
                      </i>
                    </div>
                  </base-button>
                </div>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>
<script>
import { formatErrorValidation } from "@/plugins";

export default {
  components: {},
  name: "ModalJustifyConciliation",
  props: {},
  data() {
    return {
      modal: false,
      title: 'Conciliação Financeira',
      loading: false,
      id: null,
      status: 2,
      status_reason: "",
      item: {},
    }
  },
  methods: {
    save() {
      const items = [
        {
          id: this.id,
          status: 2,
          status_reason: this.status_reason
        }
      ];

      this.$store.dispatch("financeTransactions/reconcile", {items})
      .then(() => {
        this.$emit('updateScreen');
        this.closeModal();
      }).catch(error => {
        const errors = error && error.response && error.response.status === 422
        ? formatErrorValidation(error.response.data.data)
        : error.response.data.message;
        this.$notify({ type: "danger", message: errors });
      });
      this.$Progress.finish();
    },
    closeModal() {
      this.modal = false;
    },
    openModal(id){
      this.id = id;
      this.status_reason = "";
      this.modal = true;
    },
  },
};
</script>

<style scoped>

</style>
