<template>
  <div>
    <div class="container-fluid">
      <multi-filter ref="multi-filter" @fetch="init" :filter="filter" :status="multi_filter" /> 
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
      </div>

      <PuzlEmptyData v-if="!waters.length && !loadingSkeleton"> </PuzlEmptyData>
      
      <div class="row card-wrapper" v-if="waters.length && !loadingSkeleton">
        <div class="col-lg-4" v-for="(water, index) in waters" :key="index">
          <div @click="handleEditWater(water.uuid)">
            <card class="border-primary">
              <!-- Card header -->
              <div class="row align-items-center mb-3">
                <div class="col-9 justify-content-center pr-0">
                  <h5 class="h1 mb-0">{{ water.correction }}%</h5>
                </div>
                <div class="col-3 text-right pl-0">
                  <button
                    type="button"
                    class="btn btn-sm rounded-circle btn-danger"
                    @click.stop="handleDeleteWater(water.uuid)"
                  >
                    <i class="fas fa-times"></i>
                  </button>
                </div>
                <div class="col-9 justify-content-center pr-0">
                  <p class="pb-0 mb-0 pr-1 pt-0">
                    <b> {{ water.date_time.substr(11) }} </b>
                  </p>
                  <p class="pb-0 mb-0 pr-1 pt-0">
                    <b> {{ water.date_time.substr(0, 11) }} </b>
                  </p>
                </div>
                <div class="col-9 justify-content-center pr-0 pt-2">
                  <h5 v-if="water.company_plant" class="h3 mb-0">
                    {{ water.company_plant.name }}
                  </h5>
                  <h6
                    v-if="water.company_plant_charge_point"
                    class="h3 mb-0 small mb-3"
                  >
                    {{ water.company_plant_charge_point.name }}
                  </h6>
                </div>
                <div class="col-9 justify-content-center pr-0 pt-2">
                  <h5 v-if="water.cmc_adjustment" class="h3 mb-0">
                    {{ water.cmc_adjustment.product }}
                  </h5>
                  <h6 v-if="water.company_plant_charge_point" class="h3 mb-0 small mb-3">
                    {{ water.cmc_adjustment.supplier }} ({{
                      water.cmc_adjustment.supplier_origin
                    }})
                  </h6>
                </div>
                <div class="col-9 justify-content-center pr-0 pt-0">
                  <p class="pb-0 mb-0 pr-1 pt-1">
                    <b>{{ water.user }}</b>
                  </p>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
    </div>
    <modal-edit-water ref="ModalEditWater">
    </modal-edit-water>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import ModalEditWater from "./_ModalEdit";
import { checkNumberValue } from "@/helpers";
import { mask1, formatErrorValidation } from "@/plugins";
import MultiFilter from "@/components/Utils/MultiFilterV2";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
export default {
  name: "ListWater",
  mixins: [cursorPaginate],
  components: {
    SkeletonPuzlGrid,
    ModalEditWater,
    PuzlEmptyData,
    MultiFilter,
    LoadingPagination
  },
  data() {
    return {
      loadingSkeleton: false,
      filter: {},
      multi_filter: { null: {} },
    };
  },
  computed: {
    ...mapGetters({
      waters: "water/fetch"
    })
  },
  watch: {
    added() {
      this.init();
    }
  },
  props: {
    added: Boolean
  },
  methods: {
    init(filter = null) {
      this.startCursor(filter)
      this.$Progress.start();
      this.$store
        .dispatch("water/fetchItemsPaginate", {
          filter: this.filter,
          next_page: this.paginate.nextUrl
        })
        .then(response => {
          this.resolveCursor(response)
          this.$Progress.finish();
        })
        .catch(error => {
          this.resolveCursor()
          this.$Progress.finish();
        });
    },
    handleEditWater(uuid) {
      this.$refs.ModalEditWater.handleEditModal(uuid);
    },
    handleDeleteWater(uuid) {
      this.$Swal
        .confirmDelete()
        .then(result => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação."
            });
            this.$store.dispatch("water/destroy", uuid).then(response => {
              this.$notify({
                type: 'success',
                message: response.message
              });
              this.$Progress.finish()
            }).catch(error => {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              });
            });
          }
        })
        .catch(() => this.$Progress.finish());
    }
  },
  created() {
    this.init();
  }
};
</script>

<style scoped>
.card:hover {
  cursor: pointer;
}
</style>
