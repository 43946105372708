<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <div class="px-3 py-2">
          <!-- Casos sem parcela -->
          <div v-if="!payload.has_installment">
            <h5 class="mt-0 font-weight-500 mb-0">
              <span class="new-default-black-font font-weight-normal"> Status : </span>
              <span class="new-default-black-font">{{ payload.integration_info.totvs_status === 0 ? "Pendente" : "Integrado" }} </span>
              <base-button @click="store" v-if="!payload.integration_info.totvs_status || 2==2" size="sm" class="ml-2 mt-n1" type="success">
                INTEGRAR
              </base-button>
            </h5>
            <h5 class="mt-0 mb-1 font-weight-500">
              <span class="new-default-black-font font-weight-normal"> Idmov : </span>
              <span class="new-default-black-font"
                >{{ payload.integration_info.totvs_id ? payload.integration_info.totvs_id : "Nenhum" }}
              </span>
            </h5>
            <h5 class="mt-0 font-weight-500 mb-1">
              <span class="new-default-black-font font-weight-normal"> Data de integração : </span>
              <span v-if="payload.integration_info.totvs_id" class="new-default-black-font"
                >{{ payload.integration_info.updated_at | parseDate() }}
              </span>
              <span v-else class="new-default-black-font">Nenhuma </span>
            </h5>
            <h5 v-if="!payload.integration_info.totvs_status" class="mt-0 font-weight-500 mb-1">
              <span class="new-default-black-font font-weight-normal"> Erro de integração : </span>
              <span class="new-default-black-font">{{ payload.integration_info.response }} </span>
            </h5>
          </div>

          <!-- Casos com parcela -->
          <div v-else>
            <div v-for="(item, index) in payload.integration_info" :key="index">
              <h5 class="mt-0 font-weight-500 mb-0">
                <span class="new-default-black-font font-weight-normal"> Status : </span>
                <span class="new-default-black-font">{{ item.totvs_status === 0 ? "Pendente" : "Integrado" }} </span>
                <base-button @click="store" v-if="!item.totvs_status" size="sm" class="ml-2 mt-n1" type="success"> INTEGRAR </base-button>
              </h5>
              <h5 class="mt-0 mb-1 font-weight-500">
                <span class="new-default-black-font font-weight-normal"> Idmov : </span>
                <span class="new-default-black-font">{{ item.totvs_id ? item.totvs_id : "Nenhum" }} </span>
              </h5>
              <h5 class="mt-0 font-weight-500 mb-1">
                <span class="new-default-black-font font-weight-normal"> Data de integração : </span>
                <span v-if="item.totvs_id" class="new-default-black-font">{{ item.updated_at | parseDate() }} </span>
                <span v-else class="new-default-black-font">Nenhuma </span>
              </h5>
              <h5 class="mt-0 mb-1 font-weight-500">
                <span class="new-default-black-font font-weight-normal"> Parcela : </span>
                <span class="new-default-black-font">{{ item.index }} </span>
              </h5>
              <h5 v-if="!item.totvs_status" class="mt-0 font-weight-500 mb-1">
                <span class="new-default-black-font font-weight-normal"> Erro de integração : </span>
                <span class="new-default-black-font">{{ item.response }} </span>
              </h5>
                <hr class="mt-3 mb-3">
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center row mt-3">
            <base-button type="secondary" @click="closeModal('create')"> Fechar </base-button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { entity_bill_receive as $_totvs } from "@/plugins/microservices/totvs";

export default {
  data() {
    return {
      modal: {
        title: "Integração Totvs",
        create: false,
      },
      entity_bill_receive_id: null,
      payload: {
        has_installment: false,
        integration_info: {
          totvs_id: null,
          totvs_status: 0,
          updated_at: null,
          response: null,
        },
      },
    };
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal(id) {
      const loader = this.$loading.show();
      this.entity_bill_receive_id = id;
      this.payload = {
        has_installment: false,
        integration_info: {
          totvs_id: null,
          totvs_status: 0,
          updated_at: null,
          response: null,
        },
      };

      $_totvs
        .getIntegrationInfo(id)
        .then((response) => {
          this.modal.create = true;
          if (response.data && response.data.integration_info) {
            this.payload = response.data;
          }
        })
        .finally(() => {
          loader.hide();
        });
    },
    store() {
      const loader = this.$loading.show();
      $_totvs.store({ entity_bill_receive_id: this.entity_bill_receive_id }).finally(() => {
        this.closeModal();
        loader.hide();
      });
    },
  },
};
</script>
