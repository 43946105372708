<template>
  <div>
    <multi-filter ref="multi-filter" @fetch="load" :filter="filter" />
    <div class="container-fluid">
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <!-- Empty Data -->
      <PuzlEmptyData v-if="!suppliers.length && !loadingSkeleton"></PuzlEmptyData>

      <div class="row card-wrapper" v-if="suppliers.length && !loadingSkeleton">
        <div
          class="col-lg-4"
          v-for="(supplier, indexSupplier) in suppliers"
          :key="indexSupplier"
        >
          <!-- Basic with action button -->
          <card :class="supplier.status ? 'card-border-top-success' : 'card-border-top-danger'">
            <!-- Card header -->
            <div class="row align-items-center mb-3">
              <div class="col-8">
                <!-- Title -->
                <h5 class="h3 mb-0">{{ supplier.supplier_name }}</h5>
                <h6 class="h3 mb-0 small">{{ supplier.website }}</h6>
              </div>

              <div class="col-4 text-right">
                <base-dropdown menuOnRight>
                  <base-button
                    slot="title-container"
                    type="primary"
                    class="dropdown-toggle p-2 rounded m-0"
                  >
                    <i class="fas fa-cog"></i>
                  </base-button>
                  <router-link :to="{ path:'/technology/cmc/supplier/origin',name:'Procedência MCC', params: { supplier_uuid: supplier.uuid}}">
                    <a class="dropdown-item">
                      <i class="fas fa-map-marker text-default"></i>
                      Procedência
                    </a>
                  </router-link>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleEditSupplier(supplier.uuid)">
                    <i class="fas fa-edit text-warning"></i>
                    Editar
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleDeleteSupplier(supplier.uuid)">
                    <i class="fas fa-times text-danger"></i>
                    Excluir
                  </a>
                </base-dropdown>
              </div>
            </div>
            <!-- Card body -->
            <collapse class="border rounded" v-if="supplier.supplier_origins!=''">
              <collapse-item name="1" class="header-gray" back-ground="border-header-primary-bottom">
                <h5 slot="title" class="mb-0 ">Procedências</h5>

                <collapse class="border rounded mt-3" v-for="(origin, indexOrigin) in supplier.supplier_origins" :key="indexOrigin">
                  <collapse-item name="1" class="header-gray" back-ground="border-header-default-bottom">
                    <h5 slot="title" class="mb-0 ">{{origin.origin_name}}</h5>

                    <div v-for="(entity, indexEntity) in origin.entities" :key="indexEntity">
                      <div class="form-group row m-0 p-0 mb-1 mt-2">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-10">
                              <h5 class="h5 ">{{ entity.entity_name }}</h5>
                              <h5 class="h5 small">{{ entity.document }}</h5>
                            </div>

                            <div class="col-md-2 p-2 pl-5">
                              <a href="#" class="">
                                <i class="fas fa-times text-danger"></i>
                              </a>
                            </div>
                          </div>

                          <div class="dropdown-divider p-0 m-0">&nbsp;</div>
                        </div>
                      </div>
                    </div>

                  </collapse-item>
                </collapse>

              </collapse-item>
            </collapse>

          </card>
        </div>
      </div>
      <edit-additional-classification ref="editAdditionalClassification"></edit-additional-classification>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid"
import PuzlEmptyData from "@/components/PuzlEmptyData"
import EditAdditionalClassification from  './_Edit'
import MultiFilter from '@/components/Utils/MultiFilter.vue';

export default {
  name: "ListAdditionalClassification",
  components: {
    PuzlEmptyData,
    SkeletonPuzlGrid,
    EditAdditionalClassification,
    MultiFilter
  },
  data () {
    return {
      loadingSkeleton: true,
      filter: {}
    }
  },
  computed: {
    ...mapGetters({
      'suppliers': 'cmcSupplier/fetch',
    }),
  },
  
  methods:{
    load(filter = {}) {
      this.filter = filter
      this.init();
    },

    init() {
      this.loadingSkeleton = true;
      this.$store.dispatch('cmcSupplier/fetchItems', { filter: this.filter })
        .then( response =>{
          this.loadingSkeleton = false
        })
        .catch( error => {
          this.loadingSkeleton = false
        })
    },

    handleEditSupplier(uuid){
      this.$refs.editAdditionalClassification.handleEditModal(uuid)
    },
    
    handleDeleteSupplier(uuid){
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed){
          this.$Progress.start();
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          this.$store.dispatch('cmcSupplier/destroy', uuid).then(response => {
            this.$notify({
              type: 'success',
              message: response.message
            });
          }).catch(error => {
            this.$notify({
              type: error.data.error_type,
              message: 'Erro ao excluir fornecedor mcc!'
            });
          })
          this.$Progress.finish();
        }
      }).catch(() => this.$Progress.finish())
    }
  },
  
  mounted() {
    this.filter.status = 1;
    this.$refs['multi-filter']._self.$forceUpdate();
    this.init();
  },
  
  created () {
  }
}
</script>

<style scoped>

</style>
