<template>
  <div>
    <modal :borderInfo="'2px solid #1b6eba'" :show.sync="modal" :size="'lg'">
      <template slot="header">
        <div style="display: flex;align-items: center;">
          <img style="display: inline-flex;" src="/img/icons/icons8/ios/create-new-white.png" width="22">
          <h5 style="display: inline-flex;" class="modal-title p-0 m-0 ml-3 text-white">{{ title }}</h5>
        </div>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(save)"
            autocomplete="off"
          >
            <div class="container mt-3">
              <div class="row align-items-center">
                <div class="col-4" style="display: flex;align-items: center;">
                  <el-popover trigger="click" placement="right">
                    <img class="pointer" slot="reference" src="/img/icons/test-tube.png" width="24">
                    <div v-if="!technicalPermissions.is_view_technical_data_of_traces_in_test_results_launch"
                         style="width: 170px; word-break: break-word;">
                      <h5 class="text-danger">
                        Sem permissão
                      </h5>
                      <div class="new-default-black-font" style="font-size: 12px;">
                        Visualização indisponível, solicite permissão ao gestor.
                      </div>
                    </div>
                    <div style="margin-bottom: 5px"
                         v-if="hardened_state_test.mix_code_id && technicalPermissions.is_view_technical_data_of_traces_in_test_results_launch"
                         v-for="item in hardened_state_test.company_plants">
                      <i title="Copiar código do traço" @click.prevent="copyMixCode(item.mix_code_id)"
                         style="font-size: 19px" class="zoom pointer fa-regular fa-clipboard text-primary"></i>
                      <span class="font-weight-400 ml-2">{{ item.company_plant_name }} <badge style="font-size: 10px"
                                                                                              type="default">{{
                          item.mix_code_id
                        }}</badge></span>
                    </div>
                    <div v-if="technicalPermissions.is_view_technical_data_of_traces_in_test_results_launch"
                         class="border-left border-3 border-primary p-2 mt-1">
                      <h5 class="h4 mb-0">
                        {{ report.contract_proposal_formulation.title }}
                      </h5>
                      <div class="mt-2 mb-3">
                        <h5 class="small">
                          {{ report.contract_proposal_formulation.body }}
                        </h5>
                      </div>
                      <div class="mt-2 mb-3">
                        <h5 class="small">
                          {{ report.contract_proposal_formulation.footer }}
                        </h5>
                      </div>
                    </div>
                  </el-popover>
                  <h5 class="ml-2 font-weight-400 my-0">{{ report.updated_at | parseDate }}</h5>
                </div>
                <div class="col-8 pr-4">
                  <div class="row text-right">
                    <div class="col px-1">
                      <a class="card-with-box-shadow text-center" style="display: inherit;">
                        <div class="py-2">
                          <img class="mr-2" width="18" src="/img/icons/nfe.png"/>
                          <b class="new-default-black-font numeric">3266</b>
                        </div>
                      </a>
                    </div>
                    <div class="col px-1">
                      <a class="card-with-box-shadow text-center" style="display: inherit;">
                        <div class="py-2">
                          <img class="mr-1" width="22" src="/img/icons/icons8/ios/os.png"/>
                          <b class="new-default-black-font numeric">{{ report.index }}</b>
                        </div>
                      </a>
                    </div>
                    <div class="col px-1">
                      <a class="card-with-box-shadow text-center" style="display: inherit;">
                        <div class="py-2 px-3" style="display: inline-flex;align-items: center;">
                          <div class="row" style="width: 141.6px;">
                            <div class="col-6 text-right pr-0">
                              <img class="mr-2" width="18" src="/img/icons/icons8/ios/source-code.png"/>
                            </div>
                            <div class="col-6 text-left pl-0">
                              <input
                                style="max-width: 100%;"
                                v-model="hardened_state_test.code"
                                v-mask="'##########'"
                                inputmode="numeric"
                                placeholder="N/A"
                                class="invisible-input"
                              />
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <hr/>
              <div class="row mb-1">
                <div class="col-4">
                  <h4>Data da amostragem *</h4>
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input-date-time-picker :spaceMinutes="1"
                                              :disableDate="new Date(report.finished_at)"
                                              @input="setRealPeriod"
                                              v-model="hardened_state_test.sampling_at"
                                              :size="'lg'"/>
                    </base-input>
                  </validation-provider>
                </div>
                <div class="col-5">
                  <h4>Idade do ensaio *</h4>
                  <div class="row">
                    <div class="col-6 pr-1">
                      <validation-provider rules="required">
                        <base-input input-group-classes="input-group-sm">
                          <input
                            @input="callPeriod()"
                            v-model="hardened_state_test.period"
                            v-mask="'##'"
                            inputmode="numeric"
                            class="form-control form-control-sm"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                    <div class="col-6 pl-1">
                      <validation-provider rules="required">
                        <base-input input-classes="form-control-sm">
                          <el-select
                            @input="callPeriodType"
                            v-model="hardened_state_test.period_type"
                            size="mini"
                            filterable
                            placeholder="Tipo"
                          >
                            <el-option
                              v-for="days_or_hour in days_or_hours"
                              :key="days_or_hour.id"
                              :label="days_or_hour.description"
                              :value="days_or_hour.id"
                            >
                            </el-option>
                          </el-select>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <div style="width: 107px;" class="float-right">
                    <h4>Puzl Connect</h4>
                    <base-switch
                      v-model="hardened_state_test.puzl_client"
                      type="indigo" offText="Não" onText="Sim"
                      class="indigo">
                    </base-switch>
                  </div>
                </div>
              </div>
              <div class="row mt-4 mb-4">
                <div class="col-4 pr-0">
                  <base-button class="border-new-default-gray base-button"
                               @click.prevent="tab = 1"
                               :class="{ active: (tab === 1) }"
                               outline
                               style="border-top-left-radius: 1.5rem !important;border-bottom-left-radius: 1.5rem !important;border-top-right-radius: 0 !important;border-bottom-right-radius: 0 !important;"
                               type="primary"
                               block>
                    <img
                      :src="
                        (tab === 1)
                          ? '/img/icons/icons8/ios/info-squared_white.png'
                          : '/img/icons/info-squared.png'
                      "
                      width="19px"
                      height="19px"
                      class="invert-on-hover mr-1"/>
                    <span :class="(tab === 1) ? 'text-white' : 'text-dark'"
                          style="font-size: 10px">
                      Identificação
                    </span>
                  </base-button>
                </div>
                <div class="col-4 px-0">
                  <!-- @click.prevent="goToServices" -->
                  <base-button @click.prevent="tab = 2"
                               type="primary"
                               style="border-radius: 0 !important;"
                               class="border-new-default-gray base-button"
                               :class="{ active: (tab === 2) }"
                               outline
                               block
                  >
                    <img
                      :src="
                        (tab === 2)
                          ? '/img/icons/icons8/ios/test-passed--v1.png'
                          : '/img/icons/icons8/ios/test-passed--v1_primary.png'
                      "
                      width="19px"
                      height="19px"
                      class="invert-on-hover mr-1"
                    />
                    <span :class="(tab === 2) ? 'text-white' : 'text-dark'"
                          style="font-size: 10px">
                      Resultado
                    </span>
                  </base-button>
                </div>
                <div v-if="hasNecessaryFields" class="col-4 pl-0">
                  <base-button @click.prevent="tab = 3" class="border-new-default-gray base-button"
                               :class="{ active: (tab === 3) }"
                               :disabled="loading_evolution"
                               outline
                               style="border-top-left-radius: 0 !important;border-bottom-left-radius: 0 !important;border-top-right-radius: 1.5rem !important;border-bottom-right-radius: 1.5rem !important;"
                               type="primary"
                               block>
                    <img
                      :src="
                        (tab === 3)
                          ? '/img/icons/icons8/ios/sign-up-in-calendar.png'
                          : '/img/icons/icons8/ios/sign-up-in-calendar_success.png'
                      "
                      width="19px"
                      height="19px"
                      class="invert-on-hover mr-1"/>
                    <span :class="(tab === 3) ? 'text-white' : 'text-dark'"
                          style="font-size: 10px">
                      Evolução
                    </span>
                  </base-button>
                </div>
              </div>
              <div class="row" v-if="tab === 1">
                <div class="col-12">
                  <div class="row mt-1">
                    <div class="col-12">
                      <div class="card-with-box-shadow px-3 py-2">
                        <div class="row mb-3 pt-1">
                          <div class="col-12">
                            <h4 style="display: flex; align-items: center;">
                              <img class="mr-2" src="/img/icons/icons8/ios/star--v1_primary.png" width="18">
                              Dados técnicos
                            </h4>
                          </div>
                        </div>
                        <div v-if="!technicalPermissions.is_view_technical_data_of_traces_in_test_results_launch"
                             class="row mb-3">
                          <div class="col-12">
                            <h5 class="text-danger">
                              Sem permissão
                            </h5>
                            <div class="new-default-black-font" style="font-size: 12px;">
                              Visualização indisponível, solicite permissão ao gestor.
                            </div>
                          </div>
                        </div>
                        <div v-if="technicalPermissions.is_view_technical_data_of_traces_in_test_results_launch"
                             class="row mb-3">
                          <div class="col-4">
                            <h4 class="font-weight-400" style="font-size: 12px;">Ensaio</h4>
                            <validation-provider rules="required">
                              <base-input input-classes="form-control-sm">
                                <el-select
                                  v-model="hardened_state_test.hardened_state_id"
                                  size="mini"
                                  filterable
                                  @change="setHardenedState"
                                  placeholder="Selecione"
                                >
                                  <el-option
                                    v-for="test in hardened_states"
                                    :key="test.uuid"
                                    :label="test.short_description"
                                    :value="test.id"
                                  >
                                  </el-option>
                                </el-select>
                              </base-input>
                            </validation-provider>
                          </div>
                          <div class="col-4">
                            <h4 class="font-weight-400" style="font-size: 12px;">Responsabilidade</h4>
                            <validation-provider rules="required">
                              <base-input input-classes="form-control-sm">
                                <el-select
                                  @change="changeFeature"
                                  :disabled="!selected.features.length"
                                  v-model="hardened_state_test.feature_id"
                                  size="mini"
                                  filterable
                                  placeholder="Selecione"
                                >
                                  <el-option
                                    v-for="feature in selected.features"
                                    :key="feature.id"
                                    :label="feature.label"
                                    :value="feature.id"
                                  >
                                  </el-option>
                                </el-select>
                              </base-input>
                            </validation-provider>
                          </div>
                          <div class="col-4">
                            <h4 class="font-weight-400" style="font-size: 12px;">Item</h4>
                            <validation-provider rules="required">
                              <base-input input-classes="form-control-sm">
                                <el-select
                                  :disabled="!selectedFeature.item.length"
                                  v-model="hardened_state_test.item_id"
                                  size="mini"
                                  filterable
                                  placeholder="Selecione"
                                >
                                  <el-option
                                    v-for="item in selectedFeature.item"
                                    :key="item.id"
                                    :label="item.description +' '+selectedFeature.measured_unit.letter"
                                    :value="item.id"
                                  >
                                  </el-option>
                                </el-select>
                              </base-input>
                            </validation-provider>
                          </div>
                        </div>
                        <div v-if="technicalPermissions.is_view_technical_data_of_traces_in_test_results_launch"
                             class="row mb-3">
                          <div class="col-4">
                            <h4 class="font-weight-400" style="font-size: 12px;">SD</h4>
                            <validation-provider rules="required">
                              <input
                                v-model="hardened_state_test.sd"
                                v-mask="['#.##']"
                                inputmode="numeric"
                                class="form-control form-control-sm"
                              />
                            </validation-provider>
                          </div>
                          <div class="col-4">
                            <h4 class="font-weight-400" style="font-size: 12px;">N.C *</h4>
                            <validation-provider rules="required">
                              <base-input input-group-classes="input-group-sm">
                                <el-select v-model="hardened_state_test.nc"
                                           @change="changeValueZ()"
                                           placeholder="Selecione"
                                           size="mini">
                                  <el-option label="Selecione" value="">
                                  </el-option>
                                  <el-option v-for="(reliability_level, indexReliability) in reliability_levels"
                                             :key="indexReliability" :label="reliability_level.reliability+'%'"
                                             :value="reliability_level.value_z">
                                  </el-option>
                                </el-select>
                              </base-input>
                            </validation-provider>
                          </div>
                          <div class="col-4">
                            <h4 class="font-weight-400" style="font-size: 12px;">Z</h4>
                            <validation-provider rules="required">
                              <input
                                disabled
                                v-model="hardened_state_test.z"
                                v-mask="['#.##']"
                                inputmode="numeric"
                                class="form-control form-control-sm"
                              />
                            </validation-provider>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-4 mb-3">
                    <div class="col-12">
                      <div class="card-with-box-shadow px-3 py-2">
                        <div class="row mb-3 pt-1">
                          <div class="col-12">
                            <h4 style="display: flex; align-items: center;">
                              <img class="mr-2" src="/img/icons/icons8/ios/brick-wall_warning.png" width="18">
                              Rastreabilidade
                            </h4>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-4">
                            <h4 class="font-weight-400" style="font-size: 12px;">Local de amostragem *</h4>
                            <validation-provider rules="required">
                              <base-input input-classes="form-control-sm">
                                <el-select
                                  :disabled="loadingMoldingLocation"
                                  size="mini" v-model="hardened_state_test.molding_location_id" filterable
                                  placeholder="Selecione"
                                  :selected="false">
                                  <el-option class="text-normal" v-for="item in molding_locations"
                                             :key="item.id" :label="item.name"
                                             :value="item.id">
                                  </el-option>
                                </el-select>
                              </base-input>
                            </validation-provider>
                          </div>
                          <div class="col-4">
                            <h4 class="font-weight-400" style="font-size: 12px;">Molde *</h4>
                            <validation-provider rules="required">
                              <base-input input-classes="form-control-sm">
                                <el-select
                                  :disabled="!selected.uuid"
                                  v-model="hardened_state_test.mold_id"
                                  size="mini"
                                  filterable
                                  placeholder="Selecione"
                                >
                                  <el-option
                                    v-for="mold in selected.molds"
                                    :key="mold.uuid"
                                    :label="mold.description"
                                    :value="mold.id"
                                  >
                                  </el-option>
                                </el-select>
                              </base-input>
                            </validation-provider>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="tab === 2">
                <div class="col-12">
                  <div class="row mt-1 mb-3">
                    <div class="col-12">
                      <div class="card-with-box-shadow px-3 py-2">
                        <div class="row mb-3 pt-1">
                          <div class="col-8">
                            <h4 style="display: flex; align-items: center;">
                              <img class="mr-2" src="/img/icons/icons8/ios/color-dropper_primary.png" width="18">
                              Ensaio
                            </h4>
                          </div>
                          <div class="col-4">
                            <base-switch
                              @input="unsetReason()"
                              v-model="hardened_state_test_molder"
                              type="success" offText="Não" onText="Sim"
                              class="success float-right"></base-switch>
                          </div>
                        </div>
                        <div class="row mb-3" v-if="hardened_state_test_molder">
                          <div class="col-4">
                            <h4 class="font-weight-500" style="font-size: 12px;">
                              Data do ensaio (real)
                              <img @click.prevent="copyPredictDateToReal"
                                   class="ml-2 pointer float-right"
                                   src="/img/icons/icons8/ios/copy-2.png" width="18">
                            </h4>
                            <validation-provider :rules="hardened_state_test.result && 'required'">
                              <base-input input-group-classes="input-group-sm">
                                <input-date-time-picker :spaceMinutes="1"
                                                        @input="setRealPeriod"
                                                        :disableDate="new Date(hardened_state_test.sampling_at)"
                                                        v-model="real_date"
                                                        :size="'lg'"/>
                              </base-input>
                            </validation-provider>
                          </div>
                          <div class="col-4">
                            <h4 class="font-weight-400" style="font-size: 12px;">Idade (real)</h4>
                            <base-input input-group-classes="input-group-sm">
                              <input
                                disabled
                                v-model="hardened_state_test.real_period"
                                inputmode="numeric"
                                class="form-control form-control-sm"
                              />
                            </base-input>
                          </div>
                          <div class="col-4">
                            <h4 class="font-weight-400" style="font-size: 12px;">
                              Data do ensaio (previsto)
                            </h4>
                            <base-input input-group-classes="input-group-sm">
                              <input-date-time-picker :spaceMinutes="1"
                                                      :disabled="true"
                                                      v-model="hardened_state_test.provided_at"
                                                      :size="'lg'"/>
                            </base-input>
                          </div>
                        </div>
                        <div class="row mb-3" style="align-items: end;">
                          <div class="col-4" v-if="hardened_state_test_molder">
                            <h4 class="font-weight-500" style="font-size: 12px;">
                              Resultado (real)
                              <span v-show="hardened_state_test.date_at || hardened_state_test.result"
                                    class="text-danger">*</span>
                              <img @click.prevent="handleShowTonne" class="ml-2 pointer"
                                   src="/img/icons/icons8/ios/calculator_black.png" width="18">
                            </h4>
                            <validation-provider v-if="!showTonne"
                                                 :rules="hardened_state_test.date_at && hardened_state_test_molder ? 'required' : ''">
                              <base-input input-group-classes="input-group-sm">
                                <input
                                  v-mask="['#,#', '##,#', '###,#']"
                                  v-model="hardened_state_test.result"
                                  inputmode="numeric"
                                  class="form-control form-control-sm"
                                />
                                <template slot="append">
                                  <small v-show="unit() != false" style="text-transform: none"
                                         class="input-group-sm p-0 m-0">
                                    {{ measuredunit }}
                                  </small>
                                </template>
                              </base-input>
                            </validation-provider>
                            <validation-provider v-if="showTonne"
                                                 :rules="hardened_state_test.date_at && hardened_state_test_molder ? 'required' : ''">
                              <div class="mb-2">
                                <base-input input-group-classes="input-group-sm">
                                  <input
                                    @input="setResult()"
                                    v-mask="['#,#', '##,#', '###,#']"
                                    v-model="hardened_state_test.ton"
                                    inputmode="numeric"
                                    class="form-control form-control-sm"
                                  />
                                  <template slot="append">
                                    <small class="input-group-sm p-0 m-0">
                                      TON
                                    </small>
                                  </template>
                                </base-input>
                              </div>
                              <base-input id="input-red" input-group-classes="input-group-sm">
                                <input
                                  disabled
                                  :placeholder="(Number(hardened_state_test.result)).toFixed(1).toString().replace('.', ',')"
                                  inputmode="numeric"
                                  class="form-control form-control-sm"
                                />
                                <template slot="append">
                                  <small v-show="unit() != false" style="text-transform: none"
                                         class="input-group-sm p-0 m-0">
                                    {{ measuredunit }}
                                  </small>
                                </template>
                              </base-input>
                            </validation-provider>
                          </div>
                          <div class="col-4" v-if="hardened_state_test_molder">
                            <h4 class="font-weight-400" style="font-size: 12px;">Resultado (estimado)</h4>
                            <base-input input-group-classes="input-group-sm">
                              <input
                                v-mask="['#,#', '##,#', '###,#']"
                                v-model="hardened_state_test.result_expected"
                                inputmode="numeric"
                                class="form-control form-control-sm"
                              />
                              <template slot="append">
                                <small v-show="unit() != false" class="input-group-sm p-0 m-0">
                                  {{ measuredunit }}
                                </small>
                              </template>
                            </base-input>
                          </div>
                          <div class="col-8" v-if="!hardened_state_test_molder">
                            <h4 class="font-weight-400" style="font-size: 12px;">
                              Motivo
                              <span class="text-danger">*</span>
                            </h4>
                            <validation-provider :rules="!hardened_state_test_molder ? 'required' : ''">
                              <input
                                v-model="hardened_state_test.reason"
                                class="form-control form-control-sm"
                              />
                            </validation-provider>
                          </div>
                          <div class="col-2 text-center" v-if="hardened_state_test_molder">
                            <div style="width: 80px;text-align: left;display: inline-block;">
                              <h4 class="font-weight-400" style="font-size: 12px;">Estatístico</h4>
                              <base-switch
                                @input="addReason()"
                                v-model="hardened_state_test.statistical"
                                type="primary" offText="Não" onText="Sim"
                                class="success"></base-switch>
                            </div>
                          </div>
                          <div class="col-2 text-center" v-if="hardened_state_test_molder">
                            <div style="width: 80px;text-align: left;display: inline-block;">
                              <h4 class="font-weight-400" style="font-size: 12px;">Relatório</h4>
                              <base-switch
                                v-model="hardened_state_test.report"
                                type="success" offText="Não" onText="Sim"
                                class="success"></base-switch>
                            </div>
                          </div>
                        </div>
                        <div v-if="verifyResistance()" class="row mb-3">
                          <div class="col-12">
                            <h5 class="font-weight-400" style="display: flex;align-items: center;">
                              <img class="mr-2" src="/img/icons/error-red.png" width="16">
                              Alta resistência identificada. Confira sempre o resultado digitado.
                            </h5>
                            <div class="form-check">
                              <input v-model="confirmResult" @click="changeConfirmResult" class="form-check-input"
                                     type="checkbox" id="confirmarResultado">
                              <label class="form-check-label new-default-black-font" style="font-size: 12px;"
                                     for="confirmarResultado">
                                Confirmar resultado
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-1" v-if="hardened_state_test_molder">
                    <div class="col-12">
                      <div class="card-with-box-shadow px-3 py-2">
                        <div class="row mb-3 pt-1">
                          <div class="col-12">
                            <h4 style="display: flex; align-items: center;">
                              <img class="mr-2" src="/img/icons/icons8/ios/about_success.png" width="18">
                              Informações complementares
                            </h4>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-4">
                            <h4 class="font-weight-400" style="font-size: 12px;">Padrão de rompimento</h4>
                          </div>
                          <div class="col-8">
                            <base-input input-group-classes="input-group-sm">
                              <el-select
                                v-model="hardened_state_test.disruption"
                                size="mini"
                                filterable
                                placeholder="Selecione"
                              >
                                <el-option
                                  v-for="disruption in disruptions"
                                  :key="disruption.id"
                                  :label="'(' + disruption.id + ') ' + disruption.text"
                                  :value="disruption.id"
                                >
                                </el-option>
                              </el-select>
                            </base-input>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-4">
                            <h4 class="font-weight-400" style="font-size: 12px;">Anomalias</h4>
                          </div>
                          <div class="col-8">
                            <base-input input-group-classes="input-group-sm">
                              <PuzlSelect multiple :items="$_anomalies" v-model="hardened_state_test.anomalies"/>
                            </base-input>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-4">
                            <h4 class="font-weight-400" style="font-size: 12px;">Responsável pelo ensaio</h4>
                          </div>
                          <div class="col-8">
                            <base-input input-group-classes="input-group-sm">
                              <PuzlSelect :items="$_users" v-model="hardened_state_test.user_id"/>
                            </base-input>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-2 pr-1">
                            <h4 class="font-weight-400" style="font-size: 12px;">Cura</h4>
                            <base-input input-classes="form-control-sm">
                              <el-select
                                v-model="hardened_state_test.health"
                                size="mini"
                                filterable
                                placeholder="Selecione"
                              >
                                <el-option
                                  v-for="(health, key) in healths"
                                  :key="key"
                                  :label="health"
                                  :value="key"
                                >
                                </el-option>
                              </el-select>
                            </base-input>
                          </div>
                          <div class="col-2 pl-1 pr-1">
                            <h4 class="font-weight-400" style="font-size: 12px;">M.E</h4>
                            <base-input input-group-classes="input-group-sm">
                              <input
                                v-mask="'####'"
                                v-model="hardened_state_test.me"
                                inputmode="numeric"
                                class="form-control form-control-sm"
                              />
                              <template slot="append">
                                <small class="input-group-sm p-0 m-0">
                                  kg/m³
                                </small>
                              </template>
                            </base-input>
                          </div>
                          <div class="col-4 pl-1 pr-1">
                            <h4 class="font-weight-400" style="font-size: 12px;">Base inferior</h4>
                            <base-input input-classes="form-control-sm">
                              <el-select
                                v-model="hardened_state_test.bottom_base"
                                size="mini"
                                multiple
                                filterable
                                placeholder="Selecione"
                              >
                                <el-option
                                  v-for="base in base_preparations"
                                  :key="base.id"
                                  :label="base.description"
                                  :value="base.id"
                                >
                                </el-option>
                              </el-select>
                            </base-input>
                          </div>
                          <div class="col-4 pl-1">
                            <h4 class="font-weight-400" style="font-size: 12px;">Base Superior</h4>
                            <base-input input-classes="form-control-sm">
                              <el-select
                                v-model="hardened_state_test.top_base"
                                size="mini"
                                multiple
                                filterable
                                placeholder="Selecione"
                              >
                                <el-option
                                  v-for="base in base_preparations"
                                  :key="base.id"
                                  :label="base.description"
                                  :value="base.id"
                                >
                                </el-option>
                              </el-select>
                            </base-input>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-12">
                            <h4 class="font-weight-400" style="font-size: 12px;">Observações</h4>
                            <base-input input-group-classes="input-group-sm">
                              <textarea
                                v-model="hardened_state_test.observation"
                                type="text"
                                rows="6"
                                class="form-control form-control-sm">
                              </textarea>
                            </base-input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-1" v-else>
                    <div class="col-12">
                      <div class="card-with-box-shadow px-3 py-2">
                        <div class="row mb-3 pt-1">
                          <div class="col-12">
                            <h4 style="display: flex; align-items: center;">
                              <img class="mr-2" src="/img/icons/icons8/ios/about_success.png" width="18">
                              Informações complementares
                            </h4>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-4">
                            <h4 class="font-weight-400" style="font-size: 12px;">Anomalias</h4>
                          </div>
                          <div class="col-8">
                            <base-input input-group-classes="input-group-sm">
                              <PuzlSelect multiple :items="$_anomalies" v-model="hardened_state_test.anomalies"/>
                            </base-input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <section v-show="tab ===3">
                <div class="form-group row m-0 p-0">
                  <div class="col-md-12">
                    <h4 class="small">
                      Referência <b>{{ hardened_state_test.period }}</b>
                      {{
                        formatPeriodType(hardened_state_test.period, hardened_state_test.period_type)
                      }}
                    </h4>
                  </div>
                  <div class="col-md-12">
                    <h4 v-if="projections.max_result" class="small">
                      Maior resultado
                      <b>{{ parseFloat(projections.max_result).toFixed(1).toString().replace('.', ',') }}</b>
                      {{ measuredunit }}
                    </h4>
                    <h4 v-else class="small text-warning">
                      Sem resultado
                    </h4>
                  </div>
                  <div v-if="Object.values(projections).length" class="col-md-12 mt-2">
                    <table class="table table-bordered table-sm">
                      <thead>
                      <tr style="background-color: #4e555b">
                        <th class="text-white text-center" scope="col">Idade</th>
                        <th class="text-white text-center" scope="col">%</th>
                        <th class="text-white text-center" scope="col">{{ measuredunit }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item, key) in projections.statistic">
                        <template v-if="!periodIsBigger(item)">
                          <td class="text-center" width="33%">{{ item.period }}
                            {{ formatPeriodType(item.period, item.period_type) }}
                          </td>
                          <td class="text-center" width="33%">
                            {{
                              projections.max_result && item.result ? (parseInt(
                                  (parseFloat(projections.max_result) - parseFloat(item.result)) / parseFloat(item.result) * 100)) >= 0 ?
                                  (parseInt(
                                    (parseFloat(projections.max_result) - parseFloat(item.result)) / parseFloat(item.result) * 100)) : 0
                                : '-'
                            }}
                          </td>
                          <td class="text-center" width="33%">
                            {{ item.result ? parseFloat(item.result).toFixed(1).toString().replace('.', ',') : '-' }}
                          </td>
                        </template>
                        <template v-if="periodIsBigger(item)">
                          <td class="text-center" width="33%">{{ item.period }}
                            {{ formatPeriodType(item.period, item.period_type) }}
                          </td>
                          <td class="text-center" width="33%">
                            <base-input :disabled="!projections.max_result" v-mask="'###'" size="sm" maxlength="3"
                                        v-model="item.percentage"/>
                          </td>
                          <td class="text-center" width="33%">{{
                              item.percentage ? calcPercentage(item.percentage, key) : '-'
                            }}
                          </td>
                        </template>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
              <div class="row mb-3">
                <div class="col-12">
                  <div class="text-right mt-4 mr-3">
                    <base-button type="secondary" @click="closeModal()">
                      Cancelar
                    </base-button>
                    <base-button @click.prevent="save()" type="primary"
                                 v-bind:disabled="invalid || (!confirmResult && verifyResistance())"
                                 :loading="loadingSave">
                      Salvar e Continuar
                    </base-button>
                    <base-button type="success" @click.prevent="saveAndClose"
                                 v-bind:disabled="invalid || (!confirmResult && verifyResistance())"
                                 :loading="loadingSave">
                      Salvar e fechar
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {VMoney} from 'v-money';
import moment from 'moment'
import InputDateTimePicker from "@/components/InputDateTimePicker";
import {debounce} from "@/plugins";
import PuzlSelect from "@/components/PuzlSelect.vue";

export default {
  components: {
    InputDateTimePicker,
    PuzlSelect
  },
  name: "ModalCreate",
  data() {
    return {
      features: [],
      projections: {},
      loadingMoldingLocation: false,
      modal: false,
      loadingSave: false,
      loading_evolution: false,
      tab: 1,
      showReason: false,
      technicalPermissions: {},
      title: "Incluir ensaios - estado endurecido",
      loadingHardenedState: false,
      hardened_state_test_molder: true,
      real_date: '',
      confirmResult: false,
      hardened_state_test: {},
      showTonne: false,
      windowWidth: null,
      healths: [
        'Total',
        'Parcial',
        'Nenhuma'
      ],
      selectedFeature: {
        item: []
      },
      selected: {
        molds: [],
        features: [],
      },
      disruptions: [
        {
          id: 'A',
          text: 'Cônica e cônica afastada 25mm do capeamento'
        },
        {
          id: 'B',
          text: 'Cônica e bipartida e cônica e com mais de uma partição'
        },
        {
          id: 'C',
          text: 'Colunar com formação de cones'
        },
        {
          id: 'D',
          text: 'Cônica e cisalhada'
        },
        {
          id: 'E',
          text: 'Cisalhada'
        },
        {
          id: 'F',
          text: 'Fraturas no topo e/ou na base abaixo do capeamento'
        },
        {
          id: 'G',
          text: 'Similar ao tipo f com fraturas próximas ao topo'
        },
      ],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false
      },
      company_plant_id: null,
      measuredunit: null,
      days_or_hours: [
        {
          id: 1,
          description: 'Dias'
        },
        {
          id: 2,
          description: 'Horas'
        }
      ],
      pickerOptions: {
        selectableRange: this.range,
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
    }
  },
  directives: {money: VMoney},
  computed: {
    ...mapGetters({
      'hardened_states': 'testHardenedState/fetch',
      'schedule_hardened_states': 'travelConfiguration/showHardenedStates',
      molding_locations: "moldingLocation/fetch",
      base_preparations: "basePreparation/fetch",
      'report': 'testReport/show',
      'reliability_levels': 'reliability_level/fetch',
      $_users: 'user/fetch',
      $_anomalies: 'anomaly/fetch',
    }),
  },
  async mounted() {
    await this.$store.dispatch('user/getAllWithFilter', {
      filter: {
        is_user: 1
      }
    })
    await this.$store.dispatch('anomaly/fetchItems');
    this.$watch('hardened_state_test.result', function (value) {
      if (!value) {
        return
      }
      const result = parseFloat(value.replace(',', '.')).toFixed(1)
      if (!this.projections.max_result_another_hardened_state
        || result > this.projections.max_result_another_hardened_state) {
        return this.projections.max_result = result
      }
      if (result > parseFloat(this.projections.max_result)) {
        this.projections.max_result = result
      }
    });
    this.$watch('hardened_state_test.molder', function (value) {
      this.hardened_state_test_molder = value;
    });
    window.addEventListener('resize', () => {
      this.$forceUpdate()
      this.windowWidth = window.innerWidth
      this.$forceUpdate()
    })
  },
  methods: {
    unsetReason() {
      if (this.hardened_state_test.molder) {
        this.hardened_state_test.reason = null
        this.hardened_state_test.date_at = new Date()
        this.real_date = this.hardened_state_test.date_at;
      } else {
        this.hardened_state_test.date_at = null
        this.real_date = this.hardened_state_test.date_at;
      }
    },
    copyPredictDateToReal() {
      this.hardened_state_test.date_at = this.hardened_state_test.provided_at;
      this.real_date = this.hardened_state_test.provided_at;
      this.setRealPeriod()
    },
    verifyResistance() {
      const item = this.selectedFeature.item.find((item) => item.id === this.hardened_state_test.item_id)
      let item_description = 0
      if (item) {
        item_description = item.description
      }
      const result = this.hardened_state_test.result ? Number(this.hardened_state_test.result.replace(',', '.')) : 0;
      return result > (2 * item_description);
    },
    changeConfirmResult() {
      this.confirmResult = !this.confirmResult;
    },
    /**
     * Valida idade da evolução
     */
    periodIsBigger(item) {
      // se referencia for dias e evolucao horas
      if (this.hardened_state_test.period_type === 1 && item.period_type === 2) {
        return false
      }
      // se referencia for horas e evolucao dias
      if (this.hardened_state_test.period_type === 2 && item.period_type === 1) {
        return true
      }
      if (this.hardened_state_test.period > item.period) {
        return false
      }
      return true
    },
    formatPeriodType(period, period_type) {
      switch (period_type) {
        case 1:
          return period > 1 ? `dias` : `dia`
        case 2:
          return period > 1 ? `horas` : `hora`
        default:
          return ''
      }
    },
    hasNecessaryFields() {
      if (this.hardened_state_test.period &&
        this.hardened_state_test.period_type &&
        this.hardened_state_test.hardened_state_id) {
        return true
      }
      return false
    },
    callSamplingAt() {
      const value = this.hardened_state_test.sampling_at
      if (value && this.hardened_state_test.period && this.hardened_state_test.period_type) {
        if (this.hardened_state_test.period_type === 1) {
          this.hardened_state_test.provided_at = this.addDays(this.hardened_state_test.sampling_at, this.hardened_state_test.period);
        } else {
          let date = new Date(this.hardened_state_test.sampling_at);
          this.hardened_state_test.provided_at = moment(date).add(this.hardened_state_test.period, 'hours').format('YYYY-MM-DD H:mm:ss');
        }
      }
    },
    handleShowTonne() {
      this.showTonne = !this.showTonne
      if (this.showTonne) {
        this.hardened_state_test.result = 0
        this.hardened_state_test.ton = 0
      }
    },
    callPeriodType() {
      if (this.hardened_state_test.sampling_at && this.hardened_state_test.period && this.hardened_state_test.period_type) {
        if (this.hardened_state_test.period_type === 1) {
          this.hardened_state_test.provided_at = this.addDays(this.hardened_state_test.sampling_at, this.hardened_state_test.period);
        } else {
          let date = new Date(this.hardened_state_test.sampling_at);
          this.hardened_state_test.provided_at = moment(date).add(this.hardened_state_test.period, 'hours').format('YYYY-MM-DD H:mm:ss');
        }
      }
      this.getProjections()
    },
    callPeriod() {
      if (this.hardened_state_test.period && this.hardened_state_test.sampling_at && this.hardened_state_test.period_type) {
        if (this.hardened_state_test.period_type === 1) {
          this.hardened_state_test.provided_at = this.addDays(this.hardened_state_test.sampling_at, this.hardened_state_test.period)
        } else {
          let date = new Date(this.hardened_state_test.sampling_at);
          this.hardened_state_test.provided_at = moment(date).add(this.hardened_state_test.period, 'hours').format('YYYY-MM-DD H:mm:ss')
        }
      }
      this.getProjections()
    },
    setResult() {
      if (!this.hardened_state_test.ton) {
        return
      }
      if (this.showTonne) {
        this.hardened_state_test.result = (Number(this.hardened_state_test.ton.replace(',', '.')) / 0.8).toString()
      }
    },
    addReason() {
      this.showReason = !this.showReason
    },
    calcPercentage(percentage, index) {
      const val = this.$helper.calcPercentage(percentage, parseFloat(this.projections.max_result))
      const val2 = (parseFloat(val) + parseFloat(this.projections.max_result))
        .toFixed(1)
        .replace('.', ',')
      this.projections.statistic[index].projection = val2
      if (isNaN(val2.replace(',', '.'))) {
        return '-'
      }
      return val2
    },
    setRealPeriod() {
      this.$nextTick(() => {
        this.callSamplingAt()
        if (this.hardened_state_test.sampling_at && this.hardened_state_test.date_at) {
          const date = moment(this.hardened_state_test.sampling_at).format('YYYY-MM-DD H:mm:ss')
          const date2 = moment(this.hardened_state_test.date_at).format('YYYY-MM-DD H:mm:ss')
          const diff = Math.abs(moment(date).diff(moment(date2), 'hours'))
          if (diff > 24) {
            this.$notify({type: 'warning', message: 'Atenção para diferença de idade.'})
          }
          return this.hardened_state_test.real_period = this.$helper.dateDiff(date, date2)
        }
        return this.hardened_state_test.real_period = null
      })
    },
    changeValueZ() {
      this.$nextTick(() => {
        this.hardened_state_test.z = this.hardened_state_test.nc
      })
    },
    addDays(date, days) {
      return moment(date).add(days, 'days').format('YYYY-MM-DD H:mm:ss')
    },
    unit() {
      const measuredunit = this.hardened_states.find(item => item.id == this.hardened_state_test.hardened_state_id)
      if (typeof measuredunit === 'object') {
        this.measuredunit = measuredunit.measured_unit.letter
        return true
      }
      return false
    },
    validMolder() {
      if (this.hardened_state_test.molder) {
        return
      }
      this.hardened_state_test.date_at = null
      this.hardened_state_test.result = null
      this.hardened_state_test.result_expected = null
      this.hardened_state_test.disruption = null
      this.hardened_state_test.health = null
      this.hardened_state_test.me = null
      this.hardened_state_test.bottom_base = null
      this.hardened_state_test.top_base = null
      this.hardened_state_test.observation = null
    },
    save() {
      this.loadingSave = true
      this.validMolder()
      let params = {...this.hardened_state_test}
      if (!this.hardened_state_test_molder) {
        params.anomalies = []
        params.user_id = null
        params.report = false
      }
      params.schedule_travel_uuid = this.$route.params.travel_uuid
      params.provided_at = this.$helper.parseDate(params.provided_at, 'DD-MM-YYYY H:mm:ss')
      params.statistics = this.projections.statistic
      params.max_result = this.projections.max_result
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch('travelConfiguration/createHardenedState', params)
        .then(response => {
            this.loadingSave = false
            this.$notify({type: 'success', message: response.message});
            this.hardened_state_test.result = null;
            this.hardened_state_test.molder = true;
            this.hardened_state_test.reason = null;
            this.hardened_state_test.result_expected = null;
            this.hardened_state_test.disruption = null;
            this.hardened_state_test.health = null;
            this.hardened_state_test.me = null;
            this.hardened_state_test.observation = null;
            this.hardened_state_test.bottom_base = null;
            this.hardened_state_test.top_base = null;
            this.tab = 1
            this.showTonne = false
            this.projections = {}
          }
        ).catch(() => this.loadingSave = false)
    },
    saveAndClose() {
      this.loadingSave = true
      this.validMolder()
      let params = {...this.hardened_state_test}
      params.schedule_travel_uuid = this.$route.params.travel_uuid
      params.provided_at = this.$helper.parseDate(params.provided_at, 'DD-MM-YYYY H:mm:ss')
      params.statistics = this.projections.statistic
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch('travelConfiguration/createHardenedState', params)
        .then(response => {
            this.loadingSave = false
            this.$notify({type: 'success', message: response.message});
            this.hardened_state_test = {
              sampling_at: null
            }
            this.modal = false
          }
        ).catch(() => this.loadingSave = false)
    },
    closeModal() {
      this.modal = false;
    },
    setHardenedState() {
      this.getProjections()
      this.hardened_state_test.feature_id = null
      this.hardened_state_test.item_id = null
      this.selectedFeature = {
        item: []
      }
      if (this.hardened_state_test.hardened_state_id) {
        this.selected = this.hardened_states.find((item) => item.id === this.hardened_state_test.hardened_state_id)
      } else {
        this.hardened_state_test.mold_id = null
        this.selected = {
          molds: [],
          features: [],
        }
      }
      const moldDefault = this.selected.molds.find(item => item.default == true)
      if (moldDefault) {
        this.hardened_state_test.mold_id = moldDefault.id
      }
      const moldLocationDefault = this.selected.default_molding_locations.find(item => item.company_plant_id == this.company_plant_id)
      if (moldLocationDefault) {
        this.hardened_state_test.molding_location_id = moldLocationDefault.molding_location_id
      }
      for (var feature of this.selected.features) {
        for (var item of this.features) {
          if (feature.id == item.feature_id && !this.hardened_state_test.feature_id) {
            this.hardened_state_test.feature_id = feature.id
            this.hardened_state_test.item_id = item.id
            this.hardened_state_test.sd = item.value_sd
            this.hardened_state_test.nc = parseFloat(item.value_nc)
          }
        }
      }
      this.setFeature()
      this.changeValueZ()
    },
    changeFeature() {
      this.setFeature()
      this.hardened_state_test.item_id = null
      this.changeValueZ()
    },
    setFeature() {
      if (this.hardened_state_test.feature_id) {
        this.selectedFeature = this.selected.features.find((item) => item.id === this.hardened_state_test.feature_id)
        this.hardened_state_test.period = this.selectedFeature.period
        this.hardened_state_test.period_type = this.selectedFeature.period_type
      } else {
        this.hardened_state_test.item_id = null
        this.selectedFeature = {
          item: []
        }
      }
    },
    getProjections: debounce(function () {
      if (!this.hardened_state_test.period || !this.hardened_state_test.period_type
        || !this.hardened_state_test.hardened_state_id) {
        return;
      }
      this.$store.dispatch('travelConfiguration/getProjections',
        {
          period: this.hardened_state_test.period,
          mix_feature_id: this.hardened_state_test.feature_id,
          item_id: this.hardened_state_test.item_id,
          period_type: this.hardened_state_test.period_type,
          schedule_travel_uuid: this.$route.params.travel_uuid,
          hardened_state_test_id: this.hardened_state_test.hardened_state_id,
          id: null
        })
        .then(response => {
          this.projections = response.data
        })
    }, 200),
    openModal(formulation, plantId, technicalPermissions = null) {
      this.showTonne = false
      this.technicalPermissions = technicalPermissions;
      this.$store.dispatch('reliability_level/fetchItems')
      this.tab = 1
      this.company_plant_id = plantId
      this.windowWidth = window.innerWidth
      let loader = this.$loading.show()
      this.features = formulation.features
      this.selected = {
        molds: [],
        features: [],
      }
      this.selectedFeature = {
        item: []
      }
      this.hardened_state_test = {}
      this.hardened_state_test.molder = true
      this.hardened_state_test.statistical = true
      this.hardened_state_test.sampling_at = this.report.finished_at
      this.hardened_state_test.report = true
      this.hardened_state_test.anomalies = []
      this.loadingBasePrepation = true
      this.$store.dispatch('basePreparation/fetchItems').then(() => {
        this.loadingBasePrepation = false
      })
      this.loadingMoldingLocation = true
      this.$store.dispatch("moldingLocation/fetchItems").then(() => {
        this.loadingMoldingLocation = false
      })
      this.modal = true;
      this.loadingHardenedState = true
      this.$store.dispatch('testHardenedState/fetchItems')
        .then(response => {
          this.loadingHardenedState = false
          this.hardened_state_test.hardened_state_id = 1
          this.setHardenedState()
          this.callSamplingAt()
          loader.hide()
        })
        .catch(error => {
          this.loadingHardenedState = false
        })
      this.getProjections()
    },
  }
};
</script>

<style>
.tab-btn {
  color: #4e555b !important;
}

.tab-btn:hover {
  color: white !important;
}

.tab-btn:active {
  color: white !important;
}

.active {
  color: white !important;
}
</style>

<style scoped>
.card-with-box-shadow {
  box-shadow: 0 2px 4px 0 #f3f3f3;
  border: 1px solid #E8E8E8;
  box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15);
  border-radius: 10px !important;
}

.border-new-default-gray {
  border: 1px solid #efefef;
  box-shadow: 0px 0px 2px rgba(50, 50, 93, 0.11), 0px 2px 25px rgba(0, 0, 0, 0.08);
  border-left: 0px;
}

.invisible-input {
  border: 0;
  box-shadow: none !important;
}
</style>
