<template>
  <div style="padding: 0.5rem 0rem !important;" class="dropdown-item pointer" @click="teste">
    <slot></slot>
  </div>
</template>
<script>
export default {
  methods: {
    teste() {
      this.$parent.$parent.closePopover()
      this.$emit('click')
    }
  }
}
</script>
