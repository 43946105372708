<template>
  <div>
    <modal size="lg" :show.sync="show">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">{{ title }}</h5>
      </template>
      <div>
        <div class="container">
          <div class="row mb-4">
            <div class="col-md-4 mt-2">
              <label class="col-form-label form-control-label">
                Conta bancária
                <span class="text-danger">&nbsp;*&nbsp;</span>
              </label>
            </div>
            <div class="col-md-8 mt-2">
              <base-input input-classes="form-control-sm">
                <puzl-select
                  v-model="bank_account_id_selected"
                  :items="$_bank_accounts"
                  @input="fetchItemsPaginate"
                />
              </base-input>
            </div>
          </div>

          <div v-if="bank_account_id_selected && bank_billet_remittances.length > 0" class="row pt-0" style="margin-top: -1.4rem">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12 table-responsive" style="overflow-y: scroll; max-height: 500px">
                      <table class="table table-sm table-bordered">
                        <thead>
                          <tr class="fixed">
                            <th class="text-center" style="width: 5px !important">Nome do usuário</th>
                            <th class="text-center">Data de Criação</th>
                            <th class="text-center">Nome do Arquivo</th>
                            <th class="text-center" style="width: 15px">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in bank_billet_remittances" style="">
                            <th style="width: 5px !important" class="text-center">{{ item.created_by_user_name }}</th>
                            <th class="text-center">
                              <span style="font-weight: normal; width: 15px">{{ item.created_at | parseDate("DD/MM/YYYY - HH:mm") }}</span>
                            </th>
                            <th class="text-center">
                              <span style="font-weight: normal; width: 15px">{{ item.filename }}</span>
                            </th>
                            <th class="">
                              <div v-if="item.status === 'unprocessed'" class="text-center">
                                <span style="font-weight: normal; width: 15px">Processando...</span>
                              </div>
                              <div v-else class="text-center">
                                <a class="dropdown-item" @click="downloadRemittanceFile(item)">
                                  <i
                                    style="font-size: 20px !important"
                                    class="fas fa-download"
                                    :class="item.was_downloaded ? 'text-gray' : 'text-success'"
                                  />
                                </a>
                              </div>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <pagination @navegate="navegate" :source="source" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <base-button @click.prevent="close" type="secondary" class="text-capitalize"> Cancelar </base-button>
          <base-button
            @click.prevent="storeRemittance"
            type="success"
            native-type="submit"
            :loading="isLoading"
            :disabled="!this.bank_account_id_selected"
          >
            Gerar novo arquivo de remessa
          </base-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
import Pagination from "@/components/Utils/Pagination";

export default {
  name: "ModalBankBilletRemittance",
  components: {
    PuzlSelect,
    Pagination,
  },
  props: ["show"],
  data() {
    return {
      title: "Arquivos de Remessa",
      isLoading: false,
      bank_billet_remittances: [],
      source: null,
      params: {
        page: 1,
        per_page: 3,
      },
      filter: {
        bank_account_id: null,
      },
      bank_account_id_selected: null,
    };
  },
  computed: {
    ...mapGetters({
      $_bank_accounts: "bankAccount/fetch",
    }),
  },
  mounted() {
    this.$watch("show", function (value) {
      if (value) {
        this.init();
      }
    });
  },
  methods: {
    init() {
      this.isLoading = false;
      this.bank_account_id_selected = null;
      this.$store.dispatch("bankAccount/fetchItems", {filter:{status:true}});
    },
    fetchItemsPaginate() {
      this.filter.bank_account_id = this.bank_account_id_selected;
      this.$store
        .dispatch("bankBilletRemittance/fetchItemsPaginate", {
          filter: this.filter,
          page: this.params.page,
        })
        .then((response) => {
          this.bank_billet_remittances = response.data;
          this.source = response;
        });
    },
    navegate(page) {
      this.params.page = page;
      this.fetchItemsPaginate();
    },
    downloadRemittanceFile(item) {
      this.isLoading = true;
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });
      this.$Progress.start();
      item.was_downloaded = 1;
      const params = { id: item.id, wasDownloaded: item.was_downloaded };
      this.$store
        .dispatch("bankBilletRemittance/updateWasDownloaded", params)
        .then((response) => {
          this.$Progress.finish();
          this.isLoading = false;
          this.$notify({
            type: "success",
            message: "Solicitação realizada com sucesso!.",
          });
          window.open(item.url, "_blank");
        })
        .catch((error) => {
          this.$notify({ type: "danger", message: error.data.message });
          this.$Progress.finish();
          this.isLoading = false;
        });
    },
    storeRemittance() {
      this.isLoading = true;
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });
      this.$Progress.start();
      const params = { bank_account_id: this.bank_account_id_selected };
      this.$store
        .dispatch("bankBilletRemittance/add", params)
        .then((response) => {
          this.$Progress.finish();
          this.isLoading = false;
          this.$notify({
            type: "success",
            message: "Solicitação concluída com sucesso! Este processo será realizado em segundo plano.",
          });
          this.close();
        })
        .catch((error) => {
          const msg = !error.data.data.base ? error.data.message : error.data.data.base.flat().toString().split(",").join("<br />");
          this.$notify({ type: "danger", message: msg });
          this.$Progress.finish();
          this.isLoading = false;
        });
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
