<template>
  <div>
    <PageHeader title="Tipo de Operação Financeira"
      icon="/img/icons/exchange.png"
      type="warning"
      :isBackButtonEnabled="true">
        <PageHeaderButton @click="handleCreateFinanceType"
          text="novo"
          type="success"
          icon="/img/icons/plus-math--v1-white.png" />
    </PageHeader>
    <div class="container-fluid">
      <div class="mt-4 ml-2">
        <div style="display: flex; align-items: center">
          <i class="pointer fa-regular fa-hard-drive fa-2x"></i>
          <i class="opacity-25 pointer fa-solid fa-table-list fa-2x ml-1"></i>
        </div>
      </div>
      <div>
        <MultiFilter
          @clearFilter="clearFilter()"
          @fetch="init"
          :filter="filter"
        	:type="3"
          :showSlotDates="false"
          :showSlotData="false">
          <template slot="bookmarks">
            <div class="col-12 mt-1">
              <BaseButtonHoverable :active="filter.mode"
                @click="handleFilterMode(1)"
                size="sm" type="success"  icon="check-dollar" platform="ios">
                Pagamento
              </BaseButtonHoverable>
            </div>
            <div class="col-12 mt-1 mb-1">
              <BaseButtonHoverable :active="filter.mode == false"
                @click="handleFilterMode(0)"
                size="sm" type="danger"  icon="get-cash" platform="ios">
                Recebimento
              </BaseButtonHoverable>
            </div>
          </template>
          <template slot="status">
            <div class="col-12 mt-1">
              <BaseButtonHoverable :active="filter.is_active"
                @click="handleFilterActive(1)"
                size="sm" type="success"  icon="thumb-up" platform="ios">
                Ativo
              </BaseButtonHoverable>
            </div>
            <div class="col-12 mt-1 mb-1">
              <BaseButtonHoverable :active="filter.is_active == false"
                @click="handleFilterActive(0)"
                size="sm" type="danger"  icon="thumbs-down" platform="ios">
                Inativo
              </BaseButtonHoverable>
            </div>
          </template>
          <template slot="order-by">
            <el-popover trigger="click" placement="bottom" class="p-0 float-right pr-0 ml-3">
              <div class="card" style="margin: -14px; border-radius: 4px !important;">
                <div class="pointer px-3 pb-2 pt-3 new-default-black-font font-weight-500"
                  style="font-size: 11px;">
                  <i class="fa-solid fa-angles-down" style="font-size: 12px;margin-right: 5px"></i>
                  Do mais novo para o mais velho
                </div>
                <hr class="gray-divider p-0">
                <div class="pointer px-3 py-2 new-default-black-font font-weight-500"
                  style="font-size: 11px;">
                  <i class="fa-solid fa-angles-up" style="font-size: 12px;margin-right: 5px"></i>
                  Do mais velho para o mais novo
                </div>
              </div>
              <a href="#" slot="reference">
                <h5 slot="reference" class="pt-1 font-weight-normal new-default-gray-font">ORDENAR <i class="fa-solid fa-chevron-down"></i></h5>
              </a>
            </el-popover>
          </template>
        </MultiFilter>
      </div>
      <Cards :financeTypes="$_financeTypes"
        :handleEditFinanceType="handleEditFinanceType"
        :handleDeleteFinanceType="handleDeleteFinanceType"
        :loadingSkeleton="loadingSkeleton"
      />
      <ModalCreateFinanceType ref="modalCreateFinanceType" />
      <ModalEditFinanceType ref="modalEditFinanceType" />
    </div>
  </div>
</template>
<script>
import { PageHeader, PageHeaderButton } from "@/components";
import MultiFilter from "@/components/Utils/MultiFilterV3";
import Cards from "./Shared/_Cards";
import ModalCreateFinanceType from "./Shared/_ModalCreateFinanceType";
import ModalEditFinanceType from "./Shared/_ModalEditFinanceType";
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import cursorPaginate from "@/mixins/cursorPaginate";
import {mapGetters} from "vuex";

export default {
  name: "FinanceType",
  mixins: [cursorPaginate],
  components: {
    PageHeader,
    PageHeaderButton,
    MultiFilter,
    Cards,
    ModalCreateFinanceType,
    ModalEditFinanceType,
    BaseButtonHoverable
  },
  data(){
    return {
      loadingSkeleton: true,
      financeTypes: [],
      filter: {
        is_active: null,
        mode: null
      },
    }
  },
  computed: {
    ...mapGetters({
      $_financeTypes: "financeTypes/fetch",
    })
  },
  methods: {
    handleFilterMode(mode){
      if(this.filter.mode === mode) this.filter.mode = null;
      else this.filter.mode = mode;
      this.init(this.filter);
    },
    handleFilterActive(is_active){
      if(this.filter.is_active === is_active) this.filter.is_active = null;
      else this.filter.is_active = is_active;
      this.init(this.filter);
    },
    handleCreateFinanceType(){
      this.$refs.modalCreateFinanceType.openModal();
    },
    clearFilter() {
      this.loadingSkeleton = true;
      this.filter = {};
      this.init();
    },
    /**
     * Recebe um tipo de operação financeira e abre o modal de edição do mesmo
     *
     * @param {object} financeType - Uma entidade do tipo FinanceType (Tipo de operação financeira).
    */
    handleEditFinanceType(financeType){
      this.$refs.modalEditFinanceType.openModal(financeType);
    },
    handleDeleteFinanceType(id){
      this.$Swal
        .confirmDelete()
        .then((result) => {
          if (!result.isConfirmed){
            return
          };

          this.$Progress.start();
          this.$store.dispatch("financeTypes/destroy", id)
          .then((response) => {
            this.$notify({ type: "success", message: response.message });
          }).catch(error => {
            if(error.response){
              const errors = error && error.response && error.status === 422
                  ? formatErrorValidation(error.response.data.data)
                  : error.response.data.message;
              this.$notify({ type: "danger", message: errors });
            }
          }).finally(() => {
            this.$Progress.finish();
          });
        });
    },
    init(filter = null){
      this.startCursor(filter);

      const params = {
        custom_search: this.$helper.customSearchFormatter(["finance_types.name"], filter?.global),
        mode: this.filter.mode,
        page: this.paginate.nextUrl,
        is_active: this.filter.is_active,
        order_by: [
          {
            column: "finance_types.id"
          },
        ],
      };

      this.$store
        .dispatch("financeTypes/fetchItems", params)
        .then(response => {
          this.loadingSkeleton = false;
          this.resolveCursor(response);
        })
        .catch(error => {
          this.resolveCursor();
          this.loadingSkeleton = false;
        });
    },
  },
  mounted(){
    this.init();
  },
};
</script>
<style scoped>
.gray-divider {
  background-color: #2b2d32;
  opacity: 0.1;
  color: #2b2d32;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
