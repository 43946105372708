<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-gray d-inline-block mb-0">
            {{ this.$route.meta.breadcrumbs[1].title }}
          </h6>
          <nav
            aria-label="breadcrumb"
            class="d-none d-md-inline-block ml-md-4 text-gray"
          >
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#"><i :class="this.$route.meta.icon"></i></a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">{{ this.$route.meta.breadcrumbs[0].title }}</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {{ this.$route.meta.breadcrumbs[1].title }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- Header Menus -->
      <div class="row mb-3">
        <div class="col-md-2 mb-2">
          <router-link :to="{
            path:'/equipment/equipment/implements',
            name:'equipment.implement.index',
            params: { equipment_uuid: equipment.uuid}}">
            <base-button block size="lg" type="light" class="pb-3 pt-3">
              voltar
            </base-button>
          </router-link>
        </div>
      </div>
    </base-header>

    <div class="container-fluid">
      <validation-observer
        v-slot="{ invalid, handleSubmit }"
        ref="formValidator"
      >
        <form
          class="needs-validation"
          @submit.prevent="handleSubmit(store)"
          autocomplete="off"
        >
        <card>
          <!-- Card header -->
          <div class="row align-items-center mb-3 card-body-molde">
            <div class="col-12">
              <!-- Title -->
              <div class="border-header-default-bottom p-1">
                <h5 class="h3 m-0">Identificação</h5>
              </div>
            </div>
          </div>
          <!-- Card body -->
          <div>
            <div class="form-group row m-0 p-0">
              <label class="col-md-4 col-form-label form-control-label">
                Status
              </label>
              <div class="col-md-8 pt-2 mb-2">
                <div class="row">
                  <div class="col-md-3 pt-2 pr-2 pl-2">
                    <base-button outline block size="sm" type="success"
                                 :class="vehicle.status===1 ? 'active' : ''"
                                 @click="handleChangeStatus(1)"
                    >
                      Ativo
                    </base-button>
                  </div>
                  <div class="col-md-3 pt-2 pr-2 pl-2">
                    <base-button outline block size="sm" type="danger"
                                 :class="vehicle.status===2 ? 'active' : ''"
                                 @click="handleChangeStatus(2)"
                    >
                      Inativo
                    </base-button>
                  </div>
                  <div class="col-md-3 pt-2 pr-2 pl-2">
                    <base-button outline block size="sm" type="light"
                                 :class="vehicle.status===3 ? 'active' : ''"
                                 @click="handleChangeStatus(3)"
                    >
                      Indisponível
                    </base-button>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-4 pt-1 pb-0 mb-1 col-form-label form-control-label">
                Situação
                <span class="text-danger">&nbsp;*&nbsp;</span>
                <span class="btn btn-sm btn-success p-1" @click.prevent="handleCreateSituation()">Novo</span>
              </label>
              <div class="col-md-8 pl-2">
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm">
                    <el-select v-model="vehicle.equipment_situation_id"
                               placeholder="Selecione"
                               size="mini"
                               filterable
                               :loading="loadingSituation"
                               :disabled="loadingSituation"
                    >
                      <el-option v-for="situation in situations"
                                 :key="situation.id"
                                 :label="situation.situation_name"
                                 :value="situation.id">
                      </el-option>
                    </el-select>
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-4 pt-1 pb-0 mb-1 col-form-label form-control-label">
                Categoria
                <span class="text-danger">&nbsp;*&nbsp;</span>
                <span class="btn btn-sm btn-success p-1" @click.prevent="handleCreateCategory()">Novo</span>
              </label>
              <div class="col-md-8 pl-2">
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm">
                    <el-select v-model="vehicle.equipment_category_id"
                               placeholder="Selecione"
                               size="mini"
                               filterable
                               :loading="loadingCategory"
                               :disabled="true"
                    >
                      <el-option v-for="category in categories"
                                 :key="category.id"
                                 :label="category.category_name"
                                 :value="category.id">
                      </el-option>
                    </el-select>
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-4 pt-1 pb-0 mb-1 col-form-label form-control-label">
                Tipo de Equipamento
                <span class="text-danger">&nbsp;*&nbsp;</span>
                <span class="btn btn-sm btn-success p-1" @click="handleCreateType()">Novo</span>
              </label>
              <div class="col-md-8 pl-2">
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm">
                    <el-select v-model="vehicle.equipment_type_id"
                               placeholder="Selecione"
                               size="mini"
                               filterable
                               :loading="loadingType"
                               :disabled="loadingType"
                    >
                      <el-option v-for="type in types"
                                 :key="type.id"
                                 :label="type.description"
                                 :value="type.id">
                      </el-option>
                    </el-select>
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-4 pt-1 pb-0 mb-1 col-form-label form-control-label">
                Código do equipamento
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-8 pl-2">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                    <input type="text" v-model="vehicle.code"
                           class="form-control form-control-sm" input-group-classes="input-group-sm">
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-4 pt-1 pb-0 mb-1 col-form-label form-control-label">
                Placa
              </label>
              <div class="col-md-8 pl-2">
                <base-input input-group-classes="input-group-sm">
                  <input type="text" v-model="vehicle.plate"
                         class="form-control form-control-sm" input-group-classes="input-group-sm">
                </base-input>
              </div>
            </div>

            <div class="row align-items-center mb-3 card-body-molde">
              <div class="col-12">
                <!-- Title -->
                <div class="border-header-default-bottom p-1">
                  <h5 class="h3 m-0">Capacidade / Produtividade</h5>
                </div>
              </div>
            </div>

            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-4 pt-1 pb-0 mb-1 col-form-label form-control-label">
                Unidade de Medida
              </label>
              <div class="col-md-8 pl-2">
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm">
                    <el-select v-model="vehicle.measured_unit_id"
                               placeholder="Selecione"
                               size="mini"
                               filterable
                               :loading="loadingMeasuredunit"
                               :disabled="loadingMeasuredunit"
                    >
                      <el-option v-for="measuredunit in measuredunits"
                                 :key="measuredunit.id"
                                 :label="measuredunit.letter"
                                 :value="measuredunit.id">
                      </el-option>
                    </el-select>
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0 mb-1 ">
              <label class="col-md-4 pt-1 pb-0 mb-1 col-form-label form-control-label">
                Valor
              </label>
              <div class="col-md-8 pl-2">
                <base-input input-group-classes="input-group-sm">
                  <input inputmode="numeric" v-model="vehicle.price"
                         class="form-control form-control-sm" input-group-classes="input-group-sm">
                </base-input>
              </div>
            </div>

            <div class="row align-items-center mb-3 card-body-molde">
              <div class="col-12">
                <!-- Title -->
                <div class="border-header-default-bottom p-1">
                  <h5 class="h3 m-0">Detalhes</h5>
                </div>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                Tag de identificação
              </label>
              <div class="col-md-8 pl-2">
                <base-input input-group-classes="input-group-sm">
                  <input type="text" v-model="vehicle.vehicle_tag"
                         class="form-control form-control-sm" input-group-classes="input-group-sm">
                </base-input>
              </div>
            </div>

            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-4 pt-1 pb-0 mb-1 col-form-label form-control-label">
                Marca
              </label>
              <div class="col-md-8 pl-2">
                <base-input input-group-classes="input-group-sm">
                  <input type="text" v-model="vehicle.brand"
                         class="form-control form-control-sm" input-group-classes="input-group-sm">
                </base-input>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                Modelo
              </label>
              <div class="col-md-8 pl-2">
                <base-input input-group-classes="input-group-sm">
                  <input type="text" v-model="vehicle.model"
                         class="form-control form-control-sm" input-group-classes="input-group-sm">
                </base-input>
              </div>
            </div>

            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                Chassi
              </label>
              <div class="col-md-8 pl-2">
                <base-input input-group-classes="input-group-sm">
                  <input type="text" v-model="vehicle.chassis"
                         class="form-control form-control-sm" input-group-classes="input-group-sm">
                </base-input>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                Renavan
              </label>
              <div class="col-md-8 pl-2">
                <base-input input-group-classes="input-group-sm">
                  <input type="text" v-model="vehicle.vehicle_permit_code"
                         class="form-control form-control-sm" input-group-classes="input-group-sm">
                </base-input>
              </div>
            </div>

            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                Ano do Equipamento
              </label>
              <div class="col-md-8 pl-2">
                <base-input input-group-classes="input-group-sm">
                  <input inputmode="numeric" v-model="vehicle.equipment_year"
                         class="form-control form-control-sm" input-group-classes="input-group-sm">
                </base-input>
              </div>
            </div>

            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                Ano de Fabricação do Equipamento
              </label>
              <div class="col-md-8 pl-2">
                <base-input input-group-classes="input-group-sm">
                  <input inputmode="numeric" v-model="vehicle.equipment_manufacture_year"
                         class="form-control form-control-sm" input-group-classes="input-group-sm">
                </base-input>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                Cor predominante do Equipamento
              </label>
              <div class="col-md-8 pl-2">
                <base-input input-group-classes="input-group-sm">
                  <input type="text" v-model="vehicle.predominant_color"
                         class="form-control form-control-sm" input-group-classes="input-group-sm">
                </base-input>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                Combustivel do Equipamento
              </label>
              <div class="col-md-8 pl-2">
                <base-input input-group-classes="input-group-sm">
                  <input type="text" v-model="vehicle.equipment_fuel"
                         class="form-control form-control-sm" input-group-classes="input-group-sm">
                </base-input>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                Nome - CRLV do veículo
              </label>
              <div class="col-md-8 pl-2">
                <base-input input-group-classes="input-group-sm">
                  <input type="text" v-model="vehicle.vehicle_license_name"
                         class="form-control form-control-sm" input-group-classes="input-group-sm">
                </base-input>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                CNPJ - CRLV do veículo
              </label>
              <div class="col-md-8 pl-2">
                <base-input input-group-classes="input-group-sm">
                  <input inputmode="numeric" v-model="vehicle.vehicle_license_legal_entity"
                         class="form-control form-control-sm" input-group-classes="input-group-sm">
                </base-input>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <base-button
              type="success"
              native-type="submit"
              v-bind:disabled="invalid"
              :loading="loadingSave"
            >
              Salvar
            </base-button>
          </div>
        </card>
        </form>
      </validation-observer>
    </div>
    <ModalCreateEquipmentSituation ref="createEquipmentSituation"/>
    <ModalCreateEquipmentCategory ref="createEquipmentCategory"/>
    <ModalCreateEquipmentType ref="createEquipmentType"/>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
const {formatErrorValidation} = require("@/plugins")
import ModalCreateEquipmentSituation from '../Situation/Shared/_ModalCreate'
import ModalCreateEquipmentCategory from '../Category/Shared/_ModalCreate'
import ModalCreateEquipmentType from '../Type/Shared/_ModalCreate'
export default {
  name: "CreateUser",
  components: {
    ModalCreateEquipmentSituation,
    ModalCreateEquipmentCategory,
    ModalCreateEquipmentType
  },
  data() {
    return {
      invalid: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      vehicle: {
        status: 1,
        equipment_situation_id: null,
        equipment_category_id:  null,
        equipment_type_id: null,
        code: null,
        plate: null,
        measured_unit_id: null,
        price: null,
        vehicle_tag: null,
        brand: null,
        model: null,
        chassis: null,
        vehicle_permit_code: null,
        equipment_year: null,
        equipment_manufacture_year: null,
        predominant_color: null,
        equipment_fuel: null,
        vehicle_license_name: null,
        vehicle_license_legal_entity: null,
      },
      loadingSave: false,
      loadingSituation: true,
      loadingCategory: true,
      loadingType: true,
      loadingMeasuredunit: true,
    };
  },
  computed: {
    ...mapGetters({
      situations: "equipmentSituation/fetch",
      categories: "equipmentCategory/fetch",
      types: "equipmentType/fetch",
      measuredunits: "measuredunit/fetch",
      equipment: "equipment/show",
    })
  },
  watch: {
    'vehicle.equipment_category_id': function () {
      this.fetchTypes(this.vehicle.equipment_category_id)
    },
  },
  methods: {
    fetch() {
      this.$Progress.start()
      this.loadingSkeleton = true
      this.$store
        .dispatch("equipment/show", this.$route.params.equipment_uuid)
        .then(response => {
          this.$Progress.finish()
          this.loadingSkeleton = false
          this.vehicle= {
            status: 1,
            equipment_uuid: response.data.uuid,
            equipment_situation_id: null,
            equipment_category_id: response.data.category.id,
            equipment_type_id: null,
            code: null,
            plate: null,
            measured_unit_id: null,
            price: null,
            vehicle_tag: null,
            brand: null,
            model: null,
            chassis: null,
            vehicle_permit_code: null,
            equipment_year: null,
            equipment_manufacture_year: null,
            predominant_color: null,
            equipment_fuel: null,
            vehicle_license_name: null,
            vehicle_license_legal_entity: null,
          }
        })
        .catch(error => {
          this.$Progress.finish()
          this.loadingSkeleton = false
        });
    },
    fetchTypes(idCategory){
      this.loadingType = true
      this.vehicle.equipment_type_id =null
      this.$store.dispatch("equipmentType/fetchItemsByCategoryId", idCategory).then(() => {
        this.loadingType = false
      })
    },
    handleChangeStatus(status){
      this.vehicle.status = status
    },
    handleCreateSituation(){
      this.$refs.createEquipmentSituation.handleCreateModal()
    },
    handleCreateCategory(){
      this.$refs.createEquipmentCategory.handleCreateModal()
    },
    handleCreateType(){
      this.$refs.createEquipmentType.handleCreateModal()
    },
    store() {
      this.$Progress.start()
      this.loadingStore = true
      this.$store
        .dispatch("implement/add", this.vehicle).then(response => {
          this.loadingStore = false
          this.$Progress.finish()
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.$router.push({
            path:'/equipment/equipment/implements',
            name:'equipment.implement.index',
            params: { equipment_uuid: this.vehicle.equipment_uuid}
          })
        })
        .catch(error => {
          if (error.status) {
            this.$Progress.finish()
            this.loadingStore = false
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            })
          } else {
            if (error.response.status === 422) {
              let message = formatErrorValidation(error.response.data.errors)
              this.$notify({
                type: 'danger',
                message: message
              })
              this.$Progress.finish()
              this.loadingStore = false
            } else {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              })
              this.$Progress.finish()
              this.loadingStore = false
            }
          }
        });
    },
  },
  mounted() {
    this.fetch()
    this.$store.dispatch("equipmentSituation/fetchItems").then(() => {
      this.loadingSituation = false
    })
    this.$store.dispatch("equipmentCategory/fetchItems").then(() => {
      this.loadingCategory = false
    })
    this.$store.dispatch("measuredunit/fetchItems").then(() => {
      this.loadingMeasuredunit = false
    })
  }
}
</script>
