<template>
  <div class="row mb-3">
    <div class="col-md-5">
      <h4 class="new-default-black-font mb-0">
        Fornecedor :
        <span>{{ entity_name }} </span>
      </h4>
      <h4 class="new-default-black-font mb-0">
        Cnpj/Cpf :
        <span>{{ entity_document }} </span>
      </h4>
      <h4 class="new-default-black-font mb-0">
        Documento :
        <span>{{ document_number }} </span>
        <span v-if="series">/ {{ series }} </span>
      </h4>
    </div>
    <div class="col-md-7 mt-md-0 mt-3">
      <div class="row m-0">
        <div class="col-md-3 p-0">
          <base-button
            @click.prevent="handleTabValue('document')"
            class="custom-navegation-btn rounded-pill-left text-uppercase"
            type="primary"
            outline
            size="sm"
            block
            :class="{ active: tab_value === 'document' }"
          >
            <img
              height="20px"
              :src="`/img/icons/icons8/ios/info-squared${tab_value !== 'document' ? '_warning.png' : '_white.png'}`"
              class="mr-2"
            />
            Documento
          </base-button>
        </div>
        <div class="col-md-3 p-0">
          <base-button
            @click.prevent="handleTabValue('items')"
            class="custom-navegation-btn rounded-0 text-uppercase"
            type="primary"
            outline
            size="sm"
            block
            :class="{ active: tab_value === 'items' }"
          >
            <img height="20px" :src="`/img/icons/icons8/ios/big-parcel${tab_value !== 'items' ? '_primary.png' : '.png'}`" class="mr-2" />
            Itens
          </base-button>
        </div>
        <div class="col-md-3 p-0">
          <base-button
            @click.prevent="handleTabValue('transport')"
            class="custom-navegation-btn rounded-0 text-uppercase"
            type="primary"
            outline
            size="sm"
            block
            :class="{ active: tab_value === 'transport' }"
          >
            <img height="20px" :src="`/img/icons/icons8/ios/in-transit${tab_value !== 'transport' ? '_light.png' : '.png'}`" class="mr-2" />
            Transporte
          </base-button>
        </div>
        <div class="col-md-3 p-0">
          <base-button
            @click.prevent="handleTabValue('payment')"
            class="custom-navegation-btn rounded-pill-right text-uppercase"
            type="primary"
            outline
            size="sm"
            block
            :disabled="disabledPayment"
            :class="{ active: tab_value === 'payment' }"
          >
            <img height="20px" :src="`/img/icons/receipt-terminal${tab_value !== 'payment' ? '.png' : '-white.png'}`" class="mr-2" />
            Pagamento
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navegation",
  props: {
    entity_name: {
      type: String,
    },
    entity_document: {
      type: String,
    },
    document_number: {
      type: [String, Number],
    },
    series: {
      type: [String, Number],
    },
    tab_value: {
      type: String,
    },
    disabledPayment: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleTabValue(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style scoped>
.custom-navegation-btn {
  border-color: rgba(0, 0, 0, 0.05);
  padding: 0.45rem 0.5rem;
  line-height: normal !important;
  align-items: center !important;
  -webkit-box-align: center !important;
  display: flex !important;
  color: #2b2d32;
}
.custom-navegation-btn:disabled {
  color: #2b2d32;
}
.custom-navegation-btn:not(.active):hover {
  background-color: transparent !important;
  color: #2b2d32 !important;
  border-color: transparent !important;
}
.rounded-pill-left {
  border-top-left-radius: 50rem !important;
  border-bottom-left-radius: 50rem !important;
}
.rounded-pill-right {
  border-top-right-radius: 50rem !important;
  border-bottom-right-radius: 50rem !important;
}
</style>
