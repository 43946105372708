<template>
  <div>
    <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
      <form
        @submit.prevent="handleSubmit(onSubmitClick)"
        class="needs-validation"
        autocomplete="off"
      >
        <div class="container-fluid">
          <div class="row">
            <div class="col-12" style="display: flex; align-items: center;">
              <img src="/img/icons/icons8/ios/info-squared.png" width="20">
              <h4 class="m-0 mx-3" style="font-size: 16px; font-weight: 500; color: #2B2D32;">
                  Identificação
              </h4>
              <hr class="ml-3" style="width: 100%;">
            </div>
          </div>
          <div class="form-group">
            <div class="form-group row m-0 p-0 mb-2">
              <div class="col-md-5 my-1 px-0">
                <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                    CENTRAL
                </div>
              </div>
              <div class="col-md-7 mb-2 px-0">
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm">
                    <PuzlSelect
                      v-model="formData.company_plant_id"
                      :items="companyPlants"
                      :disableBoxShadow="true"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0 mb-2">
              <div class="col-md-5 my-1 px-0">
                <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                    TIPO
                </div>
              </div>
              <div class="col-md-7 mb-2 px-0">
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm">
                    <PuzlSelect
                      v-model="formData.mix_type_id"
                      :items="mixTypes"
                      label="technical_description"
                      :disableBoxShadow="true"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>
          </div>
          <div class="modal-footer px-0">
            <div class="d-flex pr-2" style="gap: 18px;">
              <div class="d-flex align-items-center button-cancel" style="gap: 5px;" @click="onCancelClick">
                <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="16px">
                <span style="font-weight: 500;">
                  Cancelar
                </span>
              </div>
              <div class="d-flex align-items-center button-save" style="gap: 5px;" @click.prevent="onSubmitClick">
                <img src="/img/icons/icons8/ios/save_success.png" alt="save_success" width="16px">
                <span style="font-weight: 500;">
                  Salvar
                </span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, computed } from "vue";
import { AdjustmentTypeCreateType, AdjustmentTypeUpdateType } from "../../types";
import { ValidationObserver } from "vee-validate";
import { useAdjustmentTypeHook } from "../../hooks/useAdjustmentTypeHook";
import PuzlSelect from "@/components/PuzlSelect";
import adjustmentTypeStore from "../../store";

const {
  companyPlants,
  mixTypes
} = useAdjustmentTypeHook();

/**
 * Propriedades para o componente
 * @typedef {Object} Props
 * @property {AdjustmentTypeCreateType|AdjustmentTypeUpdateType} formData
 */
/** @type {Props} */
const props = defineProps({
  formData: {
    type: Object,
    required: true,
  },
  isEdit: {
    type: Boolean,
    required: false,
    default: false
  }
});

/** Eventos emitidos */
const emit = defineEmits([
  "onCancelClick", 
  "onSubmitClick"
]);

/** Store Getters */
const isSaving = computed(() => adjustmentTypeStore.getIsSaving());

function onCancelClick() {
  emit("onCancelClick");
}

function onSubmitClick() {
  emit("onSubmitClick");
}
</script>

<style scoped>
.text-title {
  font-size: 18px;
  font-weight: 500;
  color: #2B2D32;
}
.status-button-success {
  background-color: #F2F7F3;
  border-radius: 16px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #149E57;
  font-weight: 400;
  padding: 4px 40px;
}
.status-button-danger {
  background-color: #F4C7C3;
  border-radius: 16px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #DB4539;
  font-weight: 400;
  padding: 4px 34px
}
.button-cancel {
  cursor: pointer;
  border: 0.5px solid #DB4539;
  border-radius: 8px;
  color: #DB4539;
  padding: 4px 20px;
}
.button-save {
  cursor: pointer;
  border: 0.5px solid #149E57;
  border-radius: 8px;
  color: #149E57;
  padding: 4px 30px;
}
.button-save:hover {
  background: #149E57;
  color: white;
}
.button-save:hover img {
  filter: brightness(0) invert(1);
}
.button-cancel:hover {
  background: #DB4539;
  color: white;
}
.button-cancel:hover img {
  filter: brightness(0) invert(1);
}
</style>