<template>
  <div class="page-container">
    <div class="header">
      <div class="p-3">
        <img src="/img/icons/icons8/ios/mail.png" width="19" />
        <span>puzl@puzl.place</span>
      </div>
      <div class="p-3 mr-3">
        <img src="/img/icons/icons8/ios/phone-primary.png" width="19" />
        <span>(11) 3042-2930</span>
      </div>
    </div>
    <div class="content">
      <div class="content-header">
        <div class="p-4 ml-2">
          <img src="/img/brand/logo-puzl-horizontal-with-text.png" height="60">
        </div>
        <div class="d-flex website-linkedin-puzl">
          <div @click="redirect('https://puzl.place/')" class="mr-5">
            <img class="mr-2" src="/img/icons/icons8/ios/website.png" height="30">
            <span>www.puzl.place</span>
          </div>
          <div @click="redirect('https://www.linkedin.com/company/puzlplace/mycompany/')" class="mr-5">
            <img class="mr-2" src="/img/icons/icons8/ios/linkedIn.png" height="30">
            <span>PUZL Place</span>
          </div>
        </div>
      </div>
      <div class="content-body">
        <div class="mt-5 login-content p-5">
          <div class="cotation-text">
            Cotação Online
          </div>
          <div class="transform-text">
            Destaque-se da concorrência: ofereça seus melhores preços e condições.
          </div>
          <div class="mt-5">
            <div class="enter-text">
              Entre com o seu CNPJ
            </div>
            <div class="d-flex mt-1">
              <input v-model="form.entity_document" v-mask="['###.###.###-##', '##.###.###/####-##']" type="text"
                class="document-input" placeholder="99.999.999/0001-99" />
              <button class="btn btn-primary btn-sm ml-2 px-2" @click="login">Entrar</button>
            </div>
          </div>
        </div>
        <div class="p-5">
          <img src="/img/brand/puzl-work.png" height="460">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        entity_code: this.$route.params.code,
        entity_document: '',
      },
      year: new Date().getFullYear(),
      loading: false,
    };
  },
  methods: {
    login() {
      if (!this.form.entity_document) {
        this.$snotify.warning('Preencha o CNPJ!', {
          timeout: 2000,
          icon: false,
          position: "rightTop",
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false
        });
        return
      }
      let loader = this.$loading.show();
      this.$store.dispatch('purchaseBudget/login', this.form)
        .then(response => {
          loader.hide();
          this.$router.push('/entity-budget/' + response.data)
        }).catch((e) => {
            loader.hide();
            this.$snotify.warning('Documento inválido!', {
            timeout: 2000,
            icon: false,
            position: "rightTop",
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false
          });
        });
    },
    redirect(url) {
      window.open(url, '_blank');
    }
  }
};
</script>

<style scoped>
.header {
  background-color: #1A70B7 !important;
  color: #ffff;
  display: flex;
  justify-content: end;
}

.header-table-items {
  font-size: 18px;
  color: #2B2D32;
  font-weight: 500;
  width: 9rem;
  display: inline-flex;
}

.header-table-items:first-of-type {
  margin-left: 13.85rem;
}

.header-cards {
  display: grid;
  box-shadow: 0px 5px 10px 0px #0000000D;
  border: 1px solid #E8E8E8;
  padding: 12px 32px 12px 12px;
  border-radius: 8px;
}

.page-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.header>>>span {
  font-size: 12px;
}

.header>>>img {
  margin-right: 1rem;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.content .content-header {
  display: flex;
  justify-content: space-between;
  background-color: #ffff;
}

.content .content-header .website-linkedin-puzl {
  margin-top: 2.6rem;
  font-weight: 500;
}

.content .content-body {
  display: flex;
  justify-content: space-around;
}

.content .content-body .document-input {
  box-shadow: 0px 5px 10px 0px #0000001A;
  border: 0.5px solid #E8E8E8;
  width: -webkit-fill-available;
  height: Hug (43px)px;
  padding: 12px;
  border-radius: 8px;
  outline: none;
}

.content .content-body .btn {
  padding: 0.5rem 1.2rem !important;
  font-size: 16px;
  font-weight: 500 !important;
}

.content .login-content .cotation-text {
  font-family: Fredoka;
  font-size: 48px;
  font-weight: 500;
  line-height: 58.08px;
  color: #1A70B7;
}

.content .login-content .transform-text {
  font-family: Fredoka;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #000000;
  margin-top: 1rem;
}

.content .login-content .enter-text {
  font-weight: 500;
  color:#000000;
}
</style>
