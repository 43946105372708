<template>
<div>
  <modal :show.sync="modal">
    <template slot="header">
      <h5 class="modal-title">{{ title }}</h5>
    </template>
    <div>
      <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
        <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
          <div>

            <div class="form-group row m-0 p-0 m-1">
              <label class="col-md-5 col-form-label form-control-label">
                Status
              </label>
              <div class="col-md-7 pt-2">
                <base-switch v-model="supplier_origin.status" type="success" offText="inativo" onText="ativo" class="success"></base-switch>
              </div>
            </div>

            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pb-0 col-form-label form-control-label">
                Nome da Procedência
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-group-classes="input-group-sm">
                    <input 
                      type="text" v-model="supplier_origin.origin_name" class="form-control form-control-sm"
                      :class="errors[0] ? 'is-invalid' : 'is-valid'" />
                  </base-input>
                </validation-provider>
              </div>
            </div>

          </div>

          <div class="modal-footer">
            <base-button type="secondary" @click="openModal(false)">
              Cancelar
            </base-button>
            <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingSave">
              Salvar
            </base-button>
          </div>
        </form>
      </validation-observer>
    </div>
  </modal>
</div>
</template>

<script>
const {formatErrorValidation} = require("@/plugins")

export default {
  name: "CreateSupplierOrigin",
  props: {
    changeSupplierOrigin: { type: Function }
  },
  data () {
    return {
      title:'Procedência MCC',
      modal: false,
      supplier_origin: {
        status: true,
        origin_name: '',
        cmc_supplier_id: '',
      },
      loadingSave: false,
    }
  },
  computed: {
  },
  methods: {
    openModal (open, supplierId) {
      this.supplier_origin = {
        status: true,
        origin_name: '',
        cmc_supplier_id: supplierId,
      }
      this.modal = open
    },
    save () {
      this.$Progress.start()
      this.loadingSave = true
      this.$store.dispatch('cmcSupplierOrigin/add', this.supplier_origin)
        .then(response => {
          this.loadingSave = false
          this.modal = false
          this.$Progress.finish()
          this.$notify({ type: response.error_type, message: response.message })
          this.changeSupplierOrigin(response.data.id);
        })
        .catch((error) => {
          if (error.status === 422) {
            let message = formatErrorValidation(error.response.data.errors)
            this.$notify({
              type: 'danger',
              message: message
            })
          } else {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            })
          }
          this.$Progress.finish()
          this.loadingSave = false
        })
    },
  },
  mounted () {
    this.$refs.formValidator.validate();
  },
  created () {
  }
}
</script>

<style scoped>

</style>
