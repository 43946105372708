<template>
<div>
    <base-header class="bg-gray-content">
        <div class="row align-items-center py-4">
            <div class="col-lg-12 col-12">
                 <PuzlBreadcrumb/>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-2 mb-2">
                <base-button 
                    @click.prevent="handleCreateAddress()" 
                    class="text-uppercase" 
                    block size="lg" 
                    type="success"
                >
                    <i class="fas fa-plus"></i> novo
                </base-button>
            </div>
            <div class="col-md-2 mb-2">
                <back-button/>
            </div>
        </div>
    </base-header>
    <ModalCreateConstructionAddress @createAddress="updateAddressDefault" ref="modalCreateConstructionAddress" />
    <ListAddress ref="listConstructionAddress" />
</div>
</template>

<script>
import ListAddress from "./Shared/_List";
import ModalCreateConstructionAddress from "./Shared/_ModalCreate";
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import BackButton from "@/components/Utils/BackButton";
export default {
    name: "Index",
    components: {
        ListAddress,
        ModalCreateConstructionAddress,
        PuzlBreadcrumb,
        BackButton,
    },
    methods: {
        handleCreateAddress(){
            this.$refs.modalCreateConstructionAddress.handleCreateModal(this.$route.params.construction_uuid);
        },
        updateAddressDefault(address) {
            if (address.data.default) {
                this.$refs.listConstructionAddress.updateDefaultAddress(address);
            }
        }
    }
}
</script>

<style></style>
