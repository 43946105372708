<template>
  <div>
    <div class="container-fluid">
      <div class="row pt-n1">
        <div class="col-md-12 p-1 mt-3">
          <!-- Filtros -->
          <MultiFilter
            ref="multi-filter"
            @handleFilterDate="handleFilterDate"
            @fetch="init"
            :filter="filter"
            :default-range="filter.range"
            :showSlotData="false"
            :showSlotDates="false"
            :type="1"
          >
            <template slot="status">
              <div class="col-12 text-left">
                <label class="form-control-label">Status </label>
                <PuzlSelect
                  class="select-xl new-default-black-font"
                  v-model="filter.status"
                  :items="statuses"
                  :loading="loadingSkeleton"
                  @input="init({})"
                />
              </div>
            </template>
          </MultiFilter>

          <div v-if="$_items.length" class="card-body mt-n2 p-0">
            <div id="approvalTechnicalTable" class="row">
              <ShadedTable :customHeaders="[0]" :length="$_items.length" :headers="headers" :loading="loadingSkeleton">
                <template slot="header_0">
                  <img
                    @click="active_select_all ? deselectAll() : selectAll()"
                    width="18"
                    src="/img/icons/double-box-tick-primary.png"
                    class="pointer"
                  />
                </template>
                <template v-for="(item, index) in $_items" :slot="index">
                  <ShadedCol align="center">
                    <h5 v-if="!item.needs_approval_technical" class="new-default-black-font font-weight-normal m-0 mt-1 text-center">
                      <i
                        role="button"
                        v-if="selected_ids.includes(item.id)"
                        @click="handleRemove(item.id)"
                        class="fas fa-check-square fa-2x text-primary"
                      />
                      <i v-else @click="handleSelect(item.id)" role="button" class="fas fa-square fa-2x" />
                    </h5>
                  </ShadedCol>
                  <ShadedCol align="center">
                    <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
                      {{ item.concreting_date | parseDate("DD/MM/YYYY") }}
                      <el-popover trigger="hover" placement="bottom" class="p-0">
                        <h4 class="mb-0 new-default-black-font fs-12 font-weight-normal">{{ item.company_plant_names }}</h4>
                        <a role="button" slot="reference" class="ml-2">
                          <img src="/img/icons/chemical-plant-black.png" width="20px" height="20px" />
                        </a>
                      </el-popover>
                    </h5>
                  </ShadedCol>
                  <ShadedCol>
                    <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
                      {{ item.entity_name }}
                    </h5>
                  </ShadedCol>
                  <ShadedCol>
                    <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
                      <span class="btn btn-sm btn-primary p-1 mr-2"> {{ item.code }} </span>
                      <span> {{ item.construction_name }} </span>
                    </h5>
                  </ShadedCol>
                  <ShadedCol>
                    <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
                      {{ item.concreted_piece }}
                    </h5>
                  </ShadedCol>
                  <ShadedCol>
                    <div class="row">
                      <div class="col">
                        <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
                          <span class="font-weight-bold"> {{ item.formulation_title }} | </span>
                          {{ item.formulation_body }} | {{ item.formulation_footer }}
                        </h5>
                      </div>
                      <div class="col text-right">
                        <img
                          @click.prevent="showAdjustments(item.first_travel_uuid)"
                          role="button"
                          width="20px"
                          height="20px"
                          src="/img/brand/logo.png"
                        />
                      </div>
                    </div>
                  </ShadedCol>
                  <ShadedCol>
                    <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
                      {{ getFeatureLevel(item.formulation_feature_level) }}
                    </h5>
                  </ShadedCol>
                  <ShadedCol align="center">
                    <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
                      {{ item.programmed_volume }}
                    </h5>
                  </ShadedCol>
                  <ShadedCol>
                    <div class="row m-0 d-flex align-items-center justify-content-center" v-if="!item.needs_approval_technical">
                      <div
                        @click.prevent="approve(item.id)"
                        role="button"
                        class="new-minicard-yellow fs-12 font-weight-400 col"
                        style="color: #f2b532; max-width: 116px"
                      >
                        <img src="/img/icons/icons8/ios/hourglass_warning.png" class="mr-1" width="19" />
                        Pendente
                      </div>
                    </div>
                    <div class="row m-0 d-flex align-items-center justify-content-center" v-else>
                      <base-button
                        size="sm"
                        :outline="!item.started_travels > 0"
                        style="opacity: 92%"
                        type="primary"
                        class="text-uppercase mr-0 active"
                        @click.prevent="disapprove(item.id)"
                        :disabled="item.started_travels > 0"
                      >
                        <img width="19px" class="mr-1 pointer" src="/img/icons/icons8/ios/ok.png" />
                        <span class="text-white fs-12 font-weight-400"> Aprovado </span>
                      </base-button>
                      <el-popover trigger="hover" placement="bottom" class="p-0">
                        <h4 class="mb-0 new-default-black-font fs-12">{{ item.approved_technical_by_user_name }}</h4>
                        <h4 class="mb-0 new-default-black-font fs-12 font-weight-normal">{{ item.approved_technical_at | parseDate() }}</h4>
                        <a role="button" slot="reference" class="ml-2">
                          <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="20px" height="20px" />
                        </a>
                      </el-popover>
                    </div>
                  </ShadedCol>
                </template>
              </ShadedTable>
            </div>
            <div v-if="selected_ids.length" style="bottom: 100px !important; position: fixed; z-index: 9999; left: 10px" class="row w-100">
              <div class="col d-flex justify-content-center">
                <base-button type="primary" @click.prevent="approveMany()">
                  {{ selected_ids.length }} Selecionadas | Aprovar todas
                </base-button>
              </div>
            </div>
          </div>

          <PuzlEmptyData v-if="!$_items.length && !loadingSkeleton" />
        </div>
      </div>
      <LoadingPagination :show="loading && !loadingSkeleton" />
      <ModalAdjustment ref="modalAdjustment" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import MultiFilter from "@/components/Utils/MultiFilterV3";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import InputDatePicker from "@/components/InputDatePicker";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import PuzlSelect from "@/components/PuzlSelect";
import moment from "moment";
import ShadedTable from "@/components/ShadedTable.vue";
import ShadedCol from "@/components/ShadedCol.vue";
import ModalAdjustment from "../../../Operational/Schedule/Charging/Shared/_ModalAdjustments.vue";

const statuses = [
  { name: "Pendente", id: 0 },
  { name: "Aprovado", id: 1 },
];

export default {
  components: {
    MultiFilter,
    LoadingPagination,
    InputDatePicker,
    PuzlEmptyData,
    SkeletonPuzl,
    PuzlSelect,
    ShadedCol,
    ShadedTable,
    ModalAdjustment,
  },
  name: "List",
  mixins: [cursorPaginate],
  data() {
    return {
      loading: false,
      loadingSkeleton: true,
      filter: {
        status: null,
        range: {
          start: new Date(moment().format("YYYY-MM-DD 00:00:00")),
          end: new Date(moment().add(7, "days").format("YYYY-MM-DD 00:00:00")),
        },
      },
      headers: ["", "Data", "Cliente", "Obra", "Peça", "Traço", "Aplicação", "Volume", "Status"],
      selected_ids: [],
      statuses,
      active_select_all: false,
    };
  },
  computed: {
    ...mapGetters({
      $_items: "schedule/fetch",
      $_feature_levels: "mixConcretePiece/feature_levels",
    }),
  },
  mounted() {
    this.filter.range = {
      start: moment().format("YYYY-MM-DD 00:00:00"),
      end: moment().add(7, "days").format("YYYY-MM-DD 00:00:00"),
    };
    this.selected_ids = [];
    this.init();
  },
  methods: {
    to_remove() {},
    handleSelect(item) {
      this.selected_ids.push(item);
    },
    handleRemove(item) {
      this.selected_ids.splice(
        this.selected_ids.findIndex((to_remove) => to_remove === item),
        1
      );
    },
    init(filter = null) {
      this.startCursor(filter);
      this.$store
        .dispatch("schedule/fetchApprovalTechnicalItems", {
          ...this.filter,
          ...{ next_page: this.paginate.nextUrl },
        })
        .then((response) => {
          this.loadingSkeleton = false;
          this.resolveCursor(response);
        })
        .catch((error) => {
          this.resolveCursor();
          this.loadingSkeleton = false;
        });
    },
    handleFilterDate(filter) {
      this.loadingSkeleton = true;
      this.filter.range = filter;
      this.init();
    },
    async approve(id) {
      const loader = this.$loading.show();
      this.selected_ids = [];
      await this.$store.dispatch("schedule/approvalManyTechnicalItems", { selected_ids: [id] });
      loader.hide();
      this.loadingSkeleton = true;
      this.init();
    },
    async approveMany() {
      const loader = this.$loading.show();
      await this.$store.dispatch("schedule/approvalManyTechnicalItems", { selected_ids: this.selected_ids });
      loader.hide();
      this.selected_ids = [];
      this.loadingSkeleton = true;
      this.init();
    },
    selectAll() {
      this.$_items.map((item) => {
        if (!this.selected_ids.includes(item.id) && !item.needs_approval_technical) {
          this.handleSelect(item.id);
        }
      }, this);
      this.active_select_all = true;
    },
    deselectAll() {
      this.$_items.map((item) => {
        if (this.selected_ids.includes(item.id)) {
          this.handleRemove(item.id);
        }
      }, this);
      this.active_select_all = false;
    },
    async disapprove(id) {
      const loader = this.$loading.show();
      await this.$store.dispatch("schedule/disapproveTechnicalItem", id);
      loader.hide();
      this.selected_ids = [];
      this.loadingSkeleton = true;
      this.init();
    },
    getFeatureLevel(value) {
      const feature_level = this.$_feature_levels.find((item) => item.value === value);
      return feature_level ? feature_level.label : "";
    },
    /**
     * Obtém os ajustes associados a uma viagem e exibe-os em um modal
     * @param {string} schedule_travel_uuid - O UUID da viagem
     */
    async showAdjustments(schedule_travel_uuid) {
      const loader = this.$loading.show();
      this.$notify({ type: "info", message: "Estamos trabalhando em sua solicitação." });
      const response = await this.$store.dispatch("travels/show", { uuid: schedule_travel_uuid });
      const travel = response.data;
      if (!travel) {
        return this.$notify({ type: "danger", message: "Viagem não encontrada!" });
      }
      if (!travel.schedule.contract_proposal_formulation.mix_mixe_id && !travel.has_adjustments) {
        return this.$notify({ type: "danger", message: "Traço não encontrado!" });
      }

      const params = {
        has_dosage: travel.isset_manual_dosage || false,
        schedule_travel_uuid: travel.uuid,
        volume_adjustments: travel.volume || null,
        concrete_piece_adjustments: travel.mix_concreted_piece_uuid,
        temperature_adjustments: travel.temperature || null,
        concrete_cycle_adjustments: travel.concrete_cycle,
        charge_point_adjustments: travel.charge_point_uuid || null,
        company_plant_uuid: travel.send_plant.uuid,
        mix_design_mixe_id: travel.schedule.contract_proposal_formulation.mix_mixe_id,
      };
      this.$store.dispatch("travels/getAdjustments", params).then((response) => {
        this.$Progress.finish();
        loader.hide();
        this.$notify({ type: "success", message: response.message });
        this.$refs.modalAdjustment.openModal();
      });
    },
  },
};
</script>

<style>
#approvalTechnicalTable td,
th {
  vertical-align: middle !important;
}
#approvalTechnicalTable .new-minicard-yellow {
  background-color: #fef9f2;
  border-radius: 15px;
  padding: 5px 12px;
}

#approvalTechnicalTable .new-minicard-blue {
  background-color: #1a70b7;
  border-radius: 15px;
  padding: 5px 12px;
}
</style>
