<template>
   <EditSchedule :bySeller="true"/>
</template>

<script>
import EditSchedule from "../../Shared/_Edit.vue";

export default {
  name: "EditContractProposalSchedule",
  components: {
    EditSchedule,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style></style>