<template>
  <div>
    <modal size="lg" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <div>
        <multi-filter ref="multi-filter" :status="multi_filter" @fetch="init" :filter="filter" />
        <div class="container-fluid">
          <div class="row card-wrapper" v-show="loadingSkeleton">
            <SkeletonPuzlGrid v-for="index in 3" :key="index" />
          </div>
          <PuzlEmptyData v-if="!items.length && !loadingSkeleton" />
          <div v-if="items.length && !loadingSkeleton">
            <div
              v-for="(item, index) in items"
              :key="index"
              class="border-left border-3 d-flex justify-content-between p-2 mb-4 border-success"
            >
              <div class="col-10">
                <div>
                  <h4 class="mb-0">{{ item.entity_name }}</h4>
                </div>
                <h5 class="p-0 m-0">{{ item.document }}</h5>
              </div>
              <div class="col-2 d-flex justify-content-center align-items-center">
                <base-button size="sm" type="success" @click="handleSelect(item)">
                  <i class="fa fa-check text-white"></i>
                </base-button>
              </div>
            </div>
            <pagination @navegate="navegate" :source="source" />
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <base-button type="secondary" @click="closeModal()"> Fechar </base-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MultiFilter from "@/components/Utils/MultiFilterV2.vue";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import Pagination from "@/components/Utils/Pagination.vue";
import PuzlEmptyData from "@/components/PuzlEmptyData";

export default {
  name: "ModalSearchEntityByFuelTank",
  data() {
    return {
      modal: {
        title: "Buscar CPF/CNPJ",
        create: false,
      },
      filter: {
        status: null,
      },
      paginate: {
        page: null,
      },
      loadingSkeleton: true,
      multi_filter: {
        null: {
          name: "Todos",
          type: "primary",
        },
      },
      source: null,
      fuel_tank_id: null,
    };
  },
  components: { MultiFilter, SkeletonPuzlGrid, Pagination, PuzlEmptyData },
  computed: {
    ...mapGetters({
      items: "entity/fetch",
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    async openModal(fuel_tank_id) {
      let loader = this.$loading.show();
      this.$refs["multi-filter"].global = [];
      this.fuel_tank_id = fuel_tank_id;
      this.filter = {
        status: null,
        page: 1,
      };
      this.paginate.page = 1;
      await this.init();
      loader.hide();
      this.modal.create = true;
    },
    init(filter = null) {
      this.filter = filter ?? this.filter;
      this.loadingSkeleton = true;
      return this.$store
        .dispatch("entity/getByFuelTank", {
          filter: this.filter,
          page: this.paginate.page,
          fuel_tank_id: this.fuel_tank_id,
        })
        .then((response) => {
          this.source = response;
          this.loadingSkeleton = false;
        });
    },
    navegate(page) {
      this.paginate.page = page;
      this.init();
    },
    handleSelect(entity) {
      this.$emit("selected", entity, this.fuel_tank_id);
      this.closeModal();
    },
  },
};
</script>

<style scoped></style>
