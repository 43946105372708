<template>
  <div>
    <base-header class="bg-gray-content mt-2"></base-header>
    <ListAccoutingChartAccount />
  </div>
</template>

<script>
import ListAccoutingChartAccount from "./_List.vue"

export default {
  name: "Index",
  components: { ListAccoutingChartAccount },
  methods: {
  },
};
</script>

<style></style>