<template>
  <div>
    <modal class="modal-scrollable" @close="close" size="lg" :show.sync="show" :heightTitle="90">
      <template v-if="show" slot="header">
        <h5 class="modal-title p-0 m-0">{{ params.title }} <br> {{ params.value | currency() }} <br>
          {{ params.month || 'Acumulado' }}</h5>
      </template>
      <div class="row pr-2">
        <div class="col-md-12">
          <div class=" px-0 pt-0 pb-0">
            <div class="col-md-12 px-0">
              <div class="card" style="margin-bottom: 5px" v-for="(item, index) in $_chart_accounts">
                <div class="card-body">
                  <div class="row mt-n2">
                    <div class="col-7">
                      <div class="row">
                        <div class="col-1 mr-3">
                          <badge style="font-size: 1.7rem">{{ index + 1 }}.</badge>
                        </div>
                        <div class="col">
                          {{ item.entity_name }} <br> {{ item.document }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-5 text-right">N° de notas: <b>{{ item.entry_launch.length }}</b> <br>
                      <div style="font-size: 1.1rem;margin-top: -5px">{{ item.sum | currency() }}</div>
                    </div>
                    <div class="col-md-12">
                      <div style="border-top: 1px solid #1A70B7;margin-bottom: 10px;margin-top: 10px"/>
                    </div>
                  </div>
                  <card style="margin-bottom: -10px">
                    <div v-for="entry_launch in item.entry_launch">
                      <div class="row">
                        <div class="col-md-4 font-weight-500">DOC. {{ entry_launch.document_number }}
                          <el-popover trigger="hover" placement="bottom" class="p-0 pr-0">
                            <div class="table-responsive" style="max-height: 50vh;overflow: auto;margin-bottom: 0px">
                              <table class="table table-bordered table-sm" >
                                <thead>
                                <tr>
                                  <th class="text-center">DATA</th>
                                  <th class="text-center">STATUS</th>
                                  <th class="text-center">VALOR</th>
                                  <th class="text-center">CONTA BANCÁRIA</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="installment in entry_launch.bill_pay[0].installments">
                                  <td class="text-center">
                                  <span
                                    v-if="installment.status === 1"> {{ installment.paid_at | parseDate('DD MMM YYYY') }}</span>
                                    <span v-else> {{ installment.due_date | parseDate('DD MMM YYYY') }}</span>
                                  </td>
                                  <td class="text-center">
                                    <span v-if="installment.status === 1"> <span class="text-success">PAGO</span></span>
                                    <span v-else><span class="text-warning">PENDENTE</span></span>
                                  </td>
                                  <td class="text-center">
                                    <span>{{ installment.total_value | currency() }}</span>
                                  </td>
                                  <td class="text-center">
                                    <span>{{ installment.bank_account ? installment.bank_account.name : '-' }}</span>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                            <badge slot="reference" style="font-size: 1rem;background: rgb(239 234 234) !important"
                                   class="bg-light badge-default text-grey2">
                            <span
                              class="text-success">{{
                                entry_launch.bill_pay[0].paid_installments
                              }}</span> / {{ entry_launch.bill_pay[0].installments.length }}
                            </badge>
                          </el-popover>
                        </div>
                        <div class="col-md-4 font-weight-600 text-primary text-center">
                          {{ entry_launch.sum | currency() }}
                        </div>
                        <div class="col-md-4 text-right font-weight-500">PLANO DE CONTAS</div>
                        <div class="col-12">
                          <div style="border-top: 1px solid #1A70B7;margin-bottom: 10px;margin-top: 10px"/>
                        </div>
                      </div>
                      <div class="row" v-for="entry_launch_item in entry_launch.items">
                        <div class="col-md-4 text-truncate font-weight-400">{{ entry_launch_item.product_name }}</div>
                        <div class="col-md-4 font-weight-500 text-center">
                          {{ entry_launch_item.total_value | currency() }}
                        </div>
                        <div class="col-md-4 mb-1">
                          <base-input input-classes="form-control-sm">
                            <PuzlSelect
                              @change="updateChartAccount(entry_launch_item)"
                              placeholder="Plano de Contas"
                              v-model="entry_launch_item.chart_account_id"
                              :items="$_chart_accounts_to_change"
                              :clearable="false"
                              :labelMask="true"
                              label="$code$ $name$"
                              :selectable="(i) => i.extra_field.length"
                              extraField="parents"
                              :disabled="!has_permission('additional_permissions.chart-account-can-edit.index.view')"
                            />
                          </base-input>
                        </div>
                      </div>
                    </div>
                  </card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
import { hasPermission } from "@/plugins/permissions";

export default {
  name: "ModalShowEntries",
  components: {
    PuzlSelect
  },
  data() {
    return {
      title: null,
      isLoading: false,
      show: false,
      has_changed: false,
      params: {
        title: null,
        month: null,
        value: null
      },
      has_permission: hasPermission,
    }
  },
  computed: {
    ...mapGetters({
      $_chart_accounts: "dre/getListChartAccounts",
      $_chart_accounts_to_change: "chartAccount/fetch",
    })
  },
  methods: {
    updateChartAccount(entry_launch_item) {
      this.has_changed = true
      let loader = this.$loading.show()
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'})
      this.$store.dispatch('dre/postUpdateChartAccount', {
        entry_launch_item_id: entry_launch_item.id,
        chart_account_id: entry_launch_item.chart_account_id
      }).then((response) => this.$notify({
        type: response.error_type,
        message: response.message
      })).finally(() => loader.hide())
    },
    close() {
      this.show = false
      if (this.has_changed) {
        this.$emit('fetch')
      }
    },
    handleCreateModal(chart_account_id, params) {
      this.has_changed = false
      this.params.title = params.item.name
      this.params.value = params.title
      this.params.month = params.month
      let loader = this.$loading.show()
      Promise.all([
        this.$store.dispatch("chartAccount/fetchItems"),
        this.$store.dispatch('dre/getChartAccountDetails', {
          chart_account_id: chart_account_id,
          month: params.index,
          year: params.year
        })]
      ).then(() => this.show = true)
        .finally(() => loader.hide())
    }
  },
};
</script>

<style>
</style>
