<template>
<div>
<modal :show.sync="modal.update">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(update)"
            autocomplete="off"
          >
            <div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-4 col-form-label form-control-label">
                  Status
                </label>
                <div class="col-md-8 pt-2">
                  <base-switch
                    v-model="editServiceDescription.status"
                    type="success"
                    offText="inativo"
                    onText="ativo"
                    class="success"
                    :disabled="isDisabled"
                  ></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-4 pt-1 pb-0 mb-1 col-form-label form-control-label"
                >
                  Serviço
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-classes="form-control form-control-sm">
                          <puzl-select 
                            v-model="editServiceDescription.service_id" 
                            :items="listServices"  
                            label="service_name" 
                            :loading="isloadingListServices" 
                            :disabled="true" />
                        </base-input>
                      </validation-provider>
                  </div>
              </div>

            <div class="form-group row m-0 p-0 mt-1">
                <label
                  class="col-md-4 pt-1 pb-0 mb-1 col-form-label form-control-label"
                >
                  Descrição
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="editServiceDescription.description"
                        class="form-control form-control-sm"
                        :disabled="isDisabled"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('update')"
                >Cancelar</base-button
              >
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                :loading="loading"
                >Salvar</base-button
              >
            </div>
          </form>
        </validation-observer>
      </div>
  </modal>
</div>
</template>
<script>
import PuzlSelect from "@/components/PuzlSelect";
export default {
  components: { PuzlSelect },
  name: "EditServicePrice",
  data() {
    return {
      modal: {
        title: '',
        create: false,
        update: false,
      },
      validated: false,
      listServices: {},
      editServiceDescription: {
        id: '',
        uuid: '',
        status: true,
        description:'',
        service_id: '',
      },
      loading: false,
      isDisabled: true,
      isloadingListServices: false,
    };
  },
  methods: {
    closeModal (name) {
      this.modal[name] = false
    },
    openModal (name) {
      this.modal[name] = true
    },
    handleEditModal(item){
      this.showItem(item.uuid);
      this.modal.title = 'Editar Descrição do Serviço';
      this.openModal('update');
    },
    showItem(uuid){
      this.editServiceDescription = {
        id: '',
        uuid: '',
        status: true,
        description:'',
        service_id: '',
      };
      this.$Progress.start();
      this.$axios.get("/commercial/service/description/"+uuid)
        .then(({data}) => {
          this.editServiceDescription = {
            id: data.data.id,
            uuid: data.data.uuid,
            status: data.data.status,
            description:data.data.description,
            service_id: data.data.service.id,
          };
          this.$Progress.finish();
          this.isDisabled=false;
        })
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
        });
    },
    update(){
        this.$Progress.start();
        this.loading = true;
        this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
        this.$axios.put("/commercial/service/description/"+this.editServiceDescription.uuid, this.editServiceDescription)
          .then(({data}) => {
            this.$notify({ type: data.error_type, message: data.message });
            this.loading = false;
            this.editServiceDescription = {
              id: '',
              uuid: '',
              status: '',
              description:'',
              service_id: '',
            };
            EventBus.$emit("getServiceDescription");
            this.closeModal('update');
            this.loading = false;
            this.$Progress.finish();
          })
          .catch((error) => {
            if (error) {
              Exception.handle(error);
            }
            this.$notify({ type: error.response.data.error_type, message: error.response.data.message });
            this.loading = false;
          });
    },
    getServices(){
      this.$Progress.start();
      this.isloadingListServices=true;
      this.$axios.get("/configuration/service/active")
        .then(({data}) => {
          this.listServices = data.data;
          this.$Progress.finish();
          this.isloadingListServices=false;
        })
        .catch((error) => {
          if (error) {
            Exception.handle(error);
            this.isloadingListServices=false;
          }
        });
    },
    getCompanyPlant(){
      this.$Progress.start();
      this.isloadingListCompanyPlant=true;
      this.$axios.get("/company/plant/active")
        .then(({data}) => {
          this.listCompanyPlant = data.data;
          this.$Progress.finish();
          this.isloadingListCompanyPlant=false;
        })
        .catch((error) => {
          if (error) {
            Exception.handle(error);
            this.isloadingListCompanyPlant=false;
          }
        });
    },
  },
  created(){
    this.getServices();
    this.getCompanyPlant();
  },
  mounted() {
    this.$refs.formValidator.validate();
    EventBus.$on("handleEditServiceDescription", (item) => {
      this.handleEdit(item);
    });

  }
};
</script>
<style></style>
