<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-gray d-inline-block mb-0">{{ $route.name }}</h6>
          <nav
            aria-label="breadcrumb"
            class="d-none d-md-inline-block ml-md-4 text-gray"
          >
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i></a></li>
              <li class="breadcrumb-item"><a href="#">Cadastro</a></li>
              <li class="breadcrumb-item" aria-current="page">{{ $route.name }}</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-2 mb-2">
          <base-button block size="lg" type="success" @click="handleCreate()">
            <i class="fas fa-plus"></i> NOVO
          </base-button>
        </div>
        <div class="col-md-10">
          <base-dropdown class="col-md-2 p-0">
            <base-button
              slot="title-container"
              block
              size="lg"
              type="default"
              class="dropdown-toggle text-uppercase"
            >
              <i class="fas fa-external-link-alt"></i> Cadastros
            </base-button>
            <div>
              <router-link :to="{ path:'/commercial/customer-construction/type'}">
                <a class="dropdown-item">Tipo de Obra</a>
                <div class="dropdown-divider p-0 m-0"></div>
              </router-link>

              <router-link :to="{ path:'/commercial/customer-construction/prospection-method'}">
                <a class="dropdown-item" href="#">Métodos de Prospecção</a>
              </router-link>
            </div>
          </base-dropdown>
        </div>
      </div>
    </base-header>
    <list-customer></list-customer>
    <modal-create-customer ref="createCustomer"></modal-create-customer>
  </div>
</template>
<script>
import ListCustomer from './Shared/_List'
import ModalCreateCustomer from './Shared/_Create'

export default {
  name: 'IndexCustomer',
  components: {
    ListCustomer,
    ModalCreateCustomer
  },
  data() {
    return {}
  },
  methods: {
    handleCreate() {
      this.$refs.createCustomer.handleCreateModal()
    },
  },
}

</script>
<style></style>
