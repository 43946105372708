<template>
  <div>
    <modal size="lg" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">

            <div class="form-group row m-0 p-0">
              <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                Conta Bancária (Origem)
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-8 mb-1 pr-4 v-select-financial-no-hover-issue">
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                      :items="$_bank_accounts.filter(item => item.id !== bankTransfer.bank_account_target_id)"
                      v-model="bankTransfer.bank_account_id"
                      :loading="loadingBankAccount"
                      :disabled="loadingBankAccount"/>
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                Conta Bancária (Destino)
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-8 mb-1 pr-4 v-select-financial-no-hover-issue">
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                      v-model="bankTransfer.bank_account_target_id"
                      :items="$_bank_accounts.filter(item => item.id !== bankTransfer.bank_account_id)"
                      :loading="loadingBankAccount"
                      :disabled="loadingBankAccount"/>
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
                <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                  Data 
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-6 mt-1">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input-date-time-picker 
                        :disableMaxDate="max_date" 
                        v-model="bankTransfer.date" 
                        :mode="'date'"/>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                Valor
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-8 mb-1 pr-4">
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="bankTransfer.amount"
                      v-money="money"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                Observações
              </label>
              <div class="col-md-8 mb-1 pr-4">
                <base-input input-group-classes="input-group-sm">
                        <textarea
                          type="text"
                          rows="2"
                          class="form-control form-control-sm"
                          v-model="bankTransfer.note"
                          maxlength="250"
                        >
                        </textarea>
                </base-input>
              </div>
            </div>

            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')">
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                Salvar
              </base-button>
            </div>

          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import PuzlSelect from "@/components/PuzlSelect";
import {VMoney} from "v-money";
import moment from "moment";
import {moneyToFloat} from "@/helpers";
import InputDateTimePicker from "@/components/InputDateTimePicker";

const {formatErrorValidation} = require("@/plugins");

export default {
  name: "ModalCreate",
  components: {PuzlSelect, InputDateTimePicker},
  data() {
    return {
      modal: {
        title: 'Transferência entre contas',
        create: false,
      },
      bankTransfer: {
        bank_account_id: null,
        bank_account_target_id: null,
        date: null,
        amount: null,
        note: '',
      },
      loadingStore: false,
      loadingBankAccount: true,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false
      },
      max_date : false,
    };
  },
  computed: {
    ...mapGetters({
      $_bank_accounts: 'bankAccount/fetch',
    }),
  },
  mounted() {
    this.$refs.formValidator.validate();
    this.$store.dispatch('bankAccount/fetchItems', { filter: { has_allocated_plant: true } }).then(() => {
      this.loadingBankAccount = false;
    });
  },
  directives: {
    money: VMoney
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    handleCreateModal() {
      this.bankTransfer = {
        bank_account_id: null,
        bank_account_target_id: null,
        date: moment().format("YYYY-MM-DD"),
        amount: 0,
        note: '',
      },
        this.modal.create = true;
    },
    store() {
      this.$Progress.start();
      this.loadingStore = true;
      let payload = {...this.bankTransfer};
      payload.amount = this.$helper.moneyToFloat(payload.amount);
      payload.date = moment(payload.date).format("YYYY-MM-DD");
      if (payload.amount <= 0) {
        this.$notify({
          type: 'danger',
          message: 'Valor não pode ser menor ou igual a zero'
        });
        this.loadingStore = false;
        this.$Progress.finish();
        return
      }
      this.$store.dispatch('bankTransfer/add', payload)
        .then((response) => {
          this.loadingStore = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          })
          this.closeModal();
          this.$emit('created');
        })
        .catch((error) => {
          if (error.status === 200) {
            this.$notify({
              type: 'danger',
              message: error.data.message
            })
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors)
            this.$notify({
              type: 'danger',
              message: errors
            })
          }
          this.$Progress.finish();
          this.loadingStore = false;
        })
    },
  },
};
</script>

<style>
.v-select-financial-no-hover-issue .vs__dropdown-option {
  background-color: white!important;
  color: #525f7f!important;
}
</style>
