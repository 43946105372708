<template>
  <div>
    <modal size="lg" :show.sync="modal">
      <template slot="header">
        <div class="d-flex align-items-center">
          <img src="/img/icons/icons8/ios/clock-white.png" width="22" />
          <h5 class="modal-title p-0 m-0 ml-3 text-white">{{ title }}</h5>
        </div>
      </template>
      <div class="px-3">
        <div class="row">
          <!-- O.S. -->
          <div class="col-6 pr-1">
            <div v-show="currentTravel.number" style="box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important" class="card rounded mb-0">
              <div class="card-body py-2">
                <div class="row justify-content-center">
                  <img class="mr-1" width="40px" src="/img/icons/os.png" />
                  <div>
                    <h4 class="new-default-black-font mb-0">{{ currentTravel.number }}</h4>
                    <h5 class="new-default-gray-font mb-0 font-weight-normal">{{ currentTravel.number_created_at }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- NF-E -->
          <div class="col-6 pl-1">
            <div v-if="currentTravel.xml" style="box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important" class="card rounded mb-0">
              <div class="card-body py-2">
                <div class="row justify-content-center">
                  <img class="py-1 mr-2" width="26px" src="/img/nfe.png" />
                  <div>
                    <h4 class="new-default-black-font mb-0">{{ currentTravel.xml.number }}</h4>
                    <h5 v-if="currentTravel.finished_at" class="new-default-gray-font mb-0 font-weight-normal">
                      {{ currentTravel.finished_at | parseDate("DD MMM YYYY HH:mm") }}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


       <div class="row mt-1">
         <div class="col-11">
           <hr
             v-show="currentTravel.xml || currentTravel.number"
             style="background: rgba(0, 0, 0, 0.05) !important"
             class="new-default-black my-4 mx-1"
           />
         </div>
         <div class="col-1">
           <el-popover v-if="currentTravel.user_finished_travel"
             class="p-0 float-right pr-0"
             placement="left"
             trigger="hover"
           >
             <div>
                              <span>
                    Usuário:  {{ currentTravel.user_finished_travel ? currentTravel.user_finished_travel.name : null}}
                              </span>
               <br>
               Data: {{currentTravel.finished_travel_at | parseDate('DD/MM/YYYY H:mm')}}
             </div>
             <base-button
               slot="reference"
               class="p-0 m-0 shadow-none"
               outiline
               size="sm"
               type="secundary"
             >
               <img
                 src="/img/icons/icons8/ios/user-male-circle--v1.png"
                 width="25px"
                 height="25px"
                 class="mt-2"
               />
             </base-button>
           </el-popover>
         </div>
       </div>




        <!-- Menu de navegação -->
        <Navegation @input="tab_value = $event" :tab_value="tab_value" />

        <validation-observer v-slot="{ invalid }" ref="formValidator">
          <!-- Horários -->
          <section v-show="tab_value === 'entry'">
            <div class="row align-items-center mt-4 mb-3">
              <div class="col-md-2 col-4">
                <h3 style="line-height: normal !important" class="new-default-black-font mb-0 d-flex align-items-center">Horários</h3>
              </div>
              <div class="col-md-10 col-8">
                <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black m-0 mr-1" />
              </div>
            </div>

            <!-- Prenchimento automático -->
            <div class="row mb-4">
              <div class="col-md-6 col-12 mb-md-0 mb-4">
                <div
                  v-if="currentTravel.status === 5 && currentTravel.should_automatically_fill === 1"
                  role="button"
                  class="new-minicard-red rounded row m-0 align-items-center justify-content-center text-center"
                  @click.prevent="automaticallyFill"
                >
                  <img height="20px" src="/img/icons/icons8/ios/bot.png" class="mr-2" />
                  <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 mt-1">
                    Preenchimento automático
                  </h5>
                </div>
              </div>
              <div class="col-3 show-mobile" />
              <div class="col-md-3 col align-items-center d-flex">
                <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 mt-1">Previsto</h5>
              </div>
              <div class="col-md-3 col align-items-center d-flex">
                <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 mt-1">Realizado</h5>
              </div>
            </div>

            <!-- Início do carregamento -->
            <div class="row align-items-center">
              <div class="col-md-6 col-3">
                <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 ml-md-1">
                  Início do carreg.
                </h5>
              </div>
              <div class="col-md-3 col">
                <div class="new-minicard-light rounded">
                  <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 fs-11-5">
                    {{ currentTravel.load_truck_time }}
                  </h5>
                </div>
              </div>
              <div class="col-md-3 col">
                <validation-provider rules="required">
                  <InputDateTimePicker
                    :disabled="enableEdit(currentTravel)"
                    @input="changeStartLoadTruck"
                    v-model="travelCycle.load_truck_time"
                  />
                </validation-provider>
              </div>
            </div>

            <!-- Término do carregamento -->
            <div class="row mt-3 mt-md-2 align-items-center">
              <div class="col-md-6 col-3">
                <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 ml-md-1">
                  Término do carreg.
                </h5>
              </div>
              <div class="col-md-3 col">
                <div class="new-minicard-light rounded">
                  <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 fs-11-5">
                    {{ currentTravel.end_load_truck_time }}
                  </h5>
                </div>
              </div>
              <div class="col-md-3 col">
                <validation-provider rules="required">
                  <InputDateTimePicker
                    :disabled="enableEdit(currentTravel)"
                    @input="changeStartLoadTruck"
                    v-model="travelCycle.end_load_truck_time"
                  />
                </validation-provider>
              </div>
            </div>

            <!-- Saída da central -->
            <div class="row mt-3 mt-md-2 align-items-center">
              <div class="col-md-6 col-3">
                <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 ml-md-1">Saída da central</h5>
              </div>
              <div class="col-md-3 col">
                <div class="new-minicard-light rounded">
                  <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 fs-11-5">
                    {{ currentTravel.plant_exit_time }}
                  </h5>
                </div>
              </div>
              <div class="col-md-3 col">
                <!-- Horário -->
                <div v-if="fields_with_active_checkbox.plant_exit_time" class="row align-items-center">
                  <div class="col-auto pr-0">
                    <input v-model="fields_with_active_checkbox.plant_exit_time" tabindex="-1" type="checkbox" />
                  </div>
                  <div class="col">
                    <InputTimePicker
                      minuteInterval="1"
                      @input="travelCycle.plant_exit_time = addDate($event)"
                      @close="provideTimes.send_route_time = dateDiff(travelCycle.plant_exit_time, travelCycle.arrival_time)"
                      :disabled="enableEdit(currentTravel)"
                      v-model="travelCycle.plant_exit_time"
                    />
                  </div>
                </div>

                <!-- Data completa -->
                <InputDateTimePicker
                  v-else
                  @clean="fields_with_active_checkbox.plant_exit_time = true"
                  :disableDate="disableDate"
                  :disabled="enableEdit(currentTravel)"
                  @input="provideTimes.send_route_time = dateDiff(travelCycle.plant_exit_time, travelCycle.arrival_time)"
                  v-model="travelCycle.plant_exit_time"
                />
              </div>
            </div>

            <!-- Chegada na obra -->
            <div class="row mt-3 mt-md-2 align-items-center">
              <div class="col-md-6 col-3">
                <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 ml-md-1">Chegada na obra</h5>
              </div>
              <div class="col-md-3 col">
                <div class="new-minicard-light rounded">
                  <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 fs-11-5">
                    {{ currentTravel.arrival_time }}
                  </h5>
                </div>
              </div>
              <div class="col-md-3 col">
                <!-- Horário -->
                <div v-if="fields_with_active_checkbox.arrival_time" class="row align-items-center">
                  <div class="col-auto pr-0">
                    <input v-model="fields_with_active_checkbox.arrival_time" tabindex="-1" type="checkbox" />
                  </div>
                  <div class="col">
                    <InputTimePicker
                      minuteInterval="1"
                      @input="travelCycle.arrival_time = addDate($event)"
                      @close="changeArrivalTime"
                      :disabled="enableEdit(currentTravel)"
                      v-model="travelCycle.arrival_time"
                    />
                  </div>
                </div>

                <!-- Data completa -->
                <InputDateTimePicker
                  v-else
                  @clean="fields_with_active_checkbox.arrival_time = true"
                  :disableDate="disableDate"
                  :disabled="enableEdit(currentTravel)"
                  @input="changeArrivalTime"
                  v-model="travelCycle.arrival_time"
                />
              </div>
            </div>

            <!-- Início de descarga -->
            <div class="row mt-3 mt-md-2 align-items-center">
              <div class="col-md-6 col-3">
                <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 ml-md-1">
                  Início de descarga
                </h5>
              </div>
              <div class="col-md-3 col">
                <div class="new-minicard-light rounded">
                  <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 fs-11-5">N/A</h5>
                </div>
              </div>
              <div class="col-md-3 col">
                <!-- Horário -->
                <div v-if="fields_with_active_checkbox.start_discharge_time" class="row align-items-center">
                  <div class="col-auto pr-0">
                    <input v-model="fields_with_active_checkbox.start_discharge_time" tabindex="-1" type="checkbox" />
                  </div>
                  <div class="col">
                    <InputTimePicker
                      minuteInterval="1"
                      @input="travelCycle.start_discharge_time = addDate($event)"
                      @close="changeStartDischarge"
                      :disabled="enableEdit(currentTravel)"
                      v-model="travelCycle.start_discharge_time"
                    />
                  </div>
                </div>

                <!-- Data completa -->
                <InputDateTimePicker
                  v-else
                  @clean="fields_with_active_checkbox.start_discharge_time = true"
                  :disableDate="disableDate"
                  :disabled="enableEdit(currentTravel)"
                  @input="changeStartDischarge"
                  v-model="travelCycle.start_discharge_time"
                />
              </div>
            </div>

            <!-- Término de descarga -->
            <div class="row mt-3 mt-md-2 align-items-center">
              <div class="col-md-6 col-3">
                <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 ml-md-1">
                  Término de descarga
                </h5>
              </div>
              <div class="col-md-3 col">
                <div class="new-minicard-light rounded">
                  <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 fs-11-5">N/A</h5>
                </div>
              </div>
              <div class="col-md-3 col">
                <!-- Horário -->
                <div v-if="fields_with_active_checkbox.end_discharge_time" class="row align-items-center">
                  <div class="col-auto pr-0">
                    <input v-model="fields_with_active_checkbox.end_discharge_time" tabindex="-1" type="checkbox" />
                  </div>
                  <div class="col">
                    <InputTimePicker
                      minuteInterval="1"
                      @input="travelCycle.end_discharge_time = addDate($event)"
                      @close="changeEndDischarge"
                      :disabled="enableEdit(currentTravel)"
                      v-model="travelCycle.end_discharge_time"
                    />
                  </div>
                </div>

                <!-- Data completa -->
                <InputDateTimePicker
                  v-else
                  @clean="fields_with_active_checkbox.end_discharge_time = true"
                  :disableDate="disableDate"
                  :disabled="enableEdit(currentTravel)"
                  @input="changeEndDischarge"
                  v-model="travelCycle.end_discharge_time"
                />
              </div>
            </div>

            <!-- Saída da obra -->
            <div class="row mt-3 mt-md-2 align-items-center">
              <div class="col-md-6 col-3">
                <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 ml-md-1">Saída da obra</h5>
              </div>
              <div class="col-md-3 col">
                <div class="new-minicard-light rounded">
                  <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 fs-11-5">
                    {{ currentTravel.construction_exit_time }}
                  </h5>
                </div>
              </div>
              <div class="col-md-3 col">
                <!-- Horário -->
                <div v-if="fields_with_active_checkbox.construction_exit_time" class="row align-items-center">
                  <div class="col-auto pr-0">
                    <input v-model="fields_with_active_checkbox.construction_exit_time" tabindex="-1" type="checkbox" />
                  </div>
                  <div class="col">
                    <InputTimePicker
                      minuteInterval="1"
                      @input="travelCycle.construction_exit_time = addDate($event)"
                      @close="changeConstructionExitTime"
                      :disabled="enableEdit(currentTravel)"
                      v-model="travelCycle.construction_exit_time"
                    />
                  </div>
                </div>

                <!-- Data completa -->
                <InputDateTimePicker
                  v-else
                  @clean="fields_with_active_checkbox.construction_exit_time = true"
                  :disableDate="disableDate"
                  :disabled="enableEdit(currentTravel)"
                  @input="changeConstructionExitTime"
                  v-model="travelCycle.construction_exit_time"
                />
              </div>
            </div>

            <!-- Chegada na central -->
            <div class="row mt-3 mt-md-2 align-items-center">
              <div class="col-md-6 col-3">
                <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 ml-md-1">
                  Chegada na central
                </h5>
              </div>
              <div class="col-md-3 col">
                <div class="new-minicard-light rounded">
                  <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 fs-11-5">
                    {{ currentTravel.plant_arrival_time }}
                  </h5>
                </div>
              </div>
              <div class="col-md-3 col">
                <!-- Horário -->
                <div v-if="fields_with_active_checkbox.plant_arrival_time" class="row align-items-center">
                  <div class="col-auto pr-0">
                    <input v-model="fields_with_active_checkbox.plant_arrival_time" tabindex="-1" type="checkbox" />
                  </div>
                  <div class="col">
                    <InputTimePicker
                      minuteInterval="1"
                      @input="travelCycle.plant_arrival_time = addDate($event)"
                      @close="changePlantArrival"
                      :disabled="enableEdit(currentTravel)"
                      v-model="travelCycle.plant_arrival_time"
                    />
                  </div>
                </div>

                <!-- Data completa -->
                <InputDateTimePicker
                  v-else
                  @clean="fields_with_active_checkbox.plant_arrival_time = true"
                  :disableDate="disableDate"
                  :disabled="enableEdit(currentTravel)"
                  @input="changePlantArrival"
                  v-model="travelCycle.plant_arrival_time"
                />
              </div>
            </div>

            <!-- Liberado para novo carregamento -->
            <div class="row mt-3 mt-md-2 align-items-center">
              <div class="col-md-6 col-3">
                <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 ml-md-1">
                  Liberado p/ novo carreg.
                </h5>
              </div>
              <div class="col-md-3 col">
                <div class="new-minicard-light rounded">
                  <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 fs-11-5">
                    {{ currentTravel.free_for_travel }}
                  </h5>
                </div>
              </div>
              <div class="col-md-3 col">
                <!-- Horário -->
                <div v-if="fields_with_active_checkbox.free_for_travel" class="row align-items-center">
                  <div class="col-auto pr-0">
                    <input v-model="fields_with_active_checkbox.free_for_travel" tabindex="-1" type="checkbox" />
                  </div>
                  <div class="col">
                    <InputTimePicker
                      minuteInterval="1"
                      @input="travelCycle.free_for_travel = addDate($event)"
                      @close="changeFreeForTravel"
                      :disabled="enableEdit(currentTravel)"
                      v-model="travelCycle.free_for_travel"
                    />
                  </div>
                </div>

                <!-- Data completa -->
                <InputDateTimePicker
                  v-else
                  @clean="fields_with_active_checkbox.free_for_travel = true"
                  :disableDate="disableDate"
                  :disabled="enableEdit(currentTravel)"
                  @input="changeFreeForTravel"
                  v-model="travelCycle.free_for_travel"
                />
              </div>
            </div>

            <!-- Data de concretagem -->
            <div
              v-if="
                !loadingOperationalPermission &&
                !currentTravel.was_billed &&
                ['finished', 'canceled'].includes(schedule_travel_status[currentTravel.status]) &&
                $_operational_permission &&
                $_operational_permission.can_edit_schedule_travel_finished_at
              "
              class="row mt-3 mt-md-2 align-items-center"
            >
              <div class="col-md-6 col-3">
                <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 ml-md-1">
                  Data de concretagem
                </h5>
              </div>
              <div class="col-md-3 col">
                <div class="new-minicard-light rounded">
                  <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 fs-11-5">N/A</h5>
                </div>
              </div>
              <div class="col-md-3 col">
                <validation-provider rules="required">
                  <InputDateTimePicker
                    v-model="currentTravel.finished_at"
                    :disableMaxDate="new Date()"
                    :disableDate="new Date(new Date().setMonth(new Date().getMonth() - 1))"
                  />
                </validation-provider>
              </div>
            </div>

            <!-- Percurso -->
            <div class="row align-items-center mt-4 mb-3">
              <div class="col-md-2 col-4">
                <h3 style="line-height: normal !important" class="new-default-black-font mb-0 d-flex align-items-center">Percurso</h3>
              </div>
              <div class="col-md-10 col-8">
                <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black m-0 mr-1" />
              </div>
            </div>
            <div class="row justify-content-between mb-3">
              <div class="col-md-3 d-flex align-items-center mb-md-0 mb-2">
                <el-popover v-if="currentTravel.updated_by_user_name" trigger="hover" placement="bottom">
                  <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="22px" />
                  <h5 class="new-default-black-font mb-0 mt-1" style="word-break: break-word">
                    {{ currentTravel.updated_by_user_name }}
                  </h5>
                  <h5 class="font-weight-normal new-default-black-font mb-0" style="word-break: break-word">
                    {{ travelCycle.updated_at | parseDate }}
                  </h5>
                  <base-button outline slot="reference" size="sm" type="secundary" class="p-0 m-0 mr-2 shadow-none text-indigo">
                    <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="25" />
                  </base-button>
                </el-popover>
              </div>
              <div class="col-md-3">
                <base-button block type="light" class="text-uppercase d-flex align-items-center">
                  <img height="20px" src="/img/icons/icons8/ios/place-marker.png" class="mr-2" />
                  {{ travelCycle.travelled_distance }} KM
                </base-button>
              </div>
            </div>

            <!-- Local de saída -->
            <div class="row align-items-center">
              <div class="col-md-6 col-3">
                <h5 class="mb-0 new-default-black-font font-weight-400">Local de saída</h5>
              </div>
              <div class="col-md-6 col">
                <validation-provider rules="required">
                  <PuzlSelect
                    :disabled="
                      ['finished', 'unrealized', 'canceled', 'in_progress'].includes(schedule_travel_status[currentTravel.status]) ||
                      currentTravel.isset_manual_dosage === true
                    "
                    customKey="uuid"
                    :clearable="false"
                    v-model="currentTravel.send_plant_uuid"
                    :items="$_company_plants"
                  />
                </validation-provider>
              </div>
            </div>

            <!-- KM -->
            <div class="row mt-2 align-items-center">
              <div class="col-md-6 col-3">
                <h5 class="mb-0 new-default-black-font font-weight-400">KM</h5>
              </div>
              <div class="col-md-6 col">
                <base-input input-group-classes="input-group-sm">
                  <input
                    inputmode="numeric"
                    @change="changedDistance"
                    v-on:input="travelCycle.send_distance = formatString($event.target.value, '0', '9999999')"
                    :disabled="currentTravel.was_billed"
                    v-model="travelCycle.send_distance"
                    class="form-control form-control-sm"
                  />
                  <template slot="append">
                    <small class="input-group-sm p-0 m-0"> km </small>
                  </template>
                </base-input>
              </div>
            </div>

            <!-- Local de retorno -->
            <div class="row mt-2 align-items-center">
              <div class="col-md-6 col-3">
                <h5 class="mb-0 new-default-black-font font-weight-400">Local de retorno</h5>
              </div>
              <div class="col-md-6 col">
                <validation-provider rules="required">
                  <PuzlSelect
                    :disabled="currentTravel.was_billed && schedule_travel_status[currentTravel.status] !== 'canceled'"
                    customKey="uuid"
                    :clearable="false"
                    v-model="currentTravel.return_plant_uuid"
                    :items="$_company_plants"
                  />
                </validation-provider>
              </div>
            </div>

            <!-- KM -->
            <div class="row mt-2 align-items-center">
              <div class="col-md-6 col-3">
                <h5 class="mb-0 new-default-black-font font-weight-400">KM</h5>
              </div>
              <div class="col-md-6 col">
                <base-input input-group-classes="input-group-sm">
                  <input
                    inputmode="numeric"
                    @change="changedDistance"
                    v-on:input="travelCycle.return_distance = formatString($event.target.value, '0', '9999999')"
                    :disabled="currentTravel.was_billed"
                    v-model="travelCycle.return_distance"
                    class="form-control form-control-sm"
                  />
                  <template slot="append">
                    <small class="input-group-sm p-0 m-0"> km </small>
                  </template>
                </base-input>
              </div>
            </div>
          </section>

          <!-- Resumo -->
          <section v-show="tab_value === 'resume'">
            <div class="row mb-4 mt-5">
              <div class="col-6 show-md" />
              <div class="col-3 show-mobile" />
              <div class="col-md-3 col align-items-center d-flex">
                <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 mt-1">Previsto</h5>
              </div>
              <div class="col-md-3 col align-items-center d-flex">
                <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 mt-1">Realizado</h5>
              </div>
            </div>

            <!-- Tempo de carregamento -->
            <div class="row align-items-center mb-3">
              <div class="col-md-6 col-3">
                <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 ml-md-1">Tempo de carreg.</h5>
              </div>
              <div class="col-md-3 col">
                <div class="new-minicard-light rounded">
                  <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 fs-11-5">
                    {{ currentTravel.load_truck_diff }}
                  </h5>
                </div>
              </div>
              <div class="col-md-3 col">
                <div class="new-minicard-blue rounded">
                  <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 fs-11-5">
                    {{ provideTimes.load_truck_diff }}
                  </h5>
                </div>
              </div>
              <div class="col-12 mt-3">
                <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black m-0 mx-1" />
              </div>
            </div>

            <!-- Ciclo de viagem -->
            <div class="row align-items-center mb-3">
              <div class="col-md-6 col-3">
                <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 ml-md-1">Ciclo de viagem</h5>
              </div>
              <div class="col-md-3 col">
                <div class="new-minicard-light rounded">
                  <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 fs-11-5">
                    {{ currentTravel.travel_cycle }}
                  </h5>
                </div>
              </div>
              <div class="col-md-3 col">
                <div class="new-minicard-blue rounded">
                  <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 fs-11-5">
                    {{ provideTimes.travel_cycle }}
                  </h5>
                </div>
              </div>
              <div class="col-12 mt-3">
                <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black m-0 mx-1" />
              </div>
            </div>

            <!-- Espera para início da descarga -->
            <div class="row align-items-center mb-3">
              <div class="col-md-6 col-3">
                <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 ml-md-1">
                  Espera p/ início da descarga
                </h5>
              </div>
              <div class="col-md-3 col">
                <div class="new-minicard-light rounded">
                  <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 fs-11-5">N/A</h5>
                </div>
              </div>
              <div class="col-md-3 col">
                <div class="new-minicard-blue rounded">
                  <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 fs-11-5">N/A</h5>
                </div>
              </div>
              <div class="col-12 mt-3">
                <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black m-0 mx-1" />
              </div>
            </div>

            <!-- Tempo de descarga -->
            <div class="row align-items-center mb-3">
              <div class="col-md-6 col-3">
                <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 ml-md-1">
                  Tempo de descarga
                </h5>
              </div>
              <div class="col-md-3 col">
                <div class="new-minicard-light rounded">
                  <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 fs-11-5">N/A</h5>
                </div>
              </div>
              <div class="col-md-3 col">
                <div class="new-minicard-blue rounded">
                  <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 fs-11-5">N/A</h5>
                </div>
              </div>
              <div class="col-12 mt-3">
                <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black m-0 mx-1" />
              </div>
            </div>

            <!-- Ciclo de concreto -->
            <div class="row align-items-center mb-3">
              <div class="col-md-6 col-3">
                <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 ml-md-1">
                  Ciclo de concreto
                </h5>
              </div>
              <div class="col-md-3 col">
                <div class="new-minicard-light rounded">
                  <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 fs-11-5">
                    {{ currentTravel.concrete_cycle }}
                  </h5>
                </div>
              </div>
              <div class="col-md-3 col">
                <div class="new-minicard-blue rounded">
                  <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 fs-11-5">
                    {{ provideTimes.concrete_cycle }}
                  </h5>
                </div>
              </div>
              <div class="col-12 mt-3">
                <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black m-0 mx-1" />
              </div>
            </div>

            <!-- Permanência em obra -->
            <div class="row align-items-center mb-3">
              <div class="col-md-6 col-3">
                <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 ml-md-1">
                  Permanência em obra
                </h5>
              </div>
              <div class="col-md-3 col">
                <div class="new-minicard-light rounded">
                  <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 fs-11-5">
                    {{ currentTravel.construction_stay_time }}
                  </h5>
                </div>
              </div>
              <div class="col-md-3 col">
                <div class="new-minicard-blue rounded">
                  <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 fs-11-5">
                    {{ provideTimes.construction_stay_time }}
                  </h5>
                </div>
              </div>
              <div class="col-12 mt-3">
                <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black m-0 mx-1" />
              </div>
            </div>

            <!-- Lastro -->
            <div class="row align-items-center mb-3">
              <div class="col-md-6 col-3">
                <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 ml-md-1">Lastro</h5>
              </div>
              <div class="col-md-3 col">
                <div class="new-minicard-light rounded">
                  <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 fs-11-5">
                    {{ currentTravel.ballast }}
                  </h5>
                </div>
              </div>
              <div class="col-md-3 col">
                <div class="new-minicard-blue rounded">
                  <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 fs-11-5">
                    {{ provideTimes.ballast }}
                  </h5>
                </div>
              </div>
              <div class="col-12 mt-3">
                <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black m-0 mx-1" />
              </div>
            </div>

            <!-- Trajeto (Ida) -->
            <div class="row align-items-center mb-3">
              <div class="col-md-6 col-3">
                <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 ml-md-1">Trajeto (Ida)</h5>
              </div>
              <div class="col-md-3 col">
                <div class="new-minicard-light rounded">
                  <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 fs-11-5">
                    {{ currentTravel.send_route_time }}
                  </h5>
                </div>
              </div>
              <div class="col-md-3 col">
                <div class="new-minicard-blue rounded">
                  <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 fs-11-5">
                    {{ provideTimes.send_route_time }}
                  </h5>
                </div>
              </div>
              <div class="col-12 mt-3">
                <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black m-0 mx-1" />
              </div>
            </div>

            <!-- Trajeto Retorno -->
            <div class="row align-items-center mb-3">
              <div class="col-md-6 col-3">
                <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 ml-md-1">Trajeto Retorno</h5>
              </div>
              <div class="col-md-3 col">
                <div class="new-minicard-light rounded">
                  <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 fs-11-5">
                    {{ currentTravel.return_route_time }}
                  </h5>
                </div>
              </div>
              <div class="col-md-3 col">
                <div class="new-minicard-blue rounded">
                  <h5 style="line-height: normal !important" class="mb-0 new-default-black-font font-weight-400 fs-11-5">
                    {{ provideTimes.return_route_time }}
                  </h5>
                </div>
              </div>
              <div class="col-12 mt-3">
                <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black m-0 mx-1" />
              </div>
            </div>
          </section>

          <!-- Anexo -->
          <section v-show="tab_value === 'attachment'">
            <!-- Foto do documento -->
            <div class="row mt-5">
              <label class="col-12 new-default-black-font mb-3 d-flex align-items-center"> Foto do documento </label>
              <div class="col-12">
                <InputFile @input="travelCycle.id && storeAttachment(false)" v-show="!loading" v-model="schedule_travel_cycle_attachment" :multiple="false" ref="scheduleTravelCycleFile" />
                <SkeletonPuzlFullWidth v-show="loading" />
              </div>
            </div>
            <!-- Observações -->
            <div class="row mt-4" v-if="$_general_settings.disable_charging_without_attachment && $_operational_permission.is_user_allowed_justify_travel_without_attachment">
              <div class="col-12">
                <label class="new-default-black-font mb-2 d-flex align-items-center"> Justificar falta do documento? </label>
                <base-switch
                  @input="verifyPermissionAttachmentJustification"
                  v-model="travelCycle.attachment_justify"
                  type="success"
                  offText="Não"
                  onText="Sim"
                  class="success"
                ></base-switch>
              </div>
              <div class="col-12" v-show="travelCycle.attachment_justify">
                <textarea :disabled="!travelCycle.attachment_justify" placeholder="Informe a justificativa" maxlength="255" v-model="travelCycle.attachment_justification" type="text" rows="3" class="form-control form-control-sm" />
              </div>
              <div class="col-12 mt-2" v-show="travelCycle.attachment_justify">
                <label class=" new-default-black-font mb-2 d-flex align-items-center"> Responsável </label>
                <PuzlSelect
                  :clearable="false"
                  v-model="travelCycle.attachment_responsible"
                  :items="[{id: 1, name: 'Cliente'}, {id: 2, name: 'Motorista'}, {id: 3, name: 'Central'}]"
                />
              </div>
            </div>
            <!-- Observações -->
            <div class="row mt-4">
              <label class="col-12 new-default-black-font mb-2 d-flex align-items-center"> Observações </label>
              <div class="col-12">
                <textarea placeholder="Se necessário, adicione uma observação" maxlength="255" v-model="travelCycle.observations" type="text" rows="3" class="form-control form-control-sm" />
              </div>
            </div>
          </section>

          <!-- Botões de ação -->
          <section>
            <div class="row mt-5 justify-content-end mr-md-n2 mb-3">
              <div class="col-md-3 col-12 px-md-2">
                <base-button
                  @click="closeModal"
                  class="d-flex align-items-center text-uppercase py-2"
                  outline
                  size="sm"
                  block
                  type="danger"
                >
                  <i class="far fa-times-circle fa-2x mr-2" />
                  <span class="fs-13">Cancelar </span>
                </base-button>
              </div>
              <div class="col-md-3 col-12 mt-2 mt-md-0 px-md-2">
                <base-button
                  :disabled="loading || invalid"
                  class="d-flex align-items-center text-uppercase py-2"
                  outline
                  size="sm"
                  block
                  type="success"
                  @click.prevent="save(1)"
                >
                  <i class="fas fa-save fa-2x mr-2" />
                  <span class="fs-13">Salvar </span>
                </base-button>
              </div>
              <div class="col-md-3 col-12 mt-2 mt-md-0 px-md-2">
                <base-button
                  :disabled="loading || invalid || currentTravel.was_billed !== false || currentTravel.status === 6"
                  class="d-flex align-items-center text-uppercase py-2"
                  outline
                  size="sm"
                  block
                  type="primary"
                  @click.prevent="save(0)"
                >
                  <i class="fas fa-check fa-2x mr-2" />
                  <span class="fs-13">Concluir </span>
                </base-button>
              </div>
            </div>
          </section>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Navegation from "./_Navegation.vue";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import InputTimePicker from "@/components/InputTimePickerV2";
import PuzlSelect from "@/components/PuzlSelect";
import InputFile from "@/components/Utils/InputFile";
import moment from "moment";
import { mask1, formatErrorValidation, formatDate } from "@/plugins";
import { checkNumberValue } from "@/helpers";
import SkeletonPuzlFullWidth from "@/components/SkeletonPuzlFullWidth";

export default {
  name: "ModalNewTravelCycle",
  components: {
    Navegation,
    InputDateTimePicker,
    InputTimePicker,
    PuzlSelect,
    InputFile,
    SkeletonPuzlFullWidth,
  },
  data() {
    return {
      modal: false,
      title: "Ciclo de Viagem",
      tab_value: "entry",
      loading: false,
      fields_with_active_checkbox: {
        plant_exit_time: true,
        arrival_time: true,
        start_discharge_time: true,
        end_discharge_time: true,
        construction_exit_time: true,
        plant_arrival_time: true,
        free_for_travel: true,
      },
      currentTravel: {
        uuid: null,
        load_truck_time: null,
        end_load_truck_time: null,
        plant_exit_time: null,
        arrival_time: null,
        construction_exit_time: null,
        plant_arrival_time: null,
        free_for_travel: null,
        number: null,
        number_created_at: null,
        xml: null,
        finished_at: null,
        status: null,
        should_automatically_fill: null,
        accomplished_start_load_truck_time: null,
        accomplished_end_load_truck_time: null,
        send_plant: null,
        return_plant: null,
        was_billed: null,
        start_load_truck_time: null,
        updated_by_user_name: null,
        load_truck_diff: "00:00",
        travel_cycle: "00:00",
        concrete_cycle: "00:00",
        construction_stay_time: "00:00",
        ballast: "00:00",
        send_route_time: "00:00",
        return_route_time: "00:00",
      },
      travelCycle: {
        load_truck_time: null,
        end_load_truck_time: null,
        plant_exit_time: null,
        arrival_time: null,
        start_discharge_time: null,
        start_load_truck_time: null,
        end_discharge_time: null,
        construction_exit_time: null,
        plant_arrival_time: null,
        free_for_travel: null,
        travelled_distance: 0,
        send_distance: null,
        return_distance: null,
        observations: null,
        updated_at: null,
        attachment_justify: null,
        attachment_justification: null,
        attachment_responsible: null,
      },
      types: [
        "Início do Carregamento",
        "Término de carregamento",
        "Saída da central",
        "Chegada na obra",
        "Início de descarga",
        "Término de descarga",
        "Saída da obra",
        "Chegada na central",
        "Liberado para novo carregamento",
      ],
      provideTimes: {
        load_truck_diff: "00:00",
        travel_cycle: "00:00",
        wait_start_discharge: "00:00",
        discharge_time: "00:00",
        concrete_cycle: "00:00",
        construction_stay_time: "00:00",
        ballast: "00:00",
        send_route_time: "00:00",
        return_route_time: "00:00",
      },
      schedule_travel_status: {
        0: "released", // liberado
        1: "finished", // concluído
        2: "loading", // carregando
        4: "waiting_for_release", // aguardando liberação
        5: "in_progress", // em andamento
        6: "canceled", // cancelado
        7: "unrealized", // não realizado
        8: "has_pendency", // tem pendência
        9: "seller_request", // solicitação de vendedor
      },
      loadingOperationalPermission: true,
      automatic_filling_fields: [
        "load_truck_time",
        "end_load_truck_time",
        "plant_exit_time",
        "arrival_time",
        "start_discharge_time",
        "end_discharge_time",
        "construction_exit_time",
        "plant_arrival_time",
        "free_for_travel",
      ],
      schedule_travel_cycle_attachment: null,
      old_schedule_travel_cycle_attachment: null,
    };
  },

  computed: {
    ...mapGetters({
      $_operational_permission: "operationalPermission/show",
      $_company_plants: "plant/activeItems",
      $_general_settings: "generalSetting/show",
    }),
    disableDate() {
      return this.travelCycle.end_load_truck_time ? new Date(this.travelCycle.end_load_truck_time.slice(0, -8)) : null;
    },
  },

  methods: {
    closeModal() {
      this.modal = false;
    },

    async openModal(uuid) {
      const loader = this.$loading.show();
      this.loading = true;
      this.schedule_travel_cycle_attachment = null;
      this.old_schedule_travel_cycle_attachment = null;
      this.loadingOperationalPermission = true;
      await this.$store
        .dispatch("generalSetting/show").then(response => {
        })
      this.tab_value = "entry";
      this.provideTimes = {
        load_truck_diff: "00:00",
        travel_cycle: "00:00",
        wait_start_discharge: "00:00",
        discharge_time: "00:00",
        concrete_cycle: "00:00",
        construction_stay_time: "00:00",
        ballast: "00:00",
        send_route_time: "00:00",
        return_route_time: "00:00",
      };

      // Reseta os campos com checkbox de horário ativo
      this.fieldsWithActiveCheckboxDisable();

      this.$store.dispatch("travels/show", {uuid: uuid, code: "1"}).then(async (response) => {
        this.currentTravel = response.data;
        this.currentTravel.send_plant_uuid = response.data.send_plant.uuid;
        this.currentTravel.return_plant_uuid = response.data.return_plant.uuid;
        this.travelCycle = this.currentTravel.cycle;

        if (!this.travelCycle) {
          this.travelCycle = {
            start_load_truck_time: null,
            load_truck_time: null,
            end_load_truck_time: null,
            plant_exit_time: null,
            arrival_time: null,
            start_discharge_time: null,
            end_discharge_time: null,
            construction_exit_time: null,
            plant_arrival_time: null,
            free_for_travel: null,
            travelled_distance: 0,
            send_distance: null,
            return_distance: null,
            observations: null,
            updated_by_user_name: null,
            updated_at: null,
          };
          this.loading = false;
        } else {
          this.$store.dispatch("scheduleTravelCycleAttachment/show", this.travelCycle.id).then((response) => {
            this.schedule_travel_cycle_attachment = response.data;
            this.old_schedule_travel_cycle_attachment = response.data;
            response.data && this.$refs.scheduleTravelCycleFile.setFileByObject(response.data, true);
            this.loading = false;
          });
        }

        /** Se não tiver setado o incio do carregamento mas já tenha iniciado na tela de carregamento
         * setamos aqui.
         */
        if (!this.travelCycle.start_load_truck_time && this.currentTravel.accomplished_start_load_truck_time) {
          this.travelCycle.load_truck_time = this.currentTravel.accomplished_start_load_truck_time;
        }

        /** Se não tiver o fim do carregamento mas já tenha finalizado na tela de carregamento
         * setamos aqui.
         */
        if (!this.travelCycle.end_load_truck_time && this.currentTravel.accomplished_end_load_truck_time) {
          this.travelCycle.end_load_truck_time = this.currentTravel.accomplished_end_load_truck_time;
        }

        this.travelCycle.load_truck_time = this.travelCycle.load_truck_time
          ? this.travelCycle.load_truck_time
          : this.travelCycle.start_load_truck_time;

        this.calcDates();
        this.fieldsWithActiveCheckboxEnable();
        this.modal = true;
        loader.hide();
      });

      this.$store.dispatch("operationalPermission/getByUserId", this.$user.id).then(() => (this.loadingOperationalPermission = false));
    },

    fieldsWithActiveCheckboxDisable() {
      this.fields_with_active_checkbox = {
        plant_exit_time: false,
        arrival_time: false,
        start_discharge_time: false,
        end_discharge_time: false,
        construction_exit_time: false,
        plant_arrival_time: false,
        free_for_travel: false,
      };
    },

    fieldsWithActiveCheckboxEnable() {
      this.fields_with_active_checkbox = {
        plant_exit_time: true,
        arrival_time: true,
        start_discharge_time: true,
        end_discharge_time: true,
        construction_exit_time: true,
        plant_arrival_time: true,
        free_for_travel: true,
      };
    },

    calcDates() {
      this.changeArrivalTime();
      this.changeStartLoadTruck();
      this.changePlantArrival();
    },

    changeArrivalTime() {
      this.provideTimes.construction_stay_time = this.dateDiff(this.travelCycle.arrival_time, this.travelCycle.construction_exit_time);
      this.provideTimes.wait_start_discharge = this.dateDiff(this.travelCycle.arrival_time, this.travelCycle.start_discharge_time);
      this.provideTimes.send_route_time = this.dateDiff(this.travelCycle.plant_exit_time, this.travelCycle.arrival_time);
    },

    changeStartLoadTruck() {
      if (
        this.travelCycle.load_truck_time &&
        this.travelCycle.end_load_truck_time &&
        this.travelCycle.load_truck_time.slice(0, -2) !== this.travelCycle.end_load_truck_time.slice(0, -2)
      ) {
        this.provideTimes.load_truck_diff = this.dateDiff(this.travelCycle.load_truck_time, this.travelCycle.end_load_truck_time);
      } else {
        this.provideTimes.load_truck_diff = "0 min";
      }
      this.provideTimes.travel_cycle = this.dateDiff(this.travelCycle.load_truck_time, this.travelCycle.free_for_travel);
      this.provideTimes.concrete_cycle = this.dateDiff(this.travelCycle.load_truck_time, this.travelCycle.end_discharge_time);
    },

    changePlantArrival() {
      this.provideTimes.ballast = this.dateDiff(this.travelCycle.plant_arrival_time, this.travelCycle.free_for_travel);
      this.provideTimes.return_route_time = this.dateDiff(this.travelCycle.construction_exit_time, this.travelCycle.plant_arrival_time);
      this.provideTimes.discharge_time = this.dateDiff(this.travelCycle.start_discharge_time, this.travelCycle.end_discharge_time);
    },

    changeFreeForTravel() {
      this.provideTimes.travel_cycle = this.dateDiff(this.travelCycle.load_truck_time, this.travelCycle.free_for_travel);
      this.provideTimes.ballast = this.dateDiff(this.travelCycle.plant_arrival_time, this.travelCycle.free_for_travel);
    },

    dateDiff(first, second) {
      if (first && first.length > 18 && second && second.length > 18) {
        return this.$helper.dateDiff(first, second);
      }
      return "00:00";
    },

    /**
     *  Realizada o preenchimento automático de horários
     */
    automaticallyFill() {
      /* Se não atende as condições retorna false. */
      if (
        this.currentTravel.status !== 5 ||
        !this.currentTravel.should_automatically_fill ||
        this.currentTravel.should_automatically_fill !== 1
      ) {
        return;
      }
      let date = null;
      let time_to_add = 0;

      /* Saída da central = término do carregamento + (saída da central prevista - término do carregamento previsto) */
      if (this.travelCycle.end_load_truck_time) {
        date = moment(this.travelCycle.end_load_truck_time);
        time_to_add = this.$helper.dateDiff(
          formatDate(this.currentTravel.end_load_truck_time),
          formatDate(this.currentTravel.plant_exit_time),
          false
        );
        this.travelCycle.plant_exit_time = date.add(time_to_add, "seconds").format("YYYY-MM-DD HH:mm:ss");
      }

      /* Chegada na obra = saída da central + (chegada na obra prevista - saída da central prevista) */
      if (this.travelCycle.plant_exit_time) {
        date = moment(this.travelCycle.plant_exit_time);
        time_to_add = this.$helper.dateDiff(
          formatDate(this.currentTravel.plant_exit_time),
          formatDate(this.currentTravel.arrival_time),
          false
        );
        this.travelCycle.arrival_time = date.add(time_to_add, "seconds").format("YYYY-MM-DD HH:mm:ss");
      }

      const construction_stay_time = this.$helper.dateDiff(
        formatDate(this.currentTravel.arrival_time),
        formatDate(this.currentTravel.construction_exit_time),
        false
      );

      /* Ínicio da descarga = chegada na obra + tempo de permanência em obra / 3 */
      if (this.travelCycle.arrival_time) {
        date = moment(this.travelCycle.arrival_time);
        this.travelCycle.start_discharge_time = date.add(construction_stay_time / 3, "seconds").format("YYYY-MM-DD HH:mm:ss");
      }

      /* Saída da obra = inicio da descarga + tempo de permanência em obra / 3 */
      if (this.travelCycle.start_discharge_time) {
        date = moment(this.travelCycle.start_discharge_time);
        this.travelCycle.end_discharge_time = date.add(construction_stay_time / 3, "seconds").format("YYYY-MM-DD HH:mm:ss");
      }

      /* Saída da obra = fim da descarga + tempo de permanência em obra * 2/3 /*/
      if (this.travelCycle.end_discharge_time) {
        date = moment(this.travelCycle.end_discharge_time);
        this.travelCycle.construction_exit_time = date
          .add(construction_stay_time - construction_stay_time / 3, "seconds")
          .format("YYYY-MM-DD HH:mm:ss");
      }

      /* Chegada na central = Saída da obra + (saída da obra previsto + chegada na central previsto -) */
      if (this.travelCycle.construction_exit_time) {
        date = moment(this.travelCycle.construction_exit_time);
        time_to_add = this.$helper.dateDiff(
          formatDate(this.currentTravel.construction_exit_time),
          formatDate(this.currentTravel.plant_arrival_time),
          false
        );
        this.travelCycle.plant_arrival_time = date.add(time_to_add, "seconds").format("YYYY-MM-DD HH:mm:ss");
      }

      /* Liberado para novo carregamento = Chegada na central + (chegada na central previsto - liberado para novo carregamento previsto) */
      if (this.travelCycle.plant_arrival_time) {
        date = moment(this.travelCycle.plant_arrival_time);
        time_to_add = this.$helper.dateDiff(
          formatDate(this.currentTravel.plant_arrival_time),
          formatDate(this.currentTravel.free_for_travel),
          false
        );
        this.travelCycle.free_for_travel = date.add(time_to_add, "seconds").format("YYYY-MM-DD HH:mm:ss");
      }
    },

    /**Verifica se entidade está apta para edição */
    enableEdit(travel) {
      return (travel.status !== 1 && travel.status !== 5) || travel.was_billed !== false;
    },

    /* Caso o horário selecionado pelo usuário seja válido adicionamos a data do carregamento */
    addDate(value) {
      if (value.includes("m") === false && value.includes("H") === false && value !== "") {
        return `${this.travelCycle.end_load_truck_time.slice(0, -8)}${value}:00`;
      }
      return value !== "" ? value : null;
    },

    changeStartDischarge() {
      this.provideTimes.wait_start_discharge = this.dateDiff(this.travelCycle.arrival_time, this.travelCycle.start_discharge_time);
      this.provideTimes.discharge_time = this.dateDiff(this.travelCycle.start_discharge_time, this.travelCycle.end_discharge_time);
    },

    changeEndDischarge() {
      this.provideTimes.discharge_time = this.dateDiff(this.travelCycle.start_discharge_time, this.travelCycle.end_discharge_time);
      this.provideTimes.concrete_cycle = this.dateDiff(this.travelCycle.load_truck_time, this.travelCycle.end_discharge_time);
    },

    changeConstructionExitTime() {
      this.provideTimes.construction_stay_time = this.dateDiff(this.travelCycle.arrival_time, this.travelCycle.construction_exit_time);
      this.provideTimes.return_route_time = this.dateDiff(this.travelCycle.construction_exit_time, this.travelCycle.plant_arrival_time);
    },

    changedDistance() {
      if (this.travelCycle.send_distance && this.travelCycle.return_distance) {
        let value = parseInt(this.travelCycle.return_distance) - parseInt(this.travelCycle.send_distance);
        value = value < 0 ? 0 : value;
        this.travelCycle.travelled_distance = value;
      }
    },

    formatString(value, min, max, decimals = "") {
      if (decimals) {
        value = mask1(checkNumberValue(value, min, max).toString(), decimals, ".", true);
        return checkNumberValue(value, min, max);
      }
      return checkNumberValue(value, min, max);
    },

    async save(only_save = 1) {
      if (this.travelCycle.attachment_justify && !this.travelCycle.attachment_justification) {
        return this.$swal.fire({
          icon: 'error',
          title: 'Aviso',
          text: `Favor preencher a justificativa para continuar.`,
        })
      }
      /* Caso ciclo da viagem tenha todos os campos e usuário tente apenas salvar, o ciclo será concluído */
      if (only_save === 1 && !this.travelCycleHasNullField() && this.schedule_travel_status[this.currentTravel.status] !== "canceled") {
        only_save = 0;
      }
      /** Caso o ciclo da viagem seja superior a 6 horas, exigir confirmação do usuário */
      let high_cycle = await this.checkTotalTravelCycleHours();
      if (high_cycle === false) {
        return false;
      }

      this.loading = true;
      let invalid = await this.checkCycleValue();
      if (invalid) {
        this.$notify({
          type: "danger",
          message: "O campo " + this.types[invalid[0]] + " não pode ser menor ou igual ao campo " + this.types[invalid[1]],
        });
        this.loading = false;
        return false;
      }
      let travel = JSON.parse(JSON.stringify(this.currentTravel));
      travel = this.finalFormat(travel);
      let params = {
        travel: travel,
        travel_cycle: this.travelCycle,
        only_save: only_save,
      };
      if (params.travel_cycle.load_truck_time) {
        params.travel_cycle.start_load_truck_time = params.travel_cycle.load_truck_time;
      } else {
        params.travel_cycle.start_load_truck_time = null;
      }
      const loader = this.$loading.show();
      this.$store
        .dispatch("travels/updateCycle", params)
        .then(async (response) => {
          await this.storeAttachment();
          this.loading = false;
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.$emit("updatedCycle");
          this.closeModal();
        })
        .catch((error) => {
          if (error.status == 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
        })
        .finally(() => {
          this.loading = false;
          loader.hide();
        });
    },

    /**
     * Informa se há campo nulo no ciclo de viagem
     *
     * @returns {boolean}
     */
    travelCycleHasNullField() {
      let hasNullField = false;
      this.automatic_filling_fields.map((field) => {
        if (!this.travelCycle[field]) {
          hasNullField = true;
        }
      }, this);
      return hasNullField;
    },

    finalFormat(travel) {
      travel.arrival_time = formatDate(travel.arrival_time);
      travel.construction_exit_time = formatDate(travel.construction_exit_time);
      travel.free_for_travel = formatDate(travel.free_for_travel);
      travel.load_truck_time = formatDate(travel.load_truck_time);
      delete this.travelCycle.id;
      travel.new_plant = 0;

      travel.company_plants.map(function (obj) {
        if (!obj.max_volume) {
          obj.max_volume = travel.max_volumes_per_travel ? travel.max_volumes_per_travel : 12;
        }
      }, this);

      this.travelCycle.travel_uuid = this.currentTravel.uuid;
      return travel;
    },

    /* Função verifica se o ciclo respeita a ordem crescente de horários
       e caso não respeite retorna os index's encontrados com falha */
    checkCycleValue() {
      let main_index = false;
      this.automatic_filling_fields.map(function (base_field, base_index) {
        if (base_index > 0 && this.travelCycle[base_field]) {
          let base_minutes = this.timestampToMinutes(this.travelCycle[base_field]);
          for (let i = 0; i < base_index; i++) {
            let next_field_minutes = this.timestampToMinutes(this.travelCycle[this.automatic_filling_fields[i]]);
            // se não for a última comparaçao (liberado para novo carregamento e chegada na central).
            if ((base_index !== 8 || i !== 7) && base_minutes <= next_field_minutes) {
              main_index = [base_index, i];
              break;
            } else if (base_minutes < next_field_minutes) {
              main_index = [base_index, i];
              break;
            }
          }
        }
      }, this);
      return main_index;
    },
    timestampToMinutes(timestamp) {
      return timestamp ? moment.duration(moment(timestamp.slice(0, -3))).asMinutes() : "";
    },

    /* Confirma com o usuário se deve ser salvo um ciclo de viagem superior a 6 horas */
    checkTotalTravelCycleHours() {
      /** Obtem ciclo de viagem em horas */
      if (!this.travelCycle.free_for_travel || !this.travelCycle.load_truck_time) {
        return true;
      }
      let duration = moment
        .duration(moment(this.travelCycle.free_for_travel.slice(0, -3)).diff(this.travelCycle.load_truck_time.slice(0, -3)))
        .asHours();
      if (duration > 6) {
        return this.$swal
          .fire({
            title: "Você tem certeza?",
            text: "Atenção, ciclo total da viagem está muito alto!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Continuar!",
            cancelButtonText: "Cancelar",
            customClass: {
              confirmButton: "btn btn-success btn-fill",
              cancelButton: "btn btn-danger btn-fill",
            },
            buttonsStyling: false,
          })
          .then(async (result) => {
            return await result.isConfirmed;
          })
          .catch(() => {
            return false;
          });
      }
    },
    verifyPermissionAttachmentJustification() {
      if (! this.$_operational_permission.is_user_allowed_justify_travel_without_attachment) {
          this.travelCycle.attachment_justify = false
        return this.$swal.fire({
          icon: 'error',
          title: 'Aviso',
          text: `Usuário não possui permissão para justificar viagem sem anexo`,
        })
      }
    },
    async storeAttachment(on_save = true) {
      if (this.schedule_travel_cycle_attachment !== this.old_schedule_travel_cycle_attachment) {
        const loader = this.$loading.show();
        // Caso seja a primeira vez a ser salvo o ciclo da viagem.
        if(on_save && ! this.travelCycle.id) {
          const response = await this.$store.dispatch("travels/show", { uuid: this.currentTravel.uuid, code: "1" }); // Busca o ciclo de viagem com resource específico
          this.travelCycle = response.data.cycle;
        }
        const formData = new FormData();
        this.schedule_travel_cycle_attachment && formData.append("attachment", this.schedule_travel_cycle_attachment.file);
        formData.append("schedule_travel_cycle_id", this.travelCycle.id);
        return this.$store.dispatch("scheduleTravelCycleAttachment/updateOrCreate", formData)
        .then(() => {
          this.old_schedule_travel_cycle_attachment = this.schedule_travel_cycle_attachment;
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            const errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
        })
        .finally(() => {
          if(! on_save) {
            this.$emit('updatedCycle');
          }
          loader.hide();
        });
      }
    },
  },
};
</script>

<style scoped>
.new-minicard-red {
  background-color: #fcf3f3;
  padding: 12px 10px;
}

.new-minicard-light {
  background-color: #e8e8e8;
  padding: 7.5px 8px;
}

.new-minicard-blue {
  background-color: #f2f4f9;
  padding: 7.5px 8px;
}

.fs-11-5 {
  font-size: 11.5px !important;
}
</style>
