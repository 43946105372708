<template>
    <div>
        <div class="container-fluid">
            <multi-filter ref="multi-filter" @fetch="init" :filter="filter" :status="multi_filter" />
            <div class="row card-wrapper" v-show="loadingSkeleton">
                <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
            </div>

            <div class="row align-items-center" v-if="!equipments.length && !loadingSkeleton">
                <div class="col-md-12 text-center">
                    <div class="card p-4">
                        Sem dados cadastrados!
                    </div>
                </div>
            </div>

            <div class="row card-wrapper" v-if="equipments.length && !loadingSkeleton">
                <div class="col-lg-4" v-for="(equipment, index) in equipments" :key="index">
                    <div class="card">
                        <div class="card-header pointer" style="padding: 0px !important; height: 22px">
                            <base-dropdown style="width: 100%" menuOnRight>
								<base-button 
									size="sm" 
									slot="title-container"
									class="btn-header dropdown-toggle text-white text-uppercase"
									:style="equipment.status ? 'background: #149E57' : 'background: #DB4539'"
									style="opacity: 92%;color: #4f40ff" 
									block
								>
									{{ equipment.situation.situation_name }}
								</base-button>
								<div v-for="(situation, index) in $_situations" :key="index">
									<a 
										@click.prevent="updateSituation(equipment.id, situation.id)"
										class="dropdown-item text-uppercase"
									> 
										{{ situation.situation_name }}
									</a>
								</div>
							</base-dropdown>
                        </div>
                        <div class="card-body">
                            <!-- Card header -->
                            <div class="row align-items-center mb-2">
                                <div class="col-8 mt-n2 ml-n2">
                                    <!-- Alocaçao -->
                                    <el-popover placement="right" class="p-0">
                                        <div class="row align-items-center pb-0">
                                            <div class="col-md-12">
                                                <div class="checklist-item checklist-item-primary">
                                                    <div class="checklist-info">
                                                        <h4 
                                                            class="checklist-title mt-2"
                                                            v-for="(allocation, indexAllocation) in equipment.allocation"
                                                            :key="indexAllocation"
                                                        >
                                                            {{ allocation.name }}
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <base-button outiline slot="reference" size="sm" type="secundary"
                                            class="p-0 m-0 ml-2 mt-n1 btn-rounded">
                                            <img src="/img/icons/chemical-plant-black.png" width="26px" height="26px" />
                                        </base-button>
                                    </el-popover>
                                    <!-- Detalhes -->
                                    <el-popover placement="right" class="p-0">
                                        <h5 slot="title" class="mb-0 ">Detalhes</h5>

                                        <h5 class="h3 mb-0">
                                            {{ equipment.brand || '' }} &nbsp;({{ equipment.predominant_color || '' }})
                                        </h5>
                                        <h5 class="h3 mb-0 small">
                                            {{ equipment.model || '' }} ({{ equipment.equipment_manufacture_year ||
                                                '' }}/{{ equipment.equipment_year }})
                                        </h5>
                                        <h5 class="h3 mb-0 mt-2">
                                            {{ equipment.chassis || '' }}
                                        </h5>
                                        <h5 class="h3 mb-0 small">
                                            {{ equipment.vehicle_permit_code || '' }}
                                        </h5>

                                        <h5 class="h3 mb-0 mt-2">
                                            {{ equipment.vehicle_license_name || '' }}
                                        </h5>
                                        <h5 class="h3 mb-0 small">
                                            {{ equipment.vehicle_license_legal_entity || '' }}
                                        </h5>
                                        <base-button outiline slot="reference" size="sm" type="secundary"
                                            class="p-0 m-0 ml-2 mt-n1 btn-rounded">
                                            <img src="/img/icons/info.png" width="26px" height="26px" />
                                        </base-button>
                                    </el-popover>
                                    <!-- Terceiros -->
                                    <el-popover v-if="equipment.entity_name" placement="right" class="p-0">
                                        {{ equipment.entity_name }} <br>
                                        {{ equipment.entity_document }}
                                        <base-button outiline slot="reference" size="sm" type="secundary"
                                            class="p-0 m-0 ml-2 mt-n1 btn-rounded">
                                            <img src="/img/icons/salesman.png" width="26px" height="26px" />
                                        </base-button>
                                    </el-popover>
                                </div>

                                <div class="col-4 text-right mt-n2 ml-2">
                                    <base-dropdown menuOnRight>
                                        <base-button slot="title-container" type="primary"
                                            class="dropdown-toggle p-2 rounded m-0">
                                            <i class="fas fa-cog"></i>
                                        </base-button>
                                        <a class="dropdown-item" @click="handleAllocation(equipment.uuid)">
                                            <i class="fas fa-building text-purple"></i>
                                            Alocação de Central
                                        </a>
                                        <div class="dropdown-divider p-0 m-0" />
                                        <a class="dropdown-item"
                                            @click.prevent="handleCreateModalSearchEntity(equipment.id)">
                                            <img src="/img/icons/salesman.png" width="21px" height="21px" />
                                            Responsável terceiro
                                        </a>
                                        <div class="dropdown-divider p-0 m-0" />
                                        <router-link :to="{
                                            path: '/equipment/equipment/implements',
                                            name: 'equipment.implement.index',
                                            params: { equipment_uuid: equipment.uuid }
                                        }">
                                            <a class="dropdown-item">
                                                <i class="fas fa-truck-loading text-default"></i>
                                                Implementos
                                            </a>
                                        </router-link>
                                        <div class="dropdown-divider p-0 m-0" />
                                        <a class="dropdown-item" @click.prevent="handleBuySell(equipment.uuid)">
                                            <i class="fas fa-list text-info"></i>
                                            Dados de Compra / Venda
                                        </a>
                                        <div class="dropdown-divider p-0 m-0"></div>
                                        <router-link :to="{
                                            path: '/equipment/equipment/edit',
                                            name: 'equipment.equipment.edit',
                                            params: { equipment_uuid: equipment.uuid }
                                        }">
                                            <a class="dropdown-item">
                                                <i class="fas fa-edit text-warning"></i>
                                                Editar
                                            </a>
                                        </router-link>
                                        <div class="dropdown-divider p-0 m-0"></div>
                                        <a class="dropdown-item" @click.prevent="handleDelete(equipment)">
                                            <i class="fas fa-times text-danger"></i>
                                            Excluir
                                        </a>
                                    </base-dropdown>
                                </div>
                            </div>
                            <!-- Card body -->
                            <h5 class="h3 mb-0">
                                {{ equipment.category.category_name || '' }}
                            </h5>
                            <h5 class="h3 mb-0 small">
                                {{ equipment.type.description || '' }}
                            </h5>
                            <!-- Linha fina na horizontal -->
                            <div class="col-12 horizontal-line mt-3 mb-2"></div>

                            <div>
                                <h5 class="h3 mb-0" style="color: #1a70b7;">
                                    {{ equipment.code || '' }}
                                </h5>
                            </div>
                            <div>
                                <h5 class="h3 mb-1 small">
                                    {{ equipment.plate || '' }}
                                </h5>
                            </div>

                            <collapse class="border rounded p-0 mt-3" v-if="equipment.implements.length > 0">
                                <collapse-item class="header-gray" back-ground="border-header-danger-bottom">
                                    <h5 slot="title" class="mb-0 ">Implementos</h5>
                                    <!-- Loop Implements -->
                                    <div>
                                        <div class="row align-items-center pb-0 mb-3"
                                            v-for="(implement, indexImplement) in equipment.implements"
                                            :key="indexImplement">
                                            <div class="col-md-12">
                                                <div class="checklist-item checklist-item-primary">
                                                    <div class="checklist-info">
                                                        <h4 class="checklist-title mb-0">
                                                            {{ implement.vehicle_description }}
                                                        </h4>
                                                        <h4 class="checklist-title mb-0 small">
                                                            {{ implement.type ? implement.type.description : '' }}
                                                        </h4>
                                                        <h4 class="checklist-title mb-0 mt-2">
                                                            {{ implement.code ? implement.code : '' }}
                                                        </h4>
                                                        <h4 class="checklist-title mb-0 small">
                                                            {{ implement.plate ? implement.plate : '' }}
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </collapse-item>
                            </collapse>
                        </div>
                    </div>
                </div>
            </div>
            <loading-pagination :show="loading && !loadingSkeleton" />
        </div>
        <ModalSetPlant ref="modalSetPlant" />
        <ModalBuySell ref="modalBuySell" />
        <ModalSetUserPermission ref="modalSetUserPermission" />
        <ModalSearchEntity @selected="storeThirdPartyResponsible" ref="modalSearchEntity" />
    </div>
</template>

<script>
import {
    mapGetters
} from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import ModalSetPlant from './_ModalSetPlant'
import ModalBuySell from './_ModalBuySell'
import ModalSetUserPermission from './_ModalBuySell'
import MultiFilter from '@/components/Utils/MultiFilterV2.vue';
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import { equipment as $_totvs } from "@/plugins/microservices/totvs";
import ModalSearchEntity from "@/views/Modules/Configuration/Entity/Shared/_ModalSearchEntity";

export default {
    name: "ListEquipment",
    mixins: [cursorPaginate],
    components: {
        LoadingPagination,
        SkeletonPuzlGrid,
        ModalSetPlant,
        ModalBuySell,
        ModalSetUserPermission,
        MultiFilter,
        ModalSearchEntity,
    },
    data() {
        return {
            loadingSkeleton: false,
            loadingSituation: true,
            filter: {},
            multi_filter: {
                null: {
                    name: "Todos",
                    type: "primary"
                },
                '0': {
                    name: "Inativo",
                    type: "danger"
                },
                '1': {
                    name: "Ativo",
                    type: "success"
                },
                '2': {
                    name: "Indisponível",
                    type: "light"
                },
            },
            equipmentId: null,
        };
    },
    mounted() {
        this.$refs['multi-filter']._self.$forceUpdate();
        this.$store.dispatch("equipmentSituation/fetchItems").then(() => {
            this.loadingSituation = false
        });
        this.init({});
    },
    computed: {
        ...mapGetters({
            equipments: "equipment/fetch",
            $_situations: "equipmentSituation/fetch",
        }),
    },
    methods: {
        /**
         * @param {number} equipmentId
         * @param {number} situationId
         */
        updateSituation(equipmentId, situationId) {
            const payload = {
                equipmentId: equipmentId,
                situationId: situationId,
            };
            this.$store.dispatch("equipment/updateSituation", payload).then(response => {
                this.$notify({
                    type: response.error_type,
                    message: response.message
                });
            }).catch((error) => {
                this.$notify({
                    type: 'danger',
                    message: error.data.message
                })
            });
        },
        init(filter = null) {
            this.startCursor(filter)
            this.$Progress.start()
            this.$store
                .dispatch("equipment/fetchItemsPaginate", {
                    filter: this.filter,
                    next_page: this.paginate.nextUrl
                })
                .then(response => {
                    this.$Progress.finish()
                    this.resolveCursor(response)
                })
                .catch(error => {
                    this.resolveCursor()
                    this.$Progress.finish()
                });
        },
        handleAllocation(uuid) {
            this.$refs.modalSetPlant.openModal(uuid, 'equipment')
        },
        handleBuySell(uuid) {
            this.$refs.modalBuySell.openModal(uuid, 'equipment')
        },
        handleSetUserPermission(userUuid) {
            this.$refs.modalSetUserPermission.openModal(userUuid)
        },
        handleDelete(equipment) {
            this.$Swal.confirmDelete().then((result) => {
                if (result.isConfirmed) {
                    this.$Progress.start();
                    this.$notify({
                        type: 'info',
                        message: 'Estamos trabalhando em sua solicitação.'
                    });
                    this.$store.dispatch('equipment/destroy', equipment.uuid).then(response => {
                        this.$notify({
                            type: 'success',
                            message: response.message
                        });
                        $_totvs.destroy(equipment.id)
                    }).catch(error => {
                        this.$notify({
                            type: 'danger',
                            message: error.data.message
                        });
                    })
                    this.$Progress.finish();
                }
            }).catch(() => this.$Progress.finish())
        },
        handleCreateModalSearchEntity(id) {
            this.equipmentId = id;
            this.$refs.modalSearchEntity.openModal();
        },
        storeThirdPartyResponsible(entity) {
            const payload = {
                equipment_id: this.equipmentId,
                entity_id: entity.id,
            }
            this.$store.dispatch("equipment/storeThirdPartyResponsible", payload).then(() => {
                this.$notify({
                    type: 'success',
                    message: 'Cliente/fornecedor cadastrado com sucesso'
                });
            }).catch(() => {
                this.$notify({
                    type: 'danger',
                    message: 'Erro ao cadastrar cliente/fornecedor'
                });
            });
        },
    },
};
</script>

<style scoped>
.main-icon-menu {
    min-width: 18px;
}

.photo {
    width: 100px !important;
    height: 100px !important;
}

.horizontal-line {
    border-bottom: 1px solid #1a70b7;
}

.card-status {
    opacity: 100%;
    color: #4f40ff;
    border: none;
    border-radius: 10px;
    text-align: center;
}

.dropdown-item:hover {
    background-color: #f6f9fc;
    color: black;
}
</style>
