<template>
  <div>
    <base-header class="bg-gray-content pr-1 pl-1">
      <div class="row align-items-center py-4">
        <div class="col-lg-4">
          <h2 style="font-size: 1.2rem !important;" class="text-primary">
            <img height="30px" src="/img/icons/physics.png" class="mr-3"/>
            DRE TÉCNICO
          </h2>
        </div>
        <div class="col-md-8">
        </div>
      </div>
    </base-header>
    <div class="container-fluid testimonial-group">
      <multi-filter :without_filter="true" :filter="filter" @fetch="init">
        <div class="col-md-3 p-2 mt-1">
          <label class="form-control-label">Data da concretagem</label>
          <input-date-picker size="sm" ref="date-picker" :default-range="range" :filterable="4"
                             @handleFilterDate="handleFilterDate"/>
        </div>
        <div class="col-md p-2">
          <base-input label="Ensaio" input-classes="form-control-sm">
            <puzl-select
              :clearable="false"
              @change="init"
              v-model="filter.hardened_state_id"
              :items="$_hardened_states"
              label="short_description"
            />
          </base-input>
        </div>
        <div class="col-md p-2">
          <base-input label="Responsabilidade" input-classes="form-control-sm">
            <puzl-select
              :clearable="false"
              @input="init"
              class="normal-case"
              v-model="filter.feature_id"
              :items="listFeature.filter((item) => item.period)"
            />
          </base-input>
        </div>
        <div class="col-md p-2">
          <base-input label="Nível de aplicação" input-classes="form-control-sm">
            <puzl-select
              @input="init"
              class="normal-case"
              v-model="filter.level_feature"
              :items="[{id: '', name: 'SEM NÍVEL DE APLICAÇÃO'}, {id: 1, name: 'BAIXA'}, {id: 2, name: 'MÉDIA'}, {id: 3, name: 'ALTA'}]"
            />
          </base-input>
        </div>
        <div class="col-md p-2">
          <base-input label="D. MÁX" input-classes="form-control-sm">
            <puzl-select
              v-model="filter.maximum_size_id"
              :items="$_maximum_sizes"
              :multiple="true"
              class="new-default-black-font"
              :loading="loadingMaximumSize"
              label="description"
              :disabled="loadingMaximumSize"
              @input="init"
              placeholder="Selecione"
            />
          </base-input>
        </div>
      </multi-filter>
      <div id="content" ref="content" class="row" style="margin-left: 0px">
        <div
            style="padding-right: 0;position: sticky !important;left: 0;z-index: 2;background: white;border: 1px solid white"
            class="col-md-4">
          <div class="row" style="margin-left: -2rem;">
            <div class="col-12 card-sticky">
              <div class="custom-box bg-primary-1 pl-2" style="width: 100%;background: #f7f7ff !important">
                <div class="row" style="height: 24px">
                  <div class="col-12 title-box-w  text-uppercase">DESCRIÇÃO</div>
                </div>
              </div>
            </div>
            <div class="col-12 card-sticky" style="top: 2.4rem !important;z-index: 3 !important;">
              <div class="custom-box bg-light-custom pl-2" style="width: 100%;background: #fbf9f9 !important">
                <div class="row" style="height: 24px">
                  <!--                  <div class="col-12 title-box-w text-uppercase">30,0 MPa (fr) Z = 1,65 (95%)</div>-->
                  <div class="col-12 title-box-w text-uppercase">Total
                    <a href="#" v-if="!has_one_collapsed(items)" @click.prevent="uncollapse(items)">
                      <i class="fa-regular text-dark fa-square-plus pr-2"></i>
                    </a>
                    <a href="#" v-else @click.prevent="collapse_items(items)">
                      <i class="fa-regular text-dark fa-square-minus pr-2"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <template v-for="item in items">
              <div class="col-12">
                <div class="custom-box custom-border bg-grey-w" style="width: 100%">
                  <div class="row nv-1" style="height: 24px">
                    <div class="col-12 title-box text-uppercase">
                      <a href="#"
                         @click.prevent="item.expand ? collapse(item) : item.expand = true">
                        <i v-if="item.expand" class="fa-regular fa-square-caret-down text-dark pr-2"></i>
                        <i v-else class="fa-regular fa-square-caret-right text-dark pr-2"></i>
                      </a>
                      <a class="text-white" href="#"
                         @click.prevent="uncollapse(item)">
                        <i class="fa-regular text-dark fa-square-plus pr-2"></i>
                      </a>
                      <img src="/img/icons/test-tube.png" style="width: 20px;"> {{ item.title }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12" v-if="item.expand" v-for="level_feature in item.level_features">
                <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                  <div class="row nv-2" style="height: 24px">
                    <div class="col-12 title-box-w ">
                      <a v-if="level_feature.expand"
                         @click.prevent="collapse(level_feature);level_feature.expand = false"
                         class="text-dark" href="#">
                        <i class="fa-regular fa-square-caret-down pr-2"></i>
                      </a>
                      <a v-else
                         @click.prevent="collapse(level_feature);level_feature.expand = true"
                         class="text-dark" href="#">
                        <i class="fa-regular fa-square-caret-right text-dark pr-2"></i>
                      </a>
                      <img v-if="level_feature.title.includes('BAIXA')" height="19"
                           src="/img/icons/icons8/ios/1-circle_danger.png" alt="1-circle"/>
                      <img v-if="level_feature.title.includes('MÉDIA')" height="19"
                           src="/img/icons/icons8/ios/2-circle_danger.png" alt="2-circle"/>
                      <img v-if="level_feature.title.includes('ALTA')" height="19"
                           src="/img/icons/icons8/ios/3-circle_danger.png" alt="3-circle"/>
                      <img v-if="level_feature.title.includes('SEM')" height="19"
                           src="/img/icons/icons8/ios/circled_danger.png" alt="circle"/>
                      {{ level_feature.title }}
                    </div>
                  </div>
                </div>
                <div v-if="level_feature.expand" v-for="type in level_feature.types">
                  <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                    <div class="row nv-4" style="height: 24px">
                      <div class="col-12 title-box-w text-uppercase">
                        <a class="text-dark" href="#"
                           @click.prevent="type.expand ? collapse(type) : type.expand = true">
                          <i v-if="type.expand" class="fa-regular fa-square-caret-down text-dark pr-2"></i>
                          <i v-else class="fa-regular fa-square-caret-right text-dark pr-2"></i>
                        </a>
                        <img height="19" src="/img/icons/icons8/ios/personal-video-recorder-menu_success.png">
                        {{ type.title }}
                      </div>
                    </div>
                  </div>
                  <div v-if="type.expand" v-for="slump_flow in type.slump_flows">
                    <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                      <div class="row nv-5" style="height: 24px">
                        <div class="col-12 title-box-w text-uppercase">
                          <a class="text-dark" href="#"
                             @click.prevent="slump_flow.expand ? collapse(slump_flow) : slump_flow.expand = true">
                            <i v-if="slump_flow.expand" class="fa-regular fa-square-caret-down text-dark pr-2"></i>
                            <i v-else class="fa-regular fa-square-caret-right text-dark pr-2"></i>
                          </a>
                          <img src="/img/icons/icons8/ios/vlc.png" height="19px" alt="vlc"/>
                          {{ slump_flow.title || 'Nenhum' }}
                        </div>
                      </div>
                    </div>
                    <div v-if="slump_flow.expand" v-for="maximum_size in slump_flow.maximum_sizes">
                      <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                        <div class="row nv-6" style="height: 24px">
                          <div class="col-12 title-box-w text-uppercase">
                            <a class="text-dark" href="#"
                               @click.prevent="maximum_size.expand ? collapse(maximum_size) : maximum_size.expand = true">
                              <i v-if="maximum_size.expand" class="fa-regular fa-square-caret-down text-dark pr-2"></i>
                              <i v-else class="fa-regular fa-square-caret-right text-dark pr-2"></i>
                            </a>
                            <img src="/img/icons/icons8/ios/wheelbarrow_dark.png" height="19px"/>
                            {{ maximum_size.title }}
                          </div>
                        </div>
                      </div>
                      <div v-if="maximum_size.expand" v-for="contract_proposal in maximum_size.contract_proposals">
                        <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                          <div class="row nv-7" style="height: 24px">
                            <div class="col-12 title-box-w text-uppercase text-truncate"
                                 :title="contract_proposal.title">
                              <a class="text-dark" href="#"
                                 @click.prevent="contract_proposal.expand ? collapse(contract_proposal) : contract_proposal.expand = true">
                                <i v-if="contract_proposal.expand"
                                   class="fa-regular fa-square-caret-down text-dark pr-2"></i>
                                <i v-else class="fa-regular fa-square-caret-right text-dark pr-2"></i>
                              </a>
                              <img src="/img/icons/client-company.png" style="width: 19px;">
                              {{ contract_proposal.title }}
                            </div>
                          </div>
                        </div>
                        <div v-if="contract_proposal.expand" v-for="number in contract_proposal.numbers">
                          <div class="custom-box custom-border bg-white">
                            <div class="row nv-8" style="height: 24px">
                              <div class="col-12 mt-n1" style="text-transform: none">
                                <a @click.prevent="showAdjustments(number.schedule_travel_uuid)" href="#"
                                   class="pl-0"><img src="/img/icons/icons8/puzzle.png" class="pointer"
                                                     style="height: 1.3rem;"></a>
                                <el-popover v-if="number.reuse_number"
                                            :title="setTitleReuse(`${number.reuse_category}${number.reuse_category_id}`)" trigger="click"
                                            placement="right" class="p-0 pr-1">
                                  <div>
                                    <h3 class="text-info pl-1">
                                      <small class="text-default"
                                      ><img style="margin-left: -4px; margin-top: -12px" width="32px" src="/img/icons/os.png"/>
                                        <span class="text-default" style="text-transform: none !important"> n° </span>
                                      </small>
                                      <b class="money">{{ number.reuse_number }}</b>
                                    </h3>
                                  </div>
                                  <div style="margin-top: -5px !important">
                                    <h6 class="h5 new-default-black-font p-1 mb-0 font-weight-400"> Quantidade:  <b>{{ number.reuse_volume.replace(".", ",") }} </b>m<sup>3</sup></h6>
                                  </div>
                                  <div style="margin-top: -5px !important">
                                    <div class="row align-items-center">
                                      <div class="col-12">
                                        <hr class="mt-2 mb-2">
                                      </div>
                                      <div class="col-2 mt-n3" style="display: flex; align-items: center;">
                       <span style="border: 1px solid #1a70b7;border-radius: 35px;padding: 10px;">
                         <img src="/img/icons/test-tube.png" style="width: 17px;"></span>
                                      </div>
                                      <div class="col-10">
                                        <h6 class="h5 new-default-black-font p-1 mb-0 font-weight-400"> {{number.reuse_title}} </h6>
                                        <h6 class="h5 mt-n2 new-default-black-font p-1 mb-0 font-weight-400"> {{number.reuse_body}} </h6>
                                        <h6 class="h5 mt-n2 new-default-black-font p-1 mb-0 font-weight-400"> {{number.reuse_footer}} </h6>
                                      </div>
                                    </div>
                                  </div>
                                  <img
                                    slot="reference"
                                    class="pointer ml-1"
                                    :src="number.reuse_category === 1 ? '/img/icons/icons8/ios/recycle-sign_success.png' : '/img/icons/icons8/ios/recycle-sign_warning.png'"
                                    width="20px"
                                    height="20px"/>
                                </el-popover>
                                <el-popover trigger="hover" placement="top">
                                  <div class="border-left border-3 border-primary p-2 mt-1">
                                    <h5 class="h4 mb-0">
                                      {{ number.title }}
                                    </h5>
                                    <div class="mt-2 mb-3">
                                      <h5 class="small">
                                        {{ number.body }}
                                      </h5>
                                    </div>
                                    <div class="mt-2 mb-3" v-if="number.footer">
                                      <h5 class="small">
                                        {{ number.footer }}
                                      </h5>
                                    </div>
                                  </div>
                                  <div class="border-left border-3 border-info p-1 mt-2">
                                    <h5 class="m-0 pl-1">{{ number.piece }}</h5>
                                  </div>
                                  <img
                                      slot="reference"
                                      src="/img/icons/test-tube.png"
                                      width="20px"
                                      height="20px"/>
                                </el-popover>
                                <el-popover trigger="hover" placement="top">
                                  <span class="h3">{{ number.equipment_code }} ({{ number.equipment_plate }})</span>
                                  <br>
                                  <span>{{ number.driver }}</span>
                                  <img
                                      slot="reference"
                                      class="ml-1"
                                      src="/img/icons/in-transit.png"
                                      width="20px"
                                      height="20px"/>
                                </el-popover>
                                <el-popover trigger="hover" placement="top">
                                  <h5 class="h3 mb-0 text-uppercase">
                                    {{ number.charge_point }}
                                  </h5>
                                  <span>{{ number.plant }}</span>
                                  <img
                                      slot="reference"
                                      class="ml-1"
                                      src="/img/icons/chemical-plant-black.png"
                                      width="20px"
                                      height="20px"/>
                                </el-popover>
                                <img style="margin-left: 4px" src="/img/icons/os.png" width="26px" height="26px">
                                <small style="font-size: 0.75rem !important;" class="pr-2 text-dark font-weight-400 text-lowercase"> {{ number.number }}</small>
                                <template v-if="!number.nfe">
                                  <img
                                    slot="reference"
                                    class="ml-n1"
                                    src="/img/icons/sefaz.png"
                                    height="24px"/>
                                  <small style="font-size: 0.75rem !important;"
                                         class="pr-2 text-dark font-weight-400 text-lowercase"> {{ number.number }}</small>
                                </template>
                                <small style="font-family: system-ui;color: black;margin-left: -5px">
                                  {{number.construction_exit_time | parseDate('DD/MM/YYYY H:mm')}}
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div style="background: white;border: 1px solid white;left: 0 !important;padding-left: 0;padding-right: 0"
             class="col-md-2">
          <div class="row">
            <div class="col-12 card-sticky">
              <div class="custom-box bg-primary-1 pl-2" style="width: 100%">
                <div class="row" style="height: 24px">
                  <div class="col-6 title-box-w text-uppercase text-center">ENSAIADO</div>
                  <div class="col-6 title-box-w text-uppercase text-center">CONSUMO</div>
                </div>
              </div>
            </div>
            <div class="col-12 card-sticky" style="top: 2.4rem !important">
              <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                <div class="row" style="height: 24px">
                  <div class="col-6 text-center title-box-w" style="padding-top: 2px">
                    <template v-if="sumVolume() > 0">
                      {{ sum1(items, 'volume') }} m³ ({{ sumVolume() }})
                    </template>
                    <template v-else>
                      -
                    </template>
                  </div>
                  <div class="col-6 text-center title-box-w" style="padding-top: 2px">
                    <template v-if="Number(sum1(getNumbers(started_items), 'adjusted_binder')) > 0">
                      {{ Math.round(sum1(getNumbers(started_items), 'adjusted_binder') / sumVolume()) }}
                    </template>
                    <template v-else>
                      -
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <template v-for="item in items">
              <div class="col-12">
                <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                  <div class="row" style="height: 24px">
                    <div class="col-6 text-center title-box-w" style="padding-top: 2px">
                      {{ formatNumberToHumans(item.volume) }} m³ ({{ item.count_travels }})
                    </div>
                    <div class="col-6 text-center title-box-w" style="padding-top: 2px">
                      {{ Math.round(item.statistics.binder) }}
                    </div>
                  </div>
                </div>
                <div v-if="item.expand" v-for="level_feature in item.level_features">
                  <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                    <div class="row" style="height: 24px">
                      <div class="col-6 text-center title-box-w" style="padding-top: 2px">
                        {{ formatNumberToHumans(level_feature.volume) }} m³ ({{ level_feature.count_travels }})
                      </div>
                      <div class="col-6 text-center title-box-w" style="padding-top: 2px">
                        {{ Math.round(level_feature.statistics.binder) }}
                      </div>
                    </div>
                  </div>
                  <div v-if="level_feature.expand" v-for="type in level_feature.types">
                    <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                      <div class="row" style="height: 24px">
                        <div class="col-6 text-center title-box-w" style="padding-top: 2px">
                          {{ formatNumberToHumans(type.volume) }} m³ ({{ type.count_travels }})
                        </div>
                        <div class="col-6 text-center title-box-w" style="padding-top: 2px">
                          {{ Math.round(type.statistics.binder) }}
                        </div>
                      </div>
                    </div>
                    <div v-if="type.expand" v-for="slump_flow in type.slump_flows">
                      <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                        <div class="row" style="height: 24px">
                          <div class="col-6 text-center title-box-w" style="padding-top: 2px">
                            {{ formatNumberToHumans(slump_flow.volume) }} m³ ({{ slump_flow.count_travels }})
                          </div>
                          <div class="col-6 text-center title-box-w" style="padding-top: 2px">
                            {{ Math.round(slump_flow.statistics.binder) }}
                          </div>
                        </div>
                      </div>
                      <div v-if="slump_flow.expand" v-for="maximum_size in slump_flow.maximum_sizes">
                        <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                          <div class="row" style="height: 24px">
                            <div class="col-6 text-center title-box-w" style="padding-top: 2px">
                              {{ formatNumberToHumans(maximum_size.volume) }} m³ ({{ maximum_size.count_travels }})
                            </div>
                            <div class="col-6 text-center title-box-w" style="padding-top: 2px">
                              {{ Math.round(maximum_size.statistics.binder) }}
                            </div>
                          </div>
                        </div>
                        <div v-if="maximum_size.expand" v-for="contract_proposal in maximum_size.contract_proposals">
                          <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                            <div class="row" style="height: 24px">
                              <div class="col-6 text-center title-box-w" style="padding-top: 2px">
                                {{ formatNumberToHumans(contract_proposal.volume) }} m³
                                ({{ contract_proposal.count_travels }})
                              </div>
                              <div class="col-6 text-center title-box-w" style="padding-top: 2px">
                                {{ Math.round(contract_proposal.statistics.binder) }}
                              </div>
                            </div>
                          </div>
                          <div v-if="contract_proposal.expand" v-for="number in contract_proposal.numbers">
                            <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                              <div class="row" style="height: 24px">
                                <div class="col-6 text-center title-box-w" style="padding-top: 2px">
                                  {{ formatNumberToHumans(number.volume) }} m³
                                </div>
                                <div class="col-6 text-center title-box-w" style="padding-top: 2px">
                                  {{ Math.round(number.adjusted_binder) }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--              <div class="col-12" v-for="travel in item.construction.travels" v-if="item.construction.expand">-->
              <!--                <div class="custom-box custom-border bg-white">-->
              <!--                  <div class="row" style="height: 24px">-->
              <!--                    <div class="col-4" style="height: 20px">-->
              <!--                      <span class="text-dark"> 1 </span>-->
              <!--                    </div>-->
              <!--                    <div class="col-4" style="height: 20px">-->
              <!--                      <span class="text-dark"> 1 </span>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->
            </template>
          </div>
        </div>
        <div style="background: white;border: 1px solid white;left: 0 !important;padding-left: 0;padding-right: 0"
             class="col-md-3">
          <div class="row">
            <div class="col-12 card-sticky">
              <div class="custom-box bg-primary-1 pl-2" style="width: 100%">
                <div class="row" style="height: 24px">
                  <div class="col-3 title-box-w text-uppercase text-center">RESULTADO</div>
                  <div class="col-3 title-box-w text-uppercase text-center">CONFORMIDADE</div>
                  <div class="col-2 title-box-w text-uppercase text-center">VARIAÇÃO</div>
                  <div class="col-1 title-box-w text-uppercase text-center">SD</div>
                  <div class="col-1 title-box-w text-uppercase text-center">C.V</div>
                  <div class="col-2 title-box-w text-uppercase text-center">KG/MPa</div>
                </div>
              </div>
            </div>
            <div class="col-12 card-sticky" style="top: 2.4rem !important">
              <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                <div class="row" style="height: 24px">
                  <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                    <template v-if="Number(sumStatistics(items, 'divided_result')) > 0">
                      {{ (Number(sumStatistics(items, 'divided_result')) / Object.values(items).length) | numeric(1) }}
                    </template>
                    <template v-else>-</template>
                  </div>
                  <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                    <template
                        v-if="Number(sumStatistics(items, 'with_accordance_percentage')) > 0"
                        class="font-weight-bold"
                        :class="(Number(sumStatistics(items, 'with_accordance_percentage')) / Object.values(items).length) > 0 ? 'text-success' : 'text-danger'">
                      {{
                        (Math.round((Number(sumStatistics(items, 'with_accordance_percentage')) / Object.values(items).filter((item) => Number(item.statistics.with_accordance_percentage) > 0).length) * 100)) | numeric()
                      }} %
                    </template>
                    <template v-else class="text-muted font-weight-bold">-</template>
                  </div>
                  <div class="col-2 text-center title-box-w" style="padding-top: 2px">
                    <small v-if="Number(sumStatistics(items, 'variation')) > 0"
                           class="font-weight-bold"
                           :class="(Number(sumStatistics(items, 'variation')) / Object.values(items).length) > 0 ? 'text-success' : 'text-danger'">
                      {{ (Number(sumStatistics(items, 'variation')) / Object.values(items).length) | numeric(1) }}
                    </small>
                    <small v-else class="text-muted font-weight-bold">-</small>
                  </div>
                  <div class="col-1 text-center title-box-w" style="padding-top: 2px">
                    <template v-if="Number(sumStatistics(items, 'sd')) > 0">
                      {{
                        (Number(sumStatistics(items, 'sd')) / Object.values(items).filter((item) => Number(item.statistics.sd) > 0).length) | numeric(1)
                      }}
                    </template>
                    <template v-else>-</template>
                  </div>
                  <div class="col-1 text-center title-box-w" style="padding-top: 2px">
                    <template v-if="Number(sumStatistics(items, 'sd')) > 0">
                      {{
                        ((((Number(sumStatistics(items, 'sd')) / Object.values(items).filter((item) => Number(item.statistics.sd) > 0).length).toFixed(1))
                            /
                            Number(sumStatistics(items, 'divided_result'))) * 100) | numeric(1)
                      }} %
                    </template>
                    <template v-else>
                      -
                    </template>
                  </div>
                  <div class="col-2 text-center title-box-w" style="padding-top: 2px">
                    <template v-if="Number(sumStatistics(items, 'kg_mpa')) > 0">
                      {{ (Number(sumStatistics(items, 'kg_mpa')) / Object.values(items).length) | numeric(1) }}
                    </template>
                    <template v-else>-</template>
                  </div>
                </div>
              </div>
            </div>
            <template v-for="item in items">
              <div class="col-12">
                <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                  <div class="row" style="height: 24px">
                    <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                      <template v-if="Number(item.statistics.divided_result) > 0">
                        {{ Number(item.statistics.divided_result) | numeric(1) }} ({{item.statistics.count_travel_with_result}})
                      </template>
                      <template v-else>-</template>
                    </div>
                    <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                      <template v-if="item.statistics.with_accordance_percentage">
                        {{ (item.statistics.with_accordance_percentage * 100) | numeric() }} %
                      </template>
                      <template v-else>
                        {{Number(item.statistics.divided_result) > 0 ? '0%' : '-'}}
                      </template>
                    </div>
                    <div class="col-2 text-center title-box-w" style="padding-top: 2px">
                      <small v-if="item.statistics.variation != 0" class="font-weight-bold"
                             :class="item.statistics.variation > 0 ? 'text-success' : 'text-danger'">
                        {{ Number(item.statistics.variation) | numeric(1) }}
                      </small>
                      <small v-else class="text-muted font-weight-bold">-</small>
                    </div>
                    <div class="col-1 text-center title-box-w" style="padding-top: 2px">
                      <template v-if="item.statistics.sd && item.statistics.sd > 0">
                        {{ item.statistics.sd | numeric(1) }}
                      </template>
                      <template v-else>
                        -
                      </template>
                    </div>
                    <div class="col-1 text-center title-box-w" style="padding-top: 2px">
                      <template v-if="item.statistics.sd > 0">
                        {{
                          (Number((item.statistics.sd / Number(item.statistics.divided_result).toFixed(1)) * 100).toFixed(1)) | numeric()
                        }} %
                      </template>
                      <template v-else>
                        -
                      </template>
                    </div>
                    <div class="col-2 text-center title-box-w" style="padding-top: 2px">
                      {{ item.statistics.kg_mpa > 0 ? item.statistics.kg_mpa : '-' }}
                    </div>
                  </div>
                </div>
                <div v-if="item.expand" v-for="level_feature in item.level_features">
                  <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                    <div class="row" style="height: 24px">
                      <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                        <template
                            v-if="Number(level_feature.statistics.divided_result) != 0">
                          {{ Number(level_feature.statistics.divided_result).toFixed(1) }} ({{level_feature.statistics.count_travel_with_result}})
                        </template>
                        <template v-else>-</template>
                      </div>
                      <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                        <template v-if="level_feature.statistics.with_accordance_percentage">
                          {{ level_feature.statistics.with_accordance_percentage * 100 }} %
                        </template>
                        <template v-else>
                          {{Number(level_feature.statistics.divided_result) > 0 ? '0%' : '-'}}
                        </template>
                      </div>
                      <div class="col-2 text-center title-box-w" style="padding-top: 2px">
                        <small v-if="level_feature.statistics.variation != 0" class="font-weight-bold"
                               :class="level_feature.statistics.variation > 0 ? 'text-success' : 'text-danger'">
                          {{ Number(level_feature.statistics.variation).toFixed(1) }}
                        </small>
                        <small v-else class="text-muted font-weight-bold">-</small>
                      </div>
                      <div class="col-1 text-center title-box-w" style="padding-top: 2px">
                        {{ level_feature.statistics.sd > 0 ? Number(level_feature.statistics.sd).toFixed(2) : '-' }}
                      </div>
                      <div class="col-1 text-center title-box-w" style="padding-top: 2px">
                        <template v-if="level_feature.statistics.sd > 0">
                          {{
                            Number((level_feature.statistics.sd / Number(level_feature.statistics.divided_result).toFixed(1)) * 100).toFixed(1)
                          }} %
                        </template>
                        <template v-else>
                          -
                        </template>
                      </div>
                      <div class="col-2 text-center title-box-w" style="padding-top: 2px">
                        {{ level_feature.statistics.kg_mpa > 0 ? level_feature.statistics.kg_mpa : '-' }}
                      </div>
                    </div>
                  </div>
                  <div v-if="level_feature.expand" v-for="type in level_feature.types">
                    <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                      <div class="row" style="height: 24px">
                        <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                          <template
                              v-if="Number(type.statistics.divided_result) != 0">
                            {{ Number(type.statistics.divided_result).toFixed(1) }} ({{type.statistics.count_travel_with_result}})
                          </template>
                          <template v-else>-</template>
                        </div>
                        <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                          <template v-if="type.statistics.with_accordance_percentage">
                            {{ type.statistics.with_accordance_percentage * 100 }} %
                          </template>
                          <template v-else>
                            {{Number(type.statistics.divided_result) > 0 ? '0%' : '-'}}
                          </template>
                        </div>
                        <div class="col-2 text-center title-box-w" style="padding-top: 2px">
                          <small v-if="type.statistics.variation != 0" class="font-weight-bold"
                                 :class="type.statistics.variation > 0 ? 'text-success' : 'text-danger'">
                            {{ Number(type.statistics.variation).toFixed(1) }}
                          </small>
                          <small v-else class="text-muted font-weight-bold">-</small>
                        </div>
                        <div class="col-1 text-center title-box-w" style="padding-top: 2px">
                          {{ type.statistics.sd > 0 ? Number(type.statistics.sd).toFixed(2) : '-' }}
                        </div>
                        <div class="col-1 text-center title-box-w" style="padding-top: 2px">
                          <template v-if="type.statistics.sd > 0">
                            {{
                              Number((type.statistics.sd / Number(type.statistics.divided_result).toFixed(1)) * 100).toFixed(1)
                            }} %
                          </template>
                          <template v-else>
                            -
                          </template>
                        </div>
                        <div class="col-2 text-center title-box-w" style="padding-top: 2px">
                          {{ type.statistics.kg_mpa > 0 ? type.statistics.kg_mpa : '-' }}
                        </div>
                      </div>
                    </div>
                    <div v-if="type.expand" v-for="slump_flow in type.slump_flows">
                      <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                        <div class="row" style="height: 24px">
                          <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                            <template
                                v-if="Number(slump_flow.statistics.divided_result) != 0">
                              {{ Number(slump_flow.statistics.divided_result).toFixed(1) }} ({{slump_flow.statistics.count_travel_with_result}})
                            </template>
                            <template v-else>-</template>
                          </div>
                          <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                            <template v-if="slump_flow.statistics.with_accordance_percentage">
                              {{ slump_flow.statistics.with_accordance_percentage * 100 }} %
                            </template>
                            <template v-else>
                              {{Number(slump_flow.statistics.divided_result) > 0 ? '0%' : '-'}}
                            </template>
                          </div>
                          <div class="col-2 text-center title-box-w" style="padding-top: 2px">
                            <small v-if="slump_flow.statistics.variation != 0" class="font-weight-bold"
                                   :class="slump_flow.statistics.variation > 0 ? 'text-success' : 'text-danger'">
                              {{ Number(slump_flow.statistics.variation).toFixed(1) }}
                            </small>
                            <small v-else class="text-muted font-weight-bold">-</small>
                          </div>
                          <div class="col-1 text-center title-box-w" style="padding-top: 2px">
                            {{ slump_flow.statistics.sd > 0 ? Number(slump_flow.statistics.sd).toFixed(2) : '-' }}
                          </div>
                          <div class="col-1 text-center title-box-w" style="padding-top: 2px">
                            <template v-if="slump_flow.statistics.sd > 0">
                              {{
                                Number((slump_flow.statistics.sd / Number(slump_flow.statistics.divided_result).toFixed(1)) * 100).toFixed(1)
                              }} %
                            </template>
                            <template v-else>
                              -
                            </template>
                          </div>
                          <div class="col-2 text-center title-box-w" style="padding-top: 2px">
                            {{ slump_flow.statistics.kg_mpa > 0 ? slump_flow.statistics.kg_mpa : '-' }}
                          </div>
                        </div>
                      </div>
                      <div v-if="slump_flow.expand" v-for="maximum_size in slump_flow.maximum_sizes">
                        <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                          <div class="row" style="height: 24px">
                            <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                              <template
                                  v-if="Number(maximum_size.statistics.divided_result) != 0">
                                {{ Number(maximum_size.statistics.divided_result).toFixed(1) }} ({{maximum_size.statistics.count_travel_with_result}})
                              </template>
                              <template v-else>-</template>
                            </div>
                            <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                              <template v-if="maximum_size.statistics.with_accordance_percentage">
                                {{ maximum_size.statistics.with_accordance_percentage * 100 }} %
                              </template>
                              <template v-else>
                                {{Number(maximum_size.statistics.divided_result) > 0 ? '0%' : '-'}}
                              </template>
                            </div>
                            <div class="col-2 text-center title-box-w" style="padding-top: 2px">
                              <small v-if="maximum_size.statistics.variation != 0" class="font-weight-bold"
                                     :class="maximum_size.statistics.variation > 0 ? 'text-success' : 'text-danger'">
                                {{ Number(maximum_size.statistics.variation).toFixed(1) }}
                              </small>
                              <small v-else class="text-muted font-weight-bold">-</small>
                            </div>
                            <div class="col-1 text-center title-box-w" style="padding-top: 2px">
                              {{ maximum_size.statistics.sd > 0 ? Number(maximum_size.statistics.sd).toFixed(2) : '-' }}
                            </div>
                            <div class="col-1 text-center title-box-w" style="padding-top: 2px">
                              <template v-if="maximum_size.statistics.sd > 0">
                                {{
                                  Number((maximum_size.statistics.sd / Number(maximum_size.statistics.divided_result).toFixed(1)) * 100).toFixed(1)
                                }} %
                              </template>
                              <template v-else>
                                -
                              </template>
                            </div>
                            <div class="col-2 text-center title-box-w" style="padding-top: 2px">
                              {{ maximum_size.statistics.kg_mpa > 0 ? maximum_size.statistics.kg_mpa : '-' }}
                            </div>
                          </div>
                        </div>
                        <div v-if="maximum_size.expand" v-for="contract_proposal in maximum_size.contract_proposals">
                          <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                            <div class="row" style="height: 24px">
                              <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                                <template
                                    v-if="Number(contract_proposal.statistics.divided_result) != 0">
                                  {{ Number(contract_proposal.statistics.divided_result).toFixed(1) }} ({{contract_proposal.statistics.count_travel_with_result}})
                                </template>
                                <template v-else>-</template>
                              </div>
                              <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                                <template v-if="contract_proposal.statistics.with_accordance_percentage">
                                  {{ contract_proposal.statistics.with_accordance_percentage * 100 }} %
                                </template>
                                <template v-else>
                                  {{Number(contract_proposal.statistics.divided_result) > 0 ? '0%' : '-'}}
                                </template>
                              </div>
                              <div class="col-2 text-center title-box-w" style="padding-top: 2px">
                                <small v-if="contract_proposal.statistics.variation != 0" class="font-weight-bold"
                                       :class="contract_proposal.statistics.variation > 0 ? 'text-success' : 'text-danger'">
                                  {{ Number(contract_proposal.statistics.variation).toFixed(1) }}
                                </small>
                                <small v-else class="text-muted font-weight-bold">-</small>
                              </div>
                              <div class="col-1 text-center title-box-w" style="padding-top: 2px">
                                {{
                                  contract_proposal.statistics.sd > 0 ? Number(contract_proposal.statistics.sd).toFixed(2) : '-'
                                }}
                              </div>
                              <div class="col-1 text-center title-box-w" style="padding-top: 2px">
                                <template v-if="contract_proposal.statistics.sd > 0">
                                  {{
                                    Number((contract_proposal.statistics.sd / Number(contract_proposal.statistics.divided_result).toFixed(1)) * 100).toFixed(1)
                                  }} %
                                </template>
                                <template v-else>
                                  -
                                </template>
                              </div>
                              <div class="col-2 text-center title-box-w" style="padding-top: 2px">
                                {{
                                  contract_proposal.statistics.kg_mpa > 0 ? contract_proposal.statistics.kg_mpa : '-'
                                }}
                              </div>
                            </div>
                          </div>
                          <div v-if="contract_proposal.expand" v-for="number in contract_proposal.numbers">
                            <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                              <div class="row" style="height: 24px">
                                <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                                  {{ number.result || '-' }}
                                </div>
                                <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                                  <template v-if="number.result">
                                    <small class="text-success font-weight-bold"
                                           v-if="number.has_accordance">SIM</small>
                                    <small class="text-danger font-weight-bold" v-else>NÃO</small>
                                  </template>
                                  <template v-else>
                                    <small>-</small>
                                  </template>
                                </div>
                                <div class="col-2 text-center title-box-w" style="padding-top: 2px">
                                  <small v-if="calcVariation(number.result, number.description) != 0"
                                         class="font-weight-bold"
                                         :class="calcVariation(number.result, number.description) > 0 ? 'text-success' : 'text-danger'">
                                    {{ number.variation }}
                                  </small>
                                  <small v-else class="text-muted">-</small>
                                </div>
                                <div class="col-1 text-center title-box-w" style="padding-top: 2px">
                                  -
                                </div>
                                <div class="col-1 text-center title-box-w" style="padding-top: 2px">
                                  -
                                </div>
                                <div class="col-2 text-center title-box-w" style="padding-top: 2px">
                                  <template v-if="number.result">
                                    <small> {{ number.kg_mpa }}</small>
                                  </template>
                                  <template v-else>
                                    <small>-</small>
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--              <div class="col-12" v-for="travel in item.construction.travels" v-if="item.construction.expand">-->
              <!--                <div class="custom-box custom-border bg-white">-->
              <!--                  <div class="row" style="height: 24px">-->
              <!--                    <div class="col-4" style="height: 20px">-->
              <!--                      <span class="text-dark"> 1 </span>-->
              <!--                    </div>-->
              <!--                    <div class="col-4" style="height: 20px">-->
              <!--                      <span class="text-dark"> 1 </span>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->
            </template>
          </div>
        </div>
        <div style="left: 0 !important;padding-left: 0;padding-right: 0"
             class="col-md-3">
          <div class="row">
            <div class="col-12 card-sticky">
              <div class="custom-box bg-primary-1 pl-2" style="width: 100%">
                <div class="row" style="height: 24px">
                  <div class="col-3 title-box-w text-uppercase text-center">ESTIMADO</div>
                  <div class="col-3 title-box-w text-uppercase text-center">CONFORMIDADE</div>
                  <div class="col-2 title-box-w text-uppercase text-center">VARIAÇÃO</div>
                  <div class="col-1 title-box-w text-uppercase text-center">SD</div>
                  <div class="col-1 title-box-w text-uppercase text-center">C.V</div>
                  <div class="col-2 title-box-w text-uppercase text-center">KG/MPa</div>
                </div>
              </div>
            </div>
            <div class="col-12 card-sticky" style="top: 2.4rem !important">
              <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                <div class="row" style="height: 24px">
                  <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                    <template v-if="Number(sumStatistics(items, 'divided_expected_result')) > 0">
                      {{
                        (Number(sumStatistics(items, 'divided_expected_result')) / Object.values(items).filter((item) => Number(item.statistics.divided_expected_result) > 0).length).toFixed(1)
                      }}
                    </template>
                    <template v-else>-</template>
                  </div>
                  <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                    <template
                        v-if="Number(sumStatistics(items, 'with_expected_accordance_percentage')) > 0"
                        class="font-weight-bold"
                        :class="                        (Number(sumStatistics(items, 'with_expected_accordance_percentage')) / Object.values(items).filter((item) => Number(item.statistics.with_expected_accordance_percentage) > 0).length) * 100 > 0 ? 'text-success' : 'text-danger'">
                      {{
                        Math.round((Number(sumStatistics(items, 'with_expected_accordance_percentage')) / Object.values(items).filter((item) => Number(item.statistics.with_expected_accordance_percentage) > 0).length) * 100)
                      }} %
                    </template>
                    <template v-else class="text-muted font-weight-bold">-</template>
                  </div>
                  <div class="col-2 text-center title-box-w" style="padding-top: 2px">
                    <small v-if="Number(sumStatistics(items, 'expected_variation')) > 0"
                           class="font-weight-bold"
                           :class="(Number(sumStatistics(items, 'expected_variation')) / Object.values(items).filter((item) => Number(item.statistics.expected_variation) > 0).length) > 0 ? 'text-success' : 'text-danger'">
                      {{
                        (Number(sumStatistics(items, 'expected_variation')) / Object.values(items).filter((item) => Number(item.statistics.expected_variation) > 0).length).toFixed(1)
                      }}
                    </small>
                    <small v-else class="text-muted font-weight-bold">-</small>
                  </div>
                  <div class="col-1 text-center title-box-w" style="padding-top: 2px">
                    <template v-if="Number(sumStatistics(items, 'expected_sd')) > 0">
                      {{
                        (Number(sumStatistics(items, 'expected_sd')) / Object.values(items).filter((item) => Number(item.statistics.expected_sd) > 0).length).toFixed(2)
                      }}
                    </template>
                    <template v-else>-</template>
                  </div>
                  <div class="col-1 text-center title-box-w" style="padding-top: 2px">
                    <template v-if="Number(sumStatistics(items, 'expected_sd')) > 0">
                      {{
                        ((((Number(sumStatistics(items, 'expected_sd')) / Object.values(items).filter((item) => Number(item.statistics.expected_sd) > 0).length).toFixed(2))
                            /
                            Number(sumStatistics(items, 'divided_expected_result'))) * 100).toFixed(1)
                      }} %
                    </template>
                    <template v-else>
                      -
                    </template>
                  </div>
                  <div class="col-2 text-center title-box-w" style="padding-top: 2px">
                    <template v-if="Number(sumStatistics(items, 'expected_kg_mpa')) > 0">
                      {{ (Number(sumStatistics(items, 'expected_kg_mpa')) / Object.values(items).length).toFixed(1) }}
                    </template>
                    <template v-else>-</template>
                  </div>
                </div>
              </div>
            </div>
            <template v-for="item in items">
              <div class="col-12">
                <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                  <div class="row" style="height: 24px">
                    <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                      <template
                          v-if="Number(item.statistics.result_expected / item.statistics.count_travel_with_expected_result) > 0">
                        {{
                          Number(item.statistics.result_expected / item.statistics.count_travel_with_expected_result).toFixed(1)
                        }} ({{item.statistics.count_travel_with_expected_result}})
                      </template>
                      <template v-else>-</template>
                    </div>
                    <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                      <template v-if="item.statistics.with_expected_accordance_percentage">
                        {{ Math.round(item.statistics.with_expected_accordance_percentage * 100) }} %
                      </template>
                      <template v-else>
                        -
                      </template>
                    </div>
                    <div class="col-2 text-center title-box-w" style="padding-top: 2px">
                      <small v-if="item.statistics.expected_variation != 0" class="font-weight-bold"
                             :class="item.statistics.expected_variation > 0 ? 'text-success' : 'text-danger'">
                        {{ Number(item.statistics.expected_variation).toFixed(1) }}
                      </small>
                      <small v-else class="text-muted font-weight-bold">-</small>
                    </div>
                    <div class="col-1 text-center title-box-w" style="padding-top: 2px">
                      {{ item.statistics.expected_sd > 0 ? Number(item.statistics.expected_sd).toFixed(2) : '-' }}
                    </div>
                    <div class="col-1 text-center title-box-w" style="padding-top: 2px">
                      <template v-if="item.statistics.expected_sd > 0">
                        {{
                          Number((item.statistics.expected_sd / Number(item.statistics.divided_expected_result).toFixed(1)) * 100).toFixed(1)
                        }} %
                      </template>
                      <template v-else>
                        -
                      </template>
                    </div>
                    <div class="col-2 text-center title-box-w" style="padding-top: 2px">
                      {{ item.statistics.expected_kg_mpa > 0 ? item.statistics.expected_kg_mpa : '-' }}
                    </div>
                  </div>
                </div>
                <div v-if="item.expand" v-for="level_feature in item.level_features">
                  <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                    <div class="row" style="height: 24px">
                      <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                        <template
                            v-if="Number(level_feature.statistics.result_expected / level_feature.count_travels).toFixed(1) != 0">
                          {{
                            Number(level_feature.statistics.result_expected / level_feature.count_travels).toFixed(1)
                          }} ({{level_feature.statistics.count_travel_with_expected_result}})
                        </template>
                        <template v-else>-</template>
                      </div>
                      <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                        <template v-if="level_feature.statistics.with_expected_accordance_percentage > 0">
                          {{ Math.round(level_feature.statistics.with_expected_accordance_percentage * 100) }} %
                        </template>
                        <template v-else>
                          {{Number(level_feature.statistics.divided_expected_result) > 0 ? '0%' : '-'}}
                        </template>
                      </div>
                      <div class="col-2 text-center title-box-w" style="padding-top: 2px">
                        <small v-if="level_feature.statistics.expected_variation != 0" class="font-weight-bold"
                               :class="level_feature.statistics.expected_variation > 0 ? 'text-success' : 'text-danger'">
                          {{ Number(level_feature.statistics.expected_variation).toFixed(1) }}
                        </small>
                        <small v-else class="text-muted font-weight-bold">-</small>
                      </div>
                      <div class="col-1 text-center title-box-w" style="padding-top: 2px">
                        {{
                          level_feature.statistics.expected_sd > 0 ? Number(level_feature.statistics.expected_sd).toFixed(2) : '-'
                        }}
                      </div>
                      <div class="col-1 text-center title-box-w" style="padding-top: 2px">
                        <template v-if="level_feature.statistics.expected_sd > 0">
                          {{
                            Number((level_feature.statistics.expected_sd / Number(level_feature.statistics.divided_expected_result).toFixed(1)) * 100).toFixed(1)
                          }} %
                        </template>
                        <template v-else>
                          -
                        </template>
                      </div>
                      <div class="col-2 text-center title-box-w" style="padding-top: 2px">
                        {{
                          level_feature.statistics.expected_kg_mpa > 0 ? level_feature.statistics.expected_kg_mpa : '-'
                        }}
                      </div>
                    </div>
                  </div>
                  <div v-if="level_feature.expand" v-for="type in level_feature.types">
                    <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                      <div class="row" style="height: 24px">
                        <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                          <template v-if="Number(type.statistics.result_expected / type.count_travels).toFixed(1) != 0">
                            {{ Number(type.statistics.result_expected / type.count_travels).toFixed(1) }} ({{type.statistics.count_travel_with_expected_result}})
                          </template>
                          <template v-else>-</template>
                        </div>
                        <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                          <template v-if="type.statistics.with_expected_accordance_percentage > 0">
                            {{ Math.round(type.statistics.with_expected_accordance_percentage * 100) }} %
                          </template>
                          <template v-else>
                            {{Number(type.statistics.divided_expected_result) > 0 ? '0%' : '-'}}
                          </template>
                        </div>
                        <div class="col-2 text-center title-box-w" style="padding-top: 2px">
                          <small v-if="type.statistics.expected_variation != 0" class="font-weight-bold"
                                 :class="type.statistics.expected_variation > 0 ? 'text-success' : 'text-danger'">
                            {{ Number(type.statistics.expected_variation).toFixed(1) }}
                          </small>
                          <small v-else class="text-muted font-weight-bold">-</small>
                        </div>
                        <div class="col-1 text-center title-box-w" style="padding-top: 2px">
                          {{ type.statistics.expected_sd > 0 ? Number(type.statistics.expected_sd).toFixed(2) : '-' }}
                        </div>
                        <div class="col-1 text-center title-box-w" style="padding-top: 2px">
                          <template v-if="type.statistics.expected_sd > 0">
                            {{
                              Number((type.statistics.expected_sd / Number(type.statistics.divided_expected_result).toFixed(1)) * 100).toFixed(1)
                            }} %
                          </template>
                          <template v-else>
                            -
                          </template>
                        </div>
                        <div class="col-2 text-center title-box-w" style="padding-top: 2px">
                          {{ type.statistics.expected_kg_mpa > 0 ? type.statistics.expected_kg_mpa : '-' }}
                        </div>
                      </div>
                    </div>
                    <div v-if="type.expand" v-for="slump_flow in type.slump_flows">
                      <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                        <div class="row" style="height: 24px">
                          <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                            <template
                                v-if="Number(slump_flow.statistics.result_expected / slump_flow.count_travels).toFixed(1) != 0">
                              {{ Number(slump_flow.statistics.result_expected / slump_flow.count_travels).toFixed(1) }} ({{slump_flow.statistics.count_travel_with_expected_result}})
                            </template>
                            <template v-else>-</template>
                          </div>
                          <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                            <template v-if="slump_flow.statistics.with_expected_accordance_percentage > 0">
                              {{ Math.round(slump_flow.statistics.with_expected_accordance_percentage * 100) }} %
                            </template>
                            <template v-else>
                              {{Number(slump_flow.statistics.divided_expected_result) > 0 ? '0%' : '-'}}
                            </template>
                          </div>
                          <div class="col-2 text-center title-box-w" style="padding-top: 2px">
                            <small v-if="slump_flow.statistics.expected_variation != 0" class="font-weight-bold"
                                   :class="slump_flow.statistics.expected_variation > 0 ? 'text-success' : 'text-danger'">
                              {{ Number(item.statistics.expected_variation).toFixed(1) }}
                            </small>
                            <small v-else class="text-muted font-weight-bold">-</small>
                          </div>
                          <div class="col-1 text-center title-box-w" style="padding-top: 2px">
                            {{
                              slump_flow.statistics.expected_sd > 0 ? Number(slump_flow.statistics.expected_sd).toFixed(2) : '-'
                            }}
                          </div>
                          <div class="col-1 text-center title-box-w" style="padding-top: 2px">
                            <template v-if="slump_flow.statistics.expected_sd > 0">
                              {{
                                Number((slump_flow.statistics.expected_sd / Number(slump_flow.statistics.divided_expected_result).toFixed(1)) * 100).toFixed(1)
                              }} %
                            </template>
                            <template v-else>
                              -
                            </template>
                          </div>
                          <div class="col-2 text-center title-box-w" style="padding-top: 2px">
                            {{
                              slump_flow.statistics.expected_kg_mpa > 0 ? slump_flow.statistics.expected_kg_mpa : '-'
                            }}
                          </div>
                        </div>
                      </div>
                      <div v-if="slump_flow.expand" v-for="maximum_size in slump_flow.maximum_sizes">
                        <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                          <div class="row" style="height: 24px">
                            <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                              <template
                                  v-if="Number(maximum_size.statistics.result_expected / maximum_size.count_travels).toFixed(1) != 0">
                                {{
                                  Number(maximum_size.statistics.result_expected / maximum_size.count_travels).toFixed(1)
                                }} ({{maximum_size.statistics.count_travel_with_expected_result}})
                              </template>
                              <template v-else>-</template>
                            </div>
                            <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                              <template v-if="maximum_size.statistics.with_expected_accordance_percentage > 0">
                                {{ Math.round(maximum_size.statistics.with_expected_accordance_percentage * 100) }} %
                              </template>
                              <template v-else>
                                {{Number(maximum_size.statistics.divided_expected_result) > 0 ? '0%' : '-'}}
                              </template>
                            </div>
                            <div class="col-2 text-center title-box-w" style="padding-top: 2px">
                              <small v-if="maximum_size.statistics.expected_variation != 0" class="font-weight-bold"
                                     :class="maximum_size.statistics.expected_variation > 0 ? 'text-success' : 'text-danger'">
                                {{ Number(maximum_size.statistics.expected_variation).toFixed(1) }}
                              </small>
                              <small v-else class="text-muted font-weight-bold">-</small>
                            </div>
                            <div class="col-1 text-center title-box-w" style="padding-top: 2px">
                              {{
                                maximum_size.statistics.expected_sd > 0 ? Number(maximum_size.statistics.expected_sd).toFixed(2) : '-'
                              }}
                            </div>
                            <div class="col-1 text-center title-box-w" style="padding-top: 2px">
                              <template v-if="maximum_size.statistics.expected_sd > 0">
                                {{
                                  Number((maximum_size.statistics.expected_sd / Number(maximum_size.statistics.divided_expected_result).toFixed(1)) * 100).toFixed(1)
                                }} %
                              </template>
                              <template v-else>
                                -
                              </template>
                            </div>
                            <div class="col-2 text-center title-box-w" style="padding-top: 2px">
                              {{
                                maximum_size.statistics.expected_kg_mpa > 0 ? maximum_size.statistics.expected_kg_mpa : '-'
                              }}
                            </div>
                          </div>
                        </div>
                        <div v-if="maximum_size.expand" v-for="contract_proposal in maximum_size.contract_proposals">
                          <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                            <div class="row" style="height: 24px">
                              <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                                <template
                                    v-if="Number(contract_proposal.statistics.result_expected / contract_proposal.count_travels).toFixed(1) != 0">
                                  {{
                                    Number(contract_proposal.statistics.result_expected / contract_proposal.count_travels).toFixed(1)
                                  }} ({{contract_proposal.statistics.count_travel_with_expected_result}})
                                </template>
                                <template v-else>-</template>
                              </div>
                              <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                                <template v-if="contract_proposal.statistics.with_expected_accordance_percentage > 0">
                                  {{
                                    Math.round(contract_proposal.statistics.with_expected_accordance_percentage * 100)
                                  }} %
                                </template>
                                <template v-else>
                                  {{Number(contract_proposal.statistics.divided_expected_result) > 0 ? '0%' : '-'}}
                                </template>
                              </div>
                              <div class="col-2 text-center title-box-w" style="padding-top: 2px">
                                <small v-if="contract_proposal.statistics.expected_variation != 0"
                                       class="font-weight-bold"
                                       :class="contract_proposal.statistics.expected_variation > 0 ? 'text-success' : 'text-danger'">
                                  {{ Number(contract_proposal.statistics.expected_variation).toFixed(1) }}
                                </small>
                                <small v-else class="text-muted font-weight-bold">-</small>
                              </div>
                              <div class="col-1 text-center title-box-w" style="padding-top: 2px">
                                {{
                                  contract_proposal.statistics.expected_sd > 0 ? Number(contract_proposal.statistics.expected_sd).toFixed(2) : '-'
                                }}
                              </div>
                              <div class="col-1 text-center title-box-w" style="padding-top: 2px">
                                <template v-if="contract_proposal.statistics.expected_sd > 0">
                                  {{
                                    Number((contract_proposal.statistics.expected_sd / Number(contract_proposal.statistics.divided_expected_result).toFixed(1)) * 100).toFixed(1)
                                  }} %
                                </template>
                                <template v-else>
                                  -
                                </template>
                              </div>
                              <div class="col-2 text-center title-box-w" style="padding-top: 2px">
                                {{
                                  contract_proposal.statistics.expected_kg_mpa > 0 ? contract_proposal.statistics.expected_kg_mpa : '-'
                                }}
                              </div>
                            </div>
                          </div>
                          <div v-if="contract_proposal.expand" v-for="number in contract_proposal.numbers">
                            <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                              <div class="row" style="height: 24px">
                                <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                                  {{ number.result_expected || '-' }}
                                </div>
                                <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                                  <template v-if="number.result_expected">
                                    <small class="text-success font-weight-bold" v-if="number.expected_has_accordance">SIM</small>
                                    <small class="text-danger font-weight-bold" v-else>NÃO</small>
                                  </template>
                                  <template v-else>
                                    <small>-</small>
                                  </template>
                                </div>
                                <div class="col-2 text-center title-box-w" style="padding-top: 2px">
                                  <small v-if="number.expected_variation != 0" class="font-weight-bold"
                                         :class="number.expected_variation > 0 ? 'text-success' : 'text-danger'">
                                    {{ Number(number.expected_variation).toFixed(1) }}
                                  </small>
                                  <small v-else class="text-muted font-weight-bold">-</small>
                                </div>
                                <div class="col-1 text-center title-box-w" style="padding-top: 2px">
                                  -
                                </div>
                                <div class="col-1 text-center title-box-w" style="padding-top: 2px">
                                  -
                                </div>
                                <div class="col-2 text-center title-box-w" style="padding-top: 2px">
                                  <template v-if="number.result_expected">
                                    <small> {{ number.expected_kg_mpa }}</small>
                                  </template>
                                  <template v-else>
                                    <small>-</small>
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--              <div class="col-12" v-for="travel in item.construction.travels" v-if="item.construction.expand">-->
              <!--                <div class="custom-box custom-border bg-white">-->
              <!--                  <div class="row" style="height: 24px">-->
              <!--                    <div class="col-4" style="height: 20px">-->
              <!--                      <span class="text-dark"> 1 </span>-->
              <!--                    </div>-->
              <!--                    <div class="col-4" style="height: 20px">-->
              <!--                      <span class="text-dark"> 1 </span>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->
            </template>
          </div>
        </div>
        <div style="left: 0 !important;padding-left: 0;padding-right: 0"
             class="col-md-3">
          <div class="row">
            <div class="col-12 card-sticky">
              <div class="custom-box bg-primary-1 pl-2" style="width: 100%">
                <div class="row" style="height: 24px">
                  <div class="col-3 title-box-w text-uppercase text-center">TEMPERATURA</div>
                  <div class="col-3 title-box-w text-uppercase text-center">CICLO TOTAL</div>
                  <div class="col-3 title-box-w text-uppercase text-center">CICLO CONCRETO</div>
                  <div class="col-3 title-box-w text-uppercase text-center">VOLUME MÉDIO</div>
                </div>
              </div>
            </div>
            <div class="col-12 card-sticky" style="top: 2.4rem !important">
              <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                <div class="row" style="height: 24px">
                  <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                    {{ Number(sumStatistics(items, 'temperature') / Object.values(items).length).toFixed(1) }} °
                  </div>
                  <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                    {{
                      minutesToTime(sumStatistics(items, 'performed_travel_cycle') / Object.values(items).length, true)
                    }}
                  </div>
                  <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                    {{
                      minutesToTime(sumStatistics(items, 'performed_concrete_cycle') / Object.values(items).length, true)
                    }}
                  </div>
                  <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                    {{ Number(sumStatistics(items, 'volume') / Object.values(items).length).toFixed(1) }}
                  </div>
                </div>
              </div>
            </div>
            <template v-for="item in items">
              <div class="col-12">
                <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                  <div class="row" style="height: 24px">
                    <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                      {{ item.statistics.temperature }} °
                    </div>
                    <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                      {{ minutesToTime(item.statistics.performed_travel_cycle, true) }}
                    </div>
                    <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                      {{ minutesToTime(item.statistics.performed_concrete_cycle, true) }}
                    </div>
                    <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                      {{ item.statistics.volume }}
                    </div>
                  </div>
                </div>
                <div v-if="item.expand" v-for="level_feature in item.level_features">
                  <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                    <div class="row" style="height: 24px">
                      <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                        {{ level_feature.statistics.temperature }} °
                      </div>
                      <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                        {{ minutesToTime(level_feature.statistics.performed_travel_cycle, true) }}
                      </div>
                      <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                        {{ minutesToTime(level_feature.statistics.performed_concrete_cycle, true) }}
                      </div>
                      <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                        {{ level_feature.statistics.volume }}
                      </div>
                    </div>
                  </div>
                  <div v-if="level_feature.expand" v-for="type in level_feature.types">
                    <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                      <div class="row" style="height: 24px">
                        <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                          {{ type.statistics.temperature }} °
                        </div>
                        <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                          {{ minutesToTime(type.statistics.performed_travel_cycle, true) }}
                        </div>
                        <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                          {{ minutesToTime(type.statistics.performed_concrete_cycle, true) }}
                        </div>
                        <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                          {{ type.statistics.volume }}
                        </div>
                      </div>
                    </div>
                    <div v-if="type.expand" v-for="slump_flow in type.slump_flows">
                      <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                        <div class="row" style="height: 24px">
                          <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                            {{ slump_flow.statistics.temperature }} °
                          </div>
                          <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                            {{ minutesToTime(slump_flow.statistics.performed_travel_cycle, true) }}
                          </div>
                          <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                            {{ minutesToTime(slump_flow.statistics.performed_concrete_cycle, true) }}
                          </div>
                          <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                            {{ slump_flow.statistics.volume }}
                          </div>
                        </div>
                      </div>
                      <div v-if="slump_flow.expand" v-for="maximum_size in slump_flow.maximum_sizes">
                        <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                          <div class="row" style="height: 24px">
                            <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                              {{ maximum_size.statistics.temperature }} °
                            </div>
                            <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                              {{ minutesToTime(maximum_size.statistics.performed_travel_cycle, true) }}
                            </div>
                            <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                              {{ minutesToTime(maximum_size.statistics.performed_concrete_cycle, true) }}
                            </div>
                            <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                              {{ maximum_size.statistics.volume }}
                            </div>
                          </div>
                        </div>
                        <div v-if="maximum_size.expand" v-for="contract_proposal in maximum_size.contract_proposals">
                          <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                            <div class="row" style="height: 24px">
                              <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                                {{ contract_proposal.statistics.temperature }} °
                              </div>
                              <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                                {{ minutesToTime(contract_proposal.statistics.performed_travel_cycle, true) }}
                              </div>
                              <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                                {{ minutesToTime(contract_proposal.statistics.performed_concrete_cycle, true) }}
                              </div>
                              <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                                {{ contract_proposal.statistics.volume }}
                              </div>
                            </div>
                          </div>
                          <div v-if="contract_proposal.expand" v-for="number in contract_proposal.numbers">
                            <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                              <div class="row" style="height: 24px">
                                <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                                  {{ number.temperature }} °
                                </div>
                                <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                                  {{ minutesToTime(number.performed_travel_cycle, true) }}
                                </div>
                                <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                                  {{ minutesToTime(number.performed_concrete_cycle, true) }}
                                </div>
                                <div class="col-3 text-center title-box-w" style="padding-top: 2px">
                                  {{ number.volume }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--              <div class="col-12" v-for="travel in item.construction.travels" v-if="item.construction.expand">-->
              <!--                <div class="custom-box custom-border bg-white">-->
              <!--                  <div class="row" style="height: 24px">-->
              <!--                    <div class="col-4" style="height: 20px">-->
              <!--                      <span class="text-dark"> 1 </span>-->
              <!--                    </div>-->
              <!--                    <div class="col-4" style="height: 20px">-->
              <!--                      <span class="text-dark"> 1 </span>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->
            </template>
          </div>
        </div>
        <div style="background: white;border: 1px solid white;left: 0 !important;padding-left: 0;padding-right: 0"
             class="col-md-2">
          <div class="row">
            <div class="col-12 card-sticky">
              <div class="custom-box bg-primary-1 pl-2" style="width: 100%">
                <div class="row" style="height: 24px">
                  <div class="col-6 title-box-w text-uppercase text-center">MCC</div>
                  <div class="col-6 title-box-w text-uppercase text-center" style="text-transform: none">CUSTO /MPa
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 card-sticky" style="top: 2.4rem !important">
              <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                <div class="row" style="height: 24px">
                  <div class="col-6 text-center title-box-w" style="padding-top: 2px">
                    {{ sumStatistics(items, 'theorical_adjusted') / Object.values(items).length | currency() }}
                  </div>
                  <div class="col-6 text-center title-box-w" style="padding-top: 2px">
                    {{
                      (sumStatistics(items, 'theorical_adjusted') / Object.values(items).length) / (sumStatistics(items, 'divided_result') / Object.values(items).length) | currency()
                    }}
                  </div>
                </div>
              </div>
            </div>
            <template v-for="item in items">
              <div class="col-12">
                <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                  <div class="row" style="height: 24px">
                    <div class="col-6 text-center title-box-w" style="padding-top: 2px">
                      {{ item.statistics.theorical_adjusted | currency() }}
                    </div>
                    <div class="col-6 text-center title-box-w" style="padding-top: 2px">
                      <template v-if="item.statistics.theorical_adjusted_cost != 0">
                        {{ item.statistics.theorical_adjusted_cost | currency() }}
                      </template>
                      <template v-else>
                        -
                      </template>
                    </div>
                  </div>
                </div>
                <div v-if="item.expand" v-for="level_feature in item.level_features">
                  <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                    <div class="row" style="height: 24px">
                      <div class="col-6 text-center title-box-w" style="padding-top: 2px">
                        {{ level_feature.statistics.theorical_adjusted | currency() }}
                      </div>
                      <div class="col-6 text-center title-box-w" style="padding-top: 2px">
                        <template v-if="level_feature.statistics.theorical_adjusted_cost">
                          {{ level_feature.statistics.theorical_adjusted_cost | currency() }}
                        </template>
                        <template v-else>
                          -
                        </template>
                      </div>
                    </div>
                  </div>
                  <div v-if="level_feature.expand" v-for="type in level_feature.types">
                    <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                      <div class="row" style="height: 24px">
                        <div class="col-6 text-center title-box-w" style="padding-top: 2px">
                          {{ type.statistics.theorical_adjusted | currency() }}
                        </div>
                        <div class="col-6 text-center title-box-w" style="padding-top: 2px">
                          <template v-if="type.statistics.theorical_adjusted_cost ">
                            {{ type.statistics.theorical_adjusted_cost | currency() }}
                          </template>
                          <template v-else>
                            -
                          </template>
                        </div>
                      </div>
                    </div>
                    <div v-if="type.expand" v-for="slump_flow in type.slump_flows">
                      <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                        <div class="row" style="height: 24px">
                          <div class="col-6 text-center title-box-w" style="padding-top: 2px">
                            {{ slump_flow.statistics.theorical_adjusted | currency() }}
                          </div>
                          <div class="col-6 text-center title-box-w" style="padding-top: 2px">
                            <template v-if="slump_flow.statistics.theorical_adjusted_cost ">
                              {{ slump_flow.statistics.theorical_adjusted_cost | currency() }}
                            </template>
                            <template v-else>
                              -
                            </template>
                          </div>
                        </div>
                      </div>
                      <div v-if="slump_flow.expand" v-for="maximum_size in slump_flow.maximum_sizes">
                        <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                          <div class="row" style="height: 24px">
                            <div class="col-6 text-center title-box-w" style="padding-top: 2px">
                              {{ maximum_size.statistics.theorical_adjusted | currency() }}
                            </div>
                            <div class="col-6 text-center title-box-w" style="padding-top: 2px">
                              <template v-if="maximum_size.statistics.theorical_adjusted_cost ">
                                {{ maximum_size.statistics.theorical_adjusted_cost | currency() }}
                              </template>
                              <template v-else>
                                -
                              </template>
                            </div>
                          </div>
                        </div>
                        <div v-if="maximum_size.expand" v-for="contract_proposal in maximum_size.contract_proposals">
                          <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                            <div class="row" style="height: 24px">
                              <div class="col-6 text-center title-box-w" style="padding-top: 2px">
                                {{ contract_proposal.statistics.theorical_adjusted | currency() }}
                              </div>
                              <div class="col-6 text-center title-box-w" style="padding-top: 2px">
                                <template v-if="contract_proposal.statistics.theorical_adjusted_cost ">
                                  {{ contract_proposal.statistics.theorical_adjusted_cost | currency() }}
                                </template>
                                <template v-else>
                                  -
                                </template>
                              </div>
                            </div>
                          </div>
                          <div v-if="contract_proposal.expand" v-for="number in contract_proposal.numbers">
                            <div class="custom-box custom-border bg-primary-1" style="width: 100%">
                              <div class="row" style="height: 24px">
                                <div class="col-6 text-center title-box-w" style="padding-top: 2px">
                                  {{ number.theorical_adjusted | currency() }}
                                </div>
                                <div class="col-6 text-center title-box-w" style="padding-top: 2px">
                                  <template v-if="number.result">
                                    {{ number.theorical_adjusted_cost | currency() }}
                                  </template>
                                  <template v-else>
                                    -
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--              <div class="col-12" v-for="travel in item.construction.travels" v-if="item.construction.expand">-->
              <!--                <div class="custom-box custom-border bg-white">-->
              <!--                  <div class="row" style="height: 24px">-->
              <!--                    <div class="col-4" style="height: 20px">-->
              <!--                      <span class="text-dark"> 1 </span>-->
              <!--                    </div>-->
              <!--                    <div class="col-4" style="height: 20px">-->
              <!--                      <span class="text-dark"> 1 </span>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->
            </template>
          </div>
        </div>
      </div>
    </div>
    <horizontal-scrolling :parent-refs="$refs"></horizontal-scrolling>
    <ModalAdjustment ref="ModalAdjustment"/>
    <loading-pagination :show="false"/>
  </div>
</template>

<script>
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb.vue";
import PuzlSelect from "@/components/PuzlSelect.vue";
import InputDatePicker from "@/components/InputDatePicker.vue";
import {mapGetters} from "vuex";
import {period_types} from "@/views/Modules/Technology/Mix/helpers/mixe";
import {minutesToTime} from "@/helpers";
import globalFilter from "@/mixins/globalFilter";
import ModalAdjustment from '../../Operational/Schedule/Charging/Shared/_ModalAdjustments.vue'
import MultiFilter from "../../../../components/Utils/MultiFilterV2.vue";
import LoadingPagination from "@/components/LoadingPagination.vue";
import HorizontalScrolling from "@/components/HorizontalScrolling.vue";
export default {
  name: "Index",
  mixins: [globalFilter],
  components: {
    HorizontalScrolling,
    LoadingPagination,
    InputDatePicker,
    PuzlSelect,
    PuzlBreadcrumb,
    ModalAdjustment,
    MultiFilter
  },
  data() {
    return {
      format_mixe: period_types,
      minutesToTime: minutesToTime,
      items: [],
      started_items: [],
      filter: {
        hardened_state_id: null,
        feature_id: null,
        level_feature: null,
        maximum_size_id: null,
        range: []
      },
      range: {
        start: new Date(),
        end: new Date(),
      },
      loadingMaximumSize: false,
      categories: {
        11: "Reaproveitamento - Reuso",
        12: "Redirecionamento - Reuso",
        21: "Sobra de Concreto - descarte",
        22: "Perda de Concreto - descarte",
      },
    }
  },
  computed: {
    ...mapGetters({
      $_hardened_states: "testHardenedState/fetch",
      $_features: "mixFeature/activeItems",
      $_maximum_sizes: 'mixMaximumSize/activeItems',
    }),
    listFeature() {
      return this.$_features.map(feature => {
        feature.period_formated = this.format_mixe(feature.period, feature.period_type)
        feature.measured_unit_name = this.letter_unit(feature.measured_unit.initials)
        feature.name = (feature.period && feature.short_description + ' ' + this.format_mixe(feature.period, feature.period_type)).toUpperCase() + ' ' + this.letter_unit(feature.measured_unit.initials) || feature.short_description
        return feature
      }, this)
    }
  },
  async mounted() {
    let loader = this.$loading.show()
    await this.$store.dispatch("testHardenedState/fetchItems")
    await this.$store.dispatch("mixFeature/fetchItems")
    this.loadingMaximumSize = true
    this.$store.dispatch('mixMaximumSize/fetchItems').then(() => {
      this.loadingMaximumSize = false
    });
    this.filter.hardened_state_id = 1
    this.filter.feature_id = 1
    await this.init()
    loader.hide()
  },
  methods: {
    setTitleReuse(type) {
      return this.categories[Number(type)];
    },
    collapse_items(items) {
      for (let key in items) {
        items[key].expand = false
        this.collapse(items[key])
      }
    },
    has_one_collapsed(items) {
      return Object.values(items).find((item) => item.expand)
    },
    calcVariation(a, b) {
      if (!a) {
        return 0
      }
      return Number(parseFloat(Number(a) - Number(b)).toFixed(1))
    },
    handleFilterDate(filter) {
      this.range = filter
      this.init()
    },
    letter_unit(letter) {
      if (letter === "nenhum") {
        return "";
      }
      return letter;
    },
    /**
     * Obtém os ajustes associados a uma viagem e exibe-os em um modal
     * @param {string} schedule_travel_uuid - O UUID da viagem
     */
    showAdjustments(schedule_travel_uuid) {
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'})
      this.$store
          .dispatch("travels/getAdjustments",
              {schedule_travel_uuid: schedule_travel_uuid, mix_design_mixe_id: null}
          )
          .then(response => {
            this.$Progress.finish();
            this.$notify({type: "success", message: response.message});
            this.modal_shown = true
            this.$refs.ModalAdjustment.openModal();
          })
          .catch(error => {
            this.$Progress.finish();
          });
    },
    updateNumber(number) {
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'})
      this.$store
          .dispatch("travels/show",
              {uuid: number.schedule_travel_uuid, code: '0'})
          .then(response => {
            this.$Progress.finish();
            this.$notify({type: "success", message: response.message});
          })
          .catch(error => {
            this.$Progress.finish();
          });
    },
    init(filter = null) {
      if (filter && filter.global) {
        this.filter.global = filter.global
      }
      else {
        this.filter.global = []
      }
      let loader = this.$loading.show()
      this.filter.range = this.range
      this.$store.dispatch('technicalDre/fetchItems', this.filter).then(response => {
        this.started_items = {...response.data}
        /*
          Realiza o agrupamento por responsabilidade
        */
        let features = this.getFeatures(response.data)
        /* Percorre as responsabilidades pra agrupar os itens */
        for (let key in features) {
          let list = features[key]
          features[key] = {
            expand: false,
            title: features[key][0].encoded.feature,
            volume: this.sum(features[key], 'volume'),
            statistics: this.getStatistics(list),
            count_travels: Object.values(this.groupBy(list, 'schedule_travel_id')).length,
            level_features: this.groupBy(list, 'level_feature')
          }
          /*
            @var key_lv indíce array nível de aplicação
            Percorre nivel de aplicação e agrupa os tipos
           */
          for (let key_lv in features[key].level_features) {
            let lv_list = features[key].level_features[key_lv]
            features[key].level_features[key_lv] = {
              expand: false,
              title: lv_list[0].encoded.level_feature,
              statistics: this.getStatistics(lv_list),
              volume: this.sum(lv_list, 'volume'),
              count_travels: Object.values(this.groupBy(lv_list, 'schedule_travel_id')).length,
              types: this.groupBy(lv_list, 'type_id')
            }
            /*
            @var key_type indíce array tipo
            Percorre tipo agrupa os slumps
             */
            for (let key_type in features[key].level_features[key_lv]['types']) {
              let type_list = features[key].level_features[key_lv]['types'][key_type]
              features[key].level_features[key_lv]['types'][key_type] = {
                expand: false,
                title: type_list[0].encoded.type,
                statistics: this.getStatistics(type_list),
                volume: this.sum(type_list, 'volume'),
                count_travels: Object.values(this.groupBy(type_list, 'schedule_travel_id')).length,
                slump_flows: this.groupBy(type_list, 'slump_flow_code')
              }

              for (let key_slump_flow in features[key].level_features[key_lv]['types'][key_type]['slump_flows']) {
                let sf_list = features[key].level_features[key_lv]['types'][key_type]['slump_flows'][key_slump_flow]
                features[key].level_features[key_lv]['types'][key_type]['slump_flows'][key_slump_flow] = {
                  expand: false,
                  title: sf_list[0].encoded.slump_flow + (sf_list[0].encoded.slump_final ? ' | ' + sf_list[0].encoded.slump_final : ''),
                  volume: this.sum(sf_list, 'volume'),
                  statistics: this.getStatistics(sf_list),
                  count_travels: Object.values(this.groupBy(sf_list, 'schedule_travel_id')).length,
                  maximum_sizes: this.groupBy(sf_list, 'maximum_size_id')
                }
                for (let key_d_max in features[key].level_features[key_lv]['types'][key_type]['slump_flows'][key_slump_flow]['maximum_sizes']) {
                  let ms_list = features[key].level_features[key_lv]['types'][key_type]['slump_flows'][key_slump_flow]['maximum_sizes'][key_d_max]
                  features[key].level_features[key_lv]['types'][key_type]['slump_flows'][key_slump_flow]['maximum_sizes'][key_d_max] = {
                    expand: false,
                    title: ms_list[0].encoded.maximum_size,
                    volume: this.sum(ms_list, 'volume'),
                    statistics: this.getStatistics(ms_list),
                    count_travels: Object.values(this.groupBy(ms_list, 'schedule_travel_id')).length,
                    contract_proposals: this.groupBy(ms_list, 'contract_proposal_id')
                  }
                  for (let key_contract_proposal in features[key].level_features[key_lv]['types'][key_type]['slump_flows'][key_slump_flow]['maximum_sizes'][key_d_max]['contract_proposals']) {
                    let cp_list = features[key].level_features[key_lv]['types'][key_type]['slump_flows'][key_slump_flow]['maximum_sizes'][key_d_max]['contract_proposals'][key_contract_proposal]
                    features[key].level_features[key_lv]['types'][key_type]['slump_flows'][key_slump_flow]['maximum_sizes'][key_d_max]['contract_proposals'][key_contract_proposal] = {
                      expand: false,
                      title: cp_list[0].encoded.contract_proposal,
                      statistics: this.getStatistics(cp_list),
                      count_travels: Object.values(this.groupBy(cp_list, 'schedule_travel_id')).length,
                      volume: this.sum(cp_list, 'volume'),
                      numbers: this.getNumbers(cp_list)
                    }
                  }
                }
              }
            }
          }
        }
        features = Object.values(features).sort((a, b) => (a.count_travels > b.count_travels) ? 1 : -1)
        this.items = features
        loader.hide()
      })
    },
    getStatistics(arr) {
      let numbers = this.getNumbers(arr)
      let total_items = Object.values(numbers).length
      let statistics = {
        binder: 0,
        result: 0,
        divided_result: 0, /* MEDIA DE RESULTADOS COM ENSAIO QUE POSSUA RESULTADO */
        divided_expected_result: 0, /* MEDIA DE RESULTADOS ESTIMADOS COM ENSAIO QUE POSSUA RESULTADO ESTIMADO */
        result_expected: 0,
        with_accordance_percentage: 0,
        with_expected_accordance_percentage: 0,
        variation: 0,
        kg_mpa: 0,
        expected_variation: 0,
        expected_kg_mpa: 0,
        temperature: 0,
        theorical_adjusted: 0,
        theorical_adjusted_normal: 0,
        theorical_adjusted_cost: 0,
        performed_travel_cycle: 0,
        performed_concrete_cycle: 0,
        volume: 0,
        indexes: 0,
        expected_sd: 0,
        sd: 0,
        count_travel_with_result: 0 /* NÚMERO DE VIAGENS QUE POSSUI RESULTADO */,
        count_travel_with_expected_result: 0,
      }

      let tests_with_accordance = 0
      let tests_expected_with_accordance = 0

      for (let key in numbers) {
        statistics.indexes += 1
        statistics.binder += Number(numbers[key].adjusted_binder ? numbers[key].adjusted_binder : 0)
        statistics.result += numbers[key].result ? Number(numbers[key].result) : 0
        statistics.result_expected += numbers[key].result_expected ? Number(numbers[key].result_expected) : 0
        numbers[key].expected_has_accordance = Number(numbers[key].result_expected) >= Number(numbers[key].description)
        numbers[key].expected_variation = this.calcVariation(numbers[key].result_expected, numbers[key].description)
        numbers[key].expected_kg_mpa = numbers[key].result_expected ? Number(numbers[key].adjusted_binder / numbers[key].result_expected).toFixed(1) : 0
        numbers[key].has_accordance = Number(numbers[key].result) >= Number(numbers[key].description)
        numbers[key].variation = this.calcVariation(numbers[key].result, numbers[key].description)
        numbers[key].kg_mpa = numbers[key].result ? Number(numbers[key].adjusted_binder / numbers[key].result).toFixed(1) : 0
        numbers[key].temperature = Math.round(numbers[key].temperature)
        // CUSTO / MPA
        numbers[key].theorical_adjusted_cost = numbers[key].theorical_adjusted ? (numbers[key].theorical_adjusted / numbers[key].result) : 0
        statistics.variation += numbers[key].variation ? Number(numbers[key].variation) : 0
        statistics.kg_mpa += numbers[key].kg_mpa ? Number(numbers[key].kg_mpa) : 0
        statistics.expected_variation += numbers[key].expected_variation ? Number(numbers[key].expected_variation) : 0
        statistics.expected_kg_mpa += numbers[key].expected_kg_mpa ? Number(numbers[key].expected_kg_mpa) : 0
        statistics.temperature += Number(numbers[key].temperature)
        statistics.performed_travel_cycle += Number(numbers[key].performed_travel_cycle)
        statistics.performed_concrete_cycle += Number(numbers[key].performed_concrete_cycle)
        statistics.volume += Number(numbers[key].volume)
        statistics.theorical_adjusted += numbers[key].result ? Number(numbers[key].theorical_adjusted) : 0
        statistics.theorical_adjusted_normal += Number(numbers[key].theorical_adjusted)
        if (numbers[key].result) {
          statistics.count_travel_with_result += 1
        }
        if (numbers[key].result_expected) {
          statistics.count_travel_with_expected_result += 1
        }
        if (numbers[key].has_accordance) {
          tests_with_accordance += 1
        }
        if (numbers[key].expected_has_accordance) {
          tests_expected_with_accordance += 1
        }
      }
      statistics.with_accordance_percentage = tests_with_accordance > 0 ? (tests_with_accordance / statistics.count_travel_with_result) : 0
      statistics.with_expected_accordance_percentage = tests_expected_with_accordance / statistics.count_travel_with_expected_result
      statistics.binder = parseFloat(statistics.binder / total_items)
      statistics.temperature = parseFloat(statistics.temperature / total_items)
      statistics.kg_mpa = parseFloat(statistics.kg_mpa / statistics.count_travel_with_result).toFixed(1)
      statistics.expected_variation = statistics.count_travel_with_expected_result > 0 ? statistics.expected_variation / statistics.count_travel_with_expected_result : statistics.expected_variation
      statistics.variation = statistics.count_travel_with_result > 0 ? statistics.variation / statistics.count_travel_with_result : statistics.variation
      statistics.expected_kg_mpa = parseFloat(statistics.expected_kg_mpa / statistics.count_travel_with_expected_result).toFixed(1)
      statistics.volume = parseFloat(statistics.volume / total_items).toFixed(1)
      statistics.performed_travel_cycle = parseFloat(statistics.performed_travel_cycle / total_items)
      statistics.theorical_adjusted = Number(statistics.theorical_adjusted / statistics.count_travel_with_result)
      statistics.divided_result = Number(statistics.result / statistics.count_travel_with_result)
      statistics.divided_expected_result = Number(statistics.result_expected / statistics.count_travel_with_expected_result)
      statistics.theorical_adjusted_cost = Number(statistics.theorical_adjusted / statistics.divided_result)

      if (statistics.count_travel_with_result > 1) {
        let sds = 0
        for (let key in numbers) {
          const travel = numbers[key]
          if (travel.result) {
            sds += Math.pow(travel.result - statistics.divided_result, 2) / (statistics.count_travel_with_result - 1)
          }
        }
        statistics.sd = Math.pow(sds, 0.5)
      }

      if (statistics.count_travel_with_expected_result > 1) {
        let sds = 0
        for (let key in numbers) {
          const travel = numbers[key]
          if (travel.result_expected) {
            sds += Math.pow(travel.result_expected - statistics.divided_expected_result, 2) / (statistics.count_travel_with_expected_result - 1)
          }
        }
        statistics.expected_sd = Math.pow(sds, 0.5)
      }

      if (!statistics.theorical_adjusted) {
        statistics.theorical_adjusted = Number(statistics.theorical_adjusted_normal / Object.values(numbers).length)
      }
      statistics.divided_result = !isNaN(statistics.divided_result) ? statistics.divided_result : 0
      statistics.performed_concrete_cycle = Math.round(statistics.performed_concrete_cycle / Object.values(numbers).length)
      return statistics
    },
    /**
     * Realiza a soma do campo especificado
     * @param arr
     * @param field
     * @returns {unknown}
     */
    sum(arr, field) {
      let items = this.getNumbers(arr)
      return Object.values(items).reduce((b, a) => b + Number(a[field]), 0);
    },
    sum1(arr, field) {
      return Object.values(arr).reduce((b, a) => b + Number(a[field] ? a[field] : 0), 0);
    },
    sumStatistics(arr, field) {
      return Object.values(arr).reduce((b, a) => b + Number(a.statistics[field] ? a.statistics[field] : 0), 0);
    },
    sumVolume() {
      return Object.values(this.getNumbers(this.started_items)).length
    },
    /**
     * @param number
     * @returns {string}
     */
    formatNumberToHumans(number) {
      return number.toString().replace('.', ',')
    },
    /**
     * Busca as responsabilidades agrupadas
     * @param data
     * @returns {unknown}
     */
    getFeatures(data) {
      return this.groupBy(data, 'feature_code')
    },
    /**
     * Agrupa viagens
     * @param arr
     * @returns {{}}
     */
    getNumbers(arr) {
      let numbers = {}
      for (let key in arr) {
        let id = arr[key].schedule_travel_id
        if (!numbers[id]) {
          numbers[id] = arr[key]
        }
      }
      return numbers
    },
    /**
     * Realiza o agrupamento dos arrays para inserção na tabela
     * @param arr
     * @param field
     * @param fields
     * @returns {unknown}
     */
    groupBy(arr, field, fields = []) {
      let array = Object.values(arr)
      let reduced = array.reduce((group, product) => {
        const feature_code = product[field];
        group[feature_code] = group[feature_code] ?? [];
        group[feature_code].push(product);
        return group;
      }, {})
      delete reduced[undefined]
      return reduced
    },
    /**
     * Fecha todos os collapses
     * @param obj
     */
    collapse(obj) {
      if (obj && obj.expand) {
        obj.expand = false
      }
      for (const key in obj) {
        let is_arr = typeof obj[key] === 'string'
        if (!is_arr) {
          if (obj[key]) {
            Object.values(obj[key]).forEach(item => {
              if (item && item.expand) {
                item.expand = false
              }
              for (let i in item) {
                let i_is_arr = typeof item[i] === 'string'
                if (!i_is_arr && typeof item[i] !== "boolean" && item[i]) {
                  this.collapse(item[i]);
                }
              }
            });
          }
        }
      }
    },
    /**
     * Abre todos os collapses
     * @param obj
     */
    uncollapse(obj) {
      if (obj && obj.hasOwnProperty('expand')) {
        obj.expand = true
      }
      for (let key in obj) {
        if (typeof obj[key] === 'object') {
          this.uncollapse(obj[key])
        }
      }
    }
  }
}
</script>

<style scoped>

* {
//font-family: Quicksand; font-size: 12px !important;
}

/* The heart of the matter */
.testimonial-group > .row {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}


.testimonial-group > .row > .col-md-4, .col-md-3, .col-md-2, .col-md-2-sm, .col-md-1-sm {
  display: inline-block;
}

@media (max-width: 1440px) and (min-width: 1080px) {
  /*.testimonial-group > .row >, .col-md-3 {*/
  /*  max-width: 23vw !important;*/
  /*}*/
  .margin-second-row {
    margin-left: -6.2vw;
  }

  .second-stat {
    max-width: 20vw !important;
  }

  .margin {
    margin-left: -4.9vw !important;
  }
}

@media (max-width: 1440px) and (min-width: 1080px) {
  /*.testimonial-group > .row > .col-md-4, .col-md-3 {*/
  /*  max-width: 28vw !important;*/
  /*}*/
  .margin-second-row {
    margin-left: -6.2vw;
  }

  .second-stat {
    max-width: 28vw !important;
  }

  .margin {
    margin-left: -4.9vw !important;
  }
}

@media (min-width: 1441px) {
  .testimonial-group > .row > .col-md-4, .col-md-3 {
    max-width: 33vw !important;
  }

  .margin-second-row {
    margin-left: -5vw;
  }

  .second-stat {
    max-width: 18vw !important;
  }

  .margin {
    margin-left: -3.7vw !important;
  }
}

@media (max-width: 1080px) {
  .col-md-2 {
    max-width: 15vw !important;
  }

  .col-md-4 {
    max-width: 40vw !important;
  }

  .col-md-1-sm {
    max-width: 9vw !important;
  }

  .col-md-2-sm {
    max-width: 15vw !important;
  }

  .col-md-3 {
    max-width: 25vw !important;
  }

  .second-stat {
    left: 30vw !important;
  }
}

@media (min-width: 1081px) {
  .col-md-2 {
    max-width: 15vw !important;
  }

  .col-md-4 {
    max-width: 32vw !important;
  }

  .col-md-3 {
    max-width: 32vw !important;
  }

  .col-md-1-sm {
    max-width: 7vw !important;
  }

  .col-md-2-sm {
    max-width: 10vw !important;
  }
}

.grid-item--header {
  position: sticky;
  position: -webkit-sticky;
  background: white;
  top: 0;
}

.custom-box {
  border: 1px solid #bdb3b3;
  color: white;
  border-radius: 2px;
  padding: 5px;
}


.card-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

.title-box {
  color: #383636;
  font-size: 13px;
  font-weight: 400;
  text-transform: none;
}

.title-box-w {
  color: #383636;
  font-size: 13px;
  font-weight: 400;
  text-transform: none;
}

.grid-col--fixed-left {
  position: sticky;
  left: 0;
  z-index: 1;
}

.body-box {
  padding-top: 8px;
  height: 40px;
  color: #383636;
  border-radius: 2px;
}

.bg-grey-w {
  background-color: white !important
}

.custom-border {
  border: 1px solid #bdb3b3;
}

.final-sum {
  border-left: none !important;
}

.bg-light-custom {
  background: white !important;
}

.bg-primary-2 {
  border: 1px solid #bdb3b3;
  background-color: white;
}

.bg-primary-1 {
  background-color: white !important;
}

.text-muted-w {
  color: #fefeff !important;
}

.nv-1 {
  padding-left: 0.5rem !important;
}

.nv-2 {
  padding-left: 1.1rem !important;
}

.nv-3 {
  padding-left: 1.9rem !important;
}

.nv-4 {
  padding-left: 2.65rem !important;
}

.nv-5 {
  padding-left: 3.7rem !important;
}

.nv-6 {
  padding-left: 5rem !important;
}

.nv-7 {
  padding-left: 6.2rem !important;
}

.nv-8 {
  padding-left: 9rem !important;
}

#stats > .card-body {
  padding: 1.5rem 0.2rem !important;
}

.bounce-enter-active {
  animation: bounce-in 0.3s;
}

.bounce-leave-active {
  animation: bounce-in 0.3s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}


</style>
