<template>
  <div>
    <div class="container-fluid">
      <div style="border-radius: 0 !important" class="card">
        <div class="card-body">
          <div class="col-md-12 p-0 table-responsive">
            <table class="table table-sm table-bordered">
              <thead>
                <tr class="fixed">
                  <th class="text-center">Emissão</th>
                  <th class="text-center">Entrada</th>
                  <th class="text-center">Valor</th>
                  <th class="text-center">Documento</th>
                  <th class="text-center">Frete</th>
                  <th class="text-center">Tipo</th>
                  <th class="text-center">Fornecedor</th>
                  <th class="text-center">CPF/CNPJ</th>
                  <th class="text-center">Status</th>
                  <th class="text-center">Detalhes</th>
                  <th class="text-center">Ações</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="loadingSkeleton">
                  <tr v-for="(item, skeletonIndex) in 10" :key="skeletonIndex">
                    <th colspan="11">
                      <skeleton-puzl type="button" />
                    </th>
                  </tr>
                </template>
                <template v-else>
                  <tr v-for="(item, index) in $_items" :key="index">
                    <th>{{ item.issue_date }}</th>
                    <th v-if="item.entry_date">{{ item.entry_date | parseDate("DD MMM YYYY") }}</th>
                    <th v-else>{{ item.issue_date }}</th>
                    <th>{{ item.final_value | currency() }}</th>
                    <th>{{ item.document_number }}</th>
                    <th>
                      <div class="d-flex justify-content-between fs-11 font-weight-700">
                        {{ item.freight_id ? item.referenced_entry_launch.slice(0, -13) : null }}
                        <img
                          role="button"
                          @click.prevent="handleModalFreight(item)"
                          v-if="item.freight_id"
                          height="20px"
                          src="/img/icons/icons8/ios/in-transit_primary.png"
                        />
                      </div>
                    </th>
                    <th>{{ item.type_name }}</th>
                    <th>{{ item.entity_name }}</th>
                    <th>{{ item.document }}</th>
                    <th class="text-center">
                      <div v-if="![5, 6].includes(item.status)">
                        <el-popover trigger="hover" placement="bottom" class="p-0 text-center">
                          <span v-if="item.near_due_date"> {{ item.near_due_date | parseDate("DD MMM YYYY") }} </span>
                          <span v-else> {{ getText(item.status) }} </span>
                          <h4 slot="reference" class="font-weight-normal m-0 p-0">
                            <a class="m-0">
                              <badge :class="`mini-card-${getColor(item.status)}`" tag="a" class="mini-card" style="">
                                {{ getText(item.status) }}
                              </badge>
                            </a>
                          </h4>
                        </el-popover>
                      </div>

                      <div v-else class="new-default-black-font fs-30 mb-0" style="display: flex; align-items: center">
                        <div class="mb-2 mt-1" style="display: inline-flex">
                          <el-popover trigger="hover" placement="bottom" class="p-0 text-center">
                            <h4 slot="reference" class="font-weight-normal m-0 p-0">
                              <a class="m-0">
                                <badge :class="`mini-card-${getColor(item.status)}`" tag="a" class="mini-card" style="">
                                  {{ getText(item.status) }}
                                </badge>
                              </a>
                            </h4>
                          </el-popover>
                        </div>
                      </div>
                    </th>
                    <th class="text-center justify-content-center">
                      <el-popover trigger="hover" placement="bottom" class="p-0">
                        <div v-if="item.operation_source_id">
                          <h4 class="new-default-black-font">
                            <img width="20px" height="20px" src="/img/brand/logo.png" class="mr-2 mt-n1" />
                            Tipo de Operação Puzl(TOP)
                          </h4>
                          <h4 class="mb-0 new-default-black-font fs-12 font-weight-normal">
                            {{ item.operation_source_name }}
                          </h4>
                        </div>
                        <hr style="border-top: 2px solid rgba(0, 0, 0, 0.1)" class="mt-2 mb-2" />

                        <div v-if="item.company_plant_id">
                          <h4 class="new-default-black-font">
                            <img width="20px" height="20px" src="/img/icons/chemical-plant-black.png" class="mr-2 mt-n1" />
                            Central
                          </h4>
                          <h4 class="mb-1 new-default-black-font fs-12 font-weight-normal">
                            {{ item.company_plant_name }}
                          </h4>
                          <h4 class="mb-0 new-default-black-font fs-12 font-weight-normal">
                            {{ item.buyer_ein }}
                          </h4>
                        </div>
                        <hr style="border-top: 2px solid rgba(0, 0, 0, 0.1)" class="mt-2 mb-2" />

                        <div>
                          <h4 v-if="item.external_type === 1 && ![5, 6].includes(item.status)" class="new-default-black-font">
                            <img
                              width="20px"
                              height="20px"
                              src="/img/icons/icons8/ios/downloading-updates-primary.png"
                              class="mr-2 mt-n1"
                            />
                            Sefaz
                          </h4>
                          <h4 v-else class="new-default-black-font">
                            <img width="20px" height="20px" src="/img/icons/icons8/ios/plus-math-success.png" class="mr-2 mt-n1" />
                            Manual
                          </h4>
                          <h4 class="mb-1 new-default-black-font fs-12 font-weight-normal">
                            {{ item.importer_user_name }}
                          </h4>
                          <h4 class="new-default-gray-font font-weight-400 mb-0 2 d-flex align-items-center">
                            <span class="fs-12" v-if="item.imported_at"> {{ item.imported_at | parseDate("DD MMM YYYY") }}&nbsp;</span>
                          </h4>
                        </div>

                        <a slot="reference" href="#" class="ml-1">
                          <img src="/img/icons/info.png" width="20px" height="20px" />
                        </a>
                      </el-popover>
                      <a v-if="item.photo" @click.prevent="handleModalAttachment(item.photo)" href="#" class="ml-1">
                        <img src="/img/icons/attach-green.png" width="20px" height="20px" />
                      </a>
                      <a
                        @click.prevent="handleModalListEntryLaunchItems(item.id)"
                        v-show="![5, 6].includes(item.status)"
                        href="#"
                        class="ml-1"
                      >
                        <img src="/img/icons/icons8/ios/menu-squared-2-dark.png" width="20px" height="20px" />
                      </a>
                      <!-- Nota mãe -->
                      <el-popover
                        v-if="item.status !== EntryLaunchStatusEnum.WAITING && (item.is_entry_value || item.parent_id)"
                        trigger="hover"
                        placement="bottom"
                        class="p-0"
                      >
                        <!-- Com saldo -->
                        <h4
                          v-if="item.is_entry_value > 0 && (Number(item.children_balance) > 0 || item.children_balance === null)"
                          class="new-default-black-font font-weight-normal"
                        >
                          Saldo : {{ (item.children_balance !== null ? item.children_balance : item.total_value) | currency() }}
                        </h4>
                        <!-- Sem saldo -->
                        <h4
                          v-else-if="item.is_entry_value > 0 && Number(item.children_balance) <= 0 && item.children_balance !== null"
                          class="new-default-black-font font-weight-normal"
                        >
                          NOTA MAE S/ SALDO
                        </h4>
                        <a slot="reference" role="button" class="ml-1">
                          <img :src="getParentOrChildrenIcon(item)" width="22px" />
                        </a>
                      </el-popover>
                      <el-popover
                        v-if="item.has_cmc_without_mix_reference || item.has_cmc_adjustment_without_dosage_location"
                        trigger="hover"
                        placement="bottom"
                        class="p-0"
                      >
                        <div v-show="item.has_cmc_without_mix_reference">
                          <h4 class="new-default-black-font">
                            <img width="20px" height="20px" src="/img/icons/icons8/ios/star--v1_success.png" class="mr-2 mt-n2" />
                            Referência comercial
                          </h4>
                          <h4 class="mb-0 new-default-black-font fs-12 font-weight-normal">Mcc não estava definido como referência</h4>
                        </div>
                        <hr
                          v-show="item.has_cmc_adjustment_without_dosage_location && item.has_cmc_without_mix_reference"
                          style="border-top: 2px solid rgba(0, 0, 0, 0.1)"
                          class="mt-2 mb-2"
                        />
                        <div v-show="item.has_cmc_adjustment_without_dosage_location">
                          <h4 class="new-default-black-font">
                            <img width="20px" height="20px" src="/img/icons/icons8/ios/chemical-plant-v1.png" class="mr-2 mt-n2" />
                            Mapa de ponto de carga
                          </h4>
                          <h4 class="mb-0 new-default-black-font fs-12 font-weight-normal">Mcc não estava disponível</h4>
                        </div>

                        <a slot="reference" role="button" class="ml-1">
                          <img src="/img/icons/icons8/ios/break--v2_warning.png" width="20px" height="20px" />
                        </a>
                      </el-popover>
                    </th>
                    <th class="text-center">
                      <base-dropdown menuOnRight>
                        <div slot="title-container" class="dropdown-toggle rounded m-0">
                          <img width="32" src="/img/icons/icons8/ios/settings--v1_primary.png" />
                        </div>
                        <a
                          v-if="![5, 6].includes(item.status)"
                          @click.prevent="$router.push('/configuration/entry-launch/edit/' + item.id)"
                          class="dropdown-item d-flex align-items-center"
                        >
                          <img src="/img/icons/create-new.png" width="22px" height="22px" />
                          Editar
                        </a>
                        <a
                          v-else-if="[5, 6].includes(item.status) && item.created_by_barcode === 1"
                          @click.prevent="handleModalCreateByAccessKey(item)"
                          class="dropdown-item d-flex align-items-center"
                        >
                          <img src="/img/icons/create-new.png" width="22px" height="22px" />
                          Editar
                        </a>
                        <a
                          v-else-if="[5, 6].includes(item.status) && item.created_by_entity === 1"
                          @click.prevent="handleModalCreateByEntity(item)"
                          class="dropdown-item d-flex align-items-center"
                        >
                          <img src="/img/icons/create-new.png" width="22px" height="22px" />
                          Editar
                        </a>
                        <div v-show="item.status === 3" class="dropdown-divider p-0 m-0"></div>
                        <a
                          v-if="item.status === 3"
                          @click.prevent="billPayGenerator(item.id)"
                          class="dropdown-item d-flex align-items-center"
                        >
                          <img src="/img/icons/icons8/ios/duration-finance_success.png" width="22px" height="22px" />
                          Gerar cap
                        </a>
                        <div
                          v-show="item.external_type === 0 && item.created_by_barcode === 0 && item.created_by_entity === 0"
                          class="dropdown-divider p-0 m-0"
                        ></div>
                        <a
                          v-if="item.external_type === 0 && item.created_by_barcode === 0 && item.created_by_entity === 0"
                          @click.prevent="$router.push(`/configuration/entry-launch/replication/${item.id}`)"
                          class="dropdown-item d-flex align-items-center"
                        >
                          <img src="/img/icons/icons8/ios/copy--v1.png" width="22px" height="22px" />
                          Replicar
                        </a>
                        <div v-show="item.status === 6" class="dropdown-divider p-0 m-0"></div>
                        <a
                          v-if="item.status === 6"
                          @click.prevent="
                            $router.push(
                              `/configuration/entry-launch/external-create/${item.company_plant_id}/${item.company_plant_buyer_id}/${item.id}`
                            )
                          "
                          class="dropdown-item d-flex align-items-center"
                        >
                          <img src="/img/icons/icons8/ios/download--v1_primary.png" width="22px" height="22px" />
                          Baixar xml
                        </a>
                        <div v-show="item.status !== 1 && item.product_service_hub_type_id === 2" class="dropdown-divider p-0 m-0"></div>
                        <a
                          v-if="item.status !== 1 && item.product_service_hub_type_id === 2"
                          @click.prevent="handleModalEntryCompanyPlantTransfer(item.id)"
                          class="dropdown-item d-flex align-items-center"
                        >
                          <img src="/img/icons/icons8/ios/data-in-both-directions_danger.png" width="22px" height="22px" />
                          Transferir
                        </a>
                        <div
                          v-show="!item.freight_id && item.product_service_hub_type_id !== 4 && ![5, 6].includes(item.status)"
                          class="dropdown-divider p-0 m-0"
                        ></div>
                        <a
                          v-if="!item.freight_id && item.product_service_hub_type_id !== 4 && ![5, 6].includes(item.status)"
                          @click.prevent="handleEntryLaunchToFreight(item)"
                          class="dropdown-item d-flex align-items-center"
                        >
                          <img src="/img/icons/icons8/ios/in-transit_primary.png" width="22px" height="22px" />
                          Frete
                        </a>
                        <!-- Reassociar frete -->
                        <div
                          v-show="item.freight_id"
                          class="dropdown-divider p-0 m-0"
                        ></div>
                        <a
                          v-if="item.freight_id"
                          @click.prevent="handleModalGetFreightToReassociate(item.id, item.old_issue_date)"
                          class="dropdown-item d-flex align-items-center"
                        >
                          <img src="/img/icons/icons8/ios/in-transit_primary.png" width="22px" height="22px" />
                          Trocar CTE-E
                        </a>
                        <div v-show="item.access_key && !item.danfe_url" class="dropdown-divider p-0 m-0"></div>
                        <a
                          v-if="item.access_key && !item.danfe_url"
                          @click.prevent="getDanfe(item.id)"
                          class="dropdown-item d-flex align-items-center"
                        >
                          <img src="/img/icons/pdf-v2.png" width="22px" height="22px" />
                          Solicitar pdf
                        </a>
                        <div v-show="item.status !== 1" class="dropdown-divider p-0 m-0"></div>
                        <a
                          v-if="item.status !== 1"
                          @click.prevent="handleDeleteEntryLaunch(item.id)"
                          class="dropdown-item d-flex align-items-center"
                        >
                          <img src="/img/icons/delete.png" width="22px" height="22px" />
                          Excluir
                        </a>
                      </base-dropdown>
                    </th>
                  </tr>
                </template>
              </tbody>
            </table>
            <!-- Dados vazios -->
            <PuzlEmptyData v-if="!$_items.length && !loadingSkeleton" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import { EntryLaunchStatusEnum } from "@/enum/EntryLaunchStatusEnum";

export default {
  name: "Table",
  components: { SkeletonPuzl, PuzlEmptyData },
  props: {
    loadingSkeleton: Boolean,
    getColor: Function,
    getText: Function,
    downloadDanfe: Function,
    handleModalAttachment: Function,
    handleModalListEntryLaunchItems: Function,
    handleDeleteEntryLaunch: Function,
    handleModalCreateByAccessKey: Function,
    handleModalCreateByEntity: Function,
    billPayGenerator: Function,
    handleModalEntryCompanyPlantTransfer: Function,
    handleModalFreight: Function,
    handleEntryLaunchToFreight: Function,
    getDanfe: Function,
    getParentOrChildrenIcon: Function,
    handleModalGetFreightToReassociate: Function,
  },
  data() {
    return {
      EntryLaunchStatusEnum: EntryLaunchStatusEnum,
    };
  },
  computed: {
    ...mapGetters({
      $_items: "entryLaunch/fetch",
    }),
  },
};
</script>

<style scoped>
td,
th {
  vertical-align: middle !important;
}

.mini-card {
  font-weight: normal !important;
  line-height: 1.5;
}

.mini-card-success {
  color: #149e57 !important;
}

.mini-card-danger {
  color: #db4539 !important;
}

.mini-card-warning {
  color: #f2b532 !important;
  background-color: #f4dc592e !important;
  border-radius: 5px !important;
  padding: 1px 5px !important;
  font-size: 12px !important;
}

.mini-card-light {
  color: #fff !important;
}

.mini-card-success {
  background-color: #8bd5af52 !important;
  border-radius: 5px !important;
  padding: 1px 5px !important;
  font-size: 12px !important;
  box-shadow: 0 0 5px 0 #149e5752 !important;
}
</style>
