<template>
  <div>
    <modal size="lg" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <div>
        <multi-filter ref="multi-filter" @clearFilters="clearFilters" :status="multi_filter" @fetch="init" :filter="filter">
          <div class="col-md-4 p-2">
            <input-date-picker :defaultRange="range" ref="date-picker" @handleFilterDate="handleFilterDate" />
          </div>
        </multi-filter>
        <div class="container-fluid">
          <div class="row card-wrapper" v-show="loadingSkeleton">
            <SkeletonPuzlGrid v-for="index in 3" :key="index" />
          </div>
          <PuzlEmptyData v-if="!items.length && !loadingSkeleton" />
          <div v-if="items.length && !loadingSkeleton">
            <div
              v-for="(item, index) in items"
              :key="index"
              class="border-left border-3 d-flex justify-content-between p-2 mb-4 border-primary"
            >
              <div class="col-10">
                <div>
                  <h4 class="mb-0">{{ item.entity_name }}</h4>
                  <h5 class="m-0 font-weight-normal">{{ item.document }}</h5>
                </div>
                <h5 class="mb-0 font-weight-normal mt-2"> Transporte <b>{{ item.document_number }} </b></h5>
                <h5 class="mb-0 font-weight-normal">{{ item.issue_date | parseDate("DD MMM YYYY") }}</h5>
                <h5 class="mb-0 mt-2">{{ item.final_value | currency() }}</h5>
              </div>

              <div class="col-2 d-flex justify-content-center align-items-center">
                <base-button :disabled="loadingStore" size="sm" type="success" @click="handleSelect(item)">
                  <i class="fa fa-check text-white"></i>
                </base-button>
              </div>
            </div>
            <pagination @navegate="navegate" :source="source" />
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <base-button type="secondary" @click="closeModal()"> Fechar </base-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import MultiFilter from "@/components/Utils/MultiFilterV2.vue";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import Pagination from "@/components/Utils/Pagination.vue";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import InputDatePicker from "@/components/InputDatePicker";
import moment from "moment";

export default {
  name: "ModalSearchEntryLaunch",
  props: {
    must_save: {
      type: Boolean,
      default: true,
      description: "Controla se dado deve persistir no banco",
    },
  },
  data() {
    return {
      modal: {
        title: "Buscar Frete",
        create: false,
      },
      filter: {
        status: null,
      },
      paginate: {
        page: null,
      },
      loadingSkeleton: true,
      multi_filter: {
        null: null,
      },
      source: null,
      entry_launch_id: 0,
      items: [],
      loadingStore: false,
      range: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      },
    };
  },
  components: { MultiFilter, SkeletonPuzlGrid, Pagination, PuzlEmptyData, InputDatePicker },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    async openModal(entry_launch) {
      let loader = this.$loading.show();
      this.$refs["multi-filter"].clearFilters();
      this.$refs["date-picker"].resetDate();
      this.loadingStore = false;
      this.entry_launch = entry_launch;
      this.range = {
        start: new Date(moment(entry_launch.old_issue_date).format('YYYY-MM-DD 00:00:00')),
        end: new Date(moment(entry_launch.old_issue_date).format('YYYY-MM-DD 00:00:00')),
      };
      this.filter = {
        status: null,
        page: 1,
      };
      this.paginate.page = 1;
      await this.init();
      loader.hide();
      this.modal.create = true;
    },
    init(filter = null) {
      this.filter = filter ?? this.filter;
      this.filter.range = this.range;
      this.loadingSkeleton = true;
      return this.$store
        .dispatch("entryLaunch/getByCompanyPlantAndCompanyPlantBuyerToFreight", {
          filter: this.filter,
          page: this.paginate.page,
          company_plant_id: this.entry_launch.company_plant_id,
          company_plant_buyer_id: this.entry_launch.company_plant_buyer_id,
        })
        .then((response) => {
          this.items = response.data;
          this.source = response;
          this.loadingSkeleton = false;
        });
    },
    clearFilters() {
      this.filter = {
        status: null,
      };
    },
    navegate(page) {
      this.paginate.page = page;
      this.init();
    },
    handleSelect(item) {
      if(!this.must_save) {
        this.$emit('selected', item);
        return this.closeModal();
      }
      this.loadingStore = true;
      this.$notify({ type: "info", message: "Estamos trabalhando em sua solicitação." });
      this.$store
        .dispatch("entryLaunch/freightAssociate", {
          entry_launch_id: item.id,
          entry_launch_owner_id: this.entry_launch.id,
        })
        .then((response) => {
          this.$notify({ type: response.error_type, message: response.message });
          this.$emit("selected");
          this.loadingStore = false;
          this.closeModal();
        });
    },
    handleFilterDate(filter) {
      this.range = filter;
      this.paginate.page = 1;
      this.init({});
    },
  },
};
</script>

<style scoped></style>
