import { OperationSourceListType } from "./OperationSourceListType";
import { PagerType, initPagerType } from "../../../../shared/types";

/**
 * Type para retorno paginado da listagem
 *
 * @typedef {(
 *   PagerType &
 *   { items: Array<OperationSourceListType> }
 * )} OperationSourceListPagerType
 */
export const OperationSourceListPagerType = {};

/**
 * Inicializar OperationSourceListPagerType
 *
 * @returns {OperationSourceListPagerType}
 */
export const initOperationSourceListPagerType = () => ({
  ...initPagerType(),
  items: [],
});
