import { operationSourceUrls } from ".";
import httpClient from "../../../../shared/libraries/httpClient";
import { handleUnexpectedStatusCode } from "../../../../shared/services";
import { HttpStatusEnum } from "../../../../shared/enums";
import { OperationSourceListFilterType, OperationSourceListPagerType } from "../types";

/**
 * Listar
 *
 * @param {?OperationSourceListFilterType} filter
 * @returns {Promise<OperationSourceListPagerType}
 */
const execute = async (filter = null) => {
  const res = await httpClient.get(operationSourceUrls.BASE, { params: filter });

  switch (res.status) {
    case HttpStatusEnum.HTTP_OK:
      return res.data.data;
    default:
      handleUnexpectedStatusCode(res);
  }
};

export default { execute };
