<template>
  <div>
    <multi-filter ref="multi-filter" @fetch="load" :filter="filter" />
    <div class="container-fluid">
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
      </div>

      <div
        class="row align-items-center"
        v-if="!types.length && !loadingSkeleton"
      >
        <div class="col-md-12 text-center">
          <div class="card p-4">
            Sem dados cadastrados!
          </div>
        </div>
      </div>

      <div class="row card-wrapper" v-if="types.length && !loadingSkeleton">
        <div class="col-lg-4" v-for="(type, index) in types" :key="index">
          <card
            :class="
              type.status ? 'card-border-top-success' : 'card-border-top-danger'
            "
          >
            <div class="row align-items-center mb-3">
              <div class="col-8">
                <!-- Title -->
                <h5 class="h3 mb-0">{{ type.description}}</h5>
              </div>

              <div class="col-4 text-right">
                <base-dropdown menuOnRight>
                  <base-button
                    slot="title-container"
                    type="primary"
                    class="dropdown-toggle p-2 rounded m-0"
                  >
                    <i class="fas fa-cog"></i>
                  </base-button>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleEditType(type.uuid)">
                    <i class="fas fa-edit text-warning"></i>
                    Editar
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleDeleteType(type.uuid)">
                    <i class="fas fa-times text-danger"></i>
                    Excluir
                  </a>
                </base-dropdown>
              </div>
            </div>
            <!-- Card body -->
            <collapse class="border rounded p-0 mt-3" v-if="type.category!=''">
              <collapse-item
                class="header-gray"
                back-ground="border-header-default-bottom"
              >
                <h5 slot="title" class="mb-0 ">Categorias</h5>
                <div class="row align-items-center pb-0 mb-3">
                  <div class="col-md-12">
                    <div class="checklist-item checklist-item-primary">
                      <div class="checklist-info">
                        <h4 class="checklist-title mb-0" v-for="(category, indexCategory) in type.category" :key="indexCategory">
                          {{ category.category_name }}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </collapse-item>
            </collapse>
          </card>
        </div>
      </div>
    </div>
    <ModalEditType ref="editModalType" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import ModalEditType from "./_ModalEdit";
import MultiFilter from '@/components/Utils/MultiFilter.vue';

export default {
  name: "ListType",
  components: {
    SkeletonPuzlGrid,
    ModalEditType,
    MultiFilter
  },
  data() {
    return {
      loadingSkeleton: false,
      filter: {}
    };
  },
  computed: {
    ...mapGetters({
      types: "equipmentType/fetch"
    })
  },
  methods: {
    load(filter = {}) {
      this.filter = filter
      this.init();
    },

    init() {
      this.$Progress.start();
      this.loadingSkeleton = true;
      this.$store
        .dispatch("equipmentType/fetchItems", { filter: this.filter })
        .then(response => {
          this.$Progress.finish();
          this.loadingSkeleton = false;
        })
        .catch(error => {
          this.$Progress.finish();
          this.loadingSkeleton = false;
        });
    },
    handleEditType(uuid){
      this.$refs.editModalType.handleEditModal(uuid)
    },
    handleDeleteType(uuid){
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed){
          this.$Progress.start();
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          this.$store.dispatch('equipmentType/destroy', uuid)
            .then((response) => {
              this.$notify({ type: response.error_type, message: response.message })
              this.$Progress.finish()
              this.init()
            })
          this.$Progress.finish();
        }
      }).catch(() => this.$Progress.finish())
    }
  },

  mounted() {
    this.filter.status = 1;
    this.$refs['multi-filter']._self.$forceUpdate();
    this.init();
  }
};
</script>

<style scoped>
.main-icon-menu {
  min-width: 18px;
}

.photo {
  width: 100px !important;
  height: 100px !important;
}
</style>
