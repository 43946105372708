import AdjustmentTypeListPage from "../views/list/AdjustmentTypeListPage.vue";

export default [
  {
    path: "/technology/adjustment/types",
    name: "technology.adjustment.type.index.view",
    component: AdjustmentTypeListPage,
    meta: {
      title: "Tecnologia",
      description: "Ajustes - Tipo",
      icon: "fa fa-home",
      permission: "puzl_adjust.type-permissions.index.view",
    },
  },
];
