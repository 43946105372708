<template>
  <div>
    <div class="container-fluid mt-5">
      <card>
        <div style="border-radius: 0.375rem" class="form-group row m-0 p-0 mb-2">
          <div class="col-md-6 mb-2 mb-md-0">
            <InputDatePicker
              :filterable="4"
              ref="dateFilter"
              @handleFilterDate="handleFilterDate"
              :disableBoxShadow="true"
            />
          </div>
          <div class="col-md-4 my-auto">
            <validation-provider rules="required">
              <base-input input-classes="form-control-sm">
                  <PuzlSelect
                    multiple
                    :clearable="false"
                    label="name"
                    v-model="filter.company_plants_id"
                    :items="plants"
                    :disableBoxShadow="true"
                    @input="applyFilters"
                  />
              </base-input>
            </validation-provider>
          </div>
          <div class="col-md-2 my-auto">
            <PageHeaderButton
              :isDropdown="true"
              text="Exportar"
              type="dark"
              icon="/img/icons/icons8/ios/graph-report.png">
              <PageHeaderButtonDropItem text="Resumo de Viagens" icon="/img/icons/excel.png" @click="showModalResume" />
            </PageHeaderButton>
          </div>
        </div>
      </card>
      <PuzlEmptyData class="mt-3" v-if="!resume.plants.length" />
      <section v-else class="schedule-resume mt-5">
        <ScheduleWidgets :items="resume.general" />
        <ScheduleHeader />
        <TreeView :items="resume.plants" :company_plants_id="filter.company_plants_id" />
      </section>
    </div>
    <ModalResumeReport ref="modalResume" />
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import ScheduleWidgets from './_ScheduleWidgets.vue';
import TreeView from './_TreeView.vue';
import ScheduleHeader from './_ScheduleHeader.vue';
import { period_types } from "@/views/Modules/Technology/Mix/helpers/mixe";
import InputDatePicker from '@/components/InputDatePicker';
import PuzlEmptyData from "@/components/PuzlEmptyData";
import PuzlSelect from "@/components/PuzlSelect";
import { PageHeaderButton, PageHeaderButtonDropItem } from "../../../../../../components";
import ModalResumeReport from "../Shared/_ModalResumeReport.vue";

export default {
  name: "ScheduleResume",
  components: {
    mapGetters,
    ScheduleWidgets,
    InputDatePicker,
    TreeView,
    ScheduleHeader,
    PuzlEmptyData,
    PuzlSelect,
    PageHeaderButton,
    PageHeaderButtonDropItem,
    ModalResumeReport
  },
  data() {
    return {
      isLoading: true,
      loadingPlant: true,
      filter: {
        company_plants_id: [],
      },
      format_mixe: period_types,
      range: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        end: new Date(),
      },
    };
  },
  async mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
        resume: "newScheduleResume/fetch",
        plants: "plant/activeItems",
    })
  },
  methods: {
    async handleOpenDetails(item) {
      if (item.type === 1) {
        this.getDetails(item);
        return;
      }

      if (item.opened === 0) {
        this.isLoading = true;
        await this.getCompanyPlantResume(item.uuid);
        item.opened = 1;
        this.isLoading = false;
        return;
      }

      this.$store.dispatch("scheduleResume/clearResume");
      item.opened = 0;
    },

    getDetails(item) {
      if (item.opened === 0) {
        this.$store.dispatch("scheduleResume/setResumeDetails", {
          data:
          {
            type: 2,
            formulation_body: item.formulation_body,
            formulation_title: item.formulation_title,
            concreted_piece: item.concreted_piece,
          },
          parent_reference: item.id
        });
        item.opened = 1;
        return;
      }

      this.$store.dispatch("scheduleResume/clearResumeDetails");
      item.opened = 0;
    },

    getCompanyPlantResume(company_plant_uuid) {
      return this.$store
        .dispatch("scheduleResume/getCompanyPlantResume", { company_plant_uuid: company_plant_uuid, filter: this.filter })
        .then((response) => {
          return response.data;
        });
    },

    handleFilterDate(filter) {
      this.range = filter;
      this.init()
    },

    init() {
      this.isLoading = true;
      this.filter.range = this.range;
      this.$store.dispatch("newScheduleResume/fetchItems", {
        range: this.filter.range,
        company_plants_id: this.filter.company_plants_id,
        order_by: [
          {
            column: 'concreting_date',
            is_desc: false
          }
        ],
      }).then(() => {
        this.isLoading = false;
      })
    },
    applyFilters() {
      this.isLoading = true;
      this.init();
    },
    showModalResume() {
      this.$refs.modalResume.openModal();
    },
  },
  mounted() {
    this.init();
    this.$store.dispatch("plant/fetchItemsActive").then(() => {
      this.loadingPlant = false;
    });
  },
}

</script>