<template>
  <div>
    <modal :show.sync="modal" size="md">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
        <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
          <div class="row justify-content-center">
            <div class="col-12">
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pb-1 col-form-label form-control-label">
                  Central
                </label>
                <div class="col-md-7 pl-2">
                  <validation-provider rules="required">
                    <base-input input-classes="form-control-sm">
                      <PuzlSelect
                        :clearable="false"
                        v-model="filter.company_plant_id"
                        :items="$_company_plants"
                        @change="getChargePoint"
                        placeholder="Central"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pb-1 col-form-label form-control-label">
                  Ponto de carga
                </label>
                <div class="col-md-7 pl-2">
                  <validation-provider rules="required">
                    <base-input input-classes="form-control-sm">
                      <PuzlSelect
                        :clearable="false"
                        v-model="filter.charge_point_id"
                        :items="$_charge_points"
                        :disabled="!filter.company_plant_id"
                        :loading="loadingChargePoint"
                        placeholder="Ponto de carga"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer" style="margin-top: -20px">
            <base-button type="secondary" @click="close()">fechar</base-button>
            <base-button
              type="success"
              :disabled="invalid"
              native-type="submit"
              :loading="loading"
            >
              <span class="btn-label"><i class="fa-solid fa-file-excel"></i></span>
              EXCEL
            </base-button>
          </div>
        </form>
      </validation-observer>
    </modal>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex'
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "ModalReportAutomation",
  components: {
    PuzlSelect
  },
  data() {
    return {
      title: 'Relatório para integração de automação',
      modal: false,
      loadingCompanyPlant: false,
      loadingChargePoint: false,
      loading: false,
      filter: {
        company_plant_id: null,
        charge_point_id: null,
      }
    }
  },
  computed: {
    ...mapGetters({
      $_company_plants: "plant/activeItems",
      $_charge_points: "plantChargePoint/fetch",
    }),
  },
  methods: {
    close() {
      this.modal = false
    },
    getChargePoint() {
      this.loadingChargePoint = true
      this.$store.dispatch('plantChargePoint/getActiveByPlant', this.filter.company_plant_id)
        .then(() => this.loadingChargePoint = false)
    },
    save() {
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      })
      this.loading = true
      this.$store.dispatch('adjustmentCmc/downloadExcel', this.filter).then((response) => {
        this.$notify({type: 'success', message: response.message});
        this.loading = false
        this.close()
      })
    },
    async handleOpenModal() {
      await this.$store.dispatch('plant/getPlantsSimplified')
      this.filter = {
        company_plant_id: null,
        charge_point_id: null
      }
      this.modal = true
    },
  },
}
</script>

<style scoped>

</style>
