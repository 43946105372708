<template>
<div>
  <modal :show.sync="modal">
    <template slot="header">
      <h5 class="modal-title p-0 m-0">Histórico de Aprovações</h5>
    </template>
    <div>
      <div class="p-3 pt-0 mt-0">
<!--        <div class="row align-items-center pb-0">-->
<!--          <div class="col-md-12">-->
<!--            <div class="card">-->
<!--              <base-input placeholder="Pesquisar">-->
<!--                <template slot="append">-->
<!--                  <i class="fas fa-search"></i>-->
<!--                </template>-->
<!--              </base-input>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

        <div v-for="(history, index) in histories" :key="index">
          <div class="border-left border-3 p-2 mb-3" :class="[
                    history.status === 0 ? 'border-danger' : '',
                    history.status === 1 ? 'border-success' : '',
                    history.status === 2 ? 'border-yellow' : '',
                    history.status === 3 ? 'border-warning' : '',
                    history.status === 4 ? 'border-light' : '',
                    history.status === 5 ? 'border-primary' : '',
                    ]">
            <div class="row">
              <div class="col-12 h4 text-left text-uppercase">
                {{status[history.status]}}
              </div>
              <div class="h4 col-12 text-left" v-if="history.situation">
                {{history.situation.name }}
              </div>
              <div class="col-12 text-left" v-if="history.competitor">
                {{history.competitor.name }}
              </div>
              <div class="col-12 text-left" v-if="history.reasons">
                <span v-for="(item, key) in history.reasons" :key="key">
                  {{item.reason}} <span v-if="key != history.reasons.length -1">|</span>
                </span>
              </div>
              <div class="col-12 text-left h3 small" v-if="history.observation">
                {{history.observation}}
              </div>
              <div class="col-12 text-left h3 small">
              {{history.user.name}}  | {{ history.created_at | parseDate}}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer justify-content-center">
        <base-button type="secondary" @click="closeModal()">fechar</base-button>
      </div>
    </div>
  </modal>
</div>
</template>

<script>
export default {
  components: {},
  name: "ModalContractProposalApprovalHistory",
  data() {
    return {
      modal: false,
      title: 'Histórico de aprovações.',
      validated: false,
      loading: false,
      histories: [],
      status:{
        '0': 'Inativo',
        '1': 'Ativo',
        '2': 'Pendente Revisão',
        '3': 'Pendente Aprovação',
        '4': 'Proposta',
        '5': 'Concluído',
      },
    }
  },
  methods: {
    closeModal() {
      this.modal = false
    },
    openModal(histories) {
      this.histories = histories
      this.modal = true
    },
    save() {},
  },
  created() {},
  mounted() {}
};
</script>

<style>
.border-3 {
  border-width: 3px !important;
}
</style>
