<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-gray d-inline-block mb-0">{{ this.$route.meta.breadcrumbs[1].title }}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4 text-gray">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#"><i :class="this.$route.meta.icon"></i></a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">{{ this.$route.meta.breadcrumbs[0].title }}</a>
              </li>
              <li class="breadcrumb-item" aria-current="page">{{ this.$route.meta.breadcrumbs[1].title }}</li>
            </ol>
          </nav>
        </div>
      </div>
    </base-header>
    <DashboardFilter ref="filter" @fetch="fetch" @changeTheme="changeTheme"/>
    <Cards @fetch="fetch" @fetchServices="fetchServices"/>
    <Chart ref="charts" :decal="config.decal_pattern" :theme="config.dark_mode"/>
  </div>
</template>

<script>
import DashboardFilter from "./Shared/_Filter.vue";
import Chart from "./Shared/_Chart.vue";
import Cards from "./Shared/_Cards.vue";

export default {
  name: "Index",
  components: {
    DashboardFilter,
    Chart,
    Cards,
  },
  data() {
    return {
      config: {
        dark_mode: false,
        decal_pattern: false,
      },
    };
  },
  methods: {
    changeTheme() {
      this.config.dark_mode = !this.config.dark_mode;
    },
    fetch(filters = []) {
      this.$refs.charts.resetDefaultTypes();
      this.$store.dispatch("operationalDashboard/fetchItems", filters);
      this.$store.dispatch("operationalDashboard/getServices", filters);
    },
    fetchServices(service) {
      let filters = this.$refs.filter.filter;
      filters.service_name = service;
      this.$store.dispatch("operationalDashboard/getServices", filters);
    },
  },
  mounted() {
    this.$refs.filter.handleFilter();
  },
};
</script>

<style></style>
