import * as types from './mutation_types'
import { createAxios } from "@/plugins/axios";;

const endPoint = '/company/plant'
export default {
  namespaced: true,
  state: {
    states: [],
    cities: []
  },
  getters: {
    getCities: state => state.cities,
    getStates: state => state.states,
  },
  mutations: {
    [types.SET_CITIES](state, payload) {
      state.cities = payload
    },
    [types.SET_STATES](state, payload) {
      state.states = payload
    }
  },
  actions: {
    getStates({commit}) {
      return createAxios().get(`${endPoint}/states`)
        .then(({data}) => {
          commit(types.SET_STATES, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getCities({commit}) {
      return createAxios().get(`${endPoint}/cities`)
        .then(({data}) => {
          commit(types.SET_CITIES, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
