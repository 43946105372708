import * as types from "./mutation_types";
import { destroy, cursorPaginate, setUrlWithNextPage } from "@/store/baseStore";
import { createAxios } from "@/plugins/axios";;
const endPoint = "/configuration/entry-launch/";
export default {
  namespaced: true,
  state: {
    items: [],
    parent: null,
    childrens: [],
    parents: [],
    freight_to_reassociate_items: [],
  },
  getters: {
    fetch: (state) => state.items,
    getParent: (state) => state.parent,
    getChildrens: (state) => state.childrens,
    getParents: (state) => state.parents,
    getFreightToReassociateItems: (state) => state.freight_to_reassociate_items,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload;
    },
    [types.DESTROY](state, id) {
      destroy(state, id);
    },
    [types.SET_ITEMS] (state, payload){
      state.items.filter(item => item.id === payload.entry_launch_id)[0].items = payload.data ?? [];
    },
    [types.SET_PARENT](state, payload) {
      state.parent = payload;
    },
    [types.SET_CHILDRENS](state, payload) {
      state.childrens = payload;
    },
    [types.SET_PARENTS](state, payload) {
      state.parents = payload;
    },
    [types.SET_FREIGHT_TO_REASSOCIATE_ITEMS](state, payload) {
      state.freight_to_reassociate_items = payload;
    },
  },
  actions: {
    fetch({ commit, state }, params) {
      return createAxios()
        .get(setUrlWithNextPage(endPoint, params), { params: params })
        .then(({ data }) => {
          cursorPaginate(state, { data: data.data, next_page: params.next_page });
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    add({}, payload) {
      return createAxios()
        .post(endPoint, payload)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    show({}, id) {
      return createAxios()
        .get(endPoint + id)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    update({}, payload) {
      return createAxios()
        .post(endPoint + payload.get('entry_launch[id]'), payload)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    destroy({ commit }, id) {
      return createAxios()
        .delete(endPoint + id)
        .then(({ data }) => {
          commit(types.DESTROY, id);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getByEntityCompanyPlantAndCompanyPlantBuyer({ commit }, payload) {
      return createAxios()
        .get(`${endPoint}entity/${payload.entity_id}/company-plant/${payload.company_plant_id}/company-plant-buyer/${payload.company_plant_buyer_id}`, {params:payload})
        .then(({ data }) => {
          commit(types.SET, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getByBillPay({ commit }, payload) {
      return createAxios()
        .get(`${endPoint}bill-pay/${payload}`)
        .then(({ data }) => {
          commit(types.SET, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getByCompanyPlantAndCompanyPlantBuyerToFreight({ }, payload) {
      return createAxios()
        .get(`${endPoint}freight/company-plant/${payload.company_plant_id}/company-plant-buyer/${payload.company_plant_buyer_id}`, {params:payload})
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    freightAssociate({ commit }, payload) {
      return createAxios()
        .get(`${endPoint}freight/associate/${payload.entry_launch_id}/owner/${payload.entry_launch_owner_id}`)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    freightDisassociate({ commit }, payload) {
      return createAxios()
        .get(`${endPoint}freight/disassociate/${payload}`)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getFreightToReassociate({commit }, params) {
      return createAxios()
        .get(`${endPoint}freight/to-reassociate/${params.id}`, {params: params})
        .then(({ data }) => {
          commit(types.SET_FREIGHT_TO_REASSOCIATE_ITEMS, data.data.items);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    freightReassociate({}, params) {
      return createAxios()
        .post(`${endPoint}freight/reassociate`, params)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    entryCompanyPlantTransfer({}, payload) {
      return createAxios()
        .get(`${endPoint}${payload.id}/entry-company-plant-transfer/${payload.company_plant_id}`)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    billPayGenerator({}, payload) {
      return createAxios()
        .get(`${endPoint}${payload}/bill-pay-generator`)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    setItems({commit}, payload) {
      commit(types.SET_ITEMS, payload)
    },
    cancellation({}, id) {
      return createAxios()
        .get(`${endPoint}cancellation/${id}`)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    addByAccessKey({}, params) {
      return createAxios()
        .post(`${endPoint}access-key`, params)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    updateByAccessKey({}, params) {
      return createAxios()
        .put(`${endPoint}access-key`, params)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getEntryLaunchExcelReport({_}, params) {
      return createAxios().get(endPoint + 'download-excel', {
        responseType: "blob",
        timeout: 60000,
        params: params
      })
      .then((response) => {
        const contentType = response.headers['content-type'];
        if (contentType && contentType.indexOf('application/json') !== -1) {
          return response.data.text().then(JSON.parse);
        }
        return response.data;
      })
      .catch(error => {
        if (error.response && error.response.data instanceof Blob) {
          return error.response.data.text().then(text => {
            try {
              return Promise.reject(JSON.parse(text));
            } catch (e) {
              return Promise.reject(text);
            }
          });
        }
        return Promise.reject(error);
      });
    },
    getEntryItemsGroupedByTypeAndCategory({}, payload) {
      return createAxios()
        .get(`${endPoint}entry-items-grouped-by-type-and-category`, {params:payload})
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getPreviousOperationSourceByEntity({}, payload) {
      return createAxios()
        .get(`${endPoint}previous-operation-source-by-entity`, {params:payload})
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getInitialDataByContractProposal({}, payload) {
      return createAxios()
        .get(`${endPoint}contract-proposal-initial-data/${payload}`)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getRecipeDevolutionByEntity({ commit }, payload) {
      return createAxios()
        .get(`${endPoint}recipe-devolution/${payload.id}`, {params:payload})
        .then(({ data }) => {
          commit(types.SET, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    addByEntity({}, params) {
      return createAxios()
        .post(`${endPoint}entity`, params)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    updateByEntity({}, params) {
      return createAxios()
        .put(`${endPoint}entity`, params)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getDanfe({}, id) {
      return createAxios()
        .get(`${endPoint}danfe/${id}`)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    /**
     * Busca nota na sefaz/arquivei por chave de acesso
     */
    searchByAccessKey({_}, params) {
      return createAxios()
        .post(`${endPoint}search-by-access-key`, params)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getEquipmentApportionmentReport({_}, params) {
      return createAxios().get(endPoint + 'equipment-apportionment/download-excel', {
        responseType: "blob",
        timeout: 60000,
        params: params
      })
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getParent({ commit }, entry_launch_id) {
      return createAxios()
        .get(`${endPoint}parent/${entry_launch_id}`)
        .then(({ data }) => {
          commit(types.SET_PARENT, data.data)
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getChildrensWithoutParent({ commit }, params) {
      return createAxios()
        .get(`${endPoint}parent/childrens-without-parent`, { params: params })
        .then(({ data }) => {
          commit(types.SET_CHILDRENS, data.data)
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    storeChildrens({}, params) {
      return createAxios()
        .post(`${endPoint}parent`, params)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getParentsWithChildrenBalance({ commit }, params) {
      return createAxios()
        .get(`${endPoint}parent/with-children-balance`, { params: params })
        .then(({ data }) => {
          commit(types.SET_PARENTS, data.data)
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    checkStatus({}, id) {
      return createAxios()
        .post(`${endPoint}check-status/${id}`)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getParentExcelReport({}, id) {
      return createAxios().get(`${endPoint}parent/excel-report/${id}`, {
        responseType: "blob",
        timeout: 60000,
      })
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getAllChildrens({ commit }, params) {
      return createAxios()
        .get(`${endPoint}parent/childrens/${params.id}`, { params: params })
        .then(({ data }) => {
          commit(types.SET_CHILDRENS, data.data)
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    entryLaunchReportPdf(_, params) {
      const isAsync = 1;
      return createAxios().post(`${endPoint}report/${isAsync}/pdf`, {
        filter: params
      })
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  },
};
