<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">

          <div class="row p-2">
            <div class="col-md-12">
              <h2><small>Pagamento atual</small></h2>
            </div>
            <div class="col-md-12 ml-3 border-3 border-primary border-left">
              <h2><small class="numeric">{{ payment.final_value | currency() }}</small></h2>
              <h2 class="mt-n3"><small>{{ payment.payment_method.name }} | {{ payment.payment_term.name }}</small></h2>
            </div>
          </div>
          <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
            <div class="row p-2">
              <div class="col-md-12">
                <h2 class="text-success"><small>Novo pagamento</small></h2>
              </div>
            </div>
            <div class="form-group row m-0 p-0 mb-2">
              <label
                class="col-md-6  pt-2 pb-0 col-form-label form-control-label"
              >
                Valor
              </label>
              <div class="col-md-6">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-money="money"
                      maxlength="10"
                      inputmode="numeric"
                      v-model="new_payment.value"
                      type="text"
                      class="form-control form-control-sm"
                    />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0">
                        R$
                      </small>
                    </template>
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0 mb-1">
              <label
                class="col-md-6 pt-2 pb-1 col-form-label form-control-label"
              >
                Método de pagamento
                <span class="text-danger">&nbsp;*&nbsp;</span>
              </label>
              <div class="col-md-6">
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm">
                    <el-select
                      @change="getPaymentTerms"
                      @input="setRequireTransactionCode()"
                      :disabled="loading"
                      v-model="new_payment.payment_method_uuid"
                      size="mini"
                      placeholder="Selecione"
                      filterable>
                      <el-option
                        v-for="method in payment_methods_selected"
                        :key="method.uuid"
                        :label="method.name"
                        :value="method.uuid"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0 ">
              <label
                class="col-md-6 pt-1 pb-1 col-form-label form-control-label"
              >
                Condição de pagamento
                <span class="text-danger">&nbsp;*&nbsp;</span>
              </label>
              <div class="col-md-6">
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm">
                    <el-select
                      :disabled="loading"
                      v-model="new_payment.payment_term_uuid"
                      size="mini"
                      placeholder="Selecione"
                      filterable
                    >
                      <el-option
                        v-for="term in payment_terms_selected"
                        :key="term.uuid"
                        :label="term.name"
                        :value="term.uuid"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </validation-provider>
              </div>
            </div>
<!--            <div class="form-group row m-0 p-0 mt-1 mb-2">-->
<!--              <label-->
<!--                class="col-md-6 pt-2 pb-1 col-form-label form-control-label"-->
<!--              >-->
<!--                Mediador de pagamento-->
<!--                <span class="text-danger" v-show="payment_intermediaries_exists">&nbsp;*&nbsp;</span>-->
<!--              </label>-->
<!--              <div class="col-md-6">-->
<!--                <validation-provider-->
<!--                  :rules="(payment_intermediaries_exists) && 'required' || ''">-->
<!--                  <base-input input-classes="form-control-sm">-->
<!--                    <PuzlSelect-->
<!--                      placeholder="Nenhum"-->
<!--                      :items="payment_intemerdiaries_selected"-->
<!--                      :disabled="loading || !new_payment.payment_method_uuid"-->
<!--                      v-model="new_payment.payment_intermediary_id"-->
<!--                    />-->
<!--                  </base-input>-->
<!--                </validation-provider>-->
<!--              </div>-->
<!--            </div>-->
            <div class="row">
              <div class="col-md-12" v-if="validNewPayment()">
                <h4>
                  <small class="text-darkred font-weight-600">
                    <i class="fa-solid fa-circle-exclamation"></i> O valor do novo pagamento deve ser inferior ao valor
                    do pagamento atual
                  </small>
                </h4>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')">
                fechar
              </base-button>
              <base-button type="success" native-type="submit" :disabled="invalid || validNewPayment()"
                           :loading="isLoading">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  VMoney
} from "v-money";
import {mapGetters} from "vuex";
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "ModalSharePayment",
  components: {
    PuzlSelect
  },
  data() {
    return {
      modal: {
        title: 'Dividir pagamento',
        create: false,
      },
      payment_terms_selected: [],
      payment_methods_selected: [],
      payment_intemerdiaries_selected: [],
      payment_intermediaries_exists: false,
      require_transaction_code: false,
      payment: undefined,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false
      },
      loading: false,
      isLoading: false,
      initial_balance: 0,
      new_payment: {
        value: 0,
        payment_method_id: undefined,
        payment_term_id: undefined,
        payment_intermediary_id: null
      },
      loadingStore: false,
    }
  },
  directives: {
    money: VMoney
  },
  computed: {
    ...mapGetters({
      contract_proposal: "contractProposal/show",
      payment_methods: "paymentMethod/fetch",
      payment_terms: "paymentTerm/fetch",
      $_payment_intermediaries: "paymentIntermediary/fetch",
    })
  },
  methods: {
    setRequireTransactionCode(set_intermediary_id = true) {
      if (set_intermediary_id) {
        this.new_payment.payment_intermediary_id = null
      }
      this.payment_intemerdiaries_selected = []
      let payment_method = this.payment_methods.find((item) => item.uuid === this.new_payment.payment_method_uuid)
      if (!payment_method) {
        this.payment_intermediaries_exists = false
        return this.require_transaction_code = false
      }
      this.payment_intermediaries_exists = payment_method.payment_intermediaries_exists
      if (payment_method.payment_intermediaries_exists) {
        this.payment_intemerdiaries_selected = this.$_payment_intermediaries.filter(function (item) {
          if (payment_method.payment_intermediaries.includes(item.id)) {
            return item
          }
        })
        if (payment_method.payment_intermediaries.length === 1) {
          this.new_payment.payment_intermediary_id =
            payment_method.payment_intermediaries[0];
        }
      }
      return this.require_transaction_code = Boolean(payment_method.transaction_code)
    },
    validNewPayment() {
      if (this.new_payment.value === 0) {
        return false
      }
      if (Number(this.new_payment.value.replaceAll(".", "").replace(",", ".")) >= this.payment.final_value) {
        return true
      }
      return false
    },
    closeModal() {
      this.modal.create = false
    },
    save() {
      if (Number(this.new_payment.value.replaceAll(".", "").replace(",", ".")) == 0) {
        this.$notify({
          type: "warning",
          message: "O valor do novo pagamento deve ser maior que R$ 0,00."
        });
      }
      this.$Progress.start();
      this.isLoading = true
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.$store.dispatch('contractProposalPayment/postGenerateNewSharedPayment', {
        payment: this.payment,
        new_payment: this.new_payment,
      }).then(response => {
        this.$notify({
          type: response.error_type,
          message: response.message
        });
        this.closeModal()
        this.$emit('fetch')
        this.$Progress.finish()
        this.isLoading = false
      })
    },
    getPaymentTerms() {
      this.new_payment.payment_term_uuid = null
      const payment_methods_selected = [this.new_payment.payment_method_uuid]
      const self = this
      this.payment_terms_selected = this.payment_terms.filter(function (item) {
        for (var payment_method of item.payment_methods) {
          if (payment_methods_selected.includes(payment_method.uuid)
            && self.contract_proposal.payment_terms_uuids.includes(item.uuid)) {
            return item;
          }
        }
      })
      if (this.contract_proposal.payment_terms_uuids.length === 1) {
        const payment_term_uuid = this.contract_proposal.payment_terms_uuids[0]
        const exists = this.payment_terms_selected.find((item) => item.uuid = payment_term_uuid)
        if (exists) {
          this.new_payment.payment_term_uuid = payment_term_uuid
        }
      }
    },
    async openModal(payment_uuid) {
      this.new_payment = {
        value: 0,
        payment_method_id: undefined,
        payment_term_id: undefined,
        payment_intermediary_id: undefined
      }
      this.loading = true
      Promise.all([
        this.$store.dispatch("paymentTerm/fetchItems"),
        this.$store.dispatch("paymentMethod/fetchItems"),
        this.$store.dispatch("paymentIntermediary/fetchItems"),
      ]).then(() => {
        this.payment_methods_selected =
          this.payment_methods.filter((item) => this.contract_proposal.payment_methods_uuids.includes(item.uuid))
        this.loading = false
      })
      let loader = this.$loading.show()
      this.payment = await this.$store.dispatch('contractProposalPayment/show', payment_uuid).then(response => {
        return response.data
      })
      loader.hide()
      this.modal.create = true
    },
  },
}
</script>

<style scoped>

</style>
