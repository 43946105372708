<template>
  <base-dropdown
    menuOnRight
    :style="listType === 'cards' ? 'position: absolute; right: 20px;' : ''"
  >
    <div
      slot="title-container"
      class="dropdown-toggle rounded m-0"
    >
      <img
        :width="listType === 'table' ? 25 : 35"
        src="/img/icons/icons8/ios/settings--v1_primary.png"
      >
    </div>
    <a
      class="dropdown-item new-default-black-font"
      style="align-items: center; display: flex"
      @click.prevent="$emit('handleEditBillReceive', bill_receive)"
    >
      <img
        src="/img/icons/create-new.png"
        width="20"
      />
      Editar
    </a>
    <div
      v-if="bill_receive.contract_proposal_code"
      class="dropdown-divider p-0 m-0"
    ></div>
    <router-link
      v-if="bill_receive.contract_proposal_code"
      :to="{
        name: 'commercial.contract-proposal.edit',
        path: '/commercial/contract-proposal/edit',
        params: { contract_proposal_uuid: bill_receive.contract_proposal_code }
      }"
    >
      <a
        class="dropdown-item new-default-black-font"
        style="align-items: center; display: flex"
      >
        <img
          src="/img/icons/external-link-squared.png"
          width="20"
        />
        Ir para o contrato {{ bill_receive.contract_proposal_code }}
      </a>
    </router-link>
    <div class="dropdown-divider p-0 m-0"></div>
    <a
      class="dropdown-item new-default-black-font"
      style="align-items: center; display: flex"
      @click.prevent="$emit('handleOpenModalInfo', bill_receive)"
    >
      <img
        src="/img/icons/info.png"
        width="20"
      />
      Informações
    </a>
    <div class="dropdown-divider p-0 m-0"></div>
    <a
      class="dropdown-item new-default-black-font"
      style="align-items: center; display: flex"
      @click.prevent="$emit('handleShowHistory', bill_receive)"
    >
      <img
        src="/img/icons/icons8/ios/order-history_secondary.png"
        width="20"
      />
      Histórico
    </a>
    <div
      v-if="bill_receive.type === 0 && !bill_receive.entity_id"
      class="dropdown-divider p-0 m-0"
    ></div>
    <a
      class="dropdown-item new-default-black-font"
      style="align-items: center; display: flex"
      @click.prevent="$emit('handleIdentifyEntity', bill_receive)"
      v-if="bill_receive.type === 0 && !bill_receive.entity_id"
    >
      <img
        src="/img/icons/icons8/ios/identify_success.png"
        width="20"
      />
      Identificar
    </a>
    <div
      v-if="bill_receive.payment_method_uuid === '947bf938-e098-4d1e-965a-c33fc9aa05d5'
      && parseInt(bill_receive.status) !== 3"
      class="dropdown-divider p-0 m-0"
    ></div>
    <a
      v-if="bill_receive.last_billet === false
      && bill_receive.payment_method_uuid === '947bf938-e098-4d1e-965a-c33fc9aa05d5'
      && parseInt(bill_receive.status) !== 3
      && parseInt(bill_receive.status) !== 1"
      class="dropdown-item new-default-black-font"
      style="align-items: center; display: flex"
      @click.prevent="$emit('showModalGenerateBillet', bill_receive)"
    >
      <img
        src="/img/icons/barcode.png"
        width="20"
      />
      Boleto
    </a>
    <a
      v-if="bill_receive.last_billet !== false
      && bill_receive.payment_method_uuid === '947bf938-e098-4d1e-965a-c33fc9aa05d5'
      && parseInt(bill_receive.status) !== 3"
      class="dropdown-item new-default-black-font"
      style="align-items: center; display: flex"
      @click.prevent="$emit('showModalGenerateBilletHasGenerated', bill_receive)"
    >
      <img
        src="/img/icons/barcode.png"
        width="20"
      />
      Boleto
    </a>
    <div
      v-if="bill_receive.bank_billet_batch_id"
      class="dropdown-divider p-0 m-0"
    ></div>
    <a
      class="dropdown-item new-default-black-font"
      style="align-items: center; display: flex"
      @click.prevent="$emit('postBatchUpdate', bill_receive)"
      v-if="bill_receive.bank_billet_batch_id"
    >
      <img
        src="/img/icons/connection-sync.png"
        width="20"
      />
      Atualizar Lote
    </a>
    <div
      v-if="hasTotvs && [BillReceiveInstallmentEnum.RECEIVED, BillReceiveInstallmentEnum.APPROVED].includes(bill_receive.status)"
      class="dropdown-divider p-0 m-0"
    ></div>
    <a
      class="dropdown-item new-default-black-font"
      style="align-items: center; display: flex"
      @click.prevent="$emit('handleModalEntityBillReceiveIntegrationInfo', bill_receive)"
      v-if="hasTotvs && [BillReceiveInstallmentEnum.RECEIVED, BillReceiveInstallmentEnum.APPROVED].includes(bill_receive.status)"
    >
      <img
        src="/img/icons/icons8/ios/connected_success.png"
        width="20"
      />
      Totvs
    </a>
    <div
      v-if="
        $hasPermission('bill_receive.change_due_date')
        && bill_receive.type === 1
        && (validFinancialLimit())
        && !([BillReceiveInstallmentEnum.RECEIVED, BillReceiveInstallmentEnum.CANCELED].includes(bill_receive.status))
        &&
          (
            bill_receive.payment_method_uuid === '947bf938-e098-4d1e-965a-c33fc9aa05d5'
            && bill_receive.last_billet !== false
          )
      "
      class="dropdown-divider p-0 m-0"
    ></div>
    <a
      class="dropdown-item new-default-black-font"
      style="align-items: center; display: flex"
      @click.prevent="$emit('showModalEditGenerateBilletHasGenerated', bill_receive)"
      v-if="
        $hasPermission('bill_receive.change_due_date')
        && bill_receive.type === 1
        && (validFinancialLimit())
        && !([BillReceiveInstallmentEnum.RECEIVED, BillReceiveInstallmentEnum.CANCELED].includes(bill_receive.status))
        && validBillet(bill_receive)
      "
    >
      <img
        src="/img/icons/icons8/ios/empty_calendar_gray.png"
        width="20"
      />
      Alterar Vencimento
    </a>
    <div
      v-if="
        $hasPermission('bill_receive.change_due_date')
        && bill_receive.type === 1
        && (validFinancialLimit())
        && !([BillReceiveInstallmentEnum.RECEIVED, BillReceiveInstallmentEnum.CANCELED].includes(bill_receive.status))
        && validBillet(bill_receive)
      "
      class="dropdown-divider p-0 m-0"
    ></div>
    <a
      class="dropdown-item new-default-black-font"
      style="align-items: center; display: flex"
      @click.prevent="$emit('showModalEditGenerateBilletHasGenerated', bill_receive)"
      v-if="
        $hasPermission('bill_receive.change_due_date')
        && bill_receive.type === 1
        && (validFinancialLimit())
        && !([BillReceiveInstallmentEnum.RECEIVED, BillReceiveInstallmentEnum.CANCELED].includes(bill_receive.status))
        &&
          (
            bill_receive.payment_method_uuid === '947bf938-e098-4d1e-965a-c33fc9aa05d5'
            && bill_receive.last_billet !== false
          )
      "
    >
      <img
        src="/img/icons/icons8/ios/empty_calendar_gray.png"
        width="20"
      />
      Alterar Vencimento
    </a>
    <div
      v-if="
        ![
          BillReceiveInstallmentEnum.CANCELED,
          BillReceiveInstallmentEnum.APPROVED,
          BillReceiveInstallmentEnum.DENIED
        ].includes(bill_receive.status) && bill_receive.last_billet === false
      "
      class="dropdown-divider p-0 m-0"
    ></div>
    <a
      v-if="
        ![
          BillReceiveInstallmentEnum.CANCELED,
          BillReceiveInstallmentEnum.APPROVED,
          BillReceiveInstallmentEnum.DENIED
        ].includes(bill_receive.status) && bill_receive.last_billet === false
      "
      @click="$emit('handleDeleteBillReceive', bill_receive)"
      class="dropdown-item new-default-black-font"
      style="align-items: center; display: flex"
    >
      <img
        src="/img/icons/delete.png"
        width="20"
      />
      Excluir
    </a>
  </base-dropdown>
</template>
<script>
import { BillReceiveInstallmentEnum } from "@/enum/BillReceiveInstallmentEnum";
import { hasTotvs } from "@/plugins/microservices/totvs";

export default {
  name: "SettingOptions",
  props: {
    bill_receive: Object,
    listType: String,
  },
  data(){
    return {
      hasTotvs: hasTotvs(),
      BillReceiveInstallmentEnum: BillReceiveInstallmentEnum,
    }
  },
  methods: {
    validFinancialLimit(){
      return ((this.$user.financial_limit && this.$user.financial_limit.disable_max_extension_due_date && this.$user.financial_limit.general_extension_due_date > 0) || !this.$user.financial_limit || !this.$user.financial_limit.disable_max_extension_due_date)
    },
    validBillet(bill_receive) {
      if (bill_receive.payment_method_uuid === '947bf938-e098-4d1e-965a-c33fc9aa05d5') {
        if (bill_receive.last_billet === false) {
          return true
        }
        else {
          return false
        }
      }
      return true
    },
  },
};
</script>
