<template>
  <div>
    <modal size="md" :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">{{ title }}</h5>
      </template>
      <div >
        <div class="row table-responsive" style="max-height: 80vh">
          <div class="col-md-12">
            <template v-for="item in $_history">
              <div class="pt-3">
                <h4> {{ enumHistory[item.history_type] }} </h4>
                <span> {{ item.user_name }} | {{ item.created_at | parseDate() }} </span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  components: {},
  name: "ModalHistoryTask",
  data() {
    return {
      modal: false,
      title: 'Histórico de alterações',
      //classes: classes,
      taskId: null,
      enumHistory: {
        1: 'Criação',
        2: 'Ciente',
        3: 'Pendente',
        4: 'Concluído',
        5: 'Anexo Adicionado',
        6: 'Anexo Removido',
        7: 'Editado'
      },
    }
  },
  computed: {
    ...mapGetters({
      $_history: 'task/getHistory',
    }),
  },
  methods: {
    close() {
      this.modal = false
    },
    openModal(taskId) {
      let loader = this.$loading.show()
      this.taskId = taskId
      this.$store.dispatch('task/getHistory', taskId)
        .then(response => {
          loader.hide()
          this.modal = true
        })
        .catch(error => {
          loader.hide()
        })
    },
  },
};
</script>

<style>
</style>