<template>
  <div>
    <modal :show.sync="modal.edit">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(update)"
            autocomplete="off"
          >
            <div>
              <div
                class="card-header border-header-default-bottom p-2 grid-col--fixed-left"
              >
                <h5 class="mb-0" slot="title">Identificação</h5>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 col-form-label form-control-label">
                  Status
                </label>
                <div class="col-md-7 pt-2">
                  <base-switch
                    v-model="additional.status"
                    type="success"
                    offText="inativo"
                    onText="ativo"
                    class="success"
                  ></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-5 pt-1 pb-0 col-form-label form-control-label"
                >
                  Serviços
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-classes="form-control form-control-sm">
                      <puzl-select
                        v-model="additional.services"
                        :items="services"
                        label="service_name"
                        :multiple="true"
                        :loading="loadingService"
                        :disabled="true" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-5 pt-1 pb-0  col-form-label form-control-label"
                >
                  Descrição Curta
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="additional.short_description"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-5 pt-1 pb-0  col-form-label form-control-label"
                >
                  Descrição Completa
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="additional.description"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-5 pt-1 pb-0 col-form-label form-control-label"
                >
                  Valor Padrão
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        inputmode="numeric"
                        v-model="additional.standard_value"
                        class="form-control form-control-sm"
                        v-mask="['####']"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-5 pt-1 pb-0 col-form-label form-control-label"
                >
                  Unidade de Cobrança
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required">
                    <base-input input-classes="form-control form-control-sm">
                      <el-select
                        :disabled="true"
                        v-model="additional.billing_unit"
                        filterable
                        placeholder="Selecione"
                        size="mini"
                      >
                        <el-option
                          v-for="itemUnit in billingUnits"
                          :key="itemUnit.id"
                          :label="itemUnit.label"
                          :value="itemUnit.id"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="border-header-warning-bottom mt-2 mb-2 p-2">
                <h5 class="mb-0" slot="title">Regras</h5>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-8 pb-1 pb-0 col-form-label form-control-label"
                >
                  Padrão em proposta/contrato
                  <el-popover
                    title="Bloquear qualquer tipo de ajuste."
                    placement="right"
                    class="p-0"
                  >
                    <span>Padrão em proposta/contrato.</span>
                    <base-button
                      outiline
                      slot="reference"
                      size="sm"
                      type="secundary"
                      class="p-0 m-0 btn-rounded"
                    >
                      <i class="fas fa-info-circle"></i>
                    </base-button>
                  </el-popover>
                </label>
                <div class="col-md-4 pt-1">
                  <base-switch
                    v-model="additional.default"
                    type="primary"
                    offText="não"
                    onText="sim"
                  ></base-switch>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-8 pb-1 pb-0 col-form-label form-control-label"
                >
                  Permite edição em proposta/contrato
                  <el-popover
                    title="Bloquear qualquer tipo de ajuste."
                    placement="right"
                    class="p-0"
                  >
                    <span>Permite edição em proposta/contrato.</span>
                    <base-button
                      outiline
                      slot="reference"
                      size="sm"
                      type="secundary"
                      class="p-0 m-0 btn-rounded"
                    >
                      <i class="fas fa-info-circle"></i>
                    </base-button>
                  </el-popover>
                </label>
                <div class="col-md-4 pt-1">
                  <base-switch
                    v-model="additional.editable"
                    type="primary"
                    offText="não"
                    onText="sim"
                  ></base-switch>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-8 pb-1 pb-0 col-form-label form-control-label"
                >
                  Cobrança automática
                  <el-popover
                    title="Bloquear qualquer tipo de ajuste."
                    placement="right"
                    class="p-0"
                  >
                    <span>Cobrança automática.</span>
                    <base-button
                      outiline
                      slot="reference"
                      size="sm"
                      type="secundary"
                      class="p-0 m-0 btn-rounded"
                    >
                      <i class="fas fa-info-circle"></i>
                    </base-button>
                  </el-popover>
                </label>
                <div class="col-md-4 pt-1">
                  <base-switch
                    v-model="additional.automatic_payment"
                    type="primary"
                    offText="não"
                    onText="sim"
                  ></base-switch>
                </div>
              </div>

              <div class="border-header-warning-bottom mt-2 mb-2 p-2">
                <h5 class="mb-0" slot="title">Datas</h5>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-5 pt-1 pb-0 col-form-label form-control-label"
                >
                  Datas
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control form-control-sm">
                      <puzl-select
                        v-model="additional.dates"
                        :items="dates"
                        label="label"
                        :disabled="!$hasPrivilege(1)"
                        :multiple="true" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="border-header-danger-bottom mt-2 mb-2 p-2">
                <h5 class="mb-0" slot="title">Prazos e Limites</h5>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-5 pt-1 pb-0 col-form-label form-control-label"
                >
                  Parâmetro A
                </label>
                <div class="col-md-7">
                  <base-input input-classes="form-control form-control-sm">
                    <el-select
                      :disabled="true"
                      v-model="param['a'].param"
                      filterable
                      placeholder="Selecione"
                      size="mini"
                    >
                      <el-option
                        v-for="itemParam in params"
                        :key="itemParam.id"
                        :label="itemParam.label"
                        :value="itemParam.id"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-5 pt-1 pb-0 col-form-label form-control-label"
                >
                  Valor A
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      v-model="param['a'].value"
                      class="form-control form-control-sm"
                      v-mask="['####']"
                      ref="value_a"
                    />
                  </base-input>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-5 pt-1 pb-0 col-form-label form-control-label"
                >
                  Parâmetro B
                </label>
                <div class="col-md-7">
                  <base-input input-classes="form-control form-control-sm">
                    <el-select
                      :disabled="true"
                      v-model="param['b'].param"
                      filterable
                      placeholder="Selecione"
                      size="mini"
                    >
                      <el-option
                        v-for="itemParam in params"
                        :key="itemParam.id"
                        :label="itemParam.label"
                        :value="itemParam.id"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-5 pt-1 pb-0 col-form-label form-control-label"
                >
                  Valor B
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required_if:value_b">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="param['b'].value"
                        class="form-control form-control-sm"
                        v-mask="['####']"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()"
                >Cancelar</base-button
              >
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                :loading="loadingUpdate"
                >Salvar</base-button
              >
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
export default {
  name: "Edit",
  components: { PuzlSelect },
  data() {
    return {
      modal: {
        title: "Custos adicionais.",
        edit: false
      },
      services: [
        {
          id: 1,
          service_name: 'Formulações',
        },
        {
          id: 2,
          service_name: 'Serviços',
        }
      ],
      param: {
        a: {},
        b: {}
      },
      additional: {
        uuid: "",
        status: "",
        short_description: "",
        description: "",
        billing_unit: "",
        default: "",
        editable: "",
        automatic_payment: "",
        standard_value: "",
        dates: []
      },
      loadingUpdate: false,
      loadingService: false
    };
  },
  computed: {
    ...mapGetters({
      billingUnits: "additional/billingUnits",
      params: "additional/params",
      dates: "additional/dates"
    })
  },
  methods: {
    ...mapActions({
      fetchServices: "service/fetchServices"
    }),
    closeModal() {
      this.modal.edit = false;
    },
    handleEditModal(uuid) {
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.$Progress.start();
      this.$store
        .dispatch("additional/show", uuid)
        .then(({ data }) => {
          this.additional = {
            uuid: data.uuid,
            status: data.status,
            services: data.services,
            short_description: data.short_description,
            description: data.description,
            billing_unit: data.billing_unit,
            default: data.default,
            editable: data.editable,
            automatic_payment: data.automatic_payment,
            standard_value: data.standard_value,
            dates: data.dates
          };
          this.param["a"] = data.params && data.params["a"] || "";
          this.param["b"] = data.params && data.params["b"] || "";
          this.$notify({ type: "success", message: "Dados carregados com sucesso!" });
          this.modal.edit = true;
          this.$Progress.finish();
        })
        .catch(error => {
          this.$notify({ type: "danger", message: "Ops, Algo deu errado." });
          this.$Progress.finish();
        });
    },
    update() {
      this.loadingUpdate = true;
      let i = 0;

      for (let obj in this.param) {
        Object.keys(this.param[obj]).length === 0 && i++;
      }
      this.additional.params = this.param;
      if (i === 2) {
        this.additional.params = null;
      }
      let request = this.additional;

      this.$store
        .dispatch("additional/update", request)
        .then(response => {
          this.loadingUpdate = false;
          this.modal.edit = false;
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.$emit('close')
        })
        .catch(error => {
          if (error.data.error) {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            });
          }
          this.loadingUpdate = false;
        });
    }
  },
  created() {
    this.loadingService = true;
    this.fetchServices().then(() => (this.loadingService = false));
  }
};
</script>

<style scoped></style>
