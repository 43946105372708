<template>
  <div class="custom-card-entry-launch mt-3 mb-3 pb-1">
    <div class="px-0">
      <div class="row">
        <div class="col-12 col-lg-9 px-0">
          <div class="pr-lg-0">
            <div class="row">
              <div class="col-12 my-2 my-lg-0 col-lg-3">
                <InputDatePicker
                  :disable-max-date="disableMaxDate"
                  :defaultRange="defaultRange"
                  :disabled="disableFilterDate"
                  :filterable="filterable_type"
                  :disableBoxShadow="true"
                  visibility="focus"
                  @handleFilterDate="handleFilterDate"
                  size="md"
                />
              </div>
              <div class="col-12 my-2 my-lg-0 pl-lg-0 pr-lg-1 col-lg-9">
                <div class="d-flex w-100" style="height: 2.7rem">
                  <input
                    @keydown.enter="push"
                    @keypress.space="push"
                    v-model="filtered"
                    placeholder="Pesquisar"
                    style="
                      width: 100%;
                      height: 2.7rem;
                      border-radius: 8px !important;
                      box-shadow: none !important;
                      border: 0.5px solid #e8e8e8 !important;
                    "
                    class="form-control"
                  />
                  <img
                    @click.prevent="push()"
                    src="/img/icons/icons8/ios/search.png"
                    height="32"
                    class="default-search zoom pointer mb-1"
                    style="align-self: center;"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 mt-2 px-0 mt-lg-0 pl-lg-4 col-lg-1">
          <base-button
            @click.prevent="clearFilter()"
            style="
              width: 90%;
              border: 1px solid #e8e8e8 !important;
              height: 2.7rem;
              border-radius: 8px !important;
              box-shadow: none;
            "
            type="white"
          >
            <img
              height="22"
              src="/img/icons/icons8/ios/synchronize.png"
              alt="synchronize"
            />
          </base-button>
        </div>
        <div class="col-8 px-0 mt-2 mt-lg-0 pl-lg-0 col-lg-2">
          <base-dropdown style="width: 100%" menuOnRight>
            <base-button
              size="sm"
              slot="title-container"
              type="white"
              class="text-uppercase"
              style="
                width: 100%;
                border: 0.5px solid #e8e8e8 !important;
                height: 2.8rem;
                border-radius: 8px !important;
                box-shadow: none;
              "
              block
            >
              <span v-show="status == null">
                <img
                  height="19"
                  style="margin-top: -4px"
                  src="/img/icons/icons8/ios/progress-indicator_grey.png"
                  alt=""
                >
                <span class="mx-2"> Status </span>
              </span>
              <span v-show="status == EntryLaunchStatusEnum.CLASSIFIED">
                <img
                  height="19"
                  style="margin-top: -4px"
                  src="/img/icons/icons8/ios/star--v1_success.png"
                  alt="star--v1_success"
                >
                <span style="font-size: 11px;" class="mx-0"> CLASSIFICADO </span>
              </span>
              <span v-show="status == EntryLaunchStatusEnum.WITHOUT_CLASSIFICATION">
                <img
                  height="19"
                  style="margin-top: -4px"
                  src="/img/icons/icons8/ios/ratting-off.png"
                  alt="ratting-off"
                >
                <span style="font-size: 12px; margin-right: 5px; margin-left: 2px;"> SEM CLASSIF. </span>
              </span>
              <span v-show="status == EntryLaunchStatusEnum.CANCELLED">
                <img
                  height="19"
                  style="margin-bottom: 2px;"
                  src="/img/icons/icons8/ios/cancel_danger.png"
                  alt="cancel_danger"
                >
                <span style="font-size: 12px;" class="mx-1" > CANCELADA </span>
              </span>
              <img
                  height="19"
                  style="margin-top: -4px; transform: rotate(270deg)"
                  src="/img/icons/lessThan_grey.png"
                  alt="lessThan_grey"
                />
            </base-button>
            <a
              v-if="status != null"
              class="dropdown-item dropdown-item-entry-launch"
              @mouseenter="changeImageStatus(true)"
              @mouseleave="changeImageStatus(false)"
              @click="
                $emit('resetAdvancedFilter');
                handleStatusButton(null);
              "
              href="#"
            >
              <div>
                <img
                  class="dropdown-img dropdown-img-status"
                  height="19"
                  src="/img/icons/icons8/ios/infinity_dark.png"
                  alt="infinity_dark"
                />
                TODOS
              </div>
            </a>
            <a
              class="dropdown-item dropdown-item-entry-launch"
              @mouseenter="changeImageClassified(true)"
              @mouseleave="changeImageClassified(false)"
              @click="
                $emit(
                  'handleFilterAdvancedStatus',
                  EntryLaunchStatusEnum.CLASSIFIED
                );
                handleStatusButton(EntryLaunchStatusEnum.CLASSIFIED);
              "
              href="#"
            >
              <div>
                <img
                  class="dropdown-img dropdown-img-classified"
                  height="19"
                  src="/img/icons/icons8/ios/star--v1_success.png"
                  alt="star--v1_success"
                />
                CLASSIFICADO
              </div>
            </a>
            <a
              class="dropdown-item dropdown-item-entry-launch"
              @mouseenter="changeImageWithoutClassification(true)"
              @mouseleave="changeImageWithoutClassification(false)"
              @click="
                $emit(
                  'handleFilterAdvancedStatus',
                  EntryLaunchStatusEnum.WITHOUT_CLASSIFICATION
                );
                handleStatusButton(EntryLaunchStatusEnum.WITHOUT_CLASSIFICATION);
              "
              href="#"
            >
              <div>
                <img
                  class="dropdown-img dropdown-img-without-classification"
                  height="19"
                  src="/img/icons/icons8/ios/ratting-off.png"
                  alt="ratting-off"
                />
                SEM CLASSIFICAÇÃO
              </div>
            </a>
            <a
              class="dropdown-item dropdown-item-entry-launch"
              @mouseenter="changeImageCancelled(true)"
              @mouseleave="changeImageCancelled(false)"
              @click="
                $emit(
                  'handleFilterAdvancedStatus',
                  EntryLaunchStatusEnum.CANCELLED
                );
                handleStatusButton(EntryLaunchStatusEnum.CANCELLED);
              "
              href="#"
            >
              <img
                class="dropdown-img dropdown-img-cancelled"
                height="19"
                src="/img/icons/icons8/ios/cancel_danger.png"
                alt="cancel_danger"
              />
              CANCELADA
            </a>
          </base-dropdown>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="col-md-8 pl-0 pt-3 mt-n2"
        style="margin-bottom: -0.9rem !important"
      >
        <transition name="el-zoom-in-center">
          <h3 v-if="global && global.length">
            <span
              class="badge badge-primary text-white mr-2"
              style="background: #1a70b7"
              v-for="(item, key) in global"
            >
              {{ item }}
              <a href="#">
                <i
                  @click.prevent="removeSelected(key)"
                  class="fa-solid fa-xmark text-white"
                  style="
                    font-size: 16px;
                    opacity: 50%;
                    margin-left: 2px;
                    margin-right: 2px;
                  "
                ></i>
              </a>
            </span>
          </h3>
        </transition>
      </div>
      <div class="col-md-4 mt-3">
        <el-popover trigger="click" placement="bottom">
            <div
              class="pointer pb-1"
              @click.prevent="orderBy('asc')"
            >
              <img
                src="/img/icons/icons8/ios/double-left.png"
                alt="double-left"
                width="12px"
                style="transform: rotate(270deg);"
                class="mr-2"
              >
              <span
              class="font-order-by"
              :class="filter.order_by === 'asc' ? 'text-primary' : ''"
              >
                A-Z
              </span>
            </div>
            <hr class="gray-divider-entry-launch" />
            <div
              class="pointer py-1"
              @click.prevent="orderBy('desc')"
            >
              <img
                src="/img/icons/icons8/ios/double-left.png"
                alt="double-left"
                width="12px"
                style="transform: rotate(90deg);"
                class="mr-2"
              >
              <span
              class="font-order-by"
              :class="filter.order_by === 'desc' ? 'text-primary' : ''"
              >
                Z-A
              </span>
            </div>
            <hr class="gray-divider-entry-launch" />
            <div
              class="pointer py-1"
              @click.prevent="orderByDate('asc')"
            >
              <img
                src="/img/icons/icons8/ios/double-left.png"
                alt="double-left"
                width="12px"
                style="transform: rotate(270deg);"
                class="mr-2"
              >
              <span
              class="font-order-by"
              :class="filter.order_by_date === 'asc' ? 'text-primary' : ''"
              >
                LANÇAMENTO MAIS NOVO PARA O MAIS VELHO
              </span>
            </div>
            <hr class="gray-divider-entry-launch" />
            <div
              class="pointer py-1"
              @click.prevent="orderByDate('desc')"
            >
              <img
                src="/img/icons/icons8/ios/double-left.png"
                alt="double-left"
                width="12px"
                style="transform: rotate(90deg);"
                class="mr-2"
              >
              <span
              class="font-order-by"
              :class="filter.order_by_date === 'desc' ? 'text-primary' : ''"
              >
                LANÇAMENTO MAIS VELHO PARA O MAIS NOVO
              </span>
            </div>
            <hr class="gray-divider-entry-launch" />
            <div
              class="pointer py-1"
              @click.prevent="orderByValue('desc')"
            >
              <img
                src="/img/icons/icons8/ios/double-left.png"
                alt="double-left"
                width="12px"
                style="transform: rotate(270deg);"
                class="mr-2"
              >
              <span
              class="font-order-by"
              :class="filter.order_by_value === 'desc' ? 'text-primary' : ''"
              >
                VALOR: DO MAIOR PARA O MENOR
              </span>
            </div>
            <hr class="gray-divider-entry-launch" />
            <div
              class="pointer pt-1"
              @click.prevent="orderByValue('asc')"
            >
              <img
                src="/img/icons/icons8/ios/double-left.png"
                alt="double-left"
                width="12px"
                style="transform: rotate(90deg);"
                class="mr-2"
              >
              <span
              class="font-order-by"
              :class="filter.order_by_value === 'asc' ? 'text-primary' : ''"
              >
                VALOR: DO MENOR PARA O MAIOR
              </span>
            </div>
          <a href="#" slot="reference" class="float-right">
            <h5
              slot="reference"
              class="p-0"
              style="font-size: 12px; font-weight: 400; color: #606062"
            >
              ORDENAR
              <i
                class="fa-solid fa-chevron-down ml-2"
                style="font-size: 8px;"
              ></i>
            </h5>
          </a>
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script>
import InputDatePicker from "@/components/InputDatePicker";
import moment from "moment";
import { EntryLaunchStatusEnum } from "@/enum/EntryLaunchStatusEnum";

export default {
  name: "Filters",
  props: {
    disableMaxDate: {
      default: false,
    },
    defaultRange: {
      type: Object,
      default: () => ({
        start: moment(new Date()).format("MM/DD/YYYY"),
        end: moment(new Date()).format("MM/DD/YYYY"),
        /** Este campo informa que por padrão o filtro de data será utilizado no backend,
         apenas quando o usuário selecionar uma data.
         Se informado como false, só ira utilizar a data como filtro se o usuário alterar o campo
         **/
        default_filterable: true,
      }),
      description: "Alcance padrão",
    },
    disableFilterDate: {
      type: Boolean,
      default: false,
      description: "Desativar componente de data do filtro.",
    },
    filterable_type: {
      type: Number,
      default: 4,
    },
    EntryLaunchStatusEnum: {
      type: Object,
    },
    dontHasCompanyPlant: {
      type: Function,
      required: true
    }
  },
  components: {
    InputDatePicker,
  },
  data() {
    return {
      global: [],
      filtered: null,
      filter: {
        order_by: null,
        order_by_date: null,
        order_by_value: null,
      },
      status: null,
    };
  },
  methods: {
    handleFilterDate(filter) {
      this.$emit("handleFilterDate", filter);
    },
    push() {
      if (!this.filtered) {
        return this.handleFilterClick();
      }
      this.filtered.split(" ").map(function (item) {
        item = item.replaceAll("(", "");
        item = item.replaceAll(")", "");
        if (item != "") {
          this.global.push(item);
        }
      }, this);
      this.filtered = null;
      this.handleFilterClick();
    },
    handleFilterClick() {
      const filters = { ...this.filter };
      filters.global = this.global;
      if (filters.status == null) {
        delete filters.status;
      }
      this.$emit("fetch", filters);
    },
    removeSelected(key) {
      this.global.splice(key, 1);
      this.handleFilterClick();
    },
    clearFilter() {
      this.global = [];
      this.$emit("clearFilter");
      Object.keys(this.filter).forEach((key) => {
        this.filter[key] = null;
      });
      this.push();
    },
    orderBy(type) {
      if (this.filter.order_by === type) {
        this.filter.order_by = null;
      } else {
        this.filter.order_by = type;
      }
      this.$emit("fetch", this.filter);
    },
    orderByDate(type) {
      if (this.filter.order_by_date === type) {
        this.filter.order_by_date = null;
      } else {
        this.filter.order_by_date = type;
      }
      this.$emit("fetch", this.filter);
    },
    orderByValue(type) {
      if (this.filter.order_by_value === type) {
        this.filter.order_by_value = null;
      } else {
        this.filter.order_by_value = type;
      }
      this.$emit("fetch", this.filter);
    },
    changeImageClassified(isHover) {
      const img = document.querySelector('.dropdown-img-classified');
      img.src = isHover ? '/img/icons/icons8/ios/star--v1_white.png' : '/img/icons/icons8/ios/star--v1_success.png';
    },
    changeImageWithoutClassification(isHover) {
      const img = document.querySelector('.dropdown-img-without-classification');
      img.src = isHover ? '/img/icons/icons8/ios/ratting-off_white.png' : '/img/icons/icons8/ios/ratting-off.png';
    },
    changeImageCancelled(isHover) {
      const img = document.querySelector('.dropdown-img-cancelled');
      img.src = isHover ? '/img/icons/cancel.png' : '/img/icons/icons8/ios/cancel_danger.png';
    },
    changeImageStatus(isHover) {
      const img = document.querySelector('.dropdown-img-status');
      img.src = isHover ? '/img/icons/icons8/ios/infinity.png' : '/img/icons/icons8/ios/infinity_dark.png';
    },
    /**
     * @description Verifica se há central/cnpj comprador selecionado para mudança do status e botão
     * @param {number} status
     * @returns {void}
     */
    handleStatusButton(status) {
      if(!this.dontHasCompanyPlant()) {
        this.status = status;
      }
    },
  },
};
</script>
<style>
.custom-card-entry-launch {
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  padding: 20px 24px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.07) !important;
  background: white;
}
.gray-divider-entry-launch {
  background-color: #2b2d32;
  opacity: 0.1;
  color: #2b2d32;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 109%;
  margin-left: -12px;
}
.font-order-by {
  font-size: 10px;
  font-weight: 400;
  color: #606062;
}
.dropdown-item-entry-launch:hover {
  background: #1A70B7 !important;
  color: #FFFFFF !important;
}
</style>
