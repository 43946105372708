<template>
  <div>
    <modal :show.sync="modal.create">
      <template class="align-middle" slot="header">
        <h5 class="modal-title pl-3 align-middle">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            v-if="Object.values($_payment_intermediary).length > 0"
            class="needs-validation"
            @submit.prevent="handleSubmit(store)"
            autocomplete="off"
          >
            <div class="form-group row m-0 p-0">
              <label class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2 mt-2">
                Status
              </label>
              <div class="col-md-6 pt-2">
                <base-switch v-model="$_payment_intermediary.status" type="success" offText="inativo" onText="ativo" class="success"></base-switch>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <label class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2">
                <div class="pt-1">
                  Nome
                  <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                </div>
              </label>
              <div class="col-md-6 pr-4 ">
                <base-input input-group-classes="input-group-sm">
                  <input
                    v-model="$_payment_intermediary.name"
                    class="form-control form-control-sm"
                  />
                </base-input>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <label class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2 mt-2">
                DEFINIR CONTA FINANCEIRA PADRÃO
              </label>
              <div class="col-md-6 pt-2">
                <base-switch @input="$_payment_intermediary.bank_account_id_fixed = null" v-model="$_payment_intermediary.bank_account_id_is_fixed" type="success" offText="Não" onText="Sim" class="success"></base-switch>
              </div>
            </div>
            <template v-if="$_payment_intermediary.bank_account_id_is_fixed">
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-12 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2 mt-1">
                  CONTA BANCÁRIA
                </label>
                <div class="col-md-12 ">
                  <PuzlSelect
                    v-model="$_payment_intermediary.bank_account_id_fixed"
                    :items="bank_accounts"
                    placeholder="Conta Bancária"
                    :loading="loadingBankAccounts"
                    :disabled="loadingBankAccounts"
                  />
                </div>
              </div>
            </template>
            <div class="card-header border-header-default-bottom p-2 grid-col--fixed-left">
              <h5 class="mb-0" slot="title">Integração</h5>
            </div>
            <div>
              <div class="form-group row m-0 p-0 mt-2">
                <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label">
                  Configurar
                </label>
                <div class="col-md-6 pt-2">
                  <base-switch v-model="$_payment_intermediary.has_credentials" type="success" offText="Não" onText="Sim" class="success"></base-switch>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mt-2">
                <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label">
                  Importação de arquivo txt
                </label>
                <div class="col-md-6 pt-2">
                  <base-switch v-model="$_payment_intermediary.accept_txt_file" type="success" offText="Não" onText="Sim" class="success"></base-switch>
                </div>
              </div>
              <div v-if="$_payment_intermediary.has_credentials">
                <div class="form-group row m-0 p-0">
                  <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label">
                    Tipo de Integração
                  </label>
                  <div class="col-md-6 mb-1 pr-4">
                    <base-input input-group-classes="input-group-sm">
                      <puzl-select
                        v-model="$_payment_intermediary.credential_type"
                        :items="credential_types"/>
                    </base-input>
                  </div>
                </div>
                <div v-if="$_payment_intermediary.credential_type > 0">
                  <div class="form-group row m-0 p-0">
                    <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label">
                      Ambiente
                    </label>
                    <div class="col-md-6 mb-1 pr-4">
                      <base-input input-group-classes="input-group-sm">
                        <puzl-select
                          v-model="$_payment_intermediary.credential_environment"
                          :items="credential_environments"
                        />
                      </base-input>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0">
                    <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label">
                      Usuário
                    </label>
                    <div class="col-md-6 mb-1 pr-4">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="$_payment_intermediary.credential_username"
                        />
                      </base-input>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0">
                    <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label">
                      Senha
                    </label>
                    <div class="col-md-6 mb-1 pr-4">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          type="password"
                          class="form-control form-control-sm"
                          v-model="$_payment_intermediary.credential_password"
                          autocomplete="off"
                        />
                      </base-input>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0">
                    <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label">
                      ClientID
                    </label>
                    <div class="col-md-6 mb-1 pr-4">
                        <base-input input-group-classes="input-group-sm">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="$_payment_intermediary.credential_client_id"
                          />
                        </base-input>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0">
                    <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label">
                      SecretCode
                    </label>
                    <div class="col-md-6 mb-1 pr-4">
                        <base-input input-group-classes="input-group-sm">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="$_payment_intermediary.credential_client_secret"
                          />
                        </base-input>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0">
                    <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label">
                      Tipo de autenticação
                    </label>
                    <div class="col-md-6 mb-1 pr-4 v-select-financial-no-hover-issue">
                        <base-input input-group-classes="input-group-sm">
                          <puzl-select
                            v-model="$_payment_intermediary.credential_grant_type"
                            customKey="value"
                            :clearable="false"
                            :items="[{'value': 'password', 'name': 'Senha'}, {'value': 'client_credentials', 'name': 'Credenciais de cliente'}]"
                          />
                        </base-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-header border-header-default-bottom p-2 grid-col--fixed-left">
              <h5 class="mb-0" slot="title">Débito</h5>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                    <tr>
                      <th class="text-center" style="font-size: 0.6rem">
                        Prazo de recebimento <span class="text-danger">*</span>
                      </th>
                      <th class="text-center" style="font-size: 0.6rem">
                        Taxa (%)
                      </th>
                      <th class="text-center" style="font-size: 0.6rem">
                        Taxa (R$)
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td class="text-center">
                        <validation-provider rules="required" v-slot="{errors}">
                          <input-limit
                            :start_value="$_payment_intermediary.debit.deadline"
                            :options="{
                          min: 0,
                          max: 99,
                        }"
                            v-model="$_payment_intermediary.debit.deadline"
                            @value="$_payment_intermediary.debit.deadline = $event"
                          >
                            <small>dias</small>
                          </input-limit>
                        </validation-provider>
                      </td>
                      <td class="text-center">
                        <validation-provider rules="required" v-slot="{errors}">
                          <base-input>
                            <input
                              inputmode="numeric"
                              v-mask="'#,##'"
                              v-model="$_payment_intermediary.debit.tax_percentage"
                              class="form-control form-control-sm"
                              :class="errors[0] ? 'is-invalid' : 'is-valid'"
                            />
                          </base-input>
                        </validation-provider>
                      </td>
                      <td class="text-center">
                        <validation-provider rules="required">
                          <base-input>
                            <input
                              @keydown="$event.key === '-' ? $event.preventDefault() : null"
                              inputmode="numeric"
                              v-money="money"
                              v-model.lazy="$_payment_intermediary.debit.tax_monetary"
                              class="form-control form-control-sm"/>
                          </base-input>
                        </validation-provider>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div style="margin-top: -12px" class="card-header border-header-default-bottom p-2  grid-col--fixed-left">
              <h5 class="mb-0" slot="title">Crédito à vista</h5>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                    <tr>
                      <th class="text-center" style="font-size: 0.6rem">
                        Prazo de recebimento <span class="text-danger">*</span>
                      </th>
                      <th class="text-center" style="font-size: 0.6rem">
                        Taxa (%)
                      </th>
                      <th class="text-center" style="font-size: 0.6rem">
                        Taxa (R$)
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td class="text-center">
                        <validation-provider rules="required" v-slot="{errors}">
                          <input-limit
                            :start_value="$_payment_intermediary.credit_on_sight.deadline"
                            :options="{
                          min: 0,
                          max: 99,
                        }"
                            v-model="$_payment_intermediary.credit_on_sight.deadline"
                            @value="$_payment_intermediary.credit_on_sight.deadline = $event"
                          >
                            <small>dias</small>
                          </input-limit>
                        </validation-provider>
                      </td>
                      <td class="text-center">
                        <validation-provider rules="required">
                          <base-input>
                            <input
                              inputmode="numeric"
                              v-mask="'#,##'"
                              v-model="$_payment_intermediary.credit_on_sight.tax_percentage"
                              class="form-control form-control-sm"/>
                          </base-input>
                        </validation-provider>
                      </td>
                      <td class="text-center">
                        <validation-provider rules="required">
                          <base-input>
                            <input
                              @keydown="$event.key === '-' ? $event.preventDefault() : null"
                              inputmode="numeric"
                              v-money="money"
                              v-model.lazy="$_payment_intermediary.credit_on_sight.tax_monetary"
                              class="form-control form-control-sm"/>
                          </base-input>
                        </validation-provider>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div style="margin-top: -12px"
                 class="card-header border-header-default-bottom p-2 mb-3 grid-col--fixed-left">
              <h5 class="mb-0" slot="title">Crédito parcelado</h5>
            </div>
            <div class="form-group row m-0 p-0">
              <label
                class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
              >
                <div class="pt-1">
                  Método de recebimento
                  <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                </div>
              </label>
              <div class="col-md-6 pr-4 ">
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-group-classes="input-group-sm">
                    <puzl-select
                      v-model="$_payment_intermediary.credit_in_installments.receipt_method"
                      :items="receipt_methods"/>
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!--            Taxa de crédito parcelado-->
            <div class="row mt-2">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                    <tr>
                      <th class="text-center" style="font-size: 0.6rem">
                        Prazo de recebimento <span class="text-danger">*</span>
                      </th>
                      <th class="text-center" style="font-size: 0.6rem">
                        Taxa (%)
                      </th>
                      <th class="text-center" style="font-size: 0.6rem">
                        Taxa (R$)
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td class="text-center">
                        <validation-provider rules="required">
                          <input-limit
                            :start_value="$_payment_intermediary.credit_in_installments.deadline"
                            :options="{
                                                    min: 0,
                                                    max: 99,
                                                  }"
                            v-model="$_payment_intermediary.credit_in_installments.deadline"
                            @value="$_payment_intermediary.credit_in_installments.deadline = $event"
                          >
                            <small>dias</small>
                          </input-limit>
                        </validation-provider>
                      </td>
                      <td class="text-center">
                        <validation-provider rules="required">
                          <base-input>
                            <input
                              inputmode="numeric"
                              v-mask="'#,##'"
                              v-model="$_payment_intermediary.credit_in_installments.tax_percentage"
                              class="form-control form-control-sm"/>
                          </base-input>
                        </validation-provider>
                      </td>
                      <td class="text-center">
                        <validation-provider rules="required">
                          <base-input>
                            <input
                              @keydown="$event.key === '-' ? $event.preventDefault() : null"
                              inputmode="numeric"
                              v-money="money"
                              v-model.lazy="$_payment_intermediary.credit_in_installments.tax_monetary"
                              class="form-control form-control-sm"/>
                          </base-input>
                        </validation-provider>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!--            Adicional por parcela-->
            <div class="row">
              <div class="col-md-12 text-right">
                <base-button @click="clearTaxes()" size="sm" class="mb-2">
                  Zerar taxas <i class="fa-solid fa-eraser"></i>
                </base-button>
                <div class="table-responsive" style="overflow-y: scroll; height:250px;">
                  <table class="table">
                    <thead>
                    <tr class="fixed">
                      <th class="text-center" style="font-size: 0.6rem">
                        Adicional por parcela <span class="text-danger">*</span>
                      </th>
                      <th class="text-center" style="font-size: 0.6rem">
                        Taxa (%)
                      </th>
                      <th class="text-center" style="font-size: 0.6rem">
                        Taxa (R$)
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="index in 21" v-if="index > 0 && $_payment_intermediary.additional_installments[index]">
                      <td class="text-center">Parcela {{ index }}</td>
                      <td class="text-center">
                        <validation-provider rules="required">
                          <!--                          <base-input>-->
                          <!--                            <input-->
                          <!--                              @change="setAllInstallments(index)"-->
                          <!--                              inputmode="numeric"-->
                          <!--                              v-mask="'#,##'"-->
                          <!--                              v-model="$_payment_intermediary.additional_installments[index].tax_percentage"-->
                          <!--                              class="form-control form-control-sm"/>-->
                          <!--                          </base-input>-->

                          <input-limit
                            :start_value="$_payment_intermediary.additional_installments[index].tax_percentage"
                            :options="{
                             min: 0.00,
                              max: 99.99,
                              decimals: 2
                            }"
                            v-model="$_payment_intermediary.additional_installments[index].tax_percentage"
                            @value="$_payment_intermediary.additional_installments[index].tax_percentage = $event"
                          >
                            <small>%</small>
                          </input-limit>
                        </validation-provider>
                      </td>
                      <td class="text-center">
                        <validation-provider rules="required">
                          <base-input>
                            <input
                              @keydown="$event.key === '-' ? $event.preventDefault() : null"
                              inputmode="numeric"
                              v-money="money"
                              v-model="$_payment_intermediary.additional_installments[index].tax_monetary"
                              class="form-control form-control-sm"/>
                          </base-input>
                        </validation-provider>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')">
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {VMoney} from 'v-money';
import PuzlSelect from "@/components/PuzlSelect";
import InputLimit from "@/components/Utils/InputLimit";
import {formatErrorValidation} from "@/plugins";

export default {
  name: "ModalEditPaymentIntermediary",
  components: {
    PuzlSelect,
    InputLimit,
  },
  data() {
    return {
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false
      },
      credential_types: [
        {
          id: 1,
          name: 'Rede',
        },
        {
          id: 2,
          name: 'Cielo',
        },
        {
          id: 3,
          name: 'Safra',
        },
      ],
      credential_environments: [
        {
          id: 0,
          name: 'Homologação',
        },
        {
          id: 1,
          name: 'Produção',
        },
      ],
      receipt_methods: [
        {
          id: 0,
          name: 'Acumulado',
        },
        {
          id: 1,
          name: 'Parcelado',
        }
      ],
      modal: {
        title: "Mediador de pagamento",
        create: false,
      },
      loadingStore: false,
      loadingBankAccounts: false,
      bank_accounts: [],
    };
  },
  computed: {
    ...mapGetters({
      $_payment_intermediary: "paymentIntermediary/show",
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    async openModal(id) {
      this.loadingBankAccounts = true;
      await this.$store.dispatch("bankAccount/fetchItems", {filter:{status:true}}).then((response) => {
        this.loadingBankAccounts = false;
        this.bank_accounts = response.data
      });
      let loader = this.$loading.show()
      this.$store
        .dispatch("paymentIntermediary/show", Number(id))
        .then(response => {
          this.modal.create = true;
          loader.hide()
        })
    },
    setAllInstallments(index) {
      if (index !== 1) {
        return;
      }
      const tax_percentage = this.$_payment_intermediary.additional_installments[index].tax_percentage
      for (var i = 1; i < 22; i++) {
        const actual_tax_percentage = this.$_payment_intermediary.additional_installments[i].tax_percentage
        if (actual_tax_percentage.toString().replace(',', '.') == 0) {
          this.$_payment_intermediary.additional_installments[i].tax_percentage = tax_percentage
        }
      }
    },
    clearTaxes() {
      for (var i = 1; i < 22; i++) {
        this.$_payment_intermediary.additional_installments[i].tax_percentage = '0,00'
      }
      this.$notify({
        type: 'success',
        message: 'Taxas zeradas com sucesso!'
      })
    },
    store() {
      // Alterar senha se preenchida
      if (this.$_payment_intermediary.credential_password)
        this.$_payment_intermediary.credential_change_password = true;

      this.$Progress.start();
      this.loadingStore = true;
      this.$store
        .dispatch("paymentIntermediary/update", this.$_payment_intermediary)
        .then(response => {
          this.loadingStore = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.closeModal();
        })
        .catch(error => {
          this.$Progress.finish();
          this.loadingStore = false;
          if (error.status === 200) {
            this.$notify({
              type: "danger",
              message: error.data.message
            });
          } else if (error.response && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors
            });
          }
        });
    }
  },
  beforeUpdate() {
    this.$refs.formValidator.validate();
  }
};
</script>
