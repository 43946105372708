import * as types from './mutation_types'
import { createAxios } from "@/plugins/axios";
import {cursorPaginate, setUrlWithNextPage} from "@/store/baseStore";

const endPoint = '/exports'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
    completed: 0,
  },
  getters: {
    show: state => state.item,
    fetch: state => state.items,
    getCompleted: state => state.completed,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload
    },
    [types.SET_COMPLETED](state, payload) {
      state.completed = payload
    },
    [types.SHOW](state, payload) {
      state.item = payload
    },
  },
  actions: {
    fetchItems({commit, state}, params) {
      return createAxios().get(setUrlWithNextPage(`${endPoint}`, params),
        {params: params})
        .then(({data}) => {
          cursorPaginate(state, {data: data.data, next_page: params.next_page})
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getCompleted({commit, state}, params) {
      return createAxios().get(`${endPoint}/completed`)
        .then(({data}) => {
          commit(types.SET_COMPLETED, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    store({commit}, params) {
      return createAxios().post(endPoint, params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    readAll(_) {
      return createAxios().put(endPoint + '/read-all')
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    destroy({commit}, id) {
      return createAxios().delete(`${endPoint}/${id}`,)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    download(_, url) {
      return createAxios()
        .get(`${url}`, {
          responseType: "blob",
          timeout: 30000,
        })
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    getTemporaryUrl(_, params) {
      return createAxios()
        .get(`/s3/temporary-url+microservice`, {params: params})
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    exportDeleteOldData() {
      return createAxios().delete(`/export/delete-old-data` )
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
