<template>
  <div>
    <AppFixedPageTitle
      title="HISTÓRICO DE ALTERAÇÕES"
      icon="/img/icons/physics-white.png"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          :showCompanyPlants="true"
          @onSearchClick="listItems"
          @onClearClick="clearFilter"
        >
          <AppSearchBarFilterSection
              name="dados"
              icon="/img/icons/icons8/ios/info-squared_gray.png"
          >
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label
                  class="form-control-label fs-11 new-default-gray-font font-weight-400"
                >
                Selecionar
              </label>
              <PuzlSelect
                style="width: 100%"
                v-model.lazy="filter.adjustment_id"
                :items="adjustmentTitles"
                :disableBoxShadow="true"
                placeholder="Ajuste"
                class="select-sm col-md-12 px-0 new-default-black-font"
              />
            </div>
          </AppSearchBarFilterSection>
        </AppSearchBar>
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions
          :isBackButtonEnabled="true"
        >
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>
    <AppTabSelect
      :items="tabSelectItems"
      :isShowViewTypes="false"
      @onTabSelectItemClick="onTabSelectItemClick"
      @onViewTypeChange="(type) => listType = type"
    >
    </AppTabSelect>
    <div class="container-fluid">
      <AdjustmentHistoryListPageTable
        @onCompanyPlantsByIdClick="getCompanyPlantsById"
      />
      <AppViewTrigger v-if="!isListing" @onIntersected="listItems(true)" />
      <PuzlEmptyData v-if="!isListing && !listed.items.length"/>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, computed, onMounted, onUnmounted } from 'vue';
import { date, getTitles, progress } from "../../../../../helpers";
import adjustmentHistoryStore from "../../store";
import { adjustmentHistoryGetCompanyPlantsByIdService } from "../../services";
import { 
  AppFixedPageTitle, 
  AppPageHeader, 
  initSearchBarFilterType,
  AppSearchBarFilterSection,
  SearchBarFilterType,
  AppSearchBar,
  AppViewTrigger,
  AppTabSelect,
  AppPageHeaderActions,
  TabSelectItemType,
} from '../../../../../components/AppGlobal';
import {
  initAdjustmentHistoryListFilterType,
  AdjustmentHistoryListFilterType,
  AdjustmentHistoryListType,
} from "../../types";
import AdjustmentHistoryListPageTable from "./AdjustmentHistoryListPageTable.vue";
import AdjustmentEnum from "../../enums/AdjustmentEnum";
import AdjustmentHistoryActionsEnum from "../../enums/AdjustmentHistoryActionsEnum";
import { useAdjustmentHistoryHook } from "../../hooks/useAdjustmentHistoryHook";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import PuzlSelect from "@/components/PuzlSelect";

const currentDate = date.make().format(date.FORMAT.ISO_8601);
const adjustmentTitles = getTitles(AdjustmentEnum);
const { companyPlants } = useAdjustmentHistoryHook();

/** Store Getters && Computeds */
const listed = computed(() => adjustmentHistoryStore.getListed());
const isListing = computed(() => adjustmentHistoryStore.getIsListing());

const tabSelectItems = computed(() => {
  return [
    {
      id: null,
      name: 'Todos',
      selected: filter.action_id === null,
    },
    {
      id: AdjustmentHistoryActionsEnum.keys.ADJUST,
      name: 'Ajuste',
      selected: filter.action_id === AdjustmentHistoryActionsEnum.keys.ADJUST
    },
    {
      id: AdjustmentHistoryActionsEnum.keys.REMOVE,
      name: 'Remoção',
      selected: filter.action_id === AdjustmentHistoryActionsEnum.keys.REMOVE
    },
    {
      id: AdjustmentHistoryActionsEnum.keys.EDIT,
      name: 'Edição',
      selected: filter.action_id === AdjustmentHistoryActionsEnum.keys.EDIT
    },
  ];
});

/** Reactives */
/** @type {SearchBarFilterType} - Filtro do AppSearchBar */
const searchBarFilter = reactive(initSearchBarFilterType());

/** @type {AdjustmentHistoryListFilterType} - Filtro principal */
const filter = reactive(initAdjustmentHistoryListFilterType());

const listType = ref('table');

onMounted(() => {
  clearFilter(true, true);
});

onUnmounted(() => {
  searchBarFilter.custom_search_values = [];
  adjustmentHistoryStore.resetStates();
  adjustmentHistoryStore.setPageState({ filter, searchBarFilter });
});

/**
 * @param {TabSelectItemType} item
 */
function onTabSelectItemClick(item) {
  if (filter.action_id !== item.id) {
    filter.action_id = item.id;
    listItems();
  };
};

/**
 * Limpa os filtros e atualiza a lista de itens.
 *
 * @param {boolean} isRefreshList - Atualiza a lista após limpar os filtros.
 * @param {boolean} withStoreFilters - Usa filtros da store se verdadeiro.
 */
function clearFilter(isRefreshList = true, withStoreFilters = false) {
  // Inicializa filtros com os valores padrão
  let searchBarFilterValue = defaultSearchBarFilter();
  let filterValue = defaultFilter();
  
  // Caso `withStoreFilters` seja verdadeiro, obter filtros armazenados na store
  const storeFilters = withStoreFilters ? adjustmentHistoryStore.getPageState() : null;
  if (storeFilters) {
    searchBarFilterValue = storeFilters.searchBarFilter;
    filterValue = storeFilters.filter;
  }

  // Aplica os filtros
  Object.assign(searchBarFilter, searchBarFilterValue);
  Object.assign(filter, filterValue);
  
  // Listar itens
  if (isRefreshList) {
    listItems();
  }
};

/**
 * Padrão do filtro AppSearchBar
 * @returns {SearchBarFilterType}
 */
function defaultSearchBarFilter() {
  return {
    ...initSearchBarFilterType(),
    range: {
      items: [],
      selected: null,
      start: filter.range.start ?? currentDate,
      end: filter.range.end ?? currentDate,
    }
  }
};

/**
 * Padrão do filtro principal
 * @returns {AdjustmentHistoryListFilterType}
 */
function defaultFilter() {
  return {
    ...initAdjustmentHistoryListFilterType(),
  };
};

/**
 * Listar itens
 * @param {boolean} isAccumulateItems
 */
function listItems(isAccumulateItems = false) {
  prepareFilter();
  adjustmentHistoryStore.list(filter, isAccumulateItems);
};

/**
 * Preparar filtro principal para listagem
 */
function prepareFilter() {
  filter.page = "";
  filter.custom_search.values = searchBarFilter.custom_search_values;
  filter.range.start = searchBarFilter.range.start;
  filter.range.end = searchBarFilter.range.end;
  filter.company_plant_id = searchBarFilter.company_plant_selected;
};

/**
 * 
 * @param {AdjustmentHistoryListType} history
 */
function getCompanyPlantsById(history) {
  progress.start();
  adjustmentHistoryGetCompanyPlantsByIdService.execute(history.id)
    .then((data) => {
      history.company_plants = data.company_plant_names;
    })
    .finally(() => progress.finish());
}

</script>

<style scoped>
</style>