import * as types from "./mutation_types";
import { cursorPaginate, setUrlWithNextPage } from "@/store/baseStore";
import { createAxios } from "@/plugins/axios";;
const endPoint = "/cmc/divergent-price/";
export default {
  namespaced: true,
  state: {
    items: [],
    documents: [],
  },
  getters: {
    fetch: (state) => state.items,
    documents: (state) => state.documents,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload;
    },
    [types.SET_DOCUMENTS](state, payload) {
      state.documents = payload;
    },
  },
  actions: {
    fetch({ commit, state }, params) {
      return createAxios()
        .get(setUrlWithNextPage(endPoint, params), { params: params })
        .then(({ data }) => {
          cursorPaginate(state, { data: data.data, next_page: params.next_page });
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    update({}, payload) {
      return createAxios()
        .put(endPoint + payload.id, payload)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getItems({ commit }, id) {
      return createAxios()
        .get(`${endPoint}${id}/items`)
        .then(({ data }) => {
          commit(types.SET_DOCUMENTS, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getMargin({}) {
      return createAxios()
        .get(`${endPoint}margin`)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    storeMargin({}, payload) {
      return createAxios()
        .post(`${endPoint}margin`, payload)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
};
