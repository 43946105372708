<template>
  <div>
    <multi-filter ref="multi-filter" @fetch="load" :filter="filter" />
    <div class="container-fluid">
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
      </div>

      <div
        class="row align-items-center"
        v-if="!situations.length && !loadingSkeleton"
      >
        <div class="col-md-12 text-center">
          <div class="card p-4">
            Sem dados cadastrados!
          </div>
        </div>
      </div>

      <div class="row card-wrapper" v-if="situations.length && !loadingSkeleton">
        <div class="col-lg-4" v-for="(situation, index) in situations" :key="index">
          <card
            :class="
              situation.status ? 'card-border-top-success' : 'card-border-top-danger'
            "
          >
            <div class="row align-items-center mb-3">
              <div class="col-8">
                <!-- Title -->
                <h5 class="h3 mb-0">{{ situation.situation_name}}</h5>
              </div>

              <div class="col-4 text-right">
                <base-dropdown menuOnRight>
                  <base-button
                    slot="title-container"
                    type="primary"
                    class="dropdown-toggle p-2 rounded m-0"
                  >
                    <i class="fas fa-cog"></i>
                  </base-button>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleEditEquipment(situation.uuid)">
                    <i class="fas fa-edit text-warning"></i>
                    Editar
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleDeleteEquipment(situation.uuid)">
                    <i class="fas fa-times text-danger"></i>
                    Excluir
                  </a>
                </base-dropdown>
              </div>
            </div>

          </card>
        </div>
      </div>
    </div>
    <ModalEditEquipment ref="editModalEquipment" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import ModalEditEquipment from "./_ModalEdit";
import MultiFilter from '@/components/Utils/MultiFilter.vue';

export default {
  name: "ListEquipmentSituation",
  components: {
    SkeletonPuzlGrid,
    ModalEditEquipment,
    MultiFilter
  },
  data() {
    return {
      loadingSkeleton: false,
      filter: {}      
    };
  },
  computed: {
    ...mapGetters({
      situations: "equipmentSituation/fetch"
    })
  },
  methods: {
    load(filter = {}) {
      this.filter = filter
      this.init();
    },

    init() {
      this.$Progress.start();
      this.loadingSkeleton = true;
      this.$store
        .dispatch("equipmentSituation/fetchItems", { filter: this.filter })
        .then(response => {
          this.$Progress.finish();
          this.loadingSkeleton = false;
        })
        .catch(error => {
          this.$Progress.finish();
          this.loadingSkeleton = false;
        });
    },
    handleEditEquipment(uuid){
      this.$refs.editModalEquipment.handleEditModal(uuid)
    },
    handleDeleteEquipment(uuid){
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed){
          this.$Progress.start();
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          this.$store.dispatch('equipmentSituation/destroy', uuid)
            .then((response) => {
              this.$notify({ type: response.error_type, message: response.message })
              this.$Progress.finish()
              this.init()
            })
          this.$Progress.finish();
        }
      }).catch(() => this.$Progress.finish())
    }
  },

mounted() {
    this.filter.status = 1;
    this.$refs['multi-filter']._self.$forceUpdate();
    this.init();
  },
};
</script>

<style scoped>
.main-icon-menu {
  min-width: 18px;
}

.photo {
  width: 100px !important;
  height: 100px !important;
}
</style>
