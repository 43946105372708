<template>
  <div>
    <modal
        :backgroundColor="'#E8E8E8'"
        :showClose="false"
        :show.sync="isOpen" size="md-2">
      <template slot="header">
        <div class="d-flex align-items-center container ml-n3 w-100" style="gap: 15px;">
          <div>
            <img src="/img/icons/icons8/ios/administrative-tools.svg" alt="download-dark" width="24px">
          </div>
          <span class="text-title">
              {{ title }}
            </span>
          <div class="ml-auto mr-n4">
            <img class="pointer" @click="closeModal" src="/img/icons/icons8/ios/close-window.png" alt="close-window"
                 width="17px">
          </div>
        </div>
      </template>
      <div class="px-3 pt-2">
        <div class="row  mb-2">
          <div class="col-md-12 mb-4 mt-n1" v-if="!hasAnyWithoutChartAccount()">
            <div style="padding: 8px;background: rgb(252, 243, 243);border-radius: 7px"><span
                class="ml-2 float-left">PLANO DE CONTAS SEM CATEGORIA DE DRE</span>

              <el-popover trigger="click" placement="left" class="p-0 pr-0 mr-1">
                <p class="mb-0 font-weight-400" v-for="item in withoutChartAccount()">
                  • {{ item }}
                </p>
                <template slot="reference">
                  <img width="22"

                       src="/img/icons/icons8/ios/attention_danger.png"
                       class="float-right">
                </template>
              </el-popover>
            </div>
          </div>
          <div class="col-md-12">
            <h3>
              <img width="25" src="/img/icons/icons8/ios/menu_primary.png"/> Categorias do DRE
            </h3>
          </div>
        </div>
        <div v-if="configurations" class="mb-2">
          <div class="row  mt-2" v-if="!item.editable" v-for="item in configurations">
            <div class="col-md-12 mt-n2 mb-2" v-if="item.before">
              <div
                  style="border-radius: 10px;background: rgb(198 210 229 / 27%);padding: 5px 5px 5px 15px;"> (=)
                {{ item.before.name }}
              </div>
            </div>

            <div class="col-md-6 mb-2 ">
              <label class="d-flex"><span class="mr-2" v-if="item.id === 1">(+)</span> <span class="mr-2"
                                                                                             v-if="item.id !== 1">(-)</span>
                {{ item.name }}
              </label>
            </div>
            <div class="col-md-6 mb-2">
              <base-input input-classes="form-control-sm">
                <PuzlSelect
                    placeholder="Plano de Contas"
                    :items="getChartAccountDontHaveSelected(item.id)"
                    :clearable="false"
                    :labelMask="true"
                    multiple
                    :selectable="(i) => i.id !== 99"
                    v-model="item.chart_accounts"
                    label="$code_formated$$name$"
                    :loading="loadingChartAccount"
                    :disabled="loadingChartAccount"/>
              </base-input>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-md-12">
              <h3>
                <img width="25" src="/img/icons/icons8/ios/paint-pallet_warning.png"/> Categorias para personalizar
              </h3>
            </div>
          </div>
          <div class="row  mt-2" v-if="editable.editable" v-for="editable in configurations">
            <div class="col-md-12 mt-n2 mb-2" v-if="editable.before">
              <div
                  style="border-radius: 10px;background: rgb(198 210 229 / 27%);padding: 5px 5px 5px 15px;"> (=)
                {{ editable.before.name }}
              </div>
            </div>

            <div class="col-md-6 mb-2 ">
              <label class="d-flex"><span class="mr-2" v-if="!editable.before">(+)</span> <span class="mr-2"
                                                                                                v-if="editable.before">(-)</span>
                {{ editable.name }}
                <el-popover width="300" ref="popoverName" trigger="click" placement="left" class="p-0 pr-0 mr-1">
                  <div class="row">
                    <div class="col-md-12">
                      <label>
                        {{ editable.name }}
                      </label>
                      <base-input placeholder="Nome personalizado" class="text-uppercase"
                                  v-model="newName"></base-input>
                      <base-button
                          :disabled="!newName"
                          @click.prevent="setNewName(editable)"
                          class="m-0 mt-2 btn-sm btn-icon float-right"
                          id="setting-save-button"
                          type="success"
                          outline
                          native-type="submit"
                          :loading="isSaving"
                      >
                        <div class="font-weight-400" style="display: inline-flex; align-items: center">
                          <img
                              src="/img/icons/save-green.png" width="16px" height="16px" class="mr-2">
                          <i class="m-0 ml-1" style="font-style: normal"> SALVAR </i>
                        </div>
                      </base-button>
                    </div>
                  </div>
                  <div
                      v-if="editable.editable"
                      class="pl-1"
                      slot="reference"
                  >
                    <img width="22" src="/img/icons/create-new.png">
                  </div>
                </el-popover>
              </label>
            </div>
            <div class="col-md-6 mb-2">
              <base-input input-classes="form-control-sm">
                <PuzlSelect
                    placeholder="Plano de Contas"
                    :items="getChartAccountDontHaveSelected(editable.id)"
                    :clearable="false"
                    :labelMask="true"
                    multiple
                    v-model="editable.chart_accounts"
                    label="$code_formated$$name$"
                    extraField="parents"
                    :loading="loadingChartAccount"
                    :disabled="loadingChartAccount"/>
              </base-input>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-12 mb-2">
              <div
                  style="border-radius: 10px;background: rgb(198 210 229 / 27%);padding: 5px 5px 5px 15px;">
                (=) LUCRO PREJUÍZO
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4 mb-2">
          <div class="col-12 text-right">
            <base-button
                style="width: 150px"
                @click="onCancelClick"
                type="danger"
                outline
                class="btn-sm btn-icon">
          <span class="font-weight-400 d-flex justify-content-center align-items-center"><img
              src="/img/icons/cancel-red.png" width="16px" height="16px" class="mr-2"> CANCELAR </span>
            </base-button>
            <base-button
                @click.prevent="save"
                style="width: 150px"
                class="btn-sm btn-icon"
                id="setting-save-button"
                type="success"
                outline
                native-type="submit"
                :loading="isSaving"
            >
              <div class="font-weight-400" style="display: inline-flex; align-items: center">
                <img
                    src="/img/icons/save-green.png" width="16px" height="16px" class="mr-2">
                <i class="m-0 ml-1" style="font-style: normal"> SALVAR </i>
              </div>
            </base-button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script setup>
import store from "@/store/index";

const name = 'DreConfigurationModal'
import {computed, reactive, ref} from "vue";
import {dialogs, progress} from "../../../../../helpers";
import dreStore from "@/modules/administrative/dre/store";
import PuzlSelect from '@/components/PuzlSelect'

let loadingChartAccount = false
const isOpen = ref(false);
const title = "Configuração do DRE";
/** @type {StockLocationCreateType} */

/** Store Getters */
const isSaving = ref(false);
const chartAccounts = computed(() => store.getters['chartAccount/fetchFirstLevelChartAccounts']);

const sortedChartAccounts = computed(() => {
  return chartAccounts.value.sort((a, b) => parseInt(a.code) - parseInt(b.code));
});

const separator = [
  {
    id: 99,
    code_formated: '',
    name: 'Planos fixos',
  },
];

const fixed_chart_accounts = [
  {
    id: 100,
    code_formated: '',
    name: 'Venda total',
  },
  {
    id: 101,
    code_formated: '',
    name: 'Impostos retidos',
  },
  {
    id: 102,
    code_formated: '',
    name: 'Matéria prima (saída)',
  }
];

const emit = defineEmits(['update'])

const newName = ref('');
const popoverName = ref('popoverName')

async function setNewName(item) {
  const loader = progress.showLoader();
  item.name = newName.value
  for (let i in popoverName.value) {
    popoverName.value[i].showPopper = false
  }
  dreStore.updateConfiguration(item.id, {name: newName.value})
      .then(() => {
        dialogs.notify();
      })
      .finally(() => {
        loader.hide();
        isSaving.value = false
        newName.value = ''
      });
  return item
}

async function save() {
  isSaving.value = true
  const loader = progress.showLoader();
  let params = {...configurations.value}
  dreStore.update(params)
      .then(() => {
        emit('update')
        dialogs.notify();
        closeModal();
      })
      .finally(() => {
        progress.finish();
        loader.hide();
        isSaving.value = false
      });
}

function hasAnyWithoutChartAccount() {
  /**
   * selecionados pelo usuário
   * @type {any[]}
   * */
  if (!configurations.value) {
    return []
  }
  const selectedChartAccount = configurations.value.reduce((acc, item) => {
    if (!item.chart_accounts) {
      return acc;
    }
    return acc.concat(item.chart_accounts);
  }, []);
  /**
   * planos totais
   * @type {any[]}
   */
  const chartAccounts = sortedChartAccounts.value.map(function (item) {
    return item.id
  })
  return chartAccounts.every(el => selectedChartAccount.includes(el));
}

function withoutChartAccount() {
  /**
   * selecionados pelo usuário
   * @type {any[]}
   * */
  const selectedChartAccount = configurations.value.reduce((acc, item) => {
    if (!item.chart_accounts) {
      return acc;
    }
    return acc.concat(item.chart_accounts);
  }, []);
  /**
   * planos totais
   * @type {any[]}
   */
  const chartAccounts = sortedChartAccounts.value.map(function (item) {
    return item.id
  })
  const ids = chartAccounts.filter(el => !selectedChartAccount.includes(el));
  return sortedChartAccounts.value.filter(function (item) {
    return ids.includes(item.id)
  }).map((item) => item.name)
}

function hasAny(arr, number) {
  return number.some(num => arr.includes(num));
}

function getChartAccountDontHaveSelected(id) {
  let selecteds = []
  for (let item of configurations.value) {
    if (item.id !== id) {
      selecteds = [...selecteds, ...item.chart_accounts || []]
    }
  }
  let another_arr = sortedChartAccounts.value.filter(obj => {
    return !selecteds.includes(obj.id)
  });
  let maped = another_arr.map((item) => {
    item.code_formated = item.code ? item.code + '. ' : ''
    return item
  })
  return [...maped, ...separator, ...fixed_chart_accounts]
}

function onCancelClick() {
  isOpen.value = false
}

/** Abrir modal */
async function openModal() {
  await getConfiguration()
  getChartAccounts()
  isOpen.value = true;
}

async function getConfiguration() {
  const loader = progress.showLoader();
  await dreStore.getConfiguration()
  loader.hide()
}

function getChartAccounts() {
  store.dispatch("chartAccount/getFirstLevel", {
    filter: {
      dre: 1,
    }
  }).then(() => {
    loadingChartAccount = false;
  });
}

/** Fechar modal */
function closeModal() {
  isOpen.value = false;
}

const configurations = computed(() => dreStore.getConfigurationList());

/** Resetar dados do formulário */
function setFormData() {
  Object.assign(formData, initStockLocationCreateType());
}

/** Criar registro */
function handleSubmitForm() {
  progress.start();
  const loader = progress.showLoader();
}

/**
 * @typedef {Object} DreConfigurationModalExpose
 * @property {typeof openModal} openModal
 */
defineExpose({
  openModal,
});
</script>

<style>
</style>
