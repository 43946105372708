<template>
  <div>
    <modal :show.sync="modal" size="lg">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>

        <div class="row m-0 p-0" v-show="loading">
          <div class="col-md-8  m-0 p-0">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
          <div class="col-md-4">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
        </div>

        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator" v-show="!loading">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div>

              <div>
                <div class="border-header-default-bottom p-2 mb-2">
                  <h6 class="h4 mb-0" >Endereço</h6>
                </div>

                <div>
                  <div class="form-group row m-0 p-0">
                    <label class="col-md-2 pb-0 mb-1 col-form-label form-control-label">
                      CEP<span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-4">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-group-classes="form-control-sm input-group-sm">
                          <input type="text" v-model="complement.postal_code" class="form-control form-control-sm"
                                 v-mask="['#####-###']"
                                 v-on:blur="getAddressByCode"
                                 :class="errors[0] ? 'is-invalid' : 'is-valid'"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                    <label class="col-md-2 pb-0 mb-1 col-form-label form-control-label">
                      UF<span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-4">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-classes="form-control-sm">
                          <el-select v-model="complement.state"
                                     filterable
                                     placeholder="Selecione"
                                     v-on:change="getCities()"
                                     :class="errors[0] ? 'is-invalid' : 'is-valid'"
                          >
                            <el-option v-for="state in states"
                                       :key="state.letter"
                                       :label="state.letter"
                                       :value="state.letter">
                            </el-option>
                          </el-select>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0">
                    <label class="col-md-2 pb-0 mb-1 col-form-label form-control-label">
                      Endereço<span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-10">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-group-classes="form-control-sm input-group-sm">
                          <input 
                            type="text" v-model="complement.address" class="form-control form-control-sm"
                            :class="errors[0] ? 'is-invalid' : 'is-valid'"/>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0">
                    <label class="col-md-2 pb-0 mb-1 pr-0 col-form-label form-control-label">
                      N<sup>o</sup><span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-3">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-group-classes="form-control-sm input-group-sm">
                          <input 
                            type="text" v-model="complement.number" maxlength="10" class="form-control form-control-sm"
                            :class="errors[0] ? 'is-invalid' : 'is-valid'"/>
                        </base-input>
                      </validation-provider>
                    </div>
                    <label class="col-md-2 pb-0 mb-1 col-form-label form-control-label">
                      Complemento
                    </label>
                    <div class="col-md-5">
                      <base-input input-group-classes="form-control-sm input-group-sm">
                        <input 
                          type="text" v-model="complement.complement" maxlength="20" class="form-control form-control-sm"/>
                      </base-input>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0">
                    <label class="col-md-2 pb-0 mb-1 pr-0 col-form-label form-control-label">
                      Bairro <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-4">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-group-classes="form-control-sm input-group-sm">
                          <input 
                            type="text" v-model="complement.district" class="form-control form-control-sm"
                            :class="errors[0] ? 'is-invalid' : 'is-valid'"/>
                        </base-input>
                      </validation-provider>
                    </div>
                    <label class="col-md-2 pb-0 mb-1 pr-0 col-form-label form-control-label">
                      Cidade <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-4 mt-1">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-classes="form-control-sm">
                          <el-select v-model="complement.city"
                                     filterable
                                     size="mini"
                                     placeholder="Selecione"
                                     :class="errors[0] ? 'is-invalid' : 'is-valid'"
                          >
                            <el-option v-for="city in cities"
                                       :key="city.id"
                                       :label="city.title"
                                       :value="city.title">
                            </el-option>
                          </el-select>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
              </div>

            </div>

              <div>
                <div class="border-header-default-bottom p-2 mb-2 mt-1">
                  <h6 class="h4 mb-0" >Contato</h6>
                </div>

                <div>
                  <div class="form-group row m-0 p-0">
                    <label class="col-md-2 pb-0 mb-1 col-form-label form-control-label">
                      Telefone
                    </label>
                    <div class="col-md-10">
                      <validation-provider>
                        <base-input input-group-classes="form-control-sm input-group-sm">
                          <input
                            inputmode="numeric"
                            type="text"
                            v-model="complement.phone"
                            class="form-control form-control-sm"
                            v-mask="['(##) ####-####']"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0">
                    <label class="col-md-2 pb-0 mb-1 col-form-label form-control-label">
                      Celular
                    </label>
                    <div class="col-md-10">
                      <validation-provider>
                        <base-input input-group-classes="form-control-sm input-group-sm">
                          <input
                            inputmode="numeric"
                            type="text"
                            v-model="complement.cell_phone"
                            class="form-control form-control-sm"
                            v-mask="['(##) # ####-####']"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                </div>

              </div>

              <div>
                <div class="border-header-default-bottom p-2 mb-2 mt-1">
                  <h6 class="h4 mb-0" >Contato Emergencial</h6>
                </div>

                <div>
                  <div class="form-group row m-0 p-0">
                    <label class="col-md-2 pb-0 mb-1 col-form-label form-control-label">
                      Nome
                    </label>
                    <div class="col-md-10">
                      <validation-provider>
                        <base-input input-group-classes="form-control-sm input-group-sm">
                          <input
                            type="text"
                            v-model="complement.emergency_name"
                            class="form-control form-control-sm"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0">
                    <label class="col-md-2 pb-0 mb-1 col-form-label form-control-label">
                      Referência
                    </label>
                    <div class="col-md-10">
                      <validation-provider>
                        <base-input input-group-classes="form-control-sm input-group-sm">
                          <input
                            type="text"
                            v-model="complement.emergency_reference"
                            class="form-control form-control-sm"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0">
                    <label class="col-md-2 pb-0 mb-1 col-form-label form-control-label">
                      Telefone
                    </label>
                    <div class="col-md-10">
                      <validation-provider>
                        <base-input input-group-classes="form-control-sm input-group-sm">
                          <input
                            inputmode="numeric"
                            type="text"
                            v-model="complement.emergency_phone"
                            class="form-control form-control-sm"
                            v-mask="['(##) ####-####']"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0">
                    <label class="col-md-2 pb-0 mb-1 col-form-label form-control-label">
                      Celular
                    </label>
                    <div class="col-md-10">
                      <validation-provider>
                        <base-input input-group-classes="form-control-sm input-group-sm">
                          <input
                            inputmode="numeric"
                            type="text"
                            v-model="complement.emergency_cell_phone"
                            class="form-control form-control-sm"
                            v-mask="['(##) # ####-####']"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                </div>

              </div>

              <div>
                <div class="border-header-default-bottom p-2 mb-2 mt-1">
                  <h6 class="h4 mb-0" >Dados Bancários</h6>
                </div>
                <div>
                  <div class="form-group row m-0 p-0">
                    <label class="col-md-2 pb-0 mb-1 col-form-label form-control-label">
                      Banco
                    </label>
                    <div class="col-md-10">
                      <validation-provider>
                        <base-input input-group-classes="form-control-sm input-group-sm">
                          <input
                            inputmode="numeric"
                            v-model="complement.bank"
                            class="form-control form-control-sm"
                            v-mask="['###']"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0">
                    <label class="col-md-2 pb-0 mb-1 col-form-label form-control-label">
                      Agência
                    </label>
                    <div class="col-md-10">
                      <validation-provider>
                        <base-input input-group-classes="form-control-sm input-group-sm">
                          <input
                            inputmode="numeric"
                            v-model="complement.agency"
                            class="form-control form-control-sm"
                            v-mask="['####']"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0">
                    <label class="col-md-2 pb-0 mb-1 col-form-label form-control-label">
                      Conta
                    </label>
                    <div class="col-md-10">
                      <validation-provider>
                        <base-input input-group-classes="form-control-sm input-group-sm">
                          <input
                            inputmode="numeric"
                            v-model="complement.account"
                            class="form-control form-control-sm"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <validation-provider rules="required">
              <input type="hidden" v-model="checkZipCode"/>
            </validation-provider>

            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import SkeletonPuzl from "@/components/SkeletonPuzl";
const {formatErrorValidation} = require("@/plugins")
import axios from 'axios'
import {viacep} from "@/helpers";

export default {
  name: "ModalUserDetail",
  components: {
    SkeletonPuzl
  },
  data() {
    return {
      modal: false,
      title: 'Detalhes de usuário',
      loadingSave: false,
      loading: true,
      complement: {
        postal_code: null,
        state: null,
        address: null,
        number: null,
        complement: null,
        district: null,
        city: null,
        country: null,
        account_type: null,
        bank: null,
        agency: null,
        account: null,
        phone: null,
        cell_phone: null,
        emergency_name: null,
        emergency_reference: null,
        emergency_phone: null,
        emergency_cell_phone: null,
      },
      loadingStore: false,
      checkZipCode: null,
    };
  },
  computed: {
    ...mapGetters({
      'states': 'localizations/states',
      'cities': 'localizations/cities',
    }),
  },
  methods: {
    closeModal () {
      this.modal = false
    },
    openModal (userUuid) {
      this.fetch(userUuid)
      this.modal = true
    },
    fetch(userUuid){
      this.$Progress.start();
      this.loading = true
      this.$store.dispatch('userComplement/show', userUuid).then(({data}) => {
        this.complement = {
          user_uuid: userUuid,
          postal_code: (data && data.postal_code) ? data.postal_code : '',
          state: (data && data.state) ? data.state : '',
          address: (data && data.address) ? data.address : '',
          number: (data && data.number) ? data.number : '',
          complement: (data && data.complement) ? data.complement : '',
          district: (data && data.district) ? data.district : '',
          city: (data && data.city) ? data.city : '',
          country: (data && data.country) ? data.country : '',
          account_type: (data && data.account_type) ? data.account_type : '',
          bank: (data && data.bank) ? data.bank : '',
          agency: (data && data.agency) ? data.agency : '',
          account: (data && data.account) ? data.account : '',
          phone: (data && data.phone) ? data.phone : '',
          cell_phone: (data && data.cell_phone) ? data.cell_phone : '',
          emergency_name: (data && data.emergency_name) ? data.emergency_name : '',
          emergency_reference: (data && data.emergency_reference) ? data.emergency_reference : '',
          emergency_phone: (data && data.emergency_phone) ? data.emergency_phone : '',
          emergency_cell_phone: (data && data.emergency_cell_phone) ? data.emergency_cell_phone : '',
        }
        this.getAddressByCode();
        this.$Progress.finish();
        this.loading = false
      })
    },
    store() {
      this.$Progress.start();
      this.loadingStore = true;
      this.$store.dispatch("userComplement/add", this.complement).then(response => {
          this.loadingStore = false;
          this.checkZipCode = null;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
        this.$store.dispatch('user/fetchItems')
        this.modal = false
        }).catch(error => {
          if (error.status) {
            this.$Progress.finish()
            this.loadingStore = false
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            })
          } else {
            if (error.response.status === 422) {
              let message = formatErrorValidation(error.response.data.errors)
              this.$notify({
                type: 'danger',
                message: message
              })
              this.$Progress.finish()
              this.loadingStore = false
            } else {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              })
              this.$Progress.finish()
              this.loadingStore = false
            }
          }
        });
    },
    getAddressByCode() {
      this.$Progress.start();
      let postal_code = this.complement.postal_code.replace(/[^\d]+/g,'')
      if(postal_code){
        viacep(postal_code).then((response) => {
          this.complement.state = response.data.uf;
          this.getCities();
          this.complement.address = response.data.logradouro;
          this.complement.state = response.data.uf;
          this.complement.district = response.data.bairro;
          this.complement.city = response.data.localidade;
          this.checkZipCode = true;
          this.$notify({type: 'success', message: 'Dados da carregado com sucesso.'});
          this.$Progress.finish();
        }).catch((error) => {
          this.checkZipCode = null;
          this.$notify({type: 'danger', message: 'Não foi possível encontrar o endereço.'});
        })
      }
    },
    getCities() {
      this.complement.city = ''
      this.$Progress.start();
      this.$store.dispatch('localizations/fetchCities', { uf: this.complement.state })
        .then((response) => {
          this.$Progress.finish();
        })
        .catch((error) => {
        });
    },
  },
  mounted() {
    this.$refs.formValidator.validate();
    this.$store.dispatch('localizations/fetchStates')
  },
}
</script>
