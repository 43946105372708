<template>
  <div>
    <modal class="bill-modal-create" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div v-if="cmc">
        <div class="p-2">
          <div class="border-left border-3 border-primary pr-1 mt-0 mb-4">
            <h4 class="m-0 pl-2">{{ cmc.company_plant_name }}</h4>
            <h4 class="m-0 pl-2">{{ cmc.category_name }}</h4>
            <h4 class="m-0 font-weight-normal pl-2">{{ cmc.product_name }}</h4>
            <h4 class="m-0 font-weight-normal pl-2">{{ cmc.supplier_name }} ( {{ cmc.origin_name }} )</h4>
          </div>
          <div v-for="(item, index) in $_items" :key="index" class="timeline timeline-one-side pb-2">
            <div class="timeline-block">
              <span class="timeline-step badge-success"> {{ index + 1 }} </span>
            </div>
            <div class="timeline-content row m-0 ml-4">
              <div class="ml-2">
                <h5 class="mb-0 col-12 p-0 font-weight-normal">
                  {{ item.issue_date | parseDate("DD MMM YYYY") }} | Documento <b>{{ item.document_number }} </b>
                </h5>
                <h5 class="mb-0 col-12 p-0 font-weight-normal">
                  {{ item.price | currency }} + {{ item.transport_cost | currency }} = {{ item.total_price | currency }}
                </h5>
                <h5
                  v-if="item.freight_entity_name && item.freight_entity_document"
                  class="mb-0 col-12 p-0 font-weight-normal"
                  title="Transportadora"
                >
                  {{ item.freight_entity_name }} | {{ item.freight_entity_document }}
                  | Frete <b> {{ item.cte_document_number }} </b>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ModalEntryLaunchPriceDocument",
  data() {
    return {
      modal: {
        title: "Documentos",
        create: false,
      },
      loading: false,
      cmc: null,
    };
  },
  computed: {
    ...mapGetters({
      $_items: "cmcDivergentPrice/documents",
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal(cmc) {
      this.cmc = cmc;
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });
      this.$store
        .dispatch("cmcDivergentPrice/getItems", cmc.id)
        .then((response) => {
          this.$notify({ type: response.error_type, message: response.message });
          this.modal.create = true;
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: error.data.message,
          });
        });
    },
  },
};
</script>
