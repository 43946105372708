import { render, staticRenderFns } from "./_ModalAttachmentView.vue?vue&type=template&id=afbb9f66&scoped=true"
import script from "./_ModalAttachmentView.vue?vue&type=script&lang=js"
export * from "./_ModalAttachmentView.vue?vue&type=script&lang=js"
import style0 from "pdfvuer/dist/pdfvuer.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./_ModalAttachmentView.vue?vue&type=style&index=1&id=afbb9f66&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afbb9f66",
  null
  
)

export default component.exports