<template>
<div>
    <modal :show.sync="modal.create">
        <div>
            <div class="modal-body text-center">
                <div class="card">
                    <div class="card-header">
                        <div class="card-profile-image">
                            <a href="#">
                                <img
                                    class="rounded-circle"
                                    data-src="img/theme/team-4-800x800.jpg"
                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/1024px-Circle-icons-profile.svg.png"
                                    lazy="loaded"
                                />
                            </a>
                        </div>
                    </div>
                    <div class="card-body mt-3">
                        <p class="card-text mt-2">
                            <a href="#" class="link-primary hoverable">Alterar foto</a>
                        </p>
                        <div class="container">
                            <div class="row">
                                <ul class="list-group list-group-flush" data-toggle="checklist">
                                    <li class="list-group-item px-0">
                                        <div class="checklist-item">
                                            <div class="checklist-info">
                                                <strong>{{ user.name }}</strong>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="list-group-item px-0">
                                        <div class="checklist-item">
                                            <div class="checklist-info">
                                                <strong>{{ user.email }}</strong>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="row">
                                <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
                                    <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
                                        <div>
                                            <div class="form-group row m-0 p-0">
                                                <label class="col-md-5 pt-1 mt-2 pb-0  col-form-label form-control-label">
                                                    Nome abreviado
                                                    <span class="text-danger">&nbsp;*</span>
                                                </label>
                                                <div class="col-md-7 mt-2">
                                                    <validation-provider rules="required" v-slot="{ errors }">
                                                        <base-input input-group-classes="input-group">
                                                            <input 
                                                                type="text" 
                                                                v-model="user.short_name" 
                                                                class="form-control form-control-sm" 
                                                                :class="errors[0] ? 'is-invalid' : 'is-valid'" 
                                                            />
                                                        </base-input>
                                                    </validation-provider>
                                                </div>
                                                <label class="col-md-5 pt-1 mt-2 pb-0  col-form-label form-control-label">
                                                    Celular
                                                </label>
                                                <div class="col-md-7 mt-2">
                                                    <validation-provider v-slot="{ errors }">
                                                        <base-input input-group-classes="input-group">
                                                            <input 
                                                                type="text" 
                                                                v-model="user.phone" 
                                                                class="form-control form-control-sm" 
                                                                :class="errors[0] ? 'is-invalid' : 'is-valid'" 
                                                            />
                                                        </base-input>
                                                    </validation-provider>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div class="form-group row m-0 p-0">
                                            <label class="col-md-5 pt-1 mt-2 pb-0  col-form-label form-control-label">
                                                Senha atual
                                            </label>
                                            <div class="col-md-7 mt-2">
                                                <validation-provider v-slot="{ errors }">
                                                    <base-input input-group-classes="input-group">
                                                        <input 
                                                            type="password" 
                                                            v-model="password" 
                                                            class="form-control form-control-sm" 
                                                            :class="errors[0] ? 'is-invalid' : 'is-valid'" 
                                                        />
                                                    </base-input>
                                                </validation-provider>
                                            </div>
                                            <label class="col-md-5 pt-1 mt-2 pb-0  col-form-label form-control-label">
                                                Nova senha
                                            </label>
                                            <div class="col-md-7 mt-2">
                                                <validation-provider v-slot="{ errors }">
                                                    <base-input input-group-classes="input-group">
                                                        <input 
                                                            type="password" 
                                                            v-model="newPassword" 
                                                            class="form-control form-control-sm" 
                                                            :class="errors[0] ? 'is-invalid' : 'is-valid'" 
                                                        />
                                                    </base-input>
                                                </validation-provider>
                                            </div>
                                            <label class="col-md-5 pt-1 mt-2 pb-0  col-form-label form-control-label">
                                                Confirme a senha digitada
                                            </label>
                                            <div class="col-md-7 mt-2">
                                                <validation-provider v-slot="{ errors }">
                                                    <base-input input-group-classes="input-group">
                                                        <input 
                                                            type="password" 
                                                            v-model="confirmPassword" 
                                                            class="form-control form-control-sm" 
                                                            :class="errors[0] ? 'is-invalid' : 'is-valid'" 
                                                        />
                                                    </base-input>
                                                </validation-provider>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <base-button type="secondary" @click="closeModal('create')">
                                                Cancelar
                                            </base-button>
                                            <base-button type="success" native-type="submit">
                                                Salvar
                                            </base-button>
                                        </div>
                                    </form>
                                </validation-observer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</div>
</template>

<script>
import { formatErrorValidation } from "@/plugins";
export default {
    name: "UserCard",
    data() {
        return {
            modal: {
                title: null,
                create: false
            },
            user: {},
            password: '',
            newPassword: '',
            confirmPassword: '',
        };
    },
    methods: {
        closeModal() {
            this.modal.create = false;
        },
        handleCreateModal(user) {
            this.modal.create = true;
            this.user = user;
            this.modal.title = `Visualizando dados de ${this.user.name}`;
        },
        store() {
          const user = {
            id: this.user.id,
            uuid: this.user.uuid,
            name: this.user.name,
            email: this.user.email,
            short_name: this.user.short_name,
            phone: this.user.phone,
            password: this.password,
            new_password: this.newPassword,
            confirm_password: this.confirmPassword
          }
          this.$store.dispatch('auth/updateProfile', user)
            .then(response => {
                    this.$Progress.finish()
                    this.$notify({
                        type: response.error_type,
                        message: response.message
                    })
                })
                .catch(error => {
                    if (error.status == 200) {
                        this.$notify({
                            type: 'danger',
                            message: error.data.message
                        })
                        this.$Progress.finish();
                    } else if (error.response && error.response.status === 422) {
                        let errors = formatErrorValidation(error.response.data.errors)
                        this.$notify({
                            type: 'danger',
                            message: errors
                        })
                    }
                    this.$Progress.finish()
                })
        },
    }
};
</script>

<style scoped></style>
