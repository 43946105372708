<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h2 style="font-size: 1.2rem !important; color: #606062 !important" class="text-primary">
            <img height="30px" src="/img/icons/icons8/ios/general-ledger_default.png" class="mr-2 mb-1" />
            Dashboard
          </h2>
        </div>
      </div>
    </base-header>
    <List />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import List from "./Shared/_List";
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";

export default {
  name: "Index",
  components: {
    List,
    PuzlBreadcrumb,
  },
};
</script>

<style scoped></style>
