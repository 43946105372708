<template>
  <div>
    <modal size="md" :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title pl-1">{{ title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(save)"
            autocomplete="off"
          >
            <div>
              <!-- Tema-->
              <div class="form-group row m-0 p-0 mt-4">
                <label
                  class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-4 mb-2"
                >
                  <div class="pt-1">
                    Tema
                  </div>
                </label>
                <div class="col-md-6 pr-4 mb-2">
                  <base-input input-group-classes="input-group-sm">
                    <el-select
                      v-model="ganttSettings.theme"
                      size="mini"
                      placeholder="Selecione"
                      filterable
                    >
                      <el-option label="Default" value="default"></el-option>
                      <el-option label="Dark" value="dark"></el-option>
                      <el-option label="Vue" value="vue"></el-option>
                      <el-option
                        label="Material blue"
                        value="material-blue"
                      ></el-option>
                      <el-option label="Creamy" value="creamy"></el-option>
                      <el-option label="Slumber" value="slumber"></el-option>
                      <el-option label="Sky" value="sky"></el-option>
                      <el-option label="Crimson" value="crimson"></el-option>
                      <el-option label="Grove" value="grove"></el-option>
                      <el-option label="Fuchsia" value="fuchsia"></el-option>
                      <el-option label="Flare" value="flare"></el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
              <!-- grid-->
              <div class="form-group row m-0 p-0 mt-0">
                <label
                  class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-4 mb-2"
                >
                  <div class="pt-1">
                    Grid
                  </div>
                </label>
                <div class="col-md-6 pr-4 mb-2">
                  <base-switch
                    v-model="ganttSettings.grid"
                    type="primary"
                    offText="não"
                    onText="sim"
                    class="primary"
                  ></base-switch>
                </div>
              </div>
            </div>
            <div class="modal-footer pb-0">
              <base-button type="secondary" @click="closeModal()">
                Cancelar
              </base-button>
              <base-button
                type="success"
                :loading="loadingSave"
                native-type="submit"
                :disabled="invalid"
              >
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { formatErrorValidation } from "@/plugins";

export default {
  components: {},
  name: "ModalGanttSettings",
  data() {
    return {
      modal: false,
      title: "Configurar Gantt",
      loadingSave: false,
      ganttSettings: {}
    };
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    openModal() {
      this.$Progress.start();
      this.$store
        .dispatch("scheduleGantt/show")
        .then(response => {
          this.ganttSettings = {
            hour_interval: response.data.hour_interval ?? 3,
            theme: response.data.theme ?? "default",
            grid: response.data.grid ?? false
          };
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.modal = true;
        })
        .catch(error => {
          this.ganttSettings = {
            hour_interval: 3,
            theme: "default",
            grid: false
          };
          this.modal = true;
          this.$Progress.finish();
        });
    },
    save() {
      this.$Progress.start();
      this.loadingSave = true;
      this.$store
        .dispatch("scheduleGantt/add", this.ganttSettings)
        .then(response => {
          this.loadingSave = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.$emit("updatedGanttSettings");
          this.closeModal();
        })
        .catch(error => {
          if (error.status == 200) {
            this.$notify({
              type: "danger",
              message: error.data.message
            });
            this.$Progress.finish();
            this.loadingSave = false;
          } else if (error.response && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors
            });
          }
          this.$Progress.finish();
          this.loadingSave = false;
        });
    },
    changeHoursInterval(value) {
      this.ganttSettings.hour_interval = value;
    }
  },
  mounted() {}
};
</script>

<style></style>
