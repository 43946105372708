<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
        <div class="row">
          <div v-if="travel" class="col-md-12">
            <div class="">
              <span class="h3 d-block">{{
                  travel.load_truck_time.slice(-5)
                }}</span>
              <span class="h4 small d-block">{{
                  travel.load_truck_time.slice(0, 11)
                }}</span>
              <span class="h4 small d-block"
              >{{ travel.send_plant.name }}
                <span v-if="travel.charge_point">
                  ({{ travel.charge_point }})</span
                >
              </span>
              <span class="h4 small d-block">{{ travel.driver }}</span>
              <span class="h4 small d-block" v-if="travel.equipment"
              >{{ travel.equipment.code }} {{ travel.equipment.plate }}</span
              >
            </div>
          </div>
          <hr>
          <div class="col-md-12 mt-2">
            <label>Motivo
              <span class="text-danger"> * </span>
            </label>
            <validation-provider rules="required|min:15">
              <base-input input-group-classes="input-group-sm">
              <textarea
                v-model="reason"
                rows="5"
                type="text"
                placeholder="Informe o motivo do cancelamento com no mínimo 15 caracteres."
                class="form-control form-control-sm"
              />
              </base-input>
            </validation-provider>
          </div>
        </div>
        <div class="p-3">
          <div class="modal-footer pb-0 pr-0">
            <base-button type="secondary" @click="closeModal()">
              Fechar
            </base-button>
            <base-button :disabled="invalid" :loading="loading" type="danger" @click.native="cancel()">
              <span v-show="!loading" class="btn-label"><i class="fa-solid fa-ban"></i></i></span>
              Concluir cancelamento
            </base-button>
          </div>
        </div>
      </validation-observer>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  components: {},
  computed: {
    ...mapGetters({
      $_operational_permission: "operationalPermission/show",
    })
  },
  name: "ModalCancelTravel",
  data() {
    return {
      modal: false,
      reason: null,
      title: 'Cancelamento da viagem',
      loading: false,
      travel: null,
    }
  },
  methods: {
    closeModal() {
      this.modal = false
    },
    cancel() {
      this.loading = true
      this.$Progress.start();
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch('travels/cancel', {
        uuid: this.travel.uuid,
        reason: this.reason
      })
    .then(response => {
      this.$notify({type: 'success', message: response.message});
      this.loading = false
      this.$emit('cancel', this.travel)
    })
      .catch(error => {
        this.$notify({type: 'danger', message: error.data.message});
        this.loading = false
      })
    this.$Progress.finish();
  },
  async openModal(travel) {
    const loader = this.$loading.show();
    await this.$store.dispatch('operationalPermission/getByUserId', this.$user.id);
    loader.hide();
    if(!this.$_operational_permission || !this.$_operational_permission.travel_cancellation) {
      return this.$notify({
        type: "danger",
        message: "Você não possui permissão para cancelar viagens."
      });
    }
    this.reason = null
    this.travel = travel
    this.modal = true
  },
},
};
</script>

<style></style>
