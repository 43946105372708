import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import auth from "@/middleware/auth";

import ScheduleServiceForAgenda from "@/views/Modules/Services/Agenda/ScheduleServiceForAgenda/Index.vue";

const services = {
  path: "/services",
  component: DashboardLayout,
  name: "Serviços",
  meta: {
    permission: "operational.dashboard.index.view",
    middleware: auth,
  },
  children: [
    {
      path: "/service/schedule-service-agenda",
      name: "services.schedule-service-agenda.index.view",
      component: ScheduleServiceForAgenda,
      meta: {
        title: 'Services',
        description: 'Agenda',
        permission: 'service.dashboard.index.view',
        icon: 'fa fa-home',
      },
    },
  ],
};

export default services;
