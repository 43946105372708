<template>
  <div>
    <div class="card" :style="multiFilterInlineStyles">
      <div class="card-body">
        <slot name="beforeMainBarTop"/>
        <div id="multi-filter">
          <form @submit.prevent="push">
            <!--              slot para adicionar qualquer html ao card do filtro-->
            <div class="row">
              <slot/>
            </div>
            <hr v-if="divider">
            <div class="row">
              <slot name="beforeMainBar"/>
              <template v-if="!without_bar_filter">
                <div class="p-2" :class="!without_filter ? barWidth : sizeable ? 'col-md-9' : 'col-md-10'">
                  <base-input appendClass="input-grouped-rounded-append" input-classes="input-grouped-rounded"
                              :disabled="disabled" :size="size"
                              @keydown.enter="push" @keypress.space="push" v-model="filtered" placeholder="Pesquisar">
                    <template slot="append">
                      <a :style="disabled && 'pointer-events: none'" href="#" @click.prevent="push">
                        <i
                          style="color: #2d3748"
                          :style="
                            global.length ? 'opacity: 80%' : 'opacity: 50%'
                          "
                          class=" fas fa-search"
                        ></i>
                      </a>
                    </template>
                  </base-input>
                </div>
                <div :class="sizeable ? 'col-md-3' : 'col-md-2'" class="p-2">
                  <base-button :size="size"
                               outline
                               title="Limpar filtros"
                               :disabled="!global.length"
                               @click.prevent="clearFilters"
                               block
                               :type="global.length > 0
                  ? 'danger' : 'light'"
                  >
                    <i :style="global.length === 0 && 'color: #615656'" class="fas fa-1x fa-eraser"></i>
                  </base-button>
                </div>
                <base-dropdown class="col-md-3 p-2" v-show="!without_filter && Object.values(status).length !== 1">
                  <base-button :size="size"
                               :disabled="prohibited.includes(filter.status)"
                               slot="title-container"
                               block
                               :type="filter.status ? (  status[filter.status]
                              ? status[filter.status].type
                              : textClass) : 'dark'
                              " class="dropdown-toggle text-white text-uppercase">
                  <span style="font-size: 0.8rem">
                      {{
                      status[filter.status]
                        ? status[filter.status].name
                        : textValidation
                    }}
                  </span>

                  </base-button>
                  <div>
                    <a
                      :key="index"
                      v-for="(item, index) in status"
                      v-if="JSON.parse(index) != filter.status"
                      @click.passive="filter.status = JSON.parse(index)"
                      @click.prevent="handleFilterClick"
                      class="dropdown-item"
                    >
                      {{ item.name }}
                    </a
                    >
                  </div>
                </base-dropdown>
              </template>
            </div>
            <div class="row mt-2">
              <div class="col-md-12 pl-2">
                <base-button
                  :size="size"
                  class="mt-2"
                  :key="key"
                  v-for="(value, key) in global"
                  type="primary"
                >
                    <span class="text-uppercase"
                    >{{ value }}
                      <a
                        href="#"
                        class="text-white"
                        @click.prevent="removeSelected(key)"
                      >
                        <i class="fas fa-times"></i> </a
                      ></span>
                </base-button>
              </div>
            </div>
          </form>
          <slot name="after">
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MultiFilter",
  props: {
    // lista de filtros para envio no backend
    filter: {
      type: [Array, Object],
      required: true
    },
    // lista de valores setados para condição de desabilitar filtro lateral
    prohibited: {
      type: [Array],
      default: () => ([]),
    },
    divider: {
      type: Boolean,
      default: false,
    },
    sizeable: {
      type: Boolean,
      default: false,
    },
    without_filter: {
      type: Boolean,
      default: false,
    },
    mainBarWidth: {
      type: String,
      default: () => '',
    },
    multiFilterInlineStyles: {
      type: String,
      default: () => '',
    },
    size: {
      type: String,
      default: 'md'
    },
    disabled: {
      default: false,
      type: Boolean
    },
    without_bar_filter: {
      default: false,
      type: Boolean
    },
    textValidation: {
      default: 'Todos',
      type: String,
    },
    textClass: {
      default: 'primary',
      type: String,
    },
    // lista de status para busca no backend
    // descricao: indice: id no banco, name: texto que sera exibido no dropdown, type: cor do badge
    status: {
      type: Object,
      default: function () {
        return {
          null: {
            name: "Todos",
            type: "primary"
          },
          0: {
            name: "Inativo",
            type: "danger"
          },
          1: {
            name: "Ativo",
            type: "success"
          },
        };
      },
      required: false
    }
  },
  data() {
    return {
      global: [],
      filtered: null,
      period: [],
    };
  },
  computed: {
    barWidth() {
      if (!this.mainBarWidth) {
        return Object.values(this.status).length !== 1 ? "col-md-7" : "col-md-10";
      }
      return this.mainBarWidth;
    }
  },
  methods: {
    validFilterable() {
      if (this.$parent.range.hasOwnProperty('default_filterable')) {
        if (!this.$parent.range.default_filterable) {
          return true
        } else {
          return false
        }
      }
      return true
    },
    // add filtro ao array
    push() {
      if (!this.filtered) {
        return this.handleFilterClick();
      }
      this.filtered.split(' ').map(function (item) {
        item = item.replaceAll('(', '')
        item = item.replaceAll(')', '')
        if (item != '') {
          this.global.push(item);
        }
      }, this)
      this.filtered = null;
      this.handleFilterClick();
    },
    // limpa os filtros
    clearFilters(must_emit = true) {
      this.global = [];
      const filters = {...this.filter};
      filters.global = this.global;
      filters.status = null
      if (this.$parent.$el._prevClass && this.$parent.$el._prevClass.includes('modal')) {
        this.$parent.$parent.$parent.filter = []
      }
      if (must_emit === false) return;
      if (this.$listeners.hasOwnProperty('clearFilters')) {
        this.$emit('clearFilters', filters)
      } else {
        this.$emit('fetch', filters)
      }
    },
    // faz a requisicao na api
    handleFilterClick() {
      const filters = {...this.filter};
      filters.global = this.global;
      if (filters.status == null) {
        delete filters.status;
      }
      this.$emit("fetch", filters);
    },
    // remove o filtro selecionado do array
    removeSelected(key) {
      this.global.splice(key, 1);
      this.handleFilterClick();
    },
  }
};
</script>

<style scoped>
.btn-success-light {
  background-color: #68b879;
  border-color: #68b879;
}
</style>
