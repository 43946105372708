import {getPropertiesByArray} from "../../../../../helpers";

const baseResponsibilityBarChart = {
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "cross",
      crossStyle: {
        color: "#999",
      },
    },
    formatter: function (value) {
      let text = `${value[0].axisValue} <br/>`;
      value.map(function (obj) {
        text += `${obj.marker} ${obj.seriesName}: <b> ${obj.data}</b> <br/>`;
      });
      return text;
    },
  },
  toolbox: {
    feature: {
      dataView: {show: true, readOnly: false},
      magicType: {show: true, type: ["line", "bar"]},
      restore: {show: true},
      saveAsImage: {show: true},
    },
  },
  dataZoom: [
    {
      show: true,
      start: 0,
      end: 100,
    }
  ],
  legend: {
    data: [],
  },
  xAxis: [
    {
      type: "category",
      data: [],
      axisPointer: {
        type: "shadow",
      },
    },
    {
      show: false,
      type: "category",
      data: [],
      axisPointer: {
        type: "shadow",
      },
    },
  ],
  yAxis: [
    {
      type: "value",
      name: "Resistência",
      axisLabel: {
        formatter: "{value}",
      },
    },
  ],
  loading: true,
  series: [],
};

export function handleResponsibilityBarChart(payload) {
  let responsibilityBarChart = baseResponsibilityBarChart;
  responsibilityBarChart.series = [];
  // dados do eixo x
  responsibilityBarChart.xAxis[0].data = getPropertiesByArray("item_name", payload.data);
  // legendas
  responsibilityBarChart.legend.data = payload.subtitles_names;

  // conta quantos reais existem
  let real_quantity = payload.subtitles_names.filter((item) => item.includes("(R)")).length;

  payload.subtitles_names.forEach(function (name, index) {
    let serie = {
      name: name,
      type: "bar",
      data: [],
    };

    //  agrupa os valores
    if (name.includes("(R)")) {
      serie.data = payload.data.reduce((a, o) => {
        return o.real[index] ? (a.push(o.real[index].value), a) : false
      }, []);
      serie.color = "#0c8fe7";
    } else {
      serie.data = payload.data.reduce((a, o) => {
        if (!o.estimated[index - real_quantity]) {
          return null
        }
        if (a === null) {
          return [15,0,15]
        }
        const b = o.estimated[index - real_quantity] ? o.estimated[index - real_quantity].value : 0
        return (a.push(b), a)
      }, []);
      serie.color = "Grey";
    }

    // insere em data principal
    responsibilityBarChart.series.push(serie);
  });

  // o.s
  let os_serie = {
    name: "O.S",
    type: "line",
    xAxisIndex: 1,
    data: [],
    color: "Yellow",
  };

  // salvando o.s
  os_serie.data = payload.data
    .reduce((a, o) => {
      a = [...getPropertiesByArray("os_value", o.real).reverse(), ...a];
      a = [...getPropertiesByArray("os_value", o.estimated).reverse(), ...a];
      return a;
    }, [])
    .reverse();

  // inserindo o.s no gráfico
  responsibilityBarChart.xAxis[1].data = os_serie.data;
  responsibilityBarChart.series.push(os_serie);
  responsibilityBarChart.legend.data.push("O.S");

  responsibilityBarChart.loading = false;
  return responsibilityBarChart;
}

