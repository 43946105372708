<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-gray d-inline-block mb-0">{{ $route.name }}</h6>
          <nav
            aria-label="breadcrumb"
            class="d-none d-md-inline-block ml-md-4 text-gray"
          >
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i></a></li>
              <li class="breadcrumb-item"><a href="#">Tecnologia</a></li>
              <li class="breadcrumb-item" aria-current="page">{{ $route.name }}</li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- Header Menus -->
      <div class="row">
        <div class="col-md-12">
          <div class="row">
              <div class="col-md-3 col-lg-2 col-xlg-2 ml-3 mr-3 mb-3 ml-0 p-0">
                <base-button class="pb-3 pt-3" block size="lg" type="success" @click="handleCreate()">
                  <i class="fas fa-plus"></i> NOVO
                </base-button>
              </div>

              <div class="col-md-3 col-lg-2 col-xlg-2 ml-3 mr-3 mb-3 ml-0 p-0">
                <router-link :to="{ path:'/technology/mix/grid'}">
                  <base-button block size="lg" type="light" class="p-3">
                    VOLTAR
                  </base-button>
                </router-link>
              </div>
          </div>
        </div>
      </div>
    </base-header>
    <list-additional-classification></list-additional-classification>
    <create-additional-classification ref="createAdditionalClassification"></create-additional-classification>
  </div>
</template>
<script>
import ListAdditionalClassification from './Shared/_List'
import CreateAdditionalClassification from './Shared/_Create'
export default {
  components: {
    ListAdditionalClassification,
    CreateAdditionalClassification,
  },
  data() {
    return {
    }
  },
  methods: {
    handleCreate(){
      this.$refs.createAdditionalClassification.handleCreateModal()
    },
  },
  created(){
  },
  mounted() {
  }
};
</script>
<style></style>
