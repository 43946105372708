import { SapIntegrationSyncType } from "../types";
import { sapIntegrationSyncService } from "../services";
import { sapIntegrationGetExternalIdService } from "../services";
/**
 * @param {SapIntegrationSyncType} data
 * @returns {Promise}
 */
const sync = async (data) => await sapIntegrationSyncService.execute(data);

/**
 * @param {number} typeable_id
 * @param {string} type
 * @returns {Promise<string>}
 */
const getExternalId = async (typeable_id, type) => await sapIntegrationGetExternalIdService.execute(typeable_id, type);
export default {
  sync,
  getExternalId
};
