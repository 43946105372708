<template>
  <div>
    <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
      <form
        @submit.prevent="handleSubmit(onSubmitClick)"
        class="needs-validation"
        autocomplete="off"
      >
        <div class="container-fluid">
          <div class="row">
            <div class="col-12" style="display: flex; align-items: center;">
              <img src="/img/icons/icons8/ios/info-squared.png" width="20">
              <h4 class="m-0 mx-3" style="font-size: 16px; font-weight: 500; color: #2B2D32;">
                  Informação
              </h4>
              <hr class="ml-3" style="width: 100%;">
            </div>
          </div>
          <div class="form-group">
            <div v-if="isEdit" class="form-group row m-0 p-0">
              <div class="col-md-5 my-1 px-0">
                <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                    STATUS
                </div>
              </div>
              <div v-if="formData.status === true" class="col-md-7 mb-2 px-0">
                <span class="d-flex float-right align-items-center status-button-success" @click="formData.status = false;">
                  <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                  Ativo
                </span>
              </div>
              <div v-if="formData.status === false" class="col-md-7 mb-2 px-0">
                <span class="d-flex float-right align-items-center status-button-danger" @click="formData.status = true;">
                  <img src="/img/icons/icons8/ios/shutdown--v1_danger.png" alt="shutdown--v1_danger" width="12px">
                  Inativo
                </span>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <div class="col-md-5 my-1 px-0">
                <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                    NOME
                </div>
              </div>
              <div class="col-md-7 mb-2 px-0">
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm" :disableBoxShadow="true">
                    <input
                        v-model="formData.name"
                        type="text"
                        class="form-control form-control-sm"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0 mb-2">
              <div class="col-md-5 my-1 px-0">
                <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                    TIPO
                </div>
              </div>
              <div class="col-md-7 mb-2 px-0">
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm">
                      <PuzlSelect
                        v-model="formData.type"
                        :items="operation_source_types"
                        customKey="value"
                        label="text"
                        :disableBoxShadow="true"
                      />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0 mb-2">
              <div class="col-md-5 my-1 px-0">
                <div class="pb-0 mb-1 form-control-label font-weight-400" style="font-size: 14px;">
                  Regime Tributário
                  <span class="text-danger">&nbsp;*</span>
                </div>
              </div>
              <div class="col-md-7 mb-2 px-0">
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                      @input="changedTaxRegimes($event, formData)"
                      :multiple="true"
                      v-model="formData.tax_regimes"
                      label="label"
                      :items="option_tax_regimes"
                      customKey="value"
                      :disableBoxShadow="true"
                  />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0 mb-2">
              <div class="col-md-5 my-1 px-0">
                <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                    CATEGORIA
                </div>
              </div>
              <div class="col-md-7 mb-2 px-0">
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm">
                      <PuzlSelect
                        v-model="formData.product_service_hub_type_id"
                        :items="[{ id: 2, name: 'MCC' }, ...filteredHubTypes]"
                        :disableBoxShadow="true"
                      />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0 mb-2">
              <div class="col-md-5 my-1 px-0">
                <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                    GERAR DOCUMENTO
                </div>
              </div>
              <div class="col-md-7 mb-2 px-0">
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm">
                      <PuzlSelect :disabled="formData.type === 0" :clearable="false" label="label"
                        v-model="formData.document_template_id" :items="formattedDocumentTemplates()"
                        :disableBoxShadow="true"
                      />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0 mb-2">
              <div class="col-md-5 my-1 px-0">
                <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                    PLANO DE CONTAS GERENCIAL
                </div>
              </div>
              <div class="col-md-7 mb-2 px-0">
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm">
                      <PuzlSelect :multiple="true" :clearable="false" label="$code$. $name$" :labelMask="true"
                        v-model="formData.chart_accounts" :items="chart_accounts"
                        :disableBoxShadow="true"
                      />
                  </base-input>
                </validation-provider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12" style="display: flex; align-items: center;">
              <img src="/img/icons/icons8/ios/administrative-tools_primary.png" width="20">
              <h4 class="m-0 mx-3" style="font-size: 16px; font-weight: 500; color: #2B2D32;">
                  Configuração
              </h4>
              <hr class="ml-3" style="width: 100%;">
            </div>
            <div class="col-12 mt-n1">
              <span style="font-size: 14px; font-weight: 400; color: #2B2D32;">Financeiro e estoque</span>
            </div>
          </div>
          <div class="form-group mt-3">
            <div class="form-group row m-0 p-0">
              <div class="col-md-5 my-1 px-0">
                <div class="pb-0 mb-1 form-control-label" style="font-size: 12px; font-weight: 400; color:#606062">
                    GERA FINANCEIRO
                </div>
              </div>
              <div v-if="formData.is_generate_financial === true" class="col-md-7 mb-2 px-0">
                <span class="d-flex float-right align-items-center status-button-success" @click="formData.is_generate_financial = false;">
                  <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                  Ativo
                </span>
              </div>
              <div v-if="formData.is_generate_financial === false" class="col-md-7 mb-2 px-0">
                <span class="d-flex float-right align-items-center status-button-danger" @click="formData.is_generate_financial = true;">
                  <img src="/img/icons/icons8/ios/shutdown--v1_danger.png" alt="shutdown--v1_danger" width="12px">
                  Inativo
                </span>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <div class="col-md-5 my-1 px-0">
                <div class="pb-0 mb-1 form-control-label" style="font-size: 12px; font-weight: 400; color:#606062">
                    MOVIMENTA ESTOQUE
                </div>
              </div>
              <div v-if="formData.is_generate_stock === true" class="col-md-7 mb-2 px-0">
                <span class="d-flex float-right align-items-center status-button-success" @click="formData.is_generate_stock = false;">
                  <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                  Ativo
                </span>
              </div>
              <div v-if="formData.is_generate_stock === false" class="col-md-7 mb-2 px-0">
                <span class="d-flex float-right align-items-center status-button-danger" @click="formData.is_generate_stock = true;">
                  <img src="/img/icons/icons8/ios/shutdown--v1_danger.png" alt="shutdown--v1_danger" width="12px">
                  Inativo
                </span>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <div class="col-md-5 my-1 px-0">
                <div class="pb-0 mb-1 form-control-label" style="font-size: 12px; font-weight: 400; color:#606062">
                    NOTA MÃE
                </div>
              </div>
              <div v-if="formData.is_entry_value === true" class="col-md-7 mb-2 px-0">
                <span class="d-flex float-right align-items-center status-button-success" @click="formData.is_entry_value = false;">
                  <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                  Ativo
                </span>
              </div>
              <div v-if="formData.is_entry_value === false" class="col-md-7 mb-2 px-0">
                <span class="d-flex float-right align-items-center status-button-danger" @click="formData.is_entry_value = true;">
                  <img src="/img/icons/icons8/ios/shutdown--v1_danger.png" alt="shutdown--v1_danger" width="12px">
                  Inativo
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-3">
                <span style="font-size: 14px; font-weight: 400; color: #2B2D32;">Contábil e Fiscal</span>
              </div>
            </div>
            <div class="form-group mt-3">
              <div class="form-group row m-0 p-0">
                <div class="col-md-5 my-1 px-0">
                  <div class="pb-0 mb-1 form-control-label" style="font-size: 12px; font-weight: 400; color:#606062">
                      CONTABILIZA
                  </div>
                </div>
                <div v-if="formData.is_computable === true" class="col-md-7 mb-2 px-0">
                  <span class="d-flex float-right align-items-center status-button-success" @click="formData.is_computable = false;">
                    <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                    Ativo
                  </span>
                </div>
                <div v-if="formData.is_computable === false" class="col-md-7 mb-2 px-0">
                  <span class="d-flex float-right align-items-center status-button-danger" @click="formData.is_computable = true;">
                    <img src="/img/icons/icons8/ios/shutdown--v1_danger.png" alt="shutdown--v1_danger" width="12px">
                    Inativo
                  </span>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <div class="col-md-5 my-1 px-0">
                  <div class="pb-0 mb-1 form-control-label" style="font-size: 12px; font-weight: 400; color:#606062">
                      GERA LIVRO FISCAL
                  </div>
                </div>
                <div v-if="formData.is_generate_tax_book === true" class="col-md-7 mb-2 px-0">
                  <span class="d-flex float-right align-items-center status-button-success" @click="formData.is_generate_tax_book = false;">
                    <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                    Ativo
                  </span>
                </div>
                <div v-if="formData.is_generate_tax_book === false" class="col-md-7 mb-2 px-0">
                  <span class="d-flex float-right align-items-center status-button-danger" @click="formData.is_generate_tax_book = true;">
                    <img src="/img/icons/icons8/ios/shutdown--v1_danger.png" alt="shutdown--v1_danger" width="12px">
                    Inativo
                  </span>
                </div>
              </div>
              <div class="modal-footer px-0">
                <div class="d-flex pr-2" style="gap: 18px;">
                  <div class="d-flex align-items-center button-cancel" style="gap: 5px;" @click="onCancelClick">
                    <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="16px">
                    <span style="font-weight: 500;">
                      Cancelar
                    </span>
                  </div>
                  <div class="d-flex align-items-center button-save" style="gap: 5px;" @click.prevent="onSubmitClick">
                    <img src="/img/icons/icons8/ios/save_success.png" alt="save_success" width="16px">
                    <span style="font-weight: 500;">
                      Salvar
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, computed } from "vue";
import { OperationSourceCreateType, OperationSourceUpdateType } from "../../types";
import operationSourceStore from "../../store";
import { ValidationObserver } from "vee-validate";
import { useOperationSourceHook } from "../../hooks/useOperationSourceHook";
import PuzlSelect from "@/components/PuzlSelect";
/**
 * Utilizando dados exportados pelo Hook
 */
const {
  operation_source_types,
  option_tax_regimes,
  formattedDocumentTemplates,
  changedTaxRegimes,
  chart_accounts,
  filteredHubTypes
} = useOperationSourceHook();

/**
 * Propriedades para o componente
 * @typedef {Object} Props
 * @property {OperationSourceCreateType|OperationSourceUpdateType} formData
 */
/** @type {Props} */
const props = defineProps({
  formData: {
    type: Object,
    required: true,
  },
  isEdit: {
    type: Boolean,
    required: false,
    default: false
  }
});

/** Eventos emitidos */
const emit = defineEmits([
  "onCancelClick", 
  "onSubmitClick"
]);

/** Store Getters */
const isSaving = computed(() => operationSourceStore.getIsSaving());

function onCancelClick() {
  emit("onCancelClick");
}

function onSubmitClick() {
  emit("onSubmitClick");
}
</script>

<style scoped>
.text-title {
  font-size: 18px;
  font-weight: 500;
  color: #2B2D32;
}
.status-button-success {
  background-color: #F2F7F3;
  border-radius: 16px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #149E57;
  font-weight: 400;
  padding: 4px 40px;
}
.status-button-danger {
  background-color: #F4C7C3;
  border-radius: 16px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #DB4539;
  font-weight: 400;
  padding: 4px 34px
}
.button-cancel {
  cursor: pointer;
  border: 0.5px solid #DB4539;
  border-radius: 8px;
  color: #DB4539;
  padding: 4px 20px;
}
.button-save {
  cursor: pointer;
  border: 0.5px solid #149E57;
  border-radius: 8px;
  color: #149E57;
  padding: 4px 30px;
}
.button-save:hover {
  background: #149E57;
  color: white;
}
.button-save:hover img {
  filter: brightness(0) invert(1);
}
.button-cancel:hover {
  background: #DB4539;
  color: white;
}
.button-cancel:hover img {
  filter: brightness(0) invert(1);
}
</style>