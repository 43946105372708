
import { electronicBankStatementRedeRevalidateService } from "../services";

/**
 * @param {number} entityBillReceiveId 
 * @param {number} paymentIntermediaryMerchantId
 * @returns {Promise<void>}
 */
const redeRevalidate = async (entityBillReceiveId, paymentIntermediaryMerchantId) => await electronicBankStatementRedeRevalidateService.execute(entityBillReceiveId, paymentIntermediaryMerchantId);

export default {
  redeRevalidate,
};
