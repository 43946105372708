import store from "../../../../shared/libraries/store";
import { SearchBarFilterType } from "../../../../components/AppGlobal";
import {
  AdjustmentHistoryListFilterType,
  AdjustmentHistoryListPagerType,
  initAdjustmentHistoryListPagerType,
} from "../types";
import { adjustmentHistoryStoreStateKeys } from "./adjustmentHistoryStoreStateKeys";

/** @returns {AdjustmentHistoryListPagerType} */
const getListed = () =>
  store.getState(adjustmentHistoryStoreStateKeys.LISTED) ??
  initAdjustmentHistoryListPagerType();

/** @returns {boolean} */
const getIsListing = () =>
  store.getState(adjustmentHistoryStoreStateKeys.IS_LISTING) ?? false;

/** @returns {boolean} */
const getIsSaving = () =>
  store.getState(adjustmentHistoryStoreStateKeys.IS_SAVING) ?? false;

/**
 * @returns {{
 *   searchBarFilter: SearchBarFilterType,
 *   filter: AdjustmentHistoryListFilterType
 * }}
 */
const getPageState = () =>
  store.getState(adjustmentHistoryStoreStateKeys.PAGE_STATES);

export default {
  getListed,
  getIsListing,
  getIsSaving,
  getPageState,
};
