<template>
  <div class="card-body mt-n2 p-0">
    <div class="row">
      <div class="col-md-12 table-responsive">
        <table class="table table-sm table-bordered">
          <thead>
            <tr class="fixed">
              <th class="text-center">PUZL ADJUST</th>
              <th class="text-center">CENTRAL</th>
              <th class="text-center">TRAÇO</th>
              <th class="text-center">MCC</th>
              <th class="text-center">IMPOSTO</th>
              <th class="text-center">MARGEM</th>
              <th class="text-center">PREÇO MÍNIMO</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(concrete_price, index) in concrete_prices" :key="index">
              <td class="text-center">
                <span class="numeric mr-1">
                  <a @click="handleModalPuzlAdjust(concrete_price)" href="#">
                    <img src="https://img.icons8.com/ios/100/2b2d32/calculator--v1.png" width="25px" height="25px">
                  </a>
                </span>
              </td>
              <td class="text-center">
                <span class="mr-1 new-default-black-font">
                  {{ concrete_price.plant.plant_name }}
                </span>
              </td>
              <td class="text-left">
                <span class="new-default-black-font font-weight-600" v-for="(feature, indexFeature) in concrete_price.mixe.features" :key="indexFeature">
                  {{ feature.feature_name }} {{ feature.item }} {{ feature.unit_letter }}
                  <span v-if="indexFeature < (concrete_price.mixe.features.length-1)">+</span>
                </span>
                <small class="mb-1 font-weight-500 new-default-black-font">
                  | {{ concrete_price.service.type }} |
                </small>
                <small class="mb-1 font-weight-500 new-default-black-font" v-show="concrete_price.service.service_name">
                  {{ concrete_price.service.service_name }}
                </small>
                <small class="small font-weight-500 new-default-black-font" v-show="concrete_price.service.service_type">
                  | {{ concrete_price.service.service_type }}
                </small>
                <small class="mb-1 font-weight-500 new-default-black-font">
                  {{ concrete_price.mixe.slump }}
                </small>
                <small class="mb-1 font-weight-500 new-default-black-font">
                  | {{ concrete_price.mixe.product }}
                </small>
              </td>
              <td class="text-center">
                <span class="numeric mr-1 text-warning">
                  {{ concrete_price.price_parameter['mcc_price'].price }}
                </span>
              </td>
              <td class="text-center">
                <span class="numeric mr-1 text-danger">
                  {{ concrete_price.price_parameter['tax_price'].price }}
                </span>
              </td>
              <td class="text-center">
                <span class="numeric mr-1 text-success">
                  {{ calculateGrossMargin(concrete_price.price.minimum_price, concrete_price.price_parameter['mcc_price'].price, concrete_price.tax) | currency() }}
                  <span @click="handleModalConcreteParameters(concrete_price)" href="#" class="parameter-table-button ml-1">
                    <img src="https://img.icons8.com/ios/100/FFFFFF/vertical-settings-mixer--v1.png" width="18px" height="17px">
                  </span>
                </span>
              </td>
              <td class="text-center">
                <span class="numeric mr-1 new-default-black-font">
                  {{ concrete_price.price.minimum_price }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <ModalConcreteParameters ref="modalConcreteParameters" />
  </div>
</template>
<script>

import ModalConcreteParameters from './_ModalConcreteParameters.vue';

export default {
  name: "TableViewConcretePrice",
  props: {
    loading: Boolean,
    concrete_prices: Array,
    calculateGrossMargin: { type: Function },
    showParametters: { type: Function },
    handleModalPuzlAdjust: { type: Function }
  },
  components: {
    ModalConcreteParameters
  },
  data() {
    return {
    }
  },
  methods: {
    handleModalConcreteParameters(item)
    {
      let crossMargin = this.calculateGrossMargin(item.price.minimum_price, item.price_parameter['mcc_price'].price);
      this.$refs.modalConcreteParameters.openModal(item, crossMargin);
    },
  }
};
</script>
<style scoped>
.parameter-table-button {
  background-color: #1A70B7;
  padding: 4px;
  border-radius: 5px;
}
</style>
