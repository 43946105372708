<template>
  <div>
    <modal size="lg" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <div class="px-4">
        <!-- Identificação -->
        <div class="row mb-2">
          <div class="col-3">
            <h3 style="line-height: normal !important" class="new-default-black-font mb-0 d-flex align-items-center">
              <img height="30px" src="/img/icons/icons8/ios/info-squared_warning.png" class="mr-3" />
              IDENTIFICAÇÃO
            </h3>
          </div>
          <div class="col-9">
            <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black mt-3 mb-4" />
          </div>
        </div>
        <div v-if="$_parent">
          <div class="row mb-4">
            <div class="col-md-12">
              <h3 class="new-default-black-font mb-0">
                Fornecedor :
                <span class="font-weight-normal">{{ $_parent.entity_name }} </span>
              </h3>
              <h3 class="new-default-black-font mb-0">
                Cnpj/Cpf :
                <span class="font-weight-normal">{{ $_parent.document }} </span>
              </h3>
              <h3 class="new-default-black-font mb-0">
                Documento :
                <span class="font-weight-normal">{{ $_parent.document_number }} </span>
                <span class="font-weight-normal">/ {{ $_parent.series }} </span>
              </h3>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-md-4">
              <card>
                <div class="mx-n1">
                  <img class="ml-n1 mb-3" src="/img/icons/icons8/ios/estimate-warning.png" width="30" />
                  <h3 class="new-default-gray-font font-weight-500 fs-14 mb-0">Valor da nota mãe</h3>
                  <h3 class="new-default-gray-font fs-14 font-weight-normal mb-0">{{ $_parent.total_value | currency() }}</h3>
                </div>
              </card>
            </div>
            <div class="col-md-4">
              <card>
                <div class="mx-n1">
                  <img class="ml-n1 mb-3" src="/img/icons/icons8/ios/wallet-app--v1_primary.png" width="30" />
                  <h3 class="new-default-gray-font font-weight-500 fs-14 mb-0">Saldo</h3>
                  <h3 class="new-default-gray-font fs-14 font-weight-normal mb-0">
                    {{ $_parent.children_balance | currency() }}
                  </h3>
                </div>
              </card>
            </div>
            <div class="col-md-4">
              <card>
                <div class="mx-n1">
                  <div class="row">
                    <div class="col-6">
                      <img class="ml-n1 mb-3" src="/img/icons/icons8/ios/plus-2-math_success.png" width="30" />
                    </div>
                    <div class="col-6 d-flex justify-content-end">
                      <img
                        @click.prevent="getParentExcelReport($_parent.id)"
                        role="button"
                        class="ml-n1 mb-3"
                        src="/img/icons/excel.png"
                        width="30"
                      />
                    </div>
                  </div>
                  <h3 class="row ml-0 new-default-gray-font font-weight-500 fs-14 mb-0">Notas filhas adicionadas</h3>
                  <h3 class="new-default-gray-font fs-14 font-weight-normal mb-0">
                    {{ getChidrenValue | currency() }} ({{ selected_childrens.length }})
                  </h3>
                </div>
              </card>
            </div>
          </div>
        </div>

        <!-- Notas filha -->
        <div class="row mb-3">
          <div class="col-3">
            <h3 style="line-height: normal !important" class="new-default-black-font mb-0 d-flex align-items-center">
              <img height="30px" src="/img/icons/icons8/ios/upload-link-document_primary.png" class="mr-3" />
              Notas filhas
            </h3>
          </div>
          <div class="ml-n2 col-4">
            <BaseButtonHoverable
              :disabled="loadingSkeleton"
              size="sm"
              type="warning"
              icon="women-age-group-3"
              platform="ios"
              :active="list_all_childrens"
              @click="handleListAllChildrens"
            >
              Notas filhas selecionadas
            </BaseButtonHoverable>
          </div>
          <div class="col-5">
            <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black mt-3 mb-4" />
          </div>
        </div>

        <div v-show="!list_all_childrens" class="row mb-5">
          <div class="col-12">
            <input-date-picker :defaultRange="filter.range" ref="date-picker" @handleFilterDate="handleFilterDate" />
          </div>
        </div>

        <PuzlEmptyData v-if="!$_childrens.length && !loadingSkeleton" />
        <div class="row" v-else>
          <ShadedTable :customHeaders="[0]" :length="$_childrens.length" :loading="loadingSkeleton" :headers="headers">
            <template slot="header_0">
              <img
                @click="active_select_all ? deselectAll() : selectAll()"
                width="18"
                src="/img/icons/double-box-tick-primary.png"
                class="pointer"
              />
            </template>
            <template v-for="(item, index) in $_childrens" :slot="index">
              <ShadedCol>
                <h5 class="new-default-black-font font-weight-normal m-0 mt-1 text-center">
                  <i
                    role="button"
                    v-if="selected_childrens.includes(item.id)"
                    @click="handleRemove(item)"
                    class="fas fa-check-square fa-2x text-primary"
                  />
                  <i v-else @click="handleSelect(item)" role="button" class="fas fa-square fa-2x" />
                </h5>
              </ShadedCol>
              <ShadedCol>
                <h5 class="new-default-black-font font-weight-normal m-0 mt-1">{{ item.issue_date | parseDate("DD MMM YYYY") }}</h5>
              </ShadedCol>
              <ShadedCol>
                <h5 class="new-default-black-font font-weight-normal m-0 mt-1">{{ item.document_number }}</h5>
              </ShadedCol>
              <ShadedCol>
                <h5 class="new-default-black-font font-weight-normal m-0 mt-1">{{ item.operation_source_name }}</h5>
              </ShadedCol>
              <ShadedCol>
                <h5 class="new-default-black-font font-weight-normal m-0 mt-1">{{ item.total_value | currency }}</h5>
              </ShadedCol>
            </template>
          </ShadedTable>
        </div>

        <div class="col-md-12">
          <Pagination @navegate="navegate" :source="source" />
        </div>

        <div class="modal-footer d-flex justify-content-end">
          <base-button type="danger" @click="closeModal()">
            <img height="30px" src="/img/icons/icons8/ios/cancel.png" class="mr-2" />
            Cancelar
          </base-button>
          <base-button type="primary" @click="store()">
            <img height="30px" src="/img/icons/icons8/ios/plus--v1.png" class="mr-2" />
            Adicionar
          </base-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import MultiFilter from "@/components/Utils/MultiFilterV2.vue";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import Pagination from "@/components/Utils/Pagination.vue";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import InputDatePicker from "@/components/InputDatePicker";
import ShadedTable from "@/components/ShadedTable.vue";
import ShadedCol from "@/components/ShadedCol.vue";
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import { mapGetters } from "vuex";

export default {
  name: "ModalSearchChildren",
  data() {
    return {
      modal: {
        title: "Adicionar notas filhas",
        create: false,
      },
      filter: {
        status: null,
        range: {
          start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
        },
      },
      multi_filter: {
        null: null,
      },
      source: null,
      headers: ["", "DATA", "DOCUMENTO", "TIPO DE OPERAÇÃO PUZL", "VALOR"],
      loadingSkeleton: true,
      selected_childrens: [],
      active_select_all: true,
      list_all_childrens: false,
    };
  },
  components: { MultiFilter, SkeletonPuzlGrid, Pagination, PuzlEmptyData, InputDatePicker, ShadedCol, ShadedTable, BaseButtonHoverable },
  computed: {
    ...mapGetters({
      $_parent: "entryLaunch/getParent",
      $_childrens: "entryLaunch/getChildrens",
    }),
    getChidrenValue() {
      return this.$_parent.children_balance ? Number(this.$_parent.total_value) - Number(this.$_parent.children_balance) : 0;
    },
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    async openModal(entry_launch_id) {
      const loader = this.$loading.show();
      this.loadingSkeleton = true;
      this.list_all_childrens = false;
      this.$refs["date-picker"].resetDate();
      await this.$store.dispatch("entryLaunch/getParent", entry_launch_id);
      this.selected_childrens = this.$_parent.children_ids ? this.$_parent.children_ids.split(",").map((i) => parseInt(i)) : [];
      this.$_parent.children_balance = this.$_parent.children_balance === null ? this.$_parent.total_value : this.$_parent.children_balance;
      loader.hide();
      this.modal.create = true;
      this.filter = {
        range: {
          start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
        },
        product_service_hub_type_id: this.$_parent.product_service_hub_type_id,
        entity_id: this.$_parent.entity_id,
        company_plant_issuer_id: this.$_parent.company_plant_issuer_id,
        parent_id: this.$_parent.id,
        page: 1,
      };
      this.getChildrens();
    },
    getChildrens() {
      this.$store.dispatch("entryLaunch/getChildrensWithoutParent", this.filter).then((response) => {
        this.source = response;
        this.loadingSkeleton = false;
        this.active_select_all =
          this.$_childrens.every((item) => this.selected_childrens.includes(item.id)) &&
          this.$_childrens.some((item) => this.selected_childrens.includes(item.id));
      });
    },
    navegate(page) {
      this.loadingSkeleton = true;
      this.filter.page = page;
      this.list_all_childrens ? this.getAllChildrens() : this.getChildrens();
    },
    handleSelect(item) {
      this.$_parent.children_balance = Number(this.$_parent.children_balance) - Number(item.total_value);
      this.selected_childrens.push(item.id);
    },
    handleRemove(item) {
      this.$_parent.children_balance = Number(this.$_parent.children_balance) + Number(item.total_value);
      this.selected_childrens.splice(
        this.selected_childrens.findIndex((to_remove) => to_remove === item.id),
        1
      );
    },
    handleFilterDate(filter) {
      this.loadingSkeleton = true;
      this.filter.range = filter;
      this.filter.page = 1;
      this.getChildrens();
    },
    store() {
      const loader = this.$loading.show();
      this.$store
        .dispatch("entryLaunch/storeChildrens", {
          parent_entry_launch_id: this.$_parent.id,
          entry_launch_ids: this.selected_childrens,
        })
        .then((response) => {
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.$emit("updated");
          this.closeModal();
        })
        .catch((error) => {
          this.$notify({
            type: error.data.error_type,
            message: error.data.message,
          });
        })
        .finally(() => {
          loader.hide();
        });
    },
    selectAll() {
      this.$_childrens.map((item) => {
        if (!this.selected_childrens.includes(item.id)) {
          this.handleSelect(item);
        }
      }, this);
      this.active_select_all = true;
    },
    deselectAll() {
      this.$_childrens.map((item) => {
        if (this.selected_childrens.includes(item.id)) {
          this.handleRemove(item);
        }
      }, this);
      this.active_select_all = false;
    },
    getParentExcelReport(id) {
      const loader = this.$loading.show();
      this.$store
        .dispatch("entryLaunch/getParentExcelReport", id)
        .then(async (response) => {
          let blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "Relatório de despesas.xlsx");
          await link.click();

          this.$notify({
            type: "success",
            message: "Solicitação realizada com sucesso!",
          });
        })
        .catch(() => {
          this.$notify({
            type: "danger",
            message: "Erro ao realizar o download do arquivo excel!",
          });
        })
        .finally(() => {
          loader.hide();
        });
    },
    handleListAllChildrens () {
      this.list_all_childrens = !this.list_all_childrens;
      this.loadingSkeleton = true;
      this.filter.page = 1;
      this.list_all_childrens ? this.getAllChildrens() : this.getChildrens();
    },
    getAllChildrens() {
      this.$store.dispatch("entryLaunch/getAllChildrens", {id :this.$_parent.id, page : this.filter.page}).then((response) => {
        this.source = response;
        this.loadingSkeleton = false;
        this.active_select_all = true;
      });
    }
  },
};
</script>
