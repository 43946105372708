<template>
  <button
    @click.prevent="$emit('click')"
    @mouseover="hover_icon = true" @mouseout="hover_icon = false"
    class="text-uppercase col-12 sm shadow d-flex justify-content-center align-items-center"
    :style="hover_icon || active ? 'color: white;border: 1px solid #DCDFE6' : 'color: #4b4b50;border: 1px solid #DCDFE6; background-color: white'"
    :class="[{ 'btn-icon btn-fab': icon },{ [`btn-${type}`]: active || hover_icon },{ [`btn-${size}`]: size }]"
  >
    <img style="margin-right: 4px" ref="icon" height="19" :src="setIcon()" alt="receive"/>
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "BaseButtonHoverable",
  props: ['type', 'size', 'icon', 'platform', 'active'],
  data() {
    return {
      hover_icon: 0,
    }
  },
  methods: {
    setHover(op) {
      this.hover_icon = op
    },
    setIcon() {
      const typeSuffix = (this.active || this.hover_icon) ? '' : `_${this.type}`;
      return `/img/icons/icons8/${this.platform}/${this.icon}${typeSuffix}.png`;
    }
  }
}
</script>

<style scoped>

button {
  color: #4b4b50;
  border: 1px solid #DCDFE6;
}
</style>
