<template>
<div>
    <div class="container-fluid">
      <multi-filter ref="multi-filter" @fetch="init" :filter="filter" :type="multi_filter">
        <div class="col-md-4 p-2">
          <InputDatePicker @handleFilterDate="handleFilterDate" />
        </div>
      </multi-filter>
    </div>
    <div class="container-fluid">
         <div class="row card-wrapper" v-show="loadingSkeleton">
            <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
        </div>
        <PuzlEmptyData v-if="!$_stockAdjustments.length && !loadingSkeleton"></PuzlEmptyData>
        <div class="row card-wrapper" v-if="$_stockAdjustments.length && !loadingSkeleton">
            <div class="col-lg-4" v-for="(stockAdjustment, index) in $_stockAdjustments" :key="index">
    
                    <card :class="stockAdjustment.type ? 'card-border-top-success' : 'card-border-top-danger'">
                    <!-- Card header -->
                    <div class="row align-items-center mb-3">
                        <div class="col-8">
                            <b> {{ stockAdjustment.company_plant_name }} </b>
                            <div>
                                {{ stockAdjustment.dosage_location_name }} {{ stockAdjustment.code }} | 
                                {{ stockAdjustment.company_plant_charge_point_name }} |
                                {{ stockAdjustment.company_plant_charge_point_location_name }}
                            </div>
                        </div>
                        <div class="col-4 text-right">
                            <base-dropdown menuOnRight>
                                <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                                    <i class="fas fa-cog"></i>
                                </base-button>
                                 <a class="dropdown-item" @click="handleEdit(stockAdjustment.id)">
                                    <i class="fas fa-edit text-warning main-icon-menu"></i>
                                    Editar
                                </a>
                                <div class="dropdown-divider p-0 m-0"></div>
                                <a class="dropdown-item" @click.prevent="handleDelete(stockAdjustment.id)">
                                    <i class="fas fa-times text-danger"></i>
                                    Excluir
                                </a>
                            </base-dropdown>
                        </div>
                    </div>
                    <!-- Card body -->
                    <div>
                        <b> {{ stockAdjustment.product_name }} </b>
                        <div>
                            {{ stockAdjustment.cmc_category_name }}
                        </div>
                    </div>
                    <div class="mt-3">
                        <b> {{ stockAdjustment.supplier_name }} </b>
                        <div>
                            {{ stockAdjustment.origin_name }}
                        </div>
                    </div>
                    <div class="text-center">
                         {{ stockAdjustment.date_stock_adjustment | parseDate('DD/MM/YYYY HH:mm') }}
                    </div>
                    <h2 class="text-center mt-1">
                        {{ formatQuantity(stockAdjustment.quantity) }} TON
                        <div>
                           {{ stockAdjustment.total | currency }}
                        </div>
                    </h2>
                    <div class="text-center text-dark">
                        {{ stockAdjustment.observations }}
                    </div>
                </card>
            </div>
        </div>
       
        <ModalEditStockAdjustment ref="ModalEditStockAdjustment"/>
        <loading-pagination :show="loading && !loadingSkeleton"/>
    </div>
</div>
</template>

<script>
import {mapGetters} from "vuex";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import MultiFilter from "@/components/Utils/MultiFilterV2";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import ModalEditStockAdjustment from './_ModalEdit';
import InputDatePicker from '@/components/InputDatePicker';
export default {
    name: "ListStockAdjustment",
    mixins: [cursorPaginate],
    components: {
        PuzlEmptyData,
        SkeletonPuzlGrid,
        MultiFilter,
        LoadingPagination,
        ModalEditStockAdjustment,
        InputDatePicker,
    },
    data() {
        return {
            loadingSkeleton: false,
            filter: {},
            multi_filter: {
                null: {
                name: "Todos",
                type: "primary"
                },
                '0': {
                name: "Entrada",
                type: "success"
                },
                '1': {
                name: "Saída",
                type: "danger"
                },
            },
            range: {
                start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
                end: new Date(),
            },
        };
    },
    computed: {
        ...mapGetters({
            $_stockAdjustments: "stockAdjustment/fetch",
        }),
    },
    mounted() {
        this.$refs['multi-filter']._self.$forceUpdate();
        this.init({});
    },
    methods: {
        init(filter = null) {
            this.startCursor(filter)
            this.$Progress.start()
            this.$store
                .dispatch("stockAdjustment/fetchItemsPaginate", {
                    filter: this.filter,
                    next_page: this.paginate.nextUrl
                })
                .then(response => {
                    this.$Progress.finish()
                    this.resolveCursor(response)
                })
                .catch(error => {
                    this.resolveCursor()
                    this.$Progress.finish()
                });
        },
        handleEdit(id) {
            this.$refs.ModalEditStockAdjustment.handleEditModal(id)
        },
        handleDelete(id) {
            this.$Swal.confirmDelete().then((result) => {
                if (result.isConfirmed) {
                    this.$Progress.start();
                    this.$notify({
                        type: 'info',
                        message: 'Estamos trabalhando em sua solicitação.'
                    });
                    this.$store.dispatch('stockAdjustment/destroy', id)
                        .then((response) => {
                            this.$notify({
                                type: 'success',
                                message: response.message
                            });
                        })
                        .catch(error => {
                            this.$notify({
                                type: error.data.error_type,
                                message: error.data.message
                            });
                        })
                    this.$Progress.finish();
                }
            }).catch(() => this.$Progress.finish())
        },
        handleFilterDate(filter) {
            this.range = filter
            this.init(this.range)
        },
        formatQuantity(value) {
            return value.toString().replace('.', ',')
        },
    },
};
</script>

<style></style>
