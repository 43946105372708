<template>
  <div>
    <div class="container-fluid">
      <card>
        <div>
          <div class="row card-wrapper" v-if="bill_receives.length && !loadingSkeleton">
            <div class="col-lg-4" v-for="(item, index) in bill_receives" :key="index">
              <div class="card main-card" style="overflow: hidden;border-radius: 20px !important">
                <div class="p-3 px-4">
                  <div class="row align-items-center">
                    <div class="col-10">
                      <h5 class="new-default-gray-font font-weight-500 mb-0">
                        {{ item.payment_method }}
                        <div v-if="item.nsu_code && item.payment_intermediary_has_credentials === 1" class="ml-2" style="display: inline-block;">
                          <el-popover v-if="item.card_integration_status === 0" trigger="hover" placement="right">
                            <span> Pendente </span>
                            <base-button outline slot="reference" size="sm" type="secundary"
                                         class="p-0 m-0 shadow-none text-indigo">
                              <img src="/img/icons/icons8/ios/api_warning.png" width="22">
                            </base-button>
                          </el-popover>
                          <el-popover v-if="item.card_integration_status === 1" trigger="hover" placement="right"
                                      class="p-0 pr-0">
                            <span> Integrado </span>
                            <base-button outline slot="reference" size="sm" type="secundary"
                                         class="p-0 m-0 shadow-none text-indigo">
                              <img src="/img/icons/icons8/ios/api_success.png" width="22">
                            </base-button>
                          </el-popover>
                          <el-popover v-if="item.card_integration_status === 2" trigger="hover" placement="right"
                                      class="p-0 pr-0">
                            <span> Divergente </span>
                            <base-button outline slot="reference" size="sm" type="secundary"
                                         class="p-0 m-0 shadow-none text-indigo">
                              <img src="/img/icons/icons8/ios/api_danger.png" width="22">
                            </base-button>
                          </el-popover>
                        </div>
                      </h5>
                      <h5 class="new-default-gray-font font-weight-500 mb-0">{{ item.payment_term }}</h5>
                    </div>
                    <div class="col-2 text-right">
                      <base-dropdown menuOnRight>
                        <div slot="title-container"
                          class="dropdown-toggle rounded m-0">
                          <img width="32" src="/img/icons/icons8/ios/settings--v1_primary.png">
                        </div>
                        <a
                          class="dropdown-item"
                          @click="handleEditEntity(item.id)"
                          style="align-items: center;display: flex;">
                          <img src="/img/icons/edit.png" width="22" alt="">
                          Editar
                        </a>
                        <a
                          v-if="pixDisabled(item) === false"
                          class="dropdown-item"
                          @click="handleModalPix(item.id)"
                          style="align-items: center;display: flex;"
                          >
                          <img src="/img/icons/qr-code--v1.png" width="22" alt="">
                          PIX
                        </a>
                        <div v-if="['Aprovar', 'Receber'].includes(BillReceiveStatus[item.installments[0]['status']])" class="dropdown-divider p-0 m-0"></div>
                        <a class="dropdown-item"
                          style="align-items: center;display: flex;"
                          v-if="['Aprovar', 'Receber'].includes(BillReceiveStatus[item.installments[0]['status']])"
                          @click="downloadAdvanceReceipt(item.installments[0]['entity_bill_receive_id'])">
                          <img src="/img/icons/icons8/ios/print-primary.png" width="22" alt="">
                          Imprimir
                        </a>

                        <!-- Totvs -->
                        <div v-if="hasTotvs && (item.installment_statistics.approved_value > 0 || item.installment_statistics.pay_value > 0)" class="dropdown-divider p-0 m-0"></div>
                        <a @click="handleModalEntityBillReceiveIntegrationInfo(item.id)"  v-if="hasTotvs" class="dropdown-item">
                          <img src="/img/icons/icons8/ios/downloading-updates-primary.png" width="22px" height="22px" />
                          Totvs
                        </a>

                        <div class="dropdown-divider p-0 m-0"></div>
                        <a class="dropdown-item"
                          style="align-items: center;display: flex;"
                          @click="handleDeleteEntity(item.bill_receive_id, index)">
                          <img src="/img/icons/icons8/ios/delete_danger.png" width="22" alt="">
                          Excluir
                        </a>
                      </base-dropdown>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 my-1">
                      <h1 class="new-default-black-font" style="display: flex;align-items: center;">
                        {{ item.total_value | currency() }}
                        <div class="ml-3"
                          style="display: inline-flex;"
                          :class="paidText(item).type">
                          <h4 class="font-weight-normal m-0 p-0">
                            {{ paidText(item).text }}
                          </h4>
                        </div>
                      </h1>
                    </div>
                    <div class="col-8">
                      <h5 class="mt-n2 new-default-gray-font font-weight-500">{{ item.competence | parseDate('DD MMM YYYY') }}</h5>
                    </div>
                    <div class="col-4 text-right mt-n3">
                      <el-popover v-if="hasTotvs && item.is_integrated_on_totvs === TotvsStatusEnum.INTEGRATED" trigger="hover" placement="right" class="mr-1">
                        <span class="p-2">
                          <span class="text-center">Integrado com a totvs</span>
                        </span>
                        <span slot="reference">
                          <img src="/img/icons/icons8/ios/connected_success.png" width="25px">
                        </span>
                      </el-popover>
                      <el-popover v-else-if="hasTotvs && item.is_integrated_on_totvs === TotvsStatusEnum.ERROR" trigger="hover" placement="right" class="mr-1">
                        <span class="p-2">
                          <span class="text-center">Erro de integração com a totvs</span>
                        </span>
                        <span slot="reference">
                          <img src="/img/icons/icons8/ios/disconnected_danger.png" width="25px">
                        </span>
                      </el-popover>
                      <el-popover v-if="item.nsu_code && item.is_nsu_code_duplicated"
                        trigger="hover" placement="right" class="p-0 pr-0">
                        <span class="text-uppercase new-default-black-font">Duplicidade de código</span>
                        <base-button outline slot="reference" size="sm" type="secundary"
                                     class="p-0 m-0 shadow-none text-indigo">
                          <img class="mr-1" src="/img/icons/icons8/ios/break--v2_warning.png" width="26">
                        </base-button>
                      </el-popover>
                      <el-popover v-if="item.any_has_billet && !item.any_has_pending_billet" trigger="hover" placement="left" class="p-0 pr-0">
                        <span v-if="item.installment_statistics.pending_value > 0 || item.installment_statistics.late_value > 0">
                          EMITIDO
                        </span>
                        <span v-else-if="item.installment_statistics.canceled_value > 0 || item.all_are_canceled">
                          CANCELADO
                        </span>
                        <span v-else>
                          RECEBIDO
                        </span>
                        <base-button @click.prevent="downloadBilletBatch(item.bank_billet_batch_id)" outline slot="reference" size="sm" type="secundary"
                                     class="p-0 m-0 shadow-none text-indigo">
                          <img v-if="item.installment_statistics.pending_value > 0 || item.installment_statistics.late_value > 0"
                            class="mr-2" src="/img/icons/barcode_warning.png" width="26">
                          <img v-else-if="item.installment_statistics.canceled_value > 0 || item.all_are_canceled"
                            class="mr-2" src="/img/icons/barcode_danger.png" width="26">
                          <img v-else class="mr-2" src="/img/icons/barcode_success.png" width="26">
                        </base-button>
                      </el-popover>
                      <el-popover v-if="item.any_has_pending_billet" trigger="hover" placement="left" class="p-0 pr-0">
                        <span>
                          EMITIR
                        </span>
                        <base-button @click.prevent="downloadBilletBatch(item.bank_billet_batch_id)" outline slot="reference" size="sm" type="secundary"
                                     class="p-0 m-0 shadow-none text-indigo">
                          <img class="mr-2" src="/img/icons/barcode.png" width="26">
                        </base-button>
                      </el-popover>
                      <el-popover trigger="hover" placement="bottom" class="p-0 pr-0">
                        <span> {{ item.bank_account_name }} </span>
                        <base-button outline slot="reference" size="sm" type="secundary"
                                     class="p-0 m-0 shadow-none text-indigo">
                          <img src="/img/icons/merchant-account.png" width="26">
                        </base-button>
                      </el-popover>
                      <img v-show="item.exists_attachments"
                        class="pointer ml-1 p-0"
                        @click.prevent="handleShowEntityAttachments(item.bill_receive_id, index)"
                        src="/img/icons/paperclip-green.png" width="26">
                      <el-popover v-if="item.observations" trigger="hover" placement="right" class="p-0 pr-0">
                        <div style="max-width: 350px;">
                          <h3 class="text-uppercase new-default-black-font" style="display: flex;align-items: center;">
                            <img class="mr-2" src="/img/icons/icons8/ios/light-on.png" width="26">
                            Observações
                          </h3>
                          <div class="new-default-black-font" style="word-break: break-word;max-width: 350px;font-size: 12px;">
                            {{ item.observations }}
                          </div>
                        </div>
                        <base-button outline slot="reference" size="sm" type="secundary"
                                     class="ml-1 p-0 mr-0 my-0 shadow-none text-indigo">
                          <img src="/img/icons/error-red.png" width="26">
                        </base-button>
                      </el-popover>
                    </div>
                  </div>
                  <div class="row mx-1 my-2">
                    <div class="col-6 p-0">
                      <div class="calc-bar-text" v-if="getPaidAmount(item).value > 0">
                        {{ getPaidAmount(item).value | currency() }} RECEBIDO
                      </div>
                    </div>
                    <div class="col-6 p-0 text-right">
                      <div class="calc-bar-text" v-if="item.installment_statistics.canceled_value > 0">
                        CANCELADO
                      </div>
                      <div class="calc-bar-text" v-if="getOpenValuesAmount(item).value > 0">
                        {{ getOpenValuesAmount(item).value | currency() }} EM ABERTO
                      </div>
                    </div>
                  </div>
                  <div class="row mx-1">
                    <div :style="{ 'width': getPaidAmount(item).percent }">
                      <hr :style="{ 'border-top-right-radius': getOpenValuesAmount(item).border, 'border-bottom-right-radius': getOpenValuesAmount(item).border }" class="green-divider">
                    </div>
                    <div :style="{ 'width': getOpenValuesAmount(item).percent }">
                      <hr :style="{ 'border-top-left-radius': getPaidAmount(item).border, 'border-bottom-left-radius': getPaidAmount(item).border }" class="red-divider">
                    </div>
                    <div style="width: 100%;" class="calc-bar-text" v-if="item.installment_statistics.canceled_value > 0">
                      <hr class="red-divider" style="border-radius: 10px;">
                    </div>
                  </div>
                  <div :class="'parcelas-' + index" class="hide mt-4 mb-1">
                    <div class="container px-0">
                      <h4 class="px-1 new-default-gray-font">Parcelas</h4>
                    </div>
                    <div class="container px-0">
                      <div style="border-radius: 15px !important;" class="px-4 py-3 mb-1 card-with-box-shadow"
                        v-for="(installment,
                          indexInstallment) in item.installments"
                          :key="indexInstallment">
                        <div class="row">
                          <div class="col-6">
                            <div class="row">
                              <div class="col-12">
                                <div class="no-break-md">
                                  <h4 style="display: inline-flex;" class="new-default-black-font">Parcela {{ installment.index }}</h4>
                                  <!--  CASO ESTEJA RECEBIDO E NÃO ESTEJA APROVADO, ENVIA O TYPE 1 PARA VERIFICAR PERMISSÃO reverse -->
                                  <div style="display: inline;" v-if="installment.status !== 8 && installment.status === 1">
                                    <base-dropdown v-if="!checkIfDisable(installment, 1)">
                                      <div class="ml-sm-3 mb-2 mb-md-0"
                                        slot="title-container"
                                        style="display: inline-flex;align-items: center;"
                                        :class="getColor(installment.status)">
                                        <h4 class="font-weight-normal m-0 p-0 mr-2">
                                          {{ getTitle(installment.status) }}
                                        </h4>
                                        <img :src="getDropdownColor(installment.status)" width="12" alt="">
                                      </div>
                                      <div>
                                        <a @click.prevent="changeEntityInstallmentStatus(installment, 10, index, indexInstallment)"
                                          class="dropdown-item">
                                          Estornar
                                        </a>
                                      </div>
                                     </base-dropdown>
                                     <!--  SE NÃO TIVER PERMISSÃO, MOSTRA SOMENTE TEXTO COM SOMBRA -->
                                     <div v-else
                                      class="ml-sm-3 mb-2 mb-md-0"
                                      style="display: inline-flex;align-items: center;"
                                      :class="getColor(installment.status)">
                                      <h4 class="font-weight-normal m-0 p-0 mr-2">
                                        {{ getTitle(installment.status) }}
                                      </h4>
                                    </div>
                                  </div>
                                  <!--  CASO NÃO ESTEJA RECEBIDO E NEM APROVADO, ENVIA O TYPE 2 PARA VERIFICAR PERMISSÃO cancel e receive -->
                                  <div style="display: inline;" v-if="installment.status !== 8 && installment.status !== 1">
                                    <base-dropdown v-if="!checkIfDisable(installment, 2)">
                                      <div
                                        slot="title-container"
                                        class="ml-sm-3 mb-2 mb-md-0"
                                        style="display: inline-flex;align-items: center;"
                                        :class="getColor(installment.status)">
                                        <h4 class="font-weight-normal m-0 p-0 mr-2">
                                          {{ getTitle(installment.status) }}
                                        </h4>
                                        <img :src="getDropdownColor(installment.status)" width="12">
                                      </div>
                                      <div>
                                        <a
                                          @click.prevent="changeEntityInstallmentStatus(installment, 1, index, indexInstallment)"
                                          v-if="installment.status !== 1 && $hasPermission('bill_receive.receive_installment')"
                                          class="dropdown-item">
                                          Receber</a>
                                        <a @click.prevent="changeEntityInstallmentStatus(installment, 8, index, indexInstallment)"
                                          v-if="installment.status !== 1 && $hasPermission('bill_receive.approve_installment')"
                                          class="dropdown-item">
                                          Aprovar</a>
                                        <a @click.prevent="
                                          changeEntityInstallmentStatus(installment, 0, index, indexInstallment)"
                                          v-if="installment.status !== 0 && installment.status !== 2 && $hasPermission('bill_receive.reverse_installment')"
                                          class="dropdown-item">Pendente</a>
                                        <a @click.prevent="changeEntityInstallmentStatus(installment, 3, index, indexInstallment)"
                                          v-if="installment.status !== 3 && $hasPermission('bill_receive.cancel_installment')"
                                          class="dropdown-item">
                                          Cancelar
                                        </a>
                                      </div>
                                    </base-dropdown>
                                    <!--  SE NÃO TIVER PERMISSÃO, MOSTRA SOMENTE TEXTO COM SOMBRA -->
                                    <div v-else
                                      class="ml-sm-3 mb-2 mb-md-0"
                                      style="display: inline-flex;align-items: center;"
                                      :class="getColor(installment.status)">
                                      <h4 class="font-weight-normal m-0 p-0 mr-2">
                                        {{ getTitle(installment.status) }}
                                      </h4>
                                    </div>
                                  </div>
                                  <!-- CASO ESTEJA APROVADO -->
                                  <div style="display: inline;" v-if="installment.status === 8">
                                    {{  }}
                                    <!--  VERIFICA SOMENTE PERMISSÃO cancel e receive -->
                                    <base-dropdown v-if="loadingInstallmentStatus || ($hasPermission('bill_receive.cancel_installment') && $hasPermission('bill_receive.receive_installment'))">
                                      <div class="ml-sm-3 mb-2 mb-md-0"
                                        slot="title-container"
                                        style="display: inline-flex;align-items: center;"
                                        :class="getColor(installment.status)">
                                        <h4 class="font-weight-normal m-0 p-0 mr-2">
                                          {{ getTitle(installment.status) }}
                                        </h4>
                                        <img :src="getDropdownColor(installment.status)" width="12">
                                      </div>
                                      <div>
                                        <a @click.prevent=" changeEntityInstallmentStatus(installment, 1, index, indexInstallment)"
                                          v-if="installment.status !== 1 && $hasPermission('bill_receive.receive_installment')"
                                          class="dropdown-item">
                                          Receber
                                        </a>
                                        <a @click.prevent=" changeEntityInstallmentStatus(installment, 3, index, indexInstallment)"
                                          v-if="installment.status !== 3 && $hasPermission('bill_receive.cancel_installment')"
                                          class="dropdown-item">
                                          Cancelar
                                        </a>
                                      </div>
                                    </base-dropdown>
                                    <!--  SE NÃO TIVER PERMISSÃO, MOSTRA SOMENTE TEXTO COM SOMBRA -->
                                    <div v-else class="ml-sm-3 mb-2 mb-md-0"
                                      style="display: inline-flex;align-items: center;"
                                      :class="getColor(installment.status)">
                                      <h4 class="font-weight-normal m-0 p-0 mr-2">
                                        {{ getTitle(installment.status) }}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-12">
                                <img class="pointer" src="/img/icons/order-history.png"
                                  @click="handleShowHistory({installment_id: installment.id, type: 0})"
                                  width="25" alt="">
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="row" style="float: right;display: block;">
                              <div class="col-12">
                                <div style="display: inline-block;">
                                  <h5 style="display: inline;font-size: 11px" class="mr-1 my-0 font-weight-normal new-default-gray-font">
                                    Valor Total
                                  </h5>
                                  <el-popover v-if="installment.total_value != installment.value" trigger="click" placement="left">
                                    <div class="row" style="max-width: 250px;">
                                      <div class="col-12 mb-2" style="display: flex; align-items: center;">
                                        <img src="/img/icons/icons8/ios/info-squared.png" class="mr-2" width="20"> <b class="new-default-black-font">Info Parcela {{ installment.index }}</b>
                                      </div>
                                      <div class="col-6">
                                        <span class="h5 font-weight-normal">
                                          Valor total:
                                        </span>
                                      </div>
                                      <div class="col-6">
                                        <span class="h5 font-weight-normal">
                                          {{ installment.value | currency() }}
                                        </span>
                                      </div>
                                      <div class="col-6">
                                        <span class="h5 font-weight-normal">
                                          Descontos:
                                        </span>
                                      </div>
                                     <div class="col-6">
                                        <span v-if="installment.discounts === 'N/A' || !installment.discounts" class="h5 font-weight-normal">
                                            N/A
                                        </span>
                                        <span v-else class="h5 font-weight-normal text-danger">
                                          {{ parseFloat(installment.discounts) | currency() }}
                                        </span>
                                      </div>
                                      <div class="col-6">
                                        <span class="h5 font-weight-normal">
                                          Taxas:
                                        </span>
                                      </div>
                                      <div class="col-6 ">
                                        <span v-if="installment.discounts_fees === 'N/A' || !installment.discounts_fees" class="h5 font-weight-normal">
                                            N/A
                                        </span>
                                        <span v-else class="h5 font-weight-normal">
                                            {{ parseFloat(installment.discounts_fees) | currency() }}
                                          </b>
                                        </span>
                                      </div>
                                      <div class="col-6">
                                        <span class="h5 font-weight-normal">
                                          Juros/multa:
                                        </span>
                                      </div>
                                      <div class="col-6">
                                        <span v-if="installment.fees_fines === 'N/A' || !installment.fees_fines" class="h5 font-weight-normal">
                                          N/A
                                        </span>
                                        <span v-else class="h5 font-weight-normal">
                                          {{ parseFloat(installment.fees_fines) | currency() }}
                                        </span>
                                      </div>
                                      <div class="col-12">
                                        <hr class="mb-2 mt-2">
                                      </div>
                                      <div class="col-6">
                                        <span class="h5 font-weight-normal">
                                          Valor final:
                                        </span>
                                      </div>
                                      <div class="col-6 ">
                                        <span class="h5 font-weight-normal">
                                          <b>{{ installment.total_value | currency() }}</b>
                                        </span>
                                      </div>
                                    </div>
                                    <img v-if="installment.fees_fines > 0 || installment.discounts_fees > 0"
                                      slot="reference" style="display: inline;" src="/img/icons/discount_success.png" class="pointer" width="20" alt="">
                                    <img v-else
                                      slot="reference" style="display: inline;" src="/img/icons/discount--v1_danger.png" class="pointer" width="20" alt="">
                                  </el-popover>
                                </div>
                              </div>
                              <div class="col-12">
                                <h2 class="my-0 mt-n1" style="font-size: 18px;">{{ installment.value | currency() }}</h2>
                              </div>
                              <div class="col-12">
                                <h5 style="font-size: 11px" class="my-0 mt-n1 font-weight-normal new-default-gray-font">{{ installment.due_date | parseDate('DD MMM YYYY') }}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3 align-items-center">
                  <div class="col-6">
                    <el-popover v-if="item.nsu_code"
                      trigger="click" placement="right" class="">
                      <span class="text-uppercase new-default-black-font">Código: {{ item.nsu_code }}</span>
                      <base-button outline slot="reference" size="sm" type="secundary"
                                   class="ml-3 mt-n5 shadow-none text-indigo">
                        <img src="/img/icons/break-v2.png" width="30">
                      </base-button>
                    </el-popover>
                  </div>
                  <div class="col-6 text-center" style="text-align: right !important;padding: 0px">
                    <div class="card-blue-bottom text-center dropdown-toggle" @click.prevent="showParcelas(index)">
                      <a style="height: 40px; display: inline-block;"
                        href="#">
                        <h3 style="text-transform: none;padding: 8px;padding-right: 5px;">
                          <span class="mr-1">
                            <img src="/img/icons/icons8/ios/how-many-quest--v2.png" width="22px">
                          </span>
                          <small :id="'parcelas-toggle-' + index" class="mr-1 text-white" style="font-size: 14px">
                            PARCELAS
                          </small>
                        </h3>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row card-wrapper" v-show="loadingSkeleton">
            <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
          </div>
          <puzl-empty-data v-if="!bill_receives.length && !loadingSkeleton"/>
        </div>
      </card>
    </div>
    <ModalHistory ref="ModalHistory"/>
    <ModalPixQRcode ref="modalPixQrcode" @updated="loadingSkeleton = true;getBillReceives($parent.filter)"/>
    <ModalAlterStatus ref="ModalAlterStatus"
                      :show="showModalAlterStatus"
                      @close="closeModalAlterStatus"
                      @saveAndClose="saveAndCloseAlterInstallment"
    />
    <modal-generate-billet @fetch="fetch" ref="modalGenerateBillet"/>
    <modal-generate-billet-has-generated ref="modalGenerateBilletHasGenerated"/>
    <EditPayment @showInvoices="getInvoices" ref="editPayment"/>
    <ModalAttachment ref="modalAttachment"/>
    <ModalAddInvoice ref="modalAddInvoice"/>
    <modal-change-due-date @updated="updateInstallmentAfterChangeDueDate" ref="ModalChangeDueDate"/>
    <modal-edit-entity @updated="loadingSkeleton = true;getBillReceives($parent.filter)" ref="modalEditEntity"/>
    <modal-confirm-payment
      @updateInstallment="updateInstallment"
      ref="modalConfirmPayment"
    />
    <modal-share-payment
      @fetch="fetch"
      ref="modalSharePayment"
    />
    <ModalEntityBillReceiveIntegrationInfo ref="modalEntityBillReceiveIntegrationInfo" />
  </div>
</template>

<script>
import ModalAlterStatus from '../../../../Financial/BillReceive/Shared/Modals/_ModalAlterStatus'
import {mapGetters} from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import EditPayment from "./_Edit";
import ModalAttachment from "@/views/Modules/Financial/BillReceive/Shared/Modals/_ModalAttachment";
import ModalGenerateBillet from '../../../../Financial/BillReceive/Shared/Modals/_ModalGenerateBillet'
import ModalGenerateBilletHasGenerated from '../../../../Financial/BillReceive/Shared/Modals/_ModalGenerateBilletHasGenerated'
import ModalHistory from "../../../../Financial/BillReceive/Shared/Modals/_ModalHistory";
import ModalPixQRcode from './_ModalPixQRcode.vue';
import ModalAddInvoice from './_ModalAddInvoice'
import PuzlEmptyData from "../../../../../../components/PuzlEmptyData";
import ModalConfirmPayment from '../../../../Financial/BillReceive/Entity/Shared/Modals/_ModalEntityBillReceiveConfirmPayment';
import ModalSharePayment from './_ModalSharePayment'
import {base_url_ms} from '@/plugins'
import cursorPaginate from "@/mixins/cursorPaginate";
import ModalEditEntity from '../../../../Financial/BillReceive/Entity/Shared/Modals/_ModalEditEntity'
import {formatErrorValidation} from "@/plugins";
import ModalChangeDueDate from '../../../../Financial/BillReceive/Shared/Modals/_ModalChangeDueDate.vue'
import { hasTotvs } from "@/plugins/microservices/totvs";
import ModalEntityBillReceiveIntegrationInfo from "@/views/Modules/Totvs/EntityBillReceive/_ModalEntityBillReceiveIntegrationInfo";
import { TotvsStatusEnum } from "@/enum/TotvsStatusEnum";

export default {
  name: "List",
  mixins: [cursorPaginate],
  components: {
    PuzlEmptyData,
    SkeletonPuzlGrid,
    ModalAlterStatus,
    ModalHistory,
    EditPayment,
    ModalGenerateBillet,
    ModalGenerateBilletHasGenerated,
    ModalAttachment,
    ModalAddInvoice,
    ModalConfirmPayment,
    ModalSharePayment,
    ModalEditEntity,
    ModalChangeDueDate,
    ModalPixQRcode,
    ModalEntityBillReceiveIntegrationInfo,
  },
  data() {
    return {
      type: 1,
      BillReceiveStatus: {
        '0': "Pendente",
        '1': "Receber",
        '3': "Cancelado",
        '4': "Serasa",
        '5': "Cartório",
        '6': "Protestado",
        '7': "Jurídico",
        '8': "Aprovar",
      },
      PaymentInstallmentStatus: {
        '0': "pending",
        '1': "received",
        '2': "overdue",
        '3': "canceled",
        '4': "serasa",
        '5': "registry",
        '6': "protested",
        '7': "legal",
        '8': "approved",
        '9': "denied"
      },
      base_url_ms: base_url_ms(),
      loadingSkeleton: false,
      loadingInstallmentStatus: false,
      showModalAlterStatus: false,
      hasTotvs: hasTotvs(),
      TotvsStatusEnum: TotvsStatusEnum,
    };
  },
  computed: {
    ...mapGetters({
      payments: "contractProposalPayment/fetch",
      bill_receives: "billReceive/getEntities",
      $_commercial_permission: "commercialPermission/show",
    }),
  },
  methods: {
    pixDisabled(billReceive) {
      let count = 0;
      for(let i in billReceive.installments) {
        if(billReceive.installments[i].status == 0 || billReceive.installments[i].status == 2) {
          count++;
        }
      }
      return billReceive.total_installment > 1 || count < 1;
    },
    checkIfDisable(installment, type){
      if(type !== 1){
        return (
          this.loadingInstallmentStatus
          || !this.$hasPermission('bill_receive.reverse_installment')
          || ['canceled', 'approved', 'denied'].includes(this.PaymentInstallmentStatus[installment.status])
        );
      } else {
        return (
          this.loadingInstallmentStatus
          || (!this.$hasPermission('bill_receive.receive_installment') && !this.$hasPermission('bill_receive.cancel_installment'))
          || ['canceled', 'approved', 'denied'].includes(this.PaymentInstallmentStatus[installment.status])
        );
      }
    },
    getPaidAmount(item){
      return {
        value: item.installment_statistics.approved_value + item.installment_statistics.pay_value,
        percent: (item.installment_statistics.approved_value + item.installment_statistics.pay_value) / (item.total_value/100) + '%',
        border: ((item.installment_statistics.approved_value + item.installment_statistics.pay_value) / (item.total_value/100)) == 0 ? '10px' : '0px'
      };
    },
    getOpenValuesAmount(item){
      return {
        value: item.installment_statistics.pending_value + item.installment_statistics.late_value,
        percent: (item.installment_statistics.pending_value + item.installment_statistics.late_value) / (item.total_value/100) + '%',
        border: ((item.installment_statistics.pending_value + item.installment_statistics.late_value) / (item.total_value/100)) == 0 ? '10px' : '0px'
      };
    },
    updateInstallmentAfterChangeDueDate(params) {
      this.payments[params.payment].installments[params.installment].due_date = params.due_date
    },
    async handleShowAttachments(bill_receive_id, index) {
      let loader = this.$loading.show()
      await this.getAttachments(bill_receive_id, 1, index)
      const item = this.payments[index]
      loader.hide()
      this.$refs.modalAttachment.openAttachment(item.attachments)
    },
    async handleShowEntityAttachments(bill_receive_id, index) {
      let loader = this.$loading.show()
      await this.getEntityAttachments(bill_receive_id, 0, index)
      const item = this.bill_receives[index]
      loader.hide()
      this.$refs.modalAttachment.openAttachment(item.attachments)
    },
    async getAttachments(id, type, index) {
      if (!this.payments[index].attachments) {
        this.payments[index].attachments = 1;
        await this.$store
          .dispatch("billReceive/getAttachments", {
            id: id,
            type: type
          })
          .then(response => {
            this.payments[index].attachments = response.data;
            this.$forceUpdate();
          });
      }
    },
    async getEntityAttachments(id, type, index) {
      if (!this.bill_receives[index].attachments) {
        this.bill_receives[index].attachments = 1;
        await this.$store
          .dispatch("billReceive/getAttachments", {
            id: id,
            type: type
          })
          .then(response => {
            this.bill_receives[index].attachments = response.data;
            this.$forceUpdate();
          });
      }
    },
    handleEditEntity(id) {
      if (! this.$_commercial_permission.is_receipt_editing_allowed) {
        this.$notify({
          type: 'danger',
          message: 'Usuário não tem permissão para editar o recebimento.'
        });
        return;
      }

      const bill_receive = this.bill_receives.find((item) => item.id === id)

      // O usuário não pode editar um registro com status "Aprovado"
      // se não tiver permissão para aprovar antecipações.
      const billReceiveStatusApproved = 8;
      const hasBillReceiveStatusApproved = bill_receive.installments.some(installment => installment.status == billReceiveStatusApproved);
      const userCannotEdit = hasBillReceiveStatusApproved && !this.$hasPermission('bill_receive.approve_installment');
      if (userCannotEdit) {
        this.$notify({
          type: 'danger',
          message: 'Usuário não possui permissão para EDITAR esse registro.'
        });
        return;
      }

      this.$refs.modalEditEntity.handleCreateModal({
        id: bill_receive.entity_id,
        entity_name: bill_receive.entity_name,
        document: bill_receive.document
      }, bill_receive.bill_receive_id)
    },
    handleModalPix(id) {
      const bill_receive = this.bill_receives.find((item) => item.id === id)
      this.$refs.modalPixQrcode.openModal(bill_receive);
    },
    /**
     * Aba antecipação
     * @param {object} filter
     */
    getBillReceives(filter = null) {
      this.startCursor(filter)
      this.filter.type = 0
      this.filter.entity_id = this.$parent.contract_proposal.entity.id
      this.$store.dispatch('billReceive/getEntities',
        {
          filter: this.filter,
          next_page: this.paginate.nextUrl
        }).then(response => {
        this.resolveCursor(response)
      }).catch(error => {
        this.resolveCursor()
      })
    },
    handleDeleteEntity(id, index) {
      this.$Swal
        .confirmDelete()
        .then(result => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação."
            });
            this.$store
              .dispatch("billReceive/destroyEntity", id)
              .then(response => {
                this.$Progress.finish();
                this.bill_receives.splice(index, 1)
                this.$notify({
                  type: response.error_type,
                  message: response.message
                });
              })
              .catch(error => {
                if (error.status == 200) {
                  this.$notify({
                    type: "danger",
                    message: error.data.message
                  });
                }
                this.$Progress.finish();
              });
          }
        })
        .catch(() => this.$Progress.finish());
    },
    async handlePayOffPayment(payment) {
      let loader = this.$loading.show()
      const entity = await this.$store.dispatch('entity/show', this.$parent.contract_proposal.entity.uuid)
        .then(response => response.data)
      loader.hide()
      let use_parcial = null
      let sum_pending = payment.installments.filter((item) => item.status === 0 || item.status === 2)
      sum_pending = sum_pending.reduce((accumulator, currentValue) => {
        return Number(accumulator) + Number(currentValue.total_value);
      }, 0);
      if (sum_pending > Number(entity.anticipation_balance)) {
        use_parcial = 1
      }
      let has_balance_to_paid = payment.installments
        .filter((item) => [0, 2].includes(item.status) && Number(item.total_value) <= Number(entity.anticipation_balance))

      if (!has_balance_to_paid) {
        this.$notify({
          type: "danger",
          message: "Não há saldo de antecipação suficiente para recebimento das parcelas."
        });
      }
      if (has_balance_to_paid && !use_parcial) {
        use_parcial = 0
      }
      let text = undefined
      if (use_parcial) {
        text = 'Não há saldo total para recebimento de todas as parcelas, deseja continuar? o recebimento será feito parcialmente'
      } else {
        text = 'Todas as parcelas pendentes serão recebidas, deseja continuar?'
      }
      const index = this.payments.findIndex((item) => item.id === payment.id)
      this.$Swal.confirmAction(`${text}`, ['Sim', 'Não'])
        .then(async (result) => {
          if (result.isConfirmed) {
            let loader = this.$loading.show()
            this.$store.dispatch('contractProposalPayment/postPayOffPayment', payment.id).then(response => {
              this.payments[index] = response.data
              this.$parent.getContractProposal()
              loader.hide()
            }).catch(error => {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              });
              loader.hide()
            })
          }
        })
    },
    validFinancialLimit(){
      return ((this.$user.financial_limit && this.$user.financial_limit.disable_max_extension_due_date && this.$user.financial_limit.general_extension_due_date > 0) || !this.$user.financial_limit || !this.$user.financial_limit.disable_max_extension_due_date)
    },
    load(filter) {
      if (this.type === 1) {
        this.fetch(filter)
      } else {
        this.getBillReceives(filter)
      }
    },
    showParcelas(index){
      this.$el.querySelector('.parcelas-' + index).classList.toggle('hide');

      if(this.$el.querySelector('.parcelas-' + index).classList.contains('hide')){
        this.$el.querySelector('#parcelas-toggle-' +  index).innerHTML = "PARCELAS";
      } else {
        this.$el.querySelector('#parcelas-toggle-' +  index).innerHTML = "OCULTAR";
      }
    },
    /**
     * Aba recebimentos
     * @param {object} filter
     */
    fetch(filter = null) {
      this.$Progress.start();
      this.loadingSkeleton = true;
      this.$store
        .dispatch(
          "contractProposalPayment/getByContractProposal",
          {
            uuid: this.$route.params.contract_proposal_uuid,
            filter: filter
          }
        )
        .then(response => {
          this.$Progress.finish();
          this.loadingSkeleton = false;
        })
        .catch(error => {
          this.$Progress.finish();
          this.loadingSkeleton = false;
        });
    },
    paidText(payment) {
      if (payment.installment_statistics.late_value > 0) {
        return {
          text: 'Atrasado',
          type: 'mini-card-red',
          img: '/img/icons/hourglass-white.png'
        }
      }
      if (payment.installment_statistics.pending_value > 0) {
        return {
          text: 'Pendente',
          type: 'mini-card-yellow',
          img: '/img/icons/hourglass-white.png'
        }
      }
      if (payment.installment_statistics.canceled_value > 0) {
        return {
          text: 'Cancelado',
          type: 'mini-card-red',
          img: '/img/icons/cancel-white.png'
        }
      }
      if (payment.installment_statistics.approved_value == payment.total_value) {
        return {
          text: 'Aprovado',
          type: 'mini-card-blue',
          img: '/img/icons/thumb-up--v1-white.png'
        }
      }
      if (payment.installment_statistics.denied_value == payment.total_value) {
        return {
          text: 'Negado',
          type: 'mini-card-red',
          img: '/img/icons/icons8/ios/thumbs-down.png'
        }
      }
      return {
        text: 'Recebido',
        type: 'mini-card-green',
        img: '/img/icons/icons8/ios/ok--v1.png'
      }
    },
    async showModalShareInstallment(uuid, installment_id) {
      await this.$refs.editPayment.handleCreateModal(uuid, installment_id);
      const installment = this.$refs.editPayment.payload.installments.find((item) => item.id === installment_id)
      this.$refs.editPayment.showModalShareInstallment(installment)
    },
    async showModalEntityPayment(uuid, installment_id) {
      await this.$refs.editPayment.handleCreateModal(uuid, installment_id);
      const installment = this.$refs.editPayment.payload.installments.find((item) => item.id === installment_id)
      this.$refs.editPayment.showModalEntityPayment(installment)
    },
    closeModalAlterStatus() {
      this.loadingInstallmentStatus = false
      this.showModalAlterStatus = false
    },
    handleSharePayment(uuid) {
      this.$refs.modalSharePayment.openModal(uuid)
    },
    async handleClickApprove(uuid, status, indexPayment) {
      const text = status === 9 ? 'Negar' : 'Aprovar'
      this.$Swal.confirmAction(`Deseja realmente ${text} o pagamento?`, ['Sim', 'Não'])
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação."
            });
            const payment = await this.$store.dispatch('contractProposalPayment/show', uuid).then(response => {
              return response.data
            })
            const installment = payment.installments.find((item) => [0, 2].includes(item.status))
            if (!installment) {
              return this.$notify({
                type: "warning",
                message: "Não há parcelas pendentes."
              });
            }
            this.loadingInstallmentStatus = true
            this.$Progress.start()
            this.$store
              .dispatch("billReceive/changeInstallmentStatus", {
                id: installment.id,
                status: status,
                type: 1
              })
              .then(response => {
                installment.status = status;
                this.loadingInstallmentStatus = false;
                this.$Progress.finish();
                this.$notify({
                  type: response.error_type,
                  message: response.message
                });
                this.payments[indexPayment] = response.data
              })
              .catch(error => {
                this.$notify({
                  type: error.data.error_type,
                  message: error.data.message
                });
                this.loadingInstallmentStatus = false;
                this.$Progress.finish();
              });
          }
        }).catch(() => this.$Progress.finish())
    },
    async handleClickApproveEntity(id, status, indexEntity) {
      const text = status === 9 ? 'Negar' : 'Aprovar'
      this.$Swal.confirmAction(`Deseja realmente ${text} o pagamento?`, ['Sim', 'Não'])
        .then((result) => {
          if (result.isConfirmed) {
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação."
            });
            let loader = this.$loading.show()
            this.$store.dispatch("billReceive/show", id).then(response => {
              const installment = response.data.installments.find((item) => [0, 2].includes(item.status))
              if (!installment) {
                loader.hide()
                return this.$notify({
                  type: "warning",
                  message: "Não há parcelas pendentes."
                });
              }
              this.loadingInstallmentStatus = true
              this.$Progress.start()
              this.$store
                .dispatch("billReceive/changeInstallmentStatus", {
                  id: installment.id,
                  status: status,
                  type: 0
                })
                .then(response => {
                  installment.status = status;
                  this.loadingInstallmentStatus = false;
                  this.$Progress.finish();
                  this.$notify({
                    type: response.error_type,
                    message: response.message
                  });
                  loader.hide()
                  this.bill_receives[indexEntity] = response.data
                })
                .catch(error => {
                  this.$notify({
                    type: error.data.error_type,
                    message: error.data.message
                  });
                  loader.hide()
                  this.loadingInstallmentStatus = false;
                  this.$Progress.finish();
                });
            })
          }
        })
    },
    updateInstallment(params) {
      if (this.type === 1) {
        let bill_receive_id = this.payments[params.index].bill_receive_id
        this.payments[params.index] = params.data
        this.payments[params.index].bill_receive_id = bill_receive_id
      } else {
        let bill_receive_id = this.bill_receives[params.index].bill_receive_id
        this.bill_receives[params.index] = params.data.entity
        this.bill_receives[params.index].bill_receive_id = bill_receive_id
      }
      this.$forceUpdate()
    },
    handleShowHistory(payment) {
      this.$refs.ModalHistory.openModal(payment)
    },
    saveAndCloseAlterInstallment(params) {
      switch (params.type) {
        case 0:
          params.response.competence = this.bill_receives[params.indexPayment].competence;
          this.bill_receives[params.indexPayment] = params.response
          this.loadingInstallmentStatus = false
          this.showModalAlterStatus = false
          break;
        case 1:
          params.response.invoices = this.payments[params.indexPayment].invoices ?? [];
          this.payments[params.indexPayment] = params.response
          this.loadingInstallmentStatus = false
          this.payments[params.indexPayment].installments[params.index].status = params.status;
          this.showModalAlterStatus = false
          if ([0, 2, 1, 8].includes(parseInt(params.status))) {
            this.$parent.getContractProposal()
          }
          if (params.status === 3) {
            this.$parent.contract_proposal.pending_launch_value += parseFloat(params.response.total_value);
          }
          break;
      }
    },
    downloadBilletBatch(bank_billet_batch_id) {
      if (!bank_billet_batch_id) {
        this.$notify({
          type: 'warning',
          message: 'O lote ainda não foi gerado. Favor entre em contato com o suporte.'
        });
      }
      this.$Progress.start()
      let loader = this.$loading.show()
      this.$store.dispatch('bankBillet/getPdfFileUrl', bank_billet_batch_id)
        .then(async (response) => {
          let blob = new Blob([response], {type: "application/pdf"});
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", `Lote ${bank_billet_batch_id}.pdf`);
          await link.click();
          this.$snotify.success('Download iniciado com sucesso!', {
            timeout: 1000,
            icon: false,
            position: "centerBottom",
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false
          });
          loader.hide()
        });
    },
    nfseButtonClick(link) {
      this.$notify({type: 'success', message: 'Download iniciado com sucesso!'});
      window.open(link, '_parent');
    },
    showPayOff(payment) {
      let sum_pending = payment.installment_statistics.pending_value
      sum_pending += payment.installment_statistics.late_value
      let has_balance_to_paid = payment.installments.filter((item) => [0, 2].includes(item.status) && Number(item.total_value) <= Number(this.$parent.contract_proposal.entity.anticipation_balance))
      if (sum_pending > 0 && has_balance_to_paid.length > 0) {
        return true
      } else {
        return false
      }
    },
    showAllowApprove(payment) {
      if (this.$parent.contract_proposal
        && this.$parent.contract_proposal.entity.anticipation_balance >= payment.total_value) {
        return false
      }
      if (!payment.payment_method.allow_approve) {
        return false
      }
      if (payment.installment_statistics.approved_value > 0 ||
        payment.installment_statistics.denied_value > 0 || payment.installment_statistics.pay_value) {
        return false
      }
      if (this.$hasPermission('additional_permissions.financial_approvation') &&
        (payment.installment_statistics.pending_value > 0 || payment.installment_statistics.late_value)
      ) {
        return true
      }
      return false
    },
    showAllowApproveEntity(entity) {
      if (!entity.allow_approve) {
        return false
      }
      if (entity.installment_statistics.approved_value > 0 ||
        entity.installment_statistics.denied_value > 0 || entity.installment_statistics.pay_value) {
        return false
      }
      if (this.$hasPermission('bill_receive.approve_installment') &&
        (entity.installment_statistics.pending_value > 0 || entity.installment_statistics.late_value)
      ) {
        return true
      }
      return false
    },
    async getDetails(id, type, index) {
      if (!this.bill_receives[index].details) {
        this.bill_receives[index].details = 1;
        await this.$store
          .dispatch("billReceive/getDetails", {
            id: id,
            type: type
          })
          .then(response => {
            this.bill_receives[index].details = response.data;
            this.$forceUpdate();
          });
      }
    },
    getInvoices(payment) {
      payment.invoices = 1
      this.$forceUpdate()
      this.$store
        .dispatch("contractProposalPayment/getInvoices", payment.id)
        .then(response => {
          this.$forceUpdate()
          payment.invoices = response.data
        });
    },
    async handleOpenAddInvoice(uuid) {
      let loader = this.$loading.show()
      const payment = await this.$store.dispatch('contractProposalPayment/show', uuid).then(response => {
        return response.data
      })
      this.$store.dispatch("billingInvoice/init", {
        filter: {
          pending_payment: 1,
          bill_receive_id: payment.billable_id,
          contract_proposal_id: payment.contract_proposal_id,
          company_plant_id: payment.company_plant_id,
          company_plant_issuer_id: payment.company_plant_issuer_id,
          not_canceled: true,
        }
      }).then(response => {
        const all_invoices = response.data
        let invoices_paid = payment.invoices_paid
        let total_value = 0;
        const invoices = payment.invoices
        invoices.map(function (id) {
          let invoice = all_invoices.find(function (item) {
            return item.id === id;
          });
          const paid_for_this_payment = invoices_paid[invoice.id] || 0
          total_value += Number(invoice.pending_billing) + Number(paid_for_this_payment);
        });
        let invoiced_amount = total_value.toFixed(2);
        let payment_total_value = payment.total_value
          .replace('.', '').replace(',', '')
        let balance = parseFloat(payment_total_value)
          - parseFloat(invoiced_amount.replace('.', '')
            .replace(',', ''))
        if (balance <= 0) {
          loader.hide()
          return this.$notify({
            type: "warning",
            message: "Não há saldo disponível para inserção de novas faturas."
          });
        }
        loader.hide()
        this.$refs.modalAddInvoice.openModal({
          balance: balance,
          initial_balance: balance,
          uuid: uuid,
          bill_receive_id: payment.billable_id,
          invoiced_amount: invoiced_amount,
          contract_proposal_id: payment.contract_proposal_id,
          company_plant_id: payment.company_plant_id,
          company_plant_issuer_id: payment.company_plant_issuer_id,
        })
      })
    },
    changeInstallmentStatus(installment, status, indexPayment, indexInstallment) {
      status = parseInt(status)
      if (installment.billet && installment.billet.status === 1 && status === 1) {
        return this.$Swal.confirmAction('O Boleto já foi emitido para esta parcela, se continuar, o boleto será cancelado.', ['Sim', 'Não'])
          .then((result) => {
            if (result.isConfirmed) {
              let loader = this.$loading.show()
              return Promise.all([
                this.$store.dispatch("billReceive/showLight", this.payments[indexPayment].bill_receive_id),
                this.$store.dispatch("companyPlantBankAccount/getByCompanyPlant", {allocated: 1, company_plant_id: this.payments[indexPayment].company_plant_id})
              ]).then(() => {
                loader.hide()
                let disabled_change_bank_account = this.payments[indexPayment].installment_statistics.pay_value > 0
                return this.$refs.modalConfirmPayment.handleCreateModal(installment.id, indexPayment, installment.total_value, disabled_change_bank_account, 1)
              })
            }
          }).catch()
      }
      if (this.loadingInstallmentStatus) {
        return;
      }
      const payment = this.payments[indexPayment]
      // if (payment.any_has_paid_billet && status != 1) {
      //   this.$notify({
      //     type: "warning",
      //     message: "Não foi possível alterar o status. Há uma parcela com pagamento confirmado para este pagamento"
      //   });
      //   return;
      // }
      if (parseInt(status) === 1) {
        let loader = this.$loading.show()
        return Promise.all([
          this.$store.dispatch("billReceive/showLight", this.payments[indexPayment].bill_receive_id),
          this.$store.dispatch("companyPlantBankAccount/getByCompanyPlant", {allocated: 1, company_plant_id: this.payments[indexPayment].company_plant_id})
        ]).then(() => {
          loader.hide()
          let disabled_change_bank_account = this.payments[indexPayment].installment_statistics.pay_value > 0
          return this.$refs.modalConfirmPayment.handleCreateModal(installment.id, indexPayment, installment.total_value, disabled_change_bank_account)
        })
      }
      this.loadingInstallmentStatus = true;
      this.$Progress.start();
      if (status != 1) {
        this.showModalAlterStatus = true
        return this.$refs.ModalAlterStatus.setBillReceive({
          id: installment.id,
          type: 1,
          status: status,
          indexPayment: indexPayment,
          index: indexInstallment,
          received_at: installment.paid_at ? installment.paid_at : null
        })
      }
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.$store
        .dispatch("billReceive/changeInstallmentStatus", {
          id: installment.id,
          status: status,
          type: 1
        })
        .then(response => {
          installment.status = status;
          this.loadingInstallmentStatus = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.payments[indexPayment] = response.data
        })
        .catch(error => {
          this.$notify({
            type: error.data.error_type,
            message: error.data.message
          });
          this.loadingInstallmentStatus = false;
          this.$Progress.finish();
        });
    },
    changeEntityInstallmentStatus(installment, status, indexPayment, indexInstallment) {
      status = parseInt(status)
      if (installment.billet && installment.billet.bank_billet.status === 1 && status === 1) {
        return this.$Swal.confirmAction('O Boleto já foi emitido para esta parcela, se continuar, o boleto será cancelado.', ['Sim', 'Não'])
          .then((result) => {
            if (result.isConfirmed) {
              let loader = this.$loading.show()
              return Promise.all([
                this.$store.dispatch("billReceive/showLight", this.bill_receives[indexPayment].bill_receive_id),
                this.$store.dispatch("companyPlantBankAccount/getByCompanyPlant", {allocated: 1, company_plant_id: this.bill_receives[indexPayment].company_plant_id})
              ]).then(() => {
                loader.hide()
                let disabled_change_bank_account = this.bill_receives[indexPayment].installment_statistics.pay_value > 0
                return this.$refs.modalConfirmPayment.handleCreateModal(installment.id, indexPayment, installment.total_value, disabled_change_bank_account, 1)
              })
            }
          }).catch()
      }
      if (this.loadingInstallmentStatus) {
        return;
      }
      const bill_receive = this.bill_receives[indexPayment]
      // if (bill_receive.any_has_paid_billet && status != 1) {
      //   this.$notify({
      //     type: "warning",
      //     message: "Não foi possível alterar o status. Há uma parcela com pagamento confirmado para este pagamento"
      //   });
      //   return;
      // }
      if (parseInt(status) === 1) {
        let loader = this.$loading.show()
        return Promise.all([
          this.$store.dispatch("billReceive/showLight", this.bill_receives[indexPayment].bill_receive_id),
          this.$store.dispatch("companyPlantBankAccount/getByCompanyPlant", {allocated: 1, company_plant_id: this.bill_receives[indexPayment].company_plant_id})
        ]).then(() => {
          loader.hide()
          let disabled_change_bank_account = this.bill_receives[indexPayment].installment_statistics.pay_value > 0
          return this.$refs.modalConfirmPayment.handleCreateModal(installment.id, indexPayment, installment.total_value, disabled_change_bank_account)
        })
      }
      this.loadingInstallmentStatus = true;
      this.$Progress.start();
      if (status != 1) {
        this.showModalAlterStatus = true
        return this.$refs.ModalAlterStatus.setBillReceive({
          id: installment.id,
          type: 0,
          status: status,
          indexPayment: indexPayment,
          index: indexInstallment,
          received_at: installment.paid_at ? installment.paid_at : null
        })
      }
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.$store
        .dispatch("billReceive/changeInstallmentStatus", {
          id: installment.id,
          status: status,
          type: 0
        })
        .then(response => {
          installment.status = status;
          this.loadingInstallmentStatus = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.bill_receives[indexPayment] = response.data
        })
        .catch(error => {
          this.$notify({
            type: error.data.error_type,
            message: error.data.message
          });
          this.loadingInstallmentStatus = false;
          this.$Progress.finish();
        });
    },
    getColor(status) {
      switch (status) {
        case 0:
          return "mini-card-yellow";
        case 1:
          return "mini-card-green";
        case 2:
        case 9:
        case 3:
          return "mini-card-red";
        case 8:
          return 'mini-card-blue';
      }
    },
    getDropdownColor(status) {
      switch (status) {
        case 0:
          return "/img/icons/expand-arrow--v2_warning.png";
        case 1:
          return "/img/icons/expand-arrow--v2_success.png";
        case 2:
        case 3:
        case 9:
          return "/img/icons/expand-arrow--v2_danger.png";
        case 8:
          return "/img/icons/expand-arrow--v2_primary.png";
      }
    },
    downloadInvoice(id) {
      this.$Progress.start()
      let loader = this.$loading.show()
      this.$store.dispatch('billingInvoice/download', {
          id: id
        }
      ).then(response => {
        let blob = new Blob([response],
          {type: 'application/pdf'})
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute("download", 'report.pdf');
        window.open(link, '_blank')
        loader.hide()
        this.$Progress.finish()
      }).catch(error => {
        loader.hide()
        this.$Progress.finish()
      })
    },
    downloadAdvanceReceipt(entity_bill_receive_id) {
      this.$Progress.start();
      let loader = this.$loading.show();
      this.$store.dispatch('billingInvoice/downloadAdvanceReceipt', {
          entity_bill_receive_id: entity_bill_receive_id
        }
      ).then(response => {
        let blob = new Blob([response], {type: 'application/pdf'});
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", 'report.pdf');
        window.open(link, '_blank');
      }).finally(() => {
        loader.hide();
        this.$Progress.finish();
      });
    },
    showModalEditGenerateBilletHasGenerated(id, total_index, type, index) {
      let loader = this.$loading.show()
      this.$store.dispatch("billReceive/getInfoBillet", {id: id, type: type}).then(response => {
        const billet = response.data
        this.$store.dispatch("billReceive/show", id).then(response => {
          const bill_receive = response.data
          bill_receive.total_index = total_index
          this.$refs.modalGenerateBilletHasGenerated.openModal(id, billet, bill_receive, index)
          loader.hide()
        });
      });
    },
    showBillet(payment) {
      if (!payment.any_has_billet && !payment.any_has_pending_billet) {
        let loader = this.$loading.show()
        return this.$store.dispatch("billReceive/getInfoBillet", {
          id: payment.bill_receive_id,
          type: 1
        }).then(response => {
          const billet = response.data
          this.$store.dispatch("billReceive/show", payment.bill_receive_id).then(response => {
            const bill_receive = response.data
            this.$refs.modalGenerateBillet.openModal(payment.bill_receive_id, billet, bill_receive)
            loader.hide()
          });
        });
      }
      let loader = this.$loading.show()
      return this.$store.dispatch("billReceive/getInfoBillet", {
        id: payment.bill_receive_id,
        type: 1
      }).then(response => {
        const billet = response.data
        this.$store.dispatch("billReceive/show", payment.bill_receive_id).then(response => {
          const bill_receive = response.data
          this.$refs.modalGenerateBilletHasGenerated.openModal(payment.bill_receive_id, billet, bill_receive)
          loader.hide()
        });
      });
    },
    getTitle(status) {
      switch (status) {
        case 0:
          return "Pendente";
        case 1:
          return "Recebido";
        case 2:
          return "Atrasado";
        case 3:
          return "Cancelado";
        case 8:
          return "Aprovado";
        case 9:
          return "Negado";
      }
    },
    handleAttachment(key) {
      let loader = this.$loading.show()
      this.$store.dispatch('contractProposalPayment/getAttachments', key)
        .then(response => {
          loader.hide()
          this.$refs.modalAttachment.openAttachment(response.data);
        })
        .catch(error => {
          loader.hide()
        })
    },
    handleEditPayment(uuid) {
      this.$refs.editPayment.handleCreateModal(uuid);
    },
    handleDeletePayment(uuid, total_value) {
      this.$Swal
        .confirmDelete()
        .then(result => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação."
            });
            this.$store
              .dispatch("contractProposalPayment/destroy", uuid)
              .then(response => {
                this.$Progress.finish();
                this.$notify({
                  type: response.error_type,
                  message: response.message
                });
                this.$parent.contract_proposal.pending_launch_value += parseFloat(total_value)
              })
              .catch(error => {
                if (error.status == 200) {
                  this.$notify({
                    type: "danger",
                    message: error.data.message
                  });
                }
                this.$Progress.finish();
              });
          }
        })
        .catch(() => this.$Progress.finish());
    },

    handleModalEntityBillReceiveIntegrationInfo(entity_bill_receive_id) {
      this.$refs.modalEntityBillReceiveIntegrationInfo.openModal(entity_bill_receive_id);
    },
  },
  mounted() {
    this.type = 0
    this.$store.dispatch('commercialPermission/getByUserId', this.$user.id);
  },
  beforeUpdate() {
    this.$helper.keepGlobalUser(this);
  }
};
</script>

<style scoped>
.card-blue-bottom {
  border-top-left-radius: 35px;
  border-bottom-right-radius: 10px;
  background-color: #1b6eba;
  color: white;
  max-width: 190px;
  min-width: 190px;
  float: right;
}

.hide {
  display: none;
}

.mini-card {
  border: 1px solid #E8E8E8;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  border-radius: 1rem !important;
}

.mini-card-green {
  background-color: #8bd5af52;
  border-radius: 5px;
  padding: 1px 5px;
  font-size: 12px;
  box-shadow: 0 0 5px 0 #149e5752;
}

.mini-card-red {
  background-color: #f459592e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #d2272752;
}

.mini-card-yellow {
  background-color: #f4dc592e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #fdef0b52;
}

.mini-card-blue {
  background-color: #4182e52e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #0b7bfd52;
}

.mini-card-green h4 {
  color: #149e57;
}

.mini-card-red h4 {
  color: #db4539;
}

.mini-card-yellow h4 {
  color: #f2b532;
}

.mini-card-blue h4 {
  color: #1b6eba;
}

.mini-card-red h4, .mini-card-green h4, .mini-card-yellow h4, .mini-card-blue h4{
  font-size: 12px;
}

.card-with-box-shadow{
  box-shadow: 0 2px 4px 0 #f3f3f3;
  border: 1px solid #E8E8E8;
  box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15);
  border-radius: 10px !important;
}

.new-default-gray-font {
  color: #606062;
}

.green-divider {
  background-color:  #50bd40;
  color: #50bd40;
  margin: 0;
  margin-bottom: 15px;
  padding-top: 3px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.red-divider {
  background-color: #f26257;
  color: #f26257;
  margin: 0;
  margin-bottom: 15px;
  padding-top: 3px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.calc-bar-text {
  color: #606062;
  font-size: 11px;
}

@media (min-width: 1200px) {
  .no-break-md {
    white-space: nowrap;
  }
}
</style>
