<template>
  <div>
    <modal :show.sync="modal" size="lg">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <div>
            <div class="row pl-3 mb-2">
              <div class="col-md-6 ml-4 mb-1">
                <div class="border-left border-3 border-primary ml-n4 pl-2">
                  <h4 class="checklist-title mb-0">
                    <span class="">{{ contract_proposal.code }} <span v-if="contract_proposal.situation_name"
                                                                      style="font-size: 0.8rem"
                                                                      class="small font-weight-normal">| {{
                        contract_proposal.situation_name
                      }} </span></span>
                  </h4>
                  <h4 class="checklist-title mb-0">
                  <span
                    class="">
                    {{
                      contract_proposal && contract_proposal.construction && contract_proposal.construction.customer_construction.customer_name
                    }}
                  </span>
                  </h4>
                  <h4 class="checklist-title mb-0">
                    <span
                      class="">
                    {{
                        contract_proposal && contract_proposal.construction && contract_proposal.construction.construction_name
                      }}
                  </span>
                  </h4>
                  <h5>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </h5>
                </div>
              </div>
              <div class="col-md-5 ml-n1 mb-1">
                <div class="border-left border-3 border-success ml-n4 pl-2">
                  <h4 class="checklist-title mb-0">
                    <span> {{ contract_proposal.entity.entity_name }} </span>
                  </h4>
                  <h4 class="checklist-title mb-0">
                    <span> {{ contract_proposal.entity.document }} </span>
                  </h4>
                </div>
              </div>
              <div v-if="contract_proposal && contract_proposal.construction" style="margin-top: -30px;padding-left: 28px" class="col-md-12 ml-n1 mb-1">
                <h5 class="checklist-title mb-0">
                    <span
                      class="checklist-title">
                    {{
                        contract_proposal.construction.default_address.address
                      }}, {{   contract_proposal.construction.default_address.number }},
                      {{  contract_proposal.construction.default_address.district }} - {{  contract_proposal.construction.default_address.city}} / {{  contract_proposal.construction.default_address.state}}
                  </span>
                </h5>
              </div>
            </div>
            <!--            <div class="form-group row m-0 p-0">-->
            <!--              <label-->
            <!--                class="col-md-6 pb-0 mb-1 col-form-label form-control-label"-->
            <!--              >-->
            <!--                Central de faturamento-->
            <!--                <span class="text-danger">&nbsp;*</span>-->
            <!--              </label>-->
            <!--              <div class="col-md-6">-->
            <!--                <validation-provider rules="required">-->
            <!--                  <base-input>-->
            <!--                    <el-select-->
            <!--                      @change="setCompanyIssuers"-->
            <!--                      v-model="invoice.company_plant_id"-->
            <!--                      size="mini"-->
            <!--                      placeholder="Selecione"-->
            <!--                      filterable-->
            <!--                    >-->
            <!--                      <el-option v-for="plant in plants" :key="plant.id"-->
            <!--                                 :label="plant.name"-->
            <!--                                 :value="plant.id"-->
            <!--                      >-->
            <!--                      </el-option>-->
            <!--                    </el-select>-->
            <!--                  </base-input>-->
            <!--                </validation-provider>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="form-group row m-0 p-0 ">-->
            <!--              <label-->
            <!--                class="col-md-6 pb-0 mb-1 col-form-label form-control-label"-->
            <!--              >-->
            <!--                CNPJ (emissor)-->
            <!--                <span class="text-danger">&nbsp;*</span>-->
            <!--              </label>-->
            <!--              <div class="col-md-6">-->
            <!--                <validation-provider rules="required">-->
            <!--                  <base-input>-->
            <!--                    <el-select-->
            <!--                      :disabled="!plant"-->
            <!--                      v-model="invoice.company_plant_issuer_id"-->
            <!--                      size="mini"-->
            <!--                      placeholder="Selecione"-->
            <!--                      filterable-->
            <!--                    >-->
            <!--                      <template v-if="plant">-->
            <!--                        <el-option-->
            <!--                          v-for="company_issuer in plant.company_issuers"-->
            <!--                          :key="company_issuer.id"-->
            <!--                          :label="company_issuer.business_name"-->
            <!--                          :value="company_issuer.id"-->
            <!--                        >-->
            <!--                        </el-option>-->
            <!--                      </template>-->
            <!--                    </el-select>-->
            <!--                  </base-input>-->
            <!--                </validation-provider>-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="form-group row m-0 p-0  ">
              <label
                class="col-md-6 pt-2 pb-1 col-form-label form-control-label"
              >
                Método de pagamento
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-6">
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm">
                    <el-select
                      @change="getPaymentTerms()"
                      v-model="invoice.payment_method_id"
                      size="mini"
                      placeholder="Selecione"
                      filterable
                      :loading="loadingPaymentMethod"
                      :disabled="loadingPaymentMethod"
                    >
                      <el-option
                        v-for="method in payment_methods_selecteds"
                        :key="method.id"
                        :label="method.name"
                        :value="method.id"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0 ">
              <label
                class="col-md-6 pb-0 mb-1 col-form-label form-control-label"
              >
                Condição de pagamento
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-6">
                <validation-provider rules="required">
                  <base-input>
                    <base-input>
                      <el-select
                        :disabled="!invoice.payment_method_id"
                        v-model="invoice.payment_term_id"
                        size="mini"
                        placeholder="Selecione"
                        filterable
                      >
                        <el-option
                          v-for="payment_term in payment_terms_by_method"
                          :key="payment_term.id"
                          :label="payment_term.name"
                          :value="payment_term.id"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <label
                class="col-md-6 pt-2 pb-1 col-form-label form-control-label"
              >
                Conta bancária de crédito
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-6">
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm">
                    <el-select
                      v-if="showBankAccountId"
                      v-model="invoice.bank_account_id"
                      size="mini"
                      placeholder="Selecione"
                      filterable
                      :disabled="
                          loadingBankAccounts"
                    >
                      <el-option
                        v-for="item in $_bank_accounts"
                        :key="item.id"
                        :label="formatAccountLabel(item)"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0 ">
              <label
                class="col-md-6 pt-2 pb-1 col-form-label form-control-label"
              >
                Mediador de pagamento
                <span class="text-danger" v-show="payment_intermediaries_exists">&nbsp;*&nbsp;</span>
              </label>
              <div class="col-md-6">
                <validation-provider
                  :rules="payment_intermediaries_exists && 'required' || ''">
                  <base-input input-classes="form-control-sm">
                    <PuzlSelect
                      placeholder="Nenhum"
                      :items="payment_intemerdiaries_selected"
                      :disabled="loadingPaymentIntermediaries || !invoice.payment_method_id"
                      v-model="invoice.payment_intermediary_id"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="row m-0 p-0 mb-1" v-show="without_rules">
              <div class="col-md-12 pl-1">
                <h5 class="text-danger">
                  <i class="fa-solid fa-triangle-exclamation"></i>
                  {{ contract_proposal.construction.default_address.city }} /
                  {{ contract_proposal.construction.default_address.state }} (sem regra de iss cadastrada)
                </h5>
              </div>
            </div>
            <div v-for="item in billings" class="mt-2">
              <div class="header-gray card-body-molde">
                <div role="tab" class="card-header p-2 border-header-primary-bottom">
                  <a href="#"
                  ><h5
                    class="mb-0 text-left">{{ item.company_name }} ({{ item.company_plant_issuer_document }})</h5></a>
                </div>
              </div>
              <div class="row pl-2">
                <div class="col-md-12" style="margin-bottom: 10px"/>
                <div class="col-md-6" style="margin-top: -10px">
                  <h3><small>Concreto : <span class="numeric"> {{
                      item.formulation_value | currency()
                    }}</span></small></h3>
                </div>
                <div class="col-md-6" style="margin-top: -10px">
                  <h3 class="text-muted"><small>MCC (Real): <span class="numeric"> {{
                      item.mcc_value | currency()
                    }}</span></small></h3>
                </div>
                <div class="col-md-6" style="margin-top: -10px">
                  <h3><small>Serviços: <span class="numeric"> {{ item.service_value | currency() }}</span></small></h3>
                </div>
                <div class="col-md-6" style="margin-top: -10px">
                  <h3 class="text-muted"><small>MCC (Teórico): <span
                    class="numeric"> {{ item.mcc_theorical_value | currency() }}</span></small></h3>
                </div>
                <div class="col-md-6" style="margin-top: -10px">
                  <h3><small>Adicionais: <span class="numeric"> {{ item.additional_value | currency() }}</span> </small>
                  </h3>
                </div>
                <div class="col-md-6" style="margin-top: -10px">
                  <h3><small>Dedução: <span class="numeric"> {{ item.deduction | currency() }}</span> </small>
                  </h3>
                </div>
                <div class="col-md-6" style="margin-top: -10px">
                  <h3><small>Avulso: <span class="numeric"> {{ item.single_value | currency() }}</span></small></h3>
                </div>
                <div class="col-md-6" style="margin-top: -10px">
                  <h3><small>Base de cálculo: <span class="numeric"> {{ item.base | currency() }}</span></small></h3>
                </div>
                <div class="col-md-6" style="margin-top: -10px">
                  <h3 class="text-danger"><small>Cancelado: <span class="numeric"> {{
                      item.canceled | currency()
                    }}</span></small></h3>
                </div>
                <div class="col-md-6" style="margin-top: -10px">
                  <div class="form-group row  p-0 mb-0">
                    <h3 class="col-6" style="color: darkred">
                      <small>ISS: {{item.iss_retain | currency()}}</small>
                    </h3>
                    <div class="col">
                      <base-switch
                        v-model="item.retained_rate_flag"
                        type="primary"
                      ></base-switch>
                    </div>
                  </div>
                </div>
                <div class="col-md-6" style="margin-top: -10px">
                  <h3 class="text-primary"><small>Total: <span class="numeric"> {{ item.total | currency() }}</span>
                  </small></h3>
                </div>
                <div class="col-md-6" style="margin-top: -10px">
                  <h3 v-if="item.retained_rate_flag" class="text-success"><small>Final: <span class="numeric"> {{ (item.total - item.iss_retain) | currency() }}</span>
                  </small>
                  </h3>
                  <h3 v-else class="text-success"><small>Final: <span class="numeric"> {{ (item.total) | currency() }}</span>
                  </small>
                  </h3>
                </div>
                <div class="col-md-12 pl-3" v-show="item.tax_not_found">
                  <h5 class="text-danger">
                    <i class="fa-solid fa-triangle-exclamation"></i>
                    {{ contract_proposal.construction.default_address.city }} /
                    {{ contract_proposal.construction.default_address.state }} (sem regra de iss cadastrada) para o regime {{item.company_plant_issuer.tax_regime}}
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <base-button type="secondary" @click="closeModal()"
            >Cancelar
            </base-button
            >
            <base-button
              type="success"
              :loading="loadingSave"
              :disabled="invalid || dont_allow_save"
              native-type="submit"
              @click.prevent="save"
            >Gerar fatura
            </base-button>
          </div>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
import {onlyNumbers} from "@/helpers";

export default {
  components: {
    PuzlSelect,
  },
  name: "CreateInvoice",
  data() {
    return {
      modal: false,
      loading: false,
      block_iss: false,
      showBankAccountId: true,
      plant_issuer_selected: null,
      loadingBankAccounts: false,
      loadingPaymentMethod: false,
      loadingPlant: false,
      loadingSave: false,
      payment_intermediaries_exists: false,
      loadingPaymentIntermediaries: false,
      payment_intemerdiaries_selected: [],
      billings: {},
      without_rules: false,
      dont_allow_save: false,
      windowWidth: 0,
      payment_methods_selecteds: [],
      invoice: {
        company_plant_id: '',
        bank_account_id: '',
        payment_method_id: '',
        company_plant_issuer_id: '',
        payment_term_id: '',
        formulation_value: 0,
        mcc_value: 0,
        mcc_theorical_valuez: 0,
        service_value: 0,
        additional_value: 0,
        single_value: 0,
        total: 0,
        canceled: 0,
        contract_proposal_id: null,
        iss_retain: false,
      },
      payment_terms_by_method: [],
      invoice_billeds: {},
      plant: null,
      title: "Gerar fatura",
      hasOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      contract_proposal: "contractProposal/show",
      payment_methods: "paymentMethod/fetch",
      plants: "plant/activeItems",
      payment_terms: "paymentTerm/fetch",
      $_bank_accounts: 'bankAccount/fetch',
      company_plant_issuers: "companyPlantIssuer/fetch",
      $_payment_intermediaries: "paymentIntermediary/fetch"
    })
  },
  mounted() {
    this.init()
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
  methods: {
    /** seta mediadores de pagamento a partir do método */
    setIntermediaresByMethod() {
      this.invoice.payment_intermediary_id = null
      this.payment_intemerdiaries_selected = []
      let payment_method = this.payment_methods.find((item) => item.id === this.invoice.payment_method_id)
      if (!payment_method) {
        return this.payment_intermediaries_exists = false
      }
      this.payment_intermediaries_exists = payment_method.payment_intermediaries_exists
      if (payment_method.payment_intermediaries_exists) {
        this.payment_intemerdiaries_selected = this.$_payment_intermediaries.filter(function (item) {
          if (payment_method.payment_intermediaries.includes(item.id)) {
            return item
          }
        })
      }
      return this.require_transaction_code = Boolean(payment_method.transaction_code)
    },
    getPaymentTerms() {
      this.invoice.payment_term_id = null
      const payment_methods_selected = [this.invoice.payment_method_id]
      const self = this
      this.payment_terms_by_method = this.payment_terms.filter(function (item) {
        for (var payment_method of item.payment_methods) {
          if (payment_methods_selected.includes(payment_method.id)
            && self.contract_proposal.payment_terms_uuids.includes(item.uuid)) {
            return item;
          }
        }
      })
      if (this.contract_proposal.payment_terms_uuids.length === 1) {
        const payment_term_uuid = this.contract_proposal.payment_terms_uuids[0]
        const exists = this.payment_terms_by_method.find((item) => item.uuid = payment_term_uuid)
        if (exists) {
          this.invoice.payment_term_id = exists.id
        }
      }
      const payment_method = this.payment_methods_selecteds.find((item) => item.id === this.invoice.payment_method_id)
      this.payment_intermediaries_exists = payment_method.payment_intermediaries_exists
      if (this.payment_intermediaries_exists && payment_method.payment_intermediaries.length === 1) {
        this.invoice.payment_intermediary_id = payment_method.payment_intermediaries[0]
      }
      this.payment_intemerdiaries_selected = this.$_payment_intermediaries.filter(function (item) {
        if (payment_method.payment_intermediaries.includes(item.id)) {
          return item
        }
      })
    },
    formatAccountLabel(item) {
      return item.name
    },
    init() {
      this.loadingPaymentIntermediaries = true
      this.$store.dispatch("paymentIntermediary/fetchItems").then(() => {
        this.loadingPaymentIntermediaries = false
      }).catch(() => {
        this.loadingPaymentIntermediaries = false
      })
      this.loadingPaymentMethod = true
      this.$store.dispatch("paymentMethod/fetchItems").then(() => {
        this.loadingPaymentMethod = false;
      });
      this.loadingBankAccounts = true
      this.$store.dispatch('bankAccount/fetchItems', {})
        .then(response => {
          this.loadingBankAccounts = false
        })
        .catch(error => {
          this.loadingBankAccounts = false
        })
      this.$store.dispatch("paymentTerm/fetchItems").then(() => {
        if (this.contract_proposal && this.contract_proposal.payment_terms_uuids
          && this.contract_proposal.payment_terms_uuids.length === 1) {
          const uuid = this.contract_proposal.payment_terms_uuids[0]
          this.invoice.payment_terms_id = this.payment_terms.find(item => item.uuid == uuid).id
        }
        this.loadingPaymentTerm = false;
      })
    },
    closeModal() {
      this.modal = false;
    },
    setCompanyIssuers() {
      this.invoice.company_issuer_id = null
      this.plant = this.plants.find(item => item.id == this.invoice.company_plant_id)
    },
    openModal(invoice_bills = [], has_open = false) {
      this.without_rules = false
      this.hasOpen = has_open
      this.loading = true
      this.block_iss = false
      this.payment_intermediaries_exists = false
      this.invoice_billeds = []
      this.billings = []
      let loader = this.$loading.show()
      this.windowWidth = window.innerWidth
      this.invoice = {
        company_plant_id: '',
        company_plant_issuer_id: '',
        payment_term_id: '',
        formulation_value: 0,
        mcc_value: 0,
        mcc_theorical_value: 0,
        service_value: 0,
        additional_value: 0,
        single_value: 0,
        total: 0,
        canceled: 0,
        contract_proposal_id: null,
        iss_retain: false,
        retained_rate_flag: false,
        tax_not_found: false,
        not_found: false,
      }
      const self = this
      this.payment_methods_selecteds = this.payment_methods.filter(function (item) {
        if (self.contract_proposal.payment_methods_uuids.includes(item.uuid)) {
          return item
        }
      })
      this.entity_document_is_cnpj = onlyNumbers(this.contract_proposal.entity.document).length > 11;
      if (onlyNumbers(this.contract_proposal.entity.document).toString().length <= 11) {
        this.block_iss = true
      }
      Promise.all([
        this.$store.dispatch('companyPlantIssuer/fetchItems'),
        this.$store.dispatch("plant/fetchItemsActive"),
      ]).then(async () => {
        await this.start(invoice_bills)
       this.dont_allow_save = false
        this.invoice.iss_retain = false
        let params = {
          entity_id: this.contract_proposal.entity.id,
          commercial_tax_name: 'ISSQN',
          construction_city : this.contract_proposal.construction.default_address.city,
          issuers: Object.values(this.billings).map((item) => {
            return {
              id: item.company_plant_issuer.id,
              tax_regime: item.company_plant_issuer.tax_regime,
            }
          }),
        }
        this.$store.dispatch("FeesAndTaxes/getActiveRetainedRatesBetweenEntityAndIssuerIds", params).then(response => {
          for (let i in this.billings) {
            this.billings[i].not_found = false
            const has_issqn = response.data.commercial_taxes.find((a) => a.company_plant_issuer_id === this.billings[i].company_plant_issuer_id)
            if (has_issqn) {
              this.billings[i].retained_rate_flag = !!has_issqn
            }
            const deduction = response.data.deduction_rules.find((a) => a.regime == this.billings[i].company_plant_issuer.tax_regime)
            if (!deduction) {
              this.dont_allow_save = true
              this.billings[i].tax_not_found = true
            }
            const issuerConstructionCitiesEqual = this.billings[i].company_plant_issuer.city === this.contract_proposal.construction.default_address.city;
            const issuerEntityCitiesEqual = this.billings[i].company_plant_issuer.city === this.contract_proposal.entity.address.city;
            if (issuerConstructionCitiesEqual) {
              if (!this.billings[i].company_plant_issuer.nfse_rule1_equal_construction_default) {
                if (issuerEntityCitiesEqual) {
                  this.billings[i].retained_rate_flag = this.entity_document_is_cnpj
                    ? this.billings[i].company_plant_issuer.nfse_rule1_within_city_iss_legal
                    : this.billings[i].company_plant_issuer.nfse_rule1_within_city_iss_individual;
                } else {
                  this.billings[i].retained_rate_flag = this.entity_document_is_cnpj
                    ? this.billings[i].company_plant_issuer.nfse_rule1_outside_city_iss_legal
                    : this.billings[i].company_plant_issuer.nfse_rule1_outside_city_iss_individual;
                }
              }
            } else {
              if (!this.billings[i].company_plant_issuer.nfse_rule2_diff_construction_default) {
                if (issuerEntityCitiesEqual) {
                  this.billings[i].retained_rate_flag = this.entity_document_is_cnpj
                    ? this.billings[i].company_plant_issuer.nfse_rule2_within_city_iss_legal
                    : this.billings[i].company_plant_issuer.nfse_rule2_within_city_iss_individual;
                } else {
                  this.billings[i].retained_rate_flag = this.entity_document_is_cnpj
                    ? this.billings[i].company_plant_issuer.nfse_rule2_outside_city_iss_legal
                    : this.billings[i].company_plant_issuer.nfse_rule2_outside_city_iss_individual;
                }
              }
            }
            this.billings[i].retained_rate_flag = !!this.billings[i].retained_rate_flag;
            this.billings[i].iss_retain = 0
            if (deduction) {
              const deduct = this.billings[i].total * Number(deduction.deduct) / 100;
              const base = this.billings[i].total - deduct;
              this.billings[i].deduction = deduct
              this.billings[i].base = base
              this.billings[i].iss_retain = base * Number(deduction.retained_rate) / 100;
            }
            this.$forceUpdate()
          }
          this.loading = false
        })
        const company_plant = this.plants.find(item => item.id === this.contract_proposal.company_plant_id)
        this.invoice.bank_account_id = company_plant.bank_account_id
        this.showBankAccountId = false
        this.$nextTick(function () {
          this.showBankAccountId = true
        })
        loader.hide()
      })
    },
    async start(invoice_bills) {
      const billings = {}
      for (let item of invoice_bills) {
        const property = `${item.company_plant_id}${item.company_plant_issuer_id}`
        if (!this.invoice_billeds.hasOwnProperty(property)) {
          this.invoice_billeds[property] = []
        }
        this.invoice_billeds[property].push(item)
        const company_plant_id = item.company_plant_id
        const company_plant = this.plants.find(function (item) {
          return item.id === company_plant_id
        })
        const company_plant_issuer_id = item.company_plant_issuer_id
        if (!this.plant_issuer_selected) {
          this.plant_issuer_selected = company_plant_issuer_id
        }
        const company_plant_issuer = this.company_plant_issuers.find(function (item) {
          return item.id === company_plant_issuer_id
        })

        if (!company_plant_issuer || !company_plant) {
          continue;
        }
        if (!billings.hasOwnProperty(property)) {
          billings[property] = {
            total: 0,
            company_name: company_plant.name,
            company_plant_issuer_document: company_plant_issuer.ein,
            company_plant_id: item.company_plant_id,
            company_plant_issuer_id: item.company_plant_issuer_id,
            company_plant_issuer: company_plant_issuer,
            formulation_value: 0,
            mcc_value: 0,
            service_value: 0,
            mcc_theorical_value: 0,
            canceled: 0,
            iss_retain: 0,
            base: 0,
            deduction: 0,
            additional_value: 0,
            single_value: 0,
            retained_rate_flag: false,
            tax_not_found: false,
            not_found: false,
          }
        }
        const value = parseFloat(item.value)
        switch (item.type) {
          case 1:
            if (item.status === 6) {
              billings[property].canceled += value
              break;
            }
            billings[property].total += value
            billings[property].formulation_value += value
            billings[property].mcc_value += parseFloat(item.real_mcc ?? 0)
            billings[property].mcc_theorical_value += parseFloat(item.theorical_adjusted ?? 0)
            break;
          case 2:
            if (item.status === 3) {
              billings[property].canceled += value
              break;
            }
            billings[property].total += value
            billings[property].service_value += value
            break;
          case 3:
            if (item.status === 3) {
              billings[property].canceled += value
              break;
            }
            billings[property].total += value
            billings[property].additional_value += value
            break;
          case 4:
            billings[property].total += value
            billings[property].single_value += value
            break;
        }
      }
      this.billings = billings
      this.modal = true
    },
    save() {
      const billings = JSON.parse(JSON.stringify(this.billings))
      for (var index in billings) {
        billings[index].entity_id = this.contract_proposal.service_receiver.id
        billings[index].contract_proposal_id = this.contract_proposal.id
        billings[index].payment_method_id = this.invoice.payment_method_id
        billings[index].payment_term_id = this.invoice.payment_term_id
        billings[index].payment_intermediary_id = this.invoice.payment_intermediary_id
        billings[index].bank_account_id = this.invoice.bank_account_id
        billings[index].iss_retain = billings[index].retained_rate_flag
      }
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.loadingSave = true
      this.$store.dispatch('billingInvoice/add', {
        invoice: billings,
        invoice_bills: this.invoice_billeds
      }).then(response => {
        this.$notify({type: response.error_type, message: response.message});
        this.loadingSave = false
        if (this.hasOpen) {
          this.closeModal()
          this.$emit('fetch')
        } else {
          this.$router.push({
            name: 'financial.billing',
          })
        }
      }).catch(error => {
        this.$notify({type: error.data.error_type, message: error.data.message});
        this.loadingSave = false
      })
    }
  },
};
</script>

<style></style>
