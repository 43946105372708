<template>
  <div>
    <modal :show.sync="modal" size="md">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div class="p-3">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
            <div v-for="(distance, indexDistance) in distances" :key="indexDistance">
              <breadcrumb list-classes="bg-success border-header-primary-bottom">
                {{ distance.label }}
              </breadcrumb>

              <!--  Distância -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pt-0 pb-0 col-form-label form-control-label mb-2">
                  <div class="pt-1">
                    Distância
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-7 mb-2">
                  <validation-provider rules="required|min_value:1">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-on:input="distance.distance = formatString($event.target.value, '0', '99999', '')"
                        v-model="distance.distance"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> km </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <!--  Trajeto(ida) -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pt-0 pb-0 col-form-label form-control-label mb-2">
                  <div class="pt-1">
                    Trajeto(ida)
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-7 mb-2">
                  <validation-provider rules="required|min_value:1">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-on:input="distance.send_duration = formatString($event.target.value, '0', '99999', '')"
                        v-model="distance.send_duration"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> minutos </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <!--  Trajeto(volta) -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pt-0 pb-0 col-form-label form-control-label mb-2">
                  <div class="pt-1">
                    Trajeto(volta)
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-7 mb-2">
                  <validation-provider rules="required|min_value:1">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-on:input="distance.return_duration = formatString($event.target.value, '0', '99999', '')"
                        v-model="distance.return_duration"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> minutos </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
            </div>
            <div class="modal-footer pb-0">
              <base-button type="secondary" @click="closeModal()"> Cancelar </base-button>
              <base-button @click.prevent="save" type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingSave">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { checkNumberValue } from "@/helpers";
import { mask1 } from "@/plugins";

export default {
  components: {},
  name: "ModalDistanceTravelPlant",
  data() {
    return {
      modal: false,
      title: "Configurações de Ciclo",
      loadingSave: false,
      distances: [],
    };
  },
  computed: {},
  methods: {
    closeModal() {
      this.modal = false;
    },
    openModal(distances) {
      this.distances = distances;
      this.modal = true;
    },
    save() {
      this.$emit("setDistances", this.distances);
      this.closeModal();
    },
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        return checkNumberValue(mask1(value.toString(), decimals, ".", true), min, max);
      }
      return checkNumberValue(value, min, max);
    },
  },
  created() {},
  mounted() {},
};
</script>

<style></style>
