<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <PuzlBreadcrumb/>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-2 mb-2">
          <back-button/>
        </div>
      </div>
    </base-header>
    <list/>
  </div>
</template>

<script>
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb.vue";
import BackButton from "@/components/Utils/BackButton.vue";
import List from "./Shared/_List.vue";

export default {
  name: "Index",
  components: {
    List,
    BackButton,
    PuzlBreadcrumb
  },

  methods: {}
}
</script>

<style scoped>

</style>
