<template>
  <div>
    <div class="container-fluid">
      <div class="row mb-4">
        <div class="col-md-4 mb-2">
          <base-button
            @click.prevent="mainChartDataType = 'constructions'"
            block
            size="md"
            :loading="loading"
            :type="mainChartDataType === 'constructions' ? 'success' : 'secondary'"
          >
            Obra
          </base-button>
        </div>
        <div class="col-md-4 mb-2">
          <base-button
            @click.prevent="mainChartDataType = 'companyPlants'"
            block
            size="md"
            :loading="loading"
            :type="mainChartDataType === 'companyPlants' ? 'success' : 'secondary'"
          >
            Central
          </base-button>
        </div>
        <div class="col-md-4 mb-2">
          <base-button
            @click.prevent="mainChartDataType = 'months'"
            block
            size="md"
            :loading="loading"
            :type="mainChartDataType === 'months' ? 'success' : 'secondary'"
          >
            Mês
          </base-button>
        </div>
      </div>
      <div>
        <SkeletonPuzlFullWidth v-show="loading" />
      </div>
    </div>

    <div v-if="!loading">
      <BarLineChart :option="travelChart" :theme="theme" />
      <BarLineChart :option="cycleChart" :theme="theme" />
    </div>
    <div class="container-fluid">
      <div class="row mb-4">
        <div class="col-md-6 mb-2 d-flex justify-content-center pr-5 pl-5">
          <base-button
            :type="mixedChartDataType === 'equipments' ? 'success' : 'secondary'"
            :loading="loading"
            @click.prevent="mixedChartDataType = 'equipments'"
            block
            size="md"
          >
            Equipamento
          </base-button>
        </div>
        <div class="col-md-6 mb-2 d-flex justify-content-center pr-5 pl-5">
          <base-button
            @click.prevent="mixedChartDataType = 'drivers'"
            :loading="loading"
            :type="mixedChartDataType === 'drivers' ? 'success' : 'secondary'"
            block
            size="md"
          >
            Motorista
          </base-button>
        </div>
      </div>
    </div>
    <div v-if="!loading">
      <BarLineChart :option="mixedData" :theme="theme" />
    </div>
  </div>
</template>

<script>
import BarLineChart from "@/components/Echarts/BarLineChart.vue";
import { mapGetters } from "vuex";
import SkeletonPuzlFullWidth from "@/components/SkeletonPuzlFullWidth";

export default {
  name: "DashboardChart",
  components: {
    BarLineChart,
    SkeletonPuzlFullWidth,
  },

  props: {
    theme: {
      type: Boolean,
      default: false,
      description: "Tema",
    },
    decal: {
      type: Boolean,
      default: false,
      description: "Estilização adicional",
    },
  },

  computed: {
    ...mapGetters({
      travelChart: "operationalDashboard/travelChart",
      cycleChart: "operationalDashboard/cycleChart",
      mixedData: "operationalDashboard/mixedChart",
      loading: "operationalDashboard/loading",
    }),
  },

  watch: {
    mainChartDataType: function (val) {
      this.$store.dispatch("operationalDashboard/changeMainChartData", val);
    },
    mixedChartDataType: function (val) {
      this.$store.dispatch("operationalDashboard/changeMixedChartData", val);
    },
  },

  data() {
    return {
      mainChartDataType: "constructions",
      mixedChartDataType: "equipments",
    };
  },
  methods: {
    resetDefaultTypes() {
      this.mainChartDataType = "constructions";
      this.mixedChartDataType = "equipments";
    },
  },
};
</script>

<style></style>
