<template>
<div>
  <div class="container-fluid">
    <div class="row card-wrapper" v-show="loadinGrid">
      <SkeletonPuzlGrid v-for="(skeletons, index) in 3" :key="index"></SkeletonPuzlGrid>
    </div>

    <div class="row card-wrapper" v-show="!loadinGrid">
        <div class="col-lg-4"
          v-for="(item, index) in listParameter"
          :key="index"
        >
          <!-- Basic with action button -->
          <card>
            <!-- Card header -->
            <div class="row align-items-center mb-3">
              <div class="col-8">
                <h5 class="h4 mb-0">
                  {{item.description}}
                </h5>
              </div>

              <div class="col-4 text-right">
                <base-dropdown menuOnRight>
                  <base-button
                    slot="title-container"
                    type="primary"
                    class="dropdown-toggle p-2 rounded m-0"
                  >
                    <i class="fas fa-cog"></i>
                  </base-button>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <router-link
                    :to="{
                      path:'/commercial/concrete-price/parameter/edit',
                      name:'Parâmetros de preços mínimo - editar formulação',
                      params: { uuid: item.uuid }
                    }"
                  >
                  <a class="dropdown-item">
                    <i class="fas fa-edit text-warning"></i>
                    Editar
                  </a>
                  </router-link>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleDelete(item.uuid)">
                    <i class="fas fa-times text-danger"></i>
                    Excluir
                  </a>
                </base-dropdown>
              </div>
            </div>
            <!-- Card body -->
                <collapse class="border rounded p-0 mt-3" v-if="item.company_plants!=''">
                  <collapse-item
                    class="header-gray"
                    back-ground="border-header-primary-bottom"
                  >
                  <h5 slot="title" class="mb-0 ">Centrais</h5>

                    <div class="row align-items-center pb-0 mb-3">
                      <div class="col-md-12">
                        <div class="checklist-item checklist-item-primary"
                             v-for="(itemPlant, indexPlant) in item.company_plants"
                             :key="indexPlant"
                        >
                            <div class="checklist-info">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <h5 class="h4">
                                        {{itemPlant.name}}
                                      </h5>
                                    </div>
                                  </div>
                            </div>
                          </div>
                        </div>
                    </div>

                  </collapse-item>
                </collapse>

                <collapse class="border rounded p-0 mt-3">
                  <collapse-item
                    class="header-gray"
                    back-ground="border-header-danger-bottom"
                  >
                  <h5 slot="title" class="mb-0 ">Parâmetros</h5>
                    <div class="row mt-2">
                      <div class="col-md-12">
                        <h5 class="mb-0 ">
                          Validade
                        </h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <h5 class="mb-0 small">
                          {{item.expiration_months}} dias
                        </h5>
                      </div>
                    </div>

                    <div class="row  mt-2">
                      <div class="col-md-12">
                        <h5 class="mb-0 ">
                          Usinagem
                        </h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <h5 class="mb-0 small text-success">
                          R$ {{item.machining_cost}} /m<sup>3</sup>
                        </h5>
                      </div>
                    </div>

                    <div class="row  mt-2">
                      <div class="col-md-12">
                        <h5 class="mb-0 ">
                          Impostos
                        </h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <h5 class="mb-0 small text-danger">
                          {{item.tax | percent(0)}}
                        </h5>
                      </div>
                    </div>

                    <div class="row mt-3">
                      <div class="col-md-12">
                        <h5 class="mb-0 ">
                          Transporte
                        </h5>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-12">
                        <h5 class="mb-0 small">
                          Distância Máxima
                        </h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 pt-2">
                        <h5 class="mb-0 small text-primary">
                          {{item.transport_maximum_distance}}&nbsp;km
                        </h5>
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-md-12 pt-2">
                        <h5 class="mb-0 small">
                          Adicional <small>(acima da distância máxima)</small>
                        </h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 pt-2">
                        <h5 class="mb-0 small text-primary">
                          R$ {{item.transport_additional_cost}}&nbsp;/km (por m<sup>3</sup>)
                        </h5>
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-md-12">
                        <h5 class="mb-0 small">
                          Custo mínimo <small>até distância máxima</small>
                        </h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 pt-2">
                        <h5 class="mb-0 small text-primary">
                          R$ {{item.transport_minimum_cost}}&nbsp;/m<sup>3</sup>
                        </h5>
                      </div>
                    </div>

                    <div class="row mt-3">
                      <div class="col-md-12">
                        <h5 class="mb-0 ">
                          Ciclo
                        </h5>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-12">
                        <h5 class="mb-0 small">
                          Ciclo Máxima
                        </h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 pt-2">
                        <h5 class="mb-0 small text-warning">
                          {{item.cycle_maximum_distance}}&nbsp;minutos
                        </h5>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-12">
                        <h5 class="mb-0 small">
                          Adicional <small>(acima do ciclo máximo.)</small>
                        </h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 pt-2">
                        <h5 class="mb-0 small text-warning">
                          R$ {{item.cycle_additional_cost}}&nbsp;/km (por m<sup>3</sup>)
                        </h5>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-12">
                        <h5 class="mb-0 small">
                          Custo mínimo <small>até ciclo máximo</small>
                        </h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 pt-2">
                        <h5 class="mb-0 small text-warning">
                          R$ {{item.cycle_minimum_cost}}&nbsp;/m<sup>3</sup>
                        </h5>
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-md-12">
                        <h5 class="mb-0 ">
                          Puzl Connect <small>(acréscimo)</small>
                        </h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 pt-2">
                        <h5 class="mb-0 small text-purple">
                          {{item.puzl_addition | percent(0)}}
                        </h5>
                      </div>
                    </div>
                  </collapse-item>
                </collapse>
          </card>
        </div>
  </div>
</div>
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-md-12 text-center">
        <PuzlEmptyData v-if="listParameter ==''"></PuzlEmptyData>
      </div>
    </div>
  </div>
</div>

</template>
<script>
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import globalFilter from "@/mixins/globalFilter";

export default {
  mixins: [globalFilter],
  components: {
    SkeletonPuzlGrid,
    PuzlEmptyData,
  },
  data() {
    return {
      listParameter: {},
      loadinGrid: false,
    }
  },
  methods: {
    init(){
      this.$Progress.start();
      this.loadinGrid = true;
      this.$axios.get("/commercial/concrete-price/minimum-costs")
        .then(({data}) => {
          this.listParameter = data.data;
          this.loadinGrid = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loadinGrid = false;
          this.$Progress.finish();
        });
    },
    handleDelete(uuid){
      this.$Progress.start();
        this.$swal.fire({
          title: 'Você tem certeza?',
          text: 'Você não poderá recuperar este registro!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim, apague isto!',
          cancelButtonText: 'Não, mantenha',
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
            this.$axios.delete("/commercial/concrete-price/minimum-costs/"+uuid)
              .then(({data}) => {
                this.$notify({ type: data.error_type, message: data.message });
                this.$Progress.finish()
                this.init()
              })
              .catch((error) => {
                this.$notify({ type: error.response.data.error_type, message: error.response.data.message });
              });
            }
            this.$Progress.finish();
        });
    },
  },
  mounted() {
    this.init();
  }
};
</script>
