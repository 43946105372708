import * as types from "./mutation_types";
import { createAxios } from "@/plugins/axios";;
const endPoint = "/operational/schedule-resume+microservice";
export default {
  namespaced: true,
  state: {
    items: [],
    scheduleResume: null,
  },
  getters: {
    fetch: (state) => state.items,
    scheduleResume: (state) => state.scheduleResume,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload;
    },
    [types.SET_SCHEDULE_RESUME](state, payload) {
      state.scheduleResume = payload;
    },
  },
  actions: {
    fetchItems({ commit }, params) {
      return createAxios()
        .get(`${endPoint}`, { params: params })
        .then(({ data }) => {
          commit(types.SET, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getResumeByDate(_, params) {
      return createAxios()
        .get(endPoint + "-by-date/", { params: params })
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getResumeByDateAndCompanyPlant(_, params) {
      return createAxios()
        .get(
          endPoint +
            "-by-date-company-plant/" +
            params.concreting_date +
            "/" +
            params.company_plant_uuid
        )
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getFormulationsByScheduleTravel(_, params) {
      return createAxios()
        .get(
          endPoint +
            "-get-formulation-by-schedule-travel/" +
            params.schedule_id
        )
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    findByCompanyConstructionDate(_, params) {
      return createAxios()
        .get(
          endPoint +
            "/" +
            params.company_plant_uuid +
            "/" +
            params.construction_id +
            "/" +
            params.concreting_date
        )
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    }
  },
};
