var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('modal',{attrs:{"show":_vm.modal},on:{"update:show":function($event){_vm.modal=$event}}},[_c('template',{slot:"header"},[_c('h5',{staticClass:"modal-title p-0 m-0"},[_vm._v("Histórico de Aprovações")])]),_c('div',[_c('div',{staticClass:"p-3 pt-0 mt-0"},_vm._l((_vm.histories),function(history,index){return _c('div',{key:index},[_c('div',{staticClass:"border-left border-3 p-2 mb-3",class:[
                    history.status === 0 ? 'border-danger' : '',
                    history.status === 1 ? 'border-success' : '',
                    history.status === 2 ? 'border-yellow' : '',
                    history.status === 3 ? 'border-warning' : '',
                    history.status === 4 ? 'border-light' : '',
                    history.status === 5 ? 'border-primary' : '',
                    ]},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 h4 text-left text-uppercase"},[_vm._v(" "+_vm._s(_vm.status[history.status])+" ")]),(history.situation)?_c('div',{staticClass:"h4 col-12 text-left"},[_vm._v(" "+_vm._s(history.situation.name)+" ")]):_vm._e(),(history.competitor)?_c('div',{staticClass:"col-12 text-left"},[_vm._v(" "+_vm._s(history.competitor.name)+" ")]):_vm._e(),(history.reasons)?_c('div',{staticClass:"col-12 text-left"},_vm._l((history.reasons),function(item,key){return _c('span',{key:key},[_vm._v(" "+_vm._s(item.reason)+" "),(key != history.reasons.length -1)?_c('span',[_vm._v("|")]):_vm._e()])}),0):_vm._e(),(history.observation)?_c('div',{staticClass:"col-12 text-left h3 small"},[_vm._v(" "+_vm._s(history.observation)+" ")]):_vm._e(),_c('div',{staticClass:"col-12 text-left h3 small"},[_vm._v(" "+_vm._s(history.user.name)+" | "+_vm._s(_vm._f("parseDate")(history.created_at))+" ")])])])])}),0),_c('div',{staticClass:"modal-footer justify-content-center"},[_c('base-button',{attrs:{"type":"secondary"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("fechar")])],1)])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }