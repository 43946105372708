<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title mb-0">{{ modal.title }}</h5>
      </template>
      <div class="px-3">
        <validation-observer v-slot="{ invalid }" ref="formValidator">
          <form class="needs-validation" autocomplete="off">
            <!-- Viagem -->
            <div class="row">
              <div class="col-4">
                <span class="new-default-black-font font-weight-500 d-flex align-items-center fs-16">
                  <img class="mr-3 mt" src="/img/icons/icons8/ios/info-squared.png" width="21" />
                  Viagem
                </span>
              </div>
              <div style="display: grid" class="col-8 align-items-center">
                <hr style="border-top: 1.5px solid rgba(0, 0, 0, 0.1)" class="m-0" />
              </div>
            </div>

            <!-- Volume -->
            <div class="row mt-3">
              <div class="col-6 d-flex align-items-center">
                <span class="new-default-black-font fs-13"> Volume </span>
              </div>
              <div style="display: grid" class="col-6 pl-0">
                <validation-provider rules="required">
                  <InputLimit
                    input_group_classes=" input-group-sm"
                    :start_value="item.volume"
                    :options="{
                      min: 0,
                      max: 29,
                      decimals: 1,
                    }"
                    v-model="item.volume"
                    @value="item.volume = $event"
                    ><small>m³</small>
                  </InputLimit>
                </validation-provider>
              </div>
            </div>

            <!-- Horário de chegada na obra -->
            <div class="row mt-2">
              <div class="col-6 d-flex align-items-center">
                <span class="new-default-black-font fs-13"> Horário de chegada na obra </span>
              </div>
              <div class="col-6 pl-0">
                <validation-provider rules="required">
                  <InputDateTimePicker
                    :disableDate="disable_date"
                    :disabled="!item.send_plant_uuid || loading_arrival_time"
                    v-model="item.arrival_time"
                    :disableMaxDate="max_date"
                    size="lg"
                  />
                </validation-provider>
              </div>
            </div>

            <!-- Central -->
            <div class="row mt-4">
              <div class="col-4">
                <span class="new-default-black-font font-weight-500 d-flex align-items-center fs-16">
                  <img class="mr-3 mt" src="/img/icons/chemical-plant-black.png" width="21" />
                  Central
                </span>
              </div>
              <div style="display: grid" class="col-8 align-items-center">
                <hr style="border-top: 1.5px solid rgba(0, 0, 0, 0.1)" class="m-0" />
              </div>
            </div>

            <!-- Central de carregamento -->
            <div class="row mt-3">
              <div class="col-6 pr-0 d-flex align-items-center">
                <span class="new-default-black-font fs-13"> Central de carregamento </span>
              </div>
              <div class="col-6 pl-0">
                <validation-provider rules="required">
                  <PuzlSelect
                    @change="getDataByCompanyPlant"
                    customKey="uuid"
                    :clearable="false"
                    v-model="item.send_plant_uuid"
                    :items="$_company_plants"
                  />
                </validation-provider>
              </div>
            </div>

            <!-- Ponto de carga -->
            <div class="row mt-2">
              <div class="col-6 pr-0 d-flex align-items-center">
                <span class="new-default-black-font fs-13"> Ponto de carga </span>
              </div>
              <div class="col-6 pl-0">
                <PuzlSelect
                  :disabled="!item.send_plant_uuid"
                  :loading="loading_charge_points"
                  customKey="uuid"
                  :clearable="false"
                  v-model="item.charge_point_uuid"
                  :items="$_charge_points"
                />
              </div>
            </div>

            <!-- Central de retorno -->
            <div class="row mt-2">
              <div class="col-6 pr-0 d-flex align-items-center">
                <span class="new-default-black-font fs-13"> Central de retorno </span>
              </div>
              <div class="col-6 pl-0">
                <validation-provider rules="required">
                  <PuzlSelect customKey="uuid" :clearable="false" v-model="item.return_plant_uuid" :items="$_company_plants" />
                </validation-provider>
              </div>
            </div>

            <!-- Central -->
            <div class="row mt-4">
              <div class="col-4">
                <span class="new-default-black-font font-weight-500 d-flex align-items-center fs-16">
                  <img class="mr-3 mt" src="/img/icons/interstate-truck.png" width="21" />
                  Alocação
                </span>
              </div>
              <div style="display: grid" class="col-8 align-items-center">
                <hr style="border-top: 1.5px solid rgba(0, 0, 0, 0.1)" class="m-0" />
              </div>
            </div>

            <!-- Equipamento -->
            <div class="row mt-3">
              <div class="col-6 pr-0 d-flex align-items-center">
                <span class="new-default-black-font fs-13"> Equipamento </span>
              </div>
              <div class="col-6 pl-0">
                <PuzlSelect
                  :disabled="!item.send_plant_uuid"
                  :loading="loading_equipments"
                  customKey="uuid"
                  label="$code$ - $plate$"
                  :clearable="false"
                  v-model="item.equipment_uuid"
                  :items="$_equipments"
                  :labelMask="true"
                />
              </div>
            </div>

            <!-- Motorista -->
            <div class="row mt-2">
              <div class="col-6 pr-0 d-flex align-items-center">
                <span class="new-default-black-font fs-13"> Motorista </span>
              </div>
              <div class="col-6 pl-0">
                <PuzlSelect
                  :disabled="!item.send_plant_uuid"
                  :loading="loading_drivers"
                  customKey="uuid"
                  :clearable="false"
                  v-model="item.driver_uuid"
                  :items="$_drivers"
                />
              </div>
            </div>

            <div class="modal-footer mt-4">
              <base-button class="d-flex align-items-center" type="danger" @click="closeModal()">
                <img src="/img/icons/cancel-white.png" width="20" />
                <span class="font-weight-500 fs-13 ml-2"> Fechar </span>
              </base-button>
              <base-button :disabled="invalid" @click.prevent="store" class="d-flex align-items-center" type="success" native-type="submit">
                <img src="/img/icons/save.png" width="20" />
                <span class="font-weight-500 fs-13 ml-2"> Salvar </span>
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { formatErrorValidation } from "@/plugins";
import { mapGetters } from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
import InputLimit from "@/components/Utils/InputLimit.vue";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import moment from "moment";

export default {
  name: "ModalAddTravel",
  components: {
    InputLimit,
    InputDateTimePicker,
    PuzlSelect,
  },
  data() {
    return {
      modal: {
        title: "Adicionar viagem",
        create: false,
      },
      item: {
        volume: null,
        arrival_time: null,
        send_plant_uuid: null,
        return_plant_uuid: null,
        charge_point_uuid: null,
        equipment_uuid: null,
        driver_uuid: null,
        schedule_uuid: null,
      },
      loading_arrival_time: false,
      loading_drivers: false,
      loading_equipments: false,
      loading_charge_points: false,
      disable_date: false,
      interval_releases: null,
      max_date: false,
    };
  },
  computed: {
    ...mapGetters({
      $_company_plants: "plant/activeItems",
      $_charge_points: "plantChargePoint/fetch",
      $_drivers: "user/getDrivers",
      $_equipments: "equipment/fetch",
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal(schedule_uuid, interval_releases, default_send_plant_uuid) {
      this.item = {
        volume: null,
        arrival_time: null,
        send_plant_uuid: default_send_plant_uuid,
        return_plant_uuid: null,
        charge_point_uuid: null,
        equipment_uuid: null,
        driver_uuid: null,
        schedule_uuid: schedule_uuid,
      };
      this.interval_releases = interval_releases;
      this.getDataByCompanyPlant(default_send_plant_uuid);
      this.max_date = new Date(moment().add(1, "days").format("YYYY-MM-DD 00:00:00"));
      this.modal.create = true;
    },
    store() {
      const loader = this.$loading.show();
      this.$store
        .dispatch("travels/add", this.item)
        .then(() => {
          this.$emit("addedTravel");
          this.closeModal();
        })
        .catch((error) => {
          if (error.status === 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response && error.response.status === 422) {
            const errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
        })
        .finally(() => {
          loader.hide();
        });
    },
    getDataByCompanyPlant(company_plant_uuid) {
      const company_plant = this.$_company_plants.find((item) => item.uuid === company_plant_uuid);
      this.loading_drivers = true;
      this.loading_equipments = true;
      this.loading_charge_points = true;
      this.item.charge_point_uuid = null;
      this.item.equipment_uuid = null;
      this.item.driver_uuid = null;
      const filter = {
        company_plant_uuid: company_plant_uuid,
        category_id: 1, // transporte de concreto
        status: 1, // ativo
        is_concrete: 1, // concreto
      };
      if (!this.item.return_plant_uuid) {
        this.item.return_plant_uuid = company_plant_uuid;
      }
      this.$store.dispatch("user/fetchDrivers", { filter: filter }).then(() => (this.loading_drivers = false));
      this.$store.dispatch("plantChargePoint/getActiveByPlant", company_plant.id).then((response) => {
        this.item.charge_point_uuid = response.data.length ? response.data[0].uuid : null;
        this.loading_charge_points = false
      });
      this.$store.dispatch("equipment/getByCompanyPlantAndService", { filter: filter }).then(() => (this.loading_equipments = false));
      this.$store
        .dispatch("travels/getLastArrivalTimeByScheduleAndSendPlant", {
          send_plant_uuid: company_plant_uuid,
          schedule_uuid: this.item.schedule_uuid,
        })
        .then((response) => {
          this.item.arrival_time = moment(response.data).add(this.interval_releases, "minutes").format("YYYY-MM-DD HH:mm");
          this.disable_date = new Date(this.item.arrival_time + ":00");
        });
    },
  },
};
</script>
