<template>
  <div>
    <div class="container-fluid mt-6">
      <div class="row card-wrapper" v-if="bills_items.length && !loadingSkeleton">
        <div class="col-lg-4" v-for="(item, index) in bills_items" :key="index">
          <div class="card">
            <div
              class="card-header pointer"
              style="padding: 0px !important;height: 22px"
            >
              <div class="row">
                <div class="col-md-12">
                  <base-dropdown class="w-100" menuOnRight v-if="!item.reconciled && !item.identified">
                    <base-button
                      block
                      size="sm"
                      outline
                      style="opacity: 92%"
                      :loading="loadingInstallment"
                      :type="item.status.color"
                      slot="title-container"
                      :disabled="item.status.status === 1 ? !$hasPermission('bill_pay.reverse_installment') : false"
                      :class="item.status.status === 1 && !$hasPermission('bill_pay.reverse_installment') ? '' : 'dropdown-toggle'"
                      class="active text-uppercase mr-0 btn-header"
                    >
                      <img
                        v-if="['Atrasado', 'Pendente', 'Cancelado', 'Aprovado'].includes(item.status.text)"
                        width="15px"
                        class="mr-1 pointer"
                        :src="`/img/icons/`+getInstallmentStatusIcon(item.status.text)"
                      />
                      {{ item.status.text }}
                    </base-button>
                    <div>
                      <a
                        @click.prevent="changeInstallmentStatus(item.id, status.value)"
                        :key="statusIndex"
                        v-for="(status, statusIndex) in availableStatuses(item.status.status)"
                        class="dropdown-item"
                      >
                        {{ status.text }}
                      </a>
                    </div>
                  </base-dropdown>
                  <base-button
                      v-else
                      block
                      size="sm"
                      outline
                      style="opacity: 92%"
                      :loading="loadingInstallment"
                      :type="item.status.color"
                      slot="title-container"
                      :disabled="item.status.status === 1 ? !$hasPermission('bill_pay.reverse_installment') : false"
                      :class="item.status.status === 1 && !$hasPermission('bill_pay.reverse_installment') ? '' : 'dropdown-toggle'"
                      class="active text-uppercase mr-0 btn-header"
                    >
                      <img
                        v-if="['Atrasado', 'Pendente', 'Cancelado', 'Aprovado'].includes(item.status.text)"
                        width="15px"
                        class="mr-1 pointer"
                        :src="`/img/icons/`+getInstallmentStatusIcon(item.status.text)"
                      />
                      {{ item.status.text }}
                  </base-button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row mt-n2">
                <div class="col-3 pl-2">
                  <div class="d-flex w-100">
                    <!-- Saldo -->
                    <el-popover v-show="item.balance !== '0.00'" trigger="hover" placement="bottom"
                      class="p-0 float-right pr-0">
                      <span>
                        Saldo: <b>{{ item.balance | currency }} </b>
                      </span>
                      <base-button outline slot="reference" size="sm" type="secundary"
                        class="p-0 m-0 shadow-none text-success">
                        <i style="font-size: 20px !important" class="fas fa-dollar-sign" />
                      </base-button>
                    </el-popover>

                    <!-- Conta bancária concreteira -->
                    <el-popover v-if="item.bank_account_id"
                      trigger="hover"
                      placement="bottom"
                      class="p-0 float-right pr-0"
                    >
                      <span> {{ item.bank_account_name }} </span>
                      <img
                        slot="reference"
                        width="26px"
                        class="mr-1 pointer"
                        src="/img/icons/merchant-account.png"
                      />
                    </el-popover>

                    <!-- Conta bancária cpf/cnpj -->
                    <span v-show="item.payment_method_id && [1, 4].includes(item.payment_method_id)" @click.prevent="handleEntityAccount(item.entity_id)">
                      <img
                        width="26px"
                        class="mr-1 pointer"
                        src="/img/icons/bank-building-red.png"
                      />
                    </span>

                    <base-button
                      outline
                      slot="reference"
                      size="sm"
                      type="secundary"
                      v-if="['Pago', 'Aprovado'].includes(item.status.text)"
                      @click="getPaymentAuthorizationProof(item.id)"
                      class="p-0 m-0 shadow-none text-indigo"
                      title="Imprimir Autorização"
                    >
                      <img
                        width="26px"
                        class="mr-1 pointer"
                        src="/img/icons/print--v1-green-26.png"
                      />
                    </base-button>

                    <!-- Conciliação -->
                    <el-popover v-if="item.reconciled" trigger="hover" placement="bottom" class="p-0 float-right pr-0">
                      <span>Conciliado por: {{ item.reconciled_user }} </span>
                      <base-button outline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none">
                        <i style="font-size: 20px !important" class="fas fa-link text-primary text-center" />
                      </base-button>
                    </el-popover>

                    <!-- Identificado -->
                    <el-popover v-if="item.identified" trigger="hover" placement="bottom" class="p-0 float-right pr-0">
                      <span>Identificado por: {{ item.identified_user }} </span>
                      <base-button outline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none">
                        <img style="width: 1.8rem" src="/img/icons/identify_payment_green.png" />
                      </base-button>
                    </el-popover>

                    <!-- Com acumulação -->
                    <el-popover v-else-if="item.accumulation" trigger="hover" placement="bottom"
                      class="p-0 float-right pr-0">
                      <span> Acumular conta </span>
                      <base-button outline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none">
                        <i style="font-size: 20px !important" class="fas fa-history text-center text-indigo" />
                      </base-button>
                    </el-popover>

                    <!-- Gerada a partir de repetição -->
                    <el-popover v-if="item.original === false" trigger="hover" placement="bottom"
                      class="p-0 float-right pr-0">
                      <span> Ocorrência | {{ getOccurrence(item.occurrence) }} </span>
                      <base-button outline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none">
                        <i style="font-size: 20px !important" class="fas fa-repeat text-center text-primary" />
                      </base-button>
                    </el-popover>
                  </div>
                </div>
                <div class="col-6 p-0 text-center">
                  <el-popover trigger="hover" title="Detalhamento" placement="bottom" class="p-0">
                    <span>
                      <span>Valor final: <span class="font-weight-600">{{ item.final_value | currency() }}</span></span>
                      <br>
                      <span>Desconto/taxas: <span class="font-weight-600">{{
                        item.discounts_fees || 0 | currency()
                      }}</span>
                      </span>
                      <br>
                      <span>Juros/Multa: <span class="font-weight-600">{{
                        item.fees_fines || 0 | currency()
                      }}</span></span>
                    </span>
                    <h3 style="font-size: 18px !important;" slot="reference" class="numeric">
                      {{ item.total_value | currency() }}
                      <badge style="font-size: 12px !important;" type="primary" class="font-weight-500 text-white">
                        {{ item.index + "/" + item.last_index }}
                      </badge>
                      <br>
                      <div class="text-center" style="margin-top: -7px;margin-left: -10px">
                        <small v-if="item.paid_at"> {{ item.paid_at }} </small>
                        <small v-else>{{ item.due_date | parseDate('DD MMM YYYY') }}</small>
                      </div>
                    </h3>
                  </el-popover>
                </div>
                <div v-if="!paid_allotment" class="col-3 text-right pr-1">
                    <base-dropdown menuOnRight>
                      <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                        <i class="fas fa-cog"></i>
                      </base-button>
                      <a class="dropdown-item" @click.prevent="handleHistory(item.id)">
                        <i class="fas fa-history text-indigo main-icon-menu"></i>
                        Histórico
                      </a>
                      <a v-if="item.status.status !== 1 && item.status.status !== 3" class="dropdown-item"
                        @click.prevent="handleAgroup(item.bill_pay_id, item.entity_id)">
                        <i class="fas fa-layer-group text-primary main-icon-menu"></i>
                        Agrupar
                      </a>
                      <a @click.prevent="handleEditBillPay(item.bill_pay_id, item.index)" class="dropdown-item">
                        <i class="fas fa-edit text-warning main-icon-menu"></i>
                        Editar
                      </a>
                      <a v-if="item.status.status !== 1" @click.prevent="handleDeleteBillPayInstallment(item.id)"
                        class="dropdown-item">
                        <i class="fas fa-times text-danger main-icon-menu"></i>
                        Excluir
                      </a>
                      <a
                        v-if="['Atrasado', 'Pendente'].includes(bill_pay_installment_status[item.status.status])"
                        @click.prevent="handleModalShareInstallment(item)"
                        class="dropdown-item"
                      >
                          <i class="text-success fa-solid fa-arrows-split-up-and-left main-icon-menu"></i>
                          Dividir
                      </a>
                      <a
                        v-if="!['Pago', 'Cancelado'].includes(bill_pay_installment_status[item.status.status]) && item.document_quantity > 1"
                        @click.prevent="removeAgroup(item.bill_pay_id)"
                        class="dropdown-item"
                      >
                        <i class="fa fa-undo main-icon-menu text-primary"></i>
                        Desagrupar
                      </a>
                    </base-dropdown>
                  </div>
                  <div v-if="paid_allotment && (item.status.status !== 1 && item.status.status !== 3)" class="col-2 text-right pl-0">
                    <base-button style="padding: 0.32rem 0.5rem !important" v-if="paid_allotments.includes(item.id)"
                      @click.prevent="addSetPaidAllotments(item.id)" class="float-right" type="success">
                      <i class="fas fa-check text-white" />
                    </base-button>
                    <base-button style="padding: 0.32rem 0.5rem !important;background-color: white"
                      v-if="!paid_allotments.includes(item.id)" @click.prevent="addSetPaidAllotments(item.id, index)"
                      class="float-right">
                      <i class="fas fa-check text-white" />
                    </base-button>
                  </div>
              </div>
              <div class="row mt-2">
                <div class="col-10 px-2">
                  <h4 class="mb-0">{{ item.entity_name }}</h4>
                  <h4 class="mb-2 font-weight-normal">{{ item.entity_document }}</h4>
                </div>
                <div class="col-2 d-flex justify-content-end text-right pr-0">
                  <!-- anexo -->
                  <base-button v-if="item.attachment_url" @click.prevent="handleModalBillPayInstallmentAttachment(item)" outline size="sm" type="secundary" class="p-0 m-0 shadow-none">
                    <img class="ml-1 mr-1"  style="width: 25px" src="/img/icons/attach-blue-50.png">
                  </base-button>
                </div>
                <div class="col-12 p-0 pr-2 mb-0 pl-2">
                  <div style="border-top: 1px solid rgb(26, 112, 183); margin-bottom: 5px;"/>
                </div>
                <div class="row p-0 col-12 m-0">
                  <div class="col-6 px-2">
                    <h4 class="mb-0">{{ item.type_name }}</h4>
                  </div>
                  <div class="col-6 pr-0 justify-content-end d-flex">
                    <!-- observações -->
                    <el-popover v-if="item.document_observations" trigger="hover" placement="bottom" class="p-0">
                      <span> {{ item.document_observations }} </span>
                      <base-button outline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none">
                        <img class="mr-1 ml-1"  style="width: 25px" src="/img/icons/speech-bubble-with-dots--v1-black.png">
                      </base-button>
                    </el-popover>
                    <!-- modal de informações -->
                    <span
                      class="pointer"
                      @click.prevent="handleModalInfo(item)"
                    >
                      <img class="ml-1 mr-1" style="width: 25px;padding-top:2px" src="/img/icons/info.png">
                    </span>
                    <!-- anexo -->
                    <base-button v-if="item.document_photo" @click.prevent="handleModalAttachment(item.document_photo)" outline size="sm" type="secundary" class="p-0 m-0 shadow-none">
                      <img class="ml-1 mr-1"  style="width: 25px" src="/img/icons/attach-green.png">
                    </base-button>
                  </div>
                </div>
                <div class="col-auto row m-0 px-2 mt-1">
                  <base-button
                    v-if="item.document_quantity > 0"
                    @click.prevent="handleModalListEntriesLaunches(item.bill_pay_id)"
                    outline
                    slot="reference"
                    size="sm"
                    type="secondary"
                    class="p-0 m-0 shadow-none d-inline"
                    style="border-color:#606062"
                  >
                  <div class="row m-0 pt-1 pb-1 pr-2 pl-2 d-flex align-items-center">
                    <img style="width: 22px;" src="/img/icons/list--v1-indigo-22.png">
                    <small  class="ml-2 font-weight-bold d-flex text-default">{{ item.document_quantity > 1 ?  `Agrupadas (${item.document_quantity })` : item.document_numbers}}</small>
                  </div>
                  </base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <loading-pagination :show="loading && !loadingSkeleton"/>
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="index in 3" :key="index"/>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import LoadingPagination from "@/components/LoadingPagination";

export default {
  name: "CardsBillPay",
  props: {
    loading: Boolean,
    bills_items: Array,
    paid_allotment: Boolean,
    paid_allotments: Array,
    loadingSkeleton: Boolean,
    loadingInstallment: Boolean,
    getInstallmentStatusIcon: { type: Function },
    availableStatuses: { type: Function },
    bill_pay_installment_status: Object,
    handleOpenModalPaidAllotments: { type: Function },
    handleHistory: { type: Function },
    handleEditBillPay: { type: Function },
    handleDeleteBillPayInstallment: { type: Function },
    handleModalShareInstallment: { type: Function },
    handleModalInfo: { type: Function },
    handleAgroup: { type: Function },
    handleModalListEntriesLaunches: { type: Function },
    handleModalAttachment: { type: Function },
    getPaymentAuthorizationProof: { type: Function },
    handleEntityAccount: { type: Function },
    changeInstallmentStatus: { type: Function },
    addSetPaidAllotments: { type: Function },
    removeAgroup: { type: Function },
    getOccurrence: { type: Function },
    handleModalBillPayInstallmentAttachment: { type: Function },
  },
  components: {
    LoadingPagination,
    SkeletonPuzlGrid
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped>
.main-icon-menu {
  min-width: 18px;
}

.custom-border-radius .fa-bars {
  border-radius: 1.375rem !important;
}

.paid-allotment-float {
  position: fixed;
  bottom: 150px;
  right: 40px;
  background-color: #149e57 !important;
  text-align: center;
  -webkit-box-shadow: 2px 2px 3px #999;
  box-shadow: 2px 2px 3px #999;
}
</style>
