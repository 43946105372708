<template>
  <div>
    <modal :show.sync="modal" size="md">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div class="p-3">
        <form
          class="needs-validation"
          @submit.prevent="handleSubmit(save)"
          autocomplete="off"
        >
          <div class="form-group row m-0 p-0 mb-4">
            <label
              class="col-md-5 pb-0 col-form-label form-control-label pb-2 pt-1 pl-0"
            >
              Data do pedido
              <span class="text-danger">&nbsp;*&nbsp;</span>
            </label>
            <div class="col-md-7">
              <base-input input-group-classes="input-group-sm">
                <input-date-time-picker :disableDate="new Date()" :spaceMinutes="15"  v-model="schedule.concreting_date" :size="'lg'" />
              </base-input>
            </div>
          </div>
          <div
            style="margin-bottom:0!important"
            class="d-flex justify-content-center m-3 mb-0"
          >
            <div class="pr-3">
              <base-button type="secondary" @click="closeModal()"
                >Cancelar</base-button
              >
            </div>
            <div class="pl-3">
              <base-button
                type="success"
                :loading="loadingSave"
                @click="save(1)"
                >Salvar</base-button
              >
            </div>
          </div>
        </form>
      </div>
    </modal>
    <ModalTravelDistribution
      @distributionGeneratedReschedule="setTravelsReschedule"
      ref="travelDistribution"
      @endLoad="endLoading"
    />
  </div>
</template>

<script>
import moment from "moment";
import ModalTravelDistribution from "./_ModalTravelDistribution";
import { formatDate, formatErrorValidation } from "@/plugins";
import InputDateTimePicker from "@/components/InputDateTimePicker";

export default {
  components: {
    ModalTravelDistribution, 
    InputDateTimePicker
  },
  name: "ModalReschedule",
  data() {
    return {
      modal: false,
      title: "Reagendar Programação",
      loadingSave: false,
      params: {},
      companies_distances: [],
      concreted_pieces: [],
      mix_design: {},
      schedule: {
        concreting_date: null
      }
    };
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    finalFormat(schedule) {
      schedule.mix_concreted_piece_uuid = this.concreted_pieces.filter(
        item => item.id === schedule.piece_id
      )[0].uuid;
      schedule.time_construction_stay = this.mix_design.formulation_cycle.time_construction_stay;
      schedule.interval_releases = this.mix_design.formulation_cycle.interval_releases;
      schedule.interval_adjustment = this.mix_design.formulation_cycle.interval_adjustment;
      schedule.contract_proposal_formulation_uuid = this.mix_design.uuid;
      schedule.company_plants = this.schedule.companies_distances;

      schedule.travels.map(function(obj, key) {
        obj.arrival_time = formatDate(obj.arrival_time);
        obj.construction_exit_time = formatDate(obj.construction_exit_time);
        obj.free_for_travel = formatDate(obj.free_for_travel);
        obj.load_truck_time = formatDate(obj.load_truck_time);
        obj.construction_id = schedule.contract_proposal.construction_id;
        obj.customer_construction_id = schedule.contract_proposal.customer_id;
      });

      let new_services = {};
      schedule.services.map(function(obj, key) {
        let start = schedule.travels[0].arrival_time
        let end = schedule.travels[schedule.travels.length -1].construction_exit_time
        let service = {
          volume: obj.volume,
          start_at: start,
          end_at: end,
          equipment_uuid: obj.equipment ?? null,
          employers: obj.employers,
          service_uuid: obj.service.uuid,
          company_plant_uuid: obj.company_plant_uuid,
          company_plant_issuer_id: obj.company_plant_issuer_id
        };
        new_services[obj.uuid] = service;
      });
      schedule.services_uuid = new_services;

      return schedule;
    },
    openModal(
      schedule,
      params,
      companies_distances,
      mix_design,
      concreted_pieces
    ) {
      this.concreted_pieces = concreted_pieces;
      this.params = JSON.parse(JSON.stringify(params));
      this.mix_design = mix_design;
      this.companies_distances = JSON.parse(
        JSON.stringify(companies_distances)
      );
      this.schedule = JSON.parse(JSON.stringify(schedule));
      this.schedule.concreting_date = moment().format("YYYY-MM-DD HH:mm:ss");
      this.schedule.status = this.schedule.status === 2  || this.schedule.status === 3 ?  2 : 14;
      this.modal = true;
    },
    setTravelsReschedule(travels, time, condition) {
      this.schedule.travels = travels;
      this.save(0);
    },
    endLoading() {
      this.loadingSave = false;
    },
    async save(forward = 0) {
      if (forward === 1) {
        this.$Progress.start();
        this.loadingSave = true;
      }

      this.params.concreting_date = this.schedule.concreting_date;
      if (this.schedule.travels.length === 0) {
        await this.$refs.travelDistribution.onlyCalcTravelsReschedule(
          this.params,
          this.companies_distances
        );
        return false;
      }
      let schedule = this.finalFormat(this.schedule);
      let companies = JSON.parse(JSON.stringify(schedule.company_plants));
      companies.map(function(item) {
        delete item.label;
        delete item.uuid;
      });
      delete schedule.contract_proposal_formulation;
      schedule.company_plants = companies;
      this.$store
        .dispatch("schedule/reschedule", schedule)
        .then(response => {
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.$router.push(this.$parent.bySeller ? "/operational/contract-proposal/schedule/" + this.schedule.contract_proposal_uuid : "/operational/schedule");
        })
        .catch(error => {
          if (error.status) {
            this.$notify({
              type: "danger",
              message: error.data.message
            });
            this.$Progress.finish();
            this.loadingSave = false;
          } else if (
            error.response &&
            error.response.status &&
            error.response.status === 422
          ) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors
            });
            this.$Progress.finish();
            this.loadingSave = false;
          }
        });
    }
  },
  created() {}
};
</script>

<style></style>
