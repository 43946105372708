<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group row m-0 mb-1">
            <label class="col-md-6 pb-0 mt-n2 col-form-label form-control-label">
              Saldo de antecipação
            </label>
            <div class="col-md-6">
              <base-input disabled input-group-classes="input-group-sm">
                <input v-money="money"
                       @keydown="$event.key === '-' ? $event.preventDefault() : null"
                       inputmode="numeric"
                       disabled
                       :value="Number(Number(this.balance).toFixed(2) - this.verifyToPaid().toFixed(2)).toFixed(2)"
                       type="text"
                       class="form-control form-control-sm numeric"/>
                <template slot="prepend">
                  <small class="input-group-sm p-0 m-0"> R$ </small>
                </template>
              </base-input>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="border-header-primary-bottom mb-2">
            <h5 class="h3 p-1 mb-0">Pagamentos</h5>
          </div>
          <div class="form-group row m-0 mb-1" v-for="item in installments">
            <label class="col-md-6 pb-0 mt-n2 col-form-label form-control-label">
              Parcela {{ item.index }}
            </label>
            <div class="col-md-6">
              <base-input input-group-classes="input-group-sm">
                <input v-money="money" maxlength="13"
                       @keydown="$event.key === '-' ? $event.preventDefault() : null"
                       inputmode="numeric"
                       disabled
                       v-model.lazy="item.total_value"
                       type="text"
                       class="form-control form-control-sm numeric"/>
                <template slot="prepend">
                  <small class="input-group-sm p-0 m-0">
                    R$
                  </small>
                </template>
              </base-input>
            </div>
            <label class="col-md-6 pb-0 mt-n1 col-form-label form-control-label">
              Valor
            </label>
            <div class="col-md-6 mt-1">
              <base-input input-group-classes="input-group-sm">
                <input v-money="money" maxlength="13"
                       @input="changeInstallmentValue()"
                       @keydown="$event.key === '-' ? $event.preventDefault() : null"
                       inputmode="numeric"
                       v-model.lazy="to_paid"
                       type="text"
                       class="form-control form-control-sm numeric"/>
                <template slot="prepend">
                  <small class="input-group-sm p-0 m-0">
                    R$
                  </small>
                </template>
              </base-input>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <base-button type="secondary" @click="closeModal()">
          Cancelar
        </base-button>
        <base-button
          :disabled="Boolean(verifyToPaid() == 0)"
          @click.native="save()"
          type="success"
          native-type="submit"
          title="Salvar"
          class="btn-custom"
          :class="loadingSave && 'btn-loading'">
          <span v-show="!loadingSave" class="btn-label span-custom"><i
            class="fas fa-save text-white icon-custom"></i></span>
          <span v-show="loadingSave" class="btn-label span-custom"><i
            class="fas fa-spinner text-white fa-spin icon-custom"></i></span>
          Salvar
        </base-button>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  VMoney
} from "v-money";
import {mapGetters} from "vuex";
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "ModalShareInstallment",
  components: {
    PuzlSelect,
  },
  data() {
    return {
      modal: {
        title: 'Antecipação',
        create: false,
      },
      balance: 0,
      total_value: 0,
      new_installments: [],
      sum_is_bigger_than_installment: 0,
      number_installments: 0,
      sum_another_installments: 0,
      first_installment_is_zero: 0,
      installments: [],
      to_paid: 0,
      installment: undefined,
      loadingSave: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false
      },
    }
  },
  directives: {
    money: VMoney
  },
  computed: {
    ...mapGetters({})
  },
  watch: {
    to_paid: function (a, b) {
      a = Number(a.toString().replaceAll(".", "").replace(",", ".")).toFixed(2)
      if (Number(a) > Number(this.balance).toFixed(2)) {
        return this.$nextTick(function () {
          this.$notify({
            type: 'warning',
            message: 'O valor antecipado não pode ser maior do que o saldo de antecipação'
          })
          this.to_paid = b
        })
      }
      if (Number(a) > this.total_value) {
        return this.$nextTick(function () {
          this.$notify({
            type: 'warning',
            message: 'O valor antecipado não pode ser maior do que o valor da parcela'
          })
          this.to_paid = b
        })
      }
    }
  },
  methods: {
    verifyToPaid() {
      return Number(this.to_paid.toString().replaceAll(".", "").replace(",", "."))
    },
    closeModal() {
      this.modal.create = false
    },
    save() {
      this.$emit('updateInstallments', {
        old_installment: this.installment,
        installments: this.new_installments,
        shared_count: this.number_installments,
        latest_index: this.installment.index,
        due_date: this.installment.due_date
      })
      this.modal.create = false
    },
    updatedInstallments() {
      this.loadingSave = false
      this.closeModal()
      this.$notify({
        type: 'success',
        message: 'Antecipação de parcelas realizada com sucesso!'
      })
    },
    changeInstallmentValue() {
      const to_paid = this.verifyToPaid().toFixed(2)
      this.new_installments = [
        {
          index: this.installment.index,
          value: Number(this.installment.value.replaceAll(".", "").replace(",", ".")).toFixed(2),
          total_value: Number(Number(to_paid)).toFixed(2),
          status: 11
        },
      ]
      if (Number(this.total_value.toFixed(2)) > Number(this.new_installments[0].total_value)) {
        this.new_installments[1] = {
          index: this.installment.index + 1,
          value: Number(this.total_value.toFixed(2) - Number(this.new_installments[0].total_value)).toFixed(2),
          total_value: this.installment.total_value
        }
        this.new_installments[0].value = Number(Number(this.new_installments[0].value) - Number(this.new_installments[1].value)).toFixed(2)
      }
      this.number_installments = 2
    },
    openModal(params) {
      this.installment = params.installment
      this.number_installments = 2
      this.sum_another_installments = 0
      this.balance = params.balance
      this.to_paid = 0
      /**
       * pega o valor total a ser considerado pelo saldo de antecipação (sempre valor final da parcela)
       * @type {number}
       */
      this.total_value = Number(this.installment.total_value.replaceAll(".", "").replace(",", "."))
      /**
       * Cria a primeira parcela a ser antecipada
       * @type {[{total_value, index, value}]}
       */
      this.installments = [
        {
          index: this.installment.index,
          value: Number(this.installment.value.replaceAll(".", "").replace(",", ".")),
          total_value: this.installment.total_value,
        }
      ]
      const final_value = Number(this.installment.total_value.replaceAll(".", "").replace(",", "."))
      if (parseFloat(Number(this.balance).toFixed(2)) >= parseFloat(Number(final_value).toFixed(2))) {
        this.to_paid = final_value.toLocaleString('pt-BR', {minimumFractionDigits: 2})
      }
      this.$forceUpdate()
      this.modal.create = true
    },
  },
}
</script>

<style scoped>

</style>
