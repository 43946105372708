<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid }">
          <div>
            <!-- Plano de contas -->
            <div class="form-group row m-0 p-0">
              <label class="col-md-5 pb-0 pt-2 mb-0 col-form-label form-control-label pr-0">
                Plano de contas
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7 pr-4">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                    <puzl-select
                      v-model="chart_account_id"
                      :items="$_chart_accounts"
                      placeholder="Plano de Contas"
                      :clearable="false"
                      :labelMask="true"
                      label="$code$ $name$"
                      :selectable="(i) => i.extra_field.length"
                      extraField="parents"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()"> Cancelar </base-button>
              <base-button type="success" @click="store()" v-bind:disabled="invalid"> Salvar </base-button>
            </div>
          </div>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
export default {
  name: "ModalSetChartAccount",
  components: { PuzlSelect },
  data() {
    return {
      modal: {
        title: "Plano de Contas",
        create: false,
      },
      chart_account_id: null,
    };
  },
  computed: {
    ...mapGetters({
      $_chart_accounts: "chartAccount/fetch",
    }),
  },

  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal() {
      this.modal.create = true;
    },
    store() {
      this.$emit("store", this.chart_account_id);
      this.closeModal();
    },
  },
};
</script>
