<template>
  <div class="mt-4">
    <section class="header d-flex mr-2" style="gap: 10px;">
      <div class="schedule-card-header w-98">
        <div class="mx-3 my-2">
          <span class="d-flex schedule-card-header-font" style="gap: 9px;">
            <img src="/img/icons/icons8/ios/today_black.png" alt="today_black" width="24px">
            Dia
          </span>
        </div>
      </div>
      <div class="schedule-card-header w-68 align-content-center">
        <span class="schedule-card-header-font d-flex justify-content-center">
          Hora de início
        </span>
      </div>
      <div class="schedule-card-header w-68 align-content-center">
        <span class="schedule-card-header-font d-flex justify-content-center">
          Distância
        </span>
      </div>
      <div class="schedule-card-header w-68 align-content-center">
        <span class="schedule-card-header-font d-flex justify-content-center">
          Concreto
        </span>
      </div>
      <div class="schedule-card-header w-68 align-content-center">
        <span class="schedule-card-header-font d-flex justify-content-center">
          Bomba
        </span>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ScheduleHeader",
}
</script>

<style scoped>
.schedule-card-header {
  background-color: #C6D2E5;
  border: 1px solid #E8E8E8;
  border-radius: 8px;
  box-shadow: 0px 5px 10px 0px #0000001A;
}
.schedule-card-header-font {
  font-size: 18px;
  font-weight: 400;
  color: #2B2D32;
}
.w-68 {
  width: 68%;
}
.w-98 {
  width: 98%;
}
</style>