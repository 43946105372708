import Swal from "sweetalert2";
import Vue from "vue";

/** TODO: Fazer melhorias quando possível */

/** Ícones para notificação */
const TYPE_ENUM = Object.freeze({
  DANGER: 'danger',
  ERROR: 'error',
  INFO: 'info',
  QUESTION: 'question',
  SUCCESS: 'success',
  WARNING: 'warning',
});

/**
 * Confirmar remoção de registro
 *
 * @param {string} text - O texto a ser exibido no diálogo de confirmação.
 * @param {string} confirmButtonText - O texto para o botão de confirmação.
 * @returns {Promise<boolean>}
 */
const confirmRemove = async (
  text = "Você não poderá recuperar este registro!",
  confirmButtonText = "Sim, apague isto!"
) => {
  const result = await Swal.fire({
    title: "Você tem certeza?",
    text: text,
    icon: TYPE_ENUM.WARNING,
    showCancelButton: true,
    confirmButtonText: confirmButtonText,
    cancelButtonText: "Não, mantenha",
    customClass: {
      confirmButton: "btn btn-success btn-fill",
      cancelButton: "btn btn-danger btn-fill",
    },
    buttonsStyling: false,
  });
  return result.isConfirmed;
};

/**
 * Confirmar ação
 *
 * @param {string} text - O texto a ser exibido no diálogo.
 * @param {Array<string>} options - As opções para os botões do diálogo.
 * @returns {Promise<boolean>} - Uma promessa que resolve para true se o botão de confirmação for clicado, false caso contrário.
 */
const confirmAction = async (text, options = null) => {
  const result = await Swal.fire({
    title: 'Você tem certeza?',
    text: text,
    icon: TYPE_ENUM.WARNING,
    showCancelButton: true,
    confirmButtonText: options ? options[0] : 'Tenho certeza e desejo continuar',
    cancelButtonText: options ? options[1] : 'Fechar',
    customClass: {
      confirmButton: 'btn btn-success btn-fill',
      cancelButton: 'btn btn-danger btn-fill',
    },
    buttonsStyling: false,
  });

  return result.isConfirmed;
};

/**
 * Confirmar ação com negado
 *
 * @param {string} text - O texto a ser exibido no diálogo.
 * @param {Array<string>} options - As opções para os botões do diálogo.
 * @param {string} title - O título do diálogo.
 * @returns {Promise<boolean>} - Uma promessa que resolve para true se o botão de confirmação for clicado, 
 *                               false se o botão de negação for clicado, ou undefined se o diálogo for fechado sem interação.
 */
const confirmActionWithDenied = async (text, options = null, title = 'Você tem certeza?') => {
  const result = await Swal.fire({
    title: title,
    text: text,
    icon: TYPE_ENUM.WARNING,
    showDenyButton: true,
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonText: options ? options[0] : 'Tenho certeza e desejo continuar',
    denyButtonText: options ? options[1] : 'Não',
    cancelButtonText: options ? options[2] : 'Cancelar',
    customClass: {
      confirmButton: 'btn btn-success btn-fill',
      cancelButton: 'btn btn-secondary btn-fill',
      denyButton: 'btn btn-danger btn-fill',
    },
    buttonsStyling: false,
  });

  return result.isConfirmed;
};


/**
 * Exibe uma notificação
 *
 * @param {string} type - Icone da notificação. Padrão é TYPE_ENUM.SUCCESS. Utilize o enum TYPE_ENUM.
 * @param {string} message - A mensagem da notificação. Default=Operação realizada com sucesso.
 */
const notify = (type = TYPE_ENUM.SUCCESS, message = 'Operação realizada com sucesso.') => {  
  const displayMessage = message.trim() === '' 
    ? 'Ocorreu um erro, por favor tente novamente.' 
    : message;
  Vue.prototype.$notify({ type: type, message: displayMessage });
}

/**
 * Exibe uma notificação de remoção
 *
 * @param {string} type - Icone da notificação. Padrão é TYPE_ENUM.SUCCESS. Utilize o enum TYPE_ENUM.
 * @param {string} message - A mensagem da notificação. Default=Remoção realizada com sucesso.
 */
const notifyRemoved = (type = TYPE_ENUM.SUCCESS, message = 'Remoção realizada com sucesso.') => {
  Vue.prototype.$notify({ type: type, message: message });
}

export default {
  confirmRemove,
  confirmActionWithDenied,
  confirmAction,
  notify,
  notifyRemoved,
  TYPE_ENUM,
}