<template>
  <div>
    <modal @close="close" :show.sync="show">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(submitAndClose)" autocomplete="off">            
            
              <!-- CAMPO STATUS -->
              <div>
                <div class="form-group row m-0 p-0">
                  <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                    Status
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-7 pt-2">
                    <base-switch type="success" offText="inativo" onText="ativo" class="success"
                                 v-model="bankAccount.status"></base-switch>
                  </div>
                </div>
              </div>
              <!-- CAMPO NAME -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0  col-form-label form-control-label">
                  Nome da conta
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7  ">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="bankAccount.name"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Código externo -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0  col-form-label form-control-label">
                  Cód. Externo
                </label>
                <div class="col-md-7">
                  <base-input input-classes="form-control-sm">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="bankAccount.external_code"
                      maxlength="50"
                    />
                  </base-input>
                </div>
              </div>
              <div class="card-header border-header-primary-bottom mt-3 mb-2 p-2">
                <h5 class="h4 mb-0" slot="title">Dados do banco</h5>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Banco
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7 mb-1">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <puzl-select 
                        v-model="bankAccount.bank_id" 
                        :items="banks"
                        :labelMask="true"
                        label="$code$ - $name$"
                        :loading="loadingBanks"
                        :disabled="loadingBanks" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>              
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Agência sem dígito
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7 mb-1">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="bankAccount.number_without_digit_agency"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- CAMPO DIGITY AGENCY -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Dígito
                </label>
                <div class="col-md-7 mb-1">
                  <base-input input-classes="form-control-sm">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="bankAccount.digit_agency"
                    />
                  </base-input>
                </div>
              </div>
              <div class="card-header border-header-warning-bottom mt-3 mb-2 p-2">
                <h5 class="h4 mb-0" slot="title">Dados da conta</h5>
              </div>
              <!-- CAMPO NUMBER WITHOUT DIGIT ACCOUNT -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Número sem dígito
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7 mb-1">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="bankAccount.number_without_digit_account"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- CAMPO DIGIT ACCOUNT -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Dígito
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7 mb-1">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="bankAccount.digit_account"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- CAMPO ACCOUNT TYPE -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Tipo de conta
                </label>
                <div class="col-md-7 mb-1 ">
                  <base-input input-classes="form-control-sm">
                    <input type="text" class="form-control form-control-sm" v-model="bankAccount.account_type"/>
                  </base-input>
                </div>
              </div>
              <!-- CAMPO BALANCE -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Saldo
                </label>
                <div class="col-md-7 mb-1 ">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      @blur="generateInstallments(1)"
                      v-money="money" maxlength="13"
                      inputmode="numeric"
                      v-model.lazy="bankAccount.balance"
                      type="text"
                      class="form-control form-control-sm"/>
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0">
                        R$
                      </small>
                    </template>
                  </base-input>
                </div>
              </div>
              <!-- CAMPO OBSERVATION -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Observações
                </label>
                <div class="col-md-7 mb-1 ">
                  <base-input input-group-classes="input-group-sm">
                                <textarea type="text" rows="2" class="form-control form-control-sm"
                                          v-model="bankAccount.observation">
                  </textarea>
                  </base-input>
                </div>
              </div>            
            <!-- MODAL FOOTER -->
            <div class="modal-footer">
              <base-button type="secondary" @click="close()">
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" :disabled="invalid" :loading="isLoading">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {VMoney} from "v-money";
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "ModalCreate",
  props: ["show"],
  components: { PuzlSelect },
  data() {
    return {
      title: "Cadastrar Conta Bancária",
      bankAccount: {
        name: null,
        status: true,
        bank_id: null,
        number_without_digit_agency: '',
        digit_agency: null,
        number_without_digit_account: '',
        digit_account: '',
        account_type: null,
        balance: null,
        observation: null,
        external_code: null,
      },
      isLoading: false,
      loadingBanks: true,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false
      },
    };
  },
  mounted() {
    this.$refs.formValidator.validate()
    this.$store.dispatch('bank/fetchItems').then(() => {
      this.loadingBanks = false;
    })
  },
  directives: {
    money: VMoney
  },
  computed: {
    ...mapGetters({
      'banks': 'bank/fetch'
    }),
  },
  watch: {
    show: function () {
      this.bankAccount = {
        name: null,
        status: true,
        bank_id: null,
        number_without_digit_agency: '',
        digit_agency: null,
        number_without_digit_account: '',
        digit_account: '',
        account_type: null,
        balance: null,
        observation: null,
        external_code: null,
      }
    }
  },
  methods: {
    cleanBankAccount() {
      this.bankAccount = {
        name: null,
        status: true,
        bank_id: null,
        number_without_digit_agency: '',
        digit_agency: null,
        number_without_digit_account: '',
        digit_account: '',
        account_type: null,
        balance: null,
        observation: null,
        external_code: null,
      }
    },
    close() {
      this.cleanBankAccount();
      this.$emit("close");
    },
    submitAndClose() {
      this.$Progress.start()
      this.isLoading = true
      const payload = this.formatBeforeSave({...this.bankAccount});
      this.$store.dispatch('bankAccount/add', payload)
        .then(response => {
          this.isLoading = false
          this.close();
          this.$Progress.finish()
          this.$notify({
            type: response.error_type,
            message: response.message
          })
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            let errors = Object.values(error.response.data.errors)
            errors = errors.flat().toString().split(',').join("<br />")
            this.$notify({type: 'danger', message: errors})
          }
          else {
            this.$notify({type: 'danger', message: error.data.message})
          }
          this.$Progress.finish()
          this.isLoading = false
        })
    },
    formatBeforeSave(payload){
      payload.balance = this.$helper.strToNum(payload.balance);
      return payload;
    }
  },
};
</script>

<style>
mark {
  border-radius: 5%;
  background-color: #f7f7f7;
  color: #df2068;
}
</style>
