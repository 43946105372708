import dreStoreActions from './dreStoreActions';
import dreStoreGetters from './dreStoreGetters';
import dreStoreMutations from './dreStoreMutations';
import { dreStateKeys } from "./dreStateKeys";

const dreStore = {
  ...dreStoreActions,
  ...dreStoreGetters,
  ...dreStoreMutations,
  dreStateKeys,
};

export default dreStore;
