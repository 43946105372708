import DRE from "../views/list/DreListPage.vue";

export default [
  {
    path: '/administrative/dre',
    name: 'DRE ANUAL',
    component: DRE,
    meta: {
      title: 'Gerencial',
      description: 'DRE Anual',
      icon: 'fa fa-home',
      permission: 'administrative.dre.index.view',
      breadcrumbs: [
        {title: 'Dashboard', name: 'administrative.dashboard.index'},
        {title: 'DRE Anual', name: null},
      ]
    },
  },
];
