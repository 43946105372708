export const SET = 'SET'
export const SET_PAGINATE = 'SET_PAGINATE'
export const PUSH = 'PUSH'
export const SHOW = 'SHOW'
export const DESTROY = 'DESTROY'
export const UPDATE = 'UPDATE'
export const SET_NEXT_PAGE = 'SET_NEXT_PAGE'
export const SET_ENTITIES = 'SET_ENTITIES'
export const SET_HISTORY = 'SET_HISTORY'
export const SET_NEXT_PAGE_DATA = 'SET_NEXT_PAGE_DATA'
export const SET_SALES = 'SET_SALES'
