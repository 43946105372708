<template>
  <div>
    <modal class="bill-modal-create" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <div>
          <div class="row mb-2 p-0 pr-2 pl-2">
            <div class="col-md-4 mb-1">
              <base-button size="sm" :class="type === 1 && 'active'" @click.prevent="type = 1" block outline type="primary"
                >Dados
              </base-button>
            </div>
            <div class="col-md-4">
              <base-button size="sm" :class="type === 3 && 'active'" @click.prevent="type = 3" block outline type="primary"
                >Documentos
              </base-button>
            </div>
            <div class="col-md-4 mb-1">
              <base-button
                size="sm"
                :class="type === 2 && 'active'"
                :disabled="payload.installments.length < 1 || payload.bill_pay.total_value === '0,00'"
                @click.prevent="type = 2"
                block
                outline
                type="primary"
                >Parcelas
              </base-button>
            </div>
          </div>
          <div v-if="entity" class="border-left border-3 border-danger pr-1 mt-3 mb-2">
            <div class="col-md-12">
              <h4 class="m-0">{{ entity.entity_name }}</h4>
              <h4 class="m-0 font-weight-normal">{{ entity.document }}</h4>
            </div>
          </div>
          <div v-if="hasPaidInstallment === false" class="form-group row m-0 p-0 mb-3 mt-3">
            <div class="col-md-6">
              <base-button
                type="indigo"
                outline
                @click.prevent="changeAccumulation"
                :class="{ active: payload.bill_pay.accumulation === 1 }"
              >
                <i style="font-size: 17px !important" class="fas fa-history text-center" /> Acumular
              </base-button>
            </div>
          </div>
          <hr v-show="type !== 1 && hasPaidInstallment === false" class="mb-4 mt-3 pb-1 ml-3 mr-3" />
          <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
            <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
              <div>
                <section v-show="type == 1">
                  <div class="border-header-success-bottom mt-3 mb-2">
                    <h5 class="h4 p-1 mb-0">Identificação</h5>
                  </div>
                  <div class="form-group row m-0 p-0 mb-1 limited-row">
                    <label class="col-md-6 pb-1 pt-2 col-form-label form-control-label">
                      Data
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <validation-provider rules="required">
                        <base-input input-group-classes="input-group-sm">
                          <input-date-time-picker @input="changeInstallments(false)" :mode="'date'" v-model.lazy="payload.bill_pay.date" />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-0">
                    <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label">
                      Ocorrência <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <base-input v-if="Number.isInteger(payload.bill_pay.occurrence)" input-classes="form-control-sm">
                        <el-select v-model="payload.bill_pay.occurrence" size="mini" placeholder="Selecione" filterable>
                          <el-option
                            v-for="(occurrence, occurrenceIndex) in occurrences"
                            :key="occurrenceIndex"
                            :label="occurrence.label"
                            :value="occurrence.value"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                      <div v-else class="col-12 p-0">
                        <base-input input-group-classes="input-group-sm">
                          <input
                            v-on:input="payload.bill_pay.occurrence = formatString($event.target.value, '0', '90')"
                            @blur="checkOccurrence"
                            type="text"
                            v-model="payload.bill_pay.occurrence"
                            class="form-control form-control-sm"
                          />
                          <template slot="append">
                            <small class="input-group-sm p-0 m-0"> dias </small>
                          </template>
                        </base-input>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0 mb-1 limited-row">
                    <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label">
                      Valor total (a pagar)
                      <span class="text-danger">&nbsp;*&nbsp;</span>
                    </label>
                    <div class="col-md-6">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          @keydown="$event.key === '-' ? $event.preventDefault() : null"
                          @blur="changeInstallments(false)"
                          v-money="money"
                          maxlength="13"
                          inputmode="numeric"
                          v-model.lazy="payload.bill_pay.total_value"
                          type="text"
                          class="form-control form-control-sm"
                          :disabled="payload.entries_launches && payload.entries_launches.length > 0"
                        />
                        <template slot="prepend">
                          <small class="input-group-sm p-0 m-0"> R$ </small>
                        </template>
                      </base-input>
                    </div>
                  </div>
                  <div class="form-group row ml-0 mr-0 mt-2 p-0 mb-1 limited-row">
                    <label class="col-md-6 pt-2 pb-0 col-form-label form-control-label">
                      Valor final
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          disabled
                          v-money="money"
                          maxlength="13"
                          inputmode="numeric"
                          :value="finalValue"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <template slot="prepend">
                          <small class="input-group-sm p-0 m-0"> R$ </small>
                        </template>
                      </base-input>
                    </div>
                  </div>
                  <hr class="m-0 mb-2 mt-3 pb-1 ml-3 mr-3" />
                  <div class="form-group row m-0 p-0 mb-1 limited-row">
                    <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label"> Central
                       <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <el-select
                          @change="changedCompanyPlant(payload.bill_pay)"
                          v-model="payload.bill_pay.company_plant_id"
                          size="mini"
                          placeholder="Selecione"
                          filterable
                          :disabled="loadingCompanyPlant"
                        >
                          <el-option
                            v-for="company_plant in $_company_plants"
                            :key="company_plant.id"
                            :label="company_plant.name"
                            :value="company_plant.id"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-1 limited-row">
                    <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label"> CNPJ Comprador
                       <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                       <el-select
                          v-model="payload.bill_pay.company_plant_buyer_id"
                          size="mini"
                          placeholder="Selecione"
                          filterable
                          :disabled="loadingCompanyPlantBuyer"
                          @change="getDocuments(payload.bill_pay)"
                        >
                          <el-option
                            v-for="company_plant_buyer in company_plant_buyers"
                            :key="company_plant_buyer.id"
                            :label="company_plant_buyer.issuer.ein"
                            :value="company_plant_buyer.id"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-1 limited-row">
                    <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label"> Tipo
                       <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <el-select
                            :disabled="loadingProductServiceHubType"
                            filterable
                            v-model="payload.bill_pay.product_service_hub_type_id"
                            size="mini"
                            placeholder="Selecione"
                          >
                            <el-option label="MCC" :value="2"> </el-option>
                            <el-option v-for="type in $_product_service_hub_types" :key="type.id" :label="type.name" :value="type.id">
                            </el-option>
                          </el-select>
                      </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-1 limited-row">
                    <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label"> Conta bancária  <span class="text-danger">&nbsp;*</span> </label>
                    <div class="col-md-6">
                      <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <el-select
                          v-model.lazy="payload.bill_pay.bank_account_id"
                          size="mini"
                          placeholder="Selecione"
                          filterable
                          :disabled="loadingBankAccount"
                        >
                          <el-option
                            v-for="bank_account in bank_accounts"
                            :key="bank_account.bank_account_id"
                            :label="bank_account.bank_account.name"
                            :value="bank_account.bank_account_id"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-1 limited-row">
                    <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label"> Método de pagamento </label>
                    <div class="col-md-6">
                      <base-input input-classes="form-control-sm">
                        <el-select
                          v-model.lazy="payload.bill_pay.payment_method_id"
                          size="mini"
                          placeholder="Selecione"
                          filterable
                          :disabled="loadingPaymentMethod"
                        >
                          <el-option v-for="method in $_payment_methods" :key="method.id" :label="method.name" :value="method.id">
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0">
                    <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label"> Descrição </label>
                    <div class="col-md-6">
                      <base-input input-group-classes="input-group-sm">
                        <input type="text" v-model="payload.bill_pay.description" class="form-control form-control-sm" />
                      </base-input>
                    </div>
                  </div>
                </section>

                <section class="mt-3" v-show="type == 2">
                  <div v-if="payload.installments.length">
                    <div class="form-group row m-0 mt-2 mb-4 p-0">
                      <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label">
                        Quantidade de Parcelas <span class="text-danger">&nbsp;*</span>
                      </label>
                      <div class="col-md-6">
                        <base-input input-group-classes="input-group-sm">
                          <input
                            inputmode="numeric"
                            v-on:input="quantity_installments = formatString($event.target.value, '0', '99')"
                            v-model.lazy="quantity_installments"
                            @blur="changeInstallments"
                            class="form-control form-control-sm"
                          />
                        </base-input>
                      </div>
                    </div>
                    <div v-for="(installment, indexInstallment) in paginatedInstallment" :key="indexInstallment">
                      <div class="form-group row m-0 mb-1 limited-row" :class="installment.index > 1 ? 'mt-4' : ''">
                        <label class="col-md-6 pt-2 pb-0 pt-1 col-form-label form-control-label font-weight-bold">
                          Parcela {{ installment.index }}
                          <span class="text-danger">&nbsp;*</span>
                        </label>
                        <div class="col-md-6">
                          <base-button
                            block
                            size="sm"
                            :type="bill_pay_types[installment.status].color"
                            class="text-uppercase"
                          >
                            {{ bill_pay_types[installment.status].text }}
                          </base-button>
                        </div>
                      </div>
                      <div class="form-group row m-0 p-0 mb-1 limited-row mt-2">
                        <label class="col-md-6 pb-1 pt-2 col-form-label form-control-label">
                          Data de vencimento
                          <span class="text-danger">&nbsp;*</span>
                        </label>
                        <div class="col-md-6">
                          <validation-provider rules="required">
                            <base-input input-group-classes="input-group-sm">
                              <input-date-time-picker
                                @input="installment.original_due_date = installment.due_date + ' 00:00:00'"
                                :mode="'date'"
                                v-model="installment.due_date"
                              />
                            </base-input>
                          </validation-provider>
                        </div>
                      </div>
                      <div v-show="installment.status === 1" class="form-group row m-0 p-0 mb-1 limited-row">
                        <label class="col-md-6 pb-1 pt-2 col-form-label form-control-label"> Data do pagamento </label>
                        <div class="col-md-6">
                          <base-input input-group-classes="input-group-sm">
                            <input-date-time-picker :mode="'date'" v-model="installment.paid_at" />
                          </base-input>
                        </div>
                      </div>
                      <div class="form-group row m-0 p-0 mb-1 limited-row">
                        <label class="col-md-6 pt-2 pb-0 col-form-label form-control-label">
                          Valor total
                          <span class="text-danger">&nbsp;*</span>
                        </label>
                        <div class="col-md-6">
                          <base-input input-group-classes="input-group-sm">
                            <input
                             :disabled="indexInstallment === 0 || (payload.entries_launches && payload.entries_launches.length > 0)"
                              v-money="money"
                              maxlength="13"
                              inputmode="numeric"
                              v-model.lazy="installment.total_value"
                              @blur="changedInstallmentTotalValue($event.target.value, indexInstallment, payload.installments, payload.bill_pay)"
                              type="text"
                              class="form-control form-control-sm"
                            />
                            <template slot="prepend">
                              <small class="input-group-sm p-0 m-0"> R$ </small>
                            </template>
                          </base-input>
                        </div>
                      </div>
                      <div class="form-group row ml-0 mr-0 mt-2 p-0 mb-1 limited-row">
                        <label class="col-md-6 pt-2 pb-0 col-form-label form-control-label"> Descontos/Taxas </label>
                        <div class="col-md-6">
                          <base-input input-group-classes="input-group-sm">
                            <input
                              @keydown="$event.key === '-' ? $event.preventDefault() : null"
                              v-money="money"
                              maxlength="10"
                              inputmode="numeric"
                              @blur="calcFinalValue(installment)"
                              v-model.lazy="installment.discounts_fees"
                              class="form-control form-control-sm"
                            />
                            <template slot="prepend">
                              <small class="input-group-sm p-0 m-0"> R$ </small>
                            </template>
                          </base-input>
                        </div>
                      </div>
                      <div class="form-group row ml-0 mr-0 mt-2 p-0 mb-1 limited-row">
                        <label class="col-md-6 pt-2 pb-0 col-form-label form-control-label"> Juros/Multas </label>
                        <div class="col-md-6">
                          <base-input input-group-classes="input-group-sm">
                            <input
                              @keydown="$event.key === '-' ? $event.preventDefault() : null"
                              @blur="calcFinalValue(installment)"
                              v-money="money"
                              maxlength="10"
                              inputmode="numeric"
                              v-model.lazy="installment.fees_fines"
                              class="form-control form-control-sm"
                            />
                            <template slot="prepend">
                              <small class="input-group-sm p-0 m-0"> R$ </small>
                            </template>
                          </base-input>
                        </div>
                      </div>
                      <div class="form-group row ml-0 mr-0 mt-2 p-0 mb-1 limited-row">
                        <label class="col-md-6 pt-2 pb-0 col-form-label form-control-label">
                          Valor final
                          <span class="text-danger">&nbsp;*</span>
                        </label>
                        <div class="col-md-6">
                          <base-input input-group-classes="input-group-sm">
                            <input
                              disabled
                              v-money="money"
                              maxlength="13"
                              inputmode="numeric"
                              v-model.lazy="installment.final_value"
                              type="text"
                              class="form-control form-control-sm"
                            />
                            <template slot="prepend">
                              <small class="input-group-sm p-0 m-0"> R$ </small>
                            </template>
                          </base-input>
                        </div>
                      </div>
                      <div class="form-group row m-0 p-0 mb-1 limited-row">
                        <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label"> Conta bancária de crédito </label>
                        <div class="col-md-6 v-select-financial-no-hover-issue">
                          <base-input input-classes="form-control-sm">
                            <puzl-select
                              :clearable="false"
                              v-model="installment.bank_account_id"
                              :items="bank_accounts"
                              :label="['bank_account', 'name']"
                              :recursiveKey="['bank_account', 'id']"
                              :labelMask="true"
                            />
                          </base-input>
                        </div>
                      </div>
                      <div class="form-group row ml-0 mr-0 mt-2 p-0 mb-0">
                        <label class="col-md-4 pt-2 pb-0 col-form-label form-control-label">
                          Anexo
                          <span class="text-danger">&nbsp;*</span>
                        </label>
                        <div class="col-md-8">
                          <base-input input-group-classes="input-group-sm">
                            <InputFile ref="installmentAttachment" @input="installment.file = $event" :multiple="false" />
                          </base-input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-show="payload.installments && payload.installments.length > 1" class="p-3">
                    <pagination @navegate="handleInstallmentNavegate" :source="source" />
                  </div>
                </section>
                <section v-show="type == 3">
                  <div class="form-group row m-0 p-0 mb-1 limited-row">
                    <label class="col-md-6 pt-2 pb-0 col-form-label form-control-label"> Total (documentos) </label>
                    <div class="col-md-6">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          disabled
                          v-money="money"
                          maxlength="13"
                          inputmode="numeric"
                          v-model.lazy="payload.bill_pay.document_value"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <template slot="prepend">
                          <small class="input-group-sm p-0 m-0"> R$ </small>
                        </template>
                      </base-input>
                    </div>
                  </div>
                  <div class="form-group row ml-0 mr-0 mt-2 p-0 mb-1 limited-row">
                    <label class="col-md-6 pt-2 pb-0 col-form-label form-control-label"> Saldo </label>
                    <div class="col-md-6">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          disabled
                          v-money="money"
                          maxlength="13"
                          inputmode="numeric"
                          :value="balance"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <template slot="prepend">
                          <small class="input-group-sm p-0 m-0"> R$ </small>
                        </template>
                      </base-input>
                    </div>
                  </div>
                  <div class="form-group row ml-0 mr-0 mt-2 p-0 mb-1 limited-row custom-input-select">
                    <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label"> Lançamentos de despesas</label>
                    <div class="col-md-6">
                      <base-input input-classes="form-control-sm">
                        <el-select
                          :disabled="loadingEntryLaunch || ! payload.bill_pay.company_plant_buyer_id"
                          v-model="payload.entries_launches"
                          size="mini"
                          @change="calcDocumentValue"
                          placeholder="Selecione"
                          filterable
                          multiple
                          collapse-tags
                        >
                          <el-option
                            v-for="entry_launch in $_entries_launches"
                            :key="entry_launch.id"
                            :label="formatEntryLaunchLabel(entry_launch)"
                            :value="entry_launch.id"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                </section>
              </div>
              <div class="modal-footer">
                <base-button type="secondary" @click="closeModal('create')"> Cancelar </base-button>
                <base-button
                  type="success"
                  native-type="submit"
                  :disabled="invalid || !payload.installments.length"
                  :loading="loadingStore"
                >
                  <span v-show="!loadingStore" class="btn-label"><i class="fas fa-save"></i></span>
                  Salvar
                </base-button>
              </div>
            </form>
          </validation-observer>
        </div>
      </div>
    </modal>
    <ModalSearchEntity @selected="selectedEntity" ref="modalSearchEntity" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PuzlFile from "@/components/PuzlFile";
import { VMoney } from "v-money";
import { checkNumberValue, moneyToFloat, toMoney } from "@/helpers";
import { mask1, formatErrorValidation } from "@/plugins";
import moment from "moment";
import InputFile from "@/components/Utils/InputFile";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import ModalSearchEntity from "../../../Configuration/Entity/Shared/_ModalSearchEntity.vue";
import billPayMixin from "../Mixins/bill_pay_mixin";
import billPayInstallmentMixin from "../Mixins/bill_pay_installment_mixin";
import entryLaunchMixin from "../../../Configuration/EntryLaunch/Mixins/entry_launch_mixin"
import Pagination from "@/components/Utils/Pagination";
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "ModalCreateBillPay",
  mixins: [billPayMixin, entryLaunchMixin, billPayInstallmentMixin],
  components: {
    InputFile,
    PuzlFile,
    InputDateTimePicker,
    ModalSearchEntity,
    Pagination,
    PuzlSelect,
  },
  data() {
    return {
      loadingPaymentMethod: false,
      loadingEntryLaunch: false,
      loadingCompanyPlantBuyer:false,
      loadingProductServiceHubType:false,
      loadingCompanyPlant:false,
      loadingBankAccount:false,
      modal: {
        title: "Contas a pagar",
        create: false,
      },
      type: 1,
      payload: {
        bill_pay: {
          date: null,
          total_value: 0,
          final_value: 0,
          balance: 0,
          document_value: 0,
          description: null,
          entity_id: null,
          bank_account_id: null,
          payment_method_id: null,
          occurrence: 0,
        },
        installments: [],
        entries_launches: [],
      },
      company_plant_buyers:[],
      quantity_installments: null,
      loadingStore: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      entity: null,
      occurrences: [
        {
          label: "Única",
          value: 0,
        },
        {
          label: "Semanal",
          value: 7,
        },
        {
          label: "Mensal",
          value: 30,
        },
        {
          label: "Personalizado",
          value: "8",
        },
      ],
      bank_accounts:[],
    };
  },
  directives: {
    money: VMoney,
  },
  computed: {
    ...mapGetters({
      $_payment_methods: "paymentMethod/fetch",
      $_entries_launches: "entryLaunch/fetch",
      $_company_plants: "plant/activeItems",
      $_product_service_hub_types: "productServiceHubType/fetch",
    }),
    finalValue() {
      return this.payload.installments
        .reduce(function (a, item) {
          return a + (item.final_value.includes(',') ? moneyToFloat(item.final_value) : parseFloat(item.final_value));
        }, 0)
        .toFixed(2);
    },
    balance() {
      return this.payload.bill_pay.total_value && this.payload.bill_pay.document_value
        ? (moneyToFloat(this.payload.bill_pay.total_value) - moneyToFloat(this.payload.bill_pay.document_value)).toFixed(2)
        : 0;
    },
    hasPaidInstallment() {
      return this.payload.installments.findIndex((item) => item.status === 1) !== -1;
    },
    paginatedInstallment() {
      const from = this.source.meta.current_page > 1 ? this.source.meta.current_page - 1 : 0;
      return this.payload.installments.slice(from, this.source.meta.current_page);
    },
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    checkOccurrence() {
      this.payload.bill_pay.occurrence = parseInt(this.payload.bill_pay.occurrence) > 7 ? this.payload.bill_pay.occurrence : 0;
    },
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        this.$forceUpdate();
        return checkNumberValue(mask1(value.toString(), decimals, ".", true), min, max);
      }
      return checkNumberValue(value, min, max);
    },
    changeAccumulation() {
      if (!this.payload.bill_pay.accumulation) {
        this.payload.bill_pay.accumulation_time = null;
        this.payload.bill_pay.accumulation = 1;
      } else {
        this.payload.bill_pay.accumulation = 0;
      }
      this.$forceUpdate();
    },
    formatEntryLaunchLabel(item) {
      return `${item.issue_date} | Nº ${item.document_number} | ${toMoney(item.final_value)}`;
    },
    selectedEntity(entity) {
      this.entity = entity;
      this.loadingPaymentMethod = true;
      this.loadingEntryLaunch = true;
      this.quantity_installments = null;
      this.loadingProductServiceHubType = true;
      this.loadingCompanyPlantBuyer = true;
      this.loadingCompanyPlant = true;
      this.$store.dispatch("paymentMethod/fetchItems").then((response) => {
        this.loadingPaymentMethod = false;
      });
      this.$store.dispatch("productServiceHubType/fetch").then(() => {
        this.loadingProductServiceHubType = false;
      });
      this.$store.dispatch("plant/fetchItemsActive").then(() => {
        this.loadingCompanyPlant = false;
      });
      this.type = 1;
      this.payload = {
        bill_pay: {
          date: moment().format("YYYY-MM-DD HH:mm:ss"),
          total_value: '0,00',
          final_value: '0,00',
          balance: '0,00',
          document_value: '0,00',
          description: null,
          occurrence: 0,
          entity_id: entity.id,
          bank_account_id: null,
          payment_method_id: null,
        },
        installments: [],
      };
      this.modal.create = true;
      this.changeInstallments(false);
    },
    openModal() {
      this.$refs.modalSearchEntity.openModal();
    },
    finalFormat() {
      if (this.hasPaidInstallment) {
        this.payload.bill_pay.accumulation = 0;
      }
      let payload = JSON.parse(JSON.stringify(this.payload));
      let formData = new FormData();
      payload.bill_pay.total_value = moneyToFloat(payload.bill_pay.total_value);
      payload.bill_pay.document_value = moneyToFloat(payload.bill_pay.document_value);
      payload.bill_pay.final_value = this.finalValue;
      payload.bill_pay.balance = this.balance;
      payload.bill_pay.date = moment(payload.bill_pay.date).format("YYYY-MM-DD");
      payload.installments.map(function (obj) {
          obj.bank_account_id = obj.bank_account_id ? obj.bank_account_id : payload.bill_pay.bank_account_id;
          obj.fees_fines = obj.fees_fines !== '0,00' ? this.normalizeValue(obj.fees_fines) : null;
          obj.discounts_fees = obj.discounts_fees !== '0,00' ? this.normalizeValue(obj.discounts_fees) : null;
          obj.due_date = moment(obj.due_date).format("YYYY-MM-DD");
          obj.original_due_date = moment(obj.original_due_date).format("YYYY-MM-DD");
          obj.total_value = this.normalizeValue(obj.total_value);
          obj.final_value = this.normalizeValue(obj.final_value);
      }, this);
      for (let key of Object.keys(payload.bill_pay)) {
        formData.append(`bill_pay[${key}]`, payload.bill_pay[key] !== null ? payload.bill_pay[key] : "");
      }
      payload.installments.map(function (obj, index) {
        for (let key of Object.keys(payload.installments[index])) {
          formData.append(
            `installments[${index}][${key}]`,
            payload.installments[index][key] !== null ? payload.installments[index][key] : ""
          );
        }
        /* Verifica se há anexo para a parcela */
        if (obj.file) {
          formData.append(`${index}[file]`, this.payload.installments[index].file.file);
        }
      }, this);
      if (payload.entries_launches && payload.entries_launches.length) {
        payload.entries_launches.map(function (obj, index) {
          formData.append(`entries_launches[${index}]`, obj);
        });
      }
      return formData;
    },
    store() {
      this.loadingStore = true;
      let payload = this.finalFormat();
      this.$store
        .dispatch("billPay/add", payload, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.loadingStore = false;
          this.closeModal();
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
        })
        .catch((error) => {
          if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
          this.$Progress.finish();
          this.loadingStore = false;
        });
    },
    async changeInstallments(show_message = true) {
      this.payload.installments = [];
      let val = parseInt(this.quantity_installments);
      switch (true) {
        /* caso não haja parcelas a serem manipuladas */
        case !val:
          this.quantity_installments = 1;
          if (show_message) {
            this.$notify({ type: "danger", message: "Deve haver ao menos uma parcela!" });
          }
          await this.generateInstallments(1);
          break;
        default:
          await this.generateInstallments(val);
      }
      this.resetInstallmentPage(this.payload.installments);
    },
    /** Recalcula o valor total dos documentos e dispara parcelas */
    calcDocumentValue() {
      this.payload.bill_pay.document_value = 0;
      this.payload.entries_launches.map(function (id) {
        this.payload.bill_pay.document_value += parseFloat(this.$_entries_launches.filter((item) => item.id === id)[0].final_value);
      }, this);
      this.payload.bill_pay.document_value = this.payload.bill_pay.document_value.toFixed(2);
      this.payload.bill_pay.total_value = toMoney(this.payload.bill_pay.document_value).replace("R$", "");
      this.changeInstallments(false);
    },
    generateInstallments(to_generate) {
      /* valor total informado pelo usuário */
      let bill_pay_total_value = moneyToFloat(this.payload.bill_pay.total_value);
      /* Valor por parcela */
      let value_per_installment = (bill_pay_total_value / to_generate).toFixed(2);

      for (let i = 0; i < to_generate; i++) {
        let date = moment().add(i, "months").format("YYYY-MM-DD") + " 00:00:00";
        this.payload.installments.push({
          status: 0,
          index: i + 1,
          due_date: date,
          original_due_date: date,
          paid_at: null,
          discounts_fees: null,
          fees_fines: null,
          final_value: value_per_installment,
          total_value: value_per_installment,
        });
      }
      let real_total_value = this.payload.installments.reduce(function (a, item) {
        return a + parseFloat(item.total_value);
      }, 0);

      /** Diferença de valor real e teórico */
      let difference = (real_total_value - bill_pay_total_value).toFixed(2);

      if (difference !== 0) {
        /** Checa integridade do valor */
        switch (true) {
          case difference > 0:
            this.payload.installments[0].total_value = (
              parseFloat(this.payload.installments[0].total_value) - difference
            ).toFixed(2);
            this.payload.installments[0].final_value = (
              parseFloat(this.payload.installments[0].final_value) - difference
            ).toFixed(2);
            break;
          case difference < 0:
            difference = Math.abs(difference);
            this.payload.installments[0].total_value = (
              parseFloat(this.payload.installments[0].total_value) + difference
            ).toFixed(2);
            this.payload.installments[0].final_value = (
              parseFloat(this.payload.installments[0].final_value) + difference
            ).toFixed(2);
            break;
        }
      }
      return difference;
    },
    getCompanyPlantBuyers() {
      this.loadingCompanyPlantBuyer = true;
      this.$store.dispatch("companyPlantBuyer/getByCompanyPlant", this.payload.bill_pay.company_plant_id).then((response) => {
        this.company_plant_buyers = response.data || [];
        if (this.company_plant_buyers.length) {
          let default_company_plant_buyer =
            this.company_plant_buyers.filter((item) => item.default === 1)[0] || this.company_plant_buyers[0];
          this.payload.bill_pay.company_plant_buyer_id = default_company_plant_buyer.id;
          this.getDocuments(this.payload.bill_pay)
        } else {
          this.payload.bill_pay.company_plant_buyer_id = null;
        }
        this.loadingCompanyPlantBuyer = false;
      });
    },
    removeInstallments(to_remove) {
      this.payload.installments.splice(to_remove);
    },
    /**
     * Realiza verificação de anexo e dispara navegação entre as páginas
     *
     * @param {integer} page
     */
    handleInstallmentNavegate(page) {
      if(this.payload.installments[page -1] && this.payload.installments[page -1].file) {
        const file = this.payload.installments[page -1].file;
        this.$refs.installmentAttachment[0].setFileByObject({
          url: file.urlResized,
          ext: file.ext,
          name: file.file.name,
          size: file.size,
          type: file.type,
        }, true);
      } else {
        this.$refs.installmentAttachment[0].fileRecords = [];
        this.$refs.installmentAttachment[0].fileRecordsForUpload = [];
      }
      this.$forceUpdate();
      this.navegate(page, this.payload.installments);
    },
  },
  mounted() {},
};
</script>

<style>
.bill-modal-create {
  overflow-y: scroll !important;
}

@media (min-width: 768px) {
  .limited-row {
    max-height: 28px !important;
  }
}
.custom-input-select .el-select__tags-text {
  max-width: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
}
</style>
