<template>
  <div>
    <modal :show.sync="modal.create" size="lg">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(store)"
            autocomplete="off"
          >
            <div>
              <!-- Central -->
              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-4 pb-0 mb-1 col-form-label form-control-label"
                >
                  Central
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 mb-1 pr-4">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <puzl-select 
                        v-model="water.company_plant_id" 
                        :items="plants" 
                        @input="getChargePoint(water.company_plant_id)"
                        disabled="true" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Ponto de carga -->
              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-4 pb-0 mb-1 col-form-label form-control-label"
                >
                  Ponto de carga
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 mb-1 pr-4">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <puzl-select 
                        v-model="water.company_plant_charge_point_id" 
                        :items="chargePointItems" 
                        disabled="true" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-4 pb-0 mb-1 col-form-label form-control-label"
                >
                  Água
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 mb-1 pr-4">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <puzl-select 
                        v-model="water.cmc_adjustment_uuid" 
                        :items="filteredWater" 
                        customKey="uuid"
                        :labelMask="true"
                        label="product_name$ - $supplier_name$ ($origin_name$)"
                        disabled="true"  />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Corte de Água -->
              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-4 pb-0 mb-1 col-form-label form-control-label"
                >
                  Corte de Água
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 mb-1 pr-4">
                  <validation-provider rules="required|min_value:1" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        inputmode="numeric"
                        :disabled="!correctionInput"
                        v-model="water.correction"
                        class="form-control form-control-sm"
                        maxlength="3"
                        v-on:input="
                          water.correction = formatString(
                            $event.target.value,
                            '0',
                            '100'
                          )
                        "
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          %
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="modal-footer">
                <base-button type="secondary" @click="closeModal('create')">
                  Cancelar
                </base-button>
                <base-button
                  type="success"
                  native-type="submit"
                  v-bind:disabled="invalid"
                  :loading="loadingStore"
                >
                  Salvar
                </base-button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { checkNumberValue } from "@/helpers";
import { mask1, formatErrorValidation } from "@/plugins";
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "ModalEditWater",
  components: { PuzlSelect },
  data() {
    return {
      modal: {
        title: "Corte de Água",
        create: false
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      charge_point_select: false,
      water: {
        uuid: null,
        company_plant_id: null,
        company_plant_charge_point_id: null,
        cmc_adjustment_uuid: null,
        date_current: null,
        correction: null,
        hour: null
      },
      loadingStore: false,
      selectedGroups: [],
      chargePointItems: [],
      correctionInput: true
    };
  },
  computed: {
    ...mapGetters({
      plants: "plant/fetch",
      chargePoints: "plantChargePoint/fetch",
      cmcAdjustments: "adjustmentCmc/fetch"
    }),
    filteredWater() {
      return this.cmcAdjustments.filter((item) => 
        item.cmc_categories_id === 6
        && this.water.company_plant_id === item.company_plants_id
        && this.water.company_plant_charge_point_id === item.company_plant_charge_points_id)
    },
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    handleEditModal(uuid) {
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.$Progress.start();
      this.correctionInput = true;
      this.chargePointItems = [];
      this.charge_point_select = false;
      this.$store.dispatch("water/show", uuid).then(response => {
        this.water = {
          uuid: response.data.uuid || "",
          cmc_adjustment_uuid: response.data.cmc_adjustment.uuid,
          company_plant_id: response.data.company_plant.id || "",
          company_plant_charge_point_id:
            response.data.company_plant_charge_point.id || "",
          correction: response.data.correction || "",
        };
        let company_plant_uuid = this.plants.filter(
          item => item.id == this.water.company_plant_id
        )[0].uuid;
        this.$store
          .dispatch("plantChargePoint/getByPlant", company_plant_uuid)
          .then(response => {
            this.chargePointItems = response.data;
            this.charge_point_select = true;
            this.$Progress.finish();
            this.$notify({
              type: response.error_type,
              message: response.message
            });
            this.modal.create = true;
          });
      });
    },
    getChargePoint() {
      if (this.water.company_plant_id) {
        this.charge_point_select = false;
        this.correctionInput = false;
        this.water.company_plant_charge_point_id = null;
        this.water.cmc_adjustment_uuid = null;
        let uuid = this.plants.filter(
          item => item.id == this.water.company_plant_id
        )[0].uuid;
        this.$store
          .dispatch("plantChargePoint/getByPlant", uuid)
          .then(response => {
            this.chargePointItems = response.data;
            this.correctionInput = true;
            this.charge_point_select = true;
          });
      } else {
        this.charge_point_select = false;
        this.correctionInput = false;
        this.water.company_plant_charge_point_id = null;
      }
    },
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        value = mask1(
          checkNumberValue(value, min, max).toString(),
          decimals,
          ".",
          true
        );
        return checkNumberValue(value, min, max);
      }
      return checkNumberValue(value, min, max);
    },
    store() {
      this.$Progress.start();
      this.loadingStore = true;
      this.water.date_time =
        this.water.date_current + " " + this.water.hour + ":00";
      this.$store
        .dispatch("water/update", this.water)
        .then(response => {
          this.loadingStore = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.closeModal();
        })
        .catch(error => {
          if (error.status === 200) {
            this.$notify({
              type: "danger",
              message: error.data.message
            });
            this.$Progress.finish();
            this.loadingStore = false;
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors
            });
            this.$Progress.finish();
            this.loadingStore = false;
          }
        });
    }
  },
  created() {
    this.$store.dispatch("plant/fetchItems");
    this.$store.dispatch("adjustmentCmc/getCmcAdjustment");
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
};
</script>

<style scoped></style>
