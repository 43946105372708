<template>
  <div class="card-body mt-n2 p-0" v-if="!loadingHeaders">
    <div class="row">
      <!-- Operacional -->
      <div class="col-md-12 p-1">
        <button
          type="button"
          class="btn-custom mb-n3 ml-3"
          @click.prevent="nextMonthsOperational()"
        >
          <i :class="currentIconOperational"></i>
        </button>
        <ShadedTableFiscalClosing
          :length="dados.length"
          :headers="isNextMonthsOperational ? lastMonthsHeadersOperational : firstMonthsHeadersOperational"
          :loading="loadingSkeleton"
          :clickableColumn="true"
          :selectedYear="selectedYear"
          :firstColumn="firstColumn[0]"
          @toggleHeaderStatus="toggleHeaderStatusOperational"
          @nextMonths="nextMonthsOperational"
        >
        </ShadedTableFiscalClosing>
      </div>
      <!-- Faturamento -->
      <div class="col-md-12 p-1">
        <button
          type="button"
          class="btn-custom mb-n3 ml-3"
          @click.prevent="nextMonthsInvoicing()"
        >
          <i :class="currentIconInvoicing"></i>
        </button>
        <ShadedTableFiscalClosing
          :length="dados.length"
          :headers="isNextMonthsInvoicing ? lastMonthsHeadersInvoicing : firstMonthsHeadersInvoicing"
          :loading="loadingSkeleton"
          :clickableColumn="true"
          :selectedYear="selectedYear"
          :firstColumn="firstColumn[1]"
          @toggleHeaderStatus="toggleHeaderStatusInvoicing"
          @nextMonths="nextMonthsInvoicing"
        >
        </ShadedTableFiscalClosing>
      </div>
      <!-- Fiscal -->
      <div class="col-md-12 p-1">
        <button
          type="button"
          class="btn-custom mb-n3 ml-3"
          @click.prevent="nextMonthsFiscal()"
        >
          <i :class="currentIconFiscal"></i>
        </button>
        <ShadedTableFiscalClosing
          :length="dados.length"
          :headers="isNextMonthsFiscal ? lastMonthsHeadersFiscal : firstMonthsHeadersFiscal"
          :loading="loadingSkeleton"
          :clickableColumn="true"
          :selectedYear="selectedYear"
          :firstColumn="firstColumn[2]"
          @toggleHeaderStatus="toggleHeaderStatusFiscal"
          @nextMonths="nextMonthsFiscal"
        >
        </ShadedTableFiscalClosing>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonPuzl from "@/components/SkeletonPuzl";
import ShadedTableFiscalClosing from "./ShadedTableFiscalClosing.vue";
import ShadedCol from "@/components/ShadedCol.vue";
import { FiscalClosingIsClosedEnum } from "./Enums/FiscalClosingIsClosedEnum";
import { FiscalClosingTypeEnum } from "./Enums/FiscalClosingTypeEnum";

export default {
  name: "TableConcretesResume",
  components: {
    SkeletonPuzl,
    ShadedTableFiscalClosing,
    ShadedCol,
  },
  data() {
    return {
      filter: {
        year: null,
        month: null,
        type: null,
      },
      dados: {
        id: 1, title: 'um',
        id: 2, title: 'dois'
      },
      firstColumn: [
        { title: "Operacional" },
        { title: "Faturamento" },
        { title: "Fiscal" },
      ],
      isNextMonthsOperational: false,
      isNextMonthsInvoicing: false,
      isNextMonthsFiscal: false,
      currentIconOperational: 'fa-solid fa-angle-right',
      currentIconInvoicing: 'fa-solid fa-angle-right',
      currentIconFiscal: 'fa-solid fa-angle-right',
      FiscalClosingIsClosedEnum: FiscalClosingIsClosedEnum,
      FiscalClosingTypeEnum: FiscalClosingTypeEnum,
    };
  },
  props: {
    firstMonthsHeadersOperational: {
      type: Array,
      default: () => [],
      description: "Colunas para os primeiros 6 meses do ano, tipo operacional",
    },
    firstMonthsHeadersInvoicing: {
      type: Array,
      default: () => [],
      description: "Colunas para os primeiros 6 meses do ano, tipo faturamento",
    },
    firstMonthsHeadersFiscal: {
      type: Array,
      default: () => [],
      description: "Colunas para os primeiros 6 meses do ano, tipo fiscal",
    },
    lastMonthsHeadersOperational: {
      type: Array,
      default: () => [],
      description: "Colunas para os últimos 6 meses do ano, tipo operacioanl",
    },
    lastMonthsHeadersInvoicing: {
      type: Array,
      default: () => [],
      description: "Colunas para os últimos 6 meses do ano, tipo faturamento",
    },
    lastMonthsHeadersFiscal: {
      type: Array,
      default: () => [],
      description: "Colunas para os últimos 6 meses do ano, tipo fiscal",
    },
    selectedYear: {
      type: Number,
      required: true,
      description: "Ano selecionado no filtro",
    },
    loadingSkeleton: {
      type: Boolean,
      required: true,
      description: "Loading para o conteúdo da tabela",
    },
    loadingHeaders: {
      type: Boolean,
      required: true,
      description: "Espera carregar os dados do banco para exibir as colunas da tabela",
    }
  },
  methods: {
    /**
     * @param {string|null} property
     * @param {string|null} icon
     */
    toggleMonths(property, icon) {
      this[property] = !this[property];

      this[icon] =
        this[icon] === 'fa-solid fa-angle-right'
          ? 'fa-solid fa-angle-left'
          : 'fa-solid fa-angle-right';
    },
    nextMonthsOperational() {
      this.toggleMonths('isNextMonthsOperational', 'currentIconOperational');
    },
    nextMonthsInvoicing() {
      this.toggleMonths('isNextMonthsInvoicing', 'currentIconInvoicing');
    },
    nextMonthsFiscal() {
      this.toggleMonths('isNextMonthsFiscal', 'currentIconFiscal');
    },
    /**
     * Altera coluna is_closed de um registro em um ano, mês e tipo específico.
     * @param {string} month
     * @param {number} monthHeader
     * @param {FiscalClosingTypeEnum} type
     */
    updateStatusColumn(month, monthHeader, type) {
      this.$Swal.confirmAction().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.filter = {
            year: this.selectedYear,
            month: month,
            type: type,
            is_closed: monthHeader.is_closed,
          };
          this.$store
            .dispatch("fiscalClosing/updateStatus", this.filter)
            .then((response) => {
              if (response.data) {
                monthHeader.is_closed =
                monthHeader.is_closed == this.FiscalClosingIsClosedEnum.CLOSED
                  ? this.FiscalClosingIsClosedEnum.OPEN
                  : this.FiscalClosingIsClosedEnum.CLOSED;
              }
              this.$notify({
                type: 'success',
                message: 'Status atualizado com sucesso!',
              });
            })
            .catch((error) => {
              this.$notify({
                type: 'danger',
                message: error.response.data.message,
              });
            })
            .finally(() => {
              this.$Progress.finish();
            });
          }
      }).catch(() => this.$Progress.finish());
    },
    /**
     * Alterna o status do cabeçalho de operacional com base no índice fornecido.
     * @param {number} index
     */
    toggleHeaderStatusOperational(index) {
      if (this.isNextMonthsOperational) {
          const item = this.lastMonthsHeadersOperational[index -1];
          this.filter.month = item.month;
          this.updateStatusColumn(this.filter.month, item, FiscalClosingTypeEnum.OPERATIONAL);
      } else {
          const item = this.firstMonthsHeadersOperational[index -1];
          this.filter.month = item.month;
          this.updateStatusColumn(this.filter.month, item, FiscalClosingTypeEnum.OPERATIONAL);
      }
    },
    /**
     * Alterna o status do cabeçalho de faturamento com base no índice fornecido.
     * @param {number} index
     */
    toggleHeaderStatusInvoicing(index) {
      if (this.isNextMonthsInvoicing) {
        const item = this.lastMonthsHeadersInvoicing[index -1];
        this.filter.month = item.month;
        this.updateStatusColumn(this.filter.month, item, FiscalClosingTypeEnum.INVOICING);
      } else {
        const item = this.firstMonthsHeadersInvoicing[index -1];
        this.filter.month = item.month;
        this.updateStatusColumn(this.filter.month, item, FiscalClosingTypeEnum.INVOICING);
      }
    },
    /**
     * Alterna o status do cabeçalho de fiscal com base no índice fornecido.
     * @param {number} index
     */
    toggleHeaderStatusFiscal(index) {
      if (this.isNextMonthsFiscal) {
        const item = this.lastMonthsHeadersFiscal[index -1];
        this.filter.month = item.month;
        this.updateStatusColumn(this.filter.month, item, FiscalClosingTypeEnum.FISCAL);
      } else {
        const item = this.firstMonthsHeadersFiscal[index -1];
        this.filter.month = item.month;
        this.updateStatusColumn(this.filter.month, item, FiscalClosingTypeEnum.FISCAL);
      }
    },
  },
};
</script>

<style scoped>
/** Botão para troca de meses na tabela */
.btn-custom {
  background-color: rgba(200, 200, 200, 0.3); /* Cinza claro transparente */
  border: 1px solid rgba(200, 200, 200, 0.3);
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: #000;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  height: 40px;
  width: 40px;
  overflow: hidden;
  transition: background-color 0.3s ease, border-color 0.3s ease; /* Adiciona transição suave */
}
.btn-custom:hover {
  background-color: rgba(180, 180, 180, 0.5); /* Cinza um pouco mais escuro */
  border-color: rgba(180, 180, 180, 0.5);
}
.btn-custom:active {
  background-color: rgba(160, 160, 160, 0.6); /* Cinza ainda mais escuro */
  border-color: rgba(160, 160, 160, 0.6);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
</style>