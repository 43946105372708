
/**
 * Type para retorno paginado da listagem
 *
 * @typedef {(
 *   PagerType &
 *   { items: Array }
 * )} StockLocationListPagerType
 */
export const DreListType = {};

/**
 * Inicializar StockLocationListPagerType
 *
 * @returns {DreListType}
 */
export const initDreListType = () => {
  return {
    chart_accounts: [],
    chart_account: 1,
    year: new Date().getFullYear()
  }
};

