<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div>
              <!-- Tipo -->
              <div>
                <div class="form-group row m-1 p-0">
                  <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">Tipo de cobrança </label>
                  <div class="col-md-8">
                    <puzl-select
                      @input="changedBillingType"
                      :clearable="false"
                      :value="item.billing_type"
                      :items="service_billing_types"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <!-- Quantidade -->
              <div>
                <div class="form-group row m-1 p-0">
                  <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">
                    Quantidade
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-8">
                    <validation-provider rules="required|min_value:1">
                      <base-input input-group-classes="input-group-sm">
                        <input-limit
                          :options="{
                            min: 0,
                            decimals : item.billing_type === 0 ? 0 : 2,
                          }"
                          max_length="7"
                          :start_value="item.quantity"
                          v-model="item.quantity"
                          input_group_classes="input-group-sm pb-0"
                          @value="item.quantity = $event === '-' ? null : $event"
                          @change="getTotalValue"
                          :disabled="item.billing_type === 0"
                        >
                        </input-limit>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
              </div>
              <!-- Preço -->
              <div>
                <div class="form-group row m-1 p-0">
                  <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">
                    Preço unitário
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-8">
                    <validation-provider :rules="valueRequired(item.price)">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          @input="getTotalValue"
                          v-money="money"
                          v-model.lazy="item.price"
                          maxlength="10"
                          inputmode="numeric"
                          class="form-control form-control-sm"
                        />
                        <template slot="prepend">
                          <small class="input-group-sm p-0 m-0"> R$ </small>
                        </template>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
              </div>
              <!-- Observações -->
              <div class="form-group row m-1 p-0">
                <label class="col-md-4 pb-0 pt-2 col-form-label form-control-label"> 
                  Observações 
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input maxlength="80" v-model="item.observations" class="form-control form-control-sm" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Total -->
              <div class="form-group row m-1 p-0">
                <label class="col-md-4 pb-0 pt-2 col-form-label form-control-label"> Total </label>
                <div class="col-md-8">
                  <base-input input-group-classes="input-group-sm">
                    <input disabled v-model="human_total_value" class="form-control form-control-sm" />
                  </base-input>
                </div>
              </div>
              <div class="modal-footer d-flex justify-content-center">
                <base-button type="secondary" @click="closeModal('create')"> Cancelar </base-button>
                <base-button type="success" native-type="submit" v-bind:disabled="invalid || item.total_value <= 0" :loading="loadingStore">
                  Salvar
                </base-button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { VMoney } from "v-money";
import { formatErrorValidation } from "@/plugins";
import PuzlSelect from "@/components/PuzlSelect";
import InputLimit from "@/components/Utils/InputLimit";

export default {
  name: "ModalChangeServiceValue",
  components: { PuzlSelect, InputLimit },
  data() {
    return {
      modal: {
        title: "Alteração de preços de serviço",
        create: false,
      },
      item: {
        billing_type: 2,
        quantity: null,
        price: null,
        total_value: 0,
        observations:null,
      },
      service_billing_types: [
        {
          id: 0,
          name: "Fixo",
        },
        {
          id: 1,
          name: "m³",
        },
        {
          id: 2,
          name: "Por hora",
        },
      ],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      loadingStore: false,
    };
  },
  directives: { money: VMoney },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal(schedule_service_id) {
      this.item = {
        billing_type: 0,
        quantity: 1,
        price: null,
        total_value: 0,
        schedule_service_id : schedule_service_id,
        observations:null,
      };
      this.modal.create = true;
    },
    store() {
      const loader = this.$loading.show();
      this.loadingStore = true;
      this.$store
        .dispatch("scheduleService/changePerformedValue", this.item)
        .then((response) => {
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.$emit('updated')
          this.closeModal();
        })
        .catch((error) => {
          if (error.status === 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
        })
        .finally(() => {
          loader.hide();
          this.loadingStore = false;
        });
    },

    /**
     * Trata valor obrigarório do v-money
     *
     * @param {string} value
     * @returns {string}
     */
    valueRequired(value) {
      return value === "0,00" ? "min_value:1" : "";
    },

    /**
     * Calcula valor total
     *
     * @returns void
     */
    getTotalValue() {
      let price = parseFloat(this.item.price.replaceAll(".", "").replace(",", "."));
      let quantity = this.item.quantity ? parseFloat(this.item.quantity.replace(",", ".")) : 0;
      this.item.total_value = price * quantity;
    },

    /**
     * Quando há mudança de tipo de cobrança, seta a quantidade
     *
     * @param {integer} value
     * @returns void
     */
    changedBillingType(value) {
      if (value === 0) {
        this.item.quantity = 1;
      }
    },
  },
  computed: {
    human_total_value() {
      return `${this.item.total_value.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}`;
    },
  },
};
</script>

<style scoped>
.modal {
  overflow-y: scroll !important;
}
</style>
