<template>
  <div>
    <!-- Header -->
    <div class="header py-7 py-lg-8 pt-lg-9" style="margin-top: 150px">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12 px-12">
              <img height="100px" src="https://cdn-icons-png.flaticon.com/512/3208/3208742.png">
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 px-12 mt-2">
              <span class="h1">você não possui permissão para acessar este recurso.</span>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 px-12">
              <span>Entre em contato com o gestor da empresa para solicitar as permissões de acesso necessárias.</span>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 px-12 mt-4">
              <a href="#" @click.prevent="handleGoToHome()"><b>Voltar para tela inicial</b></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      name: '400'
    };
  },
  methods: {
    handleGoToHome() {
      this.$router.push('/dashboard')
    }
  },
  mounted() {
    this.$Progress.finish()
  }
};
</script>
<style>
</style>
