<template>
  <div>
    <modal size="md" :show.sync="showModal" :borderInfo="'2px solid #1a70b7'">
      <template slot="header">
        <img src="/img/icons/whatsapp--v1--white.png" width="30px" height="30px" class="mr-4">
        <h5 class="modal-title mt-2">Envio de fatura por whatsapp</h5>
      </template>
      <template>
        <div class="container pb-5">
          <div class="row d-flex align-items-center m-0">
            <div class="col-1">
              <img src="/img/icons/info-squared.png" width="25px" height="25px">
            </div>
            <div class="col-4">
              <h4 class="m-0">IDENTIFICAÇÃO</h4>
            </div>
            <hr class="bg-dark flex-grow-1 mr-3">
          </div>
          <div class="row d-flex align-items-center m-0">
            <div class="col-md-6 col-sm-6">
              <h5 class="h4 new-default-black-font">{{ bill.contract_proposal.code }}</h5>
              <h5 class="h5 new-default-black-font font-weight-400">{{ bill.contract_proposal.construction.construction_name }}</h5>
              <h5 class="h5 mb-0 new-default-black-font font-weight-400">{{ bill.contract_proposal.construction.default_address.city }}</h5>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="row">
                <div class="col-12">
                  <div class="row d-flex flex-row-reverse align-items-center pb-3">
                    <div class="col-md-8 col-sm-8">
                      <div @click.prevent="downloadInvoice(bill.id)" class="pointer avatar avatar-sm new-default-button bg-white"
                        style="width: 100%;opacity: 95%;border: 1px solid grey">
                        <img src="/img/icons/icons8/ios/pdf-2_danger.png" style="width: 19px; height: 19px" />
                        <h6 :title="bill.id" class="text-truncate h6 new-default-black-font mt-2 font-weight-400"
                          style="color: #656363 !important;font-size: 12px;margin-left: 4px">
                          n. {{ bill.id }}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row d-flex flex-row-reverse">
                    <div class="col-md-8 col-sm-8 pb-0">
                      <div @click.prevent="nfseButtonClick(bill)" class="pointer avatar avatar-sm new-default-button"
                        style="width: 100%;opacity: 95%;background: white;border: 1px solid grey">
                        <img style="width: 30px;" src="/img/icons/nfe_blue.png" />
                        <small class="ml-1 text-dark" style="font-size: 12px;">{{ backgroundNfse(bill).text }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row d-flex align-items-center m-0 mt-2">
            <div class="col-1">
              <img src="/img/icons/icons8/ios/folder-invoices.png" width="25px" height="25px">
            </div>
            <div class="col-4">
              <h4 class="m-0">Dados</h4>
            </div>
            <hr class="bg-dark flex-grow-1 mr-3">
          </div>
          <div class="row d-flex align-items-center m-0">
            <div class="col-md-6 col-sm-6 mt-1">
              <label for="cellphone-whatsapp" class="m-0">
                <h5 class="h5 new-default-black-font mb-0 font-weight-400 mb-0">CELULAR</h5>
              </label>
            </div>
            <div class="col-md-6 col-sm-6 d-flex align-items-center">
              <input v-mask="['(##) ####-####', '(##) #####-####']" id="cellphone-whatsapp" v-model="recipientPhone" class="form-control form-control-sm" />
            </div>
          </div>
          <div class="row d-flex align-items-center m-0 mt-4">
            <div class="col-md-8 col-sm-8 d-flex align-items-center" style="text-wrap: nowrap;">
              <label for="autorization-nfse-whatsapp" class="m-0">
                <h5 class="h5 new-default-black-font mb-0 font-weight-400 mb-0">SOLICITAR AUTORIZAÇÃO DE EMISSÃO DE NFS-E
                </h5>
              </label>
            </div>
            <div class="col-md-4 col-sm-4 d-flex flex-row-reverse align-items-center">
              <base-switch id="autorization-nfse-whatsapp" v-model="useNfse" type="primary" offText="Não" onText="Sim"
                class="success" />
            </div>
          </div>
          <div class="row d-flex align-items-center justify-content-end m-0 mt-5">
            <div class="col-md-4 col-sm-12 mt-2">
              <base-button class="w-100 p-0" type="danger" @click="closeModal()">
                <img src="/img/icons/icons8/ios/cancel.png" width="20px" height="20px">
                CANCELAR
              </base-button>
            </div>
            <div class="col-md-4 col-sm-12 mt-2">
              <base-button class="w-100" type="success" @click="sendWhatsappCustomerService()" :loading="isLoading">
                <img src="/img/icons/whatsapp--v1--white.png" width="20px" height="20px">
                ENVIAR
              </base-button>
            </div>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import {base_url_ms} from '@/plugins'
export default {
  name: "ModalWhatsapp",
  data() {
    return {
      base_url_ms: base_url_ms(),
      showModal: false,
      bill: undefined,
      useNfse: false,
      recipientPhone: null,
      ownerPhone: null,
      whatsapp_message_sent_code: 7,
    }
  },
  methods: {
    backgroundNfse(item) {
      if (!item.nfse) {
        return {
          text: 'EMITIR',
          classText: '#606062'
        }
      }
      switch (item.nfse.status.trim()) {
        case 'Cancelada':
          return {
            class: 'text-lowercase',
            text: this.formatNfse(item.nfse),
            classText: 'rgb(219, 69, 57)'
          }
        case 'Cancelando':
          return {
            text: 'CANCELANDO',
            classText: 'rgb(180, 40, 50)'
          }
        case 'Negada':
          return {
            text: 'NEGADA',
            classText: 'rgb(219, 69, 57)'
          }
        case 'Autorizada':
          return {
            class: 'text-lowercase',
            text: this.formatNfse(item.nfse),
            classText: 'rgb(26, 112, 183)'
          }
        case 'Pendente':
          return {
            text: 'EMITINDO',
            classText: '#f2b532'
          }
      }
    },
    formatNfse(nfse) {
      // Supondo que $treatedNfseRow seja um objeto em JavaScript
      const nfseNumber = nfse.nfse_number.toString();
      const lastFiveDigits = nfseNumber.slice(-5);
      const formatedNumber = lastFiveDigits.padStart(5, '0');

      // Supondo que $treatedNfseRow.authorization_date seja uma data válida em JavaScript
      const currentDate = new Date(nfse.authorization_date);
      const currentYear = currentDate.getFullYear();
      return `${currentYear}${formatedNumber}`;
    },

    closeModal() {
      this.showModal = false;
    },
    openModal(bill) {
      this.showModal = true;
      this.bill = bill;
      this.recipientPhone = this.bill.entity.phone;
      this.ownerPhone = this.bill.company_plant_issuer_whatsapp_api_phone;
    },
    downloadInvoice(id) {
      this.$emit('downloadInvoiceWhatsappModal', id);
    },
    nfseButtonClick(item) {
      this.$emit('nfseButtonClickWhatsappModal', item);
    },
    async sendWhatsappCustomerService() {

      let phone = this.recipientPhone
        .replace("(","")
        .replace(")","")
        .replace("-", "")
        .replace(" ", "");

      phone = "55" + phone

      this.$Progress.start()
      let loader = this.$loading.show()

      let downloadBill = await this.$store.dispatch('billingInvoice/download', { id: this.bill.id })

      let blob_bill = new Blob([downloadBill], { type: 'application/pdf' })

      let formData = new FormData();

      if (this.useNfse && this.bill.nfse !== null) {
        const urlFromAmazon = this.bill.nfse.link_pdf.includes('s3');

        if (urlFromAmazon) {
          const url = this.base_url_ms + 'download-s3?url=' + this.bill.nfse.link_pdf
          let downloadNfse = await this.$store.dispatch('exports/download', url);
          let blob_nfse = new Blob([downloadNfse], { type: "application/pdf" });
          formData.append('file', blob_nfse, 'nfse.pdf')
        } else {
          const url = this.bill.nfse.link_pdf;
          let downloadNfse = await this.$store.dispatch('exports/download', url);
          let blob_nfse = new Blob([downloadNfse], { type: "application/pdf" });
          formData.append('file', blob_nfse, 'nfse.pdf');
        }
      }

      formData.append('file', blob_bill, 'report.pdf');
      formData.append('flow', 'emissao_fatura_nfse');
      formData.append('useNfse', this.useNfse);
      formData.append('phone', phone);
      formData.append('ownerPhone', this.ownerPhone);
      formData.append('invoice_id', this.bill.id);
      formData.append('base_url', window.location.origin);

      this.$store
        .dispatch("whatsappApi/sendCustomerService", formData)
        .then(response => {
          this.$store.dispatch('billingInvoice/updateInvoiceStatus', {
            id: this.bill.id,
            user_id: this.$user.id,
            status: this.whatsapp_message_sent_code,
            comment: `Número: ${this.recipientPhone}`
          })
          .then(response => {})
          .catch(error => {})

          this.$notify({ type: response.error_type, message: response.message });
          this.$Progress.finish()
          loader.hide();
        })
        .catch(error => {
          this.$notify({ type: error.error_type, message: "Não foi possível mandar a mensagem " + error.message })
          this.$Progress.finish()
          loader.hide()
        })
    },
  }
}
</script>


