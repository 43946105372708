<template>
  <div>
    <AppPageHeader>
      <template slot="search-bar">
        <div>
          <h3
            class="new-default-black-font m-0 mb-2 font-weight-normal"
          >
            <b class="h3">CÓDIGO:</b> {{ contract_proposal.code }}
          </h3>
          <h3
            class="new-default-black-font m-0 mb-2 font-weight-normal"
          >
            <b class="h3">CLIENTE:</b>
            {{
              contract_proposal &&
              contract_proposal.construction &&
              contract_proposal.construction.customer_construction.customer_name
            }}
          </h3>
          <h3
            class="new-default-black-font m-0 mb-2 font-weight-normal"
          >
            <b class="h3">OBRA:</b>
            {{
              contract_proposal && contract_proposal.construction && contract_proposal.construction.construction_name
            }}
          </h3>
        </div>
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions :isBackButtonEnabled="true">
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>
    <FormulationForm />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import FormulationForm from "./Forms/_FormulationForm.vue";
import {
  AppPageHeader,
  AppPageHeaderActions,
} from "../../../../../../components/AppGlobal";

export default {
  name: "EditContractProposalFormulation",
  components: {
    AppPageHeader,
    AppPageHeaderActions,
    FormulationForm
  },
  computed: {
    ...mapGetters({
      contract_proposal: "contractProposal/show",
    }),
  },
};
</script>
<style>
.custom-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid red;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
  position: relative;
}

.custom-checkbox::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 2px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.custom-checkbox:checked::before {
  opacity: 1;
}
</style>
