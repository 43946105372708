<template>
<div>
    <div class="container-fluid">
      <div class="row mt-n3" style="margin-bottom: 29px;">
        <div class="col-md-12 p-1">
          <multi-filter ref="multi-filter" @fetch="init({}, getCountTotal())" :filter="filter" :without_filter="true" />
        </div>
      </div>
      <div v-if="!loadingTotals" class="row card-row">
        <div class="col-md-6 col-sm-6 p-1 card-row">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-8" style="margin-top: -10px">
                  <p style="font-family: FreeMono, monospace;"
                      class="mb-0 text-sm text-uppercase font-weight-bold ml-n3">
											<small class="font-weight-bold">TOTAL</small>
									</p>
                </div>
                <div class="col-md-12">
                  <h3 class="font-weight-semibold mb-0 pl-2 ml-n3" style="font-family: 'Gill Sans', sans-serif;">
                    <div class="numeric">
                      {{ totals['total_anticipation_balance'] | currency() }}
                    </div>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets-sm col-md-6 col-sm-6 p-1 card-row">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-9" style="margin-top: -10px">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold ml-n3">
										<small class="font-weight-bold">CLIENTES</small>
									</p>
                </div>
                <div class="col-md-12">
                  <h3 class="font-weight-semibold mb-0 pl-2 ml-n3" style="font-family: 'Gill Sans', sans-serif;">
                    <div class="numeric">
                      {{ totals['total_entity'] }}
                    </div>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Loading -->
      <div class="row card-wrapper" v-show="loadingTotals" style="margin-top: -25px;">
        <div 
          v-for="index in 2" 
          :key="index" :class="'col-md-6'" 
          class="mt-n4 mb-2"
        >
          <div class="card card-border-top-light bg-gray-content">
            <div style="padding: 0.7rem" class="card-body">
              <div class="row">
                <div class="col-md-5">
                  <div><span class="bar button"></span></div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-11">
                  <div><span class="bar button"></span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row pt-0 card-row">
        <div class="col-md-12 p-1">
          <div class="card">
            <div class="card-body mt-n2">
              <div class="row">
                <div class="col-md-12 table-responsive">
                  <table class="table table-sm table-bordered">
                    <thead>
                      <tr class="fixed">
                        <th class="text-center">Cliente</th>
                        <th class="text-center">Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in 10" v-show="loadingSkeleton">
                        <th colspan="12">
                          <SkeletonPuzl type="button" />
                        </th>
                      </tr>
                      <tr v-for="item in $_anticipationBalances">
                        <th class="text-left">
                          <span class="style-table">{{ item.entity_name }}</span>
                        </th>
                        <th class="text-left">
                          <span class="style-table numeric">{{ item.anticipation_balance | currency() }}</span>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LoadingPagination :show="loading && !loadingSkeleton"/>
</div>
</template>

<script>
import {mapGetters} from "vuex";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import cursorPaginate from "@/mixins/cursorPaginate";
import Pagination from "@/components/Utils/Pagination";
import SpinnerPuzl from "@/components/SpinnerPuzl";
import { SkeletonPuzl } from "@/components";
import BackButton from "@/components/Utils/BackButton";
import MultiFilter from "@/components/Utils/MultiFilterV2";
import LoadingPagination from "@/components/LoadingPagination";

export default {
    name: "ListAntecipationBalances",
    mixins: [cursorPaginate],
    components: {
      PuzlEmptyData,
      Pagination,
      SpinnerPuzl,
      SkeletonPuzl,
      BackButton,
      MultiFilter,
      LoadingPagination,
    },
    data() {
			return {
        loadingTotals: true,
        source: null,
        totals: null,
        filter: {},
        params: {
          page: 1,
          per_page: 20,
        },
			};
    },
    computed: {
			...mapGetters({
			  $_anticipationBalances: "anticipationBalance/fetch",
			}),
    },
    mounted() {
			this.init({});
			this.getCountTotal();
    },
    methods: {
			init(filter = null) {
        this.startCursor(filter);
        this.$store
            .dispatch("anticipationBalance/fetchItemsPaginate", {
                filter: this.filter,
                next_page: this.paginate.nextUrl
            })
            .then(response => {
                this.$Progress.finish();
                this.resolveCursor(response);
            })
            .catch(error => {
                this.resolveCursor();
                this.$Progress.finish();
            });
    	},
			getCountTotal() {
				this.loadingTotals = true;
				this.$store.dispatch("anticipationBalance/getCountTotal")
					.then((response) => {
						this.totals = response.data;
					}).finally(() => {
						this.loadingTotals = false;
					});
			},
    },
};
</script>

<style></style>
