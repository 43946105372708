import { render, staticRenderFns } from "./_ModalAutomaticClosingNote.vue?vue&type=template&id=2c421a92&scoped=true"
import script from "./_ModalAutomaticClosingNote.vue?vue&type=script&lang=js"
export * from "./_ModalAutomaticClosingNote.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c421a92",
  null
  
)

export default component.exports