import { BaseListFilterType, initBaseListFilterType, RangeValueType, RangeDateType } from "../../../../../shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} ScheduleListFilterExtType
 * @property {Array} global - Usado para buscas no padrão antigo da API
 * @property {Array<16|10|null>} status - [16,10,null] Pendente, Em andamento, Todos
 * @property {RangeDateType|null} range - Intervalo entre datas
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & ScheduleListFilterExtType)} ScheduleListFilterType
 */
export const ScheduleListFilterType = {};

// ARRUMAR FILTROS ESPECÍFICOS

/**
 * Inicializar ScheduleListFilterType
 *
 * @returns {ScheduleListFilterType}
 */
export const initScheduleListFilterType = () => ({
  ...initBaseListFilterType(),
  filter: {
    columns: [],
    values: [],
  },
  global: [],
  status: null,
  range: {
    start: null,
    end: null,
    default_filterable: true
  },
});
