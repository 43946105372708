<template>
<div>
  <modal :show.sync="modal.create">
    <template slot="header">
      <h5 class="modal-title">{{ modal.title }}</h5>
    </template>
    <div>
      <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
        <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
          <div>
            <div class="form-group row m-0 p-0">
              <label class="col-md-5 col-form-label form-control-label">
                Status
              </label>
              <div class="col-md-7 pt-2">
                <base-switch v-model="situation.status" type="success" offText="inativo" onText="ativo" class="success"></base-switch>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                Nome
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      v-model="situation.name"
                      class="form-control form-control-sm"
                      :class="errors[0] ? 'is-invalid' : 'is-valid'"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                Observações
              </label>
              <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      v-model="situation.observation"
                      class="form-control form-control-sm"
                    />
                  </base-input>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-5 col-form-label form-control-label">
                Informa motivo de perda/cancelamento
              </label>
              <div class="col-md-7 pt-2">
                <base-switch v-model="situation.has_cancellation_reason" type="success" class="success"></base-switch>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-5 col-form-label form-control-label">
                Informa concorrente
              </label>
              <div class="col-md-7 pt-2">
                <base-switch v-model="situation.has_competitor" type="success" class="success"></base-switch>
              </div>
            </div>


          </div>
          <div class="modal-footer">
            <base-button type="secondary" @click="closeModal('create')">
              Cancelar
            </base-button>
            <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
              Salvar
            </base-button>
          </div>
        </form>
      </validation-observer>
    </div>
  </modal>
</div>
</template>

<script>
export default {
  name: "ModalCreateSituation",
  data () {
    return {
      modal: {
        title: 'Situação de proposta/contrato',
        create: false,
      },
      situation: {
        status: true,
        name: null,
        observation: null,
        has_cancellation_reason: false,
        has_competitor: false,
      },
      loadingStore: false,
    }
  },
  computed: {
  },
  methods: {
    closeModal () {
      this.modal.create = false
    },
    handleCreateModal () {
      this.modal.create = true
    },
    store () {
      this.$Progress.start()
      this.loadingStore = true
      this.$store.dispatch('contractSituation/add', this.situation)
        .then(response => {
          this.loadingStore = false
          this.modal.create = false
          this.situation = {
            status: true,
            name: null,
            observation: null,
            has_cancellation_reason: false,
            has_competitor: false,
          }
          this.$Progress.finish()
          this.$notify({ type: response.error_type, message: response.message })
        })
        .catch(error =>{
          if(error.response.status===422){
            let errors = Object.values(error.response.data.errors)
            errors = errors.flat().toString().split(',').join("<br />")
            this.$notify({ type: 'danger', message: errors })
          }
          this.$Progress.finish()
          this.loadingStore = false
        })
    },
  },
  mounted () {
    this.$refs.formValidator.validate();
  },
  created () {
  }
}
</script>

<style scoped>

</style>
