<template>
  <div>
    <modal :show.sync="modal"
    	headerClasses="custom-reconciliation-hash0905242234"
      bodyClasses="px-4"
    	size="xl">
      <template slot="header">
        <div style="display: flex;align-items: center;">
          <img style="display: inline-flex;" src="/img/icons/icons8/ios/link_primary.png" width="24">
          <h5 style="display: inline-flex;" class="modal-title p-0 m-0 ml-2 text-primary fs-18">{{ title }}</h5>
        </div>
      </template>
      <template slot="close-button">
        <img class="pointer" @click="closeModal" src="/img/icons/icons8/ios/close-window.png" alt="close-window" width="17px">
      </template>
      <Filters @handleChangeFilters="handleChangeFilters"
        @handleChangeAmounts="handleChangeAmounts"
        @clearFilters="clearFilters"
        :filter="filter" />
      <Cards :financeTransactions="$_finance_transactions"
        :isAllSelected="isAllSelected"
        :financeTransactionSelection="financeTransactionSelection"
        @selectOrDeselectAll="selectOrDeselectAll"
        @selectOrUnselect="selectOrUnselect" />
      <Footer :bankStatement="bankStatement"
        :bankInfo="$_bank_reconciliation"
        :total="total"
        @closeModal="closeModal"
        @finishConciliate="finishConciliate" />
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Filters from './Filters/_Filters';
import Cards from './Lists/_Cards';
import Footer from './Footer/_Footer';
import cursorPaginate from "@/mixins/cursorPaginate";

export default {
  name: "ModalReconciliate",
  mixins: [cursorPaginate],
  components: {
    Filters,
    Cards,
    Footer
  },
  data () {
    return {
      title: 'Conciliação Bancária',
      modal: false,
      total: 0,
      bankStatement: 0,
      financeTransactionSelection: [],
      financeTransactionsIds: [],
      savedFilter: {},
      filter: {
        payment_method_id: null,
        range_amount: {
          min: null,
          max: null
        }
      },
    };
  },
  computed: {
    ...mapGetters({
      $_finance_transactions: "financeTransactions/fetch",
      $_bank_reconciliation: 'bankReconciliation/show',
    }),
    isAllSelected(){
      return this.financeTransactionSelection.every(item => item == true);
    },
  },
  methods: {
    clearFilters(){
      this.filter = this.savedFilter;
      this.init();
    },
    handleChangeAmounts(min, max){
      this.filter.range_amount = {
        min: min,
        max: max
      };
      this.init();
    },
    finishConciliate(){
      this.$store
        .dispatch("bankReconciliationTransactionAssociation/associateFinanceTransaction", {
          bank_account_id: this.filter.bank_reconciliation_transaction_id,
          items: this.financeTransactionsIds,
        }).then(response => {
          this.$emit('init');
          this.closeModal();
        })
    },
    handleChangeFilters(){
      this.init();
    },
    closeModal(){
      this.modal = false;
    },
    init(){
      this.startCursor(this.filter);
      this.$store
        .dispatch("financeTransactions/fetchItems", {
          page: this.paginate.nextUrl,
          in_status: this.filter.in_status,
          is_credit: this.filter.is_credit,
          range_discharge_at: {
            start: this.filter.range_discharge_at,
            end: this.filter.range_discharge_at,
          },
          is_reconciled: false,
          range_amount: this.filter.range_amount,
          bank_account_id: this.filter.bank_account_id,
          payment_method_id: this.filter.payment_method_id,
          // range_reconciled_at: this.filter.range_reconciled_at,
          limit: 100,
          order_by: [
            {
              column: "finance_transactions.id"
            },
          ],
        })
        .then(response => {
          this.loadingSkeleton = false;
          this.financeTransactionSelection = new Array(response.data.items.length).fill(false);
          this.resolveCursor(response);
        })
        .catch(error => {
          this.resolveCursor();
          this.loadingSkeleton = false;
        });
    },
  	openModal(item){
  		this.modal = true;
      this.bankStatement = item;
      this.total = 0;
      this.financeTransactionSelection = [];
      this.financeTransactionsIds = [];

      this.savedFilter = {
        in_status: [0],
        bank_account_id: this.$_bank_reconciliation.bank_account_id,
        is_credit: item.value < 0 ? false : true,
        limit: 50,
        bank_reconciliation_transaction_id: item.id,
        range_discharge_at: item.date_at,
      };

      this.filter = this.savedFilter;
      this.init();
  	},
    selectOrDeselectAll(){
      const selectAll = !this.isAllSelected
      this.$_finance_transactions.map((financeTransaction, index) => {
        this.selectOrUnselect(index, selectAll);
      });
    },
    selectOrUnselect(position, selectAll = null){
      const select = selectAll != null ? selectAll : !this.financeTransactionSelection[position];
      this.financeTransactionSelection.splice(position, 1, select);
      this.calculate();
    },
    calculate()
    {
      let newTotal = 0;
      let newFinanceTransactionsIds = [];
      this.financeTransactionSelection.map((isSelected, index) => {
        if(isSelected){
          newFinanceTransactionsIds.push({
            finance_transaction_id: this.$_finance_transactions[index].id
          });
          newTotal += this.$_finance_transactions[index].amount;
        }
      });
      this.financeTransactionsIds = newFinanceTransactionsIds;
      this.total = newTotal;
    }
  },
}
</script>

<style>
.custom-reconciliation-hash0905242234 {
	background-color: #F2F4F9 !important;
	height: 56px !important;
}
</style>
