import AuthLayout from '@/views/Pages/AuthLayout.vue';
import Login from '@/views/Pages/Login.vue';
import NotFound from '@/views/GeneralViews/NotFoundPage.vue';
import WithoutPermission from '@/views/GeneralViews/400.vue';

const auth = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    { path: '*', component: NotFound },
  ]
};

export default auth;
