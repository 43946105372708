export const SET = 'SET'
export const SET_GROUP_CATEGORY = 'SET_GROUP_CATEGORY'
export const SET_BY_CATEGORY = 'SET_BY_CATEGORY'
export const SET_ADDITIONS = 'SET_ADDITIONS'
export const SET_ORIGINS = 'SET_ORIGINS'
export const SET_CARRIER = 'SET_CARRIER'
export const SET_PRICE = 'SET_PRICE'
export const SET_DOCUMENTS = 'SET_DOCUMENTS'
export const SET_NEXT_PAGE = 'SET_NEXT_PAGE'
export const SET_NEXT_PAGE_DATA = 'SET_NEXT_PAGE_DATA'
export const PUSH = 'PUSH'
export const SHOW = 'SHOW'
export const DESTROY = 'DESTROY'
export const UPDATE = 'UPDATE'
