<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-gray d-inline-block mb-0">{{ this.$route.meta.breadcrumbs[1].title }}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4 text-gray">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i :class="this.$route.meta.icon"></i></a></li>
              <li class="breadcrumb-item"><a href="#">{{ this.$route.meta.breadcrumbs[0].title }}</a></li>
              <li class="breadcrumb-item" aria-current="page">{{ this.$route.meta.breadcrumbs[1].title }}</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-2 mb-2">
          <base-button @click.prevent="showInvoice()" block size="lg" type="success" class="text-uppercase">
            FATURAR
          </base-button>
        </div>
        <div class="col-md-2 mb-2">
            <base-button :disabled="loadingSkeleton" @click.prevent="showModalCreateSingle()" block size="lg" type="indigo" class="text-uppercase">
              AVULSO
            </base-button>
        </div>
        <div class="col-md-2 mb-2">
          <back-button :disabled="loadingSkeleton"/>
        </div>
      </div>
      <div class="row align-items-center pb-0 mb-3" v-if="loadingSkeleton">
        <div class="col-md-4">
          <skeleton-puzl type="button"/>
        </div>
        <div class="col-md-8"/>
        <div class="col-md-3">
          <skeleton-puzl type="button"/>
        </div>
        <div class="col-md-9"/>
        <div class="col-md-4">
          <skeleton-puzl type="button"/>
        </div>
        <div class="col-md-8"/>
      </div>
      <div class="row" v-else>
        <div class="col-md-12">
          <div class="checklist-item checklist-item-primary">
            <div class="checklist-info">
              <h2 class="checklist-title mb-0">
                Código: <span
                class="small">{{ contract_proposal.code }}
              </span>
              </h2>
              <h2 class="checklist-title mb-0">
                Cliente: <span
                class="small">
                  {{
                  contract_proposal && contract_proposal.construction && contract_proposal.construction.customer_construction.customer_name
                }}
                </span>
              </h2>
              <h2 class="checklist-title mb-0">
                Obra: <span
                class="small">
                  {{
                  contract_proposal && contract_proposal.construction && contract_proposal.construction.construction_name
                }}
                </span>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </base-header>
    <multi-filter
      class="mt-4"
      @fetch="fetch"
      :filter="filter"
      :type="2"
      :status="null"
    />
    <list ref="listBills"/>
    <create-invoice @fetch="fetch"  ref="createInvoice"/>
    <create-single @fetch="fetch" ref="createSingle"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import MultiFilter from "@/components/Utils/MultiFilter";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import BackButton from "@/components/Utils/BackButton";
import List from './Shared/_List'
import CreateInvoice from './Shared/_CreateInvoice'
import CreateSingle from './Shared/_CreateSingle'
export default {
  name: "Index",
  components: {
    BackButton,
    SkeletonPuzl,
    MultiFilter,
    CreateInvoice,
    List,
    CreateSingle,
  },
  data() {
    return {
      filter: {},
      loadingSkeleton: true,
    }
  },
  computed: {
    ...mapGetters({
      contract_proposal: "contractProposal/show",
    })
  },
  methods: {
    init() {
      this.loadingSkeleton = true
      this.$store
        .dispatch(
          "contractProposal/show",
          this.$route.params.contract_proposal_uuid
        )
        .then(() => {
          this.$refs.listBills.init({})
          this.loadingSkeleton = false
        }).catch(() => {
        this.loadingSkeleton = false
      });
    },
    async showInvoice() {
      const selecteds = this.$refs.listBills.getSelecteds()
      if (!selecteds.selecteds.length) {
        return this.$notify({type: 'warning', message: 'Selecione ao menos um item a ser faturado.'})
      }
      this.$refs.createInvoice.openModal(selecteds.selecteds, selecteds.has_open)
    },
    showModalCreateSingle() {
      this.$refs.createSingle.openModal()
    },
    fetch() {
      this.$refs.listBills.init({})
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style scoped>

</style>
