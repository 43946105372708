<template>
  <div>
    <div
      class="d-flex justify-content-start align-items-center"
      style="gap: 8px"
      v-bind="$attrs"
    >
      <img
        src="/img/icons/icons8/ios/minus_key.png"
        alt="minus_key"
        width="24px"
        v-if="item.childrens && item.childrens.length && expanded"
        @click="toggle"
        class="pointer"
      />
      <img
        v-else-if="item.childrens && item.childrens.length && !expanded"
        src="/img/icons/icons8/ios/plus_key.png"
        alt="plus_key"
        width="24px"
        class="pointer"
        @click="toggle"
      />
      <span
        class="pointer identity-item"
        v-if="!parent"
        @click.prevent="handleUpdate(item.uuid)"
        >{{ item.ordered_code }}. {{ item.name }}</span
      >
      <span
        class="pointer identity-item"
        v-else
        @click.prevent="handleUpdate(item.uuid)"
        >{{ item.ordered_code }}. {{ item.name }}<span v-if="item.description"> - {{ item.description }}</span></span
      >
      <div
        class="checklist-options d-flex justify-content-end align-items-center"
      >
        <img
          src="/img/icons/icons8/ios/camera-black.png"
          alt="minus_key"
          width="24px"
          class="pointer"
          v-if="item.photo_requirement"
        />
        <img
          src="/img/icons/icons8/ios/road-closure.png"
          alt="minus_key"
          width="24px"
          class="pointer"
          v-if="item.operation_lock"
        />
        <img
          src="/img/icons/icons8/ios/comment.png"
          alt="minus_key"
          width="24px"
          class="pointer"
          v-if="item.observation_requirement"
        />
        <div class="ml-1 pt-1" v-if="parent">
          <label
            class="app-badge"
            :class="{
              'green-backgroud-light': item.active,
              'red-backgroud-light': !item.active,
            }"
            style="width: 130px"
          >
            <input
              type="checkbox"
              v-model="item.active"
              @input="handleStatusUpdate(item.uuid, $event)"
            />
            <span
              style="gap: 4px"
              class="d-flex justify-content-center align-items-center"
              :class="{
                'green-text-badge': item.active,
                'red-text-badge': !item.active,
              }"
            >
              <img
                :src="
                  item.active
                    ? '/img/icons/ok--v1.png'
                    : '/img/icons/icons8/ios/cancel_danger.png'
                "
                alt="..."
                width="14px"
              />
              {{ item.active ? "Ativo" : "Inativo" }}
            </span>
          </label>
        </div>
      </div>
    </div>
    <div
      class="body-list pt-3 pb-1 pt-1 pl-4 child-list"
      v-if="item.childrens && item.childrens.length && expanded"
    >
      <ul>
        <li
          class="d-flex"
          v-for="(child, index) in item.childrens"
          :key="index"
        >
          <Item
            :item="child"
            :parent="item"
            data-class="item-child"
            @update-checklist-item="handleUpdate(child.uuid)"
            @update-status-checklist-item="
              handleStatusUpdate(child.uuid, $event)
            "
          ></Item>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Item from "./_Item";
export default {
  name: "Item",
  inheritAttrs: false,
  components: {
    Item,
  },
  props: {
    parent: {
      type: Object,
      default: null,
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
    },
    handleUpdate(uuid) {
      this.$emit("update-checklist-item", uuid);
    },
    handleStatusUpdate(uuid, event) {
      this.checklistStatusUpdate({
        checklist_uuid: this.$route.params.uuid,
        uuid: uuid,
        active: event.target.checked,
      });
    },
    checklistStatusUpdate(param) {
      this.$Progress.start();
      this.$store
        .dispatch("itemCheckList/updateStatus", param)
        .then((response) => {
          this.$Progress.finish();
        })
        .catch(() => {
          this.$Progress.finish();
        });
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
};
</script>

<style scoped>
span {
  font-family: "Fredoka";
}
div.child-list {
  border-top: 0.5px solid #e8e8e8;
  margin-top: 10px;
}
div.body-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
div.body-list ul > li {
  gap: 8px;
  flex-direction: column;
}
div.body-list ul > li:not(:last-child) {
  margin-bottom: 1.5%;
}
div.body-list ul > li div[data-class="item-child"] {
  gap: 8px;
}
div.checklist-options {
  flex: 1;
  gap: 8px;
}
.green-text-badge {
  color: #149e57;
}
.red-text-badge {
  color: #db4539;
}
.green-backgroud-light {
  background: #f2f7f3;
}
.red-backgroud-light {
  background: #fcf3f3;
}
label.app-badge {
  display: flex;
  max-width: 100%;
  height: 24px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  cursor: pointer;
}

label.app-badge > input[type="checkbox"] {
  display: none;
}
label.app-badge > span {
  font-family: "Fredoka";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
