<template>
  <div>
    <modal size="md" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">

            <table class="table table-sm">
              <thead>
              <tr>
                <th>Nivel</th>
                <th>Definição comercial</th>
                <th>Orientação</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th>
                  Baixa
                </th>
                <th>
                  <base-input
                    maxlength="24"
                    v-model="applicationLevelGuideline.commercial_definition_low"
                    input-group-classes="input-group-sm">
                  </base-input>
                </th>
                <th>
                  <base-input input-group-classes="input-group-sm">
                        <textarea
                          type="text" :rows="calcRow(applicationLevelGuideline.description_low)"
                          class="form-control form-control-sm"
                          v-model="applicationLevelGuideline.description_low">
                        </textarea>
                  </base-input>
                </th>
              </tr>
              <tr>
                <th>
                  Média
                </th>
                <th>
                  <base-input
                    maxlength="24"
                    v-model="applicationLevelGuideline.commercial_definition_medium"
                    input-group-classes="input-group-sm">
                  </base-input>
                </th>
                <th>
                  <base-input input-group-classes="input-group-sm">
                        <textarea
                          type="text" :rows="calcRow(applicationLevelGuideline.description_medium)"
                          class="form-control form-control-sm"
                          v-model="applicationLevelGuideline.description_medium">
                        </textarea>
                  </base-input>
                </th>

              </tr>
              <tr>
                <th>
                  Alta
                </th>
                <th>
                  <base-input
                    maxlength="24"
                    v-model="applicationLevelGuideline.commercial_definition_high"
                    input-group-classes="input-group-sm">
                  </base-input>
                </th>
                <th>
                  <base-input input-group-classes="input-group-sm">
                        <textarea
                          type="text" :rows="calcRow(applicationLevelGuideline.description_high)"
                          class="form-control form-control-sm"
                          v-model="applicationLevelGuideline.description_high">
                        </textarea>
                  </base-input>
                </th>
              </tr>
              </tbody>
            </table>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')">
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
const {formatErrorValidation} = require("@/plugins");

export default {
  name: "ModalCreate",
  data() {
    return {
      modal: {
        title: 'Cadastrar orientações técnicas para nível de aplicação',
        create: false,
      },
      applicationLevelGuideline: {
        description_low: '',
        description_medium: '',
        description_high: '',
        commercial_definition_low: '',
        commercial_definition_medium: '',
        commercial_definition_high: '',
      },
      loadingStore: false,
    };
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    handleCreateModal() {
      let loader = this.$loading.show()
      this.$store.dispatch('applicationLevelGuideline/fetchItems').then(response => {
        this.applicationLevelGuideline = response.data ? response.data : {
          description_low: '',
          description_medium: '',
          description_high: '',
          commercial_definition_low: '',
          commercial_definition_medium: '',
          commercial_definition_high: '',
        }
        loader.hide()
        this.modal.create = true;
      })
    },
    calcRow(val) {
      let length = val ? val.length : ''
      if (length > 24 && length <= 40) {
        return 2
      }
      if (length > 40) {
        return 3
      }
      return 1
    },
    store() {
      this.$Progress.start()
      this.loadingStore = true;
      this.$store.dispatch('applicationLevelGuideline/add', this.applicationLevelGuideline)
        .then((response) => {
          // this.$notify({
          //   type: response.error_type,
          //   message: response.message
          // })
          this.closeModal();
        })
        .catch((error) => {
          if (error.status === 200) {
            this.$notify({
              type: 'danger',
              message: error.data.message
            });
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors)
            this.$notify({
              type: 'danger',
              message: errors
            });
          }
        }).finally(() => {
        this.$Progress.finish();
        this.loadingStore = false;
      });
    },
  },
};
</script>

<style scoped></style>
