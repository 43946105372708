import * as types from './mutation_types'
import {destroy, update} from '@/store/baseStore'
import { createAxios } from "@/plugins/axios";
const endPoint = '/cmc/supplier'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
    origins: [],
  },
  getters:{
    show: state => state.item,
    fetch: state => state.items,
    fetch_origins: state => state.origins,
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
    [types.SET_ORIGINS] (state, id){
      let [supplier] = state.items.filter((item) => item.id === id)
      state.origins = supplier.supplier_origins
    },
    [types.PUSH] (state, payload){
      state.items.push(payload)
    },
    [types.SHOW] (state, payload){
      state.item = payload
    },
    [types.DESTROY] (state, uuid){
      destroy(state,uuid)
    },
    [types.UPDATE] (state, payload){
      update(state, payload)
    },
  },
  actions: {
    fetchItems({commit}, params) {
      return createAxios().get(endPoint, {params: params})
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    add({commit, state}, payload) {
      return createAxios().post(`${endPoint}`, payload)
        .then(({data}) =>{
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    update({commit}, payload) {
      return createAxios().put(`${endPoint}/${payload.uuid}`, payload)
        .then(({data}) =>{
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    show({commit, dispatch}, uuid) {
      return createAxios().get(`${endPoint}/${uuid}`)
        .then(({data}) =>{
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchBySupplierId({commit}, id) {
          commit(types.SET_ORIGINS, id)
    },
    destroy({commit, dispatch}, uuid) {
      return createAxios().delete(`${endPoint}/${uuid}`)
        .then(({data}) =>{
          commit(types.DESTROY, uuid)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
