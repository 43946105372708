import sapConfigurationStoreActions from './sapConfigurationStoreActions';
import sapConfigurationStoreGetters from './sapConfigurationStoreGetters';
import sapConfigurationStoreMutations from './sapConfigurationStoreMutations';
import sapConfigurationStoreStateKeys from './sapConfigurationStoreStateKeys';


const sapConfigurationStore = {
  ...sapConfigurationStoreActions,
  ...sapConfigurationStoreGetters,
  ...sapConfigurationStoreMutations,
  sapConfigurationStoreStateKeys
};

export default sapConfigurationStore;