<template>
  <div>
    <modal @close="close" :show.sync="show" size="lg">
      <template slot="header">
        <h5 class="modal-title pl-2 ml-1">{{ title }}</h5>
      </template>
      <multi-filter @fetch="init" :filter="{}" />
      <div class="container-fluid">
        <div class="row card-wrapper">
          <SkeletonPuzlGrid v-for="index in 3" v-show="loading" :key="index" />
        </div>
        <div class="row align-items-center" v-if="!company_plant_issuers.length && !loading">
          <div class="col-md-12 text-center">
            <div class="card p-4">
              Sem dados cadastrados!
            </div>
          </div>
        </div>
        <div class="row ">
          <div class="col-md-12" v-if="company_plant_issuers.length && !loading" v-for="(item, index) in company_plant_issuers">
            <div
              class="border-left border-3 d-flex justify-content-between p-2 mb-4"
              :class="item.status ? 'border-success' : 'border-danger'"
            >
              <div class="col-10">
                <div class="mt-2">
                  <h5 class="p-0 m-0">
                    {{ item.alias_name || item.business_name }}
                  </h5>
                  <small>
                    {{ item.ein }}
                  </small>
                </div>
                <collapse class="border rounded p-0 mt-3">
                  <collapse-item class="header-gray" back-ground="border-header-primary-bottom">
                    <h5 slot="title" class="mb-0 ">Detalhes</h5>
                    <div class="row">
                      <div class="col-md-12">
                        <h5 class="h4 mb-0">Inscrição municipal</h5>
                        <h5 class="h4 mb-0 small ml-4">
                          {{ item.municipal_registration || "-" }}
                        </h5>
                      </div>
                    </div>
                    <div class="row mt-1">
                      <div class="col-md-12">
                        <h5 class="h4 mb-0">Inscrição estadual</h5>
                        <h5 class="h4 mb-0 small ml-4">
                          {{ item.state_registration || "-" }}
                        </h5>
                      </div>
                    </div>
                    <div class="row mt-1">
                      <div class="col-md-12">
                        <h5 class="h4 mb-0">Código IBGE</h5>
                        <h5 class="h4 mb-0 small ml-4">
                          {{ item.ibge_code || "-" }}
                        </h5>
                      </div>
                    </div>
                  </collapse-item>
                </collapse>
              </div>
              <div class="col-2 d-flex justify-content-center align-items-center">
                <base-button size="sm" type="success" @click="handleSelect(item.id, index)">
                  <i class="fa fa-check text-white"></i>
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import MultiFilter from "@/components/Utils/MultiFilter.vue";
import { mapGetters } from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";

export default {
  props: ["show"],
  name: "ModalAddIssuerDocument",
  components: { MultiFilter, SkeletonPuzlGrid },
  data() {
    return {
      title: "Buscar CNPJ Emissor",
      modal: false,
      loading: false,
      types: ["danger", "success"],
      company_plant_id: null
    };
  },
  watch: {
    show: function(val) {
      if (val) {
        this.init();
      }
    }
  },
  computed: {
    ...mapGetters({
      company_plant_issuers: "companyPlantIssuer/fetch"
    })
  },
  methods: {
    close() {
      this.$emit("close");
    },
    handleSelect(id, index) {
      let params = {
        company_plant_issuer_id: id,
        company_plant_id: this.company_plant_id
      };
      this.$Progress.start();
      this.loading = true;
      this.$notify({ type: "info", message: "Estamos trabalhando em sua solicitação." });
      this.$store
        .dispatch("plant/addCompanyPlantIssuer", params)
        .then(response => {
          this.$Progress.finish();
          this.loading = false;
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          const itemIssuer = this.company_plant_issuers[index];
          this.$emit("addIssuerToPlant", {
            item_issuer: itemIssuer,
            company_plant_id: this.company_plant_id
          });
          this.company_plant_issuers.splice(index, 1);
        })
        .catch(() => {
          this.loading = false;
          this.$Progress.finish();
        });
    },
    init(filter = null) {
      this.$Progress.start();
      this.loading = true;
      let params = {
        filter: {
          not_in_plant_has_issuers: true,
          company_plant_id: this.company_plant_id,
          global: filter ? filter.global : [],
        }
      };
      this.$notify({ type: "info", message: "Estamos trabalhando em sua solicitação." });
      this.$store
        .dispatch("companyPlantIssuer/fetchItems", params)
        .then(response => {
          this.$Progress.finish();
          this.loading = false;
          this.$notify({
            type: response.error_type,
            message: response.message
          });
        })
        .catch(() => {
          this.loading = false;
          this.$Progress.finish();
        });
    }
  }
};
</script>

<style scoped></style>
