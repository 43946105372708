<template>
  <div>
    <modal size="lg" :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ createNew ? 'Novo Cliente' : title }}</h5>
      </template>
      <div>
        <div class="col-12">
          <div class="d-flex align-items-center">
            <img height="23px" src="/img/icons/icons8/ios/info-squared.png" class="mr-3" />
            <b class="text-dark mr-3">Identificação</b>
            <hr class="bg-secondary flex-grow-1 ml-2 mr-2">
          </div>
        </div>
        <div class="form-group row m-0 p-0 mb-1">
          <div class="col-12 mb-2" v-if="!customerConstruction">
            <base-button
              @click.prevent="handleCreateConstruction()"
              v-if="!createNew"
              :class="createNew && 'active'"
              class="float-left"
              size="sm"
              type="success"
            >
              <i class="fas fa-plus"></i> Novo
            </base-button>
          </div>
          <label class="col-md-5 pt-1 pb-0 col-form-label form-control-label" v-if="!createNew">
            Cliente
            <span class="text-danger">&nbsp;*</span>
          </label>
          <div class="col-md-7" v-if="!createNew">
            <validation-provider rules="required" v-slot="{errors}">
              <base-input input-classes="form-control-sm">
                <PuzlSelect
                  v-model="selected.customer_uuid"
                  :items="labelCustomers"
                  customKey="uuid"
                  :disabled="disabledCustomer"
                  @input="validCreateNew" />
              </base-input>
            </validation-provider>
          </div>
        </div>
        <div v-show="createNew">
          <validation-observer
            v-slot="{ invalid, handleSubmit }"
            ref="formValidator"
          >
            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pt-1 pb-0 col-form-label form-control-label">
                Cliente
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      v-model="customer.customer_name"
                      class="form-control form-control-sm"
                      :class="errors[0] ? 'is-invalid' : 'is-valid'"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pt-1 pb-0 col-form-label form-control-label">
                CPF/CNPJ
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider :rules="'document|required'" v-slot="{errors}">
                  <base-input input-group-classes="input-group-sm" group class="mb-2">
                    <input
                      type="text" v-model="customer.document" class="form-control form-control-sm"
                      v-mask="['###.###.###-##', '##.###.###/####-##']"
                      :class="customer.document && customer.document.length > 0 && ! verifyDocument(customer.document) && 'is-invalid' || errors[0] ? 'is-invalid' : 'is-valid'"
                    />
                  </base-input>
                  <small v-show="customer.document && customer.document.length > 0 && ! verifyDocument(customer.document)"><i
                    class="fas fa-exclamation-triangle text-danger mr-2"></i>
                    <strong class="" style="color: #7e7e7e">CPF/CNPJ Inválido!</strong>
                  </small>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0 mb-1">
              <div class="col-12">
                <base-button
                  @click.prevent="saveConstruction()"
                  v-bind:disabled="invalid"
                  :loading="loadingStore"
                  class="float-right mt-2"
                  type="success"
                >
                  Salvar
                </base-button>
                <base-button class="float-right mt-2 mr-2" type="secondary" @click="createNew = !createNew">
                  Cancelar
                </base-button>
              </div>
            </div>
          </validation-observer>
        </div>
        <template v-if="selected.customer_uuid">
          <validation-observer
            v-slot="{ invalid, handleSubmit }"
            ref="formValidator"
          >
            <form
              class="needs-validation"
              @submit.prevent="handleSubmit(save)"
              autocomplete="off"
            >
              <div>
                <div class="form-group row m-0 p-0 mb-1 mt-3">
                  <div class="col-md-5">
                    <div class="pb-1 mt-n1 col-form-label form-control-label">
                      Nome da Construção
                      <span class="text-danger">*</span>
                      <a href="javascript:void(0)">
                        <i
                          class="fa-solid fa-copy ml-2 text-dark"
                          @click.prevent="copyCustomerName"
                        >
                        </i>
                      </a>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <validation-provider rules="required">
                      <base-input input-group-classes="input-group-sm pb-1 mb-1">
                        <input
                          type="text"
                          v-model="construction.construction_name"
                          class="form-control form-control-sm"
                          maxlength="150"
                          @keyup="construction.construction_name.length ? checkConstructionName = true : checkConstructionName = false;"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div v-show="checkConstructionName">
                  <div class="form-group row m-0 p-0 mt-2">
                    <div class="col-md-5">
                      <div class="pb-1 col-form-label form-control-label">
                        Central Padrão
                        <span class="text-danger">*</span>
                      </div>
                    </div>
                    <div class="col-md-7">
                      <validation-provider rules="required">
                        <base-input input-classes="form-control-sm">
                          <PuzlSelect
                            v-model="construction.company_plant_uuid"
                            :items="plants"
                            customKey="uuid"
                            :loading="loadingCompanyPlant"
                            :disabled="loadingCompanyPlant"
                            @input="selectedPlant" />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0 mt-2">
                    <div class="col-md-5">
                      <div class="pb-1 col-form-label form-control-label">
                        Vendedor
                        <span class="text-danger">*</span>
                      </div>
                    </div>
                    <div class="col-md-7">
                      <validation-provider rules="required">
                        <base-input input-classes="form-control-sm">
                          <PuzlSelect
                            v-model="construction.seller_uuid"
                            :items="users"
                            customKey="uuid"
                            :loading="loadingUsers"
                            :disabled="loadingUsers || !selected_plant" />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0 mt-2">
                    <div class="col-md-5">
                      <div class="pb-1 col-form-label form-control-label">
                        Método de Prospecção
                        <span
                          class="btn btn-sm btn-success p-1"
                          @click.prevent="handleCreateProspectionMethod()"
                        >
                          <i class="fas fa-plus"></i> Novo
                        </span>
                      </div>
                    </div>
                    <div class="col-md-7">
                      <base-input input-classes="form-control-sm">
                        <PuzlSelect
                          v-model="construction.prospection_method_construction_id"
                          :items="prospection_methods"
                          label="description"
                          :disabled="loadingProscpection" />
                      </base-input>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0 mt-1">
                    <div class="col-md-5">
                      <div class="pb-1 col-form-label form-control-label">
                        Tipo
                        <span class="btn btn-sm btn-success p-1" @click.prevent="handleCreateType()">
                          <i class="fas fa-plus"></i>
                          Novo
                        </span>
                      </div>
                    </div>
                    <div class="col-md-7">
                      <base-input input-classes="form-control-sm pb-1 mb-1">
                        <PuzlSelect
                          v-model="construction.type_construction_id"
                          :items="types"
                          label="description"
                          :disabled="loadingTypes" />
                      </base-input>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0 mb-1 mt-1">
                    <div class="col-md-5">
                      <div class="pb-1 col-form-label form-control-label">
                        Restrição de acesso
                      </div>
                    </div>
                    <div class="col-md-7">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          type="text"
                          v-model="construction.access_restriction"
                          class="form-control form-control-sm"
                        />
                      </base-input>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0 mb-1 mt-2">
                    <div class="col-md-5">
                      <div class="pb-1 col-form-label form-control-label">
                        Volume total estimado
                      </div>
                    </div>
                    <div class="col-md-7">
                      <base-input input-group-classes="input-group-sm pb-1 mb-1">
                        <input
                          inputmode="numeric"
                          v-model="construction.estimated_volume"
                          class="form-control form-control-sm"
                          v-mask="['#.#', '##.#', '###.#', '####.#', '#####.#', '######.#', '#######.#', '########.#']"
                        />
                        <template slot="append">
                          <small class="input-group-sm p-0 m-0">
                            m<sup>3</sup>
                          </small>
                        </template>
                      </base-input>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0 mb-1 mt-2">
                    <div class="col-md-5">
                      <div class="pb-1 col-form-label form-control-label">
                        Período previsto
                      </div>
                    </div>
                    <div class="col-md-7">
                      <base-input input-group-classes="input-group-sm">
                        <input-date-picker @handleFilterDate="handleFilterDate" size="sm"/>
                      </base-input>
                    </div>
                  </div>

                  <div class="mt-3">
                    <span class="mr-3 h3">
                      <img height="32px" src="/img/icons/icons8/ios/order-delivered_primary.png" class="mr-2"/>
                      Endereço
                      <span 
                        class="btn btn-sm btn-outline-danger p-1 text-center mr-2 mt-n1 mb-1"
                        @click="setReplicateEntityAddress"
                        v-if="entityAddress"
                      >
                        <span style="font-size: 10px;">REPLICAR ENDEREÇO DO TOMADOR</span>
                      </span>
                    </span>
                    <hr class="new-default-black mt-2 mb-1">
                  </div>
                  
                  <div class="text-center text-danger mt-2">
                    <i class="fa-solid fa-triangle-exclamation"></i>
                    <b> ENDEREÇO DA OBRA E MUNICÍPIO DE TRIBUTAÇÃO DO ISS </b>
                  </div>

                  <div class="form-group row m-0 p-0">
                    <div class="col-md-4">
                      <div class="pb-0 mb-1 col-form-label form-control-label">
                        CEP<span class="text-danger">&nbsp;*</span>
                      </div>
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-group-classes="input-group-sm">
                          <input
                            type="text"
                            v-model="construction.postal_code"
                            class="form-control form-control-sm"
                            v-mask="['#####-###']"
                            v-on:blur="getAddressByCode"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                    <div class="col-md-4">
                      <div class="pb-0 mb-1 col-form-label form-control-label">
                        UF<span class="text-danger">&nbsp;*</span>
                      </div>
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-group-classes="input-group-sm">
                          <PuzlSelect
                            v-model="construction.state"
                            :items="states"
                            @change="getCities()"
                            customKey="letter"
                            label="letter"
                            :disabled="loadingStates"
                            :loading="loadingStates" />
                        </base-input>
                      </validation-provider>
                    </div>
                    <div class="col-md-4">
                      <div class="pb-0 mb-1 pr-0 col-form-label form-control-label">
                        Cidade <span class="text-danger">&nbsp;*</span>
                      </div>
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-group-classes="input-group-sm">
                          <PuzlSelect
                            v-model="construction.city"
                            :items="cities"
                            customKey="title"
                            label="title"
                            :disabled="loadingCities"
                            :loading="loadingCities" />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0">
                    <div class="col-md-12">
                      <div class="pb-0 mb-1 col-form-label form-control-label">
                        Endereço<span class="text-danger">&nbsp;*</span>
                      </div>
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-group-classes="input-group-sm">
                          <input
                            type="text"
                            v-model="construction.address"
                            class="form-control form-control-sm"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0 mb-1 mt-1">
                    <div class="col-md-4">
                      <div class="pb-0 col-form-label form-control-label">
                        N°<span class="text-danger">&nbsp;*</span>
                      </div>
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-group-classes="input-group-sm">
                          <input maxlength="5"
                                v-if="!without_number"
                                v-model="construction.number"
                                v-mask="'#####'"
                                inputmode="numeric"
                                class="form-control form-control-sm"
                          />
                          <input v-else maxlength="5"
                                v-model="construction.number"
                                disabled
                                type="text"
                                inputmode="numeric"
                                class="form-control form-control-sm"
                          />
                          <template slot="append">
                            <small class="input-group-sm p-0 m-0" style="text-transform: none">
                              <a href="#" @click.prevent="without_number = !without_number">
                                <i v-if="without_number" class="fa-solid fa-square-check"></i>
                                <i v-else class="fa-regular fa-square"></i>
                              </a> Sem número
                            </small>
                          </template>
                        </base-input>
                      </validation-provider>
                    </div>

                    <div class="col-md-4">
                      <div class="pb-0 col-form-label form-control-label">
                        Complemento
                      </div>
                      <base-input input-group-classes="form-control-sm input-group-sm">
                        <input
                          type="text"
                          v-model="construction.complement"
                          maxlength="20"
                          class="form-control form-control-sm"
                        />
                      </base-input>
                    </div>

                    <div class="col-md-4">
                      <div class="pb-0  pr-0 col-form-label form-control-label">
                        Bairro <span class="text-danger">&nbsp;*</span>
                      </div>
                      <validation-provider rules="required">
                        <base-input input-group-classes="form-control-sm input-group-sm">
                          <input type="text" v-model="construction.district" class="form-control form-control-sm"/>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>

                </div>
              </div>

              <div class="modal-footer">
                <base-button type="danger" @click="closeModal()">
                  <img src="/img/icons/cancel-white.png" width="22px" height="22px" class="mr-2" />
                  Cancelar
                </base-button>
                <base-button
                  type="success"
                  native-type="submit"
                  v-bind:disabled="invalid"
                  :loading="loadingSave"
                >
                  <img src="/img/icons/save.png" width="22px" height="22px" class="mr-2" />
                  Salvar
                </base-button>
              </div>
            </form>
          </validation-observer>
        </template>
      </div>
    </modal>
    <ModalCreateProspectionMethod ref="createProspectionMethod"/>
    <ModalCreateType ref="createType"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import axios from "axios";
import ModalCreateProspectionMethod from '@/views/Modules/Commercial/CustomerConstruction/ProspectionMethod/Shared/_Create'
import ModalCreateType from '@/views/Modules/Commercial/CustomerConstruction/Type/Shared/_Create'
import InputDatePicker from "@/components/InputDatePicker";
import moment from "moment";
import {viacep} from "@/helpers";
import PuzlSelect from "@/components/PuzlSelect";
import {verifyDocument} from "@/helpers";
const {formatErrorValidation} = require("@/plugins");

export default {
  components: {
    ModalCreateProspectionMethod,
    ModalCreateType,
    InputDatePicker,
    viacep,
    PuzlSelect,
  },
  props: {
    entityProps: {
      type: Object,
    },
  },
  name: "ModalCreateCustomerConstruction",
  data() {
    return {
      modal: false,
      title: 'Cliente/Obra',
      verifyDocument: verifyDocument,
      validated: false,
      loading: true,
      disabledCustomer: false,
      createNew: false,
      loadingStore: false,
      without_number: false,
      selected: {
        customer_uuid: ''
      },
      customer: {
        status: true,
        customer_name: '',
        document: '',
      },
      construction: {
        status: true,
        customer_construction_uuid: '',
        company_plant_uuid: null,
        seller_uuid: this.$store.state.auth.user.uuid || null,
        construction_name: '',
        web_site: '',
        access_restriction: '',
        prospection_method_construction_id: '',
        type_construction_id: '',
        estimated_volume: '',
        scheduled_beginning: '',
        scheduled_ending: '',
        total_days: '',
        defatul: true,
        alias: '',
        postal_code: '',
        district: '',
        state: '',
        city: '',
        address: '',
        number: '',
      },
      range: {
        start: new Date(),
        end: new Date(),
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      loadingSave: false,
      loadingProscpection: true,
      loadingTypes: true,
      loadingStates: true,
      loadingCities: false,
      loadingCompanyPlant: true,
      loadingUsers: false,
      selected_plant: false,
      loadingCustomer: true,
      checkConstructionName: false,
      customerConstruction: null, // para cadastro através de propostas/contratos
      labelCustomers: [],
      entityAddress: null,
    }
  },
  computed: {
    ...mapGetters({
      'customers': 'customerConstruction/fetch',
      'prospection_methods': 'customerConstructionProspectionMethod/fetch',
      'types': 'customerConstructionType/fetch',
      'states': 'localizations/states',
      'cities': 'localizations/cities',
      'plants': 'plant/activeItems',
      'users': "user/fetch",
    }),
  },
  watch: {
    'construction.scheduled_beginning': function () {
      this.getMonths()
    },
    'construction.scheduled_ending': function () {
      this.getMonths()
    },
    'without_number': function (val) {
      if (val) {
        this.construction.number = 'SN'
      } else {
        this.construction.number = ''
      }
    },
  },
  methods: {
    closeModal() {
      this.modal = false
    },
    validCreateNew() {
      if (this.createNew) {
        this.createNew = false
      }
    },
    getCustomerLabel(customer) {
      let label = customer.id + ' | ' + customer.customer_name
      if (customer.document) {
        return label + ' (' + customer.document + ') '
      }
      return label
    },
    handleChangeStatus(status) {
      this.construction.status = status;
    },
    handleCreateProspectionMethod() {
      this.$refs.createProspectionMethod.handleCreateModal()
    },
    handleCreateType() {
      this.$refs.createType.handleCreateModal()
    },
    handleFilterDate(filter) {
      this.range = filter;
      this.construction.scheduled_beginning = moment(this.range.start).format("YYYY-MM-DD");
      this.construction.scheduled_ending = moment(this.range.end).format("YYYY-MM-DD");
    },
    getMonths() {
      let dt1 = new Date(this.construction.scheduled_ending)
      let dt2 = new Date(this.construction.scheduled_beginning)

      if (dt1 !== null && dt2 !== null) {
        let diference = dt1.getTime() - dt2.getTime()
        let diference_in_days = diference / (1000 * 3600 * 24)

        if (diference_in_days) {
          this.construction.total_days = diference_in_days
        }
      }
    },
    getCities() {
      this.$Progress.start();
      this.construction.city = ''
      this.loadingCities = true
      this.$store.dispatch('localizations/fetchCities', {uf: this.construction.state})
        .then((response) => {
          this.loadingCities = false
          this.$Progress.finish()
        })
        .catch((error) => {
          this.loadingCities = false
          this.$Progress.finish()
        });
    },
    getAddressByCode() {
      this.$Progress.start();
      let postal_code = this.construction.postal_code.replace(/[^\d]+/g, '')
      let endPoint = `https://viacep.com.br/ws/${postal_code}/json/`
      let instance = axios.create();
      delete instance.defaults.headers.common['Authorization'];
      if (postal_code) {
        viacep(postal_code).then((response) => {
          if (response.data.logradouro != "") {
            this.getCities();
            this.construction.address = response.data.logradouro;
            this.construction.district = response.data.bairro;
          } else {
            this.construction.address = '';
            this.construction.district = '';
          }
          this.construction.state = response.data.uf;
          this.construction.city = response.data.localidade;
          if (response.data.erro) {
            this.$notify({
              type: 'danger',
              message: 'Erro ao carregar os dados.'
            });
          } else {
            this.$notify({
              type: 'success',
              message: 'Dados carregados com sucesso.'
            });
          }
          this.$Progress.finish();
        }).catch((error) => {
          this.$notify({type: 'danger', message: 'Não foi possível encontrar o endereço.'});
        })
      }
    },
    saveConstruction() {
      this.$Progress.start()
      this.loadingStore = true
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch('customerConstruction/add', this.customer)
        .then(response => {
          this.loadingStore = false
          this.$Progress.finish()
          this.$notify({type: response.error_type, message: response.message})
          this.createNew = false
          this.selected.customer_uuid = response.data.uuid
          this.customer = {
            status: true,
            customer_name: '',
            document:'',
          }
        })
        .catch(error => {
          if (error.status === 200) {
            this.$store.dispatch('customerConstruction/getByDocument', {document: this.customer.document})
              .then((response) => {
                this.selected.customer_uuid = response.data;
                this.createNew = false;
                this.customer = {
                  status: true,
                  customer_name: '',
                  document:'',
                }
              })
              this.$Progress.finish();
              this.loadingStore = false;
          } else if (error.response.status === 422) {
              this.$notify({type: 'danger', message: error.response.data.errors.document[0]})
              this.$Progress.finish();
              this.loadingStore = false;
          }
        })

    },
    /**
     * @param {object} address
     * @param {object}  construction
     */
    openModal(address = null, customerConstruction = null) {
      this.selected.customer_uuid = ''
      this.customer = {
        status: true,
        customer_name: '',
        document: '',
      }
      this.createNew = false
      this.construction = {
        status: true,
        customer_construction_uuid: '',
        construction_name: '',
        company_plant_uuid: null,
        seller_uuid: null,
        web_site: '',
        access_restriction: '',
        prospection_method_construction_id: '',
        type_construction_id: '',
        estimated_volume: '',
        scheduled_beginning: '',
        scheduled_ending: '',
        total_days: '',
        defatul: true,
        alias: '',
        postal_code: '',
        state: '',
        address: '',
        number: '',
        complement: '',
        district: '',
        city: '',
      }
      // Se for aberto pelo tela de propostas e contratos
      if (customerConstruction) {
        this.disabledCustomer = true;
        this.customerConstruction = customerConstruction;
        this.selected.customer_uuid = customerConstruction.uuid;
        // Verifica se o customerConstruction já está no array labelCustomers, o caso o usuário fique abrinao a modal várias vezes.
        let existsInArray = this.labelCustomers.some(item => item.uuid === this.customerConstruction.uuid);
        if (!existsInArray) {
            this.labelCustomers.push(this.customerConstruction);
        }
        this.customerConstruction.name = `${this.customerConstruction.id} | ${this.customerConstruction.customer_name}` + (this.customerConstruction.document ? ` (${this.customerConstruction.document})` : '');
      }
      if (address) {
        this.entityAddress = address;
      }

      this.modal = true
    },
    save() {
      this.$Progress.start()
      this.loadingSave = true
      this.construction.customer_construction_uuid = this.selected.customer_uuid
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch('customerConstructionConstructions/add', this.construction)
        .then(response => {
          this.loadingSave = false
          this.modal = false
          this.$Progress.finish()
          this.$notify({type: response.error_type, message: response.message})
          this.$emit('set_construction', response.data)
        })
        .catch(error => {
          if (error.status == 200) {
            //Retorna a construção se ela já existir mas não estiver cadastrada em nenhum contrato.
            this.$store.dispatch('customerConstructionConstructions/getConstructionByNameAndCustomerUuid',
             {
               construction_name: this.construction.construction_name,
               customer_construction_uuid: this.construction.customer_construction_uuid
             }
            )
            .then((response) => {
              if (response.data) {
                response.data.foundConstruction = true;
                this.$emit('set_construction', response.data);
              } else {
                this.$notify({
                  type: 'danger',
                  message: error.data.message
                });
              }
              this.closeModal();
            })
          } else if (error.response && error.response.status === 422) {
              let message = formatErrorValidation(error.response.data.errors)
              this.$notify({
                  type: 'danger',
                  message: message
              })
          }
          this.$Progress.finish();
          this.loadingSave = false;
        })
    },
    handleCreateConstruction() {
      this.customer = {
        status: true,
        customer_name: '',
      }
      this.selected.customer_uuid = ''
      this.createNew = !this.createNew
    },
    async copyCustomerName() {
      var constructionExists = null;
      const found = this.labelCustomers.find(item => item.uuid == this.selected.customer_uuid);
      await this.$store.dispatch('contractProposal/getConstructionInContracts', {'construction_name': found.customer_name})
        .then(async (response) => {
          constructionExists = response.data;
      });
      if (!constructionExists) {
        this.checkConstructionName = true;
        this.construction.construction_name = found.customer_name;
      } else {
        this.$notify({
          type: 'warning',
          message: 'Já existe um contrato com esse nome de construção'
        });
      }
    },
    /**
     * Se o usuário logado fizer parte do resultado, ele é selecionado.
     * @param {object} response
     */
    selectLoggedUser(response) {
      const foundLoggedUser = response.data.find(item => item.uuid === this.$store.state.auth.user.uuid)
      if (foundLoggedUser) {
        this.construction.seller_uuid = foundLoggedUser.uuid;
      }
    },
    selectedPlant() {
      this.construction.seller_uuid = null;
      if (this.construction.company_plant_uuid) {
        this.selected_plant = true;
        // Carrega vendedores alocados a central selecionada.
        this.loadingUsers = true;
        this.$store.dispatch("user/getSellersAllocatedByPlant", this.construction.company_plant_uuid).then(response => {
          this.selectLoggedUser(response);
          this.loadingUsers = false;
          // Se existir apenas um vendedor no select, ele será selecionado automaticamente.
          if (response.data.length === 1) {
            this.construction.seller_uuid = response.data[0].uuid;
          }
        });
      } else {
        this.selected_plant = false;
      }
    },
    setReplicateEntityAddress() {
      if (this.entityAddress) {
        this.construction.postal_code = this.entityAddress.postal_code;
        this.construction.district = this.entityAddress.district;
        this.construction.state = this.entityAddress.state;
        this.construction.city = this.entityAddress.city;
        this.construction.address = this.entityAddress.address;
        if (this.entityAddress.number === 'SN') {
          this.construction.number = 'SN';
          this.without_number = true;
        } else {
          this.construction.number = this.entityAddress.number;
        }
      }
    },
  },
  mounted() {
    this.$refs.formValidator.validate();
    this.$store.dispatch('plant/fetchItemsActive').then(() => {
      this.loadingCompanyPlant = false
    })
    this.$store.dispatch('customerConstructionProspectionMethod/fetchItems').then(() => this.loadingProscpection = false)
    this.$store.dispatch('customerConstructionType/fetchItems').then(() => this.loadingTypes = false)
    this.$store.dispatch('localizations/fetchStates').then(() => this.loadingStates = false)
  },
  beforeUpdate() {
    if (this.entityProps && this.entityProps.customer_name) {
      this.selected.customer_uuid = this.entityProps.uuid;
    }
    else {
      if (this.entityProps) {
        this.$nextTick(() => {
          this.customer = {
            status: true,
            customer_name: this.entityProps.entity_name,
            document: this.entityProps.document,
          }
        });
      }
    }

    // Se existir apenas uma central no select, ela será selecionado automaticamente.
    if (this.plants.length === 1) {
      this.construction.company_plant_uuid = this.plants[0].uuid;
      if (this.construction.company_plant_uuid) {
        this.selected_plant = true;
        this.loadingUsers = true;
        this.$store.dispatch("user/getSellersAllocatedByPlant", this.construction.company_plant_uuid).then(response => {
          this.loadingUsers = false;
          this.selectLoggedUser(response);
          // Se existir apenas um vendedor no select, ele será selecionado automaticamente.
          // Mas apenas se this.construction.seller_uuid ainda não foi definido.
          if (response.data.length === 1 && !this.construction.seller_uuid) {
            this.construction.seller_uuid = response.data[0].uuid;
          }
        });
      }
    }
  },
};
</script>

<style></style>
