<template>
  <div>
    <modal :show.sync="modal" size="lg">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div v-if="modal">
        <div class="mt-0 card mb-3">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-md-9 pr-2 pl-2 mb-2 mb-md-0" style="margin-top: 0.15rem !important">
                <base-input input-group-classes="input-group-sm">
                  <puzl-select
                    v-model="filter.formulation_ids"
                    :items="$_formulations"
                    :labelMask="true"
                    :loading="loading"
                    :multiple="true"
                    label="$title$ | $body$"
                    placeholder="Traços"
                    @input="init"
                  />
                </base-input>
              </div>
              <div class="col-md-3" />
            </div>
          </div>
        </div>
        <div class="card text-white bg-primary mb-3">
          <div class="card-header pt-1 pb-1 bg-primary">
            <div class="row">
              <div class="col-3"></div>
              <div class="col-3">
                <h4 class="m-0 text-white">Previsto</h4>
              </div>
              <div class="col-3">
                <h4 v-show="has_performed" class="m-0 text-white">Realizado</h4>
              </div>
              <div class="col-3">
                <h4 v-show="has_performed" class="m-0 text-white">Variação</h4>
              </div>
            </div>
          </div>
          <div class="card-body bg-white">
            <h3 class="card-title mb-1">Concreto</h3>
            <hr class="mt-1 mb-3 border-primary" />
            <div class="row text-default">
              <div class="col-3">
                <h4 class="m-0">Volume</h4>
              </div>
              <div class="col-3">
                <h4 class="numeric m-0 font-weight-normal">{{ $_contract_proposal_analysis.concrete.expected_volume.toFixed(1) }} m³</h4>
              </div>
              <div class="col-3">
                <h4 v-show="has_performed" class="m-0 numeric">{{ $_contract_proposal_analysis.concrete.performed_volume }} m³</h4>
              </div>
              <div class="col-3">
                <h4 v-show="has_performed" class="numeric m-0 font-weight-normal">
                  <i :class="getIconClasses($_contract_proposal_analysis.variation.concrete_volume)" class="fa strong"></i>
                  {{ $_contract_proposal_analysis.variation.concrete_volume.toFixed(1) }} m³
                </h4>
              </div>
            </div>
            <div class="row text-default">
              <div class="col-3">
                <h4 class="m-0">Viagens</h4>
              </div>
              <div class="col-3">
                <h4 class="numeric m-0 font-weight-normal">{{ $_contract_proposal_analysis.concrete.expected_quantity }}</h4>
              </div>
              <div class="col-3">
                <h4 v-show="has_performed" class="m-0 numeric">{{ $_contract_proposal_analysis.concrete.performed_quantity }}</h4>
              </div>
              <div class="col-3">
                <h4 v-show="has_performed" class="numeric m-0 font-weight-normal">
                  <i :class="getIconClasses($_contract_proposal_analysis.variation.concrete_volume, true)" class="fa strong"></i>
                  {{ $_contract_proposal_analysis.variation.concrete_travels }}
                </h4>
              </div>
            </div>
            <div class="row text-default">
              <div class="col-3">
                <h4 class="m-0">Volume médio por BT</h4>
              </div>
              <div class="col-3">
                <h4 class="m-0 numeric font-weight-normal">{{ $_contract_proposal_analysis.concrete.expected_volume_by_average }} m³</h4>
              </div>
              <div class="col-3">
                <h4 v-show="has_performed" class="m-0 numeric">{{ $_contract_proposal_analysis.concrete.performed_volume_by_average.toFixed(1) }} m³</h4>
              </div>
              <div class="col-3">
                <h4 v-show="has_performed" class="m-0 numeric font-weight-normal">
                  <i :class="getIconClasses($_contract_proposal_analysis.variation.concrete_volume_by_average)" class="fa strong"></i>
                  {{ $_contract_proposal_analysis.variation.concrete_volume_by_average.toFixed(1) }} m³
                </h4>
              </div>
            </div>
            <div class="row text-default mt-3">
              <div class="col-3">
                <h4 class="m-0">(+) Preço de venda</h4>
              </div>
              <div class="col-3">
                <h4 class="m-0 numeric font-weight-normal">
                  {{ $_contract_proposal_analysis.concrete.expected_price_by_average | currency }} /m³
                </h4>
              </div>
              <div class="col-3">
                <h4 v-show="has_performed" class="m-0 numeric">{{ $_contract_proposal_analysis.concrete.performed_price_by_average | currency }} /m³</h4>
              </div>
              <div class="col-3">
                <h4 v-show="has_performed" class="m-0 numeric font-weight-normal">
                  <i :class="getIconClasses($_contract_proposal_analysis.variation.concrete_price_by_average)" class="fa strong"></i>
                  {{ $_contract_proposal_analysis.variation.concrete_price_by_average | currency }} /m³
                </h4>
              </div>
            </div>
            <div class="row text-default">
              <div class="col-3">
                <h4 class="m-0">(=) Receita TOTAL</h4>
              </div>
              <div class="col-3">
                <h4 class="m-0 numeric font-weight-normal">
                  {{ $_contract_proposal_analysis.margin.expected_before_tax | currency }}
                </h4>
              </div>
              <div class="col-3">
                <h4 v-show="has_performed" class="m-0 numeric">{{ $_contract_proposal_analysis.margin.performed_before_tax | currency }}</h4>
              </div>
              <div class="col-3">
                <h4 v-show="has_performed" class="m-0 numeric font-weight-normal">
                  <i :class="getIconClasses($_contract_proposal_analysis.variation.total_value)" class="fa strong"></i>
                  {{ $_contract_proposal_analysis.variation.total_value | currency }}
                </h4>
              </div>
            </div>
            <div class="row text-default mb-4">
              <div class="col-3">
                <h4 class="m-0">(-) Custo MCC</h4>
              </div>
              <div class="col-3">
                <h4 class="m-0 numeric font-weight-normal">
                  {{ $_contract_proposal_analysis.concrete.expected_theorical_adjusted_by_average | currency }} /m³
                </h4>
              </div>
              <div class="col-3">
                <h4 v-show="has_performed" class="m-0 numeric">
                  {{ $_contract_proposal_analysis.concrete.performed_theorical_adjusted_by_average | currency }} /m³
                </h4>
              </div>
              <div class="col-3">
                <h4 v-show="has_performed" class="m-0 numeric font-weight-normal">
                  <i :class="getIconClasses($_contract_proposal_analysis.variation.concrete_cmc_cost, true)" class="fa strong"></i>
                  {{ $_contract_proposal_analysis.variation.concrete_cmc_cost | currency }} /m³
                </h4>
              </div>
            </div>
            <div v-if="$_contract_proposal_analysis.service.quantity > 0">
              <h3 class="card-title mb-1">Serviço</h3>
              <hr class="mt-1 mb-3 border-primary" />
              <div class="row text-default">
                <div class="col-3">
                  <h4 class="m-0">Volume de Serviços</h4>
                </div>
                <div class="col-3">
                  <h4 class="m-0 numeric font-weight-normal">{{ $_contract_proposal_analysis.service.expected_volume }} m³</h4>
                </div>
                <div class="col-3">
                  <h4 class="m-0 numeric">{{ $_contract_proposal_analysis.service.performed_volume }} m³</h4>
                </div>
                <div class="col-3">
                  <h4 class="m-0 numeric font-weight-normal">
                    <i :class="getIconClasses($_contract_proposal_analysis.variation.service_volume)" class="fa strong"></i>
                    {{ $_contract_proposal_analysis.variation.service_volume }} m³
                  </h4>
                </div>
              </div>
            </div>
            <!-- Serviços -->
            <div v-if="$_contract_proposal_analysis.service.performed_quantity > 0">
              <div class="row text-default">
                <div class="col-3">
                  <h4 class="m-0">Número de Serviços</h4>
                </div>
                <div class="col-3" />
                <div class="col-3">
                  <h4 class="m-0 numeric">{{ $_contract_proposal_analysis.service.performed_quantity }}</h4>
                </div>
                <div class="col-3" />
              </div>
              <div class="row text-default">
                <div class="col-3">
                  <h4 class="m-0">Tempo total</h4>
                </div>
                <div class="col-3">
                  <h4 class="m-0 numeric font-weight-normal">
                    {{ $_contract_proposal_analysis.service.expected_formatted_cycle }}
                  </h4>
                </div>
                <div class="col-3">
                  <h4 class="m-0 numeric">{{ $_contract_proposal_analysis.service.performed_formatted_cycle }}</h4>
                </div>
                <div class="col-3">
                  <h4 class="m-0 numeric font-weight-normal">
                    <i :class="getIconClasses($_contract_proposal_analysis.variation.service_cycle, true)" class="fa strong"></i>
                    {{ $_contract_proposal_analysis.variation.formatted_service_cycle }}
                  </h4>
                </div>
              </div>
              <div class="row text-default mb-4">
                <div class="col-3">
                  <h4 class="m-0">(+) Bombeamento</h4>
                </div>
                <div class="col-3">
                  <h4 class="m-0 numeric font-weight-normal">
                    {{ $_contract_proposal_analysis.service.expected_value | currency }}
                  </h4>
                </div>
                <div class="col-3">
                  <h4 class="m-0 numeric">{{ $_contract_proposal_analysis.service.performed_value | currency }}</h4>
                </div>
                <div class="col-3">
                  <h4 class="m-0 numeric font-weight-normal">
                    <i :class="getIconClasses($_contract_proposal_analysis.variation.service_price_by_average)" class="fa strong"></i>
                    {{ $_contract_proposal_analysis.variation.service_price_by_average | currency }}
                  </h4>
                </div>
              </div>
            </div>
            <div v-show="$_contract_proposal_analysis.concrete.has_schedule">
              <h3 class="card-title mb-1">Adicional E Avulso</h3>
              <hr class="mt-1 mb-3 border-primary" />
              <div class="row text-default mb-4">
                <div class="col-3">
                  <h4 class="m-0">(+) m³ Faltante</h4>
                </div>
                <div class="col-3">
                  <h4 class="m-0 numeric font-weight-normal">
                    {{ $_contract_proposal_analysis.additional.expected_value | currency }}
                  </h4>
                </div>
                <div class="col-3">
                  <h4 v-show="has_performed" class="m-0 numeric">{{ $_contract_proposal_analysis.additional.performed_value | currency }}</h4>
                </div>
                <div class="col-3">
                  <h4 v-show="has_performed" class="m-0 numeric font-weight-normal">
                    <i :class="getIconClasses($_contract_proposal_analysis.variation.additional_value)" class="fa strong"></i>
                    {{ $_contract_proposal_analysis.variation.additional_value | currency }}
                  </h4>
                </div>
              </div>
            </div>
            <h3 class="card-title mb-1 text-primary">Margem</h3>
            <hr class="mt-1 mb-3 border-primary" />
            <div class="row text-primary">
              <div class="col-3">
                <h4 class="m-0">(=) Pós impostos</h4>
              </div>
              <div class="col-3">
                <h4 class="m-0 numeric font-weight-normal">
                  {{ $_contract_proposal_analysis.margin.expected_after_tax | currency }}
                </h4>
              </div>
              <div class="col-3">
                <h4 v-show="has_performed" class="m-0 numeric">{{ $_contract_proposal_analysis.margin.performed_after_tax | currency }}</h4>
              </div>
              <div class="col-3">
                <h4 v-show="has_performed" class="m-0 numeric font-weight-normal">
                  <i :class="getIconClasses($_contract_proposal_analysis.variation.margin_after_tax)" class="fa strong"></i>
                  {{ $_contract_proposal_analysis.variation.margin_after_tax | currency }}
                </h4>
              </div>
            </div>
            <div class="row text-primary">
              <div class="col-3">
                <h4 class="m-0">Por m³</h4>
              </div>
              <div class="col-3">
                <h4 class="m-0 numeric font-weight-normal">
                  {{ $_contract_proposal_analysis.margin.expected_after_tax_per_m3 | currency }} /m³
                </h4>
              </div>
              <div class="col-3">
                <h4 v-show="has_performed" class="m-0 numeric">{{ $_contract_proposal_analysis.margin.performed_after_tax_per_m3 | currency }} /m³</h4>
              </div>
              <div class="col-3">
                <h4 v-show="has_performed" class="m-0 numeric font-weight-normal">
                  <i :class="getIconClasses($_contract_proposal_analysis.variation.margin_after_tax_per_m3)" class="fa strong"></i>
                  {{ $_contract_proposal_analysis.variation.margin_after_tax_per_m3 | currency }} /m³
                </h4>
              </div>
            </div>
            <div class="row text-primary mt-3">
              <div class="col-3 pr-0">
                <h4 class="m-0">(=) Pós MCC e impostos</h4>
              </div>
              <div class="col-3">
                <h4 class="m-0 numeric font-weight-normal">
                  {{ $_contract_proposal_analysis.margin.expected_after_tax_and_cmc | currency }}
                </h4>
              </div>
              <div class="col-3">
                <h4 v-show="has_performed" class="m-0 numeric">{{ $_contract_proposal_analysis.margin.performed_after_tax_and_cmc | currency }}</h4>
              </div>
              <div class="col-3">
                <h4 v-show="has_performed" class="m-0 numeric font-weight-normal">
                  <i :class="getIconClasses($_contract_proposal_analysis.variation.margin_after_tax_and_cmc)" class="fa strong"></i>
                  {{ $_contract_proposal_analysis.variation.margin_after_tax_and_cmc | currency }}
                </h4>
              </div>
            </div>
            <div class="row text-primary">
              <div class="col-3">
                <h4 class="m-0">Por m³</h4>
              </div>
              <div class="col-3">
                <h4 class="m-0 numeric font-weight-normal">
                  {{ $_contract_proposal_analysis.margin.expected_after_tax_and_cmc_per_m3 | currency }} /m³
                </h4>
              </div>
              <div class="col-3">
                <h4 v-show="has_performed" class="m-0 numeric">{{ $_contract_proposal_analysis.margin.performed_after_tax_and_cmc_per_m3 | currency }} /m³</h4>
              </div>
              <div class="col-3">
                <h4 v-show="has_performed" class="m-0 numeric font-weight-normal">
                  <i :class="getIconClasses($_contract_proposal_analysis.variation.margin_after_tax_and_cmc_per_m3)" class="fa strong"></i>
                  {{ $_contract_proposal_analysis.variation.margin_after_tax_and_cmc_per_m3 | currency }} /m³
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "ModalEbitdaAnalysis",
  components: { PuzlSelect },
  data() {
    return {
      modal: false,
      title: "Análise de Ebitda",
      loading: true,
      filter: {
        formulation_ids: null,
      },
      contract_proposal_id: null,
      has_performed:false,
    };
  },
  computed: {
    ...mapGetters({
      $_contract_proposal_analysis: "ebitdaAnalysis/getByContractProposal",
      $_formulations: "formulations/show",
    }),
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    async openModal(contract_proposal_id, contract_proposal_uuid) {
      this.loading = true;
      this.filter = {
        formulation_ids: null,
      };
      this.contract_proposal_id = contract_proposal_id;
      this.$store
        .dispatch("formulations/fetchBasic", {
          uuid: contract_proposal_uuid,
        })
        .then(() => {
          this.loading = false;
        });
      await this.init();
      /* caso haja receita/margem realizada */
      this.has_performed = this.$_contract_proposal_analysis.margin.performed_before_tax  && this.$_contract_proposal_analysis.margin.performed_before_tax  > 0;
      this.modal = true;
    },

    init() {
      let loader = this.$loading.show();
      return this.$store
        .dispatch("ebitdaAnalysis/getByContractProposal", {
          id: this.contract_proposal_id,
          params: { filter: this.filter },
        })
        .then(() => {
          loader.hide();
        });
    },

    /**
     * Retorna ícone de acordo com respectivo valor
     *
     * @param {number} value
     * @param {bool} inverted
     *
     * @returns string
     */
    getIconClasses(value, inverted = false) {
      switch (true) {
        case value > 0 && !inverted:
          return "fa-long-arrow-alt-up text-success";
        case value > 0 && inverted:
          return "fa-long-arrow-alt-up text-danger";
        case value < 0 && !inverted:
          return "fa-long-arrow-alt-down text-danger";
        case value < 0 && inverted:
          return "fa-long-arrow-alt-down text-success";
        default:
          return "fa-equals text-primary";
      }
    },
  },
};
</script>
