<template>
  <div>
    <div class="row card-wrapper">
      <div class="col-lg-4" v-for="item in listed.items" :key="item.id">
        <div class="card" style="box-shadow: none !important; border-radius: 8px !important; border: 0.5px solid #E8E8E8;">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <section class="top-section d-flex align-items-center mb-1 mt-n1">
                <span
                    v-if="item.is_adjusting || item.is_adjusting_content"
                    class="status-button-adjusting"
                  >
                      <img src="/img/icons/icons8/ios/adjust_grey.png" alt="adjust_grey" width="12px">
                      Ajustando
                </span>
                <span
                    v-else-if="!item.has_adjustment && !item.is_adjusting"
                    class="status-button-no-adjustment"
                  >
                      <img src="/img/icons/icons8/ios/adjust_grey.png" alt="adjust_grey" width="12px">
                      Sem Ajuste
                </span>
                <span
                    v-else
                    class="status-button-has-adjustment"
                  >
                      <img src="/img/icons/icons8/ios/adjust_primary.png" alt="adjust_primary" width="12px">
                      Com Ajuste
                </span>
                <div class="row m-0 ml-2">
                  <div class="mx-1">
                    <el-popover
                      class="ml-0"
                      placement="bottom"
                      trigger="click"
                    >
                      <div class="mb-n2">
                        <span>
                          <span style="font-weight: 500;color: #2B2D32;font-size: 13px;">
                            CRIAÇÃO
                          </span>
                          <hr class="hr-popover-user">
                          <span style="font-weight: 400;color: #606062;font-size: 11px;"> {{ item.user_name }} </span>
                          <br>
                          <span style="font-weight: 400;color: #606062;font-size: 11px;"> {{ item.created_at | parseDate('DD MMM YYYY  HH:mm') }} </span>
                        </span>
                      </div>
                      <base-button
                        slot="reference"
                        class="p-0 m-0 shadow-none"
                        outiline
                        size="sm"
                        type="secundary"
                      >
                        <img src="/img/icons/icons8/ios/user-male-circle--v1.png" alt="user-male-circle--v1" width="22px">
                      </base-button>
                    </el-popover>
                  </div>
                  <div
                    class="pointer ml-1"
                  >
                    <img
                      src="/img/icons/icons8/ios/star-blue.png"
                      alt="star-blue"
                      width="22px"
                      @click.prevent="onReferenceClick(item)"
                    >
                  </div>
                </div>
              </section>
              <div class="ml-auto">
                <base-dropdown>
                  <base-button slot="title-container" type="empty" size="sm" class="dropdown-toggle shadow-none mt--2 mr--2" style="border-radius: 4px !important;">
                    <img height="30px" width="30px" src="/img/icons/icons8/ios/settings--v1_primary.png" class="mr-1 mt-n1"/>
                  </base-button>
                  <span class="dropdown-item" @click.prevent="onEditItemClick(item.uuid)">
                    <img src="/img/icons/create-new.png" width="19px" height="19px" class="imgBaseButton">
                    EDITAR
                  </span>
                  <span class="dropdown-item" @click.prevent="onRemoveItemClick(item.id)">
                    <img src="/img/icons/icons8/ios/delete--v1.png" width="19px" height="19px" class="imgBaseButton">
                    REMOVER
                  </span>
                </base-dropdown>
              </div>
            </div>
            <section class="mid-section mt-2">
              <div class="mb-1">
                <span style="color: #606062;font-size: 13px;font-weight: 400;">
                  {{ item.company_plant_name }} | {{ item.charge_point_name }}
                </span>
              </div>
              <div class="mb-1">
                <span style="color: #2B2D32;font-size: 16px;font-weight: 500;">
                  {{ item.group_name }}
                </span>
              </div>
              <div class="mb-1">
                <span style="color: #2B2D32;font-size: 14px;font-weight: 400;">
                  {{ item.cmc_product }} ( {{ item.category_name ? item.category_name : 'Puzl Admix' }} )
                </span>
              </div>
              <div class="mb-1">
                <span style="color: #2B2D32;font-size: 14px;font-weight: 400;">
                  {{ item.cmc_supplier }} ( {{ item.supplier_origin }} )
                </span>
              </div>
            </section>
            <hr class="hr-card">
            <section>
              <div v-if="item.dosage_locations">
                <div v-for="(dosage, index) in item.dosage_locations" :key="index" style="color: #149E57;font-weight: 500;font-size: 12px;">
                  <span> {{ dosage.name }} </span>
                  <span v-if="dosage.code != null"> ({{ dosage.dosage_location }} {{ dosage.code }}) </span>
                  <span v-if="dosage.code === null"> {{ dosage.dosage_location }} </span>
                </div>
              </div>
              <div v-else>
                <b> Nenhum Informado </b>
              </div>
              <span style="font-weight: 400;color: #606062;font-size: 11px;" > Locais de Dosagem ({{ item.measured_unit }}) </span>
              <collapse class="p-0 mt-3" @click.native.stop="onShowDosageAdjutmentClick(item)" >
                <collapse-item :name="item.id" class="card-body-molde" back-ground="border-header-primary-bottom">
                  <h5 slot="title" class="mb-0">Ajustes</h5>
                  <div class="row mt-2">
                    <div class="col-md-12 justify-content-center">
                      <base-button
                        block
                        slot="title-container"
                        type="dark"
                        size="sm"
                        class="rounded"
                        outline
                        @click="resetAdjustmentValues(item.dosage_adjustments);item.is_adjusting = true;"
                      >
                        zerar todas
                      </base-button>
                    </div>
                  </div>
                  <div v-if="item.dosage_adjustments != null">
                    <div class="row justify-content-md-center">
                    <div class="col-md-6 text-center pt-3">
                      <div class="auxiliary-buttons d-flex justify-content-between mb-1">
                        <button
                          :class="
                              item.dosage_adjustments.binder < 0
                                ? 'text-white btn-danger'
                                : 'text-dark btn-outline-secondary'
                          "
                          class="btn btn-sm"
                          type="button"
                          @click="
                            item.dosage_adjustments.binder = toNegative(CmcAdjustmentLimitTypeEnum.keys.BINDER, item.dosage_adjustments.binder);
                            item.is_adjusting = true;
                          "
                        >
                          -
                        </button>
                          <span
                            :class="
                              item.dosage_adjustments.binder > 0
                                ? 'text-success align-self-end'
                                : item.dosage_adjustments.binder < 0
                                ? 'text-danger align-self-end'
                                : 'text-light align-self-end'
                            "
                            style="font-weight: 500;font-size: 13px;"
                          >
                            aglomerante
                        </span>
                        <button
                          :class="
                            item.dosage_adjustments.binder > 0
                              ? 'text-white btn-success'
                              : 'text-dark btn-outline-secondary'
                          "
                          class="btn btn-sm"
                          type="button"
                          @click="
                            item.dosage_adjustments.binder = toPositive(CmcAdjustmentLimitTypeEnum.keys.BINDER, item.dosage_adjustments.binder);
                            item.is_adjusting = true;
                          "
                        >
                          +
                        </button>
                      </div>
                      <base-input :disableBoxShadow="true" input-group-classes="input-group-sm" class="mt-0">
                        <input
                          maxlength="4"
                          inputmode="numeric"
                          type="number"
                          v-model="item.dosage_adjustments.binder"
                          @input="
                            item.is_adjusting = true;
                            item.dosage_adjustments.binder = validateValueFromLimit(CmcAdjustmentLimitTypeEnum.keys.BINDER, item.dosage_adjustments.binder);
                          "
                          class="form-control form-control-sm"
                        />
                        <template slot="append">
                          <small class="input-group-sm p-0 m-0"> kg/m<sup>3</sup> </small>
                        </template>
                      </base-input>
                      <base-slider
                        @input="
                          item.is_adjusting = true;
                          item.dosage_adjustments.binder = validateValueFromLimit(CmcAdjustmentLimitTypeEnum.keys.BINDER, item.dosage_adjustments.binder);
                        "
                        v-model="item.dosage_adjustments.binder"
                        :connect="true"
                        :options="{ step: 1, range: { min: parseInt(allLimits.binder.bottom), max: parseInt(allLimits.binder.top) } }"
                        :decimals="0"
                      >
                      </base-slider>
                    </div>
                    <div class="col-md-6 text-center pt-3">
                      <div class="auxiliary-buttons d-flex justify-content-between mb-1">
                        <button
                          :class="
                            item.dosage_adjustments.water < 0
                              ? 'text-white btn-danger'
                              : 'text-dark btn-outline-secondary'
                          "
                          class="btn btn-sm"
                          type="button"
                          @click="
                            item.dosage_adjustments.water = toNegative(CmcAdjustmentLimitTypeEnum.keys.WATER, item.dosage_adjustments.water);
                            item.is_adjusting = true;
                          "
                        >
                          -
                        </button>
                        <span
                        :class="
                          item.dosage_adjustments.water > 0
                            ? 'text-success align-self-center'
                            : item.dosage_adjustments.water < 0
                            ? 'text-danger align-self-center'
                            : 'text-light align-self-center'
                        "
                        style="font-weight: 500;font-size: 13px;"
                      >
                        água
                      </span>
                        <button
                          :class="
                            item.dosage_adjustments.water > 0
                              ? 'text-white btn-success'
                              : 'text-dark btn-outline-secondary'
                          "
                          class="btn btn-sm"
                          type="button"
                          @click="
                            item.dosage_adjustments.water = toPositive(CmcAdjustmentLimitTypeEnum.keys.WATER, item.dosage_adjustments.water);
                            item.is_adjusting = true;
                          "
                        >
                          +
                        </button>
                      </div>
                      <base-input :disableBoxShadow="true" input-group-classes="input-group-sm" class="mt-0">
                        <input
                          maxlength="4"
                          @input="
                            item.is_adjusting = true;
                            item.dosage_adjustments.water = validateValueFromLimit(CmcAdjustmentLimitTypeEnum.keys.WATER, item.dosage_adjustments.water);
                          "
                          inputmode="numeric"
                          type="number"
                          v-model="item.dosage_adjustments.water"
                          class="form-control form-control-sm"
                        />
                        <template slot="append">
                          <small class="input-group-sm p-0 m-0"> kg/m<sup>3</sup> </small>
                        </template>
                      </base-input>
                      <base-slider
                        @input="
                          item.is_adjusting = true;
                          item.dosage_adjustments.water = validateValueFromLimit(CmcAdjustmentLimitTypeEnum.keys.WATER, item.dosage_adjustments.water);
                        "
                        v-model="item.dosage_adjustments.water"
                        :connect="true"
                        :options="{ step: 1, range: { min: parseInt(allLimits.water.bottom), max: parseInt(allLimits.water.top) } }"
                        :decimals="0"
                      >
                      </base-slider>
                    </div>
                  </div>
                  <div class="row justify-content-md-center">
                  <div class="col-md-6 text-center pt-3">
                    <div class="auxiliary-buttons d-flex justify-content-between mb-1">
                      <button
                        :class="
                          item.dosage_adjustments.content_mortar < 0
                            ? 'text-white btn-danger'
                            : 'text-dark btn-outline-secondary'
                        "
                        class="btn btn-sm"
                        type="button"
                        @click="
                          item.dosage_adjustments.content_mortar = toNegative(CmcAdjustmentLimitTypeEnum.keys.CONTENT_MORTAR, item.dosage_adjustments.content_mortar);
                          item.is_adjusting = true;
                        "
                      >
                        -
                      </button>
                      <span
                      :class="
                        item.dosage_adjustments.content_mortar > 0
                          ? 'text-success align-self-center'
                          : item.dosage_adjustments.content_mortar < 0
                          ? 'text-danger align-self-center'
                          : 'text-light align-self-center'
                      "
                      style="font-weight: 500;font-size: 13px;"
                    >
                      teor de argamassa
                    </span>
                      <button
                        :class="
                          item.dosage_adjustments.content_mortar > 0
                            ? 'text-white btn-success'
                            : 'text-dark btn-outline-secondary'
                        "
                        class="btn btn-sm"
                        type="button"
                        @click="
                          item.dosage_adjustments.content_mortar = toPositive(CmcAdjustmentLimitTypeEnum.keys.CONTENT_MORTAR, item.dosage_adjustments.content_mortar);
                          item.is_adjusting = true;
                        "
                      >
                        +
                      </button>
                    </div>
                    <base-input :disableBoxShadow="true" input-group-classes="input-group-sm">
                      <input
                        maxlength="6"
                        @input="
                          item.is_adjusting = true;
                          item.dosage_adjustments.content_mortar = validateValueFromLimit(CmcAdjustmentLimitTypeEnum.keys.CONTENT_MORTAR, item.dosage_adjustments.content_mortar);
                        "
                        inputmode="numeric"
                        type="number"
                        v-model="item.dosage_adjustments.content_mortar"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> % </small>
                      </template>
                    </base-input>
                    <base-slider
                      @input="
                        item.is_adjusting = true;
                        item.dosage_adjustments.content_mortar = validateValueFromLimit(CmcAdjustmentLimitTypeEnum.keys.CONTENT_MORTAR, item.dosage_adjustments.content_mortar);
                      "
                      v-model="item.dosage_adjustments.content_mortar"
                      :connect="true"
                      :options="{
                        step: 0.1,
                        range: { min: parseFloat(allLimits.content_mortar.bottom), max: parseFloat(allLimits.content_mortar.top) },
                      }"
                      :decimals="1"
                    >
                    </base-slider>
                  </div>
                  <div class="col-md-6 text-center pt-3">
                    <div class="auxiliary-buttons d-flex justify-content-between mb-1">
                      <button
                        :class="
                          item.dosage_adjustments.total_air < 0
                            ? 'text-white btn-danger'
                            : 'text-dark btn-outline-secondary'
                        "
                        class="btn btn-sm"
                        type="button"
                        @click="
                          item.dosage_adjustments.total_air = toNegative(CmcAdjustmentLimitTypeEnum.keys.TOTAL_AIR, item.dosage_adjustments.total_air);
                          item.is_adjusting = true;
                        "
                      >
                        -
                      </button>
                      <span
                        :class="
                          item.dosage_adjustments.total_air > 0
                            ? 'text-success align-self-center'
                            : item.dosage_adjustments.total_air < 0
                            ? 'text-danger align-self-center'
                            : 'text-light align-self-center'
                        "
                        style="font-weight: 500;font-size: 13px;"
                      >
                      ar total
                    </span>
                      <button
                        :class="
                          item.dosage_adjustments.total_air > 0
                            ? 'text-white btn-success'
                            : 'text-dark btn-outline-secondary'
                        "
                        class="btn btn-sm"
                        type="button"
                        @click="
                          item.dosage_adjustments.total_air = toPositive(CmcAdjustmentLimitTypeEnum.keys.TOTAL_AIR, item.dosage_adjustments.total_air);
                          item.is_adjusting = true;
                        "
                      >
                        +
                      </button>
                    </div>
                    <base-input :disableBoxShadow="true" input-group-classes=" input-group-sm">
                      <input
                        maxlength="6"
                        @input="
                          item.is_adjusting = true;
                          item.dosage_adjustments.total_air = validateValueFromLimit(CmcAdjustmentLimitTypeEnum.keys.TOTAL_AIR, item.dosage_adjustments.total_air);
                        "
                        inputmode="numeric"
                        type="number"
                        v-model="item.dosage_adjustments.total_air"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> % </small>
                      </template>
                    </base-input>
                    <base-slider
                      @input="
                        item.is_adjusting = true;
                        item.dosage_adjustments.total_air = validateValueFromLimit(CmcAdjustmentLimitTypeEnum.keys.TOTAL_AIR, item.dosage_adjustments.total_air);
                      "
                      v-model="item.dosage_adjustments.total_air"
                      :connect="true"
                      :options="{
                        step: 0.1,
                        range: { min: parseFloat(allLimits.total_air.bottom), max: parseFloat(allLimits.total_air.top) },
                      }"
                      :decimals="1"
                    >
                    </base-slider>
                  </div>
                </div>
                  <div class="row">
                    <div class="col-md-6 text-center pt-3">
                      <h5
                        v-if="item.dosage_adjustments.agent != null"
                        :class="
                          item.dosage_adjustments.agent && item.dosage_adjustments.agent[0].value > 0
                            ? 'text-uppercase text-success'
                            : item.dosage_adjustments.agent && item.dosage_adjustments.agent[0].value < 0
                            ? 'text-uppercase text-danger'
                            : 'text-uppercase text-light'
                        "
                      >
                        Aditivo A
                      </h5>
                      <base-input :disableBoxShadow="true" input-classes="form-control-sm">
                        <el-select
                          @change="item.is_adjusting = true;changeFirstAgent($event, item)"
                          filterable
                          v-model="item.dosage_adjustments.agent[0].id"
                          size="mini"
                          placeholder="Selecione"
                        >
                          <el-option label="Selecione" value=""></el-option>
                          <el-option
                            v-if="!item.dosage_adjustments.agent || agent.id !== item.dosage_adjustments.agent[1].id"
                            v-for="agent in agentGroups"
                            :key="agent.id"
                            :label="agent.group_name"
                            :value="agent.id"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                      <div v-if="item.dosage_adjustments.agent && item.dosage_adjustments.agent[0].id" class="auxiliary-buttons d-flex justify-content-between my-1">
                        <button
                          :class="
                            item.dosage_adjustments.agent[0].value < 0
                              ? 'text-white btn-danger'
                              : 'text-dark btn-outline-secondary'
                          "
                          class="btn btn-sm"
                          type="button"
                          @click="
                            item.dosage_adjustments.agent[0].value = toNegative(CmcAdjustmentLimitTypeEnum.keys.AGENT, item.dosage_adjustments.agent[0].value);
                            item.is_adjusting = true;
                          "
                        >
                          -
                        </button>
                        <button
                          :class="
                            item.dosage_adjustments.agent[0].value > 0
                              ? 'text-white btn-success'
                              : 'text-dark btn-outline-secondary'
                          "
                          class="btn btn-sm"
                          type="button"
                          @click="
                            item.dosage_adjustments.agent[0].value = toPositive(CmcAdjustmentLimitTypeEnum.keys.AGENT, item.dosage_adjustments.agent[0].value);
                            item.is_adjusting = true;
                          "
                        >
                          +
                        </button>
                      </div>
                      <base-input :disableBoxShadow="true" v-if="item.dosage_adjustments.agent && item.dosage_adjustments.agent[0].id" input-group-classes="input-group-sm mt-1">
                        <input
                          maxlength="7"
                          @input="
                            item.is_adjusting = true;
                            item.dosage_adjustments.agent[0].value = validateValueFromLimit(CmcAdjustmentLimitTypeEnum.keys.AGENT, item.dosage_adjustments.agent[0].value);
                          "
                          v-model="item.dosage_adjustments.agent[0].value"
                          inputmode="numeric"
                          type="number"
                          class="form-control form-control-sm"
                        />
                        <template slot="append">
                          <small class="input-group-sm p-0 m-0"> % </small>
                        </template>
                      </base-input>
                      <base-slider
                        v-if="item.dosage_adjustments.agent[0].id"
                        @input="
                          item.is_adjusting = true;
                          item.dosage_adjustments.agent[0].value = validateValueFromLimit(CmcAdjustmentLimitTypeEnum.keys.AGENT, item.dosage_adjustments.agent[0].value);
                        "
                        v-model="item.dosage_adjustments.agent[0].value"
                        :connect="true"
                        :options="{ step: 0.01, range: { min: parseFloat(allLimits.agent.bottom), max: parseFloat(allLimits.agent.top) } }"
                        :decimals="2"
                      >
                      </base-slider>
                    </div>
                    <div class="col-md-6 text-center pt-3">
                      <h5
                        v-if="item.dosage_adjustments.agent != null"
                        :class="
                          item.dosage_adjustments.agent && item.dosage_adjustments.agent[1].value > 0
                            ? 'text-uppercase text-success'
                            : item.dosage_adjustments.agent && item.dosage_adjustments.agent[1].value < 0
                            ? 'text-uppercase text-danger'
                            : 'text-uppercase text-light'
                        "
                      >
                        Aditivo B
                      </h5>
                      <base-input :disableBoxShadow="true" input-classes="form-control-sm">
                        <el-select
                          @change="item.is_adjusting = true;changeSecondAgent($event, item)"
                          filterable
                          v-model="item.dosage_adjustments.agent[1].id"
                          size="mini"
                          placeholder="Selecione"
                        >
                          <el-option label="Selecione" value=""></el-option>
                          <el-option
                            v-if="!item.dosage_adjustments.agent || agent.id !== item.dosage_adjustments.agent[0].id"
                            v-for="agent in agentGroups"
                            :key="agent.id"
                            :label="agent.group_name"
                            :value="agent.id"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                      <div v-if="item.dosage_adjustments.agent && item.dosage_adjustments.agent[1].id" class="auxiliary-buttons d-flex justify-content-between my-1">
                        <button
                          :class="
                            item.dosage_adjustments.agent[1].value < 0
                              ? 'text-white btn-danger'
                              : 'text-dark btn-outline-secondary'
                          "
                          class="btn btn-sm"
                          type="button"
                          @click="
                            item.dosage_adjustments.agent[1].value = toNegative(CmcAdjustmentLimitTypeEnum.keys.AGENT, item.dosage_adjustments.agent[1].value);
                            item.is_adjusting = true;
                          "
                        >
                          -
                        </button>
                        <button
                          :class="
                            item.dosage_adjustments.agent[1].value > 0
                              ? 'text-white btn-success'
                              : 'text-dark btn-outline-secondary'
                          "
                          class="btn btn-sm"
                          type="button"
                          @click="
                            item.dosage_adjustments.agent[1].value = toPositive(CmcAdjustmentLimitTypeEnum.keys.AGENT, item.dosage_adjustments.agent[1].value);
                            item.is_adjusting = true;
                          "
                        >
                          +
                        </button>
                      </div>
                      <base-input :disableBoxShadow="true" v-if="item.dosage_adjustments.agent && item.dosage_adjustments.agent[1].id" input-group-classes="input-group-sm mt-1">
                        <input
                          maxlength="7"
                          @input="
                            item.is_adjusting = true;
                            item.dosage_adjustments.agent[1].value = validateValueFromLimit(CmcAdjustmentLimitTypeEnum.keys.AGENT, item.dosage_adjustments.agent[1].value);
                          "
                          v-model="item.dosage_adjustments.agent[1].value"
                          inputmode="numeric"
                          type="number"
                          class="form-control form-control-sm"
                        />
                        <template slot="append">
                          <small class="input-group-sm p-0 m-0"> % </small>
                        </template>
                      </base-input>
                      <div>
                        <base-slider
                          v-if="item.dosage_adjustments.agent[1].id"
                          @input="
                            item.is_adjusting = true;
                            item.dosage_adjustments.agent[1].value = validateValueFromLimit(CmcAdjustmentLimitTypeEnum.keys.AGENT, item.dosage_adjustments.agent[1].value);
                          "
                          v-model="item.dosage_adjustments.agent[1].value" 
                          :connect="true"
                          :options="{ step: 0.01, range: { min: parseFloat(allLimits.agent.bottom), max: parseFloat(allLimits.agent.top) } }"
                          :decimals="2"
                        >
                        </base-slider>
                      </div>
                    </div>
                  </div>
                <div class="row">
                  <div class="col-md-12 pt-4">
                    <base-input input-group-classes="input-group-sm">
                      <textarea
                        v-model="item.observation"
                        type="text"
                        rows="4"
                        class="form-control form-control-sm"
                        @input="item.is_adjusting = true;"
                      >
                      </textarea>
                    </base-input>
                  </div>
                </div>
                <div v-show="item.is_adjusting">
                  <div class="d-flex mt-3 mb-2 justify-content-between form-buttons-cmc-adjustment">
                    <div class="d-flex align-items-center button-cancel" style="gap: 5px;" @click="onShowDosageAdjutmentClick(item, true);">
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="16px">
                      <span style="font-weight: 500;">
                        Cancelar
                      </span>
                    </div>
                    <div class="d-flex align-items-center button-save" style="gap: 5px;" @click.prevent="onUpdateDosageValuesClick(item);">
                      <img src="/img/icons/icons8/ios/save_success.png" alt="save_success" width="18px">
                      <span style="font-weight: 500;">
                        Salvar
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              </collapse-item>
            </collapse>
            <collapse v-if="item.has_content" class="p-0 mt-3">
              <collapse-item class="card-body-molde" back-ground="border-header-primary-bottom">
                <h5 slot="title" class="mb-0">
                  Ajustes de Teor
                  <i v-if="item.content && item.content != 0" class="fas fa-exclamation-triangle text-default pl-2"></i>
                </h5>
                <div class="row mt-2">
                  <div class="col-md-12 justify-content-center">
                    <base-button
                      block
                      slot="title-container"
                      type="dark"
                      size="sm"
                      class="rounded"
                      outline
                      @click="
                        item.is_adjusting_content = true;
                        resetContentValues(item);
                      "
                    >
                      zerar todas
                    </base-button>
                  </div>
                </div>
                <div class="row justify-content-md-center pt-3">
                  <div class="col-md-7 text-center">
                    <div class="auxiliary-buttons d-flex justify-content-between mb-0">
                      <button
                        :class="
                          item.content < 0
                            ? 'text-white btn-danger'
                            : 'text-dark btn-outline-secondary'
                        "
                        class="btn btn-sm"
                        type="button"
                        @click="
                          item.content = toNegative(CmcAdjustmentLimitTypeEnum.keys.CONTENT, item.content);
                          item.is_adjusting_content = true;
                        "
                      >
                        -
                      </button>
                      <span
                        style="font-weight: 500;font-size: 13px;"
                        :class="
                          item.content > 0
                            ? 'text-success align-self-center'
                            : item.content < 0
                            ? 'text-danger align-self-center'
                            : 'text-light align-self-center'
                        ">
                      Teor do MCC
                    </span>
                    <button
                        :class="
                          item.content > 0
                            ? 'text-white btn-success'
                            : 'text-dark btn-outline-secondary'
                        "
                        class="btn btn-sm"
                        type="button"
                        @click="
                          item.content = toPositive(CmcAdjustmentLimitTypeEnum.keys.CONTENT, item.content);
                          item.is_adjusting_content = true;
                        "
                      >
                        +
                      </button>
                    </div>
                    <base-input :disableBoxShadow="true" input-group-classes=" input-group-sm mt-3">
                      <input
                        maxlength="6"
                        @input="
                          item.is_adjusting_content = true;
                          item.content = validateValueFromLimit(CmcAdjustmentLimitTypeEnum.keys.CONTENT, item.content);
                        "
                        inputmode="numeric"
                        type="number"
                        v-model="item.content"
                        class="form-control form-control-sm"
                        :class="item.content > 0 ? 'is-valid' : item.content < 0 ? 'is-invalid' : ''"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> % </small>
                      </template>
                    </base-input>
                    <base-slider
                      @input="
                        item.is_adjusting_content = true;
                        item.content = validateValueFromLimit(CmcAdjustmentLimitTypeEnum.keys.CONTENT, item.content);
                      "
                      v-model="item.content"
                      :connect="true"
                      :options="{ step: 1, range: { min: -100, max: 100 } }"
                      :decimals="0"
                    >
                    </base-slider>
                  </div>
                </div>
                <div class="row justify-content-md-center">
                  <div class="col-md-6 text-center pt-3">
                    <div class="auxiliary-buttons d-flex justify-content-between mb-0">
                      <button
                        :class="
                          item.content_min < 0
                            ? 'text-white btn-danger'
                            : 'text-dark btn-outline-secondary'
                        "
                        class="btn btn-sm"
                        type="button"
                        @click="
                          item.content_min = toNegative(CmcAdjustmentLimitTypeEnum.keys.CONTENT_MIN, item.content_min);
                          item.is_adjusting_content = true;
                        "
                      >
                        -
                      </button>
                      <span
                        style="font-weight: 500;font-size: 13px;"
                        :class="
                          item.content_min > 0
                            ? 'text-success align-self-center'
                            : item.content_min < 0
                            ? 'text-danger align-self-center'
                            : 'text-light align-self-center'
                        ">
                        Limitar Teor Mínimo
                    </span>
                    <button
                       :class="
                          item.content_min > 0
                            ? 'text-white btn-success'
                            : 'text-dark btn-outline-secondary'
                        "
                        class="btn btn-sm"
                        type="button"
                        @click="
                          item.content_min = toPositive(CmcAdjustmentLimitTypeEnum.keys.CONTENT_MIN, item.content_min);
                          item.is_adjusting_content = true;
                        "
                      >
                        +
                      </button>
                    </div>
                    <base-input :disableBoxShadow="true" input-group-classes="input-group-sm mt-1">
                      <input
                        maxlength="6"
                        @input="
                          item.is_adjusting_content = true;
                          item.content_min = validateValueFromLimit(CmcAdjustmentLimitTypeEnum.keys.CONTENT_MIN, item.content_min);
                        "
                        v-model="item.content_min"
                        inputmode="numeric"
                        type="number"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> % </small>
                      </template>
                    </base-input>
                    <base-slider
                      @input="
                        item.is_adjusting_content = true;
                        item.content_min = validateValueFromLimit(CmcAdjustmentLimitTypeEnum.keys.CONTENT_MIN, item.content_min);
                      "
                      v-model="item.content_min"
                      :connect="true"
                      :options="{ step: 1, range: { min: 0, max: 100 } }"
                      :decimals="0"
                    >
                    </base-slider>
                  </div>
                  <div class="col-md-6 text-center pt-3">
                     <div class="auxiliary-buttons d-flex justify-content-between mb-0">
                      <button
                        :class="
                          item.content_max < 0
                            ? 'text-white btn-danger'
                            : 'text-dark btn-outline-secondary'
                        "
                        class="btn btn-sm"
                        type="button"
                        @click="
                          item.content_max = toNegative(CmcAdjustmentLimitTypeEnum.keys.CONTENT_MAX, item.content_max);
                          item.is_adjusting_content = true;
                        "
                      >
                        -
                      </button>
                      <span
                        style="font-weight: 500;font-size: 13px;"
                        :class="
                          item.content_max > 0
                            ? 'text-success align-self-center'
                            : item.content_max < 0
                            ? 'text-danger align-self-center'
                            : 'text-light align-self-center'
                        ">
                        Limitar Teor Máximo
                    </span>
                    <button
                        :class="
                          item.content_max > 0
                            ? 'text-white btn-success'
                            : 'text-dark btn-outline-secondary'
                        "
                        class="btn btn-sm"
                        type="button"
                        @click="
                          item.content_max = toPositive(CmcAdjustmentLimitTypeEnum.keys.CONTENT_MAX, item.content_max);
                          item.is_adjusting_content = true;
                        "
                      >
                        +
                      </button>
                    </div>
                    <base-input :disableBoxShadow="true" input-group-classes="input-group-sm mt-1">
                      <input
                        maxlength="6"
                        @input="
                          item.is_adjusting_content = true;
                          item.content_max = validateValueFromLimit(CmcAdjustmentLimitTypeEnum.keys.CONTENT_MAX, item.content_max);
                        "
                        v-model="item.content_max"
                        inputmode="numeric"
                        type="number"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> % </small>
                      </template>
                    </base-input>
                    <base-slider
                      @input="
                        item.is_adjusting_content = true;
                        item.content_max = validateValueFromLimit(CmcAdjustmentLimitTypeEnum.keys.CONTENT_MAX, item.content_max);
                      "
                      v-model="item.content_max"
                      :connect="true"
                      :options="{ step: 1, range: { min: 0, max: 100 } }"
                      :decimals="0"
                    >
                    </base-slider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 pt-4">
                    <base-input input-group-classes="input-group-sm">
                      <textarea
                        v-model="item.observation_content"
                        type="text"
                        rows="4"
                        class="form-control form-control-sm"
                        @input="item.is_adjusting_content = true;"
                      >
                      </textarea>
                    </base-input>
                  </div>
                </div>
                <div v-if="item.is_adjusting_content">
                  <div class="d-flex mt-3 mb-2 justify-content-between form-buttons-cmc-adjustment">
                    <div class="d-flex align-items-center button-cancel" style="gap: 5px;" @click="item.is_adjusting_content = false;onCancelContentClick(item)">
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="16px">
                      <span style="font-weight: 500;">
                        Cancelar
                      </span>
                    </div>
                    <div class="d-flex align-items-center button-save" style="gap: 5px;" @click.prevent="onUpdateContentValuesClick(item)">
                      <img src="/img/icons/icons8/ios/save_success.png" alt="save_success" width="18px">
                      <span style="font-weight: 500;">
                        Salvar
                      </span>
                    </div>
                  </div>
                </div>
              </collapse-item>
            </collapse>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script setup>
import { computed, defineEmits } from "vue";
import cmcAdjustmentStore from "../../store";
import { CmcAdjustmentListType, CmcAdjustmentUpdateDosagesType, CmcAdjustmentUpdateContentsType } from "../../types";
import { useCmcAdjustmentHook } from "../../hooks/useCmcAdjustmentHook";
import CmcAdjustmentLimitTypeEnum from "../../enums/CmcAdjustmentLimitTypeEnum";

/** Eventos emitidos */
const emit = defineEmits([
  "onEditItemClick",
  "onRemoveItemClick",
  "onReferenceClick",
  "onShowDosageAdjutmentClick",
  "onUpdateDosageValuesClick",
  "onUpdateContentValuesClick",
  "onCancelContentClick"
]);

/** Store Getters */
const listed = computed(() => cmcAdjustmentStore.getListed());
const isListing = computed(() => cmcAdjustmentStore.getIsListing());

const {
  validateValueFromLimit,
  allLimits,
  agentGroups,
  resetAdjustmentValues,
  resetContentValues,
  toNegative,
  toPositive
} = useCmcAdjustmentHook();

/**
 * Editar item
 * @param {string} uuid
 */
function onEditItemClick(uuid) {
  emit("onEditItemClick", uuid);
}

/**
 * Remover item
 * @param {number} id
 */
function onRemoveItemClick(id) {
  emit("onRemoveItemClick", id);
}

/**
 * Abrir modal para referências
 * @param {CmcAdjustmentListType} adjustment
 */
function onReferenceClick(adjustment) {
  emit("onReferenceClick",adjustment);
}

/**
 * Cancelar edição de teores
 */
function onCancelContentClick(item) {
  emit("onCancelContentClick",item, true);
}

/**
 * Atualizar valores de dosagem
 * @param {CmcAdjustmentListType} adjustment
 */
function onUpdateDosageValuesClick(adjustment) {
  /** @type {CmcAdjustmentUpdateDosagesType} */
  const cmcAdjustmentUpdateDosagesType = {
    id: adjustment.id,
    agent: adjustment.dosage_adjustments.agent,
    water: adjustment.dosage_adjustments.water,
    binder: adjustment.dosage_adjustments.binder,
    content_mortar: adjustment.dosage_adjustments.content_mortar,
    total_air: adjustment.dosage_adjustments.total_air,
    observation: adjustment.observation
  };

  adjustment.is_adjusting = false;
  emit("onUpdateDosageValuesClick",cmcAdjustmentUpdateDosagesType);
}

/**
 * Atualizar valores de dosagem
 * @param {CmcAdjustmentListType} adjustment
 */
function onUpdateContentValuesClick(adjustment) {
  /** @type {CmcAdjustmentUpdateContentsType} */
  const cmcAdjustmentUpdateContentsType = {
    id: adjustment.id,
    content: adjustment.content,
    content_min: adjustment.content_min,
    content_max: adjustment.content_max,
    observation_content: adjustment.observation_content
  };

  adjustment.is_adjusting_content = false;
  emit("onUpdateContentValuesClick",cmcAdjustmentUpdateContentsType);
}

/**
 * Carrega informações de dosagens
 * @param {CmcAdjustmentListType} adjustment
 * @param {boolean} is_cancel
 */
function onShowDosageAdjutmentClick(adjustment, is_cancel = false) {
  if(is_cancel) {
    adjustment.is_adjusting = false;
  }
  emit("onShowDosageAdjutmentClick", adjustment, is_cancel)
}

/**
 * 
 * @param {number} agent_id 
 * @param {CmcAdjustmentListType} cmcAdjustment 
 */
function changeFirstAgent(agent_id, cmcAdjustment) {
  if(!agent_id) {
    cmcAdjustment.dosage_adjustments.agent[0].name = null;
    cmcAdjustment.dosage_adjustments.agent[0].agent_id = null;
    cmcAdjustment.dosage_adjustments.agent[0].value = 0;
  }
}

/**
 * 
 * @param {number} agent_id 
 * @param {CmcAdjustmentListType} cmcAdjustment 
 */
function changeSecondAgent(agent_id, cmcAdjustment) {
  if(!agent_id) {
    cmcAdjustment.dosage_adjustments.agent[1].name = null;
    cmcAdjustment.dosage_adjustments.agent[1].agent_id = null;
    cmcAdjustment.dosage_adjustments.agent[1].value = 0;
  }
}

</script>

<style scoped>
hr {
  border: 0;
  border-top: 0.5px solid #E8E8E8;
}
.hr-popover-user {
  margin: 7px 0;
  width: 118%;
  margin-left: -11px;
}
.hr-popover-dosage {
  margin: 7px 0;
  width: 113%;
  margin-left: -11px;
}
.hr-card {
  margin: 15px 0;
}
.status-button-has-adjustment {
  background-color: #F2F4F9;
  border-radius: 16px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #1A70B7;
  font-weight: 400;
  padding: 5px 25px;
}
.status-button-no-adjustment {
  background-color: #E8E8E8;
  border-radius: 16px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #606062;
  font-weight: 400;
  padding: 5px 25px;
}
.status-button-adjusting {
  background-color: #FEF9F2;
  border-radius: 16px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #606062;
  font-weight: 400;
  padding: 5px 25px;
}
.history-info span{
  font-size: 14px;
  font-weight: 500;
  color: #2B2D32;
}
.custom-collapse {
  box-shadow: none !important;
}

.btn {
  box-shadow: none !important;
}
.button-cancel {
  cursor: pointer;
  border: 0.5px solid #DB4539;
  border-radius: 8px;
  color: #DB4539;
  padding: 5px 53px;
}
.button-save {
  cursor: pointer;
  border: 0.5px solid #149E57;
  border-radius: 8px;
  color: #149E57;
  padding: 10px 75px;
}
.button-save:hover {
  background: #149E57;
  color: white;
}
.button-save:hover img {
  filter: brightness(0) invert(1);
}
.button-cancel:hover {
  background: #DB4539;
  color: white;
}
.button-cancel:hover img {
  filter: brightness(0) invert(1);
}
@media (max-width: 1727px) {
  .button-cancel {
    padding: 10px 20px;
    width: 100%;
    text-align: center;
    justify-content: center;
    justify-items: center;
  }
  .button-save {
    padding: 10px 20px;
    width: 100%;
    text-align: center;
    justify-content: center;
    justify-items: center;
  }
  .form-buttons-cmc-adjustment {
    flex-direction: column;
    gap: 10px;
  }
}
</style>