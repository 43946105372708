<template>
  <div class="container-fluid">
    <div class="row card-wrapper" v-show="loading">
      <SkeletonPuzlGrid v-for="(skeletons, index) in 3" :key="index" />
    </div>
    <div class="row">
      <div class="col-md-4" v-if="loading === false">
        <div :class="show_bill_receive ? 'mb-2' : 'mb-4'" class="card border-success">
          <div class="card-body">
            <div class="row m-0">
              <div class="numbers col-10 p-0">
                <h2 class="mb-3 text-success">Contas a receber</h2>
                <h3 class="mb-0">Total</h3>
                <h3 class="mb-0">{{ generalTotal.bill_receive[`${valueType}_value`] || 0 | currency }}</h3>
              </div>
              <div class="text-center col-2 p-0">
                <div class="text-center shadow icon icon-shape bg-gradient-success rounded-circle">
                  <i style="margin-right: 0.7rem" class="text-lg text-white opacity-10 ni ni-money-coins" aria-hidden="true"></i>
                  <i class="fas fa-arrow-up text-white floating-icon"></i>
                </div>
                <br />
                <div class="p-2 mt-2">
                  <i
                    role="button"
                    @click.prevent="show_bill_receive = !show_bill_receive"
                    :class="`fas fa-2x fa-${show_bill_receive ? 'minus text-danger' : 'plus-circle text-success'}`"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Detalhes -->
        <transition name="fade">
          <div class="mb-4" v-show="show_bill_receive">
            <!-- Pago e pendente -->
            <div class="row m-0">
              <div class="col-md-6 p-0 pr-md-1 mb-2">
                <div class="card border-success mb-0">
                  <div class="card-body">
                    <h4 class="mb-0 text-success">Pago | {{ $helper.percentageFrom(generalTotal.bill_receive[`${valueType}_value`], generalTotal.bill_receive[`${valueType}_value_paid`], true)}}%</h4>
                    <h3 class="mb-0">{{ generalTotal.bill_receive[`${valueType}_value_paid`] || 0 | currency }}</h3>
                  </div>
                </div>
              </div>
              <div class="col-md-6 p-0 pl-md-1 mb-2">
                <div class="card border-warning mb-0">
                  <div class="card-body">
                    <h4 class="mb-0 text-warning">Pendente | {{ $helper.percentageFrom(generalTotal.bill_receive[`${valueType}_value`], generalTotal.bill_receive[`${valueType}_value_pending`], true)}}%</h4>
                    <h3 class="mb-0">{{ generalTotal.bill_receive[`${valueType}_value_pending`] || 0 | currency }}</h3>
                  </div>
                </div>
              </div>
            </div>
            <!-- Atrasado -->
            <div class="row m-0">
              <div class="col-md-12 p-0 mb-2">
                <div class="card border-danger mb-0">
                  <div class="card-body">
                    <h4 class="mb-0 text-danger">Atrasado | {{ $helper.percentageFrom(generalTotal.bill_receive[`${valueType}_value`], generalTotal.bill_receive[`${valueType}_value_overdue`], true)}}%</h4>
                    <h3 class="mb-1">{{ generalTotal.bill_receive[`${valueType}_value_overdue`] || 0 | currency }}</h3>
                  </div>
                </div>
              </div>
            </div>
            <!-- Outros e Cancelado -->
            <div class="row m-0">
              <div class="col-md-6 p-0 pr-md-1 mb-2">
                <div class="card border-indigo mb-0">
                  <div class="card-body">
                    <h4 class="mb-0 text-indigo">Outros | {{ $helper.percentageFrom(generalTotal.bill_receive[`${valueType}_value`], generalTotal.bill_receive[`${valueType}_value_others`], true)}}%</h4>
                    <h3 class="mb-0">{{ generalTotal.bill_receive[`${valueType}_value_others`] || 0 | currency }}</h3>
                  </div>
                </div>
              </div>
              <div class="col-md-6 p-0 pl-md-1 mb-2">
                <div class="card border-danger mb-0">
                  <div class="card-body">
                    <h4 class="mb-0 text-danger">Cancelado | {{ $helper.percentageFrom(generalTotal.bill_receive[`${valueType}_value`], generalTotal.bill_receive[`${valueType}_value_canceled`], true)}}%</h4>
                    <h3 class="mb-0">{{ generalTotal.bill_receive[`${valueType}_value_canceled`] || 0 | currency }}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>

      <div v-if="loading === false" class="col-md-4">
        <div :class="show_bill_pay ? 'mb-2' : 'mb-4'" class="card border-danger">
          <div class="card-body">
            <div class="row m-0">
              <div class="numbers col-10 p-0">
                <h2 class="mb-3 text-danger">Contas a pagar</h2>
                <h3 class="mb-0">Total</h3>
                <h3 class="mb-1">{{ generalTotal.bill_pay[`${valueType}_value`] || 0 | currency }}</h3>
              </div>
              <div class="text-center col-2 p-0">
                <div class="text-center shadow icon icon-shape bg-gradient-danger rounded-circle">
                  <i style="margin-right: 0.7rem" class="text-lg text-white opacity-10 ni ni-money-coins" aria-hidden="true"></i>
                  <i class="fas fa-arrow-down text-white floating-icon"></i>
                </div>
                <br />
                <div class="p-2 mt-2">
                  <i
                    role="button"
                    @click.prevent="show_bill_pay = !show_bill_pay"
                    :class="`fas fa-2x fa-${show_bill_pay ? 'minus text-danger' : 'plus-circle text-success'}`"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Detalhes -->
        <transition name="fade">
          <div class="mb-4" v-show="show_bill_pay">
            <!-- Pago e pendente -->
            <div class="row m-0">
              <div class="col-md-6 p-0 pr-md-1 mb-2">
                <div class="card border-success mb-0">
                  <div class="card-body">
                    <h4 class="mb-0 text-success">Pago | {{ $helper.percentageFrom(generalTotal.bill_pay[`${valueType}_value`], generalTotal.bill_pay[`${valueType}_value_paid`], true)}}%</h4>
                    <h3 class="mb-0">{{ generalTotal.bill_pay[`${valueType}_value_paid`] || 0 | currency }}</h3>
                  </div>
                </div>
              </div>
              <div class="col-md-6 p-0 pl-md-1 mb-2">
                <div class="card border-warning mb-0">
                  <div class="card-body">
                    <h4 class="mb-0 text-warning">Pendente | {{ $helper.percentageFrom(generalTotal.bill_pay[`${valueType}_value`], generalTotal.bill_pay[`${valueType}_value_pending`], true)}}%</h4>
                    <h3 class="mb-0">{{ generalTotal.bill_pay[`${valueType}_value_pending`] || 0 | currency }}</h3>
                  </div>
                </div>
              </div>
            </div>
            <!-- Atrasado -->
            <div class="row m-0">
              <div class="col-md-12 p-0 mb-2">
                <div class="card border-danger mb-0">
                  <div class="card-body">
                    <h4 class="mb-0 text-danger">Atrasado | {{ $helper.percentageFrom(generalTotal.bill_pay[`${valueType}_value`], generalTotal.bill_pay[`${valueType}_value_overdue`], true)}}%</h4>
                    <h3 class="mb-0">{{ generalTotal.bill_pay[`${valueType}_value_overdue`] || 0 | currency }}</h3>
                  </div>
                </div>
              </div>
            </div>
            <!-- Outros e Cancelado -->
            <div class="row m-0">
              <div class="col-md-6 p-0 pr-md-1 mb-2">
                <div class="card border-indigo mb-0">
                  <div class="card-body">
                    <h4 class="mb-0 text-indigo">Outros | {{ $helper.percentageFrom(generalTotal.bill_pay[`${valueType}_value`], generalTotal.bill_pay[`${valueType}_value_others`], true)}}%</h4>
                    <h3 class="mb-0">{{ generalTotal.bill_pay[`${valueType}_value_others`] || 0 | currency }}</h3>
                  </div>
                </div>
              </div>
              <div class="col-md-6 p-0 pl-md-1 mb-2">
                <div class="card border-danger mb-0">
                  <div class="card-body">
                    <h4 class="mb-0 text-danger">Cancelado | {{ $helper.percentageFrom(generalTotal.bill_pay[`${valueType}_value`], generalTotal.bill_pay[`${valueType}_value_canceled`], true)}}%</h4>
                    <h3 class="mb-0">{{ generalTotal.bill_pay[`${valueType}_value_canceled`] || 0 | currency }}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div v-if="loading === false" class="col-md-4">
        <div :class="show_invoice ? 'mb-2' : 'mb-4'" class="card border-primary">
          <div class="card-body">
            <div class="row m-0">
              <div class="numbers col-10 p-0">
                <h2 class="mb-3 text-primary">Faturamento</h2>
                <h3 class="mb-0">Total</h3>
                <h3 class="mb-1">{{ generalTotal.invoice.total_value || 0 | currency }}</h3>
              </div>
              <div class="text-center col-2 p-0">
                <div class="text-center shadow icon icon-shape bg-gradient-primary rounded-circle">
                  <i class="text-lg text-white opacity-10 ni ni-money-coins" aria-hidden="true"></i>
                </div>
                <br />
                <div class="p-2 mt-2">
                  <i
                    role="button"
                    @click.prevent="show_invoice = !show_invoice"
                    :class="`fas fa-2x fa-${show_invoice ? 'minus text-danger' : 'plus-circle text-success'}`"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Detalhes -->
        <transition name="fade">
          <div class="mb-4" v-show="show_invoice">
            <!-- Pendente e Cancelado -->
            <div class="row m-0">
              <div class="col-md-6 p-0 pr-md-1 mb-2">
                <div class="card border-warning mb-0">
                  <div class="card-body">
                    <h4 class="mb-0 text-warning">Pendente | {{ $helper.percentageFrom(generalTotal.invoice.total_value, generalTotal.invoice.pending_value, true)}}%</h4>
                    <h3 class="mb-0">{{ generalTotal.invoice.pending_value || 0 | currency }}</h3>
                  </div>
                </div>
              </div>
              <div class="col-md-6 p-0 pl-md-1 mb-2">
                <div class="card border-danger mb-0">
                  <div class="card-body">
                    <h4 class="mb-0 text-danger">Cancelado | {{ $helper.percentageFrom(generalTotal.invoice.total_value, generalTotal.invoice.canceled_value, true)}}%</h4>
                    <h3 class="mb-0">{{ generalTotal.invoice.canceled_value || 0 | currency }}</h3>
                  </div>
                </div>
              </div>
            </div>
            <!-- Concreto e Serviços -->
            <div class="row m-0">
              <div class="col-md-6 p-0 pr-md-1 mb-2">
                <div class="card border-primary mb-0">
                  <div class="card-body">
                    <h4 class="mb-0 text-primary">Concreto | {{ $helper.percentageFrom(generalTotal.invoice.total_value, generalTotal.invoice.formulation_value, true)}}%</h4>
                    <h3 class="mb-0">{{ generalTotal.invoice.formulation_value || 0 | currency }}</h3>
                  </div>
                </div>
              </div>
              <div class="col-md-6 p-0 pl-md-1 mb-2">
                <div class="card border-info mb-0">
                  <div class="card-body">
                    <h4 class="mb-0 text-info">Serviços | {{ $helper.percentageFrom(generalTotal.invoice.total_value, generalTotal.invoice.service_value, true)}}%</h4>
                    <h3 class="mb-0">{{ generalTotal.invoice.service_value || 0 | currency }}</h3>
                  </div>
                </div>
              </div>
            </div>
            <!-- Adicionais e avulsos -->
            <div class="row m-0">
              <div class="col-md-6 p-0 pr-md-1 mb-2">
                <div class="card border-danger mb-0">
                  <div class="card-body">
                    <h4 class="mb-0 text-danger">Adicionais | {{ $helper.percentageFrom(generalTotal.invoice.total_value, generalTotal.invoice.additional_value, true)}}%</h4>
                    <h3 class="mb-0">{{ generalTotal.invoice.additional_value || 0 | currency }}</h3>
                  </div>
                </div>
              </div>
              <div class="col-md-6 p-0 pl-md-1 mb-2">
                <div class="card border-indigo mb-0">
                  <div class="card-body">
                    <h4 class="mb-0 text-indigo">Avulsos | {{ $helper.percentageFrom(generalTotal.invoice.total_value, generalTotal.invoice.single_value, true)}}%</h4>
                    <h3 class="mb-0">{{ generalTotal.invoice.single_value || 0 | currency }}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";

export default {
  name: "DashboardCards",
  components: {
    SkeletonPuzlGrid,
  },
  data() {
    return {
      show_bill_receive: false,
      show_bill_pay: false,
      show_invoice: false,
    };
  },
  computed: {
    ...mapGetters({
      loading: "financialDashboard/loading",
      generalTotal: "financialDashboard/generalTotal",
      valueType: "financialDashboard/valueType",
    }),
  },
};
</script>

<style scoped>
.floating-icon {
  margin-left: 1.6rem;
  font-size: 1rem;
  position: absolute;
}
.bg-gradient-danger {
  background: linear-gradient(87deg, #f74c3e 0, #cf0909 100%) !important;
}

.bg-gradient-success-alternative {
  background: linear-gradient(87deg, #228b22 0, #67b601 100%) !important;
}
</style>
