<template>
  <modal :show.sync="modal.create">
    <template slot="header">
      <h5 class="modal-title">{{ modal.title }}</h5>
    </template>
    <div>
      <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
        <form class="needs-validation p-2 mt-2" @submit.prevent="handleSubmit(store)" autocomplete="off">
          <!-- Status -->
          <div>
            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-6 pl-2 pt-2 pb-0 col-form-label form-control-label">Status </label>
              <div class="col-md-6">
                <base-switch type="success" offText="inativo" onText="ativo" class="success" v-model="payload.status"></base-switch>
              </div>
            </div>
          </div>
          <!-- Central -->
          <div class="form-group row m-0 p-0 mb-1">
            <label class="col-md-6 pl-2 pt-2 pb-0 col-form-label form-control-label">
              Central
              <span class="text-danger mr-2">&nbsp;*</span>
            </label>
            <div class="col-md-6">
              <validation-provider rules="required">
                <base-input input-group-classes="input-group-sm">
                  <PuzlSelect 
                    v-model="payload.company_plant_id" 
                    :items="$_company_plants"
                    :disabled="loadingCompanyPlants"
                    :loading="loadingCompanyPlants" 
                  />
                </base-input>
              </validation-provider>
            </div>
          </div>
          <!-- Nome -->
          <div class="form-group row m-0 p-0 mb-1">
            <label class="col-md-6 pl-2 pt-2 pb-0 col-form-label form-control-label">
              Nome
              <span class="text-danger">&nbsp;*</span>
            </label>
            <div class="col-md-6">
              <validation-provider rules="required">
                <base-input input-group-classes="input-group-sm">
                  <input v-model="payload.name" type="text" class="form-control form-control-sm" maxlength="80" />
                </base-input>
              </validation-provider>
            </div>
          </div>
          <!-- Categoria -->
          <div class="form-group row m-0 p-0 mb-1">
            <label class="col-md-6 pl-2 pt-2 pb-0 col-form-label form-control-label">
              Categoria
              <span class="text-danger mr-2">&nbsp;*</span>
            </label>
            <div class="col-md-6">
              <validation-provider rules="required">
                <base-input input-group-classes="input-group-sm">
                  <PuzlSelect
                    @input="
                      payload.product_service_hub_id = null;
                      getProductServiceByCategory($event);
                    "
                    v-model="payload.product_service_category_id"
                    :items="$_product_service_categories"
                    :disabled="loadingProductServiceCategories"
                    :loading="loadingProductServiceCategories"
                  />
                </base-input>
              </validation-provider>
            </div>
          </div>
          <!-- Combustível -->
          <div class="form-group row m-0 p-0 mb-1">
            <label class="col-md-6 pl-2 pt-2 pb-0 col-form-label form-control-label">
              Combustível
              <span class="text-danger mr-2">&nbsp;*</span>
            </label>
            <div class="col-md-6">
              <validation-provider rules="required">
                <base-input input-group-classes="input-group-sm">
                  <PuzlSelect
                    :disabled="!payload.product_service_category_id"
                    :loading="loadingProductService"
                    label="product_name"
                    v-model="payload.product_service_hub_id"
                    :items="$_products_services"
                  />
                </base-input>
              </validation-provider>
            </div>
          </div>
          <div class="modal-footer">
            <base-button type="secondary" @click="closeModal('create')"> Cancelar </base-button>
            <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore"> Salvar </base-button>
          </div>
        </form>
      </validation-observer>
    </div>
  </modal>
</template>

<script>
import { formatErrorValidation } from "@/plugins";
import PuzlSelect from "@/components/PuzlSelect";
import { mapGetters } from "vuex";
import productServiceMixin from "@/views/Modules/Configuration/ProductService/Mixins/product_service_mixin";

export default {
  name: "ModalCreate",
  mixins: [productServiceMixin],
  components: { PuzlSelect },
  data() {
    return {
      modal: {
        title: "Reservatório de Combustível",
        create: false,
      },
      payload: {
        status: true,
        company_plant_id: null,
        name: null,
        product_service_hub_id: null,
        product_service_category_id: null,
      },
      loadingStore: false,
    };
  },
  props: {
    loadingCompanyPlants: {
      type: Boolean,
    },
    loadingProductServiceCategories: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      $_company_plants: "plant/activeItems",
      $_product_service_categories: "productServiceCategory/fetch",
      $_products_services: "productService/fetch",
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal() {
      this.loadData();
      this.payload = {
        status: true,
        company_plant_id: null,
        name: null,
        product_service_category_id: null,
        product_service_hub_id: null,
      };
      this.modal.create = true;
    },
    store() {
      this.loadingStore = true;
      this.$store
        .dispatch("fuelTank/add", this.payload)
        .then((response) => {
          this.loadingStore = false;
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.closeModal();
        })
        .catch((error) => {
          if (error.status === 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
          this.loadingStore = false;
        });
    },
    loadData() {
      this.loadingCompanyPlants = true; 
      this.$store.dispatch("plant/getPlantsSimplified").then(() => {
        this.loadingCompanyPlants = false;
      });
      this.loadingProductServiceCategories = true;
      this.$store.dispatch("productServiceCategory/getCategoriesByType", {type:3, is_fuel:true}).then(() => {
        this.loadingProductServiceCategories = false;
      });
    },
  },
};
</script>