<template>
  <div>
    <list-supplier-origin></list-supplier-origin>
  </div>
</template>
<script>
import ListSupplierOrigin from './Shared/_List'


export default {
  name: 'IndexSupplierOrigin',
  components: {
    ListSupplierOrigin,
  },
  data() {
    return {
    }
  },
  methods: {
  },
  created(){
  },
  mounted() {
  }
};
</script>
<style></style>
