<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator" >
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div>
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pb-1 mt-n1 col-form-label form-control-label">
                  Grupo de Permissão
                </label>
                <div class="col-md-7">
                  <base-input input-classes="form-control-sm">
                    <puzl-select 
                      v-model="user.role_id" 
                      :items="group_permissions" 
                      :loading="loading" 
                      :disabled="loading" />
                  </base-input>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import PuzlSelect from "@/components/PuzlSelect";
const {formatErrorValidation} = require("@/plugins")

export default {
  name: "ModalSetUserRole",
  components: { PuzlSelect },
  data() {
    return {
      modal: false,
      title: 'Grupo Permissão do Usuário',
      loadingRole: true,
      loadingSave: false,
      loading: true,
      user: {
        user_id: null,
        role_id: null,
      },
      loadingStore: false,
    };
  },
  computed: {
    ...mapGetters({
      group_permissions: "authGroupPermission/fetch",
      user_role: "userRole/show",
    })
  },
  methods: {
    closeModal () {
      this.modal = false
    },
    openModal (user_id) {
      this.fetch(user_id)
      this.modal = true
    },
    fetch(user_id){
      this.$Progress.start();
      this.loading = true
      this.$store.dispatch('userRole/show', user_id).then( ({data}) => {
        this.user = {
          user_id: user_id,
          role_id: data.role.length? data.role[0].id : null,
        }
        this.loadingRole = false
        this.loading = false
        this.$Progress.finish();
      })
    },
    store() {
      this.$Progress.start();
      this.loadingStore = true;
      this.$store.dispatch("userRole/add", this.user).then(response => {
          this.loadingStore = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
        // this.$store.dispatch('user/fetchItems')
        this.modal = false
        }).catch(error => {
          if (error.status) {
            this.$Progress.finish()
            this.loadingStore = false
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            })
          } else {
            if (error.response.status === 422) {
              let message = formatErrorValidation(error.response.data.errors)
              this.$notify({
                type: 'danger',
                message: message
              })
              this.$Progress.finish()
              this.loadingStore = false
            } else {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              })
              this.$Progress.finish()
              this.loadingStore = false
            }
          }
        });
    },
  },
  mounted() {
    this.$store.dispatch("authGroupPermission/fetchItems", 0).then(() => {})
  }
}
</script>
