<template>
  <div class="row align-items-center py-3">
    <div class="col-lg-12 col-12">
      <h6 class="h3 text-gray d-inline-block mb-0">{{ this.$route.meta.breadcrumbs[1].title }}</h6>
      <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-2 text-gray">
        <ol class="breadcrumb mb-0">
          <li class="breadcrumb-item">
            <a href="#"><i :class="this.$route.meta.icon"></i></a>
          </li>
          <li class="breadcrumb-item ">
            <a href="#">{{ this.$route.meta.breadcrumbs[0].title }}</a>
          </li>
          <li class="breadcrumb-item" aria-current="page">
            {{ this.$route.meta.breadcrumbs[1].title }}
          </li>
        </ol>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PuzlBreadcrumb',
  components: {
  },
  data(){
    return {
    }
  },
}
</script>
