<template>
  <div>
    <base-header class="bg-gray-content mb-n4">
      <div class="row align-items-center py-4">
        <div class="col-md-4">
          <h2 style="font-size: 1.2rem !important;" class="text-primary">
            <img height="30px" src="/img/icons/check.png" class="mr-2"/>
            ANÁLISE DE ESTOQUE
          </h2>
        </div>
      </div>
    </base-header>
    <list ref="list"/>
  </div>
</template>
<script>

import MultiFilter from "@/components/Utils/MultiFilterV3.vue";
import List from './Shared/_List.vue'
export default {
  components: {
    MultiFilter,
    List,
  },
  name: "Index",
  data() {
    return {
    }
  },
  methods: {
  },

}
</script>
