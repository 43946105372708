<template>
  <div>
    <div class="container-fluid">
      <multi-filter ref="multi-filter" @fetch="init" :filter="filter">
        <div class="col-md-3 p-2">
          <base-button :class="filter.is_invoicing && 'active'" @click="getInvoicing" outline block type="primary">
            <img :src="filter.is_invoicing
              ? '/img/icons/check-white.png'
              : '/img/icons/check.png'
              " width="25px" height="25px" class="invert-on-hover"/>
            faturamento
          </base-button>
        </div>
        <div class="col-md-3 p-2">
          <base-button :class="filter.is_anticipation && 'active'" @click="getAnticipation" outline block type="indigo">
            <img :src="filter.is_anticipation
              ? '/img/icons/dollar-white.png'
              : '/img/icons/dollar-purple.png'
              " width="25px" height="25px" class="invert-on-hover"/>
            antecipação
          </base-button>
        </div>
      </multi-filter>
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <PuzlEmptyData v-if="!payment_terms.length && !loadingSkeleton"/>

      <div class="row card-wrapper" v-if="payment_terms.length && !loadingSkeleton">
        <div class="col-lg-4" v-for="(item, index) in payment_terms" :key="index">
          <div class="card">
            <div class="card-header pointer" style="padding: 0px !important; height: 22px">
              <div class="row">
                <div class="col-md-12">
                  <base-button
                    @click="changeStatus(item)" size="sm"
                    :style="{ backgroundColor: item.status ? '#149E57' : '#DB4539' }"
                    class="text-white text-uppercase btn-header" style="opacity: 100%; color: #4f40ff; border: none;"
                    block
                  >
                    {{ item.status ? "Ativo" : "Inativo" }}
                  </base-button>
                </div>
              </div>
            </div>
            <!-- Card header -->
            <div class="card-body">
              <div class="row align-items-center mb-3">
                <div class="col-12 row">
                  <!-- Início metodos -->
                  <div class="col-1 mt-n2 mr-1">
                    <el-popover trigger="click" placement="right" class="p-0 pr-0">
                      <div v-for="(method, index) in item.payment_methods" :key="index">
                        {{ method.name }}
                      </div>
                      <base-button outline slot="reference" size="sm" type="secundary"
                                   class="p-0 m-0 shadow-none text-indigo">
                        <img src="/img/icons/split-transaction-blue.png" width="30px" height="30px"/>
                      </base-button>
                    </el-popover>
                  </div>
                  <!-- Fim metodos -->
                  <!-- Início permissões -->
                  <div class="col-1 mt-n2 mr-1">
                    <el-popover trigger="click" placement="right" class="p-0 pr-0">
                      <h5 class="mb-1">
                        Pessoa física
                        <i v-if="item.physical_person" class="pl-1 fas fa-check text-success"/>
                        <i v-if="!item.physical_person" class="pl-1 fas fa-ban text-danger"/>
                      </h5>
                      <h5 class="mb-1">
                        Pessoa jurídica
                        <i v-if="item.legal_person" class="pl-1 fas fa-check text-success"/>
                        <i v-if="!item.legal_person" class="pl-1 fas fa-ban text-danger h-100"/>
                      </h5>
                      <h5 class="mb-1">
                        Exige aprovação financeira
                        <i v-if="item.financial_approval" class="pl-1 fas fa-check text-success"/>
                        <i v-if="!item.financial_approval" class=" pl-1 fas fa-ban text-danger"/>
                      </h5>
                      <h5 class="mb-1">
                        Exige crédito antecipado
                        <i v-if="item.requires_balance" class="pl-1 fas fa-check text-success"/>
                        <i v-if="!item.requires_balance" class=" pl-1 fas fa-ban text-danger"/>
                      </h5>
                      <base-button
                        outline slot="reference" size="sm" type="secundary"
                        class="p-0 m-0 shadow-none text-indigo"
                      >
                        <img src="/img/icons/lock-blue.png" width="30px" height="30px"/>
                      </base-button>
                    </el-popover>
                  </div>
                  <!-- Fim permissões -->
                  <!-- Início parcelas -->
                  <div class="col-1 mt-n2 mr-1">
                    <el-popover trigger="click" placement="right" class="p-0 pr-0">
                      <div class="pl-4 pr-4">
                        <!-- Titles -->
                        <div class="row pt-2" style="white-space: nowrap;">
                          <div class="mr-4 ml-n3 col-4 text-center pt-2 pr-1" style="white-space: nowrap;">
                            <h5>Parcela</h5>
                          </div>
                          <div class="mr-1 col-4 text-center pt-2 pl-1 pr-0 ml-n1" style="white-space: nowrap;">
                            <h5>Prazo</h5>
                          </div>
                          <div class="col-1 text-center pt-2 pl-1 pr-2 ml-2" style="white-space: nowrap;">
                            <h5>%</h5>
                          </div>
                        </div>
                        <hr class="my-0 mx-n3" type="primary"/>
                        <!-- Body -->
                        <div class="pb-3">
                          <div class="row" v-for="(installment, index) in item.installments" :key="index">
                            <div class="col-4 text-center pr-1 ml-n1 mr-1">
                              <small>
                                {{ installment.index }}
                              </small>
                            </div>
                            <div class="col-4 text-center pl-1 pr-0 ml-1 mr-n1">
                              <small>{{ installment.deadline }} {{
                                  installment.deadline === 1 ? 'Dia' : 'Dias'
                                }} </small>
                            </div>
                            <div class="col-4 text-center pl-1 pr-0">
                              <small>{{ installment.percentage }}%</small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <base-button outline slot="reference" size="sm" type="secundary"
                                   class="p-0 m-0 shadow-none text-indigo">
                        <img src="/img/icons/timetable.png" width="30px" height="30px"/>
                      </base-button>
                    </el-popover>
                  </div>
                  <div class="col-7" block></div>
                  <div class="col-1 ml-n1 mt-n2 text-right">
                    <base-dropdown menuOnRight>
                      <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                        <i class="fas fa-cog"></i>
                      </base-button>
                      <router-link
                        :to="{
                          path: '/financial/bill-receive/payment-term/edit',
                          name: 'Editar condição de pagamento',
                          params: { id: item.uuid }
                        }"
                      >
                        <a class="dropdown-item">
                          <i class="fas fa-edit text-warning"></i>
                          Editar
                        </a>
                      </router-link>
                      <div class="dropdown-divider p-0 m-0"></div>
                      <a class="dropdown-item" @click="handleDelete(item.uuid)">
                        <i class="fas fa-times text-danger"></i>
                        Excluir
                      </a>
                    </base-dropdown>
                  </div>
                  <!-- Fim parcelas -->
                </div>
                <!-- Card body -->
                <div class="row">
                  <div class="col-md-12">
                    <h5 class="h3 mb-2 mt-1 ml-3">
                      {{ item.name }}
                    </h5>
                    <h4 class="mb-2 mt-1 font-weight-normal ml-3">
                      {{ item.description }}
                    </h4>
                  </div>
                </div>
                <!-- Linha fina na horizontal -->
                <div class="col-12 horizontal-line mt-1 mb-3"></div>
                <!-- Botões abaixo da linha -->
              </div>

              <div class="row mt-n4 mb-n2">
                <div class="col-md-4 p-1">
                  <base-button
                    @click.prevent="changePuzlCustomer(item)"
                    block size="md" type="indigo" outline
                    style="font-size: 10px !important;border-radius: 7px !important;"
                    :class="[item.puzl_customer ? 'active' : '']"
                  >
                                          <span
                                            style="font-size: 10px;"
                                            :class="item.puzl_customer ? 'text-white' : 'text-dark'"
                                          >
                    PUZL Connect
                                          </span>
                  </base-button>
                </div>
                <div class="col-md-4 p-1">
                  <base-button
                    :class="[item.allow_in_proposal ? 'active' : '']"
                    @click.prevent="changeInvoicing(item)"
                    style="font-size: 10px !important;border-radius: 7px !important;"
                    outline block size="md" type="primary"
                  >
                                          <span
                                            style="font-size: 10px;"
                                            :class="item.allow_in_proposal ? 'text-white' : 'text-dark'"
                                          >
                    Faturamento
                                          </span>
                  </base-button>
                </div>
                <div class="col-md-4 p-1">
                  <base-button
                    :class="[item.anticipation ? 'active' : '']"
                    @click.prevent="changeAnticipation(item)"
                    style="font-size: 10px !important;border-radius: 7px !important;"
                    outline block size="md" type="indigo"
                  >
                                          <span
                                            style="font-size: 10px;"
                                            :class="item.anticipation ? 'text-white' : 'text-dark'"
                                          >
                    Antecipação
                                          </span>
                  </base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading-pagination :show="loading && !loadingSkeleton"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import MultiFilter from "@/components/Utils/MultiFilterV2";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";

export default {
  name: "ListPaymentTerm",
  mixins: [cursorPaginate],
  components: {
    SkeletonPuzlGrid,
    PuzlEmptyData,
    MultiFilter,
    LoadingPagination,
  },
  data() {
    return {
      loadingSkeleton: false,
      loadingPuzlConnect: false,
      filter: {
        is_invoicing: null,
        is_anticipation: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      payment_terms: "paymentTerm/fetch"
    })
  },
  methods: {
    /**
     * @param {object} filter
     */
    init(filter = null) {
      this.startCursor(filter)
      this.$Progress.start();
      this.$store
        .dispatch("paymentTerm/fetchItemsPaginate", {
          filter: this.filter,
          next_page: this.paginate.nextUrl
        })
        .then(response => {
          this.resolveCursor(response)
          this.$Progress.finish();
        })
        .catch(error => {
          this.resolveCursor()
          this.$Progress.finish();
        });
    },
    getInvoicing() {
      this.filter.is_invoicing = this.filter.is_invoicing === null ? 1 : null;
      this.init({});
    },
    getAnticipation() {
      this.filter.is_anticipation = this.filter.is_anticipation === null ? 1 : null;
      this.init({});
    },
    /**
     * @param {object} paymentTerm
     */
    changePuzlCustomer(paymentTerm) {
      paymentTerm.puzl_customer = !paymentTerm.puzl_customer
      this.$store.dispatch("paymentTerm/changePuzlConnect", {
        id: paymentTerm.id,
        puzl_connect: paymentTerm.puzl_customer ? 1 : 0
      }).then(response => {
        this.$notify({
          type: response.error_type,
          message: response.message
        });
      });
    },
    /**
     * @param {object} paymentTerm
     */
    changeStatus(paymentTerm) {
      const previousStatus = paymentTerm.status;
      paymentTerm.status = +!paymentTerm.status;
      this.$store.dispatch("paymentTerm/updateStatus", paymentTerm).then((response) => {
        if (response.data) {
          this.$notify({
            type: "success",
            message: "Status atualizado com sucesso!"
          });
        } else {
          paymentTerm.status = previousStatus;
          this.$notify({
            type: "danger",
            message: "Erro ao atualizar status!"
          });
        }
      }).catch((error) => {
        paymentTerm.status = previousStatus;
        this.$notify({
          type: "danger",
          message: "Erro ao atualizar status!"
        });
      });
    },
    /**
     * @param {object} paymentTerm
     */
    changeInvoicing(paymentTerm) {
      paymentTerm.allow_in_proposal = +!paymentTerm.allow_in_proposal;
      this.$store.dispatch("paymentTerm/updateInvoicing", paymentTerm).then((response) => {
        this.$notify({
          type: "success",
          message: "Status atualizado com sucesso!"
        });
      }).catch((error) => {
        this.$notify({
          type: "danger",
          message: "Erro ao atualizar status!"
        });
      });
    },
    /**
     * @param {object} paymentTerm
     */
    changeAnticipation(paymentTerm) {
      paymentTerm.anticipation = +!paymentTerm.anticipation;
      this.$store.dispatch("paymentTerm/updateAnticipation", paymentTerm).then((response) => {
        this.$notify({
          type: "success",
          message: "Status atualizado com sucesso!"
        });
      }).catch((error) => {
        this.$notify({
          type: "danger",
          message: "Erro ao atualizar status!"
        });
      });
    },
    /**
     * @param {string} uuid
     */
    handleDelete(uuid) {
      this.$Swal
        .confirmDelete()
        .then(result => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação."
            });
            this.$store.dispatch("paymentTerm/destroy", uuid).then(response => {
              this.$notify({
                type: response.error_type,
                message: response.message
              });
              this.$Progress.finish();
            }).catch(error => {
              this.$Progress.finish();
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              });
            });
          }
        })
        .catch(() => this.$Progress.finish());
    }
  },
  created() {
    this.init({});
  }
};
</script>

<style scoped>
.imgBaseButton {
  margin-bottom: 2px;
  margin-left: -10px;
  margin-right: 5px;
}

.horizontal-line {
  border-bottom: 1px solid #1a70b7;
}
</style>
