import { createAxios } from "@/plugins/axios";;
const endPoint = "/configuration/user/credit/";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    add({}, payload) {
      return createAxios()
        .post(endPoint, payload)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getByUser({}, id) {
      return createAxios()
        .get(`${endPoint}${id}`)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    disableCredit({}, id) {
      return createAxios()
        .get(`${endPoint}disable/${id}`)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
};
