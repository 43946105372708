<template>
  <div>
    <div class="container-fluid">
      <multi-filter @clearFilters="clearFilters" :status="multi_filter" ref="multi-filter" @fetch="init"
                    :filter="filter">
        <div class="col-md-3 p-2">
          <input-date-picker :filterable="4" :default-range="range" ref="date-picker" @handleFilterDate="handleFilterDate"/>
        </div>
        <div class="col-md-3 p-2">
            <puzl-select 
              v-model="filter.fuel_tank_ids" 
              :items="$_fuel_tanks"  
              :multiple="true"
              :disabled="loadingFuelTank"
              :loading="loadingFuelTank" 
              @input="init({})"
              label="$name$ | $product_name$"
              placeholder="Bomba"
              :labelMask="true"
              class="select-xl"
            />
        </div>
      </multi-filter>
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="index in 3" :key="index"/>
      </div>
      <!-- Dados vazios -->
      <PuzlEmptyData v-if="!$_items.length && !loadingSkeleton"/>
      <div class="row card-wrapper" v-if="$_items.length && !loadingSkeleton">
        <div class="col-lg-4" v-for="(item, index) in $_items" :key="index">
          <card>
            <!-- Card header -->
            <div class="row align-items-center mb-1">
              <div class="col-9 d-flex align-items-center pr-0">
                <el-popover v-if="item.statistics" trigger="hover" placement="bottom" class="p-0 float-right pr-0">
                  <h4 class="mb-0" slot="reference">
                    <badge :type="getStatusColor(item.status)">
                      <i class="fas fa-gas-pump"/> &nbsp;{{ getStatusText(item.status) }}
                    </badge>
                  </h4>
                  <div>
                      <table style="margin-bottom: 0px" class="table table-sm table-bordered">
                    <thead>
                      <tr>
                        <th class="text-center text-primary">LITROS</th>
                        <th class="text-center text-warning">KM</th>
                        <th class="text-center text-indigo">HORAS</th>
                        <th class="text-center text-success">VOLUME(M³)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-center">{{ item.total_liter ? item.total_liter.replace('.', ',') : 0 }}</td>
                        <td class="text-center">
                          {{ item.travelled_distance ? item.travelled_distance.toString().replace('.', ',') : 0}}
                        </td>
                        <td class="text-center">
                          {{ item.travelled_hour ? item.travelled_hour.replace('.', ',') : 0 }}
                        </td>
                        <td class="text-center">
                          {{ item.volume ? item.volume.replace('.', ',') : 0 }}
                        </td>
                      </tr>
                       <tr>
                        <td class="text-center font-weight-bold">{{
                            item.statistics.value_per_liter  | currency
                          }} (/l)</td>
                        <td class="text-center font-weight-bold">
                          {{ item.statistics.value_per_km | currency }} (/km)
                        </td>
                        <td class="text-center font-weight-bold">
                          {{ item.statistics.value_per_hour | currency }} (/h)
                        </td>
                        <td class="text-center font-weight-bold">
                          {{ item.statistics.value_per_volume | currency }} (/m³)
                        </td>
                      </tr>
                       <tr>
                        <td class="text-center">{{ item.statistics.liter_per_km }} (l/km)</td>
                        <td class="text-center">
                          {{ item.statistics.km_per_liter }} (km/l)
                        </td>
                        <td class="text-center">
                          {{ item.statistics.hour_per_liter }} (h/l)
                        </td>
                        <td class="text-center">
                          {{ item.statistics.volume_per_liter }} (m³/l)
                        </td>
                      </tr>
                       <tr>
                        <td class="text-center">
                          {{ item.statistics.liter_per_volume }} (l/m³)
                        </td>
                        <td class="text-center">
                          {{ item.statistics.km_per_volume }} (km/m³)
                        </td>
                        <td class="text-center">
                          {{ item.statistics.hour_per_volume }} (h/m³)
                        </td>
                        <td class="text-center">
                          {{ item.statistics.volume_per_km }} (m³/km)
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  </div>
                </el-popover>
                <h4 v-else class="mb-0" slot="reference">
                  <badge :type="getStatusColor(item.status)">
                    <i class="fas fa-gas-pump"/> &nbsp;{{ getStatusText(item.status) }}
                  </badge>
                </h4>
                <el-popover trigger="hover" placement="bottom" class="p-0 float-right pr-0 ml-3">
                  <span> {{ item.creator_user_name }} </span> <br/>
                  <span> {{ item.created_at | parseDate() }} </span>
                  <base-button outiline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none">
                    <i style="font-size: 20px !important" class="fas fa-user p-2 text-primary"/>
                  </base-button>
                </el-popover>
              </div>
              <div class="col-3 text-right pl-0">
                <base-dropdown menuOnRight>
                  <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                    <i class="fas fa-cog"></i>
                  </base-button>
                  <a class="dropdown-item" @click="handleModalEdit(item.id)">
                    <i class="fas fa-edit text-warning main-icon-menu"></i>
                    Editar
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleDelete(item.id)">
                    <i class="fas fa-times text-danger main-icon-menu"></i>
                    Excluir
                  </a>
                </base-dropdown>
              </div>
            </div>
            <div class="mb-2">
              <h4 class="mb-0">{{ item.company_plant_name }}</h4>
              <h4 class="mb-0 font-weight-normal">{{ item.fuel_tank_name }}</h4>
            </div>
            <div v-show="item.entity_id" class="mb-2">
              <h4 class="mb-0">{{ item.entity_name }}</h4>
              <h4 class="mb-0 font-weight-normal">{{ item.entity_document }}</h4>
            </div>
            <div class="mb-2">
              <h4 v-if="item.equipment_id" class="mb-0 font-weight-normal">
                <b>{{ item.equipment_code }} </b> | {{ item.equipment_plate }}
              </h4>
              <h4 v-else class="mb-0">{{ item.plate }}</h4>
              <h4 v-if="item.driver_id" class="mb-0 font-weight-normal">
                {{ item.driver_name }}
              </h4>
              <h4 v-else class="mb-0 font-weight-normal">{{ item.driver_observations }}</h4>
            </div>
            <div class="text-center">
              <h4 class="mb-0 col-12 p-0">
                {{ item.total_liter }} Litros | {{ item.total_value | currency }}
              </h4>
              <h4 class="mb-0 col-12 p-0 font-weight-normal">
                {{ item.date | parseDate() }}
              </h4>
            </div>
            <div v-if="item.observations" class="border-3 border-danger border-left pl-1 mt-2">
              <h5 class="mb-0 pl-1 font-weight-normal">{{ item.observations }}</h5>
            </div>
          </card>
        </div>
      </div>
    </div>
    <ModalEdit ref="modalEdit"/>
    <LoadingPagination :show="loading && !loadingSkeleton"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import ModalEdit from "./_ModalEdit";
import MultiFilter from "@/components/Utils/MultiFilterV2.vue";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import fuelControlMixin from "../Mixins/fuel_control_mixin";
import InputDatePicker from "@/components/InputDatePicker";
import PuzlSelect from "@/components/PuzlSelect";
import moment from "moment";

export default {
  name: "List",
  mixins: [cursorPaginate, fuelControlMixin],
  components: {
    SkeletonPuzlGrid,
    PuzlEmptyData,
    ModalEdit,
    MultiFilter,
    LoadingPagination,
    InputDatePicker,
    PuzlSelect,
  },
  data() {
    return {
      loadingSkeleton: true,
      filter: {},
      multi_filter: {
        0: {
          name: "INTERNO",
          type: "primary",
        },
        1: {
          name: "TERCEIROS",
          type: "warning",
        },
        2: {
          name: "EXTERNO",
          type: "dark",
        },
      },
      range: {
        start: moment().format("YYYY-MM-DD hh:mm:ss"),
        end: moment().format("YYYY-MM-DD hh:mm:ss"),
      },
      loadingFuelTank:true,
    };
  },
  computed: {
    ...mapGetters({
      $_items: "fuelControl/fetch",
      $_fuel_tanks: "fuelTank/fetch",
    }),
  },
  methods: {
    init(filter = null) {
      this.startCursor(filter);
      filter = this.filter.range ? this.filter : {range: this.range};
      this.handleGetTotalLiter(filter)
      this.$store
        .dispatch("fuelControl/fetch", {
          filter: filter,
          next_page: this.paginate.nextUrl,
        })
        .then((response) => {
          this.resolveCursor(response);
        })
        .catch(() => {
          this.resolveCursor();
        });
    },
    handleModalEdit(id) {
      this.$refs.modalEdit.openModal(id);
    },
    clearFilters() {
      this.loadingSkeleton = true;
      this.paginate = {
        hasMore: false,
        nextUrl: null,
      };
      delete this.filter.range;
      this.range = {
        start: moment().format("YYYY-MM-DD hh:mm:ss"),
        end: moment().format("YYYY-MM-DD hh:mm:ss"),
      };
      this.init();
    },
    handleFilterDate(filter) {
      this.range = filter;
      this.init({});
    },
    handleDelete(id) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          let loader = this.$loading.show();
          this.$notify({
            type: "info",
            message: "Estamos trabalhando em sua solicitação.",
          });
          this.$store
            .dispatch("fuelControl/destroy", id)
            .then((response) => {
              this.$notify({
                type: response.error_type,
                message: response.message,
              });
              loader.hide();
            })
            .catch((error) => {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message,
              });
              loader.hide();
            });
        }
      });
    },
    
    /**
     * Busca total de litros abastecidos
     *
     * @param {object|null} filter 
     * @returns void
     */
    handleGetTotalLiter(filter) {
      this.$store.dispatch("fuelControl/getTotalLiter", {filter : filter });
    }
  },
  mounted() {
    this.init();
    this.$store.dispatch("fuelTank/fetchNotPaginated").then( () => this.loadingFuelTank = false)
  },
};
</script>

<style scoped>
th {
  font-weight: 700;
  font-family: Courier New, monospace;
}
td {
  /*font-family: Courier New, monospace;*/
  text-transform: none;
}
.main-icon-menu {
  min-width: 18px;
}
</style>
