<template>
  <div>
    <modal :use-rerender="false" size="md-2" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <div class="container">
        <div>
          <div class="row mb-3">
            <div class="col-6">
              <base-button
                @click.prevent="changeType(2)"
                type="primary"
                outline
                size="md"
                block
                :class="{ active: type === 2 }"
              >
                <img v-if="type === 2" width="10"
                     src="/img/icons/physics-white.png"
                     class="border-radius-lg"
                     style="width: 20px;">
                <img v-else width="10"
                     src="/img/icons/physics.png"
                     class="border-radius-lg"
                     style="width: 20px;">
                MCC
              </base-button>
            </div>
            <div class="col-6">
              <base-button
                disabled
                @click.prevent="changeType(3)"
                type="primary"
                outline
                size="md"
                block
                :class="{ active: type === 3 }"
              >
                PRODUTO
              </base-button>
            </div>
          </div>
        </div>
        <multi-filter ref="multi-filter" @clearFilters="clearFilters" :status="multi_filter" @fetch="init"
                      :filter="filter"/>
        <div class="container-fluid">
          <div class="row card-wrapper" v-show="loadingSkeleton">
            <div class="col-12 p-0  " v-for="item in 3">
              <skeleton-puzl v-for="item in 3" type="text"></skeleton-puzl>
              <hr class="mt-3 mb-3">
            </div>
          </div>
          <PuzlEmptyData :with-card="false" v-if="!$_items.length && !loadingSkeleton"/>
        </div>
        <div v-show="$_items.length && !loadingSkeleton" class="row mt-n3 mb-3">
          <div class="col-lg-12">
            <div class="card h-100">
              <div class="card-header bg-primary text-white"
                   style="border-bottom-left-radius: 0 !important; border-bottom-right-radius: 0 !important;"><h5
                class="mb-0 text-capitalize text-white">MCC</h5></div>
              <div class="card-body pt-0">
                <ul id="list-group-product" class="list-group list-group-flush table-responsive"
                    style="max-height: 42vh;overflow-y: auto">
                  <li v-for="item in $_items" class="list-group-item px-0 mb-n5">
                    <div class="row align-items-center">
                      <div class="col-auto d-flex align-items-center"><a>
                        <img width="10"
                             src="/img/icons/icons8/ios/badge-primary.png"
                             class="border-radius-lg"
                             style="width: 20px;"></a>
                      </div>
                      <div class="col ml-2">
                        <h4 class="m-0 p-0" style="font-size: 1rem;"> {{ item.product_name }} </h4>
                        <div class="mt-n1 new-default-gray-font font-weight-400" style="font-size: 14px;">
                          {{ `${item.supplier_name} (${item.origin_name})` }}
                        </div>
                        <div class="mt-n1 new-default-gray-font font-weight-400" style="font-size: 14px;">
                          {{ item.category_name }}
                        </div>
                      </div>
                      <div class="col-12 mt-n1">
                        <div class="offset-6 col-6">
                          <div class="card-blue-bottom text-center pointer" style="
    margin-right: -14px;min-width: 130px !important;">
                            <a @click.prevent="handleSetCmc(item)" href="#"
                               style="height: 32px;display: inline-block;"><h3
                              style="text-transform: none;padding: 3px">
                            <span data-v-07030b43="" class="mr-1">
                              <img
                                src="/img/icons/icons8/ios/right-squared.png"
                                width="20px"></span><small
                              class="mr-1 text-white"
                              style="font-size: 12px;">
                              CONTINUAR </small></h3></a>
                          </div>
                        </div>
                        <hr>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="card-footer mt-n5 text-right border-top-0">
                <base-button type="secondary" @click="closeModal()"> Fechar</base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

import MultiFilter from "@/components/Utils/MultiFilterV2.vue";
import {SkeletonPuzlGrid, SkeletonPuzl, PuzlEmptyData} from "@/components";

export default {
  name: "ModalAddCmc",
  components: {SkeletonPuzl, PuzlEmptyData, MultiFilter},
  data() {
    return {
      modal: {
        title: 'Buscar MCC/Produto',
        create: false,
      },
      loading_more: false,
      params: {
        hasMore: false,
        per_page: 3,
        next_page: '',
        filter: {}
      },
      type: 2,
      filter: {
        status: null,
        global: [],
      },
      multi_filter: {
        null: {
          name: "Todos",
          type: "primary",
        },
      },
      type_is_changeable: false,
      loading: false,
      loadingSkeleton: false,
    };
  },
  computed: {
    ...mapGetters({
      $_items: "cmc/getSimplified",
    }),
  },
  methods: {
    navigateCalc(e) {
      const screenHeight = e.target.clientHeight;
      const scrolled = e.target.scrollTop;
      const scrollHeight = e.target.scrollHeight;
      if (scrollHeight - (screenHeight + scrolled) < 213) {
        return true
      }
      return false
    },
    navigate(e) {
      if (!this.loading && this.navigateCalc(e)) {
        if (this.params.hasMore) {
          this.init()
        }
      }
    },
    changeType(type) {
      this.filter.type = type;
      this.init();
    },
    handleSetCmc(cmc) {
      this.closeModal()
      this.$emit('setCmc', cmc)
    },
    clearFilters() {
      this.filter = {
        status: null,
        type: this.type,
        entity_id: this.entity_id
      };
      this.init();
    },
    closeModal() {
      this.modal.create = false;
    },
    handleCreateModal() {
      this.modal.create = true;
      this.init()
    },
    /*
    Valida se há fechamento de estoque inferior ao item percorrido
     */
    init(filter = {}) {
      this.loading = true
      this.params.filter = filter
      let loader = this.$loading.show()
      this.$store.dispatch('cmc/getCmcSimplified', this.params).then((response) => {
        this.params.hasMore = response.has_more
        this.params.next_page = response.next_page
      }).finally(() => {
        loader.hide()
        this.loadingSkeleton = false
        this.loading = false
      })
    },
  },
  mounted() {
    const list_group_product = document.getElementById('list-group-product')
    list_group_product.addEventListener("scroll", this.navigate)
    this.loadingSkeleton = true
    this.init({})
  },
};
</script>

<style scoped></style>
