import clickOutside from '@/directives/click-ouside.js';
import { mask } from "vue-the-mask";

const GlobalDirectives = {
  install(Vue) {
    Vue.directive('click-outside', clickOutside);
    Vue.directive('mask', mask);
  }
};

export default GlobalDirectives;
