<template>
  <div>
    <div class="row card-wrapper">
      <div class="col-lg-4" v-for="item in $_stock_locations_listed.items" :key="item.id">
        <div class="card main-card mb-3">
          <div class="p-3 px-4">
            <div class="row align-items-center">
              <div class="col-10">
                <div :class="item.status ? 'new-minicard-green' : 'new-minicard-red'">
                  <h4>{{ item.status ? "Ativo" : "Inativo" }}</h4>
                </div>
              </div>
              <div class="col-2 text-right">
                <base-dropdown menuOnRight>
                  <div slot="title-container" class="dropdown-toggle rounded m-0">
                    <img width="32" src="/img/icons/icons8/ios/settings--v1_primary.png" />
                  </div>
                  <a 
                    @click.prevent="onEditItemClick(item.id)"
                    class="dropdown-item font-weight-500 new-default-black-font"
                    style="align-items: center; display: flex"
                  >
                    <img width="18" src="/img/icons/edit.png" />
                    EDITAR
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a 
                    @click.prevent="onRemoveItemClick(item.id)"
                    class="dropdown-item font-weight-500 new-default-black-font"
                    style="align-items: center; display: flex"
                  >
                    <img width="18" src="/img/icons/delete.png" />
                    EXCLUIR
                  </a>
                </base-dropdown>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12" style="display: flex; align-items: center;">
                <h4 class="new-default-black-font m-0">
                  {{ item.name }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row card-wrapper" v-show="$_stock_locations_is_listing">
      <SkeletonPuzlGrid v-for="(index) in 3" :key="index"/>
    </div>
  </div>
</template>

<script setup>
//#region Imports
import { computed, defineEmits } from "vue";
import stockLocationStore from "../../store/stockLocationStore";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid.vue";
//#endregion

//#region Emits
const emit = defineEmits([
  "onEditItemClick",
  "onRemoveItemClick"
]);
//#endregion

//#region Computeds
/** Store Getters */
const $_stock_locations_listed = computed(() => stockLocationStore.getters.getListed());
const $_stock_locations_is_listing = computed(() => stockLocationStore.getters.getIsListing());
//#endregion

//#region Methods
/**
 * Editar item
 * @param {number} id
 */
 function onEditItemClick(id) {
  emit("onEditItemClick", id);
}

/**
 * Remover item
 * @param {number} id
 */
function onRemoveItemClick(id) {
  emit("onRemoveItemClick", id);
}
//#endregion
</script>

<style scoped>
* {
  font-family: Fredoka;
}

.card {
  box-shadow: 0px 5px 10px 0px #0000000D !important;
  border-radius: 8px !important;
  border: 1px solid #ECECEC;
}

.outline-success-button {
  border: 1px solid #149e57;
  color: #149e57;
  font-size: 10px;
  padding: 1px 5px;
  border-radius: 10px;
}

.card-with-box-shadow {
  box-shadow: 0 2px 4px 0 #f3f3f3;
  border: 1px solid #E8E8E8;
  box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15);
  border-radius: 10px !important;
}

.grid-card {
  display: grid;
  grid-template-columns: 60% 40%;
  overflow: hidden;
  height: 70px;
  margin-bottom: 10px;
}

.gray-bg {
  background-color: #e6e6e6;
  box-shadow: 0 0 5px 0 #6a6a6a52;
  ;
}

.blue-bg {
  background-color: #4182e52e;
  box-shadow: 0 0 5px 0 #0b7bfd52;
}

.red-bg {
  background-color: #f459592e;
  box-shadow: 0 0 5px 0 #d2272752;
}

.invisible-text {
  color: rgba(0, 0, 0, 0);
}

.new-cardwithbox-shadow {
  text-align: center;
  box-shadow: 0 5px 10px 0 #f3f3f3;
  display: flex;
  border: 1px solid #E8E8E8;
  border-radius: 5px !important;
  align-items: center;
  padding: 4px 0px;
}

.new-minicard-green {
  background-color: #F2F7F3;
  border-radius: 15px;
  padding: 5px 12px;
  font-size: 12px;
}

.new-minicard-red {
  background-color: #FCF3F3;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-yellow {
  background-color: #FEF9F2;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-blue {
  background-color: #F2F4F9;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-gray {
  background-color: #646e7d2e;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-green h4 {
  color: #149e57;
}

.new-minicard-red h4 {
  color: #db4539;
}

.new-minicard-yellow h4 {
  color: #f2b532;
}

.new-minicard-blue h4 {
  color: #1b6eba;
}

.new-minicard-gray h4 {
  color: #66696c;
}

.new-minicard-red,
.new-minicard-green,
.new-minicard-yellow,
.new-minicard-blue,
.new-minicard-gray {
  align-items: center;
  display: inline-flex;
  text-align: center;
}

.new-minicard-red img:first-child,
.new-minicard-green img:first-child,
.new-minicard-yellow img:first-child,
.new-minicard-blue img:first-child,
.new-minicard-gray img:first-child {
  width: 16px;
}

.new-minicard-red h4,
.new-minicard-green h4,
.new-minicard-yellow h4,
.new-minicard-blue h4,
.new-minicard-gray h4 {
  font-size: 12px;
  margin: 0 6px 0 6px;
  font-weight: normal !important;
}
</style>
