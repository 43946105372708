<template>
  <div  class="col-md-12 mt-n1 table-responsive">
    <!-- Tabela meses -->
    <table class="table mb-n1" style="border: none;">
      <thead>
        <tr class="fixed">
          <th style="visibility: hidden; border: none;">{{ firstColumn.title }}</th>
          <th
            v-for="(header, index) in headers"
            :key="index"
            class="text-center new-default-black-font"
            style="border: none;"
          >
            <img
              src="/img/icons/calendar-black.png"
              height="21px"
            />
            {{ monthNames[header.month - 1] }}/{{ selectedYear }}
          </th>
        </tr>
      </thead>
    </table>

    <!-- Tabela status -->
    <table class="table table-custom-shaded table-sm mb-9 pb-9">
      <thead>
        <tr class="fixed">
          <th class="text-center new-default-black-font">
            {{ firstColumn.title }}
          </th>
          <th
            v-for="(header, index) in headers"
            :key="index"
            class="text-center new-default-black-font"
            @click="$emit('toggleHeaderStatus', index + 1)"
            :style="{
              color: getColor(header.is_closed),
              backgroundColor: getBackgroundColor(header.is_closed),
              cursor: clickableColumn ? 'pointer' : '',
            }"
          >
            <span v-if="header.is_closed == FiscalClosingIsClosedEnum.CLOSED">
              Fechado
            </span>
            <span v-else>
              Aberto
            </span>
            <img
              src="/img/icons/icons8/ios/user-male-circle--v1.png"
              class="ml-1"
              height="22px"
              v-if="clickableColumn && header.is_closed == FiscalClosingIsClosedEnum.CLOSED"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="loading" v-for="index in 10" :key="index">
          <td
            class="text-center"
            v-for="column in headers.length + 1"
            :key="column"
          >
            <div>
              <SkeletonPuzl custom_margin="5px 5px 0px 0px" type="text" />
            </div>
          </td>
        </tr>
        <tr v-if="!loading" v-for="(column, index) in length" :key="index">
          <slot :name="index">
             <!--
              Aqui ficarão as <ShadedCol>
              Sempre que <ShadedCol> receber 2 <div>, o primeiro alinhará tudo a direita e o segunda a esquerda
              <ShadedCol> pode receber qualquer elemento, o alinhamento pode ser passado assim <ShadedCol align="center">
              Exemplo de uso: /Configuration/FeesAndTaxes/Tributes/Shared/_Table.vue
            -->
          </slot>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import SkeletonPuzl from "@/components/SkeletonPuzl.vue";
import { FiscalClosingIsClosedEnum } from "./Enums/FiscalClosingIsClosedEnum";

export default {
  name: "ShadedTableFiscalClosing",
  props: {
    headers: {
      type: Array,
      default: () => [],
      description: "Define os títulos do cabeçalho.",
    },
    customHeaders: {
      type: Array,
      default: () => [],
      description:
        'Define cabeçalhos personalizados usando índices. Por exemplo: customHeaders="[0,2]" significa que o 1º e o 3º cabeçalho serão personalizados.',
    },
    length: {
      type: Number,
      default: 0,
      description: "Define quantas linhas a tabela terá",
    },
    data: {
      type: Array,
      default: () => [],
      description: "Dados da tabela",
    },
    loading: {
      type: Boolean,
      default: true,
      description: "Indica se a tabela está carregando",
    },
    clickableColumn: {
      type: Boolean,
      default: false,
      description: "Torna a coluna clicável",
    },
    selectedYear: {
      type: Number,
      required: true,
      description: "Ano selecionado no filtro",
    },
    firstColumn: {
      type: Object,
      required: true,
      description: "Primeira coluna de cada tabela",
    },
  },
  data() {
    return {
      monthNames: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      FiscalClosingIsClosedEnum: FiscalClosingIsClosedEnum,
    };
  },
  components: {
    SkeletonPuzl,
  },
  methods: {
    /**
     * @param {boolean|null} isClosedValue
     * @returns {string}
     */
    getColor(isClosedValue) {
      if (isClosedValue == FiscalClosingIsClosedEnum.OPEN) {
        return '#198754 !important';
      } else if (isClosedValue == FiscalClosingIsClosedEnum.CLOSED) {
        return '#dc3545 !important';
      } else {
        return 'black';
      }
    },
    /**
     * @param {boolean|null} isClosedValue
     * @returns {string}
     */
    getBackgroundColor(isClosedValue) {
      if (isClosedValue == FiscalClosingIsClosedEnum.OPEN) {
        return '#daf1e5 !important';
      } else if (isClosedValue == FiscalClosingIsClosedEnum.CLOSED) {
        return '#fde1e1 !important';
      } else {
        return '';
      }
    },
  },
};
</script>

<style scoped>
.table th {
  width: 150px; /* Tamanho fixo para as colunas das 2 tabelas ficarem alinhadas */
}

.table-custom-shaded {
  border-collapse: initial;
}

.table-custom-shaded thead th {
  background-color: #f5f5f5;
}

.table-custom-shaded td,
.table-custom-shaded th {
  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15) !important;
  border-radius: 5px !important;
  border: 0 !important;
}

.table-custom-shaded td > nav:first-child {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
}

.table-custom-shaded td > nav:first-child > div:first-child {
  text-align: left;
}

.table-custom-shaded td > nav:first-child > div:nth-child(2) {
  text-align: right;
}
</style>
