var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',_vm._b({staticClass:"d-flex justify-content-start align-items-center",staticStyle:{"gap":"8px"}},'div',_vm.$attrs,false),[(_vm.item.childrens && _vm.item.childrens.length && _vm.expanded)?_c('img',{staticClass:"pointer",attrs:{"src":"/img/icons/icons8/ios/minus_key.png","alt":"minus_key","width":"24px"},on:{"click":_vm.toggle}}):(_vm.item.childrens && _vm.item.childrens.length && !_vm.expanded)?_c('img',{staticClass:"pointer",attrs:{"src":"/img/icons/icons8/ios/plus_key.png","alt":"plus_key","width":"24px"},on:{"click":_vm.toggle}}):_vm._e(),(!_vm.parent)?_c('span',{staticClass:"pointer identity-item",on:{"click":function($event){$event.preventDefault();return _vm.handleUpdate(_vm.item.uuid)}}},[_vm._v(_vm._s(_vm.item.ordered_code)+". "+_vm._s(_vm.item.name))]):_c('span',{staticClass:"pointer identity-item",on:{"click":function($event){$event.preventDefault();return _vm.handleUpdate(_vm.item.uuid)}}},[_vm._v(_vm._s(_vm.item.ordered_code)+". "+_vm._s(_vm.item.name)),(_vm.item.description)?_c('span',[_vm._v(" - "+_vm._s(_vm.item.description))]):_vm._e()]),_c('div',{staticClass:"checklist-options d-flex justify-content-end align-items-center"},[(_vm.item.photo_requirement)?_c('img',{staticClass:"pointer",attrs:{"src":"/img/icons/icons8/ios/camera-black.png","alt":"minus_key","width":"24px"}}):_vm._e(),(_vm.item.operation_lock)?_c('img',{staticClass:"pointer",attrs:{"src":"/img/icons/icons8/ios/road-closure.png","alt":"minus_key","width":"24px"}}):_vm._e(),(_vm.item.observation_requirement)?_c('img',{staticClass:"pointer",attrs:{"src":"/img/icons/icons8/ios/comment.png","alt":"minus_key","width":"24px"}}):_vm._e(),(_vm.parent)?_c('div',{staticClass:"ml-1 pt-1"},[_c('label',{staticClass:"app-badge",class:{
            'green-backgroud-light': _vm.item.active,
            'red-backgroud-light': !_vm.item.active,
          },staticStyle:{"width":"130px"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.active),expression:"item.active"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.item.active)?_vm._i(_vm.item.active,null)>-1:(_vm.item.active)},on:{"input":function($event){return _vm.handleStatusUpdate(_vm.item.uuid, $event)},"change":function($event){var $$a=_vm.item.active,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.item, "active", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.item, "active", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.item, "active", $$c)}}}}),_c('span',{staticClass:"d-flex justify-content-center align-items-center",class:{
              'green-text-badge': _vm.item.active,
              'red-text-badge': !_vm.item.active,
            },staticStyle:{"gap":"4px"}},[_c('img',{attrs:{"src":_vm.item.active
                  ? '/img/icons/ok--v1.png'
                  : '/img/icons/icons8/ios/cancel_danger.png',"alt":"...","width":"14px"}}),_vm._v(" "+_vm._s(_vm.item.active ? "Ativo" : "Inativo")+" ")])])]):_vm._e()])]),(_vm.item.childrens && _vm.item.childrens.length && _vm.expanded)?_c('div',{staticClass:"body-list pt-3 pb-1 pt-1 pl-4 child-list"},[_c('ul',_vm._l((_vm.item.childrens),function(child,index){return _c('li',{key:index,staticClass:"d-flex"},[_c('Item',{attrs:{"item":child,"parent":_vm.item,"data-class":"item-child"},on:{"update-checklist-item":function($event){return _vm.handleUpdate(child.uuid)},"update-status-checklist-item":function($event){return _vm.handleStatusUpdate(child.uuid, $event)}}})],1)}),0)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }