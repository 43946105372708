<template>
  <div>
    <modal size="md-2" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>

      <div class="container pb-5">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <div class="row d-flex align-items-center m-0">
            <div class="col-1">
              <img src="/img/icons/info-squared.png" width="25px" height="25px">
            </div>
            <div class="col-4">
              <h4 class="m-0">IDENTIFICAÇÃO</h4>
            </div>
            <hr class="bg-dark flex-grow-1 mr-3">
          </div>
          <div class="row d-flex align-items-center m-0">
            <div class="col-12 mt-0">
              <h4 class="m-0 p-0" style="font-size: 1rem;"> {{ product_title }} </h4>
            </div>
          </div>
          <div class="row d-flex align-items-center m-0 mt-2">
            <div class="col-md-6">
              <label class="pb-0 mb-1 col-form-label form-control-label">
                DATA DE MOVIMENTAÇÃO<span class="text-danger"> *</span>
              </label>
            </div>
            <div class="col-md-6">
              <validation-provider rules="required" v-slot="{errors}">
                <input-date-picker v-model="stock_movement.date_at" visibility="focus"/>
              </validation-provider>
            </div>
          </div>
          <div class="row d-flex align-items-center m-0 ">
            <div class="col-md-6">
              <label class="pb-0 mb-1 col-form-label form-control-label">
                CENTRAL DE ESTOQUE <span class="text-danger"> *</span>
              </label>
            </div>
            <div class="col-md-6">
              <validation-provider rules="required" v-slot="{errors}">
                <puzl-select
                  @change="getChargePointLocations"
                  placeholder="Central de estoque"
                  v-model="stock_movement.company_plant_id"
                  :items="$_plants"/>
              </validation-provider>
            </div>
          </div>
          <div class="row d-flex align-items-center m-0 ">
            <div class="col-md-6">
              <label class="pb-0 mb-1 col-form-label form-control-label">
                GRUPO DE ESTOQUE <span class="text-danger"> *</span>
              </label>
            </div>
            <div class="col-md-6">
              <validation-provider rules="required" v-slot="{errors}">
                <puzl-select
                  :clearable="false"
                  placeholder="Grupo de estoque"
                  label="group_name"
                  v-model="stock_movement.stock_group_id"
                  :items="$_groups"/>
              </validation-provider>
            </div>
          </div>
          <div class="row d-flex align-items-center m-0 ">
            <div class="col-md-6">
              <label class="pb-0 mb-1 col-form-label form-control-label">
                LOCAL DE ESTOQUE <span class="text-danger"> *</span>
              </label>
            </div>
            <div class="col-md-6">
              <validation-provider rules="required" v-slot="{errors}">
                <puzl-select
                  placeholder="Local de estoque"
                  v-model="stock_movement.dosage_location_id"
                  :items="stock_locations"/>
              </validation-provider>
            </div>
          </div>
          <div class="row d-flex align-items-center m-0 ">
            <div class="col-md-6">
              <label class="pb-0 mb-1 col-form-label form-control-label">
                TIPO <span class="text-danger"> *</span>
              </label>
            </div>
            <div class="col-md-6">
              <validation-provider rules="required" v-slot="{errors}">
                <puzl-select
                  :clearable="false"
                  v-model="stock_movement.origin_type"
                  placeholder="Tipo"
                  :items="origins"/>
              </validation-provider>
            </div>
          </div>
          <!--        DOCUMENTO-->
          <div class="row d-flex align-items-center m-0">
            <div class="col-1">
              <img src="/img/icons/icons8/ios/menu-squared-2-dark.png" width="25px" height="25px">
            </div>
            <div class="col-4">
              <h4 class="m-0">DOCUMENTO</h4>
            </div>
            <hr class="bg-dark flex-grow-1 mr-3">
          </div>
          <div class="row d-flex align-items-center m-0 ">
            <div class="col-md-6">
              <label class="pb-0 mb-1 col-form-label form-control-label d-flex">
                CLIENTE FORNECEDOR
                <base-button
                  class="ml-2" @click.prevent="handleSearchEntity" type="primary" size="sm" outline>
                  <i class="fa-solid fa-magnifying-glass"></i>
                </base-button>
              </label>
            </div>
            <div class="col-md-6 d-flex">
              <input :value="stock_movement.entity_selected" type="text" disabled="disabled" valid="true"
                     class="form-control m-0"
                     style="height: 1.87rem;">
              <div v-show="stock_movement.entity_id" class="pointer" style="margin-left: -18px;margin-top: 4px;"
                   @click.prevent="stock_movement.entity_selected = null">
                <i class="fa-solid fa-xmark" style="font-size: 14px;"></i>
              </div>
            </div>
          </div>
          <div class="row d-flex align-items-center m-0 ">
            <div class="col-md-6">
              <label class="pb-0 mb-1 col-form-label form-control-label">
                DOCUMENTO
              </label>
            </div>
            <div class="col-md-6">
              <base-input
                v-model="stock_movement.document"
                size="sm"/>
            </div>
          </div>
          <!--        LANÇAMENTO-->
          <div class="row d-flex align-items-center m-0">
            <div class="col-1">
              <img src="/img/icons/price-tag-usd-green.png" width="25px" height="25px">
            </div>
            <div class="col-4">
              <h4 class="m-0">LANÇAMENTO</h4>
            </div>
            <hr class="bg-dark flex-grow-1 mr-3">
          </div>
          <div class="row d-flex align-items-center m-0 ">
            <div class="col-md-6">
              <label class="pb-0 mb-1 col-form-label form-control-label">
                UNIDADE DE MEDIDA <span class="text-danger"> *</span>
              </label>
            </div>
            <div class="col-md-6">
              <validation-provider rules="required" v-slot="{errors}">
                <input
                  v-model="stock_movement.unit_type"
                  maxlength="10"
                  type="text"
                  class="form-control form-control-sm"
                />
              </validation-provider>
            </div>
          </div>
          <div class="row d-flex align-items-center m-0 ">
            <div class="col-md-6">
              <label class="pb-0 mb-1 col-form-label form-control-label">
                QUANTIDADE <span class="text-danger"> *</span>
              </label>
            </div>
            <div class="col-md-6">
              <validation-provider rules="required" v-slot="{errors}">
                <base-input input-group-classes="input-group-sm">
                  <input size="sm"
                         v-model="amount_value"
                         v-mask="['#.###', '##.###', '###.###', '####.###', '#####.###', '######.###', '#######.###']"
                         class="form-control form-control-sm"
                  />
                </base-input>
              </validation-provider>
            </div>
          </div>
          <div class="row d-flex align-items-center m-0 ">
            <div class="col-md-6">
              <label class="pb-0 mb-1 col-form-label form-control-label">
                PREÇO UNITÁRIO <span class="text-danger"> *</span>
              </label>
            </div>
            <div class="col-md-6">
              <base-input input-group-classes="input-group-sm">
                <template slot="prepend">
                  <small class="input-group-sm p-0 m-0">
                    R$
                  </small>
                </template>
                <input
                  v-money="money"
                  v-model.lazy="price_stock_value"
                  maxlength="13"
                  inputmode="numeric"
                  type="text"
                  class="form-control form-control-sm"
                />
              </base-input>
            </div>
          </div>
          <!--        ESTOQUE-->
          <div class="row d-flex align-items-center m-0">
            <div class="col-1">
              <img src="/img/icons/open-box.png" width="25px" height="25px">
            </div>
            <div class="col-4">
              <h4 class="m-0">ESTOQUE</h4>
            </div>
            <hr class="bg-dark flex-grow-1 mr-3">
          </div>
          <div class="row d-flex align-items-center m-0 ">
            <div class="col-md-6">
              <label class="pb-0 mb-1 col-form-label form-control-label">
                UNIDADE DE MEDIDA <span class="text-danger"> *</span>
              </label>
            </div>
            <div class="col-md-6">
              <validation-provider rules="required" v-slot="{errors}">
                <base-input
                  disabled
                  v-model="stock_movement.unit_stock_type"
                  maxlength="10"
                  type="text"
                  size="sm"
                />
              </validation-provider>
            </div>
          </div>
          <div class="row d-flex align-items-center m-0 ">
            <div class="col-md-6">
              <label class="pb-0 mb-1 col-form-label form-control-label">
                FATOR DE CONVERSÃO <span class="text-danger"> *</span>
              </label>
            </div>
            <div class="col-md-6">
              <validation-provider rules="required" v-slot="{errors}">
                <base-input input-group-classes="input-group-sm">
                  <input
                    v-model="stock_movement.unit_convert_value"
                    maxlength="10"
                    inputmode="numeric"
                    type="text"
                    v-mask="['#.#####', '##.#####', '###.#####', '####.#####']"
                    class="form-control form-control-sm"
                  />
                </base-input>
              </validation-provider>
            </div>
          </div>
          <div class="row d-flex align-items-center m-0 ">
            <div class="col-md-6">
              <label class="pb-0 mb-1 col-form-label form-control-label">
                PREÇO UNITÁRIO <span class="text-danger"> *</span>
              </label>
            </div>
            <div class="col-md-6">
              <base-input :value="calcPriceValue()" disabled size="sm"/>
            </div>
          </div>
          <div class="row d-flex align-items-center m-0 ">
            <div class="col-md-6">
              <label class="pb-0 mb-1 col-form-label form-control-label">
                QUANTIDADE <span class="text-danger"> *</span>
              </label>
            </div>
            <div class="col-md-6">
              <base-input
                :value="calcQuantity()" disabled size="sm"/>
            </div>
          </div>
          <div class="row d-flex align-items-center m-0 ">
            <div class="col-md-6">
              <label class="pb-0 mb-1 col-form-label form-control-label">
                PREÇO TOTAL <span class="text-danger"> *</span>
              </label>
            </div>
            <div class="col-md-6">
              <base-input
                :value="calcTotal() | currency()" disabled size="sm"/>
            </div>
          </div>
          <div class="row float-right mt-4 pr-3">
            <div class="col-md-12" style="display: flex; align-items: center;">
              <base-button type="danger" @click.prevent="modal.create = false">
                <img src="/img/icons/cancel-white.png" width="22px" height="22px" class="mr-2"/>
                CANCELAR
              </base-button>
              <base-button
                type="success"
                @click.prevent="save(false)"
                title="Salvar"
                v-bind:disabled="invalid"
                :class="loading && 'btn-loading'"
              >
                <img v-if="!loading" src="/img/icons/save.png" width="22px" height="22px" class="mr-2"/>
                SALVAR
              </base-button>
            </div>
          </div>
        </validation-observer>
      </div>
      <modal-search-entity @selected="setEntity" ref="modalSearchEntity"/>
    </modal>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import InputDatePicker from "@/components/InputDateTimePickerV2.vue";
import PuzlSelect from "@/components/PuzlSelect.vue";
import {VMoney} from 'v-money';
import ModalSearchEntity from '@/views/Modules/Configuration/Entity/Shared/_ModalSearchEntity.vue'
import moment from "moment";

export default {
  name: "ModalEdit",
  components: {PuzlSelect, InputDatePicker, ModalSearchEntity},
  data() {
    return {
      modal: {
        title: 'Editar movimentação',
        create: false,
      },
      product_title: undefined,
      params: {
        type: undefined,
        type_id: undefined
      },
      origins: [
        {name: 'Venda', id: 1},
        {name: 'Compra', id: 2},
        {name: 'Ajuste de entrada', id: 3},
        {name: 'Ajuste de saída', id: 4},
        {name: 'Quebra positiva', id: 5},
        {name: 'Quebra negativa', id: 6},
      ],
      loading: false,
      stock_locations: [],
      amount_stock: null,
      amount_value: null,
      disable_max_date: new Date(),
      disable_min_date: null,
      price_stock_value: null,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false
      },
    };
  },
  directives: {money: VMoney},
  computed: {
    ...mapGetters({
      $_plants: "plant/activeItems",
      stock_movement: "stockMovement/show",
      $_groups: "cmcGroup/showGroupByCategory",
      $_latest_closing: "stockClosing/getLatestClosingAt",
    })
  },
  methods: {
    async updateLatestStock() {
      await this.$store.dispatch('stockClosing/getLatestClosingAt')
      this.disable_min_date = new Date(moment(this.$_latest_closing.closing_at).add(1, "days"))
    },
    calcTotal() {
      let price_value = Number(this.calcPriceValue())
      let quantity = Number(this.calcQuantityWithoutSeparator())
      if (!(price_value > 0) || !(quantity > 0)) {
        return 0
      }
      return price_value * quantity
    },
    calcQuantityWithoutSeparator() {
      let amount_value = this.amount_value
      let unit = this.stock_movement.unit_convert_value
      return Math.abs(amount_value * unit)
    },
    calcQuantity() {
      return this.calcQuantityWithoutSeparator().toLocaleString('pt-BR', {minimumFractionDigits: 2})
    },
    calcPriceValue() {
      if (this.stock_movement.unit_convert_value > 0 && this.price_stock_value !== '0,00' && this.price_stock_value) {
        return Number(parseFloat(this.price_stock_value.replaceAll('.', '').replaceAll(',', '.')) / this.stock_movement.unit_convert_value).toFixed(5)
      }
      return ''
    },
    save() {
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      let params = {...this.stock_movement}
      params.price_value = this.calcTotal()
      params.amount_value = this.amount_value
      if ([1, 4].includes(this.stock_movement.origin_type)) {
        params.amount_stock = -this.calcQuantityWithoutSeparator()
      } else {
        params.amount_stock = this.calcQuantityWithoutSeparator()
      }
      if (!params.dosage_location_id) {
        params.dosage_location_id = null
      }
      let loader = this.$loading.show()
      this.loading = true
      this.$store.dispatch('stockMovement/update', params).then((response) => {
        this.loading = false
        loader.hide()
        this.$notify({type: 'success', message: response.message});
        this.modal.create = false
      })
        .catch((error) => {
          this.$notify({
            type: error.data.error_type,
            message: error.data.message
          })
        })
        .finally(() => loader.hide())
    },
    setUnitStockType() {
      if (!this.stock_movement.unit_stock_type) {
        this.stock_movement.unit_stock_type = this.stock_movement.unit_type
      }
    },
    setEntity(entity) {
      this.stock_movement.entity_id = entity.id
      this.stock_movement.entity_selected = entity.entity_name
    },
    handleSearchEntity() {
      this.$refs.modalSearchEntity.openModal()
    },
    getChargePointLocations() {
      this.$store.dispatch("plantChargePointLocation/getByCompanyPlant", this.stock_movement.company_plant_id).then((response) => {
        this.stock_locations = [{id: 0, name: 'Sem Local'}, ...response.data];
        this.loadingStockLocation = false;
      });
    },
    /**
     */ async handleCreateModal(stock_movement_id) {
      let loader = this.$loading.show()
      this.$store.dispatch('stockMovement/show', stock_movement_id).then(async (response) => {
        await this.$store.dispatch('cmcGroup/fetchGroupByCategory', response.data.cmc_category_id)
        loader.hide()
        if (!this.stock_movement.dosage_location_id) {
          this.stock_movement.dosage_location_id = 0
        }
        this.amount_stock = Number(response.data.amount_stock).toFixed(3)
        this.amount_value = Math.abs(response.data.amount_value).toFixed(3)
        this.price_stock_value = Number(response.data.price_stock_value * response.data.unit_convert_value).toFixed(2)
        this.product_title = response.data.title
        await this.updateLatestStock()
        await this.getChargePointLocations()
      })
      this.modal.create = true
    }
  },
};
</script>
<style>
.btn-loading {
  pointer-events: none;
  opacity: 0.9;
}
</style>
