var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('base-header',{staticClass:"bg-gray-content"},[_c('div',{staticClass:"row align-items-center py-4"},[_c('div',{staticClass:"col-lg-12 col-12"},[_c('h6',{staticClass:"h2 text-gray d-inline-block mb-0"},[_vm._v(_vm._s(this.$route.meta.breadcrumbs[1].title))]),_c('nav',{staticClass:"d-none d-md-inline-block ml-md-4 text-gray",attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"#"}},[_c('i',{class:this.$route.meta.icon})])]),_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"#"}},[_vm._v(_vm._s(this.$route.meta.breadcrumbs[0].title))])]),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v(_vm._s(this.$route.meta.breadcrumbs[1].title))])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-2 mb-3"},[_c('base-button',{staticClass:"pb-3 pt-3 text-uppercase",attrs:{"block":"","size":"lg","type":"success"},on:{"click":function($event){return _vm.handleCreate()}}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" novo ")])],1),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('base-dropdown',{staticClass:"col-md-12 mb-3 ml-0 p-0"},[_c('base-button',{staticClass:"dropdown-toggle p-3 text-uppercase",attrs:{"slot":"title-container","block":"","type":"default"},slot:"title-container"},[_c('i',{staticClass:"fas fa-external-link-alt"}),_vm._v(" Cadastros ")]),_c('div',[_c('router-link',{attrs:{"to":{
                    path: '/equipment/equipment/category',
                    name: 'equipment.category.index',
                  }}},[_c('a',{staticClass:"dropdown-item"},[_vm._v("Categoria")]),_c('div',{staticClass:"dropdown-divider p-0 m-0"})]),_c('router-link',{attrs:{"to":{
                    path: '/equipment/equipment/type',
                    name: 'equipment.type.index',
                  }}},[_c('a',{staticClass:"dropdown-item"},[_vm._v("Tipo de Equipamento")])])],1)],1)],1)])]),_c('div',{staticClass:"col-md-2 mb-3"},[_c('router-link',{attrs:{"to":{ path:'/equipment/equipment'}}},[_c('base-button',{staticClass:"pb-3 pt-3",attrs:{"block":"","size":"lg","type":"light"}},[_vm._v(" VOLTAR ")])],1)],1)])]),_c('ListEquipmentSituation'),_c('ModalCreateEquipmentSituation',{ref:"createEquipmentSituation"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }