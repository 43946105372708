<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 mb-2">
        <base-button :disabled="loadingSkeleton"
                     :class="invoices && checked.length === invoices.length && 'active'"
                     :type="checked.length === invoices.length && 'success' || 'primary'"
                     @click.prevent="selectAll = !selectAll" outline>
          Selecionar todos <span class="badge badge-light text-dark">{{ checked.length }} /
          {{
            invoices && !loadingSkeleton ? invoices.length : 0
          }}
        </span>
        </base-button>
      </div>
    </div>
    <div class="row card-wrapper" v-if="loadingSkeleton">
      <SkeletonPuzlGrid v-for="(index) in 3" v-show="true" :key="index"></SkeletonPuzlGrid>
    </div>
    <div class="row card-wrapper" v-if="invoices && !loadingSkeleton">
      <div class="col-md-4" v-for="(item, indexItem) in invoices">
        <div class="card" :class="[
              checked.includes(`${item.id}${item.type}`) ? 'card-border-top-success' : '',
              item.type === 1 ? 'card-border-top-primary' : '',
              item.type === 2 ? 'card-border-top-default' : '',
              item.type === 3 ? 'card-border-top-yellow' : '',
              item.type === 4 ? 'card-border-top-indigo' : ''
              ]">
          <div class="card-body">
            <div class="row">
              <div class="col-8">
                <base-button v-if="!checked.includes(`${item.id}${item.type}`)"
                             @click.prevent="check(item.id, item.type)"
                             outline :type="setType(item.type).type">{{ setType(item.type).name }}
                  <i class="far  fa-square"></i>
                </base-button>
                <base-button v-else outline
                             class="active"
                             @click.prevent="uncheck(item.id, item.type)"
                             :type="setType(item.type).type">{{ setType(item.type).name }}
                  <i class="far fa-check-square"></i>
                </base-button>

                <!--                <a href="#" @click.prevent="check(item.id, item.type)"-->
                <!--                   v-if="!checked.includes(`${item.id}${item.type}`)">-->
                <!--                  <i class="far fa-2x fa-square"></i>-->
                <!--                </a>-->
                <!--                <a href="#" @click.prevent="uncheck(item.id, item.type)" v-else>-->
                <!--                  <i class="far fa-2x fa-check-square"></i> </a>-->
              </div>
              <div class="col-4 text-right" v-if="item.type === 1 && item.max_versions">
                <base-dropdown menuOnRight>
                  <base-button
                    slot="title-container"
                    type="primary"
                    class="dropdown-toggle p-2 rounded m-0"
                  >
                    V.{{ item.version }}
                  </base-button>
                  <a v-if="index !== Number(item.version)" @click.prevent="handleUpdateVersion(item.id, index, indexItem)" class="dropdown-item"
                     v-for="index in Number(item.max_versions)">
                    <span class="text-right">V.{{ index }}</span>
                  </a>
                </base-dropdown>
              </div>
              <div class="col-4 text-right" v-if="item.type === 1 && !item.max_versions">
                <base-dropdown menuOnRight>
                  <base-button
                    disabled
                    style="width: 51px"
                    slot="title-container"
                    type="primary"
                    class="p-2 rounded m-0"
                  >
                    V.{{ item.version }}
                  </base-button>
                </base-dropdown>
              </div>
              <div class="col-4 text-right" v-if="item.type === 4">
                <base-dropdown menuOnRight>
                  <base-button
                    slot="title-container"
                    type="primary"
                    class="dropdown-toggle p-2 rounded m-0"
                  >
                    <i class="fas fa-cog"></i>
                  </base-button>
                  <a class="dropdown-item" @click.prevent="removeSingle(item.id)" v-if="item.type === 4">
                    <i class="fas fa-times text-danger"></i>
                    <span class="text-right">Excluir</span>
                  </a>
                </base-dropdown>
              </div>
            </div>
            <template v-if="item.type == 1">
              <div class="row mt-4 ">
                <div class="col-md-12">
                  <h5 class="h4 mb-0">{{ item.date_at | parseDate() }}</h5>
                  <h2><small class="text-info"><b style="font-size: 18px;margin-left: -5px !important">
                    <small class="text-default"><b><img width="55px"
                                                        src="/img/icons/os.png">
                      <span>
                    n°
                    </span>
                    </b></small>
                    {{ item.number }}
                    <badge v-if="item.status === 6" type="danger">CANCELADA</badge>

                  </b></small
                  ></h2>
                  <h5 class="h4 mb-0 small">{{ item.company_plant_name }}</h5>
                </div>
              </div>
              <div class="mt-4">
                <div class="row">
                  <div class="col-md-12">
                <span class="h4 mb-0">
                     {{ item.title }}
                </span>
                  </div>
                </div>
                <div class="mt-2">
                  <h5 class="small">
                    {{ item.body }}
                  </h5>
                  <br v-if="item.puzl_additions.length">
                  <div v-for="(puzl_addtion, index) in item.puzl_additions"
                       :key="puzl_addtion.id"><h5 class="text-indigo">
                    {{ puzl_addtion.name }}
                    ({{ puzl_addtion.volume }} kg/m<sup>3</sup>)
                    <span class="text-indigo"
                          v-if="index != item.puzl_additions.length -1">+</span>
                  </h5></div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-12 text-center">
                  <h4 class="font-weight-bold h4 mb-0 small">{{ item.volume.toString().replace('.', ',') }}
                    m<sup>3</sup></h4>
                  <h5 class="h4 mb-0 small mt-2">{{ item.formulation_value | currency() }}
                    /m<sup>3</sup></h5>
                </div>
                <div class="col-md-12 mt-3 text-center">
                  <span class="h1">{{ item.value | currency() }}</span>
                </div>
              </div>
            </template>
            <template v-if="item.type == 2">
              <div class="row mt-4">
                <div class="col-md-12">
                  <h5 class="h4 mb-0">{{ item.date_at | parseDate() }}</h5>
                  <h2><small class="text-primary"><b style="font-size: 18px;margin-left: -7px !important">
                    <small class="text-default"><b><img width="55px"
                                                        src="/img/icons/os.png">
                      <span>
                    n°
                    </span>
                    </b></small>
                    {{ item.number }}
                    <badge v-if="item.status === 3" type="danger">CANCELADA</badge>

                  </b></small
                  ></h2>
                  <h5 class="h4 mb-0 small">{{ item.company_plant_name }}</h5>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-12">
                <span class="display-5 h5">
                  {{ item.service_name }}
                </span>
                  <br>
                  <span class="display-5 h5">
                  {{ item.service_description }}
                </span>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-12 text-center" v-if="item.price_key === 'price_fixed'">
                  <h5 class="h4 mb-0 small"><br></h5>
                  <h5 class="h4 mb-0 small mt-2">Taxa mínima</h5>
                </div>
                <div class="col-md-12 text-center" v-if="item.price_key === 'price_meters'">
                  <h5 class="h4 mb-0 small font-weight-bold">{{ item.volume_performed }} m³</h5>
                  <h5 class="h4 mb-0 small mt-2">{{ item.proposal_by_meters | currency() }} /m³<br></h5>
                </div>
                <div class="col-md-12 text-center" v-if="item.price_key === 'price_hour'">
                  <h5 class="h4 mb-0 small font-weight-bold">{{
                      $helper.secondsToTime(item.hour_performed * 3600, true)
                    }}</h5>
                  <h5 class="h4 mb-0 small mt-2">{{ item.proposal_by_hours | currency() }} /hr<br></h5>
                </div>
                <div class="col-md-12 mt-3 text-center">
                  <span class="h1">{{ item.value | currency() }}</span>
                </div>
              </div>
            </template>
            <template v-if="item.type == 3">
              <div class="row mt-4">
                <div class="col-md-12">
                  <h5 class="h4 mb-0">{{ item.finished_at | parseDate() }}</h5>
                  <h2><small class="text-info"><b style="font-size: 18px;margin-left: -5px !important">
                    <small class="text-default"><b><img width="55px"
                                                        src="/img/icons/os.png">
                      <span>
                    n°
                    </span>
                    </b></small>
                    {{ item.number }}
                    <badge v-if="item.status === 3" type="danger">CANCELADA</badge>

                  </b></small
                  ></h2>
                  <h5 class="h4 mb-0 small">{{ item.company_plant_name }}</h5>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-12">
                <span class="display-5 h5">
                  {{ item.short_description }}
                </span>
                  <br>
                  <span class="display-5 h5">
                  {{ item.reason || '-' }}
                </span>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-12 text-center">
                  <h5 class="h4 mb-0 small font-weight-bold" v-if="item.billing_unit_id !== 0">
                    {{ format(item.calculation_base) || '-' }}
                  </h5>
                  <h5 v-else class="h4 mb-0 small">
                    {{ item.calculation_base | currency() }}
                  </h5>
                  <h5 class="h4 mb-0 small mt-2">{{ item.standard_value }} {{ item.billing_unit }}</h5>
                </div>
                <div class="col-md-12 mt-3 text-center">
                  <span class="h1">{{ item.value | currency }}</span>
                </div>
              </div>
            </template>
            <template v-if="item.type == 4">
              <div class="row mt-4">
                <div class="col-md-12">
                  <h5 class="h4 mb-0">{{ item.date_at | parseDate() }}</h5>
                  <h5 class="h4 mb-0 small">{{ item.company_plant_name }}</h5>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-12">
                <span class="display-5 h5">
                  {{ item.description }}
                </span>
                  <br>
                  <span><br></span>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-12 text-center">
                  <h5 class="h4 mb-0 small mt-2">
                    <span class="font-weight-bold">{{ item.quantity }}</span> (Quantidade)
                  </h5>
                  <h5 class="h4 mb-0 small mt-2">{{ (item.value / item.quantity) | currency() }} /unid. </h5>

                </div>
                <div class="col-md-12 mt-3 text-center">
                  <span class="h1">{{ item.value | currency() }}</span>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-center" v-if="!loadingSkeleton && invoices && !invoices.length">
      <div class="col-md-12 text-center">
        <div class="card p-4">
          Sem dados cadastrados!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import {mapGetters} from "vuex";
import {
  period_types
} from "@/views/Modules/Technology/Mix/helpers/mixe";
export default {
  name: "List",
  components: {SkeletonPuzlGrid},
  data() {
    return {
      checked: [],
      filter: {},
      loadingSkeleton: false,
      selectAll: false,
      invoice_bills: [],
      format_mixe: period_types,
    }
  },
  computed: {
    ...mapGetters({
      invoices: "billingInvoice/getListBills",
      contract_proposal: "contractProposal/show",
    })
  },
  mounted() {
    this.loadingSkeleton = true
  },
  watch: {
    'selectAll': function (val) {
      if (val) {
        this.checked = []
        for (var item of this.invoices) {
          this.check(item.id, item.type)
        }
      } else {
        this.checked = []
      }
    },
  },
  methods: {
    init(filter = null) {
      this.filter = {...filter, ...this.filter}
      this.loadingSkeleton = true
      this.checked = []
      this.$store.dispatch('billingInvoice/getBills',
        {
          filter: this.filter,
          contract_proposal_id: this.contract_proposal.id
        }).then(response => {
        this.loadingSkeleton = false
      }).catch(error => {
        this.loadingSkeleton = false
      })
    },
    format(value) {
      if (!isNaN(value)) {
        return this.$helper.secondsToTime(value * 60, true)
      }
      return value
    },
    check(key, type) {
      this.checked.push(`${key}${type}`)
      this.checked = this.checked.sort()
    },
    handleUpdateVersion(id, version, index) {
      let loader = this.$loading.show()
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });
      this.$store
        .dispatch("travels/updateVersion", {
          id: id,
          version: version
        }).then(response => {
        this.$notify({
          type: response.error_type,
          message: response.message,
        });
        this.init()
        loader.hide()
      })
    },
    uncheck(key, type) {
      this.checked.splice(this.checked.indexOf(`${key}${type}`), 1)
      this.$forceUpdate()
    },
    setType(type) {
      switch (type) {
        case 1:
          return {
            type: 'primary',
            name: 'FORMULAÇÃO'
          }
        case 2:
          return {
            type: 'default',
            name: 'SERVIÇO'
          }
        case 3:
          return {
            type: 'warning',
            name: 'ADICIONAL'
          }
        case 4:
          return {
            type: 'indigo',
            name: 'AVULSO'
          }
      }
    },
    removeSingle(id) {
      this.$Progress.start()
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'})
      this.$store.dispatch('billingInvoice/destroySingle', id).then(response => {
        this.$notify({type: response.error_type, message: response.message})
        this.$Progress.finish()
      }).catch(error => {
        this.$notify({type: error.error_type, message: error.message})
        this.$Progress.finish()
      })
    },
    getSelecteds() {
      const selecteds = []
      for (var id of this.checked) {
        const item = this.invoices.find((item) => {
          const key = `${item.id}${item.type}`
          if (key === id) {
            return item
          }
        })
        selecteds.push(
          {
            date_at: item.date_at,
            type: item.type,
            status: item.status,
            company_plant_id: item.company_plant_id,
            company_plant_issuer_id: item.company_plant_issuer_id,
            type_id: item.id,
            value: item.value,
            theorical_adjusted: item.theorical_adjusted,
            real_mcc: item.real_mcc,
          }
        )
      }
      return {
        selecteds: selecteds,
        has_open: selecteds.length < this.invoices.length
      }
    }
  }
}
</script>

<style scoped>

</style>
