<template>
<div class="container-fluid mt-2">
    <PageHeader
        title="Plano de Contas Gerencial"
        icon="/img/icons/icons8/ios/increase_success.png"
        type="success"
        isBackButtonEnabled="true">
        <PageHeaderButton @click.prevent="handleCreateChartAccount"
            text="Novo"
            type="success"
            icon="/img/icons/plus-math--v1-white.png" />
    </PageHeader>
<list-chart-account-resume @init="init" ref="ListChartAccountResume"></list-chart-account-resume>
<ModalCreateChartAccountResume @init="init" ref="ModalCreateChartAccountResume" />
</div>
</template>

<script>
import ListChartAccountResume from './Shared/_ListResume'
import ModalCreateChartAccountResume from '../Shared/_ModalCreate'
import { PageHeader, PageHeaderButton } from "@/components";

export default {
    name: "IndexChartAccountResume",
    components: {
        ListChartAccountResume,
        ModalCreateChartAccountResume,
        PageHeader,
        PageHeaderButton
    },
    data() {
        return {
            added: false,
        }
    },
    methods: {
        handleCreateChartAccount() {
            this.$refs.ModalCreateChartAccountResume.handleCreateModal()
        },
        handleCreateChartAccountResume(uuid) {
            this.$refs.ModalCreateChartAccountResume.handleCreateModalByResume(uuid)
        },
        handleCreatedChartAccountResumeList() {
            this.added = !this.added
        },
        init() {
            this.$refs.ListChartAccountResume.init();
        }
    },
};
</script>

<style scoped>

</style>
