import { adjustmentTypeUrls } from ".";
import httpClient from "../../../../shared/libraries/httpClient";
import { handleUnexpectedStatusCode } from "../../../../shared/services";
import { AdjustmentTypeCreateType, AdjustmentTypeViewType } from "../types";
import { HttpStatusEnum } from "../../../../shared/enums";

/**
 * Incluir
 *
 * @param {AdjustmentTypeCreateType} data
 * @returns {Promise<AdjustmentTypeViewType>}
 */
const execute = async (data) => {
  const res = await httpClient.post(adjustmentTypeUrls.BASE, data);
  switch (res.status) {
    case HttpStatusEnum.HTTP_CREATED:
      return res.data.data;
    default:
      handleUnexpectedStatusCode(res);
  }
};

export default { execute };
