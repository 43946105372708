<template>
  <div class="card-body mt-n2 p-0">
    <div class="row">
      <ShadedTable :length="$_cmcs.length"
        :headers="headers"
        :loading="loadingSkeleton">
        <template v-if="cmc.data" v-for="(cmc, cmcIndex) in $_cmcs" :slot="cmcIndex">
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1"> 
              {{ cmc.data.company_plant_name }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1"> 
              {{ cmc.data.category_name }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1"> 
              {{ cmc.data.product_name }} | {{ cmc.data.supplier_name }} | {{ cmc.data.supplier_origin_name }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <div>
              <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
                {{ cmc.current_price | currency() }} / {{ cmc.data.measured_unit_buy_letter }}
                <!-- Desabilitado por hora -->
              </h5>
            </div>
            <div>
              <img
                class="pointer"
                @click="handleFormatToCreateCmcPrice(cmc)"
                width="22"
                src="/img/icons/price-tag-usd-green.png"
              />
            </div>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ cmc.data.average_price_with_transport_cost | currency() }}
            </h5> 
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ cmc.data.average_price | currency() }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ cmc.data.average_transport_cost | currency() }}
            </h5>
          </ShadedCol>
        </template>
      </ShadedTable>
    </div>
  </div>
</template>
<script>
import SkeletonPuzl from "@/components/SkeletonPuzl";
import { mapGetters } from "vuex";
import ShadedTable from "@/components/ShadedTable.vue";
import ShadedCol from "@/components/ShadedCol.vue";

export default {
  name: "TableCmc",
  data(){
    return {
      headers: [
        "CENTRAL",
        "CATEGORIA",
        "MCC",
        "PREÇO VIGENTE",
        "PREÇO MÉDIO",
        "MATERIAL",
        "FRETE",
      ],
    }
  },
  props: {
    handleFilterCmcGroup: { type: Function },
    handleFilterCmcProduct: { type: Function },
    handleFilterCmcSupplier: { type: Function },
    handleFilterCmcCategory: { type: Function },
    setStatus: { type: Function },
    showGroups: { type: Function },
    handleDeleteCmc: { type: Function },
    handleEnableDisable: { type: Function },
    handleCreateCarrierPrice: { type: Function },
    handleCreateCmcPrice: { type: Function },
    handleShowCmcPrice: { type: Function },
    handleDeleteCarrierPrice: { type: Function },
    handleDeleteCmcPrice: { type: Function },
    pricePerKg: { type: Function },
    handleDelete: { type: Function },
    loadingSkeleton: Boolean,
  },
  components: {
    SkeletonPuzl,
    ShadedTable,
    ShadedCol,
  },
  computed: {
    ...mapGetters({
      $_cmcs: "cmc/fetch",
    }),
  },
  methods: {
    handleFormatToCreateCmcPrice(cmc) {
      const payload = {
        id: cmc.cmc_id,
        measured_unit_buy_initials: cmc.data.measured_unit_buy_initials,
        product_name: cmc.data.product_name,
        supplier_name: cmc.data.supplier_name,
        supplier_origin_name: cmc.data.supplier_origin_name,
        params: {
          average_humidity: cmc.data.average_humidity,
          measured_unit_id: cmc.data.measured_unit_id_buy,
          bulk_density: this.$helper.strToNum(cmc.data.bulk_density),
        },
      };

      this.handleCreateCmcPrice(cmc, payload);
    },
  },
};
</script>
