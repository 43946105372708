import * as types from './mutation_types'
import { createAxios } from "@/plugins/axios";

const endPoint = 'operational/stock/+microservice'
export default {
  namespaced: true,
  state: {
    items: [],
    items_by_code: [],
    items_by_contract_proposal: [],
    widgets: {},
    entryResumeItems: [],
    services:[],
    product_service_hub_entries: [],
    product_service_hub_puzl_adjust: [],
  },
  getters: {
    fetch: state => state.items,
    fetchByCode: state => state.items_by_code,
    fetchByContractProposal: state => state.items_by_contract_proposal,
    fetchWidgets: state => state.widgets,
    getServices: state => state.services,
    entryResumeItems: state => state.entryResumeItems,
    getProductServiceHubEntries: state => state.product_service_hub_entries,
    getProductServiceHubPuzlAdjust: state => state.product_service_hub_puzl_adjust,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload
    },
    [types.SET_WIDGETS](state, payload) {
      state.widgets = payload
    },
    [types.SET_BY_CODE](state, payload) {
      state.items_by_code = payload
    },
    [types.SET_BY_CONTRACT_PROPOSAL](state, payload) {
      state.items_by_contract_proposal = payload
    },
    [types.SET_ENTRY_RESUME](state, payload) {
      state.entryResumeItems = payload
    },
    [types.SET_SERVICES](state, payload) {
      state.services = payload
    },
    [types.SET_PRODUCT_SERVICE_HUB_ENTRIES](state, payload) {
      state.product_service_hub_entries = payload
    },
    [types.SET_PRODUCT_SERVICE_HUB_PUZL_ADJUST](state, payload) {
      state.product_service_hub_puzl_adjust = payload
    },
  },
  actions: {
    getWidgets({commit}, params) {
      return createAxios().get(`${endPoint}resume/widgets`, {params: params})
        .then(({data}) => {
          commit(types.SET_WIDGETS, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchItems({commit}, params) {
      return createAxios().get(`${endPoint}resume`, {params: params})
        .then(({data}) => {
          commit(types.SET, data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchItemsByCode({commit}, params) {
      return createAxios().get(`${endPoint}resume/by-code`, {params: params})
        .then(({data}) => {
          commit(types.SET_BY_CODE, data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchItemsByContractProposal({commit}, params) {
      return createAxios().get(`${endPoint}resume/by-contract-proposal`, {params: params})
        .then(({data}) => {
          commit(types.SET_BY_CONTRACT_PROPOSAL, data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getEntryResume({commit}, params) {
      return createAxios().get(`${endPoint}entry-resume`, {params: params})
        .then(({data}) => {
          commit(types.SET_ENTRY_RESUME, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getProductServiceHubEntries({commit}, payload) {
      return createAxios().get(`${endPoint}entry-resume/product-service-hub/${payload.product_service_hub_id}`, {params: payload})
        .then(({data}) => {
          commit(types.SET_PRODUCT_SERVICE_HUB_ENTRIES, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getProductServiceHubPuzlAdjust({commit}, payload) {
      return createAxios().get(`${endPoint}entry-resume/puzl-adjust/product-service-hub/${payload.product_service_hub_id}`, {params: payload})
        .then(({data}) => {
          commit(types.SET_PRODUCT_SERVICE_HUB_PUZL_ADJUST, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getServices({commit}, params) {
      return createAxios().get(`${endPoint}resume/service`, {params: params})
        .then(({data}) => {
          commit(types.SET_SERVICES, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getReportExcel({_}, params) {
      return createAxios()
        .get(endPoint + 'resume/mcc/report-excel', {params: params})
        .then(({data}) => Promise.resolve(data))
        .catch(error => Promise.reject(error));
    },
  }
}
