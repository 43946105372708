<template>
<div>
    <modal :show.sync="modal.update">
        <template slot="header">
            <h5 class="modal-title">{{ modal.title }}</h5>
        </template>
        <SkeletonPuzlModalMd v-show="isLoading"></SkeletonPuzlModalMd>
        <div v-show="!isLoading">
            <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
                <form class="needs-validation" @submit.prevent="handleSubmit(update)" autocomplete="off">
                    <div>
                        <div class="card-header border-header-default-bottom p-2 grid-col--fixed-left">
                            <h5 class="mb-0" slot="title">Identificação</h5>
                        </div>
                        <div class="form-group row m-0 p-0">
                            <div class="col-md-6 pt-2">
                                <div class="col-form-label form-control-label">
                                    Status
                                </div>
                                <div class="pt-2">
                                    <base-switch v-model="editServicePrice.status" type="success" offText="inativo" onText="ativo" class="success mt-n2">
                                    </base-switch>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="pt-1 pb-0 mb-1 mt-3 col-form-label form-control-label">
                                    Central
                                </div>
                                <base-input input-classes="form-control form-control-sm">
                                    <puzl-select 
                                        v-model="editServicePrice.company_plant_ids" 
                                        :items="listCompanyPlant" 
                                        :multiple="true"
                                        :loading="isloadingListCompanyPlant" 
                                        :disabled="isloadingListCompanyPlant" />
                                </base-input>
                            </div>
                        </div>
                        <div class="form-group row m-0 p-0 mb-1">
                            <div class="col-md-6">
                                <div class="pt-1 pb-0 mt-2 col-form-label form-control-label">
                                    Serviço
                                    <span class="text-danger">&nbsp;*</span>
                                </div>
                                <validation-provider rules="required" v-slot="{errors}">
                                    <div class="mt-1">
                                        <base-input input-classes="form-control form-control-sm">
                                            <el-select 
                                                v-model="editServicePrice.service_id" 
                                                filterable 
                                                placeholder="Selecione" 
                                                size="mini" 
                                                :loading="isloadingListServices" 
                                                :disabled="true"
                                                :class="errors[0] ? 'is-invalid' : 'is-valid'"
                                            >
                                                <el-option v-for="itemService in listServices" :key="itemService.id" :label="itemService.service_name" :value="itemService.id">
                                                </el-option>
                                            </el-select>
                                        </base-input>
                                    </div>
                                </validation-provider>
                            </div>
                            <div class="col-md-6">
                                <div class="pt-1 pb-0 col-form-label form-control-label">
                                    Descrição
                                    <span class="text-danger">&nbsp;*</span>
                                    <span class="btn btn-sm btn-success p-1 mb-1 ml-1" @click.prevent="handleCreateDescription()">
                                        <i class="fas fa-plus"></i> Novo
                                    </span>
                                </div>
                                <validation-provider rules="required" v-slot="{errors}">
                                    <base-input input-classes="form-control form-control-sm">
                                        <el-select 
                                            v-model="editServicePrice.description_id" 
                                            filterable 
                                            :placeholder="placeholderDescription" 
                                            size="mini" 
                                            :loading="isloadingDescriptions" 
                                            :disabled="true"
                                            :class="errors[0] ? 'is-invalid' : 'is-valid'"
                                        >
                                            <el-option v-for="itemDescription in listDescriptions" :key="itemDescription.id" :label="itemDescription.description" :value="itemDescription.id">
                                            </el-option>
                                        </el-select>
                                    </base-input>
                                </validation-provider>
                            </div>
                        </div>
                        <div class="form-group row m-0 p-0">
                            <div class="col-md-6 pt-2">
                                <div class="col-form-label form-control-label">
                                    Puzl Cliente
                                </div>
                                <base-switch v-model="editServicePrice.puzl_customer" offText="não" onText="sim" class="success mt-n2">
                                </base-switch>
                            </div>
                            <div class="col-md-6">
                                <div class="pt-1 pb-0 mt-3 col-form-label form-control-label">
                                    <span class="text-purple">
                                        Acrescimo Puzl Cliente
                                    </span>
                                </div>
                                <base-input input-group-classes="input-group-sm">
                                    <input inputmode="numeric" v-model="editServicePrice.addition_puzl_customer" class="form-control form-control-sm" v-mask="['##']" />
                                    <template slot="append">
                                        <small class="input-group-sm p-0 m-0">
                                            %
                                        </small>
                                    </template>
                                </base-input>
                            </div>
                        </div>
                        <div class="border-header-danger-bottom mb-2 p-2">
                            <h5 class="mb-0" slot="title">Preço Mínimo</h5>
                        </div>
                        <div class="form-group row m-0 p-0 mb-1">
                            <div class="col-md-4">
                                <div class="pt-1 pb-0 col-form-label form-control-label">
                                    Fixo
                                </div>
                                <base-input input-group-classes="input-group-sm">
                                    <input inputmode="numeric" v-model="editServicePrice.price_fixed" class="form-control form-control-sm" v-mask="['#.##','##.##', '###.##', '####.##']" />
                                    <template slot="prepend">
                                        <small class="input-group-sm p-0 m-0">
                                            R$
                                        </small>
                                    </template>
                                </base-input>
                            </div>
                            <div class="col-md-4">
                                <div class="pt-1 pb-0 col-form-label form-control-label">
                                    Por m<sup>3</sup>
                                </div>
                                <base-input input-group-classes="input-group-sm">
                                    <input inputmode="numeric" v-model="editServicePrice.by_meters" class="form-control form-control-sm" v-mask="['#.##','##.##', '###.##', '####.##']" />
                                    <template slot="append">
                                        <small class="input-group-sm p-0 m-0">
                                            /m<sup>3</sup>
                                        </small>
                                    </template>
                                    <template slot="prepend">
                                        <small class="input-group-sm p-0 m-0">
                                            R$
                                        </small>
                                    </template>
                                </base-input>
                            </div>
                            <div class="col-md-4">
                                <div class="pt-1 pb-0 col-form-label form-control-label">
                                    Por Hora
                                </div>
                                <base-input input-group-classes="input-group-sm">
                                    <input inputmode="numeric" v-model="editServicePrice.by_hour" class="form-control form-control-sm" v-mask="['#.##','##.##', '###.##', '####.##']" />
                                    <template slot="append">
                                        <small class="input-group-sm p-0 m-0">
                                            /hora
                                        </small>
                                    </template>
                                    <template slot="prepend">
                                        <small class="input-group-sm p-0 m-0">
                                            R$
                                        </small>
                                    </template>
                                </base-input>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <base-button type="secondary" @click="closeModal('update')">Cancelar</base-button>
                        <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loading">Salvar</base-button>
                    </div>
                </form>
            </validation-observer>
        </div>
    </modal>
    <ModalCreateDescriptionService ref="modalCreateDescriptionService" />
</div>
</template>

<script>
const {
    formatErrorValidation
} = require("@/plugins")
import SkeletonPuzlModalMd from "@/components/SkeletonPuzlModalMd";
import ModalCreateDescriptionService from '../../ServiceDescription/Shared/_ModalCreate'
import PuzlSelect from "@/components/PuzlSelect"
export default {
    components: {
        SkeletonPuzlModalMd,
        ModalCreateDescriptionService,
        PuzlSelect,
    },
    name: "AddServicePrice",
    data() {
        return {
            modal: {
                title: '',
                create: false,
                update: false,
            },
            validated: false,
            listServices: {},
            listDescriptions: {},
            listCompanyPlant: {},
            editServicePrice: {
                uuid: null,
                status: false,
                puzl_customer: false,
                increment_puzl_customer: null,
                price_fixed: null,
                by_meters: null,
                by_hour: null,
                description_id: null,
                service_id: null,
                company_plant_ids: [],
            },
            validate: false,
            loading: false,
            isloadingListServices: false,
            isloadingDescriptions: false,
            isDisabledDescriptions: true,
            placeholderDescription: 'Selecione...',
            placeholderCompanyPlant: 'Selecione...',
            isloadingListCompanyPlant: false,
            isLoading: false,
        };
    },
    methods: {
        closeModal(name) {
            this.modal[name] = false
        },
        openModal(name) {
            this.modal[name] = true
            this.loadData()
        },
        handleCreateDescription() {
            this.$refs.modalCreateDescriptionService.handleCreate()
        },
        handleEditServicePrice(uuid) {
            this.getServicePrice(uuid)
            this.modal.title = 'Editar preço mínimo serviço';
            this.openModal('update');
        },
        showDescriptionByService(descriptionID) {
            this.placeholderDescription = 'Aguarde...'
            this.isloadingDescriptions = true;
            let serviceId = this.editServicePrice.service_id;
            let service = this.listServices.filter(function (item) {
                return item.id == serviceId;
            });
            this.$Progress.start();
            this.$axios.get("/commercial/service/description/service/" + service[0].uuid)
                .then(({
                    data
                }) => {
                    this.listDescriptions = data.data
                    this.placeholderDescription = 'Selecione'
                    this.isloadingDescriptions = false
                    this.isDisabledDescriptions = false
                    this.editServicePrice.description_id = descriptionID
                    this.$Progress.finish()
                })
                .catch((error) => {});
        },
        getServicePrice(uuid) {
            this.$Progress.start();
            this.isLoading = true
            this.$axios.get("/commercial/service/price/" + uuid)
                .then(({
                    data
                }) => {
                    let servicePrice = data.data;
                    let company_ids = servicePrice.company_plants.map(function (item) {
                        return item.id;
                    });
                    this.editServicePrice.service_id = servicePrice.service.id
                    this.showDescriptionByService(servicePrice.description.id)
                    this.editServicePrice = {
                        uuid: servicePrice.uuid,
                        status: servicePrice.status,
                        puzl_customer: servicePrice.puzl_customer,
                        increment_puzl_customer: servicePrice.increment_puzl_customer,
                        price_fixed: servicePrice.price_fixed,
                        by_meters: servicePrice.by_meters,
                        by_hour: servicePrice.by_hour,
                        description_id: null,
                        service_id: servicePrice.service.id,
                        company_plant_ids: company_ids,
                    }
                    this.$Progress.finish();
                    this.isLoading = false
                })
                .catch((error) => {});
        },
        update() {
            this.$Progress.start();
            this.loading = true;
            this.$notify({
                type: 'info',
                message: 'Estamos trabalhando em sua solicitação.'
            });
            this.$axios.put("/commercial/service/price/" + this.editServicePrice.uuid, this.editServicePrice)
                .then(({
                    data
                }) => {
                    this.$notify({
                        type: data.error_type,
                        message: data.message
                    });
                    this.loading = false;
                    this.addServicePrice = {
                        uuid: '',
                        status: true,
                        puzl_customer: false,
                        increment_puzl_customer: '',
                        price_fixed: '',
                        by_meters: '',
                        by_hour: '',
                        description_id: '',
                        service_id: '',
                    };
                    EventBus.$emit("getServicePrice");
                    this.closeModal('update');
                    this.loading = false;
                    this.$Progress.finish();
                })
                .catch((error) => {
                    if (error.status) {
                        this.$Progress.finish()
                        this.loading = false
                        this.$notify({
                            type: error.data.error_type,
                            message: error.data.message
                        })
                    } else {
                        if (error.response.status === 422) {
                            let message = formatErrorValidation(error.response.data.errors)
                            this.$notify({
                                type: 'danger',
                                message: message
                            })
                            this.$Progress.finish()
                            this.loading = false
                        } else {
                            this.$notify({
                                type: error.data.error_type,
                                message: error.data.message
                            })
                            this.$Progress.finish()
                            this.loading = false
                        }
                    }
                });
        },
        getServices() {
            this.$Progress.start();
            this.isloadingListServices = true;
            this.$axios.get("/configuration/service/active")
                .then(({
                    data
                }) => {
                    this.listServices = data.data;
                    this.$Progress.finish();
                    this.isloadingListServices = false;
                })
                .catch((error) => {
                    if (error) {
                        this.isloadingListServices = false;
                    }
                });
        },
        getCompanyPlant() {
            this.$Progress.start();
            this.placeholderCompanyPlant = "Aguarde..."
            this.isloadingListCompanyPlant = true;
            this.$axios.get("/company/plant/active")
                .then(({
                    data
                }) => {
                    this.listCompanyPlant = data.data;
                    this.$Progress.finish();
                    this.placeholderCompanyPlant = "Selecione"
                    this.isloadingListCompanyPlant = false;
                })
                .catch((error) => {
                    if (error) {
                        this.isloadingListCompanyPlant = false;
                    }
                });
        },
        loadData() {
            this.getServices();
            this.getCompanyPlant();
        },
    },

    mounted() {
        this.$refs.formValidator.validate();
        EventBus.$on("handleEditServicePrice", (uuid) => {
            this.handleEditServicePrice(uuid);
        });
    }
};
</script>

<style></style>
