<template>
  <div>
    <base-header>
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 d-inline-block mb-0" v-if="loading">
          <div class="greetings d-flex align-items-center">
            <img class="mr-1" src="/img/brand/logo.png" alt="Puzl Place" width="25px" height="25px">
            <span style="color: #7A7A7B;">{{ userName }} , {{ greeting }}</span>
          </div>
          </h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-md-4">
          <div class="card">
            <div class="card-header shadow-header d-flex justify-content">
              <img class="mr-2" src="/img/icons/icons8/ios/favorites.png" alt="favorites" width="25px" height="25px">
              <span class="pt-1"> <strong>Meus Favoritos</strong></span>
              <div v-if="showFavorites" class="ml-auto mt-2 hide-above-sm" @click.prevent="handleHideFavorites()">
                <i class="fa-solid fa-chevron-down"></i>
                Ocultar
              </div>
              <div v-else class="ml-auto mt-2 hide-above-sm" @click.prevent="handleShowFavorites()">
                <i class="fa-solid fa-chevron-up"></i>
                Expandir
              </div>
            </div>
            <div class="row card-body" v-show="loadingSkeleton">
                <SkeletonPuzlGrid class="col-lg-6" v-for="(index) in 2" :key="index"></SkeletonPuzlGrid>
            </div>
            <div class="card-body" id="button-hide-favorites" v-if="!loadingSkeleton">
              <div class="row row-cols-1 row-cols-md-2">
                <div v-for="(card, index) in cardsUser" :key="card.id">
                  <div v-if="card.menu && card.module">
                    <div class="col-12 mb-1">
                      <div class="card" style="height: 100px;">
                        <div class="card-body pl-3">
                          <div class="float-right">
                            <a href="#" class="float-right mr--2 mt-n3">
                              <i
                                @click="deleteCard(card.id)"
                                class="fas fa-times text-black"
                                style="font-size: 13px;">
                              </i>
                            </a>
                          </div>
                          <a href="javascript:void(0)" @mousedown="openPage($event, card.link)">
                            <div style="margin-top: 4px;">
                              <div class="d-flex">
                                <img class="":src="'/img/icons/' + card.image_home_menu" width="30px" height="30px">
                                <div class="ml-3 text-dark">
                                  <b class="font-weight-bold"> {{ card.module }} </b>
                                  <br>
                                  <b class="font-weight-light" style="font-size: 11px;"> {{ card.menu }} </b>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="col-12 mb-1">
                        <div class="card" style="height: 100px;">
                          <div class="card-body ml-2">
                            <a href="javascript:void(0)" @click="handleModalMenu(index)">
                              <div class="row ml-1">
                                <div class="col-3 pt-2">
                                  <img :src="'/img/icons/icons8/ios/add-folder--v1.png'" alt="add-folder--v1" width="32px" height="32px"/>
                                </div>
                                <div class="col-9">
                                  <div class="allign-middle pt-3">
                                    <b class="font-weight-bold" style="color: #7A7A7B;"> Adicionar </b>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-8">
          <div class="card">
            <div class="card-header d-flex align-items-center justify-content-between shadow-header">
              <div class="d-flex align-items-center">
                <img class="mr-2" src="/img/icons/icons8/ios/bulleted-list.png" alt="bulleted-list" width="25px" height="25px">
                <span class="pt-1"><strong>Minhas Tarefas</strong></span>
                <el-popover
                  class="ml-2 mb--1 pt-2"
                  placement="right"
                  trigger="hover"
                >
                  <div>
                    <span>
                      <span class="text-center text-dark" style="font-weight: 500 !important;">
                        As tarefas serão excluídas automaticamente após 2 meses de sua data de criação.
                      </span>
                    </span>
                  </div>
                    <base-button
                      slot="reference"
                      class="p-0 m-0 shadow-none"
                      outiline
                      size="sm"
                      type="secundary"
                    >
                      <img
                        src="/img/icons/icons8/ios/info-circle_warning.png"
                        width="25px"
                        height="25px"
                        class="mt--3"
                      />
                    </base-button>
                </el-popover>
              </div>
              <base-button @click="this.handleCreateModal" class="pl-5 pr-5" type="success" size="sm">
                <img height="19" src="/img/icons/plus-math--v1-white.png" class="mt-n1" />
                NOVO
              </base-button>
            </div>
            <div class="card-body" style="max-height: 730px; overflow-y: scroll;">
              <MultiFilter
                ref="multi-filter"
                @fetch="init"
                @handleFilterDate="handleFilterLimitDate"
                :showSlotData = "false"
                :showSlotDates="true"
                :showSlotStatus="true"
                :showSlotBookmark="true"
                :defaultRange="filter.limit_date_range"
                @clearFilter="clearFilter()"
                :type="1"
              >
                <template slot="dates">
                  <div class="col-12 mt-1 mb-3 text-left">
                    <label class="form-control-label">
                      Data de Criação
                    </label>
                    <InputDatePicker
                      :disable-max-date="false"
                      :defaultRange="filter.created_date_range"
                      :disabled="false"
                      :filterable="4"
                      visibility="focus"
                      @handleFilterDate="handleFilterCreatedDate"
                      size="md"
                    />
                  </div>
                </template>
                <template slot="status">
                  <div class="col-12 mt-1 mb-2">
                    <BaseButtonHoverable
                      size="sm"
                      type="primary"
                      icon="double-tick"
                      platform="ios"
                      @click="toggleCompletedButton()"
                      :active="filter.status === TaskStatusEnum.DONE"
                    >
                      Concluídas
                    </BaseButtonHoverable>
                    <BaseButtonHoverable
                      class="mt-2"
                      size="sm"
                      type="warning"
                      icon="hourglass"
                      platform="ios"
                      @click="togglePendingButton()"
                      :active="filter.status === TaskStatusEnum.PENDING"
                    >
                      Pendentes
                    </BaseButtonHoverable>
                    <BaseButtonHoverable
                      class="mt-2"
                      size="sm"
                      type="dark"
                      icon="infinity"
                      platform="ios"
                      @click="clearFilter()"
                      :active="filter.status === TaskStatusEnum.ALL"
                    >
                      Todas
                    </BaseButtonHoverable>
                  </div>
                </template>
                <template slot="bookmarks">
                  <div class="col-12 mt-1 mb-1">
                      <BaseButtonHoverable
                        class="mt-2"
                        size="sm"
                        type="success"
                        icon="what-i-do"
                        platform="ios"
                        @click="handleMyTasksButton()"
                        :active="filter.myTasks === true"
                      >
                        Minhas Tarefas
                      </BaseButtonHoverable>
                    </div>
                  <div class="col-12 mt-1 mb-1">
                    <BaseButtonHoverable
                      class="mt-2"
                      size="sm"
                      type="primary"
                      icon="why-quest"
                      platform="ios"
                      @click="handleRequestsButton()"
                      :active="filter.requests === true"
                    >
                      Solicitações
                    </BaseButtonHoverable>
                  </div>
                  <div class="col-12 mt-1 mb-1">
                    <BaseButtonHoverable
                      class="mt-2"
                      size="sm"
                      type="dark"
                      icon="why-us-male"
                      platform="ios"
                      @click="handleMyRequestsButton()"
                      :active="filter.myRequests === true"
                    >
                      Minhas Solicitações
                    </BaseButtonHoverable>
                  </div>
                </template>
              </MultiFilter>

              <div class="row card-body" v-show="loadingSkeleton">
                <SkeletonPuzlGrid class="col-lg-12" v-for="(index) in 1" :key="index"></SkeletonPuzlGrid>
              </div>

              <div class="p-3" style="background-color: #F3F8FB;" v-if="!loadingSkeleton">
                <PuzlEmptyData v-if="!$_tasks.length && !loadingSkeleton"></PuzlEmptyData>
                <div v-for="(task, index) in $_tasks" :key="index">
                  <div class="card" style="height: 115px;">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <div class="top-section mt--3">
                        <span class="font-weight-light mr-2" style="font-size: 10px">
                          {{ checkInfoTaskName(task) }}
                        </span>
                        <base-button
                          v-if="
                            task.status == TaskStatusEnum.DONE &&
                            isResponsibleTask(task) ||
                            task.status == TaskStatusEnum.DONE &&
                            isCreatorTask(task) &&
                            !isOwnTask(task)
                          "
                          @click="updateStatusTask(task.id, TaskStatusEnum.PENDING)"
                          :disabled="
                            task.status == TaskStatusEnum.DONE &&
                            isCreatorTask(task) &&
                            !isOwnTask(task) ? true : false
                          "
                          size="sm"
                          type="empty"
                          class="pl-3 pr-3 shadow-dropdown rounded-pill dropdown-toggle"
                        >
                          <img
                            src="/img/icons/icons8/ios/checked--v1.png"
                            width="15px"
                            height="15px"
                          />
                          FEITO
                        </base-button>
                        <base-button
                          v-if="task.status == TaskStatusEnum.PENDING && isResponsibleTask(task)"
                          @click="updateStatusTask(task.id, TaskStatusEnum.DONE)"
                          size="sm"
                          type="empty"
                          class="pl-3 pr-3 shadow-dropdown rounded-pill dropdown-toggle"
                        >
                          <img
                            src="/img/icons/icons8/ios/hourglass_warning.png"
                            width="15px"
                            height="15px"
                          />
                          PENDENTE
                        </base-button>
                        <base-button
                          v-if="!task.is_aware && isResponsibleTask(task) && !isOwnTask(task)"
                          @click="updateAware(task.id)"
                          size="sm"
                          type="empty"
                          class="pl-3 pr-3 shadow-dropdown rounded-pill dropdown-toggle"
                        >
                          <img
                            src="/img/icons/icons8/ios/task-completed.png"
                            width="15px"
                            height="15px"
                          />
                          CIENTE
                        </base-button>
                      </div>
                      <div class="ml-auto">
                        <base-dropdown>
                          <base-button 
                            slot="title-container"
                            type="empty"
                            size="sm"
                            class="dropdown-toggle shadow-none mt--2 mr--2"
                            style="border-radius: 4px !important;"
                          >
                            <img 
                              height="30px"
                              width="30px"
                              src="/img/icons/icons8/ios/settings--v1_primary.png"
                              class="mr-1 mt-n1" 
                            />
                          </base-button>
                          <span 
                            class="dropdown-item"
                            v-if="isCreatorTask(task)"
                            @click="handleEditModal(task.id)"
                          >
                            <img
                              src="/img/icons/create-new.png"
                              width="19px"
                              height="19px"
                              class="imgBaseButton"
                            >
                            EDITAR
                          </span>
                          <div class=" dropdown-divider p-0 m-0"></div>
                          <span class="dropdown-item" @click="handleHistoryModal(task.id)">
                            <img
                              src="/img/icons/order-history.png"
                              width="19px"
                              height="19px"
                              class="imgBaseButton"
                            />
                            HISTÓRICO
                          </span>
                          <div class=" dropdown-divider p-0 m-0"></div>
                          <span
                            class="dropdown-item"
                            v-if="isCreatorTask(task) && task.status !== TaskStatusEnum.DONE"
                            @click="handleDelete(task.id)"
                          >
                            <img
                              src="/img/icons/icons8/ios/delete--v1.png"
                              width="19px"
                              height="19px"
                              class="imgBaseButton" 
                            />
                            EXCLUIR
                          </span>
                        </base-dropdown>
                      </div>
                    </div>
                    <div class="mid-section mt--1">
                      <div class="d-flex justify-content-start">
                        <img
                          :src="TaskPriorityImageEnum[task.priority]" 
                          width="25px"
                          height="25px"
                          style="margin-top: -3px;"
                        />
                        <span class="ml-3 text-dark" style="font-weight: 500 !important;">
                          {{ task.description }}
                        </span>
                      </div>
                    </div>
                    <div class="end-section mt-3">
                      <div class="d-flex justify-content-between">
                        <span class="font-weight-light" style="font-size: 10px;">
                          Data limite: {{ formatDate(task.limit_date, null) }}
                        </span>
                        <div class="ml-auto">
                          <el-popover
                            class="p-0 float-right pr-0"
                            placement="left"
                            trigger="hover"
                            v-if="task.observation"
                          >
                            <div>
                              <span>
                                <img
                                src="/img/icons/icons8/ios/speech-bubble-with-dots--v1.png"
                                width="20px"
                                height="20px"
                                class="mr-1"
                              />
                                <span class="text-center text-dark" style="font-weight: 500 !important;">
                                  OBSERVAÇÕES DA TAREFA
                                </span>
                                <br />
                                <div class="mt-1" style="white-space: pre-wrap; max-width: 300px;">
                                  <span class="text-center">
                                    {{ task.observation }}
                                  </span>
                                </div>
                              </span>
                            </div>
                              <base-button
                                slot="reference"
                                class="p-0 m-0 shadow-none"
                                outiline
                                size="sm"
                                type="secundary"
                              >
                                <img
                                  src="/img/icons/icons8/ios/speech-bubble-with-dots--v1.png"
                                  width="25px"
                                  height="25px"
                                  class="mt--3"
                                />
                              </base-button>
                          </el-popover>
                        </div>
                        <img @click.prevent="handleShowAttachments(task.id, task.created_by_user_id)"
                            :src="
                            task.attachment_count > 0
                              ? '/img/icons/attach-green.png'
                              : '/img/icons/attach-black-50.png'
                            " 
                            width="25px"
                            height="25px"
                            class="ml-3 mr-3"
                            style="margin-top: -6px; cursor: pointer;"
                          >
                        <el-popover
                            class="p-0 float-right pr-0"
                            placement="left"
                            trigger="hover"
                            v-if="!isOwnTask(task)"
                          >
                            <div>
                              <span>
                                <img
                                src="/img/icons/icons8/ios/user-male-circle--v1.png"
                                width="20px"
                                height="20px"
                                class="mr-1"
                              />
                                <span class="text-dark mb--2" style="font-weight: 500 !important;">
                                  {{ isResponsibleTask(task) == true ? 'Solicitante' : 'Responsável' }}
                                </span>
                                <br />
                                <div class="mt-1" style="white-space: pre-wrap; max-width: 300px;">
                                  <span class="text-center">
                                    {{ isResponsibleTask(task) == true ? task.creator_name : task.receiver_name }}
                                  </span>
                                  <br>
                                  <span class="text-center">
                                    {{ formatDate(null, task.created_at) }}
                                  </span>
                                </div>
                              </span>
                            </div>
                              <base-button
                                slot="reference"
                                class="p-0 m-0 shadow-none"
                                outiline
                                size="sm"
                                type="secundary"
                              >
                                <img
                                  src="/img/icons/icons8/ios/user-male-circle--v1.png"
                                  width="25px"
                                  height="25px"
                                  class="mt--3"
                                />
                              </base-button>
                          </el-popover>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <LoadingPagination :show="loading && !loadingSkeleton" />
            </div>
        </div>
        </div>
      </div>
  </div>
  <ModalCreateTask ref="modalCreateTask" />
  <ModalHistory ref="modalHistory" />
  <ModalEditTask ref="modalEditTask" />
  <ModalTaskAttachmentList ref="modalTaskAttachmentList" @storedAttachment="storedAttachment" @deletedLastAttachment="deletedLastAttachment"/>
  <ModalMenu @updatedCardList="listCards" ref="ModalMenu"></ModalMenu>
  </div>
</template>

<script>
import ModalMenu from './_ModalMenu';
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import MultiFilter from "@/components/Utils/MultiFilterV3";
import ModalCreateTask from './Shared/_ModalCreateTask';
import ModalEditTask from './Shared/_ModalEditTask';
import ModalTaskAttachmentList from './Shared/_ModalTaskAttachmentList';
import ModalHistory from './Shared/_ModalHistory';
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import InputDatePicker from '@/components/InputDatePicker';
import PuzlEmptyData from "@/components/PuzlEmptyData";
import LoadingPagination from "@/components/LoadingPagination";
import cursorPaginate from "@/mixins/cursorPaginate";
import Pagination from "@/components/Utils/Pagination";
import moment from "moment";
import {TaskStatusEnum} from "@/enum/TaskStatusEnum";
import {TaskInfoNameEnum} from "@/enum/TaskInfoNameEnum";
import {TaskPriorityImageEnum} from "@/enum/TaskPriorityImageEnum";

import {
  mapGetters
} from "vuex";
import ModalInfo from './Shared/ModalInfo.vue'

export default {
  mixins: [cursorPaginate],
  components: {
    ModalMenu,
    SkeletonPuzlGrid,
    ModalInfo,
    MultiFilter,
    ModalCreateTask,
    ModalEditTask,
    ModalTaskAttachmentList,
    ModalHistory,
    BaseButtonHoverable,
    InputDatePicker,
    PuzlEmptyData,
    LoadingPagination,
    Pagination,
  },
  computed: {
    ...mapGetters({
      $_cards: "card/fetch",
      $_tasks: "task/fetch"
    })
  },
  data() {
    return {
      filter : {
        status: TaskStatusEnum.ALL,
        myTasks: null,
        requests: null,
        myRequests: null,
        type_filter_date: 'limit_date',
        limit_date_range: {
          start: moment().subtract(7, 'days').format('YYYY-MM-DD'),
          end: moment().add(7, 'days').format('YYYY-MM-DD')
        },
        created_date_range: {
          start: moment().format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD')
        },
      },
      multi_filter: { // viagens
        1: {
          name: "Concluído",
          type: "success",
        },
        6: {
          name: "Cancelado",
          type: "danger",
        },
      },
      months: [
        'JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'
      ],
      showFavorites: true,
      loadingSkeleton: false,
      loadingTasks: false,
      loading: false,
      loaderTasks: false,
      greeting: null,
      userName: null,
      userId: null,
      cards: null,
      cardsUser: [
        {id: null, menu: null, module: null, link: null, icon_style: null, image_home_menu: null},
        {id: null, menu: null, module: null, link: null, icon_style: null, image_home_menu: null},
        {id: null, menu: null, module: null, link: null, icon_style: null, image_home_menu: null},
        {id: null, menu: null, module: null, link: null, icon_style: null, image_home_menu: null},
        {id: null, menu: null, module: null, link: null, icon_style: null, image_home_menu: null},
        {id: null, menu: null, module: null, link: null, icon_style: null, image_home_menu: null},
        {id: null, menu: null, module: null, link: null, icon_style: null, image_home_menu: null},
        {id: null, menu: null, module: null, link: null, icon_style: null, image_home_menu: null},
        {id: null, menu: null, module: null, link: null, icon_style: null, image_home_menu: null},
        {id: null, menu: null, module: null, link: null, icon_style: null, image_home_menu: null},
      ],
      TaskStatusEnum: TaskStatusEnum,
      TaskInfoNameEnum: TaskInfoNameEnum,
      TaskPriorityImageEnum: TaskPriorityImageEnum
    }
  },
  async mounted() {
    this.loadingSkeleton = true;
    this.loadingTasks = true;
    // Obtem o id do usuário logado
    await this.$store.dispatch("auth/loadSession").then((response) => {
      this.userId = response.data.user.id;
      this.userName = response.data.user.name;
    });

    let has_read_news = localStorage.getItem('read_news')
    if (!has_read_news) {
      /**
       * desabilitado por enquanto
       */
      // this.$refs.modalInfo.handleCreateModal()
      localStorage.setItem('read_news', 1)
    }
    this.getWelcomeMessage();
    // Obtem os cards do usuário
    await this.$store.dispatch('card/fetchItems', {
      userId: this.userId
    }).then((response) => {
      // Insere no array estático os dados dos cards cadastrados do usuário
      this.cards = response.data;
      //Loop 9x alterando as posições
      this.cards.map((item, i) => {
        this.cardsUser[response.data[i].order].id = response.data[i].id;
        this.cardsUser[response.data[i].order].menu = response.data[i].menu;
        this.cardsUser[response.data[i].order].module = response.data[i].module;
        this.cardsUser[response.data[i].order].link = response.data[i].link;
        this.cardsUser[response.data[i].order].icon_style = response.data[i].icon_style;
        this.cardsUser[response.data[i].order].image_home_menu = response.data[i].image_home_menu;
      })
      this.loadingSkeleton = false;
      this.loading = true;
    }).catch((error) => {
      this.$notify({
        type: 'danger',
        message: 'Erro ao carregar cards do dashboard'
      })
    });
    this.init(this.filter);
  },
  methods: {
    /**
     * Atualiza lista de cards após o cadastro.
     */
    listCards(cards) {
      cards.map((item, i) => {
        this.cardsUser[cards[i].order].id = cards[i].id;
        this.cardsUser[cards[i].order].menu = cards[i].menu;
        this.cardsUser[cards[i].order].module = cards[i].module;
        this.cardsUser[cards[i].order].link = cards[i].link;
        this.cardsUser[cards[i].order].icon_style = cards[i].icon_style;
        this.cardsUser[cards[i].order].image_home_menu = cards[i].image_home_menu;
      })
    },
    handleModalMenu(index) {
      this.$refs.ModalMenu.handleModalInsertMenu(index)
    },
    handleCreateModal() {
      this.$refs.modalCreateTask.handleCreateModal(this.userId);
    },
    handleEditModal(taskId) {
      this.$refs.modalEditTask.handleCreateModal(taskId);
    },
    handleHistoryModal(taskId) {
      this.$refs.modalHistory.openModal(taskId);
    },
    deleteCard(id) {
      this.$store.dispatch('card/destroy', id).then((response) => {
        const index = this.cardsUser.findIndex((item) => item.id == id);
        this.cardsUser[index].id = null;
        this.cardsUser[index].menu = null;
        this.cardsUser[index].module = null;
        this.cardsUser[index].link = null;
        this.cardsUser[index].icon_style = null;
        this.cardsUser[index].image_home_menu = null;
      });
    },
    openPage(event, link) {
      if (event.button === 0) { // botão esquerdo do mouse
        this.$router.push("/" + link);
      } else if (event.button === 2) { // botão direito do mouse
        window.open(link, '_blank');
      }
    },
    getWelcomeMessage() {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();
      if (currentHour < 12) {
        this.greeting = 'Bom dia!';
      } else if (currentHour < 18) {
        this.greeting = 'Boa tarde!';
      } else {
        this.greeting = 'Boa noite!';
      }
    },
    async init(filter = null) {
      this.startCursor(filter);
      this.loaderTasks = true;
      await this.$store.dispatch('task/fetchItems', {
        user_id: this.userId,
        type_filter_date: this.filter.type_filter_date,
        limit_date_range: this.filter.limit_date_range,
        created_date_range: this.filter.created_date_range,
        paginate_type: 1,
        limit: 10,
        page: this.paginate.nextUrl,
        order_by: [
          {
            column: 'limit_date',
            is_desc: true
          }
        ],
        status: this.filter.status,
        my_tasks: this.filter.myTasks,
        requests: this.filter.requests,
        my_requests: this.filter.myRequests,
        custom_search: this.$helper.customSearchFormatter(["tasks.description"], filter?.global),
      }).then((response) => {
        this.loadingTasks = false;
        this.loaderTasks = false;
        this.resolveCursor(response);
      }).catch((error) => {
        this.resolveCursor();
        this.$notify({
          type: 'danger',
          message: 'Erro ao carregar tasks do dashboard'
        })
      });
    },
    clearFilter() {
      this.filter = {
        type_filter_date: 'limit_date',
        limit_date_range: {
          start: moment().subtract(7, 'days').format('YYYY-MM-DD'),
          end: moment().add(7, 'days').format('YYYY-MM-DD')
        },
        created_date_range: {
          start: moment().format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD')
        },
        status: TaskStatusEnum.ALL,
        myTasks: null,
        requests: null,
        myRequests: null,
      }

      this.init(this.filter);
    },
    /**
     * Método para listagem do tipo da tarefa
     */
    checkInfoTaskName(task) {
      switch (true) {
        case task.created_by_user_id == this.$user.id && task.receiver_user_id == this.$user.id : // se a tarefa foi criada para eu mesmo.
          return this.TaskInfoNameEnum.MY_TASKS;
        case task.created_by_user_id != this.$user.id : // se a tarefa foi criada por outra pessoa, para mim.
          return this.TaskInfoNameEnum.REQUESTS;
        case task.receiver_user_id != this.$user.id : // se a tarefa foi criada por mim, para outra pessoa.
          return this.TaskInfoNameEnum.MY_REQUESTS;
      }
    },
    formatDate(limit_date = null, timestamp = null) {
      const date = new Date(limit_date != null ? limit_date : timestamp);

      const day = date.getDate();
      const month = this.months[date.getMonth()];
      const year = date.getFullYear();

      if(limit_date != null) {
        return `${day} ${month} ${year}`;
      } else {
        const hour = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        return `${day} ${month} ${year} ${hour}:${minutes}`;
      }
    },
    handleShowAttachments(taskId, createdByUserId) {
      this.$refs.modalTaskAttachmentList.handleCreateModal(taskId, createdByUserId);
    },
    updateStatusTask(taskId, statusId) {

      this.$Swal.confirmAction('Deseja realmente atualizar o status da tarefa?', ['Sim', 'Não'])
        .then((result) => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$store.dispatch('task/updateStatus', {
              id: taskId,
              status: statusId
            }).then((response) => {
                this.$notify({
                    type: response.error_type,
                    message: response.message
                })
            }).finally(() => {
              this.$Progress.finish();
            })
          }
      })
    },
    updateAware(taskId) {
      this.$Progress.start();
      this.$store.dispatch('task/updateAware', {
        id: taskId,
      }).then((response) => {
          this.$notify({
              type: response.error_type,
              message: response.message
          })
      })
      .catch((error) => {
          if (error.status === 200) {
              this.$notify({
                  type: 'danger',
                  message: error.data.message
              })
          } else if (error.response.status === 422) {
              let errors = formatErrorValidation(error.response.data.errors)
              this.$notify({
                  type: 'danger',
                  message: errors
              })
          }
      }).finally(() => {
        this.$Progress.finish();
      })
    },
    handleDelete(id) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$notify({ type: 'info', message: 'Estamos trabalhando em sua solicitação.' });
          this.$store.dispatch('task/destroy', id)
              .then((response) => {
                this.$notify({ type: 'success', message: response.message });
              })
              .catch(error => {
                this.$notify({ type: error.data.error_type, message: error.data.message });
              }).finally(() => {
                this.$Progress.finish();
              });
        }
      }).catch(() => this.$Progress.finish());
    },
    deletedLastAttachment(taskId) {
      let foundTask = this.$_tasks.find((item) => item.id == taskId);
      foundTask.attachment_count --;
    },
    storedAttachment(taskId) {
      let foundTask = this.$_tasks.find((item) => item.id == taskId);
      foundTask.attachment_count ++;
    },
    /**
     * Filtro de status ativo.
     */
    toggleCompletedButton() {
      this.filter.status =
        this.filter.status === this.TaskStatusEnum.DONE ? null : this.TaskStatusEnum.DONE;
      this.init(this.filter);
    },
    /**
     * Filtro de status pendente.
     */
    togglePendingButton() {
      this.filter.status =
        this.filter.status === this.TaskStatusEnum.PENDING ? null : this.TaskStatusEnum.PENDING;
      this.init(this.filter);
    },
    /**
     * Marcadores: minhas tarefas.
     */
    handleMyTasksButton() {
      this.filter.myTasks = this.filter.myTasks ? null : true;
      this.filter.requests = null;
      this.filter.myRequests = null;
      this.init(this.filter);
    },
    /**
     * Marcadores: solicitações.
     */
    handleRequestsButton() {
      this.filter.myTasks = null;
      this.filter.requests = this.filter.requests ? null : true;
      this.filter.myRequests = null;
      this.init(this.filter);
    },
    /**
     * Marcadores: minhas solicitações.
     */
    handleMyRequestsButton() {
      this.filter.myTasks = null;
      this.filter.requests = null;
      this.filter.myRequests = this.filter.myRequests ? null : true;
      this.init(this.filter);
    },
    handleFilterLimitDate(filter) {
      this.filter.type_filter_date = 'limit_date';
      this.filter.created_date_range.start = moment().format('YYYY-MM-DD');
      this.filter.created_date_range.end = moment().format('YYYY-MM-DD');
      this.filter.limit_date_range.start = filter.start;
      this.filter.limit_date_range.end = filter.end;
      this.init(this.filter);
    },
    handleFilterCreatedDate(filter) {
      this.filter.type_filter_date = 'created_date';
      this.filter.created_date_range.start = filter.start;
      this.filter.created_date_range.end = filter.end;
      this.filter.limit_date_range.start = null;
      this.filter.limit_date_range.end = null;
      this.init(this.filter);
    },
    /**
     * Responsável e criador da tarefa.
     */
    isOwnTask(task) {
      return task.receiver_user_id == task.created_by_user_id && task.receiver_user_id == this.$user.id ? true : false;
    },
    /**
     * Responsável pela tarefa
     */
    isResponsibleTask(task) {
      return task.receiver_user_id == this.$user.id ? true : false;
    },
    /**
     * Criador da tarefa
     */
    isCreatorTask(task) {
      return task.created_by_user_id == this.$user.id ? true : false;
    },
    handleHideFavorites() {
      var element = document.getElementById("button-hide-favorites");
      element.classList.add("hide-below-sm");
      this.showFavorites = false;
    },
    handleShowFavorites() {
      var element = document.getElementById("button-hide-favorites");
      element.classList.remove("hide-below-sm");
      this.showFavorites = true;
    }
  },
};
</script>

<style>

.shadow-header {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.shadow-dropdown {
  box-shadow: 1px 2px 1px 1px rgba(0, 0, 0, 0.2) !important;
}

.card-header {
  border-radius: 0 !important;
}

/* esconde o elemento em telas com largura menor que 576px */
@media only screen and (max-width: 576px) {
  .hide-below-sm {
    display: none;
  }
}

/* esconde o elemento em telas com largura maior que 576px */
@media only screen and (min-width: 576px) {
  .hide-above-sm {
    display: none;
  }
}

</style>

