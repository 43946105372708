<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(store)"
            autocomplete="off"
          >
            <div>
              <!-- Central -->
              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-4 pb-0 mb-1 col-form-label form-control-label"
                >
                  Central
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 mb-1 pr-4">
                  <validation-provider>
                    <base-input input-classes="form-control-sm">
                      <puzl-select 
                        v-model="temperature.company_plant_id" 
                        :items="plants"  
                        @input="setTemperature" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Temperatura -->
              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-4 pb-0 mb-1 col-form-label form-control-label"
                >
                  Temperatura
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 mb-1 pr-4">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        inputmode="numeric"
                        :disabled="!temperatureInput || loadingTemperature"
                        v-model="temperature.temperature"
                        class="form-control form-control-sm"
                        maxlength="2"
                        v-on:input="
                          temperature.temperature = formatString(
                            $event.target.value,
                            '0',
                            '50'
                          )
                        "
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          <sup>°</sup>C
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="modal-footer">
                <base-button type="secondary" @click="closeModal('create')">
                  Cancelar
                </base-button>
                <base-button
                  type="success"
                  native-type="submit"
                  v-bind:disabled="invalid"
                  :loading="loadingStore"
                >
                  Salvar
                </base-button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment";
import {checkNumberValue} from "@/helpers";
import {mask1, formatErrorValidation} from "@/plugins";
import PuzlSelect from "@/components/PuzlSelect";
export default {
  name: "ModalCreatetemperature",
  components: { PuzlSelect },
  data() {
    return {
      status: true,
      modal: {
        title: "Adicionar Temperatura Ambiente",
        create: false
      },
      temperature: {
        company_plant_id: null,
        temperature: null,
      },
      loadingStore: false,
      selectedGroups: [],
      temperatureInput: true,
      loadingTemperature: false
    };
  },
  computed: {
    ...mapGetters({
      plants: "plant/fetch"
    })
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    setTemperature() {
      this.$Progress.start();
      this.loadingTemperature = true
      this.$store
        .dispatch("temperatureSetting/getByPlant", this.temperature.company_plant_id)
        .then(response => {
          this.loadingTemperature = false
          this.$Progress.finish();
          this.temperature.temperature = response.data
        })
        .catch(error => {
          this.loadingTemperature = false
          this.$Progress.finish();
        });
    },
    handleCreateModal(plant = null, get_plant = true) {
      this.$Progress.start();
      if (get_plant) {
        this.$store.dispatch("plant/fetchItems");
      }
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.temperatureInput = true;
      this.temperature = {
        company_plant_id: plant,
        temperature: null,
      };
      if (plant) {
        this.$store
          .dispatch("temperatureSetting/getByPlant", plant)
          .then(response => {
            this.modal.create = true;
            this.$Progress.finish();
            this.temperature.temperature = response.data
            this.$emit('setNowTemperature', response.data)
          })
          .catch(error => {
            this.modal.create = true;
            this.$Progress.finish();
          });
      } else {
        this.$store
          .dispatch("temperatureSetting/getByUser")
          .then(response => {
            if (response.data.auto_complete === 1) {
              this.temperature = {
                company_plant_id: response.data.company_plant.id,
                temperature: response.data.temperature,
              };
              this.$Progress.finish();
              this.$notify({
                type: response.error_type,
                message: response.message
              });
              this.modal.create = true;
            } else {
              this.modal.create = true;
              this.$Progress.finish();
            }
          })
          .catch(error => {
            this.modal.create = true;
            this.$Progress.finish();
          });
      }
    },
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        value = mask1(
          checkNumberValue(value, min, max).toString(),
          decimals,
          ".",
          true
        );
        return checkNumberValue(value, min, max);
      }
      return checkNumberValue(value, min, max);
    },
    store() {
      this.$Progress.start();
      this.loadingStore = true;
      this.temperature.date = moment().format('YYYY-MM-DD HH:mm:ss')
      this.$store
        .dispatch("temperature/add", this.temperature)
        .then(response => {
          this.$emit("createdTemperature", this.temperature);
          this.loadingStore = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
        })
        .catch(error => {
          if (error.status === 200) {
            this.$notify({
              type: "danger",
              message: error.data.message
            });
            this.$Progress.finish();
            this.loadingStore = false;
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors
            });
            this.$Progress.finish();
            this.loadingStore = false;
          }
        });
    }
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
};
</script>

<style scoped></style>
