<template>
  <div v-if="!$_stock_location_is_finding">
    <modal :borderInfo="'2px solid #1b6eba'" :show.sync="isOpen" size="lg">
      <template slot="header">
        <div style="display: flex; align-items: center">
          <img
            style="display: inline-flex"
            src="/img/icons/icons8/ios/exchange-dollar.png"
            width="22"
          />
          <h5
            style="display: inline-flex"
            class="modal-title p-0 m-0 ml-3 text-white"
          >
            {{ title }}
          </h5>
        </div>
      </template>

      <StockLocationForm
        :formData="formData"
        @onCancelClick="closeModal"
        @onSubmitClick="handleSubmitForm"
      />
    </modal>
  </div>
</template>

<script setup>
//#region Imports
import { computed, reactive, ref } from "vue";
import { initStockLocationType, StockLocationType } from "../../types";
import stockLocationStore from "../../store/stockLocationStore";
import { dialogs, progress } from "../../../../../helpers";
import StockLocationForm from "../shared/StockLocationForm.vue";
//#endregion

//#region Data
const isOpen = ref(false);
const title = "Local de estoque";
/** @type {StockLocationType} */
const formData = reactive(initStockLocationType());
//#endregion

//#region Computeds
/** Store Getters */
const $_stock_location_is_finding = computed(() => stockLocationStore.getters.getIsFinding());
//#endregion

//#region Methods
/**
 * Abrir modal
 * @param {number} id
 */
function openModal(id) {
  setFormData(id);
  isOpen.value = true;
}

/** Fechar modal */
function closeModal() {
  isOpen.value = false;
}

/** Localizar registro por id e setar dados no formulário */
function setFormData(id) {
  progress.start();
  stockLocationStore.actions.find(id)
    .then((data) => Object.assign(formData, data))
    .finally(() => progress.finish());
}

/** Atualizar registro */
function handleSubmitForm() {
  progress.start();
  const loader = progress.showLoader();
  stockLocationStore.actions.update(formData, formData.id)
    .then(() => {
      dialogs.notify();
      closeModal();
    })
    .finally(() => {
      progress.finish();
      loader.hide();
    });
}
//#endregion

//#region DefineExposes
/**
 * @typedef {Object} StockLocationEditModalExpose
 * @property {typeof openModal} openModal
 */
defineExpose({
  openModal,
});
//#endregion
</script>

<style scoped></style>
