import * as types from './mutation_types'
import {destroy, update, cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'
import { createAxios } from "@/plugins/axios";

const endPoint = '/company/plant/'
export default {
  namespaced: true,
  state: {
    items: [],
    company_plant_issuers: [],
    activeItems: [],
    activeShortItems: [],
    item: {},
    general_setting: {},
  },
  getters: {
    show: state => state.item,
    getCompanyPlantIssuers: state => state.company_plant_issuers,
    fetch: state => state.items,
    getGeneralSettings: state => state.general_setting,
    activeItems: state => state.activeItems,
    activeShortItems: state => state.activeShortItems,
    getPlantWithAll: state => {
      return [{uuid: 'all', name: 'Todos'}, ...state.activeItems]
    },
    getPlantIdWithAll: state => {
      return [{id: 'all', name: 'Todos'}, ...state.activeItems]
    },
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload
    },
    [types.SET_COMPANY_PLANT_ISSUERS](state, payload) {
      state.company_plant_issuers = payload
    },
    [types.SET_ACTIVE](state, payload) {
      state.activeItems = payload
      state.activeShortItems = payload.map(item => ({
        id: String(item.id),
        uuid: item.uuid,
        name: item.name,
        selected_name: item.short_name || item.name,
      }))
    },
    [types.PUSH](state, payload) {
      state.items.push(payload)
    },
    [types.SHOW](state, payload) {
      state.item = payload
    },
    [types.SET_GENERAL_SETTINGS](state, payload) {
      state.general_setting = payload
    },
    [types.DESTROY](state, uuid) {
      destroy(state, uuid)
    },
    [types.UPDATE](state, payload) {
      update(state, payload)
    },
  },
  actions: {
    fetchItems({commit}, filtered = 1) {
      let params = {
        allocated: filtered
      }
      return createAxios().get(endPoint, {params: params})
        .then(({data}) => {
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchItemsWithAdditional({commit}, additional_id) {
      return createAxios().get(endPoint + 'with-additional/' + additional_id)
        .then(({data}) => {
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    postUpdateAdditionalPlant({commit}, params) {
      return createAxios().post(endPoint + 'with-additional', params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchItemsPaginate({commit, state}, params) {
      return createAxios().get(setUrlWithNextPage(`${endPoint}company/${params.company}`, params),
        {params: params})
        .then(({data}) =>{
          cursorPaginate(state, {data : data.data, next_page: params.next_page})
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchItemsActive({commit}, filtered = 1) {
      let params = {
        allocated: filtered
      }
      return createAxios().get(endPoint + 'active', {params: params})
        .then(({data}) => {
          commit(types.SET_ACTIVE, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchCompanyPlantIssuersByPlant({commit}, params) {
      return createAxios().get(`${endPoint}company-plant-issuers/${params.id}`)
        .then(({data}) => {
          commit(types.SET_COMPANY_PLANT_ISSUERS, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchCompanyPlantIssuersByPlantByUuid({commit}, params) {
      return createAxios().get(`${endPoint}company-plant-issuers-by-uuid/${params.uuid}`)
        .then(({data}) => {
          commit(types.SET_COMPANY_PLANT_ISSUERS, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    add({commit, state}, payload) {
      return createAxios().post(endPoint, payload)
        .then(({data}) => {
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    update({commit}, payload) {
      return createAxios().put(endPoint + payload.uuid, payload)
        .then(({data}) => {
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    show({commit, dispatch}, uuid) {
      return createAxios().get(endPoint + uuid)
        .then(({data}) => {
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getGeneralSettings({commit}, uuid) {
      return createAxios().get(endPoint + 'general-settings/' + uuid)
        .then(({data}) => {
          commit(types.SET_GENERAL_SETTINGS, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    addGeneralSettings({}, payload) {
      return createAxios().post(endPoint + 'general-settings', payload)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    addCompanyPlantIssuer({commit}, params) {
      return createAxios().post(endPoint + 'plant-issuer', params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    unbindCompanyPlantIssuer({commit}, params) {
      return createAxios().delete(`${endPoint}${params.id}/plant-issuer/${params.issuer_id}`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    destroy({commit, dispatch}, uuid) {
      return createAxios().delete(endPoint + uuid)
        .then(({data}) => {
          commit(types.DESTROY, uuid)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    postSetDefaultItemIssuer({commit}, params) {
      return createAxios().post(`${endPoint}plant-issuer/default`, params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    postSetNfeEnvironment({commit}, params) {
      return createAxios().post(`nfe-environment`, params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getCompanyPlantByUser({commit}, userId) {
      return createAxios().get(`${endPoint}company-plant-simplified/` + userId)
        .then(({data}) => {
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getCompanyPlantForGlobalFilter({commit}, filtered = 1) {
      let params = {
        allocated: filtered
      }
      return createAxios().get(`${endPoint}global-filter/simplified`, {params: params})
        .then(({data}) => {
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getPlantsSimplified({commit}) {
      return createAxios().get(`${endPoint}all/active/simplified`)
        .then(({data}) => {
          commit(types.SET_ACTIVE, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
