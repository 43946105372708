<template>
  <div>
    <multi-filter ref="multi-filter" @fetch="load" :filter="filter" />

    <div class="container-fluid">
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <!-- Empty Data -->
      <PuzlEmptyData v-if="!groups.length && !loadingSkeleton"></PuzlEmptyData>

      <div class="row card-wrapper" v-if="groups.length && !loadingSkeleton">
        <div class="col-lg-4" v-for="(group, indexGroup) in groups" :key="indexGroup">
          <!-- Basic with action button -->
          <card :class="group.status ? 'card-border-top-success' : 'card-border-top-danger'">
            <!-- Card header -->
            <div class="row align-items-center mb-3">
              <div class="col-8">
                <!-- Title -->
                <h5 class="h3 mb-0">
                  <a href="#" @click.prevent="copyCode(group.uuid)">
                    <i class="fa-solid fa-clipboard text-muted mr-1" title="uuid"></i>
                  </a>
                  <a href="#" @click.prevent="copyCode(group.id)">
                    <i class="fa-solid fa-clipboard text-primary mr-1" title="id"></i>
                  </a>
                  <a href="#" @click.prevent="copyCode(group.external_code)" v-if="group.external_code">
                    <i class="fa-solid fa-clipboard text-success mr-1" title="código externo"></i>
                  </a>
                </h5>
                <h5 class="h3 mb-0">{{ group.group_name }}</h5>
                <h6 class="h3 mb-0 small">{{ group.cmc_category.name }}</h6>
              </div>

              <div class="col-4 text-right">
                <base-dropdown menuOnRight>
                  <base-button
                    slot="title-container"
                    type="primary"
                    class="dropdown-toggle p-2 rounded m-0"
                  >
                    <i class="fas fa-cog"></i>
                  </base-button>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleEditGroup(group.uuid)">
                    <i class="fas fa-edit text-warning"></i>
                    Editar
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleDeleteGroup(group.uuid)">
                    <i class="fas fa-times text-danger"></i>
                    Excluir
                  </a>
                </base-dropdown>
              </div>
            </div>

            <!-- Card body -->
            <collapse class="border rounded" v-if="group.description">
              <collapse-item name="1" class="header-gray" back-ground="border-header-default-bottom">
                <h5 slot="title" class="mb-0 ">Detalhes</h5>
                {{ group.description }}
              </collapse-item>
            </collapse>

          </card>
        </div>
      </div>
      <edit-group ref="editGroup"></edit-group>
    </div>
  </div>
</template>
<script>

import {mapGetters} from 'vuex'
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import EditGroup from  './_Edit'
import { formatErrorValidation } from "@/plugins";
import MultiFilter from '@/components/Utils/MultiFilter.vue';

export default {
  name: "List",
  components: {
    PuzlEmptyData,
    SkeletonPuzlGrid,
    EditGroup,
    MultiFilter
  },
  data () {
    return {
      loadingSkeleton: true,
      filter: {}
    }
  },
  computed: {
    ...mapGetters({
      'groups': 'cmcGroup/fetch',
    }),
  },
  methods:{
    load(filter = {}) {
      this.filter = filter
      this.init();
    },

    init() {
      this.loadingSkeleton = true;
      this.$store.dispatch('cmcGroup/fetchItems', { filter: this.filter })
        .then( response =>{
          this.loadingSkeleton = false
        })
        .catch( error => {
          this.loadingSkeleton = false
        })
    },
    handleEditGroup(uuid){
      this.$refs.editGroup.handleEditModal(uuid)
    },
    copyCode(code) {
      const el = document.createElement('textarea');
      el.value = code;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
      this.$notify({ type: 'success', message: 'Código copiado com sucesso!' })
    },
    handleDeleteGroup(uuid){
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed){
          this.$Progress.start();
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          this.$store.dispatch('cmcGroup/destroy', uuid)
            .then(response => {
              this.$Progress.finish()
              this.$notify({ type: response.error_type, message: response.message })
            })
            .catch(error => {
              if (error.status == 200) {
                this.$notify({
                  type: "danger",
                  message: error.data.message
                });
                this.$Progress.finish();
              } else if (error.response && error.response.status === 422) {
                let errors = formatErrorValidation(error.response.data.errors);
                this.$notify({
                  type: "danger",
                  message: errors
                });
              }
              this.$Progress.finish();
            });
          this.$Progress.finish();
        }
      }).catch(() => this.$Progress.finish())
    }
  },

  mounted() {
    this.filter.status = 1;
    this.$refs['multi-filter']._self.$forceUpdate();
    this.init();
  },

  created () {
  }
}
</script>

<style scoped>

</style>
