import store from "../../../../shared/libraries/store";
import { productServiceHubTypeStoreStateKeys } from "./productServiceHubTypeStoreStateKeys";
import { ProductServiceHubTypeListPagerType } from "../types";
import { BaseListFilterType } from "../../../../shared/types";
import { SearchBarFilterType } from "../../../../components/AppGlobal";

/**
 * @param {ProductServiceHubTypeListPagerType} listed
 * @returns {ProductServiceHubTypeListPagerType}
 */
const setListed = (listed) =>
  store.commit(productServiceHubTypeStoreStateKeys.LISTED, listed);

/**
 * @param {boolean} isListing
 * @returns {boolean}
 */
const setIsListing = (isListing) =>
  store.commit(productServiceHubTypeStoreStateKeys.IS_LISTING, isListing);

/**
 * @param {ProductServiceHubTypeListPagerType} listed
 * @returns {ProductServiceHubTypeListPagerType}
 */
const setListMerge = (listed) =>
  store.commitListMerge(productServiceHubTypeStoreStateKeys, listed);

/**
 * @param {{
 *   searchBarFilter: SearchBarFilterType,
 *   filter: BaseListFilterType
 * }} filters
 */
const setPageState = (filters) =>
  store.commit(productServiceHubTypeStoreStateKeys.PAGE_STATES, filters);

/** Resetar chaves de estado */
const resetStates = () => {
  store.resetStates(Object.values(productServiceHubTypeStoreStateKeys));
};

export default {
  setListed,
  setIsListing,
  setListMerge,
  setPageState,
  resetStates,
};
