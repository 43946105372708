<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>

        <div class="row m-0 p-0" v-show="loading">
          <div class="col-md-8  m-0 p-0">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
          <div class="col-md-4">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
        </div>

        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator" v-show="!loading">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div>
              <div class="row align-items-center mb-3">
                  <div class="col-12">
                    <!-- Title -->
                    <div class="card-header border-header-success-bottom p-2">
                      <h5 class="h4 mb-0" slot="title">Dados da compra</h5>
                    </div>
                  </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-6 pt-1 col-form-label form-control-label">
                  Data de compra
                </label>
                <div class="col-md-6">
                  <base-input input-group-classes="input-group-sm">
                    <el-date-picker
                      size="mini"
                      v-model="vehicle.buy_date_buy_sale"
                      type="date"
                      placeholder="Data Compra"
                      format="dd/MM"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label">
                  Valor da compra
                </label>
                <div class="col-md-6">
                  <base-input input-group-classes="input-group-sm">
                    <input inputmode="numeric" v-model="vehicle.buy_price" class="form-control form-control-sm"
                           v-mask="['######.##']"/>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label">
                  Adquirido de (nome)
                </label>
                <div class="col-md-6">
                  <base-input input-group-classes="input-group-sm">
                    <input type="text" v-model="vehicle.buy_acquired_name" class="form-control form-control-sm"/>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label">
                  Adquirido de (CPF/CNPJ)
                </label>
                <div class="col-md-6">
                  <base-input input-group-classes="input-group-sm">
                    <input inputmode="numeric" v-model="vehicle.buy_acquired_document" class="form-control form-control-sm"
                           v-mask="['###.###.###-##', '##.###.###/####-##']"/>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label">
                  Horímetro inicial do veículo
                </label>
                <div class="col-md-6">
                  <base-input input-group-classes="input-group-sm">
                    <input type="text" v-model="vehicle.buy_hour_meter_vehicle" class="form-control form-control-sm"/>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label">
                  Km incial do veículo
                </label>
                <div class="col-md-6">
                  <base-input input-group-classes="input-group-sm">
                    <input type="text" v-model="vehicle.buy_km_vehicle" class="form-control form-control-sm"/>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label">
                  Garantia do veículo até
                </label>
                <div class="col-md-6">
                  <base-input input-group-classes="input-group-sm">
                    <input type="text" v-model="vehicle.buy_warranty_vehicle" class="form-control form-control-sm"/>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mt-3">
                <div class="col-md-12">
                  <base-input input-group-classes="input-group-sm">
                        <textarea type="text" v-model="vehicle.buy_observation" rows="4" class="form-control form-control-sm">
                        </textarea>
                  </base-input>
                </div>
              </div>

              <div class="row align-items-center mb-3 mt-3">
                  <div class="col-12">
                    <!-- Title -->
                    <div class="card-header border-header-danger-bottom p-2">
                      <h5 class="h4 mb-0" slot="title">Dados da venda</h5>
                    </div>
                  </div>
              </div>
                <div class="form-group row m-0 p-0">
                  <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label">
                    Data de venda
                  </label>
                  <div class="col-md-6">
                    <base-input input-group-classes="input-group-sm">
                      <el-date-picker
                        size="mini"
                        v-model="vehicle.sale_date_buy_sale"
                        type="date"
                        placeholder="Data Compra"
                        format="dd/MM"
                        value-format="yyyy-MM-dd"
                      >
                      </el-date-picker>
                    </base-input>
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label">
                    Valor da venda
                  </label>
                  <div class="col-md-6">
                    <base-input input-group-classes="input-group-sm">
                      <input inputmode="numeric" v-model="vehicle.sale_price" class="form-control form-control-sm"
                             v-mask="['######.##']"/>
                    </base-input>
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label">
                    Vendido para (nome)
                  </label>
                  <div class="col-md-6">
                    <base-input input-group-classes="input-group-sm">
                      <input type="text" v-model="vehicle.sale_acquired_name" class="form-control form-control-sm"/>
                    </base-input>
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label">
                    Vendido para (CPF/CNPJ)
                  </label>
                  <div class="col-md-6">
                    <base-input input-group-classes="input-group-sm">
                      <input type="text" v-model="vehicle.sale_acquired_document" class="form-control form-control-sm"
                             v-mask="['###.###.###-##', '##.###.###/####-##']"/>
                    </base-input>
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label">
                    Horímetro inicial do veículo
                  </label>
                  <div class="col-md-6">
                    <base-input input-group-classes="input-group-sm">
                      <input type="text" v-model="vehicle.sale_hour_meter_vehicle" class="form-control form-control-sm"/>
                    </base-input>
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label">
                    Km incial do veículo
                  </label>
                  <div class="col-md-6">
                    <base-input input-group-classes="input-group-sm">
                      <input type="text" v-model="vehicle.sale_km_vehicle" class="form-control form-control-sm"/>
                    </base-input>
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label">
                    Garantia do veículo até
                  </label>
                  <div class="col-md-6">
                    <base-input input-group-classes="input-group-sm">
                      <input type="text" v-model="vehicle.sale_warranty_vehicle" class="form-control form-control-sm"/>
                    </base-input>
                  </div>
                </div>

                <div class="form-group row m-0 p-0 mt-3">
                  <div class="col-md-12">
                    <base-input input-group-classes="input-group-sm">
                        <textarea type="text" v-model="vehicle.sale_observation" rows="4" class="form-control form-control-sm">
                        </textarea>
                    </base-input>
                  </div>
                </div>

            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import SkeletonPuzl from "@/components/SkeletonPuzl";
const {formatErrorValidation} = require("@/plugins")

export default {
  name: "ModalBuySell",
  components: {
    SkeletonPuzl
  },
  data() {
    return {
      modal: false,
      title: 'Dados de Compra/Venda',
      loadingSave: false,
      loading: true,
      vehicle: {
        vehicle_uuid: null,
        buy_date_buy_sale: '',
        buy_acquired_name: '',
        buy_acquired_document: '',
        buy_invoice: '',
        buy_hour_meter_vehicle: '',
        buy_km_vehicle: '',
        buy_warranty_vehicle: '',
        buy_observation: '',
        buy_transaction: 0,
        buy_status: 0,
        buy_price: '',
        sale_date_buy_sale:'',
        sale_acquired_name:'',
        sale_acquired_document: '',
        sale_invoice: '',
        sale_hour_meter_vehicle: '',
        sale_km_vehicle: '',
        sale_warranty_vehicle: '',
        sale_observation: '',
        sale_transaction: 1,
        sale_status: 0,
        sale_price: '',
      },
      loadingStore: false,
      dispatch: null,
    };
  },
  methods: {
    closeModal () {
      this.modal = false
    },
    openModal (uuid, dispatch) {
      this.dispatch = dispatch.toString()
      this.fetch(uuid)
      this.modal = true
    },
    fetch(uuid){
      this.$Progress.start()
      this.loading = true
      this.$store.dispatch(this.dispatch+'/show', uuid).then(({data}) => {
          this.vehicle= {
            vehicle_uuid: data.uuid || null,
            buy_date_buy_sale: data.buy_sale.buy ? data.buy_sale.buy[0].date_buy_sale : '',
            buy_acquired_name: data.buy_sale.buy ? data.buy_sale.buy[0].acquired_name : '',
            buy_acquired_document: data.buy_sale.buy ? data.buy_sale.buy[0].acquired_document : '',
            buy_invoice: data.buy_sale.buy ? data.buy_sale.buy[0].invoice : '',
            buy_hour_meter_vehicle: data.buy_sale.buy ? data.buy_sale.buy[0].hour_meter_vehicle : '',
            buy_km_vehicle: data.buy_sale.buy ? data.buy_sale.buy[0].km_vehicle : '',
            buy_warranty_vehicle: data.buy_sale.buy ? data.buy_sale.buy[0].warranty_vehicle : '',
            buy_observation: data.buy_sale.buy ? data.buy_sale.buy[0].observation : '',
            buy_transaction: 0,
            buy_status: 0,
            buy_price: data.buy_sale.buy ? data.buy_sale.buy[0].price : '',
            sale_date_buy_sale: data.buy_sale.sale ? data.buy_sale.sale[0].date_buy_sale : '',
            sale_acquired_name: data.buy_sale.sale ? data.buy_sale.sale[0].acquired_name : '',
            sale_acquired_document: data.buy_sale.sale ? data.buy_sale.sale[0].acquired_document : '',
            sale_invoice: data.buy_sale.sale ? data.buy_sale.sale[0].invoice : '',
            sale_hour_meter_vehicle: data.buy_sale.sale ? data.buy_sale.sale[0].hour_meter_vehicle : '',
            sale_km_vehicle: data.buy_sale.sale ? data.buy_sale.sale[0].km_vehicle : '',
            sale_warranty_vehicle: data.buy_sale.sale ? data.buy_sale.sale[0].warranty_vehicle : '',
            sale_observation: data.buy_sale.sale ? data.buy_sale.sale[0].observation : '',
            sale_transaction: 1,
            sale_status: 0,
            sale_price: data.buy_sale.sale ? data.buy_sale.sale[0].price : '',
          }
        this.$Progress.finish()
        this.loading = false
      })
    },
    store() {
      this.$Progress.start()
      this.loadingStore = true
      this.$store.dispatch(this.dispatch+"/buySell", this.vehicle).then(response => {
          this.loadingStore = false
          this.$Progress.finish()
          this.$notify({
            type: response.error_type,
            message: response.message
          });
        this.modal = false
        }).catch(error => {
          if (error.status) {
            this.$Progress.finish()
            this.loadingStore = false
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            })
          } else {
            if (error.response.status === 422) {
              let message = formatErrorValidation(error.response.data.errors)
              this.$notify({
                type: 'danger',
                message: message
              })
              this.$Progress.finish()
              this.loadingStore = false
            } else {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              })
              this.$Progress.finish()
              this.loadingStore = false
            }
          }
        });
    },
  },
}
</script>
