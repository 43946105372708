<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <div class="row pl-2 mt-3 mb-4">
          <div class="col ml-4">
            <div class="row">
              <div class="col-md-12">
                  <div class="row d-flex align-items-center">
                    <div class="col-1">
                      <img src="/img/icons/info-squared.png" width="25px" height="25px">
                    </div>
                    <div class="col-md-5">
                      <h4 class="m-0">{{ toggleComponent == "QrcodeModal" ? "INFO PIX" : "DADOS DE EMISSÃO" }}</h4>
                    </div>
                    <hr class="bg-dark flex-grow-1 mr-5">
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row p-4 pt-0 mt-3" style="justify-content: center; padding-top: 0px !important">
          <div class="row p-0 col-md-11" style="flex-direction: row; justify-content: space-between;padding-top:0px">
            <div class="col p-0 mr-2">
              <div class="card m-0" style="min-height: 100%;text-wrap: nowrap;text-overflow: ellipsis;">
                <div class="card-body shadow-lg" style="text-wrap: balance;">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-1">
                          <img src="/img/icons/contract-job.png" width="25px" height="25px">
                        </div>
                        <div class="col pl-4">
                          <h4 class="m-0" style="font-size: 16px !important">CLIENTE</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div clas="row mt-2">
                    <div class="col-md-12 p-0 mt-2">
                      <span class="small" style="font-size: 13px !important">{{ bill.entities.name }}</span><br/>
                      <span class="small" style="font-size: 13px !important">{{ bill.entities.cpfCnpj }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col p-0">
              <div class="card m-0" style="min-height: 100%;text-wrap: nowrap;">
                <div class="card-body shadow-lg" style="text-wrap: balance;">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-1">
                          <img src="/img/icons/ok--v1.png" width="25px" height="25px">
                        </div>
                        <div class="col pl-4">
                          <h4 class="m-0" style="font-size: 16px !important">BENEFICIÁRIO</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div clas="row mt-2">
                    <div class="col-md-12 p-0 mt-2">
                      <span class="small" style="font-size: 13px !important">{{ bill.issuer.name }}</span><br/>
                      <span class="small" style="font-size: 13px !important">{{ bill.issuer.ein }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="col-md-11 p-0">
            <!-- aqui -->
            <template>
              <component :is="getComponent" :bill="bill"></component>
            </template>
          </div>
          <div class="col-md-12">
            <div class="row d-flex justify-content-end">
              <div class="col-md-5">
                <base-button v-if="!isQrcodeGenerated"
                  :block="true" type="primary"
                  @click="makeCharge()"
                  :disabled="!isApiAccountHealthy"
                  :loading="isLoading">
                  <img src="/img/icons/ok--v2.png" width="20px" height="20px">
                  CRIAR QR CODE
                </base-button>
              </div>
              <div class="col-md-5" v-if="isQrcodeGenerated">
                <!-- <base-button :block="true" @click="downloadPdf()" type="success">IMPRIMIR</base-button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>

import {
  VMoney
} from "v-money";

import { mapGetters } from "vuex";

import InfoModalQRcode from './_InfoModalQRcode.vue'
import QrcodeModal from './_QrcodeModal.vue'

export default {
  name: "ModalPixQRcode",
  components: {
    InfoModalQRcode,
    QrcodeModal
  },
  data() {
    return {
      modal: {
        title: 'GERAR QR CODE',
        create: false,
      },
      toggleComponent: false,
      bill: {
        id: '',
        key: '',
        key_type: '',
        banco: '',
        agencia: '',
        conta: '',
        valor: '',
        expireAt: '',
        erp_account_bank_id: '',
        entities: {
          name: '',
          cpfCnpj: ''
        },
        issuer: {
          name: '',
          ein: ''
        },
        qrcode: null,
        copy_and_paste: null,
      },
      isLoading: false,
      loadingStore: false,

    }
  },
  mounted() {

  },
  directives: {
    money: VMoney
  },
  computed: {
    getComponent() {
      return this.toggleComponent ? 'QrcodeModal' : 'InfoModalQRcode';
      // return 'QrcodeModal';
    },
    isQrcodeGenerated() {
      return this.toggleComponent;
    },
    isApiAccountHealthy() {
      return (this.bill.key !== null && this.bill.client_id !== null && this.bill.client_secret !== null)
    }
  },
  methods: {
    closeModal() {
      this.modal.create = false
    },
    resetData(){
      this.bill.client_id = null;
      this.bill.client_secret = null;
      this.bill.key = null;
      this.bill.key_type = null;
      this.bill.banco = null;
      this.bill.agencia = null;
      this.bill.conta = null;
      this.bill.value = null;
      this.bill.expireAt = null;
      this.bill.entities.name = null;
      this.bill.entities.cpfCnpj = null;
      this.bill.issuer.name = null;
      this.bill.issuer.ein = null;
      this.bill.id = null;
      this.bill.qrcode = null;
      this.bill.erp_account_bank_id = null;
    },
    openModal(billReceive) {
      this.resetData();
      let loader = this.$loading.show()

      this.bill.client_id = billReceive.client_id;
      this.bill.client_secret = billReceive.client_secret;

      this.bill.key = billReceive.pix_key;
      this.bill.key_type = billReceive.key_type;
      this.bill.banco = billReceive.bank_account_name;
      this.bill.agencia = billReceive.digit_agency;
      this.bill.conta = billReceive.digit_account;
      this.bill.value = billReceive.total_value;
      this.bill.expireAt = billReceive.competence;

      this.bill.entities.name = billReceive.entity_name;
      this.bill.entities.cpfCnpj = billReceive.document;

      this.bill.issuer.name = billReceive.issuer_name;
      this.bill.issuer.ein = billReceive.issuer_ein;

      this.bill.id = billReceive.installments[0].id

      this.bill.due_date = billReceive.installments[0].due_date;

      if(billReceive.installments[0].pix !== null) {
        this.bill.qrcode = billReceive.installments[0].pix.qrcode;
        this.bill.copy_and_paste = billReceive.installments[0].pix.copy_and_paste;
      }

      this.bill.erp_account_bank_id = billReceive.uuid_pix;

      this.modal.create = true;

      loader.hide()

      this.toggleComponent = this.bill.qrcode !== null;
    },
    makeCharge() {

      this.$Progress.start();
      this.isLoading = true;

      let payload = {
        expire_at: this.bill.expireAt,
        document: this.bill.entities.cpfCnpj,
        name: this.bill.entities.name,
        value: this.bill.value,
        entity_bill_receive_installment_id: this.bill.id,
        erp_account_bank_id: this.bill.erp_account_bank_id,
        key_type: this.bill.key_type
      }

      this.$store.dispatch('billReceive/generateQrcodePix', payload)
        .then(response => {
          if (response.data.qrcode) {
            this.bill.qrcode = response.data.qrcode;
            this.bill.copy_and_paste = response.data.pixCopiaECola
            this.toggleComponent = 'QrcodeModal';
            this.$emit("updated");
            this.$Progress.finish()
            this.isLoading = false
          }
        })
        .catch((error) => {
          this.isLoading = false
          this.$Progress.finish()
          this.$notify({
            type: "danger",
            message: error.data.message,
          });
        });
    },
    downloadPdf() {
      this.$store.dispatch('billReceive/downloadPdfPix', {})
        .then(async (response) => {
          let blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "Cobrança PIX.pdf");
          await link.click();
          this.$notify({
            type: "success",
            message: "Solicitação realizada com sucesso!",
          });
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: "Erro ao realizar o download do arquivo pdf!",
          });
        })
    }
  },
}
</script>

<style scoped></style>
