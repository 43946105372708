<template>
  <div class="d-flex w-100 custom-input-time-picker">
    <i class="fa fa-clock m-0 text-light vc-custom-clock-icon align-middle pt-2 pl-2 ml-1" />
    <v-date-picker
      :first-day-of-week="0"
      :minute-increment="spaceMinutes"
      :disabled="disabled"
      is24hr
      locale="pt-BR"
      mode="time"
      :value="currentValue"
      @input="handleInput"
      timezone="America/Buenos_Aires"
      ref="originDatePicker"
    >
      <template v-slot="{ inputValue, inputEvents }">
        <input
          :disabled="disabled"
          :style="customStyle"
          class="border rounded focus:outline-none focus:border-blue-300 vc-custom-input-datetime"
          :value="inputValue"
          v-on="inputEvents"
        />
        <i
          v-show="!disabled"
          role="button"
          @click.prevent="clearInput"
          style="margin-left: -1rem !important; position: absolute !important"
          class="fas fa-times m-0 text-light vc-custom-clock-icon align-middle pt-2 hoverable"
        />
      </template>
    </v-date-picker>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "InputTimePicker",
  methods: {
    handleInput(e) {
      this.$emit("input", e ? moment(e).format(this.format) : null);
    },
    clearInput() {
      this.handleInput(null);
    },
  },
  computed: {
    currentValue() {
      if (this.value) {
        let date = new Date();
        date.setHours(this.value.substring(0, 2), this.value.substring(3, 5));
        return date;
      }
      return null;
    },
  },
  props: {
    customStyle: {
      type: String,
      default: () => "",
      description: "Propriedades css adicionais",
    },
    format: {
      type: [String],
      default: () => "HH:mm",
      description: "Formatação de tempo",
    },
    value: {
      type: [String, Date],
      default: () => null,
      description: "Valor",
    },
    disabled: {
      type: Boolean,
      default: () => false,
      description: "Desabilitar input",
    },
    spaceMinutes: {
      type: Number,
      default: () => 1,
      description: "Espaçamento de minutos",
    },
  },
};
</script>

<style>
.custom-input-time-picker .vc-date {
  display: none !important;
}
.vc-custom-input-datetime {
  color: #606266;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
  width: 100%;
  padding-left: 1.8rem !important;
}

.vc-custom-clock-icon {
  font-size: 12px;
  position: absolute;
}
</style>
