<template>
  <div>
    <modal size="md" :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ title }}</h5>
      </template>
        <div class="form-group row m-0 p-0 mb-1 mt-3">
          <div class="col-md-12">
            <InputDatePicker
              ref="input-date-picker"
              @handleFilterDate="handleFilterDate"
              :default-range="defaultRange"
            />
          </div>
        </div>

        <div class="form-group row m-0 p-0 mb-1 mt-4">
          <label class="col-md-2 form-control-label mt-1"> Central </label>
          <div class="col-md-10">
            <PuzlSelect
              v-model="selectedCompanyPlants"
              :items="[{id: 'all', name: 'Todos'}, ...$_plants]"
              customKey="id"
              :multiple="true"
              :loading="loadingCompanyPlants"
              :disabled="loadingCompanyPlants"
              @input="setAll"
            />
          </div>
        </div>

        <div class="form-group row m-0 p-0 mb-1 mt-3">
          <label class="col-md-2 form-control-label mt-1"> Status </label>
          <div class="col-md-10">
            <PuzlSelect
              :items="installmentStatusEnum"
              v-model="selectedStatus"
              :multiple="true"
              placeholder="Status"
            />
          </div>
        </div>

      <div class="col-md-12 mt-2 text-right pl-4 mt-4">
        <base-button
          @click.prevent="close"
          type="secondary"
          class="text-capitalize"
        >
          Cancelar
        </base-button>
        <base-button
          @click.prevent="submitAndClose"
          type="success"
          native-type="submit"
          :disabled="selectedStatus.length === 0"
          :loading="isLoading"
        >
          <span v-if="!isLoading" class="btn-label"><i class="fa-solid fa-file-excel"></i></span>
          Gerar
        </base-button>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InputDatePicker from "@/components/InputDatePicker";
import PuzlSelect from "@/components/PuzlSelect";

const installmentStatusEnum = Object.freeze({
  PENDING: {
    id: 0,
    name: 'Pendente',
  },
  RECEIVED: {
    id: 1,
    name: 'Recebido',
  },
  LATE: {
    id: 2,
    name: 'Atrasado',
  },
  CANCELED: {
    id: 3,
    name: 'Cancelado',
  },
  OTHERS: {
    id: 10,
    name: 'Outros'
  },
});

export default {
  name: "ModalBillReceiveExcelReport",
  components: {
    InputDatePicker,
    PuzlSelect,
  },
  data() {
    return {
      title: "Gerar Relatório Contas a Receber (Excel)",
      modal: false,
      filter: {
        range: null,
        company_plants: [],
      },
      range: {
        start: null,
        end: null,
      },
      defaultRange: {
        start: new Date( new Date().getFullYear(), new Date().getMonth(), 1),
        end: new Date( new Date().getFullYear(), new Date().getMonth() + 1, 0),
      },
      selectedCompanyPlants: [],
      isLoading: false,
      loadingCompanyPlants: true,
      selectedStatus: [],
      installmentStatusEnum: Object.values(installmentStatusEnum),
    };
  },
  computed: {
    ...mapGetters({
      $_plants: "plant/activeItems",
    }),
  },
  methods: {
    openModal() {
      this.loadData();
      this.modal = true;
      this.range = this.defaultRange;
      this.selectedCompanyPlants = [];
      // Status Padrão
      this.selectedStatus = [
        installmentStatusEnum.PENDING.id,
        installmentStatusEnum.RECEIVED.id,
        installmentStatusEnum.LATE.id,
      ];
    },
    close() {
      this.modal = false;
    },
    async submitAndClose() {
      // Filtro de dados
      this.filter.range = this.range;
      this.filter.company_plants = !this.selectedCompanyPlants.includes("all") ? this.selectedCompanyPlants : [];
      this.filter.status = this.selectedStatus ? this.selectedStatus : null;

      // Baixar Relatório em Excel
      let loader = this.$loading.show();
      this.isLoading = true;
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store
        .dispatch("billReceive/getBillReceiveExcelReport", { filter: this.filter })
        .then(async (response) => {
          let blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "Relatório de contas a receber.xlsx");
          await link.click();
          loader.hide();
          this.isLoading = false;
          this.close();
          this.$notify({
            type: "success",
            message: "Solicitação realizada com sucesso!",
          });
        })
        .catch((error) => {
          loader.hide();
          this.isLoading = false;
          this.$notify({
            type: "danger",
            message: "Erro ao realizar o download do arquivo excel!",
          });
        });
    },
    handleFilterDate(filter) {
      this.range = filter;
    },
    setAll() {
      const index = this.selectedCompanyPlants.findIndex((item) => item == 'all');
      if (this.selectedCompanyPlants.length > 1 && index === 0) {
        return this.selectedCompanyPlants.splice(0, 1)
      }
      if (this.selectedCompanyPlants.length > 1 && this.selectedCompanyPlants.includes("all")) {
        this.selectedCompanyPlants = []
        this.selectedCompanyPlants.push("all")
      }
    },
    loadData() {
      this.loadingCompanyPlants = true;
      this.$store.dispatch('plant/getPlantsSimplified').then(() => {
        this.loadingCompanyPlants = false;
      });
    },
  },
};
</script>

<style></style> 
