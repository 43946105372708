<template>
<div>
	<base-header class="bg-gray-content">
		<div class="row align-items-center py-4">
			<div class="col-lg-12 col-12">
				<PuzlBreadcrumb/>
			</div>
		</div>
		<div class="row mb-3">
			<div class="col-md-2 mb-2">
				<base-button 
					@click.prevent="handleCreateModal" 
					class="pb-3 pt-3 text-uppercase" 
					block size="lg" 
					type="success"
				>
					<i class="fas fa-plus"></i> novo
				</base-button>
			</div>
			<div class="col-md-2 mb-2">
				<base-button 
					class="pb-3 pt-3 text-uppercase" block size="lg" type="primary" 
					@click.prevent="handleCreateLimitAdjustment"
				>
					<i class="fas fa-arrows-alt-v"></i> limite
				</base-button>
			</div>
			<div class="col-md-2 mb-2">
				<base-button 
					class="pb-3 pt-3 text-uppercase" 
					block size="lg" type="orange" 
					@click.prevent="handleCreateCreateApplicationLevelGuideline"
				>
					<span class="text-white" style="font-size: 12px;">
						(i) Orientações
					</span>
				</base-button>
			</div>
			<div class="col-md-2 mb-2">
				<back-button/>
			</div>
		</div>
	</base-header>
	<ListPuzlAdjustLevelResponsibility v-bind:added="this.added" />
	<CreatePuzlAdjustLevelResponsibility @createdLevelResponsibilityAdjustments="handleCreateAdjustmentList" ref="modalCreate" />
	<ModalLimitSetting @createdLevelResponsibilityAdjustments="handleCreateAdjustmentList" ref="modalLimitSetting" />
	<ModalCreateApplicationLevelGuideline ref="modalCreateApplicationLevelGuideline" />
</div>
</template>

<script>
import ListPuzlAdjustLevelResponsibility from "./Shared/_List";
import CreatePuzlAdjustLevelResponsibility from "./Shared/_ModalCreate";
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import BackButton from "@/components/Utils/BackButton";
import ModalLimitSetting from './Shared/_ModalLimitSetting';
import ModalCreateApplicationLevelGuideline from "@/views/Modules/Technology/Adjustment/LevelResponsibility/ApplicationLevelGuideline/Shared/_ModalCreate";

export default {
	name: "IndexPuzlAdjustmentLevelResponsibility",
	components: {
		ListPuzlAdjustLevelResponsibility,
		CreatePuzlAdjustLevelResponsibility,
		PuzlBreadcrumb,
		BackButton,
		ModalLimitSetting,
		ModalCreateApplicationLevelGuideline,
	},
	data() {
		return {
			added: false,
		}
	},
	methods: {
		handleCreateModal() {
			this.$refs.modalCreate.handleCreateModal();
		},
		handleCreateLimitAdjustment() {
			this.$refs.modalLimitSetting.handleCreateModal();
		},
		handleCreateAdjustmentList(){
			this.added = !this.added;
		},
		handleCreateCreateApplicationLevelGuideline(){
			this.$refs.modalCreateApplicationLevelGuideline.handleCreateModal();
		},
	},
}
</script>

<style></style>
