const keys = Object.freeze({
  ENTITY: 'entity',
  PAYMENT_METHOD: 'payment_method',
  PAYMENT_METHOD_FOR_ENTITY_BILL_RECEIVE: 'payment_method_for_entity_bill_receive',
  PAYMENT_TERM: 'payment_term',
  ENTITY_BILL_RECEIVE: 'entity_bill_receive',
  COMPANY_PLANT_ISSUER : 'company_plant_issuer',
  COMPANY_PLANT_ISSUER_GROUP: 'company_plant_issuer_group',
  COMPANY_PLANT: 'company_plant',
  BANK: 'bank',
});

export const SapTypeEnum = {
  keys,
};
