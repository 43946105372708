import { sapIntegrationUrls } from ".";
import httpClient from "../../../../shared/libraries/httpClient";
import { handleUnexpectedStatusCode } from "../../../../shared/services";
import { HttpStatusEnum } from "../../../../shared/enums";

/**
 * @param {number} typeable_id
 * @param {string} type
 * @returns {Promise<string>}
 */
const execute = async (typeable_id, type) => {
  const res = await httpClient.get(`${sapIntegrationUrls.BASE}/external-id/${typeable_id}/${type}`);

  switch (res.status) {
    case HttpStatusEnum.HTTP_OK:
      return res.data.data;
    case HttpStatusEnum.HTTP_NOT_ACCEPTABLE:
      return null;
    default:
      handleUnexpectedStatusCode(res);
  }
};

export default { execute };
