<template>
  <div>
    <modal v-if="show" size="lg" @close="close" :show.sync="show">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">Visualização de arquivos</h5>
      </template>
      <div v-if="selected.ext != 'pdf'" class="text-center" style="overflow: auto;max-height: 90vh">
        <p>
          <img :src="selected.url">
        </p>
      </div>
      <div v-else class="text-center" style="overflow: auto;max-height: 90vh">
        <pdf
          :annotation="true"
           :src="selected.url" />
      </div>
    </modal>
  </div>
</template>

<script>
import pdf from 'pdfvuer';

export default {
  components: {
    pdf
  },
  props: {
    selected: {
        type: Object,
        default: () => ({}),
        description: "Dados da imagem selecionada"
    },
    show: {
        type: Boolean,
        default: true,
        description: "Abrir modal"
    },
  },
  methods: {
    close() {
      this.$parent.closeModalImgView()
    },
  }
}
</script>
