<template>
  <div>
    <modal @close="close" size="md" :show.sync="show">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">{{ title }}</h5>
      </template>
      <validation-observer
        v-slot="{ invalid }"
        ref="formValidator"
      >
      <div>
        <div v-show="$_bill_receive" class="container-fluid">
          <div class="row">
            <div class="col-md-12 mb-1">
              <label
                class="col-form-label form-control-label"
              >
                Data do crédito <span class="text-danger">&nbsp;*&nbsp;</span>
              </label>
              <validation-provider rules="required">
                <input-date-time-picker v-model="payload.paid_at" :disableMaxDate="new Date()" />
              </validation-provider>
            </div>
          </div>
          <div class="form-row">
            <label
              class="col-md-12 pt-2 pb-1 col-form-label form-control-label"
            >
              Conta bancária de crédito
              <span class="text-danger">&nbsp;*&nbsp;</span>
            </label>
            <div class="col-md-12">
              <validation-provider rules="required">
                <base-input input-classes="form-control-sm">
                  <puzl-select
                    :disabled="disabled_change_bank_account || $_bill_receive.has_paid"
                    v-model="payload.bank_account_id"
                    :items="$_bank_accounts"
                    :clearable="false"
                    :label="['bank_account', 'name']"
                    :recursiveKey="['bank_account', 'id']"
                    :labelMask="true"/>
                </base-input>
              </validation-provider>
            </div>
            <div class="col-md-12 ml-1 mt-2 border-3 border-dark border-left">
              <h2>
                <small>
                  {{ $_bill_receive.payment_method_name }}
                  <br>
                  {{ $_bill_receive.payment_term_name }}
                </small>
              </h2>
            </div>
            <div class="col-md-12 mt-2">
              <h3>
                <small>
                  Valor total: <span class="numeric font-weight-600 pl-1">{{
                    total_value | currency()
                  }}</span>
                </small>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center row m-3 pb-1 p-0">
        <div class="col-md-4">
          <base-button block type="secondary" @click="close()"> Fechar</base-button>
        </div>
        <div class="col-md-8 mt-3 mt-md-0">
          <base-button :disabled="invalid" :loading="isLoading" block type="success" @click="save()">
            Confirmar recebimento
          </base-button>
        </div>
      </div>
      </validation-observer>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
import InputDateTimePicker from "@/components/InputDateTimePicker";

export default {
  name: "ModalConfirmPayment",
  components: { PuzlSelect, InputDateTimePicker },
  props: {
    return_payment: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data() {
    return {
      title: 'Confirmação de recebimento',
      isLoading: false,
      loadingBankAccounts: false,
      disabled_change_bank_account: false,
      show: false,
      total_value: 0,
      payload: {
        id: null,
        cancel_billet: 0,
        bank_account_id: null,
        paid_at: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      $_bill_receive: "billReceive/show",
      $_bank_accounts: 'companyPlantBankAccount/getListBankAccounts',
    }),
  },
  methods: {
    close() {
      this.show = false
    },
    handleCreateModal(installment_id, index = null, total_value = 0, disabled_change_bank_account = false, cancel_billet = 0) {
      this.show = true
      this.index = index
      this.total_value = total_value
      this.payload.id = installment_id
      this.payload.cancel_billet = cancel_billet
      this.disabled_change_bank_account = disabled_change_bank_account
      this.payload.paid_at = null
      this.payload.bank_account_id = this.$_bill_receive.bank_account_id
    },
    save() {
      this.$Progress.start();
      this.isLoading = true
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.$store
        .dispatch("billReceive/changeInstallmentStatus", {
          id: this.payload.id,
          status: 1,
          type: this.$_bill_receive.type,
          bank_account_id: this.payload.bank_account_id,
          paid_at: this.payload.paid_at,
          return_payment: +this.return_payment,
          manual: 1,
          cancel_billet: this.payload.cancel_billet
        })
        .then(response => {
          this.isLoading = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.show = false
          this.$emit('updateInstallment', {
            data: response.data,
            index: this.index
          })
        })
        .catch(error => {
          this.$notify({
            type: error.data.error_type,
            message: error.data.message
          });
          this.isLoading = false;
          this.$Progress.finish();
        });
    }
  },
};
</script>

<style>
</style>
