<template>
  <div>
    <ListUser />
  </div>
</template>

<script>
import ListUser from "./Shared/_List";

export default {
  name: "IndexUser",
  components: {
    ListUser,
  },
};
</script>

<style scoped lang="scss">
h2 {
  font-size: 1.2rem;
  color: #606062;
  font-family: Fredoka;
}

.d-flex.justify-content-end {
  gap: 10px;
}

#btn-override {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;

  border-radius: 100px !important;
  font-family: Fredoka;
  font-weight: 400 !important;
  margin-right: 10px !important;
  min-height: 35px !important;
  width: 205px !important;
  padding: 7px 0;
}

.btn-wrapper,
#btn-override {
  @media screen and (max-width: 768px) {
    width: 100% !important;
  }
}
</style>
