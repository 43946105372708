<template>
  <div
    :style="listType === 'table' && 'display: grid;'"
  >
    <base-dropdown v-if="PossibleActionsDropdownConstructor.items.length">
      <div
        class="new-minicard-table"
        :class="miniCardTypes[`${PossibleActionsDropdownConstructor.bill_receive_status.id}`]"
        slot="title-container">
        <div>
          <img :src="statusIcon[`${PossibleActionsDropdownConstructor.bill_receive_status.id}`]">
          <h4>
            {{ PossibleActionsDropdownConstructor.bill_receive_status.name }}
          </h4>
          <img :src="dropdownColors[`${PossibleActionsDropdownConstructor.bill_receive_status.id}`]" width="12">
        </div>
      </div>
      <a
        class="dropdown-item"
        v-for="item in PossibleActionsDropdownConstructor.items"
        @click.prevent="$emit('requestChangeInstallmentStatus',
          item.id,
          index
        )"
      >
        {{
          item.name
        }}
      </a>
    </base-dropdown>
    <div v-else
      class="new-minicard-table"
      style="cursor: auto;"
      :class="miniCardTypes[`${PossibleActionsDropdownConstructor.bill_receive_status.id}`]"
      slot="title-container">
      <div>
        <img :src="statusIcon[`${PossibleActionsDropdownConstructor.bill_receive_status.id}`]">
        <h4>
          {{ PossibleActionsDropdownConstructor.bill_receive_status.name }}
        </h4>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "EntityPossibleActions",
  props: {
    listType: String,
    index: Number,
    PossibleActionsDropdownConstructor: {
      bill_receive_status: {
        type: Object,
        default: {
          id: null,
          name: "",
        },
      },
      items: {
        type: Array,
        default: [
          {
            id: 0,
            name: "",
          }
        ]
      }
    }
  },
  data(){
    return {
      miniCardTypes: {
        '0': "new-minicard-yellow",
        '1': "new-minicard-green",
        '2': "new-minicard-red",
        '3': "new-minicard-red",
        '4': "new-minicard-blue",
        '5': "new-minicard-blue",
        '6': "new-minicard-yellow",
        '7': "new-minicard-gray",
        '8': "new-minicard-blue",
        '9': "new-minicard-red",
      },
      dropdownColors: {
        '0': "/img/icons/expand-arrow--v2_warning.png",
        '1': "/img/icons/expand-arrow--v2_success.png",
        '2': "/img/icons/expand-arrow--v2_danger.png",
        '3': "/img/icons/expand-arrow--v2_danger.png",
        '4': "/img/icons/expand-arrow--v2_primary.png",
        '5': "/img/icons/expand-arrow--v2_primary.png",
        '6': "/img/icons/expand-arrow--v2_warning.png",
        '7': "/img/icons/expand-arrow--v2_default.png",
        '8': "/img/icons/expand-arrow--v2_primary.png",
        '9': "/img/icons/expand-arrow--v2_danger.png",
      },
      statusIcon: {
        '0': "/img/icons/icons8/ios/hourglass_warning.png",
        '1': "/img/icons/icons8/ios/receive-dollar_success.png",
        '2': "/img/icons/icons8/ios/clock-danger.png",
        '3': "/img/icons/icons8/ios/cancel_danger.png",
        '4': "/img/icons/expand-arrow--v2_primary.png",
        '5': "/img/icons/icons8/ios/sheriff_primary.png",
        '6': "/img/icons/icons8/ios/strike_warning.png",
        '7': "/img/icons/icons8/ios/strike_warning.png",
        '8': "/img/icons/icons8/ios/thumb-up_primary.png",
        '9': "/img/icons/icons8/ios/thumbs-down_danger.png",
      },
    }
  },
  methods: {

  },
};
</script>
<style scoped>
.new-minicard-green {
  background-color: #F2F7F3;
  border-radius: 15px;
  padding: 5px 12px;
  font-size: 12px;
/*  box-shadow: 0 0 5px 0 #149e5752;*/
}

.new-minicard-red {
  background-color: #FCF3F3;
  border-radius: 15px;
  padding: 5px 12px;
/*  box-shadow: 0 0 5px 0 #d2272752;*/
}

.new-minicard-yellow {
  background-color: #FEF9F2;
  border-radius: 15px;
  padding: 5px 12px;
/*  box-shadow: 0 0 5px 0 #fdef0b52;*/
}

.new-minicard-blue {
  background-color: #F2F4F9;
  border-radius: 15px;
  padding: 5px 12px;
/*  box-shadow: 0 0 5px 0 #0b7bfd52;*/
}

.new-minicard-gray {
  background-color: #646e7d2e;
  border-radius: 15px;
  padding: 5px 12px;
/*  box-shadow: 0 0 5px 0 #5d636a52;*/
}

.new-minicard-green h4 {
  color: #149e57;
}

.new-minicard-red h4 {
  color: #db4539;
}

.new-minicard-yellow h4 {
  color: #f2b532;
}

.new-minicard-blue h4 {
  color: #1b6eba;
}

.new-minicard-gray h4 {
  color: #66696c;
}

.new-minicard-red, .new-minicard-green, .new-minicard-yellow, .new-minicard-blue, .new-minicard-gray {
  align-items: center;
  display: inline-flex;
  cursor: pointer;
  text-align: center;
}

.new-minicard-red img:first-child, .new-minicard-green img:first-child, .new-minicard-yellow img:first-child, .new-minicard-blue img:first-child, .new-minicard-gray img:first-child {
  width: 16px;
}

.new-minicard-table {
  display: block;
}

.new-minicard-table div {
  display: inline-flex;
  align-items: center;
  margin-top: 1px;
}

.new-minicard-red h4, .new-minicard-green h4, .new-minicard-yellow h4, .new-minicard-blue h4, .new-minicard-gray h4 {
  font-size: 12px;
  margin: 0 6px 0 6px;
  font-weight: normal !important;
}
</style>
