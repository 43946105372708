<template>
<div>
  <modal :show.sync="modal.create">
    <template slot="header">
      <h5 class="modal-title">{{ modal.title }}</h5>
    </template>
    <div>
      <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
        <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
          <div>
            <div class="card-header border-header-default-bottom p-2 grid-col--fixed-left mb-2">
              <h5 class="mb-0" slot="title">Identificação</h5>
            </div>
            <div class="form-group row m-0">
              <label class="col-md-5 form-control-label">
                Nome
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      v-model="group_permission.name"
                      class="form-control "
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <base-button type="secondary" @click="closeModal('create')">
              Cancelar
            </base-button>
            <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
              Salvar
            </base-button>
          </div>
        </form>
      </validation-observer>
    </div>
  </modal>
</div>
</template>

<script>
export default {
  name: "Create",
  data () {
    return {
      modal: {
        title: 'Grupo de Permissão',
        create: false,
      },
      group_permission: {
        name: null,
      },
      loadingStore: false,
    }
  },
  computed: {
  },
  methods: {
    closeModal () {
      this.modal.create = false
    },
    handleCreateModal () {
      this.modal.create = true
    },
    store () {
      this.$Progress.start()
      this.loadingStore = true
      this.$store.dispatch('authGroupPermission/add', this.group_permission)
        .then(response => {
          this.loadingStore = false
          this.modal.create = false
          this.group_permission = {
            name: null,
          }
          this.$emit('changePermission', response.data.id)
          this.$Progress.finish()
          this.$notify({ type: response.error_type, message: response.message })
        })
        .catch(error =>{
          if(error.response.status===422){
            let errors = Object.values(error.response.data.errors)
            errors = errors.flat().toString().split(',').join("<br />")
            this.$notify({ type: 'danger', message: errors })
          }
          this.$Progress.finish()
          this.loadingStore = false
        })
    },
  }
}
</script>

<style scoped>

</style>
