<template>
  <div id="app">
    <div>
      <div class="container-fluid">
        <div class="mt-05">
          <MultiFilter
            :showSlotDates="false"
            :showSlotData="true"
            :showSlotBookmark="false"
            :disable-max-date="new Date()"
            :showSlotStatus="true"
            @clearFilter="clearFilter()"
            @fetch="handleFetch"
            @additionalFiltersToggled="additionalFiltersToggled"
            @handleFilterDate="handleFilterDate"
          >
            <template slot="data">
              <div class="row pr-3 mt-3 pl-3">
                <div class="col-12 text-left">
                  <label class="form-control-label"> Ciclo Total </label>
                  <div class="row">
                    <div class="col-6 text-left pr-1">
                      <input
                        placeholder="de"
                        v-model="lowerValueCycle"
                        @input="setValueRangeCycle"
                        class="form-control btn-first-child"
                      />
                    </div>
                    <div class="col-6 pl-1">
                      <input
                        placeholder="até"
                        v-model="higherValueCycle"
                        @input="setValueRangeCycle"
                        class="form-control btn-first-child"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pr-3 mt-3 pl-3">
                <div class="col-12 text-left">
                  <label class="form-control-label"> Ciclo do Concreto </label>
                  <div class="row">
                    <div class="col-6 text-left pr-1">
                      <input
                        placeholder="de"
                        v-model="lowerValueConcrete"
                        @input="setValueRangeConcrete"
                        class="form-control btn-first-child"
                      />
                    </div>
                    <div class="col-6 pl-1">
                      <input
                        placeholder="até"
                        v-model="higherValueConcrete"
                        @input="setValueRangeConcrete"
                        class="form-control btn-first-child"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pr-3 mt-3 pl-3">
                <div class="col-12 text-left">
                  <label class="form-control-label"> Carga </label>
                  <div class="row">
                    <div class="col-6 text-left pr-1">
                      <input
                        placeholder="de"
                        v-model="lowerValueCharge"
                        @input="setValueRangeCharge"
                        class="form-control btn-first-child"
                      />
                    </div>
                    <div class="col-6 pl-1">
                      <input
                        placeholder="até"
                        v-model="higherValueCharge"
                        @input="setValueRangeCharge"
                        class="form-control btn-first-child"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pr-3 mt-3 pl-3">
                <div class="col-12 text-left">
                  <label class="form-control-label"> Espera p/ Descarga </label>
                  <div class="row">
                    <div class="col-6 text-left pr-1">
                      <input
                        placeholder="de"
                        v-model="lowerValueWaitStartDischange"
                        @input="setValueRangeWaitStartDischange"
                        class="form-control btn-first-child"
                      />
                    </div>
                    <div class="col-6 pl-1">
                      <input
                        placeholder="até"
                        v-model="higherValueWaitStartDischange"
                        @input="setValueRangeWaitStartDischange"
                        class="form-control btn-first-child"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pr-3 mt-3 pl-3">
                <div class="col-12 text-left">
                  <label class="form-control-label"> Trajeto (ida) </label>
                  <div class="row">
                    <div class="col-6 text-left pr-1">
                      <input
                        placeholder="de"
                        v-model="lowerValueSendRouteTime"
                        @input="setValueRangeSendRouteTime"
                        class="form-control btn-first-child"
                      />
                    </div>
                    <div class="col-6 pl-1">
                      <input
                        placeholder="até"
                        v-model="higherValueSendRouteTime"
                        @input="setValueRangeSendRouteTime"
                        class="form-control btn-first-child"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pr-3 mt-3 pl-3">
                <div class="col-12 text-left">
                  <label class="form-control-label"> Trajeto (retorno) </label>
                  <div class="row">
                    <div class="col-6 text-left pr-1">
                      <input
                        placeholder="de"
                        v-model="lowerValueReturnRouteTime"
                        @input="setValueRangeReturnRouteTime"
                        class="form-control btn-first-child"
                      />
                    </div>
                    <div class="col-6 pl-1">
                      <input
                        placeholder="até"
                        v-model="higherValueReturnRouteTime"
                        @input="setValueRangeReturnRouteTime"
                        class="form-control btn-first-child"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pr-3 mt-3 pl-3">
                <div class="col-12 text-left">
                  <label class="form-control-label"> Lastro </label>
                  <div class="row">
                    <div class="col-6 text-left pr-1">
                      <input
                        placeholder="de"
                        v-model="lowerValueBallast"
                        @input="setValueRangeBallast"
                        class="form-control btn-first-child"
                      />
                    </div>
                    <div class="col-6 pl-1">
                      <input
                        placeholder="até"
                        v-model="higherValueBallast"
                        @input="setValueRangeBallast"
                        class="form-control btn-first-child"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template slot="status">
              <div class="col-12 mt-1">
                <BaseButtonHoverable
                  size="sm"
                  type="primary"
                  @click="handleCompletedButton()"
                  icon="double-tick"
                  platform="ios"
                >
                  Concluído
                </BaseButtonHoverable>
              </div>
              <div class="col-12 mt-1 mb-4">
                <BaseButtonHoverable
                  size="sm"
                  type="danger"
                  @click="handleCanceledButton()"
                  icon="cancel-2"
                  platform="ios"
                  alt="cancel-2"
                >
                  Canceladas
                </BaseButtonHoverable>
              </div>
            </template>
          </MultiFilter>
        </div>
        <!-- Widgets -->
        <Widgets :widgets="widgets" :loadingWidgets="loadingWidgets" />
        <!-- Tabela -->
        <div class="pt-0">
          <Table 
            :loadingSkeleton="loadingSkeleton" 
            @handleTravelCycle="handleTravelCycle"
          />
          <div class="mt-n2" style="margin-top: 5px">
            <PuzlEmptyData v-if="!$_horarys.length && !loadingSkeleton" />
          </div>
        </div>
      </div>
    </div>
    <LoadingPagination :show="loading && !loadingSkeleton" />
    <ModalTravelCycle @updatedCycle="init({})" ref="travelCycle" />
  </div>
</template>

<script>
import MultiFilter from "@/components/Utils/MultiFilterV3";
import InputDatePicker from "@/components/InputDatePicker";
import { mapGetters } from "vuex";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import PuzlSelect from "@/components/PuzlSelect";
import { floatToHuman } from "@/helpers";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import ModalTravelCycle from "@/views/Modules/Operational/Schedule/Weighing/Shared/ScheduleTravelCycle/_ModalNewTravelCycle";
import Widgets from "./_Widgets";
import Table from "./_Table";

export default {
  name: "ListHoraryResume",
  mixins: [cursorPaginate],
  components: {
    PuzlEmptyData,
    SkeletonPuzl,
    InputDatePicker,
    PuzlSelect,
    LoadingPagination,
    MultiFilter,
    BaseButtonHoverable,
    ModalTravelCycle,
    Table,
    Widgets,
  },
  data() {
    return {
      floatToHuman: floatToHuman,
      loadingWidgets: false,
      loadingSkeleton: true,
      isAdditionalFiltersOpen: false,
      lowerValueCycle: null,
      higherValueCycle: null,
      lowerValueConcrete: null,
      higherValueConcrete: null,
      lowerValueCharge: null,
      higherValueCharge: null,
      lowerValueWaitStartDischange: null,
      higherValueWaitStartDischange: null,
      lowerValueSendRouteTime: null,
      higherValueSendRouteTime: null,
      lowerValueReturnRouteTime: null,
      higherValueReturnRouteTime: null,
      lowerValueBallast: null,
      higherValueBallast: null,
      widgets: {},
      range: {
        start: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        ),
        end: new Date(),
      },
      filter: {
        range: null,
        status: null,
        totalCycle: {
          start: null,
          end: null,
        },
        concreteCycle: {
          start: null,
          end: null,
        },
        loadTruckDiff: {
          start: null,
          end: null,
        },
        waitStartDischarge: {
          start: null,
          end: null,
        },
        sendRouteTime: {
          start: null,
          end: null,
        },
        returnRouteTime: {
          start: null,
          end: null,
        },
        ballast: {
          start: null,
          end: null,
        },
      },
    };
  },
  mounted() {
    this.getWidgets(this.filter);
    this.init(this.filter);
  },
  computed: {
    ...mapGetters({
      $_horarys: "horaryResume/fetch",
    }),
  },
  methods: {
    getWidgets(filter = null) {
      this.loadingWidgets = true;
      this.filter.range = this.range;
      this.$store
        .dispatch("horaryResume/getWidgets", { filter: this.filter })
        .then((response) => {
          if (Object.keys(response.data).length !== 0) {
            this.widgets = response.data;
          } else {
            this.widgets = {};
          }
          this.loadingWidgets = false;
        })
        .catch((error) => {
          this.widgets = {};
        });
    },
    setDefaultParams() {
      this.filter.range = this.range;
    },
    init(filter = null) {
      this.startCursor(filter);
      this.filter.range = this.range;
      this.$store
        .dispatch("horaryResume/fetchHorarysItemsPaginate", {
          filter: this.filter,
          next_page: this.paginate.nextUrl,
        })
        .then((response) => {
          this.resolveCursor(response);
        })
        .catch(() => {
          this.resolveCursor();
        });
    },
    handleFilterDate(filter) {
      this.range = filter;
      this.setDefaultParams();
      this.getWidgets(filter);
      this.init(filter);
    },
    setValueRangeCycle() {
      this.filter.totalCycle.start = this.lowerValueCycle;
      this.filter.totalCycle.end = this.higherValueCycle;
      this.init(this.filter);
      this.getWidgets(this.filter);
    },
    setValueRangeConcrete() {
      this.filter.concreteCycle.start = this.lowerValueConcrete;
      this.filter.concreteCycle.end = this.higherValueConcrete;
      this.init({});
      this.getWidgets(this.filter);
    },
    setValueRangeCharge() {
      this.filter.loadTruckDiff.start = this.lowerValueCharge;
      this.filter.loadTruckDiff.end = this.higherValueCharge;
      this.init({});
      this.getWidgets(this.filter);
    },
    setValueRangeWaitStartDischange() {
      this.filter.waitStartDischarge.start = this.lowerValueWaitStartDischange;
      this.filter.waitStartDischarge.end = this.higherValueWaitStartDischange;
      this.init({});
      this.getWidgets(this.filter);
    },
    setValueRangeSendRouteTime() {
      this.filter.sendRouteTime.start = this.lowerValueSendRouteTime;
      this.filter.sendRouteTime.end = this.higherValueSendRouteTime;
      this.init({});
      this.getWidgets(this.filter);
    },
    setValueRangeReturnRouteTime() {
      this.filter.returnRouteTime.start = this.lowerValueReturnRouteTime;
      this.filter.returnRouteTime.end = this.higherValueReturnlowerValueReturnRouteTime;
      this.init({});
      this.getWidgets(this.filter);
    },
    setValueRangeBallast() {
      this.filter.ballast.start = this.lowerValueBallast;
      this.filter.ballast.end = this.higherValueBalowerValueBallast;
      this.init({});
      this.getWidgets(this.filter);
    },
    handleCompletedButton() {
      this.filter.status = 1;
      this.init(this.filter);
      this.getWidgets(this.filter);
    },
    handleCanceledButton() {
      this.filter.status = 6;
      this.init(this.filter);
      this.getWidgets(this.filter);
    },
    clearFilter() {
      this.filter = {
        range: null,
        group_by_location: 0,
        order_by: null,
        totalCycle: {
          start: null,
          end: null,
        },
        concreteCycle: {
          start: null,
          end: null,
        },
        loadTruckDiff: {
          start: null,
          end: null,
        },
        waitStartDischarge: {
          start: null,
          end: null,
        },
        sendRouteTime: {
          start: null,
          end: null,
        },
        returnRouteTime: {
          start: null,
          end: null,
        },
        ballast: {
          start: null,
          end: null,
        },
      };
    },
    handleFetch(filter) {
      this.init(filter);
      this.getWidgets(filter);
    },
    additionalFiltersToggled(value) {
      this.isAdditionalFiltersOpen = value;
    },
    /**
     * Abre modal de edição de Ciclo de Viagem
     * @param {object} travel
     */
    handleTravelCycle(travel) {
      this.$refs.travelCycle.openModal(
        travel.uuid,
        travel.address_construction_uuid
      );
    },
  },
};
</script>
<style scoped>
.table-column-color {
  background-color: #cccccc;
}
</style>
