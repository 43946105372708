<template>
  <div>
    <modal :backgroundColor="'#e8e8e8'" :show.sync="isOpen">
      <template slot="header">
        <div class="d-flex align-items-center">
          <h5 class="text-title mb-0">Importação de xml</h5>
        </div>
      </template>
      <template slot="close-button">
        <img class="pointer mt-2" @click="closeModal" src="/img/icons/icons8/ios/close-window.png" alt="close-window" width="17px" />
      </template>

      <div class="mb-2 px-2 mx-1 mt-3">
        <div class="row">
          <label class="col-12 new-default-black-font mb-3 d-flex align-items-center"> Xml <span class="text-danger">&nbsp;*</span> </label>
          <div class="col-12">
            <InputFile
              :accept="'.xml'"
              v-model="attachmentData"
              :errorTextType="'Tipo de arquivo inválido. Apenas arquivo com extensão .xml permitido'"
              :multiple="false"
              ref="attachment"
            />
          </div>
        </div>

        <div class="row d-flex justify-content-end mt-5 mb-4 mr-1">
          <div @click="closeModal" class="d-flex align-items-center button-cancel col-auto px-4" style="gap: 5px">
            <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="16px" />
            <span style="font-weight: 500"> Cancelar </span>
          </div>
          <div @click="save" class="d-flex align-items-center button-save col-auto py-1 ml-3 px-4" style="gap: 5px">
            <img src="/img/icons/icons8/ios/save_success.png" alt="save_success" width="16px" />
            <span style="font-weight: 500"> Salvar </span>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script setup>
import { ref, computed, defineEmits } from "vue";
import { dialogs, progress } from "../../../../../helpers";

import InputFile from "../../../../../components/Utils/InputFile";
import entryLaunchStore from "../../store";

const isOpen = ref(false);
const attachmentData = ref({});

const emit = defineEmits(["onSave"]);

function openModal() {
  attachmentData.value = {};
  isOpen.value = true;
}

function save() {
  if (!attachmentData.value || (attachmentData.value && !attachmentData.value.file)) {
    return dialogs.notify("danger", "Anexo inválido, importação não disponível.");
  }

  const loader = progress.showLoader();
  const formData = new FormData();
  formData.append("attachment", attachmentData.value.file);

  entryLaunchStore
    .createByXmlAttachment(formData)
    .then(() => {
      dialogs.notify("success", "Xml importado com sucesso.");
      emit("onSave");
      closeModal();
    })
    .finally(() => {
      loader.hide();
    });
}

function closeModal() {
  isOpen.value = false;
}

defineExpose({
  openModal,
});
</script>
