<template>
  <div v-show="!loadingCompanyPlantIssuerTaxes && !loadingCommercialTaxes">
    <modal size="md"
      :show.sync="modal.create"
      :backgroundColor="'#E8E8E8'"
      :showClose="false"
    >
      <template>
        <template slot="header">
          <div class="d-flex align-items-center container ml-1 w-100" style="gap: 15px;">
            <div>
              <img src="/img/icons/ledger.png" alt="ledger" width="24px">
            </div>
            <span class="text-title">
              {{ modal.title }}
            </span>
            <div class="ml-auto">
              <img class="pointer" @click="closeModal" src="/img/icons/icons8/ios/close-window.png" alt="close-window" width="17px">
            </div>
          </div>
        </template>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12" style="display: flex; align-items: center;">
                  <img src="/img/icons/icons8/ios/info-squared.png" width="20">
                  <h4 class="m-0 mx-2" style="font-size: 16px; font-weight: 500; color: #2B2D32;">
                      EMISSOR
                  </h4>
                  <hr class="ml-3" style="width: 100%;">
                </div>
                <div class="col-12 mt-n1">
                  <span style="font-size: 14px; font-weight: 400; color: #2B2D32;"> {{ companyPlantIssuer.name }} </span>
                </div>
                <div class="col-12">
                  <span style="font-size: 12px; font-weight: 400; color: #2B2D32;"> {{ companyPlantIssuer.document }} </span>
                </div>
                <div class="col-12">
                  <span style="font-size: 12px; font-weight: 400; color: #606062;"> {{ companyPlantIssuer.tax_regime }} </span>
                </div>
                <div class="col-12 mt-2" style="display: flex; align-items: center;">
                  <img src="/img/icons/icons8/ios/administrative-tools_primary.png" width="20">
                  <h4 class="m-0 mx-2" style="font-size: 16px; font-weight: 500; color: #2B2D32;">
                      RETENÇÃO DE TRIBUTOS
                  </h4>
                  <hr class="float-right" style="width: 46%;">
                </div>
                <div class="col-12 mt-n1">
                  <span style="font-size: 14px; font-weight: 400; color: #2B2D32;">TRIBUTOS</span>
                </div>
              </div>
              <div class="form-group mt-3 mb-n1">
                <div class="form-group row m-0 p-0" v-for="(item, index) in commercialTaxes" :key="index">
                  <div class="col-md-5 my-1 px-0">
                    <div class="pb-0 mb-1 form-control-label" style="font-size: 12px; font-weight: 400; color:#606062">
                        {{ item.name }}
                    </div>
                  </div>
                  <div v-if="item.retention_status" class="col-md-7 mb-2 px-0">
                    <span class="d-flex float-right align-items-center status-button-success" @click="toggleRetentionStatus(item, false);">
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </span>
                  </div>
                  <div v-else class="col-md-7 mb-2 px-0">
                    <span class="d-flex float-right align-items-center status-button-danger" @click="toggleRetentionStatus(item, true);">
                      <img src="/img/icons/icons8/ios/shutdown--v1_danger.png" alt="shutdown--v1_danger" width="12px">
                      Inativo
                    </span>
                  </div>
                </div>
              </div>
              <div class="modal-footer px-0 mb-n2">
                <div class="d-flex pr-2" style="gap: 18px;">
                  <div class="d-flex align-items-center button-cancel" style="gap: 5px;" @click="closeModal">
                    <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="16px">
                    <span style="font-weight: 500;">
                      Cancelar
                    </span>
                  </div>
                  <div class="d-flex align-items-center button-save" style="gap: 5px;" @click.prevent="store">
                    <img src="/img/icons/icons8/ios/save_success.png" alt="save_success" width="16px">
                    <span style="font-weight: 500;">
                      Salvar
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: "ModalCreateRetentions",
  data() {
    return {
      modal: {
        title: "Retenções",
        create: false,
      },
      companyPlantIssuer: {
        id: 0,
        name: "",
        document: "",
        taxes: [],
        tax_regime: "",
      },
      commercialTaxes: {
        name: "",
        retention_status: false,
      },
      loadingCompanyPlantIssuerTaxes: true,
      loadingCommercialTaxes: true,
      loadingStore: false,
    };
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    handleCreateRetentionModal(companyPlantIssuers) {
      this.companyPlantIssuer.id = companyPlantIssuers.id;
      this.companyPlantIssuer.name = companyPlantIssuers.business_name;
      this.companyPlantIssuer.document = companyPlantIssuers.ein;
      this.companyPlantIssuer.tax_regime = companyPlantIssuers.tax_regime;
      this.loadCommercialTaxes();
      this.modal.create = true;
    },
    loadCommercialTaxes() {
      this.loadingCommercialTaxes = true;
      this.$Progress.start();
      this.$store
        .dispatch("FeesAndTaxes/fetchItems")
        .then((response) => {
          const taxes = ['ISSQN', 'COFINS', 'CSLL', 'INSS', 'IR', 'PIS']; 
          this.commercialTaxes = response.data.filter(item => taxes.includes(item.name) ? item.name : null);
          this.$Progress.finish();
          this.loadingCommercialTaxes = false;
          this.loadCompanyPlantIssuerTaxes();
        })
        .catch(() => {
          this.$Progress.finish();
          this.loadingCommercialTaxes = false;
        });
    },
    loadCompanyPlantIssuerTaxes() {
      this.loadingCompanyPlantIssuerTaxes = true;
      this.$Progress.start();
      this.$store
        .dispatch("companyPlantIssuer/getTaxes", this.companyPlantIssuer.id)
        .then((response) => {
          this.companyPlantIssuer.taxes = response.data;
          this.mergeTaxes();
          this.$Progress.finish();
          this.loadingCompanyPlantIssuerTaxes = false;
        })
        .catch(() => {
          this.$Progress.finish();
          this.loadingCompanyPlantIssuerTaxes = false;
        });
    },
    mergeTaxes() {
      this.companyPlantIssuer.taxes.map(function (item) {
        const index = this.commercialTaxes.findIndex((el) => el.id === item.commercial_tax_id);
        this.commercialTaxes[index].retention_status = true;
      }, this);
    },
    store() {
      let taxes = [];
      let companyPlantIssuerId = this.companyPlantIssuer.id;
      this.commercialTaxes.map(function (item) {
        if (item.retention_status) {
          taxes.push({ commercial_tax_id: item.id });
        }
      });

      this.$Progress.start();
      this.loadingStore = true;
      this.$store
        .dispatch("companyPlantIssuer/saveOrUpdateTaxes", { companyPlantIssuerId, taxes })
        .then((response) => {
          this.loadingStore = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.modal.create = false;
        })
        .catch(() => {
          this.$notify({
            type: "danger",
            message: message,
          });
          this.$Progress.finish();
          this.loadingStore = false;
        });
    },
    toggleRetentionStatus(item, status) {
      item.retention_status = status;
    }
  },
};
</script>

<style scoped>
  .status-button-success {
    background-color: #F2F7F3;
    border-radius: 16px;
    cursor: pointer;
    gap: 5px;
    font-size: 12px;
    color: #149E57;
    font-weight: 400;
    padding: 4px 40px;
  }
  .status-button-danger {
    background-color: #F4C7C3;
    border-radius: 16px;
    cursor: pointer;
    gap: 5px;
    font-size: 12px;
    color: #DB4539;
    font-weight: 400;
    padding: 4px 34px
  }
  .button-cancel {
    cursor: pointer;
    border: 0.5px solid #DB4539;
    border-radius: 8px;
    color: #DB4539;
    padding: 4px 20px;
  }
  .button-save {
    cursor: pointer;
    border: 0.5px solid #149E57;
    border-radius: 8px;
    color: #149E57;
    padding: 4px 30px;
  }
</style>
