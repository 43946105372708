import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import auth from "@/middleware/auth";

// Start Schedule
import OperationalSchedule from '@/views/Modules/Operational/Schedule/Index';
import OperationalScheduleCreate from '@/views/Modules/Operational/Schedule/Shared/_Create';
import OperationalScheduleEdit from '@/views/Modules/Operational/Schedule/Shared/_Edit';
import OperationalWeighing from '@/views/Modules/Operational/Schedule/Weighing/Index';
import OperationalCharging from '@/views/Modules/Operational/Schedule/Charging/Index';
import OperationalScheduleGantt from '@/views/Modules/Operational/Schedule/Gantt/Index';
import OperationalScheduleResume from '@/views/Modules/Operational/Schedule/Resume/Index';
import OperationalTravelsInCourse from '@/views/Modules/Operational/InCourse/Index';
import OperationalStock from '@/views/Modules/Operational/Stock/Index';
import OperationalStockBalance from '@/views/Modules/Operational/StockBalance/Index';
import ResumeStockIndex from '@/views/Modules/Operational/Stock/Resume/Index';
import EntryResumeStock from '@/views/Modules/Operational/Stock/EntryResume/Index';
import OperationalStockAdjustment from '@/views/Modules/Operational/Stock/Adjustment/Index';
import OperationalContractProposalSchedule from '@/views/Modules/Operational/Schedule/ContractProposalSchedule/Index';
import OperationalContractProposalScheduleCreate from '@/views/Modules/Operational/Schedule/ContractProposalSchedule/Shared/_Create';
import OperationalContractProposalScheduleEdit from '@/views/Modules/Operational/Schedule/ContractProposalSchedule/Shared/_Edit';
// End Schedule

// Start Resume Additional
import OperationalResumeAdditional from '@/views/Modules/Operational/ResumeAdditional/Index';
// End Resume Additional

// Start Dashboard
import OperationalDashboard from '@/views/Modules/Operational/Dashboard/Index';
import OperationalKanban from '@/views/Modules/Operational/Kanban/Index';
// End Dashboard
import DailyResume from '@/views/Modules/Operational/DailyResume/Index';
import ChargeResume from '@/views/Modules/Operational/ChargeResume/Index';
import StockMovement from '@/views/Modules/Operational/StockMovement/Index';

import StockCurrent from '@/views/Modules/Operational/StockCurrent/Index.vue'
import StockAnalyze from '@/views/Modules/Operational/StockAnalyze/Index.vue'

import ScheduleTravelGantt from '@/views/Modules/Operational/Schedule/ScheduleTravelGantt/Index.vue';

const operational = {
  path: '/operational',
  component: DashboardLayout,
  name: 'Operacional',
  meta: {
    permission: 'operational.dashboard.index.view',
    middleware: auth,
  },
  children: [
    {
      path: '/operational/schedule',
      name: 'operational.schedule.view',
      component: OperationalSchedule,
      meta: {
        title: 'Operational',
        description: 'Programações',
        icon: 'fa fa-home',
        permission: 'operational.schedule.index.view',
        global_filter : false,
        breadcrumbs: [
          {title: 'Operacional', name: 'operational.schedule.dashboard.index'},
          {title: 'Programações', name: null},
        ]
      },
    },
    {
      path: '/operational/schedule/create/:contract_proposal_uuid',
      name: 'operational.schedule.create',
      component: OperationalScheduleCreate,
      meta: {
        title: 'Operational',
        description: 'Programações',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Operacional', name: 'operational.schedule.dashboard.index'},
          {title: 'Nova Programação', name: null},
        ]
      },
    },
    {
      path: '/operational/schedule/weighing/:schedule_uuid',
      name: 'operational.schedule.weighing.index',
      component: OperationalWeighing,
      meta: {
        title: 'Operational',
        description: 'Viagens',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Operacional', name: 'operational.schedule.dashboard.index'},
          {title: 'Viagens', name: null},
        ]
      },
    },
    {
      path: '/operational/schedule/charging/:travel_uuid',
      name: 'operational.schedule.charging.index',
      component: OperationalCharging,
      meta: {
        title: 'Operational',
        description: 'Carregamento',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Operacional', name: 'operational.schedule.dashboard.index'},
          {title: 'Carregamento', name: null},
        ]
      },
    },
    {
      path: '/operational/schedule/edit/:schedule_uuid',
      name: 'operational.schedule.edit',
      component: OperationalScheduleEdit,
      meta: {
        title: 'Operational',
        description: 'Programações',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Operacional', name: 'operational.schedule.dashboard.index'},
          {title: 'Editar Programação', name: null},
        ]
      },
    },
    {
      path: '/operational/schedule/gantt',
      name: 'operational.schedule.gantt',
      component: OperationalScheduleGantt,
      meta: {
        title: 'Operational',
        description: 'Programações - Gantt',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Operacional', name: 'operational.schedule.dashboard.index'},
          {title: 'Programações - Gantt', name: null},
        ]
      },
    },
    {
      path: '/operational/schedule/resume',
      name: 'operational.schedule.resume',
      component: OperationalScheduleResume,
      meta: {
        title: 'Operational',
        description: 'Programações - Resumo',
        icon: 'fa fa-home',
        permission: 'operational.schedule-resume.index.view',
        breadcrumbs: [
          {title: 'Operacional', name: 'operational.schedule.dashboard.index'},
          {title: 'Programações - Resumo', name: null},
        ]
      },
    },
    {
      path: '/operational/dashboard',
      name: 'operational.dashboard',
      component: OperationalDashboard,
      meta: {
        title: 'Operational',
        description: 'Dashboard operacional',
        icon: 'fa fa-home',
        permission: 'operational.dashboard.index.view',
        breadcrumbs: [
          {title: 'Operacional', name: 'operational.dashboard.index'},
          {title: 'Dashboard', name: null},
        ]
      },
    },
    {
      path: '/operational/schedule/in_course',
      name: 'operational.in_course',
      component: OperationalTravelsInCourse,
      meta: {
        title: 'Operational',
        description: 'Em andamento',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Operacional', name: 'operational.dashboard.index'},
          {title: 'Programações', name: 'operational.schedule.view'},
          {title: 'Em andamento', name: null},
        ]
      },
    },
    {
      path: '/operational/stock',
      name: 'operational.stock.view',
      component: OperationalStock,
      meta: {
        title: 'Operational',
        description: 'Estoque MCC',
        global_filter: true,
        icon: 'fa fa-home',
        permission: 'stock.index.view',
        breadcrumbs: [
          {title: 'Operacional', name: 'operational.schedule.dashboard.index'},
          {title: 'Estoque MCC', name: null},
        ]
      },
    },
    {
      path: '/operational/stock-balance',
      name: 'operational.stock.balance.view',
      component: OperationalStockBalance,
      meta: {
        title: 'Operational',
        description: 'Saldos de estoque',
        icon: 'fa fa-home',
        permission: 'stock.stock-balance.index.view',
        breadcrumbs: [
          {title: 'Operacional', name: 'operational.dashboard.index'},
          {title: 'Saldos de estoque', name: null},
        ]
      },
    },
    {
      path: '/operational/stock/adjustment',
      name: 'operational.stock.adjustment.view',
      component: OperationalStockAdjustment,
      meta: {
        title: 'Operational',
        description: 'Ajustes de estoque',
        icon: 'fa fa-home',
        permission: 'stock.index.view',
        breadcrumbs: [
          {title: 'Operacional', name: 'operational.schedule.dashboard.index'},
          {title: 'Ajustes de entradas e saídas', name: null},
        ]
      },
    },
    {
      path: '/operational/stock/resume',
      name: 'operational.stock.resume.view',
      component: ResumeStockIndex,
      meta: {
        title: 'Operational',
        description: 'Resumo sintético de saídas',
        icon: 'fa fa-home',
        permission: 'stock.stock-resume.index.view',
        breadcrumbs: [
          {title: 'Operacional', name: 'operational.schedule.dashboard.index'},
          {title: 'Resumo sintético de saídas (kg)', name: null},
        ]
      },
    },
    {
      path: '/operational/contract-proposal/schedule/:contract_proposal_uuid',
      name: 'operational.contract_proposal_schedule.view',
      component: OperationalContractProposalSchedule,
      meta: {
        title: 'Operational',
        description: 'Programações',
        icon: 'fa fa-home',
        permission: 'commercial.contract-proposal.schedule.index.view',
        breadcrumbs: [
          {title: 'Operacional', name: 'operational.contract_proposal_schedule.dashboard.index'},
          {title: 'Programações', name: null},
        ]
      },
    },
    {
      path: '/operational/contract-proposal/schedule/create/:contract_proposal_uuid',
      name: 'operational.contract_proposal_schedule.create',
      component: OperationalContractProposalScheduleCreate,
      meta: {
        title: 'Operational',
        description: 'Programações',
        icon: 'fa fa-home',
        permission: 'commercial.contract-proposal.schedule.index.view',
        breadcrumbs: [
          {title: 'Operacional', name: 'operational.contract_proposal_schedule.dashboard.index'},
          {title: 'Nova Programação', name: null},
        ]
      },
    },
    {
      path: '/operational/contract-proposal/schedule/edit/:schedule_uuid',
      name: 'operational.contract_proposal_schedule.edit',
      component: OperationalContractProposalScheduleEdit,
      meta: {
        title: 'Operational',
        description: 'Programações',
        icon: 'fa fa-home',
        permission: 'commercial.contract-proposal.schedule.index.view',
        breadcrumbs: [
          {title: 'Operacional', name: 'operational.contract_proposal_schedule.dashboard.index'},
          {title: 'Editar Programação', name: null},
        ]
      },
    },
    {
      path: '/operational/resume-additional',
      name: 'operational/resume_additional',
      component: OperationalResumeAdditional,
      meta: {
        title: 'Operacional',
        description: 'Resumo de adicionais',
        icon: 'fa fa-home',
        permission: 'operational.resume-additional.index.view',
        global_filter: true,
        breadcrumbs: [
          {title: 'Operacional', name: ''},
          {title: 'Resumo de adicionais', name: null},
        ]
      },
    },
    {
      path: '/operational/kanban',
      name: 'operational/kanban',
      component: OperationalKanban,
      meta: {
        title: 'Operacional',
        description: 'Viagens',
        icon: 'fa fa-home',
        permission: 'operational.kanban.index.view',
        breadcrumbs: [
          {title: 'Operacional', name: ''},
          {title: 'Viagens', name: null},
        ]
      },
    },
    {
      path: '/operational/stock/entry-resume',
      name: 'operational.stock.entry-resume.view',
      component: EntryResumeStock,
      meta: {
        title: 'Operational',
        description: 'Resumo sintético de entradas',
        icon: 'fa fa-home',
        permission: 'stock.entry-resume.index.view',
        breadcrumbs: [
          {title: 'Operacional', name: 'operational.stock.entry-resume.dashboard.index'},
          {title: 'Resumo sintético de entradas', name: null},
        ]
      },
    },
    {
      path: '/operational/daily-resume',
      name: 'operational.daily-resume.index.view',
      component: DailyResume,
      meta: {
        title: 'Operational',
        description: 'Resumo diário',
        icon: 'fa fa-home',
        permission: 'operational.daily-resume.index.view',
        global_filter: true,
        breadcrumbs: [
          {title: 'Dashboard', name: 'operational.dashboard.index'},
          {title: 'Resumo diário', name: null},
        ]
      },
    },
    {
      path: '/operational/charge-resume',
      name: 'operational.charge-resume.index.view',
      component: ChargeResume,
      meta: {
        title: 'Operational',
        description: 'Resumo de cargas',
        icon: 'fa fa-home',
        permission: 'operational.charge-resume.index.view',
        breadcrumbs: [
          {title: 'Dashboard', name: 'operational.dashboard.index'},
          {title: 'Resumo de cargas', name: null},
        ]
      },
    },
    {
      path: '/operational/stock/stock-movements',
      name: 'operational.stock-movements.index.view',
      component: StockMovement,
      meta: {
        title: 'Operational',
        description: 'Movimentos de estoque',
        icon: 'fa fa-home',
        permission: 'stock.stock-movements.index.view',
        global_filter: true,
        breadcrumbs: [
          {title: 'Dashboard', name: 'operational.dashboard.index'},
          {title: 'Movimentos de estoque', name: null},
        ]
      },
    },
    {
      path: '/operational/stock/current',
      name: 'operational.current-stock.index.view',
      component: StockCurrent,
      meta: {
        title: 'Operational',
        description: 'Estoque atual',
        icon: 'fa fa-home',
        permission: 'stock.stock-current.index.view',
        global_filter: true,
        breadcrumbs: [
          {title: 'Dashboard', name: 'operational.dashboard.index'},
          {title: 'Estoque atual, name: null'},
        ]
      },
    },
    {
      path: '/operational/stock/analyze',
      name: 'operational.analyze-stock.index.view',
      component: StockAnalyze,
      meta: {
        title: 'Operational',
        description: 'Análise de estoque',
        icon: 'fa fa-home',
        permission: 'stock.stock-analyze.index.view',
        global_filter: true,
        breadcrumbs: [
          {title: 'Dashboard', name: 'operational.dashboard.index'},
          {title: 'Análise de estoque', name: null},
        ]
      },
    },
    {
      path: '/operational/schedule-travel-gantt',
      name: 'operational.schedule-travel-gantt.index.view',
      component: ScheduleTravelGantt,
      meta: {
        title: 'Operational',
        description: 'Gantt',
        icon: 'fa fa-home',
        permission: 'operational.schedule-travel-gantt.index.view',
        breadcrumbs: [
          {title: 'Dashboard', name: 'operational.dashboard.index'},
          {title: 'Gantt', name: null},
        ]
      },
    },
  ]
};

export default operational;
