<template>
  <div>
    <base-header class="bg-gray-content">
      <!-- Start Breadcrumb -->
      <PuzlBreadcrumb/>
      <!-- End Breadcrumb -->
      <!-- Header Menus -->
      <div class="row mb-3">
        <div class="col-md-2">
          <base-button slot="title-container" @click.prevent="$router.push({name: 'technology.test.index'})"
                       block type="light"
                       class="p-3 text-uppercase">
            <i class="fa-solid fa-circle-arrow-left"></i> Voltar
          </base-button>
        </div>
      </div>
    </base-header>
    <div class="container-fluid">
      <multi-filter-v2 :without_filter="true" @clearFilters="clearFilter" @fetch="init" :filter="filter">
        <div class="col-md-4 p-2">
          <input-date-picker @handleFilterDate="handleFilterDate" :filterable="4"/>
        </div>
        <div class="col-md-4 p-2" @mouseleave="hover_icon_with_hardened_state = 0"
             @mouseover="hover_icon_with_hardened_state = 1">
          <base-button :class="filter.with_hardened_state && 'active'"
                       @click="filter.with_hardened_state = Number(!filter.with_hardened_state);init({})" outline block
                       type="primary">
            <template v-if="filter.with_hardened_state">
              <img src="https://img.icons8.com/ios/100/FFFFFF/impossible-shapes.png" style="height: 20px;"> COM MOLDAGEM
            </template>
            <template v-if="!filter.with_hardened_state">
              <img
                :src="hover_icon_with_hardened_state ? 'https://img.icons8.com/ios/100/FFFFFF/impossible-shapes.png' : 'https://img.icons8.com/ios/100/1A70B7/impossible-shapes.png'"
                style="height: 20px;"> COM MOLDAGEM
            </template>
          </base-button>
        </div>
        <div class="col-md-4 p-2" @mouseleave="hover_icon_without_hardened_state = 0"
             @mouseover="hover_icon_without_hardened_state = 1">
          <base-button :class="filter.without_hardened_state && 'active'"
                       @click="filter.without_hardened_state = Number(!filter.without_hardened_state);init({})" outline
                       block type="light">
            <template v-if="filter.without_hardened_state">
              <img src="https://img.icons8.com/ios/100/FFFFFF/impossible-shapes.png" style="height: 20px;"> SEM MOLDAGEM
            </template>
            <template v-if="!filter.without_hardened_state">
              <img
                :src="hover_icon_without_hardened_state ? 'https://img.icons8.com/ios/100/FFFFFF/impossible-shapes.png' : 'https://img.icons8.com/ios/100/8b8787/impossible-shapes.png'"
                style="height: 20px;"> SEM MOLDAGEM
            </template>
          </base-button>
        </div>
      </multi-filter-v2>
    </div>
    <div class="container-fluid">
      <div class="row card-wrapper">
        <SkeletonPuzlGrid v-show="loadingSkeleton" v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <puzl-empty-data v-if="!reports.length && !loadingSkeleton"/>
      <div class="row card-wrapper" v-if="!loadingSkeleton && reports.length > 0">
        <!--        <div class="col-lg-4" v-for="(report, index) in reports" :key="index">-->
        <!--          <card :class="report.hardened_state_count > 0 ? 'card-border-top-primary' :-->
        <!--'card-border-top-light'">-->
        <!--            <div class="row align-items-center mb-3">-->
        <!--              <div class="col-8">-->
        <!--                <h5 class="h3 mb-0 text-uppercase">-->
        <!--                  {{ report.charge_point }}-->
        <!--                </h5>-->
        <!--                <span>{{ report.plant }}</span>-->
        <!--                <br>-->
        <!--              </div>-->
        <!--
                     <input style="display: none" v-model="report.volume"-->
        <!--                     v-mask="['#', '##', '#,#', '##,#', '###,#','#.###,#','##.###,#','###.###,#','#.###.###,#','##.###.###,#']"-->
        <!--              />-->
        <!--              <div class="col-12 text-center mt-4 mb-2">-->
        <!--                <span class="h1 text-primary"> {{ report.index }} </span> <span>({{-->
        <!--                  report.volume-->
        <!--                }} <span style="text-transform: none">m</span><sup>3</sup>)</span>-->
        <!--                <br>-->
        <!--                <span class="text-muted">{{ report.updated_at | parseDate }}</span>-->
        <!--                <br>-->
        <!--              </div>-->
        <!--              <div class="col-12 mt-4 border-3 border-left border-primary">-->
        <!--                <span class="h3">{{ report.schedule.construction }}</span>-->
        <!--                <br>-->
        <!--                <span>{{ report.schedule.customer }}</span>-->
        <!--              </div>-->
        <!--              <br>-->
        <!--              <div class="col-12 border-3 border-left mt-2">-->
        <!--                <span class="h3">{{ report.equipment.code }} ({{ report.equipment.plate }})</span>-->
        <!--                <br>-->
        <!--                <span>{{ report.driver }}</span>-->
        <!--              </div>-->

        <!--              <div class="col-12 mt-2">-->
        <!--                <span>Peça: {{ report.schedule.piece }}</span>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--            <collapse class="border rounded  mt-3">-->
        <!--              <collapse-item-->
        <!--                class="header-gray"-->
        <!--                back-ground="border-header-primary-bottom"-->
        <!--              >-->
        <!--                <h5 slot="title" class="mb-0 ">Formulação</h5>-->
        <!--                <div class="border-left border-3 p-2 border-primary mt-0 row">-->
        <!--                  <div-->
        <!--                    class="col-md-11"-->
        <!--                    v-if="report.contract_proposal_formulation"-->
        <!--                  >-->
        <!--                    <h5 class="h4 mb-0">-->
        <!--                      {{ report.contract_proposal_formulation.title }}-->
        <!--                    </h5>-->
        <!--                    <div class="mt-2 mb-3">-->
        <!--                      <h5 class="small">-->
        <!--                        {{ report.contract_proposal_formulation.body }}-->
        <!--                      </h5>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </collapse-item>-->
        <!--            </collapse>-->
        <!--            <collapse class="border rounded mt-3">-->
        <!--              <collapse-item-->
        <!--                class="header-gray"-->
        <!--                back-ground="border-header-primary-bottom"-->
        <!--              >-->
        <!--                <h5 slot="title" class="mb-0 ">Moldagem e Coleta</h5>-->
        <!--                <div class="row">-->
        <!--                  <div class="col-12">-->
        <!--                    <div class="form-group row m-0 p-0 mb-1">-->
        <!--                      <label-->
        <!--                        class="col-md-12 pb-0 pt-1 col-form-label form-control-label"-->
        <!--                      >-->
        <!--                        Moldador-->
        <!--                      </label>-->
        <!--                      <validation-provider rules="required">-->
        <!--                        <div class="col-md-12">-->
        <!--                          <validation-provider rules="required">-->
        <!--                            <base-input input-classes="form-control-sm">-->
        <!--                              <el-select-->
        <!--                                @change="teste(report)"-->
        <!--                                size="mini"-->
        <!--                                v-model="report.molder"-->
        <!--                                placeholder="Selecione"-->
        <!--                                filterable-->
        <!--                              >-->
        <!--                                <el-option label="Selecione" value=""></el-option>-->
        <!--                                <el-option-->
        <!--                                  v-for="user in users"-->
        <!--                                  :key="user.uuid"-->
        <!--                                  :label="user.name"-->
        <!--                                  :value="user.uuid"-->
        <!--                                >-->
        <!--                                </el-option>-->
        <!--                              </el-select>-->
        <!--                            </base-input>-->
        <!--                          </validation-provider>-->
        <!--                        </div>-->
        <!--                      </validation-provider>-->
        <!--                    </div>-->
        <!--                    <div class="form-group row m-0 p-0 mb-1">-->
        <!--                      <label-->
        <!--                        class="col-md-12 pb-0 pt-1 col-form-label form-control-label"-->
        <!--                      >-->
        <!--                        Coleta-->
        <!--                      </label>-->
        <!--                      <div class="col-md-12">-->
        <!--                        <validation-provider rules="required">-->
        <!--                          <base-input input-group-classes="input-group-sm">-->
        <!--                            <el-date-picker-->
        <!--                              @change="teste(report)"-->
        <!--                              v-model="report.collect"-->
        <!--                              size="mini"-->
        <!--                              type="datetime"-->
        <!--                              format="dd/MM/yyyy HH:mm"-->
        <!--                              value-format="yyyy-MM-dd HH:mm:ss"-->
        <!--                              block-->
        <!--                              :picker-options="pickerOptions"-->
        <!--                            >-->
        <!--                            </el-date-picker>-->
        <!--                          </base-input>-->
        <!--                        </validation-provider>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                    <div class="form-group row m-0 p-0 mb-1">-->
        <!--                      <label-->
        <!--                        class="col-md-12 pb-0 pt-1 col-form-label form-control-label"-->
        <!--                      >-->
        <!--                        Responsável-->
        <!--                      </label>-->
        <!--                      <validation-provider rules="required">-->
        <!--                        <div class="col-md-12">-->
        <!--                          <validation-provider rules="required">-->
        <!--                            <base-input input-classes="form-control-sm">-->
        <!--                              <el-select-->
        <!--                                @change="teste(report)"-->
        <!--                                v-model="report.responsible"-->
        <!--                                size="mini"-->
        <!--                                placeholder="Selecione"-->
        <!--                                filterable-->
        <!--                              >-->
        <!--                                <el-option label="Selecione" value=""></el-option>-->
        <!--                                <el-option-->
        <!--                                  v-for="user in users"-->
        <!--                                  :key="user.uuid"-->
        <!--                                  :label="user.name"-->
        <!--                                  :value="user.uuid"-->
        <!--                                >-->
        <!--                                </el-option>-->
        <!--                              </el-select>-->
        <!--                            </base-input>-->
        <!--                          </validation-provider>-->
        <!--                        </div>-->
        <!--                      </validation-provider>-->
        <!--                    </div>-->
        <!--                    <div class="form-group row m-0 p-0 mb-1">-->
        <!--                      <label-->
        <!--                        class="col-md-12 pb-0 pt-1 col-form-label form-control-label"-->
        <!--                      >-->
        <!--                        Observações-->
        <!--                      </label>-->
        <!--                      <div class="col-md-12">-->
        <!--                        <validation-provider rules="required">-->
        <!--                          <base-input input-group-classes="input-group-sm">-->
        <!--                        <textarea-->
        <!--                          @input="teste2(report)"-->
        <!--                          v-model="report.observation"-->
        <!--                          type="text"-->
        <!--                          rows="4"-->
        <!--                          class="form-control form-control-sm">-->
        <!--                          </textarea>-->
        <!--                          </base-input>-->
        <!--                        </validation-provider>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </collapse-item>-->
        <!--            </collapse>-->
        <!--          </card>-->
        <!--        </div>-->
        <div class="col-lg-4" v-for="(report, index) in reports" :key="index">
          <div class="card">
            <div class="card-header pointer"
                 style="padding: 0px !important;height: 22px">
              <div class="row">
                <div class="col-md-12">
                  <base-dropdown style="width: 100%" menuOnRight>
                    <base-button size="sm" slot="title-container"
                                 class="btn-header dropdown-toggle text-white text-uppercase"
                                 :style="!report.hardened_state ? 'background: #606062' : 'background: #1A70B7'"
                                 style="opacity: 92%;color: #4f40ff"
                                 block>
                      <img style="height: 20px" src="https://img.icons8.com/ios/100/FFFFFF/impossible-shapes.png"/>
                      {{ report.hardened_state ? 'Com moldagem' : 'Sem moldagem' }}
                    </base-button>
                    <a
                      @click.prevent="setHardenedState(index)"
                      class="dropdown-item text-uppercase"
                    >
                      {{ !report.hardened_state ? 'Com moldagem' : 'Sem moldagem' }}
                    </a>
                  </base-dropdown>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <h4 style="font-size: 1.2rem;padding-top: -5px" class="font-weight-600 mt-n2">
                    {{ report.schedule.contract_proposal_code }}
                    <a class="ml-1" @click.prevent="downloadReport(report)" href="#">
                      <img v-if="!report.printing" style="height: 1.5rem" class="pr-2 pointer"
                           src="/img/icons/icons8/print.png"/>
                      <img v-else style="height: 1.5rem" class="pr-2 pointer" src="/img/icons/icons8/printer.gif"/>
                    </a>
                    <a class="ml-n1" href="#" @click.prevent="showAdjustments(report.travel_uuid)">
                      <img style="height: 1.5rem" class="pointer" src="/img/icons/icons8/puzzle.png"/>
                    </a>
                  </h4>
                </div>
                <div class="col-6 text-right align-top mt-n2">
                  <base-dropdown menuOnRight>
                    <base-button
                      slot="title-container"
                      type="primary"
                      class="dropdown-toggle p-2 rounded m-0"
                    >
                      <i class="fas fa-cog"></i>
                    </base-button>

                    <router-link
                      :to="{ name: 'technology.report.hardened.index', params: { travel_uuid: report.travel_uuid }}">
                      <a
                        class="dropdown-item"
                      >
                        <i class="fas fa-cube fa-2x" style="color: #2d3748; opacity: 80%"></i>
                        <span class="text-right">Estado endurecido</span>
                      </a>
                    </router-link>
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      @click.prevent="handleFreshStateTest(report.travel_uuid)"
                    >
                      <i class="fa fa-flask text-primary"></i>
                      <span class="text-right">Estado fresco</span>
                    </a>
                  </base-dropdown>
                </div>
                <div class="col-md-12 mt-0">
                  <h4>{{ report.schedule.customer }}</h4>
                  <h4 class="mt-n2">{{ report.schedule.construction }}</h4>
                </div>
                <div class="col-12 p-0 pr-2 mb-2 pl-2">
                  <div style="border-top: 1px solid #1A70B7;margin-bottom: 5px"/>
                </div>
              </div>
              <div class="mt-n1 mb-3">
                <span class="text-dark" style="font-size: 11.2px;">
                    {{ report.updated_at | parseDate }}
                </span>
                <el-popover trigger="hover" placement="bottom" class="p-0 float-right pr-0 mr-2">
                  <h5 class="h3 mb-0 text-uppercase">
                    {{ report.charge_point }}
                  </h5>
                  <span>{{ report.plant }}</span>
                  <base-button outline slot="reference" size="sm" type="secundary"
                          class="p-0 m-0 shadow-none text-indigo">
                      <img src="/img/icons/chemical-plant-black.png" width="25">
                  </base-button>
                </el-popover>
                <el-popover trigger="hover" placement="bottom" class="p-0 float-right pr-0 mr-2">
                  <span class="h3">{{ report.equipment.code }} ({{ report.equipment.plate }})</span>
                  <br>
                  <span>{{ report.driver }}</span>
                  <base-button outline slot="reference" size="sm" type="secundary"
                          class="p-0 m-0 shadow-none text-indigo">
                      <img src="/img/icons/in-transit.png" width="25">
                  </base-button>
                </el-popover>
                <el-popover trigger="hover" placement="bottom" class="p-0 float-right pr-0 mr-2">
                  <div v-if="!technicalPermissions.is_view_technical_data_of_traces_in_test_results_launch"
                    style="width: 170px; word-break: break-word;">
                    <h5 class="text-danger">
                      Sem permissão
                    </h5>
                    <div class="new-default-black-font" style="font-size: 12px;">
                      Visualização indisponível, solicite permissão ao gestor.
                    </div>
                  </div>
                  <div v-if="technicalPermissions.is_view_technical_data_of_traces_in_test_results_launch"
                    class="border-left border-3 border-primary p-2 mt-1">
                    <h5 class="h4 mb-0">
                      {{ report.contract_proposal_formulation.title }}
                    </h5>
                    <div class="mt-2 mb-3">
                      <h5 class="small">
                        {{ report.contract_proposal_formulation.body }}
                      </h5>
                    </div>
                    <div class="mt-2 mb-3" v-if="report.contract_proposal_formulation.footer">
                      <h5 class="small">
                        {{ report.contract_proposal_formulation.footer }}
                      </h5>
                    </div>
                    <div class="mt-2 mb-3" v-if="report.schedule.piece">
                      <h5 class="small">
                        {{ report.schedule.piece }}
                      </h5>
                    </div>
                  </div>
                  <base-button outline slot="reference" size="sm" type="secundary"
                          class="p-0 m-0 shadow-none text-indigo">
                      <img src="/img/icons/test-tube.png" width="25">
                  </base-button>
                </el-popover>
                <a href="#" v-show="report.hardened_state" @click.prevent="handleShowModalInfo(report)">
                  <img class="mr-1 float-right" src="/img/icons/info.png"  width="26" />
                </a>
              </div>
              <div class="row">
                <div class="col px-1">
                  <base-button
                    size="sm"
                    class="text-uppercase"
                    style="border-color: gray; height: 40px;"
                    type="white"
                    block
                    outline
                  >
                    <h3 class="text-primary">
                      <img style="width: 33px" src="/img/icons/os.png" />
                      {{ report.index }}
                    </h3>
                  </base-button>
                </div>
                <div class="col px-1" v-if="report.xml">
                  <base-button
                    size="sm"
                    class="text-uppercase"
                    style="border-color: gray; height: 40px;"
                    type="white"
                    block
                    outline
                  >
                    <h3 class="text-indigo-nfe">
                      <img style="width: 22px" src="/img/icons/nfe.png" />
                      {{ report.xml.number }}
                    </h3>
                  </base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <loading-pagination :show="loading && !loadingSkeleton" :show-offset="!modal_shown"/>
      <ModalFreshStateTest ref="freshStateTest"/>
      <ModalAdjustment ref="ModalAdjustment"/>
      <modal-info ref="modalInfo"/>
      <ModalHardenedStateTest ref="hardenedStateTest"/>
    </div>
  </div>
</template>

<script>
import cursorPaginate from "@/mixins/cursorPaginate";
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import {mapGetters} from "vuex";
import SkeletonPuzlGrid from "../../../../../components/SkeletonPuzlGrid";
import {period_types} from "@/views/Modules/Technology/Mix/helpers/mixe";
import {
  debounce,
} from "@/plugins";
import ModalFreshStateTest from "../../../Operational/Schedule/Weighing/Shared/_ModalFreshStateTest";
import MultiFilterV2 from "@/components/Utils/MultiFilterV2";
import LoadingPagination from "@/components/LoadingPagination";
import InputDatePicker from "@/components/InputDatePicker";
import PuzlEmptyData from "../../../../../components/PuzlEmptyData";
import ModalInfo from './Shared/ModalInfo.vue'
import ModalAdjustment from '../../../Operational/Schedule/Charging/Shared/_ModalAdjustments.vue'
import ModalHardenedStateTest from "../../../Operational/Schedule/Weighing/Shared/_ModalHardenedStateTest.vue";

export default {
  mixins: [cursorPaginate],
  name: "Index",
  components: {
    PuzlEmptyData,
    InputDatePicker,
    ModalFreshStateTest,
    SkeletonPuzlGrid,
    PuzlBreadcrumb,
    MultiFilterV2,
    LoadingPagination,
    ModalInfo,
    ModalAdjustment,
    ModalHardenedStateTest
  },
  data() {
    return {
      format_mixe: period_types,
      loadingUsers: false,
      modal_shown: false,
      printing: false,
      loadingSkeleton: false,
      technicalPermissions: {},
      filter: {
        with_hardened_state: 1,
        without_hardened_state: 1,
      },
      hover_icon_with_hardened_state: 0,
      hover_icon_without_hardened_state: 0,
      range: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        end: new Date(),
      },
      pickerOptions: {
        selectableRange: this.range,
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      users: "user/fetch",
      reports: "testReport/fetch",
      $_user: 'auth/getUser',
    })
  },
  methods: {
    fetchTechnicalPermissions(userId) {
      this.$store.dispatch('technicalPermission/getByUserId', userId).then((response) => {
        this.technicalPermissions = response.data
      });
    },
    /**
     * Realiza o download do relatório de carregamento
     * @param {Object} report - O objeto de relatório
     * @param {string} report.schedule_travel_uuid - O UUID da viagem associada ao relatório
     */
    downloadReport(report) {
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'})
      let params = {
        uuid: report.travel_uuid,
        type: "report"
      };
      this.$store
        .dispatch("travels/download", params)
        .then(async response => {
          let blob = new Blob([response], {type: "application/pdf"});
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", 'Relatório de carregamento ' + report.index);
          await link.click()
          this.$snotify.success('Download iniciado com sucesso!', {
            timeout: 1000,
            icon: false,
            position: "centerBottom",
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false
          });
          report.printing = false
        })
        .catch(error => {
          loader.hide();
        });
    },
    /**
     * Obtém os ajustes associados a uma viagem e exibe-os em um modal
     * @param {string} schedule_travel_uuid - O UUID da viagem
     */
    showAdjustments(schedule_travel_uuid) {
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'})
      this.$store
        .dispatch("travels/getAdjustments",
          {schedule_travel_uuid: schedule_travel_uuid, mix_design_mixe_id: null}
        )
        .then(response => {
          this.$Progress.finish();
          this.$notify({type: "success", message: response.message});
          this.modal_shown = true
          this.$refs.ModalAdjustment.openModal();
        })
        .catch(error => {
          this.$Progress.finish();
        });
    },
    /**
     * Busca informações de coleta da viagem
     * @param report
     */
    handleShowModalInfo(report) {
      this.$refs.hardenedStateTest.openModal(report.travel_uuid)
    },
    /**
     /**
     * Atualizar status de moldagem da viagem
     * @param index
     */
    setHardenedState(index) {
      /**
       * coloca a moldagem diferente do valor atual
       * @type {boolean}
       */
      let report = this.reports[index]
      let hardened_state = !report.hardened_state
      /**
       * se o novo status for não, exibe mensagem de confirmação
       */
      if (!hardened_state) {
        this.$Swal.confirmAction('Deseja confirmar a exclusão das moldagens dessa viagem?', ['Sim', 'Não'])
          .then((result) => {
            if (result.isConfirmed) {
              this.updateHardenedState({
                travel_uuid: report.travel_uuid,
                hardened_state: hardened_state,
              }, index)
            }
          }).catch(() => this.$Progress.finish())
      } else {
        this.updateHardenedState({
          travel_uuid: report.travel_uuid,
          hardened_state: hardened_state,
        }, index)
      }
    },
    updateHardenedState(report, index) {
      let params = {
        schedule_travel_uuid: report.travel_uuid,
        hardened_state: report.hardened_state,
      };
      let loader = this.$loading.show()
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.$store
        .dispatch("travelConfiguration/storeDefaultHardenedStates", params)
        .then(response => {
          if (report.hardened_state) {
            this.$refs.hardenedStateTest.openModal(report.travel_uuid)
          }
          this.reports[index].hardened_state = report.hardened_state
          this.$notify({type: "success", message: response.message});
        })
        .catch((error) => {
          this.$notify({type: "danger", message: error.data.message});
        })
        .finally(() => (loader.hide()));
    },
    handleFilterAdvancedStatus() {
      if (this.filter.with_hardened_state) {
        this.filter.with_hardened_state = 0
      } else {
        this.filter.with_hardened_state = 1
      }
      this.init()
    },
    handleFilterDate(filter) {
      this.range = filter
      this.loadingSkeleton = true
      this.init({})
    },
    handleHardenedSate(uuid) {
      this.$router.push({name: 'technology.report.hardened.index', params: {travel_uuid: uuid}})
    },
    clearFilter() {
      this.filter = {}
      this.init({})
    },
    handleFreshStateTest(uuid) {
      let loader = this.$loading.show()
      this.$store.dispatch("testFreshState/fetchItems").then(() => {
        loader.hide()
        this.$refs.freshStateTest.openModal(uuid);
      });
    },
    init(filter = null) {
      this.startCursor(filter)
      this.filter.range = this.range
      this.$store.dispatch('testReport/fetchItems',
        {
          filter: this.filter,
          next_page: this.paginate.nextUrl
        }).then(response => {
        this.loadingSkeleton = false
        this.resolveCursor(response)
      }).catch(error => {
        this.resolveCursor()
        this.loadingSkeleton = false
      })
    },
    teste(report) {
      let params = {
        responsible: report.responsible,
        observation: report.observation,
        collect: report.collect,
        molder: report.molder,
        schedule_travel_uuid: report.travel_uuid
      }
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      let loader = this.$loading.show()
      this.$store.dispatch("testReport/add", params).then(response => {
        loader.hide()
        this.$notify({type: 'success', message: response.message});
      }).catch(() => loader.hide());
    },
    teste2: debounce(function (report) {
      let params = {
        observation: report.observation,
        schedule_travel_uuid: report.travel_uuid
      }
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      let loader = this.$loading.show()
      this.$store.dispatch("testReport/add", params).then(response => {
        loader.hide()
        this.$notify({type: 'success', message: response.message});
      }).catch(() => loader.hide());
    }, 500),
  },
  mounted() {
    this.loadingSkeleton = true
    this.loadingUsers = true
    this.$store.dispatch("user/fetchItems").then(() => {
      this.loadingUsers = false;
    }).catch(() => this.loadingUsers = false);
    this.init();
    this.fetchTechnicalPermissions(this.$_user.id);
  }
}
</script>
<style scoped>
.el-input__suffix {
  width: 112%;
}
</style>
