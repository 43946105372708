<template>
  <div class="mt-6">
    <div class="container-fluid">
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <PuzlEmptyData v-if="!$_taxes.length && !loadingSkeleton" />
      <div class="row card-wrapper" v-if="!loadingSkeleton">
        <div class="col-lg-4" v-for="(taxe, index) in $_taxes" :key="index">
          <card>
            <div class="row">
              <div class="col-10" style="display: flex; align-items: center;">
                <h5 class="m-0 mr-3 new-default-gray-font">
                  {{ taxe.coverage }}
                </h5>
                <div class="mx-1"
                  :class="taxe.nfe == 0 ? 'text-danger card-outline-danger' : 'text-success card-outline-success'">
                  NFE 
                </div>
                <div class="mx-1"
                  :class="taxe.nfse == 0 ? 'text-danger card-outline-danger' : 'text-success card-outline-success'">
                  NFSE
                </div>
              </div>
              <div class="col-2 text-right">
                <base-dropdown menuOnRight>
                  <div slot="title-container" class="dropdown-toggle rounded m-0">
                    <img width="32" src="/img/icons/icons8/ios/settings--v1_primary.png" />
                  </div>
                  <a @click="handleEditTax(taxe)"
                    class="dropdown-item new-default-black-font"
                    style="align-items: center; display: flex">
                    <img width="22" src="/img/icons/edit.png" />
                    EDITAR
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a @click="handleDetailTributes(taxe.uuid)"
                    class="dropdown-item new-default-black-font"
                    style="align-items: center; display: flex">
                    <img width="22" src="/img/icons/icons8/ios/place-marker--v1.png" />
                    Abrangência
                  </a>
                  <div v-if="$hasPrivilege(1)" class="dropdown-divider p-0 m-0"></div>
                  <a v-if="$hasPrivilege(1)"
                    class="dropdown-item new-default-black-font"
                    style="align-items: center; display: flex"
                    @click="handleDeleteTax(taxe.uuid)"
                  >
                    <img width="22" src="/img/icons/delete.png" />
                    EXCLUIR
                  </a>
                </base-dropdown>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <h3 class="new-default-black-font m-0">
                  {{ taxe.name }}
                </h3>
                <h4 class="new-default-black-font font-weight-normal">
                  {{ taxe.observation }}
                </h4>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
    <modal-edit ref="modal-edit" />
    <loading-pagination :show="loading && !loadingSkeleton" />
  </div>
</template>

<script>
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import ModalEdit from "./_ModalEdit";
import { mapGetters } from "vuex";
import MultiFilter from "@/components/Utils/MultiFilterV2";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import PuzlEmptyData from "@/components/PuzlEmptyData";

export default {
  name: "ListFeesAndTaxes",
  mixins: [cursorPaginate],
  components: {
    SkeletonPuzlGrid,
    ModalEdit,
    MultiFilter,
    LoadingPagination,
    PuzlEmptyData,
  },
  data() {
    return {
      loadingSkeleton: true,
      filter: {},
    };
  },
  computed: {
    ...mapGetters({
      $_taxes: "FeesAndTaxes/fetch",
    }),
  },
  methods: {
    init(filter = null) {
      this.startCursor(filter);
      this.$store
        .dispatch("FeesAndTaxes/fetchItemsPaginate", {
          filter: this.filter,
          next_page: this.paginate.nextUrl,
        })
        .then((response) => {
          this.resolveCursor(response);
        })
        .catch((error) => {
          this.resolveCursor();
        });
    },
    handleDeleteTax(uuid) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$notify({
            type: "info",
            message: "Estamos trabalhando em sua solicitação.",
          });
          this.$store
            .dispatch("FeesAndTaxes/destroy", uuid)
            .then(() => this.$Progress.finish())
            .catch((error) => {
              this.$notify({ type: error.data.error_type, message: error.data.message });
              this.$Progress.finish();
            });
        }
      });
    },
    handleEditTax(taxe) {
      this.$refs["modal-edit"].handleEditModal(taxe);
    },
    handleDetailTributes(uuid) {
      this.$router.push({ name: "Tributos", params: { uuid: uuid } });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style scoped>
.card-outline-danger {
  border: 1px solid #db4539;
  padding: 0px 5px;
  border-radius: 10px;
  height: 14px;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.card-outline-success {
  border: 1px solid #149e57;
  padding: 0px 5px;
  border-radius: 10px;
  height: 14px;
  display: flex;
  align-items: center;
  font-size: 12px;
}
</style>