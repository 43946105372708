<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-gray d-inline-block mb-0">{{ this.$route.meta.breadcrumbs[1].title }}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4 text-gray">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i :class="this.$route.meta.icon"></i></a></li>
              <li class="breadcrumb-item"><a href="#">{{ this.$route.meta.breadcrumbs[0].title }}</a></li>
              <li class="breadcrumb-item active" aria-current="page">{{ this.$route.meta.breadcrumbs[1].title }}</li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- Header Menus -->
      <div class="row">
        <div class="col-md-2 mb-3">
          <router-link
            :to="{
                  path: '/equipment/equipment/implement/create',
                  name: 'equipment.implement.create',
                  params: { equipment_uuid: equipment.uuid}
                }"
          >
            <base-button class="pb-3 pt-3 text-uppercase" block size="lg" type="success">
              <i class="fas fa-plus"></i> novo
            </base-button>
          </router-link>
        </div>
        <div class="col-md-2 mb-2">
          <router-link :to="{ path:'/equipment/equipment'}">
            <base-button block size="lg" type="light" class="pb-3 pt-3 text-uppercase">
              voltar
            </base-button>
          </router-link>
        </div>
      </div>

      <div class="row align-items-center pb-0 mb-3">
        <div class="col-md-12">
          <div class="checklist-item checklist-item-primary">
            <div class="checklist-info">
              <h1 class="checklist-title mb-0">
                Equipamento: {{ equipment.type ? equipment.type.description : ''}}
              </h1>
              <small>
                Descrição: {{ equipment.vehicle_description || ''}}
              </small>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>

      <div class="row align-items-center pb-0">
        <div class="col-md-12">
          <div class="card p-2">
            <base-input placeholder="Pesquisar">
              <template slot="append">
                <i class="fas fa-search"></i>
              </template>
            </base-input>
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid">
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <div
        class="row align-items-center"
        v-if="!equipment_implements.length && !loadingSkeleton"
      >
        <div class="col-md-12 text-center">
          <div class="card p-4">
            Sem dados cadastrados!
          </div>
        </div>
      </div>

      <div class="row card-wrapper" v-if="equipment_implements.length && !loadingSkeleton">
        <div class="col-lg-4" v-for="(implement, index) in equipment_implements" :key="index">
          <card
            :class="
              implement.status ? 'card-border-top-success' : 'card-border-top-danger'
            "
          >
            <!-- Card header -->
            <div class="row align-items-center mb-3">
              <div class="col-8">
                <!-- Title -->
                <h5 class="h3 mb-0">
                  {{implement.code || ''}}
                </h5>
                <h5 class="h3 mb-0 small">
                  {{implement.plate || ''}}
                </h5>
              </div>

              <div class="col-4 text-right">
                <base-dropdown menuOnRight>
                  <base-button
                    slot="title-container"
                    type="primary"
                    class="dropdown-toggle p-2 rounded m-0"
                  >
                    <i class="fas fa-cog"></i>
                  </base-button>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click.prevent="handleBuySell(implement.uuid)">
                    <i class="fas fa-list text-info"></i>
                    Dados de Compra / Venda
                  </a>

                  <div class="dropdown-divider p-0 m-0"></div>
                  <router-link :to="{
                    path: '/configuration/implement/edit',
                    name: 'configuration.implement.edit',
                    params: { implement_uuid: implement.uuid}}">
                    <a class="dropdown-item">
                      <i class="fas fa-edit text-warning"></i>
                      Editar
                    </a>
                  </router-link>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click.prevent="handleDelete(implement.uuid)">
                    <i class="fas fa-times text-danger"></i>
                    Excluir
                  </a>
                </base-dropdown>
              </div>
            </div>
            <!-- Card body -->
            <h5 class="h3 mb-0">
              {{implement.category.category_name || ''}}
            </h5>
            <h5 class="h3 mb-0 small">
              {{implement.type.description || ''}}
            </h5>

            <collapse class="border rounded p-0 mt-3">
              <collapse-item
                class="header-gray"
                back-ground="border-header-primary-bottom"
              >
                <h5 slot="title" class="mb-0 ">Detalhes</h5>

                <h5 class="h3 mb-0">
                  {{ implement.brand || ''}} &nbsp;({{implement.predominant_color || ''}})
                </h5>
                <h5 class="h3 mb-0 small">
                  {{implement.model || ''}} ({{implement.equipment_manufacture_year || ''}}/{{implement.equipment_year}})
                </h5>

                <h5 class="h3 mb-0 mt-2">
                  {{implement.chassis || ''}}
                </h5>
                <h5 class="h3 mb-0 small">
                  {{implement.vehicle_permit_code || ''}}
                </h5>

                <h5 class="h3 mb-0 mt-2">
                  {{implement.vehicle_license_name || ''}}
                </h5>
                <h5 class="h3 mb-0 small">
                  {{implement.vehicle_license_legal_entity || ''}}
                </h5>

              </collapse-item>
            </collapse>

            <collapse class="border rounded p-0 mt-3" v-if="implement.allocation.length > 0">
              <collapse-item
                class="header-gray"
                back-ground="border-header-success-bottom"
              >
                <h5 slot="title" class="mb-0 ">Alocação</h5>

                <div class="row align-items-center pb-0 mb-3">
                  <div class="col-md-12">
                    <div class="checklist-item checklist-item-primary">
                      <div class="checklist-info">

                        <h4 class="checklist-title mt-2" v-for="(implement, indexImplement) in implement.allocation" :key="indexImplement">
                          {{implement.name}}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </collapse-item>
            </collapse>
          </card>
        </div>
      </div>


    </div>
    <ModalSetPlant ref="modalSetPlant" />
    <ModalBuySell ref="modalBuySell" />
    <ModalSetUserPermission ref="modalSetUserPermission" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import ModalSetPlant from './_ModalSetPlant'
import ModalBuySell from './_ModalBuySell'
import ModalSetUserPermission from './_ModalBuySell'
export default {
  name: "ListImplement",
  components: {
    SkeletonPuzlGrid,
    ModalSetPlant,
    ModalBuySell,
    ModalSetUserPermission,
  },
  data() {
    return {
      loadingSkeleton: false,
    };
  },
  computed: {
    ...mapGetters({
      equipment: "equipment/show",
      equipment_implements: "implement/fetch"
    })
  },
  methods: {
    fetch() {
      this.$Progress.start();
      this.loadingSkeleton = true
      this.$store
        .dispatch("equipment/show", this.$route.params.equipment_uuid)
        .then(response => {
          this.$Progress.finish()
          this.loadingSkeleton = false
        })
        .catch(error => {
          this.$Progress.finish()
          this.loadingSkeleton = false
        });
    },
    fetchImplement() {
      this.$Progress.start()
      this.loadingSkeleton = true
      this.$store
        .dispatch("implement/fetchItems", this.$route.params.equipment_uuid)
        .then(response => {
          this.$Progress.finish()
          this.loadingSkeleton = false
        })
        .catch(error => {
          this.$Progress.finish()
          this.loadingSkeleton = false
        });
    },
    handleAllocation(uuid) {
      this.$refs.modalSetPlant.openModal(uuid, 'implement')
    },
    handleBuySell(uuid) {
      this.$refs.modalBuySell.openModal(uuid, 'implement')
    },
    handleSetUserPermission(userUuid) {
      this.$refs.modalSetUserPermission.openModal(userUuid)
    },
    handleDelete(uuid){
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed){
          this.$Progress.start();
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          this.$store.dispatch('implement/destroy', uuid)
          this.$Progress.finish();
        }
      }).catch(() => this.$Progress.finish())
    }
  },
  mounted() {
    this.fetch()
    this.fetchImplement()
  }
};
</script>

<style scoped>
.main-icon-menu {
  min-width: 18px;
}

.photo {
  width: 100px !important;
  height: 100px !important;
}
</style>
