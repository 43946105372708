<template>
  <div>
    <div class="container-fluid">
      <multi-filter
        @clearFilter="clearFilter()"
        @handleFilterDate="handleFilterDate" @fetch="init" :showSlotBookmark="false">
        <template slot="dates">
          <div class="col-md-12 text-left">
            <label class="form-control-label">
             DATA DA CONCRETAGEM
            </label>
            <input-date-picker visibility="focus"/>
          </div>
        </template>
        <template slot="data">
          <div class="row pr-3 mt-1 pl-3">
           <div class="col-12 text-left">
             <label class="form-control-label">
               NÚMERO DA O.S
             </label>
             <div class="row">
               <div class="col-6 text-left pr-1">
                 <input placeholder="de" class="form-control form-control-sm btn-first-child"/>
               </div>
               <div class="col-6 pl-1">
                 <input placeholder="até" class="form-control form-control-sm" />
               </div>
             </div>
           </div>
         </div>
         <div class="row pr-3 mt-1 pl-3">
           <div class="col-12 text-left">
             <label class="form-control-label">
               NÚMERO DA NF-E
             </label>
             <div class="row">
               <div class="col-6 text-left pr-1">
                 <input placeholder="de" class="form-control form-control-sm btn-first-child"/>
               </div>
               <div class="col-6 pl-1">
                 <input placeholder="até" class="form-control form-control-sm" />
               </div>
             </div>
           </div>
         </div>
         <div class="col-md-12 mt-3 text-left">
            <label class="form-control-label">
            ADICIONAIS
            </label>
            <puzl-select
              v-model="additionals_filter"
              :items="[]"
              :multiple="true"
              class="select-xl new-default-black-font"
              :disabled="false"
              :loading="false" />
         </div>
        </template>
        <template slot="status">
          <div class="col-12">
            <base-button-hoverable size="sm" type="success" icon="money-time" platform="ios">
              COBRAR
            </base-button-hoverable>
          </div>
          <div class="col-12 mt-1">
            <base-button-hoverable size="sm" type="danger"  icon="close" platform="ios">
              CANCELADO
            </base-button-hoverable>
          </div>
          <div class="col-12 mt-1">
            <base-button-hoverable size="sm" type="primary"  icon="check" platform="ios">
              FATURADO
            </base-button-hoverable>
          </div>
          <div class="col-12 mt-1 mb-5">
            <base-button-hoverable size="sm" type="danger"  icon="check" platform="ios">
              FATURADO SEM COBRANÇA
            </base-button-hoverable>
          </div>
        </template>
        <template slot="order-by">
          <el-popover trigger="click" placement="bottom" class="p-0 float-right pr-0 ml-3">
              <h6 style="padding: 2px;"><a href="#" class="font-weight-400" style="font-size: 12px">
                <i class="text-primary fa-solid fa-angles-down" style="font-size: 12px;margin-right: 5px"></i> O.S: DO MAIS NOVO PARA O MAIS VELHO</a> </h6>
              <hr class="mb-1 mt-n2">
              <h6 style="padding: 2px;"><a href="#" class="font-weight-400" style="font-size: 12px">
                <i class="text-primary fa-solid fa-angles-up" style="font-size: 12px;margin-right: 5px"></i> O.S: DO MAIS VELHO PARA O MAIS NOVO</a> </h6>
              <a href="#" slot="reference">
                <h5 slot="reference" class="pt-1">ORDENAR <i class="fa-solid fa-chevron-down"></i></h5>
              </a>
            </el-popover>
        </template>
      </multi-filter>
    </div>
    <div class="container-fluid">
      <PuzlEmptyData v-if="!loadingSkeleton && !$_resumeAdditional.length"></PuzlEmptyData>
      <div class="row card-wrapper" v-if="!loadingSkeleton && $_resumeAdditional.length">
        <div class="col-lg-4" v-for="item, index in $_resumeAdditional" :key="index">
          <div class="card main-card" style="overflow: hidden;">
            <div class="card-header pointer" style="padding: 0px !important;">
              <div 
                v-if="((item.billed && item.status) || (item.billed && !item.status))"
                class="btn-header text-center text-white text-uppercase"
                style="font-size: 0.75rem;padding: 5px;letter-spacing: 0.025em;"
                :style="(item.billed && item.status) ? 'background: #149E57' : 'background: #DB4539'"
              >
                FATURADO
              </div>
              <base-dropdown 
                v-if="((!item.billed && item.status) || (!item.billed && !item.status))" 
                style="width: 100%" 
                menuOnRight
              >
                <base-button size="sm" slot="title-container"
                  class="btn-header dropdown-toggle text-white text-uppercase"
                  :style="!item.billed && item.status ? 'background: #149E57;' : 'background: #DB4539;'"
                  style="opacity: 92%;color: #4f40ff block;width: 100%;border: none;padding: 5px;"
                >
                    <img class="mt-n1 mr-1" width="13px" src="/img/icons/icons8/ios/clock-white.png" />
                    {{ !item.billed && item.status ? "COBRAR" : "CANCELADO" }}
                </base-button>
                <div 
                  @click="handleItemStatusChange(item, index)" 
                  v-if="!item.billed && item.status"
                  class="dropdown-item text-uppercase"
                >
                  Não cobrar
                </div>
                <div 
                  @click="handleItemStatusChange(item, index)" 
                  v-if="!item.billed && !item.status"
                  class="dropdown-item text-uppercase"
                >
                  Cobrar
                </div>
              </base-dropdown>
            </div>
            <div class="pt-3 pr-4 pl-4 pb-3">
              <!-- Card body -->
              <div class="row">
                <div class="col-8">
                  <h3 class="new-default-black-font font-weight-600"
                    style="font-size: 16px;"> {{ item.code }}</h3>
                  <h5 class="mt-n1 new-default-black-font font-weight-500">{{ item.construction_name }}</h5>
                  <h5 class="mt-n1 new-default-black-font font-weight-500">{{ item.customer_name }}</h5>
                </div>
                <div class="col-4" v-if="item.send_company_plant_name">
                  <el-popover trigger="click" placement="left">
                    <div class="new-default-black-font">
                      {{ item.send_company_plant_name }}
                    </div>
                    <img slot="reference" role="button" @click.prevent="showPlantName(index)" class="float-right mt-5" width="26px" src="/img/icons/icons8/ios/chemical-plant-v1.png"/>
                  </el-popover>
                </div>
              </div>
              <hr class="blue-divider mt-2 mb-2">
              <div class="row">
                <div class="col-7">
                  <h4 class="new-default-black-font font-weight-600"
                    style="font-size: 16px;">
                    {{ item.short_description }}
                    <el-popover trigger="click" placement="right">
                      <div class="new-default-black-font" style="width: 300px;">
                        <div class="row">
                          <div class="col-8 font-weight-600 new-default-black-font" style="font-size: 16px;">
                            TOTAL: {{ item.price | currency() }}
                          </div>
                          <div class="col-4">
                            <img width="22px" src="/img/icons/nfe.png"/>
                            <span class="font-weight-500" style="text-transform: none; font-size: 14px;"> n. </span>
                            <span class="font-weight-500" style="font-size: 13px;"> {{ item.number }}</span>
                          </div>
                          <div class="col-12 mt-2">
                            <!-- Informação estática, dado não está vindo da API, nem o uuid para busca -->
                            <!-- Dado pode ser encontrado nessa URL, https://testing.puzl.place/commercial/additional -->
                            <!-- Tentei fazer uma função para pesquisar pelo short_description, mas ficaria uma gambiarra  -->
                            <nav class="info-additional-text new-default-black-font font-weight-400" style="font-size: 12px;">
                              DE SEGUNDA A SEXTA, A PARTIR DOS HORÁRIOS DEFINIDOS, SERÁ COBRADO ACRÉSCIMO SOBRE OS PREÇOS CONTRATADOS.
                            </nav>
                          </div>
                        </div>
                      </div>
                      <img slot="reference" role="button" class="mt-n1 ml-2" width="24px" src="/img/icons/info.png"/>
                    </el-popover>
                  </h4>
                </div>
                <div class="col-5 text-right">
                  <h4 style="text-transform: none;font-size: 19px;"
                    class="text-success font-weight-600"
                    v-if="item.billing_unit_id !== 0">
                    {{ format(item.calculation_base) || '-' }}
                  </h4>
                  <h4 v-else class="text-success font-weight-600">
                    {{ item.calculation_base | currency() }}
                  </h4>
                </div>
                <div class="col-12 mt-n2">
                  <h5 style="font-size: 14px;" class="new-default-gray">
                    {{ item.billing_unit_description }}
                  </h5>
                </div>
                <div class="col-12">
                  <img role="button" width="24px" src="/img/icons/os.png"/>
                  <span class="text-primary font-weight-500" style="text-transform: none; font-size: 14px;"> nº </span>
                  <span class="text-primary font-weight-500" style="font-size: 13px;"> 
                    {{ item.number }} | {{ item.schedule_travel_volume }} m³ | {{ item.finished_at | parseDate }} 
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row card-wrapper" v-if="loadingSkeleton">
        <SkeletonPuzlGrid size="lg" v-for="(index) in 3" v-show="true" :key="index"></SkeletonPuzlGrid>
      </div>
      <ModalJustification @reupdated="init({})" @updatedJustification="updatedJustification" ref="ModalJustification"/>
      <loading-pagination :show="loading && !loadingSkeleton"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import MultiFilter from "@/components/Utils/MultiFilterV3";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import InputDatePicker from '@/components/InputDatePicker';
import PuzlSelect from "@/components/PuzlSelect";
import ModalJustification from './_ModalJustification';
import {SkeletonPuzl} from "@/components";
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";

export default {
  name: "ResumeAdditional",
  mixins: [cursorPaginate],
  components: {
    PuzlEmptyData,
    SkeletonPuzlGrid,
    SkeletonPuzl,
    MultiFilter,
    LoadingPagination,
    InputDatePicker,
    PuzlSelect,
    ModalJustification,
    BaseButtonHoverable
  },
  data() {
    return {
      loadingSkeleton: false,
      loadingSkeletonWidgets: false,
      isLoadingCompanyPlants: true,
      filter: {
        company_plant_uuids: [],
        range: [],
        additionals: []
      },
      additionals_filter: [],
      range: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        end: new Date(),
      },
      index: null,
    };
  },
  computed: {
    ...mapGetters({
      $_widgets: "resumeAdditional/getWidgets",
      $_resumeAdditional: "resumeAdditional/fetch",
      $_plants: "plant/activeItems",
    }),
  },
  mounted() {
    this.$store.dispatch('plant/fetchItemsActive').then(() => {
      this.isLoadingCompanyPlants = false;
    });
    this.init({});
  },
  methods: {
    clearFilter(){
      this.filter = {
        company_plant_uuids: [],
        range: this.range,
        additionals: []
      };
      this.init(this.filter);
    },
    getWidgets() {
      this.loadingSkeletonWidgets = true
      this.$store
        .dispatch("resumeAdditional/fetchWidgets", {
          filter: this.filter,
        })
        .then(response => {
          this.loadingSkeletonWidgets = false
        })
        .catch(error => {
          this.loadingSkeletonWidgets = false
        });
    },
    showPlantName(index){
      this.$el.querySelector('.plant-name-' + index).classList.toggle('hide');
    },
    showAdditionalFullDescription(index){
      this.$el.querySelector('.additional-info-' + index).classList.toggle('hide');
    },
    /**
     * @description
     * Atualiza justificativa dinamicamente após salvar.
     *
     * @param {string} justification
     */
    updatedJustification(justification) {
      this.$_resumeAdditional[this.index].justification = justification;
    },
    init(filter = null) {
      this.startCursor(filter)
      this.getWidgets()
      this.$Progress.start()
      this.$store
        .dispatch("resumeAdditional/fetchItemsPaginate", {
          filter: this.filter,
          next_page: this.paginate.nextUrl
        })
        .then(response => {
          this.$Progress.finish()
          this.resolveCursor(response)
        })
        .catch(error => {
          this.resolveCursor()
          this.$Progress.finish()
        });
    },
    format(value) {
      if (!isNaN(value)) {
        return this.$helper.secondsToTime(value * 60, true)
      }
      return value
    },
    handleDelete(id) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$notify({
            type: 'info',
            message: 'Estamos trabalhando em sua solicitação.'
          });
          this.$store.dispatch('resumeAdditional/destroy', id)
            .then((response) => {
              this.$notify({
                type: 'success',
                message: response.message
              });
            })
            .catch(error => {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              });
            })
          this.$Progress.finish();
        }
      }).catch(() => this.$Progress.finish())
    },
    handleFilterDate(filter) {
      this.range = filter;
      this.init(this.range);
    },
    handleFilterCompanyPlants() {
      this.init({});
    },
    onlySave(item) {
      let params = {
        justification: null,
        status: 1,
        uuid: item.uuid
      }
      let loader = this.$loading.show()
      this.$Progress.start();
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch('scheduleAdditional/update', params)
        .then(response => {
          this.$Progress.finish();
          loader.hide()
          this.$notify({type: 'success', message: response.message});
        }).catch(error => {
        this.$notify({type: 'danger', message: error.data.message});
        this.$Progress.finish();
        loader.hide()
      })
    },
    /**
     * @param {object} item
     * @param {number} index
     */
    async handleItemStatusChange(item, index) {
      this.index = index;
      if (!item.status) {
        item.status = !item.status;
        return this.onlySave(item)
      } else {
        let hasPermissionToCancelAdditional = true;
        await this.$store.dispatch('operationalPermission/getByUserId', this.$user.id).then((response) => {
          if (!response.data.cancellation_of_additions) {
            this.$notify({
              type: "danger",
              message: "Usuário não possui permissão para cancelar adicional."
            });
            hasPermissionToCancelAdditional = false;
          }
        });
        if (hasPermissionToCancelAdditional) {
          this.$refs.ModalJustification.handleCreateModal(item);
        }
      }
    },
    downloadExcel() {
      const loader = this.$loading.show();
      this.$store
        .dispatch("scheduleAdditional/getReportExcel", this.filter).then(() => {
          this.$notify({
            type: "success",
            message: "Solicitação realizada com sucesso! O relatório será exportado em segundo plano.",
          });
        }).catch(error => {
          const errors = error && error.response && error.response.status === 422
            ? formatErrorValidation(error.response.data.data)
            : error.response.data.message;
          this.$notify({ type: "danger", message: errors });
        }).finally(() => {
          loader.hide();
        });
    },
  },
};
</script>

<style scoped>
.blue-divider {
  background-color: #1b6eba;
  color: #1b6eba;
  height: 0.1px;
}

.new-default-gray {
  color: #6c6c6c;
}

.hide {
  display: none;
}

.popover-div {
  position: absolute;
  background-color: white;
  border-radius: 8px;
  width: auto;
  padding: 5px;
  white-space: nowrap;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  z-index: 1;
}
.popover-plant-div {
  top: 42px;
  right: 45px;
}

.popover-info-div {
  top: 27px;
  left: 160px;
  padding: 15px;
  max-width: 260px;
}

.info-additional-text {
  box-sizing: border-box;
  width: 100%;
  height: auto;
  white-space: pre-wrap;
}
</style>
