<template>
  <div>
    <list-charge-point></list-charge-point>
  </div>
</template>
<script>
import ListChargePoint from './Shared/_List'


export default {
  name: 'IndexChargePoint',
  components: {
    ListChargePoint,
  },
  data() {
    return{

    }
  },
  beforeMount() {
    this.$store.dispatch('automation/fetch');
  }
};
</script>
<style></style>
