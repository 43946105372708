<template>
  <div>
    <modal
      :show.sync="modal.create"
      :backgroundColor="'#F2F4F9'"
    >
      <template slot="header">
        <div class="d-flex align-items-center container-fluid" style="gap: 12px; margin-left: -28px;">
          <div>
            <img src="/img/icons/icons8/ios/icon-plus-success.png" height="24" width="24">
          </div>
          <span class="text-title">
            {{ modal.title }}
          </span>
        </div>
      </template>
      <template slot="close-button">
        <img class="pointer" @click="closeModal('create')" src="/img/icons/icons8/ios/close-window.png" alt="close-window" width="17px">
      </template>
      <div class="p-2">
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form class="needs-validation" autocomplete="off">
            <span class="ml-2 label-title">
              <img height="30px" src="/img/icons/icons8/ios/info-squared.png" class="mr-2"/>
              Identificação
            </span>
            <div class="form-group row m-0 p-0 mb-1">
              <div class="col-md-8 mb-1 pr-4">
                <div class="pb-0 mb-1 col-form-label form-control-label label-item">
                  Receber e-mails Financeiros
                </div>
              </div>
              <div class="col-md-3 mb-1 mt-2">
                <ToggleButton 
                  :initialValue="$_entityContact?.is_financial ?? false " 
                  @update:initialValue="$_entityContact.is_financial = $event"
                />
              </div>
            </div>

            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label label-item">
                Nome
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-8">
                <validation-provider rules="required" v-slot="{ errors }">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="$_entityContact.name"
                      :class="errors[0] ? 'is-invalid' : 'is-valid'"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label label-item">
                Cargo
              </label>
              <div class="col-md-8">
                <base-input input-group-classes="input-group-sm">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="$_entityContact.office"
                  />
                </base-input>
              </div>
            </div>

            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label label-item">
                E-mail
                <span class="text-danger" v-if="$_entityContact.is_financial">
                  &nbsp;*
                </span>
              </label>
              <div class="col-md-8">
                <validation-provider
                  :rules="$_entityContact.is_financial ? 'required' : ''"
                  v-slot="{ errors }"
                >
                  <input
                    type="email"
                    class="form-control form-control-sm"
                    v-model="$_entityContact.email"
                    :class="errors[0] ? 'is-invalid' : 'is-valid'"
                  />
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label label-item">
                Celular
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-8">
                <validation-provider
                  :rules="$_entityContact.is_financial ? 'required' : ''"
                  v-slot="{ errors }"
                >
                  <input
                    type="tel"
                    class="form-control form-control-sm"
                    v-model="$_entityContact.phone"
                    v-mask="['(##) # ####-####']"
                  />
                </validation-provider>
              </div>
            </div>

            <div class="modal-footer">
              <base-button
                type="secondary"
                @click="closeModal('create')"
                id="setting-close-button"
              >
                <span class="d-flex justify-content-center align-items-center"
                  ><img
                    src="/img/icons/cancel-red.png"
                    width="16px"
                    height="16px"
                    class="mr-2"
                  />
                  Cancelar</span
                >
              </base-button>
              <base-button
                style="width: 150px;"
                class="btn-sm btn-icon"
                id="setting-save-button"
                type="success"
                outline
                native-type="submit"
                :loading="loadingStore"
                :disabled="invalid"
                @click.prevent="updateContact"
              >
                <div class="font-weight-400" style="display: inline-flex; align-items: center">
                  <img
                    src="/img/icons/save-green.png" width="16px" height="16px" class="mr-2">
                  <i class="m-0 ml-1" style="font-style: normal"> SALVAR </i>
                </div>
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ToggleButton from '../../../../../components/ToggleButton';
const { formatErrorValidation } = require("@/plugins");

export default {
  name: "EditEntityContact",
  components: { ToggleButton },
  data() {
    return {
      modal: {
        title: "Novo Contato",
        create: false,
      },
      contacts: [],
      loadingStore: false,
    };
  },
  computed: {
    ...mapGetters({
      $_entityContact: "entityContact/fetch",
    }),
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    /**
     * @param {number} entityContactId
     */
    openModal(entityContactId) {
      this.$store
        .dispatch("entityContact/show", { id: entityContactId })
        .then((response) => {
          this.modal.create = true;
        });
    },
    updateContact() {
      this.$Progress.start();
      this.loadingStore = true;
      this.$store
        .dispatch("entityContact/update", this.$_entityContact)
        .then((response) => {
          this.modal.create = false;
          this.$emit("editedEntityContact", response.data);
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
        })
        .catch((error) => {
          if (error.status == 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
        })
        .finally(() => {
          this.$Progress.finish();
          this.loadingStore = false;
        });
    },
  },
};
</script>

<style scoped>
.label-title {
  color: #2B2D32;
  font-family: Fredoka;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.label-item {
  color: #2B2D32;
  font-family: Fredoka;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
