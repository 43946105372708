const baseVariationGaugeChart = {
  loading: true,
  // título e subtítulo
  title: {
    text: "Desvio Padrão (MPa)",
    left: "center",
    top: "1",
    subtext: "250",
    itemGap: 20,
    subtextStyle: {
      fontWeight: "normal",
      fontSize: 20,
      color: '#464646',
    },
  },
  series: [
    {
      // posição do gráfico
      center: ["50%", "63%"],
      type: "gauge",
      startAngle: 180,
      endAngle: 0,
      min: 0,
      max: 1,
      splitNumber: 8,
      axisLine: {
        lineStyle: {
          width: 6,
          // divisão de cores
          color: [
            [0.33, "#149e57"],
            [0.66, "#f2b532"],
            [1, "#db4539"],
          ],
        },
      },
      // seta que informa o valor
      pointer: {
        icon: "path://M12.8,0.7l12,40.1H0.7L12.8,0.7z",
        length: "12%",
        width: 20,
        offsetCenter: [0, "-40%"],
        itemStyle: {
          color: "auto",
        },
      },
      axisTick: {
        length: 12,
        lineStyle: {
          color: "auto",
          width: 2,
        },
      },
      splitLine: {
        length: 20,
        lineStyle: {
          color: "auto",
          width: 5,
        },
      },
      // label que mostrará o mínimo, médio e max
      axisLabel: {
        color: "#464646",
        fontSize: 20,
        distance: -65,
        padding: [0, -10, 0, -10],
        formatter: function (value) {
          return
        },
      },
      title: {
        offsetCenter: [0, "-20%"],
        fontSize: 30,
      },
      detail: {
        fontSize: 30,
        offsetCenter: [0, "0%"],
        valueAnimation: true,
        formatter: function (value) {
          return value.toFixed(2).toString().replace('.', ',');
        },
        color: "auto",
      },
      // dados
      data: [
        {
          value: 0,
        },
      ],
      divisible: [],
    },
  ],
};

export function handleVariationGaugeChart(payload) {
  // parâmetros para cores
  baseVariationGaugeChart.divisible = [payload.max, 0];
  let gaugeChart = baseVariationGaugeChart;
  // média
  gaugeChart.title.subtext = null;
  // valor principal
  gaugeChart.series[0].data[0] = {value: payload.value};
  // valor máximo
  gaugeChart.series[0].max = payload.max;
  gaugeChart.loading = false;
  return gaugeChart;
}
