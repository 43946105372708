const adjustment = {
  methods: {
    resetAll(adjustment) {
      adjustment.controls = 1;
      adjustment.color = 0;
      adjustment.binder = null;
      adjustment.water = null;
      adjustment.content_mortar = null;
      adjustment.total_air = null;
      adjustment.agent[0].value = null;
      adjustment.agent[0].id = null;
      adjustment.agent[1].value = null;
      adjustment.agent[1].id = null;
    },
  },
};
export default adjustment;
