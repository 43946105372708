import * as types from './mutation_types'
import { createAxios } from "@/plugins/axios";
const endPoint = '/cmc/price'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
    carrier_prices: [],
    cmc_prices: [],
  },
  getters: {
    show: state => state.item,
    getCmcPrice: state => state.cmc_prices,
    fetch: state => state.items,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload
    },
  },
  actions: {
    enabledOrDisabled({ commit, state }, payload) {
      return createAxios().post(`${endPoint}/enable-disable`, payload)
        .then(({ data }) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
