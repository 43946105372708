export const SET = 'SET'
export const SET_SEARCH = 'SET_SEARCH'
export const SET_MODAL_LIST = 'SET_MODAL_LIST'
export const PUSH = 'PUSH'
export const SHOW = 'SHOW'
export const SHOW_COUNT_USERS = 'SHOW_COUNT_USERS'
export const DESTROY = 'DESTROY'
export const UPDATE = 'UPDATE'
export const SET_DRIVER = 'SET_DRIVER'
export const SET_SELLER = 'SET_SELLER'
export const SET_FILTERABLE = 'SET_FILTERABLE'
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const SET_ACTIVE_SELLERS_SIMPLIFIED = 'SET_ACTIVE_SELLERS_SIMPLIFIED'
export const SET_ACTIVE_USERS_SIMPLIFIED = 'SET_ACTIVE_USERS_SIMPLIFIED'
export const SET_ACTIVE_SELLERS_ALLOCATED_BY_USER_ID = 'SET_ACTIVE_SELLERS_ALLOCATED_BY_USER_ID'
export const SET_ENTRY_DRIVERS = 'SET_ENTRY_DRIVERS'
export const SET_ALL_INTERMEDIARIES = 'SET_ALL_INTERMEDIARIES'
export const SET_USER_PLANTS = 'SET_USER_PLANTS'
