<template>
  <div>
    <modal :show.sync="modal" size="lg">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <multi-filter ref="contract_filter" @fetch="fetch" :filter="filter" :type="2" :status="null"/>
          <div class="container-fluid">
            <div class="row card-wrapper" v-show="loadingSkeleton">
              <SkeletonPuzlFullWidth v-for="index in 3" :key="index"></SkeletonPuzlFullWidth>
            </div>
            <PuzlEmptyData v-if="!entities.length && !loadingSkeleton"></PuzlEmptyData>
            <form class="needs-validation" autocomplete="off">
              <div
                v-if="entities.length && !loadingSkeleton"
                v-for="(entity, index) in entities"
                :key="index"
              >
                <div class="border-left border-3   p-2 mb-2"
                     :class="
                        entity.status
                        ? 'border-success'
                        : 'border-danger'
                      ">
                  <div class="row">
                    <div class="col-10">
                      <h5 class="h3 mb-0">{{ entity.entity_name }}</h5>
                      <h6 class="h3 mb-0 small">{{ entity.document }}</h6>
                    </div>
                  <div class="col-2 d-flex justify-content-center align-items-center">
                    <base-button size="sm" type="success" @click="handleSelect(entity)">
                      <i class="fa fa-check text-white"></i>
                    </base-button>
                  </div>
                  <div class="col-12 mt-2">
                    <div class="row">
                      <div class="col-10">
                        <div class="mb-2" style="padding-top: 15px;" @click.prevent="getDetailsEntity(index, entity.id)">
                          <collapse class="border rounded">
                            <collapse-item
                              name="1"
                              class="header-gray"
                              back-ground="border-header-primary-bottom"
                            >
                              <h5 slot="title" class="mb-0" :id="entity.id">Detalhes</h5>
                              <div v-show="entity.loading" class="row">
                                <SkeletonPuzlFullWidth/>
                              </div>
                              <div v-if="
                    entity.postal_code &&
                    entity.address &&
                    entity.number &&
                    entity.district"
                              >
                                <h5 slot="title" class="mb-0 text-dark">Endereço</h5>
                                <div>
                                  {{ entity.postal_code }}
                                </div>
                                <div>
                                  {{ entity.address }}, {{ entity.number }},
                                </div>
                                <div v-if="entity.complement">
                                  {{ entity.complement }},
                                </div>
                                <div>
                                  {{ entity.district }},
                                </div>
                                <div>
                                  {{ entity.state }} / {{ entity.city }}
                                </div>
                                <h5 v-if="entity.state_registration" slot="title" class="mb-0 mt-2 text-dark">Insc.
                                  Estadual</h5>
                                <div>{{ entity.state_registration }}</div>
                                <div
                                  v-if="entity.state_registration === 'Contribuinte Isento'
                      || entity.state_registration === 'Contribuinte'"
                                >
                                  <h5 slot="title" class="mb-0 mt-2 text-dark">N<sup>o</sup>. Contribuinte</h5>
                                  {{ entity.contributing_number }}
                                </div>
                                <div v-if="entity.email">
                                  <h5 slot="title" class="mb-0 mt-2 text-dark">E-mail</h5>
                                  <div class="text-lowercase">{{ entity.email }}</div>
                                </div>
                                <div v-if="entity.phone">
                                  <h5 slot="title" class="mb-0 mt-2 text-dark">Telefone</h5>
                                  <div>{{ entity.phone }}</div>
                                </div>
                              </div>
                            </collapse-item>
                          </collapse>
                        </div>

                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center m-3">
                <base-button type="secondary" @click="closeModal()"
                >Cancelar
                </base-button
                >
              </div>
            </form>
            <pagination @navegate="navegate" :source="source"/>
          </div>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import MultiFilter from "@/components/Utils/MultiFilter.vue";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import Pagination from "@/components/Utils/Pagination.vue";
import SkeletonPuzlFullWidth from '@/components/SkeletonPuzlFullWidth'

export default {
  components: {
    MultiFilter,
    PuzlEmptyData,
    SkeletonPuzlGrid,
    Pagination,
    SkeletonPuzlFullWidth
  },
  name: "ModalSearchContract",
  props: {
    not_in: {
      type: Array,
      default: () => [0, 5],
      required: false,
    },
  },
  data() {
    return {
      modal: false,
      title: "Buscar Cliente",
      detailsEntitiesOpen: [],
      validated: false,
      loadingSave: false,
      invalid: false,
      loadingSkeleton: false,
      with_push: false,
      filter: {
        status: null
      },
      entities: [],
      source: null,
      params: {
        page: 1,
        per_page: 3
      }
    };
  },
  computed: {
    ...mapGetters({
      plants: "plant/activeItems"
    })
  },
  methods: {
    close() {
      this.modal = false;
      this.$emit('close')
    },
    /**
     *
     * @param with_push
     * @returns {Promise<void>}
     */
    async openModal(with_push = true) {
      this.filter = {
        status: null
      };
      this.params = {
        page: 1,
        per_page: 3
      };
      this.with_push = with_push;
      this.$refs.contract_filter.clearFilters();
      this.contract_proposals = [];
      this.modal = true;
    },
    getDetailsEntity(indexEntity, id) {
      if (!this.entities[indexEntity].postal_code) {
        const found = this.detailsEntitiesOpen.find(item => id == item);
        if (!found) {
          this.detailsEntitiesOpen.push(id);
        }
        this.$set(this.entities[indexEntity], "loading", true);
        this.$store.dispatch('entity/getDetailsEntity', {id: id})
          .then((response) => {
            this.$set(this.entities[indexEntity], "postal_code", response.data.postal_code);
            this.$set(this.entities[indexEntity], "state", response.data.state);
            this.$set(this.entities[indexEntity], "address", response.data.address);
            this.$set(this.entities[indexEntity], "number", response.data.number);
            this.$set(this.entities[indexEntity], "district", response.data.district);
            this.$set(this.entities[indexEntity], "city", response.data.city);
            this.$set(this.entities[indexEntity], "state_registration", response.data.state_registration);
            this.$set(this.entities[indexEntity], "contributing_number", response.data.contributing_number);
            this.entities[indexEntity].loading = false;
          });
      }
    },
    fetch(filter = null) {
      if (filter) {
        this.filter = filter
        this.filter.status_not_in = this.not_in
        this.params.page = 1
      }
      this.$Progress.start();
      this.loadingSkeleton = true;
      this.params.filter = this.filter;

      this.$store
        .dispatch("entity/fetchItems", this.params).then(response => {
        this.entities = response.data;
        this.source = response;
        this.$Progress.finish();
        this.loadingSkeleton = false;
      });
    },
    navegate(page) {
      this.params.page = page;
      this.fetch();
    },
    handleSelect(entity) {
      this.modal = false;
      let loader = this.$loading.show();
      this.$emit("setEntity", {entity: entity});
      loader.hide();
    },
  }
};
</script>

<style></style>
