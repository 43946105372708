<template>
<div>
  <list-prospection-method></list-prospection-method>
  <modal-create-prospection-method ref="createProspectionMethod"></modal-create-prospection-method>
</div>
</template>
<script>
import ListProspectionMethod from './Shared/_List'
import ModalCreateProspectionMethod from './Shared/_Create'

export default {
  name: 'IndexProspectionMethod',
  components: {
    ListProspectionMethod,
    ModalCreateProspectionMethod
  },
  data () {
    return {
    }
  },
  methods: {
    handleCreate(){
      this.$refs.createProspectionMethod.handleCreateModal()
    },
  },
}

</script>
<style></style>
