import {
  AdjustmentHistoryListFilterType,
  AdjustmentHistoryListPagerType,
  AdjustmentHistoryCreateType
} from "../types";
import {
  adjustmentHistoryListService,
  adjustmentHistoryCreateService,
} from "../services";
import mutations from "./adjustmentHistoryStoreMutations";
import getters from "./adjustmentHistoryStoreGetters";
import {
  listResources,
  findResource,
  createResource
} from "../../../../shared/store";

/**
 * Incluir
 *
 * @param {AdjustmentHistoryCreateType} data
 * @returns {void}
 */
const create = async (data) =>
  await createResource(data, mutations, adjustmentHistoryCreateService);

/**
 * Listar
 *
 * @param {AdjustmentHistoryListFilterType} filter - Filtro de listagem
 * @param {boolean} isAccumulateItems - Incluir mais itens na lista existente
 * @returns {Promise<?AdjustmentHistoryListPagerType>} - Retorno paginado
 */
const list = async (filter, isAccumulateItems = false) => {
  return await listResources(
    filter,
    isAccumulateItems,
    mutations,
    getters,
    adjustmentHistoryListService
  );
};

/**
 * Localizar por ID
 *
 * @param {number|string} id
 * @returns {Promise<?CmcAdjustmentViewType>}
 */
const find = async (id) =>
  await findResource(id, mutations, cmcAdjustmentFindService);

export default {
  create,
  list,
  find,
};
