<template>
  <div>
    <modal :show.sync="modal.open">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
            <div>
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 col-form-label form-control-label">
                  Status
                </label>
                <div class="col-md-7 pt-2">
                  <base-switch v-model="concrete_piece.status" type="success" offText="inativo" onText="ativo"
                    class="success"></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-5 col-form-label form-control-label">
                  Permite Reuso
                </label>
                <div class="col-md-7 pt-2">
                  <base-switch v-model="concrete_piece.reuse" type="primary" offText="não" onText="sim"
                    class="success"></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 col-form-label form-control-label">
                  Puzl Connect
                </label>
                <div class="col-md-7 pt-2">
                  <base-switch v-model="concrete_piece.customer_puzl" type="primary" offText="não" onText="sim"
                    class="success"></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pb-0 col-form-label form-control-label">
                  Descrição
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-group-classes="input-group-sm">
                      <input type="text" v-model="concrete_piece.description" class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pb-0 col-form-label form-control-label">
                  Nível de Aplicação
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-group-classes="input-group-sm">
                      <puzl-select multiple v-model="concrete_piece.level_feature" :items="feature_levels"
                        customKey="value" label="label" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingSave">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "Create",
  components: { PuzlSelect },
  data() {
    return {
      modal: {
        title: 'Peça a concretar',
        open: false,
      },
      concrete_piece: {
        status: true,
        reuse: true,
        customer_puzl: false,
        description: null,
        level_feature: null,
      },
      loadingSave: false,
    }
  },
  computed: {
    ...mapGetters({
      'feature_levels': 'mixConcretePiece/feature_levels',
    }),
  },
  methods: {
    closeModal() {
      this.modal.open = false
    },
    handleCreateModal() {
      this.concrete_piece = {
        status: true,
        reuse: true,
        customer_puzl: false,
        description: null,
        level_feature: null,
      }
      this.modal.open = true
    },
    save() {
      this.$Progress.start()
      this.loadingSave = true
      this.$store.dispatch('mixConcretePiece/add', this.concrete_piece)
        .then(response => {
          this.loadingSave = false
          this.modal.open = false
          this.$Progress.finish()
          this.$notify({ type: response.error_type, message: response.message })
        })
        .catch(error => {
          if (error.response.status === 422) {
            let errors = Object.values(error.response.data.errors)
            errors = errors.flat().toString().split(',').join("<br />")
            this.$notify({ type: 'danger', message: errors })
          }
          this.$Progress.finish()
          this.loadingSave = false
        })
    },
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
  created() {
  }
}
</script>

<style scoped></style>
