import * as types from './mutation_types'
import { createAxios } from "@/plugins/axios";
import {destroy, update, cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'
import {order} from "../../../baseStore";
const endPoint = 'operational/schedule/schedule-travel/configuration/+microservice'
export default {
  namespaced: true,
  state: {
    item: {},
    fresh_states: [],
    hardened_states: [],
  },
  getters: {
    showFreshStates: state => state.fresh_states,
    showHardenedStates: state => state.hardened_states,
  },
  mutations: {
    [types.SET] (state, payload){
      state[payload.key] = payload.data
    },
    [types.PUSH] (state, payload){
      state[payload.key].push(payload.data)
    },
    [types.DESTROY] (state, params){
      const key = params.key || null
      destroy(state, params.uuid, key)
    },
    [types.UPDATE] (state, payload){
      const key = payload.key || null
      update(state, payload.payload, key)
    },
  },
  actions: {
    createWaterControl({commit, state}, params) {
      return createAxios().post(`${endPoint}water-control`, params)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    createFreshTest({commit, state}, params) {
      return createAxios().post(`${endPoint}fresh-test`, params)
        .then(({data}) =>{
          commit(types.PUSH, {data: data.data, key: 'fresh_states'})
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    createHardenedState({commit, state}, params) {
      return createAxios().post(`${endPoint}hardened-state`, params)
        .then(({data}) =>{
          commit(types.PUSH, {data: data.data, key: 'hardened_states'})
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    storeDefaultHardenedStates({commit, state}, params) {
      return createAxios().post(`${endPoint}hardened-state/default`, params)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchFreshStates({commit, state}, params) {
      return createAxios().get(`${endPoint}fresh-test`, {params: params})
        .then(({data}) =>{
          commit(types.SET, {data: data.data, key: 'fresh_states'})
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getProjections({commit, state}, params) {
      return createAxios().patch(`${endPoint}hardened-state/hardened-evolution/projections`, params)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchHardenedStates({commit, state}, params) {
      return createAxios().get(`${endPoint}hardened-state`, {params: params})
        .then(({data}) =>{
          commit(types.SET, {data: data.data, key: 'hardened_states'})
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchItemsPaginate({commit, state}, params) {
      return createAxios().get(setUrlWithNextPage(endPoint+'hardened-state', params),
        {params: params})
        .then(({data}) =>{
          cursorPaginate(state, {data : data.data, next_page: params.next_page}, 'hardened_states')
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    updateFreshState({commit, state}, params) {
      return createAxios().put(`${endPoint}fresh-test/${params.uuid}`, params)
        .then(({data}) =>{
          commit(types.UPDATE, {payload: data.data, key: 'fresh_states'})
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    updateHardenedState({commit, state}, params) {
      return createAxios().put(`${endPoint}hardened-state/${params.uuid}`, params)
        .then(({data}) =>{
          commit(types.UPDATE, {payload: data.data, key: 'hardened_states'})
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    deleteRemoveHardenedState({commit, state}, uuid) {
      return createAxios().delete(`${endPoint}hardened-state/${uuid}`,)
        .then(({data}) =>{
          commit(types.DESTROY, {uuid: uuid, key: 'hardened_states'})
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    deleteRemoveFreshState({commit, state}, uuid) {
      return createAxios().delete(`${endPoint}fresh-test/${uuid}`,)
        .then(({data}) =>{
          commit(types.DESTROY, {uuid: uuid, key: 'fresh_states'})
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
