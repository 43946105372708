<template>
  <div>
    <modal size="lg" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title ml-2">
          {{ modal.title }} 
          <span><div class="text-left h3 small" style="color: white;">{{ modal.detail }}</div></span> 
        </h5>
      </template>

      <div class="row pt-0 mx-3">
        <div class="col-md-12 p-1">
          <multi-filter-v2
            @clearFilters="clearFilters"
            @fetch="fetchItemsPaginate"
            :filter="filter"
            :status="multi_filter"
            ref="multi-filter"
          >
          </multi-filter-v2>          
        </div>
      </div>

      <div class="row pt-0 mx-3" style="margin-top: -1.4rem">
        <div class="col-md-12 p-1">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 table-responsive" style="overflow-y: scroll; max-height: 500px">
                  <table class="table table-sm table-bordered">
                    <thead>
                      <tr class="fixed">
                        <th class="text-center" style="width: 5px">UF</th>
                        <th class="text-left">Município</th>                        
                        <th class="text-center" style="width: 5px">Ação</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in params.per_page" v-show="isLoading">
                        <th colspan="5">
                          <skeleton-puzl type="button" />
                        </th>
                      </tr>
                      <tr v-show="!isLoading && construction_cities.length" v-for="item in construction_cities" style="">
                        <th class="text-center">
                          <span style="font-weight: normal"><span>{{ item.address_constructions_state }}</span></span>
                        </th>
                        <th class="text-left">
                          <span style="font-weight: normal"><span>{{ item.address_constructions_city }}</span></span>
                        </th>
                        <th class="text-center">
                          <button class="btn btn-sm btn-primary" @click="handleCreate(item.address_constructions_state, item.address_constructions_city)">+</button>                          
                        </th>
                      </tr>                      
                    </tbody>
                  </table>
                </div>
              </div>              
              <pagination @navegate="navegate" :source="source" :loading="isLoading" />
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal-create ref="ModalCreate"/>
  </div>
</template>

<script>
import MultiFilterV2 from "@/components/Utils/MultiFilterV2"
import Pagination from "@/components/Utils/Pagination"
import { SkeletonPuzl } from "@/components"
import ModalCreate from './_ModalCreate';
export default {
  name: "ModalConstructionCities",
  props: ["tax"],
  components: {
    MultiFilterV2,
    Pagination,
    SkeletonPuzl,
    ModalCreate,
   },
  data() {
    return {
      modal: {
        title: "LISTA DE MUNICÍPIOS",
        detail: "TRIBUTAÇÃO",
        create: false,
      },
      isLoading: false,
      params: {
        page: 1,
        per_page: 10,
      },
      construction_cities: [],
      source: null,
      filter: {},
      multi_filter: {
        null: {
          name: "Todos",
          type: "primary"
        },
        '0': {
          name: "Sem Cadastro",
          type: "danger"
        },
        '1': {
          name: "Com Cadastro",
          type: "success"
        },        
      },
    }
  },
  computed: {    
  },
  methods: {    
    close() {
      this.modal.create = false;
      this.$emit("close");
    },
    handleCreateModal() {
      this.modal.create = true;
      this.init();
    },
    init(){
      this.filter.status = '0'    
      this.$refs['multi-filter']._self.$forceUpdate()
      this.fetchItemsPaginate(this.filter)
    },
    fetchItemsPaginate(filter = {}) {
      this.isLoading = true
      this.filter = filter
      this.filter.has_commercial_tax_locality = this.filter.status

      this.$store
        .dispatch("FeesAndTaxes/getConstructionCitiesWithTaxFlagPaginate", {
          filter: this.filter,
          page: this.params.page,
        })
        .then((response) => {
          this.construction_cities = response.data
          this.source = response
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    navegate(page) {
      this.params.page = page
      this.fetchItemsPaginate()
    },
    clearFilters() {
      this.filter = {}
      this.init();
    },
    handleCreate(state, city){
      this.$refs.ModalCreate.handleCreateModal(this.tax, state, city)
    }
  },
};
</script>

<style scoped></style>
