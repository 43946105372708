<template>
  <div>
    <modal class="modal-mixe-resume" :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">{{ title }}</h5>
      </template>
      <div>
        <div class="p-3">
          <div class="p-2 border-left border-3 border-primary">
            <div>
              <div class="row">
                <div class="col-md-12">
                  <h5 class="h4 mb-0">{{ mix_design.title }}</h5>
                </div>
              </div>

              <div class="mt-2">
                <h5 class="small">{{ mix_design.body }}</h5>
              </div>
            </div>
          </div>

          <!--  ADIÇÃO PUZL-->
          <div v-if="mix_design.puzl_additions && mix_design.puzl_additions.length" class="mt-2 border-left border-3 border-indigo p-2">
            <div v-for="(puzl_addition, index) in mix_design.puzl_additions" :key="puzl_addition.id">
              <h5 class="text-indigo mb-0">
                {{ puzl_addition.name }}
                ({{ puzl_addition.volume }} kg/m<sup>3</sup>)
                <span class="text-indigo" v-if="index != mix_design.puzl_additions.length - 1">+</span>
              </h5>
            </div>
          </div>

          <div class="mt-2 border-left border-3 border-primary p-2">
            <div class="d-flex justify-content-between">
              <div>
                <span class="h4"> Preço de venda </span>
              </div>
              <div>
                <span class="h4"> {{ mix_design.average_selling_price | currency }} </span>
              </div>
            </div>
          </div>
        </div>

        <!-- REALIZADO -->
        <div class="col-12 p-0">
          <breadcrumb list-classes="bg-success border-header-success-bottom mb-0 ">
            <h5 class="mb-0">Realizado</h5>
          </breadcrumb>
        </div>
        <div class="p-3">
          <div class="border-left border-3 p-1 border-success mt-1">
            <div class="row d-flex justify-content-between">
              <div class="col-11">
                <span class="h5 small">Volume</span>
                <br />
                <span class="h5">{{ mix_design.total_volume }} m<sup>3</sup></span>
              </div>
              <div class="col-1 p-0 d-flex justify-content-end">&nbsp;</div>
            </div>

            <div class="row d-flex justify-content-between mt-1">
              <div class="col-11">
                <span class="h5 small">Faturamento</span>
                <br />
                <span class="h5">{{ mix_design.total_billing | currency() }}</span>
              </div>
            </div>

            <div class="row d-flex justify-content-between mt-1">
              <div class="col-11">
                <span class="h5 small">Adicionais</span>
                <br />
                <span class="h5">{{ mix_design.total_additional | currency() }}</span>
              </div>
            </div>

            <div class="row d-flex justify-content-between mt-1">
              <div class="col-11">
                <span class="h5 small">Volume Médio por BT</span>
                <br />
                <span class="h5">{{ mix_design.average_volume_per_bt.toFixed(1) }} m<sup>3</sup></span>
              </div>
            </div>

            <div class="row d-flex justify-content-between mt-1">
              <div class="col-11">
                <span class="h5 small">Ciclo de viagem</span>
                <br />
                <span class="h5">{{ mix_design.total_travel_cycle.toFixed(1) }} minutos</span>
              </div>
            </div>

            <div class="row d-flex justify-content-between mt-1">
              <div class="col-11">
                <span class="h5 small">MCC</span>
                <br />
                <span class="h5">{{ mix_design.total_theorical_mcc | currency() }} /m<sup>3</sup></span>
              </div>
            </div>

            <div class="row d-flex justify-content-between mt-1">
              <div class="col-11">
                <span class="h5 small">Margem bruta de serviço</span>
                <br />
                <span class="h5">{{ mix_design.total_theorical_margin | currency() }} /m<sup>3</sup></span>
              </div>
            </div>

            <div class="row d-flex justify-content-between mt-1">
              <div class="col-11">
                <span class="h5 small">Faturamento por hora</span>
                <br />
                <span class="h5">{{ mix_design.total_billing_per_hour | currency() }} /hr</span>
              </div>
            </div>

            <div class="row d-flex justify-content-between mt-1">
              <div class="col-11">
                <span class="h5 small">Adicionais por hora</span>
                <br />
                <span class="h5">{{ mix_design.total_additional_per_hour | currency() }}</span>
              </div>

              <div class="col-1 p-0 d-flex justify-content-end align-items-center">&nbsp;</div>
            </div>

            <div class="row d-flex justify-content-between mt-1">
              <div class="col-11">
                <span class="h5 small">Serviço por hora</span>
                <br />
                <span class="h5">{{ mix_design.service_per_hour | currency() }} /hr</span>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer justify-content-center">
          <base-button type="secondary" @click="closeModal()">Fechar</base-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  components: {},
  name: "ModalResumeMixe",
  data() {
    return {
      modal: false,
      title: "Resumo do traço",
      mix_design: {},
    };
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    openModal(uuid) {
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });
      this.$store.dispatch("formulations/getResume", uuid).then((response) => {
        this.mix_design = response.data;
        this.$notify({
          type: response.error_type,
          message: response.message,
        });
        this.modal = true;
      });
    },
  },
};
</script>

<style>
.modal-mixe-resume {
overflow-y: scroll !important;
}

</style>
