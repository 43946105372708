<template>
  <div>
    <AppFixedPageTitle
      title="TIPO"
      icon="/img/icons/physics-white.png"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          @onSearchClick="listItems"
          @onClearClick="clearFilter"
        />
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions
          :isBackButtonEnabled="true"
        >
          <AppPageHeaderActionsButton
            @click="openAdjustmentTypeCreateModal"
            text="novo"
            type="success"
            icon="/img/icons/plus-math--v1-white.png"
          />
          <AppPageHeaderActionsButton
            @click="openLimitSettingCreateModal"
            text="Limite"
            type="primary"
            icon="/img/icons/icons8/ios/resize-vertical_white.png"
          />
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>
    <AppTabSelect
      :items="tabSelectItems"
      :isShowViewTypes="false"
      @onTabSelectItemClick="onTabSelectItemClick"
      @onViewTypeChange="(type) => listType = type"
    />
    <div class="container-fluid">
      <AdjustmentTypeListPageCards
        @onCancelDosageAdjutmentClick="restoreAdjustmentValues"
        @onUpdateDosageAdjustmentClick="updateDosageAdjustmentValues"
        @onRemoveItemClick="removeAdjustmentType"
      />
      <AppViewTrigger v-if="!isListing" @onIntersected="listItems(true)" />
      <PuzlEmptyData v-if="!isListing && !listed.items.length"/>
    </div>
    <AdjustmentTypeCreateModal ref="adjustmentTypeCreateModal" />
    <LimitSettingCreateModal ref="limitSettingCreateModal"/>
  </div>
</template>

<script setup>
import { reactive, ref, computed, onMounted, onUnmounted, Ref } from 'vue';
import adjustmentTypeStore from "../../store";
import { 
  AppFixedPageTitle, 
  AppPageHeader, 
  initSearchBarFilterType,
  AppSearchBarFilterSection,
  SearchBarFilterType,
  AppSearchBar,
  AppViewTrigger,
  AppTabSelect,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  AppPageHeaderActionsButtonDropItem,
  AppPageHeaderActionsButtonSectionDivider,
  TabSelectItemType,
} from '../../../../../components/AppGlobal';
import {
  initAdjustmentTypeListFilterType,
  AdjustmentTypeListFilterType,
  AdjustmentTypeListType,
  AdjustmentTypeUpdateType,
  AdjustmentTypeViewType
} from "../../types";
import AdjustmentTypeFilterStatusEnum from "../../enums/AdjustmentTypeFilterStatusEnum";
import { useAdjustmentTypeHook } from "../../hooks/useAdjustmentTypeHook";
import AdjustmentTypeListPageCards from './AdjustmentTypeListPageCards.vue';
import PuzlEmptyData from "@/components/PuzlEmptyData";
import { dialogs, progress } from '../../../../../helpers';
import AdjustmentTypeCreateModal from "../create/AdjustmentTypeCreateModal.vue";
import { AdjustmentTypeCreateModalExpose } from "../create/AdjustmentTypeCreateModal.vue";
import LimitSettingCreateModal from "../limit-setting/LimitSettingCreateModal.vue";
/** HISTORY IMPORTS */
import { adjustmentTypeCreateHistoryService } from "../../services";
import AdjustmentHistoryActionsEnum from "../../../adjustment-history/enums/AdjustmentHistoryActionsEnum";

const { agentGroups } = useAdjustmentTypeHook();

/** Store Getters && Computeds */
const listed = computed(() => adjustmentTypeStore.getListed());
const isListing = computed(() => adjustmentTypeStore.getIsListing());

/** @type {Ref<AdjustmentTypeCreateModalExpose>} */
const adjustmentTypeCreateModal = ref(null);

/** @type {Ref<LimitSettingCreateModal>} */
const limitSettingCreateModal = ref(null);

const tabSelectItems = computed(() => {
  return [
    {
      id: null,
      name: 'Todos',
      selected: filter.status === null,
    },
    {
      id: AdjustmentTypeFilterStatusEnum.keys.WITH_ADJUSTMENT,
      name: 'Com Ajuste',
      selected: filter.status === AdjustmentTypeFilterStatusEnum.keys.WITH_ADJUSTMENT
    },
    {
      id: AdjustmentTypeFilterStatusEnum.keys.WITHOUT_ADJUSTMENT,
      name: 'Sem Ajuste',
      selected: filter.status === AdjustmentTypeFilterStatusEnum.keys.WITHOUT_ADJUSTMENT
    },
  ];
});

/** Reactives */
/** @type {SearchBarFilterType} - Filtro do AppSearchBar */
const searchBarFilter = reactive(initSearchBarFilterType());

/** @type {AdjustmentTypeListFilterType} - Filtro principal */
const filter = reactive(initAdjustmentTypeListFilterType());

const listType = ref('cards');

onMounted(() => {
  clearFilter(true, true);
});

onUnmounted(() => {
  searchBarFilter.custom_search_values = [];
  adjustmentTypeStore.resetStates();
  adjustmentTypeStore.setPageState({ filter, searchBarFilter });
});

/**
 * @param {TabSelectItemType} item
 */
function onTabSelectItemClick(item) {
  if (filter.status !== item.id) {
    filter.status = item.id;
    listItems();
  };
};

/**
 *
 * @param {boolean} isRefreshList - Atualiza a lista após limpar os filtros.
 * @param {boolean} withStoreFilters - Usa filtros da store se verdadeiro.
 */
function clearFilter(isRefreshList = true, withStoreFilters = false) {
  let searchBarFilterValue = defaultSearchBarFilter();
  let filterValue = defaultFilter();
  
  const storeFilters = withStoreFilters ? adjustmentTypeStore.getPageState() : null;
  if (storeFilters) {
    searchBarFilterValue = storeFilters.searchBarFilter;
    filterValue = storeFilters.filter;
  }

  Object.assign(searchBarFilter, searchBarFilterValue);
  Object.assign(filter, filterValue);
  
  if (isRefreshList) {
    listItems();
  }
};

/**
 * Padrão do filtro AppSearchBar
 * @returns {SearchBarFilterType}
 */
function defaultSearchBarFilter() {
  return {
    ...initSearchBarFilterType(),
  }
};

/**
 * Padrão do filtro principal
 * @returns {AdjustmentTypeListFilterType}
 */
function defaultFilter() {
  return {
    ...initAdjustmentTypeListFilterType(),
    status: null
  };
};

/**
 * Listar itens
 * @param {boolean} isAccumulateItems
 */
function listItems(isAccumulateItems = false) {
  prepareFilter();
  adjustmentTypeStore.list(filter, isAccumulateItems);
};

/**
 * Preparar filtro principal para listagem
 */
function prepareFilter() {
  filter.page = "";
  filter.custom_search.values = searchBarFilter.custom_search_values;
};

/**
 * 
 * @param {AdjustmentTypeListType} adjustment 
 */
async function restoreAdjustmentValues(adjustment) {
  progress.start();

  const oldAdjustment = await adjustmentTypeStore.find(adjustment.id);
  adjustment.binder = oldAdjustment.binder;
  adjustment.water = oldAdjustment.water;
  adjustment.content_mortar = oldAdjustment.content_mortar;
  adjustment.total_air = oldAdjustment.total_air;
  adjustment.agent = oldAdjustment.agent;
  adjustment.observation = oldAdjustment.observation;
  adjustment.has_adjustment = oldAdjustment.has_adjustment;
  adjustment.is_adjusting = false;

  progress.finish();
}

/**
 * 
 * @param {AdjustmentTypeUpdateType} data 
 */
async function updateDosageAdjustmentValues(data) {
  progress.start();

  try {
    await adjustmentTypeStore.update(data, data.id);

    /** @type {AdjustmentTypeViewType } */
    const typeAdjustment = await adjustmentTypeStore.find(data.id);
    const has_agent = await hasAgent(typeAdjustment.agent);

    adjustmentTypeCreateHistoryService.execute(
      AdjustmentHistoryActionsEnum.keys.ADJUST,
      typeAdjustment,
      has_agent ? agentGroups : null
    );

    dialogs.notify();
    listItems(true);

  } catch (error) {
    dialogs.notify(
      "danger",
      "Erro ao atualizar ajuste."
    );
  } finally {
    progress.finish();
  }
}

/**
 * Função que verifica se ajuste possui aditivo
 * 
 * @param {Array<AgentType>} agents
 * @returns {boolean}
 */
function hasAgent(agents) {
  return agents.some(item => item.id !== null);
}

/** 
 * Abrir modal para criar registro
 */
function openAdjustmentTypeCreateModal() {
  adjustmentTypeCreateModal.value.openModal();
}

/**
 * Abrir modal para ajuste de limite
 */
function openLimitSettingCreateModal() {
  limitSettingCreateModal.value.handleCreateModal();
}

/**
 * Remover registro
 * @param {number} id
 */
async function removeAdjustmentType(id) {
  const isConfirmed = await dialogs.confirmRemove();
  if (isConfirmed) {
    try {
      /** @type {AdjustmentTypeViewType } */
      const typeAdjustment = await adjustmentTypeStore.find(id);

      await adjustmentTypeStore.remove(id);
      dialogs.notifyRemoved();

      adjustmentTypeCreateHistoryService.execute(
        AdjustmentHistoryActionsEnum.keys.REMOVE,
        typeAdjustment,
      );

    } catch (error) {
      dialogs.notify(
        "danger",
        error.getFormatedError()
      );
    } finally {
      progress.finish();
    }
  }
};

</script>

<style scoped>
</style>