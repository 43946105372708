<template>
  <div>
    <div class="container-fluid">
      <div class="row pt-0" style="margin-top: -0.5rem">
        <div class="col-md-12 p-1">
          <multi-filter @fetch="init" :filter="{}" :without_filter="true">
            <div class="col-md-6 pl-2 pr-2 pb-2">
              <label>Périodo de entrada</label>
              <InputDatePicker
                size="small"
                :default-range="range"
                ref="datePicker"
                @handleFilterDate="handleFilterDate"
                :filterable="4" />
            </div>
            <div class="col-md-6 pl-2 pr-2 pb-2 select-lg">
              <label> Filtro de centrais </label>
              <base-input input-group-classes="input-group-sm">
                <puzl-select
                  class="select-lg"
                  v-model="filter.company_plant_ids"
                  :items="$_company_plants"
                  customKey="id"
                  @change="init"
                  multiple
                  placeholder="Centrais"
                />
              </base-input>
            </div>
          </multi-filter>
        </div>
      </div>
      <div class="row pt-0" style="margin-top: -1.4rem">
        <div class="col-md-12 p-1">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 table-responsive" style="overflow-y: scroll; max-height: 500px">
                  <table class="table table-sm table-bordered">
                    <thead>
                      <tr class="fixed">
                        <th class="text-center">Categoria</th>
                        <th class="text-left">MCC</th>
                        <th class="text-center">Unid. de compra</th>
                        <th class="text-center">Quantidade</th>
                        <th class="text-center">Ajustes</th>
                        <th class="text-center">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="loadingSkeleton">
                        <tr v-for="(item, skeletonIndex) in 5" :key="skeletonIndex">
                          <th colspan="7">
                            <skeleton-puzl type="button" />
                          </th>
                        </tr>
                      </template>
                      <template v-if="!loadingSkeleton && $_items.length">
                        <tr v-for="(item, index) in $_items" :key="index">
                          <td class="text-center">{{ item.category_name }}</td>
                          <td class="text-left">
                            <b>{{ item.product_name }}</b> {{ item.cmc_name }}
                          </td>
                          <td class="text-center">
                            {{ item.letter }}
                          </td>
                          <td class="text-center">
                            {{ item.quantity }}
                            <img @click.prevent="handleModalEntries(item)" role="button" class="ml-1" src="/img/icons/info.png" height="22px" />
                          </td>
                          <td class="text-center">
                            {{ item.puzl_adjust }}
                            <img @click.prevent="handleModalPuzlAdjust(item)" role="button" class="ml-1" src="/img/icons/info.png" height="22px" />
                          </td>
                          <td class="text-center">
                            {{ getTotalValue(item) }}
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                  <puzl-empty-data v-show="!$_items.length && !loadingSkeleton" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalEntries :filter="filter" ref="modalEntries" />
    <ModalPuzlAdjust :filter="filter" ref="modalPuzlAdjust" />
  </div>
</template>

<script>
import MultiFilter from "@/components/Utils/MultiFilterV2";
import InputDatePicker from "@/components/InputDatePicker";
import { mapGetters } from "vuex";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import PuzlSelect from "@/components/PuzlSelect";
import ModalEntries from "./_ModalEntries.vue";
import ModalPuzlAdjust from "./_ModalPuzlAdjust.vue";
import moment from 'moment';

export default {
  name: "ListResume",
  components: {
    PuzlEmptyData,
    SkeletonPuzl,
    InputDatePicker,
    MultiFilter,
    PuzlSelect,
    ModalEntries,
    ModalPuzlAdjust,
  },
  data() {
    return {
      loadingSkeleton: false,
      range: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        end: new Date(),
      },
      filter: {
        company_plant_ids: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      $_company_plants: "plant/activeItems",
      $_items: "operationalStockResume/entryResumeItems",
    }),
  },
  mounted() {
    this.$store.dispatch("plant/fetchItemsActive");
    this.init();
  },
  methods: {
    init() {
      this.filter.range = this.range;
      this.loadingSkeleton = true;
      this.$store.dispatch("operationalStockResume/getEntryResume", { filter: this.filter }).then(() => (this.loadingSkeleton = false));
    },
    /**
     * Verifica se o intervalo é maior que 31 dias.
     * @param {object} range
     * @returns {boolean}
     */
    checkDateRangeExceedsLimit(range) {
      let startDate = moment(range.start);
      let endDate = moment(range.end);
      if (endDate.diff(startDate, 'days') > 30) {
        // Seta data inicial da tela
        this.range = {
          start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
          end: new Date()
        };
        this.$refs.datePicker.resetDate();
        this.$notify({
          type: "warning",
          message: "O intervalo de datas não pode ser maior que 31 dias",
        });
        return false;
      }
      return true;
    },
    handleFilterDate(filter) {
      this.range = filter;
      if (!this.checkDateRangeExceedsLimit(this.range)) {
        return;
      }
      this.init({});
    },
    /**
     * Abre modal de entradas
     *
     * @param {object} product_service_hub
     * @returns void
     */
    handleModalEntries(product_service_hub) {
      this.$refs.modalEntries.openModal(product_service_hub);
    },

    /**
     * Abre modal de ajustes puzl
     *
     * @param {object} product_service_hub
     * @returns void
     */
    handleModalPuzlAdjust(product_service_hub) {
      this.$refs.modalPuzlAdjust.openModal(product_service_hub);
    },

    /**
     * Soma valor total
     *
     * @param {object} item
     *
     * @returns {string}
     */
    getTotalValue(item) {
      return (parseFloat(item.quantity) + parseFloat(item.puzl_adjust)).toFixed(2);
    },
  },
};
</script>
