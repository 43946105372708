<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div class="row mt-0 p-0 p-2">
        <label class="col-md-3 pb-1 mt-n1 col-form-label form-control-label">
          Vendedores
        </label>
        <div class="col-md-9">
          <base-input input-classes="form-control-sm">
            <puzl-select
                    v-model="view.users"
                    :items="[{id: 'all', name: 'Todos'}, ...users]"
                    :multiple="true"
                    :loading="loadingUsers"
                    :disabled="loadingUsers"
                     @input="setAll" />
          </base-input>
        </div>
      </div>
      <div class="modal-footer">
        <base-button type="secondary" @click="close()">
          Cancelar
        </base-button>
        <base-button :loading="loading" @click.prevent="save" type="success" native-type="submit">
          Salvar
        </base-button>
      </div>
    </modal>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import PuzlSelect from "@/components/PuzlSelect";
const {formatErrorValidation} = require("@/plugins")
export default {
  name: "ModalSetSellers",
  components: {
    SkeletonPuzl,
    PuzlSelect,
  },
  data() {
    return {
      modal: false,
      loading: false,
      title: 'Visualização de contratos',
      loadingUsers: false,
      view: {
        users: [],
        user_id: null
      },
    };
  },
  computed: {
    ...mapGetters({
      users: "user/filterable",
    })
  },
  methods: {
    close() {
      this.modal = false
    },
    openModal(user) {
      this.view.user_id = user.id
      this.view.users = []
      this.modal = true
      this.load(user.uuid)
    },
    save() {
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      // this.loading = true
      let user = {...this.view}
      // if (this.view.users.includes('all')) {
      //   user.users = this.users.map((item) => item.id)
      // }
      this.$store.dispatch("user/setViewSellers", user)
        .then(response => {
          this.close()
          this.$notify({ type: response.error_type, message: response.message });
          this.loading = false;
      }).catch(error => {
        this.loading = false;
      });
    },
    load(user_uuid) {
      this.loadingUsers = true;
      this.$store.dispatch("user/show", user_uuid).then(response => {
        if (response.data.view_sellers && response.data.view_sellers.length) {
          this.view.users = response.data.view_sellers
        }
        this.$store.dispatch("user/fetchItemsFilterable", {
          filter: {
            is_seller: 1
          }
        }).then(() => {
          this.loadingUsers = false;
        }).catch(error => {
          this.loadingUsers = false;
        });
      })
    },
    setAll() {
      const index = this.view.users.findIndex((item) => item == 'all');
      if (this.view.users.length > 1 && index === 0) {
        this.view.users.splice(0, 1)
      }
      if (this.view.users.length > 1 && this.view.users.includes("all")) {
        this.view.users = []
        this.view.users.push("all")
      }
    },
  },
  beforeUpdate() {
    // if (this.view.users.length === this.users.length) {
    //   this.view.users = [];
    //   this.view.users.push("all");
    // }
  },
}
</script>
