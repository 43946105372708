<template>
  <div>
    <base-header class="bg-gray-content pr-1 pl-1">
      <puzl-breadcrumb />
    </base-header>
    <List />
  </div>
</template>

<script>
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import List from "./Shared/_List";

export default {
  name: "Index",
  components: { PuzlBreadcrumb, List },
};
</script>
