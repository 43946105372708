import { BaseListFilterType, initBaseListFilterType, RangeValueType, RangeDateType } from "../../../../../shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} FinanceListFilterExtType
 * @property {0|1} mode - [0..1] Recebimento: 0 Pagamento: 1
 * @property {RangeValueType|null} range_amount - Intervalo entre valores
 * @property {Array<0|1>} in_finance_type_mode - Array contendo tipos de operações financeiras
 * @property {Array<0|1|2>} in_status - [0,1,2] Pendente, Finalizado e Cancelado
 * @property {RangeDateType|null} range_expire_or_discharge_at - Intervalo entre datas
 * @property {number|null} bank_account_id - ID da conta bancária
 * @property {number|null} payment_method_id - ID do método de pagamento
 * @property {number|null} finance_type_id - ID do tipo de operação financeira
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & FinanceListFilterExtType)} FinanceListFilterType
 */
export const FinanceListFilterType = {};

/**
 * Inicializar FinanceListFilterType
 *
 * @returns {FinanceListFilterType}
 */
export const initFinanceListFilterType = () => ({
  ...initBaseListFilterType(),
  order_by: [{ column: "finance_installments.id", is_desc: false }],
  custom_search: {
    columns: [
      "entities.entity_name",
      "entities.document",
      "finance_installments.document_number",
      "finance_installments.note",
    ],
    values: [],
  },
  mode: null,
  range_amount: {
    max: null,
    min: null,
  },
  in_finance_type_mode: [],
  in_status: [],
  range_expire_or_discharge_at: {
    start: null,
    end: null,
  },
  bank_account_id: null,
  payment_method_id: null,
  finance_type_id: null,
});
