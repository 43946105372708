<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">

            <!-- CAMPO MÓDULO -->
            <div class="form-group row m-0 p-0">
                <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                    Módulo
                    <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 mb-1 pr-4">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <PuzlSelect
                        v-model="tutorial.modules_id"
                        :items="$_modules"
                        :loading="loadingModules"
                        :disabled="loadingModules" />
                    </base-input>
                  </validation-provider>
                </div>
            </div>

            <!-- CAMPO TELA -->
            <div class="form-group row m-0 p-0">
                <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                    Tela
                    <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 mb-1 pr-4">
                    <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-classes="form-control-sm">
                            <input
                                type="text"
                                class="form-control form-control-sm"
                                v-model="tutorial.screen"
                                :class="errors[0] ? 'is-invalid' : 'is-valid'"
                            />
                        </base-input>
                    </validation-provider>
                </div>
            </div>

            <!-- CAMPO NAME -->
            <div class="form-group row m-0 p-0">
                <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                    Nome
                    <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 mb-1 pr-4">
                    <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-classes="form-control-sm">
                            <input
                                type="text"
                                class="form-control form-control-sm"
                                v-model="tutorial.name"
                                :class="errors[0] ? 'is-invalid' : 'is-valid'"
                            />
                        </base-input>
                    </validation-provider>
                </div>
            </div>

            <!-- CAMPO VíDEO -->
            <div class="form-group row m-0 p-0">
                <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                    Vídeo
                    <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 mb-1 pr-4">
                    <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-classes="form-control-sm">
                            <input
                                type="text"
                                class="form-control form-control-sm"
                                v-model="tutorial.video"
                                :class="errors[0] ? 'is-invalid' : 'is-valid'"
                            />
                        </base-input>
                    </validation-provider>
                </div>
            </div>

            <!-- CAMPO DESCRIÇÃO -->
            <div class="form-group row m-0 p-0">
                <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                    Descrição
                </label>
                <div class="col-md-8 mb-1 pr-4">
                    <base-input input-group-classes="input-group-sm">
                        <textarea type="text" rows="2" class="form-control form-control-sm" v-model="tutorial.description">
                        </textarea>
                    </base-input>
                </div>
            </div>

            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')">
                  Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                  Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import PuzlSelect from "@/components/PuzlSelect";
const { formatErrorValidation } = require("@/plugins");

export default {
    name: "ModalCreate",
    components: { PuzlSelect },
    data() {
        return {
          modal: {
                title: 'Cadastrar Vídeo de Tutorial',
                create: false,
          },
          tutorial: {
            status: true,
            screen: '',
            name: '',
            description: '',
            video: '',
            modules_id: null,
          },
          loadingStore: false,
          loadingModules: true,
        };
    },
    computed: {
        ...mapGetters({
            $_modules: 'module/fetch'
        }),
    },
    mounted() {
        this.$refs.formValidator.validate();
    },
    methods: {
        closeModal() {
            this.modal.create = false;
        },
        handleCreateModal() {
          this.loadData();
          this.tutorial = {
            status: true,
            screen: '',
            name: '',
            description: '',
            video: '',
            modules_id: null,
          },
          this.modal.create = true;
        },
        store() {
            this.$Progress.start();
            this.tutorial.status = this.tutorial.modules_id;
            this.loadingStore = true;
            this.$store.dispatch('tutorial/add', this.tutorial)
                .then((response) => {
                    this.$notify({
                        type: response.error_type,
                        message: response.message
                    });
                    this.closeModal();
                })
                .catch((error) => {
                    if (error.status === 200) {
                        this.$notify({
                            type: 'danger',
                            message: error.data.message
                        });
                    } else if (error.response.status === 422) {
                        let errors = formatErrorValidation(error.response.data.errors);
                        this.$notify({
                            type: 'danger',
                            message: errors
                        });
                    }
                }).finally(() => {
                    this.loadingStore = false;
                    this.$Progress.finish();
                });
        },
        loadData() {
            this.loadingModules = true;
            this.$store.dispatch('module/getModules').then(() => {
                this.loadingModules = false;
            });
        },
    },
};
</script>

<style scoped></style>
