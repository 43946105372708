<template>
  <SpinnerPuzl v-show="config.loading" />
</template>

<script>
import SpinnerPuzl from "@/components/SpinnerPuzl";

export default {
  name: "ScrollPagination",
  components: {
    SpinnerPuzl,
  },
  props: {
    config: {
      type: Object,
      default: () => ({
        loading: false,
        has_more: null,
        scrolled_current: false,
        items_length: 0,
      }),
      description: "Configurações gerais da paginação",
    },
  },
  methods: {
    onScroll() {
      const screenHeight = window.innerHeight;
      const scrolled = window.pageYOffset;
      const scrollHeight = document.body.scrollHeight;
      if (
        scrollHeight - (screenHeight + scrolled) < 5 &&
        this.config.has_more &&
        !this.config.scrolled_current &&
        this.config.items_length > 0
      ) {
        this.config.loading = true;
        this.$emit("nextPage", [], true);
      }
    },
  },
  created() {
    window.addEventListener("scroll", this.onScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>

<style></style>
