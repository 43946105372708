import { ProductServiceHubTypeListPagerType } from "../types";
import { BaseListFilterType } from "../../../../shared/types";
import {
  productServiceHubTypeListService,
} from "../services";
import mutations from "./productServiceHubTypeStoreMutations";
import getters from "./productServiceHubTypeStoreGetters";
import {
  listResources,
} from "../../../../shared/store";

/**
 * Listar
 *
 * @param {BaseListFilterType} filter - Filtro de listagem
 * @param {boolean} isAccumulateItems - Incluir mais itens na lista existente
 * @returns {Promise<?ProductServiceHubTypeListPagerType>} - Retorno paginado
 */
const list = async (filter, isAccumulateItems = false) => {
  return await listResources(
    filter,
    isAccumulateItems,
    mutations,
    getters,
    productServiceHubTypeListService
  );
};

export default {
  list,
};
