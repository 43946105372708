export const NfeIssuedesStatusEnum = Object.freeze({
  IN_PROGRESS: 0, // Emitindo
  AUTHORIZED: 1, // Autorizadas
  REJECTED: 2, // Rejeitadas
  IN_CANCELATION_PROCESS: 3, // Em processo de cancelamento
  CANCELLED: 4, //Cancelada
  DUPLICATED: 5, // Duplicada
  DENIED: 6, // Negada
  SENDING: 7, // Emitindo
  INTERNAL_ERROR: 97, // Erro Interno
  EVENT_ERROR: 99, // Erro de Evento
});
