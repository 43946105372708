<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(save)"
            autocomplete="off"
          >
            <div>
              <div class="form-group row m-0 p-0 mb-1">
                <div class="col-md-4">
                  <div class="col-form-label form-control-label">
                    Status
                  </div>
                  <base-switch
                    v-model="contact.status"
                    type="success"
                    offText="inativo"
                    onText="ativo"
                    class="success"
                  >
                  </base-switch>
                </div>

                <div class="col-md-4">
                  <div class="col-form-label form-control-label">
                    Usuário
                  </div>
                  <base-switch
                    v-model="contact.is_user"
                    type="success"
                    class="success"
                    ref="is_user"
                    name="is_user"
                  >
                  </base-switch>
                </div>

                <div class="col-md-4">
                  <div class="col-form-label form-control-label">
                    Administrador
                  </div>
                  <base-switch
                    v-model="contact.is_administrator"
                    type="success"
                    class="success"
                    :disabled="!contact.is_user"
                  >
                  </base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <div class="col-md-6">
                  <div class="pt-1 pb-0 col-form-label form-control-label">
                    Nome
                    <span class="text-danger">&nbsp;*</span>
                  </div>
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="contact.name"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>

                <div class="col-md-6">
                  <div class="pt-1 pb-0 col-form-label form-control-label">
                    E-mail
                    <span class="text-danger" v-if="contact.is_user">&nbsp;*</span>
                    <el-popover trigger="hover" placement="bottom" class=" mr-8 mt-n1 p-0 float-right pr-0">
                      <b>Financeiro :</b> envio de documentos | fatura, nota fiscal e boleto. <br>
                      <b>Técnico :</b> envio de laudos de resistência do concreto. <br>
                      <b>Comercial :</b> envio de propostas e contratos. <br>
                      <b>Operacional :</b> notificações de programação e carregamento. <br>
                      <base-button outiline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none">
                        <i style="font-size: 20px !important" class="fas fa-info-circle" />
                      </base-button>
                    </el-popover>
                  </div>
                  <validation-provider :rules="contact.is_user ? 'required|email' : 'email'" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="email"
                        v-model="contact.email"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <div class="col-md-3">
                  <div class="col-form-label form-control-label">
                    Financeiro
                  </div>
                  <base-switch
                    v-model="contact.is_financial"
                    type="success"
                    class="success"
                    offText="Não"
                    onText="Sim"
                  >
                  </base-switch>
                </div>
                <div class="col-md-3">
                  <div class="col-form-label form-control-label">
                    Técnico
                  </div>
                  <base-switch
                    v-model="contact.is_technical"
                    type="success"
                    class="success"
                    offText="Não"
                    onText="Sim"
                  >
                  </base-switch>
                </div>
                <div class="col-md-3">
                  <div class="col-form-label form-control-label">
                    Comercial
                  </div>
                  <base-switch
                    v-model="contact.is_commercial"
                    type="success"
                    class="success"
                    offText="Não"
                    onText="Sim"
                  >
                  </base-switch>
                </div>
                <div class="col-md-3">
                  <div class="col-form-label form-control-label">
                    Operacional
                  </div>
                  <base-switch
                    v-model="contact.is_operational"
                    type="success"
                    class="success"
                    offText="Não"
                    onText="Sim"
                  >
                  </base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <div class="col-md-6">
                  <div class="pt-1 pb-0 col-form-label form-control-label">
                    Setor
                  </div>
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      v-model="contact.sector"
                      class="form-control form-control-sm"
                    />
                  </base-input>
                </div>

                <div class="col-md-6">
                  <div class="pt-1 pb-0 col-form-label form-control-label">
                    Cargo
                  </div>
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      v-model="contact.office"
                      class="form-control form-control-sm"
                    />
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <div class="col-md-6">
                  <div class="pt-1 pb-0 col-form-label form-control-label">
                    Celular
                    <span class="text-danger">&nbsp;*</span>
                  </div>
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="contact.cell_phone"
                        class="form-control form-control-sm"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>

                <div class="col-md-6">
                  <div class="pt-1 pb-0 col-form-label form-control-label">
                    Telefone
                  </div>
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      v-model="contact.phone"
                      class="form-control form-control-sm"
                      v-mask="['(##) ####-####', '(##) #####-####']"
                    />
                  </base-input>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <base-button type="danger" @click="closeModal()">
                <img src="/img/icons/cancel-white.png" width="22px" height="22px" class="mr-2" />
                Cancelar
              </base-button>
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                :loading="loadingSave"
              >
                <img src="/img/icons/save.png" width="22px" height="22px" class="mr-2" />
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
const {formatErrorValidation} = require("@/plugins")
import axios from 'axios'

export default {
  name: "ModalCreateContact",
  data () {
    return {
      modal: false,
      title: 'Acesso / Contato',
      contact: {
        status: true,
        is_user: false,
        is_administrator: false,
        name: '',
        email: '',
        is_financial: false,
        is_technical: false,
        is_commercial: false,
        is_operational: false,
        sector: '',
        office: '',
        cell_phone: '',
        phone: '',
        construction_uuid: '',
      },
      loadingSave: false,
    }
  },
  methods: {
    closeModal () {
      this.modal = false
    },
    handleCreateModal (uuid) {
      this.contact = {
        status: true,
        is_user: false,
        is_administrator: false,
        name: '',
        email: '',
        is_financial: false,
        is_technical: false,
        is_commercial: false,
        is_operational: false,
        sector: '',
        office: '',
        cell_phone: '',
        phone: '',
        construction_uuid: uuid,
      }
      this.modal = true
    },
    save () {
      this.$Progress.start()
      this.loadingSave = true
      this.$store.dispatch('customerConstructionConstructionsContact/add', this.contact)
      .then(response => {
        this.loadingSave = false
        this.modal = false
        this.$emit('addContact', response.data.construction_uuid);
        this.$Progress.finish()
        this.$notify({ type: response.error_type, message: response.message })
      })
        .catch(error =>{
          if (error.status) {
            this.$Progress.finish()
            this.loadingSave = false
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            })
          } else {
            if (error.response.status === 422) {
              let message = formatErrorValidation(error.response.data.errors)
              this.$notify({
                type: 'danger',
                message: message
              })
              this.$Progress.finish()
              this.loadingSave = false
            } else {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              })
              this.$Progress.finish()
              this.loadingSave = false
            }
          }
        })
    },
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
}
</script>

<style scoped>

</style>
