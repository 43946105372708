<template>
  <div>
    <base-header class="bg-gray-content">
      <puzl-breadcrumb />

      <!-- Header Menus -->
      <div class="row mb-3">
        <div class="col-md-2 mb-2">
          <base-button @click.prevent="handleCreateModal" block size="lg" type="success" class="text-uppercase">
            <i class="fas fa-plus"></i> novo
          </base-button>
        </div>
        <div class="col-md-2 mb-3">
              <router-link :to="{ path:'/configuration/product-service'}">
                <base-button block size="lg" type="light" class="pb-3 pt-3 text-uppercase">
                  voltar
                </base-button>
              </router-link>
        </div>
      </div>
    </base-header>
    <ListProductServiceCategory />
    <CreateProductServiceCategory ref="modalCreate" />
  </div>
</template>

<script>
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import ListProductServiceCategory from "./Shared/_List";
import CreateProductServiceCategory from "./Shared/_ModalCreate";
export default {
  name: "IndexProductServiceCategory",
  components: { PuzlBreadcrumb, ListProductServiceCategory, CreateProductServiceCategory },
  methods: {
    handleCreateModal() {
      this.$refs.modalCreate.handleCreateModal();
    },
  },
};
</script>

<style></style>
