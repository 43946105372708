import * as types from './mutation_types'
import { createAxios } from "@/plugins/axios";
import { destroy } from '@/store/baseStore'
const endPoint = '/commercial/customer-construction/constructions/construction-attachment'

export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters:{
    show: state => state.items,
  },
  mutations: {
    [types.SHOW_ATTACHMENT] (state, payload){
      state.items = payload
    },
    [types.DESTROY_ATTACHMENT] (state, id){
      destroy(state,id)
    },
  },
  actions: {
    getByConstructionId({commit}, constructionId) {
      return createAxios().get(`${endPoint}/${constructionId}`)
        .then(({data}) => {
          commit(types.SHOW_ATTACHMENT, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getTotalAttachmentByConstructionId(_, constructionId) {
      return createAxios().get(`${endPoint}/total-attachments/${constructionId}`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    add(_, payload) {
      let formData = new FormData();
      payload.forEach(el => {
        formData.append(`files[${el.index}][file]`, el.file);
        formData.append(`files[${el.index}][construction_id]`, el.construction_id);
      });
      return createAxios().post(`${endPoint}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    destroy({commit}, id) {
      return createAxios().delete(`${endPoint}/${id}`)
        .then(({data}) =>{
          commit(types.DESTROY_ATTACHMENT, id)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
