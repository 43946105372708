import * as types from "./mutation_types";
import { destroy, cursorPaginate, setUrlWithNextPage, update } from "@/store/baseStore";
import { createAxios } from "@/plugins/axios";;
const endPoint = "/financial/bill-pay";
export default {
  namespaced: true,
  state: {
    items: [],
    item: {},
    history: [],
    agroup: [],
    resume: null,
  },
  getters: {
    fetch: (state) => state.items,
    show: (state) => state.item,
    history: (state) => state.history,
    agroup: (state) => state.agroup,
    getResume: (state) => state.resume,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload;
    },
    [types.CONCAT](state, payload) {
      state.items = [...state.items, ...payload];
    },
    [types.SHOW](state, payload) {
      state.item = payload;
    },
    [types.DESTROY](state, uuid) {
      destroy(state, uuid);
    },
    [types.UPDATE](state, payload) {
      update(state, payload);
    },
    [types.DESTROY_MANY](state, payload) {
      payload.map((item) => destroy(state, item.id));
    },
    [types.SET_HISTORY](state, payload) {
      state.history = payload;
    },
    [types.SET_AGROUP](state, payload) {
      state.agroup = payload;
    },
    [types.SET_RESUME](state, payload) {
      state.resume = payload;
    },
  },
  actions: {
    fetch({ state }, payload) {
      return createAxios()
        .get(setUrlWithNextPage(`${endPoint}-installment`, payload), { params: payload })
        .then(({ data }) => {
          cursorPaginate(state, { data: data.data, next_page: payload.next_page });
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    add({ commit }, payload) {
      return createAxios()
        .post(endPoint, payload)
        .then(({ data }) => {
          commit(types.CONCAT, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    update({ commit }, payload) {
      return createAxios()
        .post(`${endPoint}/${payload.data.get('bill_pay[id]')}`, payload.data)
        .then(({ data }) => {
          commit(types.DESTROY_MANY, payload.old_installments);
          commit(types.CONCAT, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    show({ commit }, payload) {
      return createAxios()
        .get(`${endPoint}/${payload}`)
        .then(({ data }) => {
          commit(types.SHOW, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    destroy({ commit }, id) {
      return createAxios()
        .delete(`${endPoint}-installment/${id}`)
        .then(({ data }) => {
          commit(types.DESTROY, id);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    changeInstallmentStatus({ commit }, payload) {
      return createAxios()
        .get(`${endPoint}-installment/${payload.id}/change-status/${payload.status}`, {params:payload})
        .then(({ data }) => {
          commit(types.UPDATE, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getByEntryLaunch({ commit }, payload) {
      return createAxios()
        .get(`${endPoint}-installment/entry-launch/${payload}`)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getHistory({ commit }, payload) {
      return createAxios()
        .get(`${endPoint}-installment/${payload}/history`)
        .then(({ data }) => {
          commit(types.SET_HISTORY, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getAgroup({ commit }, payload) {
      return createAxios()
        .get(`${endPoint}/${payload.bill_pay_id}/agroup/entity/${payload.entity_id}`, { params: payload })
        .then(({ data }) => {
          commit(types.SET_AGROUP, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getByBillPay({ commit }, payload) {
      return createAxios()
        .get(`${endPoint}-installment/bill-pay/${payload}`)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    agroup({ commit }, payload) {
      return createAxios()
        .post(`${endPoint}/agroup`, payload)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getBillPayExcelReport({_}, params) {
      return createAxios().get(endPoint + '/download-excel', {
        responseType: "blob",
        timeout: 60000,
        params: params
      })
      .then((response) => {
        const contentType = response.headers['content-type'];
        if (contentType && contentType.indexOf('application/json') !== -1) {
          return response.data.text().then(JSON.parse);
        }
        return response.data;
      })
      .catch(error => {
        if (error.response && error.response.data instanceof Blob) {
          return error.response.data.text().then(text => {
            try {
              return Promise.reject(JSON.parse(text));
            } catch (e) {
              return Promise.reject(text);
            }
          });
        }
        return Promise.reject(error);
      });
    },
    postPayPaymentAllotments({commit}, params) {
      return createAxios()
        .post(`financial/bill-pay-installment/allotments/set-payments`, params)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    postApprovePaymentAllotments({}, params) {
      return createAxios()
        .post(`financial/bill-pay-installment/allotments/set-payments-to-approved`, params)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getResume({commit}, payload) {
      return createAxios()
        .get(`${endPoint}/resume`, {params:payload} )
        .then(({ data }) => {
          commit(types.SET_RESUME, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getPaymentAuthorizationProof({}, payload) {
      return createAxios().get(`${endPoint}/payment-authorization-proof/${payload}`, {
        responseType: "blob",
        timeout: 30000,
      })
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getCompleteReport({}, payload) {
      return createAxios().get(`${endPoint}/complete-report`, {
        responseType: "blob",
        timeout: 60000,
        params: payload
      })
      .then((response) => {
        const contentType = response.headers['content-type'];
        if (contentType && contentType.indexOf('application/json') !== -1) {
          return response.data.text().then(JSON.parse);
        }
        return response.data;
      })
      .catch(error => {
        if (error.response && error.response.data instanceof Blob) {
          return error.response.data.text().then(text => {
            try {
              return Promise.reject(JSON.parse(text));
            } catch (e) {
              return Promise.reject(text);
            }
          });
        }
        return Promise.reject(error);
      });
    },
    /**
     * @param {integer} id id de contas a pagar
     */
    removeAgroup(_, id) {
      return createAxios().get(`${endPoint}/${id}/remove-agroup`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    shareInstallment(_, params) {
      return createAxios().post(`${endPoint}-installment/share-installment`, params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
     getNewReport({}, params) {
      return createAxios().get(`${endPoint}/new-report`, {
        responseType: "blob",
        timeout: 60000,
        params: params
      })
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  },
};
