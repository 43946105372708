<template>
  <div style="z-index: 10000000">
    <modal :show.sync="modal" style="z-index: 10000000">
      <template slot="header">
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
            <div>
              <div class="row">
                <div class="col-md-12">
                  <h5 class="h3 text-center pt-2"
                      :class="reference.reference_type==1 ? 'text-primary' : 'text-warning'">
                    <strong>{{ title }}</strong>
                  </h5>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-3 pb-0 col-form-label form-control-label">
                  Central
                </label>
                <div class="col-md-9">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <puzl-select
                        v-model="reference.company_plant_id"
                        :items="plants"
                        :disabled="loadingPlant"
                        :loading="loadingPlant"/>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0mb-1">
                <label class="col-md-3 pb-0 col-form-label form-control-label">
                  Grupo
                </label>
                <div class="col-md-9">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <puzl-select
                        v-model="reference.cmc_group_id"
                        :items="groups"
                        :disabled="loadingGroup"
                        :loading="loadingGroup"
                        :labelMask="true"
                        label="group_name$ ($cmc_categories_name$)"/>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="row" v-show="showCmc">
                <div class="col-md-12">
                  <h5 class="h3 text-center p-2 mt-2 text-white"
                      :class="reference.reference_type==1 ? 'bg-primary' : 'bg-warning'">
                    MCC
                  </h5>
                  <base-input input-group-classes="input-group-sm">
                    <el-select @change="save" :disabled="loading" v-show="!loadingCmc" v-model="reference.cmc_id" size="large"
                               placeholder="Selecione" filterable>
                      <el-option v-for="cmc in cmcs" :key="cmc.cmc_id" :label="cmc.product_name+
                                    ' - '+cmc.supplier_name+
                                    ' ('+cmc.origin_name+')  -  R$ '+
                                    cmc.price+
                                    ' / '+cmc.unit_buy" :value="cmc.cmc_id">
                      </el-option>

                    </el-select>
                  </base-input>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')">Fechar</base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "ModalReference",
  components: {PuzlSelect},
  data() {
    return {
      modal: false,
      title: '',
      listCmc: {},
      validated: false,
      old_cmc_id: null,
      reference: {
        cmc_id: null,
        cmc_group_id: null,
        company_plant_id: null,
        reference_type: null,
      },
      loadingCmc: false,
      loading: false,
      loadingGroup: true,
      loadingPlant: true,
      showCmc: false,
    };
  },
  computed: {
    ...mapGetters({
      'groups': 'cmcGroup/fetch',
      'plants': 'plant/fetch',
      'cmcs': 'cmc/getCmcPrice',
      'set_reference': 'mixReference/fetch',
      $_technical_permission: "technicalPermission/show",
      $_user: 'auth/getUser',
    }),
  },
  watch: {
    'reference.company_plant_id': function () {
      this.getCmc()
    },
    'reference.cmc_group_id': function () {
      this.getCmc()
    },
    'reference.cmc_id': function (val, oldVal) {
      this.old_cmc_id = oldVal
    }
  },
  methods: {
    closeModal() {
      if (this.$parent.hasOwnProperty('getWithoutReference')) {
        this.$parent.getWithoutReference()
      }
      this.reference.cmc_id = null;
      this.loading = false
      this.showCmc = false
      this.modal = false
    },
    openModal() {
      this.modal = true
    },
    async handleCreate(type, cmc_group_id = null, company_plant_id = null) {
      if (type === 1) {
        this.title = 'Referência Técnica';
      }
      if (type === 2) {
        this.title = 'Referência Comercial';
      }
      const has_permission = await this.fetchTechnicalPermissions(type)
      if (! has_permission) {
        return;
      }
      this.reference = {
        cmc_id: null,
        cmc_group_id: null,
        company_plant_id: null,
        reference_type: null,
      }
      this.reference = {
        cmc_id: null,
        cmc_group_id: cmc_group_id,
        company_plant_id: company_plant_id,
        reference_type: type,
      }
      await this.$store.dispatch('cmcGroup/fetchGroupsSimplified').then(() => this.loadingGroup = false)
      this.getCmc()
      this.$forceUpdate()
      this.loading = false
      this.loadingCmc = false
      this.openModal();
    },
    fetchTechnicalPermissions(type) {
      return this.$store.dispatch('technicalPermission/getByUserId', this.$_user.id)
        .then((response) => {
          if (type === 1 && !response.data.is_change_technical_references_of_mcc_groups) {
            this.$notify({type: 'danger', message: 'Usuário não possui permissão para alterar referência técnica'});
            return false
          }
          if (type === 2 && !response.data.is_cmc_group_business_references_modified) {
            this.$notify({
              type: 'danger',
              message: 'Usuário não possui permissão para alterar referência comercial'
            });
            return false
          }
          return true
        })
    },
    save() {
      if (!this.reference.cmc_id || this.loading) {
        return
      }
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      });
      this.$Progress.start();
      this.loading = true;
      this.$axios.post("mix/reference", this.reference)
        .then(({data}) => {
          this.$notify({
            type: data.error_type,
            message: data.message
          });
          this.$emit('submit')
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          if (this.old_cmc_id) {
            this.$set(this.reference, 'cmc_id', this.old_cmc_id)
          }
          this.$notify({
            type: error.data.error_type,
            message: error.data.exception
          });
          this.loading = false;
        });
    },
    getCmc() {
      this.loadingCmc = true
      this.reference.cmc_id = '';
      let plantId = this.reference.company_plant_id;
      let groupId = this.reference.cmc_group_id;
      let reference_type = this.reference.reference_type
      if (plantId !== null && groupId !== null) {
        this.$Progress.start();
        this.$store.dispatch('cmc/fetchPrice', {
          plantId: plantId,
          groupId: groupId,
          reference_type: reference_type,
          filter: { with_active_cmc_and_price : true },
        })
          .then(({data}) => {
            if (this.cmcs.length) {
              this.showCmc = true;
            }
            this.getReference();
            this.loadingCmc = false
            this.$Progress.finish();
          })
          .catch((error) => {
            this.loadingCmc = false
          });
      }
    },
    getReference() {
      let plantId = this.reference.company_plant_id;
      let groupId = this.reference.cmc_group_id;
      let type = this.reference.reference_type;
      if (plantId !== null && groupId !== null) {
        this.$Progress.start();
        this.$store.dispatch('mixReference/fetchByParams', {
          plantId: plantId,
          groupId: groupId,
          type: type
        })
          .then((data) => {
            if (data.data.length) {
              this.reference.cmc_id = data.data[0].cmc_id
            }
            this.$Progress.finish();
          })
          .catch((error) => {
            this.$Progress.finish();
          });
      }
    },
  },
  mounted() {
    this.$refs.formValidator.validate();
    this.$store.dispatch('plant/fetchItems').then(() => this.loadingPlant = false)
  }
};
</script>

<style>
.el-select-dropdown {
  z-index: 10000000008888 !important;
}
</style>
