<template>
  <div>
    <modal :show.sync="modal.create" size="md">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(confirm)" autocomplete="off">
            <div>
              <!-- Central -->
              <div class="form-group row m-1 p-0">
                <label class="col-md-5 pt-2 pb-0 mb-1 col-form-label form-control-label"
                  >Central <span class="text-danger">&nbsp;*</span></label
                >
                <div class="col-md-7">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <el-select
                        @change="getCompanyPlantBuyers"
                        v-model="form.company_plant_id"
                        size="mini"
                        placeholder="Selecione"
                        filterable
                        :disabled="!company_plants.length"
                      >
                        <el-option
                          v-for="company_plant in company_plants"
                          :key="company_plant.id"
                          :label="company_plant.name"
                          :value="company_plant.id"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <!-- CNPJ Comprador -->
              <div class="form-group row m-1 p-0">
                <label class="col-md-5 pb-0 pt-2 mb-1 col-form-label form-control-label">
                  CNPJ Comprador <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <el-select
                        v-model="form.company_plant_buyer_id"
                        size="mini"
                        placeholder="Selecione"
                        filterable
                        :disabled="loadingCompanyPlantBuyer"
                      >
                        <el-option
                          v-for="company_plant_buyer in company_plant_buyers"
                          :key="company_plant_buyer.id"
                          :label="company_plant_buyer.issuer.ein"
                          :value="company_plant_buyer.id"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="modal-footer d-flex justify-content-center">
                <base-button type="secondary" @click="closeModal('create')"> Cancelar </base-button>
                <base-button type="success" native-type="submit" v-bind:disabled="invalid || loadingCompanyPlantBuyer"> Confirmar </base-button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      modal: {
        title: "Consulta Sefaz",
        create: false,
      },
      loadingCompanyPlantBuyer: true,
      company_plant_buyers: [],
      form: {
        type: null,
        company_plant_id: null,
        company_plant_buyer_id: null,
      },
      no_reload : false,
    };
  },
  computed: {
    ...mapGetters({
      company_plants: "plant/activeItems",
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal(no_reload = false) {
      this.no_reload = no_reload;
      this.loadingCompanyPlantBuyer = false;
      if(this.company_plants.length === 1 && !this.form.company_plant_id) {
        this.form.company_plant_id = this.company_plants[0].id;
        this.getCompanyPlantBuyers();
      } 
      this.modal.create = true;
    },
    confirm() {
      if(!this.no_reload) {
        this.$router.push(`/configuration/entry-launch/external/${this.form.company_plant_buyer_id}`)
      } else {
        this.$emit('selected',this.form.company_plant_buyer_id);
        this.closeModal();
      }
    },
    getCompanyPlantBuyers() {
      this.loadingCompanyPlantBuyer = true;
      this.$store.dispatch("companyPlantBuyer/getByCompanyPlant", this.form.company_plant_id).then((response) => {
        this.company_plant_buyers = response.data || [];
        if (this.company_plant_buyers.length) {
          let default_company_plant_buyer =
            this.company_plant_buyers.filter((item) => item.default === 1)[0] || this.company_plant_buyers[0];
          this.form.company_plant_buyer_id = default_company_plant_buyer.id;
        } else {
          this.form.company_plant_buyer_id = null;
        }
        this.loadingCompanyPlantBuyer = false;
      });
    },
  },
};
</script>

<style></style>
