<template>
<div>
  <div class="container-fluid">

    <div class="row card-wrapper" v-show="loadingSkeleton">
      <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
    </div>

    <div class="row align-items-center pb-0 mb-3" v-if="contract && !loadingSkeleton">
      <div class="col-md-12">
        <div class="checklist-item checklist-item-primary">
          <div class="checklist-info">
            <h1 class="checklist-title mb-0">
              {{contract.name}}
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div class="row align-items-center pb-0" v-if="contract && !loadingSkeleton">
      <div class="col-md-12">
        <div class="card p-2">
          <base-input placeholder="Pesquisar">
            <template slot="append">
              <i class="fas fa-search"></i>
            </template>
          </base-input>
        </div>
      </div>
    </div>
  <div v-if="contract.pages && !loadingSkeleton">
    <div class="row align-items-center"  v-if="!contract.pages.length && !loadingSkeleton">
      <div class="col-md-12 text-center">
        <div class="card p-4">
          Sem dados cadastrados!
        </div>
      </div>
    </div>
    <div class="row card-wrapper" v-if="contract.pages.length && !loadingSkeleton">
      <div
        class="col-lg-4"
        v-for="(page, indexPage) in contract.pages"
      >
        <!-- Basic with action button -->
        <card :class="contract.status ? 'card-border-top-success' : 'card-border-top-danger'">
          <!-- Card header -->
          <div class="row align-items-center mb-3">
            <div class="col-8">
              <!-- Title -->
              <h5 class="h3 mb-0">
                <badge type="default">v.{{page.version}}</badge>
              </h5>
            </div>

            <div class="col-4 text-right">
              <base-dropdown menuOnRight>
                <base-button
                  slot="title-container"
                  type="primary"
                  class="dropdown-toggle p-2 rounded m-0"
                >
                  <i class="fas fa-cog"></i>
                </base-button>
                <a class="dropdown-item" :href="download(page.uuid)" target="_blank">
                  <i class="fas fa-file text-primary"></i>
                  Visualizar
                </a>
                <a class="dropdown-item"
                   v-show="isDisabledHandleEdit(page.date_validity)"
                   @click.prevent="handleEdit(page.uuid)"
                >
                  <i class="fas fa-edit text-warning"></i>
                  Editar
                </a>
                <div v-show="isDisabledHandleEdit(page.date_validity)" class="dropdown-divider p-0 m-0"></div>
                <a class="dropdown-item"
                   v-show="isDisabledHandleEdit(page.date_validity)"
                   @click.prevent="handleDelete(page.uuid)"
                >
                  <i class="fas fa-times text-danger"></i>
                  Excluir
                </a>
              </base-dropdown>
            </div>
          </div>
          <!-- Card body -->
          <h5 class="h4 small">Início da Vigência: {{page.date_validity | format_date}}</h5>
          <h5>{{ page.user }} - <small>{{ page.updated_at }}</small></h5>
        </card>
      </div>
    </div>
  </div>
  </div>
  <Create ref="createSituation" @fetchContract="fetch" />
  <Edit ref="editContractPage" @fetchContract="fetch" />
</div>
</template>

<script>
import {mapGetters} from 'vuex'
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import Create from './_Create'
import Edit from './_Edit'
import moment from 'moment'
export default {
  name: "List",
  components: {
    SkeletonPuzlGrid,
    Create,
    Edit
  },
  data () {
    return {
      loadingSkeleton: false,
      url: process.env.VUE_APP_BASE_URI
    }
  },
  computed: {
    ...mapGetters({
      'contract': 'contractContracts/show',
    }),
  },
  filters: {
    format_date: function (value) {
      if (!value) return ''
      return moment(value).format('DD-MM-YYYY HH:mm')
    },
  },
  methods:{
    isDisabledHandleEdit(date){
      moment.locale('pt-br')
      const now = moment().format('YYYY-MM-DD HH:mm')
      const date_validity = moment(date).format('YYYY-MM-DD HH:mm')
      return moment(now).isBefore(date_validity)
    },
    download(uuid) {
      let baseURL = process.env.VUE_APP_BASE_URI;
      if(!baseURL){
        let host = window.location.host
        let subdomain = host.split('.')[0]
        baseURL = `https://${subdomain}.puzl.place/api/v1/`;
      }
      return baseURL + `contract/${uuid}`
    },
    handleCreate(){
      this.$refs.createSituation.handleCreateModal(this.$route.params.contract_uuid)
    },
    handleEdit(uuid){
      this.$refs.editContractPage.handleEditModal(uuid)
    },
    handleDelete(uuid) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          this.$store.dispatch('contractContractsPages/destroy', uuid)
          this.$store.dispatch('contractContracts/show', this.$route.params.contract_uuid)
          this.$Progress.finish();
        }
      }).catch(() => this.$Progress.finish())
    },
    fetch () {
      this.loadingSkeleton = true
      this.$store.dispatch('contractContracts/show', this.$route.params.contract_uuid)
        .then( response =>{
          this.loadingSkeleton = false
        })
        .catch( error => {
          this.loadingSkeleton = false
        })
    },
  },
  mounted() {
    this.fetch()
  },
}
</script>

<style scoped>

</style>
