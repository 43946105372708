export function verifyDocument(document) {
    //Retira caracteres especiais
    document = document.replace(/[/.-]/g,"")
    //Verifica se a variável é uma string
    if (typeof(document) != 'string') {
      return false
    }
    return verifyNumber(document)
  }
  //Valide se a string contem apenas números
  function verifyNumber(document) {
    if (isNaN(document)) {
      return false
    }
    return verifyCategory(document)
  }
  //Validar tipo de entrada
  function verifyCategory(document) {
    if (document.length === 11) {
      return validCpf(document)
    }
    if (document.length === 14) {
      return validCnpj(document)
    }
    return false
  }
  const validCpf = function(cpf) {
    //Elimina CPFs invalidos conhecidos
    if (!cpf || cpf.length != 11
      || cpf == "00000000000"
      || cpf == "11111111111"
      || cpf == "22222222222"
      || cpf == "33333333333"
      || cpf == "44444444444"
      || cpf == "55555555555"
      || cpf == "66666666666"
      || cpf == "77777777777"
      || cpf == "88888888888"
      || cpf == "99999999999") {
      return false
    }
    //Valida primeiro Dig Verificador
    let sum = 0
    let mod = 0
    //Soma dos digitos
    for (var i = 1; i <= 9; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i)
    }
    //Calculo do resto
    mod = (sum * 10) % 11
    if ((mod == 10) || (mod == 11)) {
      mod = 0
    }
    if (mod != parseInt(cpf.substring(9, 10))) {
      return false
    }
    //Valida segundo Dig Verificador
    sum = 0
    mod = 0
    //Soma dos digitos
    for (var i = 1; i <= 10; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i)
    }
    //Calculo do resto
    mod = (sum * 10) % 11
    if ((mod == 10) || (mod == 11)) {
      mod = 0
    }
    if (mod != parseInt(cpf.substring(10, 11))) {
      return false
    } else {
      return true
    }
  }
  const validCnpj= function(cnpj) {
    //Elimina CNPJs invalidos conhecidos
    if (!cnpj || cnpj.length != 14
      || cnpj == "00000000000000"
      || cnpj == "11111111111111"
      || cnpj == "22222222222222"
      || cnpj == "33333333333333"
      || cnpj == "44444444444444"
      || cnpj == "55555555555555"
      || cnpj == "66666666666666"
      || cnpj == "77777777777777"
      || cnpj == "88888888888888"
      || cnpj == "99999999999999")
      return false
    //Valida primeiro digito Verificador
    let size = cnpj.length - 2
    let number = cnpj.substring(0, size)
    let digits = cnpj.substring(size)
    let sum = 0
    let pos = size - 7
    //Soma dos digitos e calculo de posiçao
    for (var i = size; i >= 1; i--) {
      sum += number.charAt(size - i) * pos--
      if (pos < 2) pos = 9
    }
    // Cálculo validador 1
    let result = sum % 11 < 2 ? 0 : 11 - sum % 11
    if (result != digits.charAt(0)) {
      return false
    }
    //Valida segundo digito verificador
    size = size + 1
    number = cnpj.substring(0, size)
    sum = 0
    pos = size - 7
    //Soma dos digitos e calculo de posiçao
    for (var i = size; i >= 1; i--) {
      sum += number.charAt(size - i) * pos--
      if (pos < 2) pos = 9
    }
    result = sum % 11 < 2 ? 0 : 11 - sum % 11
    // Cálculo validador 2
    if (result != digits.charAt(1)) {
      return false
    } else {
      return true
    }
  }