<template>
  <modal v-if="report" :show.sync="modal" size="sm">
    <template slot="header">
      <h5 class="modal-title ">{{ title }}</h5>
    </template>
    <div class="row">
      <div class="col-12">
        <validation-observer
          v-slot="{ invalid }"
          ref="formValidator"
        >
          <form @submit.prevent="save()">
          <div class="form-group row m-0 p-0 mb-1">
            <label
              class="col-md-12 pb-0 pt-1 col-form-label form-control-label"
            >
              Moldador
            </label>
            <div class="col-md-12">
              <validation-provider rules="required">
                <puzl-select
                  placeholder="Selecionar moldador"
                  v-model="report.molder_user_id"
                  :items="users"
                  :loading="loadingUsers"
                  :disabled="loadingUsers"/>
              </validation-provider>
            </div>
          </div>
          <div class="form-group row m-0 p-0 mb-1">
            <label
              class="col-md-12 pb-0 pt-1 col-form-label form-control-label"
            >
              Coleta
            </label>
            <div class="col-md-12">
              <validation-provider rules="required">
                <base-input input-group-classes="input-group-sm">
                  <input-date-time-picker v-model="report.date_at" :mode="'date'"/>

                  <!--                <el-date-picker-->
                  <!--                  :disabled="loadingUsers"-->
                  <!--                  @change="teste(report)"-->
                  <!--                  v-model="report.collect"-->
                  <!--                  size="mini"-->
                  <!--                  type="datetime"-->
                  <!--                  format="dd/MM/yyyy HH:mm"-->
                  <!--                  value-format="yyyy-MM-dd HH:mm:ss"-->
                  <!--                  block-->
                  <!--                  :picker-options="pickerOptions"-->
                  <!--                >-->
                  <!--                </el-date-picker>-->
                </base-input>
              </validation-provider>
            </div>
          </div>
          <div class="form-group row m-0 p-0 mb-1">
            <label
              class="col-md-12 pb-0 pt-1 col-form-label form-control-label"
            >
              Responsável
            </label>
            <div class="col-md-12">
              <validation-provider rules="required">
                <puzl-select
                  placeholder="Selecionar responsável"
                  v-model="report.user_id"
                  :items="users"
                  :loading="loadingUsers"
                  :disabled="loadingUsers"/>
              </validation-provider>
            </div>
          </div>
          <div class="form-group row m-0 p-0 mb-1">
            <label
              class="col-md-12 pb-0 pt-1 col-form-label form-control-label"
            >
              Observações
            </label>
            <div class="col-md-12">
              <validation-provider rules="required">
                <base-input input-group-classes="input-group-sm">
                        <textarea
                          @input="teste2(report)"
                          v-model="report.observation"
                          type="text"
                          rows="4"
                          class="form-control form-control-sm">
                          </textarea>
                </base-input>
              </validation-provider>
            </div>
          </div>
          <div class="modal-footer pr-4">
            <base-button type="secondary" @click="closeModal('create')">
              Fechar
            </base-button>
            <base-button type="success" native-type="submit" :loading="loadingStore">
              <i class="fa-regular fa-floppy-disk"></i> Salvar e fechar
            </base-button>
          </div>
          </form>
        </validation-observer>
      </div>
    </div>
  </modal>
</template>

<script>
import {debounce} from "@/plugins";
import {mapGetters} from "vuex";
import InputDateTimePicker from "@/components/InputDateTimePicker.vue";
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "ModalInfo",
  components: {
    InputDateTimePicker,
    PuzlSelect
  },
  data() {
    return {
      modal: false,
      title: 'Moldagem e coleta',
      report: null,
      loadingUsers: false,
      loadingStore: false,
      pickerOptions: {
        selectableRange: this.range,
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      users: "user/fetch",
    })
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    async save() {
      let params = {
        molder_user_id: this.report.molder_user_id,
        observation: this.report.observation,
        date_at: this.report.date_at,
        user_id: this.report.user_id,
        schedule_travel_id: this.report.schedule_travel_id,
        schedule_travel_uuid: this.report.travel_uuid
      }
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.loadingStore = true
      await this.$store.dispatch('scheduleTravelMolding/store', params)
      await this.$store.dispatch("testReport/add", params).then(response => {
        this.$notify({type: 'success', message: response.message});
        this.closeModal()
      });
      this.loadingStore = false
    },
    handleCreateModal(report) {
      this.$store.dispatch("user/fetchItems").then(() => {
        this.loadingUsers = false;
      }).catch(() => this.loadingUsers = false);
      this.report = report
      this.modal = true
    },
  }
}
</script>

<style scoped>

</style>
