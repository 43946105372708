const keys = Object.freeze({
  WITH_ADJUSTMENT: 1,
  WITHOUT_ADJUSTMENT: 2,
});

const getTitle = (value) => {
  switch (value) {
    case keys.WITH_ADJUSTMENT:
      return "Com ajuste";
    case keys.WITHOUT_ADJUSTMENT:
      return "Sem ajuste";
    default:
      return "";
  }
};

export default {
  keys,
  getTitle,
};
