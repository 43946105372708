<template>
  <div class="header-card" v-bind="$attrs">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "HeaderCard",
  inheritAttrs: false
};
</script>

<style scoped>
div.header-card {
  border-radius: 8px;
  height: 43px;
  padding: 12px;
  border: 1px solid #e8e8e8;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

div.header-card.active {
  background-color: #e8e8e8;
}
</style>
