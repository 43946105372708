<template>
  <div>
    <modal :show.sync="modal" size="lg">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div class="p-3">
        <form
          class="needs-validation"
          autocomplete="off"
        >
          <div>
            <div class="row align-items-center pb-0 mt-3">
              <div class="col-md-12">
                <div class="card">
                  <base-input placeholder="Pesquisar">
                    <template slot="append">
                      <i class="fas fa-search"></i>
                    </template>
                  </base-input>
                </div>
              </div>
            </div>
          </div>

          <PuzlEmptyData
            v-if="!services.length && !loadingSkeleton"
          ></PuzlEmptyData>

          <div
            v-if="services.length && !loadingSkeleton"
            class="border-left border-3 border-primary p-2 mt-2 d-flex justify-content-between"
            v-for="(service, indexService) in services"
            :key="indexService"
          >
            <div class="col-11">
              <h5 class="h3 mb-1">
                {{ service.service.name }}
              </h5>
              <h5 class="h3 mb-1 small">
                {{ service.service.description }}
              </h5>
            </div>

            <div class="col-1 d-flex justify-content-center align-items-center">
              <base-button
                @click.prevent="save(service)"
                block
                size="sm"
                type="success"
              >
                <i
                  class="fa fa-check text-white"
                ></i>
              </base-button>
            </div>
          </div>

          <div class="d-flex justify-content-center m-3">
            <div class="pr-3">
              <base-button type="secondary" @click="closeModal()"
                >Cancelar</base-button
              >
            </div>
          </div>
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PuzlEmptyData from "@/components/PuzlEmptyData";

export default {
  components: {
    PuzlEmptyData
  },
  computed: {
    ...mapGetters({
      services: "contractService/fetch"
    })
  },
  name: "ModalAddService",
  data() {
    return {
      modal: false,
      title: "Incluir Serviço",
      loadingSkeleton: true
    };
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    openModal(contract_uuid) {
      this.$Progress.start();
      this.$store
        .dispatch("contractService/fetchItems", {uuid : contract_uuid, filter: { status:1}})
        .then(response => {
            this.services.map(function(obj) {
            obj.selected = false;
            obj.volume = null;
            obj.start_at = null;
            obj.end_at = null;
            obj.time = null;
          });
          this.$Progress.finish();
          this.loadingSkeleton = false;
          this.modal = true;
          this.$forceUpdate();
        });
    },
    save(service) {
      service.status = 0;
      this.$emit("setServices", service);
      this.modal = false;
    },
    changeState(index) {
      this.services[index].selected = !this.services[index].selected;
      this.$forceUpdate();
      this.save();
    }
  },
  created() {}
};
</script>

<style></style>
