<template>
  <div>
    <modal size="md" :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">{{ title }}</h5>
      </template>
      <div>
        <div v-if="!$_history.length && modal" class="mt-3">
          <PuzlEmptyData />
        </div>
        <div class="row table-responsive" style="max-height: 80vh">
          <div class="col-md-12">
            <div v-for="item in $_history" :key="item.id">
              <template>
                <div class="border-left border-3 p-1 mb-2" :class="`border-${bill_pay_types[item.status].color}`">
                  <h4 :class="`text-${bill_pay_types[item.status].color}`">
                    {{ bill_pay_types[item.status].text }}
                    <span v-if="item.paid_at" class="text-default font-weight-normal small numeric"> | {{ item.paid_at | parseDate('DD MMM YYYY') }} </span>
                  </h4>
                  <div v-show="item.observation" class="text-left h3 mt-n1 small">{{ item.observation }}</div>
                  <div class="text-left h3 small">{{ item.name }} | {{ item.created_at | parseDate }}</div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import billPayMixin from "../Mixins/bill_pay_mixin";

export default {
  components: { PuzlEmptyData },
  name: "ModalHistory",
  mixins:[billPayMixin],
  data() {
    return {
      modal: false,
      title: "Histórico de alterações",
    };
  },
  computed: {
    ...mapGetters({
      $_history: "billPay/history",
    }),
  },
  methods: {
    close() {
      this.modal = false;
    },
    openModal(id) {
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });
      this.$store
        .dispatch("billPay/getHistory", id)
        .then((response) => {
          this.$notify({ type: response.error_type, message: response.message });
          this.modal = true;
        })
        .catch((error) => {});
    },
  },
};
</script>

<style></style>
