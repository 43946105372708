<template>
  <div>
    <modal
      :show.sync="modal.create"
      size="xl"
    >
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <div class="row align-items-center">
        <!--        <div class="col-12 mt-n3">-->
        <!--          <ul-->
        <!--            class="list-group list-group-flush"-->
        <!--            data-toggle="checklist"-->
        <!--          >-->
        <!--            <li class="list-group-item px-0">-->
        <!--              &lt;!&ndash; Data, valor e Descrição da Transação &ndash;&gt;-->
        <!--              <div-->
        <!--                class="checklist-item"-->
        <!--                :class="selected.reconciled == 1 ? 'checklist-item checklist-item-success' : 'checklist-item checklist-item-warning'"-->
        <!--              >-->
        <!--                <div class="col-12 checklist-info pl-0 p-0">-->
        <!--                  <div class="row">-->
        <!--                    <div class="col-6">-->
        <!--                      <h4 class="font-weight-500">-->
        <!--                        {{ selected.date_at | parseDate('DD/MM/YYYY - ddd') }}-->
        <!--                      </h4>-->
        <!--                    </div>-->
        <!--                    <div class="col-12 mt-n2">-->
        <!--                      <h4 class="mt-n1 font-weight-400">-->
        <!--                        {{ selected.description }}-->
        <!--                      </h4>-->
        <!--                    </div>-->
        <!--                    <div-->
        <!--                      class="col-12 mt-n3">-->
        <!--                      <h2>-->
        <!--                        <small :class="selected.value >= 0 ? 'text-success' : 'text-danger'"-->
        <!--                               class="numeric font-weight-600">-->
        <!--                          {{ selected.value | currency }}-->
        <!--                        </small>-->
        <!--                      </h2>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </li>-->
        <!--          </ul>-->
        <!--        </div>-->
        <div class="col-md-12 mt-n2">
          <card>
            <div class="row show-mobile">
              <div class="col-md-4 p-1">
                <input-date-picker
                  :disabled="loading"
                  @handleFilterDate="handleFilterDate"
                  size="small"
                  ref="date-picker" :default-range="range"/>
              </div>
              <div class="col-md-4 p-1">
                <base-input input-group-classes="input-group-sm">
                  <puzl-select :disabled="loading"
                               placeholder="Método de pagamento" customKey="uuid" v-model="filter.payment_method_uuid"
                               :items="$_payment_methods"/>
                </base-input>
              </div>
              <div class="col-md-12 text-center">
                <span class="display-4 numeric" style="font-size: 0.9rem">Até</span>
              </div>
              <div class="col p-1">
                <base-input input-group-classes="input-group-sm">
                  <input
                    :disabled="loading"
                    type="text"
                    inputmode="numeric"
                    v-money="money"
                    v-model="filter.min_money_range"
                    class="form-control form-control-sm "
                  />
                  <template slot="prepend">
                    <small class="input-group-sm p-0 m-0 numeric"> R$ </small>
                  </template>
                </base-input>
              </div>
              <div class="col p-1">
                <base-input input-group-classes="input-group-sm">
                  <input
                    :disabled="loading"
                    type="text"
                    inputmode="numeric"
                    v-money="money"
                    v-model="filter.max_money_range"
                    class="form-control form-control-sm "
                  />
                  <template slot="prepend">
                    <small class="input-group-sm p-0 m-0 numeric"> R$ </small>
                  </template>
                </base-input>
              </div>
              <div class="col-md-12 p-1">
                <base-button
                  size="sm"
                  style="height: 30px"
                  @click.prevent="clearFilters"
                  outline
                  title="Limpar filtros"
                  block
                  type="light"
                >
                  <i class="fas fa-1x fa-eraser"></i>
                </base-button>
              </div>
            </div>
            <div class="row show-md" style="margin-top: -20px;margin-bottom: -10px">
              <div class="col-6">
              </div>
              <div class="col-4 text-center">
                <span class="display-4 numeric" style="font-size: 0.9rem">Até</span>
              </div>
              <div class="col-md-3 p-1">
                <input-date-picker
                  :disabled="loading"
                  @handleFilterDate="handleFilterDate"
                  size="md"
                  ref="date-picker" :default-range="range"/>
              </div>
              <div class="col-md-3 p-1">
                <base-input input-group-classes="input-group-sm">
                  <puzl-select :disabled="loading"
                               class="select-xl"
                               @change="handleAdvancedFilter"
                               placeholder="Método de pagamento" customKey="uuid" v-model="filter.payment_method_uuid"
                               :items="$_payment_methods"/>
                </base-input>
              </div>
              <div class="col-md-2 p-1">
                <base-input input-group-classes="input-group-sm">
                  <input
                    style="height: 40px !important;"
                    :disabled="loading"
                    type="text"
                    @input="handleFilterMoney"
                    inputmode="numeric"
                    v-model="filter.min_money_range"
                    class="form-control form-control-sm "
                  />
                  <template slot="prepend">
                    <small class="input-group-sm p-0 m-0 numeric"> R$ </small>
                  </template>
                </base-input>
              </div>
              <div class="col-md-2 p-1">
                <base-input input-group-classes="input-group-sm">
                  <input
                    style="height: 40px !important;"
                    :disabled="loading"
                    type="text"
                    @input="handleFilterMoney"
                    inputmode="numeric"
                    v-model="filter.max_money_range"
                    class="form-control form-control-sm "
                  />
                  <template slot="prepend">
                    <small class="input-group-sm p-0 m-0 numeric"> R$ </small>
                  </template>
                </base-input>
              </div>
              <div class="col-md-2 p-1">
                <base-button
                  size="md"
                  @click.prevent="clearFilters"
                  outline
                  title="Limpar filtros"
                  block
                  type="light"
                >
                  <i class="fas fa-1x fa-eraser"></i>
                </base-button>
              </div>
            </div>
          </card>
          <card style="overflow: auto;margin-top: -20px">
            <div class="row" v-if="!loadingSkeleton && $_to_associate.length" style="margin-top: 15px;max-height: 40vh">
              <div class="col-md-4 p-1 card-row" v-for="(item, index) in $_to_associate" :key="index">
                <card style="min-height: 158px" :style="selecteds.includes(item.id) && 'border: 0.5px solid green'">
                  <div class="row pl-2 pr-0 pt-0 mt-0">
                    <div class="pl-1 col-9">
                      <div>
                        <h4 v-if="item.name" class="font-weight-400" style="font-size: 0.9rem">{{ item.name }}</h4>
                        <h4 v-else class="font-weight-400 text-muted" style="font-size: 0.9rem">
                          <img style="width: 22px" src="/img/icons/not_identified.png"/>
                          DEPOSITO NÃO IDENTIFICADO</h4>
                      </div>
                      <div style="margin-top: -10px">
                        <span style="font-size: 0.9rem" class="numeric">{{ item.document }}</span>
                      </div>
                    </div>
                    <div v-if="type === 1" class="col-3 text-center">
                      <base-button
                        v-if="!loading || !selecteds.includes(item.i)"
                        :disabled="loading"
                        class="btn-square"
                        @click.prevent="pushToAssociate(item.id, item.type)" size="sm"
                        :class="selecteds.includes(item.i) && 'active'"
                        outline type="primary">
                        <i class="fa-regular fa-circle-check"></i>
                      </base-button>
                      <base-button
                        v-else
                        disabled
                        class="btn-square"
                        @click.prevent="pushToAssociate(item.id, item.type)" size="sm"
                        :class="selecteds.includes(item.i) && 'active'"
                        type="primary">
                        <i class="fa-regular fa-circle-check text-white"></i>
                      </base-button>
                    </div>
                    <div v-if="type === 0" class="col-3 text-center">
                      <base-button
                        v-if="!loading || !selecteds.includes(item.id)"
                        :disabled="loading"
                        class="btn-square"
                        @click.prevent="pushToAssociate(item.id)" size="sm"
                        :class="selecteds.includes(item.id) && 'active'"
                        outline type="primary">
                        <i class="fa-regular fa-circle-check"></i>
                      </base-button>
                      <base-button
                        v-else
                        disabled
                        class="btn-square"
                        @click.prevent="pushToAssociate(item.id)" size="sm"
                        :class="selecteds.includes(item.id) && 'active'"
                        type="primary">
                        <i class="fa-regular fa-circle-check text-white"></i>
                      </base-button>
                    </div>
                    <div class="col-md-12 pl-1 mt-n2">
                      <h2>
                        <small
                          class="numeric font-weight-600 text-warning">
                          {{ item.value | currency() }} <span style="font-size: 0.9rem"
                                                              class="font-weight-300 numeric text-grey2">({{
                            item.index
                          }}/{{ item.total_index }})</span>
                        </small>
                      </h2>
                      <div class="row">
                      </div>
                      <h3 class="mt-n3">
                        <small
                          class="numeric font-weight-400">
                          Data de vencimento: {{ item.due_date | parseDate('DD/MM/YYYY') }}
                        </small>
                        <a class="float-right pr-2" v-show="item.exists_attachments" @click.prevent="handleShowAttachments(item.id, item.type)" href="#">
                          <i style="font-size: 1.5rem !important" class="text-green fa-solid fa-paperclip"></i>
                        </a>
                      </h3>
                    </div>
<!--                    <div class="col-8 text-right" v-if="[0,1].includes(item.type)">-->
<!--                      <badge v-if="item.type === 0" class="text-white" type="indigo">Antecipação</badge>-->
<!--                      <badge v-if="item.type === 1" class="text-white" type="green" style="background: #149e57 !important">Contas a receber</badge>-->
<!--                    </div>-->
                    <div class="col-md-12 pl-1 mt-n3">
                      <collapse :id="index" class="border rounded p-0 mt-3">
                        <collapse-item class="header-gray" back-ground="border-header-primary-bottom">
                          <h5 slot="title" class="mb-0 ">Ajuste de Juros/Multa</h5>
                          <div class="row">
                            <div class="col-md-12">
                              <div v-if="type === 1" class="form-group row m-0 p-0 mb-1">
                                <label
                                  class="col-md-12 pt-2 pb-0 col-form-label form-control-label"
                                >
                                  Descontos
                                </label>
                                <div class="col-md-12">
                                  <base-input input-group-classes="input-group-sm">
                                    <input
                                      @keydown="$event.key === '-' ? $event.preventDefault() : null"
                                      maxlength="13"
                                      @blur="calcFinalValue(item, index)"
                                      v-money="money"
                                      v-model.lazy="item.discount_editable"
                                      inputmode="numeric"
                                      class="form-control form-control-sm"
                                    />
                                    <template slot="prepend">
                                      <small class="input-group-sm p-0 m-0">
                                        R$
                                      </small>
                                    </template>
                                  </base-input>
                                </div>
                              </div>
                              <div class="form-group row m-0 p-0 mb-1">
                                <label
                                  class="col-md-12 pt-2 pb-0 col-form-label form-control-label"
                                >
                                  {{ type === 1 ? 'Taxas' : 'Descontos/Taxas' }}
                                </label>
                                <div class="col-md-12">
                                  <base-input input-group-classes="input-group-sm">
                                    <input
                                      @keydown="$event.key === '-' ? $event.preventDefault() : null"
                                      maxlength="13"
                                      @blur="calcFinalValue(item, index)"
                                      v-money="money"
                                      v-model.lazy="item.discount_fees_editable"
                                      inputmode="numeric"
                                      class="form-control form-control-sm"
                                    />
                                    <template slot="prepend">
                                      <small class="input-group-sm p-0 m-0">
                                        R$
                                      </small>
                                    </template>
                                  </base-input>
                                </div>
                              </div>
                              <div class="form-group row m-0 p-0 mb-1 ">
                                <label
                                  class="col-md-12 pt-2 pb-0 col-form-label form-control-label"
                                >
                                  Juros/Multa
                                </label>
                                <div class="col-md-12">
                                  <base-input input-group-classes="input-group-sm">
                                    <input
                                      @keydown="$event.key === '-' ? $event.preventDefault() : null"
                                      ref="feesInput"
                                      @blur="calcFinalValue(item, index)"
                                      v-money="money"
                                      v-model.lazy="item.fees_editable"
                                      maxlength="13"
                                      inputmode="numeric"
                                      class="form-control form-control-sm"
                                    />
                                    <template slot="prepend">
                                      <small class="input-group-sm p-0 m-0">
                                        R$
                                      </small>
                                    </template>
                                  </base-input>
                                </div>
                              </div>
                              <div class="form-group row m-0 p-0 mb-1 ">
                                <label
                                  class="col-md-12 pt-2 pb-0 col-form-label form-control-label"
                                >
                                  Valor final
                                  <span class="text-danger">&nbsp;*</span>
                                </label>
                                <div class="col-md-12">
                                  <base-input input-group-classes="input-group-sm">
                                    <input
                                      disabled
                                      v-money="money"
                                      v-model.lazy="item.final_value"
                                      maxlength="13"
                                      inputmode="numeric"
                                      type="text"
                                      class="form-control form-control-sm"
                                    />
                                    <template slot="prepend">
                                      <small class="input-group-sm p-0 m-0">
                                        R$
                                      </small>
                                    </template>
                                  </base-input>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 pt-2">
                              <div class="form-group row m-0 p-0 mb-1">
                                <div class="col-md-12 text-right">
                                  <base-button
                                    @click.prevent="clearAdjustment(item, index)"
                                    type="light"
                                    native-type="submit"
                                    :disabled="loadingSaveAdjustment"
                                  >
                                    <i class="fas fa-1x fa-eraser"></i>
                                  </base-button>
                                  <base-button
                                    @click.prevent="saveAdjustment(item, index)"
                                    type="success"
                                    native-type="submit"
                                    title="Salvar"
                                    :disabled="loadingSaveAdjustment"
                                    class="btn-custom"
                                   >
                                    <span v-if="loadingSaveAdjustment" class="btn-label span-custom" ><i class="fas fa-spinner text-white fa-spin icon-custom"></i></span>
                                    <span v-else class="btn-label span-custom"><i class="fas fa-save text-white icon-custom"></i></span>
                                    Salvar
                                  </base-button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </collapse-item>
                      </collapse>
                    </div>
                  </div>
                </card>
              </div>
            </div>
            <div class="row p-0" v-show="loadingSkeleton" style="max-height: 300px">
              <skeleton-puzl-grid v-for="item in 6" classCard="col-md-4 p-1 card-row"/>
            </div>
            <div class="row p-0" v-if="!loadingSkeleton && !$_to_associate.length">
              <div class="col-md-12">
                <puzl-empty-data :with-card="false"/>
              </div>
            </div>
          </card>
        </div>
      </div>
      <div class="row mt-n3 show-md" v-if="selected">
        <div v-if="!(Number(selected.value) === Number(selectedSum()))" class="col-1 text-right">
          <i class="fa-2x fa-solid fa-scale-unbalanced-flip" style="color: #d16161"></i>
        </div>
        <div v-else class="col-1 text-right">
          <i class="fa-solid fa-scale-balanced fa-2x text-success"></i>
        </div>
        <div class="col-4 mt-n2 text-left">
          <h3 class="font-weight-400">
            <small>
              {{ selected.date_at | parseDate('DD/MM/YYYY - ddd') }}
            </small>
          </h3>
          <h3 :title="selected.description" class="font-weight-400 mt-n3 text-truncate">
            <small>
              {{ selected.description }}
            </small>
          </h3>
          <h2 class="mt-n3">
            <small class="numeric font-weight-600"
                   :class="selected.value > 0 ? 'text-success' : 'text-danger'">{{
                selected.value | currency()
              }}</small>
          </h2>
          <h2 class="mt-n4">
            <small class="numeric font-weight-600 text-grey2"> <span class="numeric font-weight-600"
                                                                     :class="(Number(selected.value) === Number(selectedSum())) ? 'text-success' : 'text-grey2'">
              {{ selectedSum() | currency() }}</span>
              ({{ selecteds.length }})</small>
          </h2>
        </div>
        <div class="col-md-7 text-right" style="padding-top: 24px">
          <base-button @click.prevent="close" type="secondary">
            Fechar
          </base-button>
          <base-button
            :disabled="Number(selected.value).toFixed(2) !== Number(selectedSum()).toFixed(2)"
            @click.prevent="save"
            :loading="loading"
            type="primary"
            native-type="submit"
          >
            Associar selecionados
          </base-button>
        </div>
      </div>
      <div class="row mt-n2 show-mobile" v-if="selected">
        <div v-if="!(Number(selected.value) === Number(selectedSum()))" class="col-2 text-right">
          <i class="fa-2x fa-solid fa-scale-unbalanced-flip" style="color: #d16161"></i>
        </div>
        <div v-else class="col-2 text-right">
          <i class="fa-solid fa-scale-balanced fa-2x text-success"></i>
        </div>
        <div class="col-10 mt-n2 text-left">
          <h3 class="font-weight-400">
            <small>
              {{ selected.date_at | parseDate('DD/MM/YYYY - ddd') }}
            </small>
          </h3>
          <h3 class="font-weight-400 mt-n3">
            <small>
              {{ selected.description }}
            </small>
          </h3>
          <h2 class="mt-n3">
            <small class="numeric font-weight-600"
                   :class="selected.value > 0 ? 'text-success' : 'text-danger'">{{
                selected.value | currency()
              }}</small>
          </h2>
          <h2 class="mt-n4">
            <small class="numeric font-weight-600 text-grey2">{{ selectedSum() | currency() }}
              ({{ selecteds.length }})</small>
          </h2>
        </div>
        <div class="col-md-7 text-right">
          <base-button @click.prevent="close" type="secondary">
            Fechar
          </base-button>
          <base-button
            @click.prevent="save"
            type="primary"
            :loading="loading"
            native-type="submit"
          >
            Associar selecionados
          </base-button>
        </div>
      </div>
      <ModalAttachment ref="modalAttachment"/>
    </modal>
  </div>
</template>

<script>
import MultiFilter from "@/components/Utils/MultiFilterV2";
import InputDatePicker from "@/components/InputDatePicker";
import PuzlSelect from "@/components/PuzlSelect";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import {mapGetters} from "vuex";
import {selecteds} from "@/helpers/array";
import {debounce} from '@/plugins/index';
import {VMoney} from 'v-money';
import ModalAttachment from '../../../../BillReceive/Shared/Modals/_ModalAttachment'

export default {
  name: "ModalAssociate",
  components: {
    PuzlEmptyData,
    SkeletonPuzlGrid,
    SkeletonPuzl,
    InputDatePicker,
    MultiFilter,
    PuzlSelect,
    ModalAttachment
  },
  data() {
    return {
      modal: {
        title: 'Associação de parcelas',
        create: false,
      },
      selecteds: [],
      loadingPaymentMethods: false,
      loadingSkeleton: false,
      loadingSaveAdjustment: false,
      filter: {
        min_money_range: null,
        max_money_range: null,
        payment_method_uuid: null,
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false
      },
      loading: false,
      type: 0, /** Define se o tipo é 0 = contas a pagar, 1 = contas a receber **/
      range: {
        start: new Date(),
        end: new Date(),
      },
      selected: undefined,
    };
  },
  computed: {
    ...mapGetters({
      $_to_associate: 'bankReconciliationTransaction/getToAssociate',
      $_payment_methods: "paymentMethod/fetch",
      $_bank_reconciliation: 'bankReconciliation/show',
    }),
  },
  directives: {money: VMoney},
  mounted() {
    this.loadingPaymentMethods = true
    this.$store.dispatch("paymentMethod/fetchItems");
    this.loadingPaymentMethods = false
  },
  methods: {
    clearAdjustment(item, index) {
      this.$_to_associate[index].final_value = Math.abs(item.value)
      this.$_to_associate[index].discount_editable = item.discounts || '0,00'
      this.$_to_associate[index].discount_fees_editable = item.discounts_fees || '0,00'
      this.$_to_associate[index].fees_editable = item.fees_fines || '0,00'
      this.$forceUpdate()
    },
    saveAdjustment(item, index) {
      this.loadingSaveAdjustment = true
      this.$store.dispatch('bankReconciliationTransaction/postAdjustInstallment', {
        id: item.id,
        type: this.type,
        installment_type: item.type,
        fees_fines: item.fees_editable,
        discounts_fees: item.discount_fees_editable,
        discounts: item.discount_editable,
        value: item.final_value,
      }).then(response => {
        this.$_to_associate[index].fees_fines = item.fees_editable
        this.$_to_associate[index].discounts_fees = item.discount_fees_editable
        this.$_to_associate[index].discounts = item.discount_editable
        if (this.type === 1) {
          this.$_to_associate[index].value = Number(item.final_value.replaceAll(".", "").replace(",", "."));
        }
        else {
          this.$_to_associate[index].value = 0 - Number(item.final_value.replaceAll(".", "").replace(",", "."));
        }
        this.$forceUpdate()
        document.getElementById(index).getElementsByClassName("card-header")[0].click()
        this.loadingSaveAdjustment = false
        this.$notify({type: response.error_type, message: response.message})
      })
    },
    calcFinalValue(item, index) {
      let value = Number(item.old_value)
      if (item.discount_editable) {
        value -= Number(item.discount_editable.toString().replaceAll(".", "").replace(",", "."));
      }
      value -= Number(item.discount_fees_editable.toString().replaceAll(".", "").replace(",", "."));
      value += Number(item.fees_editable.toString().replaceAll(".", "").replace(",", "."));
      this.$_to_associate[index].final_value = value.toFixed(2)
      this.$forceUpdate()
    },
    async handleShowAttachments(installment_id, type) {
      let loader = this.$loading.show()
      const item = await this.getDetails(installment_id, type)
      loader.hide()
      if (!item.urls.length) {
        return this.$notify({type: 'warning', message: 'Não há arquivos anexados a parcela selecionada'})
      }
      this.$refs.modalAttachment.openAttachment(item.urls)
    },
    async getDetails(id, type) {
      return await this.$store
        .dispatch("billReceive/getDetails", {
          id: id,
          type: type
        })
        .then(response => {
          return response.data
        });
    },
    clearFilters() {
      this.filter = {
        min_money_range: null,
        max_money_range: null,
        payment_method_uuid: null,
      }
      this.init()
    },
    handleFilterMoney: debounce(function () {
      this.init()
    }, 200),
    init() {
      this.filter.range = this.range
      const self = this
      const selected_payment_method_uuid = this.$_payment_methods.find(item => item.uuid === self.filter.payment_method_uuid)
      if (selected_payment_method_uuid) {
        this.filter.payment_method_id = selected_payment_method_uuid.id
      }
      this.loadingSkeleton = true
      this.filter.bank_account_id = this.$_bank_reconciliation.bank_account_id
      this.filter.without_associate = 1
      if (this.selected.value > 0) {
        /**
         * Busca contas a receber não conciliadas
         */
        this.$store.dispatch('bankReconciliationTransaction/getBillReceives', {
          id: this.selected.id,
          filter: this.filter
        })
          .then(() => {
            this.loadingSkeleton = false
          })
      } else {
        /**
         * Busca contas a pagar não conciliadas
         */
        this.$store.dispatch('bankReconciliationTransaction/getBillPay', {
          id: this.selected.id,
          filter: this.filter
        })
          .then(() => {
            this.loadingSkeleton = false
          })
      }
    },
    handleAdvancedFilter() {
      this.init()
    },
    /**
     * Soma os valores associados
     */
    selectedSum() {
      let sum = 0
      this.selecteds.map(function (i) {
        let value = 0
        if (this.type === 1) {
          let splited = i.split('-')
          let id = Number(splited[0])
          let type = Number(splited[1])
          value = this.$_to_associate.find(item => item.id === id && item.type === type).value
        }
        else {
          value = this.$_to_associate.find(item => item.id === i).value
        }
        sum += Number(value)
      }, this)
      return sum
    },
    pushToAssociate(key, type = null) {
      if (this.type === 1) {
        key = `${key}-${type}`
      }
      this.selecteds = this.$helper.Array.selecteds(this.selecteds, key)
    },
    close() {
      this.modal.create = false
      this.selecteds = []
    },
    save() {
      let params = {
        bank_transaction_id: this.selected.id,
        selecteds: this.selecteds,
        type: this.type,
      }
      this.$Progress.start()
      this.loading = true
      this.$store.dispatch('bankReconciliationTransaction/postAssociate', params)
        .then((response) => {
          this.loading = false
          this.close()
          this.$Progress.finish();
          this.$emit('conciliate', {bank_transaction_id: params.bank_transaction_id})
          this.$notify({type: response.error_type, message: response.message})
        })
    },
    handleFilterDate(range) {
      this.range = range
      this.init()
    },
    handleCreate(transaction, range) {
      this.filter = {
        min_money_range: Number(Math.abs(Number(transaction.value) - (Number(transaction.value) / 100 * 5)).toFixed(2)
        ).toLocaleString('pt-BR', {minimumFractionDigits: 2}),
        max_money_range: Number(Math.abs(Number(transaction.value) - (Number(transaction.value) / 100 * -5)).toFixed(2)).toLocaleString('pt-BR', {minimumFractionDigits: 2}),
        payment_method_uuid: null,
      }
      this.range = range
      this.modal.create = true
      this.selected = transaction
      this.type = 0
      this.$refs["date-picker"].resetDate()
      /**
       * Se o valor a conciliar for positivo é contas a receber, se não, é contas a pagar
       * 0 = contas a pagar, 1 = contas a receber
       */
      if (this.selected.value > 0) {
        this.type = 1
      }
      this.init()
    },
  },
}
</script>

<style scoped></style>

