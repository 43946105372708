<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-md-4">
          <h2 style="font-size: 1.2rem !important" class="text-primary">
            <img height="30px" src="/img/icons/physics.png" class="mr-2 mb-1" />
            MCC
          </h2>
        </div>
        <div class="col-md-8 pr-4">
          <div class="row d-flex justify-content-end">
            <div class="mb-md-0 mb-2 col-md-3 px-1">
              <router-link :to="{ path: '/technology/cmc/create' }">
                <base-button type="success" block>
                  <img height="19" src="/img/icons/plus-math--v1-white.png" class="mr-1 mt-n1" />
                  NOVO
                </base-button>
              </router-link>
            </div>
            <base-dropdown class="mb-md-0 mb-2 col-md-3 px-1">
              <base-button block slot="title-container" type="warning" class="dropdown-toggle text-uppercase" style="font-size: 0.8rem">
                <img height="19" src="/img/icons/product-documents.png" class="mr-1 mt-n1" />
                Cadastros
              </base-button>

              <div>
                <router-link :to="{ path: '/technology/cmc/group' }">
                  <a class="dropdown-item new-default-black-font">
                    <img height="19" src="/img/icons/icons8/ios/parallel-tasks_primary.png" class="mr-1 mt-n1" /> Grupo
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                </router-link>

                <router-link :to="{ path: '/technology/cmc/product' }">
                  <a class="dropdown-item new-default-black-font" href="#">
                    <img height="19" src="/img/icons/icons8/ios/sandbox2_warning.png" class="mr-1 mt-n1" /> Produto
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                </router-link>

                <router-link :to="{ path: '/technology/cmc/additional-classification' }">
                  <a class="dropdown-item new-default-black-font" href="#">
                    <img height="19" src="/img/icons/icons8/ios/add-to-favorites--v1_warning.png" class="mr-1 mt-n1" /> Classificação
                    Adicional
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                </router-link>

                <router-link :to="{ path: '/technology/cmc/supplier' }">
                  <a class="dropdown-item new-default-black-font" href="#">
                    <img height="19" src="/img/icons/icons8/ios/drop-shipping_success.png" class="mr-1 mt-n1" /> Fornecedor
                  </a>
                </router-link>
              </div>
            </base-dropdown>
            <base-dropdown class="mb-md-0 mb-2 col-md-3 px-1">
              <base-button slot="title-container" block class="new-default-black dropdown-toggle text-uppercase" style="font-size: 0.8rem">
                <img height="19" src="/img/icons/rating.png" class="mr-1 mt-n1" />
                Referências
              </base-button>
              <div>
                <a class="dropdown-item new-default-black-font" href="#" @click.prevent="handleReference(1)">
                  <img height="19" src="/img/icons/icons8/ios/star--v1_primary.png" class="mr-1 mt-n1" /> Técnica
                </a>
                <div class="dropdown-divider p-0 m-0"></div>

                <a class="dropdown-item new-default-black-font" href="#" @click.prevent="handleReference(2)">
                  <img height="19" src="/img/icons/icons8/ios/star--v1_success.png" class="mr-1 mt-n1" /> Comercial
                </a>
              </div>
            </base-dropdown>
          </div>
        </div>
      </div>
      <modal-reference ref="modalReference" />
    </base-header>
    <div class="container-fluid">
      <div class="mt-4 ml-2">
        <div style="display: flex; align-items: center">
          <i
            :class="listType !== 'cards' && 'opacity-25'"
            @click.prevent="setViewType('cards')"
            class="pointer fa-regular fa-hard-drive fa-2x"
          ></i>
          <i
            :class="listType !== 'table' && 'opacity-25'"
            @click.prevent="setViewType('table')"
            class="pointer fa-solid fa-table-list fa-2x ml-1"
          ></i>
          <div style="display: inline-block; width: 180px" v-show="listType !== 'cards'">
            <puzl-select
              :clearable="false"
              @input="handleTableRangeFilter"
              v-model="table_range"
              :items="table_ranges"
              class="mx-2 select-sm new-default-black-font"
            />
          </div>
        </div>
      </div>
      <div>
        <multi-filter
          :showSlotBookmark="false"
          @clearFilter="clearFilter()"
          :showSlotData="false"
          :type="3"
          @fetch="init"
          :filter="filter"
          :status="multi_filter"
        >
          <template slot="dates">
            <div class="col-md-12 mt-3 text-left">
              <label class="form-control-label"> CATEGORIA MCC </label>
              <puzl-select
                v-model="categories"
                :items="$_categories"
                :multiple="true"
                class="select-xl new-default-black-font"
                @input="handleFilterCmcCategory"
                :disabled="loadingCmcCategories"
                :loading="loadingCmcCategories"
              />
            </div>
            <div class="col-md-12 mt-3 text-left">
              <label class="form-control-label"> PRODUTO </label>
              <puzl-select
                v-model="products"
                :items="$_products"
                label="product_name"
                :multiple="true"
                class="select-xl new-default-black-font"
                @input="handleFilterCmcProduct"
                :disabled="loadingCmcProducts"
                :loading="loadingCmcProducts"
              />
            </div>
            <div class="col-md-12 mt-3 text-left">
              <label class="form-control-label"> FORNECEDOR </label>
              <puzl-select
                v-model="suppliers"
                :items="$_suppliers"
                label="supplier_name"
                :multiple="true"
                class="select-xl new-default-black-font"
                @input="handleFilterCmcSupplier"
                :disabled="loadingCmcSuppliers"
                :loading="loadingCmcSuppliers"
              />
            </div>
            <div v-show="listType !== 'table'" class="col-md-12 mt-3 text-left">
              <label class="form-control-label"> GRUPOS </label>
              <puzl-select           
                v-model="groups"
                :items="$_groups"
                label="group_name"
                :multiple="true"
                class="select-xl new-default-black-font"
                @input="handleFilterCmcGroup"
                :disabled="loadingCmcGroups"
                :loading="loadingCmcGroups"
              />
            </div>
          </template>
          <template slot="status">
            <div class="col-12">
              <base-button-hoverable
                :active="filter.status == 1"
                @click="setStatus(1)"
                size="sm"
                type="success"
                icon="ok--v1"
                platform="ios"
              >
                ATIVO
              </base-button-hoverable>
            </div>
            <div class="col-12 mt-1 mb-5">
              <base-button-hoverable
                size="sm"
                :active="filter.status == 0"
                @click="setStatus(0)"
                type="danger"
                icon="cancel"
                platform="ios"
              >
                INATIVO
              </base-button-hoverable>
            </div>
          </template>
          <template slot="order-by">
            <el-popover trigger="click" placement="bottom" class="m-0 p-0 float-right">
              <h6 class="ml-2">
                <a href="#" class="font-weight-400" style="font-size: 12px">
                  <i class="text-primary fa-solid fa-angles-down" style="font-size: 12px; margin-right: 5px"></i> PRODUTO: A-Z
                </a>
              </h6>
              <hr class="mb-2 mt-0" />
              <h6 class="ml-2">
                <a href="#" class="font-weight-400" style="font-size: 12px">
                  <i class="text-primary fa-solid fa-angles-up" style="font-size: 12px; margin-right: 5px"></i> PRODUTO: Z-A
                </a>
              </h6>
              <a href="#" slot="reference">
                <h5 slot="reference" class="pt-1">ORDENAR <i class="fa-solid fa-chevron-down"></i></h5>
              </a>
            </el-popover>
          </template>
        </multi-filter>
      </div>
      <Cards
        v-if="listType === 'cards'"
        :handleFilterCmcGroup="handleFilterCmcGroup"
        :handleFilterCmcProduct="handleFilterCmcProduct"
        :handleFilterCmcSupplier="handleFilterCmcSupplier"
        :handleFilterCmcCategory="handleFilterCmcCategory"
        :setStatus="setStatus"
        :showGroups="showGroups"
        :handleDeleteCmc="handleDeleteCmc"
        :handleEnableDisable="handleEnableDisable"
        :handleCreateCarrierPrice="handleCreateCarrierPrice"
        :handleCreateCmcPrice="handleCreateCmcPrice"
        :handleShowCmcPrice="handleShowCmcPrice"
        :handleDeleteCarrierPrice="handleDeleteCarrierPrice"
        :handleDeleteCmcPrice="handleDeleteCmcPrice"
        :pricePerKg="pricePerKg"
        :handleDelete="handleDelete"
        :loadingSkeleton="loadingSkeleton"
        :technicalPermissions="technicalPermissions"
      />
      <Table
        v-else
        :handleFilterCmcGroup="handleFilterCmcGroup"
        :handleFilterCmcProduct="handleFilterCmcProduct"
        :handleFilterCmcSupplier="handleFilterCmcSupplier"
        :handleFilterCmcCategory="handleFilterCmcCategory"
        :setStatus="setStatus"
        :showGroups="showGroups"
        :handleDeleteCmc="handleDeleteCmc"
        :handleEnableDisable="handleEnableDisable"
        :handleCreateCarrierPrice="handleCreateCarrierPrice"
        :handleCreateCmcPrice="handleCreateCmcPrice"
        :handleShowCmcPrice="handleShowCmcPrice"
        :handleDeleteCarrierPrice="handleDeleteCarrierPrice"
        :handleDeleteCmcPrice="handleDeleteCmcPrice"
        :pricePerKg="pricePerKg"
        :handleDelete="handleDelete"
        :loadingSkeleton="loadingSkeleton"
      />
      <LoadingPagination :show="loading && !loadingSkeleton" />
      <modal-create-cmc-carrier-price ref="createCmcCarrierPrice"></modal-create-cmc-carrier-price>
      <modal-create-cmc-price ref="createCmcPrice" @fetchCmc="init({})"></modal-create-cmc-price>
      <modal-show-cmc-price
        ref="showCmcPrice"
        :pricePerKg="pricePerKg"
        :handleEnableDisable="handleEnableDisable"
        :handleDeleteCmcPrice="handleDeleteCmcPrice"
      ></modal-show-cmc-price>
      <PuzlEmptyData v-if="!$_cmcs.length && !loadingSkeleton"></PuzlEmptyData>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingPagination from "@/components/LoadingPagination";
import ModalReference from "../Mix/Shared/_ModalReference";
import MultiFilter from "@/components/Utils/MultiFilterV3";
import cursorPaginate from "@/mixins/cursorPaginate";
import Cards from "./Shared/_Cards";
import Table from "./Shared/_Table";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import ModalCreateCmcCarrierPrice from "./Shared/_ModalCreateCmcCarrierPrice";
import ModalCreateCmcPrice from "./Shared/_ModalCreateCmcPrice";
import ModalShowCmcPrice from "./Shared/_ModalShowCmcPrice";
import PuzlSelect from "@/components/PuzlSelect";
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import moment from "moment";
import {setPreferredViewMode, getPreferredViewMode} from '@/plugins/session';

export default {
  name: "CmcGrid",
  mixins: [cursorPaginate],
  components: {
    ModalReference,
    PuzlEmptyData,
    ModalCreateCmcCarrierPrice,
    ModalCreateCmcPrice,
    ModalShowCmcPrice,
    MultiFilter,
    LoadingPagination,
    Cards,
    Table,
    PuzlSelect,
    BaseButtonHoverable,
  },
  data() {
    return {
      listType: "cards",
      loadingSkeleton: true,
      loadingCmcProducts: true,
      loadingCmcGroups: true,
      loadingCmcSuppliers: true,
      loadingCmcCategories: true,
      technicalPermissions: {},
      table_data: {},
      filter: {
        status: null,
        cmc_group_id: [],
        cmc_product_id: [],
        cmc_supplier_id: [],
        cmc_category_id: [],
      },
      categories: null,
      products: null,
      suppliers: null,
      groups: null,
      table_range: 0,
      table_ranges: [
        {
          id: 0,
          name: "Último preço",
          value: 0,
        },
        {
          id: 7,
          name: "7 dias",
          value: 7,
        },
        {
          id: 14,
          name: "14 dias",
          value: 14,
        },
        {
          id: 21,
          name: "21 dias",
          value: 21,
        },
        {
          id: 45,
          name: "45 dias",
          value: 45,
        },
      ],
      multi_filter: {
        null: {
          name: "Todos",
          type: "primary",
        },
        0: {
          name: "Inativo",
          type: "danger",
        },
        1: {
          name: "Ativos",
          type: "success",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      $_products: "cmcProduct/fetch",
      $_groups: "cmcGroup/fetch",
      $_suppliers: "cmcSupplier/fetch",
      $_categories: "category/fetch",
      $_cmcs: "cmc/fetch",
      $_user: 'auth/getUser',
    }),
  },
  methods: {
    fetchTechnicalPermissions(userId) {
      this.$store.dispatch('technicalPermission/getByUserId', userId).then((response) => {
        this.technicalPermissions = response.data
      });
    },
    setViewType(type) {
      this.loadingSkeleton = true;
      this.listType = type;
      setPreferredViewMode(type);
      this.paginate.nextUrl = null;
      this.init();
    },
    clearFilter() {
      this.loadingSkeleton = true;
      this.filter = {
        status: null,
      };
      this.init();
    },
    handleReference(type) {
      this.$refs.modalReference.handleCreate(type);
    },
    handleFilterCmcGroup(filter) {
      this.filter.cmc_group_id = filter;
      this.groups = filter;
      this.init(this.filter);
    },
    handleTableRangeFilter(filter) {
      this.loadingSkeleton = true;

      if (!filter) {
        delete this.filter.table_range;
        return this.init({});
      }

      this.filter.table_range = {
        start: moment().subtract(filter, "days").format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD"),
      };
      this.init(this.filter);
    },
    handleFilterCmcProduct(filter) {
      this.filter.cmc_product_id = filter;
      this.products = filter;
      this.init(this.filter);
    },
    handleFilterCmcSupplier(filter) {
      this.filter.cmc_supplier_id = filter;
      this.suppliers = filter;
      this.init(this.filter);
    },
    handleFilterCmcCategory(filter) {
      this.filter.cmc_category_id = filter;
      this.categories = filter;
      this.init(this.filter);
    },
    init(filter = null) {
      this.startCursor(filter);
      this.$store
        .dispatch("cmc/" + (!this.listType || this.listType === 'cards' ? "newFetchItems" : "fetchItems"), {
          ... this.filter,
          next_page: this.paginate.nextUrl,
          list_type: this.listType,
        })
        .then((response) => {
          this.resolveCursor(response);
        })
        .catch(() => {
          this.resolveCursor();
        });
    },

    setStatus(status) {
      if (this.filter.status == status) {
        this.filter.status = null;
      } else {
        this.filter.status = status;
      }
      this.init(this.filter);
    },
    showGroups(index) {
      this.$el.querySelector(".grupos-" + index).classList.toggle("groups-disabled");
    },
    handleDeleteCmc(uuid) {
      this.$Swal
        .confirmDelete()
        .then((result) => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação.",
            });
            this.$store.dispatch("cmc/destroy", uuid);
            this.$Progress.finish();
          }
        })
        .catch(() => this.$Progress.finish());
    },
    handleEnableDisable(cmcId, plantId, isActive) {
      const loader = this.$loading.show();
      this.$store
        .dispatch("cmcPrice/enabledOrDisabled", {
          cmc_id: cmcId,
          company_plant_id: plantId,
          is_active: !isActive,
        })
        .then((response) => {
          loader.hide();
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
        });
    },
    handleCreateCarrierPrice(cmcId, initialsBuy) {
      this.$refs.createCmcCarrierPrice.handleCreate(cmcId, initialsBuy);
    },
    /**
     * @param {object} cmc
     */
    handleCreateCmcPrice(cmc, tablePayload = false) {
      if(!this.technicalPermissions.is_mcc_prices_changed) {
        return this.$notify({
          type: "danger",
          message: "Você não possui permissão para alterar preços de MCC."
        });
      } else {
        if(tablePayload) {
          return this.$refs.createCmcPrice.handleCreate( 
            tablePayload.id,
            tablePayload.measured_unit_buy_initials,
            tablePayload.product_name,
            tablePayload.supplier_name,
            tablePayload.supplier_origin_name,
            tablePayload.params,
            cmc.company_plant_id ? cmc.company_plant_id : null,
            cmc.data ? cmc.data.average_price_with_transport_cost : null
          );
        }
        let params = {
          average_humidity: cmc.average_humidity,
          measured_unit_id: cmc.measured_unit_id_buy.id,
          bulk_density: this.$helper.strToNum(cmc.bulk_density),
        };
        this.$refs.createCmcPrice.handleCreate(
          cmc.id,
          cmc.measured_unit_id_buy.initials,
          cmc.cmc_product.product_name,
          cmc.cmc_supplier.supplier_name,
          cmc.cmc_supplier_origin.origin_name,
          params
        );
      }
    },
    /**
     * @param {object} cmc
     */
    handleShowCmcPrice(cmc, indexCmc) {
      this.$refs.showCmcPrice.handleCreate(cmc, indexCmc);
    },
    handleDeleteCarrierPrice(id) {
      this.$Swal
        .confirmDelete()
        .then((result) => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação.",
            });
            this.$store.dispatch("cmc/destroyCarrierPrice", id);
            this.loadingSkeleton = true;
            this.init();
            this.$Progress.finish();
          }
        })
        .catch(() => this.$Progress.finish());
    },
    handleDeleteCmcPrice(id, index, indexCmc) {
      this.$Swal
        .confirmDelete()
        .then((result) => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação.",
            });
            this.loadingSkeleton = true;
            return this.$store
              .dispatch("cmc/destroyPrice", id)
              .then((response) => {
                this.$Progress.finish();
                this.$notify({
                  type: response.error_type,
                  message: response.message,
                });
                this.$_cmcs[indexCmc].cmc_price_next[index] = null;
                init(this.filter);
              })
              .catch((error) => {
                if (error.status == 200) {
                  this.$notify({
                    type: "danger",
                    message: error.data.message,
                  });
                  this.loadingSkeleton = false;
                  this.$Progress.finish();
                }
                this.$Progress.finish();
                this.loadingSkeleton = false;
              });
          }
        })
        .catch(() => this.$Progress.finish());
    },
    pricePerKg(current_price, measured_unit_id, bulk_density, average_humidity) {
      const price = this.$helper.strToNum(current_price);
      switch (measured_unit_id) {
        case 1: // m3
          return price / bulk_density / 1000;
        case 26: // l
          return price / bulk_density;
        case 4: // ton
          return price / 1000 / (1 - average_humidity / 100);
        case 8: // g
          return price * 1000;
        default:
          return price;
      }
    },
    handleDelete(uuid) {
      this.$Swal
        .confirmDelete()
        .then((result) => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação.",
            });
            this.$store
              .dispatch("cmc/destroy", uuid)
              .then((response) => {
                this.$notify({
                  type: response.error_type,
                  message: response.message,
                });
                this.$Progress.finish();
                this.init();
              })
              .catch((error) => {
                this.$notify({
                  type: "danger",
                  message: error.data.message,
                });
              });
            this.$Progress.finish();
          }
        })
        .catch(() => this.$Progress.finish());
    },
  },
  mounted() {
    this.listType = getPreferredViewMode() ?? 'cards';
    this.init();
    this.$store.dispatch("cmcProduct/fetchItems").then(() => {
      this.loadingCmcProducts = false;
    });
    this.$store.dispatch("cmcGroup/fetchItems").then(() => {
      this.loadingCmcGroups = false;
    });
    this.$store.dispatch("cmcSupplier/fetchItems").then(() => {
      this.loadingCmcSuppliers = false;
    });
    this.$store.dispatch("category/fetchItems").then(() => {
      this.loadingCmcCategories = false;
    });
    this.fetchTechnicalPermissions(this.$_user.id);
  },
};
</script>