<template>
  <div class="third-node">
    <div class="d-flex align-items-center">
      <span class="w-100 third-node-content-item d-flex" style="gap: 15px;">
        {{ truncateString(node.title) }}
        <el-popover
          class=""
          placement="right"
          trigger="hover"
        >
          <div class="popover-content">
            <span class="popover-content-title">Endereço da Obra</span>
            <hr class="popover-hr">
            <span class="popover-content-content">
              {{ formatAddress(addressObject) }}
            </span>
          </div>
          <base-button
              slot="reference"
              class="p-0 m-0 shadow-none"
              outiline
              size="sm"
              type="secundary"
            >
            <img
              src="/img/icons/icons8/ios/map--v1_primary.png"
              alt="map--v1_primary"
              width="20px"
              class="pointer"
            >
          </base-button>
        </el-popover>
        <el-popover
          class=""
          placement="right"
          trigger="hover"
        >
          <span style="width: 200px;"
            class="row">
            <div class="col-12 my-1" style="display: flex; align-items: center;">
              <img class="mr-2" src="/img/icons/icons8/ios/user-male-circle--v1_success.png" width="22">
              <h4 class="p-0 m-0">Vendedor</h4>
            </div>
            <div class="col-12 mb-n1">
              <h5 class="font-weight-normal" style="word-break: break-word;">
                {{ node.seller_name }}
              </h5>
            </div>
          </span>
          <base-button
              slot="reference"
              class="p-0 m-0 shadow-none"
              outiline
              size="sm"
              type="secundary"
            >
            <img
              src="/img/icons/salesman.png"
              alt="salesman"
              width="20px"
              class="pointer"
            >
          </base-button>
        </el-popover>
      </span>
      <span class="w-70 third-node-content-item align-content-center">
        <span class="d-flex justify-content-center" style="gap: 15px;">
          {{ node.concreting_time }}
          <img
            src="/img/icons/icons8/ios/clock-success.png"
            alt="clock-success"
            width="20px"
            class="pointer"
            @click="openModalInfo(node.company_plant_uuid, node.construction_id, node.concreting_date)"
          >
        </span>
      </span>
      <span class="w-70 third-node-content-item align-content-center">
        <div class="d-flex justify-content-center">
          {{ Math.trunc(node.average_distance) }} km
        </div>
      </span>
      <span class="w-70 third-node-content-item align-content-center">
          <div class="d-flex justify-content-center mr-2" style="gap: 11px;">
            <el-popover
              placement="right"
              trigger="click"
            >
              <div class="popover-formulation" v-if="formulations !== null">
                <span class="popover-formulation-title">Traço Utilizado</span>
                <hr class="popover-hr">
                <span class="popover-formulation-content">
                  <section class="top-section d-flex justify-content-between">
                    <div class="ml-auto">
                      <span class="badge-mix-code d-flex" style="gap: 10px; color: #fff;font-size: 12px;">
                        <img src="/img/icons/icons8/ios/copy-white.png"
                          alt="copy-white"
                          width="13px"
                          height="15px"
                          class="pointer"
                          @click.prevent="copyMixCode(formulations.mix_code_id)"
                        >
                        {{ formulations.mix_code_id }}
                      </span>
                    </div>
                  </section>
                  <section class="mid-section d-flex flex-column mt-2">
                    <span style="font-weight: 500;">{{ formulations.formulation_title }}</span>
                    <span>{{ formulations.formulation_body }}</span>
                    <span v-if="formulations.footer">{{ formulations.footer }}</span>
                  </section>
                  <section class="end-section mt-3" v-if="$hasPermission('operational.change_feature_level')">
                    <div class="d-flex mb-2 mr-1 align-items-center">
                      <span style="font-size: 10px;font-weight: 400;color: #2B2D32;">
                        Nível de Aplicação
                      </span>
                      <div class="d-flex ml-auto align-items-center" style="gap: 4px;" v-if="formulations.has_feature_level">
                        <span class="badge-feature-level d-flex align-items-center"
                          style="gap: 5px;font-size: 10px;font-weight: 400;color: #2BA767;"
                          v-if="formulations.has_feature_level.piece"
                        >
                          <img src="/img/icons/icons8/ios/ok--v1_success.png" alt="ok--v1_success" width="12px" height="12px">
                          Peça
                        </span>
                        <span class="badge-feature-level d-flex align-items-center"
                          style="gap: 5px;font-size: 10px;font-weight: 400;color: #2BA767;"
                          v-if="formulations.has_feature_level.item"
                          >
                          <img src="/img/icons/icons8/ios/ok--v1_success.png" alt="ok--v1_success" width="12px" height="12px">
                          Traço
                        </span>
                      </div>
                    </div>
                    <validation-provider rules="required" v-slot="{ errors }">
                        <base-input input-group-classes="input-group-sm">
                          <PuzlSelect :clearable="false" @change="setFeatureLevel(formulations)"
                                       v-model="formulations.feature_level" :items="FeatureLevelEnum"
                                       customKey="value" label="label"/>
                        </base-input>
                      </validation-provider>
                  </section>
                </span>
              </div>
              <base-button
                slot="reference"
                class="p-0 m-0 shadow-none"
                outiline
                size="sm"
                type="secundary"
              >
                <img
                  src="/img/icons/icons8/ios/test-tube_primary.png"
                  alt="test-tube_primary"
                  width="20px"
                  class="pointer"
                  @click="getFormulations(node.id)"
                >
              </base-button>
        </el-popover>
            <div style="font-weight: 500;">
              <span class="ml-n2 mr-1">
                {{ node.feature_level }}
              </span>
              <span class="info-concrete-volume">
                {{ node.total_volume ? node.total_volume : 0 }} m<sup>3</sup> ({{ node.total_travels ? node.total_travels : 0 }})
              </span>
              /
              <span class="info-concrete-volume" style="color: #149E57;">
                {{ node.performed_volume ? node.performed_volume : 0 }} m<sup>3</sup> ({{
                  node.performed_travels ? node.performed_travels : 0
                }})
              </span>
            </div>
          </div>
      </span>
      <span class="w-70 third-node-content-item align-content-center">
        <div class="d-flex justify-content-center">
          <div v-if="node.service_total_volume">
            <span>
              {{ node.service_total_volume ? node.service_total_volume : 0 }} m<sup>3</sup>
            </span>
            /
            <span style="color: #149E57;">
              {{ node.service_performed_volume ? node.service_performed_volume : 0 }} m<sup>3</sup>
            </span>
          </div>
          <div v-else-if="node.service_type">
            {{ node.service_type }}
          </div>
          <div v-else>
            NENHUM
          </div>
        </div>
      </span>
    </div>
    <ModalInfoSchedule ref="ModalInfoSchedule"/>
  </div>
</template>

<script>
import {FeatureLevelEnum} from "@/enum/FeatureLevelEnum";
import PuzlSelect from "@/components/PuzlSelect.vue";
import ModalInfoSchedule from "./_ModalInfoSchedule.vue";

export default {
  name: "ThirdNode",
  components: { PuzlSelect, ModalInfoSchedule },
  props: {
    node: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      lengthToTruncate: 35,
      addressObject: {
        address: this.node.address,
        number: this.node.number,
        complement: this.node.complement,
        district: this.node.district,
        city: this.node.city,
        postal_code: this.node.postal_code
      },
      FeatureLevelEnum: FeatureLevelEnum,
      loadingFormulations: false,
      formulations: null,
    }
  },
  methods: {
    /**
    * Trunca uma string e adiciona reticências se exceder o comprimento máximo especificado.
    *
    * @param {string} str - A string a ser truncada.
    * @returns {string} - A string truncada com reticências adicionadas, se necessário.
    */
    truncateString(str) {
      if(str.length <= this.lengthToTruncate) {
        return str;
      }

      return str.slice(0, this.lengthToTruncate) + '...';
    },
    /**
    * Formata o endereço em um formato específico.
    *
    * @param {object} address - O objeto a ser formatado
    * @returns {string} - A string formatada.
    */
    formatAddress(addressObject) {
      let formattedAddress = addressObject.address.toUpperCase();

      // SN == sem número, definido no backend
      if(addressObject.number !== "SN") {
        formattedAddress += `, ${addressObject.number}`;
      }

      if(addressObject.complement) {
        formattedAddress += `, ${addressObject.complement}`;
      }

      formattedAddress += `, ${addressObject.district.toUpperCase()}`;
      formattedAddress += `, ${addressObject.city.toUpperCase()}`;
      formattedAddress += `, CEP ${addressObject.postal_code}`;

      return formattedAddress;
    },
    openModalInfo(company_plant_uuid, construction_id, concreting_date) {
      this.$refs.ModalInfoSchedule.openModal(company_plant_uuid, construction_id, concreting_date);
    },
    getFormulations(schedule_id) {
      this.$Progress.start();
      this.formulations = null;
      this.$store.dispatch('newScheduleResume/getFormulationsByScheduleTravel', {schedule_id: schedule_id})
        .then((response) => {
          this.formulations = response.data[0];
        }).catch((error) => {
          if(error.response.status === 406) {
            this.$notify({ type: "danger", message: error.response.data.message });
          }
        }).finally(() => this.$Progress.finish())
    },
    setFeatureLevel(formulations) {
      this.$nextTick(() => {
        let loader = this.$loading.show()
        let params = {
          schedule_uuid: formulations.schedule_uuid,
          feature_level: formulations.feature_level
        }
        this.$store.dispatch('schedule/postSetFeatureLevel', params).then(({data}) => {
          formulations.has_feature_level = data
          this.$forceUpdate()
          loader.hide()
        })
      })
    },
    /**
     * função para copiar valores
     * @param val
     */
    copyMixCode(val) {
      this.$helper.copy(val)
      this.$notify({type: 'success', message: 'Código do traço copiado com sucesso!'})
    },
  }
}
</script>

<style scoped>
.third-node {
  border: 0.5px solid var(--Cores-Cinza-200, #CFCFCF);
  border-radius: 8px;
}
.third-node span {
  font-size: 14px;
  font-weight: 400;
  color: #2B2D32;
}
.third-node-content-item {
  padding: 8px;
}
.third-node-content-item sup {
  align-content: center;
}
.w-70 {
  width: 70%;
}
.popover-content span {
  font-size: 12px;
  color: #2B2D32;
}
.popover-content-title {
  font-weight: 400;
}
.popover-content-content {
  font-weight: 300;
}
.popover-hr {
  border: 0;
  border-top: 0.5px solid #E8E8E8;
  margin: 7px 0;
  width: 116%;
  margin-left: -11px;
}
.popover-formulation span {
  font-size: 12px;
  color: #2B2D32;
}
.popover-formulation-title {
  font-weight: 500;
}
.popover-formulation .top-section {
  font-size: 12px;
  font-weight: 400;
}
.popover-formulation .mid-section {
  font-size: 12px;
  font-weight: 400;
}
.badge-mix-code {
  padding: 2px 12px;
  border-radius: 4px;
  background-color: #7498C7;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
}
.badge-feature-level {
  padding: 2px 16px;
  background-color: #F2F7F3;
  border-radius: 16px;
}
</style>