export const SET = 'SET'
export const SET_PRICES = 'SET_PRICES'
export const SET_HISTORY = 'SET_HISTORY'
export const SET_CURRENT_PRICES = 'SET_CURRENT_PRICES'
export const PUSH = 'PUSH'
export const SHOW = 'SHOW'
export const DESTROY = 'DESTROY'
export const UPDATE = 'UPDATE'
export const GET_CODE = 'GET_CODE'

