<template>
<div>
    <div class="container-fluid mb-1">
        <div class="checklist-item checklist-item-primary">
            <div class="checklist-info">
                <h1 class="checklist-title mb-0">
                {{ construction_name }}
                </h1>
            </div>
        </div>
    </div>
    <multi-filter ref="multi-filter" @fetch="init" :filter="filter" /> 
    <div class="container-fluid">
        <div class="row card-wrapper" v-show="loadingSkeleton">
            <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
        </div>

        <PuzlEmptyData v-if="!$_adresses.length && !loadingSkeleton"></PuzlEmptyData>

        <div class="row card-wrapper" v-if="$_adresses.length && !loadingSkeleton">
            <div class="col-lg-4" v-for="(address, index) in $_adresses" :key="index">
                    <card :class="address.status ? 'card-border-top-success' : 'card-border-top-danger'">
                    <!-- Card header -->
                    <div class="row align-items-center mb-3">
                        <div class="col-8">
                            <button 
                                type="button" :class="address.default ? 'btn btn-primary' : 'btn btn-outline-primary'"
                                @click.prevent="handleChangeDefaultAddress(address.uuid, address.construction_uuid, address.status)">
                                <i class="fa-solid fa-user-check"></i> Padrão
                            </button>
                        </div>
                        <div class="col-4 text-right">
                            <base-dropdown menuOnRight>
                                <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                                    <i class="fas fa-cog"></i>
                                </base-button>
                                 <a class="dropdown-item" @click="handleEditAddress(address.uuid)">
                                    <i class="fas fa-edit text-warning main-icon-menu"></i>
                                    Editar
                                </a>
                                <div class="dropdown-divider p-0 m-0"></div>
                                <a class="dropdown-item" @click.prevent="handleDeleteAddress(address.uuid)">
                                    <i class="fas fa-times text-danger"></i>
                                    Excluir
                                </a>
                            </base-dropdown>
                        </div>
                    </div>
                    <!-- Card body -->
                    <div>
                        <span class="h4">
                        {{ address.city }} /
                        {{ address.state }}
                        </span>
                    </div>
                    <div>
                        {{ address.postal_code }}
                    </div>
                    <div>
                        {{ address.address }}, 
                        {{ address.number }} 
                        <span v-if="address.complement">, {{ address.complement }} </span>
                    </div>
                    <div>
                        {{ address.district }}
                    </div>
                </card>
            </div>
        </div>
        <ModalEditConstructionAddress @updateAddress="updateDefaultAddress" ref="modalEditConstructionAddress"/>
        <loading-pagination :show="loading && !loadingSkeleton"/>
    </div>
</div>
</template>

<script>
import {mapGetters} from "vuex";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import MultiFilter from "@/components/Utils/MultiFilter";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import ModalEditConstructionAddress from './_ModalEdit';
export default {
    name: "ListAddressConstruction",
    mixins: [cursorPaginate],
    components: {
        PuzlEmptyData,
        SkeletonPuzlGrid,
        MultiFilter,
        LoadingPagination,
        ModalEditConstructionAddress,
    },
    data() {
        return {
            loadingSkeleton: false,
            filter: {},
            construction_name: '',
        };
    },
    computed: {
    ...mapGetters({
      $_adresses: 'addressConstruction/fetch',
    }),
  },
    mounted() {
        this.$refs['multi-filter']._self.$forceUpdate();
        this.init({});
    },
    methods: {
        init(filter = null) {
            this.startCursor(filter)
            this.$Progress.start()
            this.$store
                .dispatch("addressConstruction/fetchItemsPaginate", {
                    filter: this.filter,
                    next_page: this.paginate.nextUrl,
                    construction_uuid: this.$route.params.construction_uuid,
                })
                .then(response => {
                    this.construction_name = response.data[0].construction_name;
                    this.resolveCursor(response)
                    this.$Progress.finish()
                })
                .catch(error => {
                    this.resolveCursor()
                    this.$Progress.finish()
                });
        },
        handleEditAddress(uuidAddress){
            this.$refs.modalEditConstructionAddress.handleEditModal(uuidAddress)
        },
        handleDeleteAddress(uuidAddress){
            this.$Swal.confirmDelete().then((result) => {
                if (result.isConfirmed){
                this.$Progress.start();
                this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
                this.$store.dispatch('addressConstruction/destroy', uuidAddress)
                .then(() => {
                    this.$notify({type: 'success', message: 'Endereço deletado com sucesso.'});
                }).catch((error) => {
                    this.$notify({type: 'danger', message: error.data.message});
                })
                this.$Progress.finish();
                }
            }).catch(() => this.$Progress.finish())
        },
        handleChangeDefaultAddress(uuid, construction_uuid, status) {
            this.$store.dispatch('addressConstruction/changeDefaultAddress', {
                uuid: uuid,
                construction_uuid: construction_uuid,
                status: status
            })
                .then((response) => {
                    this.$_adresses.map(function(element, index){
                        element.default = false;
                    });
                    if (response.data.status && !response.data.default) {
                        response.data.default = true;
                    }
                })
                .catch(error => {
                    this.$notify({
                        type: 'danger',
                        message: error.data.message
                    })
                })
        },
        updateDefaultAddress(address) {
            this.$_adresses.map(function(element, index){
                element.default = false;
            });
            address.data.default = true;
        },
    },
};
</script>

<style></style>
