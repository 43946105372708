import { computed, getCurrentInstance } from "vue";

export function useAdjustmentHistoryHook() {
  const store = getCurrentInstance().proxy.$store;

  /**
   * Centrais
   */
  store.dispatch("plant/fetchItemsActive");
  const companyPlants = computed(() => store.getters["plant/activeShortItems"]);

  return {
    companyPlants
  };
}