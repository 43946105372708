<template>
<div>
    <base-header class="bg-gray-content">
        <div class="row align-items-center py-4">
            <div class="col-lg-12 col-12">
                <PuzlBreadcrumb/>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-2 mb-2" v-if="$_user.internal_type === 1">
                <base-button 
                    @click.prevent="handleCreateModal" 
                    class="pb-3 pt-3 text-uppercase" 
                    block size="lg" 
                    type="success"
                >
                    <i class="fas fa-plus"></i> novo
                </base-button>
            </div>
        </div>
    </base-header>
    <ListTutorial />
    <CreateTutorial ref="modalCreate" />
</div>
</template>

<script>
import {mapGetters} from "vuex";
import ListTutorial from "./Shared/_List";
import CreateTutorial from "./Shared/_ModalCreate";
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
export default {
    name: "Index",
    components: {
        ListTutorial,
        CreateTutorial,
        PuzlBreadcrumb,
    },
    computed: {
        ...mapGetters({
            $_user: 'auth/getUser',
        }),
    },
    methods: {
        handleCreateModal() {
            this.$refs.modalCreate.handleCreateModal();
        },
    },
}
</script>

<style></style>
