<template>
  <div>
    <AppFixedPageTitle
      title="MAPA DO PONTO DE CARGA"
      icon="/img/icons/icons8/ios/business-building_white.png"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          :showCompanyPlants="true"
          :isLoading="isListing"
          @onSearchClick="listItems"
          @onClearClick="clearFilter"
        >
          <AppSearchBarFilterSection
              name="dados"
              icon="/img/icons/icons8/ios/info-squared_gray.png"
          >
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label
                  class="form-control-label fs-11 new-default-gray-font font-weight-400"
                >
                Selecionar
              </label>
              <PuzlSelect
                style="width: 100%"
                v-model.lazy="filter.cmc_group_uuid"
                :items="cmcGroups"
                :disableBoxShadow="true"
                label="group_name"
                customKey="uuid"
                placeholder="Grupo"
                class="select-sm col-md-12 px-0 new-default-black-font"
              />
            </div>
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label
                  class="form-control-label fs-11 new-default-gray-font font-weight-400"
                >
                Selecionar
              </label>
              <PuzlSelect
                style="width: 100%"
                v-model.lazy="filter.cmc_category_uuid"
                :items="cmcCaterories"
                :disableBoxShadow="true"
                customKey="uuid"
                placeholder="Categoria"
                class="select-sm col-md-12 px-0 new-default-black-font"
              />
            </div>
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label
                  class="form-control-label fs-11 new-default-gray-font font-weight-400"
                >
                Selecionar
              </label>
              <PuzlSelect
                style="width: 100%"
                v-model.lazy="filter.product_ids"
                :items="cmcProducts"
                :disableBoxShadow="true"
                placeholder="Produto"
                label="product_name"
                :multiple="true"
                class="select-sm col-md-12 px-0 new-default-black-font"
              />
            </div>
          </AppSearchBarFilterSection>
        </AppSearchBar>
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions
          :isBackButtonEnabled="false"
        >
          <AppPageHeaderActionsButton
            @click="openCmcAdjustmentCreateModal"
            text="novo"
            type="success"
            icon="/img/icons/plus-math--v1-white.png"
          />
          <AppPageHeaderActionsButton
            text="Ajustes"
            :isDropdown="true"
            type="warning"
            icon="/img/icons/icons8/ios/adjust_white.png"
          >
            <AppPageHeaderActionsButtonSectionDivider
              title="Geral"
            />
            <AppPageHeaderActionsButtonDropItem
              text="Temperatura Ambiente"
              icon="/img/icons/icons8/ios/dew-point_danger.png"
              link="/technology/adjustment/temperature"
            />
            <AppPageHeaderActionsButtonDropItem
              text="Ciclo do Concreto"
              icon="/img/icons/icons8/ios/sync-settings_warning.png"
              link="/technology/adjustment/concrete-cycle"
            />
            <AppPageHeaderActionsButtonDropItem
              text="Peça a Concretar"
              icon="/img/icons/icons8/ios/puzzle_black.png"
              link="/technology/adjustment/concrete-piece"
            />
            <AppPageHeaderActionsButtonDropItem
              text="Volume Carregado"
              icon="/img/icons/icons8/ios/scales--v1_primary.png"
              link="/technology/adjustment/volume"
            />
            <AppPageHeaderActionsButtonDropItem
              text="PUZL Reuse"
              icon="/img/icons/icons8/ios/recycle-sign_success.png"
              link="/technology/adjustment/reuse"
            />
            <AppPageHeaderActionsButtonSectionDivider
            title="Individual"
            />
            <AppPageHeaderActionsButtonDropItem
              text="Tipo"
              icon="/img/icons/test-tube.png"
              link="/technology/adjustment/types"
            />
            <AppPageHeaderActionsButtonDropItem
              text="Nível de Aplicação"
              icon="/img/icons/icons8/ios/3-circle_danger.png"
              link="/technology/adjustment/level-responsibility"
            />
            <AppPageHeaderActionsButtonDropItem
              text="Slump Final"
              icon="/img/icons/icons8/ios/vlc_warning.png"
              link="/technology/adjustment/final-slump"
            />
            <AppPageHeaderActionsButtonDropItem
              text="Contrato e Horário"
              icon="/img/icons/icons8/ios/client-company_success.png"
              link="/technology/adjustment/contract-hour"
            />
            <AppPageHeaderActionsButtonDropItem
              text="Ponto de Carga"
              icon="/img/icons/chemical-plant-black.png"
              link="/technology/adjustment/charge-point"
            />
            <AppPageHeaderActionsButtonSectionDivider
              title="Limites"
            />
            <AppPageHeaderActionsButtonDropItem
              text="MCC"
              icon="/img/icons/icons8/ios/sandbox2_warning.png"
              @click="openLimitSettingCreateModal"
            />
            <AppPageHeaderActionsButtonDropItem
              text="Ajustes Totais"
              icon="/img/icons/icons8/ios/customize-view_danger.png"
              @click="openAllLimitsSettingCreateModal"
            />
          </AppPageHeaderActionsButton>
          <AppPageHeaderActionsButton
            text="Relatórios"
            type="dark"
            icon="/img/icons/graph-report.png"
            :isDropdown="true"
          >
            <AppPageHeaderActionsButtonDropItem
              text="Resumo de Ponto de Carga"
              icon="/img/icons/icons8/ios/customize-view_danger.png"
              @click="openReportAutomationModal"
            />
            <AppPageHeaderActionsButtonDropItem
              text="Histórico de Alterações"
              icon="/img/icons/physics.png"
              link="/technology/adjustment-histories/"
            />
          </AppPageHeaderActionsButton>
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>
    <AppTabSelect
      :items="tabSelectItems"
      :isShowViewTypes="false"
      @onTabSelectItemClick="onTabSelectItemClick"
      @onViewTypeChange="(type) => listType = type"
    >
      <AppSelect
        v-model="orderBy.selected"
        :items.sync="orderBy.items"
        @onSelectItemClick="listItems(false)"
        variant="text-only"
      />
    </AppTabSelect>
    <div class="container-fluid">
      <CmcAdjustmentListPageCards
        @onEditItemClick="openCmcAdjustmentEditModal"
        @onReferenceClick="openReferenceModal"
        @onShowDosageAdjutmentClick="showDosageAdjustment"
        @onUpdateDosageValuesClick="updateCmcAdjustmentDosage"
        @onUpdateContentValuesClick="updateCmcAdjustmentContent"
        @onRemoveItemClick="removeCmcAdjustment"
        @onCancelContentClick="findCmcAdjustment"
      />
      <AppViewTrigger v-if="!isListing" @onIntersected="listItems(true)" />
      <PuzlEmptyData v-if="!isListing && !listed.items.length"/>
    </div>
    <ReferenceModal ref="referenceModal"/>
    <CmcAdjustmentCreateModal @createdCmcAdjustments="handleCreatedCmcAdjustment" ref="cmcAdjustmentCreateModal" />
    <CmcAdjustmentEditModal @updatedCmcAdjustment="handleUpdatedCmcAdjustment" ref="cmcAdjustmentEditModal" />
    <ReportAutomationModal ref="reportAutomationModal"/>
    <LimitSettingCreateModal ref="limitSettingCreateModal"/>
    <AllLimitsSettingCreateModal ref="allLimitsSettingCreateModal"/>
  </div>
</template>

<script setup>
import { reactive, ref, computed, onMounted, onUnmounted } from 'vue';
import cmcAdjustmentStore from "../../store";
import { 
  initCmcAdjustmentListFilterType,
  CmcAdjustmentListFilterType,
  CmcAdjustmentListType,
  CmcAdjustmentUpdateDosagesType,
  CmcAdjustmentUpdateContentsType,
  DosageAdjustmentByIdType,
  AgentType
} from '../../types';
import CmcAdjustmentFilterStatusEnum from "../../enums/CmcAdjustmentFilterStatusEnum";
import { useCmcAdjustmentHook } from "../../hooks/useCmcAdjustmentHook";
import { 
  AppFixedPageTitle, 
  AppPageHeader, 
  initSearchBarFilterType,
  AppSearchBarFilterSection,
  SearchBarFilterType,
  AppSearchBar,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  AppPageHeaderActionsButtonDropItem,
  AppPageHeaderActionsButtonSectionDivider,
  AppViewTrigger,
  AppTabSelect,
  AppSelect,
  TabSelectItemType,
} from '../../../../../components/AppGlobal';
import { Ref } from 'vue';
import { dialogs, progress } from "../../../../../helpers";
import CmcAdjustmentListPageCards from './CmcAdjustmentListPageCards.vue';
import PuzlEmptyData from "@/components/PuzlEmptyData";
import PuzlSelect from "@/components/PuzlSelect";
import ReferenceModal from "../reference/ReferenceModal.vue";
import CmcAdjustmentCreateModal from "../create/CmcAdjustmentCreateModal.vue";
import CmcAdjustmentEditModal from "../edit/CmcAdjustmentEditModal.vue";
import ReportAutomationModal from "../reports/ReportAutomationModal.vue";
import LimitSettingCreateModal from "../limit-setting/LimitSettingCreateModal.vue";
import AllLimitsSettingCreateModal from "../limit-setting/AllLimitsSettingCreateModal.vue";
/** History imports */
import AdjustmentHistoryActionsEnum from "../../../adjustment-history/enums/AdjustmentHistoryActionsEnum";
import { cmcAdjustmentCreateHistoryService, cmcAdjustmentCreateContentHistoryService } from '../../services';

/** Store Getters && Computeds */
const listed = computed(() => cmcAdjustmentStore.getListed());
const isListing = computed(() => cmcAdjustmentStore.getIsListing());

const {
  cmcGroups,
  cmcCaterories,
  cmcProducts,
  companyPlants,
  formatNewAgent,
  agentGroups
} = useCmcAdjustmentHook();

const tabSelectItems = computed(() => {
  return [
    {
      id: null,
      name: 'Todos',
      selected: filter.status === null,
    },
    {
      id: CmcAdjustmentFilterStatusEnum.keys.WITH_ADJUSTMENT,
      name: 'Com Ajuste',
      selected: filter.status === CmcAdjustmentFilterStatusEnum.keys.WITH_ADJUSTMENT,
    },
    {
      id: CmcAdjustmentFilterStatusEnum.keys.WITHOUT_ADJUSTMENT,
      name: 'Sem Ajuste',
      selected: filter.status === CmcAdjustmentFilterStatusEnum.keys.WITHOUT_ADJUSTMENT,
    },
    {
      id: CmcAdjustmentFilterStatusEnum.keys.UNAVAILABLE,
      name: 'Indisponível',
      selected: filter.status === CmcAdjustmentFilterStatusEnum.keys.UNAVAILABLE,
    },
  ];
});

/** @type {Ref<ReferenceModal>} */
const referenceModal = ref(null);

/** @type {Ref<CmcAdjustmentCreateModal>} */
const cmcAdjustmentCreateModal = ref(null);

/** @type {Ref<CmcAdjustmentEditModal>} */
const cmcAdjustmentEditModal = ref(null);

/** @type {Ref<ReportAutomationModal>} */
const reportAutomationModal = ref(null);

/** @type {Ref<LimitSettingCreateModal>} */
const limitSettingCreateModal = ref(null);

/** @type {Ref<AllLimitsSettingCreateModal>} */
const allLimitsSettingCreateModal = ref(null);

const listType = ref('cards');

/** Reactives */
/** @type {SearchBarFilterType} - Filtro do AppSearchBar */
const searchBarFilter = reactive(initSearchBarFilterType());

/** @type {CmcAdjustmentListFilterType} - Filtro principal */
const filter = reactive(initCmcAdjustmentListFilterType());

const orderBy = reactive({
  items: [
    {
      id: 0,
      name: "PADRÃO",
      selected_name: "ORDENAR",
      filter: [
        {
          column: "cmc_adjustments.id",
          is_desc: false,
        },
      ],
    },
    {
      id: 1,
      name: "A-Z",
      selected_name: "A-Z",
      icon: "/img/icons/icons8/ios/double-down.png",
      filter: [{
        column: "cmc_groups.group_name",
        is_desc: false,
      }],
    },
    {
      id: 2,
      name: "Z-A",
      selected_name: "Z-A",
      icon: "/img/icons/icons8/ios/double-up.png",
      filter: [{
        column: "cmc_groups.group_name",
        is_desc: true,
      }],
    },
    {
      id: 3,
      name: "CRIAÇÃO MAIS NOVA PARA A MAIS VELHA",
      selected_name: "CRIAÇÃO MAIS NOVA",
      icon: "/img/icons/icons8/ios/double-down.png",
      filter: [{
        column: "cmc_adjustments.created_at",
        is_desc: true,
      }],
    },
    {
      id: 4,
      name: "CRIAÇÃO MAIS VELHA PARA A MAIS NOVA",
      selected_name: "CRIAÇÃO MAIS VELHA",
      icon: "/img/icons/icons8/ios/double-up.png",
      filter: [{
        column: "cmc_adjustments.created_at",
        is_desc: false,
      }],
    },
    {
      id: 5,
      name: "ATUALIZAÇÃO MAIS NOVA PARA A MAIS VELHA",
      selected_name: "ATUALIZAÇÃO MAIS NOVA",
      icon: "/img/icons/icons8/ios/double-down.png",
      filter: [{
        column: "cmc_adjustments.updated_at",
        is_desc: true,
      }],
    },
    {
      id: 6,
      name: "ATUALIZAÇÃO MAIS VELHA PARA A MAIS NOVA",
      selected_name: " ATUALIZAÇÃO MAIS VELHA",
      icon: "/img/icons/icons8/ios/double-up.png",
      filter: [{
        column: "cmc_adjustments.updated_at",
        is_desc: false,
      }],
    },
  ],
  selected: 0,
});

onMounted(() => {
  clearFilter(true, true);
});

onUnmounted(() => {
  searchBarFilter.custom_search_values = [];
  cmcAdjustmentStore.resetStates();
  cmcAdjustmentStore.setPageState({ filter, searchBarFilter });
});

/**
 * @param {TabSelectItemType} item
 */
function onTabSelectItemClick(item) {
  if (filter.status !== item.id) {
    filter.status = item.id;
    listItems();
  };
};

/**
 * Limpa os filtros e atualiza a lista de itens.
 *
 * @param {boolean} isRefreshList - Atualiza a lista após limpar os filtros.
 * @param {boolean} withStoreFilters - Usa filtros da store se verdadeiro.
 */
function clearFilter(isRefreshList = true, withStoreFilters = false) {
  // Inicializa filtros com os valores padrão
  let searchBarFilterValue = defaultSearchBarFilter();
  let filterValue = defaultFilter();
  
  // Caso `withStoreFilters` seja verdadeiro, obter filtros armazenados na store
  const storeFilters = withStoreFilters ? cmcAdjustmentStore.getPageState() : null;
  if (storeFilters) {
    searchBarFilterValue = storeFilters.searchBarFilter;
    filterValue = storeFilters.filter;
  }

  // Aplica os filtros
  Object.assign(searchBarFilter, searchBarFilterValue);
  Object.assign(filter, filterValue);
  
  // Listar itens
  if (isRefreshList) {
    listItems();
  }
};

/**
 * Padrão do filtro AppSearchBar
 * @returns {SearchBarFilterType}
 */
function defaultSearchBarFilter() {
  return initSearchBarFilterType()
};

/**
 * Padrão do filtro principal
 * @returns {CmcAdjustmentListFilterType}
 */
function defaultFilter() {
  return {
    ...initCmcAdjustmentListFilterType(),
    status: null,
    cmc_group_uuid: null,
    cmc_category_uuid: null,
    product_ids: null
  };
};

/**
 * Listar itens
 * @param {boolean} isAccumulateItems
 */
function listItems(isAccumulateItems = false) {
  prepareFilter();
  cmcAdjustmentStore.list(filter, isAccumulateItems);
};

/**
 * Preparar filtro principal para listagem
 */
function prepareFilter() {
  filter.page = "";
  filter.custom_search.values = searchBarFilter.custom_search_values;
  filter.order_by = orderBy.items[orderBy.selected].filter;
  filter.company_plant_id = searchBarFilter.company_plant_selected;
};

/**
 * 
 * @param {CmcAdjustmentListType} adjustment 
 * @param {boolean} is_cancel
 */
function showDosageAdjustment(adjustment, is_cancel = false) {
  //se o collapse nunca foi aberto ou usuário clicar em cancelar, carrega os dados
  if(adjustment.dosage_adjustments == null || is_cancel) {
    progress.start();
    cmcAdjustmentStore.showDosageAdjustment(adjustment.id)
      .then((data) => {
        adjustment.dosage_adjustments = data;
        adjustment.observation = data.observation;
        adjustment.observation_content = data.observation_content;

        if(adjustment.dosage_adjustments.agent == null) {
          adjustment.dosage_adjustments.agent = formatNewAgent();
        }
      })
      .finally(() => progress.finish());
  }
}

/**
 * @param {CmcAdjustmentUpdateDosagesType} adjustment
 */
async function updateCmcAdjustmentDosage(adjustment) {
  progress.start();

  /** @type {CmcAdjustmentListType} */
  const cmcAdjustment = await findCmcAdjustmentById(adjustment.id);
  const has_agent = await hasAgent(adjustment.agent);

  cmcAdjustmentStore.updateDosages(adjustment, adjustment.id)
    .then((data) => {
        cmcAdjustmentCreateHistoryService.execute(
          AdjustmentHistoryActionsEnum.keys.ADJUST,
          cmcAdjustment,
          data,
          has_agent ? agentGroups : null 
        );
        dialogs.notify();
        listItems(true);
    })
    .finally(() => progress.finish());
}

/**
 * @param {CmcAdjustmentUpdateContentsType} adjustment
 */
async function updateCmcAdjustmentContent(adjustment) {
  progress.start();

  try {
    await cmcAdjustmentStore.updateContents(adjustment, adjustment.id);

    const cmcAdjustment = await findCmcAdjustmentById(adjustment.id);

    await cmcAdjustmentCreateContentHistoryService.execute(
      AdjustmentHistoryActionsEnum.keys.ADJUST,
      cmcAdjustment
    );

    dialogs.notify();
    listItems();
  } catch (error) {
    dialogs.notify(
      "danger",
      "Erro ao atualizar ajuste."
    );
  } finally {
    progress.finish();
  }
}

/**
 * 
 * @param {CmcAdjustmentListType} adjustment 
 * @param {boolean} is_cancel
 */
function findCmcAdjustment(adjustment, is_cancel = false) {
  progress.start();
  cmcAdjustmentStore.find(adjustment.id)
    .then((data) => {
        if(is_cancel) {
          adjustment.content = data.content;
          adjustment.content_min = data.content_min;
          adjustment.content_max = data.content_max;
          adjustment.observation_content = data.observation_content;
        }
    })
    .finally(() => progress.finish());
}

/**
 * @param {number} id
 * @returns {CmcAdjustmentListType}
 */
function findCmcAdjustmentById(id) {
  return cmcAdjustmentStore.find(id);
}

/**
 * 
 * @param {number} id 
 */
async function removeCmcAdjustment(id) {
  const isConfirmed = await dialogs.confirmRemove();
  if (isConfirmed) {
    progress.start();
    /** @type {CmcAdjustmentListType} */
    const cmcAdjustment = await findCmcAdjustmentById(id);

    await cmcAdjustmentStore.remove(id)
      .then(() => {
        cmcAdjustmentCreateHistoryService.execute(
          AdjustmentHistoryActionsEnum.keys.REMOVE,
          cmcAdjustment
        );
        /**
         * se ajuste possuia teor, gerar histórico
         * de remoção de teor
         */
        if(cmcAdjustment.has_content) {
          cmcAdjustmentCreateContentHistoryService.execute(
          AdjustmentHistoryActionsEnum.keys.REMOVE,
          cmcAdjustment
        );
        }
      })
      .finally(() => dialogs.notifyRemoved());
  }
  progress.finish();
}

/**
 * Função que verifica se ajuste possui aditivo
 * 
 * @param {Array<AgentType>} agents
 * @returns {boolean}
 */
function hasAgent(agents) {
  return agents.some(item => item.id !== null);
}

/**
 * 
 * @param {CmcAdjustmentListType} adjustment 
 */
const openReferenceModal = (adjustment) => {
  referenceModal.value.handleDatatoOpenModal(adjustment);
};

const openCmcAdjustmentCreateModal = () => {
  cmcAdjustmentCreateModal.value.handleCreateModal();
};

/**
 * Abrir modal para editar registro
 * @param {string} uuid
 */
function openCmcAdjustmentEditModal(uuid) {
  cmcAdjustmentEditModal.value.handleEditModal(uuid);
}

function openReportAutomationModal() {
  reportAutomationModal.value.handleOpenModal();
}

function openLimitSettingCreateModal() {
  limitSettingCreateModal.value.handleCreateModal();
}

function openAllLimitsSettingCreateModal() {
  limitSettingCreateModal.value.handleCreateModal();
}

function handleUpdatedCmcAdjustment() {
  listItems();
}

function handleCreatedCmcAdjustment() {
  listItems();
}

</script>

<style scoped>
</style>