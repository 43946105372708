export function viacep(zip_code) {
  let endPoint = `https://viacep.com.br/ws/${zip_code}/json/`
  return fetch(endPoint, {
    method: 'get'
  })
    .then(response => response.json())
    .then((response) => {
      return Promise.resolve({
        data: response
      })
    })
}
