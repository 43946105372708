<template>
<div>
    <modal :show.sync="modal">
        <template slot="header">
            <h5 class="modal-title">{{ title }}</h5>
        </template>
        <div>
            <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
                <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
                    <div>

                        <div class="form-group row m-0 p-0 m-1">
                            <label class="col-md-5 col-form-label form-control-label">
                                Status
                            </label>
                            <div class="col-md-7 pt-2">
                                <base-switch v-model="additional_classification.status" type="success" offText="inativo" onText="ativo" class="success"></base-switch>
                            </div>
                        </div>

                        <div class="form-group row m-0 p-0 mb-1">
                            <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                                Categoria MCC
                                <span class="text-danger">&nbsp;*</span>
                            </label>
                            <div class="col-md-7">
                                <validation-provider rules="required" v-slot="{errors}">
                                    <base-input input-classes="form-control-sm">
                                        <PuzlSelect 
                                            v-model="additional_classification.cmc_category_id" 
                                            :items="categories" 
                                            :loading="loadingCategory" 
                                            :disabled="loadingCategory" />
                                    </base-input>
                                </validation-provider>
                            </div>
                        </div>

                        <div class="form-group row m-0 p-0">
                            <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                                Classificação Adicional
                                <span class="text-danger">&nbsp;*</span>
                            </label>
                            <div class="col-md-7">
                                <validation-provider rules="required" v-slot="{errors}">
                                    <base-input input-group-classes="input-group-sm">
                                        <input 
                                            type="text" 
                                            v-model="additional_classification.additional_classification" 
                                            class="form-control form-control-sm" 
                                            :class="errors[0] ? 'is-invalid' : 'is-valid'" />
                                    </base-input>
                                </validation-provider>
                            </div>
                        </div>

                        <div class="form-group row m-0 p-0">
                            <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                                Observações:
                            </label>
                            <div class="col-md-7">
                                <base-input input-group-classes="input-group-sm">
                                    <textarea type="text" v-model="additional_classification.remarks" class="form-control form-control-sm">
                    </textarea>
                                </base-input>
                            </div>
                        </div>

                    </div>

                    <div class="modal-footer">
                        <base-button type="secondary" @click="openModal(false)">
                            Cancelar
                        </base-button>
                        <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingSave">
                            Salvar
                        </base-button>
                    </div>
                </form>
            </validation-observer>
        </div>
    </modal>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import PuzlSelect from "@/components/PuzlSelect"
const { formatErrorValidation } = require("@/plugins")
export default {
    name: "CreateAdditionalClassification",
    components: { PuzlSelect },
    props: {
        changeAdditionalClassification: { type: Function }
    },
    data() {
        return {
            title: 'Classificação Adicional MCC',
            modal: false,
            additional_classification: {
                status: true,
                cmc_category_id: '',
                additional_classification: '',
                remarks: '',
            },
            loadingSave: false,
            loadingCategory: true,
        }
    },
    computed: {
        ...mapGetters({
            'categories': 'category/fetch',
        }),
    },
    methods: {
        openModal(open) {
            this.loadData();
            this.additional_classification = {
                    status: true,
                    cmc_category_id: '',
                    additional_classification: '',
                    remarks: '',
                },
            this.modal = open
        },
        save() {
            this.$Progress.start()
            this.loadingSave = true
            this.$store.dispatch('cmcAdditionalClassification/add', this.additional_classification)
                .then(response => {
                    this.loadingSave = false
                    this.modal = false
                    this.$Progress.finish()
                    this.$notify({
                        type: response.error_type,
                        message: response.message
                    })
                    if (this.$parent.useChangeOptions) {
                        if (this.additional_classification.cmc_category_id == this.$parent.cmc.cmc_category_id) {
                            this.$parent.changeOptions('additional_classification');
                        }
                    }

                    this.changeAdditionalClassification(response.data);
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        let message = formatErrorValidation(error.response.data.errors)
                        this.$notify({
                            type: 'danger',
                            message: message
                        })
                    } else {
                        this.$notify({
                            type: error.data.error_type,
                            message: error.data.message
                        })
                    }
                    this.$Progress.finish()
                    this.loadingSave = false
                })
        },
        loadData() {
            this.loadingCategory = true;
            this.$store.dispatch('category/fetchItems').then(() => {
                this.loadingCategory = false;
            });
        },
    },
    mounted() {
        this.$refs.formValidator.validate();
    },
    created() {}
}
</script>

<style scoped>

</style>
