import * as types from './mutation_types'
import { createAxios } from "@/plugins/axios";
const endPoint = '/configuration/technical-permission'
export default {
  namespaced: true,
  state: {
    item: [],
  },
  getters:{
    show: state => state.item,
  },
  mutations: {
    [types.SHOW] (state, payload){
      state.item = payload
    },
  },
  actions: {
    getByUserId({commit}, userId) {
      return createAxios().get(`${endPoint}/${userId}`)
        .then(({data}) =>{
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    store({}, payload) {
      return createAxios().post(endPoint, payload)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
  }
}
