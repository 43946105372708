<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-gray d-inline-block mb-0">
            {{ this.$route.meta.breadcrumbs[1].title }}
          </h6>
          <nav
            aria-label="breadcrumb"
            class="d-none d-md-inline-block ml-md-4 text-gray"
          >
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#"><i :class="this.$route.meta.icon"></i></a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">{{ this.$route.meta.breadcrumbs[0].title }}</a>
              </li>
              <li class="breadcrumb-item" aria-current="page">
                {{ this.$route.meta.breadcrumbs[1].title }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- Header Menus -->
      <div class="row mb-3">
        <div class="col-md-2 mb-2">
          <router-link
            :to="{
              path: '/configuration/user',
              name: 'Usuários',
            }"
          >
            <base-button block size="lg" type="light" class="text-uppercase">
              voltar
            </base-button>
          </router-link>
        </div>
        <div class="col-md-2 mb-2">
          <base-button
            block
            size="lg"
            type="dark"
            class="text-uppercase"
            @click.prevent="handleUserDetail(user.uuid)"
            :disabled="loadingUsers"
          >
            <i class="fas fa-info main-icon-menu"></i>
            COMPLEMENTAR
          </base-button>
        </div>
        <div class="col-md-2 mb-2">
          <base-button
            block
            size="lg"
            type="warning"
            class="text-uppercase"
            @click.prevent="handleUserPermission(user.id, user.uuid)"
            :disabled="loadingUsers"
          >
            <i class="fas fa-key main-icon-menu"></i>
            PERMISSÃO
          </base-button>
        </div>
      </div>
    </base-header>

    <div class="container-fluid">
      <validation-observer
        v-slot="{ invalid, handleSubmit }"
        ref="formValidator"
      >
        <form
          class="needs-validation"
          @submit.prevent="handleSubmit(store)"
          autocomplete="off"
        >
          <card>
            <!-- Card header -->
            <div class="row align-items-center mb-3 card-body-molde">
              <div class="col-12">
                <!-- Title -->
                <div class="border-header-default-bottom p-1">
                  <h5 class="h3 m-0">Identificação</h5>
                </div>
              </div>
            </div>
            <!-- Card body -->
            <div>
              <div class="form-group row m-0 p-0">
                <div class="col-md-2 mb-1 pr-4">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    Status
                    <span class="text-danger">&nbsp;*</span>
                  </div>

                  <base-switch
                    v-model="user.status"
                    type="success"
                    offText="inativo"
                    onText="ativo"
                    class="success"
                  ></base-switch>
                </div>

                <div class="col-md-4 mb-1 pr-4">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    Nome completo
                    <span class="text-danger">&nbsp;*</span>
                  </div>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-classes="form-control-sm">
                      <input
                        v-model="user.name"
                        type="text"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>

                <div class="col-md-4 mb-1 pr-4">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    Nome abreviado
                    <span class="text-danger">&nbsp;*</span>
                  </div>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-classes="form-control-sm">
                      <input
                        v-model="user.short_name"
                        type="text"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <div class="col-md-6 mb-1 pr-4">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    E-mail
                  </div>

                  <validation-provider>
                    <base-input input-classes="form-control-sm">
                      <input
                        v-model="user.email"
                        type="email"
                        class="form-control form-control-sm"
                      />
                    </base-input>
                  </validation-provider>
                </div>

                <div class="col-md-6 mb-1 pr-4">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    Redefinir Senha
                  </div>
                  <validation-provider>
                    <base-input input-classes="form-control-sm">
                      <input
                        v-model="user.reset_password"
                        type="password"
                        class="form-control form-control-sm"
                        autocomplete="off"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <div class="col-md-4 mb-1 pr-4">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    CPF/CNPJ
                  </div>
                  <base-input input-classes="form-control-sm">
                    <input
                      class="form-control form-control-sm"
                      v-model="user.document"
                      inputmode="numeric"
                      v-mask="['###.###.###-##', '##.###.###/####-##']"
                    />
                  </base-input>
                </div>

                <div class="col-md-4 mb-1 pr-4">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    RG
                  </div>
                  <base-input input-classes="form-control-sm">
                    <input
                      class="form-control form-control-sm"
                      v-model="user.national_id_card"
                      type="text"
                    />
                  </base-input>
                </div>

                <div class="col-md-4 mb-1 pr-4">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    Celular
                  </div>
                  <base-input input-classes="form-control-sm">
                    <input
                      class="form-control form-control-sm"
                      v-model="user.phone"
                      inputmode="numeric"
                      v-mask="['(##) # ####-####']"
                    />
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <div class="col-md-4 mb-1 pr-4">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    Data de nascimento
                  </div>
                  <base-input input-group-classes="input-group-sm">
                    <el-date-picker
                      size="mini"
                      v-model="user.birth_date"
                      type="date"
                      format="dd/MM/yyyy"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </base-input>
                </div>

                <div class="col-md-4 mb-1 pr-4">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    Estado civil
                  </div>
                  <base-input input-classes="form-control-sm">
                    <input
                      class="form-control form-control-sm"
                      v-model="user.marital_status"
                      type="text"
                    />
                  </base-input>
                </div>

                <div class="col-md-4 mb-1 pr-4">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    Matrícula (R.E.)
                  </div>
                  <base-input input-classes="form-control-sm">
                    <input
                      class="form-control form-control-sm"
                      v-model="user.registration"
                      type="text"
                    />
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <div class="col-md-4">
                  <div class="pb-1 col-form-label form-control-label">
                    Cargo
                    <span
                      class="btn btn-sm btn-success p-1 ml-1"
                      @click.prevent="handleCreateJobDescription()"
                    >
                      <i class="fas fa-plus"></i> Novo
                    </span>
                  </div>
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                      v-model="user.job_description_uuid"
                      :items="job_descriptions"
                      customKey="uuid"
                      label="description"
                      :loading="loadingJobDescription"
                      :disabled="loadingJobDescription"
                    />
                  </base-input>
                </div>

                <div class="col-md-4 mb-1 pr-4">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    Regime de contrato
                    <span
                      class="btn btn-sm btn-success p-1 ml-1"
                      @click.prevent="handleCreateContractRegime()"
                    >
                      <i class="fas fa-plus"></i> Novo
                    </span>
                  </div>
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                      v-model="user.contract_regime_uuid"
                      :items="contract_regimes"
                      customKey="uuid"
                      label="description"
                      :loading="loadingContractRegimes"
                      :disabled="loadingContractRegimes"
                    />
                  </base-input>
                </div>

                <div class="col-md-4 mb-1 pr-4">
                  <div class="pb-0 mb-2 col-form-label form-control-label">
                    Data de admissão
                  </div>
                  <base-input input-group-classes="input-group-sm">
                    <el-date-picker
                      size="mini"
                      v-model="user.admission_date"
                      type="date"
                      format="dd/MM/yyyy"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </base-input>
                </div>
              </div>

              <div class="row align-items-center mb-3 mt-4">
                <div class="col-12">
                  <!-- Title -->
                  <div class="border-header-warning-bottom p-1">
                    <h5 class="h4 m-0">Financeiro/Contábil</h5>
                  </div>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <div class="col-md-6">
                  <div class="pb-1 col-form-label form-control-label">
                    Centro de custo
                  </div>
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                      v-model="user.cost_center_uuid"
                      :items="cost_centers"
                      customKey="uuid"
                      :loading="loadingCostCenters"
                      :disabled="loadingCostCenters"
                    />
                  </base-input>
                </div>

                <div class="col-md-6">
                  <div class="pb-1 col-form-label form-control-label">
                    Plano de Contas
                  </div>
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                      v-model="user.chart_account_uuid"
                      :items="chart_accounts"
                      customKey="uuid"
                      :loading="loadingChartAccounts"
                      :disabled="loadingChartAccounts"
                    />
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <div class="col-md-6">
                  <div class="pb-1 col-form-label form-control-label">
                    Central
                  </div>
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                      v-model="user.company_plant_uuid"
                      :items="plants"
                      customKey="uuid"
                      :loading="loadingPlant"
                      :disabled="loadingPlant"
                    />
                  </base-input>
                </div>

                <div class="col-md-6">
                  <div class="pb-1 col-form-label form-control-label">CNPJ</div>
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                      v-model="user.company_plant_issuer_id"
                      :items="company_issuers"
                      :disabled="loadingCompanyIssuers"
                      :labelMask="true"
                      label="business_name$ - $ein"
                    />
                  </base-input>
                </div>
              </div>

              <div class="row align-items-center mb-3 mt-4">
                <div class="col-12">
                  <!-- Title -->
                  <div class="border-header-danger-bottom p-1">
                    <h5 class="h4 m-0">Operacional</h5>
                  </div>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <div class="col-md-2">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    Motorista ?
                  </div>
                  <base-switch
                    v-model="user.driver"
                    type="success"
                    offText="inativo"
                    onText="ativo"
                    class="success"
                    @input="checkDriverStatus()"
                  ></base-switch>
                </div>

                <div class="col-md-2">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    Concreto
                  </div>
                  <base-switch
                    v-model="user.is_concrete"
                    type="success"
                    offText="não"
                    onText="sim"
                    class="success"
                    :disabled="!user.driver"
                  ></base-switch>
                </div>

                <div class="col-md-2">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    Serviço
                  </div>
                  <base-switch
                    v-model="user.is_service"
                    type="success"
                    offText="não"
                    onText="sim"
                    class="success"
                    :disabled="!user.driver"
                  ></base-switch>
                </div>

                <div class="col-md-2">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    Terceiro
                  </div>
                  <base-switch
                    v-model="user.is_internal_service"
                    type="success"
                    offText="não"
                    onText="sim"
                    class="success"
                    :disabled="!user.driver"
                  >
                  </base-switch>
                </div>

                <div class="col-md-3">
                  <div class="pb-1 col-form-label form-control-label">
                    Categoria de equipamento
                  </div>
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                      v-model="user.equipment_category_id"
                      :items="$_equipmentCategorys"
                      label="category_name"
                      :disabled="loadingEquipmentsCategorys || !user.driver"
                      @input="getEquipmentByCategory"
                    />
                  </base-input>
                </div>

                <div class="col-md-3">
                  <div class="pb-1 col-form-label form-control-label">
                    Equipamento padrão
                  </div>
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                      v-model="user.equipment_default"
                      :items="$_equipmentsByCategory"
                      customKey="uuid"
                      :labelMask="true"
                      :loading="loadingEquipmentDefault"
                      :disabled="
                        loadingEquipmentsCategorys ||
                        !user.equipment_category_id
                      "
                      label="code$ | $equipment_types_description"
                    />
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <div class="col-md-4 mb-1 pr-4">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    Tipo de carteira
                  </div>
                  <base-input input-classes="form-control-sm">
                    <input
                      class="form-control form-control-sm"
                      v-model="user.driver_type"
                      type="text"
                    />
                  </base-input>
                </div>

                <div class="col-md-4 mb-1 pr-4">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    Número da Habilitaçao
                  </div>
                  <base-input input-classes="form-control-sm">
                    <input
                      class="form-control form-control-sm"
                      v-model="user.driver_number"
                      type="text"
                    />
                  </base-input>
                </div>

                <div class="col-md-4 mb-1 pr-4">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    Vencimento
                  </div>
                  <base-input input-group-classes="input-group-sm">
                    <el-date-picker
                      size="mini"
                      v-model="user.driver_validity"
                      type="date"
                      format="dd/MM/yyyy"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </base-input>
                </div>
              </div>

              <div class="row align-items-center mb-3 mt-4">
                <div class="col-12">
                  <!-- Title -->
                  <div class="border-header-primary-bottom p-1">
                    <h5 class="h4 m-0">Comercial</h5>
                  </div>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <div class="col-md-4">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    Vendedor / Representante comercial
                  </div>
                  <base-switch
                    v-model="user.is_seller"
                    type="success"
                    offText="inativo"
                    onText="ativo"
                    class="success"
                    @input="checkSellerStatus()"
                  >
                  </base-switch>
                </div>

                <div class="col-md-4">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    E-mails de faturamento
                  </div>
                  <base-switch
                    v-model="user.is_invoicing_emails"
                    type="success"
                    offText="Não"
                    onText="Sim"
                    class="success"
                    :disabled="!user.is_seller"
                  >
                  </base-switch>
                </div>

                <div class="col-md-4">
                  <div class="pb-1 col-form-label form-control-label">
                    Comissão
                  </div>
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                      v-model="user.commission_uuid"
                      :items="commissions"
                      customKey="uuid"
                      :loading="loadingCommissions"
                      :disabled="loadingCommissions"
                    />
                  </base-input>
                </div>
              </div>

              <div class="row align-items-center mb-3 mt-4">
                <div class="col-12">
                  <!-- Title -->
                  <div class="border-header-danger-bottom p-1">
                    <h5 class="h4 m-0">Comissão sobre outros colaboradores</h5>
                  </div>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 col-form-label form-control-label">
                  Usuário
                </label>
                <div class="col-md-8">
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                      v-model="others_commission.user_uuid_commission"
                      :items="filteredUsers"
                      customKey="uuid"
                      :loading="loadingUsers"
                      :disabled="loadingUsers"
                    />
                  </base-input>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-4 pb-0 mb-1 col-form-label form-control-label"
                >
                  Comissão
                </label>
                <div class="col-md-8 mb-1 pr-4">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-model="others_commission.commision"
                      inputmode="numeric"
                      v-mask="['##']"
                      class="form-control form-control-sm"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0"> % </small>
                    </template>
                  </base-input>
                </div>
              </div>
              <div class="modal-footer">
                <base-button
                  type="primary"
                  @click.prevent="addOtherCommission()"
                >
                  adicionar
                </base-button>
              </div>

              <div
                class="m-2 mt-3"
                v-for="(itemOtherCommision, index) in user.others_commissions"
                :key="index"
              >
                <card class="mb-1 border card--hover card-body-molde">
                  <div class="row align-items-center">
                    <div class="col-auto">
                      <h4>{{ itemOtherCommision.user_name }}</h4>
                    </div>
                    <div class="col">
                      <h4>{{ itemOtherCommision.commision | percent(0) }}</h4>
                    </div>
                    <div class="col-auto">
                      <button
                        type="button"
                        class="btn btn-sm rounded-circle btn-danger"
                        @click="removeOtherCommission(index)"
                      >
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                </card>
              </div>
            </div>
            <div class="modal-footer">
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                :loading="loadingStore"
              >
                Salvar
              </base-button>
            </div>
          </card>
        </form>
      </validation-observer>
    </div>
    <ModalSetPlant ref="modalSetPlant" />
    <ModalSetUserPermission ref="modalSetUserPermission" />
    <ModalUserDetail ref="modalUserDetail" />
    <ModalSetUserRole ref="modalSetUserRole" />
    <CreateJobDescription ref="modalCreateJobDescription" />
    <CreateContractRegime ref="modalCreateContractRegime" />
    <ModalSetSellers ref="modalSetSeller" />
    <ModalUserPermission ref="modalUserPermission" />
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import ModalSetPlant from './_ModalSetPlant'
import ModalSetUserPermission from './_ModalSetUserPermission'
import ModalUserDetail from './_ModalUserDetail'
import ModalSetUserRole from './_ModalSetUserRole'
import CreateJobDescription from "../JobDescription/Shared/_Create.vue"
import CreateContractRegime from "../ContractRegime/Shared/_Create.vue"
import ModalSetSellers from './_ModalSetSellers'
import PuzlSelect from "@/components/PuzlSelect"
const {formatErrorValidation} = require("@/plugins")
import { seller as $_totvs }  from "@/plugins/microservices/totvs";
import ModalUserPermission from './_ModalUserPermission';

export default {
  name: "EditUser",
  components: {
    ModalSetPlant,
    ModalSetUserPermission,
    ModalUserDetail,
    ModalSetUserRole,
    CreateJobDescription,
    CreateContractRegime,
    ModalSetSellers,
    PuzlSelect,
    ModalUserPermission,
  },
  data() {
    return {
      loadingPlant: true,
      loadingJobDescription: true,
      loadingSave: false,
      invalid: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      user: {
        status: true,
        is_user: true,
        is_seller: false,
        is_concrete: false,
        is_service: false,
        is_external_service: false,
        is_invoicing_emails: false,
        name: null,
        short_name: null,
        email: null,
        password: null,
        reset_password: null,
        document: null,
        phone: null,
        national_id_card: null,
        birth_date: null,
        marital_status: null,
        registration: null,
        job_description_uuid: null,
        contract_regime_uuid: null,
        admission_date: null,
        cost_center_uuid: null,
        chart_account_uuid: null,
        company_plant_uuid: null,
        company_plant_issuer_id: null,
        driver: false,
        equipment_type: null,
        equipment_category_id: null,
        equipment_default: null,
        driver_number: null,
        driver_validity: null,
        commission_uuid: null,
        others_commissions: []
      },
      others_commission: {
        user_name: null,
        user_uuid_commission: null,
        commision: null,
      },
      loadingStore: false,
      loadingUsers: true,
      loadingCostCenters: true,
      loadingChartAccounts: true,
      loadingCompanyIssuers: true,
      loadingContractRegimes: true,
      loadingCommissions: true,
      loadingEquipmentsCategorys: true,
      loadingEquipmentDefault: false,
      company_issuers: [],
    };
  },
  computed: {
    ...mapGetters({
      plants: "plant/activeItems",
      users: "user/fetch",
      job_descriptions: "jobDescription/fetch",
      cost_centers: "costCenter/fetch",
      chart_accounts: "chartAccount/fetch",
      contract_regimes: "contractRegime/fetch",
      commissions: "commission/fetch",
      $_equipmentCategorys: "equipmentCategory/fetch",
      $_equipmentsByCategory: "equipment/fetchEquipmentsByCategory",
    }),
    filteredUsers() {
      return this.users.filter((item) => item.is_seller === 1)
    },
  },
  watch: {
    'user.company_plant_uuid': function () {
      this.changePlantIssuer(this.user.company_plant_uuid)
    },
  },
  methods: {
    changePlantIssuer(company_plant_uuid){
      this.loadingCompanyIssuers = true
      let [issuer] = this.plants.filter((item) => item.uuid === company_plant_uuid)
      this.company_issuers = issuer.company_issuers
      this.loadingCompanyIssuers = false
    },
    addOtherCommission(){
      let user_uuid_commission = this.others_commission.user_uuid_commission
      let others_commissions = this.user.others_commissions

      let commissions = {...this.others_commission}
      let [user] = this.users.filter((item) => item.uuid === user_uuid_commission)
      commissions.user_name = user.name
      commissions.user_uuid_commission = user_uuid_commission
      commissions.commision = this.others_commission.commision

      if(others_commissions.length > 0){
        let [check_user] = others_commissions.filter((item) => item.user_uuid_commission === user_uuid_commission)
        if(check_user && check_user.user_uuid_commission === user_uuid_commission){
          this.$notify({type: 'warning', message: 'Este usuário já foi selecionado!'})
          return
        } 
      }

      this.user.others_commissions.push(commissions)
    },
    removeOtherCommission(index) {
      this.user.others_commissions.splice(index, 1);
    },
    store() {
      this.$Progress.start();
      this.loadingStore = true;
      this.$store
        .dispatch("user/update", this.user).then(response => {
          this.loadingStore = false
          this.$Progress.finish()

          this.$notify({
            type: response.error_type,
            message: response.message
          })
        this.user.is_seller && $_totvs.update(this.user);
        this.$router.push({
          path: '/configuration/user',
          name: 'Usuários',
        })

        })
        .catch(error => {
          if (error.status) {
            this.$Progress.finish()
            this.loadingStore = false
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            })
          } else {
            if (error.response.status === 422) {
              let message = formatErrorValidation(error.response.data.errors)
              this.$notify({
                type: 'danger',
                message: message
              })
              this.$Progress.finish()
              this.loadingStore = false
            } else {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              })
              this.$Progress.finish()
              this.loadingStore = false
            }
          }
        });
    },
    fetch(){
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      let loader = this.$loading.show();
      this.$store.dispatch("user/show", this.$route.params.user_uuid).then(({data}) => {
        this.user = {
          id: data.id,
          uuid: data.uuid || "",
          status: data.status,
          is_user: data.is_user,
          is_seller: data.is_seller,
          is_concrete: data.is_concrete,
          is_service: data.is_service,
          is_invoicing_emails: data.is_invoicing_emails,
          name: data.name || "",
          short_name: data.short_name || "",
          email: data.email || "",
          password: data.password,
          reset_password: data.reset_password,
          document: data.detail? data.detail.document : "",
          phone: data.phone || "",
          national_id_card: data.detail? data.detail.national_id_card : "",
          birth_date: data.detail? data.detail.birth_date : "",
          marital_status: data.detail? data.detail.marital_status : "",
          registration: data.detail? data.detail.registration : "",
          admission_date: data.detail? data.detail.admission_date : "",
          job_description_uuid: (data.detail && data.detail.job_description) ? data.detail.job_description.uuid : "",
          contract_regime_uuid: (data.detail && data.detail.contract_regime) ? data.detail.contract_regime.uuid : "",
          cost_center_uuid: data.financial ? data.financial.cost_center_uuid : "",
          chart_account_uuid: data.financial ? data.financial.chart_account_uuid : "",
          company_plant_uuid: data.financial ? data.financial.company_plant_uuid : "",
          company_plant_issuer_id: data.financial ? data.financial.company_plant_issuer_id : "",
          driver: data.operational ? data.operational.driver : false,
          equipment_type: data.operational ? data.operational.equipment_type : "",
          equipment_default: data.operational ? data.operational.equipment_default : "",
          equipment_category_id: data.operational ? data.operational.equipment_category_id : "",
          driver_type: data.operational ? data.operational.driver_type : "",
          driver_number: data.operational ? data.operational.driver_number : "",
          driver_validity: data.operational ? data.operational.driver_validity : "",
          commission_uuid: data.user_commercial ? data.user_commercial.commission_uuid : "",
          others_commissions: data.user_commercial_commision,
        };
        loader.hide();
        if (this.user.equipment_category_id) {
          this.$store.dispatch("equipment/getEquipmentByCategory", this.user.equipment_category_id)
        }
        //Quando o editar é aberto após cadastrar o usuário.
        if (this.$route.query.modal) {
          this.handleUserPermission(this.user.id, this.user.uuid)
        }
      });
    },
    getEquipmentByCategory() {
      this.loadingEquipmentDefault = true
      this.$store.dispatch("equipment/getEquipmentByCategory", this.user.equipment_category_id).then(() => {
        this.loadingEquipmentDefault = false
      })
      this.user.equipment_default = null
    },
    checkDriverStatus() {
      if (!this.user.driver) {
        this.user.equipment_category_id = null;
        this.user.is_concrete = false;
        this.user.is_service = false;
      } else {
        this.user.is_concrete = true;
        this.user.is_service = false;
      }
      if (!this.user.equipment_category_id) {
        this.user.equipment_default = null;
      }
    },
    checkSellerStatus() {
      if (!this.user.is_seller) {
        this.user.is_invoicing_emails = false;
      }
    },
    /**
     * @param {string} userUuid 
     */
    handleUserDetail(userUuid) {
      this.$refs.modalUserDetail.openModal(userUuid);
    },
    /**
     * @param {number} userId 
     * @param {string} userUuid 
     */
    handleUserPermission(userId, userUuid) {
      this.$refs.modalUserPermission.openModal(userId, userUuid);
    },
    handleCreateJobDescription() {
      this.$refs.modalCreateJobDescription.handleCreateModal();
    },
    handleCreateContractRegime() {
      this.$refs.modalCreateContractRegime.handleCreateModal();
    },
  },
  mounted() {
    this.$refs.formValidator.validate();
    this.$store.dispatch("plant/fetchItemsActive", 0).then(() => {
      this.loadingPlant = false
    })
    this.$store.dispatch("user/fetchItems").then(() => {
      this.loadingUsers = false
    })
    this.$store.dispatch("costCenter/fetchItems").then(() => {
      this.loadingCostCenters = false
    })
    this.$store.dispatch("user/fetchItems").then(() => {
      this.loadingUsers = false;
    });
    this.$store.dispatch("chartAccount/fetchItems").then(() => {
      this.loadingChartAccounts = false
    })
    this.$store.dispatch("contractRegime/fetchItems").then(() => {
      this.loadingContractRegimes = false
    })
    this.$store.dispatch("commission/fetchItems").then(() => {
      this.loadingCommissions = false
    })
    this.$store.dispatch("jobDescription/fetchItems").then(() => {
      this.loadingJobDescription = false
    })
    this.$store.dispatch("equipmentCategory/fetchItems").then(() => {
      this.loadingEquipmentsCategorys = false
    })
    this.fetch()
  }
}
</script>
