<template>
  <div class="container-fluid">
    <!-- Filtros -->
    <section>
      <MultiFilter :status="multi_filter" ref="multi-filter" :filter="filter" @fetch="handleFetch">
        <div class="col-md-4 pl-2 pr-2 pb-2">
          <label>Data</label>
          <InputDatePicker 
            :isRange="false" 
            @handleFilterDate="handleFilterDate"
            @input="loadingTable = true, loadingCards = true"
            size="small" 
            ref="date-picker" />
        </div>
        <div class="col-md-4 pl-2 pr-2 pb-2">
          <label> Filtro de vendedores </label>
          <PuzlSelect
            @input="loadingTable = true, init({})" 
            v-model="filter.seller_uuids" 
            :items="$_sellers" 
            customKey="uuid" 
            multiple 
            placeholder="Vendedores" />
        </div>
      </MultiFilter>
    </section>

    <!-- Cards -->
    <section>
      <div v-if="$_general_cards && !loadingCards" class="row align-items-center mt-n3">
        <div class="col-md-4 col-sm-6">
          <div class="mb-3 card">
            <div class="p-3 card-body">
              <div class="row">
                <div class="col-9 pr-0" style="margin-top: -5px">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold pl-2">
                    <small class="font-weight-bold"> VOLUME DE CONCRETO</small>
                  </p>
                  <h3 class="font-weight-semibold mb-0 pl-2 text-lowercase">
                    <span class="numeric">
                      {{ $_general_cards.concrete.expected_volume }} m³ ({{ $_general_cards.concrete.expected_quantity }}) 
                    </span>/ 
                    <span class="numeric text-success">
                      {{ $_general_cards.concrete.performed_volume }} m³ ({{ $_general_cards.concrete.performed_quantity }})
                    </span>
                  </h3>
                </div>
                <div class="col-3" style="margin-top: -5px">
                  <div class="text-center shadow icon icon-shape bg-primary rounded-circle float-right">
                    <i class="text-white fa fa-flask"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="mb-3 card">
            <div class="p-3 card-body">
              <div class="row">
                <div class="col-9 pr-0" style="margin-top: -5px">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold pl-2">
                    <small class="font-weight-bold"> VOLUME DE SERVIÇOS</small>
                  </p>
                  <h3 class="font-weight-semibold mb-0 pl-2 text-lowercase">
                    <span class="numeric">
                      {{ $_general_cards.service.expected_volume }} m³ ({{ $_general_cards.service.expected_quantity }})
                    </span>/
                    <span class="numeric text-success">
                      {{ $_general_cards.service.performed_volume }} m³ ({{ $_general_cards.service.performed_quantity }})
                    </span>
                  </h3>
                </div>
                <div class="col-3" style="margin-top: -5px">
                  <div class="text-center shadow icon icon-shape bg-info rounded-circle float-right">
                    <i class="text-white fa-solid fa-list-check"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row card-wrapper" v-show="loadingCards">
        <div v-for="index in 2" :key="index" class="col-md-3">
          <div class="card card-border-top-light bg-gray-content">
            <div style="padding: 0.7rem" class="card-body">
              <div class="row">
                <div class="col-md-5">
                  <div><span class="bar button"></span></div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-11">
                  <div><span class="bar button"></span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Tabela de serviços -->
    <section>
      <div class="row">
        <div class="col-12">
        <div>
            <ul role="tablist" class="nav nav-tabs nav-justified">
              <li role="presentation" class="nav-item">
                <a
                  @click.prevent="
                    init({}, true),
                    viewType = 'services',
                    loadingTable = true
                  "
                  role="tab"
                  aria-selected="true"
                  aria-setsize="4"
                  aria-posinset="1"
                  href="#"
                  target="_self"
                  class="nav-link"
                  style="padding: 8px 0; border: 1px solid #DCDFE6"
                  :style="
                    viewType === 'services'
                      ? 'background-color: #1a70b7; color: white !important;'
                      : 'background-color: white; color: black;'
                  "
                  :class="viewType === 'services' && 'active'"
                >
                  <b class="font-weight-500"> Com serviços </b>
                </a>
              </li>
              <li role="presentation" class="nav-item">
                <a
                  @click.prevent="
                    viewType = 'schedules',
                    loadingTable = true,
                    init({}, true)
                  "
                  role="tab"
                  tabindex="-1"
                  aria-selected="false"
                  aria-setsize="4"
                  aria-posinset="2"
                  href="#"
                  target="_self"
                  class="nav-link"
                  style="padding: 8px 0; border: 1px solid #DCDFE6"
                  :style="
                    viewType === 'schedules'
                      ? 'background-color: #1a70b7; color: white !important;'
                      : 'background-color: white; color: black;'
                  "
                  :class="viewType === 'schedules' && 'active'"
                >
                  <b class="font-weight-500"> Sem serviços </b>
                </a>
              </li>
            </ul>
          </div>

          <div 
            class="card"
            style="
              border-top: 0px !important;
              border-top-left-radius: 0px !important;
              border-top-right-radius: 0px !important;
            "
            v-if="viewType === 'services'"
          >
            <h4 class="font-weight-semibold mb-0 pl-4 mt-3">
              <span class="text-success">Programações com serviço</span>
            </h4>
            <div class="card-body mt-n2">
              <div class="col-md-12 pl-md-0 table-responsive">
                <table class="table table-sm table-bordered">
                  <thead>
                    <tr class="fixed">
                      <th class="text-center table-column-light-gray text-dark">Equipamento</th>
                      <th class="text-center table-column-light-gray text-dark">Cliente</th>
                      <th class="text-center table-column-light-gray text-dark">Vendedor</th>
                      <th class="text-center table-column-light-gray text-dark">Bairro/Município</th>
                      <th class="text-center table-column-light-gray text-dark">Horário</th>
                      <th class="text-center table-column-light-gray text-dark">O.S.</th>
                      <th class="text-center table-column-light-gray text-dark">Volume</th>
                      <th class="text-center table-column-light-gray text-dark">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in 10" v-show="loadingTable">
                      <th colspan="12">
                        <SkeletonPuzl type="button"/>
                      </th>
                    </tr>
                    <tr v-for="(item, index) in $_services" :key="index">
                      <td class="text-left">
                        {{ item.equipment_code || "Sem equipamento" }}
                      </td>
                      <td class="text-left">
                        <el-popover trigger="click" placement="bottom" class="p-0">
                          <span>{{ item.construction_name }}</span>
                          <base-button
                            outline
                            slot="reference"
                            size="sm"
                            type="secundary"
                            class="p-0 m-0 shadow-none custom-popover-button"
                          >
                            {{ item.customer_name }}
                          </base-button>
                        </el-popover>
                      </td>
                      <td class="text-left">{{ item.seller_name }}</td>
                      <td class="text-left">
                        <el-popover trigger="click" placement="bottom" class="p-0">
                          <span>
                            {{
                              `${item.construction_address}, ${item.construction_number}, ${item.construction_district} 
                               ${item.construction_city}/${item.construction_state}-${item.construction_country}`
                            }}
                          </span>
                          <base-button
                            outline
                            slot="reference"
                            size="sm"
                            type="secundary"
                            class="p-0 m-0 shadow-none custom-popover-button"
                          >
                            {{ item.construction_district }} / {{ item.construction_city }}
                          </base-button>
                        </el-popover>
                      </td>
                      <td class="text-center">{{ item.start_at | parseDate("H:mm") }}</td>
                      <td role="button" @click.prevent="getScheduleServiceOrderReport(item)" class="text-center">
                        {{ item.order_number || "Emitir" }}
                      </td>
                      <td class="text-center text-lowercase">
                        {{ item.volume }} m³ /
                        <span
                          :class="item.status !== 3 ? 'text-success' : 'text-danger text-uppercase'"
                          class="text-center custom-popover-button"
                        >
                          {{ item.status !== 3 ? `${item.volume_performed} m³` : "Cancelada" }}
                        </span>
                      </td>
                      <td class="text-center">
                        <base-button
                          @click.prevent="handleEditService(item)"
                          outline
                          slot="reference"
                          size="sm"
                          type="secundary"
                          class="p-0 m-0 shadow-none"
                        >
                          <img src="/img/icons/create-new.png" width="23px" height="23px">
                        </base-button>
                        <base-button
                          :disabled="!item.order_number"
                          @click.prevent="handleServiceCycle(item)"
                          outline
                          size="sm"
                          type="secundary"
                          class="p-0 m-0 shadow-none"
                        >
                          <img src="/img/icons/clock--v1.png" width="23px" height="23px">
                        </base-button>
                        <el-popover trigger="click" placement="bottom" class="p-0">
                          <div class="border-left border-3 border-primary p-2 mt-1">
                            <h5 class="h4 mb-0">{{ item.formulation_title }}</h5>
                            <div class="mt-2 mb-3">
                              <h5 class="small">{{ item.formulation_body }}</h5>
                            </div>
                            <div class="mt-2 mb-3" v-if="item.formulation_footer">
                              <h5 class="small">{{ item.formulation_footer }}</h5>
                            </div>
                          </div>
                          <div class="border-left border-3 border-info p-1 mt-2">
                            <h5 class="m-0 pl-1">{{ item.concreted_piece }}</h5>
                          </div>
                          <base-button outline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none">
                            <img src="/img/icons/test-tube.png" width="23px" height="23px">
                          </base-button>
                        </el-popover>
                        <el-popover trigger="hover" placement="right" class="p-0 pr-0">
                            {{ item.company_plant_name }}
                          <base-button 
                            outline slot="reference" size="sm" type="secundary"
                            class="p-0 m-0 shadow-none text-indigo"
                          >
                            <img src="/img/icons/chemical-plant-black.png" width="23px" height="23px" class="mx-1" />
                          </base-button>
                        </el-popover>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <PuzlEmptyData v-show="$_services && !$_services.length && !loadingSkeleton" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

     <!-- Tabela de programações -->
    <section>
      <div 
        class="card"
        style="
          border-top: 0px !important;
          border-top-left-radius: 0px !important;
          border-top-right-radius: 0px !important;
        "
        v-if="viewType === 'schedules'"
      >
        <h4 class="font-weight-semibold mb-0 pl-4 mt-3">
          <span class="text-success">Programações sem serviço</span>
        </h4>
        <div class="card-body mt-n2">
          <div class="col-md-12 pl-md-0 table-responsive">
            <table class="table table-sm table-bordered">
              <thead>
                <tr v-for="item in 10" v-show="loadingTable">
                  <th colspan="12">
                    <SkeletonPuzl type="button"/>
                  </th>
                </tr>
                <tr class="fixed">
                  <th class="text-center table-column-light-gray text-dark">Cliente</th>
                  <th class="text-center table-column-light-gray text-dark">Vendedor</th>
                  <th class="text-center table-column-light-gray text-dark">Bairro/Município</th>
                  <th class="text-center table-column-light-gray text-dark">Horário</th>
                  <th class="text-center table-column-light-gray text-dark">Volume</th>
                  <th class="text-center table-column-light-gray text-dark">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in $_schedules" :key="index">
                  <td class="text-left">
                    <el-popover trigger="click" placement="bottom" class="p-0">
                      <span>{{ item.construction_name }}</span>
                      <base-button
                        outline
                        slot="reference"
                        size="sm"
                        type="secundary"
                        class="p-0 m-0 shadow-none custom-popover-button"
                      >
                        {{ item.customer_name }}
                      </base-button>
                    </el-popover>
                  </td>
                  <td class="text-left">{{ item.seller_name }}</td>
                  <td class="text-left">
                    <el-popover trigger="click" placement="bottom" class="p-0">
                      <span>
                        {{
                          `${item.construction_address}, ${item.construction_number}, ${item.construction_district} 
                            ${item.construction_city}/${item.construction_state}-${item.construction_country}`
                        }}
                      </span>
                      <base-button
                        outline
                        slot="reference"
                        size="sm"
                        type="secundary"
                        class="p-0 m-0 shadow-none custom-popover-button"
                      >
                        {{ item.construction_district }} / {{ item.construction_city }}
                      </base-button>
                    </el-popover>
                  </td>
                  <td class="text-center">{{ item.concreting_date | parseDate("H:mm") }}</td>
                  <td class="text-center text-lowercase">
                    {{ item.expected_volume }} m³ /
                    <span
                      class="text-center custom-popover-button text-success"
                    >
                      {{ `${item.volume_performed} m³`}}
                    </span>
                  </td>
                  <td class="text-left">
                    <base-button
                      @click.prevent="changeScheduleServiceType(item)"
                      outline
                      slot="reference"
                      size="sm"
                      type="secondary"
                      class="p-0 m-0 shadow-none pr-1" 
                    >
                      <img :src="`/img/icons/concrete-pump${!item.service_type ? '-blue' : ''}.png`" style="width: 23px;">
                    </base-button>
                      <base-button
                      @click.prevent="handleModalAddService(item.uuid)"
                      outline
                      slot="reference"
                      size="sm"
                      type="secundary"
                      class="p-0 m-0 shadow-none"
                    >
                      <img src="/img/icons/todo-list.png" style="width: 23px;">
                    </base-button>
                    <el-popover trigger="click" placement="bottom" class="p-0">
                      <div class="border-left border-3 border-primary p-2 mt-1">
                        <h5 class="h4 mb-0">{{ item.formulation_title }}</h5>
                        <div class="mt-2 mb-3">
                          <h5 class="small">{{ item.formulation_body }}</h5>
                        </div>
                        <div class="mt-2 mb-3" v-if="item.formulation_footer">
                          <h5 class="small">{{ item.formulation_footer }}</h5>
                        </div>
                      </div>
                      <div class="border-left border-3 border-info p-1 mt-2">
                        <h5 class="m-0 pl-1">{{ item.concreted_piece }}</h5>
                      </div>
                      <base-button outline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none">
                        <img src="/img/icons/test-tube.png" width="23px" height="23px">
                      </base-button>
                    </el-popover>
                    <el-popover trigger="hover" placement="right" class="p-0 pr-0">
                        {{ item.company_plant_name }}
                      <base-button 
                        outline slot="reference" size="sm" type="secundary"
                        class="p-0 m-0 shadow-none text-indigo"
                      >
                        <img src="/img/icons/chemical-plant-black.png" width="23px" height="23px" class="mx-1" />
                      </base-button>
                    </el-popover>
                  </td>
                </tr>
              </tbody>
            </table>
            <PuzlEmptyData v-show="$_schedules && !$_schedules.length && !loadingSkeleton" />
          </div>
        </div>
      </div>
    </section>
    <LoadingPagination :show="loading && !loadingSkeleton" />
    <ModalEditService @updatedService="init({})" ref="modalEditService" />
    <ModalServiceCycle @updatedCycle="init({})" ref="modalServiceCycle" />
    <ModalScheduleAddService @addedService="init({})" ref="modalScheduleAddService" />
  </div>
</template>

<script>
import PuzlSelect from "@/components/PuzlSelect";
import MultiFilter from "@/components/Utils/MultiFilterV2";
import InputDatePicker from "@/components/InputDatePicker";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import Pagination from "@/components/Utils/Pagination";
import { mapGetters } from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import SkeletonPuzlFullWidth from "@/components/SkeletonPuzlFullWidth";
import ModalEditService from "../../Schedule/Service/_Edit.vue";
import ModalServiceCycle from "../../Schedule/Service/_ModalServiceCycle";
import ModalScheduleAddService from "../../Schedule/Shared/_ModalScheduleListService";
import { formatErrorValidation } from "@/plugins";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import SkeletonPuzl from "@/components/SkeletonPuzl";

export default {
  name: "List",
  mixins: [cursorPaginate],
  components: {
    PuzlSelect,
    MultiFilter,
    PuzlEmptyData,
    Pagination,
    SkeletonPuzlGrid,
    SkeletonPuzlFullWidth,
    ModalEditService,
    InputDatePicker,
    ModalServiceCycle,
    ModalScheduleAddService,
    LoadingPagination,
    SkeletonPuzl,
  },
  computed: {
    ...mapGetters({
      $_general_cards: "dailyResume/getGeneralCards",
      $_services: "dailyResume/getServices",
      $_schedules: "dailyResume/getSchedules",
      $_sellers: "user/sellers",
    }),
  },
  data() {
    return {
      multi_filter: {
        null: {
          name: "Todos",
          type: "primary",
        },
        1: {
          name: "Concluído",
          type: "primary",
        },
        2: {
          name: "Em andamento",
          type: "default",
        },
      },
      filter: {
        seller_uuids: [],
        date: new Date(),
      },
      loadingCards: true,
      viewType: 'services',
      loadingTable: false,
      initTabLoad: true,
    };
  },
  methods: {
    /**
     * @param {object} filter
     */
    handleFetch(filter) {
      this.init(filter, true);
    },
    /**
     * @param {object|null} filter,
     * @param {bool|null} initTabLoad
     */
    init(filter = null, initTabLoad = null) {
      if (filter) {
        this.filter = { ...this.filter, ...filter };
      }
      // loading apenas ao iniciar a tela ou trocar de aba.
      if (initTabLoad) {
        this.initTabLoad = false;
        this.loadingTable = true;
      }
      if (this.viewType === 'services') {
        this.getServices(filter);
      } else {
        this.getSchedules(filter);
      }
    },

    /**
     * @param {object} filter,
     */
    getGeneralCards(filter) {
      this.$store.dispatch("dailyResume/getGeneralCards", { filter: this.filter }).then(() => (this.loadingCards = false));
    },

    handleFilterDate(filter) {
      this.filter.date = filter;
      this.init({});
      this.getGeneralCards(filter);
    },

    /**
     * Busca serviços
     *
     * @returns void
     */
    getServices(filter = null) {
      this.startCursor(filter);
      this.$store.dispatch("dailyResume/fetchServicesItemsPaginate", { 
        filter: this.filter,
        next_page: this.paginate.nextUrl
      }).then((response) => {
        this.resolveCursor(response);
        this.loadingTable = false;
      }).catch((error) => {
        this.resolveCursor();
      });
    },

     /**
     * Busca programações
     *
     * @returns void
     */
    getSchedules(filter = null) {
      this.startCursor(filter);
      this.$store.dispatch("dailyResume/fetchSchedulesItemsPaginate", { 
        filter: this.filter,
        next_page: this.paginate.nextUrl
      }).then((response) => {
        this.resolveCursor(response);
        this.loadingTable = false;
      }).catch((error) => {
        this.resolveCursor();
      });
    },

    /**
     * Retorna relatório de o.s de serviço
     *
     * @param {object} service
     *
     * @returns void
     */
    async getScheduleServiceOrderReport(service) {
      let schedule_service_order_uuid = service.schedule_service_order_uuid;
      let first_time = false;
      // Se ainda não houver O.S. emitida, será criada.
      if (!schedule_service_order_uuid) {
        const loader = this.$loading.show();
        const service_order = {
          service_uuid: service.uuid,
          company_plant_uuid: service.company_plant_uuid,
          equipment_uuid: service.equipment_uuid,
          employers: service.employer_uuids,
          status: 0,
        };
        await this.$store
          .dispatch("scheduleService/addServiceOrder", service_order)
          .then((response) => {
            schedule_service_order_uuid = response.data.uuid;
            first_time = true;
          })
          .catch((error) => {
            if (error.status == 200) {
              this.$notify({
                type: "danger",
                message: error.data.message,
              });
            } else {
              let message = formatErrorValidation(error.response.data.errors);
              this.$notify({
                type: "danger",
                message: message,
              });
              this.handleEditService(service);
            }
          })
          .finally(() => {
            loader.hide();
          });
      }
      if (!schedule_service_order_uuid) return;
      const loader = this.$loading.show();
      this.$store
        .dispatch("scheduleService/generateReport", schedule_service_order_uuid)
        .then(async (response) => {
          const blob = new Blob([response], {
            type: "application/pdf",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "serviço.pdf");
          await link.click();
          this.$snotify.success("Download iniciado com sucesso!", {
            timeout: 1000,
            icon: false,
            position: "centerBottom",
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
          });
        })
        .finally(() => {
          if (first_time) {
            this.init({});
          }
          loader.hide();
        });
    },

    /**
     * Busca dados necessários para disparar edição de serviço
     *
     * @param {object} item
     *
     * @returns void
     */
    handleEditService(item) {
      this.$Progress.start();
      this.$store.dispatch("schedule/show", item.schedule_uuid).then(async (response) => {
        // Mescla dados complementares
        let service = await response.data.services.find((service) => service.uuid === item.uuid);
        let employer_uuids = null;
        switch (true) {
          case !item.employer_uuids:
            employer_uuids = [];
            break;
          case item.employer_uuids.includes(","):
            employer_uuids = item.employer_uuids.split(",").map((item) => (item = { uuid: item }));
            break;
          default:
            employer_uuids = [{ uuid: item.employer_uuids }];
            break;
        }
        service = {
          ...service,
          ...{
            schedule_uuid: item.schedule_uuid,
            service_name: item.service_name,
            service_description: item.service_description,
            employers: employer_uuids,
          },
        };
        this.$refs.modalEditService.handleCreate(service, response.data.company_plants);
        this.$Progress.finish();
      });
    },

    handleServiceCycle(service) {
      let loader = this.$loading.show();
      this.$store.dispatch("schedule/show", service.schedule_uuid).then((response) => {
        this.$refs.modalServiceCycle.handleCreate(response.data, service);
        loader.hide();
      });
    },

    /**
     * Formata dados necessários e realiza abertura de modal de adição de serviços
     *
     * @param {string} schedule_uuid
     *
     * @returns void
     */
    handleModalAddService(schedule_uuid) {
      let loader = this.$loading.show();
      this.$store.dispatch("schedule/show", schedule_uuid).then(async (response) => {
        const schedule = response.data;
        this.$refs.modalScheduleAddService.openModal(
          schedule.contract_proposal.uuid, 
          schedule_uuid, 
          schedule.company_plants, 
          {
            total_volume: schedule.total_volume,
            first_arrival_time: schedule.first_arrival_time,
            last_exit_time: schedule.last_exit_time
          }
        );
        loader.hide();
      });
    },

    /**
     * Altera tipo de serviço da programação
     *
     * @param {object} schedule 
     */
    changeScheduleServiceType(schedule) {
       this.$Swal.confirmAction(`Deseja realmente alterar para ${schedule.service_type ? 'Bomba externa' : 'Convencional'} ?`, ['Sim', 'Não'])
        .then((result) => {
          if (result.isConfirmed) {
            const loader = this.$loading.show();
            const service_type = schedule.service_type ? 0 : 1 ;
            this.$store.dispatch("schedule/updateServiceType", {service_type: service_type, uuid : schedule.uuid})
              .then((response) => {
                this.$notify({
                  type: response.error_type,
                  message: response.message
                });
                schedule.service_type = service_type;
              })
              .catch(error => {
                this.$notify({
                  type: "danger",
                  message: error.data.message
                });
              })
            .finally(() => loader.hide());
          }
        });
    },
  },
  mounted() {
    this.filter.date = new Date();
    this.getGeneralCards(this.filter);
    this.init({}, true);
    this.$store.dispatch("user/fetchSellers");
  },
};
</script>

<style scoped>
table tbody tr td,
table thead tr th {
  vertical-align: middle !important;
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.custom-popover-button {
  font-size: 11px !important;
  font-family: "Poppins" !important;
  font-weight: normal !important;
}
</style>
