<template>
  <div :class="defaultClass">
    <slot />
    <div class="card rounded pt-3" :class="theme ? 'bg-dark-chart' : 'bg-white'">
      <v-chart :theme="theme === true ? 'dark' : 'default'" class="chart" :option="option" />
    </div>
  </div>
</template>

<script>
import { use } from "echarts/core";
import { BarChart, LineChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import {
  TitleComponent,
  TooltipComponent,
  ToolboxComponent,
  LegendComponent,
  DataZoomComponent,
  MarkLineComponent, MarkPointComponent
} from "echarts/components";
import VChart from "vue-echarts";
import { GridComponent } from "echarts/components";

use([BarChart, MarkLineComponent, MarkPointComponent, TitleComponent, TooltipComponent, ToolboxComponent, LegendComponent, CanvasRenderer, LineChart,DataZoomComponent]);
use([GridComponent]);

export default {
  name: "BarLineChart",
  components: {
    VChart,
  },

  props: {
    theme: {
      type: Boolean,
      default: false,
      description: "Tema",
    },
    defaultClass: {
      type: String,
      default: 'container-fluid',
    },
    option: {
      type: Object,
      default: () => ({
        loading:true,
      }),
      description: "Dados gerais do chart",
    },
  },
};
</script>

<style scoped>
.bg-dark-chart {
  background-color: #100c2a;
}
</style>
