<template>
  <div class="mt-4">
    <base-header class="bg-gray-content">
      <Filters
        :range="range"
        @nextFile="nextFile()"
        @previousFile="previousFile()"
        @clearFilter="clearFilter()"
        @fetch="init">
        <template slot="data">
          <div class="row pr-3 mt-2 pl-3">
            <div class="col-12 text-left">
              <label class="form-control-label">
                Valor do Registro
              </label>
              <div class="row">
                <div class="col-6 text-left pr-1">
                  <base-input
                    v-mask="['#,##', '##,##', '###,##', '#,###.##', '##,###.##', '###,###.##']"
                    inputmode="numeric"
                    @change="setRange()"
                    v-model="filter.range_value.min"
                    input-group-classes="input-group-sm"
                    placeholder="de"
                    size="sm"
                  >
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0">R$</small>
                    </template>
                  </base-input>
                </div>
                <div class="col-6 pl-1">
                  <base-input
                    v-mask="['#,##', '##,##', '###,##', '#,###.##', '##,###.##', '###,###.##']"
                    inputmode="numeric"
                    @change="setRange()"
                    v-model="filter.range_value.max"
                    input-group-classes="input-group-sm"
                    placeholder="até"
                    size="sm"
                  >
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0">R$</small>
                    </template>
                  </base-input>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template slot="status">
          <div class="col-12 mt-1">
            <base-button-hoverable
              size="sm"
              :active="filter.reconciled === 1"
              @click="handleFilterStatus(card_status_enum.reconciled)"
              type="primary"
              icon="link"
              platform="ios"
            >
              Conciliado
            </base-button-hoverable>
          </div>
          <div class="col-12 mt-1">
            <base-button-hoverable
              size="sm"
              :active="filter.no_registry === 1"
              @click="handleFilterStatus(card_status_enum.no_registry)"
              type="dark"
              icon="link"
              platform="ios"
            >
              Pendente
            </base-button-hoverable>
          </div>
        </template>
        <template slot="bookmarks">
          <div class="col-12">
            <base-button-hoverable
              size="sm"
              :active="filter.status === filter_status_enum.type_credit"
              @click="handleFilterBookmark(filter_status_enum.type_credit)"
              type="success"
              icon="circle-left"
              platform="ios"
            >
              Recebimentos
            </base-button-hoverable>
          </div>
          <div class="col-12 mt-1">
            <base-button-hoverable
              size="sm"
              :active="filter.status === filter_status_enum.type_debit"
              @click="handleFilterBookmark(filter_status_enum.type_debit)"
              type="danger"
              icon="circle-right"
              platform="ios"
            >
              Pagamentos
            </base-button-hoverable>
          </div>
        </template>
        <template slot="order-by">
          <el-popover trigger="click" placement="bottom" class="p-0 float-right pr-0 ml-3">
            <h6><a @click.prevent="handleFilterOrderBy(order_by_enum.id_asc)" href="#" class="font-weight-400"
                   style="font-size: 12px">
              <i :class="order_by_enum_current === order_by_enum.id_asc && 'text-primary'"
                 class="fa-solid fa-angles-down" style="font-size: 12px;margin-right: 5px"></i>
              Do mais novo para o mais antigo</a>
            </h6>
            <hr class="mb-1 mt-n2">
            <h6><a @click.prevent="handleFilterOrderBy(order_by_enum.id_desc)" href="#" class="font-weight-400"
                   style="font-size: 12px">
              <i :class="order_by_enum_current === order_by_enum.id_desc && 'text-primary'"
                 class="fa-solid fa-angles-up" style="font-size: 12px;margin-right: 5px"></i>
              Do mais antigo para o mais novo</a>
            </h6>
            <hr class="mb-1 mt-n2">
            <h6><a @click.prevent="handleFilterOrderBy(order_by_enum.value_desc)" href="#" class="font-weight-400"
                   style="font-size: 12px">
              <i :class="order_by_enum_current === order_by_enum.value_desc && 'text-primary'"
                 class="fa-solid fa-angles-down" style="font-size: 12px;margin-right: 5px"></i>
              Do maior valor para o menor valor</a>
            </h6>
            <hr class="mb-1 mt-n2">
            <h6><a @click.prevent="handleFilterOrderBy(order_by_enum.value_asc)" href="#" class="font-weight-400"
                   style="font-size: 12px">
              <i :class="order_by_enum_current === order_by_enum.value_asc && 'text-primary'"
                 class="fa-solid fa-angles-up" style="font-size: 12px;margin-right: 5px"></i>
              Do menor valor para o maior valor</a>
            </h6>
            <a href="#" slot="reference">
              <h5 slot="reference" class="pt-1 font-weight-normal new-default-gray-font">ORDENAR <i class="fa-solid fa-chevron-down"></i></h5>
            </a>
          </el-popover>
        </template>
      </Filters>

    </base-header>
    <div class="container-fluid ">
      <!-- Skeleton de Carregamento -->
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <PuzlEmptyData v-if="!$_bank_reconciliation_transaction.length && !loadingSkeleton"></PuzlEmptyData>
      <!-- Lista de Cards -->
      <div class="row card-wrapper" v-if="$_bank_reconciliation_transaction.length && !loadingSkeleton">
        <div class="col-lg-4" v-for="(item, index) in $_bank_reconciliation_transaction" :key="index">
          <div class="card main-card mb-3">
            <div class="p-3">
              <div class="row mb-3" v-if="!cardStatusByItem(item).show_value_associated">
                <div class="col-12">
                  <div :class="cardStatusByItem(item).color">
                    <img :src="cardStatusByItem(item).title_image">
                    <h4>
                      {{ cardStatusByItem(item).description }}
                    </h4>
                  </div>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-auto pr-0">
                  <img src="/img/icons/merchant-account.png" width="24">
                </div>
                <div class="col-auto pl-3">
                  <h3 class="new-default-black-font m-0">
                     {{ item.date_at | parseDate('DD/MM/YYYY') }}
                  </h3>
                  <h4 class="new-default-black-font m-0 font-weight-normal">
                    {{ item.description }}
                  </h4>
                </div>
                <div class="col text-right">
                  <h4 class="m-0" :class="item.value >= 0 ? 'text-success' : 'text-danger'">
                    {{ item.value | currency }}
                  </h4>
                </div>
              </div>
              <div v-if="cardStatusByItem(item).show_value_associated" class="row align-items-center mt-3">
                <div class="col-auto pr-0">
                  <img src="/img/icons/finance.png" width="24">
                </div>
                <div class="col-auto pl-3">
                  <h3 class="new-default-black-font m-0">
                     {{ item.document }}
                  </h3>
                  <h4 v-if="item.entity_id" class="new-default-black-font m-0 font-weight-normal">
                    {{ item.entity_name }}
                  </h4>
                  <h4 v-else class="new-default-black-font m-0 font-weight-normal">
                    {{ item.title ? item.title : 'DEPOSITO NÃO IDENTIFICADO' }}
                  </h4>
                </div>
                <div class="col text-right">
                  <h4 v-if="item.value > 0" class="m-0" :class="item.value === item.value_associated ? 'text-success' : 'text-danger'">
                    {{ item.value_associated | currency }}
                  </h4>
                  <h4 v-else class="m-0" :class="item.value === item.value_associated ? 'text-danger' : 'text-warning'">
                    {{ item.value_associated | currency }}
                  </h4>
                </div>
              </div>
              <div v-if="item.automatic_closing_set && item.automatic_closing_note" class="row align-items-center mt-3">
                <div class="col-12">
                  <small class="font-weight-600">{{ item.automatic_closing_note }}</small>
                </div>
              </div>
              <div v-if="item.is_conciliating" class="row align-items-center mt-3">
                <div class="col-12">
                  <small class="font-weight-600">Em processo de conciliação</small>
                </div>
              </div>
              <div v-if="item.pending_auto_association" class="row align-items-center mt-2">
                <div class="col-12">
                  <small class="font-weight-600">Em processo de associação automática</small>
                </div>
              </div>
              <div v-if="item.bank_reconciliation_transaction_associations.length"
                class="row align-items-center mt-3 pointer"
                @click.prevent="handleCreateModalAssociations(item)">
                <div class="col-auto pr-0">
                  <img src="/img/brand/logo.png" width="24">
                </div>
                <div class="col-auto pl-3">
                  <h3 class="new-default-black-font m-0">
                     {{
                        item.bank_reconciliation_transaction_associations[0].entity_name
                        ? item.bank_reconciliation_transaction_associations[0].entity_name
                        : 'Sem interveniente'
                      }}
                  </h3>
                  <h4 class="new-default-black-font m-0 font-weight-normal">
                    {{ item.bank_reconciliation_transaction_associations[0].entity_document }}
                  </h4>
                </div>
                <div class="col text-right">
                  <h4 class="m-0"
                    :class="item.bank_reconciliation_transaction_associations[0].value  >= 0 ? 'text-success' : 'text-danger'">
                    {{ item.bank_reconciliation_transaction_associations[0].value | currency }}
                  </h4>
                </div>
              </div>
              <div class="row mt-2">


                <!-- Exibição dos botões disponiveis de acordo com o card -->
                <div v-if="!item.is_conciliating && !item.pending_auto_association" class="col-12 mt-3 px-1">
                  <div class="container">
                    <div class="row px-1">
                      <div class="col px-2" v-for="(current_button, index) in cardStatusByItem(item).available_buttons" :key="index">
                        <!-- mx-2 -->
                        <div class="new-cardwithbox-shadow pointer"
                          style="display: inherit;height: 30px;"
                          @click="buttons[current_button].on_click(item)">
                          <div class="new-default-black-font" style="display: inline-flex; align-items: center;">
                            <img :src="buttons[current_button].icon" class="mr-2" width="16">
                            <small style="font-size: 12px;">{{ buttons[current_button].label }}</small>
                          </div>
                          <!-- <base-button-hoverable
                            @click="buttons[current_button].on_click(item)"
                            :type="buttons[current_button].type"
                            :icon="buttons[current_button].icon"
                            :active="(current_button == button_enum.agroup) && group_selecteds.includes(item.id)"
                            platform="ios"
                          >
                            {{ buttons[current_button].label }}
                          </base-button-hoverable> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="started" class="scrolling-box bg-white" style="margin-top: -2%">
      <base-button
        @click.prevent="setGroupTransactions()"
        :disabled="group_selecteds.length === 1"
        class="entry-launch-custom-float">
        <span class="btn-label"><i class="fa-regular fa-object-ungroup"></i></span>
        <span style="font-size: 0.75rem">AGRUPAR</span>
        <span v-if="group_selecteds.length > 0"
              class="badge badge-light text-dark numeric" style="font-size: 0.75rem">
          {{ group_selecteds.length }}/{{ $_bank_reconciliation_transaction.length }}
        </span>
      </base-button>
      <base-button
        @click.prevent="clearSelecteds()"
        class="clear">
        <span class="btn-label"><i class="fa-solid fa-xmark"></i></span>
        <span style="font-size: 0.75rem">CANCELAR</span>
      </base-button>
    </div>
    <modal-associate @conciliate="handleAutoAssociate" @fetch="loadTransactions(filter)" ref="ModalAssociate"/>
    <modal-identify @fetch="loadTransactions(filter)" ref="ModalIdentify"/>
    <modal-associated @fetch="loadTransactions(filter)" ref="ModalAssociated"/>
    <modal-automatic-closing-note ref="modalAutomaticClosingNote"/>
    <modal-agroup ref="modalGroup"/>
    <ModalReconciliate ref="modalReconciliate" @init="init"/>
    <ModalAssociations ref="modalAssociations" @init="init"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import Filters from "./Filters/_Filters";
import InputDatePicker from '@/components/InputDatePicker';
import ModalAssociate from "./_ModalAssociate";
import ModalAssociated from "./_ModalAssociated";
import ModalReconciliate from './ModalReconciliation/_ModalReconciliate';
import ModalAssociations from './ModalAssociations/_ModalAssociations';
import moment from 'moment';
import {moneyToFloat} from "@/helpers";
import ModalAgroup from "./_ModalAgroup";
import ModalIdentify from './_ModalIdentify.vue';
import ModalAutomaticClosingNote from './_ModalAutomaticClosingNote.vue';
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import {debounce} from "@/plugins";

const card_status_enum = Object.freeze({
  no_registry: 0,
  identified: 1,
  associate: 2,
  automatic_closing: 3,
  reconciled: 4,
  reconciled_financial: 5,
});
const button_enum = Object.freeze({
  identify: 0,
  agroup: 1,
  close_and_reconcile: 2,
  bills: 3,
  reverse: 4,
  cancel: 5,
  reconcile: 6,
  reconcile_financial: 7,
});
const button_label = Object.freeze({
  identify: 'Identificar',
  agroup: 'Agrupar',
  close_and_reconcile: 'Baixar e Conciliar',
  bills: 'Contas',
  reverse: 'Estornar',
  cancel: 'Cancelar',
  reconcile: 'Conciliar',
  reconcile_financial: 'Conciliar',
});
const filter_status_enum = Object.freeze({
  type_all: 0,
  type_credit: 1,
  type_debit: 2,
})
const order_by_enum = Object.freeze({
  id_asc: 0,
  id_desc: 1,
  value_desc: 2,
  value_asc: 3,
})

export default {
  name: "ListBankTransaction",
  props: {
    bankTransactions: [],
  },
  components: {
    ModalAgroup,
    ModalAssociate,
    ModalAssociated,
    PuzlEmptyData,
    SkeletonPuzlGrid,
    Filters,
    InputDatePicker,
    ModalIdentify,
    ModalAutomaticClosingNote,
    BaseButtonHoverable,
    ModalReconciliate,
    ModalAssociations,
  },
  data() {
    return {
      loadingTitleSkeleton: false,
      loadingSkeleton: false,
      filter: null,
      filter_status_enum: filter_status_enum,
      currentBankReconciliationId: null,
      default_filter: {
        no_registry: null,
        automatic_closing: null,
        associate: null,
        reconciled: null,
        identified: null,
        status: filter_status_enum.type_all,
        range: null,
        range_value: {
          min: null,
          max: null
        },
        global: [],
        order_by: {
          field: null,
          type: null,
        }
      },
      order_by_enum: order_by_enum,
      order_by_enum_current: null,
      group_selecteds: [],
      started: false,
      range: {},
      button_enum: button_enum,
      buttons: [
        {
          id: button_enum.identify,
          label: button_label.identify,
          on_click: this.handleShowModalIdentify,
          type: 'success',
          icon: '/img/icons/icons8/ios/identify_success.png',
        },
        {
          id: button_enum.agroup,
          label: button_label.agroup,
          on_click: this.pushGroup,
          type: 'danger',
          icon: '/img/icons/icons8/ios/network_danger.png',
        },
        {
          id: button_enum.close_and_reconcile,
          label: button_label.close_and_reconcile,
          on_click: this.handleShowModalAssociate,
          type: 'primary',
          icon: '/img/icons/icons8/ios/link_primary.png',
        },
        {
          id: button_enum.bills,
          label: button_label.bills,
          on_click: this.showAssociated,
          type: 'primary',
          icon: '/img/icons/icons8/ios/bursts_primary.png',
        },
        {
          id: button_enum.reverse,
          label: button_label.reverse,
          on_click: this.removeConciliation,
          type: 'danger',
          icon: '/img/icons/icons8/ios/remove_link_danger.png',
        },
        {
          id: button_enum.cancel,
          label: button_label.cancel,
          on_click: this.removeIdentification,
          type: 'danger',
          icon: '/img/icons/icons8/ios/no_hidden_fee_danger.png',
        },
        {
          id: button_enum.reconcile,
          label: button_label.reconcile,
          on_click: this.setConciliation,
          type: 'success',
          icon: '/img/icons/icons8/ios/link_success.png',
        },
        {
          id: button_enum.reconcile_financial,
          label: button_label.reconcile_financial,
          on_click: this.setConciliationTwo,
          type: 'success',
          icon: '/img/icons/icons8/ios/link_primary.png',
        },
      ],
      card_status_enum: card_status_enum,
      card_status: [
        {
          id: card_status_enum.no_registry,
          description: 'Pendente',
          color: 'new-minicard-gray',
          available_buttons: [button_enum.reconcile_financial, button_enum.agroup, button_enum.close_and_reconcile],
          show_value_associated: false,
          title_image: '/img/icons/icons8/ios/link_secondary.png',
        },
        {
          id: card_status_enum.identified,
          description: 'Identificado',
          color: 'new-minicard-green',
          available_buttons: [button_enum.bills, button_enum.cancel],
          show_value_associated: true,
          title_image: '/img/icons/icons8/ios/identify.png',
        },
        {
          id: card_status_enum.associate,
          description: 'Associado',
          color: 'new-minicard-yellow',
          available_buttons: [button_enum.bills, button_enum.cancel, button_enum.reconcile],
          show_value_associated: true,
          title_image: '/img/icons/icons8/ios/link.png',
        },
        {
          id: card_status_enum.automatic_closing,
          description: 'Baixar e conciliar',
          color: 'new-minicard-red',
          available_buttons: [],
          show_value_associated: false,
          title_image: '/img/icons/icons8/ios/page-down-button.png',
        },
        {
          id: card_status_enum.reconciled,
          description: 'Pendente',
          color: 'new-minicard-gray',
          available_buttons: [button_enum.reconcile_financial, button_enum.bills, button_enum.reverse],
          show_value_associated: true,
          title_image: '/img/icons/icons8/ios/link_secondary.png',
        },
        {
          id: card_status_enum.reconciled_financial,
          description: 'Conciliado',
          color: 'new-minicard-blue',
          available_buttons: [],
          show_value_associated: false,
          title_image: '/img/icons/icons8/ios/link_primary.png',
        },
      ],
    }
  },
  beforeMount() {
    this.currentBankReconciliationId = this.$route.params.bank_reconciliation_id;
    this.getBankReconciliation();
    this.clearFilter();
  },
  computed: {
    ...mapGetters({
      $_bank_reconciliation: 'bankReconciliation/show',
      $_bank_reconciliation_transaction: 'bankReconciliationTransaction/fetch',
    }),
  },
  methods: {
    /**
     * Atualiza a visualização de data de {de} - {até} para que o usuário se localize
     *
     * @param {array} bankReconciliationTransactions - Array com os novos extratos à serem mostrados na tela
     */
    updateRangeViewer(bankReconciliationTransactions){
      if(bankReconciliationTransactions.length){
        this.range = {
          start: new Date(bankReconciliationTransactions[0].date_at),
          end: new Date(bankReconciliationTransactions[0].date_at),
        }
      }
    },
    /**
     * Busca o próximo extrato da mesma conta bancária
     */
    nextFile(){
      this.loadingSkeleton = true;
      this.$store
        .dispatch('bankReconciliationTransaction/nextOrPreviousReconciliation', {
          id: this.currentBankReconciliationId,
          next: 1,
        }).then((response) => {
          this.updateScreen(response.data);
        }).catch(error => {
          const errors = error && error.response && error.response.status === 422
            ? formatErrorValidation(error.response.data.data)
            : error.response.data.message;
          this.$notify({ type: "danger", message: errors });
        });
      this.loadingSkeleton = false;
    },
    /**
     * Busca o extrato anterior da mesma conta bancária
     */
    previousFile(){
      this.loadingSkeleton = true;
      this.$store
        .dispatch('bankReconciliationTransaction/nextOrPreviousReconciliation', {
          id: this.currentBankReconciliationId,
          next: 0,
        }).then((response) => {
          this.updateScreen(response.data);
        }).catch(error => {
          const errors = error && error.response && error.response.status === 422
            ? formatErrorValidation(error.response.data.data)
            : error.response.data.message;
          this.$notify({ type: "danger", message: errors });
        });
      this.loadingSkeleton = false;
    },
    /**
     * Atualiza a tela com transações do extrato anterior ou seguinte
     *
     * @param {array} newReconciliations - Array com extratos anteriores ou seguintes
     */
    updateScreen(newReconciliationId){
      this.currentBankReconciliationId = newReconciliationId;
      this.$router.push({
        name: 'cash_flow.bank_reconciliation.transaction.view',
        params: {
          bank_reconciliation_id: this.currentBankReconciliationId,
        },
      });
      this.getBankReconciliation();
      this.clearFilter();
    },
    handleCreateModalReconciliate(item){
      this.$refs.modalReconciliate.openModal(item);
    },
    handleCreateModalAssociations(item){
      this.$refs.modalAssociations.openModal(item);
    },
    handleFilterOrderBy(orderByEnum) {
      const allowedField = ['id', 'value'];
      const allowedType = ['asc', 'desc'];
      switch (orderByEnum) {
        case this.order_by_enum.id_asc:
          this.filter.order_by = {field: allowedField[0], type: allowedType[0]};
          break;
        case this.order_by_enum.id_desc:
          this.filter.order_by = {field: allowedField[0], type: allowedType[1]};
          break;
        case this.order_by_enum.value_asc:
          this.filter.order_by = {field: allowedField[1], type: allowedType[0]};
          break;
        case this.order_by_enum.value_desc:
          this.filter.order_by = {field: allowedField[1], type: allowedType[1]};
          break;
        default:
          break;
      }
      this.order_by_enum_current = orderByEnum;
      this.init();
    },
    handleFilterBookmark(filterStatusEnum) {
      if (filterStatusEnum === this.filter_status_enum.type_credit) {
        this.filter.status = (this.filter.status === this.filter_status_enum.type_credit)
          ? this.filter_status_enum.type_all
          : this.filter_status_enum.type_credit;
      } else {
        this.filter.status = (this.filter.status === this.filter_status_enum.type_debit)
          ? this.filter_status_enum.type_all
          : this.filter_status_enum.type_debit;
      }
      this.init();
    },
    /**
     * Alterar filtros card_status_enum
     *
     * @param {card_status_enum|integer} cardStatusEnum
     */
    handleFilterStatus(cardStatusEnum) {
      const active = 1;
      const inactive = null;
      const keyToChange = Object.keys(card_status_enum).find((key) => card_status_enum[key] === cardStatusEnum);
      const filterKeys = [
        'no_registry',
        'automatic_closing',
        'associate',
        'reconciled',
        'identified'
      ];
      filterKeys.forEach((filterKey) => {
        if (filterKey !== keyToChange)
          this.filter[filterKey] = inactive;
      });
      this.filter[keyToChange] = (this.filter[keyToChange] === inactive) ? active : inactive;
      this.init();
    },
    setRange: debounce(function () {
      this.init();
    }, 100),
    clearFilter() {
      this.filter = this.$helper.cloneObject(this.default_filter);
      this.filter.order_by = {field: 'id', value: 'asc'};
      this.order_by_enum_current = this.order_by_enum.id_asc;
      this.init();
    },
    init(multiFilterParams) {
      this.loadingSkeleton = true;
      this.filter = {...this.filter, ...multiFilterParams};
      this.$store
        .dispatch('bankReconciliationTransaction/showByReconciliation', {
          id: this.currentBankReconciliationId,
          filter: this.filter,
        })
        .then((response) => {
          this.updateRangeViewer(response.data);
          this.loadingSkeleton = false;
        });
    },
    setAutomaticClosing(item) {
      this.$refs.modalAutomaticClosingNote.openModal(item);
    },
    handleAutoAssociate({bank_transaction_id = null}) {
      let loader = this.$loading.show()
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch('bankReconciliation/postAutoAssociation', {
        bank_reconciliation_id: this.$_bank_reconciliation.id,
        bank_transaction_id: bank_transaction_id,
      }).then((response) => {
        this.$notify({type: response.error_type, message: response.message})
        loader.hide()
        if (bank_transaction_id) {
          let index = this.$_bank_reconciliation_transaction.findIndex((item) => item.id == bank_transaction_id)
          this.$_bank_reconciliation_transaction[index].is_conciliating = true
        } else {
          for (let i in this.$_bank_reconciliation_transaction) {
            this.$_bank_reconciliation_transaction[i].pending_auto_association = true
          }
        }
        // Atualizar registros após 2s
        setTimeout(() => this.init(), 2000);
      }).catch(error => {
        loader.hide()
      })
    },
    removeAutomaticClosing(item) {
      this.$swal.fire({
        title: 'Você tem certeza?',
        text: 'Deseja realmente remover marcação de baixa automática?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, continuar!',
        cancelButtonText: 'Não, cancelar',
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          const payload = {
            id: item.id,
            automatic_closing_set: false,
            automatic_closing_note: null,
          };
          let loader = this.$loading.show()
          this.$store.dispatch('bankReconciliationTransaction/putAutoClosing', payload).then((response) => {
            item.automatic_closing_set = 0;
            item.automatic_closing_note = null;
            this.$notify({type: response.error_type, message: response.message});
          }).catch(error => {
            this.$notify({type: "danger", message: error.data.message});
          }).finally(() => {
            loader.hide();
          })
        }
      });
    },
    cardStatusByItem(item) {
      if(item.bank_reconciliation_transaction_associations_count > 0){
        return this.card_status[card_status_enum.reconciled_financial];
      }

      if (!item.reconciled) {
        // Baixa Automática
        if (item.automatic_closing_set > 0)
          return this.card_status[card_status_enum.automatic_closing];

        // Sem Registro
        if ((item.has_association == 0) && (item.has_identification == 0))
          return this.card_status[card_status_enum.no_registry];

        // Identificado
        if (item.has_identification > 0)
          return this.card_status[card_status_enum.identified];

        // Associado
        if (item.has_association > 0)
          return this.card_status[card_status_enum.associate];
      }
      // Conciliado com conciliação financeira
      if (item.bank_reconciliation_transaction_associations_count > 0)
        return this.card_status[card_status_enum.reconciled_financial];

      // Conciliado!
      if (item.reconciled > 0)
        return this.card_status[card_status_enum.reconciled];
    },
    setGroupTransactions() {
      let items = this.$_bank_reconciliation_transaction.filter((item) => this.group_selecteds.includes(item.id))
      const params = {
        ids: this.group_selecteds,
        total_value: items.reduce(function (a, item) {
          return a + (item.value.includes(',') ? moneyToFloat(item.value) : parseFloat(item.value));
        }, 0).toFixed(2)
      }
      this.$refs.modalGroup.handleCreateModal(params)
    },
    clearSelecteds() {
      this.group_selecteds = []
      this.started = 0
    },
    pushGroup(item) {
      this.group_selecteds = this.$helper.Array.selecteds(this.group_selecteds, item.id);
      if (this.group_selecteds.length > 0) {
        this.started = 1
      } else {
        this.started = 0
      }
    },
    removeConciliation(item) {
      if (this.verifyStarted()) {
        return this.$notify({type: 'warning', message: 'Conclua o agrupamento ou cancele para continuar.'});
      }
      this.$swal.fire({
        title: 'Você tem certeza?',
        text: 'Deseja realmente realizar a desconciliação da transação?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, continuar!',
        cancelButtonText: 'Não, cancelar',
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          let params = {
            id: item.id,
            type: item.value > 0 ? 1 : 0,
            paid_at: item.date_at,
          }
          let loader = this.$loading.show()
          this.$store.dispatch('bankReconciliationTransaction/postRemoveConciliation',
            params).then((response) => {
            item.reconciled = 0
            item.has_association = 0
            this.$notify({type: response.error_type, message: response.message})
          }).catch(error => {
            this.$notify({
              type: "danger",
              message: error.data.message
            });
          }).finally(() => {
            loader.hide()
          })
        }
      })
    },
    removeIdentification(item) {
      if (this.verifyStarted()) {
        return this.$notify({type: 'warning', message: 'Conclua o agrupamento ou cancele para continuar.'});
      }
      this.$swal.fire({
        title: 'Você tem certeza?',
        text: 'Deseja realmente desvincular a identificação do registro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, continuar!',
        cancelButtonText: 'Não, cancelar',
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          let params = {
            id: item.id,
            type: item.value > 0 ? 1 : 0,
            paid_at: item.date_at,
          }
          let loader = this.$loading.show()
          this.$store.dispatch('bankReconciliationTransaction/postRemoveIdentification',
            params).then((response) => {
            item.reconciled = 0
            item.has_association = 0
            item.has_identification = 0
            this.$notify({type: response.error_type, message: response.message})
          }).finally(() => {
            loader.hide()
          })
        }
      })
    },
    setConciliation(item) {
      if (this.verifyStarted()) {
        return this.$notify({type: 'warning', message: 'Conclua o agrupamento ou cancele para continuar.'});
      }
      this.$swal.fire({
        title: 'Você tem certeza?',
        text: 'Confirme os dados antes de realizar a conciliação!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, continuar!',
        cancelButtonText: 'Não, cancelar',
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          let params = {
            id: item.id,
            type: item.value > 0 ? 1 : 0,
            paid_at: item.date_at,
          }
          let loader = this.$loading.show()
          this.$store.dispatch('bankReconciliationTransaction/postConciliation',
            params).then((response) => {
            item.reconciled = 1
            this.$notify({type: response.error_type, message: response.message})
            loader.hide()
          }).catch(error => {
            this.$notify({type: error.data.error_type, message: error.data.message})
            loader.hide()
          })
        }
      })
    },
    setConciliationTwo(item) {
      this.handleCreateModalReconciliate(item);
    },
    getBankReconciliation() {
      this.loadingTitleSkeleton = true
      this.$store
        .dispatch(
          "bankReconciliation/show",
          this.currentBankReconciliationId
        )
        .then(() => {
          this.loadingTitleSkeleton = false
        })
        .catch(() => {
          this.loadingTitleSkeleton = false
        })
    },
    loadTransactions(filter = {}) {
      this.filter = filter
      let loader = this.$loading.show()
      this.$store.dispatch('bankReconciliationTransaction/showByReconciliation',
        {
          id: this.currentBankReconciliationId,
          filter: this.filter
        }).then(() => {
        loader.hide()
      })
    },
    showAssociated(item) {
      if (this.verifyStarted()) {
        return this.$notify({type: 'warning', message: 'Conclua o agrupamento ou cancele para continuar.'});
      }
      let loader = this.$loading.show()
      const type = item.value > 0 ? 1 : 0
      this.$store.dispatch('bankReconciliationTransaction/show',
        {
          type: type,
          id: item.id
        }).then(() => {
        loader.hide()
        this.$refs.ModalAssociated.handleCreate(item)
      })
    },
    /**
     * verifica processos em andamento
     * @returns {ElNotificationComponent}
     */
    verifyStarted() {
      return this.started
    },
    handleShowModalIdentify(item) {
      if (this.verifyStarted()) {
        return this.$notify({type: 'warning', message: 'Conclua o agrupamento ou cancele para continuar.'});
      }
      const start_at = moment(item.date_at).subtract(2, "days").format('YYYY-MM-DD 00:00:00')
      const end_at = moment(item.date_at).add(2, "days").format('YYYY-MM-DD 00:00:00')
      this.$refs.ModalIdentify.handleCreate(item,
        {
          start: start_at,
          end: end_at,
        }
      )
    },
    handleShowModalAssociate(item) {
      if (this.verifyStarted()) {
        return this.$notify({type: 'warning', message: 'Conclua o agrupamento ou cancele para continuar.'});
      }
      const start_at = moment(item.date_at).subtract(2, "days").format('YYYY-MM-DD 00:00:00')
      const end_at = moment(item.date_at).add(2, "days").format('YYYY-MM-DD 00:00:00')
      this.$refs.ModalAssociate.handleCreate(item,
        {
          start: start_at,
          end: end_at,
        }
      )
    }
  },
}
</script>

<style scoped>
.dropdown-menu {
  padding: 0 0;
}

h1, h2, h3, h4, h5, p {
  font-family: "Fredoka", sans-serif;
}

.entry-launch-custom-float {
  position: fixed;
  bottom: 150px;
  right: 155px;
  background-color: #00ABE7 !important;
  text-align: center;
  -webkit-box-shadow: 2px 2px 3px #999;
  box-shadow: 2px 2px 3px #999;
}

.clear {
  position: fixed;
  bottom: 150px;
  right: 10px;
  background-color: #de2d2d !important;
  text-align: center;
  -webkit-box-shadow: 2px 2px 3px #999;
  box-shadow: 2px 2px 3px #999;
}

.horizontal-line {
  border-bottom: 1px solid #1a70b7;
}

.card {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.07) !important;
  border-radius: 8px !important;
  border: 1px solid #ECECEC;
}

.new-cardwithbox-shadow {
  text-align: center;
  box-shadow: 0 5px 10px 0 #f3f3f3;
  display: flex;
  border: 1px solid #E8E8E8;
  border-radius: 5px !important;
  align-items: center;
  padding: 4px 0px;
}

.new-minicard-green {
  background-color: #F2F7F3;
  border-radius: 15px;
  padding: 5px 12px;
  font-size: 12px;
}

.new-minicard-red {
  background-color: #FCF3F3;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-yellow {
  background-color: #FEF9F2;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-blue {
  background-color: #F2F4F9;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-gray {
  background-color: #646e7d2e;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-green h4 {
  color: #149e57;
}

.new-minicard-red h4 {
  color: #db4539;
}

.new-minicard-yellow h4 {
  color: #f2b532;
}

.new-minicard-blue h4 {
  color: #1b6eba;
}

.new-minicard-gray h4 {
  color: #66696c;
}

.new-minicard-red, .new-minicard-green, .new-minicard-yellow, .new-minicard-blue, .new-minicard-gray {
  align-items: center;
  display: inline-flex;
  text-align: center;
}

.new-minicard-red img:first-child, .new-minicard-green img:first-child, .new-minicard-yellow img:first-child, .new-minicard-blue img:first-child, .new-minicard-gray img:first-child {
  width: 16px;
}

.new-minicard-red h4, .new-minicard-green h4, .new-minicard-yellow h4, .new-minicard-blue h4, .new-minicard-gray h4 {
  font-size: 12px;
  margin: 0 6px 0 6px;
  font-weight: normal !important;
}

.custom-dropdown-toggle:after {
  position: absolute;
  content: "";
  top: 13px;
  right: -10px;
  border: solid #b5b5b5;
  border-width: 0 1px 1px 0;
  padding: 2px;
  transform: scaleY(1) rotate(45deg);
  -webkit-transform: scaleY(1) rotate(45deg);
}

.see-more-association {
  border-top-left-radius: 16px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom: 0px;
  border-right: 0px;
}
</style>
