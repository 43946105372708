import store from "../../../../shared/libraries/store";
import { SearchBarFilterType } from "../../../../components/AppGlobal";
import {
  AdjustmentTypeListFilterType,
  AdjustmentTypeListPagerType,
  initAdjustmentTypeListPagerType,
  AdjustmentTypeViewType
} from "../types";
import { adjustmentTypeStoreStateKeys } from "./adjustmentTypeStoreStateKeys";

/** @returns {AdjustmentTypeListPagerType} */
const getListed = () =>
  store.getState(adjustmentTypeStoreStateKeys.LISTED) ??
  initAdjustmentTypeListPagerType();

/** @returns {boolean} */
const getIsListing = () =>
  store.getState(adjustmentTypeStoreStateKeys.IS_LISTING) ?? false;

/** @returns {boolean} */
const getIsSaving = () =>
  store.getState(adjustmentTypeStoreStateKeys.IS_SAVING) ?? false;

/** @returns {?AdjustmentTypeViewType} */
const getItem = () => store.getState(adjustmentTypeStoreStateKeys.ITEM);

/** @returns {boolean} */
const getIsRemoving = () =>
  store.getState(adjustmentTypeStoreStateKeys.IS_REMOVING) ?? false;

/** @returns {boolean} */
const getIsFinding = () =>
  store.getState(adjustmentTypeStoreStateKeys.IS_FINDING) ?? false;

/**
 * @returns {{
 *   searchBarFilter: SearchBarFilterType,
 *   filter: AdjustmentTypeListFilterType
 * }}
 */
const getPageState = () =>
  store.getState(adjustmentTypeStoreStateKeys.PAGE_STATES);

export default {
  getListed,
  getIsListing,
  getIsSaving,
  getPageState,
  getItem,
  getIsRemoving,
  getIsFinding,
};
