<template>
  <div>
    <modal :show.sync="modal.create" size="sm">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation p-2 mt-2" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <!-- Replicar por -->
            <div class="form-group row m-0 p-0 mb-2">
              <label class="col-md-6 pl-2 pt-2 pb-0 col-form-label form-control-label"> Replicar por </label>
              <div class="col-md-6">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                    <puzl-select
                      @input="setDefaultDays"
                      v-model="payload.type"
                      :items="types"
                      :clearable="false"
                      customKey="name"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!-- Dias-->
            <div class="form-group row m-0 p-0 mb-4">
              <label class="col-md-6 pl-2 pt-2 pb-0 col-form-label form-control-label"> 
                {{ payload.type === 'Prazo' ? 'Dias' : 'Dia' }} 
              </label>
              <div class="col-md-6">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                    <input-limit
                      v-if="payload.type === 'Prazo'"
                      :start_value="payload.days"
                      :options="{
                        min: 0,
                        max: 365,
                      }"
                      max_length="3"
                      input_group_classes="input-group-sm pb-0"
                      v-model="payload.days"
                      @value="payload.days = $event"
                    >
                      <small class="input-group-sm p-0 m-0"> Dias </small>
                    </input-limit>
                    <input-date-time-picker 
                      v-else  
                      size="lg" 
                      v-model="payload.days" 
                      mode="date"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!-- Replicar-->
            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-10 pl-2 pt-0 pb-0 col-form-label form-control-label"> Replicar para vencimento original </label>
              <div class="col-md-2 d-flex items-center align-items-center">
                <base-input input-group-classes="input-group-sm">
                  <input v-model="payload.replicate_to_original_due_date" style="height: 20px; width: 20px" type="checkbox" />
                </base-input>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">Cancelar</base-button>
              <base-button type="success" native-type="submit" :disabled="invalid">Salvar</base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import moment from "moment";
import InputLimit from "@/components/Utils/InputLimit";
import PuzlSelect from "@/components/PuzlSelect";
import InputDateTimePicker from "@/components/InputDateTimePicker";

export default {
  name: "ModalSetDueDateInterval",
  components: { InputLimit, PuzlSelect, InputDateTimePicker },
  data() {
    return {
      modal: {
        title: "Prazo de Parcelas",
        create: false,
      },
      types: [
        {
          name : 'Prazo'
        },
        {
          name : 'Dia do mês'
        },
      ],
      payload: {
        type: null,
        days: null,
        replicate_to_original_due_date: false,
      },
      default_date : null,
    };
  },

  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal(default_date) {
      this.payload = {
        type : 'Prazo',
        days: null,
        replicate_to_original_due_date: false,
      };
      this.default_date = default_date;
      this.modal.create = true;
    },

    /**
     * Atribui quantidade de dias padrão
     *
     * @param {string} type 
     * @returns void
     */
    setDefaultDays(type) {
      if(type === 'Prazo') {
        this.payload.days = null;
      } else {
        this.payload.days = this.default_date;
      }
    },
    store() {
      this.$emit("store", this.payload);
      this.closeModal();
    },
  },
};
</script>
