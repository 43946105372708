<template>
  <div class="first-node-content-item">
    <div class="d-flex flex-row align-items-center" style="gap: 10px;">
      <span class="w-90">
        <div class="mx-0">
          <span class="d-flex" style="gap: 9px;">
            <img v-if="node.opened"
              src="/img/icons/icons8/ios/minus_key.png"
              alt="minus_key"
              width="24px"
              class="pointer"
              @click="node.opened = false;"
            >
            <img v-else
              src="/img/icons/icons8/ios/plus_key.png"
              alt="plus_key"
              width="24px"
              class="pointer"
              @click="loadSecondNode(node.concreting_date)"
            >
            {{ node.concreting_date | parseDate('DD MMM YYYY') }}
          </span>
        </div>
      </span>
      <span class="w-72 align-content-center">
        <div class="">
          <div class="d-flex justify-content-center">
            {{ node.construction_count }} {{ node.construction_count > 1 ? 'obras' : 'obra' }}
          </div>
        </div>
      </span>
      <span class="w-72 align-content-center">
        <div class="">
          <div class="d-flex justify-content-center">
            {{ Math.trunc(node.average_distance) }} km
          </div>
        </div>
      </span>
      <span class="w-72 align-content-center ml-2 mr-n2">
        <div class="">
          <div class="d-flex justify-content-center">
            <div>
              <span class="info-concrete-volume">
                {{ node.total_volume ? node.total_volume : 0 }} m<sup>3</sup> ({{ node.total_travels ? node.total_travels : 0 }})
              </span>
              /
              <span class="info-concrete-volume" style="color: #149E57;">
                {{ node.performed_volume ? node.performed_volume : 0 }} m<sup>3</sup> ({{
                  node.performed_travels ? node.performed_travels : 0
                }})
              </span>
            </div>
          </div>
        </div>
      </span>
      <span class="w-72 align-content-center">
        <div class="">
          <div class="d-flex justify-content-center">
            <div v-if="node.service_total_volume">
              <span>
                {{ node.service_total_volume ? node.service_total_volume : 0 }} m<sup>3</sup>
              </span>
              /
              <span style="color: #149E57;">
                {{ node.service_performed_volume ? node.service_performed_volume : 0 }} m<sup>3</sup>
              </span>
            </div>
            <div v-else>
              NENHUM
            </div>
          </div>
        </div>
      </span>
    </div>
    <hr v-if="node.opened">
    <div class="d-flex flex-column" v-if="node.opened">
      <SecondNode v-for="item in secondNodeItems" :key="item.id" :node="item" class="my-2" />
    </div>
  </div>
</template>

<script>
import SecondNode from './_SecondNode.vue';

export default {
  name: "FirstNode",
  components: { SecondNode },
  props: {
    node: {
      type: Object,
      required: true
    },
    company_plants_id: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      secondNodeItems: null,
      today: new Date().toISOString().split('T')[0]
    }
  },
  mounted() {
    this.checkHaveToday();
  },
  methods: {
    loadSecondNode(concreting_date) {
      var concreting_date = concreting_date.split(' ')[0];
      this.secondNodeItems = null;
      this.$Progress.start();

      this.$store.dispatch("newScheduleResume/getResumeByDate",
        { concreting_date: concreting_date,
          company_plants_id: this.company_plants_id
        })
      .then((response) => {
        this.secondNodeItems = response.data;
      }).finally(() => this.$Progress.finish());

      this.node.opened = true;
    },
    checkHaveToday() {
      if(this.node.concreting_date === this.today) {
        this.loadSecondNode(this.node.concreting_date);
      }
    }
  }
}
</script>

<style scoped>
.first-node-content-item {
  background-color: #fff;
  padding: 20px 15px;
  border-radius: 16px;
}
.first-node-content-item span {
  font-size: 16px;
  font-weight: 500;
  color: #2B2D32;
}
.first-node-content-item sup {
  align-content: center;
}

hr {
  border: 0;
  border-top: 0.5px solid #E8E8E8;
  margin: 13px 0;
}
.w-72 {
  width: 72%;
}
.w-90 {
  width: 100%;
}
</style>