var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dropdown"},[_c('button',{staticClass:"btn btn-primary btn-custom",staticStyle:{"border-radius":"1.7rem !important","width":"100%"},attrs:{"title":"Menu","type":"button"},on:{"click":function($event){return _vm.handleMenu()}}},[_vm._m(0)]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.menu),expression:"menu"}],staticClass:"dropdown-menu show custom-menu"},[_c('router-link',{attrs:{"to":{
      path: '/commercial/contract-proposal/payments',
      name: 'commercial.contract-proposal.payments.view',
      params: { contract_proposal_uuid: _vm.contract_proposal.uuid }
    }}},[(_vm.contract_proposal.entity)?_c('li',{staticClass:"dropdown-item"},[_c('a',{staticClass:"dropdown-item custom-title text-gray"},[_vm._v("Recebimentos")]),_c('img',{staticStyle:{"width":"30px"},attrs:{"src":"/img/icons/card-in-use.png"}})]):_vm._e()]),_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.$router.push(`/commercial/contract-proposal/invoices/${_vm.contract_proposal.uuid}`)}}},[(_vm.contract_proposal.entity)?_c('li',{staticClass:"dropdown-item"},[_c('a',{staticClass:"dropdown-item custom-title text-gray"},[_vm._v("Faturas")]),_c('img',{staticStyle:{"width":"30px"},attrs:{"src":"/img/icons/receipt-terminal.png"}})]):_vm._e()]),_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.$router.push(`/operational/contract-proposal/schedule/${_vm.contract_proposal.uuid}`)}}},[(_vm.contract_proposal.entity)?_c('li',{staticClass:"dropdown-item"},[_c('a',{staticClass:"dropdown-item custom-title text-gray"},[_vm._v("Programações")]),_c('img',{staticStyle:{"width":"30px"},attrs:{"src":"/img/icons/calendar.png"}})]):_vm._e()]),_c('router-link',{attrs:{"to":{
      path:
        '/commercial/contract-proposal/additional/:contract_proposal_uuid',
      name: 'commercial.contract-proposal.additional.view',
      params: { contract_proposal_uuid: _vm.contract_proposal.uuid }
    }}},[_c('li',{staticClass:"dropdown-item"},[_c('a',{staticClass:"dropdown-item custom-title text-gray"},[_vm._v("Adicionais")]),_c('img',{staticStyle:{"width":"30px"},attrs:{"src":"/img/icons/plus-math.png"}})])]),_c('router-link',{attrs:{"to":{
      path: '/commercial/contract-proposal/services',
      name: 'commercial.contract-proposal.services.view',
      params: { contract_proposal_uuid: _vm.contract_proposal.uuid }
    }}},[_c('li',{staticClass:"dropdown-item"},[_c('a',{staticClass:"dropdown-item custom-title text-gray"},[_vm._v("Serviços")]),_c('img',{staticStyle:{"width":"30px"},attrs:{"src":"/img/icons/todo-list.png"}})])]),_c('router-link',{attrs:{"to":{
      path: '/commercial/contract-proposal/formulation',
      name: 'commercial.contract-proposal.formulation',
      params: { contract_proposal_uuid: _vm.contract_proposal.uuid }
    }}},[_c('li',{staticClass:"dropdown-item"},[_c('a',{staticClass:"dropdown-item custom-title text-gray"},[_vm._v("Traços")]),_c('img',{staticStyle:{"width":"30px"},attrs:{"src":"/img/icons/test-tube.png"}})])]),_c('div',{staticClass:"dropdown-divider"}),_c('li',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.handleMenuEdit()}}},[_c('a',{staticClass:"dropdown-item custom-title dropleft text-gray"},[_vm._v("Editar")]),_c('img',{staticStyle:{"width":"30px"},attrs:{"src":"/img/icons/edit.png"}})]),_c('router-link',{attrs:{"to":{ path: `/commercial/contract-proposal/edit/${_vm.contract_proposal.uuid}` }}},[_c('li',{staticClass:"dropdown-item"},[_c('a',{staticClass:"dropdown-item custom-title text-gray"},[_vm._v("Principal")]),_c('img',{staticStyle:{"width":"30px"},attrs:{"src":"/img/icons/home.png"}})])])],1),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.menuEdit),expression:"menuEdit"}],staticClass:"dropdown-menu show custom-dropdown"},[_c('li',{staticClass:"dropdown-item",on:{"click":function($event){$event.preventDefault();return _vm.handleEditConstruction()}}},[_c('a',{staticClass:"dropdown-item text-gray"},[_vm._v("Obra")]),_vm._m(1)]),(_vm.contract_proposal.entity)?_c('li',{staticClass:"dropdown-item",on:{"click":function($event){$event.preventDefault();return _vm.handleEditEntity(_vm.contract_proposal.entity.uuid)}}},[_c('a',{staticClass:"dropdown-item text-gray"},[_vm._v("Tomador")]),_vm._m(2)]):_vm._e()]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.menu),expression:"menu"}],staticClass:"dropdown-menu show custom-list"},[_c('li',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 col-lg-3 custom-icon"},[_c('div',{staticClass:"custom-item"},[_c('button',{staticClass:"btn base-button btn-success btn-sm icon-credit display-4",attrs:{"title":"Crédito","type":"button","disabled":_vm.contract_proposal.status === 0
                                || _vm.contract_proposal.status === 4
                                || _vm.contract_proposal.status === 5},on:{"click":function($event){$event.preventDefault();return _vm.handleModalEntityCreditLimit()}}},[_c('i',{staticClass:"ni ni-fat-add icon-credit-plus"})]),_c('span',[_vm._v("Crédito")])])]),_c('div',{staticClass:"col-md-3 col-lg-3 custom-icon",attrs:{"title":"Resumo"},on:{"click":function($event){$event.preventDefault();return _vm.handleGeneralResume(_vm.contract_proposal.id)}}},[_vm._m(3)]),_c('div',{staticClass:"col-md-3 col-lg-3 custom-icon",attrs:{"title":"Imprimir"},on:{"click":function($event){$event.preventDefault();return _vm.openModalPrint(_vm.contract_proposal)}}},[_vm._m(4)]),_c('div',{staticClass:"col-md-3 col-lg-3 custom-icon",attrs:{"title":"Contatos"},on:{"click":function($event){$event.preventDefault();return _vm.handleListContacts.apply(null, arguments)}}},[_vm._m(5)])])])]),_c('ModalGeneralResume',{ref:"modalGeneralResume"}),_c('ModalPrintOut',{ref:"ModalPrint",on:{"open":_vm.openModalContractPrint}}),_c('ModalContractPrint',{ref:"modalContractPrint"}),_c('ModalListContacts',{ref:"listContacts",on:{"updateConstruction":_vm.updateConstruction}}),_c('ModalEditConstruction',{ref:"editConstruction",on:{"updatedConstruction":_vm.updateConstruction}}),_c('ModalEditEntity',{ref:"editEntity",on:{"updatedEntity":_vm.set_entity}}),_c('ModalEntityCreditLimit',{ref:"modalEntityCreditLimit"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"custom-img"},[_c('img',{attrs:{"src":"/img/brand/logo.png","width":"35px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"fa-solid fa-2x fa-helmet-safety text-orange"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"fa-solid fa-2x fa-hand-holding-dollar text-danger"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-item"},[_c('i',{staticClass:"fas fa-file text-info",attrs:{"data-v-55d45a30":""}}),_c('span',[_vm._v("Resumo")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-item"},[_c('i',{staticClass:"fa-solid fa-print text-default"}),_c('span',[_vm._v("Imprimir")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-item"},[_c('i',{staticClass:"fas fa-id-card-alt text-warning"}),_c('span',[_vm._v("Contatos")])])
}]

export { render, staticRenderFns }