<template>
  <div>
    <AppFixedPageTitle
      title="NF-E EMITIDAS"
      icon="/img/icons/ledger-white.png"
    />
  <AppPageHeader>
    <template slot="search-bar">
      <AppSearchBar
        :searchBarFilter.sync="searchBarFilter"
        :showCompanyPlants="true"
        :isLoading="loading"
        @onSearchClick="listItems"
        @onClearClick="clearFilter"
      >
        <AppSearchBarFilterSection
            name="Dados"
            icon="/img/icons/icons8/ios/info-squared_gray.png"
          >
          <div class="col-md-12 mt-1 mb-2 px-0 text-left">
            <label
              class="form-control-label fs-12 new-default-black-font font-weight-400"
            >
              CNPJ EMISSOR
            </label>
            <PuzlSelect
              style="width: 100%;"
              :disableBoxShadow="true"
              v-model.lazy="filter.company_plant_issuer_id"
              :items="$_company_plant_issuers"
              :multiple="false"
              class="select-xl"
              label="ein"
              :loading="loadingCompanyPlantIssuers"
              :disabled="loadingCompanyPlantIssuers"
            />
          </div>
          <div class="col-md-12 mt-1 mb-2 px-0 text-left">
            <label
              class="form-control-label fs-12 new-default-black-font font-weight-400"
            >
              NF-E
            </label>
            <div class="row">
              <div class="col-6 text-left pr-1">
                <div class="input-custom-group">
                  <div>DE</div>
                  <input inputmode="numeric"
                    v-model="filter.number_nfe.min"
                  />
                </div>
              </div>
              <div class="col-6 pl-1">
                <div class="input-custom-group">
                  <div>ATÉ</div>
                  <input inputmode="numeric"
                    v-model="filter.number_nfe.max"
                  />
                </div>
              </div>
            </div>
          </div>
        </AppSearchBarFilterSection>
      </AppSearchBar>
      </template>
    <template slot="header-buttons">
      <AppPageHeaderActions>
        <AppPageHeaderActionsButton
          @click="handleShowModalExport"
          text="Exportar"
          icon="/img/icons/icons8/ios/exchange-dollar.png"
          type="dark"
        />
      </AppPageHeaderActions>
    </template>
    </AppPageHeader>
    <AppTabSelect
      :items="tabSelectItems"
      @onTabSelectItemClick="onTabSelectItemClick"
      :isShowViewTypes="false"
    >
      <AppSelect
        v-model="orderBy.selected"
        :items.sync="orderBy.items"
        @onSelectItemClick="listItems(false)"
        placeholder="ORDENAR"
        variant="text-only"
      />
    </AppTabSelect>
    <div class="container-fluid">
      <Widgets
        :widgets="$_widgets"
        @showBrokenSequence="showBrokenSequence"
      />
      <ListNfe
        :items="$_xml_issuedes"
        :loadingSkeleton="loadingSkeleton"
      />
    </div>
    <AppViewTrigger v-if="$_xml_issuedes.length" @onIntersected="listItems(true)"/>
    <ModalEditEntity ref="modalEditEntity" />
    <ModalBrokenSequence :show="showModalBrokenSequence" @close="showModalBrokenSequence = false" />
    <ModalExporter @openProcessList="showModalFiles()" ref="ModalExporter" />
    <ModalFiles ref="ModalFiles" />
  </div>
</template>

<script>
import {
  AppSearchBar,
  initSearchBarFilterType,
  AppSearchBarFilterSection,
  SearchBarFilterType,
  AppSelect,
  AppTabSelect,
  AppFixedPageTitle,
  AppPageHeader,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  AppViewTrigger
} from "../../../../../components/AppGlobal";
import {SkeletonPuzl} from "@/components";
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import {mapGetters} from "vuex";
import Pagination from "../../../../../components/Utils/Pagination";
import ModalReSend from "./Shared/_ModalReSend"
import ModalCancelNfe from "./Shared/_ModalCancelNfe"
import ModalBrokenSequence from "./Shared/_ModalBrokenSequence"
import cursorPaginate from "@/mixins/cursorPaginate";
import PuzlEmptyData from "../../../../../components/PuzlEmptyData";
import PuzlSelect from "@/components/PuzlSelect";
import {base_url_ms} from '@/plugins'
import ModalFiles from "./Shared/_ModalFiles";
import ModalExporter from "./Shared/_ModalExporter"
import InputDatePicker from "../../../../../components/InputDatePicker";
import ModalEditEntity from "@/views/Modules/Configuration/Entity/Shared/_Edit";
import { hasPermission } from "@/plugins/permissions";
import { NfeIssuedesListFilterType, initNfeIssuedesListFilterType } from "./types";
import { NfeIssuedesStatusEnum } from "./Shared/Enums/NfeIssuedesStatusEnum";
import { date } from "../../../../../helpers";
import ListNfe from './Shared/_Table';
import Widgets from './Shared/_Widgets';

export default {
  components: {
    AppSearchBar,
    AppTabSelect,
    AppFixedPageTitle,
    AppPageHeader,
    AppPageHeaderActions,
    AppPageHeaderActionsButton,
    AppSearchBarFilterSection,
    InputDatePicker,
    ModalFiles,
    PuzlEmptyData,
    Pagination,
    PuzlBreadcrumb,
    ModalExporter,
    SkeletonPuzl,
    ModalReSend,
    ModalBrokenSequence,
    ModalCancelNfe,
    PuzlSelect,
    ModalEditEntity,
    AppSelect,
    ListNfe,
    AppViewTrigger,
    Widgets
  },
  name: "Index",
  mixins: [cursorPaginate],
  data() {
    return {
      loadingPlant: false,
      loadingCompanyPlantIssuers: false,
      base_url_ms: base_url_ms(),
      showModalReSend: false,
      showModalCancelNfe: false,
      showModalBrokenSequence: false,
      selected: false,
      searchBarFilter: initSearchBarFilterType(),
      currentDate: date.make().format(date.FORMAT.ISO_8601),
      filter: initNfeIssuedesListFilterType(),
      NfeIssuedesStatusEnum: NfeIssuedesStatusEnum,
      loadingSkeleton: false,
      loadingSkeletonWidgets: false,
      has_permission: hasPermission,
      orderBy: {
        items: [
          {
            id: 0,
            name: "PADRÃO",
            selected_name: "ORDENAR",
            filter: [{ 
              column: "xml_travels.id", 
              is_desc: true,
            }],
          },
          {
            id: 1,
            name: "A-Z (CLIENTE)",
            selected_name: "A-Z (CLIENTE)",
            icon: "/img/icons/icons8/ios/double-down.png",
            filter: [{ 
              column: "customer_constructions.customer_name", 
              is_desc: false,
            }],
          },
          {
            id: 2,
            name: "Z-A (CLIENTE)",
            selected_name: "Z-A (CLIENTE)",
            icon: "/img/icons/icons8/ios/double-up.png",
            filter: [{ 
              column: "customer_constructions.customer_name", 
              is_desc: true,
            }],
          },
          {
            id: 3,
            name: "EMISSÃO MAIS NOVA PARA A MAIS VELHA",
            selected_name: "MAIS NOVA",
            icon: "/img/icons/icons8/ios/double-down.png",
            filter: [{ 
              column: "xml_travels.created_at", 
              is_desc: false,
            }],
          },
          {
            id: 4,
            name: "EMISSÃO MAIS VELHA PARA A MAIS NOVA",
            selected_name: "MAIS VELHA",
            icon: "/img/icons/icons8/ios/double-up.png",
            filter: [{ 
              column: "xml_travels.created_at", 
              is_desc: true,
            }],
          },
        ],
        selected: 0,
      },
    }
  },
  computed: {
    ...mapGetters({
      $_xml_issuedes: "xmlIssuedes/fetch",
      $_widgets: "xmlIssuedes/fetchWidgets",
      $_plants: 'plant/activeItems',
      $_company_plant_issuers: "companyPlantIssuer/fetch",
    }),
    tabSelectItems() {
      return [
        {
          id: null, 
          name: 'Todos', 
          selected: this.filter.status === null,
        },
        {
          id: NfeIssuedesStatusEnum.AUTHORIZED, 
          name: 'Autorizadas', 
          selected: this.filter.status === NfeIssuedesStatusEnum.AUTHORIZED,
        },
        {
          id: NfeIssuedesStatusEnum.REJECTED, 
          name: 'Rejeitadas', 
          selected: this.filter.status === NfeIssuedesStatusEnum.REJECTED,
        },
        {
          id: NfeIssuedesStatusEnum.CANCELLED, 
          name: 'Cancelada', 
          selected: this.filter.status === NfeIssuedesStatusEnum.CANCELLED,
        },
        {
          id: NfeIssuedesStatusEnum.DUPLICATED, 
          name: 'Duplicada', 
          selected: this.filter.status === NfeIssuedesStatusEnum.DUPLICATED,
        }
      ];
    },
  },
  methods: {
    /**
     * Padrão de filtro da barra de pesquisa
     * @returns {SearchBarFilterType}
     */
    defaultSearchBarFilter() {
      return {
        ...initSearchBarFilterType(),
        range: {
          items: [],
          selected: null,
          start: this.filter.range.start ?? this.currentDate,
          end: this.filter.range.end ?? this.currentDate,
        },
      };
    },
    /**
     * Padrão do filtro principal
     * @returns {NfeIssuedesListFilterType}
     */
    defaultFilter() {
      return {
        ...initNfeIssuedesListFilterType(),
      };
    },
    /**
     * Preparar filtro antes da listagem
     */
    prepareFilter() {
      this.filter.custom_search.values = this.searchBarFilter.custom_search_values;
      this.filter.company_plant_id = this.searchBarFilter.company_plant_selected;
      this.filter.range.start = this.searchBarFilter.range.start;
      this.filter.range.end = this.searchBarFilter.range.end;
      this.filter.order_by = this.orderBy.items[this.orderBy.selected].filter;
    },
    /**
     * Listar itens
     * @param {boolean} isAccumulateItems
     */
    listItems(isAccumulateItems = false) {
      if (!this.startCursor(this.filter, isAccumulateItems)) {
        return;
      }
      this.prepareFilter();
      this.loadingSkeleton = true;
      this.$store
        .dispatch("xmlIssuedes/fetchItems", this.filter)
        .then((res) => this.resolveCursor(res, this.filter))
        .finally(() => {
          this.$Progress.finish();
          this.loadingSkeleton = false;
          this.loading = false;
        });
      /**
       * @todo lógica de widgets mantida pois
       * será feita melhoria na api futuramente.
       */
      const params = {
        page: 1,
        per_page: 3,
        filter: {
          company_plant_id: this.filter.company_plant_id,
          company_plant_issuer_id: this.filter.company_plant_issuer_id,
          number_nfe: this.filter.number_nfe,
          custom_search: {
            ...this.filter.custom_search,
            values: this.searchBarFilter.custom_search_values,
          },
          status: this.filter.status,
          range: {
            start: this.filter.range.start,
            end: this.filter.range.end
          }
        }
      };
      this.loadingSkeletonWidgets = true
        this.$store.dispatch('xmlIssuedes/fetchWidgets', params)
          .then(() => {
            this.loadingSkeletonWidgets = false
          }).catch(() => {
            this.loadingSkeletonWidgets = false
          })
    },
    clearFilter(isRefreshList = true) {
      Object.assign(this.searchBarFilter, this.defaultSearchBarFilter());
      Object.assign(this.filter, this.defaultFilter());
      this.orderBy.selected = 0;
      if (isRefreshList) {
        this.listItems();
      }
    },
    /**
     * @param {TabSelectItemType} item
     */
    onTabSelectItemClick(item) {
      const isAlreadyFiltered = this.filter.status === item.id ? true : false;
      if (isAlreadyFiltered) {
        return;
      };
      this.filter.status = item.id;
      this.listItems();
    },
    showBrokenSequence() {
      let loader = this.$loading.show();
      const params = {
        company_plant_id: this.filter.company_plant_id,
        status: this.filter.status,
        range: {
          start: this.filter.range.start,
          end: this.filter.range.end
        }
      };
      this.$store.dispatch('xmlIssuedes/getBrokenSequence', {
        filter: params
      })
        .then(() => {
          this.showModalBrokenSequence = true
          loader.hide()
        }).catch(() => {
          loader.hide()
        })
    },
    handleShowModalExport() {
      this.$refs.ModalExporter.openModal()
    },
    showModalFiles() {
      this.$refs.ModalFiles.openModal()
    },
  },
  mounted() {
    this.loadingCompanyPlantIssuers = true
    this.$store
    .dispatch("companyPlantIssuer/fetchItems").then(() => {
      this.loadingCompanyPlantIssuers = false
    });
    this.clearFilter();
  }
}
</script>

<style scoped>
.input-custom-group {
	display: flex;
	align-items: center;
	border-radius: 8px;
  border: 1px solid #E8E8E8;
  overflow: hidden;
  height: 40px;
}

.input-custom-group input {
	box-shadow: none !important;
  border: none;
  padding-left: 5px;
  border-left: 1px solid #E8E8E8;
  border-left-color: #E8E8E8;
}

.input-custom-group div {
	padding: 0px 10px;
}

.input-custom-group input, .input-custom-group div {
	display: inline-flex;
	height: 41px;
	background: white;
	align-items: center;
	font-size: 12px;
}

.input-custom-group div {
	color: #606062;
}

.input-custom-group input {
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}

.input-custom-group input:focus {
	outline: none;
}
</style>
