const fuelControlMixin = {
  data() {
    return {
      loadingDriver: true,
      loadingEquipment: true,
      loadingFuelTank: true,
      hasDefaultFuelTankValue:false,
      loadingStore: false,
      loadingProductService:true,
      previousKmPanel:null,
      previousHourMeter:null,
    };
  },
  methods: {
    getStatusColor(status) {
      switch (parseInt(status)) {
        case 0:
          /* tipo interno e próprio */
          return "primary";
        case 1:
          /* tipo interno e terceiros */
          return "warning";
        default:
          /* tipo externo */
          return "dark";
      }
    },
    getStatusText(status) {
      switch (parseInt(status)) {
        case 0:
          /* tipo interno e próprio */
          return "INTERNO";
        case 1:
          /* tipo interno e terceiros */
          return "TERCEIROS";
        case 2:
          /* tipo externo */
          return "EXTERNO";
      }
    },
    changedCompanyPlant(default_fill = true) {
      if (this.payload.status !== 1) {
        /* Motorista */
        this.loadingDriver = true;
        let filter = {
          company_plant_uuid: this.$_company_plants.filter((item) => item.id === this.payload.company_plant_id)[0].uuid
        };
        this.$store.dispatch("user/fetchDrivers", { filter: filter }).then(() => {
          this.loadingDriver = false;
        });
        /* Equipamento */
        this.loadingEquipment = true;
        this.$store.dispatch("equipment/getByCompanyPlantAndService", { filter: filter }).then(() => {
          this.loadingEquipment = false;
          if (default_fill === true) {
            /* Caso seja externo e usuário for motorista trazer preenchido o motorista e buscar equipamento padrão */
            if (this.payload.status === 2 && this.$_user.is_driver === true) {
              this.payload.driver_id = this.$_user.id;
              let default_driver = this.$_drivers.filter((item) => item.id === this.payload.driver_id)[0] ?? null;
              if (default_driver && default_driver.user_operational && default_driver.user_operational.equipment_default) {
                let default_equipment =
                  this.$_equipments.filter((item) => item.uuid === default_driver.user_operational.equipment_default)[0] ?? null;
                this.payload.equipment_id = default_equipment ? default_equipment.id : null;
              }
            }
          } else {
            this.getFuelDataByDateAndEquipment()
          }
        });
      }

      if (this.payload.status !== 2) {
        /* Reservatórios */
        this.loadingFuelTank = true;
        this.$store.dispatch("fuelTank/getByCompanyPlant", this.payload.company_plant_id).then(() => {
          this.loadingFuelTank = false;
          if (default_fill === true && this.$_fuel_tanks.length === 1) {
            this.payload.fuel_tank_id = this.$_fuel_tanks[0].id;
            this.fillStartLiter();
          }

          // Verifica se tiver somente um reservatório, ele já virá preenchido, após selecionar a central.
          // Em seguida ele vai preencher o fornecedor automáticamente.
          this.selectedPlant();
          let fuel_tank = this.$_fuel_tanks.find((item) => item.id === this.payload.fuel_tank_id);
          this.entities = fuel_tank.entities;
          let default_entity = fuel_tank.entities.find((item) => item.pivot.default === 1);
          this.payload.entry_entity_id = default_entity ? default_entity.id : null;
        });
      }
    },
    fillStartLiter() {
      if (this.payload.fuel_tank_id) {
        /* Reservatório */
        let fuel_tank = this.$_fuel_tanks.find((item) => item.id === this.payload.fuel_tank_id);
        /* Litro inicial */
        this.payload.start_liter = fuel_tank ? fuel_tank.end_liter : 0;
        /* Produto */
        this.payload.product_name = fuel_tank ? fuel_tank.product_name : null;
        /* Fornecedor de entrada */
        this.entities = fuel_tank.entities;
        let default_entity = fuel_tank.entities.find((item) => item.pivot.default === 1);
        this.payload.entry_entity_id = default_entity ? default_entity.id : null;
        if (fuel_tank.end_liter) this.hasDefaultFuelTankValue = true;
      } else {
        this.payload.start_liter = null;
        this.payload.entry_entity_id = null;
        this.hasDefaultFuelTankValue = false;
      }
    },
    getTotal() {
      if (this.payload.status !== 2) {
        this.payload.total_liter = ((this.payload.end_liter ?? 0) - (this.payload.start_liter ?? 0)).toFixed(1);
        if (this.payload.total_liter > 999.9) {
          this.$notify({ type: "danger", message: "O campo total não pode ser superior a 999.9!" });
        }
      } else {
        this.payload.total_value = (
          parseFloat(this.payload.price_per_liter.replaceAll(".", "").replace(",", ".")) * this.payload.total_liter
        ).toFixed(2);
      }
    },
    getFinalValue() {
      let total_liter = parseFloat(this.payload.total_liter);
      if (total_liter) { 
        this.payload.end_liter = (total_liter + parseFloat(this.payload.start_liter)).toFixed(2);
      }
    },
    finalFormat(method = "post") {
      let payload = { ...this.payload };
      payload.price_per_liter = payload.price_per_liter ? this.$helper.moneyToFloat(payload.price_per_liter) : null;
      payload.total_value = payload.total_value ? this.$helper.moneyToFloat(payload.total_value) : null;
      if(this.previousHourMeter && payload.hour_meter && parseFloat(payload.hour_meter) < parseFloat(this.previousHourMeter) && method === "post") {
        this.loadingStore = false;
        return this.$snotify.error('O campo horímetro é menor que o registro anterior para o mesmo equipamento, operação não permitida.', {
          timeout: 2000,
          icon: false,
          position: "rightTop",
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true
        });
      }
      let formData = new FormData();
      for (let key of Object.keys(payload)) {
        formData.append(key, payload[key] !== null ? payload[key] : "");
      }
      /* Verifica se há anexo */
      if (
        this.payload.status === 2 &&
        this.$refs.fuelControlAttachment.fileRecords &&
        this.$refs.fuelControlAttachment.fileRecords.length
      ) {
        formData.append(`photo`, this.$refs.fuelControlAttachment.fileRecords[0].file);
      }
      if(method !== 'post') formData.append("_method", "PUT");
      return formData;
    },
    getFuelDataByDateAndEquipment() {
      if (this.payload.date && this.payload.equipment_id) {
        this.loadingStore = true;
        let payload = {
          equipment_id: this.payload.equipment_id,
          date: this.payload.date,
          id: this.payload.id ?? false,
        };
        this.$store.dispatch("fuelControl/getFuelDataByDateAndEquipment", payload).then((response) => {
          this.payload.volume = response.data.volume;
          this.previousKmPanel = response.data.km_panel;
          this.previousHourMeter = response.data.hour_meter;
          this.getTravelledDistance();
          this.loadingStore = false;
        });
      }
    },
    getTravelledDistance() {
      if(this.previousKmPanel && this.payload.km_panel) this.payload.travelled_distance = this.payload.km_panel - this.previousKmPanel;
    },
    getTravelledHour() {
      if(this.previousHourMeter && this.payload.hour_meter) {
        this.payload.travelled_hour = this.payload.hour_meter - this.previousHourMeter;
      }
    },
    getProductServiceHub() {
      this.loadingProductService = true;
      this.$store.dispatch("productService/getWithPriceByEntity", this.payload.entity_id).then(() => {
        this.loadingProductService = false;
      });
    }
  },
};
export default fuelControlMixin;
