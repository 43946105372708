<template>
  <div>
    <modal :show.sync="modal.create" size="lg">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation p-2" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div>
              <span @click.prevent="changedItem" class="btn btn-md btn-success p-1 mb-2 ml-1 float-right mr-0">
                <i class="fas fa-plus"></i> Novo
              </span>
              <table style="margin-bottom: 0px" class="table table-sm table-bordered">
                <thead>
                  <tr>
                    <th class="text-center text-white bg-default">Centro de custo</th>
                    <th class="text-center text-white bg-default">Equipamento</th>
                    <th class="text-center text-white bg-default">%</th>
                    <th class="text-center text-white bg-default"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in payload" :key="index">
                    <td class="text-center col-3">
                      <validation-provider :rules="!item.equipment_id ? 'required' : ''">
                        <PuzlSelect
                          @input="changedCostCenter($event, item)"
                          placeholder="Centro de custo"
                          v-model="item.cost_center_id"
                          :items="$_cost_centers"
                        />
                      </validation-provider>
                    </td>
                    <td class="text-center col-3">
                      <validation-provider :rules="!item.cost_center_id ? 'required' :''">
                        <base-input input-group-classes="input-group-sm">
                          <puzl-select
                            @input="changedEquipment($event, item)"
                            :clearable="false"
                            v-model="item.equipment_id"
                            :items="$_equipments"
                            label="$code$ - $plate$"
                            :labelMask="true"
                          />
                        </base-input>
                      </validation-provider>
                    </td>
                    <td class="text-center col-2 align-middle">
                      <validation-provider rules="required|min_value:0.01">
                        <base-input input-group-classes="input-group-sm">
                          <InputLimit
                            :start_value="item.percentage"
                            :options="{
                              min: 0.0,
                              max: 100.0,
                              decimals: 1,
                            }"
                            v-model="item.percentage"
                            @value="item.percentage = $event"
                            @change="getLastPercentage"
                          >
                           <small>%</small>
                          </InputLimit>
                        </base-input>
                      </validation-provider>
                    </td>
                    <td class="text-center col-2 align-middle">
                      <i
                        v-show="item.equipment_id || item.cost_center_id"
                        @click.prevent="handleDelete(index)"
                        role="button"
                        class="fas fa-trash text-danger pt-1"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">Cancelar</base-button>
              <base-button type="success" native-type="submit" :disabled="invalid">Salvar</base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
import { VMoney } from "v-money";
import InputLimit from "@/components/Utils/InputLimit.vue";

export default {
  name: "ModalSetEquipmentApportionment",
  components: { PuzlSelect, InputLimit },
  data() {
    return {
      modal: {
        title: "Rateio de Equipamentos",
        create: false,
      },
      payload: [],
      item: {},
      index: null,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
    };
  },
  directives: { money: VMoney },
  computed: {
    ...mapGetters({
      $_equipments: "equipment/fetch",
      $_company_plants: "plant/activeItems",
      $_cost_centers: "costCenter/fetch",
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    async openModal() {
      this.payload = [
        {
          percentage: "100.0",
          equipment_id: null,
        },
      ];
      this.modal.create = true;
    },
    store() {
      this.$emit("store", this.payload);
      this.closeModal();
    },
    async changedItem(must_add = true) {
      const percentage_by_item = 100 / (must_add ? this.payload.length + 1 : this.payload.length);

      if (must_add) {
        this.payload.push({
          percentage: Number(percentage_by_item).toFixed(1),
          equipment_id: null,
        });
      }
      this.payload = await this.normalizePercentage(percentage_by_item);
      this.getLastPercentage();
    },
    changedEquipment(value, current_item) {
      if (this.payload.filter((item) => item.equipment_id === value && item.cost_center_id === current_item.cost_center_id).length !== 1) {
        current_item.equipment_id = null;
        return this.$notify({ type: "danger", message: "Não são permitidos equipamentos duplicados com o mesmo centro de custo." });
      }
    },
    changedCostCenter(value, current_item) {
      if (this.payload.filter((item) => item.equipment_id === current_item.equipment_id && item.cost_center_id === value).length !== 1) {
        current_item.cost_center_id = null;
        current_item.equipment_id = null;
        return this.$notify({ type: "danger", message: "Não são permitidos equipamentos duplicados com o mesmo centro de custo." });
      }
    },
    normalizePercentage(percentage_by_item) {
      return this.payload.map((item) => {
        item.percentage = Number(percentage_by_item).toFixed(1);
        return item;
      });
    },
    getLastPercentage() {
      const sum_equipment_percentage = this.payload.reduce(function (new_value, obj, index) {
        new_value += parseFloat(obj.percentage);
        return new_value;
      }, 0);

      const difference = 100 - sum_equipment_percentage;

      if (difference > 100) {
        this.payload[0].percentage = Number(parseFloat(this.payload[0].percentage) - difference).toFixed(1);
      } else if (difference < 100) {
        this.payload[0].percentage = Number(parseFloat(this.payload[0].percentage) + difference).toFixed(1);
      }
    },
    handleDelete(index) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.payload.splice(index, 1);
          let last_index = this.payload.length - 1;
          this.payload.length && this.changedItem(false);
        }
      });
    },
  },
};
</script>

<style>
.modal {
  overflow-y: scroll !important;
}
</style>
