import { BaseException } from ".";

/**
 * Classe de exceção para login não autorizado.
 */
export default class LoginUnauthorizedException extends BaseException {
  /**
   * @param {string} message
   * @param {object} data
   */
  constructor(message = "Usuário não autorizado", data = {}) {
    super(message);
    this.data = data;
  }
}
