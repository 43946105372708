<template>
  <div>
    <div class="container-fluid">
      <multi-filter ref="multi-filter" @fetch="init" :filter="filter" /> 
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
      </div>

      <div
        class="row align-items-center"
        v-if="!commissions.length && !loadingSkeleton"
      >
        <div class="col-md-12 text-center">
          <div class="card p-4">
            Sem dados cadastrados!
          </div>
        </div>
      </div>

      <div
        class="row card-wrapper"
        v-if="commissions.length && !loadingSkeleton"
      >
        <div
          class="col-lg-4"
          v-for="(commission, index) in commissions"
          :key="index"
        >
          <!-- Basic with action button -->
          <card :class="commission.status ? 'card-border-top-success' : 'card-border-top-danger'">
            <!-- Card header -->
            <div class="row align-items-center mb-2">
              <div class="col-9 justify-content-center pr-0">
                <h5 class="h3 mb-0 text-capitalize h-100">{{ commission.name }}</h5>
                <h5 v-if="commission.description" class="h3 h-100 mb-0 small">
                  {{ commission.description }}
                </h5>
              </div>

              <div class="col-3 text-right pl-0">
                <base-dropdown menuOnRight>
                  <base-button
                    slot="title-container"
                    type="primary"
                    class="dropdown-toggle p-2 rounded m-0"
                  >
                    <i class="fas fa-cog"></i>
                  </base-button>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a
                    class="dropdown-item"
                    @click="handleEditCommission(commission.uuid)"
                  >
                    <i
                      class="fas fa-edit text-warning text-center main-icon-menu"
                    ></i>
                    Editar
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a
                    class="dropdown-item text-center"
                    @click="
                      handleCreateParameter(commission.uuid)
                    "
                  >
                    <i
                      class="fas fa-plus text-success text-center main-icon-menu"
                    ></i>
                    Incluir parâmetro
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a
                    class="dropdown-item"
                    @click="handleDeleteCommission(commission.uuid)"
                  >
                    <i
                      class="fas fa-times text-danger text-center main-icon-menu"
                    ></i>
                    Excluir
                  </a>
                </base-dropdown>
              </div>
            </div>
            <div class="col-12 justify-content-center pr-0 pl-0 mb-3 pb-1">
              <p class="pb-0 mb-0 pr-1 pt-1 text-success">
                <b v-if="commission.commissioning_type">{{ commission.commissioning_type.name }}</b>
              </p>
              <p class="small text-success pb-0 m-0">
                {{
                  commission.additional === 1
                    ? "(considera adicionais)"
                    : "(não considera adicionais)"
                }}
              </p>
            </div>
            <!-- Serviços-->
            <collapse
              v-if="commission.parameters && commission.parameters[0]"
              class="border rounded p-0 mt-3"
            >
              <collapse-item
                name="1"
                class="header-gray card-body-molde"
                back-ground="border-header-primary-bottom"
              >
                <h5 slot="title" class="mb-0">Serviços</h5>

                <div
                  v-if="commission.services && commission.services[0]"
                  v-for="(service, index_service) in commission.services"
                  :key="index_service"
                >
                  <h5 slot="title" class="mb-0 ">{{ service.service_name }}</h5>
                </div>
              </collapse-item>
            </collapse>
            <!-- Parâmetros-->
            <collapse
              v-if="commission.parameters && commission.parameters[0]"
              class="border rounded p-0 mt-3"
            >
              <collapse-item
                name="1"
                class="header-gray card-body-molde"
                back-ground="border-header-success-bottom"
              >
                <h5 slot="title" class="mb-0">Parâmetros</h5>
                <div class="row">
                  <div class="col-12 text-center">
                    <h5 class="mb-0">
                      Sobre o preço mínimo de venda
                    </h5>
                  </div>
                </div>
                <div class="pl-4 pr-4">
                  <!-- Body -->
                  <div class="pb-3 pt-2">
                    <div
                      class="row"
                      v-for="(parameter, index) in commission.parameters"
                      :key="index"
                    >
                      <div class="col-4 text-center pr-1">
                        <div
                          class="align-middle d-flex justify-content-center align-items-center"
                        >
                          <small>{{ parameter.from }}%</small>
                        </div>
                        <div
                          class="align-middle d-flex justify-content-center align-items-center"
                        >
                          <small>{{ parameter.until }}%</small>
                        </div>
                      </div>
                      <div
                        class="col-4 text-center pl-1 pr-0 align-middle d-flex justify-content-center align-items-center text-success"
                      >
                        <small>{{ parameter.commission }}%</small>
                      </div>
                      <div
                        class="col-4 pl-1 pr-3 d-flex justify-content-center align-items-center"
                      >
                        <div class="d-flex justify-content-center">
                          <a href="#">
                            <i
                              @click="handleEditParameter(commission.uuid,parameter.uuid)"
                              class="fas fa-edit text-warning mr-1"
                            ></i>
                          </a>
                          <a
                            @click="
                              handleDeleteParameter(
                                commission.uuid,
                                parameter.uuid
                              )
                            "
                            href="#"
                          >
                            <i class="fas fa-times text-danger"></i>
                          </a>
                        </div>
                      </div>
                      <hr class="m-0 mt-2 pb-1 w-100" type="primary" />
                    </div>
                  </div>
                </div>
              </collapse-item>
            </collapse>
          </card>
        </div>
      </div>
    </div>

    <modal-edit-parameter @updatedParameter="init" ref="editParameter">
    </modal-edit-parameter>
    <modal-create-parameter @createdParameter="init" ref="createParameter">
     </modal-create-parameter>
    <modal-edit ref="editCommission"></modal-edit>
    <loading-pagination :show="loading && !loadingSkeleton"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import modalEdit from "./_ModalEdit";
import modalCreateParameter from "../Parameter/_ModalCreateParameter";
import modalEditParameter from "../Parameter/_ModalEditParameter";
import { formatErrorValidation } from "@/plugins";
import MultiFilter from "@/components/Utils/MultiFilterV2";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
export default {
  name: "ListCommission",
  mixins: [cursorPaginate],
  components: {
    SkeletonPuzlGrid,
    modalEdit,
    modalCreateParameter,
    modalEditParameter,
    MultiFilter,
    LoadingPagination,
  },
  data() {
    return {
      loadingSkeleton: false,
      filter: {},
    };
  },
  computed: {
    ...mapGetters({
      commissions: "commission/fetch"
    })
  },
  watch: {
    added() {
      this.init();
    }
  },
  props: {
    added: Boolean
  },
  methods: {
    init(filter = null) {
      this.startCursor(filter)
      this.$Progress.start()
      this.$store
        .dispatch("commission/fetchItemsPaginate", {
            filter: this.filter,
            next_page: this.paginate.nextUrl
        })
        .then(response => {
            this.$Progress.finish()
            this.resolveCursor(response)
        })
        .catch(error => {
            this.resolveCursor()
            this.$Progress.finish()
        });
    },
    handleDeleteCommission(uuid) {
      this.$Swal
        .confirmDelete()
        .then(result => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação."
            });
            this.$store
              .dispatch("commission/destroy", uuid)
              .then((response) => {
                this.$notify({
                  type: 'success',
                  message: response.message
                });
                this.$Progress.finish()
              });
          }
        })
        .catch(() => this.$Progress.finish());
    },
    handleDeleteParameter(uuid, parameter_uuid) {
      this.$Swal.confirmDelete().then(result => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$notify({
            type: "info",
            message: "Estamos trabalhando em sua solicitação."
          });
          let params = {
            uuid: uuid,
            parameter_uuid: parameter_uuid
          };
          this.$store
            .dispatch("commission/destroyParameter", params)
            .then((response) => {
              this.$notify({
                type: 'success',
                message: response.message
              });
              this.$Progress.finish();
              this.init();
            })
            .catch(error => {
              this.$notify({
                type: "danger",
                message: error.data.message
              });
              this.$Progress.finish();
            });
        }
      });
    },
    handleEditCommission(uuid) {
      this.$refs.editCommission.handleEditModal(uuid);
    },
    handleCreateParameter(uuid) {
      this.$refs.createParameter.handleCreateModal(uuid);
    },
    handleEditParameter(uuid, parameter_uuid) {
      this.$refs.editParameter.handleCreateModal(uuid, parameter_uuid);
    }
  },
  created() {
    this.init({});
  }
};
</script>

<style scoped>
.main-icon-menu {
  min-width: 18px;
}
</style>
