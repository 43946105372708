<template>
  <div class="mt-6">
    <div class="container-fluid">
      <div class="row card-wrapper">
        <SkeletonPuzlGrid v-for="(index) in 3" v-show="$parent.loadingSkeleton" :key="index"/>
      </div>
      <PuzlEmptyData v-if="source && !source.length && !$parent.loadingSkeleton"/>
      <!-- Start Card ContractProposal -->
      <div class="row card-wrapper mt-n4">
        <div
          class="col-md-6 col-lg-4 col-xlg-4"
          v-if="source && source.length && !$parent.loadingSkeleton"
          v-for="(contract_proposal, indexContractProposal) in source"
        >
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-10 ml-n2">
                  <!-- ícones -->
                  <div class="mt-3 mb-1 mt-n2 mr-4 d-flex justify-content-start align-items-center">
                    <a href="#" class="mt-1" @click.prevent="handleChangeStatus(contract_proposal)">
                      <badge
                        :style="
                          contract_proposal.status == ContractProposalEnum.ACTIVE && !contract_proposal.entity_uuid ? 'background-color: #d6d8db; color: #606062' :
                          contract_proposal.status == ContractProposalEnum.ACTIVE ? 'background-color: #daf1e5; color: #149e57' :
                          contract_proposal.status == ContractProposalEnum.INACTIVE ? 'background-color: #fde1e1; color: #e05a50' :
                          contract_proposal.status == ContractProposalEnum.PENDING_REVIEW ? 'background-color: #fff3cd; color: #856404' :
                          contract_proposal.status == ContractProposalEnum.PENDING_APPROVAL ? 'background-color: #ffedcc; color: #d39e00' :
                          contract_proposal.status == ContractProposalEnum.PROPOSAL ? 'background-color: #d6d8db; color: #606062' :
                          contract_proposal.status == ContractProposalEnum.COMPLETED ? 'background-color: #cce5ff; color: #004085' :
                          'background-color: #f8f9fa; color: #6c757d'"
                        style="padding: 7px 16px; margin-left: -5px;"
                        class="mt-n1 mb-2 mr-1"
                      >
                        <template v-if="contract_proposal.entity_uuid">
                          <span
                            style="font-size: 10.3px;"
                            v-if="contract_proposal.status"
                          >
                            {{ statusName[contract_proposal.status] }}
                          </span>
                        </template>
                        <template v-if="!contract_proposal.entity_uuid">
                          <span
                            style="font-size: 10.3px;"
                            v-if="!contract_proposal.entity_uuid && contract_proposal.status === ContractProposalEnum.ACTIVE"
                          >
                            PROPOSTA
                          </span>
                          <span style="font-size: 10.3px;" v-else>
                            {{ statusName[contract_proposal.status] }}
                          </span>
                        </template>
                        <span style="font-size: 10.3px;" v-if="!contract_proposal.status">Bloqueado</span>
                        <i class="fa-solid fa-chevron-down ml-1" style="font-size: 10px;"></i>
                      </badge>
                    </a>
                    <img
                      v-if="contract_proposal.entity_uuid"
                      class="pointer mr-1"
                      @click="handleEntityHistory(contract_proposal)"
                      width="22"
                      src="/img/icons/wallet.png"
                      title="Histórico de cliente"
                    />

                    <!--                    hover-->
                    <el-popover trigger="click" placement="left" class="p-0 pr-0 mr-1">
                      <div class="row" v-if="loadingContractProposal">
                        <div class="col-12 text-center">
                          <img
                            width="40px"
                            src="/img/loading.gif"
                          />
                        </div>
                      </div>
                      <div class="row" v-if="updated_info[contract_proposal.id] && !loadingContractProposal">
                        <div class="mt-n3 p-4">
                          <div>
                            <h3 slot="title">
                              <img src="/img/icons/salesman.png" width="22">
                              Vendedor
                            </h3>
                            <span class="h4 small"> {{ updated_info[contract_proposal.id].seller_name }} </span>
                          </div>

                          <hr class="bg-secondary" style="margin-top: 12px;">

                          <div class="mt-n3">
                            <h3 slot="title">
                              <img src="/img/icons/icons8/ios/chemical-plant-v1.png" width="22">
                              Central
                            </h3>
                            <span class="h4 small"> {{ updated_info[contract_proposal.id].plant.name }} </span>
                          </div>

                          <hr class="bg-secondary" style="margin-top: 12px;">

                          <div
                            v-if="updated_info[contract_proposal.id] && updated_info[contract_proposal.id].service_receiver"
                            class="mt-n3">
                            <h3 slot="title">
                              <img class="mr-1" src="/img/icons/icons8/ios/contract-job_primary.png" width="22">
                              Dados do cliente
                            </h3>
                            <div class="row align-items-center pb-0 mb-3">
                              <div class="col-md-12">
                                <span class="mt-4 p">
                                  <span class="h4 small">
                                    <span class="text-dark h5">CNPJ/CPF:</span>
                                    {{ updated_info[contract_proposal.id].service_receiver.document || '' }}
                                  </span>
                                </span>
                                <br/>
                                <span
                                  class="mt-4 p"
                                  v-if="
                                  updated_info[contract_proposal.id] &&
                                  updated_info[contract_proposal.id].service_receiver &&
                                  updated_info[contract_proposal.id].service_receiver.address"
                                >
                                  <span class="h4 small">
                                    <span class="text-dark h5">ENDEREÇO:</span>
                                    {{ updated_info[contract_proposal.id].service_receiver.address.city }}
                                    /
                                    {{ updated_info[contract_proposal.id].service_receiver.address.state }}
                                  </span>
                                  <br/>
                                  <span class="h4 small">
                                    {{ updated_info[contract_proposal.id].service_receiver.address.address || '' }},
                                    {{ updated_info[contract_proposal.id].service_receiver.address.number || '' }},
                                    {{ updated_info[contract_proposal.id].service_receiver.address.district || '' }}
                                  </span>
                                </span>
                                <br/>
                                <span
                                  class="mt-4 p"
                                  v-if="
                                  updated_info[contract_proposal.id] &&
                                  updated_info[contract_proposal.id].service_receiver &&
                                  updated_info[contract_proposal.id].service_receiver.address"
                                >
                                  <span class="h4 small">
                                  {{ updated_info[contract_proposal.id].service_receiver.address.postal_code || '' }}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>

                          <hr
                            v-if="updated_info[contract_proposal.id] && updated_info[contract_proposal.id].service_receiver"
                            class="bg-secondary" style="margin-top: 5px;">

                          <h3 slot="title" class="mt-n3">
                            <img class="mr-1" src="/img/icons/icons8/ios/brick-wall_warning.png" width="22">
                            Dados da Obra
                          </h3>
                          <div class="row align-items-center pb-0">
                            <div class="col-md-12">
                              <span v-if="updated_info[contract_proposal.id].construction.estimated_volume"
                                    class="mt-4 p">
                                <span class="h4 small">
                                  <span class="text-dark h5">VOLUME PREVISTO:</span>
                                  {{ updated_info[contract_proposal.id].construction.estimated_volume || '-' }}
                                  <small>m<sup>3</sup></small>
                                </span>
                              </span>
                              <br v-if="updated_info[contract_proposal.id].construction.estimated_volume"/>
                              <span class="mt-4 p">
                                <span class="h4 small">
                                  <span class="text-dark h5">ENDEREÇO:</span>
                                  {{ updated_info[contract_proposal.id].construction.default_address.city }}
                                  /
                                  {{ updated_info[contract_proposal.id].construction.default_address.state }}
                                </span>
                                <br/>
                                <span class="h4 small">
                                  {{ updated_info[contract_proposal.id].construction.default_address.address || '' }},
                                  {{ updated_info[contract_proposal.id].construction.default_address.number || '' }},
                                  {{ updated_info[contract_proposal.id].construction.default_address.district || '' }}
                                </span>
                              </span>
                              <br/>
                              <span class="mt-4 p">
                                <span class="h4 small">
                                {{ updated_info[contract_proposal.id].construction.default_address.postal_code || '' }}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <base-button
                        @click.prevent="getContractProposal(contract_proposal)"
                        outline slot="reference" size="sm" type="secundary"
                        class="p-0 m-0 shadow-none text-indigo"
                      >
                        <img src="/img/icons/info.png" width="22">
                      </base-button>
                    </el-popover>
                    <a
                      v-if="
                        contract_proposal.total_construction_attachments > 0 &&
                        contract_proposal.entity_uuid
                      "
                      href="#"
                      @click.prevent="handleShowConstructionAttachments(
                        contract_proposal.construction_id,
                        contract_proposal.construction_name
                      )"
                      class="mr-n1"
                    >
                      <img
                        src="/img/icons/paperclip-green.png"
                        width="22"
                        :title="`Total de anexos: ${contract_proposal.total_construction_attachments}`"
                      />
                    </a>
                    <a
                      v-if="!contract_proposal.entity_uuid"
                      href="#"
                      class="mr-1"
                      @click.prevent="openModalPrint(contract_proposal)"
                    >
                      <img width="22" title="Imprimir/enviar" src="/img/icons/icons8/ios/print-primary.png"/>
                    </a>
                    <div
                      @click.prevent="handleShowModalDigitalSignature(contract_proposal)"
                      v-if="
                        digitalSignatureConfig && digitalSignatureConfig.enable_digital_signature &&
                        (contract_proposal.entity_uuid)
                      "
                      trigger="hover"
                      placement="bottom"
                      class="p-0 pr-0 mr-1"
                    >
                      <base-button
                        outline slot="reference" size="sm" type="secundary"
                        class="p-0 m-0 shadow-none text-indigo"
                      >
                        <img height="24" src="/img/icons/autograph-black.png" title="Assinatura digital"/>
                      </base-button>
                    </div>
                    <span
                      v-if="
                        contract_proposal.guarantor_entity_id &&
                        contract_proposal.entity_uuid
                      "
                      @click.prevent="selectGuarantorEntity(contract_proposal.id)"
                      class="ml-n2"
                    >
                      <el-popover trigger="click" placement="bottom" class="p-0">
                        <div
                          v-if="updated_info[contract_proposal.id] && updated_info[contract_proposal.id].guarantorEntity">
                          <h3 slot="title">
                            <img
                              src="/img/icons/icons8/ios/insurance-agent-danger.png"
                              width="22"
                            />
                            Fiador
                          </h3>
                          <div class="h4 small">{{
                              updated_info[contract_proposal.id].guarantorEntity.entity_name
                            }}</div>
                          <div class="h4 small">{{ updated_info[contract_proposal.id].guarantorEntity.document }}</div>
                        </div>
                        <span slot="reference" @click.prevent="getContractProposal(contract_proposal)">
                          <img
                            class="ml-2 mt-n2 pointer"
                            src="/img/icons/icons8/ios/insurance-agent-danger.png"
                            width="22"
                          />
                        </span>
                      </el-popover>
                    </span>
                  </div>

                  <h4 style="display: flex; align-items: center; font-size: 16px; color: #2B2D32;">
                    {{ contract_proposal.code }}
                    <span
                      style="font-size: 11px !important;"
                      title="Situação"
                      v-if="contract_proposal.situation_name"
                      class="font-weight-500 mr-1"
                    >
                      <span style="margin-left: 5px; margin-right: 3px;"> | </span>
                      {{ contract_proposal.situation_name }}
                    </span>
                  </h4>

                  <h4 class="font-weight-400" style="color: #2B2D32">
                    {{ contract_proposal.customer_name }}
                  </h4>
                  <h4 class="mt-n2 font-weight-400" style="color: #2B2D32">
                    {{ contract_proposal.construction_name }}
                  </h4>

                  <!-- Botão financeiro -->
                  <div v-if="$hasPermission('additional_permissions.financial_approvation')" class="row mb-n3"
                       style="margin-top: -30px !important; margin-left: -5px !important;">
                    <div class="col-md-6 mt-n2">
                      <div
                        class="col-2 ml-n4"
                        style="margin-top: 39px;"
                        :title="!$hasPermission('additional_permissions.financial_approvation') ? 'Sem permissão para alterar' : ''"
                      >
                        <base-dropdown
                          style="margin-top: -5px;"
                          class="btn-group btn-block"
                          v-show="contract_proposal.necessary_approvation && contract_proposal.entity_uuid"
                        >
                          <button
                            slot="title-container"
                            class="dropdown-toggle p-1 rounded m-0 mt-1 mb-1 btn-block colorize-btn-img font-weight-400 text-uppercase"
                            style="font-size: 10px; min-width: 110px;"
                            :class="[
                              contract_proposal.approvation === financialApprovalStatus['financial_pending']['status'] ? 'btn btn-outline-warning' : '',
                              contract_proposal.approvation === financialApprovalStatus['financial_approval']['status'] ? 'btn btn-outline-success' : '',
                              contract_proposal.approvation === financialApprovalStatus['financial_disapproval']['status'] ? 'btn btn-outline-danger' : '',
                            ]"
                          >
                            <img
                              v-if="contract_proposal.approvation === financialApprovalStatus['financial_pending']['status']"
                              src="/img/icons/icons8/ios/hourglass_warning.png" class="mr-1" width="19">
                            <img
                              v-if="contract_proposal.approvation === financialApprovalStatus['financial_approval']['status']"
                              src="/img/icons/icons8/ios/thumb-up_success.png" class="mr-1" width="19">
                            <img
                              v-if="contract_proposal.approvation === financialApprovalStatus['financial_disapproval']['status']"
                              src="/img/icons/icons8/ios/thumbs-down_danger.png" class="mr-1" width="19">
                            Financeiro
                          </button>
                          <span
                            class="dropdown-item"
                            :disabled="!$hasPermission('additional_permissions.financial_approvation') || [0,5,4].includes(contract_proposal.status)"
                            @click.prevent="approve(contract_proposal.id, financialApprovalStatus['financial_pending']['status'], indexContractProposal)"
                          >
                            <i class="fa-regular fa-clock text-warning"></i> PENDENTE
                          </span>
                          <div class="dropdown-divider p-0 m-0"></div>
                          <span
                            class="dropdown-item"
                            :disabled="!$hasPermission('additional_permissions.financial_approvation') || [0,5,4].includes(contract_proposal.status)"
                            @click.prevent="approve(contract_proposal.id, financialApprovalStatus['financial_approval']['status'], indexContractProposal)"
                          >
                            <i class="fa-solid fa-thumbs-up text-success"></i> APROVAR
                          </span>
                          <div class="dropdown-divider p-0 m-0"></div>
                          <span
                            class="dropdown-item"
                            :disabled="!$hasPermission('additional_permissions.financial_approvation') || [0,5,4].includes(contract_proposal.status)"
                            @click.prevent="approve(contract_proposal.id, financialApprovalStatus['financial_disapproval']['status'], indexContractProposal)"
                          >
                            <i class="fa-solid fa-thumbs-down text-danger"></i> REPROVAR
                          </span>
                        </base-dropdown>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-2">
                  <base-dropdown menuOnRight>
                    <div slot="title-container"
                         class="dropdown-toggle rounded m-0">
                      <img width="32" src="/img/icons/icons8/ios/settings--v1_primary.png">
                    </div>
                    <router-link
                      :to="{
                        name:'commercial.contract-proposal.edit',
                        path:'/commercial/contract-proposal/edit',
                        params: { contract_proposal_uuid: contract_proposal.uuid}
                      }"
                    >
                      <a class="dropdown-item" style="display: flex; align-items: center;">
                        <img width="26" src="/img/icons/create-new.png"/>
                        <span class="text-right">Editar</span>
                      </a>
                    </router-link>
                    <div class="dropdown-divider p-0 m-0"></div>
                    <a
                      style="display: flex; align-items: center;"
                      @click.prevent="handlePortfolioProjection(
                      contract_proposal.id,
                      [
                        contract_proposal.code,
                        contract_proposal.customer_name,
                        contract_proposal.construction_name,
                        contract_proposal.company_plant_id
                      ]
                    )"
                      class="dropdown-item">
                      <img width="23" height="23" src="/img/icons/icons8/ios/ratings.png"/>
                      <span class="text-right">Projeção de Carteira</span>
                    </a>
                    <div class="dropdown-divider p-0 m-0"></div>
                    <a
                      v-if="!contract_proposal.entity_uuid"
                      @click="handleShowModalSearchEntity(contract_proposal)"
                      class="dropdown-item"
                      style="display: flex; align-items: center;"
                    >
                      <img src="/img/icons/icons8/ios/agreement-black.png" class="mr-1" height="26"/>
                      <span class="text-right">Gerar contrato</span>
                    </a>
                    <div
                      class="dropdown-divider p-0 m-0"
                      v-if="!contract_proposal.entity_uuid"
                    />
                    <a
                      v-if="contract_proposal.entity_uuid"
                      style="display: flex; align-items: center;"
                      class="dropdown-item" href="#"
                      @click.prevent="handleModalEbitdaAnalysis(contract_proposal)"
                    >
                      <span>
                        <img
                          v-if="$hasPermission('additional_permissions.contract-proposal-formulation.details.view')"
                          width="26"
                          src="https://img.icons8.com/ios/100/1a70b7/bar-chart--v1.png"
                        />
                      </span>
                      <span class="text-right">Ebitda</span>
                    </a>
                    <div class="dropdown-divider p-0 m-0" v-if="contract_proposal.entity_uuid"/>
                    <a
                      v-if="contract_proposal.entity_uuid"
                      style="display: flex; align-items: center;"
                      class="dropdown-item" href="#"
                      @click.prevent="selectGuarantorEntity(contract_proposal.id)"
                    >
                      <span>
                        <img width="23" height="23" src="/img/icons/icons8/ios/insurance-agent-danger.png"/>
                      </span>
                      <span class="text-right">Fiador</span>
                    </a>
                    <div class="dropdown-divider p-0 m-0" v-if="contract_proposal.entity_uuid"/>
                    <a
                      v-if="contract_proposal.entity_uuid"
                      style="display: flex; align-items: center;"
                      @click.prevent="handleGeneralResume(contract_proposal.id)"
                      class="dropdown-item"
                    >
                      <img width="23" height="23" src="/img/icons/icons8/ios/profit-report.png"/>
                      <span class="text-right">Resumo Geral</span>
                    </a>
                    <div class="dropdown-divider p-0 m-0" v-if="contract_proposal.entity"/>
                    <a
                      style="display: flex; align-items: center;"
                      class="dropdown-item" href="#"
                      @click.prevent="openModalPrint(contract_proposal)"
                    >
                      <img width="23" height="23" src="/img/icons/icons8/ios/print-primary.png"/>
                      <span class="text-right">Imprimir</span>
                    </a>
                    <div class="dropdown-divider p-0 m-0"></div>
                    <a
                      v-if="contract_proposal.entity_uuid"
                      style="display: flex; align-items: center;"
                      class="dropdown-item" href="#"
                      @click.prevent="handleContractProposalApprovalHistory(contract_proposal.lastApproval)"
                    >
                      <img width="23" height="23" src="/img/icons/order-history.png"/>
                      <span class="text-right">Histórico</span>
                    </a>
                    <div class="dropdown-divider p-0 m-0" v-if="contract_proposal.entity_uuid"/>
                    <a
                      style="display: flex; align-items: center;"
                      class="dropdown-item"
                      @click.prevent="handleRemoveContractProposal(contract_proposal)"
                    >
                      <img width="23" height="23" src="/img/icons/delete.png"/>
                      <span>Excluir</span>
                    </a>
                  </base-dropdown>
                </div>
              </div>

              <hr
                class="mb-n1"
                :style="$route.query.type === 'contract' ? 'background-color: #149e57' : 'background-color: #606062'"
              />

              <!-- Footer -->
              <div
                v-if="contract_proposal.entity_uuid"
                class="row align-items-center"
                style="margin-top: 23px;"
              >
                <div class="col-2 justify-content-center" style="display: inline-flex;">
                  <router-link :to="pushRouter(contract_proposal, 'formulations')">
                    <a
                      title="Concretos"
                      class="pointer ml-1 mr-1 avatar rounded-pill bg-white"
                      style="border: 1px solid #E8E8E8">
                      <img style="width: 25px" src="/img/icons/test-tube.png"/>
                    </a>
                    <div
                      v-if="contract_proposal.pending_formulation"
                      style="position: absolute;top: 0;right: 12%;background: white;border-radius: 11px;width: 22px;"
                    >
                      <img src="/img/icons/icons8/ios/box-important--v1_danger.png" width="22"/>
                    </div>
                  </router-link>
                </div>
                <div class="col-2 justify-content-center" style="display: inline-flex;">
                  <router-link :to="pushRouter(contract_proposal, 'services')">
                    <a
                      title="Serviços"
                      class="pointer ml-1 mr-1 avatar rounded-pill bg-white"
                      style="border: 1px solid #E8E8E8">
                      <img style="width: 25px" src="/img/icons/todo-list.png"/>
                      <div
                        v-if="contract_proposal.pending_services"
                        style="position: absolute;top: 0;right: 12%;background: white;border-radius: 11px;width: 22px;"
                      >
                        <img src="/img/icons/icons8/ios/box-important--v1_danger.png" width="22"/>
                      </div>
                    </a>
                  </router-link>
                </div>
                <div class="col-2 justify-content-center" style="display: inline-flex;">
                  <router-link :to="pushRouter(contract_proposal, 'additionals')">
                    <a
                      title="Adicionais"
                      class="pointer ml-1 mr-1 avatar rounded-pill bg-white"
                      style="border: 1px solid #E8E8E8">
                      <img style="width: 25px" src="/img/icons/plus-math.png"/>
                    </a>
                  </router-link>
                </div>
                <div class="col-2 justify-content-center" style="display: inline-flex;">
                  <router-link :to="`/operational/contract-proposal/schedule/${contract_proposal.uuid}`">
                    <a
                      title="Programações"
                      class="pointer ml-1 mr-1 avatar rounded-pill bg-white"
                      style="border: 1px solid #E8E8E8">
                      <img style="width: 25px" src="/img/icons/calendar.png"/>
                    </a>
                  </router-link>
                </div>
                <div class="col-2 justify-content-center" style="display: inline-flex;">
                  <router-link :to="pushRouter(contract_proposal, 'payments')">
                    <a
                      title="Recebimentos"
                      class="pointer ml-1 mr-1 avatar rounded-pill bg-white"
                      style="border: 1px solid #E8E8E8">
                      <img style="width: 25px" src="/img/icons/card-in-use.png"/>
                      <div
                        v-if="contract_proposal.pending_launch_value > 0"
                        style="position: absolute;top: 0;right: 12%;background: white;border-radius: 11px;width: 22px;"
                      >
                        <img src="/img/icons/icons8/ios/box-important--v1_danger.png" width="22"/>
                      </div>
                    </a>
                  </router-link>
                </div>
                <div class="col-2 justify-content-center" style="display: inline-flex;">
                  <router-link :to="`/commercial/contract-proposal/invoices/${contract_proposal.uuid}`">
                    <a
                      title="Faturas"
                      class="pointer ml-1 mr-1 avatar rounded-pill bg-white"
                      style="border: 1px solid #E8E8E8">
                      <img style="width: 25px" src="/img/icons/check.png"/>
                    </a>
                  </router-link>
                  
                </div>
              </div>
              <div v-else class="row align-items-center" style="margin-top: 23px;">
                <div class="col-4 justify-content-center" style="display: inline-flex;">
                  <router-link :to="pushRouter(contract_proposal, 'formulations')">
                    <a
                      title="Concretos"
                      class="pointer ml-1 mr-1 avatar rounded-pill bg-white"
                      style="border: 1px solid #E8E8E8">
                      <img style="width: 25px" src="/img/icons/test-tube.png"/>
                    </a>
                    <div
                      v-if="contract_proposal.pending_formulation"
                      style="position: absolute;top: 0;right: 12%;background: white;border-radius: 11px;width: 22px;"
                    >
                      <img src="/img/icons/icons8/ios/box-important--v1_danger.png" width="22"/>
                    </div>
                  </router-link>
                </div>
                <div class="col-4 justify-content-center" style="display: inline-flex;">
                  <router-link :to="pushRouter(contract_proposal, 'services')">
                    <a
                      title="Serviços"
                      class="pointer ml-1 mr-1 avatar rounded-pill bg-white"
                      style="border: 1px solid #E8E8E8">
                      <img style="width: 25px" src="/img/icons/todo-list.png"/>
                      <div
                        v-if="contract_proposal.pending_services"
                        style="position: absolute;top: 0;right: 12%;background: white;border-radius: 11px;width: 22px;"
                      >
                        <img src="/img/icons/icons8/ios/box-important--v1_danger.png" width="22"/>
                      </div>
                    </a>
                  </router-link>
                </div>
                <div class="col-4 justify-content-center" style="display: inline-flex;">
                  <router-link :to="pushRouter(contract_proposal, 'additionals')">
                    <a
                      title="Adicionais"
                      class="pointer ml-1 mr-1 avatar rounded-pill bg-white"
                      style="border: 1px solid #E8E8E8">
                      <img style="width: 25px" src="/img/icons/plus-math.png"/>
                    </a>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Card ContractProposal -->
      <LoadingPagination :show.sync="$parent.loading && !$parent.loadingSkeleton"/>
    </div>
    <ModalChangeStatus @changeStatus="load()" ref="changeStatus"/>
    <ModalSendLinkToCustomerApprove ref="sendLinkToCustomerApprove"/>
    <ModalContractProposalApprovalHistory ref="contractProposalApprovalHistory"/>
    <ModalContractProposalApprovals @fetch="load" ref="contractProposalApprovals"/>
    <ModalPrintOut @open="openModalContractPrint" ref="ModalPrint"/>
    <ModalContractPrint ref="modalContractPrint"/>
    <ModalGeneralResume ref="modalGeneralResume"/>
    <ModalEntityHistory ref="modalEntityHistory"/>
    <ModalEbitdaAnalysis ref="modalEbitdaAnalysis"/>
    <ModalSearchEntity @selected="storeGuarantorEntity" ref="modalSearchEntity"/>
    <ModalGuarantorEntity
      ref="modalGuarantorEntity"
      @deletedGuarantorEntity="updateContractAfterGuarantorEntityDeletion"
      @contractProposalUpdated="updateContractAfterGuarantorEntityEdition"/>
    <ModalConstructionAttachmentList
      ref="modalConstructionAttachmentList"
      @updatedTotalAttachmentAfterUpload="updatedTotalConstructionAttachmentAfterUpload"
      @updateTotalAttachmentAfterDeletion="updateTotalConstructionAttachmentAfterDeletion"/>
    <ModalDigitalSignatureList ref="modalDigitalSignature"/>
    <ModalSearchEntityContratProposal ref="modalSearchEntityContratProposal" @setEntity="setEntity"/>
    <ModalPortfolioProjection ref="modalPortfolioProjection"/>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import ModalSendLinkToCustomerApprove from './_ModalSendLinkToCustomerApprove.vue'
import ModalContractProposalApprovalHistory from './_ModalContractProposalApprovalHistory.vue'
import ModalContractProposalApprovals from './_ModalContractProposalApprovals'
import ModalChangeStatus from "./_ModalChangeStatus";
import SkeletonPuzlGrid from "../../../../../components/SkeletonPuzlGrid";
import ModalPrintOut from './_ModalPrintOut'
import ModalContractPrint from './_ModalContractPrint'
import LoadingPagination from "@/components/LoadingPagination";
import ModalGeneralResume from "./_ModalGeneralResume.vue";
import PuzlEmptyData from "../../../../../components/PuzlEmptyData";
import SkeletonPuzlFullWidth from "@/components/SkeletonPuzlFullWidth";
import ModalEntityHistory from "../../../Configuration/Entity/Shared/_ModalHistory";
import {construction as $_totvs} from "@/plugins/microservices/totvs";
import ModalEbitdaAnalysis from './_ModalEbitdaAnalysis';
import ModalSearchEntity from "@/views/Modules/Configuration/Entity/Shared/_ModalSearchEntity";
import ModalGuarantorEntity from "./_ModalGuarantorEntity";
import ModalConstructionAttachmentList
  from "@/views/Modules/Commercial/CustomerConstruction/Constructions/Shared/_ModalConstructionAttachmentList";
import ModalDigitalSignatureList from './_ModalDigitalSignatureList.vue';
import {ContractProposalEnum} from "@/enum/ContractProposalEnum";
import ModalSearchEntityContratProposal from "./_ModalSearchEntity";
import ModalPortfolioProjection from "./_ModalPortfolioProjection.vue";

export default {
  props: ['source', 'digitalSignatureConfig'],
  name: 'ListContractProposal',
  components: {
    PuzlEmptyData,
    LoadingPagination,
    SkeletonPuzlGrid,
    ModalSendLinkToCustomerApprove,
    ModalContractProposalApprovalHistory,
    ModalContractProposalApprovals,
    ModalChangeStatus,
    ModalPrintOut,
    ModalContractPrint,
    ModalGeneralResume,
    SkeletonPuzlFullWidth,
    ModalEntityHistory,
    ModalEbitdaAnalysis,
    ModalSearchEntity,
    ModalGuarantorEntity,
    ModalConstructionAttachmentList,
    ModalDigitalSignatureList,
    ModalSearchEntityContratProposal,
    ModalPortfolioProjection,
  },
  computed: {
    ...mapGetters({
      $_setting: "generalSetting/show",
      $_user: 'auth/getUser',
      $_contract_proposal_show: 'contractProposal/show',
      $_company_plants: "plant/activeItems",
    }),
  },
  data() {
    return {
      filter: {
        status: null,
      },
      showDetails: {},
      statusName: [
        'Inativo',
        'Ativo',
        'Pendente Revisão',
        'Pendente Aprovação',
        'Proposta',
        'Concluído',
      ],
      types: [
        'danger',
        'success',
        'yellow',
        'warning',
        'primary',
      ],
      financialApprovalStatus: {
        'financial_pending': {
          name: "Pendência financeira",
          status: 0,
        },
        'financial_approval': {
          name: "Aproação financeira",
          status: 1,
        },
        'financial_disapproval': {
          name: "Reprovação financeira",
          status: 2,
        }
      },
      loadingBalance: false,
      contractId: null,
      ContractProposalEnum: ContractProposalEnum,
      selectedProposalToConvertToContract: null,
      foundContractProposal: null,
      loadingContractProposal: false,
      updated_info: {},
      is_cno_required_prop_contracts: false,
      is_municipal_work_code_required_prop_contracts: false,
      is_art_encapsulation_required: false,
    }
  },
  methods: {
    async getContractProposal(contract_proposal) {
      if (this.updated_info[contract_proposal.id]) {
        return
      }
      this.loadingContractProposal = true
      await this.$store.dispatch('contractProposal/show', contract_proposal.uuid).then((response) => {
        this.updated_info[contract_proposal.id] = response.data
        this.loadingContractProposal = false
      })
    },
    load() {
      this.$parent.init({})
    },
    getImageByStatus(status) {
      switch (status) {
        case this.ContractProposalEnum.INACTIVE:
          return '/img/icons/icons8/ios/shutdown--v1.png';
          break;
        case this.ContractProposalEnum.ACTIVE:
          return '/img/icons/icons8/ios/ok--v1.png';
          break;
        case this.ContractProposalEnum.PENDING_REVIEW:
          return '/img/icons/icons8/ios/hourglass.png';
          break;
        case this.ContractProposalEnum.PENDING_APPROVAL:
          return '/img/icons/icons8/ios/hourglass.png';
          break;
        case this.ContractProposalEnum.PROPOSAL:
          return '/img/icons/icons8/ios/proposal-white.png';
          break;
        case this.ContractProposalEnum.COMPLETED:
          return '/img/icons/icons8/ios/double-tick.png';
          break;
      }
    },
    setShowDetail(show, contract_proposal) {
      this.showDetails[contract_proposal.id] = show
      this.$forceUpdate()
    },
    isEqual(a, b) {
      a = a.toString().toLowerCase().trim()
      b = b.toString().toLowerCase().trim()
      return a === b
    },
    handleGeneralResume(id) {
      this.$refs.modalGeneralResume.openModal(id)
    },
    handleSendLinkToCustomerApprove(constructionUuid) {
      this.$refs.sendLinkToCustomerApprove.handleSendLink(constructionUuid)
    },
    openModalContractPrint() {
      this.$refs.modalContractPrint.openModal()
    },
    handleModalEntityHistory(entity, contract_proposal_id) {
      this.$refs.modalEntityHistory.openModal(entity.id, entity, contract_proposal_id);
    },
    pushRouter(contract_proposal, router) {
      switch (router) {
        case 'formulations':
          return {
            name: 'commercial.contract-proposal.formulation',
            params: {contract_proposal_uuid: contract_proposal.uuid}
          }
        case 'services':
          return {
            name: 'commercial.contract-proposal.services.view',
            params: {contract_proposal_uuid: contract_proposal.uuid}
          }
        case 'additionals':
          return {
            name: 'commercial.contract-proposal.additional.view',
            params: {contract_proposal_uuid: contract_proposal.uuid}
          }
        case 'payments':
          return {
            name: 'commercial.contract-proposal.payments.view',
            params: {
              contract_proposal_uuid: contract_proposal.uuid,
              construction_name: contract_proposal.construction_name,
              customer_name: contract_proposal.customer_name
            }
          }
        case 'invoices':
          return {
            name: 'commercial.sales-resume.index.view',
            params: {
              contract_uuid: contract_proposal.uuid,
            }
          }
      }
    },
    handleContractProposalApprovals(uuid) {
      this.$refs.contractProposalApprovals.openModal(uuid)
    },
    handleRemoveContractProposal(contractProposal) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$notify({
            type: 'info',
            message: 'Estamos trabalhando em sua solicitação.'
          });
          this.$store.dispatch("contractProposal/destroy", contractProposal.uuid).then(response => {
            this.$Progress.finish()
            $_totvs.destroy(contractProposal.construction.id);
            this.$notify({type: response.error_type, message: response.message});
            this.$emit('fetch');
          }).catch(error => {
            this.$notify({
              type: "danger",
              message: error.data.message
            });
            this.$emit('fetch');
            this.$Progress.finish();
          });
        }
      }).catch(() => this.$Progress.finish());
    },
    async openModalPrint(contractProposal) {
      await this.getContractProposal(contractProposal)
      if (this.$_setting.pending_proposals && contractProposal.status === 1) {
        this.$refs.ModalPrint.openModal(this.updated_info[contractProposal.id]);
      } else if (!this.$_setting.pending_proposals && contractProposal.status !== 1) {
        this.$notify({
          type: 'danger',
          message: 'Só é possível imprimir propostas ou contratos se o card estiver como ATIVO.'
        });
      } else {
        this.$refs.ModalPrint.openModal(this.updated_info[contractProposal.id]);
      }
    },
    async handleEntityHistory(contract_proposal) {
      await this.getContractProposal(contract_proposal)
      await this.$refs.modalEntityHistory.openModal(this.updated_info[contract_proposal.id].entity.id, this.updated_info[contract_proposal.id].entity, contract_proposal.id);
    },
    approve(id, status, index) {
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch('contractProposal/putApprove', {id: id, status: status})
        .then(response => {
          this.$notify({type: 'success', message: response.message});
          this.source[index].approvation = status
        }).catch(error => {
        this.$notify({type: error.response.data.error_type, message: error.response.data.message});
      })
    },
    async handleChangeStatus(contractProposal) {
      if ([3, 2].includes(contractProposal.status)) {
        if (!this.$hasPermission('additional_permissions.commercial_approvation')) {
          return;
        }
        this.handleContractProposalApprovals(contractProposal.uuid)
      }
      if (![1, 0, 5, 4].includes(contractProposal.status)) {
        return;
      }
      this.loading = true
      this.$Progress.start();
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      });
      await this.getContractProposal(contractProposal)
      this.$store
        .dispatch(
          "contractProposal/fetchSituations")
        .then(response => {
          this.$Progress.finish();
          this.loading = false
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.$refs.changeStatus.handleChangeStatus(this.updated_info[contractProposal.id]);
        });
    },
    handleContractProposalApprovalHistory(histories) {
      this.$refs.contractProposalApprovalHistory.openModal(histories)
    },

    handleModalEbitdaAnalysis(contract_proposal) {
      this.$refs.modalEbitdaAnalysis.openModal(contract_proposal.id, contract_proposal.uuid);
    },
    /**
     * @param {number} id
     */
    selectGuarantorEntity(id) {
      this.contractId = id;
      this.$store.dispatch('contractProposal/getGuarantorEntityContractProposalById', id).then((response) => {
        if (response.data.guarantor_entity_id) {
          this.$refs.modalGuarantorEntity.openModal(response.data);
        } else {
          this.$refs.modalSearchEntity.openModal();
        }
      });
    },
    /**
     * @param {object} entity
     */
    storeGuarantorEntity(entity) {
      this.$Progress.start();
      this.$store.dispatch('contractProposal/storeGuarantorEntity', {
        'id': this.contractId,
        'guarantor_entity_id': entity.id,
        'guarantor_entity_created_by_user_id': this.$_user.id,
      }).then((response) => {
        this.$emit("contractProposalUpdated", response.data);
        this.$notify({
          type: 'success',
          message: 'Fiador cadastrado com sucesso!'
        });
      }).finally(() => {
        this.$Progress.finish();
      });
    },
    /**
     * Envia evento de atualização do fiador para o componente pai.
     * @param {object} contractProposal
     */
    updateContractAfterGuarantorEntityEdition(contractProposal) {
      this.$emit('contractProposalUpdated', contractProposal);
    },
    /**
     * Envia evento de exclusão do fiador para o componente pai.
     * @param {number} contractProposalId
     */
    updateContractAfterGuarantorEntityDeletion(contractProposalId) {
      this.$emit('deletedGuarantorEntity', contractProposalId);
    },
    /**
     * Abre modal de listagem de anexos da obra.
     * @param {number} constructionId
     * @param {string} constructionName
     */
    handleShowConstructionAttachments(constructionId, constructionName) {
      this.$refs.modalConstructionAttachmentList.handleCreateModal(constructionId, constructionName);
    },
    /**
     * Atualiza total de anexos de obras após realizar o upload.
     * @param {number} constructionId
     */
    updatedTotalConstructionAttachmentAfterUpload(constructionId) {
      this.$store.dispatch('constructionAttachment/getTotalAttachmentByConstructionId', constructionId)
        .then(response => {
          let foundContractProposal = this.source.find((item) => item.construction_attachments[0].construction_id == constructionId);
          foundContractProposal.total_construction_attachments = response.data.total_construction_attachments;
        });
    },
    /**
     * Atualiza total de anexos da obra após deletar um anexo.
     * @param {number} constructionId
     */
    updateTotalConstructionAttachmentAfterDeletion(constructionId) {
      let foundContractProposal = this.source.find((item) => item.construction_attachments[0].construction_id == constructionId);
      foundContractProposal.total_construction_attachments--;
    },
   async handleShowModalDigitalSignature(contract_proposal) {
      await this.getContractProposal(contract_proposal)
     this.$refs.modalDigitalSignature.handleCreateModal({
       contract_proposal: this.updated_info[contract_proposal.id],
       digitalSignatureConfig: this.digitalSignatureConfig
     })
    },
    /**
     * Abre modal de pesquisa do tomador por documento.
     * @param {object} contract_proposal
     */
   async handleShowModalSearchEntity(contract_proposal) {
      await this.getContractProposal(contract_proposal)

      // Validações de configurações gerais da central antes de gerar um contrato.
      const isValid = await this.validateContractProposal(contract_proposal);
      if (!isValid) {
        return;
      }
     
      this.selectedProposalToConvertToContract = this.updated_info[contract_proposal.id];
      this.$refs.modalSearchEntityContratProposal.openModal(this.updated_info[contract_proposal.id]);
    },
    /**
     * Valida campos obrigatórios antes de gerar o contrato (configurações gerais de central)
     * @param {object} contract_proposal
     */
    async validateContractProposal(contract_proposal) {
      // Busca central para recuperar uuid
      const companyPlant = this.$_company_plants.find(
        item => item.id === this.updated_info[contract_proposal.id].company_plant_id
      );

      // Recupera dados de configurações gerais
      const response = await this.$store.dispatch("plant/getGeneralSettings", companyPlant.uuid);
      this.is_cno_required_prop_contracts = response.data.is_cno_required_prop_contracts;
      this.is_municipal_work_code_required_prop_contracts = response.data.is_municipal_work_code_required_prop_contracts;
      this.is_art_encapsulation_required = response.data.is_art_encapsulation_required;

      // Validações
      if (
        this.is_cno_required_prop_contracts &&
        !this.updated_info[contract_proposal.id].cno
      ) {
        this.$notify({
          type: 'warning',
          message: 'Preencha o campo CNO para gerar o contrato.'
        });
        return false;
      }

      if (
        this.is_municipal_work_code_required_prop_contracts &&
        !this.updated_info[contract_proposal.id].city_hall_construction_code
      ) {
        this.$notify({
          type: 'warning',
          message: 'Preencha o campo Cód. de obra da prefeitura para gerar o contrato.'
        });
        return false;
      }

      if (
        this.is_art_encapsulation_required &&
        !this.updated_info[contract_proposal.id].art_code
      ) {
        this.$notify({
          type: 'warning',
          message: 'Preencha o campo Art/nº de controle do encapsulamento para gerar o contrato.'
        });
        return false;
      }

      return true;
    },
    /**
     * Atualiza a proposta para adicionar o tomador e ser convertida em contrato.
     * @param {object} entity
     */
    async setEntity(entity) {

      this.$Progress.start();
      // Busca a proposta selecionada para realizar o update com os dados corretos, sem erros sql.
      await this.$store.dispatch("contractProposal/show", this.selectedProposalToConvertToContract.uuid)
        .then((response) => {
          this.foundContractProposal = response.data;
          this.foundContractProposal.entity = entity;
        });

      // Corrige formatação do campo 'volume médio por bt'
      const fixedValue = this.foundContractProposal.average_volume_per_bt.replace(",", ".");
      this.foundContractProposal.average_volume_per_bt = parseFloat(fixedValue).toFixed(1);

      // Back-end espera o uuid de entity no objeto service_receiver.uuid
      if (this.foundContractProposal.service_receiver === null) {
        this.foundContractProposal.service_receiver = {};
      }
      this.foundContractProposal.service_receiver.uuid = entity.uuid;

      // Atualiza status do card, deixando ele como um contrato ativo.
      if (this.foundContractProposal && this.foundContractProposal.entity) {
        let contractProposal = this.source.find(item => item.id === this.selectedProposalToConvertToContract.id);
        if (contractProposal.status == ContractProposalEnum.PROPOSAL) {
          contractProposal.status = ContractProposalEnum.ACTIVE;
          this.foundContractProposal.status = ContractProposalEnum.ACTIVE;
        }
      }

      this.$store
        .dispatch("contractProposal/update", this.foundContractProposal)
        .then(response => {
          if (this.foundContractProposal && this.foundContractProposal.entity) {
            $_totvs.update({
              ...this.foundContractProposal.construction,
              ...{entity_id: this.foundContractProposal.entity.id}
            });
          }
          this.$notify({
            type: response.error_type,
            message: response.message
          });

          // Remove card da listagem de propostas, agora ele é um contrato.
          this.source = this.source.filter(item => item.id !== this.foundContractProposal.id);
        })
        .catch(error => {
          if (error.response.status === 422) {
            let message = formatErrorValidation(error.response.data.errors);
            this.$notify({type: "danger", message: message});
          } else {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            });
          }
        }).finally(() => {
        this.$Progress.finish();
      });
    },
    handlePortfolioProjection(id, contract) {
      this.$refs.modalPortfolioProjection.openModal(id, contract)
    },
  },
  mounted() {
    this.$store.dispatch('generalSetting/show');
  },
}
</script>
<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

#add-credit-button .ni-fat-add::before {
  position: absolute;
  top: 25%;
  left: 23%;
}

.blue-divider {
  background-color: #1b6eba;
  color: #1b6eba;
  height: 0.1px;
}

.colorize-btn-img:hover img {
  filter: brightness(0) invert(1);
}

/** Badge personalizado para cards com status 'proposta' */
.custom-badge-proposal {
  background-color: white !important;
  color: #2B2D32 !important;
  border-bottom: 1px solid #ccc !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 0 8px rgba(0, 0, 0, 0.1) !important;
}

.custom-badge-proposal:hover,
.custom-badge-proposal.active-icon {
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
  background-color: #d3d3d3 !important;
}
</style>
