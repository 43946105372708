<template>
  <div>
    <modal
      :show.sync="modal.create"
      size="lg"
    >
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(submitAndClose)"
            autocomplete="off"
          >
            <!-- Conta -->
            <div class="form-group row m-0 p-0">
              <label class="col-md-3 pt-1 pb-3 mb-0 col-form-label form-control-label text-left pr-0">
                Conta
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-9 pr-4">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                    <PuzlSelect
                      v-model="bank_account_id"
                      :items="$_bank_accounts"
                      :disabled="loadingBankAccounts"
                      :loading="loadingBankAccounts"
                      :labelMask="true"
                      label="$name$ | $number_without_digit_account$ - $digit_account$" />
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <!-- Arquivo OFX -->
            <div class="form-group row m-0 p-0">
              <label class="col-md-3 pt-1 pb-3 mb-0 col-form-label form-control-label text-left pr-0">
                Arquivo OFX
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-9 pr-4">
                <input
                  type="file"
                  class="form-control-uniform"
                  multiple=""
                  v-on:change="handleFileUpload()"
                  id="file"
                  ref="file"
                  accept=".ofx"
                  data-fouc
                />
                <small
                  id="emailHelp2"
                  class="form-text text-muted"
                >Arquivos em formato:<span
                    class="badge mr-1 ml-1"
                    style="background-color: #d3d3d3"
                  >OFX</span>
                </small>
              </div>
            </div>

            <!-- MODAL FOOTER -->
            <div class="modal-footer">
              <base-button
                type="secondary"
                @click="close()"
              >
                Cancelar
              </base-button>
              <base-button
                type="success"
                native-type="submit"
                :disabled="invalid || !ofx_file"
                :loading="isLoading"
              >
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "ModalCreate",
  components: { PuzlSelect },
  data () {
    return {
      modal: {
        title: 'Arquivo de conciliação bancária',
        create: false,
      },
      isLoading: false,
      ofx_file: null,
      bank_account_id: null,
      loadingBankAccounts: true,
    };
  },
  computed: {
    ...mapGetters({
      '$_bank_accounts': 'bankAccount/fetch'
    }),
  },
  methods: {
    handleCreate () {
      this.loadData();
      this.bank_account_id = null
      this.ofx_file = null
      this.$refs.file.value = null
      this.modal.create = true
    },
    handleFileUpload () {
      this.ofx_file = this.$refs.file.files[0]
    },
    close () {
      this.modal.create = false
      this.$emit("close")
    },
    submitAndClose () {
      this.isLoading = true
      this.$Progress.start()
      this.$notify({ type: "info", message: "Estamos trabalhando em sua solicitação." })
      let params = {
        ofx_file: this.ofx_file,
        bank_account_id: this.bank_account_id
      }
      this.$store
        .dispatch("bankReconciliation/insertByOfxFile", params)
        .then(response => {
          this.$notify({ type: response.error_type, message: response.message })
          this.$Progress.finish()
          this.isLoading = false
          this.$store.dispatch('bankReconciliation/fetchItems')
          this.close()
        })
        .catch(error => {
          this.isLoading = false
          this.$Progress.finish()
          if (error.response && error.response.status === 422) {
            let message = formatErrorValidation(error.response.data.errors)
            this.$notify({
              type: "danger",
              message: message
            })
            this.$Progress.finish()
          } else {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            })
          }
        })
    },
    loadData() {
      // Lista de Contas
      this.loadingBankAccounts = true;
      this.$store.dispatch('bankAccount/fetchItems', {
        filter: {
          has_allocated_plant: true
        }
      }).then(() => {
        this.loadingBankAccounts = false;
      });
    },
  },
}
</script>

<style scoped></style>
