<template>
  <div>
      <el-popover :disabled="disabled" ref="dropdown" trigger="click" placement="bottom" class="p-0">
        <slot ref="mySlot">
        </slot>
        <span slot="reference" class="pointer">
                    <img :width="size === 'md' ? '28px' : '19px'" :style="disabled && 'opacity: 60%'" src="/img/icons/icons8/ios/settings--v1_primary.png">
        <i v-if="!disabled" style="color: #aeaaaa" class="fa-solid fa-caret-down ml-1"></i>
                    </span>
      </el-popover>
  </div>
</template>
<script>
export default {
  props: {
    size: {
      type: String,
      default: 'md'
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    closePopover() {
      this.$refs.dropdown.showPopper = false
    }
  },
}
</script>
