<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-gray d-inline-block mb-0">{{ $route.name }}</h6>
          <nav
            aria-label="breadcrumb"
            class="d-none d-md-inline-block ml-md-4 text-gray"
          >
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i></a></li>
              <li class="breadcrumb-item"><a href="#">Cadastro</a></li>
              <li class="breadcrumb-item" aria-current="page">{{ $route.name }}</li>
            </ol>
          </nav>
        </div>
      </div>
<!-- pr-6 pl-6 pt-3 pb-3 -->
      <!-- Header Menus -->
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-2 col-xlg-2 mb-3">
              <base-button block size="lg" type="success" @click="handleCreate()">
                <i class="fas fa-plus"></i> NOVO
              </base-button>
            </div>
            <div class="col-md-2 col-xlg-2 mb-3">
              <router-link :to="{ path:'/commercial/service-price'}">
                <base-button block size="lg" type="light">
                  VOLTAR
                </base-button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </base-header>
<list-service-description></list-service-description>
<add-service-description></add-service-description>
<edit-service-description></edit-service-description>
  </div>
</template>
<script>
import AddServiceDescription from './Shared/_ModalCreate.vue';
import EditServiceDescription from './Shared/_ModalEdit.vue';
import ListServiceDescription from './Shared/_List.vue';

export default {
  components: {
    AddServiceDescription,
    ListServiceDescription,
    EditServiceDescription,
  },
  data() {
    return {
    }
  },
  methods: {
    handleCreate(){
      EventBus.$emit("handleCreateServiceDescription");
    },
  },
  created(){
  },
  mounted() {
  }
};
</script>
<style></style>
