var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.disableBoxShadow === true ? 'disable-box-shadow-input-group' : ''},[_c('validation-provider',_vm._b({attrs:{"rules":_vm.rules,"name":_vm.name},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid, invalid, validated }){return [_c('div',{staticClass:"form-group m-0",style:('width: '+_vm.width)},[_vm._t("label",function(){return [(_vm.label)?_c('label',{staticClass:"form-control-label",class:_vm.labelClasses,staticStyle:{"font-size":"0.8rem"}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()]}),_c('div',{class:[
          { 'input-group': _vm.hasIcon },
          { focused: _vm.focused },
          { 'input-group-alternative': _vm.alternative },
          { 'has-label': _vm.label || _vm.$slots.label },
          _vm.inputGroupClasses
        ]},[(_vm.prependIcon || _vm.$slots.prepend)?_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text",class:_vm.prependClass},[_vm._t("prepend",function(){return [_c('i',{class:_vm.prependIcon})]})],2)]):_vm._e(),_vm._t("default",function(){return [_c('input',_vm._g(_vm._b({staticClass:"form-control m-0",class:[
              { 'is-valid': valid && validated && _vm.successMessage },
              { 'is-invalid': invalid && validated },
              _vm.inputClasses
            ],style:(_vm.size === 'sm' && 'height: 1.87rem'),attrs:{"type":_vm.type,"valid":valid,"required":_vm.required},domProps:{"value":_vm.value}},'input',_vm.$attrs,false),_vm.listeners))]},null,_vm.slotData),(_vm.appendIcon || _vm.$slots.append)?_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text",class:_vm.appendClass,style:(_vm.size === 'sm' && 'height: 1.87rem')},[_vm._t("append",function(){return [_c('i',{class:_vm.appendIcon})]})],2)]):_vm._e(),_vm._t("infoBlock")],2)],2)]}}],null,true)},'validation-provider',_vm.$attrs,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }