import { moneyToFloat, cloneObject } from "../../../../../helpers";
import { mapGetters } from "vuex";
import moment from "moment";

const exitLaunchMixin = {
  data() {
    return {
      selected_items: null,
      operation_source: false,
      loadingChartAccount: true,
      loadingOperationSource: false,
      max_date: false,
      product_service_hub_types: [
        { id: 2, name: "MCC" },
        { id: 3, name: "Produto" },
      ],
      freight_modalities: [
        { id: 1, name: "Por conta do emitente", code: "0" },
        { id: 2, name: "Por conta do destinatário/remetente", code: "1" },
        { id: 3, name: "Por conta de terceiros", code: "2" },
        { id: 4, name: "Transporte próprio por conta do remetente", code: "3" },
        { id: 5, name: "Transporte próprio por conta do destinatário", code: "4" },
        { id: null, name: "Sem frete", code: "9" },
      ],
      freight: {
        total_value: 0,
        quantity: null,
        gross_weight: null,
        net_weight: null,
        plate: null,
        model: null,
        species: null,
        entity_id: null,
        freight_modality_id: null,
      },
      freight_entity: null,
      document_templates: [
        { id: 1, name: "0 - Recibo" },
        { id: 2, name: "1 - Nota fiscal" },
        { id: 3, name: "1B - Nota fiscal avulsa" },
        { id: 4, name: "4 - Nota fiscal de produtor" },
        { id: 5, name: "55 - Nota fiscal eletrônica" },
        { id: 6, name: "57 - CT-e" },
        { id: 7, name: "65 - NFC-e" },
        { id: 8, name: "Nota fiscal de serviço" },
        { id: 9, name: "58 - MDFe" },
        { id: 10, name: "Nota fiscal fatura" },
        { id: 11, name: "Fatura" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      $_company_plants: "plant/activeItems",
      $_chart_accounts: "chartAccount/fetch",
      $_operation_sources: "operationSource/fetch",
      $_company_plant_issuers: "companyPlantIssuer/fetch",
    }),
  },
  created() {
    this.getMaxDate();
  },
  methods: {
    applyToAllItems(value, type, is_fuel) {
      if (!is_fuel) is_fuel = 0;
      this.$forceUpdate();
      /* Se não for o primeiro a ser definido ou for do tipo mcc interrompe a função */
      if (
        this.items.length <= 1 ||
        this.items.filter((item) => item[type] != null).length !== 1 ||
        (type === "stock_location_id" && this.exit_launch.product_service_hub_type_id === 2)
      )
        return;
      this.$Swal.confirmAction("Deseja aplicar para todos os itens?", ["Sim", "Não"]).then((result) => {
        if (result.isConfirmed) {
          this.items.map(function (item) {
            item.product_service_hub.is_fuel = item.product_service_hub.is_fuel ? item.product_service_hub.is_fuel : 0;
            /* local de estoque é variável para produtos do tipo de combustível. */
            if (type !== "stock_location_id" || item.product_service_hub.is_fuel === is_fuel) {
              item[type] = value;
            }
            return item;
          });
          this.$forceUpdate();
        }
      });
    },

    /**
     * Normaliza valor manipulado ou não por v-money.
     *
     * @param {string|float|null} value
     * @returns {string|float|null}
     */
    normalizeValue(value) {
      switch (true) {
        case !value:
          return null;
        case value.includes(","):
          return moneyToFloat(value);
        default:
          return parseFloat(value);
      }
    },

    /**
     * Busca tipos de operação Puzl
     *
     * @returns {void}
     */
    getOperationSources(handle_check_financial = false) {
      this.loadingOperationSource = true;
      this.$store
        .dispatch("operationSource/fetchItems", {
          filter: {
            product_service_hub_type_id: this.exit_launch.product_service_hub_type_id,
            type: 1,
          },
        })
        .then(() => {
          this.loadingOperationSource = false;
          if (handle_check_financial) this.checkFinancial();
        });
    },

    /**
     * Busca planos de contas
     *
     * @returns {void}
     */
    getChartAccounts() {
      this.$store.dispatch("chartAccount/fetchItems").then(() => {
        this.$_chart_accounts.sort((a, b) => parseInt(a.code.substring(0, 1)) - parseInt(b.code.substring(0, 1)));
        this.loadingChartAccount = false;
      });
    },

    /**
     * Verifica se aba de documento está preenchida com todos os campos obrigatórios.
     *
     * @returns {bool}
     */
    pendingDocumentTab() {
      switch (true) {
        case !this.exit_launch.issue_company_plant_id:
        case !this.exit_launch.exit_company_plant_id:
        case !this.exit_launch.product_service_hub_type_id:
        case !this.exit_launch.operation_source_id:
        case !this.exit_launch.company_plant_issuer_id:
        case !this.exit_launch.issue_date:
        case !this.exit_launch.document_template_id:
        case !this.exit_launch.delivery_date:
          return true;
        default:
          return false;
      }
    },

    /**
     * Verifica se aba de itens está preenchida com todos os campos obrigatórios.
     *
     * @returns {bool}
     */
    pendingItemTab() {
      switch (true) {
        case this.items.length === 0:
        case this.items.findIndex((item) => !item.chart_account_id) >= 0:
          return true;
        default:
          return false;
      }
    },

    /**
     * Avança para próxima aba
     *
     * @returns {void}
     */
    goToNextTab() {
      this.tab += this.tab === 1 ? 2 : 1;
    },

    /**
     * Define se botão de próxima aba deve ser habilitado
     *
     * @returns {bool}
     */
    getNextTabButtonStatus() {
      /* (Existirão mais opções no switch) */
      switch (true) {
        case this.tab === 1:
        case this.tab === 2: // transporte
          return true;
        default:
          return false;
      }
    },

    /**
     * Ativa ou desativa a marcação de itens
     *
     * @returns {void}
     */
    setSelectedItems() {
      this.selected_items = !this.selected_items ? [] : null;
    },

    /**
     * Adiciona item ao array de itens marcados
     *
     * @param {integer} product_service_hub_id
     * @returns {void}
     */
    addToSelectedItems(product_service_hub_id) {
      this.selected_items.push(product_service_hub_id);
    },

    /**
     * Adiciona todos os itens ao array de itens marcados
     *
     * @returns {void}
     */
    addAllToSelectedItems() {
      this.selected_items = this.items.reduce((a, o) => (a.push(o.product_service_hub_id), a), []);
    },

    /**
     * Remove todos itens do array de itens marcados
     *
     * @returns {void}
     */
    removeAllFromSelectedItems() {
      this.selected_items = [];
    },

    /**
     * Remove item do array de itens marcados
     *
     * @param {integer} product_service_hub_id
     * @returns {void}
     */
    removeFromSelectedItems(product_service_hub_id) {
      const index = this.selected_items.findIndex((item) => item === product_service_hub_id);
      this.selected_items.splice(index, 1);
    },

    /**
     * Aplica plano de contas ou rateio de equipamentos para itens de despesas
     *
     * @param {object|integer} value
     * @param {string} key
     * @returns {void}
     */
    applyToSelectedItems(value, key) {
      this.items.map(function (item) {
        if (this.selected_items.includes(item.product_service_hub_id)) {
          item[key] = value;
        }
      }, this);
      this.$forceUpdate();
      this.setSelectedItems();
    },

    /**
     * Busca data máxima permitida em componentes de data para fluxo de entradas
     *
     * @returns {void}
     */
    getMaxDate() {
      this.max_date = new Date(this.$helper.parseDate(moment(), "YYYY-MM-DD HH:mm:ss"));
    },

    /**
     * Ao se alterar o tipo de item, reseta os itens e o tipo de operação puzl
     *
     * @returns {void}
     */
    checkProductServiceHubType() {
      this.exit_launch.operation_source_id = null;
      this.operation_source = false;
      this.items = [];
      this.getOperationSources();
    },

    /**
     * Seta objeto completo de tipo de operação Puzl
     *
     * @param {integer} operation_source_id
     * @returns {void}
     */
    setOperationSourceById(operation_source_id) {
      this.operation_source = this.$_operation_sources.find((item) => item.id === operation_source_id);
      this.exit_launch.nature_operation = this.operation_source.name;
      this.exit_launch.cfop = this.operation_source.operation_nature ? this.operation_source.operation_nature.cfop : null;
    },

    /**
     * Busca cnpj emissor
     *
     * @returns {void}
     */
    getCompanyPlantIssuers() {
      this.loadingCompanyPlantIssuer = true;
      this.$store
        .dispatch("companyPlantIssuer/fetchItems", {
          company_plant_id: this.exit_launch.issue_company_plant_id,
        })
        .then(() => {
          if (this.exit_launch.issue_company_plant_id && !this.exit_launch.exit_company_plant_id) {
            this.exit_launch.exit_company_plant_id = this.exit_launch.issue_company_plant_id;
          }
          if (this.$_company_plant_issuers.length) {
            let default_company_plant_buyer =
              this.$_company_plant_issuers.filter((item) => item.default === 1)[0] || this.$_company_plant_issuers[0];
            this.exit_launch.company_plant_issuer_id = default_company_plant_buyer.id;
          } else {
            this.exit_launch.company_plant_issuer_id = null;
          }
          this.loadingCompanyPlantIssuer = false;
        });
    },
    /**
     * Formata item com estrutura necessária
     *
     * @param {object} item
     * @returns {object}
     */
    normalizeItem(item) {
      item.product_service_hub = item.product_service_hub ? item.product_service_hub : cloneObject(item);
      return {
        id: item.id ? item.id : null,
        cfop: item.cfop ? item.cfop : null,
        chart_account_id: item.chart_account_id ? item.chart_account_id : null,
        price: item.price,
        product_service_hub_id: item.product_service_hub_id,
        product_service_hub_type_id: item.product_service_hub.product_service_hub_type_id,
        quantity: item.quantity,
        discount: item.discount,
        total_value: item.total_value,
        is_fuel_group: item.is_fuel_group,
        anp_code: item.anp_code,
        anp_description: item.anp_description,
        consumer_uf: item.consumer_uf,
        cest: item.cest,
        purchase_unit: item.product_service_hub.purchase_unit ? item.product_service_hub.purchase_unit : item.purchase_unit,
        product_name: item.product_name || item.product_service_hub.product_name,
        ncm: item.ncm || item.product_service_hub.ncm,
        type_name: item.type_name,
        origin_name: item.product_service_hub.origin_name,
        supplier_name: item.product_service_hub.supplier_name,
        product_service_hub: item.product_service_hub,
        icms_bc: item.icms_bc,
        icms_aliq: item.icms_aliq,
        pis_bc: item.pis_bc,
        pis_aliq: item.pis_aliq,
        cofins_bc: item.cofins_bc,
        cofins_aliq: item.cofins_aliq,
        ipi_bc: item.ipi_bc,
        ipi_aliq: item.ipi_aliq,
        other_expenses: item.other_expenses,
      };
    },
    getFreight() {
      if (!this.freight.freight_modality_id) {
        this.clearFreight();
        return;
      }
      this.handleSelectEntity();
    },
    clearFreight() {
      this.freight_entity = {
        entity_name: null,
        document: null,
      };
      this.freight.entity_id = null;
    },
    handleSelectEntity() {
      if (!this.freight.freight_modality_id) {
        return;
      }
      this.$refs.modalSearchEntity.openModal();
    },
    selectedEntity(entity) {
      this.freight_entity = entity;
      this.freight.entity_id = entity.id;
    },
  },
};
export default exitLaunchMixin;
