import store from "../../../../shared/libraries/store";
import { adjustmentHistoryStoreStateKeys } from "./adjustmentHistoryStoreStateKeys";
import {
  AdjustmentHistoryListFilterType,
  AdjustmentHistoryListPagerType,
  AdjustmentHistoryCreateType
} from "../types";
import { SearchBarFilterType } from "../../../../components/AppGlobal";

/**
 * @param {AdjustmentHistoryListPagerType} listed
 * @returns {AdjustmentHistoryListPagerType}
 */
const setListed = (listed) =>
  store.commit(adjustmentHistoryStoreStateKeys.LISTED, listed);

/**
 * @param {boolean} isListing
 * @returns {boolean}
 */
const setIsListing = (isListing) =>
  store.commit(adjustmentHistoryStoreStateKeys.IS_LISTING, isListing);

/**
 * @param {boolean} isFinding
 * @returns {boolean}
 */
const setIsFinding = (isFinding) =>
  store.commit(adjustmentHistoryStoreStateKeys.IS_FINDING, isFinding);

/**
 * @param {boolean} isSaving
 * @returns {boolean}
 */
const setIsSaving = (isSaving) =>
  store.commit(adjustmentHistoryStoreStateKeys.IS_SAVING, isSaving);

/**
 * @param {AdjustmentHistoryListPagerType} listed
 * @returns {AdjustmentHistoryListPagerType}
 */
const setListMerge = (listed) =>
  store.commitListMerge(adjustmentHistoryStoreStateKeys, listed);

/**
 * @param {AdjustmentHistoryCreateType} created
 * @returns {void}
 */
const setNewItemInList = (created) =>
  store.commitNewItemToList(adjustmentHistoryStoreStateKeys, created);

/**
 * @param {{
 *   searchBarFilter: SearchBarFilterType,
 *   filter: AdjustmentHistoryListFilterType
 * }} filters
 */
const setPageState = (filters) =>
  store.commit(adjustmentHistoryStoreStateKeys.PAGE_STATES, filters);

/** Resetar chaves de estado */
const resetStates = () => {
  store.resetStates(Object.values(adjustmentHistoryStoreStateKeys));
};

export default {
  setListed,
  setIsListing,
  setIsFinding,
  setIsSaving,
  setListMerge,
  setPageState,
  resetStates,
  setNewItemInList,
};
