<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-gray d-inline-block mb-0">
            {{ this.$route.meta.breadcrumbs[2].title }}</h6>
          <nav aria-label="breadcrumb"
               class="d-none d-md-inline-block ml-md-4 text-gray">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i
                :class="this.$route.meta.icon"></i></a></li>
              <li class="breadcrumb-item"><a
                href="#">{{ this.$route.meta.breadcrumbs[0].title }}</a></li>
              <li class="breadcrumb-item"><a
                href="#">{{ this.$route.meta.breadcrumbs[1].title }}</a></li>
              <li class="breadcrumb-item" aria-current="page">
                {{ this.$route.meta.breadcrumbs[2].title }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="col-md-2 mb-2" v-if="contract_proposal">
        <router-link :to="{
        name:'commercial.contract-proposal.formulation.create',
        path:'/commercial/contract-proposal/formulation/create/type?=',
        params: { contract_proposal_uuid: contract_proposal.uuid}
      }">
          <create-new :disabled="loading || [0, 5].includes(contract_proposal.status)"/>
        </router-link>
      </div>
      <!-- Header Menus -->
      <div v-if="loading">
        <div v-for="item in 3">
          <div class="col-3 ml-n4">
            <SkeletonPuzl type="button"/>
          </div>
        </div>
      </div>
      <div class="row mt-n4" v-else>
        <div class="col-md-12">
          <div class="ml-3 mb-1">
            <div class="checklist-info">
              <h2 class="checklist-title mb-0">
                {{contract_proposal.entity ? 'Contrato' : 'Proposta'}}:<span
                class="small">{{ contract_proposal && contract_proposal.code }}
              </span>
              </h2>
              <h2 class="checklist-title mb-0">
                Cliente: <span
                class="small">
                  {{
                  contract_proposal && contract_proposal.construction && contract_proposal.construction.customer_construction.customer_name
                }}
                </span>
              </h2>
              <h2 class="checklist-title mb-0">
                Obra: <span
                class="small">
                  {{
                  contract_proposal && contract_proposal.construction && contract_proposal.construction.construction_name
                }}
                </span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div :class="contract_proposal.entity ? '' : 'd-flex justify-content-center'">
        <ContractProposalNavigation
          :contractUuid="contract_proposal.uuid"
          :contractStatus="contract_proposal.status"
          :entity="contract_proposal.entity"
          :activeFormulation="true"
        />
      </div>
    </base-header>
    <div class="container-fluid mt-2">
      <div class="row align-items-center pb-0">
        <div class="col-md-12">
          <base-button @click.prevent="handleFilterActive()" :class="filter.show_inactive && 'active'" outline type="danger">
            <i class="fa-solid fa-ban"></i> EXIBIR INATIVOS
          </base-button>
        </div>
      </div>
      <!-- Start Card ContractProposal -->
      <div class="row card-wrapper mt-2">
        <skeleton-puzl-grid v-if="loading" :key="item" v-for="item in 3"/>
        <template v-if="contract_proposal && !loading">
          <div class="col-md-4"
               v-for="(mix_design, indexMixDesign) in contract_proposal.mix_designs"
               :key="indexMixDesign">
            <!-- Basic with action button -->
            <div class="card">
              <div class="card-header pointer"
                   style="padding: 0px !important;height: 22px">
                <div class="row">
                  <div class="col-md-12">
                    <base-button size="sm" slot="title-container" class="d-flex text-white text-uppercase btn-header"
                                 style="opacity: 92%;color: #4f40ff"
                                 :type="mix_design.status === 1 ? 'success' : mix_design.status === 5 ? 'primary' : 'danger'"
                                 @click.prevent="handleSetStatus(mix_design.formulation_uuid, Number(!mix_design.status), indexMixDesign)"
                                 block>
                      <div>
                        <i v-if="mix_design.status === 1" class="fa-regular fa-circle-check"></i>
                        <i v-if="mix_design.status === 0" class="fa-regular fa-circle-xmark"></i>
                        <i v-if="mix_design.status === 5" class="fa-brands fa-react"></i>
                      </div>
                      {{ mix_design.status === 1 ? 'ATIVO' : mix_design.status === 5 ? 'COMERCIAL' : 'INATIVO' }}
                    </base-button>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row align-items-center mt-n5 mb-2">
                  <div class="col-8">
                    <!-- Title -->
                    <div class="row mt-n1">
                      <div class="mt-4 col-6" v-if="!mix_design.situation">
                        <badge rounded tag="a" href="#" type="warning"><i class="fa-regular fa-circle-check mr-1"></i>PENDENTE
                          APROVAÇÃO
                        </badge>
                      </div>
                      <div v-if="mix_design.situation && mix_design.situation.situation == 2" class="mt-4 col-6">
                        <badge rounded tag="a" href="#" type="yellow"><i
                          class="fa-regular fa-circle-exclamation mr-1"></i>PENDENTE REVISÃO
                        </badge>
                      </div>
                      <div v-if="mix_design.situation && mix_design.situation.situation == 0" class="mt-4 col-6">
                        <badge rounded tag="a" href="#" type="danger"><i class="fa-regular fa-thumbs-down mr-1"></i>REPROVADO
                        </badge>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 mt-5 text-right">
                    <base-dropdown menuOnRight>
                      <base-button
                        slot="title-container"
                        type="primary"
                        class="dropdown-toggle p-2 rounded m-0"
                      >
                        <i class="fas fa-cog"></i>
                      </base-button>
                      <!--                      mixDesign.accomplished.volume-->
                      <a v-if="![0,5].includes(contract_proposal.status)" class="dropdown-item"
                         @click.prevent="handleEditFormulation(contract_proposal, mix_design)">
                        <i class="fas fa-edit text-warning"></i>
                        <span class="text-right">Editar</span>
                      </a>
                      <div v-if="![0,5].includes(contract_proposal.status)" class="dropdown-divider p-0 m-0"></div>
                      <router-link v-if="![0,5].includes(contract_proposal.status)" :to="{
                                        name:'commercial.contract-proposal.formulation.new_validity',
                                        path:'/commercial/contract-proposal/formulation/new-validity',
                                        params: { contract_proposal_uuid: contract_proposal.uuid, formulation_uuid: mix_design.formulation_uuid}
                                      }"
                      >
                        <a class="dropdown-item">
                          <i class="fas fa-calendar text-primary"></i>
                          <span class="text-right">Nova Vigência</span>
                        </a>
                      </router-link>
                      <!--                    <div class="dropdown-divider p-0 m-0"></div>-->
                      <!--                    <router-link :to="{-->
                      <!--                      name:'commercial.contract-proposal.formulation.concurrent.view',-->
                      <!--                      path:'/commercial/contract-proposal/formulation/concurrent',-->
                      <!--                      params: {-->
                      <!--                        contract_proposal_formulation_uuid: mix_design.uuid,-->
                      <!--                      formulation_uuid: mix_design.formulation_uuid-->
                      <!--                      }-->
                      <!--                    }">-->

                      <!--                      <a class="dropdown-item">-->
                      <!--                        <i class="fas fa-edit text-indigo"></i>-->
                      <!--                        <span class="text-right">Concorrentes</span>-->
                      <!--                      </a>-->
                      <!--                    </router-link>-->
                      <div class="dropdown-divider p-0 m-0"></div>
                      <a v-if="$hasPermission('additional_permissions.contract-proposal-formulation.details.view')"
                         @click.prevent="handleModalResumeMixe(mix_design.uuid)" class="dropdown-item">
                        <i class="fas fa-file text-info"></i>
                        <span class="text-right">Resumo do Traço</span>
                      </a>
                      <div
                           class="dropdown-divider p-0 m-0"></div>
                      <a  class="dropdown-item"
                         @click.prevent="handleChangePrice(mix_design)">
                        <i class="fa-solid fa-money-bill-transfer text-muted"></i>
                        <span class="text-right" style="margin-left: -8px">Alterar preço</span>
                      </a>
                      <div class="dropdown-divider p-0 m-0"></div>
                      <a class="dropdown-item" @click.prevent="handleChangeConcretePiece(mix_design)">
                        <img slot="reference" src="/img/icons/icons8/puzzle.png"
                             style="height: 18px;margin-right: 12px"/>
                        <span class="text-right" style="margin-left: -8px">Incluir peça a concretar</span>
                      </a>
                      <div class="dropdown-divider p-0 m-0"></div>
                      <a class="dropdown-item" @click.prevent="handleCreateTechnicalData(mix_design.uuid)">
                        <i class="fa-brands fa-react text-primary" style="height: 18px"></i>
                        <span class="text-right" style="margin-left: -8px">Dados técnicos</span>
                      </a>
                    </base-dropdown>
                  </div>
                </div>
                <!-- Card body -->
                <div :class="mix_design.situation && mix_design.situation.situation === 1 ? 'mt-n5' : 'mt-n2'"
                     class="border-left border-3 border-primary p-2 mb-n4">
                  <div class="mt-n2 mb-1">
                    <button type="button"
                            class="btn base-button fs-11 new-btn-light new-default-black-font btn-light btn-sm"
                            style="background: #1b52bad1 !important;color: white !important;border: 1px solid #dcd5d5;margin-top: -5px">
                      V.{{ mix_design.version }}
                    </button>
                    <button v-if="mix_design.mix_design_integration_mixe_id" type="button"
                            class="btn base-button fs-11 new-btn-light new-default-black-font btn-light btn-sm"
                            style="background: #1b52bad1 !important;color: white !important;border: 1px solid #dcd5d5;margin-top: -5px">
                     TR-{{mix_design.mix_design_integration_mixe_id}}
                    </button>
                  </div>
                  <h5 class="h4 font-weight-400">
                    {{ mix_design.plant.name }}
                  </h5>
                  <h5 class="h4 mt-n1">
                    {{ mix_design.title }}
                  </h5>
                  <h5 class="small mt-n2">
                    {{ mix_design.body }}
                    <div class="text-right mt-n4">
                      <el-popover trigger="click" placement="bottom"
                                  class="p-0 float-right pr-0">
                        <div
                          v-for="(concreted_piece, indexConcretedPiece) in mix_design.concretedPiece"
                          :key="indexConcretedPiece"
                        >
                          <div class="d-flex justify-content-between">
                            <p class="h5">
                              {{ concreted_piece.description }}
                            </p>
                          </div>
                        </div>
                        <img class="pointer" slot="reference" src="/img/icons/icons8/puzzle.png" style="height: 25px"/>
                      </el-popover>
                    </div>
                    <div class="text-right mt-n4">
                      <el-popover trigger="click" placement="bottom"
                                  class="p-0 float-right pr-0">
                        <div
                          v-for="(concreted_piece, indexConcretedPiece) in mix_design.concretedPiece"
                          :key="indexConcretedPiece"
                        >
                          <div class="d-flex justify-content-between">
                            <p class="h5">
                              {{ mix_design.thecnical_data }}
                            </p>
                          </div>
                        </div>
                        <i v-if="mix_design.thecnical_data && mix_design.thecnical_data.length" slot="reference" class="pointer fa-brands fa-react text-primary" style="font-size: 25px;"></i>
                      </el-popover>
                    </div>
                  </h5>
                  <h5 class="small mt-n1">
                    {{ mix_design.footer }}
                  </h5>
                  <h5>
                    <div v-if="mix_design.puzl_additions.length"
                         v-for="(puzl_addtion, index) in mix_design.puzl_additions"
                         :key="puzl_addtion.id"><h5 class="text-indigo">
                      {{ puzl_addtion.name }}
                      ({{ puzl_addtion.volume }} kg/<span style="text-transform: none">m</span><sup>3</sup>)
                      <span class="text-indigo" v-if="index != mix_design.puzl_additions.length -1">+</span>
                    </h5></div>
                  </h5>
                </div>
                <div class="mt-4 p-2 border-left border-3 mb-n4"
                     :class="mix_design.value > mix_design.minimum_price ? 'border-success' : 'border-danger'">
                  <div class="row">
                    <div class="col-12 mt-n3 mb-n2">
                      <div class="pt-2">
                        <div class="h3 mt-n1" style="text-transform: none">
                          {{ toMoney(mix_design.value) }} /m³ <small>({{ deliveryTypes[mix_design.delivery_type] }})</small>
                          <div
                            v-if="$hasPermission('additional_permissions.contract-proposal-formulation.details.view')"
                            class="text-right mt-n4">
                            <el-popover v-if="mix_design.mix_design_id" trigger="click" placement="bottom"
                                        class="p-0 float-right pr-0">
                              <h5 class="text-dark ">
                                Preço mínimo atual:
                              </h5>
                              <h4 class="text-default" v-if="mix_design.minimum_price_atual">
                                           <span class="">
                                            {{ toMoney(mix_design.minimum_price_atual) }} /<span
                                             style="text-transform: none">m</span><sup>3</sup>
                                           </span>
                                <span class="small "
                                      :class="mix_design.minimum_price_atual_pct > 0 ? 'text-success' : 'text-danger'">
                                             ({{
                                    toMoney(mix_design.minimum_price_atual_pct)
                                  }})
                                           </span>
                              </h4>
                              <h4 v-else class="text-muted">
                                Traço não encontrado
                              </h4>
                              <h4 class="text-left mt-n2">
                                {{
                                  calculateGrossMargin(mix_design.value, mix_design.mcc_atual, unformatTax(mix_design.concrete_price.tax_name)) | currency()
                                }}
                                <small> (Margem c/imposto)</small>
                              </h4>
                              <h4 class="text-left mt-n2" :class="validAtualPrice(mix_design)">
                                {{
                                  mix_design.value - mix_design.mcc_atual - mix_design.puzl_addition_price_without_addition | currency
                                }} <small>/ m³ (Margem bruta)</small></h4>
                              <h4 class="text-left text-primary mt-n2" :class="validAtualPriceByHour(mix_design)">
                                {{ calcServiceHour(mix_design, mix_design.mcc_atual) | currency }} <small>/ hora
                                (Margem
                                atual)</small></h4>
                              <hr v-if="mix_design.feature_level_selected" class="mb-2 mt-1">
                              <base-input input-group-classes="input-group-sm">
                                <puzl-select :clearable="false"
                                             @change="changeFeatureLevel(mix_design)"
                                             :loading="loadingFeatureLevels"
                                             v-model="mix_design.feature_level" :items="getFeatureLevels(mix_design.level_features)"
                                             customKey="value" label="label"/>
                              </base-input>
                              <img v-if="mix_design.value > mix_design.minimum_price" class="pointer"
                                   style="height: 28px" slot="reference"
                                   src="https://img.icons8.com/ios/50/149e57/cash-in-hand.png"/>
                              <img v-else class="pointer" style="height: 28px" slot="reference"
                                   src="https://img.icons8.com/ios/50/db4539/cash-in-hand.png"/>
                            </el-popover>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-3 border-left border-3 border-grey pl-2 pt-1"><h4 class="text-dark">
                 <span>
                   {{
                     parseFloat(mix_design.expected_volume).toLocaleString('pt-BR', {minimumFractionDigits: 1})
                   }}
                  <span style="text-transform: none;">m</span><sup>3</sup></span> |
                  <span> {{
                      parseFloat(mix_design.value * mix_design.expected_volume) | currency()
                    }} </span><small>(PREVISTO)</small></h4>
                </div>
                <div class="row pl-2" v-if="!mix_design.mix_design_id">
                  <div class="col-md-12 pl-1">
                    <h4>
                      <img
                        style="width: 25px"
                        src="https://img.icons8.com/pastel-glyph/100/cc3c3c/error--v1.png"/>
                      <small class="text-danger font-weight-500">Traço não encontrado</small></h4>
                  </div>
                </div>

                <!--              <div v-if="$hasPermission('additional_permissions.contract-proposal-formulation.details.view')"-->
                <!--                   class="mt-3 border-left border-3 p-2"-->
                <!--                   :class="mix_design.minimum_price_atual_pct > 0 ? 'border-success' : 'border-danger'">-->

                <!--              </div>-->
                <div class="mt-3">
                  <div class="dropdown-divider p-0 m-0 pb-2"></div>
                  <h5 class="mb-0" v-if="mix_design.observation">
                    {{ mix_design.observation }}
                  </h5>
                  <h5 class="text-dark small mb-2 font-weight-400">
                    {{ mix_design.user }} - {{ mix_design.created_at | parseDate }}
                  </h5>
                  <br>
                </div>
                <div class="d-flex justify-content-between mt-n4">
                  <base-button
                    block
                    size="sm"
                    type="warning"
                    class="mt-2"
                    @click.prevent="handleDetailCycle(mix_design)"
                  >
                    <i class="fa fa-clock text-white"></i>
                  </base-button>

                  <base-button
                    block
                    size="sm"
                    type="primary"
                    @click.prevent="handleDetailHistory(mix_design)"
                  >
                    <i class="fa fa-list text-white"></i>
                  </base-button>

                  <base-button
                    v-if="![0,5].includes(contract_proposal.status)"
                    block
                    size="sm"
                    type="danger"
                    @click.prevent="handleRemoveFormulation(mix_design.formulation_uuid, indexMixDesign)"
                  >
                    <i class="fa fa-trash text-white"></i>
                  </base-button>
                </div>

                <!-- Collapse Aprovatuion -->
                <collapse class="border rounded p-0 mt-3" v-if="mix_design.futures.length">
                  <collapse-item name="1" class="header-gray"
                                 back-ground="border-header-primary-bottom">
                    <h5 slot="title" class="mb-0 ">Vigência futura</h5>
                    <template v-for="(future, indexFuture) in mix_design.futures">
                      <hr v-if="indexFuture != 0 && mix_design.futures.length > 1">
                      <div :key="indexFuture" class="border-left border-3 p-2 border-primary">
                        <div class="row">
                          <div class="col-12">
                            <badge type="primary">Vigência em {{ future.date_at | parseDate('DD MMM YYYY') }}
                            </badge>
                            <h4 class="mt-2">
                              {{ future.plant.name }}
                            </h4>
                            <h4 class="text-default">
                              {{ toMoney(future.value) }} /<span style="text-transform: none">m</span><sup>3</sup>
                              (venda)
                            </h4>
                            <h5 class="text-default small">
                              {{ toMoney(future.minimum_price) }} /<span
                              style="text-transform: none">m</span><sup>3</sup>
                              (mínimo)
                            </h5>
                          </div>
                        </div>

                        <div class="mt-3">
                          <div class="dropdown-divider p-0 m-0 pb-2"></div>
                          <h5 v-if="future.observation">
                            Observação: {{ future.observation }}
                          </h5>
                          <h4 class="small mb-5">{{ future.user }} <br> {{ future.created_at | parseDate }}</h4>
                          <div class="dropdown-divider p-0 m-0"></div>
                        </div>

                        <!-- Start Action for History -->
                        <div class="d-flex justify-content-between">
                          <base-button
                            block
                            size="sm"
                            type="warning"
                            class="mt-2"
                            @click.prevent="handleDetailCycle(future)"
                          >
                            <i class="fa fa-clock text-white"></i>
                          </base-button>

                          <base-button
                            block
                            size="sm"
                            type="primary"
                            @click.prevent="handleDetailHistory(future, false)"
                          >
                            <i class="fa fa-list text-white"></i>
                          </base-button>

                          <base-button
                            v-if="![0,5].includes(contract_proposal.status)"
                            block
                            size="sm"
                            type="danger"
                            @click.prevent="handleRemoveFormulationValidity(future.uuid, indexMixDesign, indexFuture)"
                          >
                            <i class="fa fa-trash text-white"></i>
                          </base-button>
                        </div>
                      </div>
                    </template>

                  </collapse-item>
                </collapse>

                <!-- Collapse Aprovation -->
                <collapse v-if="hasPermissionApprovation()"
                          class="border rounded p-0 mt-3">
                  <collapse-item name="1" class="header-gray"
                                 back-ground="border-header-success-bottom">
                    <h5 slot="title" class="mb-0 ">
                      Aprovações</h5>
                    <div class="">
                      <base-button
                        block
                        size="sm"
                        outline
                        @click.prevent="handleShowModalSituation(mix_design, 1)"
                        :class="mix_design.situation && mix_design.situation.situation === 1 && 'active'"
                        type="success"
                        class="mt-2 text-uppercase"
                      >
                        <i class="fa-regular fa-circle-check"></i>
                        {{ mix_design.situation && mix_design.situation.situation === 1 ? 'Aprovada' : 'Aprovar' }}
                      </base-button>
                      <base-button
                        block
                        size="sm"
                        @click.prevent="handleShowModalSituation(mix_design, 0)"
                        :class="mix_design.situation && mix_design.situation.situation === 0 && 'active'"
                        outline
                        class="text-uppercase"
                        type="danger"
                      >
                        <i class="fa-regular fa-thumbs-down"></i>
                        {{ mix_design.situation && mix_design.situation.situation === 0 ? 'Reprovada' : 'Reprovar' }}
                      </base-button>
                      <base-button
                        block
                        size="sm"
                        @click.prevent="handleShowModalSituation(mix_design, 2)"
                        :class="mix_design.situation && mix_design.situation.situation === 2 && 'active'"
                        outline
                        class="text-uppercase"
                        type="warning"
                      >
                        <i class="fa-solid fa-circle-exclamation"></i>
                        Revisar
                      </base-button>
                    </div>
                    <div class="col-12 mt-3 p-0" v-if="mix_design.situation">
                      <h5 class="mb-0 ">{{ mix_design.situation.observation }}</h5>
                      <div class="row">
                        <div class="col-12 ">
                          <h5 style="font-size: 0.7rem" class="   font-weight-400"> {{mix_design.situation.user && mix_design.situation.user.name }}
                            - {{ mix_design.situation.created_at | parseDate }} </h5>
                        </div>
                        <br>
                        <div class="col-12">
                          <base-button
                            outline
                            block
                            class="btn-sm"
                            @click.prevent="handleShowModalSituationHistories(mix_design.formulation_uuid)">
                            <i class="fas fa-history  main-icon-menu"></i>
                            Histórico
                          </base-button>
                        </div>
                      </div>
                    </div>
                  </collapse-item>
                </collapse>
                <collapse class="border rounded p-0 mt-3" v-if="mix_design.histories.length">
                  <collapse-item name="1" class="header-gray"
                                 back-ground="border-header-default-bottom">
                    <h5 slot="title" class="mb-0">Histórico</h5>
                    <template v-for="(historic, indexHistoric) in mix_design.histories">
                      <hr v-if="indexHistoric != 0 && mix_design.histories.length > 1">
                      <div :key="indexHistoric" class="border-left border-3 p-2 border-indigo">
                        <div class="row">
                          <div class="col-12">
                            <h4 class="">
                              {{ historic.plant.name }}
                            </h4>
                            <h4 class="text-primary">
                              {{ toMoney(historic.value) }} /<span style="text-transform: none">m</span><sup>3</sup>
                              (venda)
                            </h4>
                            <h4 class="text-default small">
                              {{ toMoney(historic.minimum_price) }} /<span
                              style="text-transform: none">m</span><sup>3</sup> (mínimo)
                            </h4>
                          </div>
                        </div>

                        <div class="mt-3">
                          <div class="dropdown-divider p-0 m-0 pb-2"></div>
                          <h5 v-if="historic.observation">
                            Observação: {{ historic.observation }}
                          </h5>
                          <h4 class="small mb-5">{{ historic.user }} <br> {{ historic.created_at | parseDate }}
                          </h4>
                          <div class="dropdown-divider p-0 m-0"></div>
                        </div>
                        <!-- Start Action for History -->
                        <div class="d-flex justify-content-between">
                          <base-button
                            block
                            size="sm"
                            type="warning"
                            class="mt-2"
                            @click.prevent="handleDetailCycle(historic)"
                          >
                            <i class="fa fa-clock text-white"></i>
                          </base-button>

                          <base-button
                            block
                            size="sm"
                            type="primary"
                            @click.prevent="handleDetailHistory(historic)"
                          >
                            <i class="fa fa-list text-white"></i>
                          </base-button>
                          <base-button
                            outline
                            block
                            class="btn-sm"
                            @click.prevent="handleShowModalSituationHistories(historic.formulation_uuid)">
                            <i class="fas fa-history"></i>
                          </base-button>
                        </div>
                      </div>
                    </template>
                  </collapse-item>
                </collapse>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="row" v-if="!loading && !contract_proposal.mix_designs.length">
        <div class="col-md-12">
          <PuzlEmptyData/>
        </div>
      </div>
      <!-- End Card ContractProposal -->
    </div>
    <ModalDetailHistory ref="detailHistory"/>
    <ModalDetailCycle ref="ModalDetailCycle"/>
    <ModalSituation @updateSituation="handleUpdateSituation" ref="ModalSituation"/>
    <ModalSituationHistories ref="ModalSituationHistories"/>
    <ModalResumeMixe ref="modalResumeMixe"/>
    <modal-update-price ref="ModalUpdatePrice"/>
    <float-button :contract_proposal="contract_proposal"/>
    <modal-add-new-piece @fetch="updateFormulation" ref="ModalAddNewPiece"/>
    <modalCreateThecnicalData @updateThecnicalData="init" ref="modalCreateThecnicalData"/>
  </div>
</template>
<script>
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid"
import {mapGetters} from 'vuex'
import {
  period_types
} from "@/views/Modules/Technology/Mix/helpers/mixe";
import ModalDetailCycle from './_ModalDetailCycle'
import ModalDetailHistory from './_ModalDetailHistory'
import ModalSituation from './_ModalSituation'
import ModalResumeMixe from './_ModalResumeMixe.vue';
import ModalSituationHistories from './_ModalSituationHistories'
import BackButton from "@/components/Utils/BackButton";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import MultiFilter from "@/components/Utils/MultiFilterV2";
import ModalUpdatePrice from './_ModalUpdatePrice'
import FloatButton from "../../components/FloatButton/Index.vue";
import CreateNew from "../../components/FloatButton/CreateNew/Index.vue";
import ModalAddNewPiece from './_ModalAddNewPiece.vue';
import ContractProposalNavigation from "@/components/ContractProposalNavigation";
import {ContractProposalEnum} from "@/enum/ContractProposalEnum";
import ModalCreateThecnicalData from './_ModalCreateThecnicalData.vue'
import PuzlSelect from "@/components/PuzlSelect.vue";
export default {
  name: 'ListContractProposalFormulation',
  components: {
    PuzlSelect,
    ModalUpdatePrice,
    MultiFilter,
    PuzlEmptyData,
    SkeletonPuzl,
    BackButton,
    ModalDetailHistory,
    SkeletonPuzlGrid,
    ModalDetailCycle,
    ModalSituation,
    ModalSituationHistories,
    ModalResumeMixe,
    FloatButton,
    CreateNew,
    ModalAddNewPiece,
    ContractProposalNavigation,
    ModalCreateThecnicalData,
  },
  data() {
    return {
      format_mixe: period_types,
      loadingFeatureLevels: false,
      loading: false,
      filter: {
        show_inactive: 0
      },
      ContractProposalEnum: ContractProposalEnum,
      deliveryTypes: {
        1: 'Venda',
        2: 'Doação',
        3: 'Transferência',
        4: 'Uso interno',
        5: 'Reparo de concretagem',
      }

    }
  },
  computed: {
    ...mapGetters({
      $_commercial_permission: "commercialPermission/show",
      'contract_proposal': 'formulations/show',
      $_feature_levels: 'mixConcretePiece/feature_levels',
    }),
  },
  methods: {
    handleCreateTechnicalData(formulation_uuid) {
      this.$refs.modalCreateThecnicalData.openModal(formulation_uuid)
    },
    fetchCommercialPermissions() {
      this.$store.dispatch('commercialPermission/getByUserId', this.$user.id);
    },
    calculateGrossMargin: function (minimumPrice, mcc, tax) {
      const price = minimumPrice
      const cmc = mcc
      if (price > 0) {
        if (Number(tax) > 0) {
          let tax_value = price * (tax / 100)
          return price - tax_value - cmc
        } else {
          return price - cmc
        }
      }
      return 0
    },
    hasPermissionApprovation() {
      if (!this.$_commercial_permission.minimum_price_approval) {
        return false
      }
      return true
    },
    /**
     * Atualiza a formulação após alteração
     * @param params
     */
    updateFormulation(params) {
      let index = this.contract_proposal.mix_designs.findIndex((item) => item.uuid === params.uuid)
      this.contract_proposal.mix_designs[index].concretedPiece = params.contract_proposal_formulation.concretedPiece
    },
    handleFilterActive() {
      this.filter.show_inactive = Number(!this.filter.show_inactive)
      this.init()
    },
    async init() {
      await this.fetchCommercialPermissions()
      this.$Progress.start();
      this.loading = true
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch('formulations/fetchItem', {filter: this.filter, uuid: this.$route.params.contract_proposal_uuid}).then(response => {
        this.loading = false
        this.$Progress.finish();
        this.$notify({
          type: response.error_type,
          message: response.message
        });
      })
    },
    handleEditFormulation(contractProposal, mixDesign) {
      if (mixDesign.accomplished.travel_exists) {
        this.$router.push({
          name: 'commercial.contract-proposal.formulation.new_validity',
          params: {contract_proposal_uuid: contractProposal.uuid, formulation_uuid: mixDesign.formulation_uuid}
        })
      } else {
        this.$router.push({
          name: 'commercial.contract-proposal.formulation.edit',
          params: {contract_proposal_uuid: contractProposal.uuid, formulation_uuid: mixDesign.formulation_uuid}
        })
      }
    },
    handleModalResumeMixe(id) {
      this.$refs.modalResumeMixe.openModal(id)
    },
    unformatTax(tax) {
      const regex = /\d+\.?\d*/;
      let match = tax.match(regex)
       return match?.[0] || 0
    },
    handleShowModalSituationHistories(uuid) {
      this.$Progress.start();
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      });
      this.$store.dispatch('formulations/showSituation', uuid).then(response => {
        this.$Progress.finish();
        this.$notify({
          type: response.error_type,
          message: response.message
        })
        this.$refs.ModalSituationHistories.openModal()
      })
    },
    toMoney(value) {
      value = Number(value)
      value = parseFloat(value)
      value = value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
      return value
    },
    handleUpdateSituation(situation) {
      if (!this.contract_proposal) {
        return
      }
      let index = this.contract_proposal.mix_designs.findIndex((item) => item.formulation_uuid === situation.uuid)
      this.contract_proposal.mix_designs[index].situation = situation.situation
    },
    handleDetailHistory(mix_design, showMostDetails = true) {
      this.$refs.detailHistory.openModal(mix_design, showMostDetails)
    },
    handleChangePrice(formulation) {
      if (! this.$_commercial_permission.change_sale_price_of_delivered_trip) {
        return this.$swal.fire({
          icon: 'error',
          title: 'Aviso',
          text: 'Usuário não possui permissão para alterar o preço',
        })
      }
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      let loader = this.$loading.show()
      this.$store.dispatch('formulations/getFormulationBilled', formulation.uuid).then((response) => {
        this.$refs.ModalUpdatePrice.openModal(formulation)
        this.$notify({type: 'success', message: response.message});
        loader.hide()
      }).catch(error => {
        loader.hide()
        this.$notify({
          type: 'warning',
          message: error.data.message
        });
      })
    },
    /**
     * Abre o modal de alteração de peça a concretar
     * @param formulation
     */
    handleChangeConcretePiece(formulation) {
      this.$refs.ModalAddNewPiece.openModal(this.contract_proposal.uuid, formulation.uuid)
    },
    handleShowModalSituation(mix_design, type) {
      let params = {
        uuid: mix_design.formulation_uuid,
        price: mix_design.value,
        minimum_price: mix_design.minimum_price,
        type: type,
        expected_volume: mix_design.expected_volume
      }
      let type_situation = ''
      let description = {
        name: '',
        type: ''
      }
      if (this.$_commercial_permission.limit_variation && type == 1) {
        let margin = mix_design.minimum_price_atual - mix_design.mcc_atual - mix_design.concrete_price.tax_price
        let monetary_sell = 0
        let monetary_percentage = 0
        let bigger_monetary = 0

        let monetary_margin = 0
        let monetary_margin_percentage = 0
        let bigger_margin = 0

        if (Number(this.$_commercial_permission.margin_sell_limit) > 0 || Number(this.$_commercial_permission.margin_sell_limit_percentage) > 0) {
          if (Number(this.$_commercial_permission.margin_sell_limit) > 0) {
            monetary_sell = mix_design.minimum_price - this.$_commercial_permission.margin_sell_limit
          }
          if (Number(this.$_commercial_permission.margin_sell_limit_percentage) > 0) {
            monetary_percentage = mix_design.minimum_price * (1 - (this.$_commercial_permission.margin_sell_limit_percentage / 100))
          }
          bigger_monetary = Math.max(monetary_sell, monetary_percentage)
        }

        let gross_margin = this.calculateGrossMargin(mix_design.value, mix_design.mcc_atual, this.unformatTax(mix_design.concrete_price.tax_name))

        if (Number(this.$_commercial_permission.margin_machining_limit) > 0 || Number(this.$_commercial_permission.margin_machining_limit_percentage) > 0) {
          if (Number(this.$_commercial_permission.margin_machining_limit) > 0) {
            monetary_margin = margin - this.$_commercial_permission.margin_machining_limit
          }
          if (Number(this.$_commercial_permission.margin_machining_limit_percentage) > 0) {
            monetary_margin_percentage = margin * (1 - (this.$_commercial_permission.margin_machining_limit_percentage / 100))
          }
          bigger_margin = Math.max(monetary_margin, monetary_margin_percentage)
        }
        if (Number(mix_design.value) < Number(bigger_monetary)) {
          return this.$notify({
            type: 'danger',
            message: `Este usuário não possui limite de desconto suficiente para realizar essa aprovação.`
          });
        }
        if (bigger_margin > 0 && gross_margin < bigger_margin) {
          return this.$notify({
            type: 'danger',
            message: `Este usuário não possui limite de desconto suficiente para realizar essa aprovação.`
          });
        }
      }
      switch (params.type) {
        case 1:
          type_situation = 'aprovada'
          description = {
            name: 'Aprovar',
            type: 'success'
          }
          break;
        case 0:
          type_situation = 'reprovada'
          description = {
            name: 'Reprovar',
            type: 'danger'
          }
          break;
        case 2:
          type_situation = 'em revisão'
          description = {
            name: 'Revisar',
            type: 'warning'
          }
          break;
      }
      if (mix_design.situation && mix_design.situation.situation == type) {
        return this.$notify({
          type: 'warning',
          message: `Situação da proposta já definida como ${type_situation}`
        });
      }
      this.$refs.ModalSituation.openModal(params, description)
    },
    handleSetStatus(uuid, status, index) {
      this.$Progress.start();
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      });
      let params = {
        uuid: uuid,
        status: status,
      }
      this.$store.dispatch('formulations/setStatus', params).then(response => {
        this.$Progress.finish();
        this.$notify({
          type: response.error_type,
          message: response.message
        })
        let formulation = this.$store.state.formulations.item.mix_designs[index]
        formulation.status = formulation.mix_design_mixe_status === 5 && status === 1 ? 5 : status === 0 ? 0 : 1
      })
    },
    handleDetailCycle(mix_design) {
      let cycle = mix_design.cycle
      cycle.plant = mix_design.plant.name
      cycle.user = mix_design.user
      this.$refs.ModalDetailCycle.openModal(cycle)
    },
    handleRemoveFormulationValidity(uuid, index, indexFuture) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$notify({
            type: 'info',
            message: 'Estamos trabalhando em sua solicitação.'
          });
          this.$store.dispatch('formulations/destroy', uuid).then(response => {
            this.$Progress.finish();
            this.$notify({
              type: response.error_type,
              message: response.message
            })
            this.$store.state.formulations.item.mix_designs[index].futures.splice(indexFuture, 1)
          })
        }
      }).catch(() => this.$Progress.finish())
    },
    calcServiceHour(mixDesign, mcc) {
      const billing = mixDesign.value - mcc - mixDesign.puzl_addition_price_without_addition
      const service = billing * mixDesign.cycle.average_volume_per_bt
      const cycle = mixDesign.cycle.total_cycle / 60
      return service / cycle
    },
    validAtualPrice(mixDesign) {
      if (mixDesign.value - mixDesign.mcc_atual > mixDesign.value - mixDesign.concrete_price.mcc_price) {
        return 'text-success'
      }
      if (mixDesign.value - mixDesign.mcc_atual < mixDesign.minimum_price_atual_pct) {
        return 'text-danger'
      }
      return 'text-default'
    },
    changeFeatureLevel(mix_design) {
      this.$Progress.start()
      this.$Swal.confirmAction('Deseja realmente alterar o nível de aplicação?', ['Sim', 'Não'])
        .then((result) => {
          if (result.isConfirmed) {
            let loader = this.$loading.show()
            this.$store.dispatch('formulations/changeFeatureLevel', {
              formulation_uuid: mix_design.uuid,
              feature_level: mix_design.feature_level
            }).then(response => {
              this.$Progress.finish()
              loader.hide()
              this.$notify({
                type: response.error_type,
                message: response.message
              })
              this.init()
            }).catch(error => {
              loader.hide()
              this.$Progress.finish()
            })
          }
          else {
            mix_design.feature_level = mix_design.old_feature_level
          }
        }).catch(() => this.$Progress.finish())
    },
    getFeatureLevels(level_features) {
      return this.$_feature_levels.filter((item) => level_features.includes(item.value))
    },
    validAtualPriceByHour(mixDesign) {
      const atual = this.calcServiceHour(mixDesign, mixDesign.mcc_atual);
      const defined = this.calcServiceHour(mixDesign, mixDesign.concrete_price.mcc_price)

      if (atual > defined) {
        return 'text-sucess'
      }
      if (atual < defined) {
        return 'text-danger'
      }
      return 'text-default'
    },
    handleRemoveFormulation(uuid, index) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$notify({
            type: 'info',
            message: 'Estamos trabalhando em sua solicitação.'
          });
          this.$store.dispatch('formulations/destroy', uuid).then(response => {
            this.$Progress.finish();
            this.$notify({
              type: response.error_type,
              message: response.message
            })
            if (this.contract_proposal.mix_designs[index].histories) {
              this.init()
            } else {
              this.$store.state.formulations.item.mix_designs.splice(index, 1)
            }
          }).catch(error => {
            this.$notify({
              type: "danger",
              message: error.data.message
            });
            this.$Progress.finish()
          });
        }
      }).catch(() => this.$Progress.finish())
    },
  },
  mounted() {
    this.loadingFeatureLevels = true;
    this.$store.dispatch('mixConcretePiece/getFeatureLevels').then(() => {
      this.loadingFeatureLevels = false;
    });
    this.init()
  }
}
</script>
<style>
.border-3 {
  border-width: 4px !important;
}

.main-menu {
  flex-wrap: nowrap !important;
  display: flex;
  justify-content: space-between;
}

.rounded-circle {
  border: 1px solid #D3D3D3;
}

.background-color-circle {
  background-color: #f3f4fa;
}

.icon:hover {
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
  transform: scale(1.1);
  background-color: #D3D3D3;
}

.border-black {
  border-color: black !important;
}

@media only screen and (max-width: 991px) {
  .base-button {
    font-size: 6px;
  }
}

.button-border-left {
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.button-border-right {
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.btn-left-none {
  border-left: 0;
}

.btn-left-none:hover {
  transform: none !important;
}

.base-button:hover .invert-on-hover {
  filter: brightness(0) invert(1);
}

.base-button:hover .text-dark {
  color: white !important;
}
</style>
