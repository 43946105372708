<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row pt-0" style="margin-top: -0.5rem">
        <div class="col-md-12 p-1 card-row">
          <multi-filter-v2
            ref="multi-filter"
            @clearFilters="clearFilters"
            @fetch="getFilterStatus"
            :filter="filter"
            :status="multi_filter"
          >
            <div class="col-md-3 p-2">
              <input-date-picker :filterable="4" size="small" ref="date-picker" @handleFilterDate="handleFilterDate" :default-range="defaultRange" />
            </div>
            <div class="col-md-3 p-2 custom-dashboard">
              <puzl-select
                class="select-lg"
                v-model="selectedBanksAccount"
                :items="$_bank_accounts"
                :multiple="true"
                placeholder="Conta Bancária de Crédito"
                @input="handleFilterBankAccounts"
                :loading="loadingBankAccount"
                :disabled="loadingBankAccount" />
            </div>
            <div class="col-md-3 p-2 custom-dashboard">
              <puzl-select
                v-model="selectedCompanyPlants"
                :items="$_plants"
                customKey="id"
                :multiple="true"
                class="select-lg"
                @input="handleFilterCompanyPlants"
                :loading="loadingCompanyPlants"
                :disabled="loadingCompanyPlants"
                placeholder="Central"
              />
            </div>
            <div class="col-md-3 p-2 custom-dashboard">
              <puzl-select
                v-model="selectedSellers"
                :items="$_sellers"
                customKey="uuid"
                :multiple="true"
                placeholder="Vendedor"
                @input="handleFilterSellers"
                :loading="loadingSellers"
                :disabled="loadingSellers"
              />
            </div>
            <div slot="after">
              <div class="row">
                <div class="col-md-2 p-2">
                  <base-button :class="grossValue && 'active'" @click="setGrossValue()" outline block type="primary">
                    Valor bruto
                  </base-button>
                </div>
                <div class="col-md-8">
                  <div v-if="loadingTotalBillReceive && loadingTotalBillPay">
                    <div class="row">
                      <div class="col-md-4 p-2">
                          <base-button disabled block type="success">
                              <i class="fa-solid fa-dollar-sign"></i> Contas a receber
                          </base-button>
                      </div>
                      <div class="col-md-4 p-2">
                          <base-button disabled block type="danger">
                              <i class="fa-solid fa-dollar-sign"></i> Contas a pagar
                          </base-button>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="row">
                      <div class="col-md-4 p-2">
                          <base-button :class="listBillReceive && 'active'"
                              @click="getBillReceive()" outline block type="success">
                              <i class="fa-solid fa-dollar-sign"></i> Contas a receber
                          </base-button>
                      </div>
                      <div class="col-md-4 p-2">
                          <base-button :class="listBillPay && 'active'"
                              @click="getBillPay()" outline block type="danger">
                              <i class="fa-solid fa-dollar-sign"></i> Contas a pagar
                          </base-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </multi-filter-v2>
        </div>
      </div>
      <div class="row align-items-center card-row">
        <div class="widgets-sm col-md-3 col-sm-6 p-1">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-8" style="margin-top: -10px">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold ml-n3 ml-n3">
                    <small class="font-weight-bold">Total</small>
                  </p>
                </div>
                <div class="col-4 pr-1 pr-1" style="margin-top: -5px">
                  <div class="text-center icon icon-shape float-right mr-n2 mr-n2">
                    <i class="fa-solid fa-dollar-sign text-success" style="font-size: 28px;"></i>
                  </div>
                </div>
                <div class="col-md-12" style="margin-top: -25px">
                  <h3 class="font-weight-semibold mb-0 ml-n3" style="font-family: 'Gill Sans', sans-serif;">
                    <div v-if="loadingTotalBillReceive || loadingTotalBillPay"><SpinnerPuzl/></div>
                    <div v-else>
                      <div v-if="listBillReceive">
                        <div v-if="totalsBillReceive">
                          <div v-if="grossValue" class="numeric">
                            {{ totalsBillReceive['total'] | currency }}
                          </div>
                          <div v-else class="numeric">
                            {{ totalsBillReceive['final_total'] | currency }}
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div v-if="totalsBillPay">
                          <div v-if="grossValue" class="numeric">
                            {{ totalsBillPay['total'] | currency }}
                          </div>
                          <div v-else class="numeric">
                            {{ totalsBillPay['final_total'] | currency }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets-sm col-md-3 col-sm-6 p-1">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-8" style="margin-top: -10px">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold">
                    <div v-if="listBillReceive">
                      <small class="font-weight-bold ml-n3" style="font-size: 11px;">Clientes</small>
                    </div>
                    <div v-else>
                      <small class="font-weight-bold ml-n3" style="font-size: 11px;">Fornecedor</small>
                    </div>
                  </p>
                </div>
                <div class="col-4 pr-1" style="margin-top: -5px">
                  <div class="text-center shadow icon icon-shape float-right mr-n2 mr-n2">
                    <i class="fa-solid fa-user text-primary" style="font-size: 28px;"></i>
                  </div>
                </div>
                <div class="col-md-12" style="margin-top: -25px">
                  <h3 class="font-weight-semibold mb-0" style="font-family: 'Gill Sans', sans-serif;">
                    <div v-if="loadingTotalBillReceive || loadingTotalBillPay"><SpinnerPuzl/></div>
                    <div v-else>
                      <div v-if="listBillReceive">
                        <div class="numeric">
                          {{ totalsBillReceive['total_customer'] }}
                        </div>
                      </div>
                      <div v-else>
                        <div class="numeric">
                          {{ totalsBillPay['total_entity'] }}
                        </div>
                      </div>
                    </div>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets-sm col-md-3 col-sm-6 p-1">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-8" style="margin-top: -10px">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold ml-n3">
                      <small class="font-weight-bold">Média atrasos</small></p>
                </div>
                <div class="col-4 pr-1" style="margin-top: -5px">
                  <div class="text-center shadow icon icon-shape float-right mr-n2">
                    <i class="fa-solid fa-calendar-days" style="font-size: 28px;"></i>
                  </div>
                </div>
                <div class="col-md-12 " style="margin-top: -25px">
                  <h3 class="font-weight-semibold mb-0" style="font-family: 'Gill Sans', sans-serif;">
                    <div v-if="loadingTotalBillReceive || loadingTotalBillPay"><SpinnerPuzl/></div>
                    <div v-else>
                      <div v-if="listBillReceive">
                        <div v-if="totalsBillReceive['average_overdue']" class="numeric">
                          {{ parseFloat(totalsBillReceive['average_overdue']).toFixed(0) }}
                        </div>
                      </div>
                      <div v-else>
                        <div v-if="totalsBillPay['average_overdue']" class="numeric">
                          {{ parseFloat(totalsBillPay['average_overdue']).toFixed(0) }}
                        </div>
                      </div>
                    </div>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets-sm col-md-3 col-sm-6 p-1">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-8" style="margin-top: -10px">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold">
                    <div v-if="listBillReceive">
                      <small class="font-weight-bold ml-n3" style="font-size: 11px;">Recebido</small>
                    </div>
                    <div v-else>
                      <small class="font-weight-bold ml-n3" style="font-size: 11px;">Pagar</small>
                    </div>
                  </p>
                </div>
                <div class="col-4 pr-1" style="margin-top: -5px">
                  <div class="text-center shadow icon icon-shape float-right mr-n2">
                    <i class="fa-regular fa-square-check text-success" style="font-size: 28px;"></i>
                  </div>
                </div>
                <div class="col-md-12" style="margin-top: -25px">
                  <h3 class="font-weight-semibold mb-0 ml-n3" style="font-family: 'Gill Sans', sans-serif;">
                    <div v-if="loadingTotalBillReceive || loadingTotalBillPay"><SpinnerPuzl/></div>
                    <div v-else>
                      <div v-if="listBillReceive">
                        <div v-if="totalsBillReceive">
                          <div v-if="grossValue" class="numeric">
                            {{ totalsBillReceive['total_paid'] | currency }}
                          </div>
                          <div v-else class="numeric">
                            {{ totalsBillReceive['final_total_paid'] | currency }}
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div v-if="totalsBillPay">
                          <div v-if="grossValue" class="numeric">
                            {{ totalsBillPay['total_paid'] | currency }}
                          </div>
                          <div v-else class="numeric">
                            {{ totalsBillPay['final_total_paid'] | currency }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets-sm col-md-3 col-sm-6 p-1 card-row">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-8" style="margin-top: -10px">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold ml-n3"><small class="font-weight-bold">
                    Atrasado</small></p>
                </div>
                <div class="col-4 pr-1" style="margin-top: -5px">
                  <div class="text-center shadow icon icon-shape float-right mr-n2">
                    <i class="fa-regular fa-circle-xmark text-danger" style="font-size: 28px;"></i>
                  </div>
                </div>
                <div class="col-md-12 " style="margin-top: -25px">
                  <h3 class="font-weight-semibold mb-0 ml-n3" style="font-family: 'Gill Sans', sans-serif;">
                    <div v-if="loadingTotalBillReceive || loadingTotalBillPay"><SpinnerPuzl/></div>
                    <div v-else>
                      <div v-if="listBillReceive">
                        <div v-if="totalsBillReceive">
                          <div v-if="grossValue" class="numeric">
                            {{ totalsBillReceive['total_overdue'] | currency }}
                          </div>
                          <div v-else class="numeric">
                            {{ totalsBillReceive['final_total_overdue'] | currency }}
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div v-if="totalsBillPay">
                          <div v-if="grossValue" class="numeric">
                            {{ totalsBillPay['total_overdue'] | currency }}
                          </div>
                          <div v-else class="numeric">
                            {{ totalsBillPay['final_total_overdue'] | currency }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets-sm col-md-3 col-sm-6 p-1 card-row">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-8" style="margin-top: -10px">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold ml-n3"><small class="font-weight-bold">
                    Pendente</small></p>
                </div>
                <div class="col-4 pr-1" style="margin-top: -5px">
                  <div class="text-center shadow icon icon-shape float-right mr-n2">
                    <i class="fa-solid fa-ellipsis text-warning" style="font-size: 28px;"></i>
                  </div>
                </div>
                <div class="col-md-12" style="margin-top: -25px">
                  <h3 class="font-weight-semibold mb-0 ml-n3" style="font-family: 'Gill Sans', sans-serif;">
                    <div v-if="loadingTotalBillReceive || loadingTotalBillPay"><SpinnerPuzl/></div>
                    <div v-else>
                      <div v-if="listBillReceive">
                        <div v-if="totalsBillReceive">
                          <div v-if="grossValue" class="numeric">
                           {{ totalsBillReceive['total_pending'] | currency }}
                          </div>
                          <div v-else class="numeric">
                             {{ totalsBillReceive['final_total_pending'] | currency }}
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div v-if="totalsBillPay">
                          <div v-if="grossValue" class="numeric">
                            {{ totalsBillPay['total_pending'] | currency }}
                          </div>
                          <div v-else class="numeric">
                            {{ totalsBillPay['final_total_pending'] | currency }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="widgets-sm col-md-3 col-sm-6 p-1 card-row">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-8" style="margin-top: -10px">
                  <div v-if="listBillReceive">
                    <p class="mb-0 text-sm text-uppercase font-weight-bold ml-n3">
                      <small class="font-weight-bold">Antecipação aprovada</small>
                    </p>
                  </div>
                  <div v-else>
                    <p class="mb-0 text-sm text-uppercase font-weight-bold ml-n3">
                      <small class="font-weight-bold">Aprovado</small>
                    </p>
                  </div>
                </div>
                <div class="col-4 pr-1" style="margin-top: -5px">
                  <div class="text-center shadow icon icon-shape float-right mr-n2">
                    <div v-if="listBillReceive">
                      <i class="fa-solid fa-diagram-predecessor text-indigo" style="font-size: 28px;"></i>
                    </div>
                    <div v-else>
                      <i class="fa-solid fa-thumbs-up text-indigo" style="font-size: 28px;"></i>
                    </div>
                  </div>
                </div>
                <div class="col-md-12" style="margin-top: -25px">
                  <h3 class="font-weight-semibold mb-0 ml-n3" style="font-family: 'Gill Sans', sans-serif;">
                    <div v-if="loadingTotalBillReceive || loadingTotalBillPay"><SpinnerPuzl/></div>
                    <div v-else>
                      <div v-if="listBillReceive">
                        <div v-if="totalsBillReceive">
                          <div v-if="grossValue" class="numeric">
                           {{ totalApprovedAnticipationBillReceive['total_prepayments_approved'] | currency }}
                          </div>
                          <div v-else class="numeric">
                            {{ totalApprovedAnticipationBillReceive['final_total_prepayments_approved'] | currency }}
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div v-if="totalsBillPay">
                          <div v-if="grossValue" class="numeric">
                            {{ totalsBillPay['total_approved'] | currency }}
                          </div>
                          <div v-else class="numeric">
                            {{ totalsBillPay['final_total_approved'] | currency }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets-sm col-md-3 col-sm-6 p-1 card-row">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-8" style="margin-top: -10px">
                  <div v-if="listBillReceive">
                    <p class="mb-0 text-sm text-uppercase font-weight-bold ml-n3">
                      <small class="font-weight-bold">Total a receber</small>
                    </p>
                  </div>
                  <div v-else>
                    <p class="mb-0 text-sm text-uppercase font-weight-bold ml-n3">
                      <small class="font-weight-bold">Total a pagar</small>
                    </p>
                  </div>
                </div>
                <div class="col-4 pr-1" style="margin-top: -5px">
                  <div class="text-center shadow icon icon-shape float-right mr-n2">
                    <i class="fa-regular fa-square-check text-warning" style="font-size: 28px;"></i>
                  </div>
                </div>
                <div class="col-md-12" style="margin-top: -25px">
                  <h3 class="font-weight-semibold mb-0 ml-n3" style="font-family: 'Gill Sans', sans-serif;">
                    <div v-if="loadingTotalBillReceive || loadingTotalBillPay"><SpinnerPuzl/></div>
                    <div v-else>
                      <div v-if="listBillReceive">
                        <div v-if="totalsBillReceive">
                          <div v-if="grossValue" class="numeric">
                           {{ parseFloat(totalsBillReceive['total_overdue']) +
                              parseFloat(totalsBillReceive['total_pending']) +
                              parseFloat(totalApprovedAnticipationBillReceive['total_prepayments_approved']) | currency
                            }}
                          </div>
                          <div v-else class="numeric">
                            {{ parseFloat(totalsBillReceive['final_total_overdue']) +
                              parseFloat(totalsBillReceive['final_total_pending']) +
                              parseFloat(totalApprovedAnticipationBillReceive['final_total_prepayments_approved']) | currency
                            }}
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div v-if="totalsBillPay">
                          <div v-if="grossValue" class="numeric">
                            {{ parseFloat(totalsBillPay['total_overdue']) +
                               parseFloat(totalsBillPay['total_pending']) +
                               parseFloat(totalsBillPay['total_approved']) | currency
                            }}
                          </div>
                          <div v-else class="numeric">
                            {{ parseFloat(totalsBillPay['final_total_overdue']) +
                               parseFloat(totalsBillPay['final_total_pending']) +
                               parseFloat(totalsBillPay['final_total_approved']) | currency
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <!-- Primeira tabela -->
      <div class="row pt-0 card-row">
        <div class="col-md-12 p-1">
          <div class="card">
            <div class="card-body mt-n2">
              <div class="row">
                <div class="col-md-3 mb-3">
                  <base-button
                    style="border-radius: 5px !important;"

                    title="Agrupamento por cliente e status da parcela"
                    size="sm"
                    class="w-outline font-weight-500"
                              @click="setStatus">
                    STATUS
                    <i style="font-size: 12px" v-if="!hasStatus" class="far fa-square"></i>
                    <i style="font-size: 12px" v-else class="far fa-check-square"></i>
                  </base-button>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 table-responsive" style="overflow-y: scroll; max-height: 500px">
                  <table class="table table-sm table-bordered">
                    <thead>
                      <tr class="fixed">
                        <th class="text-center" v-if="listBillReceive">Cliente</th>
                        <th class="text-center" v-else>Fornecedor</th>
                        <th class="text-center">Conta</th>
                        <th class="text-center" v-if="hasStatus">Status</th>
                        <th class="text-center" v-if="listBillReceive">CAR</th>
                        <th class="text-center" v-else>CAP</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in 10" v-show="loadingCashierResume">
                        <th colspan="12">
                          <skeleton-puzl type="button" />
                        </th>
                      </tr>
                      <tr v-for="item in fetchBillReceiveOrBillPay">
                        <th class="text-left">
                          <span class="style-table">{{ item.name }}</span>
                        </th>
                        <th class="text-left">
                          <span class="style-table">{{ item.bank_account_name }}</span>
                        </th>
                        <th class="text-left" v-if="hasStatus">
                          <span class="style-table">
                            <span>
                              {{ item.status === 0 ? 'Pendente' : '' }}
                              {{ item.status === 1 ? listBillReceive ? 'Recebido' : 'Pago' : '' }}
                              {{ item.status === 2 ? 'Atrasado' : '' }}
                              {{ item.status === 3 ? 'Cancelado' : '' }}
                              {{ item.status === 4 ? 'Serasa' : '' }}
                              {{ item.status === 5 ? 'Cartório' : '' }}
                              {{ item.status === 6 ? 'Protestado' : '' }}
                              {{ item.status === 7 ? 'Jurídico' : '' }}
                              {{ item.status === 8 ? 'Aprovado' : '' }}
                              {{ item.status === 9 ? 'Negado' : '' }}
                            </span>
                          </span>
                        </th>
                        <th class="text-center">
                          <span class="style-table">
                            <div v-if="grossValue" class="numeric text-dark">
                              {{ item.total | currency }}
                            </div>
                            <div v-else class="numeric text-dark">
                              {{ item.final_total | currency }}
                            </div>
                          </span>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <pagination @navegate="navegate" :source="source" :loading="loadingCashierResume" />
            </div>
          </div>
        </div>
      </div>

      <!-- Segunda tabela -->
      <div class="row pt-0 card-row">
        <div class="col-md-12 p-1">
          <div class="card">
            <div class="card-body mt-n2">
              <div class="row">
                <div class="col-md-3 mb-3">
                  <base-button
                    style="border-radius: 5px !important;"

                    title="Agrupamento por condição de pagamento"
                    size="sm"
                    class="w-outline font-weight-500"
                              @click="setPaymentsCondition">
                    CONDIÇÃO DE PAGAMENTO
                    <i style="font-size: 12px" v-if="!hasPaymentsCondition" class="far fa-square"></i>
                    <i style="font-size: 12px" v-else class="far fa-check-square"></i>
                  </base-button>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 table-responsive" style="overflow-y: scroll; max-height: 500px">
                  <table class="table table-sm table-bordered">
                    <thead>
                      <tr class="fixed">
                        <th class="text-center">Método de pagamento</th>
                        <th class="text-center" v-if="hasPaymentsCondition">Condição de pagamento</th>
                        <th class="text-center" v-if="listBillReceive">CAR</th>
                        <th class="text-center" v-else>CAP</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in 10" v-show="loadingCashierResumeSecond">
                        <th colspan="12">
                          <skeleton-puzl type="button" />
                        </th>
                      </tr>
                      <tr v-for="item in fetchBillReceiveOrBillPaySecond">
                        <th class="text-left">
                          <span class="style-table">{{ item.name }}</span>
                        </th>
                        <th class="text-left" v-if="hasPaymentsCondition">
                          <div v-if="listBillReceive">
                            <span class="style-table">{{ item.payment_terms_name }}</span>
                          </div>
                          <div v-else>
                            <span class="style-table">{{ item.installment_quantity }}</span>
                          </div>
                        </th>
                        <th class="text-center">
                          <span class="style-table">
                            <div v-if="grossValue" class="numeric text-dark">
                                {{ item.total | currency }}
                            </div>
                            <div v-else class="numeric text-dark">
                              {{ item.final_total | currency }}
                            </div>
                          </span>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <pagination @navegate="navegateSecond" :source="sorceSecond" :loading="loadingCashierResumeSecond" />
            </div>
          </div>
        </div>
      </div>
    </base-header>
  </div>
</template>

<script>
import MultiFilterV2 from "@/components/Utils/MultiFilterV2";
import { SkeletonPuzl } from "@/components";
import Pagination from "@/components/Utils/Pagination";
import InputDatePicker from "@/components/InputDatePicker";
import { mapGetters } from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
import SpinnerPuzl from "@/components/SpinnerPuzl";
import moment from 'moment';

export default {
  components: {
    SkeletonPuzl,
    Pagination,
    MultiFilterV2,
    InputDatePicker,
    PuzlSelect,
    SpinnerPuzl,
  },
  name: "List",
  data() {
    return {
      title: "Resumo de caixa",
      loadingCashierResume: false,
      loadingCashierResumeSecond: false,
      loadingTotalBillReceive: true,
      loadingTotalBillPay: true,
      loadingBankAccount: true,
      loadingCompanyPlants: true,
      loadingSellers: true,
      source: null,
      sorceSecond: null,
      listBillReceive: true,
      listBillPay: false,
      fetchBillReceiveOrBillPay: null,
      fetchBillReceiveOrBillPaySecond: null,
      totalsBillReceive: null,
      totalApprovedAnticipationBillReceive: null,
      totalsBillPay: null,
      hasStatus: true,
      hasPaymentsCondition: true,
      grossValue: false,
      today: moment().format('DD/MM/YYYY'),
      params: {
        page: 1,
        per_page: 10,
      },
      filter: {},
      selectedBanksAccount: [],
      selectedCompanyPlants: [],
      selectedSellers: [],
      status: null,
      selectAll: false,
      range: {
        start: null,
        end: null,
      },
      defaultRange: {
          start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
          end: new Date(),
      },
      multi_filter: {
        null: {
          name: "Todos",
          type: "primary"
        },
        '0': {
          name: "Pendente",
          type: "warning"
        },
        '1': {
          name: "Recebido",
          type: "success"
        },
        '2': {
          name: "Atrasado",
          type: "danger"
        },
        '3': {
          name: "Cancelado",
          type: "danger"
        },
        '8': {
          name: "Aprovado",
          type: "primary"
        },
        '9': {
          name: "Negado",
          type: "danger"
        },
        '10': {
          name: "Outros",
          type: "info"
        }
      },
    };
  },
  computed: {
    ...mapGetters({
      $_cashier_resume_bill_receive: "cashierResume/fetchBillReceive",
      $_cashier_resume_bill_receive_second: "cashierResume/fetchBillReceiveSecond",
      $_cashier_resume_bill_pay: "cashierResume/fetchBillPay",
      $_cashier_resume_bill_pay_second: "cashierResume/fetchBillPaySecond",
      $_bank_accounts: 'bankAccount/fetch',
      $_plants: "plant/activeItems",
      $_sellers: "user/sellers",
    }),
  },
  mounted() {
    this.$store.dispatch('bankAccount/fetchItems').then(() => {
        this.loadingBankAccount = false;
    });
    this.$store.dispatch('plant/fetchItems').then(() => {
        this.loadingCompanyPlants = false;
    });
    this.$store.dispatch("user/fetchSellers").then(() => {
      this.loadingSellers = false;
    });
    this.range = this.defaultRange;
    this.init();
  },
  methods: {
    init() {
      this.selectedCompanyPlants = [];
      this.fetchItemsPaginateBillReceive();
      this.fetchItemsPaginateBillReceiveSecond();
      this.countTotalsBillReceive();
      this.countTotalsBillPay();
    },
    getFilterStatus() {
      this.status = this.filter.status;
      if (this.listBillReceive) {
        this.fetchItemsPaginateBillReceive();
        this.fetchItemsPaginateBillReceiveSecond();
        this.countTotalsBillReceive();
      } else {
        this.fetchItemsPaginateBillPay();
        this.fetchItemsPaginateBillPaySecond();
        this.countTotalsBillPay();
      }
    },
    fetchItemsPaginateBillReceive(filter = {}) {
      this.loadingCashierResume = true;
      this.loadingTotalBillReceive = true;
      this.filter = filter;
      this.filter = {
        range: {
          start: this.$helper.parseDate(this.range.start, "YYYY-MM-DD"),
          end: this.$helper.parseDate(this.range.end, "YYYY-MM-DD"),
        }
      }
      if (this.hasStatus) {
        this.filter.hasStatus = this.hasStatus;
      }
      this.handleFilterBankAccountsAndPlants();
      this.$store
        .dispatch("cashierResume/fetchItemsPaginateBillReceive", {
          filter: this.filter,
          page: this.params.page,
        })
        .then((response) => {
          this.fetchBillReceiveOrBillPay = {... this.$_cashier_resume_bill_receive};
          this.source = response;
          this.loadingCashierResume = false;
          this.loadingTotalBillReceive = false;
        })
        .catch(() => {
          this.loadingCashierResume = false;
          this.loadingTotalBillReceive = false;
        });
    },
    fetchItemsPaginateBillReceiveSecond(filter = {}) {
      this.loadingCashierResumeSecond = true;
      this.loadingTotalBillReceive = true;
      this.filter = filter;
      this.filter = {
        range: {
          start: this.$helper.parseDate(this.range.start, "YYYY-MM-DD"),
          end: this.$helper.parseDate(this.range.end, "YYYY-MM-DD"),
        }
      }
      if (this.hasPaymentsCondition) {
        this.filter.hasPaymentsCondition = this.hasPaymentsCondition;
      }
      this.handleFilterBankAccountsAndPlants();
      this.$store
        .dispatch("cashierResume/fetchItemsPaginateBillReceiveSecond", {
          filter: this.filter,
          page: this.params.page,
        })
        .then((response) => {
          this.fetchBillReceiveOrBillPaySecond = {... this.$_cashier_resume_bill_receive_second};
          this.sorceSecond = response;
          this.loadingCashierResumeSecond = false;
          this.loadingTotalBillReceive = false;
        })
        .catch((error) => {
          this.loadingCashierResumeSecond = false;
          this.loadingTotalBillReceive = false;
        });
    },
    countTotalsBillReceive() {
      this.loadingTotalBillReceive = true;
      this.handleFilterBankAccountsAndPlants();
      this.$store
        .dispatch("cashierResume/countTotalCashierResumeBillReceive", {
          filter: this.filter,
        })
        .then((response) => {
          this.loadingTotalBillReceive = false;
          this.totalsBillReceive = response.data;
        });

      this.$store
        .dispatch("cashierResume/getCountTotalApprovedAnticipationBillReceive", {
          filter: this.filter,
        })
        .then((response) => {
          this.totalApprovedAnticipationBillReceive = response.data;
        });
    },
    fetchItemsPaginateBillPay(filter = {}) {
      this.loadingCashierResume = true;
      this.loadingTotalBillPay = true;
      this.filter = filter;
      this.filter.range = this.range;
      if (this.hasStatus) {
        this.filter.hasStatus = this.hasStatus;
      }
      this.handleFilterBankAccountsAndPlants();
      this.$store
        .dispatch("cashierResume/fetchItemsPaginateBillPay", {
          filter: this.filter,
          page: this.params.page,
        })
        .then((response) => {
          this.countTotalsBillPay();
          this.fetchBillReceiveOrBillPay = {... this.$_cashier_resume_bill_pay};
          this.source = response;
          this.loadingCashierResume = false;
          this.loadingTotalBillPay = false;
        })
        .catch(() => {
          this.loadingCashierResume = false;
          this.loadingTotalBillPay = false;
        });
    },
    fetchItemsPaginateBillPaySecond(filter = {}) {
      this.loadingCashierResumeSecond = true;
      this.loadingTotalBillPay = true;
      this.filter = filter;
      this.filter.range = this.range;
      if (this.hasPaymentsCondition) {
        this.filter.hasPaymentsCondition = this.hasPaymentsCondition;
      }
      this.handleFilterBankAccountsAndPlants();
      this.$store
        .dispatch("cashierResume/fetchItemsPaginateBillPaySecond", {
          filter: this.filter,
          page: this.params.page,
        })
        .then((response) => {
          this.fetchBillReceiveOrBillPaySecond = {... this.$_cashier_resume_bill_pay_second};
          this.sorceSecond = response;
          this.loadingCashierResumeSecond = false;
          this.loadingTotalBillPay = false;
        })
        .catch(() => {
          this.loadingCashierResumeSecond = false;
          this.loadingTotalBillPay = false;
        });
    },
    countTotalsBillPay() {
      this.loadingTotalBillPay = true;
      this.handleFilterBankAccountsAndPlants();
      this.$store
        .dispatch("cashierResume/countTotalCashierResumeBillPay", {
          filter: this.filter,
        })
        .then((response) => {
          this.loadingTotalBillPay = false;
          this.totalsBillPay = response.data;
        })
    },
    setStatus() {
      this.hasStatus = !this.hasStatus ? true : null;
      if (this.listBillReceive) {
        this.fetchItemsPaginateBillReceive();
      } else {
        this.fetchItemsPaginateBillPay();
      }
    },
    setPaymentsCondition() {
      this.hasPaymentsCondition = !this.hasPaymentsCondition ? true : null;
      if (this.listBillReceive) {
        this.fetchItemsPaginateBillReceiveSecond();
      } else {
        this.fetchItemsPaginateBillPaySecond();
      }
    },
    getBillReceive() {
      this.listBillReceive = true;
      this.listBillPay = false;
      this.fetchItemsPaginateBillReceive();
      this.fetchItemsPaginateBillReceiveSecond();
    },
    getBillPay() {
      this.listBillPay = true;
      this.listBillReceive = false;
      this.fetchItemsPaginateBillPay();
      this.fetchItemsPaginateBillPaySecond();
    },
    setGrossValue() {
      this.grossValue = !this.grossValue;
    },
    handleFilterBankAccountsAndPlants() {
      this.filter.company_plants = this.selectedCompanyPlants ? this.selectedCompanyPlants : [];
      this.filter.bank_accounts = this.selectedBanksAccount ? this.selectedBanksAccount : [];
      this.filter.sellers_uuid = this.selectedSellers ? this.selectedSellers : [];
      this.filter.status = this.status;
    },
    handleFilterDate(filter) {
      this.range = filter;
      if (this.listBillReceive) {
        this.fetchItemsPaginateBillReceive();
        this.fetchItemsPaginateBillReceiveSecond();
        this.countTotalsBillReceive();
      } else {
        this.fetchItemsPaginateBillPay();
        this.fetchItemsPaginateBillPaySecond();
        this.countTotalsBillPay();
      }
    },
    handleFilterBankAccounts() {
      const index = this.selectedBanksAccount.findIndex((item) => item == "all");
      if (this.selectedBanksAccount.length > 1 && index === 0) {
        return this.selectedBanksAccount.splice(0, 1);
      }
      if (this.selectedBanksAccount.length > 1 && this.selectedBanksAccount.includes("all")) {
        this.selectedBanksAccount = [];
        this.selectedBanksAccount.push("all");
      }
      if (this.listBillReceive) {
        this.fetchItemsPaginateBillReceive();
        this.fetchItemsPaginateBillReceiveSecond();
        this.countTotalsBillReceive();
      } else {
        this.fetchItemsPaginateBillPay();
        this.fetchItemsPaginateBillPaySecond();
        this.countTotalsBillPay();
      }
    },
    handleFilterCompanyPlants() {
      const index = this.selectedCompanyPlants.findIndex((item) => item == "all");
      if (this.selectedCompanyPlants.length > 1 && index === 0) {
        return this.selectedCompanyPlants.splice(0, 1);
      }
      if (this.selectedCompanyPlants.length > 1 && this.selectedCompanyPlants.includes("all")) {
        this.selectedCompanyPlants = [];
        this.selectedCompanyPlants.push("all");
      }
      if (this.listBillReceive) {
        this.fetchItemsPaginateBillReceive();
        this.fetchItemsPaginateBillReceiveSecond();
        this.countTotalsBillReceive();
      } else {
        this.fetchItemsPaginateBillPay();
        this.fetchItemsPaginateBillPaySecond();
        this.countTotalsBillPay();
      }
    },
    handleFilterSellers() {
      if (this.listBillReceive) {
        this.fetchItemsPaginateBillReceive();
        this.fetchItemsPaginateBillReceiveSecond();
        this.countTotalsBillReceive();
      } else {
        this.fetchItemsPaginateBillPay();
        this.fetchItemsPaginateBillPaySecond();
        this.countTotalsBillPay();
      }
    },
    clearFilters() {
      this.filter = {};
      this.selectedCompanyPlants = [];
      this.selectedBanksAccount = [];
      if (this.listBillReceive) {
        this.fetchItemsPaginateBillReceive();
        this.fetchItemsPaginateBillReceiveSecond();
        this.countTotalsBillReceive();
      } else {
        this.fetchItemsPaginateBillPay();
        this.fetchItemsPaginateBillPaySecond();
        this.countTotalsBillPay();
      }
    },
    navegate(page) {
      this.params.page = page;
      if (this.listBillReceive) {
        this.fetchItemsPaginateBillReceive();
      } else {
        this.fetchItemsPaginateBillPay();
      }
    },
    navegateSecond(page) {
      this.params.page = page;
      if (this.listBillReceive) {
        this.fetchItemsPaginateBillReceiveSecond();
      } else {
        this.fetchItemsPaginateBillPaySecond();
      }
    },
  },
};
</script>

<style scoped>
.w-outline {
  background-color: white !important;
  color: #000000 !important;
}
.style-table {
  font-weight: normal;
  width: 15px;
}
</style>
