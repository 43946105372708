<template lang="">
<div>
    <modal :show.sync="modal.create">
        <template slot="header">
            <h5 class="modal-title pl-3">{{ modal.title }}</h5>
        </template>
        <div class="row">
            <div class="col-md-12 mb-2">
            <!-- Search form -->
                <div class="input-group input-group-alternative input-group-merge">
                        <autocomplete
                        class="auto-complete-search"
                        :search="search"
                        aria-label="Pesquisar menus"
                        placeholder="Pesquisar menus"
                        default-value=""
                        :value="searchBar"
                        @submit="handleSubmitSearch"
                        >
                        </autocomplete>
                    </div>
                    <button
                    type="button"
                    class="close"
                    data-action="search-close"
                    data-target="#navbar-search-main"
                    aria-label="Close"
                    >
                    </button>
            </div>
        </div>
    </modal>
</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "ModalMenus",
    computed: {
        ...mapGetters({
            tags: "tag/fetch",
            menus: "menu/fetch",
        })
    },
    data() {
        return {
            modal: {
                title: 'Definir Menu',
                create: false,
            },
            loadingStore: false,
            module: null,
            moduleId: null,
            userId: null,
            activeNotifications: false,
            searchBar: null,
            index: null,
        }
    },
    created() {
        // this.$store.dispatch("menu/fetchItems")
        // this.$store.dispatch("tag/fetchItems");
        this.$store.dispatch("auth/loadSession").then((response) => {
            this.userId = response.data.user.id
        });
    },
    methods: {
        closeModal() {
            this.modal.create = false
        },
        handleModalInsertMenu(index) {
            this.index = index;
            this.modal.create = true
            this.$notify({
                type: 'info',
                message: 'Estamos trabalhando em sua solicitação.'
            })
        },
        async handleSubmitSearch(result) {
            let menu = this.menus.filter(item => item.name == result);
            if (menu.length > 0) {
                // Faz a busca pelo nome do módulo de acordo com o menu selecionado.
                await this.$store.dispatch("module/getModule", menu[0].module_uuid).then((response) => {
                    this.module = response.data.name;
                    this.moduleId = response.data.id;
                }).catch((error) => {
                    this.$notify({
                        type: 'danger',
                        message: 'Erro ao carregar módulo do menu'
                    })
                });
                // Cadastra o card do usuário de acordo com o menu selecionado.
                this.$store.dispatch("card/add",
                    {
                        menu: menu[0].name,
                        module: this.module,
                        icon: menu[0].icon || '/',
                        link: menu[0].link,
                        order: this.index,
                        user_id: this.userId,
                        module_id: this.moduleId,
                    }
                ).then((response) => {
                    this.$notify({
                        type: 'success',
                        message: 'Card cadastrado com sucesso.'
                    })
                    this.$store.dispatch("card/fetchItems", {userId: this.userId}).then((response) => {
                        // Envia lista de cards atualizada para o compoenente pai.
                        this.$emit('updatedCardList', response.data);
                        this.closeModal();
                    })
                }).catch((error) => {
                    this.$notify({
                        type: 'danger',
                        message: 'Erro ao cadastrar card.'
                    })
                });
            }
            this.searchBar = null;
        },
        search(input) {
        this.searchBar = input;
        // caso input esteja vazio o retornamos.
        if (input.length < 1) {
            return [];
        }
        // filtramos todas as tags com o valor correspondente ao input do usuario e armazenamos em tags
        let tags = this.tags.filter(tag => {
            return tag.name.toLowerCase().includes(input.toLowerCase());
        });
        let menus = this.menus.filter(menu => {
            return menu.name.toLowerCase().includes(input.toLowerCase());
        });
        let menu_titles = [];
        menus.forEach(function(item, index) {
            if (!menu_titles.includes(item.name)) menu_titles.push(item.name);
        });
        // percorrendo todos os menus e inserindo no array
        tags.forEach(function(item, index) {
            item.menus.forEach(function(menu, index_menu) {
            if (!menu_titles.includes(menu.name)) menu_titles.push(menu.name);
            });
        });
        return menu_titles;
        },
        closeDropDown() {
            this.activeNotifications = false;
        },
    }
}
</script>

<style>
.autocomplete-input,
.autocomplete-result-list {
  font-size: 0.875rem !important;
}
.autocomplete-input {
  background-color: transparent !important;
  border: none !important;
}
.autocomplete-input:focus,
.autocomplete-input {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>

