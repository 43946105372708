<template>
  <div>
    <modal size="lg" @close="close" :show.sync="modal.create && !showImgView">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">Anexos</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div>
              <div class="row">
                <div class="col-md-12 text-center">
                     <span class="display-4">
                </span>
                </div>
              </div>
              <div class="row ">
                <!--                <div class="col-md-12">-->
                <!--                  <base-alert style="background: red" type="info">-->
                <!--                    <strong>Atenção!</strong> Clique no arquivo desejado para visualizar os detalhes.-->
                <!--                  </base-alert>-->
                <!--                </div>-->
                <div v-for="item in urls" class="col-md-4" @mouseover="enter(item)" @mouseleave="leave(item)">
                  <div :style="!item.up_here ? 'opacity: 0%' : 'opacity: 100%'" class="card text-center float-right"
                       style="margin-top: 10px;margin-right:5px;width: 100px;margin-bottom: -120px;z-index: 99999999999">
                    <div class="card-body" style="padding: 0.8rem !important;">
                      <a href="#" @click.prevent="showModalImgView(item)">
                        <i style="font-size: 1.3rem" class="fa-regular fa-eye mr-2 text-grey2"></i>
                      </a>
                      <a href="#" @click.prevent="download(item)">
                        <i style="font-size: 1.3rem" class="fa-regular fa-floppy-disk ml-2 text-primary"></i>
                      </a>
                    </div>
                  </div>
                  <div @click.prevent="showModalImgView(item)" class="card pointer">
                    <img style="height: 290px"
                         v-if="item.ext != 'pdf'" class="img" :src="item.url"/>
                    <pdf
                      style="height: 270px!important;width:80%;margin:10px 25px;"
                      v-if="item.ext == 'pdf'" :src="item.url" :page="1"/>

                    <div class="card-footer text-truncate">
                      <span :title="item.file_name" class="">{{ item.file_name }}</span>
                      <br>
                      <small class="text-muted mt-n4">Importado {{ item.last_updated }}</small>
                    </div>
                    <!--                    <div class="card-footer">-->
                    <!--                      <div class="row p-0" style="margin-bottom: 0px">-->
                    <!--                        <div class="col-12 p-0 text-truncate">-->
                    <!--                          <small class="numeric" style="font-size: 1rem;color: #4e555b">{{ item.file_name }}-->
                    <!--                          </small>-->
                    <!--                        </div>-->
                    <!--                        <div class="col-12 p-0">-->
                    <!--                          <small class="text-muted">Importado {{item.last_updated}}.</small>-->
                    <!--                        </div>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="close()">
                fechar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
    <modal-attachment-view :selected="selected" :show.sync="showImgView"/>
  </div>
</template>

<script>
import ModalAttachmentView from './_ModalAttachmentView'
import pdf from 'pdfvuer';
import {base_url_ms} from '@/plugins'

export default {
  name: "ModalAttachment",
  components: {
    ModalAttachmentView,
    pdf
  },
  data() {
    return {
      modal: {
        title: 'Anexos',
        create: false,
      },
      base_url_ms: base_url_ms(),
      scale: 'page-width',
      selected: null,
      showImgView: false,
      opened_in_new_tab: false,
      urls: [],
      windowWidth: null,
      url: null,
      loadingStore: false,
    }
  },
  computed: {},
  methods: {
    close() {
      this.modal.create = false
    },
    showModalImgView(item) {
      this.selected = item
      this.showImgView = true
    },
    closeModalImgView() {
      this.showImgView = false
    },
    enter(item) {
      item.up_here = true
    },
    leave(item) {
      item.up_here = false
    },
    async download(item) {
      let loader = this.$loading.show()
      const url = this.base_url_ms + 'download-s3?url=' + item.url + '&name=' + item.file_name
      this.$store.dispatch('exports/download', url).then(async response => {
        let blob = new Blob([response], {type: "application/pdf"});
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", item.file_name);
        await link.click()
      });
      loader.hide()
    },
    async openAttachment(urls) {
      this.urls = urls
      const loader = this.$loading.show();
      /**
       * cria um array de urls para retornar urls temporárias
       */
      const temporaryUrls = urls.map(({id, url}) => ({id, file_path: url}));
      const response = await this.$store.dispatch('exports/getTemporaryUrl', {urls: temporaryUrls});
      /**
       * mescla a url temporária com a url pré definida
       * @type {(*&{url: *})[]}
       */
      this.urls = this.urls.map(item => {
        const match = response.data.find(temp => Number(temp.id) === Number(item.id));
        return { ...item, url: match.file_path }
      });
      this.url = null
      this.windowWidth = window.innerWidth
      this.opened_in_new_tab = false
      this.modal.create = true
      loader.hide()
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  }
}
</script>

<style>
.img {
  cursor: pointer;
  /*width: 15rem;*/
  height: 200px;
  object-fit: scale-down;
  /*z-index: 1;*/
}

.download-button:hover {
  transform: scale(1.2);
}
</style>
