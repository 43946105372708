<template>
<div>
  <modal :show.sync="modal.create">
    <template slot="header">
      <h5 class="modal-title">{{ modal.title }}</h5>
    </template>
    <div>
      <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
        <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
          <div>
            <div class="form-group row m-0 p-0">
              <label class="col-md-5 col-form-label form-control-label">
                Status
              </label>
              <div class="col-md-7 pt-2">
                <base-switch v-model="type.status" type="success" offText="inativo" onText="ativo" class="success"></base-switch>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                Descrição Técnica
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      v-model="type.technical_description"
                      class="form-control form-control-sm"
                      :class="errors[0] ? 'is-invalid' : 'is-valid'"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                Descrição Comercial
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      v-model="type.commercial_description"
                      class="form-control form-control-sm"
                      :class="errors[0] ? 'is-invalid' : 'is-valid'"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                Massa Específica (kg/m<sup>3</sup>)
              </label>
            </div>
            <div class="form-group row m-0 p-0">
              <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label text-right">
                de
              </label>
              <div class="col-md-7">
                <validation-provider rules="min_value:99|max_value:4999" v-slot="{errors}">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      inputmode="numeric"
                      name="specific_gravity_start"
                      v-model="type.specific_gravity_start"
                      class="form-control form-control-sm"
                      :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      v-mask="['#####']"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label text-right">
                até
              </label>
              <div class="col-md-7">
                <validation-provider rules="min_value:99|max_value:4999" v-slot="{errors}">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      inputmode="numeric"
                      v-model="type.specific_gravity_end"
                      class="form-control form-control-sm"
                      :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      v-mask="['#####']"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <base-button type="secondary" @click="closeModal('create')">
              Cancelar
            </base-button>
            <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
              Salvar
            </base-button>
          </div>
        </form>
      </validation-observer>
    </div>
  </modal>
</div>
</template>

<script>
export default {
  name: "Create",
  data () {
    return {
      modal: {
        title: 'Tipo',
        create: false,
      },
      type: {
        status: true,
        technical_description: null,
        commercial_description: null,
        specific_gravity_start: null,
        specific_gravity_end: null,
      },
      loadingStore: false,
    }
  },
  computed: {
  },
  methods: {
    closeModal () {
      this.modal.create = false
    },
    handleCreateModal () {
      this.modal.create = true
    },
    store () {
      this.$Progress.start()
      this.loadingStore = true
      this.$store.dispatch('mixType/add', this.type)
        .then(response => {
          this.loadingStore = false
          this.modal.create = false
          this.type = {
            status: true,
            technical_description: null,
            commercial_description: null,
            specific_gravity_start: null,
            specific_gravity_end: null,
          }
          this.$Progress.finish()
          this.$notify({ type: response.error_type, message: response.message })
        })
        .catch(error =>{
          if(error.response.status===422){
            let errors = Object.values(error.response.data.errors)
            errors = errors.flat().toString().split(',').join("<br />")
            this.$notify({ type: 'danger', message: errors })
          }
          this.$Progress.finish()
          this.loadingStore = false
        })
    },
  },
  mounted () {
    this.$refs.formValidator.validate();
  },
  created () {
  }
}
</script>

<style scoped>

</style>
