<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(store)"
            autocomplete="off"
          >
            <div>
              <!-- Maior que -->
              <div class="form-group row m-0 p-0">
                <div class="col-md-12 pr-0 pl-0">
                  <div class="form-group row m-0 p-0 ">
                    <label
                      class="col-md-6 pb-0 col-form-label form-control-label mb-1"
                    >
                      Maior que
                    </label>
                    <div class="col-md-6 pr-4 mb-1">
                      <validation-provider rules="required">
                        <base-input input-group-classes="input-group-sm">
                          <input
                            inputmode="numeric"
                            v-model="parameter.from"
                            class="form-control form-control-sm"
                            v-on:input="
                              parameter.from = formatString(
                                $event.target.value,
                                '-999',
                                '999'
                              )
                            "
                          />
                          <template slot="append">
                            <small class="input-group-sm p-0 m-0">
                              %
                            </small>
                          </template>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <!-- Menor que -->
                  <div class="row m-0 p-0">
                    <label
                      class="col-md-6 pb-0 col-form-label form-control-label mb-1"
                    >
                      Menor que
                    </label>
                    <div class="col-md-6 pr-4">
                      <validation-provider rules="required">
                        <base-input input-group-classes="input-group-sm">
                          <input
                            inputmode="numeric"
                            v-model="parameter.until"
                            class="form-control form-control-sm"
                            v-on:input="
                              parameter.until = formatString(
                                $event.target.value,
                                '-999',
                                '999'
                              )
                            "
                          />
                          <template slot="append">
                            <small class="input-group-sm p-0 m-0">
                              %
                            </small>
                          </template>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <!-- Comissão -->
                  <div class="row m-0 p-0">
                    <label
                      class="col-md-6 pb-0 col-form-label form-control-label mb-1"
                    >
                      Comissão
                    </label>
                    <div class="col-md-6 pb-3 pr-4">
                      <validation-provider rules="required">
                        <base-input input-group-classes="input-group-sm">
                          <input
                            inputmode="numeric"
                            v-model="parameter.commission"
                            class="form-control form-control-sm"
                            v-on:input="
                              parameter.commission = formatString(
                                $event.target.value,
                                '0',
                                '999.9',
                                1
                              )
                            "
                          />
                          <template slot="append">
                            <small class="input-group-sm p-0 m-0">
                              %
                            </small>
                          </template>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <base-button type="secondary" @click="closeModal('create')">
                  Cancelar
                </base-button>
                <base-button
                  type="success"
                  native-type="submit"
                  v-bind:disabled="invalid"
                  :loading="loadingStore"
                >
                  Salvar
                </base-button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mask1, formatErrorValidation } from "@/plugins";
import { checkNumberValue } from "@/helpers";

export default {
  name: "ModalEditParameter",
  data() {
    return {
      modal: {
        title: "Comissão",
        create: false
      },
      parameter: {
        uuid: null,
        from: null,
        until: null,
        commission: null
      },
      loadingStore: false
    };
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    handleCreateModal(uuid, parameter_uuid) {
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.$Progress.start();
      let params = {
        uuid: uuid,
        parameter_uuid: parameter_uuid
      };
      this.$store
        .dispatch("commission/showParameter", params)
        .then(response => {
          this.parameter = {
            parameter_uuid: response.data.uuid || "",
            uuid : uuid,
            from: response.data.from,
            until: response.data.until || "",
            commission: response.data.commission || ""
          };
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.modal.create = true;
        })
        .catch(error => {
          if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors
            });
          }
          this.$Progress.finish();
          this.loadingStore = false;
        });
    },
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        return checkNumberValue(
          mask1(value.toString(), decimals, ".", true),
          min,
          max
        );
      }
      return checkNumberValue(value, min, max);
    },
    store() {
      this.$Progress.start();
      this.loadingStore = true;
      this.parameter.from = parseInt(this.parameter.from)
      this.parameter.until = parseInt(this.parameter.until)
      this.$store
        .dispatch("commission/updateParameter", this.parameter)
        .then(response => {
          this.closeModal("create");
          this.$emit("updatedParameter");
          this.loadingStore = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
        })
        .catch(error => {
          if (error.status === 200) {
            this.$notify({
              type: "danger",
              message: error.data.message
            });
            this.$Progress.finish();
            this.loadingStore = false;
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors
            });
            this.$Progress.finish();
            this.loadingStore = false;
          }
        });
    }
  }
};
</script>

<style scoped></style>
