import { CmcAdjustmentListType } from "./CmcAdjustmentListType";
import { PagerType, initPagerType } from "../../../../shared/types";

/**
 * Type para retorno paginado da listagem
 *
 * @typedef {(
 *   PagerType &
 *   { items: Array<CmcAdjustmentListType> }
 * )} CmcAdjustmentListPagerType
 */
export const CmcAdjustmentListPagerType = {};

/**
 * Inicializar CmcAdjustmentListPagerType
 *
 * @returns {CmcAdjustmentListPagerType}
 */
export const initCmcAdjustmentListPagerType = () => ({
  ...initPagerType(),
  items: [],
});
