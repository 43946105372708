import * as types from './mutation_types'
import { createAxios } from "@/plugins/axios";;
const endPoint = '/commercial/ebitda-analysis/+microservice'
export default {
  namespaced: true,
  state: {
    contractProposalAnalysis: {},
  },
  getters: {
    getByContractProposal: state => state.contractProposalAnalysis,
  },
  mutations: {
    [types.SET_CONTRACT_PROPOSAL_ANALYSIS](state, payload) {
      state.contractProposalAnalysis = payload
    },
  },
  actions: {
    getByContractProposal({ commit }, payload) {
      return createAxios().get(`${endPoint}contract-proposal/${payload.id}`,{params : payload.params})
        .then(({ data }) => {
          commit(types.SET_CONTRACT_PROPOSAL_ANALYSIS, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
