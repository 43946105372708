import Vue from "vue";

/** Iniciar barra de progresso do navegador */
const start = () => Vue.prototype.$Progress.start();

/** Finalizar barra de progresso do navegador */
const finish = () => Vue.prototype.$Progress.finish();

/**
 * Exibe o loader no centro da tela.
 * 
 * @returns {Object} Um objeto que possui uma função `hide` para ocultar o loader.
 * @returns {Function} returns.hide - Função que oculta o loader quando chamada.
 * 
 * @example
 * const loader = progress.showLoader();
 * // Para ocultar o loader
 * loader.hide();
 */
const showLoader = () => {
  return Vue.prototype.$loading.show();
};

export default {
  start,
  finish,
  showLoader,
};
