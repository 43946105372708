<template>
  <div>
    <modal :show.sync="modal" size="sm">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div class="row justify-content-center">
        <div class="col ml-4 border-3 border-left border-success"><h4 class="mb-0"><a
          href="#!">{{ selected.cmc_group.name }}</a></h4>
          <p class="text-sm text-muted mb-0">{{ selected.company_plant.name }}</p></div>
        <div class="col-md-12 mt-4">
          <div class="card">
            <div class="card-body">
            <span class="display-4 text-primary" style="font-size: 0.9rem">
                Referência técnica
            </span>
              <base-input input-classes="form-control-sm">
                <el-select
                  @change="setTechnical"
                  v-model="reference.technical"
                  size="mini"
                  :disabled="disable_technical_reference"
                  placeholder="Selecione"
                  filterable
                >
                  <el-option v-for="cmc in technical_reference" :key="cmc.cmc_id" :label="cmc.product_name+
                                    ' - '+cmc.supplier_name+
                                    ' ('+cmc.origin_name+')  -  R$ '+
                                    cmc.price+
                                    ' / '+cmc.unit_buy" :value="cmc.cmc_id">
                  </el-option>
                </el-select>
              </base-input>
              <hr>
              <span class="display-4 text-warning" style="font-size: 0.9rem">
                Referência comercial
            </span>
              <base-input input-classes="form-control-sm">
                <el-select
                  @change="setCommercial"
                  v-model="reference.commercial"
                  size="mini"
                  :disabled="disable_commercial_reference"
                  placeholder="Selecione"
                  filterable
                >
                  <el-option v-for="cmc in commercial_reference" :key="cmc.cmc_id" :label="cmc.product_name+
                                    ' - '+cmc.supplier_name+
                                    ' ('+cmc.origin_name+')  -  R$ '+
                                    cmc.price+
                                    ' / '+cmc.unit_buy" :value="cmc.cmc_id">
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer" style="margin-top: -20px">
        <base-button type="secondary" @click="close()">fechar</base-button>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex'
import {
  formatErrorValidation
} from '@/plugins'
import { CmcAdjustmentListType } from "../../types";

export default {
  name: "ModalReference",
  data() {
    return {
      title: 'Referências',
      modal: false,
      selected: null,
      reference: {
        commercial: null,
        technical: null,
      },
      disable_technical_reference: false,
      disable_commercial_reference: false,
      commercial_reference: [],
      technical_reference: []
    }
  },
  computed: {
    ...mapGetters({
      $_user: 'auth/getUser',
    }),
  },
  methods: {
    close() {
      this.modal = false
    },
    /**
     * Preparando dados para modal antiga, vindo de uma tela nova
     * @param {CmcAdjustmentListType} adjustment
     */
    async handleDatatoOpenModal(adjustment) {
      let item = {
        cmc_group: {
          id: adjustment.group_id,
          name: adjustment.group_name
        },
        company_plant: {
          id: adjustment.company_plant_id,
          name: adjustment.company_plant_name
        }
      };

      this.handleOpenModal(item);
    },
    fetchTechnicalPermissions() {
      return this.$store.dispatch('technicalPermission/getByUserId', this.$_user.id)
        .then((response) => {
          this.disable_technical_reference = false
          this.disable_commercial_reference = false
          if (!response.data.is_change_technical_references_of_mcc_groups) {
            this.disable_technical_reference = true
          }
          if (!response.data.is_cmc_group_business_references_modified) {
            this.disable_commercial_reference = true
          }
        })
    },
    async handleOpenModal(item) {
      await this.fetchTechnicalPermissions()
      if (this.disable_technical_reference && this.disable_commercial_reference) {
        return this.$notify({
          type: 'danger',
          message: 'Usuário não possui permissão para alterar referência'
        });
      }
      this.selected = item
      this.reference = {
        commercial: null,
        technical: null,
      }
      this.load()
      this.modal = true
    },
    setCommercial() {
      if (!this.reference.commercial) {
        return;
      }
      this.save({
        cmc_id: this.reference.commercial,
        type: 2
      })
    },
    setTechnical() {
      if (!this.reference.technical) {
        return;
      }
      this.save({
        cmc_id: this.reference.technical,
        type: 1
      })
    },
    save(params) {
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      });
      this.$Progress.start();
      let loader = this.$loading.show()
      this.$axios.post("mix/reference", {
        cmc_id: params.cmc_id,
        cmc_group_id: this.selected.cmc_group.id,
        company_plant_id: this.selected.company_plant.id,
        reference_type: params.type,
      })
        .then(({data}) => {
          this.$notify({
            type: data.error_type,
            message: data.message
          });
          loader.hide()
          this.$Progress.finish();
        })
        .catch((error) => {
          this.$notify({
            type: error.data.error_type,
            message: error.data.exception
          });
          loader.hide()
        });
    },
    load() {
      let loader = this.$loading.show()
      Promise.all([
        this.$store.dispatch('cmc/fetchPrice', {
          plantId: this.selected.company_plant.id,
          groupId: this.selected.cmc_group.id,
          reference_type: 1
        }),
        this.$store.dispatch('cmc/fetchPrice', {
          plantId: this.selected.company_plant.id,
          groupId: this.selected.cmc_group.id,
          reference_type: 2
        }),
        this.$store.dispatch('mixReference/fetchByParams', {
          plantId: this.selected.company_plant.id,
          groupId: this.selected.cmc_group.id,
          type: 1
        }),
        this.$store.dispatch('mixReference/fetchByParams', {
          plantId: this.selected.company_plant.id,
          groupId: this.selected.cmc_group.id,
          type: 2
        }),
      ]).then(response => {
        loader.hide()
        this.technical_reference = response[0].data
        this.commercial_reference = response[1].data
        if (response[2].data.length) {
          this.reference.technical = response[2].data[0].cmc_id
        }
        if (response[3].data.length) {
          this.reference.commercial = response[3].data[0].cmc_id
        }
      }).catch(() => loader.hide())
    }
  },
}
</script>

<style scoped>

</style>
