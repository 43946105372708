import Vue from 'vue';
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'
import * as storage from '@/plugins/storage'
import * as session from '@/plugins/session'

Vue.use(VueRouter)
import { hasPermission } from '@/plugins/permissions'

const router = new VueRouter({
  routes,
  linkActiveClass: 'active',
  hashbang: false,
  mode: 'history'
})

async function beforeEach(to, from, next) {
  const LOGOUT_ROUTE_NAME = 'Logout'
  const FORGET_PASSWORD_ROUTE_NAME = 'forgot.password'
  const ENTITY_LOGIN_ROUTE_NAME = 'entity.login'
  const ENTITY_BUDGET_ROUTE_NAME = 'entity.budget'
  
  if (to.name === undefined) {
    return next()
  }
  let token = storage.getPuzlToken() || session.getPuzlToken()
  if (to.name === 'Login' && token) {
    next({ name: 'Dashboard' })
  }
  if (to.name === 'Login' && !token) {
    next()
  }
  if (
    [
      LOGOUT_ROUTE_NAME,
      FORGET_PASSWORD_ROUTE_NAME,
      ENTITY_LOGIN_ROUTE_NAME,
      ENTITY_BUDGET_ROUTE_NAME,
    ].includes(to.name)
  ) {
    return next()
  }
  if (!token) {
    next({ name: 'Login' })
    return
  }
  if (!store.getters['auth/getUser'] && token) {
    await store.dispatch('auth/loadSession')
  }
  if (to.meta.middleware === false) {
    return next()
  }
  let has_permission = false

  if (to.name === 'Dashboard' || to.name === 'Login') {
    has_permission = true
  }
  if (to.name === 'without.permission') {
    return next()
  }
  const user = store.getters['auth/getUser']
  if (!user.hasOwnProperty('permissions')) {
    storage.clearStorage()
    session.clearStorage()
    // return next('/login')
  }
  if (to.meta.permission) {
    if (hasPermission(to.meta.permission)) {
      has_permission = true
    }
  } else {
    const base_path = to.path.split('/')[2]
    if (hasPermission(base_path)) {
      has_permission = true
    }
  }
  if (!has_permission) {
    return next('/400')
  }
  next()
}

function afterEach(to) {
  Vue.nextTick(() => {
    document.title = to.meta.description || 'Puzl Place';
  });
}

router.beforeEach(beforeEach)
router.afterEach(afterEach)
export default router
