<template>
  <div>
    <modal :show.sync="modal" size="lg">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(save)"
            autocomplete="off"
          >
            <div>
              <div class="form-group row m-0 p-0">
                <div class="col-md-4">
                  <div class="pt-1 col-form-label form-control-label">
                    Status
                  </div>
                  <div class="row mt-n3">
                    <div class="col-md-5 pt-2 pr-2 pl-2">
                      <base-button outline block size="sm"
                                   :class="construction.status ? 'active' : ''"
                                   type="success"
                                   @click="handleChangeStatus(true)"
                      >
                        Prospecção
                      </base-button>
                    </div>

                    <div class="col-md-5 pt-2 pr-2 pl-2">
                      <base-button outline block size="sm"
                                   :class="construction.status ? '' : 'active'"
                                   type="danger"
                                   @click="handleChangeStatus(false)"
                      >
                        Inativo
                      </base-button>
                    </div>

                  </div>
                </div>

                <div class="col-md-2">
                  <div class="pb-1 col-form-label form-control-label">
                    Código
                  </div>
                  {{ construction.id }}
                </div>

                <div class="col-md-6 mt-n1">
                  <div class="pb-1 col-form-label form-control-label">
                    Nome da Construção
                    <span class="text-danger">*</span>
                  </div>
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm pb-1 mb-2">
                      <input
                        type="text"
                        v-model="construction.construction_name"
                        class="form-control form-control-sm"
                        maxlength="150"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mt-n2">
                <div class="col-md-6">
                  <div class="pb-1 col-form-label form-control-label">
                    Central Padrão
                    <span class="text-danger">*</span>
                  </div>
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <puzl-select
                        v-model="construction.company_plant_uuid"
                        :items="plants"
                        customKey="uuid"
                        :loading="loadingCompanyPlant"
                        :disabled="loadingCompanyPlant"
                        @input="selectedPlant"
                      />
                    </base-input>
                  </validation-provider>
                </div>

                <div class="col-md-6">
                  <div class="pb-1 col-form-label form-control-label">
                    Vendedor
                    <span class="text-danger">*</span>
                  </div>
                  <validation-provider rules="required">
                    <base-input input-classes="form-control-sm">
                      <puzl-select
                        v-model="construction.seller_uuid"
                        :items="users"
                        customKey="uuid"
                        :loading="loadingUsers"
                        :disabled="loadingUsers || !selected_plant"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <div class="col-md-6">
                  <div class="pb-1 col-form-label form-control-label">
                    Método de Prospecção
                    <span
                      class="btn btn-sm btn-success p-1"
                      @click.prevent="handleCreateProspectionMethod()"
                    >
                      <i class="fas fa-plus"></i> Novo
                    </span>
                  </div>
                  <base-input input-classes="form-control-sm pb-1 mb-1">
                    <puzl-select
                      v-model="construction.prospection_method_construction_id"
                      :items="prospection_methods"
                      label="description"
                      :disabled="loadingProscpection"/>
                  </base-input>
                </div>

                <div class="col-md-6">
                  <div class="pb-1 col-form-label form-control-label">
                    Tipo
                    <span class="btn btn-sm btn-success p-1" @click.prevent="handleCreateType()"><i
                      class="fas fa-plus"></i> Novo</span>
                  </div>
                  <base-input input-classes="form-control-sm pb-1 mb-1">
                    <puzl-select
                      v-model="construction.type_construction_id"
                      :items="types"
                      label="description"
                      :disabled="loadingTypes"/>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <div class="col-md-6">
                  <div class="pt-1 pb-0 mt-1 col-form-label form-control-label">
                    Restrição de acesso
                  </div>
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      v-model="construction.access_restriction"
                      class="form-control form-control-sm"
                    />
                  </base-input>
                </div>

                <div class="col-md-6">
                  <div class="pt-1 pb-0 mt-1 col-form-label form-control-label">
                    Volume total estimado
                  </div>
                  <base-input input-group-classes="input-group-sm pb-1 mb-1">
                    <input
                      inputmode="numeric"
                      v-model="construction.estimated_volume"
                      class="form-control form-control-sm"
                      v-mask="['#.#', '##.#', '###.#', '####.#', '#####.#', '######.#', '#######.#', '########.#']"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0">
                        <span style="text-transform: none">m</span><sup>3</sup>
                      </small>
                    </template>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1 mt-n2">
                <div class="col-md-6">
                  <div class="pb-1 col-form-label form-control-label">
                    Período previsto
                  </div>
                  <base-input input-group-classes="input-group-sm">
                    <input-date-picker @handleFilterDate="handleFilterDate" size="sm"/>
                  </base-input>
                </div>

                <div class="col-md-6">
                  <div class="pb-1 col-form-label form-control-label">
                    Prazo total
                  </div>
                  <base-input input-group-classes="input-group-sm pb-1 mb-1">
                    <input
                      type="text"
                      v-model="construction.total_days"
                      class="form-control form-control-sm"
                      disabled
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0">
                        dias
                      </small>
                    </template>
                  </base-input>
                </div>
              </div>

              <div class="card-header border-header-default-bottom p-1 mb-2 mt-2">
                <h6 class="h4 mb-0">Endereço</h6>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <div class="col-md-4">
                  <div class="pb-0 col-form-label form-control-label">
                    CEP<span class="text-danger">&nbsp;*</span>
                  </div>
                  <validation-provider rules="required|min:9" v-slot="{errors}">
                    <base-input input-group-classes="form-control-sm input-group-sm">
                      <input
                        type="text"
                        v-model="construction.postal_code"
                        class="form-control form-control-sm"
                        v-mask="['#####-###']"
                        v-on:blur="getAddressByCode"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>

                <div class="col-md-4">
                  <div class="pb-0 col-form-label form-control-label">
                    UF<span class="text-danger">&nbsp;*</span>
                  </div>
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <puzl-select
                        v-model="construction.state"
                        :items="states"
                        customKey="letter"
                        label="letter"
                        :disabled="checkAddress"/>
                    </base-input>
                  </validation-provider>
                </div>

                <div class="col-md-4 mt-1">
                  <div class="pb-0 pr-0 col-form-label form-control-label">
                    Cidade <span class="text-danger">&nbsp;*</span>
                  </div>
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <puzl-select
                        v-model="construction.city"
                        :items="cities"
                        label="title"
                        :disabled="checkAddress"/>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <div class="col-md-12">
                  <div class="pb-0 col-form-label form-control-label">
                    Endereço<span class="text-danger">&nbsp;*</span>
                  </div>
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="form-control-sm input-group-sm">
                      <input
                        type="text"
                        v-model="construction.address"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <div class="col-md-4">
                  <div class="pb-0 pr-0 col-form-label form-control-label">
                    N°<span class="text-danger">&nbsp;*</span>
                  </div>
                  <base-input input-group-classes="input-group-sm">
                    <input maxlength="5"
                           v-if="!without_number"
                           v-model="construction.number"
                           v-mask="'#####'"
                           inputmode="numeric"
                           class="form-control form-control-sm"
                    />
                    <input v-else maxlength="5"
                           v-model="construction.number"
                           disabled
                           type="text"
                           inputmode="numeric"
                           class="form-control form-control-sm"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0" style="text-transform: none">
                        <a href="#" @click.prevent="without_number = !without_number">
                          <i v-if="without_number" class="fa-solid fa-square-check"></i>
                          <i v-else class="fa-regular fa-square"></i>
                        </a> Sem número
                      </small>
                    </template>
                  </base-input>
                  <!--                  <validation-provider rules="required" v-slot="{errors}">-->
                  <!--                    <base-input input-group-classes="form-control-sm input-group-sm">-->
                  <!--                      <input-->
                  <!--                        type="text"-->
                  <!--                        v-model="construction.number"-->
                  <!--                        maxlength="10"-->
                  <!--                        input-group-classes="input-group-sm"-->
                  <!--                        class="form-control form-control-sm"-->
                  <!--                        :class="errors[0] ? 'is-invalid' : 'is-valid'"-->
                  <!--                      />-->
                  <!--                    </base-input>-->
                  <!--                    <template slot="append">-->
                  <!--                      <small class="input-group-sm p-0 m-0">-->
                  <!--                        /min. (por m<sup>3</sup>)-->
                  <!--                      </small>-->
                  <!--                    </template>-->
                  <!--                  </validation-provider>-->
                </div>
                <div class="col-md-4">
                  <div class="pb-0 col-form-label form-control-label">
                    Complemento
                  </div>
                  <base-input input-group-classes="form-control-sm input-group-sm">
                    <input
                      type="text"
                      v-model="construction.complement"
                      maxlength="20"
                      class="form-control form-control-sm"
                    />
                  </base-input>
                </div>

                <div class="col-md-4">
                  <div class="pb-0  pr-0 col-form-label form-control-label">
                    Bairro <span class="text-danger">&nbsp;*</span>
                  </div>
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="form-control-sm input-group-sm">
                      <input
                        type="text"
                        v-model="construction.district"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1 mt-1">

              </div>
            </div>

            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">
                Cancelar
              </base-button>
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                :loading="loadingSave"
              >
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
    <ModalCreateProspectionMethod ref="createProspectionMethod"/>
    <ModalCreateType ref="createType"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

const {formatErrorValidation} = require("@/plugins")
import axios from 'axios'
import ModalCreateProspectionMethod from '../../ProspectionMethod/Shared/_Create'
import ModalCreateType from '../../Type/Shared/_Create'
import InputDatePicker from "@/components/InputDatePicker"
import moment from "moment"
import {viacep} from "@/helpers";
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "ModalCreateConstruction",
  components: {
    ModalCreateProspectionMethod,
    ModalCreateType,
    InputDatePicker,
    viacep,
    PuzlSelect,
  },
  data() {
    return {
      modal: false,
      title: 'Obra',
      without_number: false,
      construction: {
        id: null,
        status: true,
        company_plant_uuid: null,
        seller_uuid: null,
        customer_construction_uuid: '',
        construction_name: '',
        web_site: '',
        access_restriction: '',
        prospection_method_construction_id: '',
        type_construction_id: '',
        estimated_volume: '',
        scheduled_beginning: '',
        scheduled_ending: '',
        total_days: '',
        defatul: true,
        alias: '',
        postal_code: '',
        district: '',
        state: '',
        city: '',
        address: '',
        number: '',
      },
      range: {
        start: new Date(),
        end: new Date(),
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      loadingSave: false,
      loadingProscpection: true,
      loadingTypes: true,
      loadingCities: true,
      loadingCompanyPlant: true,
      loadingUsers: false,
      selected_plant: false,
      checkAddress: false,
      sellerUsers: [],
    }
  },
  computed: {
    ...mapGetters({
      'prospection_methods': 'customerConstructionProspectionMethod/fetch',
      'types': 'customerConstructionType/fetch',
      'states': 'localizations/states',
      'cities': 'localizations/cities',
      'plants': 'plant/activeItems',
      'users': "user/fetch",
      $_user_logged: "auth/getUser",
    }),
  },
  watch: {
    'construction.scheduled_beginning': function () {
      this.getMonths()
    },
    'without_number' : function (val) {
      if (val) {
        this.construction.number = 'SN'
      }
      else {
        this.construction.number = ''
      }
    },
    'construction.scheduled_ending': function () {
      this.getMonths()
    },
    'construction.state': function (value) {
      this.getCities(value)
    },
  },
  methods: {
    handleChangeStatus(status) {
      this.construction.status = status;
    },
    handleCreateProspectionMethod() {
      this.$refs.createProspectionMethod.handleCreateModal()
    },
    handleCreateType() {
      this.$refs.createType.handleCreateModal()
    },
    closeModal() {
      this.modal = false
      this.checkAddress = false
    },
    handleCreateModal() {
      this.without_number = false
      this.construction = {
        id: null,
        status: true,
        customer_construction_uuid: this.$route.params.customer_construction_uuid,
        company_plant_uuid: null,
        seller_uuid: null,
        construction_name: '',
        web_site: '',
        access_restriction: '',
        prospection_method_construction_id: '',
        type_construction_id: '',
        estimated_volume: '',
        scheduled_beginning: '',
        scheduled_ending: '',
        total_days: '',
        default: true,
        alias: '',
        postal_code: '',
        district: '',
        state: '',
        city: '',
        address: '',
        number: '',
      }
      this.$store.dispatch('customerConstructionConstructions/getLastConstruction').then((response) => {
        this.construction.id = response.data;
        this.construction.id += 1;
      })
      this.modal = true
    },
    handleFilterDate(filter) {
      this.range = filter;
      this.construction.scheduled_beginning = moment(this.range.start).format("YYYY-MM-DD");
      this.construction.scheduled_ending = moment(this.range.end).format("YYYY-MM-DD");
    },
    save() {
      this.$Progress.start()
      this.loadingSave = true
      this.$store.dispatch('customerConstructionConstructions/add', this.construction)
        .then(response => {
          this.loadingSave = false
          this.modal = false
          this.$store.dispatch('customerConstruction/show', this.$route.params.customer_construction_uuid)
          this.$Progress.finish()
          this.$notify({type: response.error_type, message: response.message})
        })
        .catch(error => {
          if (error.status == 200) {
            this.$notify({
              type: 'danger',
              message: error.data.message
            })
          } else if (error.response && error.response.status === 422) {
            let message = formatErrorValidation(error.response.data.errors)
            this.$notify({
              type: 'danger',
              message: message
            })
          }
          this.$Progress.finish();
          this.loadingSave = false;
        })
    },
    getMonths() {
      let dt1 = new Date(this.construction.scheduled_ending)
      let dt2 = new Date(this.construction.scheduled_beginning)

      if (dt1 !== null && dt2 !== null) {
        let diference = dt1.getTime() - dt2.getTime()
        let diference_in_days = diference / (1000 * 3600 * 24)

        if (diference_in_days) {
          this.construction.total_days = diference_in_days
        }
      }
    },
    getCities(state) {
      this.$Progress.start();

      this.$store.dispatch('localizations/fetchCities', {uf: state})
        .then((response) => {
          this.loadingCities = false
          this.$Progress.finish()
        })
        .catch((error) => {
          this.loadingCities = false
          this.$Progress.finish()
        });
    },
    getAddressByCode() {
      this.$Progress.start();
      let postal_code = this.construction.postal_code.replace(/[^\d]+/g, '')
      if (postal_code) {
        viacep(postal_code).then((response) => {
          if (!response.data.erro) {
            this.construction.state = response.data.uf
            this.construction.address = response.data.logradouro
            this.construction.state = response.data.uf
            this.construction.district = response.data.bairro
            this.construction.city = response.data.localidade
            this.checkAddress = true
            this.$notify({type: 'success', message: 'Dados carregados com sucesso.'})
            this.$Progress.finish()
          } else {
            this.$notify({type: 'danger', message: 'Não foi possível encontrar o endereço.'});
            this.checkAddress = false;
            this.$Progress.finish()
          }
        }).catch((error) => {
          this.$notify({type: 'danger', message: 'Não foi possível encontrar o endereço.'});
        })
      }
    },
    filterAllocatedSellers() {
      if (this.$_user_logged.view_sellers[0] === "all") {
        this.sellerUsers = this.users.filter(item => item.is_seller === 1);
      } else {
        this.sellerUsers = this.users.filter(user => {
          return this.$_user_logged.view_sellers.includes(user.id);
        });
      }
    },
    selectedPlant() {
      this.construction.seller_uuid = null;
      if (this.construction.company_plant_uuid) {
        this.selected_plant = true;
        // Carrega vendedores alocados a central selecionada.
        this.loadingUsers = true;
        this.$store.dispatch("user/getSellersAllocatedByPlant", this.construction.company_plant_uuid).then(response => {
          this.loadingUsers = false;
          // Se existir apenas um vendedor no select, ele será selecionado automaticamente.
          if (response.data.length === 1) {
            this.construction.seller_uuid = response.data[0].uuid;
          }
        });
      } else {
        this.selected_plant = false;
      }
    },
  },
  mounted() {
    this.$refs.formValidator.validate()
    this.$store.dispatch('plant/fetchItemsActive').then(() => {
      this.loadingCompanyPlant = false
    });
    this.$store.dispatch('customerConstructionProspectionMethod/fetchItems').then(() => this.loadingProscpection = false);
    this.$store.dispatch('customerConstructionType/fetchItems').then(() => this.loadingTypes = false);
    this.$store.dispatch('localizations/fetchStates');
  },
  beforeUpdate() {
    // Se existir apenas uma central no select, ela será selecionado automaticamente.
    if (this.plants.length === 1) {
      this.construction.company_plant_uuid = this.plants[0].uuid;
      if (this.construction.company_plant_uuid) {
        this.selected_plant = true;
        this.loadingUsers = true;
        this.$store.dispatch("user/getSellersAllocatedByPlant", this.construction.company_plant_uuid).then(response => {
          this.loadingUsers = false;
          // Se existir apenas um vendedor no select, ele será selecionado automaticamente.
          if (response.data.length === 1) {
            this.construction.seller_uuid = response.data[0].uuid;
          }
        });
      }
    }
  },
}
</script>

<style scoped>

</style>
