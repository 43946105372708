<template>
  <div class="mt-3">
    <section class="mt-3">
      <div class="d-flex flex-column">
        <FirstNode v-for="item in items" :key="item.id" :node="item" :company_plants_id="company_plants_id" class="my-2" />
      </div>
    </section>
  </div>
</template>

<script>
import FirstNode from './_FirstNode.vue';
export default {
  name: "TreeView",
  components: { FirstNode },
  props: {
    items: {
      type: Array,
      required: false
    },
    company_plants_id: {
      type: Array,
      required: false
    }
  },
};
</script>