<template>
  <div>
    <modal :show.sync="modal.create" size="lg">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <div class="text-dark">
          {{ entity.name }}
        </div>
        <div class="text-dark">
          {{ entity.document }}
        </div>
        
        <div class="card-header border-header-primary-bottom mb-3 p-2"></div>

        <div class="row">
          <div class="col-3 mb-3">
            <base-button block size="lg" type="success" 
              @click="handleModalCreateEntityRegistry(entity.id, entity.uuid, entity.name, entity.document)">
              <i class="fas fa-plus"></i> NOVO
            </base-button>
          </div>
        </div>

        <MultiFilter @fetch="init" :filter="filter" :without_filter="true" />

        <div class="row card-wrapper">
          <div class="col-12" v-for="(item, index) in $_entityRegistries" :key="index">
            <card>
              <div class="row">
                <div class="col-3" v-if="item.contract_proposal_code">
                  <h3>
                    {{ item.contract_proposal_code }}
                  </h3>
                </div>

                <div v-if="item.contract_proposal_code" class="offset-6 col-3">
                  <h5>
                    {{ item.user_name }}
                  </h5>
                  <h5 class="mt-n2">
                    {{ item.created_at | parseDate("DD MMM YYYY hh:mm") }}
                  </h5>
                </div>
                <div v-else class="col-6">
                  <h5>
                    {{ item.user_name }}
                    ———
                    {{ item.created_at | parseDate("DD MMM YYYY") }}
                    ———
                    {{ item.created_at | parseDate("hh:mm") }}
                  </h5>
                </div>
                
                <div class="col-12 mt-n3" v-if="item.construction_name">
                  <h5>
                    {{ item.construction_name }}
                  </h5>
                </div>
                <div class="col-12" v-if="item.invoice_id">
                  <h5>
                    NFS-e: {{ item.nfse_number }} | Fatura: {{ item.invoice_id }}
                  </h5>
                </div>

                <div class="col-12">
                  <div class="card-header border-header-primary-bottom mb-3 p-2"></div>
                </div>

                <div class="col-12">
                  <h5>
                    Descrição
                  </h5>
                  <h6>
                    {{ item.description }}
                  </h6>
                </div>
              </div>
            </card>
          </div>
        </div>
        <pagination @navegate="navegate" :source="source"/>
      </div>
      <ModalCreateEntityRegistry ref="modalCreateEntityRegistry" @storedEntityRegistry="storedAfterEntityRegistration" />
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import MultiFilter from "@/components/Utils/MultiFilterV2";
import ModalCreateEntityRegistry from "./_ModalCreateEntityRegistry";
import Pagination from "@/components/Utils/Pagination.vue";

export default {
    name: "ModalList",
    components: {
      MultiFilter,
      ModalCreateEntityRegistry,
      Pagination,
    },
    data() {
      return {
        modal: {
          title: 'Registros do cliente',
          create: false,
        },
        entity: {
          id: null,
          uuid: '',
          name: '',
          document: '',
        },
        filter: {},
        source: null,
        params: {
          page: 1,
          per_page: 10
        },
      };
    },
    computed: {
      ...mapGetters({
        $_entityRegistries: "entityRegistry/fetch"
      }),
    },
    methods: {
      init(filter = {}) {
        this.$Progress.start();
        let loader = this.$loading.show();
        this.filter = filter;
        this.$store
          .dispatch("entityRegistry/getRegistries", {
            filter: this.filter,
            page: this.params.page,
            entityId: this.entity.id,
          })
          .then((response) => {
            this.source = response;
          })
          .catch(error => {
            this.$notify({ type: error.data.error_type, message: error.data.message });
          }).finally(() => {
            this.$Progress.finish();
            loader.hide();
          });
      },
      navegate(page) {
        this.params.page = page;
        this.init();
      },
      closeModal() {
        this.modal.create = false;
      },
      /**
       * @param {number} entityId
       * @param {string} entityUuid
       * @param {string} name
       * @param {string} document
       */
      handleCreateModal(entityId, entityUuid, name, document) {
        this.entity = {
          id: entityId,
          uuid: entityUuid,
          name: name,
          document: document,
        };
        this.params.page = 1;
        this.init();
        this.modal.create = true;
      },
      /**
       * @param {number} entityId
       * @param {string} entityUuid
       * @param {string} name
       * @param {string} document
       */
      handleModalCreateEntityRegistry(entityId, entityUuid, name, document) {
        this.$refs.modalCreateEntityRegistry.handleCreateModal(entityId, entityUuid, name, document);
      },
      /**
       * Envia o registro cadastrado para o listar clientes e fornecedores ataulizar no card.
       * 
       * @param {object} entityRegistry
       */
      storedAfterEntityRegistration(entityRegistry) {
        this.$emit('storedEntityRegistry', entityRegistry);
      },
    },
};
</script>

<style scoped></style>
