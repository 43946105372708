<template>
  <div>
    <modal :show.sync="modal" size="lg">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <base-alert v-if="$_taskAttachment.length && !loadingSkeleton">
          <span class="alert-inner--text"><strong> Atenção!</strong> Clique no arquivo desejado para visualizar os detalhes.</span>
          <br>
          <span class="alert-inner--text"> O número máximo de anexos por tarefa é {{ maxFiles }}.</span>
        </base-alert>
        <div class="row">
          <div class="col-3 mb-2">
            <base-button block size="lg" type="success" @click="handleCreateAttachments(taskId)">
              <i class="fas fa-plus"></i> NOVO
            </base-button>
          </div>
        </div>
        <PuzlEmptyData v-if="!$_taskAttachment.length && !loadingSkeleton"></PuzlEmptyData>
        <div class="row" v-if="!loadingSkeleton">
          <div v-for="item in $_taskAttachment" class="col-md-4">
            <div class="card">
              <a href="#" class="text-center" @click.prevent="showModalImgView(item)">
                <img style="height: 270px!important; width:80%; margin:10px 25px;"
                  v-if="item.ext != 'pdf'" class="img" :src="item.url"
                />
                <pdf
                  :scale.sync="scale" style="height: 270px!important;width:80%;margin:10px 25px;"
                  :annotation="true"
                  v-if="item.ext == 'pdf'" :src="item.url" :page="1"
                />
              </a>
              <div class="card-footer text-truncate">
                <div :title="item.file_name" class="numeric">
                  {{item.file_name}}
                </div>
                <div class="text-muted" style="font-size: 11px;">
                </div>
                <a href="#" @click.prevent="handleDelete(item.id)" class="px-1">
                  <i class="fa-solid fa-x text-danger mr-1"></i>
                </a>
                <a href="#" @click.prevent="download(item)" class="px-1">
                  <i style="font-size: 1.3rem" class="fa-solid fa-download text-primary"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <ModalAttachmentView :selected="selected" :show.sync="showImgView"/>
    <ModalTaskAttachment @storedAttachment="storedAttachment" ref="modalTaskAttachment" />

  </div>
</template>

  <script>
  import {mapGetters} from "vuex";
  import PuzlEmptyData from "@/components/PuzlEmptyData";
  import pdf from 'pdfvuer';
  import {base_url_ms} from '@/plugins';
  import ModalAttachmentView from "@/components/ModalAttachmentView";
  import ModalTaskAttachment from "./_ModalTaskAttachment";
  export default {
    name: "modalTaskAttachmentList",
    components: {
      PuzlEmptyData,
      pdf,
      ModalAttachmentView,
      ModalTaskAttachment,
    },
    data () {
      return {
        modal: false,
        title: 'Anexos da tarefa',
        loadingSkeleton: false,
        base_url_ms: base_url_ms(),
        scale: 'page-width',
        selected: null,
        showImgView: false,
        taskId: null,
        createdByUserId: null,
        maxFiles: 3
      }
    },
    computed: {
      ...mapGetters({
        $_taskAttachment: "taskAttachment/show",
      }),
    },
    methods: {
      init() {
        this.$Progress.start();
        this.loadingSkeleton = true;
        let loader = this.$loading.show();
        this.$store
          .dispatch("taskAttachment/getByTaskId", this.taskId).then(() => {})
          .catch(error => {
            this.$notify({ type: error.data.error_type, message: error.data.message });
          }).finally(() => {
            this.$Progress.finish();
            this.loadingSkeleton = false;
            loader.hide();
            this.modal= true;
          });
      },
      closeModal () {
        this.modal= false;
      },
      /**
       * @param {number} id
       * @param {number} createdByUserId
       */
      handleCreateModal (id, createdByUserId) {
        this.taskId = id;
        this.createdByUserId = createdByUserId;
        this.init();
      },
      /**
       * @param {number} taskId
       */
      storedAttachment(taskId) {
        this.$emit('storedAttachment', taskId);
        this.init();
      },
      async download(item) {
        let loader = this.$loading.show()
        const url = this.base_url_ms + 'download-s3?url=' + item.url;
        this.$store.dispatch('exports/download', url).then(async response => {
          let blob = new Blob([response], {type: "application/pdf"});
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", item.file_name);
          await link.click()
        });
        loader.hide()
      },
      showModalImgView(item) {
        this.selected = item;
        this.showImgView = true;
      },
      closeModalImgView() {
        this.showImgView = false;
      },
      handleDelete(id) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$notify({ type: 'info', message: 'Estamos trabalhando em sua solicitação.' });
          this.$store.dispatch('taskAttachment/destroy', id)
              .then((response) => {
                this.$notify({ type: 'success', message: response.message });
                this.$emit('deletedLastAttachment', this.taskId);
              })
              .catch(error => {
                this.$notify({ type: error.data.error_type, message: error.data.message });
              }).finally(() => {
                this.$Progress.finish();
              });
        }
        }).catch(() => this.$Progress.finish());
      },
      handleCreateAttachments(taskId) {
        this.$refs.modalTaskAttachment.handleCreateModal(taskId);
      },
      storedAttachment(taskId) {
        this.$emit('storedAttachment', taskId);
        this.init();
      },
    },
  }
  </script>

  <style scoped>
  </style>