import productServiceHubTypeStoreActions from "./productServiceHubTypeStoreActions";
import productServiceHubTypeStoreGetters from "./productServiceHubTypeStoreGetters";
import productServiceHubTypeStoreMutations from "./productServiceHubTypeStoreMutations";
import { productServiceHubTypeStoreStateKeys } from "./productServiceHubTypeStoreStateKeys";

const productServiceHubTypeStore = {
  ...productServiceHubTypeStoreActions,
  ...productServiceHubTypeStoreGetters,
  ...productServiceHubTypeStoreMutations,
  productServiceHubTypeStoreStateKeys,
};

export default productServiceHubTypeStore;
