<template>
  <div>
    <modal :show.sync="modal" size="md">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <div class="row align-items-center pb-0 mb-3 ml-0"
             v-if="contractProposalCode && constructionName && customerConstructionName">
          <div class="col-md-12">
            <div class="checklist-item checklist-item-primary">
              <div class="checklist-info">
                <h5><span class="text-dark">Código:</span> {{ contractProposalCode }}</h5>
                <h5><span class="text-dark">Cliente:</span> {{ constructionName }}</h5>
                <h5><span class="text-dark">Obra:</span> {{ customerConstructionName }}</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row m-0 p-0 mb-3 limited-row">
          <label
            class="col-md-5 pt-2 pb-0 col-form-label form-control-label"
          >
            Plano de contas
          </label>
          <div class="col-md-7">
            <base-input input-classes="form-control-sm">
              <puzl-select
                placeholder="Plano de Contas"
                v-model="single.chart_account_id"
                :items="$_chart_accounts"
                :clearable="false"
                :labelMask="true"
                label="$code$ $name$"
                :selectable="(i) => i.extra_field.length"
                extraField="parents"
                :loading="loadingChartAccount"
                :disabled="loadingChartAccount"/>
            </base-input>
          </div>
        </div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <div>
            <div class="form-group row m-0 p-0 mb-2">
              <label
                class="col-md-5 pb-0 col-form-label form-control-label"
              >
                Central
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required">
                  <base-input>
                    <puzl-select
                      v-model="single.company_plant_id"
                      :items="plants"
                      @input="setCompanyPlantIssuers()"/>
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0 mb-3 limited-row">
              <label
                class="col-md-5 pt-2 pb-0 col-form-label form-control-label"
              >
                CNPJ Emissor
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                    <puzl-select
                      :disabled="!single.company_plant_id"
                      v-model="single.company_plant_issuer_id"
                      :items="company_plant_issuers"
                      label="business_name"/>
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <label
                class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
              >
                Descrição
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="single.description" class="form-control form-control-sm"/>
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0 ">
              <label
                class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
              >
                Data
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required">
                  <input-date-time-picker v-model="single.date_at" :mode="'date'"/>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <label
                class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
              >
                Quantidade
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                    <input-limit
                      :start_value="single.quantity"
                      :options="{min: 0, decimals:1}"
                      max_length="6"
                      input_group_classes="input-group-sm pb-0"
                      v-model="single.quantity"
                      @value="single.quantity = $event"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <label
                class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
              >
                Preço
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0">
                        R$
                      </small>
                    </template>
                    <input
                      v-money="money"
                      maxlength="13"
                      inputmode="numeric"
                      v-model="single.value"
                      type="text"
                      class="form-control form-control-sm"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <label
                class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
              >
                Total
              </label>
              <div class="col-md-7">
                <base-input input-group-classes="input-group-sm">
                  <template slot="prepend">
                    <small class="input-group-sm p-0 m-0">
                      R$
                    </small>
                  </template>
                  <input
                    v-money="money"
                    disabled
                    v-model="single.total"
                    type="text"
                    class="form-control form-control-sm"
                  />
                </base-input>
              </div>
            </div>
            <!--            <div class="form-group row m-0 p-0">-->
            <!--              <label-->
            <!--                class="col-md-5 pb-0 mb-1 col-form-label form-control-label"-->
            <!--              >-->
            <!--                Total-->
            <!--              </label>-->
            <!--              <div class="col-md-7">-->
            <!--                <validation-provider rules="required">-->
            <!--                  <base-input input-group-classes="input-group-sm">-->
            <!--                    <template slot="prepend">-->
            <!--                      <small class="input-group-sm p-0 m-0">-->
            <!--                        R$-->
            <!--                      </small>-->
            <!--                    </template>-->
            <!--                    <input-->
            <!--                      v-money="money"-->
            <!--                      disabled-->
            <!--                      maxlength="10"-->
            <!--                      inputmode="numeric"-->
            <!--                      v-model="single.total"-->
            <!--                      type="text"-->
            <!--                      class="form-control form-control-sm"-->
            <!--                    />-->
            <!--                  </base-input>-->
            <!--                </validation-provider>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
          <div class="modal-footer">
            <base-button type="secondary" @click="closeModal()"
            >Cancelar
            </base-button
            >
            <base-button
              type="success"
              native-type="submit"
              :loading="loading"
              @click.prevent="save"
              :disabled="invalid || single.total === '0,00'"
            >Salvar
            </base-button>
          </div>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {VMoney} from 'v-money';
import PuzlSelect from "@/components/PuzlSelect";
import moment from 'moment';
import InputDateTimePicker from "@/components/InputDateTimePicker";
import InputLimit from "@/components/Utils/InputLimit";

export default {
  components: {
    InputDateTimePicker,
    PuzlSelect,
    InputLimit,
  },
  name: "CreateSingle",
  data() {
    return {
      modal: false,
      loading: false,
      single: {
        description: '',
        date_at: '',
        chart_account_id: null,
        quantity: '',
        value: 0,
        company_plant_id: '',
        contract_proposal_id: null,
        company_plant_issuer_id: null,
      },
      loadingChartAccount: false,
      company_plant_issuers: [],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false
      },
      title: "Valor Avulso",
    };
  },
  directives: {money: VMoney},
  computed: {
    ...mapGetters({
      contract_proposal: "contractProposal/show",
      plants: "plant/activeItems",
      $_chart_accounts: "chartAccount/fetch",
    })
  },
  props: {
    defaultDate: {
      type: Date,
      required: false
    },
    contractProposalId: {
      type: Number,
      default: null,
    },
    contractProposalCode: {
      type: String,
      default: null,
    },
    constructionName: {
      type: String,
      default: null,
    },
    customerConstructionName: {
      type: String,
      default: null,
    },
  },
  mounted() {
    this.init()
    this.getChartAccounts()
    this.$watch('single.value', () => this.sumQuantityAndValue())
    this.$watch('single.quantity', () => this.sumQuantityAndValue())
  },
  methods: {
    getChartAccounts() {
      this.$store.dispatch("chartAccount/fetchItems", {filter: {type: 0}}).then(() => {
        this.$_chart_accounts.sort((a, b) => parseInt(a.code.substring(0, 1)) - parseInt(b.code.substring(0, 1)));
        this.loadingChartAccount = false;
      });
    },
    init() {
      this.$store.dispatch("plant/fetchItemsActive").then(() => {
        this.loadingPlant = false;
      });
    },
    setCompanyPlantIssuers() {
      this.single.company_plant_issuer_id = null
      const selected_company_plant = this.plants.find(item => item.id === this.single.company_plant_id)
      this.company_plant_issuers = selected_company_plant.company_issuers
      const default_plant_issuer = this.company_plant_issuers.find((item) => item.default === 1)
      if (default_plant_issuer) {
        this.single.company_plant_issuer_id = default_plant_issuer.id
      }

    },
    sumQuantityAndValue() {
      let {value} = this.single
      value = value.replaceAll(".", "")
        .replace(",", ".");
      this.single.total = (value * this.single.quantity || 0).toFixed(2)
    },
    closeModal() {
      this.modal = false;
    },
    openModal() {
      this.modal = true
      this.single = {
        description: '',
        date_at: moment().format('YYYY-MM-DD'),
        quantity: '',
        value: 0,
        company_plant_id: '',
        contract_proposal_id: null,
      }
    },
    save() {
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.loading = true
      this.single.contract_proposal_id = this.contractProposalId
        ? this.contractProposalId
        : this.contract_proposal.id;
      const params = {...this.single}
      params.date_at = moment(this.single.date_at).format('YYYY-MM-DD')
      this.$store.dispatch('detached/add', params).then(response => {
        this.loading = false
        this.modal = false
        this.$notify({type: response.error_type, message: response.message})
        this.$emit('fetch')
      }).catch(error => {
        this.loading = false
        this.$notify({type: 'danger', message: error.data.message})
      })
    },
  },
};
</script>

<style></style>
