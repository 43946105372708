<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body pb-2">
        <div class="form-group row m-0 custom-dashboard">
          <div class="col-md-4 p-1 mb-2 mb-md-0">
            <InputDatePicker :filterable="4" ref="period" :defaultRange="defaultRange" @handleFilterDate="handleFilterDate" />
          </div>
          <!-- Central -->
          <div class="col-md-3 p-1 select-lg mb-3 mb-md-0">
            <div>
              <el-select
                collapse-tags
                @change="handleFilter"
                :loading="loadingPlant"
                v-model="filter.company_plant_ids"
                placeholder="Centrais"
                filterable
                multiple
              >
                <el-option v-for="plant in plants" :key="plant.id" :label="plant.name" :value="plant.id"></el-option>
              </el-select>
            </div>
          </div>

          <!-- Cliente/Obra -->
          <div class="col-md-3 p-1 select-lg mb-3 mb-md-0">
            <el-select
              collapse-tags
              @change="handleFilter(false)"
              :loading="loadingCustomerConstruction"
              v-model="filter.constructions_uuids"
              placeholder="Cliente / Obra"
              filterable
              multiple
            >
              <el-option
                v-for="customerConstruction in customerConstructions"
                :key="customerConstruction.id"
                :label="customerConstruction.name"
                :value="customerConstruction.uuid"
              >
              </el-option>
            </el-select>
          </div>

          <!-- Obra -->
          <div class="col-md-2 p-1">
            <base-button title="Limpar filtros" @click.prevent="clearFilter" block type="secondary">
              <i class="fas fa-1x fa-eraser"></i>
            </base-button>
          </div>

          <div class="col-md-3 p-1 mt-3 mb-2">
            <base-button
              :disabled="loading"
              @click.prevent="triggerValueType"
              block
              size="md"
              :type="valueType === 'final' ? 'primary' : 'secondary'"
            >
              VALOR LÍQUIDO
            </base-button>
          </div>
          <div class="col-md-3 p-1 mt-3">
            <base-button
              :disabled="loading"
              @click.prevent="triggerValueType"
              block
              size="md"
              :type="valueType === 'total' ? 'primary' : 'secondary'"
            >
              VALOR BRUTO
            </base-button>
          </div>
          <div class="col-md-3 p-1 mt-3 v-select-financial-dashboard"> 
            <puzl-select
              v-model="filter.bank_account_ids"
              :items="$_bank_accounts"
              :multiple="true"
              :loading="loadingBankAccount"
              @input="handleFilter(false)"
              placeholder="Conta Bancária"
            />
          </div>
          <div class="col-md-3 p-1 text-right mt-3">
            <base-button @click="config.dark_mode = !config.dark_mode" type="default">
              <span class="btn-label"><i :class="config.dark_mode ? 'fa-2x fas fa-moon' : 'fa-2x far fa-moon'"></i></span>
              MODO ESCURO
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputDatePicker from "@/components/InputDatePicker";
import { mapGetters } from "vuex";
import PuzlSelect from "@/components/PuzlSelect"

export default {
  name: "DashboardFilter",
  data() {
    return {
      filter: {
        bank_account_ids: [],
        range: {
          start: null,
          end: null,
        },
        company_plant_ids: [],
        constructions_uuids: [],
      },
      loadingPlant: true,
      loadingCustomerConstruction: true,
      loadingBankAccount:true,
      config: {
        dark_mode: false,
      },
      defaultRange: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      },
    };
  },
  components: {
    InputDatePicker,
    PuzlSelect,
  },
  watch: {
    "config.dark_mode"() {
      this.$emit("changeTheme");
    },
  },
  computed: {
    ...mapGetters({
      plants: "plant/activeItems",
      customerConstructions: "customerConstruction/fetch",
      valueType: "financialDashboard/valueType",
      loading: "financialDashboard/loading",
      $_bank_accounts: "bankAccount/fetch",
    }),
  },
  methods: {
    handleFilterDate(filter) {
      this.filter.range = filter;
      this.handleFilter();
    },
    handleFilter(customerConstruction = true) {
      if (!this.filter.range.start) {
        this.filter.range = this.defaultRange;
      }
      if (customerConstruction) {
        this.getCustomerConstructions();
      }
      this.$emit("fetch", this.filter);
    },
    clearFilter() {
      this.filter = {
        range: {
          start: null,
          end: null,
        },
        company_plant_ids: [],
        constructions_uuids: [],
      };
      this.$refs.period.resetDate();
      this.handleFilter();
    },
    getCustomerConstructions() {
      this.loadingCustomerConstruction = true;
      if (!this.filter.range.start) {
        this.filter.range = this.defaultRange;
      }
      this.$store.dispatch("customerConstruction/getByPeriodAndCompanyPlant", { filter: this.filter }).then(() => {
        this.loadingCustomerConstruction = false;
      });
    },
    triggerValueType() {
      this.$store.dispatch("financialDashboard/triggerValueType");
    },
  },
  mounted() {
    this.$store.dispatch("plant/fetchItemsActive").then(() => {
      this.loadingPlant = false;
    });
    this.$store.dispatch("bankAccount/fetchItems", {filter:{status:true}}).then(() => {
      this.loadingBankAccount = false;
    });
    this.getCustomerConstructions();
  },
};
</script>

<style>
.col-date-picker {
  max-height: 29px !important;
}

.label-config-control {
  padding-top: 0.2rem !important;
}

.v-select-financial-dashboard .vs__dropdown-toggle {
  min-height: 43px;
  background: white!important;
}
.v-select-financial-dashboard .vs__search {
  height: 100%;
}

.v-select-financial-dashboard  .vs__selected {
  height: 33px;
}
</style>
