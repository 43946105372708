<template>
<div>
    <base-header class="bg-gray-content">
        <div class="row align-items-center py-4">
            <div class="col-lg-6 col-7">
                <h6 class="h2 text-gray d-inline-block mb-0">{{ $route.name }}</h6>
                <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4 text-gray">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i></a></li>
                        <li class="breadcrumb-item"><a href="#">Tecnologia</a></li>
                        <li class="breadcrumb-item" aria-current="page">{{ $route.name }}</li>
                    </ol>
                </nav>
            </div>
        </div>
        <!-- Header Menus -->
        <div class="row">
            <div class="col-md-2 mb-3">
                <base-button class="pb-3 pt-3 text-uppercase" block size="lg" type="success" @click.prevent="handleCreateAdjustment">
                  <i class="fas fa-plus"></i> novo
                </base-button>
            </div>
            <div class="col-md-10">
                <div class="row">
                    <div class="col-md-3 col-lg-2 col-xlg-2 ml-3 mr-3 mb-3 ml-0 p-0">
                        <base-button class="pb-3 pt-3 text-uppercase" block size="lg" type="primary" @click.prevent="handleCreateLimitAdjustment">
                            <i class="fas fa-arrows-alt-v"></i> limite
                        </base-button>
                    </div>
                      <div class="col-md-3 col-lg-2 col-xlg-2 ml-3 mr-3 mb-3 ml-0 p-0">
                        <router-link :to="{ path:'/technology/adjustments'}">
                            <base-button class="pb-3 pt-3 text-uppercase" block size="lg" type="light">
                                <i class="fas fa-undo-alt"></i> voltar
                            </base-button>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </base-header>

    <div class="container-fluid">

    </div>

    <list-adjustment-concrete-cycle v-bind:added="this.added"></list-adjustment-concrete-cycle>
    <modal-create-adjustment ref="ModalCreateAdjustment"></modal-create-adjustment>
    <modal-limit-setting-create ref="ModalLimitSettingCreate"></modal-limit-setting-create>
    <modal-edit-adjustment ref="ModalEditAdjustment"> </modal-edit-adjustment>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'
import ListAdjustmentConcreteCycle from './Shared/_List'
import ModalCreateAdjustment from './Shared/_ModalCreateAdjustment'
import ModalLimitSettingCreate from './Shared/_ModalLimitSettingCreate'
import ModalEditAdjustment from './Shared/_Edit'

export default {
    name: "IndexAdjustmentConcreteCycle",
    components: {
        ListAdjustmentConcreteCycle,
        ModalCreateAdjustment,
        ModalLimitSettingCreate,
        ModalEditAdjustment
    },
    data() {
        return {
            added: false,
        }
    },
    computed: {
        ...mapGetters({

        }),
    },
    methods: {

        handleCreateAdjustment() {
            this.$refs.ModalCreateAdjustment.handleCreateModal()
        },
        handleCreateLimitAdjustment() {
            this.$refs.ModalLimitSettingCreate.handleCreateModal()
        },
        handleEditAdjustment(uuid) {
            this.$refs.ModalEditAdjustment.handleEditModal(uuid)
        }
    },
    created() {},
    mounted() {
        EventBus.$on("getCycleAdjustments", () => {
            this.added = !this.added
        });
        EventBus.$on("editConcreteCycleAdjustment", (uuid) => {
            this.handleEditAdjustment(uuid)
        });
        EventBus.$on("updatedConcreteCycleAdjustment", () => {
            this.added = !this.added
        });
    }
};
</script>

<style></style>
