import { BaseListFilterType, initBaseListFilterType } from "../../../../shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} CmcAdjustmentListFilterExtType
 * @property {null|1|2|3} status - 1-Com Ajuste, 2-Sem Ajuste, 3-Indisponível ,null-Todos
 * @property {null|string} cmc_group_uuid
 * @property {null|string} cmc_category_uuid
 * @property {null|number[]} product_ids
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & CmcAdjustmentListFilterExtType)} CmcAdjustmentListFilterType
 */
export const CmcAdjustmentListFilterType = {};

/**
 * Inicializar CmcAdjustmentListFilterType
 *
 * @returns {CmcAdjustmentListFilterType}
 */
export const initCmcAdjustmentListFilterType = () => {
  return {
    ...initBaseListFilterType(),
    order_by: [
      { column: "cmc_adjustments.id", is_desc: false },
    ],
    custom_search: {
      columns: [
        "cmc_groups.group_name",
        "cmc_products.product_name",
        "cmc_suppliers.supplier_name",
        "cmc_supplier_origins.origin_name",
        "cmc_groups.group_name",
        "company_plant_charge_point_locations.name",
        "company_plant_charge_points.name"
      ],
      values: [],
    },
    status: null,
    cmc_group_uuid: null,
    cmc_category_uuid: null,
    product_ids: null
  };
}
