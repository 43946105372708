<template>
  <div>
    <!-- Cabeçalho da Página -->
      <!-- Botões do Topo -->
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-md-4">
          <h2 style="font-size: 1.2rem !important;" class="text-warning">
            <img height="30px" src="/img/icons/exchange.png" class="mr-3"/>
            Conciliação Bancária
          </h2>
        </div>
        <div class="col-md-8 pr-4">
          <div class="row d-flex justify-content-end">
            <div class="mb-md-0 mb-2 col-md-3 px-1">
              <base-button type="success"
                block
                style="border-radius: 8px !important;"
                @click.prevent="handleCreate()">
              <img height="19" src="https://img.icons8.com/ios/100/FFFFFF/plus-math--v1.png" alt="plus-math--v1" class="mr-1 mt-n1"/>
                NOVO
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </base-header>

    <!-- Lista de Conciliações -->
    <list-bank-reconciliation></list-bank-reconciliation>

    <!-- Modal de Importação de Arquivo OFX -->
    <modal-create ref="ModalCreate"></modal-create>
    <ModalImportTextFile ref="modalImportTextFile" />
  </div>
</template>

<script>
import BackButton from "@/components/Utils/BackButton.vue"
import ListBankReconciliation from './Shared/_List'
import ModalCreate from "./Shared/_ModalCreate.vue"

export default {
  name: "BankReconciliation",
  components: {
    BackButton,
    ListBankReconciliation,
    ModalCreate,
  },
  methods: {
    handleCreate () {
      this.$refs.ModalCreate.handleCreate()
    },
  }
};
</script>

<style></style>
