<template>
  <ul>
    <tree-node v-for="item in items" :key="item.uuid" :node="item" @init="init"></tree-node>
  </ul>
</template>

<script>
import TreeNode from './_TreeNode.vue';

export default {
  name: 'TreeView',
  components: { TreeNode },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    init() {
      this.$emit('init');
    }
  }
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding-left: 20px;
}
</style>
