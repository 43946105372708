<template>
   <CreateSchedule :bySeller="true"/>
</template>

<script>
import CreateSchedule from "../../Shared/_Create.vue";

export default {
  name: "CreateContractProposalSchedule",
  components: {
    CreateSchedule,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style></style>