<template>
  <div class="d-flex w-100">
    <i class="fa fa-clock m-0 text-light vc-custom-clock-icon align-middle pt-2 pl-2 ml-1"/>
    <v-date-picker
      class="w-100"
      :popover="{ placement: $screens({ default: 'bottom', md: 'right' }), visibility: disabled ? 'none' : 'hover'}"
      ref="calendar"
      :max-date="disableMaxDate"
      :min-date="disableMinDate"
      mode="date"
      locale="pt-BR" @input="handleInput"
      :value="moment(value).format('YYYY-MM-DD 00:00:00')">
      <template v-slot="{ inputValue, inputEvents }">
        <input
          style="height: 30px"
          :disabled="disabled"
          class=" border rounded focus:outline-none focus:border-blue-300 vc-custom-input-datetime"
          :value="inputValue"
          v-on="inputEvents"
        />
        <i
          v-show="!disabled"
          role="button"
          @click.prevent="clearInput"
          style="margin-left:-1rem!important;position:absolute!important"
          class="fas fa-times m-0 text-light vc-custom-clock-icon align-middle pt-2 hoverable "
        />
      </template>
    </v-date-picker>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  name: "InputDatePicker",
  data() {
    return {
      moment: moment,
      sizePercentage: {
        'sm': 86,
        'small': 92,
        'md': 119,
        'lg': 180
      },
    };
  },
  props: {
    size: {
      type: String,
      default: 'md'
    },
    value: {
      type: [String, Date],
      default: () => null,
      description: "Valor"
    },
    disabled: {
      type: Boolean,
      default: () => false,
      description: "Desabilitar input"
    },
    disableMaxDate: {
      type: [Date, Boolean, String],
      default: null
    },
    disableMinDate: {
      type: [Date, Boolean, String],
      default: null
    },
  },
  beforeMount() {
    /**
     * busca o timezone da máquina do cliente
     */
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  },
  methods: {
    handleInput(e) {
      this.$emit("input", e ? moment(e).tz(this.timeZone).format('YYYY-MM-DD') : null);
    },
    clearInput() {
      this.handleInput(null);
      this.$emit('clean')
    },
  },
};
</script>

<style>
.bg-gray-100 {
  background-color: #f7fafc;
}

.border-t {
  min-height: 3.1rem !important;
  border-top: 1px solid #adb5bd;
}

.rounded-b-lg {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
</style>
