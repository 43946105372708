import Vue from 'vue'
import {parseDate} from '@/helpers'

Vue.filter('parseDate', function (value, formatter = 'DD MMM YYYY HH:mm') {
  return parseDate(value, formatter)
})

Vue.filter('parseHour', function (value) {
  const split = value.toString().split('.')
  return split[1] ? `${split[0]} hr e ${split[1]} min` : `${split[0]} hr`
})

Vue.filter('toHuman', function (value) {
  const str = value.toString()
  return str.replace('.', ',')
})


Vue.filter('numeric', function (value, decimals = 0, cleared = true) {
  if (cleared && (isNaN(value) || !value)) {
    return '-'
  }
  if (isNaN(value)) {
    value = 0
    return value.toFixed(decimals)
  }
  value = Number(value).toFixed(decimals)
  const str = value.toString()
  return str.replace('.', ',')
})
