<template>
<div>
  <modal :show.sync="modal">
    <template slot="header">
      <h5 class="modal-title">{{ title }}</h5>
    </template>
    <div>
      <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
        <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
          <div>

            <breadcrumb
              list-classes="bg-success border-header-success-bottom"
            >
              Locais
            </breadcrumb>

            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pb-0 col-form-label form-control-label">
                Local de dosagem
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-classes="form-control-sm">
                    <puzl-select 
                      v-model="location.dosage_location_id" 
                      :items="dosage_locations"  
                      :loading="loadingLocattion"
                      :disabled="loadingLocattion" />
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0 mb-1">
              <label
                class="col-md-5 pb-0 col-form-label form-control-label">
                Código
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      inputmode="numeric"
                      v-model="location.code"
                      v-mask="['####']"
                      class="form-control form-control-sm"
                      input-group-classes="input-group-sm"
                      :class="errors[0] ? 'is-invalid' : 'is-valid'"
                    >
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pb-0 col-form-label form-control-label">
                Categoria MCC
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-classes="form-control-sm">
                    <puzl-select 
                      v-model="location.cmc_category_id" 
                      :items="categories"  
                      :loading="loadingCategory"
                      :disabled="loadingCategory" />
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0 mb-1">
              <label
                class="col-md-5 pb-0 col-form-label form-control-label"
              >
                Nome do local
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text" v-model="location.name"
                      class="form-control form-control-sm"
                      input-group-classes="input-group-sm"
                      :class="errors[0] ? 'is-invalid' : 'is-valid'"
                    >
                  </base-input>
                </validation-provider>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <base-button type="secondary" @click="closeModal()">
              Cancelar
            </base-button>
            <base-button type="primary" native-type="submit" 
              :disabled="invalid" :loading="loadingSaveAndContinue" @click="saveAndContinue">
                  Salvar e Continuar
            </base-button>
            <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingSave">
              Salvar
            </base-button>
          </div>
        </form>
      </validation-observer>
    </div>
  </modal>
</div>
</template>

<script>
import {mapGetters} from 'vuex'
import moment from 'moment';
import PuzlSelect from "@/components/PuzlSelect";
const {formatErrorValidation} = require("@/plugins")

export default {
  name: "ModalCreateChargePointLocation",
  components: { PuzlSelect },
  data () {
    return {
      title:'Locais de Ponto de Carga',
      modal: false,
      location: {
        dosage_location_id: '',
        code: '',
        cmc_category_id: '',
        name: '',
      },
      loadingSave: false,
      loadingSaveAndContinue: false,
      loadingLocattion: false,
      loadingCategory: true,
    }
  },
  computed: {
    ...mapGetters({
      'dosage_locations': 'dosageLocation/dosage_locations',
      'categories': 'category/fetch',
    }),
  },
  methods: {
    closeModal(){
      this.modal=false
    },
    openModal (open, chargePoint) {
      this.location = {
        company_id: chargePoint.company_id || '',
        company_plant_id: chargePoint.company_plant_id || '',
        company_plant_charge_point_id: chargePoint.id || '',
        cmc_category_id: '',
        code: '',
        dosage_location_id: '',
        name: '',
      }
      this.modal = open
    },
    save () {
      this.$Progress.start()
      if (!this.loadingSaveAndContinue) {
        this.loadingSave = true
      }
      this.$store.dispatch('plantChargePointLocation/add', this.location)
        .then(response => {
          this.$notify({ type: response.error_type, message: response.message })
          this.$store.dispatch('plantChargePoint/fetchItems', this.location.company_plant_id)
          this.$Progress.finish()
          this.loadingSave = false
          if (!this.loadingSaveAndContinue) {
            this.modal = false
          }
          this.loadingSaveAndContinue = false;
        })
        .catch(error =>{
            if(error.status===422){
              let message = formatErrorValidation(error.response.data.errors)
              this.$notify({ type: 'danger', message: message })
            }else{
              this.$notify({ type: error.data.error_type, message: error.data.message })
            }
          this.$Progress.finish()
          this.loadingSave = false
          this.loadingSaveAndContinue = false
        })
    },
    saveAndContinue() {
      this.loadingSaveAndContinue = true;
      this.save();
    },
  },
  mounted () {
    this.$refs.formValidator.validate();
    this.$store.dispatch('dosageLocation/fetchItems').then(() => this.loadingLocattion = false)
    this.$store.dispatch('category/fetchItems').then( () => this.loadingCategory=false)
  }
}
</script>

<style scoped>

</style>
