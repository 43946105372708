<template>
  <div>
    <modal class="bill-modal-create" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div v-if="entity">
        <div class="p-2">
          <div class="border-left border-3 border-success pr-1 mb-2">
            <div class="col-md-12">
              <h4 class="m-0">{{ entity.entity_name }}</h4>
              <h4 class="m-0 font-weight-normal">{{ entity.document }}</h4>
            </div>
          </div>
          <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
            <form class="needs-validation p-2 mt-2" @submit.prevent="handleSubmit(store)" autocomplete="off">
              <!-- Data de entrada -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pl-2 pt-2 pb-0 col-form-label form-control-label">
                  Data de entrada <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-6">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input-date-time-picker :disableMaxDate="max_date" v-model="payload.entry_date" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Central -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pl-2 pt-2 pb-0 col-form-label form-control-label">
                  Central <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-6">
                  <validation-provider rules="required">
                    <!-- form-control-sm -->
                    <base-input input-group-classes="input-group-sm">
                      <puzl-select v-model="payload.company_plant_id" :items="$_company_plants" @change="getCompanyPlantBuyers" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- CNPJ Comprador -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pl-2 pt-2 pb-0 col-form-label form-control-label">
                  CNPJ Comprador <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-6">
                  <validation-provider rules="required">
                    <!-- form-control-sm -->
                    <base-input input-group-classes="input-group-sm">
                      <puzl-select
                        :labelMask="true"
                        :label="['issuer', 'ein']"
                        v-model="payload.company_plant_buyer_id"
                        :items="company_plant_buyers"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Central de entrada -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pl-2 pt-2 pb-0 col-form-label form-control-label">
                  Central de entrada <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-6">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <puzl-select v-model="payload.entry_company_plant_id" :items="$_company_plants" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Tipo -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pl-2 pt-2 pb-0 col-form-label form-control-label">
                  Tipo <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-6">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <puzl-select v-model="payload.document_template_id" :items="document_templates" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Número do documento -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pl-2 pt-2 pb-0 col-form-label form-control-label">
                  Número do documento <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-6">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        inputmode="numeric"
                        type="text"
                        v-model="payload.document_number"
                        class="form-control form-control-sm"
                        v-mask="['###.###.###']"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Série -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pl-2 pt-2 pb-0 col-form-label form-control-label">
                  Série <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-6">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        inputmode="numeric"
                        type="text"
                        v-model="payload.series"
                        class="form-control form-control-sm"
                        maxlength="3"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="modal-footer">
                <base-button type="secondary" @click="closeModal()">Cancelar</base-button>
                <base-button type="success" native-type="submit" :disabled="invalid" :loading="loadingStore">Salvar</base-button>
              </div>
            </form>
          </validation-observer>
        </div>
      </div>
    </modal>
    <ModalSearchEntity @selected="selectedEntity" ref="modalSearchEntity" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ModalSearchEntity from "../../Entity/Shared/_ModalSearchEntity";
import PuzlSelect from "@/components/PuzlSelect";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import moment from "moment";

export default {
  name: "ModalCreateByEntity",
  components: { ModalSearchEntity, PuzlSelect, InputDateTimePicker },
  data() {
    return {
      modal: {
        title: "Entrada de Nota Fiscal Eletrônica",
        create: false,
      },
      entity: null,
      loadingStore: false,
      payload: {
        entry_date: null,
        company_plant_id: null,
        company_plant_buyer_id: null,
        entry_company_plant_id: null,
        document_template_id: null,
        document_number: null,
        series: null,
      },
      document_templates: [
        {
          id: 5,
          name: "NF-e",
        },
        {
          id: 6,
          name: "CT-e",
        },
      ],
      company_plant_buyers: [],
      max_date: false,
      loadingCompanyPlantBuyer: false,
      default_payload: null,
    };
  },
  computed: {
    ...mapGetters({
      $_company_plants: "plant/activeItems",
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal(default_payload = null) {
      this.default_payload = this.$helper.cloneObject(default_payload); 
      if(!this.default_payload) {
        this.entity = null;
        this.$refs.modalSearchEntity.openModal();
      } else {
        this.entity = {
          id : default_payload.entity_id,
          entity_name : default_payload.entity_name,
          document : default_payload.document
        }
        this.payload = this.default_payload;
        this.getCompanyPlantBuyers();
        this.modal.create = true;
      }
    },

    /**
     * Seleciona cliente fornecedor e dados complementares
     *
     * @returns void
     */
    selectedEntity(entity) {
      this.entity = entity;
      this.company_plant_buyers = [];
      this.max_date = new Date(this.$helper.parseDate(moment(), "YYYY-MM-DD HH:mm:ss"));
      this.payload = {
        entry_date: this.max_date,
        company_plant_id: null,
        company_plant_buyer_id: null,
        entry_company_plant_id: null,
        document_template_id: 5,
        document_number: null,
        series: null,
      };
      this.modal.create = true;
    },

    /**
     * Busca cnpj compradores
     *
     * @returns void
     */
    getCompanyPlantBuyers() {
      this.loadingCompanyPlantBuyer = true;
      this.payload.entry_company_plant_id = this.payload.company_plant_id;
      this.$store.dispatch("companyPlantBuyer/getByCompanyPlant", this.payload.company_plant_id).then((response) => {
        this.company_plant_buyers = response.data || [];
        if (this.company_plant_buyers.length) {
          let default_company_plant_buyer =
            this.company_plant_buyers.filter((item) => item.default === 1)[0] || this.company_plant_buyers[0];
          this.payload.company_plant_buyer_id = default_company_plant_buyer.id;
        } else {
          this.payload.company_plant_buyer_id = null;
        }
        this.loadingCompanyPlantBuyer = false;
      });
    },

    store() {
      const loading = this.$loading.show();
      this.loadingStore = true;
      let data = this.$helper.cloneObject(this.payload);
      data.document_number = data.document_number.replaceAll(".", "");
      data.entry_date = moment(this.payload.entry_date).format("YYYY-MM-DD HH:mm:ss");
      data.entity_id = this.entity.id;
      data.company_plant_buyer_ein = this.company_plant_buyers.find((item) => item.id === data.company_plant_buyer_id).issuer.ein;
      data.company_plant_buyer_ein = data.company_plant_buyer_ein.replace(/\D/g, "");
      this.$store
        .dispatch(`entryLaunch/${this.default_payload ? 'update' :'add'}ByEntity`, data)
        .then((response) => {
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.$emit("saved");
          this.closeModal();
        })
        .catch((error) => {
          if (error.status) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response && error.response.status && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
        })
        .finally(() => {
          this.loadingStore = false;
          loading.hide();
        });
    },
  },
};
</script>
