<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <div>
        <div class="p-2">
          <div v-for="(item, index) in items" :key="index">
            <div class="border-left border-3 pl-2 mt-3" :class="item.status.class">
              <div class="row">
                <div class="col-10">
                  <h4 class="mb-0 col-12 pl-2">{{ item.formated_validity_start }}</h4>
                  <h4 class="mb-1 col-12 pl-2">{{ item.price | currency() }} / {{ item.purchase_unit }}</h4>
                  <h4 class="mb-0 col-12 font-weight-normal pl-2">{{ item.updated_at }}</h4>
                  <h4 class="mb-0 col-12 font-weight-normal pl-2">{{ item.name }}</h4>
                </div>
                <div class="col-2 d-flex justify-content-center">
                  <base-button
                    v-if="item.status.status !== 0"
                    size="sm"
                    type="danger"
                    @click.prevent="handleDeleteProductServiceHubPrice(item)"
                    class="text-uppercase  pl-3 pr-3 m-3 text-center d-flex"
                  >
                    <i class="fa fa-trash text-white m-0" />
                  </base-button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <base-button type="secondary" @click="closeModal()"> Fechar </base-button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ModalPriceHistory",
  data() {
    return {
      modal: {
        title: "Histórico de preço",
        create: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      items: "productServiceHubPrice/history",
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal(payload) {
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });
      this.$store.dispatch("productServiceHubPrice/getHistory", payload).then((response) => {
        this.modal.create = true;
        this.$notify({
          type: response.error_type,
          message: response.message,
        });
      });
    },
    handleDeleteProductServiceHubPrice(product_service_hub_price) {
            this.$Swal.confirmDelete().then((result) => {
                if (result.isConfirmed) {
                    this.$Progress.start();
                    this.$notify({
                        type: 'info',
                        message: 'Estamos trabalhando em sua solicitação.'
                    });
                    this.$store.dispatch('productServiceHubPrice/destroy', product_service_hub_price.id).then((response) => {
                        this.$notify({
                            type: response.error_type,
                            message: response.message
                        })
                        this.$Progress.finish()
                        this.$emit('deletedPrice',product_service_hub_price.product_service_hub_id)
                    }).catch((error) => {
                        this.$notify({
                            type: error.data.error_type,
                            message: error.data.message
                        });
                    })
                }
            }).catch(() => this.$Progress.finish())
        },
  },
};
</script>

<style scoped></style>
