import * as types from './mutation_types'
import { createAxios } from "@/plugins/axios";
import {destroy, update} from "@/store/baseStore";
export default {
  namespaced: true,
  state: {
    item: {},
  },
  getters:{
    getResume: state => state.item,
  },
  mutations: {
    [types.SET] (state, payload){
      state.item = payload
    },
  },
  actions: {
    fetchResume({commit}, params) {
      return createAxios().get(`mix/${params.uuid}/resume+microservice`, {params: params})
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  },
}
