<template>
  <div>
    <modal :show.sync="modal.show" size="lg" style="border-radius: 10px;">
      <template slot="header">
        <img height="23px" src="/img/icons/icons8/ios/new-post--v1.png" class="mr-2" />
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">        
            <div class="row mt-n3 mx-0">
              <div class="col-12">
                <div class="d-flex align-items-center">
                  <img height="23px" src="/img/icons/icons8/ios/info-squared_warning.png" class="mr-3" />
                  <b class="text-dark mr-3">Identificação de API</b>
                  <hr class="bg-dark flex-grow-1 ml-2">
                </div>
              </div>
            </div>
            <div class="mx-3">
              <div class="row mt-3 mb-2 align-items-center">
                <div class="col-12 col-md-6">
                  <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                    API
                    <span class="text-danger">&nbsp;*</span>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <validation-provider rules="required">
                    <base-input autocomplete="off">
                      <puzl-select
                        v-model="$_companyPlantIssuer.email_param_api"
                        :items="apis"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="row mt-3 mb-2 align-items-center">
                <div class="col-12 col-md-6">
                  <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                    API Secret/Token
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <base-input
                    type="password"
                    input-classes="form-control-sm"
                    autocomplete="off"
                    v-model="$_companyPlantIssuer.email_param_token"
                  />
                </div>
              </div>
              <div class="row mt-3 mb-2 align-items-center">
                <div class="col-12 col-md-6">
                  <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                    Host
                    <span class="text-danger">&nbsp;*</span>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <validation-provider rules="required">
                    <base-input
                      input-classes="form-control-sm"
                      autocomplete="off"
                      v-model="$_companyPlantIssuer.email_param_host"
                    />
                  </validation-provider>
                </div>
              </div>
              <div class="row mt-3 mb-2 align-items-center">
                <div class="col-12 col-md-6">
                  <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                    Porta
                    <span class="text-danger">&nbsp;*</span>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <validation-provider rules="required">
                    <base-input
                      input-classes="form-control-sm"
                      autocomplete="off"
                      v-model="$_companyPlantIssuer.email_param_port"
                    />
                  </validation-provider>
                </div>
              </div>
              <div class="row mt-3 mb-2 align-items-center">
                <div class="col-12 col-md-6">
                  <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                    Usuário
                    <span class="text-danger">&nbsp;*</span>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <validation-provider rules="required">
                    <base-input
                      input-classes="form-control-sm"
                      autocomplete="off"
                      v-model="$_companyPlantIssuer.email_param_user_name"
                    />
                  </validation-provider>
                </div>
              </div>
              <div class="row mt-3 mb-2 align-items-center">
                <div class="col-12 col-md-6">
                  <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                    Senha
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <base-input
                    type="password"  
                    input-classes="form-control-sm"
                    autocomplete="off"
                    v-model="email_param_password"
                  />
                </div>
              </div>
              <div class="row mt-3 mb-2 align-items-center">
                <div class="col-12 col-md-6">
                  <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                    Tipo de Encriptação
                    <span class="text-danger">&nbsp;*</span>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <validation-provider rules="required">
                    <base-input autocomplete="off">
                      <puzl-select
                        v-model="$_companyPlantIssuer.email_param_encryption_type"
                        :items="encryptionTypes"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
            </div>
            <div class="row mt-3 mx-0">
              <div class="col-12">
                <div class="d-flex align-items-center">
                  <img height="23px" src="/img/icons/icons8/ios/administrative-tools_success.png" class="mr-3" />
                  <b class="text-dark mr-3">Configuração de E-mail</b>
                  <hr class="bg-dark flex-grow-1 ml-2">
                </div>
              </div>
            </div>
            <div class="mx-3">
              <div class="row mt-3 mb-2 align-items-center">
                <div class="col-12 col-md-6">
                  <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                    E-mail de envio
                    <span class="text-danger">&nbsp;*</span>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <validation-provider rules="required">
                    <base-input
                      input-classes="form-control-sm"
                      autocomplete="off"
                      v-model="$_companyPlantIssuer.email_param_trigger_email"
                    />
                  </validation-provider>
                </div>
              </div>
              <div class="row mt-3 mb-2 align-items-center">
                <div class="col-12 col-md-6">
                  <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                    Nome de exibição
                    <span class="text-danger">&nbsp;*</span>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <validation-provider rules="required">
                    <base-input
                      input-classes="form-control-sm"
                      autocomplete="off"
                      v-model="$_companyPlantIssuer.email_param_display_name"
                    />
                  </validation-provider>
                </div>
              </div>
            </div>
            <div class="modal-footer mt-3">
              <div class="col-md-3 px-1">
                <base-button
                  block
                  type="danger"
                  size="md"
                  style="border-radius: 4px !important;"
                  @click.prevent="closeModal()"
                >
                  <img class="mr-2" src="/img/icons/icons8/ios/cancel.png" width="19px">
                  Cancelar
                </base-button>
              </div>
              <div class="col-md-3 px-1">
                <base-button
                  native-type="submit"   
                  v-bind:disabled="invalid"
                  :loading="isLoading"
                  block
                  type="success"
                  size="md"
                  style="border-radius: 4px !important;"
                >
                  <img class="mr-2" src="/img/icons/save.png" width="19px">
                  Salvar
                </base-button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {formatErrorValidation} from "@/plugins";
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "ModalEmailParam",
  components: {
    PuzlSelect,
  },
  data() {
    return {
      modal: {
        title: "E-MAIL",
        show: false,
        PuzlSelect,
      },
      email_param_password: null,
      isLoading: false,
      apis: [
        {id: 0, name: 'SMTP'},
        {id: 1, name: 'Token'},
      ],
      encryptionTypes: [
        {id: 0, name: 'TLS'},
        {id: 1, name: 'SSL'},
      ],
    };
  },
  computed: {
    ...mapGetters({
      $_companyPlantIssuer: "companyPlantIssuer/show",
    }),  
  },
  methods: {
    handleOpenModal(){
      this.email_param_password = null;
      this.modal.show = true;
    },
    closeModal(){
      this.modal.show = false;
    },
    /**
     * Verificar se ibge_code foi preenchido.
     * Caso não esteja preenchido, tenta localizar ibgecode a partir de this.$_companyPlantIssuer.state e this.$_companyPlantIssuer.city
     */
     async checkIbgeCode() {
      if (this.$_companyPlantIssuer.state && this.$_companyPlantIssuer.city) {
        let loader = this.$loading.show();
        let params = {state: this.$_companyPlantIssuer.state, name: this.$_companyPlantIssuer.city}
        await this.$store.dispatch("city/showByStateAndCity", params)
          .then(response => {
            this.$_companyPlantIssuer.ibge_code = response.data.iso;
            this.$notify({type: response.error_type, message: response.message});
          }).catch((error) => {
            let errors = error.status
              ? error.data.message
              : formatErrorValidation(error.response.data.errors);
            this.$notify({type: "danger", message: errors});
          }).finally(() => {
            loader.hide();
          });
      }
    },
    async save() {
      // Garantir que ibge_code seja salvo
      if (!this.$_companyPlantIssuer.ibge_code) {
        await this.checkIbgeCode();
        if (!this.$_companyPlantIssuer.ibge_code) {
          this.$notify({type: "danger", message: 'Não foi possível obter o código IBGE da Cidade.'});
          return;
        }
      }
      this.isLoading = true;
      this.$Progress.start();
      this.$notify({type: "info", message: "Estamos trabalhando em sua solicitação."});
      this.$_companyPlantIssuer.send_auto_mail = Number(this.$_companyPlantIssuer.send_auto_mail);
      this.$_companyPlantIssuer.alias_name = this.$_companyPlantIssuer.business_name;
      this.$_companyPlantIssuer.certificate_password = this.certificate_password;
      this.$_companyPlantIssuer.email_param_password = this.email_param_password;

      this.$store
        .dispatch('companyPlantIssuer/update', {
          companyPlantIssuer: this.$_companyPlantIssuer,
          certificate_file: this.certificate_file
        })
        .then(({ error_type, message }) => {
          this.$notify({type: error_type, message});
          this.closeModal();
        })
        .catch(error => {
          const errors = error.status
            ? error.data.message
            : formatErrorValidation(error.response.data.errors);
          this.$notify({ type: "danger", message: errors });
        }).finally(() => {
          this.$Progress.finish();
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.btn.dropdown-toggle::after {
  content: none;
}
</style>
