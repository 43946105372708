/**
 * Type para resposta de listagem base
 *
 * @typedef {Object} PagerType
 * @property {number} current_page - Página atual
 * @property {number} per_page - Itens por página
 * @property {0|1|2} paginate_type - Tipo de paginação (0=CURSOR, 1=SIMPLE, 2=NORMAL)
 * @property {boolean} has_next_page - Se tem próxima página
 * @property {string} next_page - Próxima página
 * @property {number} last_page - Última página
 * @property {number} total_count - Total de itens
 */
export const PagerType = {};

/**
 * Inicializar PagerType
 *
 * @returns {PagerType}
 */
export const initPagerType = () => ({
  current_page: 1,
  per_page: 0,
  paginate_type: 0,
  has_next_page: false,
  next_page: '',
  last_page: 1,
  total_count: 0,
});
