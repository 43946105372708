import store from "../../../../shared/libraries/store";
import { SearchBarFilterType } from "../../../../components/AppGlobal";
import {
  CmcAdjustmentListFilterType,
  CmcAdjustmentListPagerType,
  initCmcAdjustmentListPagerType,
} from "../types";
import { cmcAdjustmentStoreStateKeys } from "./cmcAdjustmentStoreStateKeys";

/** @returns {CmcAdjustmentListPagerType} */
const getListed = () =>
  store.getState(cmcAdjustmentStoreStateKeys.LISTED) ??
  initCmcAdjustmentListPagerType();

/** @returns {boolean} */
const getIsListing = () =>
  store.getState(cmcAdjustmentStoreStateKeys.IS_LISTING) ?? false;

/** @returns {boolean} */
const getIsRemoving = () =>
  store.getState(operationSourceStoreStateKeys.IS_REMOVING) ?? false;

/**
 * @returns {{
 *   searchBarFilter: SearchBarFilterType,
 *   filter: CmcAdjustmentListFilterType
 * }}
 */
const getPageState = () =>
  store.getState(cmcAdjustmentStoreStateKeys.PAGE_STATES);

export default {
  getListed,
  getIsListing,
  getPageState,
  getIsRemoving,
};
