<template>
<div>
    <base-header class="bg-gray-content">
        <div class="row align-items-center py-4">
            <div class="col-lg-6 col-7">
                <h6 class="h2 text-gray d-inline-block mb-0">{{ this.$route.meta.breadcrumbs[1].title }}</h6>
                <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4 text-gray">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i></a></li>
                        <li class="breadcrumb-item"><a href="#">Financeiro</a></li>
                        <li class="breadcrumb-item" aria-current="page">{{ this.$route.meta.breadcrumbs[1].title }}</li>
                    </ol>
                </nav>
            </div>
        </div>
        <!-- Header Menus -->
        <div class="row">
            <div class="col-md-2 mb-3">
                <base-button block size="lg" type="success" @click.prevent="showModalCreate = true">
                  <i class="fas fa-plus"></i> NOVO
                </base-button>
            </div>
            <div class="col-md-2 mb-2">
                <BackButton />
            </div>
        </div>
    </base-header>
    <list-bank-account></list-bank-account>
    <modal-create
      @close="showModalCreate = false"
      :show="showModalCreate"
    />
</div>
</template>

<script>
import BackButton from "@/components/Utils/BackButton.vue";
import ListBankAccount from './Shared/_List'
import ModalCreate from "./Shared/_Create.vue"

export default {
    name: "BankAccount",
    components: {
        BackButton,
        ListBankAccount,
        ModalCreate,
    },
    data() {
        return {
          showModalCreate: false,
        };
    },
};
</script>

<style></style>
