<template>
<div>
  <base-header class="bg-gray-content">
    <div class="row align-items-center py-4">
      <div class="col-lg-6 col-7">
        <h6 class="h2 text-gray d-inline-block mb-0">{{ this.$route.meta.title }}</h6>
        <nav
          aria-label="breadcrumb"
          class="d-none d-md-inline-block ml-md-4 text-gray"
        >
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i></a></li>
            <li class="breadcrumb-item"><a href="#">{{ this.$route.meta.breadcrumbs[0].title }}</a></li>
            <li class="breadcrumb-item" aria-current="page">{{ this.$route.meta.breadcrumbs[1].title }}</li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 mb-2">
        <base-button block size="lg" type="success" class="text-uppercase" @click="handleCreate(construction_uuid)">
          <i class="fas fa-plus"></i> novo
        </base-button>
      </div>
      <div class="col-md-2 col-xlg-2">
        <router-link
          :to="{ path:'/commercial/customer-construction/constructions',
          name: 'commercial.customer-construction.constructions',
          params: { customer_construction_uuid: customer_construction_uuid }}"
        >
          <base-button block size="lg" type="light" class="text-uppercase">
            voltar
          </base-button>
        </router-link>
      </div>
    </div>
    <div class="row align-items-center pb-0 mb-3" v-if="construction_name">
      <div class="col-md-12">
        <div class="checklist-item checklist-item-primary">
          <div class="checklist-info pb-2">
            <h1 class="checklist-title mb-0">
              {{construction_name}}
            </h1>
            <h1 class="checklist-title mb-0 small" v-if="customer_name">
              {{ customer_name }}
            </h1>
          </div>
        </div>
      </div>
    </div>
  </base-header>

  <multi-filter ref="multi-filter" @fetch="init" :filter="filter" :status="multi_filter" />
  <div class="container-fluid">
    <div class="row card-wrapper" v-show="loadingSkeleton">
      <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
    </div>
        <PuzlEmptyData v-if="!this.$helper.Array.isEmpty($_contacts) && !loadingSkeleton"></PuzlEmptyData>
        <div class="row card-wrapper" v-if="this.$helper.Array.isEmpty($_contacts) && !loadingSkeleton">
          <div class="col-lg-4"
               v-for="(contact, index) in $_contacts"
               :key="index"
          >
            <!-- Basic with action button -->
            <card :class="contact.status ? 'card-border-top-success' : 'card-border-top-danger'">
              <!-- Card header -->
              <div class="row align-items-center mb-3">
                <div class="col-8">
                  <!-- Title -->
                  <h5 class="h4 mb-0">Usuário</h5>
                  <h5 class="h4 mb-0 small">
                    <span v-if="contact.is_user===true">
                      Sim
                    </span>
                    <span v-else>
                      Não
                    </span>
                  </h5>
                </div>
                <div class="col-4 text-right">
                  <base-dropdown menuOnRight>
                    <base-button
                      slot="title-container"
                      type="primary"
                      class="dropdown-toggle p-2 rounded m-0"
                    >
                      <i class="fas fa-cog"></i>
                    </base-button>

                    <div class="dropdown-divider p-0 m-0"></div>
                    <a class="dropdown-item" @click="handleEditContact(contact.uuid)">
                      <i class="fas fa-edit text-warning"></i>
                      Editar
                    </a>
                    <div class="dropdown-divider p-0 m-0"></div>
                    <a class="dropdown-item" @click="handleDeleteContact(contact.uuid)">
                      <i class="fas fa-times text-danger"></i>
                      Excluir
                    </a>
                  </base-dropdown>
                </div>
              </div>
              <!-- Card body -->
              <div class="row mt-3">
                <div class="col-md-12">
                  <h5 class="h4 mb-0">
                    {{ contact.name }}
                  </h5>
                  <h5 class="h4 mb-0 small" v-if="contact.email">
                    {{ contact.email }}
                  </h5>
                </div>
              </div>
              <div class="row mt-3" v-if="contact.sector || contact.office">
                <div class="col-md-12">
                  <h5 class="h4 mb-0" v-if="contact.sector">
                    {{ contact.sector }}
                  </h5>
                  <h5 class="h4 mb-0 small" v-if="contact.office">
                    {{ contact.office }}
                  </h5>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12">
                  <h5 class="h4 mb-0" v-if="contact.cell_phone">
                    {{ contact.cell_phone }}
                  </h5>
                  <h5 class="h4 mb-0" v-if="contact.phone">
                    {{ contact.phone }}
                  </h5>
                </div>
              </div>
            </card>
          </div>
        </div>
  </div>
  <loading-pagination :show="loading && !loadingSkeleton"/>
  <modal-create-contact ref="createContact"></modal-create-contact>
  <modal-edit-contact ref="editContact"></modal-edit-contact>
</div>
</template>
<script>
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import {mapGetters} from 'vuex'
import PuzlEmptyData from "@/components/PuzlEmptyData";
import ModalCreateContact from './_Create'
import ModalEditContact from './_Edit'
import MultiFilter from '@/components/Utils/MultiFilter.vue';
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";

export default {
  name: "ListConstruction",
  mixins: [cursorPaginate],
  components: {
    PuzlEmptyData,
    SkeletonPuzlGrid,
    ModalCreateContact,
    ModalEditContact,
    MultiFilter,
    LoadingPagination,
  },
  data () {
    return {
      loadingSkeleton: false,
      filter: {
        status: null,
      },
      multi_filter: {
        null: {
          name: "Todos",
          type: "primary",
        },
        0: {
          name: "Inativo",
          type: "danger",
        },
        1: {
          name: "Ativo",
          type: "success",
        },
      },
      construction_uuid: this.$route.params.construction_uuid,
      construction_name: '',
      customer_construction_uuid: '',
      customer_name: '',
    }
  },
  computed: {
    ...mapGetters({
      '$_contacts': 'customerConstructionConstructionsContact/fetch',
    }),
  },
  methods:{
    init (filter = null) {
      this.startCursor(filter)
      this.$store.dispatch('customerConstructionConstructionsContact/getContactsPagination', {
          constructionUuid: this.$route.params.construction_uuid,
          filter: this.filter,
          next_page: this.paginate.nextUrl
        })
        .then( response =>{
          this.construction_name = response.data[0].construction_name
          this.customer_construction_uuid = response.data[0].customer_construction_uuid
          this.customer_name = response.data[0].customer_name
          this.resolveCursor(response)
        })
        .catch( error => {
          this.resolveCursor()
        })
    },
    handleCreate(uuid){
      this.$refs.createContact.handleCreateModal(uuid)
    },
    handleEditContact(uuid){
      this.$refs.editContact.handleEditModal(uuid)
    },
    handleDeleteContact(uuid){
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed){
          this.$Progress.start();
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          this.$store.dispatch('customerConstructionConstructionsContact/destroy', uuid).then(() => this.init())
          this.$Progress.finish();
        }
      }).catch(() => this.$Progress.finish())
    }
  },
  mounted() {
    this.init({})
  }
}
</script>

<style scoped>

</style>
