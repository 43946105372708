<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(store)"
            autocomplete="off"
          >
            <div>
              <div class="form-group row m-0 p-0 pt-2">
                <label
                  class="col-md-4 pt-1 pb-3 mb-1 col-form-label form-control-label text-left"
                >
                  Central
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-6 pr-1 pb-3">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-classes="form-control-sm">
                      <puzl-select
                        v-model="adjustment.company_plant_uuid"
                        :items="plants"
                        customKey="uuid"
                        :multiple="true"
                        :disabled="loadingPlant"
                        :loading="loadingPlant"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 col-form-label form-control-label pb-0">
                  Dia inteiro
                </label>
                <div class="col-md-8 pt-2">
                  <base-switch
                    v-model="adjustment.is_day"
                    type="primary"
                  ></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0 pt-2">
                <label
                  class="col-md-4 pt-1 pb-3 mb-1 col-form-label form-control-label text-left pr-0"
                >
                  Maior que
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7 pb-3 pl-3">
                  <validation-provider rules="required">
                    <input-time-picker
                      :disabled="disabledDay"
                      v-model="adjustment.hour_min"
                    />
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-4 pt-1 pb-3 mb-0 col-form-label form-control-label text-left pr-0"
                >
                  Menor ou igual a
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input-time-picker
                        :disabled="disabledDay"
                        v-model="adjustment.hour_max"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')">
                Cancelar
              </base-button>
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                :loading="loadingStore"
              >
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InputTimePicker from "@/components/InputTimePicker";
import PuzlSelect from "@/components/PuzlSelect";
import { formatErrorValidation } from "@/plugins";

export default {
  name: "ModalCreateAdjustment",
  components: { InputTimePicker, PuzlSelect },
  data() {
    return {
      modal: {
        title: "Ajuste por Contrato e Horário",
        create: false,
      },
      adjustment: {
        company_plant_uuid: [],
        is_day: false,
        hour_min: null,
        hour_max: null,
        contract_proposal_formulation_uuid: null,
        mix_code: null,
        contract_proposal_uuid: null,
      },
      disabledDay: false,
      loadingStore: false,
      loadingPlant: true,
      secondSelect: false,
    };
  },
  computed: {
    ...mapGetters({
      plants: "plant/fetch",
    }),
  },
  watch: {
    "adjustment.is_day"() {
      if (this.adjustment.is_day) {
        this.disabledDay = true;
        this.adjustment.hour_min = "00:00";
        this.adjustment.hour_max = "23:59";
      } else {
        this.disabledDay = false;
        this.adjustment.hour_min = null;
        this.adjustment.hour_max = null;
      }
    },
  },
  methods: {
    closeModal() {
      this.adjustment = {
        company_plant_uuid: [],
        is_day: false,
        contract_proposal_formulation_uuid: null,
        mix_code: null,
        contract_proposal_uuid: null,
      };
      this.modal.create = false;
    },
    handleCreateModal(formulationUuid, mixCode, contractProposalUuid) {
      this.adjustment = {
        company_plant_uuid: [],
        contract_proposal_formulation_uuid: formulationUuid,
        mix_code: mixCode,
        contract_proposal_uuid: contractProposalUuid,
        hour_min: null,
        hour_max: null,
      };
      this.modal.create = true;
    },
    store() {
      this.$Progress.start();
      this.loadingStore = true;
      this.$store
        .dispatch("adjustmentContractHour/add", this.adjustment)
        .then((response) => {
          this.adjustment = {
            company_plant_uuid: [],
            is_day: false,
            hour_min: null,
            hour_max: null,
            contract_proposal_formulation_uuid: null,
            mix_code: null,
            contract_proposal_uuid: null,
          };
          this.closeModal("create");
          this.$emit("fetchAdjustmentsContract");
          this.loadingStore = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
        })
        .catch((error) => {
          if (error.status === 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
            this.$Progress.finish();
            this.loadingStore = false;
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
        });
    },
  },
  mounted() {
    this.$store.dispatch("plant/fetchItems").then(() => {
      this.loadingPlant = false;
    });
  },
};
</script>

<style scoped></style>
