<template>
  <div>
    <modal :show.sync="modal.open" size="lg">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div v-if="loadingLatestPage">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(update)" autocomplete="off">
            <div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-4 pb-0 pt-1 col-form-label form-control-label"
                >
                  Data de início da vigência
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <el-date-picker
                        v-model="payload.date_validity"
                        size="mini"
                        type="datetime"
                        format="dd/MM/yyyy HH:mm"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :picker-options="pickerOptions"
                      >
                      </el-date-picker>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <quill-editor
                ref="myQuillEditor"
                v-model="payload.text"
                :options="editorOption"
              />

            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')">
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
      <div v-if="!loadingLatestPage">
        carregando...
      </div>
    </modal>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

import moment from "moment"
const {formatErrorValidation} = require("@/plugins")
export default {
  name: "ModalEditContractPage",
  components: {
    quillEditor,
  },
  data () {
    return {
      modal: {
        title: 'Contrato Paginas',
        open: false,
      },
      payload: {
        uuid: null,
        date_validity: null,
        text: '',
        contract_uuid: '',
        version: 0,
      },
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'align': [] }],
          ],
          syntax: {
            highlight: text => hljs.highlightAuto(text).value
          }
        }
      },
      range: ["00:00:00 - 23:59:59"],
      pickerOptions: {
        selectableRange: this.range,
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      loadingStore: false,
      loadingLatestPage: false,
    }
  },
  watch: {
    "payload.date_validity"() {
      if (
        moment(this.payload.date_validity).format("YYYY-MM-DD") ===
        moment().format("YYYY-MM-DD")
      ) {
        this.pickerOptions.selectableRange = [
          moment().format("HH:mm:ss") + "- 23:59:59"
        ];
        let date = moment(this.payload.date_validity);
        date = moment.duration(date).asMinutes();

        let now = moment();
        now = moment.duration(now).asMinutes();

        if (date < now) {
          this.payload.date_validity = moment().format(
            "YYYY-MM-DD HH:mm:ss"
          );
        }
      } else {
        this.pickerOptions.selectableRange = ["00:00:00 - 23:59:59"];
      }
    }
  },
  methods: {
    closeModal () {
      this.modal.open = false
    },
    handleEditModal (uuid) {
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'})
      this.loadingLatestPage = false
      this.$Progress.start()
      this.$store.dispatch('contractContractsPages/show', uuid)
        .then( (response) => {
          this.payload = {
            uuid: response.data.uuid || '',
            date_validity: response.data.date_validity || moment().format('YYYY-MM-DD HH:mm:ss'),
            text: response.data.text || '',
            contract_uuid: response.data.contract_uuid || '',
            version: response.data.version || 0,
          }
          this.loadingLatestPage = true
          this.modal.open = true
          this.$Progress.finish()
          this.$notify({type: response.error_type, message: response.message})
        })
        .catch(error =>{
          if (error.status) {
            this.$Progress.finish()
            this.loadingStore = false
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            })
          } else {
            if (error.response.status === 422) {
              let message = formatErrorValidation(error.response.data.errors)
              this.$notify({
                type: 'danger',
                message: message
              })
              this.$Progress.finish()
              this.loadingStore = false
            } else {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              })
              this.$Progress.finish()
              this.loadingStore = false
            }
          }
        })
    },
    update () {
      this.$Progress.start()
      this.loadingStore = true
      this.$store.dispatch('contractContractsPages/update', this.payload)
        .then(response => {
          this.loadingStore = false
          this.modal.open = false
          this.payload = {
            uuid: null,
            date_validity: null,
            text: '',
            contract_uuid: '',
            version: 0,
          }
          this.$emit('fetchContract')
          this.$Progress.finish()
          this.$notify({ type: response.error_type, message: response.message })
        })
        .catch(error =>{
          if (error.status) {
            this.$Progress.finish()
            this.loadingStore = false
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            })
          } else {
            if (error.response.status === 422) {
              let message = formatErrorValidation(error.response.data.errors)
              this.$notify({
                type: 'danger',
                message: message
              })
              this.$Progress.finish()
              this.loadingStore = false
            } else {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              })
              this.$Progress.finish()
              this.loadingStore = false
            }
          }
        })
    },
  },
}
</script>

<style scoped>

</style>
