/**
 * Enum para instancia de DateFactory
 * @enum {string}
 */
export const DATE_ENUM = Object.freeze({
  MOMENT: 0,
  DAY: 1
});

/**
 * Enum para os formatos de data mais utilizados
 * @enum {string}
 */
export const FORMAT = Object.freeze({
  FULL_DATE: 'YYYY-MM-DD', // 2024-06-17
  FULL_DATE_SLASH: 'DD/MM/YYYY', // 17/06/2024
  FULL_DATE_SLASH_US: 'MM/DD/YYYY', // 06/17/2024
  FULL_DATE_LONG: 'dddd, Do [de] MMMM YYYY', // Segunda-feira, 17º de Junho 2024
  ABBREVIATED_DATE: 'DD MMM YYYY', // 17 Jun 2024
  ABBREVIATED_DATE_LONG: 'MMM Do, YYYY', // Jun 17º, 2024
  TIME_24H: 'HH:mm:ss', // 14:30:45
  TIME_12H: 'hh:mm:ss A', // 02:30:45 PM
  TIME_WITH_MILLISECONDS: 'HH:mm:ss.SSS', // 14:30:45.123
  DATE_TIME: 'YYYY-MM-DD HH:mm:ss', // 2024-06-17 14:30:45
  DATE_TIME_SLASH: 'DD/MM/YYYY HH:mm:ss', // 17/06/2024 14:30:45
  DATE_TIME_LONG: 'MMMM Do YYYY, h:mm:ss a', // Junho 17º 2024, 2:30:45 pm
  ISO_8601: 'YYYY-MM-DDTHH:mm:ssZ', // 2024-06-17T14:30:45Z
  SHORT_DAY_HOUR: 'ddd, hA', // Seg, 2PM
  HOUR_MINUTE_12H: 'h:mm A', // 2:30 PM
  MONTH_YEAR: 'MMMM YYYY', // Junho 2024,
  SHORT_DATE_AND_TIME: "DD MMM YYYY - HH:mm", // 24 NOV 2023 10:10
});

/**
 * Enumeração da duração do tempo
 * @enum {string}
 */
export const DURATION_INPUT = Object.freeze({
  MILLISECONDS: 'milliseconds',
  SECONDS: 'seconds',
  MINUTES: 'minutes',
  HOURS: 'hours',
  DAYS: 'days',
  WEEKS: 'weeks',
  MONTHS: 'months',
  QUARTERS: 'quarters',
  YEARS: 'years'
});

/**
 * Enumeração das unidades de tempo
 * @enum {string}
 */
export const UNIT_OF_TIME = Object.freeze({
  YEAR: 'year',
  QUARTER: 'quarter',
  MONTH: 'month',
  WEEK: 'week',
  DAY: 'day',
  HOUR: 'hour',
  MINUTE: 'minute',
  SECOND: 'second',
  MILLISECOND: 'millisecond',
});
