export const EntryLaunchStatusEnum = Object.freeze({
    // Dinâmicos
    PENDING: 0, // Pendente
    PAID: 1, // Pago
    OVERDUE: 2, // Atrasado
    WITHOUT_BILL_PAY: 3, // Sem pagamento de fatura
    // Estáticos
    CANCELLED: 4, // Cancelado
    WAITING: 5, // Aguardando
    VERIFICATION: 6, // Em verificação
    NO_FINANCIAL: 7, // Sem financeiro
    SEFAZ_CANCELLATION: 8,
    // Filtros
    WITHOUT_CLASSIFICATION: 9, // Sem Classificação
    CLASSIFIED: 10 // Classificado
});
