import * as types from './mutation_types'
import { createAxios } from "@/plugins/axios";
import {cursorPaginate, setUrlWithNextPage, paginate} from "@/store/baseStore";
const endPoint = '/mix/reference/history'
export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters:{
    fetch: state => state.items,
  },
  mutations: {
    [types.SET_ITEMS] (state, payload){
      state.items = payload
    },
  },
  actions: {
    fetch({commit, dispatch, state}, params) {
      return createAxios().get(setUrlWithNextPage(endPoint, params), {params: params})
        .then(({data}) =>{
          paginate(state, {data: data.data, next_page: params.next_page}, 'items', 'id', params.page)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  },
}
