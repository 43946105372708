<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 mt-4 ml-2">
        <base-button
          @click.prevent="setViewType"
          :class="filter.type === 2 && 'active'"
          class="ml-n1"
          size="sm"
          type="primary"
          style="border: 1px solid #c4baba"
          outline
        >
          <template>
            <img width="18" v-if="filter.type === 2" src="/img/icons/icons8/ios/parallel-tasks.png"/>
            <img width="18" v-else src="/img/icons/icons8/ios/parallel-tasks-blue.png"/>  AGRUPAMENTO
          </template>
        </base-button>
      </div>
    </div>
    <div>
      <multi-filter ref="multi-filter"
                    :type="5"
                    :show-slot-bookmark="false"
                    :show-slot-order-by="false"
                    :show-slot-dates="false"
                    :show-slot-status="false"
                    @handleFilterDate="handleFilterDate"
                    :filter="filter"
                    @fetch="init"
      >
        <template slot="data">
          <div class="mt-2 col-12 text-left">
            <label class="form-control-label">
              Tipo de visualização
            </label>
            <PuzlSelect
              style="width: 100%"
              disabled
              :clearable="false"
              v-model="filter.view_type"
              :items="[{name: 'Unidade de entrada', id: 1}, {name: 'Financeiro', id: 2}]"
              class="select-xl new-default-black-font"
              placeholder="Unidade de entrada"
              @change="init(filter)"
            />
          </div>
          <div class="mt-2 col-12 text-left">
            <label class="form-control-label">
              AGRUPAR POR
            </label>
            <PuzlSelect
              style="width: 100%"
              v-model="filter.group_by"
              :items="[{name: 'Diário', id: 1}, {name: 'Mensal', id: 2}]"
              class="select-xl new-default-black-font"
              placeholder="Unidade de entrada"
              @change="init(filter)"
            />
          </div>
          <div style="margin-bottom: 50%" class="mt-2 col-12 text-left">
            <label class="form-control-label">
              CATEGORIA MCC
            </label>
            <puzl-select
              v-model="filter.categories_id"
              :items="$_categories"
              :multiple="true"
              @change="init(filter)"
              class="select-xl new-default-black-font"
              :disabled="loadingCmcCategories"
              :loading="loadingCmcCategories" />
          </div>
        </template>
        <template slot="dropdown-instead-input">
          <PuzlSelect
            disabled
            :clearable="false"
            style="width: 100%"
            v-model="filter.view"
            :items="[{name: 'MCC', id: 1}]"
            placeholder="Visualização"
            @change="init({})"
            class="select-xl"
          />
        </template>
      </multi-filter>
      <div class="card">
        <div class="card-body mt-n2 p-0">
          <div class="row">
            <ShadedTable class="px-4 pt-2"
                         :length="$_analyzes.length"
                         :headers="filter.type === 2 ? headers : headers_mcc"
                         :loading="loadingSkeleton">
              <template v-for="(item, key) in $_analyzes" :slot="key">
                <ShadedCol>
                  <div class="text-center">
                    <h5 class="m-0" v-if="filter.group_by === 2">
                      {{ item.stock_date_at | parseDate('MM/YYYY') }}
                    </h5>
                    <h5 class="m-0" v-if="filter.group_by === 1">
                      {{ item.stock_date_at | parseDate('DD/MM/YYYY') }}
                    </h5>
                  </div>
                </ShadedCol>
                <ShadedCol>
                  <div class="text-center">
                    <h5 class="m-0">
                      {{ item.company_plant_name }}
                    </h5>
                  </div>
                </ShadedCol>
                <ShadedCol>
                  <div class="text-center">
                    <h5 class="m-0">
                      {{ item.category_name }}
                    </h5>
                  </div>
                </ShadedCol>
                <ShadedCol>
                  <div class="text-center">
                    <h5 class="m-0">
                      {{ item.product_name }}
                    </h5>
                  </div>
                </ShadedCol>
                <ShadedCol>
                  <div class="text-center">
                    <h5 class="m-0">
                      {{ item.unit_stock_type }}
                    </h5>
                  </div>
                </ShadedCol>
                <ShadedCol>
                  <div class="text-center">
                    <h5 class="m-0">
                      {{
                        Math.round(item.previous_position).toLocaleString('pt-BR') }}
                    </h5>
                  </div>
                </ShadedCol>
                <ShadedCol>
                  <div class="text-center">
                    <h5 class="m-0">
                      {{
                        Math.round(item.sum_entries).toLocaleString('pt-BR') }}
                    </h5>
                  </div>
                </ShadedCol>
                <ShadedCol>
                  <div class="text-center">
                    <h5 class="m-0">
                      {{
                        Math.round(item.sum_exits).toLocaleString('pt-BR') }}
                    </h5>
                  </div>
                </ShadedCol>
                <ShadedCol>
                  <div class="text-center">
                    <h5 class="m-0">
                      {{
                        Math.round(item.final_position_amount).toLocaleString('pt-BR') }}
                    </h5>
                  </div>
                </ShadedCol>
                <ShadedCol>
                  <div class="text-center">
                    <h5 class="m-0">
                      {{
                        Math.round(item.final_stock_kg).toLocaleString('pt-BR') }}
                    </h5>
                  </div>
                </ShadedCol>
                <ShadedCol>
                  <div class="text-center">
                    <h5 class="m-0" :class="textClassBreakingValue(item.breaking_value)">
                     {{ Math.round(item.breaking_value).toLocaleString('pt-BR') }}
                    </h5>
                  </div>
                </ShadedCol>
                <ShadedCol>
                  <div class="text-center">
                    <h5 class="m-0" :class="textClassBreakingValue(item.breaking_percentage)">
                      {{  Math.round(item.breaking_percentage) }} %
                    </h5>
                  </div>
                </ShadedCol>
              </template>
            </ShadedTable>
          </div>
          <puzl-empty-data :with-card="false" v-if="!$_analyzes.length"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ShadedTable from "@/components/ShadedTable.vue";
import ShadedCol from "@/components/ShadedCol.vue";
import {mapGetters} from "vuex";
import PuzlEmptyData from "@/components/PuzlEmptyData.vue";
import MultiFilter from "@/components/Utils/MultiFilterV3.vue";
import cursorPaginate from "@/mixins/cursorPaginate";
import PuzlSelect from "@/components/PuzlSelect.vue";
import moment from "moment/moment";
export default {
  components: {
    PuzlEmptyData,
    ShadedCol,
    ShadedTable,
    MultiFilter,
    PuzlSelect
  },
  mixins: [cursorPaginate],
  data() {
    return {
      headers: [
        "Data",
        "Central",
        "Categoria",
        "Grupo",
        "Unidade",
        "Inicial",
        "Entrada",
        "Saída",
        "Final",
        "Real",
        "Quebra",
        "%",
      ],
      headers_mcc: [
        "Data",
        "Central",
        "Categoria",
        "MCC",
        "Unidade",
        "Inicial",
        "Entrada",
        "Saída",
        "Final",
        "Real",
        "Quebra",
        "%",
      ],
      loading: false,
      loadingSkeleton: false,
      loadingCmcCategories: true,
      range: {
        start: new Date(),
        end: new Date(),
      },
      filter: {
        type: 2,
        view: 1,
        view_type: 1,
        group_by: 1,
        categories_id: [],
      },
    }
  },
  computed: {
    ...mapGetters({
      $_analyzes: "stockMovement/getAnalyze",
      $_categories: 'category/fetch',
      $_latest_closing: "stockClosing/getLatestClosingAt",
    })
  },
  methods: {
    setViewType() {
      this.filter.type === 1 ? this.filter.type = 2 : this.filter.type = 1
      this.init(this.filter)
    },
    async updateLatestStock() {
      await this.$store.dispatch('stockClosing/getLatestClosingAt')
    },
    init(filter = null) {
      this.startCursor(filter)
      this.filter.range = this.range;
      this.$store.dispatch('stockMovement/getAnalyze',
        {
          filter: this.filter,
          per_page: 3,
          next_page: this.paginate.nextUrl
        }).then(response => {
        this.resolveCursor(response)
      }).catch(error => {
        this.resolveCursor()
      })
    },
    textClassFinalPosition(latest_position, amount_stock) {
      let sum = Number(latest_position) + Number(amount_stock)
      if (sum < 0) {
        return 'text-danger'
      }
      if (sum > 0) {
        return 'text-success'
      }
    },
    textClassBreakingValue(value) {
      if (value < 0) {
        return 'text-danger'
      }
      if (value > 0) {
        return 'text-success'
      }
    },
    disableIfHasStock() {
      if (this.$_latest_closing && this.$_latest_closing.closing_at) {
       return new Date(this.$_latest_closing.closing_at)
      }
      return new Date()
    },
    handleFilterDate(filter) {
      this.range = filter
      this.init({})
    },
  },
  async mounted() {
    await this.updateLatestStock()
    this.$store.dispatch('category/fetchItems').then(() => {
      this.loadingCmcCategories = false
    });
    this.loadingSkeleton = true
    this.init({})
  }
}
</script>
