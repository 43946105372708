<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(update)" autocomplete="off">
            <div class="card-header border-header-success-bottom mt-3 mb-2 p-2">
              <h5 class="h4 mb-0" slot="title">Identificação</h5>
            </div>
            <div>
              <!-- Status -->
              <div>
                <div class="form-group row m-0 p-0">
                  <label class="col-md-5 pb-1 col-form-label form-control-label">
                    Status
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-7 mt-1">
                    <base-switch v-model="item.status" type="success" offText="inativo" onText="ativo"
                      class="success"></base-switch>
                  </div>
                </div>
              </div>
              <!-- Tipo -->
              <div>
                <div class="form-group row m-0 p-0">
                  <label class="col-md-5 pb-1 pr-0 col-form-label form-control-label">
                    Tipo
                    <span class="text-danger mr-2">&nbsp;*</span>
                  </label>
                  <div class="col-md-7 mt-1 pr-4">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <base-input input-group-classes="input-group-sm">
                        <puzl-select
                          :items="[
                            {id: 3, name: 'Produtos e Serviços'},
                            {id: 10, name: 'Taxas e Tributos'},
                          ]" 
                          v-model="item.product_service_hub_type_id"
                          @change="handleChangeType"
                          :disabled="oldest_type !== 8 || has_custom_type" />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
              </div>
              <!-- Descrição -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-1 col-form-label form-control-label">
                  Descrição
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7 pr-4 mt-1">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-group-classes="input-group-sm">
                      <input v-model="item.description" type="text" class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Categoria -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 col-form-label form-control-label text-left pr-0">
                  Categoria
                  <span class="text-danger">&nbsp;*</span>
                  <span class="btn btn-sm btn-success p-1 ml-1" @click.prevent="handleCreateCategory()"><i
                      class="fas fa-plus"></i> Novo</span>
                </label>
                <div class="col-md-7 pr-4 mt-1">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-group-classes="input-group-sm">
                      <puzl-select :items="categories" v-model="item.category_id" :loading="loadingCategory"
                        :disabled="loadingCategory" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Unid. de Entrada -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-2 col-form-label form-control-label">
                  Unid. de Entrada
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7 pr-4 mt-1">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-group-classes="input-group-sm">
                      <input v-model="item.input_unit" type="text" class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Observação -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-1 col-form-label form-control-label"> Observação </label>
                <div class="col-md-7 pr-4 mt-0">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="item.observation" type="text" class="form-control form-control-sm" maxlength="250" />
                  </base-input>
                </div>
              </div>
              <div>
                <div class="card-header border-header-danger-bottom mt-3 mb-2 p-2">
                  <h5 class="h4 mb-0" slot="title">Dados complementares</h5>
                </div>
                <div>
                  <div class="form-group row m-0 p-0">
                    <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label"> NCM </label>
                    <div class="col-md-7 mb-1 pr-4">
                      <base-input input-group-classes="input-group-sm">
                        <input v-model="item.ncm" type="text" class="form-control form-control-sm" />
                      </base-input>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0">
                    <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label"> CFOP </label>
                    <div class="col-md-7 mb-1 pr-4">
                      <base-input input-group-classes="input-group-sm">
                        <input v-model="item.cfop" type="text" class="form-control form-control-sm" />
                      </base-input>
                    </div>
                  </div>
                </div>
                <div class="form-group row m-0 p-0">
                  <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label"> CNAE </label>
                  <div class="col-md-7 mb-1 pr-4">
                    <base-input input-group-classes="input-group-sm">
                      <input v-model="item.cnae" type="text" class="form-control form-control-sm" />
                    </base-input>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <base-button type="secondary" @click="closeModal('create')"> Cancelar </base-button>
                <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore"> Salvar
                </base-button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
    <CreateProductServiceCategory @createdCategory="setCategory" ref="modalCreateCategory" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { formatErrorValidation } from "@/plugins";
import CreateProductServiceCategory from "../Category/Shared/_ModalCreate.vue";
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "EditProductService",
  components: { CreateProductServiceCategory, PuzlSelect },
  data() {
    return {
      modal: {
        title: "Despesa",
        create: false,
      },
      loadingStore: false,
      loadingCategory: false,
      oldest_type: 0,
      has_custom_type: false,
    };
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
  computed: {
    ...mapGetters({
      item: "productService/show",
      categories: "productServiceCategory/fetch",
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    handleEditModal(id, custom_type = false, entry_launch_data = false) {
      this.oldest_type = 0;
      this.loadingCategory = true;
      this.has_custom_type = false;
      /* Mcc não é permitido cadastrar */
      if (custom_type === 2) {
        return this.$notify({
          type: "danger",
          message: "O tipo MCC não é permitido nesta operação.",
        });
      }
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });
      let loader = this.$loading.show();
      this.$store
        .dispatch("productService/show", id)
        .then((response) => {
          this.oldest_type = response.data.product_service_hub_type_id
          this.item.entry_launch_data = entry_launch_data ? entry_launch_data : null; // força associação
          loader.hide();
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          if (custom_type !== false) {
            this.has_custom_type = true;
            this.item.product_service_hub_type_id = custom_type
          }
          if (!this.item.code) {
            this.getCode();
          }
          this.getCategories();
          this.modal.create = true;
        })
        .catch(() => {
          loader.hide();
        });
    },
    update() {
      this.$Progress.start();
      this.loadingStore = true;
      this.$store
        .dispatch("productService/update", this.item)
        .then((response) => {
          this.loadingStore = false;
          this.$emit("updated", this.item.id, this.item);
          this.modal.create = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
        })
        .catch((error) => {
          if (error.status == 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
          this.$Progress.finish();
          this.loadingStore = false;
        });
    },
    handleChangeStatus(status) {
      this.item.status = status;
    },
    getCategories() {
      return this.$store.dispatch("productServiceCategory/getCategoriesByType", this.item.product_service_hub_type_id).then((response) => {
        this.loadingCategory = false;
      });
    },
    handleChangeType() {
      this.loadingCategory = true;
      this.item.category_id = null;
      this.$store.dispatch("productServiceCategory/getCategoriesByType", this.item.product_service_hub_type_id).then((response) => {
        this.loadingCategory = false;
      });
    },
    getCode() {
      this.$store.dispatch("productService/getCode").then((response) => {
        this.item.code = response.data ? parseInt(response.data) + 1 : 1;
      });
    },
    handleCreateCategory() {
      this.$refs.modalCreateCategory.handleCreateModal();
    },
    async setCategory(category) {
      this.loadingCategory = true;
      await this.getCategories();
      if (this.item.product_service_hub_type_id === category.product_service_hub_type_id) {
        this.item.category_id = category.id;
      }
    },
  },
};
</script>

<style scoped></style>
