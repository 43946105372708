<template>
<div>
  <modal :show.sync="modal">
    <template slot="header">
      <h5 class="modal-title">{{ title }}</h5>
    </template>
    <div>
      <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
        <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
          <div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                Descrição
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      v-model="payload.description"
                      class="form-control form-control-sm"
                      :class="errors[0] ? 'is-invalid' : 'is-valid'"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <base-button type="secondary" @click="closeModal('create')">
              Cancelar
            </base-button>
            <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
              Salvar
            </base-button>
          </div>
        </form>
      </validation-observer>
    </div>
  </modal>
</div>
</template>

<script>
const {formatErrorValidation} = require("@/plugins")
export default {
  name: "CreateMold",
  data () {
    return {
      modal: false,
      title: 'Adicionar Molde',
      payload: {
        hardened_state_uuid: null,
        description: null,
      },
      loadingStore: false,
    }
  },
  methods: {
    closeModal () {
      this.modal= false
    },
    handleCreateModal (hardenedStateUuid) {
      this.payload = {
        hardened_state_uuid: hardenedStateUuid,
        description: null,
      }
      this.modal= true
    },
    store () {
      this.$Progress.start()
      this.loadingStore = true
      this.$store.dispatch('testHardenedStateMold/add', this.payload)
        .then(response => {
          this.loadingStore = false
          this.modal = false
          this.payload = {
            hardened_state_uuid: null,
            description: null,
          }
          this.$store.dispatch('testHardenedState/fetchItems')
          this.$Progress.finish()
          this.$notify({ type: response.error_type, message: response.message })
        })
        .catch(error =>{
          if (error.status) {
            this.$Progress.finish()
            this.loadingStore = false
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            })
          } else {
            if (error.response.status === 422) {
              let message = formatErrorValidation(error.response.data.errors)
              this.$notify({
                type: 'danger',
                message: message
              })
              this.$Progress.finish()
              this.loadingStore = false
            } else {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              })
              this.$Progress.finish()
              this.loadingStore = false
            }
          }
        })
    },
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
}
</script>

<style scoped>

</style>
