<template>
  <div>
    <modal :show.sync="modal.show">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div v-if="bankAccountBilletParam">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(submitAndClose)" autocomplete="off">
            <div class="row mb-2 m-0 p-0">
              <div class="col-md-4 mb-1">
                <base-button @click.prevent="tabIndex = 0" :class="tabIndex === 0 ? 'active' : ''" block outline type="primary"
                  ><i class="fa-solid fa-file-invoice"></i>Geral
                </base-button>
              </div>
              <div class="col-md-4 mb-1">
                <base-button @click.prevent="tabIndex = 1" :class="tabIndex === 1 ? 'active' : ''" block outline type="primary"
                  ><i class="fa-solid fa-money-check-dollar"></i>Valores
                </base-button>
              </div>
              <div class="col-md-4 mb-1">
                <base-button @click.prevent="tabIndex = 2" :class="tabIndex === 2 ? 'active' : ''" block outline type="primary"
                  ><i class="fa-solid fa-circle-plus"></i>Adicional
                </base-button>
              </div>
            </div>

            <!-- 0-Aba Geral -->
            <div v-show="tabIndex === 0">
              <div class="form-group row m-0 mt-3 p-0 mb-2 limited-row">
                <label
                  class="col-md-6  pt-2 pb-0 col-form-label form-control-label"
                >
                  CNPJ emissor
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-6">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-group-classes="input-group-sm">
                      <el-select
                        v-model="bankAccountBilletParam.company_plant_issuer_id"
                        size="mini"
                        placeholder="Selecione"
                        filterable
                        :loading="isLoadingCompanyPlantIssuers"
                      >
                        <el-option
                          v-for="(item, index) in $_company_plant_issuers"
                          :key="index"
                          :label="item.alias_name"
                          :value="item.id"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label">
                  Enviar CNPJ beneficiário ?                  
                </label>
                <div class="col-md-6 pt-1">
                  <base-switch v-model="bankAccountBilletParam.send_guarantor_data" type="success" offText="Não" onText="Sim"> </base-switch>
                </div>
              </div>
              <div class="card-header border-header-primary-bottom mt-3 mb-2 p-2">
                <h5 class="h4 mb-0" slot="title">Registro bancário</h5>
              </div>
              <!-- Layout CNAB -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label">
                  Layout CNAB
                  <span class="text-danger">&nbsp;*</span>
                  <el-popover title="Layout CNAB" placement="right" class="p-0">
                    <span>
                      Layout para registro de cobranças em um molde padrão.
                      <mark>Exemplos: 240, 480, ...</mark>
                    </span>
                    <base-button outiline slot="reference" size="sm" type="secundary" class="p-0 m-0 btn-rounded">
                      <span class="badge mr-1 ml-1" style="background-color: #d3d3d3">?</span>
                    </base-button>
                  </el-popover>
                </label>
                <div class="col-md-6 pt-1">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-group-classes="input-group-sm">
                      <input v-model="bankAccountBilletParam.cnab_layout" type="number" class="form-control form-control-sm" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Código do Cedente\Convênio -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label">
                  Cód. cedente\convênio
                  <span class="text-danger">&nbsp;*</span>
                  <el-popover title="Código do cedente/convênio" placement="right" class="p-0">
                    <span>
                      Código fornecido pelo gerente de sua conta.
                      <mark>Geralmente formado pelo código da conta sem o dígito.</mark>
                    </span>
                    <base-button outiline slot="reference" size="sm" type="secundary" class="p-0 m-0 btn-rounded">
                      <span class="badge mr-1 ml-1" style="background-color: #d3d3d3">?</span>
                    </base-button>
                  </el-popover>
                </label>
                <div class="col-md-6 pt-1">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-group-classes="input-group-sm">
                      <input v-model="bankAccountBilletParam.assignor_code" type="text" class="form-control form-control-sm" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Código do Transmissão -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label">
                  Cód. transmissão
                  <el-popover title="Código de transmissão" placement="right" class="p-0">
                    <span> Confirme com o gerente de sua conta se é necessário preencher este campo. </span>
                    <base-button outiline slot="reference" size="sm" type="secundary" class="p-0 m-0 btn-rounded">
                      <span class="badge mr-1 ml-1" style="background-color: #d3d3d3">?</span>
                    </base-button>
                  </el-popover>
                </label>
                <div class="col-md-6 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="bankAccountBilletParam.transmission_code" type="text" class="form-control form-control-sm" />
                  </base-input>
                </div>
              </div>
              <!-- Carteira -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label">
                  Carteira
                  <span class="text-danger">&nbsp;*</span>
                  <el-popover title="Carteira" placement="right" class="p-0">
                    <span>
                      Código da carteira habilitada e fornecida pelo seu banco.
                      <mark>Exemplos: 102, 103, 104, 121, ...</mark>
                    </span>
                    <base-button outiline slot="reference" size="sm" type="secundary" class="p-0 m-0 btn-rounded">
                      <span class="badge mr-1 ml-1" style="background-color: #d3d3d3">?</span>
                    </base-button>
                  </el-popover>
                </label>
                <div class="col-md-6 pt-1">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-group-classes="input-group-sm">
                      <input v-model="bankAccountBilletParam.bank_wallet" type="number" class="form-control form-control-sm" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Carteira ID Kobana Boleto -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label">
                  ID da carteira no MS
                  <!-- <span class="text-danger">&nbsp;*</span> -->
                  <el-popover title="Carteira" placement="right" class="p-0">
                    <span>
                      Verifique o valor deste campo com a nossa equipe puzl.
                    </span>
                    <base-button outiline slot="reference" size="sm" type="secundary" class="p-0 m-0 btn-rounded">
                      <span class="badge mr-1 ml-1" style="background-color: #d3d3d3">?</span>
                    </base-button>
                  </el-popover>
                </label>
                <div class="col-md-6 pt-1">
                    <base-input input-group-classes="input-group-sm">
                      <input v-model="bankAccountBilletParam.bank_wallet_id" type="number" class="form-control form-control-sm" />
                    </base-input>
                </div>
              </div>
              <!-- Tipo de Cobrança -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label">
                  Tipo de cobrança <span class="text-danger">&nbsp;*</span></label
                >
                <div class="col-md-6 pt-1">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-group-classes="input-group-sm">
                      <el-select v-model="bankAccountBilletParam.charge_type" size="mini" placeholder="Selecione" filterable>
                        <el-option v-for="item in charge_type_list" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Aceite -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label">
                  Aceite
                  <el-popover title="Aceite" placement="right" class="p-0">
                    <span> Utilize "Sim" quando o pagador reconhece(assina) formalmente o título. </span>
                    <base-button outiline slot="reference" size="sm" type="secundary" class="p-0 m-0 btn-rounded">
                      <span class="badge mr-1 ml-1" style="background-color: #d3d3d3">?</span>
                    </base-button>
                  </el-popover>
                </label>
                <div class="col-md-6 pt-1">
                  <base-switch v-model="bankAccountBilletParam.acceptance" type="success" offText="Não" onText="Sim"> </base-switch>
                </div>
              </div>
              <!-- Espécie da Moeda -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label">
                  Espécie (Moeda)
                  <span class="text-danger">&nbsp;*</span>
                  <el-popover title="Espécie (Moeda)" placement="right" class="p-0">
                    <span> Exemplo: R$. </span>
                    <base-button outiline slot="reference" size="sm" type="secundary" class="p-0 m-0 btn-rounded">
                      <span class="badge mr-1 ml-1" style="background-color: #d3d3d3">?</span>
                    </base-button>
                  </el-popover>
                </label>
                <div class="col-md-6 pt-1">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-group-classes="input-group-sm">
                      <input v-model="bankAccountBilletParam.currency_type" type="text" class="form-control form-control-sm" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Espécie do Documento -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label">
                  Espécie do documento
                  <span class="text-danger">&nbsp;*</span>
                  <el-popover title="Espécie de documento" placement="right" class="p-0">
                    <span>
                      Abreviação do tipo de documento. Não é necessário informar a descrição.
                      <mark>Exemplo: Abreviação: DM Descrição: Duplicata Mercantil (Padrão)</mark>
                    </span>
                    <base-button outiline slot="reference" size="sm" type="secundary" class="p-0 m-0 btn-rounded">
                      <span class="badge mr-1 ml-1" style="background-color: #d3d3d3">?</span>
                    </base-button>
                  </el-popover>
                </label>
                <div class="col-md-6 pt-1">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-group-classes="input-group-sm">
                      <input v-model="bankAccountBilletParam.document_type_abbreviation" type="text" class="form-control form-control-sm" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Envio da cobrança -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label">
                  Envio da cobrança <span class="text-danger">&nbsp;*</span></label
                >
                <div class="col-md-6 pt-1">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-group-classes="input-group-sm">
                      <el-select v-model="bankAccountBilletParam.dispatch_type" size="mini" placeholder="Selecione" filterable>
                        <el-option v-for="item in dispatch_type_list" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="card-header border-header-primary-bottom mt-3 mb-2 p-2">
                <h5 class="h4 mb-0" slot="title">Instruções</h5>
              </div>
              <!-- Primeira Instrução -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label"> Primeira instrução </label>
                <div class="col-md-6 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="bankAccountBilletParam.first_instruction" type="text" class="form-control form-control-sm" />
                  </base-input>
                </div>
              </div>
              <!-- Segunda Instrução -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label"> Segunda instrução </label>
                <div class="col-md-6 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="bankAccountBilletParam.second_instruction" type="text" class="form-control form-control-sm" />
                  </base-input>
                </div>
              </div>
              <!-- Terceira Instrução -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label"> Terceira instrução </label>
                <div class="col-md-6 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="bankAccountBilletParam.third_instruction" type="text" class="form-control form-control-sm" />
                  </base-input>
                </div>
              </div>
              <!-- Quarta Instrução -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label"> Quarta instrução </label>
                <div class="col-md-6 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="bankAccountBilletParam.fourth_instruction" type="text" class="form-control form-control-sm" />
                  </base-input>
                </div>
              </div>
              <!-- Instrução para o caixa -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label"> Instrução para o caixa </label>
                <div class="col-md-6 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="bankAccountBilletParam.instruction_to_the_cashier" type="text" class="form-control form-control-sm" />
                  </base-input>
                </div>
              </div>
            </div>

            <!-- 1-Aba Valores -->
            <div v-show="tabIndex === 1">
              <div class="card-header border-header-danger-bottom mt-3 mb-2 p-2">
                <h5 class="h4 mb-0" slot="title">Cobrança de juros</h5>
              </div>
              <!-- Tipo de Juros -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label"> Tipo <span class="text-danger">&nbsp;*</span></label>
                <div class="col-md-6 pt-1">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-group-classes="input-group-sm">
                      <el-select v-model="bankAccountBilletParam.interest_type" size="mini" placeholder="Selecione" filterable>
                        <el-option v-for="item in interest_type_list" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Quantos dias após o vencimento deve cobrar juros -->
              <div v-if="bankAccountBilletParam.interest_type != 0">
                <div class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label"> Dias após o vencimento </label>
                  <div class="col-md-6 pt-1">
                    <base-input input-group-classes="input-group-sm">
                      <input v-model="bankAccountBilletParam.days_for_interest" type="number" class="form-control form-control-sm" />
                    </base-input>
                  </div>
                </div>
                <!-- Dias Corridos/Úteis -->
                <div class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label"> Dias Corridos/Úteis </label>
                  <div class="col-md-6 pt-1">
                    <base-input input-group-classes="input-group-sm">
                      <el-select v-model="bankAccountBilletParam.interest_days_type" size="mini" placeholder="Selecione" filterable>
                        <el-option v-for="item in interest_days_type_list" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                      </el-select>
                    </base-input>
                  </div>
                </div>
                <!-- Valor do Juros -->
                <div class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label"> Valor </label>
                  <div class="col-md-6 pt-1">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model="bankAccountBilletParam.interest_value"
                        v-money="money"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <template slot="prepend">
                        <small v-if="bankAccountBilletParam.interest_type == 2" class="input-group-sm p-0 m-0"> R$ </small>
                        <small v-else="" class="input-group-sm p-0 m-0"> % </small>
                      </template>
                    </base-input>
                  </div>
                </div>
              </div>

              <div class="card-header border-header-danger-bottom mt-3 mb-2 p-2">
                <h5 class="h4 mb-0" slot="title">Cobrança de multa</h5>
              </div>
              <!-- Tipo de Multa -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label"> Tipo <span class="text-danger">&nbsp;*</span></label>
                <div class="col-md-6 pt-1">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-group-classes="input-group-sm">
                      <el-select v-model="bankAccountBilletParam.fine_type" size="mini" placeholder="Selecione" filterable>
                        <el-option v-for="item in fine_type_list" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div v-if="bankAccountBilletParam.fine_type != 0">
                <!-- Quantos dias após o vencimento deve cobrar multa -->
                <div class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label"> Dias após o vencimento </label>
                  <div class="col-md-6 pt-1">
                    <base-input input-group-classes="input-group-sm">
                      <input v-model="bankAccountBilletParam.days_for_fine" type="number" class="form-control form-control-sm" />
                    </base-input>
                  </div>
                </div>
                <!-- Valor da Multa -->
                <div class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label"> Valor </label>
                  <div class="col-md-6 pt-1">
                    <base-input input-group-classes="input-group-sm">
                      <input v-model="bankAccountBilletParam.fine_value" v-money="money" type="text" class="form-control form-control-sm" />
                      <template slot="prepend">
                        <small v-if="bankAccountBilletParam.fine_type == 2" class="input-group-sm p-0 m-0"> R$ </small>
                        <small v-else="" class="input-group-sm p-0 m-0"> % </small>
                      </template>
                    </base-input>
                  </div>
                </div>
              </div>

              <div class="card-header border-header-warning-bottom mt-3 mb-2 p-2">
                <h5 class="h4 mb-0" slot="title">Conceder desconto</h5>
              </div>
              <!-- Tipo de desconto -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label"> Tipo <span class="text-danger">&nbsp;*</span></label>
                <div class="col-md-6 pt-1">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-group-classes="input-group-sm">
                      <el-select v-model="bankAccountBilletParam.discount_type" size="mini" placeholder="Selecione" filterable>
                        <el-option v-for="item in discount_type_list" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div v-if="bankAccountBilletParam.discount_type != 0">
                <!-- Quantos dias anterior ao vencimento deve conceder desconto -->
                <div class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label"> Dias anterior ao vencimento </label>
                  <div class="col-md-6 pt-1">
                    <base-input input-group-classes="input-group-sm">
                      <input v-model="bankAccountBilletParam.days_for_discount" type="number" class="form-control form-control-sm" />
                    </base-input>
                  </div>
                </div>
                <!-- Valor do Desconto -->
                <div class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label"> Valor </label>
                  <div class="col-md-6 pt-1">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model="bankAccountBilletParam.discount_value"
                        v-money="money"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <template slot="prepend">
                        <small v-if="bankAccountBilletParam.discount_type == 2" class="input-group-sm p-0 m-0"> R$ </small>
                        <small v-else="" class="input-group-sm p-0 m-0"> % </small>
                      </template>
                    </base-input>
                  </div>
                </div>
              </div>
            </div>

            <!-- 2-Adicional -->
            <div v-show="tabIndex === 2">
              <div class="card-header border-header-primary-bottom mt-3 mb-2 p-2">
                <h5 class="h4 mb-0" slot="title">Protesto</h5>
              </div>
              <!-- Dias corridos para protesto -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label"> Dias corridos para protestar </label>
                <div class="col-md-6 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="bankAccountBilletParam.days_for_sue" type="number" class="form-control form-control-sm" />
                  </base-input>
                </div>
              </div>
              <!-- Código do protesto -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label"> Código do protesto </label>
                <div class="col-md-6 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="bankAccountBilletParam.sue_code" type="text" class="form-control form-control-sm" />
                  </base-input>
                </div>
              </div>
              <!-- Dias para negativação -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label"> Dias para negativação </label>
                <div class="col-md-6 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="bankAccountBilletParam.days_for_negativation" type="number" class="form-control form-control-sm" />
                  </base-input>
                </div>
              </div>
              <div class="card-header border-header-primary-bottom mt-3 mb-2 p-2">
                <h5 class="h4 mb-0" slot="title">Outros</h5>
              </div>
              <!-- Custo adicional do boleto -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label"> Custo adicional do boleto </label>
                <div class="col-md-6 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-model="bankAccountBilletParam.additional_billet_cost"
                      v-money="money"
                      type="text"
                      class="form-control form-control-sm"
                    />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0"> R$ </small>
                    </template>
                  </base-input>
                </div>
              </div>
              <!-- Float Bancário -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label"> Float bancário </label>
                <div class="col-md-6 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="bankAccountBilletParam.bank_float" v-money="money" type="text" class="form-control form-control-sm" />
                  </base-input>
                </div>
              </div>
              <!-- Float bancário para tarifas -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label"> Float bancário para tarifas </label>
                <div class="col-md-6 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-model="bankAccountBilletParam.bank_float_for_fees"
                      v-money="money"
                      type="text"
                      class="form-control form-control-sm"
                    />
                  </base-input>
                </div>
              </div>
              <!-- Próximo número da remessa -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pb-1 pb-0 col-form-label form-control-label"> Próximo número da remessa </label>
                <div class="col-md-6 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="bankAccountBilletParam.next_remittance_number" type="number" class="form-control form-control-sm" />
                  </base-input>
                </div>
              </div>
            </div>

            <!-- MODAL FOOTER -->
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()"> Cancelar </base-button>
              <base-button type="success" native-type="submit" :disabled="invalid" :loading="isLoadingStore"> Salvar </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
const { formatErrorValidation } = require("@/plugins");
import {mapGetters} from "vuex";

export default {
  name: "ModalBilletParamCreateEdit",
  data() {
    return {
      isLoadingStore: false,
      tabIndex: 0,
      isDataCreating: true,
      isLoadingCompanyPlantIssuers: false,
      modal: {
        title: "Configurações do Boleto",
        show: false,
      },
      bankAccountId: null,
      bankAccountBilletParam: null,
      charge_type_list: [
        { id: 0, name: "Simples" },
        { id: 1, name: "Vinculada" },
        { id: 2, name: "Descontada" },
        { id: 3, name: "Vendor" },
        { id: 4, name: "Caucionada" },
      ],
      dispatch_type_list: [
        { id: 0, name: "Cliente" },
        { id: 1, name: "Banco" },
      ],
      interest_type_list: [
        { id: 0, name: "Sem juros" },
        { id: 1, name: "Porcentagem diária" },
        { id: 2, name: "Valor diário" },
      ],
      fine_type_list: [
        { id: 0, name: "Sem multa" },
        { id: 1, name: "Porcentagem do valor do boleto" },
        { id: 2, name: "Valor fixo" },
      ],
      discount_type_list: [
        { id: 0, name: "Sem desconto" },
        { id: 1, name: "Porcentagem do valor do boleto" },
        { id: 2, name: "Valor fixo" },
      ],
      interest_days_type_list: [
        { id: 0, name: "Dias corridos" },
        { id: 1, name: "Dias úteis" },
      ],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      $_company_plant_issuers: "companyPlantIssuer/fetch"
    })
  },
  mounted() {
    this.isLoadingCompanyPlantIssuers = true
    this.$store.dispatch('companyPlantIssuer/fetchItems')
      .then(response => {
        this.isLoadingCompanyPlantIssuers = false
        this.$notify({
          type: response.error_type,
          message: response.message
        });
      })
      .catch(() => {
        this.isLoadingCompanyPlantIssuers = false
      });
  },  
  methods: {
    closeModal() {
      this.modal.show = false;
    },
    handleCreateModal(bankAccountId) {
      this.tabIndex = 0;
      this.bankAccountId = bankAccountId;
      this.$Progress.start();
      this.$store
        .dispatch("bankAccountBilletParam/showByBankAccountId", this.bankAccountId)
        .then((response) => {
          this.bankAccountBilletParam = this.formatBeforeShow({...response.data});
          this.isDataCreating = false;
          this.modal.show = true;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.clearData();
          this.isDataCreating = true;
          this.modal.show = true;
          this.$Progress.finish();
        });
    },
    clearData() {
      this.bankAccountBilletParam = {
        bank_account_id: this.bankAccountId,
        cnab_layout: null,
        assignor_code: "",
        transmission_code: "",
        bank_wallet: null,
        bank_wallet_id: null,
        charge_type: 0,
        acceptance: false,
        currency_type: "R$",
        document_type_abbreviation: "DM",
        interest_type: 0,
        days_for_interest: 0,
        interest_days_type: 1,
        interest_value: 0,
        fine_type: 0,
        days_for_fine: 0,
        fine_value: 0,
        discount_type: 0,
        days_for_discount: 0,
        discount_value: 0,
        days_for_sue: 0,
        sue_code: "",
        days_for_negativation: 0,
        dispatch_type: 0,
        first_instruction: "",
        second_instruction: "",
        third_instruction: "",
        fourth_instruction: "",
        instruction_to_the_cashier: "",
        additional_billet_cost: 0,
        bank_float: 0,
        bank_float_for_fees: 0,
        next_remittance_number: 0,
        send_guarantor_data: true,
      };
    },
    submitAndClose() {
      this.$Progress.start();
      this.isLoadingStore = true;
      let payload = {
        bank_account_id: this.bankAccountBilletParam.bank_account_id,
        data: this.formatBeforeSave(),
      };
      this.$store
        .dispatch("bankAccountBilletParam/addOrUpdate", payload)
        .then((response) => {
          this.isLoadingStore = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.closeModal();
        })
        .catch((error) => {
          if (error.status === 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
            this.$Progress.finish();
            this.isLoadingStore = false;
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
            this.$Progress.finish();
            this.isLoadingStore = false;
          }
        });
    },
    formatBeforeSave() {
      let payload = { ...this.bankAccountBilletParam };
      // Evitar envio de string em campo number
      payload.additional_billet_cost = this.$helper.strToNum(payload.additional_billet_cost);
      payload.bank_float = this.$helper.strToNum(payload.bank_float);
      payload.bank_float_for_fees = this.$helper.strToNum(payload.bank_float_for_fees);
      payload.discount_value = this.$helper.strToNum(payload.discount_value);
      payload.fine_value = this.$helper.strToNum(payload.fine_value);
      payload.interest_value = this.$helper.strToNum(payload.interest_value);
      return payload;
    },
    formatBeforeShow(payload){
      // Evitar erro de casa decimal ao exibir dados na tela
      payload.additional_billet_cost = this.$helper.strToNum(payload.additional_billet_cost).toFixed(2);
      payload.bank_float = this.$helper.strToNum(payload.bank_float).toFixed(2);
      payload.bank_float_for_fees = this.$helper.strToNum(payload.bank_float_for_fees).toFixed(2);
      payload.discount_value = this.$helper.strToNum(payload.discount_value).toFixed(2);
      payload.fine_value = this.$helper.strToNum(payload.fine_value).toFixed(2);
      payload.interest_value = this.$helper.strToNum(payload.interest_value).toFixed(2);
      return payload;
    }   
  },
};
</script>

<style scoped></style>
