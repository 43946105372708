<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
            <div>

              <div class="form-group row m-0 p-0 m-1">
                <label class="col-md-5 col-form-label form-control-label">
                  Status
                </label>
                <div class="col-md-7 pt-2">
                  <base-switch v-model="supplier.status" type="success" offText="inativo" onText="ativo" class="success"></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pb-0 col-form-label form-control-label">
                  Nome do Fornecedor
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input 
                        type="text" v-model="supplier.supplier_name" class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pb-0 col-form-label form-control-label">
                  Website
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <input type="text" v-model="supplier.website" class="form-control form-control-sm"/>
                  </base-input>
                </div>
              </div>

            </div>

            <div class="modal-footer">
              <base-button type="secondary" @click="openModal(false)">
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingSave">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
const {formatErrorValidation} = require("@/plugins")
export default {
  name: "EditAdditionalClassification",
  data () {
    return {
      title:'Fornecedor MCC',
      modal: false,
      supplier: {
        status: true,
        supplier_name: '',
        website: '',
      },
      loadingSave: false,
    }
  },
  methods: {
    openModal (open) {
        this.modal = open
    },
    handleEditModal (uuid) {
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'})
      this.$Progress.start()
      this.$store.dispatch('cmcSupplier/show', uuid)
        .then( (response) => {
          this.supplier = {
            uuid: response.data.uuid || null,
            status: response.data.status || false,
            supplier_name: response.data.supplier_name || null ,
            website: response.data.website || null,
          }
          this.modal = true
          this.$notify({type: response.error_type, message: response.message})
          this.$Progress.finish()
        }).catch((error) => {
          if(error.status===422){
            let message = formatErrorValidation(error.response.data.errors)
            this.$notify({ type: 'danger', message: message })
          }else{
            this.$notify({ type: error.data.error_type, message: error.data.message })
          }
          this.$Progress.finish()
          this.loadingSave = false
      })
    },
    save () {
      this.$Progress.start()
      this.loadingSave = true
      this.$store.dispatch('cmcSupplier/update', this.supplier)
        .then(response => {
          this.loadingSave = false
          this.modal = false
          this.$Progress.finish()
          this.$notify({ type: response.error_type, message: response.message })
        })
        .catch(error =>{
          if(error.response.status===422){
            let message = formatErrorValidation(error.response.data.errors)
            this.$notify({ type: 'danger', message: message })
          }else{
            this.$notify({ type: error.data.error_type, message: error.data.message })
          }
          this.$Progress.finish()
          this.loadingSave = false
        })
    },
  },
  mounted () {
    this.$refs.formValidator.validate();
  },
  created () {
  }
}
</script>

<style scoped>

</style>
