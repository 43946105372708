<template>
  <div class="card-body mt-n2 p-0">
    <div class="row">
      <ShadedTable :length="hardened_states.length"
                   :headers="headers"
                   :loading="loadingSkeleton">
        <template v-for="(item, index) in hardened_states" :slot="index">
          <ShadedCol align="center">
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.sampling_at | parseDate('DD/MM/yyyy') }}
            </h5>
          </ShadedCol>
          <ShadedCol align="center">
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.number }}
            </h5>
          </ShadedCol>
          <ShadedCol align="center">
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.xml_travel_number }}
            </h5>
          </ShadedCol>
          <ShadedCol align="center">
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.code }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <div>
              <div class="contract-proposal-code">
                <h5 class="m-0">
                  {{ item.contract_proposal_code }}
                </h5>
              </div>
              <h5 style="display: inline-flex;"
                  class="new-default-black-font font-weight-normal m-0 ml-2">
                {{ item.schedule.construction }}
              </h5>
            </div>
          </ShadedCol>
          <ShadedCol>
            <div style="display: grid;grid-template-columns: auto auto;">
              <h5 style="display: inline-flex;" class="new-default-black-font m-0 mt-1">
                {{
                  technicalPermissions.is_view_technical_data_of_traces_in_test_results_launch ?
                    `${item.feature} ${format_mixe(item.period, item.period_type)} ${item.item_description}` :
                    ''
                }}
              </h5>
              <div style="text-align: end;display: inline-flex;flex-direction: row-reverse;align-items: center;">
                <div class="mt-1"
                     :class="item.molder && item.result ? 'mini-card-blue' : item.molder && !item.result ? 'mini-card-yellow' : 'mini-card-red'">
                  <h4 class="font-weight-normal m-0 p-0">
                    {{
                      item.molder && item.result ? 'Ensaiado' : item.molder && !item.result ? 'Pendente' : 'Descartado'
                    }}
                  </h4>
                </div>
              </div>
            </div>
          </ShadedCol>
          <ShadedCol align="center">
            <div>
              <h5 style="display: inline-flex;"
                  class="new-default-black-font m-0">
                {{ format_mixe(item.schedule_period, item.schedule_period_type, true) }}
              </h5>
              <h5 style="display: inline-flex;"
                  class="new-default-black-font font-weight-normal m-0 ml-2">
                ({{ item.provided_at | parseDate }})
              </h5>
            </div>
          </ShadedCol>
          <ShadedCol align="center" customStyle="width: 100%;">
            <div class="grid-false-center">
              <div>
                <el-popover v-if="item.anomalies_extract" trigger="hover" placement="top" class="p-0 pr-0 mr-1">
                  <h5 class="mb-n1" v-for="anomaly in item.anomalies_extract.split(',')">{{ anomaly }}</h5>
                  <img slot="reference" class="pointer" width="23" src="/img/icons/icons8/ios/question--v1.png">
                </el-popover>
              </div>
              <div>
                <h5 class="new-default-black-font text-center m-0 mt-1">
                  {{ item.result ? `${item.result.toString().replace('.', ',')} ${item.letter}` : '' }}
                </h5>
              </div>
              <div class="pointer m-0 text-right" @click.prevent="$emit('handleEdit', item)">
                <img width="23" src="/img/icons/create-new.png">
              </div>
            </div>
          </ShadedCol>
          <ShadedCol align="center">
            <div>
              <base-switch
                @input="updateItem(item)"
                v-model="item.report"
                type="success"/>
            </div>
          </ShadedCol>
          <ShadedCol align="center">
            <div>
              <base-switch
                @input="updateItem(item)"
                v-model="item.statistical"
                type="success"/>
            </div>
          </ShadedCol>
          <ShadedCol align="center">
            <div>
              <el-popover trigger="click" placement="bottom">
                <span style="width: 290px;"
                      class="row">
                  <div class="col-12 mb-3" style="display: flex; align-items: center;">
                    <img class="mr-2" src="/img/icons/icons8/ios/info-circle_warning.png" width="22">
                    <h4 style="font-size: 16px;" class="p-0 m-0">Detalhes</h4>
                  </div>
                    <div class="col-12" v-if="item.user_name">
                    <h5 class="font-weight-normal"><b class="mr-2">Responsável:</b> {{ item.user_name }}</h5>
                  </div>
                  <div class="col-12">
                    <h5 class="font-weight-normal"><b class="mr-2">M.E:</b>{{ item.me }} KG/M3</h5>
                  </div>
                  <div class="col-12">
                    <h5 class="font-weight-normal"><b
                      class="mr-2">Cura:</b>{{ item.health ? healths[item.health] : '-' }}</h5>
                  </div>
                  <div class="col-12">
                    <h5 class="font-weight-normal"><b class="mr-2">Amostragem:</b>{{
                        item.molding_location_name
                      }}</h5>
                  </div>
                  <div class="col-12">
                    <h5 class="font-weight-normal"><b class="mr-2">Base inferior:</b>
                      <i style="font-style: normal;" v-if="item.bottom_base"
                         v-for="(base, key) in item.bottom_base">
                        {{ base }} <span v-if="item.bottom_base.length -1 != key">+</span>
                      </i>
                      <i style="font-style: normal;" v-if="item.bottom_base < 1">-</i>
                    </h5>
                  </div>
                  <div class="col-12">
                    <h5 class="font-weight-normal"><b class="mr-2">Base superior:</b>
                      <i style="font-style: normal;" v-for="(base, key) in item.top_base">
                        {{ base }} <span v-if="item.top_base.length -1 != key">+</span>
                      </i>
                      <i style="font-style: normal;" v-if="item.top_base < 1"> - </i>
                    </h5>
                  </div>
                  <div class="col-12">
                    <h5 class="font-weight-normal"><b class="mr-2">Padrão de rompimento:</b>
                      {{ item.disruption ? disruptions.find(disrupt => disrupt.id === item.disruption).text : '-' }}
                    </h5>
                  </div>
                  <div class="col-12">
                    <h5 class="font-weight-normal"><b
                      class="mr-2">Observações:</b>{{ item.observation ? item.observation : '-' }}</h5>
                  </div>
                </span>
                <base-button outline slot="reference" size="sm" type="secundary"
                             class="p-0 m-0 mr-2 shadow-none text-indigo">
                  <img src="/img/icons/icons8/ios/info-circle_warning.png" width="25">
                </base-button>
              </el-popover>
              <el-popover
                class="p-0"
                placement="right"
                trigger="click">
                <div v-if="!technicalPermissions.is_view_technical_data_of_traces_in_test_results_launch"
                     style="width: 170px; word-break: break-word;">
                  <h5 class="text-danger">
                    Sem permissão
                  </h5>
                  <div class="new-default-black-font" style="font-size: 12px;">
                    Visualização indisponível, solicite permissão ao gestor.
                  </div>
                </div>
                <div v-if="technicalPermissions.is_view_technical_data_of_traces_in_test_results_launch"
                     class="border-left border-3 border-primary p-2 mt-1">
                  <h5 class="h4 mb-0">
                    {{ item.schedule.contract_proposal_formulation.title }}
                  </h5>
                  <div class="mt-2 mb-3">
                    <h5 class="small">
                      {{ item.schedule.contract_proposal_formulation.body }}
                    </h5>
                  </div>
                  <div class="mt-2 mb-3" v-if="item.schedule.contract_proposal_formulation.footer">
                    <h5 class="small">
                      {{ item.schedule.contract_proposal_formulation.footer }}
                    </h5>
                  </div>
                  <div class="mt-2 mb-3" v-if="item.schedule.level_feature">
                    <h5 class="small" v-if="item.schedule.level_feature === 1">
                      Aplicação Baixa
                    </h5>
                    <h5 class="small" v-else-if="item.schedule.level_feature === 2">
                      Aplicação Média
                    </h5>
                    <h5 class="small" v-else-if="item.schedule.level_feature === 3">
                      Aplicação Alta
                    </h5>
                  </div>
                </div>
                <div v-if="technicalPermissions.is_view_technical_data_of_traces_in_test_results_launch"
                     class="border-left border-3 border-info p-1 mt-2">
                  <span style="font-size: 12px;" class="numeric m-0 pl-1">
                    {{ item.schedule.piece }}
                  </span>
                </div>
                <base-button outline slot="reference" size="sm" type="secundary"
                             class="p-0 m-0 mr-2 shadow-none text-indigo">
                  <img src="/img/icons/test-tube.png" width="25">
                </base-button>
              </el-popover>
              <el-popover trigger="click" placement="left">
                <div
                  class="mt-2 border-left border-3 border-indigo p-2">
                  <div>
                    <span>
                      <span class="text-center text-dark">
                        {{ item.equipment_code }}
                      </span>
                      <br/>
                      <span class="text-center">
                        {{ item.driver_name }}
                      </span>
                    </span>
                  </div>
                </div>
                <base-button outline slot="reference" size="sm" type="secundary"
                             class="p-0 m-0 mr-2 shadow-none text-indigo">
                  <img src="/img/icons/in-transit.png" width="25">
                </base-button>
              </el-popover>
              <el-popover trigger="click" placement="left">
                <span style="width: 290px;"
                      class="row">
                  <div class="col-12 mb-2" style="display: flex; align-items: center;">
                    <img class="mr-2" src="/img/icons/chemical-plant-black.png" width="22">
                    <h4 style="font-size: 14px;" class="p-0 m-0 font-weight-500">Central e ponto de carga</h4>
                  </div>
                  <div class="col-12 mt-0 mb-n2">
                    <h5 class="font-weight-normal">{{ item.plant }}</h5>
                  </div>
                  <div class="col-12">
                    <h5 class="font-weight-normal" v-if="item.charge_point">PC ({{ item.charge_point }})</h5>
                  </div>
                </span>
                <base-button outline slot="reference" size="sm" type="secundary"
                             class="p-0 m-0 mr-2 shadow-none text-indigo">
                  <img src="/img/icons/chemical-plant-black.png" width="25">
                </base-button>
              </el-popover>
              <el-popover trigger="click" placement="left">
                <div
                  class="mt-0 p-2"
                >
                  <div>
                                    <span>
                                      <template v-if="item.equipment_integrated_id">
                                         <span class="text-center text-primary font-weight-600">
                                        RESULTADO IMPORTADO
                                      </span>
                                      <br/>
                                      </template>
                                      <span class="text-center text-dark">
                                        {{ item.updated_user_name }}
                                      </span>
                                      <br/>
                                      <span class="text-center">
                                        {{ item.updated_at | parseDate }}
                                      </span>
                                    </span>
                  </div>
                </div>
                <base-button v-if="item.updated_user_name" outline slot="reference" size="sm" type="secundary"
                             class="p-0 m-0 mr-2 shadow-none text-indigo">
                  <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="25">
                </base-button>
              </el-popover>
            </div>
          </ShadedCol>
        </template>
      </ShadedTable>
    </div>
  </div>
</template>
<script>
import {period_types} from "@/views/Modules/Technology/Mix/helpers/mixe";
import ShadedTable from "@/components/ShadedTable.vue";
import ShadedCol from "@/components/ShadedCol.vue";

export default {
  name: "TableTests",
  props: {
    hardened_states: Array,
    loadingSkeleton: Boolean,
    technicalPermissions: Object,
  },
  components: {
    ShadedTable,
    ShadedCol
  },
  data() {
    return {
      headers: [
        "Moldagem",
        "O.S",
        "Nfe",
        "Código",
        "Obra",
        "Responsabilidade",
        "Ensaio",
        "Resultado",
        "Relatório ?",
        "Estatístico ?",
        "Informações",
      ],
      disruptions: [
        {
          id: 'A',
          text: 'Cônica e cônica afastada 25mm do capeamento'
        },
        {
          id: 'B',
          text: 'Cônica e bipartida e cônica e com mais de uma partição'
        },
        {
          id: 'C',
          text: 'Colunar com formação de cones'
        },
        {
          id: 'D',
          text: 'Cônica e cisalhada'
        },
        {
          id: 'E',
          text: 'Cisalhada'
        },
        {
          id: 'F',
          text: 'Fraturas no topo e/ou na base abaixo do capeamento'
        },
        {
          id: 'G',
          text: 'Similar ao tipo f com fraturas próximas ao topo'
        },
      ],
      healths: [
        'Total',
        'Parcial',
        'Nenhuma'
      ],
      format_mixe: period_types,
      categories: {
        11: "Reaproveitamento - Reuso",
        12: "Redirecionamento - Reuso",
        21: "Sobra de Concreto - descarte",
        22: "Perda de Concreto - descarte",
      },
    };
  },
  methods: {
    updateItem(item) {
      let params = item;
      this.$emit('updateItem', params);
    },
    setTitleReuse(type) {
      return this.categories[Number(type)];
    },
    formatPeriod(period) {
      const date_splited = period.split(' ')
      for (let date of date_splited) {
        if (date.includes('d')) {
          date = date.replace('d', '')
          return Math.round(date) > 1 ? `${date} dias` : `${date} dia`
        }
        if (date.includes('h')) {
          date = date.replace('h', '')
          return date > 1 ? `${date} horas` : `${date} hora`
        }
        if (date.includes('m')) {
          date = date.replace('min', '')
          date = date.replace('m', '')
          date = Math.round(date) / 60
          if (date < 1) {
            date = Math.round(date * 60)
            return `${date} minutos`
          }
          return date > 1 ? `${date} horas` : `${date} hora`
        }
      }
    },
  },
};
</script>

<style scoped>
.grid-false-center {
  display: grid;
  width: 100%;
  grid-template-columns: 25% 50% 25%;
}

.grid-false-center div {
  padding: 0 5px;
}

.card-with-box-shadow {
  box-shadow: 0 2px 4px 0 #f3f3f3;
  border: 1px solid #E8E8E8;
  box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15);
  border-radius: 10px !important;
}

.contract-proposal-code {
  background-color: #1a70b7;
  border-radius: 5px;
  padding: 1px 5px;
  display: inline-flex;
  align-items: center;
}

.contract-proposal-code h5 {
  padding: 3px 0px;
  color: white;
}

.mini-card-green {
  background-color: #8bd5af52;
  border-radius: 5px;
  padding: 1px 5px;
  font-size: 12px;
  box-shadow: 0 0 5px 0 #149e5752;
}

.mini-card-red {
  background-color: #f459592e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #d2272752;
}

.mini-card-yellow {
  background-color: #f4dc592e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #fdef0b52;
}

.mini-card-blue {
  background-color: #4182e52e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #0b7bfd52;
}

.mini-card-gray {
  background-color: #646e7d2e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #5d636a52;
}

.mini-card-green h4 {
  color: #149e57;
}

.mini-card-red h4 {
  color: #db4539;
}

.mini-card-yellow h4 {
  color: #f2b532;
}

.mini-card-blue h4 {
  color: #1b6eba;
}

.mini-card-gray h4 {
  color: #66696c;
}

.mini-card-red h4, .mini-card-green h4, .mini-card-yellow h4, .mini-card-blue h4, .mini-card-gray h4 {
  font-size: 12px;
}
</style>
