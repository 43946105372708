<template>
<div>
  <ListConstructions />
</div>
</template>
<script>
import ListConstructions from './Shared/_List'

export default {
  name: 'IndexConstruction',
  components: {
    ListConstructions,
  },
}

</script>

<style></style>
