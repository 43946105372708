<template>
  <div>
    <multi-filter ref="multi-filter" @fetch="load" :filter="filter" />
    <div class="container-fluid">

    <div class="row card-wrapper" v-show="loadingSkeleton">
      <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
    </div>

    <!-- Dados vazios -->
    <PuzlEmptyData v-if="!$_anomalies.length && !loadingSkeleton" />

    <div class="row card-wrapper" v-if="$_anomalies.length && !loadingSkeleton">
      <div class="col-lg-4" v-for="(anomaly, index) in $_anomalies" :key="index">
        <!-- Basic with action button -->
        <card
          :class="[
              anomaly.is_active===1 || anomaly.is_active===true ? 'card-border-top-success' : 'card-border-top-danger'
            ]"
        >
          <!-- Card header -->
          <div class="row align-items-center mb-3">
            <div class="col-8">
              <!-- Title -->
              <h5 class="h3 mb-0">{{ anomaly.name }}</h5>
            </div>

            <div class="col-4 text-right">
              <base-dropdown menuOnRight>
                <base-button
                  slot="title-container"
                  type="primary"
                  class="dropdown-toggle p-2 rounded m-0"
                >
                  <i class="fas fa-cog"></i>
                </base-button>
                <div class="dropdown-divider p-0 m-0"></div>
                <a class="dropdown-item" @click="handleEdit(anomaly.id)">
                  <i class="fas fa-edit text-warning"></i>
                  Editar
                </a>
                <div>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleDelete(anomaly.id)">
                    <i class="fas fa-times text-danger"></i>
                    Excluir
                  </a>
                </div>
              </base-dropdown>
            </div>
          </div>
          <!-- Card body -->

        </card>
      </div>
    </div>
  </div>
  <Edit ref="editAnomaly" />
</div>
</template>

<script>
import {mapGetters} from 'vuex'
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import { PuzlEmptyData } from '@/components';
import Edit from './_Edit';
import MultiFilter from '@/components/Utils/MultiFilter.vue';

export default {
  name: "List",
  components: {
    SkeletonPuzlGrid,
    Edit,
    PuzlEmptyData,
    MultiFilter
  },
  data () {
    return {
      loadingSkeleton: false,
      filter: {}
    }
  },
  computed: {
    ...mapGetters({
      $_anomalies: 'anomaly/fetch',
    }),
  },
  methods:{
    load(filter = {}) {
      this.filter = filter
      this.init();
    },

    init() {
      this.loadingSkeleton = true;
      this.$store.dispatch('anomaly/fetchItems', { filter: this.filter })
        .then( response =>{
          this.loadingSkeleton = false

        })
        .catch( error => {
          this.loadingSkeleton = false
        })
    },

    handleEdit(id){
      this.$refs.editAnomaly.handleEditModal(id)
    },

    handleDelete(id){
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed){
          this.$Progress.start();
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          this.$store.dispatch('anomaly/destroy', id).then(response => {
            this.$notify({
              type: 'success',
              message: response.message
            });
          }).catch(error => {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            });
          })
          this.$Progress.finish();
        }
      }).catch(() => this.$Progress.finish())
    }
  },

  mounted() {
    this.filter.status = 1;
    this.$refs['multi-filter']._self.$forceUpdate();
    this.init();
  },
}
</script>

<style scoped>

</style>
