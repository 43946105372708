import * as types from './mutation_types'
import {destroy, update, cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'
import { createAxios } from "@/plugins/axios";

const endPoint = '/financial/invoice/+microservice'
const endPointBills = '/financial/billing/+microservice'
export default {
  namespaced: true,
  state: {
    items: [],
    mail_history_invoice: {},
    invoices: [],
    pending_bank_billets: [],
    open_billing: [],
    bills: [],
    item: [],
    payments: [],
  },
  getters: {
    show: state => state.item,
    fetch: state => state.items,
    invoices: state => state.invoices,
    getListBills: state => state.bills,
    getListPendingBankBillets: state => state.pending_bank_billets,
    getListOpenBilling: state => state.open_billing,
    getListPayments: state => state.payments,
    showMailHistory: state => state.mail_history_invoice,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload
    },
    [types.SET_LIST_INVOICES](state, payload) {
      state.invoices = payload
    },
    [types.SHOW_PENDING_BANK_BILLETS](state, payload) {
      state.pending_bank_billets = payload
    },
    [types.SET_BILLS](state, payload) {
      state.bills = payload
    },
    [types.SET_MAIL_HISTORY](state, payload) {
      state.mail_history_invoice = payload
    },
    [types.SET_PROPOSAL_OPEN_BILLING](state, payload) {
      state.open_billing = payload
    },
    [types.PUSH](state, payload) {
      state.items.push(payload)
    },
    [types.SHOW](state, payload) {
      state.item = payload
    },
    [types.SHOW_PAYMENTS](state, payload) {
      state.payments = payload
    },
    [types.DESTROY](state, id) {
      destroy(state, id)
    },
    [types.DESTROY_BILLS](state, id) {
      destroy(state, id, 'bills')
    },
    [types.UPDATE](state, payload) {
      update(state, payload)
    },
  },
  actions: {
    init({commit, state}, params) {
      return createAxios().get(endPoint,
        {params: params})
        .then(({data}) => {
          commit(types.SET_LIST_INVOICES, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchPaginate({commit, state}, params) {
      return createAxios().get(setUrlWithNextPage(`${endPoint}paginate`, params),
        {params: params})
        .then(({data}) => {
          cursorPaginate(state, {data: data.data, next_page: params.next_page})
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getBills({commit, state}, params) {
      return createAxios().get(endPointBills, {params: params})
        .then(({data}) => {
          commit(types.SET_BILLS, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getAllProposalsWithOpenBilling({commit, state}, params) {
      const endPoint = '/commercial/contract-proposal/pending-billing+microservice'
      return createAxios().get(setUrlWithNextPage(endPoint, params),
        {params: params})
        .then(({data}) => {
          cursorPaginate(state, {data: data.data, next_page: params.next_page}, 'open_billing', 'key')
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    generateInvoices({commit}, params) {
      return createAxios().post(`${endPoint}generate-invoices-by-proposals`, params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    add({commit, state}, payload) {
      return createAxios().post(endPoint, payload)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    postSendToEmail({commit, state}, payload) {
      return createAxios().post(endPoint + 'send-to-email', payload)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    addSingle({commit, state}, payload) {
      return createAxios().post('/financial/single+microservice', payload)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    destroySingle({commit, state}, id) {
      return createAxios().delete('/financial/single/' + id + '+microservice')
        .then(({data}) => {
          commit(types.DESTROY_BILLS, id)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    update({commit}, payload) {
      return createAxios().put(endPoint + payload.id, payload)
        .then(({data}) => {
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    postSetSetSendToMail({commit}, params) {
      return createAxios().post(`${endPoint}set-auto-mail`, params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getMailHistory({commit}, params) {
      return createAxios().get(`${endPoint}mail-history`, {params: params})
        .then(({data}) => {
          commit(types.SET_MAIL_HISTORY, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    show({commit, dispatch}, id) {
      return createAxios().get(endPoint + 'updated/' + id)
        .then(({data}) => {
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    destroy({commit, dispatch}, id) {
      return createAxios().delete(`/financial/invoice/${id}+microservice`)
        .then(({data}) => {
          commit(types.DESTROY, id)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getPaymentTerms({commit, dispatch}, params) {
      return createAxios().get('/financial/invoices/payment-terms', {params: params})
        .then(({data}) => {
          commit(types.SHOW_PAYMENTS, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    postIssuerBankBilletByPaymentIds({commit, dispatch}, payment_ids) {
      return createAxios().post('/financial/invoices/issue-bank-billet', {ids: payment_ids})
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getPendingBankBillets({commit, dispatch}, params) {
      return createAxios().get('/financial/invoices/pending-bank-billets', {params: params})
        .then(({data}) => {
          commit(types.SHOW_PENDING_BANK_BILLETS, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    download({commit}, params) {
      return createAxios().get('/financial/invoice/download/report+microservice', {
        responseType: "blob",
        timeout: 30000,
        params: params
      })
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    postSetComment({}, params) {
      return createAxios().post(`${endPoint}comments/${params.id}`, params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    downloadAdvanceReceipt({commit}, params) {
      return createAxios().get('/financial/invoices/issue-advance-receipt', {
        responseType: "blob",
        timeout: 30000,
        params: params
      })
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getInvoiceByContractProposal({commit}, contractPropsalId) {
      return createAxios().get(`/financial/invoices/by-contract-proposal/${contractPropsalId}`)
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    postSetAgroup(_, params) {
      return createAxios().post(endPoint + `generate-invoices-by-proposals/agroup`, params)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getInvoiceHistory({commit}, invoiceId) {
      return createAxios().get(`/financial/invoices/${invoiceId}/history`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    reverseReceivedes(_, invoiceId) {
      return createAxios().post( `/financial/invoice/${invoiceId}/reverse`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    regenerateBillReceive(_, invoiceId) {
      return createAxios().post( endPoint + `${invoiceId}/regenerate-bill-receive`)
        .then(({data}) => {
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },
    updateInvoiceStatus({ commit }, params) {
      return createAxios().post(`/financial/invoices/history`, {
          comment: params.comment,
          id: params.id,
          status: params.status,
          user: params.user_id
      })
        .then(({data}) => {
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },
    /**
     * Relatório em aberto em PDF
     * @param commit
     * @param params
     * @returns {Promise<unknown>}
     */
    getReportOpenedPDF({commit}, params) {
      return createAxios().get('financial/billing/report/opened/pdf+microservice', {
        params: params
      }).then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    updateSellerInvoice(_, params) {
      return createAxios().put(`${endPoint}${params.id}/seller/${params.seller_id}`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    updateUserIntermediaryInvoice(_, params) {
      return createAxios().put(`${endPoint}${params.id}/user-intermediary/${params.user_intermediary_id}`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
