<template>
  <div class="card rounded pt-3" :class="theme ? 'bg-dark-chart' : 'bg-white'">
    <v-chart :theme="theme === true ? 'dark' : 'default'" class="chart" :option="option" />
  </div>
</template>
<script>
import { use } from "echarts/core";
import { PieChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { TitleComponent, TooltipComponent, ToolboxComponent, LegendComponent, DataZoomComponent } from "echarts/components";
import VChart from "vue-echarts";
import { GridComponent } from "echarts/components";

use([PieChart, TitleComponent, TooltipComponent, ToolboxComponent, LegendComponent, CanvasRenderer, DataZoomComponent]);
use([GridComponent]);
export default {
  name: "PieChart",
  components: {
    VChart,
  },
  props: {
    theme: {
      type: Boolean,
      default: false,
      description: "Tema",
    },
    option: {
      type: Object,
      default: () => ({
        loading:true,
      }),
      description: "Dados gerais do chart",
    },
  },
};
</script>

<style scoped>
.bg-dark-chart {
  background-color: #100c2a;
}
</style>
