<template>
<div>
  <div class="container-fluid">

    <div class="row card-wrapper" v-show="loadingSkeleton">
      <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
    </div>
    <PuzlEmptyData v-if="!job_descriptions.length && !loadingSkeleton"></PuzlEmptyData>
    <div class="row card-wrapper" v-if="job_descriptions.length && !loadingSkeleton">
      <div class="col-lg-4" v-for="(job_description, index) in job_descriptions" :key="index">
        <!-- Basic with action button -->
        <card :class="job_description.status ? 'card-border-top-success' : 'card-border-top-danger'">
          <!-- Card header -->
          <div class="row align-items-center mb-3">
            <div class="col-8">
              <!-- Title -->
              <h5 class="h3 mb-0">{{ job_description.description }}</h5>
            </div>

            <div class="col-4 text-right">
              <base-dropdown menuOnRight>
                <base-button
                  slot="title-container"
                  type="primary"
                  class="dropdown-toggle p-2 rounded m-0"
                >
                  <i class="fas fa-cog"></i>
                </base-button>
                <div class="dropdown-divider p-0 m-0"></div>
                <a class="dropdown-item" @click="handleEditType(job_description.uuid)">
                  <i class="fas fa-edit text-warning"></i>
                  Editar
                </a>
                <div class="dropdown-divider p-0 m-0"></div>
                <a class="dropdown-item" @click="handleDeleteType(job_description.uuid)">
                  <i class="fas fa-times text-danger"></i>
                  Excluir
                </a>
              </base-dropdown>
            </div>
          </div>
          <!-- Card body -->
        </card>
      </div>
    </div>
  </div>
  <edit-type ref="editJobDescription"></edit-type>
</div>
</template>

<script>
import {mapGetters} from 'vuex'
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import EditType from './_Edit';

export default {
  name: "List",
  components: {
    SkeletonPuzlGrid,
    PuzlEmptyData,
    EditType,
  },
  data () {
    return {
      loadingSkeleton: false,
    }
  },
  computed: {
    ...mapGetters({
      'job_descriptions': 'jobDescription/fetch',
    }),
  },
  methods:{
    fetch () {
      this.loadingSkeleton = true
      this.$store.dispatch('jobDescription/fetchItems')
        .then( response =>{
          this.loadingSkeleton = false
        })
        .catch( error => {
          this.loadingSkeleton = false
        })
    },
    handleEditType(uuid){
      this.$refs.editJobDescription.handleEditModal(uuid)
    },
    handleDeleteType(uuid){
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed){
          this.$Progress.start();
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          this.$store.dispatch('jobDescription/destroy', uuid).then(response => {
            this.$notify({
              type: 'success',
              message: response.message
            });
          }).catch(error => {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            });
          })
          this.$Progress.finish();
        }
      }).catch(() => this.$Progress.finish())
    }
  },
  mounted() {
    this.fetch()
  },
  created () {

  }
}
</script>

<style scoped>

</style>
