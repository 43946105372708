import * as types from './mutation_types'
import {destroy, update, cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'
import { createAxios } from "@/plugins/axios";

const endPoint = 'operational/stock/'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
  },
  getters: {
    show: state => state.item,
    fetch: state => state.items,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload
    },
    [types.SHOW](state, payload) {
      state.item = payload
    },
    [types.PUSH](state, payload) {
      state.items.push(payload)
    },
    [types.DESTROY](state, id) {
      destroy(state, id)
    },
  },
  actions: {
    fetchItems({commit}, params) {
      return createAxios().get(endPoint, {params: params})
        .then(({data}) => {
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchItemsPaginate({state}, params) {
      return createAxios().get(setUrlWithNextPage(endPoint, params),
        {params: params})
        .then(({data}) => {
          cursorPaginate(state, {data: data.data, next_page: params.next_page})
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getLastStock({}, params) {
      return createAxios().get(endPoint + 'last-stock')
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getLastStockByPlantAndCmcCategoryAndCmcAndLocation({}, params) {
      return createAxios().get(`${endPoint}last-stock/${params.company_plant_id}/${params.category_id}/${params.cmc_id}`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getCmcPricePerKg({}, params) {
      return createAxios().get(`${endPoint}cmc-price`, {params: params})
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    add({commit}, payload) {
      return createAxios().post(endPoint, payload)
        .then(({data}) => {
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    show({commit}, payload) {
      return createAxios().get(endPoint + payload.id)
        .then(({data}) => {
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    destroy({commit}, id) {
      return createAxios().delete(endPoint + id)
        .then(({data}) => {
          commit(types.DESTROY, id)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getStockReportExcel(_, params) {
      return createAxios().get(endPoint + 'download-excel', {
        responseType: "blob",
        timeout: 60000,
        params: params
      })
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
