<template>
  <div>
    <base-header class="bg-gray-content">
      <puzl-breadcrumb />
      <div class="row mb-3">
        <div class="col-md-2 mb-2">
          <base-button @click.prevent="handleSearchEntity" block size="lg" type="success"> <i class="fas fa-plus"></i> NOVO </base-button>
        </div>
        <div class="col-md-2 mb-2">
          <base-button @click.prevent="handleCreateEntity" block size="lg" type="primary">
            <i class="fas fa-plus"></i> FORNECEDOR
          </base-button>
        </div>
      </div>
    </base-header>
    <ListExitLaunch />
    <ModalSearchEntity @selected="selectedEntity" ref="modalSearchEntity" />
    <ModalCreateEntity ref="modalCreateEntity" :openFromEntryLaunch="true" />
  </div>
</template>

<script>
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import ListExitLaunch from "./Shared/_List";
import ModalSearchEntity from "../Entity/Shared/_ModalSearchEntity";
import ModalCreateEntity from "../Entity/Shared/_Create";

export default {
  name: "Index",
  components: { PuzlBreadcrumb, ListExitLaunch, ModalSearchEntity, ModalCreateEntity },
  methods: {
    handleCreateEntity() {
      this.$refs.modalCreateEntity.openModal(true);
    },
    selectedEntity(entity) {
      this.$router.push(`/configuration/exit-launch/create/${entity.uuid}`);
    },
    handleSearchEntity() {
      this.$refs.modalSearchEntity.openModal();
    },
  },
};
</script>
