<template>
  <div>
    <div class="mt-0 container-fluid">
      <div style="border-radius: 0.375rem;" class="row bg-white p-0 m-0 w-100 pb-1">
        <div class=" w-100 align-items-center mb-4 mt-4">
          <div class="col-12">
            <div class="border-header-primary-bottom">
              <h5 class="h4 p-1 mb-0">Filtrar por</h5>
            </div>
          </div>
        </div>
        <div style="border-radius: 0.375rem;" class="form-group row m-0 p-0 w-100">
          <div class="col-md-3 row">
            <label class="col-md-2 pb-0 col-form-label form-control-label pb-2 pt-1 pr-0">
              Data
            </label>
            <div class="col-md-10">
              <InputDateTimePicker
                v-model="filters.date"
                :disabled="loadingSkeleton"
                :loading="loadingSkeleton"
                :mode="'date'" />
            </div>
          </div>
          <div class="col-lg-3 row gantt-bf-cols">
            <label class="col-md-3 pb-0 col-form-label form-control-label pb-2 pt-1 pr-0">
              Central
            </label>
            <div class="col-md-9">
              <puzl-select 
                v-model="filters.company_plant_uuids" 
                :items="plants"  
                :multiple="true"
                customKey="uuid"
                :disabled="loadingPlant"
                :loading="loadingPlant" />
            </div>
          </div>
          <div class="col-lg-3" />
          <div class="col-lg-3 row ml-1">
            <div class="col-12 gantt-btn-advanced" style="padding-top:1px">
              <base-button block size="sm" type="info" class="text-uppercase" @click.prevent="handleAdvancedFilters">
                <i class="fas fa-filter"></i> avançado
              </base-button>
            </div>
          </div>
        </div>

        <div style="border-radius: 0.375rem;" class="form-group row m-0 p-0 w-100">
          <div class="col-md-10 mb-2 row"></div>
          <div class="col-md-2 mb-0 pt-3 row pr-0 text-right">
            <div class="col-md-6 p-0 mb-2  col-mobile-gantt-btn">
              <base-button size="sm" :disabled="loadingSkeleton" type="primary" class="text-uppercase" @click.prevent="resetFilters">
                limpar
              </base-button>
            </div>
            <div class="col-md-6 p-0  col-mobile-gantt-btn">
              <base-button :disabled="loadingSkeleton" size="sm" type="success" class="text-uppercase" @click.prevent="applyFilters">
                aplicar
              </base-button>
            </div>
          </div>
        </div>
      </div>
      <div class="row card-wrapper mt-4" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <div v-if="!rows.length && !loadingSkeleton" class="mt-4 mb-6">
        <PuzlEmptyData></PuzlEmptyData>
      </div>
      <div>
        <g-gantt-chart
          v-if="rows.length && !loadingSkeleton"
          :chart-start="start_at"
          :chart-end="end_at"
          :theme="ganttSettings.theme"
          :locale="'pt-BR'"
          :rowHeight="60"
          :grid="ganttSettings.grid"
          @dragend-bar="dragUpdate($event)"
        >
          <div class="d-flex justify-content-between" v-for="(row, index) in rows" :key="row.index">
            <div class="d-flex align-items-center pl-1 pr-1" style="z-index: 999; background-color:#fff;">
              <img style="height:20px" src="https://upload.wikimedia.org/wikipedia/commons/9/92/Cog_font_awesome.svg" />
              <base-dropdown menuOnRight style="width:0">
                <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0" style="opacity: 0;left: -22px;">
                </base-button>
                <a @click.prevent="chargeTravel(index)" class="dropdown-item">
                  Carregamento
                </a>
                <a @click.prevent="handleResume(index)" class="dropdown-item">
                  Resumo
                </a>
                <a @click.prevent="handleEditTravel(index)" class="dropdown-item">
                  Editar
                </a>
                <!-- Ocultar botão de excluir até confirmação de regras
                 <a @click.prevent="handleDeleteTravel(index)" class="dropdown-item">
                  Excluir
                </a> -->
              </base-dropdown>
            </div>

            <g-gantt-row :label="row.label" :bars="row.bars" bar-start="myStart" bar-end="myEnd">
              <template #bar-label="{bar}">
                <span>{{ bar.label }}</span>
              </template>
            </g-gantt-row>
          </div>
        </g-gantt-chart>
      </div>
    </div>
    <ModalEditTravel @updatedTravel="fetch" ref="editTravel" />
    <ModalAdvancedFilters @selectedFilters="setFilters" ref="advancedFilters" />
    <ModalResumeTravel ref="modalResume" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { GGanttChart, GGanttRow } from "vue-ganttastic";
import moment from "moment";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import ModalEditTravel from "./_ModalEditTravel";
import ModalAdvancedFilters from "./_ModalAdvancedFilters";
import ModalResumeTravel from "./_ModalResumeTravel";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "ListGantt",
  components: {
    GGanttChart,
    GGanttRow,
    PuzlEmptyData,
    SkeletonPuzlGrid,
    ModalEditTravel,
    ModalAdvancedFilters,
    ModalResumeTravel,
    InputDateTimePicker,
    PuzlSelect,
  },
  data() {
    return {
      filters: {
        date: null,
        company_plant_uuids: [],
        customer_uuids: [],
        construction_uuids: [],
        status: []
      },
      start_at: null,
      ganttSettings: {},
      end_at: null,
      rows: [],
      loadingSkeleton: true,
      loadingPlant: true
    };
  },
  computed: {
    ...mapGetters({
      travels: "travels/fetch",
      plants: "plant/activeItems"
    })
  },
  methods: {
    async fetch() {
      return this.$store
        .dispatch("travels/fetchWithFilter", this.filters)
        .then(response => {
          this.rows = JSON.parse(JSON.stringify(this.travels));
          this.calcParamsSettings();
        })
        .catch(error => {
          this.loadingSkeleton = false;
        });
    },
    calcParamsSettings() {
      let start_at = this.filters.date + " 00:00:00";
      this.start_at = start_at;
      this.end_at = moment(start_at)
        .add(1, "days")
        .format("YYYY-MM-DD HH:mm:ss");

      return this.filters.date;
    },
    chargeTravel(index) {
      this.$router.push({
        path: "/operational/schedule/charging/" + this.travels[index].uuid
      });
    },
    handleDeleteTravel(index) {
      this.$Swal.confirmDelete().then(result => {
        if (result.isConfirmed) {
          this.loadingSkeleton = true;
          this.$notify({
            type: "info",
            message: "Estamos trabalhando em sua solicitação."
          });
          this.$store
            .dispatch("travels/destroy", this.travels[index].uuid)
            .then(response => {
              this.applyFilters();
              this.$notify({
                type: response.error_type,
                message: response.message
              });
            })
            .catch(error => {
              if (error.status) {
                this.$notify({
                  type: "danger",
                  message: error.data.message
                });
                this.applyFilters();
              }
            });
        }
      });
    },
    handleEditTravel(index) {
      this.$refs.editTravel.openModal(this.travels[index].uuid);
    },
    handleResume(index) {
      this.$refs.modalResume.openModal(this.travels[index].id);
    },
    handleAdvancedFilters() {
      this.$refs.advancedFilters.openModal(this.filters);
    },
    setFilters(filters) {
      this.filters = filters;
      this.applyFilters();
    },
    resetFilters() {
      this.filters = {
        date: moment().format("YYYY-MM-DD"),
        company_plant_uuids: [],
        customer_uuids: [],
        construction_uuids: []
      };
      this.initData();
    },
    async initData() {
      this.loadingSkeleton = true;
      await this.getSettings();
      await this.fetch();
      this.loadingSkeleton = false;
    },
    async applyFilters() {
      this.loadingSkeleton = true;
      await this.fetch();
      this.loadingSkeleton = false;
    },
    getSettings() {
      return this.$store
        .dispatch("scheduleGantt/show")
        .then(response => {
          this.ganttSettings = {
            hour_interval: response.data.hour_interval ?? 3,
            theme: response.data.theme ?? "default",
            grid: response.data.grid ?? false
          };
        })
        .catch(error => {
          this.ganttSettings = {
            hour_interval: 3,
            theme: "default",
            grid: false
          };
        });
    },
    resetTravel(travel_uuid) {
      this.rows.filter(item => item.uuid === travel_uuid)[0].bars[0].myStart = this.travels.filter(
        item => item.uuid === travel_uuid
      )[0].bars[0].myStart;
      this.rows.filter(item => item.uuid === travel_uuid)[0].bars[0].myEnd = this.travels.filter(
        item => item.uuid === travel_uuid
      )[0].bars[0].myEnd;
    },
    allowEditing(status) {
      let allow = false;
      switch (status) {
        case 1:
        case 5:
        case 6:
        case 7:
        case 9:
          allow = true;
          break;
      }
      return allow;
    },
    dragUpdate(event) {
      this.$swal
        .fire({
          title: "Você tem certeza?",
          text: "Essa modificação afeta os demais horários desta viagem!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, prosseguir!",
          cancelButtonText: "Não, mantenha",
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill"
          },
          buttonsStyling: false
        })
        .then(result => {
          let travel_uuid = event.bar.uuid;
          if (result.isConfirmed) {
            if (this.allowEditing(event.bar.status) || event.bar.isset_manual_dosage === true) {
              this.$notify({
                type: "danger",
                message: "Não é permitida a edição de viagem com o status atual!"
              });
              this.resetTravel(travel_uuid);
              return false;
            }
            this.$Progress.start();
            let params = {
              travel_uuid: travel_uuid,
              new_load_truck_time: event.bar.myStart
            };
            this.$store
              .dispatch("travels/dragUpdate", params)
              .then(response => {
                this.$Progress.finish();
                this.$notify({
                  type: response.error_type,
                  message: response.message
                });
              })
              .catch(error => {
                if (error.status == 200) {
                  this.resetTravel(travel_uuid);
                  this.$notify({
                    type: "danger",
                    message: error.data.message
                  });
                  this.$Progress.finish();
                }
                this.resetTravel(travel_uuid);
                this.$Progress.finish();
              });
          } else {
            this.resetTravel(travel_uuid);
          }
        });
    }
  },
  mounted() {
    this.filters.date = moment().format("YYYY-MM-DD");
    this.initData();
    this.$store.dispatch("plant/fetchItemsActive").then(() => {
      this.loadingPlant = false;
    });
  }
};
</script>

<style>
#g-timeaxis {
  box-shadow: none !important;
  font-family: Open Sans, sans-serif !important;
  text-transform: capitalize;
  font-size: 12px !important;
}
.g-timeaxis-days * {
  font-family: Open Sans, sans-serif !important;
}
.g-timeaxis-days {
  width: 86% !important;
}
.g-timeaxis-empty-space {
  width: 14% !important;
}
.g-gantt-row-label {
  font-size: 11.3px !important;
  text-align: left;
  padding: 0 10px;
  width: 12% !important;
}
#g-gantt-chart .dropdown-menu-right {
  right: -125px;
}
#g-gantt-chart .dropdown-menu .dropdown-item {
  text-align: left !important;
}
#g-gantt-chart .dropdown-menu {
  top: -187%;
}
.g-gantt-row-bars-container {
  width: 86% !important;
}
#g-gantt-chart .dropdown-item {
  font-size: 0.8rem !important;
  padding: 7px;
}
#g-gantt-chart {
  padding-bottom: 35px !important;
}
#g-gantt-chart .dropdown-item,
.g-gantt-bar-label span,
#g-gantt-chart .my-label {
  font-family: Open Sans, sans-serif !important;
  text-align: center;
}
.g-gantt-bar {
  margin-left: 1rem !important;
}
.gantt-btn-advanced {
  padding-left: 3rem !important;
}
.gantt-bf-cols {
  margin-left: 1.5rem !important;
}

.g-gantt-row-label,
#g-timeaxis {
  border-radius: 0.275rem !important;
  font-family: Open Sans, sans-serif !important;
  text-transform: capitalize;
}

@media screen and (max-width: 1050px) {
  .col-mobile-gantt-btn {
    text-align: center;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media screen and (max-width: 991px) {
  .gantt-btn-advanced {
    padding-left: 0px !important;
    margin-top: 1rem !important;
  }
  .gantt-bf-cols {
    margin-left: 0px !important;
    padding-left: 0px !important;
  }
  .col-mobile-gantt-btn {
    margin-bottom: 1rem !important;
  }
}

@media screen and (max-width: 678px) {
  .g-timeaxis-days *,
  .g-gantt-row-label {
    font-size: 9px !important;
  }
  .g-timeaxis-days {
    width: 80% !important;
  }
  .g-timeaxis-empty-space {
    width: 20% !important;
  }
  .g-gantt-row-label {
    width: 16% !important;
  }
  .g-gantt-bar-label {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 461px) {
  .g-timeaxis-days *,
  .g-gantt-row-label {
    font-size: 8px !important;
    padding-left: 1px 3px !important;
  }
}
</style>
