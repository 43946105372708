const keys = Object.freeze({
  INACTIVE: 0,
  ACTIVE: 1,
});

const getTitle = (value) => {
  switch (value) {
    case keys.INACTIVE: return 'Inativo';
    case keys.ACTIVE: return 'Ativo';
    default:
      return "";
  }
};

const getToSelect = Object.freeze([
  { id: keys.INACTIVE, label: getTitle(keys.INACTIVE) },
  { id: keys.ACTIVE, label: getTitle(keys.ACTIVE) },
]);

export default {
  keys,
  getTitle,
  getToSelect
};
