<template>
  <div class="row">
    <div class="col-md-3">
      <base-button
        style="border-radius: 5px !important;"

        title="Agrupamento por local de saída"
        size="sm"
        class="w-outline font-weight-500"
                   @click="setActive">
        <img style="width: 25px"
             class="mr-1"
             src="/img/icons/dosage_location.png"/>
         LOCAL DE SAÍDA
        <i style="font-size: 12px" v-if="!active" class="far fa-square"></i>
        <i style="font-size: 12px" v-else class="far fa-check-square"></i>
      </base-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectConcretePiece",
  data() {
    return {
      active: 0,
    }
  },
  methods: {
    setActive()
    {
      this.active = !this.active
      this.$emit('load', this.active)
    }
  }
}
</script>

<style scoped>
.w-outline {
  background-color: white !important;
  color: #1f3a68 !important;
}
</style>
