<template>
  <div class="card-body mt-n2 p-0">
    <div class="row">
      <ShadedTable :length="$_concretes.length" :headers="headers" :loading="loadingSkeleton">
        <template v-for="(item, index) in $_concretes" :slot="index">
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.schedule_travel_finished_at | parseDate("DD/MMM") }}
              <el-popover class="p-0 pr-0 mr-1 float-right" placement="bottom" trigger="hover">
                <div class="p-2">
                  <img
                    style="width: 21px"
                    src="/img/icons/chemical-plant-black.png"
                  />
                  <b class="text-dark"> Central </b>
                  <div class="text-left text-dark">
                    {{ item.company_plant_name }}
                  </div>
                  <div class="text-left text-dark">
                    {{ item.company_plant_charge_point_name }}
                  </div>
                </div>
                <base-button
                  slot="reference"
                  class="p-0 m-0 shadow-none"
                  outiline
                  size="sm"
                  type="secundary"
                >
                  <img src="/img/icons/chemical-plant-black.png" width="20px" height="20px" />
                </base-button>
              </el-popover>
            </h5>
          </ShadedCol>
          <ShadedCol>
            <div class="text-center">
              <span class="small text-dark mr-1">
                {{ item.schedule_travel_finished_at | parseDate("HH:mm") }}
              </span>
              <el-popover class="p-0 pr-0 mr-1" placement="bottom" trigger="hover">
                <img
                  v-if="item.driver_name"
                  src="/img/icons/interstate-truck.png"
                  width="20px"
                  height="20px"
                />
                <b class="text-dark"> Motorista </b>
                <div class="p-2">
                  <span>
                    <span class="text-center text-dark">
                      {{ item.equipment_code }}
                    </span>
                    <br />
                    <span class="text-center text-dark">
                      {{ item.driver_name }}
                    </span>
                  </span>
                </div>
                <base-button
                  slot="reference"
                  class="p-0 m-0 shadow-none"
                  outiline
                  size="sm"
                  type="secundary"
                >
                  <img
                    v-if="item.driver_name"
                    src="/img/icons/interstate-truck.png"
                    width="20px"
                    height="20px"
                  />
                </base-button>
              </el-popover>
            </div>
          </ShadedCol>
          <ShadedCol>
            <span class="style-table">
              <span class="float-left numeric table-content-font-size pr-4 text-dark">
                {{ item.sequencial_number_plant_number }}
              </span>
              <a
                href="#"
                class="float-right"
                @click.prevent="$emit('downloadProof', item.schedule_travel_uuid)"
              >
                <img width="28px" src="/img/icons/os.png" />
              </a>
            </span>
          </ShadedCol>
          <ShadedCol>
            <div
              @click.prevent="
                $emit(
                  'downloadDanfe',
                  item.xml_travel_status,
                  item.xml_travel_key,
                  item.schedule_travel_uuid,
                )
              "
              class="text-center"
              v-if="item.xml_travel_id"
            >
              <span class="float-left font-weight-400 text-dark" style="font-size: 12.5px">
                {{ item.xml_travel_number }}
              </span>
              <span class="float-right">
                <img
                  style="cursor: pointer"
                  v-if="item.status !== scheduleTravelEnum.CANCELED"
                  width="19px"
                  src="/img/nfe.png"
                />
                <img
                  style="cursor: pointer"
                  v-if="
                    item.status === scheduleTravelEnum.CANCELED &&
                    item.xml_travel_status !== 3
                  "
                  width="19px"
                  src="/img/nfe%20cancelada%20icon.svg"
                />
                <img
                  style="cursor: pointer"
                  v-if="
                    item.status === scheduleTravelEnum.CANCELED &&
                    item.xml_travel_status === 3
                  "
                  width="19px"
                  src="/img/nfe_in_cancelation_progress.png"
                />
              </span>
            </div>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              <span
                class="btn btn-sm btn-primary p-1 mr-2"
                @click.prevent="$emit('copyContractProposal', item.contract_proposal_code)"
              >
                {{ item.contract_proposal_code }}
              </span>
              <span
                :title="item.entity_name"
                class="table-content-font-size text-dark"
              >
                {{ truncateString(item.entity_name, 20) }}
              </span>
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              <span class="style-table">
                <span class="numeric float-left pr-2 table-content-font-size text-dark">
                  {{ (item.sale_price - item.margin) | currency }}
                </span>
                <el-popover class="p-0" placement="right" trigger="hover">
                  <div class="p-2 mt-1 text-dark">
                    <div class="mb-2 ml-n1">
                      <img src="/img/icons/test-tube.png" width="22px" height="22px" />
                      <b class="text-dark"> Formulação </b>
                    </div>
                    <div class="mt-2 mb-3">
                      <div class="small">
                        {{ item.contract_proposal_formulation_title }}
                      </div>
                    </div>
                    <div class="mt-2 mb-3">
                      <div class="small">
                        {{ item.contract_proposal_formulation_body }}
                      </div>
                    </div>
                    <div
                      class="mt-2 mb-3"
                      v-if="item.contract_proposal_formulation_footer"
                    >
                      <div class="small">
                        {{ item.contract_proposal_formulation_footer }}
                      </div>
                    </div>
                    <div class="mt-2 mb-3" v-if="item.level_feature">
                      <div class="small" v-if="item.level_feature === 1">
                        Aplicação Baixa
                      </div>
                      <div class="small" v-else-if="item.level_feature === 2">
                        Aplicação Média
                      </div>
                      <div class="small" v-else-if="item.level_feature === 3">
                        Aplicação Alta
                      </div>
                    </div>
                  </div>
                  <div class="p-1 mt-n2">
                    <div>
                      <img
                        src="/img/icons/icons8/puzzle.png"
                        width="22px"
                        height="22px"
                      />
                      <b class="text-dark"> Peça </b>
                    </div>
                    <div class="m-0 pl-1 mt-1 text-dark small">
                      {{ item.mix_concreted_piece_description }}
                    </div>
                  </div>
                  <div class="p-1 mt-2">
                    <div>
                      <img src="/img/icons/icons8/ios/sandbox2_warning.png" width="22px" height="22px" />
                      <b class="text-dark"> Mcc </b>
                    </div>
                    <div class="m-0 pl-1 mt-1 text-dark small">
                      Mcc {{ item.margin | currency() }} /m³
                    </div>
                  </div>
                  <span slot="reference">
                    <img src="/img/icons/test-tube.png" width="22px" height="22px" class="float-right" />
                  </span>
                </el-popover>
              </span>
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              <span class="numeric float-left pr-2 text-dark table-content-font-size">
                {{ item.sale_price | currency() }}
              </span>
              <el-popover class="p-0" placement="right" trigger="hover">
                <span class="numeric m-0 pl-1">
                  <div class="mt-n3">
                    <img src="/img/icons/salesman.png" width="22px" height="22px" />
                    <b class="text-dark"> Vendedor </b>
                  </div>
                  {{ item.seller_name }}
                </span>
                <span slot="reference">
                  <img src="/img/icons/salesman.png" width="22px" height="22px" class="float-right" />
                </span>
              </el-popover>
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              <span class="numeric text-dark table-content-font-size">
                {{ item.schedule_travel_volume }}
                <a href="#" @click.prevent="$emit('showAdjustments', item.schedule_travel_uuid)">
                  <img src="/img/icons/icons8/puzzle.png" width="22px" height="22px" class="float-right" />
                </a>
              </span>
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              <span
                v-if="item.additional_price"
                class="numeric table-content-font-size text-dark wefe efwe"
              >
                {{ item.additional_price | currency() }}
              </span>
              <span v-else class="numeric table-content-font-size text-dark">
                {{ 0 | currency() }}
              </span>
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              <span
                class="numeric float-left pr-2 table-content-font-size text-dark"
              >
                {{
                  calculateTotal(
                    item.sale_price,
                    item.schedule_travel_volume,
                    item.additional_price
                  ) | currency()
                }}
              </span>
              <el-popover class="p-0" placement="right" trigger="hover">
                <span class="numeric m-0 pl-1">
                  {{ item.invoice_id }}
                </span>
                <span slot="reference">
                  <img
                    v-if="item.invoice_id"
                    src="/img/icons/check.png"
                    width="22px"
                    height="22px"
                    class="float-right"
                  />
                </span>
              </el-popover>
            </h5>
          </ShadedCol>
        </template>
      </ShadedTable>
    </div>
  </div>
</template>

<script>
import SkeletonPuzl from "@/components/SkeletonPuzl";
import ShadedTable from "@/components/ShadedTable.vue";
import ShadedCol from "@/components/ShadedCol.vue";
import { mapGetters } from "vuex";
import { strToNum } from "@/helpers";

export default {
  name: "TableDeliveryResume",
  data() {
    return {
      headers: [
        "Data",
        "Hora",
        "O.S.",
        "Nf-e",
        "Cliente",
        "Margem",
        "Preço",
        "Volume",
        "Adicionais",
        "Total",
      ],
      scheduleTravelEnum: Object.freeze({
        RELEASED: 0, // Liberada
        FINISHED: 1, // Concluída
        LOADING: 2, // Carregando
        WAITING_FOR_RELEASE: 4, // Aguardando liberação do cliente atraveś da regra da programação
        IN_PROGRESS: 5, // Em andamento
        CANCELED: 6, // Cancelada
        UNREALIZED: 7, // Não realizada
        HAS_PENDENCY: 8, // Possui pendência
        SELLER_REQUEST: 9, // Solicitação do vendedor
      }),
      xmlTravelEnum: Object.freeze({
        AUTHORIZED: 1,
      }),
    };
  },
  props: {
    loadingSkeleton: Boolean,
  },
  computed: {
    ...mapGetters({
      $_concretes: "deliveryResume/fetch",
    }),
  },
  components: {
    SkeletonPuzl,
    ShadedTable,
    ShadedCol,
  },
  methods: {
    /**
     * @param {string} str
     * @param {number} maxLength
     */
    truncateString(str, maxLength) {
      if (str && str.length > maxLength) {
        return str.slice(0, maxLength) + "...";
      }
      return str;
    },
    /**
     * @param {string} sale_price
     *  @param {string} schedule_travel_volume
     *  @param {string|null} additional_price
     */
    calculateTotal(sale_price, schedule_travel_volume, additional_price) {
      return (
        strToNum(sale_price) * strToNum(schedule_travel_volume) +
        strToNum(additional_price)
      );
    },
  },
};
</script>
