<template>
    <div :class="disableBoxShadow === true ? 'disable-box-shadow-input-group' : ''">
      <validation-provider
        :rules="rules"
        :name="name"
        v-bind="$attrs"
        v-slot="{ errors, valid, invalid, validated }"
      >
        <div class="form-group m-0" :style="'width: '+width" >
          <slot name="label">
            <label style="font-size: 0.8rem" class="form-control-label" v-if="label" :class="labelClasses">
              {{ label }}
            </label>
          </slot>
          <div
            :class="[
              { 'input-group': hasIcon },
              { focused: focused },
              { 'input-group-alternative': alternative },
              { 'has-label': label || $slots.label },
              inputGroupClasses
            ]"
          >
            <div v-if="prependIcon || $slots.prepend" class="input-group-prepend">
              <span :class="prependClass" class="input-group-text">
                <slot name="prepend">
                  <i :class="prependIcon"></i>
                </slot>
              </span>
            </div>
            <slot v-bind="slotData">
              <input
                :style="size === 'sm' && 'height: 1.87rem'"
                :value="value"
                :type="type"
                v-on="listeners"
                v-bind="$attrs"
                :valid="valid"
                :required="required"
                class="form-control m-0"
                :class="[
                  { 'is-valid': valid && validated && successMessage },
                  { 'is-invalid': invalid && validated },
                  inputClasses
                ]"
              />
            </slot>
            <div v-if="appendIcon || $slots.append" class="input-group-append">
              <span :class="appendClass" :style="size === 'sm' && 'height: 1.87rem'"
                class="input-group-text">
                <slot name="append">
                  <i :class="appendIcon"></i>
                </slot>
              </span>
            </div>
            <slot name="infoBlock"></slot>
          </div>
    <!--      <slot name="success">-->
    <!--        <div class="valid-feedback" v-if="valid && validated">-->
    <!--          {{ successMessage }}-->
    <!--        </div>-->
    <!--      </slot>-->
    <!--      <slot name="error">-->
    <!--        <div v-if="errors[0]" class="invalid-feedback" style="display: block;">-->
    <!--          {{ errors[0] }}-->
    <!--        </div>-->
    <!--      </slot>-->
        </div>
      </validation-provider>
    </div>
</template>
<script>
export default {
  inheritAttrs: false,
  name: "base-input",
  props: {
    required: {
      type: Boolean,
      description: "Whether input is required (adds an asterix *)"
    },
    size: {
      type: String,
      default: 'md'
    },
    group: {
      type: Boolean,
      description:
        "Whether input is an input group (manual override in special cases)"
    },
    alternative: {
      type: Boolean,
      description: "Whether input is of alternative layout"
    },
    label: {
      type: String,
      description: "Input label (text before input)"
    },
    error: {
      type: String,
      description: "Input error (below input)"
    },
    successMessage: {
      type: String,
      description: "Input success message",
      default: ""
    },
    labelClasses: {
      type: String,
      description: "Input label css classes",
      default: "form-control-label"
    },
    inputClasses: {
      type: String,
      description: "Input css classes"
    },
    appendClass: {
      type: String,
      description: "Input css classes"
    },
    prependClass: {
      type: String,
      description: "Input css classes"
    },
    inputGroupClasses: {
      type: String,
      description: "Input group css classes"
    },
    width: {
      type: [String],
      description: "Width",
      default: '100%'
    },
    value: {
      type: [String, Number],
      description: "Input value"
    },
    type: {
      type: String,
      description: "Input type",
      default: "text"
    },
    appendIcon: {
      type: String,
      description: "Append icon (right)"
    },
    prependIcon: {
      type: String,
      description: "Prepend icon (left)"
    },
    rules: {
      type: [String, Array, Object],
      description: "Vee validate validation rules",
      default: ""
    },
    name: {
      type: String,
      description: "Input name (used for validation)",
      default: ""
    },
    disableBoxShadow: {
      type: Boolean,
      default: false,
      description: "Adiciona estilos para desabilitar o box shadow, conforme o novo padrão."
    }
  },
  data() {
    return {
      focused: false
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur
      };
    },
    slotData() {
      return {
        focused: this.focused,
        error: this.error,
        ...this.listeners
      };
    },
    hasIcon() {
      const { append, prepend } = this.$slots;
      return (
        append !== undefined ||
        prepend !== undefined ||
        this.appendIcon !== undefined ||
        this.prependIcon !== undefined ||
        this.group
      );
    }
  },
  methods: {
    updateValue(evt) {
      let value = evt.target.value;
      this.$emit("input", value);
    },
    onFocus(evt) {
      this.focused = true;
      this.$emit("focus", evt);
    },
    onBlur(evt) {
      this.focused = false;
      this.$emit("blur", evt);
    }
  }
};
</script>
<style>
/* declaração de variáveis */
:root {
  --border-color: #E8E8E8;
  --border-top: 0.5px solid var(--border-color);
  --border-right: 0.5px solid var(--border-color);
  --border-left: 0.25px solid var(--border-color);
  --border-bottom: 0.5px solid var(--border-color);
  --border-radius: 8px 8px 8px 8px;
}

.disable-box-shadow-input-group {
  .form-group input {
    box-shadow: none !important;
    border-radius: var(--border-radius);
    border-top: var(--border-top);
    border-right: var(--border-right);
    border-left: var(--border-left);
    border-bottom: var(--border-bottom);
  }
  .input-group-text {
    box-shadow: none !important;
    border-radius: var(--border-radius);
    border-top: var(--border-top);
    border-right: var(--border-right);
    border-left: var(--border-left);
    border-bottom: var(--border-bottom);
  }
  .focused .input-group-text {
    border-top: var(--border-top);
    border-right: var(--border-right);
    border-left: var(--border-left);
    border-bottom: var(--border-bottom);
  }
}
</style>
