<template>
  <div>
    <div class="row card-wrapper">
        <div class="col-lg-4" v-for="(item, index) in items" :key="index">
          <div class="card" style="box-shadow: none !important; border-radius: 16px !important; border: 1px solid #E8E8E8;">
            <div class="card-body">
              <div class="d-flex justify-content-between ">
                <div class="top-section mt--4" style="display: flex; align-items: center;">
                  <div class="">
                    <span v-if="getColor(item) == 'success'" class="badge-custom badge-custom-success">
                      <img src="/img/icons/icons8/ios/star--v1_success.png" width="19px" height="19px">
                      <b>Classificado</b>
                    </span>
                    <span v-if="getColor(item) == 'warning'" class="badge-custom badge-warning">
                      <img src="/img/icons/icons8/ios/ratting-off.png" width="19px" height="19px">
                      <b>Sem Classif.</b>
                    </span>
                    <span v-if="getColor(item) == 'danger'" class="badge-custom badge-danger">
                      <img src="/img/icons/icons8/ios/cancel_danger.png" width="19px" height="19px">
                      <b>Cancelado</b>
                    </span>
                  </div>
                  <div class="row m-0 ml-2" v-if="item.product_service_hub_type_id === ProductServiceHubTypeEnum.FREIGHT">
                    <div class="card-outline-grey text-dark mx-1">
                      CTE
                    </div>
                    <div v-if="item.referenced_entry_launch_is_imported" class="card-outline-success text-success mx-1">
                      NFE
                    </div>
                    <div v-else class="card-outline-warning text-warning mx-1">
                      NFE
                    </div>
                  </div>
                  
                  <div class="row m-0 ml-2" v-else>
                    <div class="card-outline-grey text-dark mx-1">
                      NFE
                    </div>
                    <div v-if="item.referenced_entry_launch_is_imported" class="card-outline-success text-success mx-1">
                      CTE
                    </div>
                    <div v-else class="card-outline-warning text-warning mx-1">
                      CTE
                    </div>
                  </div>
                </div>
                <div class="ml-auto">
                  <base-dropdown>
                    <base-button slot="title-container" type="empty" size="sm" class="dropdown-toggle shadow-none mt--2 mr--2" style="border-radius: 4px !important;">
                      <img height="30px" width="30px" src="/img/icons/icons8/ios/settings--v1_primary.png" class="mr-1 mt-n1"/>
                    </base-button>
                  
                    <span v-if="![EntryLaunchStatusEnum.SEFAZ_CANCELLATION, EntryLaunchStatusEnum.CANCELLED].includes(item.status)" @click.prevent="$router.push(`/configuration/entry-launch/external-create/${company_plant_buyer.company_plant.id}/${company_plant_buyer.id}/${item.id}`);" class="dropdown-item">
                      <img src="/img/icons/create-new.png" width="19px" height="19px" class="imgBaseButton">
                      EDITAR
                    </span>
                    <div class=" dropdown-divider p-0 m-0"></div>
                    <span v-if="![EntryLaunchStatusEnum.SEFAZ_CANCELLATION, EntryLaunchStatusEnum.CANCELLED].includes(item.status)" @click.prevent="$emit('handleModalImportEntryLaunch', item)" class="dropdown-item">
                      <img src="/img/icons/icons8/ios/downloading-updates.png" width="19px" height="19px" class="imgBaseButton">
                      Lançar Xml
                    </span>
                    <div v-if="![EntryLaunchStatusEnum.SEFAZ_CANCELLATION, EntryLaunchStatusEnum.CANCELLED].includes(item.status)" class=" dropdown-divider p-0 m-0"></div>
                    <span v-if="getColor(item) == 'success' || getColor(item) == 'warning'" @click.prevent="$emit('handleCancellation', item.id, index)" class="dropdown-item">
                      <img src="/img/icons/icons8/ios/cancel-2_danger.png" width="19px" height="19px" class="imgBaseButton">
                      Cancelar
                    </span>
                    <span v-else @click.prevent="$emit('handleCancellation', item.id, index)" class="dropdown-item">
                      <img src="/img/icons/icons8/ios/refresh--v1_success.png" width="19px" height="19px" class="imgBaseButton">
                      Restaurar
                    </span>
                  </base-dropdown>
                </div>
              </div>
              <div class="mid-section">
                <div class="mb-2">
                  <span style="font-size: 14px; font-weight: 400;"> {{ item.document_number }} </span>
                </div>
                <div class="d-flex justify-content-start mt--1 mb-2">
                  <h1 class="text-dark mt--1"> {{ item.final_value | currency() }} </h1>
                </div>
                <div class="mt--3">
                  <span style="font-size: 14px; font-weight: 400;"> {{ item.entry_date | parseDate }} </span>
                </div>
                <hr class="gray-divider mt-3 mb-2">
                <div class="end-section mt-3">
                  <div>
                    <span class="text-dark" style="font-weight: 400; font-size: 14px;"> {{ item.entity_name }} </span>
                  </div>
                  <div>
                    <span class="text-dark" style="font-size: 14px;"> {{ parseEin(item.document) }} </span>
                  </div>
                  <span v-if="isClassified(item)"
                    class="float-right mt-n4"
                    @click.prevent="item.checked = item.checked === 1 ? 0 : 1"
                  >
                    <img :src="item.checked === 1 ? '/img/icons/icons8/ios/checked-checkbox.png' : '/img/icons/icons8/ios/checked-checkbox--v1.png'" id="image-check" width="32" class="mb-0 pb-0 mt-n1">
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import {ProductServiceHubTypeEnum} from "@/enum/ProductServiceHubTypeEnum";
import {EntryLaunchStatusEnum} from "@/enum/EntryLaunchStatusEnum";

export default {
  name: "Cards",
  props: {
    loadingSkeleton: Boolean,
    items: Array,
    company_plant_buyer: Object
  },
  components: {
    SkeletonPuzlGrid
  },
  methods: {
    /**
     * Função que recebe um item e retorna a cor do status
     *
     * @param {object} item
     * @returns {string}
     */
    getColor(item) {
      switch (true) {
        case item.status === EntryLaunchStatusEnum.CANCELLED || item.status === EntryLaunchStatusEnum.SEFAZ_CANCELLATION:
          return "danger";
        case (item.status !== EntryLaunchStatusEnum.CANCELLED || item.status !== EntryLaunchStatusEnum.SEFAZ_CANCELLATION) && item.not_registered_items !== EntryLaunchStatusEnum.PENDING:
          return "warning";
        case (item.status !== EntryLaunchStatusEnum.CANCELLED || item.status !== EntryLaunchStatusEnum.SEFAZ_CANCELLATION) && item.not_registered_items === EntryLaunchStatusEnum.PENDING:
          return "success";
      }
    },
    /**
     * Função que recebe um número de identificação sem formatação e o formata para melhor visualização com barra e traços.
     *
     * @param {string} str
     * @returns {string}
     */
    parseEin(str) {
      return str.toString().replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    },
    isClassified(item) {
      if(item.not_registered_items === 0 && ![EntryLaunchStatusEnum.SEFAZ_CANCELLATION, EntryLaunchStatusEnum.CANCELLED].includes(item.status)) {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      ProductServiceHubTypeEnum: ProductServiceHubTypeEnum,
      EntryLaunchStatusEnum: EntryLaunchStatusEnum,
    }
  }
}
</script>
<style>
.entry-launch-custom-float {
  position: fixed;
  bottom: 260px;
  right: 40px;
  background-color: #1a70b7 !important;
  text-align: center;
  -webkit-box-shadow: 2px 2px 3px #999;
  box-shadow: 2px 2px 3px #999;
}
.card-outline-grey {
  border: 1px solid #606062;
  color: #606062;
  padding: 10px;
  height: 14px;
  display: flex;
  align-items: center;
  font-size: 8px;
  font-weight: 400;
}
.card-outline-warning {
  border: 1px solid #F2B532;
  border-radius: 4px;
  height: 14px;
  padding: 10px;
  display: flex;
  align-items: center;
  font-size: 8px;
  font-weight: 400;
}
.card-outline-success {
  border: 1px solid #2AA767;
  padding: 10px;
  border-radius: 4px;
  height: 14px;
  display: flex;
  align-items: center;
  font-size: 8px;
  font-weight: 400;
}
.gray-divider {
  background-color: #2b2d32;
  opacity: 0.1;
  color: #2b2d32;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}
#image-check {
  cursor: pointer;
}
.badge-custom {
  padding: 6px 19px;
  border-radius: 16px;
  cursor: pointer;
}
.badge-custom b {
  font-weight: 400;
  font-size: 12px;
}
.badge-custom img {
  margin-right: 5px;
  margin-bottom: 2px;
}
.badge-danger {
  background-color: #F9E3E1;
  color: #DB4539;
}
.badge-warning {
  background-color: #FEF9F2;
  color: #F2B532;
}
.badge-custom-success {
  background: #F2F7F3;
  color: #149E57;
}
</style>