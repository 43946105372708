import { BaseListFilterType, initBaseListFilterType } from "../../../../../shared/types";
/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} UsersListFilterExtType
 * @property {Array} global - Usado para buscas no padrão antigo da API
 * @property {0|1|null} status - Filtrar entidades por status de ativo ou inativo
 * @property {null | 1 } is_user - Filtrar entidades por usuarios
 * @property {null | 1 } driver - Filtrar entidades por motoristas
 * @property {null | 1 } is_seller - Filtrar entidades por vendedores
 * @property {null | 1 } is_inactive - Filtrar entidades por inativo
 * @property {Number} role_id - Filtrar tipos de permissões do usuario
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & UsersListFilterExtType )} UsersListFilterType
 */
export const UsersListFilterType = {};

/**
 * Inicializar UsersListFilterType
 *
 * @returns {UsersListFilterType}
 */
export const initUsersListFilterType = () => ({
    ...initBaseListFilterType(),
    custom_search: {
        columns: ['users.name', 'users.email'],
        values: []
    },
    company_plant_id: null,
    status: null,
    is_user: null,
    driver: null,
    is_seller: null,
    is_inactive: null,
    order_by: [],
    date_type: null,
    role_id: null,
});