<template>
  <div>
    <modal :backgroundColor="'#e8e8e8'" :show.sync="isOpen" size="lg">
      <template slot="header">
        <div class="d-flex align-items-center">
          <img style="margin-top: 2px" src="/img/icons/icons8/ios/general-ledger_default.png" width="24" />
          <h5 class="text-title ml-3 mb-0">Editar tributos ou item</h5>
        </div>
      </template>
      <template slot="close-button">
        <img class="pointer mt-2" @click="closeModal" src="/img/icons/icons8/ios/close-window.png" alt="close-window" width="17px" />
      </template>

      <div class="mb-2 px-2 mx-1">
        <!-- Aviso -->
        <div v-if="!item.composes_nfe_value" class="mini-card-danger py-2 d-flex align-items-center justify-content-center mt-2 mb-2">
          <div class="row">
            <div class="col-12 d-flex align-items-center">
              <img class="mr-3" src="/img/icons/error-red.png" width="25" height="25" />
              <span class="font-weight-400 mb-0 new-default-black-font">O valor deste item não entra no valor total da Nfe </span>
            </div>
          </div>
        </div>

        <!-- Informação -->
        <div class="d-flex align-items-center mt-4 mb-3">
          <img width="25" height="25" src="/img/icons/info-squared-yellow.png" alt="info-squared" />
          <span class="ml-2 mr-3 new-default-black-font font-weight-600">Informação</span>
          <div class="dropdown-divider" style="width: -webkit-fill-available" />
        </div>

        <div class="row">
          <div class="col-4 pr-2">
            <div style="box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1) !important; border-radius: 0.4rem !important" class="card mb-0">
              <div class="card-body px-2 mx-2 py-2 my-2">
                <img width="35" class="mb-2 text-success" src="/img/icons/icons8/ios/cash-register_success.png" />
                <h5 class="new-default-black-font mb-0 font-weight-normal">Valor total do produto</h5>
                <h2 class="mb-0 font-weight-normal new-default-black-font">{{ productValue | currency }}</h2>
              </div>
            </div>
          </div>

          <div class="col-4 pl-2">
            <div
              style="box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1) !important; border-radius: 0.4rem !important"
              class="card mb-0 border-radius"
            >
              <div class="card-body px-2 mx-2 py-2 my-2">
                <img width="35" class="mb-2" src="/img/icons/icons8/ios/cash-receipt_warning.png" />
                <h5 class="new-default-black-font mb-0 font-weight-normal">Valor unitário do produto</h5>
                <h2 class="mb-0 font-weight-normal new-default-black-font">{{ unitValue | currency }}</h2>
              </div>
            </div>
          </div>

          <div class="col-4 pl-2">
            <div
              style="box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1) !important; border-radius: 0.4rem !important"
              class="card mb-0 border-radius"
            >
              <div class="card-body px-2 mx-2 py-2 my-2">
                <img width="35" class="mb-2" src="/img/icons/check.png" />
                <h5 class="new-default-black-font mb-0 font-weight-normal">Valor final</h5>
                <h2 class="mb-0 font-weight-normal new-default-black-font">{{ totalValue | currency }}</h2>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-4 pl-1">
          <label class="col-md-5 new-default-black-font"> Cfop </label>
          <div class="col-md-7">
            <input v-model="item.cfop" maxlength="4" type="text" class="form-control form-control-sm no-box-shadow" />
          </div>
          <label class="col-md-5 mt-2 new-default-black-font"> Ncm </label>
          <div class="col-md-7 mt-2">
            <input maxlength="8" v-model="item.ncm" type="text" class="form-control form-control-sm no-box-shadow" />
          </div>
          <label class="col-md-5 mt-2 new-default-black-font"> Cnae </label>
          <div class="col-md-7 mt-2">
            <input maxlength="7" v-model="item.cnae" type="text" class="form-control form-control-sm no-box-shadow" />
          </div>
        </div>

        <!-- Impostos -->
        <div class="d-flex align-items-center mt-4 mb-3">
          <img width="25" height="25" src="/img/icons/icons8/ios/tax-cut_danger.png" alt="info-squared" />
          <span class="ml-2 mr-3 new-default-black-font font-weight-600">Impostos</span>
          <div class="dropdown-divider" style="width: -webkit-fill-available" />
        </div>

        <div class="row mt-4 pl-1">
          <label class="col-md-5 new-default-black-font"> Icms</label>
          <div class="col-md-7">
            <base-input input-group-classes="input-group-sm no-box-shadow">
              <input
                v-model.lazy="item.icms_value"
                maxlength="11"
                v-money="moneyOptions"
                type="text"
                class="form-control form-control-sm no-box-shadow"
              />
              <template slot="prepend">
                <small class="input-group-sm p-0 m-0"> R$ </small>
              </template>
            </base-input>
          </div>
          <label class="col-md-5 new-default-black-font mt-2"> Icms com subst. tribut. </label>
          <div class="col-md-7 mt-2">
            <base-input input-group-classes="input-group-sm no-box-shadow">
              <input
                v-model.lazy="item.icms_with_replacement_value"
                maxlength="11"
                v-money="moneyOptions"
                type="text"
                class="form-control form-control-sm no-box-shadow"
              />
              <template slot="prepend">
                <small class="input-group-sm p-0 m-0"> R$ </small>
              </template>
            </base-input>
          </div>
          <label class="col-md-5 mt-2 new-default-black-font"> Ipi </label>
          <div class="col-md-7 mt-2">
            <base-input input-group-classes="input-group-sm no-box-shadow">
              <input
                @blur="calcTotalValue"
                v-model.lazy="item.ipi_value"
                maxlength="11"
                v-money="moneyOptions"
                type="text"
                class="form-control form-control-sm no-box-shadow"
              />
              <template slot="prepend">
                <small class="input-group-sm p-0 m-0"> R$ </small>
              </template>
            </base-input>
          </div>
          <label class="col-md-5 mt-2 new-default-black-font"> Ipi devolvido </label>
          <div class="col-md-7 mt-2">
            <base-input input-group-classes="input-group-sm no-box-shadow">
              <input
                @blur="calcTotalValue"
                v-model.lazy="item.ipi_returned_value"
                maxlength="11"
                v-money="moneyOptions"
                type="text"
                class="form-control form-control-sm no-box-shadow"
              />
              <template slot="prepend">
                <small class="input-group-sm p-0 m-0"> R$ </small>
              </template>
            </base-input>
          </div>
          <label class="col-md-5 mt-2 new-default-black-font"> Pis </label>
          <div class="col-md-7 mt-2">
            <base-input input-group-classes="input-group-sm no-box-shadow">
              <input v-model.lazy="item.pis_value" maxlength="11" v-money="moneyOptions" type="text" class="form-control form-control-sm" />
              <template slot="prepend">
                <small class="input-group-sm p-0 m-0"> R$ </small>
              </template>
            </base-input>
          </div>
          <label class="col-md-5 mt-2 new-default-black-font"> Cofins </label>
          <div class="col-md-7 mt-2">
            <base-input input-group-classes="input-group-sm no-box-shadow">
              <input
                v-model.lazy="item.cofins_value"
                maxlength="11"
                v-money="moneyOptions"
                type="text"
                class="form-control form-control-sm no-box-shadow"
              />
              <template slot="prepend">
                <small class="input-group-sm p-0 m-0"> R$ </small>
              </template>
            </base-input>
          </div>
          <label class="col-md-5 mt-2 new-default-black-font"> Issqn </label>
          <div class="col-md-7 mt-2">
            <base-input input-group-classes="input-group-sm no-box-shadow">
              <input
                v-model.lazy="item.issqn_value"
                maxlength="11"
                v-money="moneyOptions"
                type="text"
                class="form-control form-control-sm no-box-shadow"
              />
              <template slot="prepend">
                <small class="input-group-sm p-0 m-0"> R$ </small>
              </template>
            </base-input>
          </div>
          <label class="col-md-5 mt-2 new-default-black-font"> Imposto de importação </label>
          <div class="col-md-7 mt-2">
            <base-input input-group-classes="input-group-sm no-box-shadow">
              <input
                @blur="calcTotalValue"
                v-model.lazy="item.import_value"
                maxlength="11"
                v-money="moneyOptions"
                type="text"
                class="form-control form-control-sm no-box-shadow"
              />
              <template slot="prepend">
                <small class="input-group-sm p-0 m-0"> R$ </small>
              </template>
            </base-input>
          </div>
          <label class="col-md-5 mt-2 new-default-black-font"> Fcp retido por subst. trib. </label>
          <div class="col-md-7 mt-2">
            <base-input input-group-classes="input-group-sm no-box-shadow">
              <input
                @blur="calcTotalValue"
                v-model.lazy="item.fcp_with_replacement_value"
                maxlength="11"
                v-money="moneyOptions"
                type="text"
                class="form-control form-control-sm no-box-shadow"
              />
              <template slot="prepend">
                <small class="input-group-sm p-0 m-0"> R$ </small>
              </template>
            </base-input>
          </div>
        </div>

        <!-- Outros -->
        <div class="d-flex align-items-center mt-4 mb-3">
          <img width="25" height="25" src="/img/icons/icons8/ios/add_warning.png" alt="info-squared" />
          <span class="ml-2 mr-3 new-default-black-font font-weight-600">Outros</span>
          <div class="dropdown-divider" style="width: -webkit-fill-available" />
        </div>

        <div class="row mt-4 pl-1">
          <label class="col-md-5 new-default-black-font"> Frete </label>
          <div class="col-md-7">
            <base-input input-group-classes="input-group-sm no-box-shadow">
              <input
                @blur="calcTotalValue"
                v-model.lazy="item.freight_value"
                maxlength="11"
                v-money="moneyOptions"
                type="text"
                class="form-control form-control-sm no-box-shadow"
              />
              <template slot="prepend">
                <small class="input-group-sm p-0 m-0"> R$ </small>
              </template>
            </base-input>
          </div>
          <label class="col-md-5 mt-2 new-default-black-font"> Seguro </label>
          <div class="col-md-7 mt-2">
            <base-input input-group-classes="input-group-sm no-box-shadow">
              <input
                @blur="calcTotalValue"
                v-model.lazy="item.insurance_value"
                maxlength="11"
                v-money="moneyOptions"
                type="text"
                class="form-control form-control-sm no-box-shadow"
              />
              <template slot="prepend">
                <small class="input-group-sm p-0 m-0"> R$ </small>
              </template>
            </base-input>
          </div>

          <label class="col-md-5 mt-2 new-default-black-font"> Outras despesas </label>
          <div class="col-md-7 mt-2">
            <base-input input-group-classes="input-group-sm no-box-shadow">
              <input
                @blur="calcTotalValue"
                v-model.lazy="item.other_value"
                maxlength="11"
                v-money="moneyOptions"
                type="text"
                class="form-control form-control-sm no-box-shadow"
              />
              <template slot="prepend">
                <small class="input-group-sm p-0 m-0"> R$ </small>
              </template>
            </base-input>
          </div>
          <label class="col-md-5 mt-2 new-default-black-font"> Serviço </label>
          <div class="col-md-7 mt-2">
            <base-input input-group-classes="input-group-sm no-box-shadow">
              <input
                @blur="calcTotalValue"
                v-model.lazy="item.service_value"
                maxlength="11"
                v-money="moneyOptions"
                type="text"
                class="form-control form-control-sm no-box-shadow"
              />
              <template slot="prepend">
                <small class="input-group-sm p-0 m-0"> R$ </small>
              </template>
            </base-input>
          </div>
        </div>

        <!-- Impostos -->
        <div class="d-flex align-items-center mt-4 mb-3">
          <img width="25" height="25" src="/img/icons/icons8/ios/subtract_success.png" alt="info-squared" />
          <span class="ml-2 mr-3 new-default-black-font font-weight-600">Descontos</span>
          <div class="dropdown-divider" style="width: -webkit-fill-available" />
        </div>

        <div class="row">
          <label class="col-md-5 mt-2 new-default-black-font"> Desconto </label>
          <div class="col-md-7 mt-2">
            <base-input input-group-classes="input-group-sm no-box-shadow">
              <input
                @blur="calcTotalValue"
                v-model.lazy="item.discount_value"
                maxlength="11"
                v-money="moneyOptions"
                type="text"
                class="form-control form-control-sm no-box-shadow"
              />
              <template slot="prepend">
                <small class="input-group-sm p-0 m-0"> R$ </small>
              </template>
            </base-input>
          </div>
          <label class="col-md-5 mt-2 new-default-black-font"> Icms Desonerado </label>
          <div class="col-md-7 mt-2">
            <base-input input-group-classes="input-group-sm no-box-shadow">
              <input
                @blur="calcTotalValue"
                v-model.lazy="item.icms_exonerated_value"
                maxlength="11"
                v-money="moneyOptions"
                type="text"
                class="form-control form-control-sm no-box-shadow"
              />
              <template slot="prepend">
                <small class="input-group-sm p-0 m-0"> R$ </small>
              </template>
            </base-input>
          </div>
        </div>

        <div class="row d-flex justify-content-end mt-5 mb-4 mr-1">
          <div @click="closeModal" class="d-flex align-items-center button-cancel col-auto px-4" style="gap: 5px">
            <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="16px" />
            <span style="font-weight: 500"> Cancelar </span>
          </div>
          <div @click="save" class="d-flex align-items-center button-save col-auto py-1 ml-3 px-4" style="gap: 5px">
            <img src="/img/icons/icons8/ios/save_success.png" alt="save_success" width="16px" />
            <span style="font-weight: 500"> Salvar </span>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script setup>
import { ref, computed, defineEmits, defineProps } from "vue";
import { dialogs } from "../../../../../../../helpers";
import entryLaunchItemTaxStore from "../../store";
import money from "v-money";

const isOpen = ref(false);
const productValue = ref(0);
const totalValue = ref(0);
const unitValue = ref(0);
const item = computed(() => entryLaunchItemTaxStore.getItem());
const moneyOptions = {
  decimal: ",",
  thousands: ".",
  prefix: "",
  suffix: "",
  precision: 2,
  masked: true,
};
const emit = defineEmits(["onSave"]);
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
    required: true,
  },
});

function openModal(entry_launch_item, entry_launch_item_line_number) {
  totalValue.value = entry_launch_item.total_value;
  productValue.value = entry_launch_item.product_value ? entry_launch_item.product_value : entry_launch_item.total_value;
  unitValue.value = entry_launch_item.product_price ? entry_launch_item.product_price : entry_launch_item.price;
  entryLaunchItemTaxStore.setItem(entry_launch_item.tax ? entry_launch_item.tax : null);
  if (!item.value.line_number) {
    item.value.line_number = entry_launch_item_line_number;
  }
  isOpen.value = true;
}

function save() {
  if (props.disabled) {
    isOpen.value = false;
    return dialogs.notify("danger", "Nota proveniente da Sefaz, edição não disponível.");
  }

  emit("onSave", item.value, totalValue.value);
  isOpen.value = false;
}

function closeModal() {
  isOpen.value = false;
}

function calcTotalValue() {
  // impostos
  const payload = JSON.parse(JSON.stringify(item.value));
  let newTotalValue = parseFloat(productValue.value);
  // seguro
  newTotalValue += parseFloat(payload.insurance_value.replaceAll(".", "").replace(",", "."));
  // fcp por substituição
  newTotalValue += parseFloat(payload.fcp_with_replacement_value.replaceAll(".", "").replace(",", "."));
  // frete
  newTotalValue += parseFloat(payload.freight_value.replaceAll(".", "").replace(",", "."));
  // outros
  newTotalValue += parseFloat(payload.other_value.replaceAll(".", "").replace(",", "."));
  // importação
  newTotalValue += parseFloat(payload.import_value.replaceAll(".", "").replace(",", "."));
  // ipi
  newTotalValue += parseFloat(payload.ipi_value.replaceAll(".", "").replace(",", "."));
  // ipi devolvido
  newTotalValue += parseFloat(payload.ipi_returned_value.replaceAll(".", "").replace(",", "."));
  // serviço
  newTotalValue += parseFloat(payload.service_value.replaceAll(".", "").replace(",", "."));
  // desconto
  newTotalValue -= parseFloat(payload.discount_value.replaceAll(".", "").replace(",", "."));
  // icms desonerado
  newTotalValue -= parseFloat(payload.icms_exonerated_value.replaceAll(".", "").replace(",", "."));

  totalValue.value = newTotalValue.toFixed(2);
}

defineExpose({
  openModal,
});
</script>
