/**
 * Tipo para aba selecionada
 *
 * @typedef {Object} TabSelectItemType
 * @property {any|null} id - identificação
 * @property {string} name - Nome da aba
 * @property {string} suffix - Sufixo da aba
 * @property {boolean} selected - Indica se a aba está selecionada
 */
export const TabSelectItemType = {};

/**
 * Inicializa uma aba com valores padrão
 *
 * @returns {TabSelectItemType} - Aba
 */
export const initTabSelectItemType = () => {
  return {
    id: null,
    name: '',
    suffix: '',
    selected: false,
  }
}

/**
 * Inicializa um array de abas
 *
 * @returns {Array<TabSelectItemType>} - Array de abas
 */
export const initTabSelectItemsType = () => {
  return [];
}
