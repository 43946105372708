<template>
  <div>
    <InCourse />
  </div>
</template>

<script>
import InCourse from "../../Operational/InCourse/Index.vue";

export default {
  name: "Index",
  /* Será utilizado em breve. */
  props: {

  },
  components: {
    InCourse
  }
}
</script>