<template>
  <div>
    <modal :borderInfo="'2px solid #1b6eba'" :show.sync="modal" :size="'lg'">
      <template slot="header">
        <div style="display: flex;align-items: center;">
          <img style="display: inline-flex;" src="/img/icons/icons8/ios/create-new-white.png" width="22">
          <h5 style="display: inline-flex;" class="modal-title p-0 m-0 ml-3 text-white">{{ title }}</h5>
        </div>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(save)"
            autocomplete="off"
          >
            <div class="container mt-3">
              <div class="row align-items-center">
                <div class="col-4" style="display: flex;align-items: center;">
                  <el-popover trigger="click" placement="right">
                    <img class="pointer" slot="reference" src="/img/icons/test-tube.png" width="24">
                    <div v-if="!technicalPermissions.is_view_technical_data_of_traces_in_test_results_launch"
                      style="width: 170px; word-break: break-word;">
                      <h5 class="text-danger">
                        Sem permissão
                      </h5>
                      <div class="new-default-black-font" style="font-size: 12px;">
                        Visualização indisponível, solicite permissão ao gestor.
                      </div>
                    </div>
                    <div style="margin-bottom: 5px"
                      v-if="hardened_state_test.mix_code_id && technicalPermissions.is_view_technical_data_of_traces_in_test_results_launch"
                      v-for="item in hardened_state_test.company_plants">
                      <i title="Copiar código do traço" @click.prevent="copyMixCode(item.mix_code_id)"
                         style="font-size: 19px" class="zoom pointer fa-regular fa-clipboard text-primary"></i>
                      <span class="font-weight-400 ml-2">{{ item.company_plant_name }} <badge style="font-size: 10px"
                                                                                            type="default">{{
                        item.mix_code_id
                      }}</badge></span>
                    </div>
                    <div v-if="technicalPermissions.is_view_technical_data_of_traces_in_test_results_launch"
                      class="border-left border-3 border-primary p-2 mt-1">
                      <h5 class="h4 mb-0">
                        {{ report.contract_proposal_formulation.title }}
                      </h5>
                      <div class="mt-2 mb-3">
                        <h5 class="small">
                          {{ report.contract_proposal_formulation.body }}
                        </h5>
                      </div>
                      <div class="mt-2 mb-3">
                        <h5 class="small">
                          {{ report.contract_proposal_formulation.footer }}
                        </h5>
                      </div>
                    </div>
                  </el-popover>
                  <h5 class="ml-2 font-weight-400 my-0">{{ report.updated_at | parseDate }}</h5>
                </div>
                <div class="col-8 pr-4">
                  <div class="row text-right">
                    <div class="col px-1">
                      <a class="card-with-box-shadow text-center" style="display: inherit;">
                        <div class="py-2">
                          <img class="mr-2" width="18" src="/img/icons/nfe.png"/>
                          <b class="new-default-black-font numeric">{{ hardened_state_test.xml_travel_number }}</b>
                        </div>
                      </a>
                    </div>
                    <div class="col px-1">
                      <a class="card-with-box-shadow text-center" style="display: inherit;">
                        <div class="py-2">
                          <img class="mr-1" width="22" src="/img/icons/icons8/ios/os.png"/>
                          <b class="new-default-black-font numeric">{{ report.index }}</b>
                        </div>
                      </a>
                    </div>
                    <div class="col px-1">
                      <a class="card-with-box-shadow text-center" style="display: inherit;">
                        <div class="py-2 px-3" style="display: inline-flex;align-items: center;">
                          <div class="row" style="width: 141.6px;">
                            <div class="col-6 text-right pr-0">
                              <img class="mr-2" width="18" src="/img/icons/icons8/ios/source-code.png"/>
                            </div>
                            <div class="col-6 text-left pl-0">
                              <input
                                  style="max-width: 100%;"
                                  v-model="hardened_state_test.code"
                                  v-mask="'##########'"
                                  inputmode="numeric"
                                  placeholder="N/A"
                                  class="invisible-input"
                                />
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row mb-1">
                <div class="col-4">
                  <h4>Data da amostragem *</h4>
                  <input-date-time-picker :spaceMinutes="1"
                    :disableDate="disableDate"
                    @input="setRealPeriod"
                    v-model="hardened_state_test.sampling_at"
                    :size="'lg'"/>
                </div>
                <div class="col-5">
                  <h4>Idade do ensaio *</h4>
                  <div class="row">
                    <div class="col-6 pr-1">
                      <validation-provider rules="required">
                        <base-input input-group-classes="input-group-sm">
                          <input
                            @input="getProjections"
                            v-model="hardened_state_test.period"
                            v-mask="'##'"
                            inputmode="numeric"
                            class="form-control form-control-sm"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                    <div class="col-6 pl-1">
                      <validation-provider rules="required">
                        <base-input input-classes="form-control-sm">
                          <el-select
                            v-model="hardened_state_test.period_type"
                            size="mini"
                            filterable
                            placeholder="Tipo"
                          >
                            <el-option
                              v-for="days_or_hour in days_or_hours"
                              :key="days_or_hour.id"
                              :label="days_or_hour.description"
                              :value="days_or_hour.id"
                            >
                            </el-option>
                          </el-select>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <div style="width: 107px;" class="float-right">
                    <h4>Puzl Connect</h4>
                    <base-switch
                      v-model="hardened_state_test.puzl_client"
                      type="indigo" offText="Não" onText="Sim"
                      class="indigo">
                    </base-switch>
                  </div>
                </div>
              </div>
              <div class="row mt-4 mb-4">
                <div class="col-4 pr-0">
                  <base-button class="border-new-default-gray base-button"
                    @click.prevent="tab = 1"
                    :class="{ active: (tab === 1) }"
                    outline
                    style="border-top-left-radius: 1.5rem !important;border-bottom-left-radius: 1.5rem !important;border-top-right-radius: 0 !important;border-bottom-right-radius: 0 !important;"
                    type="primary"
                    block>
                    <img
                      :src="
                        (tab === 1)
                          ? '/img/icons/icons8/ios/info-squared_white.png'
                          : '/img/icons/info-squared.png'
                      "
                      width="19px"
                      height="19px"
                      class="invert-on-hover mr-1" />
                    <span :class="(tab === 1) ? 'text-white' : 'text-dark'"
                      style="font-size: 10px">
                      Identificação
                    </span>
                  </base-button>
                </div>
                <div class="col-4 px-0">
                  <!-- @click.prevent="goToServices" -->
                  <base-button @click.prevent="tab = 2"
                    type="primary"
                    style="border-radius: 0 !important;"
                    class="border-new-default-gray base-button"
                    :class="{ active: (tab === 2) }"
                    outline
                    block
                  >
                    <img
                      :src="
                        (tab === 2)
                          ? '/img/icons/icons8/ios/test-passed--v1.png'
                          : '/img/icons/icons8/ios/test-passed--v1_primary.png'
                      "
                      width="19px"
                      height="19px"
                      class="invert-on-hover mr-1"
                    />
                    <span :class="(tab === 2) ? 'text-white' : 'text-dark'"
                      style="font-size: 10px">
                      Resultado
                    </span>
                  </base-button>
                </div>
                <div class="col-4 pl-0">
                  <base-button @click.prevent="tab = 3" class="border-new-default-gray base-button"
                    :class="{ active: (tab === 3) }"
                    :disabled="loading_evolution"
                    outline
                    style="border-top-left-radius: 0 !important;border-bottom-left-radius: 0 !important;border-top-right-radius: 1.5rem !important;border-bottom-right-radius: 1.5rem !important;"
                    type="primary"
                    block>
                    <img
                      :src="
                        (tab === 3)
                          ? '/img/icons/icons8/ios/sign-up-in-calendar.png'
                          : '/img/icons/icons8/ios/sign-up-in-calendar_success.png'
                      "
                      width="19px"
                      height="19px"
                      class="invert-on-hover mr-1" />
                    <span :class="(tab === 3) ? 'text-white' : 'text-dark'"
                      style="font-size: 10px">
                      Evolução
                    </span>
                  </base-button>
                </div>
              </div>
              <div class="row" v-if="tab === 1">
                <div class="col-12">
                  <div class="row mt-1">
                    <div class="col-12">
                      <div class="card-with-box-shadow px-3 py-2">
                        <div class="row mb-3 pt-1">
                          <div class="col-12">
                            <h4 style="display: flex; align-items: center;">
                              <img class="mr-2" src="/img/icons/icons8/ios/star--v1_primary.png" width="18">
                              Dados técnicos
                            </h4>
                          </div>
                        </div>
                        <div v-if="!technicalPermissions.is_view_technical_data_of_traces_in_test_results_launch"
                          class="row mb-3">
                          <div class="col-12">
                            <h5 class="text-danger">
                              Sem permissão
                            </h5>
                            <div class="new-default-black-font" style="font-size: 12px;">
                              Visualização indisponível, solicite permissão ao gestor.
                            </div>
                          </div>
                        </div>
                        <div v-if="technicalPermissions.is_view_technical_data_of_traces_in_test_results_launch"
                          class="row mb-3">
                          <div class="col-4">
                            <h4 class="font-weight-400" style="font-size: 12px;">Ensaio</h4>
                            <validation-provider rules="required">
                              <base-input input-classes="form-control-sm">
                                <el-select
                                  v-model="hardened_state_test.hardened_state_id"
                                  size="mini"
                                  filterable
                                  @change="setHardenedState"
                                  placeholder="Selecione"
                                >
                                  <el-option
                                    v-for="test in hardened_states"
                                    :key="test.uuid"
                                    :label="test.short_description"
                                    :value="test.id"
                                  >
                                  </el-option>
                                </el-select>
                              </base-input>
                            </validation-provider>
                          </div>
                          <div class="col-4">
                            <h4 class="font-weight-400" style="font-size: 12px;">Responsabilidade</h4>
                            <validation-provider rules="required">
                              <base-input input-classes="form-control-sm">
                                <el-select
                                  @change="changeFeature"
                                  :disabled="!selected.features.length"
                                  v-model="hardened_state_test.feature_id"
                                  size="mini"
                                  filterable
                                  placeholder="Selecione"
                                >
                                  <el-option
                                    v-for="feature in selected.features"
                                    :key="feature.id"
                                    :label="feature.label"
                                    :value="feature.id"
                                  >
                                  </el-option>
                                </el-select>
                              </base-input>
                            </validation-provider>
                          </div>
                          <div class="col-4">
                            <h4 class="font-weight-400" style="font-size: 12px;">Item</h4>
                            <validation-provider rules="required">
                              <base-input input-classes="form-control-sm">
                                <el-select
                                  :disabled="!selectedFeature.item.length"
                                  v-model="hardened_state_test.item_id"
                                  size="mini"
                                  filterable
                                  placeholder="Selecione"
                                >
                                  <el-option
                                    v-for="item in selectedFeature.item"
                                    :key="item.id"
                                    :label="item.description +' '+selectedFeature.measured_unit.letter"
                                    :value="item.id"
                                  >
                                  </el-option>
                                </el-select>
                              </base-input>
                            </validation-provider>
                          </div>
                        </div>
                        <div v-if="technicalPermissions.is_view_technical_data_of_traces_in_test_results_launch"
                          class="row mb-3">
                          <div class="col-4">
                            <h4 class="font-weight-400" style="font-size: 12px;">SD</h4>
                            <validation-provider rules="required">
                              <input
                                v-model="hardened_state_test.sd"
                                v-mask="['#.##']"
                                inputmode="numeric"
                                class="form-control form-control-sm"
                              />
                            </validation-provider>
                          </div>
                          <div class="col-4">
                            <h4 class="font-weight-400" style="font-size: 12px;">N.C *</h4>
                            <validation-provider rules="required">
                              <base-input input-group-classes="input-group-sm">
                                <el-select v-model="hardened_state_test.nc"
                                           @change="changeValueZ()"
                                           placeholder="Selecione"
                                           size="mini">
                                  <el-option label="Selecione" value="">
                                  </el-option>
                                  <el-option v-for="(reliability_level, indexReliability) in reliability_levels"
                                             :key="indexReliability" :label="reliability_level.reliability+'%'"
                                             :value="reliability_level.value_z">
                                  </el-option>
                                </el-select>
                              </base-input>
                            </validation-provider>
                          </div>
                          <div class="col-4">
                            <h4 class="font-weight-400" style="font-size: 12px;">Z</h4>
                            <validation-provider rules="required">
                              <input
                                disabled
                                v-model="hardened_state_test.z"
                                v-mask="['#.##']"
                                inputmode="numeric"
                                class="form-control form-control-sm"
                              />
                            </validation-provider>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-4 mb-3">
                    <div class="col-12">
                      <div class="card-with-box-shadow px-3 py-2">
                        <div class="row mb-3 pt-1">
                          <div class="col-12">
                            <h4 style="display: flex; align-items: center;">
                              <img class="mr-2" src="/img/icons/icons8/ios/brick-wall_warning.png" width="18">
                              Rastreabilidade
                            </h4>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-4">
                            <h4 class="font-weight-400" style="font-size: 12px;">Local de amostragem *</h4>
                            <validation-provider rules="required">
                              <base-input input-classes="form-control-sm">
                                <el-select
                                  :disabled="loadingMoldingLocation"
                                  size="mini" v-model="hardened_state_test.molding_location_id" filterable
                                  placeholder="Selecione"
                                  :selected="false">
                                  <el-option class="text-normal" v-for="item in molding_locations"
                                             :key="item.id" :label="item.name"
                                             :value="item.id">
                                  </el-option>
                                </el-select>
                              </base-input>
                            </validation-provider>
                          </div>
                          <div class="col-4">
                            <h4 class="font-weight-400" style="font-size: 12px;">Molde *</h4>
                            <validation-provider rules="required">
                              <base-input input-classes="form-control-sm">
                                <el-select
                                  :disabled="!selected.uuid"
                                  v-model="hardened_state_test.mold_id"
                                  size="mini"
                                  filterable
                                  placeholder="Selecione"
                                >
                                  <el-option
                                    v-for="mold in selected.molds"
                                    :key="mold.uuid"
                                    :label="mold.description"
                                    :value="mold.id"
                                  >
                                  </el-option>
                                </el-select>
                              </base-input>
                            </validation-provider>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="tab === 2">
                <div class="col-12">
                  <div class="row mt-1 mb-3">
                    <div class="col-12">
                      <div class="card-with-box-shadow px-3 py-2">
                        <div class="row mb-3 pt-1">
                          <div class="col-8">
                            <h4 style="display: flex; align-items: center;">
                              <img class="mr-2" src="/img/icons/icons8/ios/color-dropper_primary.png" width="18">
                              Ensaio
                            </h4>
                          </div>
                          <div class="col-4">
                            <base-switch
                              @input="unsetReason()"
                              v-model="hardened_state_test.molder"
                              type="success" offText="Não" onText="Sim"
                              class="success float-right"></base-switch>
                          </div>
                        </div>
                        <div class="row mb-3" v-if="hardened_state_test.molder">
                          <div class="col-4">
                            <h4 class="font-weight-500" style="font-size: 12px;">
                              Data do ensaio (real)
                              <img @click.prevent="copyPredictDateToReal"
                                class="ml-2 pointer float-right"
                                src="/img/icons/icons8/ios/copy-2.png" width="18">
                            </h4>
                            <validation-provider :rules="hardened_state_test.result ? 'required' : ''">
                              <base-input input-group-classes="input-group-sm">
                                <input-date-time-picker :spaceMinutes="1"
                                  @input="setRealPeriod"
                                  v-model="hardened_state_test.date_at"
                                  :size="'lg'"/>
                              </base-input>
                            </validation-provider>
                          </div>
                          <div class="col-4">
                            <h4 class="font-weight-400" style="font-size: 12px;">Idade (real)</h4>
                            <base-input input-group-classes="input-group-sm">
                              <input
                                disabled
                                v-model="hardened_state_test.real_period"
                                inputmode="numeric"
                                class="form-control form-control-sm"
                              />
                            </base-input>
                          </div>
                          <div class="col-4">
                            <h4 class="font-weight-400" style="font-size: 12px;">Data do ensaio (previsto)</h4>
                            <base-input input-group-classes="input-group-sm">
                              <input-date-time-picker :spaceMinutes="1"
                                :disabled="true"
                                v-model="hardened_state_test.provided_at"
                                :size="'lg'"/>
                            </base-input>
                          </div>
                        </div>
                        <div v-if="diffProvidedAndRealInDays() >= 5" class="row mb-3">
                          <div class="col-12">
                            <h5 class="font-weight-400" style="display: flex;align-items: center;">
                              <img class="mr-2" src="/img/icons/error-red.png" width="16">
                              Diferença de idade prevista e real não pode ser superior a 5 dias
                              <el-popover trigger="click" placement="right">
                                <img slot="reference" class="ml-2" src="/img/icons/icons8/ios/info-circle_warning.png" width="22">
                                <div class="pointer new-default-black-font" style="margin-bottom: 5px; font-size: 11px;width: 150px;word-break: break-word;">
                                  Altere a data do ensaio (real) ou então altere a idade do ensaio.
                                </div>
                              </el-popover>
                            </h5>
                          </div>
                        </div>
                        <div class="row mb-3" style="align-items: end;">
                          <div class="col-4" v-if="hardened_state_test.molder">
                            <h4 class="font-weight-500" style="font-size: 12px;">
                              Resultado (real)
                              <span v-show="hardened_state_test.date_at || hardened_state_test.result"
                              class="text-danger">*</span>
                              <img @click.prevent="handleShowTonne" class="ml-2 pointer" src="/img/icons/icons8/ios/calculator_black.png" width="18">
                            </h4>
                            <validation-provider v-if="!showTonne" :rules="hardened_state_test.date_at && hardened_state_test.molder ? 'required' : ''">
                              <base-input input-group-classes="input-group-sm">
                                <input
                                  v-mask="['#,#', '##,#', '###,#']"
                                  v-model="hardened_state_test.result"
                                  inputmode="numeric"
                                  class="form-control form-control-sm"
                                />
                                <template slot="append">
                                  <small v-show="unit() != false" style="text-transform: none" class="input-group-sm p-0 m-0">
                                    {{ measuredunit }}
                                  </small>
                                </template>
                              </base-input>
                            </validation-provider>
                            <validation-provider v-if="showTonne" :rules="hardened_state_test.date_at && hardened_state_test.molder ? 'required' : ''">
                              <div class="mb-2">
                                <base-input input-group-classes="input-group-sm">
                                  <input
                                    @input="setResult()"
                                    v-mask="['#,#', '##,##', '###,##']"
                                    v-model="hardened_state_test.ton"
                                    inputmode="numeric"
                                    class="form-control form-control-sm"
                                  />
                                  <template slot="append">
                                    <small class="input-group-sm p-0 m-0">
                                      TON
                                    </small>
                                  </template>
                                </base-input>
                              </div>
                              <base-input id="input-red" input-group-classes="input-group-sm">
                                <input
                                  disabled
                                  :placeholder="(Number(hardened_state_test.result)).toFixed(1).toString().replace('.', ',')"
                                  inputmode="numeric"
                                  class="form-control form-control-sm"
                                />
                                <template slot="append">
                                  <small v-show="unit() != false" style="text-transform: none" class="input-group-sm p-0 m-0">
                                    {{ measuredunit }}
                                  </small>
                                </template>
                              </base-input>
                            </validation-provider>
                          </div>
                          <div class="col-4" v-if="hardened_state_test.molder">
                            <h4 class="font-weight-400" style="font-size: 12px;">Resultado (estimado)</h4>
                            <base-input input-group-classes="input-group-sm">
                              <input
                                v-mask="['#,#', '##,#', '###,#']"
                                v-model="hardened_state_test.result_expected"
                                inputmode="numeric"
                                class="form-control form-control-sm"
                              />
                              <template slot="append">
                                <small v-show="unit() != false" class="input-group-sm p-0 m-0">
                                  {{ measuredunit }}
                                </small>
                              </template>
                            </base-input>
                          </div>
                          <div class="col-8" v-if="!hardened_state_test.molder">
                            <h4 class="font-weight-400" style="font-size: 12px;">
                              Motivo
                              <span class="text-danger">*</span>
                            </h4>
                            <validation-provider :rules="!hardened_state_test.molder ? 'required' : ''">
                              <input
                                v-model="hardened_state_test.reason"
                                class="form-control form-control-sm"
                              />
                            </validation-provider>
                          </div>
                          <div class="col-2 text-center" v-if="hardened_state_test.molder">
                            <div style="width: 80px;text-align: left;display: inline-block;">
                              <h4 class="font-weight-400" style="font-size: 12px;">Estatístico</h4>
                              <base-switch
                                v-model="hardened_state_test.statistical"
                                type="success" offText="Não" onText="Sim"
                                class="success">
                              </base-switch>
                            </div>
                          </div>
                          <div class="col-2 text-center" v-if="hardened_state_test.molder">
                            <div style="width: 80px;text-align: left;display: inline-block;">
                              <h4 class="font-weight-400" style="font-size: 12px;">Relatório</h4>
                              <base-switch
                                v-model="hardened_state_test.report"
                                type="success" offText="Não" onText="Sim"
                                class="success"></base-switch>
                            </div>
                          </div>
                        </div>
                        <div v-if="verifyResistance()" class="row mb-3">
                          <div class="col-12">
                            <h5 class="font-weight-400" style="display: flex;align-items: center;">
                              <img class="mr-2" src="/img/icons/error-red.png" width="16">
                              Alta resistência identificada. Confira sempre o resultado digitado.
                            </h5>
                            <div class="form-check">
                              <input v-model="confirmResult" @click="changeConfirmResult" class="form-check-input" type="checkbox" id="confirmarResultado">
                              <label class="form-check-label new-default-black-font" style="font-size: 12px;" for="confirmarResultado">
                                Confirmar resultado
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-1" v-if="hardened_state_test.molder">
                    <div class="col-12">
                      <div class="card-with-box-shadow px-3 py-2">
                        <div class="row mb-3 pt-1">
                          <div class="col-12">
                            <h4 style="display: flex; align-items: center;">
                              <img class="mr-2" src="/img/icons/icons8/ios/about_success.png" width="18">
                              Informações complementares
                            </h4>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-4">
                            <h4 class="font-weight-400" style="font-size: 12px;">Padrão de rompimento</h4>
                          </div>
                          <div class="col-8">
                            <base-input input-group-classes="input-group-sm">
                              <el-select
                                v-model="hardened_state_test.disruption"
                                size="mini"
                                filterable
                                placeholder="Selecione"
                              >
                                <el-option
                                  v-for="disruption in disruptions"
                                  :key="disruption.id"
                                  :label="'(' + disruption.id + ') ' + disruption.text"
                                  :value="disruption.id"
                                >
                                </el-option>
                              </el-select>
                            </base-input>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-4">
                            <h4 class="font-weight-400" style="font-size: 12px;">Anomalias</h4>
                          </div>
                          <div class="col-8">
                            <base-input input-group-classes="input-group-sm">
                              <PuzlSelect multiple :items="$_anomalies" v-model="hardened_state_test.anomalies"/>
                            </base-input>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-4">
                            <h4 class="font-weight-400" style="font-size: 12px;">Responsável pelo ensaio</h4>
                          </div>
                          <div class="col-8">
                            <base-input input-group-classes="input-group-sm">
                              <PuzlSelect :items="$_users" v-model="hardened_state_test.user_id"/>
                            </base-input>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-2 pr-1">
                            <h4 class="font-weight-400" style="font-size: 12px;">Cura</h4>
                            <base-input input-classes="form-control-sm">
                              <el-select
                                v-model="hardened_state_test.health"
                                size="mini"
                                filterable
                                placeholder="Selecione"
                              >
                                <el-option
                                  v-for="(health, key) in healths"
                                  :key="key"
                                  :label="health"
                                  :value="key"
                                >
                                </el-option>
                              </el-select>
                            </base-input>
                          </div>
                          <div class="col-2 pl-1 pr-1">
                            <h4 class="font-weight-400" style="font-size: 12px;">M.E</h4>
                            <base-input input-group-classes="input-group-sm">
                              <input
                                v-mask="'####'"
                                v-model="hardened_state_test.me"
                                inputmode="numeric"
                                class="form-control form-control-sm"
                              />
                              <template slot="append">
                                <small class="input-group-sm p-0 m-0">
                                  kg/m³
                                </small>
                              </template>
                            </base-input>
                          </div>
                          <div class="col-4 pl-1 pr-1">
                            <h4 class="font-weight-400" style="font-size: 12px;">Base inferior</h4>
                            <base-input input-classes="form-control-sm">
                              <el-select
                                v-model="hardened_state_test.bottom_base"
                                size="mini"
                                multiple
                                filterable
                                placeholder="Selecione"
                              >
                                <el-option
                                  v-for="base in base_preparations"
                                  :key="base.id"
                                  :label="base.description"
                                  :value="base.id"
                                >
                                </el-option>
                              </el-select>
                            </base-input>
                          </div>
                          <div class="col-4 pl-1">
                            <h4 class="font-weight-400" style="font-size: 12px;">Base Superior</h4>
                            <base-input input-classes="form-control-sm">
                              <el-select
                                v-model="hardened_state_test.top_base"
                                size="mini"
                                multiple
                                filterable
                                placeholder="Selecione"
                              >
                                <el-option
                                  v-for="base in base_preparations"
                                  :key="base.id"
                                  :label="base.description"
                                  :value="base.id"
                                >
                                </el-option>
                              </el-select>
                            </base-input>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-12">
                            <h4 class="font-weight-400" style="font-size: 12px;">Observações</h4>
                            <base-input input-group-classes="input-group-sm">
                              <textarea
                                v-model="hardened_state_test.observation"
                                type="text"
                                rows="6"
                                class="form-control form-control-sm">
                              </textarea>
                            </base-input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-1" v-else>
                    <div class="col-12">
                      <div class="card-with-box-shadow px-3 py-2">
                        <div class="row mb-3 pt-1">
                          <div class="col-12">
                            <h4 style="display: flex; align-items: center;">
                              <img class="mr-2" src="/img/icons/icons8/ios/about_success.png" width="18">
                              Informações complementares
                            </h4>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-4">
                            <h4 class="font-weight-400" style="font-size: 12px;">Anomalias</h4>
                          </div>
                          <div class="col-8">
                            <base-input input-group-classes="input-group-sm">
                              <PuzlSelect multiple :items="$_anomalies" v-model="hardened_state_test.anomalies"/>
                            </base-input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <section v-show="tab ===3">
                <div class="form-group row m-0 p-0">
                  <div class="col-md-12">
                    <h4 class="small">
                      Referência <b>{{ hardened_state_test.schedule_period }}</b>
                      {{
                        formatPeriodType(hardened_state_test.schedule_period, hardened_state_test.schedule_period_type)
                      }}
                    </h4>
                  </div>
                  <div class="col-md-12">
                    <h4 v-if="hardened_state_test.max_result" class="small">
                      Maior resultado
                      <b>{{ parseFloat(hardened_state_test.max_result).toFixed(1).toString().replace('.', ',') }}</b>
                      {{ hardened_state_test.letter }}
                    </h4>
                    <h4 v-else class="small text-warning">
                      Sem resultado
                    </h4>
                  </div>
                  <div v-if="Object.values(projections).length" class="col-md-12 mt-2">
                    <table class="table table-bordered table-sm">
                      <thead>
                      <tr style="background-color: #4e555b">
                        <th class="text-white text-center" scope="col">Idade</th>
                        <th class="text-white text-center" scope="col">%</th>
                        <th class="text-white text-center" scope="col">{{ hardened_state_test.letter }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item, key) in projections">
                        <template v-if="!periodIsBigger(item)">
                          <td class="text-center" width="33%">{{ item.period }}
                            {{ formatPeriodType(item.period, item.period_type) }}
                          </td>
                          <td class="text-center" width="33%">
                            {{
                              hardened_state_test.max_result && item.result ? showAndSetPercentage(item) : '-'
                            }}
                          </td>
                          <td class="text-center" width="33%">
                            {{ item.result ? parseFloat(item.result).toFixed(1).toString().replace('.', ',') : '-' }}
                          </td>
                        </template>
                        <template v-if="periodIsBigger(item)">
                          <td class="text-center" width="33%">{{ item.period }}
                            {{ formatPeriodType(item.period, item.period_type) }}
                          </td>
                          <td class="text-center" width="33%">
                            <base-input :disabled="!hardened_state_test.max_result" v-mask="'###'" size="sm" maxlength="3"
                                        v-model="item.percentage"/>
                          </td>
                          <td class="text-center" width="33%">{{
                              item.percentage ? calcPercentage(item.percentage, key) : '-'
                            }}
                          </td>
                        </template>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
              <div class="row mb-3">
                <div class="col-12">
                  <div class="text-right mt-4 mr-3">
                    <base-button type="secondary" @click="closeModal()">
                      Cancelar
                    </base-button>
                    <base-button type="success" native-type="submit"
                                 v-bind:disabled="invalid || diffProvidedAndRealInDays() >= 5 || (!confirmResult && verifyResistance())"
                                 :loading="loadingSave">
                      Salvar
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {VMoney} from 'v-money';
import moment from "moment";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import {debounce} from "@/plugins";
import PuzlSelect from "@/components/PuzlSelect.vue";

export default {
  props: {
    refreshData: { type: Function },
  },
  components: {InputDateTimePicker, PuzlSelect},
  name: "ModalEdit",
  data() {
    return {
      disableDate: null,
      loadingProjections: false,
      loadingMoldingLocation: false,
      modal: false,
      tab: 1,
      showTonne: false,
      technicalPermissions: {},
      loading_evolution: false,
      windowWidth: null,
      loadingSave: false,
      title: "Editar ensaio - estado endurecido",
      loadingHardenedState: false,
      hardened_state_test: {},
      projections: [],
      showIdentification: true,
      confirmResult: false,
      healths: [
        'Total',
        'Parcial',
        'Nenhuma'
      ],
      selectedFeature: {
        item: []
      },
      selected: {
        molds: [],
        features: [],
      },
      disruptions: [
        {
          id: 'A',
          text: 'Cônica e cônica afastada 25mm do capeamento'
        },
        {
          id: 'B',
          text: 'Cônica e bipartida e cônica e com mais de uma partição'
        },
        {
          id: 'C',
          text: 'Colunar com formação de cones'
        },
        {
          id: 'D',
          text: 'Cônica e cisalhada'
        },
        {
          id: 'E',
          text: 'Cisalhada'
        },
        {
          id: 'F',
          text: 'Fraturas no topo e/ou na base abaixo do capeamento'
        },
        {
          id: 'G',
          text: 'Similar ao tipo f com fraturas próximas ao topo'
        },
      ],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false
      },
      measuredunit: null,
      days_or_hours: [
        {
          id: 1,
          description: 'Dias'
        },
        {
          id: 2,
          description: 'Horas'
        }
      ],
    }
  },
  directives: {money: VMoney},
  computed: {
    ...mapGetters({
      'hardened_states': 'testHardenedState/fetch',
      'schedule_hardened_states': 'travelConfiguration/showHardenedStates',
      molding_locations: "moldingLocation/fetch",
      base_preparations: "basePreparation/fetch",
      'report': 'testReport/show',
      'reliability_levels': 'reliability_level/fetch',
      $_users: 'user/fetch',
      $_anomalies: 'anomaly/fetch',
    }),
  },
  mounted() {
    this.$watch('hardened_state_test.sampling_at', function (value) {
      if (value && this.hardened_state_test.period && this.hardened_state_test.period_type) {
        const period = +this.hardened_state_test.period
        if (this.hardened_state_test.period_type === 1) {
          this.hardened_state_test.provided_at = this.addDays(this.hardened_state_test.sampling_at, period)
        } else {
          let date = new Date(this.hardened_state_test.sampling_at);
          this.hardened_state_test.provided_at = moment(date).add(period, 'hours').format('YYYY-MM-DD H:mm:ss')
        }
      }
    })
    this.$watch('hardened_state_test.period', function (value) {
      if (this.hardened_state_test.period !== this.hardened_state_test.old_period) {
        this.hardened_state_test.result_expected = null
      } else {
        this.hardened_state_test.result_expected = this.hardened_state_test.old_result_expected
      }
      if (value && this.hardened_state_test.sampling_at && this.hardened_state_test.period_type) {
        const period = +this.hardened_state_test.period
        if (this.hardened_state_test.period_type === 1) {
          this.hardened_state_test.provided_at = this.addDays(this.hardened_state_test.sampling_at, period)
        } else {
          let date = new Date(this.hardened_state_test.sampling_at);
          this.hardened_state_test.provided_at = moment(date).add(period, 'hours').format('YYYY-MM-DD H:mm:ss')
        }
      }
    })
    this.$watch('hardened_state_test.period_type', function (value) {
      if (value && this.hardened_state_test.period && this.hardened_state_test.period_type) {
        const period = +this.hardened_state_test.period
        if (this.hardened_state_test.period_type === 1) {
          this.hardened_state_test.provided_at = this.addDays(this.hardened_state_test.sampling_at, period)
        } else {
          let date = new Date(this.hardened_state_test.sampling_at);
          this.hardened_state_test.provided_at = moment(date).add(period, 'hours').format('YYYY-MM-DD H:mm:ss')
        }
      }
    })
    this.$watch('hardened_state_test.result', function (value) {
      if (!value) {
        return
      }
      const result = parseFloat(value.replace(',', '.')).toFixed(1)
      if (!this.hardened_state_test.max_result_another_hardened_state
        || result > this.hardened_state_test.max_result_another_hardened_state) {
        return this.hardened_state_test.max_result = result
      }
      if (result > parseFloat(this.hardened_state_test.max_result)) {
        this.hardened_state_test.max_result = result
      }
    })
    window.addEventListener('resize', () => {
      this.$forceUpdate()
      this.windowWidth = window.innerWidth
      this.$forceUpdate()
    })
  },
  methods: {
    copyPredictDateToReal(){
      this.hardened_state_test.date_at = this.hardened_state_test.provided_at;
      this.setRealPeriod()
    },
    changeConfirmResult(){
      this.confirmResult = !this.confirmResult;
    },
    setResult() {
      if (! this.hardened_state_test.ton) {
        return
      }
      if (this.showTonne) {
        this.hardened_state_test.result = (Number(this.hardened_state_test.ton.replace(',', '.')) / 0.8).toString()
      }
    },
    showAndSetPercentage(item) {
      const val = parseInt((parseFloat(this.hardened_state_test.max_result) - parseFloat(item.result)) / parseFloat(item.result) * 100)
      item.percentage = val
      return item.percentage
    },
    changeFeature() {
      this.setFeature()
      this.hardened_state_test.item_id = null
      this.changeValueZ()
    },
    /**
     * Busca evolução do ensaio de acordo com o periodo informado
     */
    getProjections: debounce(function () {
      if (!this.hardened_state_test.period || !this.hardened_state_test.period_type
        || !this.hardened_state_test.hardened_state_id) {
        return;
      }
      this.loading_evolution = true
      this.$store.dispatch('travelConfiguration/getProjections',
        {
          period: this.hardened_state_test.period,
          period_type: this.hardened_state_test.period_type,
          mix_feature_id: this.hardened_state_test.feature_id,
          item_id: this.hardened_state_test.item_id,
          schedule_travel_uuid: this.$route.params.travel_uuid,
          hardened_state_test_id: this.hardened_state_test.hardened_state_id,
          id: null
        })
        .then(response => {
          this.loading_evolution = false
          this.hardened_state_test.schedule_period = this.hardened_state_test.period
          this.hardened_state_test.schedule_period_type = this.hardened_state_test.period_type
          this.projections = response.data.statistic
          this.hardened_state_test.max_result = response.data.max_result
          this.hardened_state_test.max_result_another_hardened_state = response.data.max_result_another_hardened_state
          if (!this.hardened_states.max_result) {
            this.hardened_state_test.max_result = this.hardened_state_test.result
          }
        })
    }, 200),
    /**
     * Valida idade da evolução
     */
    periodIsBigger(item) {
      // se referencia for dias e evolucao horas
      if (this.hardened_state_test.period_type === 1 && item.period_type === 2) {
        return false
      }
      // se referencia for horas e evolucao dias
      if (this.hardened_state_test.period_type === 2 && item.period_type === 1) {
        return true
      }
      if (this.hardened_state_test.period > item.period) {
        return false
      }
      return true
    },
    /**
     * Busca diferença entre previsto e real
     */
    diffProvidedAndRealInDays() {
      const date = moment(this.hardened_state_test.provided_at).format('YYYY-MM-DD')
      const date2 = moment(this.hardened_state_test.date_at).format('YYYY-MM-DD')
      return Math.abs(moment(date).diff(moment(date2), 'days'))
    },
    setRealPeriod() {
      this.$nextTick(() => {
        if (this.hardened_state_test.sampling_at && this.hardened_state_test.date_at) {
          const date = moment(this.hardened_state_test.sampling_at).format('YYYY-MM-DD H:mm:ss')
          const date2 = moment(this.hardened_state_test.date_at).format('YYYY-MM-DD H:mm:ss')
          const diff = Math.abs(moment(date).diff(moment(date2), 'hours'))
          if (diff > 24) {
            this.$notify({type: 'warning', message: 'Atenção para diferença de idade.'})
          }
          return this.hardened_state_test.real_period = this.$helper.dateDiff(date, date2)
        }
        return this.hardened_state_test.real_period = null
      })
    },
    handleShowTonne()
    {
      this.showTonne = !this.showTonne
      if (this.showTonne) {
        this.hardened_state_test.result = 0
        this.hardened_state_test.ton = 0
      }
    },
    addDays(date, days) {
      return moment(date).add(days, 'days').format('YYYY-MM-DD H:mm:ss')
    },
    calcPercentage(percentage, index) {
      const val = this.$helper.calcPercentage(percentage, parseFloat(this.hardened_state_test.max_result))
      const val2 = (parseFloat(val) + parseFloat(this.hardened_state_test.max_result))
        .toFixed(1)
        .replace('.', ',')
      this.projections[index].projection = val2
      return val2
    },
    unit() {
      const measuredunit = this.hardened_states.find(item => item.id == this.hardened_state_test.hardened_state_id)
      if (typeof measuredunit === 'object') {
        this.measuredunit = measuredunit.measured_unit.letter
        return true
      }
      return false
    },
    validMolder() {
      if (this.hardened_state_test.molder) {
        return
      }
      this.showTonne = false
      this.hardened_state_test.date_at = null
      this.hardened_state_test.result = null
      this.hardened_state_test.ton = null
      this.hardened_state_test.result_expected = null
      this.hardened_state_test.disruption = null
      this.hardened_state_test.health = null
      this.hardened_state_test.me = null
      this.hardened_state_test.bottom_base = null
      this.hardened_state_test.top_base = null
      this.hardened_state_test.observation = null
    },
    verifyResistance() {
      const item = this.selectedFeature.item.find((item) => item.id === this.hardened_state_test.item_id)
      let item_description = 0
      if (item) {
        item_description = item.description
      }
      const result = this.hardened_state_test.result ? Number(this.hardened_state_test.result.replace(',', '.')) : 0;
      return result > (2 * item_description);
    },
    save() {
      this.loadingSave = true
      this.validMolder()
      let params = {...this.hardened_state_test}
      if (params.date_at) {
        params.date_at = this.$helper.parseDate(params.date_at, 'DD-MM-YYYY H:mm:ss')
      }
      params.provided_at = this.$helper.parseDate(params.provided_at, 'DD-MM-YYYY H:mm:ss')
      params.statistics = this.projections

      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch('travelConfiguration/updateHardenedState', params)
        .then(response => {
            this.loadingSave = false
            this.hardened_state_test = {}
            this.modal = false
            this.$emit('fetch')
            this.$notify({type: 'success', message: response.message});
            this.refreshData();
          }
        ).catch(() => this.loadingSave = false)
    },
    closeModal() {
      this.modal = false;
    },
    unsetReason() {
      if (this.hardened_state_test.molder) {
        this.hardened_state_test.reason = null
        this.hardened_state_test.date_at = new Date()
      }
      else {
        this.hardened_state_test.date_at = null
      }
    },
    setHardenedState(unset = true) {
      if (unset) {
        this.hardened_state_test.feature_id = null
        this.hardened_state_test.item_id = null
      }
      this.selectedFeature = {
        item: []
      }
      if (this.hardened_state_test.hardened_state_id) {
        this.selected = this.hardened_states.find((item) => item.id === this.hardened_state_test.hardened_state_id)
      } else {
        this.hardened_state_test.mold_id = null
        this.selected = {
          molds: [],
          features: [],
        }
      }
      for (var feature of this.selected.features) {
        for (var item of this.features) {
          if (feature.id == item.mix_feature_id && !this.hardened_state_test.feature_id) {
            this.hardened_state_test.feature_id = feature.id
            this.hardened_state_test.item_id = item.id
            this.hardened_state_test.sd = item.value_sd
          }
        }
      }
      this.hardened_state_test.nc = Math.abs(this.hardened_state_test.nc)
      this.setFeature()
      this.changeValueZ()
    },
    changeValueZ() {
      this.$nextTick(() => {
        this.hardened_state_test.z = this.hardened_state_test.nc
      })
    },
    formatPeriodType(period, period_type) {
      switch (period_type) {
        case 1:
          return period > 1 ? `dias` : `dia`
        case 2:
          return period > 1 ? `horas` : `hora`
        default:
          return ''
      }
    },
    setFeature() {
      if (this.hardened_state_test.feature_id) {
        this.selectedFeature = this.selected.features.find((item) => item.id === this.hardened_state_test.feature_id)
      } else {
        this.hardened_state_test.item_id = null
        this.selectedFeature = {
          item: []
        }
      }
    },
    async openModal(item, formulation, show_identification = true, technicalPermissions = null) {
      await this.$store.dispatch('user/getAllWithFilter', {
        filter: {
          is_user: 1
        }
      })
      await this.$store.dispatch('anomaly/fetchItems');
      this.showTonne = false
      this.loadingProjections = true
      this.technicalPermissions = technicalPermissions;
      this.confirmResult = false;
      this.$store.dispatch('travelConfiguration/getProjections', {
        period: item.schedule_period,
        uuid: item.uuid,
        id: item.id,
        mix_feature_id: item.feature_id,
        item_id: item.item_id,
        period_type: item.schedule_period_type,
        schedule_travel_uuid: item.travel_uuid,
        hardened_state_test_id: item.hardened_state_id,
      }).then(response => {
        this.loadingProjections = false
        this.projections = Object.values(response.data.statistic).sort(function (a, b) {
          if (a.period_type === 1 && b.period_type === 2) {
            return 1
          }
          if (a.period_type === 2 && b.period_type === 1) {
            return -1
          }
          if (a.period > b.period) {
            return 1
          }
          if (b.period > a.period) {
            return -1
          }
          return 0
        })
        this.hardened_state_test.max_result = response.data.max_result
        this.hardened_state_test.max_result_another_hardened_state = response.data.max_result_another_hardened_state
      })
      this.$store.dispatch('reliability_level/fetchItems')
      this.tab = 2
      this.windowWidth = window.innerWidth
      this.showIdentification = show_identification
      this.features = formulation.features
      this.selected = {
        molds: [],
        features: [],
      }
      this.selectedFeature = {
        item: []
      }
      this.hardened_state_test = {...item}
      this.hardened_state_test.ton = 0
      this.hardened_state_test.old_period = this.hardened_state_test.schedule_period
      this.hardened_state_test.old_result_expected = this.hardened_state_test.result_expected
      this.hardened_state_test.period = this.hardened_state_test.schedule_period
      this.hardened_state_test.period_type = this.hardened_state_test.schedule_period_type
      if (!this.hardened_state_test.date_at) {
        this.hardened_state_test.date_at = new Date()
      }
      this.hardened_state_test.molder = Boolean(item.molder)
      this.hardened_state_test.puzl_client = Boolean(this.hardened_state_test.puzl_client)
      this.loadingBasePrepation = true
      let loader = this.$loading.show()
      this.$store.dispatch('basePreparation/fetchItems').then(() => {
        this.loadingBasePrepation = false
      })
      this.loadingMoldingLocation = true
      this.$store.dispatch("moldingLocation/fetchItems").then(() => {
        this.loadingMoldingLocation = false
      })
      this.$store.dispatch("testReport/show",
        item.travel_uuid).then(() => {
        this.disableDate = new Date(this.report.finished_at)
        this.modal = true;
      }).catch(() => this.loadingSkeleton = false);
      this.loadingHardenedState = true
      this.$store.dispatch('testHardenedState/fetchItems')
        .then(() => {
          this.loadingHardenedState = false
          this.setRealPeriod()
          this.setHardenedState(false)
          loader.hide()
        })
        .catch(() => {
          this.loadingHardenedState = false
        })
    },
  }
};
</script>

<style>
#input-red .form-control::placeholder {
  color: darkred;
  font-weight: 500;
}
</style>

<style scoped>
.card-with-box-shadow{
  box-shadow: 0 2px 4px 0 #f3f3f3;
  border: 1px solid #E8E8E8;
  box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15);
  border-radius: 10px !important;
}

.border-new-default-gray {
  border: 1px solid #efefef;
  box-shadow: 0px 0px 2px rgba(50, 50, 93, 0.11), 0px 2px 25px rgba(0, 0, 0, 0.08);
  border-left: 0px;
}

.invisible-input {
  border: 0;
  box-shadow: none !important;
}
</style>
