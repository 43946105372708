<template>
  <div>
    <modal :show.sync="modal.create" size="lg">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div>
              <!-- Central -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                  Central
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 mb-1 pr-4">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <puzl-select
                        v-model="water.company_plant_id"
                        :items="plants"
                        @input="getChargePoint(water.company_plant_id)" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Ponto de carga -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                  Ponto de carga
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 mb-1 pr-4">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <puzl-select
                        v-model="water.company_plant_charge_point_id"
                        :items="chargePointItems"
                        :disabled="!charge_point_select || !water.company_plant_id" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-4 pb-0 mb-1 col-form-label form-control-label"
                >
                  Água
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 mb-1 pr-4">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <puzl-select
                        @change="setMaxHumidity()"
                        v-model="water.cmc_adjustment_uuid"
                        :items="filteredWater"
                        customKey="uuid"
                        :labelMask="true"
                        label="product_name$ - $supplier_name$ ($origin_name$)"  />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Corte de Água -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                  Corte de Água
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 mb-1 pr-4">
                  <validation-provider rules="required|min_value:1" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input-limit :start_value="water.correction" :options="{
                                    min: 0,
                                    max: max_humidity,
                                  }" v-model="water.correction" @value="water.correction = $event">
                        <small>%</small>
                      </input-limit>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="modal-footer">
                <base-button type="secondary" @click="closeModal('create')">
                  Cancelar
                </base-button>
                <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                  Salvar
                </base-button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment';
import { checkNumberValue } from '@/helpers'
import { mask1, formatErrorValidation } from '@/plugins'
import PuzlSelect from "@/components/PuzlSelect";
import InputLimit from "../../../../../components/Utils/InputLimit.vue";

export default {
  name: "ModalCreateWater",
  components: { PuzlSelect, InputLimit },
  data() {
    return {
      status: true,
      modal: {
        title: 'Adicionar Corte de Água',
        create: false,
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      charge_point_select: false,
      max_humidity: 100,
      water: {
        company_plant_id: null,
        company_plant_charge_point_id: null,
        date_current: null,
        correction: null,
        hour: null,
        cmc_adjustment_uuid: null,
      },
      loadingStore: false,
      selectedGroups: [],
      chargePointItems: [],
      correctionInput: true,
    }
  },
  computed: {
    ...mapGetters({
      'plants': 'plant/fetch',
      'chargePoints': 'plantChargePoint/fetch',
      cmcAdjustments: "adjustmentCmc/fetch"
    }),
    filteredWater() {
      return this.cmcAdjustments.filter((item) =>
        item.cmc_categories_id === 6 // água
        && this.water.company_plant_id === item.company_plants_id
        && this.water.company_plant_charge_point_id === item.company_plant_charge_points_id)
    },
  },
  methods: {
    setMaxHumidity() {
      const cmc_adjustment = this.cmcAdjustments.find((item) => item.uuid === this.water.cmc_adjustment_uuid)
      if (cmc_adjustment.max_humidity > 0) {
        this.max_humidity = cmc_adjustment.max_humidity
      }
      else {
        this.max_humidity = 100
      }
      this.water.correction = null
    },
    closeModal() {
      this.modal.create = false
    },
    handleCreateModal(params = null) {
      this.$Progress.start()
      let loader = this.$loading.show()
      this.$store.dispatch('plant/fetchItems')
      this.$store.dispatch("adjustmentCmc/getCmcAdjustment");
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      })
      this.correctionInput = true
      this.water = {
        company_plant_id: null,
        company_plant_charge_point_id: null,
        date_current: moment().format('YYYY-MM-DD'),
        correction: null,
        hour: moment().add(1, 'minute').format('H:mm')
      }
      if (params) {
        this.water.company_plant_id = params.company_plant_id
        this.getChargePoint()
        this.water.company_plant_charge_point_id = params.charge_point_id
      }
      this.chargePointItems = []
      this.charge_point_select = false
      this.$store.dispatch('waterSetting/getByUser')
        .then((response) => {
          this.water = {
            company_plant_id: response.data.company_plant.id,
            company_plant_charge_point_id: response.data.company_plant_charge_point.id,
            date_current: moment().format('YYYY-MM-DD'),
            hour: moment().add(1, 'minute').format('H:mm')
          }
          let uuid_charge_point = this.plants.filter(item => item.id == this.water.company_plant_id)[0].uuid
          this.$store.dispatch('plantChargePoint/getByPlant', uuid_charge_point).then((response) => {
            this.charge_point_select = true
            this.chargePointItems = response.data
            this.$Progress.finish()
            loader.hide()
            this.$notify({
              type: response.error_type,
              message: response.message
            })
            this.modal.create = true
            this.$Progress.finish()
          })
        }).catch(error => {
        this.modal.create = true
        this.$Progress.finish()
        loader.hide()
      })
    },
    getChargePoint() {
      if (this.water.company_plant_id) {
        this.charge_point_select = false
        this.correctionInput = false
        this.water.company_plant_charge_point_id = null
        this.water.cmc_adjustment_uuid = null
        let uuid = this.plants.filter(item => item.id == this.water.company_plant_id)[0].uuid
        this.$store.dispatch('plantChargePoint/getByPlant', uuid).then((response) => {
          this.chargePointItems = response.data
          this.correctionInput = true
          this.charge_point_select = true
        })
      } else {
        this.charge_point_select = false
        this.correctionInput = false
        this.water.company_plant_charge_point_id = null
      }
    },
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        value = mask1(checkNumberValue(value, min, max).toString(), decimals, '.', true)
        return checkNumberValue(value, min, max)
      }
      return checkNumberValue(value, min, max)
    },
    store() {
      this.$Progress.start()
      this.loadingStore = true
      this.water.date_time = this.water.date_current + ' ' + this.water.hour + ':00'
      this.$store.dispatch('water/add', this.water)
        .then((response) => {
          this.loadingStore = false
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          })
          this.closeModal();
        })
        .catch((error) => {
          if (error.status === 200) {
            this.$notify({
              type: 'danger',
              message: error.data.message
            })
            this.$Progress.finish();
            this.loadingStore = false
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors)
            this.$notify({
              type: 'danger',
              message: errors
            })
            this.$Progress.finish();
            this.loadingStore = false
          }
        })
    }
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
}
</script>

<style scoped>

</style>
