<template>
  <div>
    <list ref="list"/>
  </div>
</template>

<script>
import List from "./Shared/_List";

export default {
  components: {
    List,
  },
  name: "Index",
  data() {
    return {}
  },
  methods: {}
};
</script>

<style scoped></style>
