<template>
  <div>
    <modal size="md" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title mb-0">{{ modal.title }}</h5>
      </template>
      <div v-if="modal.create">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <div class="row">
            <div class="col-md-12 d-flex">
              <div class="sidebar sidebar-icon-login-rounded-right"></div>
              <span>{{ $_operation_source.name }}<br>{{ tax_regime.join(" e ") }}</span>
            </div>
            <div class="col-md-12 ">
              <hr class="my-2">
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-12 mb-2">
              <div class="border-header-default-bottom p-1">
                <h5 class="h5 m-0">Identificação</h5>
              </div>
              <div class="form-row mt-2 ">
                <label
                  class="col-md-5 pt-2 pb-1 col-form-label form-control-label"
                >
                  CFOP
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required|min:4">
                    <base-input maxlength="4" v-model="operation_nature.cfop" input-group-classes="input-group-sm"/>
                  </validation-provider>
                </div>
              </div>
              <div class="form-row mt-2 ">
                <label
                  class="col-md-5 pt-2 pb-1 col-form-label form-control-label"
                >
                  Finalidade de Emissão
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required">
                    <puzl-select v-model="operation_nature.finNfe"
                                 label="$id$ - $text$"
                                 :labelMask="true"
                                 :clearable="false"
                                 :items="finNfe"/>
                  </validation-provider>
                </div>
              </div>
            </div>
            <div class="col-md-12 mb-3">
              <div class="border-header-default-bottom p-1">
                <h5 class="h5 m-0">Definição de impostos</h5>
              </div>
            </div>
            <div class="col pr-0">
              <base-button @click.prevent="selected = 0" :class="selected === 0 && 'active'"
                           class="button-border-left border-black" outline type="primary" block>
                {{ tax_regime.includes('Simples Nacional') ? 'ICMSSN' : 'ICMS' }}
              </base-button>
            </div>
            <div class="col px-0">
              <base-button @click.prevent="selected = 1" :class="selected === 1 && 'active'"
                           style="border-radius: 0% !important;" type="primary" class="btn-left-none border-black"
                           outline
                           block>IPI
              </base-button>
            </div>
            <div class="col px-0">
              <base-button disabled @click.prevent="selected = 2" :class="selected === 2 && 'active'"
                           style="border-radius: 0% !important;" type="primary" class="btn-left-none border-black"
                           outline
                           block>ISSQN
              </base-button>
            </div>
            <div class="col px-0">
              <base-button @click.prevent="selected = 3" :class="selected === 3 && 'active'"
                           style="border-radius: 0% !important;" type="primary" class="btn-left-none border-black"
                           outline
                           block>PIS
              </base-button>
            </div>
            <div class="col pl-0">
              <base-button @click.prevent="selected = 4" :class="selected === 4 && 'active'"
                           class="button-border-right btn-left-none border-black" outline type="primary" block>COFINS
              </base-button>
            </div>
          </div>
          <section class="mt-4" v-show="selected === 0">
            <!--          caso seja simples nacional, usará CSOSN-->
            <div class="form-row mt-2" v-if="tax_regime.includes('Simples Nacional')">
              <label
                class="col-md-3 pt-2 pb-1 col-form-label form-control-label"
              >
                CSOSN
              </label>
              <div class="col-md-9">
                <validation-provider rules="required">
                  <puzl-select 
                    v-model="csosnModel"
                    label="$id$ - $text$"
                    :labelMask="true"
                    :clearable="false"
                    :items="Object.values($_taxes.csosn)"
                  />
                </validation-provider>
              </div>

            </div>
            <!--          caso seja regime normal, usará CST-->
            <div class="form-row mt-2" v-else>
              <label
                class="col-md-3 pt-2 pb-1 col-form-label form-control-label"
              >
                CST
              </label>
              <div class="col-md-9">
                <validation-provider rules="required">
                  <puzl-select 
                    v-model="cstModel"
                    label="$id$ - $text$"
                    :labelMask="true"
                    :clearable="false"
                    :items="Object.values($_taxes.cst_icms)"
                  />
                </validation-provider>
              </div>
            </div>
          </section>
          <section class="mt-4" v-show="selected === 1">
            <div class="form-row mt-2">
              <label
                class="col-md-3 pt-2 pb-1 col-form-label form-control-label"
              >
                CST
              </label>
              <div class="col-md-9">
                <validation-provider rules="required">
                  <puzl-select v-model="operation_nature.ipi.cst"
                               label="$id$ - $text$"
                               :labelMask="true"
                               :clearable="false"
                               :items="Object.values($_taxes.cst_ipi)"/>
                </validation-provider>
              </div>
            </div>
          </section>
          <section class="mt-4" v-show="selected === 2">
            <div class="form-row mt-2">
              <label
                class="col-md-3 pt-2 pb-1 col-form-label form-control-label"
              >
                CST
              </label>
              <div class="col-md-9">
                  <puzl-select disabled v-model="operation_nature.issqn.cst"
                               label="$id$ - $text$"
                               :labelMask="true"
                               :clearable="false"
                               :items="Object.values($_taxes.cst_issqn)"/>
              </div>
            </div>
          </section>
          <section class="mt-4" v-show="selected === 3">
            <!--          PIS/COFINS utilizam a mesma listagem de cst-->
            <div class="form-row mt-2">
              <label
                class="col-md-3 pt-2 pb-1 col-form-label form-control-label"
              >
                CST
              </label>
              <div class="col-md-9">
                <validation-provider rules="required">
                  <puzl-select v-model="operation_nature.pis.cst"
                               :clearable="false"
                               label="$id$ - $text$"
                               :labelMask="true"
                               :items="Object.values($_taxes.cst_pis)"/>
                </validation-provider>
              </div>
            </div>
          </section>
          <section class="mt-4" v-show="selected === 4">
            <!--          PIS/COFINS utilizam a mesma listagem de cst-->
            <div class="form-row mt-2">
              <label
                class="col-md-3 pt-2 pb-1 col-form-label form-control-label"
              >
                CST
              </label>
              <div class="col-md-9">
                <validation-provider rules="required">
                  <puzl-select v-model="operation_nature.cofins.cst"
                               label="$id$ - $text$"
                               :labelMask="true"
                               :clearable="false"
                               :items="Object.values($_taxes.cst_pis)"/>
                </validation-provider>
              </div>
            </div>
          </section>
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div class="modal-footer">
              <base-button type="secondary" @click.prevent="closeModal()"> Cancelar</base-button>
              <base-button type="success" native-type="submit" :disabled="invalid" :loading="loadingStore"> Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import PuzlSelect from "@/components/PuzlSelect";
import {mapGetters} from "vuex";

const {formatErrorValidation} = require("@/plugins");

const defaultOperationNature = {
  cfop: null,
  finNfe: null,
  operation_source_id: null,
  tax_regime: 0,
  icms: {
    cst: null,
  },
  icmssn: {
    csosn: null
  },
  ipi: {
    cst: null,
  },
  issqn: {
    cst: null
  },
  pis: {
    cst: null
  },
  cofins: {
    cst: null
  },
}
export default {
  name: "ModalOperationNature",
  components: {
    PuzlSelect,
  },
  data() {
    return {
      modal: {
        title: "Natureza da operação",
        create: false,
      },
      operation_nature: {},
      tax_regime: [],
      selected: 0,
      loadingStore: false,
    };
  },
  computed: {
    ...mapGetters({
      $_operation_source: "operationSource/show",
      $_taxes: "nfe/getListTaxes",
    }),
    csosnModel: {
      get() {
        if (!this.operation_nature.icmssn) {
          this.$set(this.operation_nature, 'icmssn', { csosn: null });
        }
        return this.operation_nature.icmssn.csosn;
      },
      set(value) {
        if (!this.operation_nature.icmssn) {
          this.$set(this.operation_nature, 'icmssn', { csosn: value });
        } else {
          this.operation_nature.icmssn.csosn = value;
        }
      }
    },
    cstModel: {
      get() {
        if (!this.operation_nature.icms) {
          this.$set(this.operation_nature, 'icms', { cst: null });
        }
        return this.operation_nature.icms.cst;
      },
      set(value) {
        if (!this.operation_nature.icms) {
          this.$set(this.operation_nature, 'icms', { cst: value });
        } else {
          this.operation_nature.icms.cst = value;
        }
      }
    },
    finNfe() {
      return [
        {
          id: 1,
          text: 'NF-e normal',
        },
        {
          id: 2,
          text: 'NF-e complementar',
        },
        {
          id: 3,
          text: 'NF-e de ajuste',
        },
        {
          id: 4,
          text: 'Devolução de mercadoria',
        },
      ]
    },
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    handleOpenModal(id) {
      this.operation_nature = this.$helper.cloneObject(defaultOperationNature)
      this.selected = 0
      this.$Progress.start();
      let loader = this.$loading.show()
      Promise.all([
        this.$store
          .dispatch("operationSource/show", id),
        this.$store
          .dispatch("nfe/getTaxes"),
      ])
        .then(() => {
          /**
           * Retorna um objeto com os regimes inclusos no tipo de operação
           * @type {any}
           */
          this.tax_regime = JSON.parse(this.$_operation_source.tax_regimes)
          this.operation_nature.operation_source_id = id
          if (this.$_operation_source.operation_nature) {
            this.operation_nature = this.$helper.cloneObject(this.$_operation_source.operation_nature)
          }

          this.modal.create = true
        })
        .finally(() => {
          this.$Progress.finish();
          loader.hide()
        })
    },
    store() {
      let params = this.$helper.cloneObject(this.operation_nature)
      /**
       * Se for simples nacional, use ICMSSN, se não use ICMS
       */
      if (this.tax_regime.includes('Simples Nacional')) {
        params.icms = null
      } else {
        params.icmssn = null
      }
      this.loadingStore = true
      this.$Progress.start();
      this.$store.dispatch('operationSource/postAddOperationNature', params).then(response => {
        this.$notify({
          type: response.error_type,
          message: response.message
        });
        this.modal.create = false
      }).finally(() => {
        this.loadingStore = false
        this.$Progress.finish()
      })
    }
  },
};
</script>

<style scoped>
.sidebar {
  height: 2rem !important;
  width: 3rem;
  margin-top: 0.5rem;
}

.border-black {
  border-color: black !important;
}

@media only screen and (max-width: 412px) {
  .border-black {
    font-size: 10px !important;
    border-color: black !important;
  }
}

.button-border-left {
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.button-border-right {
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.btn-left-none {
  border-left: 0;
}

.btn-left-none:hover {
  transform: none !important;
}
</style>
