<template>
<div>
    <base-header class="bg-gray-content">
        <div class="row align-items-center py-4">
            <div class="col-lg-6 col-7">
                <h6 class="h2 text-gray d-inline-block mb-0">{{ $route.name }}</h6>
                <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4 text-gray">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i></a></li>
                        <li class="breadcrumb-item"><a href="#">Cadastro</a></li>
                        <li class="breadcrumb-item" aria-current="page">{{ $route.name }}</li>
                    </ol>
                </nav>
            </div>
        </div>
        <!-- Header Menus -->
        <div class="row">
            <div class="col-md-2 mb-3">
                <base-button @click.prevent="handleCreateMenu" class="pb-3 pt-3 text-uppercase" block size="lg" type="success">
                  <i class="fas fa-plus"></i> novo
                </base-button>
            </div>
        </div>

        <div class="row align-items-center pb-0">
            <div class="col-md-12">
                <div class="card p-2">
                    <base-input placeholder="Pesquisar">
                        <template slot="append">
                            <i class="fas fa-search"></i>
                        </template>
                    </base-input>
                </div>
            </div>
        </div>
    </base-header>
    <list-menu v-bind:added="this.added"> </list-menu>
    <modal-create @createdMenu="handleCreatedMenuList" ref="ModalCreateMenu"> </modal-create>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'
import ListMenu from './Shared/_List'
import ModalCreate from './Shared/_ModalCreate'

export default {
    name: "IndexMenu",
    components: {
        ListMenu,
        ModalCreate,
    },
    data() {
        return {
            added: false,
        }
    },
    methods: {
        handleCreateMenu() {
            this.$refs.ModalCreateMenu.handleCreateModal()
        },
        handleCreatedMenuList() {
            this.added = !this.added
        },
    },
};
</script>

<style scoped>

</style>
