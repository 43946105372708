<template>
  <div>
    <modal :borderInfo="'2px solid #1b6eba'" :show.sync="modal" size="lg">
      <template slot="header">
        <div style="display: flex;align-items: center;">
          <img style="display: inline-flex;" src="/img/icons/icons8/ios/exchange-dollar.png" width="22">
          <h5 style="display: inline-flex;" class="modal-title p-0 m-0 ml-3 text-white">{{ title }}</h5>
        </div>
      </template>
      <FinanceTypeForm :financeType="financeType" @close="closeModal" />
    </modal>
  </div>
</template>

<script>
import FinanceTypeForm from "./Forms/_FinanceTypeForm";

export default {
  components: {
    FinanceTypeForm
  },
  name: "ModalCreateFinanceType",
  props: {},
  data() {
    return {
      modal: false,
      title: 'Tipo de operação financeira',
      financeType: {},
    }
  },
  methods: {
    closeModal() {
      this.modal = false
    },
    openModal(){
      this.financeType.mode = 1;
      this.financeType.is_active = true;
      this.financeType.management_chart_account_id = null;
      this.financeType.name = "";
      this.financeType.reference_key = null;
      this.financeType.created_by_user_name = null;
      this.financeType.updated_by_user_name = null;

      this.modal = true;
    },
  },
};
</script>

<style></style>
