<template>
  <div>
    <modal :show.sync="modal" size="md">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div class="p-3">
        <!--  <div class="timeline timeline-one-side mb-4" > -->
        <div class="timeline timeline-one-side pb-2" v-for="(item, index) in $_items" :key="index">
          <div class="timeline-block">
            <span class="timeline-step badge-success"> {{index + 1}} </span>
            <div class="timeline-content">
              <div class="row m-0 p-0">
                <div class="col-md-8 pl-0 pr-0 ">
                  <div class="border-left border-primary border-3 pl-2">
                    <h5 class="mb-0 pl-2">{{`${item.product_name} (${item.category_name || item.type_name})`}}</h5>
                    <h5 class="mb-0 font-weight-normal pl-2">{{getItemType(item.type)}}</h5>
                    <h5 v-if="item.ncm" class="mb-0 font-weight-normal pl-2 mt-2">NCM: {{item.ncm}}</h5>
                  </div>
                </div>
                <div class="col-md-4 text-right">
                  <h5 class="mb-0 pl-2">{{item.total_value | currency}}</h5>
                  <h5 class="mb-0 font-weight-normal pl-2 mt-2">{{`${item.quantity.replace(".", ",")} ${item.purchase_unit}`}}</h5>
                  <h5 class="mb-0 font-weight-normal pl-2">{{item.price | currency}} / {{item.purchase_unit}}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center m-3 pt-3">
          <base-button type="secondary" @click="closeModal()">Fechar </base-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  name: "ModalListItems",
  data() {
    return {
      modal: false,
      title: "Itens",
    };
  },
  computed: {
    ...mapGetters({
      $_items: "entryLaunchItem/fetch",
    }),
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    openModal(entry_launch_id) {
      this.$Progress.start();
      this.$store.dispatch("entryLaunchItem/getByEntryLaunch", entry_launch_id).then((response) => {
        this.$Progress.finish();
        this.modal = true;
      });
    },
    getItemType(type) {
      switch (type) {
        case 0:
          return "Produtos e Serviços";
        case 1:
          return "Serviço";
        case 2:
          return "MCC";
      }
    },
  },
};
</script>

<style></style>
