<template>
  <div>
    <modal :borderInfo="'2px solid #1b6eba'" :show.sync="modal" size="lg">
      <template slot="header">
        <div style="display: flex;align-items: center;">
          <img style="display: inline-flex;" src="/img/icons/icons8/ios/new-store.png" width="22">
          <h5 style="display: inline-flex;" class="modal-title p-0 m-0 ml-3 text-white">{{ title }}</h5>
        </div>          
      </template>
      <div>
        <div class="container-fluid">
          <div class="row mt-2 mb-3">
            <div class="col-12" v-if="loading === false || item.prices">
              <div class="card main-card mb-3"
                style="border-radius: 20px !important"
                v-for="(price, priceIndex) in item.prices" :key="priceIndex"> 
                <div class="p-3 px-4">
                  <div class="row">
                    <div class="col-10">
                      <h6 class="new-default-gray-font p-0 m-0 mb-2">
                        {{ price.validity_start }}
                      </h6>
                      <h4 class="new-default-black-font m-0 p-0 font-weight-normal">
                        {{ price.entity_name }}
                      </h4>
                    </div>
                    <div class="col-2 text-right">
                      <base-dropdown menuOnRight>
                        <div slot="title-container" class="dropdown-toggle rounded m-0">
                          <img width="32" src="/img/icons/icons8/ios/settings--v1_primary.png" />
                        </div>
                        <a 
                          @click.prevent="$emit('handleEditPrice', item, price)"
                          class="dropdown-item text-dark"
                          style="align-items: center; display: flex"
                        >
                          <img width="18" src="/img/icons/edit.png" />
                          EDITAR
                        </a>
                        <div class="dropdown-divider p-0 m-0"></div>
                        <a
                          @click.prevent="$emit('handleHistoryPrice', price)"
                          class="dropdown-item text-dark"
                          style="align-items: center; display: flex"
                        >
                          <img width="18" src="/img/icons/icons8/ios/order-history_secondary.png" />
                          Histórico
                        </a>
                        <div class="dropdown-divider p-0 m-0"></div>
                        <a 
                          @click.prevent="$emit('deleteProductServiceHubPrice', price)"
                          class="dropdown-item text-dark"
                          style="align-items: center; display: flex"
                        >
                          <img width="18" src="/img/icons/delete.png" />
                          EXCLUIR
                        </a>
                      </base-dropdown>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-12">
                      <h4 class="new-default-black-font m-0 font-weight-normal">
                        {{ price.document }}
                      </h4>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <h3 class="new-default-black-font p-0 m-0 mt-3">
                        {{price.price | currency()}} <span v-if="price.purchase_unit !== 'N/A'"> / {{price.purchase_unit}} </span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="item.prices && !item.prices.length ">
              <h5 class="m-0">Não há preços cadastrados.</h5>
            </div>         
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {VMoney} from "v-money";

export default {
  components: {},
  name: "ModalListPrices",
  data() {
    return {
      modal: false,
      title: 'Fornecedores',
      validated: false,
      loading: true,
      item: {},
    }
  },
  computed: {
    ...mapGetters({
        items: 'productService/fetch',
    }),
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    openModal(item){
      this.item = item;
      this.getPrices(item);
      this.modal = true;
    },
    verifyUpdate(){
      if(this.modal) this.getPricesByProductServiceHub(this.item.id);
    },
    getPrices(product_service_hub) {
      if(!product_service_hub.prices) {
        this.getPricesByProductServiceHub(product_service_hub.id);
      }
    },
    getPricesByProductServiceHub(product_service_hub_id) {
        this.loading = true;
        return this.$store.dispatch('productService/getPricesByProductServiceHub', product_service_hub_id)
          .then((response) => {
            this.loading = false;
        });
    },
  },
};
</script>

<style></style>
