<template>
<div>
    <base-header class="bg-gray-content">
        <div class="row align-items-center py-4">
            <div class="col-lg-6 col-7">
                <h6 class="h2 text-gray d-inline-block mb-0">{{ $route.name }}</h6>
                <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4 text-gray">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i></a></li>
                        <li class="breadcrumb-item"><a href="#">Tecnologia</a></li>
                        <li class="breadcrumb-item" aria-current="page">{{ $route.name }}</li>
                    </ol>
                </nav>
            </div>
        </div>
        <!-- Header Menus -->
        <div class="row">
            <div class="col-md-2 mb-3">
                <base-button class="pb-3 pt-3 text-uppercase" block size="lg" type="success" @click.prevent="handleCreateAdjustment">
                  <i class="fas fa-plus"></i> novo
                </base-button>
            </div>
            <div class="col-md-10">
                <div class="row">
                    <div class="col-md-3 col-lg-2 col-xlg-2 ml-3 mr-3 mb-3 ml-0 p-0">
                        <base-button class="pb-3 pt-3 text-uppercase" block size="lg" type="primary" @click.prevent="handleCreateLimitAdjustment">
                            <i class="fas fa-arrows-alt-v"></i> limite
                        </base-button>
                    </div>
                    <base-dropdown class="col-md-3 col-lg-2 col-xlg-2 ml-3 mr-3 mb-3 ml-0 p-0">
                        <base-button slot="title-container" block type="dark" class="dropdown-toggle p-3 text-uppercase">
                          <i class="fas fa-external-link-alt"></i> cadastros
                        </base-button>
                        <div>
                            <router-link :to="{ path:'/technology/adjustment/reuse/left-over'}">
                                <a class="dropdown-item">Motivos de sobra/descarte</a>
                                <div class="dropdown-divider p-0 m-0"></div>
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ path:'/technology/adjustment/reuse/stabilizing-additive'}">
                                <a class="dropdown-item">Aditivos estabilizadores</a>
                            </router-link>
                        </div>
                    </base-dropdown>
                    <div class="col-md-3 col-lg-2 col-xlg-2 ml-3 mr-3 mb-3 ml-0 p-0">
                        <router-link :to="{ path:'/technology/adjustments'}">
                            <base-button class="pb-3 pt-3 text-uppercase" block size="lg" type="light">
                                <i class="fas fa-undo-alt"></i> voltar
                            </base-button>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </base-header>

    <list-adjustment-charge-point v-bind:added="this.added"></list-adjustment-charge-point>
    <modal-create-adjustment @createdReuseAdjustments="handleCreateAdjustmentList" ref="ModalCreateAdjustment"></modal-create-adjustment>
    <modal-limit-setting @createdReuseLimitSetting="handleCreateAdjustmentList" ref="ModalLimitSetting"></modal-limit-setting>
</div>
</template>

<script>
import ListAdjustmentChargePoint from './Shared/_List'
import ModalCreateAdjustment from './Shared/_ModalCreateAdjustment'
import ModalLimitSetting from './Shared/_ModalLimitSetting'

export default {
    name: "IndexAdjustmentChargePoint",
    components: {
        ListAdjustmentChargePoint,
        ModalLimitSetting,
        ModalCreateAdjustment
    },
    data() {
        return {
            added: false,
        }
    },
    methods: {
        handleCreateAdjustment() {
            this.$refs.ModalCreateAdjustment.handleCreateModal()
        },
        handleCreateLimitAdjustment() {
            this.$refs.ModalLimitSetting.handleCreateModal()
        },
        handleCreateAdjustmentList() {
            this.added = !this.added
        }
    }
};
</script>

<style></style>
