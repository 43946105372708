<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body pb-2">
        <div class="form-group row m-0 custom-dashboard">
          <div class="col-md-4 p-1">
            <InputDatePicker :filterable="4" ref="period" :defaultRange="defaultRange" @handleFilterDate="handleFilterDate"/>
          </div>
          <!-- Central -->
          <div class="col-md-3 p-1 select-lg">
            <div>
              <el-select
                collapse-tags
                @change="handleFilter"
                :loading="loadingPlant"
                v-model="filter.company_plants_uuid"
                placeholder="Centrais"
                filterable
                multiple
              >
                <el-option v-for="plant in plants" :key="plant.id" :label="plant.name" :value="plant.uuid"></el-option>
              </el-select>
            </div>
          </div>

          <!-- Cliente/Obra -->
          <div class="col-md-3 p-1 select-lg">
            <el-select
              collapse-tags
              @change="handleFilter(false)"
              :loading="loadingCustomerConstruction"
              v-model="filter.constructions"
              placeholder="Cliente / Obra"
              filterable
              multiple
            >
              <el-option
                v-for="customerConstruction in customerConstructions"
                :key="customerConstruction.id"
                :label="customerConstruction.name"
                :value="customerConstruction.id"
              >
              </el-option>
            </el-select>
          </div>

          <!-- Obra -->
          <div class="col-md-2 p-1">
            <base-button
              title="Limpar filtros"
              @click.prevent="clearFilter"
              block
              type="secondary"
            >
              <i class="fas fa-1x fa-eraser"></i>
            </base-button>
          </div>
          <div class="col-md-9 p-1"></div>
          <div class="col-md-3 p-1 text-right">
            <base-button
              @click="config.dark_mode = !config.dark_mode"
              type="default"
              >
              <span class="btn-label"><i :class="config.dark_mode ? 'fa-2x fas fa-moon' : 'fa-2x far fa-moon'"></i></span>
              Modo escuro
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputDatePicker from "@/components/InputDatePicker";
import {mapGetters} from "vuex";

export default {
  name: "DashboardFilter",

  data() {
    return {
      filter: {
        period: {
          start: null,
          end: null,
        },
        company_plants_uuid: [],
        constructions: [],
      },
      loadingPlant: true,
      loadingCustomerConstruction: true,
      config: {
        dark_mode: false,
      },
      defaultRange: {
        start: new Date(),
        end: new Date(),
      },
    };
  },
  components: {
    InputDatePicker,
  },
  watch: {
    "config.dark_mode"() {
      this.$emit("changeTheme");
    },
  },
  computed: {
    ...mapGetters({
      plants: "plant/activeItems",
      services: "service/activeItems",
      customerConstructions: "customerConstruction/fetch",
    }),
  },
  methods: {
    handleFilterDate(filter) {
      this.filter.period = filter;
      this.handleFilter();
    },
    handleFilter(customerConstruction = true) {
      if (!this.filter.period.start) {
        this.filter.period = this.defaultRange;
      }
      if (customerConstruction) {
        this.getCustomerConstructions();
      }
      this.$emit("fetch", this.filter);
    },
    clearFilter() {
      this.filter = {
        period: {
          start: null,
          end: null,
        },
        company_plants_uuid: [],
        constructions: [],
      };
      this.$refs.period.resetDate();
      this.handleFilter();
    },
    getCustomerConstructions() {
      this.loadingCustomerConstruction = true;
      if (!this.filter.period.start) {
        this.filter.period = this.defaultRange;
      }
      this.$store.dispatch("customerConstruction/getByPeriodAndCompanyPlant", {filters: this.filter}).then(() => {
        this.loadingCustomerConstruction = false;
      });
    },
  },
  mounted() {
    this.$store.dispatch("plant/fetchItemsActive").then(() => {
      this.loadingPlant = false;
    });
    this.getCustomerConstructions();
  },
};
</script>

<style>
.col-date-picker {
  max-height: 29px !important;
}

.label-config-control {
  padding-top: 0.2rem !important;
}
</style>
