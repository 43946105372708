import { adjustmentTypeUrls } from ".";
import httpClient from "../../../../shared/libraries/httpClient";
import { handleUnexpectedStatusCode } from "../../../../shared/services";
import { HttpStatusEnum } from "../../../../shared/enums";
import { AdjustmentTypeViewType } from "../types";
import { adjustmentTypeFormatEmptyAgentService } from "./";

/**
 * Localizar por ID
 *
 * @param {number|string} id
 * @returns {Promise<?AdjustmentTypeViewType>}
 */
const execute = async (id) => {
  const url = `${adjustmentTypeUrls.BASE}/${id}`;
  const res = await httpClient.get(url);

  switch (res.status) {
    case HttpStatusEnum.HTTP_OK:

      if(res.data.data.agent === null) {
        res.data.data.agent = adjustmentTypeFormatEmptyAgentService.execute();
      }

      return res.data.data;
    case HttpStatusEnum.HTTP_NOT_ACCEPTABLE:
      return null;
    default:
      handleUnexpectedStatusCode(res);
  }
};

export default { execute };
