<template>
<div>
  <modal :show.sync="modal.open">
    <template slot="header">
      <h5 class="modal-title">{{ modal.title }}</h5>
    </template>
    <div>
      <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
        <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
          <div>
            <div class="form-group row m-0 p-0">
              <label class="col-md-5 col-form-label form-control-label">
                Status
              </label>
              <div class="col-md-7 pt-2">
                <base-switch v-model="subtype.status" type="success" offText="inativo" onText="ativo" class="success"></base-switch>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                Descrição Técnica
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      v-model="subtype.technical_description"
                      class="form-control form-control-sm"
                      :class="errors[0] ? 'is-invalid' : 'is-valid'"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                Descrição Comercial
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      v-model="subtype.commercial_description"
                      class="form-control form-control-sm"
                      :class="errors[0] ? 'is-invalid' : 'is-valid'"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pb-1 col-form-label form-control-label">
                Tipo
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                        :clearable="false"
                        v-model="subtype.type_ids"
                        :items="[{ id: 'all', technical_description: 'Todos' }, ...types]"
                        @input="setAll"
                        :multiple="true"
                        label="technical_description"
                        :disabled="loadingTypes"
                        :loading="loadingTypes" />
                  </base-input>
                </validation-provider>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <base-button type="secondary" @click="closeModal()">
              Cancelar
            </base-button>
            <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingSave">
              Salvar
            </base-button>
          </div>
        </form>
      </validation-observer>
    </div>
  </modal>
</div>
</template>

<script>
import {mapGetters} from 'vuex'
import PuzlSelect from "@/components/PuzlSelect"

export default {
  name: "Create",
  components: { PuzlSelect },
  data () {
    return {
      modal: {
        title: 'Subtipo',
        open: false,
      },
      subtype: {
        status: true,
        technical_description: null,
        commercial_description: null,
        type_ids: [],
      },
      loadingSave: false,
      loadingTypes: true,
    }
  },
  computed: {
    ...mapGetters({
      'types': 'mixType/fetch',
    }),
  },
  methods: {
    closeModal () {
      this.modal.open = false
    },
    handleCreateModal () {
      this.modal.open = true
      this.subtype = {
        status: true,
        technical_description: null,
        commercial_description: null,
        type_ids: [],
      }
    },
    setAll() {
      if (this.subtype.type_ids.includes('all')) {
        this.subtype.type_ids = []
        for(let type of this.types) {
          this.subtype.type_ids.push(type.id)
        }
      }
    },
    save () {
      this.$Progress.start()
      this.loadingSave = true
      this.$store.dispatch('mixSubtype/add', this.subtype)
        .then(response => {
          this.loadingSave = false
          this.modal.open = false
          this.subtype = {
            status: true,
            technical_description: null,
            commercial_description: null,
            type_ids: [],
          }
          this.$Progress.finish()
          this.$notify({ type: response.error_type, message: response.message })
        })
        .catch(error =>{
          if(error.response.status===422){
            let errors = Object.values(error.response.data.errors)
            errors = errors.flat().toString().split(',').join("<br />")
            this.$notify({ type: 'danger', message: errors })
          }
          this.$Progress.finish()
          this.loadingSave = false
        })
    },
  },
  mounted () {
    this.$refs.formValidator.validate();
    this.$store.dispatch('mixType/fetchItems').then(() => {
      this.loadingTypes = false;
    })
  },
  created () {
  }
}
</script>

<style scoped>

</style>
