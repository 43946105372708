<template>
  <div>
    <modal :show.sync="modal" size="lg">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div class="p-3">
        <form
          class="needs-validation"
          @submit.prevent="handleSubmit(save)"
          autocomplete="off"
        >
          <PuzlEmptyData v-if="!services.length"></PuzlEmptyData>

          <div
            v-if="services.length"
            class="border-left border-3 border-primary p-2 mt-2 d-flex justify-content-between"
            v-for="(service, indexService) in services"
            :key="indexService"
          >
            <div class="col-11">
              <h5 class="h3 mb-1">
                {{ service.service.name }}
              </h5>
              <h5 class="h3 mb-1 small">
                {{ service.service.description }}
              </h5>
            </div>

            <div class="col-1 d-flex justify-content-center align-items-center">
              <base-button
                @click.prevent="changeState(indexService)"
                block
                size="sm"
                :type="!service.selected ? 'white' : 'success'"
              >
                <i
                  class="fa fa-check"
                  :class="!service.selected ? 'text-primary' : 'text-white'"
                ></i>
              </base-button>
            </div>
          </div>

          <div class="d-flex justify-content-center m-3">
            <div class="pr-3">
              <base-button type="secondary" @click="closeModal()"
                >Cancelar</base-button
              >
            </div>
          </div>
        </form>
      </div>
    </modal>
    <ModalScheduleAddService @addedService="addedService" ref="modalAddService" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import ModalScheduleAddService from "../Service/_Create.vue";

export default {
  components: {
    PuzlEmptyData,
    ModalScheduleAddService
  },
  computed: {
    ...mapGetters({
      services: "contractService/fetch"
    })
  },
  name: "ModalScheduleListService",
  data() {
    return {
      modal: false,
      title: "Incluir Serviço",
      schedule_uuid: null,
      plants:[],
      params:{}
    };
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    openModal(contract_uuid, schedule_uuid, company_plants, params) {
      this.$Progress.start();
      this.$store
        .dispatch("contractService/fetchItems", {uuid : contract_uuid, filter:{ status: 1}})
        .then(response => {
          this.services.map(function(obj, key) {
            obj.selected = false;
            obj.volume = null;
            obj.start_at = null;
            obj.end_at = null;
            obj.time = null;
          });
          this.schedule_uuid = schedule_uuid;
          this.plants = company_plants;
          this.params = params;
          this.$Progress.finish();
          this.modal = true;
          this.$forceUpdate();
        });
    },
    addedService(params) {
      this.$emit('addedService', params)
    },
    save() {
      let current_services = [];
      this.services.map(function(obj, key) {
        if (obj.selected === true) {
          current_services.push(obj);
        }
      });
      this.$refs.modalAddService.handleCreate(current_services[0], this.schedule_uuid, this.plants, this.params);
      this.modal = false;
    },
    changeState(index) {
      this.services[index].selected = !this.services[index].selected;
      this.$forceUpdate();
      this.save();
    }
  },
  created() {}
};
</script>

<style></style>
