<template>
  <div id="app" >
    <router-view id="router-view"></router-view>
    <loading ref="loading"/>
    <vue-snotify style="text-transform: none"></vue-snotify>
    <vue-progress-bar/>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import "@/style/main.css";
import * as Websocket from '@/helpers/ws';

export default {
  components: {
    Loading,
  },
  beforeDestroy() {
    WebSocket.disconnect()
  },
  created() {
    document.addEventListener('keyup', function (evt) {
      if (evt.code === 'Escape') {
        window.EventBus.$emit('closeWindow')
      }
    });
    if (this.$store.state && this.$store.state.auth && this.$store.state.auth.user) {
      this.$user = this.$store.state.auth.user
      Websocket.connect(this.$user.id)
    }
    this.$Progress.start()
    this.$router.beforeEach((to, from, next) => {
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress
        this.$Progress.parseMeta(meta)
      }
      this.$Progress.start()
      next()
    })
    this.$router.afterEach((to, from) => {
      this.$Progress.finish()
    })
  },
}
</script>
<style>
</style>
