<template>
  <div>
    <modal :show.sync="modal" size="lg">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div class="p-3">
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(save)"
            autocomplete="off"
          >
            <div>
              <div class="row align-items-center pb-0 mt-3">
                <div class="col-md-12">
                  <div class="card">
                    <base-input placeholder="Pesquisar">
                      <template slot="append">
                        <i class="fas fa-search"></i>
                      </template>
                    </base-input>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-for="(mix_design, indexMixDesign) in mix_designs"
              :key="indexMixDesign"
            >
              <div class="p-2 mt-3 row">
                <div class="col-md-11 pl-2">
                  <div class="border-left border-3 border-primary p-2">
                    <div
                      class="row"
                    >
                      <div class="col-md-12">
                        <h5 class="h mb-0">
                        {{mix_design.title}}
                        </h5>
                      </div>
                    </div>
                    <div class="">
                      <span class="p small">
                        {{mix_design.body}}
                      </span>
                    </div>
                    <div class="mt-n1">
                      <span class="p small">
                        {{mix_design.footer}}
                      </span>
                    </div>
                  </div>
                  <div class=" p-2 row mt-2" v-if="mix_design.puzl_additions.length">
                    <div class="col-md-12 pl-2">
                      <div class="border-left border-3 border-indigo p-2">
                        <div
                          v-for="(
                            puzl_addition, index
                          ) in mix_design.puzl_additions"
                          :key="puzl_addition.id"
                        >
                          <h5 class="text-indigo mb-0">
                            {{ puzl_addition.name }}
                            ({{ puzl_addition.volume }} kg/m<sup>3</sup>)
                            <span
                              class="text-indigo"
                              v-if="
                                index != mix_design.puzl_additions.length - 1
                              "
                              >+</span
                            >
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="show-md col-md-1 col-sm-12
                  "
                >
                  <base-button
                    size="sm"
                    type="primary"
                    outline
                    @click="setMixDesign(mix_design)"
                  >
                    <i class="fa-regular fa-circle-check"></i>
                  </base-button>
                </div>
                <div
                  class="show-mobile col-md-1 col-sm-12
                  "
                >
                  <base-button
                    size="sm"
                    type="primary"
                    outline
                    block
                    @click="setMixDesign(mix_design)"
                  >
                    <i class="fa-regular fa-circle-check"></i>
                  </base-button>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-center m-3">
              <!--            <div class="modal-footer">-->
              <base-button type="secondary" @click="closeModal()"
                >Cancelar</base-button
              >
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { period_types } from "@/views/Modules/Technology/Mix/helpers/mixe";

export default {
  components: {},
  name: "ModalAddMixe",
  data() {
    return {
      format_mixe: period_types,
      modal: false,
      title: "Incluir traço",
      validated: false,
      loadingPlant: true,
      loadingSave: false,
      invalid: false,
      mixe: {
        plant_uuid: null
      },
      mix_designs: []
    };
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    openModal(contract_uuid, piece_id) {
      this.$Progress.start();
      this.$store
        .dispatch("formulations/getByPiece", {
          uuid: contract_uuid,
          piece_id: piece_id
        })
        .then(response => {
          this.mix_designs = response.data;
          this.mix_designs.map(function(obj){
            obj.puzl_additions = JSON.parse(obj.puzl_additions)
          })
          this.$Progress.finish();
          this.modal = true;
        });
    },
    setMixDesign(mix_design) {
      this.$notify({
        type: "success",
        message: "Traço selecionado com sucesso!"
      });
      this.$emit("setMixDesign", mix_design);
      this.modal = false;
    },
    save() {}
  }
};
</script>

<style></style>
