<template>
  <div>
    <modal @close="close" size="sm" :show.sync="show">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">{{ title }}</h5>
      </template>
      <validation-observer
        v-slot="{ invalid, handleSubmit }"
        ref="formValidator"
      >
      <div class="row" v-if="show">

        <div class="col-md-12">
          <label class="form-control-label">Novo vencimento</label>
          <validation-provider rules="required">
          <input-date-time-picker v-if="$user.financial_limit.disable_max_extension_due_date"
                                  :disableMaxDate="max_date"
                                  :disable-date="min_date"
                                  v-model="params.due_date" position="bottom" :mode="'date'"></input-date-time-picker>
          <input-date-time-picker v-else
                                  v-model="params.due_date" position="bottom" :mode="'date'"></input-date-time-picker>
          </validation-provider>
        </div>
        <div class="col-md-12">
          <label class="form-control-label">Observações</label>
          <textarea v-model="params.observation" class="form-control"></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <base-button type="secondary" @click="close()">
          Cancelar
        </base-button>
        <base-button
          :disabled="invalid"
          @click.prevent="save()"
          type="success"
          native-type="submit"
          :loading="isLoading"
        >
          Salvar
        </base-button>
      </div>
      </validation-observer>
    </modal>
  </div>
</template>

<script>
import moment from "moment";
import InputDateTimePicker from "@/components/InputDateTimePicker";

export default {
  name: "ModalChangeDueDate",
  components: {
    InputDateTimePicker
  },
  data() {
    return {
      title: 'Alteração de vencimento',
      show: false,
      isLoading: false,
      max_date: null,
      min_date: null,
      additional_params: {},
      params: {
        due_date: null,
        observation: null
      }
    }
  },
  methods: {
    close() {
      this.show = false
    },
    handleCreateModal(installment_id, due_date, params = {})
    {
      this.params = {
        due_date: null,
        observation: null,
        installment_id: installment_id
      }
      this.additional_params = params
      this.max_date = new Date(this.$helper.parseDate(moment().add(this.$user.financial_limit.general_extension_due_date, 'd'),'YYYY-MM-DD 00:00:00'));
      this.min_date = new Date(this.$helper.parseDate(moment().subtract(this.$user.financial_limit.general_extension_due_date, 'd'),'YYYY-MM-DD 00:00:00'));
      this.show = true
    },
    save() {
      this.$Progress.start();
      this.isLoading = true
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.$store
        .dispatch("billReceive/postChangeDueDate", this.params)
        .then(response => {
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.isLoading = false
          this.close()
          this.additional_params.due_date = this.params.due_date
          this.$emit('updated', this.additional_params)
        })
        .catch(error => {
          this.$notify({
            type: error.data.error_type,
            message: error.data.message
          });
          this.isLoading = false
          this.$Progress.finish();
        });
    }
  },
};
</script>

<style>
</style>
