var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"mx-1"},[_c('el-popover',{staticClass:"ml-0",attrs:{"placement":"left","trigger":"click"}},[_c('div',{staticStyle:{"min-width":"250px"}},[_c('span',[_c('span',{staticClass:"d-flex justify-content-center",staticStyle:{"font-weight":"500","color":"#2B2D32","font-size":"13px"}},[_vm._v(" AJUSTES DE TEOR ")]),_c('hr',{staticClass:"hr-popover-content-adjustment"}),_c('div',{staticClass:"w-100 justify-content-around d-flex mt-3"},[_c('span',{staticClass:"popover-content-font"},[_vm._v("Teor do MCC")]),_c('hr',{staticClass:"flex-grow-1 mx-3 hr-divider-popover"}),_c('span',{staticClass:"popover-content-font font-weight-normal",class:_setup.props.adjustment.content < 0
                ? 'danger-color'
                : _setup.props.adjustment.content > 0
                ? 'success-color'
                : 'font-dark'},[_vm._v(" "+_vm._s((_setup.props.adjustment.content || 0) + ' %')+" ")])]),_c('div',{staticClass:"w-100 justify-content-around d-flex mt-2"},[_c('span',{staticClass:"popover-content-font"},[_vm._v("Limite Mínimo")]),_c('hr',{staticClass:"flex-grow-1 mx-3 hr-divider-popover"}),_c('span',{staticClass:"popover-content-font font-weight-normal",class:_setup.props.adjustment.content_min < 0
                ? 'danger-color'
                : _setup.props.adjustment.content_min > 0
                ? 'success-color'
                : 'font-dark'},[_vm._v(" "+_vm._s((_setup.props.adjustment.content_min || 0) + ' %')+" ")])]),_c('div',{staticClass:"w-100 justify-content-around d-flex mt-2"},[_c('span',{staticClass:"popover-content-font"},[_vm._v("Limite Máximo")]),_c('hr',{staticClass:"flex-grow-1 mx-3 hr-divider-popover"}),_c('span',{staticClass:"popover-content-font font-weight-normal",class:_setup.props.adjustment.content_max < 0
                ? 'danger-color'
                : _setup.props.adjustment.content_max > 0
                ? 'success-color'
                : 'font-dark'},[_vm._v(" "+_vm._s((_setup.props.adjustment.content_max || 0) + ' %')+" ")])]),(_setup.props.adjustment.observation_content !== null)?_c('hr',{staticClass:"hr-popover-content-adjustment mt-4"}):_vm._e(),(_setup.props.adjustment.observation_content !== null)?_c('div',[_c('span',{staticClass:"d-flex justify-content-center mb-2",staticStyle:{"font-weight":"500","color":"#2B2D32","font-size":"13px"}},[_vm._v(" OBSERVAÇÃO ")]),_c('span',{staticClass:"popover-content-font"},[_vm._v(" "+_vm._s(_setup.props.adjustment.observation_content)+" ")])]):_vm._e()])]),_c('base-button',{staticClass:"p-0 m-0 shadow-none",attrs:{"slot":"reference","outiline":"","size":"sm","type":"secundary"},slot:"reference"},[_c('img',{attrs:{"src":"/img/brand/logo.png","alt":"logo","width":"22px"}})])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }