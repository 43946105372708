<template>
  <span ref="trigger"></span>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, defineProps, defineEmits } from "vue";

/**
 * Propriedades para o componente
 * @typedef {Object} Props
 * @property {Object} options - IntersectionObserverInit
 * @property {?HTMLElement} options.root - O elemento que é usado como viewport para verificar a visibilidade do alvo.
 * @property {String} options.threshold - 0 significa que um pixel está visível e 1 que todo o elemento está visível.
 * @property {Number} delayOnMounted - Atraso na montagem do componente [milesegundos]. Padrão = 1000ms.
 * @property {Number} delayOnIntersect - Atraso no evento de interseção. Padrão = 0ms.
 */
/** @type {Props} */
const props = defineProps({
  options: {
    type: Object,
    default: () => ({
      root: null,
      threshold: "0",
    }),
    required: false,
  },
  delayOnMounted: {
    type: Number,
    default: () => 1000,
    required: false,
  },
  delayOnIntersect: {
    type: Number,
    default: () => 0,
    required: false,
  }
});

/**
 * Define a função de emissão para emitir eventos.
 *
 * @emits onIntersected - Emitido quando a interseção é observada.
 */
const emit = defineEmits(["onIntersected"]);

/** @type {import('vue').Ref<?HTMLElement>} */
const trigger = ref(null);

/** @type {import('vue').Ref<?IntersectionObserver>} */
const observer = ref(null);

/**
 * Lida com o evento de interseção.
 *
 * @param {IntersectionObserverEntry} entry - A entrada do elemento observado.
 */
const handleIntersect = async (entry) => {
  if (entry.isIntersecting) {
    await new Promise(resolve => setTimeout(resolve, props.delayOnIntersect));
    emit("onIntersected");
  }
};

/**
 * Inicializa o IntersectionObserver quando o componente é montado.
 */
onMounted(async () => {
  await new Promise(resolve => setTimeout(resolve, props.delayOnMounted));
  observer.value = new IntersectionObserver((entries) => {
    handleIntersect(entries[0]);
  }, props.options);

  if (trigger.value) {
    observer.value.observe(trigger.value);
  }
});

/**
 * Desconecta o IntersectionObserver quando o componente está prestes a ser desmontado.
 */
onBeforeUnmount(() => {
  if (observer.value) {
    observer.value.disconnect();
  }
});
</script>
