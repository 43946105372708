<template>
  <div>
    <AppFixedPageTitle
      title="Controle de reuso"
      icon="/img/icons/icons8/ios/increase.png"
    />
    <AppPageHeader :invertColumnOrderOnMobile="false">
      <template slot="search-bar">
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          @onSearchClick="listItems"
          @onClearClick="clearFilter"
        >
          <AppSearchBarFilterSection
              name="Central"
              icon="/img/icons/icons8/ios/progress-indicator_grey.png"
            >
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label
                  class="form-control-label fs-11 new-default-gray-font font-weight-400"
                >
                Selecionar
              </label>
              <PuzlSelect
                style="width: 100%"
                v-model.lazy="filter.company_plants"
                :items="$_short_plants"
                :disableBoxShadow="true"
                placeholder="Centrais"
                :multiple="true"
                class="select-sm mb-9 col-md-12 px-0 new-default-black-font"
              />
            </div>
          </AppSearchBarFilterSection>
        </AppSearchBar>
      </template>
      <template slot="header-buttons">
        <div class="container-fluid" style="padding-left: 0 !important;padding-right: 0 !important;">
          <div class="row">
            <div class="offset-lg-4 col-lg-8 pl-lg-0 pr-lg-3">
              <div class="container-fluid" style="padding-left: 0 !important;padding-right: 0 !important;">
                <div class="row pr-0">
                  <div class="my-2 my-lg-0 col-lg-4">
                    <div class="info-view-card">
                      <div>
                        <img src="/img/icons/icons8/ios/test-tube_primary.png" width="30">
                      </div>
                      <div>
                        <div v-if="loadingWidgets" class="spinner"></div>
                        <h5 v-if="!loadingWidgets" class="m-0 font-weight-normal new-default-gray-font">Volume de concreto</h5>
                        <h3 v-if="!loadingWidgets" class="m-0 new-default-black-font">
                          {{ Number($_widgets.formulation_volume).toFixed(1).toString().replace('.', ',') }} m<sup>3</sup>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="mb-2 mb-lg-0 col-lg-4">
                    <div class="info-view-card">
                      <div>
                        <img src="/img/icons/todo-list.png" width="30">
                      </div>
                      <div>
                        <div v-if="loadingWidgets" class="spinner"></div>
                        <h5 v-if="!loadingWidgets" class="m-0 font-weight-normal new-default-gray-font">Volume de serviços</h5>
                        <h3 v-if="!loadingWidgets" class="m-0 new-default-black-font">
                          {{ Number($_widgets.service_volume).toFixed(1).toString().replace('.', ',') }} m<sup>3</sup>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="mb-2 mb-lg-0 col-lg-4">
                    <div class="info-view-card">
                      <div>
                        <img src="/img/icons/icons8/ios/recycle-sign_warning.png" width="30">
                      </div>
                      <div>
                        <h5 v-if="!loadingWidgets" class="m-0 font-weight-normal new-default-gray-font">Sobra</h5>
                        <div v-if="loadingWidgets" class="spinner"></div>
                        <h3 v-else-if="!loadingWidgets && $_widgets.total_value" class="m-0 new-default-black-font">
                          {{ Number($_widgets.reuse_volume).toFixed(1).toString().replace('.', ',') }} m<sup>3</sup>
                        </h3>
                        <h3 v-else-if="!loadingWidgets && !$_widgets.total_value" class="m-0 new-default-black-font">
                          {{ 0 | currency }}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </AppPageHeader>
    <AppTabSelect
      :items="tabSelectItems"
      @onTabSelectItemClick="onTabSelectItemClick"
      :isShowViewTypes="false"
    >
    </AppTabSelect>
    <MiniDashboard
      :widgets="$_widgets"
      :loadingWidgets="loadingWidgets"
    />
    <ListingManager
      :loadingSkeleton="loadingSkeleton"
      :loadingSkeletonCanceleds="loadingSkeletonCanceleds"
      :isViewing="isViewing"
      :canceled_items="$_canceled_items.data"
      :items="$_items.data"
      @getReportExcel="getReportExcel"
      @getReportCanceledsExcel="getReportCanceledsExcel"
      @navegate="navegate"
      @navegateCanceleds="navegateCanceleds"
    />
  </div>
</template>

<script>
import {
  AppSearchBar,
  AppSearchBarFilterSection,
  initSearchBarFilterType,
  SearchBarFilterType,
  AppViewTrigger,
  AppSelect,
  AppTabSelect,
  AppFixedPageTitle,
  AppPageHeader,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  AppPageHeaderActionsButtonDropItem,
  TabSelectItemType,
} from "../../../../components/AppGlobal";
import { ReuseControlFilterType, initReuseControlListFilterType } from "./types";
import MiniDashboard from "./_MiniDashboard.vue";
import { date } from "../../../../helpers";
import cursorPaginate from "@/mixins/cursorPaginate";
import PuzlSelect from "@/components/PuzlSelect";
import ListingManager from "./Shared/_ListingManager.vue";
import {mapGetters} from "vuex";

const VIEWS_ENUM = Object.freeze({
  CANCELED: 0,
  LEFTOVER: 1,
});

export default {
  mixins: [cursorPaginate],
  name: "Index",
  components : {
    AppSearchBar,
    AppSearchBarFilterSection,
    initSearchBarFilterType,
    SearchBarFilterType,
    AppViewTrigger,
    AppSelect,
    AppTabSelect,
    AppFixedPageTitle,
    AppPageHeader,
    AppPageHeaderActions,
    AppPageHeaderActionsButton,
    AppPageHeaderActionsButtonDropItem,
    TabSelectItemType,
    PuzlSelect,
    ListingManager,
    MiniDashboard,
  },
  data() {
    return {
      searchBarFilter: initSearchBarFilterType(),
      currentDate: date.make().format(date.FORMAT.ISO_8601),
      filter: initReuseControlListFilterType(),
      viewEnum: VIEWS_ENUM,
      isViewing: 1, // viewEnum.REMAINS
      isLoading: false,
      loadingSkeleton: false,
      loadingWidgets: false,
      loadingSkeletonCanceleds: false,
      page: 1,
      canceled_page: 1,
      per_page: 15,
    }
  },
  computed: {
    ...mapGetters({
      $_short_plants: "plant/activeShortItems",
      $_widgets: 'reuseControl/getWidgets',
      $_items: 'reuseControl/fetch',
      $_canceled_items: 'reuseControl/fetchCanceleds',
    }),
    tabSelectItems() {
      return [
        {
          id: 1,
          name: 'Sobra de concreto',
          selected: this.isViewing === this.viewEnum.LEFTOVER,
        },
        {
          id: 0,
          name: 'Cancelamentos',
          selected: this.isViewing === this.viewEnum.CANCELED,
        },
      ];
    },
  },
  methods: {
    navegate(page) {
      this.page = page;
      this.getItems()
    },
    navegateCanceleds(page) {
      this.canceled_page = page;
      this.getCanceleds()
    },
    /**
     * @param {TabSelectItemType} item
     */
     onTabSelectItemClick(item) {
      if (this.isViewing === item.id) {
        return;
      };
      this.isViewing = item.id;
    },
    load() {
      this.init()
    },
    getWidgets() {
      this.loadingWidgets = true;
      this.$store.dispatch("reuseControl/getWidgets", {filter: this.filter})
        .then(() => {
          this.loadingWidgets = false
        })
    },
    getReportExcel(){
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      let loader = this.$loading.show();
      this.$store
        .dispatch("reuseControl/getReportExcel", { filter: this.filter, per_page: this.per_page, page: this.page })
        .then(async (response) => {
          let blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "Relatório controle de reuso.xlsx");
          await link.click();
          this.$notify({
            type: "success",
            message: "Solicitação realizada com sucesso!",
          });
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: "Erro ao realizar o download do arquivo excel!",
          });
        }).finally(() => {
        loader.hide();
      });
    },
    getReportCanceledsExcel(){
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      let loader = this.$loading.show();
      this.$store
        .dispatch("reuseControl/getReportCanceledsExcel", { filter: this.filter, per_page: this.per_page, page: this.page })
        .then(async (response) => {
          let blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "Relatório controle de reuso - canceladas.xlsx");
          await link.click();
          this.$notify({
            type: "success",
            message: "Solicitação realizada com sucesso!",
          });
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: "Erro ao realizar o download do arquivo excel!",
          });
        }).finally(() => {
        loader.hide();
      });
    },
    getItems() {
      this.loadingSkeleton = true;
      this.$store.dispatch("reuseControl/fetchItems", { filter: this.filter, per_page: this.per_page, page: this.page })
        .then(() => {
          this.loadingSkeleton = false
        })
    },
    getCanceleds() {
      this.loadingSkeletonCanceleds = true;
      this.$store.dispatch("reuseControl/getCanceleds", { filter: this.filter, per_page: this.per_page, page: this.canceled_page })
        .then(() => {
          this.loadingSkeletonCanceleds = false
        })
    },
    /**
     * Listar itens
     * @param {boolean} isAccumulateItems
     */
    async listItems(isAccumulateItems = false) {
      this.isLoading = true;
      if (!this.startCursor(this.filter, isAccumulateItems)) {
        this.isLoading = false;
        return;
      }

      this.prepareFilter();
      await this.getWidgets();
      await this.getItems();
      await this.getCanceleds();

      this.isLoading = false;
      this.loadingSkeleton = false
    },
    /**
     * Preparar filtro antes da listagem
     */
    prepareFilter() {
      this.filter.range.start = this.searchBarFilter.range.start;
      this.filter.range.end = this.searchBarFilter.range.end;
    },
    /**
     * Limpar os filtros e listar os itens caso especificado
     * @param {boolean} isRefreshList - Indica se deve listar os itens
     */
    clearFilter(isRefreshList = true) {
      Object.assign(this.searchBarFilter, this.defaultSearchBarFilter());
      Object.assign(this.filter, this.defaultFilter());
      if (isRefreshList) {
        this.listItems();
      }
    },
    /**
     * Padrão do filtro principal
     * @returns {ReuseControlFilterType}
     */
    defaultFilter() {
      const defaultFilter = {
        ...initReuseControlListFilterType(),
        company_plants: [],
        range: {
          start: null,
          end: null,
        },
      };

      return defaultFilter;
    },
    /**
     * Padrão de filtro da barra de pesquisa
     * @returns {SearchBarFilterType}
     */
    defaultSearchBarFilter() {
      return {
        ...initSearchBarFilterType(),
        custom_search_values: null,
        range: {
          items: [],
          selected: null,
          start: this.filter.range.start ?? this.currentDate,
          end: this.filter.range.end ?? this.currentDate,
        },
      }
    },
  },
  async mounted() {
    if (!this.$_short_plants.length) {
      await this.$store.dispatch("plant/fetchItemsActive");
    }
    this.clearFilter()
  },
}
</script>

<style scoped>
.info-view-card {
  display: flex;
  align-items: center;
  padding: 12px 12px 12px 12px;
  box-shadow: 0px 5px 10px 0px #0000000D;
  border-radius: 8px; background: white; gap: 16px;
  min-height: 75px;
}

.info-view-card h5 {
  white-space: nowrap;
  font-size: 10px;
}

.spinner {
  border: 4px solid rgba(52, 152, 219, 0.2);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
