<template>
  <div class="header">
    <div class="container-fluid">
      <div class="row py-4 align-items-center">
        <div class="col-md-4">
          <h2 style="font-size: 18px !important;" class="m-0" :class="'text-' + type">
            <img width="32" :src="icon" class="mr-2"/>
            {{ title }}
          </h2>
        </div>
        <div class="col-md-8">
          <div class="row d-flex justify-content-end">
            <!-- Aqui ficarão os botões do cabeçalho -->
            <slot>

            </slot>
            <div v-if="isBackButtonEnabled" @click.prevent="$router.go(-1)" class="new-puzl-default">
              <base-button v-if="!isDropdown" type="light" block class="new-puzl-default"
                style="border-radius: 30px !important; border: none;">
                <img src="/img/icons/circled-left.png" width="16">
                Voltar
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'page-header',
  data(){
    return {
      prevRoute: null,
    }
  },
  props: {
    title: {
      type: String,
      description: 'Título da tela',
      default: '',
    },
    isDropdown: {
      type: Boolean,
      description: 'Indica se o botão funcionará como dropdown abrindo slots para um ou mais itens.',
      default: false
    },
    icon: {
      type: String,
      description: 'Ícone que será usado na tela',
      default: '',
    },
    type: {
      type: String,
      description: 'Define a cor do texto que será exibido no header',
      default: 'primary',
    },
    isBackButtonEnabled: {
      type: Boolean,
      description: 'Habilita o botão voltar no cabeçalho',
      default: false,
    },
  },
  mounted() {
    this.prevRoute = this.$router.history._startLocation
  }
};
</script>

<style scoped>
div.new-puzl-default {
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 5px;
  display: block;
}

.new-puzl-default {
  font-family: Fredoka;
  font-weight: 400 !important;
  font-size: 12px !important;
  min-height: 35px !important;
  max-height: 35px !important;
  width: 100%;
}

button.new-puzl-default {
  display: flex;
  align-items: center;
}

.new-puzl-default img {
  margin-right: 8px;
}

@media (min-width: 992px) {
  div.new-puzl-default {
    margin-left: 0px;
    margin-top: 0px;
  }

  .new-puzl-default {
    min-width: 205px;
    max-width: 205px;
    display: flex;
  }
}
</style>
