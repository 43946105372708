/**
 * @typedef {Object} SapConfigurationSyncType
 * @property {boolean} is_active_control
 * @property {string} is_active
 * @property {string} typeable_id
 * @property {string} external_id
 * @property {string} password
 * @property {string} auth_url
 * @property {string} url
 */
export const SapConfigurationSyncType = {};

/**
 * @returns {SapConfigurationSyncType}
 */
export const initSapConfigurationSyncType = () => {
  return {
    is_active_control: false,
    is_active: "",
    typeable_id: "",
    external_id: "",
    password: "",
    auth_url: "",
    url: "",
  };
};
