<template>
  <div>
    <div class="container-fluid">
      <card>
        <div class="row">
          <div class="col-md-4 p-2">
            <input-date-picker ref="date-picker" @handleFilterDate="handleFilterDate" :default-range="defaultRange"/>
          </div>
          <div class="col-md-4 p-2 custom-dashboard">
            <puzl-select
              class="select-xl"
              v-model="selectedBanksAccount"
              :items="$_bank_accounts"
              placeholder="Conta Bancária de Crédito"
              label="bank_name"
              @input="init"
              :loading="loadingBankAccount"
              :disabled="loadingBankAccount"/>
          </div>
        </div>
      </card>
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
      </div>

      <PuzlEmptyData v-if="!$_bank_balances.length && !loadingSkeleton"></PuzlEmptyData>

      <div class="row card-wrapper" v-if="$_bank_balances.length && !loadingSkeleton">
        <div class="col-lg-4" v-for="(bankBalance, index) in $_bank_balances" :key="index">

          <card>
            <!-- Card header -->
            <div class="row align-items-center mb-3">
              <div class="col-6 text-dark" style="font-size: 15px;">
                <b> {{ bankBalance.bank_account_name }} </b>
              </div>
              <div class="col-2">
                <el-popover trigger="hover" placement="top" class="p-0 float-right pr-0">
                  Ajuste =
                  <span
                    :class="[
                                        bankBalance.balance_adjustment === 0 ? 'text-dark' : '',
                                        bankBalance.balance_adjustment > 0 ? 'text-success' : '',
                                        bankBalance.balance_adjustment < 0 ? 'text-danger' : '',
                                    ]"
                  >
                                    {{ bankBalance.balance_adjustment | currency() }}
                                </span>
                  <base-button outline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none">
                    <i
                      class="fa-solid fa-dollar-sign display-4"
                      :class="[
                                        bankBalance.balance_adjustment === 0 ? 'text-dark' : '',
                                        bankBalance.balance_adjustment > 0 ? 'text-success' : '',
                                        bankBalance.balance_adjustment < 0 ? 'text-danger' : '',
                                    ]">
                    </i>
                  </base-button>
                </el-popover>
              </div>
              <div class="col-4 text-right">
                <base-dropdown menuOnRight>
                  <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                    <i class="fas fa-cog"></i>
                  </base-button>
                  <a class="dropdown-item" @click="handleEdit(bankBalance.id)">
                    <i class="fas fa-edit text-warning main-icon-menu"></i>
                    Editar
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click.prevent="handleDelete(bankBalance.id)">
                    <i class="fas fa-times text-danger"></i>
                    Excluir
                  </a>
                </base-dropdown>
              </div>
            </div>
            <!-- Card body -->
            <h2 class="mb-0 mt-2 numeric display-5 text-center text-success"
                :class="bankBalance.total > 0 ? 'text-success' : 'text-danger'">
              {{ bankBalance.total | currency() }}
            </h2>
            <div class="text-center display-5 text-dark">
              {{ bankBalance.date_bank_balance | parseDate("DD/MM/YYYY") }}
            </div>
            <div v-if="bankBalance.observation" class="border-left border-danger border-3 p-2 mb-3">
              <span class="text-dark" style="font-size: 12px;"> {{ bankBalance.observation }} </span>
            </div>

            <div class="text-center">
                        <span style="font-size: 12px;">
                            {{ bankBalance.user_name }}  | {{ bankBalance.created_at | parseDate("DD/MM/YYYY hh:mm") }}
                        </span>
            </div>
          </card>
        </div>
      </div>
      <ModalEdit ref="ModalEdit"/>
      <loading-pagination :show="loading && !loadingSkeleton"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import ModalEdit from './_ModalEdit';
import InputDatePicker from "@/components/InputDatePicker";
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "ListBankBalance",
  mixins: [cursorPaginate],
  components: {
    PuzlEmptyData,
    SkeletonPuzlGrid,
    LoadingPagination,
    ModalEdit,
    InputDatePicker,
    PuzlSelect,
  },
  data() {
    return {
      loadingSkeleton: false,
      loadingBankAccount: true,
      selectedBanksAccount: null,
      filter: {},
      range: {
        start: null,
        end: null,
      },
      defaultRange: {
        start: new Date(),
        end: new Date(),
      },
    };
  },
  computed: {
    ...mapGetters({
      $_bank_balances: "bankBalance/fetch",
      $_bank_accounts: 'bankAccount/fetch',
    }),
  },
  mounted() {
    this.$store.dispatch('bankAccount/fetchItems').then(() => {
      this.loadingBankAccount = false;
    });
    this.fetchItemsPaginate();
  },
  methods: {
    fetchItemsPaginate() {
      this.range = this.defaultRange;
      this.init({});
    },
    init(filter = null) {
      this.startCursor(filter);
      this.$Progress.start();
      this.filter.range = this.range;
      this.filter.bankAccount = this.selectedBanksAccount;
      this.$store
        .dispatch("bankBalance/fetchItemsPaginate", {
          filter: this.filter,
          next_page: this.paginate.nextUrl
        })
        .then(response => {
          this.$Progress.finish()
          this.resolveCursor(response)
        })
        .catch(error => {
          this.resolveCursor()
          this.$Progress.finish()
        });
    },
    handleFilterDate(filter) {
      this.range = filter;
      this.init({});
    },
    handleEdit(id) {
      this.$refs.ModalEdit.handleEditModal(id)
    },
    handleDelete(id) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$notify({
            type: 'info',
            message: 'Estamos trabalhando em sua solicitação.'
          });
          this.$store.dispatch('bankBalance/destroy', id)
            .then((response) => {
              this.$notify({
                type: 'success',
                message: response.message
              });
            })
            .catch(error => {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              })
            })
          this.$Progress.finish();
        }
      }).catch(() => this.$Progress.finish())
    }
  },
};
</script>

<style></style>
