<template>
  <div>
    <modal size="lg" :show.sync="show">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <multi-filter @fetch="load" :without_filter="true" :filter="filter">
        <h4 v-if="filterHaveToday()" class="col-md-12 p-1 mb-1 text-warning font-weight-500" style="text-transform: none">
          <i class="fa-solid fa-circle-exclamation"></i>
          Caso seja filtrado a data de hoje, serviços em andamento poderão ser faturados.
        </h4>
        <div class="col-md-4 p-1 mb-1">
          <input-date-picker size="small" :default-range="range" ref="input-date-picker"
                             @handleFilterDate="handleFilterSubmit"/>
        </div>
        <div class="col-sm p-1">
          <validation-provider rules="required" v-slot="{errors}">
            <base-input input-classes="form-control form-control-sm">
              <puzl-select
                @change="load"
                class="select-md"
                v-model="filter.company_plant_id"
                :items="$_plants"
                :loading="loadingCompanyPlant"
                :disabled="loadingCompanyPlant"/>
            </base-input>
          </validation-provider>
        </div>
        <div class="col-sm p-1">
          <base-button size="sm" style="height: 36px" :class="invoiced === contract_proposals.length && 'active'"
                       :disabled="loadingSkeleton"
                       @click.prevent="setAll" type="info" outline block>
            <i class="far fa-file-alt"></i> Faturas <span style="font-size: 10px;background-color: #00ABE7"
                                                          class="badge badge-info text-white">{{ invoiced }} /
          {{
              contract_proposals.length
            }}
        </span>
          </base-button>
        </div>
        <div class="col-sm p-1">
          <base-button size="sm" style="height: 36px" disabled type="primary" outline block>
            <i class="far fa-file-code"></i> NFS-e <span style="font-size: 10px" class="badge badge-primary text-white">{{
              0
            }} /
          {{
              this.contract_proposals.length
            }}
        </span>
          </base-button>
        </div>
      </multi-filter>
      <div class="table-responsive" style="overflow-x: hidden">
        <div class="row" style="max-height: 400px">
          <div v-show="!loadingSkeleton" class="col-md-12" v-for="(item, index) in contract_proposals">
            <card style="margin-top: -20px">
              <div class="row card-header pl-0 pt-0 mt-0">
                <div class="col-md-12 pl-1">
<h5 class="text-danger" v-show="!item.has_deduct">
  <i class="fa-solid fa-triangle-exclamation"></i> {{item.has_deduct_title}} (sem regra de iss cadastrada)
</h5>
                </div>
                <div class="pl-1" :class="item.block_iss ? 'col-md-12' : 'col-md-9'">
                  <div>
                    <h3>{{ item.code }} <span style="font-size: 0.9rem" v-if="item.situation_name"
                                              class="small font-weight-normal">| {{ item.situation_name }} </span></h3>
                    <span class="display-5">{{ item.construction_name }} | </span>
                    <span class="display-5">{{ item.customer_name }}</span>
                  </div>
                </div>
                <div class="col-md-3 pl-1" v-if="!item.block_iss">
                  <label class="label form-control-label text-danger">RETER ISS?</label>
                  <base-input>
                    <base-switch
                      v-model="item.iss_retain"
                    />
                  </base-input>
                </div>
              </div>
              <div class="card-body p-0 pt-1">
                <div class="row">
                  <!--                  <div class="col-md-4">-->
                  <!--                    <label-->
                  <!--                      class="col-form-label form-control-label"-->
                  <!--                    >-->
                  <!--                      <span class="text-danger">&nbsp;*&nbsp;</span>-->
                  <!--                    </label>-->
                  <!--                    <validation-provider rules="required">-->
                  <!--                      <base-input input-classes="form-control-sm">-->
                  <!--                        <el-select-->
                  <!--                          v-model="item.payment_term_id"-->
                  <!--                          :disabled="loadingPaymentTerm || !item.payment_method_id"-->
                  <!--                          size="mini"-->
                  <!--                          placeholder="Selecione"-->
                  <!--                          filterable-->
                  <!--                        >-->
                  <!--                          <el-option v-for="term in payment_terms_by_method[item.payment_method_id]" :key="term.id"-->
                  <!--                                     :label="term.name"-->
                  <!--                                     :value="term.id"></el-option>-->
                  <!--                        </el-select>-->
                  <!--                      </base-input>-->
                  <!--                    </validation-provider>-->
                  <!--                  </div>-->
                  <div class="col-md-4 p-1">
                    <label>Método de pagamento</label>
                    <span class="text-danger"> *</span>
                    <base-input input-classes="form-control-sm">
                      <el-select
                        @change="getPaymentTerms(item)"
                        v-model="item.payment_method_id"
                        size="mini"
                        :disabled="loadingMethod"
                        placeholder="Selecione"
                        filterable
                      >
                        <el-option v-for="method in item.payment_methods"
                                   :key="method.id" :label="method.name"
                                   :value="method.id"></el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-md-4 p-1">
                    <label>Condição de pagamento</label>
                    <span class="text-danger"> *</span>
                    <base-input input-classes="form-control-sm">
                      <el-select
                        v-model="item.payment_term_id"
                        :disabled="loadingPaymentTerm || !item.payment_method_id"
                        size="mini"
                        placeholder="Selecione"
                        filterable
                      >
                        <el-option v-for="term in payment_terms_by_method[item.id]" :key="term.id"
                                   :label="term.name"
                                   :value="term.id"></el-option>
                      </el-select>
                    </base-input>
                  </div>
<!--                  <div class="col-md-4 p-1">-->
<!--                    <label>Mediador de pagamento</label>-->
<!--                    <span class="text-danger" v-show="item.payment_intermediaries_exists"> *</span>-->
<!--                    <base-input input-classes="form-control-sm">-->
<!--                      <el-select-->
<!--                        v-model="item.payment_intermediary_id"-->
<!--                        size="mini"-->
<!--                        :disabled="!item.payment_method_id"-->
<!--                        placeholder="Selecione"-->
<!--                        filterable-->
<!--                      >-->
<!--                        <el-option v-for="method in payment_intemerdiaries_selected[item.id]"-->
<!--                                   :key="method.id" :label="method.name"-->
<!--                                   :value="method.id"></el-option>-->
<!--                      </el-select>-->
<!--                    </base-input>-->
<!--                  </div>-->
                  <div class="col-md-4 p-1">
                    <label>Conta bancária de crédito</label>
                    <span class="text-danger"> *</span>
                    <base-input input-classes="form-control-sm">
                      <el-select
                        v-model="item.bank_account_id"
                        :disabled="loadingBankAccounts"
                        size="mini"
                        placeholder="Selecione"
                        filterable
                      >
                        <el-option
                          v-for="item in getBankAccounts(item)"
                          :key="item.id"
                          :label="formatAccountLabel(item)"
                          :value="item.id"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-md-4 p-1">
                    <base-button size="sm" block :class="item.invoice && 'active'"
                                 style="height: 31px"
                                 :style="windowWidth >= 1024 && 'margin-top: 29px'"
                                 :disabled="!item.has_deduct || !item.bank_account_id || !item.payment_method_id || !item.payment_term_id || (item.payment_intermediaries_exists && !item.payment_intermediary_id)"
                                 @click.prevent="setInvoice(index, item.invoice)"
                                 outline type="info"><i class="far fa-file-alt"></i> Fatura
                    </base-button>
                  </div>
                  <div class="col-md-4 p-1">
                    <base-button
                      :style="windowWidth >= 1024 && 'margin-top: 29px'"
                      style="height: 31px" size="sm" block disabled outline type="primary"><i
                      class="far fa-file-code"></i>NFS-e
                    </base-button>
                  </div>
                  <!--                  <div class="col-md-4 p-1">-->
                  <!--                    <label>Mediador de pagamento</label>-->
                  <!--                    <base-input input-classes="form-control-sm">-->
                  <!--                      <el-select-->
                  <!--                        v-model="item.bank_account_id"-->
                  <!--                        size="mini"-->
                  <!--                        placeholder="Selecione"-->
                  <!--                        filterable-->
                  <!--                        :disabled="-->
                  <!--                          loadingBankAccounts"-->
                  <!--                      >-->
                  <!--                        <el-option-->
                  <!--                          v-for="item in $_bank_accounts"-->
                  <!--                          :key="item.id"-->
                  <!--                          :label="formatAccountLabel(item)"-->
                  <!--                          :value="item.id"-->
                  <!--                        >-->
                  <!--                        </el-option>-->
                  <!--                      </el-select>-->
                  <!--                    </base-input>-->
                  <!--                  </div>-->
                  <!--                  <div class="col-md-4 p-1">-->
                  <!--                    <label> Conta bancária de crédito</label>-->
                  <!--                    <base-input input-classes="form-control-sm">-->
                  <!--                      <el-select-->
                  <!--                        v-model="item.bank_account_id"-->
                  <!--                        size="mini"-->
                  <!--                        placeholder="Selecione"-->
                  <!--                        filterable-->
                  <!--                        :disabled="-->
                  <!--                          loadingBankAccounts"-->
                  <!--                      >-->
                  <!--                        <el-option-->
                  <!--                          v-for="item in $_bank_accounts"-->
                  <!--                          :key="item.id"-->
                  <!--                          :label="formatAccountLabel(item)"-->
                  <!--                          :value="item.id"-->
                  <!--                        >-->
                  <!--                        </el-option>-->
                  <!--                      </el-select>-->
                  <!--                    </base-input>-->
                  <!--                  </div>-->
                  <!--                  <div class="col-md-4">-->
                  <!--                    <label>CNPJ Emissor</label>-->
                  <!--                    <base-input class="" input-classes="form-control-sm">-->
                  <!--                      <el-select-->
                  <!--                        v-model="item.company_plant_issuer_id"-->
                  <!--                        size="mini"-->
                  <!--                        placeholder="Selecione"-->
                  <!--                        filterable-->
                  <!--                        :loading="loadingCompanyPlantIssuers"-->
                  <!--                        :disabled="loadingCompanyPlantIssuers || !item.company_plant_id"-->
                  <!--                      >-->
                  <!--                        <el-option label="Selecione" value=""></el-option>-->
                  <!--                        <el-option-->
                  <!--                          v-for="company_plant_issuer in selectedCompanyPlantIssuers[item.company_plant_id]"-->
                  <!--                          :key="company_plant_issuer.id"-->
                  <!--                          :label="'('+company_plant_issuer.ein+') - ' + company_plant_issuer.business_name"-->
                  <!--                          :value="company_plant_issuer.id"-->
                  <!--                        >-->
                  <!--                        </el-option>-->
                  <!--                      </el-select>-->
                  <!--                    </base-input>-->
                  <!--                  </div>-->
                  <!--                  <template v-if="windowWidth > 1024">-->
                  <!--                    <div class="col-md-2" style="margin-top: 31px">-->
                  <!--                      <base-button size="sm" block :class="item.invoice && 'active'"-->
                  <!--                                   :disabled="!item.payment_method_id|| !item.payment_term_id"-->
                  <!--                                   @click.prevent="setInvoice(index, item.invoice)"-->
                  <!--                                   outline type="info">Fatura-->
                  <!--                      </base-button>-->
                  <!--                    </div>-->
                  <!--                    <div class="col-md-2" style="margin-top: 31px">-->
                  <!--                      <base-button size="sm" block disabled outline type="primary">NFS-e</base-button>-->
                  <!--                    </div>-->
                  <!--                  </template>-->
                  <!--                  <template>-->
                  <!--                    <div class="col-lg-4 pt-1" style="margin-top: 31px">-->
                  <!--                      <base-button size="sm" block :class="item.invoice && 'active'"-->
                  <!--                                   style="height: 31px"-->
                  <!--                                   :disabled="!item.payment_method_id|| !item.payment_term_id"-->
                  <!--                                   @click.prevent="setInvoice(index, item.invoice)"-->
                  <!--                                   outline type="info">Fatura-->
                  <!--                      </base-button>-->
                  <!--                    </div>-->
                  <!--                    <div class="col-lg-4 pt-1" style="margin-top: 31px">-->
                  <!--                      <base-button style="height: 31px" size="sm" block disabled outline type="primary">NFS-e-->
                  <!--                      </base-button>-->
                  <!--                    </div>-->
                  <!--                  </template>-->
                </div>
              </div>
            </card>
          </div>
          <div class="col-md-12" v-for="item in 3" v-show="loadingSkeleton">
            <card>
              <div class="card-header">
                <div class="row">
                  <div class="col-md-4">
                    <skeleton-puzl type="text"/>
                  </div>
                  <div class="col-md-8"/>
                  <div class="col-md-6">
                    <skeleton-puzl type="text"/>
                  </div>
                  <div class="col-md-6"/>
                  <div class="col-md-6">
                    <skeleton-puzl type="text"/>
                  </div>
                  <div class="col-md-6"/>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <skeleton-puzl type="text"/>
                  </div>
                  <!--                  <div class="col-md-2" style="margin-top: 31px">-->
                  <!--                    <base-button :class="item.invoice && 'active'" @click.prevent="setInvoice(index, item.invoice)"-->
                  <!--                                 outline type="info">Fatura-->
                  <!--                    </base-button>-->
                  <!--                  </div>-->
                  <!--                  <div class="col-md-2" style="margin-top: 31px">-->
                  <!--                    <base-button disabled outline type="primary">NFS-e</base-button>-->
                  <!--                  </div>-->
                </div>
              </div>
            </card>
          </div>
          <div class="col-md-12 mb-2" v-if="!loadingSkeleton && !contract_proposals.length">
            <PuzlEmptyData :withCard="false"/>
          </div>
        </div>
      </div>
      <div class="col-md-12 mt-2 text-right p-0">
        <base-button @click.prevent="close" type="secondary" class="text-capitalize">
          cancelar
        </base-button>
        <!--        <base-button :disabled="disableGenerate || !contract_proposals.length" @click.prevent="generateInvoices" type="primary"-->
        <!--                     native-type="submit">-->
        <!--          <span class="btn-label"><i class="fas fa-file-invoice-dollar"></i></span>-->
        <!--          Faturar e continuar-->
        <!--        </base-button>-->
        <base-button :disabled="!contract_proposals.length || !invoiced"
                     :loading="loading"
                     @click.prevent="generateInvoices"
                     type="success"
                     native-type="submit">
          <i class="fas fa-file-invoice-dollar"></i>
          Faturar e fechar
        </base-button>
      </div>
    </modal>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import MultiFilter from "@/components/Utils/MultiFilterV2";
import InputDatePicker from "@/components/InputDatePicker";
import SkeletonPuzl from '@/components/SkeletonPuzl'
import PuzlEmptyData from "@/components/PuzlEmptyData";
import PuzlSelect from "@/components/PuzlSelect";
import moment from 'moment'

export default {
  props: ['show'],
  name: "ModalAllotments",
  components: {
    MultiFilter,
    InputDatePicker,
    SkeletonPuzl,
    PuzlEmptyData,
    PuzlSelect
  },
  computed: {
    ...mapGetters({
      company_plants: "plant/activeItems",
      contract_proposals: "billingInvoice/getListOpenBilling",
      company_plant_issuers: "companyPlantIssuer/fetch",
      payment_terms: "paymentTerm/fetch",
      $_payment_intermediaries: "paymentIntermediary/fetch",
      payment_methods: "paymentMethod/fetch",
      $_bank_accounts: 'bankAccount/fetch',
      $_plants: "plant/activeItems",
    }),
    disableGenerate: function () {
      let missing_plant_or_issuer = 0
      const self = this
      for (var i = 0; i < self.contract_proposals.length; ++i) {
        if (self.contract_proposals[i].invoice) {
          if (!self.contract_proposals[i].company_plant_issuer_id ||
            !self.contract_proposals[i].company_plant_id) {
            missing_plant_or_issuer += 1
            break;
          }
        }
      }
      if (missing_plant_or_issuer) {
        return true
      }
      return false
    }
  },
  data() {
    return {
      range: {
        start: new Date(),
        end: new Date(),
      },
      loadingPlant: false,
      loadingCompanyPlant: false,
      loadingPaymentIntermediaries: false,
      payment_terms_by_method: {},
      payment_intermediaries_by_method: {},
      payment_intemerdiaries_selected: {},
      selectedCompanyPlantIssuers: {},
      loadingMethod: false,
      loadingBankAccounts: false,
      loadingPaymentTerm: false,
      loadingCompanyPlantIssuers: false,
      filter: {},
      loading: false,
      invoiced: 0,
      loadingSkeleton: true,
      allSelected: false,
      title: 'Emissão em lote',
      windowWidth: null,
    };
  },
  mounted() {
    this.$watch('show', function (val) {
      if (val) {
        this.windowWidth = window.innerWidth
        this.filter.range = this.$refs['input-date-picker'].range
        this.init()
        this.load()
      }
    })
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
  methods: {
    getBankAccounts(contract_proposal) {
      return this.$_bank_accounts.filter(item => {
        return contract_proposal.bank_accounts.includes(item.id)
      })
    },
    filterHaveToday() {
      if (this.filter.range) {
        return moment(this.filter.range.end).format('y-M-D') === moment(new Date()).format('y-M-D') ||
          moment(this.filter.range.start).format('y-M-D') === moment(new Date()).format('y-M-D')
      }
      return false
    },
    formatAccountLabel(item) {
      return item.name
    },
    getPaymentTerms(item) {
      item.payment_term_id = null
      item.payment_intermediary_id = null
      let paymentMethod = item.payment_methods.find((self) => self.id === item.payment_method_id)
      this.payment_terms_by_method[item.id] = item.payment_terms.filter(function (selected) {
        let has_method = selected.payment_methods.find((method) => method.uuid === paymentMethod.uuid)
        if (has_method) {
          return selected
        }
      })
      // BUSCA MEDIADOR DE PAGAMENTO
      this.payment_intemerdiaries_selected[item.id] = paymentMethod.payment_intermediaries
      item.payment_intermediaries_exists = paymentMethod.payment_intermediaries.length > 0
      if (paymentMethod.payment_intermediaries.length === 1) {
        item.payment_intermediary_id = paymentMethod.payment_intermediaries[0].id
      }

      if (this.payment_terms_by_method[item.id].length === 1) {
        item.payment_term_id = this.payment_terms_by_method[item.id][0].id
      }
    },
    init() {
      this.loadingPaymentTerm = true;
      this.$store.dispatch("paymentTerm/fetchItems").then(() => {
        this.loadingPaymentTerm = false;
      });
      this.loadingCompanyPlant = true;
      this.$store.dispatch("plant/fetchItemsActive").then(() => {
        this.loadingCompanyPlant = false;
      });
      this.loadingPaymentIntermediaries = true
      this.$store.dispatch("paymentIntermediary/fetchItems").then(() => {
        this.loadingPaymentIntermediaries = false
      })
      this.bankAccounts = true
      this.$store.dispatch('bankAccount/fetchItems', {})
        .then(response => {
          this.bankAccounts = false
        })

      this.loadingMethod = true
      this.$store.dispatch("paymentMethod/fetchItems")
        .then(() => {
          this.loadingMethod = false;
        }).catch(() => {
      })
      this.invoiced = 0
      this.$store.dispatch('companyPlantIssuer/fetchItems')
        .then(response => {
          this.loadingCompanyPlantIssuers = false;
        })
        .catch(() => {
          this.loadingCompanyPlantIssuers = false;
        });
      this.loadingPlant = true;
      this.$store.dispatch("plant/fetchItemsActive").then(() => {
        const self = this
        this.loadingPlant = false;
      });
    },
    generateInvoices() {
      this.loading = true
      const items = this.contract_proposals.filter(function (item) {
        return item.invoice == 1
      });
      this.$store.dispatch('billingInvoice/generateInvoices', {
        items: items,
        range: this.filter.range
      })
        .then(response => {
          this.close()
          this.$notify({type: 'success', message: response.message});
          this.loading = false
        }).catch(error => {
        this.loading = false
      })
    },
    setInvoice(index, value) {
      this.contract_proposals[index].invoice = !value
      !value ? this.invoiced += 1 : this.invoiced -= 1
    },
    setAll() {
      this.invoiced = 0
      this.allSelected = !this.allSelected
      if (this.invoiced === this.contract_proposals.length) {
        this.allSelected = false
      }
      const change = (val) => this.contract_proposals.forEach(item => {
        if (val) {
          if (!item.has_deduct || !item.bank_account_id || !item.payment_method_id || !item.payment_term_id || (item.payment_intermediaries_exists && !item.payment_intermediary_id)) {
            return
          }
          item.invoice = val
          return this.invoiced++
        }
        item.invoice = val
      })
      this.allSelected ? change(1) : change(0)
    },
    close() {
      this.$emit('close')
    },
    handleFilterSubmit(range) {
      this.filter.range = range
      this.load()
    },
    load(filter = null) {
      this.filter = {...filter, ...this.filter}
      this.loadingSkeleton = true
      this.$store.dispatch('billingInvoice/getAllProposalsWithOpenBilling', {filter: this.filter})
        .then(response => {
          const self = this
          this.contract_proposals.map(function (item) {
            if (item.payment_methods.length === 1) {
              item.payment_method_id = item.payment_methods[0].id
            }
          })
          this.contract_proposals.map(function (item) {
            if (item.payment_methods.length === 1) {
              self.getPaymentTerms(item)
            }
          })
          this.loadingSkeleton = false
        }).catch(error => {
        this.loadingSkeleton = false
      })
    }
  },
};
</script>

<style></style>
