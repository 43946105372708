var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.enabled)?_c('v-select',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.handleOnSelectChange),expression:"handleOnSelectChange"}],ref:"selectedEl",class:_vm.disableBoxShadow && 'no-box-shadow',attrs:{"autocomplete":"on","reduce":(item) => item.id,"multiple":_vm.multiple,"value":_vm.loading && _vm.multiple ? [] : _vm.loading ? null : _vm.value,"clearable":_vm.clearable,"closeOnSelect":!_vm.multiple,"loading":_vm.loading,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"selectable":_vm.selectable,"options":_vm.items.map(function (item) {
        return {
          label: _vm.labelMask ? _vm.applyMask(item) : item[_vm.label],
          id: _vm.recursiveKey ? this.setByArray(item, _vm.recursiveKey) : item[_vm.customKey],
          extra_field : this.extraField ? item[this.extraField] : null,
        };
    }, this)},on:{"input":_vm.handleInput,"search":_vm.fetchOptions}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Não há dados")])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }