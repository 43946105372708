<template>
  <div>
    <GanttElastic id="new-gantt-elastic" :options="headers" :tasks="items" />
  </div>
</template>

<script>
import GanttElastic from "gantt-elastic";

export default {
  name: "Gantt",
  components: {
    GanttElastic,
  },
  props: {
    headers: {
      type: Object,
      required: true,
      description: "Cabeçalhos",
    },
    items: {
      type: Array,
      description: "Itens principais da listagem",
      default: () => [],
    },
  },
};
</script>

<style>
#new-gantt-elastic .gantt-elastic__header-title--text,
#new-gantt-elastic .gantt-elastic__header-label,
#new-gantt-elastic .gantt-elastic__header-task-list-switch--wrapper {
  color: #2b2d32 !important;
}
#new-gantt-elastic .gantt-elastic__header-label,
#new-gantt-elastic .gantt-elastic__header-task-list-switch--wrapper {
  font-size: 12px;
}
#new-gantt-elastic .gantt-elastic__header-slider-wrapper {
  margin: 0 15px 0 8px;
}
#new-gantt-elastic .gantt-elastic__header {
  background: #fff !important;
}

#new-gantt-elastic .gantt-elastic__task-list-item-value, #new-gantt-elastic .gantt-elastic__task-list-header-label {
  font-family: 'Fredoka', 'Poppins', sans-serif!important;
}
</style>
