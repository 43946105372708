<template>
  <div>
    <modal size="lg" :show.sync="show">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ title }}</h5>
      </template>
      <multi-filter @clearFilters="clearFilter" @fetch="init" :filter="filter">
        <div class="col-md-6 p-2">
          <input-date-picker ref="input-date-picker" @handleFilterDate="handleFilterDate" />
        </div>
        <div class="col-md-3 p-2">
          <puzl-select
            class="select-lg"
            v-model="selectedCompanyPlants"
            :items="[{ id: 'all', name: 'Todos' }, ...$_plants]"
            customKey="id"
            :multiple="true"
            @input="handleFilterCompanyPlants"
            placeholder="Central"
          />
        </div>
        <div class="col-md-3 p-2">
          <base-button
            :class="invoice_checked_count === nfseList.length && !loadingSkeleton"
            :disabled="loadingSkeleton"
            @click.prevent="setCheckedAllInvoices()"
            type="info"
            outline
            block
          >
            <i class="far fa-file-code"></i> NFS-e
            <span class="badge badge-primary text-white">{{ invoice_checked_count }} / {{ nfseList.length }} </span>
          </base-button>
        </div>
      </multi-filter>

      <div class="table-responsive" style="overflow-x: hidden">
        <div class="row" style="max-height: 400px">
          <div class="col-md-12" v-for="item in 3" v-show="loadingSkeleton">
            <card>
              <div class="card-header">
                <div class="row">
                  <div class="col-md-4">
                    <skeleton-puzl type="text" />
                  </div>
                  <div class="col-md-8" />
                  <div class="col-md-6">
                    <skeleton-puzl type="text" />
                  </div>
                  <div class="col-md-6" />
                  <div class="col-md-6">
                    <skeleton-puzl type="text" />
                  </div>
                  <div class="col-md-6" />
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <skeleton-puzl type="text" />
                  </div>
                </div>
              </div>
            </card>
          </div>

          <div v-show="!loadingSkeleton" class="col-md-12" v-for="(item, index) in nfseGrouped">
            <card>
              <div class="card-header">
                <div class="row h4">
                  {{ item[0]['contract_proposal_code'] }}
                  <span
                    style="font-size: 0.9rem"
                    v-if="item[0]['situation_name']"
                    class="small font-weight-normal ml-1"
                  >| {{ item[0]['situation_name'] }}
                  </span>
                </div>

                <div class="row">
                  <span>{{ item[0]["entity_name"] }} | {{ item[0]["construction_name"] }}</span>
                </div>
              </div>

              <div class="card-body p-2 mt-2">
                <div v-for="(detail, index) in item">
                  <div class="row">
                    <div class="col-md-2 mt-1 m-0">
                      <h4>Fatura {{ leftPad(detail.id, 2) }}</h4>
                    </div>
                    <div class="col-md-2 mt-1 text-right">
                      <span>{{ detail.total | currency }}</span>
                    </div>
                    <div class="col-md-5 mb-1 mt-1">
                      <span>{{ detail.company_plant_issuer_ein }} | {{ detail.company_plant_name }}</span>
                    </div>
                    <div class="col-md-1 mb-1 text-right">                      
                      <el-popover v-if="detail.iss_retain == 1" trigger="hover" placement="top" class="p-0">
                        <span>
                          Valor retido: <b class="numeric">{{ detail.iss_value | currency() }}</b>
                        </span>
                        <span slot="reference">
                          <i class="fa-solid fa-hand-holding-dollar text-darkred"></i>
                        </span>
                      </el-popover>
                    </div>
                    <div class="col-md-2 text-right">
                      <base-button v-if="!detail.is_checked" size="sm" outline type="info" @click.prevent="chkInvoiceClick(detail)">
                        NFS-e
                        <i class="far fa-square"></i>
                      </base-button>
                      <base-button v-else size="sm" type="info" @click.prevent="chkInvoiceClick(detail)">
                        NFS-e
                        <i class="far fa-check-square"></i>
                      </base-button>
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </div>

          <div class="col-md-12" v-if="!loadingSkeleton && !nfseGrouped.length">
            <PuzlEmptyData />
          </div>
        </div>
      </div>

      <div class="col-md-12 mt-2 text-right p-0">
        <base-button @click.prevent="close" type="secondary" class="text-capitalize"> Cancelar </base-button>
        <base-button
          @click.prevent="generateNfse"
          type="success"
          native-type="submit"
          :loading="isLoading"
          :disabled="this.invoice_checked_count === 0"
        >
          <span class="btn-label"><i class="fa-solid fa-file-code"></i></span>
          Emitir NFS-e e Fechar
        </base-button>
      </div>
    </modal>
  </div>
</template>

<script>
import { groupArrayBy, leftPad } from "@/helpers";
import MultiFilter from "@/components/Utils/MultiFilterV2";
import InputDatePicker from "@/components/InputDatePicker";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import cursorPaginate from "@/mixins/cursorPaginate";
import {mapGetters} from "vuex"
import PuzlSelect from "@/components/PuzlSelect"

export default {
  props: ["show"],
  name: "ModalNfseAllotments",
  mixins: [cursorPaginate],
  components: {
    MultiFilter,
    InputDatePicker,
    SkeletonPuzl,
    PuzlEmptyData,
    leftPad,
    PuzlSelect,
  },
  computed: {
    ...mapGetters({
      $_plants: "plant/activeItems",
    }),    
  },
  data() {
    return {
      title: "NFS-e em lote",
      filter: {},
      range: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        end: new Date(),
      },
      selectedCompanyPlants: [],
      isLoading: false,
      invoice_checked_count: 0,
      nfseList: [],
      nfseGrouped: [],
      loadingSkeleton: true,
    };
  },
  mounted() {
    this.$watch("show", function (value) {
      if (value) {
        this.init();
      }
    });
  },
  methods: {
    leftPad: leftPad,
    handleFilterCompanyPlants() {
      const index = this.selectedCompanyPlants.findIndex((item) => item == "all")
      if (this.selectedCompanyPlants.length > 1 && index === 0) {
        return this.selectedCompanyPlants.splice(0, 1)
      }
      if (this.selectedCompanyPlants.length > 1 && this.selectedCompanyPlants.includes("all")) {
        this.selectedCompanyPlants = []
        this.selectedCompanyPlants.push("all")
      }
      this.init({})
    },
    clearFilter() {
      this.filter = {};
      this.filter.company_plants = [];
      this.init();
    },
    handleFilterDate(filter) {
      this.range = filter;
      this.init({});
    },
    init(filter = null) {
      this.invoice_checked_count = 0;
      this.filter = { ...filter, ...this.filter };
      this.filter.nfse_status = "not_issued";
      this.filter.range = this.range;
      this.filter.company_plants = !this.selectedCompanyPlants.includes("all") ? this.selectedCompanyPlants : [];
      this.loadingSkeleton = true;
      this.$store
        .dispatch("nfse/getNotIssuedList", { filter: this.filter })
        .then((response) => {
          this.nfseList = response.data;
          this.nfseGrouped = groupArrayBy(response.data, (item) => item.contract_proposal_id);
          this.loadingSkeleton = false;
        })
        .catch((error) => {
          this.loadingSkeleton = false;
        });
    },
    close(modalResult) {
      this.$emit("close", modalResult);
    },
    chkInvoiceClick(detail) {
      detail.is_checked = !detail.is_checked;
      detail.is_checked ? (this.invoice_checked_count += 1) : (this.invoice_checked_count -= 1);
      this.$forceUpdate();
    },
    setCheckedAllInvoices() {
      let checked_all = !(this.invoice_checked_count === this.nfseList.length);
      this.nfseList.forEach((item) => {
        item.is_checked = checked_all;
      });
      this.invoice_checked_count = checked_all ? this.nfseList.length : 0;
      this.$forceUpdate();
    },
    async generateNfse() {
      this.isLoading = true;

      const nfseListChecked = this.nfseList.filter((item) => {
        if (item.is_checked){
          return item.id;
        }
      }).map(item => item.id);

      const payload = {
        range: {
          start: this.$helper.parseDate(this.filter.range.start, 'YYYY-MM-DD'),
          end: this.$helper.parseDate(this.filter.range.end, 'YYYY-MM-DD'),
        },
        invoice_ids: nfseListChecked,
      };      
      this.$store.dispatch('nfse/issuePedingNfses', payload)
        .then((response) => {
          this.$notify({
            type: "success",
            message: "Solicitação concluída com sucesso! Este processo será realizado em segundo plano.",
          });
          this.close(true);
        }).catch(error => {
          let errors = error.status
            ? error.data.message
            : formatErrorValidation(error.response.data.errors);
          this.$notify({ type: "danger", message: errors });
        }).finally(() => {
          this.isLoading = false;
        });      
    },    
  },
};
</script>

<style></style>
