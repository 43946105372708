<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(save)"
            autocomplete="off"
          >
            <div>
              <span class="small">
                Ao salvar você estará
                <span v-if="selectedStatus === 1">
                  atualizando
                </span>
                <span v-else>
                  desconsiderando
                </span>
                o preço do MCC
              </span>
              <div class="row mt-3">
                <div class="col-md-12">
                  <label>Observações</label>
                  <textarea
                    v-model="observation"
                    maxlength="255"
                    class="form-control"
                    rows="3"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="danger" @click="closeModal()">
                <img src="/img/icons/cancel-white.png" width="22px" height="22px" class="mr-2" />
                Cancelar
              </base-button>
              <base-button
                type="success"
                native-type="submit"
                :disabled="invalid"
              >
                <img src="/img/icons/save.png" width="22px" height="22px" class="mr-2" />
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  components: {},
  name: "ModalObservation",
  data() {
    return {
      modal: false,
      title: "Justificar",
      observation: "",
      selectedStatus: null,
    };
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    /**
     * @param {number} selectedStatus
     */
    openModal(selectedStatus) {
      this.observation = '';
      this.selectedStatus = selectedStatus;
      this.modal = true;
    },
    save() {
      this.$emit("updateObservation", this.observation);
    },
  },
};
</script>

<style scoped></style>
