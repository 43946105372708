<template>
  <div>
    <modal @close="closeModal" :show.sync="modal.create" size="lg">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">Visualização de arquivo</h5>
      </template>
      <div v-if="item">
        <div class="row mb-2">
          <div class="col-md-12">
            <base-alert type="default">
              <div class="row">
                <div class="col-md-4 mb-1">
                  <base-button
                    @click.prevent="openLinkInNewTab"
                    style="background: none"
                    class="text-white"
                    size="sm"
                    block
                    outline
                    type="secondary"
                  >
                    <i class="fas fa-external-link-alt"></i> Abrir em nova guia
                  </base-button>
                </div>
                <div class="col-md-4 mb-1" v-if="item.ext != 'pdf'">
                  <base-button
                    disabled
                    style="background: none"
                    class="text-white"
                    size="sm"
                    block
                    outline
                    type="secondary"
                  >
                    <i class="fas fa-cloud-download-alt"></i> Download
                  </base-button>
                </div>
                <div class="col-md-4 mb-1">
                  <base-button disabled style="background: none" class="text-white" size="sm" block outline type="secondary">
                    <i class="far fa-trash-alt"></i> Excluir
                  </base-button>
                </div>
              </div>
            </base-alert>
          </div>
        </div>
        <div v-if="item.ext != 'pdf'" class="text-center" style="overflow: auto; height: 720px">
          <p>
            <img class="img-thumbnail" style="width: 100%" :src="item.url" />
          </p>
        </div>
        <div v-else class="text-center">
          <object :data="item.url" type="application/pdf" style="height: 720px; width: 100vh; max-width: 768px">
            <a :href="item.url">{{ item.name }}</a>
          </object>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { base_url_ms } from "@/plugins";

export default {
  data() {
    return {
      modal: {
        title: "Visualização de Arquivo",
        create: false,
      },
      item: null,
      base_url_ms: base_url_ms(),
    };
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    async openModal(item) {
      const loader = this.$loading.show();
      this.item = JSON.parse(item);
      const url = this.base_url_ms + 'download-s3?url=' + this.item.url;
      const response = await this.$store.dispatch('exports/download', url);
      let blob = new Blob([response], {type: this.item.type});
      this.item.url = URL.createObjectURL(blob);
      loader.hide();
      this.modal.create = true;
    },
    openLinkInNewTab(only_open = true) {
      let link = document.createElement("a");
      link.href = this.item.url;
      link.setAttribute("download", this.item.name);
      window.open(link, "_blank");
    },
  },
};
</script>
