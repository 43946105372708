<template>
<div>
    <modal :show.sync="modal.open">
        <template slot="header">
            <h5 class="modal-title">{{ modal.title }}</h5>
        </template>
        <div>
            <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
                <form class="needs-validation" @submit.prevent="handleSubmit(handleVolumeAdjustmentUpdate)" autocomplete="off">
                      <div>
                        <div class="form-group row m-0 p-0 pt-2">
                             <label class="col-md-5 pt-1 pb-3 mb-1 col-form-label form-control-label text-left">
                                Maior que
                                <span class="text-danger">&nbsp;*</span>
                            </label>
                            <div class="col-md-7 pb-3  pr-4">
                                <validation-provider rules="required" v-slot="{errors}">
                                    <base-input input-group-classes="input-group-sm">
                                        <input 
                                          inputmode="numeric" 
                                          v-model="adjustment.from" 
                                          class="form-control form-control-sm" 
                                          :class="errors[0] ? 'is-invalid' : 'is-valid'"
                                          maxlength="4"
                                          v-on:input="adjustment.from = formatString($event.target.value,'0','12.0',1)" 
                                        />
                                        <template slot="append">
                                            <small class="input-group-sm p-0 m-0">
                                                m<sup>3</sup>
                                            </small>
                                        </template>
                                    </base-input>
                                </validation-provider>
                            </div>
                        </div>
                        <div class="form-group row m-0 p-0">
                            <label class="col-md-5 pt-1 pb-3 mb-0 col-form-label form-control-label text-left pr-0">
                                Menor ou igual a
                                <span class="text-danger">&nbsp;*</span>
                             </label>
                            <div class="col-md-7 pr-4">
                                <validation-provider rules="required" v-slot="{errors}">
                                    <base-input input-group-classes="input-group-sm">
                                        <input 
                                          inputmode="numeric" 
                                          v-model="adjustment.until" 
                                          class="form-control form-control-sm" 
                                          :class="errors[0] ? 'is-invalid' : 'is-valid'"
                                          maxlength="4"
                                          v-on:input="adjustment.until = formatString($event.target.value,'0','12.0',1)" 
                                        />
                                        <template slot="append">
                                            <small class="input-group-sm p-0 m-0">
                                                m<sup>3</sup>
                                            </small>
                                        </template>
                                    </base-input>
                                </validation-provider>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <base-button type="secondary" @click="closeModal()">
                            Cancelar
                        </base-button>
                        <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                            Salvar
                        </base-button>
                    </div>
                </form>
            </validation-observer>
        </div>
    </modal>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'

import {
    checkNumberValue
} from '@/helpers'

import {
    mask1,formatErrorValidation
} from '@/plugins'

export default {
    name: "EditVolumeAdjustment",
    data() {
        return {
            modal: {
                title: 'Ajuste Volume Carregado',
                open: false,
            },
            adjustment: {
                from: null,
                until: null,
            },
            loadingStore: false,
        }
    },
    computed: {
        ...mapGetters({
            'listAdjustment': 'adjustmentVolume/fetch',
        })
    },
    methods: {
        formatString(value, min, max, decimals = "") {
                if (decimals) {
                    value = mask1(checkNumberValue(value, min, max).toString(), decimals, '.', true)
                    return checkNumberValue(value, min, max)
                }
                return checkNumberValue(value, min, max)
        },
        closeModal() {
            this.modal.open = false
        },
        handleEditModal(uuid) {
            this.$notify({
                type: 'info',
                message: 'Estamos trabalhando em sua solicitação.'
            })
            this.$Progress.start()
            this.$store.dispatch('adjustmentVolume/show', uuid)
                .then((response) => {
                    this.adjustment = {
                        uuid: response.data.uuid || '',
                        from: response.data.from || '',
                        until: response.data.until || ''
                    }
                    this.modal.open = true
                    this.$notify({
                        type: response.error_type,
                        message: response.message
                    })
                    this.$Progress.finish()
                }).catch((error) => {
                    if (error.response.status === 422) {
                        let errors = formatErrorValidation(error.response.data.errors)
                        this.$notify({
                            type: 'danger',
                            message: errors
                        })
                    }
                    this.$Progress.finish()
                    this.loadingStore = false
                })
        },
        handleVolumeAdjustmentUpdate() {
            this.$Progress.start()
            this.loadingStore = true
            this.$store.dispatch('adjustmentVolume/update', this.adjustment)
                .then(response => {
                    this.loadingStore = false
                    this.modal.open = false
                    this.$Progress.finish()
                    this.$notify({
                        type: response.error_type,
                        message: response.message
                    })
                    this.$emit("updatedVolumeAdjustment");
                })
                .catch(error => {
                    if (error.status == 200) {
                        this.$notify({
                            type: 'danger',
                            message: error.data.message
                        })
                        this.$Progress.finish();
                        this.loadingStore = false
                    } else if (error.response && error.response.status === 422) {
                        let errors = formatErrorValidation(error.response.data.errors)
                        this.$notify({
                            type: 'danger',
                            message: errors
                        })
                    }
                    this.$Progress.finish()
                    this.loadingStore = false
                })
        },
    },
    mounted() {
        this.$refs.formValidator.validate();
    },
}
</script>

<style scoped>

</style>
