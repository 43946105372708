<template>
  <div>
    <modal :show.sync="modal.show" size="lg" style="border-radius: 10px;">
      <template slot="header">
        <img height="23px" src="/img/icons/icons8/ios/new-post--v1.png" class="mr-2" />
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <div class="row mt-n3 mx-1">
          <div class="col-12">
            <div class="d-flex align-items-center">
              <img height="23px" src="/img/icons/icons8/ios/administrative-tools_success.png" class="mr-3" />
              <b class="text-dark mr-3">Configuração de texto</b>
              <hr class="bg-dark flex-grow-1 ml-2">
            </div>
          </div>
        </div>
        <form v-if="data && data[current_model]" autocomplete="off">
          <div class="form-group row m-0 p-0 col-md-3">
            <div class="col-md-12">
              <div class="pb-0 mb-1 col-form-label form-control-label">
                Modelo
              </div>
              <base-input autocomplete="off">
                <puzl-select
                  v-model="current_model"
                  :items="models"
                  :clearable=false
                />
              </base-input>
            </div>
          </div>
          <div class="form-group row m-0 p-0">
            <div class="col-md-12">
              <div class="pb-0 mb-1 col-form-label form-control-label">
                Assunto (Prefixo)
              </div>
              <base-input input-group-classes="input-group">
                <input
                  type="text"
                  class="form-control form-control-sm col-md-9"
                  v-model="data[current_model].subject_prefix"
                />
                <input
                  placeholder="- EMPRESA | FATURA 123"
                  type="text"
                  class="form-control form-control-sm col-md-3"
                  :disabled="true"
                />
              </base-input>
            </div>
          </div>
          <div class="form-group row m-0 p-0 mt-2">
            <div class="col-md-12">
              <div class="row mt-3 mb-2 align-items-center">
                <div class="col-12 col-md-6">
                  <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                    Mensagem
                  </div>
                </div>
                <div class="col-12 col-md-6 d-flex justify-content-end">
                  <button
                    style="margin: 0;width: 190px; height: 50px; justify-content: center; height: 25px;display: flex; align-items: center;"
                    class="btn btn-outline-primary dropdown-toggle text-uppercase colorize-btn-img"
                    @click.prevent="setDefaultParams()"
                  >
                    Padrão
                  </button>
                </div>
              </div>
              <base-input v-if="$_mentions[current_model] && $_mentions[current_model].mentions" input-group-classes="input-group">
                <div class="col-12 col-md-12 m-0 p-0">
                  <mention
                    style="font-size: 2rem !important;"
                    :default_comment="data[current_model].body"
                    :items="Object.values($_mentions[current_model].mentions)"
                    v-model.lazy="data[current_model].body"
                    rows="12"
                  />
                </div>
              </base-input>
            </div>
          </div>
          <div class="form-group row mx-0 my-3 p-0">
            <div class="col-md-12">
              <div class="pb-0 mb-1 col-form-label form-control-label">
                <img height="20px" src="/img/icons/icons8/ios/error--v1_danger.png" class="mb-1 mr-2" />
                As assinaturas e definição do e-mail de envio poderão ser feitos por central e por CNPJ EMISSOR.
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="col-md-3 px-1">
              <base-button
                block
                type="danger"
                size="md"
                style="border-radius: 4px !important;"
                @click.prevent="closeModal()"
              >
                <img class="mr-2" src="/img/icons/icons8/ios/cancel.png" width="19px">
                Cancelar
              </base-button>
            </div>
            <div class="col-md-3 px-1">
              <base-button
                @click.prevent="storeOrUpdate()"
                :loading="isLoading"
                block
                type="success"
                size="md"
                style="border-radius: 4px !important;"
              >
                <img class="mr-2" src="/img/icons/save.png" width="19px">
                Salvar
              </base-button>
            </div>
          </div>
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
const {formatErrorValidation} = require("@/plugins")
import PuzlSelect from "@/components/PuzlSelect";
import Mention from "@/components/Inputs/Mention";

const model_enum = {
  invoice: 0,
  bill: 1,
  contract: 2,
  reminder: 3,
};

export default {
  name: "ModalCompanyEmailParam",
  components: {
    PuzlSelect,
    Mention,
  },
  data() {
    return {
      modal: {
        title: "E-MAIL",
        show: false,
      },
      current_model: 0,
      model_enum: model_enum,
      models: [
        {id: model_enum.invoice, name: 'Fatura'},
        {id: model_enum.bill, name: 'Cobrança'},
        {id: model_enum.contract, name: 'Contrato'},
        {id: model_enum.reminder, name: 'Lembrete'},
      ],
      data: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      $_mentions: "companyEmailParam/getListMentions",
    }),
  },
  mounted(){
    this.$store.dispatch(`companyEmailParam/getMentions`);
  },
  methods: {
    handleOpenModal(companyId){
      this.current_model = 0;
      this.$Progress.start();
      let loader = this.$loading.show();
      const params = {filter: {company_id: companyId}};
      this.$store
        .dispatch("companyEmailParam/fetchItems", params)
        .then((response) => {
          // Adicione modelos ausentes
          this.data = response.data;
          Object.values(this.model_enum).forEach(modelValue => {
            if (!this.data.some(item => item.model === modelValue)) {
              this.data.push({
                id: null,
                company_id: companyId,
                model: modelValue,
                subject_prefix: "",
                body: "",
              });
            }
          });
          // Ordenar por modelos
          this.data.sort((a, b) => a.model - b.model);
          this.$notify({type: response.error_type, message: response.message});
        })
        .catch(error => {
          const errors = error.status
            ? error.data.message
            : formatErrorValidation(error.response.data.errors);
          this.$notify({ type: "danger", message: errors });
        }).finally(() => {
          this.$Progress.finish();
          loader.hide();
        });

      this.data = {};
      this.modal.show = true;
    },
    closeModal(){
      this.modal.show = false;
    },
    setDefaultParams(){
      const keepGoing = (this.current_model >= 0) && this.$_mentions && this.$_mentions[this.current_model];
      if (!keepGoing) {
        return;
      }
      this.data[this.current_model].body = this.$_mentions[this.current_model].default;
    },
    storeOrUpdate() {
      this.isLoading = true;
      this.$Progress.start();
      this.$notify({type: "info", message: "Estamos trabalhando em sua solicitação."});
      this.$store
        .dispatch('companyEmailParam/storeOrUpdate', this.data)
        .then(({ error_type, message }) => {
          this.$notify({type: error_type, message});
          this.closeModal();
        })
        .catch(error => {
          const errors = error.status
            ? error.data.message
            : formatErrorValidation(error.response.data.errors);
          this.$notify({ type: "danger", message: errors });
        }).finally(() => {
          this.$Progress.finish();
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.btn.dropdown-toggle::after {
  content: none;
}
</style>
