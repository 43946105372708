import * as types from "./mutation_types";
import { changeScatterChart, changeTreemapChart, calcTreemapItems } from "./charts";
import { createAxios } from "@/plugins/axios";;
const endPoint = "/commercial/dashboard/+microservice";
export default {
  namespaced: true,
  state: {
    cards: {},
    scatterChartData: {},
    treemapChartData: {},
    treemapItems: [],
    scatterItems: [],
    loading: true,
    loadingTreemap: false
  },
  getters: {
    loading: state => state.loading,
    loadingTreemap: state => state.loadingTreemap,
    cards: state => state.cards,
    scatter: state => state.scatterChartData,
    treemap: state => state.treemapChartData
  },
  mutations: {
    [types.SET_SCATTER](state, payload) {
      state.scatterItems = Number.isInteger(payload) ? state.scatterItems : payload;
      state.scatterChartData = changeScatterChart(payload, state.scatterItems);
    },
    [types.SET_TREEMAP](state, payload) {
      state.treemapItems = Number.isInteger(payload) ? state.treemapItems : calcTreemapItems(payload);
      state.treemapChartData = changeTreemapChart(payload, state.treemapItems);
    },
    [types.SET_LOADING](state, payload) {
      state.loading = payload;
    },
    [types.SET_TREEMAP_LOADING](state, payload) {
      state.loadingTreemap = payload;
    },
    [types.SET_CARDS](state, payload) {
      state.cards = payload;
    },
    [types.SET_SERVICE](state, payload) {
      state.cards.service = payload;
    }
  },
  actions: {
    fetchItems({ commit }, payload) {
      commit(types.SET_LOADING, true);
      return createAxios()
        .get(endPoint, { params: { filters: payload } })
        .then(({ data }) => {
          commit(types.SET_SCATTER, data.data.items.scatter);
          commit(types.SET_CARDS, data.data.cards);
          commit(types.SET_LOADING, false);
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    getTreemapItems({ commit }, payload) {
      commit(types.SET_TREEMAP_LOADING, true);
      return createAxios()
        .get(`${endPoint}treemap`, { params: { filters: payload } })
        .then(({ data }) => {
          commit(types.SET_TREEMAP, data.data);
          commit(types.SET_TREEMAP_LOADING, false);
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    getServices({ commit }, payload) {
      return createAxios()
        .get(`${endPoint}services`, { params: { filters: payload } })
        .then(({ data }) => {
          commit(types.SET_SERVICE, data.data);
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    changeTreemapChartData({ commit }, payload) {
      commit(types.SET_TREEMAP, payload);
    },
    changeScatterChartData({ commit }, payload) {
      commit(types.SET_SCATTER, payload);
    }
  }
};
