<template>
    <div>
        <modal class="modal-scroll-y" size="md" :show.sync="modal">
            <!-- Botões iniciais -->
            <div class="row p-2">
                <div class="col-6 d-flex justify-content-center mt-1">
                    <base-button @click.prevent="type = 0" class="btn-block" size="md" :class="type === 0 ? 'active' : ''" outline type="primary">
                        Resumo
                    </base-button>
                </div>
                <div class="col-6 d-flex justify-content-center mt-1">
                    <base-button @click.prevent="type = 1" class="btn-block" size="md" :class="type === 1 ? 'active' : ''" outline type="primary">
                        Ciclo
                    </base-button>
                </div>
            </div>

            <!-- Resumo -->
            <div class="p-2 mb-2 mt-0" v-show="type === 0">
                <div v-if="resume.number" class="row mb-2">
                    <div class="col-6 d-flex justify-content-center">
                        <div @click.prevent="downloadProof(resume.uuid)" role="button" class="row m-0">
                            <small class="text-primary col-2 p-0">
                                <img
                                    width="55px"
                                    src="/img/icons/os.png"
                                />
                            </small>
                            <div class="col-10 pl-4">
                                <span class="mb-0 h4 font-weight-bold">nº <span class="text-primary h2">{{resume.number}} </span></span>
                                <h5 class="mb-0 font-weight-normal">{{ resume.number_created_at | parseDate }}</h5>
                            </div>
                        </div>
                    </div>
                    <div v-if="resume.xml" @click.prevent="downloadDanfe(resume.xml)" role="button" class="col-6 d-flex justify-content-center">
                        <div class="row">
                            <small class="text-primary col-2 p-0 mt-2">
                                <img
                                    width="40px"
                                    src="https://cdn1.iconfinder.com/data/icons/unigrid-bluetone-file-extensions-vol-2/60/039_078_file_extension_nfe_invoice-512.png"
                                />
                            </small>
                            <div class="col-10">
                                <span class="mb-0 h4 font-weight-bold">nº <span class="text-info h2">{{resume.xml.number}} </span></span>
                                <h5 class="mb-0 font-weight-normal">{{resume.finished_at | parseDate}}</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="m-0" v-show="resume.number">
                <!-- Status da viagem -->
                <div class="col-12 mt-3">
                    <h3 :class="classSituation[resume.status]">
                        {{ textSituation[resume.status] }}
                    </h3>
                </div>
                <!-- inicio de detalhes -->
                <div v-if="resume.contract_proposal" class="col-12 border-left border-3 p-2 border-danger mt-2">
                    <div class="col-12 pl-1">
                        <h5 class="h4 mb-0">
                            Cód. Contrato:
                            <span class="font-weight-normal">{{resume.contract_proposal.code}}</span>
                        </h5>
                    </div>
                    <div class="col-12 pl-1">
                        <h5 class="h4 mb-0">
                            Obra:
                            <span class="font-weight-normal">{{resume.contract_proposal.construction_name}}</span>
                        </h5>
                    </div>
                    <div class="col-12 pl-1">
                        <h5 class="h4 mb-0">
                            Cliente:
                            <span class="font-weight-normal">{{resume.contract_proposal.customer_name}}</span>
                        </h5>
                    </div>
                </div>

                <!-- Fromulação -->
                <div v-if="resume.contract_proposal_formulation" class="mt-3">
                    <div class="border-left border-3 border-primary p-2">
                        <div class="row" v-for="(feature) in resume.contract_proposal_formulation.features" :key="feature.id">
                            <div class="col-md-12">
                                <h5 class="h4 mb-0">
                                    {{ feature.feature.short_description }}
                                    {{ format_mixe(feature.feature.period, feature.feature.period_type) }}
                                    {{ feature.description }}
                                    {{ feature.feature.measured_unit.letter }}
                                </h5>
                            </div>
                        </div>
                        <div class="mt-2 mb-3">
                            <h5 class="small">
                                {{ resume.contract_proposal_formulation.type.technical_description }}
                                |
                                {{resume.contract_proposal_formulation.subtype? resume.contract_proposal_formulation.subtype.technical_description + "|": ""}}
                                {{ resume.contract_proposal_formulation.slump_flow.nomenclature }}
                                |
                                {{ resume.contract_proposal_formulation.maximum_size.description }}
                            </h5>
                        </div>
                    </div>
                </div>
                <div class="mt-2 border-left border-3 border-indigo p-2" v-if="resume.contract_proposal_formulation && resume.contract_proposal_formulation.puzl_additions.length">
                    <div v-for="(puzl_addition, index) in resume.contract_proposal_formulation.puzl_additions" :key="puzl_addition.id">
                        <h5 class="text-indigo mb-0">
                            {{ puzl_addition.name }}
                            ({{ puzl_addition.volume }} <span style="text-transform: none">kg/m</span><sup>3</sup>)
                            <span class="text-indigo" v-if="index != resume.contract_proposal_formulation.puzl_additions.length - 1">+</span>
                        </h5>
                    </div>
                </div>

                <!-- Peça -->
                <div v-if="resume.piece" class="col-12 border-left border-3 p-2 border-success mt-2">
                    <div class="col-12 pl-1">
                        <h5 class="h4 mb-0">
                            Peça:
                            <span class="font-weight-normal">{{resume.piece.description}}</span>
                        </h5>
                    </div>
                    <div class="col-12 pl-1">
                        <h5 class="h4 mb-0">
                            Identificação:
                            <span class="font-weight-normal">{{resume.piece.identification}}</span>
                        </h5>
                    </div>
                </div>

                <!-- Volumes -->
                <div v-if="resume.schedule_statistics" class="col-12 border-left border-3 p-2 border-primary mt-2">
                    <div class="col-12 pl-1">
                        <h5 class="h4 mb-0 text-success">
                            Volume entregue:
                            <span class="font-weight-normal">
                                {{resume.schedule_statistics.volume_performed}} <span style="text-transform: none">m</span><sup>3</sup>
                                {{ "(" + resume.schedule_statistics.travel_performed + ")" }}
                            </span>
                        </h5>
                    </div>
                    <div class="col-12 pl-1">
                        <h5 class="h4 mb-0">
                            Volume previsto:
                            <span class="font-weight-normal">
                                {{resume.schedule_statistics.total_volume}} <span style="text-transform: none">m</span><sup>3</sup>
                                {{ "(" + resume.schedule_statistics.total_travels + ")" }}
                            </span>
                        </h5>
                    </div>
                </div>

                <!-- Endereço -->
                <div v-if="resume.address_construction" class="col-12 p-2 mt-3">
                    <div class="row align-items-center mb-3 card-body-molde">
                        <div class="col-12">
                            <div class="border-header-success-bottom p-1">
                                <h5 class="h3 m-0">Endereço</h5>
                            </div>
                        </div>
                    </div>
                    <span class="h4 ">
                        {{ resume.address_construction.address || '' }},
                        {{ resume.address_construction.number || '' }},
                        {{ resume.address_construction.complement ? resume.address_construction.complement +',' : '' }}
                        {{ resume.address_construction.district || '' }}
                        <br>
                        {{ resume.address_construction.city || '' }}/
                        {{ resume.address_construction.state || '' }}
                    </span>
                </div>

                <!-- Pendências -->
                <div v-if="resume.pendencies && resume.pendencies.length" class="col-12 p-2 mt-2">
                    <div class="row align-items-center mb-3 card-body-molde">
                        <div class="col-12">
                            <div class="border-header-warning-bottom p-1">
                                <h5 class="h3 m-0">Pendências</h5>
                            </div>
                        </div>
                    </div>
                    <h4 v-for="(pendencie, index) in  resume.pendencies" :key="index" class="mb-0">
                        {{pendencie}}
                    </h4>
                </div>
            </div>

            <div class="p-2 mb-2" v-show="type === 1">
                <div class="table-responsive">
                    <table class="table table-sm text-sm">
                        <thead>
                            <tr class="text-center font-weight-bold">
                                <th>&nbsp;</th>
                                <th style="letter-spacing: 0.5px !important" class="text-sm text-capitalize font-weight-bold">Previsto</th>
                                <th style="letter-spacing: 0.5px !important" class="text-success text-sm text-capitalize font-weight-bold">Realizado</th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- Tempo de carregamento -->
                            <tr>
                                <td class="text-left pb-0">Tempo de carregamento</td>
                                <td class="text-center pb-0">
                                    {{ predictedCycle.load_truck_diff }}
                                </td>
                                <td class="text-center pb-0">
                                    {{ provideTimes.load_truck_diff }}
                                </td>
                            </tr>
                            <!-- Ciclo de viagem -->
                            <tr>
                                <td class="text-left pb-0">Ciclo de viagem</td>
                                <td class="text-center pb-0">
                                    {{ predictedCycle.travel_cycle }}
                                </td>
                                <td class="text-center pb-0">
                                    {{ provideTimes.travel_cycle }}
                                </td>
                            </tr>
                            <!-- Espera p/ iniciar descarga -->
                            <tr>
                                <td class="text-left pb-0">Espera p/ iniciar descarga</td>
                                <td class="text-center pb-0">N/A</td>
                                <td class="text-center pb-0">
                                    {{ provideTimes.wait_start_discharge }}
                                </td>
                            </tr>
                            <!-- Tempo de descarga -->
                            <tr>
                                <td class="text-left pb-0">Tempo de descarga</td>
                                <td class="text-center pb-0">N/A</td>
                                <td class="text-center pb-0">
                                    {{ provideTimes.discharge_time }}
                                </td>
                            </tr>
                            <!-- Ciclo de Concreto -->
                            <tr>
                                <td class="text-left pb-0">Ciclo de Concreto</td>
                                <td class="text-center pb-0">
                                    {{ predictedCycle.concrete_cycle }}
                                </td>
                                <td class="text-center pb-0">
                                    {{ provideTimes.concrete_cycle }}
                                </td>
                            </tr>
                            <!-- Permanência em obra -->
                            <tr>
                                <td class="text-left pb-0">Permanência em obra</td>
                                <td class="text-center pb-0">
                                    {{ predictedCycle.construction_stay_time }}
                                </td>
                                <td class="text-center pb-0">
                                    {{ provideTimes.construction_stay_time }}
                                </td>
                            </tr>
                            <!-- Lastro -->
                            <tr>
                                <td class="text-left pb-0">Lastro</td>
                                <td class="text-center pb-0">
                                    {{ predictedCycle.ballast }}
                                </td>
                                <td class="text-center pb-0">
                                    {{ provideTimes.ballast }}
                                </td>
                            </tr>
                            <!-- Trajeto (ida)" -->
                            <tr>
                                <td class="text-left pb-0">Trajeto (ida)</td>
                                <td class="text-center pb-0">
                                    {{ predictedCycle.send_route_time }}
                                </td>
                                <td class="text-center pb-0">
                                    {{ provideTimes.send_route_time }}
                                </td>
                            </tr>
                            <!-- Trajeto (retorno) -->
                            <tr>
                                <td class="text-left pb-0">Trajeto (retorno)</td>
                                <td class="text-center pb-0">
                                    {{ predictedCycle.return_route_time }}
                                </td>
                                <td class="text-center pb-0">
                                    {{ provideTimes.return_route_time }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row p-2">
                <div class="col-md-6 d-flex justify-content-center mt-2">
                    <base-button class=" btn-block" type="secondary" @click="closeModal()">
                        Fechar
                    </base-button>
                </div>
                <div class="col-md-6 d-flex justify-content-center mt-2">
                    <base-button class=" btn-block" @click.prevent="chargeTravel" type="indigo">
                        Carregamento
                    </base-button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import {base_url_ms} from '@/plugins'
import {
    period_types
} from "@/views/Modules/Technology/Mix/helpers/mixe";

export default {
    components: {},
    name: "ModalResumeTravel",
    data() {
        return {
            format_mixe: period_types,
            modal: false,
            type: 0,
            resume: {},
            provideTimes: {
                load_truck_diff: "00:00",
                travel_cycle: "00:00",
                wait_start_discharge: "00:00",
                discharge_time: "00:00",
                concrete_cycle: "00:00",
                construction_stay_time: "00:00",
                ballast: "00:00",
                send_route_time: "00:00",
                return_route_time: "00:00",
            },
            classSituation: {
                0: "text-success-light",
                1: "text-primary",
                2: "text-indigo",
                3: "text-indigo",
                4: "text-warning",
                5: "text-info",
                6: "text-danger",
                7: "text-danger",
                8: "text-warning"
            },
            textSituation: {
                0: "Liberado",
                1: "Concluído",
                2: "Carregando",
                3: "Em edição",
                4: "Aguardando liberação do cliente",
                5: "Em andamento",
                6: "Cancelado",
                7: "Não realizado",
                8: "Pendência"
            },
            predictedCycle: {
                load_truck_diff: "00:00",
                travel_cycle: "00:00",
                wait_start_discharge: "00:00",
                discharge_time: "00:00",
                concrete_cycle: "00:00",
                construction_stay_time: "00:00",
                ballast: "00:00",
                send_route_time: "00:00",
                return_route_time: "00:00",
            },
            base_url_ms: base_url_ms(),
        };
    },
    methods: {
        downloadProof(schedule_travel_uuid) {
            let params = {
                uuid: schedule_travel_uuid,
                type: "proof"
            };
            let loader = this.$loading.show();
            this.$store.dispatch("travels/download", params)
            .then(response => {
                let blob = new Blob([response], {type: "application/pdf"});
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.setAttribute("download", "custom_name.pdf"); // Added Line
                window.open(link, "_blank");
                loader.hide();
            })
            .catch(error => {
                loader.hide();
            });
        },
        downloadDanfe(xml) {
            const url = this.base_url_ms+'download-s3?url='+xml.pdf_file_path
            let link = document.createElement('a')
            link.setAttribute("download", `DANFE ${xml.number}.pdf`);
            window.open(url)
            this.$notify({type: 'success', message: 'Arquivo exportado com sucesso!'})
        },
        closeModal() {
            this.modal = false;
        },
        /**
         * Abre o modal com os dados iniciais
         *
         * @param {integer} id
         *
         * @return {void}
         */
        openModal(id) {
            this.$Progress.start();
            this.provideTimesReset();
            this.$store.dispatch("travels/getResume", id).then((response) => {
                this.resume = response.data
                this.$store.dispatch("travels/show", { uuid: this.resume.uuid, code: "1" }).then(response => {
                    this.resume.cycle = response.data.cycle ? response.data.cycle : this.resume.cycle
                    if(!this.resume.cycle.start_load_truck_time && response.data.accomplished_start_load_truck_time) {
                        this.resume.cycle.load_truck_time = response.data.accomplished_start_load_truck_time
                    }
                    if(!this.resume.cycle.end_load_truck_time && response.data.accomplished_end_load_truck_time) {
                        this.resume.cycle.end_load_truck_time = response.data.accomplished_end_load_truck_time
                    }
                    this.calcDates();
                })
                this.predictedCycle = this.resume.predicted_cycle
                this.$Progress.finish();
                this.$notify({
                    type: response.error_type,
                    message: response.message,
                });
                this.modal = true;
            });
        },

        /**
         * Reseta as datas realizadas
         *
         * @return {void}
         */
        provideTimesReset() {
            this.provideTimes = {
                load_truck_diff: "00:00",
                travel_cycle: "00:00",
                wait_start_discharge: "00:00",
                discharge_time: "00:00",
                concrete_cycle: "00:00",
                construction_stay_time: "00:00",
                ballast: "00:00",
                send_route_time: "00:00",
                return_route_time: "00:00"
            };
        },

        /**
         * Leva o usuário para a tela de carregamento
         *
         * @return {void}
         */

        chargeTravel() {
            this.$router.push({
                path: "/operational/schedule/charging/" + this.resume.uuid
            });
        },

        /**
         * Calcula as datas gerais do ciclo
         *
         * @return {void|boolean}
         */
        calcDates() {
            if (!this.resume.cycle) {
                return false
            }

            this.provideTimes.construction_stay_time = this.dateDiff(
                this.resume.cycle.arrival_time,
                this.resume.cycle.construction_exit_time
            );

            this.provideTimes.wait_start_discharge = this.dateDiff(
                this.resume.cycle.arrival_time,
                this.resume.cycle.start_discharge_time
            );

            this.provideTimes.send_route_time = this.dateDiff(
                this.resume.cycle.plant_exit_time,
                this.resume.cycle.arrival_time
            );

            if (
                this.resume.cycle.end_load_truck_time &&
                this.resume.cycle.load_truck_time.slice(0, -2) !==
                this.resume.cycle.end_load_truck_time.slice(0, -2)
            ) {
                this.provideTimes.load_truck_diff = this.dateDiff(
                    this.resume.cycle.load_truck_time,
                    this.resume.cycle.end_load_truck_time
                );
            } else {
                this.provideTimes.load_truck_diff = "0 min";
            }

            this.provideTimes.travel_cycle = this.dateDiff(
                this.resume.cycle.load_truck_time,
                this.resume.cycle.free_for_travel
            );

            this.provideTimes.concrete_cycle = this.dateDiff(
                this.resume.cycle.load_truck_time,
                this.resume.cycle.end_discharge_time
            );

            this.provideTimes.ballast = this.dateDiff(
                this.resume.cycle.plant_arrival_time,
                this.resume.cycle.free_for_travel
            );

            this.provideTimes.return_route_time = this.dateDiff(
                this.resume.cycle.construction_exit_time,
                this.resume.cycle.plant_arrival_time
            );
            this.provideTimes.discharge_time = this.dateDiff(
                this.resume.cycle.start_discharge_time,
                this.resume.cycle.end_discharge_time
            );
        },

        /**
         * Compara duas datas
         *
         * @return {string}
         */
        dateDiff(first, second) {
            if(first && first.length > 18 && second && second.length > 18) {
                return this.$helper.dateDiff(first, second);
            }
            return '00:00';
        },
    }
};
</script>

<style scoped>
.text-success-light {
  color: #68b879 !important;
}
</style>
