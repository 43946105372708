import store from "../../../../shared/libraries/store";
import { operationSourceStoreStateKeys } from "./operationSourceStoreStateKeys";
import {
  OperationSourceListFilterType,
  OperationSourceListPagerType,
  OperationSourceViewType,
} from "../types";
import { SearchBarFilterType } from "../../../../components/AppGlobal";

/**
 * @param {OperationSourceViewType} item
 * @returns {OperationSourceViewType}
 */
const setItem = (item) => store.commit(operationSourceStoreStateKeys.ITEM, item);

/**
 * @param {OperationSourceListPagerType} listed
 * @returns {OperationSourceListPagerType}
 */
const setListed = (listed) =>
  store.commit(operationSourceStoreStateKeys.LISTED, listed);

/**
 * @param {boolean} isListing
 * @returns {boolean}
 */
const setIsListing = (isListing) =>
  store.commit(operationSourceStoreStateKeys.IS_LISTING, isListing);

/**
 * @param {boolean} isFinding
 * @returns {boolean}
 */
const setIsFinding = (isFinding) =>
  store.commit(operationSourceStoreStateKeys.IS_FINDING, isFinding);

/**
 * @param {boolean} isSaving
 * @returns {boolean}
 */
const setIsSaving = (isSaving) =>
  store.commit(operationSourceStoreStateKeys.IS_SAVING, isSaving);

/**
 * @param {boolean} isRemoving
 * @returns {boolean}
 */
const setIsRemoving = (isRemoving) =>
  store.commit(operationSourceStoreStateKeys.IS_REMOVING, isRemoving);

/**
 * @param {OperationSourceViewType} created
 * @returns {OperationSourceViewType}
 */
const setNewItemInList = (created) =>
  store.commitNewItemToList(operationSourceStoreStateKeys, created);

/**
 * @param {OperationSourceListPagerType} listed
 * @returns {OperationSourceListPagerType}
 */
const setListMerge = (listed) =>
  store.commitListMerge(operationSourceStoreStateKeys, listed);

/**
 * @param {number|string} id
 */
const setRemovedItem = (id) =>
  store.commitRemovedItem(operationSourceStoreStateKeys, id);

/**
 * @param {OperationSourceViewType} updated
 * @returns {OperationSourceViewType}
 */
const setUpdatedItem = (updated) =>
  store.commitUpdatedItem(operationSourceStoreStateKeys, updated);

/**
 * @param {{
 *   searchBarFilter: SearchBarFilterType,
 *   filter: OperationSourceListFilterType
 * }} filters
 */
const setPageState = (filters) =>
  store.commit(operationSourceStoreStateKeys.PAGE_STATES, filters);

/** Resetar chaves de estado */
const resetStates = () => {
  store.resetStates(Object.values(operationSourceStoreStateKeys));
};

export default {
  setItem,
  setListed,
  setIsListing,
  setIsFinding,
  setIsSaving,
  setIsRemoving,
  setNewItemInList,
  setListMerge,
  setRemovedItem,
  setUpdatedItem,
  setPageState,
  resetStates,
};
