<template>
  <div>
  <base-header class="bg-gray-content">
    <puzl-breadcrumb/>
  </base-header>
  <list/>
  </div>
</template>
<script>
import List from './Shared/_List'
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";

export default {
  components: {
    List,
    PuzlBreadcrumb,
  },
  name: "Index"
}
</script>

<style scoped>

</style>
