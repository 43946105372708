<template>
  <div>
    <div class="container-fluid">
      <div class="row card-wrapper mt-5">
        <div class="col-lg-4 col-md-6">
          <div class="card main-card mb-3">
            <div class="p-3 px-4">
              <div class="row">
                <div
                  class="col-10 d-flex justify-content-start align-items-center"
                >
                  <!-- <div class="mr-2">
                    <label
                      class="app-badge"
                      :class="{
                        'green-backgroud-light': item.active,
                        'red-backgroud-light': !item.active,
                      }"
                      style="width: 130px"
                    >
                      <input
                        type="checkbox"
                        v-model="item.active"

                      />
                      <span
                        style="gap: 4px"
                        class="d-flex justify-content-center align-items-center"
                        :class="{
                          'green-text-badge': item.active,
                          'red-text-badge': !item.active,
                        }"
                      >
                        <img
                          :src="
                            item.active
                              ? '/img/icons/ok--v1.png'
                              : '/img/icons/icons8/ios/cancel_danger.png'
                          "
                          alt="..."
                          width="14px"
                        />
                        item.active
                      </span>
                    </label>
                  </div> -->
                  <div class="mt-n2 mr-2">
                    <el-popover
                      class="p-0 pr-0 mr-1"
                      placement="right"
                      trigger="click"
                    >
                      <div class="pt-2 pl-3 pr-2 pb-4">
                        <span class="text-left text-dark">
                          <strong>Criação -</strong>
                          <!-- {{ item.created_at | parseDate("DD/MM/YYYY H:mm") }} | -->
                          <!-- {{ item.created_by_user_name }} -->
                            emilperezpiz1
                          </span
                        >
                        <br />
                        <span class="text-left text-dark"
                          ><strong>Última alteração -</strong>
                          emilperezpiz2
                          <!-- {{ item.created_at | parseDate("DD/MM/YYYY H:mm") }} | -->
                          <!-- {{ item.updated_by_user_name }} -->
                          </span
                        >
                      </div>
                      <base-button
                        slot="reference"
                        class="p-0 m-0 shadow-none"
                        outiline
                        size="sm"
                        type="secundary"
                      >
                        <img
                          src="/img/icons/icons8/ios/user-male-circle--v1.png"
                          width="24px"
                          height="24px"
                        />
                      </base-button>
                    </el-popover>
                  </div>
                </div>
                <div
                  class="col-2 d-flex justify-content-end align-items-center"
                >
                  <base-dropdown menuOnRight>
                    <div
                      slot="title-container"
                      class="dropdown-toggle rounded m-0"
                    >
                      <img
                        width="32"
                        src="/img/icons/icons8/ios/settings--v1_primary.png"
                      />
                    </div>
                    <a
                      class="dropdown-item text-dark"
                      style="align-items: center; display: flex"
                    >
                      <img width="18" src="/img/icons/edit.png" />
                      Editar
                    </a>
                    <div class="dropdown-divider p-0 m-0" />
                    <a
                      class="dropdown-item text-dark"
                      style="align-items: center; display: flex"
                    >
                      <img
                        width="18"
                        src="/img/icons/icons8/ios/add-primary.png"
                      />
                      Gerar checklist
                    </a>
                    <div class="dropdown-divider p-0 m-0" />
                    <a
                      class="dropdown-item text-dark"
                      style="align-items: center; display: flex"
                    >
                      <img
                        width="18"
                        src="/img/icons/icons8/ios/checklist-success.png"
                      />
                      Itens de checklist
                    </a>
                    <div class="dropdown-divider p-0 m-0" />
                    <a
                      class="dropdown-item text-dark"
                      style="align-items: center; display: flex"
                    >
                      <img width="18" src="/img/icons/delete.png" />
                      Excluir
                    </a>
                  </base-dropdown>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-10">
                  <h4
                    class="new-default-black-font m-0 p-0"
                    style="font-size: 14px"
                  >
                    item.name
                  </h4>
                  <h6
                    class="new-default-gray-font p-0 m-0 mb-2"
                    style="font-size: 13px"
                  >
                    item.equipment_category_name
                  </h6>
                </div>
                <div class="col-2 text-right"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import SkeletonPuzlFullWidth from "@/components/SkeletonPuzlFullWidth";

export default {
  name: "List",
  inheritAttrs: false,
  components: {
    SkeletonPuzlGrid,
    SkeletonPuzlFullWidth,
    PuzlEmptyData,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    //////
  },
  data() {
    return {};
  },
};
</script>
