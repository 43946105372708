<template>
  <div v-if="$_companyPlantIssuer">
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-md-4">
          <h2 style="font-size: 1.2rem !important; color: #606062">
            <img height="30px" src="/img/icons/icons8/ios/settings-3--v1_gray.png" class="mr-3"/>
            Configuração de CNPJ
          </h2>
        </div>
        <div class="col-md-8 pr-4">
          <div class="row d-flex justify-content-end">
            <div class="mb-md-0 mb-2 col-md-3 px-1">
              <router-link :to="{ path: '/configuration/plant/document/issuer-document' }">
                <base-button block size="md" type="light" class="pb-2 pt-2 text-uppercase">
                  <img width="19" src="/img/icons/circled-left.png" class="mr-1"/>
                  Voltar
                </base-button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <hr class="blue-divider mt-2 mb-4" />
    </base-header>

    <div class="container-fluid">
      <card>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(save)">
            <div class="row mb-3">
              <div class="col-12 col-md-6">
                <!-- CARD - IDENTIFICAÇÃO -->
                <div class="card p-4">
                  <div class="row">
                    <div class="col-10">
                      <h5 class="h3 new-default-black-font p-1 mb-0">
                        <img height="30px" src="/img/icons/icons8/ios/info-squared_warning.png" class="mr-3"/>
                        Identificação
                      </h5>
                    </div>
                    <div class="col-2">
                      <base-dropdown class="float-right">
                        <button
                          style="margin: 0;width: 95px; justify-content: center; height: 25px;display: flex; align-items: center;"
                          slot="title-container"
                          class="btn dropdown-toggle text-uppercase colorize-btn-img"
                          type="button"
                          :class="$_companyPlantIssuer.status ? 'btn-outline-success' : 'btn-outline-danger'"
                        >
                          <img class="mr-1" v-if="$_companyPlantIssuer.status" src="/img/icons/icons8/ios/thumb-up_success.png" width="16px">
                          <img class="mr-1" v-else src="/img/icons/icons8/ios/thumbs-down_danger.png" width="16px">
                          {{ $_companyPlantIssuer.status ? 'ATIVO' : 'INATIVO' }}
                        </button>
                        <a
                          @click.prevent="$_companyPlantIssuer.status = !$_companyPlantIssuer.status"
                          class="dropdown-item text-uppercase new-default-black-font"
                          style="font-size: 12px;"
                        >
                          {{ $_companyPlantIssuer.status ? 'INATIVO' : 'ATIVO' }}
                        </a>
                      </base-dropdown>
                    </div>
                  </div>
                  <hr class="new-default-black mt-2 mb-2">
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        CNPJ
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required|min:18|document">
                        <base-input group class="mb-3" autocomplete="off">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            aria-describedby="button-addon4"
                            v-model="$_companyPlantIssuer.ein"
                            v-mask="['##.###.###/####-##']"
                          />

                          <div class="input-group-append" id="button-addon4">
                            <button
                              :disabled="!$_companyPlantIssuer.ein || ($_companyPlantIssuer.ein && $_companyPlantIssuer.ein.length < 18)"
                              class="btn btn-outline-primary btn-sm form-control-sm"
                              type="button"
                              @click="getRecipeNet"
                            >
                              Pesquisar
                            </button>
                          </div>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Nome da Filial
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required">
                        <base-input group class="mb-3" autocomplete="off">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            @input="$_companyPlantIssuer.subsidiary_name = $_companyPlantIssuer.subsidiary_name.toUpperCase()"
                            v-model="$_companyPlantIssuer.subsidiary_name"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label" :disabled="true">
                        Nome Empresarial
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required">
                        <base-input group class="mb-3" autocomplete="off">
                        <textarea
                          class="form-control form-control-sm"
                          style="white-space: normal; word-wrap: break-word;"
                          disabled
                          v-model="companyPlantIssuerGroupName"
                        ></textarea>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        E-mail
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input group class="mb-3" autocomplete="off">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="$_companyPlantIssuer.email"
                        />
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Recebimento de E-mail
                        <el-popover
                          style="margin-top: 30px !important;"
                          trigger="click"
                          placement="top"
                          class="p-0 px-1"
                        >
                          Ao selecionar essa opção, o e-mail <br>
                          será copiado em todos os disparos<br>
                          aos clientes de faturas e NFS-E.
                          <span slot="reference"><img src="/img/icons/info.png" width="20px" height="20px"/></span>
                        </el-popover>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 d-flex justify-content-end">
                      <base-input group class="mb-0" autocomplete="off">
                        <base-switch
                          v-model="$_companyPlantIssuer.send_auto_mail"
                          type="primary"
                          offText="Não"
                          onText="Sim"
                        ></base-switch>
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Telefone
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input group class="mb-3" autocomplete="off">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          autocomplete="off"
                          v-model="$_companyPlantIssuer.phone"
                          v-mask="['(##) ####-####', '(##) #####-####']"
                        />
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        WHATSAPP API PHONE
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input group class="mb-3" autocomplete="off">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          autocomplete="off"
                          v-model="$_companyPlantIssuer.whatsapp_api_phone"
                        />
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Regime Tributário
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required">
                        <base-input autocomplete="off">
                          <puzl-select
                            v-model="$_companyPlantIssuer.tax_regime"
                            :items="taxRegimes"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Incrição Municipal
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input group class="mb-3" autocomplete="off">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="$_companyPlantIssuer.municipal_registration"
                        />
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Incrição Estadual
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required">
                        <base-input group class="mb-3" autocomplete="off">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="$_companyPlantIssuer.state_registration"
                            @input="$event.target.value = $_companyPlantIssuer.state_registration = $event.target.value.replace(/\./g, '')"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Código Totvs
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input group class="mb-3" autocomplete="off">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="$_companyPlantIssuer.totvs_code"
                        />
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Código SAP
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input group class="mb-3" autocomplete="off">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="$_companyPlantIssuer.sap_code"
                        />
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Código Externo
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input group class="mb-3" autocomplete="off">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="$_companyPlantIssuer.external_code"
                        />
                      </base-input>
                    </div>
                  </div>
                </div>
                <!-- CARD - ENDEREÇO -->
                <div class="card p-4">
                  <div class="row">
                    <div class="col-12">
                      <h5 class="h3 new-default-black-font p-1 mb-0">
                        <img height="30px" src="/img/icons/icons8/ios/order-delivered_primary.png" class="mr-3"/>
                        Endereço
                      </h5>
                    </div>
                  </div>
                  <hr class="new-default-black mt-2 mb-2">
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        CEP
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required">
                        <base-input
                          input-classes="form-control-sm"
                          autocomplete="off"
                          v-model="$_companyPlantIssuer.postal_code"
                          v-mask="['#####-###']"
                          v-on:blur="getAddressIssuerByCode"
                        />
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-3 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        UF
                      </div>
                    </div>
                    <div class="col-12 col-md-6 d-flex justify-content-end">
                      <base-input group class="mb-3" autocomplete="off">
                        <puzl-select
                          v-model="$_companyPlantIssuer.state"
                          :items="states"
                          customKey="letter"
                          :labelMask="true"
                          label="$letter$"
                          @change="getCities()"
                        />
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Município
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required">
                        <base-input autocomplete="off">
                          <puzl-select
                            v-model="$_companyPlantIssuer.city"
                            :items="cities"
                            customKey="title"
                            :labelMask="true"
                            label="$title$"/>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Endereço
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required">
                        <base-input group class="mb-3" autocomplete="off">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="$_companyPlantIssuer.address"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Número
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input group class="mb-3" autocomplete="off">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="$_companyPlantIssuer.number"
                        />
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Bairro
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider rules="required">
                        <base-input group class="mb-3" autocomplete="off">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="$_companyPlantIssuer.district"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Complemento
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input group class="mb-3" autocomplete="off">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="$_companyPlantIssuer.complement"
                        />
                      </base-input>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <!-- CARD - CERTIFICADO -->
                <div class="card p-4">
                  <div class="row">
                    <div class="col-12">
                      <h5 class="h3 new-default-black-font p-1 mb-0">
                        <img height="30px" src="/img/icons/icons8/ios/grand-master-key_success.png" class="mr-3"/>
                        Certificado Digital
                      </h5>
                    </div>
                  </div>
                  <hr class="new-default-black mt-2 mb-2">
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Certificado
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input group class="mb-3" autocomplete="off">
                      <input
                        type="file"
                        class="form-control-uniform"
                        multiple=""
                        v-on:change="handleFileUpload()"
                        id="file"
                        ref="file"
                        accept=".pfx, .p12"
                        data-fouc
                      />
                      <small id="emailHelp2" class="form-text text-muted">
                        Arquivos em formato:<span class="badge mr-1 ml-1" style="background-color: #d3d3d3">PFX</span>
                        <span class="badge" style="background-color: #d3d3d3">P12</span>
                      </small>
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Senha
                        <span v-show="certificate_file" class="text-danger">&nbsp;*</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <validation-provider :rules="certificate_file && 'required'">
                        <base-input group class="mb-3" autocomplete="off">
                          <input
                            type="password"
                            class="form-control form-control-sm"
                            aria-describedby="button-addon4"
                            v-model="certificate_password"
                            autocomplete="off"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                </div>

                <!-- CARD - EMISSAO DE BOLETOS -->
                <div class="card p-4">
                  <div class="row">
                    <div class="col-12">
                      <h5 class="h3 new-default-black-font p-1 mb-0">
                        <img height="30px" src="/img/icons/icons8/ios/barcode_warning.png" class="mr-3"/>
                        Emissão de Boletos
                      </h5>
                    </div>
                  </div>
                  <hr class="new-default-black mt-2 mb-2">
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Token de Emissão
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input group class="mb-3" autocomplete="off">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="$_companyPlantIssuer.bank_billet_api_token"
                        />
                      </base-input>
                    </div>
                  </div>
                </div>

                <!-- CARD - NF-E (DANFE)-->
                <div class="card p-4">
                  <div class="row">
                    <div class="col-12">
                      <h5 class="h3 new-default-black-font p-1 mb-0">
                        <img height="30px" src="/img/icons/nfe.png" class="mr-3"/>
                        NF-E (DANFE)
                      </h5>
                    </div>
                  </div>
                  <hr class="new-default-black mt-2 mb-2">
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Código de Benefício Fiscal
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input
                        input-classes="form-control-sm"
                        autocomplete="off"
                        maxlength="8"
                        v-model="$_companyPlantIssuer.tax_benefit_code"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="offset-md-4 col-md-8 mt-n1">
                <div class="row d-flex justify-content-end show-md">
                  <div class="col-md-3 px-1">
                    <base-button
                      block
                      type="danger"
                      @click.prevent="close()"
                      size="md"
                      style="border-radius: 4px !important;"
                    >
                      <img class="mr-2" src="/img/icons/icons8/ios/cancel.png" width="19px">
                      CANCELAR
                    </base-button>
                  </div>
                  <div class="col-md-3 px-1">
                    <base-button
                      native-type="submit"
                      v-bind:disabled="invalid"
                      :loading="isLoading"
                      block
                      type="success"
                      size="md"
                      style="border-radius: 4px !important;"
                    >
                      <img class="mr-2" src="/img/icons/save.png" width="19px">
                      SALVAR
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </validation-observer>
      </card>
    </div>
    <ModalIssuerGroup ref="modalIssuerGroup" @submitted="modalIssuerGroupSubmitted" />
  </div>
</template>

<script>
import PuzlSelect from "@/components/PuzlSelect";
import {mapGetters} from "vuex";
import {formatErrorValidation} from "@/plugins";
import ModalIssuerGroup from "./_ModalIssuerGroup.vue";

export default {
  name: "CreateIssuerDocument",
  components: {
    PuzlSelect,
    ModalIssuerGroup,
  },
  computed: {
    ...mapGetters({
      $_companyPlantIssuer: "companyPlantIssuer/show",
    }),
    companyPlantIssuerGroupName() {
      const { group_name, subsidiary_name } = this.$_companyPlantIssuer;
      if (group_name && subsidiary_name) {
        return `${group_name} | ${subsidiary_name}`;
      }
      if (group_name) {
        return group_name;
      }
      if (subsidiary_name) {
        return subsidiary_name;
      }
      return '';
    }
  },
  data(){
    return {
      companyPlantIssuerDefault: {
        status: true,
        certificate_password: null,
        ein: null,
        subsidiary_name: '',
        business_name: '',
        alias_name: '',
        email: null,
        phone: null,
        registration_situation: null,
        tax_regime: null,
        municipal_registration: null,
        contributing_number: null,
        state_registration: null,
        suframa_number: null,
        send_auto_mail: false,
        postal_code: null,
        state: null,
        address: null,
        number: null,
        complement: null,
        district: null,
        city: null,
        municipal_service_code: null,
        service_list_item: null,
        cnae: null,
        ibge_code: null,
        observation_nfse: null,
        nfse_operation_source_const_city_diff_issuer_puzl_default: true,
        nfse_operation_source_const_city_equal_issuer_puzl_default: true,
        nfse_without_municipal_registration: true,
        nfse_send_regime_metadata: false,
        nfse_rule1_equal_construction_default: false,
        nfse_rule1_within_city_iss_legal: true,
        nfse_rule1_within_city_iss_legal_regime_metadata: null,
        nfse_rule1_within_city_iss_individual: false,
        nfse_rule1_within_city_iss_individual_regime_metadata: null,
        nfse_rule1_outside_city_iss_legal: true,
        nfse_rule1_outside_city_iss_legal_regime_metadata: null,
        nfse_rule1_outside_city_iss_individual: false,
        nfse_rule1_outside_city_iss_individual_regime_metadata: null,
        nfse_rule1_within_city_esp: false,
        nfse_rule1_within_city_iss_legal_esp: false,
        nfse_rule1_within_city_iss_individual_esp: false,
        nfse_rule2_diff_construction_default: false,
        nfse_rule2_within_city_iss_legal: true,
        nfse_rule2_within_city_iss_legal_regime_metadata: null,
        nfse_rule2_within_city_iss_individual: false,
        nfse_rule2_within_city_iss_individual_regime_metadata: null,
        nfse_rule2_outside_city_iss_legal: true,
        nfse_rule2_outside_city_iss_legal_regime_metadata: null,
        nfse_rule2_outside_city_iss_individual: false,
        nfse_rule2_outside_city_iss_individual_regime_metadata: null,
        nfse_rule2_outside_city_esp: null,
        nfse_rule2_outside_city_iss_legal_esp: null,
        nfse_rule2_outside_city_iss_individual_esp: null,
        nfse_allow_manual_cancellation: false,
        nfse_load_construction_ibge_code: true,
        nfse_load_city_hall_construction_code: false,
        nfse_load_construction_address: false,
        nfse_load_calc_base_in_metadata: false,
        nfse_change_tag_regime_to_cst: false,
        nfse_send_iss_rate_when_construction_city_equal_issuer: true,
        nfse_replace_comma_with_dot: false,
        nfse_block_service_within_the_city: false,
        nfse_block_service_outside_the_city: false,
        totvs_code: null,
        sap_code: null,
        external_code: null,
      },
      certificate_password: null,
      certificate_file: null,
      taxRegimes: [
        {id: 'Lucro Real', name: 'Lucro Real'},
        {id: 'Lucro Presumido', name: 'Lucro Presumido'},
        {id: 'Simples Nacional', name: 'Simples Nacional'},
        {id: 'Mei', name: 'Mei'},
      ],
      states: [],
      cities: [],
      isLoading: false,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getStates();
      // Permitir voltar a esta página sem perder os dados no vuex
      if (this.$route.query['changing'] === 'true') {
        return;
      }
      // Usar vuex para manter os dados ao navegar entre páginas/rotas.
      this.$store.dispatch(
        "companyPlantIssuer/setItem",
        this.$helper.cloneObject(this.companyPlantIssuerDefault)
      );
    },
    getStates() {
      this.$Progress.start();
      this.$axios
        .get("/state")
        .then(response => {
          this.states = response.data.data;
          this.$Progress.finish();
        })
        .catch(error => {
          this.$Progress.finish();
        });
    },
    getCities() {
      this.$Progress.start();
      this.$axios
        .post("/city", {uf: this.$_companyPlantIssuer.state})
        .then(response => {
          this.cities = response.data.data;
          this.$Progress.finish();
        })
        .catch(error => {
          this.$Progress.finish();
        });
    },
    getRecipeNet() {
      this.$Progress.start();
      this.$notify({type: "info", message: "Estamos trabalhando em sua solicitação."});
      this.$axios
        .post("/recipenet/ein", this.$_companyPlantIssuer)
        .then(response => {
          this.$_companyPlantIssuer.ein = response.data.data.cnpj || "";
          this.$_companyPlantIssuer.alias_name = response.data.data.fantasia || "";
          this.$_companyPlantIssuer.business_name = response.data.data.nome || "";
          this.$_companyPlantIssuer.group_name = response.data.data.nome || "";
          this.$_companyPlantIssuer.email = response.data.data.email || "";
          this.$_companyPlantIssuer.phone = response.data.data.telefone || "";
          this.$_companyPlantIssuer.number = response.data.data.numero || '';
          this.$_companyPlantIssuer.registration_situation = response.data.data.situacao || "";
          this.$_companyPlantIssuer.postal_code = response.data.data.cep.replace(/[^\d]+/g, "") || "";
          this.getAddressIssuerByCode();
          this.$notify({type: "success", message: "Dados da empresa carregado com sucesso."});
          this.$Progress.finish();
        })
        .catch(error => {
          this.$notify({type: "warning", message: "Não foi encontrado empresas com este documento."});
          this.$Progress.finish();
        });
    },
    getAddressIssuerByCode() {
      this.$Progress.start();
      this.$notify({type: "info", message: "Estamos trabalhando em sua solicitação."});
      this.$axios
        .post("/recipenet/address", {postal_code: this.$_companyPlantIssuer.postal_code})
        .then(response => {
          this.$_companyPlantIssuer.state = response.data.data.uf;
          this.getCities(this.$_companyPlantIssuer.state);
          this.$_companyPlantIssuer.address = response.data.data.logradouro;
          this.$_companyPlantIssuer.state = response.data.data.uf;
          this.$_companyPlantIssuer.district = response.data.data.bairro;
          this.$_companyPlantIssuer.city = response.data.data.localidade;
          this.$_companyPlantIssuer.ibge_code = response.data.data.ibge;
          const needToSearchForIbgecode = !this.$_companyPlantIssuer.ibge_code || (this.$_companyPlantIssuer.ibge_code === 'undefined')
            && this.$_companyPlantIssuer.city && this.$_companyPlantIssuer.state;
          if (needToSearchForIbgecode) {
            this.checkIbgeCode();
          }
          this.$notify({type: "success", message: "Dados carregados com sucesso."});
          this.$Progress.finish();
        })
        .catch(error => {
          this.$Progress.finish();
        });
    },
    /**
     * Verificar se ibge_code foi preenchido.
     * Caso não esteja preenchido, tenta localizar ibgecode a partir de this.$_companyPlantIssuer.state e this.$_companyPlantIssuer.city
     */
    async checkIbgeCode() {
      if (this.$_companyPlantIssuer.state && this.$_companyPlantIssuer.city) {
        let loader = this.$loading.show();
        let params = {state: this.$_companyPlantIssuer.state, name: this.$_companyPlantIssuer.city}
        await this.$store.dispatch("city/showByStateAndCity", params)
          .then(response => {
            this.$_companyPlantIssuer.ibge_code = response.data.iso;
            this.$notify({type: response.error_type, message: response.message});
          }).catch((error) => {
            let errors = error.status
              ? error.data.message
              : formatErrorValidation(error.response.data.errors);
            this.$notify({type: "danger", message: errors});
          }).finally(() => {
            loader.hide();
          });
      }
    },
    handleFileUpload() {
      this.certificate_file = this.$refs.file.files[0];
    },
    close(){
      this.$router.push({path: '/configuration/plant/document/issuer-document'});
    },
    async save() {
      // Garantir que ibge_code seja salvo
      if (!this.$_companyPlantIssuer.ibge_code) {
        await this.checkIbgeCode();
        if (!this.$_companyPlantIssuer.ibge_code) {
          this.$notify({type: "danger", message: 'Não foi possível obter o código IBGE da Cidade.'});
          return;
        }
      }

      // Tratar campos
      this.$_companyPlantIssuer.send_auto_mail = Number(this.$_companyPlantIssuer.send_auto_mail);

      // Incluir registro
      this.$notify({ type: "info", message: "Estamos trabalhando em sua solicitação." });
      this.isLoading = true;
      this.$Progress.start();
      let loader = this.$loading.show();
      try {
        const response = await this.$store.dispatch('companyPlantIssuerGroup/getBySubsidiaryEin', this.$_companyPlantIssuer.ein);
        const hasCompanyPlantIssuerGroupId = response.data && response.data.id;
        if (!hasCompanyPlantIssuerGroupId && !this.$_companyPlantIssuer.company_plant_issuer_loaded) {
          this.$refs.modalIssuerGroup.handleCreate(this.$_companyPlantIssuer);
          return;
        }          
        this.$_companyPlantIssuer.company_plant_issuer_group_id = hasCompanyPlantIssuerGroupId;
        this.$_companyPlantIssuer.business_name = this.companyPlantIssuerGroupName;
        this.$_companyPlantIssuer.alias_name = this.companyPlantIssuerGroupName;
        const result = await this.$store.dispatch('companyPlantIssuer/add', {
          companyPlantIssuer: this.$_companyPlantIssuer,
          certificate_file: this.certificate_file,
        });
        this.$notify({ type: result.error_type, message: result.message });
        this.close();
      } catch (error) {
        const errors = error && error.response && error.response.status === 422
          ? formatErrorValidation(error.response.data.errors)
          : error.data.message;
        this.$notify({ type: "danger", message: errors });
      } finally {
        this.isLoading = false;
        this.$Progress.finish();
        loader.hide();
      }
    },
    modalIssuerGroupSubmitted(issuerGroupData){
      this.$_companyPlantIssuer.company_plant_issuer_group_id = null;
      this.$_companyPlantIssuer.company_plant_issuer_loaded = true;
      this.$_companyPlantIssuer.company_plant_issuer_group_name = issuerGroupData.name;
      this.$_companyPlantIssuer.company_plant_issuer_group_initial_ein = issuerGroupData.initial_ein;
      this.$_companyPlantIssuer.company_plant_issuer_group_tax_regime = issuerGroupData.tax_regime;
      this.$_companyPlantIssuer.company_plant_issuer_group_logo_extension = issuerGroupData.logo_extension;
      this.$_companyPlantIssuer.company_plant_issuer_group_logo_base64 = issuerGroupData.logo_base64;
      this.save();
    },
    openNfsePage() {
      this.$router.push({path: '/configuration/plant/document/issuer-document/nfse?create=true'});
    }
  }
};
</script>

<style scoped>
.installments-box {
  max-height: 230px;
  overflow: auto;
}
.new-default-gray {
  background: rgba(43, 45, 50, 0.05);
}

.mini-card {
  border: 1px solid #E8E8E8;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  border-top-left-radius: 0.8rem !important;
  border-bottom-left-radius: 0.8rem !important;
}

.blue-divider {
  background-color: #1b6eba;
  color: #1b6eba;
  height: 0.1px;
}

.rezise-input-group {
  font-size: 11px !important;
}

.colorize-btn-img:hover img, .show img {
  filter: brightness(0) invert(1);
}

.btn.dropdown-toggle::after {
  content: none;
}
</style>
