import store from "../../../store";

const SET = "store/set";
const GET = "store/get";
const CLEAR = "store/clear";

/**
 * Define um valor na store
 *
 * @param {String|Symbol} stateKey - A chave única para identificar o valor na store
 * @param {*} value - O valor a ser definido na store
 * @returns {*} O valor definido na store
 */
const commit = async (stateKey, value) => {
  store.dispatch(SET, { key: stateKey, value: value });
  return value;
};

/**
 * Obtém um valor do store
 *
 * @param {String|Symbol} stateKey - A chave única para identificar o valor na store
 * @returns {*} O valor definido na store associado à chave fornecida
 */
const getState = (stateKey) => {
  const value = store.getters[GET](stateKey);
  return value !== undefined ? value : null;
};

/**
 * Reseta o estado identificado por uma chave.
 *
 * @param {String|Symbol} stateKey - A chave que identifica o módulo cujo estado será resetado.
 * @returns {void}
 */
const resetState = (stateKey) => store.dispatch(CLEAR, stateKey);

/**
 * Reseta o estado de múltiplos módulos identificados por um array de chaves.
 *
 * @param {Array<Symbol>} stateKeys - Um array de chaves que identificam os módulos cujo estado será resetado.
 * @returns {void}
 */
const resetStates = (stateKeys) =>
  stateKeys.forEach((stateKey) => resetState(stateKey));

/**
 * Remove um item de uma lista armazenada na store, com base no seu ID.
 *
 * @param {Object} stateKeys - Um objeto contendo chaves e símbolos para identificar estado na store.
 * @param {string} id - O ID do item a ser removido.
 * @param {string} stateKeyListed - A chave do símbolo no objeto `stateKeys` que identifica a lista paginada.
 * @param {string} listedItems - O nome da propriedade que contém a lista de itens na store.
 * @returns {void}
 */
const commitRemovedItem = (stateKeys, id, stateKeyListed = "LISTED", listedItems = "items") => {
  const listed = getState(stateKeys[stateKeyListed]);
  if (listed?.[listedItems]) {
    const index = listed[listedItems].findIndex((item) => item.id === id);
    if (index !== -1) {
      listed[listedItems].splice(index, 1);
    }
  }
};

/**
 * Atualiza um item de uma lista armazenada na store, com base em updatedItem.
 *
 * @param {Object} stateKeys - Um objeto contendo chaves e símbolos para identificar estado na store.
 * @param {Object} updatedItem - O item a ser atualizado.
 * @param {string} stateKeyListed - A chave do símbolo no objeto `stateKeys` que identifica a lista paginada.
 * @param {string} listedItems - O nome da propriedade que contém a lista de itens na store.
 * @returns {any}
 */
const commitUpdatedItem = (stateKeys, updatedItem, stateKeyListed = "LISTED", listedItems = "items") => {
  const listed = getState(stateKeys[stateKeyListed]);
  if (listed?.[listedItems]) {
    const index = listed[listedItems].findIndex(
      (item) => item.id === updatedItem.id
    );
    if (index !== -1) {
      listed[listedItems].splice(index, 1, updatedItem);
    }
  }
  return updatedItem;
};

/**
 * Adiciona um novo item na lista armazenada na store.
 *
 * @param {Object} stateKeys - Um objeto contendo chaves e símbolos para identificar listas na store.
 * @param {Object} item - Os dados do novo item a serem incluídos na lista.
 * @param {string} stateKeyListed - A chave do símbolo no objeto `stateKeys` que identifica a lista paginada.
 * @param {string} symbolItem - A chave do símbolo no objeto `stateKeys` que identifica o item.
 * @param {string} listedItems - O nome da propriedade que contém a lista de itens na store.
 * @returns {any}
 */
const commitNewItemToList = (stateKeys, item, stateKeyListed = "LISTED", symbolItem = "ITEM", listedItems = "items") => {
  const listed = getState(stateKeys[stateKeyListed]);
  if (listed?.[listedItems]) {
    listed[listedItems].push(item);
  }
  return commit(stateKeys[symbolItem], item);
};

/**
 * Atualiza a lista armazenada na store, inserindo novos itens na lista existente.
 *
 * @param {Object} stateKeys - Um objeto contendo chaves e símbolos para identificar listas na store.
 * @param {Object} listedEntering - Os novos itens a serem inseridos na lista.
 * @param {string} stateKeyListed - A chave no objeto `stateKeys` que identifica a lista paginada.
 * @param {string} listedItems - O nome da propriedade que contém a lista de itens na store.
 * @returns {any} O resultado do commit na store.
 */
const commitListMerge = (stateKeys, listedEntering, stateKeyListed = "LISTED", listedItems = "items") => {
  const currentListed = getState(stateKeys[stateKeyListed]);
  listedEntering[listedItems].unshift(...currentListed[listedItems]);
  return commit(stateKeys[stateKeyListed], listedEntering);
};

export default {
  commit,
  getState,
  resetState,
  resetStates,
  commitRemovedItem,
  commitUpdatedItem,
  commitNewItemToList,
  commitListMerge,
};
