<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 mt-4 ml-2">
        <base-button
          @click.prevent="setViewType(2)"
          :class="filter.view_type === 2 && 'active'"
          class="ml-n1"
          size="sm"
          type="primary"
          style="border: 1px solid #c4baba"
          outline
        >
          <template>
            <img width="18" v-if="filter.view_type === 2" src="/img/icons/icons8/ios/parallel-tasks.png"/>
            <img width="18" v-else src="/img/icons/icons8/ios/parallel-tasks-blue.png"/>   GRUPO
          </template>
        </base-button>

        <base-button
          @click.prevent="setViewType(1)"
          size="sm"
          :class="filter.view_type === 1 && 'active'"
          type="primary"
          style="border: 1px solid #c4baba"
          outline
        >
          <template>
            <i style="width: 18px" class="fa-solid fa-puzzle-piece"></i> MCC
          </template>
        </base-button>
      </div>
    </div>
    <div >
      <multi-filter ref="multi-filter"
                    :showSlotDates="false"
                    :showSlotData="false"
                    :type="3"
                    :filter="filter"
                    @fetch="init"
      >
      </multi-filter>
      <div class="card">
        <div class="card-body mt-n2 p-0">
          <div class="row">
            <ShadedTable class="px-4 pt-2"
                         :length="$_stock_movements.length"
                         :headers="filter.view_type === 1 ? headers : filter.view_type === 2 ? group_headers : dosage_location_headers"
                         :loading="loadingSkeleton">
              <template v-for="(item, key) in $_stock_movements" :slot="key">
                <ShadedCol>
                  <div class="text-center">
                    <h5 class="m-0">
                      {{ item.company_plant_name }}
                    </h5>
                  </div>
                </ShadedCol>
                <ShadedCol>
                  <div class="text-center">
                    <h5 class="m-0">
                      {{ filter.view_type === 1 ? item.cmc_title : filter.view_type === 2 ? item.group_name : item.dosage_location}}
                    </h5>
                  </div>
                </ShadedCol>
                <ShadedCol>
                  <div class="text-center">
                    <h5 class="m-0" v-if="item.latest_stock_date_at">
                      {{ item.latest_stock_date_at | parseDate("DD/MM/YYYY") }}
                    </h5>
                    <h5 class="m-0" v-else>
                      -
                    </h5>
                  </div>
                </ShadedCol>
                <ShadedCol>
                  <div class="text-center">
                    <h5 class="m-0">
                      {{ Math.round(item.latest_final_position_amount).toLocaleString('pt-BR') }}
                    </h5>
                  </div>
                </ShadedCol>
                <ShadedCol>
                  <div class="text-center">
                    <h5 class="m-0 font-weight-600" :class="textClassFinalPosition(
                      item.latest_final_position_amount, item.amount_stock
                      )">
                      {{ Math.round(Number(item.latest_final_position_amount) + Number(item.amount_stock)).toLocaleString('pt-BR') }}
                    </h5>
                  </div>
                </ShadedCol>
              </template>
            </ShadedTable>
          </div>
          <puzl-empty-data :with-card="false" v-if="!$_stock_movements.length"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ShadedTable from "@/components/ShadedTable.vue";
import ShadedCol from "@/components/ShadedCol.vue";
import {mapGetters} from "vuex";
import PuzlEmptyData from "@/components/PuzlEmptyData.vue";
import MultiFilter from "@/components/Utils/MultiFilterV3.vue";
import cursorPaginate from "@/mixins/cursorPaginate";
export default {
  components: {
    PuzlEmptyData,
    ShadedCol,
    ShadedTable,
    MultiFilter,
  },
  mixins: [cursorPaginate],
  data() {
    return {
      headers: [
        "Central",
        "MCC",
        "Último fechamento",
        "Estoque final",
        "Saldo atual",
      ],
      group_headers: [
        "Central",
        "Grupo",
        "Último fechamento",
        "Estoque final",
        "Saldo atual",
      ],
      dosage_location_headers: [
        "Central",
        "Local",
        "Último movimento",
        "Estoque final",
        "Saldo atual",
      ],
      loading: false,
      loadingSkeleton: false,
      filter: {
        view_type: 2,
      },
    }
  },
  computed: {
    ...mapGetters({
      $_stock_movements: "stockMovement/getCurrent",
    })
  },
  methods: {
    setViewType(view_type) {
      this.filter.view_type = view_type
      this.init(this.filter)
    },
    init(filter = null) {
      this.startCursor(filter)
      this.$store.dispatch('stockMovement/getCurrent',
        {
          filter: this.filter,
          per_page: 3,
          next_page: this.paginate.nextUrl
        }).then(response => {
        this.resolveCursor(response)
      }).catch(error => {
        this.resolveCursor()
      })
    },
    textClassFinalPosition(latest_position, amount_stock) {
      let sum = Number(latest_position) + Number(amount_stock)
      if (sum < 0) {
        return 'text-danger'
      }
      if (sum > 0) {
        return 'text-success'
      }
    },
    handleFilterDate(filter) {
      this.range = filter
      this.init({})
    },
  },
  mounted() {
    this.loadingSkeleton = true
    this.init({})
  }
}
</script>
