<template>
  <div>
    <modal :show.sync="modal.create" :backgroundColor="'#149e57'">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <div class="row mt-n3">
          <div class="col-12">
            <div class="d-flex align-items-center">
              <img height="23px" src="/img/icons/icons8/ios/info-squared.png" class="mr-3" />
              <b class="text-dark mr-3">Identificação</b>
              <hr class="bg-secondary flex-grow-1 ml-2 mr-2">
            </div>
          </div>
        </div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(getCustomerConstructionByDocument)"
            autocomplete="off"
          >
            <div class="form-group row m-0 p-0">
              <label
                class="col-md-4 pb-0 mb-1 col-form-label form-control-label"
              >
                Cpf/Cnpj
              </label>
              <div class="col-md-8 mb-1 pr-4">
                <validation-provider :rules="'required|document'" v-slot="{errors}">
                  <base-input input-classes="form-control-sm">
                    <input
                      v-model="customerConstruction.document"
                      type="text"
                      class="form-control form-control-sm"
                      :class="
                        customerConstruction.document.length > 0 && 
                        ! verifyDocument(customerConstruction.document) && 
                        'is-invalid' || errors[0] ? 'is-invalid' : 'is-valid'
                      "
                      v-mask="['###.###.###-##', '##.###.###/####-##']"
                      :disabled="isCustomerConstructionDocumentDisabled"
                    />
                  </base-input>
                  <small 
                    v-show="customerConstruction.document.length > 0 && ! verifyDocument(customerConstruction.document)"
                  >
                    <i class="fas fa-exclamation-triangle text-danger mr-2"></i>
                    <strong class="" style="color: #7e7e7e">CPF/CNPJ Inválido!</strong>
                  </small>
                  <div>
                    <a href="#" @click.prevent="openModalSearchEntity" v-if="!isHideSearchButton" class="text-dark">
                      <small class="float-right mt-1">
                        Procura por cpf/cnpj
                        <img src="/img/icons/icons8/ios/identify_dark.png" width="17px" class="mr-1" />
                      </small>
                    </a>
                  </div>
                </validation-provider>
              </div>
              <label
                class="col-md-4 pb-0 mb-1 col-form-label form-control-label"
                v-if="verifyDocument(customerConstruction.document) && isResearchedCustomerConstruction"
              >
                Nome
              </label>
              <div 
                class="col-md-8 mb-1 pr-4" 
                v-if="verifyDocument(customerConstruction.document) && isResearchedCustomerConstruction"
              >
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-classes="form-control-sm">
                    <input
                      v-model="customerConstruction.customer_name"
                      type="text"
                      class="form-control form-control-sm"
                      :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      @input="handleCustomerNameChange"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="modal-footer">
              <div v-if="! searchingForContractProposalRegistration && isResearchedCustomerConstruction">
                <base-button
                  type="success"
                  @click="storeCustomerConstruction"
                  :disabled="invalid"
                >
                  <img height="17px" src="/img/icons/icons8/ios/right-squared.png" class="mr-2" />
                  Nova obra
                </base-button>
              </div>

              <base-button
                type="success"
                native-type="submit"
                :disabled="invalid"
                :loading="loadingSearchCustomerConstruction"
                v-if="!isHideSearchButton"
              >
                <img height="17px" src="/img/icons/icons8/ios/right-squared.png" class="mr-2" />
                Pesquisar
              </base-button>
            </div>
          </form>
        </validation-observer>
        <div class="row" v-if="constructionsWithoutContractProposal.length">
          <div class="col-12 mt-n3">
            <div class="d-flex align-items-center">
              <img src="/img/icons/icons8/ios/under-construction.png" height="25px" class="mr-2" />
              <b class="text-dark mr-3">Obras prospectadas</b>
              <hr class="bg-dark flex-grow-1 ml-2 mr-2">
            </div>
          </div>
        </div>
        <div v-if="! loadingSearchCustomerConstruction && constructionsWithoutContractProposal">
          <div
            v-for="(item, index) in constructionsWithoutContractProposal" 
            :key="index"
          >
            <div class="card mb-3">
              <div class="card-body">
                <b class="card-title text-dark" style="font-size: 12px;">
                  {{ item.construction_name }}
                </b>
                <span class="float-right">
                  <el-popover trigger="hover" placement="bottom" class="p-0 pr-0">
                    <span> {{ item.seller_name }} </span> 
                    <base-button outiline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none">
                      <img src="/img/icons/salesman.png" height="25px" class="mr-3" />
                    </base-button>
                  </el-popover>
      
                  <el-popover trigger="hover" placement="bottom" class="p-0 pr-0">
                    <span> {{ item.company_plant_name }} </span> 
                    <base-button outiline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none">
                      <img src="/img/icons/plant.png" height="25px" />
                    </base-button>
                  </el-popover>
                </span>
                <hr class="bg-info m-0 mt-3 mb-1">
                <div class="text-dark" style="font-size: 11.2px;">
                  {{ item.address_construction_address }}, 
                  {{ item.address_construction_number }},
                  {{ item.address_construction_district }}, 
                  cep {{ item.address_construction_postal_code }},
                  {{ item.address_construction_city }}/
                  {{ item.address_construction_state }}
                </div>
              </div>
              <div class="row align-items-center" style="margin-bottom: 0px; margin-right: 0px;">
                <div class="col-md-12" style="text-align: right !important; padding: 0px">
                  <div class="card-blue-bottom">
                    <a 
                      style="height: 45px; display: block;" 
                      href="#" 
                      @click="redirectToContractProposalCreate(item)"
                    >
                      <h3 style="padding: 8px; padding-right: 17px;">
                        <img src="/img/icons/icons8/ios/right-squared.png" height="17px" class="mr-1" />
                        <span :disabled="invalid" class="mr-1 text-white" style="font-size: 11px !important;">
                          Continuar
                        </span>
                      </h3>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="row align-items-center">
          <div class="col-12">
            <div class="mb-3 card" style="height: 80px">
              <div class="card-body">
                <div class="row">
                  <div class="col-9" style="margin-top: -10px">
                    <SkeletonPuzl type="small"/>
                  </div>
                  <div class="col-2 text-right float-right" style="margin-top: -15px">
                    <SkeletonPuzl type="circle"/>
                  </div>
                  <div class="col-5" style="margin-top: -22px">
                    <SkeletonPuzl type="small"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PuzlEmptyData v-if="! loadingSearchCustomerConstruction && ! constructionsWithoutContractProposal.length && firstSearch"  />
      </div>
    </modal>
    <ModalSearchEntity @selected="fillDocumentAfterEntitySelection" ref="modalSearchEntity" />
  </div>
</template>

<script>
import SkeletonPuzl from "@/components/SkeletonPuzl";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import {verifyDocument} from "@/helpers";
import ModalSearchEntity from "@/views/Modules/Configuration/Entity/Shared/_ModalSearchEntity";

export default {
  name: "ModalCreate",
  components: { 
    SkeletonPuzl,
    PuzlEmptyData,
    ModalSearchEntity,
  },
  data() {
    return {
      modal: {
        title: "Contrato",
        create: false,
      },
      verifyDocument: verifyDocument,
      customerConstruction: {
        uuid: null,
        status: 1,
        document: '',
        customer_name: '',
      },
      constructionsWithoutContractProposal: [],
      loadingSearchCustomerConstruction: false,
      isResearchedCustomerConstruction: false,
      invalid: false,
      searchingForContractProposalRegistration: false,
      firstSearch: false,
      isCustomerConstructionDocumentDisabled: false,
      isHideSearchButton: false,
    };
  },
  watch: {
    // Caso o usuário apague o documento e pesquise de novo;
    'customerConstruction.document': function (newVal) {
      if (!newVal) {
        this.isResearchedCustomerConstruction = false;
        this.customerConstruction.customer_name = '';
      }
    },
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    /**
     * @param {object} customerConstruction - caso seja aberto ao clicar no botão de pesquisa na tela de cadastro de propostas/contratos
     */
    openModal(customerConstruction = null) {
      this.customerConstruction = {
        uuid: null,
        status: 1,
        document: '',
        customer_name: '',
      };
      this.isCustomerConstructionDocumentDisabled = false;
      this.isHideSearchButton = false;
      if (customerConstruction) {
        this.customerConstruction = customerConstruction;
        this.searchingForContractProposalRegistration = true;
      }
      this.modal.create = true;
      this.firstSearch = false;
    },
    /**
     * Busca cliente/obra por documento e suas obras relacionadas que não possuem contrato, quando existirem.
     */
    getCustomerConstructionByDocument() {
      this.isResearchedCustomerConstruction = true;
      this.loadingSearchCustomerConstruction = true;
      this.firstSearch = true;
      this.isCustomerConstructionDocumentDisabled = true;
      this.$store.dispatch("customerConstruction/getCustomerByDocument", {document: this.customerConstruction.document})
        .then((response) => {
          if (response.data) {
            this.customerConstruction.customer_name = response.data.customer_name;
            this.isHideSearchButton = true;
            this.$store.dispatch('customerConstructionConstructions/getConstructionsWithoutContractByCustomerConstruction', {
              customer_construction_uuid: response.data.uuid
            }).then((response) => {
              this.constructionsWithoutContractProposal = response.data;
            });
          } else {
            this.constructionsWithoutContractProposal = [];
            this.$store.dispatch('entity/getEntityByDocument', {document: this.customerConstruction.document}).then((response) => {
              if (response.data) {
                this.customerConstruction.customer_name = response.data.entity_name;
              }
            });
          }
          this.loadingSearchCustomerConstruction = false;
        });
    },
    /**
     * Cadastrar novo cliente/obra se não existir, 
     * e redireciona para tela de cadastro de proposta/contrato.
     */
    async storeCustomerConstruction() {
      await this.$store.dispatch('customerConstruction/getCustomerByDocument', {document: this.customerConstruction.document})
        .then(async (response) => {
          if (! response.data) {
            await this.$store.dispatch('customerConstruction/add', this.customerConstruction).then((response) => {
              this.customerConstruction.id = response.data.id;
              this.customerConstruction.uuid = response.data.uuid;
              this.customerConstruction.customer_name = response.data.customer_name;
            });
          } else {
            this.customerConstruction.id = response.data.id;
            this.customerConstruction.uuid = response.data.uuid;
          }
          this.$router.push({
            name: 'commercial.contract-proposal.create',
            path: '/commercial/contract-proposal/create',
            query: {
              customerConstruction: JSON.stringify(this.customerConstruction),
              fromContractScreen: 'true' 
            }
          });
        });
    },
    /**
     * Redireciona para cadastro de propostas/contratos com todos os dados da obra selecionada.
     * @param {object} construction
     */
    redirectToContractProposalCreate(construction) {
      // Pesquisando obra já estando na tela de cadastro de cadastro de proposta/contato
      if (this.searchingForContractProposalRegistration) {
        this.$store.dispatch("customerConstructionConstructions/show", construction.uuid)
          .then(response => {
            if (response.data) {
              this.$emit('setConstruction', response.data);
            }
          });
      } else { // Fluxo padrão
        this.$router.push({
          name: 'commercial.contract-proposal.create',
          path: '/commercial/contract-proposal/create',
          query: { construction: JSON.stringify(construction) }
        });
      }
    },
    /** Abre modal de pesquisa por cliente/fornecedor */
    openModalSearchEntity() {
      this.$refs.modalSearchEntity.openModal();
    },
    /**
     * Preenche automaticamente o documento após o usuário selecionar um cliente/fornecedor na modal.
     * @param {object} entity
     */
    fillDocumentAfterEntitySelection(entity) {
      this.customerConstruction.document = entity.document;
      this.customerConstruction.customer_name = entity.entity_name;
      if (this.constructionsWithoutContractProposal.length) {
        this.constructionsWithoutContractProposal = [];
      }
    },
    /** Verifica se o campo nome está preenchido. */
    handleCustomerNameChange() {
      this.isHideSearchButton = !!this.customerConstruction.customer_name.trim();
    },
  },
};
</script>

<style scoped>
.card-blue-bottom {
  border-top-left-radius: 35px;
  border-bottom-right-radius: 10px;
  background-color: #1b6eba;
  color: white;
  max-width: 140px;
  min-width: 140px;
  float: right;
}
.card {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
</style>
