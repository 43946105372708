import * as types from "./mutation_types";
import { createAxios } from "@/plugins/axios";;
const endPoint = "/financial/dashboard/";
import { changeTreemapChart, calcTreemapItems, changeBarBrushChart } from "./charts";
export default {
  namespaced: true,
  state: {
    loading: true,
    generalTotal: {},
    valueType: null,
    treemapLoading: true,
    treemap: {},
    treemapType: {},
    barBrushLoading: true,
    barBrush: {},
    barBrushType: {},
  },
  getters: {
    loading: (state) => state.loading,
    generalTotal: (state) => state.generalTotal,
    valueType: (state) => state.valueType,
    treemapLoading: (state) => state.treemapLoading,
    treemap: (state) => state.treemap,
    treemapType: (state) => state.treemapType,
    barBrushLoading: (state) => state.barBrushLoading,
    barBrush: (state) => state.barBrush,
    barBrushType: (state) => state.barBrushType,
  },
  mutations: {
    [types.SET_GENERAL_TOTAL](state, payload) {
      state.generalTotal = payload;
    },
    [types.SET_LOADING](state, payload) {
      state.loading = payload;
    },
    [types.SET_VALUE_TYPE](state) {
      state.valueType = state.valueType === "final" ? "total" : "final";
    },
    [types.SET_TREEMAP_LOADING](state, payload) {
      state.treemapLoading = payload;
    },
    [types.SET_TREEMAP](state, payload) {
      state.treemap = changeTreemapChart([calcTreemapItems(payload, "bill_pay"), calcTreemapItems(payload, "bill_receive"), calcTreemapItems(payload, "invoice")]);
    },
    [types.SET_TREEMAP_TYPE](state, payload) {
      state.treemapLoading = true;
      state.treemapType = payload;
      state.treemapLoading = false;
    },
    [types.SET_BAR_BRUSH_LOADING](state, payload) {
      state.barBrushLoading = payload;
    },
    [types.SET_BAR_BRUSH](state, payload) {
      state.barBrush = changeBarBrushChart(payload);
    },
    [types.SET_BAR_BRUSH_TYPE](state, payload) {
      state.barBrushType = payload;
    },
  },
  actions: {
    getGeneralTotal({ commit }, payload) {
      commit(types.SET_LOADING, true);
      return createAxios()
        .get(`${endPoint}general-total`, { params: { filter: payload } })
        .then(({ data }) => {
          commit(types.SET_GENERAL_TOTAL, data.data);
          commit(types.SET_LOADING, false);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getTreemap({ commit }, payload) {
      commit(types.SET_TREEMAP_LOADING, true);
      commit(types.SET_TREEMAP_TYPE, "billReceiveData");
      return createAxios()
        .get(`${endPoint}treemap`, { params: { filter: payload } })
        .then(({ data }) => {
          commit(types.SET_TREEMAP, data.data);
          commit(types.SET_TREEMAP_LOADING, false);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getBarBrush({ commit }, payload) {
      commit(types.SET_BAR_BRUSH_LOADING, true);
      commit(types.SET_BAR_BRUSH_TYPE, "day");
      return createAxios()
        .get(`${endPoint}bar-brush`, { params: { filter: payload } })
        .then(({ data }) => {
          commit(types.SET_BAR_BRUSH, data.data);
          commit(types.SET_BAR_BRUSH_LOADING, false);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    triggerValueType({ commit }) {
      commit(types.SET_VALUE_TYPE);
    },
    triggerBarBrushType({ commit }, payload) {
      commit(types.SET_BAR_BRUSH_TYPE, payload);
    },
    triggerTreemapType({ commit }, payload) {
      commit(types.SET_TREEMAP_TYPE, payload);
    },
  },
};
