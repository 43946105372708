<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
      <transition name="el-zoom-in-center">
        <div class="row" v-if="!params.formulation_uuid">
          <div class="col-md-12">
            <div class="row px-3">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12 ">
                      <h5>Favor selecione um traço para continuar</h5>
                    </div>
                    <template v-if="$_formulations">
                      <div class="col-md-6 p-1 mb-n4" v-for="item in $_formulations.mix_designs">
                        <card class="mx-0 mt-0">
                          <div class="row">
                            <div class="col-12">
                              <span class="font-weight-500" style="font-size: 0.8rem">
                                {{ item.title }}
                              </span>
                            </div>
                            <div class="col-md-12">
                              <span style="font-size: 0.75rem" class="font-weight-400">
                                {{ item.body }}
                              </span>
                            </div>
                            <div class="col-md-12">
                              <span style="font-size: 0.75rem" class="font-weight-400">
                                {{ item.footer }}
                              </span>
                            </div>
                            <div class="col-12">
                              <hr class="my-2">
                              <base-button
                                @click.prevent="setSelected(item.uuid)"
                                class="btn-square"
                                size="sm"
                                block
                                outline type="primary">
                                SELECIONAR
                                <i class="fa-regular fa-circle-check"></i>
                              </base-button>
                            </div>
                          </div>
                        </card>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <template v-if="params.formulation_uuid">
        <div class="form-row">
          <div class="col-md-10">
            <div class="row">
              <div class="col-md-12">
                <img style="width: 30px" src="/img/icons/test-tube.png">
              </div>
              <div class="col-md-12">
            <span class="font-weight-500" style="font-size: 0.8rem">
                      {{ selected.title }}
                    </span>
              </div>
              <div class="col-md-12">
                    <span style="font-size: 0.75rem" class="font-weight-400">
                      {{ selected.body }}
                    </span>
              </div>
              <div class="col-md-12">
                    <span style="font-size: 0.75rem" class="font-weight-400">
                      {{ selected.footer }}
                    </span>
              </div>
            </div>
          </div>
          <div class="col-md-2 d-flex align-items-center justify-content-between">
            <a href="#" @click.prevent="params.formulation_uuid = null">
              <i style="font-size: 1.4rem" class="fa-regular fa-pen-to-square text-warning"></i>
            </a>
          </div>
          <div class="col-md-12">
            <hr class="mb-2 mt-3">
          </div>
        </div>
        <div class="form-row">
          <div class="col-12">
            <label class="pb-0 mb-1 col-form-label form-control-label">
              Peça a concretar
            </label>
          </div>
          <div class="col-12">
            <validation-provider rules="required">
            <puzl-select
              v-model="params.mix_concreted_piece_ids"
              :items="$_mix_concrete_pieces"
              multiple
              label="description"
            />
            </validation-provider>
          </div>
        </div>
      </template>
      <div class="modal-footer mt-n2">
        <base-button type="secondary" @click="closeModal()">
          fechar
        </base-button>
        <base-button
          type="success"
          native-type="submit"
          title="Salvar"
          @click.prevent="handleSave"
          :disabled="(invalid || !params.formulation_uuid) || loadingSave"
          class="btn-custom"
          :class="loadingSave && 'btn-loading'">
              <span v-show="!loadingSave" class="btn-label span-custom"><i
                class="fas fa-save text-white icon-custom"></i></span>
          <span v-show="loadingSave" class="btn-label span-custom"><i
            class="fas fa-spinner text-white fa-spin icon-custom"></i></span>
          Salvar
        </base-button>
      </div>
      </validation-observer>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PuzlSelect from "@/components/PuzlSelect.vue";

export default {
  components: {
    PuzlSelect
  },
  name: "ModalAddNewPiece",
  data() {
    return {
      modal: false,
      loadingSave: false,
      title: "Adicionar peça a concretar",
      selected: null,
      params: {
        mix_concreted_piece_ids: [],
        formulation_uuid: null,
      }
    };
  },
  computed: {
    ...mapGetters({
      $_formulations: 'formulations/show',
      $_mix_concrete_pieces: 'mixConcretePiece/activeItems',
    }),
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    /**
     * adiciona o traço selecionado
     * @param uuid
     */
    setSelected(uuid) {
      this.selected = this.$_formulations.mix_designs.find((item) => item.uuid === uuid)
      this.params.formulation_uuid = uuid
    },
    handleSave() {
      this.$Progress.start()
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.loadingSave = true
      this.$store.dispatch('formulations/postAddConcretePiece', this.params).then((response) => {
        this.loadingSave = false
        this.$notify({
          type: response.error_type,
          message: response.message
        });
        this.$Progress.finish()
        this.closeModal()
        this.$emit('fetch', {contract_proposal_formulation: response.data, uuid: this.params.formulation_uuid})
      })
    },
    openModal(contract_proposal_uuid, formulation_uuid = null) {
      this.params.formulation_uuid = null
      this.params.mix_concreted_piece_ids = []
      Promise.all(
        [
          this.$store.dispatch('formulations/fetchItem', {uuid: contract_proposal_uuid, filter: { show_inactive : false }}),
          this.$store.dispatch('mixConcretePiece/fetchItems'),
        ]
      ).then(() => {
        this.modal = true
        formulation_uuid && this.setSelected(formulation_uuid)
      })
    },
  },
};
</script>

<style></style>
