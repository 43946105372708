import store from "../../../../../../shared/libraries/store";
import { EntryLaunchItemTaxType, initEntryLaunchItemTaxType } from "../types";
import { entryLaunchItemTaxStoreStateKeys } from "./entryLaunchItemTaxStoreStateKeys";

/** @returns {?EntryLaunchItemTaxType} */
const getItem = () => store.getState(entryLaunchItemTaxStoreStateKeys.ITEM) ?? initEntryLaunchItemTaxType();

export default {
  getItem,
};
