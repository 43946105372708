import { adjustmentTypeUrls } from ".";
import httpClient from "../../../../shared/libraries/httpClient";
import { handleUnexpectedStatusCode } from "../../../../shared/services";
import { HttpStatusEnum } from "../../../../shared/enums";
import {
  AdjustmentTypeListFilterType,
  AdjustmentTypeListPagerType,
} from "../types";
import { adjustmentTypeFormatEmptyAgentService } from "./";

/**
 * Listar
 *
 * @param {?AdjustmentTypeListFilterType} filter
 * @returns {Promise<AdjustmentTypeListPagerType}
 */
const execute = async (filter = null) => {
  const res = await httpClient.get(adjustmentTypeUrls.BASE, {
    params: filter,
  });

  switch (res.status) {
    case HttpStatusEnum.HTTP_OK:
      res.data.data = formatAgentsOnNull(res.data.data);

      return res.data.data;
    default:
      handleUnexpectedStatusCode(res);
  }
};

/**
 * faz a formatação do aditivo caso seja nulo
 * @param {AdjustmentTypeListPagerType} adjustments
 * @returns {AdjustmentTypeListPagerType}
 */
const formatAgentsOnNull = (adjustments) => {

  adjustments.items.forEach(item => {
    if(item.agent === null) {
      item.agent = adjustmentTypeFormatEmptyAgentService.execute();
    }
  });

  return adjustments;
}

export default { execute };
