import { operationSourceUrls } from ".";
import httpClient from "../../../../shared/libraries/httpClient";
import { handleUnexpectedStatusCode } from "../../../../shared/services";
import { HttpStatusEnum } from "../../../../shared/enums";

/**
 * Deletar por ID
 *
 * @param {number} id
 */
const execute = async (id) => {
  const url = `${operationSourceUrls.BASE}/${id}`;
  const res = await httpClient.remove(url);

  const removedStatus = [
    HttpStatusEnum.HTTP_OK,
    HttpStatusEnum.HTTP_NO_CONTENT,
  ];
  const removed = removedStatus.includes(res.status);
  if (!removed) {
    handleUnexpectedStatusCode(res);
  }
};

export default { execute };
