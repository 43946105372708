import {
  AdjustmentTypeListFilterType,
  AdjustmentTypeListPagerType,
  AdjustmentTypeCreateType,
  AdjustmentTypeUpdateType,
  AdjustmentTypeViewType,
} from "../types";
import {
  adjustmentTypeFindService,
  adjustmentTypeListService,
  adjustmentTypeCreateService,
  adjustmentTypeUpdateService,
  adjustmentTypeDeleteService,
} from "../services";
import mutations from "./adjustmentTypeStoreMutations";
import getters from "./adjustmentTypeStoreGetters";
import {
  listResources,
  findResource,
  createResource,
  updateResource,
  removeResource
} from "../../../../shared/store";

/**
 * Incluir
 *
 * @param {AdjustmentTypeCreateType} data
 * @returns {Promise<AdjustmentTypeViewType>}
 */
const create = async (data) =>
  await createResource(data, mutations, adjustmentTypeCreateService);

/**
 * Listar
 *
 * @param {AdjustmentTypeListFilterType} filter - Filtro de listagem
 * @param {boolean} isAccumulateItems - Incluir mais itens na lista existente
 * @returns {Promise<?AdjustmentTypeListPagerType>} - Retorno paginado
 */
const list = async (filter, isAccumulateItems = false) => {
  return await listResources(
    filter,
    isAccumulateItems,
    mutations,
    getters,
    adjustmentTypeListService
  );
};

/**
 * Localizar por ID
 *
 * @param {number|string} id
 * @returns {Promise<?AdjustmentTypeViewType>}
 */
const find = async (id) =>
  await findResource(id, mutations, adjustmentTypeFindService);

/**
 * Atualizar tipo por id
 *
 * @param {AdjustmentTypeUpdateType} data
 * @param {number} id
 * @returns {Promise<?AdjustmentTypeViewType>}
 */
const update = async (data, id) =>
  await updateResource(data, id, mutations, adjustmentTypeUpdateService);

/**
 * Deletar por ID
 *
 * @param {number} id
 * @returns {Promise<void>}
 */
const remove = async (id) => await removeResource(id, mutations, adjustmentTypeDeleteService);

export default {
  create,
  list,
  find,
  update,
  remove
};
