<template>
  <div>
    <AppFixedPageTitle
      title="Usuários"
      icon="/img/icons/icons8/ios/users.svg"
      class="invert-color"
    />
    <AppPageHeader>
      <template #search-bar>
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          :showCompanyPlants="true"
          :isLoading="isLoading"
          @onSearchClick="listItems"
          @onClearClick="clearFilter"
        >
          <AppSearchBarFilterSection
            name="status"
            icon="/img/icons/icons8/ios/status.svg"
          >
            <PuzlSelect
              style="width: 100%"
              v-model.lazy="filter.status"
              :items="searchBarFilterStatus"
              :disableBoxShadow="true"
              customKey="value"
              class="select-xl col-md-12 px-0 new-default-black-font"
            >
            </PuzlSelect>
          </AppSearchBarFilterSection>
          <AppSearchBarFilterSection
            name="Permissão"
            icon="/img/icons/icons8/ios/security-shield.svg"
          >
            <PuzlSelect
              style="width: 100%"
              v-model.lazy="filter.role_id"
              :items="group_permissions"
              :disableBoxShadow="true"
              class="select-xl col-md-12 px-0 new-default-black-font mb-5"
            />
          </AppSearchBarFilterSection>
        </AppSearchBar>
      </template>
      <template #header-buttons>
        <AppPageHeaderActions>
          <AppPageHeaderActionsButton
            type="success"
            @click.prevent="handleCreateModal"
            text="novo"
          />
          <AppPageHeaderActionsButton
            type="primary"
            icon="/img/icons/security-shield.png"
            @click.prevent="$router.push('/configuration/user/permission')"
            text="Permissões"
          />
          <AppPageHeaderActionsButton
            type="danger"
            icon="/img/icons/icons8/ios/error.png"
            @click.prevent="$router.push('/configuration/user/user-alert')"
            text="Alerta"
          />
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>
    <AppTabSelect
      :items="tabSelectItems"
      @onTabSelectItemClick="onTabSelectItemClick"
      @onViewTypeChange="handleViewTypeChange"
    >
      <AppSelect
        placeholder="ORDENAR"
        v-model="orderBy.selected"
        :items.sync="orderBy.items"
        @onSelectItemClick="listItems(false)"
        variant="text-only"
      />
    </AppTabSelect>

    <div class="container-fluid">
      <hr class="espacamento-provisorio" />

      <div class="row card-wrapper" v-show="loadingSkeleton && listCard">
        <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
      </div>

      <PuzlEmptyData v-if="!users.length && !loadingSkeleton"></PuzlEmptyData>

      <!-- TABELA -->
      <div class="row" v-if="listType === 'table' && !loadingSkeleton">
        <div class="col-md-12 table-responsive" style="min-height: 280px">
          <table class="table table-sm">
            <thead>
              <tr class="fixed">
                <th class="text-center">Status</th>
                <th class="text-left">Nome</th>
                <th class="text-center">Telefone</th>
                <th class="text-center">Email</th>
                <th class="text-center">Permissão</th>
                <th class="text-center">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in 10"
                v-show="loadingSkeleton && listTable"
                :key="index"
              >
                <th colspan="12">
                  <SkeletonPuzl type="button" />
                </th>
              </tr>
              <tr v-for="(user, index) in users" :key="`user-${index}`">
                <td width="15%" class="text-center">
                  <a href="#" @click.prevent="changeStatus(user)">
                    <badge
                      class="active-badge px-3 py-2 badge-mobile"
                      :style="
                        user.status
                          ? 'color: #149E57'
                          : 'color: #DB4539; background-color: #FCF3F3;'
                      "
                    >
                      <AppIcon
                        :icon="user.status ? 'check' : 'shutdown'"
                        width="14"
                        :color="user.status ? 'success' : 'danger'"
                        class="mr-1"
                        align="bottom"
                      />
                      {{ user.status ? "Ativo" : "Inativo" }}
                    </badge>
                  </a>
                </td>
                <td width="35%">
                  <div class="table-content d-flex align-items-center">
                    <span class="table-text col p-0">
                      {{ user.name }}
                    </span>

                    <div class="table-icons col text-right pr-0"></div>
                  </div>
                </td>
                <td width="20%" class="">
                  <span v-if="user.phone" class="table-text">{{
                    user.phone
                  }}</span>
                </td>
                <td width="10%" class="">
                  <span v-if="user.email" class="table-text">
                    {{ user.email }}
                  </span>
                </td>
                <td width="20%" class="permission-mobile">
                  <div
                    class="table-content d-flex align-items-center"
                    v-if="user.role.length !== 0"
                  >
                    <span class="table-text col p-0" :key="index">
                      {{ userRole(user.role) }}
                    </span>

                    <span class="table-icons col text-right pr-0">
                      <el-popover
                        trigger="click"
                        placement="right"
                        class="p-0"
                        v-if="
                          user.latest_user_log_at &&
                          user.latest_user_log_at.length > 0
                        "
                      >
                        <div class="table-text d-flex flex-column">
                          <span class="popover-title"> Acessos </span>
                          <hr class="popover-title-border full" />
                          <b style="font-weight: 400 !important">
                            Último acesso:
                          </b>
                          <span
                            v-if="user.latest_user_log_at"
                            class="popover-text"
                          >
                            {{
                              user.latest_user_log_at
                                | parseDate("DD MMM YYYY - HH:mm")
                            }}
                          </span>
                          <hr class="popover-title-border" />
                          <b style="font-weight: 400 !important">
                            Última alteração
                          </b>
                          <span
                            v-if="user.permission_change_at"
                            class="popover-text"
                          >
                            {{ user.changer_name }} -
                            {{
                              user.permission_change_at
                                | parseDate("DD MMM YYYY - HH:mm")
                            }}
                          </span>
                        </div>
                        <base-button
                          outline
                          slot="reference"
                          size="sm"
                          type="secundary"
                          class="p-0 m-0 shadow-none text-indigo"
                          style="min-width: 25px"
                        >
                          <AppIcon icon="male-user" width="25" />
                        </base-button>
                      </el-popover>
                      <el-popover
                        trigger="click"
                        placement="bottom"
                        class="icon-reset ml-2"
                        @show="handleUserPlants(user)"
                      >
                        <div class="d-flex flex-column">
                          <b class="table-text"> Centrais </b>
                          <hr class="popover-title-border full" />

                          <!-- Mostrar loading spinner -->
                          <div v-if="isLoading" class="d-inline-block m-auto">
                            <i class="fas fa-spinner fa-spin"></i>
                          </div>

                          <!-- Mostrar plants existentes -->
                          <div
                            v-else-if="
                              !user.has_access_to_all_plants &&
                              userPlants.length > 0
                            "
                          >
                            <div
                              v-for="(plant, index) in userPlants"
                              :key="index"
                              class="popover-text"
                            >
                              {{ plant.plant_name }}
                            </div>
                          </div>

                          <!-- Mostrar quando nenhuma plant for encontrada -->
                          <div
                            v-else-if="
                              !user.has_access_to_all_plants &&
                              userPlants.length === 0
                            "
                            class="popover-text"
                          >
                            Nenhuma central associada.
                          </div>

                          <!-- Mostrar 'Todas' se user tem acesso a todas as plants -->
                          <div v-else class="d-flex flex-column">
                            <div class="popover-text">Todas</div>
                          </div>
                        </div>
                        <base-button
                          outline
                          slot="reference"
                          size="sm"
                          type="secundary"
                          class="p-0 m-0 shadow-none text-indigo"
                        >
                          <AppIcon icon="chemical-plant" width="25" />
                        </base-button>
                      </el-popover>
                      <el-popover
                        trigger="click"
                        placement="bottom"
                        class="h-100 ml-2"
                        v-if="
                          user.bank_account_ids &&
                          user.bank_account_ids.length > 0
                        "
                      >
                        <div class="table-text d-flex flex-column">
                          <span class="popover-title">
                            Contas Financeiras
                          </span>
                          <hr class="popover-title-border full" />
                          <span
                            v-if="
                              user.bank_account_ids &&
                              user.bank_account_ids === `All`
                            "
                            class="popover-text"
                          >
                            Todas
                          </span>
                          <span
                            v-else-if="user.bank_account_ids"
                            v-for="bankAccount in user.bank_account_ids"
                            :key="bankAccount"
                          >
                            {{ showUserBankAccountIds(bankAccount) }}
                          </span>
                        </div>
                        <base-button
                          outline
                          slot="reference"
                          size="sm"
                          type="secundary"
                          class="p-0 m-0 shadow-none text-indigo"
                        >
                          <AppIcon icon="merchant-account" width="25px" />
                        </base-button>
                      </el-popover>
                    </span>
                  </div>
                </td>
                <td width="6%" class="text-center">
                  <base-dropdown menuOnRight>
                    <div
                      slot="title-container"
                      class="dropdown-toggle rounded m-0"
                    >
                      <AppIcon icon="settings" color="primary" width="25" />
                    </div>
                    <a class="dropdown-item" @click="handleEditUser(user.uuid)">
                      <AppIcon icon="create" color="warning" width="20" />
                      Editar
                    </a>
                    <hr class="my-1" />
                    <a
                      class="dropdown-item"
                      @click.prevent="
                        handleUserExtraInfo(
                          user.id,
                          user.uuid,
                          user.name,
                          user.email,
                          user.phone
                        )
                      "
                    >
                      <AppIcon icon="contact" width="20" class="mr-2" />
                      INFO COMPLEMENTARES
                    </a>

                    <hr class="my-1" />
                    <a
                      class="dropdown-item"
                      @click.prevent="
                        handleUserPermission(
                          user.id,
                          user.uuid,
                          user.role && user.role[0] ? user.role[0].name : null
                        )
                      "
                    >
                      <AppIcon
                        icon="security-shield"
                        color="primary"
                        class="mr-2"
                        width="20"
                      />
                      Permissões
                    </a>
                  </base-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
          <PuzlEmptyData
            v-show="users && !users.length && !loadingSkeleton && listTable"
          />
        </div>
      </div>

      <!-- CARDS -->
      <div
        class="row card-wrapper"
        v-if="users.length && !loadingSkeleton && listType === 'cards'"
      >
        <div
          class="col-lg-4"
          v-if="user.internal_type === 1 ? $hasPrivilege(1) : true"
          v-for="(user, index) in users"
          :key="index"
        >
          <div class="card">
            <div class="card-header d-flex aligh-items-center">
              <a
                href="#"
                @click.prevent="changeStatus(user)"
                class="d-inline-block p-0"
              >
                <badge
                  class="active-badge px-3 py-2 d-flex align-items-center justify-content-center"
                  :style="
                    user.status
                      ? 'color: #149E57; background-color: #F2F7F3;'
                      : 'color: #DB4539; background-color: #FCF3F3;'
                  "
                >
                  <AppIcon
                    :icon="user.status ? 'check' : 'shutdown'"
                    width="14"
                    :color="user.status ? 'success' : 'danger'"
                    class="mr-1"
                    align="bottom"
                  />
                  {{ user.status ? "Ativo" : "Inativo" }}
                </badge>
              </a>
              <el-popover
                trigger="click"
                placement="bottom"
                class="ml-2 h-100"
                v-if="
                  user.latest_user_log_at && user.latest_user_log_at.length > 0
                "
              >
                <div class="table-text d-flex flex-column">
                  <span class="popover-title"> Acessos </span>
                  <hr class="popover-title-border full" />
                  <b style="font-weight: 400 !important"> Último acesso: </b>
                  <span v-if="user.latest_user_log_at" class="popover-text">
                    {{
                      user.latest_user_log_at | parseDate("DD MMM YYYY - HH:mm")
                    }}
                  </span>
                  <hr class="popover-title-border" />
                  <b style="font-weight: 400 !important"> Última alteração </b>
                  <span v-if="user.permission_change_at" class="popover-text">
                    {{ user.changer_name }} <br />
                    {{
                      user.permission_change_at
                        | parseDate("DD MMM YYYY - HH:mm")
                    }}
                  </span>
                </div>
                <base-button
                  outline
                  slot="reference"
                  size="sm"
                  type="secundary"
                  class="p-0 m-0 shadow-none text-indigo"
                >
                  <AppIcon icon="male-user" width="25" />
                </base-button>
              </el-popover>
              <el-popover
                trigger="click"
                placement="bottom"
                class="icon-reset ml-2"
                @show="handleUserPlants(user)"
              >
                <div class="d-flex flex-column">
                  <b class="popover-title"> Centrais </b>
                  <hr class="popover-title-border full" />

                  <!-- Mostrar loading spinner -->
                  <div v-if="isLoading" class="d-inline-block m-auto">
                    <i class="fas fa-spinner fa-spin"></i>
                  </div>

                  <!-- Mostrar plants existentes -->
                  <div
                    v-else-if="
                      !user.has_access_to_all_plants && userPlants.length > 0
                    "
                  >
                    <div
                      v-for="(plant, index) in userPlants"
                      :key="index"
                      class="popover-text"
                    >
                      {{ plant.plant_name }}
                    </div>
                  </div>

                  <!-- Mostrar quando nenhuma plant for encontrada -->
                  <div
                    v-else-if="
                      !user.has_access_to_all_plants && userPlants.length === 0
                    "
                    class="popover-text"
                  >
                    Nenhuma central associada.
                  </div>

                  <!-- Mostrar 'Todas' se user tem acesso a todas as plants -->
                  <div v-else class="d-flex flex-column">
                    <div class="popover-text">Todas</div>
                  </div>
                </div>
                <base-button
                  outline
                  slot="reference"
                  size="sm"
                  type="secundary"
                  class="p-0 m-0 shadow-none text-indigo"
                >
                  <AppIcon icon="chemical-plant" width="25" />
                </base-button>
              </el-popover>
              <el-popover
                trigger="click"
                placement="bottom"
                class="ml-2 h-100"
                v-if="user.bank_account_ids && user.bank_account_ids.length > 0"
              >
                <div class="table-text d-flex flex-column">
                  <span class="popover-title"> Contas Financeiras </span>
                  <hr class="popover-title-border full" />
                  <span
                    v-if="
                      user.bank_account_ids &&
                      user.bank_account_ids[0] === 'All'
                    "
                    class="popover-text"
                  >
                    Todas
                  </span>
                  <span
                    v-else-if="user.bank_account_ids"
                    v-for="bankAccount in user.bank_account_ids"
                    :key="bankAccount"
                  >
                    {{ showUserBankAccountIds(bankAccount) }}
                  </span>
                </div>
                <base-button
                  outline
                  slot="reference"
                  size="sm"
                  type="secundary"
                  class="p-0 m-0 shadow-none text-indigo"
                >
                  <AppIcon icon="merchant-account" width="25px" />
                </base-button>
              </el-popover>
              <base-dropdown menuOnRight>
                <div
                  slot="title-container"
                  class="dropdown-toggle rounded m-0 ml-auto"
                >
                  <AppIcon icon="settings" color="primary" width="30" />
                </div>
                <a
                  class="dropdown-item"
                  @click.prevent="handleEditUser(user.uuid)"
                >
                  <AppIcon
                    icon="create"
                    color="warning"
                    width="20"
                    class="mr-2"
                  />
                  Editar
                </a>
                <hr class="my-1" />
                <a
                  class="dropdown-item"
                  @click.prevent="
                    handleUserExtraInfo(
                      user.id,
                      user.uuid,
                      user.name,
                      user.email,
                      user.phone
                    )
                  "
                >
                  <AppIcon icon="contact" width="20" class="mr-2" />
                  INFO COMPLEMENTARES
                </a>
                <hr class="my-1" />
                <a
                  class="dropdown-item"
                  @click.prevent="
                    handleUserPermission(
                      user.id,
                      user.uuid,
                      user.role && user.role[0] ? user.role[0].name : null
                    )
                  "
                >
                  <AppIcon
                    icon="security-shield"
                    color="primary"
                    width="20"
                    class="mr-2"
                  />
                  Permissões
                </a>
              </base-dropdown>
            </div>
            <div class="card-body">
              <h6
                v-if="user.role && user.role.length > 0"
                class="user-role"
                :key="index"
              >
                {{ userRole(user.role) }}
              </h6>
              <h6 v-else class="user-role">SEM PERMISSÕES</h6>
              <h5
                class="mb-2"
                style="white-space: nowrap; font-size: 16px"
                :title="user.name"
              >
                {{ getFirstAndLastName(user) }}
              </h5>
              <h6 class="mb-2" v-if="user.email">
                {{ user.email }}
              </h6>
              <h6 class="mb-2" v-if="user.phone">
                {{ user.phone }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AppViewTrigger
      v-if="!isLoading && users.length"
      @onIntersected="listItems(true)"
    ></AppViewTrigger>
    <LoadingPagination :show="loading && !loadingSkeleton" />
    <ModalUserPermission
      @storedAfterCenterAllocation="storedAfterCenterAllocation"
      ref="modalUserPermission"
    />
    <ModalEditUser ref="modalEditUser" />
    <ModalCreateUser ref="modalCreateUser" @refreshPage="handleRefreshPage" />
    <ModalEditDetails ref="modalUserExtraInfo"></ModalEditDetails>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import MultiFilter from "@/components/Utils/MultiFilterV3";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import ModalUserPermission from "./_ModalUserPermission";
import ModalEditUser from "./_ModalEdit";
import ModalCreateUser from "./_ModalCreate.vue";
import ModalEditDetails from "./_ModalEditDetails.vue";
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import {
  AppSearchBarFilterSection,
  initSearchBarFilterType,
  SearchBarFilterType,
  TabSelectItemType,
  AppViewTrigger,
  AppSelect,
  AppIcon,
} from "../../../../../components/AppGlobal";
import AppFixedPageTitle from "../../../../../components/AppGlobal/AppFixedPageTitle/AppFixedPageTitle.vue";
import AppPageHeader from "../../../../../components/AppGlobal/AppPageHeader/AppPageHeader.vue";
import AppPageHeaderActions from "../../../../../components/AppGlobal/AppPageHeader/AppPageHeaderActions.vue";
import AppPageHeaderActionsButton from "../../../../../components/AppGlobal/AppPageHeader/AppPageHeaderActionsButton.vue";
import AppSearchBar from "../../../../../components/AppGlobal/AppSearchBar/AppSearchBar.vue";
import PuzlSelect from "@/components/PuzlSelect";
import { date } from "../../../../../helpers";
import {
  initUsersListFilterType,
  UsersListFilterType,
} from "../types/initUsersListFilterType";
import AppTabSelect from "../../../../../components/AppGlobal/AppTabSelect/AppTabSelect.vue";

const UserTypeEnum = Object.freeze({
  ALL: 0,
  USERS: 1,
  DRIVERS: 2,
  SELLERS: 3,
});

export default {
  name: "ListUser",
  mixins: [cursorPaginate],
  components: {
    SkeletonPuzlGrid,
    MultiFilter,
    LoadingPagination,
    ModalUserPermission,
    ModalEditUser,
    ModalEditDetails,
    BaseButtonHoverable,
    PuzlEmptyData,
    SkeletonPuzl,
    AppFixedPageTitle,
    AppPageHeader,
    AppPageHeaderActions,
    AppPageHeaderActionsButton,
    AppSearchBar,
    AppSearchBarFilterSection,
    AppTabSelect,
    AppViewTrigger,
    AppSelect,
    AppIcon,
    ModalCreateUser,
    PuzlSelect,
  },
  data() {
    return {
      searchBarFilter: initSearchBarFilterType(),
      currentDate: date.make().format(date.FORMAT.ISO_8601),
      filter: initUsersListFilterType(),
      loadingSkeleton: false,
      added: false,
      isLoading: false,
      status: {
        inactive: { value: 0, name: "Inativos" },
        active: { value: 1, name: "Ativos" },
      },
      searchBarFilterStatus: [
        { value: null, name: "Todos" },
        { value: 0, name: "Inativos" },
        { value: 1, name: "Ativos" },
      ],
      loadingTotalUsers: true,
      listCard: true,
      listTable: false,
      listType: "cards",
      title: "",
      userPlants: [],
      userInfo: {
        id: null,
        name: "",
        email: "",
        phone: "",
      },
      orderBy: {
        selected: 0,
        items: [
          {
            id: 0,
            name: "A-Z",
            selected_name: "A-Z",
            icon: "/img/icons/icons8/ios/double-down.png",
            filter: [
              { column: "users.status", is_desc: true },
              {
                column: "users.name",
                is_desc: false,
              },
            ],
          },
          {
            id: 1,
            name: "Z-A",
            selected_name: "Z-A",
            icon: "/img/icons/icons8/ios/double-up.png",
            filter: [
              { column: "users.status", is_desc: true },
              {
                column: "users.name",
                is_desc: true,
              },
            ],
          },
          {
            id: 2,
            name: "REGISTRO MAIS NOVO PARA O MAIS VELHO ",
            selected_name: "REG. MAIS NOVO",
            icon: "/img/icons/icons8/ios/double-down.png",
            filter: [
              {
                column: "users.created_at",
                is_desc: true,
              },
            ],
          },
          {
            id: 3,
            name: "REGISTRO MAIS VELHO PARA O MAIS NOVO",
            selected_name: "REG. MAIS VELHO",
            icon: "/img/icons/icons8/ios/double-up.png",
            filter: [
              {
                column: "users.created_at",
                is_desc: false,
              },
            ],
          },
          {
            id: 4,
            name: "ACESSOS MAIS RECENTES",
            selected_name: "ACESSOS MAIS RECENTES",
            icon: "/img/icons/icons8/ios/double-down.png",
            filter: [
              {
                column: "users.latest_user_log_at",
                is_desc: true,
              },
            ],
          },
          {
            id: 5,
            name: "Últimas alterações em permissões",
            selected_name: "ULT. ALT. EM PERMISSÕES",
            icon: "/img/icons/icons8/ios/double-down.png",
            filter: [
              {
                column: "users.permission_change_at",
                is_desc: true,
              },
            ],
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      users: "user/fetch",
      group_permissions: "authGroupPermission/fetch",
      $_getUserPlants: "user/getUserPlants",
      $_totalUsers: "user/showCountUsers",
      $_authenticatedUser: "auth/getUser",
      $_bank_accounts: "bankAccount/fetchWithAll",
    }),
    tabSelectItems() {
      return [
        {
          id: UserTypeEnum.ALL,
          name: "Todos",
          selected:
            !this.filter.is_user &&
            !this.filter.driver &&
            !this.filter.is_seller,
        },
        {
          id: UserTypeEnum.USERS,
          name: "Usuários",
          selected: this.filter.is_user === 1,
        },
        {
          id: UserTypeEnum.DRIVERS,
          name: "Motoristas",
          selected: this.filter.driver === 1,
        },
        {
          id: UserTypeEnum.SELLERS,
          name: "Vendedores",
          selected: this.filter.is_seller === 1,
        },
      ];
    },
    click() {
      return this.isMobileOrTablet ? "click" : "hover";
    },
    isMobileOrTablet() {
      const lgBreakpoint = 992;
      return window.innerWidth < lgBreakpoint;
    },
  },
  watch: {
    added() {
      this.listItems();
    },
  },
  methods: {
    userRole(userRole) {
      return userRole && userRole[0].name ? userRole[0].name : userRole;
    },
    /**
     * @param {number} userId
     * @param {string} userUuid
     * @param {string} groupPermissionName
     */
    handleUserPermission(userId, userUuid, groupPermissionName = null) {
      this.$refs.modalUserPermission.openModal(
        userId,
        userUuid,
        this.$_authenticatedUser.id,
        groupPermissionName
      );
    },
    handleCreateModal() {
      this.$refs.modalCreateUser.handleCreateModal();
    },
    /**
     * @param {string} uuid
     */
    handleEditUser(uuid) {
      this.$refs.modalEditUser.handleCreateModal(uuid);
    },
    /**
     * Atualiza centrais no card após realizar alocação nas permissões do usuário.
     * @param {object} user
     * @param {object} permissionChangeAt
     */
    handleRefreshPage() {
      this.listItems();
    },
    async handleUserPlants(user) {
      if (user.has_access_to_all_plants) return;

      const userFound = this.findUserPlants(user.id);

      if (userFound && userFound.legth > 0) {
        this.$set(this, "userPlants", userFound);

        return;
      }

      try {
        this.isLoading = true;
        const plants = await this.getUserPlants(user.uuid);
        this.userPlants = plants || [];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Erro ao carregar centrais!",
        });
        this.userPlants = [];
      } finally {
        this.isLoading = false;
      }
    },
    async getUserPlants(userUuid) {
      try {
        const response = await this.$store.dispatch(
          "user/getUserPlants",
          userUuid
        );
        return response && response.data ? response.data : [];
      } catch (e) {
        this.$notify({
          type: "danger",
          message: "Erro ao carregar centrais!",
        });

        return [];
      }
    },
    findUserPlants(userId) {
      const userPlantsAlreadyFetched = this.$_getUserPlants.find((user) => {
        return Number(user?.[0]?.user_id) === Number(userId);
      });

      return userPlantsAlreadyFetched || [];
    },
    storedAfterCenterAllocation(user, permissionChange) {
      let foundUser = this.users.find((item) => item.uuid == user.user_uuid);
      if (foundUser) {
        foundUser.plant = user.plants;
        this.$store.dispatch("userRole/show", foundUser.id).then((response) => {
          foundUser.role[0].name = response.data.role[0].name;
        });
        foundUser.permission_change_at = permissionChange.permission_change_at;
        foundUser.changer_name = permissionChange.changer_name;
      }
    },

    /**
     * @param {object} user
     */
    getFirstAndLastName(user) {
      let fullName = user.name;
      let names = fullName.split(" ");
      return names.length >= 2
        ? `${names[0]} ${names[names.length - 1]}`
        : fullName;
    },
    /**
     * @param {object} user
     */
    changeStatus(user) {
      let newStatus = +!user.status;
      this.$store
        .dispatch("user/updateStatus", { id: user.id, status: newStatus })
        .then((response) => {
          if (response.data) {
            user.status = newStatus;
            this.$notify({
              type: "success",
              message: "Status atualizado com sucesso!",
            });
          } else {
            this.$notify({
              type: "danger",
              message: "Erro ao atualizar status!",
            });
          }
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: "Erro ao atualizar status!",
          });
        });
    },
    handleUserExtraInfo(id, uuid, name, email, phone) {
      this.userInfo.id = id;
      this.userInfo.uuid = uuid;
      this.userInfo.name = name || "";
      this.userInfo.email = email || "";
      this.userInfo.phone = phone || "";
      this.$refs.modalUserExtraInfo.openModal({ ...this.userInfo });
    },
    showUserBankAccountIds(userbankAccountId) {
      const userBankAccountList = this.$_bank_accounts.find(
        ({ id }) => id === userbankAccountId
      );
      if (!userBankAccountList || !userBankAccountList.name) {
        return "";
      }
      return userBankAccountList.name;
    },
    handleViewTypeChange(type) {
      this.listType = type;
    },
    resetSelectedUserType() {
      this.filter.is_user = null;
      this.filter.driver = null;
      this.filter.is_seller = null;
    },
    /**
     * @param {TabSelectItemType} item
     */
    onTabSelectItemClick(item) {
      this.resetSelectedUserType();

      switch (item.id) {
        case UserTypeEnum.USERS:
          this.filter.is_user = 1;
          break;
        case UserTypeEnum.DRIVERS:
          this.filter.driver = 1;
          break;
        case UserTypeEnum.SELLERS:
          this.filter.is_seller = 1;
          break;
        case UserTypeEnum.ALL:
          break;
        default:
          return;
      }

      this.listItems();
    },
    /**
     * Padrão de filtro da barra de pesquisa
     * @returns {SearchBarFilterType}
     *
     */
    defaultSearchBarFilter() {
      return initSearchBarFilterType();
    },
    /**
     * Padrão do filtro principal
     * @returns {UsersListFilterType}
     *
     */
    defaultFilter() {
      return {
        ...initUsersListFilterType(),
      };
    },
    /**
     * Listar itens
     * @param {boolean} isAccumulateItems
     */
    listItems(isAccumulateItems = false) {
      this.isLoading = true;
      if (!this.startCursor(this.filter, isAccumulateItems)) {
        this.isLoading = false;
        return;
      }
      this.prepareFilter();
      this.$store
        .dispatch("user/fetchItemsPaginateNewApi", this.filter)
        .then((res) => {
          return this.resolveCursor(res, this.filter);
        })
        .finally(() => {
          this.isLoading = false;
          this.loadingSkeleton = false;
        });
    },
    /**
     * Preparar filtro antes da listagem
     */
    prepareFilter() {
      this.filter.custom_search.values = this.searchBarFilter.custom_search_values;
      this.filter.order_by = this.orderBy.items[this.orderBy.selected].filter;
      this.filter.company_plant_id = this.searchBarFilter.company_plant_selected;
      const dateTypes = [null, "created_at", "updated_at"];
      this.filter.date_type = dateTypes[this.searchBarFilter.range.selected];
    },
    /**
     * Limpar os filtros e listar os itens caso especificado
     * @param {boolean} isRefreshList - Indica se deve listar os itens
     */
    clearFilter(isRefreshList = true) {
      Object.assign(this.searchBarFilter, this.defaultSearchBarFilter());
      Object.assign(this.filter, this.defaultFilter());
      if (isRefreshList) {
        this.listItems();
      }
    },
    getBankAccounts() {
      this.$store.dispatch("bankAccount/fetchItems", {
        allocated: 0,
        status: true,
      });
    },
  },
  mounted() {
    this.loadingSkeleton = true;
    this.getBankAccounts();
    this.clearFilter();
  },
};
</script>

<style scoped lang="scss">
$default-color-gray: #e8e8e8;
$default-color-dark-gray: #cfcfcf;

.dropdown-toggle::after {
  background-image: url("/img/icons/more-than.png");
}

.header h2 {
  filter: brightness(0) invert(1);
  display: none;
}

.espacamento-provisorio {
  border-color: transparent !important;
  padding: 3px;
  margin: 0;
}

.base-button:hover .invert-on-hover,
.btn:hover .invert-on-hover {
  filter: brightness(0) invert(1);
}

.header-card {
  padding-bottom: 25px;
  margin-left: auto;
  margin-top: -10px;
}

/* TABLE */
/* Tirando boxshadow do card em volta da table */
.card-body .card {
  box-shadow: none !important;
  border: none;
}

.table {
  $default-table-text-color: #2b2d32;

  border-collapse: separate;
  border-spacing: 2px 2px;

  th,
  td {
    box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15) !important;
    -webkit-box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15) !important;
    color: $default-table-text-color;
    vertical-align: middle;
    border-radius: 5px !important;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    @media screen and (max-width: 768px) {
      min-width: max-content;

      .permission-mobile {
        min-width: 190px;
      }
    }
  }

  th {
    padding-left: 8px;
    padding-right: 8px;
    background: #f5f5f5;
    font-family: "Quicksand" !important;
    font-size: 0.7rem !important;
    font-weight: 700 !important;
    letter-spacing: 1px;
  }

  td {
    font-weight: 400 !important;
    font-family: Fredoka !important;
    font-size: 10px !important;
    padding-left: 8px;
    padding-right: 8px;
    background-color: white;
  }

  .table-text {
    font-size: 12px !important;
    padding-right: 1rem;
  }

  .active-badge {
    background-color: #f2f7f3;
    width: 100%;
    font-size: 12px !important;
    font-weight: 400 !important;
    border-radius: 100px !important;
    min-width: 100px;
  }
}

/* CARDS */
.card-wrapper .card {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: 16px !important;
  border: 1px solid #e8e8e8;
  margin-bottom: 1rem;
  min-height: 180px;

  .dropdown {
    margin-left: auto;
  }

  .badge {
    font-size: 12px !important;
    font-weight: 400 !important;
  }

  .card-header {
    border-bottom: none !important;
    box-shadow: none !important;
    padding-bottom: 0;
    border-radius: 16px !important;
  }

  .card-body {
    padding-top: 12px;

    & * {
      line-height: 1;
    }

    h6 {
      color: #2b2d32;
      font-weight: 400 !important;
      font-size: 14px;
    }

    .user-role {
      font-size: 12px;
      font-weight: 300 !important;
      margin-bottom: 12px;
    }
  }

  .active-badge {
    border-radius: 100px;
    min-height: 28px !important;
    min-width: 125px !important;
  }
}

/* ACTION BUTTON */
.dropdown-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

/** Popovers */
.popover-title {
  font-family: Fredoka;
  font-size: 16px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  position: relative;
}

.popover-text {
  font-family: Fredoka;
  font-size: 12px;
  font-weight: 300;
}
.popover-title-border {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  height: 1px;
  width: 100%;
  position: relative;
  border-color: #e8e8e8 !important;

  &.full {
    border-color: transparent !important;
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: #e8e8e8;
      top: 50%;
      transform: translate(-12px, 50%);
    }
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      top: 50%;
      transform: translate(12px, -50%);
      background-color: #e8e8e8;
    }
  }
}
</style>
