<template>
  <div
    class="header"
  >
    <div
      class="container-fluid"
      style="padding-left: 0 !important;padding-right: 0 !important;"
    >
      <div
        class="row align-items-center"
      >
        <div
          class="col"
        >
          <div
            class="row d-flex justify-content-end"
          >
            <slot></slot>
            <div
              v-if="isBackButtonEnabled"
              @click.prevent="$router.go(-1)"
              class="new-puzl-default"
            >
              <base-button
                v-if="!isDropdown"
                type="light" block
                class="new-puzl-default"
                style="border-radius: 30px !important; border: none;"
              >
                <img
                  src="/img/icons/circled-left.png" width="16"
                >
                Voltar
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'page-header-action-buttons',
  data(){
    return {
      prevRoute: null,
    }
  },
  props: {
    isDropdown: {
      type: Boolean,
      description: 'Indica se o botão funcionará como dropdown abrindo slots para um ou mais itens.',
      default: false
    },
    isBackButtonEnabled: {
      type: Boolean,
      description: 'Habilita o botão voltar no cabeçalho',
      default: false,
    },
  },
  mounted() {
    this.prevRoute = this.$router.history._startLocation
  }
};
</script>

<style scoped>
div.new-puzl-default {
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 5px;
  display: block;
}

.new-puzl-default {
  font-family: Fredoka;
  font-weight: 400 !important;
  font-size: 12px !important;
  min-height: 35px !important;
  max-height: 35px !important;
  width: 100%;
}

button.new-puzl-default {
  display: flex;
  align-items: center;
}

.new-puzl-default img {
  margin-right: 8px;
}

@media (min-width: 1200px) {
  div.new-puzl-default {
    margin-left: 0px;
    margin-top: 0px;
  }

  .new-puzl-default {
    min-width: 205px;
    max-width: 205px;
    display: flex;
  }
}
</style>
