import * as types from './mutation_types'
import {destroy} from '@/store/baseStore'
import { createAxios } from "@/plugins/axios";
const endPoint = '/configuration/product-service-hub/price/'
export default {
  namespaced: true,
  state: {
    currentPrices:[],
    history: [],
  },
  getters:{
    history: state => state.history,
    currentPrices: state => state.currentPrices,
  },
  mutations: {
    [types.SET_HISTORY] (state, payload){
      state.history = payload
    },
    [types.SET_CURRENT_PRICES] (state, payload){
      state.currentPrices = payload
    },
    [types.DESTROY] (state, id){
      destroy(state,id,'history')
    },
  },
  actions: {
    add({}, payload) {
      return createAxios().post(endPoint, payload)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    update({}, payload) {
      return createAxios().put(endPoint+payload.id, payload)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    destroy({commit}, payload) {
      return createAxios().delete(endPoint+payload)
        .then(({data}) =>{
          commit(types.DESTROY, payload)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getHistory({commit,state}, payload) {
      return createAxios().get(`${endPoint}history/hub/${payload.product_service_hub_id}/entity/${payload.entity_id}`)
        .then(({data}) =>{
          commit(types.SET_HISTORY, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    getCurrentPricesByEntity({commit}, payload) {
      return createAxios().get(`${endPoint}current/entity/${payload.filter.entity_id}`,{params: payload})
        .then(({data}) =>{
          commit(types.SET_CURRENT_PRICES, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
  }
}
