<template>
  <div>
    <card
      class="container-fluid pb-3 d-flex"
      v-for="(item, index) in items"
      :key="index"
    >
      <Item
        :item="item"
        @update-checklist-item="handleUpdate"
      />
    </card>
    <PuzlEmptyData v-if="!loading && (!items || !items.length)"></PuzlEmptyData>
  </div>
</template>

<script>
import Item from "./_Item";
import PuzlEmptyData from "@/components/PuzlEmptyData";
export default {
  name: "List",
  inheritAttrs: false,
  components: {
    Item,
    PuzlEmptyData,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleUpdate(uuid) {
      this.$emit("update-checklist-item", uuid);
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
span {
  font-family: "Fredoka";
}

/deep/ div.card {
  margin-bottom: 10px;
}

/deep/ div.card > div.card-body {
  padding-left: 0;
  padding-bottom: 0;
}
</style>
