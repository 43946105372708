<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title ml-2">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <div class="col-md-12 text-center">
            <div class="checklist-item checklist-item mt-1">
              <div class="checklist-info">
                <span class="text-center checklist-title mb-0">
                  {{ tax.name }}
                </span>
              </div>
            </div>
          </div>
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(store)"
            autocomplete="off"
          >
            <div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 col-form-label form-control-label">
                  Status
                </label>
                <div class="col-md-7 pt-2">
                  <base-switch
                    v-model="tribute.status"
                    type="success"
                    offText="inativo"
                    onText="ativo"
                    class="success"
                  ></base-switch>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 col-form-label form-control-label">
                  Abrangência Total
                </label>
                <div class="col-md-7 pt-2">
                  <base-switch
                    v-model="tribute.full_coverage"
                    type="success"
                    offText="Não"
                    onText="Sim"
                    class="success"
                  ></base-switch>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                <label
                  class="col-md-5 pt-1 pb-0 col-form-label form-control-label"
                >
                  Regime Tributário <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-classes="form-control form-control-sm">
                      <puzl-select 
                        v-model="tribute.regime"
                        :items="[
                          {id: 'Lucro Real', name: 'Lucro Real'}, 
                          {id: 'Lucro Presumido', name: 'Lucro Presumido'},
                          {id: 'Simples Nacional', name: 'Simples Nacional'},
                          {id: 'Mei', name: 'Mei'},
                        ]" 
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div v-if="!tribute.full_coverage">
                <div
                  class="form-group row m-0 p-0"
                  v-if="tax.coverage == 'Federal'"
                >
                  <label
                    class="col-md-5 pt-1 pb-0 col-form-label form-control-label"
                  >
                    País
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-7">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <base-input input-classes="form-control form-control-sm">
                        <puzl-select 
                          v-model="tribute.country" 
                          :items="countries" 
                          customKey="letter"  
                          label="letter" 
                          @input="getCountries()" />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div
                  class="form-group row m-0 p-0"
                  v-if="tax.coverage == 'Estadual'"
                >
                  <label
                    class="col-md-5 pt-1 pb-0 col-form-label form-control-label"
                  >
                    País
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-7 mb-2">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <base-input input-classes="form-control form-control-sm">
                        <puzl-select 
                          v-model="tribute.country" 
                          :items="countries" 
                          customKey="letter"  
                          label="letter" 
                          @input="getStates()" />
                      </base-input>
                    </validation-provider>
                  </div>
                  <label
                    class="col-md-5 pt-1 pb-0 col-form-label form-control-label"
                  >
                    UF
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-7 mb-2">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <base-input input-classes="form-control form-control-sm">
                        <puzl-select 
                          v-model="tribute.state" 
                          :items="states" 
                          customKey="letter"  
                          label="letter"
                          :disabled="!countries.length || loadingCountries" />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div
                  class="form-group row m-0 p-0"
                  v-if="tax.coverage == 'Municipal'"
                >
                  <label
                    class="col-md-5 pt-1 pb-0 col-form-label form-control-label mb-2"
                  >
                    País
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-7">
                    <validation-provider
                      :rules="tribute.full_coverage ? '' : 'required'"
                      v-slot="{ errors }"
                    >
                      <base-input input-classes="form-control form-control-sm">
                        <puzl-select 
                          v-model="tribute.country" 
                          :items="countries" 
                          customKey="letter" 
                          label="letter" 
                          @input="getStates()" />
                      </base-input>
                    </validation-provider>
                  </div>
                  <label
                    class="col-md-5 pt-1 pb-0 col-form-label form-control-label mt-2"
                  >
                    UF
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-7 mb-2 mt-2">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <base-input input-classes="form-control form-control-sm">
                        <puzl-select 
                          v-model="tribute.state" 
                          :items="states" 
                          customKey="letter"  
                          label="letter"
                          @input="getCities()"
                          :disabled="!states.length || loadingStates" />
                      </base-input>
                    </validation-provider>
                  </div>
                  <template>
                    <label
                      class="col-md-5 pt-1 pb-0 col-form-label form-control-label"
                    >
                      Município
                      <span
                        v-if="tax.coverage == 'Municipal'"
                        class="text-danger"
                        >&nbsp;*</span
                      >
                    </label>
                    <div class="col-md-7">
                      <validation-provider
                        :rules="tax.coverage == 'Municipal' ? 'required' : ''"
                        v-slot="{ errors }"
                      >
                        <base-input
                          input-classes="form-control form-control-sm"
                        >
                          <puzl-select 
                            v-model="tribute.city" 
                            :items="cities" 
                            customKey="title"  
                            label="title"
                            :disabled="!cities.length || loadingCities" />
                        </base-input>
                      </validation-provider>
                    </div>
                  </template>
                </div>                
              </div>
              <div class="form-group row mt-0 p-3 mb-2">
                <label class="col-md-5 pt-2 pb-0 col-form-label form-control-label">
                  Dia do Vencimento
                </label>
                <div class="col-md-7 mt-0">
                  <base-input input-group-classes="input-group-sm">
                    <input-limit
                      :options="{min: 0, max: 31}" 
                      max_length="2"
                      :start_value="tribute.expiration_day"
                      v-model="tribute.expiration_day"
                      @value="tribute.expiration_day = $event"
                    />
                  </base-input>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()"
                >Cancelar
              </base-button>
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                :loading="loadingStore"
                >Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
import InputLimit from "@/components/Utils/InputLimit";
export default {
  name: "ModalCreateTributes",
  components: { 
    PuzlSelect,
    InputLimit,
  },
  props: {
    showDeductionRulesModal: { type: Function },
    updateScreen: { type: Function },
  },
  data() {
    return {
      modal: {
        title: "Tributos",
        create: false,
      },
      loadingStore: false,
      loadingCities: false,
      loadingStates: false,
      loadingCountries: false,
      tribute: {
        status: true,
        state: "",
        city: "",
        country: "",
        regime: "",
        full_coverage: false,
        allow_issqn_retention_of_legal_person: false,
        allow_issqn_retention_of_natural_person: false,
        allow_issuance_without_customer_municipal_registration: false,
        expiration_day: 0,
      },
      tax: {
        status: true,
        name: "",
        coverage: "",
      },
      Cities: [],
      Countries: [],
      States: [],
    };
  },
  computed: {
    ...mapGetters({
      states: "localizations/states",
      cities: "localizations/cities",
      countries: "localizations/countries",
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    handleCreateModal(tax, state, city) {
      this.tribute = {
        status: true,
        state: "",
        city: "",
        country: "BR",
        regime: "",
        allow_issqn_retention_of_legal_person: true,
        allow_issqn_retention_of_natural_person: false,
        allow_issuance_without_customer_municipal_registration: true,
        expiration_day: 0,
      };
      this.tax = { ...tax };
      this.getStates();

      // Iniciar Cadastro C/ Estado e Cidade Preenchidos
      if ((state) && (city)) {
        this.tribute.state = state;
        this.tribute.city = city;        
        this.getStates();
        this.getCities();
      }

      this.modal.create = true;
    },
    getCountries() {
      this.loadingCountries = true;
      this.loadingStates = true;
      this.tribute.country = null;
      this.Countries = this.countries.filter(
        (item) => item.country_id == this.tribute.country
      );
      this.loadingCountries = false;
    },
    getStates() {
      this.loadingStates = false;
      this.loadingCities = true;
      this.$Progress.start();
      this.$store
        .dispatch("localizations/fetchStates", {
          country: this.tribute.country,
        })
        .then((response) => {
          this.loaders--;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loaders--;
          this.$Progress.finish();
        });
    },
    getCities() {
      this.loadingCities = false;
      this.$Progress.start();
      this.$store
        .dispatch("localizations/fetchCities", { uf: this.tribute.state })
        .then((response) => {
          this.loaders--;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loaders--;
          this.$Progress.finish();
        });
    },
    store() {
      let params = {
        commercial_tax_id: this.tax.id,
        status: this.tribute.status,
        full_coverage: this.tribute.full_coverage,
        allow_issqn_retention_of_legal_person: this.tribute.allow_issqn_retention_of_legal_person,
        allow_issqn_retention_of_natural_person: this.tribute.allow_issqn_retention_of_natural_person,
        allow_issuance_without_customer_municipal_registration: this.tribute.allow_issuance_without_customer_municipal_registration,
        state: this.tribute.state,
        city: this.tribute.city,
        country: this.tribute.country,
        regime: this.tribute.regime,
        expiration_day: this.tribute.expiration_day,
      };
      this.loadingStore = true;
      this.$Progress.start();
      this.$store
        .dispatch("TaxLocality/add", params)
        .then((response) => {
          this.loadingStore = false;
          this.modal.create = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.updateScreen();
          this.showDeductionRulesModal(response.data.uuid);
        })
        .catch((error) => {
          this.loadingStore = false;
          this.$notify({ type: "danger", message: "Erro ao cadastrar" });
          if (error.status === 422) {
            let message = formatErrorValidation(error.response.data.errors);
            this.$notify({ type: "danger", message: message });
          } else if (error.status === 200) {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message,
            });
          } else {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message,
            });
          }
          this.$Progress.finish();          
        })
    },
    mounted() {
      this.$refs.formValidator.validate();
      this.loadingCountries = true;
      this.loadingStates = false;
      this.loadingCities = false;
    },
  },
};
</script>

<style scoped></style>
