<template>
  <div class="card card-body mt-n2 p-0" v-if="finances.length">
    <div class="row m-0 mt-3">
      <ShadedTable :length="finances.length"
        :headers="headers"
        :loading="loadingSkeleton">
        <template v-for="(item, index) in finances" :slot="index">
          <ShadedCol align="center" customStyle="display: flex;">
            <div style="display: grid;">
              <base-dropdown v-if="item.status !== statusEnum.CANCELED">
                <div :class="item.status ? 'new-minicard-green' : 'new-minicard-yellow'"
                  slot="title-container"
                  class="new-minicard-table">
                  <div>
                    <img class="mr-2" width="16"
                      :src="getStatus(item.status, item.finance_type_mode).icon">
                    <h4 class="font-weight-normal m-0 p-0 mr-2">
                      {{ getStatus(item.status, item.finance_type_mode).text }}
                    </h4>
                    <img :src="getStatus(item.status, item.finance_type_mode).dropdown" width="12" alt="">
                  </div>
                </div>
                <div v-if="item.status == statusEnum.PENDING">
                  <a @click.prevent="$emit('handleChangeStatus', item, statusAction.POST)"
                    class="dropdown-item">
                    {{ item.finance_type_mode ? "Pagar" : "Receber" }}
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a @click.prevent="$emit('handleChangeStatus', item, statusAction.CANCEL)"
                    class="dropdown-item">
                    Cancelar
                  </a>
                </div>
                <div v-if="item.status == statusEnum.FINISHED">
                  <a @click.prevent="$emit('handleChangeStatus', item, statusAction.REVERT)"
                    class="dropdown-item">
                    Estornar
                  </a>
                </div>
              </base-dropdown>
              <div v-else style="width: 100%;"
                :class="item.status ? 'new-minicard-green' : 'new-minicard-yellow'"
                class="text-center">
                <div style="align-items: center;display: inline-flex;margin-top: 1px;">
                  <img class="mr-2" width="16"
                    :src="getStatus(item.status, item.finance_type_mode).icon">
                  <h4 class="font-weight-normal m-0 p-0 mr-2">
                    {{ getStatus(item.status, item.finance_type_mode).text }}
                  </h4>
                </div>
              </div>
            </div>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.entity_name && item.entity_name }}
              <i class="new-default-gray-font fs-10"
                v-if="!item.entity_name">
                Não informado
              </i>
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.entity_document }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.finance_type_name }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.expire_at | parseDate("DD MMM YYYY") }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <div style="display: flex;align-items: center;">
              <p v-if="!item.finance_type_mode" class="m-0 mr-1 text-success">+</p>
              <p v-else class="m-0 mr-1 text-danger">-</p>
              <h4 class="m-0 new-default-black-font">{{ item.amount | currency }}</h4>
            </div>
          </ShadedCol>
          <ShadedCol align="center">
            <base-dropdown menuOnRight>
              <div slot="title-container" class="dropdown-toggle rounded m-0">
                <img width="22" src="/img/icons/icons8/ios/settings--v1_primary.png" />
              </div>
              <a v-if="item.status !== statusEnum.FINISHED" @click.prevent="$emit('handleEditFinance', item.finance_id)"
                class="dropdown-item font-weight-500 new-default-black-font"
                style="align-items: center; display: flex">
                <img width="18" src="/img/icons/edit.png" />
                EDITAR
              </a>
              <div v-if="item.status !== statusEnum.FINISHED" class="dropdown-divider p-0 m-0"></div>
              <a @click.prevent="$emit('handleHistoryOperationsModal', item)"
                class="dropdown-item font-weight-500 new-default-black-font"
                style="align-items: center; display: flex">
                <img width="18" src="/img/icons/icons8/ios/order-history_secondary.png" />
                HISTÓRICO
              </a>
              <div class="dropdown-divider p-0 m-0"></div>
              <a @click.prevent="$emit('handleDeleteFinance', item.finance_id)"
                class="dropdown-item font-weight-500 new-default-black-font"
                style="align-items: center; display: flex">
                <img width="18" src="/img/icons/delete.png" />
                EXCLUIR
              </a>
            </base-dropdown>
          </ShadedCol>
        </template>
      </ShadedTable>
    </div>
  </div>
</template>
<script>
import ShadedTable from "@/components/ShadedTable.vue";
import ShadedCol from "@/components/ShadedCol.vue";
import { STATUS_ENUM } from "./Enums/StatusEnum";
import { STATUS_ACTION } from "./Enums/StatusAction";

export default {
  name: "TableFinances",
  props: {
    finances: Array,
    loadingSkeleton: Boolean,
    getStatus: { type: Function },
  },
  components: {
    ShadedTable,
    ShadedCol,
  },
  data(){
    return {
      headers: ["Status", "Interveniente", "CPF/CNPJ", "Descrição", "Data", "Valor", "Ação"],
      statusEnum: STATUS_ENUM,
      statusAction: STATUS_ACTION,
    }
  },
}
</script>
<style scoped>
* {
  font-family: Fredoka;
}

.new-minicard-table {
  display: block;
}

.new-minicard-table div {
  display: inline-flex;
  align-items: center;
  margin-top: 1px;
}

.new-cardwithbox-shadow {
  text-align: center;
  box-shadow: 0 5px 10px 0 #f3f3f3;
  display: flex;
  border: 1px solid #E8E8E8;
  border-radius: 5px !important;
  align-items: center;
  padding: 4px 0px;
}

.new-minicard-green {
  background-color: #F2F7F3;
  border-radius: 15px;
  padding: 5px 12px;
  font-size: 12px;
}

.new-minicard-red {
  background-color: #FCF3F3;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-yellow {
  background-color: #FEF9F2;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-blue {
  background-color: #F2F4F9;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-gray {
  background-color: #646e7d2e;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-green h4 {
  color: #149e57;
}

.new-minicard-red h4 {
  color: #db4539;
}

.new-minicard-yellow h4 {
  color: #f2b532;
}

.new-minicard-blue h4 {
  color: #1b6eba;
}

.new-minicard-gray h4 {
  color: #66696c;
}

.new-minicard-red, .new-minicard-green, .new-minicard-yellow, .new-minicard-blue, .new-minicard-gray {
  align-items: center;
  display: block;
  text-align: center;
}

.new-minicard-red img:first-child, .new-minicard-green img:first-child, .new-minicard-yellow img:first-child, .new-minicard-blue img:first-child, .new-minicard-gray img:first-child {
  width: 16px;
}

.new-minicard-red h4, .new-minicard-green h4, .new-minicard-yellow h4, .new-minicard-blue h4, .new-minicard-gray h4 {
  font-size: 12px;
  margin: 0 6px 0 6px;
  font-weight: normal !important;
}
</style>
