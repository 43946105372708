import * as types from "./mutation_types";
import {
  destroy,
  update,
  cursorPaginate,
  setUrlWithNextPage,
} from "@/store/baseStore";
import { createAxios } from "@/plugins/axios";;
const endPoint = "/equipment/checklist";
export default {
  namespaced: true,
  state: {
    items: [],
    item: {},
  },
  getters: {
    show: (state) => state.item,
    fetch: (state) => state.items,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload;
    },
    [types.SHOW](state, payload) {
      state.item = payload;
    },
    [types.PUSH](state, payload) {
      state.items.push(payload);
    },
    [types.DESTROY](state, id) {
      destroy(state, id);
    },
    [types.UPDATE](state, payload) {
      update(state, payload);
    },
  },
  actions: {
    fetch({ commit }, checklistUuid) {
      return createAxios()
        .get(`${endPoint}/${checklistUuid}/item`)
        .then(({ data }) => {
          commit(types.SET, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    add({ commit }, payload) {
      return createAxios()
        .post(`${endPoint}/${payload.checklist_uuid}/item`, payload)
        .then(({ data }) => {
          commit(types.PUSH, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    show({ commit }, { checklistUuid, uuid }) {
      return createAxios()
        .get(`${endPoint}/${checklistUuid}/item/${uuid}`)
        .then(({ data }) => {
          commit(types.SHOW, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    update({ commit }, params) {
      return createAxios()
        .put(`${endPoint}/${params.checklist_uuid}/item/${params.uuid}`, params)
        .then(({ data }) => {
          commit(types.UPDATE, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    destroy({ commit }, { checklistUuid, uuid }) {
      return createAxios()
        .delete(`${endPoint}/${checklistUuid}/item/${uuid}`)
        .then(({ data }) => {
          commit(types.DESTROY, uuid);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    updateStatus({ commit }, params) {
      return createAxios()
        .put(
          `${endPoint}/${params.checklist_uuid}/item/${params.uuid}/update-status`,
          params
        )
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
};
