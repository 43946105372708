<template>
  <div>
    <div class="container-fluid">
      <multi-filter @fetch="fetch" :filter="filter">
        <div class="col-md-3 p-2">
          <base-button :class="filter.low && 'active'" @click="getLow()" outline block type="primary">
            BAIXA
          </base-button>
        </div>
        <div class="col-md-3 p-2">
          <base-button :class="filter.mid && 'active'" @click="getMid()" outline block type="primary">
            MÉDIA
          </base-button>
        </div>
        <div class="col-md-3 p-2">
          <base-button :class="filter.high && 'active'" @click="getHigh()" outline block type="primary">
            ALTA
          </base-button>
        </div>
      </multi-filter>
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
      </div>

      <PuzlEmptyData v-if="!$_concretePieces.length && !loadingSkeleton"></PuzlEmptyData>

      <div class="row card-wrapper" v-if="$_concretePieces.length && !loadingSkeleton">
        <div class="col-lg-4" v-for="(concretePiece, index) in $_concretePieces" :key="index">
          <!-- Basic with action button -->
          <div class="card">
            <div class="card-header pointer" style="padding: 0px !important; height: 22px">
              <div class="row">
                <div class="col-md-12">
                  <base-button
                    @click="changeStatus(concretePiece)" size="sm"
                    :style="{
                      backgroundColor: concretePiece.status == true &&
                      concretePiece.customer_puzl == false ? '#149E57' : '#DB4539'
                    }"
                    class="text-white text-uppercase btn-header" style="opacity: 100%; color: #4f40ff; border: none;"
                    block
                  >
                    {{ concretePiece.status == true && concretePiece.customer_puzl == false ? "Ativo" : "Inativo" }}
                  </base-button>
                </div>
              </div>
            </div>
            <!-- Card header -->
            <div class="card-body">
              <div class="row align-items-center mb-3">
                <div class="col-8">
                  <!-- Title -->
                  <h5 class="h4 mb-0 font-weight-500">
                    {{ concretePiece.description }}
                  </h5>
                </div>

                <div class="col-4 text-right">
                  <base-dropdown menuOnRight>
                    <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                      <i class="fas fa-cog"></i>
                    </base-button>
                    <a class="dropdown-item" @click="handleEditConcretePiece(concretePiece.uuid)">
                      <i class="fas fa-edit text-warning"></i>
                      Editar
                    </a>
                    <div class="dropdown-divider p-0 m-0"></div>
                    <a class="dropdown-item" @click="handleDeleteConcretePiece(concretePiece.uuid)">
                      <i class="fas fa-times text-danger"></i>
                      Excluir
                    </a>
                    <a class="dropdown-item" @click="handleHistoryConcretePiece(concretePiece)">
                      <img src="/img/icons/order-history.png" width="22px" height="22px" />
                      Histórico
                    </a>
                  </base-dropdown>
                </div>

                <!-- Linha fina na horizontal -->
                <div class="col-12 horizontal-line"></div>

                <!-- Card body -->
                <!-- Responsabilidades -->
                <div class="col-12">
                  <h4>Nível de Aplicação:</h4>
                </div>
                <div class="col-md-4 p-1">
                  <base-button
                    :class="concretePiece.level_feature.includes(1) ? 'active' : null"
                    :disabled="buttonDisabled"
                    @click.prevent="updateLevelFeature(concretePiece, 1)"
                    outline block
                    type="primary"
                  >
                    BAIXA
                  </base-button>
                </div>
                <div class="col-md-4 p-1">
                  <base-button
                    :class="concretePiece.level_feature.includes(2) ? 'active' : null"
                    :disabled="buttonDisabled"
                    @click.prevent="updateLevelFeature(concretePiece, 2)"
                    outline block
                    type="primary"
                  >
                    MÉDIA
                  </base-button>
                </div>
                <div class="col-md-4 p-1">
                  <base-button
                    :class="concretePiece.level_feature.includes(3) ? 'active' : null"
                    :disabled="buttonDisabled"
                    @click.prevent="updateLevelFeature(concretePiece, 3)"
                    outline block
                    type="primary"
                  >
                    ALTA
                  </base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <edit-concrete-piece ref="editConcretePiece"></edit-concrete-piece>
    <history-concrete-piece ref="historyConcretePiece"></history-concrete-piece>
  </div>
</template>

<script>
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import { mapGetters } from 'vuex'
import EditConcretePiece from './_Edit'
import HistoryConcretePiece from './_History.vue';
import { formatErrorValidation } from "@/plugins";
import MultiFilter from "@/components/Utils/MultiFilterV2";
import PuzlEmptyData from "@/components/PuzlEmptyData";

export default {
  name: "List",
  components: {
    MultiFilter,
    SkeletonPuzlGrid,
    EditConcretePiece,
    PuzlEmptyData,
    HistoryConcretePiece
  },
  data() {
    return {
      buttonDisabled: false,
      loadingSkeleton: false,
      filter: {
        low: null,
        mid: null,
        high: null,
      }
    }
  },
  computed: {
    ...mapGetters({
      '$_concretePieces': 'mixConcretePiece/fetch',
    }),
  },
  methods: {
    handleHistoryConcretePiece(concretePiece) {
      this.$refs.historyConcretePiece.handleHistoryModal(concretePiece.id);
    },
    fetch(filter = {}) {
      this.filter = filter
      this.loadingSkeleton = true
      this.$store.dispatch('mixConcretePiece/fetchItems', { filter: this.filter })
        .then(response => {
          this.loadingSkeleton = false
        })
        .catch(error => {
          this.loadingSkeleton = false
        })
    },
    handleEditConcretePiece(uuid) {
      this.$refs.editConcretePiece.handleEditModal(uuid)
    },
    handleDeleteConcretePiece(uuid) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$notify({ type: 'info', message: 'Estamos trabalhando em sua solicitação.' });
          this.$store.dispatch('mixConcretePiece/destroy', uuid)
            .then(response => {
              this.$Progress.finish()
              this.$notify({ type: response.error_type, message: response.message })
            })
            .catch(error => {
              if (error.status == 200) {
                this.$notify({
                  type: "danger",
                  message: error.data.message
                });
                this.$Progress.finish();
              } else if (error.response && error.response.status === 422) {
                let errors = formatErrorValidation(error.response.data.errors);
                this.$notify({
                  type: "danger",
                  message: errors
                });
              }
              this.$Progress.finish();
            });
          this.$Progress.finish();
        }
      }).catch(() => this.$Progress.finish())
    },
    /**
     * @param {object} concreteṔiece
     */
    changeStatus(concreteṔiece) {

      const previousStatus = concreteṔiece.status;
      concreteṔiece.status = +!concreteṔiece.status;
      this.$store.dispatch("mixConcretePiece/updateStatus", concreteṔiece).then((response) => {
        if (response.data) {
          this.$notify({
            type: "success",
            message: "Status atualizado com sucesso!"
          });
        } else {
          concreteṔiece.status = previousStatus;
          this.$notify({
            type: "danger",
            message: "Erro ao atualizar status!"
          });
        }
      }).catch((error) => {
        concreteṔiece.status = previousStatus;
        this.$notify({
          type: "danger",
          message: "Erro ao atualizar status!"
        });
      });
    },
    /**
     * @param {object} concreteṔiece
     * @param {number} levelFeature
     */
    updateLevelFeature(concreteṔiece, levelFeature) {

      this.buttonDisabled = true;

      setTimeout(() => {
        this.buttonDisabled = false;
      }, 1000);

      let isLevelFeatureIncluded = concreteṔiece.level_feature.includes(levelFeature) ? 1 : 0;
      let mixConcretePiece = {
        id: concreteṔiece.id,
        levelFeature: levelFeature,
        addOrRemove: isLevelFeatureIncluded,
        user: this.$user.id
      };

      this.$store.dispatch("mixConcretePiece/updateLevelFeature", mixConcretePiece).then((response) => {
        this.$notify({
            type: "success",
            message: "Nível de aplicação atualizado com sucesso!"
          });
      }).catch((error) => {
        this.$notify({
            type: "danger",
            message: error
          });
      });
    },
    getLow() {
      this.filter.low = this.filter.low === null ? 1 : null;
      this.fetch(this.filter);
    },
    getMid() {
      this.filter.mid = this.filter.mid === null ? 2 : null;
      this.fetch(this.filter);
    },
    getHigh() {
      this.filter.high = this.filter.high === null ? 3 : null;
      this.fetch(this.filter);
    },
  },
  mounted() {
    this.fetch()
    this.filter = {
        low: null,
        mid: null,
        high: null,
    };
  },
  created() {

  }
}
</script>

<style scoped>
.horizontal-line {
  border-bottom: 1px solid #1a70b7;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
