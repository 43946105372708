import * as types from './mutation_types'
import { destroy, update } from '@/store/baseStore'
import { createAxios } from "@/plugins/axios";
const endPoint = 'configuration/user/user_details/'
export default {
  namespaced: true,
  state: {
    item: [],
  },
  getters: {
    show: state => state.item,
  },
  mutations: {
    [types.SHOW](state, payload) {
      state.item = payload
    },
  },
  actions: {
    add({ commit }, { payload, user_uuid }) {
      return createAxios().post(endPoint + user_uuid, payload)
        .then(({ data }) => {
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    show({ commit }, uuid) {
      return createAxios().get(endPoint + uuid)
        .then(({ data }) => {
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
