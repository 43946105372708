<template>
  <div>
    <modal :show.sync="modal" :backgroundColor="'#606062'">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(save)"
            autocomplete="off"
          >
            <div>
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-3 pt-1 pb-0 col-form-label form-control-label">
                  Nome cliente
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-9">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="customerConstruction.customer_name"
                        class="form-control form-control-sm"
                        @input="validateName"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                  <span class="text-danger" style="font-size: 10px;">
                    {{ errorMessage }}
                  </span>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="danger" @click="closeModal()">
                <img src="/img/icons/cancel-white.png" width="22px" height="22px" class="mr-2" />
                Cancelar
              </base-button>
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                :loading="loadingSave"
              >
                <img src="/img/icons/save.png" width="22px" height="22px" class="mr-2" />
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
const { formatErrorValidation } = require("@/plugins");
import { verifyDocument } from "@/helpers";

export default {
  name: "ModalCreateTemporaryCustomerConstruction",
  data() {
    return {
      title: "Proposta",
      modal: false,
      customerConstruction: {
        status: true,
        customer_name: "",
      },
      loadingSave: false,
      errorMessage: '',
      verifyDocument: verifyDocument,
    };
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
  methods: {
    openModal() {
      this.customerConstruction = {
        status: true,
        customer_name: "",
      };
      this.modal = true;
    },
    closeModal() {
      this.modal = false;
    },
    save() {
      // Caso o usuário tente cadastar um documento no lugar do nome.
      if (this.$helper.verifyDocument(this.customerConstruction.customer_name)) {
        this.$notify({
          type: 'warning',
          message: 'Não é permitido cadastrar um documento!',
        });
        return;
      }

      this.$Progress.start();
      this.loadingSave = true;
      this.$store.dispatch("customerConstruction/storeTemporaryCustomerConstruction", this.customerConstruction)
        .then((response) => {
          this.modal = false;
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          
          this.customerConstruction = response.data;
          this.customerConstruction.byCreateTemporaryCustomer = true;
          this.$router.push({
            name: 'commercial.contract-proposal.create',
            path: '/commercial/contract-proposal/create',
            query: { customerConstruction: JSON.stringify(this.customerConstruction) }
          });
        })
        .catch((error) => {
          if (error.status === 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
        })
        .finally(() => {
          this.$Progress.finish();
          this.loadingSave = false;
        });
    },
    validateName() {
      const nameIsEmpty = this.customerConstruction.customer_name === '';
      const nameStartsWithLetter = /^[a-zA-Z].*$/.test(this.customerConstruction.customer_name);

      if (nameIsEmpty || nameStartsWithLetter) {
        this.errorMessage = '';
      } else {
        this.errorMessage = 'Confira se o nome do cliente começa com números.';
      }
    },
  },
};
</script>

<style scoped></style>
