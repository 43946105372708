import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import ReuseControl from '@/views/Modules/Administrative/ReuseControl/Index.vue';
import DeliveryResume from '@/views/Modules/Administrative/DeliveryResume/Index.vue';
import HoraryResume from '@/views/Modules/Administrative/HoraryResume/Index.vue'
import auth from "@/middleware/auth";
import EntityWallet from "@/views/Modules/Administrative/EntityWallet/Index.vue";
import Dre from '../../modules/administrative/dre/routes/Dre'

const administrative = {
  path: '/administrative',
  component: DashboardLayout,
  name: 'Gerencial',
  meta: {
    middleware: auth,
  },
  children: [
    ...Dre,
    {
      path: '/administrative/reuse-control',
      name: 'Controle de reuso',
      component: ReuseControl,
      meta: {
        title: 'Gerencial',
        description: 'Controle de reuso',
        icon: 'fa fa-home',
        permission: 'administrative.reuse-control.index.view',
        breadcrumbs: [
          {title: 'Dashboard', name: 'administrative.dashboard.index'},
          {title: 'Controle de reuso', name: null},
        ]
      },
    },
    {
      path: '/administrative/delivery-resume',
      name: 'Resumo de entregas',
      component: DeliveryResume,
      meta: {
        title: 'Gerencial',
        description: 'Resumo de entregas',
        icon: 'fa fa-home',
        permission: 'administrative.delivery-resume.index.view',
        breadcrumbs: [
          {title: 'Dashboard', name: 'administrative.dashboard.index'},
          {title: 'Resumo de entregas', name: null},
        ]
      },
    },
    {
      path: '/administrative/horary-resume',
      name: 'Resumo de horários',
      component: HoraryResume,
      meta: {
        global_filter: true,
        title: 'Gerencial',
        description: 'Resumo de horários',
        icon: 'fa fa-home',
        permission: 'administrative.horary-resume.index.view',
        breadcrumbs: [
          {title: 'Dashboard', name: 'administrative.dashboard.index'},
          {title: 'Resumo de horários', name: null},
        ]
      },
    },
    {
      path: '/administrative/entity-wallet',
      name: 'administrative.entity-wallet.index.view',
      component: EntityWallet,
      meta: {
        title: 'Gerencial',
        description: 'Carteira de clientes',
        permission: 'administrative.entity-wallet.index.view',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Dashboard', name: 'administrative.dashboard.index'},
          {title: 'Carteira de clientes', name: null},
        ]
      },
    },
  ]
};

export default administrative;
