<template>
  <div class="mt-n1">
    <base-dropdown ref="dropdown" :closeOnClick="false" menuOnRight style="margin-left: 3.7rem">
      <div slot="title-container" class="dropdown-toggle rounded green-button px-3">
        <img height="22px" src="/img/icons/create-new.png" />
      </div>
      <div class="p-3" style="min-width: 21rem !important; padding-bottom: 0.6rem !important">
        <div class="d-flex align-items-center mb-3">
          <img width="26" src="/img/icons/hand-with-pen-primary.png" alt="hand-with-pen" />
          <span class="text-nowrap">Editar código</span>
        </div>
        <base-input class="col-12 pr-1 mb-1 input-name-email-signatory" v-model="item.external_code" placeholder="CÓDIGO EXTERNO" />
        <div class="col-12 d-flex justify-content-end pr-0">
          <base-button @click="update" type="success" native-type="submit" class="mt-2 align-self-end" size="sm">
            <img width="22" src="/img/icons/save.png" alt="info-squared" class="mr-1" />
            Salvar
          </base-button>
        </div>
      </div>
    </base-dropdown>
  </div>
</template>

<script>
import { formatErrorValidation } from "@/plugins";

export default {
  name: "EditItem",
  props: {
    item: Object,
  },
  methods: {
    update() {
      const loader = this.$loading.show();
      this.$store
        .dispatch("paymentBrand/update", this.item)
        .then(() => {
          this.$notify({ type: "success", message: "Bandeira atualizada com sucesso!" });
        })
        .catch((error) => {
          let errors = "";
          if (error && error.response && error.response.status === 422) {
            errors = formatErrorValidation(error.response.data.data);
          } else {
            errors = error.response.data.message;
          }
          this.$notify({ type: "danger", message: errors });
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};
</script>
