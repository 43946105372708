<template>
  <div>
    <modal :show.sync="modal.open">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Nome
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="alertGroup.name"
                        class="form-control form-control-sm"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>
<script>

export default {
  name: "StoreAlertGroup",
  data () {
    return {
      modal: {
        title: 'Cadastrar grupo de alerta',
        open: false,
      },
      alertGroup: {
        name: '',
      },
      loadingStore: false,
    }
  },
  methods: {
    openModal () {
      this.alertGroup = {
        name: '',
      };
      this.modal.open = true;
    },
    closeModal () {
      this.modal.open = false;
    },
    store() {
      this.$Progress.start();
      this.loadingStore = true;
      this.$store.dispatch('alertGroup/add', this.alertGroup).then((response) => {
            this.$notify({
                type: response.error_type,
                message: response.message
            });
            this.$emit("changeAlertGroup", response.data.id);
            this.closeModal();
        })
        .catch((error) => {
            if (error.status === 200) {
                this.$notify({
                    type: 'danger',
                    message: error.data.message
                });
            } else if (error.response.status === 422) {
                let errors = formatErrorValidation(error.response.data.errors)
                this.$notify({
                    type: 'danger',
                    message: errors
                });
            }
        }).finally(() => {
            this.$Progress.finish();
            this.loadingStore = false;
        });
    },
  }
}
</script>

<style scoped>

</style>
