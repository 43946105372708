<template>
<div>
    <modal :show.sync="modal.create">
        <template slot="header">
            <h5 class="modal-title pl-3">{{ modal.title }}</h5>
        </template>
        <div>
            <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
                <form class="needs-validation" @submit.prevent="handleSubmit(handleCostCenterUpdate)" autocomplete="off">
                    <div>
                        <!-- Status -->
                        <div class="form-group row m-0 p-0">
                            <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                                Status
                                <span class="text-danger">&nbsp;*</span>
                            </label>
                            <div class="col-md-7 pt-2">
                                <base-switch v-model="status" type="success" offText="inativo" onText="ativo" class="success"></base-switch>
                            </div>
                        </div>
                        <!-- Nome -->
                        <div class="form-group row m-0 p-0">
                            <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                                Nome
                                <span class="text-danger">&nbsp;*</span>
                            </label>
                            <div class="col-md-7 mb-1 pr-4 pt-1">
                                <validation-provider rules="required" v-slot="{errors}">
                                    <base-input input-group-classes="input-group-sm">
                                        <input 
                                            v-model="costCenter.name" 
                                            type="text" 
                                            class="form-control form-control-sm" 
                                            :class="errors[0] ? 'is-invalid' : 'is-valid'" />
                                    </base-input>
                                </validation-provider>
                            </div>
                        </div>
                        <!-- Observações -->
                        <div class="form-group row m-0 mb-3 p-0">
                            <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                                Observações
                            </label>
                            <div class="col-md-7 mb-1 pr-4 pt-1">
                                <validation-provider>
                                    <base-input input-group-classes="input-group-sm">
                                        <input v-model="costCenter.observation" type="text" maxlength="250" class="form-control form-control-sm" />
                                    </base-input>
                                </validation-provider>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <base-button type="secondary" @click="closeModal('create')">
                                Cancelar
                            </base-button>
                            <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                                Salvar
                            </base-button>
                        </div>
                    </div>
                </form>
            </validation-observer>
        </div>
    </modal>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'

import {
    formatErrorValidation
} from '@/plugins'

export default {
    name: "EditCostCenter",
    data() {
        return {
            modal: {
                title: 'Centro de Custo',
                create: false,
            },
            status: true,
            costCenter: {
                uuid: null,
                name: null,
                observation: null,
                status: null,
            },
            loadingStore: false,
        }
    },
    computed: {
        ...mapGetters({
            'suppliers': 'costCenter/fetch',
        }),
    },
    methods: {
        closeModal() {
            this.modal.create = false
        },
        handleEditModal(uuid) {
            this.$notify({
                type: 'info',
                message: 'Estamos trabalhando em sua solicitação.'
            })
            this.$Progress.start()
            this.$store.dispatch('costCenter/show', uuid)
                .then((response) => {
                    if (response.data.status === 1) {
                        this.status = true
                    } else {
                        this.status = false
                    }
                    this.costCenter = {
                        uuid: response.data.uuid || '',
                        name: response.data.name || '',
                        observation: response.data.observation || '',
                        status: null,
                    }
                    this.$Progress.finish()
                    this.$notify({
                        type: response.error_type,
                        message: response.message
                    })
                    this.modal.create = true
                })
        },
        handleCostCenterUpdate() {
            this.$Progress.start()
            this.loadingStore = true
            this.costCenter.status = this.status ? 1 : 0
            this.$store.dispatch('costCenter/update', this.costCenter)
                .then(response => {
                    this.loadingStore = false
                    this.modal.create = false
                    this.$Progress.finish()
                    this.$notify({
                        type: response.error_type,
                        message: response.message
                    })
                })
                .catch(error => {
                    if (error.status == 200) {
                        this.$notify({
                            type: 'danger',
                            message: error.data.message
                        })
                        this.$Progress.finish();
                        this.loadingStore = false
                    } else if (error.response && error.response.status === 422) {
                        let errors = formatErrorValidation(error.response.data.errors)
                        this.$notify({
                            type: 'danger',
                            message: errors
                        })
                    }
                    this.$Progress.finish()
                    this.loadingStore = false
                })
        }
    },
    mounted() {
        this.$refs.formValidator.validate();
    },
}
</script>

<style scoped>

</style>
