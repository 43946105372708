<template>
  <base-input :input-group-classes="input_group_classes">
    <input :maxlength="max_length"
           style="height: 28px !important;"
           @change="$emit('change', $event)"
           :disabled="disabled"
           v-model="value"
           :placeholder="placeholder"
           inputmode="numeric"
           class="form-control form-control-sm"
           :class="value == 0 ? '' : value > 0 ? 'is-valid': value && value < 0 ? 'is-invalid' : ''"
    />
    <template slot="append">
      <slot></slot>
    </template>
  </base-input>
</template>

<script>
import {mask1} from "@/plugins";

export default {
  props: {
    options: {
      description: 'Opções de limite',
    },
    placeholder: {
      default: '',
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    start_value: {
      type: [Number, String],
    },
    max_length: {
      type: [Number, String],
      default: () => "6",
    },
    input_group_classes: {
      type: String,
      default: () => "input-group-sm pb-2",
    },
  },
  name: "InputLimit",
  data() {
    return {
      value: 0
    }
  },
  mounted() {
    this.$watch('start_value', function (value) {
      if (!this.value || this.value != value) {
        this.value = value
      }
    }, {
      immediate: true,
      deep: true
    })
  },
  watch: {
    value(after, before) {
      const min = this.options.min !== null ? this.options.min : null
      const max = this.options.max !== null ? this.options.max : null
      if (min && after && min >= 0 && after.toString() && after.toString().includes('-')) {
        this.$notify({
          type: 'danger',
          message: `Valor fora do limite permitido!`
        });
        value = before_item
        this.value = this.$helper.checkNumberValue(value)
        return this.$emit('value', this.value)
      }
      const decimals = this.options.decimals || 0
      let value = after ? mask1(after.toString(), decimals) : after
      let before_item = before ? mask1(before.toString(), decimals) : 0
      if (min !== null && max !== null && value > max || value < min) {
        this.$notify({
          type: 'danger',
          message: `Valor fora do limite permitido!`
        });
        value = before_item
      }
      this.value = this.$helper.checkNumberValue(value)
      return this.$emit('value', this.value)
    }
  }
}
</script>

<style scoped>

</style>
