import * as types from './mutation_types'
import {destroy, update} from '@/store/baseStore'
import { createAxios } from "@/plugins/axios";
const endPoint = '/cmc/group'
export default {
  namespaced: true,
  state: {
    items: [],
    additions: [],
    groups: [],
    item: [],
    group_by_category: [],
  },
  getters:{
    show: state => state.item,
    groups: state => state.groups,
    fetch: state => state.items,
    fetchAdditions: state => state.additions,
    showGroupByCategory: state => state.group_by_category,
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
    [types.SET_GROUP_CATEGORY] (state, payload){
      state.groups = payload
    },
    [types.SET_BY_CATEGORY] (state, payload){
      state.group_by_category = payload
    },
    [types.SET_ADDITIONS] (state){
      state.additions = state.items.filter(item => item.cmc_category_id === 5)
    },
    [types.PUSH] (state, payload){
      state.items.push(payload)
    },
    [types.SHOW] (state, payload){
      state.item = payload
    },
    [types.DESTROY] (state, uuid){
      destroy(state,uuid)
    },
    [types.UPDATE] (state, payload){
      update(state, payload)
    },
  },
  actions: {
    fetchItems({commit}, params) {
      return createAxios().get(endPoint, {params: params})
        .then(({data}) =>{
          commit(types.SET, data.data)
          commit(types.SET_ADDITIONS)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchGroups({commit}) {
      return createAxios().get('/cmc/mixe-groups')
        .then(({data}) =>{
          commit(types.SET_GROUP_CATEGORY, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchGroupsSimplified({commit}) {
      return createAxios().get('/cmc/groups-simplified')
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchGroupByCategory({commit},id_category) {
      return createAxios().get(`${endPoint}/category/${id_category}`)
        .then(({data}) =>{
          commit(types.SET_BY_CATEGORY, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    add({commit, state}, payload) {
      return createAxios().post(`${endPoint}`, payload)
        .then(({data}) =>{
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    update({commit}, payload) {
      return createAxios().put(`${endPoint}/${payload.uuid}`, payload)
        .then(({data}) =>{
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    show({commit, dispatch}, uuid) {
      return createAxios().get(`${endPoint}/${uuid}`)
        .then(({data}) =>{
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    destroy({commit, dispatch}, uuid) {
      return createAxios().delete(`${endPoint}/${uuid}`)
        .then(({data}) =>{
          commit(types.DESTROY, uuid)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
