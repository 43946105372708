<template>
  <div>
    <modal size="lg" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-0">
        <div class="row">
          <div class="col-md-12">
            <div class="card-body mt-n2">
              <div class="row">
                <div
                  class="col-md-12 table-responsive"
                  style="overflow-y: scroll; max-height: 500px"
                >
                  <card>
                    <table class="table table-sm table-bordered">
                      <thead>
                        <tr class="fixed">
                          <th style="border-top: 0"
                              class="text-center text-uppercase text-muted text-xxs font-weight-bolder  ps-2"><i
                            class="fa-regular fa-calendar-check"></i> DATA DO FECHAMENTO
                          </th>
                          <th style="border-top: 0"
                              class="text-center text-uppercase text-muted text-xxs font-weight-bolder  ps-2"><i
                            class="fa-regular fa-calendar-check"></i> STATUS
                          </th>
                          <th style="border-top: 0" class=" text-uppercase text-muted text-xxs font-weight-bolder ">
                            <i class="fa-regular fa-user"></i> RESPONSÁVEL
                          </th>
                          <th style="border-top: 0"
                              class="text-center text-uppercase text-muted text-xxs font-weight-bolder ">
                            <i class="fa-regular fa-clock"></i> DATA DE ALTERAÇÃO
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-if="loadingSkeleton">
                          <tr v-for="(item, index) in 5">
                            <th colspan="12">
                              <SkeletonPuzl type="button" />
                            </th>
                          </tr>
                        </template>
                        <tr
                          v-for="item in $_accountClosingHistory"
                          :key="item.id"
                          v-show="!loadingSkeleton"
                        >
                          <td class="text-center">
                            {{ item.closure | parseDate("DD/MM/YYYY") }}
                          </td>
                          <td class="text-center">
                            <div
                              v-if="item.status == 0"
                              style="margin-left: 10px; margin-right: 10px;"
                            >
                              <base-button
                                disabled
                                size="sm" slot="title-container"
                                class="text-white text-uppercase"
                                style="color: white; background: #34a73cc2;"
                                block
                              >
                                <template>
                                  <i class="fa-solid fa-lock-open"></i>
                                  ABERTO
                                </template>
                              </base-button>
                            </div>
                            <div v-else>
                              <base-dropdown menuOnRight>
                                <base-button
                                  size="sm" slot="title-container"
                                  class="dropdown-toggle text-white text-uppercase"
                                  style="color: white;background: rgb(205 42 42 / 76%);"
                                  block
                                >
                                  <template>
                                    <i class="fa-solid fa-lock"></i>FECHADO
                                  </template>
                                </base-button>
                                <div>
                                  <a
                                    @click.prevent="handleReopenReconciliation(item)"
                                    class="dropdown-item text-uppercase"
                                  >
                                    ABRIR
                                  </a>
                                </div>
                              </base-dropdown>
                            </div>
                          </td>
                          <td style="font-weight: 600 !important;">
                            {{ item.user_name }}
                          </td>
                          <td class="text-center" style="font-weight: 600 !important;">
                            {{ item.created_at | parseDate("DD/MM/YYYY") }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </card>
                  <PuzlEmptyData
                    v-show="
                      $_accountClosingHistory &&
                      !$_accountClosingHistory.length &&
                      !loadingSkeleton
                    "
                  />
                </div>
              </div>
              <Pagination
                @navegate="navegate"
                :source="source"
                :loading="loadingSkeleton"
              />
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
const { formatErrorValidation } = require("@/plugins");
import InputDateTimePicker from "@/components/InputDateTimePickerV2";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import Pagination from "@/components/Utils/Pagination";
import moment from "moment";
export default {
  name: "ModalCreate",
  components: {
    InputDateTimePicker,
    SkeletonPuzl,
    PuzlEmptyData,
    Pagination,
  },
  data() {
    return {
      modal: {
        title: "Histórico de fechamento de conta",
        create: false,
      },
      params: {
        page: 1,
        per_page: 5,
      },
      accountClosingHistory: {
        bank_account_id: null,
        closure: moment(new Date()).format("YYYY-MM-DD"),
        observation: "",
      },
      loadingStore: false,
      loadingSkeleton: false,
      bankAccountId: null,
      source: null,
    };
  },
  computed: {
    ...mapGetters({
      $_accountClosingHistory: "accountClosingHistory/fetch",
    }),
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
  methods: {
    /**
     * @param {number} bankAccountId
     */
    openModal(bankAccountId) {
      this.params.page = 1;
      this.bankAccountId = bankAccountId;
      this.accountClosingHistory = {
        bank_account_id: null,
        closure: moment(new Date()).format("YYYY-MM-DD"),
        observation: "",
      };
      this.getAccountClosingHistory();
      this.modal.create = true;
    },
    closeModal() {
      this.modal.create = false;
    },
    getAccountClosingHistory() {
      this.loadingSkeleton = true;
      this.$store
        .dispatch("accountClosingHistory/getByBankAccountId", {
          bankAccountId: this.bankAccountId,
          page: this.params.page,
        })
        .then((response) => {
          this.source = response;
          this.loadingSkeleton = false;
        });
    },
    store() {
      this.$Progress.start();
      this.loadingStore = true;
      this.accountClosingHistory.bank_account_id = this.bankAccountId;
      this.$store
        .dispatch("accountClosingHistory/add", this.accountClosingHistory)
        .then((response) => {
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
        })
        .catch((error) => {
          if (error.status === 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
        })
        .finally(() => {
          this.$Progress.finish();
          this.loadingStore = false;
        });
    },
    /**
     * @param {number} page
     */
    navegate(page) {
      this.params.page = page;
      this.getAccountClosingHistory();
    },
    /**
     * @param {object} item
     */
    handleReopenReconciliation(item) {
      this.$Progress.start();
      this.loadingStore = true;
      const payload = {
        bankAccountId: item.bank_account_id,
        closure: item.closure,
      }
      this.$store
        .dispatch("accountClosingHistory/reopenAccount", payload)
        .then((response) => {
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          item.status = 0;
        })
        .catch((error) => {
          if (error.status === 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
        })
        .finally(() => {
          this.$Progress.finish();
          this.loadingStore = false;
        });
    }
  },
};
</script>
<style scoped>
.card {
  border-radius: 10px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
}
</style>