<template>
<div>
    <modal :show.sync="modal.create">
        <template slot="header">
            <h5 class="modal-title pl-3">{{ modal.title }}</h5>
        </template>
        <div>
            <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
                <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
                    <div>
                        <div class="form-group row m-0 p-0 pt-2">
                             <label class="col-md-4 pt-1 pb-3 mb-1 col-form-label form-control-label text-left pr-0">
                                Maior que
                                <span class="text-danger">&nbsp;*</span>
                            </label>
                            <div class="col-md-7 pb-3 pl-3">
                                <validation-provider rules="required|min_value:0|max_value:999" v-slot="{errors}">
                                    <base-input input-group-classes="input-group-sm">
                                        <input
                                            inputmode="numeric"
                                            v-model="adjustment.from"
                                            class="form-control form-control-sm"
                                            :class="errors[0] ? 'is-invalid' : 'is-valid'"
                                            v-mask="['###']"
                                        />
                                        <template slot="append">
                                            <small class="input-group-sm p-0 m-0">
                                                minutos
                                            </small>
                                        </template>
                                    </base-input>
                                </validation-provider>
                            </div>
                        </div>
                        <div class="form-group row m-0 p-0">
                            <label class="col-md-4 pt-1 pb-3 mb-0 col-form-label form-control-label text-left pr-0">
                                Menor ou igual a
                                <span class="text-danger">&nbsp;*</span>
                             </label>
                            <div class="col-md-7 pl-3">
                                <validation-provider rules="required|min_value:0|max_value:999" v-slot="{errors}">
                                    <base-input input-group-classes="input-group-sm">
                                        <input
                                            inputmode="numeric"
                                            v-model="adjustment.until"
                                            class="form-control form-control-sm"
                                            :class="errors[0] ? 'is-invalid' : 'is-valid'"
                                            v-mask="['###']"
                                        />
                                        <template slot="append">
                                            <small class="input-group-sm p-0 m-0">
                                                minutos
                                            </small>
                                        </template>
                                    </base-input>
                                </validation-provider>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <base-button type="secondary" @click="closeModal('create')">
                            Cancelar
                        </base-button>
                        <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                            Salvar
                        </base-button>
                    </div>
                </form>
            </validation-observer>
        </div>
    </modal>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'

export default {
    name: "ModalCreateAdjustment",
    data() {
        return {
            modal: {
                title: 'Ajuste Ciclo do Concreto',
                create: false,
            },
            adjustment: {
                from: null,
                until: null
            },
            loadingStore: false
        }
    },
    computed: {},
    methods: {
        closeModal() {
            this.adjustment = {
                from: null,
                until: null,
            }
            this.modal.create = false
        },
        handleCreateModal() {
            this.modal.create = true
        },
        store() {
            this.adjustment.from = parseInt(this.adjustment.from) 
            this.adjustment.until = parseInt(this.adjustment.until) 
            this.$Progress.start()
            this.loadingStore = true
            this.$store.dispatch('adjustmentConcreteCycle/add', this.adjustment)
                .then(response => {
                    this.adjustment = {
                        description: null,
                        until: null,
                    };
                    this.closeModal('create');
                    EventBus.$emit("getCycleAdjustments");
                    this.loadingStore = false
                    this.$Progress.finish();
                    this.$notify({
                        type: response.error_type,
                        message: response.message
                    })
                })
                .catch((error) => {
                    if (error.status == 200) {
                        this.$notify({
                            type: 'danger',
                            message: error.data.message
                        })
                        this.$Progress.finish();
                        this.loadingStore = false
                    } else if (error.response.status === 422) {
                        let errors = Object.values(error.response.data.errors)
                        errors = errors.flat().toString().split(',').join("<br />")
                        this.$notify({
                            type: 'danger',
                            message: errors
                        })
                    }
                })
        }
    },
    mounted() {
        this.$refs.formValidator.validate();
    },
    created() {}
}
</script>

<style scoped>

</style>
