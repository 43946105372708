import { OperationSourceCreateType } from "./OperationSourceCreateType";
import { initOperationSourceCreateType } from "./OperationSourceCreateType";

/**
 * Type para atualizar
 *
 * @typedef {(
 *   OperationSourceCreateType
 * )} OperationSourceUpdateType
 */
export const OperationSourceUpdateType = {};

/**
 * Inicializar OperationSourceUpdateType
 *
 * @returns {OperationSourceUpdateType}
 */
export const initOperationSourceUpdateType = () => {
  return {
    ...initOperationSourceCreateType(),
  };
};
