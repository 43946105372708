<template>
  <label
    class="base-toggle"
    :class="customCSS"
  >
    <input type="checkbox" v-model="model" :disabled="disabled" />
    <span class="d-flex justify-content-center align-items-center"
      ><img
        :src="model ? '/img/icons/check-green.png' : '/img/icons/off-red.png'"
        width="16px"
        height="16px"
        class="mr-2"
      />
      {{ model ? onText : offText }}</span
    >
  </label>
</template>
<script>
export default {
  name: "base-toggle",
  props: {
    value: [Array, Boolean, Number],
    disabled: Boolean,
    pxWidth: {
      type: Number,
      default: null,
    },
    pxHeight: {
      type: Number,
      default: null,
    },
    onText: {
      type: String,
      default: "Sim",
    },
    offText: {
      type: String,
      default: "Não",
    },
  },
  computed: {
    customCSS() {
      let custom = ["base-toggle", this.model ? "activated" : "inactivated"];

      return custom;
    },
    customStyle() {
      let customStyle = {};
      if (pxWidth) customStyle["width"] = this.pxWidth;
      if (pxHeight) customStyle["height"] = this.pxHeight;
      return customStyle;
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    triggerToggle() {
      this.model = !this.model;
    },
  },
};
</script>
<style scoped lang="scss">
label.base-toggle {
  display: flex;
  cursor: pointer;
  max-width: 100%;
  height: 24px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 16px;

  & > input[type="checkbox"] {
    display: none;
  }

  & > span {
    font-family: "Fredoka";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &.activated {
    background: #f2f7f3;

    & > span {
      color: #149e57;
    }
  }
  &.inactivated {
    background: #fcf3f3;

    & > span {
      color: #db4539;
    }
  }
}
</style>
