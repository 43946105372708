<template>
  <div>
    <base-header class="pb-1 bg-gray-content">
      <div class="row justify-content-between py-4">
        <div class="d-flex m-2 mx-4 mx-md-3">
          <h2 style="font-size: 1.2rem !important; color: #606062 !important;" class="text-primary font-weight-600">
            <img height="30px" src="/img/icons/automation.png" class="mr-2" />
            Centrais
          </h2>
          <div @click="addCompany()" v-if="$hasPrivilege(1)" role="button" class="p-2 ml-3 d-flex align-items-center cursor-pointer"
            style="border: 1px solid #149E57; height: 30px; border-radius: 20px; font-size: 12px;  height: 30px; color: #149E57; font-weight: 500; box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);">
            <img width="15" height="15" src="https://img.icons8.com/ios/50/149E57/plus-math.png" alt="plus-math"/>
            <span class="ml-1 mr-2">Novo</span>
          </div>
        </div>
          <!-- Botoes cabecalho -->
          <div class="d-flex justify-content-end align-items-center row mx-2 mx-md-0 mt-2 mt-md-0">
            <div class="col px-2 mb-2 mb-md-0">
              <base-dropdown style="width: 100%;">
                <base-button slot="title-container" class="text-uppercase d-flex justify-content-center col-12" type="warning">
                <img width="16" src="https://img.icons8.com/ios/50/FFFFFF/product-documents.png" alt="plus-math--v1" class="mr-2"/>
                  Cadastros
                <img width="16" style="transform:rotate(270deg);" src="/img/icons/lessThan.png" alt="plus-math--v1" class="ml-2"/>
                </base-button>
                <div>
                  <router-link :to="{
                    path: '/configuration/stock-locations',
                    name: 'configuration.stock-location.view'
                  }">
                    <span class="dropdown-item">
                      <img src="/img/icons/open-box.png" width="19px" height="19px" class="imgBaseButton">
                      Locais de Estoque
                    </span>
                    <div class="dropdown-divider p-0 m-0"></div>
                  </router-link>
                  <router-link :to="{
                    path: '/configuration/general/cost-center',
                    name: 'Centro de Custo'
                  }">
                    <span class="dropdown-item">
                      <img src="/img/icons/market-square-red.png" width="19px" height="19px" class="imgBaseButton">
                      Centro de Custo
                    </span>
                    <div class="dropdown-divider p-0 m-0"></div>
                  </router-link>
                  <router-link :to="{
                    path: '/financial/bill-receive/payment-intermediary',
                    name: 'financial.bill_receive.payment_intermediary.view'
                  }">
                    <span class="dropdown-item">
                      <img src="/img/icons/escrow-process.png" width="19px" height="19px" class="imgBaseButton">
                      Mediador de pagamento
                    </span>
                    <div class="dropdown-divider p-0 m-0"></div>
                  </router-link>
                  <router-link :to="{
                    path: '/financial/bill-receive/payment-method',
                    name: 'financial.bill_receive.payment_method.view'
                  }">
                    <span class="dropdown-item">
                      <img src="/img/icons/split-transaction-blue.png" width="19px" height="19px" class="imgBaseButton">
                      Métodos de Pagamento
                    </span>
                    <div class="dropdown-divider p-0 m-0"></div>
                  </router-link>
                  <router-link :to="{
                    path: '/financial/bill-receive/payment-term',
                    name: 'financial.bill_receive.payment_term.view'
                  }">
                    <span class="dropdown-item">
                      <img src="/img/icons/crowdfunding-green.png" width="19px" height="19px" class="imgBaseButton">
                      Condições de Pagamento
                    </span>
                  </router-link>
                </div>
              </base-dropdown>
            </div>
            <div class="col px-2 mb-2 mb-md-0 mr-0 mr-md-2" style="width: 280px;">
              <base-button
                @click.prevent="handleCreateGenerateSettings()"
                class="text-uppercase d-flex justify-content-center"
                type="dark"
                block
              >
              <img width="16" src="https://img.icons8.com/ios/50/ffffff/settings--v1.png" alt="plus-math--v1" class="mr-2"/> Configurações
              </base-button>
            </div>
          </div>
      </div>
    </base-header>
    <div class="container-fluid">
      <multi-filter ref="multi-filter" @fetch="init" :filter="filter"/>
        <div class="row card-wrapper">
          <!-- CARD -->
          <div class="col-lg-4" v-for="itemCompany in $_companies" :key="itemCompany.id">
            <div class="row card mx-1" style="padding: 2rem 0 0 2rem;">
              <div class="d-flex justify-content-between">
                <div>
                    <div class="p-1 px-3 mb-3" style=" width: fit-content; border-radius: 10px; font-weight: 500;" :style="itemCompany.status ? 'background-color: #d4ede0; color: #149E57' : 'background-color: #f9dedc; color: #DB4539' ">
                      {{ itemCompany.status ? 'Ativo' : 'Inativo' }}
                    </div>
                  <h3 class="card-title mb-1">{{ itemCompany.name }}</h3>
                  <p class="card-text mb-2">cnpj: {{ itemCompany.ein }}</p>
                </div>
                <!-- Menu engrenagem -->
                <div style="padding: 0 2rem 0 0;">
                  <base-dropdown menuOnRight class="d-flex justify-content-center">
                    <div slot="title-container"
                         class="dropdown-toggle rounded">
                      <img width="30" src="/img/icons/icons8/ios/settings--v1_primary.png">
                    </div>
                    <div>
                      <a class="dropdown-item d-flex" @click="handleUpdate(itemCompany)" style="font-size: 12px;">
                        <img width="15" src="https://img.icons8.com/ios/100/f2b532/create-new.png" alt="create-new"/>
                        Editar
                      </a>
                      <a class="dropdown-item" @click="handleOpenCompanyEmailParams(itemCompany.id)">
                        <img width="15x" src="/img/icons/icons8/ios/new-post--v1_primary.png" class="mr-2" />
                        E-mail
                      </a>
                      <router-link :to="{ name:'plant', params: { company_id: itemCompany.id}}">
                        <a class="dropdown-item d-flex" style="font-size: 12px;">
                          <img width="15" src="https://img.icons8.com/ios/50/2B2D32/chemical-plant-3.png" alt="plus-math--v1" class="mr-2"/>
                          Centrais
                        </a>
                      </router-link>
                      <a class="dropdown-item" @click="deleteCompany(itemCompany.id)" v-if="$hasPrivilege(1)">
                        <i v-if="$hasPrivilege(1)" class="fas fa-times text-danger"></i>
                        Excluir
                      </a>
                    </div>
                  </base-dropdown>
                </div>
              </div>
              <div class="d-flex justify-content-end mt-2">
                <div class="col-6 text-center" style="text-align: right !important;padding: 0px">
                    <div class="card-blue-bottom text-center dropdown-toggle" style="cursor: pointer;"
                      :style="cardShowCentral == itemCompany.id ? '' : 'border-bottom-right-radius: 10px;'"
                      @click="cardShowCentral == itemCompany.id ? cardShowCentral = null : cardShowCentral = itemCompany.id">
                      <a style="height: 40px; display: inline-block;"
	                      href="#">
	                      <h3 style="text-transform: none;padding: 8px;padding-right: 5px;">
	                        <span class="mr-1">
	                          <img src="https://img.icons8.com/ios/50/FFFFFF/chemical-plant-3.png" width="22px">
	                        </span>
	                        <small class="mr-1 text-white" style="font-size: 14px">
                              {{ cardShowCentral == itemCompany.id ? 'Ocultar' : itemCompany.company_plants.length + ' Centrais' }}
	                        </small>
	                      </h3>
	                     </a>
                    </div>
                  </div>
              </div>
              <div v-if="cardShowCentral == itemCompany.id" style="padding: 0 2rem 0 0;">
                <div style="margin-top: -1px;">
							    <hr class="blue-divider">
						    </div>
                <collapse class="border rounded mb-3" v-for="itemPlant in itemCompany.company_plants" :key="itemPlant.id">
                  <collapse-item
                    name="1"
                    class="header-gray "
                    back-ground="bg-header-gray"
                  >
                    <h5 slot="title" class="mb-0 ">{{ itemPlant.name }}</h5>
                    <ul class="list-unstyled">
                      <li>{{ itemPlant.address.address }} {{ itemPlant.address.number }}</li>
                      <li>{{ itemPlant.address.city }} {{ itemPlant.address.state }}</li>
                      <li>{{ itemPlant.address.postal_code }}</li>
                    </ul>
                  </collapse-item>
                </collapse>
              </div>
            </div>
        </div>
      </div>
      <div class="row card-wrapper">
        <SkeletonPuzlGrid v-for="(index) in 3" v-show="loadingSkeleton" :key="index"></SkeletonPuzlGrid>
      </div>
      <div class="row align-items-center" v-if="$_companies && !$_companies.length && !loadingSkeleton">
        <div class="col-md-12 text-center">
          <div class="card p-4">
            Não há empresas cadastradas
          </div>
        </div>
      </div>
    </div>

    <modal :show.sync="modal.create" size="lg">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(storeCompany)" autocomplete="off"
          >
            <!-- NOVA EMPRESA-->
            <div>
              <breadcrumb
                list-classes="bg-success border-header-success-bottom"
              >
                Identificação
              </breadcrumb>

              <div>
                <div class="form-group row m-0 p-0">
                  <label class="col-md-3 col-form-label form-control-label"
                  >Status</label
                  >
                  <div class="col-md-9 pt-2">
                    <base-switch
                      v-model="company.status"
                      type="success"
                      offText="inativo"
                      onText="ativo"
                      class="success"
                    ></base-switch>
                  </div>
                </div>
                <div class="form-group row m-0 mt-1 mb-1 p-0 limited-row">
                  <label
                    class="col-md-3 pt-0 pb-0 col-form-label form-control-label pl-3 mb-1"
                  >
                    Contribuinte ICMS
                  </label>
                  <div class="col-md-9 pr-4 mb-1">
                    <validation-provider rules="required">
                      <base-input input-group-classes="input-group-sm">
                        <base-switch
                          v-model="company.icms_taxpayer"
                          type="primary"
                          class="primary"
                        ></base-switch>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <!-- LOGO -->
                <div class="row mb-2">
                  <div class="col-md-12 ml-3">
                    <div class="text-left">
                      <img
                        style="border: 1px solid #999999;object-fit: contain"
                        class="rounded-corner photo"
                        :src="url_logo"
                      />
                      <input accept="image/*" @change="onFileChange" ref="file" hidden type="file"/>
                      <a style="margin-left: -57px" @click.prevent="openFileLogo"
                         href="#">
                        <i style="color: #999999" class="logo-ref fas fa-camera"/>
                      </a>
                    </div>

                    <!--                    <div class="company-logo text-center img-preview"-->
                    <!--                         :style="`background-image: url(${url_logo})`">-->
                    <!--                      <a @click.prevent="openFileLogo"-->
                    <!--                         href="#" class="href-logo">-->
                    <!--                        <i style="margin-top: 43%" class="logo-ref fa-2x fas fa-camera"/>-->
                    <!--                      </a>-->
                    <!--                      <input accept="image/*" @change="onFileChange" ref="file" hidden type="file"/>-->
                    <!--                    </div>-->
                  </div>
                </div>
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-3 pb-0 mb-1 col-form-label form-control-label"
                  >Nome<span class="text-danger">&nbsp;*</span></label
                  >
                  <div class="col-md-9">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <input type="text" v-model="company.name" autocomplete="off"
                               class="form-control form-control-sm"/>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-3 pb-0 mb-1 col-form-label form-control-label"
                  >Cnpj<span class="text-danger">&nbsp;*</span></label
                  >
                  <div class="col-md-9">
                    <validation-provider rules="required">
                      <base-input group class="mb-3">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          aria-describedby="button-addon4"
                          v-model="company.ein"
                          v-mask="['##.###.###/####-##']"
                        />

                        <div class="input-group-append" id="button-addon4">
                          <button
                            class="btn btn-outline-primary btn-sm form-control-sm"
                            type="button"
                            @click="getRecipeNet"
                          >
                            Pesquisar
                          </button>
                        </div>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-3 pb-0 mb-1 col-form-label form-control-label"
                  >Nome Empresarial<span class="text-danger"
                  >&nbsp;*</span
                  ></label
                  >
                  <div class="col-md-9">
                    <validation-provider rules="required">
                      <base-input
                        input-classes="form-control-sm"
                        autocomplete="off"
                        v-model="company.business_name"
                      />
                    </validation-provider>
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-3 pb-0 mb-1 col-form-label form-control-label"
                  >Nome fantasia</label
                  >
                  <div class="col-md-9">
                    <base-input
                      input-classes="form-control-sm"
                      autocomplete="off"
                      v-model="company.alias_name"
                    />
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-3 pb-0 mb-1 col-form-label form-control-label"
                  >E-mail</label
                  >
                  <div class="col-md-9">
                    <base-input
                      input-classes="form-control-sm"
                      autocomplete="off"
                      v-model="company.email"
                    />
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-3 pb-0 mb-1 col-form-label form-control-label"
                  >Telefone</label
                  >
                  <div class="col-md-9">
                    <base-input
                      input-classes="form-control-sm"
                      autocomplete="off"
                      v-model="company.phone"
                      v-mask="['(##) ####-####', '(##) #####-####']"
                    />
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-3 pb-0 mb-1 col-form-label form-control-label"
                  >Situação Cadastral</label
                  >
                  <div class="col-md-9">
                    <base-input>
                      <puzl-select
                        v-model="company.registration_situation"
                        :items="[{id: 'Ativo', name: 'Ativo'}, {id: 'Inativo', name: 'Inativo'}]"/>
                    </base-input>
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-3 pb-0 mb-1 col-form-label form-control-label"
                  >Regime Tributário</label
                  >
                  <div class="col-md-9">
                    <base-input>
                      <puzl-select
                        v-model="company.tax_regime"
                        :items="[
                          {id: 'Lucro Real', name: 'Lucro Real'},
                          {id: 'Lucro Presumido', name: 'Lucro Presumido'},
                          {id: 'Simples Nacional', name: 'Simples Nacional'},
                          {id: 'Mei', name: 'Mei'},
                        ]"
                      />
                    </base-input>
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-3 pb-0 mb-1 col-form-label form-control-label"
                  >Insc. Municipal</label
                  >
                  <div class="col-md-9">
                    <base-input
                      input-classes="form-control-sm"
                      autocomplete="off"
                      v-model="company.municipal_registration"
                    />
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-3 pb-0 mb-1 col-form-label form-control-label"
                  >Insc. Estadual</label
                  >
                  <div class="col-md-9">
                    <base-input>
                      <puzl-select
                        v-model="company.state_registration"
                        :items="[
                          {id: 'Não Contribuinte', name: 'Não Contribuinte'},
                          {id: 'Contribuinte', name: 'Contribuinte'},
                          {id: 'Contribuinte Isento', name: 'Contribuinte Isento'},
                        ]"
                      />
                    </base-input>
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-3 pb-0 mb-1 col-form-label form-control-label"
                  >Numero contribuinte</label
                  >
                  <div class="col-md-9">
                    <base-input
                      input-classes="form-control-sm"
                      autocomplete="off"
                      v-model="company.contributing_number"
                    />
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-3 pb-0 mb-1 col-form-label form-control-label"
                  >Insc. Suframa</label
                  >
                  <div class="col-md-9">
                    <base-input
                      input-classes="form-control-sm"
                      autocomplete="off"
                      v-model="company.suframa_number"
                    />
                  </div>
                </div>

                <breadcrumb
                  list-classes="bg-success border-header-success-bottom mt-2"
                >
                  Integração
                </breadcrumb>

                <div class="form-group row m-0 p-0">
                  <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">Código externo (TOTVS)</label>
                  <div class="col-md-7">
                    <base-input input-classes="form-control-sm">
                      <input type="text" v-mask="'#'" v-model="company.totvs_code" autocomplete="off"
                            class="form-control form-control-sm"/>
                    </base-input>
                  </div>
                </div>

                <breadcrumb
                  list-classes="bg-success border-header-warning-bottom mt-4"
                >
                  Endereço
                </breadcrumb>
                <div class="pl-3 pr-3">
                  <div class="row">
                    <label
                      class="col-md-2 pb-0 mb-1 col-form-label form-control-label"
                    >Cep<span class="text-danger">&nbsp;*</span></label
                    >
                    <div class="col-md-4">
                      <validation-provider rules="required">
                        <base-input
                          input-classes="form-control-sm"
                          autocomplete="off"
                          v-model="company.postal_code"
                          v-mask="['#####-###']"
                          v-on:blur="getAddressByCode"
                        />
                      </validation-provider>
                    </div>
                    <label
                      class="col-md-2 pb-0 mb-1 col-form-label form-control-label"
                    >Uf<span class="text-danger">&nbsp;*</span></label
                    >
                    <div class="col-md-4">
                      <validation-provider rules="required">
                        <base-input>
                          <select
                            v-model="company.state"
                            class="form-control form-control-sm"
                            v-on:change="getCities()"
                          >
                            <option v-for="state in states" :key="state.id" :value="state.letter">{{ state.letter }}
                            </option>
                          </select>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="row">
                    <label
                      class="col-md-2 pb-0 mb-1 col-form-label form-control-label"
                    >Endereço<span class="text-danger">&nbsp;*</span></label
                    >
                    <div class="col-md-10">
                      <validation-provider rules="required">
                        <base-input
                          input-classes="form-control-sm"
                          autocomplete="off"
                          v-model="company.address"
                        />
                      </validation-provider>
                    </div>
                  </div>

                  <div class="row">
                    <label
                      class="col-md-2 pb-0 mb-1 col-form-label form-control-label"
                    >Número<span class="text-danger">&nbsp;*</span></label
                    >
                    <div class="col-md-4">
                      <validation-provider rules="required">
                        <base-input
                          input-classes="form-control-sm"
                          autocomplete="off"
                          v-model="company.number"
                        />
                      </validation-provider>
                    </div>
                    <label
                      class="col-md-2 pb-0 mb-1 col-form-label form-control-label"
                    >Complemento</label
                    >
                    <div class="col-md-4">
                      <base-input
                        input-classes="form-control-sm"
                        autocomplete="off"
                        v-model="company.complement"
                      />
                    </div>
                  </div>

                  <div class="row">
                    <label
                      class="col-md-2 pb-0 mb-1 col-form-label form-control-label"
                    >Bairro<span class="text-danger">&nbsp;*</span></label
                    >
                    <div class="col-md-4">
                      <validation-provider rules="required">
                        <base-input
                          input-classes="form-control-sm"
                          autocomplete="off"
                          v-model="company.district"
                        />
                      </validation-provider>
                    </div>
                    <label
                      class="col-md-2 pb-0 mb-1 col-form-label form-control-label"
                    >Município<span class="text-danger">&nbsp;*</span></label
                    >
                    <div class="col-md-4">
                      <validation-provider rules="required">
                        <base-input>
                          <select
                            v-model="company.city"
                            class="form-control form-control-sm">
                            <option v-for="city in cities" :key="city.id" :value="city.title">{{ city.title }}</option>
                          </select>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')"
              >Cancelar
              </base-button
              >
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                :loading="storeCompanyLoading"
              >Salvar
              </base-button
              >
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>

    <modal :show.sync="modal.update" size="lg">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(updateCompany)" autocomplete="off"
          >
            <div>
              <breadcrumb
                list-classes="bg-success border-header-success-bottom"
              >
                Identificação
              </breadcrumb>

              <div>
                <div class="form-group row m-0 p-0">
                  <label class="col-md-3 col-form-label form-control-label"
                  >Status</label
                  >
                  <div class="col-md-9 pt-2">
                    <base-switch
                      v-model="editCompany.status"
                      type="success"
                      offText="inativo"
                      onText="ativo"
                      class="success"
                    ></base-switch>
                  </div>
                </div>

                <div class="form-group row m-0 mt-1 mb-1 p-0 limited-row">
                  <label
                    class="col-md-3 pt-0 pb-0 col-form-label form-control-label pl-3 mb-1"
                  >
                    Contribuinte ICMS
                  </label>
                  <div class="col-md-9 pr-4 mb-1">
                    <validation-provider rules="required">
                      <base-input input-group-classes="input-group-sm">
                        <base-switch
                          v-model="editCompany.icms_taxpayer"
                          type="primary"
                          class="primary"
                        ></base-switch>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-12 ml-3">
                    <div class="text-left">
                      <img
                        style="border: 1px solid #999999;object-fit: contain"
                        class="rounded-corner photo"
                        :src="url_logo"
                      />
                      <input accept="image/*" @change="onFileChange" ref="file" hidden type="file"/>
                      <a style="margin-left: -57px" @click.prevent="openFileLogo"
                         href="#">
                        <i style="color: #999999" class="logo-ref fas fa-camera"/>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-3 pb-0 mb-1 col-form-label form-control-label"
                  >Nome<span class="text-danger">&nbsp;*</span></label
                  >
                  <div class="col-md-9">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <input type="text" v-model="editCompany.name" autocomplete="off"
                               class="form-control form-control-sm"/>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-3 pb-0 mb-1 col-form-label form-control-label"
                  >Cnpj<span class="text-danger">&nbsp;*</span></label
                  >
                  <div class="col-md-9">
                    <validation-provider rules="required">
                      <base-input group class="mb-3">
                        <input
                          inputmode="numeric"
                          type="text"
                          class="form-control form-control-sm"
                          aria-describedby="button-addon4"
                          v-model="editCompany.ein"
                          v-mask="['##.###.###/####-##']"
                        />

                        <div class="input-group-append" id="button-addon4">
                          <button
                            class="btn btn-outline-primary btn-sm form-control-sm"
                            type="button"
                            @click="getRecipeNet"
                          >
                            Pesquisar
                          </button>
                        </div>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-3 pb-0 mb-1 col-form-label form-control-label"
                  >Nome Empresarial<span class="text-danger"
                  >&nbsp;*</span
                  ></label
                  >
                  <div class="col-md-9">
                    <validation-provider rules="required">
                      <base-input
                        input-classes="form-control-sm"
                        autocomplete="off"
                        v-model="editCompany.business_name"
                      />
                    </validation-provider>
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-3 pb-0 mb-1 col-form-label form-control-label"
                  >Nome fantasia</label
                  >
                  <div class="col-md-9">
                    <base-input
                      input-classes="form-control-sm"
                      autocomplete="off"
                      v-model="company.alias_name"
                    />
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-3 pb-0 mb-1 col-form-label form-control-label"
                  >E-mail</label
                  >
                  <div class="col-md-9">
                    <base-input
                      input-classes="form-control-sm"
                      autocomplete="off"
                      v-model="editCompany.email"
                    />
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-3 pb-0 mb-1 col-form-label form-control-label"
                  >Telefone</label
                  >
                  <div class="col-md-9">
                    <base-input
                      inputmode="numeric"
                      input-classes="form-control-sm"
                      autocomplete="off"
                      v-model="editCompany.phone"
                      v-mask="['(##) ####-####', '(##) #####-####']"
                    />
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-3 pb-0 mb-1 col-form-label form-control-label"
                  >Situação Cadastral</label
                  >
                  <div class="col-md-9">
                    <base-input>
                      <puzl-select
                        v-model="editCompany.registration_situation"
                        :items="[{id: 'Ativo', name: 'Ativo'}, {id: 'Inativo', name: 'Inativo'}]"/>
                    </base-input>
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-3 pb-0 mb-1 col-form-label form-control-label"
                  >Regime Tributário</label
                  >
                  <div class="col-md-9">
                    <base-input>
                      <puzl-select
                        v-model="editCompany.tax_regime"
                        :items="[
                          {id: 'Lucro Real', name: 'Lucro Real'},
                          {id: 'Lucro Presumido', name: 'Lucro Presumido'},
                          {id: 'Simples Nacional', name: 'Simples Nacional'},
                          {id: 'Mei', name: 'Mei'},
                        ]"
                      />
                    </base-input>
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-3 pb-0 mb-1 col-form-label form-control-label"
                  >Insc. Municipal</label
                  >
                  <div class="col-md-9">
                    <base-input
                      inputmode="numeric"
                      input-classes="form-control-sm"
                      autocomplete="off"
                      v-model="editCompany.municipal_registration"
                    />
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-3 pb-0 mb-1 col-form-label form-control-label"
                  >Insc. Estadual</label
                  >
                  <div class="col-md-9">
                    <base-input>
                      <puzl-select
                        v-model="editCompany.state_registration"
                        :items="[
                          {id: 'Não Contribuinte', name: 'Não Contribuinte'},
                          {id: 'Contribuinte', name: 'Contribuinte'},
                          {id: 'Contribuinte Isento', name: 'Contribuinte Isento'},
                        ]"
                      />
                    </base-input>
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-3 pb-0 mb-1 col-form-label form-control-label"
                  >Numero contribuinte</label
                  >
                  <div class="col-md-9">
                    <base-input
                      inputmode="numeric"
                      input-classes="form-control-sm"
                      autocomplete="off"
                      v-model="editCompany.contributing_number"
                    />
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-3 pb-0 mb-1 col-form-label form-control-label"
                  >Insc. Suframa</label
                  >
                  <div class="col-md-9">
                    <base-input
                      inputmode="numeric"
                      input-classes="form-control-sm"
                      autocomplete="off"
                      v-model="editCompany.suframa_number"
                    />
                  </div>
                </div>
                <breadcrumb
                  list-classes="bg-success border-header-success-bottom mt-4"
                >
                  Integração
                </breadcrumb>

                <div class="form-group row m-0 p-0">
                  <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">Código externo (TOTVS)</label>
                  <div class="col-md-7">
                      <base-input input-classes="form-control-sm">
                        <input type="text" v-mask="'#'" v-model="editCompany.totvs_code" autocomplete="off"
                               class="form-control form-control-sm"/>
                      </base-input>
                  </div>
                </div>

                <breadcrumb
                  list-classes="bg-success border-header-warning-bottom mt-4"
                >
                  Endereço
                </breadcrumb>
                <div class="pl-3 pr-3">
                  <div class="row">
                    <label
                      class="col-md-2 pb-0 mb-1 col-form-label form-control-label"
                    >Cep<span class="text-danger">&nbsp;*</span></label
                    >
                    <div class="col-md-4">
                      <validation-provider rules="required">
                        <base-input
                          input-classes="form-control-sm"
                          autocomplete="off"
                          inputmode="numeric"
                          v-model="editCompany.postal_code"
                          v-mask="['#####-###']"
                          v-on:blur="getAddressByCode"
                        />
                      </validation-provider>
                    </div>
                    <label
                      class="col-md-2 pb-0 mb-1 col-form-label form-control-label"
                    >Uf<span class="text-danger">&nbsp;*</span></label
                    >
                    <div class="col-md-4">
                      <validation-provider rules="required">
                        <base-input>
                          <select
                            v-model="editCompany.state"
                            class="form-control form-control-sm"
                            v-on:change="getCities()"
                          >
                            <option v-for="state in states" :key="state.id" :value="state.letter">{{ state.letter }}
                            </option>
                          </select>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="row">
                    <label
                      class="col-md-2 pb-0 mb-1 col-form-label form-control-label"
                    >Endereço<span class="text-danger">&nbsp;*</span></label
                    >
                    <div class="col-md-10">
                      <validation-provider rules="required">
                        <base-input
                          input-classes="form-control-sm"
                          autocomplete="off"
                          v-model="editCompany.address"
                        />
                      </validation-provider>
                    </div>
                  </div>

                  <div class="row">
                    <label
                      class="col-md-2 pb-0 mb-1 col-form-label form-control-label"
                    >Número<span class="text-danger">&nbsp;*</span></label
                    >
                    <div class="col-md-4">
                      <validation-provider rules="required">
                        <base-input
                          inputmode="numeric"
                          input-classes="form-control-sm"
                          autocomplete="off"
                          v-model="editCompany.number"
                        />
                      </validation-provider>
                    </div>
                    <label
                      class="col-md-2 pb-0 mb-1 col-form-label form-control-label"
                    >Complemento</label
                    >
                    <div class="col-md-4">
                      <base-input
                        input-classes="form-control-sm"
                        autocomplete="off"
                        v-model="editCompany.complement"
                      />
                    </div>
                  </div>

                  <div class="row">
                    <label
                      class="col-md-2 pb-0 mb-1 col-form-label form-control-label"
                    >Bairro<span class="text-danger">&nbsp;*</span></label
                    >
                    <div class="col-md-4">
                      <validation-provider rules="required">
                        <base-input
                          input-classes="form-control-sm"
                          autocomplete="off"
                          v-model="editCompany.district"
                        />
                      </validation-provider>
                    </div>
                    <label
                      class="col-md-2 pb-0 mb-1 col-form-label form-control-label"
                    >Município<span class="text-danger">&nbsp;*</span></label
                    >
                    <div class="col-md-4">
                      <validation-provider rules="required">
                        <base-input>
                          <select
                            v-model="editCompany.city"
                            class="form-control form-control-sm">
                            <option v-for="city in cities" :key="city.id" :value="city.title">{{ city.title }}</option>
                          </select>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('update')"
              >Cancelar
              </base-button
              >
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                :loading="storeCompanyLoading"
              >Salvar
              </base-button
              >
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
    <loading-pagination :show="loading && !loadingSkeleton"/>
    <ModalGeneralSettings ref="createGeneralSettings" />
    <ModalCompanyEmailParam ref="modalCompanyEmailParam"/>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import MultiFilter from "@/components/Utils/MultiFilterV2";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import PuzlSelect from "@/components/PuzlSelect";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import ModalGeneralSettings from './_ModalGeneralSettings';
import ModalCompanyEmailParam from './Company/_ModalCompanyEmailParam';

export default {
  name: 'CreateCompany',
  mixins: [cursorPaginate],
  components: {
    MultiFilter,
    LoadingPagination,
    PuzlSelect,
    SkeletonPuzlGrid,
    ModalGeneralSettings,
    ModalCompanyEmailParam,
  },
  data() {
    return {
      modal: {
        title: 'Adicionar Empresa',
        update: false,
        create: false,
      },
      loadingSkeleton: false,
      filter: {},
      validated: false,
      company: {},
      editCompany: {
        id: null,
        totvs_code: '',
        status: false,
        icms_taxpayer: false,
        name: null,
        ein: null,
        business_name: null,
        alias_name: null,
        email: null,
        phone: null,
        registration_situation: null,
        tax_regime: null,
        municipal_registration: null,
        state_registration: null,
        contributing_number: null,
        suframa_number: null,
        postal_code: null,
        state: null,
        address: null,
        number: null,
        complement: null,
        district: null,
        city: null,
      },
      state: '',
      states: [],
      cities: {},
      storeCompanyLoading: false,
      logo: [],
      url_logo: '/img/empty-logo.png',
      cardShowCentral: 0,
    };
  },
  computed: {
    ...mapGetters({
      $_companies: "company/fetch",
    }),
  },
  methods: {
    handleCreateGenerateSettings() {
      this.$refs.createGeneralSettings.openModal();
    },
    onFileChange(e) {
      this.company.logo = e.target.files[0]
      this.editCompany.logo = e.target.files[0]
      this.url_logo = URL.createObjectURL(this.company.logo);
    },
    closeModal(name) {
      this.modal[name] = false
    },
    openModal(name) {
      this.url_logo = '/img/empty-logo.png'
      this.modal[name] = true
    },
    openFileLogo() {
      this.$refs.file.click()
    },
    addCompany() {
      this.company = {
        status: true,
        totvs_code: '',
        icms_taxpayer: false,
        name: '',
        logo: '',
        ein: '',
        business_name: '',
        alias_name: '',
        email: '',
        phone: '',
        registration_situation: '',
        tax_regime: '',
        municipal_registration: '',
        state_registration: '',
        contributing_number: '',
        suframa_number: '',
        postal_code: '',
        state: '',
        address: '',
        number: '',
        complement: '',
        district: '',
        city: '',
      };
      this.openModal('create');
    },
    getRecipeNet() {
      this.$Progress.start();
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$axios.post("/recipenet/ein", this.company)
        .then((response) => {
          this.company.ein = response.data.data.cnpj || '';
          this.company.name = response.data.data.nome || '';
          this.company.business_name = response.data.data.nome || '';
          this.company.alias_name = response.data.data.fantasia || '';
          this.company.email = response.data.data.email || '';
          this.company.phone = response.data.data.telefone || '';
          this.company.number = response.data.data.numero || '';
          this.company.registration_situation = response.data.data.situacao || '';
          this.company.postal_code = response.data.data.cep.replace(/[^\d]+/g, '') || '';
          this.getAddressByCode();
          this.$notify({type: 'success', message: 'Dados da empresa carregado com sucesso.'});
          this.$Progress.finish();
        })
        .catch((error) => {
          this.$Progress.finish();
        });
    },
    getAddressByCode() {
      this.$Progress.start();
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$axios.post("/recipenet/address", {"postal_code": this.company.postal_code})
        .then((response) => {
          this.company.state = response.data.data.uf;
          this.getCities();
          this.company.address = response.data.data.logradouro;
          this.company.state = response.data.data.uf;
          this.company.district = response.data.data.bairro;
          this.company.city = response.data.data.localidade;
          this.$notify({type: 'success', message: 'Dados carregado com sucesso.'});
          this.$Progress.finish();
        })
        .catch((error) => {
          this.$Progress.finish();
        });
    },
    getStates() {
      this.$Progress.start();
      this.$axios.get("/state")
        .then((response) => {
          this.states = response.data.data;
          this.$Progress.finish();
        })
        .catch((error) => {
        });
    },
    init(filter = null) {
      this.startCursor(filter)
      this.$Progress.start()
      this.$store
        .dispatch("company/fetchItemsPaginate", {
          filter: this.filter,
          next_page: this.paginate.nextUrl
        })
        .then(response => {
          this.$Progress.finish()
          this.resolveCursor(response)
        })
        .catch(error => {
          this.resolveCursor()
          this.$Progress.finish()
        });
    },
    getCities() {
      this.$Progress.start();
      if (this.company.state != undefined) {
        this.state = this.company.state;
      } else if (this.editCompany.state != undefined) {
        this.state = this.editCompany.state;
      }

      this.$axios.post('/city', {uf: this.state})
        .then((response) => {
          this.cities = response.data.data;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.$Progress.finish();
        });
    },
    fileChange() {
      this.company.logo = this.$refs.logo.files[0];
    },
    handleUpdate(row) {
      this.editCompany = {
        id: row.id,
        status: row.status || false,
        icms_taxpayer: row.icms_taxpayer || false,
        name: row.name,
        ein: row.ein,
        totvs_code: row.totvs_code || '',
        business_name: row.business_name,
        alias_name: row.alias_name,
        email: row.email,
        phone: row.phone,
        registration_situation: row.registration_situation,
        tax_regime: row.tax_regime,
        municipal_registration: row.municipal_registration,
        state_registration: row.state_registration,
        contributing_number: row.contributing_number,
        suframa_number: row.suframa_number,
        postal_code: row.company_address.postal_code,
        state: row.company_address.state,
        address: row.company_address.address,
        number: row.company_address.number,
        complement: row.company_address.complement,
        district: row.company_address.district,
        city: row.company_address.city,
      }
      this.state = row.company_address.state;
      this.getCities();

      this.modal.title = "Editar Empresa";
      this.openModal('update');
      this.url_logo = row.logo
    },
    storeCompany() {
      this.$Progress.start();
      this.storeCompanyLoading = true;
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});

      let formData = new FormData();
      let status = this.company.status ? 1 : 0;
      let icms_taxpayer = this.company.icms_taxpayer ? 1 : 0;
      formData.append('status', status);
      formData.append('icms_taxpayer', icms_taxpayer);
      formData.append('name', this.company.name);
      formData.append('totvs_code', this.company.totvs_code);
      formData.append('logo', this.company.logo);
      formData.append('ein', this.company.ein);
      formData.append('business_name', this.company.business_name);
      formData.append('alias_name', this.company.alias_name);
      formData.append('email', this.company.email);
      formData.append('phone', this.company.phone);
      formData.append('registration_situation', this.company.registration_situation);
      formData.append('tax_regime', this.company.tax_regime);
      formData.append('municipal_registration', this.company.municipal_registration);
      formData.append('state_registration', this.company.state_registration);
      formData.append('contributing_number', this.company.contributing_number);
      formData.append('suframa_number', this.company.suframa_number);
      formData.append('postal_code', this.company.postal_code);
      formData.append('state', this.company.state);
      formData.append('address', this.company.address);
      formData.append('number', this.company.number);
      formData.append('complement', this.company.complement);
      formData.append('district', this.company.district);
      formData.append('city', this.company.city);
      this.$axios.post("/company", formData,
        {
          headers: {
            'content-type': 'multipart/form-data'
          }
        }
      )
        .then((response) => {
          this.$notify({type: response.data.error_type, message: response.data.message});
          this.company = {};
          this.init();
          this.closeModal('create');
        })
        .catch((error) => {
          this.$notify({type: error.response.data.error_type, message: error.response.data.message});
        }).finally(() => {
          this.$Progress.finish();
          this.storeCompanyLoading = false;
        });
    },
    updateCompany() {
      this.$Progress.start();
      this.storeCompanyLoading = true;
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      let formData = new FormData();
      let status = this.editCompany.status ? 1 : 0;
      let icms_taxpayer = this.editCompany.icms_taxpayer ? 1 : 0;
      formData.append('status', status);
      formData.append('icms_taxpayer', icms_taxpayer);
      formData.append('name', this.editCompany.name);
      formData.append('logo', this.editCompany.logo);
      formData.append('totvs_code', this.editCompany.totvs_code);
      formData.append('ein', this.editCompany.ein);
      formData.append('business_name', this.editCompany.business_name);
      formData.append('alias_name', this.editCompany.alias_name);
      formData.append('email', this.editCompany.email);
      formData.append('phone', this.editCompany.phone);
      formData.append('registration_situation', this.editCompany.registration_situation);
      formData.append('tax_regime', this.editCompany.tax_regime);
      formData.append('municipal_registration', this.editCompany.municipal_registration);
      formData.append('state_registration', this.editCompany.state_registration);
      formData.append('contributing_number', this.editCompany.contributing_number);
      formData.append('suframa_number', this.editCompany.suframa_number);
      formData.append('postal_code', this.editCompany.postal_code);
      formData.append('state', this.editCompany.state);
      formData.append('address', this.editCompany.address);
      formData.append('number', this.editCompany.number);
      formData.append('complement', this.editCompany.complement);
      formData.append('district', this.editCompany.district);
      formData.append('city', this.editCompany.city);
      this.$axios.post("/company", formData,
        {
          headers: {
            'content-type': 'multipart/form-data'
          }
        }
      )
        .then(({data}) => {
          this.$notify({type: data.error_type, message: data.message});
          this.editCompany = {};
          this.init();
          this.closeModal('update');
        })
        .catch((error) => {
          this.$notify({type: error.response.data.error_type, message: error.response.data.message});
        }).finally(() => {
          this.$Progress.finish();
          this.storeCompanyLoading = false;
        });
    },
    deleteCompany(id) {
      this.$Progress.start();
      this.$swal.fire({
        title: 'Você tem certeza?',
        text: 'Você não poderá recuperar este registro!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, apague isto!',
        cancelButtonText: 'Não, mantenha',
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          this.$axios.delete("/company/" + id)
            .then((response) => {
              this.$notify({type: response.data.error_type, message: response.data.message});
              this.init();
              this.$Progress.finish();
            })
            .then((response) => {
              this.$notify({
                type: response.error_type,
                message: response.message
              });
              this.$Progress.finish();
            })
            .catch((error) => {
              this.$notify({
                type: "danger",
                message: error.data.message
              });
              this.storeCompanyLoading = false;
            });
        }
        this.$Progress.finish();
      });
    },
    handleOpenCompanyEmailParams(companyId){
      this.$refs.modalCompanyEmailParam.handleOpenModal(companyId);
    },
    companyEmailParamUpdated(value){
      Object.assign(
        this.$_companies.find(company => company.id === value.id),
        value
      );
    }
  },
  mounted() {
    this.$refs.formValidator.validate()
    this.init({})
    this.getStates()
  },
};

</script>
<style>
.photo {
  width: 100px !important;
  height: 100px !important;
}
.card-blue-bottom {
  border-top-left-radius: 35px;
  background-color: #1b6eba;
  color: white;
  max-width: 190px;
  min-width: 190px;
  float: right;
}
.blue-divider {
	background-color: #7ec5ff;
	color: #7ec5ff;
	margin: 0;
	margin-bottom: 15px;
}

.logo-ref:hover {
  color: grey !important;
}

.rounded-corner {
  border-radius: 5%
}
</style>
