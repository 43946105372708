<template>
  <div>
    <modal size="md" :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">{{ title }}</h5>
      </template>
      <div >
        <div class="row table-responsive" style="max-height: 80vh">
          <div class="col-md-12">
            <template v-for="item in history">
              <div class="border-left border-3 p-1 mb-2" :class="`border-${classes[item.status].class}`">
                <h4 :class="`text-${classes[item.status].class}`">
                  {{classes[item.status].name}}
                  <span v-if="item.received_at" class="text-default font-weight-normal small numeric"> | {{ item.received_at | parseDate('DD MMM YYYY') }} </span>
                </h4>
                <div class="text-left h4 mt-n2 text-uppercase" v-if="item.observation">
                  <small>{{item.observation}}</small>
                </div>
                <div class="text-left h3 mt-n2 small">
                  {{ item.user_name ? item.user_name + ' | ' : '' }}{{ item.created_at | parseDate }}
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

const classes = {
  0: {
    name: 'PENDENTE',
    class: 'yellow',
  },
  1: {
    name: 'RECEBIDO',
    class: 'success',
  },
  2: {
    name: 'ATRASADO',
    class: 'danger',
  },
  3: {
    name: 'CANCELADO',
    class: 'danger',
  },
  4: {
    name: 'SERASA',
    class: 'pink',
  },
  5: {
    name: 'CARTÓRIO',
    class: 'default',
  },
  6: {
    name: 'PROTESTADO',
    class: 'warning',
  },
  7: {
    name: 'JURÍDICO',
    class: 'indigo',
  },
  8: {
    name: 'APROVADO',
    class: 'primary',
  },
  9: {
    name: 'Negado',
    class: 'danger',
  },
  10: {
    name: 'Estorno de recebível',
    class: 'warning',
  },
  11: {
    name: 'Compensação de crédito',
    class: 'success',
  },
  12: {
    name: 'Estorno de crédito',
    class: 'warning',
  },
  13: {
    name: 'Envio de e-mails',
    class: 'success',
  },
  14: {
    name: 'Boleto Emitido',
    class: 'success',
  },
  15: {
    name: 'Boleto Cancelado',
    class: 'danger',
  },
  99: {
    name: 'Alteração de vencimento',
    class: 'warning',
  },
  98: {
    name: 'Divisão de parcela',
    class: 'warning',
  },
  97: {
    name: 'Parcela proveniente de divisão de parcelas',
    class: 'success',
  },
}
export default {
  components: {},
  name: "ModalHistory",
  data() {
    return {
      modal: false,
      title: 'Histórico de alterações',
      classes: classes,
      bill_receive: null
    }
  },
  computed: {
    ...mapGetters({
      'history': 'billReceive/getHistory',
    }),
  },
  methods: {
    close() {
      this.modal = false
    },
    openModal(bill_receive) {
      let loader = this.$loading.show()
      this.bill_receive = bill_receive
      this.$store.dispatch('billReceive/getHistory', bill_receive)
        .then(response => {
          loader.hide()
          this.modal = true
        })
        .catch(error => {
          loader.hide()
        })
    },
  },
};
</script>

<style>
</style>
