<template>
  <div>
    <base-header class="bg-gray-content">
      <puzl-breadcrumb/>
    </base-header>
    <div class="row">
      <div class="col-md-12">
        <div class="container-fluid">
          <div class="card-body table-responsive card">
            <div style="margin-bottom: -20px" class="row">
              <div class="pointer col-md-4 mb-0">
                <div class="card card-stats" @click.prevent="showDetails = !showDetails"
                     style="border: 1px solid #E8E8E8; height: 115px !important;">
                  <div class="card-body">
                    <div class="row">
                      <div class="col text-center"><i style="font-size: 2rem"
                                                      class="fa-solid fa-coins text-warning"></i>
                        <h5
                          class="card-title text-uppercase text-muted mb-0 text-center">RECEITA</h5>
                        <span
                          class="dashboard-text card-stats-title h3 font-weight-bold mb-0 text-center"
                          style="font-family: 'Roboto', sans-serif;">
                          <badge style="font-family: FreeMono, monospace;font-size: 14px;background: none"
                                 type="secondary">
                              {{ form_data.income.total || 0 | currency }}
                            <br>
                                                        <span
                                                          style="font-family: FreeMono, monospace;font-size: 13px"
                                                          class="font-weight-400 ml-2"> {{
                                                            $helper.strToNum(form_data.income.total) / $helper.strToNum(form_data.income.sum_schedule_travel_volume)
                                                              | currency
                                                          }}<span
                                                            style="text-transform: none;font-size: 12px"> /m<sup>3</sup></span></span>
                            </badge>
                      </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 mb-0 widgets">
                <div class="card card-stats" style="border: 1px solid #E8E8E8; height: 115px !important;">
                  <div class="card-body">
                    <div class="row">
                      <div class="col text-center"><i style="font-size: 2rem"
                                                      class="fa-solid fa-square-poll-vertical text-primary"></i>
                        <h5
                          class="card-title text-uppercase text-muted mb-0 text-center">RESULTADO DO PERÍODO</h5>
                        <span
                          class="dashboard-text card-stats-title h3 font-weight-bold mb-0 text-center"
                          style="font-family: 'Roboto', sans-serif;">
                           <span style="font-family: FreeMono, monospace;font-size: 14px" type="secondary">
                          {{ $helper.strToNum(form_data.balance || 0) | currency }}
                        </span>
                      </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 mb-0 widgets">
                <div class="card card-stats" style="border: 1px solid #E8E8E8; height: 115px !important;">
                  <div class="card-body">
                    <div class="row">
                      <div class="col text-center">
                        <i style="font-size: 2rem"
                           class=" fa-brands fa-buffer text-yellow"></i><h5
                        class="card-title text-uppercase text-muted mb-0 text-center">Volume de carregamento</h5><span
                        class="dashboard-text card-stats-title h3 font-weight-bold mb-0 text-center"
                        style="font-family: 'Roboto', sans-serif;">
                         <span style="font-family: FreeMono, monospace;font-size: 14px" type="secondary">
                          {{ $helper.toDecimal(form_data.income.sum_schedule_travel_volume || 0) }} <span
                           style="text-transform: none;font-size: 13px">m<sup>3</sup></span></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <transition name="fade">
              <div class="col-md-12" v-if="showDetails">
                <div class="row" style="margin-top: -20px">
                  <div class="col-md-3">
                    <div class="card card-stats" style="border: 1px solid #E8E8E8">
                      <div class="card-body">
                        <div class="row">
                          <div class="col text-center">
                            <h5
                              class="card-title text-uppercase text-muted mb-0 text-center">CONCRETO</h5>
                            <span
                              class="dashboard-text card-stats-title h3 font-weight-bold mb-0 text-center"
                              style="font-family: 'Roboto', sans-serif;">
                          <badge style="font-family: FreeMono, monospace;font-size: 14px;background: none"
                                 type="secondary">
                               {{ $helper.strToNum(form_data.income.sum_schedule_travel_cost_billing) | currency }}
                            <br>
    <span style="font-family: FreeMono, monospace;font-size: 13px" class="font-weight-400 ml-2"> {{
        $helper.strToNum(form_data.income.sum_schedule_travel_cost_billing) / $helper.strToNum(form_data.income.sum_schedule_travel_volume)
          | currency
      }}<span style="text-transform: none;font-size: 12px"> /m<sup>3</sup></span></span>
                            </badge>
                      </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 widgets">
                    <div class="card card-stats" style="border: 1px solid #E8E8E8">
                      <div class="card-body">
                        <div class="row">
                          <div class="col text-center">
                            <h5
                              class="card-title text-uppercase text-muted mb-0 text-center">SERVIÇO</h5>
                            <span
                              class="dashboard-text card-stats-title h3 font-weight-bold mb-0 text-center"
                              style="font-family: 'Roboto', sans-serif;">
                          <badge style="font-family: FreeMono, monospace;font-size: 14px;background: none"
                                 type="secondary">
                               {{ $helper.strToNum(form_data.income.sum_schedule_service_cost_cost) | currency }}
                            <br>
    <span style="font-family: FreeMono, monospace;font-size: 13px" class="font-weight-400 ml-2"> {{
        $helper.strToNum(form_data.income.sum_schedule_service_cost_cost) / $helper.strToNum(form_data.income.sum_schedule_travel_volume)
          | currency
      }}<span style="text-transform: none;font-size: 12px"> /m<sup>3</sup></span></span>
                            </badge>
                      </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 widgets">
                    <div class="card card-stats" style="border: 1px solid #E8E8E8">
                      <div class="card-body">
                        <div class="row">
                          <div class="col text-center">
                            <h5
                              class="card-title text-uppercase text-muted mb-0 text-center">ADICIONAL</h5>
                            <span
                              class="dashboard-text card-stats-title h3 font-weight-bold mb-0 text-center"
                              style="font-family: 'Roboto', sans-serif;">
                          <badge style="font-family: FreeMono, monospace;font-size: 14px;background: none"
                                 type="secondary">
                               {{ $helper.strToNum(form_data.income.sum_schedule_additional_price) | currency }}
                            <br>
    <span style="font-family: FreeMono, monospace;font-size: 13px" class="font-weight-400 ml-2"> {{
        $helper.strToNum(form_data.income.sum_schedule_additional_price) / $helper.strToNum(form_data.income.sum_schedule_travel_volume)
          | currency
      }}<span style="text-transform: none;font-size: 12px"> /m<sup>3</sup></span></span>
                            </badge>
                      </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 widgets">
                    <div class="card card-stats" style="border: 1px solid #E8E8E8">
                      <div class="card-body">
                        <div class="row">
                          <div class="col text-center">
                            <h5
                              class="card-title text-uppercase text-muted mb-0 text-center">AVULSO</h5>
                            <span
                              class="dashboard-text card-stats-title h3 font-weight-bold mb-0 text-center"
                              style="font-family: 'Roboto', sans-serif;">
                          <badge style="font-family: FreeMono, monospace;font-size: 14px;background: none"
                                 type="secondary">
                               {{ $helper.strToNum(form_data.income.sum_single_additional_invoice_value) | currency }}
                            <br>
    <span style="font-family: FreeMono, monospace;font-size: 13px" class="font-weight-400 ml-2"> {{
        $helper.strToNum(form_data.income.sum_single_additional_invoice_value) / $helper.strToNum(form_data.income.sum_schedule_travel_volume)
          | currency
      }}<span style="text-transform: none;font-size: 12px"> /m<sup>3</sup></span></span>
                            </badge>
                      </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </transition>
              <div class="col-md-12" style="margin-top: -20px">
                <div class="card card-stats" style="border: 1px solid #E8E8E8">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-6">
                        <label style="font-size: 0.8rem" class="form-control-label">Data de criação</label>
                        <input-date-picker :filterable="4" size="small" ref="date-picker" @handleFilterDate="handleFilterDate"
                                           :default-range="defaultRange"/>
                      </div>
                      <div class="col-md-6">
                        <label style="font-size: 0.8rem" class="form-control-label">Central</label>
                        <puzl-select
                          class="select-lg"
                          v-model="selectedCompanyPlants"
                          :items="[{ id: 'all', name: 'Todos' }, ...$_plants]"
                          customKey="id"
                          :multiple="true"
                          @input="handleFilterCompanyPlants"
                          placeholder="Central"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style="border: 1px solid #E8E8E8;"
              class="m-0 card"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-md-8 mb-4 table-responsive">
                    <draggable
                      :list="entryItems"
                      :disabled="!enabled"
                      class="dragabble"
                      :move="checkMove"
                      ghost-class="ghost"
                      @start="dragging = true"
                      @end="dragging = false"
                    >
                      <div class="row" style="cursor: pointer" @click.prevent="setSelected(item)" v-for="(item, index) in entryItems">
                        <div class="col-md-12" v-show="index !== 0">
                          <hr style="margin-top: 0px !important;">
                        </div>
                        <div class="menu col-md-3 m-0 p-2">
                          <a href="#" @click.prevent="setDown(index)" v-show="index !== entryItems.length - 1"
                             class="text-primary ml-1 avatar avatar-sm bg-primary">
                            <i class="fa-solid fa-arrow-down text-white"></i>
                          </a>
                          <a href="#" @click.prevent="setTop(index)" v-show="index !== 0"
                             class="ml-1 avatar avatar-sm bg-primary">
                            <i class="fa-solid fa-arrow-up text-white"></i>
                          </a>
                          <a @click.prevent="setSelected(item)" href="#" class="ml-1 avatar avatar-sm bg-light">
                            <i class="text-white fa-solid fa-eye"></i>
                          </a>
                        </div>
                        <div class="col-md-3 ml-1 m-0 p-0">
                          <h3>
                            <small>
                              {{ item.product_service_hub_type_name }}
                              <a @click.prevent="setSelected(item)"
                                 href="#" class="float-right mr-3 show-mobile text-white avatar avatar-sm bg-light">
                                <i class="fa-solid fa-eye"></i>
                              </a>
                            </small>
                          </h3>
                          <h4>
                            <badge style="font-family: FreeMono, monospace" type="secondary text-success"
                                   v-if="item.accumulated > 0">
                              {{ item.accumulated || 0 | currency() }}
                            </badge>
                            <badge style="font-family: FreeMono, monospace;font-size: 17px" type="secondary text-danger"
                                   v-else>
                              {{ item.entries_launche_sum_final_value || 0 | currency() }}
                            </badge>
                          </h4>
                        </div>
                        <div class="col-md-5 ml-1 m-0 p-0">
                          <h4>
                            <badge style="font-family: FreeMono, monospace;font-size: 17px" type="secondary">
                              {{ item.entries_launche_sum_final_value || 0 | currency() }} <span
                              style="font-family: FreeMono, monospace;font-size: 14px"
                              class="font-weight-400">({{
                                calc(item.entries_launche_sum_final_value, form_data.income.sum_schedule_travel_volume) | currency()
                              }} <span style="text-transform: none;font-size: 13px">/m<sup>3</sup></span>) </span>
                            </badge>
                          </h4>
                        </div>
                      </div>
                    </draggable>
                  </div>
                  <div class="menu col-md-1"/>
                  <transition name="fade">
                    <div class="col-md-3" v-if="selected">
                      <div class="row">
                        <div class=" col-md-12 ml-1 m-0 p-0">
                          <div class="row">
                            <div class="col-md-12 table-responsive">
                              <div class="timeline timeline-one-side mb-4" style="height: 400px">
                                <div class="timeline-block" v-for="(item, index) in Object.values(selected.categories)">
                                  <span class="timeline-step badge-primary text-white"> {{ index }} </span>
                                  <div class="timeline-content">
                                    <h3>
                                      <small style="text-transform: none">
                                        {{ item.category_name }}
                                      </small>
                                      <br>
                                      <badge style="font-family: FreeMono, monospace;font-size: 14px !important;"
                                             class=""
                                             type="secondary">
                                        {{ item.entry_launch_items_total_value || 0 | currency() }}
                                      </badge>
                                      <br>
                                      <span class="ml-2 font-weight-300"
                                            style="font-family: FreeMono, monospace;font-size: 14px !important;text-transform: none"
                                      >
                                        {{
                                          ((item.entry_launch_items_total_value || 0) / form_data.income.sum_schedule_travel_volume) | currency()
                                        }} <span
                                        style="text-transform: none;font-size: 13px">/m<sup>3</sup></span></span>
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--    <div class="row d-flex justify-content-center mx-1">-->
  <!--      <card class="col-md-8 mr-1">-->
  <!--        <div class="row">-->
  <!--          <div class="col-md-4 mt-3">-->
  <!--            <input-date-picker ref="date-picker" @handleFilterDate="handleFilterDate" :default-range="defaultRange"/>-->
  <!--          </div>-->
  <!--          <div class="col-md-8 mt-3">-->
  <!--            <puzl-select-->
  <!--              v-model="selectedCompanyPlants"-->
  <!--              :items="[{ id: 'all', name: 'Todos' }, ...$_plants]"-->
  <!--              customKey="id"-->
  <!--              :multiple="true"-->
  <!--              @input="handleFilterCompanyPlants"-->
  <!--              placeholder="Central"-->
  <!--            />-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </card>-->
  <!--    </div>-->

  <!--    <div v-if="form_data.income && !isLoading" class="row d-flex justify-content-center mx-1">-->
  <!--      <card class="col-md-4 mr-1">-->
  <!--        <div class="row">-->
  <!--          <div class="col-md-8" style="margin-top: -10px">-->
  <!--            <p class="mb-0 text-sm text-uppercase font-weight-bold pl-2">-->
  <!--              <small class="font-weight-bold">Resultado</small>-->
  <!--            </p>-->
  <!--          </div>-->
  <!--          <div class="col-md-4" style="margin-top: -5px">-->
  <!--            <div class="text-center shadow icon icon-shape bg-green rounded-circle float-right">-->
  <!--              <i class="text-lg opacity-10 ni ni-money-coins text-white" aria-hidden="true"></i>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--          <div class="col-md-12" style="margin-top: -25px">-->
  <!--            <h3 class="mb-0 pl-2 font-weight-normal">{{ $helper.strToNum(form_data.balance) | currency }}</h3>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </card>-->
  <!--      <card class="col-md-4 ml-1">-->
  <!--        <div class="row">-->
  <!--          <div class="col-md-8" style="margin-top: -10px">-->
  <!--            <p class="mb-0 text-sm text-uppercase font-weight-bold pl-2">-->
  <!--              <small class="font-weight-bold">Volume</small>-->
  <!--            </p>-->
  <!--          </div>-->
  <!--          <div class="col-md-4" style="margin-top: -5px">-->
  <!--            <div class="text-center shadow icon icon-shape bg-warning rounded-circle float-right">-->
  <!--              <i class="fas fa-ellipsis-h text-white" aria-hidden="true"></i>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--          <div class="col-md-12" style="margin-top: -25px">-->
  <!--            <h3 class="mb-0 pl-2 font-weight-normal">{{-->
  <!--                $helper.toDecimal(form_data.income.sum_schedule_travel_volume)-->
  <!--              }} m<sup>3</sup></h3>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </card>-->

  <!--      <card class="col-md-8 m-1 p-0">-->
  <!--        <div class="row" role="button" @click="openOrCloseIncome">-->
  <!--          <div class="h2 col-md-6">RECEITA</div>-->
  <!--          <div v-if="form_data.income.total" class="h2 col-md-6 text-right">-->
  <!--            {{ form_data.income.total | currency }}-->
  <!--            <span>-->
  <!--              ({{-->
  <!--                ($helper.strToNum(form_data.income.total) / $helper.strToNum(form_data.income.sum_schedule_travel_volume)) | currency-->
  <!--              }}/m<sup>3</sup>)-->
  <!--            </span>-->
  <!--          </div>-->
  <!--          <div v-else class="h2 col-md-6 text-right">R$ 0,00</div>-->
  <!--        </div>-->
  <!--        <div v-show="form_data.income.expanded" role="button" @click="openOrCloseIncome">-->
  <!--          <div class="row">-->
  <!--            <div class="col-md-8 text-right">Concreto</div>-->
  <!--            <div class="col-md-4 text-right">-->
  <!--              {{ $helper.strToNum(form_data.income.sum_schedule_travel_cost_billing) | currency }}-->
  <!--            </div>-->
  <!--          </div>-->
  <!--          <div class="row">-->
  <!--            <div class="col-md-8 text-right">Serviço</div>-->
  <!--            <div class="col-md-4 text-right">-->
  <!--              {{ $helper.strToNum(form_data.income.sum_schedule_service_cost_cost) | currency }}-->
  <!--            </div>-->
  <!--          </div>-->
  <!--          <div class="row">-->
  <!--            <div class="col-md-8 text-right">Adicional</div>-->
  <!--            <div class="col-md-4 text-right">-->
  <!--              {{ $helper.strToNum(form_data.income.sum_schedule_additional_price) | currency }}-->
  <!--            </div>-->
  <!--          </div>-->
  <!--          <div class="row">-->
  <!--            <div class="col-md-8 text-right">Avulso</div>-->
  <!--            <div class="col-md-4 text-right">-->
  <!--              {{ $helper.strToNum(form_data.income.sum_single_additional_invoice_value) | currency }}-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </card>-->

  <!--      <card class="col-md-8 m-1 p-0" v-for="item in entryItems">-->
  <!--        <div-->
  <!--          draggable-->
  <!--          @dragstart="startDrag($event, item)"-->
  <!--          @drop="onDrop($event, item)"-->
  <!--          @dragenter.prevent-->
  <!--          @dragover.prevent-->
  <!--        >-->
  <!--          <div class="row" role="button" @click="openOrCloseEntryItem(item)">-->
  <!--            <div class="col-md-6">-->
  <!--              <div class="h2">{{ item.product_service_hub_type_name }}</div>-->
  <!--              <div class="badge" :class="item.accumulated > 0 ? 'bg-success' : 'bg-red'" style="width: 300px">-->
  <!--                <div class="h3 text-white mb-0">{{ item.accumulated | currency }}</div>-->
  <!--              </div>-->
  <!--            </div>-->
  <!--            <div class="h2 col-md-6 text-right">-->
  <!--              {{ item.entries_launche_sum_final_value | currency }}-->
  <!--              <span> ({{-->
  <!--                  (item.entries_launche_sum_final_value / form_data.income.sum_schedule_travel_volume) | currency-->
  <!--                }}/m<sup>3</sup>) </span>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--          <div v-show="item.expanded" role="button" @click="openOrCloseEntryItem(item)">-->
  <!--            <div class="row" v-for="category in item.categories">-->
  <!--              <div class="col-md-8 text-right">-->
  <!--                {{ category.category_name }}-->
  <!--              </div>-->
  <!--              <div class="col-md-4 text-right">-->
  <!--                {{ category.entry_launch_items_total_value | currency }}-->
  <!--              </div>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </card>-->


  <!--    </div>-->
</template>

<script>
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb"
import MultiFilterV2 from "@/components/Utils/MultiFilterV2"
import InputDatePicker from "@/components/InputDatePicker"
import PuzlSelect from "@/components/PuzlSelect"
import {mapGetters} from "vuex"
import draggable from "vuedraggable";

export default {
  name: "Index",
  components: {
    PuzlBreadcrumb,
    MultiFilterV2,
    InputDatePicker,
    PuzlSelect,
    draggable
  },
  data() {
    return {
      isLoading: true,
      enabled: true,
      selected: null,
      showDetails: false,
      dragging: false,
      filter: {},
      selectedCompanyPlants: [],
      defaultRange: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      },
      range: {
        start: null,
        end: null,
      },
      form_data: {
        balance: 0,
        income: {},
        entry_items: [],
      },
    }
  },
  computed: {
    ...mapGetters({
      $_plants: "plant/activeItems",
    }),
    entryItems() {
      // Ordenar dados
      this.form_data.entry_items.sort(function (a, b) {
        if (a.sequence > b.sequence) return 1
        if (a.sequence < b.sequence) return -1
      })

      // Calcular Saldo e Valor Acumulado
      this.form_data.balance = this.form_data.income.total
      this.form_data.entry_items.forEach((item) => {
        this.form_data.balance -= item.entries_launche_sum_final_value
        item.accumulated = this.form_data.balance
      })

      return this.form_data.entry_items
    }
  },
  mounted() {
    this.isLoading = true
    this.init()
  },
  methods: {
    calc(a, b) {
      if (!a || !b) {
        return 0
      }
      return a / b
    },
    setSelected(item) {
      this.selected = false
      setTimeout(() => {
        this.selected = item
      }, 200)
    },
    init() {
      this.range = this.defaultRange
      this.selectedCompanyPlants = []
      this.fetchItems()
    },
    checkMove: function (e) {
      const atualIndex = this.entryItems.findIndex(item => item.sequence === e.draggedContext.futureIndex)
      const futureIndex = this.entryItems.findIndex(item => item.sequence === e.draggedContext.index)
      this.entryItems[atualIndex].sequence = e.draggedContext.index
      this.entryItems[futureIndex].sequence = e.draggedContext.futureIndex
    },
    async fetchItems(filter = {}) {
      this.isLoading = true
      this.filter = filter
      this.filter.range = this.range
      this.filter.company_plants = !this.selectedCompanyPlants.includes("all") ? this.selectedCompanyPlants : []
      // Resumo de Viagens Encerradas
      this.form_data.income = await this.$store
        .dispatch("scheduleResume/getSumResumeFinishedTravels", {filter: this.filter})
        .then((response) => response.data[0])
        .catch((error) => {
          this.isLoading = false
        })
      // EntryItems Agrupado p/ Tipo e Categoria
      this.form_data.entry_items = await this.$store
        .dispatch("entryLaunch/getEntryItemsGroupedByTypeAndCategory", {filter: this.filter})
        .then((response) => {
          return response.data.map(function (item, index) {
            return {...item, sequence: index}
          })
        })
        .catch((error) => {
          this.isLoading = false
        })

      this.isLoading = false
    },
    handleFilterDate(filter) {
      this.range = filter
      this.fetchItems()
    },
    setDown(index) {
      this.entryItems[index].sequence = index + 1
      this.entryItems[index + 1].sequence = index - 1
      this.entryItems.forEach(function (item, index) {
        item.sequence = index
      })
    },
    setTop(index) {
      this.entryItems[index].sequence = index - 1
      this.entryItems[index - 1].sequence = index + 1
      this.entryItems.forEach(function (item, index) {
        item.sequence = index
      })
    },
    handleFilterCompanyPlants() {
      const index = this.selectedCompanyPlants.findIndex((item) => item == "all")
      if (this.selectedCompanyPlants.length > 1 && index === 0) {
        return this.selectedCompanyPlants.splice(0, 1)
      }
      if (this.selectedCompanyPlants.length > 1 && this.selectedCompanyPlants.includes("all")) {
        this.selectedCompanyPlants = []
        this.selectedCompanyPlants.push("all")
      }
      this.fetchItems()
    },
    updateProductServiceHubType(item) {
      const payload = {
        id: item.product_service_hub_type_id,
        name: item.product_service_hub_type_name,
        default: item.product_service_hub_type_default,
        sequence: item.sequence
      }
      this.$store.dispatch('productServiceHubType/update', payload)
    }
  },
}
</script>
<style>
@media only screen and (max-width: 600px) {
  .widgets {
    margin-top: -20px !important;
  }
}
</style>
