<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-md-4">
          <h2 style="font-size: 1.2rem !important; color: #606062 !important" class="text-primary">
            <img height="30px" src="/img/icons/icons8/ios/settings-3--v1_gray.png" class="mr-2 mb-1" />
            Alertas de usuário
          </h2>
        </div>
        <div class="col-md-8 pr-4">
          <div class="row d-flex justify-content-end">
            <div class="mb-md-0 mb-2 col-md-3 px-1">
             <base-button class="text-uppercase" block size="sm" type="success" @click="handleModalCreateAlertGroup()">
                <img src="/img/icons/plus-math--v1-white.png" width="23px" height="23px" /> Novo
              </base-button>
            </div>
            <div class="mb-md-0 mb-2 col-md-3 px-1" :class="{'d-none': !$hasPermission('configuration.user-permissions.index.view')}">
              <router-link :to="{ path:'/configuration/user'}">
                <base-button block size="sm" type="light" class="text-uppercase">
                  <img src="/img/icons/circled-left.png" class="mr-1" width="23px" height="23px" />
                  Voltar
                </base-button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </base-header>
    <UserAlerts ref="userAlerts" :alertGroupId="idFromModalCreateAlertGroup" />
    <ModalCreateAlertGroup @changeAlertGroup="handleChangeAlertGroup" ref="modalCreateAlertGroup"/>
  </div>
</template>
<script>
import UserAlerts from './Shared/UserAlerts'
import ModalCreateAlertGroup from './Shared/_ModalCreateAlertGroup'

export default {
  components: {
    UserAlerts,
    ModalCreateAlertGroup
  },
  data() {
    return {
      idFromModalCreateAlertGroup: null
    };
  },
  methods: {
    handleModalCreateAlertGroup() {
      this.$refs.modalCreateAlertGroup.openModal();
    },
    /**
     * @param {number} id
     */
    handleChangeAlertGroup(id) {
      this.idFromModalCreateAlertGroup = id;
    },
  },
};
</script>
<style></style>