<template>
  <div class="second-node">
    <div class="d-flex flex-row align-items-center" style="gap: 10px;">
      <span class="w-100 second-node-content-item">
        <div class="mx-0">
          <span class="d-flex" style="gap: 9px;">
            <img v-if="node.opened"
              src="/img/icons/icons8/ios/minus_key.png"
              alt="minus_key"
              width="24px"
              class="pointer"
              @click="node.opened = false;"
            >
            <img v-else
              src="/img/icons/icons8/ios/plus_key.png"
              alt="plus_key"
              width="24px"
              class="pointer"
              @click="loadThirdNode(node.concreting_date, node.company_plant_uuid)"
            >
            {{ node.plant_name }}
          </span>
        </div>
      </span>
      <span class="w-70 second-node-content-item align-content-center">
        <div class="">
          <div class="d-flex justify-content-center">
            {{ node.construction_count }} {{ node.construction_count > 1 ? 'obras' : 'obra' }}
          </div>
        </div>
      </span>
      <span class="w-70 second-node-content-item align-content-center">
        <div class="">
          <div class="d-flex justify-content-center">
            {{ Math.trunc(node.average_distance) }} km
          </div>
        </div>
      </span>
      <span class="w-70 second-node-content-item align-content-center">
        <div class="">
          <div class="d-flex justify-content-center ml-2 mr-n2">
            <div>
              <span class="info-concrete-volume">
                {{ node.total_volume ? node.total_volume : 0 }} m<sup>3</sup> ({{ node.total_travels ? node.total_travels : 0 }})
              </span>
              /
              <span class="info-concrete-volume" style="color: #149E57;">
                {{ node.performed_volume ? node.performed_volume : 0 }} m<sup>3</sup> ({{
                  node.performed_travels ? node.performed_travels : 0
                }})
              </span>
            </div>
          </div>
        </div>
      </span>
      <span class="w-70 second-node-content-item align-content-center">
        <div class="">
          <div class="d-flex justify-content-center">
            <div v-if="node.service_total_volume">
              <span>
                {{ node.service_total_volume ? node.service_total_volume : 0 }} m<sup>3</sup>
              </span>
              /
              <span style="color: #149E57;">
                {{ node.service_performed_volume ? node.service_performed_volume : 0 }} m<sup>3</sup>
              </span>
            </div>
            <div v-else>
              NENHUM
            </div>
          </div>
        </div>
      </span>
    </div>
    <div class="d-flex flex-column mt-3" v-if="node.opened">
      <ThirdNode v-for="item in thirdNodeItems" :key="item.id" :node="item" class="my-1" />
    </div>
  </div>
</template>

<script>
import ThirdNode from './_ThirdNode.vue';

export default {
  name: "SecondNode",
  components: { ThirdNode },
  props: {
    node: {
      required: true
    }
  },
  data() {
    return {
      thirdNodeItems: null,
    }
  },
  methods: {
    loadThirdNode(concreting_date, company_plant_uuid, schedule_id) {
      var concreting_date = concreting_date.split(' ')[0];
      this.thirdNodeItems = null;
      this.$Progress.start();

      this.$store.dispatch("newScheduleResume/getResumeByDateAndCompanyPlant", { concreting_date: concreting_date, company_plant_uuid: company_plant_uuid })
      .then((response) => {
        this.thirdNodeItems = response.data;
      }).finally(() => this.$Progress.finish());

      this.node.opened = true;
    }
  }
}
</script>

<style scoped>
.second-node span {
  font-size: 16px;
  font-weight: 400;
  color: #2B2D32;
}
.second-node-content-item {
  background-color: #E8E8E8;
  padding: 8px 14px;
  border-radius: 8px;
  box-shadow: 0px 5px 10px 0px #0000000D;
}
.second-node-content-item sup {
  align-content: center;
}
.w-70 {
  width: 70%;
}
</style>