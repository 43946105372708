<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(update)" autocomplete="off">
            <input :checked="priceWithThreeDecimalPlace" @change="handlePriceDecimalPlace" type="checkbox" class="ml-3" />
            <span class="text-dark"> Adotar preço com 3 casas decimais</span>
            <div>
              <!-- Tipo -->
              <div>
                <div class="form-group row m-1 p-0">
                  <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">Tipo </label>
                  <div class="col-md-8">
                    <base-button block size="sm" type="primary">
                      {{ item.type_name || 'Tipo não informado' }}
                    </base-button>
                  </div>
                </div>
              </div>
              <!-- Descrição -->
              <div class="form-group row m-1 p-0">
                <label class="col-md-4 pb-0 pt-2 mb-1 col-form-label form-control-label"> Descrição </label>
                <div class="col-md-8">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      disabled
                      :value="`${item.product_service_hub.product_name} (${item.product_service_hub.category_name || 'Categoria não informada'})`"
                      class="form-control form-control-sm"
                    />
                  </base-input>
                </div>
              </div>
              <!-- NCM -->
              <div v-if="item.product_service_hub.ncm">
                <div class="form-group row m-1 p-0">
                  <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label"> NCM </label>
                  <div class="col-md-8">
                    <base-input input-group-classes="input-group-sm">
                      <input disabled v-model="item.product_service_hub.ncm" class="form-control form-control-sm" />
                    </base-input>
                  </div>
                </div>
              </div>
              <!-- Unid. de Compra -->
              <div>
                <div v-show="!default_product_service_hub_types.includes(item.product_service_hub_type_id)" class="form-group row m-1 p-0">
                  <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label"> Unid. de Compra </label>
                  <div class="col-md-8">
                    <base-input input-group-classes="input-group-sm">
                      <input disabled v-model="item.product_service_hub.purchase_unit" class="form-control form-control-sm" />
                    </base-input>
                  </div>
                </div>
              </div>
              <!-- Quantidade -->
              <div>
                <div
                  v-show="!default_product_service_hub_types.includes(item.product_service_hub.product_service_hub_type_id) || entry_launch_types[item.product_service_hub.product_service_hub_type_id] === 'Frete'"
                  class="form-group row m-1 p-0"
                >
                  <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">
                    Quantidade
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-8">
                    <validation-provider rules="required">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          @change="getTotalValue"
                          v-on:input="item.quantity = formatString($event.target.value, '0', '99999.999', 3)"
                          inputmode="numeric"
                          v-model="item.quantity"
                          class="form-control form-control-sm"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
              </div>
              <!-- Preço -->
              <div>
                <div class="form-group row m-1 p-0">
                  <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">
                    {{
                      default_product_service_hub_types.includes(item.product_service_hub.product_service_hub_type_id) &&
                      entry_launch_types[item.product_service_hub.product_service_hub_type_id] !== 'Frete'  ?
                      'Total' : 'Preço'
                    }}
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-8">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        @input="getTotalValue"
                        v-money="money"
                        v-model.lazy="item.price"
                        maxlength="13"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                </div>
              </div>
              <!-- Desconto Unitário -->
              <div v-if="showUnitDiscount">
                <div class="form-group row m-1 p-0">
                  <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">
                    Desconto Total
                  </label>
                  <div class="col-md-8">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        @input="getTotalValue"
                        v-money="money"
                        v-model.lazy="item.discount"
                        maxlength="13"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                </div>
              </div>
              <!-- Total -->
              <div
                v-show="!default_product_service_hub_types.includes(item.product_service_hub.product_service_hub_type_id) || entry_launch_types[item.product_service_hub.product_service_hub_type_id] === 'Frete'"
                class="form-group row m-1 p-0"
              >
                <label class="col-md-4 pb-0 pt-2 col-form-label form-control-label"> Total </label>
                <div class="col-md-8">
                  <base-input input-group-classes="input-group-sm">
                    <input disabled v-model="human_total_value" class="form-control form-control-sm" />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0"> R$ </small>
                    </template>
                  </base-input>
                </div>
              </div>
              <div class="modal-footer d-flex justify-content-center">
                <base-button type="secondary" @click="closeModal('create')"> Cancelar </base-button>
                <base-button type="success" native-type="submit" v-bind:disabled="invalid || item.total_value <= 0" :loading="loadingStore">
                  Salvar
                </base-button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { VMoney } from "v-money";
import entryLaunchMixin from "../Mixins/entry_launch_mixin";
import entryLaunchItemMixin from "../Mixins/entry_launch_item_mixin";
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "ModalEditEntryLaunchItem",
  mixins: [entryLaunchMixin, entryLaunchItemMixin],
  data() {
    return {
      modal: {
        title: "Editar Item",
        create: false,
      },
      item: {
        type_name:null,
        quantity: null,
        price: null,
        discount: 0,
        total_value: 0,
        is_fuel_group: false,
        anp_code: null,
        anp_description: null,
        consumer_uf: null,
        cest: null,
        product_service_hub_id: null,
        product_service_hub: {},
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      loadingStore: false,
      index:null,
      priceWithThreeDecimalPlace:false,
      showUnitDiscount: false,
    };
  },
  components: { PuzlSelect },
  directives: { money: VMoney },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal(item, index, showUnitDiscount) {
      this.index = index;
      this.item = { ...item };
      this.showUnitDiscount = showUnitDiscount;
      const price = String(item.price);
      const decimalPlace = price.split('.')[1];
      if(price.charAt(price.length - 1) !== '0' && decimalPlace.length === 3) {
        this.money.precision = 3;
        this.priceWithThreeDecimalPlace = true;
      } else {
        this.item.price = price.slice(0, -1);
        this.priceWithThreeDecimalPlace = false;
        this.money.precision = 2;
      }
      this.modal.create = true;
    },
    update() {
      let item = this.$helper.cloneObject(this.item);
      item.price = Number(this.$helper.moneyToFloat(this.item.price)).toFixed(3);
      this.$emit("updated", item, this.index);
      this.closeModal();
    },
    handlePriceDecimalPlace() {
      this.money.precision = this.money.precision === 2 ? 3 : 2;
      this.priceWithThreeDecimalPlace = !this.priceWithThreeDecimalPlace;
    },
  },
  computed: {
    human_total_value() {
      return `${this.item.total_value.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}`;
    },
  },
};
</script>

<style scoped>
.modal {
  overflow-y: scroll !important;
}
</style>
