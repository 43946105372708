<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">

            <div class="form-group row m-0 p-0">
                <label class="col-md-12 pb-0 mb-1 col-form-label form-control-label">
                    Vendedor
                    <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-12 mb-1 pr-4">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <PuzlSelect
                        v-model="seller" 
                        :items="$_sellers"
                        :loading="loadingSeller" 
                        :disabled="loadingSeller" />
                    </base-input>
                  </validation-provider>
                </div>
            </div>

            <div class="modal-footer">
              <base-button type="danger" @click="closeModal('create')">
                <img src="/img/icons/cancel-white.png" width="22px" height="22px" />
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                <img src="/img/icons/save.png" width="22px" height="22px" />
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import PuzlSelect from "@/components/PuzlSelect";
const { formatErrorValidation } = require("@/plugins");
import {invoice as $_totvs}  from "@/plugins/microservices/totvs";

export default {
  name: "ModalCreate",
  components: { PuzlSelect },
  data() {
      return {
        modal: {
          title: 'Alterar vendedor da fatura',
          create: false,
        },
        seller: null,
        invoiceId: null,
        loadingStore: false,
        loadingSeller: true,
      };
  },
  computed: {
    ...mapGetters({
      $_sellers: "user/getAllActiveSellersSimplified",
    }),
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal(invoiceId) {
      this.invoiceId = invoiceId;
      this.loadData();
      this.modal.create = true;
    },
    store() {
      this.$Progress.start();
      this.loadingStore = true;
      const params = {
        id: this.invoiceId,
        seller_id: this.seller,
      };
      this.$store.dispatch("billingInvoice/updateSellerInvoice", params).then((response) => {
        this.$emit("updatedSeller", response.data);
        $_totvs.updateConstrupay(this.invoiceId);
        this.$notify({
          type: response.error_type,
          message: response.message
        });
        this.closeModal();
      }).finally(() => {
        this.loadingStore = false;
        this.$Progress.finish();
      });
    },
    loadData() {
      this.loadingSeller = true;
      this.$store.dispatch("user/getAllActiveSellersSimplified").then(() => {
        this.loadingSeller = false;
      });
    },
  },
};
</script>

<style scoped></style>
