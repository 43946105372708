<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <h4>Hora extra</h4>
        <div class="dropdown-divider p-0 m-0 pb-3"></div>
        <div v-for="(history, indexHistory) in histories" :key="indexHistory">
          <div class="card mb-2">
            <div class="card-body shadow">
              <div class="row mb-n2">
                <div class="col-3">
                  <badge type="default" class="" style="font-size: 24px">
                    V.{{ history.version }}
                  </badge>
                </div>
                <div class="col-5">
                  <h5 class="ml-n5">
                    {{ history.standard_value }}
                    {{ history.billing_unit_description }}
                  </h5>
                  <h5
                    class="mt-n2 ml-n5"
                    v-if="history.params && history.params.a"
                  >
                    {{ history.params.a.description }}
                  </h5>
                </div>
                <div class="col-4 h5 text-right">
                  {{ history.user }} <br>
                  {{ history.created_at | parseDate("DD MMM YYYY hh:mm") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: "HistoricAdditional",
  data() {
    return {
      modal: {
        title: "Histórico",
        create: false,
      },
      histories: null,
    };
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal(histories) {
      this.histories = histories;
      this.modal.create = true;
    },
  },
};
</script>

<style scoped></style>
