<template>
  <div>
    <modal :show.sync="modal.create" size="lg">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation p-2 mt-2" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <!-- Data de entrada -->
            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pl-2 pt-2 pb-0 col-form-label form-control-label">
                Data de entrada <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-auto">
                <base-button class="mb-n2" size="sm" type="success" @click.prevent="setEntryDate">
                  <i class="fa fa-clock text-white"></i>
                  AGORA
                </base-button>
              </div>
              <div class="col">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                    <input-date-time-picker :disableMaxDate="max_date" :disableDate="disableMinDate" v-model="payload.entry_date" />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!-- Central -->
            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pl-2 pt-2 pb-0 col-form-label form-control-label">
                Central de custo <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required">
                  <!-- form-control-sm -->
                  <base-input input-group-classes="input-group-sm">
                    <puzl-select @input="setEntryCompanyPlant" v-model="payload.company_plant_id" :items="$_company_plants" />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!-- Central de entrada -->
            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pl-2 pt-2 pb-0 col-form-label form-control-label">
                Central de entrada <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                    <puzl-select v-model="payload.entry_company_plant_id" :items="$_company_plants" />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!-- Tipo -->
            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pl-2 pt-2 pb-0 col-form-label form-control-label">
                Tipo <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                    <puzl-select v-model="payload.document_template_id" :items="document_templates" />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!-- Chave -->
            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pl-2 pr-0 pb-0 pt-0 col-form-label form-control-label mb-3 mb-md-0 row ml-0 mr-0 align-items-center">
                Chave
                <span class="text-danger pr-2">&nbsp;*</span>
                <!-- <span
                  @click.prevent="handleOpenBarCodeScanner"
                  class="btn btn-sm col-md-2 mt-0 btn-block btn-warning pr-1 pl-1 mb-3 mb-md-0 mr-1"
                >
                  <i class="fas fa-barcode border p-1"></i>
                </span>
                <span
                  @click.prevent="handleOpenBarCodeGallery"
                  class="btn btn-sm col-md-2 mt-0 btn-block btn-primary pr-1 pl-1 mr-1 mb-3 mb-md-0"
                >
                  <i class="fas fa-camera border border p-1"></i>
                </span>
                <span
                  @click.prevent="handleOpenPuzlBarCodeScanner"
                  class="btn btn-sm col-md-2 mt-0 btn-block btn-indigo pr-1 pl-1 mb-0 mr-0"
                >
                  <i class="fas fa-barcode border p-1"></i>
                </span> -->
              </label>
              <div class="col-md-7">
                <validation-provider rules="required|numeric|length:44">
                  <base-input input-group-classes="input-group-sm pt-0">
                    <input inputmode="numeric" class="form-control form-control-sm" v-model="payload.access_key" maxlength="44" />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!-- Motorista (Insumos) -->
            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pl-2 pt-2 pb-0 col-form-label form-control-label">
                Motorista (Insumos)
              </label>
              <div class="col-md-7">
                <base-input input-group-classes="input-group-sm">
                  <PuzlSelect :disabled="loadingDrivers" v-model="payload.driver_id" :items="$_drivers" />
                </base-input>
              </div>
            </div>
            <div v-show="readBarCode === 'scanner'" class="border-header-warning-bottom p-1 mt-3 mb-1">
              <h4 class="m-0">Leitor de código de barras</h4>
              <button @click.prevent="handleCloseBarCodeScanner" type="button" class="close text-danger" style="margin-top: -21px">
                <i class="fas fa-times" style="font-size: 20px"></i>
              </button>
            </div>
            <ModalReadBarCode ref="modalReadBarCode" v-if="readBarCode !== false" :onDecode="onDecode" :readBarCode="readBarCode" />
            <PuzlBarCodeReader v-if="modal.create" ref="modalPuzlBarCodeReader" :onDecode="onDecode" />
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">Cancelar</base-button>
              <base-button type="success" native-type="submit" :disabled="invalid" :loading="loadingStore">Salvar</base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import InputDateTimePicker from "@/components/InputDateTimePicker";
import { mapGetters } from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
import ModalReadBarCode from "@/components/BarCodeReader.vue";
import PuzlBarCodeReader from "@/components/PuzlBarCodeReader";
import { formatErrorValidation } from "@/plugins";
import moment from "moment";

export default {
  name: "ModalCreateByAccessKey",
  components: { InputDateTimePicker, PuzlSelect, ModalReadBarCode, PuzlBarCodeReader },
  data() {
    return {
      modal: {
        title: "Entrada de Nota Fiscal Eletrônica",
        create: false,
      },
      loadingStore: false,
      loadingCompanyPlantBuyer: false,
      readBarCode: false,
      disableMinDate: null,
      payload: {
        entry_date: null,
        company_plant_id: null,
        entry_company_plant_id: null,
        document_template_id: null,
        access_key: null,
        driver_id: null,
      },
      document_templates: [
        {
          id: 5,
          name: "NF-e",
        },
        {
          id: 6,
          name: "CT-e",
        },
      ],
      company_plant_buyers: [],
      max_date: false,
      loadingDrivers: false,
    };
  },
  computed: {
    ...mapGetters({
      $_company_plants: "plant/activeItems",
      $_latest_closing: "stockClosing/getLatestClosingAt",
      $_drivers: "user/getEntryDrivers",
    }),
    formatted_company_plant_buyers() {
      return this.company_plant_buyers.map(function (item) {
        item.name = item.issuer.ein;
        return item;
      });
    },
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    async updateLatestStock() {
      await this.$store.dispatch('stockClosing/getLatestClosingAt')
    },
    async openModal(payload = false) {
      let loader = this.$loading.show()
      await this.updateLatestStock()
      this.disableMinDate = new Date(moment(this.$_latest_closing.closing_at).add(1, "days"))
      this.loadingStore = false;
      this.loadingCompanyPlantBuyer = false;
      this.readBarCode = false;
      this.loadingDrivers = true;
      if (payload === false) {
        this.payload = {
          entry_date: null,
          company_plant_id: null,
          entry_company_plant_id: null,
          document_template_id: 5,
          access_key: null,
          driver_id: null,
        };
        if (this.$_company_plants.length === 1) {
          this.payload.company_plant_id = this.$_company_plants[0].id;
          this.payload.entry_company_plant_id = this.$_company_plants[0].id;
        }
      } else {
        this.payload = payload;
      }
      this.company_plant_buyers = [];
      this.max_date = new Date(this.$helper.parseDate(moment(), 'YYYY-MM-DD HH:mm:ss'));
      loader.hide()
      this.modal.create = true;
      await this.$store.dispatch("user/getEntryDrivers");
      this.loadingDrivers = false;
    },
    store() {
      this.loadingStore = true;
      this.payload.entry_date = moment(this.payload.entry_date).format("YYYY-MM-DD HH:mm:ss");
      this.$store
        .dispatch(`entryLaunch/${this.payload.id ? "update" : "add"}ByAccessKey`, this.payload)
        .then((response) => {
          this.loadingStore = false;
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.$emit("saved");
          this.closeModal();
        })
        .catch((error) => {
          if (error.status) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response && error.response.status && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
          this.loadingStore = false;
        });
    },
    onDecode(value) {
      this.readBarCode = false;
      if (!this.$helper.isNumber(value) || value.length !== 44) {
        return ;
      }
      this.payload.access_key = value;
      this.$refs.modalPuzlBarCodeReader.stop();
    },
    handleOpenBarCodeGallery() {
      this.readBarCode = "gallery";
      this.$refs.modalReadBarCode.openGallery();
    },
    handleOpenBarCodeScanner() {
      this.readBarCode = "scanner";
    },
    handleOpenPuzlBarCodeScanner() {
      this.$refs.modalPuzlBarCodeReader.start();
    },
    handleCloseBarCodeScanner() {
      this.readBarCode = false;
    },
    setEntryCompanyPlant(value) {
      this.payload.entry_company_plant_id = value;
    },
    setEntryDate() {
      this.payload.entry_date = new Date();
    },
  },
};
</script>

<style>
.modal {
  overflow-y: scroll !important;
}
</style>
