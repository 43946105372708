<template>
  <div>
    <modal :show.sync="modal.create" size="md">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div class="p-2">

              <!-- Chave de acesso -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Chave de acesso
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7 pt-2">
                  <validation-provider rules="required|numeric|length:44">
                    <base-input input-group-classes="input-group-sm">
                      <input maxlength="44" v-model="access_key" class="form-control form-control-sm" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="modal-footer d-flex justify-content-end mt-2">
                <base-button type="secondary" @click="closeModal('create')"> CANCELAR </base-button>
                <base-button :loading="loading" type="success" native-type="submit" v-bind:disabled="invalid"> SOLICITAR </base-button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { formatErrorValidation } from "@/plugins";

export default {
  name: "ModalSearchEntryLaunchByAccessKey",
  data() {
    return {
      modal: {
        title: "Buscar nota via chave de acesso",
        create: false,
      },
      access_key: null,
      loading: false,
    };
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal() {
      this.access_key = null;
      this.loading = false;
      this.modal.create = true;
    },
    store() {
      let loader = this.$loading.show();
      this.loading = true;
      this.$store
        .dispatch("entryLaunch/searchByAccessKey", {access_key: this.access_key})
        .then((response) => {
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.closeModal();
        })
        .catch((error) => {
          if (error.status) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response && error.response.status && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
        }).finally(() => {
          this.loading = false;
          loader.hide();
        })
    },
  },
};
</script>

<style></style>
