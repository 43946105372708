<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div class="container">
              <h3 class="m-0">
                {{ $_contractProposal.code }}
              </h3>
              <h3 class="m-0">
                {{ $_contractProposal.construction.construction_name }}
              </h3>
              <h3 class="mb-n3">
                {{ $_contractProposal.entity.document }}
              </h3>
              <hr class="bg-primary">
            </div>
            <div class="form-group row m-0 p-0 mt-n4">
              <label class="col-md-12 pb-0 mb-1 col-form-label form-control-label">
                Métodos de pagamento
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-12">
                <div class="mb-1 pr-4">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <puzl-select
                        v-model="$_contractProposal.payment_methods_ids"
                        :items="$_paymentMethods"
                        multiple
                        :loading="loadingMethods"
                        :disabled="loadingMethods"
                        @input="setPaymentTermsByMethod()"/>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-12 pb-0 mb-1 col-form-label form-control-label">
                Condições de pagamento
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-12">
                <div class="mb-1 pr-4">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <puzl-select
                        v-model="$_contractProposal.payment_terms_uuids"
                        :items="payment_terms_by_principal"
                        customKey="uuid"
                        multiple
                        :loading="loadingTerms"
                        :disabled="loadingTerms"/>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-12 pb-0 mb-1 col-form-label form-control-label">
                Observações para nota fiscal de serviço
              </label>
              <div class="col-md-12">
                <div class="mb-1 pr-4">
                  <base-input input-group-classes="input-group-sm">
                            <textarea
                              type="text" rows="3" class="form-control form-control-sm" maxlength="400"
                              v-model="$_contractProposal.observation"/>
                  </base-input>
                </div>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-12 pb-0 mb-1 col-form-label form-control-label">
                Orientações para o faturamento
              </label>
              <div class="col-md-12">
                <div class="mb-1 pr-4">
                  <base-input input-group-classes="input-group-sm">
                            <textarea
                              type="text" rows="3" class="form-control form-control-sm"
                              v-model="$_contractProposal.billing_guidelines"/>
                  </base-input>
                </div>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <label class="col-md-12 pb-0 mb-1 col-form-label form-control-label">
                Faturamento por medição
              </label>
              <div class="col-md-12">
                <div class="mb-1 pr-4">
                  <base-switch v-model="$_contractProposal.measurement" type="primary" offText="NÃO" onText="SIM"
                               class="success">
                  </base-switch>
                </div>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <label class="col-md-12 pb-0 mb-1 col-form-label form-control-label">
                Reidi
              </label>
              <div class="col-md-12">
                <div class="mb-1 pr-4" @click="validPermissionCanIncludeReidi">
                  <base-switch :disabled="!$_financial_limit.invoice_can_include_reidi" v-model="$_contractProposal.reidi" type="primary" offText="NÃO" onText="SIM"
                               class="danger">
                  </base-switch>
                </div>
              </div>
            </div>
            <div class="row m-0 p-0 mt-n2">
              <div class="col-md-12">
                <div class="pb-1 col-form-label form-control-label">
                  INSC. ESTADUAL
                </div>
                <base-input input-classes="form-control-sm">
                  <input
                    class="form-control form-control-sm"
                    v-model="$_contractProposal.state_registration"/>
                </base-input>
              </div>
              <div class="col-md-12">
                <div class="pb-1 col-form-label form-control-label">
                  Cód. de obra da prefeitura
                </div>
                <base-input input-group-classes="input-group-sm">
                  <input class="form-control form-control-sm" v-model="$_contractProposal.city_hall_construction_code"/>
                </base-input>
              </div>
              <div class="col-md-12">
                <div class="pb-1 col-form-label form-control-label">
                  Art/nº de controle do encapsulamento
                </div>
                <base-input input-group-classes="input-group-sm">
                  <input class="form-control form-control-sm" v-model="$_contractProposal.art_code"/>
                </base-input>
              </div>
            </div>
            <div class="row m-0 p-0 mt-n2">
              <div class="col-md-12">
                <div class="pb-1 col-form-label form-control-label">
                  CNO
                </div>
                <base-input input-classes="form-control-sm">
                  <input
                    class="form-control form-control-sm"
                    maxlength="15"
                    v-mask="'##.###.#####/##'"
                    v-model="$_contractProposal.cno"/>
                </base-input>
              </div>
              <div class="col-md-12">
                <div class="pb-1 col-form-label form-control-label">
                  Cód. de obra da prefeitura
                </div>
                <base-input input-group-classes="input-group-sm">
                  <input class="form-control form-control-sm" v-model="$_contractProposal.city_hall_construction_code"/>
                </base-input>
              </div>
              <div class="col-md-12">
                <div class="pb-1 col-form-label form-control-label">
                  Art/nº de controle do encapsulamento
                </div>
                <base-input input-group-classes="input-group-sm">
                  <input class="form-control form-control-sm" v-model="$_contractProposal.art_code"/>
                </base-input>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')">
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import PuzlSelect from "@/components/PuzlSelect";

const {formatErrorValidation} = require("@/plugins");

export default {
  name: "ModalBilling",
  components: {PuzlSelect},
  data() {
    return {
      modal: {
        title: 'Faturamento',
        create: false,
      },
      loadingStore: false,
      loadingMethods: true,
      loadingTerms: true,
      payment_terms_by_principal: [],
    };
  },
  computed: {
    ...mapGetters({
      $_contractProposal: 'contractProposal/show',
      $_paymentMethods: "paymentMethod/fetch",
      $_paymentTerms: "paymentTerm/fetch",
      $_financial_limit: "permissionFinancialLimit/show",
    }),
  },
  async mounted() {
    this.$refs.formValidator.validate();
    this.$store.dispatch("paymentMethod/fetchItems").then(() => {
      this.loadingMethods = false;
    });
    this.$store.dispatch("paymentTerm/fetchItems").then(() => {
      this.loadingTerms = false;
    });
    await this.fetchFinancialLimits(this.$user.id)
  },
  methods: {
    validPermissionCanIncludeReidi() {
      if (!this.$_financial_limit.invoice_can_include_reidi) {
        this.$notify({
          type: 'danger',
          message: 'Usuário não possui permissão para realizar alteração'
        });
      }
    },
    /**
     * @param {number} userId
     */
    fetchFinancialLimits(userId) {
      this.$store.dispatch('permissionFinancialLimit/show', userId);
    },
    setPaymentTermsByMethod(clear = true) {
      if (clear) {
        this.$_contractProposal.payment_terms_uuids = [];
      }
      this.payment_terms_by_principal = this.$_paymentTerms.filter((item) => {
        return item.allow_in_proposal && item.payment_methods.some(r => this.$_contractProposal.payment_methods_ids.includes(r.id))
      });
    },
    closeModal() {
      this.modal.create = false;
    },
    /**
     * @param {string} uuid - contract uuid
     */
    handleCreateModal(uuid) {
      const loader = this.$loading.show();
      this.$store.dispatch('contractProposal/show', uuid).then((response) => {
        this.loadingMethods = false;
        this.setPaymentTermsByMethod(false);
      }).catch((error) => {
        this.$notify({
          type: 'danger',
          message: 'Erro ao carregar contrato',
        });
      }).finally(() => {
        loader.hide();
      });
      this.modal.create = true;
    },
    store() {
      this.$Progress.start()
      this.loadingStore = true;
      // formatação payload
      const contractProposal = this.$helper.cloneObject(this.$_contractProposal);
      contractProposal.average_volume_per_bt = contractProposal.average_volume_per_bt.replace(',', '.');
      this.$store.dispatch('contractProposal/update', contractProposal)
        .then((response) => {
          this.$notify({
            type: response.error_type,
            message: response.message
          })
          this.closeModal();
        })
        .catch((error) => {
          if (error.status === 200) {
            this.$notify({
              type: 'danger',
              message: error.data.message
            });
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors)
            this.$notify({
              type: 'danger',
              message: errors
            });
          }
        }).finally(() => {
        this.loadingStore = false;
        this.$Progress.finish();
      });
    },
  },
};
</script>

<style scoped></style>
