<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
            <div class="col-lg-12 col-12">
                <PuzlBreadcrumb />
            </div>
        </div>
    </base-header>
    <ListEquipmentResume />
  </div>
</template>

<script>
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import ListEquipmentResume from "./Shared/_List";

export default {
  name: "Index",
  components : {
    PuzlBreadcrumb,
    ListEquipmentResume,
  }
}
</script>

<style scoped></style>
