<template>
  <div>
    <modal :show.sync="modal.create">
      <template class="align-middle" slot="header">
        <h5 class="modal-title pl-3 align-middle">{{ modal.title }}</h5>
      </template>
      <div>

        <div class="row mt-1 mb-3 m-0 p-0">
          <div class="col pr-0">
            <base-button
              @click.prevent="selected = 0" :class="selected === 0 && 'active'"
              class="button-border-left border-black" outline type="primary" block
            >
              <span :class="selected === 0 ? 'text-white' : 'text-dark'" style="font-size: 11px;">
                Identificação
              </span>
            </base-button>
          </div>
          <div class="col pl-0">
            <base-button
              @click.prevent="selected = 1" :class="selected === 1 && 'active'"
              class="button-border-right btn-left-none border-black" outline type="primary" block
            >
              <span :class="selected === 1 ? 'text-white' : 'text-dark'" style="font-size: 11px;">
                Permissões
              </span>
            </base-button>
          </div>
        </div>

        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(store)"
            autocomplete="off"
          >
            <div>
              <!-- IDENTIFICAÇÃO -->
              <div v-if="selected === 0">
                <h2 class="text-primary ml-3 m-0 p-0">
                  Identificação
                </h2>
                <!-- Status -->
                <div class="form-group row m-0 p-0">
                  <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label">
                    Status
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-6 pt-2">
                    <base-switch type="success" offText="inativo" onText="ativo" class="success"
                                v-model="payload.status">
                    </base-switch>
                  </div>
                </div>
                <!-- Nome -->
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                  >
                    <div class="pt-1">
                      Nome
                      <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                    </div>
                  </label>
                  <div class="col-md-6 pr-4 ">
                    <validation-provider rules="required" v-slot="{errors}">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          v-model="payload.name"
                          class="form-control form-control-sm"
                          :class="errors[0] ? 'is-invalid' : 'is-valid'"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <!-- Forma de pagamento -->
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                  >
                    <div class="pt-1">
                      Forma de pagamento
                      <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                    </div>
                  </label>
                  <div class="col-md-6 pr-4 mb-2">
                    <base-input input-group-classes="input-group-sm">
                      <puzl-select v-model="payload.payment_type" :items="[
                        {
                          id: 0,
                          name: 'Débito',
                        },
                        {
                          id: 1,
                          name: 'Crédito',
                        }
                      ]"/>
                    </base-input>
                  </div>
                </div>
                <!-- Mediador de pagamento -->
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                  >
                    <div class="pt-1">
                      Mediador de pagamento
                    </div>
                  </label>
                  <div class="col-md-6 pr-4 mb-2">
                    <base-input input-group-classes="input-group-sm">
                      <puzl-select placeholder="Nenhum" multiple v-model="payload.payment_intermediary" :items="$_payment_intermediaries"/>
                    </base-input>
                  </div>
                </div>
                <!-- Códigoe externo -->
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                  >
                    <div class="pt-1">
                      Código externo
                    </div>
                  </label>
                  <div class="col-md-6 pr-4 mb-2">
                    <base-input input-group-classes="input-group-sm">
                      <input
                      disabled
                      v-model="payload.external_code"
                      type="text"
                      class="form-control form-control-sm" />
                    </base-input>
                  </div>
                </div>
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                  >
                    <div class="pt-1">
                      Detalhes para propostas/contratos
                    </div>
                  </label>
                  <div class="col-md-6 pr-4 mb-2">
                    <base-input input-group-classes="input-group-sm">
                      <textarea
                      v-model="payload.contract_details"
                      type="text"
                      class="form-control form-control-sm" />
                    </base-input>
                  </div>
                </div>
              </div>
              <!-- PERMISSÕES -->
              <div v-else>
                <h2 class="text-primary ml-3 m-0 p-0">
                  Permissões Gerais
                </h2>
                <!-- Permitir aprovação -->
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-8 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                  >
                    <div class="pt-1">
                      Permitir aprovação
                      <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                    </div>
                  </label>
                  <div class="col-md-4 pr-4 ">
                      <base-switch
                        type="primary" offText="Não" onText="Sim" class="success"
                        v-model="payload.allow_approve" />
                  </div>
                </div>
                <!-- Exige código de transação -->
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-8 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                  >
                    <div class="pt-1">
                      Exige código de transação
                      <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                    </div>
                  </label>
                  <div class="col-md-4 pr-4">
                      <base-switch
                        type="primary" offText="Não" onText="Sim" class="success"
                        v-model="payload.transaction_code" />
                  </div>
                </div>
                <!-- Exibir em antecipação -->
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-8 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                  >
                    <div class="pt-1">
                      Exibir em antecipação
                      <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                    </div>
                  </label>
                  <div class="col-md-4 pr-4 ">
                      <base-switch
                        type="primary" offText="Não" onText="Sim" class="success"
                        v-model="payload.display_in_anticipation" />
                  </div>
                </div>

                <hr class="bg-ligth m-0 mb-1">
                <h2 class="text-primary ml-3 m-0 p-0">
                  Contas a receber
                </h2>

                <!-- Permite recebimento de fatura manual -->
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-8 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                  >
                    <div class="pt-1">
                      Permite recebimento de fatura manual
                      <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                    </div>
                  </label>
                  <div class="col-md-4 pr-4 ">
                      <base-switch
                        type="primary" offText="Não" onText="Sim" class="success"
                        v-model="payload.is_manual_invoice_receipt" />
                  </div>
                </div>
                <!-- Permite confirmação de recebíveis manual -->
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-8 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                  >
                    <div class="pt-1">
                      Permite confirmação de recebíveis manual
                      <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                    </div>
                  </label>
                  <div class="col-md-4 pr-4 ">
                      <base-switch
                        type="primary" offText="Não" onText="Sim" class="success"
                        v-model="payload.is_manual_receivable_confirmation" />
                  </div>
                </div>

                <hr class="bg-ligth m-0 mb-1">
                <h2 class="text-primary ml-3 m-0 p-0">
                  Contas a pagar
                </h2>

                <!-- Permite pagamento de fornecedor manual-->
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-8 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                  >
                    <div class="pt-1">
                      Permite pagamento de fornecedor manual
                      <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                    </div>
                  </label>
                  <div class="col-md-4 pr-4 ">
                      <base-switch
                        type="primary" offText="Não" onText="Sim" class="success"
                        v-model="payload.is_manual_supplier_payment" />
                  </div>
                </div>

                <hr class="bg-ligth m-0 mb-1">
                <h2 class="text-primary ml-3 m-0 p-0">
                  Faturamento
                </h2>

                <!-- Permite faturamento automático-->
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-8 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                  >
                    <div class="pt-1">
                      Permite faturamento automático
                      <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                    </div>
                  </label>
                  <div class="col-md-4 pr-4 ">
                      <base-switch
                        type="primary" offText="Não" onText="Sim" class="success"
                        v-model="payload.allow_auto_billing" />
                  </div>
                </div>

                <hr class="bg-ligth m-0 mb-1">
                <h2 class="text-primary ml-3 m-0 p-0">
                  Créditos e Antecipações
                </h2>

                <!-- Registros ficam visíveis na tela de créditos e antecipações -->
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-8 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                  >
                    <div class="pt-1">
                      Registros ficam visíveis na tela de créditos e antecipações
                      <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                    </div>
                  </label>
                  <div class="col-md-4 pr-4 ">
                      <base-switch
                        type="primary" offText="Não" onText="Sim" class="success"
                        v-model="payload.show_records_on_credit_anticipation_window" />
                  </div>
                </div>

              </div>
              <div class="modal-footer">
                <base-button type="secondary" @click="closeModal('create')">
                  Cancelar
                </base-button>
                <base-button
                  type="success"
                  native-type="submit"
                  v-bind:disabled="invalid"
                  :loading="loadingStore"
                >
                  Salvar
                </base-button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>

import {formatErrorValidation} from "@/plugins";
import PuzlSelect from "@/components/PuzlSelect";
import {mapGetters} from "vuex";

export default {
  name: "ModalCreatePaymentMethod",
  components: {
    PuzlSelect,
  },
  data() {
    return {
      modal: {
        title: "Método de Pagamento",
        create: false
      },
      payload: {
        status: true,
        name: null,
        puzl_customer: 0,
        allow_approve: 0,
        payment_type: 0,
        transaction_code: true,
        display_in_anticipation: false,
        payment_intermediary: [],
        external_code: '',
        contract_details: '',
        is_manual_invoice_receipt: true,
        is_manual_receivable_confirmation: true,
        is_manual_supplier_payment: true,
      },
      loadingStore: false,
      selected: 0,
    };
  },
  computed: {
    ...mapGetters({
      $_payment_intermediaries: "paymentIntermediary/fetch"
    })
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal() {
      this.payload = {
        status: true,
        name: null,
        puzl_customer: 0,
        payment_type: 0,
        transaction_code: true,
        display_in_anticipation: false,
        payment_intermediary: [],
        is_manual_invoice_receipt: true,
        is_manual_receivable_confirmation: true,
        is_manual_supplier_payment: true,
        allow_auto_billing: false,
        show_records_on_credit_anticipation_window: false,
      };
      this.modal.create = true;
      this.selected = 0;
      let loader = this.$loading.show();
      this.$store
        .dispatch("paymentIntermediary/fetchItems")
        .then(response => {
          loader.hide()
        })
        .catch(error => {
          loader.hide()
        });
    },
    store() {
      this.$Progress.start();
      this.loadingStore = true;
      this.$store
        .dispatch("paymentMethod/add", this.payload)
        .then(response => {
          this.loadingStore = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.closeModal();
        })
        .catch(error => {
          if (error.status === 200) {
            this.$notify({
              type: "danger",
              message: error.data.message
            });
            this.$Progress.finish();
            this.loadingStore = false;
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors
            });
            this.$Progress.finish();
            this.loadingStore = false;
          }
        });
    }
  },
  mounted() {
    this.$refs.formValidator.validate();
  }
};
</script>

<style scoped></style>
