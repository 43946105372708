<template>
  <div>
    <modal size="md" :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ title }}</h5>
      </template>
      <div class="container">
        <div class="form-group row m-0 p-0 mb-1 mt-3">
          <div class="col-md-12">
            <label>
              <b>Período</b>
            </label>
            <input-date-picker ref="input-date-picker" @handleFilterDate="handleFilterDate" :default-range="defaultRange" />
          </div>
        </div>

        <div class="form-group row m-0 p-0 mb-1 mt-3">
          <div class="col-md-12">
            <b> Conta </b>
            <puzl-select :items="$_bank_accounts" v-model="filter.bank_account_id" />
          </div>
        </div>
      </div>

      <div class="col-md-12 mt-2 text-right p-0 mt-4">
        <base-button @click.prevent="close" type="secondary" class="text-capitalize"> Cancelar </base-button>
        <base-button :disabled="!filter.bank_account_id" @click.prevent="store" type="primary">
          <span class="btn-label"><i class="fa-solid fa-cloud-download-alt"></i></span>
          Exportar
        </base-button>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InputDatePicker from "@/components/InputDatePicker";
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "ModalReport",
  components: {
    InputDatePicker,
    PuzlSelect,
  },
  data() {
    return {
      title: "Imprimir Extrato Bancário",
      modal: false,
      filter: {
        range: null,
        bank_account_id: null,
      },
      range: {
        start: null,
        end: null,
      },
      defaultRange: {
        start: new Date(),
        end: new Date(),
      },
    };
  },
  computed: {
    ...mapGetters({
      $_bank_accounts: "bankAccount/fetch",
    }),
  },
  methods: {
    openModal() {
      this.range = this.defaultRange;
      this.filter = {
        range: this.range,
        bank_account_id: null,
        advanced: true,
      };
      this.modal = true;
    },
    close() {
      this.modal = false;
    },

    /**
     * Retorna relatório pdf
     *
     * @returns void
     */
    store() {
      const loader = this.$loading.show();
      this.filter.bank_accounts = [this.filter.bank_account_id];
      this.filter;
      this.$store
        .dispatch("accountStatement/getReport", { filter: this.filter })
        .then(async (response) => {
          const blob = new Blob([response], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "extrato.pdf");
          await link.click();
          this.$snotify.success("Download iniciado com sucesso!", {
            timeout: 1000,
            icon: false,
            position: "centerBottom",
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
          });
          this.close();
        })
        .finally(() => {
          loader.hide();
        });
    },

    handleFilterDate(filter) {
      this.filter.range = filter;
    },
  },
};
</script>
