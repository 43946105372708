<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-md-4">
          <h2 style="font-size: 1.2rem !important" class="text-danger">
            <img height="30px" src="/img/icons/icons8/ios/circled-left-2_danger.png" class="mr-2 mb-1" />
            Itens
          </h2>
        </div>
        <div class="col-md-8 pr-4">
          <div class="row d-flex justify-content-end">
            <div class="mb-md-0 mb-2 col-md-3 px-1">
              <base-button type="success" block @click.prevent="handleCreateProductService">
                <img height="19" src="/img/icons/plus-math--v1-white.png" class="mr-1 mt-n1" />
                NOVO
              </base-button>
            </div>
            <base-dropdown class="mb-md-0 mb-2 col-md-3 px-1">
              <base-button block slot="title-container" type="warning" class="dropdown-toggle text-uppercase" style="font-size: 0.8rem">
                <img height="19" src="/img/icons/product-documents.png" class="mr-1 mt-n1" />
                Cadastros
              </base-button>
              <div>
                <router-link :to="{ path: '/configuration/product-service/category' }">
                  <a class="dropdown-item new-default-black-font">
                    <img height="19" src="/img/icons/icons8/ios/categorize_primary.png" class="mr-1 mt-n1" /> 
                    Categorias
                  </a>
                </router-link>
              </div>
            </base-dropdown>
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid">
      <i 
        class="pointer fa-regular fa-hard-drive fa-2x"
        :class="!listCard && 'opacity-25'"
        @click="listCard = true, listTable = false"
      >
      </i>
      <i 
        class="pointer fa-solid fa-table-list fa-2x ml-1 mr-2"
        :class="!listTable && 'opacity-25'"
        @click="listTable = true, listCard = false"
      >
      </i>
      <div class="mt-n2">
        <MultiFilter ref="multi-filter"
          @clearFilter="clearFilter()"
          :showSlotBookmark="false"
          :showSlotData="false"
          :type="3"
          @fetch="init">
        </MultiFilter>
      </div>
      <div v-if="listCard">
        <Cards
          @handleDeleteProductService="handleDeleteProductService"
          @handleEditProductService="handleEditProductService"
          @handleSearchEntity="handleSearchEntity"
          @handleListPrices="handleListPrices"
          :loadingSkeleton="loadingSkeleton"
        />
      </div>
      <div v-else>
        <Table
          @handleDeleteProductService="handleDeleteProductService"
          @handleEditProductService="handleEditProductService"
          @handleSearchEntity="handleSearchEntity"
          @handleListPrices="handleListPrices"
          :loadingSkeleton="loadingSkeleton"
        />
      </div>
      <LoadingPagination :show="loading && !loadingSkeleton" />
      <CreateProductService ref="modalCreate" />
      <ModalEditProductService @updated="checkPricingHasOpened" ref="ModalEditProductService"/>
      <ModalListPrices 
        ref="modalListPrices"
        @handleEditPrice="handleEditPrice"
        @handleHistoryPrice="handleHistoryPrice"
        @deleteProductServiceHubPrice="deleteProductServiceHubPrice"
      />
      <ModalCreatePrice @createdPrice="getPricesByProductServiceHub" ref="modalCreatePrice" />
      <ModalEditPrice @updatedPrice="getPricesByProductServiceHub" ref="modalEditPrice" />
      <ModalPriceHistory @deletedPrice="getPricesByProductServiceHub" ref="modalPriceHistory" />
      <ModalSearchEntity @selected="handleCreatePrice" ref="modalSearchEntity" />
      <PuzlEmptyData v-if="!$_productServices.length && !loadingSkeleton" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import Cards from "./Shared/_Cards";
import Table from "./Shared/_Table";
import CreateProductService from "./Shared/_ModalCreate";
import ModalListPrices from "./Shared/_ModalListPrices";
import BackButton from "@/components/Utils/BackButtonV2";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import ModalEditProductService from './Shared/_ModalEdit';
import ModalCreatePrice from "./Price/_ModalCreate.vue";
import ModalEditPrice from "./Price/_ModalEdit.vue";
import ModalPriceHistory from "./Price/_ModalHistory.vue";
import ModalSearchEntity from "../Entity/Shared/_ModalSearchEntity"
import MultiFilter from '@/components/Utils/MultiFilterV3.vue';
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";

export default {
  name: "IndexProductService",
  mixins: [cursorPaginate],
  components: {
    LoadingPagination,
    PuzlEmptyData,
    ModalEditProductService,
    ModalCreatePrice,
    ModalPriceHistory,
    MultiFilter,
    ModalSearchEntity,
    ModalEditPrice,
    ModalListPrices,
    PuzlBreadcrumb,
    Cards,
    CreateProductService,
    BackButton,
    Table,
  },
  data(){
    return {
      loadingPrice:false,
      filter: {},
      listCard: true,
      listTable: false,
    }
  },
  mounted() {
    this.$store.dispatch('productServiceCategory/getCategoriesByType', 0);
    this.filter.status = 1;
    this.init({});
  },
  computed: {
    ...mapGetters({
      $_productServices: 'productService/fetch',
    }),
  },
  methods: {
    handleEditPrice(price, product) {
      this.$refs.modalEditPrice.openModal(price, product);
    },
    handleHistoryPrice(product_service_hub_price) {
      this.$refs.modalPriceHistory.openModal({
        product_service_hub_id : product_service_hub_price.product_service_hub_id,
        entity_id: product_service_hub_price.entity_id
      });
    },
    deleteProductServiceHubPrice(product_service_hub_price) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          let loader = this.$loading.show();
          this.$store.dispatch('productServiceHubPrice/destroy', product_service_hub_price.id)
          .then(async (response) => {
            await this.getPricesByProductServiceHub(product_service_hub_price.product_service_hub_id)
            this.$notify({
              type: response.error_type,
              message: response.message
            });
          })
          .catch((error) => { 
            this.$notify({
                type: "danger",
                message: error.data.message,
            }); 
          })
          .finally(() => loader.hide())
        }
      });
    },
    handleCreateProductService() {
      this.$refs.modalCreate.handleCreateModal();
    },
    handleListPrices(item){
      this.$refs.modalListPrices.openModal(item);
    },
    init(filter = null) {
      this.startCursor(filter);
      this.$Progress.start();
      this.$store.dispatch('productService/fetch', {
        filter: this.filter,
        next_page: this.paginate.nextUrl
      })
      .then(response => {
        this.$Progress.finish()
        this.resolveCursor(response)
      })
      .catch((error) => {
        this.resolveCursor();
        this.$Progress.finish();
      })
    },
    handleDeleteProductService(id) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$notify({
            type: 'info',
            message: 'Estamos trabalhando em sua solicitação.'
          });
          this.$store.dispatch('productService/destroy', id).then((response) => {
            this.$notify({
              type: response.error_type,
              message: response.message
            });
            this.$Progress.finish();
            this.init();
          }).catch((error) => {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            });
            this.$Progress.finish();
          });
        }
      }).catch(() => this.$Progress.finish());
    },
    getPricesByProductServiceHub(product_service_hub_id) {
      this.loading = true;
      return this.$store.dispatch('productService/getPricesByProductServiceHub', product_service_hub_id)
        .then((response) => {
          this.loading = false;
          this.$refs.modalListPrices.verifyUpdate();
      });
    },
    handleEditProductService(id) {
      this.$refs.ModalEditProductService.handleEditModal(id);
    },
    handleSearchEntity(product) {
      this.$refs.modalSearchEntity.openModal(product);
    },
    handleCreatePrice(entity, product) {
      this.$refs.modalCreatePrice.openModal(entity, product);
    },
    checkPricingHasOpened(id) {
      this.$forceUpdate();
      let item = this.$_productServices.filter(item => item.hubable_id === id)[0];
      item.closed_price_collapse = true;
    },
  },
};
</script>

<style scoped>
.imgBaseButton {
  margin-bottom: 2px;
}
</style>
