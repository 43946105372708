<template>
  <div class="row">
    <div class="col-md-3">
      <base-button
        style="border-radius: 5px !important;"
        title="Agrupamento por equipamento"
        size="sm"
        class="w-outline font-weight-500 pt-2"
                   @click="setActive">
        <div class="row row-cols-12 ml-1 mr-1">

            <i class="ni ni-delivery-fast" style="font-size: 20px;"></i>
            EQUIPAMENTOS
            <i style="font-size: 12px" v-if="!active" class="far fa-square mt-1"></i>
            <i style="font-size: 12px" v-else class="far fa-check-square mt-1"></i>

        </div>
      </base-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectEquipment",
  data() {
    return {
      active: 0,
    }
  },
  methods: {
    setActive()
    {
      this.active = !this.active
      this.$emit('load', this.active)
    }
  }
}
</script>

<style scoped>
.w-outline {
  background-color: white !important;
  color: #1f3a68 !important;
}
</style>
