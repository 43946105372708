<template>
<div>
    <base-header class="bg-gray-content">
        <div class="row align-items-center py-4">
            <div class="col-lg-12 col-12">
                <PuzlBreadcrumb/>
            </div>
        </div>
        <div class="row mb-3">

        </div>
    </base-header>
    <ListCashierResume />
</div>
</template>

<script>
import {mapGetters} from "vuex";
import ListCashierResume from "./Shared/_List";
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
export default {
    name: "Index",
    components: {
        ListCashierResume,
        PuzlBreadcrumb,
    },
}
</script>

<style></style>
