<template>
  <div>
    <div class="container-fluid">
      <multi-filter @fetch="init" :filter="filter" :status="multi_filter"/>
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <PuzlEmptyData v-if="!contract_formulations.length && !loadingSkeleton && !loading"/>
      <!-- Start Card ContractProposal -->
      <div class="row card-wrapper" v-if="contract_formulations.length && !loadingSkeleton">
        <div
          class="col-md-6 col-lg-4 col-xlg-4"
          v-for="(contract_formulation, index) in contract_formulations" :key="index"
        >
          <!-- Basic with action button -->
          <card
            :class="contract_formulation.has_adjutment ? 'card-border-top-primary' : 'card-border-top-success'"
          >
            <div>
              <div>
                  <span class="h3 mb-1">
                    {{ contract_formulation.construction.construction_name }}
                  </span>
                <br/>
                <span class="display-5">
                    {{ contract_formulation.construction.customer_construction.customer_name }}
                </span>
              </div>
              <div class="mt-2">
                <span class="display-5">
                  {{ contract_formulation && contract_formulation.plant && contract_formulation.plant.name }}
                </span>
              </div>
              <div class="mt-1">
                <span class="display-5 mb-1">
                  {{ contract_formulation && contract_formulation.code }}
                </span>
              </div>
            </div>

            <!-- Formulações dentro do contrato -->
            <collapse class="border rounded p-0 mt-3">
              <collapse-item class="header-gray card-body-molde" back-ground="border-header-primary-bottom">
                <h5 slot="title" class="mb-0 ">Formulações</h5>
                <div class="d-flex justify-content-between align-items-center"
                     v-for="(formulation, indexFormulation) in contract_formulation.formulations"
                     :key="indexFormulation"
                >
                  <div class="row border-left border-3 border-primary p-2 mt-2 d-flex flex-column">
                    <div
                      class="row"
                      v-for="feature in formulation.features"
                      :key="feature.id"
                    >
                      <div class="col-md-12">
                        <router-link
                          :to="{
                          path:'/technology/adjustment/contract-hour/adjustment',
                          name:'technology.contract-hour.adjustment.index',
                          params: {formulation_uuid: formulation.uuid}
                      }"
                        >
                          <h5 v-if="formulation.mixe_status_adjustment">
                            <badge type="default">Com ajustes <i class="fa-solid fa-wrench"></i></badge>
                          </h5>
                          <h5 v-else>
                            <badge type="light" style="background: #b9b3b3;color: white">Sem ajustes <i
                              class="fa-solid fa-wrench"></i></badge>
                          </h5>
                        </router-link>
                        <h5 class="h4 mb-0">
                          {{ feature.feature.short_description }}
                          {{ format_mixe(feature.feature.period, feature.feature.period_type) }}
                          {{ feature.description }}
                          {{ feature.letter }}
                        </h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <p>
                          <span class="h5 small">
                            {{ formulation.type.technical_description }}
                            {{ formulation.subtype ? '|' + formulation.subtype.technical_description : '' }}
                            |
                            {{ formulation.slump_flow.nomenclature }}
                            |
                            {{ formulation.maximum_size.description }}
                            <br>
                          </span>
                        </p>
                        <h5 class="mt-n2">
                          {{ formulation.footer }}
                        </h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div
                          class="border-left border-3 border-default p-2"
                          v-if="formulation.concretedPiece.length"
                        >
                          <div
                            v-for="(piece, indexPiece) in formulation.concretedPiece"
                            :key="piece.id"
                          >
                            <h5>
                              {{ piece.description }}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Adção Puzl-->
                    <div class="mt-4 border-left border-3 border-indigo p-2" v-if="formulation.puzl_additions.length">
                      <div
                        v-for="(puzl_addtion, index) in formulation.puzl_additions"
                        :key="puzl_addtion.id"
                      >
                        <h5 class="text-indigo">
                          {{ puzl_addtion.name }}
                          ({{ puzl_addtion.volume }} <span style="text-transform: none">kg/m</span><sup>3</sup>)
                          <span class="text-indigo" v-if="index != formulation.puzl_additions.length -1">+</span>
                        </h5>
                      </div>
                    </div>

                  </div>
                  <div>
                    <el-popover trigger="click" placement="bottom"
                                class="p-0 float-right pr-0">
                      <div
                        v-for="(concreted_piece, indexConcretedPiece) in formulation.concretedPiece"
                        :key="indexConcretedPiece"
                      >
                        <div class="d-flex justify-content-between">
                          <p class="h5">
                            {{ concreted_piece.description }}
                          </p>
                        </div>
                      </div>
                      <img class="pointer" slot="reference" src="/img/icons/icons8/puzzle.png" style="height: 25px"/>
                    </el-popover>
                  </div>
                </div>
              </collapse-item>
            </collapse>
          </card>
        </div>
      </div>
      <!-- End Card ContractProposal -->
      <LoadingPagination :show="loading && !loadingSkeleton"/>
    </div>
  </div>
</template>

<script>
import {period_types} from "@/views/Modules/Technology/Mix/helpers/mixe";
import {mapGetters} from 'vuex'
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import MultiFilter from "@/components/Utils/MultiFilterV2";
import cursorPaginate from "@/mixins/cursorPaginate"
import LoadingPagination from "@/components/LoadingPagination";
import PuzlEmptyData from "@/components/PuzlEmptyData";

export default {
  name: "ListChargePointAdjustment",
  mixins: [cursorPaginate],
  components: {
    SkeletonPuzlGrid,
    MultiFilter,
    LoadingPagination,
    PuzlEmptyData
  },
  data() {
    return {
      format_mixe: period_types,
      loadingSkeleton: false,
      statusName: [
        'Inativo',
        'Ativo',
        'Pendente Revisão',
        'Pendente Aprovação',
        'Rascunho',
      ],
      types: [
        'danger',
        'success',
        'yellow',
        'warning',
        'primary',
      ],
      filter: {},
      multi_filter: {
        null: {
          name: "Todos",
          type: "dark",
        },
        1: {
          name: "Com ajustes",
          type: "primary",
        },
        2: {
          name: "Sem ajustes",
          type: "success",
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      'contract_formulations': 'adjustmentContractHour/fetchFormulations',
    }),
  },
  methods: {
    init(filter = null) {
      this.startCursor(filter);
      this.$store.dispatch('adjustmentContractHour/getFormulations', {
        type: this.type,
        filter: this.filter,
        next_page: this.paginate.nextUrl,
      })
        .then((response) => {
          this.resolveCursor(response);
        })
        .catch((error) => {
          this.resolveCursor();
        })
    },
  },
  mounted() {
    this.init({})
  },
}
</script>

<style scoped>

</style>
