<template>
  <div class="w-100">
    <div v-if="$_headers" class="row card-wrapper d-flex mt-0 justify-content-end mx-1">
      <!-- Programado -->
      <div class="col-md-4 px-md-2">
        <div class="card main-card mb-md-0">
          <div class="py-2 px-4">
            <!-- Ícone -->
            <div class="row">
              <div class="col-12">
                <img src="/img/icons/icons8/ios/no-connection--v1_primary.png" width="20px" />
              </div>
            </div>

            <!-- Título -->
            <div class="row mt-2">
              <h5 class="col-12 new-default-gray-font font-weight-normal mb-0 fs-11">Programado</h5>
            </div>

            <!-- Quantidade -->
            <div class="row">
              <div class="col-12 d-flex justify-content-between">
                <span class="new-default-black-font font-weight-500 fs-13"> {{ $_headers.programmed_volume }} m³ </span>
                <span class="new-default-black-font font-weight-500 fs-13"> {{ $_headers.programmed_travels }} viagens </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Realizado -->
      <div class="col-md-4 px-md-2">
        <div class="card main-card mb-md-0">
          <div class="py-2 px-4">
            <!-- Ícone -->
            <div class="row">
              <div class="col-6">
                <img src="/img/icons/icons8/ios/no-connection--v1_success.png" width="20px" />
              </div>
              <div class="col-6 d-flex justify-content-end">
                <h5 class="new-default-black-font font-weight-normal m-0  text-center">
                  <i
                    role="button"
                    v-if="showOnlyFinished"
                    @click="handleShowOnlyFinishedFilter(false)"
                    class="fas fa-check-square fa-2x text-success"
                  />
                  <i v-else @click="handleShowOnlyFinishedFilter(true)" role="button" class="fas fa-square fa-2x" />
                </h5>
              </div>
            </div>

            <!-- Título -->
            <div class="row mt-2">
              <h5 class="col-12 new-default-gray-font font-weight-normal mb-0 fs-11">Realizado</h5>
            </div>

            <!-- Quantidade -->
            <div class="row">
              <div class="col-12 d-flex justify-content-between">
                <span class="new-default-black-font font-weight-500 fs-13"> {{ $_headers.performed_volume }} m³ </span>
                <span class="new-default-black-font font-weight-500 fs-13"> {{ $_headers.performed_travels }} viagens </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Pendente -->
      <div class="col-md-4 px-md-2">
        <div class="card main-card mb-md-0">
          <div class="py-2 px-4">
            <!-- Ícone -->
            <div class="row">
              <div class="col-6">
                <img src="/img/icons/icons8/ios/hourglass_warning.png" width="20px" />
              </div>
              <div class="col-6 d-flex justify-content-end">
                <h5 class="new-default-black-font font-weight-normal m-0  text-center">
                  <i
                    role="button"
                    v-if="dontShowFinishedAndCanceled"
                    @click="handleDontShowFinishedAndCanceledFilter(false)"
                    class="fas fa-check-square fa-2x text-success"
                  />
                  <i v-else @click="handleDontShowFinishedAndCanceledFilter(true)" role="button" class="fas fa-square fa-2x" />
                </h5>
              </div>
            </div>

            <!-- Título -->
            <div class="row mt-2">
              <h5 class="col-12 new-default-gray-font font-weight-normal mb-0 fs-11">A realizar</h5>
            </div>

            <!-- Quantidade -->
            <div class="row">
              <div class="col-12 d-flex justify-content-between">
                <span class="new-default-black-font font-weight-500 fs-13"> {{ $_headers.pending_volume }} m³ </span>
                <span class="new-default-black-font font-weight-500 fs-13"> {{ $_headers.pending_travels }} viagens </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Headers",
  computed: {
    ...mapGetters({
      $_headers: "scheduleTravelGantt/getHeaders",
    }),
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    dontShowFinishedAndCanceled: {
      type: Boolean,
      default: false,
    },
    showOnlyFinished: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleDontShowFinishedAndCanceledFilter(value) {
      this.$emit('handleDontShowFinishedAndCanceledFilter', value)
    },
    handleShowOnlyFinishedFilter(value) {
      this.$emit('handleShowOnlyFinishedFilter', value)
    }
  },
};
</script>
