<template>
  <div>
    <modal :show.sync="modal" size="lg">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <div class="row">
          <div class="col-3 mb-2">
            <base-button
              block size="lg"
              type="success"
              @click="handleCreateAttachments(invoice.id)"
              :disabled="$_invoiceAttachment && $_invoiceAttachment.length >= 1"
            >
              <i class="fas fa-plus"></i> NOVO
            </base-button>
          </div>
        </div>

        <base-alert v-if="$_invoiceAttachment.length && !loadingSkeleton">
          <span class="alert-inner--text"><strong> Atenção!</strong> Clique no arquivo desejado para visualizar os detalhes.</span>
        </base-alert>

        <PuzlEmptyData v-if="!$_invoiceAttachment.length && !loadingSkeleton"></PuzlEmptyData>

        <div class="row" v-if="!loadingSkeleton">
          <div v-for="item in $_invoiceAttachment" class="col-md-4">
            <div class="card">
              <a href="#" class="text-center" @click.prevent="showModalImgView(item)">
                <img style="height: 270px!important; width:80%; margin:10px 25px;"
                  v-if="item.ext != 'pdf'" class="img" :src="item.url"
                />
                <pdf
                  :scale.sync="scale" style="height: 270px!important;width:80%;margin:10px 25px;"
                  :annotation="true"
                  v-if="item.ext == 'pdf'" :src="item.url" :page="1"
                />
              </a>
              <div class="card-footer text-truncate">
                <div :title="item.file_name" class="numeric">
                  {{item.file_name}}
                </div>
                <div class="text-muted" style="font-size: 11px;">
                  Importado {{ moment(item.created_at).tz('America/Sao_Paulo').locale("pt-br").fromNow() }}
                </div>
                <a href="#" @click.prevent="handleDelete(item.id)" class="px-1">
                  <i class="fa-solid fa-x text-danger mr-1"></i>
                </a>
                <a href="#" @click.prevent="download(item)" class="px-1">
                  <i style="font-size: 1.3rem" class="fa-solid fa-download text-primary"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <ModalAttachmentView :selected="selected" :show.sync="showImgView"/>
    <ModalInvoiceAttachment @storedAttachment="storedAttachment" ref="modalInvoiceAttachment" />
  </div>
</template>

  <script>
  import {mapGetters} from "vuex";
  import PuzlEmptyData from "@/components/PuzlEmptyData";
  import moment from "moment";
  import moment_tz from 'moment-timezone';
  import pdf from 'pdfvuer';
  import {base_url_ms} from '@/plugins';
  import ModalAttachmentView from "@/components/ModalAttachmentView";
  import ModalInvoiceAttachment from "./_ModalInvoiceAttachment";

  export default {
    name: "View",
    components: {
      PuzlEmptyData,
      pdf,
      ModalAttachmentView,
      ModalInvoiceAttachment,
    },
    data () {
      return {
        modal: false,
        title: 'Anexos',
        loadingSkeleton: false,
        moment: moment_tz,
        base_url_ms: base_url_ms(),
        scale: 'page-width',
        selected: null,
        showImgView: false,
        invoice: {
          id: null,
        }
      }
    },
    computed: {
      ...mapGetters({
        $_invoiceAttachment: "invoiceAttachment/show",
      }),
    },
    methods: {
      init() {
        this.$Progress.start();
        this.loadingSkeleton = true;
        let loader = this.$loading.show();
        this.$store
          .dispatch("invoiceAttachment/getByInvoiceId", this.invoice.id).then(() => {})
          .catch(error => {
            this.$notify({ type: error.data.error_type, message: error.data.message });
          }).finally(() => {
            this.$Progress.finish();
            this.loadingSkeleton = false;
            loader.hide();
            this.modal= true;
          });
      },
      closeModal () {
        this.modal= false;
      },
      /**
       * @param {number} id
       */
      handleCreateModal (id) {
        this.invoice = {
          id: id,
        }
        this.init();
      },
      /**
       * @param {number} id
       */
      handleCreateAttachments(id) {
        this.$refs.modalInvoiceAttachment.handleCreateModal(id);
      },
      /**
       * @param {number} invoiceId
       */
      storedAttachment(invoiceId) {
        this.$emit('storedAttachment', invoiceId);
        this.init();
      },
      async download(item) {
        let loader = this.$loading.show()

        const url = this.base_url_ms + 'download-s3?url=' + item.url;
        this.$store.dispatch('exports/download', url).then(async response => {
          let blob = new Blob([response], {type: "application/pdf"});
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", item.file_name);
          await link.click()
        });
        loader.hide()
      },
      showModalImgView(item) {
        this.selected = item;
        this.showImgView = true;
      },
      closeModalImgView() {
        this.showImgView = false;
      },
      handleDelete(id) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$notify({ type: 'info', message: 'Estamos trabalhando em sua solicitação.' });
          this.$store.dispatch('invoiceAttachment/destroy', id)
              .then((response) => {
                this.$notify({ type: 'success', message: response.message });
                this.$emit('deletedLastAttachment', this.invoice.id);
              })
              .catch(error => {
                this.$notify({ type: error.data.error_type, message: error.data.message });
              }).finally(() => {
                this.$Progress.finish();
              });
        }
        }).catch(() => this.$Progress.finish());
      },
    },
  }
  </script>

  <style scoped>

  </style>
