import { createAxios } from "@/plugins/axios";;
const endPoint = '/commercial/schedule-report'
export default {
  namespaced: true,
  actions: {
    download({}, params) {
      return createAxios().get(endPoint, {
        responseType: "blob",
        timeout: 60000,
        params: params
      })
      .then((response) => {
        const contentType = response.headers['content-type'];
        if (contentType && contentType.indexOf('application/json') !== -1) {
          return response.data.text().then(JSON.parse);
        }
        return response.data;
      })
      .catch(error => {
        if (error.response && error.response.data instanceof Blob) {
          return error.response.data.text().then(text => {
            try {
              return Promise.reject(JSON.parse(text));
            } catch (e) {
              return Promise.reject(text);
            }
          });
        }
        return Promise.reject(error);
      });
    },
    scheduleReportPdf(_, params) {
      const isAsync = 1;
      return createAxios().post(`commercial/new/schedule-report/${isAsync}/pdf`, params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
