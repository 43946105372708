<template>
<modal size="md" :show.sync="modal.create">
  <template slot="header">
    <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
  </template>
  <div class="" style="overflow: hidden;">
    <div class="pt-3 pr-4 pl-4 pb-4">
      <div class="row align-items-center mb-2">
        <div class="col-12">
          <!-- Title -->
          <div>
            <h5 class="h5 new-default-black-font">
              {{ concrete_price.plant.plant_name }}
            </h5>
          </div>

          <div class="mt-2">
            <h4 class="new-default-black-font font-weight-600" v-for="(feature, indexFeature) in concrete_price.mixe.features" :key="indexFeature">
              {{ feature.feature_name }} {{ feature.item }}
              {{ feature.unit_letter }}
            </h4>
          </div>

          <div class="row mt-n1">
            <div class="col-md-12">
                <small class="mb-1 font-weight-500 new-default-black-font">
                  {{ concrete_price.service.type }}
                </small>
                <small class="mb-1 font-weight-500 new-default-black-font" v-show="concrete_price.service.service_name">
                  | {{ concrete_price.service.service_name }}
                </small>
                <small class="small font-weight-500 new-default-black-font" v-show="concrete_price.service.service_type">
                  | {{ concrete_price.service.service_type }}
                </small>
            </div>
            <div class="col-12 mt-n1">
                <small class="mb-1 font-weight-500 new-default-black-font">
                  {{ concrete_price.mixe.slump }}
                </small>
                <small class="mb-1 font-weight-500 new-default-black-font">
                  | {{ concrete_price.mixe.product }}
                </small>
            </div>
          </div>
        </div>
      </div>
      <hr class="blue-divider">
      <!-- Card body -->
      <div class="row d-flex align-items-center">
        <div class="col-md-12 text-primary text-center">
          <div class="card mini-card">
            <div>
              <h5 class="new-default-black-font checklist-title mb-0">{{ concrete_price.price.minimum_price }}</h5>
              <small class="new-default-black-font">PREÇO MÍNIMO</small>
            </div>
            <div>
              <h5 class="checklist-title mb-0">{{ concrete_price.price_parameter['mcc_price'].price }}</h5>
              <small>MCC</small>
            </div>
            <div>
              <h5 class="checklist-title mb-0">
                {{ gross_margin }}
              </h5>
              <small>Margem Bruta</small>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center mt-n2 mb-2 parametters-list">
        <div class="card mr-3 ml-3" style="width: 100%; padding: 0;overflow: hidden;">
          <div style="font-size: 11px;" class="p-1 new-default-black mcc-title text-white text-center">
            PARÂMETROS
          </div>
          <div style="margin-right: 7px;margin-left: 7px;margin-top: 5px;" v-for="(price_parameter, index) in concrete_price.price_parameter" :key="index">
            <div v-if="index === 'mcc_price'" class="row d-flex align-items-center border-bottom pb-1 pt-1" :class="[price_parameter.puzl_customer ? 'mt-4' : '']">
              <div class="col-8 text-left">
                <h5 class="new-default-blue-font">
                  {{ price_parameter.name }}
                </h5>
              </div>
              <div class="col-4 text-right">
                <h5 class="new-default-blue-font">
                  {{ price_parameter.price }}
                </h5>
              </div>
            </div>
            <div v-if="index !== 'mcc_price'" class="row d-flex align-items-center border-bottom pb-1 pt-1" :class="[price_parameter.puzl_customer ? 'mt-4' : '']">
              <div class="col-8 text-left">
                <h5
                  :class="[
                    price_parameter.puzl_customer ? 'text-indigo' : '',
                    price_parameter.tax ? 'text-danger' : 'new-default-black-font',
                    price_parameter.mcc ? '' : 'small',
                  ]"
                >
                  {{ price_parameter.name }}
                </h5>
              </div>
              <div class="col-4 text-right">
                <h5
                  :class="[
                    price_parameter.puzl_customer ? 'text-indigo' : '',
                    price_parameter.tax ? 'text-danger' : 'new-default-black-font',
                    price_parameter.mcc ? '' : 'small',
                  ]"
                >
                  {{ price_parameter.price }}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Collapse History Price -->
      <collapse class="border rounded p-0 mt-3" v-show="false">
        <collapse-item name="1" class="header-gray" back-ground="border-header-danger-bottom">
          <h5 slot="title" class="mb-0">Histórico de Preço</h5>
        </collapse-item>
      </collapse>
    </div>
  </div>
</modal>
</template>
<script>
export default {
  name: "ModalConcreteParameters",
  components: {
  },
  data() {
    return {
      modal: {
        title: "PARÂMETROS",
        create: false,
      },
      concrete_price: {},
      gross_margin: 0,
    }
  },
  methods: {
    openModal(concretePrice, grossMargin) {
      this.modal.create = true;
      this.concrete_price = concretePrice;
      this.gross_margin = grossMargin;
    },
    closeModal() {
      this.modal.create = false;
    },
  },
};
</script>
<style scoped>
  .parametters-list {
    -webkit-transition: all 3s ease;
    -moz-transition: all 3s ease;
    -o-transition: all 3s ease;
    -ms-transition: all 3s ease;
    transition: all 3s ease;
  }
  .blue-divider {
    background-color: #7ec5ff;
    color: #7ec5ff;
    margin: 0;
    margin-bottom: 15px;
  }
  .card-blue-bottom {
    border-top-left-radius: 35px;
    border-bottom-right-radius: 10px;
    background-color: #1b6eba;
    color: white;
    max-width: 140px;
    min-width: 140px;
    float: right;
  }
  .mini-card {
    display: grid;
    grid-template-columns: 34% 33% 33%;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    padding: 0;
  }
  .mini-card div:nth-child(2) {
    background-color: #f3949452;
    color: #DB4539;
  }
  .mini-card div:last-child {
    background-color: #149e5752;
    color: #149E57;
  }

  .mini-card div:nth-child(2) small, .mini-card div:nth-child(2) h5 {
    color: #DB4539;
  }

  .mini-card div:last-child small, .mini-card div:last-child h5 {
    color: #149E57;
  }
  .mini-card div {
    display: inline-grid;
    padding: 3px;
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .mini-card div h5 {
    font-weight: 600 !important;
  }

  .mini-card div h5  {
    font-size: 14px !important;
  }

  .mini-card div small {
    font-size: 10px !important;
  }

  .mini-card div small {
    font-size: 10px !important;
    font-weight: 500 !important;
  }

  .main-card {
    border-radius: 10px;
    border: 1px solid #E8E8E8;
    box-shadow: none !important;
  }
  .card {
    border-radius: 10px !important;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3) !important;
  }
</style>
