<template>
  <div>
    <modal size="lg" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <div>
        <div class="container-fluid">
          <div class="row card-wrapper" v-show="loadingSkeleton">
            <SkeletonPuzlGrid v-for="index in 3" :key="index" />
          </div>
          <PuzlEmptyData v-if="!$_history.length && !loadingSkeleton" />
          <div v-if="$_history.length && !loadingSkeleton">
            <div
              v-for="(item, index) in $_history"
              :key="index"
              class="border-left border-3 d-flex justify-content-between p-2 mt-3 mb-4 border-primary"
            >
              <div>
                <h4 class="mb-0">{{ item.state }}</h4>
                <h4 class="mb-0 font-weight-normal">{{ item.user_name }}</h4>
                <h4 class="mb-0 font-weight-normal">{{ item.created_at | parseDate }}</h4>
              </div>
            </div>
            <pagination @navegate="navegate" :source="source" />
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <base-button type="secondary" @click="closeModal()"> Fechar </base-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Pagination from "@/components/Utils/Pagination.vue";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import { mapGetters } from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";

export default {
  name: "ModalGroupHistory",
  data() {
    return {
      modal: {
        title: "HISTÓRICO DE ALTERAÇÕES",
        create: false,
      },
      paginate: {
        page: null,
      },
      loadingSkeleton: true,
      source: null,
      mix_design_id: false,
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      $_history: "MixDesigns/groupHistory",
    }),
  },
  components: { Pagination, PuzlEmptyData, SkeletonPuzlGrid },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    async openModal(mix_design_id) {
      let loader = this.$loading.show();
      this.mix_design_id = mix_design_id;
      this.paginate.page = 1;
      await this.init();
      loader.hide();
      this.modal.create = true;
    },
    init(filter = null) {
      this.filter = filter ?? this.filter;
      this.loadingSkeleton = true;
      return this.$store
        .dispatch("MixDesigns/getGroupHistory", {
          page: this.paginate.page,
          mix_design_id: this.mix_design_id,
        })
        .then((response) => {
          this.source = response;
          this.loadingSkeleton = false;
        });
    },
    navegate(page) {
      this.paginate.page = page;
      this.init();
    },
  },
};
</script>

<style scoped></style>
