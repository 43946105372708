<template>
  <div>
    <modal size="lg" :show.sync="modal">
      <div ref="content" style="width: 950px">
        <div class="row">
          <div class="col-6">
            <img width="100px" src="https://marcas-logos.net/wp-content/uploads/2020/01/Amazon-Logo-1.png"/>
          </div>
          <div class="col-6">1</div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {
  period_types
} from "@/views/Modules/Technology/Mix/helpers/mixe";

export default {
  components: {},
  name: "ModalContractPrint",
  data() {
    return {
      modal: false,
    }
  },
  computed: {
    ...mapGetters({})
  },
  methods: {
    closeModal() {
      this.modal = false
    },
    async openModal() {
      this.modal = true
    },
    save() {
    },
  },
  mounted() {
  }
};
</script>

<style>
.border-3 {
  border-width: 3px !important;
}
</style>
