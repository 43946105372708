import store from "../../../../shared/libraries/store";
import { sapConfigurationStoreStateKeys } from "./sapConfigurationStoreStateKeys";
import { SapConfigurationSyncType} from "../types";

/**
 * @param {SapConfigurationSyncType} item
 * @returns {SapConfigurationSyncType}
 */
const setItem = (item) => {
  item.is_active_control = item.is_active === 'y';
  store.commit(sapConfigurationStoreStateKeys.ITEM, item)
};

const resetStates = () => {
  store.resetStates(Object.values(sapConfigurationStoreStateKeys));
}

export default {
  setItem
};
