import { adjustmentTypeUrls } from ".";
import httpClient from "../../../../shared/libraries/httpClient";
import { handleUnexpectedStatusCode } from "../../../../shared/services";
import { HttpStatusEnum } from "../../../../shared/enums";
import { AdjustmentTypeUpdateType, AdjusmentTypeViewType } from "../types";
import { adjustmentTypeFormatEmptyAgentService } from "./";

/**
 * Atualizar por ID
 *
 * @param {AdjustmentTypeUpdateType} data
 * @param {number} id
 * @returns {Promise<AdjusmentTypeViewType>}
 */
const execute = async (data, id) => {
  const url = `${adjustmentTypeUrls.BASE}/${id}`;
  const res = await httpClient.put(url, data);

  switch (res.status) {
    case HttpStatusEnum.HTTP_OK:
      if(res.data.data.agent === null) {
        res.data.data.agent = adjustmentTypeFormatEmptyAgentService.execute();
      }
      return res.data.data;
    default:
      handleUnexpectedStatusCode(res);
  }
};

export default { execute };
