<template>
  <div v-if="item">
    <modal size="md" :show.sync="modal.show">
      <template slot="header">
        <h5 class="modal-title d-flex align-items-center">
          <img class="mr-3 mt-n1" role="button" height="30px" src="/img/icons/icons8/ios/in-transit.png" />
          {{ modal.title }}
        </h5>
      </template>
      <div class="row mt-2 mb-2 p-2">
        <div class="col-12">
          <h4 class="new-default-black-font fs-16 font-weight-600">
            <img class="mt-n1 mr-1" src="/img/icons/info-squared.png" width="25px" height="25px" /> Identificação
          </h4>
          <h4 class="new-default-black-font mb-1">
            <span class="font-weight-600"> Fornecedor: </span>
            <span class="font-weight-normal">{{ item.freight_entity_name }}</span>
          </h4>
          <h4 class="new-default-black-font">
            <span class="font-weight-600"> CPF/CNPJ: </span>
            <span class="font-weight-normal">{{ item.freight_document }}</span>
          </h4>
          <div class="mt-3 mb-3" style="border-top: 1px solid #1a70b7" />
        </div>
        <div class="col-12">
          <h4 class="new-default-black-font fs-16 font-weight-600">
            <img class="mt-n1 mr-1" src="/img/icons/icons8/ios/receipt-dollar_primary.png" width="25px" height="25px" /> Documento
          </h4>
          <h4 class="new-default-black-font mb-1">
            <span class="font-weight-600"> Número: </span>
            <span class="font-weight-normal"> {{ item.referenced_entry_launch.slice(0, -13) }}</span>
          </h4>
          <h4 class="new-default-black-font">
            <span class="font-weight-600"> Emissão: </span>
            <span class="font-weight-normal">{{ item.referenced_entry_launch.slice(-11) }}</span>
          </h4>
          <h4 class="new-default-black-font">
            <span class="font-weight-600"> Modalidade: </span>
            <span class="font-weight-normal">{{ `${item.freight_modality_code} - ${item.freight_modality_name}` }}</span>
          </h4>
          <h4 class="new-default-black-font">
            <span class="font-weight-600"> Valor do frete: </span>
            <span class="font-weight-normal">{{ item.freight_total_value | currency }}</span>
          </h4>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: "ModalFreight",
  data() {
    return {
      modal: {
        title: "Frete",
        show: false,
      },
      item: null,
    };
  },
  methods: {
    openModal(item) {
      this.item = this.$helper.cloneObject(item);
      this.modal.show = true;
    },
  },
};
</script>
