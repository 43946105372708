import {
  OperationSourceCreateType,
  OperationSourceListFilterType,
  OperationSourceListPagerType,
  OperationSourceUpdateType,
  OperationSourceViewType,
} from "../types";
import {
  operationSourceCreateService,
  operationSourceDeleteService,
  operationSourceFindService,
  operationSourceListService,
  operationSourceUpdateService,
  operationSourceUpdateStatusService
} from "../services";
import mutations from "./operationSourceStoreMutations";
import getters from "./operationSourceStoreGetters";
import { listResources, createResource, findResource, updateResource, removeResource } from "../../../../shared/store";

/**
 * Incluir
 *
 * @param {OperationSourceCreateType} data
 * @returns {Promise<OperationSourceViewType>}
 */
const create = async (data) => await createResource(data, mutations, operationSourceCreateService);

/**
 * Localizar por ID
 *
 * @param {number|string} id
 * @returns {Promise<?OperationSourceViewType>}
 */
const find = async (id) => await findResource(id, mutations, operationSourceFindService);

/**
 * Listar
 *
 * @param {OperationSourceListFilterType} filter - Filtro de listagem
 * @param {boolean} isAccumulateItems - Incluir mais itens na lista existente
 * @returns {Promise<?OperationSourceListPagerType>} - Retorno paginado
 */
const list = async (filter, isAccumulateItems = false) => {
  return await listResources(filter, isAccumulateItems, mutations, getters, operationSourceListService);
};

/**
 * Atualizar por ID
 *
 * @param {OperationSourceUpdateType} data
 * @param {number} id
 * @returns {Promise<OperationSourceViewType>}
 */
const update = async (data, id) => await updateResource(data, id, mutations, operationSourceUpdateService);

/**
 * Atualizar por ID
 *
 * @param {boolean} status
 * @param {number} id
 * @returns {Promise<OperationSourceViewType>}
 */
const updateStatus = async (status, id) => await updateResource(status, id, mutations, operationSourceUpdateStatusService);

/**
 * Deletar por ID
 *
 * @param {number} id
 * @returns {Promise<void>}
 */
const remove = async (id) => await removeResource(id, mutations, operationSourceDeleteService);

export default {
  list,
  create,
  find,
  update,
  remove,
  updateStatus
};
