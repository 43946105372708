import * as types from './mutation_types'
import { createAxios } from "@/plugins/axios";
const endPoint = '/financial/bank-billet-discharge'
export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters:{
    fetch: state => state.items,
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
  },
  actions: {
    addFile ({ commit, state }, payload) {
      let formData = new FormData();
      let index = 0;
      payload.files.forEach(el => {
        formData.append(`files[${index++}]`, el);
      });
      formData.append('bank_account_id', payload.bank_account_id);
      return createAxios().post(`${endPoint}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    fetchItemsPaginate({commit, state}, params) {
      return createAxios().get(endPoint, {params: params})
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
