<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-4">
          <h2 style="font-size: 1.2rem !important" class="text-warning mb-0 d-flex align-items-center mb-2 mb-md-0">
            <img height="30px" src="/img/icons/exchange.png" class="mr-3" />
            Conciliação de cartões
          </h2>
        </div>
        <div class="col-md-8">
          <div class="row d-flex justify-content-end">
            <div class="mb-2 mb-md-0 col-md-3">
              <base-button @click.prevent="handleModalCreate" type="success" block>
                <i class="fas fa-plus" />
                Novo
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </base-header>
    <List ref="list" />
    <ModalCreate @created="$refs.list.init({})" ref="modalCreate" />
  </div>
</template>
<script>
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import List from "./Shared/_List.vue";
import ModalCreate from "./Shared/_ModalCreate.vue";

export default {
  components: {
    PuzlBreadcrumb,
    List,
    ModalCreate
  },
  name: "Index",
  methods : {
    handleModalCreate() {
      this.$refs.modalCreate.openModal();
    },
  }
};
</script>
