<template>
  <modal size="lg" :show.sync="modal">
    <template slot="header">
      <h5 class="modal-title p-0 m-0">{{ title }}</h5>
    </template>
    <div class="row">
      <div class="col-md-12">
        <quill-editor
          ref="myQuillEditor"
          v-model="text"
          :options="editorOption"
          class=""
        />
      </div>
    </div>
    <div class="modal-footer pr-2">
      <base-button type="secondary" @click="closeModal()">
        Cancelar
      </base-button>
      <base-button :disabled="!text" @click.prevent="save()" type="success" native-type="submit" :loading="loading">
        Salvar
      </base-button>
    </div>
  </modal>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor'

export default {
  name: "ModalProposalTerms",
  components: {
    quillEditor
  },
  data() {
    return {
      modal: false,
      loading: false,
      title: 'Termos de proposta',
      text: null,
      editorOption: {
        placeholder: 'Insira os termos aqui...',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{'header': 1}, {'header': 2}],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{'indent': '-1'}, {'indent': '+1'}],
            [{'direction': 'rtl'}],
            [{'size': ['small', false, 'large', 'huge']}],
            [{'align': []}],
          ],
          syntax: {
            highlight: text => hljs.highlightAuto(text).value
          },
        },
        theme: 'snow'
      },
    }
  },
  methods: {
    handleCreateModal(a) {
      let loader = this.$loading.show()
      /**
       * busca os termos já registrados, se existir
       *
       */
      this.$store.dispatch('proposalTerms/show')
        /**
         * @response null|string
         */
        .then((response) => {
          this.text = response.data && response.data.html
          this.modal = true
        }).finally(() => loader.hide())
    },
    closeModal() {
      this.modal = false
    },
    save() {
      this.loading = true
      let params = {
        html: this.text
      }
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'})
      this.$store.dispatch('proposalTerms/add', params).then((response) => {
        this.closeModal()
        this.$notify({type: response.error_type, message: response.message})
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
