import * as types from './mutation_types'
import { createAxios } from "@/plugins/axios";
import { destroy } from '@/store/baseStore'
const endPoint = '/financial/invoice-attachment'

export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters:{
    show: state => state.items,
  },
  mutations: {
    [types.SHOW] (state, payload){
      state.items = payload
    },
    [types.DESTROY] (state, id){
      destroy(state,id)
    },
  },
  actions: {
    getByInvoiceId({commit}, invoiceId) {
      return createAxios().get(`${endPoint}/${invoiceId}`)
        .then(({data}) => {
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    add({}, payload) {
      let formData = new FormData();
      payload.forEach(el => {
        formData.append(`files[${el.index}][file]`, el.file);
        formData.append(`files[${el.index}][invoice_id]`, el.invoice_id);
      });
      return createAxios().post(`${endPoint}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    destroy({commit}, id) {
      return createAxios().delete(`${endPoint}/${id}`)
        .then(({data}) =>{
          commit(types.DESTROY, id)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
