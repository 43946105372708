import {mapGetters} from "vuex";
import {checkNumberValue} from "@/helpers";
import {formatErrorValidation, mask1} from "@/plugins";
const list = {
  data() {
    return {
      loadingSkeleton: true,
      filter: {
        status: null,
      },
      multi_filter: {
        null: {
          name: "Todos",
          type: "primary",
        },
        1: {
          name: "Com ajustes",
          type: "primary",
        },
        2: {
          name: "Sem ajustes",
          type: "success",
        },
      },
      limit: {
        binder: {
          top: 100,
          bottom: -50,
        },
        adjustment_type: null,
        uuid: null,
        water: {
          top: 100,
          bottom: -50,
        },
        content_mortar: {
          top: 100,
          bottom: -50,
        },
        total_air: {
          top: 100,
          bottom: -50,
        },
        agent: {
          top: 100,
          bottom: -50,
        },
      },
      agentGroups: [],
    }
  },
  watch: {
    added() {
      this.handleInit();
      this.getAdjustmentLimit();
    },
  },
  props: {
    added: Boolean,
  },
  methods: {
    initCmc(filter = null) {
      this.startCursor(filter);
      this.$store
        .dispatch("adjustmentCmc/fetchItems", {
          type: this.type,
          filter: this.filter,
          next_page: this.paginate.nextUrl,
        })
        .then((response) => {
          this.formatList();
          this.resolveCursor(response);
          this.$store.dispatch("cmcGroup/fetchGroupByCategory", 4).then((response) => {
            this.agentGroups = response.data;
          });
        })
        .catch((error) => {
          this.formatList();
          this.resolveCursor();
        });
    },
    handleInit(filter = null) {
      this.paginate.nextUrl = null;
      this.loadingSkeleton = true;
      this.initCmc(filter);
    },
    getContentCardClass(content) {
      if (content) {
        if (content > 0) {
          return "text-uppercase text-success";
        } else if (content < 0) {
          return "text-uppercase text-danger";
        }
      }
      return "text-uppercase text-light";
    },
    updatedAgent(adjustment = "", forceUpdate = false, type = 0) {
      if (adjustment && type === 0) {
        this.changeColor(adjustment, 1, 1);
      } else if (adjustment && type === 1) {
        this.checkContentLimits(adjustment);
        this.changeColorContent(adjustment, 1, 1);
      }
      if (forceUpdate) {
        if (this.cmcAdjustments[0].binder || this.cmcAdjustments[0].binder === 0) {
          this.cmcAdjustments[0].binder += "a";
          this.cmcAdjustments[0].binder = this.cmcAdjustments[0].binder.slice(0, -1);
        } else {
          this.cmcAdjustments[0].binder = "a";
          this.cmcAdjustments[0].binder = this.cmcAdjustments[0].binder.slice(0, -1);
        }
      }
    },
    formatString(value, min, max, decimals = "", adjustment, type = 0) {
      if (type === 0) {
        this.changeColor(adjustment, 1, 1);
      } else {
        this.changeColorContent(adjustment, 1, 1);
      }
      this.updatedAgent("", true);
      if (decimals) {
        return checkNumberValue(mask1(value.toString(), decimals, ".", true), min, max);
      }
      return checkNumberValue(value, min, max);
    },
    forceUpdate(adjustment, type = 0) {
      this.$forceUpdate();
      if (type === 0) {
        this.changeColor(adjustment, 1, 1);
      } else {
        this.changeColorContent(adjustment, 1, 1);
      }
    },
    changeColor(adjustment, color, controls = 0) {
      adjustment.color = color;
      adjustment.controls = controls;
    },
    checkContentLimits(adjustment) {
      /* Caso os ajustes de teor não estejam definidos, oferecemos um valor padrão. */
      adjustment.content_min = !adjustment.content_min && adjustment.content_min !== 0 ? 0 : adjustment.content_min
      adjustment.content_max = !adjustment.content_max && adjustment.content_max !== 0 ? 100 : adjustment.content_max
    },
    changeColorContent(adjustment, color, controls = 0) {
      adjustment.color = color;
      adjustment.content_controls = controls;
    },
    formatList() {
      this.cmcAdjustments.map(function (obj, key) {
        const forbidden_options = [
          "id",
          "uuid",
          "observation",
          "updated_at",
          "user",
          "description",
          "observation_content",
          "content",
          "content_min",
          "content_max",
          "company_plant",
          "cmc_category",
          "cmc_group",
          "charge_point",
          "dosage_locations",
          "cmc",
          "measured_unit",
        ];
        const decimal_options = ["total_air", "content_mortar"];
        // Tratamento dos aditivos.
        // Caso não esteja setado, definimos a estrutura para não termos problemas na listagem.
        if (!obj.agent) {
          obj.agent = [];
          obj.agent[0] = {
            id: null,
            value: null,
          };
          obj.agent[1] = {
            id: null,
            value: null,
          };
        }
        // Tratamento de cores dos cards.
        // Caso algum dos valores esteja definido e seja diferente de 0 nós setamos a cor para azul

        if (!obj.dosage_locations || !obj.dosage_locations.length) {
          obj.color = 3;
        } else {
          for (var property in obj) {
            if (!Array.isArray(obj[property]) && obj[property] && decimal_options.includes(property)) {
              obj[property] = parseFloat(obj[property]);
            }
            if (!forbidden_options.includes(property)) {
              if (
                (obj[property] && !Array.isArray(obj[property]) && obj[property] !== 0) ||
                (obj[property] && obj[property][0] && obj[property][0].value && obj[property][0].value != 0) ||
                (obj[property] && obj[property][1] && obj[property][1].value && obj[property][1].value != 0)
              ) {
                obj.color = 2;
              }
            }
          }
        }
        // caso não tenha nenhuma configuração salva passamos a cor cinza
        if (!obj.color) {
          obj.color = 0;
        }
        // Tratamento dos controles, sempre virão definidos para não aparecerem
        obj.controls = 0;
        obj.content_controls = 0;
      });
    },
    handleDeleteCmcAdjustment(uuid) {
      this.$Swal
        .confirmDelete()
        .then((result) => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação.",
            });
            this.$store.dispatch("adjustmentCmc/destroy", uuid)
              .then(() => this.$Progress.finish())
              .catch(error => {
                this.$notify({
                  type: error.data.error_type,
                  message: error.data.message
                });
              });
          }
        })
        .catch(() => this.$Progress.finish());
    },
    handleEditCmcAdjustment(uuid) {
      this.$refs.editCmc.handleEditModal(uuid);
    },
    handleCmcAdjustmentUpdate(adjustment, index) {
      this.$Progress.start();
      this.loadingStore = true
      const copy = {...adjustment}
      delete copy.dosage_locations;
      copy.cmc_category_uuid = adjustment.cmc_category.uuid;
      copy.charge_point_uuid = adjustment.charge_point.uuid;
      this.$store
        .dispatch("adjustmentCmc/update", copy)
        .then((response) => {
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.$Progress.finish();
          this.handleInit();
        })
        .catch((error) => {
          if (error.status === 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
            this.$Progress.finish();
            this.loadingStore = false;
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
            this.$Progress.finish();
            this.loadingStore = false;
          }
        });
    },
    getAdjustmentLimit() {
      this.$store
        .dispatch("adjustmentLimitSetting/getByType", 6)
        .then((response) => {
          this.limit = {
            binder: {
              top: response.data.binder.top,
              bottom: response.data.binder.bottom,
            },
            adjustment_type: response.data.adjustment_type,
            uuid: response.data.uuid,
            water: {
              top: response.data.water.top,
              bottom: response.data.water.bottom,
            },
            content_mortar: {
              top: response.data.content_mortar.top,
              bottom: response.data.content_mortar.bottom,
            },
            total_air: {
              top: response.data.total_air.top,
              bottom: response.data.total_air.bottom,
            },
            agent: {
              top: response.data.agent.top,
              bottom: response.data.agent.bottom,
            },
          };
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
        });
    },
    formatDecimals(value, min, max, decimals = "", adjustment) {
      this.changeColor(adjustment, 1, 1);
      this.updatedAgent("", true);
      max = parseFloat(max).toFixed(decimals);
      min = parseFloat(min).toFixed(decimals);
      return checkNumberValue(mask1(value.toString(), decimals, ".", true), min, max);
    },
    resetAgent(adjustment, type) {
      adjustment.agent[type].value = null;
      this.$forceUpdate();
      this.changeColorContent(adjustment, 1, 1);
    },
    restoreData(adjustment) {
      this.$Progress.start();
      this.$store.dispatch("adjustmentCmc/show", adjustment.uuid).then((response) => {
        adjustment.binder = response.data.binder;
        adjustment.water = response.data.water;
        adjustment.content_mortar = response.data.content_mortar;
        adjustment.total_air = response.data.total_air;
        if (response.data.agent) {
          adjustment.agent = response.data.agent;
        } else {
          adjustment.agent[0].value = null;
          adjustment.agent[1].value = null;
          adjustment.agent[0].id = null;
          adjustment.agent[1].id = null;
        }
        if (
          (response.data.binder && response.data.binder != 0) ||
          (response.data.water && response.data.water != 0) ||
          (response.data.content_mortar && response.data.content_mortar != 0) ||
          (response.data.total_air && response.data.total_air != 0) ||
          (response.data.agent &&
            ((response.data.agent[0].value && response.data.agent[0].value != 0) ||
              (response.data.agent[1].value && response.data.agent[1].value)))
        ) {
          adjustment.color = 2;
        } else {
          adjustment.color = 0;
        }
        adjustment.controls = 0;
        this.$forceUpdate();
        this.$Progress.finish();
      });
    },
    restoreContentData(adjustment) {
      this.$Progress.start();
      this.$store.dispatch("adjustmentCmc/show", adjustment.uuid).then((response) => {
        adjustment.content = response.data.content;
        adjustment.content_min = response.data.content_min;
        adjustment.content_max = response.data.content_max;
        if (
          (response.data.binder && response.data.binder != 0) ||
          (response.data.water && response.data.water != 0) ||
          (response.data.content_mortar && response.data.content_mortar != 0) ||
          (response.data.total_air && response.data.total_air != 0) ||
          (response.data.agent &&
            ((response.data.agent[0].value && response.data.agent[0].value != 0) ||
              (response.data.agent[1].value && response.data.agent[1].value)))
        ) {
          adjustment.color = 2;
        } else {
          adjustment.color = 0;
        }
        adjustment.controls = 0;
        this.$forceUpdate();
        this.$Progress.finish();
      });
    },
    resetContent(adjustment) {
      adjustment.content_controls = 1;
      adjustment.color = 0;
      adjustment.content = null;
      adjustment.content_min = null;
      adjustment.content_max = null;
    },
  },
  created() {
    this.initCmc();
    this.getAdjustmentLimit();
    this.$store.dispatch("cmcGroup/fetchGroupByCategory", 4).then((response) => {
      this.agentGroups = response.data;
    });
  },
}
export default list
