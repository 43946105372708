export const SET = 'SET'
export const SHOW = 'SHOW'
export const PUSH = 'PUSH'
export const SET_ENTRY_RESUME = 'SET_ENTRY_RESUME'
export const SET_LATEST_STOCK = 'SET_LATEST_STOCK'
export const SET_WIDGETS = 'SET_WIDGETS'
export const SET_BY_CODE = 'SET_BY_CODE'
export const UPDATE = 'UPDATE'
export const DESTROY = 'DESTROY'
export const SET_BY_CONTRACT_PROPOSAL = 'SET_BY_CONTRACT_PROPOSAL'
export const SET_PRODUCT_SERVICE_HUB_ENTRIES = 'SET_PRODUCT_SERVICE_HUB_ENTRIES'
export const SET_PRODUCT_SERVICE_HUB_PUZL_ADJUST = 'SET_PRODUCT_SERVICE_HUB_PUZL_ADJUST'
export const SET_SERVICES = 'SET_SERVICES'
export const SET_ADJUSTMENTS = 'SET_ADJUSTMENTS'
export const SET_MCC_ADJUSTMENTS = 'SET_MCC_ADJUSTMENTS'
