<template>
  <div>
    <div class="container-fluid">
      <PuzlEmptyData v-if="!entities.length && !loadingSkeleton" />

      <!-- TABELA -->
      <div v-if="listType === 'table'">
        <div class="row">
          <ShadedTable :length="entities.length" :headers="headers" :loading="loadingSkeleton">
            <template v-for="(entity, indexEntity) in entities" :slot="indexEntity">
              <ShadedCol style="width: 15%;height: fit-content;">
                <span v-if="entity.status" class="badge-table badge-table-success d-flex" style="gap: 5px;">
                  <img src="/img/icons/ok--v1.png" width="15px" height="15px">
                  <b>Ativo</b>
                </span>
                <span v-else class="badge-table badge-table-danger d-flex" style="gap: 5px;">
                  <img src="/img/icons/icons8/ios/lock_danger.png" width="15px" height="15px">
                  <b>Bloqueado</b>
                </span>
              </ShadedCol>
              <ShadedCol style="width: 70%;">
                <div class="d-flex">
                  <span class="text-left" style="font-size: 13px;font-weight: 400;color: #2B2D32;">
                    {{ entity.entity_name }}
                  </span>
                  <div class="ml-auto d-flex" style="gap: 6px;">
                    <a href="#" title="Saldos do cliente" @click.prevent="handleModalEntityHistory(entity)">
                      <img style="width: 25px" src="/img/icons/wallet.png" />
                    </a>
                    <a href="#"
                      @click.prevent="handleModalEntityRegistry(entity.id, entity.uuid, entity.entity_name, entity.document)">
                      <div v-if="entity.total_entity_registries > 0">
                        <img style="width: 25px" src="/img/icons/signing-a-document-blue.png"
                          :title="`Total de registros do cliente: ${entity.total_entity_registries}`" />
                      </div>
                      <div v-else>
                        <img style="width: 25px" src="/img/icons/signing-a-document.png"
                          :title="`Total de registros do cliente: ${entity.total_entity_registries}`" />
                      </div>
                    </a>
                    <a href="#" @click.prevent="handleShowAttachments(entity.id, entity.entity_name, entity.document)">
                        <div v-if="entity.total_entity_attachments > 0">
                          <img style="width: 22px" src="/img/icons/paperclip-green.png"
                            :title="`Total de anexos: ${entity.total_entity_attachments}`" />
                        </div>
                        <div v-else>
                          <img style="width: 22px" src="/img/icons/paperclip.png"
                            :title="`Total de anexos: ${entity.total_entity_attachments}`" />
                        </div>
                      </a>
                  </div>
                </div>
              </ShadedCol>
              <ShadedCol style="width: 10%;">
                <span class="text-left" style="font-size: 13px;font-weight: 400;color: #2B2D32;">
                  {{ entity.document }}
                </span>
              </ShadedCol>
              <ShadedCol style="width: 5%;">
                <div class="text-center">
                  <base-dropdown menuOnRight>
                    <div slot="title-container" class="dropdown-toggle rounded m-0">
                      <img width="25px" src="/img/icons/icons8/ios/settings--v1_primary.png">
                    </div>
                    <span class="dropdown-item" @click="handleEditEntity(entity.uuid)">
                      <img src="/img/icons/create-new.png" width="19px" height="19px">
                      EDITAR
                    </span>
                    <div class="dropdown-divider p-0 m-0"></div>
                    <span class="dropdown-item" @click="handleModalSettings(entity.id)">
                      <img src="/img/icons/icons8/ios/settings-3--v1_gray.png" width="19px" height="19px">
                      CONFIGURAÇÕES
                    </span>
                    <div class="dropdown-divider p-0 m-0"></div>
                    <span class="dropdown-item" @click="handleEntityAccount(entity.id)">
                      <img src="/img/icons/bank-building-red.png" width="19px" height="19px">
                      BANCO
                    </span>
                    <div class="dropdown-divider p-0 m-0"></div>
                    <span class="dropdown-item" @click="handleRetentionModal(entity)">
                      <img src="/img/icons/ledger.png" width="19px" height="19px">
                      RETENÇÕES
                    </span>
                    <div class="dropdown-divider p-0 m-0"></div>
                    <span class="dropdown-item" @click="handleDeleteEntity(entity)">
                      <img src="/img/icons/icons8/ios/delete--v1.png" width="19px" height="19px">
                      EXCLUIR
                    </span>
                  </base-dropdown>
                </div>
              </ShadedCol>
            </template>
          </ShadedTable>
        </div>
      </div>
      <!-- CARDS -->
      <div class="row card-wrapper" v-if="entities.length && listType === 'cards'">
        <div class="col-lg-4" v-for="(entity, indexEntity) in entities" :key="indexEntity">
          <div class="card" style="box-shadow: none !important; border-radius: 8px !important; border: 0.5px solid #E8E8E8;">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <section class="top-section d-flex align-items-center mb-1">
                  <span v-if="entity.status" class="status-badge-success d-flex align-items-center">
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                  </span>
                  <span v-else class="status-badge-danger d-flex align-items-center">
                      <img src="/img/icons/icons8/ios/lock_danger.png" width="12px">
                      Bloqueado
                  </span>
                  <div class="row m-0 ml-2">
                    <span class="mx-1">
                      <a href="#" title="Saldos do cliente" @click.prevent="handleModalEntityHistory(entity)">
                        <img style="width: 20px" src="/img/icons/wallet.png" />
                      </a>
                    </span>
                    <span class="mx-1">
                      <a href="#"
                        @click.prevent="handleModalEntityRegistry(entity.id, entity.uuid, entity.entity_name, entity.document)">
                        <div v-if="entity.total_entity_registries > 0">
                          <img style="width: 20px" src="/img/icons/signing-a-document-blue.png"
                            :title="`Total de registros do cliente: ${entity.total_entity_registries}`" />
                        </div>
                        <div v-else>
                          <img style="width: 20px" src="/img/icons/signing-a-document.png"
                            :title="`Total de registros do cliente: ${entity.total_entity_registries}`" />
                        </div>
                      </a>
                    </span>
                    <span class="mx-1">
                      <a href="#" @click.prevent="handleShowAttachments(entity.id, entity.entity_name, entity.document)">
                        <div v-if="entity.total_entity_attachments > 0">
                          <img style="width: 20px" src="/img/icons/paperclip-green.png"
                            :title="`Total de anexos: ${entity.total_entity_attachments}`" />
                        </div>
                        <div v-else>
                          <img style="width: 20px" src="/img/icons/paperclip.png"
                            :title="`Total de anexos: ${entity.total_entity_attachments}`" />
                        </div>
                      </a>
                    </span>
                  </div>
                </section>
                <div class="ml-auto">
                  <base-dropdown menuOnRight>
                    <div slot="title-container" class="dropdown-toggle rounded m-0">
                      <img width="32px" src="/img/icons/icons8/ios/settings--v1_primary.png">
                    </div>
                    <span class="dropdown-item" @click="handleEditEntity(entity.uuid)">
                      <img src="/img/icons/create-new.png" width="19px" height="19px">
                      EDITAR
                    </span>
                    <div class="dropdown-divider p-0 m-0"></div>
                    <span class="dropdown-item" @click="handleModalSettings(entity.id)">
                      <img src="/img/icons/icons8/ios/settings-3--v1_gray.png" width="19px" height="19px">
                      CONFIGURAÇÕES
                    </span>
                    <div class="dropdown-divider p-0 m-0"></div>
                    <span class="dropdown-item" @click="handleEntityAccount(entity.id)">
                      <img src="/img/icons/bank-building-red.png" width="19px" height="19px">
                      BANCO
                    </span>
                    <div class="dropdown-divider p-0 m-0"></div>
                    <span class="dropdown-item" @click="handleRetentionModal(entity)">
                      <img src="/img/icons/ledger.png" width="19px" height="19px">
                      RETENÇÕES
                    </span>
                    <div class="dropdown-divider p-0 m-0"></div>
                    <span class="dropdown-item" @click="handleDeleteEntity(entity)">
                      <img src="/img/icons/icons8/ios/delete--v1.png" width="19px" height="19px">
                      EXCLUIR
                    </span>
                  </base-dropdown>
                </div>
              </div>
              <section class="mid-section mt-2 d-flex flex-column">
                <span class="entity-name">
                  {{ entity.entity_name }}
                </span>
                <span class="entity-document">
                  {{ entity.document }}
                </span>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalEditEntity @updatedEntity="loadDetailsAfterUpdate" ref="ModalEditEntity" />
    <ModalCreateRetentions ref="ModalCreateRetentions" />
    <EntityAccount ref="entityAccount" />
    <ModalSettings ref="modalSettings" />
    <ModalEntityAttachmentList ref="modalEntityAttachmentList" @storedAttachment="storedAttachment" @deletedLastAttachment="deletedLastAttachment" />
    <ModalEntityHistory ref="modalEntityHistory" />
    <ModalEntityRegistry ref="modalEntityRegistry" @storedEntityRegistry="storedAfterEntityRegistration" />
  </div>
</template>

<script>
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import ModalEditEntity from "./_Edit";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import ModalCreateRetentions from "../Retentions/_Create";
import LoadingPagination from "@/components/LoadingPagination";
import cursorPaginate from "@/mixins/cursorPaginate";
import EntityAccount from "@/views/Modules/Configuration/Entity/EntityAccount/Index";
import ModalSettings from "./_ModalSettings";
import SkeletonPuzlFullWidth from "@/components/SkeletonPuzlFullWidth";
import ModalEntityAttachmentList from "./_ModalEntityAttachmentList";
import ModalEntityHistory from "../../../Configuration/Entity/Shared/_ModalHistory";
import ModalEntityRegistry from "./_ModalEntityRegistry";
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import ShadedTable from "@/components/ShadedTable.vue";
import ShadedCol from "@/components/ShadedCol.vue";

const {formatErrorValidation} = require("@/plugins")
export default {
  name: "ListEntity",
  mixins: [cursorPaginate],
  components: {
    SkeletonPuzlGrid,
    ModalEditEntity,
    ModalCreateRetentions,
    PuzlEmptyData,
    LoadingPagination,
    EntityAccount,
    ModalSettings,
    SkeletonPuzlFullWidth,
    ModalEntityAttachmentList,
    ModalEntityHistory,
    ModalEntityRegistry,
    BaseButtonHoverable,
    SkeletonPuzl,
    ShadedTable,
    ShadedCol,
  },
  props: {
    entities: {
      type: Array,
      required: false
    },
    listType: {
      type: String,
      default: 'cards',
      required: true
    },
    loadingSkeleton: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data() {
    return {
      detailsEntitiesOpen: [],
      status: {
        blocked: { value: 0, name: "Bloqueados" },
        active: { value: 1, name: "Ativos" },
      },
      headers: [
        "Status",
        "Fornecedor",
        "CPF/CNPJ",
        "Ação"
      ]
    };
  },
  methods: {
    handleEditEntity(uuid) {
      this.$refs.ModalEditEntity.openModal(true, uuid);
    },
    handleRetentionModal(entity){
      this.$refs['ModalCreateRetentions'].handleCreateRetentionModal(entity);
    },
    handleEntityAccount(entityId) {
      this.$refs.entityAccount.handleCreateModal(entityId);
    },
    /**
     * @param {number} entityId
     * @param {string} name
     * @param {string} document
     */
    handleShowAttachments(entityId, name, document) {
      this.$refs.modalEntityAttachmentList.handleCreateModal(entityId, name, document);
    },
    /**
     * Atualiza total do ícone de anexos após realizar o upload.
     * @param {number} entityId
     */
    storedAttachment(entityId) {
      this.$store.dispatch('entity/getTotalAttachmentsByEntityId', entityId).then(response => {
        let foundEntity = this.entities.find((item) => item.id == entityId);
        foundEntity.total_entity_attachments = response.data.total_entity_attachments;
      });
    },
    /**
     * Atualiza total do ícone de registros do cliente após realizar um cadastro.
     * @param {object} entityRegistry
     */
    storedAfterEntityRegistration(entityRegistry) {
      this.$store.dispatch('entity/getTotalRegistriesByEntityId', entityRegistry.entity_id).then(response => {
        let foundEntity = this.entities.find((item) => item.id == entityRegistry.entity_id);
        foundEntity.total_entity_registries = response.data.total_entity_registries;
      });
    },
    /**
     * Após excluir um anexo, é atualizado o total de arquivos anexados.
     * @param {number} entityId
     */
    deletedLastAttachment(entityId) {
      let foundEntity = this.entities.find((item) => item.id == entityId);
      foundEntity.total_entity_attachments --;
    },
    /**
     * @param {object} entity
     */
    handleModalEntityHistory(entity) {
      this.$refs.modalEntityHistory.openModal(
        entity.id,
        { id: entity.id, uuid: entity.uuid, document: entity.document, entity_name : entity.entity_name },
      );
    },
    /**
     * @param {number} entityId
     * @param {string} entityUuid
     * @param {string} name
     * @param {string} document
     */
    handleModalEntityRegistry(entityId, entityUuid, name, document) {
      this.$refs.modalEntityRegistry.handleCreateModal(entityId, entityUuid, name, document);
    },
    /**
     * @param {object} contract_proposal
     */
    contractSelected(contract_proposal) {
      this.$refs.modalEntityHistory.openModal(contract_proposal.entity.id, true, contract_proposal.id);
    },
    handleDeleteEntity(entity) {
      this.$Swal
        .confirmDelete()
        .then(result => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação."
            });
            this.$store.dispatch("entity/destroy", entity.uuid)
              .then(() => {}).catch(error =>{
              if (error.status) {
                this.$Progress.finish()
                this.loadingStore = false
                this.$notify({
                  type: error.data.error_type,
                  message: error.data.message
                })
              } else {
                if (error.response.status === 422) {
                  let message = formatErrorValidation(error.response.data.errors)
                  this.$notify({
                    type: 'danger',
                    message: message
                  })
                  this.$Progress.finish()
                  this.loadingStore = false
                } else {
                  this.$notify({
                    type: error.data.error_type,
                    message: error.data.message
                  })
                  this.$Progress.finish()
                  this.loadingStore = false
                }
              }
            })
            this.$Progress.finish();
          }
        })
        .catch(() => this.$Progress.finish());
    },
    init(filter = null) {
      this.startCursor(filter);
      this.$store
        .dispatch("entity/fetchItems", {
          filter: this.filter,
          next_page: this.paginate.nextUrl,
        })
        .then(response => {
          this.resolveCursor(response);
        }).catch((error) => {
          this.resolveCursor();
        });
    },
    handleModalSettings(id) {
      this.$refs.modalSettings.openModal(id);
    },
    getDetailsEntity(indexEntity, id) {
      if (!this.entities[indexEntity].postal_code) {
        const found = this.detailsEntitiesOpen.find(item => id == item);
        if (!found) {
          this.detailsEntitiesOpen.push(id);
        }
        this.$set(this.entities[indexEntity], "loading", true);
        this.$store.dispatch('entity/getDetailsEntity', {id: id})
        .then((response) => {
          this.$set(this.entities[indexEntity], "postal_code", response.data.postal_code);
          this.$set(this.entities[indexEntity], "state", response.data.state);
          this.$set(this.entities[indexEntity], "address", response.data.address);
          this.$set(this.entities[indexEntity], "number", response.data.number);
          this.$set(this.entities[indexEntity], "district", response.data.district);
          this.$set(this.entities[indexEntity], "city", response.data.city);
          this.$set(this.entities[indexEntity], "state_registration", response.data.state_registration);
          this.$set(this.entities[indexEntity], "contributing_number", response.data.contributing_number);
          this.entities[indexEntity].loading = false;
        });
      }
    },
    loadDetailsAfterUpdate(entity) {
      const detailsWasOpened = this.detailsEntitiesOpen.find(item => entity.id == item);
      if (detailsWasOpened) {
        document.getElementById(entity.id).click();
        document.getElementById(entity.id).click();
      }
    },
  },
};
</script>

<style scoped>
.status-badge-success {
  background-color: #F2F7F3;
  border-radius: 16px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #149E57;
  font-weight: 400;
  padding: 4px 40px;
}
.status-badge-danger {
  background-color: #FCF3F3;
  border-radius: 16px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #DB4539;
  font-weight: 400;
  padding: 4px 34px
}
.entity-name {
  font-size: 16px;
  font-weight: 500;
  color: #2B2D32;
}
.entity-document {
  font-size: 14px;
  font-weight: 400;
  color: #2B2D32;
}
.badge-table {
  padding: 6px 19px;
  border-radius: 16px;
  cursor: pointer;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.badge-table b {
  font-weight: 400;
  font-size: 12px;
}
.badge-table-danger {
  background-color: #F9E3E1;
  color: #DB4539;
}
.badge-table-success {
  background: #F2F7F3;
  color: #149E57;
}

</style>
