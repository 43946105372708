<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">

            <div class="form-group row m-0 p-0 mb-1">
              <label
                class="col-md-12 pt-2 pb-1 col-form-label form-control-label"
              >
                Faturas
              </label>
              <div class="col-md-12">
                <base-input input-classes="form-control-sm">
                  <el-select
                    @input="calcInvoicedAmount()"
                    v-model="invoices"
                    size="mini"
                    placeholder="Selecione"
                    filterable
                    multiple
                    ref="invoice-select"
                  >
                    <el-option
                      v-for="invoice in $_invoices"
                      :key="invoice.id"
                      :label="`Fatura ${invoice.id} (${invoice.pending_to_paid.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} /
                          ${invoice.total.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})})`"
                      :value="invoice.id"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
            <div class="form-group row m-0 p-0 mb-2 limited-row">
              <label
                class="col-md-6  pt-2 pb-0 col-form-label form-control-label"
              >
                Valor faturado
              </label>
              <div class="col-md-6">
                <base-input input-group-classes="input-group-sm">
                  <input
                    disabled
                    v-money="money"
                    maxlength="10"
                    inputmode="numeric"
                    v-model="invoiced_amount"
                    type="text"
                    class="form-control form-control-sm"
                  />
                  <template slot="prepend">
                    <small class="input-group-sm p-0 m-0">
                      R$
                    </small>
                  </template>
                </base-input>
              </div>
            </div>
            <div class="form-group row m-0 p-0 mb-1 limited-row">
              <label
                class="col-md-6  pt-2 pb-0 col-form-label form-control-label"
              >
                Saldo
              </label>
              <div class="col-md-6">
                <base-input input-group-classes="input-group-sm">
                  <input
                    disabled
                    v-money="money"
                    maxlength="10"
                    inputmode="numeric"
                    v-model.lazy="params.balance"
                    type="text"
                    class="form-control form-control-sm"
                  />
                  <template slot="prepend">
                    <small class="input-group-sm p-0 m-0">
                      R$
                    </small>
                  </template>
                </base-input>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')">
                fechar
              </base-button>
              <base-button type="success" native-type="submit" :disabled="invalid
             || diffBalance()" :loading="isLoading">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  VMoney
} from "v-money";
import {mapGetters} from "vuex";

export default {
  name: "ModalAddInvoice",
  data() {
    return {
      modal: {
        title: 'Adicionar fatura',
        create: false,
      },
      invoiced_amount: 0,
      initial_invoiced_amount: 0,
      invoices: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false
      },
      isLoading: false,
      initial_balance: 0,
      params: {
        balance: 0,
        initial_balance: 0,
        uuid: null,
      },
      loadingStore: false,
    }
  },
  directives: {
    money: VMoney
  },
  computed: {
    ...mapGetters({
      $_invoices: "billingInvoice/invoices",
    })
  },
  methods: {
    diffBalance() {
      const balance = this.params.balance.toString()
      const initial_balance = this.params.initial_balance
      if (Number(balance.replace(',', '').replace('.', '')) === initial_balance) {
        return true
      }
      return false
    },
    calcInvoicedAmount() {
      const self = this
      this.balance = parseFloat(this.params.initial_balance)
      this.invoiced_amount = 0
      this.invoiced_amount += parseFloat(this.initial_invoiced_amount.replace(",", "."))
      this.invoices.map(function (item) {
        const invoice = self.$_invoices.find((self) => self.id === item)
        self.invoiced_amount += Number(invoice.pending_billing)
      })
      this.invoiced_amount = this.invoiced_amount.toFixed(2)
      const balance = parseFloat(this.params.initial_balance)
        + parseFloat(this.initial_invoiced_amount.replace(",", ".").replace('.', ''))
      this.total_value = balance
      this.params.balance = balance - parseFloat(this.invoiced_amount.replace(",", ".").replace('.', ''))
      if (this.params.balance < 0) {
        this.params.balance = 0
      }
    },
    closeModal() {
      this.modal.create = false
    },
    validHasSufficientBalance() {
      if (this.params.balance.replaceAll(".", "")
        .replace(",", ".") > 0) {
        return true
      }
      let balance = 0
      let total = this.params.initial_balance
      total = parseFloat(total)
      balance += total
      for (let id of this.invoices) {
        if (balance <= 0) {
          return false
        }
        const invoice = this.$_invoices.find(item => item.id === id)
        const value = Number(invoice.pending_billing)
        if (balance > value.toFixed(2).replace('.', '')) {
          balance -= value.toFixed(2).replace('.', '')
        } else {
          balance = 0
        }
      }
      return true
    },
    save() {
      const has = this.validHasSufficientBalance()
      if (!has) {
        return this.$notify({type: 'danger', message: 'Não há saldo suficiente para pagamento das faturas.'})
      }
      this.$Progress.start();
      this.isLoading = true
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.$store.dispatch('billReceive/addInvoicesToPayment', {
        invoices: this.invoices,
        bill_receive_id: this.params.bill_receive_id,
        balance: this.params.balance,
      }).then(response => {
        this.$notify({
          type: response.error_type,
          message: response.message
        });
        this.closeModal()
        this.$Progress.finish()
        this.isLoading = false
      })
    },
    openModal(params) {
      let loader = this.$loading.show()
      this.invoices = []
      this.invoiced_amount = params.invoiced_amount
      this.initial_invoiced_amount = params.invoiced_amount
      this.$store
        .dispatch("billingInvoice/init", {
          filter: {
            // with_iss_retained_flag: true,
            without_bill_receive: params.bill_receive_id,
            pending_payment: 1,
            contract_proposal_id: params.contract_proposal_id,
            not_canceled: true,
          }
        })
        .then(response => {
          this.params = params
          this.modal.create = true
          loader.hide()
        })

    },
  },
}
</script>

<style scoped>

</style>
