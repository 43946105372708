import * as types from './mutation_types'
import {destroy, update, cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'
import { createAxios } from "@/plugins/axios";
const endPoint = '/configuration/chart-account/'
const endPointGetCode = '/configuration/chart-account/get-code/'
export default {
  namespaced: true,
  state: {
    items: [],
    first_level_items: [],
    chartAccountItems: [],
    resumeItems: [],
    item: [],
    firstLevels: []
  },
  getters: {
    show: (state) => state.item,
    fetch: (state) => state.items,
    fetchChartAccounts: (state) => state.chartAccountItems,
    fetchFirstLevelChartAccounts: (state) => state.first_level_items,
    resume: (state) => state.resumeItems,
    fetchFirstLevels: (state) => state.firstLevels,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload;
    },
    [types.SET_FIRST_LEVEL](state, payload) {
      state.first_level_items = payload;
    },
    [types.SET_CHART_ACCOUNTS](state, payload) {
      state.chartAccountItems = payload;
    },
    [types.SET_RESUME](state, payload) {
      state.resumeItems = payload;
    },
    [types.PUSH](state, payload) {
      state.items.push(payload);
    },
    [types.SHOW](state, payload) {
      state.item = payload;
    },
    [types.DESTROY](state, uuid) {
      destroy(state, uuid);
    },
    [types.UPDATE](state, payload) {
      update(state, payload);
    },
    [types.GET_CODE](state, payload) {
      state.item = payload;
    },
    [types.GET_FIRST_LEVELS](state, payload) {
      state.firstLevels = payload;
    }
  },
  actions: {
    fetchItems({ commit }, params) {
      return createAxios()
        .get(endPoint, { params: params })
        .then(({ data }) => {
          commit(types.SET, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    fetchChartAccountItems({ commit }, params) {
      return createAxios()
        .get(endPoint, { params: params })
        .then(({ data }) => {
          commit(types.SET_CHART_ACCOUNTS, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    fetchItemsPaginate({ commit, state }, params) {
      return createAxios()
        .get(setUrlWithNextPage(endPoint, params), { params: params })
        .then(({ data }) => {
          cursorPaginate(state, {
            data: data.data,
            next_page: params.next_page,
          });
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getResume({ commit }, params) {
      return createAxios()
        .get(endPoint + "resume", { params: params })
        .then(({ data }) => {
          commit(types.SET_RESUME, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    add({ commit, state }, payload) {
      return createAxios()
        .post(endPoint, payload)
        .then(({ data }) => {
          commit(types.PUSH, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    update({ commit }, payload) {
      return createAxios()
        .put(endPoint + payload.uuid, payload)
        .then(({ data }) => {
          commit(types.UPDATE, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    show({ commit, dispatch }, uuid) {
      return createAxios()
        .get(endPoint + uuid)
        .then(({ data }) => {
          commit(types.SHOW, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    destroy({ commit, dispatch }, uuid) {
      return createAxios()
        .delete(endPoint + uuid)
        .then(({ data }) => {
          commit(types.DESTROY, uuid);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getCode({ commit, dispatch }, uuid) {
      return createAxios()
        .get(endPointGetCode + uuid)
        .then(({ data }) => {
          commit(types.SHOW, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getLastCode() {
      return createAxios()
        .get(endPoint + "last-code")
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getFirstLevel({ commit }, params) {
      return createAxios()
        .get(endPoint + "first-level", {params: params})
        .then(({ data }) => {
          commit(types.SET_FIRST_LEVEL, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
};
