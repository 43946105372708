var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-dropdown',{style:(_vm.listType === 'cards' ? 'position: absolute; right: 20px;' : ''),attrs:{"menuOnRight":""}},[_c('div',{staticClass:"dropdown-toggle rounded m-0",attrs:{"slot":"title-container"},slot:"title-container"},[_c('img',{attrs:{"width":_vm.listType === 'table' ? 25 : 35,"src":"/img/icons/icons8/ios/settings--v1_primary.png"}})]),_c('a',{staticClass:"dropdown-item new-default-black-font",staticStyle:{"align-items":"center","display":"flex"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('handleEditBillReceive', _vm.bill_receive)}}},[_c('img',{attrs:{"src":"/img/icons/create-new.png","width":"20"}}),_vm._v(" Editar ")]),(_vm.bill_receive.contract_proposal_code)?_c('div',{staticClass:"dropdown-divider p-0 m-0"}):_vm._e(),(_vm.bill_receive.contract_proposal_code)?_c('router-link',{attrs:{"to":{
      name: 'commercial.contract-proposal.edit',
      path: '/commercial/contract-proposal/edit',
      params: { contract_proposal_uuid: _vm.bill_receive.contract_proposal_code }
    }}},[_c('a',{staticClass:"dropdown-item new-default-black-font",staticStyle:{"align-items":"center","display":"flex"}},[_c('img',{attrs:{"src":"/img/icons/external-link-squared.png","width":"20"}}),_vm._v(" Ir para o contrato "+_vm._s(_vm.bill_receive.contract_proposal_code)+" ")])]):_vm._e(),_c('div',{staticClass:"dropdown-divider p-0 m-0"}),_c('a',{staticClass:"dropdown-item new-default-black-font",staticStyle:{"align-items":"center","display":"flex"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('handleOpenModalInfo', _vm.bill_receive)}}},[_c('img',{attrs:{"src":"/img/icons/info.png","width":"20"}}),_vm._v(" Informações ")]),_c('div',{staticClass:"dropdown-divider p-0 m-0"}),_c('a',{staticClass:"dropdown-item new-default-black-font",staticStyle:{"align-items":"center","display":"flex"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('handleShowHistory', _vm.bill_receive)}}},[_c('img',{attrs:{"src":"/img/icons/icons8/ios/order-history_secondary.png","width":"20"}}),_vm._v(" Histórico ")]),(_vm.bill_receive.type === 0 && !_vm.bill_receive.entity_id)?_c('div',{staticClass:"dropdown-divider p-0 m-0"}):_vm._e(),(_vm.bill_receive.type === 0 && !_vm.bill_receive.entity_id)?_c('a',{staticClass:"dropdown-item new-default-black-font",staticStyle:{"align-items":"center","display":"flex"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('handleIdentifyEntity', _vm.bill_receive)}}},[_c('img',{attrs:{"src":"/img/icons/icons8/ios/identify_success.png","width":"20"}}),_vm._v(" Identificar ")]):_vm._e(),(_vm.bill_receive.payment_method_uuid === '947bf938-e098-4d1e-965a-c33fc9aa05d5'
    && parseInt(_vm.bill_receive.status) !== 3)?_c('div',{staticClass:"dropdown-divider p-0 m-0"}):_vm._e(),(_vm.bill_receive.last_billet === false
    && _vm.bill_receive.payment_method_uuid === '947bf938-e098-4d1e-965a-c33fc9aa05d5'
    && parseInt(_vm.bill_receive.status) !== 3
    && parseInt(_vm.bill_receive.status) !== 1)?_c('a',{staticClass:"dropdown-item new-default-black-font",staticStyle:{"align-items":"center","display":"flex"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('showModalGenerateBillet', _vm.bill_receive)}}},[_c('img',{attrs:{"src":"/img/icons/barcode.png","width":"20"}}),_vm._v(" Boleto ")]):_vm._e(),(_vm.bill_receive.last_billet !== false
    && _vm.bill_receive.payment_method_uuid === '947bf938-e098-4d1e-965a-c33fc9aa05d5'
    && parseInt(_vm.bill_receive.status) !== 3)?_c('a',{staticClass:"dropdown-item new-default-black-font",staticStyle:{"align-items":"center","display":"flex"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('showModalGenerateBilletHasGenerated', _vm.bill_receive)}}},[_c('img',{attrs:{"src":"/img/icons/barcode.png","width":"20"}}),_vm._v(" Boleto ")]):_vm._e(),(_vm.bill_receive.bank_billet_batch_id)?_c('div',{staticClass:"dropdown-divider p-0 m-0"}):_vm._e(),(_vm.bill_receive.bank_billet_batch_id)?_c('a',{staticClass:"dropdown-item new-default-black-font",staticStyle:{"align-items":"center","display":"flex"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('postBatchUpdate', _vm.bill_receive)}}},[_c('img',{attrs:{"src":"/img/icons/connection-sync.png","width":"20"}}),_vm._v(" Atualizar Lote ")]):_vm._e(),(_vm.hasTotvs && [_vm.BillReceiveInstallmentEnum.RECEIVED, _vm.BillReceiveInstallmentEnum.APPROVED].includes(_vm.bill_receive.status))?_c('div',{staticClass:"dropdown-divider p-0 m-0"}):_vm._e(),(_vm.hasTotvs && [_vm.BillReceiveInstallmentEnum.RECEIVED, _vm.BillReceiveInstallmentEnum.APPROVED].includes(_vm.bill_receive.status))?_c('a',{staticClass:"dropdown-item new-default-black-font",staticStyle:{"align-items":"center","display":"flex"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('handleModalEntityBillReceiveIntegrationInfo', _vm.bill_receive)}}},[_c('img',{attrs:{"src":"/img/icons/icons8/ios/connected_success.png","width":"20"}}),_vm._v(" Totvs ")]):_vm._e(),(
      _vm.$hasPermission('bill_receive.change_due_date')
      && _vm.bill_receive.type === 1
      && (_vm.validFinancialLimit())
      && !([_vm.BillReceiveInstallmentEnum.RECEIVED, _vm.BillReceiveInstallmentEnum.CANCELED].includes(_vm.bill_receive.status))
      &&
        (
          _vm.bill_receive.payment_method_uuid === '947bf938-e098-4d1e-965a-c33fc9aa05d5'
          && _vm.bill_receive.last_billet !== false
        )
    )?_c('div',{staticClass:"dropdown-divider p-0 m-0"}):_vm._e(),(
      _vm.$hasPermission('bill_receive.change_due_date')
      && _vm.bill_receive.type === 1
      && (_vm.validFinancialLimit())
      && !([_vm.BillReceiveInstallmentEnum.RECEIVED, _vm.BillReceiveInstallmentEnum.CANCELED].includes(_vm.bill_receive.status))
      && _vm.validBillet(_vm.bill_receive)
    )?_c('a',{staticClass:"dropdown-item new-default-black-font",staticStyle:{"align-items":"center","display":"flex"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('showModalEditGenerateBilletHasGenerated', _vm.bill_receive)}}},[_c('img',{attrs:{"src":"/img/icons/icons8/ios/empty_calendar_gray.png","width":"20"}}),_vm._v(" Alterar Vencimento ")]):_vm._e(),(
      _vm.$hasPermission('bill_receive.change_due_date')
      && _vm.bill_receive.type === 1
      && (_vm.validFinancialLimit())
      && !([_vm.BillReceiveInstallmentEnum.RECEIVED, _vm.BillReceiveInstallmentEnum.CANCELED].includes(_vm.bill_receive.status))
      && _vm.validBillet(_vm.bill_receive)
    )?_c('div',{staticClass:"dropdown-divider p-0 m-0"}):_vm._e(),(
      _vm.$hasPermission('bill_receive.change_due_date')
      && _vm.bill_receive.type === 1
      && (_vm.validFinancialLimit())
      && !([_vm.BillReceiveInstallmentEnum.RECEIVED, _vm.BillReceiveInstallmentEnum.CANCELED].includes(_vm.bill_receive.status))
      &&
        (
          _vm.bill_receive.payment_method_uuid === '947bf938-e098-4d1e-965a-c33fc9aa05d5'
          && _vm.bill_receive.last_billet !== false
        )
    )?_c('a',{staticClass:"dropdown-item new-default-black-font",staticStyle:{"align-items":"center","display":"flex"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('showModalEditGenerateBilletHasGenerated', _vm.bill_receive)}}},[_c('img',{attrs:{"src":"/img/icons/icons8/ios/empty_calendar_gray.png","width":"20"}}),_vm._v(" Alterar Vencimento ")]):_vm._e(),(
      ![
        _vm.BillReceiveInstallmentEnum.CANCELED,
        _vm.BillReceiveInstallmentEnum.APPROVED,
        _vm.BillReceiveInstallmentEnum.DENIED
      ].includes(_vm.bill_receive.status) && _vm.bill_receive.last_billet === false
    )?_c('div',{staticClass:"dropdown-divider p-0 m-0"}):_vm._e(),(
      ![
        _vm.BillReceiveInstallmentEnum.CANCELED,
        _vm.BillReceiveInstallmentEnum.APPROVED,
        _vm.BillReceiveInstallmentEnum.DENIED
      ].includes(_vm.bill_receive.status) && _vm.bill_receive.last_billet === false
    )?_c('a',{staticClass:"dropdown-item new-default-black-font",staticStyle:{"align-items":"center","display":"flex"},on:{"click":function($event){return _vm.$emit('handleDeleteBillReceive', _vm.bill_receive)}}},[_c('img',{attrs:{"src":"/img/icons/delete.png","width":"20"}}),_vm._v(" Excluir ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }