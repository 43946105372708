<template>
  <div>
    <!-- Filtros -->
    <base-header class="bg-gray-content">
      <div class="row m-0">
        <div class="col-md-12 card-row p-0 m-0">
          <multi-filter ref="multi-filter" @fetch="fetch" @clearFilters="clearFilters" :filter="filter" :status="multi_filter">
            <div class="col-md-3 p-2">
              <input-date-picker
                :filterable="4"
                size="small"
                ref="date-picker"
                @handleFilterDate="handleFilterDate"
                :default-range="range"
              />
            </div>
            <div class="col-md-3 p-2">
              <puzl-select v-model="filter.integration_type" :items="integration_types" placeholder="Integração" @input="fetch()" />
            </div>
            <div class="col-md-3 p-2">
              <puzl-select
                :multiple="true"
                v-model="filter.company_plants"
                :items="$_company_plants"
                placeholder="Central"
                @input="fetch()"
              />
            </div>
          </multi-filter>
        </div>
      </div>
    </base-header>

    <div class="container-fluid">
      <!-- Tabela -->
      <section>
        <div class="card">
          <div class="card-body">
            <div class="col-md-12 p-0 table-responsive">
              <table class="table table-sm table-bordered">
                <thead>
                  <tr class="fixed">
                    <th class="text-center">Data</th>
                    <th class="text-center">Integração</th>
                    <th class="text-center">Registro</th>
                    <th class="text-center">Central</th>
                    <th class="text-center">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="loadingSkeleton">
                    <tr v-for="(item, skeletonIndex) in 10" :key="skeletonIndex">
                      <th colspan="8">
                        <skeleton-puzl type="button" />
                      </th>
                    </tr>
                  </template>
                  <template v-else>
                    <tr v-for="(item, index) in items" :key="index">
                      <th class="text-center">{{ item.updated_at | parseDate }}</th>
                      <th class="text-center">{{ getIntegrationName(item.type) }}</th>
                      <th class="text-center">{{ item.name }}</th>
                      <th class="text-center">{{ item.company_plant_name || "N/A" }}</th>
                      <th class="text-center">
                        <el-popover v-if="!item.totvs_id" trigger="hover" placement="bottom" class="p-0">
                          <h4 class="mt-n1 mb-0 text-sm font-weight-400">{{ item.response }}</h4>
                          <base-button
                            slot="reference"
                            @click.prevent="resend(item)"
                            block
                            size="sm"
                            type="danger"
                            outline
                            class="active text-uppercase mr-0"
                          >
                            Erro
                        </base-button>
                        </el-popover>
                        <base-button
                          v-else
                          block
                          size="sm"
                          type="success"
                          outline
                          class="active text-uppercase mr-0"
                        >
                          Integrado
                        </base-button>
                      </th>
                    </tr>
                  </template>
                </tbody>
              </table>
              <PuzlEmptyData v-if="!loadingSkeleton && !items.length" />
            </div>
            <div class="col-md-12 p-0 mt-3">
              <pagination @navegate="navegate" :source="source" :loading="loadingSkeleton" />
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import PuzlEmptyData from "@/components/PuzlEmptyData";
import { report, entity, invoice, xml_travels, entity_bill_receive } from "@/plugins/microservices/totvs";
import Pagination from "@/components/Utils/Pagination";
import SkeletonPuzl from "@/components/SkeletonPuzl.vue";
import InputDatePicker from "@/components/InputDatePicker";
import MultiFilter from "@/components/Utils/MultiFilterV2";
import PuzlSelect from "@/components/PuzlSelect";
import { mapGetters } from "vuex";

export default {
  name: "List",
  components: {
    PuzlEmptyData,
    Pagination,
    SkeletonPuzl,
    InputDatePicker,
    MultiFilter,
    PuzlSelect,
  },
  computed: {
    ...mapGetters({
      $_company_plants: "plant/activeItems",
    }),
  },
  data() {
    return {
      loadingSkeleton: false,
      items: [],
      source: null,
      page: 1,
      filter: {
        integration_type: null,
        company_plants: [],
        status: 2,
      },
      range: {
        start: new Date(),
        end: new Date(),
        default_filterable: false,
      },
      multi_filter: {
        null: {
          name: "Todos",
          type: "primary",
        },
        1: {
          name: "Integrado",
          type: "success",
        },
        2: {
          name: "Erro",
          type: "danger",
        },
      },
      integration_types: [
        {
          id: "entities",
          name: "Cliente/Fornecedor",
        },
        {
          id: "sellers",
          name: "Vendedor",
        },
        {
          id: "equipments",
          name: "Equipamentos",
        },
        {
          id: "xml_travels",
          name: "Remessa de carregamento",
        },
        {
          id: "anticipation_invoices",
          name: "Fatura antecipada",
        },
        {
          id: "nfse_invoices",
          name: "Fatura pós concretagem",
        },
        {
          id: "entity_bill_receives",
          name: "Antecipações",
        },
        {
          id: "constructions",
          name: "Obras",
        },
      ],
    };
  },
  methods: {
    async fetch(filter = null) {
      this.loadingSkeleton = true;
      const loader = this.$loading.show();
      const response = await report.index({ page: this.page, filter: filter ?? this.filter });
      this.items = response.data.data ?? [];
      this.items.map((item) => {
        item.integration_name = this.getIntegrationName(item.type);
        return item;
      }, this);
      this.source = response.data;
      this.loadingSkeleton = false;
      loader.hide();
    },

    /**
     * Retorna nome de integração
     *
     * @param {string} integration
     * @returns {string}
     */
    getIntegrationName(integration) {
      const selected_integration = this.integration_types.find((item) => item.id === integration);
      return selected_integration ? selected_integration.name : "";
    },

    /**
     * Navegação entre páginas
     *
     * @param {integer} page
     *
     * @returns void
     */
    navegate(page) {
      this.page = page;
      this.fetch();
    },

    /**
     * Realiza o reenvio para totvs
     *
     * @param {object} item
     *
     * @returns void
     */
    async resend(item) {
      const loader = this.$loading.show();
      switch (item.type) {
        case "entities":
          await entity.store({ id: item.puzl_id });
          break;
        case "anticipation_invoices":
        case "nfse_invoices":
          await invoice.store({ invoice_id: item.puzl_id });
          break;
        case "xml_travels":
          await xml_travels.store({ xml_travel_id: item.puzl_id });
          break;
        case "entity_bill_receives":
          await entity_bill_receive.store({ 'entity_bill_receive_id': item.puzl_id });
          break;
      }
      loader.hide();
      this.fetch();
    },

    /**
     * Limpa os filtros
     *
     * @returns void
     */
    clearFilters() {
      this.filter.integration_type = null;
      this.filter.company_plants = [];
      this.page = 1;
      this.fetch();
    },

    /**
     * Dispara filtro de data
     *
     * @returns void
     */
    handleFilterDate(filter) {
      this.filter.range = filter;
      this.page = 1;
      this.fetch();
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>

<style scoped>
td,
th {
  vertical-align: middle !important;
}
</style>
