<template>
  <div>
    <modal size="lg" :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }} <small>(Previsto)</small></h5>
      </template>
      <div v-if="service">
        <div class="border-left border-primary border-3 p-2 mb-4">
          {{ service.service ? service.service.name : "" }}

          <br/>
          <small
          >{{ service.service ? service.service.description : "" }}
          </small>
        </div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(save)"
            autocomplete="off"
          >
            <div>
              <div v-if="plants.length" class="form-group row m-0 p-0 mt-4">
                <label
                  class="col-md-4 pt-1 pb-0 col-form-label form-control-label mb-1"
                >
                  Central
                  <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                </label>
                <div class="col-md-8 pr-4 mb-1">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <el-select
                        v-model="service.company_plant_uuid"
                        size="mini"
                        placeholder="Selecione"
                        filterable
                        @change="changePlant"
                      >
                        <el-option
                          v-for="plant in plants"
                          :key="plant.company_plant_uuid"
                          :label="plant.company_plant_name"
                          :value="plant.company_plant_uuid"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-4 pt-1 pb-0 col-form-label form-control-label"
                >
                  Cnpj emissor
                  <template  v-if="company_plant_fixed_issuer_not_found">
                    <el-popover trigger="click"
                                placement="right"
                    >
                      <h5 class="font-weight-normal m-0">CNPJ Emissor fixo não encontrado para central
                        selecionada.</h5>
                      <base-button outline slot="reference" size="sm" type="secundary"
                                   class="shadow-none text-indigo">
                        <img src="/img/icons/icons8/ios/error--v1_danger.png" width="18">
                      </base-button>
                    </el-popover>
                  </template>
                  <template v-else>
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </template>
                </label>
                <div class="col-md-8 pr-4">
                  <validation-provider rules="required">
                    <base-input input-classes="form-control-sm">
                      <el-select
                        v-model="service.company_plant_issuer_id"
                        size="mini"
                        placeholder="Selecione"
                        filterable
                        :disabled="loadingPlantIssuer || !service.company_plant_uuid || contract_proposal.issuer_fixed"
                      >
                        <el-option
                          v-for="issuer in $_company_plant_issuers"
                          :key="issuer.id"
                          :label="issuer.business_name"
                          :value="issuer.id"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-4 pb-0 pt-1 col-form-label form-control-label"
                >
                  Início previsto
                  <span class="text-danger">&nbsp;*&nbsp;</span>
                </label>
                <div class="col-md-8 pr-4">
                  <base-input input-group-classes="input-group-sm">
                    <input-date-time-picker
                      :disabled="loading"
                      @input="timeCalc"
                      v-model="service.start_at"
                    />
                  </base-input>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-4 pb-0 pt-1 col-form-label form-control-label"
                >
                  Fim previsto
                  <span class="text-danger">&nbsp;*&nbsp;</span>
                </label>
                <div class="col-md-8 pr-4">
                  <base-input input-group-classes="input-group-sm">
                    <input-date-time-picker
                      :disabled="loading"
                      @input="timeCalc"
                      v-model="service.end_at"
                    />
                  </base-input>
                </div>
              </div>
            </div>

            <div class="form-group row m-0 p-0 mb-1">
              <label
                class="col-md-4 pt-1 pb-1 col-form-label form-control-label"
              >
                Tempo previsto
              </label>
              <div class="col-md-8 pr-4">
                <base-input input-group-classes="input-group-sm">
                  <input
                    v-model="hours"
                    disabled
                    type="text"
                    class="form-control form-control-sm"
                  />
                  <template slot="append">
                    <small class="input-group-sm p-0 m-0">
                      horas
                    </small>
                  </template>
                </base-input>
              </div>
            </div>
            <div class="form-group row m-0 p-0 mb-1">
              <label
                class="col-md-4 pt-1 pb-0 col-form-label form-control-label"
              >
                Volume previsto
                <span class="text-danger">&nbsp;*&nbsp;</span>
              </label>
              <div class="col-md-8 pr-4">
                <base-input input-group-classes="input-group-sm">
                  <input
                    v-on:input="
                        service.volume = formatString(
                          $event.target.value,
                          '0',
                          '999.9',
                          '1'
                        )
                      "
                    v-model="service.volume"
                    :disabled="loading"
                    type="text"
                    class="form-control form-control-sm"
                  />
                  <template slot="append">
                    <small class="input-group-sm p-0 m-0">
                      m<sup>3</sup>
                    </small>
                  </template>
                </base-input>
              </div>
            </div>

            <div v-show="!bySeller" class="form-group row m-0 p-0 mb-1">
              <label
                class="col-md-4 pb-1 pt-1 col-form-label form-control-label"
              >
                Equipamento
              </label>
              <div class="col-md-8 pr-4">
                <validation-provider>
                  <base-input input-classes="form-control-sm">
                    <el-select
                      v-model="service.equipment_uuid"
                      size="mini"
                      placeholder="Selecione"
                      filterable
                      :disabled="loading"
                    >
                      <el-option label="Selecione" :value="null"></el-option>
                      <el-option
                        v-for="equipment in equipments"
                        :key="equipment.uuid"
                        :label="equipment.code + ' - ' + equipment.plate"
                        :value="equipment.uuid"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div v-show="!bySeller" class="form-group row m-0 p-0 mb-1">
              <label
                class="col-md-4 pt-1 pb-0 col-form-label form-control-label"
              >
                Responsáveis
              </label>
              <div class="col-md-8 pr-4">
                <validation-provider>
                  <base-input input-classes="form-control-sm">
                    <el-select
                      v-model="service.employers"
                      size="mini"
                      placeholder="Selecione"
                      filterable
                      multiple
                      :disabled="loading"
                    >
                      <el-option
                        v-for="employer in users"
                        :key="employer.uuid"
                        :label="employer.name"
                        :value="employer.user_uuid"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!-- Observaçoes -->
            <div class="form-group row m-0 p-0 mb-1">
              <label
                class="col-md-4 pt-1 pb-0 col-form-label form-control-label"
              >
                Observações
              </label>
              <div class="col-md-8 pr-4">
                <validation-provider>
                  <base-input input-classes="form-control-sm">
                    <textarea
                      v-model="service.observations"
                      type="text"
                      rows="2"
                      class="form-control form-control-sm"
                    >
                    </textarea>
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="modal-footer text-center d-flex align-items-center">
              <base-button type="secondary" @click="closeModal()"
              >Cancelar
              </base-button
              >
              <base-button
                type="success"
                native-type="submit"
                :disabled="invalid || loading"
              >
                <span v-show="!loading" class="btn-label"><i class="fas fa-save"></i></span>
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import moment from "moment";
import {checkNumberValue} from "@/helpers";
import {mask1, formatErrorValidation, formatDate} from "@/plugins";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import {mapGetters} from "vuex";

export default {
  components: {
    InputDateTimePicker,
  },
  name: "ModalCreateService",
  data() {
    return {
      modal: false,
      title: "Novo Serviço",
      loading: false,
      loadingPlantIssuer: true,
      plants: [],
      company_plant_fixed_issuer_not_found: [],
      service: {
        id: null,
        uuid: null,
        service_name: null,
        equipment: null,
        employers: [],
        volume: null,
        company_plant_issuer_id: null,
        observations: null,
        company_plant_uuid: null
      },
      hours: null,
      equipments: [],
      users: []
    };
  },
  computed: {
    ...mapGetters({
      $_company_plant_issuers: "plant/getCompanyPlantIssuers",
      contract_proposal: "contractProposal/show",
    })
  },
  props: {
    bySeller: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        this.$forceUpdate();
        return checkNumberValue(
          mask1(value.toString(), decimals, ".", true),
          min,
          max
        );
      }
      return checkNumberValue(value, min, max);
    },
    async handleCreate(service, schedule_uuid, company_plants, params) {
      let loader = this.$loading.show()
      this.hours = null;
      this.equipments = [];
      this.users = [];
      this.plants = company_plants;
      service.volume = params.total_volume;
      service.start_at = formatDate(params.first_arrival_time);
      service.end_at = formatDate(params.last_exit_time);
      this.company_plant_fixed_issuer_not_found = false
      this.service = {
        id: null,
        uuid: null,
        service_name: null,
        equipment: null,
        employers: [],
        company_plant_issuer_id: null,
        volume: null,
        observations: null,
        company_plant_uuid: null
      };
      this.service = JSON.parse(JSON.stringify(service));
      this.service.schedule_uuid = schedule_uuid;
      this.timeCalc();
      this.$forceUpdate();
      await this.$store.dispatch('contractProposal/show', this.service.contract_proposal_uuid).then((response) => {
        this.modal = true;
        loader.hide()
      })
    },
    getCompanyPlantIssuers() {
      this.loadingPlantIssuer = true;
      this.service.company_plant_issuer_id = null;
      let id = this.plants.filter(item => item.company_plant_uuid === this.service.company_plant_uuid)[0].company_plant_id
      this.$store
        .dispatch(
          "plant/fetchCompanyPlantIssuersByPlant",
          {id: id}
        ).then(response => {
        if (response.data.length) {
          let default_plant_issuer = response.data.find((item) => item.default === 1) || response.data[0]
          /**
           * verifica se possui CNPJ Emissor fixo
           */
          if (this.contract_proposal.issuer_fixed) {
            let fixed_issuer_id = this.contract_proposal.company_plant_issuer_id_fixed
            /**
             * verifica se o CNPJ Emissor fixo pertence a central selecionada
             */
            let has_issuer = response.data.find((item) => item.id === fixed_issuer_id)
            if (has_issuer) {
              this.service.company_plant_issuer_id = fixed_issuer_id
              this.company_plant_fixed_issuer_not_found = false
            } else {
              this.company_plant_fixed_issuer_not_found = true
              this.service.company_plant_issuer_id = null
            }
          } else {
            if (default_plant_issuer && !this.contract_proposal.company_plant_issuer_id) {
              this.service.company_plant_issuer_id = default_plant_issuer.id
            }
          }
        }
        this.loadingPlantIssuer = false;
      });
    },
    async changePlant() {
      this.$Progress.start();
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.loading = true;
      this.service.employers = [];
      this.service.equipment_uuid = null;
      this.getCompanyPlantIssuers();
      const equipment_category_id = await this.getEquipmentCategoryIdByContractProposalServiceUuid(this.service.uuid);
      this.$store
        .dispatch(
          "equipment/getByCompanyPlantAndService",
          {
            filter: {
              company_plant_uuid: this.service.company_plant_uuid,
              status: 1, // ativo,
              with_equipment_restriction: this.service.service.description,
              category_id: equipment_category_id
            }
          }
        )
        .then(response => {
          this.equipments = response.data;
          this.$store
            .dispatch("user/getByCompanyPlant", {
              company_plant_uuid: this.service.company_plant_uuid,
              type: 'service_driver'
            })
            .then(response => {
              this.users = response.data;
              this.loading = false;
              this.$notify({
                type: response.error_type,
                message: response.message
              });
              this.$Progress.finish();
            });
        });
    },
    timeCalc() {
      this.$forceUpdate();
      if (this.service.start_at && this.service.end_at) {
        let start = moment(this.service.start_at);
        let end = moment(this.service.end_at);
        var duration = moment.duration(end.diff(start));
        this.hours = duration.asHours().toFixed(1);
      } else {
        this.hours = null;
      }
    },
    save() {
      this.$Progress.start();
      this.loading = true;
      this.service.service_uuid = this.service.uuid;
      this.service.start_at = moment(this.service.start_at).format('YYYY-MM-DD HH:mm:ss');
      this.service.end_at = moment(this.service.end_at).format('YYYY-MM-DD HH:mm:ss');
      this.$store
        .dispatch("scheduleService/add", this.service)
        .then(response => {
          this.$Progress.finish();
          this.loading = false;
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.$emit("addedService", response.data);
          this.closeModal();
        })
        .catch(error => {
          if (error.status) {
            this.$notify({
              type: "danger",
              message: error.data.message
            });
            this.$Progress.finish();
            this.loading = false;
          } else if (
            error.response &&
            error.response.status &&
            error.response.status === 422
          ) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors
            });
            this.$Progress.finish();
            this.loading = false;
          }
        });
    },
    async getEquipmentCategoryIdByContractProposalServiceUuid(contract_proposal_service_uuid) {
      const response = await this.$store.dispatch("contractService/getEquipmentCategoryIdByContractProposalServiceUuid", contract_proposal_service_uuid);
      return response.data;
    }
  },
  created() {
  },
  mounted() {
  }
};
</script>

<style></style>
