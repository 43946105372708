<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-12">
          <h2 style="font-size: 1.2rem !important;" class="text-muted">
            <img height="30px" src="/img/icons/icons8/ios/time-machine.png" class="mr-3"/>
            HISTÓRICO DE ALTERAÇÕES DE REFERÊNCIA COMERCIAL E TÉCNICA
          </h2>
        </div>
      </div>
    </base-header>
    <list/>
  </div>
</template>
<script>
import List from './Shared/_List.vue'
export default {
  props: ['show'],
  name: "Index",
  components: {
    List
  },
  data() {
    return {}
  }
}
</script>
