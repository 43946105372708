<template>
  <div>
    <modal :show.sync="modal.open">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>

      <div v-show="loadingSkeleton">
        <div class="form-group row m-0 p-0">
          <div class="col-md-5">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
          <div class="col-md-7">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
        </div>
        <div class="form-group row m-0 p-0">
          <div class="col-md-3">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
          <div class="col-md-9">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
        </div>
        <div class="form-group row m-0 p-0">
          <div class="col-md-4">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
          <div class="col-md-8">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
        </div>
      </div>

      <div v-show="!loadingSkeleton">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
            <div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  {{ feature.short_description }}
                  <span class="small" v-if="feature.measured_unit.id!==99">
                   ({{ feature.measured_unit.initials }})
                  </span>
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <div v-if="feature.type===2">
                        <input
                          type="text"
                          v-model="feature_item.description"
                          class="form-control form-control-sm"
                          :class="errors[0] ? 'is-invalid' : 'is-valid'"
                        />
                      </div>
                      <div v-if="feature.type===1 && (feature.decimal_places === 0 || !feature.decimal_places)">
                        <input
                          type="text"
                          v-model="feature_item.description"
                          class="form-control form-control-sm"
                          v-mask="['####']"
                          :class="errors[0] ? 'is-invalid' : 'is-valid'"
                        />
                      </div>
                      <div v-if="feature.type===1 && feature.decimal_places === 1">
                        <input
                          type="text"
                          v-model="feature_item.description"
                          class="form-control form-control-sm"
                          v-mask="['####.#','###.#','##.#','#.#']"
                          :class="errors[0] ? 'is-invalid' : 'is-valid'"
                        />
                      </div>
                      <div v-if="feature.type===1 && feature.decimal_places === 2">
                        <input
                          type="text"
                          v-model="feature_item.description"
                          class="form-control form-control-sm"
                          v-mask="['####.##','###.##','##.##','#.##']"
                          :class="errors[0] ? 'is-invalid' : 'is-valid'"
                        />
                      </div>
                      <div v-if="feature.type===1 && feature.decimal_places === 3">
                        <input
                          type="text"
                          v-model="feature_item.description"
                          class="form-control form-control-sm"
                          v-mask="['####.###','###.###','##.###','#.###']"
                          :class="errors[0] ? 'is-invalid' : 'is-valid'"
                        />
                      </div>
                    </base-input>

                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0" v-if="feature.type===1">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Sd
                  <span class="small" v-if="feature.measured_unit.id!==99">
                    ({{ feature.measured_unit.initials }})
                  </span>
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      v-model="feature_item.value_sd"
                      class="form-control form-control-sm"
                      v-mask="['#.##']"
                      @change="calculateValueMedium"
                    />
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0" v-if="feature.type===1">
                <label
                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                >
                  Nivel de Confiança
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <el-select v-model="feature_item.level_reliability"
                               placeholder="Selecione"
                               @change="calculateValueMedium"
                    >
                      <el-option v-for="reliability_level in reliability_levels"
                                 :key="reliability_level.id"
                                 :label="reliability_level.reliability+'%'"
                                 :value="reliability_level.value_z">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0" v-if="feature.type==1">
                <label
                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                >
                  Z
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      disabled
                      type="text"
                      v-model="feature_item.value_z"
                      class="form-control form-control-sm"
                    />
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0" v-if="feature.type===1">
                <label
                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                >
                  Valor Médio
                  <span class="small" v-if="feature.measured_unit.id!==99">
                   ({{ feature.measured_unit.initials }})
                  </span>
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      disabled
                      type="text"
                      v-model="feature_item.value_medium"
                      class="form-control form-control-sm"
                    />
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                >
                  Consumo Minimo
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      v-model="feature_item.consumption"
                      class="form-control form-control-sm"
                      v-mask="['####']"
                    />
                  </base-input>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pb-0 pt-1 col-form-label form-control-label">
                  Nível de aplicação <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <validation-provider rules="required" v-slot="{errors}">
                      <PuzlSelect
                        v-model="feature_item.feature_level"
                        :items="feature_levels"
                        multiple
                        customKey="value"
                        label="label"/>
                    </validation-provider>
                  </base-input>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 col-form-label form-control-label">
                  Permite Reuso
                </label>
                <div class="col-md-7 pt-2">
                  <base-switch
                    v-model="feature_item.reuse"
                    type="success"
                    offText="não"
                    onText="sim"
                    class="primary"
                  ></base-switch>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')">
                Cancelar
              </base-button>
              <base-button
                type="success"
                native-type="submit"
                :disabled="invalid || feature_item.description == null"
                :loading="loadingSave"
              >
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import SkeletonPuzl from "@/components/SkeletonPuzl";
import PuzlSelect from "@/components/PuzlSelect.vue";
const {formatErrorValidation} = require("@/plugins");

export default {
  name: "ModalCreateItemFeature",
  components: {
    SkeletonPuzl,
    PuzlSelect
  },
  data() {
    return {
      modal: {
        title: 'Item da responsabilidade',
        open: false,
      },
      feature_levels: [
        {
          value: 1,
          label: 'Baixa'
        },
        {
          value: 2,
          label: 'Média'
        },
        {
          value: 3,
          label: 'Alta'
        },
      ],
      feature: {
        decimal_places: null,
        description: null,
        hardened_state: {
          id: null,
          short_description: null,
        },
        id: null,
        uuid: null,
        label: null,
        measured_unit: {
          id: null,
          initials: null,
          letter: null,
        },
        operator: null,
        period: null,
        period_type: null,
        short_description: null,
        status: null,
        tolerance: null,
        type: null,
      },
      feature_item: {
        description: null,
        value_sd: null,
        level_reliability: null,
        feature_level: null,
        value_z: null,
        value_medium: null,
        consumption: null,
        reuse: true,
      },
      loadingSave: false,
      loadingSkeleton: true,
    }
  },
  computed: {
    ...mapGetters({
      'reliability_levels': 'reliability_level/fetch',
    }),
  },
  methods: {
    closeModal() {
      this.modal.open = false
    },
    calculateValueMedium() {
      this.feature_item.value_z = this.feature_item.level_reliability;
      let value_medium = 0;

      let description = parseFloat(this.feature_item.description);
      let value_sd = parseFloat(this.feature_item.value_sd);
      let value_z = parseFloat(this.feature_item.level_reliability);

      value_medium = parseFloat(description + value_sd * value_z);

      if (!isNaN(value_medium)) {
        this.feature_item.value_medium = value_medium.toFixed(2);
      }
    },
    handleCreateItemFeatureModal(uuid) {
      this.$Progress.start()
      this.loadingSkeleton = true
      this.feature_item.description = null
      this.feature.type = null
      this.feature.decimal_places = null
      this.feature.consumption = null

      this.feature = {
        decimal_places: null,
        description: null,
        hardened_state: {
          id: null,
          short_description: null,
        },
        id: null,
        itens: [],
        label: null,
        measured_unit: {
          id: null,
          initials: null,
          letter: null,
        },
        operator: null,
        period: null,
        period_type: null,
        short_description: null,
        status: false,
        tolerance: null,
        type: null,
        uuid: null,
      }

      this.$store.dispatch('mixFeature/show', uuid)
        .then((response) => {
          this.feature = response.data
          this.feature_item.mix_feature_id = this.feature.id
          this.modal.open = true
          this.$notify({type: response.error_type, message: response.message})
          this.$Progress.finish()
          this.loadingSkeleton = false
        }).catch((error) => {
        if (error.response.status === 422) {
          let errors = Object.values(error.response.data.errors)
          errors = errors.flat().toString()
          this.$notify({type: 'danger', message: errors})
        }
        this.$Progress.finish()
        this.loadingSave = false
        this.loadingSkeleton = false
      })

      this.modal.open = true
    },
    save() {
      this.$Progress.start()
      this.loadingSave = true

      this.$store.dispatch('mixFeatureItem/add', this.feature_item)
        .then(response => {
          this.loadingSave = false
          this.modal.open = false

          this.feature_item = {
            mix_feature_id: null,
            description: null,
            value_sd: null,
            value_z: null,
            value_medium: null,
            consumption: null,
            reuse: true,
          }
          this.$store.dispatch('mixFeature/fetchItems')
          this.$Progress.finish()
          this.$notify({type: response.error_type, message: response.message})
        })
        .catch(error => {
          if (error.status) {
            this.$Progress.finish()
            this.loadingSave = false
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            })
          } else {
            if (error.response.status === 422) {
              let message = formatErrorValidation(error.response.data.errors)
              this.$notify({
                type: 'danger',
                message: message
              })
              this.$Progress.finish()
              this.loadingSave = false
            } else {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              })
              this.$Progress.finish()
              this.loadingSave = false
            }
          }
        })
    },
  },
  mounted() {
    this.$refs.formValidator.validate();
    this.$store.dispatch('reliability_level/fetchItems');
    this.feature_item.feature_level = this.feature_levels;
  },
}
</script>

<style scoped>

</style>
