<template>
  <div>
    <base-header class="bg-gray-content mb-n4">
      <div class="row align-items-center py-4">
        <div class="col-md-4">
          <h2 style="font-size: 1.2rem !important;" class="text-warning">
            <img height="30px" src="/img/icons/exchange.png" class="mr-3"/>
            Saldo de contas
          </h2>
        </div>
        <div class="col-md-8">
          <div class="row d-flex justify-content-end mb-3">
            <div class="mb-2 mb-md-0 col-md-3 px-1">
              <base-button block type="success" class="new-puzl-default" style="border-radius: 50px !important;"
              @click.prevent="handleModalCreateBankTransfer">
              <img height="19px" src="/img/icons/icons8/ios/initiate-money-transfer.png" alt="note" class="mr-1 mt-n1"/>
                Transferir
              </base-button>
            </div>
            <div class="mb-2 mb-md-0 col-md-3 px-1">
              <base-button block type="primary" class="new-puzl-default" style="border-radius: 50px !important;"
              @click.prevent="$router.push({ name: 'financial.bank_balance.bank_transfer' })">
              <i class="fas fa-right-left"></i>
              Transferências
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </base-header>

    <!-- Lista de Conciliações -->
    <list ref="list"></list>
    <ModalCreateBankTransfer @created="this.$refs.list.init()" ref="modalCreateBankTransfer" />
  </div>
</template>

<script>
import List from './Shared/_List'
import ModalCreateBankTransfer from "@/views/Modules/Financial/BankTransfer/Shared/_ModalCreate";

export default {
  name: "BankReconciliation",
  components: {
    List,
    ModalCreateBankTransfer,
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.$refs.list.interval_id)
    next();
  },
  methods: {
    handleModalCreateBankTransfer () {
      this.$refs.modalCreateBankTransfer.handleCreateModal()
    },
  }
};
</script>

<style></style>
