<template>
  <div>
    <div class="card mt-2">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
              <div class="row">
                <div class="col-1">
                  <img src="/img/icons/bank-building.png" width="25px" height="25px">
                </div>
                <div class="col">
                  <h4 class="m-0 pl-2" style="font-size: 16px">PIX</h4>
                </div>
              </div>
          </div>
        </div>
        <div class="form-group row m-0 p-0 mt-3">
          <label class="col-md-5 pl-0 col-form-label form-control-label" style="font-weight: 400;"> CHAVE PIX
            <span class="text-danger">&nbsp;*</span>
          </label>
          <div class="col-md-7">
            <base-input input-group-classes="input-group-sm">
              <input type="text" v-model="bill.key" disabled class="form-control form-control-sm" />
            </base-input>
          </div>
        </div>
        <div class="form-group row m-0 p-0">
          <label class="col-md-5 pl-0 col-form-label form-control-label" style="font-weight: 400;"> BANCO
            <span class="text-danger">&nbsp;*</span>
          </label>
          <div class="col-md-7">
            <base-input input-group-classes="input-group-sm">
              <input type="text" v-model="bill.banco" disabled class="form-control form-control-sm" />
            </base-input>
          </div>
        </div>
        <div class="form-group row m-0 p-0">
          <label class="col-md-5 pl-0 col-form-label form-control-label" style="font-weight: 400;"> AGÊNCIA
            <span class="text-danger">&nbsp;*</span>
          </label>
          <div class="col-md-7">
            <base-input input-group-classes="input-group-sm">
              <input type="text" v-model="bill.agencia" disabled class="form-control form-control-sm" />
            </base-input>
          </div>
        </div>
        <div class="form-group row m-0 p-0">
          <label class="col-md-5 pl-0 col-form-label form-control-label" style="font-weight: 400;"> CONTA
            <span class="text-danger">&nbsp;*</span>
          </label>
          <div class="col-md-7">
            <base-input input-group-classes="input-group-sm">
              <input type="text" disabled v-model="bill.conta" class="form-control form-control-sm" />
            </base-input>
          </div>
        </div>
        <div class="form-group row m-0 p-0 mt-4">
          <label class="col-md-5 pl-0 col-form-label form-control-label" style="font-weight: 400;"> VALOR A SER PAGO
            <span class="text-danger">&nbsp;*</span>
          </label>
          <div class="col-md-7 d-flex justify-content-end position-relative">
            <H1>{{ Number(bill.value).toLocaleString('pt-BR', { style: "currency", currency: "BRL" }) }}</H1>
            <small class="position-absolute" style="top:35px">{{ new Date(bill.due_date).toLocaleDateString('pt-BR') }}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoModalQRcode",
  props: ['bill']
}
</script>
