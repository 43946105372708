<template>
<div class="pt-3">
  <multi-filter ref="multi-filter" @fetch="init" :filter="filter" />
  <div class="container-fluid">
    <PuzlEmptyData v-if="!$_servicesDescription.length && !loadingSkeleton"></PuzlEmptyData>
    <div class="row card-wrapper pt-3">
        <div class="col-lg-4"
          v-for="(item, index) in $_servicesDescription"
          :key="index"
        >
          <!-- Basic with action button -->
          <card :class="item.status ? 'card-border-top-success' : 'card-border-top-danger'">
            <!-- Card header -->
            <div class="row align-items-center mb-3">
              <div class="col-8">
                  <h5 class="h4 mb-0">
                    {{ item.service_name }}
                  </h5>
                  <h5 class="h4 mb-0 small">
                    {{ item.description }}
                  </h5>
              </div>

              <div class="col-4 text-right">
                <base-dropdown menuOnRight>
                  <base-button
                    slot="title-container"
                    type="primary"
                    class="dropdown-toggle p-2 rounded m-0"
                  >
                    <i class="fas fa-cog"></i>
                  </base-button>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleEditServiceDescription(item)">
                    <i class="fas fa-edit text-warning"></i>
                    Editar
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleDeleteServiceDescription(item.uuid)">
                    <i class="fas fa-times text-danger"></i>
                    Excluir
                  </a>
                </base-dropdown>
              </div>
            </div>
            <!-- Card body -->
          </card>
        </div>
      </div>
      <loading-pagination :show="loading && !loadingSkeleton"/>
      <ModalEditServiceDescription ref="ModalEditServiceDescription"/>
  </div>
</div>
</template>
<script>
import {
    mapGetters
} from 'vuex'
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import ModalEditServiceDescription from './_ModalEdit';
import MultiFilter from '@/components/Utils/MultiFilter.vue';
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
export default {
    name: "ListServiceDescription",
    mixins: [cursorPaginate],
    components: {
        LoadingPagination,
        SkeletonPuzlGrid,
        PuzlEmptyData,
        ModalEditServiceDescription,
        MultiFilter,
    },
    data() {
        return {
            loadingSkeleton: false,
            filter: {},
        }
    },
    mounted() {
        this.filter.status = 1;
        this.init({});
        EventBus.$on("getServiceDescription", () => {
          this.init();
        });
    },
    computed: {
        ...mapGetters({
            '$_servicesDescription': 'serviceDescription/fetch',
        }),
    },
    watch: {
        'added'() {
            this.init()
        }
    },
    props: {
        added: Boolean
    },
    methods: {
        init(filter = null) {
            this.startCursor(filter)
            this.$Progress.start();
            this.$store.dispatch('serviceDescription/fetch', {
                    filter: this.filter,
                    next_page: this.paginate.nextUrl
                })
                .then(response => {
                    this.$Progress.finish()
                    this.resolveCursor(response)
                })
                .catch((error) => {
                    this.resolveCursor()
                    this.$Progress.finish()
                })
        },
        handleDeleteServiceDescription(id) {
            this.$Swal.confirmDelete().then((result) => {
                if (result.isConfirmed) {
                    this.$Progress.start();
                    this.$notify({
                        type: 'info',
                        message: 'Estamos trabalhando em sua solicitação.'
                    });
                    this.$store.dispatch('serviceDescription/destroy', id).then((response) => {
                        this.$notify({
                            type: response.error_type,
                            message: response.message
                        })
                        this.$Progress.finish()
                        this.init()
                    }).catch((error) => {
                      if (error.response.status === 500) {
                        this.$notify({
                            type: 'danger',
                            message: 'Existem preços de serviço vinculados a este registro, exclusão não permitida.'
                        });
                      }
                      this.$notify({
                          type: error.data.error_type,
                          message: error.data.message
                      });
                    })
                }
            }).catch(() => this.$Progress.finish())
        },
        handleEditServiceDescription(id) {
          this.$refs.ModalEditServiceDescription.handleEditModal(id)
        },
    },
}
</script>

<style scoped>

</style>