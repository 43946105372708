<template>
  <div class="card-body mt-n2 p-0">
    <div class="row">
      <ShadedTable
        :length="$_concretes.length"
        :headers="headers"
        :loading="loadingSkeleton"
      >
        <template v-for="(item, index) in $_concretes" :slot="index">
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.driver_name }}
            </h5>
          </ShadedCol>
          <ShadedCol :isVisible="hasEquipment">
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.equipment_code }}
            </h5>
          </ShadedCol>
          <ShadedCol :isVisible="activateGroupingByPlant">
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.company_plant_name }}
            </h5>
          </ShadedCol>
          <ShadedCol align="center">
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.total_driver_travels }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ floatToHuman(item.total_volume_travels) }}
            </h5>
          </ShadedCol>
        </template>
      </ShadedTable>
    </div>
  </div>
</template>

<script>
import SkeletonPuzl from "@/components/SkeletonPuzl";
import ShadedTable from "@/components/ShadedTable.vue";
import ShadedCol from "@/components/ShadedCol.vue";
import { mapGetters } from "vuex";
import { floatToHuman } from "@/helpers";

export default {
  name: "TableConcretesResume",
  data() {
    return {
      floatToHuman: floatToHuman,
    };
  },
  props: {
    loadingSkeleton: Boolean,
    activateGroupingByPlant: Boolean,
    hasEquipment: Boolean,
  },
  computed: {
    ...mapGetters({
      $_concretes: "driverResume/getConcreteItems",
    }),
    /**
     * Retorna um array de cabeçalhos para exibição na tabela.
     * @returns {Array}
     */
    headers() {
      let headers = ["Motorista", "Viagens", "Volume"];
      // Verifica e adiciona agrupamento por central ou equipamento na segunda coluna.
      if (this.activateGroupingByPlant) {
        headers.splice(1, 0, "Central");
      }
      if (this.hasEquipment) {
        headers.splice(1, 0, "Equipamento");
      }
      return headers;
    },
  },
  components: {
    SkeletonPuzl,
    ShadedTable,
    ShadedCol,
  },
};
</script>
