<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(update)" autocomplete="off">
            <div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 col-form-label form-control-label">
                  Status
                </label>
                <div class="col-md-7 pt-2">
                  <base-switch v-model="type.status" type="success" offText="inativo" onText="ativo" class="success"></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Descrição
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="type.description"
                        class="form-control form-control-sm"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Categoria
                </label>
                <div class="col-md-7">
                  <base-input input-classes="form-control-sm">
                    <PuzlSelect
                      v-model="type.category_id"
                      :items="categories"
                      label="category_name"
                      :multiple="true"
                      :loading="isloadingListCategory"
                      :disabled="isloadingListCategory" />
                  </base-input>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mt-2">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                Código Externo (TOTVS) 
                </label>
                <div class="col-md-7">
                  <validation-provider rules="numeric">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        maxlength="10"
                        inputmode="numeric"
                        v-model="type.totvs_external_code"
                        class="form-control form-control-sm"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
const {formatErrorValidation} = require("@/plugins")
export default {
  name: "ModalEdit",
  components: { PuzlSelect },
  data () {
    return {
      isloadingListCategory: false,
      modal: false,
      title: 'Tipo de Equipamento',
      type: {
        status: true,
        description: '',
        category_id: '',
      },
      loadingStore: false,
    }
  },
  computed: {
    ...mapGetters({
      categories: "equipmentCategory/fetch"
    })
  },
  methods: {
    closeModal () {
      this.modal = false
    },
    handleEditModal (uuid) {
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'})
      this.$Progress.start()
      this.loadData()
      this.$store.dispatch('equipmentType/show', uuid)
        .then( (response) => {
          this.type = {
            uuid: response.data.uuid || '',
            status: response.data.status || false,
            description: response.data.description || '',
            category_id: response.data.category_selected || '',
            totvs_external_code: response.data.totvs_external_code || null,
          }
          this.modal = true
          this.$notify({type: response.error_type, message: response.message})
          this.$Progress.finish()
        }).catch((error) => {
          if(error.response.status===422){
            let errors = Object.values(error.response.data.errors)
            errors = errors.flat().toString().split(',').join("<br />")
            this.$notify({ type: 'danger', message: errors })
          }
          this.$Progress.finish()
        this.loadingStore = false
      })
    },
    update () {
      this.$Progress.start()
      this.loadingStore = true
      this.$store.dispatch('equipmentType/update', this.type)
        .then(response => {
          this.loadingStore = false
          this.modal = false
          this.$Progress.finish()
          this.$notify({ type: response.error_type, message: response.message })
        })
        .catch(error =>{
          if (error.status) {
            this.$Progress.finish()
            this.loadingStore = false
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            })
          } else {
            if (error.response.status === 422) {
              let message = formatErrorValidation(error.response.data.errors)
              this.$notify({
                type: 'danger',
                message: message
              })
              this.$Progress.finish()
              this.loadingStore = false
            } else {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              })
              this.$Progress.finish()
              this.loadingStore = false
            }
          }
        })
    },
    loadData() {
      this.isloadingListCategory = true;
      this.$store.dispatch("equipmentCategory/fetchItems").then(response => {
        this.isloadingListCategory = false;
      }).catch(error => {});
    },
  },
}
</script>

<style scoped>

</style>
