<template>
  <div>
    <div v-for="item in $_items" :key="item.id" class="category-list-item mt-3 d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <!-- STATUS -->
        <div @click="changeStatus(item.id)" class="status-button pointer" :class="item.is_active === 'y' ? 'active-status' : 'disabled-status'">
          <div>
            <img
              :src="item.is_active === 'y' ? '/img/icons/ok--v1.png' : '/img/icons/icons8/ios/ok--v1_danger.png'"
              class="mr-1"
              width="18"
            />

            {{ item.is_active === "y" ? "ATIVO" : "INATIVO" }}
          </div>
        </div>
        <label class="new-default-black-font mb-0">
          {{ item.name }}
          <span v-if="item.external_code">(Cód. {{ item.external_code }}) </span>
        </label>
      </div>
      <div class="d-flex align-items-center">
        <!-- Editar -->
        <EditItem :item="item" />

        <!-- EXCLUIR -->
        <img
          v-if="item.created_by_user_id"
          role="button"
          @click="destroy(item.id)"
          height="22px"
          src="/img/icons/icons8/ios/trash_can.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EditItem from "./_EditItem.vue";
export default {
  name: "List",
  computed: {
    ...mapGetters({
      $_items: "paymentBrand/fetch",
    }),
  },
  components: {
    EditItem,
  },
  methods: {
    destroy(id) {
      this.$Swal.confirmDelete().then(async (result) => {
        if (result.isConfirmed) {
          const loader = this.$loading.show();
          await this.$store.dispatch("paymentBrand/destroy", id);
          loader.hide();
          this.$notify({ type: "success", message: "Bandeira removida!" });
        }
      });
    },

    async changeStatus(id) {
      const loader = this.$loading.show();
      await this.$store.dispatch("paymentBrand/changeStatus", id);
      loader.hide();
      this.$notify({ type: "success", message: "Status atualizado!" });
    },
  },
};
</script>

<style scoped>
.category-list-item {
  box-shadow: 0px 5px 10px 0px #0000000d;
  border: 0.5px solid #e8e8e8;
  border-radius: 5px;
  padding: 8px 12px;
}

.active-status {
  background-color: #f2f7f3;
  color: #149E57;
}

.disabled-status {
  background-color: #fcf3f3;
  color: #DB4539;
}

.status-button {
  width: fit-content;
  padding: 8px 12px;
  border-radius: 16px;
  display: flex;
  align-items: center;
}
</style>
