<template>
  <div>
    <modal :size="type === 1 ? 'md' : 'xxl'" :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <template v-if="Object.values(adjustments).length">
        <div class="row p-0">
        <div class="mb-4 col-8 pl-4">
          <base-button size="sm" @click.prevent="type = 1" :class="type === 1 && 'active'" outline type="default">
            Simples
          </base-button>
          <base-button size="sm" @click.prevent="type = 2" :class="type === 2 && 'active'" outline type="primary">
            Composto
          </base-button>
        </div>
        <div class="col-4 text-right pr-4" v-if="adjustments.mixe && adjustments.mixe.user_name && adjustments.mixe.user_name">
          <el-popover trigger="click" placement="bottom"
                      class="p-0 col-1 mr-1">
            <div>
              <div class="col-12">
                        <span>
                          <span class="h4">
                            {{ adjustments.mixe.user_name }}
                          </span>
                          <br>
                          <span>
                            {{ adjustments.mixe.updated_mixe_at | parseDate }}
                          </span>
                        </span>
              </div>
            </div>
            <div slot="reference" size="sm" type="secundary" style="box-shadow: none;">
              <img class="pointer" src="/img/icons/icons8/ios/user-male-circle--v1.png" width="26px" height="26px" />
            </div>
          </el-popover>
        </div>
        </div>
        <div v-show="type === 1" class="col-12">
          <div class="row mb-4">
            <div class="col-4">
              <h6> &nbsp;&nbsp;</h6>
              <small style="font-size: 11px">Aglomerante <br></small>
              <small style="font-size: 11px">Água <br></small>
              <small style="font-size: 11px">T.Arg. (V) <br></small>
              <small style="font-size: 11px">Ar Total <br></small>
              <br>
              <small style="font-size: 11px" v-for="groupName in adjustments.groups"> {{ groupName.name }} <br></small>
            </div>
            <div class="col-4 text-right">
              <h6>Formulação</h6>
              <small>{{ adjustments.technical_parameters.binder }} kg/m³ <br></small>
              <small>{{ adjustments.technical_parameters.water }} kg/m³ <br></small>
              <small>{{ adjustments.technical_parameters.content_mortar }} % <br></small>
              <small>{{ adjustments.technical_parameters.total_air }} % <br></small>
              <br>
              <small v-for="groupValue in adjustments.groups">{{ groupValue.value }} % <br></small>
            </div>
            <div class="col-4 border-3 border-left border-muted">
              <div class="">
                <h6>Ajustado</h6>
                <small>{{ adjustments.technical_parameters.conditioning_binder }} kg/m³ <br></small>
                <small>{{ adjustments.technical_parameters.conditioning_water }} kg/m³ <br></small>
                <small>{{ adjustments.technical_parameters.conditioning_content_mortar.toFixed(1) }} % <br></small>
                <small>{{ adjustments.technical_parameters.conditioning_total_air }} % <br></small>
                <br>
                <small
                  v-for="conditioningGroup in adjustments.conditioning_groups">{{
                    conditioningGroup.value > 0 ? parseFloat(conditioningGroup.value).toFixed(2) : 0
                  }}
                  % <br></small>
              </div>
            </div>
          </div>
          <div v-show="adjustments.adjustments.length > 0" class="col-12 text-right">
            <input v-model="only_adjustments"
                   type="checkbox" checked=""
                   autocomplete="off" class="mr-2">
            <label>Exibir apenas com ajustes</label>
          </div>
          <div class="row mb-2 col-12 el-table--scrollable-y" style="height: 40vh;overflow-x: auto;">
            <div class="timeline timeline-one-side mb-4">
              <div v-if="validOnlyAdjustment(item)" class="timeline-block"
                   v-for="(item, index) in adjustments.adjustments">
                <template v-if="item">
                  <span class="timeline-step" :class="validAdjustment(item)"
                        :style="!item.block_percentage && !item.adjust && 'background-color: #D0D0D0'"> {{
                      item.position
                    }} </span>
                  <div class="timeline-content" style="width: 150%">
                    <small class="d-flex">{{ item.title }}
                      <el-popover v-if="item.user" trigger="click" placement="bottom"
                                  class="p-0 col-1 mr-1">
                        <div>
                          <div class="col-12">
                        <span>
                          <span class="h4">
                            {{item.user}}
                          </span>
                          <br>
                          <span>
                            {{ item.updated_adjustment_at | parseDate }}
                          </span>
                        </span>
                          </div>
                        </div>
                        <div slot="reference" size="sm" type="secundary" style="box-shadow: none;">
                          <img class="ml-2 pointer" src="/img/icons/icons8/ios/user-male-circle--v1.png" width="26px" height="26px" />
                        </div>
                      </el-popover>
                      <br></small>
                    <div style="margin-top: -10px" class="text-primary">
                    <small>
                      {{ item.subtitle }}
                    </small>
                    </div>
                    <div class="row">
                      <div class="col"><small :class="!item.binder ? 'text-muted' : ''">Aglomerante</small></div>
                      <div v-if="item.binder" class="col"><small><small
                        :class="validPositive(item.binder)">{{ item.binder }}</small> <small class="font-weight-bold">kg/m³</small></small>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col"><small :class="!item.water ? 'text-muted' : ''">Água</small></div>
                      <div v-if="item.water" class="col "><small><small
                        :class="validPositive(item.water)">{{ item.water }}</small> <small class="font-weight-bold">kg/m³</small></small>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col"><small :class="!item.content_mortar ? 'text-muted' : ''">T. Arg. (V)</small>
                      </div>
                      <div v-if="item.content_mortar" class="col "><small> <small
                        :class="validPositive(item.content_mortar)">{{ item.content_mortar }}</small> <small
                        class="font-weight-bold">%</small></small>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col"><small :class="!item.total_air ? 'text-muted' : ''">Ar Total</small></div>
                      <div v-if="item.total_air" class="col "><small><small
                        :class="validPositive(item.total_air)">{{ item.total_air }}</small> <small
                        class="font-weight-bold">%</small></small>
                      </div>
                    </div>
                    <div class="row mt-1" v-for="itemGroup in item.groups">
                      <div v-if="itemGroup.id && adjustments.groups[itemGroup.id]" class="col"><small
                        style="font-size: 10px">{{ itemGroup.name }} </small>
                      </div>
                      <div v-if="itemGroup.id && adjustments.groups[itemGroup.id]" class="col"><small><small
                        :class="validPositive(itemGroup.value)">{{ itemGroup.value }}</small> <small
                        class="font-weight-bold">%</small></small>
                      </div>
                    </div>
                    <div class="row" v-if="item.quantities && item.quantities.started != item.quantities.final">
                      <div class="col-md-12">
                        <small>Teor inicial:
                          <span class="text-default">{{ item.quantities.started }}%</span>
                        </small>
                      </div>
                      <div class="col-md-12">
                        <small>Teor ajustado:
                          <span
                            :class="item.quantities.final > item.quantities.started ? 'text-success' : 'text-danger'">{{
                              item.quantities.final
                            }}%</span>

                          ({{ item.quantities.final - item.quantities.started }}%)</small>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div v-show="type === 2">
          <div class="row">
            <div class="col-12">
              <div class=" table-responsive">
                <table class="table table-sm">
                  <thead>
                  <tr class="thead-light">
                    <th>
                    Ajuste
                    </th>
                    <th>
                     Dados do carregamento
                    </th>
                    <th>
                     Aglomerante (kg/m³)
                    </th>
                    <th>
                      Água (kg/m³)
                    </th>
                    <th>
                      T. Arg. (V)
                    </th>
                    <th>
                     Ar total (%)
                    </th>
                    <th>
                     Aditivo A (%)
                    </th>
                    <th>
                     Aditivo B (%)
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="tr-small" v-if="item" v-for="(item, key) in adjustments.adjustments">
                    <th :class="validTextAdjustment(item)">{{ item.title_compound || item.title }}</th>
                    <th>{{ item.subtitle_compound || item.subtitle }}</th>
                    <th class="text-center" :class="validPositive(item.binder)">{{ item.binder || '-' }}</th>
                    <th class="text-center" :class="validPositive(item.water)">{{ item.water || '-' }}</th>
                    <th class="text-center" :class="validPositive(item.content_mortar)">{{
                        item.content_mortar || '-'
                      }}
                    </th>
                    <th class="text-center" :class="validPositive(item.total_air)">{{ item.total_air || '-' }}</th>
                    <th v-for="group in item.groups">
                              <span v-if="group.id">
                              {{ group.name }} ({{ group.value }}%)
                              </span>
                      <span v-else>
                                -
                              </span>
                    </th>
                    <th v-if="item.groups.length < 2" v-for="newItem in verifyNewLength(item.groups.length)">
                      -
                    </th>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  components: {},
  name: "ModalAdjustment",
  data() {
    return {
      modal: false,
      title: 'Ajustes',
      type: 0,
      technical_parameter: null,
      OldGroups: [],
      adjustedGroups: [],
      only_adjustments: false,
    }
  },
  computed: {
    ...mapGetters({
      adjustments: 'travels/adjustment',
    }),
  },
  methods: {
    openModal() {
      this.type = 1
      this.modal = true
    },
    validPositive(value) {
      if (value === 0) {
        return 'font-weight-bold text-muted'
      }
      if (value > 0) {
        return 'font-weight-bold text-success'
      }
      return 'font-weight-bold text-danger'
    },
    setGroups(params) {
      this.adjustedGroups = params.adjusted_groups
      return this.OldGroups = params.groups
    },
    verifyNewLength(length) {
      return 2 - length
    },
    validOnlyAdjustment(item) {
      if (this.only_adjustments) {
        if (item && item.adjust) {
          return true
        } else {
          return false
        }
      }
      if (item) {
        return true
      }
    },
    validAdjustment(item) {
      if (item.block || item.block_percentage) {
        return 'badge-danger'
      }
      if (!item.adjust) {
        return null
      }
      return 'badge-success'
    },
    close: function () {
      this.modal = false
      this.$parent.modal_shown = false
    },
    validTextAdjustment(item) {
      if (item.block) {
        return 'text-danger'
      }
      if (!item.adjust) {
        return 'text-muted'
      }
      return 'text-success'
    },
  }
}
</script>

<style>
.tr-small > th, td {
  font-size: 11px !important;
}

.tr-small > th > span, td > span {
  font-size: 11px !important;
}
</style>
