import { toMoney } from "../../../../helpers";

export const baseScatterChart = {
  color: ["#91cc75", "#5470c6", "#fac858"],
  legend: {
    top: 10,
    data: ["Real", "Teórico", "Previsto"],
    textStyle: {
      fontSize: 16
    }
  },
  grid: {
    left: "5%",
    right: 150,
    top: "18%",
    bottom: "10%"
  },
  tooltip: {
    backgroundColor: "rgba(255,255,255,1)",
    formatter: function(param) {
      let value = param.value;
      return `
        <div style="border-bottom: 1px solid rgba(255,255,255,.3); font-size: 14px;padding-bottom: 3px;margin-bottom: 7px">
        ${param.marker} ${param.seriesName}  </div> <b> ${value[7]} </b> <br/>
        Volume: <b>${value[2]} m³</b> <br/>
        Preço de venda: <b>R$ ${value[4]} / m³ </b> <br/>
        MCC: <b>R$ ${value[3]} / m³ </b> <br/>
        Margem bruta: <b>R$ ${value[0]} / m³ </b> <br/>
        Margem / hora: <b>R$ ${value[1]} / hr </b> <br/>
        Adicionais: <b>R$ ${value[5]} / m³ </b> <br/>
      `;
    }
  },
  xAxis: {
    type: "value",
    name: "Margem bruta",
    nameGap: 16,

    nameTextStyle: {
      fontSize: 12
    },
    splitLine: {
      show: false
    }
  },
  yAxis: {
    type: "value",
    name: "Margem / hora",
    nameLocation: "end",
    nameGap: 20,
    nameTextStyle: {
      fontSize: 12
    },
    splitLine: {
      show: false
    }
  },
  visualMap: [
    {
      left: "right",
      top: "28%",
      dimension: 2,
      min: 0,
      itemWidth: 30,
      itemHeight: 120,
      calculable: true,
      precision: 0.1,
      text: ["Volume  "],
      textGap: 15,
      inRange: {
        symbolSize: [10, 70]
      },
      outOfRange: {
        symbolSize: [10, 70],
        color: ["rgba(255,255,255,0.4)"]
      },
      controller: {
        inRange: {
          color: ["#c23531"]
        },
        outOfRange: {
          color: ["#999"]
        }
      }
    }
  ],
  series: [
    {
      name: "Real",
      type: "scatter",
      itemStyle: {
        opacity: 0.8,
        shadowBlur: 10,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        shadowColor: "rgba(0,0,0,0.3)"
      },
      data: []
    },
    {
      name: "Teórico",
      type: "scatter",
      itemStyle: {
        opacity: 0.8,
        shadowBlur: 10,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        shadowColor: "rgba(0,0,0,0.3)"
      },
      data: []
    },
    {
      name: "Previsto",
      type: "scatter",
      itemStyle: {
        opacity: 0.8,
        shadowBlur: 10,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        shadowColor: "rgba(0,0,0,0.3)"
      },
      data: []
    }
  ]
};

const modes = ["Total"];

export const baseTreemapChart = {
  options: {
    animation: false,
    title: {
      top: 5,
      left: "center",
      text: "Distribuição de valores"
    },
    legend: {
      data: modes,
      selectedMode: "single",
      top: 55,
      itemGap: 5,
      borderRadius: 5
    },
    tooltip: {},
    series: modes.map(function(mode, idx) {
      let seriesOpt = createSeriesCommon(idx);
      seriesOpt.name = mode;
      seriesOpt.top = 80;
      seriesOpt.visualDimension = idx === 2 ? 2 : undefined;
      seriesOpt.data = [];
      seriesOpt.levels = getLevelOption(idx);
      return seriesOpt;
    })
  }
};

function createSeriesCommon(mode) {
  return {
    type: "treemap",
    tooltip: {
      formatter: getTooltipFormatter(mode)
    },
    label: {
      position: "insideTopLeft",
      formatter: function(params) {
        let arr = ["{name|" + params.name + "}", "{hr|}", "{budget| " + toMoney(params.value[0] ? params.value[0] : 0 ) + "} {label|Faturamento}"];
        return arr.join("\n");
      },
      rich: {
        budget: {
          fontSize: 22,
          lineHeight: 30,
          color: "yellow"
        },
        household: {
          fontSize: 14,
          color: "#fff"
        },
        label: {
          fontSize: 9,
          backgroundColor: "rgba(0,0,0,0.3)",
          color: "#fff",
          borderRadius: 2,
          padding: [2, 4],
          lineHeight: 25,
          align: "right"
        },
        name: {
          fontSize: 12,
          color: "#fff"
        },
        hr: {
          width: "100%",
          borderColor: "rgba(255,255,255,0.2)",
          borderWidth: 0.5,
          height: 0,
          lineHeight: 10
        }
      }
    },
    itemStyle: {
      borderColor: "black"
    },
    levels: getLevelOption(0)
  };
}
function getTooltipFormatter(mode) {
  return function(info) {
    if (!Array.isArray(info.value)) {
      return "";
    }
    return [
      '<div class="tooltip-title"><b>' + info.name + "</b></div>",
      "Faturamento: <b>" + toMoney(info.value[0]) + "</b><br>",
      "Volume: <b>" + info.value[1] + " m³</b><br>",
      "Serviço total: <b>" + toMoney(info.value[2]) + "</b><br>",
      "Mcc total: <b>" + toMoney(info.value[4]) + "</b><br>",
      "Adicionais: <b>" + toMoney(info.value[5])
    ].join("");
  };
}
function getLevelOption(mode) {
  return [
    {
      color:
        mode === 2
          ? ["#c23531", "#314656", "#61a0a8", "#dd8668", "#91c7ae", "#6e7074", "#61a0a8", "#bda29a", "#44525d", "#c4ccd3"]
          : undefined,
      colorMappingBy: "id",
      itemStyle: {
        borderWidth: 3,
        gapWidth: 3
      }
    },
    {
      colorAlpha: mode === 2 ? [0.5, 1] : undefined,
      itemStyle: {
        gapWidth: 1
      }
    }
  ];
}
function buildData(mode, originList) {
  let out = [];
  for (let i = 0; i < originList.length; i++) {
    let node = originList[i];
    let newNode = cloneNodeInfo(node);
    if (!newNode) {
      continue;
    }
    out[i] = newNode;
    let value = newNode.value;

    value[3] = 0;
    if (mode === 1) {
      let tmp = value[1];
      value[1] = value[0];
      value[0] = tmp;
    }
    if (node.children) {
      newNode.children = buildData(mode, node.children);
    }
  }
  return out;
}
function cloneNodeInfo(node) {
  if (!node) {
    return;
  }
  const newNode = {};
  newNode.name = node.name;
  newNode.id = node.id;
  newNode.value = (node.value || []).slice();
  return newNode;
}

export const changeScatterChart = (payload, data) => {
  payload = !Number.isInteger(payload) ? 0 : payload;
  switch (payload) {
    case 0:
      data = data.constructions;
      break;
    case 1:
      data = data.sellers;
      break;
    case 2:
      data = data.plants;
      break;
  }
  let scatterChart = baseScatterChart;
  /** Real */
  scatterChart.series[0].data = data[0];
  /** Teórico */
  scatterChart.series[1].data = data[1];
  /** Previsto */
  scatterChart.series[2].data = data[2];
  return scatterChart;
};

export const changeTreemapChart = (payload, data) => {
  payload = !Number.isInteger(payload) ? 0 : payload;
  data = payload === 0 ? data.plants : data.sellers;
  let treemapChart = baseTreemapChart;
  treemapChart.options.series[0].data = data[0].children.length > 0 ? data : [];
  return treemapChart.options;
};

export const calcTreemapItems = payload => {
  let data = {
    sellers: [],
    plants: []
  };

  payload.map(function(obj) {
    let item = { ...obj };
    let new_proposals = [];
    obj.contract_proposals.map(function(contract_proposal) {
      let current_proposal = new_proposals.find(item => item.id == contract_proposal.id);
      /**
       * Caso a proposta não exista no array geral.
       */
      if (!current_proposal) {
        let values = [];
        let total_billing = parseFloat(contract_proposal.total_billing);
        contract_proposal.name = contract_proposal.construction_name;
        values.push(total_billing);
        values.push(parseFloat(contract_proposal.total_volume));
        /** Iniciando valores em 0 */
        for (let i = 0; i < 4; i++) {
          values.push(0);
        }
        switch (contract_proposal.type) {
          case 0:
             /** Serviço total */
            values[2] = parseFloat(contract_proposal.total_real_brutal_margin);
            /** Mcc total */
            values[4] = parseFloat(contract_proposal.total_charged_cost);
            break;
          /** Adicionais */
          case 2:
            values[5] = total_billing;
            break;
        }
        contract_proposal.value = values;
        new_proposals.push(contract_proposal);
      } else {
        let total_billing = parseFloat(contract_proposal.total_billing);
        /** Faturamento */
        current_proposal.value[0] += total_billing;
        /** Volume total */
        current_proposal.value[1] += parseFloat(contract_proposal.total_volume);
        switch (contract_proposal.type) {
          case 0:
            /** Serviço total */
            current_proposal.value[2] += parseFloat(contract_proposal.total_real_brutal_margin);
            /** Mcc total */
            current_proposal.value[4] += parseFloat(contract_proposal.total_charged_cost);
            break;
          /** Adicionais */
          case 2:
            current_proposal.value[5] += total_billing;
            break;
        }
      }
    });
    item.children = new_proposals;
    /**Caso seja vendedor */
    if (obj.type === 1) {
      data.sellers.push(item);
    } else {
      data.plants.push(item);
    }
  });
  return data;
};
