<template>
<div>
  <base-header class="bg-gray-content">
    <div class="row align-items-center py-4">
      <div class="col-lg-12">
        <h6 class="h2 text-gray d-inline-block mb-0">{{ $route.name }}</h6>
        <nav
          aria-label="breadcrumb"
          class="d-none d-md-inline-block ml-md-4 text-gray"
        >
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i></a></li>
            <li class="breadcrumb-item"><a href="#">Cadastro</a></li>
            <li class="breadcrumb-item" aria-current="page">{{ $route.name }}</li>
          </ol>
        </nav>
      </div>
    </div>
    <!-- Header Menus -->
    <div class="row pl-2">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-2 col-xlg-2 mb-3 pr-3 pl-2">
            <router-link
              :to="{
                  path:'/commercial/concrete-price/parameter',
                  name:'Parâmetros de preços mínimo de concreto'
                }"
            >
              <base-button block size="lg" type="light" class="text-uppercase">
                <i class="fas fa-undo-alt"></i>
                VOLTAR
              </base-button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </base-header>
</div>

</template>
<script>
export default {
  data() {
    return {
    }
  },
  methods: {
  },
  created(){
  },
  mounted() {
  }
};
</script>
