<template>
  <div class="container-fluid">
    <div class="mt-5">
      <div class="row">
        <div class="col-12 pl-2">
          <div style="display: inline-block; width: 180px">
            <puzl-select
              @change="init({})"
              :clearable="false"
              v-model="filter.view_type"
              :items="view_options"
              class="mx-2 select-sm new-default-black-font"
            />
          </div>
        </div>
      </div>
      <multi-filter ref="multi-filter"
                    :showSlotDates="false"
                    :showSlotData="false"
                    :defaultRange="range"
                    @handleFilterDate="handleFilterDate"
                    :filterable_type="1"
                    :filter="filter"
                    @fetch="init"
      >
        <template slot="bookmarks">
          <div class="col-12 pb-1">
            <base-button type="success" size="sm"
                         outline
                         block
                         :class="filter.is_manual && 'active'"
                         @click="filter.is_manual = Number(!filter.is_manual);init({})"

            >
              <i class="fa-regular fa-user"></i> LANÇAMENTO MANUAL
            </base-button>
          </div>
          <div class="col-12">
            <base-button type="danger" size="sm"
                         outline
                         block
                         :class="filter.deleted_items && 'active'"
                         @click="filter.deleted_items = Number(!filter.deleted_items);init({})"
            >
              <i class="fa-regular fa-trash-can"></i> REGISTROS EXCLUÍDOS
            </base-button>
          </div>
        </template>
      </multi-filter>
      <div class="card">
        <div class="card-body mt-n2 p-0">
          <div class="row">
            <div @click.prevent="downloadExcel()" class="col-md-12 pt-4 text-right" style="padding-right: 2rem">
              <img class="pointer" src="/img/icons/excel.png" style="width: 25px;">
            </div>
            <ShadedTable class="px-4 pt-2"
                         :length="$_stock_movements.length"
                         :headers="headers"
                         :loading="loadingSkeleton">
              <template v-for="(item, key) in $_stock_movements" :slot="key">
                <ShadedCol>
                  <div class="text-center">
                    <h5 class="m-0">
                      {{ item.date_at | parseDate('DD/MM/YYYY') }}
                    </h5>
                  </div>
                </ShadedCol>
                <ShadedCol>
                  <div class="text-center">
                    <h5 class="m-0" :class="item.origin_class">
                      {{ item.origin_description }}
                    </h5>
                  </div>
                </ShadedCol>
                <ShadedCol>
                  <div class="text-center">
                    <h5 class="m-0">
                      {{ item.company_plant_name }}
                    </h5>
                  </div>
                </ShadedCol>
                <ShadedCol>
                  <div class="text-center">
                    <h5 class="m-0">
                      {{ item.dosage_location || 'Sem local' }}
                    </h5>
                  </div>
                </ShadedCol>
                <ShadedCol>
                  <div class="text-center">
                    <h5 class="m-0">
                      {{ item.document || '-' }}
                    </h5>
                  </div>
                </ShadedCol>
                <ShadedCol>
                  <div class="text-center">
                    <h5 class="m-0">
                      {{ item.supplier_name || '-' }}
                    </h5>
                  </div>
                </ShadedCol>
                <ShadedCol>
                  <div class="text-center">
                    <h5 class="m-0">
                      {{ item.product_name }}
                    </h5>
                  </div>
                </ShadedCol>
                <ShadedCol>
                  <div class="text-center">
                    <h5 class="m-0">
                      {{ item.group_name }}
                    </h5>
                  </div>
                </ShadedCol>
                <ShadedCol>
                  <div class="text-center">
                    <h5 class="m-0">
                      {{ Math.abs(item.quantity).toLocaleString('pt-BR') }} {{ item.unit_type }}
                    </h5>
                  </div>
                </ShadedCol>
                <ShadedCol>
                  <div class="text-center">
                    <h5 class="m-0">
                      {{ format(item.amount_stock) }} {{ item.unit_stock_type }}
                    </h5>
                  </div>
                </ShadedCol>
                <ShadedCol>
                  <div class="text-center">
                    <h5 class="m-0">
                      {{ item.total | currency() }}
                    </h5>
                  </div>
                </ShadedCol>
                <ShadedCol>
                  <div class="text-center">
                    <base-dropdown-v2 size="sm" :disabled="disableIfHasStock(item.date_at)">
                      <base-dropdown-item @click="handleEditItem(item.id)">
                        <img width="19px" height="19px" src="/img/icons/create-new.png"/>
                        Editar
                      </base-dropdown-item>
                      <base-dropdown-item @click="handleReplicate(item.id)">
                        <img width="19px" height="19px" src="/img/icons/icons8/ios/replicate-primary.png"/>
                        Replicar
                      </base-dropdown-item>
                      <base-dropdown-item @click="handleRemoveItem(item.id)">
                        <img width="19px" height="19px" class="mr-2" src="/img/icons/icons8/ios/delete--v1_danger.png">
                        Excluir
                      </base-dropdown-item>
                    </base-dropdown-v2>
                  </div>
                </ShadedCol>
              </template>
            </ShadedTable>
          </div>
          <puzl-empty-data :with-card="false" v-if="!$_stock_movements.length"/>
          <modal-edit-stock-movement ref="ModalEditStockMovement"/>
          <modal-replicate-stock-movement ref="ModalReplicateStockMovement"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ShadedTable from "@/components/ShadedTable.vue";
import ShadedCol from "@/components/ShadedCol.vue";
import {mapGetters} from "vuex";
import PuzlEmptyData from "@/components/PuzlEmptyData.vue";
import MultiFilter from "@/components/Utils/MultiFilterV3.vue";
import cursorPaginate from "@/mixins/cursorPaginate";
import BaseDropdownV2 from '@/components/Utils/BaseDropdown.vue'
import BaseDropdownItem from "@/components/Utils/BaseDropdownItem.vue";
import ModalEditStockMovement from './_ModalEditStockMovement.vue';
import moment from "moment";
import PuzlSelect from '@/components/PuzlSelect.vue'
import ModalReplicateStockMovement from './_ModalReplicateStockMovement.vue'

export default {
  components: {
    PuzlEmptyData,
    ShadedCol,
    ShadedTable,
    MultiFilter,
    BaseDropdownV2,
    BaseDropdownItem,
    ModalEditStockMovement,
    PuzlSelect,
    ModalReplicateStockMovement
  },
  mixins: [cursorPaginate],
  data() {
    return {
      headers: [
        "Data",
        "Tipo",
        "Central",
        "Local",
        "Documento",
        "Fornecedor",
        "Item",
        "Grupo",
        "Lançamento",
        "Estoque",
        "Total",
        "Ações",
      ],
      range: {
        start: new Date(),
        end: new Date(),
      },
      loading: false,
      loadingSkeleton: false,
      filter: {
        view_type: null,
        is_manual: 0,
        deleted_items: 0,
      },
      view_options: [
        {name: 'Todos', id: null},
        {name: 'Venda', id: 1},
        {name: 'Compra', id: 2},
        {name: 'Ajuste de entrada', id: 3},
        {name: 'Ajuste de saída', id: 4},
        {name: 'Quebra positiva', id: 5},
        {name: 'Quebra negativa', id: 6},
      ]
    }
  },
  computed: {
    ...mapGetters({
      $_stock_movements: "stockMovement/fetch",
      $_latest_closing: "stockClosing/getLatestClosingAt",
    })
  },
  methods: {
    handleRemoveItem(stock_movement_id) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          let loader = this.$loading.show()
          this.$store.dispatch('stockMovement/remove', stock_movement_id).then((response) => {
            loader.hide()
            this.$notify({
              type: response.error_type,
              message: response.message
            });
          })
        }
      }).catch(() => this.$Progress.finish())
    },
    downloadExcel() {
      this.$Progress.start();
      let loader = this.$loading.show()
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.$store.dispatch('stockMovement/downloadExcel', this.filter).then(response => {
        loader.hide()
        this.$Progress.finish()
        this.$notify({
          type: response.error_type,
          message: response.message
        });
      })
    },
    handleEditItem(stock_movement_id) {
      this.$refs.ModalEditStockMovement.handleCreateModal(stock_movement_id)
    },
    handleReplicate(stock_movement_id) {
      this.$refs.ModalReplicateStockMovement.handleCreateModal(stock_movement_id)
    },
    async updateLatestStock() {
      await this.$store.dispatch('stockClosing/getLatestClosingAt')
    },
    init(filter = null) {
      this.startCursor(filter)
      if (this.filter.view_type == null) {
        delete this.filter.view_type
      }
      this.$store.dispatch('stockMovement/fetchItems',
        {
          filter: this.filter,
          per_page: 3,
          next_page: this.paginate.nextUrl
        }).then(response => {
        this.resolveCursor(response)
      }).catch(error => {
        this.resolveCursor()
      })
    },
    handleFilterDate(filter) {
      this.range = filter
      this.init({})
    },
    disableIfHasStock(date_at) {
      if (this.$_latest_closing && this.$_latest_closing.closing_at) {
        if (moment(date_at) <= moment(this.$_latest_closing.closing_at)) {
          return true
        }
      }
      return false
    },
    format(value) {
      return Math.round(Math.abs(value)).toLocaleString('pt-BR')
    },
  },
  async mounted() {
    this.loadingSkeleton = true
    this.filter.deleted_items = 0
    this.filter.is_manual = 0
    await this.updateLatestStock()
    await this.init({})
  }
}
</script>
