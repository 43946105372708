<template>
<div>
    <multi-filter ref="multi-filter" @fetch="load" :filter="filter" />
    <div class="container-fluid">
        <div class="row card-wrapper" v-show="loadingSkeleton">
            <SkeletonPuzlGrid v-for="index in 3" :key="index"/>
        </div>
        <!-- Dados vazios -->
        <PuzlEmptyData v-if="!items.length && !loadingSkeleton"/>
        <div class="row card-wrapper" v-if="items.length && !loadingSkeleton">
            <div class="col-lg-4" v-for="(item, index) in items" :key="index">
                <card :class="item.status ? 'card-border-top-success' : 'card-border-top-danger'">
                    <!-- Card header -->
                    <div class="row align-items-center mb-3">
                        <div class="col-9 justify-content-center pr-0">
                            <h5 class="h3 mb-1">{{item.name}}</h5>
                            <h4 v-if="item.type" class="mb-0 font-weight-normal">{{item.type.name}}</h4>
                            <div class="mt-2">
                                <span v-if="item.require_equipment_apportionment">  
                                    <i class="fa-solid fa-check text-success"></i>
                                </span>
                                <span v-else>  
                                    <i class="fa-solid fa-ban text-danger"></i>
                                </span>
                                <span class="small"> Exige rateio de equipamento </span>
                            </div>
                            <div>
                                <span v-if="item.is_fuel">
                                    <i class="fa-solid fa-check text-success"></i>
                                </span>
                                <span v-else>  
                                    <i class="fa-solid fa-ban text-danger"></i>
                                </span>
                                 <span class="small"> Itens de abastecimento </span>
                            </div>
                        </div>
                        
                        <div class="col-3 text-right pl-0 mt-n5">
                            <base-dropdown menuOnRight>
                                <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                                    <i class="fas fa-cog"></i>
                                </base-button>
                                <div class="dropdown-divider p-0 m-0"></div>
                                <a class="dropdown-item" @click="handleEditProductServiceCategory(item.id)">
                                    <i class="fas fa-edit text-warning main-icon-menu"></i>
                                    Editar
                                </a>
                                <div class="dropdown-divider p-0 m-0"></div>
                                <a class="dropdown-item" @click="handleDeleteProductServiceCategory(item.id)">
                                    <i class="fas fa-times text-danger main-icon-menu"></i>
                                    Excluir
                                </a>
                            </base-dropdown>
                        </div>
                    </div>
                </card>
            </div>
        </div>
    </div>
    <ModalEditProductServiceCategory ref="modalEditProductServiceCategory"/>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import ModalEditProductServiceCategory from './_ModalEdit';
import MultiFilter from '@/components/Utils/MultiFilter.vue';
export default {
    name: "ListProductServiceCategory",
    components: {
        SkeletonPuzlGrid,
        PuzlEmptyData,
        ModalEditProductServiceCategory,
        MultiFilter,
    },
    data() {
        return {
            loadingSkeleton: false,
            filter: {},
        }
    },
    computed: {
        ...mapGetters({
            items: 'productServiceCategory/fetch',
        }),
    },
    methods: {
        load(filter = {}) {
            this.filter = filter
            this.fetch();
        },
        fetch() {
            this.$Progress.start();
            this.loadingSkeleton = true
            this.$store.dispatch('productServiceCategory/fetchItems', { filter: this.filter })
                .then((response) => {
                     this.$Progress.finish()
                     this.loadingSkeleton = false
                })
                .catch((error) => {
                    this.$Progress.finish()
                    this.loadingSkeleton = false
                })
        },
        handleDeleteProductServiceCategory(id) {
            this.$Swal.confirmDelete().then((result) => {
                if (result.isConfirmed) {
                    this.$Progress.start();
                    this.$notify({
                        type: 'info',
                        message: 'Estamos trabalhando em sua solicitação.'
                    });
                    this.$store.dispatch('productServiceCategory/destroy', id).then((response) => {
                        this.$notify({
                            type: response.error_type,
                            message: response.message
                        })
                        this.$Progress.finish()
                    }).catch((error) => {
                        this.$notify({
                            type: error.data.error_type,
                            message: error.data.message
                        });
                    })
                }
            }).catch(() => this.$Progress.finish())
        },
        handleEditProductServiceCategory(id) {
            this.$refs.modalEditProductServiceCategory.handleEditModal(id)
        },
    },
    mounted() {
        this.filter.status = 1;
        this.$refs['multi-filter']._self.$forceUpdate();
        this.fetch();
    },
}
</script>

<style scoped>
.main-icon-menu {
    min-width: 18px;
}
.color-icon-true {
    color: green;
}
.color-icon-false {
    color: red;
}
</style>
