<template>
  <div>
    <modal size="md" :show="show">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <template v-if="contractProposal">
        <div class="checklist-item checklist-item-primary mb-2">
          <div class="checklist-info">
            <h4 class="checklist-title mb-0">
              Obra:
              <span class="small">
                                {{
                  contractProposal.construction
                  && contractProposal.construction.construction_name
                }}
                            </span>
            </h4>
            <h4 class="checklist-title mb-0">
              Cliente:
              <span class="small">
                                {{
                  contractProposal.construction
                  && contractProposal.construction.customer_construction.customer_name
                }}
                            </span>
            </h4>
            <h4 class="checklist-title mb-0">
              Cód. Contrato:
              <span class="small">{{ contractProposal.code }}</span>
            </h4>
          </div>
        </div>
      </template>
      <div class="row">
        <div class="col-md-12">
          <input-date-picker
            ref="date-picker" @handleFilterDate="handleFilterDate" :default-range="range"/>
        </div>
      </div>
      <div class="form-group row mt-4 pb-0">
        <label class="col-md-6 col-form-label form-control-label">
          Exibir horários
        </label>
        <div class="col-md-6 pt-2">
          <base-switch v-model="params.show_time" type="success" offText="NÃO" onText="SIM" class="success"
          >
          </base-switch>
        </div>
        <label class="col-md-6 col-form-label form-control-label pt-1">
          Exibir todas?
        </label>
        <div class="col-md-6 pt-0">
          <base-switch v-model="show_all" type="success" offText="NÃO" onText="SIM"
                       class="success"
          >
          </base-switch>
        </div>
        <div class="col-md-12 text-right mt-2">
          <div class="">
            <base-button type="secondary" @click="close()"
            >Cancelar
            </base-button
            >
            <base-button
              :loading="isLoading"
              type="primary"
              native-type="submit"
              @click.prevent="handleGenerateReport()"
            ><i class="fa-regular fa-eye"></i> Visualizar
            </base-button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import InputDatePicker from "@/components/InputDatePicker";
import moment from "moment";

export default {
  components: {InputDatePicker},
  props: ['show'],
  name: "ModalReport",
  data() {
    return {
      isLoading: false,
      params: {
        show_time: true,
        show_dont_have_accordance: false,
      },
      show_all: false,
      range: {
        start: moment().startOf("month").format("YYYY-MM-DD hh:mm:ss"),
        end: moment().endOf("month").format("YYYY-MM-DD hh:mm:ss"),
      },
      title: 'Gerar relatório'
    }
  },
  watch: {
    'show': function () {
      this.show_all = false
      this.range = {
        start: moment().startOf("month").format("YYYY-MM-DD hh:mm:ss"),
        end: moment().endOf("month").format("YYYY-MM-DD hh:mm:ss"),
      }
    }
  },
  computed: {
    contractProposal: function () {
      return this.$store.state.contractProposal.selected
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    handleFilterDate(filter) {
      this.range = filter
    },
    handleGenerateReport() {
      let loader = this.$loading.show()
      this.isLoading = true
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch('testReport/getShowReport', {
        contract_proposal_id: this.contractProposal.id,
        range: this.range,
        show_time: +this.params.show_time,
        show_all: Number(this.show_all),
      }).then(response => {
        let blob = new Blob([response],
          {type: 'application/pdf'})
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute("download", 'report.pdf');
        loader.hide()
        this.isLoading = false
        this.$notify({
          type: "success",
          message: "Solicitação realizada com sucesso!",
        })
        window.open(link, '_blank')
        this.close()
      })
    }
  }
}
</script>

<style scoped>

</style>
