<template>
  <div>
    <modal :show.sync="modal.open">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(update)" autocomplete="off">
            <div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 col-form-label form-control-label">
                  Status
                </label>
                <div class="col-md-7 pt-2">
                  <base-switch v-model="situation.status" type="success" offText="inativo" onText="ativo" class="success"></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Nome
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="situation.name"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Observações
                </label>
                <div class="col-md-7">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="situation.observation"
                        class="form-control form-control-sm"
                      />
                    </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-5 col-form-label form-control-label">
                  Informa motivo de perda/cancelamento
                </label>
                <div class="col-md-7 pt-2">
                  <base-switch v-model="situation.has_cancellation_reason" type="success" class="success"></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-5 col-form-label form-control-label">
                  Informa concorrente
                </label>
                <div class="col-md-7 pt-2">
                  <base-switch v-model="situation.has_competitor" type="success" class="success"></base-switch>
                </div>
              </div>


            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: "ModalEditSituation",
  data () {
    return {
      modal: {
        title: 'Situação de proposta/contrato',
        open: false,
      },
      situation: {
        status: true,
        name: null,
        observation: null,
        has_cancellation_reason: false,
        has_competitor: false,
      },
      loadingStore: false,
    }
  },
  computed: {
  },
  methods: {
    closeModal () {
      this.modal.open = false
    },
    handleEditModal (uuid) {
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'})
      this.$Progress.start()
      this.$store.dispatch('contractSituation/show', uuid)
        .then( (response) => {
          this.situation = {
            uuid: response.data.uuid || '',
            status: response.data.status || false,
            name: response.data.name || '',
            observation: response.data.observation || '',
            has_cancellation_reason: response.data.has_cancellation_reason || false,
            has_competitor: response.data.has_competitor || false,
          }
          this.modal.open = true
          this.$notify({type: response.error_type, message: response.message})
          this.$Progress.finish()
        }).catch((error) => {
          if(error.response.status===422){
            let errors = Object.values(error.response.data.errors)
            errors = errors.flat().toString().split(',').join("<br />")
            this.$notify({ type: 'danger', message: errors })
          }
          this.$Progress.finish()
        this.loadingStore = false
      })
    },
    update () {
      this.$Progress.start()
      this.loadingStore = true
      this.$store.dispatch('contractSituation/update', this.situation)
        .then(response => {
          this.loadingStore = false
          this.modal.open = false
          this.$Progress.finish()
          this.$notify({ type: response.error_type, message: response.message })
        })
        .catch(error =>{
          if(error.response.status===422){
            let errors = Object.values(error.response.data.errors)
            errors = errors.flat().toString().split(',').join("<br />")
            this.$notify({ type: 'danger', message: errors })
          }
          this.$Progress.finish()
          this.loadingStore = false
        })
    },
  },
  mounted () {
    this.$refs.formValidator.validate();
  },
  created () {
  }
}
</script>

<style scoped>

</style>
