const keys = Object.freeze({
  ALL: 0,
  IS_ENTRY_VALUE: 1,
  IS_OUTPUT_VALUE: 2,
});

const getTitle = (value) => {
  switch (value) {
    case keys.ALL:
      return "Todos";
    case keys.IS_ENTRY_VALUE:
      return "Entradas";
    case keys.IS_OUTPUT_VALUE:
      return "Saídas";
    default:
      return "";
  }
};

export default {
  keys,
  getTitle,
};
