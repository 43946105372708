<template>
  <div>
    <base-header class="bg-gray-content">
      <!-- Start Breadcrumb -->
      <PuzlBreadcrumb/>
      <!-- End Breadcrumb -->
      <!-- Header Menus -->
      <div class="row">
        <div class="col-md-2  col-xlg-2 mb-3">
         <back-button/>
        </div>
      </div>
    </base-header>
    <div class="container-fluid">
      <div class="row card-wrapper">
        <div class="col-lg-12">
          <card class="p-0">
            <div class="row align-items-center p-0 mb-4">

              <div class="col-md-6">
                <label class="form-control-label">
                  Central
                </label>
                    <base-input input-classes="form-control-sm">
                      <el-select
                        v-model="requestPayload.company_plant_id"
                        size="mini"
                        placeholder="Selecione"
                        filterable
                        :loading="loadingCompanyPlant"
                        :disabled="loadingCompanyPlant"
                      >
                        <el-option label="Selecione" value=""></el-option>
                        <el-option
                          v-for="plant in plants"
                          :key="plant.id"
                          :label="plant.name"
                          :value="plant.id"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
              </div>

              <div class="col-md-6">
                <label class="form-control-label">
                  Ensaio Estado Endurecido
                </label>
                <base-input input-classes="form-control-sm">
                  <el-select
                    v-model="requestPayload.hardened_state_id"
                    size="small"
                    filterable
                    placeholder="selecione"
                    :loading="loadingHardenedState"
                    :disabled="loadingHardenedState"
                  >
                    <el-option
                      v-for="hardened_state in hardened_states"
                      :key="hardened_state.id"
                      :label="hardened_state.short_description"
                      :value="hardened_state.id"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-5 text-center">
                <div class="card p-2">
                  <div>
                    <div class="row">
                      <div class="col-md-12 d-flex justify-content-center">
                        <h4 class="text-center">Local de amostragem</h4>
                      </div>
                      <div class="col-md-12 d-flex justify-content-center">
                        <el-checkbox-group
                          v-model="payload.molding_location_id"
                          :max="1"
                          size="medium"
                        >
                            <span
                              class="text-center d-flex flex-column d-block"
                              v-for="molding_location in molding_locations"
                              :key="molding_location.id"
                            >
                              <el-checkbox-button
                                :label="molding_location.id"
                              >
                                {{ molding_location.name }}
                              </el-checkbox-button>
                            </span>
                        </el-checkbox-group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-7 text-center">
                <div class="card p-2">
                  <div>
                    <h4 class="h3">Preparo de base (capeamento)</h4>
                    <div class="row">
                      <div class="col-md-6">
                        <h5 class="h3 text-center pl-5">BASE INFERIOR</h5>
                        <el-checkbox-group
                          v-model="payload.lower_base"
                          :max="2"
                          size="medium"
                        >
                          <ul>
                            <li
                              class="text-center"
                              v-for="base_preparation in base_preparations"
                              :key="base_preparation.id"
                            >
                              <el-checkbox-button
                                :label="base_preparation.id"
                              >
                                {{ base_preparation.description }}
                              </el-checkbox-button>
                            </li>
                          </ul>
                        </el-checkbox-group>
                      </div>

                      <div class="col-md-6">
                        <h5 class="h3 text-center pl-5">BASE SUPERIOR</h5>
                        <el-checkbox-group
                          v-model="payload.upper_base"
                          :max="2"
                          size="medium"
                        >
                          <ul>
                            <li
                              class="text-center"
                              v-for="base_preparation in base_preparations"
                              :key="base_preparation.id"
                            >
                              <el-checkbox-button
                                :label="base_preparation.id"
                              >
                                {{ base_preparation.description }}
                              </el-checkbox-button>
                            </li>
                          </ul>
                        </el-checkbox-group>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 offset-md-8">
                    <base-button
                      v-show="!validate"
                      :loading="storeLoading"
                      disabled
                      block
                      size="lg"
                      type="success"
                    >
                      salvar
                    </base-button>
                    <base-button
                      v-show="validate"
                      :loading="storeLoading"
                      block
                      size="lg"
                      type="success"
                      @click.prevent="store()"
                    >
                      salvar
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
      <div class="row align-items-center" v-show="showMoldingPreparation">
        <div class="col-md-12 text-center">
          <div class="card p-4">
            Selecione os itens acima para escolher o preparo!
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import BackButton from "../../../../components/Utils/BackButton";
const {formatErrorValidation} = require("@/plugins")
export default {
  components: {
    BackButton,
    PuzlBreadcrumb
  },
  data() {
    return {
      payload: {
        company_plant_id: '',
        hardened_state_id: '',
        molding_location_id: [],
        upper_base: [],
        lower_base: [],
      },
      requestPayload: {
        company_plant_id: '',
        hardened_state_id: '',
      },
      showMoldingPreparation: false,
      validate: false,
      storeLoading: false,
      loadingCompanyPlant: true,
      loadingHardenedState: true,
      loadingMoldingLocation: true,
      loadingBasePrepation: true,
    };
  },
  computed: {
    ...mapGetters({
      plants: 'plant/activeItems',
      hardened_states: 'testHardenedState/fetch',
      molding_locations: "moldingLocation/fetch",
      base_preparations: "basePreparation/fetch",
    }),
  },
  watch: {
    'requestPayload.company_plant_id': function () {
      this.getMoldingCapping()
    },
    'requestPayload.hardened_state_id': function () {
      this.getMoldingCapping()
    },
  },
  methods: {
    closeModal (name) {
      this.modal[name] = false
    },
    openModal (name) {
      this.modal[name] = true
    },
    getMoldingCapping(){
      this.payload = {
        company_plant_id: '',
        hardened_state_id: '',
        molding_location_id: [],
        upper_base: [],
        lower_base: [],
      };
      if(this.requestPayload.company_plant_id!='' && this.requestPayload.hardened_state_id!=''){
        this.storeLoading = true;
        this.$Progress.start();
        this.$store.dispatch('moldingCapping/show',this.requestPayload ).then(({data}) => {
          if(data !=''){
            let upper_base = JSON.parse("[" + data[0].upper_base + "]");
            let lower_base = JSON.parse("[" + data[0].lower_base + "]");
            let molding_location_id = JSON.parse("[" + data[0].molding_location_id + "]");
            this.payload = {
              company_plant_id: data[0].company_plant_id,
              hardened_state_id: data[0].hardened_state_id,
              molding_location_id: molding_location_id,
              upper_base: upper_base,
              lower_base: lower_base,
            }
          }
          this.storeLoading = false
          this.validate = true;
          this.$Progress.finish();
        })
      }
    },
    store(){
      this.$Progress.start();
      this.storeLoading = true;
      this.payload.company_plant_id = this.requestPayload.company_plant_id;
      this.payload.hardened_state_id = this.requestPayload.hardened_state_id;
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});

      this.$store.dispatch('moldingCapping/add',this.payload)
        .then(response => {
          this.payload = {
            company_plant_id: '',
            hardened_state_id: '',
            molding_location_id: [],
            upper_base: [],
            lower_base: [],
          };
          this.requestPayload = {
            company_plant_id: '',
            hardened_state_id: '',
          }
          this.storeLoading = false;
          this.$Progress.finish()
          this.$notify({ type: response.error_type, message: response.message })
        })
        .catch(error =>{
          if (error.status) {
            this.$Progress.finish()
            this.storeLoading = false
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            })
          } else {
            if (error.response.status === 422) {
              let message = formatErrorValidation(error.response.data.errors)
              this.$notify({
                type: 'danger',
                message: message
              })
              this.$Progress.finish()
              this.storeLoading = false
            } else {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              })
              this.$Progress.finish()
              this.storeLoading = false
            }
          }
        })
    },
  },
  mounted() {
    this.$store.dispatch('plant/fetchItemsActive').then(() => {
      this.loadingCompanyPlant = false
    })
    this.$store.dispatch('testHardenedState/fetchItems').then(() => {
      this.loadingHardenedState = false
    })
    this.$store.dispatch('moldingLocation/fetchItems').then(() => {
      this.loadingMoldingLocation = false
    })
    this.$store.dispatch('basePreparation/fetchItems').then(() => {
      this.loadingBasePrepation = false
    })
  }
};

</script>
<style></style>
