<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <!-- Identificação -->
            <section>
              <div class="card-header border-header-default-bottom p-2 grid-col--fixed-left">
                <h5 class="mb-0" slot="title">Identificação</h5>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 col-form-label form-control-label"> Status </label>
                <div class="col-md-7 pt-2">
                  <base-switch v-model="taxes.status" type="success" offText="inativo" onText="ativo" class="success"></base-switch>
                </div>
              </div>
              <!-- Nome do tributo -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pt-1 pb-0 col-form-label form-control-label">
                  Nome do tributo
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="taxes.name"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Plano de contas -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pt-1 pb-0 col-form-label form-control-label"> Plano de contas </label>
                <div class="col-md-7">
                  <validation-provider>
                    <base-input input-classes="form-control form-control-sm">
                      <PuzlSelect
                        v-model="taxes.chart_account_id"
                        :items="$_chart_accounts"
                        :labelMask="true"
                        :disabled="loadingChartAccounts"
                        :loading="loadingChartAccounts"
                        label="code$ - $name"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Incide sobre NFS-e -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 col-form-label form-control-label">
                  Incide sobre NFS-e
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7 pt-2">
                  <base-switch v-model="taxes.nfse" type="success" offText="Não" onText="Sim" class="success"></base-switch>
                </div>
              </div>
              <!-- Incide sobre NF-e-->
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 col-form-label form-control-label">
                  Incide sobre NF-e
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7 pt-2">
                  <base-switch v-model="taxes.nfe" type="success" offText="Não" onText="Sim" class="success"></base-switch>
                </div>
              </div>
              <!-- Abrangência -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pt-1 pb-0 col-form-label form-control-label">
                  Abrangência
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required">
                    <base-input input-classes="form-control form-control-sm">
                      <puzl-select v-model="taxes.coverage" :items="coverages" label="text" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Observações -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pb-0 pt-1 col-form-label form-control-label"> Observações </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <textarea type="text" v-model="taxes.observation" rows="2" class="form-control form-control-sm"> </textarea>
                  </base-input>
                </div>
              </div>
            </section>
            <!-- TOP Para Retenção de Imposto -->
            <section>
              <div class="card-header border-header-primary-bottom p-2 grid-col--fixed-left mt-3 mb-2">
                <h5 class="mb-0" slot="title">TOP Para Retenção de Imposto</h5>
              </div>
              <h4 class="mb-1 text-danger col-12">Entradas, Despesas e Custos</h4>
              <!-- Imposto retido | empresa paga -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pt-1 pb-0 col-form-label form-control-label">
                  Imposto retido | empresa paga
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7 justify-content-center align-self-center">
                  <validation-provider rules="required">
                    <base-input input-classes="form-control form-control-sm">
                      <PuzlSelect
                        v-model="taxes.operation_source_id_for_withheld_entry_tax"
                        :items="$_operation_sources"
                        :disabled="loadingOperationSourceLoading"
                        :loading="loadingOperationSourceLoading" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <h4 class="mb-1 mt-3 text-success col-12">Saídas, Vendas e Receitas</h4>
              <!-- Imposto retido | cliente paga -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pt-1 pb-0 col-form-label form-control-label">
                  Imposto retido | cliente paga
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7 justify-content-center align-self-center">
                  <validation-provider rules="required">
                    <base-input input-classes="form-control form-control-sm">
                      <PuzlSelect
                        v-model="taxes.operation_source_id_for_withheld_exit_tax"
                        :items="$_operation_sources"
                        :disabled="loadingOperationSourceLoading"
                        :loading="loadingOperationSourceLoading" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Imposto devido | empresa paga -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pt-1 pb-0 col-form-label form-control-label ">
                  Imposto devido | empresa paga
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7 justify-content-center align-self-center">
                  <validation-provider rules="required">
                    <base-input input-classes="form-control form-control-sm">
                      <PuzlSelect 
                        v-model="taxes.operation_source_id_for_owing_exit_tax" 
                        :items="$_operation_sources"
                        :disabled="loadingOperationSourceLoading"
                        :loading="loadingOperationSourceLoading" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
            </section>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">Cancelar </base-button>
              <base-button type="success" native-type="submit" :disabled="invalid" :loading="loadingStore">Salvar </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
const { formatErrorValidation } = require("@/plugins");
export default {
  name: "ModalCreate",
  components: { PuzlSelect },
  data() {
    return {
      modal: {
        title: "Taxas e tributos",
        create: false,
      },
      loadingStore: false,
      coverages: [
        {
          id: "MUN",
          text: "Municipal",
        },
        {
          id: "EST",
          text: "Estadual",
        },
        {
          id: "FED",
          text: "Federal",
        },
      ],
      taxes: {
        status: true,
        name: null,
        observation: null,
        chart_account_id: null,
        nfse: true,
        nfe: false,
        coverage: null,
        operation_source_id_for_owing_exit_tax:null,
        operation_source_id_for_withheld_exit_tax:null,
        operation_source_id_for_withheld_entry_tax:null,
      },
      loadingService: false,
      loadingChartAccounts: true,
      loadingOperationSourceLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      $_chart_accounts: "chartAccount/fetch",
      $_operation_sources: "operationSource/fetch",
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    handleCreateModal() {
      this.loadData();
      this.cleanModal();
      this.modal.create = true;
    },
    cleanModal() {
      this.taxes = {
        status: true,
        name: null,
        observation: null,
        chart_account_id: null,
        nfse: true,
        nfe: false,
        coverage: null,
        operation_source_id_for_owing_exit_tax:null,
        operation_source_id_for_withheld_exit_tax:null,
        operation_source_id_for_withheld_entry_tax:null,
      };
    },
    store() {
      this.loadingStore = true;
      this.$Progress.start();
      this.$store
        .dispatch("FeesAndTaxes/add", this.taxes)
        .then((response) => {
          this.modal.create = false;
          this.$notify({ type: response.error_type, message: response.message });
        })
        .catch((error) => {
          if (error.response.status === 422) {
            let message = formatErrorValidation(error.response.data.errors);
            this.$notify({ type: "danger", message: message });
          } else {
            this.$notify({ type: error.data.error_type, message: error.data.message });
          }
        })
        .finally(() => {
          this.$Progress.finish();
          this.loadingStore = false;
        });
    },
    loadData() {
      this.loadingChartAccounts = true;
      this.$store.dispatch('chartAccount/fetchItems').then(() => {
        this.loadingChartAccounts = false;
      });
      
      // product_service_hub_type_id 5 = Tributos Automáticos
      this.loadingOperationSourceLoading = true;
      this.$store.dispatch("operationSource/fetchItems", { filter: { type: "0", product_service_hub_type_id: 5 } })
        .then(() => {
          this.loadingOperationSourceLoading = false;
        });
    },
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
};
</script>

<style scoped></style>
