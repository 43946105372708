import * as types from "./mutation_types";
import { createAxios } from "@/plugins/axios";;
const endPoint = "/operational/schedule-travel-gantt+microservice";
export default {
  namespaced: true,
  state: {
    headers: null,
    items: [],
  },
  getters: {
    getHeaders: (state) => state.headers,
    getItems: (state) => state.items,
  },
  mutations: {
    [types.SET_HEADERS](state, payload) {
      state.headers = payload;
    },
    [types.SET_ITEMS](state, payload) {
      state.items = payload;
    },
  },
  actions: {
    getHeaders({ commit }, params) {
      return createAxios()
        .get(`${endPoint}/headers`, { params: params })
        .then(({ data }) => {
          commit(types.SET_HEADERS, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getItems({ commit }, params) {
      return createAxios()
        .get(`${endPoint}/items`, { params: params })
        .then(({ data }) => {
          commit(types.SET_ITEMS, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
};
