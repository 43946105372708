<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
            <!-- Start Mix Groups -->
            <!-- <div class="card-header border-header-primary-bottom mt-3 mb-2 p-2">
              <h5 class="h4 mb-0" slot="title">Adicionar grupo</h5>
            </div> -->
            <div class="row mt-3">
              <div class="col-6">
                <!-- <span
                  class="btn btn-sm btn-success p-1"
                  @click.prevent="handleCreateGroup()"
                >
                  <i class="fas fa-plus"></i> NOVO
                </span> -->
              </div>
              <div class="col-6 d-flex justify-content-end">
                <span
                  class="btn btn-sm btn-primary p-1"
                  @click.prevent="handleMixDesignGroupHistory"
                >
                  <i class="fas fa-history"></i> HISTÓRICO
                </span>
              </div>
            </div>
            <div class="card-header border-header-primary-bottom mt-3 mb-2 p-2">
              <h5 class="h4 mb-0" slot="title">Grupos</h5>
            </div>

            <div v-for="(category, indexCategory) in groups" :key="indexCategory">
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pt-1 pb-1 col-form-label form-control-label">
                  {{ category.category_name }}
                  <span class="text-danger" v-show="category.calculable && indexCategory !== '3' && indexCategory !== '2'">*</span>
                </label>
                <div class="col-md-8">
                  <validation-provider :rules="validCategory(indexCategory)">
                    <base-input input-classes="form-control-sm">
                      <el-select size="mini" filterable multiple placeholder="Selecione" @change="unSetPuzlAdmixture"
                                 v-model="set_group[indexCategory]">
                        <el-option v-for="(group, indexGroup) in category.groups" :key="indexGroup" :label="group"
                                   :value="indexGroup">
                        </el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
            </div>
            <!-- End CMC Groups -->

            <div class="card-header border-header-warning-bottom mt-3 mb-2 p-2">
              <h5 class="h4 mb-0 text-warning" slot="title">Puzl Admix</h5>
            </div>
            <div class="form-group row m-0 p-0">
              <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                Puzl Admix
              </label>
              <div class="col-md-8 pl-2">
                <base-input input-classes="form-control-sm">
                  <el-select v-model="set_group[99]" size="mini" @change="setPuzlAdmixture()" filterable multiple
                             placeholder="Selecione">
                    <el-option v-for="puzl_admixture in puzl_admixtures" :key="puzl_admixture.cmc_group.id"
                               :label="puzl_admixture.cmc_group.group_name"
                               :value="puzl_admixture.cmc_group.id.toString()">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </div>

            <div class="modal-footer">
              <base-button type="secondary" @click="openModal()">
                Cancelar
              </base-button>

              <base-button type="success" native-type="submit"
                           v-bind:disabled="invalid" :loading="loadingSave">
                Salvar
              </base-button>
            </div>

          </form>
        </validation-observer>
      </div>
    </modal>
    <ModalGroupCreate ref="modalCreateGroup" @fetchGroupMixDesigns="fetch"/>
    <ModalGroupHistory ref="modalGroupHistory"/>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex'
import ModalGroupCreate from '../../Cmc/Group/Shared/_Create';
import ModalGroupHistory from './_ModalGroupHistory.vue';

export default {
  components: {
    ModalGroupCreate,
    ModalGroupHistory,
  },
  name: "ModalGroup",
  data() {
    return {
      modal: false,
      title: 'Grupos',
      set_group: {},
      mix_design_id: '',
      validated: false,
      loadingSave: false,
    };
  },
  computed: {
    ...mapGetters({
      'groups': 'cmcGroup/groups',
      'puzl_admixtures': 'mixPuzlAdmixture/fetch',
      'selected_groups': 'MixDesigns/showGroups',
    }),
  },
  watch: {
    modal() {
      this.setSelected()
    }
  },
  methods: {
    handleCreateGroup() {
      this.$refs.modalCreateGroup.openModal(true)
    },
    handleMixDesignGroupHistory() {
      this.$refs.modalGroupHistory.openModal(this.mix_design_id)
    },
    async fetch() {
      this.$refs.formValidator.validate();
      await this.$store.dispatch('cmcGroup/fetchGroups')
      await this.$store.dispatch('mixPuzlAdmixture/fetchItems')
      await this.$store.dispatch('MixDesigns/fetchGroupByMixDesignUuid', this.$route.params.mix_design_uuid)
      this.setSelected()
    },
    async openModal() {
      let loader = this.$loading.show()
      await this.fetch()
      loader.hide()
      this.modal = !this.modal
    },
    handleCreate(mix_design) {
      this.mix_design_id = mix_design.id
      this.set_group = {}
      this.openModal();
    },
    validCategory(category) {
      if ([1, 6].includes(Number(category))) {
        return 'required'
      }
    },
    save() {
      let params = {
        mix_design_id: this.mix_design_id,
        groups_per_category: this.set_group
      }
      this.$Progress.start()
      this.loadingSave = true

      let hasAg = false;
      if (params.groups_per_category[3] && params.groups_per_category[3].length > 0) {
        hasAg = true
      } else {
        hasAg = false
      }
      this.$store.dispatch('MixDesigns/groups', params)
        .then(response => {
          let params = {groups: this.selected_groups, has_ag: hasAg}
          this.$emit('submit', params)
          this.loadingSave = false
          this.modal = false
          this.set_group = {}
          this.$Progress.finish()
          this.$notify({
            type: response.error_type,
            message: response.message
          })
        })
        .catch(error => {
          if (error.response.status === 422) {
            let errors = Object.values(error.response.data.errors)
            errors = errors.flat().toString().split(',').join("<br />")

            this.$notify({
              type: 'danger',
              message: errors
            })
          }
          this.$Progress.finish()
          this.loadingSave = false
        })
    },
    unSetPuzlAdmixture() {
      if (this.set_group[5] && this.set_group[99]) {
        for (let index = 0; index < this.set_group[5].length; index++) {
          if (this.set_group[99].includes(this.set_group[5][index].toString())) {
            this.set_group[99].splice(this.set_group[99].indexOf(this.set_group[5][index]), 1);
          }
        }
      }
    },
    setPuzlAdmixture() {
      if (this.set_group[99] && this.set_group[5]) {
        for (let index = 0; index < this.set_group[99].length; index++) {
          if (this.set_group[5].includes(this.set_group[99][index].toString())) {
            this.set_group[5].splice(this.set_group[5].indexOf(this.set_group[99][index]), 1);
          }
        }
      }
    },
    setSelected() {
      let category = this.selected_groups
      if (!Object.values(category).length) {
        return
      }
      for (var index in category) {
        this.set_group[index] = category[index].position.flat().toString().split(',')
      }
    },
  },
  created() {
  },
  mounted() {
    this.fetch()
  }
};
</script>

<style></style>
