<template>
<div>
  <multi-filter ref="multi-filter" @fetch="init" :filter="filter" />
  <div class="container-fluid">
    <div class="row card-wrapper" v-show="loadingSkeleton">
      <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
    </div>
    <PuzlEmptyData v-if="!situations.length && !loadingSkeleton"></PuzlEmptyData>
    <div class="row card-wrapper" v-if="situations.length && !loadingSkeleton">
      <div class="col-lg-4" v-for="(situation, index) in situations" :key="index">
        <!-- Basic with action button -->
        <card :class="situation.status ? 'card-border-top-success' : 'card-border-top-danger'">
          <!-- Card header -->
          <div class="row align-items-center mb-3">
            <div class="col-8">
              <!-- Title -->
              <h5 class="h3 mb-0">{{ situation.name }}</h5>
            </div>
            <div class="col-4 text-right">
              <base-dropdown menuOnRight>
                <base-button
                  slot="title-container"
                  type="primary"
                  class="dropdown-toggle p-2 rounded m-0"
                >
                  <i class="fas fa-cog"></i>
                </base-button>
                <div class="dropdown-divider p-0 m-0"></div>
                <a class="dropdown-item" @click="handleEditType(situation.uuid)">
                  <i class="fas fa-edit text-warning"></i>
                  Editar
                </a>
                <div class="dropdown-divider p-0 m-0"></div>
                <a class="dropdown-item" @click="handleDeleteType(situation.uuid)">
                  <i class="fas fa-times text-danger"></i>
                  Excluir
                </a>
              </base-dropdown>
            </div>
          </div>
          <!-- Card body -->
          <collapse class="border rounded p-0 mt-3">
            <collapse-item
              class="header-gray"
              back-ground="border-header-primary-bottom"
            >
              <h5 slot="title" class="mb-0 ">Complementos</h5>

              <div class="row align-items-center mb-3">
                <div class="col-12">
              <span v-if="situation.has_cancellation_reason" class="p-2">
                Informa motivo de perda/cancelamento
              </span><br>
                  <span v-if="situation.has_competitor" class="p-2">
                Informa concorrente
              </span>
                </div>
              </div>

            </collapse-item>
          </collapse>

          <collapse class="border rounded p-0 mt-3">
            <collapse-item
              class="header-gray"
              back-ground="border-header-danger-bottom"
            >
              <h5 slot="title" class="mb-0 ">Observações</h5>

              <div class="row align-items-center mb-3" v-if="situation.observation">
                <div class="col-12">
              <span class="text-body p-2">
                {{ situation.observation }}
              </span>
                </div>
              </div>

            </collapse-item>
          </collapse>
        </card>
      </div>
    </div>
    <loading-pagination :show="loading && !loadingSkeleton"/>
  </div>
  <edit-situation ref="editSituation"></edit-situation>
</div>
</template>

<script>
import {mapGetters} from 'vuex'
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import EditSituation from './_Edit'
import MultiFilter from '@/components/Utils/MultiFilter.vue';
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";

export default {
  name: "ListSituation",
  mixins: [cursorPaginate],
  components: {
    LoadingPagination,
    MultiFilter,
    SkeletonPuzlGrid,
    PuzlEmptyData,
    EditSituation,
  },
  data () {
    return {
      loadingSkeleton: false,
      filter: {},
    }
  },
  computed: {
    ...mapGetters({
      'situations': 'contractSituation/fetch',
    }),
  },
  methods:{
    init(filter = null) {
      this.startCursor(filter)
      this.$store.dispatch('contractSituation/fetchItems', {
        filter: this.filter,
        next_page: this.paginate.nextUrl
      })
        .then( response =>{
          this.resolveCursor(response)
        })
        .catch( error => {
          this.resolveCursor()
        })
    },
    handleEditType(uuid){
      this.$refs.editSituation.handleEditModal(uuid)
    },
    handleDeleteType(uuid){
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed){
          this.$Progress.start();
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          this.$store.dispatch('contractSituation/destroy', uuid).then(response => {
            this.$notify({
              type: 'success',
              message: response.message
            });
          }).catch(error => {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            });
          })
          this.$Progress.finish();
        }
      }).catch(() => this.$Progress.finish())
    }
  },
  mounted() {
    this.init({})
    this.$refs['multi-filter']._self.$forceUpdate();
  },
  created () {

  }
}
</script>

<style scoped>

</style>
