<template>
  <div>
    <modal size="lg"
      :show.sync="modal.create"
      :backgroundColor="'#F2F7F3'"
      :showClose="false"
    >
    <template slot="header">
      <div class="d-flex align-items-center container-fluid" style="gap: 15px;">
        <div>
          <img src="/img/icons/icons8/ios/clock-success.png" alt="clock-success" width="24px">
        </div>
        <span class="text-title">
          {{ modal.title }}
        </span>
        <div class="ml-auto">
          <img class="pointer" @click="closeModal" src="/img/icons/icons8/ios/close-window.png" alt="close-window" width="17px">
        </div>
      </div>
    </template>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12" style="display: flex; align-items: center;">
          <img src="/img/icons/icons8/ios/info-squared.png" width="22">
            <h4 class="new-default-black-font m-0 mx-3 ">
                Identificação
            </h4>
            <hr class="ml-3" style="width: 100%;">
        </div>
        <div class="col-12 mt-n1" style="font-weight: 400;font-size: 14px;color: #2B2D32;">
          <span> {{ schedule.title }} </span>
        </div>
        <div class="col-12" style="color: #606062;">
          <span> {{ formatAddress(addressObject) }} </span>
        </div>
        <div class="col-12 mt-3">
          <div class="d-flex" style="gap: 10px;">
            <div class="volume-card card-total w-100">
              <div class="mx-3 my-1">
                <section class="mb-2 top-section">
                  <img src="/img/icons/icons8/ios/signal_primary.png" alt="signal_primary" width="24px">
                </section>
                <section class="mid-section">
                  <span style="font-weight: 400; font-size: 12px;color: #2B2D32;"> Vol. Programado </span>
                </section>
                <section class="bottom-section d-flex">
                  <span style="font-size: 16px;font-weight: 400;color: #2B2D32;">
                    {{ schedule.total_volume ? schedule.total_volume : 0 }} m<sup>3</sup>
                  </span>
                  <span class="ml-auto" style="font-size: 14px;font-weight: 400;color: #606062;">
                    {{ schedule.total_travels ? schedule.total_travels : 0 }} VIAGENS
                  </span>
                </section>
              </div>
            </div>
            <div class="volume-card card-performed w-100">
              <div class="mx-3 my-1">
                <section class="mb-2 top-section">
                  <img src="/img/icons/icons8/ios/signal_success.png" alt="signal_success" width="24px">
                </section>
                <section class="mid-section">
                  <span style="font-weight: 400; font-size: 12px;color: #2B2D32;"> Vol. Realizado </span>
                </section>
                <section class="bottom-section d-flex">
                  <span style="font-size: 16px;font-weight: 400;color: #2B2D32;">
                    {{ schedule.performed_volume ? schedule.performed_volume : 0 }} m<sup>3</sup>
                  </span>
                  <span class="ml-auto" style="font-size: 14px;font-weight: 400;color: #606062;">
                    {{ schedule.performed_travels ? schedule.performed_travels : 0 }} VIAGENS
                  </span>
                </section>
              </div>
            </div>
            <div class="volume-card card-unrealized w-100">
              <div class="mx-3 my-1">
                <section class="mb-2 top-section">
                  <img src="/img/icons/hourglass-yellow.png" alt="hourglass-yellow" width="24px">
                </section>
                <section class="mid-section">
                  <span style="font-weight: 400; font-size: 12px;color: #2B2D32;"> Vol. Pendente </span>
                </section>
                <section class="bottom-section d-flex">
                  <span style="font-size: 16px;font-weight: 400;color: #2B2D32;">
                    {{ schedule.unrealized_volume ? schedule.unrealized_volume : 0 }} m<sup>3</sup>
                  </span>
                  <span class="ml-auto" style="font-size: 14px;font-weight: 400;color: #606062;">
                    {{ schedule.unrealized_travels ? schedule.unrealized_travels : 0 }} VIAGENS
                  </span>
                </section>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12" style="display: flex; align-items: center;">
          <img src="/img/icons/icons8/ios/concrete-mixer-dark.png" width="22">
            <h4 class="new-default-black-font m-0 mx-3 ">
                Concretagem
            </h4>
            <hr class="ml-3" style="width: 100%;">
        </div>
        <div class="col-12 mt-n1">
          <span style="font-size: 14px;font-weight: 500;color: #2B2D32;"> Tempo de Concretagem </span>
        </div>
        <div class="col-12 d-flex align-items-center mt-n3 mb-n3">
          <span style="font-size: 14px; font-weight: 400; color: #2B2D32;">Data de Início</span>
          <hr class="flex-grow-1 mx-2">
          <span style="font-size: 14px; font-weight: 400; color: #2B2D32;">{{ schedule.concreting_start_date }}</span>
        </div>
        <div class="col-12 d-flex align-items-center mt-n3 mb-n3">
          <span style="font-size: 14px; font-weight: 400; color: #2B2D32;">Data de Término</span>
          <hr class="flex-grow-1 mx-2">
          <span style="font-size: 14px; font-weight: 400; color: #2B2D32;">{{ schedule.concreting_end_date }}</span>
        </div>
        <div class="col-12 d-flex align-items-center mt-n3">
          <span style="font-size: 14px; font-weight: 400; color: #2B2D32;">Tempo Total</span>
          <hr class="flex-grow-1 mx-2">
          <span style="font-size: 14px; font-weight: 400; color: #2B2D32;">{{ schedule.concreted_total_time }}</span>
        </div>

        <div class="col-12 mt-n1" v-if="schedule.average_travel_cycle || schedule.average_concrete_cycle">
          <span style="font-size: 14px;font-weight: 500;color: #2B2D32;"> Viagens </span>
        </div>
        <div class="col-12 d-flex align-items-center mt-n3 mb-n3" v-if="schedule.average_travel_cycle">
          <span style="font-size: 14px; font-weight: 400; color: #2B2D32;">Ciclo Médio de Viagem</span>
          <hr class="flex-grow-1 mx-2">
          <span style="font-size: 14px; font-weight: 400; color: #2B2D32;">{{ Math.trunc(schedule.average_travel_cycle) }} MIN</span>
        </div>
        <div class="col-12 d-flex align-items-center mt-n3" v-if="schedule.average_concrete_cycle">
          <span style="font-size: 14px; font-weight: 400; color: #2B2D32;">Ciclo Médio de Concreto</span>
          <hr class="flex-grow-1 mx-2">
          <span style="font-size: 14px; font-weight: 400; color: #2B2D32;">{{ Math.trunc(schedule.average_concrete_cycle) }} MIN</span>
        </div>

        <div class="col-12 mt-n1">
          <span style="font-size: 14px;font-weight: 500;color: #2B2D32;"> Métricas de Distribuição </span>
        </div>
        <div class="col-12 d-flex align-items-center mt-n3 mb-n3" >
          <span style="font-size: 14px; font-weight: 400; color: #2B2D32;">Intervalo Entre Descargas</span>
          <hr class="flex-grow-1 mx-2">
          <span style="font-size: 14px; font-weight: 400; color: #2B2D32;">{{ schedule.interval_between_discharges }} MIN</span>
        </div>
        <div class="col-12 d-flex align-items-center mt-n3">
          <span style="font-size: 14px; font-weight: 400; color: #2B2D32;">Temp. de Permanência em Obra</span>
          <hr class="flex-grow-1 mx-2">
          <span style="font-size: 14px; font-weight: 400; color: #2B2D32;">{{ schedule.time_spent_site }} MIN</span>
        </div>
      </div>
      <div class="my-3 d-flex justify-content-center">
        <div class="buttom-close-bottom d-flex pointer align-items-center" style="gap: 8px;" @click="closeModal">
          <img src="/img/icons/icons8/ios/cancel_gray.png" width="22" alt="cancel_gray">
          <span>
            FECHAR
          </span>
        </div>
      </div>
    </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: "ModalInfoSchedule",
  components: {},
  data() {
    return {
      modal: {
        title: "Distribuição de Viagens",
        create: false,
      },
      schedule: null,
      addressObject: {}
    }
  },
  methods: {
    openModal(company_plant_uuid, construction_id, concreting_date) {
      this.modal.create = true;
      this.schedule = null;
      this.addressObject = {};
      this.getSchedule(company_plant_uuid, construction_id, concreting_date);
    },
    closeModal() {
      this.modal.create = false;
    },
    getSchedule(company_plant_uuid, construction_id, concreting_date) {
      let loader = this.$loading.show();
      this.$store.dispatch("newScheduleResume/findByCompanyConstructionDate", {
        company_plant_uuid: company_plant_uuid,
        construction_id: construction_id,
        concreting_date: concreting_date
      }).then((response) => {
        this.schedule = response.data;
        this.addressObject = {
          address: this.schedule.address,
          number: this.schedule.number,
          complement: this.schedule.complement,
          district: this.schedule.district,
          city: this.schedule.city,
          postal_code: this.schedule.postal_code
        }
        this.$notify({
          type: response.error_type,
          message: response.message,
        });
        loader.hide();
      });
    },
    /**
    * Formata o endereço em um formato específico.
    *
    * @param {object} address - O objeto a ser formatado
    * @returns {string} - A string formatada.
    */
    formatAddress(addressObject) {
      let formattedAddress = addressObject.address.toUpperCase();

      // SN == sem número, definido no backend
      if(addressObject.number !== "SN") {
        formattedAddress += `, ${addressObject.number}`;
      }

      if(addressObject.complement) {
        formattedAddress += `, ${addressObject.complement}`;
      }

      formattedAddress += `, ${addressObject.district.toUpperCase()}`;
      formattedAddress += `, ${addressObject.city.toUpperCase()}`;
      formattedAddress += `, CEP ${addressObject.postal_code}`;

      return formattedAddress;
    },
  }
}
</script>

<style scoped>
.text-title {
  font-size: 18px;
  font-weight: 500;
  color: #2B2D32;
}
.volume-card {
  border: 0.5px solid #E8E8E8;
  background-color: #fff;
  border-radius: 8px;
}
.buttom-close-bottom {
  padding: 7px 20px;
  border: 0.5px solid #606062;
  border-radius: 8px;
}
.buttom-close-bottom span {
  font-size: 14px;
  font-weight: 500;
  color: #606062;
}
</style>