import { createAxios } from "@/plugins/axios";;
import * as types from './mutation_types'
import {cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'
const endPoint = '/administrative/equipment-resume/+microservice'
export default {
  namespaced: true,
  state: {
    concreteItems: [],
    serviceItems: [],
  },
  getters:{
    getConcreteItems: state => state.concreteItems,
    getServiceItems: state => state.serviceItems,
  },
  mutations: {
    [types.SET_CONCRETES](state, payload) {
      state.concreteItems = payload
    },
    [types.SET_SERVICES](state, payload) {
      state.serviceItems = payload
    },
  },
  actions: {
    fetchConcretesItemsPaginate({commit, state}, params) {
      return createAxios().get(setUrlWithNextPage(`${endPoint}travel`, params),
        {params: params})
        .then(({data}) =>{
          cursorPaginate(state, {data : data.data, next_page: params.next_page}, 'concreteItems')
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchServicesItemsPaginate({commit, state}, params) {
      return createAxios().get(setUrlWithNextPage(`${endPoint}service`, params),
        {params: params})
        .then(({data}) =>{
          cursorPaginate(state, {data : data.data, next_page: params.next_page}, 'serviceItems')
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getTravelReportPdf(_, params) {
      return createAxios().get(endPoint + 'travel-report-pdf', {
        responseType: "blob",
        timeout: 60000,
        params: params
      })
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getServiceReportPdf(_, params) {
      return createAxios().get(endPoint + 'service-report-pdf', {
        responseType: "blob",
        timeout: 60000,
        params: params
      })
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getReportExcel(_, params) {
      return createAxios().get(endPoint + 'report-excel', {
        responseType: "blob",
        timeout: 60000,
        params: params
      })
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
