<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-gray d-inline-block mb-0">{{ $route.name }}</h6>
          <nav
            aria-label="breadcrumb"
            class="d-none d-md-inline-block ml-md-4 text-gray"
          >
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i></a></li>
              <li class="breadcrumb-item"><a href="#">Tecnologia</a></li>
              <li class="breadcrumb-item" aria-current="page">{{ $route.name }}</li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- Header Menus -->
      <div class="row" v-if="supplier">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-3 col-lg-2 col-xlg-2 ml-3 mr-3 mb-3 ml-0 p-0">
              <base-button class="pb-3 pt-3 text-uppercase" block size="lg" type="success" @click="handleCreate(supplier.id)">
                <i class="fas fa-plus"></i> novo
              </base-button>
            </div>

            <div class="col-md-3 col-lg-2 col-xlg-2 ml-3 mr-3 mb-3 ml-0 p-0">
              <router-link :to="{ path:'/technology/cmc/supplier'}">
                <base-button block size="lg" type="light" class="p-3 text-uppercase">
                  voltar
                </base-button>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="row align-items-center pb-0 mb-3" v-if="supplier">
        <div class="col-md-12">
          <div class="checklist-item checklist-item-primary">
            <div class="checklist-info">
              <h1 class="checklist-title mb-0">
                {{supplier.supplier_name}}
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div class="row align-items-center pb-0">
        <div class="col-md-12">
          <div class="card p-2">
            <base-input placeholder="Pesquisar">
              <template slot="append">
                <i class="fas fa-search"></i>
              </template>
            </base-input>
          </div>
        </div>
      </div>

    </base-header>

    <div class="container-fluid">
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <!-- Empty Data -->
      <PuzlEmptyData v-if="!supplier.supplier_origins && !loadingSkeleton"></PuzlEmptyData>

      <div class="row card-wrapper" v-if="supplier.supplier_origins && !loadingSkeleton">
        <div
          class="col-lg-4"
          v-for="(supplier_origin, indexSupplierOrigin) in supplier.supplier_origins"
          :key="indexSupplierOrigin"
        >
          <!-- Basic with action button -->
          <card :class="supplier.status ? 'card-border-top-success' : 'card-border-top-danger'">
            <!-- Card header -->
            <div class="row align-items-center mb-3">
              <div class="col-8">
                <!-- Title -->
                <h5 class="h3 mb-0">{{ supplier_origin.origin_name }}</h5>
              </div>

              <div class="col-4 text-right">
                <base-dropdown menuOnRight>
                  <base-button
                    slot="title-container"
                    type="primary"
                    class="dropdown-toggle p-2 rounded m-0"
                  >
                    <i class="fas fa-cog"></i>
                  </base-button>
                  <a class="dropdown-item" @click.prevent="handleRelateEntity(supplier_origin.id)">
                    <i class="fas fa-building text-default"></i>
                    Associar CNPJ / CPF
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click.prevent="handleEditSupplierOrigin(supplier_origin.uuid)">
                    <i class="fas fa-edit text-warning"></i>
                    Editar
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click.prevent="handleDeleteSupplierOrigin(supplier_origin.uuid)">
                    <i class="fas fa-times text-danger"></i>
                    Excluir
                  </a>
                </base-dropdown>
              </div>
            </div>
            <!-- Card body -->
            <collapse class="border rounded" v-if="supplier_origin.entities!=''">
              <collapse-item name="1" class="header-gray" back-ground="border-header-primary-bottom">
                <h5 slot="title" class="mb-0 ">Cadastros CNPJ / CPF</h5>
                <div v-for="(entity, indexEntity) in supplier_origin.entities" :key="indexEntity">
                  <div class="form-group row m-0 p-0 mb-1 mt-2">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-10">
                          <h5 class="h5 ">{{ entity.entity_name }}</h5>
                          <h5 class="h5 small">{{ entity.document }}</h5>
                        </div>
                        <div class="col-md-2 p-0 mb-1 col-form-label form-control-label">
                          <base-button
                            block
                            size="lg"
                            type="danger"
                            class="p-1"
                            @click.prevent="handleDeleteSupplierOriginEntity(supplier_origin.id, entity.id)"
                          >
                            <i class="fas fa-times text-withe"></i>
                          </base-button>
                        </div>
                      </div>
                      <div class="dropdown-divider p-0 m-0">&nbsp;</div>
                    </div>
                  </div>
                </div>

              </collapse-item>
            </collapse>
          </card>
        </div>
      </div>
      <edit-supplier-origin ref="editSupplierOrigin"></edit-supplier-origin>
      <create-supplier-origin ref="createSupplierOrigin"></create-supplier-origin>
      <modal-relate-entity ref="modalRelateEntity"></modal-relate-entity>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid"
import PuzlEmptyData from "@/components/PuzlEmptyData"
import CreateSupplierOrigin from  './_Create'
import EditSupplierOrigin from  './_Edit'
import ModalRelateEntity from  './_ModalRelateEntity'

export default {
  name: "ListSupplierOrigin",
  components: {
    PuzlEmptyData,
    SkeletonPuzlGrid,
    EditSupplierOrigin,
    CreateSupplierOrigin,
    ModalRelateEntity
  },
  data () {
    return {
      loadingSkeleton: true,
    }
  },
  computed: {
    ...mapGetters({
      'supplier': 'cmcSupplierOrigin/fetch',
    }),
  },
  methods:{
    handleRelateEntity(supplierOriginId){
      this.$refs.modalRelateEntity.openModal(true, supplierOriginId)
    },
    handleCreate(supplierId){
      this.$refs.createSupplierOrigin.openModal(true, supplierId)
    },
    handleEditSupplierOrigin(uuid){
      this.$refs.editSupplierOrigin.handleEditModal(uuid)
    },
    handleDeleteSupplierOrigin(uuid){
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed){
          this.$Progress.start();
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          this.$store.dispatch('cmcSupplierOrigin/destroy', uuid).then(response => {
            this.$notify({
              type: 'success',
              message: response.message
            });
          }).catch(error => {
            this.$notify({
              type: error.data.error_type,
              message: 'Erro ao excluir procedência mcc!'
            });
          })
          this.$Progress.finish();
        }
      }).catch(() => this.$Progress.finish())
    },
    handleDeleteSupplierOriginEntity(supplierOriginId, entityId){
      const params = { cmc_supplier_origin_id: supplierOriginId, entity_id: entityId,}
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed){
          this.$Progress.start();
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          this.$store.dispatch('cmcSupplierOriginEntity/remove', params).then(response => {
            this.$notify({
              type: 'success',
              message: response.message
            });
          }).catch(error => {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            });
          })
          this.$store.dispatch('cmcSupplierOrigin/fetchItems', this.$route.params.supplier_uuid).then( () => {this.loadingSkeleton = false})
          this.$Progress.finish();
        }
      }).catch(() => this.$Progress.finish())
    }
  },
  mounted() {
    this.$store.dispatch('cmcSupplierOrigin/fetchItems', this.$route.params.supplier_uuid).then( () => {this.loadingSkeleton = false})
  }
}
</script>

<style scoped>

</style>
