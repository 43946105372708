<template>
  <label :class="puzl ? 'custom-toggle-puzl '+ switchClass : 'custom-toggle ' + switchClass">
  <!-- <label class="custom-toggle-puzl" :class="switchClass"> -->
    <input type="checkbox" v-model="model" :disabled="disabled">
    <span class="custom-toggle-slider"
          :data-label-off="offText"
          :data-label-on="onText">
    </span>
  </label>

</template>
<script>
  export default {
    name: 'base-switch',
    props: {
      value: [Array, Boolean, Number],
      type: String,
      puzl: Boolean,
      disabled: Boolean,
      onText: {
        type: String,
        default: 'Sim'
      },
      offText: {
        type: String,
        default: 'Não'
      }
    },
    computed: {
      switchClass() {
        let baseClass = 'custom-toggle-';
        if (this.type) {
          return baseClass + this.type
        }
        return ''
      },
      model: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      }
    },
    methods: {
      triggerToggle() {
        this.model = !this.model;
      }
    }
  };
</script>
<style></style>
