/**
 * @typedef {Object} SapIntegrationSyncType
 * @property {number} typeable_id
 * @property {number} external_id
 * @property {string} type
 */
export const SapIntegrationSyncType = {};

/**
 * @returns {SapIntegrationSyncType}
 */
export const initSapIntegrationSyncType = () => {
  return {
    typeable_id: null,
    external_id: null,
    type: null,
  };
};
