import store from "../../../../../../shared/libraries/store";
import { entryLaunchItemTaxStoreStateKeys } from "./entryLaunchItemTaxStoreStateKeys";
import { EntryLaunchItemTaxType } from "../types";

/**
 * @param {EntryLaunchItemTaxType} item
 * @returns {EntryLaunchItemTaxType}
 */
const setItem = (item) => store.commit(entryLaunchItemTaxStoreStateKeys.ITEM, item);

/**
 * @param {boolean} isFinding
 * @returns {boolean}
 */
const setIsFinding = (isFinding) => store.commit(entryLaunchItemTaxStoreStateKeys.IS_FINDING, isFinding);

export default {
  setItem,
  setIsFinding,
};
