<template>
  <div>
    <multi-filter @fetch="fetch" :filter="filter" />
    <div class="container-fluid">
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
      </div>

      <div class="row align-items-center" v-if="!slumpflows.length && !loadingSkeleton">
        <div class="col-md-12 text-center">
          <div class="card p-4">
            Sem dados cadastrados!
          </div>
        </div>
      </div>

      <div class="row card-wrapper" v-if="slumpflows.length && !loadingSkeleton">
        <div class="col-lg-4" v-for="(slumpflow, index) in slumpflows" :key="index">
          <!-- Basic with action button -->
          <card :class="slumpflow.status ? 'card-border-top-success' : 'card-border-top-danger'">
            <!-- Card header -->
            <div class="row align-items-center mb-3">
              <div class="col-8">
                <!-- Title -->
                <h5 class="h3 mb-0">{{ slumpflow.nomenclature }}</h5>
                <h6 class="h3 mb-0 small">{{ slumpflow.minimum }} à {{ slumpflow.maximum }} (mm)</h6>
              </div>

              <div class="col-4 text-right">
                <base-dropdown menuOnRight>
                  <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                    <i class="fas fa-cog"></i>
                  </base-button>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleEditSlumpFlow(slumpflow.uuid)">
                    <i class="fas fa-edit text-warning"></i>
                    Editar
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleDeleteSlumpFlow(slumpflow.uuid)">
                    <i class="fas fa-times text-danger"></i>
                    Excluir
                  </a>
                </base-dropdown>
              </div>
            </div>
            <!-- Card body -->
          </card>
        </div>
      </div>
    </div>
    <edit-slump-flow ref="editSlumpFlow"></edit-slump-flow>
  </div>
</template>

<script>
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import { mapGetters } from "vuex";
import EditSlumpFlow from "./_Edit";
import { formatErrorValidation } from "@/plugins";
import MultiFilter from "@/components/Utils/MultiFilter";
export default {
  name: "List",
  components: {
    MultiFilter,
    SkeletonPuzlGrid,
    EditSlumpFlow
  },
  data() {
    return {
      filter: {},
      loadingSkeleton: false
    };
  },
  computed: {
    ...mapGetters({
      slumpflows: "mixSlumpFlow/fetch"
    })
  },
  methods: {
    fetch(filter = {}) {
      this.filter = filter;
      this.loadingSkeleton = true;
      this.$store
        .dispatch("mixSlumpFlow/fetchItems", { filter: this.filter })
        .then(response => {
          this.loadingSkeleton = false;
        })
        .catch(error => {
          this.loadingSkeleton = false;
        });
    },
    handleEditSlumpFlow(uuid) {
      this.$refs.editSlumpFlow.handleEditModal(uuid);
    },
    handleDeleteSlumpFlow(uuid) {
      this.$Swal
        .confirmDelete()
        .then(result => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({ type: "info", message: "Estamos trabalhando em sua solicitação." });
            this.$store
              .dispatch("mixSlumpFlow/destroy", uuid)
              .then(response => {
                this.$Progress.finish();
                this.$notify({ type: response.error_type, message: response.message });
              })
              .catch(error => {
                if (error.status == 200) {
                  this.$notify({
                    type: "danger",
                    message: error.data.message
                  });
                  this.$Progress.finish();
                } else if (error.response && error.response.status === 422) {
                  let errors = formatErrorValidation(error.response.data.errors);
                  this.$notify({
                    type: "danger",
                    message: errors
                  });
                }
                this.$Progress.finish();
              });
            this.$Progress.finish();
          }
        })
        .catch(() => this.$Progress.finish());
    }
  },
  mounted() {
    this.fetch();
  },
  created() {}
};
</script>

<style scoped></style>
