<template>
  <div>
    <base-header class="bg-gray-content">
      <puzl-breadcrumb />
      <!-- Header Menus -->
      <div class="row mb-3">
        <div class="col-md-2 mb-2">
          <base-button @click.prevent="handleModalCreate" block size="lg" type="success" class="text-uppercase">
            <i class="fas fa-plus"></i> novo
          </base-button>
        </div>
        <div class="col-md-2" aria-current="page">
          <BackButton/>
        </div>
      </div>
    </base-header>
    <list />
  </div>
</template>

<script>
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import List from "./_List.vue";
import ModalCreate from "./_ModalCreate.vue";
import BackButton from '@/components/Utils/BackButton'

export default {
  name: "IndexPaymentTerm",
  components: { PuzlBreadcrumb, List, ModalCreate, BackButton },
  data() {
    return {};
  },
  methods: {
     handleModalCreate() {
      this.$router.push('/financial/bill-receive/payment-term/create');
    }
  }
};
</script>

<style></style>
