<template>
  <div>
    <modal size="md" :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div class="col-12">
        <div class="row mb-2">
          <div class="col-12">
            <div class="checklist-item checklist-item-danger">
              <div class="checklist-danger">
                <span class="display-4" style="font-size: 1.2rem">
                  {{ params.plant }}
                </span>
                <br>
                <span class="display-4" style="font-size: 1rem">
                  {{ params.charge_point }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
        <div class="col-12" v-for="(item, index) in params.mcc" :key="index" v-if="modal">
          <div class="row">
            <div class="col-4 mt-1">
              <label :class="validIssetField(item.id)">
                <b>{{ item.name }}</b></label>
            </div>
            <template v-if="item.cmc && !item.dont_exists_in_mixe">
              <div style="margin-right: -30px" class="col-12 mt-1">
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm">
                    <el-select
                      size="mini"
                      placeholder="Selecione"
                      filterable
                      v-model="teste[item.id].cmc"
                      @change="setDosage(index, item.id)"
                    >
                      <el-option label="Selecione" value=""></el-option>
                      <el-option
                        v-for="item in item.cmc"
                        :key="item.uuid"
                        :label="item.product+ ' - '+item.supplier+ ' ('+item.supplier_origin+')'"
                        :value="item.uuid"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </validation-provider>
              </div>
            </template>
            <template v-else-if="handleValidItem(item)">
              <div class="col-2"></div>
              <div class="col-6"><small class="text-muted">MCC não encontrado para este grupo</small>
              </div>
            </template>
            <template v-if="item.dont_exists_in_mixe && setDisabledSave()">
              <div class="col-2"></div>
              <div class="col-6"><small class="text-indigo">Grupo de Puzl Admix não encontrado para o traço</small>
              </div>
            </template>
            <template v-if="item.dont_exists_in_formulation">
              <div class="col-2"></div>
              <div class="col-6"><small class="text-indigo">Grupo de Puzl Admix não incluso na formulação</small>
              </div>
            </template>
            <template v-if="item.dosage_not_allowed">
              <div class="col-2"></div>
              <div class="col-6"><small class="text-indigo">Quantidade da Puzl Admix fora do limite permitido.</small>
              </div>
            </template>
          </div>
          <template v-if="item.cmc">
            <div class="row">
              <div class="col-12 mt-1">
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm">
                    <el-select :ref="'focus-'+item.id" :class="is_duplicated(item.id) && 'is-invalid'"
                               @change="validDosage()"
                               size="mini"
                               placeholder="Selecione"
                               filterable
                               v-model="teste[item.id].dosage"
                    >
                      <el-option
                        v-for="(dosage, index) in teste[item.id].dosages"
                        :key="dosage.uuid"
                        :label="dosage.text + (dosage.name ? ' - ' + dosage.name : '')"
                        :value="dosage.uuid"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <small class="text-danger" v-if="is_duplicated(item.id)">
                    O local de saída deve ser único por grupo
                  </small>
                </validation-provider>
              </div>
            </div>
          </template>
          <br/>
        </div>
        <div class="modal-footer">
          <base-button type="secondary" @click="close"
          >Cancelar
          </base-button
          >
          <base-button
            type="success"
            native-type="submit"
            :disabled="invalid || disabledSave || dontHaveAdmixture || dosageLocationIsDuplicated()"
            @click.native="save"
          >Salvar
          </base-button
          >
        </div>
      </validation-observer>

    </modal>
  </div>
</template>

<script>
export default {
  components: {},
  name: "ModalMCC",
  data() {
    return {
      modal: false,
      dosage_duplicates: [],
      title: 'Definição de MCC',
      params: [],
      disabledSave: false,
      mccs: {},
      dosages: {},
      length: 0,
      teste: {},
      teste2: {},
      selectedMCC: [],
      dontHaveAdmixture: false,
    }
  },
  methods: {
    setDisabledSave() {
      return this.dontHaveAdmixture = true
    },
    validDosage() {
      const dosage_uuids = []
      for (let index in this.teste) {
        if (!this.teste[index].dosage) {
          continue
        }
        const dosage = this.teste[index].dosages.find(item => item.uuid === this.teste[index].dosage)
        if (dosage.is_manual) {
          continue;
        }
        dosage_uuids.push(this.teste[index].dosage)
      }
      this.dosage_duplicates = dosage_uuids.filter(function (item, index) {
        return dosage_uuids.indexOf(item) !== index;
      })
    },
    dosageLocationIsDuplicated() {
      return this.dosage_duplicates.length > 0
    },
    is_duplicated(id) {
      const dosage = this.teste[id].dosage
      return this.dosage_duplicates.includes(dosage)
    },
    validIssetField(id) {
      if (!this.teste[id]) {
        return 'text-danger'
      }
      if (!this.teste[id].cmc || !this.teste[id].dosage) {
        return 'text-danger'
      }
      return 'text-success'
    },
    openModal(params) {
      this.dontHaveAdmixture = false
      this.disabledSave = false
      this.params = params
      this.modal = true
      for (var item of this.params.selected) {
        for (var group of item.groups) {
          this.teste2[group.group_id] = {
            cmc: group.cmc_uuid,
            dosage: group.dosage.uuid
          }
        }
      }
      if (!Object.values(this.teste).length) {
        for (var key in params.mcc) {
          if (params.mcc[key].cmc) {
            this.teste[params.mcc[key].id] = {
              cmc: '',
              dosage: ''
            }
          }
        }
        for (var key in params.mcc) {
          this.length++
          if (this.teste2[params.mcc[key].id]) {
            this.teste[params.mcc[key].id] = this.teste2[params.mcc[key].id]
            this.setDosage(key, params.mcc[key].id)
            continue;
          }
          if (params.mcc[key].cmc) {
            for (var index in params.mcc[key].cmc) {
              if (Object.keys(params.mcc[key].cmc).length === 1) {
                this.teste[params.mcc[key].id].cmc = params.mcc[key].cmc[index].uuid
              }
              this.setDosage(key, params.mcc[key].id)
            }
          }
        }
      }
      this.validDosage()
    },
    clearTeste() {
      this.teste = {}
    },
    handleValidItem(item) {
      if (!item.mcc &&
        !item.dont_exists_in_mixe &&
        !item.dont_exists_in_formulation &&
        !item.dosage_not_allowed) {
        this.disabledSave = true
        return true
      }
      this.disabledSave = false
      return false
    },
    setDosage: function (index, key) {
      this.$forceUpdate()
      this.teste[key].dosage = null
      this.teste[key].dosages = []
      if (this.teste[key].cmc) {
        const cmcs = Object.values(this.params.mcc[index].cmc)
        const selected = cmcs.find(item => item.uuid == this.teste[key]['cmc'])
        this.teste[key].dosages = selected.dosages
        if (selected.dosages.length === 1) {
          this.teste[key].dosage = selected.dosages[0].uuid
        }
      }
      this.$forceUpdate()
    },
    save: function () {
      const teste = {}
      let params = []
      let mccs = []
      for (var key in this.teste) {
        const allMcc = Object.values(this.params.mcc)
        const mccSelected = allMcc.find(item => item.id == key)
        const cmc = Object.values(mccSelected.cmc)
        const selected = cmc.find(item => item.uuid == this.teste[key]['cmc'])
        mccs.push(selected.adjustment_uuid)
        const dosageSelected = selected.dosages.find(item => item.uuid == this.teste[key]['dosage'])
        teste[key] = {
          cmc: selected,
          group_name: mccSelected.name,
          dosage: dosageSelected,
        }
        let item = {
          schedule_travel_uuid: this.params.travel_uuid,
          cmc_uuid: selected.uuid,
          order: mccSelected.order,
          cmc_group_uuid: selected.cmc_group_uuid,
          measured_unit_id: selected.measured_unit_id,
          dosage_uuid: dosageSelected.uuid,
          specific_gravity: selected.specific_gravity,
          bulk_density: selected.bulk_density,
          water_correction: selected.water_correction,
          moisture: selected.moisture,
          price: selected.price,
          max_humidity: selected.max_humidity,
          average_humidity: selected.average_humidity,
          purchase_unit_id: selected.purchase_unit_id,
          percentage_adjustment: selected.percentage_adjustment ? JSON.stringify(selected.percentage_adjustment) : null,
        }
        params.push(item)
      }
      this.$emit('setMcc', {params: params, mccs: mccs})
    },
    close: function () {
      this.modal = false
    }
  }
}
;
</script>

<style></style>
