import cmcAdjustmentStoreActions from "./cmcAdjustmentStoreActions";
import cmcAdjustmentStoreGetters from "./cmcAdjustmentStoreGetters";
import cmcAdjustmentStoreMutations from "./cmcAdjustmentStoreMutations";
import { cmcAdjustmentStoreStateKeys } from "./cmcAdjustmentStoreStateKeys";

const cmcAdjustmentStore = {
  ...cmcAdjustmentStoreActions,
  ...cmcAdjustmentStoreGetters,
  ...cmcAdjustmentStoreMutations,
  cmcAdjustmentStoreStateKeys,
};

export default cmcAdjustmentStore;
