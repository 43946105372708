import { entryLaunchCreateByXmlAttachmentService } from "../services";

/**
 * @param {FormData} data
 * @returns {Promise<void>}
 */
const createByXmlAttachment = async (data) => await entryLaunchCreateByXmlAttachmentService.execute(data);

export default {
  createByXmlAttachment,
};
