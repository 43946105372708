<template>
<div>
	<base-header class="bg-gray-content">
		<div class="row align-items-center py-4">
			<div class="col-lg-12 col-12">
				<PuzlBreadcrumb/>
			</div>
		</div>
		<div class="row mb-3">
			<div class="col-md-2 mb-2">
				<base-button 
					@click.prevent="handleCreateModal" 
					class="pb-3 pt-3 text-uppercase" 
					block size="lg" 
					type="success"
				>
					<i class="fas fa-plus"></i> novo
				</base-button>
			</div>
			<div class="col-md-2 mb-2">
				<base-button class="pb-3 pt-3 text-uppercase" block size="lg" type="primary" @click.prevent="handleCreateLimitAdjustment">
					<i class="fas fa-arrows-alt-v"></i> limite
				</base-button>
			</div>
			<div class="col-md-2 mb-2">
				<back-button/>
			</div>
		</div>
	</base-header>
	<ListPuzlAdjustFinalSlump v-bind:added="this.added" />
	<CreatePuzlAdjustFinalSlump @createdFinalSlumpAdjustments="handleCreateAdjustmentList" ref="modalCreate" />
	<ModalLimitSetting  @createdFinalSlumpAdjustments="handleCreateAdjustmentList" ref="modalLimitSetting" />
</div>
</template>

<script>
import ListPuzlAdjustFinalSlump from "./Shared/_List";
import CreatePuzlAdjustFinalSlump from "./Shared/_ModalCreate";
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import BackButton from "@/components/Utils/BackButton";
import ModalLimitSetting from './Shared/_ModalLimitSetting';

export default {
	name: "IndexPuzlAdjustmentFinalSlump",
	components: {
		ListPuzlAdjustFinalSlump,
		CreatePuzlAdjustFinalSlump,
		PuzlBreadcrumb,
		BackButton,
		ModalLimitSetting,
	},
	data() {
		return {
			added: false,
		}
	},
	methods: {
		handleCreateModal() {
			this.$refs.modalCreate.handleCreateModal();
		},
		handleCreateLimitAdjustment() {
			this.$refs.modalLimitSetting.handleCreateModal();
		},
		handleCreateAdjustmentList() {
			this.added = !this.added;
		},
	},
}
</script>

<style></style>
