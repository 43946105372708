export const SET = 'SET'
export const SET_ITEMS = 'SET_ITEMS'
export const SET_FEATURE_LEVEL = 'SET_FEATURE_LEVEL'
export const SET_WITHOUT_REFERENCE = 'SET_WITHOUT_REFERENCE'
export const SET_REFERENCE = 'SET_REFERENCE'
export const SET_TYPES_BY_UUID_TYPE = 'SET_TYPES_BY_UUID_TYPE'
export const SET_BY_UUID_SUBTYPE = 'SET_BY_UUID_SUBTYPE'
export const SET_GROUP_BY_UUID = 'SET_GROUP_BY_UUID'
export const SET_GROUP_HISTORY = 'SET_GROUP_HISTORY'
export const PUSH = 'PUSH'
export const SHOW = 'SHOW'
export const DESTROY = 'DESTROY'
export const UPDATE = 'UPDATE'
export const SET_GROUPS = 'SET_GROUPS'
export const SET_NEXT_PAGE = 'SET_NEXT_PAGE'
export const SET_NEXT_PAGE_DATA = 'SET_NEXT_PAGE_DATA'
