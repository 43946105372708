import * as types from './mutation_types'
import {destroy, update, cursorPaginate,setUrlWithNextPage, formatResponseData} from '@/store/baseStore'
import { createAxios } from "@/plugins/axios";

const endPoint = '/financial/bank-reconciliation/transaction/'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
  },
  getters: {
    show: state => state.item,
    fetch: state => state.items,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload
    },
    [types.PUSH](state, payload) {
      state.items.push(payload)
    },
    [types.DESTROY](state, id) {
      destroy(state, id)
    },
  },
  actions: {
    fetchItems({}, params) {
      return createAxios().get(endPoint + params.bank_reconciliation_transaction_id + '/associations')
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    associateFinanceTransaction({}, params){
      return createAxios().post(endPoint + params.bank_account_id + '/associations', params.items)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    removeAssociateFinanceTransaction({commit}, id){
      return createAxios().delete(endPoint + id + '/associations')
        .then(({data}) => {
          commit(types.DESTROY, id)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    }
  }
}
