import AdjustmentHistoryActionsEnum from "../../adjustment-history/enums/AdjustmentHistoryActionsEnum";
import AdjustmentHistoryTypesEnum from "../../adjustment-history/enums/AdjustmentHistoryTypesEnum";
import AdjustmentEnum from "../../adjustment-history/enums/AdjustmentEnum";
import { CmcAdjustmentListType, DosageAdjustmentByIdType, AgentType } from "../types";
import { AdjustmentHistoryCreateType } from "../../adjustment-history/types";
import adjustmentHistoryStore from "../../adjustment-history/store";

/**
 * Serviço que faz a manipulação dos objetos
 * para criação de histórico (ajuste, edição e remoção)
 * do Mapa do Ponto de Carga
 *
 * @param {number} action_id
 * @param {CmcAdjustmentListType} cmcAdjustment
 * @param {?DosageAdjustmentByIdType} adjustment
 * @param {?Array<object>} agentGroups - array de grupos de aditivo
 */
const execute = (action_id, cmcAdjustment, adjustment = null, agentGroups = null) => {

  /** @type {string} */
  const title = formatTitle(
    cmcAdjustment.group_name,
    cmcAdjustment.cmc_product,
    cmcAdjustment.cmc_supplier,
    cmcAdjustment.supplier_origin,
    cmcAdjustment.category_name
  );

  switch (action_id) {
    case AdjustmentHistoryActionsEnum.keys.ADJUST:
      const agent = formatAgent(adjustment.agent, agentGroups);

      /** @type {AdjustmentHistoryCreateType} */
      const historyObjectAdjust = {
        action: action_id,
        type: AdjustmentHistoryTypesEnum.keys.INDIVIDUAL,
        adjust: AdjustmentEnum.keys.MCC,
        title: title,
        company_plant_ids: [cmcAdjustment.company_plant_id],
        binder: adjustment.binder,
        water: adjustment.water,
        content_mortar: adjustment.content_mortar,
        total_air: adjustment.total_air,
        agent: agent,
        observation_mcc: adjustment.observation,
        content: cmcAdjustment.content,
        content_min: cmcAdjustment.content_min,
        content_max: cmcAdjustment.content_max,
        observation_content: cmcAdjustment.observation_content,
      };

      adjustmentHistoryStore.create(historyObjectAdjust);
      break;

    case AdjustmentHistoryActionsEnum.keys.REMOVE:
      /** @type {AdjustmentHistoryCreateType} */
      const historyObjectRemove = {
        action: action_id,
        type: AdjustmentHistoryTypesEnum.keys.INDIVIDUAL,
        adjust: AdjustmentEnum.keys.MCC,
        title: title,
      };

      adjustmentHistoryStore.create(historyObjectRemove);
      break;

    case AdjustmentHistoryActionsEnum.keys.EDIT:
      /** @type {AdjustmentHistoryCreateType} */
      const historyObjectEdit = {
        action: action_id,
        type: AdjustmentHistoryTypesEnum.keys.INDIVIDUAL,
        adjust: AdjustmentEnum.keys.MCC,
        title: title,
        dosage_locations: cmcAdjustment.dosage_locations
      };

      adjustmentHistoryStore.create(historyObjectEdit);
    break;

    default:
      break;
  }
};

/**
 * Construção do formato do título do histórico
 *
 * @param {string} group_name
 * @param {string} cmc_product
 * @param {string} cmc_supplier
 * @param {string} supplier_origin
 * @param {string} category_name
 * @returns {string}
 */
const formatTitle = (
  group_name,
  cmc_product,
  cmc_supplier,
  supplier_origin,
  category_name
) => {
  return `${group_name} | ${cmc_product} - ${cmc_supplier} (${supplier_origin}) | ${category_name ?? "Puzl Admix"}`;
};

/**
 * Se o ajuste conter aditivo, é necessário
 * salvar o nome do grupo para apresentar
 * no histórico. Serviço criado para 
 * não precisar acessar o backend.
 * 
 * @param {Array<AgentType>} agents
 * @param {Array<Object>} agentGroups
 * @returns {Array<AgentType>}
 */
const formatAgent = (agents, agentGroups) => {
  if(agentGroups !== null) {
    const nameMap = agentGroups.value.reduce((acc, item) => {
      acc[item.id] = item.group_name;
      return acc;
    }, {});

    return agents.map(item => ({
      ...item,
      name: nameMap[item.id]
    }));
  } else {
    return agents;
  }
}

export default { execute, formatTitle };
