<template>
  <div class="card-body mt-n2 p-0">
    <div class="row">
      <ShadedTable :length="tributes.length"
        :headers="headers"
        :loading="loading">
        <template v-for="(tribute, index) in tributes" :slot="index">
          <ShadedCol>
            <div>
                <h5 :class="tribute.status ? 'text-success' : 'text-danger'" class="m-0">
                  {{tribute.status ? 'Ativo' : 'Inativo' }}
                </h5>
            </div>
            <div>
                <el-popover v-if="tribute.updated_by" trigger="click" placement="left">
                  <span style="width: 200px;"
                    class="row">
                    <div class="col-12 mb-1" style="display: flex; align-items: center;">
                      <img class="mr-2" src="/img/icons/icons8/ios/user-male-circle--v1.png" width="22">
                      <h4 class="p-0 m-0">Usuário</h4>
                    </div>
                    <div class="col-12">
                      <h5 class="font-weight-normal" style="word-break: break-word;">
                        {{ tribute.updated_by.name }}
                      </h5>
                    </div>
                  </span>
                  <base-button outline slot="reference" size="sm" type="secundary"
                          class="shadow-none text-indigo">
                      <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="20">
                  </base-button>
                </el-popover>
            </div>
          </ShadedCol>
          <ShadedCol>
            <div>
              <h5 class="new-default-black-font font-weight-normal m-0">
                {{ verifyText(getLastValitity(tribute), "condition") }}
              </h5>
            </div>
            <div>
              <el-popover v-if="getLastValitity(tribute)" trigger="click" placement="left">
                <span style="width: 370px;"
                  class="row">
                  <div class="col-12 mb-1" style="display: flex; align-items: center;">
                    <img class="mr-2" src="/img/icons/info.png" width="22">
                    <h4 class="p-0 m-0">Info alíquotas</h4>
                  </div>
                  <div class="col-12">
                    <div class="row align-items-center mt-3">
                      <div class="col-auto pr-0">
                        <h5 class="font-weight-normal text-primary m-0">
                          Condição
                        </h5>
                      </div>
                      <div class="col px-3">
                        <hr class="m-0">
                      </div>
                      <div class="col-auto pl-0 text-right">
                        <h5 class="font-weight-normal text-primary m-0">
                          {{ getLastValitity(tribute).condition }}
                        </h5>
                      </div>
                    </div>
                    <div class="row align-items-center mt-3">
                      <div class="col-auto pr-0">
                        <h5 class="font-weight-normal new-default-black-font m-0">
                          Deduzir custo de MCC
                        </h5>
                      </div>
                      <div class="col px-3">
                        <hr class="m-0">
                      </div>
                      <div class="col-auto pl-0 text-right">
                        <h5 class="font-weight-normal new-default-black-font m-0">
                          {{ getLastValitity(tribute).deduct_mcc_cost ? 'Sim' : 'Não' }}
                        </h5>
                      </div>
                    </div>
                    <div class="row align-items-center mt-3">
                      <div class="col-auto pr-0">
                        <h5 class="font-weight-normal new-default-black-font m-0">
                          Considerar Reuso
                        </h5>
                      </div>
                      <div class="col px-3">
                        <hr class="m-0">
                      </div>
                      <div class="col-auto pl-0 text-right">
                        <h5 class="font-weight-normal new-default-black-font m-0">
                          {{ getLastValitity(tribute).consider_reuse ? 'Sim' : 'Não' }}
                        </h5>
                      </div>
                    </div>
                    <div class="row align-items-center mt-3">
                      <div class="col-auto pr-0">
                        <h5 class="font-weight-normal new-default-black-font m-0">
                          Dedução
                        </h5>
                      </div>
                      <div class="col px-3">
                        <hr class="m-0">
                      </div>
                      <div class="col-auto pl-0 text-right">
                        <h5 class="font-weight-normal new-default-black-font m-0">
                          {{ verifyCondition(getLastValitity(tribute), "deduct") }}
                        </h5>
                      </div>
                    </div>
                    <div class="row align-items-center mt-3">
                      <div class="col-auto pr-0">
                        <h5 class="new-default-black-font m-0">
                          Base cálculo
                        </h5>
                      </div>
                      <div class="col px-3">
                        <hr class="m-0">
                      </div>
                      <div class="col-auto pl-0 text-right mt-2 mb-1">
                        <h5 class="new-default-black-font m-0">
                          {{ verifyCondition(getLastValitity(tribute), "base") }}
                        </h5>
                      </div>
                    </div>
                    <div class="row align-items-center mt-3">
                      <div class="col-auto pr-0">
                        <h5 class="text-success font-weight-normal m-0">
                          Alíquota total
                        </h5>
                      </div>
                      <div class="col px-3">
                        <hr class="m-0">
                      </div>
                      <div class="col-auto pl-0 text-right">
                        <h5 class="font-weight-normal text-success m-0">
                          {{ getLastValitity(tribute).total_rate }}%
                        </h5>
                      </div>
                    </div>
                    <div class="row align-items-center mt-3">
                      <div class="col-auto pr-0">
                        <h5 class="new-default-black-font font-weight-normal">
                          Alíquota retida
                        </h5>
                      </div>
                      <div class="col px-3">
                        <hr class="m-0">
                      </div>
                      <div class="col-auto pl-0 text-right">
                        <h5 class="font-weight-normal new-default-black-font m-0">
                          {{ getLastValitity(tribute).retained_rate }}%
                        </h5>
                      </div>
                    </div>
                  </div>
                </span>
                <base-button outline slot="reference" size="sm" type="secundary"
                        class="p-0 m-0 ml-2 shadow-none text-indigo">
                    <img src="/img/icons/info.png" width="20">
                </base-button>
              </el-popover>
              <el-popover v-if="tribute.entity" trigger="click" placement="left">
                <span style="width: 250px;"
                  class="row">
                  <div class="col-12 mb-1" style="display: flex; align-items: center;">
                    <img class="mr-2" src="/img/icons/icons8/ios/city-hall.png" width="22">
                    <h4 class="p-0 m-0">CNPJ</h4>
                  </div>
                  <div class="col-12">
                    <h5 class="font-weight-normal">
                      {{ tribute.entity.entity_name }}
                    </h5>
                    <h5 class="font-weight-normal" style="word-break: break-word;">
                      {{ tribute.entity.document }}
                    </h5>
                  </div>
                </span>
                <base-button outline slot="reference" size="sm" type="secundary"
                        class="p-0 m-0 ml-1 shadow-none text-indigo">
                    <img src="/img/icons/icons8/ios/city-hall.png" width="20">
                </base-button>
              </el-popover>
            </div>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              <i style="font-style: normal;font-size: 13px;">{{ tribute.city }}</i>
              <i style="font-style: normal;font-size: 13px;" class="mx-1" v-if="tribute.state">-</i>
              <i style="font-style: normal;font-size: 13px;">{{ tribute.state }}</i>
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ tribute.regime }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ verifyCondition(getLastValitity(tribute), "deduct") }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ verifyCondition(getLastValitity(tribute), "base") }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="text-success font-weight-normal m-0 mt-1">
              {{ getLastValitity(tribute) ? Number(getLastValitity(tribute).total_rate) + '%' : 'n/a'}}
            </h5>
          </ShadedCol>
          <ShadedCol align="center">
            <base-dropdown menuOnRight>
              <div slot="title-container" class="dropdown-toggle rounded m-0">
                <img width="22" src="/img/icons/icons8/ios/settings--v1_primary.png" />
              </div>
              <a class="dropdown-item font-weight-500 new-default-black-font"
                style="align-items: center; display: flex"
                @click="handleEditTax(tribute)">
                <img width="22" src="/img/icons/create-new.png" />
                Editar
              </a>
              <div class="dropdown-divider p-0 m-0"></div>
              <a class="dropdown-item font-weight-500 new-default-black-font"
                style="align-items: center; display: flex"
                @click="handleListTaxRates(tribute)">
                <img width="22" src="/img/icons/icons8/ios/cash-receipt.png" />
                Alíquotas
              </a>
              <div class="dropdown-divider p-0 m-0"></div>
              <a class="dropdown-item font-weight-500 new-default-black-font"
                style="align-items: center; display: flex"
                @click="handleLocateCpfCnpj(tribute)">
                <img width="22" src="/img/icons/icons8/ios/city-hall.png" />
                CNPJ
              </a>
              <div class="dropdown-divider p-0 m-0"></div>
              <a class="dropdown-item font-weight-500 new-default-black-font"
                style="align-items: center; display: flex"
                @click="handleDeleteTax(tribute.uuid)">
                <img width="22" src="/img/icons/icons8/ios/delete--v1_danger.png" />
                Excluir
              </a>
            </base-dropdown>
          </ShadedCol>
        </template>
      </ShadedTable>
    </div>
  </div>
</template>

<script>
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import { mapGetters } from "vuex";
import SkeletonPuzl from "@/components/SkeletonPuzl.vue";
import ShadedTable from "@/components/ShadedTable.vue";
import ShadedCol from "@/components/ShadedCol.vue";

export default {
  props: {
    tributes: Array,
    loading: Boolean,
    tributeSelected: null,
    newValidity: { type: Function },
    getLastValitity: { type: Function },
    handleDeleteTax: { type: Function },
    handleDetailDeductionRules: { type: Function },
    handleEditTax: { type: Function },
    handleListTaxRates: { type: Function },
    handleLocateCpfCnpj: { type: Function },
    updateTributeWithselectedEntity: { type: Function },
    verifyText: { type: Function },
    verifyCondition: { type: Function },
    tributeSelected: Object,
  },
  name: "ListTributes",
  data() {
    return {
      headers: [
        "Status",
        "Condição",
        "Cidade",
        "Regime",
        "Dedução",
        "Base de cálculo",
        "Alíquota",
        "Ação"
      ],
    };
  },
  components: {
    SkeletonPuzlGrid,
    SkeletonPuzl,
    ShadedTable,
    ShadedCol
  },
  methods: {},
};
</script>

<style scoped>

</style>
