
import * as types from './mutation_types'
import { createAxios } from "@/plugins/axios";
const endPoint = '/financial/cashier-resume'
export default {
  namespaced: true,
  state: {
    itemsBillReceive: [],
    itemsBillReceiveSecond: [],
    itemsBillPay: [],
    itemsBillPaySecond: [],
  },
  getters:{
    fetchBillReceive: state => state.itemsBillReceive,
    fetchBillReceiveSecond: state => state.itemsBillReceiveSecond,
    fetchBillPay: state => state.itemsBillPay,
    fetchBillPaySecond: state => state.itemsBillPaySecond,
  },
  mutations: {
    [types.SET_BILL_RECEIVE] (state, payload){
      state.itemsBillReceive = payload
    },
    [types.SET_BILL_RECEIVE_SECOND] (state, payload){
      state.itemsBillReceiveSecond = payload
    },
    [types.SET_BILL_PAY] (state, payload){
      state.itemsBillPay = payload
    },
    [types.SET_BILL_PAY_SECOND] (state, payload){
      state.itemsBillPaySecond = payload
    },
  },
  actions: {
    fetchItemsPaginateBillReceive({commit}, params) {
      return createAxios().get(`${endPoint}/bill-receive`, {params: params})
        .then(({data}) =>{
          commit(types.SET_BILL_RECEIVE, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchItemsPaginateBillReceiveSecond({commit}, params) {
      return createAxios().get(`${endPoint}/bill-receive-second`, {params: params})
        .then(({data}) =>{
          commit(types.SET_BILL_RECEIVE_SECOND, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    countTotalCashierResumeBillReceive({commit}, params) {
      return createAxios().get(`${endPoint}/count-total-resume-bill-receive`, {params: params})
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },

    getCountTotalApprovedAnticipationBillReceive({}, params) {
      return createAxios().get(`${endPoint}/count-total-approved-anticipation-bill-receive`, {params: params})
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },

    fetchItemsPaginateBillPay({commit}, params) {
      return createAxios().get(`${endPoint}/bill-pay`, {params: params})
        .then(({data}) =>{
          commit(types.SET_BILL_PAY, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchItemsPaginateBillPaySecond({commit}, params) {
      return createAxios().get(`${endPoint}/bill-pay-second`, {params: params})
        .then(({data}) =>{
          commit(types.SET_BILL_PAY_SECOND, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    countTotalCashierResumeBillPay({commit}, params) {
      return createAxios().get(`${endPoint}/count-total-resume-bill-pay`, {params: params})
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    countTotalsBankBalance({}, params) {
      return createAxios().get(`${endPoint}/count-total-bank-balance`, {params: params})
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
