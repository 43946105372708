import { urls } from '.';
import httpClient from '../../../../shared/libraries/httpClient';
import { handleUnexpectedStatusCode } from '../../../../shared/services';
import { HttpStatusEnum } from '../../../../shared/enums';


/**
 * Incluir
 * 
 * @param {FormData} data
 * @returns {Promise<void>}
 */
const execute = async (data) => {
  const res = await httpClient.post(`${urls.BASE}/by-attachment`, data);

  switch (res.status) {
    case HttpStatusEnum.HTTP_OK: return res.data.data;
    default: handleUnexpectedStatusCode(res);
  }
};

export default { execute };