<template>
  <div>
    <card>
      <SkeletonPuzlFullWidth v-if="loading" />
      <Gantt v-else-if="items.length" :items="items" :headers="headers" />
      <PuzlEmptyData v-else />
    </card>
  </div>
</template>

<script>
import Gantt from "@/components/Gantt";
import { headers } from "./scheduleTravelGanttHeaders";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzlFullWidth from "@/components/SkeletonPuzlFullWidth";

export default {
  name: "ScheduleTravelGantt",
  components: {
    Gantt,
    PuzlEmptyData,
    SkeletonPuzlFullWidth,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      headers,
    };
  },
  methods: {},
};
</script>
