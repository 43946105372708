import dayjs from "dayjs";
import DateBase from "../Base/DateBase";
import { FORMAT, DURATION_INPUT, UNIT_OF_TIME } from "../Enums";

/**
 * Classe adaptadora para manipulação de datas usando Day.js.
 *
 * @extends DateBase
 */
class DateDay extends DateBase {
  constructor(date) {
    super(date);
  }

  toDate() {
    return dayjs(this.date).toDate();
  }

  format(formatString = FORMAT.FULL_DATE_SLASH) {
    return dayjs(this.date).format(formatString);
  }

  add(amount, durationInput = DURATION_INPUT.DAYS) {
    this.date = dayjs(this.date).add(amount, durationInput).toDate();
    return this;
  }

  subtract(amount, durationInput = DURATION_INPUT.DAYS) {
    this.date = dayjs(this.date).subtract(amount, durationInput).toDate();
    return this;
  }

  diff(otherDate, durationInput = DURATION_INPUT.DAYS) {
    return dayjs(this.date).diff(otherDate.date, durationInput);
  }

  isBefore = (otherDate) => {
    return dayjs(this.date).isBefore(dayjs(otherDate));
  };

  isAfter = (otherDate) => {
    return dayjs(this.date).isAfter(dayjs(otherDate));
  };

  setStartOf = (unitOfTime = UNIT_OF_TIME.MONTH) => {
    this.date = dayjs(this.date).startOf(unitOfTime).toDate();
    return this;
  };

  setEndOf = (unitOfTime = UNIT_OF_TIME.MONTH) => {
    this.date = dayjs(this.date).endOf(unitOfTime).toDate();
    return this;
  };  
}

export default DateDay;
