<template>
  <div>
    <modal :show.sync="modal" size="lg">
      <template slot="header">
        <div style="display: flex;align-items: center;">
          <img style="display: inline-flex;" src="/img/icons/icons8/ios/order-history.png" width="22">
          <h5 style="display: inline-flex;" class="modal-title p-0 m-0 ml-3 text-white">{{ title }}</h5>
        </div>
      </template>
      <div>
        <div class="container-fluid">
          <div class="row mt-3 mb-4">
            <div class="col-12" style="display: flex; align-items: center;">
              <img src="/img/icons/icons8/ios/info-squared_warning.png" width="20">
              <h4 class="new-default-black-font m-0 mx-3" style="white-space: nowrap;">
                Informação
              </h4>
              <hr class="ml-3 m-0" style="width: 100%;">
            </div>
          </div>
          <div v-if="financeTransaction"  class="row mb-3 align-items-center">
            <div class="col-12">
              <h4 class="new-default-black-font font-weight-normal m-0">
                {{ financeTransaction.entity_name }}
              </h4>
              <h5 class="new-default-gray-font font-weight-normal m-0">
                {{ financeTransaction.entity_document }}
              </h5>
            </div>
          </div>
          <div class="row mt-4 mb-4">
            <div class="col-12" style="display: flex; align-items: center;">
              <img src="/img/icons/order-history.png" width="20">
              <h4 class="new-default-black-font m-0 mx-3" style="white-space: nowrap;">
                Histórico
              </h4>
            </div>
          </div>
          <div v-if="history.length" class="custom-card-wrapper mb-3 pt-4">
            <div class="container">
              <div class="row card-wrapper px-1">
                <div class="col-lg-12 px-1 mt-3" v-if="history.length" v-for="(item, index) in history" :key="index">
                  <div class="container">
                    <div class="row">
                      <div class="col-12" style="display: flex; align-items: center;">
                        <el-popover trigger="hover" placement="right">
                          <span style="width: 210px;" class="row">
                            <div class="col-12 mb-2" style="display: flex; align-items: center;">
                              <img class="mr-2" src="/img/icons/icons8/ios/user-male-circle--v1.png" width="22">
                              <h4 style="font-size: 14px;" class="p-0 m-0 font-weight-500">Informações</h4>
                            </div>
                            <div class="col-12 mt-0 mb-n2">
                              <h5 class="font-weight-normal fs-12"><b class="fs-13">Usuário: </b>
                                {{ item.created_by_user_name ? item.created_by_user_name : item.updated_by_user_name }}
                              </h5>
                            </div>
                            <div class="col-12">
                              <h5 class="font-weight-normal fs-12"><b class="fs-13">Conta: </b>{{ item.bank_account_name }}</h5>
                            </div>
                          </span>
                          <base-button outline slot="reference" size="sm" type="secundary"
                            class="p-0 m-0 shadow-none">
                            <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="22">
                          </base-button>
                        </el-popover>
                        <h4 class="new-default-black-font m-0 mx-3" style="white-space: nowrap;">
                          {{ item.created_at | parseDate('DD MMM YYYY') }}
                        </h4>
                        <hr class="ml-3 m-0" style="width: 100%;">
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-12">
                        <div class="card main-card mb-3">
                          <div class="pt-3 px-3">
                            <div class="row align-items-center">
                              <div class="col-12" style="display: inline-flex; align-items: center;">
                                <img :src="statusEnum[item.status].img" width="17">
                                <h4 class="m-0 ml-2 new-default-black-font">{{ statusEnum[item.status].text }}</h4>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-12">
                                <p class="status-reason new-default-black-font mb-0">
                                  {{ financeTransaction.finance_type_name }}
                                </p>
                              </div>
                              <div class="col-6">
                                <h5 class="m-0 new-default-black-font">{{ item.amount | currency() }}</h5>
                              </div>
                              <div class="col-6">
                                <p class="status-reason-description new-default-black-font text-right">
                                  {{ statusEnum[item.status].text }}
                                  <i v-if="financeTransactionStatus.PENDING != item.status">
                                    em {{ item.discharge_at | parseDate('DD MMM YYYY') }}
                                  </i>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PuzlEmptyData msg="Não existem operações suficientes para geração de histórico" v-else />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import PuzlEmptyData from "@/components/PuzlEmptyData";

const FINANCE_TRANSACTION_ENUM = Object.freeze({
  FINANCE_INSTALLMENT_ID: 0,
  BILL_PAY_INSTALLMENT_ID: 1,
  CONTRACT_PROPOSAL_PAYMENT_INSTALLMENT_ID: 2,
  ENTITY_BILL_RECEIVE_INSTALLMENT_ID: 3,
  BANK_TRANSFER_ID: 4
});

const FINANCE_TRANSACTION_STATUS = Object.freeze({
  PENDING: 0,
  RECONCILED: 1,
  JUSTIFIED: 2
});

export default {
  components: { PuzlEmptyData },
  name: "ModalConciliationHistory",
  data() {
    return {
      modal: false,
      title: "Histórico de operações",
      financeTransaction: [],
      history: [],
      financeTransactionEnum: FINANCE_TRANSACTION_ENUM,
      financeTransactionStatus: FINANCE_TRANSACTION_STATUS,
      statusEnum: {
        0: {
          text: 'Pendente',
          description: "PENDING",
          class: "warning",
          img: "/img/icons/icons8/ios/future_secondary.png"
        },
        1: {
          text: 'Conciliado',
          description: "RECONCILED",
          class: "primary",
          img: "/img/icons/icons8/ios/link_primary.png"
        },
        2: {
          text: 'Justificado',
          description: "JUSTIFIED",
          class: "gray",
          img: "/img/icons/icons8/ios/inscription_warning.png"
        },
      }
    };
  },
  computed: {

  },
  methods: {
    close() {
      this.modal = false;
    },
    openModal(financeTransaction) {
      this.financeTransaction = financeTransaction;

      const generateByFieldMap = {
        [this.financeTransactionEnum.FINANCE_INSTALLMENT_ID]: "finance_installment_id",
        [this.financeTransactionEnum.BILL_PAY_INSTALLMENT_ID]: "bill_pay_installment_id",
        [this.financeTransactionEnum.CONTRACT_PROPOSAL_PAYMENT_INSTALLMENT_ID]: "contract_proposal_payment_installment_id",
        [this.financeTransactionEnum.ENTITY_BILL_RECEIVE_INSTALLMENT_ID]: "entity_bill_receive_installment_id",
        [this.financeTransactionEnum.BANK_TRANSFER_ID]: "bank_transfer_id"
      };

      const idType = generateByFieldMap[financeTransaction.move_type];
      if (idType) {
        this.getAll(financeTransaction[idType], idType);
      }

      this.modal = true;
    },
    getAll(id, filter){
      this.$store
        .dispatch("financeTransactions/history", {
          [filter]: id,
          limit: 50,
          order_by: [
            {
              column: "id",
              is_desc: false,
            },
          ],
        })
        .then(response => {
          this.history = response.data.items;
          this.loadingSkeleton = false;
        })
        .catch(error => {
          this.loadingSkeleton = false;
        });
    }
  },
};
</script>

<style scoped>
.gray-divider {
  background-color: #E8E8E8;
  color: #E8E8E8;
  margin: 0;
  border-top: 1px solid #E8E8E8;
}

h1, h2, h3, h4, h5, p {
	font-family: "Fredoka", sans-serif;
}

.status-reason {
  font-family: Fredoka;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
}

.status-reason-description {
  font-family: Fredoka;
  font-size: 10px;
  font-weight: 500;
  text-align: left;
}

.status-reason-description i {
  font-style: normal;
  font-size: 10px;
  font-weight: 400;
}

.custom-card-wrapper {
	border: 1px solid #eeeeee;
  background: #1A70B70D;
	border-radius: 16px;
	padding: 16px 12px;
	min-height: 55px;
	max-height: 520px;
  overflow-y: scroll;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.07) !important;
}

.card {
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 16px !important;
  border: 1px solid #e2e4e7;
  background: #FFFFFF80;
}
</style>
