<template>
  <div>
    <modal size="sm" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <div>
        <div v-if="$_resume" class="container-fluid">
          <div class="row">
            <div class="col-md-12 mb-1">
              <input-date-picker @handleFilterDate="init" v-model="filter.range" />
              <label class="col-form-label pb-1 form-control-label mb-0 mt-1"> Conta bancária </label>
              <base-input input-classes="form-control-sm">
                <puzl-select @input="init" :multiple="true" v-model="filter.bank_account_ids" :items="$_bank_accounts" />
              </base-input>
            </div>
            <div class="col-md-12 mt-2">
              <small>
                Valor total:
                <span style="font-size: 0.9rem" class="pl-1 money font-weight-600">{{
                  $_resume.total_value || 0 | currency()
                }}</span></small
              >
            </div>
            <div class="col-md-12 mt-2 mb-3">
              <small>
                Fornecedores:
                <span style="font-size: 0.9rem" class="pl-1 money font-weight-600">{{ $_resume.total_entity }}</span></small
              >
            </div>
            <div class="col-md-12 pl-2 ml-1 border-left border-3 border-success mt-1 mb-1">
              <small>
                Pago:
                <span style="font-size: 0.9rem" class="money font-weight-600">
                  {{ $_resume.total_paid || 0 | currency() }} ({{ $_resume.total_paid_quantity }})</span
                ></small
              >
            </div>
            <div class="col-md-12 pl-2 ml-1 border-left border-3 border-approved mt-1 mb-1">
              <small>
                Aprovado:
                <span style="font-size: 0.9rem" class="money font-weight-600">
                  {{ $_resume.total_approved || 0 | currency() }} ({{ $_resume.total_approved_quantity }})</span
                ></small
              >
            </div>
            <div class="col-md-12 pl-2 ml-1 border-left border-3 border-warning mt-1 mb-1">
              <small>
                Pendente:
                <span style="font-size: 0.9rem" class="money font-weight-600">
                  {{ $_resume.total_pending || 0 | currency() }} ({{ $_resume.total_pending_quantity }})</span
                ></small
              >
            </div>
            <div class="col-md-12 pl-2 ml-1 border-left border-3 border-danger">
              <small>
                Atrasado:
                <span style="font-size: 0.9rem" class="money font-weight-600">
                  {{ $_resume.total_overdue || 0 | currency() }} ({{ $_resume.total_overdue_quantity }})</span
                ></small
              >
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center row m-3 pb-1 p-0">
          <div class="col-md-4 pl-1 pr-1">
            <base-button block type="secondary" @click="closeModal()"> Fechar</base-button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import InputDatePicker from "@/components/InputDatePicker";
import { mapGetters } from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
import moment from "moment";

export default {
  name: "ModalResume",
  data() {
    return {
      modal: {
        title: "Resumo",
        create: false,
      },
      filter: {
        range: {
          start: null,
          end: null,
        },
        bank_account_ids: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      $_resume: "billPay/getResume",
      $_bank_accounts: "bankAccount/fetch",
    }),
  },
  components: {
    InputDatePicker,
    PuzlSelect,
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    async openModal() {
      this.filter = {
        range: {
          start: this.$helper.parseDate(moment().toString(), "YYYY-MM-DD HH:mm:ss"),
          end: this.$helper.parseDate(moment().toString(), "YYYY-MM-DD HH:mm:ss"),
        },
        bank_account_ids: [],
      };
      await this.init();
      this.modal.create = true;
    },
    init(range) {
      let loader = this.$loading.show();
      if (range && range.start) this.filter.range = range;
      return this.$store.dispatch(`billPay/getResume`, { filter: this.filter }).then((response) => {
        this.$notify({
          type: response.error_type,
          message: response.message,
        });
        loader.hide();
      });
    },
  },
};
</script>
