<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(store)"
            autocomplete="off"
          >
            <div>
              <!-- Menu -->
              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                >
                  Menu
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7 pr-4">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <puzl-select 
                        v-model="menu.uuid" 
                        :items="menus"
                        customKey="uuid" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Tag -->
              <div class="form-group row m-0 p-0">
                <div class="col-md-10 pr-0 pl-0">
                  <div class="form-group row m-0 p-0 ">
                    <label
                      class="col-md-6 pb-0 col-form-label form-control-label mb-1 pt-3 mt-1 align-middle"
                    >
                      Tag
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-5 pt-3 pr-4 ">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-group-classes="input-group-sm">
                          <input
                            v-model="currentTag"
                            class="form-control form-control-sm"
                            :class="errors[0] ? 'is-invalid' : 'is-valid'"
                          />
                          <template slot="append"> </template>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-2 d-flex justify-content-center align-items-center pt-3"
                >
                  <base-button
                    @click="addTag"
                    type="success"
                    native-type="button"
                    class="btn btn-sm align-items-center mr-2"
                    block
                  >
                    <i class="fas fa-plus"></i>
                  </base-button>
                </div>
              </div>
              <!-- lista de tags -->
              <div
                class="m-2 mt-3 mb-1"
                v-for="(tag, index) in menu.tags"
                :key="index"
              >
                <card
                  class="border card--hover card-body-molde mb-0"
                  :bodyClasses="'p-2'"
                >
                  <div class="row align-items-center">
                    <div class="col-10 text-center pl-2 pr-2">
                      <h6 class="mb-0">
                        {{ tag }}
                      </h6>
                    </div>
                    <div class="col-2 text-center pl-1">
                      <button
                        type="button"
                        class="btn btn-sm rounded-circle btn-danger"
                        @click="removeTag(index)"
                      >
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                </card>
              </div>
              <div class="modal-footer">
                <base-button type="secondary" @click="closeModal('create')">
                  Cancelar
                </base-button>
                <base-button
                  type="success"
                  native-type="submit"
                  v-bind:disabled="invalid"
                  :loading="loadingStore"
                >
                  Salvar
                </base-button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatErrorValidation } from "@/plugins";
import PuzlSelect from "@/components/PuzlSelect";
export default {
  name: "ModalCreateMenu",
  components: { PuzlSelect },
  data() {
    return {
      modal: {
        title: "Adicionar Busca de Menu",
        create: false
      },
      currentTag: null,
      menu: {
        uuid: null,
        tags: []
      },
      loadingStore: false
    };
  },
  computed: {
    ...mapGetters({
      menus: "menu/fetch"
    })
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    handleCreateModal() {
      this.menu = {
        uuid: null,
        tags: []
      };
      this.modal.create = true;
    },
    store() {
      if (
        this.menus.filter(item => item.uuid == this.menu.uuid)[0].tags.length
      ) {
        this.$swal
          .fire({
            title: "Você tem certeza?",
            text:
              "O menu selecionado já possui tags atribuídas a ele, isto fará com que as tags sejam substituídas!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim, substitua!",
            cancelButtonText: "Não, mantenha",
            customClass: {
              confirmButton: "btn btn-success btn-fill",
              cancelButton: "btn btn-danger btn-fill"
            },
            buttonsStyling: false
          })
          .then(result => {
            if (result.isConfirmed) {
              this.update();
            } else {
              this.modal.create = false;
            }
          });
        return false;
      }
      this.update();
    },
    update() {
      this.$Progress.start();
      this.loadingStore = true;
      this.$store
        .dispatch("menu/update", this.menu)
        .then(response => {
          this.loadingStore = false;
          this.modal.create = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.$emit("createdMenu");
        })
        .catch(error => {
          if (error.status == 200) {
            this.$notify({
              type: "danger",
              message: error.data.message
            });
            this.$Progress.finish();
            this.loadingStore = false;
          } else if (error.response && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors
            });
          }
          this.$Progress.finish();
          this.loadingStore = false;
        });
    },
    addTag() {
      if (this.menu.tags.filter(item => item == this.currentTag)[0]) {
        this.$notify({
          type: "danger",
          message: "Tag já cadastrada!"
        });
        return false;
      }
      this.menu.tags.push(this.currentTag);
      this.currentTag = null;
    },
    removeTag(index) {
      this.menu.tags.splice(index, 1);
    }
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
  created() {
    this.$store.dispatch("menu/fetchItems");
  }
};
</script>

<style scoped></style>
