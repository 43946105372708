import { render, staticRenderFns } from "./_ModalProposalTerms.vue?vue&type=template&id=5377532f&scoped=true"
import script from "./_ModalProposalTerms.vue?vue&type=script&lang=js"
export * from "./_ModalProposalTerms.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5377532f",
  null
  
)

export default component.exports