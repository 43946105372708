<template>
  <div>
    <modal :show.sync="modal.open">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>

      <div v-show="loadingSkeleton">
        <div class="form-group row m-0 p-0">
          <div class="col-md-5">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
          <div class="col-md-7">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
        </div>
        <div class="form-group row m-0 p-0">
          <div class="col-md-3">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
          <div class="col-md-9">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
        </div>
        <div class="form-group row m-0 p-0">
          <div class="col-md-4">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
          <div class="col-md-8">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
        </div>
      </div>

      <div v-show="!loadingSkeleton">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
            <div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  {{ feature.short_description }}
                  <span class="small" v-if="feature.measured_unit.id!==99">
                   ({{ feature.measured_unit.initials }})
                  </span>
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <div v-if="feature.type===2">
                        <input
                          type="text"
                          v-model="feature_item.description"
                          class="form-control form-control-sm"
                        />
                      </div>
                      <div v-if="feature.type===1 && feature.decimal_places===0">
                        <input
                          type="text"
                          v-model="feature_item.description"
                          class="form-control form-control-sm"
                          v-mask="['####']"
                        />
                      </div>
                      <div v-if="feature.type===1 && feature.decimal_places===1">
                        <input
                          type="text"
                          v-model="feature_item.description"
                          class="form-control form-control-sm"
                          v-mask="['####.#','###.#','##.#','#.#']"
                        />
                      </div>
                      <div v-if="feature.type===1 && feature.decimal_places===2">
                        <input
                          type="text"
                          v-model="feature_item.description"
                          class="form-control form-control-sm"
                          v-mask="['####.##','###.##','##.##','#.##']"
                        />
                      </div>
                      <div v-if="feature.type===1 && feature.decimal_places===3">
                        <input
                          type="text"
                          v-model="feature_item.description"
                          class="form-control form-control-sm"
                          v-mask="['####.###','###.###','##.###','#.###']"
                        />
                      </div>
                    </base-input>

                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0" v-if="feature.type===1">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Sd
                  <span class="small" v-if="feature.measured_unit.id!==99">
                    ({{ feature.measured_unit.initials }})
                  </span>
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      v-model="feature_item.value_sd"
                      class="form-control form-control-sm"
                      v-mask="['#.##']"
                      @change="calculateValueMedium"
                    />
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0" v-if="feature.type===1">
                <label
                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                >
                  Nivel de Confiança
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <el-select v-model="feature_item.level_reliability"
                               placeholder="Selecione"
                               @change="calculateValueMedium"
                    >
                      <el-option v-for="reliability_level in reliability_levels"
                                 :key="reliability_level.id"
                                 :label="reliability_level.reliability+'%'"
                                 :value="reliability_level.value_z">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0" v-if="feature.type==1">
                <label
                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                >
                  Z
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      disabled
                      type="text"
                      v-model="feature_item.value_z"
                      class="form-control form-control-sm"
                    />
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0" v-if="feature.type===1">
                <label
                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                >
                  Valor Médio
                  <span class="small" v-if="feature.measured_unit.id!==99">
                   ({{ feature.measured_unit.initials }})
                  </span>
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      disabled
                      type="text"
                      v-model="feature_item.value_medium"
                      class="form-control form-control-sm"
                    />
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                >
                  Consumo Minimo
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      v-model="feature_item.consumption"
                      class="form-control form-control-sm"
                      v-mask="['####']"
                    />
                  </base-input>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pb-0 pt-1 col-form-label form-control-label">
                  Nível de aplicação <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <validation-provider rules="required" v-slot="{errors}">
                      <PuzlSelect
                        v-model="feature_item.feature_level"
                        :items="feature_levels"
                        multiple
                        customKey="value"
                        label="label"/>
                    </validation-provider>
                  </base-input>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 col-form-label form-control-label">
                  Permite Reuso
                </label>
                <div class="col-md-7 pt-2">
                  <base-switch
                    v-model="feature_item.reuse"
                    type="success"
                    offText="não"
                    onText="sim"
                    class="primary"
                  ></base-switch>
                </div>
              </div>

            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')">
              Cancelar
              </base-button>
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                :loading="loadingSave"
              >
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import SkeletonPuzl from "@/components/SkeletonPuzl";
import PuzlSelect from "@/components/PuzlSelect.vue";
const {formatErrorValidation} = require("@/plugins");

export default {
  name: "EditFeatureItem",
  components: {SkeletonPuzl, PuzlSelect},
  data() {
    return {
      modal: {
        title: 'Item da responsabilidade',
        open: false,
      },
      feature_levels: [
        {
          value: 1,
          label: 'Baixa'
        },
        {
          value: 2,
          label: 'Média'
        },
        {
          value: 3,
          label: 'Alta'
        },
      ],
      feature: {
        decimal_places: null,
        description: null,
        hardened_state: {
          id: null,
          short_description: null,
        },
        id: null,
        uuid: null,
        label: null,
        measured_unit: {
          id: null,
          initials: null,
          letter: null,
        },
        operator: null,
        period: null,
        period_type: null,
        short_description: null,
        status: false,
        tolerance: null,
        type: null,
      },
      feature_item: {
        description: null,
        value_sd: null,
        level_reliability: null,
        feature_level: null,
        value_z: null,
        value_medium: null,
        consumption: null,
        reuse: false,
      },
      loadingSave: false,
      loadingSkeleton: true,
    }
  },
  computed: {
    ...mapGetters({
      'reliability_levels': 'reliability_level/fetch',
    }),
  },
  methods: {
    closeModal() {
      this.modal.open = false
    },
    calculateValueMedium() {
      this.feature_item.value_z = this.feature_item.level_reliability;
      let value_medium = 0;

      let description = parseFloat(this.feature_item.description);
      let value_sd = parseFloat(this.feature_item.value_sd);
      let value_z = parseFloat(this.feature_item.level_reliability);

      value_medium = parseFloat(description + value_sd * value_z);

      if (!isNaN(value_medium)) {
        this.feature_item.value_medium = value_medium.toFixed(2);
      }
    },
    handleEditModal(featureUuid, featureItemUuid) {
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'})
      this.$Progress.start()

      this.loadingSkeleton = true

      this.feature = {
        decimal_places: null,
        description: null,
        hardened_state: {
          id: null,
          short_description: null,
        },
        id: null,
        uuid: null,
        label: null,
        measured_unit: {
          id: null,
          initials: null,
          letter: null,
        },
        operator: null,
        period: null,
        period_type: null,
        short_description: null,
        status: false,
        tolerance: null,
        type: null,
      }

      this.getFeature(featureUuid)
      this.getFeatureItem(featureItemUuid)
      this.loadingSkeleton = false
    },
    save() {
      this.$Progress.start()
      this.loadingSave = true

      this.$store.dispatch('mixFeatureItem/update', this.feature_item)
        .then(response => {
          this.loadingSave = false
          this.modal.open = false

          this.$store.dispatch('mixFeature/fetchItems')
          this.$Progress.finish()
          this.$notify({type: response.error_type, message: response.message})
        })
        .catch(error => {
          if (error.status) {
            this.$Progress.finish()
            this.loadingSave = false
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            })
          } else {
            if (error.response.status === 422) {
              let message = formatErrorValidation(error.response.data.errors)
              this.$notify({
                type: 'danger',
                message: message
              })
              this.$Progress.finish()
              this.loadingSave = false
            } else {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              })
              this.$Progress.finish()
              this.loadingSave = false
            }
          }
        })
    },
    getFeature(uuid) {
      this.$store.dispatch('mixFeature/show', uuid)
        .then((response) => {
          this.feature = response.data
          this.feature_item.mix_feature_id = this.feature.id

          this.modal.open = true
          this.$notify({type: response.error_type, message: response.message})
          this.$Progress.finish()
        })
        .catch(error => {
          if (error.status) {
            this.$Progress.finish()
            this.loadingSave = false
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            })
          } else {
            if (error.response.status === 422) {
              let message = formatErrorValidation(error.response.data.errors)
              this.$notify({
                type: 'danger',
                message: message
              })
              this.$Progress.finish()
              this.loadingSave = false
            } else {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              })
              this.$Progress.finish()
              this.loadingSave = false
            }
          }
        })
    },
    getFeatureItem(uuid) {
      this.feature_item = {
        id: null,
        uuid: null,
        description: null,
        value_sd: null,
        level_reliability: null,
        feature_level: null,
        value_z: null,
        value_medium: null,
        consumption: null,
        reuse: false,
      }
      this.$store.dispatch('mixFeatureItem/show', uuid)
        .then((response) => {
          let value = parseFloat(response.data.level_reliability) || 0
          let level = this.reliability_levels.filter(item => item.value_z === value)
          this.feature_item = {
            id: response.data.id || null,
            uuid: response.data.uuid || null,
            description: response.data.description || '',
            value_sd: response.data.value_sd || 0,
            level_reliability: level[0].value_z || 0,
            feature_level: response.data.feature_level || null,
            value_z: parseFloat(response.data.value_z) || 0,
            value_medium: parseFloat(response.data.value_medium) || 0,
            consumption: response.data.consumption || 0,
            reuse: response.data.reuse || false,
          }
          this.modal.open = true
        })
        .catch(error => {
          if (error.status) {
            this.$Progress.finish()
            this.loadingSave = false
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            })
          } else {
            if (error.response.status === 422) {
              let message = formatErrorValidation(error.response.data.errors)
              this.$notify({
                type: 'danger',
                message: message
              })
              this.$Progress.finish()
              this.loadingSave = false
            } else {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              })
              this.$Progress.finish()
              this.loadingSave = false
            }
          }
        })
    }
  },
  mounted() {
    this.$store.dispatch('reliability_level/fetchItems')
  },
}
</script>

<style scoped>

</style>
