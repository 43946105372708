export const SET = 'SET'
export const SET_LIST_INVOICES = 'SET_LIST_INVOICES'
export const SHOW_PENDING_BANK_BILLETS = 'SHOW_PENDING_BANK_BILLETS'
export const SET_PROPOSAL_OPEN_BILLING = 'SET_PROPOSAL_OPEN_BILLING'
export const SET_BILLS = 'SET_BILLS'
export const SET_MENTIONS = 'SET_MENTIONS'
export const DEFAULT_MENTION = 'DEFAULT_MENTION'
export const PUSH = 'PUSH'
export const SHOW = 'SHOW'
export const SET_MAIL_HISTORY = 'SET_MAIL_HISTORY'
export const SHOW_PAYMENTS = 'SHOW_PAYMENTS'
export const DESTROY = 'DESTROY'
export const DESTROY_BILLS = 'DESTROY_BILLS'
export const UPDATE = 'UPDATE'
