import { render, staticRenderFns } from "./_FinanceTypeForm.vue?vue&type=template&id=108c5962&scoped=true"
import script from "./_FinanceTypeForm.vue?vue&type=script&lang=js"
export * from "./_FinanceTypeForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "108c5962",
  null
  
)

export default component.exports