import * as types from './mutation_types'
import {destroy, update, cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'
import { createAxios } from "@/plugins/axios";

const endPoint = 'operational/stock-closing/'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
    latest_closing_at: null,
  },
  getters: {
    fetch: state => state.items,
    getLatestClosingAt: state => state.latest_closing_at,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload
    },
    [types.SET_LATEST_STOCK](state, payload) {
      state.latest_closing_at = payload
    },
    [types.PUSH](state, payload) {
      state.items.unshift(payload)
    },
  },
  actions: {
    fetchItems({commit, state}, params) {
      return createAxios().get(setUrlWithNextPage(endPoint, params),
        {params: params})
        .then(({data}) => {
          cursorPaginate(state, {data: data.data, next_page: params.next_page})
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    add({commit}, params) {
      return createAxios().post(endPoint, params)
        .then(({data}) => {
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getLatestClosingAt({commit}, params) {
      return createAxios().get(`${endPoint}latest-stock`, params)
        .then(({data}) => {
          commit(types.SET_LATEST_STOCK, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    update({commit}, params) {
      return createAxios().put(endPoint + params.id, params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
