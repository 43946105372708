<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div v-if="unit">
              <div>
                <div class="form-group row m-1 p-0">
                  <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">
                    {{ billingUnits[unit.billing_unit_id] }}
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-8">
                    <validation-provider :rules="valueRequired(price)">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          @change="calcTotalValue"
                          v-money="money"
                          v-model.lazy="price"
                          maxlength="10"
                          inputmode="numeric"
                          class="form-control form-control-sm"
                        />
                        <template slot="prepend" v-if="unit.billing_unit_id != 4">
                          <small class="input-group-sm p-0 m-0"> R$ </small>
                        </template>
                        <template slot="prepend" v-else>
                          <small class="input-group-sm p-0 m-0"> % </small>
                        </template>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
              </div>
              <div v-show="unit.billing_unit_id != 0">
                <div class="form-group row m-1 p-0">
                  <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">
                    Base de cálculo
                  </label>
                  <div class="col-md-8">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        disabled
                        :value="calculation_base"
                        maxlength="10"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                      />
                      <template v-if="unit.billing_unit_id == 2" slot="prepend">
                        <small class="input-group-sm p-0 m-0"> M<sup>3</sup> </small>
                      </template>
                      <template v-else-if="unit.billing_unit_id != 3" slot="prepend">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                      <template v-else slot="prepend">
                        <small class="input-group-sm p-0 m-0"> Min </small>
                      </template>
                    </base-input>
                  </div>
                </div>
              </div>
              <div>
                <div class="form-group row m-1 p-0">
                  <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">
                    Adicional total
                  </label>
                  <div class="col-md-8">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        disabled
                        :value="total_calc"
                        class="form-control form-control-sm"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                </div>
              </div>
              <div class="modal-footer d-flex justify-content-center">
                <base-button type="secondary" @click="closeModal('create')"> Cancelar</base-button>
                <base-button type="success" native-type="submit" v-bind:disabled="invalid || item.price <= 0"
                             :loading="loadingStore">
                  Salvar
                </base-button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {VMoney} from "v-money";
import {formatErrorValidation} from "@/plugins";
import PuzlSelect from "@/components/PuzlSelect";
import InputLimit from "@/components/Utils/InputLimit";
import { toMoneyWithoutCurrencyLayout } from "@/helpers";

export default {
  name: "ModalChangeAdditionalValue",
  components: {PuzlSelect, InputLimit},
  data() {
    return {
      modal: {
        title: "Alteração de preços de adicional",
        create: false,
      },
      total_calc: 0,
      billingUnits: {
        0: 'R$ (Fixo)',
        2: 'R$ /m3',
        3: 'R$ / Hora',
        4: '% (Sobre Preço Contratado)',
      },
      calculation_base: 0,
      price: 0,
      unit: null,
      item: null,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      loadingStore: false,
    };
  },
  directives: {money: VMoney},
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    calcTotalValue() {
      const unit = Number(this.unit.billing_unit_id)
      const price = this.price.replaceAll(".", "").replace(",", ".")
      let value = 0
      value = Number(price) * Number(this.unit.unit_calculation)
      switch (unit) {
        case 0:
          this.$set(this, 'total_calc', toMoneyWithoutCurrencyLayout(Number(price).toFixed(2)))
          break;
        case 2:
        case 3:
          value = Number(value).toFixed(2)
          this.$set(this, 'total_calc', toMoneyWithoutCurrencyLayout(value))
          break;
        case 4:
          value = Number(value).toFixed(2)
          this.$set(this, 'total_calc', toMoneyWithoutCurrencyLayout(value / 100))
          break;
      }
    },
    openModal(item) {
      let loader = this.$loading.show()
      this.item = {...item}
      this.modal.create = true;
      this.$store.dispatch('scheduleAdditional/show', item.id)
        .then(response => {
          this.modal.create = true;
          this.unit = response.data
          if (response.data.billing_unit_id == 3) {
            this.calculation_base = response.data.calculation_base
          }
          else if (response.data.billing_unit_id == 2) {
            this.calculation_base = Number(response.data.unit_calculation).toFixed(1)
          }
          else {
            this.calculation_base = toMoneyWithoutCurrencyLayout(response.data.unit_calculation)
          }
          this.price = Number(response.data.billing_unit_value).toFixed(2)
          this.calcTotalValue()
          loader.hide()
        })
    },
    store() {
      const loader = this.$loading.show();
      this.loadingStore = true;
      this.$store
        .dispatch("scheduleAdditional/changePrice", {
          id: this.item.id,
          price: this.total_calc,
          billing_unit_value: this.price,
        })
        .then((response) => {
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.$emit('updated')
          this.closeModal();
        })
        .catch((error) => {
          if (error.status === 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
        })
        .finally(() => {
          loader.hide();
          this.loadingStore = false;
        });
    },
    /**
     * Trata valor obrigarório do v-money
     *
     * @param {string} value
     * @returns {string}
     */
    valueRequired(value) {
      return value === "0,00" ? "min_value:1" : "";
    },
  },
};
</script>

<style scoped>
.modal {
  overflow-y: scroll !important;
}
</style>
