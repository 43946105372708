<template>
  <div>
    <base-header class="bg-gray-content">
      <puzl-breadcrumb />
      <div class="row">
        <div class="col-md-3 p-2">
          <base-button :class="filter.is_user && 'active'" @click.prevent="showModaModalBankBilletRemittance = true" block type="success">
            <i class="fa-solid fa-file-import"></i> ARQUIVOS DE REMESSA
          </base-button>
        </div>
        <div class="col-md-3 p-2">
          <base-button :class="filter.driver && 'active'" @click.prevent="showModaModalBankBilletDischarge = true" block type="primary">
            <i class="fa-solid fa-rotate-left"></i> ARQUIVOS DE RETORNO
          </base-button>
        </div>
      </div>
      <div v-if="Object.values(bank_billet_widgets).length > 0 && !isLoadingWidgets" class="row align-items-center">
        <div class="col-md-3 col-sm-6 p-1">
          <div class="mb-3 card">
            <div class="p-3 card-body">
              <div class="row">
                <div class="col-8" style="margin-top: -10px">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold pl-2"><small class="font-weight-bold">RECEBIDO</small></p>
                </div>
                <div class="col-4" style="margin-top: -5px">
                  <div class="text-center shadow icon icon-shape bg-green rounded-circle float-right">
                    <i class="text-lg opacity-10 ni ni-money-coins text-white" aria-hidden="true"></i>
                  </div>
                </div>
                <div class="col-md-12" style="margin-top: -25px">
                  <h3 class="mb-0 pl-2 font-weight-normal" >
                    {{ (bank_billet_widgets.status_paid_out_amount || 0) | currency }}
                    <strong>({{ bank_billet_widgets.status_paid_out_count || 0 }})</strong>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 p-1">
          <div class="mb-3 card">
            <div class="p-3 card-body">
              <div class="row">
                <div class="col-9" style="margin-top: -10px">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold pl-2"><small class="font-weight-bold"> PENDENTE</small></p>
                </div>
                <div class="col-3" style="margin-top: -5px">
                  <div class="text-center shadow icon icon-shape bg-warning rounded-circle float-right">
                    <i class="fas fa-ellipsis-h text-white" aria-hidden="true"></i>
                  </div>
                </div>
                <div class="col-md-12" style="margin-top: -25px">
                  <h3  class="mb-0 pl-2 font-weight-normal">
                    {{ (bank_billet_widgets.status_pending_amount || 0) | currency }}
                    <strong>({{ bank_billet_widgets.status_pending_count || 0}})</strong>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 p-1">
          <div class="mb-3 card">
            <div class="p-3 card-body">
              <div class="row">
                <div class="col-8" style="margin-top: -10px">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold pl-2"><small class="font-weight-bold">ATRASADO</small></p>
                </div>
                <div class="col-4" style="margin-top: -5px">
                  <div class="text-center shadow icon icon-shape rounded-circle float-right" style="background-color: darkred">
                    <i class="text-white fa-solid fa-minus" aria-hidden="true"></i>
                  </div>
                </div>
                <div class="col-md-12" style="margin-top: -25px">
                  <h3  class="mb-0 pl-2 font-weight-normal">
                    {{ (bank_billet_widgets.status_late_amount || 0) | currency }}
                    <strong>({{ bank_billet_widgets.status_late_count || 0 }})</strong>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 p-1">
          <div class="mb-3 card">
            <div class="p-3 card-body">
              <div class="row">
                <div class="col-8" style="margin-top: -10px">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold pl-2"><small class="font-weight-bold">CANCELADO</small></p>
                </div>
                <div class="col-4" style="margin-top: -5px">
                  <div class="text-center shadow icon icon-shape bg-danger rounded-circle float-right">
                    <i class="fa-solid fa-ban text-white" aria-hidden="true"></i>
                  </div>
                </div>
                <div class="col-md-12" style="margin-top: -25px">
                  <h3  class="mb-0 pl-2 font-weight-normal">
                    {{ (bank_billet_widgets.status_canceled_amount || 0) | currency }}
                    <strong>({{ bank_billet_widgets.status_canceled_count || 0}})</strong>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="row align-items-center">
        <div v-for="item in 4" class="col-md-3 col-sm-6 p-1">
          <div class="mb-3 card" style="height: 80px">
            <div class="card-body">
              <div class="row">
                <div class="col-9" style="margin-top: -10px">
                  <skeleton-puzl type="small" />
                </div>
                <div class="col-2 text-right float-right" style="margin-top: -15px">
                  <skeleton-puzl type="circle" />
                </div>
                <div class="col-5" style="margin-top: -22px">
                  <skeleton-puzl type="small" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row pt-0" style="margin-top: -0.5rem">
        <div class="col-md-12 p-1">
          <multi-filter-v2 :without_filter="false" @clearFilters="clearFilters" @fetch="fetchItemsPaginate" :filter="filter" :status="multi_filter">
            <template slot="beforeMainBarTop">
              <div class="row pl-2">
                <el-popover trigger="hover" placement="bottom" class="p-0 float-right pr-0 mr-2">
                  <span> A filtragem <b> padrão </b> buscará a data de vencimento de cada parcela</span>
                  <base-button outline slot="reference" size="sm" type="secundary"
                               class="p-0 m-0 shadow-none  text-primary">
                    <i style="padding-top: 0.1rem;" class="fas fa-calendar"></i>
                  </base-button>
                </el-popover>
                <div class="form-group mb-0 d-flex align-items-center mr-3">
                  <small class="font-weight-bold mb-0 mr-2">Padrão</small>
                  <input @change="init({})" v-model="filter_by_default" :value="1" style="padding-top: 0.1rem;"
                         type="radio"/>
                </div>
                <div class="form-group mb-0 d-flex align-items-center">
                  <small class="font-weight-bold mb-0 mr-2">Atualização</small>
                  <input @change="init({})" v-model="filter_by_default" :value="0" style="padding-top: 0.1rem;"
                         type="radio"/>
                </div>
              </div>
            </template>

            <div class="col-md-3 p-2">
              <input-date-picker ref="date-picker" @handleFilterDate="handleFilterDate" :default-range="defaultRange" />
            </div>
            <div class="col-md-3 p-2 custom-dashboard">
              <puzl-select
                class="select-xl"
                @input="handleFilterBankAccount"
                v-model="selectedBankAccountId"
                :items="$_bank_accounts"
                placeholder="Conta Bancária de Crédito"
                :loading="loadingBankAccount"
                :disabled="loadingBankAccount"
              />
            </div>
            <div class="col-md-3 p-2 custom-dashboard">
              <puzl-select
                class="select-xl"
                v-model="selectedCompanyPlants"
                :items="$_plants"
                customKey="id"
                :multiple="true"
                :loading="isLoadingCompanyPlants"
                :disabled="isLoadingCompanyPlants"
                @input="handleFilterCompanyPlants"
                placeholder="Central"
              />
            </div>
          </multi-filter-v2>
        </div>
      </div>

      <div class="row pt-0" style="margin-top: -1.4rem">
        <div class="col-md-12 p-1">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 table-responsive" style="overflow-y: auto; max-height: 80vw">
                  <table class="table table-sm table-bordered">
                    <thead>
                      <tr class="fixed">
                        <th class="text-center" style="width: 5px">Ação</th>
                        <th class="text-center" style="width: 5px">Status</th>
                        <th class="text-center">Central</th>
                        <th class="text-center" style="width: 5px">Conta</th>
                        <th>Cliente</th>
                        <th class="text-center" style="width: 18px">Valor</th>
                        <th class="text-center" style="width: 5px">Vencimento</th>
                        <th class="text-center" style="width: 5px">Data do crédito</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in 15" v-show="isLoadingBankBillet">
                        <th colspan="8">
                          <skeleton-puzl type="button" />
                        </th>
                      </tr>
                      <tr v-show="!isLoadingBankBillet && bank_billet_issuedes.length" v-for="item in bank_billet_issuedes" style="">
                        <th class="text-center" style="width: 18px">
                          <base-dropdown menuOnRight class="dropdown-table">
                            <a class="dropdown-item" @click.prevent="handleShowHistory(item)">
                              <i class="fas fa-history text-indigo main-icon-menu"></i>
                              <span class="text-right">Histórico</span>
                            </a>
                            <a
                              style="text-transform: none"
                              slot="title-container"
                              class="text-default dropdown-toggle font-weight-300"
                            >
                              Selecione
                            </a>

                            <a class="dropdown-item" @click="handleEditBillReceive(item)">
                              <i class="fas fa-edit text-warning"></i>
                              Editar
                            </a>
                            <a class="dropdown-item" @click="downloadBankBillet(item)">
                              <i class="fas fa-solid fa-print text-primary"></i>
                              Imprimir
                            </a>
                            <div v-if="[bankBilletStatus.options.pending, bankBilletStatus.options.late].includes(item.status)">
                              <a class="dropdown-item" @click="updateBillet(item)">
                                <i class="fas fa-solid fa-calendar-days text-primary"></i>
                                Prorrogar
                              </a>
                              <a class="dropdown-item" @click="cancelBillet(item)">
                                <i class="fas fa-solid fa-circle-xmark text-danger"></i>
                                Cancelar
                              </a>
                            </div>
                          </base-dropdown>
                        </th>
                        <th :style="'width: 5px'" :class="'text-'+getColor(item.installment_status)">{{ getTitle(item.installment_status) }}</th>
                        <th style="width: 5px" class="text-center">
                          <span style="font-weight: normal; width: 15px">{{ item.company_plant_name }}</span>
                        </th>
                        <th class="text-center">
                          <span style="font-weight: normal; width: 5px"
                            >{{ item.bank_account_name }}
                          </span>
                        </th>
                        <th class="">
                          <span style="font-weight: normal; width: 15px">
                            <badge type="primary" class="font-weight-500 bg-primary text-white">{{ item.contract_proposal_code }}</badge>
                            <span :title="item.entity_name"> {{ firstTwoEntityNameWords(item.entity_name) }} </span>
                            ({{ item.document }})
                          </span>
                        </th>
                        <th class="text-center" style="width: 18px">
                          <span style="font-weight: normal"
                          ><span>{{ item.amount | currency }} ({{ item.index }}/{{ item.total_index }})</span></span
                          >
                        </th>
                        <th>
                          <span style="font-weight: normal; width: 5px">{{ item.expire_at | parseDate("DD/MM/YYYY") }}</span>
                        </th>
                        <th class="text-center">
                          <span v-if="item.paid_at" class="text-success" style="font-weight: normal; width: 5px">{{ item.paid_at | parseDate("DD/MM/YYYY") }}</span>
                          <span v-else style="font-weight: normal; width: 5px">-</span>
                        </th>
                      </tr>
                    </tbody>

                  </table>
                </div>
              </div>
              <puzl-empty-data :withCard="false" v-if="!bank_billet_issuedes.length"/>
              <pagination @navegate="navegate" :source="source" :loading="isLoadingBankBillet" />
            </div>
          </div>
        </div>
      </div>
    </base-header>
    <ModalHistory ref="ModalHistory" />
    <EditBillReceive @updated="init" ref="editBillReceive" />
    <ModalBankBilletRemittance
      ref="ModalBankBilletRemittance"
      :show="showModaModalBankBilletRemittance"
      @close="showModaModalBankBilletRemittance = false"/>
    <ModalBankBilletDischarge
      ref="ModalBankBilletDischarge"
      :show="showModaModalBankBilletDischarge"
      @close="showModaModalBankBilletDischarge = false"/>
    <modal-update-billet
      ref="modalUpdateBillet"
      @close="onModalUpdateBilletClose"/>
  </div>
</template>

<script>
import MultiFilterV2 from "@/components/Utils/MultiFilterV2";
import { SkeletonPuzl } from "@/components";
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import Pagination from "@/components/Utils/Pagination";
import ModalHistory from "@/views/Modules/Financial/BillReceive/Shared/Modals/_ModalHistory";
import EditBillReceive from "@/views/Modules/Financial/BillReceive/Shared/Modals/_Edit";
import InputDatePicker from "@/components/InputDatePicker";
import { mapGetters } from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
import ModalBankBilletRemittance from "@/views/Modules/Financial/BillReceive/Shared/Modals/_ModalBankBilletRemittance";
import ModalBankBilletDischarge from "@/views/Modules/Financial/BillReceive/Shared/Modals/_ModalBankBilletDischarge";
import ModalUpdateBillet from "@/views/Modules/Financial/BillReceive/Shared/Modals/_ModalUpdateBillet";
import PuzlEmptyData from "@/components/PuzlEmptyData.vue";

export default {
  components: {
    PuzlEmptyData,
    SkeletonPuzl,
    PuzlBreadcrumb,
    Pagination,
    ModalHistory,
    EditBillReceive,
    MultiFilterV2,
    InputDatePicker,
    PuzlSelect,
    ModalBankBilletRemittance,
    ModalBankBilletDischarge,
    ModalUpdateBillet,
  },
  name: "Index",
  data() {
    return {
      title: "Resumo de Boletos",
      isLoadingBankBillet: false,
      isLoadingWidgets: false,
      isLoadingCompanyPlants: true,
      loadingBankAccount: true,
      bank_billet_issuedes: [],
      bank_billet_widgets: {},
      source: null,
      showModaModalBankBilletRemittance: false,
      showModaModalBankBilletDischarge: false,
      params: {
        page: 1,
        per_page: 15,
      },
      filter: {},
      filter_by_default: 1,
      selectedBankAccountId: null,
      selectedCompanyPlants: [],
      range: {
        start: null,
        end: null,
      },
      defaultRange: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      },
      multi_filter: {
        null: {
          name: "Todos",
          type: "primary"
        },
        '0': {
          name: "Pendente",
          type: "warning"
        },
        '2': {
          name: "Atrasado",
          type: "danger"
        },
        '3': {
          name: "Cancelado",
          type: "danger"
        },
        '1': {
          name: "Pago",
          type: "success"
        },
      },
      bankBilletStatus: {
        options: {
          generating: 0,
          pending: 1,
          canceled: 2,
          paidOut: 3,
          late: 4,
          fail: 5,
          invalid: 6,
        },
        value: [
          {
            text: 'Gerando',
            class: 'text-center',
          },
          {
            text: 'Pendente',
            class: 'text-center text-warning',
          },
          {
            text: 'Cancelado',
            class: 'text-center text-danger',
          },
          {
            text: 'Pago',
            class: 'text-center text-success',
          },
          {
            text: 'Atrasado',
            class: 'text-center text-danger',
          },
          {
            text: 'Falha ao gerar',
            class: 'text-center',
          },
          {
            text: 'Inválido',
            class: 'text-center',
          },
        ]
      },
    };
  },
  computed: {
    ...mapGetters({
      $_plants: "plant/activeItems",
      $_bank_accounts: "bankAccount/fetch",
    })
  },
  mounted() {
    this.filter.filter_by_default = 1
    this.range = this.defaultRange;
    this.init();
  },
  methods: {
    getTitle(status) {
      switch (status) {
        case 0:
          return "Pendente";
        case 1:
          return "Recebido";
        case 2:
          return "Atrasado";
        case 3:
          return "Cancelado";
        case 4:
          return "Serasa";
        case 5:
          return "Cartório";
        case 6:
          return "Protestado";
        case 7:
          return "Jurídico";
      }
    },
    getColor(status) {
      switch (status) {
        case 0:
          return "yellow";
        case 1:
          return "success";
        case 2:
        case 3:
          return "danger";
        case 4:
          return "info";
        case 5:
          return "default";
        case 6:
          return "warning";
        case 7:
          return "indigo";
      }
    },
    init() {
      this.params.page = 1
      this.selectedBankAccountId = null;
      this.$store.dispatch('plant/fetchItemsActive').then(() => {
        this.isLoadingCompanyPlants = false;
      })
      this.fetchItemsPaginate();
      this.$store.dispatch('bankAccount/fetchItems', { filter: { has_allocated_plant: true } }).then(() => {
        this.loadingBankAccount = false;
      });
    },
    fetchItemsPaginate(filter = {}) {
      this.isLoadingBankBillet = true;
      this.filter = filter;
      this.filter.range = this.range;
      this.filter.filter_by_default = this.filter_by_default
      this.filter.bank_account_id = this.selectedBankAccountId;
      this.filter.company_plants = this.selectedCompanyPlants;
      this.fetchWidgets();
      this.$store
        .dispatch("bankBillet/fetchItemsPaginate", {
          filter: this.filter,
          page: this.params.page,
        })
        .then((response) => {
          this.bank_billet_issuedes = response.data;
          this.source = response;
          this.isLoadingBankBillet = false;
        })
        .catch(() => {
          this.isLoadingBankBillet = false;
        });
    },
    fetchWidgets() {
      this.isLoadingWidgets = true;
      this.$store
        .dispatch("bankBillet/fetchWidgets", {
          filter: this.filter,
        })
        .then((response) => {
          this.bank_billet_widgets = response.data;
          this.isLoadingWidgets = false;
        })
        .catch(() => {
          this.isLoadingWidgets = false;
        });
    },
    navegate(page) {
      this.params.page = page;
      this.fetchItemsPaginate();
    },
    handleShowHistory(item) {
      if ((!item.installment_id) || (!item.bill_receive_type)) {
        this.$notify({
          type: "danger",
          message: "Nenhum histórico vinculado a este registro."
        });
        return
      }
      this.$refs.ModalHistory.openModal({
        installment_id: item.installment_id,
        type: item.bill_receive_type,
      });
    },
    handleEditBillReceive(item) {
      if ((!item.bill_receive_id) || (!item.bill_receive_type)) {
        this.$notify({
          type: "danger",
          message: "Nenhum recebimento vinculado a este registro."
        });
        return
      }
      this.$refs.editBillReceive.handleCreateModal(item.bill_receive_id, item.bill_receive_type, item.contract_proposal_payment_status);
    },
    handleFilterCompanyPlants(filter) {
      this.selectedCompanyPlants = filter;
      this.fetchItemsPaginate();
    },
    handleFilterDate(filter) {
      this.range = filter;
      this.fetchItemsPaginate();
    },
    handleFilterBankAccount() {
      this.filter.bank_account_id = this.selectedBankAccountId;
      this.fetchItemsPaginate();
    },
    downloadBankBillet(item) {
      window.open(item.file_path, "_blank");
    },
    /**
     * @param {string} entityName
     * @returns {string}
     */
    firstTwoEntityNameWords(entityName) {
      return entityName.split(" ").slice(0, 2).join(" ");
    },
    clearFilters() {
      this.filter = {};
      this.selectedBankAccountId = null;
      this.selectedCompanyPlants = [];
      this.fetchItemsPaginate();
    },
    cancelBillet(bankBillet){
      this.$Swal.confirmAction('Deseja realmente cancelar este boleto?', ['Sim', 'Não'])
        .then((result) => {
          if (!result.isConfirmed) return;
          this.$Progress.start()
          let loader = this.$loading.show()
          this.$store.dispatch("bankBillet/cancel", bankBillet.id)
            .then((response) => {
              this.$notify({type: response.error_type, message: response.message});
              bankBillet.status = this.bankBilletStatus.options.canceled;
            }).catch(() => {
              const errors = error.status
                  ? error.data.message
                  : formatErrorValidation(error.response.data.errors);
                this.$notify({ type: "danger", message: errors });
            }).finally(() => {
              loader.hide();
              this.$Progress.finish();
            });
        });
    },
    getBankBilletStatus(valueIndex){
      return this.bankBilletStatus.value[valueIndex];
    },
    updateBillet(bankBillet){
      bankBillet.type = bankBillet.bill_receive_type;
      this.$refs.modalUpdateBillet.openModal(bankBillet, bankBillet.index, bankBillet.total_index);
    },
    onModalUpdateBilletClose(){
      this.fetchItemsPaginate();
    },
  },
};
</script>

<style scoped></style>
