import * as types from './mutation_types'
import {destroy, update, cursorPaginate} from '@/store/baseStore'
import { createAxios } from "@/plugins/axios";

const endPoint = '/commercial/contract-proposal'
export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  actions: {
    add({commit, state}, payload) {
      return createAxios().post( `${endPoint}/proposal-terms`, payload)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    show({}) {
      return createAxios().get(`${endPoint}/proposal-terms`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
