export const ScheduleEnum = Object.freeze({
    CANCELED: 0, // Cancelado
    RESCHEDULED: 1, // Remarcado
    SELLER_REQUEST: 2, // Solicitação do vendedor
    NOT_CONFIRMED: 3, // Não confirmado
    INTERRUPTED: 8, // Interrompido
    CHARGING: 9, // Cobrando
    IN_PROGRESS: 10, // Em andamento
    COMPLETED: 11, // Concluído
    RELEASED: 13, // Liberado
    UNKNOWN: 14, // Desconhecido
    NOT_PERFORMED: 15, // Não realizado
    PENDING: 16, // Pendente
});
