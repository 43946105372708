const basePieChart = {
  loading:true,
  // título
  title: {
    text: "Conformidades (m³)",
    top: 1,
    left: "center",
  },
  /* legenda suspensa */
  tooltip: {
    trigger: "item",
    formatter(obj) {
      return `${obj.marker} ${obj.data.name} <b> ${obj.data.reference} (${obj.data.value}%) </b> <br/>`;
    },
  },
  /* legenda fixa */
  legend: false,
  series: [
    {
      // posição
      center: ["50%", "60%"],
      name: "",
      type: "pie",
      radius: "50%",
      /* Dados do gráfico */
      data: [],
      color: ["Green", "Red"],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
};

export function handlePieChart(payload) {
  const total_volume = +payload.has_accordance + +payload.dont_have_accordance
  const data = [
    {reference: +payload.has_accordance, value: Math.round(+payload.has_accordance / total_volume * 100) || 0, name: 'Conforme'},
    {reference: +payload.dont_have_accordance, value: Math.round(+payload.dont_have_accordance / total_volume * 100) || 0, name: "Não conforme"},
  ]
  let pieChart = basePieChart;
  pieChart.series[0].data = data;
  pieChart.loading = false;
  return pieChart;
}
