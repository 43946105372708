<template>
   <CreateEntryLaunch :byEntity="true"/>
</template>

<script>
import CreateEntryLaunch from "./_Create.vue";

export default {
  name: "CreateEntryLaunchByEntity",
  components: {
    CreateEntryLaunch,
  },
};
</script>