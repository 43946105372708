import * as types from './mutation_types'
import {destroy, update} from '@/store/baseStore'
import { createAxios } from "@/plugins/axios";
const endPoint = '/mix/feature/'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
    places: [
      {
        value: 0,
        label: 'Nenhuma'
      },
      {
        value: 1,
        label: '1'
      },
      {
        value: 2,
        label: '2'
      },
      {
        value: 3,
        label: '3'
      },
    ],
    period_types: [
      {
        value: 1,
        label: 'Dias'
      },
      {
        value: 2,
        label: 'Horas'
      },
    ],
    types: [
      {
        value: null,
        label: 'Nenhum'
      },
      {
        value: 1,
        label: 'Número'
      },
      {
        value: 2,
        label: 'Texto'
      },
    ],
    operators: [
      {
        value: '0',
        label: 'nenhum'
      },
      {
        value: '<=',
        label: '<= (Menor ou Igual)'
      },
      {
        value: '>=',
        label: '>= (Maior ou Igual)'
      },
    ],
  },
  getters:{
    show: state => state.item,
    fetch: state => state.items,
    activeItems (state) {
      return state.items.filter(item => item.status === true)
    },
    places: state => state.places,
    period_types: state => state.period_types,
    types: state => state.types,
    operators: state => state.operators,
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
    [types.PUSH] (state, payload){
      state.items.push(payload)
    },
    [types.SHOW] (state, payload){
      state.item = payload
    },
    [types.DESTROY] (state, uuid){
      destroy(state,uuid)
    },
    [types.UPDATE] (state, payload){
      update(state, payload)
    },
  },
  actions: {
    fetchItems({commit}) {
      return createAxios().get(endPoint)
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    add({commit, state}, payload) {
      return createAxios().post(endPoint, payload)
        .then(({data}) =>{
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    update({commit}, payload) {
      return createAxios().put(endPoint+payload.uuid, payload)
        .then(({data}) =>{
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    show({commit, dispatch}, uuid) {
      return createAxios().get(endPoint+uuid)
        .then(({data}) =>{
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    destroy({commit, dispatch}, uuid) {
      return createAxios().delete(endPoint+uuid)
        .then(({data}) =>{
          commit(types.DESTROY, uuid)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
