import * as types from './mutation_types'
import { createAxios } from "@/plugins/axios";;

const endPoint = '/financial/iss-resume'
export default {
  namespaced: true,
  state: {
    itemsRetained: [],
    itemsNfses: [],
    itemsDue: [],
    totals: [],
  },
  getters: {
    getRetained: state => state.itemsRetained,
    getNfses: state => state.itemsNfses,
    getDue: state => state.itemsDue,
    getTotals: state => state.totals,
  },
  mutations: {
    [types.SET_RETAINED](state, payload) {
      state.itemsRetained = payload
    },
    [types.SET_NFSES](state, payload) {
      state.itemsNfses = payload
    },
    [types.SET_DUE](state, payload) {
      state.itemsDue = payload
    },
    [types.SET_TOTALS](state, payload) {
      state.totals = payload
    },
  },
  actions: {
    fetchRetained({commit}, params) {
      return createAxios().get(`${endPoint}/retained`, {params: params})
        .then(({data}) => {
          commit(types.SET_RETAINED, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchDue({commit}, params) {
      return createAxios().get(`${endPoint}/due`, {params: params})
        .then(({data}) => {
          commit(types.SET_DUE, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchTotals({commit}, params) {
      return createAxios().get(`${endPoint}/totals`, {params: params})
        .then(({data}) => {
          commit(types.SET_TOTALS, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchNfses({commit}, params) {
      return createAxios().get(`${endPoint}/nfses`, {params: params})
        .then(({data}) => {
          commit(types.SET_NFSES, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
