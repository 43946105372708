<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(storeCreate)"
            autocomplete="off"
          >
            <div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 col-form-label form-control-label">
                  Status
                </label>
                <div class="col-md-7 pt-2">
                  <base-switch
                    v-model="addReasonCancellation.status"
                    type="success"
                    offText="inativo"
                    onText="ativo"
                    class="success"
                  ></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                >
                  Motivo
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="addReasonCancellation.reason"
                        class="form-control form-control-sm"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                >
                  Observação
                </label>
                <div class="col-md-7">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="addReasonCancellation.observation"
                        class="form-control form-control-sm"
                        maxlength="250"
                      />
                    </base-input>
                </div>
              </div>

            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')"
                >Cancelar</base-button
              >
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                :loading="loading"
                >Salvar</base-button
              >
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>

    <modal :show.sync="modal.update">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(update)"
            autocomplete="off"
          >
            <div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 col-form-label form-control-label">
                  Status
                </label>
                <div class="col-md-7 pt-2">
                  <base-switch
                    v-model="editReasonCancellation.status"
                    type="success"
                    offText="inativo"
                    onText="ativo"
                    class="success"
                  ></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                >
                  Motivo
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="editReasonCancellation.reason"
                        class="form-control form-control-sm"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                >
                  Observação
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="editReasonCancellation.observation"
                        class="form-control form-control-sm"
                        maxlength="250"
                      />
                    </base-input>
                </div>
              </div>

            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('update')"
                >Cancelar</base-button
              >
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                :loading="loading"
                >Salvar</base-button
              >
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>
<script>
import { Option, Select } from 'element-ui';

import { mask } from "vue-the-mask";

export default {
  directives: { mask },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  name: "AddReasonCancellation",
  data() {
    return {
      modal: {
        title: '',
        create: false,
        update: false,
      },
      validated: false,
      addReasonCancellation: {
        status: true,
        reason: '',
        observation: '',
      },
      editReasonCancellation: {
        id: '',
        status: '',
        reason: '',
        observation: '',
      },
      loading: false,
    };
  },
  methods: {
    closeModal (name) {
      this.modal[name] = false;
    },
    openModal (name) {
      this.modal[name] = true;
    },
    handleCreate(){
      this.addReasonCancellation = {
        status: true,
        reason: '',
        observation: '',
      };
      this.modal.title = 'Adicionar Motivo de situação do contrato';
      this.openModal('create');
    },
    handleUpdate(itemUpdate){
      this.editReasonCancellation = {
        id: itemUpdate.id,
        status: itemUpdate.status,
        reason: itemUpdate.reason,
        observation: itemUpdate.observation,
      };
      this.modal.title = 'Editar Motivo de situação do contrato';
      this.openModal('update');
    },
    async storeCreate(){
        this.$Progress.start();
        this.loading = true;
        this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
        this.$axios.post("/commercial/contract/cancellation-reasons", this.addReasonCancellation)
          .then(({data}) => {
            this.$notify({ type: data.error_type, message: data.message });
            this.loading = false;
            this.addReasonCancellation = {
              status: true,
              reason: '',
              observation: '',
            };
            EventBus.$emit("getReasonCancellation");
            this.closeModal('create');
            this.loading = false;
            this.$Progress.finish();
          })
          .catch((error) => {
            if (error) {
              Exception.handle(error);
            }
            this.$notify({ type: error.response.data.error_type, message: error.response.data.message });
            this.loading = false;
          });
    },
    async update(){
        this.$Progress.start();
        this.loading = true;
        this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
        this.$axios.put("/commercial/contract/cancellation-reasons/"+this.editReasonCancellation.id, this.editReasonCancellation)
          .then(({data}) => {
            this.$notify({ type: data.error_type, message: data.message });
            this.loading = false;
            this.addReasonCancellation = {
              status: true,
              reason: '',
              observation: '',
            };
            EventBus.$emit("getReasonCancellation");
            this.closeModal('update');
            this.loading = false;
            this.$Progress.finish();
          })
          .catch((error) => {
            if (error) {
              Exception.handle(error);
            }
            this.$notify({ type: error.response.data.error_type, message: error.response.data.message });
            this.loading = false;
          });
    },
    deleteItem(id){
      this.$Progress.start();
        this.$swal.fire({
          title: 'Você tem certeza?',
          text: 'Você não poderá recuperar este registro!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim, apague isto!',
          cancelButtonText: 'Não, mantenha',
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
            this.$axios.delete("/commercial/contract/cancellation-reasons/"+id)
              .then(({data}) => {
                this.$notify({ type: data.error_type, message: data.message });
                EventBus.$emit("getReasonCancellation");
                this.$Progress.finish();
              })
              .catch((error) => {
                if (error) {
                  Exception.handle(error);
                }
                this.$notify({ type: error.response.data.error_type, message: error.response.data.message });
              });
            }
            this.$Progress.finish();
        });
    },
  },
  created(){
  },
  mounted() {
    this.$refs.formValidator.validate();
    EventBus.$on("handleCreateReasonCancellation", () => {
      this.handleCreate();
    });
    EventBus.$on("handleUpdateReasonCancellation", (itemUpdate) => {
      this.handleUpdate(itemUpdate);
    });
    EventBus.$on("handleDeleteReasonCancellation", (id) => {
      this.deleteItem(id);
    });
  }
};

</script>
<style></style>
