/**
 * Definição das chaves únicas usadas para armazenar o estado.
 *
 * Cada chave deve ser única em todo o projeto.
 * Não deve haver duplicação de chaves em nenhum lugar do código.
 */
export const dreStateKeys = Object.freeze({
  LISTED: "DRE_LISTED",
  IS_LISTING: "DRE_IS_LISTING",
  DRE_CONFIGURATION: "DRE_CONFIGURATION",
  PAGE_STATES: "DRE_PAGE_STATES",
});
