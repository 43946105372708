<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <div class="checklist-item checklist-item-primary">
          <div class="checklist-info">
            <div> {{ entity.name }} </div>
            <small> {{ entity.document }} </small>
          </div>
        </div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div class="form-group row m-0 p-0">
                <label class="col-md-12 col-form-label form-control-label">
                    Anexos
                </label>
                <div class="col-md-12">
                    <InputFile :maxFiles="5" ref="files" :multiple="true"/>
                </div>
            </div>

            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')">
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

  <script>
import PuzlSelect from "@/components/PuzlSelect";
import InputFile from '@/components/Utils/InputFile';
const {formatErrorValidation} = require("@/plugins");

  export default {
    name: "Create",
    components: { PuzlSelect, InputFile },
    data () {
      return {
        modal: false,
        title: 'Anexos',
        loadingStore: false,
        entity: {
            id: null,
            name: '',
            document: '',
        },
        form_data: [],
      }
    },
    methods: {
      closeModal () {
        this.modal= false
      },
      /**
       * @param {number} entityId
       * @param {string} name
       * @param {string} document
       */
      handleCreateModal (entityId, name, document) {
        this.entity = {
            id: entityId,
            name: name,
            document: document
        }
        // this.$store.dispatch('entityAttachment/getByEntityId', this.entity.id).then(response => {
        //   this.$refs["files"].setFilesByUrl(response.data);
        // });
        this.modal = true;
      },
      store () {
        this.$Progress.start();
        this.loadingStore = true;
        this.form_data = [];
        this.$refs["files"].fileRecords.forEach((item, index) => {
            this.form_data.push({ file: item.file, entity_id: this.entity.id, index: index })
        });
        this.$store.dispatch('entityAttachment/add', this.form_data)
          .then(response => {
            this.closeModal();
            this.$notify({ type: response.error_type, message: response.message });
            this.$emit('storedAttachment', response.data);
          })
          .catch(error => {
            this.$notify({ type: 'danger', message: error.message });
            if (error.status) {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              });
            } else {
              if (error.response.status === 422) {
                let message = formatErrorValidation(error.response.data.errors)
                this.$notify({ type: 'danger', message: message });
              } else {
                this.$notify({ type: error.data.error_type, message: error.data.message });
              }
            }
          }).finally(() => {
            this.$Progress.finish();
            this.loadingStore = false;
          });
      },
    },
    mounted() {
      this.$refs.formValidator.validate();
    }
  }
  </script>

  <style scoped>

  </style>
