import InCourse from '@/views/Modules/Driver/InCourse/Index.vue';
import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import auth from "@/middleware/auth";

const driver = {
  path: '/driver',
  component: DashboardLayout,
  name: 'Driver',
  meta: {
    middleware: auth,
  },
  children: [
    {
      path: '/driver/in-course',
      name: 'driver.in-course.view',
      component: InCourse,
      meta: {
        title: 'Driver',
        description: 'Entregas',
        permission: 'driver.in-course.index.view',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Dashboard', name: 'driver.in-course.dashboard.index'},
          {title: 'Entregas', name: null},
        ]
      },
    },
  ]
};

export default driver;
