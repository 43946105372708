<template>
  <modal :show.sync="modal" size="md">
    <div class="p-2">
      <div class="col-12">
        <!-- Títulos -->
        <h3 class="mb-0 text-capitalize">{{ service.name }}</h3>
        <h5 class="font-weight-normal mb-0">{{ service.description }}</h5>
      </div>
      <div class="col-12 border-left p-2 border-3 border-danger mt-2">
        <h5 class="h3 mb-0 pl-1">{{ service.general_value | currency() }}</h5>
      </div>

      <!-- Indicadores -->
      <div class="row align-items-center mt-3">
        <div class="col-12">
          <div class="border-header-success-bottom mb-3">
            <h5 class="h4 p-1 pl-3 mb-0">Indicadores</h5>
          </div>
          <!-- Quantidades gerais -->
          <div class="col-12 border-left p-2 border-3 border-success">
            <h5 class="mb-0 font-weight-normal">
              Serviços totais :
              <span class="font-weight-bold">{{ service.total_services }}</span>
            </h5>
            <h5 class="mb-0 font-weight-normal">
              Volume total :
              <span class="font-weight-bold">{{ service.total_volume }} m<sup>3</sup> </span>
            </h5>
            <h5 class="mb-0 font-weight-normal">
              Tempo total :
              <span class="font-weight-bold">{{ service.total_time }}</span>
            </h5>
          </div>
          <!-- Totais em R$ -->
          <div class="col-12 border-left p-2 border-3 border-success mt-3">
            <h5 class="mb-0">{{ service.minimum_general_value | currency() }} / serviço</h5>
            <h5 class="mb-0 font-weight-normal">{{ service.m3_general_value | currency() }} /m<sup>3</sup></h5>
            <h5 class="mb-0 font-weight-normal">{{ service.hour_general_value | currency() }} /hr</h5>
          </div>
        </div>
      </div>
      <!-- Dados gerais -->
      <div class="row align-items-center mt-3">
        <div class="col-12">
          <div class="border-header-primary-bottom mb-3">
            <div class="p-1 pl-3 mb-0">
              <span class="h4 pr-1"> Total por unidade de cobrança </span>
              <el-popover  placement="right" class="p-0">
                <span>Cobranças que foram realizadas considerando o maior <br/>valor dentre as unidades de cada prestação de serviço</span>
                <base-button outiline slot="reference" size="sm" type="secundary" class="p-0 m-0 btn-rounded">
                  <i class="fas fa-info-circle info-resume-icon"></i>
                </base-button>
              </el-popover>
            </div>
          </div>
          <!-- Preço minimo -->
          <div class="col-12 border-left border-3 p-2 border-primary mt-2">
            <h5 class="mb-0">
              Preço mínimo
              <span class="font-weight-normal"> ({{ service.proposal_price_fixed | currency() }})</span>
            </h5>
            <h5 class="mb-0 font-weight-normal">Quantidade: {{ service.minimum_total_services }}</h5>
            <h5 class="mb-0 font-weight-normal">Total: {{ service.minimum_total_cost | currency() }}</h5>
          </div>
          <!-- Preço por m3 -->
          <div class="col-12 border-left border-3 p-2 border-primary mt-3">
            <h5 class="mb-0">
              Preço por m<sup>3</sup>
              <span class="font-weight-normal"> ({{ service.proposal_by_meters | currency() }})</span>
            </h5>
            <h5 class="mb-0 font-weight-normal">Quantidade: {{ service.m3_total_services }} m<sup>3</sup></h5>
            <h5 class="mb-0 font-weight-normal">Total: {{ service.m3_total_cost | currency() }}</h5>
          </div>
          <!-- Preço por hora -->
          <div class="col-12 border-left border-3 p-2 border-primary mt-3">
            <h5 class="mb-0">
              Preço por hora
              <span class="font-weight-normal"> ({{ service.proposal_by_hours | currency() }})</span>
            </h5>
            <h5 class="mb-0 font-weight-normal">Quantidade: {{ service.hour_total_services }}</h5>
            <h5 class="mb-0 font-weight-normal">Total: {{ service.total_hour_cost | currency() }}</h5>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center m-3">
      <div class="pr-3">
        <base-button type="secondary" @click="closeModal()">Fechar</base-button>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ModalResumeService",
  data() {
    return {
      modal: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      service: "contractProposalServiceResume/show",
    }),
  },
  methods: {
    closeModal() {
      this.modal = false;
    },

    openModal(id) {
      this.$Progress.start();

      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });

      this.$store
        .dispatch("contractProposalServiceResume/getVersionResume", id)
        .then((response) => {
          this.modal = true;
          this.$Progress.finish();
          this.$notify({ type: response.error_type, message: response.message });
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: error.data.message,
          });
          this.$Progress.finish();
        });
    },
  },
};
</script>

<style scoped>
.info-resume-icon {
  font-size: 18px;
}
.fa-info-circle:before {
  top: -4%;
  position: absolute;
}
</style>
