<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
            <div>
              <div class="form-group row m-0 p-0">
                <div class="col-md-12 pt-2">
                  <div class="row">
                    <div class="col-md-12 p-0 m-0">
                      <base-button outline size="sm" type="light" class="col-sm-12 col-xsm-12 mb-2 active">
                        Rascunho
                      </base-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-2" v-for="(itemFeature, index) in mixDesigns.feature" :key="itemFeature.id">
                <hr v-if="mixDesigns.feature.length > 0 && index > 0">
                <div class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-5 pt-1 pb-0 col-form-label form-control-label align-middle">
                    {{ itemFeature.label }}
                    <span v-if="itemFeature.measured_unit && itemFeature.measured_unit.letter!='nenhum'">
                                        {{ itemFeature.measured_unit.letter }}
                                    </span>
                  </label>
                  <div class="col-md-7 pl-2 p-0">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <el-select v-model="mixe.items[itemFeature.id].item_id" filterable size="mini"
                                   placeholder="Selecione" @change="setStartValues(itemFeature.id)">
                          <el-option label="Selecione" value="">
                          </el-option>
                          <el-option v-for="itemFeatureItem in itemFeature.item" :key="itemFeatureItem.id"
                                     :label="setLabel(itemFeature, itemFeatureItem)" :value="itemFeatureItem.id">
                          </el-option>
                        </el-select>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>

                <template v-if="itemFeature.measured_unit">
                  <div class="form-group row m-0 p-0 mb-1" v-if="itemFeature.type===1">
                    <label class="col-md-5 pt-1 pb-0 col-form-label form-control-label align-middle">
                      &nbsp;
                    </label>
                    <div class="col-md-7 pl-2 p-0">
                      <div class="row">
                        <div class="col-md-6">
                          <span>Sd</span>
                          &nbsp;
                          <span v-if="itemFeature.measured_unit.letter!=='nenhum'">
                                                ({{ itemFeature.measured_unit.letter }})
                                            </span>
                        </div>
                        <div class="col-md-6">
                          <base-input input-group-classes="input-group-sm">
                            <input v-model="mixe.items[itemFeature.id].value_sd" inputmode="numeric"
                                   class="form-control form-control-sm" v-mask="['#.##']"
                                   @change="calculateValueMedium(itemFeature.id)"/>
                          </base-input>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0 mb-1" v-if="itemFeature.type===1">
                    <label class="col-md-5 pt-1 pb-0 col-form-label form-control-label align-middle">
                      &nbsp;
                    </label>
                    <div class="col-md-7 pl-2 p-0">
                      <div class="row">
                        <div class="col-md-6">
                          <span>N.C.</span>
                        </div>
                        <div class="col-md-6">
                          <base-input input-group-classes="input-group-sm">
                            <el-select v-model="mixe.items[itemFeature.id].value_nc"
                                       placeholder="Selecione"
                                       :disabled="mixe.items[itemFeature.id].disabled"
                                       @change="changeValueZ(itemFeature.id)" size="mini">
                              <el-option label="Selecione" value="">
                              </el-option>

                              <el-option v-for="(reliability_level, indexReliability) in reliability_levels"
                                         :key="indexReliability" :label="reliability_level.reliability+'%'"
                                         :value="reliability_level.value_z">
                              </el-option>
                            </el-select>
                          </base-input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-1" v-if="itemFeature.type===1">
                    <label class="col-md-5 pt-1 pb-0 col-form-label form-control-label align-middle">
                      &nbsp;
                    </label>
                    <div class="col-md-7 pl-2 p-0">
                      <div class="row">
                        <div class="col-md-6 pt-1">
                          <span>Z</span>
                        </div>
                        <div class="col-md-6">
                          <base-input input-group-classes="input-group-sm">
                            <input v-model="mixe.items[itemFeature.id].value_z" type="text"
                                   class="form-control form-control-sm" disabled/>
                          </base-input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-1" v-if="itemFeature.type===1">
                    <label class="col-md-5 pt-1 pb-0 col-form-label form-control-label align-middle">
                      &nbsp;
                    </label>
                    <div class="col-md-7 pl-2 p-0">
                      <div class="row">
                        <div class="col-md-6 pt-1">
                          <span>Vm</span>
                          &nbsp;
                          <span v-if="itemFeature.measured_unit.letter!=='nenhum'">
                                                ({{ itemFeature.measured_unit.letter }})
                                            </span>
                        </div>
                        <div class="col-md-6">
                          <base-input input-group-classes="input-group-sm">
                            <input v-model="mixe.items[itemFeature.id].value_medium" type="text"
                                   class="form-control form-control-sm" disabled/>
                          </base-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">
                Cancelar
              </base-button>
              <!-- <base-button @click.prevent="handleSaveAndActive()" type="primary" v-bind:disabled="invalid" :loading="loadingSave">
                Salvar e Ativar
              </base-button> -->
              <base-button type="primary" native-type="submit"
                :disabled="invalid" :loading="loadingSaveAndContinue"
                @click="saveAndContinue">
                  Salvar e Continuar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingSave">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>

  </div>
</template>

<style></style>

<script>
import {
  mapGetters
} from 'vuex'
import {
  formatErrorValidation
} from "@/plugins"

export default {
  components: {},
  name: "ModalCreateMixe",
  data() {
    return {
      modal: false,
      title: "Adicionar Traço",
      validated: false,
      mixDesigns: {},
      mixe: {
        mix_design_uuid: '',
        mix_design_id: '',
        sizing: 1,
        status: 2,
        items: {}
      },
      loadingSave: false,
      loadingSaveAndContinue: false,
    };
  },
  computed: {
    ...mapGetters({
      'reliability_levels': 'reliability_level/fetch',
    }),
  },
  methods: {
    closeModal() {
      this.modal = false
    },
    openModal() {
      this.modal = true
    },
    handleCreateMixe(mixDesigns) {
      let features = mixDesigns.feature;
      this.mixDesigns = mixDesigns;
      this.mixe = {
        mix_design_uuid: mixDesigns.uuid,
        mix_design_id: mixDesigns.id,
        sizing: 1,
        status: 2,
        items: {}
      };

      for (let i = 0; i < features.length; i++) {
        const sorted = features[i].item.sort((a,b) => {
          /**
           * se for letra, ordena por A - Z
           */
          if (isNaN(a.description)) {
            return a.description - b.description
          }
          /**
           * Se não, ordena normal por número crescente
           */
         return Number(a.description) - Number(b.description)
        })
        this.mixe.items[features[i].id] = {
          feature_items: sorted,
          feature_id: features[i].id,
          item_id: null,
          item_description: null,
          value_sd: null,
          nc_id: null,
          value_nc: null,
          value_z: null,
          value_medium: null,
          disabled: true,
        };
      }
      this.mixDesigns = mixDesigns;
      this.openModal();
    },
    setLabel(itemFeature, itemFeatureItem) {
      if (itemFeature.measured_unit && itemFeature.measured_unit.letter) {
        return itemFeatureItem.description + ' ' + itemFeature.measured_unit.letter
      }
      else {
        return itemFeatureItem.description
      }
    },
    handleSaveAndActive() {
      this.mixe.status = 1;
      this.save()
    },
    setStartValues(idFeature) {
      const id = idFeature
      if (this.mixe.items[id].item_id) {
        this.mixe.items[id].disabled = false
        const {
          feature_items
        } = this.mixe.items[id];
        const itemFeatureId = this.mixe.items[id].item_id
        const [itemFeatureSelected] = feature_items.filter(item => item.id === itemFeatureId)

        this.mixe.items[id].value_sd = parseFloat(itemFeatureSelected.value_sd) || 0
        this.mixe.items[id].value_nc = parseFloat(itemFeatureSelected.level_reliability) || 0
        this.mixe.items[id].value_medium = parseFloat(itemFeatureSelected.value_medium) || 0
        this.mixe.items[id].value_z = parseFloat(itemFeatureSelected.value_z) || 0
        this.calculateValueMedium(id)
      }

    },
    changeValueZ(idFeature) {
      const id = idFeature
      this.mixe.items[id].value_z = this.mixe.items[id].value_nc
      this.calculateValueMedium(id)
    },
    calculateValueMedium(idFeature) {
      this.$nextTick(() => {
        const id = idFeature
        const item_id = this.mixe.items[id].item_id
        const {
          feature_items
        } = this.mixe.items[id];
        const [itemFeatureSelected] = feature_items.filter(item => item.id === item_id)
        const description = itemFeatureSelected.description
        const value_sd = this.mixe.items[id].value_sd
        const value_z = this.mixe.items[id].value_nc

        const value_medium = parseFloat(description) + (value_sd * value_z)

        this.mixe.items[id].item_description = description.toString() || 0
        this.mixe.items[id].value_medium = parseFloat(value_medium) || 0
      })
    },
    save() {
      this.$Progress.start()
      if (!this.loadingSaveAndContinue) {
        this.loadingSave = true
      }
      this.$store.dispatch('MixDesignMixe/add', this.mixe)
        .then(response => {
          this.loadingSave = false
          if (!this.loadingSaveAndContinue) {
            this.modal = false
          }
          this.loadingSaveAndContinue = false;
          this.$Progress.finish()
          this.$notify({
            type: response.error_type,
            message: response.message
          })
          this.$emit('submit')
        })
        .catch(error => {
          this.$notify({
            type: error.data.error_type,
            message: error.data.message
          })
          this.$Progress.finish()
          this.loadingSave = false
          this.loadingSaveAndContinue = false
        })
    },
    saveAndContinue() {
      this.loadingSaveAndContinue = true;
      this.save();
    }
  },
  created() {
  },
  mounted() {
    this.$refs.formValidator.validate();
    this.$store.dispatch('reliability_level/fetchItems')
  }
};
</script>
