export const ProductServiceHubTypeEnum = Object.freeze({
    SERVICE: 1, // Serviço
    MCC: 2, // MCC
    PRODUCTS_AND_SERVICES: 3, // Produtos e Serviços
    FREIGHT: 4, // Frete
    AUTOMATIC_TAXES: 5, // Tributos Automáticos
    LOAN: 6, // Empréstimo
    FINANCING: 7, // Financiamento
    NOT_INFORMED: 8, // Não informado
    PAYROLL: 9, // Folha de pagamento
    FEES_AND_TAXES: 10, // Taxas e Tributos
    REVENUE_RETURN: 11, // Devolução de Receita
});