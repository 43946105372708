<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div class="p-3">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
            <div>
              <div>
                <div class="row align-items-center mb-3">
                  <div class="col-12 mb-2" style="margin-top: -15px">
                    <span v-if="water_control.retained_water">
                      <i style="font-size: 2rem" class="text-info fa-solid fa-droplet-slash"></i>
                      Água retida: <strong>{{ water_control.retained_water }} litros</strong>
                    </span>
                  </div>
                  <div class="col-12">
                    <!-- Title -->
                    <div class="border-header-primary-bottom">
                      <h5 class="h4 p-1 mb-0">Água Complementar</h5>
                    </div>
                  </div>
                </div>
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                  >
                    Central <span class="text-danger">*</span>
                  </label>
                  <div class="col-md-7">
                    <validation-provider rules="required">
                      <base-input input-group-classes="input-group-sm">
                        <input maxlength="3"
                               type="text"
                               v-mask="'###'"
                               inputmode="numeric"
                               v-model="water_control.company_plant_liters"
                               class="form-control form-control-sm"
                        />
                        <template slot="append">
                          <small class="input-group-sm p-0 m-0">
                            litros
                          </small>
                        </template>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                  >
                    Obra
                  </label>
                  <div class="col-md-7">
                    <base-input input-group-classes="input-group-sm">
                      <input maxlength="3"
                             inputmode="numeric"
                             v-mask="'###'"
                             v-model="water_control.construction_liters"
                             class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          litros
                        </small>
                      </template>
                    </base-input>
                  </div>
                </div>
              </div>


              <div class="mt-4">
                <div class="row align-items-center mb-3">
                  <div class="col-12">
                    <!-- Title -->
                    <div class="border-header-danger-bottom">
                      <h5 class="h4 p-1 mb-0">Água Suplementar</h5>
                    </div>
                  </div>
                </div>
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                  >
                    Autorização do cliente
                  </label>
                  <div class="col-md-7">
                    <validation-provider rules="required">
                      <base-switch
                        v-model="water_control.supplemental_water"
                        size="sm"
                        type="success"
                        class="success"
                      >
                      </base-switch>
                    </validation-provider>
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                  >
                    Obra
                  </label>
                  <div class="col-md-7">
                    <base-input input-group-classes="input-group-sm">
                      <input maxlength="3"
                             type="text"
                             v-mask="'###'"
                             v-model="water_control.supplemental_construction_liters"
                             class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          litros
                        </small>
                      </template>
                    </base-input>
                  </div>
                </div>
              </div>

            </div>
            <div class="modal-footer pb-0">
              <base-button type="secondary" @click="closeModal()">
                Cancelar
              </base-button>
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                :loading="loadingSave"
              >
                Salvar
              </base-button
              >
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  components: {},
  name: "ModalWaterControl",
  data() {
    return {
      modal: false,
      title: 'Controle de água',
      loadingSave: false,
      water_control: {
        company_plant_liters: '',
        construction_liters: '',
        supplemental_water: false,
        supplemental_construction_liters: '',
        schedule_travel_uuid: null,
      },
      index: undefined,
    }
  },
  computed: {},
  methods: {
    closeModal() {
      this.modal = false
    },
    openModal(params) {
      this.index = params.index
      if (params.water_control) {
        this.water_control = {...params.water_control}
      } else {
        this.water_control = {
          company_plant_liters: '',
          construction_liters: '',
          supplemental_water: false,
          supplemental_construction_liters: '',
          schedule_travel_uuid: params.uuid,
        }
      }
      this.water_control.retained_water = params.retained_water
      this.modal = true
    },
    save() {
      this.loadingSave = true
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch('travelConfiguration/createWaterControl', this.water_control)
        .then(response => {
            this.loadingSave = false
            this.modal = false
            this.$emit('setWaterControl', {
              water_control: response.data, index: this.index
            })
            this.$notify({type: 'success', message: response.message});
          }
        ).catch(() => this.loadingSave = false)
    },
  },
  created() {
  },
  mounted() {
  }
};
</script>

<style></style>
