import { AdjustmentTypeListType } from "./AdjustmentTypeListType";
import { PagerType, initPagerType } from "../../../../shared/types";

/**
 * Type para retorno paginado da listagem
 *
 * @typedef {(
 *   PagerType &
 *   { items: Array<AdjustmentTypeListType> }
 * )} AdjustmentTypeListPagerType
 */
export const AdjustmentTypeListPagerType = {};

/**
 * Inicializar AdjustmentTypeListPagerType
 *
 * @returns {AdjustmentTypeListPagerType}
 */
export const initAdjustmentTypeListPagerType = () => ({
  ...initPagerType(),
  items: [],
});
