import { BaseException } from ".";
const { formatErrorValidation } = require("@/plugins");

/**
 * Classe de exceção para validação.
 */
export default class ValidationException extends BaseException {
  /**
   * @param {string} message
   * @param {object} data
   */
  constructor(message = "Falha na validação dos dados", data) {
    super(message);
    this.data = data;
  }

  /**
   * Retorna a mensagem de erro formatada.
   *
   * @returns {string} Mensagem de erro formatada ou uma mensagem padrão.
   */
  getFormatedError() {
    const error = this.data ? formatErrorValidation(this.data) : this.message;
    const formatedError = error.trim() === '' 
      ? (this.message || 'Ocorreu um erro desconhecido.') 
      : error;
    return formatedError;
  }
}
