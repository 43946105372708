<template>
  <!-- ShadedTable pode ser colocada direto de um div, já é responsiva -->
  <div class="col-md-12 table-responsive mt-2">
    <table class="table table-custom-shaded table-sm" :class="{'mb-9 pb-9': hasMargins}">
      <thead>
        <tr v-if="length" class="">
          <th
            v-for="(header, index) in headers"
            class="text-center new-default-black-font"
            :style="'text-align: ' + (headersAligns[index] ? headersAligns[index] + ' !important' : 'center')"
          >
            <slot v-if="customHeaders.includes(index)" :name="'header_' + index">

            </slot>
           {{ !customHeaders.includes(index) ? header : '' }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(column, index) in length" :key="index">
          <slot :name="index">
            <!--
              Aqui ficarão as <ShadedCol>
              Sempre que <ShadedCol> receber 2 <div>, o primeiro alinhará tudo a direita e o segunda a esquerda
              <ShadedCol> pode receber qualquer elemento, o alinhamento pode ser passado assim <ShadedCol align="center">
              Exemplo de uso: /Configuration/FeesAndTaxes/Tributes/Shared/_Table.vue
            -->
          </slot>
        </tr>
        <tr v-if="loading" v-for="index in 10">
          <td class="text-center" v-for="column in headers.length">
            <div>
              <SkeletonPuzl custom_margin="5px 5px 0px 0px" type="text" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import SkeletonPuzl from "@/components/SkeletonPuzl.vue";

export default {
  name: 'shaded-table',
  props: {
    headers: {
      type: Array,
      default: () => [],
      description: 'Define os títulos do cabeçado.'
    },
    hasMargins: {
      type: Boolean,
      default: () => false,
      description: 'Adicionar margin e padding na tabela'
    },
    headersAligns: {
      type: Array,
      default: () => [],
      description: 'Define o alinhamento de cada item do cabeçalho'
    },
    customHeaders: {
      type: Array,
      default: () => [],
      description: 'Defina cabeçalhos personalizados usando índices. Por exemplo: customHeaders="[0,2]" significa que o 1º e o 3º cabeçalho serão personalizados.'
    },
    length: {
      type: Number,
      default: 0,
      description: 'Define quantas linhas a tabela terá.'
    },
    data: {
      type: Array,
      default: () => [],
      description: 'Table data'
    },
    loading: {
      type: Boolean,
      default: true,
      description: 'Disparar eventos de carregamento da tabela'
    },
  },
  components: {
    SkeletonPuzl,
  },
  computed: {},
  methods: {
  },
};
</script>

<style>
.table-custom-shaded {
  border-collapse: initial;
}

.table-custom-shaded thead th {
  background-color: #CCCCCC;
  font-size: 14px !important;
}

.table-custom-shaded th {
  padding-left: 1.2rem !important;
}

.table-custom-shaded tr td {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.85rem;
}

.table-custom-shaded td, .table-custom-shaded th {
  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15) !important;
  border-radius: 5px !important;
  border: 0 !important;
}

.table-custom-shaded td > nav:first-child {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
}

.table-custom-shaded td > nav:first-child > div:first-child {
  text-align: left;
}

.table-custom-shaded td > nav:first-child > div:nth-child(2) {
  text-align: right;
}
</style>
