<template>
  <div>
    <div v-if="isActive" class="col-md-7 ml-n3">
      <a 
        class="button-active-success"
        @click="toggleActive(false)"
        style="color: #198754;"
      >
        <img :src="activeImage" alt="active-image" width="12px">
        {{ activeLabel }}
      </a>
    </div>
    <div v-else class="col-md-7 ml-n3">
      <a
        class="button-inactive-danger"
        @click="toggleActive(true)"
        style="color: #dc3545;"
      >
        <img :src="inactiveImage" alt="inactive-image" width="12px">
        {{ inactiveLabel }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToggleButton',
  props: {
    initialValue: {
      type: [Boolean, Number],
      required: true
    },
    activeLabel: {
      type: String,
      default: 'Ativo'
    },
    inactiveLabel: {
      type: String,
      default: 'Inativo'
    },
    activeImage: {
      type: String,
      default: '/img/icons/ok--v1.png'
    },
    inactiveImage: {
      type: String,
      default: '/img/icons/icons8/ios/cancel_danger.png'
    }
  },
  data() {
    return {
      isActive: this.convertToBoolean(this.initialValue)
    }
  },
  methods: {
    convertToBoolean(value) {
      return Boolean(value)
    },
    toggleActive(value) {
      this.isActive = value
      this.$emit('update:initialValue', value ? 1 : 0)
    }
  },
  watch: {
    initialValue(newValue) {
      this.isActive = this.convertToBoolean(newValue)
    }
  }
}
</script>
<style scoped>
.button-active-success {
  background-color: #F2F7F3;
  border-radius: 8px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #149E57;
  display: flex;
  width: 130px;
  height: 25px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
}
.button-inactive-danger {
  background-color: #F4C7C3;
  border-radius: 8px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #DB4539;
  display: flex;
  width: 130px;
  height: 25px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
}
</style>