<template>
  <div>
    <multi-filter ref="multi-filter" @fetch="load" :filter="filter"/>
    <div class="container-fluid">
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <!-- Empty Data -->
      <PuzlEmptyData v-if="!bank_accounts.length && !loadingSkeleton"></PuzlEmptyData>
      <div class="row card-wrapper" v-if="bank_accounts.length && !loadingSkeleton">
        <div class="col-lg-4" v-for="(bank_account, index) in bank_accounts" :key="index">
          <!-- Basic with action button -->
          <card :class="bank_account.status ? 'card-border-top-success' : 'card-border-top-danger'">
            <!-- Card header -->
            <div class="row align-items-center mb-3">
              <div class="col-8">
                <!-- Title -->
                <img style="width: 27px;height: 27px"
                     :src="'/img/icons/banks/'+bank_account.code+ '.png'"/>
                <h5 class="h3 mb-0"><small>{{ bank_account.name }}</small></h5>
              </div>
              <div class="col-4 text-right">
                <img class="m-2" v-if="bank_account.pix_status === 1" src="/img/icons/pix.png" width="40px" height="40px">
                <base-dropdown menuOnRight>
                  <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                    <i class="fas fa-cog"></i>
                  </base-button>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleEdit(bank_account.id)">
                    <i class="fas fa-edit text-warning"></i>
                    Editar
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleSettings(bank_account.id)">
                    <img src="/img/icons/settings.png" width="20px" height="20px">
                    Configurações
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleBilletParamCreateEdit(bank_account.id)">
                    <i class="fas fa-solid fa-file-invoice text-primary"></i>
                    Boleto
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handlePixParamCreateEdit(bank_account.id)">
                    <img src="/img/icons/pix.png" width="20px" height="20px">
                    Pix
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleDelete(bank_account.id)">
                    <i class="fas fa-times text-danger"></i>
                    Excluir
                  </a>
                </base-dropdown>
              </div>
            </div>
            <!-- Card body -->
            <collapse class="border rounded">
              <collapse-item name="1" class="header-gray" back-ground="border-header-default-bottom">
                <h5 slot="title" class="mb-0 ">Detalhes</h5>
                <h5 class="h5 mb-0">Banco</h5>
                <p>
                  <span class="numeric">
                    {{ bank_account.code }} |
                  </span>
                  {{ bank_account.bank_name }}
                </p>
                <h5 class="h5 mb-0">Agência</h5>
                <p class="numeric">
                  {{ bank_account.number_without_digit_agency }}-{{ bank_account.digit_agency }}
                </p>
                <h5 class="h5 mb-0">Conta</h5>
                <p>
                  <span class="numeric">
                    {{ bank_account.number_without_digit_account }}-{{ bank_account.digit_account }} |
                  </span>
                  {{ bank_account.account_type }}
                </p>
                <h5 class="h5 mb-0">Ajuste de saldo</h5>
                <p class="numeric">
                  {{ bank_account.balance | currency() }}
                </p>
                <h5 class="h5 mb-0" v-show="bank_account.observation">Observações</h5>
                {{ bank_account.observation }}
              </collapse-item>
            </collapse>
          </card>
        </div>
      </div>
    </div>
    <ModalEdit ref="ModalEdit"></ModalEdit>
    <ModalBilletParamCreateEdit ref="ModalBilletParamCreateEdit"></ModalBilletParamCreateEdit>
    <ModalPixParamCreateEdit @refresh="updateItemStatus" ref="ModalPixParamCreateEdit"></ModalPixParamCreateEdit>
    <ModalBankAccountSettings ref="modalBankAccountSettings" />
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex'
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import MultiFilter from '@/components/Utils/MultiFilter.vue';
import ModalEdit from "./_Edit";
import ModalBilletParamCreateEdit from "./_BilletParamCreateEdit";
import ModalPixParamCreateEdit from './_PixParamCreateEdit';
import ModalBankAccountSettings from "./_ModalBankAccountSettings";

export default {
  name: "List",
  components: {
    PuzlEmptyData,
    SkeletonPuzlGrid,
    MultiFilter,
    ModalEdit,
    ModalBilletParamCreateEdit,
    ModalBankAccountSettings,
    ModalPixParamCreateEdit
  },
  data() {
    return {
      loadingSkeleton: true,
      filter: {},
      bankAccounts: []
    }
  },
  computed: {
    ...mapGetters({
      'bank_accounts': 'bankAccount/fetch',
    }),
  },
  methods: {
    load(filter = {}) {
      this.filter = filter
      this.init();
    },
    init() {
      this.loadingSkeleton = true;
      this.$store.dispatch('bankAccount/fetchItems', {
        filter: this.filter
      })
        .then(response => {
          this.loadingSkeleton = false
        })
        .catch(error => {
          this.loadingSkeleton = false
        })
    },
    handleDelete(id) {
      this.$Swal
        .confirmDelete()
        .then((result) => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação.",
            });
            this.$store.dispatch("bankAccount/destroy", id).then((response) => {
              this.$notify({
                type: 'success',
                message: response.message
              });
            })
              .catch(error => {
                this.$notify({
                  type: error.data.error_type,
                  message: error.data.message
                });
              });
            this.$Progress.finish();
          }
        })
        .catch(() => this.$Progress.finish());
    },
    handleEdit(id) {
      this.$refs.ModalEdit.handleEdit(id);
    },
    handleBilletParamCreateEdit(id) {
      this.$refs.ModalBilletParamCreateEdit.handleCreateModal(id);
    },
    handlePixParamCreateEdit(bankAccountId)
    {
      this.$refs.ModalPixParamCreateEdit.handleCreateModal(bankAccountId);
    },
    /**
     * Abre modal de configurações da conta bancária.
     * @param {integer} id
     */
    updateItemStatus(bankAccountId) {
        // let payload = {
        //   id: bankAccountId,
        //   status: 1
        // }
        // this.$store.dispatch('bankAccount/updatePixStatus', payload);
        this.init();
    },
    handleSettings(id) {
      this.$refs.modalBankAccountSettings.openModal(id);
    },
  },
  mounted() {
    this.filter.status = 1;
    this.$refs['multi-filter']._self.$forceUpdate();
    this.init();
  },
}
</script>

<style scoped>
</style>
