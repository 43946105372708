<template>
  <div>

    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-gray d-inline-block mb-0">
            {{ this.$route.meta.breadcrumbs[2].title }}</h6>
          <nav aria-label="breadcrumb"
               class="d-none d-md-inline-block ml-md-4 text-gray">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i
                :class="this.$route.meta.icon"></i></a></li>
              <li class="breadcrumb-item"><a
                href="#">{{ this.$route.meta.breadcrumbs[0].title }}</a></li>
              <li class="breadcrumb-item"><a
                href="#">{{ this.$route.meta.breadcrumbs[1].title }}</a></li>
              <li class="breadcrumb-item" aria-current="page">
                {{ this.$route.meta.breadcrumbs[2].title }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- Header Menus -->
      <div class="row mb-3">

        <div class="col-md-2 mb-2">
          <router-link :to="{
            path: '/commercial/contract-proposal/additional',
            name: 'commercial.contract-proposal.additional.view',
            params: { contract_proposal_uuid: contract_proposal.uuid }
          }">
            <base-button block size="lg" type="light" class="text-uppercase">
              voltar
            </base-button>
          </router-link>
        </div>

      </div>
      <div class="row align-items-center pb-0 mb-3">
        <div class="col-md-12">
          <div class="checklist-item checklist-item-primary">
            <div class="checklist-info">
              <h2 class="checklist-title mb-0">
                Proposta/Contrato: <span class="small">{{ contract_proposal.code }}</span>
              </h2>
              <h2 class="checklist-title mb-0">
                Cliente: <span
                class="small">{{
                  contract_proposal.construction && contract_proposal.construction.customer_construction.customer_name
                }}</span>
              </h2>
              <h2 class="checklist-title mb-0">
                Obra: <span class="small">{{
                  contract_proposal.construction && contract_proposal.construction.construction_name
                }}</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid">
      <!-- Start Card Additional -->
      <card>
        <div>
          <div class="form-group row m-0 p-0 mb-1">
            <div class="col-md-12">
              <base-input input-group-classes="input-group-sm" group class="mb-2">
                <input
                  type="text"
                  class="form-control form-control-sm"
                />
                <div class="input-group-append" id="button-addon4">
                  <button
                    class="btn btn-outline-primary btn-sm form-control-sm p-1"
                    type="button"
                    @click.prevent="handleSearch()"
                  >
                    Pesquisar
                  </button>
                </div>
              </base-input>
            </div>
          </div>
        </div>
        <div>
          <div class="pl-3 pr-3 pt-2" v-for="(additional, indexAdditional) in additionals.filter((item) => item.status == 1)" :key="indexAdditional">
            <div class="row d-flex justify-content-between">
              <div class="col-4">
                <h4 class="p-0">
                  {{ additional.short_description }}
                </h4>
                <h5 class=" p-0">
                  <small>
                    {{ additional.description }}
                  </small>
                </h5>
              </div>
              <div class="col-7">
                <div v-for="(param, index) in additional.params_description">
                                      <span class="p-0">
                                 {{ param.description }}
                                </span>
                </div>
              </div>
              <div class="col-1 d-flex align-items-center justify-content-center">
                <base-button
                  :disabled="loading"
                  size="sm"
                  type="success"
                  @click.prevent="setAdditional(additional)"
                >
                  <i class="ni ni-check-bold mt-1"></i>
                </base-button>
              </div>
            </div>
            <div class="dropdown-divider p-0 m-0"></div>
          </div>
          <!--          <div class="pl-3 pr-3 pt-2" v-for="(additional, indexAdditional) in additionals" :key="indexAdditional">-->
          <!--            <card>-->
          <!--            <div class="row d-flex justify-content-between">-->
          <!--              <div class="col-5">-->
          <!--                <h4 class="p-0">-->
          <!--                  {{ additional.short_description }}-->
          <!--                </h4>-->
          <!--                <h5 class="mt-4 small p-0">-->
          <!--                  {{ additional.description }}-->
          <!--                </h5>-->
          <!--              </div>-->
          <!--              <div class="col-4">-->
          <!--                <h5 class="small p-0">-->
          <!--                  <span class="h4"> Valor padrão : </span>-->
          <!--                  <br>-->
          <!--                  {{ additional.standard_value }}-->
          <!--                </h5>-->
          <!--                <br>-->
          <!--                <h5 class="small p-0">-->
          <!--                  <span class="h4">Unid. de Cobrança: </span>-->
          <!--                  <br>-->
          <!--                  {{ additional.billing_unit_description }}-->
          <!--                </h5>-->
          <!--              </div>-->
          <!--              <div class="col-3">-->
          <!--                <label>Datas</label>-->
          <!--                <span v-for="date in additional.dates" :key="date">-->
          <!--                          <h4 class="checklist-title mb-0" v-if="date === 0">-->
          <!--                            Dia útil-->
          <!--                          </h4>-->
          <!--                          <h4 class="checklist-title mb-0" v-if="date === 1">-->
          <!--                            Sábado-->
          <!--                          </h4>-->
          <!--                          <h4 class="checklist-title mb-0" v-if="date === 2">-->
          <!--                            Domingo-->
          <!--                          </h4>-->
          <!--                          <h4 class="checklist-title mb-0" v-if="date === 3">-->
          <!--                            Feriado-->
          <!--                          </h4>-->
          <!--                        </span>-->
          <!--              </div>-->
          <!--              <div class="col-4">-->
          <!--                <label>Serviços</label>-->
          <!--                <div class="checklist-item checklist-item-primary">-->
          <!--                  <div-->
          <!--                    class="checklist-info"-->
          <!--                  >-->
          <!--                    <h4 v-for="(itemService,-->
          <!--                        index) in additional.services_description"-->
          <!--                        :key="index" class="checklist-title mb-0">-->
          <!--                      {{ itemService.service_name }}-->
          <!--                    </h4>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--              <div class="col-6">-->
          <!--                <label>Prazos e Limites</label>-->
          <!--                <div v-for="(param, index) in additional.params_description">-->
          <!--                      <span class="h4 p-0">-->
          <!--                  Parâmetro <span class="text-uppercase mr-4">{{ index }}</span> {{ param.param }}-->
          <!--                </span>-->
          <!--                  <br>-->
          <!--                  <span class="h4 p-0">-->
          <!--                  Valor <span class="text-uppercase mr-4">{{ index }}</span> {{ param.values }}-->
          <!--                </span>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--              <div style="margin-top: -120px" class="col-1 d-flex align-items-center justify-content-end">-->
          <!--                <base-button-->
          <!--                  size="sm"-->
          <!--                  type="success"-->
          <!--                  @click.prevent="setAdditional(additional)"-->
          <!--                >-->
          <!--                  <i class="ni ni-check-bold mt-1"></i>-->
          <!--                </base-button>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            </card>-->
          <!--            <br>-->
          <!--            <div class="dropdown-divider p-0 m-0"></div>-->
          <!--          </div>-->
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <router-link :to="{
            path: '/commercial/contract-proposal/additional',
            name: 'commercial.contract-proposal.additional.view',
            params: { contract_proposal_uuid: contract_proposal.uuid }
          }">
            <base-button type="secondary">Cancelar</base-button>

          </router-link>
        </div>
      </card>
      <!-- End Card Additional -->

    </div>
  </div>

</template>
<script>
import {mapGetters} from "vuex";

export default {
  components: {},
  name: "CreateAdditional",
  computed: {
    ...mapGetters({
      additionals: "additional/additionals",
      contract_proposal: "contractProposal/show",
    })
  },
  data() {
    return {
      modal: false,
      title: 'Adicionais',
      loading: false,
    }
  },
  methods: {
    handleSearchAdditional(search_name) {
      this.modal = true
    },
    handleSearch() {
    },
    setAdditional(additional) {
      this.loading = true
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.$Progress.start();
      let params = {
        uuid: additional.uuid,
        contract_proposal_uuid: this.contract_proposal.uuid
      }
      this.$store.dispatch('contractAdditional/add', params)
        .then(response => {
          this.$Progress.finish();
          this.loading = false
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.$router.push(
            "/commercial/contract-proposal/additional/" +
            this.$route.params.contract_proposal_uuid
          );
        }).catch(error => {
        this.$notify({type: 'danger', message: error.data.message})
        this.$Progress.finish()
        this.loading = false
      })
    },
  },
  mounted() {
    this.$store.dispatch('additional/fetchAdditionals')
    this.$store
      .dispatch(
        "contractProposal/show",
        this.$route.params.contract_proposal_uuid
      )
      .then(response => {
        this.loading = false;
      });
  }
};
</script>
