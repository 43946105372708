const paymentTermMixin = {
  methods: {
    calcFirstInstallment() {
      if (this.payload.installments.length > 1) {
        let percentage = 0;
        this.payload.installments.map(function (item, index) {
          percentage += index !== 0 ? parseFloat(item.percentage) : 0;
        });
        let final_value = 100 - percentage;
        this.payload.installments[0].percentage = final_value > 0 ? final_value : 0;
      }
    },
    fillDays(index) {
      if (index === 1) {
        let current_deadline = this.payload.installments[0].deadline;
        this.payload.installments.map(function (item, index) {
          if (index > 0) {
            item.deadline = current_deadline * item.index;
          }
        });
      }
    },
  },
};
export default paymentTermMixin;
