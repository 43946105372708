import * as types from './mutation_types'
import { createAxios } from "@/plugins/axios";
const endPoint = '/financial/bank-account/setting'
export default {
  namespaced: true,
  state: {
    item: [],
  },
  getters:{
    show: state => state.item,
  },
  mutations: {
    [types.SHOW] (state, params){
      state.item = params
    },
  },
  actions: {
    getByBankAccountId({commit}, bankAccountId) {
			return createAxios().get(`${endPoint}/${bankAccountId}`)
				.then(({data}) => {
					commit(types.SHOW, data.data)
					return Promise.resolve(data)
				}).catch(error =>{
					return Promise.reject(error)
				});
    },
    updateOrCreate(_, params) {
      return createAxios().post(`${endPoint}`, params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
       });
    },
  }
}
