<template>
  <div>
    <multi-filter ref="multi-filter" @fetch="load" :filter="filter" />
  
    <div class="container-fluid">
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <!-- Empty Data -->
      <PuzlEmptyData v-if="!additional_Classifications.length && !loadingSkeleton"></PuzlEmptyData>

      <div class="row card-wrapper" v-if="additional_Classifications.length && !loadingSkeleton">
        <div
          class="col-lg-4"
          v-for="(additional_Classification, indexAdditionalClassification) in additional_Classifications"
          :key="indexAdditionalClassification"
        >
          <!-- Basic with action button -->
          <card :class="additional_Classification.status ? 'card-border-top-success' : 'card-border-top-danger'">
            <!-- Card header -->
            <div class="row align-items-center mb-3">
              <div class="col-8">
                <!-- Title -->
                <h5 class="h3 mb-0">{{ additional_Classification.additional_classification }}</h5>
                <h6 class="h3 mb-0 small">{{ additional_Classification.cmc_category.name }}</h6>
              </div>

              <div class="col-4 text-right">
                <base-dropdown menuOnRight>
                  <base-button
                    slot="title-container"
                    type="primary"
                    class="dropdown-toggle p-2 rounded m-0"
                  >
                    <i class="fas fa-cog"></i>
                  </base-button>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleEditAdditionalClassification(additional_Classification.uuid)">
                    <i class="fas fa-edit text-warning"></i>
                    Editar
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleDeleteAdditionalClassification(additional_Classification.uuid)">
                    <i class="fas fa-times text-danger"></i>
                    Excluir
                  </a>
                </base-dropdown>
              </div>
            </div>
            <!-- Card body -->

            <collapse class="border rounded" v-if="additional_Classification.remarks">
              <collapse-item name="1" class="header-gray" back-ground="border-header-default-bottom">
                <h5 slot="title" class="mb-0 ">Detalhes</h5>
                <p>{{ additional_Classification.remarks }}</p>
              </collapse-item>
            </collapse>
          </card>
        </div>
      </div>
      <edit-additional-classification ref="editAdditionalClassification"></edit-additional-classification>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid"
import PuzlEmptyData from "@/components/PuzlEmptyData"
import EditAdditionalClassification from  './_Edit'
import MultiFilter from '@/components/Utils/MultiFilter.vue';

export default {
  name: "ListAdditionalClassification",
  components: {
    PuzlEmptyData,
    SkeletonPuzlGrid,
    EditAdditionalClassification,
    MultiFilter
  },
  data () {
    return {
      loadingSkeleton: true,
      filter: {}
    }
  },
  computed: {
    ...mapGetters({
      'additional_Classifications': 'cmcAdditionalClassification/fetch',
    }),
  },
  methods:{
    load(filter = {}) {
      this.filter = filter
      this.init();
    },
    init() {
      this.loadingSkeleton = true;
      this.$store.dispatch('cmcAdditionalClassification/fetchItems', { filter: this.filter })
        .then( response =>{
          this.loadingSkeleton = false
        })
        .catch( error => {
          this.loadingSkeleton = false
        })
    },
    handleEditAdditionalClassification(uuid){
      this.$refs.editAdditionalClassification.handleEditModal(uuid)
    },
    handleDeleteAdditionalClassification(uuid){
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed){
          this.$Progress.start();
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          this.$store.dispatch('cmcAdditionalClassification/destroy', uuid).then(response => {
            this.$notify({
              type: 'success',
              message: response.message
            });
          }).catch(error => {
            this.$notify({
              type: error.data.error_type,
              message: 'Erro ao excluir classificação adicional!'
            });
          });
        }
      }).catch(() => this.$Progress.finish())
    }
  },
  mounted() {
    this.filter.status = 1;
    this.$refs['multi-filter']._self.$forceUpdate();
    this.init();
  },
}
</script>

<style scoped>

</style>
