<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <div class="row m-0 p-0" v-show="loading">
          <div class="col-md-8  m-0 p-0">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
          <div class="col-md-4">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
        </div>

        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator" v-show="!loading">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div>
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 col-form-label form-control-label">
                  Central
                </label>
                <div class="col-md-8">
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                    v-model="user.company_plant_uuids"
                    :items="[{uuid: 'all', name: 'Todos'}, ...plants]"
                    customKey="uuid"
                    :multiple="true"
                    :loading="loadingPlant"
                    :disabled="loadingPlant"
                     @input="setAll" />
                  </base-input>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import PuzlSelect from "@/components/PuzlSelect";

const {formatErrorValidation} = require("@/plugins")

export default {
  name: "ModalSetPlant",
  components: {
    SkeletonPuzl,
    PuzlSelect,
  },
  data() {
    return {
      modal: false,
      title: 'Alocação de central',
      loadingPlant: true,
      loadingSave: false,
      loading: true,
      user: {
        user_uuid: null,
        company_plant_uuids: null,
      },
      loadingStore: false,
    };
  },
  computed: {
    ...mapGetters({
      plants: "plant/activeItems",
      user_plants: "userPlant/show",
    })
  },
  methods: {
    closeModal() {
      this.modal = false
    },
    openModal(userUuid) {
      this.fetch(userUuid)
      this.modal = true
    },
    fetch(userUuid) {
      this.$Progress.start();
      this.loading = true
      this.$store.dispatch('userPlant/show', userUuid).then(response => {
        let plants = []
        if (this.user_plants.plants[0] === 'all') {
            plants = this.user_plants.plants
        }
        else {
          plants = this.user_plants.plants.map(item => item.uuid)
        }
        this.user = {
          user_uuid: userUuid,
          company_plant_uuids: plants,
        }
        this.loadingPlant = false
        this.loading = false
        this.$Progress.finish();
      })
    },
    store() {
      this.$Progress.start();
      this.loadingStore = true;
      let user = {...this.user}
      user.has_access_to_all_plants = 0
      if (this.user.company_plant_uuids.includes('all')) {
        user.company_plant_uuids = []
        user.has_access_to_all_plants = 1
      }
      this.$store.dispatch("userPlant/add", user).then(response => {
        this.loadingStore = false;
        this.$Progress.finish();
        this.$notify({
          type: response.error_type,
          message: response.message
        });
        this.$store.dispatch('user/fetchItems')
        this.modal = false
      }).catch(error => {
        if (error.status) {
          this.$Progress.finish()
          this.loadingStore = false
          this.$notify({
            type: error.data.error_type,
            message: error.data.message
          })
        } else {
          if (error.response.status === 422) {
            let message = formatErrorValidation(error.response.data.errors)
            this.$notify({
              type: 'danger',
              message: message
            })
            this.$Progress.finish()
            this.loadingStore = false
          } else {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            })
            this.$Progress.finish()
            this.loadingStore = false
          }
        }
      });
    },
    setAll() {
      const index = this.user.company_plant_uuids.findIndex((item) => item == 'all');
      if (this.user.company_plant_uuids.length > 1 && index === 0) {
        return this.user.company_plant_uuids.splice(0, 1)
      }
      if (this.user.company_plant_uuids.length > 1 && this.user.company_plant_uuids.includes("all")) {
        this.user.company_plant_uuids = []
        this.user.company_plant_uuids.push("all")
      }
    },
  },
  mounted() {
    this.$store.dispatch("plant/fetchItemsActive", 0).then(() => {
    })
  },
  beforeUpdate() {
  },
}
</script>
