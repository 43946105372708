<template>
  <div>
    <modal size="md" v-if="Object.values(contract_proposal).length" class="bill-modal-create" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <!--        CONTRATOS-->
        <div v-if="payload.bill_receive.type === 1">
          <div
            class="border-left border-3 border-primary pr-1 mt-0 mb-2"
            v-if="contract_proposal"
          >
            <div class="col-md-12">
              <span class="display-5 mb-0">
                <b>
                  {{
                    (contract_proposal &&
                      contract_proposal.construction &&
                      contract_proposal.construction.construction_name) ||
                    ""
                  }}
                </b>
              </span>
              <br/>
              <span class="display-5 mb-0">
                <b>
                  {{
                    (contract_proposal &&
                      contract_proposal.construction &&
                      contract_proposal.construction.customer_construction &&
                      contract_proposal.construction.customer_construction
                        .customer_name) ||
                    ""
                  }}
                </b>
              </span>
              <br/>
              <span class="display-5 mb-0">
                <b>
                  {{
                    (contract_proposal &&
                      contract_proposal.service_receiver &&
                      contract_proposal.service_receiver.document) ||
                    ""
                  }}
                </b>
              </span>
            </div>
          </div>
          <div class="row mb-2 m-0 p-0">
            <div class="col-md-3 mb-1 p-1">
              <base-button
                size="sm"
                :class="type === 1 && 'active'"
                @click.prevent="type = 1"
                block
                outline
                type="primary"
              >Pagamento
              </base-button>
            </div>
            <div class="col-md-3 mb-1 p-1">
              <base-button
                size="sm"
                :disabled="
                  payload.bill_receive.company_plant_issuer_id === null
                "
                :class="type === 2 && 'active'"
                @click.prevent="type = 2"
                block
                outline
                type="primary"
              >Faturas <i v-if="invoices.length > 0" class="fa-solid fa-circle-exclamation text-warning"></i>
              </base-button>
            </div>
            <div class="col-md-3 p-1">
              <base-button
                class="text-primary"
                size="sm"
                :class="type === 3 && 'active'"
                :disabled="payload.installments.length < 1"
                @click.prevent="type = 3"
                block
                outline
                type="primary"
              >Parcelas
              </base-button>
            </div>
            <div class="col-md-3 p-1">
              <base-button
                size="sm"
                :class="type === 4 && 'active'"
                @click.prevent="type = 4"
                block
                outline
                type="primary"
              >Anexos
              </base-button>
            </div>
          </div>
          <validation-observer
            v-slot="{ invalid, handleSubmit }"
            ref="formValidator"
          >
            <form
              class="needs-validation"
              @submit.prevent="handleSubmit(store)"
              autocomplete="off"
            >
              <div>
                <!--                PAGAMENTO-->
                <section v-show="type == 1">
                  <div class="form-group row m-0 p-0 mb-1 limited-row">
                    <label
                      class="
                        col-md-6
                        pb-1
                        pt-2
                        col-form-label
                        form-control-label
                      "
                    >
                      Competência
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <validation-provider rules="required">
                        <base-input input-group-classes="input-group-sm">
                          <input-date-time-picker
                            @input="generateInstallments(1)"
                            v-model="payload.bill_receive.competence"
                            :mode="'date'"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-2 limited-row">
                    <label
                      class="
                        col-md-6
                        pt-2
                        pb-1
                        col-form-label
                        form-control-label
                      "
                    >
                      Valor total de crédito
                      <span class="text-danger">&nbsp;*&nbsp;</span>
                    </label>
                    <div class="col-md-6">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          @keydown="
                            $event.key === '-' ? $event.preventDefault() : null
                          "
                          @blur="generateInstallments(1)"
                          v-money="money"
                          maxlength="13"
                          inputmode="numeric"
                          v-model.lazy="payload.bill_receive.total_value"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <template slot="prepend">
                          <small class="input-group-sm p-0 m-0"> R$ </small>
                        </template>
                      </base-input>
                    </div>
                  </div>
                  <!--                  <div class="form-group row m-0 p-0 mb-2 limited-row">-->
                  <!--                    <label-->
                  <!--                      class="col-md-6 pt-2 pb-1 col-form-label form-control-label"-->
                  <!--                    >-->
                  <!--                      Retenção ISS-->
                  <!--                    </label>-->
                  <!--                    <div class="col-md-6">-->
                  <!--                      <base-input input-group-classes="input-group-sm">-->
                  <!--                        <input-->
                  <!--                          @blur="generateInstallments(1)"-->
                  <!--                          v-money="money"-->
                  <!--                          @keydown="$event.key === '-' ? $event.preventDefault() : null"-->
                  <!--                          maxlength="10"-->
                  <!--                          inputmode="numeric"-->
                  <!--                          v-model.lazy="payload.bill_receive.iss_retain"-->
                  <!--                          type="text"-->
                  <!--                          class="form-control form-control-sm"-->
                  <!--                        />-->
                  <!--                        <template slot="prepend">-->
                  <!--                          <small class="input-group-sm p-0 m-0">-->
                  <!--                            R$-->
                  <!--                          </small>-->
                  <!--                        </template>-->
                  <!--                      </base-input>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <div class="form-group row m-0 p-0 mb-2 limited-row">
                    <label
                      class="
                        col-md-6
                        pt-2
                        pb-0
                        col-form-label
                        form-control-label
                      "
                    >
                      Valor final a receber
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          disabled
                          v-money="money"
                          @keydown="
                            $event.key === '-' ? $event.preventDefault() : null
                          "
                          maxlength="13"
                          inputmode="numeric"
                          v-model.lazy="payload.bill_receive.final_value"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <template slot="prepend">
                          <small class="input-group-sm p-0 m-0"> R$ </small>
                        </template>
                      </base-input>
                    </div>
                  </div>

                  <div class="card-header mt-n4 mb-3"></div>

                  <div class="form-group row m-0 p-0 mb-2 limited-row">
                    <label
                      class="
                        col-md-6
                        pt-2
                        pb-1
                        col-form-label
                        form-control-label
                      "
                    >
                      Método de pagamento
                      <span class="text-danger">&nbsp;*&nbsp;</span>
                    </label>
                    <div class="col-md-6">
                      <validation-provider rules="required">
                        <base-input input-classes="form-control-sm">
                          <el-select
                            @change="getPaymentTerms(true)"
                            @input="setRequireNsuCode()"
                            v-model="payload.bill_receive.payment_method_uuid"
                            size="mini"
                            placeholder="Selecione"
                            filterable
                            :loading="loadingPlant"
                            :disabled="
                              loadingPlant ||
                              payload.bill_receive.total_value === '0,00'
                            "
                          >
                            <el-option
                              v-for="method in payment_methods_selected"
                              :key="method.uuid"
                              :label="method.name"
                              :value="method.uuid"
                            >
                            </el-option>
                          </el-select>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-2 limited-row">
                    <label
                      class="
                        col-md-6
                        pt-2
                        pb-1
                        col-form-label
                        form-control-label
                      "
                    >
                      Condição de pagamento
                      <span class="text-danger">&nbsp;*&nbsp;</span>
                    </label>
                    <div class="col-md-6">
                      <validation-provider :rules="required"
                      >
                        <base-input input-classes="form-control-sm">
                          <el-select
                            @change="generateInstallments"
                            v-model="payload.bill_receive.payment_term_uuid"
                            size="mini"
                            placeholder="Selecione"
                            filterable
                            :disabled="
                              loadingPlant ||
                              payload.bill_receive.total_value === '0,00' ||
                              !payload.bill_receive.payment_method_uuid
                            "
                          >
                            <el-option
                              v-for="term in payment_terms_selected"
                              :key="term.uuid"
                              :label="term.name"
                              :value="term.uuid"
                            >
                            </el-option>
                          </el-select>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <!--                  <div class="form-group row m-0 p-0 mb-2 limited-row">-->
                  <!--                    <label-->
                  <!--                      class="-->
                  <!--                        col-md-6-->
                  <!--                        pt-2-->
                  <!--                        pb-1-->
                  <!--                        col-form-label-->
                  <!--                        form-control-label-->
                  <!--                      "-->
                  <!--                    >-->
                  <!--                      Mediador de pagamento-->
                  <!--                      <span-->
                  <!--                        class="text-danger"-->
                  <!--                        v-show="payment_intermediaries_exists"-->
                  <!--                      >&nbsp;*&nbsp;</span-->
                  <!--                      >-->
                  <!--                    </label>-->
                  <!--                    <div class="col-md-6">-->
                  <!--                      <validation-provider-->
                  <!--                        :rules="-->
                  <!--                          ((hasInstallmentPaid ||-->
                  <!--                            payment_intermediaries_exists) &&-->
                  <!--                            'required') ||-->
                  <!--                          ''-->
                  <!--                        "-->
                  <!--                      >-->
                  <!--                        <base-input input-classes="form-control-sm">-->
                  <!--                          <PuzlSelect-->
                  <!--                            placeholder="Nenhum"-->
                  <!--                            @input="generateInstallments()"-->
                  <!--                            @change="getPaymentIntermediaryMerchants()"-->
                  <!--                            :items="payment_intemerdiaries_selected"-->
                  <!--                            :disabled="-->
                  <!--                              loadingPaymentIntermediaries ||-->
                  <!--                              !payload.bill_receive.payment_method_uuid-->
                  <!--                            "-->
                  <!--                            v-model="-->
                  <!--                              payload.bill_receive.payment_intermediary_id-->
                  <!--                            "-->
                  <!--                          />-->
                  <!--                        </base-input>-->
                  <!--                      </validation-provider>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <!--                  <div-->
                  <!--                    v-if="isDisplayPaymentIntermediaryMerchants(payload.bill_receive.payment_intermediary_id)"-->
                  <!--                    class="form-group row m-0 p-0 mt-1 mb-2 limited-row"-->
                  <!--                  >-->
                  <!--                    <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label">-->
                  <!--                      Ponto de Venda (PV)-->
                  <!--                      <span class="text-danger">&nbsp;*&nbsp;</span>-->
                  <!--                    </label>-->
                  <!--                    <div class="col-md-6">-->
                  <!--                      <base-input input-classes="form-control-sm">-->
                  <!--                        <PuzlSelect-->
                  <!--                          placeholder="Nenhum"-->
                  <!--                          :items="payment_intermediary_merchants"-->
                  <!--                          label="description"-->
                  <!--                          v-model="payload.bill_receive.payment_intermediary_merchant_id"-->
                  <!--                        />-->
                  <!--                      </base-input>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <!--                  <div class="form-group row m-0 p-0 mb-2 limited-row">-->
                  <!--                    <label-->
                  <!--                      class="-->
                  <!--                        col-md-6-->
                  <!--                        pt-2-->
                  <!--                        pb-1-->
                  <!--                        col-form-label-->
                  <!--                        form-control-label-->
                  <!--                      "-->
                  <!--                    >-->
                  <!--                      NSU da transação-->
                  <!--                      <span-->
                  <!--                        class="text-danger"-->
                  <!--                        v-show="require_nsu_code"-->
                  <!--                        >&nbsp;*&nbsp;</span-->
                  <!--                      >-->
                  <!--                      <span v-if="-->
                  <!--                        modalCardIntegrations &&-->
                  <!--                        payload.bill_receive.payment_intermediary_id &&-->
                  <!--                        payload.bill_receive.payment_intermediary_merchant_id &&-->
                  <!--                        (payload.bill_receive.payment_method_uuid === '947bf91c-abd0-43d4-a0b7-97a50a481cdd' ||-->
                  <!--                        payload.bill_receive.payment_method_uuid === '947bf924-efbd-4263-809a-b2105f6674e2')"-->
                  <!--                      >-->
                  <!--                        &lt;!&ndash; <a href="javascript:void(0)"-->
                  <!--                          @click.prevent="handleCreateModalCardIntegration"-->
                  <!--                          class="fa-regular fa-credit-card text-success fa-xl">-->
                  <!--                        </a> &ndash;&gt;-->
                  <!--                      </span>-->
                  <!--                    </label>-->
                  <!--                    <div class="col-md-6">-->
                  <!--                      <validation-provider-->
                  <!--                        :rules="(require_nsu_code && 'required') || ''"-->
                  <!--                      >-->
                  <!--                        <base-input input-classes="form-control-sm">-->
                  <!--                          <input-->
                  <!--                            inputmode="numeric"-->
                  <!--                            v-mask="'####################'"-->
                  <!--                            v-model="payload.bill_receive.nsu_code"-->
                  <!--                            class="form-control form-control-sm"-->
                  <!--                          />-->
                  <!--                        </base-input>-->
                  <!--                      </validation-provider>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <div class="card-header mt-n4 mb-3"></div>
                  <div class="form-group row m-0 p-0 mb-2 limited-row">
                    <label
                      class="
                        col-md-6
                        pt-2
                        pb-0
                        col-form-label
                        form-control-label
                      "
                    >
                      Central
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <base-input input-group-classes="input-group-sm">
                        <el-select
                          @input="changeBankAccount()"
                          :disabled="payload.invoices.length > 0"
                          @change="setInvoice(true)"
                          v-model="payload.bill_receive.company_plant_id"
                          size="mini"
                          placeholder="Selecione"
                          filterable
                          :loading="loadingCompanyPlants"
                        >
                          <el-option
                            v-for="(item, index) in $_company_plants"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-2 limited-row">
                    <label
                      class="
                        col-md-6
                        pt-2
                        pb-0
                        col-form-label
                        form-control-label
                      "
                    >
                      CNPJ Beneficiário
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <validation-provider rules="required">
                        <base-input input-group-classes="input-group-sm">
                          <el-select
                            :disabled="
                            payload.invoices.length > 0 ||
                            payload.bill_receive.company_plant_id === null
                          "
                            @change="setInvoice()"
                            v-model="payload.bill_receive.company_plant_issuer_id"
                            size="mini"
                            placeholder="Selecione"
                            filterable
                            :loading="loadingCompanyPlantIssuers"
                          >
                            <el-option
                              v-for="(item, index) in company_plant_issuers"
                              :key="index"
                              :label="item.alias_name"
                              :value="item.id"
                            >
                            </el-option>
                          </el-select>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-2 limited-row">
                    <label
                      class="
                        col-md-6
                        pt-2
                        pb-1
                        col-form-label
                        form-control-label
                      "
                    >
                      Conta bancária de crédito
                      <span class="text-danger">&nbsp;*&nbsp;</span>
                    </label>
                    <div class="col-md-6">
                      <validation-provider rules="required">
                        <base-input input-classes="form-control-sm">
                          <el-select
                            v-model="payload.bill_receive.bank_account_id"
                            size="mini"
                            placeholder="Selecione"
                            filterable
                            :disabled="loadingBankAccounts"
                          >
                            <el-option
                              v-for="item in bank_accounts"
                              :key="item.bank_account.id"
                              :label="formatAccountLabel(item.bank_account)"
                              :value="item.bank_account.id"
                            >
                            </el-option>
                          </el-select>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0">
                    <label
                      class="
                        col-md-6
                        pt-2
                        pb-1
                        col-form-label
                        form-control-label
                      "
                    >
                      Observações
                    </label>
                    <div class="col-md-6">
                      <base-input input-group-classes="input-group-sm">
                          <textarea
                            v-model="payload.bill_receive.observations"
                            rows="3"
                            type="text"
                            class="form-control form-control-sm"
                          />
                      </base-input>
                    </div>
                  </div>
                </section>
                <!--                FATURAS-->
                <section v-show="type == 2">
                  <div class="form-group row m-0 p-0 mb-1">
                    <label
                      class="
                        col-md-12
                        pt-2
                        pb-1
                        col-form-label
                        form-control-label
                      "
                    >
                      Faturas
                    </label>
                    <div class="col-md-12">
                      <base-input input-classes="form-control-sm">
                        <el-select
                          @change="calcInvoicedAmount()"
                          v-model="payload.invoices"
                          size="mini"
                          placeholder="Selecione"
                          filterable
                          multiple
                        >
                          <el-option
                            v-for="invoice in invoices"
                            :key="invoice.id"
                            :label="`Fatura ${
                              invoice.id
                            } (${invoice.pending_to_paid.toLocaleString(
                              'pt-br',
                              { style: 'currency', currency: 'BRL' }
                            )} /
                          ${invoice.total.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })})`"
                            :value="invoice.id"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-2 limited-row">
                    <label
                      class="
                        col-md-6
                        pt-2
                        pb-0
                        col-form-label
                        form-control-label
                      "
                    >
                      Valor faturado
                    </label>
                    <div class="col-md-6">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          disabled
                          v-money="money"
                          maxlength="13"
                          inputmode="numeric"
                          v-model="invoiced_amount"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <template slot="prepend">
                          <small class="input-group-sm p-0 m-0"> R$ </small>
                        </template>
                      </base-input>
                    </div>
                  </div>
<!--                  <div class="form-group row m-0 p-0 mb-1 limited-row">-->
<!--                    <label-->
<!--                      class="-->
<!--                        col-md-6-->
<!--                        pt-2-->
<!--                        pb-0-->
<!--                        col-form-label-->
<!--                        form-control-label-->
<!--                      "-->
<!--                    >-->
<!--                      Saldo-->
<!--                    </label>-->
<!--                    <div class="col-md-6">-->
<!--                      <base-input input-group-classes="input-group-sm">-->
<!--                        <input-->
<!--                          disabled-->
<!--                          v-money="money"-->
<!--                          maxlength="13"-->
<!--                          inputmode="numeric"-->
<!--                          v-model.lazy="balance"-->
<!--                          type="text"-->
<!--                          class="form-control form-control-sm"-->
<!--                        />-->
<!--                        <template slot="prepend">-->
<!--                          <small class="input-group-sm p-0 m-0"> R$ </small>-->
<!--                        </template>-->
<!--                      </base-input>-->
<!--                    </div>-->
<!--                  </div>-->
                </section>
                <!--                PARCELAS-->
                <section v-show="type == 3">
                  <!-- Start Payments -->
                  <div v-if="payload.installments.length">
                    <div class="border-header-danger-bottom mb-2 mt-3">
                      <h5 class="h3 p-1 mb-0">Pagamentos</h5>
                    </div>
                    <!-- Start Orders-->
                    <div
                      v-for="(
                        installment, indexInstallment
                      ) in payload.installments"
                      :key="installment.index"
                    >
                      <div
                        class="form-group row m-0 mb-1 limited-row"
                        :class="installment.index > 1 ? 'mt-4' : ''"
                      >
                        <label
                          class="
                            col-md-6
                            pt-2
                            pb-0
                            pt-1
                            col-form-label
                            form-control-label
                            font-weight-bold
                          "
                        >
                          <h5 class="h4 mb-1">
                            Parcela {{ installment.index }}
                            <span class="text-danger">&nbsp;*</span>
                          </h5>
                        </label>
                        <div class="col-md-6">
                          <!--                          <base-dropdown class="col-md-12 p-0 m-0">-->
                          <base-button
                            slot="title-container"
                            block
                            size="sm"
                            :type="getColor(installment.status)"
                            class="text-uppercase"
                          >
                            {{ getTitle(installment.status) }}
                          </base-button>
                          <!--                            <div>-->
                          <!--                              <a-->
                          <!--                                @click.prevent="installment.status = 1"-->
                          <!--                                v-if="installment.status !== 1"-->
                          <!--                                class="dropdown-item"-->
                          <!--                              >Pago</a-->
                          <!--                              >-->
                          <!--                              <div-->
                          <!--                                v-if="installment.status !== 1"-->
                          <!--                                class="dropdown-divider p-0 m-0"-->
                          <!--                              ></div>-->
                          <!--                              <a-->
                          <!--                                @click.prevent="installment.status = 3"-->
                          <!--                                v-if="installment.status !== 3"-->
                          <!--                                class="dropdown-item"-->
                          <!--                              >Cancelado</a-->
                          <!--                              >-->
                          <!--                              <div-->
                          <!--                                v-if="installment.status !== 3"-->
                          <!--                                class="dropdown-divider p-0 m-0"-->
                          <!--                              ></div>-->
                          <!--                              <a-->
                          <!--                                @click.prevent="installment.status = 0"-->
                          <!--                                v-if="installment.status !== 0"-->
                          <!--                                class="dropdown-item"-->
                          <!--                              >Pendente</a-->
                          <!--                              >-->
                          <!--                            </div>-->
                          <!--                          </base-dropdown>-->
                        </div>
                      </div>
                      <div class="form-group row m-0 mb-1 limited-row">
                        <label
                          class="
                            col-md-6
                            pt-2
                            pb-0
                            col-form-label
                            form-control-label
                          "
                        >
                          Vencimento
                          <span class="text-danger">&nbsp;*</span>
                        </label>
                        <div class="col-md-6">
                          <validation-provider rules="required">
                            <base-input input-group-classes="input-group-sm">
                              <input-date-time-picker
                                v-model="installment.due_date"
                                :mode="'date'"
                              />
                            </base-input>
                          </validation-provider>
                        </div>
                      </div>
                      <!--                      <div class="form-group row m-0 mb-1 limited-row">-->
                      <!--                        <label-->
                      <!--                          class="-->
                      <!--                            col-md-6-->
                      <!--                            pt-2-->
                      <!--                            pb-0-->
                      <!--                            col-form-label-->
                      <!--                            form-control-label-->
                      <!--                          "-->
                      <!--                        >-->
                      <!--                          Vencimento original-->
                      <!--                          <span class="text-danger">&nbsp;*</span>-->
                      <!--                        </label>-->
                      <!--                        <div class="col-md-6">-->
                      <!--                          <validation-provider rules="required">-->
                      <!--                            <base-input input-group-classes="input-group-sm">-->
                      <!--                              <input-date-time-picker-->
                      <!--                                v-model="installment.original_due_date"-->
                      <!--                                :mode="'date'"-->
                      <!--                              />-->
                      <!--                            </base-input>-->
                      <!--                          </validation-provider>-->
                      <!--                        </div>-->
                      <!--                      </div>-->
                      <div class="form-group row m-0 p-0 mb-1 limited-row">
                        <label
                          class="
                            col-md-6
                            pt-2
                            pb-0
                            col-form-label
                            form-control-label
                          "
                        >
                          Valor
                          <span class="text-danger">&nbsp;*</span>
                        </label>
                        <div class="col-md-6">
                          <base-input input-group-classes="input-group-sm">
                            <input
                              @blur="
                                calcTotalValue(installment, indexInstallment)
                              "
                              v-money="money"
                              @keydown="
                                $event.key === '-'
                                  ? $event.preventDefault()
                                  : null
                              "
                              maxlength="13"
                              inputmode="numeric"
                              v-model.lazy="installment.value"
                              type="text"
                              class="form-control form-control-sm"
                            />
                            <template slot="prepend">
                              <small class="input-group-sm p-0 m-0"> R$ </small>
                            </template>
                          </base-input>
                        </div>
                      </div>
                      <div class="form-group row m-0 p-0 mb-1 limited-row">
                        <label
                          class="
                            col-md-6
                            pt-2
                            pb-0
                            col-form-label
                            form-control-label
                          "
                        >
                          Descontos
                        </label>
                        <div class="col-md-6">
                          <base-input input-group-classes="input-group-sm">
                            <input
                              @keydown="
                                $event.key === '-'
                                  ? $event.preventDefault()
                                  : null
                              "
                              @blur="
                                calcTotalValue(installment, indexInstallment)
                              "
                              v-money="money"
                              maxlength="13"
                              inputmode="numeric"
                              v-model="installment.discounts"
                              class="form-control form-control-sm"
                            />
                            <template slot="prepend">
                              <small class="input-group-sm p-0 m-0"> R$ </small>
                            </template>
                          </base-input>
                        </div>
                      </div>
                      <div class="form-group row m-0 p-0 mb-1 limited-row">
                        <label
                          class="
                            col-md-6
                            pt-2
                            pb-0
                            col-form-label
                            form-control-label
                          "
                        >
                          Taxas
                        </label>
                        <div class="col-md-6">
                          <base-input input-group-classes="input-group-sm">
                            <input
                              @keydown="
                                $event.key === '-'
                                  ? $event.preventDefault()
                                  : null
                              "
                              @blur="
                                calcTotalValue(installment, indexInstallment)
                              "
                              v-money="money"
                              maxlength="13"
                              inputmode="numeric"
                              v-model="installment.discounts_fees"
                              class="form-control form-control-sm"
                            />
                            <template slot="prepend">
                              <small class="input-group-sm p-0 m-0"> R$ </small>
                            </template>
                          </base-input>
                        </div>
                      </div>
                      <div class="form-group row m-0 p-0 mb-1 limited-row">
                        <label
                          class="
                            col-md-6
                            pt-2
                            pb-0
                            col-form-label
                            form-control-label
                          "
                        >
                          Juros/Multa
                        </label>
                        <div class="col-md-6">
                          <base-input input-group-classes="input-group-sm">
                            <input
                              @keydown="
                                $event.key === '-'
                                  ? $event.preventDefault()
                                  : null
                              "
                              ref="feesInput"
                              @blur="
                                calcTotalValue(installment, indexInstallment)
                              "
                              v-money="money"
                              maxlength="13"
                              inputmode="numeric"
                              v-model="installment.fees_fines"
                              class="form-control form-control-sm"
                            />
                            <template slot="prepend">
                              <small class="input-group-sm p-0 m-0"> R$ </small>
                            </template>
                          </base-input>
                        </div>
                      </div>
                      <div class="form-group row m-0 p-0 mb-1 limited-row">
                        <label
                          class="
                            col-md-6
                            pt-2
                            pb-0
                            col-form-label
                            form-control-label
                          "
                        >
                          Valor final
                          <span class="text-danger">&nbsp;*</span>
                        </label>
                        <div class="col-md-6">
                          <base-input input-group-classes="input-group-sm">
                            <input
                              @keydown="
                                $event.key === '-'
                                  ? $event.preventDefault()
                                  : null
                              "
                              disabled
                              v-money="money"
                              maxlength="13"
                              inputmode="numeric"
                              v-model.lazy="installment.total_value"
                              type="text"
                              class="form-control form-control-sm"
                            />
                            <template slot="prepend">
                              <small class="input-group-sm p-0 m-0"> R$ </small>
                            </template>
                          </base-input>
                        </div>
                      </div>
                      <!--                      &lt;!&ndash;                    ANEXO&ndash;&gt;-->
                      <!--                      <div class="form-group row m-0 p-0 mb-4">-->
                      <!--                        <div class="col-md-12 mt-2">-->
                      <!--                          <InputFile :ref="`input-file-${indexInstallment}`"/>-->
                      <!--                        </div>-->
                      <!--                      </div>-->
                    </div>
                    <!-- End Orders-->
                  </div>
                  <!-- End Payments -->
                </section>
                <section v-show="type === 4">
                  <div class="form-group row m-0 p-0 mb-4">
                    <div class="col-md-12 mt-2">
                      <label class="form-control-label">Anexos</label>
                      <InputFile :maxFiles="10" ref="files"/>
                    </div>
                  </div>
                </section>
              </div>
              <div class="modal-footer">
                <base-button type="secondary" @click="closeModal('create')">
                  Cancelar
                </base-button>
                <base-button
                  v-if="type === 4"
                  type="success"
                  native-type="submit"
                  :disabled="invalid || !payload.installments.length || !payload.invoices.length"
                  :loading="loadingStore"
                >
                  <span v-show="!loadingStore" class="btn-label"
                  ><i class="fas fa-save"></i
                  ></span>
                  Salvar
                </base-button>
                <base-button
                  v-else
                  @click.prevent="type += 1"
                  type="success"
                  native-type="submit"
                  v-bind:disabled="invalid || (type + 1 === 3 && !payload.installments.length)"
                  class="btn-custom">
                  <span class="btn-label span-custom"><i class="fa-solid fa-circle-right"></i></span>
                  Próximo
                </base-button>
              </div>
            </form>
          </validation-observer>
        </div>
      </div>
    </modal>
    <ModalSearchContract
      @close="closeModal"
      @setContractProposal="contractSelected"
      ref="modalSearchContract"
    />
    <ModalCardIntegration ref="ModalCardIntegration" @selectedTransactionProps="setTransactionProps"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import SpinnerPuzl from "@/components/SpinnerPuzl";
import PuzlFile from "@/components/PuzlFile";
import ModalSearchContract from "@/views/Modules/Operational/Schedule/Shared/_ModalSearchContract";
import {VMoney} from "v-money";
import {checkNumberValue} from "@/helpers";
import {formatErrorValidation, mask1} from "@/plugins";
import moment from "moment";
import InputFile from "@/components/Utils/InputFile";
import PuzlSelect from "@/components/PuzlSelect";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import ModalCardIntegration from "./_ModalCardIntegration";

export default {
  name: "ModalCreateBillReceive",
  components: {
    InputFile,
    PuzlFile,
    SpinnerPuzl,
    PuzlSelect,
    ModalSearchContract,
    InputDateTimePicker,
    ModalCardIntegration,
  },
  data() {
    return {
      loadingBankAccounts: false,
      loadingCompanyPlantIssuers: false,
      loadingPaymentIntermediaries: false,
      loadingPaymentIntermediaryMerchants: false,
      loadingCompanyPlants: false,
      modal: {
        title: "Recebimentos",
        create: false,
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      bank_accounts: [],
      require_nsu_code: false,
      type: 1,
      loadingSpinner: false,
      payment_methods_selected: [],
      payment_terms_selected: [],
      payload: {
        bill_receive: {
          type: null,
          company_plant_issuer_id: null,
          company_plant_id: null,
          competence: null,
          name: null,
          total_value: null,
          observations: null,
          final_value: null,
          payment_term_uuid: null,
          payment_method_uuid: null,
          bank_account_id: null,
          payment_intermediary_id: null,
          payment_intermediary_merchant_id: null,
          iss_retain: null,
        },
        installments: [],
        invoices: [],
      },
      payment_intermediaries_exists: false,
      payment_intemerdiaries_selected: [],
      payment_intermediary_merchants: [],
      hasInstallmentPaid: false,
      contract_proposal: {},
      loadingStore: false,
      loadingPlant: false,
      loadingMethod: true,
      loading: true,
      file: null,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      invoiced_amount: null,
      balance: null,
      modalCardIntegrations: false,
    };
  },
  directives: {
    money: VMoney,
  },
  watch: {},
  computed: {
    ...mapGetters({
      plants: "plant/activeItems",
      payment_methods: "paymentMethod/fetch",
      payment_terms: "paymentTerm/fetch",
      entities: "entity/fetch",
      invoices: "billingInvoice/invoices",
      $_company_plants: "plant/activeItems",
      company_plant_issuers: "companyPlantIssuer/fetch",
      $_payment_intermediaries: "paymentIntermediary/fetch",
    }),
  },
  methods: {
    handleCreateModalCardIntegration() {
      this.$refs.ModalCardIntegration.handleCreateModal(
        this.payload.bill_receive.payment_intermediary_id,
        this.payload.bill_receive.payment_intermediary_merchant_id,
        this.payload.bill_receive.competence,
      )
    },
    /**
     * @param {object} selectedTransactionProps
     * @param {integer} selectedTransactionProps.payment_intermediary_id
     * @param {integer} selectedTransactionProps.paymentIntermediaryMerchantId
     * @param {string} selectedTransactionProps.nsu_code
     */
    setTransactionProps(selectedTransactionProps) {
      this.payload.bill_receive.payment_intermediary_id = selectedTransactionProps.paymentIntermediaryId;
      this.payload.bill_receive.payment_intermediary_merchant_id = selectedTransactionProps.paymentIntermediaryMerchantId;
      this.payload.bill_receive.nsu_code = selectedTransactionProps.nsu_code;
      this.payload.bill_receive.competence = selectedTransactionProps.competence;
    },
    /**
     * Exibir campos de ponto de venda de acordo com ID do Mediador de Pagamento
     *
     * @param {integer} paymentIntermediaryId Id de mediador de pagamento
     * @returns {boolean} Exibir/Não exibir pontos de venda
     */
    isDisplayPaymentIntermediaryMerchants(paymentIntermediaryId) {
      const paymentIntermediaryIdFound = this.payment_intemerdiaries_selected.find((item) => item.id === paymentIntermediaryId);
      return paymentIntermediaryIdFound
        && paymentIntermediaryIdFound.has_credentials
        && (this.payment_intermediary_merchants.length > 0);
    },
    getPaymentIntermediaryMerchants() {
      this.payload.bill_receive.payment_intermediary_merchant_id = null
      this.loadingPaymentIntermediaryMerchants = true
      const params = {
        filter: {
          payment_intermediary_id: this.payload.bill_receive.payment_intermediary_id,
          status: true,
        }
      };
      this.$store
        .dispatch("paymentIntermediaryMerchant/fetchItems", params)
        .then(response => {
          this.payment_intermediary_merchants = response.data;
          if (this.payment_intermediary_merchants.length === 1) {
            this.payload.bill_receive.payment_intermediary_merchant_id = this.payment_intermediary_merchants[0].id;
          }
          this.loadingPaymentIntermediaryMerchants = false;
        });
    },
    closeModal() {
      this.modal.create = false;
    },
    getBankAccounts() {
      this.payload.bill_receive.bank_account_id = null;
      this.loadingBankAccounts = true;
      this.$store
        .dispatch(
          "companyPlantBankAccount/getByCompanyPlant", {
            allocated: 1,
            company_plant_id: this.payload.bill_receive.company_plant_id
          })
        .then((response) => {
          this.loadingBankAccounts = false;
          this.bank_accounts = response.data;
          const company_plant = this.$_company_plants.find(
            (item) => item.id === this.payload.bill_receive.company_plant_id
          );
          if (response.data.some(item => item.id === company_plant.bank_account_id)) {
            this.payload.bill_receive.bank_account_id = company_plant.bank_account_id;
          }
        });
    },
    changeBankAccount() {
      this.getBankAccounts();
    },
    /** validações por método de pagamento */
    setRequireNsuCode() {
      this.modalCardIntegrations = true;
      this.payload.bill_receive.payment_intermediary_id = null;
      this.payment_intemerdiaries_selected = [];
      let payment_method = this.payment_methods.find(
        (item) => item.uuid === this.payload.bill_receive.payment_method_uuid
      );
      if (!payment_method) {
        this.payment_intermediaries_exists = false;
        return (this.require_nsu_code = false);
      }
      this.payment_intermediaries_exists =
        payment_method.payment_intermediaries_exists;
      // if (payment_method.payment_intermediaries_exists) {
      //   this.payload.installments = [];
      //   if (payment_method.payment_intermediaries.length === 1) {
      //     this.payload.bill_receive.payment_intermediary_id =
      //       payment_method.payment_intermediaries[0];
      //   }
      //   this.payment_intemerdiaries_selected =
      //     this.$_payment_intermediaries.filter(function (item) {
      //       if (payment_method.payment_intermediaries.includes(item.id) && item.status) {
      //         return item;
      //       }
      //     });
      // }
      return (this.require_nsu_code = Boolean(
        payment_method.transaction_code
      ));
    },
    setInvoice(set_plant_issuers = false) {
      if (set_plant_issuers) {
        this.payload.bill_receive.company_plant_issuer_id = null;
        this.loadingCompanyPlantIssuers = true;
        this.$store
          .dispatch("companyPlantIssuer/fetchItems", {
            allocated: true,
            company_plant_id: this.payload.bill_receive.company_plant_id,
          })
          .then((response) => {
            const plant = this.plants.find(
              (item) => item.id === this.payload.bill_receive.company_plant_id
            );
            const default_plant_issuer = plant.company_issuers.find(
              (item) => item.default === 1
            );
            if (default_plant_issuer) {
              this.payload.bill_receive.company_plant_issuer_id =
                default_plant_issuer.id;
              this.setInvoice();
            }
            this.loadingCompanyPlantIssuers = false;
          })
          .catch(() => {
            this.loadingCompanyPlantIssuers = false;
          });
      }
      if (
        this.payload.bill_receive.company_plant_issuer_id === null ||
        this.payload.bill_receive.company_plant_id === null
      ) {
        return;
      }
      let loader = this.$loading.show();
      this.payload.invoices = [];
      this.$store
        .dispatch("billingInvoice/init", {
          filter: {
            // with_iss_retained_flag: true,
            pending_payment: 1,
            contract_proposal_id: this.contract_proposal.id,
            not_canceled: true,
          },
        })
        .then((response) => {
          loader.hide();
        });
    },
    calcTotalValueToReceive() {
      const sum = this.payload.installments.reduce(function (new_value, obj) {
        new_value += parseFloat(obj.value.includes(",") ? obj.value.replaceAll(".", "").replace(",", ".") : obj.value);
        return new_value;
      }, 0);
      return this.payload.bill_receive.total_value = Number(sum).toFixed(2)
    },
    calcFinalValue() {
      let total = 0;
      let total_value = 0;
      let final_value = 0
      for (let i in this.payload.installments) {
        const day = Number(moment(this.payload.installments[i].due_date).day());
        if ([0, 6].includes(day)) {
          if (day === 0) {
            const due_date = moment(this.payload.installments[i].due_date)
              .add(1, "days")
              .format("YYYY/MM/DD");
            this.payload.installments[i].due_date = due_date;
            this.payload.installments[i].original_due_date = due_date;
          }
          if (day === 6) {
            const due_date = moment(this.payload.installments[i].due_date)
              .add(2, "days")
              .format("YYYY/MM/DD");
            this.payload.installments[i].due_date = due_date;
            this.payload.installments[i].original_due_date = due_date;
          }
        }
        total += Number(this.$helper.strToNum(this.payload.installments[i].value))
        final_value += Number(this.$helper.strToNum(this.payload.installments[i].total_value))
      }
      total_value = this.payload.bill_receive.total_value
        .replace(".", "")
        .replace(",", ".");
      if (this.payload.installments.length) {
        if (Number(total_value).toFixed(2) > Number(total).toFixed(2)) {
          const diff = (Number(total_value) - total).toFixed(2);
          this.payload.installments[0].value = Number(
            Number(this.$helper.strToNum(this.payload.installments[0].value)) + Number(diff)
          ).toFixed(2);
        }
        if (Number(total_value).toFixed(2) < Number(total).toFixed(2)) {
          const diff = Number(total).toFixed(2) - Number(total_value).toFixed(2)
          this.payload.installments[0].value = Number(
            Number(this.$helper.strToNum(this.payload.installments[0].value)) - Number(diff)
          ).toFixed(2);
        }
      }
      this.payload.bill_receive.final_value = Number(final_value).toFixed(2);
    },
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        this.$forceUpdate();
        return checkNumberValue(
          mask1(value.toString(), decimals, ".", true),
          min,
          max
        );
      }
      return checkNumberValue(value, min, max);
    },
    getColor(status) {
      switch (status) {
        case 0:
          return "warning";
        case 1:
          return "success";
        case 2:
        case 3:
          return "danger";
      }
    },
    getTitle(status) {
      switch (status) {
        case 0:
          return "Pendente";
        case 1:
          return "Pago";
        case 2:
          return "Atrasado";
        case 3:
          return "Cancelado";
      }
    },
    formatAccountLabel(item) {
      return item.name;
    },
    handleCreateModal() {
      this.payment_intermediaries_exists = false;
      this.loadingCompanyPlants = true;
      this.loadingPaymentIntermediaries = true;
      this.modalCardIntegrations = false;
      Promise.all([
        this.$store.dispatch("paymentTerm/fetchItems"),
        this.$store.dispatch("paymentIntermediary/fetchItems"),
        this.$store.dispatch("plant/fetchItemsActive"),
        this.$store.dispatch("paymentMethod/fetchItems"),
      ]).then(() => {
        this.initContractProposal()
        this.loadingBankAccounts = false;
        this.loadingPaymentIntermediaries = false;
        this.loadingCompanyPlants = false;
        this.modal.create = true;
      });
      this.type = 1;
      this.contract_proposal = {};
      this.payload = {
        bill_receive: {
          competence: null,
          name: null,
          type: null,
          total_value: null,
          observations: null,
          final_value: null,
          payment_term_uuid: null,
          company_plant_id: null,
          company_plant_issuer_id: null,
          payment_method_uuid: null,
          payment_intermediary_id: null,
          transaction_code: null,
          nsu_code: null,
        },
        installments: [],
        invoices: [],
      };
      this.balance = 0;
      this.invoiced_amount = 0;
    },
    finalFormat() {
      let payload = JSON.parse(JSON.stringify(this.payload));
      payload.bill_receive.total_value = payload.bill_receive.total_value
        .replaceAll(".", "")
        .replace(",", ".");
      payload.bill_receive.final_value = payload.bill_receive.final_value
        .replaceAll(".", "")
        .replace(",", ".");
      payload.bill_receive.iss_retain = 0;
      payload.installments.map(function (obj, key) {
        obj.value = obj.value.replaceAll(".", "").replace(",", ".");
        obj.total_value = obj.total_value.replaceAll(".", "").replace(",", ".");
      });
      if (payload.bill_receive.type === 0) {
        let company_plant_uuid = payload.bill_receive.company_plant_uuid;
        payload.bill_receive.company_plant_id = this.plants.filter(function (
          item
        ) {
          return item.uuid == company_plant_uuid;
        })[0].id;
        delete payload.bill_receive.company_plant_uuid;
        let payment_term_uuid = payload.bill_receive.payment_term_uuid;
        payload.bill_receive.payment_term_id = this.payment_terms.filter(
          function (item) {
            return item.uuid == payment_term_uuid;
          }
        )[0].id;
        delete payload.bill_receive.payment_term_uuid;
        let payment_method_uuid = payload.bill_receive.payment_method_uuid;
        payload.bill_receive.payment_method_id = this.payment_methods.filter(
          function (item) {
            return item.uuid == payment_method_uuid;
          }
        )[0].id;
        delete payload.bill_receive.payment_method_uuid;
      } else {
        payload.bill_receive.contract_proposal_uuid =
          this.contract_proposal.uuid;
      }
      if (payload.bill_receive.type === 1) {
        payload.bill_receive.balance = this.balance
          .toString()
          .replaceAll(".", "")
          .replace(",", ".");
      }
      return payload;
    },
    validInstallmentPaid() {
      let pending_before_paid = false;
      let has_pending = false;
      for (let item of this.payload.installments) {
        if (item.status != 1) {
          has_pending = true;
          continue;
        }
        if (item.status === 1 && has_pending) {
          pending_before_paid = true;
          break;
        }
      }
      return pending_before_paid;
    },
    /**
     * Validar pagamento de comerciante (Transações de cartão de crédito/Débito)
     * @returns boolean
     */
    async isValidPaymentIntermediaryMerchant() {
      // Campos obrigatórios para consulta
      const paymentIntermediaryMerchantIsFilled = this.payload.bill_receive.payment_intermediary_id
        && this.payload.bill_receive.payment_intermediary_merchant_id
        && this.payload.bill_receive.nsu_code;
      if (!paymentIntermediaryMerchantIsFilled) {
        this.$notify({
          type: "danger",
          message: `Quando NSU da transação é informado, é necessário preencher "Mediador de Pagamento" e "Ponto de Venda (PV)".`
        });
        return false;
      }

      // Verificar existência da transação
      // const params = {
      //   id: this.payload.bill_receive.payment_intermediary_merchant_id,
      //   saleDate: this.$helper.parseDate(this.payload.bill_receive.competence, 'YYYY-MM-DD'),
      //   nsuCode: this.payload.bill_receive.nsu_code,
      // };
      // return await this.$store
      //   .dispatch("paymentIntermediaryMerchant/getSaleFromCardIntegrationByNsuCode", params)
      //   .then((response) => {
      //     if (response.data.length === 0){
      //       this.$notify({
      //         type: "danger",
      //         message: `NSU da transação "${params.nsuCode}" não foi encontrado em competência ${this.$helper.parseDate(params.saleDate, 'DD/MM/YYYY')}`,
      //       });
      //       return false
      //     }
      //     let isValid = true;
      //     const {credentialTypeName, amount, installmentQuantity, typeName, tid} = response.data;
      //     const payment_method = this.payment_methods.find((item) => item.uuid === this.payload.bill_receive.payment_method_uuid)
      //     const isCreditTypeName = payment_method && payment_method.id == 2;
      //     this.payload.bill_receive.transaction_code = tid;

      //     // Comparar valor com API de Mediador de Pagamento
      //     if (this.$helper.strToNum(this.payload.bill_receive.total_value) !== amount){
      //       isValid = false;
      //       this.$notify({
      //         type: "danger",
      //         message: `Valor Total (A Receber) cadastrado é diferente do encontrado em ${credentialTypeName}: R$ ${amount.toFixed(2)}.`
      //       });
      //     };

      //     // Comparar quantidade de parcelas com API de Mediador de Pagamento
      //     if (this.payload.installments.length !== installmentQuantity){
      //       isValid = false;
      //       this.$notify({
      //         type: "danger",
      //         message: `Quantidade de Parcelas cadastrada é diferente do encontrado em ${credentialTypeName}: ${installmentQuantity} Parcelas.`
      //       });
      //     }

      //     // Compararar tipo de cartão (Crédito ou Débito)
      //     if (isCreditTypeName && (typeName.toUpperCase() !== 'CREDIT')){
      //       isValid = false;
      //       this.$notify({
      //         type: "danger",
      //         message: `Tipo de Cartão [Crédito/Débito] não confere com o encontrado em ${credentialTypeName}: ${typeName.toUpperCase()}O.`
      //       });
      //     }

      //     return isValid;
      //   }).catch(error => {
      //     const technicalMessage = error.status ? error.data.message : formatErrorValidation(error.response.data.errors);
      //     this.$notify({type: "danger", message: `Transação: ${params.nsuCode} não encontrada.`});
      //     this.$notify({type: "danger", message: `Mensagem técnica: ${technicalMessage}.`});
      //     return false;
      //   });
      return true;
    },
    async store() {
      let total_value = Number(this.payload.bill_receive.total_value.replaceAll(".", "").replace(",", "."))
      let invoice_amount = Number(this.invoiced_amount.replaceAll(".", "").replace(",", "."))
      if (total_value > invoice_amount) {
         return this.$notify({
            type: "danger",
            message: 'O valor total do recebimento não pode ser maior que o valor faturado'
          });
      }
      const has_final_less = this.payload.installments.some((item) => Number(item.total_value.replaceAll(".", "").replace(",", ".")) < 0)
      const has_total_less = this.payload.installments.some((item) => Number(item.value.replaceAll(".", "").replace(",", ".")) <= 0)
      if (has_total_less) {
        this.$notify({
          type: "danger",
          message: 'O valor total da parcela não pode ser igual ou menor a R$ 0,00.'
        });
      }
      if (has_final_less) {
        this.$notify({
          type: "danger",
          message: 'O valor total da parcela não pode ser menor a R$ 0,00.'
        });
      }
      if (has_final_less || has_total_less) {
        return;
      }
      // Verificar NSU da transação quando obrigatório
      this.loadingStore = true;
      const payment_intermediary = this.payment_intemerdiaries_selected.find((item) => item.id === this.payload.bill_receive.payment_intermediary_id);
      // const hasCredentials = payment_intermediary && payment_intermediary.has_credentials;
      // const payment_method = this.payment_methods.find((item) => item.uuid === this.payload.bill_receive.payment_method_uuid)
      // const nsuCodeIsRequired = payment_method && [2,3].includes(payment_method.id) && hasCredentials;
      // if (nsuCodeIsRequired){
      //   if (!this.payload.bill_receive.nsu_code) {
      //     this.$notify({type: "danger", message: `NSU da transação é obrigatório.`});
      //     this.loadingStore = false;
      //     return;
      //   }
      //   if (!await this.isValidPaymentIntermediaryMerchant()) {
      //     this.loadingStore = false;
      //     return;
      //   }
      // }
      const has = this.validHasSufficientBalance();
      if (!has) {
        return this.$notify({
          type: "danger",
          message: "Não há saldo suficiente para pagamento das faturas.",
        });
      }
      const correctInstallmentPaid = this.validInstallmentPaid();
      if (correctInstallmentPaid) {
        return this.$notify({
          type: "danger",
          message:
            "Para pagamento das demais parcelas, a parcela anterior deve estar paga.",
        });
      }
      let formData = new FormData();
      this.payload.bill_receive.competence = new moment(
        this.payload.bill_receive.competence
      ).format("YYYY-MM-DD");
      for (let indexFile in this.$refs.files.fileRecords) {
        formData.append(
          indexFile,
          this.$refs.files.fileRecords[indexFile]["file"]
        );
      }
      // this.payload.installments.map(function (item, key) {
      //   for (let indexFile in this.$refs[`input-file-${key}`][0].fileRecords) {
      //     const index_name = key + "-" + indexFile;
      //     formData.append(
      //       index_name,
      //       this.$refs[`input-file-${key}`][0].fileRecords[indexFile]["file"]
      //     );
      //   }
      // }, this);
      this.$Progress.start();
      this.loadingStore = true;
      const payload = this.finalFormat();
      for (let index in payload) {
        formData.append(index, JSON.stringify(payload[index]));
      }
      this.$store
        .dispatch("billReceive/add", formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.loadingStore = false;
          this.closeModal();
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.$emit("added");
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          } else {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message,
            });
          }
          this.$Progress.finish();
          this.loadingStore = false;
        });
    },
    getPaymentMethods() {
      this.$store.dispatch("paymentMethod/fetchItems").then(() => {
        this.loadingMethod = false;
      });
    },
    generateInstallments(recalc = 0) {
      if (recalc === 1) {
        this.payload.bill_receive.final_value =
          this.payload.bill_receive.total_value;
      }
      if (
        this.payload.bill_receive.payment_term_uuid &&
        this.payload.bill_receive.payment_method_uuid
      ) {
        let self = this;
        let uuid = this.payload.bill_receive.payment_term_uuid;
        let payment_term = this.payment_terms.filter(
          (item) => item.uuid === uuid
        )[0];

        let payment_method = this.payment_methods.find(
          (item) => item.uuid === self.payload.bill_receive.payment_method_uuid
        );
        /*
         * Buscamos as parcelas da condição de pagamento
         */
        let installments = payment_term.installments;
        let payload_installments = [];
        /*
         * Percorremos as parcelas de condição de pagamento
         * e montamos as nossas para pagamento de proposta contrato.
         */
        // valor total
        const total = Number(
          self.payload.bill_receive.total_value
            .replaceAll(".", "")
            .replace(",", ".")
        );
        // valor retido
        const iss_retain = 0;
        const total_value = total - iss_retain;
        const payment_intermediary = this.$_payment_intermediaries.find(
          (item) =>
            item.id === this.payload.bill_receive.payment_intermediary_id
        );
        if (payment_intermediary) {
          let total_percentage_tax = 0;
          // SE FOR CRÉDITO PARCELADO ACUMULADO
          /** checked */
          if (
            payment_intermediary.credit_in_installments.receipt_method === 0 &&
            payment_method.payment_type === 1 &&
            payment_term.total_installment > 1
          ) {
            // Object.values(payment_intermediary.additional_installments).map(function (obj) {
            //   if (obj.installment <= payment_term.total_installment) {
            //     total_percentage_tax += Number(obj.tax_percentage.toString().replace(',', '.'))
            //   }
            // })
            const deadline =
              payment_intermediary.credit_in_installments.deadline;
            total_percentage_tax = Object.values(
              payment_intermediary.additional_installments
            ).find(
              (item) => item.installment === payment_term.total_installment
            ).tax_percentage;
            total_percentage_tax = Number(
              total_percentage_tax.toString().replace(",", ".")
            );
            let date = moment(self.payload.bill_receive.competence)
              .add("days", deadline)
              .format("YYYY-MM-DD");
            let discounts = parseFloat(total_percentage_tax).toFixed(2);
            let tax_percentage_intermediary = parseFloat(
              payment_intermediary.credit_in_installments.tax_percentage
                .toString()
                .replace(",", ".")
            );
            discounts = parseFloat(discounts) + tax_percentage_intermediary;
            discounts = (discounts / 100) * total_value.toFixed(2);
            discounts += Number(
              payment_intermediary.credit_in_installments.tax_monetary
                .toString()
                .replace(",", ".")
            );
            let installment = {
              value: total_value.toFixed(2),
              index: 1,
              total_value: (total_value - discounts).toFixed(2),
              due_date: date,
              original_due_date: date,
              status: 0,
              paid_at: null,
              fees_fines: null,
              discounts: null,
              discounts_fees: discounts.toFixed(2),
            };
            this.payload.installments = [installment];
            return this.calcFinalValue();
          }
          // SE FOR CRÉDITO PARCELADO COM RECEBIMENTO PARCELADO
          /** checked */
          if (
            payment_intermediary.credit_in_installments.receipt_method === 1 &&
            payment_method.payment_type === 1 &&
            payment_term.total_installment > 1
          ) {
            /** regra antiga que acumulava todas as taxas por parcela */
            // Object.values(payment_intermediary.additional_installments).map(function (obj) {
            //   if (obj.installment <= payment_term.total_installment) {
            //     total_percentage_tax += Number(obj.tax_percentage.toString().replace(',', '.'))
            //   }
            // })
            /** regra atual que pega a taxa fixa na parcela referencia */
            total_percentage_tax = Object.values(
              payment_intermediary.additional_installments
            ).find(
              (item) => item.installment === payment_term.total_installment
            ).tax_percentage;
            total_percentage_tax = Number(
              total_percentage_tax.toString().replace(",", ".")
            );

            let discounts = parseFloat(total_percentage_tax).toFixed(2);
            let tax_percentage_intermediary = parseFloat(
              payment_intermediary.credit_in_installments.tax_percentage
                .toString()
                .replace(",", ".")
            );
            discounts = parseFloat(discounts) + tax_percentage_intermediary;
            discounts = (discounts / 100) * total_value.toFixed(2);
            discounts += Number(
              payment_intermediary.credit_in_installments.tax_monetary
                .toString()
                .replace(",", ".")
            );

            installments.map(function (obj, key) {
              let date = moment(self.payload.bill_receive.competence)
                .add("days", obj.deadline)
                .format("YYYY-MM-DD");

              let percentage = parseFloat(obj.percentage);
              let value = (percentage / 100) * total_value;

              let installment = {
                value: value.toFixed(2),
                index: obj.index,
                total_value: (
                  value -
                  discounts / payment_term.total_installment
                ).toFixed(2),
                due_date: date,
                original_due_date: date,
                status: 0,
                paid_at: null,
                fees_fines: null,
                discounts: null,
                discounts_fees: (
                  discounts / payment_term.total_installment
                ).toFixed(2),
              };
              payload_installments.push(installment);
            });
            this.payload.installments = payload_installments;
            return this.calcFinalValue();
          }
          // SE FOR CRÉDITO A VISTA
          if (
            payment_method.payment_type === 1 &&
            payment_term.total_installment === 1
          ) {
            const deadline = payment_intermediary.credit_on_sight.deadline;
            total_percentage_tax = Number(
              payment_intermediary.credit_on_sight.tax_percentage
                .toString()
                .replace(",", ".")
            );
            let date = moment(self.payload.bill_receive.competence)
              .add("days", deadline)
              .format("YYYY-MM-DD");

            let discounts = parseFloat(total_percentage_tax).toFixed(2);
            discounts = (discounts / 100) * total_value.toFixed(2);
            discounts += Number(
              payment_intermediary.credit_on_sight.tax_monetary
                .toString()
                .replace(",", ".")
            );
            let installment = {
              value: total_value.toFixed(2),
              index: 1,
              total_value: (total_value - discounts).toFixed(2),
              due_date: date,
              original_due_date: date,
              status: 0,
              paid_at: null,
              fees_fines: null,
              discounts: null,
              discounts_fees: discounts.toFixed(2),
            };
            this.payload.installments = [installment];
            return this.calcFinalValue();
          }
          // SE FOR DÉBITO A VISTA
          if (
            payment_method.payment_type === 0 &&
            payment_term.total_installment === 1
          ) {
            const deadline = payment_intermediary.debit.deadline;
            total_percentage_tax = Number(
              payment_intermediary.debit.tax_percentage
                .toString()
                .replace(",", ".")
            );
            let date = moment(self.payload.bill_receive.competence)
              .add("days", parseInt(deadline) + installments[0].deadline)
              .format("YYYY-MM-DD");

            let discounts = parseFloat(total_percentage_tax).toFixed(2);
            discounts = (discounts / 100) * total_value.toFixed(2);
            discounts += Number(
              payment_intermediary.debit.tax_monetary
                .toString()
                .replace(",", ".")
            );
            let installment = {
              value: total_value.toFixed(2),
              index: 1,
              total_value: (total_value - discounts).toFixed(2),
              due_date: date,
              original_due_date: date,
              status: 0,
              paid_at: null,
              fees_fines: null,
              discounts: null,
              discounts_fees: discounts.toFixed(2),
            };
            this.payload.installments = [installment];
            return this.calcFinalValue();
          }
          // SE FOR DÉBITO PARCELADO
          if (
            payment_method.payment_type === 0 &&
            payment_term.total_installment > 1
          ) {
            total_percentage_tax = Number(
              payment_intermediary.debit.tax_percentage
                .toString()
                .replace(",", ".")
            );

            let discounts = parseFloat(total_percentage_tax).toFixed(2);
            discounts = (discounts / 100) * total_value.toFixed(2);
            discounts += Number(
              payment_intermediary.debit.tax_monetary
                .toString()
                .replace(",", ".")
            );
            installments.map(function (obj, key) {
              let date = moment(self.payload.bill_receive.competence)
                .add("days", obj.deadline)
                .format("YYYY-MM-DD");

              let percentage = parseFloat(obj.percentage);
              let value = (percentage / 100) * total_value;

              let installment = {
                value: value.toFixed(2),
                index: obj.index,
                total_value: (value - discounts).toFixed(2),
                due_date: date,
                original_due_date: date,
                status: 0,
                paid_at: null,
                fees_fines: null,
                discounts: null,
                discounts_fees: discounts.toFixed(2),
              };
              payload_installments.push(installment);
            });
            this.payload.installments = payload_installments;
            return this.calcFinalValue();
          }
        }

        installments.map(function (obj, key) {
          /*
           * Calculamos a data de vencimento a atribuir.
           */
          let date = moment(self.payload.bill_receive.competence)
            .add("days", obj.deadline)
            .format("YYYY-MM-DD");

          /*
           * Calculamos o valor da parcela.
           */
          let payment_total_value = total_value;
          let percentage = parseFloat(obj.percentage);
          let value = (percentage / 100) * payment_total_value;

          let installment = {
            value: value.toFixed(2),
            index: obj.index,
            total_value: value.toFixed(2),
            due_date: date,
            original_due_date: date,
            status: 0,
            paid_at: null,
            fees_fines: null,
            discounts: null,
            discounts_fees: null,
          };
          payload_installments.push(installment);
        });
        this.payload.installments = payload_installments;
      }
      // if (this.payment_intermediaries_exists) {
      //   this.payload.installments = [];
      // }
      this.$forceUpdate();
      this.calcFinalValue();
      this.calcInvoicedAmount();
    },
    async calcTotalValue(item, index) {
      const installment = {...item}
      let discounts = 0;
      let fees = 0;
      let current_total_value = 0;
      let current_value = 0;
      current_total_value = parseFloat(
        installment.value.replaceAll(".", "").replace(",", ".")
      );
      current_value = parseFloat(
        installment.value.replaceAll(".", "").replace(",", ".")
      );
      current_total_value -= Number(
        installment.discounts_fees.replaceAll(".", "").replace(",", ".")
      );
      current_total_value -= Number(
        installment.discounts.replaceAll(".", "").replace(",", ".")
      );
      current_total_value += Number(
        installment.fees_fines.replaceAll(".", "").replace(",", ".")
      );
      current_total_value += fees;
      this.payload.installments[index].total_value =
        current_total_value.toFixed(2);
      await this.calcTotalValueToReceive();
      this.calcFinalValue();
    },
    initEntity() {
      /* this.loadingSpinner = true;
      this.$store
        .dispatch("entity/fetchItems")
        .then(response => {
          this.loadingSpinner = false;
          this.payload.bill_receive.type = 0;
        })
        .catch(() => {
        }); */
    },
    initContractProposal() {
      this.$refs.modalSearchContract.openModal();
    },
    getPaymentTerms(set_installments = false) {
      this.payload.bill_receive.payment_term_uuid = null;
      const payment_methods_selected = [
        this.payload.bill_receive.payment_method_uuid,
      ];
      const self = this;
      this.payment_terms_selected = this.payment_terms.filter(function (item) {
        for (var payment_method of item.payment_methods) {
          if (
            payment_methods_selected.includes(payment_method.uuid) &&
            self.contract_proposal.payment_terms_uuids.includes(item.uuid)
          ) {
            return item;
          }
        }
      });
      if (this.contract_proposal.payment_terms_uuids.length === 1) {
        const payment_term_uuid = this.contract_proposal.payment_terms_uuids[0];
        const exists = this.payment_terms_selected.find(
          (item) => (item.uuid = payment_term_uuid)
        );
        if (exists) {
          this.payload.bill_receive.payment_term_uuid = payment_term_uuid;
        }
      }
      if (set_installments) {
        this.generateInstallments();
      }
      // if (this.contract_proposal.payment_terms_uuids.length === 1) {
      //   this.payload.bill_receive.payment_term_uuid = this.contract_proposal.payment_terms_uuids[0]
      // }
    },
    contractSelected(contract) {
      this.loadingSpinner = true;
      this.contract_proposal = contract;
      this.loadingSpinner = false;
      this.payload.bill_receive.type = 1;
      this.payload.bill_receive.company_plant_id =
        this.contract_proposal.plant.id;
      this.changeBankAccount();
      this.payload.bill_receive.company_plant_issuer_id = null;
      this.payload.bill_receive.payment_intermediary_id = null;
      this.setInvoice(true);
      let loader = this.$loading.show();
      Promise.all([
        this.$store.dispatch("paymentMethod/fetchItems"),
        this.$store.dispatch("paymentTerm/fetchItems"),
      ]).then(() => {
        loader.hide();
        this.payment_methods_selected = this.payment_methods.filter((item) =>
          this.contract_proposal.payment_methods_uuids.includes(item.uuid)
        );
        if (this.contract_proposal.payment_methods_uuids.length === 1) {
          this.payload.bill_receive.payment_method_uuid =
            this.contract_proposal.payment_methods_uuids[0];
        }
        this.setRequireNsuCode();
        this.getPaymentTerms();
      });
    },
    validHasSufficientBalance() {
      if (this.balance.toString().replaceAll(".", "").replace(",", ".") > 0) {
        return true;
      }
      let balance = 0;
      let total = this.payload.bill_receive.total_value
        .replaceAll(".", "")
        .replace(",", ".");
      total = parseFloat(total);
      balance += total;
      for (let id of this.payload.invoices) {
        if (balance <= 0) {
          return false;
        }
        const invoice = this.invoices.find((item) => item.id === id);
        const value = Number(invoice.pending_billing);
        if (balance > value) {
          balance -= value;
        } else {
          balance = 0;
        }
      }
      return true;
    },
    calcIss() {
      const self = this;
      let total_value = 0;
      this.payload.invoices.map(function (id) {
        let invoice = self.invoices.find(function (item) {
          return item.id === id;
        });
        total_value += invoice.iss_value.toFixed(2);
      });
      this.payload.bill_receive.iss_retain = total_value;
    },
    calcInvoicedAmount() {
      const self = this;
      let total_value = 0;
      this.payload.invoices.map(function (obj) {
        let invoice = self.invoices.find(function (item) {
          return item.id == obj;
        });
        total_value += Number(invoice.pending_billing);
      });
      this.invoiced_amount = total_value.toFixed(2);
      const total_value_amount = this.payload.bill_receive.total_value
        .replace(".", "")
        .replace(",", ".");
      this.balance = (
        Number(total_value_amount) - Number(this.invoiced_amount)
      ).toFixed(2);
      if (this.balance < 1) {
        this.balance = 0;
      }
    },
  },
  mounted() {
    this.$watch(
      "payload.installments",
      function () {
        const self = this;
        this.hasInstallmentPaid = false;
        self.payload.installments.forEach(function (item) {
          if (item.status === 1) {
            self.hasInstallmentPaid = true;
          }
        });
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
};
</script>

<style scoped>
label {
  font-size: 12px !important;
}

.bill-modal-create {
  overflow-y: scroll !important;
}

@media (min-width: 768px) {
  .limited-row {
    max-height: 28px !important;
  }
}
</style>
