<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-gray d-inline-block mb-0">{{ $route.name }}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4 text-gray">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i></a></li>
              <li class="breadcrumb-item"><a href="#">Tecnologia</a></li>
              <li class="breadcrumb-item" aria-current="page">{{ $route.name }}</li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- Header Menus -->
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-3 col-lg-2 col-xlg-2 ml-3 mr-3 mb-3 ml-0 p-0">
              <base-button class="pb-3 pt-3" block size="lg" type="success" @click="handleCreate()">
                <img src="/img/icons/plus-math--v1-white.png" width="20px" height="20px" class="imgBaseButton"> NOVO
              </base-button>
            </div>

            <div class="col-md-3 col-lg-2 col-xlg-2 ml-3 mr-3 mb-3 ml-0 p-0">
              <router-link :to="{ path: '/technology/mix/grid' }">
                <base-button block size="lg" type="back" class="p-3">
                  <img src="/img/icons/circled-left.png" width="20px" height="20px" class="imgBaseButton">
                  VOLTAR
                </base-button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </base-header>
    <list-concrete-pice></list-concrete-pice>
    <create-concrete-pice ref="createConcretePice"></create-concrete-pice>
  </div>
</template>
<script>
import ListConcretePice from './Shared/_List'
import CreateConcretePice from './Shared/_Create'
export default {
  components: {
    ListConcretePice,
    CreateConcretePice,
  },
  data() {
    return {
    }
  },
  methods: {
    handleCreate() {
      this.$refs.createConcretePice.handleCreateModal()
    },
  },
  created() {
  },
  mounted() {
  }
};
</script>
<style>
.imgBaseButton {
  margin-bottom: 2px;
  margin-left: -10px;
  margin-right: 5px;
}
</style>
