<template>
<div>
  <ListContractProposalFormulation />
</div>

</template>
<script>
import ListContractProposalFormulation from './Shared/_List.vue'

export default {
  name: 'IndexContractProposalFormulation',
  components: {
    ListContractProposalFormulation,
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
}
</script>
