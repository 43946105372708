<template>
  <div class="puzl-barcode-reader-external-component">
    <StreamBarcodeReader v-if="readBarCode === 'scanner'" @decode="onDecode" @error="onError" />
    <ImageBarcodeReader class="d-none" ref="imageBarCodeReader" v-else-if="readBarCode === 'gallery'" @decode="onDecode" @error="onError" />
  </div>
</template>

<script>
import { ImageBarcodeReader } from "vue-barcode-reader";
import { StreamBarcodeReader } from "vue-barcode-reader";
export default {
  name: "BarCodeReader",
  components: { ImageBarcodeReader, StreamBarcodeReader },
  props: {
    readBarCode: {
      type: [String, Boolean],
      default: false,
      description: "Tipo de leitor.",
    },
    onDecode: {
      type: Function,
      default: () => {},
      description: "Função disparada ao ler resultado.",
    },
    onError: {
      type: Function,
      default: () => {},
      description: "Função disparada ao receber erro ao ler resultado.",
    },
  },
  methods: {
    openGallery() {
      this.$refs.imageBarCodeReader.$el.click();
    },
  },
  mounted() {
    if (this.readBarCode === "gallery") {
      this.openGallery();
    }
  },
  updated() {
    if (this.readBarCode === "gallery") {
      this.openGallery();
    }
  },
};
</script>

<style>
.puzl-barcode-reader-external-component .scanner-container video {
  width: 100%;
}
.puzl-barcode-reader-external-component .overlay-element {
  background: none!important;
}
.puzl-barcode-reader-external-component .laser {
 margin-left: 0!important;
 width: 100%!important;
}
</style>
