import { createAxios } from "@/plugins/axios";
import * as types from "../stock/mutation_types";
const endPoint = '/operational/schedule/kanban/+microservice'
import {cursorPaginate,setUrlWithNextPage} from '@/store/baseStore'
export default {
  namespaced: true,
  state: {
    programmed_items: [],
    in_progress_items: [],
    finished_items: [],
    widgets: [],
  },
  getters:{
    getProgrammed: state => state.programmed_items,
    getInProgress: state => state.in_progress_items,
    getFinished: state => state.finished_items,
    getWidgets: state => state.widgets,
  },
  mutations: {
    [types.SET_WIDGETS](state, payload) {
      state.widgets = payload
    },
  },
  actions: {
    getWidgets({commit, state}, params) {
      return createAxios().get(`${endPoint}widgets`, {params: params})
        .then(({data}) => {
          commit(types.SET_WIDGETS, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getProgrammed({commit, state}, params) {
      return createAxios().patch(setUrlWithNextPage(`${endPoint}programmed`, params),
        {params: params})
        .then(({data}) => {
          cursorPaginate(state, {data: data.data, next_page: params.next_page}, 'programmed_items')
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getInProgress({commit, state}, params) {
      return createAxios().patch(setUrlWithNextPage(`${endPoint}in-progress`, params),
        {params: params})
        .then(({data}) => {
          cursorPaginate(state, {data: data.data, next_page: params.next_page}, 'in_progress_items')
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getFinished({commit, state}, params) {
      return createAxios().patch(setUrlWithNextPage(`${endPoint}finished`, params),
        {params: params})
        .then(({data}) => {
          cursorPaginate(state, {data: data.data, next_page: params.next_page}, 'finished_items')
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
