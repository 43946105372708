<template>
  <div>
    <modal size="xl" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title ml-2">
          {{ modal.title }} 
          <span><div class="text-left h3 small" style="color: white;">{{ modal.detail }}</div></span> 
        </h5>
      </template>

      <div class="row pt-0 mx-3 mt-2" style="margin-top: -1.4rem">
        <div class="col-md-12 p-1">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div
                  class="col-md-12 table-responsive"
                  style="overflow-y: scroll; max-height: 500px"
                >
                  <table class="table table-sm table-bordered">
                    <thead>
                      <tr class="fixed">
                        <th>NFS-E</th>
                        <th>FATURA</th>
                        <th>EMISSÃO</th>
                        <th>CONTRATO</th>
                        <th>CLIENTE</th>
                        <th>VALOR TOTAL</th>
                        <th>ARQUIVOS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="loadingNfses" v-for="(item, index) in 10" :key="index">
                        <th colspan="12">
                          <skeleton-puzl type="button"/>
                        </th>
                      </tr>
                      <tr v-if="!loadingNfses" v-for="item in $_nfses" style="">
                        <th v-if="item.nfse_number.length > 6" style="width: 5px;" class="text-primary"> ...{{ item.nfse_number.slice(item.nfse_number.length - 6) }} </th>
                        <th v-else style="width: 5px;" class="text-primary"> {{ item.nfse_number }}</th>
                        <th style="width: 5px">{{ item.id }}</th>
                        <th style="font-weight: normal"> {{ item.authorization_date | parseDate("DD/MMM") }}</th>
                        <th 
                          style="width: 5px"
                        > 
                          <i class="fa-solid fa-circle-user text-success" style="margin-right: 2px"></i>
                          <span>{{ item.contract_proposal_code }} </span>
                        </th>
                        <th style="font-weight: normal"> {{ item.entity_entity_name }} </th>
                        <th style="width: 5px" class="text-right"> {{ item.total | currency() }} </th>
                        <th>
                          <base-dropdown>
                            <a style="text-transform: none" slot="title-container" class="text-default dropdown-toggle font-weight-300">
                              Selecione
                            </a>
                            <div>
                              <a @click.prevent="download(item.link_pdf)">
                                <span class="dropdown-item text-center">
                                  <i class="fa-solid fa-file-pdf text-danger" style="margin-right: 2px"></i>
                                  PDF
                                </span>
                              </a>
                              <div class="dropdown-divider p-0 m-0"></div>
                              <a @click.prevent="download(item.link_xml)">
                                <span class="dropdown-item text-center">
                                  <img
                                    width="15px"
                                    style="margin-right: 4px"
                                    src="/img/icons/nfe_xml.png"
                                  />XML
                                </span>
                              </a>
                            </div>
                          </base-dropdown>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <pagination
                :loading="loadingNfses"
                :source="sourceNfses"
                @navegate="navegateNfses"
              />
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import {base_url_ms} from '@/plugins'
import {mapGetters} from "vuex";
import Pagination from "@/components/Utils/Pagination";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import {formatErrorValidation} from "@/plugins";
export default {
  name: "ModalIssResumeNfse",
  components: {
    Pagination,
    SkeletonPuzl,
  },
  data() {
    return {
      modal: {
        title: "LISTA DE NFS-E(S)",
        detail: null,
        create: false,
      },
      base_url_ms: base_url_ms(),
      loadingNfses: false,      
      sourceNfses: null,
      paramsNfsesDefault: {
        page: 1,
        per_page: 15,
      },
      paramsNfses: {},
      filter: {},        
    };
  },
   props: {
    filter_by_authorization_date: {
      type: [Boolean],
      description: "Filtrar por data de autorização",
    },
   },
  computed: {
    ...mapGetters({
      $_nfses: "issResume/getNfses",
    }),
  },
  methods: {
    close() {
      this.modal.create = false;
      this.$emit("close");
    },
    /**
     * Abrir modal com filtro fixo
     *
     * @param {string} nfseStatus [Pendente, Autorizada, Cancelada, Negada]
     * @param {string} authorizationDateStart [YYYY-MM-DD]
     * @param {string} authorizationDateEnd [YYYY-MM-DD]
     * @param {array} companyPlants array of integer
     * @param {string} state string
     * @param {string} city string
     * @param {boolean} iss_retained boolean
     *
     */
    handleCreateModal(nfseStatus, authorizationDateStart, authorizationDateEnd, companyPlants, state, city, iss_retained) {
      this.filter = {
        nfse_status: nfseStatus,
        range: { 
          start: authorizationDateStart, 
          end: authorizationDateEnd,
        },
        company_plants: companyPlants ?? [],
        state: state,
        city: city,
        iss_retained: iss_retained,
      };

      this.modal.detail = `${nfseStatus}(s)`;
      this.modal.create = true;
      this.init();
    },
    init() {
      this.paramsNfses = {...this.paramsNfsesDefault};
      this.fetchNfses();
    },
    fetchNfses() {
      this.loadingNfses = true;
      this.$store
        .dispatch("issResume/fetchNfses", {
          filter: this.filter,
          page: this.paramsNfses.page,
          filter_by_authorization_date: this.filter_by_authorization_date ? 1 : null
        })
        .then((response) => {
          this.sourceNfses = response;          
        })
        .catch((error) => {
          let errors = error.status
            ? error.data.message
            : formatErrorValidation(error.response.data.errors);
          this.$notify({ type: "danger", message: errors });
        })
        .finally(() => this.loadingNfses = false);
    },
    navegateNfses(page) {
      this.paramsNfses.page = page;
      this.fetchNfses();
    },
    download(s3_path) {
      window.open(s3_path, '_blank');
      this.$snotify.success('Download iniciado com sucesso!', {
        timeout: 1000,
        icon: false,
        position: "centerBottom",
        showProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false
      });
    },
  },
};
</script>

<style scoped></style>
