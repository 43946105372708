import 'sweetalert2/dist/sweetalert2.css'

import * as Swal from './swal'

import {
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  DatePicker,
  TimeSelect,
  TimePicker,
  Input,
  Option,
  Popover,
  Select,
  Tooltip
} from 'element-ui';
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import Badge from '@/components/Badge.vue'
import BaseAlert from "@/components/BaseAlert"
import BaseButton from '@/components/BaseButton.vue'
import BaseDropdown from '@/components/BaseDropdown.vue'
import BaseHeader from '@/components/BaseHeader'
import BaseInput from '@/components/Inputs/BaseInput.vue'
import BaseNav from "@/components/Navbar/BaseNav"
import BaseProgress from "@/components/BaseProgress"
import BaseSlider from '@/components/BaseSlider'
import BaseSwitch from '@/components/BaseSwitch.vue'
import BaseToggle from '@/components/BaseToggle.vue'
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb'
import Card from '@/components/Cards/Card.vue'
import Collapse from '@/components/Collapse/Collapse'
import CollapseItem from '@/components/Collapse/CollapseItem'
import FileInput from '@/components/Inputs/FileInput.vue'
import Modal from '@/components/Modal.vue'
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import Vue2Filters from 'vue2-filters'
import VueProgressBar from 'vue-progressbar'
import swal from 'sweetalert2'
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'
import lang from 'element-ui/lib/locale/lang/pt-br'
import locale from 'element-ui/lib/locale'
import money from 'v-money'
locale.use(lang)

const options = {
  color: '#4eb7bb',
  failedColor: '#e20e29',
  thickness: '6px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
};

const Vue2FiltersConfig = {
  capitalize: {
    onlyFirstLetter: false
  },
  number: {
    format: '0',
    thousandsSeparator: ',',
    decimalSeparator: '.'
  },
  bytes: {
    decimalDigits: 2
  },
  percent: {
    decimalDigits: 2,
    multiplier: 1
  },
  currency: {
    symbol: 'R$ ',
    decimalDigits: 2,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    showPlusSign: false
  },
  pluralize: {
    includeNumber: false
  },
  ordinal: {
    includeNumber: false
  }
}

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */
const GlobalComponents = {
  install(Vue) {
    Vue.prototype.$Swal = Swal
    Vue.prototype.$swal = swal
    Vue.component(Badge.name, Badge);
    Vue.component(BaseAlert.name, BaseAlert);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(BaseHeader.name, BaseHeader);
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseDropdown.name, BaseDropdown);
    Vue.component(BaseNav.name, BaseNav);
    Vue.component(BaseProgress.name, BaseProgress);
    Vue.component(BaseSlider.name, BaseSlider);
    Vue.component(BaseSwitch.name, BaseSwitch);
    Vue.component(BaseToggle.name, BaseToggle);
    Vue.component(Card.name, Card);
    Vue.component(Modal.name, Modal);
    Vue.component(Breadcrumb.name, Breadcrumb);
    Vue.component(Input.name, Input);
    Vue.component(FileInput.name, FileInput);
    Vue.component('validation-provider', ValidationProvider);
    Vue.component('validation-observer', ValidationObserver);
    Vue.component(Collapse.name, Collapse);
    Vue.component(CollapseItem.name, CollapseItem);
    Vue.use(Checkbox);
    Vue.use(CheckboxButton);
    Vue.use(CheckboxGroup);
    Vue.use(Tooltip);
    Vue.use(Popover);
    Vue.use(VueProgressBar, options);
    Vue.use(Vue2Filters, Vue2FiltersConfig);
    Vue.use(Autocomplete);
    Vue.use(money, { precision: 4 })
    Vue.component(Select.name, Select);
    Vue.component(Option.name, Option);
    Vue.component(DatePicker.name, DatePicker);
    Vue.component(TimeSelect.name, TimeSelect);
    Vue.component(TimePicker.name, TimePicker);
    Vue.component(SkeletonPuzlGrid);
    /* New AppComponents */
  }
};
export default GlobalComponents;
