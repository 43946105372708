<template>
  <div>
    <modal size="lg" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <div>
        <multi-filter ref="multi-filter" @clearFilters="clearFilters" :status="multi_filter" @fetch="init" :filter="filter">
          <div class="col-md-4 p-2">
            <input-date-picker :defaultRange="filter.range" ref="date-picker" @handleFilterDate="handleFilterDate" />
          </div>
        </multi-filter>
        <div class="container-fluid">
          <div class="row card-wrapper" v-show="loadingSkeleton">
            <SkeletonPuzlGrid v-for="index in 3" :key="index" />
          </div>
          <PuzlEmptyData v-if="!$_items.length && !loadingSkeleton" />
          <div v-if="$_items.length && !loadingSkeleton">
            <div
              v-for="(item, index) in $_items"
              :key="index"
              class="border-left border-3 d-flex justify-content-between p-2 mb-4 border-primary"
            >
              <div class="col-10">
                <div>
                  <h4 class="mb-0">{{ item.entity_name }}</h4>
                  <h5 class="m-0 font-weight-normal">{{ item.document }}</h5>
                </div>
                <h5 class="mb-0 font-weight-normal mt-2">
                  Transporte <b>{{ item.document_number }} </b>
                </h5>
                <h5 class="mb-0 font-weight-normal">{{ item.issue_date | parseDate("DD MMM YYYY") }}</h5>
                <h5 class="mb-0 mt-2">{{ item.final_value | currency() }}</h5>
              </div>

              <div class="col-2 d-flex justify-content-center align-items-center">
                <base-button size="sm" type="success" @click="handleSelect(item)">
                  <i class="fa fa-check text-white"></i>
                </base-button>
              </div>
            </div>
            <Pagination @navegate="navegate" :source="source" :loading="loadingSkeleton" />
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <base-button type="secondary" @click="closeModal()"> Fechar </base-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import MultiFilter from "@/components/Utils/MultiFilterV2.vue";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import InputDatePicker from "@/components/InputDatePicker";
import Pagination from "@/components/Utils/PaginationV2";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "ModalGetFreightToReassociate",
  data() {
    return {
      modal: {
        title: "Buscar Frete",
        create: false,
      },
      filter: {
        range: {
          start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
        },
      },
      paginate_page: 1,
      loadingSkeleton: true,
      multi_filter: {
        null: null,
      },
      source: null,
      entry_launch_id: null,
    };
  },
  components: { MultiFilter, SkeletonPuzlGrid, Pagination, PuzlEmptyData, InputDatePicker },
  computed: {
    ...mapGetters({
      $_items: "entryLaunch/getFreightToReassociateItems",
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    async openModal(entry_launch_id, default_issue_date) {
      const loader = this.$loading.show();
      this.$refs["date-picker"].resetDate();
      this.entry_launch_id = entry_launch_id;
      this.filter = {
        range: {
          start: new Date(moment(default_issue_date).format("YYYY-MM-DD 00:00:00")),
          end: new Date(moment(default_issue_date).format("YYYY-MM-DD 00:00:00")),
        },
      };
      await this.init();
      loader.hide();
      this.modal.create = true;
    },
    init(filter = null) {
      this.filter.custom_search = this.$helper.customSearchFormatter(
        ["entities.entity_name", "entries_launches.document_number", "entities.document"],
        filter?.global
      );
      this.loadingSkeleton = true;
      return this.$store
        .dispatch("entryLaunch/getFreightToReassociate", {
          ...this.filter,
          ...{
            page: this.paginate_page,
            id: this.entry_launch_id,
            order_by: [
              {
                column: "entries_launches.issue_date",
                is_desc: true,
              },
            ],
            limit: 3,
            paginate_type: 2, // simplePaginate
          },
        })
        .then((response) => {
          this.source = response.data;
          this.loadingSkeleton = false;
        });
    },
    clearFilters() {
      this.filter = {
        range: {
          start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
        },
      };
      this.paginate_page = 1;
      this.$refs["date-picker"].resetDate();
      this.init();
    },
    navegate(page) {
      this.paginate_page = page;
      this.init();
    },
    handleSelect(item) {
      const loader = this.$loading.show();
      this.$notify({ type: "info", message: "Estamos trabalhando em sua solicitação." });
      this.$store
        .dispatch("entryLaunch/freightReassociate", {
          freight_entry_launch_id: item.id,
          parent_entry_launch_id: this.entry_launch_id,
        })
        .then((response) => {
          this.$notify({ type: response.error_type, message: response.message });
          this.$emit("selected");
        })
        .finally(() => {
          loader.hide();
          this.closeModal();
        });
    },
    handleFilterDate(filter) {
      this.filter.range = filter;
      this.paginate_page = 1;
      this.init();
    },
  },
};
</script>
