<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div class="form-group row m-0 mb-1">
              <div class="col-6 text-center">
                <label class="col-form-label form-control-label text-danger"> Inferior </label>
              </div>
              <div class="col-6 text-center">
                <label class="col-form-label form-control-label form-group m-0 text-success"> Superior </label>
              </div>
            </div>
            <!-- Aglomerante -->
            <div class="form-group row m-0 mb-1">
              <div class="col-12">
                <label class="form-control-label"> Aglomerante <span class="text-danger">&nbsp;*</span> </label>
              </div>
              <div class="col-6 text-center">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm pb-2">
                    <input
                      maxlength="4"
                      v-on:input="limit.binder.bottom = formatString($event.target.value, '-999', '999')"
                      inputmode="numeric"
                      v-model="limit.binder.bottom"
                      class="form-control form-control-sm"
                      :class="
                        limit.binder.bottom == 0
                          ? ''
                          : limit.binder.bottom > 0
                          ? 'is-valid'
                          : limit.binder.bottom && limit.binder.bottom < 0
                          ? 'is-invalid '
                          : ''
                      "
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0"> kg/m<sup>3</sup> </small>
                    </template>
                  </base-input>
                </validation-provider>
              </div>
              <div class="col-6 text-center">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm pb-2">
                    <input
                      maxlength="4"
                      v-on:input="limit.binder.top = formatString($event.target.value, '-999', '999')"
                      inputmode="numeric"
                      v-model="limit.binder.top"
                      class="form-control form-control-sm"
                      :class="
                        limit.binder.top == 0
                          ? ''
                          : limit.binder.top > 0
                          ? 'is-valid'
                          : limit.binder.top && limit.binder.top < 0
                          ? 'is-invalid'
                          : ''
                      "
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0"> kg/m<sup>3</sup> </small>
                    </template>
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!-- Àgua -->
            <div class="form-group row m-0 mb-1">
              <div class="col-12">
                <label class="form-control-label"> Água <span class="text-danger">&nbsp;*</span> </label>
              </div>
              <div class="col-6 text-center">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm pb-2">
                    <input
                      maxlength="4"
                      v-on:input="limit.water.bottom = formatString($event.target.value, '-999', '999', 0)"
                      inputmode="numeric"
                      v-model="limit.water.bottom"
                      class="form-control form-control-sm"
                      :class="
                        limit.water.bottom == 0
                          ? ''
                          : limit.water.bottom > 0
                          ? 'is-valid'
                          : limit.water.bottom && limit.water.bottom < 0
                          ? 'is-invalid'
                          : ''
                      "
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0"> kg/m<sup>3</sup> </small>
                    </template>
                  </base-input>
                </validation-provider>
              </div>
              <div class="col-6 text-center">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm pb-2">
                    <input
                      maxlength="4"
                      v-on:input="limit.water.top = formatString($event.target.value, '-999', '999', 0)"
                      inputmode="numeric"
                      v-model="limit.water.top"
                      class="form-control form-control-sm"
                      :class="
                        limit.water.top == 0
                          ? ''
                          : limit.water.top > 0
                          ? 'is-valid'
                          : limit.water.top && limit.water.top < 0
                          ? 'is-invalid'
                          : ''
                      "
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0"> kg/m<sup>3</sup> </small>
                    </template>
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!-- Teor de argamassa -->
            <div class="form-group row m-0 mb-1">
              <div class="col-12">
                <label class="form-control-label"> Teor de argamassa <span class="text-danger">&nbsp;*</span> </label>
              </div>
              <div class="col-6 text-center">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm pb-2">
                    <input
                      maxlength="4"
                      v-on:input="limit.content_mortar.bottom = formatString($event.target.value, '-100', '100')"
                      inputmode="numeric"
                      v-model="limit.content_mortar.bottom"
                      class="form-control form-control-sm"
                      :class="
                        limit.content_mortar.bottom == 0
                          ? ''
                          : limit.content_mortar.bottom > 0
                          ? 'is-valid'
                          : limit.content_mortar.bottom && limit.content_mortar.bottom < 0
                          ? 'is-invalid'
                          : ''
                      "
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0"> % </small>
                    </template>
                  </base-input>
                </validation-provider>
              </div>
              <div class="col-6 text-center">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm pb-2">
                    <input
                      maxlength="4"
                      v-on:input="limit.content_mortar.top = formatString($event.target.value, '-100', '100')"
                      inputmode="numeric"
                      v-model="limit.content_mortar.top"
                      class="form-control form-control-sm"
                      :class="
                        limit.content_mortar.top == 0
                          ? ''
                          : limit.content_mortar.top > 0
                          ? 'is-valid'
                          : limit.content_mortar.top && limit.content_mortar.top < 0
                          ? 'is-invalid'
                          : ''
                      "
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0"> % </small>
                    </template>
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!-- Ar total -->
            <div class="form-group row m-0 mb-1">
              <div class="col-12">
                <label class="form-control-label"> Ar Total <span class="text-danger">&nbsp;*</span> </label>
              </div>
              <div class="col-6 text-center">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm pb-2">
                    <input
                      maxlength="3"
                      v-on:input="limit.total_air.bottom = formatString($event.target.value, '-50', '50')"
                      inputmode="numeric"
                      v-model="limit.total_air.bottom"
                      class="form-control form-control-sm"
                      :class="
                        limit.total_air.bottom == 0
                          ? ''
                          : limit.total_air.bottom > 0
                          ? 'is-valid'
                          : limit.total_air.bottom && limit.total_air.bottom < 0
                          ? 'is-invalid'
                          : ''
                      "
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0"> % </small>
                    </template>
                  </base-input>
                </validation-provider>
              </div>
              <div class="col-6 text-center">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm pb-2">
                    <input
                      maxlength="3"
                      v-on:input="limit.total_air.top = formatString($event.target.value, '-50', '50')"
                      inputmode="numeric"
                      v-model="limit.total_air.top"
                      class="form-control form-control-sm"
                      :class="
                        limit.total_air.top == 0
                          ? ''
                          : limit.total_air.top > 0
                          ? 'is-valid'
                          : limit.total_air.top && limit.total_air.top < 0
                          ? 'is-invalid'
                          : ''
                      "
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0"> % </small>
                    </template>
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!-- Aditivos -->
            <div class="form-group row m-0 mb-1">
              <div class="col-12">
                <label class="form-control-label"> Aditivos <span class="text-danger">&nbsp;*</span> </label>
              </div>
              <div class="col-6 text-center">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm pb-2">
                    <input
                      maxlength="3"
                      v-on:input="limit.agent.bottom = formatString($event.target.value, '-50', '50')"
                      inputmode="numeric"
                      v-model="limit.agent.bottom"
                      class="form-control form-control-sm"
                      :class="
                        limit.agent.bottom == 0
                          ? ''
                          : limit.agent.bottom > 0
                          ? 'is-valid'
                          : limit.agent.bottom && limit.agent.bottom < 0
                          ? 'is-invalid'
                          : ''
                      "
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0"> % </small>
                    </template>
                  </base-input>
                </validation-provider>
              </div>
              <div class="col-6 text-center">
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm pb-2">
                    <input
                      maxlength="3"
                      v-on:input="limit.agent.top = formatString($event.target.value, '-50', '50')"
                      inputmode="numeric"
                      v-model="limit.agent.top"
                      class="form-control form-control-sm"
                      :class="
                        limit.agent.top == 0
                          ? ''
                          : limit.agent.top > 0
                          ? 'is-valid'
                          : limit.agent.top && limit.agent.top < 0
                          ? 'is-invalid'
                          : ''
                      "
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0"> % </small>
                    </template>
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')"> Cancelar </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore"> Salvar </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { checkNumberValue } from "@/helpers";
import { mask1, formatErrorValidation } from "@/plugins";

export default {
  name: "ModalCreateLimitSetting",
  data() {
    return {
      exist: false,
      modal: {
        title: "Configurações de Limite",
        create: false,
      },
      limit: {
        binder: {
          top: null,
          bottom: null,
        },
        adjustment_type: null,
        uuid: null,
        water: {
          top: null,
          bottom: null,
        },
        content_mortar: {
          top: null,
          bottom: null,
        },
        total_air: {
          top: null,
          bottom: null,
        },
        agent: {
          top: null,
          bottom: null,
        },
      },
      loadingStore: false,
      adjustment_type: 9,
    };
  },
  methods: {
    checkBiggerValues() {
      for (var property in this.limit) {
        if (this.limit[property] && this.limit[property].bottom && parseInt(this.limit[property].bottom) > 0) {
          return true;
        }
      }
      return false;
    },
    checkSmallerValues() {
      for (var property in this.limit) {
        if (this.limit[property] && this.limit[property].top && parseInt(this.limit[property].top) < 0) {
          return true;
        }
      }
      return false;
    },
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        value = mask1(checkNumberValue(value, min, max).toString(), decimals, ".", true);
        return checkNumberValue(value, min, max);
      }
      return checkNumberValue(value, min, max);
    },
    closeModal() {
      this.modal.create = false;
    },
    checkLimitSetting() {
      if (this.limit.binder.bottom) {
        this.exist = true;
      } else {
        this.limit.adjustment_type = 9;
      }
    },
    handleCreateModal() {
      this.$Progress.start();
      this.$notify({ type: "info", message: "Estamos trabalhando em sua solicitação." });
      this.modal.create = true;
      this.loadingStore = true;
      this.$store
        .dispatch("adjustmentLimitSetting/getByType", this.adjustment_type)
        .then((response) => {
          this.limit = {
            binder: {
              top: response.data.binder.top,
              bottom: response.data.binder.bottom,
            },
            adjustment_type: response.data.adjustment_type,
            uuid: response.data.uuid,
            water: {
              top: response.data.water.top,
              bottom: response.data.water.bottom,
            },
            content_mortar: {
              top: response.data.content_mortar.top,
              bottom: response.data.content_mortar.bottom,
            },
            total_air: {
              top: response.data.total_air.top,
              bottom: response.data.total_air.bottom,
            },
            agent: {
              top: response.data.agent.top,
              bottom: response.data.agent.bottom,
            },
          };
          this.$Progress.finish();
          this.loadingStore = false;
          this.$notify({ type: response.error_type, message: response.message });
          this.modal.open = true;
          this.checkLimitSetting();
        })
        .catch((error) => {
          this.checkLimitSetting();
          if (error.response && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({ type: "danger", message: errors });
          }
          this.$Progress.finish();
          this.loadingStore = false;
        });
    },
    store() {
      this.$Progress.start();
      this.loadingStore = true;
      this.$notify({ type: "info", message: "Estamos trabalhando em sua solicitação." });
      if (this.checkBiggerValues()) {
        this.$notify({ type: "danger", message: "O Valor do campo inferior não poderá ser maior que 0." });
        this.loadingStore = false;
        this.$Progress.finish();
      } else if (this.checkSmallerValues()) {
        this.$notify({ type: "danger", message: "O Valor do campo superior não poderá ser menor que 0." });
        this.loadingStore = false;
        this.$Progress.finish();
      } else {
        if (this.exist) {
          this.$store
            .dispatch("adjustmentLimitSetting/update", this.limit)
            .then((response) => {
              this.loadingStore = false;
              this.modal.create = false;
              this.$Progress.finish();
              this.$notify({ limit: response.error_type, message: response.message });
              this.$emit("createdChargePointLimitSetting");
            })
            .catch((error) => {
              if (error.response.status === 422) {
                let errors = formatErrorValidation(error.response.data.errors);
                this.$notify({ limit: "danger", message: errors });
              }
              this.$Progress.finish();
              this.loadingStore = false;
            });
        } else {
          this.$store
            .dispatch("adjustmentLimitSetting/add", this.limit)
            .then((response) => {
              this.loadingStore = false;
              this.modal.create = false;
              this.$Progress.finish();
              this.$notify({ limit: response.error_type, message: response.message });
              this.$emit("createdChargePointLimitSetting");
            })
            .catch((error) => {
              if (error.response.status === 422) {
                let errors = formatErrorValidation(error.response.data.errors);
                this.$notify({ limit: "danger", message: errors });
              }
              this.$Progress.finish();
              this.loadingStore = false;
            });
        }
      }
    },
  },
};
</script>

<style scoped></style>
