<template>
  <div>
    <modal size="lg" :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <div class="container mt-2">
          <div class="row card-wrapper" v-if="mix_designs_mixe">
            <div class="col-md-12 mt-1">
              <div class="row mb-n1 mt-n3">
                <div class="col-md-12 mb-1">
                  <div class="">
                    <div class="" style="padding: 0.8rem !important">
                      <div class="row mb-n2 show-md">
                        <div class="col-1">
                <span class="badge badge-default bg-white"
                      style="border: 1px solid #1a70b7;border-radius: 30px;">
                  <img height="28px" src="/img/icons/test-tube.png"></span>
                        </div>
                        <div class="col-md-4 text-left mt-n1">
                          <h5><span class="font-weight-700"
                                    style="text-transform: uppercase; font-size: 16px !important;">{{
                              mix_design.plant && mix_design.plant.name || ""
                            }}</span>
                          </h5>
                          <h5 class="font-weight-600 mt-n25"
                              style="text-transform: uppercase; font-size: 14px !important;">
                            <template v-if="mix_design.type">
                              {{ mix_design.type.technical_description || "" }}
                              <template v-if="mix_design.subtype">
                                {{ nextHeader(mix_design.subtype.technical_description) }}
                              </template>
                            </template>
                            <template v-if="mix_design.subtype">
                              {{ mix_design.subtype.technical_description || "" }}
                              <template v-if="mix_design.classification">
                                {{
                                  nextHeader(
                                    mix_design.classification.technical_description
                                  )
                                }}
                              </template>
                            </template>
                            <template v-if="mix_design.classification">
                              {{ mix_design.classification.technical_description || "" }}
                            </template>
                          </h5>
                          <h5 class="mt-n25">
                            <template v-if="mix_design.slumpFlow">
                              {{ mix_design.slumpFlow.nomenclature || "" }}
                              <template v-if="mix_design.maximum_size">
                                {{ nextHeader(mix_design.maximum_size.description) }}
                              </template>
                            </template>
                            <template v-if="mix_design.maximum_size">
                              {{ mix_design.maximum_size.description || "" }}
                            </template>
                          </h5>
                        </div>
                        <div class="col text-right mt-2"><span class="badge pointer badge-default badge-light"
                                                               style="font-weight: 700 !important;color: rgb(26, 112, 183);background: none;font-size: 25px;">
                        TR-{{ mix_designs_mixe.mix_code }}
                        </span>
                        </div>
                      </div>
                      <div class="row mb-n2 show-mobile">
                        <div class="col-2">
                <span class="badge badge-default bg-white"
                      style="border: 1px solid #1a70b7;border-radius: 30px;">
                  <img height="28px" src="/img/icons/test-tube.png"></span>
                        </div>
                        <div class="col-6 text-left mt-n1">
                          <h5><span class="font-weight-700"
                                    style="text-transform: uppercase; font-size: 16px !important;">{{
                              mix_design.plant && mix_design.plant.name || ""
                            }}</span>
                          </h5>
                          <h5 class="font-weight-600 mt-n25"
                              style="text-transform: uppercase; font-size: 14px !important;">
                            <template v-if="mix_design.type">
                              {{ mix_design.type.technical_description || "" }}
                              <template v-if="mix_design.subtype">
                                {{ nextHeader(mix_design.subtype.technical_description) }}
                              </template>
                            </template>
                            <template v-if="mix_design.subtype">
                              {{ mix_design.subtype.technical_description || "" }}
                              <template v-if="mix_design.classification">
                                {{
                                  nextHeader(
                                    mix_design.classification.technical_description
                                  )
                                }}
                              </template>
                            </template>
                            <template v-if="mix_design.classification">
                              {{ mix_design.classification.technical_description || "" }}
                            </template>
                          </h5>
                          <h5 class="mt-n25">
                            <template v-if="mix_design.slumpFlow">
                              {{ mix_design.slumpFlow.nomenclature || "" }}
                              <template v-if="mix_design.maximum_size">
                                {{ nextHeader(mix_design.maximum_size.description) }}
                              </template>
                            </template>
                            <template v-if="mix_design.maximum_size">
                              {{ mix_design.maximum_size.description || "" }}
                            </template>
                          </h5>
                        </div>
                        <div class="text-right mt-2"><span class="badge pointer badge-default badge-light"
                                                               style="font-weight: 700 !important;color: rgb(26, 112, 183);background: none;font-size: 25px;">
                        TR-{{ mix_designs_mixe.mix_code }}
                        </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <template>
                <div class="row mt-0">
                  <div v-if="mix_designs_mixe.mix_code" class="col-md-4 mt-n4 p-1">
                    <collapse v-for="(itemMixItem, indexMixItem) in mix_designs_mixe.items" :key="indexMixItem"
                              class="border rounded p-0 mt-3 mb-2">
                      <collapse-item :isActive="true" name="1" class="header-gray " class-body="pl-2 p-2 m-0"
                                     v-for="(item, indexItem) in itemMixItem"
                                     back-ground="border-header-success-bottom" v-if="indexItem != 'binder'"
                                     :key="indexItem">

                        <h5 slot="title" class="mb-0 collapse-parameters">
                          {{ item.short_description }}
                          {{ format_mixe(item.period, item.period_type) }}
                          {{ item.item_description }}
                          {{ item.letter }}
                        </h5>
                        <div>
                          <h5 class="checklist-title mb-0">
                            Sd:
                            <span class="small">
                                                {{ item.value_sd }}
                                                &nbsp;
                                                {{ item.letter }}
                                            </span>
                          </h5>
                          <h5 class="checklist-title mb-0">
                            NC:
                            <span class="small" v-if="reliability_levels">
                                                {{
                                item.value_nc ? return_to_percentage(item.value_nc, reliability_levels) + '%' : '-'
                              }}
                                            </span>
                          </h5>
                          <h5 class="checklist-title mb-0">
                            Z:
                            <span class="small">
                                                {{ item.value_z ? item.value_z : '-' }}
                                            </span>
                          </h5>
                          <h5 class="checklist-title mb-0">
                            Vm:
                            <span class="small">
                                                {{ item.value_medium }}
                                                &nbsp;
                                                {{ item.letter }}
                                            </span>
                          </h5>
                        </div>
                      </collapse-item>
                    </collapse>
                  </div>
                  <div class="col-md-4 mt-n4 p-1">
                    <collapse class="border rounded p-0 mt-3" id="collapse">
                      <collapse-item :isActive="true" name="1" class="header-gray" class-body="pl-2 p-2 m-0"
                                     back-ground="border-header-primary-bottom">
                        <h5 slot="title" class="mb-0 collapse-parameters">Parâmetros Técnicos</h5>
                        <div>
                          <div class="form-group row m-0 p-0 mb-1">
                            <label class="col-md-6 m-0 p-0 col-form-label form-control-label">
                              <span class="text-default">Aglomerante</span>
                              <i
                                v-if="validateBetweenValues(mixe.technical_parameter[mix_designs_mixe.id].binder, 100, 500)"
                                class="text-warning fa-solid fa-triangle-exclamation ml-1 mr-1">
                              </i>
                            </label>
                            <div class="col-md-6 m-0 p-0">
                              <base-input input-group-classes="input-group-sm">
                                <input inputmode="numeric"
                                       v-model="mixe.technical_parameter[mix_designs_mixe.id].binder"
                                       class="form-control form-control-sm" v-mask="['####']"
                                       v-on:keydown="changeWater(mix_designs_mixe.id)"/>
                                <template slot="append">
                                  <small class="is-invalid input-group-sm p-0 m-0">
                                    <span style="text-transform: none">kg/m</span><sup>3</sup>
                                  </small>
                                </template>
                              </base-input>
                            </div>
                          </div>

                          <div class="form-group row m-0 p-0 mb-1">
                            <label class="col-md-6 m-0 p-0 col-form-label form-control-label">
                              <span class="text-default">Água</span>
                              <i
                                v-if="validateBetweenValues(mixe.technical_parameter[mix_designs_mixe.id].water, 150, 300)"
                                class="text-warning fa-solid fa-triangle-exclamation ml-1 mr-1">
                              </i>
                            </label>
                            <div class="col-md-6 m-0 p-0">
                              <base-input input-group-classes="input-group-sm">
                                <input inputmode="numeric"
                                       v-model.number="mixe.technical_parameter[mix_designs_mixe.id].water"
                                       class="form-control form-control-sm" v-mask="['####']"
                                       v-on:keydown="changeWater(mix_designs_mixe.id)"/>
                                <template slot="append">
                                  <small class="input-group-sm p-0 m-0">
                                    <span style="text-transform: none">l/m</span><sup>3</sup>
                                  </small>
                                </template>
                              </base-input>
                            </div>
                          </div>
                          <template v-if="show_calculate_water_binder">
                            <div class="row mt-2 mb-2 ml-n4 mr-n4">
                              <div class="col-md-12">
                                <hr class="mt-0 mb-0">
                              </div>
                            </div>
                            <div class="form-group row m-0 p-0 mb-1">
                              <label class="col-md-6 m-0 p-0 col-form-label form-control-label">
                              <span class="text-warning">Rj <el-popover trigger="click" placement="right">
                          <div class="new-default-black-font" style="width: 343px;word-break: break-word;">
                            <div class="row">
                              <div class="col-12">
                                <nav class="new-default-black-font font-weight-500 text-uppercase"
                                     style="font-size: 12px;">
                                Resistência a compressão média do aglomerante no ensaio padrão na idade j
                                </nav>
                              </div>
                            </div>
                          </div>
                          <img slot="reference" role="button" class="mt-n1 ml-2" width="19px"
                               src="/img/icons/info.png"/>
                        </el-popover>
                              </span>
                              </label>
                              <div class="col-md-6 m-0 p-0">
                                <base-input input-group-classes="input-group-sm">
                                  <input inputmode="numeric"
                                         v-model="rj"
                                         class="form-control form-control-sm" v-mask="['#','#,#', '##,#']"
                                  />
                                </base-input>
                              </div>
                            </div>
                            <div class="form-group row m-0 p-0 mb-1">
                              <label class="col-md-6 m-0 p-0 col-form-label form-control-label">
                              <span class="text-warning">Fcj  <el-popover trigger="click" placement="right">
                          <div class="new-default-black-font" style="width: 343px;word-break: break-word;">
                            <div class="row">
                              <div class="col-12">
                                <nav class="new-default-black-font font-weight-500 text-uppercase"
                                     style="font-size: 12px;">
                                Resistência a compressão mínima do concreto na idade j
                                </nav>
                              </div>
                            </div>
                          </div>
                          <img slot="reference" role="button" class="mt-n1 ml-2" width="19px"
                               src="/img/icons/info.png"/>
                        </el-popover></span>
                              </label>
                              <div class="col-md-6 m-0 p-0">
                                <base-input input-group-classes="input-group-sm">
                                  <input inputmode="numeric"
                                         v-model="fcj"
                                         class="form-control form-control-sm" v-mask="['#','#,#', '##,#']"
                                  />
                                </base-input>
                              </div>
                            </div>
                          </template>
                          <div class="form-group row m-0 p-0 mb-1">
                            <label class="col-md-6 m-0 p-0 col-form-label form-control-label">
                              <span class="text-default">A/Agl.
                                <img class="pointer"
                                     @click.prevent="setCalculateWaterBinder()"
                                     src="/img/react.gif"
                                     width="35px"></span>
                            </label>
                            <div class="col-md-6 m-0 p-0">
                              <base-input input-group-classes="input-group-sm">
                                <input inputmode="numeric"
                                       v-model.number="mixe.technical_parameter[mix_designs_mixe.id].water_binder"
                                       class="form-control form-control-sm" v-mask="['#.###']"
                                       v-on:keydown="changeWaterBinder(mix_designs_mixe.id)"/>
                              </base-input>
                            </div>
                          </div>
                          <div class="row mt-2 mb-2 p-0" v-show="show_calculate_water_binder">
                            <div class="col-md-12 mb-n2">
                              <h5 class="text-danger" style="font-size: 11px !important;">
                                Ao clicar em calcular, voce se responsabiliza sobre as quantidades geradas pelo PUZL MIX
                              </h5>
                            </div>
                            <div class="col-md-12 text-right">
                              <button @click.prevent="calculateWaterBinder"
                                      style="border-radius: 4px !important;"
                                      :disabled="!rj || !fcj"
                                      type="button" class="btn-sm btn base-button btn-danger">
                                <i class="fa-solid fa-calculator"></i> CALCULAR
                              </button>
                            </div>
                          </div>

                          <div class="form-group row m-0 p-0 mb-1 mt-2">
                            <label class="col-md-6 m-0 p-0 col-form-label form-control-label">
                              <span class="text-default">T. Arg. (v)</span>
                              <i
                                v-if="validateBetweenValues(mixe.technical_parameter[mix_designs_mixe.id].content_mortar, 40, 80)"
                                class="text-warning fa-solid fa-triangle-exclamation ml-1 mr-1">
                              </i>
                            </label>
                            <div class="col-md-6 m-0 p-0">
                              <base-input input-group-classes="input-group-sm">
                                <input inputmode="numeric"
                                       :disabled="!mix_design.has_ag"
                                       :value="mixe.technical_parameter[mix_designs_mixe.id].content_mortar"
                                       @input="mixe.technical_parameter[mix_designs_mixe.id].content_mortar = setTArg($event, mix_designs_mixe.id)"
                                       class="form-control form-control-sm"
                                       v-mask="['##.#', '#.#', '###.#']"
                                />
                                <template slot="append">
                                  <small class="input-group-sm p-0 m-0">
                                    %
                                  </small>
                                </template>
                              </base-input>
                            </div>
                          </div>

                          <div class="form-group row m-0 p-0 mb-1">
                            <label class="col-md-6 m-0 p-0 col-form-label form-control-label">
                              <span class="text-default">Ar total</span>
                              <i
                                v-if="validateBetweenValues(mixe.technical_parameter[mix_designs_mixe.id].total_air, 1, 9.90)"
                                class="text-warning fa-solid fa-triangle-exclamation ml-1 mr-1">
                              </i>
                            </label>
                            <div class="col-md-6 m-0 p-0">
                              <base-input input-group-classes="input-group-sm">
                                <input inputmode="numeric"
                                       v-model="mixe.technical_parameter[mix_designs_mixe.id].total_air"
                                       class="form-control form-control-sm" v-mask="['##.#', '#.#']"
                                />
                                <template slot="append">
                                  <small class="input-group-sm p-0 m-0">
                                    %
                                  </small>
                                </template>
                              </base-input>
                            </div>
                          </div>
                        </div>

                      </collapse-item>
                    </collapse>
                  </div>
                  <div class="col-md-4 mt-n4 p-1 group-categories-responsive">
                    <collapse class="border rounded p-0 mt-3 mb-2">
                      <collapse-item :isActive="true" name="1" class="header-gray " class-body="pl-2 p-2 m-0"
                                     back-ground="border-header-success-bottom">
                        <h5 slot="title" class="mb-0 collapse-parameters">Teores e Quantidades</h5>
                        <div v-for="(category, indexCategory) in mix_designs_mixe.groups_categories"
                             :key="indexCategory">
                          <h4 class="mt-3">
                            {{ category.name }}
                          </h4>

                          <div class="form-group row m-0 p-0 mb-1" v-for="(options, indexOptions) in category.options"
                               :key="options.id">
                            <label class="col-md-5 m-0 p-0 col-form-label form-control-label">
                                        <span class="text-default small">
                                            {{ options.name }}
                                        </span>
                              <i v-show="validateBetweenValuesByCategory(options.value, category.category_id)"
                                 class="text-warning fa-solid fa-triangle-exclamation mr-1"></i>
                            </label>
                            <div class="col-md-7 m-0 p-0 text-left">
                              <base-input input-group-classes="input-group-sm">
                                <input type="text" inputmode="numeric"
                                       v-if="category.category_id != 1 && category.calculable"
                                       :value="options.value" class="form-control form-control-sm"
                                       v-mask="handleMask(category.category_id)"
                                       @input="options.value = change($event, category.options, options.value, indexOptions, indexCategory)"
                                       :disabled="handleValidateCalculable(options.id, category)"/>
                                <input v-else maxlength="5" type="text" inputmode="numeric" :value="options.value"
                                       class="form-control form-control-sm"
                                       @input="options.value = change($event, category.options, options.value, indexOptions, indexCategory)"
                                       :disabled="handleValidateCalculable(options.id, category)"/>
                                <template slot="append">
                                  <small class="input-group-sm p-0 m-0" style="font-size: 65%">
                                    {{ category_calculable[category.category_id] || '%' }}
                                  </small>
                                </template>
                              </base-input>
                            </div>
                          </div>
                        </div>
                      </collapse-item>
                    </collapse>
                  </div>
                </div>
              </template>
              <div class="row">
                <div class="col-md-12 mt-1 float-right text-right">
                  <button @click.prevent="modal = false"
                          type="button" class="btn base-button btn-danger">
                    <img src="/img/icons/cancel-white.png"
                         width="22px" height="22px"
                         class="mr-2"> CANCELAR
                  </button>
                  <button
                    @click.prevent="storeMixe(mix_designs_mixe.id, mix_designs_mixe.uuid, 0)"
                    type="button" class="btn base-button btn-success" title="Salvar">
                    <img src="/img/icons/save.png" width="22px" height="22px" class="mr-2"> SALVAR
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalReference ref="ModalReference" @getWithoutReference="getWithoutReference()"/>
    </modal>
  </div>
</template>
<script>

import {
  period_types,
  value_z_to_percentage,
  nextHeader
} from "@/views/Modules/Technology/Mix/helpers/mixe";
import {debounce, mask1} from "@/plugins";
import {mapGetters} from "vuex";
import {checkNumberValue} from "@/helpers";
import ModalReference from "@/views/Modules/Technology/Mix/Shared/_ModalReference.vue";

export default {
  components: {ModalReference},
  name: "ModalEditMixeResume",
  data() {
    return {
      modal: false,
      title: "Editar Traço",
      mix_designs_mixe: null,
      mix_design: null,
      format_mixe: period_types,
      return_to_percentage: value_z_to_percentage,
      nextHeader: nextHeader,
      mask: mask1,
      rj: null,
      fcj: null,
      show_calculate_water_binder: false,
      category_calculable: {
        5: "kg/m³",
        99: "kg/m³",
        4: "% (S.P.A)"
      },
      mixe: {
        technical_parameter: {},
      },
    };
  },
  computed: {
    ...mapGetters({
      'reliability_levels': 'reliability_level/fetch',
      $_groups_without_references: 'MixDesignMixe/fetchWithoutReferences',
    }),

    doesntHasLargeAggregate() {
      return this.mix_design.groups && this.mix_design.groups.findIndex(item => item.id === 3) === -1
    }
  },
  methods: {
    calculateWaterBinder() {
      let rj = Number(this.rj.replace(',', '.'))
      let fcj = Number(this.fcj.replace(',', '.'))
      const value = 0.57 * rj / (fcj + 0.285 * rj)
      this.$set(this.mixe.technical_parameter[this.mix_designs_mixe.id], 'water_binder', value)
      this.$forceUpdate()
      this.$swal.fire({
        title: "Sucesso",
        text: 'A/Agl calculado com sucesso!',
        icon: "success"
      });
      this.show_calculate_water_binder = false
      this.changeWater(this.mix_designs_mixe.id)
      this.changeWaterBinder(this.mix_designs_mixe.id)
    },
    /**
     * Habilita o cálculo de A/Agl com base no RJ e FCJ
     */
    setCalculateWaterBinder() {
      this.show_calculate_water_binder = !this.show_calculate_water_binder
      this.rj = null
      this.fcj = null
      if (this.mix_designs_mixe.items[this.mix_designs_mixe.id] && this.mix_designs_mixe.items[this.mix_designs_mixe.id][0]) {
        this.fcj = this.mix_designs_mixe.items[this.mix_designs_mixe.id][0].value_medium
      }
    },
    getWithoutReference() {
      this.loading_all_reference = true
      this.$store.dispatch('MixDesignMixe/fetchGroupsWithoutReference',
        this.mix_design.uuid).then(() => {
        this.loading_all_reference = false
      })
    },
    change(event, options, value, index, categoryIndex, mixeIndex) {
      // se a categoria nao houver necessidade de calculo, retorna o valor digitado
      if (event.target.value == "00") {
        return event.target.value = "0"
      }
      event.target.value = event.target.value.replace('-', '')
      event.target.value = checkNumberValue(event.target.value)

      if (!this.mix_designs_mixe['groups_categories'][categoryIndex].calculable) {
        return checkNumberValue(mask1(event.target.value, 2, '.', true));
      }
      if (this.mix_designs_mixe['groups_categories'][categoryIndex].calculable &&
        this.mix_designs_mixe['groups_categories'][categoryIndex].category_id != 1) {
        event.target.value = checkNumberValue(mask1(event.target.value, 0, '.', true))
      }
      let first = 100

      if (this.mix_designs_mixe['groups_categories'][categoryIndex].category_id == 1) {
        first = "100.0";
        event.target.value = this.mask(event.target.value, 1)
      }

      options[index].value = event.target.value
      for (let index in options) {
        if (index === Object.keys(options)[0]) {
          //  verifico se o indice percorrido é a primeira posicao, se for, é ignorado
          continue
        }
        // realizo a recontagem conforme o valor informado
        if (this.mix_designs_mixe['groups_categories'][categoryIndex].category_id == 1) {
          if (options[index].value.length <= 3) {
            if (options[index].value.charAt(options[index].value.length - 1) == '.') {
              options[index].value = options[index].value.replace('.', '')
            }
            first -= options[index].value
          } else {
            first -= options[index].value
          }
        } else {
          first -= options[index].value
        }
      }
      if (first.toString().length > 6) {

        const round = (number, decimalPlaces) => {
          const factorOfTen = Math.pow(10, decimalPlaces)
          return Math.round(number * factorOfTen) / factorOfTen
        }

        first = round(first, 1)
      }
      if (this.mix_designs_mixe['groups_categories'][categoryIndex].category_id == 1) {
        if (first == "100") {
          first = "100.0"
        }
        if (first.toString().length >= 3) {
          first = this.mask(first, 1)
        }
        if (first.toString().length == 2) {
          first = first + ".0"
        }
      }
      // caso o valor da primeira posicao seja menor que 0, nao é permitido alterar o valor
      if (first < 0) {
        this.$swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Soma dos teores de mesma categoria não pode ser maior que 100%!',
        })
        return value
      }
      // define o valor da primeira posicao conforme o calculo de 100 menos os demais
      this.$set(this.mix_designs_mixe['groups_categories'][categoryIndex]['options'][0], 'value', first)
      return event.target.value
    },
    handleMask(categoryId) {
      if (categoryId == 1) {
        return ['##.#', '##', '###.#']
      }
      if (categoryId == 5) {
        return ['#.##', '##.##', '###.##']
      }
      if (categoryId == 4) {
        return ['#.##', '##.##']
      }
      return '###'
    },
    handleValidateCalculable(index, category) {
      if (category.category_id == 99) {
        return true
      }
      // VALIDA SE É CALCULAVEL, SE FOR, REMOVE O PRIMEIRO INPUT
      if (category.calculable) {
        if (category.options[0].id === index) {
          return true
        }
      }
      return false
    },
    setTArg(event, mixeId) {
      const value = mask1(event.target.value, 1, '.', true)
      if (value > 100) {
        this.$forceUpdate()
        return this.mixe.technical_parameter[mixeId].content_mortar
      }
      this.$forceUpdate()
      return event.target.value
    },
    validateBetweenValuesByCategory(val, category_id) {
      if (![4, 5].includes(category_id)) {
        return false
      }
      if (category_id === 4) {
        if (val < 0.20 || val > 1.50) {
          return true
        } else {
          return false
        }
      }
      if (category_id === 5) {
        if (val > 9.99) {
          return true
        } else {
          return false
        }
      }
    },
    validateBetweenValues(val, min, max) {
      if (val < min || val > max) {
        return true
      } else {
        return false
      }
    },
    changeWater: debounce(function (idMixe) {
      let water_binder;
      let binder = parseInt(this.mixe.technical_parameter[idMixe].binder);
      let water = parseInt(this.mixe.technical_parameter[idMixe].water);
      water_binder = parseFloat(water / binder).toFixed(3);
      if (water && binder) {
        this.mixe.technical_parameter[idMixe].water_binder = parseFloat(water_binder);
        this.$forceUpdate();
      }
    }, 500),
    changeWaterBinder: debounce(function (idMixe) {
      let binder;
      let water_binder = parseFloat(this.mixe.technical_parameter[idMixe].water_binder);
      let water = parseInt(this.mixe.technical_parameter[idMixe].water);
      binder = Math.round(water / water_binder);
      if (water) {
        this.mixe.technical_parameter[idMixe].binder = binder;
        this.$forceUpdate();
      }
    }, 50),
    copy(val) {
      this.$helper.copy(val)
      this.$notify({type: 'success', message: 'Código do traço copiado com sucesso!'})
    },
    storeMixe(mixeId, mixeUuid, index) {
      let mixe = this.mix_designs_mixe
      this.mixSend = {
        groups_categories: {...mixe.groups_categories},
        mix_designs_mixe_id: mixeId,
        mix_designs_mixe_uuid: mixeUuid,
        technical_parameter: this.mixe.technical_parameter[mixeId],
      };
      for (let key in this.mixSend.technical_parameter) {
        if (!this.mixSend.technical_parameter[key]) {
          return this.$notify({
            type: 'danger',
            message: 'Todos os parametros devem ser preenchidos para continuar!'
          });
        }
      }
      for (let key in this.mixSend.groups_categories) {
        const group = this.mixSend.groups_categories[key]
        for (let index in group.options) {
          const str = group.options[index].value.toString()
          if (str.charAt(str.length - 1) == '.') {
            this.mixSend.groups_categories[key].options[index].value = str.substr(0, str.length - 1)
          }
        }
      }
      this.$Progress.start();
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      });
      this.$axios.post("/mix/mixe/manager/+microservice", this.mixSend)
        .then(({data}) => {
          const message = data.exception || data.message
          this.$notify({
            type: data.error_type,
            message: message
          });
          this.$Progress.finish();
          this.modal = false
          this.$emit('submit')
        })
        .catch((error) => {
          if (error.data && error.data.confirm) {
            this.$refs.ModalReference.handleCreate(2);
          }
          const message = error.data.exception || error.data.message
          this.$notify({
            type: error.data.error_type,
            message: message
          });
          this.$Progress.finish()
        });
    },
    handleOpenModal(mixe, mix_design) {
      this.mix_designs_mixe = mixe
      this.mix_design = mix_design
      this.mixe.technical_parameter[mixe.id] = {
        binder: mixe.technical_parameter.binder,
        water: mixe.technical_parameter.water,
        water_binder: mixe.technical_parameter.water_binder.toFixed(3),
        content_mortar: this.mix_design.has_ag ? mixe.technical_parameter.content_mortar.toFixed(1) : "100.0",
        total_air: mixe.technical_parameter.total_air.toFixed(1)
      }
      this.show_calculate_water_binder = false
      this.modal = true
    }
  }
};
</script>

<style>
.collapse-parameters {
  /*font-weight: 700 !important;*/
  /*font-size: 14px !important;*/
}

@media only screen and (min-width: 600px) {
  .group-categories-responsive {
    height: 72vh;
    overflow: auto;
  }
}
</style>
