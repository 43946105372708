<template>
  <div>
    <multi-filter ref="multi-filter" @fetch="init" :filter="filter" /> 
    <div class="container-fluid">
      <!-- SKELETON LOADING EFFECT -->
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
      </div>

      <!-- NO DATA INFO EFFECT -->
      <PuzlEmptyData
        v-if="!entityCategoryList.length && !loadingSkeleton"
      ></PuzlEmptyData>

      <!-- CARD LIST -->
      <div
        class="row card-wrapper"
        v-show="entityCategoryList.length && !loadingSkeleton"
      >
        <div
          class="col-lg-4"
          v-for="(entityCategory, index) in entityCategoryList"
          :key="index"
        >
          <!-- CARD ITEM -->
          <card :class="entityCategory.status ? 'card-border-top-success' : 'card-border-top-danger'">
          <!-- <card :class="'card-border-top-success'"> -->
            <div class="row align-items-center mb-3">
              <div class="col-8">
                <!-- CARD TITLE -->
                <h5 class="h3 mb-0">{{ entityCategory.name }}</h5>
              </div>

              <!-- CARD MENU BUTTONS -->
              <div class="col-4 text-right">
                <base-dropdown menuOnRight>
                  <base-button
                    slot="title-container"
                    type="primary"
                    class="dropdown-toggle p-2 rounded m-0"
                  >
                    <i class="fas fa-cog"></i>
                  </base-button>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a
                    class="dropdown-item"
                    @click="handleEdit(entityCategory.id)"
                  >
                    <i class="fas fa-edit text-warning"></i>
                    Editar
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a
                    class="dropdown-item"
                    @click="handleDelete(entityCategory.id)"
                  >
                    <i class="fas fa-times text-danger"></i>
                    Excluir
                  </a>
                </base-dropdown>
              </div>
            </div>

            <!-- Card body -->
            <collapse class="border rounded p-0 mt-3">
              <collapse-item
                name="1"
                class="header-gray card-body-molde"
                back-ground="border-header-primary-bottom"
              >
                <h5 slot="title" class="mb-0">Observações</h5>
                <ul class="list-unstyled">
                  <li class="text-capitalize">
                    <span class="text-lowercase">{{
                      entityCategory.observation
                    }}</span>
                  </li>
                </ul>
              </collapse-item>
            </collapse>
          </card>
        </div>
      </div>
    </div>
    <loading-pagination :show="loading && !loadingSkeleton"/>
    <ModalEdit ref="edit"></ModalEdit>
  </div>
</template>

<script>
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import { mapGetters } from "vuex";
import ModalEdit from "./_Edit";
import MultiFilter from "@/components/Utils/MultiFilter";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
export default {
  name: "List",
  mixins: [cursorPaginate],
  components: {
    SkeletonPuzlGrid,
    PuzlEmptyData,
    ModalEdit,
    MultiFilter,
    LoadingPagination,
  },

  data() {
    return {
      loadingSkeleton: false,
      filter: {},
    };
  },
  computed: {
    ...mapGetters({
      entityCategoryList: "entityCategory/fetch",
    }),
  },
  created() {
    this.init({});
  },
  methods: {
    init(filter = null) {
      this.startCursor(filter);
      this.$store
        .dispatch("entityCategory/fetchItemsPaginate", {
            filter: this.filter,
            next_page: this.paginate.nextUrl
        })
        .then((response) => {
          this.resolveCursor(response);
        })
        .catch((error) => {
          this.resolveCursor();
        });
    },
    handleDelete(id) {
      this.$Swal
        .confirmDelete()
        .then((result) => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação.",
            });
            this.$store.dispatch("entityCategory/destroyById", id)
              .then(() => {})
              .catch(error => {
                this.$notify({
                  type: error.data.error_type,
                  message: error.data.message
                });
              });
            this.$Progress.finish();
          }
        })
        .catch(() => this.$Progress.finish());
    },
    handleEdit(id) {
      this.$refs.edit.handleEdit(id);
    },
  },
};
</script>

<style scoped></style>
