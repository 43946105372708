<template>
  <div>
    <modal size="lg" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <div>
        <div v-if="type_is_changeable">  
          <div class="row mb-3">
            <div class="col-6">
              <base-button
                @click.prevent="changeType(2)"
                type="primary"
                outline
                size="md"
                block
                :class="{ active: filter.type === 2 }"
              >
                MCC
              </base-button>
            </div>
            <div class="col-6">
              <base-button 
                @click.prevent="changeType(3)" 
                type="primary" 
                outline 
                size="md" 
                block 
                :class="{ active: filter.type === 3 }"
              >
                PRODUTO
              </base-button>
            </div>
          </div>
        </div>
        <multi-filter ref="multi-filter" @clearFilters="clearFilters" :status="multi_filter" @fetch="init" :filter="filter" />
        <div class="container-fluid">
          <div class="row card-wrapper" v-show="loadingSkeleton">
            <SkeletonPuzlGrid v-for="index in 3" :key="index" />
          </div>
          <PuzlEmptyData v-if="!$_items.length && !loadingSkeleton" />
          <div v-if="$_items.length && !loadingSkeleton">
            <div
              v-for="(item, index) in $_items"
              :key="index"
              class="border-left border-3 d-flex justify-content-between p-2 mb-4 border-primary"
            >
              <div class="col-10">
                <div>
                  <h4 class="mb-0">{{ item.product_name }}</h4>
                  <h4 v-if="item.product_service_hub_type_id === 2">{{`${item.supplier_name} (${item.origin_name})`}}</h4>
                </div>
                <div class="mt-1">
                  <h5 class="p-0 m-0">{{ item.category_name }}</h5>
                  <h5 v-if="item.code">Cód. {{ item.code }}</h5>
                </div>
              </div>
              <div class="col-2 d-flex justify-content-center align-items-center">
                <base-button size="sm" type="success" @click="handleSelect(item)">
                  <i class="fa fa-check text-white"></i>
                </base-button>
              </div>
            </div>
            <pagination @navegate="navegate" :source="source" />
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <base-button type="secondary" @click="closeModal()"> Fechar </base-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MultiFilter from "@/components/Utils/MultiFilterV2.vue";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import Pagination from "@/components/Utils/Pagination.vue";
import PuzlEmptyData from "@/components/PuzlEmptyData";

export default {
  name: "ModalSearchProductServiceHub",
  data() {
    return {
      modal: {
        title: "Buscar Despesas",
        create: false,
      },
      filter: {
        status: null,
      },
      paginate: {
        page: null,
      },
      loadingSkeleton: true,
      multi_filter: {
        null: {
          name: "Todos",
          type: "primary",
        },
      },
      source: null,
      parent_payload: null,
      type_is_changeable:false,
      entity_id: null,
      type: null,
    };
  },
  components: { MultiFilter, SkeletonPuzlGrid, Pagination, PuzlEmptyData },
  computed: {
    ...mapGetters({
      $_items: "productServiceHubPrice/currentPrices",
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    async openModal(entity_id, type = null) {
      this.$Progress.start();
      this.$refs['multi-filter'].clearFilters(false);
      this.filter = {
        status: null,
        type: type,
        entity_id: entity_id
      };
      /* Se for produto habilita mudança de tipo */
      this.type_is_changeable = type && type === 3;
      this.entity_id = entity_id;
      this.type = type;
      await this.init();
      this.$Progress.finish();
      this.modal.create = true;
    },
    init(filter = null) {
      this.filter = filter ?? this.filter;
      this.loadingSkeleton = true;
      return this.$store
        .dispatch("productServiceHubPrice/getCurrentPricesByEntity", {
          filter: this.filter,
          page: this.paginate.page,
        })
        .then((response) => {
          this.source = response;
          this.loadingSkeleton = false;
        });
    },
    clearFilters() {
      this.filter = {
        status: null,
        type: this.type,
        entity_id: this.entity_id
      };
      this.init();
    },
    navegate(page) {
      this.paginate.page = page;
      this.init();
    },
    handleSelect(item) {
      this.$emit("selected", item);
      this.closeModal();
    },
    changeType(type) {
      this.filter.type = type;
      this.paginate.page = 1;
      this.init();
    },
  },
};
</script>

<style scoped></style>
