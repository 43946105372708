import {
  BaseListFilterType,
  initBaseListFilterType,
  RangeDateType
} from "../../../../shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} AdjustmentHistoryListFilterExtType
 * @property {RangeDateType|null} range - Intervalo entre datas
 * @property {null|number} company_plant_id - Id da central
 * @property {null|number} adjustment_id - Id do tipo de ajuste
 * @property {null|number} action_id - Id do tipo de ação
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & AdjustmentHistoryListFilterExtType)} AdjustmentHistoryListFilterType
 */
export const AdjustmentHistoryListFilterType = {};

/**
 * Inicializar CmcAdjustmentListFilterType
 *
 * @returns {AdjustmentHistoryListFilterType}
 */
export const initAdjustmentHistoryListFilterType = () => {
  return {
    ...initBaseListFilterType(),
    order_by: [{ column: "adjustment_histories.id", is_desc: true }],
    custom_search: {
      columns: ["adjustment_histories.title"],
      values: [],
    },
    range: {
      start: null,
      end: null,
    },
    company_plant_id: null,
    adjustment_id: null,
    action_id: null
  };
}