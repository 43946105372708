<template>
  <div>
    <modal @close="close" :show.sync="show">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(submitAndClose)"
            autocomplete="off"
          >
            <div>
            </div>

            <div class="modal-footer">
              <base-button type="secondary" @click="close()">
                Cancelar
              </base-button>
              <base-button
                type="success"
                native-type="submit"
                :disabled="invalid"
                :loading="isLoading"
              >
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: "ModalCreate",
  props: ['show'],
  data () {
    return {
      title: 'Exemplo',
      isLoading: false
    }
  },
  computed: {
  },
  methods: {
    close () {
      this.$emit('close')
    },
    submitAndClose() {
    }
  },
}
</script>

<style scoped>

</style>
