import { render, staticRenderFns } from "./_ModalLimitSetting.vue?vue&type=template&id=0fd156b1&scoped=true"
import script from "./_ModalLimitSetting.vue?vue&type=script&lang=js"
export * from "./_ModalLimitSetting.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fd156b1",
  null
  
)

export default component.exports