/**
 * Definição das chaves únicas usadas para armazenar o estado.
 *
 * Cada chave deve ser única em todo o projeto.
 * Não deve haver duplicação de chaves em nenhum lugar do código.
 */
export const adjustmentTypeStoreStateKeys = Object.freeze({
  ITEM: "ADJUSTMENT_TYPE_ITEM",
  LISTED: "ADJUSTMENT_TYPE_LISTED",
  IS_LISTING: "ADJUSTMENT_TYPE_IS_LISTING",
  IS_FINDING: "ADJUSTMENT_TYPE_IS_FINDING",
  IS_SAVING: "ADJUSTMENT_TYPE_IS_SAVING",
  IS_REMOVING: "ADJUSTMENT_TYPE_IS_REMOVING",
  PAGE_STATES: "ADJUSTMENT_TYPE_PAGE_STATE",
});
