<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(save)"
            autocomplete="off"
          >
            <div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 col-form-label form-control-label">
                  Status
                </label>
                <div class="col-md-7 pt-2">
                  <base-switch
                    v-model="customer.status"
                    type="success"
                    offText="inativo"
                    onText="ativo"
                    class="success"
                  >
                  </base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-5 pt-1 pb-0 col-form-label form-control-label"
                >
                  Cliente
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="customer.customer_name"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                        :disabled="documentAlreadyExistsInEntity"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                  CPF/CNPJ
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-1 text-right">
                  <el-popover trigger="hover" placement="bottom" class="p-0 float-right pr-0" v-if="documentAlreadyExistsInEntity">
                    <span> Já existe um cliente/fornecedor cadastrado com esse mesmo documento. </span>
                    <base-button outline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none text-warning">
                      <i class="fa-solid fa-circle-exclamation fa-lg mr-n4"></i>
                    </base-button>
                  </el-popover>
                </div>
                <div class="col-md-7">
                  <validation-provider :rules="'required|document'" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm" group class="mb-2">
                      <input type="text" v-model="customer.document" class="form-control form-control-sm"
                             v-mask="['###.###.###-##', '##.###.###/####-##']"
                             :class="customer.document.length > 0 && ! verifyDocument(customer.document) && 'is-invalid' || errors[0] ? 'is-invalid' : 'is-valid'"
                            disabled
                      />
                    </base-input>
                    <small v-show="customer.document.length > 0 && ! verifyDocument(customer.document)"><i
                      class="fas fa-exclamation-triangle text-danger mr-2"></i>
                      <strong class="" style="color: #7e7e7e">CPF/CNPJ Inválido!</strong>
                    </small>
                  </validation-provider>
                </div>
              </div>

            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">
                Cancelar
              </base-button>
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                :loading="loadingSave"
              >
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
const {formatErrorValidation} = require("@/plugins")
import {verifyDocument} from "@/helpers";
export default {
  name: "ModalEditCustomer",
  data () {
    return {
      modal: false,
      title: 'Cliente',
      verifyDocument: verifyDocument,
      customer: {
        status: true,
        customer_name: '',
        document: '',
      },
      loadingSave: false,
      documentAlreadyExistsInEntity: false,
    }
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
  methods: {
    closeModal () {
      this.modal = false
    },
    async handleEditModal (uuid) {
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'})
      let loader = this.$loading.show()
      await this.$store.dispatch('customerConstruction/show', uuid)
        .then( (response) => {
          this.customer = {
            uuid: response.data.uuid,
            document: response.data.document || '',
            status: response.data.status || false,
            customer_name: response.data.customer_name || '',
          }
          this.$notify({type: response.error_type, message: response.message})
          loader.hide()
        }).catch((error) => {
        if(error.status===422){
          let message = formatErrorValidation(error.response.data.errors)
          this.$notify({ type: 'danger', message: message })
        }else{
          this.$notify({ type: error.data.error_type, message: error.data.message })
        }
        loader.hide()
        this.loadingSave = false
      });
      this.$store.dispatch('entity/getEntityByDocument', {document: this.customer.document}).then(response => {
        this.documentAlreadyExistsInEntity = response.data ? true : false;
        this.modal = true;
      });
    },
    save () {
      this.$Progress.start()
      this.loadingSave = true
      this.$store.dispatch('customerConstruction/update', this.customer)
      .then(response => {
        this.loadingSave = false
        this.modal = false
        this.$Progress.finish()
        this.$notify({ type: response.error_type, message: response.message })
      })
      .catch(error =>{
        if(error.response && error.response.status===422){
          let message = formatErrorValidation(error.response.data.errors)
          this.$notify({ type: 'danger', message: message })
        }
        else {
          this.$notify({type: 'danger', message: error.data.exception})
        }
        this.$Progress.finish()
        this.loadingSave = false
      })
    },
  },
}
</script>

<style scoped>

</style>
