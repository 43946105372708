const installmentMixin = {
  methods: {
    /* Função verifica necessidade de manter vencimento original. */
    getOriginalDueDate(keep_original_due_date, key, default_date) {
      if (keep_original_due_date === true && this.payload.installments[key] && this.payload.installments[key].original_due_date) {
        return this.payload.installments[key].original_due_date;
      }
      return default_date;
    },
  },
};
export default installmentMixin;
