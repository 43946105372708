<template>
  <div>
    <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
      <form
        @submit.prevent="handleSubmit(onSubmitClick)"
        class="needs-validation"
        autocomplete="off"
      >
        <div class="container-fluid">
          <div class="row mt-2 mb-3">
            <div class="col-12" style="display: flex; align-items: center">
              <img
                src="/img/icons/icons8/ios/info-squared_warning.png"
                width="22"
              />
              <h4 class="new-default-black-font m-0 mx-3">Identificação</h4>
              <hr class="ml-3 m-0" style="width: 100%" />
            </div>
          </div>
          <div class="row mt-2 align-items-center">
            <div class="col-6">
              <h4 class="new-default-black-font font-weight-normal m-0">
                Status
              </h4>
            </div>
            <div class="col-6">
              <base-switch
                v-model="formData.status"
                type="success"
                offText="Inativo"
                onText="Ativo"
                class="success"
              />
            </div>
          </div>
          <div class="row mt-2 align-items-center">
            <div class="col-6 mt-1">
              <h4 class="new-default-black-font font-weight-normal m-0">
                Descrição
              </h4>
            </div>
            <div class="col-6">
              <validation-provider rules="required" v-slot="{ errors }">
                <base-input input-group-classes="input-group-sm">
                  <input
                    v-model="formData.name"
                    type="text"
                    class="form-control form-control-sm"
                    :class="errors[0] ? 'is-invalid' : 'is-valid'"
                  />
                </base-input>
              </validation-provider>
            </div>
          </div>
          <div class="row mt-5 mb-4">
            <div class="col-12 text-right">
              <base-button
                @click="onCancelClick"
                type="danger"
                size="sm"
                style="width: 150px"
              >
                <div style="display: inline-flex; align-items: center">
                  <img src="/img/icons/cancel-white.png" width="20" />
                  <i class="m-0 ml-1" style="font-style: normal"> Cancelar </i>
                </div>
              </base-button>
              <base-button
                type="success"
                size="sm"
                style="width: 150px"
                native-type="submit"
                :disabled="invalid"
                :loading="$_stock_location_is_saving"
              >
                <div style="display: inline-flex; align-items: center">
                  <img src="/img/icons/save.png" width="20" />
                  <i class="m-0 ml-1" style="font-style: normal"> Salvar </i>
                </div>
              </base-button>
            </div>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script setup>
//#region Imports
import { defineEmits, defineProps, computed } from "vue";
import { StockLocationType } from "../../types";
import stockLocationStore from "../../store/stockLocationStore";
import { ValidationObserver } from "vee-validate";
//#endregion

//#region Props
/**
 * @typedef {Object} Props
 * @property {StockLocationType} formData
 */
/** @type {Props} */
const props = defineProps({
  formData: {
    type: Object,
    required: true,
  },
});
//#endregion

//#region Emits
const emit = defineEmits([
  "onCancelClick", 
  "onSubmitClick"
]);
//#endregion

//#region Computeds
/** Store Getters */
const $_stock_location_is_saving = computed(() => stockLocationStore.getters.getIsSaving());
//#endregion

//#region Methods
function onCancelClick() {
  emit("onCancelClick");
}

function onSubmitClick() {
  emit("onSubmitClick");
}
//#endregion
</script>

<style scoped></style>
