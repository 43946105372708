<template>
  <div>
    <modal size="md" :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(save)"
            autocomplete="off"
          >
            <div>
              <!-- Cliente-->
              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-4 mb-2"
                >
                  <div class="pt-1">
                    Cliente
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-6 pr-4 mb-2">
                  <base-input input-group-classes="input-group-sm">
                    <puzl-select 
                      v-model="filters.customer_uuids" 
                      :items="customers"  
                      label="customer_name"
                      :multiple="true"
                      customKey="uuid" />
                  </base-input>
                </div>
              </div>
              <!-- Obra-->
              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-4 mb-2"
                >
                  <div class="pt-1">
                    Obra
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-6 pr-4 mb-2">
                  <base-input input-group-classes="input-group-sm">
                     <puzl-select 
                      v-model="filters.construction_uuids" 
                      :items="constructions"  
                      label="construction_name"
                      :multiple="true"
                      customKey="uuid" />
                  </base-input>
                </div>
              </div>
              <!-- Status-->
              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-4 mb-2"
                >
                  <div class="pt-1">
                    Status
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-6 pr-4 mb-2">
                  <base-input input-group-classes="input-group-sm">
                    <puzl-select 
                        v-model="filters.status"
                        :multiple="true"
                        :items="[
                          {id: 1, name: 'Concluído'}, 
                          {id: 0, name: 'Liberado'},
                          {id: 2, name: 'Carregando'},
                          {id: 3, name: 'Em edição'},
                          {id: 4, name: 'Aguardando liberação do cliente'},
                          {id: 5, name: 'Em andamento'},
                          {id: 6, name: 'Cancelado'},
                          {id: 7, name: 'Não realizado'},
                          {id: 8, name: 'Pendência'},
                        ]" 
                    />
                  </base-input>
                </div>
              </div>
            </div>
            <div class="modal-footer pb-0">
              <base-button type="secondary" @click="closeModal()">
                Cancelar
              </base-button>
              <base-button
                type="success"
                :loading="loadingSave"
                native-type="submit"
                :disabled="invalid"
              >
                Selecionar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PuzlSelect from "@/components/PuzlSelect";

export default {
  components: { PuzlSelect },
  name: "ModalAdvancedFilters",
  data() {
    return {
      modal: false,
      title: "Busca Avançada",
      loadingSave: false,
      filters: {}
    };
  },
  computed: {
    ...mapGetters({
      customers: "customerConstruction/fetch",
      constructions: "customerConstructionConstructions/fetch"
    })
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    openModal(filters) {
      let loader = this.$loading.show()
      this.filters = filters;
      this.$store.dispatch("customerConstruction/fetchItems").then(response => {
        this.$store
          .dispatch("customerConstructionConstructions/fetchItems")
          .then(response => {
            loader.hide();
            this.$notify({
              type: response.error_type,
              message: response.message
            });
            this.modal = true;
          });
      });
    },
    save() {
      this.$emit("selectedFilters", this.filters);
      this.closeModal();
    }
  },
  mounted() {}
};
</script>

<style></style>
