<template>
<div>
    <modal :show.sync="modal.create">
        <template slot="header">
            <h5 class="modal-title pl-3">{{ modal.title }}</h5>
        </template>
        <div>
            <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
            <form class="needs-validation" @submit.prevent="handleSubmit(update)" autocomplete="off">
                <div class="form-group row m-0 p-0">
                    <!-- CAMPO DATA -->
                    <div class="col-md-6">
                        <div class="pb-0 mb-1 col-form-label form-control-label">
                            Data
                            <span class="text-danger">&nbsp;*</span>
                        </div>
                        <validation-provider rules="required" v-slot="{errors}">
                            <base-input input-classes="form-control-sm">    
                                    <el-date-picker
                                    v-model="$_stockAdjustment.date_stock_adjustment"
                                    size="mini"
                                    type="dateTime"
                                    format="dd/MM/yyyy"
                                    value-format="yyyy-MM-dd"
                                    disabled
                                >
                                </el-date-picker>
                            </base-input>
                        </validation-provider>
                    </div>

                    <!-- CAMPO TIPO -->
                    <div class="col-md-6">
                        <div class="col-form-label form-control-label">
                        Tipo
                        <span class="text-danger">&nbsp;*</span>
                        </div>
                        <div class="row mt-n1">
                            <div class="col-md-6 pt-1 mt-n2 mb-2">
                                <base-button block size="sm" type="success"
                                            disabled
                                            :class="$_stockAdjustment.type === true ? '' : 'btn-outline-success'"
                                            @click="handleChangeType(true)">
                                 Entrada
                                </base-button>
                            </div>
                            <div class="col-md-6 pt-1 mt-n2 mb-2">
                                <base-button block size="sm" type="danger"
                                            disabled
                                            :class="$_stockAdjustment.type === false ? '' : 'btn-outline-danger'"
                                            @click="handleChangeType(false)">
                                Saída
                                </base-button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group row m-0 p-0">
                    <!-- CAMPO CENTRAL -->
                    <div class="col-md-6">
                        <div class="pb-0 mb-1 col-form-label form-control-label">
                            Central
                            <span class="text-danger">&nbsp;*</span>
                        </div>
                        <validation-provider rules="required" v-slot="{errors}">
                            <base-input input-classes="form-control-sm">
                            <puzl-select
                                v-model="$_stockAdjustment.company_plant_id"
                                :items="$_plants"
                                disabled />
                            </base-input>
                        </validation-provider>
                    </div>
                    
                    <!-- CAMPO CATEGORIA MCC -->
                    <div class="col-md-6">
                        <div class="pb-0 mb-1 col-form-label form-control-label">
                            Categoria MCC
                            <span class="text-danger">&nbsp;*</span>
                        </div>
                        <validation-provider rules="required" v-slot="{errors}">
                            <base-input input-classes="form-control-sm">
                            <puzl-select
                                v-model="$_stockAdjustment.cmc_category_id                     "
                                :items="$_cmcCategories"
                                disabled />
                            </base-input>
                        </validation-provider>
                    </div>
                </div>
                
                <div class="form-group row m-0 p-0">
                    <!-- CAMPO MCC -->
                    <div class="col-md-12">
                        <div class="pb-0 mb-1 col-form-label form-control-label">
                            MCC
                            <span class="text-danger">&nbsp;*</span>
                        </div>
                        <validation-provider rules="required" v-slot="{errors}">
                            <base-input input-classes="form-control-sm">
                                <puzl-select
                                    v-model="$_stockAdjustment.cmc_id                     "
                                    :items="$_cmc"
                                    disabled
                                    label="product_name" />
                            </el-select>
                            </base-input>
                        </validation-provider>
                    </div>
                </div>

                <div class="form-group row m-0 p-0">
                    <!-- CAMPO LOCAL DE ESTOQUE -->
                    <div class="col-md-12">
                        <div class="pb-0 mb-1 col-form-label form-control-label">
                        Local de estoque
                        <span class="text-danger">&nbsp;*</span>
                        </div>
                        <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-classes="form-control-sm">
                            <puzl-select
                                v-model="$_stockAdjustment.company_plant_charge_point_location_id"
                                :items="stockLocations"
                                disabled
                                :labelMask="true"
                                label="dosage_location_name$ $code$ | $company_plant_charge_point_name$ | $company_plant_charge_point_location_name" />
                        </base-input>
                        </validation-provider>
                    </div>
                </div>
                
                <div class="card-header border-header-primary-bottom mt-3 mb-2 p-2">
                    <h5 class="h4 mb-0"> Dados considerando material seco </h5>
                </div>

                <div class="form-group row m-0 p-0">
                    <!-- CAMPO QUANTIDADE -->
                    <div class="col-md-6">
                        <div class="pb-0 mb-1 col-form-label form-control-label">
                            Quantidade
                            <span class="text-danger">&nbsp;*</span>
                        </div>
                        <validation-provider rules="required" v-slot="{errors}">
                            <base-input input-classes="form-control-sm">
                                <input 
                                    type="integer" 
                                    class="form-control form-control-sm" 
                                    v-model="$_stockAdjustment.quantity" 
                                    v-on:input="
                                        stockAdjustment.quantity = formatString(
                                        $event.target.value,
                                        '0',
                                        '999999.999',
                                        '3'
                                        )"
                                    disabled
                                    :class="errors[0] ? 'is-invalid' : 'is-valid'"
                                />
                                <template slot="prepend">
                                    <small class="input-group-sm p-0 m-0 mt-n2">
                                        TON
                                    </small>
                                </template>
                            </base-input>
                        </validation-provider>
                    </div>

                    <!-- CAMPO PREÇO -->
                    <div class="col-md-6">
                        <div class="pb-0 mb-1 col-form-label form-control-label">
                             Preço por TON
                            <span class="text-danger">&nbsp;*</span>
                        </div>
                        <validation-provider rules="required">
                            <base-input input-classes="form-control-sm">
                                <input 
                                    type="text" 
                                    inputmode="numeric" 
                                    class="form-control form-control-sm" 
                                    v-money="money"
                                    disabled
                                    v-model="$_stockAdjustment.price" 
                                />
                                <template slot="prepend">
                                    <small class="input-group-sm p-0 m-0 mt-n2">
                                        R$
                                    </small>
                                </template>
                            </base-input>
                        </validation-provider>
                    </div>
                </div>

                    <!-- CAMPO CUSTO TOTAL -->
                <div class="form-group row m-0 p-0">
                    <div class="col-md-12">
                        <div class="pb-0 mb-1 col-form-label form-control-label">
                            Custo total
                        </div>
                        <validation-provider rules="required">
                            <base-input input-classes="form-control-sm">
                                <input 
                                    type="text" 
                                    class="form-control form-control-sm" 
                                    v-money="money"
                                    disabled
                                    v-model="$_stockAdjustment.total" 
                                />
                                <template slot="prepend">
                                    <small class="input-group-sm p-0 m-0 mt-n2">
                                        R$
                                    </small>
                                </template>
                            </base-input>
                        </validation-provider>
                    </div>
                </div>

                <!-- CAMPO OBSERVAÇÕES -->
                <div class="form-group row m-0 p-0">
                    <div class="col-md-12">
                        <div class="pb-0 mb-1 col-form-label form-control-label">
                            Observações
                        </div>
                        <base-input input-classes="form-control-sm">
                            <textarea 
                                type="text" 
                                rows="2" 
                                class="form-control form-control-sm" 
                                v-model="$_stockAdjustment.observations"
                                maxlength="250">
                            </textarea>
                        </base-input>
                    </div>
                </div>

                <div class="modal-footer">
                <base-button type="secondary" @click="closeModal('create')">
                    Cancelar
                </base-button>
                <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                    Salvar
                </base-button>
                </div>
            </form>
            </validation-observer>
        </div>
    </modal>
</div>
</template>

<script>
const { formatErrorValidation } = require("@/plugins");
import { mapGetters } from 'vuex';
import moment from "moment";
import {VMoney} from "v-money";
import { moneyToFloat, checkNumberValue } from "@/helpers";
import { mask1 } from "@/plugins";
import PuzlSelect from "@/components/PuzlSelect";

export default {
    name: "ModalEdit",
    components: { PuzlSelect },
    data() {
        return {
            modal: {
                title: 'Editar Lançamento de ajustes',
                create: false,
            },
            loadingStore: false,
            stockLocations: [],
            money: {
                decimal: ",",
                thousands: ".",
                prefix: "",
                suffix: "",
                precision: 2,
                masked: false
            },
        };
    },
    computed: {
        ...mapGetters({
            $_stockAdjustment: 'stockAdjustment/show',
            $_plants: 'plant/fetch',
            $_cmcCategories: 'category/fetch',
            $_cmc: 'cmc/fetch',
            $_user: 'auth/getUser',
        }),
    },
    mounted() {
        this.$refs.formValidator.validate();
        this.$store.dispatch('plant/getCompanyPlantByUser', this.$_user.id);
        this.$store.dispatch('category/getCmcCategories');
    },
    directives: {
        money: VMoney
    },
    methods: {
        closeModal() {
            this.modal.create = false;
        },
        /**
         * @param {number} id 
         */
        handleEditModal(id) {
            let loader = this.$loading.show();
            this.$store.dispatch('stockAdjustment/show', id)
                .then(async(response) => {
                    await this.getCmc(response.data.company_plant_id, response.data.cmc_category_id);
                    await this.getStockLocation(response.data.company_plant_id, response.data.cmc_category_id);
                    this.modal.create = true;
                    loader.hide();
                    this.$notify({
                        type: "success",
                        message: "Solicitação realizada com sucesso!",
                    });
                }).catch((error) => {
                    this.$notify({
                        type: error.data.error_type,
                        message: error.data.message
                    })
                    loader.hide();
                })
        },
        update() {
            this.$Progress.start()
            this.loadingStore = true;
            this.$_stockAdjustment = {... this.$_stockAdjustment};
            let payload =  {... this.$_stockAdjustment};
            payload.price = this.$helper.strToNum(payload.price);
            payload.total = this.$helper.strToNum(payload.total);
            this.$store.dispatch('stockAdjustment/update', payload)
                .then(response => {
                    this.loadingStore = false;
                    this.modal.create = false;
                    this.$Progress.finish();
                    this.$notify({
                        type: response.error_type,
                        message: response.message
                    })
                })
                .catch(error => {
                    if (error.status == 200) {
                        this.$notify({
                            type: 'danger',
                            message: error.data.message
                        })
                       
                    } else if (error.response && error.response.status === 422) {
                        let errors = formatErrorValidation(error.response.data.errors)
                        this.$notify({
                            type: 'danger',
                            message: errors
                        })
                    }
                    this.$Progress.finish();
                    this.loadingStore = false;
                })
        },
        /**
        * @param {boolean} type 
        */
        handleChangeType(type) {
            this.$_stockAdjustment.type = type;
        },
        /**
         * @param {number} companyPlantId 
         * @param {number} cmcCategoryId 
         */
        async getCmc(companyPlantId, cmcCategoryId) {
            if (this.$_stockAdjustment.company_plant_id && this.$_stockAdjustment.cmc_category_id) {
                await this.$store.dispatch('cmc/getCmcByPlantAndCategory', {
                    company_plant_id: companyPlantId,
                    category_id: cmcCategoryId,
                });
            }
        },
        /**
         * @param {number} companyPlantId 
         * @param {number} cmcCategoryId 
         */
        async getStockLocation(companyPlantId, cmcCategoryId) {
            if (this.$_stockAdjustment.company_plant_id && this.$_stockAdjustment.cmc_category_id) {
                await this.$store.dispatch('cmc/getStockLocationByPlantAndCategory', {
                    company_plant_id: companyPlantId,
                    category_id: cmcCategoryId,
                }).then((response) => {
                    this.stockLocations = response.data;
                });
            }
        },
        formatString(value, min, max, decimals = "") {
            if (decimals) {
                return checkNumberValue(
                mask1(value.toString(), decimals, ".", true),
                min,
                max
                );
            }
            return checkNumberValue(value, min, max);
        },
    },
};
</script>

<style scoped></style>
