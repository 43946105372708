<template>
  <div class="header-card" v-bind="$attrs">
    <AppFixedPageTitle
      title="Checklist"
      icon="/img/icons/icons8/ios/concrete-pump-white.png"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <AppSearchBar
              :searchBarFilter.sync="searchBarFilter"
              :rangeParams="{ is_range: false }"
              @onSearchClick="handleFilter"
              @onClearClick="resetDefaultSearchBarFilter()"
            >
            </AppSearchBar>
          </div>
        </div>
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions>
          <AppPageHeaderActionsButton
            @click="add"
            text="novo"
            type="success"
            icon="/img/icons/plus-math--v1-white.png"
          />
          <base-button
            block
            type="light"
            class="new-puzl-default"
            style="border-radius: 50px !important"
            @click.prevent="$router.go(-1)"
          >
            <img width="19" src="/img/icons/circled-left.png" class="mr-1" />
            Voltar
          </base-button>
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>
    <AppTabSelect
      :isMultiSelect="false"
      :isShowViewTypes="false"
      :items="tabSelectItems"
      @onTabSelectItemClick="onTabSelectItemClick"
    >
    </AppTabSelect>
    <List
      :items="$_checklist"
      :loading="loading"
      @update-checklist="update"
      @generate-checklist="generate"
      @remove-checklist="removeChecklist"
      @checklist-item-redirect="checklistItemRedirect"
      @checklist-update-status="checklistUpdateStatus"
    />
    <ModalCreate ref="modalCreate" @refresh="init"></ModalCreate>
    <ModalEdit ref="modalEdit" @refresh="init"></ModalEdit>
    <ModalGenerate ref="modalGenerate" @refresh="init"></ModalGenerate>
  </div>
</template>

<script>
import {
  AppFixedPageTitle,
  AppPageHeader,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  AppSearchBar,
  AppSearchBarFilterSection,
  initSearchBarFilterType,
  AppTabSelect,
} from "@/components/AppGlobal";
import { mapGetters } from "vuex";
import List from "./Shared/_List";
import ModalCreate from "./Shared/_ModalCreate";
import ModalEdit from "./Shared/_ModalEdit";
import ModalGenerate from "./Shared/_ModalGenerate";

export default {
  name: "Checklist",
  inheritAttrs: false,
  components: {
    AppFixedPageTitle,
    AppPageHeader,
    AppPageHeaderActions,
    AppPageHeaderActionsButton,
    AppSearchBar,
    AppSearchBarFilterSection,
    AppTabSelect,
    List,
    ModalCreate,
    ModalEdit,
    ModalGenerate,
  },
  data() {
    return {
      loading: false,
      filter: {},
      status: this.initStates(),
      searchBarFilter: initSearchBarFilterType(),
    };
  },
  methods: {
    add() {
      this.$Progress.start();
      let loader = this.$loading.show();
      this.$refs.modalCreate.openModal();
      this.$Progress.finish();
      loader.hide();
    },
    onTabSelectItemClick(status) {
      this.handleFilter(status.id);
    },
    init() {
      let loader = this.$loading.show();
      this.loading = true;
      this.$store
        .dispatch("checklist/fetch", this.filter)
        .then((response) => {
          this.loading = false;
          loader.hide();
        })
        .catch(() => {
          this.loading = false;
          loader.hide();
        });
    },
    generate(uuid) {
      this.$Progress.start();
      let loader = this.$loading.show();
      this.$refs.modalGenerate.openModal(uuid);
      this.$Progress.finish();
      loader.hide();
    },
    update(uuid) {
      this.$Progress.start();
      let loader = this.$loading.show();
      this.$store
        .dispatch("checklist/show", uuid)
        .then((response) => {
          this.$Progress.finish();
          loader.hide();
          this.loading = false;
          this.$refs.modalEdit.openModal(response.data);
        })
        .catch(() => {
          this.loading = false;
          loader.hide();
        });
    },
    checklistItemRedirect(uuid) {
      this.$router.push(`/equipment/checklist-item/${uuid}`);
    },
    checklistUpdateStatus(param) {
      this.$Progress.start();
      this.$store
        .dispatch("checklist/updateStatus", param)
        .then((response) => {
          this.$Progress.finish();
        })
        .catch(() => {
          this.$Progress.finish();
        });
    },
    handleFilter(statusId = null) {
      this.filter.statusId = statusId;
      this.filter.search = this.searchBarFilter.custom_search_values;
      if (
        !this.searchBarFilter.custom_search_values ||
        !this.searchBarFilter.custom_search_values.length
      ) {
        delete this.filter.search;
      }
      if (statusId === null) {
        delete this.filter.statusId;
      }
      this.init();
    },
    removeChecklist(uuid) {
      this.$Swal
        .confirmDelete()
        .then((result) => {
          if (result.isConfirmed) {
            this.$Progress.start();
            let loader = this.$loading.show();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação.",
            });
            this.$store
              .dispatch("checklist/destroy", uuid)
              .then((response) => {
                this.$notify({ type: "success", message: response.message });
                this.init();
              })
              .catch((error) => {
                if (error.data && error.data.message) {
                  this.$notify({
                    type: "danger",
                    message: error.data.message,
                  });
                }
              })
              .finally(() => {
                this.$Progress.finish();
                loader.hide();
              });
          }
        })
        .catch(() => this.$Progress.finish());
    },
    resetDefaultSearchBarFilter() {
      this.searchBarFilter = this.defaultSearchBarFilter();
      delete this.filter.search;
      delete this.filter.statusId;
      this.init();
    },
    initStates() {
      return [
        { id: null, name: "Todos", quantity: 0 },
        { id: 0, name: "Inativos", quantity: 0 },
        { id: 1, name: "Ativos", quantity: 0 },
      ];
    },
    defaultSearchBarFilter() {
      return {
        ...initSearchBarFilterType(),
      };
    },
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      $_checklist: "checklist/fetch",
    }),
    tabSelectItems() {
      this.status = this.initStates();
      return this.status.map((state) => {
        this.$_checklist.forEach((item) => {
          if (state.id !== null) {
            let current = state.id ? true : false;
            if (item.active === current) {
              state.quantity = state.quantity + 1;
            }
          } else if (state.id === null) {
            state.quantity = state.quantity + 1;
          }
        });
        return {
          id: state.id,
          name: state.name,
          suffix: "",
          selected:
            this.filter.statusId === state.id ||
            (this.filter.statusId === undefined && state.id === null)
              ? true
              : false,
        };
      });
    },
  },
};
</script>

<style scoped></style>
