<template>
	<div class="custom-card-reconciliation my-3" v-if="bankStatement && bankInfo">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-6 col-xl-3 px-0">
					<div class="container">
						<div class="row">
							<div class="col-auto px-0 pr-1">
								<img :src="'/img/icons/banks/' + bankInfo.bank_code + '.png'" width="48">
							</div>
							<div class="col px-1">
								<h5 class="new-default-black-font m-0">
									{{ bankInfo.bank_name }}
								</h5>
								<h6 class="new-default-black-font font-weight-normal m-0">
									Agência: {{ bankInfo.agency }}{{ bankInfo.agency_digit ? bankInfo.agency_digit + '-' : '' }}
								</h6>
								<h6 class="new-default-black-font font-weight-normal m-0">
									Conta: {{ bankInfo.account }}-{{ bankInfo.account_digit }}
								</h6>
							</div>
						</div>
						<div class="row mt-2">
							<div class="col-12 px-0">
								<h4 class="new-default-black-font font-weight-normal m-0">
									{{ bankStatement.description }}
								</h4>
								<h4 class="new-default-black-font font-weight-normal m-0">
									{{ bankStatement.date_at | parseDate('DD/MM/YYYY - ddd') }}
								</h4>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-6 px-0 mt-2 mt-lg-0">
					<div class="reconciliation-results">
						<div class="card-without-shadow ml-0 mx-lg-2">
							<img src="/img/icons/merchant-account.png" width="20">
							<h4 class="new-default-gray-font font-weight-normal m-0 mt-1">
								Extrato
							</h4>
							<h3 class="text-primary m-0 mt-1" style="display: inline-flex;align-items: baseline;">
								<p style="font-size: 12px;" class="m-0 mr-1">R$</p>
								{{ Math.abs(bankStatement.value) | currency('') }}
							</h3>
						</div>
						<div class="card-without-shadow mx-2">
							<img src="/img/icons/icons8/ios/checked-checkbox--v1.png" width="20">
							<h4 class="new-default-gray-font font-weight-normal m-0 mt-1">
								Selecionado
							</h4>
							<h3 class="m-0 mt-1"
                :class="total != bankStatement.value ? 'new-default-gray-font' : 'text-success'"
                style="display: inline-flex;align-items: baseline;">
								<p style="font-size: 12px;" class="m-0 mr-1">R$</p>
								{{ total | currency('') }}
							</h3>
						</div>
					</div>
				</div>
				<div class="offset-4 col-8 mt-4 mt-xl-0 offset-xl-0 col-xl-3 px-lg-0">
					<div class="container">
						<div class="row align-items-center">
							<div class="footer-actions">
			          <base-button block
                  @click.prevent="$emit('closeModal')"
			          	size="sm"
			          	class="colorize-on-hover my-0 mx-1 p-2"
			          	type="danger"
			          	outline>
			        		<img src="/img/icons/icons8/ios/cancel_danger.png" width="16">
			        		Cancelar
			          </base-button>
			          <base-button block
                  @click.prevent="$emit('finishConciliate')"
			          	size="sm"
                  :disabled="isExtractAndSelectedValueDifferent()"
			          	class="colorize-on-hover my-0 mx-1 p-2"
			          	type="primary"
			          	outline>
			        		<img src="/img/icons/icons8/ios/link_primary.png" width="16">
			        		Conciliar
			          </base-button>
		          </div>
	          </div>
          </div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "Footer",
	props: {
  	total: {
  		type: Number
  	},
  	bankStatement: {
  		type: Object
  	},
  	bankInfo: {
  		type: Object
  	},
	},
	components: {
	},
	data(){
		return {}
	},
	methods: {
		/**
		 * Verifica se valor do extrato e valores selecionados são diferentes
		 * @returns {boolean}
		 */
		isExtractAndSelectedValueDifferent() {
			const totalFixed = Math.abs(this.total).toFixed(2);
			const bankStatementValueFixed = Math.abs(parseFloat(this.bankStatement.value)).toFixed(2);
			return totalFixed != bankStatementValueFixed;
		}
	},
}
</script>
<style scoped>
.custom-card-reconciliation {
	border-radius: 8px;
	border: 1px solid #E8E8E8;
	padding: 15px 15px;
	box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.07) !important;
}

.reconciliation-results {
	display: flex;
	align-items: center;
}

div.card-without-shadow {
	border: 1px solid #E8E8E8;
	padding: 15px 26px 15px 20px;
	border-radius: 8px;
}

.card-without-shadow img, .card-without-shadow h4 {
	display: block;
}

.footer-actions {
	display: flex;
	width: 100%;
}

.colorize-on-hover:hover small, .colorize-on-hover:hover img {
 	filter: brightness(0) invert(1);
}

.colorize-on-hover:disabled:hover, .colorize-on-hover:disabled:hover img{
  filter: brightness(1);
}
</style>
