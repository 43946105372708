<template>
  <div class="p-4 shadow-lg card mt-2 col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-1">
            <img src="/img/icons/bank-building.png" width="25px" height="25px">
          </div>
          <div class="col">
            <h4 class="m-0 pl-2" style="font-size: 16px;">PIX</h4>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="justify-content: space-around;">
      <div class="col-md-5 p-2"
        style="flex-direction: column;justify-content: center;align-items: center;text-wrap: nowrap;">
        <img :src="bill.qrcode" class="shadow-lg card mb-2">
        <a class="small ml-2 mt-6" href="#" @click="copyCopyAndPaste()">Código copia e cola <img src="/img/icons/copy-2.png"
            width="25px" height="25px"></a>
      </div>
      <div class="col-md-6 p-2">
        <h4>INSTITUIÇÃO BANCÁRIA</h4>
        <table style="width: 100%">
          <tr>
            <td><span class="small">BANCO</span></td>
            <td><span class="small">{{ bill.banco }}</span></td>
          </tr>
          <tr>
            <td><span class="small">AGÊNCIA</span></td>
            <td><span class="small">{{ bill.agencia }}</span></td>
          </tr>
          <tr>
            <td><span class="small">CONTA CORRENTE</span></td>
            <td><span class="small">{{ bill.conta }}</span></td>
          </tr>
        </table>
        <br />
        <table style="width:100%">
          <tr>
            <td>VALOR A SER PAGO</td>
          </tr>
          <tr>
            <td>
              <h1 class="new-default-black-font" style="display: inline;">{{ Number(bill.value).toLocaleString('pt-BR',
                { style: 'currency', currency: 'BRL' }) }}</h1>
            </td>
          </tr>
          <tr>
            <span class="small new-default-black-font">{{ new Date(bill.due_date).toLocaleDateString('pt-BR') }}</span>
          </tr>
        </table>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "QrcodeModal",
  props: ['bill'],
  methods: {
    // Copiar o código "copia e cola"
    async copyCopyAndPaste() {
      await navigator.clipboard.writeText(this.bill.copy_and_paste);
      this.$notify({
        type: "success",
        message: "Código copiado",
      });
    }
  }
}
</script>
