<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title mb-0">{{ modal.title }}</h5>
      </template>
      <div v-if="item" class="p-2">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(update)" autocomplete="off">
            <!-- Tipo -->
            <div>
              <div class="form-group row m-1 p-0">
                <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">Tipo </label>
                <div class="col-md-8">
                  <base-button block size="sm" type="primary">
                    {{ item.type_name || 'Tipo não informado' }}
                  </base-button>
                </div>
              </div>
            </div>
            <!-- Descrição -->
            <div class="form-group row m-1 p-0">
              <label class="col-md-4 pb-0 pt-2 mb-1 col-form-label form-control-label"> Descrição </label>
              <div class="col-md-8">
                <base-input input-group-classes="input-group-sm">
                  <input
                    disabled
                    :value="`${item.product_service_hub.product_name} (${item.product_service_hub.category_name || 'Categoria não informada'})`"
                    class="form-control form-control-sm"
                  />
                </base-input>
              </div>
            </div>
            <!-- CEST -->
            <div class="form-group row m-1 p-0">
              <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">
                CEST
              </label>
              <div class="col-md-8">
                <base-input input-group-classes="input-group-sm">
                  <input
                    v-model="item.cest"
                    class="form-control form-control-sm"
                    maxlength="9"
                  />
                </base-input>
              </div>
            </div>
            <!-- Outras Despesas -->
            <div>
              <div class="form-group row m-1 p-0">
                <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">
                  Outras Despesas
                </label>
                <div class="col-md-8">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-money="money"
                      v-model.lazy="item.other_expenses"
                      maxlength="13"
                      inputmode="numeric"
                      class="form-control form-control-sm"
                    />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0"> R$ </small>
                    </template>
                  </base-input>
                </div>
              </div>
            </div>
            <!-- Base de Cálculo do ICMS -->
            <div>
              <div class="form-group row m-1 p-0">
                <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">
                  Base do ICMS
                </label>
                <div class="col-md-8">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-money="money"
                      v-model.lazy="item.icms_bc"
                      maxlength="13"
                      inputmode="numeric"
                      class="form-control form-control-sm"
                    />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0"> R$ </small>
                    </template>
                  </base-input>
                </div>
              </div>
            </div>
            <div v-if="$helper.strToNum(item.icms_bc) > 0">
              <!-- Alíq do ICMS -->
              <div class="form-group row m-1 p-0">
                <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">
                  Alíq. ICMS
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-money="money"
                        v-model.lazy="item.icms_aliq"
                        maxlength="13"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> % </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Valor do ICMS -->
              <div class="form-group row m-1 p-0">
                <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">
                  Valor do ICMS
                </label>
                <div class="col-md-8">
                  <base-input input-group-classes="input-group-sm">
                    <input disabled v-model="icms_value" class="form-control form-control-sm" />
                  </base-input>
                </div>
              </div>
              <hr class="m-2 mx-4" />
            </div>
            <!-- Base de Cálculo do PIS -->
            <div>
              <div class="form-group row m-1 p-0">
                <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">
                  Base do PIS
                </label>
                <div class="col-md-8">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-money="money"
                      v-model.lazy="item.pis_bc"
                      maxlength="13"
                      inputmode="numeric"
                      class="form-control form-control-sm"
                    />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0"> R$ </small>
                    </template>
                  </base-input>
                </div>
              </div>
            </div>
            <div v-if="$helper.strToNum(item.pis_bc) > 0">
              <!-- Alíq do PIS -->
              <div class="form-group row m-1 p-0">
                <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">
                  Alíq. PIS
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8">
                  <validation-provider>
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-money="money"
                        v-model.lazy="item.pis_aliq"
                        maxlength="13"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> % </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Valor do PIS -->
              <div class="form-group row m-1 p-0">
                <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">
                  Valor do PIS
                </label>
                <div class="col-md-8">
                  <base-input input-group-classes="input-group-sm">
                    <input disabled v-model="pis_value" class="form-control form-control-sm" />
                  </base-input>
                </div>
              </div>
              <hr class="m-2 mx-4" />
            </div>
            <!-- Base de Cálculo do COFINS -->
            <div>
              <div class="form-group row m-1 p-0">
                <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">
                  Base do COFINS
                </label>
                <div class="col-md-8">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-money="money"
                      v-model.lazy="item.cofins_bc"
                      maxlength="13"
                      inputmode="numeric"
                      class="form-control form-control-sm"
                    />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0"> R$ </small>
                    </template>
                  </base-input>
                </div>
              </div>
            </div>
            <div v-if="$helper.strToNum(item.cofins_bc) > 0">
              <!-- Alíq do COFINS -->
              <div class="form-group row m-1 p-0">
                <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">
                  Alíq. COFINS
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8">
                  <validation-provider>
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-money="money"
                        v-model.lazy="item.cofins_aliq"
                        maxlength="13"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> % </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Valor do COFINS -->
              <div class="form-group row m-1 p-0">
                <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">
                  Valor do COFINS
                </label>
                <div class="col-md-8">
                  <base-input input-group-classes="input-group-sm">
                    <input disabled v-model="cofins_value" class="form-control form-control-sm" />
                  </base-input>
                </div>
              </div>
              <hr class="m-2 mx-4" />
            </div>
            <!-- Base de Cálculo do IPI -->
            <div>
              <div class="form-group row m-1 p-0">
                <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">
                  Base do IPI
                </label>
                <div class="col-md-8">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-money="money"
                      v-model.lazy="item.ipi_bc"
                      maxlength="13"
                      inputmode="numeric"
                      class="form-control form-control-sm"
                    />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0"> R$ </small>
                    </template>
                  </base-input>
                </div>
              </div>
            </div>
            <div v-if="$helper.strToNum(item.ipi_bc) > 0">
              <!-- Alíq do IPI -->
              <div class="form-group row m-1 p-0">
                <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">
                  Alíq. IPI
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8">
                  <validation-provider>
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-money="money"
                        v-model.lazy="item.ipi_aliq"
                        maxlength="13"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> % </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Valor do IPI -->
              <div class="form-group row m-1 p-0">
                <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">
                  Valor do IPI
                </label>
                <div class="col-md-8">
                  <base-input input-group-classes="input-group-sm">
                    <input disabled v-model="ipi_value" class="form-control form-control-sm" />
                  </base-input>
                </div>
              </div>
              <hr class="m-2 mx-4" />
            </div>
            <!-- Grupo de Combustível -->
            <div class="form-group row m-1 p-0">
              <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">
                Grupo de Combustível
              </label>
              <div class="col-md-8">
                <base-input input-group-classes="input-group-sm">
                  <base-switch
                    v-model="item.is_fuel_group"
                    type="success"
                    offText="NÃO"
                    onText="SIM"
                    class="success mt-3"
                  ></base-switch>
                </base-input>
              </div>
            </div>
            <!-- CÓD. ANP -->
            <div v-if="item.is_fuel_group">
              <div class="form-group row m-1 p-0">
                <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">
                  CÓD. ANP
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model="item.anp_code"
                        class="form-control form-control-sm"
                        maxlength="9"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-1 p-0">
                <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">
                  DESC. ANP
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model="item.anp_description"
                        class="form-control form-control-sm"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-1 p-0">
                <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">
                  UF DE CONSUMO
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <puzl-select
                        v-model="item.consumer_uf"
                        :items="$_states"
                        :loading="loadingState"
                        :disabled="loadingState"
                        customKey="letter"
                        label="letter"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
            </div>
            <div class="modal-footer d-flex justify-content-center">
              <base-button type="secondary" @click="closeModal()"> Cancelar </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { VMoney } from "v-money";
import {mapGetters} from "vuex";
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "ModalItemTaxRules",
  components: { PuzlSelect },
  directives: { money: VMoney },
  data() {
    return {
      modal: {
        title: "Editar Regras Fiscais",
        create: false,
      },
      item: null,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
    };
  },
  mounted() {
    this.$store.dispatch('localizations/fetchStates').then(() => {
      this.loadingState = false
    })
  },
  computed: {
    ...mapGetters({
      $_states: 'localizations/states',
    }),
    icms_value() {
      const icmsBc = this.$helper.strToNum(this.item.icms_bc);
      const icmsAliq = this.$helper.strToNum(this.item.icms_aliq);
      const icmsValue = icmsBc * (icmsAliq/100);
      return `${icmsValue.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}`;
    },
    pis_value() {
      const pisBc = this.$helper.strToNum(this.item.pis_bc);
      const pisAliq = this.$helper.strToNum(this.item.pis_aliq);
      const pisValue = pisBc * (pisAliq/100);
      return `${pisValue.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}`;
    },
    cofins_value() {
      const cofinsBc = this.$helper.strToNum(this.item.cofins_bc);
      const cofinsAliq = this.$helper.strToNum(this.item.cofins_aliq);
      const cofinsValue = cofinsBc * (cofinsAliq/100);
      return `${cofinsValue.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}`;
    },
    ipi_value() {
      const ipiBc = this.$helper.strToNum(this.item.ipi_bc);
      const ipiAliq = this.$helper.strToNum(this.item.ipi_aliq);
      const ipiValue = ipiBc * (ipiAliq/100);
      return `${ipiValue.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}`;
    },
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal(item) {
      this.item = item;
      this.modal.create = true;
    },
    update() {
      this.closeModal();
    },
  },
};
</script>

<style scoped>
.modal {
  overflow-y: scroll !important;
}
</style>
