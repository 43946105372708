<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(handleCommissionUpdate)"
            autocomplete="off"
          >
            <div>
              <div
                class="card-header border-header-success-bottom p-2 grid-col--fixed-left"
              >
                <h5 class="mb-0" slot="title">Identificação</h5>
              </div>
              <!-- Status -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                  Status
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 mb-1 pr-4 pt-2">
                  <base-switch type="success" offText="inativo" onText="ativo" class="success"
                                v-model="commission.status"></base-switch>
                </div>
              </div>
              <!-- Nome -->
              <div class="form-group row m-0 p-0 mt-n2 mb-1">
                <label
                  class="col-md-4 pb-0 mb-1 col-form-label form-control-label pt-3"
                >
                  Nome
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 mb-1 pr-4 pt-2">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model="commission.name"
                        type="text"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Descrição -->
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-4 pb-0 mb-1 col-form-label form-control-label pt-1"
                >
                  Descrição
                </label>
                <div class="col-md-8 mb-1 pr-4">
                  <validation-provider>
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model="commission.description"
                        type="text"
                        class="form-control form-control-sm"
                        maxlength="250"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Tipo de comissionamento -->
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-4 pb-0 mb-1 col-form-label form-control-label pt-0"
                >
                  Tipo de comissionamento
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 mb-1 pr-4">
                  <validation-provider rules="required" v-slot="{errors}">
                    <puzl-select
                      v-model="commission.commissioning_type_uuid"
                      customKey="uuid"
                      :items="commissioningTypes" />
                  </validation-provider>
                </div>
              </div>

              <!-- Considerar adicionais -->
              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-4 pb-0 mb-1 col-form-label form-control-label pt-1"
                >
                  Considerar adicionais
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 mb-1 pb-1">
                  <base-switch
                    v-model="status"
                    type="success"
                    offText="não"
                    onText="sim"
                    class="success"
                  ></base-switch>
                </div>
              </div>

              <!-- Serviços -->
              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-4 pb-0 mb-2 col-form-label form-control-label pt-1"
                >
                  Serviços
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 mb-2 pr-4">
                  <validation-provider rules="required" v-slot="{errors}">
                    <puzl-select
                      v-model="commission.services_uuid"
                      :items="services"
                      customKey="uuid"
                      label="service_name"
                      :multiple="true" />
                  </validation-provider>
                </div>
              </div>
              <div
                class="card-header border-header-warning-bottom p-2 grid-col--fixed-left"
              >
                <h5 class="mb-0" slot="title">
                  Parâmetros sobre o preço mínimo de venda
                </h5>
              </div>

              <!-- Parâmetros -->
              <div class="form-group row m-0 p-0">
                <div class="col-md-10 pr-0 pl-0">
                  <div class="form-group row m-0 p-0 ">
                    <label
                      class="col-md-6 pb-0 col-form-label form-control-label mb-1 pt-3"
                    >
                      Maior que
                    </label>
                    <div class="col-md-5 pt-2">
                      <validation-provider>
                        <base-input input-group-classes="input-group-sm">
                          <input
                            inputmode="numeric"
                            maxlength="4"
                            v-model="currentParameter.from"
                            class="form-control form-control-sm"
                            v-on:input="
                              currentParameter.from = formatString(
                                $event.target.value,
                                '-999',
                                '999'
                              )
                            "
                          />
                          <template slot="append">
                            <small class="input-group-sm p-0 m-0">
                              %
                            </small>
                          </template>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <!-- Menor ou igual a -->
                  <div class="row m-0 p-0">
                    <label
                      class="col-md-6 pb-0 col-form-label form-control-label mb-1 pt-2"
                    >
                      Menor ou igual a
                    </label>
                    <div class="col-md-5 pb-0">
                      <validation-provider>
                        <base-input input-group-classes="input-group-sm">
                          <input
                            inputmode="numeric"
                            maxlength="4"
                            v-model="currentParameter.until"
                            class="form-control form-control-sm"
                            v-on:input="
                              currentParameter.until = formatString(
                                $event.target.value,
                                '-999',
                                '999'
                              )
                            "
                          />
                          <template slot="append">
                            <small class="input-group-sm p-0 m-0">
                              %
                            </small>
                          </template>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <!-- Comissão -->
                  <div class="row m-0 p-0">
                    <label
                      class="col-md-6 pb-0 col-form-label form-control-label mb-1 pt-2"
                    >
                      Comissão
                    </label>
                    <div class="col-md-5 pb-3">
                      <validation-provider>
                        <base-input input-group-classes="input-group-sm">
                          <input
                            inputmode="numeric"
                            maxlength="6"
                            v-model="currentParameter.commission"
                            class="form-control form-control-sm"
                            v-on:input="
                              currentParameter.commission = formatString(
                                $event.target.value,
                                '0',
                                '999.9',
                                1
                              )
                            "
                          />
                          <template slot="append">
                            <small class="input-group-sm p-0 m-0">
                              %
                            </small>
                          </template>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-2 d-flex justify-content-center align-items-center"
                >
                  <base-button
                    :disabled="
                      !currentParameter.from ||
                        !currentParameter.until ||
                        !currentParameter.commission
                    "
                    @click="addParameter"
                    type="success"
                    native-type="button"
                    class="btn btn-sm align-items-center mr-2"
                    block
                  >
                    <i class="fas fa-plus"></i>
                  </base-button>
                </div>
              </div>
              <!-- lista de dosagens -->
              <div
                class="m-2 mt-3 mb-1"
                v-for="(parameter, index) in commission.parameters"
                :key="index"
              >
                <card
                  class="border card--hover card-body-molde mb-0"
                  :bodyClasses="'p-2'"
                >
                  <div class="row align-items-center">
                    <div class="col-10 text-center pl-2 pr-2">
                      <h6 class="mb-0">
                        Maior que {{ parameter.from }}%, menor ou igual a
                        {{ parameter.until }}% e comissão de
                        {{ parameter.commission }}%
                      </h6>
                    </div>
                    <div class="col-2 text-center pl-1">
                      <button
                        type="button"
                        class="btn btn-sm rounded-circle btn-danger"
                        @click="removeParameter(index)"
                      >
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                </card>
              </div>
              <div class="modal-footer">
                <base-button type="secondary" @click="closeModal('create')">
                  Cancelar
                </base-button>
                <base-button
                  type="success"
                  native-type="submit"
                  v-bind:disabled="invalid"
                  :loading="loadingStore"
                >
                  Salvar
                </base-button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mask1, formatErrorValidation } from "@/plugins";
import { checkNumberValue } from "@/helpers";
import PuzlSelect from "@/components/PuzlSelect";
export default {
  name: "EditCommission",
  components: { PuzlSelect },
  data() {
    return {
      modal: {
        title: "Comissão",
        create: false
      },
      status: true,
      commission: {
        status: null,
        name: null,
        commissioning_type_uuid: null,
        additional: null,
        parameters: [],
        description: null,
        services_uuid: []
      },
      currentParameter: {
        from: null,
        until: null,
        commission: null
      },
      loadingStore: false
    };
  },
  computed: {
    ...mapGetters({
      commissions: "commission/fetch",
      commissioningTypes: "commissioningType/fetch",
      services: "service/activeItems"
    })
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    addParameter() {
      let parameter = {};
      parameter.from = this.currentParameter.from;
      parameter.until = this.currentParameter.until;
      parameter.commission = this.currentParameter.commission;
      this.commission.parameters.push(parameter);
      this.currentParameter = {
        from: null,
        until: null,
        commission: null
      };
    },
    removeParameter(index) {
      this.commission.parameters.splice(index, 1);
    },
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        return checkNumberValue(
          mask1(value.toString(), decimals, ".", true),
          min,
          max
        );
      }
      return checkNumberValue(value, min, max);
    },
    handleEditModal(uuid) {
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.$Progress.start();
      this.currentParameter = {
        from: null,
        until: null,
        commission: null
      };
      this.commission = {};
      this.$store
        .dispatch("commission/show", uuid)
        .then(response => {
          this.status = response.data.additional === 1 ? true : false;
          this.commission = {
            uuid: response.data.uuid || "",
            status: response.data.status,
            name: response.data.name || "",
            commissioning_type_uuid:
              response.data.commissioning_type.uuid || "",
            parameters: response.data.parameters || "",
            description: response.data.description || "",
            services_uuid: []
          };
          var self = this;
          response.data.services.forEach(function(obj, index) {
            self.commission.services_uuid.push(obj.uuid);
          });
          this.$Progress.finish();
          this.modal.create = true;
          this.$notify({
            type: response.error_type,
            message: response.message
          });
        })
        .catch(error => {
          if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors
            });
          }
          this.$Progress.finish();
          this.loadingStore = false;
        });
    },
    handleCommissionUpdate() {
      this.$Progress.start();
      this.loadingStore = true;
      if (this.status) {
        this.commission.additional = 1;
      } else {
        this.commission.additional = 0;
      }
      this.$store
        .dispatch("commission/update", this.commission)
        .then(response => {
          this.loadingStore = false;
          this.modal.create = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
        })
        .catch(error => {
          if (error.status == 200) {
            this.$notify({
              type: "danger",
              message: error.data.message
            });
            this.$Progress.finish();
            this.loadingStore = false;
          } else if (error.response && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors
            });
          }
          this.$Progress.finish();
          this.loadingStore = false;
        });
    }
  },
  mounted() {
    this.$refs.formValidator.validate();
    this.$store.dispatch("commissioningType/fetchItems");
    this.$store.dispatch("service/fetchServices");
  }
};
</script>

<style scoped></style>
