import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import Report from '@/views/Modules/Totvs/Report/Index.vue';
import auth from "@/middleware/auth";

const totvs = {
  path: '/totvs',
  component: DashboardLayout,
  name: 'Integrações',
  meta: {
    middleware: auth,
  },
  children: [
    {
      path: '/totvs/report',
      name: 'Integrações',
      component: Report,
      meta: {
        title: 'Totvs',
        description: 'Integrações',
        icon: 'fa fa-home',
        permission: 'additional_permissions.totvs-integrations.index.view',
        breadcrumbs: [
          {title: 'Dashboard', name: 'totvs.dashboard.index'},
          {title: 'Integrações', name: null},
        ]
      },
    },
  ]
};

export default totvs;
