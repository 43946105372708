<template>
  <div>
    <!-- Header -->
    <div class="header py-2">

    </div>
    <!-- Page content -->
    <div class="container pt-lg-8">
      <div class="row justify-content-center">
        <div class="col-lg-8 col-md-7">
          <div class="card bg-secondary border-0 mb-0">

            <div class="card-body px-lg-5">
              <div class="text-center text-muted">
                <img src="/img/brand/puzl-mix.jpeg" width="210px">
              </div>
              <br>
              <br>
              <span style="font-size: 1.2rem"
                    class="display-4 mt-4">Para alterar a senha entre em contato com o gestor da sua empresa.</span>

              <!--              <div class="text-center my-3">Esqueci minha senha</div>-->
<!--              <h4 class="text-center mt-2 mb-0 small">-->
<!--                <span :class="this.flag ? 'text-success' : 'text-danger'"> {{message}} </span>-->
<!--              </h4>-->
<!--              <validation-observer v-slot="{handleSubmit}" ref="formValidator">-->
<!--                <form role="form" @submit.prevent="handleSubmit(onSubmit)">-->
<!--                  <base-input alternative-->
<!--                              class="mb-3 m-3"-->
<!--                              name="Email"-->
<!--                              :rules="{required: true, email: true}"-->
<!--                              prepend-icon="ni ni-email-83"-->
<!--                              placeholder="Email"-->
<!--                              v-model="form.email">-->
<!--                  </base-input>-->

<!--                  <div class="text-center">-->
<!--                    <base-button type="primary" v-show="loading" :loading="loading">Carregando</base-button>-->
<!--                    <base-button type="primary" native-type="submit" v-show="!loading">Recuperar Senha</base-button>-->
<!--                  </div>-->
                  <div class="text-center my-3">
                    <router-link :to="{
                    path:'/login',
                    name:'Login',}" class="text-dark">
                      <div class="text-center mt-1 text-primary">
                        <span style="font-size: 0.9rem">Retornar ao login</span>
                      </div>                    </router-link>
                  </div>
<!--                </form>-->
<!--              </validation-observer>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        form: {
          email: '',
        },
        message:'',
        loading:false,
        flag: false,
      };
    },
    methods: {
      onSubmit () {
        this.loading = true
        this.$store.dispatch('auth/forgotPassword', this.form)
          .then(response => {
            this.flag = true
            this.loading = false,
            this.message='Enviamos um e-mail com instruções de recuperação de senha.'
        }).catch(response =>{
          this.flag = false
          this.loading = false
          this.message='Ops! Erro ao enviar e-mail de recuperação, ou o usuário não existe.'
        })
      },
    }
  };
</script>
