export const SET = 'SET'
export const SET_WIDGETS = 'SET_WIDGETS'
export const SET_COMPANY_PLANT_RESUME = 'SET_COMPANY_PLANT_RESUME'
export const CLEAR_RESUME = 'CLEAR_RESUME'
export const CLEAR_RESUME_DETAILS = 'CLEAR_RESUME_DETAILS'
export const SET_RESUME_DETAILS = 'SET_RESUME_DETAILS'
export const PUSH = 'PUSH'
export const SHOW = 'SHOW'
export const DESTROY = 'DESTROY'
export const UPDATE = 'UPDATE'
export const SHOW_MCC = 'SHOW_MCC'
export const SHOW_ADJUSTMENT = 'SHOW_ADJUSTMENT'
export const SET_MIX_CODES = 'SET_MIX_CODES'
export const SET_CONTRACT_PROPOSALS = 'SET_CONTRACT_PROPOSALS'
export const SET_SCHEDULE_RESUME = 'SET_SCHEDULE_RESUME'
export const SET_HEADERS = 'SET_HEADERS'
export const SET_ITEMS = 'SET_ITEMS'

