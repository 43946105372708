<template>
  <div>
    <base-header class="bg-gray-content card-row">
      <div class="row pt-0" style="margin-top: -0.5rem">
        <div class="col-md-12 p-1">
          <multi-filter-v2
            :without_filter="true"
            @clearFilters="clearFilters"
            @fetch="fetchItemsPaginate"
            :filter="filter"
        >
            <div class="col-md-3 p-2">
              <input-date-picker :filterable="4" size="small" ref="date-picker" @handleFilterDate="handleFilterDate" :default-range="defaultRange" />
            </div>
            <div class="col-md-3 p-2 custom-dashboard">
              <puzl-select
                v-model="selectedCompanyPlants"
                :items="[{ id: 'all', name: 'Todos' }, ...$_plants]"
                customKey="id"
                :multiple="true"
                class="select-lg"
                :loading="loadingCompanyPlants"
                :disabled="loadingCompanyPlants"
                @input="handleFilterCompanyPlants"
                placeholder="Central"
              />
            </div>
          </multi-filter-v2>
        </div>
      </div>
      <div class="row align-items-center card-row">
        <div class="col-md-2 col-sm-6 p-1">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-8" style="margin-top: -10px">
                  <p 
                    style="font-family: FreeMono, monospace;"
                    class="mb-0 text-sm text-uppercase font-weight-bold ml-n3"
                  >
                    <small class="font-weight-bold">Volume total</small>
                  </p>
                </div>
                <div class="col-4" style="margin-top: -5px;">
                  <div class="text-center icon icon-shape float-right mr-n2">
                    <i class="fa-solid fa-chart-line text-success" style="font-size: 28px;"></i>
                  </div>
                </div>
                <div class="col-md-12" style="margin-top: -25px">
                  <h3 class="font-weight-semibold mb-0 pl-2 ml-n3" style="font-family: 'Gill Sans', sans-serif;">
                    <span v-if="loadingTotals"><SpinnerPuzl/></span>
                    <div v-if="total && !loadingTotals" class="numeric">
                      {{ total['total_volume_travels'] || 0 }}
                      <small v-show="total['total_volume_travels']"><span class="text-dark">M3</span> </small>
                    </div>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets-sm col-md-2 col-sm-6 p-1">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-9" style="margin-top: -10px">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold ml-n3">
                    <small class="font-weight-bold">Série moldada</small>
                  </p>
                </div>
                <div class="col-3" style="margin-top: -5px">
                  <div class="text-center shadow icon icon-shape float-right mr-n2">
                    <i class="fa-solid fa-arrow-right-arrow-left" style="font-size: 28px;"></i>
                  </div>
                </div>
                <div class="col-md-12" style="margin-top: -25px">
                  <h3 class="font-weight-semibold mb-0 pl-2 ml-n3" style="font-family: 'Gill Sans', sans-serif;">
                    <span v-if="loadingTotals"><SpinnerPuzl/></span>
                    <div v-if="total && !loadingTotals" class="numeric">
                      {{ total['total_molded_series'] || 0 }}
                    </div>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets-sm col-md-2 col-sm-6 p-1">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-8" style="margin-top: -10px">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold ml-n3">
                    <small class="font-weight-bold">Íncice M3/série</small>
                  </p>
                </div>
                <div class="col-4" style="margin-top: -5px">
                  <div class="text-center shadow icon icon-shape float-right mr-n2">
                    <i class="fa-solid fa-arrow-up-right-dots text-warning" style="font-size: 28px;"></i>
                  </div>
                </div>
                <div class="col-md-12 " style="margin-top: -25px">
                  <h3 class="font-weight-semibold mb-0 pl-2 ml-n3" style="font-family: 'Gill Sans', sans-serif;">
                      <span v-if="loadingTotals"><SpinnerPuzl/></span>
                      <div v-if="total && !loadingTotals" class="numeric">
                        {{ total['m3_serie'] ? parseFloat(total['m3_serie']).toFixed(2) : 0 }}
                      </div>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets-sm col-md-2 col-sm-6 p-1">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-8" style="margin-top: -10px">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold ml-n3">
                    <small class="font-weight-bold">Cp moldado</small></p>
                </div>
                <div class="col-4" style="margin-top: -5px">
                  <div class="text-center shadow icon icon-shape float-right mr-n2">
                    <i class="fa-solid fa-cube text-primary" style="font-size: 28px;"></i>
                  </div>
                </div>
                <div class="col-md-12" style="margin-top: -25px">
                  <h3 class="font-weight-semibold mb-0 pl-2 ml-n3" style="font-family: 'Gill Sans', sans-serif;">
                    <span v-if="loadingTotals"><SpinnerPuzl/></span>
                    <div v-if="total && !loadingTotals" class="numeric">
                      {{ total['cp_molded'] ? total['cp_molded'] : 0 }}
                    </div>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets-sm col-md-2 col-sm-6 p-1">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-8" style="margin-top: -10px">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold ml-n3">
                    <small class="font-weight-bold">Cp rompido</small>
                  </p>
                </div>
                <div class="col-4" style="margin-top: -5px">
                  <div class="text-center shadow icon icon-shape float-right mr-n2">
                    <i class="fa-solid fa-cube text-success" style="font-size: 28px;"></i>
                  </div>
                </div>
                <div class="col-md-12" style="margin-top: -25px">
                  <h3 class="font-weight-semibold mb-0 pl-2 ml-n3" style="font-family: 'Gill Sans', sans-serif;">
                    <span v-if="loadingTotals"><SpinnerPuzl/></span>
                    <div v-if="total && !loadingTotals" class="numeric">
                      {{ total['cp_broken'] || 0 }}
                    </div>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets-sm col-md-2 col-sm-6 p-1">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-9" style="margin-top: -10px">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold ml-n3">
                    <small class="font-weight-bold">Cp descartado</small>
                  </p>
                </div>
                <div class="col-3" style="margin-top: -5px">
                  <div class="text-center shadow icon icon-shape float-right mr-n2">
                    <i class="fa-solid fa-cube text-danger" style="font-size: 28px;"></i>
                  </div>
                </div>
                <div class="col-md-12 " style="margin-top: -25px">
                  <h3 class="font-weight-semibold mb-0 pl-2 ml-n3" style="font-family: 'Gill Sans', sans-serif;">
                    <span v-if="loadingTotals"><SpinnerPuzl/></span>
                    <div v-if="total && !loadingTotals" class="numeric">
                      {{ total['cp_discarded'] || 0 }}
                    </div>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Primeira tabela -->
      <div class="row pt-0 card-row">
        <div class="col-md-12 p-1">
          <div class="card">
            <div class="card-body mt-n2">
              <div class="row">
                <div class="col-md-3 mb-3">
                  <base-button
                    style="border-radius: 5px !important;"
                    title="Agrupamento por motorista e equipamento"
                    size="sm"
                    class="w-outline font-weight-500"
                    @click="setEquipment"
                  >
                    <i class="ni ni-delivery-fast text-green"></i>
                    EQUIPAMENTOS
                    <i style="font-size: 12px" v-if="!hasEquipment" class="far fa-square"></i>
                    <i style="font-size: 12px" v-else class="far fa-check-square"></i>
                  </base-button>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 table-responsive" style="overflow-y: scroll; max-height: 500px">
                  <table class="table table-sm table-bordered">
                    <thead>
                      <tr class="fixed">
                        <th class="text-center table-column-light-gray text-dark" style="width: 5px">Motorista</th>
                        <th class="text-center table-column-light-gray text-dark" style="width: 5px" v-if="hasEquipment">
                          Equipamento
                        </th>
                        <th class="text-center table-column-light-gray text-dark">Volume</th>
                        <th class="text-center table-column-light-gray text-dark" style="width: 5px">Viagens</th>
                        <th class="text-center table-column-light-gray text-dark" style="width: 5px">Série Moldada</th>
                        <th class="text-center table-column-light-gray text-dark" style="width: 5px">M3 / Série</th>
                        <th class="text-center table-column-light-gray text-dark" style="width: 5px">CP Moldado</th>
                        <th class="text-right table-column-light-gray text-dark" style="width: 18px">CP Rompido</th>
                        <th class="text-right table-column-light-gray text-dark" style="width: 18px">CP Descartado</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in 10" v-show="loadingMoldingResume">
                        <th colspan="12">
                          <SkeletonPuzl type="button" />
                        </th>
                      </tr>
                      <tr v-for="item in $_molding_resume">
                        <th class="text-left">
                          <span class="style-table table-content-font-size text-dark">{{ item.driver_name }}</span>
                        </th>
                        <th class="text-left" v-if="hasEquipment">
                          <span class="style-table table-content-font-size text-dark">{{ item.equipment_code }}</span>
                        </th>
                        <th class="text-center">
                          <span class="style-table table-content-font-size text-dark">{{ item.total_volume_travels }}</span>
                        </th>
                        <th class="text-center">
                          <span class="style-table table-content-font-size text-dark">{{ item.total_driver_travels }}</span>
                        </th>
                        <th class="text-center">
                          <span class="style-table table-content-font-size text-dark">{{ item.total_molded_series }}</span>
                        </th>
                        <th class="text-center">
                          <span class="style-table table-content-font-size text-dark">
                            {{ item.m3_serie ? parseFloat(item.m3_serie).toFixed(2) : 0}}
                          </span>
                        </th>
                        <th class="text-center">
                          <span class="style-table table-content-font-size text-dark"> {{ item.cp_molded || 0}} </span>
                        </th>
                        <th class="text-center">
                          <span class="style-table table-content-font-size text-dark">{{ item.cp_broken || 0 }} </span>
                        </th>
                        <th class="text-center">
                          <span class="style-table table-content-font-size text-dark"> {{ item.cp_discarded || 0 }} </span>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <pagination @navegate="navegate" :source="source" :loading="loadingMoldingResume" />
            </div>
          </div>
        </div>
      </div>

      <!-- Segunda tabela -->
      <div class="row pt-0 card-row">
        <div class="col-md-12 p-1">
          <div class="card">
            <div class="card-body mt-n2">
              <small class="text-dark"> Agrupado por contrato </small>
              <div class="row">
                <div class="col-md-12 table-responsive" style="overflow-y: scroll; max-height: 500px">
                  <table class="table table-sm table-bordered">
                    <thead>
                      <tr class="fixed">
                        <th class="text-center table-column-light-gray text-dark" style="width: 5px">Cliente</th>
                        <th class="text-center table-column-light-gray text-dark">Volume</th>
                        <th class="text-center table-column-light-gray text-dark" style="width: 5px">Viagens</th>
                        <th class="text-center table-column-light-gray text-dark" style="width: 5px">Série Moldada</th>
                        <th class="text-center table-column-light-gray text-dark" style="width: 5px">M3 / Série</th>
                        <th class="text-center table-column-light-gray text-dark" style="width: 5px">CP Moldado</th>
                        <th class="text-right table-column-light-gray text-dark" style="width: 18px">CP Rompido</th>
                        <th class="text-right table-column-light-gray text-dark" style="width: 18px">CP Descartado</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in 10" v-show="loadingMoldingResumeGroupByContract">
                        <th colspan="12">
                          <SkeletonPuzl type="button" />
                        </th>
                      </tr>
                      <tr v-for="item in $_molding_resume_group_by_contract">
                        <th class="text-left">
                          <span class="style-table table-content-font-size text-dark">
                            {{ item.contract_proposal_code }} | {{ item.customer_name }} | {{ item.construction_name }}
                          </span>
                        </th>
                        <th class="text-center">
                          <span class="style-table table-content-font-size text-dark">{{ item.total_volume_travels }}</span>
                        </th>
                        <th class="text-center">
                          <span class="style-table table-content-font-size text-dark">{{ item.total_driver_travels }}</span>
                        </th>
                        <th class="text-center">
                          <span class="style-table table-content-font-size text-dark">{{ item.total_molded_series }}</span>
                        </th>
                        <th class="text-center">
                          <span class="style-table table-content-font-size text-dark">
                            {{ item.m3_serie ? parseFloat(item.m3_serie).toFixed(2) : 0}}
                          </span>
                        </th>
                        <th class="text-center">
                          <span class="style-table table-content-font-size text-dark"> {{ item.cp_molded || 0}} </span>
                        </th>
                        <th class="text-center">
                          <span class="style-table table-content-font-size text-dark">{{ item.cp_broken || 0 }} </span>
                        </th>
                        <th class="text-center">
                          <span class="style-table table-content-font-size text-dark"> {{ item.cp_discarded || 0 }} </span>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <pagination
                @navegate="navegateGroupByContract"
                :source="sourceGroupByContract"
                :loading="loadingMoldingResumeGroupByContract" />
            </div>
          </div>
        </div>
      </div>
    </base-header>
  </div>
</template>

<script>
import MultiFilterV2 from "@/components/Utils/MultiFilterV2";
import { SkeletonPuzl } from "@/components";
import Pagination from "@/components/Utils/Pagination";
import InputDatePicker from "@/components/InputDatePicker";
import { mapGetters } from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
import moment from "moment";
import SpinnerPuzl from "@/components/SpinnerPuzl";

export default {
  components: {
    SkeletonPuzl,
    Pagination,
    MultiFilterV2,
    InputDatePicker,
    PuzlSelect,
    SpinnerPuzl,
  },
  name: "List",
  data() {
    return {
      title: "Resumo de Moldagem",
      loadingMoldingResume: true,
      loadingMoldingResumeGroupByContract: true,
      loadingCompanyPlants: true,
      loadingTotals: true,
      source: null,
      sourceGroupByContract: null,
      total: null,
      hasEquipment: null,
      params: {
        page: 1,
        per_page: 10,
      },
      paramsGroupByContract: {
        page: 1,
        per_page: 10,
      },
      filter: {},
      selectedCompanyPlants: [],
      selectAll: false,
      range: {
        start: null,
        end: null,
      },
      defaultRange: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      },
    };
  },
  computed: {
    ...mapGetters({
      $_molding_resume: "moldingResume/fetch",
      $_molding_resume_group_by_contract: "moldingResume/fetchGroupByContract",
      $_plants: "plant/activeItems",
    }),
  },
  mounted() {
    this.$store.dispatch('plant/fetchItems').then(() => {
        this.loadingCompanyPlants = false;
    });
    this.init();
  },
  methods: {
    init() {
      this.range = this.defaultRange;
      this.selectedCompanyPlants = [];
      this.fetchItemsPaginate();
      this.fetchItemsGroupByContractPaginate();
    },
    fetchItemsPaginate(filter = {}) {
      this.loadingMoldingResume = true;
      this.filter = filter;
      this.filter.range = this.range;
      if (this.hasEquipment) {
        this.filter.hasEquipment = this.hasEquipment;
      }
      this.countTotals();
      this.handleFilterPlants();
      this.$store
        .dispatch("moldingResume/fetchItemsPaginate", {
          filter: this.filter,
          page: this.params.page,
        })
        .then((response) => {
          this.source = response;
          this.loadingMoldingResume = false;
        })
        .catch(() => {
          this.loadingMoldingResume = false;
        });
    },
    fetchItemsGroupByContractPaginate(filter = {}) {
      this.loadingMoldingResumeGroupByContract = true;
      this.filter = filter;
      this.filter.range = this.range;
      this.handleFilterPlants();
      this.$store
        .dispatch("moldingResume/fetchGroupByContractItemsPaginate", {
          filter: this.filter,
          page: this.paramsGroupByContract.page,
        })
        .then((response) => {
          this.sourceGroupByContract = response;
          this.loadingMoldingResumeGroupByContract = false;
        })
        .catch(() => {
          this.loadingMoldingResumeGroupByContract = false;
        });
    },
    countTotals() {
      this.loadingTotals = true;
      this.handleFilterPlants();
      this.$store
        .dispatch("moldingResume/countTotalMoldingResume", {
          filter: this.filter,
        })
        .then((response) => {
          this.total = response.data;
          this.loadingTotals = false;
        })
    },
    handleFilterPlants() {
      this.filter.company_plants = !this.selectedCompanyPlants.includes("all") ? this.selectedCompanyPlants : [];
    },
    navegate(page) {
      this.params.page = page;
      this.fetchItemsPaginate();
    },
    navegateGroupByContract(page) {
      this.paramsGroupByContract.page = page;
      this.fetchItemsGroupByContractPaginate();
    },
    handleFilterCompanyPlants() {
      const index = this.selectedCompanyPlants.findIndex((item) => item == "all");
      if (this.selectedCompanyPlants.length > 1 && index === 0) {
        this.countTotals();
        return this.selectedCompanyPlants.splice(0, 1);
      }
      if (this.selectedCompanyPlants.length > 1 && this.selectedCompanyPlants.includes("all")) {
        this.selectedCompanyPlants = [];
        this.selectedCompanyPlants.push("all");
      }
      this.fetchItemsPaginate();
      this.fetchItemsGroupByContractPaginate();
    },
    handleFilterDate(filter) {
      this.range = filter;
      this.fetchItemsPaginate();
      this.fetchItemsGroupByContractPaginate();
    },
    clearFilters() {
      this.filter = {};
      this.selectedCompanyPlants = [];
      this.fetchItemsPaginate();
      this.fetchItemsGroupByContractPaginate();
    },
    setEquipment() {
      this.hasEquipment = !this.hasEquipment ? true : null;
      this.fetchItemsPaginate();
    },
  },
};
</script>

<style scoped>
.w-outline {
  background-color: white !important;
  color: #000000 !important;
}
.style-table {
  font-weight: normal;
  width: 15px;
}

.table-column-light-gray {
  background-color: #CCCCCC;
}
.font-size {
  font-size: 12px !important;
}
</style>
