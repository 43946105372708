export const baseTravelChart = {
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "cross",
      crossStyle: {
        color: "#999"
      }
    },
    formatter: function(value) {
      let text = "";
      value.map(function(obj) {
        let unit = "";
        /** Ler unidade de medida */
        switch (obj.seriesName) {
          case "Viagens":
            obj.data = parseInt(obj.data);
            break;
          default:
            unit = "m³";
        }
        text += `${obj.marker} ${obj.seriesName} <b> ${obj.data} ${unit}</b> <br/>`;
      });
      return text;
    }
  },

  toolbox: {
    showTitle: false,
    feature: {
      dataView: { show: true, readOnly: false },
      magicType: { show: true, type: ["line", "bar"] },
      restore: { show: true },
      saveAsImage: { show: true }
    }
  },
  xAxis: [
    {
      data: []
    }
  ],
  yAxis: [
    {
      type: "value",
      name: "Volume",
      minInterval: 1,
      min: 0,
      axisLabel: {
        formatter: "{value} m³"
      }
    },
    {
      type: "value",
      name: "Quantidade",
      min: 0,
      minInterval: 1,
      splitLine: {
        show: false
      },
      axisLabel: {
        formatter: "{value}"
      }
    }
  ],

  dataZoom: [
    {
      show: true,
      start: 0,
      end: 100
    }
  ],

  series: [
    {
      name: "Programado",
      type: "bar",
      data: [],
      itemStyle: {
        opacity: 1,
        decal: {
          symbol: "circle",
          symbolSize: 1,
          color: "rgba(0, 0, 0,0.0)"
        }
      }
    },
    {
      name: "Realizado",
      type: "bar",
      data: [],
      itemStyle: {
        decal: {
          symbol: "circle",
          symbolSize: 1,
          color: "rgba(0, 0, 0,0.0)"
        }
      }
    },
    {
      name: "Viagens",
      type: "line",
      yAxisIndex: 1,
      data: []
    }
  ],

  legend: {
    display: true,
    data: ["Programado", "Realizado", "Viagens"]
  }
};

export const baseCycleChart = {
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "cross",
      crossStyle: {
        color: "#999"
      }
    },
    formatter: function(value) {
      let text = "";
      value.map(function(obj) {
        let unit = "";
        /** Ler unidade de medida */
        switch (obj.seriesName) {
          case "Volume médio":
            unit = "m³";
            break;
          default:
            unit = "min";
        }
        text += `${obj.marker} ${obj.seriesName} <b> ${obj.data} ${unit}</b> <br/>`;
      });
      return text;
    }
  },
  toolbox: {
    showTitle: false,
    feature: {
      dataView: { show: true, readOnly: false },
      magicType: { show: true, type: ["line", "bar"] },
      restore: { show: true },
      saveAsImage: { show: true }
    }
  },
  xAxis: [
    {
      data: []
    }
  ],
  yAxis: [
    {
      type: "value",
      name: "Ciclo",
      min: 0,
      axisLabel: {
        formatter: "{value} min"
      }
    },
    {
      type: "value",
      name: "Volume médio",
      min: 0,
      max: 12,
      splitLine: {
        show: false
      },
      axisLabel: {
        formatter: "{value} m³"
      }
    }
  ],
  dataZoom: [
    {
      show: true,
      start: 0,
      end: 100
    }
  ],
  series: [
    {
      name: "Previsto",
      type: "bar",
      data: [],
      itemStyle: {
        opacity: 1,
        decal: {
          symbol: "circle",
          symbolSize: 1,
          color: "rgba(0, 0, 0,0.0)"
        }
      }
    },
    {
      name: "Realizado",
      type: "bar",
      data: [],
      itemStyle: {
        opacity: 1,
        decal: {
          symbol: "circle",
          symbolSize: 1,
          color: "rgba(0, 0, 0,0.0)"
        }
      }
    },
    {
      name: "Ociosidade",
      type: "bar",
      color: "#e94545",
      data: [],
      itemStyle: {
        opacity: 1,
        decal: {
          symbol: "circle",
          symbolSize: 1,
          color: "rgba(0, 0, 0,0.0)"
        }
      }
    },
    {
      name: "Concreto",
      type: "bar",
      data: [],
      itemStyle: {
        opacity: 1,
        decal: {
          symbol: "circle",
          symbolSize: 1,
          color: "rgba(0, 0, 0,0.0)"
        }
      },
      color: "#71bdff"
    },
    {
      name: "Volume médio",
      type: "line",
      yAxisIndex: 1,
      color: "Yellow",
      data: []
    }
  ],

  legend: {
    display: true,
    data: ["Previsto", "Realizado", "Ociosidade", "Concreto", "Volume médio"]
  }
};

export const baseMixedChart = {
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "cross",
      crossStyle: {
        color: "#00000"
      }
    },
    formatter: function(value) {
      let text = "";
      value.map(function(obj) {
        let unit = "";
        /** Ler unidade de medida */
        switch (obj.seriesName) {
          case "Volume":
            unit = "m³";
            break;
          case "Ciclo":
            unit = "min";
            break;
          case "Ociosidade":
            unit = "min";
            break;
          case "Viagens":
            obj.data = parseInt(obj.data);
            break;
        }
        text += `${obj.marker} ${obj.seriesName} <b> ${obj.data} ${unit}</b> <br/>`;
      });
      return text;
    }
  },

  toolbox: {
    showTitle: false,
    feature: {
      dataView: { show: true, readOnly: false },
      magicType: { show: true, type: ["line", "bar"] },
      restore: { show: true },
      saveAsImage: { show: true }
    }
  },
  xAxis: [
    {
      data: []
    }
  ],
  yAxis: [
    {
      type: "value",
      name: "Quantidade",
      min: 0,
      minInterval: 1,
      position: "left",
      axisLine: {
        show: true,
        lineStyle: {
          color: "#5470C6"
        }
      },
      axisLabel: {
        formatter: "{value}"
      }
    },
    {
      type: "value",
      name: "Volume",
      min: 0,
      minInterval: 1,
      position: "right",
      splitLine: {
        show: false
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: "#91CC75"
        }
      },
      axisLabel: {
        formatter: "{value} m³"
      }
    },
    {
      type: "value",
      name: "Tempo",
      min: 0,
      minInterval: 1,
      position: "right",
      offset: 65,
      splitLine: {
        show: false
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: "#fd3e3e"
        }
      },
      axisLabel: {
        formatter: "{value} min"
      }
    }
  ],

  dataZoom: [
    {
      show: true,
      start: 0,
      end: 100
    }
  ],

  series: [
    {
      name: "Volume",
      type: "bar",
      yAxisIndex: 1,
      data: [],
      color: "#91CC75",
      itemStyle: {
        opacity: 1,
        decal: {
          symbol: "circle",
          symbolSize: 1,
          color: "rgba(0, 0, 0,0.0)"
        }
      }
    },
    {
      name: "Viagens",
      type: "line",
      data: [],
      yAxisIndex: 0,
      itemStyle: {
        decal: {
          symbol: "circle",
          symbolSize: 1,
          color: "rgba(0, 0, 0,0.0)"
        }
      }
    },
    {
      name: "Ciclo",
      type: "bar",
      color: "#4992ff",
      yAxisIndex: 2,
      data: []
    },
    {
      name: "Ociosidade",
      color: "#fd3e3e",
      yAxisIndex: 2,
      type: "bar",
      data: []
    }
  ],

  legend: {
    display: true,
    data: ["Volume", "Viagens", "Ciclo", "Ociosidade"]
  }
};

export const changeTravelChart = (type, payload) => {
  let travelChartData = baseTravelChart;
  travelChartData.xAxis[0].data = payload.travels[type].map(a => a.name);
  travelChartData.series[0].data = payload.travels[type].map(a => a.programmed_volume);
  travelChartData.series[1].data = payload.travels[type].map(a => a.performed_volume);
  travelChartData.series[2].data = payload.travels[type].map(a => a.performed_travels);
  return travelChartData;
};

export const changeCycleChart = (type, payload) => {
  let cycleChartData = baseCycleChart;
  cycleChartData.xAxis[0].data = payload.cycles[type].map(a => a.name);
  cycleChartData.series[0].data = payload.cycles[type].map(a => a.predicted_travel_cycle);
  cycleChartData.series[1].data = payload.cycles[type].map(a => a.performed_travel_cycle);
  cycleChartData.series[2].data = payload.cycles[type].map(a => a.idleness);
  cycleChartData.series[3].data = payload.cycles[type].map(a => a.performed_concrete_cycle);
  cycleChartData.series[4].data = payload.cycles[type].map(a => a.average_volume);
  return cycleChartData;
};

export const changeMixedChart = (type, payload) => {
  let mixedChartData = baseMixedChart;
  mixedChartData.xAxis[0].data = payload.mixed[type].map(a => a.name);
  mixedChartData.series[0].data = payload.mixed[type].map(a => a.performed_volume);
  mixedChartData.series[1].data = payload.mixed[type].map(a => a.performed_travels);
  mixedChartData.series[2].data = payload.mixed[type].map(a => a.total_performed_travel_cycle);
  mixedChartData.series[3].data = payload.mixed[type].map(a => a.idleness);
  return mixedChartData;
};
