import { BaseListFilterType, initBaseListFilterType, RangeValueType, RangeDateType } from "../../../../../../shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} LoansAndFinancialAdvancesListFilterExtType
 * @property {Array} global - Usado para buscas no padrão antigo da API
 * @property {"due_date"|"original_due_date"|"paid_at"} date_type - [due_date..original_due_date..paid_at] due_date: Vencimento original_due_date: vencimento original paid_at: crédito
 * @property {Number|null} range_min_value - Valor mínimo do pagamento
 * @property {Number|null} range_max_value - Valor máximo do pagamento
 * @property {Array<0|1|2|3|8|9>} status - [0,1,2] Pendente, Recebido, Atrasado, Cancelado, Aprovado e Negado
 * @property {RangeDateType|null} range - Intervalo entre datas
 * @property {0|1} type - [0..1] Tipo de visualização
 * @property {Array<Number>} bank_account_id - IDs de contas bancárias
 * @property {0|1} pending_identification - [0..1] Não identificado: 0 Identificado: 1
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & LoansAndFinancialAdvancesListFilterExtType)} LoansAndFinancialAdvancesListFilterType
 */
export const LoansAndFinancialAdvancesListFilterType = {};

// ARRUMAR FILTROS ESPECÍFICOS

/**
 * Inicializar LoansAndFinancialAdvancesListFilterType
 *
 * @returns {LoansAndFinancialAdvancesListFilterType}
 */
export const initLoansAndFinancialAdvancesListFilterType = () => ({
  ...initBaseListFilterType(),
  order_by: "",
  filter: {
    columns: [],
    values: [],
  },
  global: [],
  date_type: null,
  range_max_total_value: "0,00",
  range_max_value: "0,00",
  range_min_total_value: "0,00",
  range_min_value: "0,00",
  status: [],
  range: {
    start: null,
    end: null,
    default_filterable: true
  },
  type: 0,
  bank_account_id: null,
  pending_identification: 0,
});
