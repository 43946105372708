<template>
<div>
    <div class="container-fluid">
        <div class="row card-wrapper" v-show="loadingSkeleton">
            <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
        </div>

        <PuzlEmptyData 
            v-if="
            $_entityAccounts.entity_bank_accounts && $_entityAccounts.entity_pix_accounts &&
            !$_entityAccounts.entity_bank_accounts.length &&
            !$_entityAccounts.entity_pix_accounts.length &&
            !loadingSkeleton"
        >
        </PuzlEmptyData>

        <!-- CONTA BANCÁRIA -->
        <div class="row card-wrapper mt-2" v-if="$_entityAccounts.entity_bank_accounts && 
            $_entityAccounts.entity_bank_accounts.length && !loadingSkeleton">
            <div class="col-12" v-for="(bankAccount, index) in $_entityAccounts.entity_bank_accounts" :key="index">
                <div class="row align-items-center mb-3 ml-1">
                    <div class="col-7 border-left border-primary border-3">
                        <h4 class="mb-0 ml-n2">{{ bankAccount.name }}</h4>
                        <h4 class="mb-0 ml-n2">{{ bankAccount.bank.code }} - {{ bankAccount.bank.name }}</h4>
                        <h4 class="mb-0 ml-n2">
                            {{ bankAccount.number_without_digit_agency }} | {{ bankAccount.number_without_digit_account }}
                        </h4>
                    </div>
                    <div class="col-4 text-right">
                        <a href="javascript:void(0)" @click="handleEditAccountBank(bankAccount.id)" class="px-2">
                            <img src="/img/icons/create-new.png" width="19px" height="19px">
                        </a>
                        <a href="javascript:void(0)" @click="handleDeleteBankAccount(bankAccount.id)">
                            <img src="/img/icons/icons8/ios/delete--v1.png" width="19px" height="19px">
                        </a>
                    </div>
                </div>
                <hr class="m-0 mb-3" />
            </div>
        </div>

        <!-- PIX -->
        <div class="row card-wrapper mt-2" v-if="$_entityAccounts.entity_pix_accounts && 
            $_entityAccounts.entity_pix_accounts.length && !loadingSkeleton">
            <div class="col-12" v-for="(pix, index) in $_entityAccounts.entity_pix_accounts" :key="index">
                <div class="row align-items-center mb-3 ml-1">
                    <div class="col-7 border-left border-success border-3">
                        <h4 class="mb-0 ml-n2">
                            PIX |
                            <span v-if="pix.key_type == 0">
                                Cpf/Cnpj
                            </span>
                            <span v-else-if="pix.key_type == 1">
                                Telefone
                            </span>
                            <span v-else-if="pix.key_type == 2">
                                E-mail
                            </span>
                            <span v-else-if="pix.key_type == 3">
                                Chave aleatória
                            </span>
                        </h4>
                        <h4 class="mb-0 ml-n2">{{ pix.key_value }}</h4>
                    </div>
                    <div class="col-4 text-right">
                        <a href="javascript:void(0)" @click="handleEditPix(pix.id)" class="px-2">
                            <img src="/img/icons/create-new.png" width="19px" height="19px">
                        </a>
                        <a href="javascript:void(0)" @click="handleDeletePix(pix.id)">
                            <img src="/img/icons/icons8/ios/delete--v1.png" width="19px" height="19px">
                        </a>
                    </div>
                </div>
                <hr class="m-0 mb-3" />
            </div>
        </div>
    </div>
    <ModalEditBank @fetchEntityAccount="init" ref="modalEditBank"/>
    <ModalEditPix @fetchEntityAccount="init" ref="modalEditPix"/>
</div>
</template>

<script>
import {mapGetters} from "vuex";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import ModalEditBank from './_ModalEditBank';
import ModalEditPix from './_ModalEditPix';

export default {
    name: "ListEntityAccount",
    components: {
        PuzlEmptyData,
        SkeletonPuzlGrid,
        ModalEditBank,
        ModalEditPix,
    },
    props: {
        entityId: {
        type: Number,
      },
    },
    watch: {
        entityId: function () {
            this.init();
        }
    },
    data() {
        return {
            loadingSkeleton: false,
        };
    },
    computed: {
        ...mapGetters({
            $_entityAccounts: "entity/getEntityAccounts"
        }),
    },
    mounted() {
        if (this.entityId) {
            this.init();
        }
    },
    methods: {
        init() {
            this.$Progress.start();
            this.loadingSkeleton = true;
            this.$store
                .dispatch("entity/getAccountsByEntityId", this.entityId)
                .then(response => {
                    this.$Progress.finish();
                    this.loadingSkeleton = false;
                })
                .catch(error => {
                    this.$Progress.finish();
                    this.loadingSkeleton = false;
                });
        },
        handleEditAccountBank(id) {
            this.$refs.modalEditBank.handleEditModal(id);
        },
        handleEditPix(id) {
            this.$refs.modalEditPix.handleEditModal(id);
        },
        handleDeleteBankAccount(id) {
            this.$Swal.confirmDelete().then((result) => {
                if (result.isConfirmed) {
                    this.$Progress.start();
                    this.$notify({
                        type: 'info',
                        message: 'Estamos trabalhando em sua solicitação.'
                    });
                    this.$store.dispatch('entityBankAccount/destroy', id)
                        .then((response) => {
                            this.$notify({
                                type: 'success',
                                message: response.message
                            });
                            this.init();
                            this.$Progress.finish();
                        })
                        .catch(error => {
                            this.$notify({
                                type: error.data.error_type,
                                message: error.data.message
                            })
                            this.$Progress.finish();
                        })
                }
            }).catch(() => this.$Progress.finish())
        },
        handleDeletePix(id) {
            this.$Swal.confirmDelete().then((result) => {
                if (result.isConfirmed) {
                    this.$Progress.start();
                    this.$notify({
                        type: 'info',
                        message: 'Estamos trabalhando em sua solicitação.'
                    });
                    this.$store.dispatch('entityPixAccount/destroy', id)
                        .then((response) => {
                            this.$notify({
                                type: 'success',
                                message: response.message
                            });
                            this.init();
                            this.$Progress.finish();
                        })
                        .catch(error => {
                            this.$notify({
                                type: error.data.error_type,
                                message: error.data.message
                            })
                            this.$Progress.finish();
                        })
                }
            }).catch(() => this.$Progress.finish())
        },
    },
};
</script>

<style></style>
