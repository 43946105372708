<template>
  <div class="sidebar-new sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white"
       @mouseenter="$sidebar.onMouseEnter()"
       @mouseleave="$sidebar.onMouseLeave()">
    <div style="z-index: 999999999" class="scrollbar-inner" ref="sidebarScrollArea">
      <div class="sidenav-header d-flex align-items-center">
        <a class="navbar-brand" href="#" @click.prevent="toHome">
          <img :src="logo" class="navbar-brand-img" alt="Puzl Place">
        </a>
        <div class="ml-auto">
          <!-- Sidenav toggler -->
          <div class="sidenav-toggler d-none d-xl-block"
               :class="{'active': !$sidebar.isMinimized }"
               @click="minimizeSidebar">
            <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
            </div>
          </div>
        </div>
      </div>
      <slot></slot>
      <div class="navbar-inner">
        <ul class="navbar-nav">
          <slot name="links">
            <sidebar-item
              v-for="(link, index) in sidebarLinks"
              :key="link.name + index"
              :link="link"
            >
              <sidebar-item
                v-for="(subLink, index) in link.children"
                :key="subLink.name + index"
                :link="subLink"
              >
              </sidebar-item>
            </sidebar-item>
          </slot>
        </ul>

      </div>
<!--      <div class="show-md footer-custom" style="width:249px;height:80px !important;">-->
<!--        <h6 class="text-center font-weight-normal navbar-brand"><small class="font-weight-bold"-->
<!--                                                                       style="text-transform: none !important;">powered-->
<!--          by </small>-->
<!--          <img src="/img/brand/logo-cor.png" width="90px"></h6>-->
<!--      </div>-->
    </div>
  </div>
</template>
<script>
export default {
  name: "sidebar",
  props: {
    title: {
      type: String,
      default: "Puzl Place",
      description: "Puzl Place"
    },
    shortTitle: {
      type: String,
      default: "CT",
      description: "Sidebar short title"
    },
    logo: {
      type: String,
      default: "/img/brand/puzl-mix.jpeg",
      description: "Puzl Place"
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        "List of sidebar links as an array if you don't want to use components for these."
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        "Whether sidebar should autoclose on mobile when clicking an item"
    },
    disabled: {
      type: Boolean,
      default: false,
      description: "When you need disabled menu open"
    },
    data() {
      return {
        windowWidth: 0
      }
    }
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize(true);
      }
    },
    toHome() {
      this.$router.push('/dashboard');
    },
  },
  mounted() {
    this.$sidebar.isMinimized = this.$sidebar.breakpoint < window.innerWidth;
    this.$sidebar.toggleMinimize();
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
<style scoped>
.footer-custom {
  z-index: -9;
  position: fixed;
  bottom: -5px !important;
}
</style>
