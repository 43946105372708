<template>
<div>
    <base-header class="bg-gray-content">
        <PuzlBreadcrumb />
        <!-- Header Menus -->
        <div class="row">
            <div class="col-md-2 mb-3">
                <base-button
                  class="pb-3 pt-3 text-uppercase"
                  block size="lg"
                  type="success"
                  :disabled="loading"
                  @click.prevent="handleCreateAdjustment(
                    formulation.first_formulation_uuid,
                    formulation.mixe_code,
                    contract.uuid
                   )"
                >
                  <i class="fas fa-plus"></i> novo
                </base-button>
            </div>
            <div class="col-md-10">
                <div class="row">
                      <div class="col-md-3 col-lg-2 col-xlg-2 ml-3 mr-3 mb-3 ml-0 p-0">
                        <router-link :to="{ path:'/technology/adjustment/contract-hour'}">
                            <base-button class="pb-3 pt-3 text-uppercase" block size="lg" type="light">
                              <i class="fas fa-undo-alt"></i> voltar
                            </base-button>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

      <div class="d-flex justify-content-between flex-column">
          <div class="checklist-item checklist-item-primary" v-if="contract">
            <div class="checklist-info">
              <h3 v-if="!loading" class="checklist-title mb-0">
                Código: <span class="small">{{ contract.code }}</span>
              </h3>
              <h3 v-if="!loading" class="checklist-title mb-0">
                Cliente:
                <span class="small">
                    {{contract && contract.construction && contract.construction.customer_construction.customer_name }}
                  </span>
              </h3>
              <h3 v-if="!loading" class="checklist-title mb-0">
                Obra:
                <span class="small">
                    {{ contract && contract.construction && contract.construction.construction_name }}
                  </span>
              </h3>
            </div>
          </div>
          <div class="checklist-item checklist-item-primary mt-2 mb-4 " v-if="formulation">
            <div class="checklist-info">
              <div
                class="row"
                v-for="feature in formulation.features"
                :key="feature.id"
              >
                <div class="col-md-12">
                  <h5 class="h4 mb-0">
                    {{ feature.feature.short_description }}
                    {{ format_mixe(feature.feature.period, feature.feature.period_type) }}
                    {{ feature.description }}
                    {{ feature.letter }}
                  </h5>
                </div>
              </div>
              <div class="mt-2">
                <h5 class="small">
                  {{ formulation && formulation.type && formulation.type.technical_description || '' }}
                  {{ formulation && formulation.subtype && formulation.subtype ? '|' + formulation.subtype.technical_description : '' }}
                  |
                  {{ formulation && formulation.slump_flow && formulation.slump_flow.nomenclature }}
                  |
                  {{ formulation && formulation.maximum_size && formulation.maximum_size.description }}
                </h5>
                <h5 class="small">
                  {{ formulation.footer }}
                </h5>
              </div>
              <div class="row">
                <div class="col-12">
                  <div
                    class="border-left border-3 border-default p-2"
                    v-if="formulation.concretedPiece.length"
                  >
                    <div
                      v-for="(piece, indexPiece) in formulation.concretedPiece"
                      :key="piece.id"
                    >
                      <h5>
                        {{ piece.description }}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Adção Puzl-->
              <div class="mt-2 border-left border-3 border-indigo p-2" v-if="formulation && formulation.puzl_additions && formulation.puzl_additions.length">
                <div
                  v-for="(puzl_addtion, index) in formulation.puzl_additions"
                  :key="puzl_addtion.id"
                >
                  <h5 class="text-indigo">
                    {{ puzl_addtion.name }}
                    ({{ puzl_addtion.volume }} kg/m<sup>3</sup>)
                    <span class="text-indigo" v-if="index != formulation.puzl_additions.length -1">+</span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
      </div>

    </base-header>

    <ListAdjustmentFormulation v-bind:added="this.added" />
    <ModalCreateAdjustment @fetchAdjustmentsContract="handleCreateAdjustmentList" ref="ModalCreateAdjustment" />
</div>
</template>

<script>
import {period_types} from "@/views/Modules/Technology/Mix/helpers/mixe";
import {mapGetters } from 'vuex'
import ListAdjustmentFormulation from './Shared/_List'
import ModalCreateAdjustment from './Shared/_ModalCreateAdjustment'
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import { formatErrorValidation} from '@/plugins'
export default {
    name: "IndexAdjustmentChargePoint",
    components: {
      PuzlBreadcrumb,
      ListAdjustmentFormulation,
      ModalCreateAdjustment
    },
    data() {
        return {
          format_mixe: period_types,
          added: false,
          loading: true,
          contract: {},
          formulation: {},
        }
    },
  computed: {
    ...mapGetters({
      'contract_formulation': 'adjustmentContractHour/formulation',
    }),
  },
  methods: {
    fetch(){
      this.$Progress.start();
      this.loading = true
      this.$store.dispatch('adjustmentContractHour/showFormulation',this.$route.params.formulation_uuid)
        .then((response) => {
          this.contract = response.data.contract_proposal || {}
          this.formulation = response.data || {}
          this.$Progress.finish()
          this.loading = false
        })
        .catch((error) => {
          this.$Progress.finish()
          this.loading = false
        })
    },
      handleCreateAdjustment(formulationUuid, mixCode, contractProposalUuid) {
          this.$refs.ModalCreateAdjustment.handleCreateModal(formulationUuid, mixCode, contractProposalUuid)
      },
       handleCreateAdjustmentList(){
          this.added = !this.added
      }
  },
  mounted() {
    this.fetch()
  },
};
</script>

<style></style>
