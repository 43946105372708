<template>
  <div>
    <BarLineChart v-if="barBrushLoading === false" :theme="theme" :option="barBrush[barBrushType]">
      <div class="row mb-0 p-0 mb-4">
        <div class="col-4">
          <base-button
            :disabled="loading"
            @click.prevent="triggerBarBrushType('day')"
            block
            size="md"
            :type="barBrushType === 'day' ? 'primary' : 'secondary'"
          >
            DIA
          </base-button>
        </div>
        <div class="col-4">
          <base-button
            :disabled="loading"
            @click.prevent="triggerBarBrushType('month')"
            block
            size="md"
            :type="barBrushType === 'month' ? 'primary' : 'secondary'"
          >
            MÊS
          </base-button>
        </div>
        <div class="col-4">
          <base-button
            :disabled="loading"
            @click.prevent="triggerBarBrushType('company_plant')"
            block
            size="md"
            :type="barBrushType === 'company_plant' ? 'primary' : 'secondary'"
          >
            CENTRAL
          </base-button>
        </div>
      </div>
    </BarLineChart>
    <div v-if="treemapLoading === false">
      <TreemapChart customStyle="padding-top:0!important" :option="treemap[treemapType]" :theme="theme">
        <div class="row mb-0 p-3 mb-3">
          <div class="col-4 pr-2 pl-0">
            <base-button
              :disabled="loading"
              @click.prevent="triggerTreemapType('billReceiveData')"
              block
              size="md"
              :type="treemapType === 'billReceiveData' ? 'primary' : 'secondary'"
            >
              CONTAS A RECEBER
            </base-button>
          </div>
          <div class="col-4 p-0">
            <base-button
              :disabled="loading"
              @click.prevent="triggerTreemapType('billPayData')"
              block
              size="md"
              :type="treemapType === 'billPayData' ? 'primary' : 'secondary'"
            >
              CONTAS A PAGAR
            </base-button>
          </div>
          <div class="col-4 pr-0 pl-2">
            <base-button
              :disabled="loading"
              @click.prevent="triggerTreemapType('invoiceData')"
              block
              size="md"
              :type="treemapType === 'invoiceData' ? 'primary' : 'secondary'"
            >
              FATURAMENTO
            </base-button>
          </div>
        </div>
      </TreemapChart>
    </div>
  </div>
</template>

<script>
import TreemapChart from "@/components/Echarts/TreemapChart.vue";
import BarLineChart from "@/components/Echarts/BarLineChart.vue";
import { mapGetters } from "vuex";
export default {
  name: "DashboardChart",
  components: {
    TreemapChart,
    BarLineChart,
  },
  computed: {
    ...mapGetters({
      loading: "financialDashboard/loading",
      treemapLoading: "financialDashboard/treemapLoading",
      treemapType: "financialDashboard/treemapType",
      treemap: "financialDashboard/treemap",
      barBrushLoading: "financialDashboard/barBrushLoading",
      barBrush: "financialDashboard/barBrush",
      barBrushType: "financialDashboard/barBrushType",
    }),
  },
  methods: {
    triggerTreemapType(type) {
      this.$store.dispatch("financialDashboard/triggerTreemapType", type);
    },
    triggerBarBrushType(type) {
      this.$store.dispatch("financialDashboard/triggerBarBrushType", type);
    },
  },
  props: {
    theme: {
      type: Boolean,
      default: false,
      description: "Tema",
    },
  },
};
</script>

<style></style>
