<template>
  <div>
    <base-header class="bg-gray-content mb-n4">
      <div class="row align-items-center py-4">
        <div class="col-md-4 mt-n4">
          <h2 style="font-size: 1.2rem !important;" class="text-warning">
            <img height="30px" src="/img/icons/chemical-plant.png" class="mr-2 mb-1"/>
            RESUMO DE ADICIONAIS
          </h2>
        </div>
        <div class="col-md-8">
          <div class="row d-flex justify-content-end show-md mr-1">
            <div class="px-1">
              <div class="card mini-card">
                <div>
                  <h5 class="new-default-black-font checklist-title mb-0 ml-2 mt-1">
                    <img height="19px" src="/img/icons/clock--v1.png" class="mr-1 mb-1 mt-1"/>
                    A COBRAR
                  </h5>
                </div>
                <div>
                  <h5 class="checklist-title mb-0 mt-1">{{($_widgets.active || 0) | currency()}}</h5>
                </div>
              </div>
            </div>
            <div class="px-1">
              <div class="card mini-card red">
                <div>
                  <h5 class="new-default-black-font checklist-title mb-0 ml-2 mt-1">
                    <img height="19px" src="/img/icons/close.png" class="mr-1 mb-1 mt-1"/>
                    CANCELADOS
                  </h5>
                </div>
                <div>
                  <h5 class="checklist-title mb-0 mt-1">{{($_widgets.canceled || 0) | currency()}}</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="row d-flex justify-content-end show-mobile">
            <div class="col-12 mt-2 mb-n4 px-1">
              <div class="card mini-card">
                <div>
                  <h5 class="new-default-black-font checklist-title mb-0 ml-2 mt-1">
                    <img height="19px" src="/img/icons/clock--v1.png" class="mr-1 mb-1 mt-1"/>
                    A COBRAR
                  </h5>
                </div>
                <div>
                  <h5 class="checklist-title mb-0 ml-2">{{($_widgets.active || 0) | currency()}}</h5>
                </div>
              </div>
            </div>
            <div class="col-12 mt-0 px-1">
              <div class="card mini-card red">
                <div>
                  <h5 class="new-default-black-font checklist-title mb-0 ml-2 mt-1">
                    <img height="19px" src="/img/icons/close.png" class="mr-1 mb-1"/>
                    CANCELADOS
                  </h5>
                </div>
                <div>
                  <h5 class="checklist-title mb-0 ml-2">{{($_widgets.canceled || 0) | currency()}}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a href="#" class="mt-1 ml-3" @click.prevent="downloadExcel()" style="margin-right: 160px;">
          <img style="width: 28px" src="/img/icons/excel.png" />
        </a>
      </div>
      
    </base-header>
    <ResumeAdditional ref="resumeAdditional" />
</div>
</template>

<script>
import {mapGetters} from "vuex";
import ResumeAdditional from "./Shared/_List";
import BackButton from "@/components/Utils/BackButton.vue";
export default {
    name: "Index",
    components: {
        ResumeAdditional,
        BackButton,
    },
    computed: {
    ...mapGetters({
      $_widgets: "resumeAdditional/getWidgets",
    }),
  },
  methods: {
    downloadExcel() {
      this.$refs.resumeAdditional.downloadExcel();
    },
  },
}
</script>

<style scoped>
  .mini-card {
    display: grid;
    grid-template-columns: 55% 46%;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    padding: 0;
  }

  .mini-card div:last-child {
    background-color: #149e5752;
    color: #149E57;
  }

  .mini-card div:last-child small, .mini-card div:last-child h5 {
    color: #149E57;
  }

  .mini-card.red div:last-child {
    background-color: #9e141452;
    color: #DB4539;
  }

  .mini-card.red div:last-child small, .mini-card.red div:last-child h5 {
    color: #DB4539;
  }

  .mini-card div {
    display: inline-grid;
    padding: 8px;
    padding-top: 9.14px;
    padding-bottom: 9.14px;
  }

  .mini-card div h5 {
    font-weight: 600 !important;
    font-size: 14px;
  }

  .mini-card div:last-child h5 {
    font-size: 16px !important;
  }

  .mini-card div:last-child small {
    font-size: 9px !important;
  }

  .mini-card div small {
    font-size: 10px !important;
    font-weight: 500 !important;
  }

  .new-default-black {
    background-color: #2B2D32 !important;
    background: #2B2D32 !important;
  }

  .card {
    border-radius: 10px !important;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3) !important;
    min-width: 300px;
  }
</style>
