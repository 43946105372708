/**
 * Type para item da listagem
 *
 * @typedef {Object} AgentType
 * @property {?number} id
 * @property {?string} name
 * @property {?string} value

 */
export const AgentType = {};

/**
 * Inicializar AgentType
 *
 * @returns {AgentType}
 */
export const initAgentType = () => ({
  id: null,
  name: null,
  value: null,
});
