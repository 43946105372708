<template>
  <div class="card-body mt-n2 p-0">
    <div class="row">
      <ShadedTable
        :length="$_horarys.length"
        :headers="headers"
        :loading="loadingSkeleton"
      >
        <template v-for="(item, index) in $_horarys" :slot="index">
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.schedule_travel_finished_at | parseDate("DD/MMM") }}
              <el-popover
                class="float-right"
                placement="bottom"
                trigger="hover"
                style="margin-left: 55px; margin-top: -20px"
              >
                <div class="p-2">
                  <img
                    style="width: 21px"
                    src="/img/icons/chemical-plant-black.png"
                  />
                  <b class="text-dark"> Central </b>
                  <div class="text-left text-dark">
                    {{ item.company_plant_charge_point_name }}
                  </div>
                  <div class="text-left text-dark">
                    {{ item.company_plant_name }}
                  </div>
                </div>
                <base-button
                  slot="reference"
                  class="p-0 m-0 shadow-none"
                  outiline
                  size="sm"
                  type="secundary"
                >
                  <img
                    src="/img/icons/chemical-plant-black.png"
                    width="20px"
                    height="20px"
                  />
                </base-button>
              </el-popover>
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              <span class="style-table">
                <span class="float-left numeric font-size pr-4">
                  {{ item.sequencial_number_plant_number }}
                </span>
                <el-popover
                  class="ml-4 float-right"
                  placement="bottom"
                  trigger="hover"
                  style="margin-top: -20px"
                >
                  <div class="mt-2 border-left border-3 border-indigo p-2">
                    <div>
                      <span>
                        <span class="text-center text-dark">
                          {{ item.equipment_code }}
                        </span>
                        <br />
                        <span class="text-center">
                          {{ item.driver_name }}
                        </span>
                      </span>
                    </div>
                  </div>
                  <base-button
                    slot="reference"
                    class="p-0 m-0 shadow-none"
                    outiline
                    size="sm"
                    type="secundary"
                  >
                    <img
                      v-if="item.driver_name"
                      src="/img/icons/interstate-truck.png"
                      width="20px"
                      height="20px"
                    />
                  </base-button>
                </el-popover>
              </span>
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              <span
                class="btn btn-sm btn-primary p-1 mr-2"
                @click.prevent="
                  copyContractProposal(item.contract_proposal_code)
                "
              >
                {{ item.contract_proposal_code }}
              </span>
              <span class="font-size" :title="item.entity_name">
                {{ truncateString(item.entity_name, 20) }}
              </span>
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              <span class="numeric mr-2" style="font-size: 12px !important">
                {{ formatTime(item.total_cycle) }}
                <a href="#" @click.prevent="$emit('handleTravelCycle', item)">
                  <img
                    src="/img/icons/clock--v1.png"
                    width="20px"
                    height="20px"
                    class="float-right"
                  />
                </a>
              </span>
            </h5>
          </ShadedCol>
          <ShadedCol align="center">
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ formatTime(item.concrete_cycle) }}
            </h5>
          </ShadedCol>
          <ShadedCol align="center">
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ formatTime(item.load_truck_diff) }}
            </h5>
          </ShadedCol>
          <ShadedCol align="center">
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ formatTime(item.wait_start_discharge) }}
            </h5>
          </ShadedCol>
          <ShadedCol align="center">
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ formatTime(item.construction_stay_time) }}
            </h5>
          </ShadedCol>
          <ShadedCol align="center">
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ formatTime(item.send_route_time) }}
            </h5>
          </ShadedCol>
          <ShadedCol align="center">
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ formatTime(item.return_route_time) }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ formatTime(item.ballast) }}
            </h5>
          </ShadedCol>
        </template>
      </ShadedTable>
    </div>
  </div>
</template>

<script>
import SkeletonPuzl from "@/components/SkeletonPuzl";
import ShadedTable from "@/components/ShadedTable.vue";
import ShadedCol from "@/components/ShadedCol.vue";
import { mapGetters } from "vuex";

export default {
  name: "TableHoraryResume",
  data() {
    return {
      headers: [
        "Data",
        "O.S.",
        "Cliente",
        "Ciclo Total",
        "Ciclo Concreto",
        "Carga",
        "Espera p/ Desgarga",
        "Perman. em obra",
        "Trajeto (ida)",
        "Trajeto (retorno)",
        "Lastro",
      ],
    };
  },
  props: {
    loadingSkeleton: Boolean,
  },
  computed: {
    ...mapGetters({
      $_horarys: "horaryResume/fetch",
    }),
  },
  components: {
    SkeletonPuzl,
    ShadedTable,
    ShadedCol,
  },
  methods: {
    /**
     * @param {string} value
     */
    copyContractProposal(value) {
      navigator.clipboard.writeText(value);
    },
    truncateString(str, maxLength) {
      if (str && str.length > maxLength) {
        return str.slice(0, maxLength) + "...";
      }
      return str;
    },
    formatTime(minutes) {
      if (minutes) {
        if (minutes > 60) {
          var hours = Math.floor(minutes / 60);
          var minutes_remaining = minutes % 60;
          return hours + "H " + minutes_remaining.toFixed(0) + " MIN";
        } else {
          return minutes.toFixed(0) + " MIN";
        }
      }

      return "0 MIN";
    },
  },
};
</script>
