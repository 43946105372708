<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(update)" autocomplete="off">
            <!-- CAMPO CENTRAL -->
            <div class="form-group row m-0 p-0">
              <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                Central
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-8 mb-1 pr-4">
                <validation-provider rules="required" v-slot="{ errors }">
                  <base-input input-classes="form-control-sm">
                    <PuzlSelect
                      v-model="$_adjustmentTypes.company_plant_id"
                      :items="$_plants"
                      :loading="loadingCompanyPlant"
                      :disabled="loadingCompanyPlant"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!-- CAMPO TIPO -->
            <div class="form-group row m-0 p-0">
              <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                Tipo
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-8 mb-1 pr-4">
                <validation-provider rules="required" v-slot="{ errors }">
                  <base-input input-classes="form-control-sm">
                    <PuzlSelect
                      v-model="$_adjustmentTypes.mix_type_id"
                      :items="$_mixTypes"
                      label="technical_description"
                      :loading="loadingMixType"
                      :disabled="loadingMixType"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')"> Cancelar </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore"> Salvar </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
const { formatErrorValidation } = require("@/plugins");

export default {
  name: "ModalCreate",
  components: { PuzlSelect },
  data() {
    return {
      modal: {
        title: "Ajuste por tipo",
        create: false,
      },
      loadingCompanyPlant: true,
      loadingMixType: true,
      loadingStore: false,
    };
  },
  computed: {
    ...mapGetters({
      $_adjustmentTypes: "adjustmentType/show",
      $_plants: "plant/activeItems",
      $_mixTypes: "mixType/activeItems",
    }),
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    /**
     * @param {number} id
     */
    handleEditModal(id) {
      this.$Progress.start();
      this.loadData();
      this.$store
        .dispatch("adjustmentType/show", id)
        .then((response) => {
          this.modal.create = true;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.$notify({ type: error.data.error_type, message: error.data.message });
          this.$Progress.finish();
        });
    },
    update() {
      this.$Progress.start();
      this.loadingStore = true;
      this.$store
        .dispatch("adjustmentType/update", this.$_adjustmentTypes)
        .then((response) => {
          this.$emit("updatedAdjustmentType");
          this.$notify({ type: response.error_type, message: response.message });
          this.closeModal();
        })
        .catch((error) => {
          if (error.status == 200) {
            this.$notify({ type: "danger", message: error.data.message });
          } else if (error.response && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({ type: "danger", message: errors });
          }
        })
        .finally(() => {
          this.$Progress.finish();
          this.loadingStore = false;
        });
    },
    loadData() {
      this.loadingCompanyPlant = true;
      this.$store.dispatch("plant/getPlantsSimplified").then(() => {
        this.loadingCompanyPlant = false;
      });
      this.loadingMixType = true;
      this.$store.dispatch("mixType/fetchItems").then(() => {
        this.loadingMixType = false;
      });
    },
  },
};
</script>

<style scoped></style>
