<template>
<div>
  <modal :show.sync="modal">
    <template slot="header">
      <h5 class="modal-title">{{ title }}</h5>
    </template>
    <div>
      <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
        <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">

          <div>

            <div class="d-flex justify-content-betwen mb-3">
              <div class="col-6">
                <base-button disabled block size="sm" type="success" outline class="text-capitalize active">
                  aprovado
                </base-button>
              </div>
              <div class="col-6">
                <base-button disabled block size="sm" type="danger" outline class="text-capitalize active">
                  reprovado
                </base-button>
              </div>
            </div>

            <div>
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-3 pb-0 pt-1 col-form-label form-control-label">
                  Contato
                </label>
                <div class="col-md-9">
                  <base-input input-classes="form-control-sm">
                    <el-select v-model="shared_link.email" size="mini" filterable placeholder="Selecione" :disabled="loadingConstruction">
                      <el-option label="Selecione" value="">
                      </el-option>
                      <el-option v-for="contact in construction.contacts" :key="contact.uuid" :label="contact.name" :value="contact.email">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-3">

            <div v-show="show_send">
                <div class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-3 pb-0 pt-1 col-form-label form-control-label">
                    E-mail:
                  </label>
                  <div class="col-md-9">
                    <span input-classes="form-control-sm">
                      {{ shared_link.email || '-' }}
                    </span>
                  </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-3 pb-0 pt-1 col-form-label form-control-label">
                    Celular:
                  </label>
                  <div class="col-md-9">
                    <span input-classes="form-control-sm">
                      {{ shared_link.cell_phone || '-'}}
                    </span>
                  </div>
              </div>
            </div>

          </div>

          <div class="modal-footer">
            <base-button type="secondary" @click="closeModal()">Cancelar</base-button>
            <base-button type="primary" native-type="submit" :disabled="invalid" :loading="loading">enviar</base-button>
          </div>
        </form>
      </validation-observer>
    </div>
  </modal>
</div>
</template>

<script>
export default {
  components: {},
  name: "ModalSendLinkToCustomerApprove",
  data() {
    return {
      modal: false,
      title: 'Enviar link para aprovação do cliente.',
      validated: false,
      loading: false,
      loadingConstruction: false,
      shared_link: {
        email: null,
        name: null,
        cell_phone: null,
      },
      show_send: false,
      construction: {
        id: 2,
        uuid: '9357c860-cad2-482f-a1ff-cdb4b4bca17e',
        construction_name: 'Obra teste',
        status: true,
        customer_construction: {
          id: 3,
          uuid: '9357c841-3a59-4a24-a51b-aefffe64d018',
          status: true,
          customer_name: 'nome do cliente',
          web_site: 'http://www.nomedocliente.com.br'
        },
        web_site: 'http://www.uol.com.br',
        access_restriction: "Mediante autorização",
        default_address: {
          id: 2,
          uuid: '9357c860-d708-4af4-a755-86c543a9e243',
          default: true,
          alias: 'Endereço da obra',
          postal_code: '72910-139',
          state: 'GO',
          address: 'qd 2 lote 1',
          number: null,
          complement: null,
          district: 'setor 2',
          city: 'aguas lindas',
          country: 'Brasil',
          construction_id: 2
        },
        type: null,
        size: null,
        estimated_volume: '100.0',
        scheduled_beginning: '2021-01-01',
        scheduled_ending: '2021-02-01',
        total_months: 2,
        type_construction: {
          id: 1,
          uuid: '934e6e8e-75a6-4ec0-a89a-acd49fc72ab9',
          status: true,
          description: 'Tipo de Obra'
        },
        prospection_method_construction: {
          id: 1,
          uuid: '934e6e9c-f5b7-4d8f-bf3d-7c85a256216b',
          status: true,
          description: 'Método de Prospecção'
        },
        contacts: [{
          id: 4,
          uuid: '9357c88e-57ff-4ce4-82da-3f9d881e6482',
          status: true,
          is_user: true,
          is_administrator: true,
          name: 'Junior',
          email: 'j.s.junior@live.com',
          sector: 'Setor Teste',
          office: 'teste',
          cell_phone: null,
          phone: null,
          construction_uuid: '9357c860-cad2-482f-a1ff-cdb4b4bca17e'
        }]
      },
    }
  },
  watch: {
    'shared_link.email': function () {
      if(this.shared_link.email){
        let contacts = this.construction.contacts
        let [contact] = contacts.filter((item) => item.email === this.shared_link.email)
        this.shared_link.name = contact.name
        this.shared_link.cell_phone = contact.cell_phone
        this.show_send = true
      }else{
        this.show_send = false
      }
    },
  },
  methods: {
    closeModal() {
      this.modal = false
    },
    openModal() {
      this.modal = true
    },
    handleSendLink(constructionUuid) {
      this.modal = true
    },
    save() {
    },
  },
  created() {},
  mounted() {}
};
</script>

<style></style>
