<template>
<div>
<modal :show.sync="modal">
  <template slot="header">
    <h5 class="modal-title">{{title}}</h5>
  </template>
  <div>
    <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
      <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
        <div>
             <div class="form-group row m-0 p-0">
                  <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                    Central
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-8">
                      <validation-provider rules="required">
                        <base-input input-classes="form-control-sm">
                          <el-select
                            v-model="carrier_price.company_plant_id"
                            size="mini"
                            filterable
                            placeholder="Selecione"
                            :disabled="loadingPlant"
                          >
                            <el-option v-for="plant in plants"
                                       :key="plant.id"
                                       :label="plant.name"
                                       :value="plant.id">
                            </el-option>
                          </el-select>
                        </base-input>
                       </validation-provider>
                  </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-4 pb-0 col-form-label form-control-label">
                    Transportadora
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-8">
                      <validation-provider rules="required">
                        <base-input input-classes="form-control-sm">
                        <el-select
                          v-model="carrier_price.entity_id"
                          size="mini"
                          filterable
                          placeholder="Selecione"
                          :disabled="loadingCarrier"
                        >
                          <el-option v-for="carrier in carriers"
                                    :key="carrier.id"
                                    :label="carrier.entity_name"
                                    :value="carrier.id">
                          </el-option>
                        </el-select>
                        </base-input>
                       </validation-provider>
                  </div>
              </div>


              <div class="form-group row m-0 p-0">
              <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                Preço
                <span class="text-danger">&nbsp;*</span>
                </label>

                  <div class="col-md-8">
                    <validation-provider rules="required">
                      <base-input input-group-classes="input-group-sm">
                      <input type="text" v-model="carrier_price.price" v-mask="['#,##', '##,##', '###,##', '####,##']"
                      class="form-control form-control-sm" input-group-classes="input-group-sm"
                      >
                      <template slot="append">
                            <small class="input-group-sm p-0 m-0">
                            {{ initialsBuy }}
                            </small>
                            </template>
                          <template slot="prepend">
                            <small class="input-group-sm p-0 m-0">
                           R$
                            </small>
                            </template>
                      </base-input>
                    </validation-provider>
                  </div>
              </div>


              <div class="form-group row m-0 p-0">
                  <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                    Inicio Validade
                  </label>
                  <div class="col-md-8">
                    <validation-provider rules="required">
                      <base-input input-group-classes="input-group-sm">
                         <el-date-picker
                                    size="small"
                                    v-model="carrier_price.validity_start"
                                    type="date"
                                    placeholder="Inicio"
                                    format="dd/MM/yyyy"
                                    value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptions"
                                    >
                          </el-date-picker>
                      </base-input>
                      </validation-provider>
                  </div>
              </div>


        </div>
        <div class="modal-footer">
          <base-button type="secondary" @click="openModal()"
            >Cancelar</base-button
          >
          <base-button
            type="success"
            native-type="submit"
            v-bind:disabled="invalid"
            :loading="loadingSave"
          >
            Salvar
          </base-button
          >
        </div>
      </form>
    </validation-observer>
  </div>
</modal>


</div>
</template>
<script>
import {mapGetters} from "vuex"

export default {
  name: "ModalCreateCmcCarrierPrice",
  components: {
  },
  data() {
    return {
      initialsBuy: '',
      search: {
        string: '',
        types: 3,
        loading: false,
      },
      modal: false,
      title:'Adicionar preço Transportadora',
      validated: false,
      carrier_price: {
        cmc_id: '',
        company_plant_id: '',
        entity_id: '',
        price: '',
        validity_start: '',
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      loadingSave: false,
      loadingPlant: true,
      loadingCarrier: true,
    };
  },
  computed: {
    ...mapGetters({
      'plants': 'plant/activeItems',
      'carriers': 'entity/fetch',
    }),
  },
  methods: {
    openModal (open) {
      this.modal= open
    },
    handleCreate(cmc_id,initialsBuy){
      this.carrier_price = {
        cmc_id: cmc_id,
        company_plant_id: '',
        entity_id: '',
        price: '',
        validity_start: '',
      };
      this.initialsBuy = initialsBuy;
      this.openModal(true);
    },
    save(){
      this.$Progress.start()
      this.loadingSave = true
      this.$store.dispatch('cmc/addCarrierPrice', this.carrier_price)
        .then(response => {
          this.loadingSave = false
          this.modal = false
          this.$Progress.finish()
          this.$notify({ type: response.error_type, message: response.message })
          this.$store.dispatch('cmc/fetchItems').then(() => {})
        })
        .catch(error =>{
          if(error.status){
            this.$Progress.finish()
            this.loadingSave = false
            this.$notify({ type: error.data.error_type, message: error.data.message })
          }else{
            if(error.response.status===422){
              let message = formatErrorValidation(error.response.data.errors)
              this.$notify({ type: 'danger', message: message })
              this.$Progress.finish()
              this.loadingSave = false
            }else{
              this.$notify({ type: error.data.error_type, message: error.data.message })
              this.$Progress.finish()
              this.loadingSave = false
            }
          }
        })
    },
  },
  created(){
  },
  mounted() {
    this.$refs.formValidator.validate();
    this.$store.dispatch('plant/fetchItemsActive').then(() => {this.loadingPlant=false})
    this.$store.dispatch('entity/fetchItemsSearch', this.search).then(() => {this.loadingCarrier=false})
  }
};

</script>
<style></style>
