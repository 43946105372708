<template>
  <div>
    <!-- <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <PuzlBreadcrumb />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-2 mb-2">
          <base-button
            @click.prevent="handleCreateModal"
            class="pb-3 pt-3 text-uppercase new-puzl-default"
            block
            type="success"
            style="border-radius: 50px !important"
          >
            <i class="fas fa-plus"></i> novo
          </base-button>
        </div>
        <div class="col-md-2 mb-3" aria-current="page">
          <BackButton />
        </div>
      </div>
    </base-header> -->
    <base-header class="bg-gray-content mb-n4">
      <div class="row align-items-center py-4">
        <div class="col-md-4">
          <h2 style="font-size: 1.2rem !important" class="text-warning">
            <img height="30px" src="/img/icons/exchange.png" class="mr-3" />
            TRANSFERÊNCIAS BANCÁRIAS
          </h2>
        </div>
        <div class="col-md-8">
          <div class="row d-flex justify-content-end mb-3">
            <div class="mb-2 mb-md-0 col-md-3 px-1">
              <base-button
                block
                type="success"
                class="new-puzl-default"
                style="border-radius: 50px !important"
                @click.prevent="handleCreateModal"
              >
                <i class="fas fa-plus"></i>
                Novo
              </base-button>
            </div>
            <div class="mb-2 mb-md-0 col-md-3 px-1">
              <base-button
                block
                type="light"
                class="new-puzl-default"
                style="border-radius: 50px !important"
                @click.prevent="$router.go(-1)"
              >
                <img
                  width="19"
                  src="/img/icons/circled-left.png"
                  class="mr-1"
                />
                Voltar
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </base-header>

    <ListBankTransfer />
    <CreateBankTransfer ref="modalCreate" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ListBankTransfer from "./Shared/_List";
import CreateBankTransfer from "./Shared/_ModalCreate";
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import BackButton from "@/components/Utils/BackButton";

export default {
  name: "Index",
  components: {
    ListBankTransfer,
    CreateBankTransfer,
    PuzlBreadcrumb,
    BackButton,
  },
  methods: {
    handleCreateModal() {
      this.$refs.modalCreate.handleCreateModal();
    },
  },
};
</script>

<style lang="scss"></style>
