<template>
  <div>
    <base-header class="bg-gray-content mb-n4">
      <div class="row align-items-center py-4">
        <div class="col-md-3">
          <h2 style="font-size: 1.2rem !important;" class="text-primary">
            <img height="30px" src="/img/icons/check.png" class="mr-2"/>
            MOVIMENTOS DE ESTOQUE
          </h2>
        </div>
        <div class="col-md-9">
          <div class="row d-flex justify-content-end">
            <div class="mb-2 mb-md-0 col-md-2 px-1">
              <base-button block type="success" @click.prevent="handleOpenModalSearchProductServiceHub">
                <i class="fas fa-plus"></i>
                Novo
              </base-button>
            </div>
            <div class="mb-2 mb-md-0 col-md-3 px-1">
              <base-button type="primary" @click.prevent="handleShowModalClosing">
                <i class="fa-solid fa-lock"></i>
                Fechamento de estoque
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </base-header>
    <modal-add-cmc ref="ModalAddCmc" @setCmc="handleOpenModalAddStockMovement"/>
    <list ref="list"/>
    <modal-stock-closing @updated="$refs.list.updateLatestStock()" ref="modal-stock-closing"/>
    <modal-add-stock-movement ref="modal-add-stock-movement"/>
  </div>
</template>
<script>

import MultiFilter from "@/components/Utils/MultiFilterV3.vue";
import List from './Shared/_List.vue'
import ModalStockClosing from './Shared/_ModalStockClosing.vue'
import ModalAddCmc from './Shared/_ModalAddCmc.vue'
import ModalAddStockMovement from './Shared/_ModalAddStockMovement.vue'

export default {
  components: {
    List,
    ModalStockClosing,
    ModalAddCmc,
    ModalAddStockMovement
  },
  name: "Index",
  data() {
    return {}
  },
  methods: {
    handleOpenModalSearchProductServiceHub() {
      this.$refs.ModalAddCmc.handleCreateModal()
    },
    handleShowModalClosing() {
      this.$refs["modal-stock-closing"].handleCreateModal()
    },
    handleOpenModalAddStockMovement(cmc) {
      const title = `${cmc.product_name} | ${cmc.supplier_name} (${cmc.origin_name})`
      this.$refs["modal-add-stock-movement"].handleCreateModal({
        cmc: cmc,
        type: 1,
        title: title
      })
    },
  },

}
</script>
