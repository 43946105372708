export const SET = 'SET'
export const SET_AVERAGE = 'SET_AVERAGE'
export const SET_THEORICAL_STATISTICAL = 'SET_THEORICAL_STATISTICAL'
export const SET_REAL_STATISTICAL = 'SET_REAL_STATISTICAL'
export const SET_STANDARD_DEVIATION = 'SET_STANDARD_DEVIATION'
export const SET_PIE_CHART = 'SET_PIE_CHART'
export const SET_VARIATION_GAUGE_CHART = 'SET_VARIATION_GAUGE_CHART'
export const SET_COEFFICIENT_GAUGE_CHART = 'SET_COEFFICIENT_GAUGE_CHART'
export const SET_RESPONSIBILITY_BAR_CHART = 'SET_RESPONSIBILITY_BAR_CHART'
export const SET_OS_BAR_CHART = 'SET_OS_BAR_CHART'

