<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <input @change="handlePriceDecimalPlace" type="checkbox" class="ml-3" />
            <span class="text-dark"> Adotar preço com 3 casas decimais </span>
            <div>
              <!-- Tipo -->
              <div>
                <div class="form-group row m-1 p-0">
                  <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">Tipo </label>
                  <div class="col-md-8">
                    <base-button block size="sm" type="primary">
                      {{ item.type_name }}
                    </base-button>
                  </div>
                </div>
              </div>
              <!-- Descrição -->
              <div class="form-group row m-1 p-0">
                <label class="col-md-4 pb-0 pt-2 mb-1 col-form-label form-control-label"> Descrição </label>
                <div class="col-md-8">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      disabled
                      :value="`${item.product_service_hub.product_name} (${item.product_service_hub.category_name})`"
                      class="form-control form-control-sm"
                    />
                  </base-input>
                </div>
              </div>
              <!-- NCM -->
              <div v-if="item.product_service_hub.ncm">
                <div class="form-group row m-1 p-0">
                  <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label"> NCM </label>
                  <div class="col-md-8">
                    <base-input input-group-classes="input-group-sm">
                      <input disabled v-model="item.product_service_hub.ncm" class="form-control form-control-sm" />
                    </base-input>
                  </div>
                </div>
              </div>
              <!-- Unid. de Compra -->
              <div>
                <div v-show="!default_product_service_hub_types.includes(item.product_service_hub.product_service_hub_type_id)" class="form-group row m-1 p-0">
                  <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label"> Unid. de Compra </label>
                  <div class="col-md-8">
                   <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input disabled v-model="item.product_service_hub.purchase_unit" class="form-control form-control-sm" />
                    </base-input>
                   </validation-provider>
                  </div>
                </div>
              </div>
              <!-- Quantidade -->
              <div>
                <div
                  v-show="!default_product_service_hub_types.includes(item.product_service_hub.product_service_hub_type_id) || entry_launch_types[item.product_service_hub.product_service_hub_type_id] === 'Frete'"
                  class="form-group row m-1 p-0"
                >
                  <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">
                    Quantidade
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-8">
                    <validation-provider rules="required">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          @change="getTotalValue"
                          v-on:input="item.quantity = formatString($event.target.value, '0', '99999.999', 3)"
                          inputmode="numeric"
                          v-model="item.quantity"
                          class="form-control form-control-sm"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
              </div>
              <!-- Preço -->
              <div>
                <div class="form-group row m-1 p-0">
                  <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">
                    {{
                      default_product_service_hub_types.includes(item.product_service_hub.product_service_hub_type_id) &&
                      entry_launch_types[item.product_service_hub.product_service_hub_type_id] !== 'Frete'  ?
                      'Total' : 'Preço'
                    }}
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-8">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        @input="getTotalValue"
                        v-money="money"
                        v-model.lazy="item.price"
                        maxlength="13"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                </div>
              </div>
              <!-- Desconto Unitário -->
              <div v-if="showUnitDiscount">
                <div class="form-group row m-1 p-0">
                  <label class="col-md-4 pt-2 pb-0 mb-1 col-form-label form-control-label">
                    Desconto Total
                  </label>
                  <div class="col-md-8">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        @input="getTotalValue"
                        v-money="money"
                        v-model.lazy="item.discount"
                        maxlength="13"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                </div>
              </div>
              <!-- Total -->
              <div
                v-show="!default_product_service_hub_types.includes(item.product_service_hub.product_service_hub_type_id) || entry_launch_types[item.product_service_hub.product_service_hub_type_id] === 'Frete'"
                class="form-group row m-1 p-0"
              >
                <label class="col-md-4 pb-0 pt-2 col-form-label form-control-label"> Total </label>
                <div class="col-md-8">
                  <base-input input-group-classes="input-group-sm">
                    <input disabled v-model="human_total_value" class="form-control form-control-sm" />
                  </base-input>
                </div>
              </div>
              <div class="modal-footer d-flex justify-content-center">
                <base-button type="secondary" @click="closeModal('create')"> Cancelar </base-button>
                <base-button type="success" native-type="submit" v-bind:disabled="invalid || item.total_value <= 0" :loading="loadingStore">
                  Salvar
                </base-button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
    <ModalSearchProductServiceHub @selected="selectedProductServiceHub" ref="modalSearchProductServiceHub" />
  </div>
</template>

<script>
import { VMoney } from "v-money";
import ModalSearchProductServiceHub from "../../ProductService/Shared/_ModalSearchProductServiceHub";
import { formatErrorValidation } from "@/plugins";
import entryLaunchMixin from "../Mixins/entry_launch_mixin";
import entryLaunchItemMixin from "../Mixins/entry_launch_item_mixin";
import {mapGetters} from "vuex";
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "ModalCreateEntryLaunchItem",
  mixins: [entryLaunchMixin, entryLaunchItemMixin],
  data() {
    return {
      modal: {
        title: "Adicionar Item",
        create: false,
      },
      item: {
        type_name:null,
        quantity: null,
        discount: 0,
        price: null,
        total_value: 0,
        is_fuel_group: false,
        anp_code: null,
        anp_description: null,
        consumer_uf: null,
        cest: null,
        product_service_hub_id: null,
        product_service_hub: {},
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      loadingStore: false,
      loadingState: true,
      entry_launch_id: false,
      showUnitDiscount: false,
    };
  },
  components: {
    ModalSearchProductServiceHub,
    PuzlSelect,
  },
  directives: { money: VMoney },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal(entry_launch_type,entity_id, entry_launch_id = false, showUnitDiscount = false) {
      this.entry_launch_id = entry_launch_id;
      this.money.precision = 2;
      this.showUnitDiscount = showUnitDiscount;
      this.$refs.modalSearchProductServiceHub.openModal(entity_id, entry_launch_type);
    },
    store() {
      /** Caso não seja necessário persistir no banco */
      if (this.entry_launch_id === false) {
        let item = this.$helper.cloneObject(this.item);
        item.price = Number(this.$helper.moneyToFloat(this.item.price)).toFixed(3);
        this.$emit("added", item);
        this.closeModal();
      } else {
        this.$Progress.start();
        this.loadingStore = true;
        this.item.entry_launch_id = this.entry_launch_id;
        this.item.price = this.item.price.replaceAll(".", "").replace(",", ".");
        this.$store
          .dispatch("entryLaunchItem/add", this.item)
          .then((response) => {
            this.loadingStore = false;
            this.$Progress.finish();
            this.$notify({
              type: response.error_type,
              message: response.message,
            });
            this.closeModal();
          })
          .catch((error) => {
            if (error.status === 200) {
              this.$notify({
                type: "danger",
                message: error.data.message,
              });
            } else if (error.response.status === 422) {
              let errors = formatErrorValidation(error.response.data.errors);
              this.$notify({
                type: "danger",
                message: errors,
              });
            }
            this.$Progress.finish();
            this.loadingStore = false;
          });
      }
    },
    selectedProductServiceHub(product_service_hub) {
      this.item = {
        type_name:product_service_hub.type_name,
        quantity: product_service_hub.quantity || null,
        price: product_service_hub.price || 0,
        discount: 0,
        total_value: 0,
        is_fuel_group: false,
        anp_code: null,
        anp_description: null,
        consumer_uf: null,
        cest: null,
        product_service_hub_id: product_service_hub.product_service_hub_id,
        product_service_hub: product_service_hub,
      };
      if(! this.item.product_service_hub.purchase_unit) {
        if(product_service_hub.product_service_hub_type_id === 2) {
          this.$store.dispatch("cmc/getMeasuredUnitBuy", product_service_hub.hubable_id).then((response) => {
            this.item.product_service_hub.purchase_unit = response.data.letter
          })
        } else {
          this.item.product_service_hub.purchase_unit = product_service_hub.purchase_unit || product_service_hub.product_service_purchase_unit
        }

      }
      this.modal.create = true;
    },
    handlePriceDecimalPlace() {
      this.money.precision = this.money.precision === 2 ? 3 : 2;
    },
  },
  computed: {
    human_total_value() {
      return `${this.item.total_value.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}`;
    },
  },
};
</script>

<style scoped>
.modal {
  overflow-y: scroll !important;
}
</style>
