<template>
<div>
    <div class="container-fluid">
        <multi-filter ref="multi-filter" @fetch="init" :filter="filter" /> 
        <div class="row card-wrapper" v-show="loadingSkeleton">
            <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
        </div>

        <PuzlEmptyData v-if="!leftOvers.length && !loadingSkeleton"></PuzlEmptyData>

        <div class="row card-wrapper" v-if="leftOvers.length && !loadingSkeleton">
            <div class="col-lg-4" v-for="(leftOver, index) in leftOvers" :key="index">
                <!-- Basic with action button -->
                <card :class="leftOver.status === 1 ? 'card-border-top-success' : 'card-border-top-danger'">
                    <!-- Card header -->
                     <div class="row align-items-center mb-3">
                        <div class="col-8">
                            <!-- Title -->
                            <h5 class="h3 mb-0">{{leftOver.reason}}</h5>
                        </div>
                        <div class="col-4 text-right">
                            <base-dropdown menuOnRight>
                                <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                                    <i class="fas fa-cog"></i>
                                </base-button>
                                <div class="dropdown-divider p-0 m-0"></div>
                                <a class="dropdown-item" @click="handleEditLeftOverAdjustment(leftOver.uuid)">
                                    <i class="fas fa-edit text-warning"></i>
                                    Editar
                                </a>
                                <div class="dropdown-divider p-0 m-0"></div>
                                <a class="dropdown-item" @click="handleDeleteLeftOverAdjustment(leftOver.uuid)">
                                    <i class="fas fa-times text-danger"></i>
                                    Excluir
                                </a>
                            </base-dropdown>
                        </div>
                    </div>

                    <!-- Card body -->
                    <collapse class="border rounded p-0 mt-3">
                        <collapse-item name="1" class="header-gray card-body-molde" back-ground="border-header-default-bottom">
                           <h5 slot="title" class="mb-0">Observações</h5>
                             <!--Start Observation-->
                            <div class="row">
                                <div class="col-md-12 text-center">
                                    <small> {{leftOver.observation}} </small>
                                </div>
                            </div>
                        </collapse-item>
                    </collapse>
                </card>
            </div>
        </div>
    </div>
    <modal-edit-left-over ref="editLeftOver"></modal-edit-left-over>
    <loading-pagination :show="loading && !loadingSkeleton"/>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import modalEditLeftOver from './_ModalEditLeftOver';
import PuzlEmptyData from "@/components/PuzlEmptyData";
import MultiFilter from "@/components/Utils/MultiFilterV2";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import {
    formatErrorValidation
} from '@/plugins'
export default {
    name: "ListLeftOverAdjustment",
    mixins: [cursorPaginate],
    components: {
        SkeletonPuzlGrid,
        modalEditLeftOver,
        PuzlEmptyData,
        MultiFilter,
        cursorPaginate,
        LoadingPagination,
    },
    data() {
        return {
            loadingSkeleton: false,
            filter: {},
        }
    },
    computed: {
        ...mapGetters({
            'leftOvers': 'adjustmentLeftOver/fetch',
        }),
    },
    watch: {
        'added'() {
            this.init()
        }
    },
    props: {
        added: Boolean
    },
    methods: {
        init(filter = null) {
            this.startCursor(filter)
            this.$Progress.start();
            this.$store.dispatch('adjustmentLeftOver/fetchItemsPaginate', {
                    filter: this.filter,
                    next_page: this.paginate.nextUrl
                })
                .then((response) => {
                    this.$Progress.finish()
                    this.resolveCursor(response)
                })
                .catch((error) => {
                    this.resolveCursor()
                    this.$Progress.finish()
                })
        },
        handleDeleteLeftOverAdjustment(uuid) {
            this.$Swal.confirmDelete().then((result) => {
                if (result.isConfirmed) {
                    this.$Progress.start();
                    this.$notify({
                        type: 'info',
                        message: 'Estamos trabalhando em sua solicitação.'
                    });
                    this.$store.dispatch('adjustmentLeftOver/destroy', uuid).then(()=>this.$Progress.finish())
                }
            }).catch(() => this.$Progress.finish())
        },
        handleEditLeftOverAdjustment(uuid) {
            this.$refs.editLeftOver.handleEditModal(uuid)
        }
    },

    mounted() {
        this.init({})
    }
}
</script>

<style scoped>

</style>
