<template>
<div>
    <base-header class="bg-gray-content">
      <!-- Start Breadcrumb -->
      <PuzlBreadcrumb/>
      <!-- End Breadcrumb -->
        <!-- Header Menus -->
        <div class="row">
          <div class="col-md-2 col-xlg-2 mb-3">
            <base-button block size="lg" type="success" class="text-uppercase p-3" @click.prevent="handleCreate()">
              <i class="fas fa-plus"></i> novo
            </base-button>
          </div>
          <div class="col-md-2 col-xlg-2 mb-3">
            <router-link :to="{
                    path: '/technology/test',
                    name: 'technology.test.index',
                    }"
            >
              <base-button block size="lg" type="light" class="text-uppercase p-3">
                voltar
              </base-button>
            </router-link>
          </div>
        </div>
    </base-header>
  <List />
  <Create ref="createFreshState" />
</div>
</template>

<script>
import {mapGetters} from 'vuex'
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import List from "./Shared/_List"
import Create from "./Shared/_Create"
export default {
  name: "IndexFreshState",
  components: {
    Create,
    PuzlBreadcrumb,
    List
  },
  data() {
    return {
    }
  },
  methods: {
    handleCreate(){
      this.$refs.createFreshState.handleCreateModal()
    },
  },
}
</script>

<style scoped>

</style>
