<template>
  <div>
    <modal class="bill-modal-create" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div v-if="modal.create">
        <div class="p-2">
          <div v-if="$_items.length" class="border-left border-3 border-danger pr-1 mt-0 mb-2">
            <div class="col-md-12" >
              <h4 class="m-0">{{ $_items[0].business_name }}</h4>
              <h4 class="m-0 font-weight-normal">{{ $_items[0].ein }}</h4>
            </div>
          </div>
          <div v-if="$_items.length" class="border-left border-3 border-success pr-1 mt-3 mb-2">
            <div class="col-md-12">
              <h4 class="m-0">{{ $_items[0].entity_name }}</h4>
              <h4 class="m-0 font-weight-normal">{{ $_items[0].document }}</h4>
            </div>
          </div>
          <PuzlEmptyData v-if="$_items.length === 0 && loading === false" />
          <div 
            :class="{['row'] : entry_launch.photo || entry_launch.photo || entry_launch.observations}" 
            class="mt-4" @click.prevent="getEntryLaunchItems(entry_launch)" 
            v-for="(entry_launch, index) in $_items" :key="index"
          >
            <div :class="{['col-10'] : entry_launch.photo || entry_launch.observations}">
              <collapse class="border rounded p-0">
                <collapse-item name="1" class="header-gray" back-ground="border-header-primary-bottom">
                  <h5 slot="title" class="mb-0">
                    {{ `${entry_launch.issue_date} | Documento ${entry_launch.document_number} | ` }}
                    {{ entry_launch.final_value | currency }}
                  </h5>
                  <SkeletonPuzlFullWidth v-show="loading === true && !entry_launch.items" />
                  <div v-if="loading === false || (entry_launch.items && entry_launch.items.length)">
                    <div v-for="(item, indexItem) in entry_launch.items" :key="indexItem" class="timeline timeline-one-side pb-2">
                      <div class="timeline-block">
                        <span class="timeline-step badge-success"> {{ indexItem + 1 }} </span>
                      </div>
                      <div class="timeline-content row m-0 ml-4">
                        <div class="col-8">
                          <div class="border-left border-3 border-primary">
                            <div class="ml-2">
                              <h5 class="mb-0 col-12 p-0">{{ `${item.product_name} (${item.category_name})` }}</h5>
                              <h5 class="mb-0 font-weight-normal col-12 p-0">{{ entry_launch.type_name }}</h5>
                            </div>
                          </div>
                        </div>
                        <div class="col-4 pl-0 pr-0 d-flex justify-content-end row text-right">
                          <h5 class="mb-0 col-12 p-0">{{ item.total_value | currency }}</h5>
                          <h5 class="mb-0 font-weight-normal col-12 p-0">{{ `${item.quantity.replace(".", ",")} ${item.purchase_unit}` }}</h5>
                          <h5 class="mb-0 font-weight-normal col-12 p-0">{{ item.price | currency }} / {{ item.purchase_unit }}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </collapse-item>
              </collapse>
            </div>
            <div v-if="entry_launch.observations" class="col-1 d-flex align-items-center pl-0"> 
              <el-popover trigger="hover" placement="bottom" class="p-0 pr-0">
                <span> {{ entry_launch.observations }} </span>
                <base-button outiline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none">
                  <i class="fa-2x fa-regular fa-comment-dots text-muted"></i>
                </base-button>
              </el-popover>
            </div>
            <div v-if="entry_launch.photo" class="col-1 d-flex align-items-center pl-0"> 
              <a
                @click.prevent="handleModalAttachment(entry_launch.photo)"
                role="button"
              >
                <i class="text-green fa-2x fa-solid fa-paperclip pt-1" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <ModalAttachmentView ref="modalAttachmentView" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SkeletonPuzlFullWidth from "@/components/SkeletonPuzlFullWidth";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import ModalAttachmentView from "../../../Configuration/EntryLaunch/Shared/_ModalAttachmentView";

export default {
  name: "ModalBillPayDocument",
  components: { SkeletonPuzlFullWidth, PuzlEmptyData, ModalAttachmentView },
  data() {
    return {
      modal: {
        title: "Contas a pagar | Documentos",
        create: false,
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      $_items: "entryLaunch/fetch",
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal(bill_pay_id) {
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });
      this.$store
        .dispatch("entryLaunch/getByBillPay", bill_pay_id)
        .then((response) => {
          this.$notify({ type: response.error_type, message: response.message });
          this.modal.create = true;
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: error.data.message,
          });
        });
    },
    getEntryLaunchItems(entry_launch) {
      if (entry_launch.items === undefined) {
        this.loading = true;
        this.$store.dispatch("entryLaunchItem/getByEntryLaunch", entry_launch.id).then((response) => {
          this.$store.dispatch("entryLaunch/setItems", { data: response.data, entry_launch_id: entry_launch.id });
          this.loading = false;
        });
      }
    },
    handleModalAttachment(item) {
      this.$refs.modalAttachmentView.openModal(item);
    },
  },
  mounted() {},
};
</script>

<style></style>
