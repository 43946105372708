<template>
  <div>
    <div class="container-fluid">
      <div class="row card-wrapper" v-show="loading > 0">
        <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <div class="row align-items-center" v-if="source.length == 0 || loading == 0">
        <div class="col-md-12 text-center">
          <div class="card p-4">
            Sem dados cadastrados!
          </div>
        </div>
      </div>
      <div class="row card-wrapper">
        <div class="col-lg-4" v-for="(deductionRule, index) in source" :key="index">
          <card :class="'card-border-top-'+deductionRule.status.class">
            <div class="row align-items-center mb-3">
              <div class="col-12">
                <div class="row mb-4">
                 <h3 class="col-9">
                    <strong>{{ deductionRule.date_at | parseDate("DD MMM YYYY") }}</strong>
                    <br/>
                    <h4>
                      {{ deductionRule.rule }}
                      <span 
                        v-if="deductionRule.status.status === 1" 
                        class="badge mr-1 ml-1" 
                        style="background-color: #d3d3d3">
                        (Em Vigência)
                      </span>
                    </h4>
                  </h3>
                  <div class="col-3 text-right">
                   <base-dropdown menuOnRight>
                     <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                       <i class="fas fa-cog"></i>
                     </base-button>
                     <a class="dropdown-item" href="#" @click.prevent="editDeductionRule(deductionRule)">
                       <i class="fas fa-edit text-warning"></i>
                       <span>Editar</span>
                     </a>
                     <a
                       class="dropdown-item" href="#" @click.prevent="removeDeductionRule(deductionRule.uuid)"
                     >
                       <i class="fas fa-times text-danger main-icon-menu"></i>
                       <span>Excluir</span>
                     </a>
                   </base-dropdown>
                  </div>
                </div>
              </div>
            </div>
            <collapse class="border rounded p-0 mt-3" v-if="deductionRule.rule == 'Dedução'">
              <collapse-item name="1" class="header-gray card-body-molde"
                             back-ground="border-header-primary-bottom">
                <h5 slot="title" class="mb-0 text-center">Alíquotas</h5>
                <div class="row">
                  <div class="col-md-12 text-center">
                    <small>
                      Condição <br><strong>{{deductionRule.condition != null ? deductionRule.condition : 'Não há condições'}}</strong>
                    </small>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-center">
                    <small>
                      Dedução <br><strong>{{deductionRule.deduct}}</strong>
                    </small>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-center">
                    <small>
                      Base de cálculo <br><strong>{{deductionRule.base}}%</strong>
                    </small>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-center">
                    <small>
                      Alíquota total <br><strong>{{deductionRule.total_rate}}%</strong>
                    </small>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-center">
                    <small>
                      Alíquota retida <br><strong>{{deductionRule.retained_rate}}%</strong>
                    </small>
                  </div>
                </div>
              </collapse-item>
            </collapse>
            <collapse class="border rounded p-0 mt-3" v-if="deductionRule.rule == 'Taxa Fixa'">
              <collapse-item name="1" class="header-gray card-body-molde"
                             back-ground="border-header-primary-bottom">
                <h5 slot="title" class="mb-0 text-center">Alíquotas</h5>
                <div class="row">
                  <div class="col-md-12 text-center">
                    <small>
                      Alíquota total <br><strong>{{deductionRule.total_rate}}%</strong>
                    </small>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-center">
                    <small>
                      Alíquota retida <br><strong>{{deductionRule.retained_rate}}%</strong>
                    </small>
                  </div>
                </div>
              </collapse-item>
            </collapse>
            <collapse class="border rounded p-0 mt-3">
              <collapse-item name="1" class="header-gray card-body-molde"
                             back-ground="border-header-primary-bottom">
                <h5 slot="title" class="mb-0 text-center">Observações</h5>
                <div class="row">
                  <div class="col-md-12 text-center">
                    <small>
                    {{deductionRule.observation}}
                    </small>
                  </div>
                </div>
              </collapse-item>
            </collapse>
          </card>
        </div>
      </div>
    </div>
    <modal-edit ref="modal-edit"/>
  </div>
</template>

<script>
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import {mapGetters} from "vuex";
import ModalEdit from './_ModalEdit'
export default {
  props: ['source', 'loading'],
  name: "ListTributes",
  components: {
    SkeletonPuzlGrid,
    ModalEdit,
  },
  data() {
    return {
      types: [
        {
          id: 'service',
          text: 'Serviço',
        },
        {
          id: 'product',
          text: 'Produto',
        },
      ],
      calc_bases: [
        {
          id: 1,
          text: 'Faturamento'
        },
        {
          id: 2,
          text: 'Lucro'
        },
        {
          id: 3,
          text: 'Valor fixo'
        },
      ],
      rules: [
        {
          id: "DC",
          text: 'Dedução'
        },
        {
          id: "TF",
          text: 'Taxa Fixa'
        },
        {
          id: "VF",
          text: 'Valor fixo'
        },
      ],
      conditions: [
        {
          id: 1,
          text: 'Adotar sempre o Mcc',
        },
        {
          id: 2,
          text: 'Adotar sempre maior dedução',
        },
        {
          id: 3,
          text: 'Adotar sempre menor dedução',
        }
      ],
    }
  },
    computed: {
    ...mapGetters({
      'states': 'localizations/states',
      'cities': 'localizations/cities',
    }),
  },
  methods: {
    editDeductionRule(deductionRule) {
      this.$refs['modal-edit'].handleCreateModal(deductionRule)
    },
    getStates() {
      this.$Progress.start();
      this.$store.dispatch('localizations/fetchStates')
        .then((response) => {
          this.loaders--
          this.loadingCities = false
          this.$Progress.finish()
        })
        .catch((error) => {
          this.loaders--
          this.loadingCities = false
          this.$Progress.finish()
        });
    },
    getCities(state) {
      this.$Progress.start();
      this.loadingCities = true
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch('localizations/fetchCities', { uf: state })
        .then((response) => {
          this.$notify({type: 'success', message: response.message});
          this.loadingCities = false
          this.loaders--
          this.$Progress.finish()
        })
        .catch((error) => {
          this.loaders--
          this.loadingCities = false
          this.$Progress.finish()
        });
    },
    removeDeductionRule(uuid) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$notify({
            type: 'info',
            message: 'Estamos trabalhando em sua solicitação.'
          });
          this.$store.dispatch('deductionRules/destroy', uuid)
            .then(response => {
              this.$Progress.finish()
              this.$emit('init')
              this.$notify({type: 'success', message: response.message})
            })
            .catch(error => {
              this.$notify({type: error.data.error_type, message: error.data.message})
              this.$Progress.finish()
            })
        }
      })
    },
    handleEditTax(tribute) {
      this.$refs["modal-edit"].handleEditModal(tribute)
    }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
