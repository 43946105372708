<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
            <div>

              <div class="checklist-item checklist-item-primary ml-3 mb-1">
                <div class="checklist-info">
                  <b class="">{{ cmc_price.cmc_product_name }}</b>
                  <p class="text-dark">{{ cmc_price.cmc_supplier_name }} ({{ cmc_price.cmc_origin_name }})</p>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                  Central
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8">
                  <validation-provider rules="required">
                    <base-input input-classes="form-control-sm">
                      <puzl-select 
                        v-model="cmc_price.company_plant_id" 
                        :items="plants" 
                        :loading="loadingPlant" 
                        :disabled="loadingPlant" />
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                  Preço
                  <span class="text-danger">&nbsp;*</span>
                </label>

                <div class="col-md-8">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input inputmode="numeric"
                             @input="changeCmcPrice"
                             v-model="cmc_price.price" v-mask="['#,##', '##,##', '###,##', '####,##']"
                             class="form-control form-control-sm" input-group-classes="input-group-sm"
                      >
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          {{ initialsBuy }}
                        </small>
                      </template>
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0">
                          R$
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>


              <div class="form-group row m-0 p-0">
                <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label">
                  Inicio Validade
                </label>
                <div class="col-md-8">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <el-date-picker
                        size="small"
                        v-model="cmc_price.validity_start"
                        type="date"
                        placeholder="Inicio"
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions"
                      >
                      </el-date-picker>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="row m-0 p-0">
                <label class="col-4 pb-0 mb-1 col-form-label form-control-label">
                  Preço (seco)
                </label>
                <div class="col-8 mt-2 text-green">
                  <b>
                    R$ {{ pricePerKg().toFixed(5).toString().replace('.', ',') }} /kg
                  </b>
                </div>
              </div>
              <div class="row m-0 p-0 p-0" v-if="params && params.average_humidity">
                <label class="col-4 mt-1 form-control-label">
                  Umidade
                </label>
                <div class="col-8 text-primary">
                   <span style="font-size: 0.8rem" v-if="params && params.average_humidity">
                <i class="fa-solid fa-droplet text-info"></i> <b
                     class="text-primary">{{ params && params.average_humidity.replace('.', ',') }} %</b>
              </span>
                </div>
              </div>

            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="openModal(false)">
                Cancelar
              </base-button>
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                :loading="loadingSave"
              >
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>
<script>
import {mapGetters} from "vuex"
import moment from 'moment';
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "ModalCreateCmcPrice",
  components: {
    PuzlSelect,
  },
  data() {
    return {
      initialsBuy: '',
      search: {
        string: '',
        types: 3,
        loading: false,
      },
      modal: false,
      title: 'Adicionar preço MCC',
      validated: false,
      params: null,
      cmc_price: {
        cmc_id: '',
        company_plant_id: '',
        type: 1,
        entity_id: null,
        price: '',
        validity_start: null,
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      loadingSave: false,
      loadingPlant: true,
      loadingCarrier: true,
    };
  },
  computed: {
    ...mapGetters({
      'plants': 'plant/activeItems',
      'carriers': 'cmc/fetchCarrierPrice',
    }),
  },
  watch: {
    'cmc_price.company_plant_id': function (value) {
      if (!value) {
        return
      }
      this.getCarrierUpcomingPrice()
    },
  },
  methods: {
    openModal(open) {
      this.modal = open
    },
    changeCmcPrice() {
    },
    save() {
      this.$Progress.start()
      this.loadingSave = true
      this.$store.dispatch('cmc/addPrice', this.cmc_price)
        .then(response => {
          this.$Progress.finish()
          this.$notify({type: response.error_type, message: response.message})
          this.$emit("fetchCmc");
          this.loadingSave = false
          this.modal = false
        })
        .catch(error => {
          if (error.status) {
            this.$Progress.finish()
            this.loadingSave = false
            this.$notify({type: error.data.error_type, message: error.data.message})
          } else {
            if (error.response.status === 422) {
              let message = formatErrorValidation(error.response.data.errors)
              this.$notify({type: 'danger', message: message})
              this.$Progress.finish()
              this.loadingSave = false
            } else {
              this.$notify({type: error.data.error_type, message: error.data.message})
              this.$Progress.finish()
              this.loadingSave = false
            }
          }
        })
    },
    /**
     * Formata o preço por kg
     * @param mt unidade de medida
     * @param mu massa unitária
     * @returns {number|*}
     */
    pricePerKg() {
      if (!this.cmc_price.price) {
        return 0
      }
      const price = this.$helper.strToNum(this.cmc_price.price)
      switch (this.params.measured_unit_id) {
        case 1: // m3
          return price / this.params.bulk_density / 1000;
        case 26: // l
          return price / this.params.bulk_density;
        case 4: // ton
          return (price / 1000) / (1 - this.params.average_humidity / 100);
        case 8: // g
          return price * 1000;
        default:
          return price;
      }
    },
    /**
     * @param {number} cmc_id
     * @param {string} initialsBuy
     * @param {string} cmc_product_name
     * @param {string} cmc_supplier_name
     * @param {string} cmc_origin_name
     * @param {object} params
     */
    handleCreate(cmc_id, initialsBuy, cmc_product_name, cmc_supplier_name, cmc_origin_name, params = null, company_plant_id = null, average_price = null) {
      this.cmc_price = {
        cmc_id: cmc_id,
        cmc_product_name: cmc_product_name,
        cmc_supplier_name: cmc_supplier_name,
        cmc_origin_name: cmc_origin_name,
        type: 1,
        company_plant_id: company_plant_id ?? '',
        entity_id: null,
        price: average_price ? average_price.toFixed(2) : '',
        validity_start: moment().format('YYYY-MM-DD'),
      };
      this.params = params
      this.initialsBuy = initialsBuy;
      this.openModal(true);
    },
    getCarrierUpcomingPrice() {
      this.$Progress.start();
      this.$store.dispatch('cmc/fetchCarrierByCmcIdByPlanId',
        {cmcId: this.cmc_price.cmc_id, plantId: this.cmc_price.company_plant_id}
      ).then(({data}) => {
        this.$Progress.finish();
        this.search.loading = false;
      })
        .catch((error) => {
          this.$Progress.finish();
          this.search.loading = false;
        });
    },
  },
  mounted() {
    this.$refs.formValidator.validate()
    this.$store.dispatch('plant/fetchItemsActive').then(() => {
      this.loadingPlant = false
    })
  }
};

</script>
<style></style>
