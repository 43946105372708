import { LoginUnauthorizedException, ValidationException } from ".";
import { dialogs } from "../../helpers";
const { formatErrorValidation } = require("@/plugins");

/**
 * Lida com diferentes tipos de erros
 * 
 * @param {Error} err - O erro a ser tratado
 * @returns {void}
 */
const handleErrors = (err) => {
  switch (true) {
    case err instanceof ValidationException:
      dialogs.notify('danger', err.getFormatedError());
      break;
    case err instanceof LoginUnauthorizedException:
      console.error("Usuário não autorizado: ", formatErrorValidation(err.data));
      break;
    default:
      console.error("Erro não catalogado: ", err);
      break;
  }
};

/**
 * Lida com erros globais do JavaScript.
 * 
 * @param {string} message - Mensagem de erro.
 * @param {string} source - URL do script onde o erro foi gerado.
 * @param {number} lineno - Número da linha onde o erro foi gerado.
 * @param {number} colno - Número da coluna onde o erro foi gerado.
 * @param {Error} error - O objeto de erro.
 * @returns {void}
 */
const handleGlobalError = (message, source, lineno, colno, error) => {
  console.error("Global JS Error:", { message, source, lineno, colno, error });
  handleErrors(error);
};

/**
 * Lida com rejeições de promessas não tratadas.
 * 
 * @param {PromiseRejectionEvent} event - O objeto de evento para a rejeição.
 * @returns {void}
 */
const handleUnhandledRejection = (event) => {
  console.error("Unhandled Rejection:", event);  
  handleErrors(event.reason);
};

/**
 * Lida com erros em componentes Vue.
 * 
 * @param {Error} err - O objeto de erro.
 * @param {Vue} vm - A instância Vue onde o erro ocorreu.
 * @param {string} info - Informações específicas do erro no Vue.
 * @returns {void}
 */
const handleVueError = (err, vm, info) => {
  console.error("Vue Error:", { err, vm, info });
  handleErrors(err);
};

export default {
  handleGlobalError,
  handleUnhandledRejection,
  handleVueError,
};