<template>
  <div>
    <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
      <form @submit.prevent="handleSubmit(save)" class="needs-validation" autocomplete="off">
        <div class="container-fluid">
          <div class="row mt-2 mb-3">
            <div class="col-12" style="display: flex; align-items: center;">
              <img src="/img/icons/icons8/ios/info-squared_warning.png" width="22">
              <h4 class="new-default-black-font m-0 mx-3 ">
                Identificação
              </h4>
              <hr class="ml-3 m-0" style="width: 100%;">
            </div>
          </div>
          <div class="row mt-2 align-items-center">
            <div class="col-6">
              <h4 class="new-default-black-font font-weight-normal m-0">
                Status
              </h4>
            </div>
            <div class="col-6">
              <validation-provider rules="required">
                <base-switch
                    type="success"
                    offText="Inativo"
                    :disabled="isReservedRegistry()"
                    onText="Ativo"
                    v-model="is_active"
                    class="success" />
              </validation-provider>
            </div>
          </div>
          <div class="row mt-2 align-items-center">
            <div class="col-6">
              <h4 class="new-default-black-font font-weight-normal m-0">
                Movimento
              </h4>
            </div>
            <div class="col-6">
              <validation-provider rules="required">
                <PuzlSelect
                  v-model="financeType.mode"
                  :items="modeItems"
                  :clearable="false"
                  :multiple="false"
                  :disabled="financeType.id != null || isReservedRegistry()"
                />
              </validation-provider>
            </div>
          </div>
          <div class="row mt-2 align-items-center">
            <div class="col-6 mt-1">
              <h4 class="new-default-black-font font-weight-normal m-0">
                Descrição
              </h4>
            </div>
            <div class="col-6">
              <validation-provider>
                <base-input input-group-classes="input-group-sm">
                  <input
                    placeholder="..."
                    v-model="financeType.name"
                    type="text"
                    :disabled="isReservedRegistry()"
                    class="form-control form-control-sm"
                  />
                </base-input>
              </validation-provider>
            </div>
          </div>
          <div class="row mt-4 mb-3">
            <div class="col-12" style="display: flex; align-items: center;">
              <img src="/img/icons/icons8/ios/weak-financial-growth_success.png" width="22">
              <h4 class="new-default-black-font m-0 mx-3" style="white-space: nowrap;">
                Plano de contas
              </h4>
              <hr class="ml-3 m-0" style="width: 100%;">
            </div>
          </div>
          <div class="row mt-2 align-items-center">
            <div class="col-6">
              <h4 class="new-default-black-font font-weight-normal m-0">
                Gerencial
              </h4>
            </div>
            <div class="col-6">
              <validation-provider rules="required" v-slot="{errors}">
                <base-input input-classes="form-control-sm">
                  <PuzlSelect
                    placeholder="Plano de Contas"
                    :items="chartAccounts"
                    :class="errors[0] ? 'is-invalid' : ''"
                    :clearable="false"
                    :labelMask="true"
                    :multiple="false"
                    label="$code$ $name$"
                    :selectable="(i) => i.extra_field.length"
                    extraField="parents"
                    v-model="financeType.management_chart_account_id"
                    :loading="loadingChartAccounts"
                  />
                </base-input>
              </validation-provider>
            </div>
          </div>
          <div v-if="financeType.updated_by_user_name || financeType.created_by_user_name" class="row mt-4 mb-3">
            <div class="col-12" style="display: flex; align-items: center;">
              <img src="/img/icons/icons8/ios/order-history_secondary.png" width="22">
              <h4 class="new-default-black-font m-0 mx-3" style="white-space: nowrap;">
                Histórico
              </h4>
              <hr class="ml-3 m-0" style="width: 100%;">
            </div>
          </div>
          <div v-if="financeType.created_by_user_name" class="row mt-2 align-items-center">
            <div class="col-6">
              <h4 class="new-default-black-font font-weight-normal m-0">
                Última alteração
              </h4>
            </div>
            <div class="col-6 text-right">
              <h4 class="new-default-black-font font-weight-normal m-0">
                {{ $helper.parseDate(financeType.created_at, 'DD MMM YYYY - HH:mm')  }}
                | {{ financeType.created_by_user_name }}
              </h4>
            </div>
          </div>
          <div v-if="financeType.updated_by_user_name" class="row mt-2 align-items-center">
            <div class="col-6">
              <h4 class="new-default-black-font font-weight-normal m-0">
                Criação
              </h4>
            </div>
            <div class="col-6 text-right">
              <h4 class="new-default-black-font font-weight-normal m-0">
                {{ $helper.parseDate(financeType.updated_at, 'DD MMM YYYY - HH:mm')  }}
                | {{ financeType.updated_by_user_name }}
              </h4>
            </div>
          </div>
          <div class="row mt-5 mb-4">
            <div class="col-12 text-right">
              <base-button type="danger"
                size="sm"
                style="width: 150px;" @click.prevent="$emit('close')">
                <div style="display: inline-flex; align-items: center;">
                  <img src="/img/icons/cancel-white.png" width="20">
                  <i class="m-0 ml-1" style="font-style: normal;">
                    Cancelar
                  </i>
                </div>
              </base-button>
              <base-button type="success"
                size="sm"
                :disabled="financeType.management_chart_account_id == null"
                style="width: 150px;"
                native-type="submit">
                <div style="display: inline-flex; align-items: center;">
                  <img src="/img/icons/save.png" width="20">
                  <i class="m-0 ml-1" style="font-style: normal;">
                    Salvar
                  </i>
                </div>
              </base-button>
            </div>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>
<script>
import { formatErrorValidation } from "@/plugins";
import PuzlSelect from "@/components/PuzlSelect";

const FINANCE_TYPE_REFERENCE_KEY_ENUM = Object.freeze({
  CUSTOMER_RECEPTION: '0f97dd4f-882e-4294-8c5d-1b682c55f35b',
  SUPPLIER_PAYMENT: 'af23a2c1-4128-4922-befe-c41ee170395c',
  CUSTOMER_ANTICIPATION: '11d32dd6-51bc-454f-ae47-453964bd493f',
  CREDIT_TRANSFER_BETWEEN_ACCOUNTS: 'aa38b0fa-8094-4ab5-9fbe-be0838012e55',
  DEBIT_TRANSFER_BETWEEN_ACCOUNTS: '7888421e-8319-4c43-870b-3b0add75d001',
});

export default {
  components: {
    PuzlSelect
  },
  name: "FinanceTypeForm",
  props: {
    financeType: { type: Object },
  },
  data() {
    return {
      modal: false,
      title: 'Tipo de operação bancária',
      loading: false,
      loadingChartAccounts: true,
      chartAccounts: [],
      is_active: true,
      financeTypeReferenceKeyEnum: FINANCE_TYPE_REFERENCE_KEY_ENUM,
      modeItems: [
        {
          "name": 'Recebimento/Crédito',
          "id": 0
        },
        {
          "name": 'Pagamento/débito',
          "id": 1
        }
      ],
    }
  },
  methods: {
    /**
     * Verifica se é um registro reservado pelo sistema e bloqueia a edição do campo caso for
     *
     * @returns {bool} Retorna true se for a chave de referência
     */
    isReservedRegistry(){
      if(!this.financeType.reference_key) return false;
      return Object.values(this.financeTypeReferenceKeyEnum).includes(this.financeType.reference_key);
    },
    save(){
      const payload = {...this.financeType};
      payload.is_active = this.is_active;

      const action = this.financeType.id ? "financeTypes/update" : "financeTypes/add";

      this.$store
        .dispatch(action, payload)
        .then(response => {
          this.$emit('close');
        })
        .catch(error => {
          const errors = error && error.response && error.response.status === 422
              ? formatErrorValidation(error.response.data.data)
              : error.response.data.message;
          this.$notify({ type: "danger", message: errors });
        });
    },
    fetchChartAccounts(){
      this.$store.dispatch("chartAccount/fetchItems").then(response => {
        this.chartAccounts = response.data;
        this.chartAccounts.sort((a, b) => parseInt(a.code.substring(0, 1)) - parseInt(b.code.substring(0, 1)));
        this.loadingChartAccounts = false;
      });
    },
  },
  mounted() {
     this.is_active = this.financeType.is_active;

     this.fetchChartAccounts();
  },
};
</script>
<style scoped>

</style>
