import adjustmentHistoryStoreActions from "./adjustmentHistoryStoreActions";
import adjustmentHistoryStoreGetters from "./adjustmentHistoryStoreGetters";
import adjustmentHistoryStoreMutations from "./adjustmentHistoryStoreMutations";
import { adjustmentHistoryStoreStateKeys } from "./adjustmentHistoryStoreStateKeys";

const adjustmentHistoryStore = {
  ...adjustmentHistoryStoreActions,
  ...adjustmentHistoryStoreGetters,
  ...adjustmentHistoryStoreMutations,
  adjustmentHistoryStoreStateKeys,
};

export default adjustmentHistoryStore;
