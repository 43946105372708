<template>
  <div>
    <modal :show.sync="modal.create" size="lg">
      <template class="align-middle" slot="header">
        <img src="/img/icons/split-transaction--v1.png" height="30px" width="30px">
        <h5 class="modal-title pl-3 align-middle">{{ modal.title }}</h5>
      </template>
      <div>

        <div class="row mt-1 mb-3 m-0 p-0">
          <div class="col pr-0">
            <base-button
              @click.prevent="selected = 0" :class="selected === 0 && 'active'"
              class="button-border-left" outline type="primary" block
            >
              <span :class="selected === 0 ? 'text-white' : 'text-dark'" style="font-size: 11px;text-transform: uppercase !important;">
                <img height="25px" :src="
                        (selected === 0)
                          ? '/img/icons/icons8/ios/info-squared_white.png'
                          : '/img/icons/info-squared.png'
                      " class="mr-2"/>
                Identificação
              </span>
            </base-button>
          </div>
          <div class="col pl-0">
            <base-button
              @click.prevent="selected = 1" :class="selected === 1 && 'active'"
              class="button-border-right btn-left-none" outline type="primary" block
            >
              <span :class="selected === 1 ? 'text-white' : 'text-dark'" style="font-size: 11px;text-transform: uppercase !important;">
                <img height="25px" :src="
                        (selected === 0)
                          ? '/img/icons/finance.png'
                          : '/img/icons/finance-white.png'
                      " class="mr-2"/>
                Pagamento
              </span>
            </base-button>
          </div>
        </div>

        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(save)"
            autocomplete="off"
          >
            <div>
              <!-- IDENTIFICAÇÃO -->
              <div v-if="selected === 0">
                <!-- Status -->
                <div class="form-group row m-0 p-0">
                  <label class="col-md-6 pb-0 mb-1 col-form-label form-control-label">
                    Status
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-6 pt-2">
                    <base-dropdown class="float-right">
                        <button
                          style="margin: 0;width: 95px; justify-content: center; height: 25px;display: flex; align-items: center;"
                          slot="title-container"
                          class="btn dropdown-toggle text-uppercase colorize-btn-img"
                          type="button"
                          :class="payload.status ? 'btn-outline-success' : 'btn-outline-danger'"
                        >
                          <img class="mr-1" v-if="payload.status" src="/img/icons/icons8/ios/thumb-up_success.png" width="16px">
                          <img class="mr-1" v-else src="/img/icons/icons8/ios/thumbs-down_danger.png" width="16px">
                          {{ payload.status ? 'ATIVO' : 'INATIVO' }}
                        </button>
                        <a
                          @click.prevent="payload.status = !payload.status"
                          class="dropdown-item text-uppercase new-default-black-font"
                          style="font-size: 12px;"
                        >
                          {{ payload.status ? 'INATIVO' : 'ATIVO' }}
                        </a>
                      </base-dropdown>
                  </div>
                </div>
                <!-- Nome -->
                <div class="form-group row m-0 mt-2 mb-2 p-0">
                  <label
                    class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                  >
                    <div class="pt-1">
                      Nome
                      <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                    </div>
                  </label>
                  <div class="col-md-6 pr-4 mb-2">
                    <validation-provider rules="required" v-slot="{errors}">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          v-model="payload.name"
                          class="form-control form-control-sm"
                          :class="errors[0] ? 'is-invalid' : 'is-valid'"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <!-- Forma de pagamento -->
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                  >
                    <div class="pt-1">
                      Forma de pagamento
                      <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                    </div>
                  </label>
                  <div class="col-md-6 pr-4 mb-2">
                    <base-input input-group-classes="input-group-sm">
                      <puzl-select v-model="payload.payment_type" :items="[
                        {
                          id: 0,
                          name: 'Débito',
                        },
                        {
                          id: 1,
                          name: 'Crédito',
                        }
                      ]"/>
                    </base-input>
                  </div>
                </div>
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                  >
                    <div class="pt-1">
                      Mediador de pagamento
                    </div>
                  </label>
                  <div class="col-md-6 pr-4 mb-2">
                      <base-input input-group-classes="input-group-sm">
                        <puzl-select placeholder="Nenhum" multiple v-model="payload.payment_intermediary" :items="$_payment_intermediaries"/>
                      </base-input>
                  </div>
                </div>
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                  >
                    <div class="pt-1">
                      Código externo
                    </div>
                  </label>
                  <div class="col-md-6 pr-4 mb-2">
                    <base-input input-group-classes="input-group-sm">
                      <input
                      v-model="payload.external_code"
                      type="text"
                      class="form-control form-control-sm" />
                    </base-input>
                  </div>
                </div>
                 <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                  >
                    <div class="pt-1">
                      Código sap
                    </div>
                  </label>
                  <div v-if="hasSap" class="col-md-6 pr-4 mb-2">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        @blur="setSapExternalCode()"
                        v-model="payload.sap_code"
                        :disabled="loadingExternalCodeForEntityBillReceive"
                        type="text"
                        class="form-control form-control-sm" 
                      />
                    </base-input>
                  </div>
                </div>
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                  >
                    <div class="pt-1">
                      Código sap (Créditos e Antecipações)
                    </div>
                  </label>
                  <div v-if="hasSap" class="col-md-6 pr-4 mb-2">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        @blur="setSapExternalCodeForEntityBillReceive()"
                        v-model="payload.sap_code_for_entity_bill_receive"
                        :disabled="loadingExternalCodeForEntityBillReceive"
                        type="text"
                        class="form-control form-control-sm" 
                      />
                    </base-input>
                  </div>
                </div>
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                  >
                    <div class="pt-1">
                      Detalhes para propostas/contratos
                    </div>
                  </label>
                  <div class="col-md-6 pr-4 mb-2">
                    <base-input input-group-classes="input-group-sm">
                      <textarea
                        v-model="payload.contract_details"
                        type="text"
                        class="form-control form-control-sm" />
                    </base-input>
                  </div>
                </div>

              </div>
              <!-- PERMISSÕES -->
              <div v-else>
                <div class="row d-flex align-items-center">
                  <div class="col-md-5">
                    <b class="text-dark mr-3 ml-3">
                      <img height="30px" src="/img/icons/administrative-tools.png" class="mr-2"/>
                      Permissões gerais
                    </b>
                  </div>
                  <div class="col mr-3">
                    <hr class="bg-common-hr opacity-25 flex-grow-1 ml-2 mr-2"/>
                  </div>
                </div>
                <!-- Permitir aprovação -->
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-10 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                  >
                    <div class="pt-1">
                      <span class="small">Permitir aprovação</span>
                      <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                    </div>
                  </label>
                  <div class="col-md-2 pr-4 d-flex flex-row-reverse">
                    <base-switch type="primary" offText="Não" onText="Sim" class="success"
                                v-model="payload.allow_approve">
                    </base-switch>
                  </div>
                </div>
                <!-- Exige código de transação -->
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-10 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                  >
                    <div class="pt-1">
                      <span class="small">Exige código de transação</span>
                      <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                    </div>
                  </label>
                  <div class="col-md-2 pr-4 d-flex flex-row-reverse">
                    <base-switch type="primary" offText="Não" onText="Sim" class="success"
                                v-model="payload.transaction_code">
                    </base-switch>
                  </div>
                </div>
                <!-- Exibir em antecipação -->
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-10 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                  >
                    <div class="pt-1">
                      <span class="small">Exibir em créditos e antecipações</span>
                      <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                    </div>
                  </label>
                  <div class="col-md-2 pr-4 d-flex flex-row-reverse">
                      <base-switch type="primary" offText="Não" onText="Sim" class="success"
                                  v-model="payload.display_in_anticipation">
                      </base-switch>
                  </div>
                </div>

                <div class="row d-flex align-items-center">
                  <div class="col-md-4">
                    <b class="text-dark mr-3 ml-3">
                      <img height="30px" src="/img/icons/finance.png" class="mr-2"/>
                      Contas a receber
                    </b>
                  </div>
                  <div class="col mr-3">
                    <hr class="bg-common-hr opacity-25 flex-grow-1 ml-2 mr-2"/>
                  </div>
                </div>

                <!-- Permite recebimento de fatura manual -->
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-10 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                  >
                    <div class="pt-1">
                      <span class="small">Permite recebimento de fatura manual</span>
                      <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                    </div>
                  </label>
                  <div class="col-md-2 pr-4 d-flex flex-row-reverse">
                      <base-switch
                        type="primary" offText="Não" onText="Sim" class="success"
                        v-model="payload.is_manual_invoice_receipt" />
                  </div>
                </div>
                <div class="row d-flex align-items-center">
                  <div class="col-md-5">
                    <b class="text-dark mr-3 ml-3">
                      <img height="30px" src="/img/icons/finance.png" class="mr-2"/>
                      Créditos e antecipações
                    </b>
                  </div>
                  <div class="col mr-3">
                    <hr class="bg-common-hr opacity-25 flex-grow-1 ml-2 mr-2"/>
                  </div>
                </div>
                <!-- Permite confirmação de recebíveis manual -->
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-10 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                  >
                    <div class="pt-1">
                      <span class="small">Permite confirmação de recebíveis manual</span>
                      <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                    </div>
                  </label>
                  <div class="col-md-2 pr-4 d-flex flex-row-reverse">
                      <base-switch
                        type="primary" offText="Não" onText="Sim" class="success"
                        v-model="payload.is_manual_receivable_confirmation" />
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-10 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                  >
                    <div class="pt-1">
                      <span class="small">REGISTROS FICAM VISÍVEIS NA TELA DE CRÉDITOS E ANTECIPAÇÕES</span>
                      <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                    </div>
                  </label>
                  <div class="col-md-2 pr-4 d-flex flex-row-reverse">
                      <base-switch
                        type="primary" offText="Não" onText="Sim" class="success"
                        v-model="payload.show_records_on_credit_anticipation_window" />
                  </div>
                </div>

                <div class="row d-flex align-items-center">
                  <div class="col-md-4">
                    <b class="text-dark mr-3 ml-3">
                      <img height="30px" src="/img/icons/finance.png" class="mr-2"/>
                      Contas a pagar
                    </b>
                  </div>
                  <div class="col mr-3">
                    <hr class="bg-common-hr opacity-25 flex-grow-1 ml-2 mr-2"/>
                  </div>
                </div>

                <!-- Permite pagamento de fornecedor manual-->
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-10 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                  >
                    <div class="pt-1">
                      <span class="small">Permite pagamento de fornecedor manual</span>
                      <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                    </div>
                  </label>
                  <div class="col-md-2 pr-4 d-flex flex-row-reverse">
                      <base-switch
                        type="primary" offText="Não" onText="Sim" class="success"
                        v-model="payload.is_manual_supplier_payment" />
                  </div>
                </div>

                <div class="row d-flex align-items-center">
                  <div class="col-md-4">
                    <b class="text-dark mr-3 ml-3">
                      <img height="30px" src="/img/icons/check.png" class="mr-2"/>
                      Faturamento
                    </b>
                  </div>
                  <div class="col mr-3">
                    <hr class="bg-common-hr opacity-25 flex-grow-1 ml-2 mr-2"/>
                  </div>
                </div>

                <!-- Permite pagamento de fornecedor manual-->
                <div class="form-group row m-0 p-0">
                  <label
                    class="col-md-10 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                  >
                    <div class="pt-1">
                      <span class="small">Permite faturamento automático</span>
                      <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                    </div>
                  </label>
                  <div class="col-md-2 pr-4 d-flex flex-row-reverse">
                      <base-switch
                        type="primary" offText="Não" onText="Sim" class="success"
                        v-model="payload.allow_auto_billing" />
                  </div>
                </div>
              </div>
              <div class="row d-flex  p-4" style="flex-direction:row-reverse !important">
                <div class="col-md-3">
                  <base-button
                    type="success"
                    native-type="submit"
                    v-bind:disabled="invalid"
                    :loading="loadingStore"
                    :block="true"
                  >
                    <img src="/img/icons/save.png" width="22px" height="22px" class="mr-2" />
                    Salvar
                  </base-button>
                </div>
                  <div class="col-md-3">
                    <base-button type="danger" @click="closeModal('create')" :block="true">
                      <img src="/img/icons/cancel-white.png" width="22px" height="22px" class="mr-2" />
                      Cancelar
                    </base-button>
                  </div>
                </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {formatErrorValidation} from "@/plugins";
import PuzlSelect from "@/components/PuzlSelect";
import {mapGetters} from "vuex";
// Sap
import sapIntegrationStore from "../../../../../../modules/sap/sap-integration/store";
import { hasSap } from "../../../../../../modules/sap/shared/helpers/sapHelper";
import { initSapIntegrationSyncType } from "../../../../../../modules/sap/sap-integration/types";
import { SapTypeEnum } from "../../../../../../modules/sap/shared/enums/SapTypeEnum";

export default {
  name: "ModalCreatePaymentMethod",
  components: {
    PuzlSelect
  },
  data() {
    return {
      modal: {
        title: "Método de Pagamento",
        create: false
      },
      payload: {
        name: null,
        allow_auto_billing: false,
      },
      loadingStore: false,
      loadingExternalCode: false,
      loadingExternalCodeForEntityBillReceive: false,
      selected: 0,
      hasSap: hasSap(),
      SapTypeEnum: SapTypeEnum,
    };
  },
  computed: {
    ...mapGetters({
      $_payment_intermediaries: "paymentIntermediary/fetch"
    })
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal(uuid) {
      let loader = this.$loading.show()
      this.loadingExternalCode = false;
      this.loadingExternalCodeForEntityBillReceive = false;
      Promise.all([
        this.$store.dispatch("paymentMethod/show", uuid),
        this.$store.dispatch("paymentIntermediary/fetchItems"),
        this.$store.dispatch("plant/fetchItemsActive")
      ]).then((response) => {
        this.payload = {
          id: response[0].data.id || "",
          uuid: response[0].data.uuid || "",
          status: response[0].data.status,
          name: response[0].data.name || "",
          payment_type: response[0].data.payment_type,
          transaction_code: Boolean(response[0].data.transaction_code),
          allow_approve: Boolean(response[0].data.allow_approve),
          payment_intermediary: response[0].data.payment_intermediaries || "",
          external_code: response[0].data.external_code || "",
          contract_details: response[0].data.contract_details || "",
          allow_auto_billing: Boolean(response[0].data.allow_auto_billing),
          display_in_anticipation: Boolean(response[0].data.display_in_anticipation),
          is_manual_invoice_receipt: Boolean(response[0].data.is_manual_invoice_receipt),
          is_manual_receivable_confirmation: Boolean(response[0].data.is_manual_receivable_confirmation),
          is_manual_supplier_payment: Boolean(response[0].data.is_manual_supplier_payment),
          show_records_on_credit_anticipation_window: Boolean(response[0].data.show_records_on_credit_anticipation_window)
        };
        this.modal.create = true;
        this.selected = 0;
        loader.hide();
        this.getSapExternalCode()
      })
    },
    save() {
      this.$Progress.start();
      this.loadingStore = true;
      this.$store
        .dispatch("paymentMethod/update", this.payload)
        .then(response => {
          this.loadingStore = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.closeModal();
        })
        .catch(error => {
          if (error.status === 200) {
            this.$notify({
              type: "danger",
              message: error.data.message
            });
            this.$Progress.finish();
            this.loadingStore = false;
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors
            });
            this.$Progress.finish();
            this.loadingStore = false;
          }
        });
    },

    getSapExternalCode() {
      if(this.hasSap) {
        this.loadingExternalCode = true;
        this.loadingExternalCodeForEntityBillReceive = true;
        
        sapIntegrationStore.getExternalId(this.payload.id, SapTypeEnum.keys.PAYMENT_METHOD).then(response => {
          this.payload.sap_code = response;
          this.loadingExternalCode = false;
        });

        sapIntegrationStore.getExternalId(this.payload.id, SapTypeEnum.keys.PAYMENT_METHOD_FOR_ENTITY_BILL_RECEIVE).then(response => {
          this.payload.sap_code_for_entity_bill_receive = response;
          this.loadingExternalCodeForEntityBillReceive = false;
        });
      }
    },

    async setSapExternalCode() {
      if(this.hasSap) {
        const loader = this.$loading.show();
        let data = initSapIntegrationSyncType();
        data.typeable_id = this.payload.id;
        data.type = SapTypeEnum.keys.PAYMENT_METHOD;
        data.external_id = this.payload.sap_code;
        await sapIntegrationStore.sync(data);
        loader.hide();
      }
    },

    async setSapExternalCodeForEntityBillReceive() {
      if(this.hasSap) {
        const loader = this.$loading.show();
        let data = initSapIntegrationSyncType();
        data.typeable_id = this.payload.id;
        data.type = SapTypeEnum.keys.PAYMENT_METHOD_FOR_ENTITY_BILL_RECEIVE;
        data.external_id = this.payload.sap_code_for_entity_bill_receive;
        await sapIntegrationStore.sync(data);
        loader.hide();
      }
    },
  },
  mounted() {
    this.$refs.formValidator.validate();
  }
};
</script>

<style scoped></style>
