import * as types from './mutation_types'
import { createAxios } from "@/plugins/axios";
const endPoint = '/configuration/user/alert/messages'
export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters:{
    fetch: state => state.items,
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
  },
  actions: {
    fetchItems({commit}) {
      return createAxios().get(endPoint)
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getGroupsByAlertId({_}, id) {
      return createAxios().get(`${endPoint}/alerts/${id}/groups`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    update({commit}, payload) {
      return createAxios().put(endPoint,  payload)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    removeAlertFromGroup({_}, payload) {
      return createAxios().delete(`${endPoint}/alert/${payload.alertId}/group/${payload.groupId}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
