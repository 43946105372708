<template>
  <div>
    <modal :show.sync="modal.create">
      <template class="align-middle" slot="header">
        <h5 class="modal-title align-middle">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(store)"
            autocomplete="off"
          >
            <div>
              <!-- Status -->
              <div class="form-group row m-0 mt-2 p-0">
                <div class="col-md-4 mb-1">
                  <label class="pt-0 pb-0 col-form-label form-control-label mb-0">
                    <div class="pt-1">
                      Status <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                    </div>
                  </label>
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <base-switch
                        v-model="payload.payment_term.status"
                        type="success"
                        offText="Inativo"
                        onText="Ativo"
                        class="success"
                      ></base-switch>
                    </base-input>
                  </validation-provider>
                </div>
                <!-- Fim Status -->
                <!-- Faturamento-->
                <div class="col-md-4 mb-1">
                  <label class="pt-0 pb-0 col-form-label form-control-label mb-0">
                    <div class="pt-1">
                      Faturamento
                      <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                    </div>
                  </label>
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <base-switch
                        v-model="payload.payment_term.allow_in_proposal"
                        type="success"
                        offText="Inativo"
                        onText="Ativo"
                        class="success"
                      ></base-switch>
                    </base-input>
                  </validation-provider>
                </div>
                <!-- Fim faturamento-->
                <!-- Antecipação -->
                <div class="col-md-4 mb-1">
                  <label class="pt-0 pb-0 col-form-label form-control-label mb-0">
                    <div class="pt-1">
                      Antecipação
                      <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                    </div>
                  </label>
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <base-switch
                        v-model="payload.payment_term.anticipation"
                        type="success"
                        offText="Inativo"
                        onText="Ativo"
                        class="success"
                      ></base-switch>
                    </base-input>
                  </validation-provider>
                </div>
                <!-- Fim antecipação -->
              </div>
              <!-- Nome -->
              <div class="form-group row m-0 mt-1 mb-1 p-0 limited-row">
                <label
                  class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                >
                  <div class="pt-1">
                    Nome
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-6 pr-4 mb-2">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model="payload.payment_term.name"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Fim Nome -->
              <!-- Descrição -->
              <div class="form-group row m-0 mt-1 mb-1 p-0 limited-row">
                <label
                  class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                >
                  <div class="pt-1">
                    Descrição
                  </div>
                </label>
                <div class="col-md-6 pr-4 mb-2">
                  <validation-provider>
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model="payload.payment_term.description"
                        class="form-control form-control-sm"
                        maxlength="250"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Fim Descrição -->
              <div class="form-group row m-0 mt-1 mb-1 p-0">
                <div class="pt-0 pb-0 col-form-label form-control-label col-md-6">
                  Frequência do Faturamento
                  <span class="text-danger">&nbsp;*&nbsp;</span>
                </div>
                <div class="col-md-6 pr-4">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <puzl-select
                        :clearable="false"
                        @change="clearRangeOff"
                        v-model="payload.payment_term.range"
                        :items="range_frequency_options"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div v-if="payload.payment_term.range === 4" class="form-group row m-0 mt-1 mb-1 p-0">
                <div class="col-md-6"></div>
                <template v-if="windowWidth > 1024">
                  <div class="col-md-3 pr-1">
                    <validation-provider rules="required">
                      <base-input v-mask="'##'" inputmode="numeric" v-model="payload.payment_term.range_off"
                                  input-group-classes="input-group-sm">
                      </base-input>
                    </validation-provider>
                  </div>
                  <div class="col-md-3 pl-1 pr-4">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <el-select
                          v-model="payload.payment_term.range_off_type"
                          size="mini"
                          filterable
                          placeholder="Selecione"
                        >
                          <el-option
                            v-for="(type, index) in rangeTypes"
                            :key="index"
                            :label="type"
                            :value="index"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </validation-provider>
                  </div>
                </template>
                <template v-else>
                  <div class="col-md-3 mb-1 pr-4">
                    <validation-provider rules="required">
                      <base-input v-mask="'##'" inputmode="numeric" v-model="payload.payment_term.range_off"
                                  input-group-classes="input-group-sm">
                      </base-input>
                    </validation-provider>
                  </div>
                  <div class="col-md-3 pr-4">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <el-select
                          v-model="payload.payment_term.range_off_type"
                          size="mini"
                          filterable
                          placeholder="Selecione"
                        >
                          <el-option
                            v-for="(type, index) in rangeTypes"
                            :key="index"
                            :label="type"
                            :value="index"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </validation-provider>
                  </div>
                </template>
              </div>
              <div class="form-group row m-0 mt-1 mb-1 p-0">
                <div class="pt-0 pb-0 col-form-label form-control-label col-md-6">
                  Tipo de vencimento
                  <span class="text-danger">&nbsp;*&nbsp;</span>
                </div>
                <div class="col-md-6 pr-4">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <puzl-select
                        :clearable="false"
                        @change="clearRangeOff"
                        v-model="payload.payment_term.expire_type"
                        :items="expire_type_range"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Métodos de pagamento -->
              <div class="form-group row m-0 pt-0 mb-1 p-0">
                <label
                  class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                >
                  <div class="">
                    Métodos de pagamento
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-6 pr-4 mb-2">
                  <validation-provider rules="required" v-slot="{errors}">
                    <puzl-select
                      multiple
                      v-model="payload.payment_methods"
                      :items="payment_methods"
                      customKey="uuid"
                    />
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 mt-1 mb-1 p-0 mt-n2 limited-row">
                <label
                  class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                >
                  <div class="pt-1">
                    Código externo
                  </div>
                </label>
                <div class="col-md-6 pr-4 mb-2">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-model="payload.payment_term.external_code"
                      type="text"
                      class="form-control form-control-sm"/>
                  </base-input>
                </div>
              </div>
              <!-- Fim Métodos de pagamento -->
              <!-- Permissões -->
              <div
                class="card-header border-header-warning-bottom mt-2 mb-2 p-2 grid-col--fixed-left"
              >
                <h5 class="mb-0" slot="title">Permissões</h5>
              </div>
              <!-- Pessoa Física -->
              <div class="form-group row m-0 mt-1 mb-1 p-0 limited-row">
                <label
                  class="col-md-8 pt-0 pb-0 col-form-label form-control-label pl-3 mb-1"
                >
                  <div class="pt-1">
                    Pessoa física
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-4 pr-4 mb-1">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <base-switch
                        v-model="payload.payment_term.physical_person"
                        type="success"
                        offText="Não"
                        onText="Sim"
                        class="success"
                      ></base-switch>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Fim Pessoa Física -->
              <!-- Pessoa Jurídica -->
              <div class="form-group row m-0 mt-1 mb-1 p-0 limited-row">
                <label
                  class="col-md-8 pt-0 pb-0 col-form-label form-control-label pl-3 mb-1"
                >
                  <div class="pt-1">
                    Pessoa jurídica
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-4 pr-4 mb-1">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <base-switch
                        v-model="payload.payment_term.legal_person"
                        type="success"
                        offText="Não"
                        onText="Sim"
                        class="success"
                      ></base-switch>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Fim Pessoa Jurídica -->
              <!-- Puzl cliente -->
              <div class="form-group row m-0 mt-1 mb-1 p-0 limited-row">
                <label
                  class="col-md-8 pt-0 pb-0 col-form-label form-control-label pl-3 mb-1"
                >
                  <div class="pt-1 text-indigo">
                    Puzl Connect
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-4 pr-4 mb-1">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <base-switch
                        v-model="payload.payment_term.puzl_customer"
                        type="success"
                        offText="Não"
                        onText="Sim"
                        class="success"
                      ></base-switch>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Fim Puzl cliente -->
              <!-- Aprovação financeira -->
              <div class="form-group row m-0 mt-1 mb-1 p-0 limited-row">
                <label
                  class="col-md-8 pt-0 pb-0 col-form-label form-control-label pl-3 mb-1"
                >
                  <div class="pt-1">
                    Exige aprovação financeira
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-4 pr-4 mb-1">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <base-switch
                        v-model="payload.payment_term.financial_approval"
                        type="success"
                        offText="Não"
                        onText="Sim"
                        class="success"
                      ></base-switch>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Fim Aprovação financeira -->
              <!-- Crédito de antecipação total -->
              <div class="form-group row m-0 mt-1 mb-1 p-0 limited-row">
                <label
                  class="col-md-8 pt-0 pb-0 col-form-label form-control-label pl-3 mb-1"
                >
                  <div class="pt-1">
                    Exige crédito de antecipação total
                  </div>
                </label>
                <div class="col-md-4 pr-4 mb-1">
                  <base-input input-group-classes="input-group-sm">
                    <base-switch
                      v-model="payload.payment_term.requires_balance"
                      type="success"
                      offText="Não"
                      onText="Sim"
                      class="success"
                    ></base-switch>
                  </base-input>
                </div>
              </div>
              <!-- Fim crédito de antecipação total -->
              <!-- Fim entrada/crédito parcial -->
              <div
                class="card-header border-header-danger-bottom mt-2 mb-2 p-2 grid-col--fixed-left"
              >
                <h5 class="mb-0" slot="title">Parcelas</h5>
              </div>
              <!-- Parcelas -->
              <div class="form-group row m-0 mt-1 mb-1 p-0">
                <label
                  class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                >
                  <div class="pt-1">
                    Parcelas
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-6 pr-4 mb-2">
                  <validation-provider rules="required|min_value:0" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-on:input="
                          payload.installment = formatString(
                            $event.target.value,
                            '0',
                            '21'
                          )
                        "
                        @change="generateInstallmentFields"
                        inputmode="numeric"
                        v-model="payload.installment"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- parcelas gerais -->
              <div
                v-for="installment in payload.installments"
                :key="installment.index"
                class="h-100"
              >
                <div class="form-group row m-0 mt-1 mb-1 p-0">
                  <label
                    class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-3 my-auto "
                  >
                    <div>Parcela {{ installment.index }}</div>
                  </label>
                  <div class="col-md-6 pr-4 mb-2">
                    <div class="form-group row m-0 mt-1 mb-1 p-0 limited-row">
                      <label
                        class="col-3 pt-0 pb-0 col-form-label form-control-label pl-0 pt-1"
                      >
                        <div>Prazo</div>
                      </label>
                      <div class="col-9 mb-2 pr-0">
                        <validation-provider rules="required|min_value:0">
                          <base-input input-group-classes="input-group-sm">
                            <input
                              @change="fillDays(installment.index)"
                              v-on:input="
                                installment.deadline = formatString(
                                  $event.target.value,
                                  '0',
                                  '999'
                                )
                              "
                              inputmode="numeric"
                              v-model="installment.deadline"
                              class="form-control form-control-sm"
                            />
                            <template slot="append">
                              <small class="input-group-sm p-0 m-0">
                                dias
                              </small>
                            </template>
                          </base-input>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="form-group row m-0 mt-1 mb-1 p-0 limited-row">
                      <label
                        class="col-3 pt-0 pb-0 col-form-label form-control-label pl-0 pt-1"
                      >
                        <div>%</div>
                      </label>
                      <div class="col-9 pr-0 mb-2">
                        <validation-provider rules="required|min_value:0">
                          <base-input input-group-classes="input-group-sm">
                            <input
                              @change="calcFirstInstallment"
                              v-on:input="
                                installment.percentage = formatString(
                                  $event.target.value,
                                  '0',
                                  '100',
                                  '2'
                                )
                              "
                              inputmode="numeric"
                              :disabled="installment.index === 1"
                              v-model="installment.percentage"
                              class="form-control form-control-sm"
                            />
                            <template slot="append">
                              <small class="input-group-sm p-0 m-0">
                                %
                              </small>
                            </template>
                          </base-input>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Fim Parcelas -->
              <div class="modal-footer">
                <base-button type="secondary" @click="closeModal('create')">
                  Cancelar
                </base-button>
                <base-button type="primary" native-type="submit"
                             :disabled="invalid" :loading="loadingSaveAndContinue" @click="saveAndContinue">
                  Salvar e Continuar
                </base-button>
                <base-button
                  type="success"
                  native-type="submit"
                  v-bind:disabled="invalid"
                  :loading="loadingStore"
                >
                  Salvar
                </base-button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mask1, formatErrorValidation} from "@/plugins";
import {mapGetters} from "vuex";
import {checkNumberValue} from "@/helpers";
import responsive from "@/mixins/responsive";
import paymentTermMixin from "../Mixins/paymentTermMixin";
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "ModalCreatePaymentTerm",
  components: {
    PuzlSelect,
  },
  mixins: [responsive, paymentTermMixin],
  data() {
    return {
      modal: {
        title: "Condições de pagamento",
        create: false
      },
      expire_type_range: [
        {
          id: 1,
          name: 'Direto'
        },
        {
          id: 2,
          name: 'Fora semana'
        },
        {
          id: 3,
          name: 'Fora dezena'
        },
        {
          id: 4,
          name: 'Fora quinzena'
        },
        {
          id: 5,
          name: 'Fora mês'
        },
      ],
      range_frequency_options: [
        {
          id: 1,
          name: 'Diariamente'
        },
        {
          id: 2,
          name: 'Semanalmente'
        },
        {
          id: 3,
          name: 'Mensalmente'
        },
        {
          id: 4,
          name: 'Personalizado'
        },
      ],
      payload: {
        payment_term: {
          name: null,
          expire_type: 1,
          range_off: null,
          range_off_type: 0,
          allow_in_proposal: 1,
          anticipation: 1,
          range: null,
          status: true,
          puzl_customer: false,
          physical_person: true,
          legal_person: true,
          financial_approval: false,
          requires_balance: false,
          external_code: '',
        },
        payment_methods: [],
        installment: null,
        installments: []
      },
      loadingStore: false,
      loadingSaveAndContinue: false,
    };
  },
  computed: {
    ...mapGetters({
      payment_methods: "paymentMethod/fetch"
    }),
    rangeTypes() {
      if (this.payload.payment_term.range_off > 1) {
        return ['Dias', 'Horas']
      }
      return ['Dia', 'Hora']
    }
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        this.$forceUpdate();
        return checkNumberValue(
          mask1(value.toString(), decimals, ".", true),
          min,
          max
        );
      }
      return checkNumberValue(value, min, max);
    },
    clearRangeOff() {
      this.payload.payment_term.range_off = null
      this.payload.payment_term.range_off_type = 0
    },
    openModal() {
      this.$Progress.start();
      this.loadingStore = true;
      this.payload = {
        payment_term: {
          name: null,
          status: true,
          allow_in_proposal: true,
          anticipation: true,
          expire_type: 1,
          puzl_customer: false,
          physical_person: true,
          legal_person: true,
          financial_approval: false,
          requires_balance: false,
          range_off: null,
          range_off_type: 0,
          range: 1,
          external_code: '',
        },
        payment_methods: [],
        installment: null,
        installments: []
      };
      this.$store.dispatch("paymentMethod/fetchItems").then(response => {
        this.$notify({
          type: response.error_type,
          message: response.message
        });
        this.$Progress.finish();
        this.loadingStore = false;
        this.modal.create = true;
      });
    },
    generateInstallmentFields() {
      let length = this.payload.installment;
      this.payload.installments = [];
      let percentage = 0;
      let current_percentage = 100 / this.payload.installment;

      if (current_percentage % 1 != 0) {
        current_percentage = Math.floor(current_percentage);
        percentage = length * current_percentage;
      }
      for (let i = 1; i <= length; i++) {
        let installment = {
          deadline: null,
          percentage: current_percentage,
          index: i
        };
        this.payload.installments.push(installment);
        if (i == length && percentage !== 0) {
          this.payload.installments[0].percentage += 100 - percentage;
        }
      }
    },
    store() {
      this.$Progress.start();
      if (!this.loadingSaveAndContinue) {
        this.loadingStore = true;
      }
      this.payload.percentage = this.payload.installments.reduce(
        (a, b) => parseFloat(a) + parseFloat(b.percentage),
        0
      );
      this.$store
        .dispatch("paymentTerm/add", this.payload)
        .then(response => {
          this.loadingStore = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          if (!this.loadingSaveAndContinue) {
            this.closeModal();
          }
          this.loadingSaveAndContinue = false;
        })
        .catch(error => {
          if (error.status === 200) {
            this.$notify({
              type: "danger",
              message: error.data.message
            });
            this.$Progress.finish();
            this.loadingStore = false;
            this.loadingSaveAndContinue = false;
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors
            });
            this.$Progress.finish();
            this.loadingStore = false;
            this.loadingSaveAndContinue = false;
          }
        });
    },
    saveAndContinue() {
      this.loadingSaveAndContinue = true;
      this.store();
    },
  },
  mounted() {
    this.$refs.formValidator.validate();
  }
};
</script>

<style scoped>
@media (min-width: 768px) {
  .limited-row {
    max-height: 28px !important;
  }
}

label > div {
  font-size: 0.82rem;
}

.form-control-label {
  font-size: 0.82rem;
}
</style>
