<template>
  <div class="d-flex w-100">
    <i class="fa fa-clock m-0 text-light vc-custom-clock-icon align-middle pt-2 pl-2 ml-1"/>
    <v-date-picker
      :popover="{ placement: $screens({ default: 'bottom', md: position }), visibility: disabled ? 'none' : 'hover'}"
      :minute-increment="spaceMinutes"
      :value="formated(value)"
      class="w-100"
      :min-date="disableDate"
      @input="handleInput"
      locale="pt-BR"
      :mode="mode"
      :first-day-of-week="0"
      :max-date="disableMaxDate"
      is24hr>
      <template v-slot="{ inputValue, inputEvents }">
        <input
          :disabled="disabled"
          :style="customStyle"
          class="border rounded focus:outline-none focus:border-blue-300 vc-custom-input-datetime"
          :value="inputValue"
          v-on="inputEvents"
        />
        <i
          v-show="!disabled"
          role="button"
          @click.prevent="clearInput"
          style="margin-left:-1rem!important;position:absolute!important"
          class="fas fa-times m-0 text-light vc-custom-clock-icon align-middle pt-2 hoverable "
        />
      </template>
    </v-date-picker>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  name: "InputDateTimePicker",
  data() {
    return {
      moment: moment,
    }
  },
  methods: {
    formated(value) {
      return this.mode === "dateTime" ? moment(value).format('YYYY-MM-DD HH:mm:ss') : moment(value).format('YYYY-MM-DD 00:00:00');
    },
    handleInput(e) {
      this.$emit("input", e ? moment(e).tz(this.timeZone).format(this.format) : null);
    },
    clearInput() {
      this.handleInput(null);
      this.$emit('clean')
    }
  },
  beforeMount() {
    /**
     * busca o timezone da máquina do cliente
     */
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  },
  computed: {
    format() {
      return this.mode === "dateTime" ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD";
    },
  },
  props: {
    disableDate: {
      type: [Date, String, Boolean],
      default: null
    },
    position: {
      type: [String, Boolean],
      default: 'right-start'
    },
    visibility: {
      type: String,
      default: 'hover'
    },
    disableMaxDate: {
      type: [Date, Boolean, String],
      default: false
    },
    mode: {
      type: String,
      default: 'dateTime'
    },
    customStyle: {
      type: String,
      default: () => "",
      description: "Propriedades css adicionais"
    },
    value: {
      type: [String, Date],
      default: () => null,
      description: "Valor"
    },
    disabled: {
      type: Boolean,
      default: () => false,
      description: "Desabilitar input"
    },
    size: {
      type: String,
      default: () => 'sm',
      description: "Tamanho"
    },
    spaceMinutes: {
      type: Number,
      default: () => 1,
      description: "Espaçamento de minutos"
    }
  }
};
</script>

<style>
.vc-popover-content-wrapper {
  z-index: 100001 !important;
}

.vc-custom-input-datetime {
  color: #606266;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
  width: 100%;
  padding-left: 1.8rem !important;
}

.vc-custom-clock-icon {
  font-size: 12px;
  position: absolute;
}
</style>
