import {
    BaseListFilterType,
    initBaseListFilterType,
  } from "../../../../../shared/types";

  /**
   * Type para filtro de listagem (Extensão)
   *
   * @typedef {Object} ChargeResumeListFilterExtType
   * @property {RangeDateType|null} range - Intervalo de datas selecionado
   * @property {Date|null} dateFilter - Filtro por data
   * @property {number|null} canceled - Indicador de cancelamento
   * @property {string|null} status - Status do filtro ('todos' ou 'canceladas')
   * @property {number|null} company_plant_id - ID da central
   */

  /**
   * Type para filtro de listagem
   *
   * @typedef {(BaseListFilterType & ChargeResumeListFilterExtType)} ChargeResumeListFilterType
   */
  export const ChargeResumeListFilterType = {};

  /**
   * Inicializar ChargeResumeListFilterType
   *
   * @returns {ChargeResumeListFilterType}
   */
  export const initChargeResumeListFilterType = () => ({
    ...initBaseListFilterType(),
    order_by: [{ column: "schedule_travels.finished_at", is_desc: false }],
    custom_search: {
      columns: [
        "entities.entity_name",
        "contract_proposals.code",
        "customer_constructions.customer_name",
        "constructions.construction_name",
      ],
      values: [],
    },
    range: {
      start: null,
      end: null,
    },
    dateFilter: new Date(),
    canceled: null,
    status: null,
    company_plant_id: null,
  });