<template>
    <div class="row mx-1" style="overflow-x: scroll">
      <table class="table table-custom-shaded table-sm mb-9 pb-3">
        <thead>
          <tr class="fixed">
            <th v-for="header in headers" class="text-left new-default-black-font">
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="contractProposal in contractProposalSignatures">
            <!-- Status -->
            <td style="width: 6rem;">
              <div class="align-self-center status-button mr-2 px-3 d-flex justify-content-center align-items-center"
                :style="backgroundColors[contractProposal.status]">
                {{ getStatusText(contractProposal.status) }}
              </div>
            </td>
            <!-- Data -->
            <td>
              <div style="font-weight: 500;" class="d-flex justify-content-between date-colum">
                {{ contractProposal.created_at | parseDate() }}
              <!-- SIGNATARIOS -->
                <div class="d-flex">
                  <div class="mx-2 circle-icon">
                    <base-dropdown ref="dropdown" :closeOnClick="false">
                      <div slot="title-container" class="dropdown-toggle rounded d-flex px-3">
                        <img width="15" src="/img/icons/icons8/ios/agreement-black.png" alt="agreement"/>
                      </div>
                      <div class="p-2 px-3">
                        <div>
                          <div class="mb-2">
                            <img class="mr-1" width="20" src="/img/icons/agreement-primary.png" alt="agreement"/>
                            ASSINATURAS
                          </div>
                          <div v-for="(item, index) in contractProposal.signers" :key="index"
                            style="font-size: 12px; color: #606062; font-weight: 400; display: flex; justify-content: space-between; align-items: center;"
                            class="mt-1">
                            {{ item.name }}
                            <div class="ml-4 d-flex">
                              <!-- status da assinatura -->
                              <div class="circle-icon">
                                <el-popover trigger="click" placement="bottom" class="p-0">
                                  <span slot="reference">
                                    <img width="16" :src="'/img/icons/autograph' +
                                  (item.status === 0 ? '-black' :
                                    item.status === 1 ? '-warning' :
                                    item.status === 2 ? '-primary' : '')
                                  + '.png'" alt="copy-2"/>
                                  </span>
                                  <span style="font-size: 12px">  {{
                                      item.status === 0 ? 'Aguardando assinatura' :
                                      item.status === 1 ? 'Link aberto mas nao assinado' :
                                      item.status === 2 ? 'Assinado' :
                                      'Outro'
                                    }}
                                  </span>
                                </el-popover>
                              </div>
                              <el-popover trigger="click" placement="bottom" class="p-0">
                                  <div style="font-size: 12px">Clique para copiar a URL de assinatura</div>
                                  <span slot="reference">
                                    <img @click="$helper.copy(item.sign_url), $notify({ type: 'success', message: 'Código da automação copiado com sucesso!' });"
                                       width="20" src="/img/icons/copy-success.png" alt="copy-2" class="ml-2"/>
                                  </span>
                              </el-popover>
                            </div>
                          </div>
                        </div>
                      </div>
                    </base-dropdown>
                  </div>
                  <el-popover trigger="click" placement="bottom" class="p-0">
                    <div class="mb-1">Criado por: </div>
                    <div style="font-size: 12px" class="">{{ contractProposal.user_name }} </div>
                    <div style="font-size: 12px" class="mb-2">{{ contractProposal.user_email }} </div>
                    <span slot="reference">
                      <img width="24" src="/img/icons/icons8/ios/user-male-circle--v1.png"  alt="copy-2" class="ml-1"/>
                    </span>
                  </el-popover>
                </div>
              </div>
            </td>
            <!-- Central -->
            <td>{{ contractProposal.contract_proposal_plant_name }}</td>
            <!-- Codigo do contrato -->
            <td>{{ contractProposal.contract_proposal_seller_name }}</td>
            <td>{{ contractProposal.contract_proposal_code }}</td>
            <td style="max-width: 10rem; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
              {{ contractProposal.contract_proposal_entity_name }}
            </td>
            <td>{{ contractProposal.contract_proposal_construction_name }}</td>
            <!-- Menu engrenagem -->
            <td>
              <base-dropdown menuOnRight>
                <div slot="title-container" class="dropdown-toggle rounded m-0">
                  <img width="22" src="/img/icons/icons8/ios/settings--v1_primary.png" />
                </div>
                <a class="dropdown-item font-weight-500 new-default-black-font"
                  style="align-items: center; display: flex"
                  @click="getDocumentFile({type: 'Original', id: contractProposal.id})">
                  <img width="23" height="23" src="/img/icons/icons8/ios/print-primary.png" />
                  Imprimir documento
                </a>
                <div v-if="contractProposal.has_signed_file" class="dropdown-divider p-0 m-0"></div>
                <a v-if="contractProposal.has_signed_file" class="dropdown-item font-weight-500 new-default-black-font"
                  style="align-items: center; display: flex"
                  @click="getDocumentFile({type: 'Signed', id: contractProposal.id})">
                  <img width="19" src="/img/icons/icons8/ios/open-document-black.png" alt="document"/>
                  Imprimir doc. assinado
                </a>
              </base-dropdown>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
</template>

<script>

export default {
  name: "ListTributes",
  data() {
    return {
      headers: [
        "Status",
        "Data",
        "Central",
        "Vendedor",
        "Cod. contrato",
        "Cliente",
        "Obra",
        "Ação"
      ],
      qualification: {
        0: 'Contratado',
        1: 'Contratante',
        2: 'Testemunha',
        3: 'Fiador',
        'Outro': 'Outro'
      },
      backgroundColors: {
        0: { backgroundColor: '#fdf7e7', color: '#f5c865' },
        1: { backgroundColor: '#d1e2f1', color: '#1a70b7' },
        2: { backgroundColor: '#f8dad7', color: '#db4539' },
      },
    };
  },
  props: {
    contractProposalSignatures: {
      type: Object,
    },
  },
  methods: {
    getStatusText(status) {
      switch (status) {
        case 0:
          return 'Pendente';
        case 1:
          return 'Assinado';
        case 2:
          return 'Cancelado';
        default:
          return 'Desconhecido';
      }
    },
    documentDownload(base64Document) {
      const byteArray = atob(base64Document.split(',')[1]);
      const byteNumbers = new Array(byteArray.length);
      for (let i = 0; i < byteArray.length; i++) {
        byteNumbers[i] = byteArray.charCodeAt(i);
      }
      const byteArrayUint8 = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArrayUint8], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'documento.pdf';
      link.click();
      URL.revokeObjectURL(url);
    },
    getDocumentFile(data) {
      this.$store.dispatch(`digitalSignatureResume/get${data.type}DocumentFile`, data.id)
        .then((response) => {
          this.$notify({type: 'success', message: 'Download iniciado com sucesso!'});
          this.documentDownload(response.data);
        });
    },
  }
};
</script>

<style scoped>
.table-custom-shaded thead th {
  background-color: #CCCCCC;
  font-size: 14px !important;
}

.table-custom-shaded tr td {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.table-custom-shaded td, .table-custom-shaded th {
  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15) !important;
  border-radius: 5px !important;
  border: 0 !important;
}
.table-custom-shaded td:last-child, .table-custom-shaded th:last-child, .table-custom-shaded th:first-child {
  text-align: center !important;
  padding-left: 0.5rem !important;
}
.table-custom-shaded th {
  padding-left: 1.2rem !important;
}
.table-custom-shaded td {
  font-weight: 700 !important;
  padding-top: 0.85rem;
}
.table-custom-shaded td:last-child {
  padding-top: 0.8rem !important;
  padding-right: 0.2rem !important;
}
.table-custom-shaded td:first-child {
  font-weight: 500 !important;
  padding-top: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.status-button {
  border-radius: 7px;
  height: 30px;
  box-shadow: unset !important;
  min-width: 4rem;
  margin-right: 1rem;
  font-size: 13px;
}
.circle-icon {
  border-radius: 50%;
  border: 1px solid #606062;
  width: 1.5rem;
  height: 1.5rem;
  display: flex !important;
  align-items: center  !important;
  justify-content: center  !important;
}
.date-colum .dropdown-toggle::after {
  display: none;
}
</style>
