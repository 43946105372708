<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div class="form-group row m-0 p-0">
              <!-- CAMPO DATA -->
              <div class="col-md-6">
                <div class="pb-0 mb-1 col-form-label form-control-label">
                  Data
                  <span class="text-danger">&nbsp;*</span>
                </div>
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-group-classes="input-group-sm">
                    <input-date-time-picker
                      v-model="stockAdjustment.date_stock_adjustment" />
                  </base-input>
                </validation-provider>
              </div>

              <!-- CAMPO TIPO -->
              <div class="col-md-6">
                <div class="col-form-label form-control-label">
                  Tipo
                  <span class="text-danger">&nbsp;*</span>
                </div>
                <div class="row mt-n1">
                  <div class="col-md-6 pt-1 mt-n2 mb-2">
                    <base-button outline block size="sm" type="success"
                                 :class="stockAdjustment.type === true ? 'active' : ''"
                                 @click="handleChangeType(true)">
                      Entrada
                    </base-button>
                  </div>
                  <div class="col-md-6 pt-1 mt-n2 mb-2">
                    <base-button outline block size="sm" type="danger"
                                 :class="stockAdjustment.type === false ? 'active' : ''"
                                 @click="handleChangeType(false)">
                      Saída
                    </base-button>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <!-- CAMPO CENTRAL -->
              <div class="col-md-12">
                <div class="pb-0 mb-1 col-form-label form-control-label">
                  Central
                  <span class="text-danger">&nbsp;*</span>
                </div>
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                      v-model="stockAdjustment.company_plant_id"
                      :items="$_plants"
                      @input="getCmc(stockAdjustment.company_plant_id, stockAdjustment.cmc_category_id)"
                      :loading="loadingPlant"
                      :disabled="loadingPlant" />
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <!-- CAMPO CATEGORIA MCC -->
              <div class="col-md-12">
                <div class="pb-0 mb-1 col-form-label form-control-label">
                  Categoria MCC
                  <span class="text-danger">&nbsp;*</span>
                </div>
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                      v-model="stockAdjustment.cmc_category_id"
                      :items="$_cmcCategories"
                      @input="getCmc(stockAdjustment.company_plant_id, stockAdjustment.cmc_category_id),
                        getStockLocation(stockAdjustment.company_plant_id, stockAdjustment.cmc_category_id)"
                      :loading="loadingCmcCategory"
                      :disabled="loadingCmcCategory" />
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <!-- CAMPO MCC -->
              <div class="col-md-12">
                <div class="pb-0 mb-1 col-form-label form-control-label">
                  MCC
                  <span class="text-danger">&nbsp;*</span>
                </div>
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                      v-model="stockAdjustment.cmc_id"
                      :items="$_cmc"
                      :loading="loadingCmc"
                      :disabled="checkCmc"
                      :labelMask="true"
                      label="product_name$ | $supplier_name$ ($origin_name$)" />
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <!-- CAMPO LOCAL DE ESTOQUE -->
              <div class="col-md-12">
                <div class="pb-0 mb-1 col-form-label form-control-label">
                  Local de estoque
                  <span class="text-danger">&nbsp;*</span>
                </div>
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                      v-model="stockAdjustment.company_plant_charge_point_location_id"
                      :items="stockLocations"
                      :loading="loadingStockLocation"
                      :disabled="checkCmc"
                      :labelMask="true"
                      label="dosage_location_name$ $code$ | $company_plant_charge_point_name$ | $company_plant_charge_point_location_name" />
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="card-header border-header-primary-bottom mt-3 mb-2 p-2">
              <h5 class="h4 mb-0"> Dados considerando material seco </h5>
            </div>

            <div class="form-group row m-0 p-0">
              <!-- CAMPO QUANTIDADE -->
              <div class="col-md-6">
                <div class="pb-0 mb-1 col-form-label form-control-label">
                  Quantidade
                  <span class="text-danger">&nbsp;*</span>
                </div>
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-group-classes="input-group-sm pb-2">
                    <input
                      type="integer"
                      class="form-control form-control-sm"
                      v-model="stockAdjustment.quantity"
                      @blur="calculateTotalCost"
                      v-mask="['#,###', '##,###', '###,###', '####,###', '#####,###']"
                      :class="errors[0] ? 'is-invalid' : 'is-valid'"
                    />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0 ">
                        TON
                      </small>
                    </template>
                  </base-input>
                </validation-provider>
              </div>

              <!-- CAMPO PREÇO -->
              <div class="col-md-6">
                <div class="pb-0 mb-1 col-form-label form-control-label">
                  Preço por TON
                  <span class="text-danger">&nbsp;*</span>
                </div>
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm pb-2">
                    <input
                      @blur="calculateTotalCost"
                      v-money="money"
                      inputmode="numeric"
                      v-model.lazy="stockAdjustment.price"
                      @keydown="$event.key === '-' ? $event.preventDefault() : null"
                      class="form-control form-control-sm"
                    >
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0">
                        R$
                      </small>
                    </template>
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <div class="col-md-12">
                <!-- CAMPO CUSTO TOTAL -->
                <div class="pb-0 mb-1 col-form-label form-control-label">
                  Custo total
                </div>
                <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm pb-2">
                    <input
                      v-money="money"
                      type="text"
                      class="form-control form-control-sm"
                      :value="stockAdjustment.total"
                    />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0">
                        R$
                      </small>
                    </template>
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <!-- CAMPO OBSERVAÇÕES -->
            <div class="form-group row m-0 p-0">
              <div class="col-md-12">
                <div class="pb-0 mb-1 col-form-label form-control-label">
                  Observações
                </div>
                <base-input input-classes="form-control-sm">
                        <textarea
                          type="text"
                          rows="2"
                          class="form-control form-control-sm"
                          v-model="stockAdjustment.observations"
                          maxlength="250">
                        </textarea>
                </base-input>
              </div>
            </div>

            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')">
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
const {formatErrorValidation} = require("@/plugins");
import {mapGetters} from 'vuex';
import {VMoney} from "v-money";
import moment from "moment";
import { moneyToFloat, checkNumberValue } from "@/helpers";
import { mask1 } from "@/plugins";
import PuzlSelect from "@/components/PuzlSelect";
import InputDateTimePicker from "@/components/InputDateTimePicker";
export default {
  name: "ModalCreate",
  components: { PuzlSelect, InputDateTimePicker },
  data() {
    return {
      modal: {
        title: 'Lançamento de ajustes',
        create: false,
      },
      stockAdjustment: {
        date_stock_adjustment: null,
        type: true,
        quantity: null,
        price: null,
        total: null,
        observations: '',
        company_plant_id: null,
        cmc_category_id: null,
        cmc_id: null,
      },
      loadingStore: false,
      loadingPlant: true,
      loadingCmcCategory: true,
      loadingCmc: false,
      loadingStockLocation: false,
      checkCmc: true,
      stockLocations: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false
      },
    };
  },
  computed: {
    ...mapGetters({
      $_plants: 'plant/fetch',
      $_cmcCategories: 'category/fetch',
      $_cmc: 'cmc/fetch',
      $_user: 'auth/getUser',
    }),
  },
  mounted() {
    this.$refs.formValidator.validate();
    this.$store.dispatch('plant/getCompanyPlantByUser', this.$_user.id).then(() => {
      this.loadingPlant = false;
    });

    this.$store.dispatch('category/getCmcCategories').then(() => {
      this.loadingCmcCategory = false;
    });
  },
  directives: {
    money: VMoney
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    handleCreateModal() {
      this.stockAdjustment = {
        date_stock_adjustment: moment().toString(),
        type: true,
        quantity: null,
        price: null,
        total: null,
        observations: '',
        company_plant_id: null,
        cmc_category_id: null,
        cmc_id: null,
      },
        this.modal.create = true;
        this.checkCmc = true;
    },
    store() {
      this.$Progress.start()
      this.loadingStore = true;
      this.stockAdjustment = {... this.stockAdjustment};
      let payload =  {... this.stockAdjustment};
      payload.price = this.$helper.strToNum(payload.price);
      payload.quantity = this.$helper.strToNum(payload.quantity);
      payload.total = this.$helper.strToNum(payload.total);
      this.$store.dispatch('stockAdjustment/add', payload)
        .then((response) => {
          this.loadingStore = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          })
          this.closeModal();
        })
        .catch((error) => {
          if (error.status === 200) {
            this.$notify({
              type: 'danger',
              message: error.data.message
            })
            this.$Progress.finish();
            this.loadingStore = false;
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors)
            this.$notify({
              type: 'danger',
              message: errors
            })
            this.$Progress.finish();
            this.loadingStore = false;
          }
        })
    },
    /**
     * @param {boolean} type
     */
    handleChangeType(type) {
      this.stockAdjustment.type = type;
    },
    /**
     * @param {number} companyPlantId
     * @param {number} cmcCategoryId
     */
    getCmc(companyPlantId, cmcCategoryId) {
      if (this.stockAdjustment.company_plant_id && this.stockAdjustment.cmc_category_id) {
        this.loadingCmc = true;
        this.$store.dispatch('cmc/getCmcByPlantAndCategory', {
          company_plant_id: companyPlantId,
          category_id: cmcCategoryId,
        })
        .then((response) => {
          this.stockAdjustment.cmc_id = null;
          this.checkCmc = false;
          this.loadingCmc = false;
        });
      }
    },
    /**
     * @param {number} companyPlantId
     * @param {number} cmcCategoryId
     */
    getStockLocation(companyPlantId, cmcCategoryId) {
      if (this.stockAdjustment.company_plant_id && this.stockAdjustment.cmc_category_id) {
        this.loadingStockLocation = true;
        this.$store.dispatch('cmc/getStockLocationByPlantAndCategory', {
          company_plant_id: companyPlantId,
          category_id: cmcCategoryId,
        })
        .then((response) => {
          this.stockAdjustment.company_plant_charge_point_location_id = null;
          this.stockLocations = response.data;
          this.loadingStockLocation = false;
        });
      }
    },
    calculateTotalCost() {
      const price = moneyToFloat(this.stockAdjustment.price);
      const quantity = this.$helper.strToNum(this.stockAdjustment.quantity);
      if (this.stockAdjustment.quantity && this.stockAdjustment.price) {
        this.stockAdjustment.total = (quantity * price).toFixed(2);
      } else {
        this.stockAdjustment.total = null;
      }
    },
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        return checkNumberValue(
          mask1(value.toString(), decimals, ".", true),
          min,
          max
        );
      }
      return checkNumberValue(value, min, max);
    },
  },
};
</script>

<style scoped></style>
