<template>
<div>
    <multi-filter ref="multi-filter" @fetch="init" :filter="filter" />
    <div class="container-fluid">
        <div class="row card-wrapper" v-show="loadingSkeleton">
            <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
        </div>
         <!-- Dados vazios -->
        <PuzlEmptyData v-if="!chartAccounts.length && !loadingSkeleton"></PuzlEmptyData>
        <div class="row card-wrapper" v-if="chartAccounts.length && !loadingSkeleton">
            <div class="col-lg-4" v-for="(chartAccount, index) in chartAccounts" :key="index">
         
                <card :class="chartAccount.status ? 'card-border-top-success' : 'card-border-top-danger'">
                    <!-- Card header -->
                    <div class="row align-items-center mb-3">
                        <div class="col-9 justify-content-center pr-0">
                            <h5 class="h3 mb-0">{{chartAccount.code+'.'}} {{chartAccount.name}}</h5>
                        </div>
                        <div class="col-3 text-right pl-0">
                            <base-dropdown menuOnRight>
                                <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                                    <i class="fas fa-cog"></i>
                                </base-button>
                                <div class="dropdown-divider p-0 m-0"></div>
                                <a class="dropdown-item" @click="handleEditChartAccount(chartAccount.uuid)">
                                    <i class="fas fa-edit text-warning main-icon-menu"></i>
                                    Editar
                                </a>
                                <div class="dropdown-divider p-0 m-0"></div>
                                <a class="dropdown-item" @click="handleDeleteChartAccount(chartAccount.uuid)">
                                    <i class="fas fa-times text-danger main-icon-menu"></i>
                                    Excluir
                                </a>
                            </base-dropdown>
                        </div>
                    </div>
                    <!-- Níveis-->
                    <collapse class="border rounded p-0 mt-3">
                        <collapse-item name="1" class="header-gray card-body-molde" back-ground="border-header-primary-bottom">
                            <h5 slot="title" class="mb-0">Níveis</h5>
                            <!--Start for levels-->
                            <div v-if="chartAccount.parents[0]" v-for="(level, index) in chartAccount.parents" :key="index" class="row">
                                <div class="col-md-12">
                                    <small> {{level.code + '.'}} {{level.name}} </small>
                                </div>
                            </div>
                            <div v-if="!chartAccount.parents[0]" class="row">
                                <div class="col-md-12">
                                    <small> Nenhum </small>
                                </div>
                            </div>
                        </collapse-item>
                    </collapse>
                </card>
            </div>
        </div>
    </div>
    <modal-edit-chart-account @updatedChartAccount="init(filter)" ref="ModalEditChartAccount"></modal-edit-chart-account>
    <loading-pagination :show="loading && !loadingSkeleton"/>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import ModalEditChartAccount from './_ModalEdit';
import MultiFilter from '@/components/Utils/MultiFilter.vue';
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
export default {
    name: "ListChartAccount",
    mixins: [cursorPaginate],
    components: {
        SkeletonPuzlGrid,
        PuzlEmptyData,
        ModalEditChartAccount,
        MultiFilter,
        LoadingPagination,
    },
    data() {
        return {
            loadingSkeleton: false,
            filter: {},
        }
    },
    computed: {
        ...mapGetters({
            'chartAccounts': 'chartAccount/fetch',
        }),
    },
    watch: {
        'added'() {
            this.init()
        }
    },
    props: {
        added: Boolean
    },
    methods: {
        init(filter = null) {
            this.startCursor(filter)
            this.$Progress.start();
            this.$store.dispatch('chartAccount/fetchItemsPaginate', {
                    filter: this.filter,
                    next_page: this.paginate.nextUrl
                })
                .then((response) => {
                    var self = this;
                    this.chartAccounts.map(function (obj, key) {
                        if (obj.parents.length) {
                            obj.parents.map(function (parent, parentKey) {
                                let code = self.chartAccounts.filter(item => item.uuid == parent.uuid)[0].code
                                parent.code = code
                            })
                        }
                    });
                    this.chartAccounts.sort((a, b) => parseFloat(a.code) - parseFloat(b.code));
                    this.resolveCursor(response)
                    $Progress.finish()
                })
                .catch((error) => {
                    this.resolveCursor()
                    this.$Progress.finish()
                })
        },
        handleDeleteChartAccount(uuid) {
            this.$Swal.confirmDelete().then((result) => {
                if (result.isConfirmed) {
                    this.$Progress.start();
                    this.$notify({
                        type: 'info',
                        message: 'Estamos trabalhando em sua solicitação.'
                    });
                    this.$store.dispatch('chartAccount/destroy', uuid).then((response) => {
                        this.$notify({
                            type: response.error_type,
                            message: response.message
                        })
                        this.$Progress.finish()
                        this.init()
                    }).catch((error) => {
                        this.$notify({
                            type: error.data.error_type,
                            message: error.data.message
                        });
                    })
                }
            }).catch(() => this.$Progress.finish())
        },
        handleEditChartAccount(uuid) {
            this.$refs.ModalEditChartAccount.handleEditModal(uuid)
        },
    },
    mounted() {
        this.filter.status = 1;
        this.$refs['multi-filter']._self.$forceUpdate();
        this.init({});
    },
}
</script>

<style scoped>
.main-icon-menu {
    min-width: 18px;
}
</style>
