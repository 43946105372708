<template>
  <div class="card-body mt-n2 p-0">
    <div class="row">
      <ShadedTable :length="$_services.length" :headers="headers" :loading="loadingSkeleton">
        <template v-for="(item, index) in $_services" :slot="index">
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              <span class="float-left">
                {{ item.schedule_service_cycle_arrival_time | parseDate("DD/MMM") }}
              </span>
              <el-popover class="p-0 pr-0 float-right" placement="bottom" trigger="hover">
                <div class="p-2">
                  <img style="width: 21px" src="/img/icons/chemical-plant-black.png" />
                  <b class="text-dark"> Central </b>
                  <div class="text-left text-dark">
                    {{ item.company_plant_name }}
                  </div>
                  <div class="text-left text-dark">
                    {{ item.company_plant_charge_point_name }}
                  </div>
                </div>
                <base-button
                  slot="reference"
                  class="p-0 m-0 shadow-none"
                  outiline
                  size="sm"
                  type="secundary"
                >
                  <img src="/img/icons/chemical-plant-black.png" width="20px" height="20px" />
                </base-button>
              </el-popover>
            </h5>
          </ShadedCol>
          <ShadedCol>
            <div>
              <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
                {{ item.schedule_service_cycle_arrival_time | parseDate("HH:mm") }}
              </h5>
            </div>
            <div>
              <img src="/img/icons/clock--v1.png" width="20px" height="20px" />
            </div>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              <span class="style-table numeric text-dark table-content-font-size">
                {{ item.schedule_service_order }}
              </span>
              <a
                href="#"
                class="float-right"
                @click.prevent="$emit('getReport', item.schedule_service_order_uuid)"
              >
                <img width="28px" src="/img/icons/os.png" />
              </a>
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              <span
                class="btn btn-sm btn-primary p-1 mr-2"
                @click.prevent="$emit('copyContractProposal', item.contract_proposal_code)"
              >
                {{ item.contract_proposal_code }}
              </span>
              <span
                class="text-dark table-content-font-size"
                :title="item.entity_name"
              >
                {{ truncateString(item.entity_name, 20) }}
              </span>
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.equipment_code }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.performed_cycle }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.volume.toString().replace(".", ",") }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.type }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              <span class="float-left">
                {{ item.total | currency() }}
              </span>
              <el-popover class="p-0 float-right" placement="right" trigger="hover">
                <span class="numeric m-0 pl-1">
                  {{ item.invoice_id }}
                </span>
                <span slot="reference">
                  <img
                    v-if="item.invoice_id"
                    src="/img/icons/check.png"
                    width="22px"
                    height="22px"
                    class="float-right"
                  />
                </span>
              </el-popover>
            </h5>
          </ShadedCol>
        </template>
      </ShadedTable>
    </div>
  </div>
</template>

<script>
import SkeletonPuzl from "@/components/SkeletonPuzl";
import { mapGetters } from "vuex";
import ShadedTable from "@/components/ShadedTable.vue";
import ShadedCol from "@/components/ShadedCol.vue";

export default {
  name: "TableServices",
  data() {
    return {
      headers: [
        "Data",
        "Hora",
        "O.S.",
        "Cliente",
        "Equipamento",
        "Tempo",
        "Volume",
        "Cobrança",
        "Total",
      ],
    };
  },
  props: {
    loadingSkeleton: Boolean,
  },
  components: {
    SkeletonPuzl,
    ShadedTable,
    ShadedCol,
  },
  computed: {
    ...mapGetters({
      $_services: "salesResume/fetchServices",
    }),
  },
  methods: {
    /**
     * @param {string} str
     * @param {number} maxLength
     */
    truncateString(str, maxLength) {
      if (str && str.length > maxLength) {
        return str.slice(0, maxLength) + "...";
      }
      return str;
    },
  },
};
</script>
