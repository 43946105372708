/**
 * Definição das chaves únicas usadas para armazenar o estado.
 *
 * Cada chave deve ser única em todo o projeto.
 * Não deve haver duplicação de chaves em nenhum lugar do código.
 */
export const productServiceHubTypeStoreStateKeys = Object.freeze({
  ITEM: "PRODUCT_SERVICE_HUB_TYPE_ITEM",
  LISTED: "PRODUCT_SERVICE_HUB_TYPE_LISTED",
  IS_LISTING: "PRODUCT_SERVICE_HUB_TYPE_IS_LISTING",
  IS_FINDING: "PRODUCT_SERVICE_HUB_TYPE_IS_FINDING",
  IS_SAVING: "PRODUCT_SERVICE_HUB_TYPE_IS_SAVING",
  IS_REMOVING: "PRODUCT_SERVICE_HUB_TYPE_IS_REMOVING",
  PAGE_STATES: "PRODUCT_SERVICE_HUB_TYPE_PAGE_STATE",
});
