<template>
  <div id="reset" class="mention">
    <div class="">
      <div class="position-relative">
        <div
          ref="textareaCover"
          class="textarea-cover"
          v-html="commentMention"
        />
        <mentionable :keys="['@', '＠']" :items="items" insert-space omit-key>
          <textarea
            style="line-height: initial !important;opacity: 80% !important;"
            ref="comment"
            class="form-control"
            :rows="rows"
            v-model="comment"
            @input="handleInput"
            @keyup="commentScroll"
            @scroll="commentScroll"
          />
          <template #no-result>
            <span class="form-control">
           Nenhuma referência encontrada.
            </span>
          </template>
          <template #item="{ item }">
            <div class="user">
              <span class="font-weight-bold">
                {{ item.value }}
              </span>
              <span class="ml-2">
                {{ item.firstName }}
              </span>
            </div>
          </template>
        </mentionable>
      </div>
    </div>
  </div>
</template>

<script>
import {Mentionable} from "vue-mention";

Mentionable.methods.onKeyDown = function (e) {
  if (this.key) {
    if (e.key === "ArrowDown" || e.keyCode === 40) {
      this.selectedIndex++;
      if (this.selectedIndex >= this.displayedItems.length) {
        this.selectedIndex = 0;
      }
      this.cancelEvent(e);
    }
    if (e.key === "ArrowUp" || e.keyCode === 38) {
      this.selectedIndex--;
      if (this.selectedIndex < 0) {
        this.selectedIndex = this.displayedItems.length - 1;
      }
      this.cancelEvent(e);
    }
    if (
      (e.keyCode === 13 || e.keyCode === 9) &&
      this.displayedItems.length > 0
    ) {
      this.applyMention(this.selectedIndex);
      this.cancelEvent(e);
    }
    if (e.key === "Escape" || e.keyCode === 27) {
      this.closeMenu();
      this.cancelEvent(e);
    }
  }
};
export default {
  name: "App",
  components: {
    Mentionable,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    default_comment: {
      type: [String],
      required: false,
    },
    rows: {
      type: String,
      required: false,
      default: "4",
    }
  },
  watch: {
    default_comment: {
      immediate: true,
      handler(val) {
        this.$emit("input", val)
        this.comment = val
      }
    }
  },
  data() {
    return {
      comment: "",
    };
  },
  computed: {
    commentMention() {
      if (typeof this.comment?.replaceAll !== "function") {
        return this.comment;
      }
      const replaced =
        this.comment
          ?.replaceAll("&", "&amp;")
          ?.replaceAll(">", "&gt;")
          ?.replaceAll("<", "&lt;") + "\n\n";
      const search = new RegExp(
        this.items
          .slice()
          .sort((a, b) => b.value?.length - a.value?.length)
          .map((user) => user.value)
          .join("|"),
        "g"
      );
      return replaced.replace(search, (match, offset) => {
        return `<span class="mention-str">${match}</span>`;
      });
    },
  },
  methods: {
    handleInput(e) {
      this.$emit("input", e.target.value)
    },
    mounted() {
      setTimeout(() => {
        this.resize();
        window.addEventListener("resize", this.resize);
        this.$once("hook:beforeDestroy", () => {
          window.removeEventListener("resize", this.resize);
        });
      });
    },
    resize() {
      const barWidth =
        this.$refs.comment.getBoundingClientRect().width -
        this.$refs.comment.clientWidth -
        2; // border
      this.$refs.textareaCover.style.paddingRight = `calc(12px + ${barWidth}px)`;
    },
    commentScroll() {
      this.$refs.textareaCover.scrollTop = this.$refs.comment.scrollTop;
      this.resize();
    },
  },
};
</script>
<style>
.mention {
  font-size: 0.95rem !important;
}
.form-control comment {
  font-size: 0.95rem !important;
}
</style>

