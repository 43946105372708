import * as types from './mutation_types'
import {cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'
import { createAxios } from "@/plugins/axios";;
const endPoint = '/commercial/sales-resume'
export default {
  namespaced: true,
  state: {
    itemsInvoices: [],
    widgets: [],
    itemsConcretes: [],
    itemsServices: [],
  },
  getters:{
    fetchInvoices: state => state.itemsInvoices,
    getWidgets: state => state.widgets,
    fetchConcretes: state => state.itemsConcretes,
    fetchServices: state => state.itemsServices,
  },
  mutations: {
    [types.SET_INVOICE] (state, payload){
      state.itemsInvoices = payload
    },
    [types.SET_WIDGETS](state, payload) {
      state.widgets = payload
    },
    [types.SET_CONCRETES](state, payload) {
      state.itemsConcretes = payload
    },
    [types.SET_SERVICES](state, payload) {
      state.itemsServices = payload
    },
  },
  actions: {
    fetchInvoices({commit}, params) {
      return createAxios().get(`${endPoint}/invoices`, {params: params})
        .then(({data}) => {
          commit(types.SET_INVOICE, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getWidgets({commit}, params) {
      return createAxios().get(`${endPoint}/widgets`, {params: params})
        .then(({data}) => {
          commit(types.SET_WIDGETS, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchConcretes({commit}, params) {
      return createAxios().get(`${endPoint}/concretes`, {params: params})
        .then(({data}) => {
          commit(types.SET_CONCRETES, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchServices({commit}, params) {
      return createAxios().get(`${endPoint}/services`, {params: params})
        .then(({data}) => {
          commit(types.SET_SERVICES, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchServicesItemsPaginate({state}, params) {
      return createAxios().get(setUrlWithNextPage(`${endPoint}/services`, params),
        {params: params})
        .then(({data}) => {
          cursorPaginate(state, {data: data.data, next_page: params.next_page}, 'itemsServices')
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
