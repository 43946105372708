<template>
  <div class="container-fluid">
    <div>
      <multi-filter :type="4" :showSlotOrderBy="false" :default-range="range" @clearFilter="clearFilter()" @handleFilterDate="handleFilterDate" @fetch="init">
      </multi-filter>
    </div>
    <div class="row card-wrapper">
      <div class="col-12 table-responsive">
        <card>
          <table class="table table-sm table-bordered">
            <thead>
            <tr>
              <th>Data</th>
              <th>Usuário</th>
              <th class="text-center">Referência</th>
              <th>Central</th>
              <th>Categoria</th>
              <th>Grupo</th>
              <th>MCC</th>
              <th>Preço</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="!loadingSkeleton && $_histories.length" :key="item.id" v-for="item in $_histories">
              <td>{{item.date_at | parseDate }}</td>
              <td>{{item.user_name }}</td>
              <td class="text-center">{{item.reference_type === 2 ? 'Comercial' : 'Técnica' }}</td>
              <td>{{item.company_plant_name}}</td>
              <td>{{item.name}}</td>
              <th>{{item.cmc_group_name}}</th>
              <th>{{item.title}}</th>
              <td style="text-transform: none" class="numeric">{{item.price}}</td>
            </tr>
            <tr v-show="loadingSkeleton" v-for="item in 5">
              <td colspan="10">
                <skeleton-puzl type="text"></skeleton-puzl>
              </td>
            </tr>
            </tbody>
          </table>
          <puzl-empty-data :with-card="false" v-if="!$_histories.length && !loadingSkeleton"/>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import MultiFilter from "@/components/Utils/MultiFilterV3.vue";
import {mapGetters} from "vuex";
import cursorPaginate from "@/mixins/cursorPaginate";
import {PuzlEmptyData} from "@/components";
import {SkeletonPuzl} from "@/components";
export default {
  props: ['show'],
  name: "List",
  mixins: [cursorPaginate],
  components: {
    MultiFilter,
    PuzlEmptyData,
    SkeletonPuzl
  },
  data() {
    return {
      loadingSkeleton: false,
      range: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        end: new Date(),
      },
    }
  },
  computed: {
    ...mapGetters({
      $_histories: "MixReferenceHistory/fetch",
    }),
  },
  methods: {
    init(filter = null) {
      if (filter) {
        this.filter = {...this.filter, ...filter};
      }
      this.filter.type = this.type
      this.filter.view_type = this.view_type
      this.filter.range = this.range
      this.startCursor(filter)
      this.loadingSkeleton = true
      this.$store.dispatch('MixReferenceHistory/fetch',
        {
          filter: this.filter,
          next_page: this.paginate.nextUrl
        }).then((response) => {
        this.loadingSkeleton = false
        this.resolveCursor(response)
      })
    },
    clearFilter(){
      this.filter = {}
      this.init({});
    },
    handleFilterDate(filter) {
      this.range = filter;
      this.init({});
    },
  },
  mounted() {
    this.loadingSkeleton = true
    this.init({})
  }
}
</script>
