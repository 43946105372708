<template>
	<div class="custom-card-wrapper mt-4 pt-4" id="financeTransactionList">
		<div class="container">
			<div class="row fixed-list-title">
				<nav>
					<div class="container">
						<div class="row align-items-center">
							<div class="col-auto">
								<div style="display: flex;align-items: center;">
									<img src="/img/icons/icons8/ios/menu_dark.png"
										width="17"
										class="mr-2">
									<h4 class="new-default-black-font m-0 ml-1">
										Movimentos do extrato de conciliação financeira
									</h4>
								</div>
							</div>
							<div class="col text-right">
                <h6 class="m-0 pt-1 text-primary pointer"
                  @click.prevent="$emit('selectOrDeselectAll')"
                  style="width: fit-content;float: right;">
                  {{ isAllSelected ? "Desmarcar todos" : "Selecionar todos" }}
                </h6>
							</div>
						</div>
					</div>
				</nav>
			</div>
	    <div class="row card-wrapper px-1 mt-4 pt-1">
	      <div class="col-lg-4 px-3" v-for="(financeTransaction, index) in financeTransactions" :key="index">
	        <div class="card main-card mb-3">
	          <div class="p-3">
	          	<div class="row align-items-center">
	          		<div class="col-auto pr-0">
						      <div :class="transactionCardType[Number(financeTransaction.is_credit)].type">
						        <img :src="transactionCardType[Number(financeTransaction.is_credit)].img">
						        <h4>
						          {{ generateBy[financeTransaction.move_type] }}
						        </h4>
						      </div>
	          		</div>
	          		<div class="col">
									<img :src="financeTransactionSelection[index] ? '/img/icons/icons8/ios/checked-checkbox.png' : '/img/icons/icons8/ios/checked-checkbox--v1.png'"
										style="float: right;"
										@click="$emit('selectOrUnselect', index)"
										class="select-to-reconcile"
										width="22">
	          		</div>
	          	</div>
	            <div class="row mt-3 align-items-end">
	              <div class="col-12">
	                <h5 class="font-weight-normal new-default-black-font m-0 mt-1">
	                  {{ financeTransaction.payment_method_name }}
	                </h5>
	                <div style="display: flex;align-items: baseline;">
	                  <p style="font-size: 16px;" class="m-0 new-default-black-font font-weight-bold p-0 mr-1">R$</p>
	                  <h1 class="m-0">{{ financeTransaction.amount | currency('') }}</h1>
	                </div>
	                <h5 class="new-default-gray-font font-weight-normal m-0" style="display: flex;align-items: center;">
	                  {{ financeTransaction.discharge_at | parseDate('DD MMM YYYY') }}
	                  <div class="ml-3 no-shadow-card-blue"
	                    style="display: inline-flex;">
	                    <h4 class="m-0 p-0">
	                      {{ financeTransaction.installment_number }} / {{ financeTransaction.installment_count }}
	                    </h4>
	                  </div>
	                </h5>
	              </div>
	          	</div>
	          	<hr class="gray-divider my-2">
	          	<div class="row">
	          		<div class="col-12">
	          			<h4 class="new-default-black-font m-0 font-weight-normal">
	          				{{ financeTransaction.entity_name }}
	          			</h4>
	          			<h4 class="new-default-black-font m-0 font-weight-normal">
	          				{{ financeTransaction.entity_document }}
	          			</h4>
	          		</div>
	          	</div>
          	</div>
        	</div>
      	</div>
			</div>
		</div>
	</div>
</template>
<script>
import { FinanceTransactionEnum } from "@/enum/FinanceTransactionEnum";

export default {
	name: "Cards",
  props: {
  	financeTransactions: {
  		type: Array
  	},
  	financeTransactionSelection: {
  		type: Array
  	},
    isAllSelected: { type: Boolean },
  },
  data() {
    return {
    	financeTransactionEnum: FinanceTransactionEnum,
    	generateBy: [
    		'Op. Financeira',
    		'Contas à pagar',
    		'Créd. e Antecip.',
    		'Contas à receber',
    		'Transf. Bancária',
    	],
    	transactionCardType: {
    		0: {
    			img: '/img/icons/icons8/ios/duration-finance--v1_danger.png',
    			type: 'new-minicard-red',
    		},
    		1: {
    			img: '/img/icons/icons8/ios/duration-finance_success.png',
    			type: 'new-minicard-green',
    		}
  		},
    }
  }
}
</script>
<style scoped>
.gray-divider {
  background-color: #E8E8E8;
  color: #E8E8E8;
  margin: 0;
  border-top: 1px solid #E8E8E8;
}

h1, h2, h3, h4, h5, p {
	font-family: "Fredoka", sans-serif;
}

.custom-card-wrapper {
	border: 1px solid #E8E8E8;
	border-radius: 16px;
	padding: 16px 12px;
	min-height: 55px;
	max-height: 520px;
  overflow-y: scroll;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.07) !important;
}

.fixed-list-title {
	position: absolute;
	top: 177px;
  left: 15px;
  width: 100%;
	padding-right: 40px;
  padding-left: 40px;
  z-index: 2;
}

.fixed-list-title h4 {
  font-size: 12px;
}

@media (min-width: 992px) {
	.fixed-list-title {
	  top: 130px;
	}

  .fixed-list-title h4 {
    font-size: 14px;
  }
}

.fixed-list-title nav {
	width: 100%;
  background: white;
  height: 44px;
	padding-top: 14px;
  border-radius: 16px;
}

.card {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.07) !important;
  border-radius: 8px !important;
  border: 1px solid #ECECEC;
}

.colorize-on-hover:hover {
  background-color: #1b6eba;
}

.colorize-on-hover:hover small, .colorize-on-hover:hover img {
  filter: brightness(0) invert(1);
}

.new-cardwithbox-shadow {
  text-align: center;
  box-shadow: 0 5px 10px 0 #f3f3f3;
  display: flex;
  border: 1px solid #E8E8E8;
  border-radius: 5px !important;
  align-items: center;
  padding: 4px 0px;
}

.new-cardwithbox-shadow div {
  display: inline-flex;
  align-items: center;
}

.no-shadow-card-blue {
  border-radius: 5px !important;
  background-color: #7498C7;
  padding: 0px 5px;
}

.no-shadow-card-blue h4 {
  color: white;
  padding: 0;
  font-size: 12px;
  letter-spacing: 1px;
}

.mini-card-green {
  background-color: #8bd5af52;
  border-radius: 5px;
  padding: 1px 5px;
  font-size: 12px;
  box-shadow: 0 0 5px 0 #149e5752;
}

.mini-card-red {
  background-color: #f459592e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #d2272752;
}

.mini-card-yellow {
  background-color: #f4dc592e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #fdef0b52;
}

.mini-card-blue {
  background-color: #4182e52e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #0b7bfd52;
}

.mini-card-green h4 {
  color: #149e57;
}

.mini-card-red h4 {
  color: #db4539;
}

.mini-card-yellow h4 {
  color: #f2b532;
}

.mini-card-blue h4 {
  color: #1b6eba;
}

.mini-card-red h4, .mini-card-green h4, .mini-card-yellow h4, .mini-card-blue h4 {
  font-size: 12px;
}

.new-minicard-green {
  background-color: #F2F7F3;
  border-radius: 15px;
  padding: 5px 12px;
  font-size: 12px;
}

.new-minicard-red {
  background-color: #FCF3F3;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-yellow {
  background-color: #FEF9F2;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-blue {
  background-color: #F2F4F9;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-gray {
  background-color: #646e7d2e;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-green h4 {
  color: #149e57;
}

.new-minicard-red h4 {
  color: #db4539;
}

.new-minicard-yellow h4 {
  color: #f2b532;
}

.new-minicard-blue h4 {
  color: #1b6eba;
}

.new-minicard-gray h4 {
  color: #66696c;
}

.new-minicard-red, .new-minicard-green, .new-minicard-yellow, .new-minicard-blue, .new-minicard-gray {
  align-items: center;
  display: inline-flex;
  cursor: pointer;
  text-align: center;
}

.new-minicard-red img:first-child, .new-minicard-green img:first-child, .new-minicard-yellow img:first-child, .new-minicard-blue img:first-child, .new-minicard-gray img:first-child {
  width: 16px;
}

.new-minicard-red h4, .new-minicard-green h4, .new-minicard-yellow h4, .new-minicard-blue h4, .new-minicard-gray h4 {
  font-size: 12px;
  margin: 0 6px 0 6px;
  font-weight: normal !important;
}

.select-to-reconcile {
	cursor: pointer;
}
</style>
