<template>
  <div>
    <modal class="modal-general-resume" :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">{{ title }}</h5>
      </template>
      <div v-if="modal">
        <div class="pl-3 pr-3 pt-0 pb-3">
          <!-- Total -->
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0">Total</h3>
            </div>
            <div class="col-6 d-flex justify-content-end">
              <span class="font-weight-bold h3 mb-0">{{ resume.totals.billing | currency() }}</span>
            </div>
          </div>
          <!-- Pago -->
          <div v-show="resume.totals.billing !== 0" class="row">
            <div class="col-6">
              <h4 class="mb-0 text-success">Pago</h4>
            </div>
            <div class="col-6 d-flex justify-content-end">
              <span class="font-weight-normal h4 mb-0">{{ resume.payments.total_payment_paid | currency() }} </span>
            </div>
          </div>
           <!-- Aprovado -->
          <div v-show="resume.totals.billing !== 0" class="row">
            <div class="col-6">
              <h4 style="color: #4f40ff" class="mb-0">Aprovado</h4>
            </div>
            <div class="col-6 d-flex justify-content-end">
              <span class="font-weight-normal h4 mb-0">{{ resume.payments.total_payment_approved | currency() }} </span>
            </div>
          </div>
          <!-- Pendente -->
          <div v-show="resume.totals.billing !== 0" v-if="resume.payments.total_payment_pending > 0" class="row">
            <div class="col-6">
              <h4 class="mb-0 text-warning">Pendente</h4>
            </div>
            <div class="col-6 d-flex justify-content-end">
              <span class="font-weight-normal h4 mb-0">{{ resume.payments.total_payment_pending | currency() }} </span>
            </div>
          </div>
          <!-- Atrasado -->
          <div v-show="resume.totals.billing !== 0" v-if="resume.payments.total_payment_overdue > 0" class="row">
            <div class="col-6">
              <h4 class="mb-0 text-danger">Atrasado</h4>
            </div>
            <div class="col-6 d-flex justify-content-end">
              <span class="font-weight-normal h4 mb-0">{{ resume.payments.total_payment_overdue | currency() }} </span>
            </div>
          </div>

          <div v-if="resume.totals.formulation > 0">
            <hr class="mt-3 mb-3" />
            <!-- Concreto -->
            <div class="row">
              <div class="col-6">
                <h3 class="mb-0 text-primary">Concreto</h3>
              </div>
              <div class="col-6 d-flex justify-content-end">
                <span class="font-weight-bold h3 mb-0">{{ resume.totals.formulation | currency() }} </span>
              </div>
            </div>
          </div>

          <!-- Volume entregue -->
          <div v-show="resume.totals.formulation !== 0" class="row">
            <div class="col-6 pl-3">
              <h4 class="mb-0">Volume entregue</h4>
            </div>
            <div class="col-6 d-flex justify-content-end">
              <span class="font-weight-normal h4 mb-0">{{ resume.totals.formulation_performed_volume }} m³ </span>
            </div>
          </div>

          <!-- Volume cancelado -->
          <div v-show="resume.totals.formulation !== 0 || resume.totals.formulation_cancelled_volume !== 0" class="row mb-3">
            <div class="col-6">
              <h4 class="mb-0 text-danger">Volume cancelado</h4>
            </div>
            <div class="col-6 d-flex justify-content-end">
              <span class="font-weight-normal h4 mb-0">{{ resume.totals.formulation_cancelled_volume }} m³ </span>
            </div>
          </div>

          <!-- Resumo concreto -->
          <collapse v-show="perfomed_formulations.length" class="border rounded p-0">
            <collapse-item name="1" class="header-gray" back-ground="border-header-primary-bottom">
              <h5 slot="title" class="mb-0">Resumo</h5>
              <div v-for="(mix_design, index_mix_design) in perfomed_formulations" :key="index_mix_design">
                <div class="p-2">
                  <div class="border-left border-3 border-primary p-2 row">
                    <h5 class="mb-0 col-7 pl-0">
                      {{ mix_design.title }}
                    </h5>
                    <div class="col-5 pl-0 pr-0 d-flex justify-content-end">
                      <h4 class="mb-0">
                        {{ mix_design.total_formulation_billing | currency() }}
                      </h4>
                    </div>

                    <span class="p small col-10 pl-0">
                      {{ mix_design.body }}
                    </span>
                    <div class="col-2 pl-0 pr-0 d-flex justify-content-end">
                      <h4 class="mb-0 font-weight-normal">{{ mix_design.total_formulation_performed_volume }} m³</h4>
                    </div>
                  </div>
                  <div class="p-2 row mt-2" v-if="mix_design.puzl_additions.length">
                    <div class="col-md-12 pl-2">
                      <div class="border-left border-3 border-indigo p-2 row">
                        <div v-for="(puzl_addition, index) in mix_design.puzl_additions" :key="puzl_addition.id">
                          <h5 class="text-indigo mb-0">
                            {{ puzl_addition.name }}
                            ({{ puzl_addition.volume }} kg/m<sup>3</sup>)
                            <span class="text-indigo" v-if="index != mix_design.puzl_additions.length - 1">+</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </collapse-item>
          </collapse>

          <div v-if="resume.totals.service > 0">
            <hr class="mt-3 mb-3" />
            <!-- Serviços -->
            <div class="row mt-3">
              <div class="col-6">
                <h3 class="mb-0 text-info">Serviços</h3>
              </div>
              <div class="col-6 d-flex justify-content-end">
                <span class="font-weight-bold h3 mb-0">{{ resume.totals.service | currency() }} </span>
              </div>
            </div>
          </div>

          <!-- Fixo -->
          <div v-show="resume.totals.service !== 0" class="row">
            <div class="col-3">
              <h4 class="mb-0">Fixo</h4>
            </div>
            <div class="col-2">
              <span class="font-weight-normal h4 mb-0 pr-3 ml-n4">{{ resume.totals.minimum_service }}</span>
            </div>
            <div class="col-7 d-flex justify-content-end">
              <span class="font-weight-normal h4 mb-0">{{ resume.totals.cost_minimum_service | currency() }} </span>
            </div>
          </div>
          <!-- Por m³ -->
          <div v-show="resume.totals.service !== 0" class="row">
            <div class="col-3">
              <h4 class="mb-0">Por m³</h4>
            </div>
            <div class="col-2">
              <span class="font-weight-normal h4 mb-0 pr-3 ml-n4">{{ resume.totals.m3_service }} m³</span>
            </div>
            <div class="col-7 d-flex justify-content-end">
              <span class="font-weight-normal h4 mb-0">{{ resume.totals.cost_m3_service | currency() }} </span>
            </div>
          </div>
          <!-- Por hora -->
          <div v-show="resume.totals.service !== 0" class="row mb-3">
            <div class="col-3">
              <h4 class="mb-0">Por hora</h4>
            </div>
            <div class="col-2">
              <span class="font-weight-normal h4 mb-0 pr-3 ml-n4">{{ resume.totals.hour_service }}</span>
            </div>
            <div class="col-7 d-flex justify-content-end">
              <span class="font-weight-normal h4 mb-0">{{ resume.totals.cost_hour_service | currency() }}</span>
            </div>
          </div>

          <!-- Resumo serviços -->
          <collapse v-show="perfomed_services.length" class="border rounded p-0">
            <collapse-item name="1" class="header-gray" back-ground="border-header-info-bottom">
              <h5 slot="title" class="mb-0">Resumo</h5>
              <div v-for="(service, index_service) in perfomed_services" :key="index_service">
                <div class="p-2">
                  <div class="border-left border-3 border-info p-2 row">
                    <h4 class="mb-0 col-7 pl-0">
                      {{ service.service_name }}
                    </h4>
                    <div class="col-5 pl-0 pr-0 d-flex justify-content-end">
                      <h4 class="mb-0">
                        {{ service.total_cost_service | currency() }}
                      </h4>
                    </div>

                    <h5 class="mb-0 font-weight-normal" v-if="service.service_description">
                      {{ service.service_description }}
                    </h5>
                  </div>
                </div>
              </div>
            </collapse-item>
          </collapse>

          <div v-if="resume.totals.additional">
            <hr class="mt-3 mb-3" />
            <!-- Adicionais -->
            <div class="row mt-3 mb-3">
              <div class="col-6">
                <h3 class="mb-0 custom-text-orange">Adicionais</h3>
              </div>
              <div class="col-6 d-flex justify-content-end">
                <span class="font-weight-bold h3 mb-0">{{ resume.totals.additional | currency() }} </span>
              </div>
            </div>
          </div>

          <!-- Resumo adicionais -->
          <collapse v-show="perfomed_additionals.length" class="border rounded p-0">
            <collapse-item name="1" class="header-gray border-header-orange-bottom">
              <h5 slot="title" class="mb-0">Resumo</h5>
              <div v-for="(additional, index_additional) in perfomed_additionals" :key="index_additional">
                <div class="p-2">
                  <div class="border-orange border-3 border-orange p-2 row">
                    <h4 class="mb-0 col-8 pl-0">
                      {{ additional.short_description }}
                    </h4>
                    <div class="col-4 pl-0 pr-0 d-flex justify-content-end">
                      <h4 class="mb-0">
                        {{ additional.total_additional_cost | currency() }}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </collapse-item>
          </collapse>

          <!-- Avulsos -->
          <div class="row mt-3 mb-3" v-if="resume.totals.single !== 0">
            <div class="col-6">
              <h3 class="mb-0 text-indigo">Avulsos</h3>
            </div>
            <div class="col-6 d-flex justify-content-end">
              <span class="font-weight-bold h3 mb-0">{{ resume.totals.single | currency() }} </span>
            </div>
          </div>

          <!-- Resumo avulsos -->
          <collapse v-show="resume.singles.length" class="border rounded p-0">
            <collapse-item name="1" back-ground="border-header-indigo-bottom" class="header-gray">
              <h5 slot="title" class="mb-0">Resumo</h5>
              <div v-for="(single, single_index) in resume.singles" :key="single_index">
                <div class="p-2">
                  <div class="border-left border-3 border-indigo p-2 row">
                    <h4 class="mb-0 col-8 pl-0">
                      {{ single.description }}
                    </h4>
                    <div class="col-4 pl-0 pr-0 d-flex justify-content-end">
                      <h4 class="mb-0">
                        {{ single.total_value | currency() }}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </collapse-item>
          </collapse>

          <!-- Cancelamentos -->
          <div class="row mt-3 mb-3" v-if="resume.totals.cancelled > 0">
            <div class="col-6">
              <h3 class="mb-0 text-danger">Cancelamentos</h3>
            </div>
            <div class="col-6 d-flex justify-content-end">
              <span class="font-weight-bold h3 mb-0">{{ resume.totals.cancelled | currency() }} </span>
            </div>
          </div>

          <!-- Resumo cancelamentos -->
          <collapse v-show="cancelled_additionals.length || cancelled_services.length || cancelled_formulations.length" class="border rounded p-0">
            <collapse-item name="1" back-ground="border-header-danger-bottom" class="header-gray">
              <h5 slot="title" class="mb-0">Resumo</h5>

              <!-- formulacoes canceladas -->
              <div
                v-for="(cancelled_formulation, cancelled_formulation_index) in cancelled_formulations"
                :key="cancelled_formulation_index"
              >
                <div class="p-2">
                  <div class="border-left border-3 border-primary p-2 row">
                    <h5 class="mb-0 col-7 pl-0">
                      {{ cancelled_formulation.title }}
                    </h5>
                    <div class="col-5 pl-0 pr-0 d-flex justify-content-end">
                      <h4 class="mb-0">
                        {{ cancelled_formulation.total_formulation_cancelled_billing | currency() }}
                      </h4>
                    </div>

                    <span class="p small col-10 pl-0">
                      {{ cancelled_formulation.body }}
                    </span>
                    <div class="col-2 pl-0 pr-0 d-flex justify-content-end">
                      <h4 class="mb-0 font-weight-normal">{{ cancelled_formulation.total_formulation_cancelled_volume }} m³</h4>
                    </div>
                  </div>
                  <div class="p-2 row mt-2" v-if="cancelled_formulation.puzl_additions.length">
                    <div class="col-md-12 pl-2">
                      <div class="border-left border-3 border-indigo p-2 row">
                        <div v-for="(puzl_addition, index) in cancelled_formulation.puzl_additions" :key="puzl_addition.id">
                          <h5 class="text-indigo mb-0">
                            {{ puzl_addition.name }}
                            ({{ puzl_addition.volume }} kg/m<sup>3</sup>)
                            <span class="text-indigo" v-if="index != cancelled_formulation.puzl_additions.length - 1">+</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Servicos Cancelados -->
              <div v-for="(service, service_index) in cancelled_services" :key="service_index">
                <div class="p-2">
                  <div class="border-left border-3 border-info p-2 row">
                    <h4 class="mb-0 col-7 pl-0">
                      {{ service.service_name }}
                    </h4>
                    <div class="col-5 pl-0 pr-0 d-flex justify-content-end">
                      <h4 class="mb-0">
                        {{ service.total_cancelled_cost_service | currency() }}
                      </h4>
                    </div>

                    <h5 class="mb-0 font-weight-normal" v-if="service.service_description">
                      {{ service.service_description }}
                    </h5>
                  </div>
                </div>
              </div>

              <!-- Adicionais Cancelados -->
              <div v-for="(additional, additional_index) in cancelled_additionals" :key="additional_index">
                <div class="p-2">
                  <div class="border-orange border-3 border-orange p-2 row">
                    <h4 class="mb-0 col-8 pl-0">
                      {{ additional.short_description }}
                    </h4>
                    <div class="col-4 pl-0 pr-0 d-flex justify-content-end">
                      <h4 class="mb-0">
                        {{ additional.cancelled_additional_cost | currency() }}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </collapse-item>
          </collapse>
        </div>

        <div class="modal-footer justify-content-center">
          <base-button type="secondary" @click="closeModal()">Fechar</base-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  name: "ModalGeneralResume",
  data() {
    return {
      modal: false,
      title: "Resumo geral",
    };
  },
  computed: {
    ...mapGetters({
      resume: "contractProposal/getResume",
    }),
    perfomed_formulations() {
      return this.resume.formulations.filter((item) => item.total_formulation_performed_volume !== "0.0");
    },
    perfomed_services() {
      return this.resume.services.filter((item) => item.total_cost_service !== "0.00");
    },
    perfomed_additionals() {
      return this.resume.additionals.filter((item) => item.total_additional_cost !== "0.00");
    },
    cancelled_additionals() {
      return this.resume.additionals.filter((item) => item.cancelled_additional_cost !== "0.00");
    },
    cancelled_services() {
      return this.resume.services.filter((item) => item.total_cancelled_cost_service !== "0.00");
    },
    cancelled_formulations() {
      return this.resume.formulations.filter((item) => parseFloat(item.total_formulation_cancelled_volume) > 0);
    },
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    openModal(id) {
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });
      this.$store.dispatch("contractProposal/getGeneralResume", id).then((response) => {
        this.$notify({
          type: response.error_type,
          message: response.message,
        });
        this.modal = true;
      });
    },
  },
};
</script>

<style>
.modal-general-resume {
  overflow-y: scroll !important;
}
.custom-text-orange {
  color: #f77455 !important;
}
.border-header-orange-bottom .card-header {
  border-bottom: 1px solid #f77455;
  background-color: #f8f9fe !important;
}
.border-orange {
  border-left: 1px solid #f77455 !important;
}
</style>
