<template>
  <div>
    <modal size="lg" :show.sync="modal.create">
      <template slot="header">
        <div style="display: flex; align-items: center">
          <img
            style="display: inline-flex"
            src="/img/icons/icons8/ios/ratings-white.png"
            width="22"
          />
          <h5
            style="display: inline-flex"
            class="modal-title p-0 m-0 ml-3 text-white"
          >
            {{ title }}
          </h5>
        </div>
      </template>
      <div>
        <div class="row">
          <div class="col-12" style="display: flex; align-items: center">
            <img src="/img/icons/icons8/ios/info-squared.png" width="22" />
            <h4 class="new-default-black-font m-0 mx-3">Identificação</h4>
            <hr class="ml-3" style="width: 100%" />
          </div>
        </div>
        <div class="container mt-3">
          <div class="d-flex flex-column">
            <h4
              style="font-size: 1.2rem; padding-top: -5px"
              class="font-weight-600 mt-n2"
            >
              {{ contractProposalIdentification.code }}
            </h4>
            <h4 class="font-weight-500">
              {{ contractProposalIdentification.customer_name }}
            </h4>
            <h4 class="mt-n2 font-weight-400">
              {{ contractProposalIdentification.construction_name }}
            </h4>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12" style="display: flex; align-items: center">
            <img src="/img/icons/icons8/ios/ratings.png" width="25" />
            <h2 class="new-default-black-font m-0 mx-3">Projeção</h2>
            <hr class="ml-3" style="width: 450px" />
            <base-button
              @click="
                handleCreateProjectionModal(contractProposalIdentification.id)
              "
              class="pl-5 pr-5"
              type="success"
              size="sm"
            >
              <img
                height="19"
                src="/img/icons/plus-math--v1-white.png"
                class="mt-n1"
              />
              NOVO
            </base-button>
          </div>
        </div>
        <ShadedTable
          :length="$_projections.length"
          :headers="headers"
          :loading="loading"
        >
          <template v-for="(projection, index) in $_projections" :slot="index">
            <ShadedCol>
              <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
                {{ projection.company_name }}
              </h5>
            </ShadedCol>
            <ShadedCol align="center">
              <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
                {{
                  formatDate(
                    projection.projection_month,
                    projection.projection_year
                  )
                }}
              </h5>
            </ShadedCol>
            <ShadedCol align="center">
              <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
                {{ projection.volume_prediction }}
              </h5>
            </ShadedCol>
            <ShadedCol>
              <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
                <!-- Realizado -->
              </h5>
            </ShadedCol>
            <ShadedCol align="center">
              <base-dropdown menuOnRight>
                <div slot="title-container" class="dropdown-toggle rounded m-0">
                  <img
                    width="22"
                    src="/img/icons/icons8/ios/settings--v1_primary.png"
                  />
                </div>
                <a
                  class="dropdown-item font-weight-500 new-default-black-font"
                  style="align-items: center; display: flex"
                  @click="handleEditProjectionModal(projection.id)"
                >
                  <img width="22" src="/img/icons/create-new.png" />
                  Editar
                </a>
                <div class="dropdown-divider p-0 m-0"></div>
                <a
                  class="dropdown-item font-weight-500 new-default-black-font"
                  style="align-items: center; display: flex"
                  @click.prevent="handleDelete(projection.id)"
                >
                  <img
                    width="22"
                    src="/img/icons/icons8/ios/delete--v1_danger.png"
                  />
                  Excluir
                </a>
              </base-dropdown>
            </ShadedCol>
          </template>
        </ShadedTable>
      </div>
    </modal>
    <ModalCreateProjection ref="modalCreateProjection" />
    <ModalEditProjection ref="modalEditProjection" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ShadedTable from "@/components/ShadedTable.vue";
import ShadedCol from "@/components/ShadedCol.vue";
import ModalCreateProjection from "./_ModalCreateProjection.vue";
import ModalEditProjection from "./_ModalEditProjection.vue";

export default {
  name: "ModalPortfolioProjection",
  components: {
    ShadedTable,
    ShadedCol,
    ModalCreateProjection,
    ModalEditProjection,
  },
  data() {
    return {
      title: "Projeção de Carteira",
      headers: ["Central", "Mês", "Previsto (m³)", "Realizado (m³)", "Ação"],
      modal: {
        create: false,
      },
      loading: false,
      contractProposalIdentification: {
        id: null,
        code: null,
        customer_name: null,
        construction_name: null,
        company_plant_id: null,
      },
      abbreviatedMonths: [
        "JAN",
        "FEV",
        "MAR",
        "ABR",
        "MAI",
        "JUN",
        "JUL",
        "AGO",
        "SET",
        "OUT",
        "NOV",
        "DEZ",
      ],
    };
  },
  computed: {
    ...mapGetters({
      $_projections: "contractProposalProjection/fetch",
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal(contractPropsalId, contract) {
      this.contractProposalIdentification.id = contractPropsalId;
      this.contractProposalIdentification.code = contract[0];
      this.contractProposalIdentification.customer_name = contract[1];
      this.contractProposalIdentification.construction_name = contract[2];
      this.contractProposalIdentification.company_plant_id = contract[3];
      this.modal.create = true;
      this.init(this.contractProposalIdentification.id);
    },
    handleCreateProjectionModal(contractProposalId) {
      this.$refs.modalCreateProjection.openModal(
        contractProposalId,
        this.contractProposalIdentification.company_plant_id
      );
    },
    handleEditProjectionModal(projectionId) {
      this.$refs.modalEditProjection.handleEditProjectionModal(projectionId);
    },
    init(contractProposalId) {
      this.$Progress.start();
      this.$store
        .dispatch(
          "contractProposalProjection/getByContractProposal",
          contractProposalId
        )
        .then((response) => {
          this.$Progress.finish();
        });
    },
    formatDate(month, year) {
      var nameAbbreviatedMonth = this.abbreviatedMonths[month - 1];
      return nameAbbreviatedMonth + "/" + year;
    },
    handleDelete(id) {
      this.$Swal
        .confirmDelete()
        .then((result) => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação.",
            });
            this.$store
              .dispatch("contractProposalProjection/destroy", id)
              .then((response) => {
                this.$notify({
                  type: "success",
                  message: response.message,
                });
              })
              .catch((error) => {
                this.$notify({
                  type: error.data.error_type,
                  message: error.data.message,
                });
              });
            this.$Progress.finish();
          }
        })
        .catch(() => this.$Progress.finish());
    },
  },
};
</script>
