<template>
  <div>
    <base-header class="bg-gray-content">
      <puzl-breadcrumb />
      <!-- Header Menus -->
      <div class="row mb-3">
        <div class="col-md-2 mb-2">
          <back-button />
        </div>
      </div>
    </base-header>
    <div class="container-fluid">
      <div>
        <card>
          <div>
            <div class="row d-flex justify-content-start mb-3">
              <div class="row col-md-6 m-0 p-0">
                <div class="col-md-4 mb-2 mt-2">
                  <base-button @click.prevent="tab = 0" type="primary" outline size="sm" block :class="tab === 0 ? 'active' : ''">
                    DOCUMENTO
                    <span>
                      <i v-show="pendingDocumentTab()" class="fa-solid fa-circle-exclamation text-warning" />
                    </span>
                  </base-button>
                </div>
                <div class="col-md-4 mb-2 mt-2">
                  <base-button
                    :disabled="!exit_launch.product_service_hub_type_id || !exit_launch.company_plant_issuer_id"
                    @click.prevent="tab = 1"
                    type="primary"
                    outline
                    size="sm"
                    block
                    :class="tab === 1 ? 'active' : ''"
                  >
                    ITENS
                    <span>
                      <i v-show="pendingItemTab()" class="fa-solid fa-circle-exclamation text-warning" />
                    </span>
                  </base-button>
                </div>
                <div class="col-md-4 mb-2 mt-2">
                  <base-button
                    :disabled="!exit_launch.product_service_hub_type_id"
                    @click.prevent="tab = 2"
                    type="primary"
                    outline
                    size="sm"
                    block
                    :class="tab === 2 ? 'active' : ''"
                  >
                    TRANSPORTE
                  </base-button>
                </div>
              </div>
            </div>
            <hr class="m-0 mb-3" />
            <div v-if="!loadingEntity" class="border-left border-indigo border-3 mt-2 pl-2 mb-3">
              <div>
                <h4 class="mb-0 col-12 pl-2">{{ $_entity.entity_name }}</h4>
                <h4 class="mb-0 col-12 font-weight-normal pl-2">{{ $_entity.document }}</h4>
              </div>
            </div>
            <!-- Documento -->
            <div v-show="tab === 0">
              <!-- CPF/CNPJ -->
              <div v-show="loadingEntity" class="col-md-4">
                <SkeletonPuzlFullWidth />
              </div>
              <!-- Identificação -->
              <div>
                <div class="border-header-success-bottom">
                  <h5 class="h4 p-1 mb-0">Identificação</h5>
                </div>
                <div class="w-100 row m-0 justify-content-center p-0">
                  <!-- Central de Saída -->
                  <div class="col-md-4 row pl-4 pr-md-4 pr-0 pl-md-0 mt-2 mb-2">
                    <label class="col-12 pb-0 pl-0 mb-1 col-form-label form-control-label">
                      Central de Saída
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="w-100 pr-3 ml-0 mr-0 pt-2">
                      <base-input input-classes="form-control-sm">
                        <puzl-select
                          @input="fillCompanyPlant($event)"
                          v-model="exit_launch.exit_company_plant_id"
                          :items="$_company_plants"
                          :clearable="false"
                          :disabled="!$_company_plants.length"
                        />
                      </base-input>
                    </div>
                  </div>
                  <!-- Tipo -->
                  <div class="col-md-4 row pl-4 pr-md-4 pr-0 pl-md-0 mt-2 mb-2">
                    <label class="col-12 pb-0 pl-0 mb-1 col-form-label form-control-label">
                      Tipo de Itens
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="w-100 pr-3 ml-0 mr-0 pt-2">
                      <validation-provider rules="required">
                        <base-input input-group-classes="input-group-sm">
                          <puzl-select
                            v-model="exit_launch.product_service_hub_type_id"
                            :items="product_service_hub_types"
                            @change="checkProductServiceHubType"
                            :clearable="false"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <!-- Natureza da Operação -->
                  <div class="col-md-4 row pl-4 pr-md-4 pr-0 pl-md-0 mt-2 mb-2">
                    <label class="col-12 pb-0 pl-0 mb-1 col-form-label form-control-label">
                      Tipo de Operação Puzl(TOP) <span class="text-danger">&nbsp;*</span>
                      <el-popover v-if="operation_source" trigger="hover" placement="bottom" class="p-0 float-right pr-0">
                        <div class="border-left border-success border-3 p-2 pb-0 mb-3">
                          <h4 class="mb-0">{{ operation_source.name }}</h4>
                        </div>
                        <h5 class="mb-0 font-weight-normal">
                          <i v-if="operation_source.is_generate_financial" class="fas fa-check text-success" />
                          <i v-else class="fas fa-ban text-danger" />
                          Gera Financeiro
                        </h5>
                        <h5 class="mb-1 font-weight-normal">
                          <i v-if="operation_source.is_generate_stock" class="fas fa-check text-success" />
                          <i v-else class="fas fa-ban text-danger" />
                          Movimenta Estoque
                        </h5>
                        <h5 class="mb-1 font-weight-normal">
                          <i v-if="operation_source.is_entry_value" class="fas fa-check text-success" />
                          <i v-else class="fas fa-ban text-danger" />
                          Pagamento Antecipado
                        </h5>
                        <h5 class="mb-1 font-weight-normal">
                          <i v-if="operation_source.is_computable" class="fas fa-check text-success" />
                          <i v-else class="fas fa-ban text-danger" />
                          Contabiliza
                        </h5>
                        <h5 class="mb-1 font-weight-normal">
                          <i v-if="operation_source.is_generate_tax_book" class="fas fa-check text-success" />
                          <i v-else class="fas fa-ban text-danger" />
                          Gera Livro Fiscal
                        </h5>
                        <base-button outline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none">
                          <i style="font-size: 20px !important" class="fas fa-puzzle-piece text-center text-indigo" />
                        </base-button>
                      </el-popover>
                    </label>
                    <div class="w-100 pr-3 ml-0 mr-0 pt-2">
                      <base-input input-group-classes="input-group-sm">
                        <puzl-select
                          @input="setOperationSourceById"
                          v-model="exit_launch.operation_source_id"
                          :items="$_operation_sources"
                          :loading="loadingOperationSource"
                          :disabled="loadingOperationSource || !exit_launch.product_service_hub_type_id"
                          :clearable="false"
                        />
                      </base-input>
                    </div>
                  </div>

                  <!-- Central de emissão -->
                  <div class="col-md-4 row pl-4 pr-md-4 pr-0 pl-md-0 mt-2 mb-2">
                    <label class="col-12 pb-0 pl-0 mb-1 col-form-label form-control-label">
                      Central de emissão
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="w-100 pl-0 pr-3 ml-0 mr-0">
                      <base-input input-classes="form-control-sm">
                        <puzl-select
                          v-model="exit_launch.issue_company_plant_id"
                          :items="$_company_plants"
                          :clearable="false"
                          @change="getCompanyPlantIssuers"
                          :disabled="!$_company_plants.length"
                        />
                      </base-input>
                    </div>
                  </div>

                  <!-- CNPJ Emissor -->
                  <div class="col-md-4 row pl-4 pr-md-4 pr-0 pl-md-0 mt-2 mb-2">
                    <label class="col-12 pl-0 pb-0 mb-1 col-form-label form-control-label">
                      CNPJ Emissor
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="w-100 pr-3 ml-0 mr-0">
                      <base-input input-classes="form-control-sm">
                        <puzl-select
                          v-model="exit_launch.company_plant_issuer_id"
                          :items="$_company_plant_issuers"
                          :clearable="false"
                          label="alias_name"
                          :disabled="loadingCompanyPlantIssuer"
                          :loading="loadingCompanyPlantIssuer"
                        />
                      </base-input>
                    </div>
                  </div>

                  <!-- Data de entrega  -->
                  <div class="col-md-4 row pl-4 pr-md-4 pr-0 pl-md-0 mt-2 mb-2">
                    <label class="col-12 pl-0 pb-0 mb-1 col-form-label form-control-label">
                      Data de entrega
                      <span class="text-danger">&nbsp;*</span>
                    </label>

                    <div class="w-100 pr-3 ml-0 mr-0">
                      <base-input input-group-classes="input-group-sm">
                        <input-date-time-picker
                          :disableMaxDate="max_date"
                          :mode="'dateTime'"
                          :size="'lg'"
                          v-model="exit_launch.delivery_date"
                        />
                      </base-input>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Dados -->
              <div class="mt-3">
                <div class="border-header-danger-bottom">
                  <h5 class="h4 p-1 mb-0">Dados</h5>
                </div>
                <div class="w-100 row m-0 justify-content-center p-0">
                  <!-- Modelo do documento -->
                  <div class="col-md-4 row pl-4 pr-md-4 pr-0 pl-md-0 mt-2 mb-2">
                    <label class="col-12 pb-0 pl-0 mb-1 col-form-label form-control-label">
                      Documento
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="w-100 pl-0 pr-3 ml-0 mr-0">
                      <base-input input-group-classes="input-group-sm">
                        <puzl-select :clearable="false" v-model="exit_launch.document_template_id" :items="document_templates" />
                      </base-input>
                    </div>
                  </div>

                  <!-- Número do pedido -->
                  <div class="col-md-4 row pl-4 pr-md-4 pr-0 pl-md-0 mt-2 mb-2">
                    <label class="col-12 pl-0 pb-0 mb-1 col-form-label form-control-label"> Número do pedido </label>
                    <div class="w-100 pr-3 ml-0 mr-0">
                      <base-input input-group-classes="input-group-sm">
                        <input class="form-control form-control-sm" disabled v-model="exit_launch.document_number" />
                      </base-input>
                    </div>
                  </div>

                  <!-- CFOP  -->
                  <div class="col-md-4 row pl-4 pr-md-4 pr-0 pl-md-0 mt-2 mb-2">
                    <label class="col-12 pl-0 pb-0 mb-1 col-form-label form-control-label">
                      CFOP
                      <span class="text-danger">*</span>
                    </label>
                    <div class="w-100 pr-3 ml-0 mr-0">
                      <validation-provider rules="required">
                        <base-input input-classes="form-control-sm">
                          <puzl-select
                            placeholder="CFOP"
                            v-model="exit_launch.cfop"
                            :items="$_exit_cfops"
                            :clearable="false"
                            :labelMask="true"
                            label="$id$ - $text$"
                            :loading="loadingCfop"
                            disabled
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>

                  <!-- Data de Saída e Data de emissão  -->
                  <div class="col-md-4 row pl-4 pr-md-4 pr-0 pl-md-0 mt-2 mb-2">
                    <div class="col-md px-0">
                      <label class="col-12 pl-0 pb-0 mb-1 col-form-label form-control-label">
                        Data de emissão
                        <span class="text-danger">&nbsp;*</span>
                      </label>
                      <div class="w-100 pr-3 ml-0 mr-0">
                        <base-input input-group-classes="input-group-sm">
                          <input-date-time-picker :disableMaxDate="max_date" :mode="'date'" v-model="exit_launch.issue_date" />
                        </base-input>
                      </div>
                    </div>
                    <div class="col-md px-0">
                      <label class="col-12 pb-0 pl-0 mb-1 col-form-label form-control-label"> Data de saída </label>
                      <div class="w-100 pl-0 pr-3 ml-0 mr-0">
                        <base-input input-group-classes="input-group-sm">
                          <input-date-time-picker :disableMaxDate="max_date" :mode="'date'" v-model="exit_launch.exit_date" />
                        </base-input>
                      </div>
                    </div>
                  </div>

                  <!-- Natureza de operação(CFOP) -->
                  <div class="col-md-4 row pl-4 pr-md-4 pr-0 pl-md-0 mt-2 mb-2">
                    <label class="col-12 pl-0 pb-0 mb-1 col-form-label form-control-label"> Natureza de operação </label>
                    <div class="w-100 pr-3 ml-0 mr-0">
                      <base-input input-group-classes="input-group-sm">
                        <input maxlength="80" disabled v-model="exit_launch.nature_operation" class="form-control form-control-sm" />
                      </base-input>
                    </div>
                  </div>

                  <!-- Chave de acesso -->
                  <div class="col-md-4 row pl-4 pr-md-4 pr-0 pl-md-0 mt-2 mb-2">
                    <label class="col-12 pl-0 pb-0 mb-1 col-form-label form-control-label"> Chave de acesso </label>
                    <div class="w-100 pr-3 ml-0 mr-0">
                      <base-input input-group-classes="input-group-sm">
                        <input disabled maxlength="80" v-model="exit_launch.access_key" class="form-control form-control-sm" />
                      </base-input>
                    </div>
                  </div>
                  <div class="col-md-4 row pl-4 pr-md-4 pr-0 pl-md-0 mt-2 mb-2">
                    <label class="col-12 pl-0 pb-0 mb-1 col-form-label form-control-label"> Observações </label>
                    <div class="w-100 pr-3 ml-0 mr-0">
                      <base-input input-group-classes="input-group-sm">
                        <textarea v-model="exit_launch.observation" rows="3" type="text" class="form-control form-control-sm" />
                      </base-input>
                    </div>
                  </div>
                  <div class="col-md-4 row pl-4 pr-md-4 pr-0 pl-md-0 mt-2 mb-2">
                    <label class="col-12 pl-0 pb-0 mb-1 col-form-label form-control-label"> Chave/Nfe referenciada </label>
                    <div class="w-100 pr-3 ml-0 mr-0 mt-n4">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          v-model="exit_launch.nfe_reference_key"
                          type="text"
                          @input="restrictToNumbers"
                          class="form-control form-control-sm"
                          maxlength="44"
                        />
                      </base-input>
                    </div>
                  </div>
                  <div class="col-md-4 row pl-4 pr-md-4 pr-0pl-md-0 mt-2 mb-2" />
                  <div class="col-md-4 row pl-4 pr-md-4 pr-0pl-md-0 mt-2 mb-2" />
                </div>
                <!-- Estatísticas -->
                <div class="border-left border-danger border-3 mt-3 pl-2 ml-3">
                  <div class="col-md-4 pl-1">
                    <label class="col-12 pl-0 pb-0 mb-1 col-form-label form-control-label font-weight-normal"> Valor total </label>
                    <base-input input-group-classes="input-group-sm">
                      <input disabled v-model="human_total_value" class="form-control form-control-sm" />
                    </base-input>
                  </div>
                  <div class="col-md-4 pl-1 pb-2">
                    <label class="col-12 pl-0 pb-0 mb-1 col-form-label form-control-label"> Valor total da nota </label>
                    <base-input input-group-classes="input-group-sm mb-2">
                      <input disabled v-model="human_final_value" class="form-control form-control-sm" />
                    </base-input>
                  </div>
                </div>
              </div>
            </div>

            <!-- Items -->
            <div v-show="tab === 1">
              <div class="row align-items-center d-flex">
                <div class="col-md-3 mt-2">
                  <base-button @click.prevent="handleModalCreateExitLaunchItem()" type="success" size="sm" block>
                    <i class="fas fa-plus" /> INCLUIR
                  </base-button>
                </div>
                <div v-show="items.length" class="col-md-3 mt-2">
                  <base-button @click.prevent="setSelectedItems" type="primary" size="sm" block outline :class="selected_items && 'active'">
                    <i class="fa-solid fa-check-double" /> SELECIONAR
                  </base-button>
                </div>
                <div v-show="selected_items" class="col-md-3 mt-2">
                  <base-button @click.prevent="addAllToSelectedItems" type="success" size="sm" block>
                    <i class="fa-solid fa-check-double" /> SELECIONAR TODOS
                  </base-button>
                </div>
                <div v-show="selected_items" class="col-md-3 mt-2">
                  <base-button @click.prevent="removeAllFromSelectedItems" type="light" size="sm" block>
                    <i class="fa-solid fa-check-double" /> DESMARCAR TODOS
                  </base-button>
                </div>
              </div>
              <div class="mt-5">
                <PuzlEmptyData v-show="!items.length" />
                <div class="row card-wrapper" v-if="items.length">
                  <div class="col-lg-4" v-for="(item, index) in items" :key="index">
                    <card>
                      <div class="row align-items-center">
                        <div class="col-9 justify-content-center pr-0">
                          <h3 class="mb-0">
                            {{ item.product_name }}
                          </h3>
                          <div v-if="item.product_service_hub_type_id === 2">
                            <h3 class="mb-0">
                              {{ item.supplier_name }}
                            </h3>
                            <h3 class="mb-1">({{ item.origin_name }})</h3>
                          </div>
                        </div>
                        <div v-if="!selected_items" class="col-3 text-right pl-0">
                          <base-dropdown menuOnRight>
                            <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                              <i class="fas fa-cog"></i>
                            </base-button>
                            <a @click.prevent="handleModalEditExitLaunchItem(item)" class="dropdown-item">
                              <i class="fas fa-edit text-warning main-icon-menu"></i>
                              Editar
                            </a>
                            <a @click.prevent="handleModalItemTaxRules(item)" class="dropdown-item">
                              <i class="fas fa-edit text-warning main-icon-menu"></i>
                              Regras Fiscais
                            </a>
                            <a @click.prevent="deleteItem(index)" class="dropdown-item">
                              <i class="fas fa-times text-danger main-icon-menu"></i>
                              Excluir
                            </a>
                          </base-dropdown>
                        </div>
                        <div v-else class="col-3 text-right pl-0">
                          <base-button
                            style="padding: 0.32rem 0.5rem !important"
                            v-if="selected_items.includes(item.product_service_hub_id)"
                            @click.prevent="removeFromSelectedItems(item.product_service_hub_id)"
                            class="float-right"
                            type="success"
                          >
                            <i class="fas fa-check text-white" />
                          </base-button>
                          <base-button
                            style="padding: 0.32rem 0.5rem !important; background-color: white"
                            v-else
                            @click.prevent="addToSelectedItems(item.product_service_hub_id)"
                            class="float-right"
                          >
                            <i class="fas fa-check text-white" />
                          </base-button>
                        </div>
                        <div class="col-12 mt-2">
                          <h5 v-if="item.ncm" class="h3 mb-0 font-weight-normal">NCM : {{ item.ncm }}</h5>
                          <h5 class="h3 mb-0 font-weight-normal">{{ item.quantity }} {{ item.purchase_unit }}</h5>
                          <h5 class="h3 mb-0 font-weight-normal">{{ item.price | currency }} / {{ item.purchase_unit }}</h5>
                          <h5 class="h3 mb-0 numeric">{{ item.total_value | currency }}</h5>
                        </div>
                      </div>
                      <div class="w-100 mt-2">
                        <base-input input-classes="form-control-sm">
                          <puzl-select
                            @input="applyToAllItems($event, 'chart_account_id')"
                            placeholder="Plano de Contas"
                            v-model="item.chart_account_id"
                            :items="$_chart_accounts"
                            :clearable="false"
                            :labelMask="true"
                            label="$code$ $name$"
                            :selectable="(i) => i.extra_field.length"
                            extraField="parents"
                            :loading="loadingChartAccount"
                            :disabled="loadingChartAccount"
                          />
                        </base-input>
                      </div>
                      <div class="w-100 mt-2">
                        <validation-provider rules="required">
                          <base-input input-classes="form-control-sm">
                            <puzl-select
                              placeholder="CFOP"
                              v-model="item.cfop"
                              :items="$_exit_cfops"
                              :clearable="false"
                              :labelMask="true"
                              label="$id$ - $text$"
                              :loading="loadingCfop"
                              :disabled="loadingCfop"
                            />
                          </base-input>
                        </validation-provider>
                      </div>
                    </card>
                  </div>
                </div>
              </div>
            </div>
            <!-- Frete -->
            <div v-show="tab === 2">
              <div class="border-header-primary-bottom mt-3">
                <h5 class="h4 p-1 mb-0">Transportadora</h5>
              </div>
              <div v-if="!exit_launch.freight_id" class="row align-items-center d-flex">
                <div class="col-md-3 mt-2"></div>
              </div>
              <!-- Frete -->
              <div class="row">
                <div class="col-md-6 pl-3 mt-2 mb-2">
                  <label class="col-12 pb-0 pl-0 mb-1 col-form-label form-control-label"> Frete </label>
                  <base-input input-group-classes="input-group-sm">
                    <puzl-select
                      class="col-9 p-0"
                      label="$code$ - $name$"
                      :labelMask="true"
                      @change="getFreight"
                      v-model="freight.freight_modality_id"
                      :items="freight_modalities"
                      :clearable="false"
                    />
                  </base-input>
                </div>

                <!-- Valor do frete -->
                <div class="col-md-6 pl-3 pl-md-0 mt-2 mb-2 pr-1 float-right">
                  <label class="col-12 pb-0 pl-0 mb-1 col-form-label form-control-label">
                    Valor do frete
                  </label>
                  <div class="col-md-6 custom-entry-launch-prepend-input pl-0 pr-4">
                    <base-input input-classes="form-control-sm">
                      <input
                        v-money="money"
                        v-model.lazy="freight.total_value"
                        maxlength="10"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                      />
                      <template slot="prepend">
                        <small> R$ </small>
                      </template>
                    </base-input>
                  </div>
                </div>
              </div>

              <div class="w-100 row m-0 p-0">
                <!-- Transportadora -->                 
                <div class="col-md-6 pl-0 mt-2 mb-2">
                  <div v-if="freight.freight_modality_id">
                    <label class="col-12 pb-0 pl-0 mb-1 col-form-label form-control-label">
                      Transportadora
                      <base-button @click.prevent="getFreight" size="sm" type="primary" class="text-uppercase ml-2">
                        <i class="fas fa-search" />
                      </base-button>
                      <base-button v-if="freight_entity.entity_name" @click.prevent="clearFreight" size="sm" type="danger" class="text-uppercase ml-2">
                        <i class="fas fa-trash" />
                      </base-button>
                    </label>
                    <div v-if="freight_entity.entity_name" class="border-left border-indigo border-3 pl-1">
                      <h4 class="mb-0 col-12 pl-2">{{ freight_entity.entity_name }}</h4>
                      <h4 class="mb-0 col-12 font-weight-normal pl-2">{{ freight_entity.document }}</h4>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 p-0 m-0 row">
                  <!-- Quantidade  -->
                  <div class="col-md-4 pr-0 row pr-md-4 mt-2 mb-2">
                    <label class="col-12 pb-0 pl-0 mb-1 col-form-label form-control-label"> Quantidade </label>
                    <div class="w-100 pr-0 pr-md-3">
                      <base-input input-group-classes="input-group-sm">
                        <input-limit
                          :start_value="freight.quantity"
                          :options="{
                            min: 0,
                            max: 99999,
                          }"
                          max_length="15"
                          input_group_classes="input-group-sm pb-0"
                          v-model="freight.quantity"
                          @value="freight.quantity = $event"
                        />
                      </base-input>
                    </div>
                  </div>

                  <!-- Peso bruto  -->
                  <div v-if="strToNum(freight.quantity) > 0" class="col-md-4 pr-0 row pr-md-4 mt-2 mb-2">
                    <label class="col-12 pb-0 pl-0 mb-1 col-form-label form-control-label"> Peso bruto </label>
                    <div class="w-100 pr-0 pr-md-3">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          v-money="money_weight"
                          v-model.lazy="freight.gross_weight"
                          maxlength="10"
                          inputmode="numeric"
                          class="form-control form-control-sm"
                        />
                      </base-input>
                    </div>
                  </div>

                  <!-- Peso líquido -->
                  <div v-if="strToNum(freight.quantity) > 0" class="col-md-4 pr-0 row pr-md-4 mt-2 mb-2">
                    <label class="col-12 pl-0 pb-0 mb-1 col-form-label form-control-label"> Peso líquido </label>
                    <div class="w-100 pr-0 pr-md-3">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          v-money="money_weight"
                          v-model.lazy="freight.net_weight"
                          maxlength="10"
                          inputmode="numeric"
                          class="form-control form-control-sm"
                        />
                      </base-input>
                    </div>
                  </div>

                  <!-- Placa  -->
                  <div class="col-md-4 pr-0 row pr-md-4 mt-2 mb-2">
                    <label class="col-12 pb-0 pl-0 mb-1 col-form-label form-control-label"> Placa </label>
                    <div class="w-100 pr-0 pr-md-3">
                      <base-input input-group-classes="input-group-sm">
                        <input maxlength="20" v-model="freight.plate" class="form-control form-control-sm" />
                      </base-input>
                    </div>
                  </div>

                  <!-- Espécie  -->
                  <div v-if="strToNum(freight.quantity) > 0" class="col-md-4 pr-0 row pr-md-4 mt-2 mb-2">
                    <label class="col-12 pb-0 pl-0 mb-1 col-form-label form-control-label"> Espécie </label>
                    <div class="w-100 pr-0 pr-md-3">
                      <base-input input-group-classes="input-group-sm">
                        <input maxlength="50" v-model="freight.species" class="form-control form-control-sm" />
                      </base-input>
                    </div>
                  </div>

                  <!-- Marca -->
                  <div v-if="strToNum(freight.quantity) > 0" class="col-md-4 pr-0 row pr-md-4 mt-2 mb-2">
                    <label class="col-12 pl-0 pb-0 mb-1 col-form-label form-control-label"> Marca </label>
                    <div class="w-100 pr-0 pr-md-3">
                      <base-input input-group-classes="input-group-sm">
                        <input maxlength="50" v-model="freight.model" class="form-control form-control-sm" />
                      </base-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="exit_launch.product_service_hub_type_id && exit_launch.company_plant_buyer_id">
            <base-button
              type="success"
              :loading="loadingStore"
              style="bottom: 130px !important; right: 3.4rem !important; position: fixed; z-index: 9999"
              @click.prevent="goToNextTab"
              v-if="!getNextTabButtonStatus()"
            >
              <span v-show="!loadingStore" class="btn-label"><i class="fa-solid fa-circle-right"></i></span>
              <span style="font-size: 0.75rem">PRÓXIMO</span>
            </base-button>
            <base-button
              v-else
              type="success"
              :loading="loadingStore"
              style="bottom: 130px !important; right: 50px !important; position: fixed; z-index: 9999"
              @click.prevent="store"
            >
              <span v-show="!loadingStore" class="btn-label"><i class="fas fa-save"></i></span>
              <span style="font-size: 0.75rem">SALVAR</span>
            </base-button>
          </div>
          <div v-if="exit_launch.product_service_hub_type_id && exit_launch.company_plant_issuer_id">
            <base-button
              type="success"
              :loading="loadingStore"
              style="bottom: 130px !important; right: 3.4rem !important; position: fixed; z-index: 9999"
              @click.prevent="goToNextTab"
              v-if="!getNextTabButtonStatus()"
            >
              <span v-show="!loadingStore" class="btn-label"><i class="fa-solid fa-circle-right"></i></span>
              <span style="font-size: 0.75rem">PRÓXIMO</span>
            </base-button>
            <base-button
              v-else
              :disabled="items.find((item) => !item.cfop || !item.chart_account_id)"
              type="success"
              :loading="loadingStore"
              style="bottom: 130px !important; right: 50px !important; position: fixed; z-index: 9999"
              @click.prevent="store"
            >
              <span v-show="!loadingStore" class="btn-label"><i class="fas fa-save"></i></span>
              <span style="font-size: 0.75rem">SALVAR</span>
            </base-button>
          </div>
          <div v-if="selected_items">
            <base-button
              type="primary"
              :disabled="!selected_items.length"
              style="bottom: 186px !important; right: 2.95rem !important; position: fixed; z-index: 9999"
              @click.prevent="handleModalSetChartAccount"
            >
              <i class="fas fa-hand-holding-usd text-white"></i><span style="font-size: 0.75rem">PLANO DE CONTAS</span>
            </base-button>
          </div>
        </card>
      </div>
    </div>
    <ModalCreateExitLaunchItem @added="addedItem" ref="modalCreateExitLaunchItem" />
    <ModalEditExitLaunchItem @updated="updatedItem" ref="modalEditExitLaunchItem" />
    <ModalSetChartAccount @store="applyToSelectedItems($event, 'chart_account_id')" ref="modalSetChartAccount" />
    <ModalSearchEntity @selected="selectedEntity" ref="modalSearchEntity" />
    <ModalItemTaxRules ref="modalItemTaxRules" />
  </div>
</template>

<script>
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import { mapGetters } from "vuex";
import BackButton from "@/components/Utils/BackButton";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import moment from "moment";
import { formatErrorValidation } from "@/plugins";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import ModalCreateExitLaunchItem from "../../EntryLaunch/Item/_ModalCreate";
import ModalEditExitLaunchItem from "../../EntryLaunch/Item/_ModalEdit";
import SkeletonPuzlFullWidth from "@/components/SkeletonPuzlFullWidth";
import { VMoney } from "v-money";
import exitLaunchMixin from "../Mixins/exit_launch_mixin";
import PuzlSelect from "@/components/PuzlSelect";
import InputLimit from "@/components/Utils/InputLimit";
import ModalSetChartAccount from "../../EntryLaunch/Shared/_ModalSetChartAccount";
import ModalSearchEntity from "../../Entity/Shared/_ModalSearchEntity";
import ModalItemTaxRules from "./_ModalItemTaxRules";
import { strToNum } from "../../../../../helpers";

export default {
  name: "CreateExitLaunch",
  mixins: [exitLaunchMixin],
  components: {
    BackButton,
    PuzlBreadcrumb,
    InputDateTimePicker,
    ModalCreateExitLaunchItem,
    PuzlEmptyData,
    ModalEditExitLaunchItem,
    SkeletonPuzlFullWidth,
    PuzlSelect,
    InputLimit,
    ModalSetChartAccount,
    ModalSearchEntity,
    ModalItemTaxRules,
  },
  data() {
    return {
      strToNum: strToNum,
      tab: 0,
      loadingCfop: false,
      exit_launch: {
        status: 1,
        product_service_hub_type_id: 3,
        exit_company_plant_id: null,
        issue_company_plant_id: null,
        delivery_date: new Date(),
        issue_date: new Date(),
        document_template_id: 5,
        observation: null,
        final_value: 0,
        total_value: 0,
        nature_operation: null,
        exit_date: null,
        access_key: null,
        operation_source_id: null,
        company_plant_issuer_id: null,
        entity_id: null,
      },
      loadingStore: false,
      loadingEntity: true,
      loadingCompanyPlantIssuer: false,
      items: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      money_weight: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 4,
        masked: false,
      },
    };
  },
  directives: { money: VMoney },
  computed: {
    ...mapGetters({
      $_entity: "entity/show",
      $_exit_cfops: "nfe/getListExitCfops",
    }),
    human_final_value() {
      return `${this.exit_launch.final_value.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}`;
    },
    human_total_value() {
      return `${this.exit_launch.total_value.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}`;
    },
  },
  watch: {
    items() {
      this.exit_launch.total_value = this.items.reduce((partialSum, a) => partialSum + a.total_value, 0);
      this.exit_launch.final_value = this.exit_launch.total_value;
    },
  },
  methods: {
    handleModalCreateExitLaunchItem() {
      this.$refs.modalCreateExitLaunchItem.openModal(this.exit_launch.product_service_hub_type_id, this.exit_launch.entity_id, false, true);
    },
    handleModalEditExitLaunchItem(item) {
      this.$refs.modalEditExitLaunchItem.openModal(item, undefined, true);
    },
    handleModalItemTaxRules(item) {
      this.$refs.modalItemTaxRules.openModal(item);
    },
    addedItem(item) {
      if (this.checkItemAlreadyExists(item)) {
        return this.$notify({ type: "danger", message: "Item já cadastrado!" });
      }
      /* Barra tipos diferentes */
      if (
        this.items &&
        this.items[0] &&
        this.items[0].product_service_hub.product_service_hub_type_id !== item.product_service_hub.product_service_hub_type_id
      ) {
        return this.$notify({ type: "danger", message: "Operação não permitida!" });
      }
      item.cfop = this.exit_launch.cfop;
      this.items.push(this.normalizeItem(item));
    },

    updatedItem(item) {
      this.items.splice(
        this.items.findIndex((payload) => payload.product_service_hub_id === item.product_service_hub_id),
        1,
        item
      );
    },

    checkItemAlreadyExists(item) {
      return this.items.findIndex((payload) => payload.product_service_hub_id === item.product_service_hub_id) >= 0;
    },

    deleteItem(index) {
      this.items.splice(index, 1);
    },

    finalFormat() {
      let payload = {
        exit_launch: this.$helper.cloneObject(this.exit_launch),
        items: this.$helper.cloneObject(this.items),
      };
      if (this.freight && this.freight.freight_modality_id) {
        payload.freight = this.$helper.cloneObject(this.freight);
        payload.freight.total_value = this.normalizeValue(payload.freight.total_value);
        payload.freight.gross_weight = strToNum(payload.freight.gross_weight).toFixed(4);
        payload.freight.net_weight = strToNum(payload.freight.net_weight).toFixed(4);
      }
      payload.items.filter(function (obj) {
        obj.price = this.normalizeValue(obj.price);
        obj.quantity = obj.quantity.replace(",", ".");
        obj.discount = obj.discount.replace(",", ".");
        obj.icms_bc = this.normalizeValue(obj.icms_bc);
        obj.icms_aliq = this.normalizeValue(obj.icms_aliq);
        obj.pis_bc = this.normalizeValue(obj.pis_bc);
        obj.pis_aliq = this.normalizeValue(obj.pis_aliq);
        obj.cofins_bc = this.normalizeValue(obj.cofins_bc);
        obj.cofins_aliq = this.normalizeValue(obj.cofins_aliq);
        obj.ipi_bc = this.normalizeValue(obj.ipi_bc);
        obj.ipi_aliq = this.normalizeValue(obj.ipi_aliq);
        obj.other_expenses = strToNum(obj.other_expenses).toFixed(2);
      }, this);
      payload.exit_launch.delivery_date = payload.exit_launch.delivery_date
        ? moment(this.exit_launch.delivery_date).format("YYYY-MM-DD HH:mm:ss")
        : null;
      payload.exit_launch.issue_date = payload.exit_launch.issue_date ? moment(this.exit_launch.issue_date).format("YYYY-MM-DD") : null;
      payload.exit_launch.exit_date = payload.exit_launch.exit_date ? moment(this.exit_launch.exit_date).format("YYYY-MM-DD") : null;
      return payload;
    },
    async store() {
      if (this.exit_launch.nfe_reference_key && this.exit_launch.nfe_reference_key.length < 44) {
        this.$notify({
          type: "warning",
          message: "Chave/Nfe referenciada deve ter 44 dígitos",
        });
        return;
      }

      const loading = this.$loading.show();
      this.loadingStore = true;
      let payload = await this.finalFormat();
      this.$store
        .dispatch("exitLaunch/add", payload)
        .then((response) => {
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.$router.push("/configuration/exit-launch");
        })
        .catch((error) => {
          if (error.status) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response && error.response.status && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
        })
        .finally(() => {
          this.loadingStore = false;
          loading.hide();
        });
    },

    /**
     * Preenche segunda central automaticamente
     *
     * @param {integer} value
     */
    fillCompanyPlant(value) {
      if (!this.exit_launch.issue_company_plant_id) {
        this.exit_launch.issue_company_plant_id = value;
        this.getCompanyPlantIssuers();
      }
    },

    handleModalSetChartAccount() {
      this.$refs.modalSetChartAccount.openModal();
    },

    /**
     * Pemite digitar apenas números
     *
     * @param {object} event
     */
    restrictToNumbers(event) {
      const newValue = event.target.value.replace(/[^0-9-]/g, "");
      this.exit_launch.nfe_reference_key = newValue;
    },
  },
  mounted() {
    this.$store.dispatch("entity/show", this.$route.params.entity_uuid).then(() => {
      this.exit_launch.entity_id = this.$_entity.id;
      this.loadingEntity = false;
    });
    this.loadingCfop = true;
    this.$store.dispatch("nfe/getExitCfops").then(() => {
      this.loadingCfop = false;
    });
    this.getOperationSources();
    this.getChartAccounts();
  },
};
</script>
