import * as types from './mutation_types'
import {cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'
import { createAxios } from "@/plugins/axios";
const endPoint = '/configuration/service/'

export default {
  namespaced: true,
  state: {
    items: [],
    activeItems: [],
  },
  getters:{
    services: state => state.items,
    activeItems (state) {
      return state.items.filter(service => service.status === true)
    },
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
  },
  actions: {
    fetchServices({commit}, params) {
      return createAxios().get(endPoint, {params: params})
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchItemsPaginate({commit, state}, params) {
      return createAxios().get(setUrlWithNextPage(endPoint, params),
        {params: params})
        .then(({data}) =>{
          cursorPaginate(state, {data : data.data, next_page: params.next_page})
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
