<template>
  <div>
    <modal size="lg" v-if="show" @close="close" :show.sync="show">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">Visualização de arquivos</h5>
      </template>
      <div v-if="selected.ext != 'pdf'" class="text-center" style="overflow: auto;max-height: 90vh">
        <p>
          <img
            style="max-height: 80vh !important;max-width: 80vw !important;"
               :src="selected.url">
        </p>
      </div>
      <div v-else class="text-center" style="overflow: auto;max-height: 80vh">
        <pdf :src="selected.url" v-for="i in 10" :key="i" :id="i" :page="i"
             style="width:100%;margin:20px auto;"
             :annotation="true"
             :resize="true"
            >
        </pdf>
      </div>
    </modal>
  </div>
</template>

<script>
import pdf from 'pdfvuer';

export default {
  components: {
    pdf
  },
  props: ['selected', 'show'],
  data() {
    return {
      page: 1,
      numPages: 0,
      pdfdata: undefined,
      errors: [],
      scale: 'page-width'
    }
  },
  methods: {
    close() {
      this.$parent.closeModalImgView()
    },
    openLinkInNewTab() {
      let link = document.createElement('a')
      link.href = this.selected.url
      link.setAttribute("download", this.selected.file_name);
      window.open(link, '_blank')
    },
    download() {
    }
  }
}
</script>
<style src="pdfvuer/dist/pdfvuer.css"></style>
<style lang="css" scoped>
#buttons {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
/* Page content */
.content {
  padding: 16px;
}
</style>
