<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
            <multi-filter @fetch="getMixPuzlAdmixtureWithPercentage" :filter="filter" />
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">

            <div v-show="isLoading">
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 m-0 p-0 mb-1 col-form-label form-control-label">
                  <SkeletonPuzl type="button"></SkeletonPuzl>
                </label>
                <div class="col-md-3">
                  <SkeletonPuzl type="button"></SkeletonPuzl>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 m-0 p-0 mb-1 col-form-label form-control-label">
                  <SkeletonPuzl type="button"></SkeletonPuzl>
                </label>
                <div class="col-md-3">
                  <SkeletonPuzl type="button"></SkeletonPuzl>
                </div>
              </div>
            </div>

            <div v-for="(item, index) in listMixPercentage" :key="index">
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-9 m-0 p-0 mb-1 mt-3 col-form-label form-control-label">
                  {{item.cmc_group.group_name}}
                </label>
                <div class="col-md-3">
                  <base-input input-classes="form-control-sm">
                    <input
                      v-if="item.percentage" v-model="item.percentage.percentage"
                      type="text"
                      class="form-control form-control-md"
                      v-mask="['##']"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0">
                        %
                      </small>
                    </template>
                  </base-input>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')">
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loading">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>
<script>
import SkeletonPuzl from '@/components/SkeletonPuzl';
import MultiFilter from "@/components/Utils/MultiFilterV2";
export default {
  components: {
    SkeletonPuzl,
    MultiFilter,
  },
  name: "MixPuzlAdmixturePercentage",
  data() {
    return {
      modal: {
        title: '',
        create: false,
      },
      filter: {},
      validated: false,
      listMixPercentage: {},
      percentage:{},
      isLoading: false,
      loading: false,
    };
  },
  methods: {
    closeModal(name) {
      this.modal[name] = false
    },
    openModal(name) {
      this.modal[name] = true
    },
    handleCreate() {
      this.modal.title = 'Acréscimo sobre o custo de matéria prima.';
      this.openModal('create');
    },
    store() {
      const percentages = {};
      const lists = this.listMixPercentage;
      lists.forEach(function (item, index){
        percentages[item.id] = item.percentage ? item.percentage.percentage : 0
      });
      this.$Progress.start();
      this.loading = true;

      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$axios.post("/commercial/concrete-price/mix-puzl-admixture-with-percentage", {percentages})
        .then(({data}) => {
          this.$notify({type: data.error_type, message: data.message});
          this.closeModal('create');
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.$notify({type: error.response.data.error_type, message: error.response.data.message});
          this.loading = false;
        });
    },
    getMixPuzlAdmixtureWithPercentage(filter = {}) {
      this.filter = filter;
      this.$Progress.start();
      this.isLoading = true;
      this.$axios.get("/commercial/concrete-price/mix-puzl-admixture-with-percentage", {
          params: {
            filter: this.filter
          }
        })
        .then(({data}) => {
          this.listMixPercentage = data.data;
          this.$Progress.finish();
          this.isLoading = false;
        })
        .catch((error) => {

            this.isLoading = false;
        });
    },
  },
  mounted() {
    this.$refs.formValidator.validate();
    this.getMixPuzlAdmixtureWithPercentage();
    EventBus.$on("handleCreateMixPuzlAdmixturePercentage", () => {
      this.handleCreate();
    });
  }
};
</script>
<style></style>
