<template>
<div>
    <base-header class="bg-gray-content">
        <div class="row align-items-center py-4">
            <div class="col-lg-12 col-12">
                <PuzlBreadcrumb/>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-2 mb-2">
                <base-button
                    @click.prevent="handleCreateModal"
                    class="pb-3 pt-3 text-uppercase"
                    block size="lg"
                    type="success"
                >
                    <i class="fas fa-plus"></i> novo
                </base-button>
            </div>
        </div>
    </base-header>
    <ListBankBalance />
    <CreateBankBalance ref="modalCreate" />
</div>
</template>

<script>
import {mapGetters} from "vuex";
import ListBankBalance from "./Shared/_List";
import CreateBankBalance from "./Shared/_ModalCreate";
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
export default {
    name: "Index",
    components: {
        ListBankBalance,
        CreateBankBalance,
        PuzlBreadcrumb,
    },
    methods: {
        handleCreateModal() {
            this.$refs.modalCreate.handleCreateModal();
        },
    },
}
</script>

<style></style>
