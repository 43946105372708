import * as types from './mutation_types'
import { createAxios } from "@/plugins/axios";
const endPoint = '/technology/adjustment/application-level-guideline'
export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters:{
    fetch: state => state.items,
  },
  mutations: {
		[types.SET] (state, payload){
			state.items = payload
		},
		[types.PUSH] (state, payload){
			state.items.push(payload)
		},
  },
  actions: {
    fetchItems({commit}, params) {
      return createAxios().get(endPoint, {params: params})
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        });
    },
    add({commit}, payload) {
      return createAxios().post(endPoint, payload)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      });
    },
  }
}
