import * as types from "./mutation_types";
import { destroy, cursorPaginate, setUrlWithNextPage } from "@/store/baseStore";
import { createAxios } from "@/plugins/axios";;
const endPoint = "/configuration/exit-launch/";
export default {
  namespaced: true,
  state: {
    items: [],
    item: {},
  },
  getters: {
    fetch: (state) => state.items,
    show: (state) => state.item,
  },
  mutations: {
    [types.SHOW](state, payload) {
      state.item = payload;
    },
    [types.DESTROY](state, id) {
      destroy(state, id);
    },
  },
  actions: {
    fetch({ state }, params) {
      return createAxios()
        .get(setUrlWithNextPage(endPoint, params), { params: params })
        .then(({ data }) => {
          cursorPaginate(state, { data: data.data, next_page: params.next_page });
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    add({}, payload) {
      return createAxios()
        .post(endPoint, payload)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    show({ commit }, id) {
      return createAxios()
        .get(endPoint + id)
        .then(({ data }) => {
          commit(types.SHOW, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    update({}, payload) {
      return createAxios()
        .put(endPoint + payload.exit_launch.id, payload)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    destroy({ commit }, id) {
      return createAxios()
        .delete(endPoint + id)
        .then(({ data }) => {
          commit(types.DESTROY, id);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getItems({}, id) {
      return createAxios()
        .get(`${endPoint}items/${id}`)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getFreightByExitLaunch(_, id) {
      return createAxios()
        .get(`${endPoint}freight/${id}`)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
};
