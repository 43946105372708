<template>
    <div>
      <modal size="sm" :show.sync="modal.create">
        <template slot="header">
          <h5 class="modal-title">{{ modal.title }}</h5>
        </template>
        <div>
  <!--        <multi-filter sizeable :without_filter="true" @fetch="getMixConcretedPieceWithPercentage" :filter="filter" />-->
          <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
            <form  class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
              <div v-show="isLoading">
                <div class="form-group row m-0 p-0">
                  <label class="col-md-9 m-0 p-0 mb-1 col-form-label form-control-label">
                    <SkeletonPuzl type="button"></SkeletonPuzl>
                  </label>
                  <div class="col-md-3">
                    <SkeletonPuzl type="button"></SkeletonPuzl>
                  </div>
                </div>
                <div class="form-group row m-0 p-0">
                  <label class="col-md-9 m-0 p-0 mb-1 col-form-label form-control-label">
                    <SkeletonPuzl type="button"></SkeletonPuzl>
                  </label>
                  <div class="col-md-3">
                    <SkeletonPuzl type="button"></SkeletonPuzl>
                  </div>
                </div>
              </div>
              <div style="height: 40vh;overflow-x: auto">
                <div  v-for="(item, index) in list" :key="index">
                  <div class="form-group row m-0 p-0 mb-1">
                    <div class="col-8">
                      <div class="row">
                        <div class="col-2"/>
                        <div class="col-2">
                          <img style="height: 25px" src="https://img.icons8.com/external-dreamstale-lineal-dreamstale/32/null/external-bucket-industry-farming-dreamstale-lineal-dreamstale.png"/>
                        </div>
                        <div class="col-8">
                          <label class="text-center mt-n3 col-form-label form-control-label">
  
                            {{item.nomenclature}}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-3 text-center">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          v-model="item.percentage.percentage"
                          inputmode="numeric"
                          class="form-control form-control-sm"
                          input-group-classes="input-group-sm"
                          v-mask="['##']"
                        >
                        <template slot="append">
                          <small class="input-group-sm p-0 m-0">
                            %
                          </small>
                        </template>
                      </base-input>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <base-button type="secondary" @click="closeModal('create')">
                  Cancelar
                </base-button>
                <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loading">
                  Salvar
                </base-button>
              </div>
            </form>
          </validation-observer>
        </div>
      </modal>
    </div>
  </template>
  <script>
  import SkeletonPuzl from '@/components/SkeletonPuzl';
  import MultiFilter from "@/components/Utils/MultiFilterV2";
  export default {
    components: {
      SkeletonPuzl,
      MultiFilter,
    },
    name: "ModalCreateMixSlumpFlow",
    data() {
      return {
        modal: {
          title: '',
          create: false,
        },
        filter: {},
        validated: false,
        list: {},
        percentage:{},
        isLoading: false,
        loading: false,
      };
    },
    methods: {
      closeModal(name) {
        this.modal[name] = false
      },
      openModal(name) {
        this.modal[name] = true
      },
      handleCreate() {
        this.init();
        this.modal.title = 'Acréscimo sobre Slump final.';
      },
      store() {
        const percentages = {};
        const lists = this.list;
        lists.forEach(function (item, index){
          percentages[item.id] = item.percentage ? item.percentage.percentage : 0
        });
        this.$Progress.start();
        this.loading = true;
        this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
  
        this.$store.dispatch('mixSlumpFlow/updatePercentage', {percentages: percentages})
          .then((response) => {
            this.$notify({
              type: response.error_type,
              message: response.message
            })
            this.closeModal('create');
            this.loading = false;
            this.$Progress.finish();
          })
          .catch((error) => {
            this.$notify({type: error.response.data.error_type, message: error.response.data.message});
            this.loading = false;
          });
      },
      init(filter = {}) {
        this.filter = filter;
        this.$Progress.start();
        this.isLoading = true;
        let loader = this.$loading.show()
        this.$store.dispatch('mixSlumpFlow/fetchItems').then(response => {
          this.isLoading = false
          this.list = response.data
          loader.hide()
          this.openModal('create');
          this.$Progress.finish()
        })
      },
    },
    mounted() {
      this.$refs.formValidator.validate();
      EventBus.$on("handleCreateSlumpFinal", () => {
        this.handleCreate();
      });
    }
  };
  </script>
  <style></style>
  