<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{title}}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(storeCreate)" autocomplete="off">
            <div>


              <div class="form-group row m-0 p-0 mb-4">
                <div class="col-md-12">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <base-input input-group-classes="input-group-sm" group class="mb-2">
                        <input
                          type="text"
                          v-model="search.string"
                          class="form-control form-control-sm"
                        />
                        <div class="input-group-append" id="button-addon4">
                          <base-button
                            class="btn btn-outline-primary btn-sm"
                            type="primary"
                            @click="querySearchAsync"
                            :loading="search.loading"
                          >
                            Pesquisar
                          </base-button>
                        </div>
                      </base-input>

                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div v-if="entity">
                  <div class="form-group row m-0 p-0 mb-1 mt-2" v-for="(entity, indexEntity) in entities" :key="indexEntity">
                    <div class="col-md-10">
                      <h5 class="h5 ">{{ entity.entity_name }}</h5>
                      <h5 class="h5 small">{{ entity.document }}</h5>
                      <h5 class="h5 small">{{ entity.address.city }} / {{ entity.address.state }}</h5>
                      <div class="dropdown-divider p-0 m-0"></div>
                    </div>
                    <div class="col-md-2 pb-0 mb-1 col-form-label form-control-label">
                      <base-button
                        :loading="loadingRelate"
                        block
                        size="lg"
                        type="success"
                        class="p-1"
                        @click.prevent="connectEntityOrigin(entity.id)"
                      >
                        <i class="fas fa-check text-withe" v-show="!loadingRelate"></i>
                      </base-button>
                    </div>
                  </div>
              </div>

            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click.prevent="openModal(false)">
                Cancelar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>

  </div>
</template>
<script>
import {mapGetters} from 'vuex'

export default {
  components: {
  },
  name: "ModalRelateEntity",
  data() {
    return {
      title:'Associar CPF/CNPJ',
      modal: false,
      search: {
        string: '',
        types: 1,
        loading: false,
      },
      validated: false,
      entity: {
        cmc_supplier_origin_id: '',
        entity_id: '',
      },
      loading: false,
      loadingRelate: false,
    };
  },
  computed: {
    ...mapGetters({
      'entities': 'entity/fetch',
    }),
  },
  methods: {
    openModal (open, supplierOriginId) {
      this.entity = {
        cmc_supplier_origin_id: supplierOriginId,
        entity_id: '',
      }
      this.modal = open
    },
    querySearchAsync() {
      this.$Progress.start();
      this.search.loading = true;
      this.$store.dispatch('entity/fetchItemsSearch',this.search )
        .then(() => {
          this.$Progress.finish();
          this.search.loading = false;
        })
        .catch((error) => {
          this.$Progress.finish();
          this.search.loading = false;
        });
    },
    connectEntityOrigin(entity_id){
      this.loadingRelate = true
      this.entity.entity_id = entity_id;
      this.$store.dispatch('cmcSupplierOriginEntity/add', this.entity)
        .then(() => {
          this.loadingRelate = false;
          this.$Progress.finish();
          this.$store.dispatch('cmcSupplierOrigin/fetchItems', this.$route.params.supplier_uuid)
          this.modal = false
        })
        .catch(() => {
          this.loadingRelate = false;
          this.$Progress.finish();
        });
    },
  },
  created(){
  },
  mounted() {
    this.$refs.formValidator.validate();
  }
};

</script>
<style></style>
