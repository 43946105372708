<template>
  <div>
    <div class="container-fluid">
      <div class="col-md-12 p-1">
        <MultiFilter
          ref="multi-filter"
          @fetch="init"
          :filter="filter"
          :without_filter="true"
        >
          <div class="col-md-3 p-2">
            <PuzlSelect
              :items="years"
              :value="selectedYear"
              @input="handleYearSelect"
            />
          </div>
          <div class="col-md-3 p-2">
            <PuzlSelect
              v-model="filter.company_plant_ids"
              :items="$_plants"
              :loading="loadingCompanyPlants"
              :multiple="true"
              placeholder="Centrais"
              @input="handleFilterCompanyPlants"
            />
          </div>
        </MultiFilter>
      </div>
      <Table
        :loadingSkeleton="loadingSkeleton"
        :loadingHeaders="loadingHeaders"
        :selectedYear="selectedYear"
        :firstMonthsHeadersOperational="firstMonthsHeadersOperational"
        :firstMonthsHeadersInvoicing="firstMonthsHeadersInvoicing"
        :firstMonthsHeadersFiscal="firstMonthsHeadersFiscal"
        :lastMonthsHeadersOperational="lastMonthsHeadersOperational"
        :lastMonthsHeadersInvoicing="lastMonthsHeadersInvoicing"
        :lastMonthsHeadersFiscal="lastMonthsHeadersFiscal"
      />
    </div>
    <LoadingPagination :show="loading && !loadingSkeleton"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MultiFilter from "@/components/Utils/MultiFilterV2";
import PuzlSelect from "@/components/PuzlSelect";
import Table from "./_Table";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import { FiscalClosingTypeEnum } from "./Enums/FiscalClosingTypeEnum";
import { generateFirstMonths, generateLastMonths } from './monthData.js';

export default {
  name: "ListFiscalClosing",
  mixins: [cursorPaginate],
  components: {
    MultiFilter,
    PuzlSelect,
    Table,
    LoadingPagination,
  },
  data() {
    return {
      filter: {
        company_plant_ids: [],
      },
      years: [],
      selectedYear: new Date().getFullYear(), // Ano atual
      loadingSkeleton: false,
      loadingHeaders: false,
      loadingCompanyPlants: true,
      // Primeiros 6 meses de cada tipo.
      firstMonthsHeadersOperational: [],
      firstMonthsHeadersInvoicing: [],
      firstMonthsHeadersFiscal: [],
      // Últimos 6 meses de cada tipo.
      lastMonthsHeadersOperational: [],
      lastMonthsHeadersInvoicing: [],
      lastMonthsHeadersFiscal: [],
      FiscalClosingTypeEnum: FiscalClosingTypeEnum,
    };
  },
  computed: {
    ...mapGetters({
      $_plants: "plant/activeItems",
    }),
  },
  mounted() {
    this.fillYears();
    this.getCompanyPlants();
    this.init({});
  },
  methods: {
    /** 
     * Carrega os dados 3 vezes, uma parada cada tipo de listagem.
     * @param {object} filter
     */
    async init(filter = null) {
      this.$Progress.start();
      await this.fetchFiscalClosings(FiscalClosingTypeEnum.OPERATIONAL);
      await this.fetchFiscalClosings(FiscalClosingTypeEnum.INVOICING);
      await this.fetchFiscalClosings(FiscalClosingTypeEnum.FISCAL);
    },
    /**
     * @param {FiscalClosingTypeEnum} type
     */
    async fetchFiscalClosings(type) {
      const loader = this.$loading.show();
      this.loadingHeaders = true;
      await this.$store.dispatch("fiscalClosing/fetchItems", {
        filter: this.filter,
        order_by: [
          {
            column: "fiscal_closings.month",
          },
        ],
        limit: 12,
        paginate_type: 1, // simplePaginate
        type: type,
        year: this.selectedYear,
      })
      .then((response) => {
        switch (type) {
          case FiscalClosingTypeEnum.OPERATIONAL:
            this.firstMonthsHeadersOperational = response.data.firstMonthsHeadersOperational;
            this.lastMonthsHeadersOperational = response.data.lastMonthsHeadersOperational;
            break;
          case FiscalClosingTypeEnum.INVOICING:
            this.firstMonthsHeadersInvoicing = response.data.firstMonthsHeadersInvoicing;
            this.lastMonthsHeadersInvoicing = response.data.lastMonthsHeadersInvoicing;
            break;
          case FiscalClosingTypeEnum.FISCAL:
            this.firstMonthsHeadersFiscal = response.data.firstMonthsHeadersFiscal;
            this.lastMonthsHeadersFiscal = response.data.lastMonthsHeadersFiscal;
            break;
        }
      }).finally(() => {
        this.$Progress.finish();
        loader.hide();
        this.loadingHeaders = false;
      });
    },
    /**
     * @param {number} selectedYear
     */
    handleYearSelect(selectedYear) {
      this.selectedYear = selectedYear;
      this.firstMonthsHeadersOperational = this.generateInitialHeadersFirstMonths();
      this.firstMonthsHeadersInvoicing = this.generateInitialHeadersFirstMonths();
      this.firstMonthsHeadersFiscal = this.generateInitialHeadersFirstMonths();
      this.lastMonthsHeadersOperational = this.generateInitialHeadersLastMonths();
      this.lastMonthsHeadersInvoicing = this.generateInitialHeadersLastMonths();
      this.lastMonthsHeadersFiscal = this.generateInitialHeadersLastMonths();
      this.init({});
    },
    /**
     * Gera (reseta) array de objetos para os primeiros seis meses do ano selecionado.
     * @returns {array}
     */
    generateInitialHeadersFirstMonths() {
      return generateFirstMonths(this.selectedYear);
    },
    /**
     * Gera (reseta) array de objetos para os últimos seis meses do ano selecionado.
     * @returns {array}
     */
    generateInitialHeadersLastMonths() {
      return generateLastMonths(this.selectedYear);
    },
    fillYears() {
      var currentYear = new Date().getFullYear();
      for(var i = currentYear; i >= 1900; i--){
        this.years.push({ id: i, name: i.toString() });
      }
    },
    /**
     * @param {object} filter
     */
    handleFilterCompanyPlants(filter) {
      this.filter.company_plant_ids = filter;
      this.init({});
    },
    getCompanyPlants() {
      this.$store.dispatch("plant/getPlantsSimplified").then(() => {
        this.loadingCompanyPlants = false;
      });
    },
  },
};
</script>

<style scoped></style>
