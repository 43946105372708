import { adjustmentHistoryUrls } from ".";
import httpClient from "../../../../shared/libraries/httpClient";
import { handleUnexpectedStatusCode } from "../../../../shared/services";
import { AdjustmentHistoryCreateType } from "../types";
import { HttpStatusEnum } from "../../../../shared/enums";

/**
 * Incluir
 *
 * @param {AdjustmentHistoryCreateType} data
 * @returns {void}
 */
const execute = async (data) => {
  const res = await httpClient.post(adjustmentHistoryUrls.BASE, data);

  const createdStatus = [
    HttpStatusEnum.HTTP_OK,
    HttpStatusEnum.HTTP_NO_CONTENT,
  ];

  const created = createdStatus.includes(res.status);
  if (!created) {
    handleUnexpectedStatusCode(res);
  }
  
};

export default { execute };
