import * as types from "./mutation_types";
import { destroy, update } from "@/store/baseStore";
import { createAxios } from "@/plugins/axios";;
const endPoint = "/financial/payment-brand";
export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    fetch: (state) => state.items,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload;
    },
    [types.PUSH](state, payload) {
      state.items.push(payload);
    },
    [types.DESTROY](state, id) {
      destroy(state, id);
    },
    [types.UPDATE](state, payload) {
      update(state, payload);
    },
  },
  actions: {
    fetchItems({ commit }) {
      return createAxios()
        .get(endPoint)
        .then(({ data }) => {
          commit(types.SET, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    add({ commit }, params) {
      return createAxios()
        .post(endPoint, params)
        .then(({ data }) => {
          commit(types.PUSH, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    update({ commit }, params) {
      return createAxios()
        .put(`${endPoint}/${params.id}`, params)
        .then(({ data }) => {
          commit(types.UPDATE, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    destroy({ commit }, id) {
      return createAxios()
        .delete(`${endPoint}/${id}`)
        .then(({ data }) => {
          commit(types.DESTROY, id);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    changeStatus({ commit }, id) {
      return createAxios()
        .post(`${endPoint}/change-status/${id}`)
        .then(({ data }) => {
          commit(types.UPDATE, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    fetchActive({ commit }) {
      return createAxios()
        .get(`${endPoint}/active`)
        .then(({ data }) => {
          commit(types.SET, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
};
