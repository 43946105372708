import { operationSourceUrls } from ".";
import httpClient from "../../../../shared/libraries/httpClient";
import { handleUnexpectedStatusCode } from "../../../../shared/services";
import { HttpStatusEnum } from "../../../../shared/enums";
import { OperationSourceViewType } from "../types";

/**
 * Localizar por ID
 *
 * @param {number|string} id
 * @returns {?OperationSourceViewType}
 */
const execute = async (id) => {
  const url = `${operationSourceUrls.BASE}/${id}`;
  const res = await httpClient.get(url);

  switch (res.status) {
    case HttpStatusEnum.HTTP_OK:
      /**@type {OperationSourceViewType} operationSource */
      const operationSource = res.data.data;
  
      if(operationSource.tax_regimes !== null) {
        operationSource.tax_regimes = formatTaxRegimes(operationSource.tax_regimes);
      }
      return operationSource;
    case HttpStatusEnum.HTTP_NOT_ACCEPTABLE:
      return null;
    default:
      handleUnexpectedStatusCode(res);
  }
};

/**
 * Faz a conversão de JSON para Array de string
 *
 * @param {JSON} tax_regimes
 * @returns {Array<string>}
 */
const formatTaxRegimes = (tax_regimes) => {
  return JSON.parse(tax_regimes);
};

export default { execute };
