<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                    Status
                    <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7 pt-2">
                    <base-switch type="success" offText="inativo" onText="ativo" class="success"
                                    v-model="entityBankAccount.status"></base-switch>
                </div>
            </div>

            <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0  col-form-label form-control-label">
                    Nome da conta
                    <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                    <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-classes="form-control-sm">
                            <input
                                type="text"
                                class="form-control form-control-sm"
                                v-model="entityBankAccount.name"
                                maxlength="60"
                                :class="errors[0] ? 'is-invalid' : 'is-valid'"
                            />
                        </base-input>
                    </validation-provider>
                </div>
            </div>

            <div class="card-header border-header-primary-bottom mt-3 mb-2 p-2">
                <h5 class="h4 mb-0" slot="title">Dados do banco</h5>
            </div>

            <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                    Banco <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7 mb-1">
                    <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-group-classes="input-group-sm">
                            <puzl-select 
                                v-model="entityBankAccount.bank_id" 
                                :items="$_banks"
                                :labelMask="true"
                                label="$code$ - $name$"
                                :loading="loadingBanks"
                                :disabled="loadingBanks" />
                        </base-input>
                    </validation-provider>
                </div>
            </div>  

            <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                    Agência sem dígito <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7 mb-1">
                    <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-classes="form-control-sm">
                            <input
                                type="text"
                                class="form-control form-control-sm"
                                v-model="entityBankAccount.number_without_digit_agency"
                                :class="errors[0] ? 'is-invalid' : 'is-valid'"
                            />
                        </base-input>
                    </validation-provider>
                </div>
            </div>

            <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                    Dígito
                </label>
                <div class="col-md-7 mb-1">
                    <base-input input-classes="form-control-sm">
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="entityBankAccount.digit_agency"
                        />
                    </base-input>
                </div>
            </div>

            <div class="card-header border-header-warning-bottom mt-3 mb-2 p-2">
                <h5 class="h4 mb-0" slot="title">Dados da conta</h5>
            </div>

            <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                    Número sem dígito
                    <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7 mb-1">
                    <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-classes="form-control-sm">
                            <input
                                type="text"
                                class="form-control form-control-sm"
                                v-model="entityBankAccount.number_without_digit_account"
                                :class="errors[0] ? 'is-invalid' : 'is-valid'"
                            />
                        </base-input>
                    </validation-provider>
                </div>
            </div>

            <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Dígito <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7 mb-1">
                    <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-classes="form-control-sm">
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="entityBankAccount.digit_account"
                            :class="errors[0] ? 'is-invalid' : 'is-valid'"
                        />
                        </base-input>
                    </validation-provider>
                </div>
            </div>

            <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                    Tipo de conta
                </label>
                <div class="col-md-7 mb-1 ">
                    <base-input input-classes="form-control-sm">
                        <input type="text" class="form-control form-control-sm" v-model="entityBankAccount.account_type"/>
                    </base-input>
                </div>
            </div>

            <div class="modal-footer">
                <base-button type="secondary" @click="closeModal()">
                    Cancelar
                </base-button>
                <base-button type="success" native-type="submit" :disabled="invalid" :loading="loadingStore">
                    Salvar
                </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import PuzlSelect from "@/components/PuzlSelect";
const { formatErrorValidation } = require("@/plugins");

export default {
    name: "ModalCreateBank",
    components: { PuzlSelect },
    props: {
        entityId: {
        type: Number,
      },
    },
    data() {
        return {
            modal: {
                title: 'Cadastrar Conta Bancária',
                create: false,
            },
            entityBankAccount: {
                entity_id: null,
                status: true,
                name: null,
                bank_id: null,
                number_without_digit_agency: null,
                digit_agency: null,
                number_without_digit_account: null,
                digit_account: null,
                account_type: null,
            },
            loadingStore: false,
            loadingBanks: true,
        };
    },
    computed: {
        ...mapGetters({
            $_banks: 'bank/fetch',
        }),
    },
    mounted() {
        this.$refs.formValidator.validate();
        this.$store.dispatch('bank/fetchItems').then(() => {
            this.loadingBanks = false;
        });
    },
    beforeUpdate() {
        this.entityBankAccount = {
            entity_id: this.entityId,
            status: true,
            name: null,
            bank_id: null,
            number_without_digit_agency: null,
            digit_agency: null,
            number_without_digit_account: null,
            digit_account: null,
            account_type: null,
        }
    },
    methods: {
        closeModal() {
            this.modal.create = false;
        },
        handleCreateModal() {
          this.modal.create = true;
        },
        store() {
            this.$Progress.start()
            this.loadingStore = true;
            this.$store.dispatch('entityBankAccount/add', this.entityBankAccount)
                .then((response) => {
                    this.loadingStore = false;
                    this.$Progress.finish();
                    this.$emit('fetchEntityAccount');
                    this.$notify({
                        type: response.error_type,
                        message: response.message
                    })
                    this.closeModal();
                })
                .catch((error) => {
                    if (error.status === 200) {
                        this.$notify({
                            type: 'danger',
                            message: error.data.message
                        })
                        this.$Progress.finish();
                    } else if (error.response.status === 422) {
                        let errors = formatErrorValidation(error.response.data.errors)
                        this.$notify({
                            type: 'danger',
                            message: errors
                        })
                    }
                    this.$Progress.finish();
                    this.loadingStore = false;
                })
        },
    },
};
</script>

<style scoped></style>
