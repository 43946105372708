import * as types from './mutation_types'
import {destroy, update} from '@/store/baseStore'
import { createAxios } from "@/plugins/axios";
const endPoint = '/cmc/supplier-origin-entity'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
  },
  getters:{
    show: state => state.item,
    fetch: state => state.items,
  },
  mutations: {
    [types.PUSH] (state, payload){
      state.items.push(payload)
    },
    [types.DESTROY] (state, uuid){
      let index = state.items.findIndex((item) => item.uuid === uuid)
      if (index > -1) {
        state.items.splice(index, 1)
      }
    },
  },
  actions: {
    add({commit, state}, payload) {
      return createAxios().post(`${endPoint}`, payload)
        .then(({data}) =>{
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    destroy({commit, dispatch}, uuid) {
      return createAxios().delete(`${endPoint}/${uuid}`)
        .then(({data}) =>{
          commit(types.DESTROY, uuid)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    remove({commit, dispatch}, payload) {
      return createAxios().post(`${endPoint}/remove`, payload)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
