import { render, staticRenderFns } from "./_Create.vue?vue&type=template&id=a965d342&scoped=true"
import script from "./_Create.vue?vue&type=script&lang=js"
export * from "./_Create.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a965d342",
  null
  
)

export default component.exports