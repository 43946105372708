import { BaseListFilterType, initBaseListFilterType } from "../../../../shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} OperationSourceListFilterExtType
 * @property {boolean|null} status - false-Inativo, true-Ativo, null-Todos
 * @property {number} operation_nature - 0-Todos, 1-Entradas, 2-Saídas
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & OperationSourceListFilterExtType)} OperationSourceListFilterType
 */
export const OperationSourceListFilterType = {};

/**
 * Inicializar OperationSourceListFilterType
 *
 * @returns {OperationSourceListFilterType}
 */
export const initOperationSourceListFilterType = () => {
  return {
    ...initBaseListFilterType(),
    order_by: [
      { column: "operation_sources.name", is_desc: false },
      { column: "operation_sources.id", is_desc: true }
    ],
    custom_search: {
      columns: ["operation_sources.name"],
      values: [],
    },
    status: null,
    operation_nature: 0,
    product_service_hub_type_id: null
  };
}
