<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <div>
					<div class="row">
						<h3 class="col-7">
							{{ contractProposal.guarantorEntity.entity_name }} <br>
              {{ contractProposal.guarantorEntity.document }}
						</h3>
						<div class="col-5 text-dark text-right">
							{{ contractProposal.userGuarantorEntityCreated.name }} <br>
              {{ contractProposal.guarantor_entity_created_at | parseDate }}
						</div>
					</div>
				</div>

        <hr class="mb-0 mt-1 mb-3">

        <div class="text-dark">
          {{ contractProposal.guarantorEntity.address.address }},
          {{ contractProposal.guarantorEntity.address.number }},
          {{ contractProposal.guarantorEntity.address.district }},
          cep {{ contractProposal.guarantorEntity.address.postal_code }},
          {{ contractProposal.guarantorEntity.address.city }} /
          {{ contractProposal.guarantorEntity.address.state }}
        </div>

        <div class="modal-footer">
          <base-button type="danger" @click="deleteGuarantorEntity">
						<img width="23" height="23" src="/img/icons/icons8/ios/delete.png" />
            Excluir
          </base-button>
          <base-button type="warning" @click="selectGuarantorEntity">
						<img width="23" height="23" src="/img/icons/icons8/ios/create-new-white.png" />
            Editar
          </base-button>
        </div>
      </div>
    </modal>
    <ModalSearchEntity @selected="updateGuarantorEntity" ref="modalSearchEntity" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ModalSearchEntity from "@/views/Modules/Configuration/Entity/Shared/_ModalSearchEntity";

export default {
  name: "ModalGuarantorEntity",
  components: { ModalSearchEntity },
  computed: {
    ...mapGetters({
      $_user: 'auth/getUser',
    }),
  },
  data() {
    return {
      modal: {
        title: "Fiador",
        create: false,
      },
      contractProposal: null,
    };
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    /**
     * @param {object} contractProposal
     */
    openModal(contractProposal) {
      this.modal.create = true;
      this.contractProposal = contractProposal;
    },
    selectGuarantorEntity() {
      this.$refs.modalSearchEntity.openModal();
    },
    /**
     * @param {object} entity
     */
    updateGuarantorEntity(entity) {
      this.$Progress.start();
      this.$store.dispatch('contractProposal/storeGuarantorEntity', {
        'id': this.contractProposal.id,
        'guarantor_entity_id': entity.id,
        'guarantor_entity_created_by_user_id': this.$_user.id,
      }).then((response) => {
        this.contractProposal.guarantorEntity.entity_name = response.data.guarantorEntity.entity_name;
        this.contractProposal.guarantorEntity.document = response.data.guarantorEntity.document;
        this.contractProposal.userGuarantorEntityCreated.name = response.data.userGuarantorEntityCreated.name;
        this.contractProposal.guarantor_entity_created_at = response.data.guarantor_entity_created_at;
        this.contractProposal.guarantorEntity.address.address = response.data.guarantorEntity.address.address;
        this.contractProposal.guarantorEntity.address.number = response.data.guarantorEntity.address.number;
        this.contractProposal.guarantorEntity.address.district = response.data.guarantorEntity.address.district;
        this.contractProposal.guarantorEntity.address.postal_code = response.data.guarantorEntity.address.postal_code;
        this.contractProposal.guarantorEntity.address.city = response.data.guarantorEntity.address.city;
        this.contractProposal.guarantorEntity.address.state = response.data.guarantorEntity.address.state;
        this.$emit('contractProposalUpdated', response.data);
        this.$notify({
          type: 'success',
          message: 'Fiador atualizado com sucesso!'
        });
      }).finally(() => {
        this.$Progress.finish();
      });
    },
    deleteGuarantorEntity() {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$store
            .dispatch(
              "contractProposal/deleteGuarantorEntityContractProposalById",
              this.contractProposal.id
            ).then(() => {
              this.$emit('deletedGuarantorEntity', this.contractProposal.id);
              this.closeModal();
              this.$notify({
                type: "success",
                message: "Fiador excluído com sucesso!",
              });
            }).finally(() => {
              this.$Progress.finish();
            });
        }
      });
    },
  },
};
</script>

<style scoped></style>
