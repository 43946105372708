var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:(_vm.listType === 'table' && 'display: grid;')},[([_vm.paymentInstallmentStatusEnum.DENIED.ID, _vm.paymentInstallmentStatusEnum.CANCELED.ID].includes(_vm.bill_receive.status) && _vm.listType === 'table')?_c('div',{staticClass:"new-minicard-red new-minicard-table"},[_c('div',[_c('h4',[_vm._v(" "+_vm._s(_vm.billReceiveStatusEnum[_vm.bill_receive.status])+" ")])])]):_vm._e(),([_vm.paymentInstallmentStatusEnum.DENIED.ID, _vm.paymentInstallmentStatusEnum.CANCELED.ID].includes(_vm.bill_receive.status) && _vm.listType === 'cards')?_c('div',{staticClass:"new-minicard-red"},[_c('h4',[_vm._v(" "+_vm._s(_vm.billReceiveStatusEnum[_vm.bill_receive.status])+" ")])]):_vm._e(),(![_vm.paymentInstallmentStatusEnum.DENIED.ID, _vm.paymentInstallmentStatusEnum.CANCELED.ID].includes(_vm.bill_receive.status))?_c('base-dropdown',[(_vm.listType === 'table')?_c('div',{staticClass:"new-minicard-table",class:_vm.miniCardTypes[_vm.bill_receive.status],attrs:{"slot":"title-container"},slot:"title-container"},[_c('div',[_c('img',{attrs:{"src":_vm.statusIcon[_vm.bill_receive.status]}}),_c('h4',[_vm._v(" "+_vm._s(_vm.billReceiveStatusEnum[_vm.bill_receive.status])+" ")]),_c('img',{attrs:{"src":_vm.dropdownColors[_vm.bill_receive.status],"width":"12"}})])]):_vm._e(),(_vm.listType === 'cards')?_c('div',{class:_vm.miniCardTypes[_vm.bill_receive.status],attrs:{"slot":"title-container"},slot:"title-container"},[_c('img',{attrs:{"src":_vm.statusIcon[_vm.bill_receive.status]}}),_c('h4',[_vm._v(" "+_vm._s(_vm.billReceiveStatusEnum[_vm.bill_receive.status])+" ")]),_c('img',{attrs:{"src":_vm.dropdownColors[_vm.bill_receive.status],"width":"12"}})]):_vm._e(),_vm._l((_vm.getBillReceiveStatus()),function(item,statusIndex){return (statusIndex != _vm.bill_receive.status
      && _vm.bill_receive.type === _vm.billReceiveTypeEnum.ENTITY_BILL_RECEIVES
      && Number(_vm.bill_receive.status) !== 1
      && !(_vm.bill_receive.status == 2
      && statusIndex == 0))?_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.changeInstallmentStatus(
        _vm.bill_receive.installment_id,
        _vm.bill_receive.type,
        statusIndex,
        _vm.index,
        1)}}},[_vm._v(" "+_vm._s(item)+" ")]):_vm._e()}),_vm._l((_vm.getBillReceiveStatusWithoutApprove()),function(item,statusIndex){return (statusIndex != _vm.bill_receive.status &&
      _vm.bill_receive.type === _vm.billReceiveTypeEnum.CONTRACT_PROPOSAL_PAYMENTS
      && Number(_vm.bill_receive.status) !== 1 &&
      !(_vm.bill_receive.status == 2
      && statusIndex == 0))?_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.changeInstallmentStatus(
        _vm.bill_receive.installment_id,
        _vm.bill_receive.type,
        statusIndex,
        _vm.index,
        1)}}},[_vm._v(" "+_vm._s(item)+" ")]):_vm._e()}),(Number(_vm.bill_receive.status) === 1 &&
      _vm.$hasPermission('bill_receive.reverse_installment'))?_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.changeInstallmentStatus(
        _vm.bill_receive.installment_id,
        _vm.bill_receive.type,
        10,
        _vm.index)}}},[_vm._v(" Estornar ")]):_vm._e()],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }