<template>
    <div>
        <base-header class="bg-gray-content">
            <div class="row align-items-center py-4">
                <div class="col-lg-12 col-12">
                    <PuzlBreadcrumb />
                </div>
            </div>
        </base-header>
        <ListHoraryResume />
    </div>
</template>

<script>
import ListHoraryResume from "./Shared/_List";
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
export default {
    name: "Index",
    components: {
        ListHoraryResume,
        PuzlBreadcrumb,
    },
};
</script>

<style></style>
