<template>
  <div>
    <div
      v-if="$_widgets && !loadingWidgets"
      class="row row-cols-1 row-cols-md-4"
    >
      <div class="col card-margin-top card-margin-top-mobile">
        <div class="card height-card">
          <div class="card-body">
            <img
              src="/img/icons/icons8/ios/calculator-success.png"
              width="25px"
              height="25px"
              class="mr-2"
            />
            <span class="card-title text-dark" style="font-size: 11.3px">
              Volume total
            </span>
            <div class="text-dark card-text-border mb-2 mt-2 font-size-card">
              Concreto
              <span class="mr-1 float-right font-size-card">
                {{
                  Number($_widgets.scheduledTravels.total_volume)
                    .toFixed(1)
                    .toString()
                    .replace(".", ",")
                }}
                m³
              </span>
            </div>
            <div class="text-dark card-text-border font-size-card">
              Serviços
              <span class="mr-1 float-right font-size-card">
                {{
                  Number($_widgets.serviceCycles.total_volume_schedule_services)
                    .toFixed(1)
                    .toString()
                    .replace(".", ",")
                }}
                m³
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col card-margin-top card-margin-top-mobile">
        <div class="card height-card">
          <div class="card-body">
            <img
              src="/img/icons/icons8/ios/concrete-pump.png"
              width="25px"
              height="25px"
              class="mr-2"
            />
            <span class="card-title text-dark" style="font-size: 11.3px">
              Número de equipamentos
            </span>
            <div class="text-dark card-text-border mb-2 mt-2 font-size-card">
              Concreto
              <span class="mr-1 float-right font-size-card">
                {{ $_widgets.scheduledTravels.total_equipments_schedule_travels }}
              </span>
            </div>
            <div class="text-dark card-text-border font-size-card">
              Serviços
              <span class="mr-1 float-right font-size-card">
                {{ $_widgets.services.total_equipments_schedule_services }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col card-margin-top card-margin-top-mobile">
        <div class="card height-card">
          <div class="card-body">
            <img
              src="/img/icons/interstate-truck.png"
              width="26px"
              height="26px"
              class="mr-2"
            />
            <span class="card-title text-dark" style="font-size: 11.3px">
              Volume médio por equipamento
            </span>
            <div class="text-dark card-text-border mb-2 mt-2 font-size-card">
              Concreto
              <span class="mr-1 float-right font-size-card">
                {{
                  Number($_widgets.scheduledTravels.avg_volume_per_equipment_schedule_travels)
                    .toFixed(1)
                    .toString()
                    .replace(".", ",")
                }}
                m³
              </span>
            </div>
            <div class="text-dark card-text-border font-size-card">
              Serviços
              <span class="mr-1 float-right font-size-card">
                {{
                  Number($_widgets.services.avg_volume_per_equipment_schedule_services)
                    .toFixed(1)
                    .toString()
                    .replace(".", ",")
                }}
                m³
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col card-margin-top card-margin-top-mobile">
        <div class="card height-card">
          <div class="card-body">
            <img
              src="/img/icons//icons8/ios/driver-license-card-warning.png"
              width="29px"
              height="29px"
              class="mr-2"
            />
            <span class="card-title text-dark" style="font-size: 11.3px">
              Média por motorista/ajudante
            </span>
            <div class="text-dark card-text-border mb-2 mt-2 font-size-card">
              Concreto
              <span class="mr-1 float-right font-size-card">
                {{
                  Number($_widgets.scheduledTravels.avg_volume_per_driver_schedule_travels)
                    .toFixed(1)
                    .toString()
                    .replace(".", ",")
                }}
                m³
              </span>
            </div>
            <div class="text-dark card-text-border font-size-card">
              Serviços
              <span class="mr-1 float-right font-size-card">
                {{
                  Number($_widgets.services.avg_volume_per_driver_schedule_services)
                    .toFixed(1)
                    .toString()
                    .replace(".", ",")
                }}
                m³
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Loading -->
    <div class="row card-wrapper" v-show="loadingWidgets">
      <div v-for="index in 4" :key="index" :class="'col-md-3'" class="mt-n4">
        <div class="card card-border-top-light bg-gray-content">
          <div style="padding: 1.4rem" class="card-body">
            <div class="row">
              <div class="col-md-5">
                <div><span class="bar button"></span></div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div><span class="bar button"></span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "WidgetsEquipmentResume",
  computed: {
    ...mapGetters({
      $_widgets: "driverResume/fetchWidgets",
    }),
  },
  props: {
    loadingWidgets: Boolean,
  },
};
</script>

<style scoped>
.card-text-border {
  border: 2px solid #d3d3d3;
  border-radius: 10px;
  padding: 5px;
}
.card-margin-top {
  margin-top: -14px;
}
@media (max-width: 1190px) {
  .card-margin-top-mobile {
    margin-top: -19px;
  }
}
.card {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.height-card {
  height: 165px;
}
.font-size-card {
  font-size: 10px;
}
</style>
