<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <h4>
              <small class="font-weight-500">Vigência do preço</small>
            </h4>
          </div>
          <div class="col">
            <badge type="default">V.{{ version }}</badge>
          </div>
        </div>
        <div class="row">
          <div class="col-md">
            <h4>
              <small class="font-weight-500">Preço atual</small>
            </h4>
          </div>
          <div class="col-md">
            <base-input input-group-classes="input-group-sm">
              <template slot="prepend">
                <small class="input-group-sm p-0 m-0 font-weight-500" style="text-transform: none">
                  R$
                </small>
              </template>
              <input
                disabled
                v-model.lazy="atual_price"
                v-money="money"
                name="validate"
                maxlength="12"
                inputmode="numeric"
                class="form-control form-control-sm numeric"
              />
              <template slot="append">
                <small class="input-group-sm p-0 m-0 font-weight-500" style="text-transform: none">
                  /m3
                </small>
              </template>
            </base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md">
            <h4>
              <small class="font-weight-500">Novo preço</small>
            </h4>
          </div>
          <div class="col-md mt-1">
            <base-input input-group-classes="input-group-sm">
              <template slot="prepend">
                <small class="input-group-sm p-0 m-0 font-weight-500" style="text-transform: none">
                  R$
                </small>
              </template>
              <input
                v-model.lazy="new_price"
                name="validate"
                v-money="money"
                maxlength="12"
                inputmode="numeric"
                class="form-control form-control-sm numeric"
              />
              <template slot="append">
                <small class="input-group-sm p-0 m-0 font-weight-500" style="text-transform: none">
                  /m3
                </small>
              </template>
            </base-input>
          </div>
        </div>
        <div class="row mt-2 pr-2 pl-2 pt-2">
            <h4>
              <small style="text-transform: none" class="text-danger">
                Atenção! Essa mudança irá alterar os preços de venda de todas as viagens carregadas para essa vigência
                de preço
              </small>
            </h4>
        </div>
        <div class="modal-footer" style="padding-right: 10px">
          <base-button type="secondary" @click="closeModal('create')">
            fechar
          </base-button>
          <base-button @click.prevent="save" :disabled="new_price === '0,00'" type="success" native-type="submit"
              :loading="loading">
            Salvar
          </base-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { VMoney } from "v-money";

export default {
  components: {},
  name: "ModalUpdatePrice",
  data() {
    return {
      modal: false,
      title: "Alteração de preço",
      loading: false,
      atual_price: '1.000,00',
      version: 1,
      new_price: undefined,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false
      }
    };
  },
  directives: {
    money: VMoney,
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    openModal(formulation) {
      this.atual_price = formulation.value.toString().replace('.', ',')
      this.version = formulation.version
      this.formulation_uuid = formulation.uuid
      this.modal = true;
    },
    save() {
      this.loading = true
      this.$store.dispatch('formulations/postChangePrice', {
        uuid: this.formulation_uuid,
        value: this.new_price
      }).then((response) => {
        this.loading = false
        this.$notify({type: response.error_type, message: response.message})
        this.$parent.init()
        this.closeModal()
      }).catch(error => {
        this.$notify({
          type: 'danger',
          message: error.data.message
        });
      })
    }
  },
};
</script>

<style></style>
