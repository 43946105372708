import * as types from './mutation_types'
import {destroy, update, cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'
import { createAxios } from "@/plugins/axios";

const endPoint = '/technology/technical-dre+microservice'
export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    fetch: state => state.items,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload
    },
  },
  actions: {
    fetchItems({commit}, params) {
      return createAxios().patch(endPoint, params)
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
