<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-gray d-inline-block mb-0">{{ $route.name }}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4 text-gray">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i></a></li>
              <li class="breadcrumb-item"><a href="#">Tecnologia</a></li>
              <li class="breadcrumb-item" aria-current="page">{{ $route.name }}</li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- pr-6 pl-6 pt-3 pb-3 -->
      <!-- Header Menus -->
      <div class="row mb-3">
        <div class="col-md-2 mb-2">
          <base-button block size="lg" type="success" class="text-uppercase" @click="handleCreateMixe()"
                       :disabled="loadingSkeletonMixDesign || $helper.Object.isEmpty(mix_design.groups)">
            <i class="fas fa-plus"></i> novo
          </base-button>
        </div>
        <div class="col-md-2 mb-2">
          <a href="#" @click.prevent="handleSetGroups(mix_design)">
            <base-button block size="lg" type="indigo" class="text-uppercase" :disabled="loadingSkeletonMixDesignMixe">
              <i class="fa fa-puzzle-piece"/> grupos
            </base-button>
          </a>
        </div>
        <div class="col-md-2 mb-2">
          <a href="#" @click.prevent="handleAdjustment()">
            <base-button block size="lg" type="primary" class="text-uppercase" :disabled="loadingSkeletonMixDesignMixe">
              <i class="fas fa-wrench"></i> ajuste
            </base-button>
          </a>
        </div>
        <div class="col-md-2 mb-2">
          <router-link v-if="mix_design" :to="{ path: '/technology/mix/resume/'+mix_design.uuid}">
            <base-button class="text-uppercase" block size="lg" type="info">
              <i class="fas fa-list"></i> resumo
            </base-button>
          </router-link>
        </div>
        <div class="col-md-2 mb-2">
          <router-link v-if="mix_design" :to="{
                  name: 'Formulações Edição',
                  path:'/technology/mix/mixdesigns/edit/',
                  params: { mix_design_uuid: mix_design.uuid }
                }">
            <base-button class="text-uppercase" block size="lg" type="warning">
              <i class="fas fa-edit"></i> editar
            </base-button>
          </router-link>
        </div>
        <div class="col-md-2 mb-2">
          <router-link :to="{ path:'/technology/mix/grid'}">
            <base-button class="text-uppercase" block size="lg" type="light">
              <i class="fas fa-undo-alt"></i>
              formulações
            </base-button>
          </router-link>
        </div>
      </div>
      <div class="row align-items-center pb-0 mb-3" v-show="loadingSkeletonMixDesignMixe">
        <div class="col-md-12">
          <div class="checklist-item checklist-item-primary">
            <div class="checklist-info">
              <div class="h1">
                <skeleton-puzl type="button"></skeleton-puzl>
              </div>
              <div class="h1">
                <skeleton-puzl type="button"></skeleton-puzl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center pb-0 mb-3" v-if="mix_design && !loadingSkeletonMixDesignMixe">
        <div class="col-md-12">
          <div class="checklist-item checklist-item-primary" v-if="mix_design">
            <div class="checklist-info">
              <h1 class="checklist-title mb-0" v-if="mix_design.plant">
                {{ mix_design.plant.name || '' }}
              </h1>
              <h4 class="checklist-title mb-0 small">
                            <span v-if="mix_design.type">
                                {{ mix_design.type.technical_description || '' }}
                                <span v-if="mix_design.subtype">
                                    {{ nextHeader(mix_design.subtype.technical_description) }}
                                </span>
                            </span>
                <span v-if="mix_design.subtype">
                                {{ mix_design.subtype.technical_description || '' }}
                                <span v-if="mix_design.classification">
                                    {{ nextHeader(mix_design.classification.technical_description) }}
                                </span>
                            </span>
                <span v-if="mix_design.classification">
                                {{ mix_design.classification.technical_description || '' }}
                            </span>
              </h4>
              <h4 class="checklist-title mb-2 small mt-2">
                            <span v-if="mix_design.slumpFlow">
                                {{ mix_design.slumpFlow.nomenclature || '' }}
                                <span v-if="mix_design.maximum_size">
                                    {{ nextHeader(mix_design.maximum_size.description) }}
                                </span>
                            </span>
                <span v-if="mix_design.maximum_size">
                                {{ mix_design.maximum_size.description || '' }}
                            </span>
              </h4>
            </div>
          </div>
        </div>
        <transition name="fade">
          <div class="col-md-12 mt-2" v-if="$_groups_without_references.length">
          <span class="display-4" style="font-size: 0.95rem">
           <i class="text-warning fa-solid fa-triangle-exclamation"></i>
            Há grupos sem referência comercial
          </span>
            <br>
            <a href="#"
               @click.prevent="setGroupModalReference(item)"
               class="text-danger font-weight"
               v-for="(item, index) in $_groups_without_references">
              {{ item.group_name }}
              <span v-show="index !== $_groups_without_references.length - 1">|</span>
            </a>
          </div>

        </transition>
        <transition name="fade">
          <div class="col-md-12 mt-2" v-if="doesntHasLargeAggregate">
          <span class="display-4" style="font-size: 0.95rem">
           <i class="text-warning fa-solid fa-triangle-exclamation"></i>
            TABELA SEM AGREGADO GRAÚDO
          </span>
          </div>
        </transition>
      </div>
      <div class="p-3 mb-3" style="background-color: white">
        <div class="row row-cols-1 row-cols-md-3">
          <div class="col p-1">
            <base-button @click.prevent="handleClickFilterActiveItems()"
                         :class="filter.active && 'active'"
                         outline block type="success">
              <i class="fa-solid fa-circle-check"></i> ATIVOS
            </base-button>
          </div>
          <div class="col p-1">
            <base-button @click.prevent="expandAllParameters()" outline block type="primary">
              <i class="fa-solid fa-compress"></i> PARÂMETROS
            </base-button>
          </div>
          <div class="col p-1">
            <base-button @click.prevent="expandAllContents()" outline block type="warning">
              <i class="fa-solid fa-compress"></i> TEORES
            </base-button>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid">
      <div class="row card-wrapper" v-show="loadingSkeletonMixDesignMixe">
        <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <div class="row align-items-center" v-if="!mix_designs_mixes.length && !loadingSkeletonMixDesignMixe">
        <div class="col-md-12 text-center">
          <div class="card p-4">
            Sem dados cadastrados!
          </div>
        </div>
      </div>

      <div class="row card-wrapper" v-if="mix_designs_mixes.length && !loadingSkeletonMixDesignMixe">
        <div class="col-md-6 col-lg-4 col-xlg-4" v-for="(mix_designs_mixe, indexMixe) in MixDesignMixes"
             :key="indexMixe" v-if="mix_designs_mixes.length">
          <card v-bind:class="[
            mix_designs_mixe.status===0 ? 'card-border-top-danger' : '',
            mix_designs_mixe.status===1 ? 'card-border-top-success' : '',
            mix_designs_mixe.status===2 ? 'card-border-top-light' : '',
            mix_designs_mixe.status===3 ? 'card-border-top-yellow' : '',
            mix_designs_mixe.status===5 ? 'card-border-top-primary' : ''
            ]">
            <div class="row align-items-center mb-3 mt-n2">
              <div @click.prevent="copy(`TR-${mix_designs_mixe.mix_code}`)" v-if="mix_designs_mixe.mix_code"
                   class="col-8">
                <badge style="font-size: 12px" class="pointer" type="default">TR-{{ mix_designs_mixe.mix_code }}</badge>
              </div>
              <div v-else class="col-8">
                <collapse v-for="(itemMixItem, indexMixItem) in mix_designs_mixe.items" :key="indexMixItem">
                  <collapse-item v-for="(item, indexItem) in itemMixItem" v-if="indexItem != 'binder'" :key="indexItem">
                    <h5 slot="title" class="mb-0">
                                        <span class="small">
                                            {{ item.short_description }}
                                            {{ format_mixe(item.period, item.period_type) }}
                                        </span>
                      {{ item.item_description }}
                      {{ item.letter }}
                    </h5>
                    <div>
                      <h5 class="checklist-title mb-0">
                        Sd:
                        <span class="small">
                                                {{ item.value_sd }}
                                                &nbsp;
                                                {{ item.letter }}
                                            </span>
                      </h5>
                      <h5 class="checklist-title mb-0">
                        NC:
                        <span class="small" v-if="reliability_levels">
                                                {{
                            item.value_nc ? return_to_percentage(item.value_nc, reliability_levels) + '%' : '-'
                          }}
                                            </span>
                      </h5>
                      <h5 class="checklist-title mb-0">
                        Z:
                        <span class="small">
                                                {{ item.value_z ? item.value_z : '-' }}
                                            </span>
                      </h5>
                      <h5 class="checklist-title mb-0">
                        Vm:
                        <span class="small">
                                                {{ item.value_medium }}
                                                &nbsp;
                                                {{ item.letter }}
                                            </span>
                      </h5>
                    </div>
                  </collapse-item>
                </collapse>
              </div>
              <div class="col-4 text-right">
                <base-dropdown menuOnRight>
                  <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                    <i class="fas fa-cog"></i>
                  </base-button>
                  <a class="dropdown-item" @click="handleActivateMixe(mix_designs_mixe)"
                     v-if="mix_designs_mixe.status != 1">
                    <i class="fas fa-check-circle text-success"></i>
                    Ativar
                  </a>
                  <a class="dropdown-item" @click="handleEditMixe(mix_designs_mixe)">
                    <i class="fas fa-edit text-warning"></i>
                    Editar
                  </a>
                  <a class="dropdown-item" @click.prevent="handleReplicate(mix_designs_mixe.uuid)">
                    <i class="fas fa-copy text-puzl-place"></i>
                    Replicar
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleDeleteMixDesignMixe(mix_designs_mixe.uuid)">
                    <i class="fas fa-times text-danger"></i>
                    &nbsp;Excluir
                  </a>
                </base-dropdown>
              </div>
              <div v-if="mix_designs_mixe.mix_code" class="col-12 mt-1">
                <collapse v-for="(itemMixItem, indexMixItem) in mix_designs_mixe.items" :key="indexMixItem">
                  <collapse-item v-for="(item, indexItem) in itemMixItem" v-if="indexItem != 'binder'" :key="indexItem">
                    <h5 slot="title" class="mb-0">
                                        <span class="small">
                                            {{ item.short_description }}
                                            {{ format_mixe(item.period, item.period_type) }}
                                        </span>
                      {{ item.item_description }}
                      {{ item.letter }}
                    </h5>
                    <div>
                      <h5 class="checklist-title mb-0">
                        Sd:
                        <span class="small">
                                                {{ item.value_sd }}
                                                &nbsp;
                                                {{ item.letter }}
                                            </span>
                      </h5>
                      <h5 class="checklist-title mb-0">
                        NC:
                        <span class="small" v-if="reliability_levels">
                                                {{
                            item.value_nc ? return_to_percentage(item.value_nc, reliability_levels) + '%' : '-'
                          }}
                                            </span>
                      </h5>
                      <h5 class="checklist-title mb-0">
                        Z:
                        <span class="small">
                                                {{ item.value_z ? item.value_z : '-' }}
                                            </span>
                      </h5>
                      <h5 class="checklist-title mb-0">
                        Vm:
                        <span class="small">
                                                {{ item.value_medium }}
                                                &nbsp;
                                                {{ item.letter }}
                                            </span>
                      </h5>
                    </div>
                  </collapse-item>
                </collapse>
              </div>
            </div>
            <!-- Card body -->
            <collapse class="border rounded p-0 mt-3" id="collapse">
              <collapse-item name="1" class="header-gray" class-body="pl-2 p-2 m-0"
                             back-ground="border-header-primary-bottom">
                <h5 slot="title" class="mb-0 collapse-parameters">Parâmetros Técnicos</h5>
                <div v-if="mixe.technical_parameter[mix_designs_mixe.id]">
                  <div class="form-group row m-0 p-0 mb-1">
                    <label class="col-md-6 m-0 p-0 col-form-label form-control-label">
                      <span class="text-default">Aglomerante</span>
                      <i v-if="validateBetweenValues(mixe.technical_parameter[mix_designs_mixe.id].binder, 100, 500)"
                         class="text-warning fa-solid fa-triangle-exclamation ml-1 mr-1">
                      </i>
                    </label>
                    <div class="col-md-6 m-0 p-0">
                      <base-input input-group-classes="input-group-sm">
                        <input inputmode="numeric" v-model="mixe.technical_parameter[mix_designs_mixe.id].binder"
                               class="form-control form-control-sm" v-mask="['####']"
                               v-on:keydown="changeWater(mix_designs_mixe.id, indexMixe)"/>
                        <template slot="append">
                          <small class="is-invalid input-group-sm p-0 m-0">
                            <span style="text-transform: none">kg/m</span><sup>3</sup>
                          </small>
                        </template>
                      </base-input>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0 mb-1">
                    <label class="col-md-6 m-0 p-0 col-form-label form-control-label">
                      <span class="text-default">Água</span>
                      <i v-if="validateBetweenValues(mixe.technical_parameter[mix_designs_mixe.id].water, 150, 300)"
                         class="text-warning fa-solid fa-triangle-exclamation ml-1 mr-1">
                      </i>
                    </label>
                    <div class="col-md-6 m-0 p-0">
                      <base-input input-group-classes="input-group-sm">
                        <input inputmode="numeric" v-model.number="mixe.technical_parameter[mix_designs_mixe.id].water"
                               class="form-control form-control-sm" v-mask="['####']"
                               v-on:keydown="changeWater(mix_designs_mixe.id, indexMixe)"/>
                        <template slot="append">
                          <small class="input-group-sm p-0 m-0">
                            <span style="text-transform: none">l/m</span><sup>3</sup>
                          </small>
                        </template>
                      </base-input>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0 mb-1">
                    <label class="col-md-6 m-0 p-0 col-form-label form-control-label">
                      <span class="text-default">A/Agl.</span>
                    </label>
                    <div class="col-md-6 m-0 p-0">
                      <base-input input-group-classes="input-group-sm">
                        <input inputmode="numeric"
                               v-model.number="mixe.technical_parameter[mix_designs_mixe.id].water_binder"
                               class="form-control form-control-sm" v-mask="['#.###']"
                               v-on:keydown="changeWaterBinder(mix_designs_mixe.id, indexMixe)"/>
                      </base-input>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0 mb-1 mt-4">
                    <label class="col-md-6 m-0 p-0 col-form-label form-control-label">
                      <span class="text-default">T. Arg. (v)</span>
                      <i
                        v-if="validateBetweenValues(mixe.technical_parameter[mix_designs_mixe.id].content_mortar, 40, 80)"
                        class="text-warning fa-solid fa-triangle-exclamation ml-1 mr-1">
                      </i>
                    </label>
                    <div class="col-md-6 m-0 p-0">
                      <base-input input-group-classes="input-group-sm">
                        <input inputmode="numeric"
                               :disabled="!mix_design.has_ag"
                               :value="mixe.technical_parameter[mix_designs_mixe.id].content_mortar"
                               @input="mixe.technical_parameter[mix_designs_mixe.id].content_mortar = setTArg($event, mix_designs_mixe.id)"
                               class="form-control form-control-sm"
                               v-mask="['##.#', '#.#', '###.#']"
                               @keydown="setEditing(mix_designs_mixe.id, indexMixe)"/>
                        <template slot="append">
                          <small class="input-group-sm p-0 m-0">
                            %
                          </small>
                        </template>
                      </base-input>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0 mb-1">
                    <label class="col-md-6 m-0 p-0 col-form-label form-control-label">
                      <span class="text-default">Ar total</span>
                      <i
                        v-if="validateBetweenValues(mixe.technical_parameter[mix_designs_mixe.id].total_air, 1, 9.90)"
                        class="text-warning fa-solid fa-triangle-exclamation ml-1 mr-1">
                      </i>
                    </label>
                    <div class="col-md-6 m-0 p-0">
                      <base-input input-group-classes="input-group-sm">
                        <input inputmode="numeric" v-model="mixe.technical_parameter[mix_designs_mixe.id].total_air"
                               class="form-control form-control-sm" v-mask="['##.#', '#.#']"
                               @input="setEditing(mix_designs_mixe.id, indexMixe)"/>
                        <template slot="append">
                          <small class="input-group-sm p-0 m-0">
                            %
                          </small>
                        </template>
                      </base-input>
                    </div>
                  </div>
                </div>

              </collapse-item>
            </collapse>

            <collapse class="border rounded p-0 mt-3 ">
              <collapse-item name="1" class="header-gray " class-body="pl-2 p-2 m-0"
                             back-ground="border-header-success-bottom">
                <h5 slot="title" class="mb-0 collapse-contents">Teores e Quantidades</h5>
                <div v-for="(category, indexCategory) in mix_designs_mixe.groups_categories"
                     :key="indexCategory">
                  <h4 class="mt-3">
                    {{ category.name }}
                  </h4>

                  <div class="form-group row m-0 p-0 mb-1" v-for="(options, indexOptions) in category.options"
                       :key="options.id">
                    <label class="col-md-5 m-0 p-0 col-form-label form-control-label">
                                        <span class="text-default small">
                                            {{ options.name }}
                                        </span>
                      <i v-show="validateBetweenValuesByCategory(options.value, category.category_id)"
                         class="text-warning fa-solid fa-triangle-exclamation mr-1"></i>
                    </label>
                    <div class="col-md-7 m-0 p-0 text-left">
                      <base-input input-group-classes="input-group-sm">
                        <input type="text" inputmode="numeric"
                               v-if="category.category_id != 1 && category.calculable"
                               :value="options.value" class="form-control form-control-sm"
                               v-mask="handleMask(category.category_id)"
                               @keydown="setEditing(mix_designs_mixe.id, indexMixe)"
                               @input="options.value = change($event, category.options, options.value, indexOptions, indexCategory, indexMixe)"
                               :disabled="handleValidateCalculable(options.id, category)"/>
                        <input v-else maxlength="5" type="text" inputmode="numeric" :value="options.value"
                               class="form-control form-control-sm"
                               @keydown="setEditing(mix_designs_mixe.id, indexMixe)"
                               @input="options.value = change($event, category.options, options.value, indexOptions, indexCategory, indexMixe)"
                               :disabled="handleValidateCalculable(options.id, category)"/>
                        <template slot="append">
                          <small class="input-group-sm p-0 m-0" style="font-size: 65%">
                            {{ category_calculable[category.category_id] || '%' }}
                          </small>
                        </template>
                      </base-input>
                    </div>
                  </div>
                </div>
              </collapse-item>
            </collapse>

            <div class="row" v-show="showSave[mix_designs_mixe.id] == true">
              <div class="col-md-6 mt-4">
                <base-button block type="secondary" @click.prevent="unsetEditing(mix_designs_mixe.id, indexMixe)">
                  Cancelar
                </base-button>
              </div>
              <div class="col-md-6 mt-4">
                <base-button block type="success" :loading="loading[mix_designs_mixe.id]"
                             @click="storeMixe(mix_designs_mixe.id, mix_designs_mixe.uuid, indexMixe)">
                  Salvar
                </base-button>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8 mt-4">
                <small>{{ mix_designs_mixe.user.short_name }}</small>
                &nbsp;-&nbsp;
                <small>{{ mix_designs_mixe.updated_at | parseDate }}</small>
              </div>
              <div class="col-md-4 mt-4 text-right">
                <img v-if="!mix_designs_mixe.show_observation" class="pointer"
                     @click.prevent="mix_designs_mixe.show_observation = !mix_designs_mixe.show_observation"
                     style="height: 22px" src="https://img.icons8.com/ios/50/null/comments--v1.png"/>
                <img v-else class="pointer"
                     @click.prevent="mix_designs_mixe.show_observation = !mix_designs_mixe.show_observation"
                     style="height: 22px" src="https://img.icons8.com/ios/50/4747e1/comments--v1.png"/>
              </div>
            </div>
            <div class="row mt-2" v-if="mix_designs_mixe.show_observation">
              <div class="col-md-12">
                <textarea @input="setObservation(mix_designs_mixe)" rows="8" maxlength="400" class="form-control"
                          v-model="mix_designs_mixe.observation"/>
              </div>
            </div>
          </card>

        </div>
      </div>
    </div>
    <modal-reference @getWithoutReference="getWithoutReference()" ref="modalReference"></modal-reference>
    <modal-create-mixe @submit="initialParams()" ref="createMixe"></modal-create-mixe>
    <modal-edit-mixe ref="editMixe"></modal-edit-mixe>
    <modal-group ref="createGroupMix" @submit="fetchGroup"></modal-group>
    <ModalAdjustment ref="ModalAdjustmentMixDesigns" :item="selected" @fetchMixes="fetch"/>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex'
import Swal from 'sweetalert2'
import ModalReference from '../Mix/Shared/_ModalReference';
import {
  mask1
} from '@/plugins'
import {
  checkNumberValue
} from '@/helpers'
import ModalGroup from './Shared/_ModalGroup';
import ModalCreateMixe from './Shared/_ModalCreateMixe';
import ModalEditMixe from './Shared/_ModalEditMixe';
import {
  period_types,
  value_z_to_percentage,
  nextHeader
} from "@/views/Modules/Technology/Mix/helpers/mixe";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import {
  debounce,
  formatErrorValidation
} from "@/plugins";
import ModalAdjustment from './Shared/_ModalAdjustment';

export default {
  name: 'Mixes',
  components: {
    SkeletonPuzl,
    ModalReference,
    SkeletonPuzlGrid,
    ModalGroup,
    ModalCreateMixe,
    ModalEditMixe,
    ModalAdjustment,
  },
  data() {
    return {
      has_invalid_params: [],
      filter: {
        active: 0,
      },
      format_mixe: period_types,
      return_to_percentage: value_z_to_percentage,
      nextHeader: nextHeader,
      category_calculable: {
        5: "kg/m³",
        99: "kg/m³",
        4: "% (S.P.A)"
      },
      mask: mask1,
      loading_all_reference: false,
      oldMixes: {},
      oldStatus: {},
      mixSend: {},
      disabled_adjustment_cmc: [],
      showSave: [],
      loading: [],
      status: true,
      MixDesignMixes: [],
      loadingSkeletonMixDesign: true,
      loadingSkeletonMixDesignMixe: true,
      selected: null,
    }
  },
  computed: {
    ...mapGetters({
      'reliability_levels': 'reliability_level/fetch',
      'mix_design': 'MixDesigns/show',
      $_groups_without_references: 'MixDesignMixe/fetchWithoutReferences',
    }),
    mix_designs_mixes() {
      return !this.filter.active ? this.$store.getters['MixDesignMixe/fetch'] : this.$store.getters['MixDesignMixe/activeItems']
    },
    mixe() {
      return {
        technical_parameter: {},
      }
    },
    doesntHasLargeAggregate() {
      return this.mix_design.groups && this.mix_design.groups.findIndex(item => item.id === 3) === -1
    }
  },
  methods: {
    setObservation: debounce(function (item) {
      let params = {
        observation: item.observation,
        id: item.id
      }
      let loader = this.$loading.show()
      this.$store.dispatch("MixDesignMixe/postSetObservation", params).then(response => {
        loader.hide()
        this.$notify({type: 'success', message: response.message});
      }).catch(() => loader.hide());
    }, 500),
    async handleClickFilterActiveItems() {
      let loader = this.$loading.show()
      this.filter.active = Number(!this.filter.active)
      await this.initialParams()
      this.MixDesignMixes = await this.mix_designs_mixes.sort(function (a, b) {
        return a.technical_parameter.binder - b.technical_parameter.binder;
      })
      loader.hide()
    },
    copy(val) {
      this.$helper.copy(val)
      this.$notify({type: 'success', message: 'Código do traço copiado com sucesso!'})
    },
    handleAdjustment() {
      let loader = this.$loading.show()
      this.$store.dispatch('MixDesigns/show', this.$route.params.mix_design_uuid)
        .then((response) => {
          loader.hide()
          this.selected = response.data
          this.$refs.ModalAdjustmentMixDesigns.handleAdjustmentModal(this.selected)
        })
    },
    sortGroups(groups) {
      return Object.values(groups).sort(function (a, b) {
        return a.category_id - b.category_id;
      });
    },
    setTArg(event, mixeId) {
      const value = mask1(event.target.value, 1, '.', true)
      if (value > 100) {
        this.$forceUpdate()
        return this.mixe.technical_parameter[mixeId].content_mortar
      }
      this.$forceUpdate()
      return event.target.value
    },
    handleMask(categoryId) {
      if (categoryId == 1) {
        return ['##.#', '##', '###.#']
      }
      if (categoryId == 5) {
        return ['#.##', '##.##', '###.##']
      }
      if (categoryId == 4) {
        return ['#.##', '##.##']
      }
      return '###'
    },
    change(event, options, value, index, categoryIndex, mixeIndex) {
      // se a categoria nao houver necessidade de calculo, retorna o valor digitado
      if (event.target.value == "00") {
        return event.target.value = "0"
      }
      event.target.value = event.target.value.replace('-', '')
      event.target.value = checkNumberValue(event.target.value)
      if (!this.mix_designs_mixes[mixeIndex]['groups_categories'][categoryIndex].calculable) {
        return checkNumberValue(mask1(event.target.value, 2, '.', true));
      }
      if (this.mix_designs_mixes[mixeIndex]['groups_categories'][categoryIndex].calculable &&
        this.mix_designs_mixes[mixeIndex]['groups_categories'][categoryIndex].category_id != 1) {
        event.target.value = checkNumberValue(mask1(event.target.value, 0, '.', true))
      }
      let first = 100

      if (this.mix_designs_mixes[mixeIndex]['groups_categories'][categoryIndex].category_id == 1) {
        first = "100.0";
        event.target.value = this.mask(event.target.value, 1)
      }

      options[index].value = event.target.value
      for (let index in options) {
        if (index === Object.keys(options)[0]) {
          //  verifico se o indice percorrido é a primeira posicao, se for, é ignorado
          continue
        }
        // realizo a recontagem conforme o valor informado
        if (this.mix_designs_mixes[mixeIndex]['groups_categories'][categoryIndex].category_id == 1) {
          if (options[index].value.length <= 3) {
            if (options[index].value.charAt(options[index].value.length - 1) == '.') {
              options[index].value = options[index].value.replace('.', '')
            }
            first -= options[index].value
          } else {
            first -= options[index].value
          }
        } else {
          first -= options[index].value
        }
      }
      if (first.toString().length > 6) {

        const round = (number, decimalPlaces) => {
          const factorOfTen = Math.pow(10, decimalPlaces)
          return Math.round(number * factorOfTen) / factorOfTen
        }

        first = round(first, 1)
      }
      if (this.mix_designs_mixes[mixeIndex]['groups_categories'][categoryIndex].category_id == 1) {
        if (first == "100") {
          first = "100.0"
        }
        if (first.toString().length >= 3) {
          first = this.mask(first, 1)
        }
        if (first.toString().length == 2) {
          first = first + ".0"
        }
      }
      // caso o valor da primeira posicao seja menor que 0, nao é permitido alterar o valor
      if (first < 0) {
        this.$swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Soma dos teores de mesma categoria não pode ser maior que 100%!',
        })
        return value
      }

      // define o valor da primeira posicao conforme o calculo de 100 menos os demais
      this.mix_designs_mixes[mixeIndex]['groups_categories'][categoryIndex]['options'][0].value = first

      this.$forceUpdate()

      return event.target.value
    },
    fetchGroup(params) {
      this.fetch()
      this.getWithoutReference()
      this.loadingSkeletonMixDesign = true
      this.$store.dispatch('MixDesigns/show', this.$route.params.mix_design_uuid)
        .then(() => this.loadingSkeletonMixDesign = false)
      this.mix_design.has_ag = params.has_ag
    },
    handleValidateCalculable(index, category) {
      if (category.category_id == 99) {
        return true
      }
      // VALIDA SE É CALCULAVEL, SE FOR, REMOVE O PRIMEIRO INPUT
      if (category.calculable) {
        if (category.options[0].id === index) {
          return true
        }
      }
      return false
    },
    handleSetGroups(mix_design) {
      this.$refs.createGroupMix.handleCreate(mix_design)
    },
    handleCreateMixe() {
      this.$refs.createMixe.handleCreateMixe(this.mix_design)
    },
    handleEditMixe(itemMixe) {
      for (let index in itemMixe.items) {
        delete itemMixe.items[index]['binder']
        itemMixe.items[index] = Object.values(itemMixe.items[index])
      }
      this.$refs.editMixe.handleEditMixe(itemMixe, this.mix_design)
    },
    setStatus(idMixe, index) {
      let mixe = this.mix_designs_mixes.filter(function (mixe) {
        return mixe.id === idMixe;
      });

      if (!this.oldStatus[index]) {
        this.oldStatus[index] = mixe[0].status
      }
      mixe[0].status = 3;
    },
    changeWater: debounce(function (idMixe, index) {
      this.setEditing(idMixe, index)
      let water_binder;
      let binder = parseInt(this.mixe.technical_parameter[idMixe].binder);
      let water = parseInt(this.mixe.technical_parameter[idMixe].water);
      water_binder = parseFloat(water / binder).toFixed(3);
      if (water && binder) {
        this.mixe.technical_parameter[idMixe].water_binder = parseFloat(water_binder);
        this.$forceUpdate();
      }
    }, 500),
    changeWaterBinder: debounce(function (idMixe, index) {
      this.setEditing(idMixe, index)
      let binder;
      let water_binder = parseFloat(this.mixe.technical_parameter[idMixe].water_binder);
      let water = parseInt(this.mixe.technical_parameter[idMixe].water);
      binder = Math.round(water / water_binder);
      if (water) {
        this.mixe.technical_parameter[idMixe].binder = binder;
        this.$forceUpdate();
      }
    }, 50),
    setEditing(idMixe, index) {
      this.setStatus(idMixe, index);
      this.showSave[idMixe] = true;
      this.$forceUpdate()
    },
    unsetEditing(idMixe, index, without_clear_group = false) {
      this.init(index)
      this.mix_designs_mixes[index].status = this.oldStatus[index]
      if (!without_clear_group) {
        const copy = JSON.parse(JSON.stringify(this.mix_designs_mixes[index].old_groups));
        this.mix_designs_mixes[index].groups_categories = copy
      }
      this.showSave[idMixe] = false;
      this.$forceUpdate()
    },
    validateBetweenValues(val, min, max) {
      if (val < min || val > max) {
        return true
      } else {
        return false
      }
    },
    validateBetweenValuesByCategory(val, category_id) {
      if (![4, 5].includes(category_id)) {
        return false
      }
      if (category_id === 4) {
        if (val < 0.20 || val > 1.50) {
          return true
        } else {
          return false
        }
      }
      if (category_id === 5) {
        if (val > 9.99) {
          return true
        } else {
          return false
        }
      }
    },
    storeMixe(mixeId, mixeUuid, index) {
      let mixe = this.mix_designs_mixes.filter(function (mixe) {
        return mixe.id === mixeId;
      });
      this.mixSend = {
        groups_categories: {...mixe[0].groups_categories},
        mix_designs_mixe_id: mixeId,
        mix_designs_mixe_uuid: mixeUuid,
        technical_parameter: this.mixe.technical_parameter[mixeId],
      };
      for (let key in this.mixSend.technical_parameter) {
        if (!this.mixSend.technical_parameter[key]) {
          return this.$notify({
            type: 'danger',
            message: 'Todos os parametros devem ser preenchidos para continuar!'
          });
        }
      }
      for (let key in this.mixSend.groups_categories) {
        const group = this.mixSend.groups_categories[key]
        for (let index in group.options) {
          const str = group.options[index].value.toString()
          if (str.charAt(str.length - 1) == '.') {
            this.mixSend.groups_categories[key].options[index].value = str.substr(0, str.length - 1)
          }
        }
      }
      this.showSave[mixeId] = true;
      this.$Progress.start();
      this.loading[mixeId] = true;
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      });

      this.$axios.post("/mix/mixe/manager/+microservice", this.mixSend)
        .then(({
                 data
               }) => {
          const message = data.exception || data.message
          this.$notify({
            type: data.error_type,
            message: message
          });
          this.$Progress.finish();
          this.loading[mixeId] = false;
          this.mix_designs_mixes[index].technical_parameter = data.data.technical_parameter
          const copy = JSON.parse(JSON.stringify(this.mix_designs_mixes[index].groups_categories));
          this.mix_designs_mixes[index].old_groups = copy
          this.unsetEditing(mixeId, index, true)
          this.MixDesignMixes = this.mix_designs_mixes.sort(function (a, b) {
            return a.technical_parameter.binder - b.technical_parameter.binder;
          })
          // document.getElementById("collapse").getElementsByClassName("card-header")[0].click()
          this.$forceUpdate()
        })
        .catch((error) => {
          if (error.data.confirm) {
            this.$refs.modalReference.handleCreate(2);
          }
          const message = error.data.exception || error.data.message
          this.$notify({
            type: error.data.error_type,
            message: message
          });
          this.loading[mixeId] = false;
          this.$Progress.finish()
        });
    },
    setGroupModalReference(group) {
      this.$refs.modalReference.handleCreate(2, group.id, this.mix_design.plant.id);
    },
    initialParams() {
      let mixes = this.mix_designs_mixes;
      for (let count = 0; count < mixes.length; count++) {
        this.init(count)
      }
      this.$forceUpdate();
    },
    init(index) {
      let mixes = this.mix_designs_mixes;
      if (mixes[index].technical_parameter) {
        this.mixe.technical_parameter[mixes[index].id] = {
          binder: mixes[index].technical_parameter.binder,
          water: mixes[index].technical_parameter.water,
          water_binder: mixes[index].technical_parameter.water_binder.toFixed(3),
          content_mortar: this.mix_design.has_ag ? mixes[index].technical_parameter.content_mortar.toFixed(1) : "100.0",
          total_air: mixes[index].technical_parameter.total_air.toFixed(1),
        };
        mixes[index].groups_categories = this.sortGroups(mixes[index].groups_categories)
      }
    },
    fetch() {
      this.loadingSkeletonMixDesignMixe = true
      this.$Progress.start()
      this.$store.dispatch('MixDesignMixe/fetchMixesByMixDesignUuid', this.$route.params.mix_design_uuid)
        .then((response) => {
          this.initialParams()
          this.loadingSkeletonMixDesignMixe = false
          this.$Progress.finish()
          this.MixDesignMixes = this.mix_designs_mixes.sort(function (a, b) {
            return a.technical_parameter.binder - b.technical_parameter.binder;
          })
        })
    },
    handleReplicate(uuid) {
      this.$Progress.start();
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      let loader = this.$loading.show()
      this.$store.dispatch('MixDesignMixe/replicate', uuid).then((response) => {
        this.initialParams()
        this.MixDesignMixes = this.mix_designs_mixes.sort(function (a, b) {
          return a.technical_parameter.binder - b.technical_parameter.binder;
        })
        this.$notify({
          type: 'success',
          message: response.message
        });
        this.init()
        loader.hide()
        this.$Progress.finish()
      }).catch(error => {
        loader.hide()
        this.$Progress.finish()
      })
    },
    saveAndActivate(mixMixe) {
      this.$store.dispatch('MixDesignMixe/saveAndActivate', mixMixe)
        .then(response => {
          this.fetch()
          this.loadingSave = false
          this.$Progress.finish()
          this.$notify({
            type: response.error_type,
            message: response.message
          })
          this.init()
          this.$emit('submit')
        }).catch(error => {
        this.$notify({
          type: error.data.error_type,
          message: error.data.message
        })
        this.$Progress.finish()
        this.loadingSave = false
      })
    },
    handleActivateMixe(mixe) {
      for (let key in mixe.items) {
        for (let item in mixe.items[key]) {
          if (item == "binder") {
            continue
          }
          if (!mixe.items[key][item].item_description) {
            return this.$notify({
              type: 'danger',
              message: 'Todos os itens de responsabilidade devem ser definidos para continuar!'
            });
          }
        }

      }
      for (let key in mixe.technical_parameter) {
        if (!mixe.technical_parameter[key]) {
          return this.$notify({
            type: 'danger',
            message: 'Todos os parametros devem ser preenchidos para continuar!'
          });
        }
      }
      let mixMixe = {
        ...mixe
      }
      mixMixe.status = 1
      mixMixe.save_and_activate = true
      mixMixe.items = mixMixe.items[mixMixe.id]

      delete mixMixe.items.binder

      for (let index in mixMixe.items) {
        mixMixe.items[index]['feature_id'] = mixMixe.items[index]['mix_feature_id']
      }

      this.$Progress.start();
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      });
      this.$store.dispatch('MixDesignMixe/update', mixMixe)
        .then(response => {
          this.$notify({
            type: 'success',
            message: response.message
          });
        }).catch(error => {
        if (error.data.confirm) {
          Swal.fire({
            title: error.data.message,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, substituir!',
            cancelButtonText: 'Não, mantenha',
            customClass: {
              confirmButton: 'btn btn-success btn-fill',
              cancelButton: 'btn btn-danger btn-fill',
            },
            buttonsStyling: false,
          }).then(response => {
            if (response.isConfirmed) {
              this.saveAndActivate(mixMixe)
            } else {
              this.$Progress.finish()
            }
          })
        } else {
          this.$notify({
            type: error.data.error_type,
            message: error.data.message
          })
          this.$Progress.finish()
        }
      })
    },
    handleReference(type) {
      this.$refs.modalReference.handleCreate(type);
    },
    getWithoutReference() {
      this.loading_all_reference = true
      this.$store.dispatch('MixDesignMixe/fetchGroupsWithoutReference',
        this.$route.params.mix_design_uuid).then(() => {
        this.loading_all_reference = false
      })
    },
    handleDeleteMixDesignMixe(uuid) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$notify({
            type: 'info',
            message: 'Estamos trabalhando em sua solicitação.'
          });
          this.$Progress.start();
          this.$store.dispatch('MixDesignMixe/destroy', uuid).then((response) => {
            this.$Progress.finish()
            this.$notify({
              type: response.error_type,
              message: response.message
            })
          }).catch((error) => {
            if (error.status === 422) {
              let message = formatErrorValidation(error.response.data.errors)
              this.$notify({
                type: 'danger',
                message: message
              })
            } else {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              })
            }
          })
          this.$Progress.finish();
        }
      }).catch((error) => {
        this.$Progress.finish()
      })
    },
    expandAllParameters() {
      for (let i = 0; i < this.mix_designs_mixes.length; i++) {
        document.getElementsByClassName("collapse-parameters")[i].click();
      }
    },
    expandAllContents() {
      for (let i = 0; i < this.mix_designs_mixes.length; i++) {
        document.getElementsByClassName("collapse-contents")[i].click();
      }
    },
  },
  created() {
  },
  mounted() {
    this.$store.dispatch('reliability_level/fetchItems')
    this.fetch()
    this.getWithoutReference()
    this.$store.dispatch('MixDesigns/show', this.$route.params.mix_design_uuid)
      .then(() => {
        this.loadingSkeletonMixDesign = false
        this.initialParams()
      })
  },
  beforeRouteLeave(to, from, next) {
    let cardsInEdit = this.showSave.filter(element => {
      return element === true;
    });
    if (cardsInEdit.length > 0) {
      this.$swal.fire({
        title: 'Sair da tela ?',
        text: 'Atenção! Existem traços com alterações que não foram salvas.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          next();
        }
      });
    } else {
      next();
    }
  },
};
</script>
<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
