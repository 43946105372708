<template>
  <div>
    <modal size="lg" :show.sync="modal.create">
      <template class="align-middle" slot="header">
        <h5 class="modal-title pl-3 align-middle">{{ modal.title }}</h5>
      </template>
      <div>
        <form @submit.prevent="store" autocomplete="off">
          <div class="form-group row m-0 p-0">
            <base-button @click.prevent="newSlot" type="success" class="text-uppercase">
              <i class="fas fa-plus"></i> novo
            </base-button>
          </div>            
          <div class="row mt-3">
            <div class="col-md-12">
              <div class="table-responsive" style="overflow-y: scroll; height:400px;">
                <table class="table table-sm table-bordered">
                  <thead>
                    <tr class="fixed">
                      <th class="text-center">
                        Descrição <span class="text-danger">*</span>
                      </th>
                      <th class="text-center">
                        PV <span class="text-danger">*</span>
                      </th>
                      <th class="text-center">
                        Token <span class="text-danger">*</span>
                      </th>
                      <th class="text-center">
                        Vendedor
                      </th>
                      <th class="text-center">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in payment_intermediary_merchants">
                      <td class="text-left">
                        <input 
                          type="text" 
                          class="form-control form-control-sm" 
                          v-model="item.description"
                        />
                      </td>
                      <td class="text-left">
                        <input 
                          type="text" 
                          class="form-control form-control-sm" 
                          v-model="item.code"
                          :disabled="item.id"
                        />
                      </td>
                      <td class="text-left">
                        <input 
                          type="text" 
                          class="form-control form-control-sm" 
                          v-model="item.token"
                        />
                      </td>
                      <td style="min-width:200px" class="text-left">
                        <puzl-select :multiple="true" v-model="item.users_id" :items="$_sellers" />
                      </td>
                      <td class="text-left">
                        <base-switch v-model="item.status" type="success" offText="inativo" onText="ativo" class="success"></base-switch>
                      </td>
                      <td class="text-left">
                        <a v-if="!item.id" class="dropdown-item" @click="removeSlot(item)">
                          <i class="fas fa-times text-danger main-icon-menu"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <base-button type="secondary" @click="closeModal()">
              Cancelar
            </base-button>
            <base-button :disabled="!hasAtLeastOneSlotFilled()" type="success" native-type="submit" :loading="loadingStore">
              Salvar
            </base-button>
          </div>
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import { formatErrorValidation } from "@/plugins";
import PuzlSelect from "@/components/PuzlSelect.vue";
import { mapGetters } from "vuex";

export default {
  name: "ModalMerchantsCreateOrEdit",
  components: {
    PuzlSelect
  },
  data() {
    return {
      modal: {
        title: "Pontos de Venda",
        create: false,
      },
      loadingStore: false,
      payment_intermediary_id: null,
      payment_intermediary_merchants: [],
    };
  },
  computed: {
    ...mapGetters({
      $_sellers: "user/fetch"
    })
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal(id) {
      this.payment_intermediary_id = id;
      const params = {filter: {payment_intermediary_id: id, unallocated_by_user: 1}};
      let loader = this.$loading.show()
      this.loadingStore = true;
      this.$store
        .dispatch("paymentIntermediaryMerchant/fetchItems", params)
        .then(response => {
          this.payment_intermediary_merchants = this.$helper.cloneObject(response.data);
          this.modal.create = true;
          this.loadingStore = false;
          loader.hide();
        });
    },
    /**
     * Incluir um novo slot em branco para inserir dados do ponto de venda
     * @retuns void
     */
    newSlot(){
      // Não permitir novo slot se ultimo não estiver preenchido
      if (this.payment_intermediary_merchants.length > 0) {
        const lastSlot = this.payment_intermediary_merchants[0];
        const lastSlotIsFilled = lastSlot.code.trim() && lastSlot.token.trim();        
        if (!lastSlotIsFilled) {
          this.$notify({ type: 'warning', message: 'O último slot deve ser preenchido com PV e Token.'})
          return;
        }
      }
      // Novo Slot
      this.payment_intermediary_merchants.unshift({code: "", token: "", status: true});
    },
    /**
     * Verifica se tem ao menos um slot preenchido
     * @returns boolean
     */
    hasAtLeastOneSlotFilled(){
      if (this.payment_intermediary_merchants.length > 0) {
        const lastSlot = this.payment_intermediary_merchants[0];
        return lastSlot.code.trim() && lastSlot.token.trim();        
      }      
    },
    store(){
      this.$Progress.start()
      this.loadingStore = true;
      const paymentIntermediaryMerchantsFiltered = this.payment_intermediary_merchants.filter(item => (item.code.trim() && item.token.trim()));
      const payload = {
        paymentIntermediaryId: this.payment_intermediary_id, 
        paymentIntermediaryMerchants: paymentIntermediaryMerchantsFiltered
      }
      let notifyParams = {};
      this.$store
        .dispatch("paymentIntermediaryMerchant/createOrUpdateMany", payload)
        .then(response => {
          notifyParams = {type: response.error_type, message: response.message}
          this.closeModal();
        }).catch(error => {
          notifyParams = {
            type: "danger", 
            message: error.status ? error.data.message : formatErrorValidation(error.response.data.errors)
          }
        }).finally(() => {
          this.$notify(notifyParams);
          this.loadingStore = false;
          this.$Progress.finish();
        });
    },
    removeSlot(item){
      this.payment_intermediary_merchants.splice(this.payment_intermediary_merchants.indexOf(item), 1);
    }   
  },
};
</script>
