<template>
  <div style="min-height: 800px; background-color: #ffff;">
    <div class="mb-4" style=" margin: 0 2rem;">
      <div class="row align-items-center justify-content-between align-items-center pb-2" style="padding-top: 1.3rem;">
        <div class="d-flex align-items-center">
          <img height="30px" src="/img/icons/cart-warning.png" class="mr-2" />
          <h2 style="font-size: 1.2rem !important;color: #F2B532;" class="nowrap mb-0 mt-1">
            Formulário de cotação
          </h2>
        </div>
        <div class="mb-2 mr-3 back-button">
          <BackButton />
        </div>
      </div>
      <hr class="gray-divider mt-3 mb-4">
      <div>
        <div style="font-size: 15px">
          <span style="font-size: 15px; font-weight: 600;">Requerimento:</span> {{ purchaseRequest.code }}
        </div>
        <div style="font-size: 15px; margin: 0.3rem 0;">
          <span style="font-size: 15px; font-weight: 600;">Solicitante:</span> {{ purchaseRequest.created_by_user_name
          }}
        </div>
        <div style="font-size: 15px">
          <span style="font-size: 15px; font-weight: 600;">Central De Faturamento:</span> {{
            purchaseRequest.input_company_plant_name }}
        </div>
      </div>
      <div class="items-conatiner w-100 mt-4">
        <div class="header-items-conatiner d-block d-md-flex">
          <div class="d-flex align-items-center">
            <img width="22" src="/img/icons/big-parcel-primary.png" class="mr-2" />
            <span style="font-weight: 600; font-size: 14px;">Itens</span>
          </div>
          <div class="d-block d-md-flex mt-2 mt-md-0">
            <div
              @click="handleShowModal({ ref: 'modalListItemsApproval', data: { ...purchaseRequest, can_approve: true } })"
              class="header-button text-nowrap mr-0 mr-md-3 mb-2 mb-md-0 font-weight-500" style="border-color:#CCCCCC">
              <img src="/img/icons/icons8/ios/menu-squared-2-dark.png" width="22" />
            </div>
            <div @click="handleShowModal({ ref: 'modalAddItems', data: null })"
              class="header-button mr-0 mr-md-3 mb-2 mb-md-0 text-nowrap px-3 px-md-4 font-weight-500"
              style="border-color: #149E57; color: #149E57">
              <img height="18" src="/img/icons/icons8/ios/add-success.png" class="mr-1" /> Incluir Item
            </div>
            <div @click="handleShowModal({ ref: 'modalAddEntity', data: null })"
              class="header-button text-nowrap  mb-2 mb-md-0  font-weight-500"
              style="border-color:#1A70B7; color: #1A70B7">
              <img height="16" src="/img/icons/icons8/ios/add-primary.png" class="mr-1" /> Incluir Fornecedor
            </div>
            <div @click="fetchEntityBudgets()"
              class="header-button text-nowrap ml-0 ml-md-3 px-3 px-md-4 font-weight-500" style="border-color:#CCCCCC">
              <img height="19" src="/img/icons/icons8/ios/synchronize.png" alt="synchronize" />
            </div>
          </div>
        </div>
        <!-- BODY -->
        <div class="body-items-conatiner d-flex align-items-end" style="overflow-y: scroll;">
          <div class="row d-inline-block">
            <div class="d-flex justify-content-between mb-2">
              <div class="header-send-button mr-2" style="border-color: #149E57; color: #149E57;">
                <img src="/img/icons/whatsapp--v1.png" width="18px" class="mr-2">
                Enviar para todos
              </div>
              <div class="header-send-button" style="border-color: #1A70B7; color: #1A70B7;">
                <img src="/img/icons/email-primary.png" width="18px" class="mr-2">
                Enviar para todos
              </div>
            </div>
            <div>
              <table class="table resume-table table-custom-shaded mb-2">
                <thead>
                  <tr>
                    <th class="text-left new-default-black-font">Descrição Do Produto</th>
                    <th class="text-left new-default-black-font">Marca</th>
                    <th class="text-left new-default-black-font">Qto</th>
                    <th class="text-left new-default-black-font">UN.</th>
                    <th class="text-left new-default-black-font">Última Compra</th>
                  </tr>
                </thead>
                <!-- ITENS DA REQUISIÇÃO -->
                <tbody>
                  <tr v-for="(item, index) in requestItems" :key="index">
                    <!-- Status -->
                    <td class="">{{ item.description }}</td>
                    <td class="">{{ item.brand }}</td>
                    <td class="">{{ item.quantity }}</td>
                    <td class="">{{ item.input_unit }}</td>
                    <td class="">
                      <span style="font-size: 14px !important; color: #2B2D32; font-weight: 500">
                        R$ {{ item.preco }}
                      </span>
                      <el-popover class="p-0" placement="right" trigger="click">
                        <div>
                          <div class="d-flex justify-content-between">
                            <div>
                              <img class="mr-2" src="/img/icons/icons8/ios/info-circle_warning.png" width="20">
                              <span class="font-weight-500">Última Compra</span>
                            </div>
                            <div class="d-flex align-items-center" style="font-size: 11px;">
                              <img src="/img/icons/calendar-black.png" width="15px" class="mr-2" />
                              {{ item.dataCompra }}
                            </div>
                          </div>
                        </div>
                        <div class="my-2" style="display: grid !important">
                          <span>{{ item.fornecedor }}</span>
                          <span>{{ item.cnpj }}</span>
                        </div>
                        <div class="d-flex justify-content-between">
                          <div style="display: grid !important">
                            <span style="font-size: 12px;" class="fs-1">Quantidade</span>
                            <span style="font-size: 18px; line-height: 12px;" class="font-weight-500">{{
                              item.quantidadeDetalhes }}</span>
                          </div>
                          <div style="display: grid !important">
                            <span style="font-size: 12px;" class="fs-1">Preço Unit.</span>
                            <span style="font-size: 18px; line-height: 12px;" class="font-weight-500">{{
                              item.precoDetalhes }}</span>
                          </div>
                        </div>
                        <base-button outline slot="reference" size="sm" type="secundary"
                          class="p-0 m-0 shadow-none text-indigo">
                          <img src="/img/icons/icons8/ios/info-circle_warning.png" width="20">
                        </base-button>
                      </el-popover>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-between p-1 px-3 font-weight-600"
                style="background-color: #c4e7d5; border-radius: 5px; box-shadow: 0px 4px 4px 0px #00000040;">
                <div>Total</div>
                <div>R$ 0</div>
              </div>
              <div class="d-flex justify-content-between p-1 px-3 font-weight-600 mt-1 mb-2"
                style="background-color: #c6dbed; border-radius: 5px; box-shadow: 0px 4px 4px 0px #00000040;">
                <div>Total Selecionado</div>
                <div>{{ sumTotalValueSellectedAllItemEntityBudget() | currency() }}</div>
              </div>
              <div class="mb-2"
                style="border: 0.5px solid #CCCCCC; border-radius: 5px;box-shadow: 0px 4px 4px 0px #00000040;">
                <div class="p-1 px-3">Desconto</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">Ipi</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">Icms</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3 font-weight-600">Valor Líquido</div>
              </div>
              <div class="mb-2"
                style="border: 0.5px solid #CCCCCC; border-radius: 5px;box-shadow: 0px 4px 4px 0px #00000040;">
                <div class="p-1 px-3">Condição do frete</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">Frete por conta do</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">Valor do frete</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">Transportadora</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">Contato da Transportadora</div>
              </div>
              <div class="mb-2"
                style="border: 0.5px solid #CCCCCC; border-radius: 5px;box-shadow: 0px 4px 4px 0px #00000040;">
                <div class="p-1 px-3 font-weight-600">Valor Final</div>
              </div>
              <div class="mb-2"
                style="border: 0.5px solid #CCCCCC; border-radius: 5px;box-shadow: 0px 4px 4px 0px #00000040;">
                <div class="p-1 px-3">Condição de pagamento</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">Prazo de entrega</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">Validade da cotação</div>
              </div>
              <div class="d-flex justify-content-center p-1 px-3 pointer" @click="createAllOrders()"
                style="background-color: #149E57; border-radius: 5px; color: #ffff; box-shadow: 0px 4px 4px 0px #00000040;">
                <img height="18px" src="/img/icons/cart.png" class="mr-2" />
                <div>Gerar Todas As Ordens De Compra</div>
              </div>
            </div>
          </div>
          <!-- FORNECEDORES -->
          <div class="row d-inline-block ml-4" v-for="(entityBudget, index) in purchaseEntitiesBudgets" :key="index">
            <div>
              <div class="w-100 p-2" style="border: 0.5px solid #DCDFE6; border-radius: 8px 8px 3px 3px;">
                <div class="mb-2"
                  style="max-width: 250px;overflow: hidden; text-overflow: ellipsis; text-wrap: nowrap;">
                  {{ entityBudget.entity_name }}
                </div>
                <div>{{ entityBudget.entity_document }}</div>
                <div class="d-flex" style="justify-content: space-between;">
                  <div class="d-flex">
                    <div class="pointer px-3 d-flex align-items-center mr-1"
                      @click="entityBudget.code ? copyEntitylink(entityBudget.code) : createAndCopyEntitylink(entityBudget)"
                      style="box-shadow: 0px 4px 4px 0px #00000040; border: 0.5px solid #DCDFE6; border-radius: 5px; padding: 0.15rem 0; font-size: 13px;">
                      <img class="mr-2" width="18"
                        :src="entityBudget.code ? '/img/icons/icons8/ios/copy-2_primary.png' : '/img/icons/copy-success.png'"
                        alt="copy-2" />
                      {{ entityBudget.code ? 'Copiar Link' : 'Criar Link' }}
                    </div>
                    <div v-if="entityBudget.code" class="pointer px-2 mr-1 d-flex align-items-center"
                      @click="regenerateAndCopyEntitylink(entityBudget)"
                      style="box-shadow: 0px 4px 4px 0px #00000040; border: 0.5px solid #DCDFE6; border-radius: 5px;">
                      <img height="19" src="/img/icons/icons8/ios/synchronize.png" alt="synchronize" />
                    </div>
                    <div v-if="entityBudget.code" class="pointer px-2 d-flex align-items-center"
                      @click="allowOrDenyEntityEdit(entityBudget)"
                      style="box-shadow: 0px 4px 4px 0px #00000040; border: 0.5px solid #DCDFE6; border-radius: 5px;">
                      <img width="18"
                        :src="entityBudget.entity_can_edit ? '/img/icons/unlock.png' : '/img/icons/icons8/ios/lock_danger.png'"
                        alt="copy-2" />
                    </div>
                  </div>
                  <div class="pointer px-3"
                    style="box-shadow: 0px 4px 4px 0px #00000040; border: 0.5px solid #DCDFE6; border-radius: 5px; padding: 0.15rem 0; font-size: 13px;">
                    <base-dropdown menuOnRight>
                      <div slot="title-container" class="dropdown-toggle rounded m-0">
                        <img width="22" src="/img/icons/icons8/ios/settings--v1_primary.png" />
                      </div>
                      <a @click="handleShowModal({ ref: 'modalEditEntity', data: entityBudget })"
                        class="dropdown-item font-weight-500 new-default-black-font"
                        style="align-items: center; display: flex">
                        <img width="18" src="https://img.icons8.com/ios/100/f2b532/create-new.png" alt="create-new" />
                        Editar
                      </a>
                      <div class="dropdown-divider p-0 m-0"></div>
                      <a @click="handleDelete(entityBudget)"
                        class="dropdown-item font-weight-500 new-default-black-font"
                        style="align-items: center; display: flex">
                        <img width="19" src="/img/icons/icons8/ios/delete--v1_danger.png" />
                        Remover
                      </a>
                    </base-dropdown>
                  </div>
                </div>
              </div>
              <table class="table table-custom-shaded mb-2">
                <thead>
                  <tr>
                    <th class="text-left new-default-black-font">PREÇO Unit.</th>
                    <th class="text-left new-default-black-font d-flex justify-content-between">
                      TOTAL
                      <img @click="selectOrRemoveAllByBudgetIndex(index)" class="mr-1 pointer" width="20"
                        src="/img/icons/icons8/ios/double-box-tick-grey.png" alt="copy-2" />
                    </th>
                  </tr>
                </thead>
                <!-- ITENS DOS FORNECEDORES -->
                <tbody>
                  <tr v-for="(item, itemIndex) in entityBudget.items" :key="itemIndex">
                    <td class="">
                      <img v-if="item.best_value && item.unit_value > 0 && purchaseEntitiesBudgets.length > 1"
                        class="mr-1" width="15" src="/img/icons/icons8/ios/arrow-down-success.png" alt="copy-2" />
                      <img v-if="!item.best_value && item.unit_value > 0 && purchaseEntitiesBudgets.length > 1"
                        class="mr-1" width="15" src="/img/icons/icons8/ios/arrow-up-danger.png" alt="copy-2" />
                      {{ item.unit_value | currency() }}
                    </td>
                    <td v-if="purchaseEntitiesBudgets[index].items[itemIndex].selected"
                      @click="removeItem(index, itemIndex)"
                      class="pointer font-weight-700 d-flex justify-content-between" style="color: #2B2D32;">
                      {{ item.unit_value * item.request_quantity | currency() }}
                      <img class="ml-1" width="22" src="/img/icons/icons8/ios/circled-tick-grey.png" alt="copy-2" />
                    </td>
                    <td v-else @click="selectItem(index, itemIndex)"
                      class="pointer font-weight-700 d-flex justify-content-between" style="color: #2B2D32;">
                      {{ item.unit_value * item.request_quantity | currency() }}
                      <img class="ml-1" width="22" src="/img/icons/circle.png" alt="copy-2" />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-between p-1 px-3 font-weight-500"
                style="background-color: #c4e7d5; border-radius: 5px; box-shadow: 0px 4px 4px 0px #00000040; color: #2B2D32">
                <div>{{ sumTotalValueItemEntityBudget(index) | currency() }}</div>
              </div>
              <div class="d-flex justify-content-between p-1 px-3 font-weight-500 mt-1 mb-2"
                style="background-color: #c6dbed; border-radius: 5px; box-shadow: 0px 4px 4px 0px #00000040; color: #2B2D32;">
                <div>{{ sumSelectedItemsEntityBudget(index) | currency() }}</div>
              </div>
              <div class="mb-2 font-weight-500"
                style="border: 0.5px solid #CCCCCC; border-radius: 5px; box-shadow: 0px 4px 4px 0px #00000040; background-color: #f2f2f2; color: #2B2D32">
                <div class="p-1 px-3">{{ entityBudget.discount | currency() }}</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">{{ entityBudget.ipi | currency() }}</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">{{ entityBudget.icms | currency() }}</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">{{ entityBudget.net_value | currency() }}</div>
              </div>
              <div class="mb-2 font-weight-500"
                style="border: 0.5px solid #CCCCCC; border-radius: 5px; box-shadow: 0px 4px 4px 0px #00000040; background-color: #f2f2f2; color: #2B2D32">
                <div class="p-1 px-3">{{ entityBudget.shipping_condition ? 'FOB' : 'CIF' }}</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3 text-nowrap">{{ entityBudget.freight_modality_name || 'N/A' }}</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">{{ entityBudget.delivery_cost | currency() }}</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">{{ entityBudget.delivery_company_name || 'N/A' }}</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">{{ entityBudget.delivery_contact || 'N/A' }}</div>
              </div>
              <div class="mb-2 font-weight-500"
                style="border: 0.5px solid #CCCCCC; border-radius: 5px; box-shadow: 0px 4px 4px 0px #00000040; background-color: #f2f2f2; color: #2B2D32">
                <div class="p-1 px-3">{{ entityBudget.final_value | currency() || 'R$ 0,00' }}</div>
              </div>
              <div class="mb-2 font-weight-500"
                style="border: 0.5px solid #CCCCCC; border-radius: 5px; box-shadow: 0px 4px 4px 0px #00000040; background-color: #f2f2f2; color: #2B2D32">
                <div class="p-1 px-3">{{ entityBudget.payment_method || 'N/A' }}</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">
                  <span v-if="entityBudget.delivery_deadline">{{ entityBudget.delivery_deadline | parseDate("DD MMM
                    YYYY") }}</span>
                  <span v-else>N/A</span>
                </div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">
                  <span v-if="entityBudget.quotation_validity">{{ entityBudget.quotation_validity | parseDate("DD MMM
                    YYYY") }}</span>
                  <span v-else>N/A</span>
                </div>
              </div>
              <div @click="createOrder(entityBudget, index)"
                class="d-flex justify-content-center p-1 px-5 pointer font-weight-500 text-nowrap"
                style="border: 0.5px solid #149E57; border-radius: 5px; color: #149E57; box-shadow: 0px 4px 4px 0px #00000040;">
                <img height="18px" src="/img/icons/icons8/ios/shopping-cart.png" class="mr-2" />
                <div>Gerar ordem de compra</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalListItemsApproval :showUpdateButton="true" @fetch="fetchEntityBudgets" ref="modalListItemsApproval" />
    <ModalAddItems @addProductService="addProductService" :itemType="purchaseRequest.product_service_hub_type_id"
      ref="modalAddItems" />
    <ModaEditEntityBudget @fetchEntityBudgets="fetchEntityBudgets()" @addProductService="addProductService"
      ref="modalEditEntity" />
    <ModalAddEntity @selected="addEntityBudget" ref="modalAddEntity" />
  </div>
</template>

<script>
import BackButton from '@/components/Utils/BackButton';
import ModalAddItems from "../../Request/Shared/Shared/_ModalAddItems.vue";
import ModalAddEntity from "./_ModalAddEntity.vue";
import ModaEditEntityBudget from "./Shared/_ModaEditEntityBudget.vue";
import ModalListItemsApproval from "../../Request/Shared/Shared/_ModalListItemsApproval.vue";
import { v1 as uuidv1 } from 'uuid';

export default {
  components: {
    BackButton,
    ModalAddItems,
    ModalAddEntity,
    ModaEditEntityBudget,
    ModalListItemsApproval
  },
  data() {
    return {
      filter: {
        order_by: [
          {
            column: 'purchase_request_items.id',
          }
        ],
        purchase_request_id: this.$route.params.id,
      },
      loadingSkeleton: false,
      requestType: 'document',
      requestItems: [],
      purchaseRequest: null,
      purchaseEntitiesBudgets: [],
    }
  },
  methods: {
    getPurchaseRequest() {
      let loader = this.$loading.show();
      this.$store.dispatch('purchaseRequest/show', this.$route.params.id)
        .then((response) => {
          this.purchaseRequest = response.data;
          this.fetchItems();
          this.fetchEntityBudgets();
        }).finally(() => {
          this.loadingSkeleton = false;
          loader.hide()
        });
    },
    fetchItems() {
      let loader = this.$loading.show();
      this.filter.status = 1;
      this.$store.dispatch('purchaseRequest/fetchRequestItems', this.filter)
        .then((response) => {
          this.requestItems = response.data.items;
        }).finally(() => {
          this.loading = false;
          this.modal = true
          loader.hide()
        })
    },
    fetchEntityBudgets() {
      this.fetchItems();
      let loader = this.$loading.show();
      this.$store.dispatch('purchaseBudget/fetchEntityBudgets',
        { order_by: [{ column: 'purchase_entity_budgets.id' }], purchase_budget_id: this.purchaseRequest.purchase_budget_id })
        .then((response) => {
          this.purchaseEntitiesBudgets = response.data.items;
          if (this.purchaseEntitiesBudgets.length > 1) {
            this.setBestValueItem();
          }
          this.updateEntityBudgetItems(this.requestItems, this.purchaseEntitiesBudgets);
        }).finally(() => {
          this.loading = false;
          this.modal = true
          loader.hide()
        })
    },
    updateEntityBudgetItems(itens, budgets) {
      const itemIds = itens.map(item => item.id);

      // Atualiza o array 'items' de cada cotação
      budgets.forEach(budgetItem => {
        // Mantém apenas os itens que existem no array 'itens'
        budgetItem.items = budgetItem.items.filter(budgetEntityItem => {
          return itemIds.includes(parseInt(budgetEntityItem.purchase_request_item_id));
        });
        // Adiciona novos itens que estão em 'itens' mas não no cotação
        itens.forEach(item => {
          const itemExists = budgetItem.items.some(budgetEntityItem =>
            parseInt(budgetEntityItem.purchase_request_item_id) === item.id
          );

          if (!itemExists) {
            budgetItem.items.push({
              id: item.id,
              purchase_entity_budget_id: budgetItem.id,
              purchase_request_item_id: item.id,
              quantity: item.quantity.toFixed(3),
              selected: 0,
              unit_value: "0.00", // valor inicial
              description: item.description,
              input_unit: item.input_unit,
              request_quantity: item.quantity.toFixed(3),
              best_value: false
            });
          }
        });
      });

      this.purchaseEntitiesBudgets = budgets;
    },
    handleShowModal({ ref, data }) {
      this.$refs[ref].handleCreateModal(data);
    },
    addProductService(data) {
      const index = this.requestItems.findIndex(item => item.product_service_id === data.product_service_id);
      // Se o item ja existe apenas atualiza
      if (index !== -1) {
        this.requestItems[index].quantity = data.quantity;
        this.requestItems[index].brand = data.brand || '';
        this.requestItems[index].status = data.status;
      } else {
        data.status = 0;
        this.requestItems.push(data);
      }
      this.save();
    },
    save() {
      let loader = this.$loading.show()
      this.purchaseRequest.items = this.requestItems
      this.$store.dispatch('purchaseRequest/update', this.purchaseRequest)
        .then(() => {
          this.fetchEntityBudgets();
          loader.hide();
          this.$notify({ type: 'success', message: 'Item adicionado com sucesso!' });
        });
    },
    // Entity links
    createAndCopyEntitylink(data) {
      let loader = this.$loading.show();
      const code = uuidv1({ msecs: Date.now() });
      data.code = code;
      data.entity_can_edit = true;
      this.$store.dispatch('purchaseBudget/update', data)
        .then((response) => {
          loader.hide();
          this.$helper.copy(`${window.location.origin}/entity-login/${response.data.code}`);
          this.$notify({ type: 'success', message: 'Link copiado com sucesso!' });
        })
        .catch(() => {
          loader.hide();
          this.$notify({ type: 'danger', message: 'Erro ao salvar alteração!' });
        });
    },
    copyEntitylink(code) {
      this.$helper.copy(`${window.location.origin}/entity-login/${code}`);
      this.$notify({ type: 'success', message: 'Link copiado com sucesso!' })
    },
    regenerateAndCopyEntitylink(data) {
      this.$Swal.confirmAction('Deseja realmente renovar o link de acesso?', ['Sim', 'Não']).then((result) => {
        if (result.isConfirmed) {
          this.createAndCopyEntitylink(data)
        }
      }).catch(() => this.$Progress.finish())
    },
    allowOrDenyEntityEdit(entityBudget) {
      let loader = this.$loading.show()
      entityBudget.entity_can_edit = !entityBudget.entity_can_edit
      this.$store.dispatch('purchaseBudget/update', entityBudget)
        .then(() => {
          loader.hide();
          this.$notify({ type: 'success', message: 'Alteração salva com sucesso!' });
        })
    },
    addEntityBudget(entity) {
      // Verifica se a entidade já existe em purchaseEntitiesBudgets
      const entityAlreadyExists = this.purchaseEntitiesBudgets.some(budget => budget.entity_id === entity.id);

      if (entityAlreadyExists) {
        this.$notify({ type: 'warning', message: 'Esse fornecedor já existe nessa cotação!' });
        return;
      }

      const entityBudget = {
        purchase_budget_id: this.purchaseRequest.purchase_budget_id,
        entity_id: entity.id,
        items: this.requestItems.map(item => ({ ...item, purchase_request_item_id: item.id }))
      };

      let loader = this.$loading.show();

      this.$store.dispatch('purchaseBudget/addEntityBudget', entityBudget)
        .then(() => {
          this.fetchEntityBudgets();
          loader.hide();
          this.$notify({ type: 'success', message: 'Fornecedor adicionado com sucesso!' });
        });
    },
    selectItem(budgetIndex, itemIndex) {
      const selectedPurchaseRequestItemId = this.purchaseEntitiesBudgets[budgetIndex].items[itemIndex].purchase_request_item_id;
      this.purchaseEntitiesBudgets.forEach((budget, bIndex) => {
        budget.items.forEach((item, iIndex) => {
          if (item.purchase_request_item_id === selectedPurchaseRequestItemId) {
            this.$set(this.purchaseEntitiesBudgets[bIndex].items, iIndex, {
              ...item,
              selected: false
            });
          }
        });
      });

      // Alternar o estado de selected do item atual
      const currentItem = this.purchaseEntitiesBudgets[budgetIndex].items[itemIndex];
      this.$set(this.purchaseEntitiesBudgets[budgetIndex].items, itemIndex, {
        ...currentItem,
        selected: !currentItem.selected
      });
    },
    removeItem(budgetIndex, itemIndex) {
      this.$set(this.purchaseEntitiesBudgets[budgetIndex].items, itemIndex, {
        ...this.purchaseEntitiesBudgets[budgetIndex].items[itemIndex],
        selected: false
      });
    },
    sumTotalValueItemEntityBudget(budgetIndex) {
      let total = 0;
      this.purchaseEntitiesBudgets[budgetIndex].items.forEach((item) => {
        total += item.unit_value * item.request_quantity;
      })
      return total
    },
    sumSelectedItemsEntityBudget(budgetIndex) {
      let total = 0;
      this.purchaseEntitiesBudgets[budgetIndex].items.forEach((item) => {
        if (item.selected) {
          total += item.unit_value * item.request_quantity;
        }
      });
      return total
    },
    sumTotalValueSellectedAllItemEntityBudget() {
      let total = 0;
      this.purchaseEntitiesBudgets.forEach((budget) => {
        budget.items.forEach((item) => {
          if (item.selected) {
            total += item.unit_value * item.request_quantity;
          }
        });
      });
      return total
    },
    selectOrRemoveAllByBudgetIndex(budgetIndex) {
      // Verifica se todos os itens no cotação especificado estão selecionados
      const areAllSelected = this.purchaseEntitiesBudgets[budgetIndex].items.every(item => item.selected);
      // Define o novo estado baseado no estado atual
      const newState = !areAllSelected;
      // Desmarcar todos os itens em todos as cotações
      this.purchaseEntitiesBudgets.forEach((budget, bIndex) => {
        budget.items.forEach((item, itemIndex) => {
          this.$set(this.purchaseEntitiesBudgets[bIndex].items, itemIndex, {
            ...item,
            selected: false
          });
        });
      });
      // Definir todos os itens para o novo estado no cotação especificado
      this.purchaseEntitiesBudgets[budgetIndex].items.forEach((item, index) => {
        this.$set(this.purchaseEntitiesBudgets[budgetIndex].items, index, {
          ...item,
          selected: newState
        });
      });
    },
    handleDelete(entityBudget) {
      // if (entityBudget.has_purchase_order) {
      //   this.$notify({ type: 'warning', message: 'Cotação possui Ordem de Compra e não pode ser excluída' });
      //   return
      // }
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          let loader = this.$loading.show()
          this.$store.dispatch('purchaseBudget/remove', entityBudget.id).then((response) => {
            this.fetchEntityBudgets();
            loader.hide()
            this.$notify({
              type: response.error_type,
              message: response.message
            });
          })
        }
      }).catch(() => this.$Progress.finish())
    },
    // Função que define o item com o menor valor unitário
    setBestValueItem() {
      const minValues = {};
      this.purchaseEntitiesBudgets.forEach(budget => {
        budget.items.forEach(item => {
          const id = item.purchase_request_item_id;
          const unitValue = parseFloat(item.unit_value);
          if (!minValues[id] || unitValue < minValues[id]) {
            minValues[id] = unitValue;
          }
        });
      });
      this.purchaseEntitiesBudgets.forEach(budget => {
        budget.items.forEach(item => {
          const id = item.purchase_request_item_id;
          const unitValue = parseFloat(item.unit_value);
          item.best_value = unitValue === minValues[id];
        });
      });
    },
    createOrder(entityBudget, index) {
      this.$swal
        .fire({
          title: "Você tem certeza?",
          text: "Tem certeza que deseja criar essa ordem?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Continuar!",
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          buttonsStyling: false,
        })
        .then(result => {
          if (result.isConfirmed) {
            // if (entityBudget.has_purchase_order) {
            //   this.$notify({ type: 'warning', message: 'Cotação possui Ordem de Compra e não pode ser alterada' });
            //   return
            // }
            // Veirifica se pelo menos um item foi selecionado
            const selectedItems = entityBudget.items
              .filter(item => item.selected)
              .map(item => ({ entity_budget_item_id: item.id }));
            if (selectedItems.length === 0) {
              this.$notify({ type: 'warning', message: 'Selecione pelo menos um item' });
              return;
            }
            let loader = this.$loading.show()
            // Cria a ordem de compra
            this.$store.dispatch('purchaseOrder/add', {
              purchase_budget_id: entityBudget.purchase_budget_id,
              purchase_entity_budget_id: entityBudget.id,
              total_value: this.sumSelectedItemsEntityBudget(index),
              items: selectedItems
            })
              .then(() => {
                loader.hide();
                this.$notify({ type: 'success', message: 'Ordem de compra criada com sucesso' });
              })
              .catch(() => {
                loader.hide();
                this.$notify({ type: 'error', message: 'Erro ao criar a ordem de compra' });
              });
          }
        });
    },
    createAllOrders() {
      // Confirmação antes de criar as ordens de compra em massa
      this.$swal
        .fire({
          title: "Você tem certeza?",
          text: "Deseja criar ordens de compra para todas as cotações selecionadas?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Continuar!",
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          buttonsStyling: false,
        })
        .then((result) => {
          if (result.isConfirmed) {
            let loader = this.$loading.show();

            const purchaseOrders = this.purchaseEntitiesBudgets.map((entityBudget, index) => {
              const selectedItems = entityBudget.items
                .filter((item) => item.selected)
                .map((item) => ({ entity_budget_item_id: item.id }));

              if (selectedItems.length > 0) {
                return {
                  purchase_budget_id: entityBudget.purchase_budget_id,
                  purchase_entity_budget_id: entityBudget.id,
                  total_value: this.sumSelectedItemsEntityBudget(index),
                  items: selectedItems
                };
              }
            }).filter(Boolean);
            if (purchaseOrders.length === 0) {
              loader.hide();
              this.$notify({ type: 'warning', message: 'Selecione ao menos um item para criar ordens de compra' });
              return;
            }
            this.$store.dispatch('purchaseOrder/add', { purchase_orders: purchaseOrders })
              .then(() => {
                loader.hide();
                this.$notify({ type: 'success', message: 'Ordens de compra criadas com sucesso' });
              })
              .catch(() => {
                // loader.hide();
                this.$notify({ type: 'error', message: 'Erro ao criar as ordens de compra' });
              });
          }
        });
    },
  },
  beforeMount() {
    this.getPurchaseRequest();
  }
};
</script>
<style scoped>
.back-button {
  width: 14rem;
}

.header-items-conatiner {
  height: unset !important;
  min-height: 50px !important;
}

.header-items-conatiner .header-button {
  width: auto !important;
  font-weight: 500;
}

.header-send-button {
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px #00000040;
  border: 0.5px solid;
  border-radius: 5px;
  padding: 0.25rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  width: 50%;
}

/* HEADER */
.items-conatiner .header-items-conatiner {
  width: 1189px;
  min-height: 50px;
  top: 271px;
  left: 218px;
  border-radius: 10px 10px 0 0;
  border: 1px solid #DCDFE6;
  box-shadow: 0px 4px 4px 0px #00000040;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.items-conatiner .header-items-conatiner .header-button {
  width: 130px;
  height: 25px;
  top: 234px;
  left: 1114px;
  border-radius: 5px;
  border: 5px;
  box-shadow: 0px 4px 4px 0px #00000040;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 600;
  border: 0.5px solid;
  font-size: 13px;
  padding: 0.5rem 1rem !important;
}

.body-items-conatiner {
  border-bottom: 1px solid #DCDFE6;
  border-radius: 0 0 10px 10px !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 1rem 2rem !important;
}

/* Tabelas */
.resume-table th:last-child {
  background-color: #fceccc !important;
}

.resume-table td:last-child {
  background-color: #fceccc !important;
  color: #2B2D32 !important;
  display: flex;
  align-items: center;
  justify-content: space-between
}

.table-custom-shaded td {
  padding: 0.5rem 1rem !important;
}

.table-custom-shaded td:last-child,
.table-custom-shaded th:last-child {
  text-align: center !important;
  padding-left: 1rem !important;
}

.dropdown-divider {
  border: 1px solid #DCDFE6 !important;
}

@media (max-width: 768px) {
  .back-button {
    width: 6rem;
  }

  .back-button .btn {
    padding: 0 !important;
  }
}
</style>
