<template>
<div>
  <multi-filter ref="multi-filter" @fetch="init" :filter="filter" /> 
  <div class="container-fluid">
    <div class="row card-wrapper" v-show="loadingSkeleton">
      <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
    </div>

    <PuzlEmptyData v-if="!prospection_methods.length && !loadingSkeleton"></PuzlEmptyData>

    <div class="row card-wrapper" v-show="prospection_methods.length && !loadingSkeleton">
      <div class="col-lg-4"
           v-for="(prospection_method, index) in prospection_methods"
           :key="index"
      >
        <!-- Basic with action button -->
        <card :class="prospection_method.status ? 'card-border-top-success' : 'card-border-top-danger'">
          <!-- Card header -->
          <div class="row align-items-center mb-3">
            <div class="col-8">
              <!-- Title -->
              <h5 class="h3 mb-0">{{ prospection_method.description }}</h5>
            </div>

            <div class="col-4 text-right">
              <base-dropdown menuOnRight>
                <base-button
                  slot="title-container"
                  type="primary"
                  class="dropdown-toggle p-2 rounded m-0"
                >
                  <i class="fas fa-cog"></i>
                </base-button>
                <div class="dropdown-divider p-0 m-0"></div>
                <a class="dropdown-item" @click="handleEditProspectionMethod(prospection_method.uuid)">
                  <i class="fas fa-edit text-warning"></i>
                  Editar
                </a>
                <div class="dropdown-divider p-0 m-0"></div>
                <a class="dropdown-item" @click="handleDeleteProspectionMethod(prospection_method.uuid)">
                  <i class="fas fa-times text-danger"></i>
                  Excluir
                </a>
              </base-dropdown>
            </div>
          </div>
          <!-- Card body -->
        </card>
      </div>
    </div>
  </div>
  <modal-edit-prospection-method ref="editProspectionMethod"></modal-edit-prospection-method>
  <loading-pagination :show="loading && !loadingSkeleton"/>
</div>
</template>
<script>
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import {mapGetters} from 'vuex';
import PuzlEmptyData from "@/components/PuzlEmptyData";
import ModalEditProspectionMethod from './_Edit';
import MultiFilter from "@/components/Utils/MultiFilter";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";

export default {
  name: "ListProspectionMethod",
  mixins: [cursorPaginate],
  components: {
    PuzlEmptyData,
    SkeletonPuzlGrid,
    ModalEditProspectionMethod,
    MultiFilter,
    cursorPaginate,
    LoadingPagination,
  },
  data () {
    return {
      loadingSkeleton: false,
      filter: {},
    }
  },
  computed: {
    ...mapGetters({
      'prospection_methods': 'customerConstructionProspectionMethod/fetch',
    }),
  },
  methods:{
    init (filter = null) {
      this.startCursor(filter)
      this.$store.dispatch('customerConstructionProspectionMethod/fetchItemsPaginate', {
          filter: this.filter,
          next_page: this.paginate.nextUrl
      })
        .then( response =>{
          this.resolveCursor(response)
        })
        .catch( error => {
          this.resolveCursor()
        })
    },
    handleEditProspectionMethod(uuid){
      this.$refs.editProspectionMethod.handleEditModal(uuid)
    },
    handleDeleteProspectionMethod(uuid){
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed){
          this.$Progress.start();
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          this.$store.dispatch('customerConstructionProspectionMethod/destroy', uuid).then(response => {
            this.$notify({
              type: 'success',
              message: response.message
            });
          }).catch(error => {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            });
          })
          this.$Progress.finish();
        }
      }).catch(() => this.$Progress.finish())
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style scoped>

</style>
