<template>
  <div :class="classCard">
    <div>
      <card class="card-border-top-light bg-gray-content">
        <div class="row">
          <div class="col-md-5">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <skeleton-puzl type="button"></skeleton-puzl>
          </div>
        </div>
        <section v-if="size === 'lg'">
          <div class="row mt-4">
            <div class="col-md-8">
              <skeleton-puzl type="button"></skeleton-puzl>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <skeleton-puzl type="button"></skeleton-puzl>
            </div>
          </div>
        </section>

        <section v-if="size === 'xxl'">
          <div class="row mt-4">
            <div class="col-md-8">
              <skeleton-puzl type="button"></skeleton-puzl>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <skeleton-puzl type="button"></skeleton-puzl>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <skeleton-puzl type="button"></skeleton-puzl>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <skeleton-puzl type="button"></skeleton-puzl>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <skeleton-puzl type="button"></skeleton-puzl>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <skeleton-puzl type="button"></skeleton-puzl>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-12">
              <skeleton-puzl type="button"></skeleton-puzl>
            </div>
          </div>
        </section>
      </card>
    </div>
  </div>
</template>
<script>
import SkeletonPuzl from './SkeletonPuzl'

export default {
  name: 'SkeletonPuzlGrid',
  components: {
    SkeletonPuzl,
  },
  props: {
    classCard: {
      type: String,
      default: 'col-lg-4',
    },
    type: {
      type: String,
      default: 'sm',
      required: false
    },
    size: {
      type: String,
      default: 'md'
    }
  },
  data() {
    return {}
  },
  updated() {
  },
}
</script>
<style>


</style>
