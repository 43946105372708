<template>
  <div>
    <modal size="lg" :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <h2>
          <small class="font-weight-600 text-danger">
            {{ additional.short_description }}
          </small>
        </h2>
        <div class="row mt-n1">
          <div class="col-6">
            Unid. de cobrança:
          </div>
          <div class="col-6">{{ additional.billing_unit_description }}</div>
        </div>
        <div class="row mt-n1">
          <div class="col-6">
            Parâmetro A
          </div>
          <div class="col-6 text-truncate"
               :title="(additional.params_description && additional.params_description.a && additional.params_description.a.param) || 'Nenhum'">
            {{
              (additional.params_description && additional.params_description.a && additional.params_description.a.param && additional.params_description.a.description) || 'Nenhum'
            }}
          </div>
        </div>
        <div class="row mt-n1">
          <div class="col-6">
            Parâmetro B
          </div>
          <div class="col-6">
            {{
              (additional.params_description && additional.params_description.b && additional.params_description.b.description) || 'Nenhum'
            }}
          </div>
        </div>
        <div>
          <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
            <div class="table-responsive" style="height: 200px;overflow: auto">
              <table class="table table-sm table-bordered">
                <thead>
                <tr class="fixed">
                  <th>Central</th>
                  <th>Valor padrão</th>
                  <th>Valor A</th>
                  <th>Valor B</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in $_plants">
                  <td>{{ item.name }}</td>
                  <td>
                    <!--                    Só será obrigatório se houver valor B ou Valor A-->
                    <validation-provider
                      :rules="{required: !!additional.params_description && (!!item.params.a || !!item.params.b )}"
                      v-slot="{errors}">
                      <base-input input-classes="form-control-sm">
                        <input
                          v-mask="['####']"
                          type="text"
                          class="form-control form-control-sm"
                          v-model="item.standard_value"
                          :class="additional.params_description && (item.params.a || item.params.b) && !item.standard_value ? 'is-invalid' : ''"
                        />
                      </base-input>
                    </validation-provider>
                  </td>
                  <td v-if="!additional.params_description">
                    <base-input input-classes="form-control-sm" disabled></base-input>
                  </td>
                  <td v-if="!additional.params_description">
                    <base-input input-classes="form-control-sm" disabled></base-input>
                  </td>
                  <td v-if="additional.params_description">
                    <!--                    Só será obrigatório se houver valor B ou Valor padrão-->
                    <validation-provider :rules="{required: !!item.standard_value || !!item.params.b}"
                                         v-slot="{errors}">
                      <base-input input-classes="form-control-sm">
                        <input
                          v-mask="['####']"
                          type="text"
                          class="form-control form-control-sm"
                          v-model="item.params.a"
                          :class="(item.standard_value || item.params.b) && !item.params.a  ? 'is-invalid' : ''"
                        />
                      </base-input>
                    </validation-provider>
                  </td>
                  <td v-if="additional.params_description">
                    <!--&lt;!&ndash;                    Só será obrigatório se o valor B do adicional for necessário e houver valor A e Valor padrão&ndash;&gt;-->
                    <validation-provider
                      :rules="{required: !!additional.params_description.b && (!!item.standard_value || !!item.params.a)}"
                      v-slot="{errors}">
                      <base-input input-classes="form-control-sm">
                        <input
                          v-if="!!additional.params_description.b"
                          v-mask="['####']"
                          :disabled="!additional.params_description.b"
                          type="text"
                          class="form-control form-control-sm"
                          v-model="item.params.b"
                          :class="(item.standard_value || item.params.a) && additional.params_description.b && !item.params.b ? 'is-invalid' : ''"
                        />
                        <input
                          v-mask="['####']"
                          class="form-control form-control-sm"
                          type="text"
                          v-else
                          disabled
                        />
                      </base-input>
                    </validation-provider>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
              <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()"
              >Cancelar
              </base-button
              >
              <base-button
                @click.native="updateAdditionalPlant"
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                :loading="loading"
              >Salvar
              </base-button
              >
            </div>
          </validation-observer>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from "vuex";

export default {
  name: "ModalPlant",
  data() {
    return {
      title: "Centrais",
      modal: false,
      loading: false,
      additional: {},
    };
  },
  computed: {
    ...mapGetters({
      $_plants: "plant/fetch",
    }),
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    /**
     * Verifica se alguma central possui valores de adicional
     * @returns {boolean}
     */
    hasAnyWithParams() {
      // if (!this.additional.params_description) {
      //   return !this.$_plants.some(function (item) {
      //     return !!item.standard_value
      //   }, this)
      // }
      // return !this.$_plants.some(function (item) {
      //   return (!!this.additional.params_description.b && (!!item.standard_value && !!item.params.a && !!item.params.b))
      //     || (!this.additional.params_description.b && (!!item.standard_value && !!item.params.a))
      // }, this)
    },
    openModal(additional) {
      this.additional = additional
      this.$Progress.start();
      let loader = this.$loading.show()
      this.$store
        .dispatch("plant/fetchItemsWithAdditional", additional.id).then(() => {
        this.modal = true
        this.$Progress.finish()
        loader.hide()
      })
    },
    updateAdditionalPlant() {
      let additional_plants = []
      /**
       * Formata para o padrão esperado do DB
       */
      this.$_plants.forEach(function (item) {
          let params = null
          if (!!this.additional.params_description) {
            params = {
              a: item.params.a || 0,
              b: item.params.b || 0,
            }
          }
          additional_plants.push({
            additional_id: this.additional.id,
            company_plant_id: item.id,
            standard_value: item.standard_value || null,
            params: params
          })
      }, this)
      this.loading = true
      this.$Progress.start();
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.$store.dispatch('plant/postUpdateAdditionalPlant', additional_plants).then(response => {
        this.$notify({
          type: response.error_type,
          message: response.message
        });
        this.$Progress.finish()
        this.loading = false
        this.modal = false
      })
    },
  },
};
</script>

<style scoped></style>
