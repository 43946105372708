<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-gray d-inline-block mb-0">{{ $route.name }}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4 text-gray">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i></a></li>
              <li class="breadcrumb-item"><a href="#">Cadastro</a></li>
              <li class="breadcrumb-item" aria-current="page">{{ $route.name }}</li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- Header Menus -->
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-2 col-xlg-2 mb-3" v-if="$hasPrivilege(1)">
              <base-button block size="lg" type="success" @click="handleCreate()">
                <i class="fas fa-plus"></i> NOVO
              </base-button>
            </div>

            <div class="col-md-2 col-xlg-2 mb-3 pr-3">
              <router-link :to="{
                  path: '/commercial/service-price',
                  name: 'Preço de Serviço'
                }"
              >
                <base-button block size="lg" type="light" class="text-uppercase">
                  VOLTAR
                </base-button>
              </router-link>
            </div>
            
          </div>
        </div>
      </div>
    </base-header>
    <multi-filter ref="multi-filter" @fetch="init" :filter="filter" />
    <div class="container-fluid">
      
      <div class="row card-wrapper" v-show="loadingSkeleton">
          <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      
      <PuzlEmptyData v-if="!$_services.length && !loadingSkeleton"></PuzlEmptyData>

      <div class="row card-wrapper" v-if="$_services.length && !loadingSkeleton">
        <div class="col-lg-4" v-for="itemService in $_services" :key="itemService.id">
          <!-- Basic with action button -->
          <card :class="itemService.status ? 'card-border-top-success' : 'card-border-top-danger'">
            <!-- Card header -->
            <div class="row align-items-center mb-3">
              <div class="col-8">
                <!-- Title -->
                <h5 class="h3 mb-0">{{ itemService.service_name }}</h5>
              </div>

              <div class="col-4 text-right" v-if="$hasPrivilege(1)">
                <base-dropdown menuOnRight>
                  <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                    <i class="fas fa-cog"></i>
                  </base-button>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleUpdate(itemService)">
                    <i class="fas fa-edit text-warning"></i>
                    Editar
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleDelete(itemService.id)">
                    <i class="fas fa-times text-danger"></i>
                    Excluir
                  </a>
                </base-dropdown>
              </div>
            </div>
            <!-- Card body -->
            <h6 class="h3 mb-0 small">
              {{ itemService.equipment_category && itemService.equipment_category.category_name || '' }}
            </h6>
          </card>
        </div>
      </div>
    </div>
    <add-service></add-service>
    <loading-pagination :show="loading && !loadingSkeleton" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import AddService from './components/Service/AddService.vue';
import MultiFilter from '@/components/Utils/MultiFilter.vue';
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";

export default {
  mixins: [cursorPaginate],
  components: {
    AddService,
    MultiFilter,
    LoadingPagination,
    PuzlEmptyData,
    SkeletonPuzlGrid,
  },
  data() {
    return {
      loadingSkeleton: false,
      filter: {},
    }
  },
  computed: {
    ...mapGetters({
      $_services: "service/services",
    }),
  },
  methods: {
    init(filter = null) {
      this.startCursor(filter)
      this.$Progress.start()
      this.$store
        .dispatch("service/fetchItemsPaginate", {
          filter: this.filter,
          next_page: this.paginate.nextUrl
        })
        .then(response => {
          this.$Progress.finish()
          this.resolveCursor(response)
        })
        .catch(error => {
          this.resolveCursor()
          this.$Progress.finish()
        });
    },
    handleCreate() {
      EventBus.$emit("handleCreateService");
    },
    handleUpdate(itemUpdate) {
      EventBus.$emit("handleUpdateService", itemUpdate);
    },
    handleDelete(id) {
      EventBus.$emit("handleDeleteService", id);
    }
  },
  created() {
    this.init({});
  },
  mounted() {
    EventBus.$on("getService", () => {
      this.init({});
    });
  }
};
</script>
<style></style>
