import {getPropertiesByArray, round} from "@/helpers";
import {parseDate} from '@/helpers'

const baseOsBarChart = {
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "cross",
      crossStyle: {
        color: "#999",
      },
    },
    formatter: function (value) {
      let transposta = `<span style="font-weight: normal">Transposta</span>`
      let text = '';
      const finishedAt = parseDate(value[0].data.parent.finished_at, 'DD/MM/YYYY')
      text += `O.S: ${value[0].axisValue} | ${finishedAt} <br/>`;
      text += `${value[0].data.parent.customer_name} <br/>`;
      text += `<b>${value[0].data.parent.construction_name} <b/> <br/>`;
      text += `<b>${value[0].data.parent.code} <b/> <br/>`;
      text += `<b>${value[0].data.parent.company_plant_name} | ${value[0].data.parent.charge_point_name} <b/> <br/><br/>`;
      text += `${value[0].data.parent.title} <br/>`;
      text += `${value[0].data.parent.body}<br/><br/>`;
      text += `${value[0].data.parent.concreted_piece} <br/> <span style="font-weight: normal;text-transform: none">${value[0].data.parent.temperature} °C |
Concreto = ${value[0].data.parent.performed_concrete_cycle} min. | Viagem =
${value[0].data.parent.performed_travel_cycle} min. </span><br/><br/>`;
      if (value[0].data.parent.real_string.length > 0) {
        text += `<b>Real</b> | ${transposta} <br>`;
      }
      for (let item of value[0].data.parent.real_string) {
        const splited = item.split(' | ')
        text += splited[0] + `<small style="font-weight: normal"> | ${splited[1]}</small> <br>`;
      }
      if (value[0].data.parent.real_estimated.length > 0) {
        text += `<br><b>Estimado</b> | ${transposta} <br>`;
      }
      for (let item of value[0].data.parent.real_estimated) {
        const splited = item.split(' | ')
        text += splited[0] + `<small style="font-weight: normal"> | ${splited[1]}</small> <br>`;
      }
      return text;
    },
  },
  toolbox: {
    feature: {
      dataView: {show: true, readOnly: false},
      magicType: {show: true, type: ["line", "bar"]},
      restore: {show: true},
      saveAsImage: {show: true},
    },
  },
  dataZoom: [
    {
      show: true,
      start: 0,
      end: 100,
    },
  ],
  legend: {
    data: [],
  },
  xAxis: [
    {
      type: "category",
      data: [],
      axisPointer: {
        type: "shadow",
      },
      axisLabel: {
        formatter: "O.S {value}",
      },
    },
    {
      show: false,
      type: "category",
      data: [],
      axisPointer: {
        type: "shadow",
      },
    },
  ],
  yAxis: [
    {
      type: "value",
      name: "Resistência",
      axisLabel: {
        formatter: "{value}",
      },
    },
  ],
};

export function handleOsBarChart(payload) {
  let osBarChart = baseOsBarChart;
  osBarChart.series = [];
  // dados do eixo x
  osBarChart.xAxis[0].data = getPropertiesByArray("item_name", payload.data);
  // legendas
  osBarChart.legend.data = payload.subtitles_names;

  // conta quantos reais existem
  let real_quantity = payload.subtitles_names.filter((item) => item.includes("(R)")).length;

  payload.subtitles_names.forEach(function (name, index) {
    let serie = {
      name: name,
      type: "line",
      data: [],
    };

    //  agrupa os valores
    if (name.includes("(R)")) {
      serie.data = payload.data.reduce((a, o) => (a.push({value: o.real[index] ? o.real[index].value : 0, parent: o}), a), []);
      serie.color = "#0c8fe7";
      // serie.markPoint = {
      //   data: [
      //     {type: 'max', name: 'Max'},
      //     {type: 'min', name: 'Min'}
      //   ]
      // }
      serie.markLine = {
        data: [{type: 'average', name: 'Avg',  label: {
            normal: {
              show: true,
              formatter: function (value) { return round(value.value, 1, true)}
            }
          }}]
      }
    } else {
      serie.data = payload.data.reduce((a, o) => (a.push({
        value: o.estimated[index - real_quantity] ? o.estimated[index - real_quantity].value : 0,
        parent: o
      }), a), []);
      serie.color = '#979292';
      // serie.markPoint = {
      //   data: [
      //     {type: 'max', name: 'Max'},
      //     {type: 'min', name: 'Min'}
      //   ]
      // }
      serie.markLine = {
        data: [{type: 'average', name: 'Avg',  label: {
            normal: {
              show: true,
              formatter: function (value) { return round(value.value, 1, true)}
            }
          }}]
      }
    }
    // insere em data principal
    osBarChart.series.push(serie);
  });
  osBarChart.loading = false;
  return osBarChart;
}
