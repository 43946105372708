<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12 row pr-0">
          <div class="col-md-3 pr-0 my-auto title-index">
            <h6 class="h2 text-gray d-inline-block mb-1 mt-1">
              {{ this.$route.meta.breadcrumbs[1].title }}
            </h6>
          </div>
          <div
            class="col-md-3 active align-middle my-auto p-0 d-none d-md-block"
            style="font-size: 0.875rem;"
            aria-current="page"
          >
            <a href="#"><i :class="this.$route.meta.icon"></i> - </a>
            <a class="text-primary">
              {{ this.$route.meta.breadcrumbs[0].title }} -
            </a>
            <a class="text-gray">
              {{ this.$route.meta.breadcrumbs[1].title }}
            </a>
          </div>
          <div
            class="col-md-3 pr-4 pl-4 mb-1 mt-1 col-buttons-person"
            aria-current="page"
          >
           <BackButton/>
          </div>
          <div
            class="col-md-3 pr-4 pl-4 align-middle mt-1 mb-1 col-buttons-person"
            aria-current="page"
          >
            <base-button
              @click.prevent="handleModalGanttSettings"
              block
              size="lg"
              type="primary"
              class="text-uppercase"
            >
              configurar
            </base-button>
          </div>
        </div>
      </div>
    </base-header>
    <ListGantt ref="listGantt" />
    <ModalGanttSettings
      @updatedGanttSettings="updatedGanttSettings = !updatedGanttSettings"
      ref="ganttSettings"
    />
  </div>
</template>

<script>
import ListGantt from "./_ListGantt";
import ModalGanttSettings from "./_ModalGanttSettings";
import BackButton from "@/components/Utils/BackButton.vue"
export default {
  name: "IndexScheduleGantt",
  components: {
    ListGantt,
    ModalGanttSettings,
    BackButton
  },
  watch: {
    updatedGanttSettings() {
      this.$refs.listGantt.initData();
    }
  },
  data() {
    return {
      updatedGanttSettings: false
    };
  },
  methods: {
    handleModalGanttSettings() {
      this.$refs.ganttSettings.openModal();
    },

    handleSchedule() {
      location.href = "/operational/schedule/";
    }
  }
};
</script>

<style scoped>
@media screen and (max-width: 767px) {
  .col-buttons-person {
    margin-left: 0.5rem !important;
  }
  .title-index {
    margin-left: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
</style>
