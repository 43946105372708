export const isEmpty = (array) => array instanceof Array && array.length
export const sumWithField = (arr, field = null) => {
  if (arr.length == 1) {
    return arr[0][field].toString().replaceAll('.', '').replace(',', '.')
  }
  return arr.reduce((a,b) => parseFloat(a[field].toString().replaceAll('.', '').replace(',', '.')) + parseFloat(b[field].toString().replaceAll('.', '').replace(',', '.')));
}
export const selecteds = (arr, i) => {
  if (arr.includes(i)) {
    arr = arr.filter(selected => selected !== i)
  }
  else {
    arr.push(i)
  }
  return arr
}
