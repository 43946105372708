var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"mx-1"},[_c('el-popover',{staticClass:"ml-0",attrs:{"placement":"left","trigger":"click"}},[_c('div',{staticStyle:{"min-width":"250px"}},[_c('span',[_c('span',{staticClass:"d-flex justify-content-center",staticStyle:{"font-weight":"500","color":"#2B2D32","font-size":"13px"}},[_vm._v(" AJUSTES TÉCNICOS ")]),_c('hr',{staticClass:"hr-popover-technical-adjustment"}),_c('div',{staticClass:"w-100 justify-content-around d-flex mt-3"},[_c('span',{staticClass:"popover-content-font"},[_vm._v("Aglomerante")]),_c('hr',{staticClass:"flex-grow-1 mx-3 hr-divider-popover"}),_c('span',{staticClass:"popover-content-font font-weight-normal",class:_setup.props.adjustment.binder < 0
                ? 'danger-color'
                : _setup.props.adjustment.binder > 0
                ? 'success-color'
                : 'font-dark'},[_vm._v(" "+_vm._s((_setup.props.adjustment.binder || 0) + ' kg/m³')+" ")])]),_c('div',{staticClass:"w-100 justify-content-around d-flex mt-2"},[_c('span',{staticClass:"popover-content-font"},[_vm._v("Água")]),_c('hr',{staticClass:"flex-grow-1 mx-3 hr-divider-popover"}),_c('span',{staticClass:"popover-content-font font-weight-normal",class:_setup.props.adjustment.water < 0
                ? 'danger-color'
                : _setup.props.adjustment.water > 0
                ? 'success-color'
                : 'font-dark'},[_vm._v(" "+_vm._s((_setup.props.adjustment.water || 0) + ' kg/m³')+" ")])]),_c('div',{staticClass:"w-100 justify-content-around d-flex mt-2"},[_c('span',{staticClass:"popover-content-font"},[_vm._v("Argamassa")]),_c('hr',{staticClass:"flex-grow-1 mx-3 hr-divider-popover"}),_c('span',{staticClass:"popover-content-font font-weight-normal",class:_setup.props.adjustment.content_mortar < 0
                ? 'danger-color'
                : _setup.props.adjustment.content_mortar > 0
                ? 'success-color'
                : 'font-dark'},[_vm._v(" "+_vm._s((_setup.props.adjustment.content_mortar || 0) + ' %')+" ")])]),_c('div',{staticClass:"w-100 justify-content-around d-flex mt-2"},[_c('span',{staticClass:"popover-content-font"},[_vm._v("Ar Total")]),_c('hr',{staticClass:"flex-grow-1 mx-3 hr-divider-popover"}),_c('span',{staticClass:"popover-content-font font-weight-normal",class:_setup.props.adjustment.total_air < 0
                ? 'danger-color'
                : _setup.props.adjustment.total_air > 0
                ? 'success-color'
                : 'font-dark'},[_vm._v(" "+_vm._s((_setup.props.adjustment.total_air || 0) + ' %')+" ")])]),(_setup.hasAgent(_setup.props.adjustment.agent))?_c('div',_vm._l((_setup.props.adjustment.agent),function(agentItem,agentItemIndex){return _c('div',[(agentItem.id)?_c('div',[_c('div',{staticClass:"w-100 justify-content-around d-flex mt-2"},[_c('span',{staticClass:"popover-content-font"},[_vm._v(_vm._s(agentItem.name))]),_c('hr',{staticClass:"flex-grow-1 mx-3 hr-divider-popover"}),_c('span',{staticClass:"popover-content-font font-weight-normal",class:agentItem.value < 0
                      ? 'danger-color'
                      : agentItem.value > 0
                      ? 'success-color'
                      : 'font-dark'},[_vm._v(" "+_vm._s(( agentItem.value || 0) + ' %')+" ")])])]):_vm._e()])}),0):_vm._e(),(_setup.props.adjustment.observation_mcc !== null)?_c('hr',{staticClass:"hr-popover-technical-adjustment mt-4"}):_vm._e(),(_setup.props.adjustment.observation_mcc !== null)?_c('div',[_c('span',{staticClass:"d-flex justify-content-center mb-2",staticStyle:{"font-weight":"500","color":"#2B2D32","font-size":"13px"}},[_vm._v(" OBSERVAÇÃO ")]),_c('span',{staticClass:"popover-content-font"},[_vm._v(" "+_vm._s(_setup.props.adjustment.observation_mcc)+" ")])]):_vm._e()])]),_c('base-button',{staticClass:"p-0 m-0 shadow-none",attrs:{"slot":"reference","outiline":"","size":"sm","type":"secundary"},slot:"reference"},[_c('img',{attrs:{"src":"/img/brand/logo.png","alt":"logo","width":"22px"}})])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }