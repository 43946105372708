<template>
  <div>
    <div>
      <div class="card mt-3" :class="!show_additional_filters && 'mb-4'" :style="'margin-bottom: 3.22rem !important'"
        v-show="show_filters">
        <div class="container-fluid">
          <div class="row">
            <div class="offset-md-8 col-md-4 p-1 text-right">
              <h5 v-show="show_filters"
                @click.prevent="show_filters = false" class="pointer font-weight-normal new-default-gray-font"
                style="margin-top: -2.5rem !important">OCULTAR FILTROS <i class="fa-regular fa-eye-slash fs-11"></i></h5>
            </div>
          </div>
        </div>
        <div class="card-body p-0">
          <div class="container-fluid">
            <div class="row pt-3">
              <div class="col-md-3 p-0">
                <input-date-picker
                  :isRange="false"
                  :disabled="disableFilterDate"
                  :disableMaxDate="new Date()"
                  :disableBoxShadow="true"
                  visibility="focus"
                  @handleFilterDate="handleFilterDate"
                  size="medium" :filterable="filterable_type" />
              </div>
              <div class="col-md-4">
                <div class="d-flex">
                  <input @keydown.enter="push" @keypress.space="push" v-model="filtered" placeholder="Pesquisar"
                    class="form-control hide-shadows" />
                  <img @click.prevent="push()" src="/img/icons/icons8/ios/search.png" height="18"
                    style="margin-top: 10px" class="default-search zoom pointer">
                </div>
              </div>
              <div class="col-md-2">
                <div class="d-flex w-100">
                  <slot name="dropdown-instead-input" />
                </div>
              </div>
              <div class="col-md-1 p-0">
                <base-button @click.prevent="global = []; clearFilter(); push();"
                  class="hide-shadows" type="white" block>
                  <div style="margin-top: -4px">
                    <img height="21" src="/img/icons/icons8/ios/synchronize.png" alt="synchronize" />
                  </div>
                </base-button>
              </div>
              <div class="col-md-2 pr-0" ref="handle_show_or_hidden_filters">
                <base-button class="hide-shadows"
                  @click="show_additional_filters = true" type="white" block>
                  <div style="margin-top: -2px;font-size: 13px;color: #6d6e74">
                    <img height="19" style="margin-top: -4px" src="/img/icons/icons8/ios/filter.png" alt="filter--v1" />
                    FILTROS
                  </div>
                </base-button>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-8 pl-0 pt-2 pb-2">
                <transition name="el-zoom-in-center">
                  <h3 v-if="global && global.length"><span class="badge badge-primary text-white mr-2"
                      style="background: #1A70B7" v-for="(item, key) in global"> {{ item }} <a href="#">
                        <i @click.prevent="removeSelected(key)" class="fa-solid fa-xmark text-white"
                          style="font-size: 16px;opacity: 50%;margin-left: 2px;margin-right: 2px"></i>
                      </a>
                    </span>
                  </h3>
                </transition>
              </div>
              <div v-if="showSlotOrderBy" class="col-md-4 p-0">
                <slot name="order-by"></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    CARD OCULTO -->
    <div class="row" style="margin-top: -7px;margin-bottom: 20px" v-show="!show_filters">
      <div class="col-md-12 text-right" style="padding-right: 2rem">
        <h5 class="pointer font-weight-normal" @click="show_filters = true">MOSTRAR FILTROS <i class="fa-regular fa-eye fs-11"></i></h5>
      </div>
      <div class="col-md-12 mb-3">
        <div style="border-top: 1px solid #1A70B7;margin-bottom: 10px" />
      </div>
    </div>
    <div class="scrolling-box" style="margin-top: -2%">
      <transition name="slide-fade">
        <div ref="additional_panel" v-if="show_additional_filters" class="float-filter">
          <div style="width: 100%;background: #1A70B7;height: 69px">
            <div class="row">
              <div @click.prevent="show_additional_filters = false" class="col-8 pl-0 ml-n2" style="margin-top: 24px">
                <h3 class="text-white pointer">
                  <a href="#" class="show-mobile">
                    <img width="22" height="22" src="/img/icons/icons8/ios/circled-chevron-left--v1.png"
                      alt="circled-chevron-right--v1" />
                  </a>
                  FILTROS DE PESQUISA
                </h3>
              </div>
              <div class="col-4" style="margin-top: 24px">
                <a href="#" @click.prevent="clearFilter()">
                  <img height="24" src="/img/icons/icons8/ios/erase.png" alt="synchronize">
                </a>
              </div>
            </div>
          </div>
          <div style="scroll-behavior: smooth;max-height: 84vh" class="container-fluid">
            <div v-if="showSlotDates" class="row mt-4 pr-4 pl-4">
              <div class="col-4 text-left pr-0 mb-1">
                <h3 class="text-primary">
                  <img width="100" height="100" src="/img/icons/calendar.png" alt="calendar--v1"
                    style="width: 25px;height: 22px;padding-right: 5px;"> DATAS
                </h3>
              </div>
              <div class="col-8 pl-0">
                <div style="border-top: 1px solid rgb(26, 112, 183);padding-top: 10px !important;margin-top: 10px;"></div>
              </div>
              <slot name="dates" />
            </div>
            <div v-if="showSlotData" class="row pr-4 pl-4" style="margin-top: 27px">
              <div class="col-4 text-left pr-0 mb-n1">
                <h3 class="text-primary">
                  <img width="100" height="100" src="/img/icons/icons8/ios/folder-invoices.png" alt="calendar--v1"
                    style="width: 25px;height: 22px;padding-right: 5px;"> DADOS
                </h3>
              </div>
              <div class="col-8 pl-0">
                <div
                  style="border-top: 1px solid rgb(26, 112, 183);padding-top: 10px !important;margin-top: 10px;margin-left: 6px">
                </div>
              </div>
              <slot name="data" />
            </div>
            <div v-if="showSlotStatus" class="row mt-4 pr-4 pl-4">
              <div class="col-4 text-left pr-0 mb-1">
                <h3 class="text-primary">
                  <img width="100" height="100" src="/img/icons/icons8/ios/progress-indicator.png"
                    style="width: 25px;height: 22px;padding-right: 5px;" alt="progress-indicator" />
                  STATUS
                </h3>
              </div>
              <div class="col-8 pl-0">
                <div
                  style="border-top: 1px solid rgb(26, 112, 183);padding-top: 10px !important;margin-top: 10px;margin-left: 7px">
                </div>
              </div>
              <slot name="status" />
            </div>
            <div v-if="showSlotBookmark" class="row pr-4 pl-4" style="margin-top: 21px">
              <div class="col-6 text-left pr-0 mb-2">
                <h3 class="text-primary">
                  <img width="100" height="100" src="/img/icons/icons8/ios/rfid-signal.png"
                    style="width: 25px;height: 22px;padding-right: 5px;" alt="rfid-signal" /> MARCADORES
                </h3>
              </div>
              <div class="col-6 pl-0">
                <div style="border-top: 1px solid rgb(26, 112, 183);padding-top: 10px !important;margin-top: 10px;"></div>
              </div>
              <slot name="bookmarks" />
              <div style="margin-bottom: 80px !important;"></div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import InputDatePicker from '@/components/InputDatePicker';
import moment from "moment";

export default {
  name: "Filters",
  components: { InputDatePicker },
  data() {
    return {
      show_filters: true,
      show_additional_filters: false,
      global: [],
      filtered: null,
      period: [],
    };
  },
  watch: {
    show_additional_filters(isAdditionalFiltersOpen) {
      this.$emit('additionalFiltersToggled', isAdditionalFiltersOpen);
    },
  },
  props: {
    filterable_type: {
      type: Number,
      default: 4
    },
    disableFilterDate: {
      type: Boolean,
      default: false,
      description: "Desativar componente de data do filtro.",
    },
    onlyFilterMonth: {
      type: Boolean,
      default: false,
      description: "Habilita a filtragem apenas do mes atual.",
    },
    showSlotOrderBy: {
      type: Boolean,
      default: false,
      description: "Exibir slot de ordenação",
    },
    showSlotDates: {
      type: Boolean,
      default: false,
      description: "Exibir slot de datas",
    },
    showSlotData: {
      type: Boolean,
      default: false,
      description: "Exibir slot de dados",
    },
    showSlotStatus: {
      type: Boolean,
      default: false,
      description: "Exibir slot de status",
    },
    showSlotBookmark: {
      type: Boolean,
      default: false,
      description: "Exibir slot de marcadores",
    },
    defaultRange: {
      type: Object,
      default: () => ({
        start: moment(new Date()).format('MM/DD/YYYY'),
        end: moment(new Date()).format('MM/DD/YYYY'),
        default_filterable: true
      }),
      description: "Alcance padrão",
    },
  },
  computed: {},
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    handleClickOutside(event) {
      const navElement = this.$refs.additional_panel;
      const actionBtn = this.$refs.handle_show_or_hidden_filters

      if (navElement && !navElement.contains(event.target) && !actionBtn.contains(event.target)) {
        this.show_additional_filters = false;
      }
    },
    push() {
      if (!this.filtered) {
        return this.handleFilterClick();
      }
      this.filtered.split(' ').map(function (item) {
        item = item.replaceAll('(', '')
        item = item.replaceAll(')', '')
        if (item != '') {
          this.global.push(item);
        }
      }, this)
      this.filtered = null;
      this.handleFilterClick();
    },
    clearFilter() {
      this.$emit('clearFilter')
    },
    handleFilterClick() {
      const filters = { ...this.filter };
      filters.global = this.global;
      if (filters.status == null) {
        delete filters.status;
      }
      this.$emit("fetch", filters);
    },
    removeSelected(key) {
      this.global.splice(key, 1);
      this.handleFilterClick();
    },
    handleFilterDate(filter) {
      this.$emit('handleFilterDate', filter)
    }
  }
};
</script>

<style scoped>
.hide-shadows {
  border-radius: 8px !important;
  height: 2.7rem !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #ECECEC !important;
}

.card {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.07) !important;
  border-radius: 8px !important;
  border: 1px solid #E8E8E8;
}

input {
  display: block;
  width: 50vw;
  padding: 0 1.25rem;
}

input,
input::placeholder {
  font: 1rem/3 Fredoka;
  font-weight: 400;
}
</style>
<style>
.default-search {
  margin-left: -34px;
  height: 20px;
  margin-top: 7px;
}

.btn-success-light {
  background-color: #68b879;
  border-color: #68b879;
}

.float-filter {
  z-index: 99999999999;
  position: fixed;
  top: 5px;
  width: 28vw;
  max-height: 98vh;
  right: 15px;
  background-color: white;
  border: 1px solid #606602;
/*  color: #FFF;*/
  border-radius: 4px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
}

@media only screen and (min-height: 768px) {
  .float-filter {
    max-height: 89vh !important;
  }
}

@media only screen and (max-width: 600px) {
  .float-filter {
    width: 93vw !important;
    max-height: 100vh !important;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1000px) {
  .float-filter {
    width: 45vw !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 800px) {
  .float-filter {
    width: 55vw !important;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1200px) {
  .float-filter {
    width: 38vw !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .float-filter {
    width: 35vw !important;
  }
}
</style>
