import { sapConfigurationUrls } from ".";
import httpClient from "../../../../shared/libraries/httpClient";
import { handleUnexpectedStatusCode } from "../../../../shared/services";
import { HttpStatusEnum } from "../../../../shared/enums";
import { SapConfigurationSyncType } from "../types";

/**
 * @returns {Promise<SapConfigurationSyncType>}
 */
const execute = async () => {
  const res = await httpClient.get(sapConfigurationUrls.BASE);

  switch (res.status) {
    case HttpStatusEnum.HTTP_OK:
      return res.data.data;
    case HttpStatusEnum.HTTP_NOT_ACCEPTABLE:
      return null;
    default:
      handleUnexpectedStatusCode(res);
  }
};

export default { execute };
