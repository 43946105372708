import store from "../../../../shared/libraries/store";
import { cmcAdjustmentStoreStateKeys } from "./cmcAdjustmentStoreStateKeys";
import {
  CmcAdjustmentListFilterType,
  CmcAdjustmentListPagerType,
  DosageAdjustmentByIdType
} from "../types";
import { SearchBarFilterType } from "../../../../components/AppGlobal";

/**
 * @param {CmcAdjustmentListPagerType} listed
 * @returns {CmcAdjustmentListPagerType}
 */
const setListed = (listed) =>
  store.commit(cmcAdjustmentStoreStateKeys.LISTED, listed);

/**
 * @param {boolean} isListing
 * @returns {boolean}
 */
const setIsListing = (isListing) =>
  store.commit(cmcAdjustmentStoreStateKeys.IS_LISTING, isListing);

/**
 * @param {boolean} isFinding
 * @returns {boolean}
 */
const setIsFinding = (isFinding) =>
  store.commit(cmcAdjustmentStoreStateKeys.IS_FINDING, isFinding);

/**
 * @param {boolean} isSaving
 * @returns {boolean}
 */
const setIsSaving = (isSaving) =>
  store.commit(cmcAdjustmentStoreStateKeys.IS_SAVING, isSaving);

/**
 * @param {boolean} isRemoving
 * @returns {boolean}
 */
const setIsRemoving = (isRemoving) =>
  store.commit(cmcAdjustmentStoreStateKeys.IS_REMOVING, isRemoving);

/**
 * @param {DosageAdjustmentByIdType} item
 * @returns {DosageAdjustmentByIdType}
 */
const setItem = (item) => store.commit(cmcAdjustmentStoreStateKeys.ITEM, item);

/**
 * @param {CmcAdjustmentListPagerType} listed
 * @returns {CmcAdjustmentListPagerType}
 */
const setListMerge = (listed) =>
  store.commitListMerge(cmcAdjustmentStoreStateKeys, listed);

/**
 * @param {DosageAdjustmentByIdType} updated
 * @returns {DosageAdjustmentByIdType}
 */
const setUpdatedItem = (updated) =>
  store.commitUpdatedItem(cmcAdjustmentStoreStateKeys, updated);

  /**
 * @param {number|string} id
 */
const setRemovedItem = (id) =>
  store.commitRemovedItem(cmcAdjustmentStoreStateKeys, id);

/**
 * @param {{
 *   searchBarFilter: SearchBarFilterType,
 *   filter: CmcAdjustmentListFilterType
 * }} filters
 */
const setPageState = (filters) =>
  store.commit(cmcAdjustmentStoreStateKeys.PAGE_STATES, filters);

/** Resetar chaves de estado */
const resetStates = () => {
  store.resetStates(Object.values(cmcAdjustmentStoreStateKeys));
};

export default {
  setListed,
  setIsListing,
  setIsFinding,
  setIsSaving,
  setIsRemoving,
  setListMerge,
  setUpdatedItem,
  setPageState,
  resetStates,
  setRemovedItem,
  setItem
};
