<template>
  <div v-if="entity">
    <modal :borderInfo="'2px solid #1b6eba'" :show.sync="modal" size="md">
      <template slot="header">
        <div style="display: flex;align-items: center;">
          <img style="display: inline-flex;" src="/img/icons/icons8/ios/info-circle.png" width="22">
          <h5 style="display: inline-flex;" class="modal-title p-0 m-0 ml-3 text-white">{{ title }}</h5>
        </div>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form @submit.prevent="handleSubmit(save)" class="needs-validation" autocomplete="off">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <h1 class="text-center">
                    <i class="fas fa-exclamation-circle fa-2x text-orange"></i>
                  </h1>
                  <h5 class="text-center" v-if="action == statusAction.CANCEL">
                    A AÇÃO DE <b :class="'text-' + status[action].class">CANCELAR</b> UM PAGAMENTO NÃO PODE SER DESFEITA
                  </h5>
                  <h5 class="text-center" v-if="action == statusAction.REVERT">
                    Alterar status para <b :class="'text-' + status[action].class">ESTORNADO</b>
                  </h5>
                </div>
              </div>
              <div v-if="action == statusAction.REVERT" class="row mt-2 align-items-center">
                <div class="col-4">
                  <h5 class="new-default-black-font font-weight-normal m-0">
                    Data da baixa <span class="text-danger">&nbsp;*</span>
                  </h5>
                </div>
                <div class="col-8">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                     <input-date-time-picker
                        mode="date"
                        v-model="discharge_at"
                        :disableDate="minDate"
                        :disableMaxDate="new Date()"
                       />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-4">
                  <h5 class="new-default-black-font font-weight-normal m-0">
                    Observações <span class="text-danger">&nbsp;*</span>
                  </h5>
                </div>
                <div class="col-8">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <textarea type="text" rows="4"
                        maxlength="60"
                        v-model="status_reason"
                        :class="errors[0] ? 'is-invalid' : ''"
                        class="form-control form-control-sm" placeholder=" ...">
                      </textarea>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="row mt-5 mb-4">
                <div class="col-12 text-right">
                  <base-button type="danger"
                    size="sm"
                    style="width: 150px;" @click.prevent="closeModal">
                    <div style="display: inline-flex; align-items: center;">
                      <img src="/img/icons/cancel-white.png" width="20">
                      <i class="m-0 ml-1" style="font-style: normal;">
                        Cancelar
                      </i>
                    </div>
                  </base-button>
                  <base-button type="success"
                    size="sm"
                    style="width: 150px;"
                    native-type="submit">
                    <div style="display: inline-flex; align-items: center;">
                      <img src="/img/icons/icons8/ios/checked.png" width="20">
                      <i class="m-0 ml-1" style="font-style: normal;">
                        Confirmar
                      </i>
                    </div>
                  </base-button>
                </div>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from 'vuex';
import InputDateTimePicker from "@/components/InputDateTimePicker";
import { formatErrorValidation } from "@/plugins";

export default {
  name: "ModalReverseFinanceStatus",
  props: {
    updateScreen: { type: Function },
  },
  components: {
    InputDateTimePicker
  },
  data() {
    return {
      title: 'Alterar Status',
      entity: null,
      minDate: null,
      statusAction: {
        'REVERT': 0,
        'POST': 1,
        'CANCEL': 2,
      },
      modal: false,
      status: {
        0 : {
          text: "Estornar",
          class: "warning"
        },
        2 : {
          text: "Cancelado",
          class: "danger",
        },
      },
      id: null,
      finance_installment_id: null,
      action: null,
      chart_account_id: null,
      cnpj: "",
      note: "",
      company_plant_id: null,
      company_plant_issuer_id: null,
      bank_account_id: null,
      cost_center_id: null,
      operation_at: new Date(),
      discharge_at: null,
      expire_at_original: null,
      payment_method_id: null,
      finance_type_id: null,
      finance_type_mode: null,
      amount: 0,
      action: null,
      status_reason: ""
    }
  },
  methods: {
    openModal(finance, action){
      this.id = finance.finance_id;
      this.finance_installment_id = finance.id;
      this.action = action;
      this.status_reason = "";
      this.bank_account_id = finance.bank_account_id;

      this.$store
        .dispatch("finances/show", this.id)
        .then(response => {
          const item = response.data;
          this.cnpj = item.finance_installments[0].document_number;
          this.chart_account_id = item.finance_installments[0].chart_account_id;
          this.note = item.note;
          this.company_plant_id = item.company_plant_id;
          this.cost_center_id = item.finance_installments[0].cost_center_id;;
          this.operation_at = item.operation_at;
          this.payment_method_id = item.finance_installments[0].payment_method_id;
          this.finance_type_id = item.finance_type_id;
          this.finance_type_mode = item.finance_type_mode;
          this.amount = item.finance_installments[0].amount.toFixed(2);
          this.discharge_at = item.finance_installments[0].discharge_at;
          this.expire_at_original = item.finance_installments[0].expire_at_original;

          this.minDate = new Date(this.discharge_at);

          this.entity = {
            entity_name: "",
            id: item.entity_id,
            document: item.finance_installments[0].document_number,
          };
        });

      this.modal = true;
    },
    closeModal(){
      this.modal = false;
    },
    // verifyIfExpireAtIsLesserThanOriginalAt(){
    //   let originalAt = new Date(this.expire_at_original);
    //   originalAt = originalAt.setDate(originalAt.getDate() + 1);

    //   let expireAt = new Date(this.discharge_at);
    //   expireAt = expireAt.setDate(expireAt.getDate() + 1);

    //   return expireAt < originalAt;
    // },
    save(){
      // if(this.verifyIfExpireAtIsLesserThanOriginalAt()){
      //   this.$notify({
      //     type: 'danger',
      //     message: "Data da baixa não pode ser menor que a do lançamento."
      //   });

      //   return;
      // }

      this.$store.dispatch("financeInstallments/changeStatus", {
        id: this.id,
        finance_installment_id: this.finance_installment_id,
        action: this.action,
        status_reason: this.status_reason,
        bank_account_id: this.bank_account_id,
        discharge_at: this.discharge_at,
        payment_method_id: this.payment_method_id,
      })
      .then(response => {
        this.updateScreen();
        this.closeModal();
      }).catch(error => {
        if(error.response){
          const errors = error && error.response && error.status === 422
              ? formatErrorValidation(error.response.data.data)
              : error.response.data.message;
          this.$notify({ type: "danger", message: errors });
        }
        this.loadingSkeleton = false;
      });
      this.$Progress.finish();
    },
  },
};
</script>

<style>
</style>
