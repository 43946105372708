<template>
  <div>
    <div class="container-fluid">
      <div class="card">
        <div class="card-body">
          <div v-if="type === 0">
            <form @submit.prevent="push">
              <div class="row">
                <div class="col-md-7 p-2">
                  <base-input @keypress.space="push" v-model="filtered" placeholder="Pesquisar">
                    <template slot="append">
                      <a href="#" @click.prevent="push">
                        <i
                          style="color: #2d3748"
                          :style="
                            global.length ? 'opacity: 80%' : 'opacity: 50%'
                          "
                          class=" fas fa-search"
                        ></i>
                      </a>
                    </template>
                  </base-input>
                </div>
                <div class="col-md-2 p-2">
                  <base-button
                    title="Limpar filtros"
                    outline
                    :disabled="!global.length"
                    @click.prevent="clearFilters"
                    block
                    :type="global.length ? 'danger' : 'light'"
                  >
                    <i :style="global.length === 0 && 'color: #615656'" class="fas fa-1x fa-eraser"></i>
                  </base-button>
                </div>
                <base-dropdown class="col-md-3 p-2">
                  <base-button
                    slot="title-container"
                    block
                    :type="
                      status[filter.status]
                        ? status[filter.status].type
                        : 'primary'
                    "
                    class="dropdown-toggle text-white text-uppercase"
                  >
                    {{
                      status[filter.status]
                        ? status[filter.status].name
                        : "todos"
                    }}
                  </base-button>
                  <div>
                    <a
                      :key="index"
                      v-for="(item, index) in status"
                      v-if="JSON.parse(index) != filter.status"
                      @click.passive="filter.status = JSON.parse(index)"
                      @click.prevent="handleFilterClick"
                      class="dropdown-item"
                      >
                      {{ item.name }}
                    </a
                    >
                  </div>
                </base-dropdown>
              </div>
              <div class="row mt-2">
                <div class="col-md-12 pl-2">
                  <base-button
                    class="mt-2"
                    :key="key"
                    v-for="(value, key) in global"
                    type="primary"
                  >
                    <span class="text-uppercase"
                      >{{ value }}
                      <a
                        href="#"
                        class="text-white"
                        @click.prevent="removeSelected(key)"
                      >
                        <i class="fas fa-times"></i> </a
                    ></span>
                  </base-button>
                </div>
              </div>
            </form>
          </div>
          <!-- End Case 0 -->
          <div v-if="type === 1">
            <form @submit.prevent="push">
              <div class="row">
                <div
                  class="col-md-5 d-flex my-auto pr-0  max-col-label-for-schedule "
                >
                  <div
                    class="col-md-6 pr-0 max-col-date-for-schedule mb-1 mt-1"
                  >
                    <base-input
                      class="p-0"
                      type="date"
                      v-model="period[0]"
                      @change="changePeriod(0)"
                      input-classes="form-control-sm h-100"
                    />
                  </div>
                  <div
                    class="col-md-6 pr-0 max-col-date-for-schedule mb-1 mt-1"
                  >
                    <base-input
                      class="p-0"
                      type="date"
                      :disabled="!period[0]"
                      v-model="period[1]"
                      @change="changePeriod"
                      input-classes="form-control-sm h-100"
                    />
                  </div>
                </div>
                <div class="col-md-3 p-2 pl-3">
                  <base-input v-model="filtered" placeholder="Pesquisar">
                    <template slot="append">
                      <a href="#" @click.prevent="push">
                        <i
                          style="color: #2d3748"
                          :style="
                            global.length ? 'opacity: 80%' : 'opacity: 50%'
                          "
                          class=" fas fa-search"
                        ></i>
                      </a>
                    </template>
                  </base-input>
                </div>
                <div class="col-md-1 p-2">
                  <base-button
                    title="Limpar filtros"
                    :disabled="!global.length && (!period[0] || !period[1])"
                    @click.prevent="clearFilters"
                    block
                    :type="global.length ? 'danger' : 'secondary'"
                  >
                    <i class="fas fa-1x fa-eraser"></i>
                  </base-button>
                </div>
                <base-dropdown class="col-md-3 p-2">
                  <base-button
                    slot="title-container"
                    block
                    :type="
                      status[filter.status]
                        ? status[filter.status].type
                        : 'primary'
                    "
                    class="dropdown-toggle text-white"
                  >
                    {{
                      status[filter.status]
                        ? status[filter.status].name
                        : "Todos"
                    }}
                  </base-button>
                  <div>
                    <a
                      :key="index"
                      v-for="(item, index) in status"
                      v-if="JSON.parse(index) != filter.status"
                      @click.passive="filter.status = JSON.parse(index)"
                      @click.prevent="handleFilterClick"
                      class="dropdown-item"
                      >{{ item.name }}</a
                    >
                  </div>
                </base-dropdown>
              </div>
              <div class="row mt-0">
                <div class="col-md-12">
                  <base-button
                    class="mt-2"
                    :key="key"
                    v-for="(value, key) in global"
                    type="primary"
                  >
                    <span class="text-uppercase"
                      >{{ value }}
                      <a
                        href="#"
                        class="text-white"
                        @click.prevent="removeSelected(key)"
                      >
                        <i class="fas fa-times"></i> </a
                    ></span>
                  </base-button>
                </div>
              </div>
            </form>
          </div>
          <!-- End Case 1 -->
          <div v-if="type === 2">
            <form @keypress.space="push" @submit.prevent="push">
              <div class="row">
                <div class="col-md-6 p-2" :class="!status && 'col-md-10'">
                  <base-input v-model="filtered" placeholder="Pesquisar">
                    <template slot="append">
                      <a href="#" @click.prevent="push">
                        <i
                          style="color: #2d3748"
                          :style="
                            global.length ? 'opacity: 80%' : 'opacity: 50%'
                          "
                          class=" fas fa-search"
                        ></i>
                      </a>
                    </template>
                  </base-input>
                </div>
                <div class="col-md-2 p-2">
                  <base-button
                    title="Limpar filtros"
                    :disabled="!global.length"
                    @click.prevent="clearFilters"
                    block
                    :type="global.length ? 'danger' : 'secondary'"
                  >
                    <!-- <i class="fas fa-1x fa-eraser"></i> -->
                  </base-button>
                </div>
                <base-dropdown class="col-md-4 p-2" v-if="status">
                  <base-button
                    slot="title-container"
                    block
                    :type="
                      status[filter.status]
                        ? status[filter.status].type
                        : 'primary'
                    "
                    class="dropdown-toggle text-white"
                  >
                    {{
                      status[filter.status]
                        ? status[filter.status].name
                        : "Padrão"
                    }}
                  </base-button>
                  <div>
                    <a
                      :key="index"
                      v-for="(item, index) in status"
                      v-if="JSON.parse(index) != filter.status"
                      @click.passive="filter.status = JSON.parse(index)"
                      @click.prevent="handleFilterClick"
                      class="dropdown-item"
                      >{{ item.name }}</a
                    >
                  </div>
                </base-dropdown>
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <base-button
                    class="mt-2"
                    :key="key"
                    v-for="(value, key) in global"
                    type="primary"
                  >
                    <span class="text-uppercase"
                      >{{ value }}
                      <a
                        href="#"
                        class="text-white"
                        @click.prevent="removeSelected(key)"
                      >
                        <i class="fas fa-times"></i> </a
                    ></span>
                  </base-button>
                </div>
              </div>
            </form>
          </div>
          <!-- End Case 2 -->
          <div v-if="type === 3">
            <form role="form" @submit.prevent="setGeneralFilters">
              <div class="row">
                  <div
                    class="col-md-2 p-1 "
                  >
                    <label>Slump/Flow</label>
                    <base-input input-classes="form-control">
                      <el-select class="select-lg"
                                 @change="setGeneralFilters"
                                 size="mini"
                                 v-model="slump_filter"
                                 placeholder="Selecione"
                                 filterable
                                 :loading="loadingSlumpFlow"
                                 :disabled="loadingSlumpFlow"
                      >
                        <el-option v-for="item in $_slump_flows" :key="item.id"
                                   :label="item.nomenclature"
                                   :value="item.nomenclature"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
<!--                    <base-input-->
<!--                      class="p-0"-->
<!--                      placeholder="Slump/Flow"-->
<!--                      v-model="slump_filter"-->
<!--                    />-->
                  </div>
                  <div
                    class="col-md-2 p-1 "
                  >
                    <label>Dimensão Máx.</label>
                    <base-input input-classes="form-control">
                      <el-select class="select-lg"
                                 @change="setGeneralFilters"
                                 size="mini"
                                 v-model="gravel_filter"
                                 placeholder="Selecione"
                                 filterable
                                 :loading="loadingMaximumSize"
                                 :disabled="loadingMaximumSize"
                      >
                        <el-option v-for="item in $_maximum_sizes" :key="item.id"
                                   :label="item.description"
                                   :value="item.description"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                <div class="col-md-5  p-1 col-search search">
                  <base-input v-model="filtered" placeholder="Pesquisar">
                    <template slot="append">
                      <base-button
                        style="background-color: transparent; border:none;box-shadow: none;"
                        @click.prevent="setGeneralFilters"
                        native-type="submit"
                        class="p-0 pt-1"
                        size="sm"
                      >
                        <i
                          style="color: #2d3748"
                          :style="
                            global.length ? 'opacity: 80%' : 'opacity: 50%'
                          "
                          class=" fas fa-search"
                        ></i>
                      </base-button>
                    </template>
                  </base-input>
                </div>
                <div class="col-md-1  p-1 col-search">
                  <base-button
                    title="Limpar filtros"
                    :disabled="
                      !global.length && !slumps.length && !gravels.length
                    "
                    @click.prevent="clearGeneralFilters"
                    block
                    :type="
                      global.length || slumps.length || gravels.length
                        ? 'danger'
                        : 'secondary'
                    "
                  >
                    <!-- <i class="fas fa-1x fa-eraser"></i> -->
                  </base-button>
                </div>
                <base-dropdown class="col-md-2 p-1 col-search">
                  <base-button
                    block
                    slot="title-container"
                    blockDuplicate
                    keys
                    detected
                    :type="
                      status[filter.status]
                        ? status[filter.status].type
                        : 'primary'
                    "
                    class="dropdown-toggle text-white"
                  >
                    {{
                      status[filter.status]
                        ? status[filter.status].name
                        : "Padrão"
                    }}
                  </base-button>
                  <div>
                    <a
                      :key="index"
                      v-for="(item, index) in status"
                      v-if="JSON.parse(index) != filter.status"
                      @click.passive="filter.status = JSON.parse(index)"
                      @click.prevent="handleGeneralFilterClick"
                      class="dropdown-item"
                      >{{ item.name }}</a
                    >
                  </div>
                </base-dropdown>
              </div>
              <div class="row mt-0">
                <div class="col-md-12" :class="type ===3 && 'p-1'">
                  <base-button
                    class="mt-2"
                    :key="key + 'a'"
                    v-for="(value, key) in global"
                    type="primary"
                  >
                    <span class="text-uppercase"
                      >{{ value }}
                      <a
                        href="#"
                        class="text-white"
                        @click.prevent="removeGeneralSelected(key,1)"
                      >
                        <i class="fas fa-times"></i> </a
                    ></span>
                  </base-button>
                  <base-button
                    class="mt-2"
                    :key="key + 'b'"
                    v-for="(value, key) in slumps"
                    type="primary"
                  >
                    <span class="text-uppercase"
                      >{{ value }}
                      <a
                        href="#"
                        class="text-white"
                        @click.prevent="removeGeneralSelected(key,2)"
                      >
                        <i class="fas fa-times"></i> </a
                    ></span>
                  </base-button>
                  <base-button
                    class="mt-2"
                    :key="key + 'c'"
                    v-for="(value, key) in gravels"
                    type="primary"
                  >
                    <span class="text-uppercase"
                      >{{ value }}
                      <a
                        href="#"
                        class="text-white"
                        @click.prevent="removeGeneralSelected(key,3)"
                      >
                        <i class="fas fa-times"></i> </a
                    ></span>
                  </base-button>
                </div>
              </div>
            </form>
          </div>
          <!-- End case 3 -->
          <div v-if="type === 4">
            <form @keypress.space="push" @submit.prevent="push">
              <div class="row">
                <div class="col-md-6 p-2" :class="!status && 'col-md-10'">
                  <div class="d-flex w-100" style="height: 2.7rem;">
                    <input @keydown.enter="push" @keypress.space="push" v-model="filtered" placeholder="Pesquisar"
                      style="height: 2.7rem;border-radius: 4px !important;-webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)"
                      class="form-control w-100" />
                    <img @click.prevent="push" src="/img/icons/icons8/ios/search.png" height="18"
                      style="margin-top: 10px" class="default-search zoom pointer">
                  </div>
                </div>
                <div class="col-md-2 p-2">
                  <base-button @click.prevent="clearFilters"
                    :disabled="!global.length"
                    style="border: 1px solid #d7d6d6;height: 2.7rem;border-radius: 8px !important;"
                    type="white" block>
                    <div style="margin-top: -4px">
                      <img height="21" src="/img/icons/icons8/ios/synchronize.png" alt="synchronize" />
                    </div>
                  </base-button>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <base-button
                    class="mt-2"
                    :key="key"
                    v-for="(value, key) in global"
                    type="primary"
                  >
                    <span class="text-uppercase"
                      >{{ value }}
                      <a
                        href="#"
                        class="text-white"
                        @click.prevent="removeSelected(key)"
                      >
                        <i class="fas fa-times"></i> </a
                    ></span>
                  </base-button>
                </div>
              </div>
            </form>
          </div>
          <!-- End case 4 -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "MultiFilter",
  props: {
    filter: {
      type: [Array, Object],
      required: true
    },
    type: {
      type: Number,
      default: 0
    },
    status: {
      type: Object,
      default: function() {
        return {
          null: {
            name: "Todos",
            type: "primary"
          },
          0: {
            name: "Inativo",
            type: "danger"
          },
          1: {
            name: "Ativo",
            type: "success"
          },
        };
      },
      required: false
    }
  },
  beforeMount() {
    if (this.type === 3) {
      this.loadingSlumpFlow = true
      this.loadingMaximumSize = true
      this.$store.dispatch('mixSlumpFlow/fetchItems').then(() => {
        this.loadingSlumpFlow = false
      })
      this.$store.dispatch('mixMaximumSize/fetchItems').then(() => {
        this.loadingMaximumSize = false
      })
    }
  },
  computed: {
    ...mapGetters({
      '$_slump_flows': 'mixSlumpFlow/activeItems',
      '$_maximum_sizes': 'mixMaximumSize/activeItems',
    }),
  },
  data() {
    return {
      global: [],
      loadingSlumpFlow: false,
      loadingMaximumSize: false,
      filtered: null,
      period: [],
      slump_filter: null,
      gravel_filter: null,
      slumps: [],
      gravels: []
    };
  },
  methods: {
    push() {
      if (this.filtered && this.filtered !== ' ') {
        this.global.push(this.filtered);
      }
      this.filtered = null;
      this.handleFilterClick();
    },
    clearFilters() {
      this.global = [];
      const filters = {...this.filter};
      filters.global = this.global;
      this.$parent.filter = []
      this.$emit('fetch', filters)
    },
    changePeriod(type = 1) {
      if (this.period[0] && this.period[1]) {
        this.handleFilterClick();
      } else if (this.period[0] && !this.period[1] && type === 0) {
        this.period[1] = this.period[0];
        this.$forceUpdate();
        this.handleFilterClick();
      }
    },
    handleFilterClick() {
      const filters = { ...this.filter };
      filters.global = this.global;
      if (this.type === 1) {
        filters.period = this.period;
      }
      if (filters.status === null) {
        delete filters.status;
      }
      this.$emit("fetch", filters);
    },
    removeSelected(key) {
      this.global.splice(key, 1);
      this.handleFilterClick();
    },

    /*
     * Função para setar os 3 tipos de filtros,
     * para slump, brita e geral.
     */
    setGeneralFilters() {
      if (this.filtered) {
        this.global.push(this.filtered);
        this.filtered = null;
      }
      if (this.slump_filter) {
        this.slumps.push(this.slump_filter);
        this.slump_filter = null;
      }
      if (this.gravel_filter) {
        this.gravels.push(this.gravel_filter);
        this.gravel_filter = null;
      }
      this.handleGeneralFilterClick();
    },
    clearGeneralFilters() {
      this.global = [];
      this.slumps = [];
      this.gravels = [];
    },
    handleGeneralFilterClick() {
      const filters = { ...this.filter };
      filters.global = this.global;
      filters.slumps = this.slumps;
      filters.gravels = this.gravels;
      if (!filters.status) {
        delete filters.status;
      }
      this.$emit("fetch", filters);
    },
    removeGeneralSelected(key, type) {
      switch (type) {
        case 1:
          this.global.splice(key, 1);
          break;
        case 2:
          this.slumps.splice(key, 1);
          break;
        case 3:
          this.gravels.splice(key, 1);
          break;
      }
      this.handleFilterClick();
    }
  }
};
</script>

<style scoped>
@media (max-width: 500px) {
  .max-col-label-for-schedule {
    display: block !important;
    padding: 0 7px !important;
  }
  .max-col-date-for-schedule {
    padding: 0 !important;
  }
}
@media (min-height: 222px) and (min-width: 500px) {
  .col-search {
    margin-top: 30px;
  }
}
@media (max-width: 222px) {
  .search {
    margin-top: 8px;
  }
}


.btn-success-light {
  background-color: #68b879;
  border-color: #68b879;
}
</style>
