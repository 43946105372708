import { createAxios } from "@/plugins/axios";
import {cursorPaginate, setUrlWithNextPage, formatResponseData} from '@/store/baseStore'

const endPoint = '/configuration'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
  },
  getters:{
    show: state => state.item,
    fetch: state => state.items,
  },
  actions: {
    fetchItemsPaginate({ state }, params) {
      return createAxios().get(`${endPoint}/product-services`, { params: params })
        .then(({ data }) => {
          data.data.items = data.data.items.map(item => ({ ...item, product_service_id: item.id, quantity: "1,000"}));  
          const formattedData = formatResponseData(data);
          cursorPaginate(state, { data: formattedData.data, next_page: params.page });
          return Promise.resolve(data);
        }).catch(error => {
          return Promise.reject(error);
        });
    },
  }
}
