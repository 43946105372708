import { operationSourceUrls } from ".";
import httpClient from "../../../../shared/libraries/httpClient";
import { handleUnexpectedStatusCode } from "../../../../shared/services";
import { HttpStatusEnum } from "../../../../shared/enums";
import { OperationSourceUpdateType, OperationSourceViewType } from "../types";

/**
 * Atualizar por ID
 *
 * @param {OperationSourceUpdateType} data
 * @param {number} id
 * @returns {Promise<OperationSourceViewType>}
 */
const execute = async (data, id) => {
  const url = `${operationSourceUrls.BASE}/${id}`;
  const res = await httpClient.put(url, data);

  switch (res.status) {
    case HttpStatusEnum.HTTP_OK:
      return res.data.data;
    default:
      handleUnexpectedStatusCode(res);
  }
};

export default { execute };
