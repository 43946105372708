<template>
  <div>
    <!-- Header -->
    <div class="header  py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-dark">Ooops!</h1>
              <p class="text-lead text-dark">Pagina não encontrada</p>

              <router-link to="/dashboard" class="font-weight-bold text-dark mt-5">Voltar para o Dashboard</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page content -->

  </div>
</template>

<script>

export default {
  data() {
      return {
        name: 'not-found'
      };
    },
};
</script>
