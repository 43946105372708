/**
 * @typedef {Object} CmcExcelFilterType
 * @property {?number} company_plant_id
 */
export const CmcExcelFilterType = {};

/**
 * @returns {CmcExcelFilterType}
 */
export const initCmcExcelFilterType = () => ({
  order_by: [{ column: "cmcs.id", is_desc: false }],
  filter: {
    company_plant_id: null,
  }
});
