export const clearStorage = () =>  {
  localStorage.removeItem('puzl_token');
  localStorage.removeItem('expires_in');
  localStorage.removeItem('puzl_user');
}


export const getPuzlToken = () => localStorage.getItem('puzl_token');
export const setPuzlToken = token => localStorage.setItem('puzl_token', token);
export const setExpiresIn = expiresIn => localStorage.setItem('expires_in', expiresIn);
export const setPuzlUser = user => localStorage.setItem('puzl_user', JSON.stringify(user));
export const getPuzlUser = () => JSON.parse(localStorage.getItem('puzl_user'));
export const getExpiresIn = () => localStorage.getItem('expires_in')
