<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-4">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(store)"
            autocomplete="off"
          >
            <div>
              <!-- Central -->
              <div class="form-group row m-0 p-0 ">
                <label
                  class="col-md-6 pb-0 mb-1 col-form-label form-control-label pt-0 pb-3"
                >
                  <div class="border-left border-primary border-3 pl-2  ">
                    {{ user.name }}
                  </div>
                </label>
              </div>
              <!-- Central -->
              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-6 pb-0 mb-1 col-form-label form-control-label pl-4"
                >
                  Central padrão <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-6 mb-1 pr-4">
                  <validation-provider rules="required">
                    <base-input input-classes="form-control-sm">
                      <PuzlSelect 
                        v-model="temperature.company_plant_id"
                        :items="plants"  
                        :disabled="loadingPlants"
                        :loading="loadingPlants" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Solicitação automática  -->
              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-6 pb-0 mb-1 pt-2 col-form-label form-control-label pl-4"
                >
                  Solicitação automática a cada <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-6 mb-1 pr-4">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        inputmode="numeric"
                        v-model="temperature.auto_request"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          horas
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Preenchimento automático -->
              <div class="form-group row m-0 p-0 align-middle">
                <label
                  class="col-md-6 pb-0 mb-1 col-form-label form-control-label pl-4"
                >
                  Preenchimento automático
                </label>
                <div class="col-md-6 align-middle pt-1">
                  <base-switch
                    v-model="status"
                    type="success"
                    offText="não"
                    onText="sim"
                    class="success"
                  ></base-switch>
                </div>
              </div>
              <div class="modal-footer">
                <base-button type="secondary" @click="closeModal('create')">
                  Cancelar
                </base-button>
                <base-button
                  type="success"
                  native-type="submit"
                  v-bind:disabled="invalid"
                  :loading="loadingStore"
                >
                  Salvar
                </base-button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { checkNumberValue } from "@/helpers";
import PuzlSelect from "@/components/PuzlSelect";
import { mask1, formatErrorValidation } from "@/plugins";

export default {
  name: "ModalCreatetemperatureSetting",
  components: { PuzlSelect },
  data() {
    return {
      exists: false,
      status: true,
      modal: {
        title: "Automatizações",
        create: false
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      temperature: {
        uuid: null,
        company_plant_id: null,
        auto_complete: true,
        auto_request: null
      },
      loadingStore: false,
      loadingPlants: true,
      selectedGroups: [],
    };
  },
  computed: {
    ...mapGetters({
      plants: "plant/activeItems",
      user: "auth/getUser"
    })
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    handleCreateModal() {
      let loader = this.$loading.show();
      this.loadData();
      (this.exists = false),
        this.$store
          .dispatch("temperatureSetting/getByUser")
          .then(response => {
            this.temperature = {
              company_plant_id: response.data.company_plant.id,
              auto_request: response.data.auto_request,
              auto_complete: response.data.auto_complete,
              uuid: response.data.uuid
            };
            this.exists = response.data.uuid ? true : false;
            this.status = response.data.auto_complete === 1 ? true : false;
            loader.hide();
            this.$notify({
              type: response.error_type,
              message: response.message
            });
            this.modal.create = true;
          })
          .catch(error => {
            loader.hide();
            this.modal.create = true;
          });
    },
    store() {
      this.$Progress.start();
      this.loadingStore = true;
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.temperature.auto_complete = this.status ? 1 : 0;
      if (this.exists) {
        this.$store
          .dispatch("temperatureSetting/update", this.temperature)
          .then(response => {
            this.$Progress.finish();
            this.$notify({
              limit: response.error_type,
              message: response.message
            });
            this.loadingStore = false;
            this.modal.create = false;
          })
          .catch(error => {
            if (error.response.status === 422) {
              let errors = formatErrorValidation(error.response.data.errors);
              this.$notify({
                limit: "danger",
                message: errors
              });
            }
            this.$Progress.finish();
            this.loadingStore = false;
          });
      } else {
        this.$store
          .dispatch("temperatureSetting/add", this.temperature)
          .then(response => {
            this.temperature.auto_complete =
              response.data.auto_complete === 1 ? true : false;
            this.loadingStore = false;
            this.modal.create = false;
            this.$Progress.finish();
            this.$notify({
              limit: response.error_type,
              message: response.message
            });
          })
          .catch(error => {
            if (error.response.status === 422) {
              let errors = formatErrorValidation(error.response.data.errors);
              this.$notify({
                limit: "danger",
                message: errors
              });
            }
            this.$Progress.finish();
            this.loadingStore = false;
          });
      }
    },
    loadData() {
      this.loadingPlants = true;
      this.$store.dispatch('plant/getPlantsSimplified').then(() => {
          this.loadingPlants = false;
      });
    },
  },
};
</script>

<style scoped></style>
