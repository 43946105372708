<template>
  <div>
    <PageHeader title="EXTRATO DO BANCO"
    icon="/img/icons/exchange.png"
    type="warning">
      <PageHeaderButton :isDropdown="true"
          type="success"
          text="Opções">
        <PageHeaderButtonDropItem
          text="Operação financeira"
          icon="/img/icons/exchange.png"
          @click.prevent="handleSearchEntity" />
        <PageHeaderButtonDropItem
          text="Crédito e antecipação"
          icon="/img/icons/dollar-purple.png"
          @click.prevent="handleCreateModalEntity" />
        <PageHeaderButtonDropItem
          text="Transferir"
          icon="/img/icons/icons8/ios/initiate-money-transfer_success.png"
          @click.prevent="handleModalCreateBankTransfer" />
      </PageHeaderButton>
      <PageHeaderButton @click.prevent="handleAutoAssociation"
        text="Baixa Automática"
        type="primary"
        icon="/img/icons/icons8/ios/link.png" />
      <PageHeaderButton @click.prevent="back()"
        text="Voltar"
        type="light"
        icon="/img/icons/circled-left.png" />
    </PageHeader>
    <!-- Lista de Transações do Banco -->
    <list-bank-transaction
      ref="listBankTransaction">
    </list-bank-transaction>
    <ModalCreateEntity ref="modalCreateEntity"/>
    <ModalSearchEntity @selected="storeGuarantorEntity" ref="modalSearchEntity" />
    <ModalCreateFinance @refresh="updateScreen" ref="modalCreateFinance" />
    <!-- @created="updateScreen" -->
    <ModalCreateBankTransfer  ref="modalCreateBankTransfer" />
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import ListBankTransaction from './Shared/_ListBankTransaction';
import ModalSearchEntity from "@/views/Modules/Configuration/Entity/Shared/_ModalSearchEntity";
import ModalCreateFinance from "../../../../Financial/Finance/Shared/_ModalCreateFinance";
import ModalCreateBankTransfer from "@/views/Modules/Financial/BankTransfer/Shared/_ModalCreate";
import ModalCreateEntity from '@/views/Modules/Financial/BillReceive/Entity/Shared/Modals/_ModalCreateEntity';
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import { PageHeader, PageHeaderButton, PageHeaderButtonDropItem } from "@/components";

export default {
  name: "BankReconciliationTransaction",
  components: {
    ListBankTransaction,
    ModalCreateEntity,
    ModalSearchEntity,
    BaseButtonHoverable,
    PageHeader,
    PageHeaderButton,
    PageHeaderButtonDropItem,
    ModalCreateFinance,
    ModalCreateBankTransfer,
  },
  data(){
    return {
      isCreatingFinance: null,
      savedToGoBackFilters: {},
    }
  },
  computed: {
    ...mapGetters({
      $_bank_reconciliation: 'bankReconciliation/show',
      $_bank_reconciliation_transaction: 'bankReconciliationTransaction/fetch',
    }),
  },
  methods: {
    updateScreen(){
      this.$notify({
        type: 'success',
        message: `Cadastro realizado com sucesso!`
      });
    },
    handleModalCreateBankTransfer () {
      this.$refs.modalCreateBankTransfer.handleCreateModal()
    },
    handleSearchEntity() {
      this.isCreatingFinance = true;
      this.$refs.modalSearchEntity.openModal(null, false);
    },
    storeGuarantorEntity(entity){
      if(this.isCreatingFinance){
        this.$refs.modalCreateFinance.openModal(entity);
      } else {
        this.$refs.modalCreateEntity.handleCreateModal(entity)
      }
      this.isCreatingFinance = null;
    },
    back(){
      this.$router.push({
        name: 'cash_flow.bank_reconciliation.view',
        params: {
          filters: this.savedToGoBackFilters
        },
      });
    },
    handleCreateModalEntity(has_identification = true)
    {
      this.isCreatingFinance = false;
      if (has_identification) {
       return this.$refs.modalSearchEntity.openModal(null, true);
      }
      this.$refs.modalCreateEntity.handleCreateModal(null)
    },
    handleAutoAssociation() {
      this.$swal.fire({
        title: 'Você tem certeza?',
        text: 'Essa ação pode afetar todas as transações',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, continuar!',
        cancelButtonText: 'Não, cancelar',
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let loader = this.$loading.show()
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          this.$store.dispatch('bankReconciliation/postAutoAssociation', {
            bank_reconciliation_id: this.$_bank_reconciliation.id,
          }).then((response) => {
            this.$notify({type: response.error_type, message: response.message})
            loader.hide()
            for (let i in this.$_bank_reconciliation_transaction) {
              if (!this.$_bank_reconciliation_transaction[i].has_association) {
                this.$_bank_reconciliation_transaction[i].pending_auto_association = true
              }
            }
            // Atualizar registros após 2s
            setTimeout(() => this.$refs.listBankTransaction.init(), 2000);
          }).catch(error => {
            loader.hide()
          })
        }
      })
    },
    handleAutoConciliation(){
      this.$swal.fire({
        title: 'Você tem certeza?',
        text: 'Confirme os dados antes de realizar a conciliação!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, continuar!',
        cancelButtonText: 'Não, cancelar',
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          let loader = this.$loading.show();
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          this.$store.dispatch('bankReconciliation/postAutoConciliation', this.$_bank_reconciliation.id).then((response) => {
            this.$notify({type: response.error_type, message: response.message});
            loader.hide();
            this.$refs.listBankTransaction.init();
          }).catch(error => {
            loader.hide();
          })
        }
      })
    },
  },
  beforeMount(){
    this.savedToGoBackFilters = this.$route.params.filters;
  }
}
</script>

<style></style>
