<template>
  <div>
    <div class="container-fluid">
      <div class="row mb-4">
        <div class="col-md-4 mb-2">
          <base-button
            @click.prevent="scatterChartDataType = 0"
            block
            size="md"
            outline
            :class="scatterChartDataType === 0 && 'active'"
            :loading="loading"
            type="primary"
          >
            <i data-v-2d99b150="" class=" fa-solid fa-person-digging" ></i>
            OBRA
          </base-button>
        </div>
        <div class="col-md-4 mb-2">
          <base-button
            @click.prevent="scatterChartDataType = 2"
            block
            size="md"
            outline
            :class="scatterChartDataType === 2 && 'active'"
            :loading="loading"
            type="primary"
          >
            <i class="fa-solid fa-building"></i>
            Central
          </base-button>
        </div>
        <div class="col-md-4 mb-2">
          <base-button
            @click.prevent="scatterChartDataType = 1"
            block
            size="md"
            :class="scatterChartDataType === 1 && 'active'"
            :loading="loading"
            outline
            type="primary"
          >
            <i class="fa-solid fa-user-tag"></i>
            Vendedor
          </base-button>
        </div>
      </div>
      <div>
        <SkeletonPuzlFullWidth v-show="loading" />
      </div>
    </div>
    <div v-if="!loading">
      <ScatterChart :option="scatterChart" :theme="theme" />
    </div>

    <div class="container-fluid" v-if="!loadingTreemap">
      <div class="row mb-4">
        <div class="col-md-6 mb-2 d-flex justify-content-center pr-5 pl-5">
          <base-button
            :type="treemapChartDataType === 0 ? 'success' : 'secondary'"
            :loading="loading"
            @click.prevent="treemapChartDataType = 0"
            block
            size="md"
          >
            Central
          </base-button>
        </div>
        <div class="col-md-6 mb-2 d-flex justify-content-center pr-5 pl-5">
          <base-button
            @click.prevent="treemapChartDataType = 1"
            :loading="loading"
            :type="treemapChartDataType === 1 ? 'success' : 'secondary'"
            block
            size="md"
          >
            Vendedor
          </base-button>
        </div>
      </div>
      <div>
        <SkeletonPuzlFullWidth v-show="loadingTreemap" />
      </div>
    </div>
    <div v-if="!loadingTreemap">
      <TreemapChart v-show="treemapChart" :option="treemapChart" :theme="theme" />
    </div>
  </div>
</template>

<script>
import ScatterChart from "@/components/Echarts/ScatterChart.vue";
import TreemapChart from "@/components/Echarts/TreemapChart.vue";
import { mapGetters } from "vuex";
import SkeletonPuzlFullWidth from "@/components/SkeletonPuzlFullWidth";

export default {
  name: "DashboardChart",
  components: {
    ScatterChart,
    SkeletonPuzlFullWidth,
    TreemapChart
  },

  props: {
    theme: {
      type: Boolean,
      default: false,
      description: "Tema"
    },
    decal: {
      type: Boolean,
      default: false,
      description: "Estilização adicional"
    }
  },

  computed: {
    ...mapGetters({
      scatterChart: "commercialDashboard/scatter",
      treemapChart: "commercialDashboard/treemap",
      cards: "commercialDashboard/cards",
      loading: "commercialDashboard/loading",
      loadingTreemap: "commercialDashboard/loadingTreemap"
    })
  },

  watch: {
    treemapChartDataType: function(val) {
      this.$store.dispatch("commercialDashboard/changeTreemapChartData", val);
    },
    scatterChartDataType: function(val) {
      this.$store.dispatch("commercialDashboard/changeScatterChartData", val);
    }
  },

  data() {
    return {
      scatterChartDataType: 0,
      treemapChartDataType: 0
    };
  },
  methods: {
    resetDefaultTypes() {
      this.scatterChartDataType = 0;
      this.treemapChartDataType = 0;
    }
  },
  mounted() {}
};
</script>

<style></style>
