<template>
  <div>
    <modal :show.sync="modal" size="md">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div class="p-3">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation">
            <input type="text" style="display:none">
            <input type="password" style="display:none">
            <div>
              <div class="border-left border-danger border-3 p-2" v-if="travel">
                <span class="h3 d-block">{{ travel.number }} <small>({{travel.index}})</small></span>
                <span class="h3 d-block  small">DANFE</span>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <label
                class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
              >
                Categoria
                <span class="text-danger">*</span>
              </label>
            </div>
            <div class="form-group row m-0 mb-2 p-1">
              <div
                v-for="(category, indexCategory) in categories"
                :key:="indexCategory"
              >
                <div v-if="validCategory(category)" @click.prevent="handleSelectCategory(category)" class="border d-flex justify-content-between p-2 mt-1 card--hover">
                  <div >
                   <span
                     class="h3 d-block"
                     :class="`text-${category.color}`"
                   >
                     {{ category.title }}
                   </span>
                    <span class="text-body small text-wrap">
                     {{ category.description }}
                   </span>
                  </div>
                  <div class="col-2 text-right pl-0 ml-5 ">
                    <base-button
                      style="padding: 0.32rem 0.5rem !important"
                      v-if="category === categorySelected"
                      class=""
                      type="success"
                    >
                      <i class="fa-regular fa-circle-check"></i>
                    </base-button>
                    <base-button
                      style="padding: 0.32rem 0.5rem !important"
                      v-if="category !== categorySelected"
                      class=""
                      outline
                      type="success"
                    >
                      <i class="fa-regular fa-circle-check "></i>
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <label
                class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
              >
                Motivo
              </label>
              <div class="col-md-7">
                <base-input input-classes="form-control-sm">
                  <el-select
                    v-model="concreteLeftOver.reasons"
                    size="mini"
                    filterable
                    multiple
                    placeholder="Selecione"
                  >
                    <el-option
                      autocomplete="off"
                      v-for="leftOver in leftOvers"
                      :key="leftOver.uuid"
                      :label="leftOver.reason"
                      :value="leftOver.uuid"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label
                class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
              >
                Volume de Retorno/Reuso
                <span class="text-danger">*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required">
                  <!--                  <base-input input-group-classes="input-group-sm">-->
                  <!--                    <input-->
                  <!--                      :value.sync="concreteLeftOver.volume"-->
                  <!--                      @input="concreteLeftOver.volume = teste($event)"-->
                  <!--                      inputmode="numeric"-->
                  <!--                      v-mask="['#', '#,#', '##,#', '###,#','#.###,#','##.###,#','###.###,#','#.###.###,#','##.###.###,#']"-->
                  <!--                      class="form-control form-control-sm"-->
                  <!--                    />-->
                  <!--                    <template slot="append">-->
                  <!--                      <small class="input-group-sm p-0 m-0">-->
                  <!--                        m<sup>3</sup>-->
                  <!--                      </small>-->
                  <!--                    </template>-->
                  <!--                  </base-input>-->
                </validation-provider>
                <div>
                  <base-input input-group-classes="input-group-sm">
                    <input class="form-control form-control-sm"
                           :disabled="concreteLeftOver.travel_reference_uuid && concreteLeftOver.travel_reference_uuid !== null"
                           inputmode="numeric"
                           v-model="concreteLeftOver.volume"/>
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0">
                        m<sup>3</sup>
                      </small>
                    </template>
                  </base-input>
                </div>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <label
                class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
              >
                Temperatura ambiente
                <span v-if="concreteLeftOver.category === 1" class="text-danger">*</span>
              </label>
              <div class="col-md-7">
                <validation-provider
                  :rules="concreteLeftOver.category && concreteLeftOver.category === 1 ? 'required' : null">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      :disabled="concreteLeftOver.travel_reference_uuid && concreteLeftOver.travel_reference_uuid !== null"
                      maxlength="2"
                      v-model="concreteLeftOver.ambient_temperature"
                      inputmode="numeric"
                      class="form-control form-control-sm"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0">
                        <sup>o</sup>C
                      </small>
                    </template>
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label
                class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
              >
                Temperatura do concreto
                <span v-if="concreteLeftOver.category === 1" class="text-danger">*</span>
              </label>
              <div class="col-md-7">
                <validation-provider
                  :rules="concreteLeftOver.category && concreteLeftOver.category === 1 ? 'required' : null">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      :disabled="concreteLeftOver.travel_reference_uuid && concreteLeftOver.travel_reference_uuid !== null"
                      maxlength="2"
                      v-model="concreteLeftOver.concrete_temperature"
                      inputmode="numeric"
                      class="form-control form-control-sm"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0">
                        <sup>o</sup>C
                      </small>
                    </template>
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label
                class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
              >
                Variação de temperatura
              </label>
              <div class="col-md-7">
                <!-- É uma formula (temperatura ambiente - temperatura de concreto) sempre tem que ser positivo. -->
                <base-input input-group-classes="input-group-sm">
                  <input
                    v-model="concreteLeftOver.variation_temperature"
                    inputmode="numeric"
                    class="form-control form-control-sm"
                    disabled
                  />
                  <template slot="append">
                    <small class="input-group-sm p-0 m-0">
                      <sup>o</sup>C
                    </small>
                  </template>
                </base-input>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label
                class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
              >
                Água adicionada (lavagem)
                <span v-if="concreteLeftOver.category === 1" class="text-danger">*</span>
              </label>
              <div class="col-md-7">
                <validation-provider
                  :rules="concreteLeftOver.category && concreteLeftOver.category === 1 ? 'required' : null">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      :disabled="concreteLeftOver.travel_reference_uuid && concreteLeftOver.travel_reference_uuid !== null"
                      v-model="concreteLeftOver.water"
                      v-mask="'####'"
                      inputmode="numeric"
                      class="form-control form-control-sm"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0">
                        litros
                      </small>
                    </template>
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label
                class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
              >
                Slump
                <span v-if="concreteLeftOver.category === 1" class="text-danger">*</span>
              </label>
              <div class="col-md-7">
                <validation-provider
                  :rules="concreteLeftOver.category && concreteLeftOver.category === 1 ? 'required' : null">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      :disabled="concreteLeftOver.travel_reference_uuid && concreteLeftOver.travel_reference_uuid !== null"
                      v-model="concreteLeftOver.slump"
                      inputmode="numeric"
                      class="form-control form-control-sm"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0">
                        mm
                      </small>
                    </template>
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <!--            <div class="form-group row m-0 p-0">-->
            <!--              <label-->
            <!--                class="col-md-5 pb-0 mb-1 col-form-label form-control-label"-->
            <!--              >-->
            <!--                Aditivo estabilizador?-->
            <!--              </label>-->
            <!--              <div class="col-md-7 pt-2">-->
            <!--                <base-switch-->
            <!--                  v-model="concrete_lefthover.stabilizer"-->
            <!--                  size="sm"-->
            <!--                  type="success"-->
            <!--                  class="success"-->
            <!--                >-->
            <!--                </base-switch>-->
            <!--              </div>-->
            <!--            </div>-->

            <!--            <div v-if="concrete_lefthover.stabilizer">-->
            <!--              <div class="form-group row m-0 p-0">-->
            <!--                <label-->
            <!--                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"-->
            <!--                >-->
            <!--                  MCC-->
            <!--                </label>-->
            <!--                <div class="col-md-7">-->
            <!--                  <base-input input-classes="form-control-sm">-->
            <!--                    <el-select-->
            <!--                      v-model="concrete_lefthover.reason"-->
            <!--                      size="mini"-->
            <!--                      filterable-->
            <!--                      multiple-->
            <!--                      placeholder="Selecione"-->
            <!--                    >-->
            <!--                      <el-option-->
            <!--                        v-for="select in selects"-->
            <!--                        :key="select.uuid"-->
            <!--                        :label="select.description"-->
            <!--                        :value="select.uuid"-->
            <!--                      >-->
            <!--                      </el-option>-->
            <!--                    </el-select>-->
            <!--                  </base-input>-->
            <!--                </div>-->
            <!--              </div>-->

            <!--              <div class="form-group row m-0 p-0">-->
            <!--                <label-->
            <!--                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"-->
            <!--                >-->
            <!--                  Tempo de estabilização-->
            <!--                </label>-->
            <!--                <div class="col-md-7">-->
            <!--                  <base-input input-classes="form-control-sm">-->
            <!--                    <el-select-->
            <!--                      v-model="concrete_lefthover.reason"-->
            <!--                      size="mini"-->
            <!--                      filterable-->
            <!--                      multiple-->
            <!--                      placeholder="Selecione"-->
            <!--                    >-->
            <!--                      <el-option-->
            <!--                        v-for="select in selects"-->
            <!--                        :key="select.uuid"-->
            <!--                        :label="select.description"-->
            <!--                        :value="select.uuid"-->
            <!--                      >-->
            <!--                      </el-option>-->
            <!--                    </el-select>-->
            <!--                  </base-input>-->
            <!--                </div>-->
            <!--              </div>-->

            <!--              <div class="form-group row m-0 p-0">-->
            <!--                <label-->
            <!--                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"-->
            <!--                >-->
            <!--                  Quantidade a ser adicionada-->
            <!--                </label>-->
            <!--                <div class="col-md-7">-->
            <!--                  <base-input input-group-classes="input-group-sm">-->
            <!--                    <input-->
            <!--                      inputmode="numeric"-->
            <!--                      class="form-control form-control-sm"-->
            <!--                    />-->
            <!--                    <template slot="append">-->
            <!--                      <small class="input-group-sm p-0 m-0">-->
            <!--                        litros-->
            <!--                      </small>-->
            <!--                    </template>-->
            <!--                  </base-input>-->
            <!--                </div>-->
            <!--              </div>-->

            <!--              <div>-->
            <!--                <div class="form-group row m-0 p-0">-->
            <!--                  <label-->
            <!--                    class="col-md-5 pb-0 mb-1 col-form-label form-control-label"-->
            <!--                  >-->
            <!--                    1<sup>a</sup> Adição-->
            <!--                  </label>-->
            <!--                  <div class="col-md-7">-->
            <!--                    <base-input input-group-classes="input-group-sm">-->
            <!--                      <input-->
            <!--                        inputmode="numeric"-->
            <!--                        class="form-control form-control-sm"-->
            <!--                      />-->
            <!--                      <template slot="append">-->
            <!--                        <small class="input-group-sm p-0 m-0">-->
            <!--                          litros-->
            <!--                        </small>-->
            <!--                      </template>-->
            <!--                    </base-input>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <div class="form-group row m-0 p-0">-->
            <!--                  <label-->
            <!--                    class="col-md-5 pb-0 mb-1 col-form-label form-control-label"-->
            <!--                  >-->
            <!--                    Horário-->
            <!--                  </label>-->
            <!--                  <div class="col-md-7">-->
            <!--                    <el-time-picker-->
            <!--                      size="mini"-->
            <!--                      value-format="HH:mm"-->
            <!--                      format="HH:mm"-->
            <!--                    >-->
            <!--                    </el-time-picker>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->

            <!--              <div>-->
            <!--                <div class="form-group row m-0 p-0">-->
            <!--                  <label-->
            <!--                    class="col-md-5 pb-0 mb-1 col-form-label form-control-label"-->
            <!--                  >-->
            <!--                    2<sup>a</sup> Adição-->
            <!--                  </label>-->
            <!--                  <div class="col-md-7">-->
            <!--                    <base-input input-group-classes="input-group-sm">-->
            <!--                      <input-->
            <!--                        inputmode="numeric"-->
            <!--                        class="form-control form-control-sm"-->
            <!--                      />-->
            <!--                      <template slot="append">-->
            <!--                        <small class="input-group-sm p-0 m-0">-->
            <!--                          litros-->
            <!--                        </small>-->
            <!--                      </template>-->
            <!--                    </base-input>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <div class="form-group row m-0 p-0">-->
            <!--                  <label-->
            <!--                    class="col-md-5 pb-0 mb-1 col-form-label form-control-label"-->
            <!--                  >-->
            <!--                    Horário-->
            <!--                  </label>-->
            <!--                  <div class="col-md-7">-->
            <!--                    <el-time-picker-->
            <!--                      size="mini"-->
            <!--                      value-format="HH:mm"-->
            <!--                      format="HH:mm"-->
            <!--                    >-->
            <!--                    </el-time-picker>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->

            <div class="modal-footer pb-0">
              <base-button type="secondary" @click="closeModal()">
                Cancelar
              </base-button>
              <base-button
                type="success"
                native-type="submit"
                @click.prevent="save"
                v-bind:disabled="invalid || concreteLeftOver.category == null"
                :loading="loadingSave"
              >
                Salvar
              </base-button
              >
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {
  debounce,
} from "@/plugins";
import {
  mask1
} from '@/plugins'
import {
  checkNumberValue
} from '@/helpers'

export default {
  components: {},
  name: "ModalConcreteLeftover",
  data() {
    return {
      modal: false,
      title: 'Sobra de concreto',
      loadingSave: false,
      showCategory: false,
      showStabilizer: false,
      loadingLeftOver: false,
      concreteLeftOver: {
        category: null,
        category_type: null,
        volume: null,
        ambient_temperature: null,
        concrete_temperature: null,
        variation_temperature: null,
        water: null,
        slump: null,
        schedule_travel_uuid: null,
        reasons: []
      },
      travel: null,
      categorySelected: null,
      volumeLimit: 10,
      isUpdate: false,
      categories: [
        {
          category_type: 1,
          category: 1,
          color: 'success',
          title: 'Reaproveitamento - Reuso',
          description: 'Quando o concreto ou parte daquele volume é reaproveitado para outra obra e duas notas fiscais são utilizadas para uma única dosagem.',
        },
        {
          category_type: 2,
          category: 1,
          color: 'primary',
          title: 'Redirecionamento - Reuso',
          description: 'Quando uma carga é redirecionada para outra obra é necessário emitir uma nova nota para substituir a antiga (cancelada).',
        },
        {
          category_type: 1,
          category: 2,
          color: 'warning',
          title: 'Sobra de Concreto - descarte',
          description: 'Toda sobra de concreto que é descartada e a nota já está assinada pelo cliente, caso contrário deve ser lançado como perda de concreto.',
        },
        {
          category_type: 2,
          category: 2,
          color: 'danger',
          title: 'Perda de Concreto - descarte',
          description: 'Quando é preciso descartar o concreto por algum erro de pesagem ou da concreteira e no caso o cliente não irá pagar pelo concreto descartado.',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      'leftOvers': 'adjustmentLeftOver/fetch',
    }),
  },
  methods: {
    validCategory(category) {
      if (category.category_type === 2) {
        if (this.travel && this.travel.status === 6) {
          return true
        }
        return false
      }
      else {
        if (this.travel && this.travel.status === 1) {
          return true
        }
        return false
      }

    },
    msgUp: debounce(function () {
      this.$notify({type: 'danger', message: `O volume de reuso não pode ser superior a ${this.volumeLimit}.`})
      this.$forceUpdate();
    }, 200),
    handleSelectCategory(category) {
      this.concreteLeftOver.category = category.category
      this.concreteLeftOver.category_type = category.category_type
      this.categorySelected = category
      this.showCategory = !this.showCategory
    },
    closeModal() {
      this.modal = false
    },
    openModal(params, volume = null) {
      this.travel = params
      this.concreteLeftOver = {
        category: null,
        category_type: null,
        volume: volume,
        ambient_temperature: null,
        concrete_temperature: null,
        variation_temperature: null,
        water: null,
        slump: null,
        schedule_travel_uuid: null,
        reasons: []
      }
      this.loadingLeftOver = true
      this.$store.dispatch('adjustmentLeftOver/fetchItems')
        .then((response) => {
          this.$Progress.finish()
          this.loadingLeftOver = false
        })
        .catch((error) => {
          this.$Progress.finish()
          this.loadingLeftOver = false
        })
      this.concreteLeftOver.schedule_travel_uuid = params.uuid
      this.volumeLimit = params.count_volume_left_over
      this.isUpdate = false
      this.modal = true
    },
    editModal(travel, leftOver) {
      this.travel = travel
      this.loadingLeftOver = true
      this.$store.dispatch('adjustmentLeftOver/fetchItems')
        .then((response) => {
          this.$Progress.finish()
          this.loadingLeftOver = false
        })
        .catch((error) => {
          this.$Progress.finish()
          this.loadingLeftOver = false
        })
      this.concreteLeftOver = {...leftOver}
      this.categorySelected = this.categories.find((item) => item.category === leftOver.category && item.category_type === leftOver.category_type)
      this.concreteLeftOver.reasons = this.concreteLeftOver.schedule_travel_left_over_reason.map((item) => item.left_over_adjustment_uuid)
      this.volumeLimit = leftOver.count_volume_left_over
      this.isUpdate = true
      this.modal = true
    },
    update() {
      this.loadingSave = true
      this.$Progress.start();
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch('travelLeftOver/update', this.concreteLeftOver).then(response => {
        this.$Progress.finish();
        this.loadingSave = false
        this.modal = false
        this.$emit('setLeftOver')
        this.$notify({type: 'success', message: response.message});
      }).catch(error => {
        this.loadingSave = false
        this.$Progress.finish()
        this.$notify({type: 'danger', message: error.message});
      })
    },
    save() {
      if (this.isUpdate) {
        return this.update()
      }
      this.loadingSave = true
      this.$Progress.start();
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch('travelLeftOver/create', this.concreteLeftOver).then(response => {
        this.$Progress.finish();
        this.loadingSave = false
        this.$emit('setLeftOver', response.data)
        this.modal = false
        this.$notify({type: 'success', message: response.message});
      }).catch(error => {
        this.loadingSave = false
        this.$Progress.finish()
        this.$notify({type: 'danger', message: error.message});
      })
    },
  },
  created() {
  },
  watch: {
    'concreteLeftOver.volume': {
      handler: function (after, before) {
        if (!after) {
          return
        }
        const value = checkNumberValue(mask1(after, 1, '.', true))
        if (value > this.volumeLimit) {
          this.msgUp()
          this.concreteLeftOver.volume = before
        } else {
          this.concreteLeftOver.volume = value
        }
      },
      deep: true
    },
  },
  mounted() {
    this.$watch('concreteLeftOver.ambient_temperature', function () {
      if (this.concreteLeftOver.ambient_temperature && this.concreteLeftOver.concrete_temperature) {
        this.concreteLeftOver.variation_temperature = Math.abs(this.concreteLeftOver.ambient_temperature - this.concreteLeftOver.concrete_temperature)
      } else {
        this.concreteLeftOver.variation_temperature = null
      }
    }, {
      immediate: true,
      deep: true
    })
    this.$watch('concreteLeftOver.concrete_temperature', function () {
      if (this.concreteLeftOver.ambient_temperature && this.concreteLeftOver.concrete_temperature) {
        this.concreteLeftOver.variation_temperature = Math.abs(this.concreteLeftOver.ambient_temperature - this.concreteLeftOver.concrete_temperature)
      } else {
        this.concreteLeftOver.variation_temperature = null
      }
    }, {
      immediate: true,
      deep: true
    })
  }
}
;
</script>

<style></style>
