<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center pt-4 pb-2">
        <div class="col-md-3">
          <h2 style="font-size: 1.2rem !important" class="text-warning mb-0 d-flex align-items-center mb-2 mb-md-0">
            <img height="30px" src="/img/icons/exchange.png" class="mr-3" />
            Viagens
          </h2>
        </div>

        <!-- Botões -->
        <div class="col-md-9">
          <div class="row d-flex justify-content-end">
            <div class="col-md-3 mb-2 mb-md-0">
              <base-button
                @click.prevent="handleModalAddTravel"
                block
                size="lg"
                type="success"
                class="text-uppercase"
                :disabled="loadingSkeleton || loadingSchedule"
              >
                <i class="fas fa-plus"></i> novo
              </base-button>
            </div>

            <div class="col-md-3 mb-2 mb-md-0" v-show="$_schedule.next_travel_uuid">
              <router-link
                :to="{
                  path: '/operational/schedule/charging',
                  name: 'operational.schedule.charging.index',
                  params: { travel_uuid: $_schedule.next_travel_uuid },
                }"
              >
                <base-button :disabled="loadingSkeleton" block size="lg" type="indigo" class="text-uppercase">
                  <i class="fas fa-balance-scale"></i>carregar
                </base-button>
              </router-link>
            </div>

            <div class="col-md-3 mb-2 mb-md-0">
              <router-link
                :to="{
                  path: '/operational/schedule',
                  name: 'operational.schedule.view',
                }"
              >
                <base-button block size="lg" type="light" class="text-uppercase"> <i class="fas fa-undo-alt"></i> voltar </base-button>
              </router-link>
            </div>
            <div v-if="enableForceFinish()" class="col-md-3 mb-2 mb-md-0">
              <base-button
                @click.prevent="forceFinish"
                block
                size="lg"
                type="primary"
                class="text-uppercase"
                :disabled="loadingSkeleton || loadingSchedule"
              >
                concluir
              </base-button>
            </div>
            <div v-if="enableRecoveryForceFinish()" class="col-md-3 mb-2 mb-md-0">
              <base-button
                @click.prevent="recoveryForceFinish"
                block
                size="lg"
                type="primary"
                class="text-uppercase text-strong-warning"
                :disabled="loadingSkeleton || loadingSchedule"
              >
                retomar
              </base-button>
            </div>
          </div>
        </div>
      </div>
      <hr class="mt-1 mb-3 border-warning" />
    </base-header>

    <!-- Informações do contrato -->
    <div class="container-fluid">
      <!-- Loading -->
      <div class="row card-wrapper" v-if="loadingSkeleton || loadingSchedule">
        <SkeletonPuzlGrid />
      </div>

      <div v-else class="row align-items-top pb-0 pl-1 mb-3">
        <div class="col-md-6">
          <h4 class="mb-0 font-weight-normal">Código: {{ $_schedule.contract_proposal.code }}</h4>
          <h4 class="mb-0 font-weight-normal">Cliente: {{ $_schedule.contract_proposal.customer_name }}</h4>
          <h4 class="mb-0 font-weight-normal">Obra: {{ $_schedule.contract_proposal.construction_name }}</h4>
        </div>
        <!-- Traço -->
        <div v-if="$helper.optionalChaining($_schedule, 'contract_proposal_formulation')" class="col-md-6 justify-content-end">
          <div class="card border-primary w-100 mb-0 formulation-card">
            <div class="card-body formulation-card-body">
              <div class="row m-0">
                <div class="col-8 pr-0">
                  <div class="row">
                    <a
                      title="Formulações"
                      class="pointer ml-1 mr-1 avatar rounded-pill bg-white border-primary"
                      style="border: 1px solid #e8e8e8"
                    >
                      <img style="width: 25px" src="/img/icons/test-tube.png" />
                    </a>
                    <div class="ml-2">
                      <h5 class="mb-0">{{ $_schedule.contract_proposal_formulation.title }}</h5>
                      <h5 style="word-break: break-all; max-width: 360px" class="mb-0 font-weight-normal">
                        {{ $_schedule.contract_proposal_formulation.body }}
                      </h5>
                      <h5 class="mb-0 font-weight-normal">{{ $_schedule.contract_proposal_formulation.footer }}</h5>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    $_schedule.contract_proposal_formulation.puzl_additions &&
                    $_schedule.contract_proposal_formulation.puzl_additions.length
                  "
                  class="col-4"
                >
                  <div v-for="(puzl_addition, index) in $_schedule.contract_proposal_formulation.puzl_additions" :key="puzl_addition.id">
                    <h5 class="text-indigo mb-0">
                      {{ puzl_addition.name }}
                      ({{ puzl_addition.volume }} kg/m<sup>3</sup>)
                      <span class="text-indigo" v-if="index != $_schedule.contract_proposal_formulation.puzl_additions.length - 1">+</span>
                      <span v-else></span>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Tipo de visualização -->
      <div style="margin-top: -1.5rem" class="mb-2 pl-1 pt-4 pt-md-0">
        <div style="display: flex; align-items: center">
          <i
            :class="listType !== 'cards' && 'opacity-25'"
            @click.prevent="setViewType('cards')"
            class="pointer fa-regular fa-hard-drive fa-2x"
          ></i>
          <i
            :class="listType !== 'table' && 'opacity-25'"
            @click.prevent="setViewType('table')"
            class="pointer fa-solid fa-table-list fa-2x ml-1"
          ></i>
          <div class="ml-2">
            <base-button
              @click.prevent="handleShowAll"
              block
              size="sm"
              type="primary"
              class="text-uppercase"
              :class="{ active: filter && filter.show_all }"
              v-if="$_schedule && $_schedule.status && $_schedule.status.status === 10"
              outline
              :disabled="loadingSkeleton || loadingSchedule"
            >
              Exibir todas
            </base-button>
          </div>
        </div>
      </div>

      <!-- Filtros -->
      <section>
        <multi-filter :type="3" @clearFilter="clearFilter()" @fetch="init" />
      </section>
    </div>

    <Cards
      v-show="listType === 'cards'"
      :init="init"
      :loadingSkeleton="loadingSkeleton"
      :loadingSchedule="loadingSchedule"
      :downloadProof="downloadProof"
      :copy="copy"
      :handleReleaseTravel="handleReleaseTravel"
      :setToUnrealized="setToUnrealized"
      :handleWaterControl="handleWaterControl"
      :emptyWaterControl="emptyWaterControl"
      :showAdjustments="showAdjustments"
      :handleFreshStateTest="handleFreshStateTest"
      :handleModalScheduleTravelCycleAttchment="handleModalScheduleTravelCycleAttchment"
      @handleVolumeEdit="handleModalEditTravel"
      @handleConcreteLeftover="handleConcreteLeftover"
      @handleCancelTravel="handleCancelTravel"
      @handleTravelCycle="handleTravelCycle"
    />
    <Table
      v-show="listType === 'table'"
      :loadingSkeleton="loadingSkeleton"
      :loadingSchedule="loadingSchedule"
      :downloadProof="downloadProof"
      :copy="copy"
      :handleReleaseTravel="handleReleaseTravel"
      :setToUnrealized="setToUnrealized"
      :handleWaterControl="handleWaterControl"
      :emptyWaterControl="emptyWaterControl"
      :showAdjustments="showAdjustments"
      :handleFreshStateTest="handleFreshStateTest"
      :handleModalScheduleTravelCycleAttchment="handleModalScheduleTravelCycleAttchment"
      @handleVolumeEdit="handleModalEditTravel"
      @handleConcreteLeftover="handleConcreteLeftover"
      @handleCancelTravel="handleCancelTravel"
      @handleTravelCycle="handleTravelCycle"
    />
    <ModalConcreteLeftover @setLeftOver="init()" ref="concreteLeftover" />
    <ModalCancelTravel ref="ModalCancelTravel" @cancel="handleSetTravelCancel" />
    <ModalTravelCycle @updatedCycle="init" ref="travelCycle" />
    <ModalWaterControl ref="waterControl" @setWaterControl="setWaterControl" />
    <ModalAdjustment ref="ModalAdjustment" />
    <ModalFreshStateTest @updatedFreshStateTest="init" ref="freshStateTest" />
    <ModalAddTravel  @addedTravel="init" ref="modalAddTravel" />
    <ModalEditTravel @updatedTravel="init" ref="modalEditTravel" />
    <ModalScheduleTravelCycleAttachment ref="modalScheduleTravelCycleAttchment" />
  </div>
</template>

<script>
import Cards from "./Shared/_Cards";
import Table from "./Shared/_Table";
import { mapGetters } from "vuex";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import MultiFilter from "@/components/Utils/MultiFilterV3";
import cursorPaginate from "@/mixins/cursorPaginate";
import ModalConcreteLeftover from "./Shared/_ModalConcreteLeftover";
import ModalCancelTravel from "./Shared/_ModalCancelTravel";
import ModalTravelCycle from "@/views/Modules/Operational/Schedule/Weighing/Shared/ScheduleTravelCycle/_ModalNewTravelCycle";
import ModalScheduleTravelCycleAttachment from "@/views/Modules/Operational/Schedule/Weighing/Shared/ScheduleTravelCycle/_ModalAttachmentView";
import ModalAddTravel from "./Shared/_ModalAddTravel";
import ModalEditTravel from "./Shared/_ModalEditTravel";
import ModalWaterControl from "./Shared/_ModalWaterControl";
import ModalAdjustment from "../Charging/Shared/_ModalAdjustments.vue";
import ModalFreshStateTest from "./Shared/_ModalFreshStateTest";

export default {
  name: "IndexWeighing",
  mixins: [cursorPaginate],
  components: {
    Cards,
    SkeletonPuzl,
    SkeletonPuzlGrid,
    MultiFilter,
    Table,
    ModalConcreteLeftover,
    ModalCancelTravel,
    ModalTravelCycle,
    ModalAddTravel,
    ModalWaterControl,
    ModalAdjustment,
    ModalFreshStateTest,
    ModalEditTravel,
    ModalScheduleTravelCycleAttachment,
  },
  computed: {
    ...mapGetters({
      $_schedule: "schedule/show",
      $_items: "travels/fetch",
    }),
  },
  data() {
    return {
      loadingSchedule: true,
      loadingSkeleton: true,
      listType: "cards",
      filter: {
        status: null,
      },
    };
  },
  methods: {
    enableForceFinish() {
      if (
        this.$_schedule &&
        (this.$_schedule.complete_travels > 0 || this.$_schedule.cancelled_travels > 0) &&
        !this.$_schedule.force_finished &&
        this.$_schedule.status.status !== 11 &&
        this.$_schedule.status.status !== 0 &&
        this.$_schedule.status.status !== 15
      ) {
        return true;
      }
      return false;
    },
    enableRecoveryForceFinish() {
      if (
        this.$_schedule &&
        (this.$_schedule.complete_travels > 0 || this.$_schedule.cancelled_travels > 0) &&
        this.$_schedule.force_finished
      ) {
        return true;
      }
      return false;
    },
    setViewType(type) {
      this.listType = type;
    },
    init(filter = null) {
      this.startCursor(filter);
      this.$store
        .dispatch("travels/fetchItems", {
          uuid: this.$route.params.schedule_uuid,
          code: "1",
          filter: this.filter,
          next_page: this.paginate.nextUrl,
        })
        .then((response) => {
          this.resolveCursor(response);
          this.loadingSkeleton = false;
        });
    },
    handleUpdateVolumeTravel(travel) {
      this.$refs.updateVolumeTravel.openModal(travel);
    },
    downloadProof(travel) {
      let params = {
        uuid: travel.uuid,
        type: "proof",
      };
      let loader = this.$loading.show();
      this.$store
        .dispatch("travels/download", params)
        .then((response) => {
          let blob = new Blob([response], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "custom_name.pdf"); // Added Line
          window.open(link, "_blank");
          loader.hide();
        })
        .catch((error) => {
          loader.hide();
        });
    },
    /**
     * Copia o código da automação
     * @param code
     */
    copy(code) {
      this.$helper.copy(code);
      this.$notify({ type: "success", message: "Código da automação copiado com sucesso!" });
    },
    handleConcreteLeftover(schedule_travel, volume = null) {
      if (schedule_travel.count_volume_left_over === 0) {
        return this.$notify({
          type: "danger",
          message: "Não há sobra de concreto suficiente.",
        });
      }
      this.$refs.concreteLeftover.openModal(schedule_travel, volume);
    },
    handleCancelTravel(travel) {
      this.$refs.ModalCancelTravel.openModal(travel);
    },
    handleSetTravelCancel(travel) {
      this.init();
      this.$refs.ModalCancelTravel.closeModal();
      travel.status = 6;
      this.handleConcreteLeftover(travel, travel.volume);
    },
    /** Liberação de viagens */
    handleReleaseTravel(travel) {
      this.$swal
        .fire({
          title: "Você tem certeza?",
          text: "Todas as viagens anteriores com status aguardando liberação também serão liberadas!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, prosseguir!",
          cancelButtonText: "Não, mantenha",
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          buttonsStyling: false,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.loadingSkeleton = true;
            this.$store.dispatch("schedule/releaseTravels", { uuid: travel.schedule_uuid, index: travel.index }).then(() => {
              this.init();
            });
          }
        });
    },
    /**
     * Torna ou retoma uma viagem não realizada
     *
     * @param {integer} id
     * @returns {void}
     */
    setToUnrealized(id) {
      let loader = this.$loading.show();
      this.$store
        .dispatch("travels/setToUnrealized", id)
        .then((response) => {
          this.$notify({ type: "success", message: response.message });
          this.init({});
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: error.data.message,
          });
        })
        .finally(() => {
          loader.hide();
        });
    },
    handleShowAll() {
      this.filter.show_all = this.filter.show_all ? null : true;
      this.init({});
    },
    handleTravelCycle(travel, address_construction) {
      this.$refs.travelCycle.openModal(travel, address_construction);
    },
    handleModalScheduleTravelCycleAttchment(schedule_travel_cycle_id) {
      this.$refs.modalScheduleTravelCycleAttchment.openModal(schedule_travel_cycle_id);
    },
    forceFinish() {
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });
      this.loadingSkeleton = true;
      this.$Progress.start();
      this.$store
        .dispatch("schedule/forceFinish", {
          uuid: this.$route.params.schedule_uuid,
        })
        .then((response) => {
          this.$store
            .dispatch("travels/fetchItems", {
              uuid: this.$route.params.schedule_uuid,
              code: "1",
            })
            .then((response) => {
              this.$store.dispatch("schedule/show", this.$route.params.schedule_uuid).then((response) => {
                this.$notify({ type: "success", message: response.message });
                this.$Progress.finish();
                this.loadingSkeleton = false;
              });
            });
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: error.data.message,
          });
          this.$Progress.finish();
          this.loadingSkeleton = false;
        });
    },
    recoveryForceFinish() {
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });
      this.loadingSkeleton = true;
      this.$Progress.start();
      this.$store
        .dispatch("schedule/recoveryForceFinish", {
          uuid: this.$route.params.schedule_uuid,
        })
        .then((response) => {
          this.$store
            .dispatch("travels/fetchItems", {
              uuid: this.$route.params.schedule_uuid,
              code: "1",
            })
            .then((response) => {
              this.$store.dispatch("schedule/show", this.$route.params.schedule_uuid).then((response) => {
                this.$notify({ type: "success", message: response.message });
                this.$Progress.finish();
                this.loadingSkeleton = false;
              });
            });
        })
        .catch((error) => {
          if (error.status == 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
            this.$Progress.finish();
            this.loadingSkeleton = false;
          } else if (error.response && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
          this.$Progress.finish();
          this.loadingSkeleton = false;
        });
    },
    handleWaterControl(uuid, index) {
      let params = {
        uuid: uuid,
        index: index,
        water_control: this.$_items[index].water_control,
        retained_water: this.$_items[index].retained_water,
      };
      if (this.$_items[index].concrete_left_over.length) {
        return;
      }
      if (!this.$_items[index].isset_manual_dosage) {
        this.$notify({
          type: "danger",
          message: "Carregamento ainda não realizado.",
        });
        return;
      }
      this.$refs.waterControl.openModal(params);
    },
    setWaterControl(params) {
      this.$_items[params.index].water_control = params.water_control;
    },
    setWaterControl(params) {
      this.$_items[params.index].water_control = params.water_control;
    },
    emptyWaterControl(water) {
      return !water.company_plant_liters || !water.construction_liters;
    },
    /**
     * Obtém os ajustes associados a uma viagem e exibe-os em um modal
     * @param {Object} schedule_travel - Objeto contendo informações da viagem
     * @param {string} schedule_travel.uuid - O UUID da viagem
     * @param {Boolean} schedule_travel.has_mcc - Valida se a viagem possui MCC associado
     */
    async showAdjustments(schedule_travel) {
      let loader = this.$loading.show();
      this.$notify({ type: "info", message: "Estamos trabalhando em sua solicitação." });
      this.$Progress.start();
      let response = await this.$store.dispatch("travels/show", { uuid: schedule_travel.uuid });
      let travel = response.data;
      if (!travel) {
        return this.$notify({ type: "danger", message: "Viagem não encontrada!" });
      }
      if (!travel.schedule.contract_proposal_formulation.mix_mixe_id && !travel.has_adjustments) {
        return this.$notify({ type: "danger", message: "Traço não encontrado!" });
      }

      let params = {
        has_dosage: travel.isset_manual_dosage || false,
        schedule_travel_uuid: travel.uuid,
        volume_adjustments: travel.volume || null,
        concrete_piece_adjustments: travel.mix_concreted_piece_uuid,
        temperature_adjustments: travel.temperature || null,
        concrete_cycle_adjustments: travel.concrete_cycle,
        charge_point_adjustments: travel.charge_point_uuid || null,
        company_plant_uuid: travel.send_plant.uuid,
        mix_design_mixe_id: travel.schedule.contract_proposal_formulation.mix_mixe_id,
      };
      this.$store
        .dispatch("travels/getAdjustments", params)
        .then((response) => {
          this.$Progress.finish();
          loader.hide();
          this.$notify({ type: "success", message: response.message });
          this.modal_shown = true;
          this.$refs.ModalAdjustment.openModal();
        })
        .catch((error) => {
          this.$Progress.finish();
        });
    },
    handleFreshStateTest(uuid, index) {
      if (!this.$_items[index].isset_manual_dosage) {
        this.$notify({
          type: "danger",
          message: "Carregamento ainda não realizado.",
        });
        return;
      }
      this.$refs.freshStateTest.openModal(uuid);
    },
    handleModalAddTravel() {
      this.$refs.modalAddTravel.openModal(this.$route.params.schedule_uuid, this.$_schedule.interval_releases, this.$_schedule.company_plants[0].company_plant_uuid);
    },
    handleModalEditTravel(schedule_travel) {
      this.$refs.modalEditTravel.openModal(schedule_travel, this.$_schedule.interval_releases);
    },
  },
  async mounted() {
    await this.$store.dispatch('operationalPermission/getByUserId', this.$user.id);
    this.$store.dispatch("schedule/show", this.$route.params.schedule_uuid).then((response) => {
      this.loadingSchedule = false;
    });
    this.init();
  },
};
</script>

<style scoped>
.formulation-card-body {
  padding: 1rem !important;
}
</style>
