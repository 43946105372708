<template>
  <div>
    <div class="container-fluid">
      <validation-observer
        v-slot="{ invalid, handleSubmit }"
        ref="formValidator"
      >
        <form class="needs-validation" autocomplete="off">
          <card>
            <div class="row">
              <div class="col-md-12">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12 mb-1 pr-4 mt-n3">
                      <div class="pb-0 ml-n2 col-form-label form-control-label">
                        Grupo de alerta
                        <a 
                          href="#"
                          class="p-1 ml-1"
                          v-show="selected"
                          @click.prevent="openModalEditAlertGroup(selected)"
                        >
                          <img src="/img/icons/create-new.png" width="25px" height="25px" class="mt-n1" />
                        </a>
                        <a 
                          href="#"
                          class="p-1 ml-1"
                          v-show="selected"
                          @click.prevent="handleDelete(selected)"
                        >
                          <img src="/img/icons/delete.png" width="25px" height="25px" class="mt-n1" />
                        </a>
                      </div>
                    </div>
                    <div class="col-md-6 p-1">
                      <base-input input-classes="form-control-sm">
                        <PuzlSelect
                          v-model="selected"
                          :items="$_alertGroups"
                          @change="setAlertsSelected()"
                          :disabled="loadingAlertGroups"
                          :loading="loadingAlertGroups" 
                        />
                      </base-input>
                    </div>
                    <div class="col-md-3 p-1">
                      <base-button 
                        type="primary" block outline 
                        @click.prevent="selectAll()" 
                        style="padding: 0.30rem 0.5rem;"
                        size="sm"
                      >
                        <img src="/img/icons/icons8/ios/check-all.png" width="17px" height="17px" class="invert-on-hover" />
                          Selecionar todas
                        <span class="badge badge-light"style="color: black !important;">
                          {{ selected_alerts.length }} / {{ $_userAlerts.length }}
                        </span>
                      </base-button>
                    </div>
                    <div class="col-md-3 p-1">
                      <base-button 
                        @click.prevent="expandAllAlerts()" 
                        block type="warning" outline
                        style="padding: 0.39rem 0.5rem;"
                        size="sm"
                      >
                        <img src="/img/icons/icons8/ios/expand.png" width="17px" height="17px" class="invert-on-hover" />
                        Expandir alertas
                      </base-button>
                    </div>
                  </div>
                </div>
                <div class="card p-3 mt-n2">
                  <div class="mb-2 ml-1">
                    <img src="/img/icons/icons8/ios/flicker-free.png" width="25px" height="25px" />
                    <b class="text-dark ml-1"> Telas </b> 
                  </div>
                  <div class="row mt-n2">
                    <div class="col-md-4" v-for="(item, key, index) in options">
                      <div class="col-12 mb-3">
                        <collapse :id="key" class="border rounded p-0 mt-3">
                          <collapse-item 
                            :total="getTotalSelected(key).total_quantity"
                            :selected="getTotalSelected(key).selected_quantity"
                          >
                            <h5 slot="title" class="mb-0" :class="key">
                              <img :src="item.image" width="23px" height="23px" />
                              <span class="mb-1 px-2 mr-2"><b>{{ item.name }}</b></span>
                              <badge 
                                style="
                                  width: 15%; 
                                  font-size: 10px !important; 
                                  background-color: rgba(161, 220, 188, 0.3);
                                  border-radius: 7px;
                                " 
                                class="text-success"
                              >
                                {{ getTotalSelected(key).selected_quantity }} / {{ getTotalSelected(key).total_quantity }}
                              </badge>
                            </h5>
                            <a href="#" @click.prevent="selectAllItemAlert(key)">
                              <img src="/img/icons/icons8/ios/check-all.png" v-if="selected" width="17px" height="17px" class="mt-n4" />
                            </a>
                            <transition name="fade">
                              <div class="row align-items-center pb-0 mb-3" v-if="selected">
                                <template v-for="(alert, key) in item.alerts">
                                  <div class="col-md-12" :key="key">
                                    <input v-model="selected_alerts" :value="alert.id" type="checkbox">
                                    <small class="ml-2">{{ alert.title }}</small>
                                    <el-popover ref="myPopover" class="p-0" placement="right" trigger="click">
                                      <span class="numeric m-0 pl-1">
                                        <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="17px" height="17px" />
                                        <b> Grupos vinculados </b>
                                        <div 
                                          v-if="groupsData" 
                                          class="font-weight-400 mt-1" 
                                          style="max-height: 170px; overflow-y: auto;"
                                        >
                                          <div 
                                            v-for="groupString in groupsData.groupsData.split(',')" 
                                            v-if="!loadingGroupsName"
                                          >
                                            <span>{{ extractGroupName(groupString) }}</span>
                                            <a 
                                              href="#" 
                                              @click.prevent="removeAlertFromGroup(alert.id, groupString)"
                                            >
                                              <img 
                                                src="/img/icons/delete.png" width="17px" 
                                                height="17px" class="float-right mr-2 ml-2" />
                                            </a>
                                          </div>
                                          <div class="mr-3" v-else>
                                            <SkeletonPuzl type="button" />
                                          </div>
                                        </div>
                                      </span>
                                      <a href="#" @click.prevent="getGroupByAlert(alert.id)" slot="reference">
                                        <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="17px" height="17px" class="float-right" />
                                      </a>
                                    </el-popover>
                                  </div>
                                </template>
                              </div>
                            </transition>
                          </collapse-item>
                        </collapse>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button 
                :disabled="!selected" 
                :loading="loadingSave" 
                @click.prevent="save()" 
                type="success"
                native-type="submit"
              >
                <span v-show="!loadingSave" class="btn-label"><i class="fas fa-save"></i></span>
                Salvar
              </base-button>
            </div>
          </card>
        </form>
      </validation-observer>
    </div>
    <ModalEditAlertGroup ref="modalEditAlertGroup" />
    <ModalReplaceAlertGroup ref="modalReplaceAlertGroup" @selectNewAlertGroup="selectNewAlertGroup" />
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import PuzlSelect from "@/components/PuzlSelect";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import ModalEditAlertGroup from "./_ModalEditAlertGroup";
import ModalReplaceAlertGroup from "./_ModalReplaceAlertGroup";

export default {
  name: "UserAlerts",
  components: {
    PuzlSelect,
    SkeletonPuzl,
    ModalEditAlertGroup,
    ModalReplaceAlertGroup,
  },
  data() {
    return {
      loadingSave: false,
      options: {
        configuration: {
          name: 'Geral',
          icon: 'fas fa-cog fa-2x',
          icon_color: 'text-muted',
          image: '/img/icons/settings.png',
          alerts: []
        },
        administrative: {
          name: 'Gerencial',
          icon: 'fa-solid fa-chart-simple fa-2x',
          icon_color: 'text-grey-2',
          image: '/img/icons/bullish.png',
          alerts: []
        },
        technology: {
          name: 'Tecnologia',
          icon: 'ni ni-atom ni-2x',
          icon_color: 'text-info',
          image: '/img/icons/physics.png',
          alerts: []
        },
        commercial: {
          name: 'Comercial',
          icon: 'ni ni-building ni-2x',
          icon_color: 'text-warning',
          image: '/img/icons/client-company.png',
          alerts: []
        },
        operational: {
          name: 'Operacional',
          icon: 'ni ni-delivery-fast ni-2x',
          icon_color: 'text-green',
          image: '/img/icons/chemical-plant.png',
          alerts: []
        },
        stock: {
          name: 'Estoque',
          icon: 'fas fa-box-open fa-2x',
          icon_color: 'text-primary',
          image: '/img/icons/open-box.png',
          alerts: []
        },
        equipment: {
          name: 'Equipamento',
          icon: 'ni ni-settings ni-2x',
          icon_color: 'text-indigo',
          image: '/img/icons/concrete-pump.png',
          alerts: []
        },
        entries: {
          name: 'Entradas',
          icon: 'fas fa-inbox fa-2x',
          icon_color: 'text-warning',
          image: '/img/icons/login-rounded.png',
          alerts: []
        },
        exit: {
          name: 'Saídas',
          icon: 'fa-solid fa-right-from-bracket fa-2x',
          icon_color: 'text-lightred',
          image: '/img/icons/login-rounded-right.png',
          alerts: []
        },
        financial: {
          name: 'Financeiro',
          icon: 'ni ni-money-coins ni-2x',
          icon_color: 'text-danger',
          image: '/img/icons/finance.png',
          alerts: []
        },
        billing: {
          name: 'Faturamento',
          icon: 'fa-solid fa-receipt fa-2x',
          icon_color: 'text-indigo',
          image: '/img/icons/check.png',
          alerts: []
        },
        cash_flow: {
          name: 'Fluxo de caixa',
          icon: 'fa-solid fa-hand-holding-dollar fa-2x',
          image: '/img/icons/exchange.png',
          alerts: []
        },
        fiscal: {
          name: 'Fiscal',
          icon: 'fa-solid fa-hand-holding-dollar fa-2x',
          icon_color: 'text-darkred',
          image: '/img/icons/ledger.png',
          alerts: []
        },
        driver: {
          name: 'Motorista',
          icon: 'fas fa-shuttle-van fa-2x',
          icon_color: 'text-primary',
          image: '/img/icons/interstate-truck.png',
          alerts: []
        },
      },
      selected: null,
      selected_alerts: [],
      selectAllAlerts: false,
      alertGroupIdToDelete: null,
      groupsData: null,
      loadingGroupsName: false,
      deleteAlertGroupByPopover: false,
      loadingAlerts: false,
      loadingGroupAlert: false,
      loadingUserAlerts: true,
      loadingAlertGroups: true,
    }
  },
  props: {
    alertGroupId: { // para selecionar grupo alerta após o cadastro na modal.
      type: Number,
    }
  },
  watch: {
    alertGroupId(newId) {
      this.selected = newId;
      this.setAlertsSelected();
    }
  },
  mounted() {
    this.$store.dispatch('userAlert/fetchItems').then((response) => {
      this.setAlertAccordion();
      this.loadingUserAlerts = false;
    });
    this.fetchAlertGroup();
  },
  computed: {
    ...mapGetters({
      $_userAlerts: 'userAlert/fetch',
      $_alertGroups: 'alertGroup/fetch',
    }),
  },
  methods: {
    /**
     * Seleciona todos os itens de todos os grupos de alertas.
     */
    selectAll() {
      this.selectAllAlerts = !this.selectAllAlerts;
      if (this.selectAllAlerts) {
        this.selected_alerts = [];
        this.$_userAlerts.forEach(element => {
          this.selected_alerts.push(element.id);
        });
      } else {
        this.selected_alerts = [];
      }
    },
     /**
     * Seleciona todos os itens do grupo de alerta escolhido.
     * Caso já exista algum item selecionado, ele desconsira e faz o push sem repetir o valor.
     * @param {string} key
     */
    selectAllItemAlert(key) {
      const isChecked = this.checkIfAnyItemIsSelected(key);
      this.options[key].alerts.forEach(element => {
        let foundSelectedItem = this.selected_alerts.find(item => item == element.id)
        if (isChecked) {
          if (element.id != foundSelectedItem) {
            this.selected_alerts.push(element.id);
          }
        } else {
          this.selected_alerts.splice(this.selected_alerts.indexOf(element.id), 1);
        }
      });
    },
    /**
     * Verifica se já existe algum item selecionado.
     * @param {string} key
     * @return {bool}
     */
    checkIfAnyItemIsSelected(key) {
      const allAlertWithStatus = [];
      this.options[key].alerts.forEach(element => {
        const alertFound = this.selected_alerts.includes(element.id);
        allAlertWithStatus.push(alertFound);
      });
      const containFalseInArray = allAlertWithStatus.filter(item => item == false).length > 0;
      return containFalseInArray;
    },
    /**
     * Pega o total de itens de um card.
     * Faz a contagem dos itens selecionados.
     * @param {string} key
     * @return {object}
     */
    getTotalSelected(key) {
     let checkedCount = 0;
      let alertGroupItemsCount = this.options[key].alerts.length;
      this.options[key].alerts.forEach(element => {
        const alertFound = this.selected_alerts.includes(element.id);
        if (alertFound) {
          checkedCount ++;
        }
      });
      return {
        total_quantity: alertGroupItemsCount,
        selected_quantity: checkedCount,
      }
    },
    /**
     * Sempre que um grupo é selecionado, os dados são carregados.
     */
    setAlertsSelected() {
      if (this.selected) {
        this.$store.dispatch('alertGroup/getAlertGroupsWithAlerts', this.selected).then((response) => {
          const items = response.data;
          this.selected_alerts = items.map((item) => item.alert_id);
        });
      }
    },
    /**
     * Associa alertas a suas respectivas chaves em 'this.options'.
     * O nome do alerta é dividido em um array usando '.' como delimitador.
     * Se a chave estiver incluída nesse array, o alerta é adicionado ao array para essa chave.
     */
    setAlertAccordion() {
      for (let key in this.options) {
        this.options[key]['alerts'] = this.$_userAlerts.filter(function (item) {
          if (item.name.split('.').includes(key)) {
            return item;
          }
        })
      }
    },
    expandAllAlerts() {
      for (let key in this.options) {
        document.getElementById(key).getElementsByClassName("card-header")[0].click()
      }
    },
    save() {
      let params = {
        alert_group_id: this.selected.name ? this.selected.id : this.selected,
        alerts: this.selected_alerts
      };
      this.loadingSave = true;
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch('userAlert/update', params)
        .then(response => {
          this.$notify({type: 'success', message: response.message});
          this.setAlertAccordion();
          this.loadingSave = false;
        })
        .catch(() => {
          this.loadingSave = false;
        })
    },
    /**
     * Abre modal para substituir grupo de alertas antes de realizar a exclusão.
     */
    handleNewGroupAlert() {
      this.$refs.modalReplaceAlertGroup.openModal();
    },
    /**
     * @param {number} id
     */
    handleDelete(id, deleteAlertGroupByPopover = null) {
      this.alertGroupIdToDelete = id;
      this.handleNewGroupAlert();
      this.deleteAlertGroupByPopover = deleteAlertGroupByPopover;
    },
    /**
     * O antigo grupo de alertas é removido, e o atual que foi cadastrado é selecionado na modal,
     * mas também é atribuído para todos os usuários que estavam associados ao grupo de alerta
     * que foi deletado, para evitar que um usuário fique sem grupo de alerta.
     * @param {number} selectedAlertGroupId
     */
    selectNewAlertGroup(selectedAlertGroupId) {
      this.$Progress.start();
      if (this.alertGroupIdToDelete == selectedAlertGroupId) {
        this.$notify({
          type: 'warning',
          message: 'O novo grupo de alerta não pode ser igual àquele que está sendo removido'
        });
        return;
      }
      const deleteAndReplaceIds = {
        id: this.alertGroupIdToDelete,
        replacementId: selectedAlertGroupId,
      };
      this.$store.dispatch('alertGroup/destroy', deleteAndReplaceIds)
        .then((response) => {
          this.$notify({
            type: 'success',
            message: response.message
          });
          this.selected = selectedAlertGroupId;
          this.setAlertsSelected();
        })
        .catch(error => {
          this.$notify({
            type: error.data.error_type,
            message: error.data.message
          });
        }).finally(() => {
          this.$Progress.finish();
        });
    },
    /**
     * Recupera todos os grupos de alerta que foram associados a um determinado alerta.
     * @param {number} alertId
     */
    getGroupByAlert(alertId) {
      this.loadingGroupsName = true;
      this.$store.dispatch('userAlert/getGroupsByAlertId', alertId).then((response) => {
        this.groupsData = response.data;
        setTimeout(() => {
          this.loadingGroupsName = false;
        }, 120);
      });
    },
    /**
     * Extrai o ID do grupo de uma string.
     * @param {string} groupString
     * @return {string}
     */
    extractGroupId(groupString) {
      return groupString.trim().split(':')[0];
    },
    /**
     * Extrai o nome do grupo de uma string.
     * @param {string} groupString
     * @return {string}
     */
    extractGroupName(groupString) {
      return groupString.trim().split(':')[1];
    },
    /**
     * Remove o vínculo entre um alerta e um grupo.
     * @param {number} alertId
     * @param {string} groupString
     */
    removeAlertFromGroup(alertId, groupString) {
      const groupId = this.extractGroupId(groupString);
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          const alertGroupRemovalPayload = {
            alertId: alertId,
            groupId: parseInt(groupId),
          };
          this.$store.dispatch('userAlert/removeAlertFromGroup', alertGroupRemovalPayload).then((response) => {
            this.getGroupByAlert(alertId);
            this.fetchAlertGroup();
        
            // Desmarcar o checkbox caso o grupo de alerta selecionado 
            // seja o mesmo a ser desvinculado de um alerta.
            if (parseInt(groupId) == this.selected) {
              const index = this.selected_alerts.indexOf(alertId);
              if (index !== -1) {
                this.selected_alerts.splice(index, 1);
              }
            }
          
            this.$notify({type: 'success', message: response.message});
            this.$Progress.finish();
          }); 
        }
      }).catch(() => this.$Progress.finish());
    },
    /**
     * Abre modal para edição do nome gro grupo de alerta.
     * @param {number} alertGroupId
     */
    openModalEditAlertGroup(alertGroupId) {
      this.$refs.modalEditAlertGroup.handleEditModal(alertGroupId);
    },
    fetchAlertGroup() {
      this.$store.dispatch('alertGroup/fetchItems').then(() => {
        this.loadingAlertGroups = false;
      });
    },
  },
}
</script>

<style scoped>
.img:hover .invert-on-hover {
  filter: brightness(0) invert(1);
}
</style>
