<template>
  <div>
    <modal size="md" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div v-if="concrete_price" class="container-fluid">
        <div class="row align-items-center mb-2">
          <div class="col-12">
            <!-- Title -->
            <div>
              <h5 v-if="concrete_price.plant.plant_name != null" class="h5 new-default-black-font">
                {{ concrete_price.plant.plant_name }}
              </h5>
            </div>

            <div class="mt-2">
              <h4 class="new-default-black-font font-weight-600"
                  v-for="(feature, indexFeature) in this.concrete_price.mixe.features" :key="indexFeature">
                {{ feature.feature_name }} {{ feature.item }}
                {{ feature.unit_letter }}
              </h4>
            </div>

            <div class="row mt-n1">
              <div class="col-md-12">
                <small class="mb-1 font-weight-500 new-default-black-font">
                  {{ concrete_price.service.type }}
                </small>
                <small class="mb-1 font-weight-500 new-default-black-font" v-show="concrete_price.service.service_name">
                  | {{ concrete_price.service.service_name }}
                </small>
                <small class="small font-weight-500 new-default-black-font"
                       v-show="concrete_price.service.service_type">
                  | {{ concrete_price.service.service_type }}
                </small>
              </div>
              <div class="col-12 mt-n1">
                <small class="mb-1 font-weight-500 new-default-black-font">
                  {{ concrete_price.mixe.slump }}
                </small>
                <small class="mb-1 font-weight-500 new-default-black-font">
                  | {{ concrete_price.mixe.product }}
                </small>
                <div v-if="concrete_price.price_parameter" class="col-12 pl-0 ml-0">
                  <img class="mr-1" src="/img/icons/icons8/ios/wheelbarrow_dark.png" height="19px"><span
                  style="font-size: 12px"
                  v-for="(group, index) in concrete_price.price_parameter.mcc_price.content_groups"> {{
                    group.name
                  }} ({{ group.percentage }})
                                  <template v-if="concrete_price.price_parameter.mcc_price.content_groups[index + 1]"> + </template></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="blue-divider">
        <div class="row align-items-center mb-2 pl-3 pt-2">
          <div class="row col-12 pr-0 mb-2">
            <div class="col-4 p-0 m-0" style="align-self: end;">
              <h5
                class="new-default-black-font">
                SLUMP FINAL
              </h5>
            </div>
            <div class="col-8 p-0 m-0">
              <puzl-select
                @change="handleSearchAdjustment()"
                v-model="filter.slump_final_id"
                :items="getSlumpFlow()"
                label="nomenclature"
                :disabled="adjustment_blocks.includes(12)"
                :loading="loading_filters"
              />
            </div>
          </div>
          <div class="row col-12 pr-0 mb-2">
            <div class="col-4 p-0 m-0" style="align-self: end;">
              <h5
                class="new-default-black-font">
                NÍVEL DE APLICAÇÃO
              </h5>
            </div>
            <div class="col-8 p-0 m-0">
              <puzl-select
                @change="handleSearchAdjustment()"
                v-model="filter.level_feature"
                :items="$_feature_levels"
                label="label"
                customKey="value"
                :disabled="adjustment_blocks.includes(11)"
                :loading="loading_filters"
              />
            </div>
          </div>
          <div class="row col-12 pr-0 mb-4">
            <div class="col-4 p-0 m-0" style="align-self: end;">
              <h5
                class="new-default-black-font">
                PEÇA A CONCRETAR
              </h5>
            </div>
            <div class="col-8 p-0 m-0">
              <puzl-select
                @change="handleSearchAdjustment()"
                v-model="filter.concrete_piece_adjustments"
                :items="$_concrete_pieces"
                label="description"
                customKey="uuid"
                :disabled="adjustment_blocks.includes(3)"
                :loading="loading_filters"
              />
            </div>
          </div>
          <div class="row col-12 pr-0 mb-2">
            <div class="col-4 p-0 m-0" style="align-self: end;">
              <h5
                class="new-default-black-font">
                DISTÂNCIA
              </h5>
            </div>
            <div class="col-8 p-0 m-0">
              <div class="input-group input-group-sm">
                <input @change="handleSearchAdjustment" v-model="filter.distance" v-mask="'#####'" style="height: auto;"
                       type="text" class="form-control">
                <div class="input-group-append input-group-sm">
                  <span style="text-transform: none;" class="input-group-text">km</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row col-12 pr-0 mb-5">
            <div class="col-4 p-0 m-0" style="align-self: end;">
              <h5
                class="new-default-black-font">
                CICLO TOTAL
              </h5>
            </div>
            <div class="col-8 p-0 m-0">
              <div class="input-group input-group-sm">
                <input @change="handleSearchAdjustment" v-model="filter.cycle" v-mask="'#####'" style="height: auto;"
                       type="text" class="form-control">
                <div class="input-group-append input-group-sm">
                  <span style="text-transform: none;" class="input-group-text">min</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row col-12 pr-0 mb-1">
            <div class="col-6 p-0 m-0" style="align-self: end;">
              <h3
                class="new-default-black-font">
                PREÇO MÍNIMO DE VENDA
              </h3>
            </div>
            <div class="col-6 p-0 m-0 text-right">
              <h3 class="new-default-blue-font">{{ $_price.minimum_price | currency() }}</h3>
            </div>
          </div>
          <hr class="blue-divider mt-3 mb-4" style="width: 100%;">
          <div class="row col-12 pr-0 mb-5">
            <div class="col-4 p-0 m-0" style="align-self: end;">
              <h5
                class="new-default-black-font">
                PREÇO DE VENDA
              </h5>
            </div>
            <div class="col-8 p-0 m-0">
              <div class="input-group input-group-sm">
                <input v-money="money" v-model="price" style="height: auto;" type="text" class="form-control">
                <div class="input-group-append input-group-sm">
                  <span class="input-group-text">R$</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row col-12 mb-3 pl-0 pr-0">
            <div class="col-6 text-center pr-0">
              <div class="mcc-card">
                <h5 class="checklist-title mb-0 font-weight-600">{{
                    $_price.cmc | currency()
                  }}</h5>
                <small>MCC</small>
              </div>
            </div>
            <div class="col-6 text-center pr-0">
              <div class="gross-margin-card">
                <h5 class="checklist-title mb-0 font-weight-600">
                  {{ calcMargin() | currency() }}
                </h5>
                <small>Margem</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PuzlSelect from "@/components/PuzlSelect.vue";
import {VMoney} from "v-money";

export default {
  name: "PuzlAdjust",
  components: {
    PuzlSelect
  },
  data() {
    return {
      modal: {
        title: "CÁLCULO PUZL ADJUST",
        create: false,
      },
      filter: {
        slump_final_id: null,
        concrete_piece_adjustments: null,
        level_feature: null,
        distance: null,
        cycle: null
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false
      },
      price: 0,
      concrete_price: {},
      adjustment_blocks: [],
      brutal_margin: 0,
      loading_filters: false,
      loadingConcretePiece: false,
      loadingFeatureLevels: false,
    }
  },
  computed: {
    ...mapGetters({
      $_concrete_pieces: "mixConcretePiece/fetch",
      $_price: "concretePrice/getPriceAdjustment",
      $_feature_levels: 'mixConcretePiece/feature_levels',
      $_slump_flows: 'mixSlumpFlow/fetch',
      $_application_level_guideline: "applicationLevelGuideline/fetch",
    }),
  },
  directives: {
    money: VMoney
  },
  methods: {
    getSlumpFlow() {
      if (! this.$_price.slump_flow_ids) {
        return []
      }
      return this.$_slump_flows.filter((item) => this.$_price.slump_flow_ids.includes(item.id))
    },
    handleSearchAdjustment() {
      let loader = this.$loading.show()
      this.$store.dispatch('concretePrice/getPriceByPuzlAdjustment', {
        mix_design_mixe_id: this.concrete_price.mix_design_mixe_id,
        adjustments: {
          concrete_piece_adjustments: this.filter.concrete_piece_adjustments,
          slump_flow_id: this.filter.slump_final_id,
          company_plant_id: this.concrete_price.plant.id,
          level_feature: this.filter.level_feature,
          distance: this.filter.distance,
          cycle: this.filter.cycle,
        }
      }).then(() => {
        loader.hide()
      })
    },
    openModal(concretePrice, brutalMargin) {
      this.adjustment_blocks = concretePrice.mixe.adjustment_blocks
      this.filter = {
        slump_final_id: null,
        concrete_piece_adjustments: null,
        level_feature: null,
        distance: null,
        cycle: null,
      }
      Promise.all([
        this.$store.dispatch("mixConcretePiece/fetchItems"),
        this.$store.dispatch("mixConcretePiece/getFeatureLevels"),
        this.$store.dispatch('mixSlumpFlow/fetchItems')
      ])
      this.concrete_price = concretePrice;
      this.handleSearchAdjustment()
      this.modal.create = true;
      this.brutal_margin = brutalMargin;
    },
    calcMargin() {
      const cmc = this.$_price.cmc
      const tax = this.$_price.tax
      const price = this.price.toString().replaceAll(".", "").replace(",", ".")
      if (price > 0) {
        if (tax > 0) {
          return price - (price * (tax / 100)) - cmc
        } else {
          return price - cmc
        }
      }
      return 0
    },
    closeModal() {
      this.modal.create = false;
    },
  },
};
</script>
<style scoped>
.blue-divider {
  background-color: #7ec5ff;
  color: #7ec5ff;
  margin: 0;
  margin-bottom: 15px;
}

.new-default-black-font {
  color: #2B2D32 !important;
}

.new-default-gray {
  color: #6c6c6c;
}

.new-default-blue-font {
  color: #1A70B7 !important;
}

.mcc-card {
  background-color: #f3949452;
  color: #DB4539;
  box-shadow: 0px 2px 4px 0px #f3949452 !important;
  border-radius: 15px;
  padding: 7px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.mcc-card h5 {
  color: #DB4539;
  font-size: 14px;
}

.gross-margin-card {
  background-color: #149e5752;
  color: #149E57;
  box-shadow: 0px 2px 4px 0px #149e5752 !important;
  border-radius: 15px;
  padding: 7px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.gross-margin-card h5 {
  color: #149E57;
  font-size: 14px;
}
</style>
