<template>
  <div v-if="selected">
    <modal size="md" :show.sync="modal.show">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div class="row mt-2 mb-2 p-2">
        <div class="col-12">
          <h4 class="mt-n2">
            Cliente: <span class="font-weight-normal">{{ selected.entity_name }}</span>
          </h4>
          <h4 class="mt-n2">
            CPF / CNPJ: <span class="font-weight-normal">{{ selected.entity_document }}</span>
          </h4>
          <h4 class="mt-n2">
            Central: <span class="font-weight-normal">{{ selected.company_plant_name }} ({{ selected.buyer_ein }})</span>
          </h4>
          <div style="border-top: 1px solid #1a70b7" />
        </div>
      </div>
      <div class="row p-2">
        <div class="col-12">
          <h3>DADOS DA PARCELA</h3>
          <h4>
            Data de vencimento: <span class="font-weight-normal">{{ selected.due_date }}</span>
          </h4>
          <h4 class="mt-n2" v-if="selected.original_due_date">
            Vencimento original: <span class="font-weight-normal">{{ selected.original_due_date | parseDate('DD MMM YYYY') }}</span>
          </h4>
          <h4 v-if="selected.paid_at" class="mt-n2">
            Data de pagamento: <span class="font-weight-normal">{{ selected.paid_at }}</span>
          </h4>
          <h4 class="mt-n2">
            Valor: <span class="font-weight-normal">{{ selected.total_value | currency() }}</span>
          </h4>
          <h4 class="mt-n2">
            Desconto: <span class="font-weight-normal">{{ (selected.discounts_fees || 0) | currency() }}</span>
          </h4>
          <h4 class="mt-n2">
            Juros/Multa: <span class="font-weight-normal">{{ (selected.fees_fines || 0) | currency() }}</span>
          </h4>
          <h4 class="mt-n2">
            Valor final: <span class="font-weight-normal">{{ selected.final_value | currency() }}</span>
          </h4>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: "ModalInfo",
  components: {},
  data() {
    return {
      modal: {
        title: "Informações",
        show: false,
      },
      selected: null,
    };
  },
  methods: {
    openModal(item) {
      this.selected = this.$helper.cloneObject(item);
      this.modal.show = true;
    },
  },
};
</script>
