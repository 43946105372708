<template>
  <div>
    <modal size="lg"
      :show.sync="modal"
      :backgroundColor="'#F2F4F9'"
     
    >
      <template slot="header">
        <div class="d-flex align-items-center container-fluid" style="gap: 12px; margin-left: -28px;">
          <div>
            <img src="/img/icons/icons8/ios/security-shield-green_primary.png" height="24" width="24">
          </div>
          <span class="text-title">
            {{ title }}
          </span>
        </div>
      </template>
      <template slot="close-button">
        <img class="pointer" @click="closeModal" src="/img/icons/icons8/ios/close-window.png" alt="close-window" width="17px">
      </template>
      <div>
        <div class="col-md-12 user-label mb-2">
          <img src="/img/icons/icons8/ios/user-male-circle--v1.png" class="mr-2" height="20" width="20">
          Usuário
        </div>
        <div class="col-md-12 user-info mb-1">
          {{ user_role.name }}
        </div>
        <div class="col-md-12 user-info mb-1">
          {{ user_role.email }}
        </div>
        <div class="col-md-12 group-permission-name mb-4">
          {{ groupPermissionName }}
        </div>

        <div class="system-module-container mt-n1">
          <div class="system-module-label">
            <img src="/img/brand/logo.png" alt="Puzl Place" class="mr-2" width="20.8px" height="20px">
            Módulo de sistema
          </div>
          <div class="system-module-content">
            <PuzlSelect
              v-model="selected"
              :items="modules"
              :disableBoxShadow="true"
              @change="selectFinancialModule"
            />
            <!-- 3: Financeiro -->
            <div v-if="selected === 3" class="mt-3">
              <PuzlSelect
                v-model="selected_financial"
                :items="financial_modules"
                :disableBoxShadow="true"
              />
            </div>
          </div>
        </div>
        
        <!-- 0 - GERAL -->
        <div v-if="selected === 0">
          <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
            <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
              <div class="col-md-12 label-title mb-1">
                Grupo
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-5 pb-1 mt-n1 col-form-label label-item">
                  Permissão
                </label>
                <div class="col-md-7">
                  <base-input input-classes="form-control-sm">
                    <PuzlSelect
                      v-model="user.role_id"
                      :items="group_permissions"
                      :loading="loadingRole"
                      :disabled="loadingRole"
                      :disableBoxShadow="true" />
                  </base-input>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-5 pb-1 mt-n1 mb-3 col-form-label label-item">
                  Alerta
                </label>
                <div class="col-md-7">
                  <base-input input-classes="form-control-sm">
                    <PuzlSelect
                      v-model="userAlertGroup.alert_group_id"
                      :items="$_alertGroups"
                      :loading="loadingAlertGroups"
                      :disabled="loadingAlertGroups"
                      :disableBoxShadow="true" />
                  </base-input>
                </div>
              </div>
              <div class="col-md-12 label-title mb-1 mt-3">
                Alocações
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-5 pb-1 mt-n1 col-form-label label-item">
                  Alertas
                </label>
                <div class="col-md-7">
                  <div v-if="notify == true" class="col-md-7 ml-n3">
                    <a 
                      class="button-active-success"
                      @click="notify = false;"
                      style="color: #198754;"
                    >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="notify == false" class="col-md-7 ml-n3">
                    <a
                      class="button-inactive-danger"
                      @click="notify = true;"
                      style="color: #dc3545;"
                    >
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-5 pb-1 mt-n1 col-form-label label-item">
                  Central
                </label>
                <div class="col-md-7">
                  <base-input input-classes="form-control-sm">
                    <PuzlSelect
                      v-model="plant.company_plant_uuids"
                      :items="plants"
                      customKey="uuid"
                      :multiple="true"
                      :loading="loadingPlants"
                      :disabled="loadingPlants"
                      @input="setAllPlants" 
                      :disableBoxShadow="true" />
                  </base-input>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-5 pb-1 mt-n1 col-form-label label-item">
                  Vendedores
                </label>
                <div class="col-md-7">
                  <base-input input-classes="form-control-sm">
                    <PuzlSelect
                      v-model="seller.users"
                      :items="users"
                      :multiple="true"
                      :loading="loadingSellers"
                      :disabled="loadingSellers"
                      @input="setAllSellers"
                      :disableBoxShadow="true" />
                  </base-input>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-5 pb-1 mt-n1 col-form-label label-item">
                  Conta bancária
                </label>
                <div class="col-md-7">
                  <base-input input-classes="form-control-sm">
                    <PuzlSelect
                      v-model="bank_account.bank_account_ids"
                      :items="$_bank_accounts"
                      :multiple="true"
                      :loading="loadingBankAccounts"
                      :disabled="loadingBankAccounts"
                      @change="setAllBankAccount"
                      :disableBoxShadow="true" />
                  </base-input>
                </div>
              </div>
              <div class="modal-footer">
                <div class="d-flex pr-3" style="gap: 18px;">
                  <div class="d-flex align-items-center py-1 px-4 button-cancel" style="gap: 5px;" @click="closeModal">
                    <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="16px">
                    <span style="font-weight: 500;">
                      Cancelar
                    </span>
                  </div>
                  <div class="d-flex align-items-center py-1 px-4 button-save" style="gap: 5px;" @click.prevent="store">
                    <img src="/img/icons/icons8/ios/save_success.png" alt="save_success" width="16px">
                    <span style="font-weight: 500;">
                      Salvar
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </validation-observer>
        </div>

        <!-- 1 - COMERCIAL -->
        <div v-if="selected === 1">
          <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
            <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
              <div class="col-md-12 label-title mb-2">
                Limitação de desconto
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Aprovação de preço mínimo
                </label>
                <div class="col-md-3 d-flex justify-content-end">
                  <div v-if="$_commercial_permission.minimum_price_approval == true">
                    <a 
                      class="button-active-success"
                      @click="$_commercial_permission.minimum_price_approval = false; changeLimit()"
                      style="color: #198754;"
                    >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="$_commercial_permission.minimum_price_approval == false" class="ml-n3">
                    <a
                      class="button-inactive-danger"
                      @click="$_commercial_permission.minimum_price_approval = true; changeLimit()"
                      style="color: #dc3545;"
                    >
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb2" v-if="$_commercial_permission.minimum_price_approval">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Limitar desconto?
                </label>
                <div class="col-md-3 d-flex justify-content-end">
                  <div v-if="$_commercial_permission.limit_variation == true" class="ml-n3">
                    <a 
                      class="button-active-success"
                      @click="$_commercial_permission.limit_variation = false;"
                      style="color: #198754;"
                    >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="$_commercial_permission.limit_variation == false" class="ml-n3">
                    <a
                      class="button-inactive-danger"
                      @click="$_commercial_permission.limit_variation = true;"
                      style="color: #dc3545;"
                    >
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
              <div class="container-fluid">
                <div class="form-group row m-0 p-0 mb-1 mt-2" v-if="$_commercial_permission.limit_variation">
                  <label class="col-md-6 pb-1 mt-n1 col-form-label label-item">
                    Por margem de usinagem de concreto
                  </label>
                  <div class="col-md-3 mt-1">
                    <base-input input-group-classes="input-group-sm no-box-shadow">
                      <input
                        v-money="money"
                        maxlength="10"
                        inputmode="numeric"
                        v-model.lazy="$_commercial_permission.margin_machining_limit"
                        @keydown="$event.key === '-' ? $event.preventDefault() : null"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                  <div class="col-md-3 mt-1">
                    <input-limit
                      v-model="$_commercial_permission.margin_machining_limit_percentage"
                      :options="{
                        min: 0,
                        max: 100,
                        decimals: 1
                      }"
                      :start_value="$_commercial_permission.margin_machining_limit_percentage"
                      @value="$_commercial_permission.margin_machining_limit_percentage = $event === '-' ? null : $event"
                      input_group_classes="no-box-shadow input-group-sm pb-2"
                    >
                      <small class="input-group-sm p-0 m-0"> % </small>
                    </input-limit>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-1" v-if="$_commercial_permission.limit_variation">
                  <label class="col-md-6 pb-1 mt-n1 col-form-label label-item">
                    Por preço mínimo de venda do concreto
                  </label>
                  <div class="col-md-3 mt-1">
                    <base-input input-group-classes="input-group-sm no-box-shadow">
                      <input
                        v-money="money"
                        maxlength="10"
                        inputmode="numeric"
                        v-model.lazy="$_commercial_permission.margin_sell_limit"
                        @keydown="$event.key === '-' ? $event.preventDefault() : null"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                  <div class="col-md-3 mt-1">
                    <input-limit
                      v-model="$_commercial_permission.margin_sell_limit_percentage"
                      :options="{
                        min: 0,
                        max: 100,
                        decimals: 1
                      }"
                      :start_value="$_commercial_permission.margin_sell_limit_percentage"
                      @value="$_commercial_permission.margin_sell_limit_percentage = $event === '-' ? null : $event"
                      input_group_classes="no-box-shadow input-group-sm pb-2"
                    >
                      <small class="input-group-sm p-0 m-0"> % </small>
                    </input-limit>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-1" v-if="$_commercial_permission.limit_variation">
                  <label class="col-md-6 pb-1 mt-n1 col-form-label label-item">
                    Por preço mínimo de serviços (valor fixo)
                  </label>
                  <div class="col-md-3 mt-1">
                    <base-input input-group-classes="input-group-sm no-box-shadow">
                      <input
                        v-money="money"
                        maxlength="10"
                        inputmode="numeric"
                        v-model.lazy="$_commercial_permission.minimum_price_service_limit"
                        @keydown="$event.key === '-' ? $event.preventDefault() : null"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                  <div class="col-md-3 mt-1">
                    <input-limit
                      v-model="$_commercial_permission.minimum_price_service_limit_percentage"
                      :options="{
                        min: 0,
                        max: 100,
                        decimals: 1
                      }"
                      :start_value="$_commercial_permission.minimum_price_service_limit_percentage"
                      @value="$_commercial_permission.minimum_price_service_limit_percentage = $event === '-' ? null : $event"
                      input_group_classes="no-box-shadow input-group-sm pb-2"
                    >
                      <small class="input-group-sm p-0 m-0"> % </small>
                    </input-limit>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-1" v-if="$_commercial_permission.limit_variation">
                  <label class="col-md-6 pb-1 mt-n1 col-form-label label-item">
                    Por preço mínimo de serviços por m<sup>3</sup>
                  </label>
                  <div class="col-md-3 mt-1">
                    <base-input input-group-classes="input-group-sm no-box-shadow">
                      <input
                        v-money="money"
                        maxlength="10"
                        inputmode="numeric"
                        v-model.lazy="$_commercial_permission.minimum_price_m3_service_limit"
                        @keydown="$event.key === '-' ? $event.preventDefault() : null"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                  <div class="col-md-3 mt-1">
                    <input-limit
                      v-model="$_commercial_permission.minimum_price_m3_service_limit_percentage"
                      :options="{
                        min: 0,
                        max: 100,
                        decimals: 1
                      }"
                      :start_value="$_commercial_permission.minimum_price_m3_service_limit_percentage"
                      @value="$_commercial_permission.minimum_price_m3_service_limit_percentage = $event === '-' ? null : $event"
                      input_group_classes="no-box-shadow input-group-sm pb-2"
                    >
                      <small class="input-group-sm p-0 m-0"> % </small>
                    </input-limit>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-1" v-if="$_commercial_permission.limit_variation">
                  <label class="col-md-6 pb-1 mt-n1 col-form-label label-item">
                    Por preço mínimo de serviços por hora
                  </label>
                  <div class="col-md-3 mt-1">
                    <base-input input-group-classes="input-group-sm no-box-shadow">
                      <input
                        v-money="money"
                        maxlength="10"
                        inputmode="numeric"
                        v-model.lazy="$_commercial_permission.minimum_price_hour_service_limit"
                        @keydown="$event.key === '-' ? $event.preventDefault() : null"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                  <div class="col-md-3 mt-1">
                    <input-limit
                      v-model="$_commercial_permission.minimum_price_hour_service_limit_percentage"
                      :options="{
                        min: 0,
                        max: 100,
                        decimals: 1
                      }"
                      :start_value="$_commercial_permission.minimum_price_hour_service_limit_percentage"
                      @value="$_commercial_permission.minimum_price_hour_service_limit_percentage = $event === '-' ? null : $event"
                      input_group_classes="no-box-shadow input-group-sm pb-2"
                    >
                      <small class="input-group-sm p-0 m-0"> % </small>
                    </input-limit>
                  </div>
                </div>
              </div>

              <div class="warning-container">
                <img src="/img/icons/error-red.png" width="20" alt="Ícone de aviso" class="warning-icon">
                <span class="warning-text">Campos com desconto igual a zero, serão desconsiderados</span>
              </div>

              <label class="col-md-12 label-title">
                Alterações no contrato
              </label>
              <div class="form-group row m-0 p-0 mb-2 mt-1">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Alterar situação de contratos
                </label>
                <div class="col-md-3 d-flex justify-content-end">
                  <div v-if="$_commercial_permission.change_contract_situation == true">
                    <a 
                      class="button-active-success"
                      @click="$_commercial_permission.change_contract_situation = false;"
                      style="color: #198754;"
                    >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="$_commercial_permission.change_contract_situation == false">
                    <a
                      class="button-inactive-danger"
                      @click="$_commercial_permission.change_contract_situation = true;"
                      style="color: #dc3545;"
                    >
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Visualizar detalhes de margem
                </label>
                <div class="col-md-3 d-flex justify-content-end">
                  <div v-if="$_commercial_permission.view_margin_details == true">
                    <a 
                      class="button-active-success"
                      @click="$_commercial_permission.view_margin_details = false;"
                      style="color: #198754;"
                    >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="$_commercial_permission.view_margin_details == false">
                    <a
                      class="button-inactive-danger"
                      @click="$_commercial_permission.view_margin_details = true;"
                      style="color: #dc3545;"
                    >
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Alterar preço de venda de viagens entregues
                </label>
                <div class="col-md-3 d-flex justify-content-end">                  
                  <div v-if="$_commercial_permission.change_sale_price_of_delivered_trip == true">
                    <a 
                      class="button-active-success"
                      @click="$_commercial_permission.change_sale_price_of_delivered_trip = false;"
                      style="color: #198754;"
                    >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="$_commercial_permission.change_sale_price_of_delivered_trip == false">
                    <a
                      class="button-inactive-danger"
                      @click="$_commercial_permission.change_sale_price_of_delivered_trip = true;"
                      style="color: #dc3545;"
                    >
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Alterar e/ou excluir preços de adicionais
                </label>
                <div class="col-md-3 d-flex justify-content-end">
                  <div v-if="$_commercial_permission.edit_additional_values == true">
                    <a 
                      class="button-active-success"
                      @click="$_commercial_permission.edit_additional_values = false;"
                      style="color: #198754;"
                    >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="$_commercial_permission.edit_additional_values == false">
                    <a
                      class="button-inactive-danger"
                      @click="$_commercial_permission.edit_additional_values = true;"
                      style="color: #dc3545;"
                    >
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Editar central e vendedor do contrato?
                </label>
                <div class="col-md-3 d-flex justify-content-end">                  
                  <div v-if="$_commercial_permission.contract_editor_and_seller == true">
                    <a 
                      class="button-active-success"
                      @click="$_commercial_permission.contract_editor_and_seller = false;"
                      style="color: #198754;"
                    >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="$_commercial_permission.contract_editor_and_seller == false">
                    <a
                      class="button-inactive-danger"
                      @click="$_commercial_permission.contract_editor_and_seller = true;"
                      style="color: #dc3545;"
                    >
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Excluir informações complementares com data menor que hoje?
                </label>
                <div class="col-md-3 d-flex justify-content-end">                  
                  <div v-if="$_commercial_permission.is_supplementary_info_before_today == true">
                    <a 
                      class="button-active-success"
                      @click="$_commercial_permission.is_supplementary_info_before_today = false;"
                      style="color: #198754;"
                    >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="$_commercial_permission.is_supplementary_info_before_today == false">
                    <a
                      class="button-inactive-danger"
                      @click="$_commercial_permission.is_supplementary_info_before_today = true;"
                      style="color: #dc3545;"
                    >
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Permite editar recebimentos? 
                </label>
                <div class="col-md-3 d-flex justify-content-end">                  
                  <div v-if="$_commercial_permission.is_receipt_editing_allowed == true">
                    <a 
                      class="button-active-success"
                      @click="$_commercial_permission.is_receipt_editing_allowed = false;"
                      style="color: #198754;"
                    >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="$_commercial_permission.is_receipt_editing_allowed == false">
                    <a
                      class="button-inactive-danger"
                      @click="$_commercial_permission.is_receipt_editing_allowed = true;"
                      style="color: #dc3545;"
                    >
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Permite editar faturas/boletos?
                </label>
                <div class="col-md-3 d-flex justify-content-end">                  
                  <div v-if="$_commercial_permission.is_billing_document_modifiable == true">
                    <a 
                      class="button-active-success"
                      @click="$_commercial_permission.is_billing_document_modifiable = false;"
                      style="color: #198754;"
                    >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="$_commercial_permission.is_billing_document_modifiable == false">
                    <a
                      class="button-inactive-danger"
                      @click="$_commercial_permission.is_billing_document_modifiable = true;"
                      style="color: #dc3545;"
                    >
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
              <label class="col-md-12 label-title mt-2">
                Cadastro de cliente/tomador
              </label>
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Editar o campo de vendedor intermediador
                </label>
                <div class="col-md-3 d-flex justify-content-end">                  
                  <div v-if="$_commercial_permission.is_edit_intermediary_seller_field == true">
                    <a 
                      class="button-active-success"
                      @click="$_commercial_permission.is_edit_intermediary_seller_field = false;"
                      style="color: #198754;"
                    >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="$_commercial_permission.is_edit_intermediary_seller_field == false">
                    <a
                      class="button-inactive-danger"
                      @click="$_commercial_permission.is_edit_intermediary_seller_field = true;"
                      style="color: #dc3545;"
                    >
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>

              <div class="modal-footer">
                <div class="d-flex pr-3" style="gap: 18px;">
                  <div class="d-flex align-items-center py-1 px-4 button-cancel" style="gap: 5px;" @click="closeModal">
                    <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="16px">
                    <span style="font-weight: 500;">
                      Cancelar
                    </span>
                  </div>
                  <div class="d-flex align-items-center py-1 px-4 button-save" style="gap: 5px;" @click.prevent="store">
                    <img src="/img/icons/icons8/ios/save_success.png" alt="save_success" width="16px">
                    <span style="font-weight: 500;">
                      Salvar
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </validation-observer>
        </div>

        <!-- 2 - OPERACIONAL -->
        <div v-if="selected === 2">
          <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
            <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
              <div class="col-md-12 label-title mb-2">
                Programação e viagens
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Cancelamento de viagens
                </label>
                <div class="col-md-3 d-flex justify-content-end">                  
                  <div v-if="$_operational_permission.travel_cancellation == true">
                    <a 
                      class="button-active-success"
                      @click="$_operational_permission.travel_cancellation = false;"
                      style="color: #198754;"
                    >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="$_operational_permission.travel_cancellation == false">
                    <a
                      class="button-inactive-danger"
                      @click="$_operational_permission.travel_cancellation = true;"
                      style="color: #dc3545;"
                    >
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
               <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Cancelamento de serviços
                </label>
                <div class="col-md-3 d-flex justify-content-end">                  
                  <div v-if="$_operational_permission.service_cancellation == true">
                    <a 
                      class="button-active-success"
                      @click="$_operational_permission.service_cancellation = false;"
                      style="color: #198754;"
                    >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="$_operational_permission.service_cancellation == false">
                    <a
                      class="button-inactive-danger"
                      @click="$_operational_permission.service_cancellation = true;"
                      style="color: #dc3545;"
                    >
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Cancelamento de adicionais
                </label>
                <div class="col-md-3 d-flex justify-content-end">
                  <div v-if="$_operational_permission.cancellation_of_additions == true">
                    <a 
                      class="button-active-success"
                      @click="$_operational_permission.cancellation_of_additions = false;"
                      style="color: #198754;"
                    >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="$_operational_permission.cancellation_of_additions == false">
                    <a
                      class="button-inactive-danger"
                      @click="$_operational_permission.cancellation_of_additions = true;"
                      style="color: #dc3545;"
                    >
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Alterar volume de serviços
                </label>
                <div class="col-md-3 d-flex justify-content-end">
                  <div v-if="$_operational_permission.can_edit_schedule_service_volume == true">
                    <a 
                      class="button-active-success"
                      @click="$_operational_permission.can_edit_schedule_service_volume = false;"
                      style="color: #198754;"
                    >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="$_operational_permission.can_edit_schedule_service_volume == false">
                    <a
                      class="button-inactive-danger"
                      @click="$_operational_permission.can_edit_schedule_service_volume = true;"
                      style="color: #dc3545;"
                    >
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Alterar data da concretagem
                </label>
                <div class="col-md-3 d-flex justify-content-end">
                  <div v-if="$_operational_permission.can_edit_schedule_travel_finished_at == true">
                    <a 
                      class="button-active-success"
                      @click="$_operational_permission.can_edit_schedule_travel_finished_at = false;"
                      style="color: #198754;"
                    >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="$_operational_permission.can_edit_schedule_travel_finished_at == false">
                    <a
                      class="button-inactive-danger"
                      @click="$_operational_permission.can_edit_schedule_travel_finished_at = true;"
                      style="color: #dc3545;"
                    >
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Sempre alterar motorista e equipamento
                </label>
                <div class="col-md-3 d-flex justify-content-end">
                  <div v-if="$_operational_permission.is_user_allowed_to_change_driver_and_equip == true">
                    <a 
                      class="button-active-success"
                      @click="$_operational_permission.is_user_allowed_to_change_driver_and_equip = false;"
                      style="color: #198754;"
                    >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="$_operational_permission.is_user_allowed_to_change_driver_and_equip == false">
                    <a
                      class="button-inactive-danger"
                      @click="$_operational_permission.is_user_allowed_to_change_driver_and_equip = true;"
                      style="color: #dc3545;"
                    >
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  "Não realizar" Viagem com O.S já emitida
                </label>
                <div class="col-md-3 d-flex justify-content-end">
                  <div v-if="$_operational_permission.can_not_perform_travel_with_number == true">
                    <a 
                      class="button-active-success"
                      @click="$_operational_permission.can_not_perform_travel_with_number = false;"
                      style="color: #198754;"
                    >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="$_operational_permission.can_not_perform_travel_with_number == false">
                    <a
                      class="button-inactive-danger"
                      @click="$_operational_permission.can_not_perform_travel_with_number = true;"
                      style="color: #dc3545;"
                    >
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Emitir O.S de serviços em programações concluídas ou pendentes
                </label>
                <div class="col-md-3 d-flex justify-content-end">
                  <div v-if="$_operational_permission.can_issue_schedule_service_order_in_finished_or_pending_schedule == true">
                    <a 
                      class="button-active-success"
                      @click="$_operational_permission.can_issue_schedule_service_order_in_finished_or_pending_schedule = false;"
                      style="color: #198754;"
                    >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="$_operational_permission.can_issue_schedule_service_order_in_finished_or_pending_schedule == false">
                    <a
                      class="button-inactive-danger"
                      @click="$_operational_permission.can_issue_schedule_service_order_in_finished_or_pending_schedule = true;"
                      style="color: #dc3545;"
                    >
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-md-12 label-title mt-3 mb-2">
                Carregamento
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Aprovação de reuso
                </label>
                <div class="col-md-3 d-flex justify-content-end">
                  <div v-if="$_operational_permission.reuse_approval == true">
                    <a 
                      class="button-active-success"
                      @click="$_operational_permission.reuse_approval = false;"
                      style="color: #198754;"
                    >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="$_operational_permission.reuse_approval == false">
                    <a
                      class="button-inactive-danger"
                      @click="$_operational_permission.reuse_approval = true;"
                      style="color: #dc3545;"
                    >
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Limpar MCC no carregamento?
                </label>
                <div class="col-md-3 d-flex justify-content-end">
                  <div v-if="$_operational_permission.can_clear_schedule_travel_mcc == true">
                    <a 
                      class="button-active-success"
                      @click="$_operational_permission.can_clear_schedule_travel_mcc = false;"
                      style="color: #198754;"
                    >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="$_operational_permission.can_clear_schedule_travel_mcc == false">
                    <a
                      class="button-inactive-danger"
                      @click="$_operational_permission.can_clear_schedule_travel_mcc = true;"
                      style="color: #dc3545;"
                    >
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Realizar carregamento manual
                </label>
                <div class="col-md-3 d-flex justify-content-end">
                  <div v-if="$_operational_permission.can_manual_schedule_travel_charging == true">
                    <a 
                      class="button-active-success"
                      @click="$_operational_permission.can_manual_schedule_travel_charging = false;"
                      style="color: #198754;"
                    >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="$_operational_permission.can_manual_schedule_travel_charging == false">
                    <a
                      class="button-inactive-danger"
                      @click="$_operational_permission.can_manual_schedule_travel_charging = true;"
                      style="color: #dc3545;"
                    >
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                 Justificar viagem sem anexo
                </label>
                <div class="col-md-3 d-flex justify-content-end">
                  <div v-if="$_operational_permission.is_user_allowed_justify_travel_without_attachment == true">
                    <a 
                      class="button-active-success"
                      @click="$_operational_permission.is_user_allowed_justify_travel_without_attachment = false;"
                      style="color: #198754;"
                    >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="$_operational_permission.is_user_allowed_justify_travel_without_attachment == false">
                    <a
                      class="button-inactive-danger"
                      @click="$_operational_permission.is_user_allowed_justify_travel_without_attachment = true;"
                      style="color: #dc3545;"
                    >
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-md-12 label-title mt-3 mb-2">
                Estoque
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Detalhes de estoque
                </label>
                <div class="col-md-3 d-flex justify-content-end">
                  <div v-if="$_operational_permission.stock_details == true">
                    <a 
                      class="button-active-success"
                      @click="$_operational_permission.stock_details = false;"
                      style="color: #198754;"
                    >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="$_operational_permission.stock_details == false">
                    <a
                      class="button-inactive-danger"
                      @click="$_operational_permission.stock_details = true;"
                      style="color: #dc3545;"
                    >
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Lançamento de ajustes de estoque
                </label>
                <div class="col-md-3 d-flex justify-content-end">
                  <div v-if="$_operational_permission.release_of_stock_adjustments == true">
                    <a 
                      class="button-active-success"
                      @click="$_operational_permission.release_of_stock_adjustments = false;"
                      style="color: #198754;"
                    >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="$_operational_permission.release_of_stock_adjustments == false">
                    <a
                      class="button-inactive-danger"
                      @click="$_operational_permission.release_of_stock_adjustments = true;"
                      style="color: #dc3545;"
                    >
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div class="d-flex pr-3" style="gap: 18px;">
                  <div class="d-flex align-items-center py-1 px-4 button-cancel" style="gap: 5px;" @click="closeModal">
                    <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="16px">
                    <span style="font-weight: 500;">
                      Cancelar
                    </span>
                  </div>
                  <div class="d-flex align-items-center py-1 px-4 button-save" style="gap: 5px;" @click.prevent="store">
                    <img src="/img/icons/icons8/ios/save_success.png" alt="save_success" width="16px">
                    <span style="font-weight: 500;">
                      Salvar
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </validation-observer>
        </div>

        <!-- 3 - FINANCEIRO -->
        <span v-if="selected === 3">
          <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
            <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
              <div v-if="selected_financial === 0">
                <div class="col-md-12 label-title mb-2">
                  Liberação de crédito
                </div>
                <div class="form-group row m-0 p-0 mb-3">
                  <label class="col-md-7 pb-1 mt-n1 col-form-label label-item">
                    Para pessoa Física
                  </label>
                  <div class="col-md-5">
                    <base-input input-group-classes="input-group-sm no-box-shadow">
                      <input
                        @blur="credit.juridical_value = credit.physical_value"
                        v-money="money"
                        maxlength="13"
                        inputmode="numeric"
                        v-model.lazy="credit.physical_value"
                        @keydown="$event.key === '-' ? $event.preventDefault() : null"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-3">
                  <label class="col-md-7 pb-1 mt-n1 col-form-label label-item">
                    Para Pessoa Jurídica
                  </label>
                  <div class="col-md-5">
                    <base-input input-group-classes="input-group-sm no-box-shadow">
                      <input
                        v-money="money"
                        maxlength="13"
                        inputmode="numeric"
                        v-model.lazy="credit.juridical_value"
                        @keydown="$event.key === '-' ? $event.preventDefault() : null"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-3">
                  <label class="col-md-7 pb-1 mt-n1 col-form-label label-item">
                    Prazo máximo
                  </label>
                  <div class="col-md-5">
                    <validation-provider rules="required|min:0">
                      <input-limit
                        :options="{
                          min: 0,
                          max: 360,
                        }"
                        :start_value="credit.days"
                        v-model="credit.days"
                        @value="credit.days = $event === '-' ? null : $event"
                        input_group_classes="no-box-shadow input-group-sm pb-2"
                      >
                        <small class="input-group-sm p-0 m-0"> Dias </small>
                      </input-limit>
                    </validation-provider>
                  </div>
                </div>
              </div>
              <!-- CONTAS A RECEBER -->
              <div v-if="selected_financial === 1">
                <div class="form-group row m-0 p-0 mb-2">
                  <label class="col-md-9 label-title">
                    Geral
                  </label>
                  <div class="col-md-3 d-flex justify-content-end">
                    <div v-if="$_financial_limit.is_limit_general_values == true">
                      <a 
                        class="button-active-success"
                        @click="$_financial_limit.is_limit_general_values = false;"
                        style="color: #198754;"
                      >
                        <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                        Ativo
                      </a>
                    </div>
                    <div v-if="$_financial_limit.is_limit_general_values == false">
                      <a
                        class="button-inactive-danger"
                        @click="$_financial_limit.is_limit_general_values = true;"
                        style="color: #dc3545;"
                      >
                        <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                        Inativo
                      </a>
                    </div>
                  </div>
                </div>
                <div v-show="$_financial_limit.is_limit_general_values">
                  <div class="form-group row m-0 p-0 mb-3">
                    <label class="col-md-9 label-item">
                      Descontos
                    </label>
                    <div class="col-md-3">
                      <base-input input-group-classes="input-group-sm no-box-shadow" group>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="$_financial_limit.general_discounts_fees"
                          v-money="money"
                          inputmode="numeric"
                          @keydown="$event.key === '-' ? $event.preventDefault() : null"
                        />
                        <template slot="prepend">
                          <small class="input-group-sm p-0 m-0"> R$ </small>
                        </template>
                      </base-input>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-3">
                   <label class="col-md-9 label-item">
                      Taxas
                    </label>
                    <div class="col-md-3">
                      <base-input input-group-classes="input-group-sm no-box-shadow" group>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="$_financial_limit.fees"
                          v-money="money"
                          inputmode="numeric"
                          @keydown="$event.key === '-' ? $event.preventDefault() : null"
                        />
                        <template slot="prepend">
                          <small class="input-group-sm p-0 m-0"> R$ </small>
                        </template>
                      </base-input>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-3">
                    <label class="col-md-9 label-item">
                      Inadimplência máxima
                    </label>
                    <div class="col-md-3">
                      <base-input input-group-classes="input-group-sm no-box-shadow" group>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="$_financial_limit.general_maximum_default"
                          v-money="money"
                          inputmode="numeric"
                          @keydown="$event.key === '-' ? $event.preventDefault() : null"
                        />
                        <template slot="prepend">
                          <small class="input-group-sm p-0 m-0"> R$ </small>
                        </template>
                      </base-input>
                    </div>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-2 mt-4">
                  <label class="col-md-9 label-title">
                    Alteração de vencimento
                  </label>
                  <div class="col-md-3 d-flex justify-content-end">
                    <div v-if="$_financial_limit.disable_max_extension_due_date == true">
                      <a 
                        class="button-active-success"
                        @click="$_financial_limit.disable_max_extension_due_date = false;"
                        style="color: #198754;"
                      >
                        <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                        Ativo
                      </a>
                    </div>
                    <div v-if="$_financial_limit.disable_max_extension_due_date == false">
                      <a
                        class="button-inactive-danger"
                        @click="$_financial_limit.disable_max_extension_due_date = true;"
                        style="color: #dc3545;"
                      >
                        <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                        Inativo
                      </a>
                    </div>
                  </div>
                </div>
                <div v-show="$_financial_limit.disable_max_extension_due_date" class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-9 label-item">
                    Prazo máximo de alteração
                  </label>
                  <div class="col-md-3">
                    <base-input input-group-classes="input-group-sm no-box-shadow" group>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="$_financial_limit.general_extension_due_date"
                        inputmode="numeric"
                        @keydown="$event.key === '-' ? $event.preventDefault() : null"
                      />
                      <template slot="append">
                        <div class="input-group-append">
                          <small class="input-group-sm p-0 m-0"> Dias </small>
                        </div>
                      </template>
                    </base-input>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-2 mt-4">
                  <label class="col-md-9 label-title">
                    Aprovação
                  </label>
                  <div class="col-md-3 d-flex justify-content-end">
                    <div v-if="$_financial_limit.is_limit_approval_values == true">
                      <a 
                        class="button-active-success"
                        @click="$_financial_limit.is_limit_approval_values = false;"
                        style="color: #198754;"
                      >
                        <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                        Ativo
                      </a>
                    </div>
                    <div v-if="$_financial_limit.is_limit_approval_values == false">
                      <a
                        class="button-inactive-danger"
                        @click="$_financial_limit.is_limit_approval_values = true;"
                        style="color: #dc3545;"
                      >
                        <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                        Inativo
                      </a>
                    </div>
                  </div>
                </div>
                <div v-show="$_financial_limit.is_limit_approval_values" class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-9 label-item">
                    Por dia
                  </label>
                  <div class="col-md-3">
                    <base-input input-group-classes="input-group-sm no-box-shadow">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="$_financial_limit.approval_per_day"
                        v-money="money"
                        inputmode="numeric"
                        @keydown="$event.key === '-' ? $event.preventDefault() : null"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-2 mt-4">
                  <label class="col-md-9 label-title">
                    Recebimento
                  </label>
                  <div class="col-md-3 d-flex justify-content-end">
                    <div v-if="$_financial_limit.is_limit_receipt_values == true">
                      <a 
                        class="button-active-success"
                        @click="$_financial_limit.is_limit_receipt_values = false;"
                        style="color: #198754;"
                      >
                        <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                        Ativo
                      </a>
                    </div>
                    <div v-if="$_financial_limit.is_limit_receipt_values == false">
                      <a
                        class="button-inactive-danger"
                        @click="$_financial_limit.is_limit_receipt_values = true;"
                        style="color: #dc3545;"
                      >
                        <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                        Inativo
                      </a>
                    </div>
                  </div>
                </div>
                <div v-show="$_financial_limit.is_limit_receipt_values" class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-9 label-item">
                    Por dia
                  </label>
                  <div class="col-md-3">
                    <base-input input-group-classes="input-group-sm no-box-shadow">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="$_financial_limit.receivement_per_day"
                        v-money="money"
                        inputmode="numeric"
                        @keydown="$event.key === '-' ? $event.preventDefault() : null"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-2 mt-4">
                  <label class="col-md-9 label-title">
                    Cancelamento
                  </label>
                  <div class="col-md-3 d-flex justify-content-end">
                    <div v-if="$_financial_limit.is_limit_cancellation_values == true">
                      <a 
                        class="button-active-success"
                        @click="$_financial_limit.is_limit_cancellation_values = false;"
                        style="color: #198754;"
                      >
                        <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                        Ativo
                      </a>
                    </div>
                    <div v-if="$_financial_limit.is_limit_cancellation_values == false">
                      <a
                        class="button-inactive-danger"
                        @click="$_financial_limit.is_limit_cancellation_values = true;"
                        style="color: #dc3545;"
                      >
                        <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                        Inativo
                      </a>
                    </div>
                  </div>
                </div>
                <div v-show="$_financial_limit.is_limit_cancellation_values" class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-9 label-item">
                    Por dia
                  </label>
                  <div class="col-md-3">
                    <base-input input-group-classes="input-group-sm no-box-shadow">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="$_financial_limit.cancellation_per_day"
                        v-money="money"
                        inputmode="numeric"
                        @keydown="$event.key === '-' ? $event.preventDefault() : null"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-inpuT>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-2 mt-4">
                  <label class="col-md-9 label-title">
                    Estorno de recebível
                  </label>
                  <div class="col-md-3 d-flex justify-content-end">
                    <div v-if="$_financial_limit.is_limit_reversal_of_receivable_values == true">
                      <a 
                        class="button-active-success"
                        @click="$_financial_limit.is_limit_reversal_of_receivable_values = false;"
                        style="color: #198754;"
                      >
                        <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                        Ativo
                      </a>
                    </div>
                    <div v-if="$_financial_limit.is_limit_reversal_of_receivable_values == false">
                      <a
                        class="button-inactive-danger"
                        @click="$_financial_limit.is_limit_reversal_of_receivable_values = true;"
                        style="color: #dc3545;"
                      >
                        <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                        Inativo
                      </a>
                    </div>
                  </div>
                </div>
                <div v-show="$_financial_limit.is_limit_reversal_of_receivable_values" class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-9 label-item">
                    Por dia
                  </label>
                  <div class="col-md-3">
                    <base-input input-group-classes="input-group-sm no-box-shadow">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="$_financial_limit.reversal_of_receivable_per_day"
                        v-money="money"
                        inputmode="numeric"
                        @keydown="$event.key === '-' ? $event.preventDefault() : null"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                </div>
              </div>
              <!-- CONTAS A PAGAR -->
              <div v-if="selected_financial === 2">
                <div class="form-group row m-0 p-0 mb-2">
                  <label class="col-md-9 label-title">
                    Geral
                  </label>
                  <div class="col-md-3 d-flex justify-content-end">
                    <div v-if="$_financial_limit.bill_pay_is_limit_general_values == true">
                      <a 
                        class="button-active-success"
                        @click="$_financial_limit.bill_pay_is_limit_general_values = false;"
                        style="color: #198754;"
                      >
                        <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                        Ativo
                      </a>
                    </div>
                    <div v-if="$_financial_limit.bill_pay_is_limit_general_values == false">
                      <a
                        class="button-inactive-danger"
                        @click="$_financial_limit.bill_pay_is_limit_general_values = true;"
                        style="color: #dc3545;"
                      >
                        <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                        Inativo
                      </a>
                    </div>
                  </div>
                </div>
                <div v-show="$_financial_limit.bill_pay_is_limit_general_values">
                  <div class="form-group row m-0 p-0 mb-3">
                    <label class="col-md-9 label-item">
                      Descontos
                    </label>
                    <div class="col-md-3">
                      <base-input input-group-classes="input-group-sm no-box-shadow" group>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="$_financial_limit.bill_pay_general_discounts_fees"
                          v-money="money"
                          inputmode="numeric"
                          @keydown="$event.key === '-' ? $event.preventDefault() : null"
                        />
                        <template slot="prepend">
                          <small class="input-group-sm p-0 m-0"> R$ </small>
                        </template>
                      </base-input>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-3">
                    <label class="col-md-9 label-item">
                      Taxas
                    </label>
                    <div class="col-md-3">
                      <base-input input-group-classes="input-group-sm no-box-shadow" group>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="$_financial_limit.bill_pay_fees"
                          v-money="money"
                          inputmode="numeric"
                          @keydown="$event.key === '-' ? $event.preventDefault() : null"
                        />
                        <template slot="prepend">
                          <small class="input-group-sm p-0 m-0"> R$ </small>
                        </template>
                      </base-input>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-3">
                    <label class="col-md-9 label-item">
                      Inadimplência máxima
                    </label>
                    <div class="col-md-3">
                      <base-input input-group-classes="input-group-sm no-box-shadow" group>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="$_financial_limit.bill_pay_general_maximum_default"
                          v-money="money"
                          inputmode="numeric"
                          @keydown="$event.key === '-' ? $event.preventDefault() : null"
                        />
                        <template slot="prepend">
                          <small class="input-group-sm p-0 m-0"> R$ </small>
                        </template>
                      </base-input>
                    </div>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-2 mt-4">
                  <label class="col-md-9 label-title">
                    Alteração de vencimento
                  </label>
                  <div class="col-md-3 d-flex justify-content-end">
                    <div v-if="$_financial_limit.bill_pay_is_limit_amounts_change_due_date == true">
                      <a 
                        class="button-active-success"
                        @click="$_financial_limit.bill_pay_is_limit_amounts_change_due_date = false;"
                        style="color: #198754;"
                      >
                        <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                        Ativo
                      </a>
                    </div>
                    <div v-if="$_financial_limit.bill_pay_is_limit_amounts_change_due_date == false">
                      <a
                        class="button-inactive-danger"
                        @click="$_financial_limit.bill_pay_is_limit_amounts_change_due_date = true;"
                        style="color: #dc3545;"
                      >
                        <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                        Inativo
                      </a>
                    </div>
                  </div>
                </div>
                <div v-show="$_financial_limit.bill_pay_is_limit_amounts_change_due_date" class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-9 label-item">
                    Prazo máximo de alteração
                  </label>
                  <div class="col-md-3">
                    <base-input input-group-classes="input-group-sm no-box-shadow" group>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="$_financial_limit.bill_pay_general_extension_due_date"
                        inputmode="numeric"
                        @keydown="$event.key === '-' ? $event.preventDefault() : null"
                      />
                      <template slot="append">
                        <div class="input-group-append">
                          <small class="input-group-sm p-0 m-0"> Dias </small>
                        </div>
                      </template>
                    </base-input>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-2 mt-4">
                  <label class="col-md-9 label-title">
                    Aprovação
                  </label>
                  <div class="col-md-3 d-flex justify-content-end">
                    <div v-if="$_financial_limit.bill_pay_is_limit_approval_values == true">
                      <a 
                        class="button-active-success"
                        @click="$_financial_limit.bill_pay_is_limit_approval_values = false;"
                        style="color: #198754;"
                      >
                        <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                        Ativo
                      </a>
                    </div>
                    <div v-if="$_financial_limit.bill_pay_is_limit_approval_values == false">
                      <a
                        class="button-inactive-danger"
                        @click="$_financial_limit.bill_pay_is_limit_approval_values = true;"
                        style="color: #dc3545;"
                      >
                        <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                        Inativo
                      </a>
                    </div>
                  </div>
                </div>
                <div v-show="$_financial_limit.bill_pay_is_limit_approval_values" class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-9 label-item">
                    Por dia
                  </label>
                  <div class="col-md-3">
                    <base-input input-group-classes="input-group-sm no-box-shadow">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="$_financial_limit.bill_pay_approval_per_day"
                        v-money="money"
                        inputmode="numeric"
                        @keydown="$event.key === '-' ? $event.preventDefault() : null"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-2 mt-4">
                  <label class="col-md-9 label-title">
                    Pagamento
                  </label>
                  <div class="col-md-3 d-flex justify-content-end">
                    <div v-if="$_financial_limit.is_limit_payment_values == true">
                      <a 
                        class="button-active-success"
                        @click="$_financial_limit.is_limit_payment_values = false;"
                        style="color: #198754;"
                      >
                        <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                        Ativo
                      </a>
                    </div>
                    <div v-if="$_financial_limit.is_limit_payment_values == false">
                      <a
                        class="button-inactive-danger"
                        @click="$_financial_limit.is_limit_payment_values = true;"
                        style="color: #dc3545;"
                      >
                        <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                        Inativo
                      </a>
                    </div>
                  </div>
                </div>
                <div v-show="$_financial_limit.is_limit_payment_values" class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-9 label-item">
                    Por dia
                  </label>
                  <div class="col-md-3">
                    <base-input input-group-classes="input-group-sm no-box-shadow">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="$_financial_limit.payment_per_day"
                        v-money="money"
                        inputmode="numeric"
                        @keydown="$event.key === '-' ? $event.preventDefault() : null"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-2 mt-4">
                  <label class="col-md-9 label-title">
                    Cancelamento
                  </label>
                  <div class="col-md-3 d-flex justify-content-end">
                    <div v-if="$_financial_limit.bill_pay_is_limit_cancellation_values == true">
                      <a 
                        class="button-active-success"
                        @click="$_financial_limit.bill_pay_is_limit_cancellation_values = false;"
                        style="color: #198754;"
                      >
                        <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                        Ativo
                      </a>
                    </div>
                    <div v-if="$_financial_limit.bill_pay_is_limit_cancellation_values == false">
                      <a
                        class="button-inactive-danger"
                        @click="$_financial_limit.bill_pay_is_limit_cancellation_values = true;"
                        style="color: #dc3545;"
                      >
                        <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                        Inativo
                      </a>
                    </div>
                  </div>
                </div>
                <div v-show="$_financial_limit.bill_pay_is_limit_cancellation_values" class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-9 label-item">
                    Por dia
                  </label>
                  <div class="col-md-3">
                    <base-input input-group-classes="input-group-sm no-box-shadow">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="$_financial_limit.bill_pay_cancellation_per_day"
                        v-money="money"
                        inputmode="numeric"
                        @keydown="$event.key === '-' ? $event.preventDefault() : null"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-inpuT>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-2 mt-4">
                  <label class="col-md-9 label-title">
                    Estorno de pagamento
                  </label>
                  <div class="col-md-3 d-flex justify-content-end">
                    <div v-if="$_financial_limit.is_limit_payment_reversal_values == true">
                      <a 
                        class="button-active-success"
                        @click="$_financial_limit.is_limit_payment_reversal_values = false;"
                        style="color: #198754;"
                      >
                        <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                        Ativo
                      </a>
                    </div>
                    <div v-if="$_financial_limit.is_limit_payment_reversal_values == false">
                      <a
                        class="button-inactive-danger"
                        @click="$_financial_limit.is_limit_payment_reversal_values = true;"
                        style="color: #dc3545;"
                      >
                        <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                        Inativo
                      </a>
                    </div>
                  </div>
                </div>
                <div v-show="$_financial_limit.is_limit_payment_reversal_values" class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-9 label-item">
                    Por dia
                  </label>
                  <div class="col-md-3">
                    <base-input input-group-classes="input-group-sm no-box-shadow">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="$_financial_limit.payment_reversal_per_day"
                        v-money="money"
                        inputmode="numeric"
                        @keydown="$event.key === '-' ? $event.preventDefault() : null"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                </div>
              </div>
              <div class="mt-4" v-if="selected_financial === 3">
                <label class="col-md-9 label-title">
                  Faturamento
                </label>
                <div class="form-group row m-0 p-0 mb-3 mt-2">
                  <label class="col-md-9 label-item">
                    Alterar preço de concreto
                  </label>
                  <div class="col-md-3 d-flex justify-content-end">
                    <div v-if="$_financial_limit.invoice_can_change_concrete_price == true">
                      <a 
                        class="button-active-success"
                        @click="$_financial_limit.invoice_can_change_concrete_price = false;"
                        style="color: #198754;"
                      >
                        <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                        Ativo
                      </a>
                    </div>
                    <div v-if="$_financial_limit.invoice_can_change_concrete_price == false">
                      <a
                        class="button-inactive-danger"
                        @click="$_financial_limit.invoice_can_change_concrete_price = true;"
                        style="color: #dc3545;"
                      >
                        <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                        Inativo
                      </a>
                    </div>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-3">
                  <label class="col-md-9 label-item">
                    Alterar valor de serviço
                  </label>
                  <div class="col-md-3 d-flex justify-content-end">
                    <div v-if="$_financial_limit.invoice_can_change_service_value == true">
                      <a 
                        class="button-active-success"
                        @click="$_financial_limit.invoice_can_change_service_value = false;"
                        style="color: #198754;"
                      >
                        <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                        Ativo
                      </a>
                    </div>
                    <div v-if="$_financial_limit.invoice_can_change_service_value == false">
                      <a
                        class="button-inactive-danger"
                        @click="$_financial_limit.invoice_can_change_service_value = true;"
                        style="color: #dc3545;"
                      >
                        <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                        Inativo
                      </a>
                    </div>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-3">
                  <label class="col-md-9 label-item">
                    Alterar valor de adicionais
                  </label>
                  <div class="col-md-3 d-flex justify-content-end">
                    <div v-if="$_financial_limit.invoice_can_change_additional_value == true">
                      <a 
                        class="button-active-success"
                        @click="$_financial_limit.invoice_can_change_additional_value = false;"
                        style="color: #198754;"
                      >
                        <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                        Ativo
                      </a>
                    </div>
                    <div v-if="$_financial_limit.invoice_can_change_additional_value == false">
                      <a
                        class="button-inactive-danger"
                        @click="$_financial_limit.invoice_can_change_additional_value = true;"
                        style="color: #dc3545;"
                      >
                        <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                        Inativo
                      </a>
                    </div>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-3">
                  <label class="col-md-9 label-item">
                    Aprovar avulso
                  </label>
                  <div class="col-md-3 d-flex justify-content-end">
                    <div v-if="$_financial_limit.invoice_can_add_single == true">
                      <a 
                        class="button-active-success"
                        @click="$_financial_limit.invoice_can_add_single = false;"
                        style="color: #198754;"
                      >
                        <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                        Ativo
                      </a>
                    </div>
                    <div v-if="$_financial_limit.invoice_can_add_single == false">
                      <a
                        class="button-inactive-danger"
                        @click="$_financial_limit.invoice_can_add_single = true;"
                        style="color: #dc3545;"
                      >
                        <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                        Inativo
                      </a>
                    </div>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-3">
                  <label class="col-md-9 label-item">
                    Estornar fatura
                  </label>
                  <div class="col-md-3 d-flex justify-content-end">
                    <div v-if="$_financial_limit.invoice_can_reverse == true">
                      <a 
                        class="button-active-success"
                        @click="$_financial_limit.invoice_can_reverse = false;"
                        style="color: #198754;"
                      >
                        <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                        Ativo
                      </a>
                    </div>
                    <div v-if="$_financial_limit.invoice_can_reverse == false">
                      <a
                        class="button-inactive-danger"
                        @click="$_financial_limit.invoice_can_reverse = true;"
                        style="color: #dc3545;"
                      >
                        <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                        Inativo
                      </a>
                    </div>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-3">
                  <label class="col-md-9 label-item">
                    Cancelar nfs-e
                  </label>
                  <div class="col-md-3 d-flex justify-content-end">
                    <div v-if="$_financial_limit.invoice_can_cancel_nfse == true">
                      <a 
                        class="button-active-success"
                        @click="$_financial_limit.invoice_can_cancel_nfse = false;"
                        style="color: #198754;"
                      >
                        <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                        Ativo
                      </a>
                    </div>
                    <div v-if="$_financial_limit.invoice_can_cancel_nfse == false">
                      <a
                        class="button-inactive-danger"
                        @click="$_financial_limit.invoice_can_cancel_nfse = true;"
                        style="color: #dc3545;"
                      >
                        <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                        Inativo
                      </a>
                    </div>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-3">
                  <label class="col-md-9 label-item">
                    Incluir desconto de reidi
                  </label>
                  <div class="col-md-3 d-flex justify-content-end">
                    <div v-if="$_financial_limit.invoice_can_include_reidi == true">
                      <a 
                        class="button-active-success"
                        @click="$_financial_limit.invoice_can_include_reidi = false;"
                        style="color: #198754;"
                      >
                        <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                        Ativo
                      </a>
                    </div>
                    <div v-if="$_financial_limit.invoice_can_include_reidi == false">
                      <a
                        class="button-inactive-danger"
                        @click="$_financial_limit.invoice_can_include_reidi = true;"
                        style="color: #dc3545;"
                      >
                        <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                        Inativo
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div class="d-flex pr-3" style="gap: 18px;">
                  <div class="d-flex align-items-center py-1 px-4 button-cancel" style="gap: 5px;" @click="closeModal">
                    <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="16px">
                    <span style="font-weight: 500;">
                      Cancelar
                    </span>
                  </div>
                  <div class="d-flex align-items-center py-1 px-4 button-save" style="gap: 5px;" @click.prevent="store">
                    <img src="/img/icons/icons8/ios/save_success.png" alt="save_success" width="16px">
                    <span style="font-weight: 500;">
                      Salvar
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </validation-observer>
        </span>

        <!-- 4 - TÉCNICO -->
        <div v-if="selected === 4">
          <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
            <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
              <div class="col-md-12 label-title">
                Permissões
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Alterar referências comerciais dos grupos de MCC
                </label>
                <div class="col-md-3 d-flex justify-content-end">
                  <div v-if="$_technical_permission.is_cmc_group_business_references_modified == true">
                    <a 
                      class="button-active-success"
                      @click="
                        $_technical_permission.is_cmc_group_business_references_modified = false;
                        changeLimit();
                      "
                      style="color: #198754;"
                    >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="$_technical_permission.is_cmc_group_business_references_modified == false">
                    <a
                      class="button-inactive-danger"
                      @click="
                        $_technical_permission.is_cmc_group_business_references_modified = true;
                        changeLimit();
                      "
                      style="color: #dc3545;"
                    >
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Alterar referências técnicas dos grupos de MCC
                </label>
                <div class="col-md-3 d-flex justify-content-end">
                  <div v-if="$_technical_permission.is_change_technical_references_of_mcc_groups == true">
                    <a 
                      class="button-active-success"
                      @click="
                        $_technical_permission.is_change_technical_references_of_mcc_groups = false;
                        changeLimit();
                      "
                      style="color: #198754;"
                    >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="$_technical_permission.is_change_technical_references_of_mcc_groups == false">
                    <a
                      class="button-inactive-danger"
                      @click="
                        $_technical_permission.is_change_technical_references_of_mcc_groups = true;
                        changeLimit();
                      "
                      style="color: #dc3545;"
                    >
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Visualizar dados técnicos dos traços em lançamento de resultados de ensaios
                </label>
                <div class="col-md-3 d-flex justify-content-end">
                  <div v-if="$_technical_permission.is_view_technical_data_of_traces_in_test_results_launch == true">
                    <a 
                      class="button-active-success"
                      @click="
                        $_technical_permission.is_view_technical_data_of_traces_in_test_results_launch = false;
                        changeLimit();
                      "
                      style="color: #198754;"
                    >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="$_technical_permission.is_view_technical_data_of_traces_in_test_results_launch == false">
                    <a
                      class="button-inactive-danger"
                      @click="
                        $_technical_permission.is_view_technical_data_of_traces_in_test_results_launch = true;
                        changeLimit();
                      "
                      style="color: #dc3545;"
                    >
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Aplicar alterações de puzl adjust
                </label>
                <div class="col-md-3 d-flex justify-content-end">
                  <div v-if="$_technical_permission.is_puzl_adjust_applied == true">
                    <a 
                      class="button-active-success"
                      @click="
                        $_technical_permission.is_puzl_adjust_applied = false;
                        changeLimit();
                      "
                      style="color: #198754;"
                    >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="$_technical_permission.is_puzl_adjust_applied == false">
                    <a
                      class="button-inactive-danger"
                      @click="
                        $_technical_permission.is_puzl_adjust_applied = true;
                        changeLimit();
                      "
                      style="color: #dc3545;"
                    >
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Alterar preços de MCC
                </label>
                <div class="col-md-3 d-flex justify-content-end">
                  <div v-if="$_technical_permission.is_mcc_prices_changed == true">
                    <a 
                      class="button-active-success"
                      @click="
                        $_technical_permission.is_mcc_prices_changed = false;
                        changeLimit();
                      "
                      style="color: #198754;"
                    >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="$_technical_permission.is_mcc_prices_changed == false">
                    <a
                      class="button-inactive-danger"
                      @click="
                        $_technical_permission.is_mcc_prices_changed = true;
                        changeLimit();
                      "
                      style="color: #dc3545;"
                    >
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item">
                  Concluir carregamentos com desvios acima do limite configurado
                </label>
                <div class="col-md-3 d-flex justify-content-end">
                  <div v-if="$_technical_permission.is_block_completion_possible_with_excessive_deviation == true">
                    <a 
                      class="button-active-success"
                      @click="
                        $_technical_permission.is_block_completion_possible_with_excessive_deviation = false;
                        changeLimit();
                      "
                      style="color: #198754;"
                    >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="$_technical_permission.is_block_completion_possible_with_excessive_deviation == false">
                    <a
                      class="button-inactive-danger"
                      @click="
                        $_technical_permission.is_block_completion_possible_with_excessive_deviation = true;
                        changeLimit();
                      "
                      style="color: #dc3545;"
                    >
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div class="d-flex pr-3" style="gap: 18px;">
                  <div class="d-flex align-items-center py-1 px-4 button-cancel" style="gap: 5px;" @click="closeModal">
                    <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="16px">
                    <span style="font-weight: 500;">
                      Cancelar
                    </span>
                  </div>
                  <div class="d-flex align-items-center py-1 px-4 button-save" style="gap: 5px;" @click.prevent="store">
                    <img src="/img/icons/icons8/ios/save_success.png" alt="save_success" width="16px">
                    <span style="font-weight: 500;">
                      Salvar
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </validation-observer>
        </div>
        <div v-if="selected === 5"> <!-- Compras -->
          <div class="px-3 pt-3">
            <div class="d-flex align-items-center mb-3">
              <div class="font-weight-bold text-dark mr-3">Geral</div>
              <div class="dropdown-divider w-100 mr-3" />
            </div>
            <div class="form-group row m-0 p-0 mb-2">
              <label class="col-md-5 pb-1 mt-n1 col-form-label label-item pl-0 pt-1">
                Visualização por categoria
              </label>
              <div class="col-md-7">
                <base-input input-classes="form-control-sm">
                  <PuzlSelect
                    v-model="purchase.view_categories"
                    :items="[ { id: 0, description: 'Todos' }, ...purchase_categories ]"
                    label="description"
                    :multiple="true"
                    @input="setAllViewCategories"
                    :disableBoxShadow="true" />
                </base-input>
              </div>
            </div>
            <div class="form-group row m-0 p-0 mb-2">
              <label class="col-md-5 pb-1 mt-n1 col-form-label label-item pl-0 pt-1">
                Permissão por categoria
              </label>
              <div class="col-md-7">
                <base-input input-classes="form-control-sm">
                  <PuzlSelect 
                    v-model="purchase.edit_categories"
                    :items="[ { id: 0, description: 'Todos' }, ...purchase_categories ]"
                    label="description"
                    :multiple="true"
                    @input="setAllEditCategories"
                    :disableBoxShadow="true" />
                </base-input>
              </div>
            </div>
            <div class="form-group row m-0 p-0 mb-4">
              <label class="col-md-9 pb-1 mt-n1 col-form-label label-item pl-0">
                  Edição de categoria
                </label>
                <div class="col-md-3 d-flex justify-content-end mt-2">
                  <div v-if="purchase.purchase_category_edit == true">
                    <a class="button-active-success" @click="purchase.purchase_category_edit = false;"
                      style="color: #198754;">
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="purchase.purchase_category_edit == false">
                    <a class="button-inactive-danger" @click="purchase.purchase_category_edit = true;"
                      style="color: #dc3545;">
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
            </div>
            <div class="d-flex align-items-center mb-3">
              <div class="font-weight-bold text-dark mr-3 text-nowrap">
                Requisição de compra
              </div>
              <div class="dropdown-divider w-100 mr-3" />
            </div>
            <div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item pl-0">
                  Realizar aprovação
                </label>
                <div class="col-md-3 d-flex justify-content-end">
                  <div v-if="purchase.purchase_request_approve == true">
                    <a class="button-active-success" @click="purchase.purchase_request_approve = false;"
                      style="color: #198754;">
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="purchase.purchase_request_approve == false">
                    <a class="button-inactive-danger" @click="purchase.purchase_request_approve = true;"
                      style="color: #dc3545;">
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item pl-0">
                  Visualização apenas de requisições proprias
                </label>
                <div class="col-md-3 d-flex justify-content-end">
                  <div v-if="purchase.purchase_request_view_own == true">
                    <a class="button-active-success" @click="purchase.purchase_request_view_own = false;"
                      style="color: #198754;">
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="purchase.purchase_request_view_own == false">
                    <a class="button-inactive-danger" @click="purchase.purchase_request_view_own = true;"
                      style="color: #dc3545;">
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center mb-3">
              <div class="font-weight-bold text-dark mr-3 text-nowrap">
                Cotação de compra
              </div>
              <div class="dropdown-divider w-100 mr-3" />
            </div>
            <div>
              <div class="form-group row m-0 p-0 mb-3">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item pl-0">
                  Visualização apenas de requisições proprias
                </label>
                <div class="col-md-3 d-flex justify-content-end">
                  <div v-if="purchase.purchase_order_view_own == true">
                    <a class="button-active-success" @click="purchase.purchase_order_view_own = false;"
                      style="color: #198754;">
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="purchase.purchase_order_view_own == false">
                    <a class="button-inactive-danger" @click="purchase.purchase_order_view_own = true;"
                      style="color: #dc3545;">
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center mb-3">
              <div class="font-weight-bold text-dark mr-3 text-nowrap">
                Ordem de compra
              </div>
              <div class="dropdown-divider w-100 mr-3" />
            </div>
            <div class="form-group row m-0 p-0 mb-2 justify-content-between">
              <label class="col-md-5 pb-1 mt-n1 col-form-label label-item pl-0 pt-1">
                Permissões
              </label>
              <div class="col-md-5">
                <base-input input-classes="form-control-sm">
                  <PuzlSelect
                    v-model="purchase.order_permission"
                    :items="[
                      { id: 1, description: 'Aprovar' },
                      { id: 2, description: 'Cancelar' },
                      { id: 3, description: 'Concluir' },
                      { id: 4, description: 'Retomar' },
                      ]"
                    label="description"
                    :multiple="true"
                    @input="setOrderPermission"
                    :disableBoxShadow="true" />
                </base-input>
              </div>
            </div>
            <div class="form-group row m-0 p-0 mb-3">
                <label class="col-md-9 pb-1 mt-n1 col-form-label label-item pl-0">
                  Limitar valor de aprovação
                </label>
                <div class="col-md-3 d-flex justify-content-end">
                  <div v-if="purchase.limit_order_approval_value == true">
                    <a class="button-active-success" @click="purchase.limit_order_approval_value = false;"
                      style="color: #198754;">
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </a>
                  </div>
                  <div v-if="purchase.limit_order_approval_value == false">
                    <a class="button-inactive-danger" @click="purchase.limit_order_approval_value = true;"
                      style="color: #dc3545;">
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </a>
                  </div>
                </div>
              </div>
            <div v-if="purchase.limit_order_approval_value" class="form-group row m-0 p-0 mb-3">
              <label class="col-md-7 pb-1 mt-n1 col-form-label label-item pl-0">
                Valor maximo para aprovação
              </label>
              <div class="col-md-5 mb-4 pt-2">
                <base-input input-group-classes="input-group-sm no-box-shadow">
                  <input
                    v-money="money"
                    maxlength="13"
                    inputmode="numeric"
                    v-model.lazy="purchase.max_approval_value"
                    type="text"
                    class="form-control form-control-sm"
                  />
                  <template slot="prepend">
                    <small class="input-group-sm p-0 m-0"> R$ </small>
                  </template>
                </base-input>
              </div>
            </div>
            <div class="modal-footer w-100 p-0 mb-3 pr-2">
              <div class="d-flex justify-content-end" style="gap: 18px;">
                <div class="d-flex align-items-center py-1 px-4 button-cancel" style="gap: 5px;" @click="closeModal">
                  <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="16px">
                  <span style="font-weight: 500;">
                    Cancelar
                  </span>
                </div>
                <div class="d-flex align-items-center py-1 px-4 button-save" style="gap: 5px;" @click.prevent="updatePurchasePermission()">
                  <img src="/img/icons/icons8/ios/save_success.png" alt="save_success" width="16px">
                  <span style="font-weight: 500;">
                    Salvar
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
import {VMoney} from "v-money";
import {moneyToFloat} from "@/helpers";
import InputLimit from "@/components/Utils/InputLimit";
import {strToNum} from "@/helpers";

const {formatErrorValidation} = require("@/plugins");

export default {
  name: "ModalSetUserRole",
  components: {PuzlSelect, InputLimit},
  data() {
    return {
      title: 'Permissões de usuário',
      modal: false,
      loadingSave: false,
      loadingStore: false,
      count: 0,
      loader: null,
      userId: null,
      groupPermissionName: '',
      user_id_logged_in: null,
      selected: 0, // Geral
      selected_financial: 0, // Liberação de crédito
      //permissões
      loadingRole: true,
      loadingAlertGroups: true,
      loadingBankAccounts: false,
      user: {
        user_id: null,
        role_id: null,
      },
      userAlertGroup: {
        user_id: null,
        alert_group_id: null,
      },
      notify: false,
      //centrais
      loadingPlants: true,
      plant: {
        user_uuid: null,
        company_plant_uuids: [],
      },
      bank_account: {
        user_id: null,
        bank_account_ids: [],
      },
      //vendedores
      loadingSellers: false,
      loadingNotify: false,
      seller: {
        users: [],
        user_id: null
      },
      //Limite de crédito
      credit: {
        id: null,
        physical_value: null,
        juridical_value: null,
        days: 0,
        user_id: null,
      },
      //Compras
      purchase: {
        view_categories: [],
        edit_categories: [],
        order_permission: [],
        view_all_categories: false,
        edit_all_categories: false,
        purchase_request_approve: false,
        purchase_request_view_own: false,
        purchase_category_edit: false,
        purchase_order_view_own: false,
        purchase_order_approve: false,
        purchase_order_cancel: false,
        purchase_order_complete: false,
        purchase_order_resume: false,
        limit_order_approval_value: false,
        max_approval_value: 50000.00,
      },
      defaultPurchasePermission: {},
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      payloadFinancialLimit: null,
      modules: [
        {id: 0, name: 'Geral'},
        {id: 1, name: 'Comercial'},
        {id: 2, name: 'Operacional'},
        {id: 3, name: 'Financeiro'},
        {id: 4, name: 'Técnico'},
        {id: 5, name: 'Compras'},
      ],
      financial_modules: [
        {id: 0, name: 'Liberação de crédito'},
        {id: 1, name: 'Contas a receber - limitações de valores'},
        {id: 2, name: 'Contas a pagar - limitações de valores'},
        {id: 3, name: 'Faturamento'},
      ],
      purchase_categories: [],
    };
  },
  computed: {
    ...mapGetters({
      group_permissions: "authGroupPermission/fetch",
      user_role: "userRole/show",
      plants: "plant/getPlantWithAll",
      user_plants: "userPlant/show",
      users: "user/getSellerWithAll",
      $_financial_limit: "permissionFinancialLimit/show",
      $_bank_accounts: "bankAccount/fetchWithAll",
      $_commercial_permission: "commercialPermission/show",
      $_operational_permission: "operationalPermission/show",
      $_technical_permission: "technicalPermission/show",
      $_alertGroups: "alertGroup/fetch",
    })
  },
  directives: {money: VMoney},
  watch: {
    'count'() {
      if (this.count === 5) {
        this.loader.hide();
      }
    }
  },
  methods: {
    changeLimit() {
      if (! this.$_commercial_permission.minimum_price_approval) {
        this.$_commercial_permission.limit_variation = false
      }
    },
    closeModal() {
      this.modal = false
    },
    /**
     * @param {number} userId
     * @param {string} userUuid
     * @param {number} user_id_logged_in - id usuário logado
     * @param {string} groupPermissionName - nome do grupo de permissão
     */
    async openModal(userId, userUuid, user_id_logged_in, groupPermissionName) {
      this.modal = true;
      this.notify = false
      this.count = 0;
      this.userId = userId;
      this.groupPermissionName = groupPermissionName;
      this.user_id_logged_in = user_id_logged_in;
      this.userAlertGroup.user_id = userId;
      this.userAlertGroup.alert_group_id = null;
      this.loader = this.$loading.show();
      await this.loadData();
      await this.fetchUserRoles(userId);
      await this.fetchPlants(userId);
      await this.fetchSellers(userUuid);
      await this.fechUserCredit(userId);
      await this.fetchFinancialLimits(userId);
      await this.fetchCommercialPermissions(userId);
      await this.fetchOperationalPermissions(userId);
      await this.fetchTechnicalPermissions(userId);
      await this.fetchUserAlertGroup(userId);
      this.fetchPurchaseCategories();
      this.getPurchasePermission(userId);
      this.loadingNotify = true
      this.$store.dispatch('user/show', userUuid).then((response) => {
        this.notify = response.data.notify;
        this.loadingNotify = false;
        this.purchase.user_id = response.data.id;
        this.purchase.view_categories = [0];
        if (response.data.purchase_categories.length > 0 && !this.purchase.view_all_categories) {
          this.purchase.view_categories = response.data.purchase_categories.map(category => category.id);
        }
        this.purchase.edit_categories = [0];
        if (response.data.purchase_categories.length > 0 && !this.purchase.edit_all_categories) {
          this.purchase.edit_categories = response.data.purchase_categories
          .filter(category => category.pivot.can_approve === 1)
          .map(category => category.id);
        }
      })
      this.loadingBankAccount = true;
      this.selected = 0;
      this.$store.dispatch("bankAccount/fetchItems", {allocated: 0, status: true}).then(() => {
        this.loadingBankAccount = false;
      });
    },
    fetchUserRoles(userId) {
      this.loadingRole = true
      this.$store.dispatch('userRole/show', userId).then(({data}) => {
        this.user = {
          user_id: userId,
          role_id: data.role.length ? data.role[0].id : null,
        }
        this.loadingRole = false
        this.count++;
      })
    },
    /**
     * @param {string} userUuid
     */
    fetchPlants(userUuid) {
      this.loadingPlants = true
      this.$store.dispatch('userPlant/show', userUuid).then(response => {
        let plants = []
        if (this.user_plants.plants[0] === 'all' && response.data.has_access_to_all_plants === 1) {
          plants = this.user_plants.plants
        } else {
          plants = this.user_plants.plants ? this.user_plants.plants
              .filter(item => item.uuid !== undefined)
              .map(item => item.uuid)
            : [];
        }

        this.plant = {
          user_uuid: userUuid,
          company_plant_uuids: this.$route.query.modal ? null : plants,
        }
        this.loadingPlants = false
        this.count++;
      }).catch(error => {
        this.loadingPlants = false;
      });
    },
    setAllPlants() {
      const index = this.plant.company_plant_uuids.findIndex((item) => item == 'all');
      if (this.plant.company_plant_uuids.length > 1 && index === 0) {
        this.plant.company_plant_uuids.splice(0, 1)
      }
      if (this.plant.company_plant_uuids.length > 1 && this.plant.company_plant_uuids.includes("all")) {
        this.plant.company_plant_uuids = []
        this.plant.company_plant_uuids.push("all")
      }
    },
    setAllBankAccount() {
      const index = this.bank_account.bank_account_ids.findIndex((item) => item == 'all');
      if (this.bank_account.bank_account_ids.length > 1 && index === 0) {
        this.bank_account.bank_account_ids.splice(0, 1)
      }
      if (this.bank_account.bank_account_ids.length > 1 && this.bank_account.bank_account_ids.includes("all")) {
        this.bank_account.bank_account_ids = []
        this.bank_account.bank_account_ids.push("all")
      }
    },
    /**
     * @param {string} userUuid
     */
    fetchSellers(userUuid) {
      this.loadingSellers = true;
      this.$store.dispatch("user/show", userUuid).then(response => {
        if (response.data.view_sellers && response.data.view_sellers.length) {
          this.seller.users = response.data.view_sellers;
        }
        if (response.data.bank_account_ids && response.data.bank_account_ids.length) {
          this.bank_account.bank_account_ids = response.data.bank_account_ids;
        }
        this.seller.user_id = response.data.id;
        this.bank_account.user_id = response.data.id;
        this.$store.dispatch("user/fetchItemsFilterable", {
          filter: {
            is_seller: 1
          }
        }).then(($) => {
          this.loadingSellers = false;
          this.count++;
        }).catch(error => {
          this.loadingSellers = false;
        });
      })
    },
    setAllSellers() {
      const index = this.seller.users.findIndex((item) => item == 'all');
      if (this.seller.users.length > 1 && index === 0) {
        this.seller.users.splice(0, 1)
      }
      if (this.seller.users.length > 1 && this.seller.users.includes("all")) {
        this.seller.users = []
        this.seller.users.push("all")
      }
    },
    /**
     * @param {string} userUuid
     */
    fechUserCredit(userId) {
      this.credit = {
        id: null,
        physical_value: null,
        juridical_value: null,
        user_id: userId,
        days: 0,
      };
      this.$store.dispatch("userCredit/getByUser", userId).then((response) => {
        if (response.data) {
          this.credit = response.data;
        }
        this.count++;
      });
    },
    async store() {
      let loader = this.$loading.show();
      this.loadingStore = true;

      //Central
      let plant = {...this.plant};
      plant.has_access_to_all_plants = 0;
      if (this.plant.company_plant_uuids.includes('all')) {
        plant.company_plant_uuids = [];
        plant.has_access_to_all_plants = 1;
      }

      // Vendedor
      let seller = {...this.seller};
      // Contas
      let bank_account = {...this.bank_account};

      // Comercial
      if (this.$_commercial_permission.user_id === null) {
        this.$_commercial_permission.user_id = this.userId;
      }

      // Operacional
      if (this.$_operational_permission.user_id === null) {
        this.$_operational_permission.user_id = this.userId;
      }

      // Técnico
      if (this.$_technical_permission.user_id === null) {
        this.$_technical_permission.user_id = this.userId;
      }

      let commercial_permission = {...this.$_commercial_permission}
      /**
       * transforma valores monetários em númericos
       * @type {Number}
       */
      commercial_permission.margin_sell_limit = strToNum(commercial_permission.margin_sell_limit)
      commercial_permission.margin_machining_limit = strToNum(commercial_permission.margin_machining_limit)
      commercial_permission.minimum_price_service_limit = strToNum(commercial_permission.minimum_price_service_limit)
      commercial_permission.minimum_price_m3_service_limit = strToNum(commercial_permission.minimum_price_m3_service_limit)
      commercial_permission.minimum_price_hour_service_limit = strToNum(commercial_permission.minimum_price_hour_service_limit)

      await this.createOrUpdateFinancialLimits();

      Promise.all([
        this.$store.dispatch("userRole/add", this.user),
        this.$store.dispatch("userPlant/add", plant),
        this.$store.dispatch("user/setViewSellers", seller),
        this.$store.dispatch("user/setBankAccount", bank_account),
        this.$store.dispatch('commercialPermission/createOrUpdate', commercial_permission),
        this.$store.dispatch('operationalPermission/createOrUpdate', this.$_operational_permission),
        this.$store.dispatch('technicalPermission/store', this.$_technical_permission),
        this.$store.dispatch('user/setNotify', {user_id: this.user.user_id, notify: Number(this.notify)}),
        this.$store.dispatch('alertGroup/storeUserAlertGroup', this.userAlertGroup),
        this.$store.dispatch('permissionChange/store', {
          user_id: this.userId,
          user_id_logged_in: this.user_id_logged_in,
        }),
      ]).then((response) => {
        this.$notify({
          type: response[0].error_type,
          message: response[0].message
        });
        this.loadingStore = false;
        loader.hide();
        this.$router.push({query: null});
        this.closeModal();
        // response[1].data é o resultado da ação dispatch "userPlant/add"
        // response[9].data é o resultado da ação dispatch "permissionChange/store"
        this.$emit('storedAfterCenterAllocation', response[1].data, response[9].data);
      }).catch((error) => {
        let errors = error.status ? error.data.message : formatErrorValidation(error.response.data.errors);
        this.$notify({type: "danger", message: errors});
        loader.hide();
        this.loadingStore = false;
      });
    },
    /**
     * @param {number} userId
     */
    fetchCommercialPermissions(userId) {
      this.$store.dispatch('commercialPermission/getByUserId', userId);
    },
    /**
     * @param {number} userId
     */
    fetchTechnicalPermissions(userId) {
      this.$store.dispatch('technicalPermission/getByUserId', userId);
    },
    /**
     * @param {number} userId
     */
    fetchOperationalPermissions(userId) {
      this.$store.dispatch('operationalPermission/getByUserId', userId);
    },
    /**
     * @param {number} userId
     */
    fetchFinancialLimits(userId) {
      this.$store.dispatch('permissionFinancialLimit/show', userId);
    },
    /**
     * @param {number} userId
     */
    fetchUserAlertGroup(userId) {
      this.loadingAlertGroups = true;
      this.$store.dispatch('alertGroup/showUserAlertGroup', userId).then((response) => {
        if (response.data) {
          this.userAlertGroup.alert_group_id = response.data;
        }
        this.count++;
        this.loadingAlertGroups = false;
      });
    },
    async createOrUpdateFinancialLimits() {
      if (this.$_financial_limit.user_id) {
        await this.updateFinancialLimits();
      } else {
        await this.storeFinancialLimits();
      }

      //liberação de crédito
      let credit = JSON.parse(JSON.stringify(this.credit));
      credit.physical_value = this.$helper.strToNum(credit.physical_value);
      credit.juridical_value = this.$helper.strToNum(credit.juridical_value);
      await this.$store.dispatch("userCredit/add", credit);
    },
    storeFinancialLimits() {
      this.validatedPayloadFinancialLimit();
      this.$store.dispatch('permissionFinancialLimit/add', this.payloadFinancialLimit)
        .catch((error) => {
          if (error.status === 200) {
            this.$notify({type: 'danger', message: error.data.message})
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors)
            this.$notify({type: 'danger', message: errors})
          }
        });
    },
    updateFinancialLimits() {
      this.validatedPayloadFinancialLimit();
      this.$store.dispatch('permissionFinancialLimit/update', this.payloadFinancialLimit)
        .catch((error) => {
          if (error.status === 200) {
            this.$notify({type: 'danger', message: error.data.message});
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors)
            this.$notify({type: 'danger', message: errors});
          }
        });
    },
    validatedPayloadFinancialLimit() {
      this.payloadFinancialLimit = {...this.$_financial_limit};
      this.payloadFinancialLimit.user_id = this.userId;
      // CONTAS A RECEBER
      // Geral
      this.payloadFinancialLimit.general_discounts_fees = strToNum(this.payloadFinancialLimit.general_discounts_fees);
      this.payloadFinancialLimit.fees = strToNum(this.payloadFinancialLimit.fees);
      this.payloadFinancialLimit.general_maximum_default = strToNum(this.payloadFinancialLimit.general_maximum_default);
      // Alteração de vencimento
      this.payloadFinancialLimit.general_extension_due_date = strToNum(this.payloadFinancialLimit.general_extension_due_date);
      // Aprovação
      this.payloadFinancialLimit.approval_per_day = strToNum(this.payloadFinancialLimit.approval_per_day);
      // Recebimento
      this.payloadFinancialLimit.receivement_per_day = strToNum(this.payloadFinancialLimit.receivement_per_day);
      // Cancelamento
      this.payloadFinancialLimit.cancellation_per_day = strToNum(this.payloadFinancialLimit.cancellation_per_day);
      // Estorno de recebível
      this.payloadFinancialLimit.reversal_of_receivable_per_day = strToNum(this.payloadFinancialLimit.reversal_of_receivable_per_day);

      // CONTAS A PAGAR
      // Geral
      this.payloadFinancialLimit.bill_pay_general_discounts_fees = strToNum(this.payloadFinancialLimit.bill_pay_general_discounts_fees);
      this.payloadFinancialLimit.bill_pay_fees = strToNum(this.payloadFinancialLimit.bill_pay_fees);
      this.payloadFinancialLimit.bill_pay_general_maximum_default = strToNum(this.payloadFinancialLimit.bill_pay_general_maximum_default);
      // Alteração de vencimento
      this.payloadFinancialLimit.bill_pay_general_extension_due_date = strToNum(this.payloadFinancialLimit.bill_pay_general_extension_due_date);
      // Aprovação
      this.payloadFinancialLimit.bill_pay_approval_per_day = strToNum(this.payloadFinancialLimit.bill_pay_approval_per_day);
      // Pagamento
      this.payloadFinancialLimit.payment_per_day = strToNum(this.payloadFinancialLimit.payment_per_day);
      // Cancelamento
      this.payloadFinancialLimit.bill_pay_cancellation_per_day = strToNum(this.payloadFinancialLimit.bill_pay_cancellation_per_day);
      // Estorno de pagamento
      this.payloadFinancialLimit.payment_reversal_per_day = strToNum(this.payloadFinancialLimit.payment_reversal_per_day);
    },
    loadData() {
      this.$store.dispatch("alertGroup/getAllSimplified").then(() => {
        this.count++;
        this.loadingAlertGroups = false;
      });
    },
    selectFinancialModule() {
      // Módulo financeiro
      if (this.selected === 3) {
        this.selected_financial = 0; // // Liberação de crédito
      }
    },
  // COMPRAS
    updatePurchasePermission() {
      this.purchase.view_all_categories = this.purchase.view_categories.includes(0);
      this.purchase.edit_all_categories = this.purchase.edit_categories.includes(0);
      let purchasePermissionData = {...this.purchase};

      if (this.purchase.limit_order_approval_value) {
        purchasePermissionData.max_approval_value = Number(this.purchase.max_approval_value.replaceAll(".", "").replace(",", "."));
      }
      purchasePermissionData.categories = this.transformCategories(this.purchase);
 
      let loader = this.$loading.show()
      this.$store.dispatch('user/updatePurchasePermissions', purchasePermissionData)
      .then(() => {
          loader.hide();
          this.$notify({type: 'success', message: 'Alteração salva!'});
        })
      .catch(() => {
          loader.hide();
          this.$notify({type: 'danger', message: 'Erro ao salvar alteração!'});
        })
    },
    fetchPurchaseCategories() {
      this.$store.dispatch('purchaseCategory/fetchItemsPaginate', {
        order_by: [{ column: 'purchase_categories.id' }]})
        .then((response) => {
          this.purchase_categories = response.data.items.filter(item => item.status === true);
        });
    },
    getPurchasePermission(userId) {
      let loader = this.$loading.show();
      this.$store.dispatch('user/getPurchasePermission', userId)
        .then((response) => {
          if (response.data === null) {
            this.purchase = this.defaultPurchasePermission;
            return;
          }
          this.purchase = { ...this.purchase, ...response.data };
          this.purchase.order_permission = this.getSelectedIds(this.purchase);
        }).finally(() => {
          loader.hide()
        });
    },
    transformCategories(input) {
       const result = { categories: [] };
      input.view_categories.forEach((category) => {
         if (category) {
          const canApprove = input.edit_categories.includes(category);
          result.categories.push({
              purchase_category_id: category,
              can_approve: canApprove
          });
         }
          
      });
      input.edit_categories.forEach((category) => {
          if (!input.view_categories.includes(category)) {
            if (category) { 
              result.categories.push({
                  purchase_category_id: category,
                  can_approve: true
              });
            }
          
          }
      });
      return result.categories;
    },
    setOrderPermission(selectedIds) {
      const mapping = {
        1: 'purchase_order_approve',
        2: 'purchase_order_cancel',
        3: 'purchase_order_complete',
        4: 'purchase_order_resume',
      };
      for (const key in mapping) {
        this.purchase[mapping[key]] = selectedIds.includes(parseInt(key));
      }
    },
    getSelectedIds() {
      const mapping = {
        1: 'purchase_order_approve',
        2: 'purchase_order_cancel',
        3: 'purchase_order_complete',
        4: 'purchase_order_resume',
      };

      const selectedIds = [];

      for (const key in mapping) {
        if (this.purchase[mapping[key]] === true) {
          selectedIds.push(parseInt(key));
        }
      }

      return selectedIds;
    },
    setAllViewCategories() {
      const index = this.purchase.view_categories.findIndex((item) => item == 0);
      if (this.purchase.view_categories.length > 1 && index === 0) {
        this.purchase.view_categories.splice(0, 1)
      }
      if (this.purchase.view_categories.length > 1 && this.purchase.view_categories.includes(0)) {
        this.purchase.view_categories = []
        this.purchase.view_categories.push(0)
      }
    },
    setAllEditCategories() {
      const index = this.purchase.edit_categories.findIndex((item) => item == 0);
      if (this.purchase.edit_categories.length > 1 && index === 0) {
        this.purchase.edit_categories.splice(0, 1)
      }
      if (this.purchase.edit_categories.length > 1 && this.purchase.edit_categories.includes(0)) {
        this.purchase.edit_categories = []
        this.purchase.edit_categories.push(0)
      }
    }
  },
  mounted() {
    this.$store.dispatch("authGroupPermission/fetchItems", 0);
    this.defaultPurchasePermission = { ...this.purchase };
  }
}
</script>

<style>
/** header modal */
.text-title {
  color: #2B2D32;
  font-family: Fredoka;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/** labels dados do usuário */
.user-label {
  color: #2B2D32;
  font-family: Fredoka;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.user-info {
  color: #2B2D32;
  font-family: Fredoka;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.group-permission-name {
  color: var(--Cores-Global-Cinza-Puzl, #606062);
  font-family: Fredoka;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/** catainer e label módulo do sistema */
.system-module-container {
  background-color: #E8E8E8;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  margin-left: 12px;
  margin-right: 12px;
}
.system-module-label {
  color: #2B2D32;
  font-family: Fredoka, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
}

/** labels padrão */
.label-title {
  color: #2B2D32;
  font-family: Fredoka;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.label-item {
  color: #2B2D32;
  font-family: Fredoka;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/** botões ativo e inativo */
.button-active-success {
  background-color: #F2F7F3;
  border-radius: 8px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #149E57;
  display: flex;
  width: 130px;
  height: 25px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
}
.button-inactive-danger {
  background-color: #F4C7C3;
  border-radius: 8px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #DB4539;
  display: flex;
  width: 130px;
  height: 25px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
}

/** mesagem de alerta em comercial */
.warning-container {
  background-color: #FCF3F3;
  border-radius: 4px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 90%;
  margin: 15px auto;
  box-sizing: border-box;
}
.warning-icon {
  flex-shrink: 0;
  margin-right: 10px;
}
.warning-text {
  color: #2B2D32;
  font-family: Fredoka, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
}

/** Botões salvar e cancelar */
.button-cancel {
  cursor: pointer;
  border: 0.5px solid #DB4539;
  border-radius: 8px;
  color: #DB4539;
}
.button-save {
  cursor: pointer;
  border: 0.5px solid #149E57;
  border-radius: 8px;
  color: #149E57;
}

/** remove sombra dos input */
input.form-control.no-box-shadow {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
/** remove sobra dos input-limit */
.no-box-shadow input, .no-box-shadow div span.input-group-text {
  box-shadow: none !important;
}
</style>
