<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <div class="px-3 py-2">
          <h5 class="mt-0 font-weight-500 mb-0">
            <span class="new-default-black-font font-weight-normal"> Status : </span>
            <span class="new-default-black-font">{{ payload.integration_info.totvs_status === 0 ? "Pendente" : "Integrado" }} </span>
            <base-button @click="store" v-if="!payload.integration_info.totvs_status" size="sm" class="ml-2 mt-n1" type="success">
              INTEGRAR
            </base-button>
          </h5>
          <h5 class="mt-0 mb-1 font-weight-500">
            <span class="new-default-black-font font-weight-normal"> Idmov : </span>
            <span class="new-default-black-font"
              >{{ payload.integration_info.totvs_id ? payload.integration_info.totvs_id : "Nenhum" }}
            </span>
          </h5>
          <h5 class="mt-0 font-weight-500 mb-1">
            <span class="new-default-black-font font-weight-normal"> Data de integração : </span>
            <span v-if="payload.integration_info.totvs_id" class="new-default-black-font"
              >{{ payload.integration_info.updated_at | parseDate() }}
            </span>
            <span v-else class="new-default-black-font">Nenhuma </span>
          </h5>
          <h5 v-if="!payload.integration_info.totvs_status" class="mt-0 font-weight-500 mb-1BOLETOS">
            <span class="new-default-black-font font-weight-normal"> Erro de integração : </span>
            <span class="new-default-black-font">{{ payload.integration_info.response }} </span>
          </h5>
          <h5 v-if="!payload.billet_info.length && payload.integration_info.totvs_status" class="mt-0 font-weight-500 mb-0">
            <span class="new-default-black-font font-weight-normal"> Boleto : </span>
            <span class="new-default-black-font"> Não </span>
            <base-button @click.prevent="generateBillet" size="sm" class="ml-2 mt-n1" type="default"> GERAR BOLETO </base-button>
          </h5>
          <div v-else-if="payload.integration_info.totvs_status">
            <div class="mb-2 mt-4 card-header border-header-default-bottom p-2 grid-col--fixed-left row">
              <h5 class="mb-0" slot="title">Boletos</h5>
            </div>
            <div v-for="(item, index) in payload.billet_info" :key="index" class="timeline-block d-flex justify-content-left mt-3">
              <span class="timeline-step badge-primary ml-3" style="border: 1px solid white; left: 0% !important">
                <small
                  ><b style="color: white">{{ index + 1 }}</b>
                </small>
              </span>
              <div class="timeline-content">
                <div class="row mb-2">
                  <a :href="item.billet_url" target="_blank">
                    <img class="ml-1" style="width: 50px" src="/img/icons/boleto.png" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-center row mt-3">
          <base-button type="secondary" @click="closeModal('create')"> Fechar </base-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { invoice as $_totvs } from "@/plugins/microservices/totvs";

export default {
  data() {
    return {
      modal: {
        title: "Integração Totvs",
        create: false,
      },
      invoice_id: null,
      payload: {
        billet_info: [],
        integration_info: {
          totvs_id: null,
          totvs_status: 0,
          updated_at: null,
          response: null,
        },
      },
    };
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal(id) {
      const loader = this.$loading.show();
      this.invoice_id = id;
      this.payload = {
        billet_info: [],
        integration_info: {
          totvs_id: null,
          totvs_status: 0,
          updated_at: null,
          response: null,
        },
      };

      $_totvs
        .getIntegrationInfo(id)
        .then((response) => {
          this.modal.create = true;
          if (response.data && response.data.integration_info) {
            this.payload = response.data;
          }
        })
        .finally(() => {
          loader.hide();
        });
    },
    generateBillet() {
      const loader = this.$loading.show();
      $_totvs
        .generateBillet(this.invoice_id)
        .then((response) => {
          if (response.data.data.length) {
            this.payload.billet_info = response.data.data;
          }
        })
        .finally(() => {
          loader.hide();
        });
    },
    store() {
      const loader = this.$loading.show();
      $_totvs.store({ invoice_id: this.invoice_id }).finally(() => {
        this.closeModal();
        loader.hide();
      });
    },
  },
};
</script>
