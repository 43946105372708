<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div class="p-2">
              <!-- Produto -->
              <div class="border-left border-primary border-3 pl-2">
                <h4 class="mb-0 col-12 pl-2">{{ product.product_name }} {{ product.code ? `(${product.code})` : "" }}</h4>
                <h4 v-if="product.product_service_hub_type_id === 2" class="mb-0 col-12 pl-2">
                  {{ product.origin_name }}
                </h4>
                <h4 class="mb-0 col-12 font-weight-normal pl-2">{{ product.type_name }}</h4>
              </div>

              <!-- CPF/CNPJ -->
              <div class="border-left border-success border-3 mt-2 pl-2">
                <h4 class="mb-0 col-12 pl-2">{{ entity.entity_name }}</h4>
                <h4 class="mb-0 col-12 font-weight-normal pl-2">{{ entity.document }}</h4>
              </div>

              <div>
                <!-- Status -->
                <div class="form-group row m-1 p-0">
                  <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                    Status
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-7 row p-0 ml-0">
                    <div class="col-md-6 pt-2">
                      <base-button
                        outline
                        block
                        size="sm"
                        type="success"
                        v-model="active"
                        @click="active = true"
                        :class="active === true ? 'active' : ''"
                      >
                        Ativo
                      </base-button>
                    </div>
                    <div class="col-md-6 pt-2">
                      <base-button
                        outline
                        block
                        size="sm"
                        type="danger"
                        v-model="active"
                        @click="active = false"
                        :class="active === false ? 'active' : ''"
                      >
                        Inativo
                      </base-button>
                    </div>
                  </div>
                </div>

                <!-- Data -->
                <div class="form-group row m-1 p-0">
                  <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                    Data
                    <span class="text-danger">&nbsp;*</span>
                  </label>

                  <div class="col-md-7 pt-2">
                    <input-date-time-picker v-model="item.validity_start" :mode="'date'" />
                  </div>
                </div>

                <!-- Preço -->
                <div class="form-group row m-1 p-0">
                  <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                    Preço(fob)
                    <span class="text-danger">&nbsp;*</span>
                  </label>

                  <div class="col-md-7 pt-2">
                    <validation-provider rules="required">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          maxlength="10"
                          inputmode="numeric"
                          v-money="money"
                          v-model.lazy="price"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <template slot="prepend">
                          <small class="input-group-sm p-0 m-0"> R$ </small>
                        </template>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
              </div>

              <!-- Unid. de compra -->
              <div class="form-group row m-1 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Unid. de compra
                  <span class="text-danger">&nbsp;*</span>
                </label>

                <div class="col-md-7 pt-2">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input :disabled="product.product_service_hub_type_id === 2" maxlength="6" v-model="item.purchase_unit" type="text" class="form-control form-control-sm" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="modal-footer">
                <base-button type="secondary" @click="closeModal()"> Cancelar </base-button>
                <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loading"> Salvar </base-button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { formatErrorValidation } from "@/plugins";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import { VMoney } from "v-money";
import moment from "moment";

export default {
  name: "ModalCreatePrice",
  components: { InputDateTimePicker },
  data() {
    return {
      modal: {
        title: "Adicionar Preço de Compra",
        create: false,
      },
      item: {
        status: 1,
        validity_start: null,
        price: null,
        purchase_unit: null,
        product_service_hub_id: null,
        entity_id: null,
      },
      active: true,
      price: null,
      product: {},
      entity: {},
      loading: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
    };
  },
  directives: { money: VMoney },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal(entity, product) {
      this.item = {
        status: 1,
        validity_start: new Date(),
        price: null,
        purchase_unit: null,
        product_service_hub_id: product.id,
        entity_id: entity.id,
      };
      if(product.product_service_hub_type_id === 2) {
        this.getMeasuredUnitBuy(product.hubable_id)
      }
      this.active = true;
      this.price = null;
      this.entity = entity;
      this.product = product;
      this.modal.create = true;
    },
    store() {
      this.loading = true;
      let item = {... this.item}
      item.status = this.active ? 1 : 0;
      item.price = this.price.replaceAll(".", "").replace(",", ".");
      item.validity_start =  moment(this.item.validity_start).format('YYYY-MM-DD')
      this.$store
        .dispatch("productServiceHubPrice/add", item)
        .then((response) => {
          this.loading = false;
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.$emit("createdPrice", this.item.product_service_hub_id);
          this.closeModal();
        })
        .catch((error) => {
          if (error.status === 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
            this.loading = false;
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
            this.loading = false;
          }
        });
    },
    getMeasuredUnitBuy(hubable_id) { 
      this.$store.dispatch("cmc/getMeasuredUnitBuy", hubable_id).then((response) => {
        this.item.purchase_unit = response.data.letter
      })
    }
  },
};
</script>

<style scoped></style>
