<template>
  <div>
    <modal :show.sync="modal.create" size="md">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(downloadEntryLaunchExternalExcel)" autocomplete="off">
            <div>
              <!-- Emissão -->
              <div class="form-group row m-1 p-0 mb-3">
                <label class="col-md-5 pb-0 pt-2 mb-2 col-form-label form-control-label">
                Emissão
                </label>
                <div class="col-md-7">
                    <base-input input-group-classes="input-group-sm">
                      <input-date-picker
                        :default-range="range"
                        ref="date-picker"
                        value-format="yyyy-MM-dd"
                        :filterable="4"
                        @handleFilterDate="handleFilterDate"
                      />
                    </base-input>
                </div>
              </div>

              <div class="modal-footer d-flex justify-content-center">
                <base-button type="secondary" @click="closeModal('create')"> Cancelar </base-button>
                <base-button type="success" native-type="submit" v-bind:disabled="invalid"> Confirmar </base-button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InputDatePicker from "@/components/InputDatePicker.vue";
import moment from "moment";
import PuzlSelect from "@/components/PuzlSelect";

export default {
  components: {PuzlSelect, InputDatePicker},
  data() {
    return {
      modal: {
        title: "exportar notas da sefaz não lançadas",
        create: false,
      },
      loadingCompanyPlantBuyer: true,
      company_plant_buyers: [],
      form: {
        type: null,
        company_plant_uuids: null,
      },
      no_reload : false,
      range: {
        start: moment().startOf("month").format("YYYY-MM-DD"),
        end: moment().endOf("month").format("YYYY-MM-DD"),
      },
      loadingPlant: true,
      flag: 0,
    };
  },
  computed: {
    ...mapGetters({
      $_plants: "plant/activeItems",
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal(no_reload = false) {
      this.no_reload = no_reload;
      this.loadingPlant = false;
      this.modal.create = true;
    },
    downloadEntryLaunchExternalExcel() {
      if(moment.duration(moment(this.range.end).diff(moment(this.range.start))).asDays() > 14) {
        return this.$notify({type: 'danger', message: 'O período selecionado não pode ser maior que 14 dias.'});
      }

      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      const loader = this.$loading.show();
      this.$store
        .dispatch("entryLaunchExternal/getReportExcel", {filter: { range : this.range}})
        .then(async (response) => {
          let blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "Relatório Notas não exportadas.xlsx");
          await link.click();
          this.$notify({
            type: "success",
            message: "Solicitação realizada com sucesso!",
          });
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: "Erro ao realizar o download do arquivo excel!",
          });
        }).finally(() => {
        loader.hide();
      });
    },
    setAll() {
      this.flag = this.value++;
      if (this.form.company_plant_uuids.includes('all')) {
        if (this.flag % 2 == 1) {
          this.form.company_plant_uuids = []
          for (let plant of this.$_plants) {
            this.form.company_plant_uuids.push(plant.uuid)
          }
        }
        if (this.flag % 2 == 0) {
          this.form.company_plant_uuids = []
          for (let plant of this.$_plants) {
            this.form.company_plant_uuids.pop()
          }
        }
      }
    },
    handleFilterDate(filter) {
      this.range = filter;
      this.init({});
    },
    getCompanyPlantBuyers() {
      this.loadingCompanyPlantBuyer = true;
      this.$store.dispatch("companyPlantBuyer/getByCompanyPlant", this.form.company_plant_id).then((response) => {
        this.company_plant_buyers = response.data || [];
        if (this.company_plant_buyers.length) {
          let default_company_plant_buyer =
            this.company_plant_buyers.filter((item) => item.default === 1)[0] || this.company_plant_buyers[0];
          this.form.company_plant_buyer_id = default_company_plant_buyer.id;
        } else {
          this.form.company_plant_buyer_id = null;
        }
        this.loadingCompanyPlantBuyer = false;
      });
    },
  },
};
</script>