<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-3">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-gray d-inline-block mb-0">{{ this.$route.meta.breadcrumbs[1].title }}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-2 text-gray">
            <ol class="breadcrumb mb-0">
              <li class="breadcrumb-item">
                <a href="#"><i :class="this.$route.meta.icon"></i></a>
              </li>
              <li class="breadcrumb-item ">
                <a href="#">{{ this.$route.meta.breadcrumbs[0].title }}</a>
              </li>
              <li class="breadcrumb-item" aria-current="page">
                {{ this.$route.meta.breadcrumbs[1].title }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <a
        @click.prevent="$router.push(`/operational/contract-proposal/schedule/create/${$route.params.contract_proposal_uuid}`)">
        <create-new :disabled="loading || [0, 5, 4].includes($_contract_proposal.status)" />
      </a>
      <float-button :contract_proposal="$_contract_proposal" />
    </base-header>
    <ListContractProposalSchedule />
    <ModalSearchContract ref="searchContract" />
  </div>
</template>

<script>
import ListContractProposalSchedule from "./Shared/_List";
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import BackButton from "@/components/Utils/BackButton";
import { mapGetters } from "vuex";
import CreateNew from "../../../Commercial/ContractProposal/components/FloatButton/CreateNew/Index.vue";
import FloatButton from "../../../Commercial/ContractProposal/components/FloatButton/Index.vue";

export default {
  name: "IndexContractProposalSchedule",
  components: {
    ListContractProposalSchedule,
    PuzlBreadcrumb,
    BackButton,
    CreateNew,
    FloatButton,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      $_contract_proposal: "contractProposal/show",
    }),
  },
  methods: {
    handleSearchContract() {
      this.$refs.searchContract.openModal();
    },
  },
  beforeMount() {
    this.loading = true
    this.$store.dispatch("contractProposal/show", this.$route.params.contract_proposal_uuid).then((response) => {
      this.loading = false;
    });
  },
};
</script>

<style scoped>
.main-menu {
  flex-wrap: nowrap !important;
  display: flex;
  justify-content: space-between;
}
</style>
