<template>
  <div>
    <modal size="lg" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div v-if="to_entity">
        <div class="pl-2 pr-2">
          <div class="border-left border-3 border-primary pr-1 mt-1">
            <div class="col-md-12 mb-4">
              <h4 class="m-0 font-weight-normal">
                De : <b>{{ `${$_from_entity.entity_name} | ${$_from_entity.document}` }} </b>
              </h4>
              <h4 class="m-0 font-weight-normal">
                Para : <b>{{ `${to_entity.entity_name} | ${to_entity.document}` }} </b>
              </h4>
            </div>
          </div>
          <form class="needs-validation" @submit.prevent="store" autocomplete="off">
            <div>
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pt-1 pb-0 col-form-label form-control-label"> Saldo disponível </label>
                <div class="col-md-6">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-money="money"
                      maxlength="13"
                      v-model.lazy="$_from_entity.anticipation_balance"
                      disabled
                      type="text"
                      class="form-control form-control-sm"
                    />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0"> R$ </small>
                    </template>
                  </base-input>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pt-1 pb-0 col-form-label form-control-label"> Valor a transferir </label>
                <div class="col-md-6">
                  <base-input input-group-classes="input-group-sm">
                    <input v-money="money" maxlength="13" v-model.lazy="item.value" type="text" class="form-control form-control-sm" />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0"> R$ </small>
                    </template>
                  </base-input>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pb-0 col-form-label form-control-label">
                  Observações
                  <span class="text-danger"></span>
                </label>
                <div class="col-md-6">
                  <base-input input-group-classes="input-group-sm">
                    <textarea type="text" maxlength="255" v-model="item.observations" rows="2" class="form-control form-control-sm" />
                  </base-input>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">Cancelar</base-button>
              <base-button type="success" native-type="submit" :disabled="item.value == '0,00'">Salvar</base-button>
            </div>
          </form>
        </div>
      </div>
    </modal>
    <ModalSearchEntity @selected="selectedEntity" ref="modalSearchEntity" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { VMoney } from "v-money";
import ModalSearchEntity from "./_ModalSearchEntity";

export default {
  name: "ModalAnticipationBalanceTransfer",
  components: { ModalSearchEntity },
  data() {
    return {
      modal: {
        title: "Transferência de saldo de antecipação",
        create: false,
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      to_entity: null,
      item: {
        to_entity_id: null,
        value: null,
        observations: null,
      },
    };
  },
  directives: { money: VMoney },
  computed: {
    ...mapGetters({
      $_from_entity: "entity/show",
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },

    /**
     * Inicia o modal
     *
     * @param {string} from_entity_uuid cliente de origem
     * @returns void
     */
    async openModal(from_entity_uuid) {
      this.item = {
        to_entity_id: null,
        value: null,
        observations: null,
      };
      await this.getFromEntity(from_entity_uuid);
      this.handleModalSearchEntity();
    },

    /**
     * Seta cliente alvo e abre modal principal
     *
     * @param {object} to_entity
     * @returns void
     */
    selectedEntity(to_entity) {
      this.to_entity = to_entity;
      if (this.to_entity.id === this.$_from_entity.id) {
        return this.$notify({
          type: "danger",
          message: "Não é permitida operação para o mesmo cliente/fornecedor!",
        });
      }
      this.item.to_entity_id = to_entity.id;
      this.item.value = this.$_from_entity.anticipation_balance.includes(",")
        ? Number(this.$helper.moneyToFloat(this.$_from_entity.anticipation_balance)).toFixed(2)
        : this.$_from_entity.anticipation_balance;
      this.modal.create = true;
    },

    /**
     * Busca cliente alvo
     *
     * @returns void
     */
    handleModalSearchEntity() {
      this.$refs.modalSearchEntity.openModal();
    },

    /**
     * Persiste os dados
     *
     * @returns void
     */
    async store() {
      const data = await this.finalFormat();
      if (!data) return;
      const loader = this.$loading.show();

      this.$store
        .dispatch("entityAnticipationBalanceTransfer/add", data)
        .then((response) => {
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.$emit("updatedAfterAnticipationBalanceTransfer", data.from_entity_id, data.to_entity_id);
          this.$emit("updated");
          this.closeModal();
        })
        .catch((error) => {
          if (error.status == 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
        })
        .finally(() => loader.hide());
    },

    /**
     * Busca cliente de origem
     *
     * @param {string} from_entity_uuid cliente de origem
     * @returns void
     */
    async getFromEntity(from_entity_uuid) {
      const loader = this.$loading.show();
      await this.$store.dispatch("entity/show", from_entity_uuid).then(() => loader.hide());
      return (this.item.from_entity_id = this.$_from_entity.id);
    },

    /**
     * Formata e valida os dados
     *
     * @returns {object|null}
     */
    finalFormat() {
      let data = this.$helper.cloneObject(this.item);
      const max_value = this.$helper.moneyToFloat(this.$_from_entity.anticipation_balance);
      data.value = this.$helper.moneyToFloat(data.value);
      if (data.value > max_value) {
        this.$notify({
          type: "danger",
          message: "O valor a transferir deve ser menor ou igual ao valor disponível!",
        });
        return null;
      }
      return data;
    },
  },
};
</script>
