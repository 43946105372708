import * as types from './mutation_types'
import { createAxios } from "@/plugins/axios";
const endPointState = '/state'
const endPointCity = '/city'
const endPointCountry = '/country'
export default {
  namespaced: true,
  state: {
    state_items: [],
    city_items: [],
    country_items: []
  },
  getters:{
    states: state => state.state_items,
    cities: state => state.city_items,
    countries: state => state.country_items,

  },
  mutations: {
    [types.SET_STATES] (state, payload){
      state.state_items = payload
    },
    [types.SET_CITIES] (state, payload){
      state.city_items = payload
    },
    [types.SET_COUNTRIES] (state, payload){
      state.country_items = payload
    },
  },
  actions: {
    fetchCountries({commit}) {
      return createAxios().get(endPointCountry)
        .then(({data}) =>{
          commit(types.SET_COUNTRIES, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchStates({commit, dispatch}, payload) {
      return createAxios().get(`${endPointState}`, payload)
        .then(({data}) =>{
          commit(types.SET_STATES, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchCities({commit, dispatch}, payload) {
      return createAxios().post(`${endPointCity}`, payload)
        .then(({data}) =>{
          commit(types.SET_CITIES, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
