<template>
  <div>
    <base-header class="bg-gray-content">
      <puzl-breadcrumb />
      <div class="row mb-3">
        <div class="col-md-2 mb-2">
          <BackButton />
        </div>
        <div class="col-md-2 mb-2">
          <base-button @click.prevent="handleModalCmcDivergentMargin" block size="lg" type="primary" class="text-uppercase">
            <i class="fas fa-dollar-sign" /> MARGEM
          </base-button>
        </div>
      </div>
    </base-header>
    <ListCmcDivergentPrice />
    <ModalCmcDivergentMargin ref="modalCmcDivergentMargin" />
  </div>
</template>

<script>
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import BackButton from "@/components/Utils/BackButton";
import ListCmcDivergentPrice from "./Shared/_List";
import ModalCmcDivergentMargin from "./Shared/_ModalMargin";

export default {
  name: "Index",
  components: { PuzlBreadcrumb, BackButton, ListCmcDivergentPrice, ModalCmcDivergentMargin },
  methods: {
    handleModalCmcDivergentMargin() {
      this.$refs.modalCmcDivergentMargin.openModal();
    },
  },
};
</script>

<style></style>
