<template>
  <div class="row card-wrapper px-1 mt-1 pt-1">
    <div class="col-lg-12 px-3" v-for="(bankReconciliationTransactionAssociation, index) in bankReconciliationTransactionAssociations" :key="index">
      <div class="card main-card mb-3">
        <div class="p-3">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-auto pr-0">
                <img src="/img/brand/logo.png" width="24">
              </div>
              <div class="col-auto pl-3">
                <h3 class="new-default-black-font m-0">
                  {{ bankReconciliationTransactionAssociation.entity_name ? bankReconciliationTransactionAssociation.entity_name : 'Sem interveniente' }}
                </h3>
                <h4 class="new-default-black-font m-0 font-weight-normal">
                  {{ bankReconciliationTransactionAssociation.entity_document }}
                </h4>
              </div>
              <div class="col text-right">
                <h4 class="new-default-gray-font m-0">
                  {{ bankReconciliationTransactionAssociation.finance_transaction_amount | currency }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Cards",
  components: {},
  props: {
    bankReconciliationTransactionAssociations: { type: Array }
  },
  data: {},
}
</script>
<style scoped>
h1, h2, h3, h4, h5, p {
	font-family: "Fredoka", sans-serif;
}

.card {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.07) !important;
  border-radius: 8px !important;
  border: 1px solid #ECECEC;
}

.new-cardwithbox-shadow {
  text-align: center;
  box-shadow: 0 5px 10px 0 #f3f3f3;
  display: flex;
  border: 1px solid #E8E8E8;
  border-radius: 5px !important;
  align-items: center;
  padding: 4px 0px;
}

.new-cardwithbox-shadow div {
  display: inline-flex;
  align-items: center;
}
</style>
