<template>
  <div>
    <modal :show.sync="modal" size="md">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div class="p-3">
        <div class="mb-5 pt-0">
          <label
            class="col-md-12 pt-1 pb-1 col-form-label form-control-label pl-0"
          >
            Tempo de Concretagem:
          </label>
          <!-- Tempo de Concretagem -->
          <div class="col-md-12 row">
            <small
              >Total : <strong>{{ formatTime() }}</strong></small
            >
          </div>
          <div v-if="travels[0]" class="col-md-12 row">
            <small
              >De : <strong>{{ travels[0].arrival_time }}</strong></small
            >
          </div>
          <div v-if="travels[0]" class="col-md-12 row mb-2">
            <small
              >Até :
              <strong>{{
                travels[this.travels.length - 1].construction_exit_time
              }}</strong></small
            >
          </div>
          <!-- Início de dados das centrais -->
          <div
            v-for="(plant, indexPlant) in companies"
            :key="indexPlant"
            class="mb-2"
          >
            <label
              class="col-md-4 pt-1 pb-1 col-form-label form-control-label pl-0"
            >
              {{ plant.label }}
            </label>
            <div class="col-md-12 row">
              <small>Caminhões necessários: <strong>0</strong></small>
            </div>
            <div class="col-md-12 row">
              <small>Ciclo médio de viagem: <strong>0</strong></small>
            </div>
            <div class="col-md-12 row">
              <small>Ciclo médio de concreto: <strong>0</strong></small>
            </div>
          </div>
        </div>
        <validation-observer v-slot="{ invalid }" ref="formValidator">
          <div
            class="timeline timeline-one-side mb-4"
            v-for="(travel, indexTravel) in travels"
            :key="indexTravel"
          >
            <div class="timeline-block">
              <span class="timeline-step badge-success">
                {{ indexTravel + 1 }}
              </span>
              <div class="timeline-content">
                <div
                  class="form-group row m-0 p-0 align-middle company-center h-100"
                >
                  <div class="col-md-6 pl-0 pr-0 my-auto">
                    <small class="text-muted font-weight-bold">
                      <strong>Central de carregamento: </strong>
                    </small>
                  </div>
                  <div class="col-md-6 pr-0 pl-0">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <el-select

                          v-if="travel.send_plant.uuid"
                          :value="travel.send_plant.uuid"
                          size="mini"
                          placeholder="Selecione"
                          filterable
                          class="edit-travel-input"
                        >
                          <el-option
                            :style="'font-size: 0.775rem!important;'"
                            v-for="plant in travels[0].company_plants"
                            :key="plant.uuid"
                            :label="plant.name"
                            :value="plant.uuid"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>

                <div
                  class="form-group row m-0 p-0 align-middle company-center pt-2 h-100"
                >
                  <div class="col-md-6 pl-0 pr-0 my-auto">
                    <small class="text-muted font-weight-bold">
                      <strong>Volume: </strong>
                    </small>
                  </div>
                  <div class="col-md-6 pr-0 pl-0 mb-0">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <input
                          :value="travel.volume"
                          type="text"
                          class="form-control form-control-sm edit-travel-input"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>

                <div
                  class="form-group row m-0 p-0 align-middle company-center pt-2 h-100"
                >
                  <div class="col-md-6 pl-0 pr-0 mb-2 my-auto">
                    <small class="text-muted font-weight-bold">
                      <strong>Central de retorno: </strong>
                    </small>
                  </div>
                  <div class="col-md-6 mb-0 pr-0 pl-0">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <el-select
                          :value="travel.return_plant.uuid"
                          size="mini"
                          placeholder="Selecione"
                          filterable
                          class="edit-travel-input"
                        >
                          <el-option
                            :style="'font-size: 0.775rem!important;'"
                            v-for="plant in travels[0].company_plants"
                            :key="plant.uuid"
                            :label="plant.name"
                            :value="plant.uuid"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div
                  class="form-group row m-0 p-0 align-middle company-center pt-2 pb-2 h-100"
                >
                  <div class="col-md-6 pl-0 pr-0 mb-2 my-auto">
                    <small class="text-muted font-weight-bold">
                      <strong>Carregamento: </strong>
                    </small>
                  </div>
                  <div class="col-md-6 mb-0 pr-0 pl-0">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <input
                          :value="travel.load_truck_time"
                          type="text"
                          class="form-control form-control-sm edit-travel-input"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div
                  class="form-group row m-0 p-0 align-middle company-center  pb-2 h-100"
                >
                  <div class="col-md-6 pl-0 pr-0 mb-2 my-auto">
                    <small class="text-muted font-weight-bold">
                      <strong>Chegada na obra: </strong>
                    </small>
                  </div>
                  <div class="col-md-6 mb-0 pr-0 pl-0">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <input
                          :value="travel.arrival_time"
                          type="text"
                          class="form-control form-control-sm edit-travel-input"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div
                  class="form-group row m-0 p-0 align-middle company-center  pb-2 h-100"
                >
                  <div class="col-md-6 pl-0 pr-0 mb-2 my-auto">
                    <small class="text-muted font-weight-bold">
                      <strong>Saída da obra:</strong>
                    </small>
                  </div>
                  <div class="col-md-6 mb-0 pr-0 pl-0">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <input
                          :value="travel.construction_exit_time"
                          type="text"
                          class="form-control form-control-sm edit-travel-input"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div
                  class="form-group row m-0 p-0 align-middle company-center  pb-2 h-100"
                >
                  <div class="col-md-6 pl-0 pr-0 mb-2 my-auto">
                    <small class="text-muted font-weight-bold">
                      <strong>Liberado p/ carregar:</strong>
                    </small>
                  </div>
                  <div class="col-md-6 mb-0 pr-0 pl-0">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <input
                          :value="travel.free_for_travel"
                          type="text"
                          class="form-control form-control-sm edit-travel-input"
                          size="sm"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center m-3 pt-3">
            <base-button type="secondary" @click="closeModal()"
              >Cancelar
            </base-button>
          </div>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { checkNumberValue } from "@/helpers";
import { mask1, formatErrorValidation, formatDate } from "@/plugins";

export default {
  components: {},
  name: "ModalEditTravelDistribution",
  data() {
    return {
      modal: false,
      title: "Visualizar Viagens",
      travels: [],
      distances: [],
      companies: [],
      times: []
    };
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    changeSendPlant(index, value) {
      let current_plant_uuid = this.travels[index].send_plant_uuid;
      let current_send_duration = this.distances.filter(
        item => item.company_plant_uuid === current_plant_uuid
      )[0].send_duration;
      let new_send_duration = this.distances.filter(
        item => item.company_plant_uuid === value
      )[0].send_duration;
      this.travels[index].send_plant_uuid = value;
      this.travels[index].load_truck_time = moment(
        formatDate(this.travels[index].load_truck_time)
      )
        .add(current_send_duration, "minute")
        .format("DD MMM YYYY HH:mm");
      this.travels[index].load_truck_time = moment(
        formatDate(this.travels[index].load_truck_time)
      )
        .subtract(new_send_duration, "minute")
        .format("DD MMM YYYY HH:mm");

      this.travels[index].max_volume = this.distances.filter(
        item => item.company_plant_uuid === this.travels[index].send_plant_uuid
      )[0].max_volume_per_travel;
      this.travels[index].volume = null;
    },
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        this.$forceUpdate();
        return checkNumberValue(
          mask1(value.toString(), decimals, ".", true),
          min,
          max
        );
      }
      return checkNumberValue(value, min, max);
    },
    changeReturnPlant(index, value) {
      let current_plant_uuid = this.travels[index].return_plant_uuid;
      let current_return_duration = this.distances.filter(
        item => item.company_plant_uuid === current_plant_uuid
      )[0].return_duration;
      let new_return_duration = this.distances.filter(
        item => item.company_plant_uuid === value
      )[0].return_duration;
      this.travels[index].return_plant_uuid = value;
      this.travels[index].free_for_travel = moment(
        formatDate(this.travels[index].free_for_travel)
      )
        .subtract(current_return_duration, "minute")
        .format("DD MMM YYYY HH:mm");
      this.travels[index].free_for_travel = moment(
        formatDate(this.travels[index].free_for_travel)
      )
        .add(new_return_duration, "minute")
        .format("DD MMM YYYY HH:mm");
    },
    formatTime() {
      let final_time = "";
      if (this.times.length > 0) {
        if (this.times[0]) {
          final_time =
            this.times[0] > 1
              ? this.times[0] + " dias, "
              : this.times[0] + " dia, ";
        }
        final_time +=
          this.times[1].substring(0, 2) != "01"
            ? this.times[1].substring(0, 2) + " horas e "
            : this.times[1].substring(0, 2) + " hora e ";
        final_time +=
          this.times[1].substring(3, 5) != "01"
            ? this.times[1].substring(3, 5) + " minutos"
            : this.times[1].substring(3, 5) + " minuto";
      }
      return final_time;
    },
    openModal(schedule_uuid) {
      this.$Progress.start();
      this.$store
        .dispatch("travels/fetchItems", {
          uuid: schedule_uuid,
          code: "1"
        })
        .then(response => {
          this.travels = response.data;
          this.times = this.getConcretingTime();
          this.modal = true;
           this.$Progress.finish();
        });
      return false;
    },
    updateTravels() {
      this.$emit("updatedTravels", this.travels);
      this.closeModal();
    },
    getConcretingTime() {
      let first_time = moment(formatDate(this.travels[0].arrival_time));
      let last_time = moment(
        formatDate(this.travels[this.travels.length - 1].construction_exit_time)
      );
      let minutes = moment.duration(last_time.diff(first_time)).asMinutes();
      let new_minutes =
        Math.floor((minutes % 1440) % 60).toString().length > 1
          ? Math.floor((minutes % 1440) % 60)
          : Math.floor((minutes % 1440) % 60).toString() + "0";
      let new_hours =
        Math.floor((minutes % 1440) / 60).toString().length > 1
          ? Math.floor((minutes % 1440) / 60)
          : "0" + Math.floor((minutes % 1440) / 60).toString();
      let time = new_hours + ":" + new_minutes + ":00";
      return [Math.floor(minutes / 1440), time];
    }
  },
  created() {
    moment.locale("pt-BR");
  }
};
</script>

<style>
.edit-travel-input,
.edit-travel-input .el-input__inner {
  font-size: 0.775rem !important;
}
@media (min-width: 768px) {
  .company-center {
    height: 24px;
  }
}
</style>
