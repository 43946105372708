export const period_types = (period, period_type, without_parentheses = false) => {
  let right_p = ')'
  let left_p = '('
  if (without_parentheses) {
    right_p = ''
    left_p = ''
  }
  switch(period_type){
    case 1:
      return period > 1 ? `${left_p}${period} Dias${right_p}` : `${left_p}${period} Dia${right_p}`
    case 2:
      return period > 1 ? `${left_p}${period} Horas${right_p}` : `${left_p}${period} Hora${right_p}`
    default:
      return ''
  }
}
export const value_z_to_percentage = (value_z, reliability_levels) => {
  let percentage=reliability_levels.filter(item => item.value_z === parseFloat(value_z))
  return percentage[0].reliability
}
export const nextHeader = (field) => {
  if(field){
    return '|'
  }
}
