<template>
  <div>
    <div class="container-fluid">
      <div class="row pt-0 card-row">
        <div class="col-md-12 p-1">
          <div class="card">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-md-3 pl-2 pr-2 pb-2">
                  <div style="margin-bottom: 0.7rem !important;" class="row m-0 pl-1">
                    <div class="form-group mb-0 d-flex align-items-center mr-3">
                      <small class="font-weight-bold mb-0 mr-2" for="search">Autorização</small>
                      <input 
                        @change="init()" 
                        v-model="filter_by_authorization_date" 
                        :value="true" 
                        style="padding-top: 0.1rem;box-shadow: none!important;"
                        type="radio"
                      />
                    </div>
                    <div class="form-group mb-0 d-flex align-items-center">
                      <small class="font-weight-bold mb-0 mr-2" for="search">Competência</small>
                      <input 
                        @change="init()" 
                        v-model="filter_by_authorization_date" 
                        :value="false" 
                        style="padding-top: 0.1rem;box-shadow: none!important;"
                        type="radio"
                      />
                    </div>
                  </div>
                  <input-date-picker
                    ref="date-picker"
                    :default-range="defaultRange"
                    :filterable="4"
                    size="small"
                    @handleFilterDate="handleFilterDate"
                  />
                </div>
                <div class="col-md-3 pl-2 pr-2 pb-2">
                  <label> Filtro de centrais </label>
                  <puzl-select
                    v-model="filter.company_plants"
                    :items="$_plants"
                    :loading="loadingCompanyPlants"
                    :multiple="true"
                    placeholder="Centrais"
                    @input="handleFilterCompanyPlants"
                  />
                </div>
                <div class="col-md-3 pl-2 pr-2 pb-2">
                  <label> Filtro de Estado</label>
                  <puzl-select
                    v-model="filter.state"
                    :items="$_states"
                    :loading="loadingStates"
                    :multiple="false"
                    customKey="name"
                    placeholder="Estado"
                    @input="handleFilterState"
                  />
                </div>
                <div class="col-md-3 pl-2 pr-2 pb-2">
                  <label> Filtro de Municipio</label>
                  <puzl-select
                    v-model="filter.city"
                    :items="$_cities"
                    :loading="loadingCities"
                    :multiple="false"
                    customKey="name"
                    placeholder="Município"
                    @input="handleFilterCity"
                  />
                </div>
              </div>

              <div class="row card-row mt-3">
                <div class="col-md-3 p-1">
                  <div class="card card-stats" style="border: 0.5px solid">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 text-center">
                          <h5 class="card-title text-uppercase mb-0 font-weight-700">Total</h5>
                        </div>
                        <div class="col-12 text-center">
                          <a 
                            href="#" 
                            class="pointer"
                            v-show="!loadingTotals"
                            @click.prevent="handleOpenModalIssResumeNfse('Autorizada')"
                          >
                            <i class="fa fa-circle-info text-primary mr-2"></i>
                            <span><strong class="text-primary">{{ $_totals.authorized.total | currency() }} ({{ $_totals.authorized.quantity }})</strong></span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 p-1">
                  <div class="card card-stats" style="border: 0.5px solid">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 text-center">
                          <h5 class="card-title text-uppercase mb-0 font-weight-700">ISS Devido</h5>
                        </div>
                        <div class="col-12 text-center">
                          <a 
                            href="#" 
                            class="pointer"
                            v-show="!loadingTotals"
                            @click.prevent="handleOpenModalIssResumeNfse('Autorizada', null, null, 0)"
                          >
                            <i class="fa fa-circle-info text-danger mr-2"></i>
                            <span><strong class="text-danger">{{ $_totals.iss_due.total | currency() }} ({{ $_totals.iss_due.quantity }})</strong></span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 p-1">
                  <div class="card card-stats" style="border: 0.5px solid">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 text-center">
                          <h5 class="card-title text-uppercase mb-0 font-weight-700">ISS Retido</h5>
                        </div>
                        <div class="col-12 text-center">
                          <a 
                            href="#" 
                            class="pointer"
                            v-show="!loadingTotals"
                            @click.prevent="handleOpenModalIssResumeNfse('Autorizada', null, null, 1)"
                          >
                            <i class="fa fa-circle-info text-success mr-2"></i>
                            <span><strong class="text-success">{{ $_totals.iss_retained.total | currency() }} ({{ $_totals.iss_retained.quantity }})</strong></span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 p-1">
                  <div class="card card-stats" style="border: 0.5px solid">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 text-center">
                          <h5 class="card-title text-uppercase mb-0 font-weight-700">Canceladas</h5>
                        </div>
                        <div class="col-12 text-center">
                          <a 
                            href="#" 
                            class="pointer"
                            v-show="!loadingTotals"
                            @click.prevent="handleOpenModalIssResumeNfse('Cancelada')"
                          >
                            <i class="fa fa-circle-info text-gray mr-2"></i>
                            <span><strong class="text-gray">{{ $_totals.canceled.total | currency() }} ({{ $_totals.canceled.quantity }})</strong></span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ISS Devido -->
      <div class="row pt-0 card-row">
        <div class="col-md-12 p-1">
          <div class="card">
            <div class="card-body mt-n2">
              <h4 class="text-danger">ISS Devido</h4>
              <div class="row">
                <div class="col-md-12 table-responsive">
                  <table class="table table-sm table-bordered">
                    <thead>
                    <tr class="fixed">
                      <th class="text-center">UF</th>
                      <th class="text-center">Município Obra</th>
                      <th class="text-center">ISS</th>
                      <th class="text-center">Alíquota</th>
                      <th class="text-center">Total</th>
                      <th class="text-center">Base Cálculo</th>
                      <th class="text-center">Dedução</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-if="loadingDue">
                      <tr v-for="(item, index) in 10" :key="index">
                        <th colspan="12">
                          <skeleton-puzl type="button"/>
                        </th>
                      </tr>
                    </template>

                    <template v-if="!loadingDue">
                      <tr v-for="item in $_due" v-show="!loadingRetained">
                        <td class="text-left"><span class="style-table">{{ item.state }}</span></td>
                        <td 
                          class="text-left pointer" 
                          @click.prevent="handleOpenModalIssResumeNfse('Autorizada', item.state, item.city, item.iss_retain)"
                        >
                          <i class="fa fa-circle-info text-danger strong mr-2"></i>
                          <span class="style-table">{{ item.city }}</span>
                        </td>
                        <td class="text-left numeric font-weight-bold" style="color: rgb(40, 44, 53)">
                          {{ item.iss_value || 0 | currency() }}
                        </td>
                        <td class="text-left numeric font-weight-bold">
                          {{
                            Number(item.iss_rate)
                              .toFixed(1)
                              .toString()
                              .replace(".", ",")
                          }}%
                        </td>
                        <td class="text-left numeric font-weight-bold">{{ item.total || 0 | currency() }} ({{item.quantity}})</td>
                        <td class="text-left numeric font-weight-bold">{{ item.calc_base || 0 | currency() }}</td>
                        <td class="text-left numeric font-weight-bold">{{
                            item.deductions_value || 0 | currency()
                          }}
                        </td>
                      </tr>
                    </template>
                    </tbody>
                  </table>
                </div>
              </div>
              <pagination
                :loading="loadingDue"
                :source="sourceDue"
                @navegate="navegateDue"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- ISS Retido -->
      <div class="row pt-0 card-row">
        <div class="col-md-12 p-1">
          <div class="card">
            <div class="card-body mt-n2">
              <h4 class="text-success">ISS Retido</h4>
              <div class="row">
                <div class="col-md-12 table-responsive">
                  <table class="table table-sm table-bordered">
                    <thead>
                    <tr class="fixed">
                      <th class="text-center">UF</th>
                      <th class="text-center">Município Obra</th>
                      <th class="text-center">ISS</th>
                      <th class="text-center">Alíquota</th>
                      <th class="text-center">Total</th>
                      <th class="text-center">Base Cálculo</th>
                      <th class="text-center">Dedução</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-if="loadingRetained">
                      <tr v-for="(item, index) in 10" :key="index">
                        <td colspan="12">
                          <skeleton-puzl type="button"/>
                        </td>
                      </tr>
                    </template>
                    <template v-if="!loadingRetained">
                      <tr v-for="item in $_retained" v-show="!loadingRetained">
                        <td class="text-left"><span class="style-table">{{ item.state }}</span></td>
                        <td 
                          class="text-left pointer"
                          @click.prevent="handleOpenModalIssResumeNfse('Autorizada', item.state, item.city, item.iss_retain)"
                        >
                          <i class="fa fa-circle-info text-success strong mr-2"></i>
                          <span class="style-table">{{ item.city }}</span>
                        </td>
                        <td class="text-left numeric font-weight-bold" style="color: rgb(40, 44, 53)">
                          {{ item.iss_value || 0 | currency() }}
                        </td>
                        <td class="text-left numeric font-weight-bold">
                          {{
                            Number(item.iss_rate)
                              .toFixed(1)
                              .toString()
                              .replace(".", ",")
                          }}%
                        </td>
                        <td class="text-left numeric font-weight-bold">{{ item.total || 0 | currency() }} ({{item.quantity}})</td>
                        <td class="text-left numeric font-weight-bold">{{ item.calc_base || 0 | currency() }}</td>
                        <td class="text-left numeric font-weight-bold">{{
                            item.deductions_value || 0 | currency()
                          }}
                        </td>
                      </tr>
                    </template>
                    </tbody>

                  </table>
                </div>
              </div>
              <pagination
                :loading="loadingRetained"
                :source="sourceRetained"
                @navegate="navegateRetained"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-iss-resume-nfse :filter_by_authorization_date="filter_by_authorization_date" ref="ModalIssResumeNfse"></modal-iss-resume-nfse>    
  </div>
</template>

<script>
import ModalIssResumeNfse from "../Shared/_ModalIssResumeNfse"
import MultiFilter from "@/components/Utils/MultiFilterV2";
import InputDatePicker from "@/components/InputDatePicker";
import Pagination from "@/components/Utils/Pagination";
import PuzlSelect from "@/components/PuzlSelect";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import SpinnerPuzl from "@/components/SpinnerPuzl";
import {mapGetters} from "vuex";
import moment from "moment/moment";

export default {
  name: "ListResume",
  components: {
    PuzlSelect,
    InputDatePicker,
    SkeletonPuzl,
    MultiFilter,
    Pagination,
    SpinnerPuzl,
    ModalIssResumeNfse,
  },
  data() {
    return {
      loadingSkeleton: false,
      loadingRetained: false,
      loadingDue: false,
      loadingTotals: false,
      loadingStates: true,
      loadingCities: true,
      loadingCompanyPlants: true,
      filter: {},
      selectedCompanyPlants: [],
      selectedCity: null,
      selectedState: null,
      sourceRetained: null,
      sourceDue: null,
      paramsDue: {
        page: 1,
        per_page: 15,
      },
      paramsRetained: {
        page: 1,
        per_page: 15,
      },
      range: {
        start: null,
        end: null,
      },
      defaultRange: {
        start: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
        end: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
      },
      filter_by_authorization_date:true,
    };
  },
  computed: {
    ...mapGetters({
      $_plants: "plant/activeItems",
      $_totals: "issResume/getTotals",
      $_retained: "issResume/getRetained",
      $_due: "issResume/getDue",
      $_states: "addressConstruction/getStates",
      $_cities: "addressConstruction/getCities"
    }),
  },
  mounted() {
    this.$store.dispatch("plant/fetchItemsActive").then(() => {
      this.loadingCompanyPlants = false;
    });
    this.$store.dispatch("addressConstruction/getStates").then(() => {
      this.loadingStates = false;
    });
    this.$store.dispatch("addressConstruction/getCities").then(() => {
      this.loadingCities = false;
    });

    this.range = this.defaultRange;
    this.init();
  },
  methods: {
    init() {
      this.filter.range = this.range;
      this.fetchRetained();
      this.fetchDue();
      this.fetchTotals();
    },
    handleOpenModalIssResumeNfse(nfseStatus, state = null, city = null, issRetained = null){
      this.$refs.ModalIssResumeNfse.handleCreateModal(
        nfseStatus,
        this.$helper.parseDate(this.filter.range.start, 'YYYY-MM-DD'),
        this.$helper.parseDate(this.filter.range.end, 'YYYY-MM-DD'),
        this.filter.company_plants,
        state,
        city,
        issRetained,
      );
    },
    /**
     * Chama todas as funções de fetch
     *
     * @param {object} filter
     */
    load(filter = {}) {
      this.paramsDue.page = 1;
      this.paramsRetained.page = 1;
      this.filter = filter;
      this.init();
    },
    handleFilterDate(filter) {
      this.range = {
        start: filter.start,
        end: filter.end,
      };
      this.filter.range = this.range;
      this.paramsDue.page = 1;
      this.paramsRetained.page = 1;
      this.init();
    },
    /**
     * Recebe os totais (menu superior)
     */
    fetchTotals() {
      this.loadingTotals = true;
      this.$store
        .dispatch("issResume/fetchTotals", {filter: this.filter,  filter_by_authorization_date: this.filter_by_authorization_date ? 1 : null})
        .then(() => {
          this.loadingTotals = false;
        });
    },
    /**
     * Atualiza a tabela de ISS retido
     */
    fetchRetained() {
      this.loadingRetained = true;
      this.$store
        .dispatch("issResume/fetchRetained", {
          filter: this.filter,
          page: this.paramsRetained.page,
          filter_by_authorization_date: this.filter_by_authorization_date ? 1 : null,
        })
        .then((response) => {
          this.sourceRetained = response;
          this.loadingRetained = false;
        })
        .catch((error) => {
          this.loadingRetained = false;
        });
    },
    /**
     * Atualiza a tabela de ISS Devido
     */
    fetchDue() {
      this.loadingDue = true;
      this.$store
        .dispatch("issResume/fetchDue", {
          filter: this.filter,
          page: this.paramsDue.page,
          filter_by_authorization_date: this.filter_by_authorization_date ? 1 : null,
        })
        .then((response) => {
          this.sourceDue = response;
          this.loadingDue = false;
        })
        .catch((error) => {
          this.loadingDue = false;
        });
    },
    /**
     * Lida com o filtro de Centrais
     *
     * @param {array} filter
     */
    handleFilterCompanyPlants(filter) {
      this.selectedCompanyPlants = filter;
      this.paramsDue.page = 1;
      this.paramsRetained.page = 1;
      this.init();
    },
    /**
     * Lida com o filtro de Cidade
     *
     * @param {string} filter
     */
    handleFilterCity(filter) {
      this.selectedCity = filter;
      this.paramsDue.page = 1;
      this.paramsRetained.page = 1;
      this.init();
    },
    /**
     * Lida com o filtro de Estado
     *
     * @param {string} filter
     */
    handleFilterState(filter) {
      this.selectedState = filter;
      this.paramsDue.page = 1;
      this.paramsRetained.page = 1;
      this.init();
    },
    navegateRetained(page) {
      this.paramsRetained.page = page;
      this.fetchRetained();
    },
    navegateDue(page) {
      this.paramsDue.page = page;
      this.fetchDue();
    }
  },
};
</script>

<style lang="scss" scoped>
.style-table {
  font-weight: 400;
  width: 15px;
  font-size: 12px;
}
</style>
