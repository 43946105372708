<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-gray d-inline-block mb-0">{{ $route.name }}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4 text-gray">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#"><i class="fa fa-home"></i></a>
              </li>
              <li class="breadcrumb-item"><a href="#">Cadastro</a></li>
              <li class="breadcrumb-item" aria-current="page">{{ $route.name }}</li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- Header Menus -->
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-2 col-xlg-2 mb-3">
              <base-button :disabled="loaders > 0" block size="lg" type="success" @click="handleCreate()">
                <i class="fas fa-plus"></i> NOVO
              </base-button>
            </div>
            <div class="col-md-2 pt-0">
              <BackButton/>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center pb-0 mb-3">
        <div class="col-md-12">
          <div class="checklist-item checklist-item-primary">
            <div class="checklist-info">
              <h1 class="checklist-title mb-0">{{ tribute.tax_name }}</h1>
              <small>{{ tribute.country }} - {{ tribute.state }} - {{ tribute.city }} - {{ tribute.regime }}</small>
            </div>
            <div></div>
          </div>
        </div>
      </div>
      <div class="row align-items-center pb-0">
        <div class="col-md-12">
          <div class="card p-2">
            <base-input placeholder="Pesquisar">
              <template slot="append">
                <i class="fas fa-search"></i>
              </template>
            </base-input>
          </div>
        </div>
      </div>
    </base-header>
    <modal-create @init="init" ref="ModalCreate" :loadingCities="loadingCities" />
    <list-deduction-rules @init="init" :source="deduction_rules" ref="ListDeductionRules" :loading="loaders" />
  </div>
</template>
<script>
import ListDeductionRules from "./Shared/_ListDeductionRules";
import ModalCreate from "./Shared/_ModalCreateValidity.vue";
import { mapGetters } from "vuex";
const { formatErrorValidation } = require("@/plugins");
import BackButton from '@/components/Utils/BackButton'
export default {
  components: {
    ModalCreate,
    ListDeductionRules,
    BackButton,
  },
  data() {
    return {
      loaders: 0,
      loadingCities: false,
      prevRoute: null,
    };
  },
  computed: {
    ...mapGetters({
      tribute: "TaxLocality/show",
      deduction_rules: "deductionRules/show",
    }),
  },
  mounted() {
    this.init();
    this.$watch("loaders", function (value) {
      if (value === 0) {
        this.$Progress.finish();
      }
    });
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from.path;
    });
  },
  methods: {
    handleCreate() {
      this.$refs.ModalCreate.handleCreateModal(this.tax);
    },
    init() {
      this.loaders = 1;
      this.$Progress.start();
      this.$store
        .dispatch("TaxLocality/show", this.$route.params.tribute_uuid)
        .then((response) => {
          this.loaders--;
        })
        .catch((error) => {
          this.loaders--;
        });
      this.$store
        .dispatch("deductionRules/show", this.$route.params.tribute_uuid)
        .then((response) => {
          this.loaders--;
        })
        .catch((error) => {
          this.loaders--;
        });
    },
  },
};
</script>
<style></style>
