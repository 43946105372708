<template>
  <div>
    <modal size="md-2" :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ title }}</h5>
      </template>
      <div class="container">
        <div class="row d-flex align-items-center m-0">
          <div class="col-1">
            <img src="/img/icons/icons8/folder-puzzle.png" width="25px" height="25px" />
          </div>
          <div class="col-3">
            <h4 class="m-0">MODELO</h4>
          </div>
          <hr class="bg-dark flex-grow-1 mr-3" />
        </div>
        <!-- Tipo de arquivo -->
        <div class="row d-flex align-items-center m-0 mt-n2">
          <div class="col-md-4">
            <label class="pb-0 mb-1 col-form-label new-default-black-font"> Tipo de arquivo<span class="text-danger"> *</span> </label>
          </div>
          <div class="col-md">
            <PuzlSelect :clearable="false" v-model="filter.file_type" :items="Object.values(file_type_enum)" :disabled="loading" />
          </div>
        </div>

        <!-- Agrupamento -->
        <div v-if="filter.file_type !== file_type_enum['EXCEL'].id" class="row d-flex align-items-center m-0">
          <div class="col-md-4">
            <label class="pb-0 mb-1 col-form-label new-default-black-font"> AGRUPAMENTO<span class="text-danger"> *</span> </label>
          </div>
          <div class="col-md">
            <PuzlSelect :clearable="false" v-model="filter.grouping_type" :items="grouping_type_enum" :disabled="loading" />
          </div>
        </div>

        <!-- Tipo de data -->
        <div class="row d-flex align-items-center m-0">
          <div class="col-md-4">
            <label class="pb-0 mb-1 col-form-label new-default-black-font"> Tipo de data <span class="text-danger"> *</span> </label>
          </div>
          <div class="col-md">
            <PuzlSelect :clearable="false" v-model="filter.date_type" :items="date_type_enum" :disabled="loading" />
          </div>
        </div>

        <!-- Filtros -->
        <div class="row d-flex align-items-center m-0">
          <div class="col-1">
            <img src="/img/icons/icons8/ios/filter.png" width="25" />
          </div>
          <div class="col-3">
            <h4 class="m-0">FILTROS</h4>
          </div>
          <hr class="bg-dark flex-grow-1 mr-3" />
        </div>
        <!-- Período -->
        <div class="row d-flex align-items-center m-0">
          <div class="col-md-4">
            <label class="new-default-black-font">Período</label>
          </div>
          <div class="col-md-8">
            <InputDatePicker
              :defaultRange="{
                start: new Date(),
                end: new Date(),
              }"
              v-model="filter.range"
              size="small"
            />
          </div>
        </div>
        <!-- Central -->
        <div class="row d-flex align-items-center m-0 mt-1">
          <div class="col-md-4">
            <label class="pb-0 mb-1 col-form-label new-default-black-font"> Central </label>
          </div>
          <div class="col-md pt-1">
            <base-input input-classes="form-control">
              <PuzlSelect multiple v-model="filter.company_plant_ids" :items="$_company_plants" :disabled="loading" />
            </base-input>
          </div>
        </div>
        <!-- Conta bancária -->
        <div v-if="filter.file_type !== file_type_enum['EXCEL'].id" class="row d-flex align-items-center m-0">
          <div class="col-md-4">
            <label class="pb-0 mb-1 col-form-label new-default-black-font"> Conta bancária </label>
          </div>
          <div class="col-md">
            <PuzlSelect v-model="filter.bank_account_ids" :disabled="loadingBankAccount" :items="$_bank_accounts" multiple />
          </div>
        </div>
        <!-- Método -->
        <div v-if="filter.file_type !== file_type_enum['EXCEL'].id" class="row d-flex align-items-center m-0">
          <div class="col-md-4">
            <label class="pb-0 mb-1 col-form-label new-default-black-font"> MÉTODO </label>
          </div>
          <div class="col-md">
            <base-input input-classes="form-control-sm">
              <PuzlSelect v-model="filter.payment_method_ids" :items="$_payment_methods" multiple :loading="loadingPaymentMethod" />
            </base-input>
          </div>
        </div>
        <!-- Status -->
        <div class="row d-flex align-items-center m-0">
          <div class="col-md-4">
            <label class="pb-0 mb-1 col-form-label new-default-black-font"> STATUS </label>
          </div>
          <div class="col-md">
            <base-input input-classes="form-control">
              <PuzlSelect multiple :items="installment_status_enum" v-model="filter.status" />
            </base-input>
          </div>
        </div>

        <div class="row d-flex align-items-center m-0">
          <div class="col-1">
            <img src="/img/icons/icons8/ios/contract-job-primary.png" width="25px" height="25px" />
          </div>
          <div class="col-3">
            <h4 class="m-0">FORNECEDOR</h4>
          </div>
          <hr class="bg-dark flex-grow-1 mr-3" />
        </div>
        <div class="row d-flex align-items-center m-0">
          <div class="col-md-4">
            <label class="pb-0 mb-1 col-form-label new-default-black-font"> Grupo Econômico </label>
          </div>
          <div class="col-md">
            <PuzlSelect :items="[]" />
          </div>
        </div>
        <div class="row d-flex align-items-center m-0">
          <div class="col-md-4">
            <label class="pb-0 mb-1 col-form-label new-default-black-font"> FORNECEDOR </label>
          </div>
          <div class="col-md">
            <PuzlSelectSearch
              @search="handleFilterEntity"
              :loading="loadingEntity"
              v-model="filter.entity_ids"
              clearable
              multiple
              :items="entities"
            >
            </PuzlSelectSearch>
          </div>
        </div>
      </div>

      <div class="row float-right mt-4 pr-3">
        <div class="col-md-12" style="display: flex; align-items: center">
          <base-button @click.prevent="close" type="secondary" class="text-capitalize"> Cancelar </base-button>
          <base-button @click.prevent="submitAndClose" type="primary" native-type="submit" :loading="loading">
            <span v-if="!loading" class="btn-label"><i class="fa-solid fa-cloud-arrow-down"></i></span>
            Exportar
          </base-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InputDatePicker from "@/components/InputDatePicker";
import PuzlSelect from "@/components/PuzlSelect";
import PuzlSelectSearch from "@/components/PuzlSelectSearch.vue";
import { debounce } from "@/plugins";

const grouping_type_enum = Object.freeze({
  ENTITY: {
    id: 1,
    name: "FORNECEDOR",
  },
  DAY: {
    id: 2,
    name: "DIA",
  },
  STATUS: {
    id: 3,
    name: "STATUS",
  },
});

const date_type_enum = Object.freeze({
  DUE_DATE_AND_PAID_AT: {
    id: 1,
    name: "PAGAMENTO/VENCIMENTO",
  },
  DUE_DATE: {
    id: 2,
    name: "VENCIMENTO",
  },
  ORIGINAL_DUE_DATE: {
    id: 3,
    name: "VENCIMENTO ORIGINAL",
  },
  PAID_AT: {
    id: 4,
    name: "PAGAMENTO",
  },
});

const installment_status_enum = Object.freeze({
  PENDING: {
    id: 0,
    name: "Pendente",
  },
  PAID: {
    id: 1,
    name: "Pago",
  },
  LATE: {
    id: 2,
    name: "Atrasado",
  },
  CANCELED: {
    id: 3,
    name: "Cancelado",
  },
  APPROVED: {
    id: 8,
    name: "Aprovado",
  },
  OTHERS: {
    id: 10,
    name: "Outros",
  },
});

const file_type_enum = Object.freeze({
  PDF:{
    id: 0,
    name: "PDF",
  },
  EXCEL :{
    id: 1,
    name: "EXCEL",
  },
});

export default {
  name: "ModalBillPayNewReport",
  components: {
    InputDatePicker,
    PuzlSelect,
    PuzlSelectSearch,
  },
  data() {
    return {
      title: "EXPORTAR RELATÓRIO DE CONTAS A PAGAR",
      modal: false,
      filter: {
        grouping_type: grouping_type_enum.ENTITY.id,
        date_type: date_type_enum.DUE_DATE_AND_PAID_AT.id,
        company_plant_ids: [],
        bank_account_ids: [],
        payment_method_ids: [],
        entity_ids: [],
        status: [],
        range: {
          start: new Date(),
          end: new Date(),
        },
        file_type: file_type_enum['PDF'].id // pdf,
      },
      loading: false,
      loadingBankAccount: true,
      loadingPaymentMethod: true,
      loadingEntity: false,
      grouping_type_enum: Object.values(grouping_type_enum),
      date_type_enum: Object.values(date_type_enum),
      installment_status_enum: Object.values(installment_status_enum),
      entities: [],
      file_type_enum,
    };
  },
  computed: {
    ...mapGetters({
      $_company_plants: "plant/activeItems",
      $_bank_accounts: "bankAccount/fetch",
      $_payment_methods: "paymentMethod/fetch",
    }),
  },
  methods: {
    openModal() {
      this.filter = {
        grouping_type: grouping_type_enum.ENTITY.id,
        date_type: date_type_enum.DUE_DATE_AND_PAID_AT.id,
        company_plant_ids: [],
        bank_account_ids: [],
        payment_method_ids: [],
        entity_ids: [],
        status: [],
        range: {
          start: new Date(),
          end: new Date(),
        },
        file_type: file_type_enum['PDF'].id, // pdf
      };
      this.entities = [];
      this.modal = true;
      this.load();
    },
    close() {
      this.modal = false;
    },
    submitAndClose() {
      if(this.filter.file_type === file_type_enum['PDF'].id){
        this.getPdf();
      } else {
        this.getExcel();
      }
    },

    getPdf() {
    const loader = this.$loading.show();
      this.$store.dispatch("billPay/getNewReport", this.filter).then(() => {
        loader.hide();
        this.close();
        this.$notify({
          type: "success",
          message: "Solicitação realizada com sucesso! O relatório será exportado em segundo plano.",
        });
      });
    },

    getExcel() {
      const loader = this.$loading.show();
      this.$store
        .dispatch("billPay/getBillPayExcelReport", this.filter)
        .then(async (response) => {
          const blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "Relatório de contas a pagar.xlsx");
          await link.click();
          this.close();
          this.$notify({
            type: "success",
            message: "Solicitação realizada com sucesso!",
          });
         }).finally(() => {
            loader.hide();
          });
    },

    load() {
      this.loadingBankAccount = true;
      this.$store.dispatch("bankAccount/fetchItems", {filter:{status:true}}).then(() => {
        this.loadingBankAccount = false;
      });
      this.loadingPaymentMethod = true;
      this.$store.dispatch("paymentMethod/fetchItems").then(() => {
        this.loadingPaymentMethod = false;
      });
    },
    handleFilterEntity: debounce(function (e) {
      if (!e) return;
      this.loadingEntity = true;
      this.$store
        .dispatch("entity/getSearch", {
          search: e,
        })
        .then((response) => {
          let merged = [...response.data, ...this.entities];
          merged = [...new Map(merged.map((item) => [item["id"], item])).values()];
          this.$set(this, "entities", merged);
          this.loadingEntity = false;
        });
    }, 300),
  },
};
</script>
