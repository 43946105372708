import * as types from './mutation_types'
import { createAxios } from "@/plugins/axios";
import * as storage from '@/plugins/storage'

const state = {
  automations: [],
}
const getters = {
  getAutomations: state => state.automations,
}
const mutations =  {
  [types.SET_ITEMS] (state, payload){
    state.automations = payload
  },
}
const actions = {
  fetch({commit}){
    return createAxios().get('/automation')
      .then(({data}) =>{
        commit(types.SET_ITEMS, data)
        return Promise.resolve(data)
      }).catch(error =>{
        return Promise.reject(error)
      })
  },
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
