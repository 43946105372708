export const SET = "SET";
export const SET_CHART_ACCOUNTS = "SET_CHART_ACCOUNTS";
export const SET_RESUME = "SET_RESUME";
export const PUSH = "PUSH";
export const SHOW = "SHOW";
export const DESTROY = "DESTROY";
export const UPDATE = "UPDATE";
export const GET_CODE = "GET_CODE";
export const SET_GROUPED = "SET_GROUPED";
export const SET_LAST_CODE = "SET_LAST_CODE";
export const SET_ORDERED_CODE = "SET_ORDERED_CODE";
