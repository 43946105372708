<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>

        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(save)"
            autocomplete="off"
          >
            <div>
              <div class="row mb-2">
                <div class="col-12">
                  <base-button
                    block
                    size="sm"
                    :type="description.type"
                    outline
                    class="mt-2 mb-2 text-uppercase"
                  >
                    {{description.name}}
                  </base-button>
                </div>
              </div>
              <div class="row">
                <div class="col-md-8">
                  <div class="border-left border-3 border-primary p-1">
                    Preço de venda
                  </div>
                </div>
                <div class="col-md-2">
                  {{ Number(params.price).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}) }}
                </div>
                <div class="col-md-8 mt-2">
                  <div class="border-left border-3 border-danger p-1">
                    Preço mínimo
                  </div>
                </div>
                <div class="col-md-2 mt-2">
                  {{ Number(params.minimum_price).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}) }}
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-12">
                  <label>Observações</label>
                  <textarea v-model="params.observation" maxlength="150" class="form-control" rows="3"
                            placeholder=""></textarea>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()"
              >Cancelar
              </base-button
              >
              <base-button
                type="success"
                native-type="submit"
                :disabled="invalid"
                :loading="loading"
              >Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  components: {},
  name: "ModalSituation",
  data() {
    return {
      modal: false,
      title: "Alterar situação",
      validated: false,
      loading: false,
      params: {},
      description: {
        name: '',
        type: ''
      }
    };
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    save() {
      this.loading = true
      this.$Progress.start();
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      });
      let params = {
        formulation_uuid: this.params.uuid,
        situation: this.params.type,
        observation: this.params.observation,
        volume: this.params.expected_volume,
        price: this.params.price,
        is_bigger: parseFloat(this.params.price) > parseFloat(this.params.minimum_price) ? 1 : 0
      }
        this.$store.dispatch('formulations/createSituation', params).then(response => {
        this.$Progress.finish();
        this.$notify({
          type: response.error_type,
          message: response.message
        })
        this.modal = false;
        this.loading = false
        let params = {
          situation : response.data,
          uuid: this.params.uuid
        }
        this.$emit('updateSituation', params)
      })
    },
    openModal(params, description) {
      this.params = params;
      this.params.observation = null
      this.description = description
      this.modal = true;
    },
  },
  created() {
  },
};
</script>

<style></style>
