<template>
  <div class="card-body mt-n2 p-0">
    <div class="row">
      <ShadedTable :length="$_productServices.length" :headers="headers" :loading="loadingSkeleton">
        <template v-for="(item, index) in $_productServices" :slot="index">
          <ShadedCol>
            <div class="text-center">
              <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
                <span :class="[item.status == true ? 'text-success' : 'text-danger']">
                  {{ item.status == true ? 'Ativo' : 'Inativo' }}
                </span>
              </h5>
            </div>
          </ShadedCol>
          <ShadedCol>
            <div>
              <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
                {{ item.type_name }}
              </h5>
            </div>
            <div>
              <base-button
                outline
                slot="reference"
                size="sm"
                type="secundary"
                @click.prevent="$emit('handleListPrices', item)"
                class="p-0 m-0 mr-2 shadow-none text-indigo"
              >
                <img src="/img/icons/icons8/ios/new-store_primary.png" width="20">
              </base-button>
              <el-popover 
                v-if="
                  item.product_service_hub_type_id !== ProductServiceHubTypeEnum.MCC &&
                  (item.ncm || item.cfop || item.cnae || item.observation)
                "
                trigger="click"
                placement="bottom"
              >
                <span style="width: 290px;"
                  class="row">
                  <div class="col-12 mb-2" style="display: flex; align-items: center;">
                    <img class="mr-2" src="/img/icons/icons8/ios/info-circle_warning.png" width="20">
                    <h4 style="font-size: 16px;" class="p-0 m-0">Detalhes</h4>
                  </div>
                  <div class="col-12 mt-1">
                    <h5 v-if="item.ncm" class="font-weight-normal m-0">
                      NCM: <b>{{item.ncm}}</b> 
                    </h5>
                    <h5 v-if="item.cfop" class="font-weight-normal m-0">
                      CFOP: <b>{{item.cfop}}</b>
                    </h5>
                    <h5 v-if="item.cnae" class="font-weight-normal m-0"> 
                      CNAE: <b>{{item.cnae}}</b>
                    </h5>
                    <h5 v-if="item.observation" class="font-weight-normal m-0"> 
                      {{ item.observation }}
                    </h5>
                  </div>  
                </span>
                <base-button 
                  outline slot="reference"
                  size="sm"
                  type="secundary"
                  class="p-0 m-0 mr-2 shadow-none text-indigo"
                >
                  <img src="/img/icons/icons8/ios/info-circle_warning.png" width="20">
                </base-button>
              </el-popover>
            </div>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.product_name }}
            </h5>
          </ShadedCol>
          <ShadedCol>
           <h5 
              class="new-default-black-font font-weight-normal m-0 mt-1"
              v-if="item.product_service_hub_type_id === ProductServiceHubTypeEnum.MCC"
            >
              {{ item.supplier_name }} ({{ item.origin_name }})
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.category_name }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <div class="text-center">
              <base-dropdown menuOnRight>
                <div slot="title-container" class="dropdown-toggle rounded m-0">
                  <img width="20" src="/img/icons/icons8/ios/settings--v1_primary.png" />
                </div>
                <a 
                  v-if="item.product_service_hub_type_id !== ProductServiceHubTypeEnum.MCC"
                  @click.prevent="$emit('handleEditProductService', item.hubable_id, item.id)"
                  class="dropdown-item text-dark"
                  style="align-items: center; display: flex">
                  <img width="22" src="/img/icons/edit.png" />
                  EDITAR
                </a>
                <div 
                  v-if="item.product_service_hub_type_id !== ProductServiceHubTypeEnum.MCC"
                  class="dropdown-divider p-0 m-0" />
                <a 
                  @click.prevent="$emit('handleSearchEntity', item)"
                  class="dropdown-item text-dark"
                  style="align-items: center; display: flex">
                  <img width="22" src="/img/icons/icons8/ios/place-marker--v1.png" />
                  Fornecedores
                </a>
                <div 
                  v-if="item.product_service_hub_type_id !== ProductServiceHubTypeEnum.MCC"
                  class="dropdown-divider p-0 m-0" />
                <a 
                  v-if="item.product_service_hub_type_id !== ProductServiceHubTypeEnum.MCC"
                  @click.prevent="$emit('handleDeleteProductService', item.hubable_id)"
                  class="dropdown-item text-dark"
                  style="align-items: center; display: flex"
                >
                  <img width="22" src="/img/icons/delete.png" />
                  EXCLUIR
                </a>
              </base-dropdown>
            </div>
          </ShadedCol>
        </template>
      </ShadedTable>
    </div>
  </div>
</template>

<script>
import SkeletonPuzl from "@/components/SkeletonPuzl";
import { mapGetters } from "vuex";
import ShadedTable from "@/components/ShadedTable.vue";
import ShadedCol from "@/components/ShadedCol.vue";
import {ProductServiceHubTypeEnum} from "@/enum/ProductServiceHubTypeEnum";

export default {
  name: "ListTableProductService",
  components: {
    SkeletonPuzl,
    ShadedTable,
    ShadedCol,
  },
  data() {
    return {
      headers: [
        "Status",
        "Tipo",
        "Descrição",
        "Fornecedor (origem)",
        "Categoria",
        "Ação",
      ],
      ProductServiceHubTypeEnum: ProductServiceHubTypeEnum,
    };
  },
  props: {
    loadingSkeleton: Boolean,
  },
  computed: {
    ...mapGetters({
      $_productServices: 'productService/fetch',
    }),
  },
};
</script>
