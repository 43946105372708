<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <form class="needs-validation p-2 mt-2" @submit.prevent="store" autocomplete="off">
        <div class="row m-0 mb-2">
          <label class="col-md-4 pl-2 pt-2 pb-0 col-form-label form-control-label">
            Abastecimento
            <span class="text-danger mr-2">&nbsp;*</span>
          </label>
          <div class="col-md-4 pl-2 pt-2">
            <base-button
              @click.prevent="payload.external = 0"
              type="primary"
              outline
              size="sm"
              block
              :class="{ active: payload.external === 0 }"
            >
              INTERNO
            </base-button>
          </div>
          <div class="col-md-4 pl-2 pt-2">
            <base-button
              @click.prevent="payload.external = 1;payload.own_fleet = 1"
              type="primary"
              outline
              size="sm"
              block
              :class="{ active: payload.external === 1 }"
            >
              EXTERNO
            </base-button>
          </div>
        </div>
        <div class="row m-0">
          <label class="col-md-4 pl-2 pt-2 pb-0 col-form-label form-control-label">
            Frota
            <span class="text-danger mr-2">&nbsp;*</span>
          </label>
          <div class="col-md-4 pl-2 pt-2">
            <base-button
              @click.prevent="payload.own_fleet = 1"
              type="primary"
              outline
              size="sm"
              block
              :class="{ active: payload.own_fleet === 1 }"
            >
              PRÓPRIA
            </base-button>
          </div>
          <div class="col-md-4 pl-2 pt-2">
            <base-button
              :disabled="payload.external === 1"
              @click.prevent="payload.own_fleet = 0"
              type="primary"
              outline
              size="sm"
              block
              :class="{ active: payload.own_fleet === 0 }"
            >
              TERCEIROS
            </base-button>
          </div>
        </div>
        <div class="modal-footer mt-2">
          <base-button type="secondary" @click="closeModal('create')"> Cancelar </base-button>
          <base-button type="success" native-type="submit" :disabled="this.payload.external === null || this.payload.own_fleet === null">
            Continuar
          </base-button>
        </div>
      </form>
    </modal>
    <ModalCreate ref="modalCreate" />
  </div>
</template>

<script>
import ModalCreate from "./_ModalCreate";
import { mapGetters } from "vuex";

export default {
  name: "ModalType",
  components: { ModalCreate },
  data() {
    return {
      modal: {
        title: "Controle de Abastecimento",
        create: false,
      },
      payload: {
        external: null,
        own_fleet: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      $_user: "auth/getUser",
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal() {
      if (this.$_user.is_driver === true) {
        this.payload = {
          external: 1,
          own_fleet: 1,
        };
      } else {
        this.payload = {
          external: 0,
          own_fleet: 1,
        };
      }
      this.modal.create = true;
    },
    store() {
      this.closeModal();
      this.$refs.modalCreate.openModal(this.payload);
    },
  },
};
</script>
