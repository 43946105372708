import { createAxios } from "@/plugins/axios";;
const endPoint = '/fiscal/fiscal-closing';

export default {
  namespaced: true,
  actions: {
    fetchItems({_}, params) {
      return createAxios().get(endPoint, {params: params})
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    /**
     * @param {Object} params
     * @param {string} params.year
     * @param {string} params.month
     * @param {FiscalClosingTypeEnum} params.type
     * @returns {Promise<Object>}
     */
    updateStatus({_}, params) {
      return createAxios().post(`${endPoint}/create/update`,  params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
  }
}
