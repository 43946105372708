import store from '@/store'
/** valida se existe a permissão **/
export const hasPermission = (key) => {
  const user = store.getters['auth/getUser']
  if (user.internal_type === 1) {
    return true
  }
 const find = user.permissions.find(item => item.name === key)
  if (find) {
    return true
  }
  if (find === undefined) {
    const has_base_permission = user.permissions.find(item => item.name.includes(key))
    if (has_base_permission) {
      return true
    }
  }
  return false
};
/** valida se existe alguma permissão para o módulo informado **/
export const hasAnyPermission = (key) => {
  const user = store.getters['auth/getUser']
  if (user.internal_type === 1) {
    return true
  }
  return user.permissions.find(item => item.name.split('.')[0].includes(key))
};

export function hasPrivilege(level = 1) {
  const user = store.getters['auth/getUser']
  return user.internal_type === level
}

/** Valida permissão operacional **/
export const hasOperationalPermission = (key) => {
  const user = store.getters['auth/getUser']
  if (user.internal_type === 1) {
    return true
  }

  const permission = user.operational_permissions ? user.operational_permissions[key] : false;
  return permission ? true : false;
};
