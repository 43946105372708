import store from "../../../../shared/libraries/store";
import { adjustmentTypeStoreStateKeys } from "./adjustmentTypeStoreStateKeys";
import {
  AdjustmentTypeListFilterType,
  AdjustmentTypeListPagerType,
  AdjustmentTypeCreateType,
  AdjustmentTypeViewType
} from "../types";
import { SearchBarFilterType } from "../../../../components/AppGlobal";
import { adjustmentTypeFormatEmptyAgentService } from "../services";

/**
 * @param {AdjustmentTypeViewType} item
 * @returns {AdjustmentTypeViewType}
 */
const setItem = (item) => store.commit(adjustmentTypeStoreStateKeys.ITEM, item);

/**
 * @param {AdjustmentTypeListPagerType} listed
 * @returns {AdjustmentTypeListPagerType}
 */
const setListed = (listed) =>
  store.commit(adjustmentTypeStoreStateKeys.LISTED, listed);

/**
 * @param {boolean} isListing
 * @returns {boolean}
 */
const setIsListing = (isListing) =>
  store.commit(adjustmentTypeStoreStateKeys.IS_LISTING, isListing);

/**
 * @param {boolean} isFinding
 * @returns {boolean}
 */
const setIsFinding = (isFinding) =>
  store.commit(adjustmentTypeStoreStateKeys.IS_FINDING, isFinding);

/**
 * @param {boolean} isSaving
 * @returns {boolean}
 */
const setIsSaving = (isSaving) =>
  store.commit(adjustmentTypeStoreStateKeys.IS_SAVING, isSaving);

/**
 * @param {boolean} isRemoving
 * @returns {boolean}
 */
const setIsRemoving = (isRemoving) =>
  store.commit(adjustmentTypeStoreStateKeys.IS_REMOVING, isRemoving);

/**
 * @param {number|string} id
 */
const setRemovedItem = (id) =>
  store.commitRemovedItem(adjustmentTypeStoreStateKeys, id);

/**
 * @param {AdjustmentTypeViewType} updated
 * @returns {AdjustmentTypeViewType}
 */
const setUpdatedItem = (updated) =>
  store.commitUpdatedItem(adjustmentTypeStoreStateKeys, updated);

/**
 * @param {AdjustmentTypeListPagerType} listed
 * @returns {AdjustmentTypeListPagerType}
 */
const setListMerge = (listed) =>
  store.commitListMerge(adjustmentTypeStoreStateKeys, listed);

/**
 * @param {AdjustmentTypeViewType} created
 * @returns {AdjustmentTypeViewType}
 */
const setNewItemInList = (created) => {
  if(created.agent === null) {
    created.agent = adjustmentTypeFormatEmptyAgentService.execute();
  }
  store.commitNewItemToList(adjustmentTypeStoreStateKeys, created);
}

/**
 * @param {{
 *   searchBarFilter: SearchBarFilterType,
 *   filter: AdjustmentTypeListFilterType
 * }} filters
 */
const setPageState = (filters) =>
  store.commit(adjustmentTypeStoreStateKeys.PAGE_STATES, filters);

/** Resetar chaves de estado */
const resetStates = () => {
  store.resetStates(Object.values(adjustmentTypeStoreStateKeys));
};

export default {
  setItem,
  setListed,
  setIsListing,
  setIsFinding,
  setIsSaving,
  setListMerge,
  setPageState,
  resetStates,
  setNewItemInList,
  setIsRemoving,
  setRemovedItem,
  setUpdatedItem,
};
