<template>
  <div>
    <modal :show.sync="modal.create" size="lg">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(store)"
            autocomplete="off"
          >
            <div>
              <!-- Central -->
              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-4 pb-0 mb-1 col-form-label form-control-label"
                >
                  Central
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 mb-1 pr-4">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <puzl-select
                        v-model="moisture.company_plant_uuid"
                        :items="plants"
                        customKey="uuid"
                        @input="getChargePoint(moisture.company_plant_uuid)"
                        :disabled="loading" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Ponto de carga -->
              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-4 pb-0 mb-1 col-form-label form-control-label"
                >
                  Ponto de carga
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 mb-1 pr-4">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <puzl-select
                        v-model="moisture.charge_point_uuid"
                        :items="chargePointItems"
                        customKey="uuid"
                        :disabled="!moisture.company_plant_uuid || loading" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Categoria -->
              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-4 pb-0 mb-1 col-form-label form-control-label"
                >
                  Categoria
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 mb-1 pr-4">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <puzl-select
                        v-model="moisture.cmc_category_uuid"
                        :items="filteredCategory"
                        customKey="uuid"
                        @input="getCmcAdjustments"
                        :disabled="
                          !moisture.charge_point_uuid ||
                            !moisture.company_plant_uuid ||
                            loading
                        " />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Ajuste mcc -->
              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-4 pb-0 mb-1 col-form-label form-control-label"
                >
                  Agregado
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 mb-1 pr-4">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <puzl-select
                        @change="setMaxHumidity()"
                        v-model="moisture.cmc_adjustment_uuid"
                        :items="cmcAdjustments"
                        customKey="uuid"
                        :labelMask="true"
                        label="product_name$ - $supplier_name$ ($origin_name$)"
                        :disabled="!moisture.cmc_category_uuid || loading" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Umidade -->
              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-4 pb-0 mb-1 col-form-label form-control-label"
                >
                  Umidade
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 mb-1 pr-4">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input-limit :start_value="moisture.moisture" :options="{
                                    min: 0,
                                    max: max_humidity,
                                    decimals: 1
                                  }" v-model="moisture.moisture" @value="moisture.moisture = $event">
                        <small>%</small>
                      </input-limit>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="modal-footer">
                <base-button type="secondary" @click="closeModal('create')">
                  Cancelar
                </base-button>
                <base-button
                  type="success"
                  native-type="submit"
                  v-bind:disabled="invalid"
                  :loading="loading"
                >
                  Salvar
                </base-button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { checkNumberValue } from "@/helpers";
import PuzlSelect from "@/components/PuzlSelect";
import { mask1, formatErrorValidation } from "@/plugins";
import InputLimit from "../../../../../components/Utils/InputLimit.vue";

export default {
  name: "ModalCreateMoisture",
  components: {InputLimit, PuzlSelect },
  data() {
    return {
      modal: {
        title: "Adicionar Umidade",
        create: false
      },
      moisture: {
        company_plant_uuid: null,
        charge_point_uuid: null,
        cmc_category_uuid: null,
        cmc_adjustment_uuid: null,
        moisture: null
      },
      max_humidity: 49.9,
      loading: false,
      selectedGroups: [],
      chargePointItems: [],
      correctionInput: true
    };
  },
  computed: {
    ...mapGetters({
      plants: "plant/fetch",
      chargePoints: "plantChargePoint/fetch",
      categories: "category/fetch",
      cmcAdjustments: "adjustmentCmc/fetch"
    }),
    filteredCategory() {
      return this.categories.filter((item) => item.id === 2 || item.id === 3);
    }
  },
  methods: {
    setMaxHumidity() {
      const cmc_adjustment = this.cmcAdjustments.find((item) => item.uuid === this.moisture.cmc_adjustment_uuid)
      if (cmc_adjustment.max_humidity > 0) {
        this.max_humidity = cmc_adjustment.max_humidity
      }
      else {
        this.max_humidity = 49.9
      }
      this.moisture.moisture = null
    },
    closeModal() {
      this.modal.create = false;
    },
    handleCreateModal(params = null) {
      this.$Progress.start();
      this.$store.dispatch("plant/fetchItems");
      this.$store.dispatch("category/fetchItems");
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.correctionInput = true;
      this.moisture = {
        company_plant_uuid: null,
        charge_point_uuid: null,
        moisture: null,
        cmc_category_uuid: null,
        cmc_adjustment_uuid: null
      };
      if (params) {
        this.moisture.company_plant_uuid = params.company_plant_uuid;
        this.getChargePoint(params.company_plant_uuid);
        this.moisture.charge_point_uuid = params.charge_point_uuid;
      }
      this.chargePointItems = [];
      this.$store
        .dispatch("moistureSetting/getByUser")
        .then(response => {
          this.moisture = {
            company_plant_uuid: response.data.company_plant.uuid,
            charge_point_uuid: response.data.charge_point.uuid
          };
          this.$store
            .dispatch(
              "plantChargePoint/getByPlant",
              this.moisture.company_plant_uuid
            )
            .then(response => {
              this.chargePointItems = response.data;
              this.$Progress.finish();
              this.$notify({
                type: response.error_type,
                message: response.message
              });
              this.modal.create = true;
              this.$Progress.finish();
            });
        })
        .catch(error => {
          this.modal.create = true;
          this.$Progress.finish();
        });
    },
    getChargePoint(uuid) {
      if (this.moisture.company_plant_uuid) {
        this.loading = true;
        this.moisture.cmc_category_uuid = null;
        this.moisture.cmc_adjustment_uuid = null;
        this.moisture.charge_point_uuid = null;
        this.$store
          .dispatch("plantChargePoint/getByPlant", uuid)
          .then(response => {
            this.chargePointItems = response.data;
            this.loading = false;
          });
      } else {
        this.loading = false;
        this.moisture.cmc_category_uuid = null;
        this.moisture.cmc_adjustment_uuid = null;
        this.moisture.charge_point_uuid = null;
      }
    },
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        value = mask1(
          checkNumberValue(value, min, max).toString(),
          decimals,
          ".",
          true
        );
        return checkNumberValue(value, min, max);
      }
      return checkNumberValue(value, min, max);
    },
    getCmcAdjustments() {
      this.loading = true;
      this.moisture.cmc_adjustment_uuid = null;
      this.$store.dispatch(
        "adjustmentCmc/getCmcAdjustmentByCategoryAndChargePoint",
        {
          cmc_category_uuid : this.moisture.cmc_category_uuid,
          charge_point_uuid : this.moisture.charge_point_uuid
          }
      ).then(response => {
        this.loading = false;
      });
    },
    store() {
      this.$Progress.start();
      this.loading = true;
      this.moisture.date = moment().format("YYYY-MM-DD HH:mm:ss");
      this.$store
        .dispatch("moisture/add", this.moisture)
        .then(response => {
          this.moisture.cmc_adjustment_uuid = null;
          this.moisture.moisture = null;
          this.loading = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
        })
        .catch(error => {
          if (error.status === 200) {
            this.$notify({
              type: "danger",
              message: error.data.message
            });
            this.$Progress.finish();
            this.loading = false;
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors
            });
            this.$Progress.finish();
            this.loading = false;
          }
        });
    }
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
};
</script>

<style scoped></style>
