import { adjustmentHistoryUrls } from ".";
import httpClient from "../../../../shared/libraries/httpClient";
import { handleUnexpectedStatusCode } from "../../../../shared/services";
import { HttpStatusEnum } from "../../../../shared/enums";
import { AdjustmentHistoryCompanyPlantsByIdType } from "../types";

/**
 * Listar Centrais vinculadas no histórico
 *
 * @param {number} id
 * @returns {Promise<AdjustmentHistoryCompanyPlantsByIdType>}
 */
const execute = async (id) => {
  const url = `${adjustmentHistoryUrls.BASE}/company-plants-by-id/${id}`;
  const res = await httpClient.get(url);

  switch (res.status) {
    case HttpStatusEnum.HTTP_OK:
      return res.data.data;
    default:
      handleUnexpectedStatusCode(res);
  }
};

export default { execute };