<template>
  <div class="scrolling-box" style="margin-top: -2%">
    <h1 v-show="show" class="text-center">
      <img width="70px" class="text-center" :src="imgPath()">
    </h1>
    <a v-if="pageYOffset > 150 && showOffset" @click.prevent="scrollTop" href="javascript:void(0)" class="float-scroll float-responsive"
       style="z-index: 2;background: transparent;border-radius: 30px;border: 1px solid #a19393;bottom: 30px !important;"><i
      class="fa-solid fa-chevron-up text-grey2" style="font-size: 20px;margin-top: 15px;"></i></a>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    showOffset: {
      type: Boolean,
      default: true
    }
  },
  name: "LoadingPagination",
  data() {
    return {
      pageYOffset: 0,
    }
  },
  beforeMount() {
    window.addEventListener("scroll", this.setPageYOffset);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", {});
  },
  methods: {
    // add altura da tela
    setPageYOffset() {
      this.pageYOffset = window.pageYOffset
    },
    imgPath() {
      return "https://static.wixstatic.com/media/c6abac_72b300c91c794879a85fc3f8fd46ed63~mv2.gif"
    },
    // sobe a tela até o topo
    scrollTop() {
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
  },
}
</script>

<style>
.float {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 70px;
  right: 40px;
  background-color: #1a70b7;
  color: #FFF;
  border-radius: 10%;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
}

.float-scroll {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 70px;
  background-color: #1a70b7;
  color: #FFF;
  border-radius: 10%;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
}

.float:hover > i {
  color: #FFF;
}
</style>
