<template>
  <div>
    <modal size="md" :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div class="row" v-if="invoice">
        <div class="col-md-12">
          <h4 class="mt-n2">
            <b>Cliente: </b> <span>{{ invoice.entity.entity_name }}</span>
          </h4>
          <h4 class="mt-n2">
            <b>Endereço da obra: </b> <span>
            {{ invoice.contract_proposal.construction.default_address.address}}, <span>n°</span>
            {{invoice.contract_proposal.construction.default_address.number}}<template v-if="invoice.contract_proposal.construction.default_address.complement"> - {{invoice.contract_proposal.construction.default_address.complement}}</template>, {{invoice.contract_proposal.construction.default_address.district}}, CEP: {{invoice.contract_proposal.construction.default_address.postal_code}},
             {{invoice.contract_proposal.construction.default_address.city}}/{{invoice.contract_proposal.construction.default_address.state}},
          </span>
          </h4>
          <div style="border-top: 1px solid #1A70B7;margin-bottom: 10px"/>
          <h5>
            <b class="info">Concreto:</b> <span class="info font-weight-400">{{ invoice.formulation_value | currency() }}</span>
          </h5>
          <h5>
            <b class="info" >MCC:</b> <span class="info font-weight-400">{{ invoice.mcc_value | currency() }}</span>
          </h5>
          <h5>
            <b class="info">MCC Teórico:</b> <span class="info font-weight-400">{{ invoice.mcc_theorical_value | currency() }}</span>
          </h5>
          <h5>
            <b class="info">Serviços:</b> <span class="info font-weight-400">{{ invoice.service_value | currency() }}</span>
          </h5>
          <h5>
            <b class="info">Adicionais:</b> <span class="info font-weight-400">{{ invoice.additional_value | currency() }}</span>
          </h5>
          <h5>
            <b class="info">Avulso:</b> <span class="info font-weight-400">{{ invoice.single_value | currency() }}</span>
          </h5>
          <h5>
            <b class="info">Cancelados:</b> <span class="info font-weight-400">{{ invoice.canceled | currency() }}</span>
          </h5>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import MultiFilter from "@/components/Utils/MultiFilterV2";
import InputDatePicker from "@/components/InputDatePicker";
import SkeletonPuzl from '@/components/SkeletonPuzl'
import PuzlEmptyData from "@/components/PuzlEmptyData";
import PuzlSelect from "@/components/PuzlSelect";
import moment from 'moment'

export default {
  name: "ModalAllotments",
  components: {
  },
  data() {
    return {
      modal: false,
      title: 'Informações',
      invoice: undefined
    };
  },
  methods: {
    handleCreateModal(invoice)
    {
      this.invoice = invoice
      this.modal = true
    },
    close() {
      this.modal = false
    },
  },
};
</script>

<style scoped>
.info {
  font-size: 12px !important;
}
</style>
