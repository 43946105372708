<template>
  <div>
    <modal size="md" :show.sync="modal.show">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div class="row">
        <div class="col-12">
          <h4 v-if="selected.contract_proposal_code">Contrato: <span class="font-weight-normal">{{ selected.contract_proposal_code }}</span></h4>
          <h4 class="mt-n2">Cliente: <span class="font-weight-normal">{{ selected.entity_name }}</span></h4>
          <h4 class="mt-n2">CPF / CNPJ: <span class="font-weight-normal">{{ selected.document }}</span></h4>
          <h4 class="mt-n2" v-if="selected.construction_name">Nome da obra: <span class="font-weight-normal">{{ selected.construction_name }}</span></h4>
          <h4 class="mt-n2">Central: <span class="font-weight-normal">{{ selected.company_plant }} ({{selected.company_plant_issuer}})</span></h4>
          <h4 class="mt-n2" v-if="selected.user_is_seller">
            Vendedor: <span class="font-weight-normal">{{selected.identified_user_name}}</span>
          </h4>
          <h4 class="mt-n2" v-if="selected.observations">
            Observações: <span class="font-weight-normal">{{selected.observations}}</span>
          </h4>
          <div style="border-top: 1px solid #1A70B7;"/>
        </div>
      </div>
      <div class="row mt-2" v-if="selected.invoices && selected.invoices.length">
        <div class="col-12">
          <h3 class="text-danger">DADOS DA FATURA</h3>
          <h4>Fatura: <span class="font-weight-normal">{{ selected.invoices[0].id }}</span></h4>
          <h4 class="mt-n2">Valor total: <span class="font-weight-normal">{{ selected.invoices[0].total | currency()}}</span></h4>
          <h4 class="mt-n2">Forma de pagamento: <span class="font-weight-normal">{{ selected.payment_term_name }}</span></h4>
          <h4 class="mt-n2">Método de pagamento: <span class="font-weight-normal">{{  selected.payment_method_name }}</span></h4>
          <h4 class="mt-n2">Competência: <span class="font-weight-normal">{{ selected.invoices[0].competence_at | parseDate('DD/MM/YYYY')}}</span></h4>
          <div style="border-top: 1px solid #1A70B7;"/>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <h3 class="text-primary">DADOS DA PARCELA</h3>
          <h4>Data de vencimento: <span class="font-weight-normal">{{ selected.due_date | parseDate('DD/MM/YYYY')}}</span></h4>
          <h4 v-if="selected.paid_at" class="mt-n2">Data de recebimento: <span class="font-weight-normal">{{selected.paid_at | parseDate('DD/MM/YYYY')}}</span></h4>
          <h4 v-if="!selected.contract_proposal_code" class="mt-n2">Forma de pagamento: <span class="font-weight-normal">{{ selected.payment_term_name }}</span></h4>
          <h4 v-if="!selected.contract_proposal_code" class="mt-n2">Método de pagamento: <span class="font-weight-normal">{{  selected.payment_method_name }}</span></h4>
          <h4 class="mt-n2">Valor: <span class="font-weight-normal">{{ selected.value | currency() }}</span></h4>
          <h4 class="mt-n2">Desconto: <span class="font-weight-normal">{{(selected.discounts || 0) | currency()}}</span></h4>
          <h4 class="mt-n2">Taxas: <span class="font-weight-normal">{{(selected.discounts_fees || 0) | currency()}}</span></h4>
          <h4 class="mt-n2">Juros/Multa: <span class="font-weight-normal">{{(selected.fees_fines || 0) | currency()}}</span></h4>
          <h4 class="mt-n2">Valor final: <span class="font-weight-normal">{{selected.total_value | currency()}}</span></h4>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: "ModalInfo",
  components: {
  },
  data() {
    return {
      modal: {
        title: "Informações",
        show: false,
      },
      selected: null
    };
  },
  methods: {
    handleOpenModal(item) {
      this.selected = this.$helper.cloneObject(item)
      this.modal.show = true
    }
  }
};
</script>

<style scoped>
label {
  font-size: 12px !important;
}

.bill-modal-create {
  overflow-y: scroll !important;
}

@media (min-width: 768px) {
  .limited-row {
    max-height: 28px !important;
  }
}
</style>
