<template>
  <div class="container-fluid">
    <div class="row card-wrapper" v-show="loading">
      <SkeletonPuzlGrid v-for="(skeletons, index) in 3" :key="index"/>
    </div>
    <div v-if="production && production.total_volume && !loading" class="row">
      <div class="col-md-4">
        <div style="height: 280px" class="card bg-gradient-primary border-0">
          <div class="card-body">
            <div class="row">
              <div class="col"><h2 class="card-title  text-muted mb-0 text-white"> Produção </h2>
                <br>
                <span class="h2 font-weight-bold mb-0 text-white">{{ production.total_volume }} m<sup>3</sup></span>
                <br>
                <span class="h4 font-weight-bold mb-0 text-white">{{ production.total_travels }} {{ production.total_travels === 1 ? "viagem" : "viagens" }}</span>
              </div>
            </div>
            <p class="mt-3 mb-0 text-sm"><a href="#!" class="text-nowrap text-white font-weight-600">{{ production.average_volume }} m<sup>3</sup>/viagem</a></p>
            <p class="mt-3 mb-0 text-sm text-center"><a href="#!" class="text-nowrap text-white font-weight-600">
              {{ production.canceled_volume }} m<sup>3</sup> ({{ production.canceled_travels }})
              <br>
              Cancelamento
            </a></p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div v-show="loadingService" class="card">
          <SkeletonPuzlFullWidth/>
        </div>
          <div v-if="service && !loadingService" style="height: 280px" class="card bg-gradient-dark border-0">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <base-dropdown menuOnRight>
                    <base-button
                      :loading="loadingPayloadService"
                      style="font-size: 1.0625rem"
                      size="md"
                      type="secondary"
                      slot="title-container"
                      class="active dropdown-toggle bg-transparent text-white border-0 pl-0 pt-0"
                    >
                      Serviços
                    </base-button>
                    <!-- Desabilitado temporariamente -->
                    <!-- <div @click.prevent="handlePayloadService(service.service_name)" v-for="(service, index) in services"
                         :key="index">
                      <a class="dropdown-item"> {{ service.service_name }} </a>
                    </div> -->
                  </base-dropdown>
                  <br>
                  <h2 style="margin-top: 15px" class="h2 font-weight-bold mb-0 text-white">
                    {{ service.performed_volume }} m<sup>3</sup>
                  </h2>
                  <span class="h4 font-weight-bold mb-0 text-white">
                    {{ service.total_services }} {{ service.total_services === 1 ? "Serviço" : "Serviços" }}
                 </span>
                </div>
              </div>
              <p class="mt-3 mb-0 text-sm"><a href="#!" class="text-nowrap text-white font-weight-600">{{ service.performed_cycle }} {{ service.performed_cycle === 1 ? "hr" : "hrs" }}</a></p>
              <p class="mt-3 mb-0 text-sm text-center"><a href="#!" class="text-nowrap text-white font-weight-600">
                {{ service.canceled_volume }} m<sup>3</sup> ({{ service.canceled_services }})
                <br>
                Cancelamento
              </a></p>
            </div>
          </div>
        </div>
      <div class="col-md-4">
        <div style="height: 280px" class="card bg-gradient-indigo border-0">
          <div class="card-body">
            <div class="row">
              <div class="col"><h2 class="card-title  text-muted mb-0 text-white"> Ciclo médio </h2>
                <br>
                <span class="h2 font-weight-bold mb-0 text-white">{{ cycle.total_travel_cycle === "0s" ? "0" : cycle.total_travel_cycle }} <small>(viagem)</small></span>
               <br>
                <span class="h4 font-weight-bold mb-0 text-white">{{cycle.total_concrete_cycle === "0s" ? "0" : cycle.total_concrete_cycle }} <small>(concreto)</small></span>
              </div>
            </div>
            <p class="mt-3 mb-0 text-sm"><a href="#!" class="text-nowrap text-white font-weight-600">{{ cycle.idleness }} (ociosidade)</a></p>
            <p class="mt-3 mb-0 text-sm text-center"><a href="#!" class="text-nowrap text-white font-weight-600">
              {{ cycle.total_constructions }} {{ cycle.total_constructions === 1 ? "Obra" : "Obras" }}
            </a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import SkeletonPuzlFullWidth from "@/components/SkeletonPuzlFullWidth";

export default {
  name: "DashboardCards",
  components: {
    SkeletonPuzlGrid,
    SkeletonPuzlFullWidth
  },
  computed: {
    ...mapGetters({
      loading: "operationalDashboard/loading",
      production: "operationalDashboard/production",
      cycle: "operationalDashboard/cycle",
      service: "operationalDashboard/service",
      loadingService: "operationalDashboard/loadingService",
      services: "service/activeItems"
    })
  },
  data() {
    return {
      loadingPayloadService: true,
      currentService: null
    };
  },
  methods: {
    handlePayloadService(val) {
      this.currentService = val;
      this.$emit("fetchServices", val);
    }
  },
  mounted() {
    this.$store.dispatch("service/fetchServices").then(() => {
      this.loadingPayloadService = false;
    });
  }
};
</script>

<style scoped>
.dashboard-card h3,
h1,
h5 {
  color: white;
}

.bg-teal {
  background-color: #3c8ccf !important;
}

.card-inside {
  margin-top: -1rem !important;
}

.bg-light {
  background-color: #373f48 !important;
}

.bg-danger {
  background-color: #e94545 !important;
}

.bg-gradient-info {
  background: linear-gradient(87deg, #308af7 0, #0052bf 100%) !important;
}

.bg-gradient-danger {
  background: linear-gradient(87deg, #f74c3e 0, #cf0909 100%) !important;
}

.bg-gradient-dark {
  background: linear-gradient(
    87deg, #2f3336 0, #0c0c10 100%) !important;
}

.bg-gradient-indigo-alternative {
  background: linear-gradient(
    87deg, #9430fd 0, #a904bb 100%) !important;
}
</style>
>
