<template>
  <div>
    <base-header class="bg-gray-content pr-1 pl-1">
      <div class="row">
        <PuzlBreadcrumb />
      </div>

      <div class="mb-5 row">
        <div class="col-md-3 mb-2 pl-0">
          <base-button @click.prevent="handleModalCreateBankTransfer" class="pb-3 pt-3 text-uppercase" block size="lg" type="success">
            <i class="fas fa-right-left"></i> Transferir
          </base-button>
        </div>
        <div class="col-md-3 mb-2">
          <base-button
            @click.prevent="handleModalReport"
            block
            size="lg"
            type="info"
            class="text-uppercase pb-3 pt-3"
          >
            <i class="fa-solid fa-chalkboard"></i> Imprimir
          </base-button>
        </div>
      </div>
    </base-header>
    <ListAccountStatements ref="list" />
    <ModalCreateBankTransfer @created="$refs.list.clearFilters()" ref="modalCreateBankTransfer" />
    <ModalReport ref="modalReport" />
  </div>
</template>

<script>
import ListAccountStatements from "./Shared/_List";
import ModalReport from "./Shared/_ModalReport";
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import ModalCreateBankTransfer from "@/views/Modules/Financial/BankTransfer/Shared/_ModalCreate";
export default {
  name: "Index",
  components: {
    ListAccountStatements,
    PuzlBreadcrumb,
    ModalCreateBankTransfer,
    ModalReport,
  },
  methods: {
    handleModalCreateBankTransfer() {
      this.$refs.modalCreateBankTransfer.handleCreateModal();
    },
    handleModalReport() {
      this.$refs.modalReport.openModal();
    }
  },
};
</script>

<style></style>
