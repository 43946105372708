<template>
  <div>
    <base-header class="bg-gray-content">
      <!-- Header Title -->
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-gray d-inline-block mb-0">
            {{ $route.meta.title }}
          </h6>
          <nav
            aria-label="breadcrumb"
            class="d-none d-md-inline-block ml-md-4 text-gray"
          >
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#"><i class="fa fa-home"></i></a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">Cadastro</a>
              </li>
              <li class="breadcrumb-item" aria-current="page">
                {{ $route.meta.title }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- Header Menus -->
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-2 mb-3">
              <base-button
                block
                size="lg"
                type="success"
                @click.prevent="showModalCreate = true"
              >
                <i class="fas fa-plus"></i> NOVO
              </base-button>
            </div>
            <div class="col-md-2 mb-2">
              <BackButton />
            </div>
          </div>
        </div>
      </div>
    </base-header>
    <modal-create
      @close="showModalCreate = false"
      :show="showModalCreate"
    />
    <List />
  </div>
</template>

<script>
import BackButton from "@/components/Utils/BackButton.vue";
import ModalCreate from "./Shared/_Create.vue"
import List from "./Shared/_List.vue";

export default {
  name: "EntityCategoryIndex",
  components: {
    BackButton,
    ModalCreate,
    List
  },

  data() {
    return {
      filter: {},
      showModalCreate: false,
    }
  },
};
</script>

<style lang="scss" scoped></style>
