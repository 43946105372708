import store from "../../../../shared/libraries/store";
import { SapConfigurationSyncType, initSapConfigurationSyncType } from "../types";
import { sapConfigurationStoreStateKeys } from "./sapConfigurationStoreStateKeys";

/** @returns {?SapConfigurationSyncType} */
const getItem = () => store.getState(sapConfigurationStoreStateKeys.ITEM) ?? initSapConfigurationSyncType();

export default {
  getItem,
};
