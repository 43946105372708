export const TaxRegimeEnum = Object.freeze({
  /** Lucro Real */
  ACTUAL_PROFIT: 0,
  /** Lucro Presumido */
  PRESUMED_PROFIT: 1,
  /** Simples Nacional */
  NATIONAL_SIMPLE: 2,
  /** Microempreendedor Individual */
  MEI: 3,
});
/**
 * Função para obter a descrição com base no número
 *
 * @param {number} taxRegimeEnum
 * @returns {string} Descrição do enum
 */
export const getTitleByTaxRegimeEnum = (taxRegimeEnum) => {
  const titles = {
    [TaxRegimeEnum.ACTUAL_PROFIT]: 'Lucro Real',
    [TaxRegimeEnum.PRESUMED_PROFIT]: 'Lucro Presumido',
    [TaxRegimeEnum.NATIONAL_SIMPLE]: 'Simples Nacional',
    [TaxRegimeEnum.MEI]: 'Microempreendedor Individual',
  };

  return titles[taxRegimeEnum] || '';
}

/**
 * Função para obter o número com base na descrição
 * @param {string} taxRegimeTitle
 * @returns {number}
 */
export const getTaxRegimeEnumByTitle = (taxRegimeTitle) => {
  const titles = {
    'Lucro Real': TaxRegimeEnum.ACTUAL_PROFIT,
    'Lucro Presumido': TaxRegimeEnum.PRESUMED_PROFIT,
    'Simples Nacional': TaxRegimeEnum.NATIONAL_SIMPLE,
    'Microempreendedor Individual': TaxRegimeEnum.MEI,
  };
  return titles[taxRegimeTitle] || 0;
}

/**
 * Função para obter um array de objetos com os campos value e label para os regimes de tributação
 * @returns {Array<{value: number, label: string}>}
 */
export const getTaxRegimeTitles = () => {
  return Object.values(TaxRegimeEnum).map(value => ({
    value,
    label: getTitleByTaxRegimeEnum(value),
  }));
};
