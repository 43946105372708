/**
 * @typedef {Object} EntryLaunchItemTaxType
 * @property {?number} id
 * @property {number} line_number
 * @property {?string} cfop
 * @property {?string} ncm
 * @property {?string} cnae
 * @property {?string} icms_value
 * @property {?string} icms_with_replacement_value
 * @property {?string} icms_exonerated_value
 * @property {?string} ipi_value
 * @property {?string} ipi_returned_value
 * @property {?string} pis_value
 * @property {?string} cofins_value
 * @property {?string} issqn_value
 * @property {?string} import_value
 * @property {?string} fcp_with_replacement_value
 * @property {?string} freight_value
 * @property {?string} insurance_value
 * @property {?string} other_value
 * @property {?string} service_value
 * @property {?string} discount_value
 * @property {?number} entry_launch_item_id
 * @property {number} composes_nfe_value // compõem valor da nfe, 0 = não, 1 = sim
 */
export const EntryLaunchItemTaxType = {};

/**
 * @returns {EntryLaunchItemTaxType}
 */
export const initEntryLaunchItemTaxType = () => {
  return {
    id: null,
    line_number: 0,
    cfop: null,
    ncm: null,
    cnae: null,
    icms_value: null,
    icms_with_replacement_value: null,
    icms_exonerated_value: null,
    ipi_value: null,
    ipi_returned_value: null,
    pis_value: null,
    cofins_value: null,
    issqn_value: null,
    import_value: null,
    fcp_with_replacement_value: null,
    freight_value: null,
    insurance_value: null,
    other_value: null,
    service_value: null,
    discount_value: null,
    entry_launch_item_id: null,
    composes_nfe_value: 1, // compõem valor da nfe, 0 = não, 1 = sim
  };
};
