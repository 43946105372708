import * as types from "./mutation_types";
import { createAxios } from "@/plugins/axios";;
const endPoint = "/configuration/entity-anticipation-balance-history";
export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    fetch: (state) => state.items,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload;
    },
  },
  actions: {
    fetch({ commit }, params) {
      return createAxios()
        .get(`${endPoint}/${params.id}`, { params: params })
        .then(({ data }) => {
          commit(types.SET, data.data.items);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    recalculate({}, id) {
      return createAxios()
        .get(`${endPoint}/recalculate/${id}`)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    copyAnticipationBalanceTestToAnticipationBalance({}, id) {
      return createAxios()
        .get(`${endPoint}/copy/${id}`)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getExcelReport({}, id) {
      return createAxios().get(`${endPoint}/excel/${id}`, {
        responseType: "blob",
        timeout: 60000
      })
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  },
};
