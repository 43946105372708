import operationSourceRoute from "../../modules/fiscal/operation-source/routes/operationSourceRoute";

import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
//import OperationSource from '@/views/Modules/Fiscal/OperationSource/Index';
import IssResume from "@/views/Modules/Fiscal/IssResume/Index";
import AccountingChartAccount from "@/views/Modules/Fiscal/AccountingChartAccount/Index";
import auth from "@/middleware/auth";
import Dashboard from "@/views/Modules/Fiscal/Dashboard/Index";
import ChartAccountResume from "@/views/Modules/Configuration/ChartAccount/Resume/Index.vue";

const fiscal = {
  path: "/fiscal",
  component: DashboardLayout,
  name: "Fiscal",
  meta: {
    middleware: auth,
  },
  children: [
    {
      path: "/fiscal/dashboard",
      name: "fiscal.dashboard.index.view",
      component: Dashboard,
      meta: {
        title: "Dashboard",
        description: "Dashboard fiscal",
        icon: "fa fa-home",
        permission: "fiscal.dashboard.index.view",
        breadcrumbs: [
          { title: "Dashboard", name: "fiscal.dashboard.index" },
          { title: "Dashboard", name: null },
        ],
      },
    },
    ...operationSourceRoute,
    {
      path: "/fiscal/iss-resume",
      name: "fiscal.iss-resume.view",
      component: IssResume,
      meta: {
        title: "Fiscal",
        description: "Resumo ISS",
        icon: "fa fa-home",
        permission: "fiscal.iss-resume.index.view",
        breadcrumbs: [
          { title: "Dashboard", name: "fiscal.iss-resume.dashboard.index" },
          { title: "Resumo ISS", name: null },
        ],
      },
    },
    {
      path: "/fiscal/accounting-chart-account",
      name: "fiscal.accounting-chart-account.view",
      component: AccountingChartAccount,
      meta: {
        title: "Fiscal",
        description: "Plano Contábil",
        icon: "fa fa-home",
        permission: "fiscal.accounting-chart-account.index.view",
        breadcrumbs: [
          {
            title: "Dashboard",
            name: "fiscal.accounting-chart-account.dashboard.index",
          },
          { title: "Plano Contábil ", name: null },
        ],
      },
    },
    {
      path: "/fiscal/chart-account",
      name: "fiscal.chart-account.index.view",
      component: ChartAccountResume,
      meta: {
        title: "Fiscal",
        description: "Plano Gerencial",
        icon: "fa fa-home",
        permission: "fiscal.chart-account.index.view",
        breadcrumbs: [
          {
            title: "Dashboard",
            name: "fiscal.chart-account.index.view",
          },
          { title: "Plano Gerencial ", name: null },
        ],
      },
    },
  ],
};

export default fiscal;
