import Dashboard from '@/views/Dashboard/Dashboard.vue';
import DashboardLayout from '@/views/Layout/DashboardLayout.vue';

const dashboard = {
  path: '/',
  component: DashboardLayout,
  redirect: '/dashboard',
  name: 'Dashboard layout',
  children: [
    {
      path: 'dashboard',
      name: 'Dashboard',
      component: Dashboard
    },
  ]
};

export default dashboard;
