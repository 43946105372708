<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center pt-4 pb-2">
        <div class="col-md-3">
          <h2 style="font-size: 1.2rem !important" class="text-danger mb-0 d-flex align-items-center mb-2 mb-md-0">
            <img height="30px" src="/img/icons/login-rounded.png" class="mr-3" />
            Entradas e Custos
          </h2>
        </div>

        <!-- Botões -->
        <div class="col-md-9">
          <div class="row d-flex justify-content-end">
            <div class="col-md-3 mb-2">
              <base-button @click.prevent="handleSelectType" block type="success" class="text-uppercase">
                <img height="19" src="/img/icons/plus-math--v1-white.png" class="mr-1 mt-n1" /> Novo
              </base-button>
            </div>
            <div class="col-md-3 mb-2">
              <base-button @click.prevent="handleCreateEntity" block type="primary" class="text-uppercase">
                <img height="19" src="/img/icons/icons8/ios/new-store-white.png" class="mr-1 mt-n1" /> Fornecedor
              </base-button>
            </div>
            <div class="col-md-3 mb-2">
              <base-button @click.prevent="openModalReportEntryNotes" block type="dark" class="text-uppercase">
                <img height="19" src="/img/icons/icons8/ios/graph-report.png" alt="graph-report" class="mr-1" />
                Relatório
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid">
      <!-- Tipo de visualização -->
      <div class="mb-2 pl-1 pt-4 pt-md-0">
        <div style="display: flex; align-items: center">
          <i
            :class="listType !== 'cards' && 'opacity-25'"
            @click.prevent="listType = 'cards'"
            class="pointer fa-regular fa-hard-drive fa-2x"
          ></i>
          <i
            :class="listType !== 'table' && 'opacity-25'"
            @click.prevent="listType = 'table'"
            class="pointer fa-solid fa-table-list fa-2x ml-1"
          ></i>
        </div>
      </div>

      <!-- Filtros -->
      <multi-filter ref="multi-filter" :type="1" @handleFilterDate="handleFilterDate" @fetch="init" :filter="filter" :default-range="range">
        <template slot="dates">
          <div class="col-md-12 text-left">
            <label class="form-control-label"> Entrada </label>
            <input-date-picker :default-range="range" @handleFilterDate="handleFilterDate($event, 'entry_date')" visibility="focus" />
          </div>
          <div class="col-md-12 text-left mt-3">
            <label class="form-control-label"> Lançamento </label>
            <input-date-picker :default-range="range" @handleFilterDate="handleFilterDate($event, 'imported_at')" visibility="focus" />
          </div>
        </template>
        <template slot="data">
          <div class="col-12 text-left">
            <label class="form-control-label">Tipo do item </label>
            <puzl-select
              class="select-xl new-default-black-font"
              v-model="filter.status"
              :items="product_service_hub_types"
              :loading="loadingSkeleton"
              @input="init({})"
            />
          </div>
          <div class="row pr-3 mt-3 pl-3">
            <div class="col-12 text-left">
              <label class="form-control-label"> Número do documento </label>
              <div class="row">
                <div class="col-6 text-left pr-1">
                  <input placeholder="de" v-model="filter.document_range.start" @change="init({})" class="form-control btn-first-child" />
                </div>
                <div class="col-6 pl-1">
                  <input v-model="filter.document_range.end" placeholder="até" @change="init({})" class="form-control btn-first-child" />
                </div>
              </div>
            </div>
          </div>
          <div class="row pr-3 mt-1 pl-3">
            <div class="col-12 text-left">
              <label class="form-control-label"> Valor do lançamento </label>
              <div class="row">
                <div class="col-6 text-left pr-1">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">R$</span>
                    </div>
                    <input v-model="filter.value_range.start" @input="init({})" placeholder="de" class="form-control btn-first-child" />
                  </div>
                </div>
                <div class="col-6 pl-1">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">R$</span>
                    </div>
                    <input v-model="filter.value_range.end" @input="init({})" placeholder="até" class="form-control btn-first-child" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row m-0 mt-2 w-100">
            <label class="form-control-label col-12 text-left">Chave de acesso </label>
            <div class="col-12">
              <input v-model="filter.access_key" @blur="init({})" placeholder="Chave de Acesso" class="form-control w-100" />
            </div>
          </div>
        </template>
        <template slot="status">
          <div class="col-12">
            <base-button-hoverable
              @click="handleFilterAdvancedStatus(1)"
              :active="filter.advanced === 1"
              size="sm"
              :disabled="loadingSkeleton"
              type="success"
              icon="check-dollar"
              platform="ios"
            >
              Pago
            </base-button-hoverable>
          </div>
          <div class="col-12 mt-1">
            <base-button-hoverable
              @click="handleFilterAdvancedStatus(2)"
              :disabled="loadingSkeleton"
              :active="filter.advanced === 2"
              size="sm"
              type="warning"
              icon="hourglass"
              platform="ios"
            >
              Pendente + Atrasado
            </base-button-hoverable>
          </div>
          <div class="col-12 mt-1">
            <base-button-hoverable
              :active="filter.advanced === 7"
              @click="handleFilterAdvancedStatus(7)"
              size="sm"
              type="default"
              icon="duration-finance"
              platform="ios"
              :disabled="loadingSkeleton"
            >
              Sem financeiro
            </base-button-hoverable>
          </div>

          <div class="col-12 mt-1">
            <base-button-hoverable
              :active="filter.advanced === 3"
              @click="handleFilterAdvancedStatus(3)"
              size="sm"
              type="danger"
              icon="duration-finance"
              platform="ios"
              :disabled="loadingSkeleton"
            >
              Sem cap
            </base-button-hoverable>
          </div>
          <div class="col-12 mt-1">
            <base-button-hoverable
              :active="filter.advanced === 5"
              @click="handleFilterAdvancedStatus(5)"
              size="sm"
              type="default"
              icon="help--v1"
              platform="ios"
              :disabled="loadingSkeleton"
            >
              Verificar
            </base-button-hoverable>
          </div>
        </template>
        <template slot="bookmarks">
          <div class="col-12 mt-1">
            <base-button-hoverable
              :disabled="loadingSkeleton"
              size="sm"
              type="primary"
              icon="in-transit"
              platform="ios"
              :active="filter.advanced === 4"
              @click="handleFilterAdvancedStatus(4)"
            >
              Frete
            </base-button-hoverable>
          </div>
          <div class="col-12 mt-1">
            <base-button-hoverable
              :disabled="loadingSkeleton"
              size="sm"
              type="danger"
              icon="in-transit"
              platform="ios"
              :active="filter.advanced === 6"
              @click="handleFilterAdvancedStatus(6)"
            >
              Sem frete
            </base-button-hoverable>
          </div>
          <div class="col-12 mt-1">
            <base-button-hoverable
              :disabled="loadingSkeleton"
              size="sm"
              type="warning"
              icon="break--v2"
              platform="ios"
              :active="filter.has_cmc_without_mix_reference"
              @click="handleFilterCmcWithoutMixReference"
            >
              Mcc s/ ref. comercial
            </base-button-hoverable>
          </div>
          <div class="col-12 mt-1">
            <base-button-hoverable
              :disabled="loadingSkeleton"
              size="sm"
              type="warning"
              icon="break--v2"
              platform="ios"
              :active="filter.has_cmc_adjustment_without_dosage_location"
              @click="handleFilterCmcWithoutAdjustment"
            >
              Mcc s/ p.carga
            </base-button-hoverable>
          </div>
          <div class="col-12 mt-1">
            <BaseButtonHoverable
              :disabled="loadingSkeleton"
              size="sm"
              type="success"
              icon="working-mom"
              platform="ios"
              :active="filter.advanced === 8"
              @click="handleFilterAdvancedStatus(8)"
            >
              Nota mãe s/ saldo
            </BaseButtonHoverable>
          </div>
          <div class="col-12 mt-1">
            <BaseButtonHoverable
              :disabled="loadingSkeleton"
              size="sm"
              type="warning"
              icon="working-mom"
              platform="ios"
              :active="filter.advanced === 9"
              @click="handleFilterAdvancedStatus(9)"
            >
              Nota mãe c/ saldo
            </BaseButtonHoverable>
          </div>
          <div class="col-12 mt-1">
            <BaseButtonHoverable
              :disabled="loadingSkeleton"
              size="sm"
              type="warning"
              icon="women-age-group-3"
              platform="ios"
              :active="filter.advanced === 10"
              @click="handleFilterAdvancedStatus(10)"
            >
              Nota filha s/ mãe
            </BaseButtonHoverable>
          </div>
          <div class="col-12 mt-1 mb-6">
            <BaseButtonHoverable
              :disabled="loadingSkeleton"
              size="sm"
              type="success"
              icon="women-age-group-2"
              platform="ios"
              :active="filter.advanced === 11"
              @click="handleFilterAdvancedStatus(11)"
            >
              Nota filha c/ mãe
            </BaseButtonHoverable>
          </div>
        </template>
        <template slot="order-by">
          <el-popover trigger="click" placement="bottom" class="p-0 float-right pr-0 ml-3">
            <div class="card" style="margin: -14px">
              <div
                :class="filter.order_by === 'total_value_desc' && 'text-primary'"
                class="pointer px-3 py-2 new-default-black-font font-weight-500"
                style="font-size: 11px"
                @click.prevent="handleFilterOrderBy('total_value_desc')"
              >
                <i class="fa-solid fa-angles-down" style="font-size: 12px; margin-right: 5px"></i>
                VALOR: DO MAIOR PARA O MENOR
              </div>
              <hr class="gray-divider mt-1 mb-1" />
              <div
                :class="filter.order_by === 'total_value_asc' && 'text-primary'"
                class="pointer px-3 py-2 new-default-black-font font-weight-500"
                style="font-size: 11px"
                @click.prevent="handleFilterOrderBy('total_value_asc')"
              >
                <i class="fa-solid fa-angles-up" style="font-size: 12px; margin-right: 5px"></i>
                VALOR: DO MENOR PARA O MAIOR
              </div>
            </div>
            <a href="#" slot="reference">
              <h5 slot="reference" class="pt-1">ORDENAR <i class="fa-solid fa-chevron-down"></i></h5>
            </a>
          </el-popover>
        </template>
      </multi-filter>
    </div>

    <ModalEntryLaunchType @selected="selectedType" ref="modalEntryLaunchType" />
    <Table
      v-show="listType === 'table'"
      :loadingSkeleton="loadingSkeleton"
      :getColor="getColor"
      :getText="getText"
      :downloadDanfe="downloadDanfe"
      :handleModalAttachment="handleModalAttachment"
      :handleModalListEntryLaunchItems="handleModalListEntryLaunchItems"
      :handleDeleteEntryLaunch="handleDeleteEntryLaunch"
      :billPayGenerator="billPayGenerator"
      :handleModalEntryCompanyPlantTransfer="handleModalEntryCompanyPlantTransfer"
      :handleModalFreight="handleModalFreight"
      :handleEntryLaunchToFreight="handleEntryLaunchToFreight"
      :getDanfe="getDanfe"
      :handleModalCreateByAccessKey="handleModalCreateByAccessKey"
      :handleModalCreateByEntity="handleModalCreateByEntity"
      :getParentOrChildrenIcon="getParentOrChildrenIcon"
      :handleModalGetFreightToReassociate="handleModalGetFreightToReassociate"
    />
    <Cards
      v-show="listType === 'cards'"
      :loadingSkeleton="loadingSkeleton"
      :getColor="getColor"
      :getText="getText"
      :downloadDanfe="downloadDanfe"
      :handleModalAttachment="handleModalAttachment"
      :handleModalListEntryLaunchItems="handleModalListEntryLaunchItems"
      :handleDeleteEntryLaunch="handleDeleteEntryLaunch"
      :billPayGenerator="billPayGenerator"
      :handleModalEntryCompanyPlantTransfer="handleModalEntryCompanyPlantTransfer"
      :handleModalFreight="handleModalFreight"
      :handleEntryLaunchToFreight="handleEntryLaunchToFreight"
      :handleModalCreateByAccessKey="handleModalCreateByAccessKey"
      :handleModalCreateByEntity="handleModalCreateByEntity"
      :getDanfe="getDanfe"
      :handleModalSearchChildren="handleModalSearchChildren"
      :getParentOrChildrenIcon="getParentOrChildrenIcon"
      :handleModalGetFreightToReassociate="handleModalGetFreightToReassociate"
    />

    <ModalSearchEntity @selected="selectedEntity" ref="modalSearchEntity" />
    <ModalCreateEntity ref="modalCreateEntity" :openFromEntryLaunch="true" />
    <ModalInitialFilterSefaz ref="modalInitialFilterSefaz" />
    <ModalCreateByAccessKey ref="modalCreateByAccessKey" />
    <ModalCreateByEntity ref="modalCreateByEntity" />
    <ModalAttachmentView ref="modalAttachmentView" />
    <ModalListItems ref="modalListItems" />
    <ModalEntryCompanyPlantTransfer @transfered="init({})" ref="modalEntryCompanyPlantTransfer" />
    <ModalSearchEntryLaunch @selected="init({})" ref="modalSearchEntryLaunch" />
    <ModalFreight ref="modalFreight" />
    <ModalSearchChildren ref="modalSearchChildren" />
    <ModalGetFreightToReassociate @selected="init({})" ref="modalGetFreightToReassociate" />
    <ModalReportEntryNotes ref="modalReportEntryNotes" />
    <ModalCreateByXmlAttachment @onSave="init({})" ref="modalCreateByXmlAttachment" />
  </div>
</template>

<script>
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import ModalEntryLaunchType from "./Shared/_ModalType";
import ModalSearchEntity from "../Entity/Shared/_ModalSearchEntity";
import ModalCreateEntity from "../Entity/Shared/_Create";
import ModalInitialFilterSefaz from "./External/_ModalInitialFilterSefaz";
import ModalCreateByAccessKey from "./Shared/_ModalCreateByAccessKey";
import ModalCreateByEntity from "./Shared/_ModalCreateByEntity";
import ModalAttachmentView from "./Shared/_ModalAttachmentView";
import ModalListItems from "./Item/_ModalList.vue";
import ModalEntryCompanyPlantTransfer from "./Shared/_ModalEntryCompanyPlantTransfer";
import Cards from "./Shared/_Cards";
import Table from "./Shared/_Table";
import { base_url_ms } from "@/plugins";
import MultiFilter from "@/components/Utils/MultiFilterV3";
import cursorPaginate from "@/mixins/cursorPaginate";
import ModalSearchEntryLaunch from "./Shared/_ModalSearchEntryLaunch";
import ModalGetFreightToReassociate from "./Shared/_ModalGetFreightToReassociate";
import ModalFreight from "./Shared/_ModalFreight";
import moment from "moment";
import InputDatePicker from "@/components/InputDatePicker";
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import PuzlSelect from "@/components/PuzlSelect";
import ModalSearchChildren from "./Shared/_ModalSearchChildren";
import ModalReportEntryNotes from "./Shared/Exports/_ModalReportEntryNotes";
import ModalCreateByXmlAttachment from "../../../../modules/configuration/entry-launch/views/create/ModalCreateByXmlAttachment"

export default {
  name: "Index",
  mixins: [cursorPaginate],
  components: {
    PuzlBreadcrumb,
    ModalEntryLaunchType,
    ModalSearchEntity,
    ModalCreateEntity,
    ModalInitialFilterSefaz,
    ModalCreateByAccessKey,
    ModalCreateByEntity,
    Cards,
    Table,
    ModalAttachmentView,
    ModalListItems,
    ModalEntryCompanyPlantTransfer,
    ModalSearchEntryLaunch,
    ModalFreight,
    MultiFilter,
    InputDatePicker,
    BaseButtonHoverable,
    PuzlSelect,
    ModalSearchChildren,
    ModalGetFreightToReassociate,
    ModalReportEntryNotes,
    ModalCreateByXmlAttachment,
  },
  data() {
    return {
      loadingSkeleton: true,
      base_url_ms: base_url_ms(),
      listType: "cards",
      filter: {
        advanced: null,
        status: null,
        order_by: "bill_pay_installments.created.desc",
        due_date: {
          start: null,
          end: null,
        },
        due_date_range: {
          start: null,
          end: null,
        },
        value_range: {
          start: null,
          end: null,
        },
        document_range: {
          start: null,
          end: null,
        },
        access_key: null,
        show_all: null,
        has_cmc_without_mix_reference: null,
        has_cmc_adjustment_without_dosage_location: null,
      },
      range: {
        start: moment().format("YYYY-MM-DD hh:mm:ss"),
        end: moment().format("YYYY-MM-DD hh:mm:ss"),
      },
      filter_by_entry_date: false,
      lowerDocument: null,
      highDocument: null,
      highValue: null,
      lowerValue: null,
      product_service_hub_types: [
        { name: "MCC", id: 2 },
        { name: "Produtos e Serviços", id: 3 },
        { name: "Serviço", id: 1 },
        { name: "Frete", id: 4 },
        { name: "Tributos Automáticos", id: 5 },
        { name: "Empréstimo", id: 6 },
        { name: "Financiamento", id: 7 },
        { name: "Folha de pagamento", id: 9 },
        { name: "Taxas e Tributos", id: 10 },
        { name: "Devolução de Receita", id: 11 },
      ],
    };
  },
  methods: {
    handleSelectType() {
      this.$refs.modalEntryLaunchType.openModal();
    },
    handleCreateEntity() {
      this.$refs.modalCreateEntity.openModal(true);
    },

    selectedEntity(entity) {
      this.$router.push(`entry-launch/create/${entity.uuid}`);
    },
    selectedType(type) {
      switch (type) {
        case 0:
          this.$refs.modalInitialFilterSefaz.openModal();
          break;
        case 1:
          this.$refs.modalCreateByAccessKey.openModal();
          break;
        case 2:
          this.$refs.modalSearchEntity.openModal();
          break;
        case 1:
        // xml
        case 3:
          this.$refs.modalCreateByXmlAttachment.openModal();
          break;
      }
    },
    getColor(status) {
      switch (status) {
        case 0:
        case 5:
          return "warning";
        case 1:
          return "success";
        case 2:
          return "danger";
        case 3:
        case 6:
        case 7:
          return "light";
      }
    },
    getText(status) {
      switch (status) {
        case 0:
          return "PENDENTE";
        case 1:
          return "PAGO";
        case 2:
          return "ATRASADO";
        case 3:
          return "SEM CAP";
        case 5:
          return "AGUARDANDO";
        case 6:
          return "VERIFICAR";
        case 7:
          return "SEM FINANCEIRO";
      }
    },
    /**
     * Realiza o download da danfe
     *
     * @param {string} danfe_url
     * @returns void
     */
    async downloadDanfe(danfe_url) {
      const url = this.base_url_ms + "download-s3?url=" + danfe_url;
      this.$store.dispatch("exports/download", url).then(async (response) => {
        let blob = new Blob([response], { type: "application/pdf" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", "danfe.pdf");
        await link.click();
      });
      loader.hide();
    },
    handleModalAttachment(item) {
      this.$refs.modalAttachmentView.openModal(item);
    },
    handleModalListEntryLaunchItems(id) {
      this.$refs.modalListItems.openModal(id);
    },
    handleFilterAdvancedStatus(status) {
      this.filter.advanced = this.filter.advanced == status ? null : status;
      this.init({});
    },
    handleFilterOrderBy(order_by) {
      this.filter.order_by = this.filter.order_by == order_by ? null : order_by;
      this.init({});
    },
    init(filter = null) {
      this.startCursor(filter);
      this.$store
        .dispatch("entryLaunch/fetch", {
          filter: this.filter.range ? this.filter : { range: this.range },
          next_page: this.paginate.nextUrl,
          filter_by_entry_date: this.filter_by_entry_date ? 1 : null,
        })
        .then((response) => {
          this.resolveCursor(response);
        })
        .catch(() => {
          this.resolveCursor();
        });
    },
    handleDeleteEntryLaunch(id) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$notify({
            type: "info",
            message: "Estamos trabalhando em sua solicitação.",
          });
          this.$store
            .dispatch("entryLaunch/destroy", id)
            .then((response) => {
              this.$notify({ type: response.error_type, message: response.message });
            })
            .catch((error) => {
              this.$notify({
                type: "danger",
                message: error.data.message,
              });
            })
            .finally(() => {
              this.$Progress.finish();
            });
        }
      });
    },
    handleModalCreateByAccessKey(payload) {
      this.$refs.modalCreateByAccessKey.openModal(payload);
    },
    handleModalCreateByEntity(payload) {
      this.$refs.modalCreateByEntity.openModal(payload);
    },
    billPayGenerator(id) {
      this.$Swal.confirmAction("Deseja gerar um lançamento em contas a pagar?", ["Sim", "Não"]).then((result) => {
        if (result.isConfirmed) {
          const loader = this.$loading.show();
          this.$store
            .dispatch("entryLaunch/billPayGenerator", id)
            .then((response) => {
              loader.hide();
              this.init({});
              this.$notify({ type: response.error_type, message: response.message });
            })
            .catch((error) => {
              this.$notify({
                type: "danger",
                message: error.data.message,
              });
              loader.hide();
            });
        }
      });
    },
    handleModalEntryCompanyPlantTransfer(id) {
      this.$refs.modalEntryCompanyPlantTransfer.openModal(id);
    },
    handleModalFreight(entry_launch) {
      this.$refs.modalFreight.openModal(entry_launch);
    },
    handleEntryLaunchToFreight(entry_launch) {
      this.$refs.modalSearchEntryLaunch.openModal(entry_launch);
    },
    /**
     * Solicita danfe
     *
     * @param {integer} id
     * @returns void
     */
    getDanfe(id) {
      const loading = this.$loading.show();
      this.$store.dispatch("entryLaunch/getDanfe", id).then(() => {
        loading.hide();
        this.$notify({
          type: "success",
          message: "Solicitação concluída com sucesso! O processo será realizado em segundo plano.",
        });
      });
    },
    handleFilterDate(range, filter_by = null) {
      this.filter.date_type = filter_by;
      this.range = range;
      this.init({});
    },
    handleFilterCmcWithoutAdjustment() {
      this.filter.has_cmc_adjustment_without_dosage_location = this.filter.has_cmc_adjustment_without_dosage_location ? null : 1;
      this.init({});
    },
    handleFilterCmcWithoutMixReference() {
      this.filter.has_cmc_without_mix_reference = this.filter.has_cmc_without_mix_reference ? null : 1;
      this.init({});
    },
    handleModalSearchChildren(entry_launch_id) {
      this.$refs.modalSearchChildren.openModal(entry_launch_id);
    },
    handleModalGetFreightToReassociate(entry_launch_id, issue_date) {
      this.$refs.modalGetFreightToReassociate.openModal(entry_launch_id, issue_date);
    },
    getParentOrChildrenIcon(item) {
      const base_dir = `/img/icons/icons8/ios/`;
      let icon = null;
      switch (true) {
        //  Nota mãe completa
        case item.is_entry_value > 0 && Number(item.children_balance) <= 0 && item.children_balance !== null:
          icon = `${base_dir}working-mom_success.png`;
          break;
        // Nota mãe pendente
        case item.is_entry_value > 0 && (Number(item.children_balance) > 0 || item.children_balance === null):
          icon = `${base_dir}working-mom_warning.png`;
          break;
        // Nota filha completa
        case item.is_entry_value < 1 && item.parent_id !== null:
          icon = `${base_dir}women-age-group-2_success.png`;
          break;
      }
      return icon;
    },
    openModalReportEntryNotes() {
      this.$refs.modalReportEntryNotes.openModal();
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.gray-divider {
  background-color: #2b2d32;
  opacity: 0.1;
  color: #2b2d32;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
