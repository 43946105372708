<template>
  <div>
    <div class="row card-wrapper">
        <div class="col-lg-4" v-for="item in listed.items" :key="item.id">
          <div class="card" style="box-shadow: none !important; border-radius: 8px !important; border: 0.5px solid #E8E8E8;">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <section class="top-section d-flex align-items-center mb-1">
                  <span
                    v-if="item.status === Boolean(OperationSourceStatusEnum.keys.ACTIVE)"
                    class="status-button-success"
                    @click.prevent="onUpdateStatusItemClick(OperationSourceStatusEnum.keys.INACTIVE, item.id)"
                  >
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                  </span>
                  <span
                    v-else
                    class="status-button-danger"
                    @click.prevent="onUpdateStatusItemClick(OperationSourceStatusEnum.keys.ACTIVE, item.id)"
                  >
                      <img src="/img/icons/icons8/ios/shutdown--v1_danger.png" alt="shutdown--v1_danger" width="12px">
                      Inativo
                  </span>
                  <div class="row m-0 ml-2">
                    <div class="mx-1">
                      <el-popover
                        class="ml-0"
                        placement="bottom"
                        trigger="hover"
                      >
                        <div class="d-flex history-info" style="gap: 15px;">
                          <span>Criação</span>
                          -
                          <span>{{ item.created_at | parseDate('DD MMM YYYY - HH:mm') }} | {{ item.created_by_user_name }}</span>
                        </div>
                        <div v-if="item.updated_by_user_id != null" class="d-flex justify-content-between history-info" style="gap: 15px;">
                          <span>Última Alteração</span>
                          -
                          <span>{{ item.updated_at | parseDate('DD MMM YYYY - HH:mm') }} | {{ item.updated_by_user_name }}</span>
                        </div>
                        <base-button
                          slot="reference"
                          class="p-0 m-0 shadow-none"
                          outiline
                          size="sm"
                          type="secundary"
                        >
                          <img src="/img/icons/icons8/ios/user-male-circle--v1.png" alt="user-male-circle--v1" width="20px">
                        </base-button>
                      </el-popover>
                    </div>
                    <div>
                      <el-popover
                        class="ml-2 mb--1 pt-2"
                        placement="right"
                        trigger="hover"
                      >
                        <div>
                          <span>
                            <span style="font-weight: 500;color: #2B2D32;font-size: 12px;">
                              TIPO DE OPERAÇÕES PUZL
                            </span>
                            <hr>
                            <span style="font-weight: 500;font-size: 10px;color: #2B2D32">Financeiro e Estoque</span>
                            <div class="d-flex justify-content-between my-1">
                              <span style="font-weight: 300;font-size: 10px;color: #2B2D32">
                                Gera Financeiro
                              </span>
                              <img :src="item.is_generate_financial ? '/img/icons/icons8/ios/checked-checkbox--v1.png' : '/img/icons/icons8/ios/unavailable_danger.png'" width="16px">
                            </div>
                            <div class="d-flex justify-content-between my-1">
                              <span style="font-weight: 300;font-size: 10px;color: #2B2D32">
                                Nota Mãe
                              </span>
                              <img :src="item.is_entry_value ? '/img/icons/icons8/ios/checked-checkbox--v1.png' : '/img/icons/icons8/ios/unavailable_danger.png'" width="16px">
                            </div>
                            <div class="d-flex justify-content-between my-1">
                              <span style="font-weight: 300;font-size: 10px;color: #2B2D32">
                                Movimenta Estoque
                              </span>
                              <img :src="item.is_generate_stock ? '/img/icons/icons8/ios/checked-checkbox--v1.png' : '/img/icons/icons8/ios/unavailable_danger.png'" width="16px">
                            </div>
                            <span style="font-weight: 500;font-size: 10px;color: #2B2D32">Contábil e Fiscal</span>
                            <div class="d-flex justify-content-between my-1">
                              <span style="font-weight: 300;font-size: 10px;color: #2B2D32">
                                Contabiliza
                              </span>
                              <img :src="item.is_computable ? '/img/icons/icons8/ios/checked-checkbox--v1.png' : '/img/icons/icons8/ios/unavailable_danger.png'" width="16px">
                            </div>
                            <div class="d-flex justify-content-between my-1">
                              <span style="font-weight: 300;font-size: 10px;color: #2B2D32">
                                Gera Livro Fiscal
                              </span>
                              <img :src="item.is_generate_tax_book ? '/img/icons/icons8/ios/checked-checkbox--v1.png' : '/img/icons/icons8/ios/unavailable_danger.png'" width="16px">
                            </div>
                          </span>
                        </div>
                          <base-button
                            slot="reference"
                            class="p-0 m-0 shadow-none"
                            outiline
                            size="sm"
                            type="secundary"
                          >
                            <img src="/img/brand/logo.png" alt="logo" width="20px">
                          </base-button>
                        </el-popover>
                    </div>
                  </div>
                </section>
                <div class="ml-auto">
                  <base-dropdown>
                    <base-button slot="title-container" type="empty" size="sm" class="dropdown-toggle shadow-none mt--2 mr--2" style="border-radius: 4px !important;">
                      <img height="30px" width="30px" src="/img/icons/icons8/ios/settings--v1_primary.png" class="mr-1 mt-n1"/>
                    </base-button>
                    <span class="dropdown-item" @click.prevent="onEditItemClick(item.id)">
                      <img src="/img/icons/create-new.png" width="19px" height="19px" class="imgBaseButton">
                      EDITAR
                    </span>
                    <span v-if="item.type === 1" class="dropdown-item" @click.prevent="onOperationNatureClick(item.id)">
                      <img src="/img/icons/ledger.png" width="19px" height="19px" class="imgBaseButton">
                      NATUREZA DA OPERAÇÃO
                    </span>
                    <span class="dropdown-item" @click.prevent="onRemoveItemClick(item.id)">
                      <img src="/img/icons/icons8/ios/delete--v1.png" width="19px" height="19px" class="imgBaseButton">
                      EXCLUIR
                    </span>
                  </base-dropdown>
                </div>
              </div>
              <section class="mt-2">
                <div class="mb-1">
                  <span style="font-size: 14px;font-weight: 500;color: #2B2D32;">
                    {{ item.name }}
                  </span>
                </div>
                <div v-show="item.product_service_hub_type_name">
                  <span style="font-size: 12px;font-weight: 400;color: #2B2D32;">
                    {{ item.product_service_hub_type_name }}
                  </span>
                </div>
              </section>
              <section class="mt-0">
                <div v-if="item.operation_nature === false" class="mt-2">
                  <span style="font-size: 14px;font-weight: 400;color: #2B2D32;">
                    ENTRADA
                  </span>
                </div>
                <div v-else class="mt-2">
                  <span style="font-size: 14px;font-weight: 400;color: #2B2D32;">
                    SAÍDA
                  </span>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

    <div class="row card-wrapper" v-show="isListing">
      <SkeletonPuzlGrid v-for="(index) in 3" :key="index"/>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits } from "vue";
import operationSourceStore from "../../store";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid.vue";
import OperationSourceStatusEnum from "../../enums/OperationSourceStatusEnum";

/** Eventos emitidos */
const emit = defineEmits([
  "onEditItemClick",
  "onRemoveItemClick",
  "onUpdateStatusItemClick",
  "onOperationNatureClick"
]);

/** Store Getters */
const listed = computed(() => operationSourceStore.getListed());
const isListing = computed(() => operationSourceStore.getIsListing());

/**
 * Editar item
 * @param {number} id
 */
function onEditItemClick(id) {
  emit("onEditItemClick", id);
}

/**
 * Remover item
 * @param {number} id
 */
function onRemoveItemClick(id) {
  emit("onRemoveItemClick", id);
}

/**
 * Atualizar status do item
 * @param {boolean} status
 * @param {number} id
 */
function onUpdateStatusItemClick(status, id) {
  emit("onUpdateStatusItemClick", status ,id);
}

/**
 * Abrir modal para natureza da operação
 * @param {number} id
 */
function onOperationNatureClick(id) {
  emit("onOperationNatureClick",id);
}

</script>

<style scoped>
hr {
  border: 0;
  border-top: 0.5px solid #E8E8E8;
  margin: 7px 0;
  width: 116%;
  margin-left: -11px;
}
.status-button-success {
  background-color: #F2F7F3;
  border-radius: 16px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #149E57;
  font-weight: 400;
  padding: 4px 40px;
}
.status-button-danger {
  background-color: #FCF3F3;
  border-radius: 16px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #DB4539;
  font-weight: 400;
  padding: 4px 34px
}
.history-info span{
  font-size: 14px;
  font-weight: 500;
  color: #2B2D32;
}
</style>