import * as types from './mutation_types'
import { createAxios } from "@/plugins/axios";
const endPoint = '/mix/reference'
export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters:{
    fetch: state => state.items,
  },
  mutations: {
    [types.SET_REFERENCE] (state, payload){
      state.items = payload
    },
  },
  actions: {
    fetchByParams({commit, dispatch}, params) {
      return createAxios().patch(`${endPoint}/plant/${params.plantId}/group/${params.groupId}/type/${params.type}`)
        .then(({data}) =>{
          commit(types.SET_REFERENCE, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  },
}
