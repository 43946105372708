<template>
  <div v-bind="$attrs">
    <modal
      size="md-2"
      :show.sync="modal.show"
      data-class="programming-weighing-info"
    >
      <template slot="header">
        <h5 class="modal-title">
          <img
            src="/img/icons/icons8/ios/icon-plus-success.png"
            width="25"
            class="mt-n2 mr-2"
          />
          <span>{{ modal.title }}</span>
        </h5>
      </template>
      <validation-observer
        v-slot="{ invalid, handleSubmit }"
        ref="formValidator"
      >
        <form
          class="needs-validation"
          ref="myForm"
          @submit.prevent
          autocomplete="off"
        >
          <div class="pl-3">
            <div class="row">
              <div class="col-md-12 mb-3 info-section">
                <img
                  src="/img/icons/icons8/ios/info-squared.png"
                  width="20"
                  height="20"
                  style="margin-right: 1.5%"
                />
                <strong class="mb-3">Identificação</strong>
                <div class="pzl-container-divider">
                  <div></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row m-0 p-0 mb-1">
                  <label
                    class="col-md-4 pt-1 pb-0 col-form-label form-control-label pl-0"
                  >
                    Grupo de item
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </label>
                  <div class="col-md-8 pr-4">
                    <validation-provider rules="">
                      <base-input input-classes="form-control-sm">
                        <PuzlSelect
                          style="width: 100%"
                          v-model.lazy="checkList.checklist_item_parent_uuid"
                          :items="groups"
                          :disableBoxShadow="true"
                          placeholder="Selecione"
                          class="select-xl col-md-12 px-0 new-default-black-font"
                          @input="updateGroupList"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-1">
                  <label
                    class="col-md-3 pt-1 pb-0 col-form-label form-control-label pl-0"
                  >
                    Código
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </label>
                  <div
                    class="col-md-1 d-flex justify-content-center align-items-center"
                    style="
                      border: 1px solid #e8e8e8;
                      background-color: #e8e8e8;
                      border-radius: 4px;
                      padding: 0;
                    "
                  >
                    <span>{{ orderedCode }}</span>
                  </div>
                  <div class="col-md-8 pr-4">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <input
                          v-model="checkList.code"
                          type="number"
                          class="form-control form-control-sm"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-1">
                  <label
                    class="col-md-4 pt-1 pb-0 col-form-label form-control-label pl-0"
                  >
                    Nome
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </label>
                  <div class="col-md-8 pr-4">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <input
                          v-model="checkList.name"
                          type="text"
                          class="form-control form-control-sm"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-1">
                  <label
                    class="col-md-4 pt-1 pb-0 col-form-label form-control-label pl-0"
                  >
                    Descrição
                  </label>
                  <div class="col-md-8 pr-4">
                    <validation-provider rules="">
                      <base-input input-classes="form-control-sm">
                        <input
                          v-model="checkList.description"
                          type="text"
                          class="form-control form-control-sm"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="showFormStepTwo">
              <div class="row mt-3">
                <div class="col-md-12 mb-3 info-section">
                  <img
                    src="/img/icons/icons8/ios/setting-double-check-primary.png"
                    width="20"
                    height="20"
                    style="margin-right: 1.5%"
                  />
                  <strong class="mb-3">Configuração</strong>
                  <div class="pzl-container-divider">
                    <div></div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group row m-0 p-0 mb-1">
                    <label
                      class="col-md-4 pt-1 pb-0 col-form-label form-control-label pl-0"
                    >
                      Exigência de foto
                    </label>
                    <div class="col-md-8 pr-4 d-flex justify-content-end">
                      <label
                        class="app-badge"
                        :class="{
                          'green-backgroud-light': checkList.photo_requirement,
                          'red-backgroud-light': !checkList.photo_requirement,
                        }"
                        style="width: 130px"
                      >
                        <input
                          type="checkbox"
                          v-model="checkList.photo_requirement"
                        />
                        <span
                          style="gap: 4px"
                          class="d-flex justify-content-center align-items-center"
                          :class="{
                            'green-text-badge': checkList.photo_requirement,
                            'red-text-badge': !checkList.photo_requirement,
                          }"
                        >
                          <img
                            :src="
                              checkList.photo_requirement
                                ? '/img/icons/ok--v1.png'
                                : '/img/icons/icons8/ios/cancel_danger.png'
                            "
                            alt="..."
                            width="14px"
                          />
                          {{
                            checkList.photo_requirement ? "Ativo" : "Inativo"
                          }}
                        </span>
                      </label>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-1">
                    <label
                      class="col-md-4 pt-1 pb-0 col-form-label form-control-label pl-0"
                    >
                      Exigência de observação
                    </label>
                    <div class="col-md-8 pr-4 d-flex justify-content-end">
                      <label
                        class="app-badge"
                        :class="{
                          'green-backgroud-light':
                            checkList.observation_requirement,
                          'red-backgroud-light':
                            !checkList.observation_requirement,
                        }"
                        style="width: 130px"
                      >
                        <input
                          type="checkbox"
                          v-model="checkList.observation_requirement"
                        />
                        <span
                          style="gap: 4px"
                          class="d-flex justify-content-center align-items-center"
                          :class="{
                            'green-text-badge':
                              checkList.observation_requirement,
                            'red-text-badge':
                              !checkList.observation_requirement,
                          }"
                        >
                          <img
                            :src="
                              checkList.observation_requirement
                                ? '/img/icons/ok--v1.png'
                                : '/img/icons/icons8/ios/cancel_danger.png'
                            "
                            alt="..."
                            width="14px"
                          />
                          {{
                            checkList.observation_requirement
                              ? "Ativo"
                              : "Inativo"
                          }}
                        </span>
                      </label>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-1">
                    <label
                      class="col-md-4 pt-1 pb-0 col-form-label form-control-label pl-0"
                    >
                      Travamento de operação
                    </label>
                    <div class="col-md-8 pr-4 d-flex justify-content-end mt-n2">
                      <label
                        class="app-badge"
                        :class="{
                          'green-backgroud-light': checkList.operation_lock,
                          'red-backgroud-light': !checkList.operation_lock,
                        }"
                        style="width: 130px"
                      >
                        <input
                          type="checkbox"
                          v-model="checkList.operation_lock"
                        />
                        <span
                          style="gap: 4px"
                          class="d-flex justify-content-center align-items-center"
                          :class="{
                            'green-text-badge': checkList.operation_lock,
                            'red-text-badge': !checkList.operation_lock,
                          }"
                        >
                          <img
                            :src="
                              checkList.operation_lock
                                ? '/img/icons/ok--v1.png'
                                : '/img/icons/icons8/ios/cancel_danger.png'
                            "
                            alt="..."
                            width="14px"
                          />
                          {{ checkList.operation_lock ? "Ativo" : "Inativo" }}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="d-flex pr-2" style="gap: 5px">
              <div
                class="d-flex align-items-center button-cancel"
                style="gap: 5px"
                @click="closeModal()"
              >
                <img
                  src="/img/icons/icons8/ios/cancel_danger.png"
                  alt="cancel_danger"
                  width="16px"
                />
                <span class="font-weight-500"> Cancelar </span>
              </div>
              <button
                v-show="!loading"
                class="d-flex align-items-center button-save"
                type="submit"
                style="gap: 5px"
                @click="handleSave(false)"
              >
                <img
                  src="/img/icons/icons8/ios/save_success.png"
                  alt="save_success"
                  width="16px"
                />
                <span class="font-weight-500"> Salvar </span>
              </button>
              <button
                v-show="!loading"
                class="d-flex align-items-center button-save"
                type="submit"
                style="gap: 5px"
                @click="handleSave(true)"
              >
                <img
                  src="/img/icons/icons8/ios/save_success.png"
                  alt="save_success"
                  width="16px"
                />
                <span class="font-weight-500" style="white-space: nowrap;"> Salvar e Continuar</span>
              </button>
            </div>
          </div>
        </form>
      </validation-observer>
    </modal>
  </div>
</template>

<script>
import PuzlSelect from "@/components/PuzlSelect";
import { mapGetters } from "vuex";
export default {
  name: "ModalCreate",
  inheritAttrs: false,
  components: {
    PuzlSelect,
  },
  data() {
    return {
      modal: {
        title: "Adicionar Item",
        show: false,
      },
      orderedCode: null,
      loading: false,
      usingLastCode: 0,
      showFormStepTwo: false,
      groupsList: [],
      checkList: {
        checklist_item_parent_uuid: null,
        code: null,
        name: null,
        checklist_uuid: null,
        description: null,
        photo_requirement: false,
        observation_requirement: false,
        operation_lock: false,
        chosen_days: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      $_checkList_items: "itemCheckList/fetch",
    }),
    groups() {
      let groupsList = [{ id: null, name: "Inicial" }];
      let hydrate = (items, codeParentItem = null) => {
        for (let i = 0; i < items.length; i++) {
          let item = items[i];
          let code = codeParentItem
            ? `${codeParentItem}.${item.code}`
            : item.code;
          groupsList.push({
            id: item.uuid,
            name: `${item.ordered_code} ${item.name}`,
            code: item.code,
            childrens: item.childrens,
            checklist_item_parent_uuid: item.checklist_item_parent_uuid,
          });
        }
      };
      hydrate(this.$_checkList_items);
      this.groupsList = groupsList;
      return groupsList;
    },
  },
  methods: {
    openModal() {
      this.checkListReset();
      this.showFormStepTwo = false;
      this.getLastUsedCode();
      this.modal.show = true;
    },
    updateGroupList(selected) {
      if (!selected) {
        this.checkList.photo_requirement = false;
        this.checkList.observation_requirement = false;
        this.checkList.operation_lock = false;
        this.showFormStepTwo = false;
        this.checkList.chosen_days = [];
        this.getLastUsedCode();
        return;
      }
      this.getLastUsedCode(selected);
      this.showFormStepTwo = true;
    },
    checkListReset() {
      this.checkList = {
        checklist_item_parent_uuid: null,
        code: null,
        name: null,
        description: null,
        photo_requirement: false,
        observation_requirement: false,
        operation_lock: false,
        chosen_days: [],
      };
    },
    closeModal() {
      this.modal.show = false;
    },
    getLastUsedCode(uuid = null) {
      let lastCode = 0;
      this.orderedCode = lastCode;
      if (uuid === null) {
        this.$_checkList_items.forEach((item) => {
          lastCode = item.code;
        });
        this.checkList.code = lastCode + 1;
        this.orderedCode = this.checkList.code;
        return;
      }
      let item = this.groupsList.find((item) => item.id === uuid);
      this.orderedCode = item.code;
      item.childrens.forEach((item) => {
        lastCode = item.code;
        this.orderedCode = item.ordered_code;
      });
      this.checkList.code = lastCode + 1;
      return;
    },
    handleSave(saveAndContinue = false) {
      this.$refs.formValidator.validate().then((isValid) => {
        if (isValid) {
          this.save(saveAndContinue);
        }
      });
    },
    save(saveAndContinue = false) {
      this.$Progress.start();
      let loader = this.$loading.show();
      this.checkList.checklist_uuid = this.$route.params.uuid;
      this.$store
        .dispatch("itemCheckList/add", this.checkList)
        .then((response) => {
          this.$notify({
            type: "success",
            message: response.message,
          });
          this.checkListReset();
          this.$emit("refresh");
          if (!saveAndContinue) {
            this.closeModal();
            return;
          }
        })
        .catch((error) => {
          if (error.data && error.data.message) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          }
        })
        .finally(() => {
          this.$Progress.finish();
          loader.hide();
        });
    },
  },
};
</script>

<style scoped>
/deep/ div[data-class="programming-weighing-info"] div.modal-header.pb-1 {
  background: #fef9f2 !important;
}

/deep/
  div[data-class="programming-weighing-info"]
  div.modal-header.pb-1
  > button
  i {
  border: 1px solid #000 !important;
  color: #000;
  padding: 3%;
  font-size: 14px !important;
}

h5.modal-title > span,
h5.modal-title > small {
  color: #2b2d32;
  font-size: 18px !important;
}

div.info-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 23px;
}

div.info-section > div.pzl-container-divider {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2%;
}

div.info-section > div.pzl-container-divider > div {
  width: 100%;
  border: 0.5px solid #e8e8e8;
}

div.close-btn {
  border-radius: 8px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 115px;
  border: 0.5px solid #e8e8e8;
  gap: 10px;
}

div.close-btn > i {
  border: 1px solid #000;
  color: #000;
  padding: 3%;
  font-size: 8px !important;
}

div.close-btn > span {
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  font-family: "Fredoka";
}

.read-info {
  border: 0.5px solid #e8e8e8;
  height: 31px;
  padding: 8px 2px 8px 23px;
  border-radius: 4px;
  width: 100%;
}
textarea.read-info {
  height: 51px;
}
.container-icon {
  width: 25px;
  height: 31px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-cancel {
  cursor: pointer;
  border: 0.5px solid #db4539;
  border-radius: 8px;
  color: #db4539;
  padding: 4px 20px;
  height: 32px;
}
button.button-save {
  cursor: pointer;
  border: 0.5px solid #149e57;
  border-radius: 8px;
  color: #149e57;
  padding: 4px 30px;
  background-color: #fff;
  height: 32px !important;
  min-height: 32px !important;
}
.green-text-badge {
  color: #149e57;
}
.red-text-badge {
  color: #db4539;
}
.green-backgroud-light {
  background: #f2f7f3;
}
.red-backgroud-light {
  background: #fcf3f3;
}
label.app-badge {
  display: flex;
  max-width: 100%;
  height: 24px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  cursor: pointer;
}
label.app-badge > input[type="checkbox"] {
  display: none;
}
label.app-badge > span {
  font-family: "Fredoka";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
