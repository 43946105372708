<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
            <PuzlBreadcrumb/>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-2 mb-2">
          <back-button/>
        </div>
        <div class="col-md-2 mb-2">
          <base-button class="pt-1 pb-2" type="warning" @click.prevent="handleModalCreateEntryLaunchByAccessKey" size="lg" block> 
            <i class="fas fa-barcode mt-1 border" style="padding: 0.43rem"/>
          </base-button>
        </div>
        <!-- Desabilitado até segunda ordem -->
        <!-- <div class="col-md-2 mb-2">
          <base-button type="warning" @click.prevent="handleModalCreateByEntity" size="lg" block>
            <i class="fas fa-download"/> 
          </base-button>
        </div> -->
      </div>
    </base-header>
    <list/>
    <ModalCreateEntryLaunchByAccessKey ref="modalCreateEntryLaunchByAccessKey" />
    <ModalCreateByEntity ref="modalCreateByEntity" />
  </div>
</template>

<script>
import BackButton from "@/components/Utils/BackButton";
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import ModalCreateEntryLaunchByAccessKey from "../../Configuration/EntryLaunch/Shared/_ModalCreateByAccessKey"
import ModalCreateByEntity from "../../Configuration/EntryLaunch/Shared/_ModalCreateByEntity"
import List from "./Shared/_List";

export default {
  name: "Index",
  components: {BackButton, PuzlBreadcrumb, List, ModalCreateEntryLaunchByAccessKey, ModalCreateByEntity},
  methods: {
    handleModalCreateEntryLaunchByAccessKey() {
      this.$refs.modalCreateEntryLaunchByAccessKey.openModal();
    }, 
    handleModalCreateByEntity() {
      this.$refs.modalCreateByEntity.openModal();
    }
  }
}
</script>

<style scoped>

</style>
