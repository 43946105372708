<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-gray d-inline-block mb-0">{{ this.$route.meta.breadcrumbs[1].title }}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4 text-gray">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#"><i :class="this.$route.meta.icon"></i></a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">{{ this.$route.meta.breadcrumbs[0].title }}</a>
              </li>
              <li class="breadcrumb-item" aria-current="page">{{ this.$route.meta.breadcrumbs[1].title }}</li>
            </ol>
          </nav>
        </div>
      </div>
    </base-header>
    <list-resume/>
  </div>
</template>

<script>
import ListResume from './Shared/_ListResume'
export default {
  name: "Index",
  components : {
    ListResume
  }
}
</script>

<style scoped>

</style>
