<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div class="card-header border-header-success-bottom mt-3 mb-2 p-2">
              <h5 class="h4 mb-0" slot="title">Identificação</h5>
            </div>
            <div>
              <!-- Categoria -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-0 col-form-label form-control-label text-left pr-0">
                  Categoria
                  <span class="text-danger">&nbsp;*</span>
                  <span class="btn btn-sm btn-success p-1 ml-1" @click.prevent="handleCreateCategory()"
                    ><i class="fas fa-plus"></i> Novo</span
                  >
                </label>
                <div class="col-md-7 pr-4">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <puzl-select v-model="category_id" :items="$_categories" :disabled="loadingCategory" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="modal-footer">
                <base-button type="secondary" @click="closeModal('create')"> Cancelar </base-button>
                <base-button :loading="loadingStore" type="success" native-type="submit" v-bind:disabled="invalid"> Salvar </base-button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
    <CreateProductServiceCategory @createdCategory="setCategory" ref="modalCreateCategory" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
("@/helpers");
import CreateProductServiceCategory from "../Category/Shared/_ModalCreate.vue";
import PuzlSelect from "@/components/PuzlSelect";
export default {
  name: "ModalCreateManyProductService",
  components: { CreateProductServiceCategory, PuzlSelect },
  data() {
    return {
      modal: {
        title: "Adicionar Despesas",
        create: false,
      },
      product_service_hub_type_id: null,
      category_id: null,
      items: [],
      loadingCategory: false,
      loadingStore: false,
      loadingProductServiceHubType: true,
      loadingChartAccount: true,
      entity_id: null,
    };
  },
  computed: {
    ...mapGetters({
      $_categories: "productServiceCategory/fetch",
    }),
  },
  mounted() {
    this.loadingProductServiceHubType = true;
    this.loadingChartAccount = true;
    this.$store.dispatch("productServiceHubType/fetch").then((response) => {
      this.loadingProductServiceHubType = false;
    });
    this.$store.dispatch("chartAccount/fetchItems").then((response) => {
      this.loadingChartAccount = false;
    });
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal(entry_launch_id, product_service_hub_type_id) {
      /* Mcc não é permitido cadastrar */
      if(product_service_hub_type_id === 2) {
        this.$notify({
          type: "danger",
          message: "O tipo MCC não é permitido nesta operação.",
        });
        return this.$notify({
          type: "danger",
          message: "Entre em contato com o setor de tecnologia.",
        });
      }
      this.loadingProductServiceHubType = true;
      this.category_id = null;
      this.entry_launch_id = entry_launch_id;
      this.product_service_hub_type_id = product_service_hub_type_id;
      this.$store.dispatch("productServiceCategory/getCategoriesByType", product_service_hub_type_id);
      this.modal.create = true;
    },
    store() {
      this.loadingStore = true;
      this.$store
        .dispatch("productService/updateMany", {
          entry_launch_id: this.entry_launch_id,
          product_service_hub_type_id: this.product_service_hub_type_id,
          category_id: this.category_id,
        })
        .then((response) => {
          this.loadingStore = false;
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.$emit("updated");
          this.closeModal();
        })
    },
    handleCreateCategory() {
      this.$refs.modalCreateCategory.handleCreateModal();
    },
    setCategory(category) {
      this.loadingCategory = true;
      this.$store.dispatch("productServiceCategory/getCategoriesByType", this.product_service_hub_type_id).then((response) => {
        if (this.product_service_hub_type_id === category.product_service_hub_type_id) {
          this.category_id = category.id;
        }
        this.loadingCategory = false;
      });
    },
  },
};
</script>

<style scoped></style>
