import StockLocationListPage from "../views/list/StockLocationListPage.vue";

export default [
  {
    path: '/configuration/stock-locations',
    name: 'configuration.stock-location.view',
    component: StockLocationListPage,
    meta: {
      title: 'Configurações',
      description: 'Locais de Estoque',
      icon: 'fa fa-home',
      permission: 'configuration.plant.index.view',
      breadcrumbs: [
        {title: 'Dashboard', name: 'configuration.stock-location.dashboard.index'},
        {title: 'Locais de Estoque', name: null},
      ]
    },
  },
];
