import entryLaunchItemTaxStoreGetters from "./entryLaunchItemTaxStoreGetters";
import entryLaunchItemTaxStoreMutations from "./entryLaunchItemTaxStoreMutations";
import { entryLaunchItemTaxStateKeys } from "./entryLaunchItemTaxStoreStateKeys";

const entryLaunchItemTaxStore = {
  ...entryLaunchItemTaxStoreGetters,
  ...entryLaunchItemTaxStoreMutations,
  entryLaunchItemTaxStateKeys,
};

export default entryLaunchItemTaxStore;
