import { toMoney } from "../../../../helpers";

const modes = [
  {
    name: "Atrasado",
    itemStyle: {
      color: "#ff0000",
    },
  },
  {
    name: "Pendente",
    itemStyle: {
      color: "#fff414",
    },
  },
  {
    name: "Pago",
    itemStyle: {
      color: "#00b908",
    },
  },
];

const invoice_modes = [
  {
    name: "Pendente",
    itemStyle: {
      color: "#fff414",
    },
  },
  {
    name: "Faturado",
    itemStyle: {
      color: "#00b908",
    },
  },
];

const baseTreemapChart = {
  options: {
    animation: false,
    legend: {
      data: modes,
      selectedMode: "single",
      top: 55,
      itemGap: 5,
      borderRadius: 5,
    },
    tooltip: {},
    series: modes.map(function (mode, idx) {
      let seriesOpt = createSeriesCommon(idx);
      seriesOpt.name = mode.name;
      seriesOpt.top = 80;
      seriesOpt.data = [];
      seriesOpt.levels = getLevelOption(idx);
      return seriesOpt;
    }),
  },
};

const baseInvoiceTreemapChart = {
  options: {
    animation: false,
    legend: {
      data: invoice_modes,
      selectedMode: "single",
      top: 55,
      itemGap: 5,
      borderRadius: 5,
    },
    tooltip: {},
    series: invoice_modes.map(function (mode, idx) {
      let seriesOpt = createInvoicesSeriesCommon(idx);
      seriesOpt.name = mode.name;
      seriesOpt.top = 80;
      seriesOpt.data = [];
      seriesOpt.levels = getLevelOption(idx);
      return seriesOpt;
    }),
  },
};

function createSeriesCommon(mode) {
  return {
    type: "treemap",
    tooltip: {
      formatter: getTooltipFormatter(mode),
    },
    label: {
      position: "insideTopLeft",
      formatter: function (params) {
        let value_field = 0;
        switch (true) {
          case params.seriesName === "Atrasado":
            value_field = 4;
            break;
          case params.seriesName === "Pendente" && typeof params.data.service_value === "undefined":
            value_field = 2;
            break;
          case params.seriesName === "Pago":
            value_field = 1;
            break;
          default:
            value_field = 6;
        }
        let arr = [
          "{name| " + params.data.name + "}",
          "{des| }",
          "{description| " + (params.data.company_plant_name ?? "N/A") + "}",
          "{hr|}",
          "{budget| " + toMoney(params.value[value_field] ? params.value[value_field] : 0) + "} {label| " + params.seriesName + "}",
        ];
        return arr.join("\n");
      },
      rich: {
        budget: {
          fontSize: 22,
          lineHeight: 30,
          color: "yellow",
        },
        household: {
          fontSize: 14,
          color: "#fff",
        },
        label: {
          fontSize: 9,
          backgroundColor: "rgba(0,0,0,0.3)",
          color: "#fff",
          borderRadius: 2,
          padding: [2, 4],
          lineHeight: 25,
          align: "right",
        },
        name: {
          fontSize: 12,
          color: "#fff",
        },
        hr: {
          width: "100%",
          borderColor: "rgba(255,255,255,0.2)",
          borderWidth: 0.5,
          height: 0,
          lineHeight: 10,
        },
      },
    },
    itemStyle: {
      borderColor: "black",
    },
    levels: getLevelOption(0),
  };
}

function createInvoicesSeriesCommon(mode) {
  return {
    type: "treemap",
    tooltip: {
      formatter: getTooltipFormatter(mode),
    },
    label: {
      position: "insideTopLeft",
      formatter: function (params) {
        let arr = [
          "{name| " + params.data.name + "}",
          "{des| }",
          "{description| " + (params.data.company_plant_name ?? "N/A") + "}",
          "{hr|}",
          "{budget| " + toMoney(params.value[0] ? params.value[0] : 0) + "} {label| " + params.seriesName + "}",
        ];
        return arr.join("\n");
      },
      rich: {
        budget: {
          fontSize: 22,
          lineHeight: 30,
          color: "yellow",
        },
        household: {
          fontSize: 14,
          color: "#fff",
        },
        label: {
          fontSize: 9,
          backgroundColor: "rgba(0,0,0,0.3)",
          color: "#fff",
          borderRadius: 2,
          padding: [2, 4],
          lineHeight: 25,
          align: "right",
        },
        name: {
          fontSize: 12,
          color: "#fff",
        },
        hr: {
          width: "100%",
          borderColor: "rgba(255,255,255,0.2)",
          borderWidth: 0.5,
          height: 0,
          lineHeight: 10,
        },
      },
    },
    itemStyle: {
      borderColor: "black",
    },
    levels: getLevelOption(0),
  };
}

function getTooltipFormatter(mode) {
  return function (info) {
    if (!Array.isArray(info.value)) {
      return "";
    }
    if (typeof info.data.final_value !== "undefined") {
      return [
        '<div class="tooltip-title"><b>' + info.data.name + "</b></div>",
        "<b>" + info.data.ein + "</b><br>",
        "Total: <b>" + toMoney(info.value[0]) + "</b><br>",
        "Pago: <b>" + toMoney(info.value[1]) + "</b><br>",
        "Pendente: <b>" + toMoney(info.value[2]) + "</b><br>",
        "Atrasado: <b>" + toMoney(info.value[4]) + "</b><br>",
      ].join("");
    }

    return [
      '<div class="tooltip-title"><b>' + info.data.name + "</b></div>",
      "<b>" + info.data.ein + "</b><br>",
      "Concreto: <b>" + toMoney(info.value[1]) + "</b><br>",
      "Serviços: <b>" + toMoney(info.value[2]) + "</b><br>",
      "Adicionais: <b>" + toMoney(info.value[4]) + "</b><br>",
      "Avulsos: <b>" + toMoney(info.value[5]) + "</b><br>",
      info.seriesName === "Faturado" ? "Cancelados: <b>" + toMoney(info.value[6]) + "</b><br>" : "",
    ].join("");
  };
}

function getLevelOption(mode) {
  return [
    {
      color: undefined,
      colorMappingBy: "id",
      itemStyle: {
        borderWidth: 3,
        gapWidth: 3,
      },
    },
    {
      colorAlpha: undefined,
      itemStyle: {
        gapWidth: 1,
      },
    },
  ];
}

const emphasisStyle = {
  itemStyle: {
    shadowBlur: 10,
    shadowColor: "rgba(0,0,0,0.3)",
  },
};

const barBrushChartFields = [
  "bill_receive_overdue",
  "bill_receive_pending",
  "bill_receive_paid",
  "bill_pay_overdue ",
  "bill_pay_pending",
  "bill_pay_paid",
  "balance",
  "bank_balance",
];

const baseBarBrush = {
  legend: {
    data: ["Atrasado (CAR)", "Pendente (CAR)", "Pago (CAR)", "Atrasado (CAP)", "Pendente (CAP)", "Pago (CAP)", "Saldo", "Conta"],
  },
  toolbox: {
    feature: {
      magicType: {
        type: ["stack"],
      },
    },
  },
  tooltip: {
    formatter(obj) {
      return `${obj.marker} ${obj.seriesName} <b> ${toMoney(obj.data)} </b> <br/>`;
    },
  },
  xAxis: {
    data: [],
    name: "",
    axisLine: { onZero: true },
    splitLine: { show: false },
    splitArea: { show: false },
  },
  yAxis: {
    type: "value",
    name: "",
    splitNumber: 12,
    axisLabel: {
      formatter(value) {
        return toMoney(value);
      },
    },
  },
  dataZoom: [
    {
      show: true,
      start: 0,
      end: 100,
    },
  ],
  grid: {
    bottom: 100,
  },
  series: [
    {
      name: "Atrasado (CAR)",
      type: "bar",
      stack: "one",
      emphasis: emphasisStyle,
      data: [],
      color: "Red",
    },
    {
      name: "Pendente (CAR)",
      type: "bar",
      stack: "one",
      emphasis: emphasisStyle,
      data: [],
      color: "Yellow",
    },
    {
      name: "Pago (CAR)",
      type: "bar",
      stack: "one",
      emphasis: emphasisStyle,
      data: [],
      color: "Green",
    },
    {
      name: "Atrasado (CAP)",
      type: "bar",
      stack: "one",
      emphasis: emphasisStyle,
      data: [],
      color: "#ff7f75",
    },
    {
      name: "Pendente (CAP)",
      type: "bar",
      stack: "one",
      emphasis: emphasisStyle,
      data: [],
      color: "#fdce6a",
    },
    {
      name: "Pago (CAP)",
      type: "bar",
      stack: "one",
      emphasis: emphasisStyle,
      data: [],
      color: "#2fe185",
    },
    {
      name: "Saldo",
      type: "line",
      color: "Blue",
      data: [],
    },
    {
      name: "Conta",
      type: "bar",
      stack: "one",
      emphasis: emphasisStyle,
      data: [],
      color: "#5603ad",
    },
  ],
};

export const changeBarBrushChart = (data) => {
  let charts = {
    day: baseBarBrush,
    month: JSON.parse(JSON.stringify(baseBarBrush)),
    company_plant: JSON.parse(JSON.stringify(baseBarBrush)),
  };
  /* monta estrutura necessária para os 3 tipos de gráficos */
  for (var prop in charts) {
    charts[prop].xAxis.data = data[prop].orderables;
    for (var field_type in barBrushChartFields) {
      charts[prop].series[field_type].data = data[prop][barBrushChartFields[field_type]] ?? data[prop].bill_pay_overdue;
    }
  }
  return charts;
};

export const changeTreemapChart = (data) => {
  let billReceiveChart = baseTreemapChart;
  let billPayChart = JSON.parse(JSON.stringify(baseTreemapChart));
  let invoiceChart = baseInvoiceTreemapChart;
  invoiceChart.options.series[0].data = data[2].pendings;
  invoiceChart.options.series[1].data = data[2].paids;
  billPayChart.options.series[0].data = data[0].overdues;
  billPayChart.options.series[1].data = data[0].pendings;
  billPayChart.options.series[2].data = data[0].paids;
  billReceiveChart.options.series[0].data = data[1].overdues;
  billReceiveChart.options.series[1].data = data[1].pendings;
  billReceiveChart.options.series[2].data = data[1].paids;
  return { billPayData: billPayChart.options, billReceiveData: billReceiveChart.options, invoiceData: invoiceChart.options };
};

export const calcTreemapItems = (payload, type) => {
  let final_data = {
    paids: [],
    overdues: [],
    pendings: [],
  };
  if (Array.isArray(payload[type]) && type !== "invoice") {
    for (var company_plant of payload[type]) {
      let company_plant_overdue = { ...{ children: [] }, ...company_plant };
      let company_plant_pending = { ...{ children: [] }, ...company_plant };
      let company_plant_paid = { ...{ children: [] }, ...company_plant };

      for (var entity of company_plant.items) {
        let values = [];
        values.push(parseFloat(entity.total_value));
        values.push(parseFloat(entity.total_value_paid));
        values.push(parseFloat(entity.total_value_pending));
        values.push("");
        values.push(parseFloat(entity.total_value_overdue));
        entity.company_plant_name = company_plant.company_plant_name;
        entity.value = values;

        if (entity.value[4] !== 0) {
          company_plant_overdue.children.push(entity);
        }
        if (entity.value[2] !== 0) {
          company_plant_pending.children.push(entity);
        }
        if (entity.value[1] !== 0) {
          company_plant_paid.children.push(entity);
        }
      }
      if (company_plant_overdue.children.length > 0) {
        final_data.overdues.push(company_plant_overdue);
      }
      if (company_plant_pending.children.length) {
        final_data.pendings.push(company_plant_pending);
      }
      if (company_plant_paid.children.length) {
        final_data.paids.push(company_plant_paid);
      }
    }
  } else if (Array.isArray(payload["invoice"])) {
    for (var company_plant of payload["invoice"]) {
      let copy = { ...{ children: [] }, ...company_plant };
      for (var entity of company_plant.items) {
        let values = [];
        values.push(parseFloat(entity.total_value));
        values.push(parseFloat(entity.formulation_value));
        values.push(parseFloat(entity.service_value));
        values.push("");
        values.push(parseFloat(entity.additional_value));
        values.push(parseFloat(entity.single_value));
        values.push(parseFloat(entity.canceled_value ?? 0));
        entity.company_plant_name = company_plant.company_plant_name;
        entity.value = values;
        copy.children.push(entity);
      }
      if (typeof copy.entities_uuids === "undefined") {
        final_data.paids.push(copy);
      } else {
        final_data.pendings.push(copy);
      }
    }
  }

  return final_data;
};
