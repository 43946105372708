<template>
  <div v-if="condition" style="background: white;margin-bottom: 1px;">
    <a class="dropdown-item new-default-black-font px-3"
      style="display: flex;align-items: center;font-size: 12px;"
      @click.prevent="handleClick">
      <img height="16" :src="icon" />{{ text }}
    </a>
  </div>
</template>

<script>
export default {
  name: 'page-header-button-item',
  props: {
    condition: {
      type: Boolean,
      default: true,
      description: 'Recebe uma condição para mostrar/esconder o item do dropdown.',
    },
    text: {
      type: String,
      default: 'novo',
      description: 'Define o texto que será exibido no item do dropdown.',
    },
    link: {
      type: String,
      default: '',
      description: 'Ação ou link que será executada pelo item do dropdown.',
    },
    icon: {
      type: String,
      default: '/img/icons/plus-math--v1-white.png',
      description: 'Define o ícone que é exibido ao lado do texto no item do dropdown.',
    }
  },
  methods: {
    handleClick(evt) {
      if(!this.link){
        this.$emit('click', evt);
      } else {
        this.$router.push(this.link);
      }
    }
  },
};
</script>
