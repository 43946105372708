<template>
  <div class="row align-items-center">
    <div class="col-md-12 text-center">
      <div :class="withCard ? 'card' : ''" class="p-0 pt-4 text-muted" style="text-transform: none !important;border-radius: 0 !important;">
<!--        <img-->
<!--             style="width: 100px; opacity: 60%"-->
<!--             src="https://cdn3.iconfinder.com/data/icons/aami-web-internet/64/aami4-60-512.png"/>-->
          <img style="width: 300px" class="img-fluid img-center mb-3"
               src="/img/icons/result-not-found.jpeg">
        <h4 style="font-weight: 400 !important;font-size: 12px" class="text-uppercase mb-3">{{ msg }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PuzlEmptyData',
  props: {
    withCard: {
      required: false,
      default: true,
    },
    msg: {
      required: false,
      default: 'Nenhum resultado encontrado com o filtro selecionado!',
      type: String
    }
  },
  components: {
  },
  data(){
    return {
    }
  },
  updated () {
  },
}
</script>

<style scoped>

</style>
