import { SapConfigurationSyncType } from "../types";
import { sapConfigurationSyncService, sapConfigurationFindService } from "../services";
import mutations from "./sapConfigurationStoreMutations";

/**
 * @param {SapConfigurationSyncType} data
 * @returns {Promise}
 */
const sync = async (data) => await sapConfigurationSyncService.execute(data);

/**
 * @returns {Promise<SapConfigurationSyncType>}
 */
const find = async () => {
  const item = await sapConfigurationFindService.execute();
  mutations.setItem(item);
  return item;
};

export default {
  sync,
  find,
};
