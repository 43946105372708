<template>
  <div>
    <div class="container-fluid">
      <multi-filter ref="multi-filter" @fetch="init" :filter="filter"/>
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <PuzlEmptyData v-if="!$_payment_intermediaries.length && !loadingSkeleton">
      </PuzlEmptyData>

      <div
        class="row card-wrapper"
        v-if="$_payment_intermediaries.length && !loadingSkeleton"
      >
        <div
          class="col-lg-4"
          v-for="(item, index) in $_payment_intermediaries"
          :key="index"
        >
          <card :class="item.status ? 'card-border-top-success' : 'card-border-top-danger'">
            <!-- Card header -->
            <div class="row align-items-center mb-3">
              <div class="col-8">
                <h5 class="h3 mb-2 mt-1">
                  {{ item.name }}
                </h5>
              </div>
              <div class="col-4 text-right">
                <base-dropdown menuOnRight>
                  <base-button
                    slot="title-container"
                    type="primary"
                    class="dropdown-toggle p-2 rounded m-0"
                  >
                    <i class="fas fa-cog"></i>
                  </base-button>
                  <a class="dropdown-item" @click="handleEdit(item.id)">
                    <i class="fas fa-edit text-warning"></i>
                    Editar
                  </a>
                  <a class="dropdown-item" @click="handleMerchantsCreateOrEdit(item.id)">
                    <i class="fas fa-2x fa-hard-drive text-default"></i>
                      Pontos de Venda
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleDelete(item.id)">
                    <i class="fas fa-times text-danger"></i>
                    Excluir
                  </a>
                </base-dropdown>
              </div>
            </div>

            <div class="card-header border-header-default-bottom p-2 grid-col--fixed-left">
              <h5 class="mb-0" slot="title">Débito</h5>
            </div>
            <div class="row p-0 pt-2">
              <div class="col-6 text-center text-uppercase pt-2 pr-1">
                <h5 style="font-size: 0.6rem">Prazo de recebimento</h5>
              </div>
              <div class="col-3 text-center text-uppercase pt-2 pl-1 pr-0">
                <h5 style="font-size: 0.6rem">Taxa (%)</h5>
              </div>
              <div class="col-3 text-center text-uppercase pt-2 pl-1 pr-2">
                <h5 style="font-size: 0.6rem">Taxa (R$)</h5>
              </div>
            </div>
            <hr class="m-0 pb-1" type="primary"/>
            <div class="pb-3">
              <div
                class="row p-0"
              >
                <div class="col-6 text-center pr-1">
                  <small>
                    {{ item.debit.deadline }} dia(s)
                  </small>
                </div>
                <div class="col-3 text-center pl-1 pr-0">
                  <small> {{ item.debit.tax_percentage }}</small>
                </div>
                <div class="col-3 text-center pl-1 pr-0">
                  <small> {{ item.debit.tax_monetary }}</small>
                </div>
              </div>
            </div>
            <div class="card-header border-header-default-bottom p-2 grid-col--fixed-left">
              <h5 class="mb-0" slot="title"> Crédito à vista
              </h5>
            </div>
            <div class="row p-0 pt-2">
              <div class="col-6 text-center text-uppercase pt-2 pr-1">
                <h5 style="font-size: 0.6rem">Prazo de recebimento</h5>
              </div>
              <div class="col-3 text-center text-uppercase pt-2 pl-1 pr-0">
                <h5 style="font-size: 0.6rem">Taxa (%)</h5>
              </div>
              <div class="col-3 text-center text-uppercase pt-2 pl-1 pr-2">
                <h5 style="font-size: 0.6rem">Taxa (R$)</h5>
              </div>
            </div>
            <hr class="m-0 pb-1" type="primary"/>
            <div class="pb-3">
              <div
                class="row p-0"
              >
                <div class="col-6 text-center pr-1">
                  <small>
                    {{ item.credit_on_sight.deadline }} dia(s)
                  </small>
                </div>
                <div class="col-3 text-center pl-1 pr-0">
                  <small> {{ item.credit_on_sight.tax_percentage }}</small>
                </div>
                <div class="col-3 text-center pl-1 pr-0">
                  <small> {{ item.credit_on_sight.tax_monetary }}</small>
                </div>
              </div>
            </div>

            <div class="card-header border-header-default-bottom p-2 grid-col--fixed-left">
              <h5 class="mb-0" slot="title"> Crédito parcelado
              </h5>
            </div>
            <div class="row p-0 pt-2">
              <div class="col-12 text-left text-uppercase pt-2 pr-1">
                <h5 style="font-size: 0.6rem"> Método de recebimento :
                  {{ receipt_methods[item.credit_in_installments.receipt_method].name }}
                </h5>
              </div>
              <div class="col-6 text-center text-uppercase pt-2 pr-1">
                <h5 style="font-size: 0.6rem">Prazo de recebimento</h5>
              </div>
              <div class="col-3 text-center text-uppercase pt-2 pl-1 pr-0">
                <h5 style="font-size: 0.6rem">Taxa (%)</h5>
              </div>
              <div class="col-3 text-center text-uppercase pt-2 pl-1 pr-2">
                <h5 style="font-size: 0.6rem">Taxa (R$)</h5>
              </div>
            </div>
            <hr class="m-0 pb-1" type="primary"/>
            <div class="pb-3">
              <div
                class="row p-0"
              >
                <div class="col-6 text-center pr-1">
                  <small>
                    {{ item.credit_in_installments.deadline }} dia(s)
                  </small>
                </div>
                <div class="col-3 text-center pl-1 pr-0">
                  <small> {{ item.credit_in_installments.tax_percentage }}</small>
                </div>
                <div class="col-3 text-center pl-1 pr-0">
                  <small> {{ item.credit_in_installments.tax_monetary }}</small>
                </div>
              </div>
            </div>
            <collapse class="border rounded p-0 mt-3">
              <collapse-item
                class="header-gray"
                back-ground="border-header-primary-bottom"
              >
                <h5 slot="title" class="mb-0 ">Adicional por parcela</h5>
                <div class="row p-0 pt-2">
                  <div class="col-6 text-center text-uppercase pt-2 pr-1">
                    <h5 style="font-size: 0.6rem">Parcela</h5>
                  </div>
                  <div class="col-3 text-center text-uppercase pt-2 pl-1 pr-0">
                    <h5 style="font-size: 0.6rem">Taxa (%)</h5>
                  </div>
                  <div class="col-3 text-center text-uppercase pt-2 pl-1 pr-2">
                    <h5 style="font-size: 0.6rem">Taxa (R$)</h5>
                  </div>
                </div>
                <hr class="m-0 pb-1" type="primary"/>
                <div class="pb-3">
                  <div
                    v-for="installment in item.additional_installments"
                    class="row p-0"
                  >
                    <div class="col-6 text-center pr-1">
                      <small>
                        {{ installment.installment }}
                      </small>
                    </div>
                    <div class="col-3 text-center pl-1 pr-0">
                      <small>
                        {{ installment.tax_percentage }}
                      </small>
                    </div>
                    <div class="col-3 text-center pl-1 pr-0">
                      <small>
                        {{ installment.tax_monetary }}
                      </small>
                    </div>
                  </div>
                </div>
              </collapse-item>
            </collapse>
            <template v-if="false">
              <!-- Início Débito -->
              <collapse class="border rounded p-0 mt-3">
                <collapse-item
                  class="header-gray"
                  back-ground="border-header-primary-bottom"
                >
                  <h5 slot="title" class="mb-0 ">Débito</h5>
                  <div class="row p-0 pt-2">
                    <div class="col-6 text-center text-uppercase pt-2 pr-1">
                      <h5 style="font-size: 0.6rem">Prazo de recebimento</h5>
                    </div>
                    <div class="col-3 text-center text-uppercase pt-2 pl-1 pr-0">
                      <h5 style="font-size: 0.6rem">Taxa (%)</h5>
                    </div>
                    <div class="col-3 text-center text-uppercase pt-2 pl-1 pr-2">
                      <h5 style="font-size: 0.6rem">Taxa (R$)</h5>
                    </div>
                  </div>
                  <hr class="m-0 pb-1" type="primary"/>
                  <div class="pb-3">
                    <div
                      class="row p-0"
                    >
                      <div class="col-6 text-center pr-1">
                        <small>
                          {{ item.debit.deadline }} dia(s)
                        </small>
                      </div>
                      <div class="col-3 text-center pl-1 pr-0">
                        <small> {{ item.debit.tax_percentage }}</small>
                      </div>
                      <div class="col-3 text-center pl-1 pr-0">
                        <small> {{ item.debit.tax_monetary }}</small>
                      </div>
                    </div>
                  </div>
                </collapse-item>
              </collapse>
              <!-- Fim Débito -->
              <!-- Início Crédito à vista -->
              <collapse class="border rounded p-0 mt-3">
                <collapse-item
                  class="header-gray"
                  back-ground="border-header-primary-bottom"
                >
                  <h5 slot="title" class="mb-0 ">Crédito à vista</h5>
                  <div class="row p-0 pt-2">
                    <div class="col-6 text-center text-uppercase pt-2 pr-1">
                      <h5 style="font-size: 0.6rem">Prazo de recebimento</h5>
                    </div>
                    <div class="col-3 text-center text-uppercase pt-2 pl-1 pr-0">
                      <h5 style="font-size: 0.6rem">Taxa (%)</h5>
                    </div>
                    <div class="col-3 text-center text-uppercase pt-2 pl-1 pr-2">
                      <h5 style="font-size: 0.6rem">Taxa (R$)</h5>
                    </div>
                  </div>
                  <hr class="m-0 pb-1" type="primary"/>
                  <div class="pb-3">
                    <div
                      class="row p-0"
                    >
                      <div class="col-6 text-center pr-1">
                        <small>
                          {{ item.credit_on_sight.deadline }} dia(s)
                        </small>
                      </div>
                      <div class="col-3 text-center pl-1 pr-0">
                        <small> {{ item.credit_on_sight.tax_percentage }}</small>
                      </div>
                      <div class="col-3 text-center pl-1 pr-0">
                        <small> {{ item.credit_on_sight.tax_monetary }}</small>
                      </div>
                    </div>
                  </div>
                </collapse-item>
              </collapse>
              <!-- Fim Crédito à vista -->
              <!-- Início Crédito parcelado -->
              <collapse class="border rounded p-0 mt-3">
                <collapse-item
                  class="header-gray"
                  back-ground="border-header-primary-bottom"
                >
                  <h5 slot="title" class="mb-0 ">Crédito parcelado</h5>
                  <div class="row p-0 pt-2">
                    <div class="col-12 text-left text-uppercase pt-2 pr-1">
                      <h5 style="font-size: 0.6rem"> Método de recebimento :
                        {{ receipt_methods[item.credit_in_installments.receipt_method].name }}
                      </h5>
                    </div>
                    <div class="col-6 text-center text-uppercase pt-2 pr-1">
                      <h5 style="font-size: 0.6rem">Prazo de recebimento</h5>
                    </div>
                    <div class="col-3 text-center text-uppercase pt-2 pl-1 pr-0">
                      <h5 style="font-size: 0.6rem">Taxa (%)</h5>
                    </div>
                    <div class="col-3 text-center text-uppercase pt-2 pl-1 pr-2">
                      <h5 style="font-size: 0.6rem">Taxa (R$)</h5>
                    </div>
                  </div>
                  <hr class="m-0 pb-1" type="primary"/>
                  <div class="pb-3">
                    <div
                      class="row p-0"
                    >
                      <div class="col-6 text-center pr-1">
                        <small>
                          {{ item.credit_in_installments.deadline }} dia(s)
                        </small>
                      </div>
                      <div class="col-3 text-center pl-1 pr-0">
                        <small> {{ item.credit_in_installments.tax_percentage }}</small>
                      </div>
                      <div class="col-3 text-center pl-1 pr-0">
                        <small> {{ item.credit_in_installments.tax_monetary }}</small>
                      </div>
                    </div>
                  </div>
                </collapse-item>
              </collapse>
              <!-- Fim Crédito parcelado -->
            </template>
            <div class="row mt-2" v-if="item.user">
              <div class="col-lg-12 text-center">
                <small>Ultima atualização feita por <b>{{ item.user.name }}</b>. <br> {{ item.updated_at | parseDate }}
                </small>
              </div>
            </div>
            <!-- Início Parcelas-->
            <!--            <collapse class="border rounded p-0 mt-3">-->
            <!--              <collapse-item-->
            <!--                name="1"-->
            <!--                class="header-gray card-body-molde"-->
            <!--                back-ground="border-header-danger-bottom"-->
            <!--              >-->
            <!--                <h5 slot="title" class="mb-0">Parcelas</h5>-->
            <!--                <div class="pl-4 pr-4">-->
            <!--                  &lt;!&ndash; Titles &ndash;&gt;-->
            <!--                  <div class="row pt-2">-->
            <!--                    <div class="col-4 text-center pt-2 pr-1">-->
            <!--                      <h5>Parcela</h5>-->
            <!--                    </div>-->
            <!--                    <div class="col-4 text-center pt-2 pl-1 pr-0">-->
            <!--                      <h5>Prazo</h5>-->
            <!--                    </div>-->
            <!--                    <div class="col-4 text-center pt-2 pl-1 pr-2">-->
            <!--                      <h5>%</h5>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                  <hr class="m-0 pb-1" type="primary" />-->
            <!--                  &lt;!&ndash; Body &ndash;&gt;-->
            <!--                  <div class="pb-3">-->
            <!--                    <div-->
            <!--                      class="row"-->
            <!--                      v-for="(installment, index) in item.installments"-->
            <!--                      :key="index"-->
            <!--                    >-->
            <!--                      <div class="col-4 text-center pr-1">-->
            <!--                        <small>-->
            <!--                          {{ installment.index }}-->
            <!--                        </small>-->
            <!--                      </div>-->
            <!--                      <div class="col-4 text-center pl-1 pr-0">-->
            <!--                        <small>{{ installment.deadline }} {{ installment.deadline === 1 ? 'Dia' : 'Dias' }} </small>-->
            <!--                      </div>-->
            <!--                      <div class="col-4 text-center pl-1 pr-0">-->
            <!--                        <small>{{ installment.percentage }}%</small>-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </collapse-item>-->
            <!--            </collapse>-->
            <!-- Fim Parcelas-->
          </card>
        </div>
      </div>
    </div>
    <modal-edit ref="modalEdit"/>
    <modal-merchants-create-or-edit ref="modalMerchantsCreateOrEdit"/>
    <loading-pagination :show="loading && !loadingSkeleton"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import ModalEdit from "./_ModalEdit.vue";
import ModalMerchantsCreateOrEdit from "./_ModalMerchantsCreateOrEdit.vue";
import MultiFilter from "@/components/Utils/MultiFilterV2";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";

export default {
  name: "ListPaymentIntermediary",
  mixins: [cursorPaginate],
  components: {
    SkeletonPuzlGrid,
    PuzlEmptyData,
    ModalEdit,
    ModalMerchantsCreateOrEdit,
    MultiFilter,
    LoadingPagination,
  },
  data() {
    return {
      receipt_methods: [
        {
          id: 0,
          name: 'Acumulado',
        },
        {
          id: 1,
          name: 'Parcelado',
        }
      ],
      loadingSkeleton: false,
      loadingPuzlConnect: false,
      filter: {},
    };
  },
  computed: {
    ...mapGetters({
      $_payment_intermediaries: "paymentIntermediary/fetch"
    })
  },
  methods: {
    init(filter = null) {
      this.startCursor(filter)
      this.$Progress.start();
      this.$store
        .dispatch("paymentIntermediary/fetchItemsPaginate", {
          filter: this.filter,
          next_page: this.paginate.nextUrl
        })
        .then(response => {
          this.resolveCursor(response)
          this.$Progress.finish();
        })
        .catch(error => {
          this.resolveCursor()
          this.$Progress.finish();
        });
    },
    handleEdit(id) {
      this.$refs.modalEdit.openModal(id);
    },
    handleDelete(id) {
      this.$Swal
        .confirmDelete()
        .then(result => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação."
            });
            this.$store.dispatch("paymentIntermediary/destroy", id).then(response => {
              this.$notify({
                type: response.error_type,
                message: response.message
              });
              this.$Progress.finish();
            }).catch(error => {
              this.$Progress.finish();
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              });
            });
          }
        })
        .catch(() => this.$Progress.finish());
    },
    handleMerchantsCreateOrEdit(id){
      this.$refs.modalMerchantsCreateOrEdit.openModal(id);  
    }
  },
  created() {
    this.init({});
  }
};
</script>

<style scoped></style>
