<template>
  <div>
    <modal :show.sync="modal.open">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(store)"
            autocomplete="off"
          >
            <div>
              <div class="form-group row m-0 p-0 pt-2">
                <label
                  class="col-md-4 pt-1 pb-3 mb-1 col-form-label form-control-label text-left"
                >
                  Central
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8 pb-3 pr-4">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-classes="form-control-sm">
                      <puzl-select
                        v-model="adjustment.company_plant_uuid"
                        :items="plants"
                        customKey="uuid"
                        :multiple="true"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 col-form-label form-control-label pb-0">
                  Dia inteiro
                </label>
                <div class="col-md-8 pt-2">
                  <base-switch
                    v-model="adjustment.is_day"
                    type="primary"
                  ></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0 pt-2">
                <label
                  class="col-md-4 pt-1 pb-3 mb-1 col-form-label form-control-label text-left pr-0"
                >
                  Maior que
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7 pb-3 pl-3">
                  <base-input input-group-classes="input-group-sm">
                    <input-time-picker
                      :disabled="disabledDay"
                      v-model="adjustment.hour_min"
                    />
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-4 pt-1 pb-3 mb-0 col-form-label form-control-label text-left pr-0"
                >
                  Menor ou igual a
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7 pl-3">
                  <base-input input-group-classes="input-group-sm">
                    <input-time-picker
                      :disabled="disabledDay"
                      v-model="adjustment.hour_max"
                    />
                  </base-input>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">
                Cancelar
              </base-button>
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                :loading="loadingStore"
              >
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InputTimePicker from "@/components/InputTimePicker";
import PuzlSelect from "@/components/PuzlSelect";
import { checkNumberValue } from "@/helpers";

import { mask1, formatErrorValidation } from "@/plugins";

export default {
  name: "ModalEditAdjustment",
  components: { InputTimePicker, PuzlSelect },
  data() {
    return {
      modal: {
        title: "Ajuste por Contrato e Horário",
        open: false,
      },
      adjustment: {
        company_plant_uuid: [],
        is_day: false,
        hour_min: null,
        hour_max: null,
        contract_proposal_formulation_uuid: null,
        mix_code: null,
        contract_proposal_uuid: null,
      },
      disabledDay: false,
      loadingStore: false,
      secondSelect: false,
    };
  },
  computed: {
    ...mapGetters({
      plants: "plant/fetch",
    }),
  },
  watch: {
    "adjustment.is_day"() {
      if (this.adjustment.is_day) {
        this.disabledDay = true;
        this.adjustment.hour_min = "00:00";
        this.adjustment.hour_max = "23:59";
      } else {
        this.disabledDay = false;
        this.adjustment.hour_min = null;
        this.adjustment.hour_max = null;
      }
    },
  },
  methods: {
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        value = mask1(
          checkNumberValue(value, min, max).toString(),
          decimals,
          ".",
          true
        );
        return checkNumberValue(value, min, max);
      }
      return checkNumberValue(value, min, max);
    },
    closeModal() {
      this.modal.open = false;
    },
    handleEditModal(uuid) {
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });
      this.$Progress.start();
      this.$store
        .dispatch("adjustmentContractHour/show", uuid)
        .then((response) => {
          let plants = [];
          response.data.company_plant.map(function (value, key) {
            plants.push(value.uuid);
          });

          this.adjustment = {
            company_plant_uuid: plants || [],
            is_day: response.data.is_day || false,
            hour_min: response.data.hour_min || null,
            hour_max: response.data.hour_max || null,
            contract_proposal_formulation_uuid:
              response.data.contract_proposal_formulation_uuid || null,
            mix_code: response.data.mix_code || null,
            contract_proposal_uuid:
              response.data.contract_proposal_uuid || null,
            uuid: response.data.uuid || null,
          };
          this.$Progress.finish();
          this.modal.open = true;
        })
        .catch((error) => {
          if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
          this.$Progress.finish();
          this.loadingStore = false;
        });
    },
    store() {
      this.$Progress.start();
      this.loadingStore = true;
      this.$store
        .dispatch("adjustmentContractHour/update", this.adjustment)
        .then((response) => {
          this.loadingStore = false;
          this.modal.open = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.$emit("updatedAdjustment");
        })
        .catch((error) => {
          if (error.status == 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
            this.$Progress.finish();
            this.loadingStore = false;
          } else if (error.response && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
          this.$Progress.finish();
          this.loadingStore = false;
        });
    },
  },
};
</script>

<style scoped></style>
