<template>
  <div class="row mb-3">
    <div class="col-12 mt-md-0 mt-3">
      <div class="row m-0">
        <div class="col-md-4 p-0">
          <base-button
            @click.prevent="handleTabValue('entry')"
            class="custom-navegation-btn rounded-pill-left text-uppercase"
            type="primary"
            outline
            size="sm"
            block
            :class="{ active: tab_value === 'entry' }"
          >
            <img
              height="20px"
              :src="`/img/icons/icons8/ios/info-squared${tab_value !== 'entry' ? '_warning.png' : '_white.png'}`"
              class="mr-2"
            />
            Lançamento
          </base-button>
        </div>
        <div class="col-md-4 p-0 mt-md-0 mt-3">
          <base-button
            @click.prevent="handleTabValue('resume')"
            class="custom-navegation-btn rounded-md-0 text-uppercase border-radius-mobile"
            type="primary"
            outline
            size="sm"
            block
            :class="{ active: tab_value === 'resume' }"
          >
            <img height="20px" :src="`/img/icons/icons8/ios/menu${tab_value !== 'resume' ? '_dark.png' : '.png'}`" class="mr-2" />
            Resumo
          </base-button>
        </div>
        <div class="col-md-4 p-0 mt-md-0 mt-3">
          <base-button
            @click.prevent="handleTabValue('attachment')"
            class="custom-navegation-btn rounded-pill-right text-uppercase"
            type="primary"
            outline
            size="sm"
            block
            :class="{ active: tab_value === 'attachment' }"
          >
            <img height="19px" :src="`/img/icons/icons8/ios/attachment${tab_value === 'attachment' ? '.png' : '_dark.png'}`" class="mr-2" />
            Anexo
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navegation",
  props: {
    tab_value: {
      type: String,
    },
  },
  methods: {
    handleTabValue(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style scoped>
.custom-navegation-btn {
  border-color: rgba(0, 0, 0, 0.05);
  padding: 0.45rem 0.5rem;
  line-height: normal !important;
  align-items: center !important;
  -webkit-box-align: center !important;
  display: flex !important;
  color: #2b2d32;
}
.custom-navegation-btn:disabled {
  color: #2b2d32;
}
.custom-navegation-btn:not(.active):hover {
  background-color: transparent !important;
  color: #2b2d32 !important;
  border-color: transparent !important;
}
@media (min-width: 576px) {
  .rounded-md-0 {
    border-radius: 0px !important;
  }
  .rounded-pill-left {
    border-top-left-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }
  .rounded-pill-right {
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
  }
}
</style>
