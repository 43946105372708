<template>
  <div>
    <modal size="xl" :show.sync="show">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ title }}</h5>
      </template>
      <multi-filter :without_filter="true" @fetch="load" :filter="filter">
        <div class="col-md-4 p-2">
          <label>Central</label>
          <base-input input-classes="form-control">
            <puzl-select
              class="select-lg"
              v-model="filter.company_plant_id"
              :items="$_plants"
              @change="load(filter)"
              :loading="loadingPlant"
              :disabled="loadingPlant"/>
          </base-input>
        </div>
        <div class="col-md-4 p-2">
          <label>Conta bancária</label>
          <base-input input-classes="form-control">
            <puzl-select
              class="select-lg"
              v-model="filter.bank_account_id"
              :items="$_bank_accounts"
              @change="load(filter)"
              :loading="loadingBankAccounts"
              :disabled="loadingBankAccounts"/>
          </base-input>
        </div>
        <div class="col-md-4 p-2">
          <label>Data de faturamento</label>
          <input-date-picker
            size="small"
            :defaultRange="range" ref="input-date-picker" @handleFilterDate="handleFilterDate"/>
        </div>
        <div slot="after">
          <div class="row">
            <div class="col-md-12 pl-2 text-left float-left">
              <base-button type="primary"
                           size="sm"
                           class="text-uppercase"
                           @click.prevent="selectAll()"
                           outline>
                Selecionar todos <span style="font-size: 0.65rem"
                                       class="badge badge-light text-primary"> {{ selecteds.length }} /
              {{ $_pending_bank_billets.length }}</span>
              </base-button>
            </div>
          </div>
        </div>
      </multi-filter>
      <!--      <div v-show="!loadingSkeleton && $_pending_bank_billets.length > 0" class="table-responsive"-->
      <!--           style="overflow-x: hidden">-->
      <!--        <div class="row card-wrapper" style="max-height: 350px">-->
      <!--          <div class="col-md-6" v-for="(item, index) in $_pending_bank_billets" :key="index">-->
      <!--            <card>-->

      <!--            </card>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <card style="overflow: auto;margin-top: -20px">
        <div class="row" v-if="!loadingSkeleton && $_pending_bank_billets.length"
             style="margin-top: 15px;max-height: 40vh">
          <div class="col-md-4 p-1 card-row" v-for="(item, index) in $_pending_bank_billets" :key="index">
            <card style="min-height: 158px">
              <div class="row card-header pl-2 pr-0 pt-0 mt-0">
                <div class="pl-1 col-md-10 col-10">
                  <div>
                    <h3 class="display-4" style="font-size: 1.2rem">{{ item.contract_proposal_code }}</h3>
                  </div>
                  <div class="mt-n2">
                    <span>{{ item.customer_name }}</span>
                  </div>
                  <div class="mt-n1" v-if="!isEqual(item.customer_name, item.construction_name)">
                    <span>{{ item.construction_name }}</span>
                  </div>
                </div>
                <div class="col-md-2 col-2">
                  <base-button
                    size="sm"
                    @click.prevent="checkDueDate(item)"
                    v-show="!selecteds.includes(item.id)"
                    outline type="primary">
                    <i class="fa-regular fa-circle-check"></i>
                  </base-button>
                  <base-button
                    size="sm"
                    @click.prevent="selecteds = selecteds.filter(selected => selected !== item.id)"
                    v-show="selecteds.includes(item.id)"
                    type="primary">
                    <i class="fa-regular fa-circle-check text-white"></i>
                  </base-button>
                </div>
              </div>
              <div class="pl-1 pt-2">
                <div class="row pl-2">
                  <div class="col-md-12 p-0 pl-0 m-0">
                    <badge style="font-size: 1.1rem" class="text-warning font-weight-500" type="light">
                      {{ item.total_value | currency() }}
                    </badge>
                    <a
                      @click.prevent="handleEditBillReceive(item.bill_receive_id, 1, 0)"
                      href="#"
                      role="button"
                      class="custom-border-radius ">
                      <i style="font-size: 1.2rem" class="text-muted fas fa-edit"></i>
                    </a>
                  </div>
                  <div class="col-md-12 p-0 pl-1 m-0">
                  <span>
                    <span style="text-transform: none"> FATURA(s) : <span class="font-weight-500">{{
                        item.invoices_ids
                      }} </span>
                </span>
                  </span>
                  </div>
                  <div class="col-md-12 p-0 pl-1 m-0">
                  <span style="text-transform: none">BOLETO <span class="font-weight-500"> {{ item.payment_term }}
                  </span>
                  </span>
                  </div>
                  <div class="col-md-12 p-0 pl-1 m-0">
                  <span>
                    {{ item.bank_account }} ({{ item.ein }})
                  </span>
                  </div>
                </div>
              </div>
              <collapse class="border rounded p-0 mt-3">
                <collapse-item>
                  <h5 slot="title" class="mb-0 ">Parcelas</h5>
                  <div class="col-md-12">
                    <div v-for="(installment, indexInstallment) in item.installments"
                         class="timeline timeline-one-side">
                      <div class="timeline-block">
                        <span class="timeline-step" :class="installment.status == 1 ? 'badge-success' : 'badge-warning'">
                          <i class="fa-solid fa-arrow-rotate-left"></i>
                        </span>
                        <div class="timeline-content">
                          <div class="row">
                            <small class="col-md-10 text-muted font-weight-bold">({{
                                installment.index
                              }}/{{ item.installment_count }})</small>
                            <a
                              @click.prevent="$refs.ModalChangeDueDate.handleCreateModal(installment.id, installment.original_due_date, {
                                index: index,
                                indexInstallment: indexInstallment
                              })"
                              href="#"
                              role="button"
                              class="custom-border-radius pl-1 col-md-2">
                              <i class="fa-solid fa-calendar-days"></i>
                            </a>
                          </div>
                          <h4 class=" mb-0" style="font-family: Gilroy,sans-serif !important">
                            {{ installment.total_value | currency() }}</h4>
                          <p style="margin-top: -0.5rem !important;">
                            <small style="font-family: Gilroy,sans-serif !important">Vencimento:
                              {{ installment.due_date | parseDate('DD/MM/YYYY') }}</small>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </collapse-item>
              </collapse>
            </card>
          </div>
        </div>
        <div class="row p-0" v-show="loadingSkeleton" style="max-height: 300px">
          <skeleton-puzl-grid v-for="item in 6" classCard="col-md-4 p-1 card-row"/>
        </div>
        <div class="row p-0" v-if="!loadingSkeleton && !$_pending_bank_billets.length">
          <div class="col-md-12">
            <puzl-empty-data :with-card="false"/>
          </div>
        </div>
      </card>
      <div class="col-md-12 mt-2 text-right p-0">
        <base-button @click.prevent="close" type="secondary" class="text-capitalize"> Cancelar</base-button>
        <base-button
          :loading="loading"
          @click.prevent="issuerBankBillets"
          type="primary"
          native-type="submit"
        >
          <span class="btn-label"><i class="fa-solid fa-diagram-predecessor"></i></span>
          Emitir
        </base-button>
      </div>
    </modal>
    <EditBillReceive @updated="load(filter)" ref="editBillReceive"/>
    <modal-change-due-date @updated="updateInstallment" ref="ModalChangeDueDate"/>
  </div>
</template>

<script>
import MultiFilter from "@/components/Utils/MultiFilterV2";
import InputDatePicker from "@/components/InputDatePicker";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import {mapGetters} from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlSelect from "@/components/PuzlSelect";
import EditBillReceive from "@/views/Modules/Financial/BillReceive/Shared/Modals/_Edit.vue";
import ModalChangeDueDate from '../../../Financial/BillReceive/Shared/Modals/_ModalChangeDueDate.vue'
import moment from "moment"

export default {
  props: ["show"],
  name: "ModalGeneratePendingBankBillet",
  components: {
    SkeletonPuzlGrid,
    MultiFilter,
    InputDatePicker,
    PuzlEmptyData,
    PuzlSelect,
    EditBillReceive,
    ModalChangeDueDate
  },
  data() {
    return {
      loading: false,
      loadingSkeleton: false,
      loadingBankAccounts: false,
      title: 'Emissão de boletos pendentes',
      selecteds: [],
      loadingPlant: false,
      filter: {},
      range: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
      },
    }
  },
  watch: {
    show: function () {
      this.selecteds = []
      this.loadingPlant = true
      this.$store.dispatch('plant/fetchItemsActive').then(() => {
        this.loadingPlant = false
      })
      this.$refs["input-date-picker"].resetDate()
      this.loadingBankAccounts = true
      this.$store.dispatch('bankAccount/fetchItems', {})
        .then(() => {
          this.loadingBankAccounts = false
        })
      this.range = {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
      }
    }
  },
  computed: {
    ...mapGetters({
      $_plants: 'plant/activeItems',
      $_bank_accounts: 'bankAccount/fetch',
      $_pending_bank_billets: "billingInvoice/getListPendingBankBillets",
    }),
  },
  methods: {
    updateInstallment(params) {
      this.$_pending_bank_billets[params.index].installments[params.indexInstallment].due_date = params.due_date
    },
    handleEditBillReceive(bill_receive_id, type, status) {
      this.$refs.editBillReceive.handleCreateModal(bill_receive_id, type, status)
    },
    handleFilterDate(range) {
      this.range = range
      this.load(this.filter)
    },
    issuerBankBillets() {
      this.loading = true
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch('billingInvoice/postIssuerBankBilletByPaymentIds', this.selecteds)
        .then(response => {
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.close(true)
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.$notify({type: error.data.error_type, message: error.data.message})
        });
    },
    /**
     * Verifica se alguma data de vencimento é menor que a data atual.
     * Se sim, exibe um alerta. Se não, adiciona o item selecionado ao array `selecteds`.
     *
     * @param {object} pending_bank_billet Item selecionado no loop v-for
     */
    checkDueDate(pending_bank_billet) {
      const today = moment();
      const hasDueDateBeforeToday = pending_bank_billet.installments.some(installment => {
        if (installment.status === 1) {
          return
        }
        const dueDate = moment(installment.due_date);
        return dueDate.isBefore(today, 'day');
      });
      if (hasDueDateBeforeToday) {
        this.$notify({
          type: 'warning',
          message: "Data de vencimento das parcelas não pode ser menor que hoje"
        });
      } else {
        this.selecteds.push(pending_bank_billet.id);
      }
    },
    selectAll() {
      if (this.selecteds.length === this.$_pending_bank_billets.length) {
        return this.selecteds = []
      }
      this.$_pending_bank_billets.map((item) => {
        if (!this.selecteds.includes(item.id)) {
          this.selecteds.push(item.id)
        }
      }, this)
    },
    isEqual(a, b) {
      a = a.toString().toLowerCase().trim()
      b = b.toString().toLowerCase().trim()
      return a === b
    },
    load(filter) {
      this.filter = filter
      this.filter.range = this.range
      this.loadingSkeleton = true
      this.$store.dispatch('billingInvoice/getPendingBankBillets', {
        filter: this.filter
      }).then(() => {
        this.loadingSkeleton = false
      }).catch(() => {
        this.loadingSkeleton = false
      })
    },
    close(modalResult) {
      this.$emit('close', modalResult)
    }
  }
};
</script>

<style></style>
