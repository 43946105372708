<template>
  <div>
    <modal :show.sync="modal" size="md">
      <template slot="header">
        <h5 class="modal-title ">{{ title }}</h5>
      </template>
      <div v-if="invoice">
        <div class="row">
          <div class="col-md-12">
            <h4 class="display-4" style="font-size: 1.2rem">{{ invoice.contract_proposal.code }}</h4>
            <h4 class="small">{{ invoice.contract_proposal.construction.customer_construction.customer_name }}</h4>
            <h4 class="small">{{ invoice.contract_proposal.construction.construction_name }}</h4>
          </div>
        </div>
        <hr style="margin-top: 5px;margin-bottom: 15px">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <div class="row">
            <div class="col-md-12">
              <base-input>
                <input 
                  :disabled="!(invoice.nfse && invoice.nfse.link_pdf) && !has_billet && invoice.invoice_attachments.length == 0"
                  v-model="email.invoice" 
                  type="checkbox" 
                  class="mr-2"
                /> <span style="font-size: 0.85rem">Fatura</span>
              </base-input>
            </div>
            <div v-show="invoice.nfse && invoice.nfse.link_pdf" class="col-md-12 mt-1">
              <base-input>
                <input v-model="email.nfse" type="checkbox" class="mr-2"
                /> <span style="font-size: 0.85rem">Nota fiscal de serviço eletrônica</span>
              </base-input>
            </div>
            <div class="col-md-12 mt-1" v-show="has_billet">
              <base-input>
                <input v-model="email.bank_billet" type="checkbox" class="mr-2"
                /> <span style="font-size: 0.85rem">Boletos</span>
              </base-input>
            </div>
            <div class="col-md-12 mt-1">
              <base-input>
                <input v-model="email.entity_include" type="checkbox" class="mr-2"
                /> <span style="font-size: 0.85rem">Incluir envio para o e-mail do tomador</span>
              </base-input>
            </div>
            <div class="col-md-12 mt-1">
              <base-input>
                <input v-model="email.seller_include" type="checkbox" class="mr-2"
                /> <span style="font-size: 0.85rem">Incluir envio para o e-mail do vendedor</span>
              </base-input>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-12 mb-4">
              <label class="form-control-label">Mensagem do e-mail</label>
              <textarea v-model="email.text" maxlength="150" class="form-control" rows="3"
                        placeholder=""></textarea>
            </div>
              <div class="col-md-12">
                <label class="form-control-label pr-1">
                  E-mails
                </label>
                <base-button @click.prevent="handleListContacts" size="sm" type="warning" class="text-uppercase">
                  <i class="fas fa-id-card-alt"></i> contatos
                </base-button>
              </div>
            <div class="col-md-12 col-form-label form-control-label">
              <base-input>
                <puzl-select
                  multiple
                  :loading="loadingContacts"
                  label="email"
                  customKey="uuid"
                  v-model="email.emails"
                  :items="customer_construction.contact ? customer_construction.contact.filter((item) => item.email) : []"
                />
              </base-input>
            </div>
          </div>

          <div class="col-md-12 mt-2 text-right p-0">
            <base-button @click.prevent="closeModal" type="secondary" class="text-capitalize">
              cancelar
            </base-button>
            <!--        <base-button :disabled="disableGenerate || !contract_proposals.length" @click.prevent="generateInvoices" type="primary"-->
            <!--                     native-type="submit">-->
            <!--          <span class="btn-label"><i class="fas fa-file-invoice-dollar"></i></span>-->
            <!--          Faturar e continuar-->
            <!--        </base-button>-->
            <base-button
              :disabled="invalid || 
                (!email.invoice && !email.nfse && !email.bank_billet && invoice.invoice_attachments.length == 0) ||
                !(email.entity_include || email.seller_include || email.emails.length > 0)"
              :loading="loading"
              @click="submit()"
              type="success"
              native-type="submit">
              <span v-if="!loading" class="btn-label"><i class="fas fa-file-invoice-dollar"></i></span>
              Enviar
            </base-button>
          </div>
        </validation-observer>
        <ModalListContacts @updateConstruction="listContacts" ref="listContacts"/>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ModalListContacts from "@/views/Modules/Operational/Schedule/Weighing/Shared/_ModalListContacts"
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "ModalSendToEmail",
  components: {PuzlSelect, ModalListContacts},
  data() {
    return {
      modal: false,
      loading: false,
      invoice: null,
      has_billet: false,
      email: {
        text: null,
        emails: [],
        entity_include: false,
        seller_include: false,
        invoice: false,
        nfse: true,
      },
      loadingContacts: null,
      title: "Envio por e-mail",
    };
  },
  computed: {
    ...mapGetters({
      customer_construction: "customerConstructionConstructions/show",
    })
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    openModal(item) {
      this.has_billet = item.has_any_billet_generated
      this.email = {
        invoice: true,
        nfse: true,
        entity_include: true,
        seller_include: false,
        emails: [],
        bank_billet: !!item.has_any_billet_generated,
        text: null,
        id: item.id
      }
      this.invoice = item
      this.listContacts()
      this.modal = true;
    },
    submit() {
      this.loading = true
      const self = this
      const emails = this.customer_construction.contact.filter(function (item) {
        if (self.email.emails.includes(item.uuid)) {
          return item
        }
      }).map(function (obj) {
        return obj.email;
      })
      const email = {...this.email}
      email.emails = emails
      this.$store.dispatch('billingInvoice/postSendToEmail',
        email).then(response => {
        this.modal = false
        this.$notify({
          type: response.error_type, message: response.message
        })
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    listContacts() {
      this.loadingContacts = true
      this.$store.dispatch('customerConstructionConstructions/show', this.invoice.contract_proposal.customer_construction_uuid)
        .then(response => {
          this.contacts = response.data.contact.filter(item => item.email)
          this.loadingContacts = false
        })
        .catch(error => {
          this.loadingContacts = false
          this.$notify({
            type: error.data.error_type, message: error.data.message
          })
        })
    },
    handleListContacts() {
      this.$refs.listContacts.openModal(this.customer_construction.uuid);
    },
  }
};
</script>

<style></style>
