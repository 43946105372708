<template>
  <div>
    <modal size="md" :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(save)"
            autocomplete="off"
          >
            <div>
              <!-- central de carregamento-->
              <div class="form-group row m-0 p-0 mt-4">
                <label
                  class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-4 mb-2"
                >
                  <div class="pt-1">
                    Central de carregamento
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-6 pr-4 mb-2">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <el-select
                        @change="setMaxVolume"
                        v-model="travel.send_plant_uuid"
                        size="mini"
                        placeholder="Selecione"
                        filterable
                        :disabled="
                          allowEditing(travel.status) ||
                            travel.isset_manual_dosage === true
                        "
                      >
                        <el-option
                          v-for="plant in travel.company_plants"
                          :key="plant.uuid"
                          :label="plant.name"
                          :value="plant.uuid"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- central de retorno-->
              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-4 mb-2"
                >
                  <div class="pt-1">
                    Central de retorno
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-6 pr-4 mb-2">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <el-select
                        @change="getReturnDuration"
                        v-model="travel.return_plant_uuid"
                        size="mini"
                        placeholder="Selecione"
                        filterable
                      >
                        <el-option
                          v-for="plant in travel.company_plants"
                          :key="plant.uuid"
                          :label="plant.name"
                          :value="plant.uuid"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Número da viagem -->
              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-4 mb-2"
                >
                  <div class="pt-1">
                    Número da viagem
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-6 pr-4 mb-2">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model="travel.index"
                        disabled
                        inputmode="numeric"
                        class="form-control form-control-sm"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- volume -->
              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-6 pt-0 pb-0 col-form-label form-control-label pl-4 mb-2"
                >
                  <div class="pt-1">
                    Volume
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-6 pr-4 mb-2">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-on:input="
                          travel.volume = formatString(
                            $event.target.value,
                            '0',
                            travel.max_volume,
                            '1'
                          )
                        "
                        :disabled="
                          !selectedPlant ||
                            allowEditing(travel.status) ||
                            travel.isset_manual_dosage === true
                        "
                        v-model="travel.volume"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          m<sup>3</sup>
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
            </div>
            <div class="modal-footer pb-0">
              <base-button type="secondary" @click="closeModal()">
                Cancelar
              </base-button>
              <base-button
                type="success"
                :loading="loadingSave"
                native-type="submit"
                :disabled="invalid"
              >
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { checkNumberValue } from "@/helpers";
import { mask1, formatErrorValidation, formatDate } from "@/plugins";

export default {
  components: {},
  name: "ModalEditTravel",
  data() {
    return {
      modal: false,
      selectedPlant: true,
      title: "Editar Viagem",
      loadingSave: false,
      travel: {},
      old_send_plant_uuid: null,
      old_volume: null
    };
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    openModal(uuid) {
      let params = {
        uuid: uuid,
        code: 1
      };
      this.$Progress.start();
      this.$store.dispatch("travels/show", params).then(response => {
        this.travel = response.data;
        this.travel.send_plant_uuid = this.travel.send_plant.uuid;
        this.travel.return_plant_uuid = this.travel.return_plant.uuid;
        this.travel.max_volume = this.getMaxVolume();
        this.old_volume = this.travel.volume;
        this.old_send_plant_uuid = this.travel.send_plant_uuid;
        this.$Progress.finish();
        this.$notify({
          type: response.error_type,
          message: response.message
        });
        this.modal = true;
      });
    },
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        this.$forceUpdate();
        return checkNumberValue(
          mask1(value.toString(), decimals, ".", true),
          min,
          max
        );
      }
      return checkNumberValue(value, min, max);
    },
    allowEditing(status) {
      let allow = false;
      switch (status) {
        case 1:
        case 5:
        case 6:
        case 7:
          allow = true;
          break;
      }
      return allow;
    },
    finalFormat() {
      if (!this.travel.equipment_uuid) {
        delete this.travel.equipment_uuid;
      }
      if (!this.travel.driver_uuid) {
        delete this.travel.driver_uuid;
      }
      this.travel.arrival_time = formatDate(this.travel.arrival_time);
      this.travel.load_truck_time = formatDate(this.travel.load_truck_time);
      this.travel.construction_exit_time = formatDate(
        this.travel.construction_exit_time
      );
      this.travel.send_duration = this.getSendDuration();
      this.getReturnDuration();
      if (
        this.travel.send_plant_uuid !== this.old_send_plant_uuid ||
        this.travel.volume !== this.old_volume
      ) {
        this.travel.core_changes = 2;
      }
    },
    save() {
      this.$Progress.start();
      this.loadingSave = true;
      this.getReturnDuration();
      this.finalFormat();
      this.$store
        .dispatch("travels/update", this.travel)
        .then(response => {
          this.loadingSave = false;

          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.$emit("updatedTravel");
          this.closeModal();
        })
        .catch(error => {
          if (error.status == 200) {
            this.$notify({
              type: "danger",
              message: error.data.message
            });
            this.$Progress.finish();
            this.loadingSave = false;
          } else if (error.response && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors
            });
          }
          this.$Progress.finish();
          this.loadingSave = false;
        });
    },
    async setMaxVolume() {
      this.travel.volume = null;
      this.selectedPlant = false;
      this.travel.max_volume = await this.getMaxVolume();
      this.travel.send_duration = await this.getSendDuration();
      this.selectedPlant = true;
    },
    getMaxVolume() {
      return this.travel.company_plants.filter(
        item => item.uuid === this.travel.send_plant_uuid
      )[0].max_volume;
    },
    getSendDuration() {
      return this.travel.company_plants.filter(
        item => item.uuid === this.travel.send_plant_uuid
      )[0].send_duration;
    },
    getReturnDuration() {
      this.travel.return_duration = this.travel.company_plants.filter(
        item => item.uuid === this.travel.return_plant_uuid
      )[0].return_duration;
    }
  },
  mounted() {}
};
</script>

<style></style>
