export { default as DreList } from "./DreList";
export { default as DreConfigurationList } from "./DreConfigurationList";
export { default as DreConfigurationUpdate } from "./DreConfigurationUpdate";
export { default as DreConfigurationUpdateMultiple } from "./DreConfigurationUpdateMultiple";

/** Urls para acesso externo */
export const dreUrls = Object.freeze({
  BASE: "/administrative/dre",
  CONFIGURATION: "administrative/configurations",
  CONFIGURATION_UPDATE_PERSONALIZED: "administrative/configurations/personalized"
});
