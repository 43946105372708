<template>
  <div style="min-height: 2000px;">
    <base-header class="bg-gray-content mb-4">
      <div class="row align-items-center justify-content-between py-4">
        <div class="col-6 col-md-6">
          <h2 style="font-size: 1.2rem !important;color: #6c757d;" class="nowrap">
            <img height="30px" src="/img/icons/icons8/ios/business-building-success.png" class="mr-2"/>
            RESUMO DE ASSINATURA DIGITAL
          </h2>
        </div>
        <!-- Botao de voltar -->
        <div>
        <div class="mb-2 mr-3" style="width: 14rem;">
          <BackButton/>
        </div>
        </div>
      </div>
      <!-- Botoes status documentos -->
      <div class="row ml-1 justify-content-between justify-content-sm-start">
        <div v-for="(button, index) in widgets" :key="index"
          @click="filterByStatus(button.status)"
          class="status-button d-flex justify-content-between pointer col-5 col-md-2 pr-0 mb-2 mb-md-0"
          :style="{ border: button.border }">
          <div class="pl-2 d-flex align-items-center">
            <img :src="button.icon" width="15px" />
            <span class="mx-2">{{ button.text }}</span>
          </div>
          <div class="status-button-count d-flex align-items-center justify-content-center"
             :style="{ backgroundColor: button.countBackgroundColor }">
             {{ statusCount[button.status] || 0 }}
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid">
      <MultiFilter
        :type="5"
        :default-range="range"
        @handleFilterDate="handleFilterDate"
        ref="multi-filter"
        @fetch="init"
        :showSlotBookmark="true"
        :disable-max-date="new Date()"
        :filter="filter">
        <template slot="dropdown-instead-input">
          <PuzlSelect
              style="width: 100%"
              v-model="filter.sellers_uuid"
              :items="$_sellers"
              customKey="uuid"
              placeholder="Vendedores"
              @change="init({})"
              multiple
              class="select-xl"/>
          </template>
        <template slot="order-by">
          <el-popover trigger="click" placement="bottom" class="p-0 float-right pr-0 ml-3">
            <h6><a @click.prevent="handleFilterOrderBy('created.desc')" href="#" class="font-weight-400" style="font-size: 12px">
              <i :class="[filter.order_by === 'created.desc' && 'text-primary ', 'fa-solid fa-angles-down']" style="font-size: 12px; margin-right: 5px"/>
                DO MAIS NOVO PARA O MAIS VELHO
              </a></h6>
              <hr class="mb-1 mt-n2">
            <h6 class="mb-0"><a @click.prevent="handleFilterOrderBy('created.asc')" href="#" class="font-weight-400" style="font-size: 12px">
              <i :class="[filter.order_by === 'created.asc' && 'text-primary ', 'fa-solid fa-angles-up']" style="font-size: 12px; margin-right: 5px"/>
              DO MAIS VELHO PARA O MAIS NOVO
            </a></h6>
            <a href="#" slot="reference">
              <h5 slot="reference" class="pt-1">ORDENAR <i class="fa-solid fa-chevron-down"></i></h5>
            </a>
          </el-popover>
        </template>
      </MultiFilter>
      <!-- Tabela -->
      <Table v-if="$_contractProposalSignatures.length > 0"
        :contractProposalSignatures="$_contractProposalSignatures" />
      <!-- Carregamento da paginaçao -->
      <LoadingPagination :show="loading"/>
      <!-- Nenhuma informação encontrada -->
      <PuzlEmptyData
        v-if="!loading && $_contractProposalSignatures.length <= 0" />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import BackButton from '@/components/Utils/BackButton';
import MultiFilter from "@/components/Utils/MultiFilterV3";
import Table from './Shared/_Table';
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import PuzlSelect from "@/components/PuzlSelect";

const widgets = Object.freeze({
  all: {
    text: 'todos', border: '0.5px solid #606062', status: 'all',
    countBackgroundColor: '#dfdfe0', icon: '/img/icons/autograph-black.png'
  },
  signed: {
    text: 'assinados', border: '0.5px solid #1A70B7', status: 'signed',
    countBackgroundColor: '#d1e2f1', icon: '/img/icons/icons8/ios/double-tick-primary.png'
  },
  pending: {
    text: 'pendentes', border: '0.5px solid #F2B532', status: 'pending',
    countBackgroundColor: '#fcf0d6', icon: '/img/icons/icons8/ios/hourglass_warning.png'
  },
  canceled: {
    text: 'cancelados', border: '0.5px solid #DB4539', status: 'canceled',
    countBackgroundColor: '#f8dad7', icon: '/img/icons/icons8/ios/cancel_danger.png'
  },
});

export default {
  mixins: [cursorPaginate],
  components: {
    BackButton,
    MultiFilter,
    Table,
    LoadingPagination,
    SkeletonPuzlGrid,
    cursorPaginate,
    PuzlEmptyData,
    PuzlSelect,
  },
  data() {
    return {
      filter: {},
      loadingSkeleton: false,
      widgets,
      statusCount: {
        all: 0, // Todos
        signed: 0, // Assinado
        pending: 0, // Pendente
        canceled: 0, // Cancelado
      },
      range: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 29),
        end: new Date(),
      },
      loadingSeller: true
    }
  },
  computed: {
    ...mapGetters({
        $_contractProposalSignatures: "digitalSignatureResume/fetch",
        $_sellers: "user/getAllActiveSellersSimplified",
    }),
  },
  methods: {
    init(filter = null) {
      this.startCursor(filter);
      this.loadingSkeleton = true
      this.filter.next_page = this.paginate.nextUrl;
      this.$store.dispatch('digitalSignatureResume/fetchItemsPaginate', {
          filter: this.filter,
          next_page: this.paginate.nextUrl,
        })
        .then(response => {
          if(this.paginate.nextUrl) this.$_contractProposalSignatures = response.data;
          this.resolveCursor(response);
          this.setWidgetsCount();
          this.loadingSkeleton = false
        }).catch(error => {
          this.resolveCursor()
          this.loadingSkeleton = false
      });
    },
    handleFilterDate(filter) {
      this.range = filter;
      this.init({});
    },
    filterByStatus(status) {
      const statusMapping = {
        'pending': 0,
        'signed': 1,
        'canceled': 2,
        'all': null
      };
      this.filter.status = statusMapping[status];
      this.init({});
    },
    handleFilterOrderBy(type) {
      this.filter.order_by == type ? this.filter.order_by = null : this.filter.order_by = type;
      this.init({});
    },
    setWidgetsCount() {
      this.$store.dispatch('digitalSignatureResume/getWidgets', {filter: this.filter})
        .then((response) => {
          this.statusCount.all = response.data['all'] ?? 0;
          this.statusCount.signed = response.data['signed'] ?? 0;
          this.statusCount.pending = response.data['pending'] ?? 0;
          this.statusCount.canceled = response.data['canceled'] ?? 0;
        });
    },
  },
  mounted() {
    this.init({});
    this.$store.dispatch("user/getAllActiveSellersSimplified").then(() => {
      this.loadingSeller = false;
    });
  },
};
</script>
<style>
.status-button {
  border-radius: 5px;
  height: 30px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  min-width: 12rem;
  margin-right: 1rem;
}
.status-button-count {
  border-radius: 0 5px 5px 0;
  width: 2.5rem;
  font-weight: 600;
}

</style>
