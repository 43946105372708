<template>
  <div class="container-fluid mt-4">
    <div class="card mb-4">
      <div class="row mx-n2">
        <div class="col-md-6">
          <!-- Filtros -->
          <MultiFilter
            @clearFilters="applyGlobalFilter"
            ref="multi-filter"
            id="schedule-travel-gantt-filter"
            @handleFilterDate="handleFilterDate"
            @fetch="applyGlobalFilter"
            :filter="filter"
            :status="multi_filter"
          >
            <div class="col-md-4 px-2 d-flex align-items-end mb-2 pb-1">
              <input-date-picker class="w-100" :isRange="false" ref="date-picker" @handleFilterDate="handleFilterDate" />
            </div>
            <div class="col-md-3 px-2 d-flex align-items-center">
              <PuzlSelect
                @input="changedCompanyPlant"
                class="w-100 mt-1"
                v-model="filter.company_plant_id"
                :items="$_company_plants"
                :clearable="false"
              />
            </div>
            <div class="col-md-3 px-2 d-flex align-items-center">
              <PuzlSelect
                @input="applyGlobalFilter"
                class="w-100 mt-1"
                :disabled="!filter.company_plant_id || loading_charge_points"
                v-model="filter.charge_point_name"
                :items="$_charge_points"
                placeholder="Ponto de carga"
                customKey="name"
              />
            </div>
            <div class="px-2 col-md-2 mt-md-0 mt-2">
              <base-button @click.prevent="handleVisibilityChange" block type="primary" outline>
                <i class="fas fa-1x fa-sync"></i>
              </base-button>
            </div>
          </MultiFilter>
        </div>
        <div class="col-md-6 py-4 d-flex align-items-center">
          <CardsHeader
            @handleDontShowFinishedAndCanceledFilter="handleDontShowFinishedAndCanceledFilter"
            @handleShowOnlyFinishedFilter="handleShowOnlyFinishedFilter"
            :dontShowFinishedAndCanceled="filter.dont_show_finished_and_canceled"
            :showOnlyFinished="filter.show_only_finished"
            :loading="loading_headers"
          />
        </div>
      </div>
    </div>
    <ScheduleTravelGantt :items="items" :loading="loading_items" />
    <ModalEditScheduleTravel @updatedTravel="init" ref="modalEditScheduleTravel" />
  </div>
</template>

<script>
import ScheduleTravelGantt from "./Shared/_ScheduleTravelGantt";
import CardsHeader from "./Shared/_CardsHeader";
import MultiFilter from "@/components/Utils/MultiFilterV2";
import InputDatePicker from "@/components/InputDatePicker";
import PuzlSelect from "@/components/PuzlSelect";
import ModalEditScheduleTravel from "@/views/Modules/Operational/Schedule/Weighing/Shared/_ModalEditTravel";
import { mapGetters } from "vuex";
import moment from "moment";
import { ScheduleTravelEnum } from "@/enum/ScheduleTravelEnum";

export default {
  name: "IndexGantt",
  components: {
    ScheduleTravelGantt,
    CardsHeader,
    MultiFilter,
    InputDatePicker,
    PuzlSelect,
    ModalEditScheduleTravel,
  },
  computed: {
    ...mapGetters({
      $_company_plants: "plant/activeItems",
      $_items: "scheduleTravelGantt/getItems",
      $_charge_points: "plantChargePoint/fetch",
    }),
  },
  data() {
    return {
      loading_headers: false,
      loading_items: false,
      loading_charge_points: true,
      filter: {
        date: moment().format("YYYY-MM-DD"),
        company_plant_id: null,
        charge_point_name: null,
        dont_show_finished_and_canceled: false,
        show_only_finished: false,
      },
      multi_filter: {
        null: null,
      },
      items: [],
      ScheduleTravelEnum: ScheduleTravelEnum,
    };
  },
  methods: {
    init() {
      if (!this.filter.company_plant_id) {
        this.$notify({ type: "danger", message: "Central não selecionada!" });
      }
      delete this.filter.global;
      this.filter.charge_point_name = null;
      this.filter.dont_show_finished_and_canceled = false;
      this.filter.show_only_finished = false;
      this.getHeaders();
      this.getItems();
    },

    async getHeaders() {
      this.loading_headers = true;
      await this.$store.dispatch("scheduleTravelGantt/getHeaders", this.filter);
      this.loading_headers = false;
    },

    async getItems() {
      this.loading_items = true;
      await this.$store.dispatch("scheduleTravelGantt/getItems", this.filter);
      this.items = this.$_items;
      this.applyFormat();
      this.loading_items = false;
    },

    clearFilters() {
      this.filter = {
        date: this.filter.date ?? moment().format("YYYY-MM-DD"),
        company_plant_id: this.filter.company_plant_id,
        charge_point_name: null,
      };
      this.$refs["date-picker"].resetDate();
      this.init();
    },

    handleFilterDate(date) {
      this.filter.date = moment(date).format("YYYY-MM-DD");
      this.init();
    },

    applyGlobalFilter(filter) {
      const global_filter = filter && filter.global && filter.global.length ? filter.global : [];
      this.filter.charge_point_name && global_filter.push(this.filter.charge_point_name);
      // Filtragem por palavras
      this.items = this.$_items.filter((obj) => {
        return global_filter.every((value) =>
          [obj.description, obj.equipment_name, obj.contract_proposal_code, obj.charge_point_name, obj.schedule_id].some(
            (prop) => value && prop && prop.toString().toLowerCase().includes(value.toString().toLowerCase())
          )
        );
      });
      // Não mostra viagens finalizadas, não realizadas e canceladas
      if (this.filter.dont_show_finished_and_canceled) {
        this.items = this.items.filter(
          (obj) => ![
              ScheduleTravelEnum.CANCELED,
              ScheduleTravelEnum.FINISHED,
              ScheduleTravelEnum.UNREALIZED,
              ScheduleTravelEnum.IN_PROGRESS,
            ].includes(obj.status)
        );
      } else if(this.filter.show_only_finished) {
        this.items = this.items.filter((obj) => [ScheduleTravelEnum.FINISHED,ScheduleTravelEnum.IN_PROGRESS,].includes(obj.status));
      }

      this.applyFormat();
    },

    applyFormat() {
      this.items.map((item, index) => {
        item.index = ++index;
        item.start = this.getStart(item);
        item.end = this.getEnd(item);
        item.label = moment(item.start).format("HH:mm");
        item.label += ` | ${item.volume.toFixed(1)} M³`;
        item.label += item.sequencial_number ? ` | O.S ${item.sequencial_number}` : "";
        item.label += item.danfe_number ? ` | N.F. ${item.danfe_number}` : "";
        item.equipment_name = item.equipment_name ?? "N/A";
        const color = this.getItemColor(item.status);
        item.style = {
          base: color,
        };
        return item;
      }, this);

      this.items.sort((a, b) => a.start - b.start).map((item, index) => {
        item.index = ++index;
        return item;
      });
    },

    changedCompanyPlant() {
      this.$refs["multi-filter"].clearFilters();
      this.filter.charge_point_name = null;
      this.filter.dont_show_finished_and_canceled = false;
      this.init();
      this.setDefaultCompanyPlant();
      this.getCompanyPlantChargePoints();
    },

    getItemColor(status) {
      switch (status) {
        // Concluído
        case 1:
          return {
            fill: "#1a70b7",
            stroke: "#1a70b7",
          };
        // Caregando
        case 2:
          return {
            fill: "#5603ad",
            stroke: "#5603ad",
          };
        //Cancelado
        case 6:
          return {
            fill: "#c93024",
            stroke: "#c93024",
          };
        // Em andamento
        case 5:
          return {
            fill: "#11cdef",
            stroke: "#11cdef",
          };
        // Não realizado
        case 7:
          return {
            fill: "#de4e43",
            stroke: "#de4e43",
          };
        // Padrão
        default:
          return {
            fill: "#adb5bd",
            stroke: "#adb5bd",
          };
      }
    },

    setDefaultCompanyPlant() {
      localStorage.setItem("company_plant_id_to_schedule_travel_gantt", this.filter.company_plant_id);
    },

    getDefaultCompanyPlant() {
      const company_plant_id = localStorage.getItem("company_plant_id_to_schedule_travel_gantt");
      return company_plant_id ? parseInt(company_plant_id) : null;
    },

    applyContractProposalFilter(contract_proposal_code) {
      if (
        !this.$refs["multi-filter"] ||
        (this.$refs["multi-filter"] && this.$refs["multi-filter"].global.includes(contract_proposal_code))
      ) {
        return;
      }
      this.$refs["multi-filter"].global.push(contract_proposal_code);
      this.$refs["multi-filter"].handleFilterClick();
    },

    applyScheduleFilter(schedule_id) {
      if (
        !this.$refs["multi-filter"] ||
        (this.$refs["multi-filter"] && this.$refs["multi-filter"].global.includes(schedule_id))
      ) {
        return;
      }
      this.$refs["multi-filter"].global.push(schedule_id);
      this.$refs["multi-filter"].handleFilterClick();
    },

    async handleModalEditScheduleTravel(schedule_travel) {
      if (
        [
          ScheduleTravelEnum.CANCELED,
          ScheduleTravelEnum.FINISHED,
          ScheduleTravelEnum.IN_PROGRESS,
          ScheduleTravelEnum.UNREALIZED,
          ScheduleTravelEnum.LOADING,
        ].includes(schedule_travel.status)
      ) {
        return this.$notify({ type: "danger", message: "Viagem não apta para edição!" });
      }

      const loader = this.$loading.show();
      const response = await this.$store.dispatch("travels/show", {
        uuid: schedule_travel.uuid, // uuid da viagem
        code: "1", // direcionamento para resource
      });

      this.$refs.modalEditScheduleTravel.openModal(response.data);
      loader.hide();
    },

    getStart(schedule_travel) {
      switch (true) {
        case schedule_travel.start_cycle !== null:
          return new Date(schedule_travel.start_cycle);
        case schedule_travel.start_timer !== null &&
          ![ScheduleTravelEnum.CANCELED, ScheduleTravelEnum.UNREALIZED].includes(schedule_travel.status):
          return new Date(schedule_travel.start_timer);
        default:
          return new Date(schedule_travel.load_truck_time);
      }
    },

    getEnd(schedule_travel) {
      switch (true) {
        case schedule_travel.end_cycle !== null:
          return new Date(schedule_travel.end_cycle);
        case schedule_travel.start_timer !== null &&
          ![ScheduleTravelEnum.CANCELED, ScheduleTravelEnum.UNREALIZED].includes(schedule_travel.status):
          const start = moment(schedule_travel.start_timer);
          const diffMinutes = moment(schedule_travel.load_truck_time).diff(moment(schedule_travel.free_for_travel), "minutes");
          const resultDate = start.add(Math.abs(diffMinutes), "minutes").format("YYYY-MM-DD HH:mm:ss");
          return new Date(resultDate);
        default:
          return new Date(schedule_travel.free_for_travel);
      }
    },

    handleVisibilityChange() {
      if (document.visibilityState === "visible") {
        this.$refs["multi-filter"].clearFilters();
        this.init();
      }
    },

    async getCompanyPlantChargePoints() {
      if (!this.filter.company_plant_id) {
        return (this.loading_charge_points = false);
      }

      this.loading_charge_points = true;
      await this.$store.dispatch("plantChargePoint/getActiveByPlant", this.filter.company_plant_id);
      this.loading_charge_points = false;
    },

    handleDontShowFinishedAndCanceledFilter(value) {
      this.filter.dont_show_finished_and_canceled = value;
      this.filter.show_only_finished = false;
      this.applyGlobalFilter();
    },

    handleShowOnlyFinishedFilter(value) {
      this.filter.show_only_finished = value;
      this.filter.dont_show_finished_and_canceled = false;
      this.applyGlobalFilter();
    },
  },
  mounted() {
    this.filter.company_plant_id = this.getDefaultCompanyPlant();
    this.init();
    this.getCompanyPlantChargePoints();

    // Aplica filtro de proposta/contrato
    EventBus.$on("apply_contract_proposal_filter", (contract_proposal_code) => {
      this.applyContractProposalFilter(contract_proposal_code);
    });

    // Aplica filtro de programação
    EventBus.$on("apply_schedule_filter", (schedule_id) => {
      this.applyScheduleFilter(schedule_id);
    });

    // Abre modal de edição de viagem
    EventBus.$on("handle_modal_edit_schedule_travel", (schedule_travel) => {
      this.handleModalEditScheduleTravel(schedule_travel);
    });

    document.addEventListener("visibilitychange", this.handleVisibilityChange);
  },

  beforeDestroy() {
    document.removeEventListener("visibilitychange", this.handleVisibilityChange);
  },
};
</script>

<style>
#schedule-travel-gantt-filter .card {
  border: none !important;
  box-shadow: none !important;
  margin-bottom: 0 !important ;
}
#schedule-travel-gantt-filter .card-body {
  padding-bottom: 0.8rem !important;
}
</style>
