<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group row m-0 mb-1">
            <label class="col-md-6 pb-0 mt-n2 col-form-label form-control-label">
              Número de parcelas
            </label>
            <div class="col-md-2">
              <base-input input-group-classes="input-group-sm">
                <puzl-select @change="generateInstallments()"
                             :clearable="false" v-model="number_installments" :items="getInstallments()"/>
              </base-input>
            </div>
          </div>
          <h2 style="font-size: 1.1rem" class="pl-3" v-if="sum_is_bigger_than_installment">
            <small class="text-grey2 numeric font-weight-600">
              <i class="fa-solid fa-circle-exclamation text-danger"></i> A soma das parcelas não deve superior a {{total_value | currency()}}.
            </small>
          </h2>
          <h2 style="font-size: 1.1rem" class="pl-3" v-if="first_installment_is_zero">
            <small class="text-grey2 numeric font-weight-600">
              <i class="fa-solid fa-circle-exclamation text-danger"></i> A primeira parcela deve ser superior a {{0 | currency()}}.
            </small>
          </h2>
        </div>
        <div class="col-md-12">
          <div class="border-header-primary-bottom mb-2">
            <h5 class="h3 p-1 mb-0">Pagamentos</h5>
          </div>
          <div class="form-group row m-0 mb-1" v-for="item in installments">
            <label class="col-md-6 pb-0 mt-n2 col-form-label form-control-label">
              Parcela {{ item.index }}
            </label>
            <div class="col-md-6">
              <button data-v-d02c1b2e="" type="button" disabled
                      class="btn base-button text-uppercase btn-block btn-warning btn-sm" slot="title-container">
                Pendente
              </button>
            </div>
            <label class="col-md-6 pb-0 mt-n1 col-form-label form-control-label">
              Valor
            </label>
            <div class="col-md-6 mt-1">
              <base-input v-if="item.index !== installment.index" input-group-classes="input-group-sm">
                <input v-money="money" maxlength="13"
                       @input="changeInstallmentValue()"
                       @keydown="$event.key === '-' ? $event.preventDefault() : null"
                       inputmode="numeric"
                       v-model.lazy="item.value"
                       type="text"
                       class="form-control form-control-sm numeric"/>
                <template slot="prepend">
                  <small class="input-group-sm p-0 m-0">
                    R$
                  </small>
                </template>
              </base-input>
              <base-input v-else input-group-classes="input-group-sm">
                <input disabled
                       v-money="money" maxlength="13"
                       @keydown="$event.key === '-' ? $event.preventDefault() : null"
                       inputmode="numeric"
                       :value="(total_value - sum_another_installments).toFixed(2) > 0 ? (total_value - sum_another_installments).toFixed(2) : 0"
                       type="text"
                       class="form-control form-control-sm numeric"/>
                <template slot="prepend">
                  <small class="input-group-sm p-0 m-0">
                    R$
                  </small>
                </template>
              </base-input>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <base-button type="secondary" @click="closeModal()">
          Cancelar
        </base-button>
        <base-button
          :disabled="Boolean(sum_is_bigger_than_installment || first_installment_is_zero)"
          @click.native="save()"
                     type="success"
                     native-type="submit"
                     title="Salvar"
                     class="btn-custom"
                     :class="loadingSave && 'btn-loading'">
          <span v-show="!loadingSave" class="btn-label span-custom"><i class="fas fa-save text-white icon-custom"></i></span>
          <span v-show="loadingSave" class="btn-label span-custom" ><i class="fas fa-spinner text-white fa-spin icon-custom"></i></span>
          Salvar
        </base-button>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  VMoney
} from "v-money";
import {mapGetters} from "vuex";
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "ModalShareInstallment",
  components: {
    PuzlSelect,
  },
  data() {
    return {
      modal: {
        title: 'Dividir parcelas',
        create: false,
      },
      total_value: 0,
      sum_is_bigger_than_installment: 0,
      number_installments: 0,
      sum_another_installments: 0,
      first_installment_is_zero: 0,
      installments: [],
      installment: undefined,
      loadingSave: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false
      },
    }
  },
  directives: {
    money: VMoney
  },
  computed: {
    ...mapGetters({})
  },
  methods: {
    closeModal() {
      this.modal.create = false
    },
    save()
    {
      this.loadingSave = true
      this.installments[0].value = (this.total_value - this.sum_another_installments).toFixed(2)
      this.$emit('updateInstallments', {
        old_installment: this.installment,
        installments: this.installments,
        shared_count: this.number_installments,
        latest_index: this.installment.index,
        due_date: this.installment.due_date
      })
    },
    updatedInstallments()
    {
      this.loadingSave = false
      this.closeModal()
      this.$notify({
        type: 'success',
        message: 'Divisão das parcelas realizadas com sucesso!'
      })
    },
    changeInstallmentValue() {
      const new_installments = []
      let sum_another_installments = 0
      for (let item of this.installments) {
        if (item.index === this.installment.index) {
          new_installments.push({
            index: this.installment.index,
            value: 0
          })
        } else {
          const value = Number(item.value.replaceAll(".", "").replace(",", "."))
          new_installments.push({
            index: item.index,
            value: value.toFixed(2)
          })
          sum_another_installments += value
        }
      }
      this.sum_another_installments = sum_another_installments.toFixed(2)
      if (sum_another_installments === this.total_value) {
        this.first_installment_is_zero = true
      }
      else {
        this.first_installment_is_zero = 0
      }
      if (sum_another_installments > this.total_value) {
        this.sum_is_bigger_than_installment = true
      } else {
        this.sum_is_bigger_than_installment = false
      }
    },
    getInstallments() {
      const installments = []
      for (let i = 1; i <= 12; i++) {
        installments.push({
          id: i,
          name: i
        })
      }
      return installments
    },
    generateInstallments() {
      let loader = this.$loading.show()
      let new_installments = []
      const value_per_installment = (this.installment.value.replaceAll(".", "").replace(",", ".") / Number(this.number_installments)).toFixed(2)
      let first_index = this.installment.index - 1
      for (var i = 1; i <= this.number_installments; i++) {
        first_index += 1
        const item = {
          index: first_index,
          value: value_per_installment
        }
        new_installments.push(item)
      }
      this.installments = new_installments
      loader.hide()
    },
    openModal(installment) {
      this.installment = installment
      this.modal.create = true
      this.number_installments = 1
      this.sum_another_installments = 0
      this.total_value = Number(this.installment.value.replaceAll(".", "").replace(",", "."))
      this.installments = [
        {
          index: installment.index,
          value: installment.value
        }
      ]
      this.$forceUpdate()
    },
  },
}
</script>

<style scoped>

</style>
