<template>
  <div>
    <modal :borderInfo="'2px solid #1b6eba'" :show.sync="modal.create" size="lg">
      <template slot="header">
        <div style="display: flex;align-items: center;">
          <img style="display: inline-flex;" src="/img/icons/icons8/ios/cash-receipt_white.png" width="22">
          <h5 style="display: inline-flex;" class="modal-title p-0 m-0 ml-3 text-white">{{ modal.title }}</h5>
        </div>
      </template>
      <div v-if="current_tribute">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div class="container-fluid">
              <div class="row mt-2" style="display: flex; align-items: center;">
                <div class="col-auto" style="display: flex; align-items: center;">
                  <img src="/img/icons/icons8/ios/info-squared_warning.png" width="20">
                  <h4 class="ml-2 new-default-black-font m-0">
                    Identificação
                  </h4>
                </div>
                <div class="col">
                  <hr class="m-0">
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-12">
                  <h4 class="m-0 new-default-black-font">
                    {{ current_tribute.city }}
                  </h4>
                  <h4 class="m-0 new-default-black-font font-weight-normal">
                    {{ current_tribute.regime }}
                  </h4>
                </div>
              </div>
              <div class="row align-items-center mt-4">
                <div class="col-md-6">
                  <h4 class="m-0 new-default-black-font font-weight-normal">
                    Regra
                  </h4>
                </div>
                <div class="col-md-6">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-group-classes="input-group-sm">
                      <puzl-select
                        v-if="current_tribute && current_tribute.tax_name && current_tribute.tax_name === 'ISSQN'"
                        v-model="deduction_rules.rule"
                        :items="issqnRules"
                      />
                      <puzl-select v-else v-model="deduction_rules.rule" :items="otherTaxRules"/>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="row align-items-center mt-1">
                <div class="col-md-6">
                  <h4 class="m-0 new-default-black-font font-weight-normal">
                    Início da vigência
                  </h4>
                </div>
                <div class="col-md-6">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-group-classes="input-group-sm">
                      <input-date-time-picker :spaceMinutes="1"
                        v-model="deduction_rules.date_at"
                        size="sm"
                        placeholder="Ínicio de vigência"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="row mt-4 mb-4" style="display: flex; align-items: center;">
                <div class="col-auto" style="display: flex; align-items: center;">
                  <img src="/img/icons/icons8/ios/general-ledger_success.png" width="20">
                  <h4 class="ml-2 new-default-black-font m-0">
                    Taxas
                  </h4>
                </div>
                <div class="col">
                  <hr class="m-0">
                </div>
              </div>
              <div class="row align-items-center mb-1" v-if="deduction_rules.rule == 'Dedução'">
                <div class="col-6">
                  <h4 class="m-0 new-default-black-font">
                    Deduzir custo de mcc *
                  </h4>
                </div>
                <div class="col-6">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-switch
                      v-model="deduction_rules.deduct_mcc_cost"
                      type="success"
                      offText="Não"
                      onText="Sim"
                      class="success"
                    ></base-switch>
                  </validation-provider>
                </div>
              </div>
              <div class="row align-items-center" v-if="deduction_rules.deduct_mcc_cost">
                <div class="col-6">
                  <h4 class="m-0 new-default-black-font">
                    Considerar reuso *
                  </h4>
                </div>
                <div class="col-6">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-switch
                      v-model="deduction_rules.consider_reuse"
                      type="success"
                      offText="Não"
                      onText="Sim"
                      class="success"
                    ></base-switch>
                  </validation-provider>
                </div>
              </div>
              <div class="row align-items-center mt-4" v-if="deduction_rules.deduct_mcc_cost">
                <div class="col-md-6">
                  <h4 class="m-0 new-default-black-font font-weight-normal">
                    Condição *
                  </h4>
                </div>
                <div class="col-md-6">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-group-classes="input-group-sm">
                      <el-select v-model="deduction_rules.condition" filterable placeholder="Selecione">
                        <el-option v-for="condition in conditions" :key="condition.id" :label="condition.text" :value="condition.text">
                        </el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="row align-items-center mt-1"
                v-show="(!deduction_rules.deduct_mcc_cost
                  || (deduction_rules.deduct_mcc_cost && deduction_rules.condition != 'Adotar sempre o MCC'))
                  && deduction_rules.rule != 'Taxa Fixa'">
                <div class="col-md-6">
                  <h4 class="m-0 new-default-black-font font-weight-normal">
                    Dedução
                  </h4>
                </div>
                <div class="col-md-6">
                  <validation-provider v-slot="{ errors }">
                    <input-limit
                      :start_value="deduction_rules.deduct"
                      :options="{
                        min: 0.0,
                        max: 80.0,
                        decimals: 2,
                      }"
                      v-model="deduction_rules.deduct"
                      @value="deduction_rules.deduct = $event"
                    >
                      <small>%</small>
                    </input-limit>
                  </validation-provider>
                </div>
              </div>
              <div class="row align-items-center mt-1"
                v-show="(!deduction_rules.deduct_mcc_cost
                  || (deduction_rules.deduct_mcc_cost && deduction_rules.condition != 'Adotar sempre o MCC'))
                  && deduction_rules.rule != 'Taxa Fixa'">
                <div class="col-md-6">
                  <h4 class="m-0 new-default-black-font font-weight-normal">
                    Base de cálculo
                  </h4>
                </div>
                <div class="col-md-6">
                  <validation-provider v-slot="{ errors }">
                    <input-limit
                      :start_value="deduction_rules.base"
                      :options="{
                        min: 0.0,
                        max: 100.0,
                        decimals: 2,
                      }"
                      v-model="deduction_rules.base"
                      @value="deduction_rules.base = $event"
                    >
                      <small>%</small>
                    </input-limit>
                  </validation-provider>
                </div>
              </div>
              <div class="row align-items-center mt-1">
                <div class="col-md-6">
                  <h4 class="m-0 new-default-black-font font-weight-normal">
                    Alíquota total
                  </h4>
                </div>
                <div class="col-md-6">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <div class="input-group input-group-sm">
                      <input
                        v-on:blur="totalRateOnExit()"
                        v-model="deduction_rules.total_rate"
                        v-mask="['#,##', '##,##', '###,##']"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <div class="input-group-prepend">
                        <span class="input-group-text"><small>%</small></span>
                      </div>
                    </div>
                  </validation-provider>
                </div>
              </div>
              <div class="row align-items-center mt-1">
                <div class="col-md-6">
                  <h4 class="m-0 new-default-black-font font-weight-normal">
                    Alíquota retida
                  </h4>
                </div>
                <div class="col-md-6">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <div class="input-group input-group-sm">
                      <input
                        v-on:blur="deduction_rules.retained_rate = $helper.strToNum($event.target.value).toFixed(2)"
                        v-model="deduction_rules.retained_rate"
                        v-mask="['#,##', '##,##', '###,##']"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <div class="input-group-prepend">
                        <span class="input-group-text"><small>%</small></span>
                      </div>
                    </div>
                  </validation-provider>
                </div>
              </div>
              <div class="row mt-5 mb-3">
                <div class="col-12 text-right">
                  <base-button type="danger"
                    size="sm"
                    style="width: 150px;" @click.prevent="closeModal">
                    <div style="display: inline-flex; align-items: center;">
                      <img src="/img/icons/cancel-white.png" width="20">
                      <i class="m-0 ml-1" style="font-style: normal;">
                        Cancelar
                      </i>
                    </div>
                  </base-button>
                  <base-button type="success"
                    size="sm"
                    style="width: 150px;"
                    native-type="submit"
                    :disabled="invalid"
                    :loading="loadingStore">
                    <div style="display: inline-flex; align-items: center;">
                      <img src="/img/icons/save.png" width="20">
                      <i class="m-0 ml-1" style="font-style: normal;">
                        Salvar
                      </i>
                    </div>
                  </base-button>
                </div>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatErrorValidation, mask1 } from "@/plugins";
import { checkNumberValue } from "@/helpers";
import InputLimit from "@/components/Utils/InputLimit";
import PuzlSelect from "@/components/PuzlSelect";
import InputDateTimePicker from "@/components/InputDateTimePicker";

export default {
  components: {
    InputLimit,
    PuzlSelect,
    InputDateTimePicker,
  },
  name: "ModalCreateValidity",
  props: {
    updateObject: { type: Function },
  },
  data() {
    return {
      modal: {
        title: "Alíquotas",
        detail: null,
        create: false,
      },
      index: null,
      loadingStore: false,
      loadingCities: false,
      current_tribute: null,
      deduction_rules: {
        date_at: null,
        rule: null,

        deduct_mcc_cost: false,
        consider_reuse: false,

        condition: "",
        deduct: null,
        base: null,
        total_rate: null,
        retained_rate: null,
        observation: null,
        tax_locality_uuid: null,
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      type: null,
      tribute: {
        state: "",
        city: "",
      },
      tax: {
        name: "",
        coverage: "",
      },
      Cities: [],
      types: [
        {
          id: "service",
          text: "Serviço",
        },
        {
          id: "product",
          text: "Produto",
        },
      ],
      issqnRules: [
        {
          id: "Dedução",
          name: "Dedução",
        },
      ],
      otherTaxRules: [
        {
          id: "Taxa Fixa",
          name: "Taxa Fixa",
        },
      ],
      conditions: [
        {
          id: 1,
          text: "Adotar sempre o MCC",
        },
        {
          id: 2,
          text: "Adotar sempre maior dedução",
        },
        {
          id: 3,
          text: "Adotar sempre menor dedução",
        },
      ],
      listService: [],
      loadingService: true,
    };
  },
  watch: {
    "deduction_rules.deduct": function () {
      this.deductOnExit();
    },
    "deduction_rules.base": function () {
      this.baseOnExit();
    },
  },
  computed: {
    ...mapGetters({
      states: "localizations/states",
      cities: "localizations/cities",
    }),
  },
  mounted() {
    this.$watch("deduction_rules.deduction", function (value) {
      this.deduction_rules.base_tax = 100 - value;
    });
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    removeConditions() {
      this.deduction_rules.condition = null;
    },
    setServiceOrProduct() {
      switch (this.type) {
        default:
          this.getServices();
          break;
      }
    },
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        this.$forceUpdate();
        return checkNumberValue(mask1(value.toString(), decimals, ".", true), min, max);
      }
      return checkNumberValue(value, min, max);
    },
    handleCreateModalWithIndex(tax, index){
      this.index = index;
      this.handleCreateModal(tax, null);
    },
    handleCreateModal(tax, modalDetail) {
      if(modalDetail) this.modal.detail = modalDetail;
      this.deduction_rules = {
        date_at: null,
        rule: "Taxa Fixa",
        deduct_mcc_cost: false,
        consider_reuse: false,
        condition: "",
        deduct: 0,
        base: 100,
        total_rate: null,
        retained_rate: null,
        observation: null,
        tax_locality_uuid: null,
      };

      const uuid = this.$route.params.tax ? this.$route.params.tax.uuid : tax.uuid;

      // Localizar tributo
      this.$Progress.start();
      this.$store
        .dispatch("TaxLocality/show", uuid)
        .then((response) => {
          this.current_tribute = response.data;
          this.deduction_rules.tax_locality_uuid = this.current_tribute.uuid;

          // Preenchimento automático dependendo da tributação
          this.deduction_rules.rule = "Dedução";
          if (this.current_tribute.tax_name !== "ISSQN") {
            this.deduction_rules.rule = "Taxa Fixa";
            this.rules = [{ id: "TF", text: this.deduction_rules.rule }];
          }
          let date = new Date();
          let dateAt = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes();
          this.deduction_rules.date_at = dateAt;
          this.tribute = {
            state: "",
            city: "",
          };
          this.tax = { ...tax };
          this.$Progress.finish();
          this.modal.create = true;
        })
        .catch((error) => {
          this.$notify({ type: error.response.data.error_type, message: error.response.data.message });
        });
    },
    getCities() {
      this.loadingCities = true;
      this.tribute.city = null;
      this.Cities = this.cities.filter((item) => item.state_id == this.tribute.state);
      this.loadingCities = false;
    },
    getServices() {
      this.loadingService = true;
      this.$Progress.start();
      this.$axios
        .get("/configuration/service")
        .then(({ data }) => {
          this.loadingService = false;
          this.listService = data.data;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loadingService = false;
          this.$Progress.finish();
        });
    },
    store() {
      this.deduction_rules.base = this.$helper.strToNum(this.deduction_rules.base);
      this.deduction_rules.deduct = this.$helper.strToNum(this.deduction_rules.deduct);
      this.deduction_rules.total_rate = this.$helper.strToNum(this.deduction_rules.total_rate);
      this.deduction_rules.retained_rate = this.$helper.strToNum(this.deduction_rules.retained_rate);
      if (!this.deduction_rules.deduct_mcc_cost) {
        this.deduction_rules.condition = "";
      }
      let params = this.deduction_rules;
      this.loadingStore = true;
      this.$Progress.start();
      this.$store
        .dispatch("deductionRules/add", params)
        .then((response) => {
          this.loadingStore = false;
          this.modal.create = false;
          this.$Progress.finish();
          this.updateObject(this.index, response.data);
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
        })
        .catch((error) => {
          if (error.response.status === 422) {
            let message = formatErrorValidation(error.response.data.errors);
            this.$notify({ type: "danger", message: message });
          } else {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message,
            });
          }
          this.$Progress.finish();
          this.loadingStore = false;
        });
    },
    baseOnExit() {
      this.deduction_rules.deduct = (100 - this.$helper.strToNum(this.deduction_rules.base)).toFixed(2);
      this.deduction_rules.base = this.$helper.strToNum(this.deduction_rules.base).toFixed(2);
    },
    deductOnExit() {
      this.deduction_rules.base = (100 - this.$helper.strToNum(this.deduction_rules.deduct)).toFixed(2);
      this.deduction_rules.deduct = this.$helper.strToNum(this.deduction_rules.deduct).toFixed(2);
    },
    totalRateOnExit() {
      this.deduction_rules.total_rate = this.$helper.strToNum(this.deduction_rules.total_rate).toFixed(2);
      this.deduction_rules.retained_rate = this.deduction_rules.total_rate;
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
