<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-md-4">
          <h2 style="font-size: 1.2rem !important;color: #6c757d;">
            <img height="30px" src="/img/icons/icons8/ios/general-ledger_default.png" class="mr-2"/>
            Tributos - {{ tax.name }}
          </h2>
        </div>
        <div class="col-md-8 mt-n1">
          <div class="row d-flex justify-content-end">
            <div class="mb-md-0 mt-2 mt-md-0 mb-2 col-md-3 px-1">
              <base-button :disabled="!tax || loading" type="success" block style="border-radius: 4px !important;"
                @click="handleCreate()">
                <img src="/img/icons/plus-math--v1-white.png" width="19">
                novo
              </base-button>
            </div>
            <div v-if="tax.name === 'ISSQN'" class="mb-md-0 mt-2 mt-md-0 mb-2 col-md-3 px-1">
              <base-button type="danger" block style="border-radius: 4px !important;"
                @click="handleOpenModalConstructionCities()">
                <img src="/img/icons/icons8/ios/new-store.png" width="19">
                Municípios
              </base-button>
            </div>
            <div class="mb-md-0 mt-2 mt-md-0 mb-2 col-md-3 px-1">
              <BackButton/>
            </div>
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid">
      <div class="mt-4 ml-2">
        <div style="display: flex; align-items: center">
          <i :class="listType !== 'cards' && 'opacity-25'" @click.prevent="setViewType('cards')"
            class="pointer fa-regular fa-hard-drive fa-2x"></i>
          <i :class="listType !== 'table' && 'opacity-25'" @click.prevent="setViewType('table')"
            class="pointer fa-solid fa-table-list fa-2x ml-1"></i>
        </div>
      </div>
      <div>
        <multi-filter :type="3" ref="multi-filter" @fetch="init" :filter="filter">
          <template slot="order-by">
            <el-popover trigger="click" placement="bottom" class="p-0 float-right pr-0 ml-3">
              <div class="card" style="margin: -14px; border-radius: 4px !important;">
                <div class="pointer px-3 pb-2 pt-3 new-default-black-font font-weight-500"
                  :class="filter.order_by === 'asc' ? 'text-primary' : ''"
                  @click.prevent="orderBy('asc')"
                  style="font-size: 11px;">
                  <i class="fa-solid fa-angles-down" style="font-size: 12px;margin-right: 5px"></i>
                  CIDADE: A - Z
                </div>
                <hr class="gray-divider p-0">
                <div class="pointer px-3 py-2 new-default-black-font font-weight-500"
                  :class="filter.order_by === 'desc' ? 'text-primary' : ''"
                  @click.prevent="orderBy('desc')"
                  style="font-size: 11px;">
                  <i class="fa-solid fa-angles-up" style="font-size: 12px;margin-right: 5px"></i>
                  CIDADE: Z - A
                </div>
              </div>
              <a href="#" slot="reference">
                <h5 slot="reference" class="pt-1">ORDENAR <i class="fa-solid fa-chevron-down"></i></h5>
              </a>
            </el-popover>
          </template>
        </multi-filter>
      </div>
      <Cards
        v-if="listType === 'cards'"
        :tributes="tributes"
        :tributeSelected="tributeSelected"
        :newValidity="newValidity"
        :getLastValitity="getLastValitity"
        :handleDeleteTax="handleDeleteTax"
        :handleDetailDeductionRules="handleDetailDeductionRules"
        :handleEditTax="handleEditTax"
        :handleListTaxRates="handleListTaxRates"
        :handleLocateCpfCnpj="handleLocateCpfCnpj"
        :updateTributeWithselectedEntity="updateTributeWithselectedEntity"
        :verifyText="verifyText"
        :verifyCondition="verifyCondition"
        ref="listTributes"
        :loading="loadingSkeleton"/>
      <Table
        v-if="listType === 'table'"
        :tributes="tributes"
        :tributeSelected="tributeSelected"
        :newValidity="newValidity"
        :getLastValitity="getLastValitity"
        :handleDeleteTax="handleDeleteTax"
        :handleDetailDeductionRules="handleDetailDeductionRules"
        :handleEditTax="handleEditTax"
        :handleListTaxRates="handleListTaxRates"
        :handleLocateCpfCnpj="handleLocateCpfCnpj"
        :updateTributeWithselectedEntity="updateTributeWithselectedEntity"
        :verifyText="verifyText"
        :verifyCondition="verifyCondition"
        ref="listTributes"
        :loading="loadingSkeleton" />
      <PuzlEmptyData v-show="!tributes.length && !loadingSkeleton" />
    </div>
    <modal-create ref="ModalCreate"
      @countries="getCountries"
      :loadingCountries="loadingCountries"
      :showDeductionRulesModal="showDeductionRulesModal"
      :updateScreen="updateScreen" />
    <loading-pagination :show="loading && !loadingSkeleton"/>
    <modal-construction-cities ref="ModalConstructionCities" :tax="tax"/>
    <modal-edit ref="modal-edit" />
    <ModalSearchEntity @selected="updateTributeWithselectedEntity" ref="modalSearchEntity" />
    <ModalTaxRates :newValidity="newValidity"  ref="modalTaxRates" />
    <modal-create-validity ref="modalCreateValidity" :updateObject="updateObject" />
  </div>
</template>

<script>
import Cards from './Shared/_Cards'
import Table from './Shared/_Table'
import ModalCreate from './Shared/_ModalCreate';
import {mapGetters} from "vuex";
import MultiFilter from "@/components/Utils/MultiFilterV3";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import ModalConstructionCities from "./Shared/_ModalConstructionCities"
const {formatErrorValidation} = require("@/plugins")
import BackButton from '@/components/Utils/BackButton'
import moment from "moment";
import ModalEdit from "./Shared/_ModalEdit";
import ModalSearchEntity from "@/views/Modules/Configuration/Entity/Shared/_ModalSearchEntity";
import ModalTaxRates from "./Shared/_ModalTaxRates";
import ModalCreateValidity from "./../DeductionRules/Shared/_ModalCreateValidity";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import {setPreferredViewMode, getPreferredViewMode} from '@/plugins/session';

export default {
  mixins: [cursorPaginate],
  components: {
    ModalCreate,
    Cards,
    Table,
    MultiFilter,
    LoadingPagination,
    ModalConstructionCities,
    BackButton,
    ModalEdit,
    ModalSearchEntity,
    ModalTaxRates,
    ModalCreateValidity,
    PuzlEmptyData
  },
  data() {
    return {
      listType: 'cards',
      tributeSelected: null,
      showModalConstructionCities: false,
      loadingSkeleton: false,
      filter: {},
      loading: false,
      loadingCountries: false,
      prevRoute: null,
      tributes: [],
    }
  },
  computed: {
    ...mapGetters({
      'tax': 'FeesAndTaxes/show',
      'countries': 'localizations/countries',
    }),
  },
  mounted() {
    this.listType = getPreferredViewMode() ?? 'cards';
    this.init({})
  },
  methods: {
    orderBy(type){
      if(this.filter.order_by === type) this.filter.order_by = null;
      else this.filter.order_by = type;

      this.init(this.filter);
    },
    updateObject(index, deduction_rule){
      this.tributes[index].deduction_rules.push(deduction_rule);
    },
    updateScreen()
    {
      this.init(this.filter);
    },
    showDeductionRulesModal(deduction_rule_uuid)
    {
      this.$refs.modalTaxRates.openModalWithId(deduction_rule_uuid);
    },
    newValidity(tribute, index){
      this.$refs.modalTaxRates.closeModal();
      this.$refs.modalCreateValidity.handleCreateModalWithIndex(tribute, index);
    },
    /**
     * Calcula a porcentagem de base de cálculo ou dedução do tributo
     *
     * @param {object} tribute - Um objeto do tipo Tribute.
     * @param {string} property - Uma string que pode ser 'base' ou 'deduct'
     * @returns {string} Um valor de 0 à 100 em formato de porcentagem.
     */
    verifyCondition(tribute, property){
      if(tribute
        && tribute.condition
        && tribute.deduct_mcc_cost
        && tribute.condition.toLowerCase() === "adotar sempre o mcc"){
        return "Variável pelo MCC";
      }

      if (tribute && (!tribute.condition || tribute.condition.toLowerCase() !== "adotar sempre o mcc")) {
        return tribute[property] + '%';
      }

      return property === 'base' ? '100%' : '0.00%';
    },
    verifyText(object, property){
      let txt = object && object[property] !== null ? object[property] : 'n/a';
      return txt;
    },
    getLastValitity(tribute){
      let validity = null;

      for (const rule of tribute.deduction_rules) {
        const ruleDate = moment(rule.date_at);

        if (ruleDate.isSameOrBefore(moment()) && (!validity || ruleDate.isAfter(moment(validity.date_at)))) {
          validity = rule;
        }
      }

      return validity;
    },
    handleDeleteTax(uuid) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$notify({
            type: "info",
            message: "Estamos trabalhando em sua solicitação.",
          });
          this.$store
            .dispatch("TaxLocality/destroy", uuid)
            .then((response) => {
              this.$Progress.finish();
              this.$notify({ type: "success", message: response.message });
            })
            .catch((error) => {
              this.$notify({ type: error.data.error_type, message: error.data.message });
              this.$Progress.finish();
            });
        }
      });
    },
    handleDetailDeductionRules(tribute) {
      this.$router.push(`/configuration/fees-and-taxes/${tribute.uuid}/deduction_rules`);
    },
    handleEditTax(tribute) {
      this.$refs["modal-edit"].handleEditModal(tribute);
    },
    handleListTaxRates(tribute, index){
      this.$refs.modalTaxRates.openModal(tribute, index);
    },
    handleLocateCpfCnpj(tribute) {
      this.tributeSelected = tribute;
      this.$refs.modalSearchEntity.openModal();
    },
    updateTributeWithselectedEntity(entity) {
      if (entity) {
        this.tributeSelected.entity_id = entity.id;
        this.tributeSelected.entity = entity;
      } else {
        this.tributeSelected.entity_id = null;
        this.tributeSelected.entity = null;
      }
      this.$Progress.start();
      this.$store
        .dispatch("TaxLocality/update", this.tributeSelected)
        .then((response) => {
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
        })
        .catch((error) => {
          if (error.response.status === 422) {
            let message = formatErrorValidation(error.response.data.errors);
            this.$notify({ type: "danger", message: message });
          } else {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message,
            });
          }
          this.$Progress.finish();
        });
    },
    setViewType(type){
      this.listType = type;
      setPreferredViewMode(type);
    },
    handleCreate() {
      this.$refs.ModalCreate.handleCreateModal(this.tax)
    },
    handleOpenModalConstructionCities(){
      this.$refs.ModalConstructionCities.handleCreateModal();
    },
    getCountries() {
      this.$Progress.start();
      this.$store.dispatch('localizations/fetchCountries')
        .then((response) => {
          this.loadingCountries = false
          this.$Progress.finish()
        })
        .catch((error) => {
          this.loadingCountries = false
          this.$Progress.finish()
        });
    },
    init(filter = null) {
      this.startCursor(filter)
      this.getCountries()
      this.$store.dispatch('FeesAndTaxes/show', this.$route.params.uuid)
        .then(response => {
          this.$Progress.start()
          let params = {
            id: this.tax.id
          };
          this.$refs['listTributes'].loadingSkeleton = true
          this.$store.dispatch('TaxLocality/fetchItemsPaginate', {
              filter: this.filter,
              next_page: this.paginate.nextUrl,
              id: this.tax.id,
            })
            .then(response => {
              if(this.paginate.nextUrl) this.tributes = this.tributes.concat(response.data);
              else this.tributes = response.data;
              this.resolveCursor(response);
              this.$refs['listTributes'].loadingSkeleton = false
            }).catch(error => {
              this.resolveCursor()
              this.$refs['listTributes'].loadingSkeleton = false
          })
        }).catch(error => {
      })
    }
  },
};
</script>

<style scoped>
.gray-divider {
  background-color: #2b2d32;
  opacity: 0.1;
  color: #2b2d32;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
