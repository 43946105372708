<template>
<div>
  <module-header></module-header>
  <div class="container-fluid">
    <card>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(store)"
            autocomplete="off"
          >
            <div>
              <div class="card-header border-header-default-bottom p-2 grid-col--fixed-left mb-2">
                <h5 class="mb-0" slot="title">Identificação</h5>
              </div>

              <div class="form-group row m-0 p-0 m-1">
                <div class="col-md-6">
                  <div class="pt-1 pb-0 mb-1 col-form-label form-control-label">
                    Descrição
                    <span class="text-danger">&nbsp;*</span>
                  </div>
                  <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-classes="form-control form-control-sm">
                          <input
                            type="text"
                            v-model="addParameter.description"
                            class="form-control form-control-sm"
                            :class="errors[0] ? 'is-invalid' : 'is-valid'"
                          />
                        </base-input>
                  </validation-provider>
                </div>

                <div class="col-md-6">
                  <div class="pt-1 pb-0 mb-1 col-form-label form-control-label">
                    Central
                    <span class="text-danger">&nbsp;*</span>
                  </div>
                    <validation-provider rules="required" v-slot="{errors}">
                          <base-input input-classes="form-control form-control-sm" >
                            <puzl-select
                              v-model="addParameter.company_plants"
                              :items="$_plants"
                              :multiple="true"
                              :loading="loadingCompanyPlant"
                              :disabled="loadingCompanyPlant" />
                          </base-input>
                    </validation-provider>
                </div>
              </div>

              <div class="border-header-danger-bottom mb-2 p-2">
                <h5 class="mb-0" slot="title">Parâmetros</h5>
              </div>

              <div class="form-group row m-0 p-0 m-1">
                <div class="col-md-4">
                  <div class="pt-1 pb-0 mb-1 col-form-label form-control-label">
                      Validade Padrão (Proposta/Contrato)
                    <span class="text-danger">&nbsp;*</span>
                  </div>
                  <validation-provider rules="required|max_value:999" v-slot="{ errors }">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        name="validate"
                        inputmode="numeric"
                        v-mask="['###']"
                        v-model="addParameter.expiration_months"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          Dias
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>

                <div class="col-md-4">
                  <div class="pt-1 pb-0 col-form-label form-control-label">
                    Usinagem
                    <span class="text-danger">&nbsp;*</span>
                  </div>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        inputmode="numeric"
                        type="text"
                        v-model="addParameter.machining_cost"
                        class="form-control form-control-sm"
                        v-mask="['#.##','##.##', '###.##']"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          /<span style="text-transform: none">m</span><sup>3</sup>
                        </small>
                      </template>
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0">
                          R$
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>

                <div class="col-md-4">
                  <div class="pt-1 pb-0 col-form-label form-control-label">
                    Impostos
                  </div>
                    <base-input input-group-classes="input-group-sm">
                      <input
                        inputmode="numeric"
                        type="text"
                        v-model="addParameter.tax"
                        class="form-control form-control-sm"
                        v-mask="['##']"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          %
                        </small>
                      </template>
                    </base-input>
                </div>
              </div>

              <div class="border-header-default-bottom mb-2 p-2 mt-2">
                <h5 class="mb-0" slot="title">Transporte</h5>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <div class="col-md-4">
                  <div class="pt-1 pb-0 col-form-label form-control-label">
                    Distância máxima
                  </div>
                    <base-input input-group-classes="input-group-sm">
                      <input
                        inputmode="numeric"
                        v-model="addParameter.transport_maximum_distance"
                        class="form-control form-control-sm"
                        v-mask="['##']"
                        @change="setMinimumCost"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          <sup>km</sup>
                        </small>
                      </template>
                    </base-input>
                </div>

                <div class="col-md-4">
                  <div class="pt-1 pb-0 col-form-label form-control-label">
                  Adicional <small>(acima da distância máxima)</small>
                  </div>
                    <base-input input-group-classes="input-group-sm">
                      <input
                        inputmode="numeric"
                        v-model="addParameter.transport_additional_cost"
                        class="form-control form-control-sm"
                        v-mask="['#.##',]"
                        @change="setMinimumCost"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0">
                          R$
                        </small>
                      </template>
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          /km (por m<sup>3</sup>)
                        </small>
                      </template>
                    </base-input>
                </div>

                <div class="col-md-4">
                  <div class="pt-1 pb-0 col-form-label form-control-label">
                    Custo mínimo <small>(até a distância máxima)</small>
                  </div>
                  <base-input input-group-classes="input-group-sm">
                    <input
                      inputmode="numeric"
                      v-model="addParameter.transport_minimum_cost"
                      class="form-control form-control-sm"
                      v-mask="['#.##','##.##', '###.##', '####.##']"
                      disabled
                      readonly="true"
                    />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0">
                        R$
                      </small>
                    </template>
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0">
                        /<span style="text-transform: none">m</span><sup>3</sup>
                      </small>
                    </template>
                  </base-input>
                </div>
              </div>

              <div class="card-header border-header-default-bottom mb-2 p-2 grid-col--fixed-left mt-2">
                <h5 class="mb-0" slot="title">Ciclo</h5>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <div class="col-md-4">
                  <div class="pt-1 pb-0 col-form-label form-control-label">
                    Ciclo máximo
                  </div>
                    <base-input input-group-classes="input-group-sm">
                      <input
                        inputmode="numeric"
                        v-model="addParameter.cycle_maximum_distance"
                        class="form-control form-control-sm"
                        v-mask="['###']"
                        @change="setMinimumCicle"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          min
                        </small>
                      </template>
                    </base-input>
                </div>

                <div class="col-md-4">
                  <div class="pt-1 pb-0 col-form-label form-control-label">
                    Adicional <small>(acima do ciclo máximo)</small>
                  </div>
                    <base-input input-group-classes="input-group-sm">
                      <input
                        inputmode="numeric"
                        v-model="addParameter.cycle_additional_cost"
                        class="form-control form-control-sm"
                        v-mask="['#.##']"
                        @change="setMinimumCicle"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0">
                          R$
                        </small>
                      </template>
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          /min. (por <span style="text-transform: none">m</span><sup>3</sup>)
                        </small>
                      </template>
                    </base-input>
                </div>

                <div class="col-md-4">
                  <div class="pt-1 pb-0 col-form-label form-control-label">
                    Custo mínimo <small>(até a ciclo máximo)</small>
                  </div>
                  <base-input input-group-classes="input-group-sm">
                    <input
                      inputmode="numeric"
                      v-model="addParameter.cycle_minimum_cost"
                      class="form-control form-control-sm"
                      v-mask="['#.##','##.##', '###.##', '####.##']"
                      disabled
                      readonly
                    />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0">
                        R$
                      </small>
                    </template>
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0">
                        /<span style="text-transform: none">m</span><sup>3</sup>
                      </small>
                    </template>
                  </base-input>
                </div>
              </div>

              <div class="border-header-pink-bottom mb-2 p-2">
                <h5 class="mb-0" slot="title">Puzl Connect</h5>
              </div>

              <div class="form-group row m-0 p-0 mt-3">
                <label
                  class="col-md-5 pt-1 pb-0 mb-1 col-form-label form-control-label"
                >
                  <span class="text-purple">
                    Acréscimo Puzl Connect
                  </span>
                </label>
                <div class="col-md-7">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        inputmode="numeric"
                        type="text"
                        v-model="addParameter.puzl_addition"
                        class="form-control form-control-sm"
                        v-mask="['#','##']"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          %
                        </small>
                      </template>
                    </base-input>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                :loading="loading"
              >
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
    </card>
  </div>
</div>
</template>
<script>
import { mapGetters } from "vuex";
import  ModuleHeader from './Shared/_Header';
import { formatErrorValidation } from "@/plugins";
import PuzlSelect from "@/components/PuzlSelect";
export default {
  components: {
    ModuleHeader,
    PuzlSelect,
  },
  name: "Create",
  data() {
    return {
      validated: false,
      addParameter: {
        description: '',
        company_plants: [],
        expiration_months: '',
        machining_cost: '',
        tax: 0,
        transport_minimum_cost: 0,
        transport_maximum_distance: 0,
        transport_additional_cost: 0,
        cycle_maximum_distance: 0,
        cycle_additional_cost: 0,
        puzl_addition: 0,
      },
      loading: false,
      loadingCompanyPlant: true,
    };
  },
  computed: {
    ...mapGetters({
      $_plants: "plant/activeItems",
    }),
  },
  methods: {
    store(){
        this.$Progress.start();
        this.loading = true;
        this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
        this.$axios.post("/commercial/concrete-price/minimum-costs", this.addParameter)
          .then(({data}) => {
            this.$notify({ type: data.error_type, message: data.message });
            this.loading = false;
            this.$Progress.finish();
            this.$router.push({
              path: '/commercial/concrete-price/parameter',
              name: 'Parâmetros de preços mínimo de concreto',
            })
          })
          .catch((error) => {
            if (error.status) {
            this.$notify({ type: "danger", message: error.data.message });
          } else {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors
            });
          }
          this.loading = false;
          this.$Progress.finish();
          });
    },
    setMinimumCost(){
      let distance = this.addParameter.transport_maximum_distance;
      let addtional = this.addParameter.transport_additional_cost;

      if(distance || addtional){
        let total = distance * addtional;
        this.addParameter.transport_minimum_cost= parseFloat(total).toFixed(2);
      }
    },
    setMinimumCicle(){
      let distanceCycle = this.addParameter.cycle_maximum_distance;
      let addtionalCycle = this.addParameter.cycle_additional_cost;

      if(distanceCycle || addtionalCycle){
        let totalCycle = distanceCycle * addtionalCycle;
        this.addParameter.cycle_minimum_cost= parseFloat(totalCycle).toFixed(2);
      }
    },
  },
  mounted() {
    this.$refs.formValidator.validate();
    this.$store.dispatch("plant/fetchItemsActive").then((response) => {
      this.loadingCompanyPlant=false;
    });
  }
};
</script>
<style></style>
