export { default as operationSourceCreateService } from "./operationSourceCreateService";
export { default as operationSourceDeleteService } from "./operationSourceDeleteService";
export { default as operationSourceFindService } from "./operationSourceFindService";
export { default as operationSourceListService } from "./operationSourceListService";
export { default as operationSourceUpdateService } from "./operationSourceUpdateService";
export { default as operationSourceUpdateStatusService } from "./operationSourceUpdateStatusService";

/** Urls para acesso externo */
export const operationSourceUrls = Object.freeze({
  BASE: "/fiscal/operation-sources",
});
