import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import Tutorial from '@/views/Modules/Tutorial/Index.vue';
import Deploy from '@/views/Modules/Support/Deploy/Index.vue'
import auth from "@/middleware/auth";

const tutorials =
  {
    path: '/tutorial',
    component: DashboardLayout,
    name: 'Tutoriais',
    children: [
      {
        path: '/tutorial',
        name: 'support.index',
        component: Tutorial,
        meta: {
          title: 'Tutoriais',
          description: 'Tutoriais Puzl',
          icon: 'fa fa-home',
          middleware: false,
          permission: 'support.index.view',
          breadcrumbs: [
            {title: 'Tutoriais', name: 'support.dashboard.index'},
            {title: 'Tutoriais', name: null},
          ]
        }
      },
      {
        path: '/support/deploy',
        name: 'deploy.index',
        component: Deploy,
        meta: {
          title: 'Deploy',
          description: 'Deploy',
          icon: 'fa-solid fa-rocket',
          middleware: auth,
          permission: 'deploy.index.view',
          breadcrumbs: [
            {title: 'Suporte', name: 'deploy.index'},
            {title: 'Deploy', name: null},
          ]
        }
      },
    ]
  };

export default tutorials;
