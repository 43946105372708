import axios from "axios";
import router from "@/routes";
import store from "@/store";
import * as storage from "@/plugins/storage";
import * as session from "@/plugins/session";
import dayjs from "dayjs";

import Vue from "vue";

let qs = require("qs");
const enotas_url = "https://nfse-microservice.puzl.place/";
const enotas_token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjEzRDBGQTA3MTFFNkVDMzM5RTUwRkMyRDhFQTczNkVGIn0.VK-3uHq8K1jMtXcI0ZVRn1CYOsHKhZ5pgCgjJ26N6pM";
const msmigrate_url = "https://migrate-ms.puzl.place/";
const msmigrate_token = "18ef769adb624c02a3c385410d62d831";
const token_whatsapp = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImZlbGlwZWRldmxwQGdtYWlsLmNvbSIsImlhdCI6MTcwNjYxNTE5OCwiZXhwIjo0ODYyMzc1MTk4fQ.k1d-URp0kGCDhwOa8grcTPnZ7NY-022SU1HswTkKE88`;

let baseURL = process.env.VUE_APP_BASE_URI;
let baseURLMS = process.env.VUE_APP_BASE_URI_MS;
let baseURLENOTAS = process.env.VUE_APP_BASE_URI_ENOTAS;
let baseURLWHATSAPP = process.env.VUE_APP_BASE_URI_WHATSAPP;
let baseURLMSMIGRATE = process.env.VUE_APP_BASE_URI_MSMIGRATE;
if (!baseURL) {
  let host = window.location.host;
  let subdomain = host.split(".")[0];
  baseURL = `https://${subdomain}.puzl.place/api/v1/`;
}

axios.defaults.baseURL = baseURL;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.common["X-localization"] = "pt_BR";

const paramsSerializerFunction = (params) => {
  return qs.stringify(params, { arrayFormat: "brackets" });
};

const requestInterceptor = (request) => {
  request.paramsSerializer = (params) =>
    qs.stringify(params, {
      serializeDate: (date) => dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
    });
  if (request.url.includes("microservice")) {
    if (!baseURLMS && process.env.NODE_ENV === "development") {
      request.baseURL = "microservice/v1/";
    } else if (baseURLMS && process.env.NODE_ENV === "development") {
      request.baseURL = baseURLMS;
    } else {
      request.baseURL = baseURL.replace("api", "microservice");
    }
  } else if (request.url.includes("enotas")) {
    if (!baseURLENOTAS && process.env.NODE_ENV === "development") {
      request.baseURL = enotas_url;
    } else if (baseURLENOTAS && process.env.NODE_ENV === "development") {
      request.baseURL = baseURLENOTAS;
    } else {
      request.baseURL = enotas_url;
    }
  } else if (request.url.includes("migrate-ms")) {
    if (!baseURLMSMIGRATE && process.env.NODE_ENV === "development") {
      request.baseURL = msmigrate_url;
    } else if (baseURLMSMIGRATE && process.env.NODE_ENV === "development") {
      request.baseURL = baseURLMSMIGRATE;
    } else {
      request.baseURL = msmigrate_url;
    }
  } else if (request.url.includes("whatsapp")) {
    request.baseURL = "https://api.whatsapp.puzl.place/";
  } else {
    request.baseURL = baseURL.replace("microservice", "api");
    request.baseURL = baseURL.replace("enotas", "api");
    request.baseURL = baseURL.replace("migrate-ms", "api");
  }
  const token = session.getPuzlToken() || storage.getPuzlToken();
  if (
    token &&
    !request.url.includes("enotas") &&
    !request.url.includes("migrate-ms")
  ) {
    request.headers.Authorization = `Bearer ${token}`;
  }
  if (request.url.includes("enotas")) {
    request.headers.Authorization = enotas_token;
  }
  if (request.url.includes("migrate-ms")) {
    request.headers.Authorization = msmigrate_token;
  }
  if (request.url.includes("whatsapp")) {
    request.headers.Authorization = token_whatsapp;
  }

  request.url = request.url.replace("+microservice", "");
  request.url = request.url.replace("+enotas", "");
  request.url = request.url.replace("+whatsapp", "");
  request.url = request.url.replace("+migrate-ms", "");

  const plant_id = Vue.prototype.$filterable;

  if (!isNaN(plant_id)) {
    request.headers["company-plant-id"] = plant_id.toString();
  } else {
    delete request.headers["company-plant-id"];
  }
  return request;
};

axios.defaults.paramsSerializer = paramsSerializerFunction;
axios.interceptors.request.use(requestInterceptor);

const shouldRetry = (config) => config.retries.count < 3;

const responseInterceptor = (response) => {
  if (response.data instanceof Blob) {
    return response;
  }
  if (response.data instanceof Array) {
    return Promise.resolve(response);
  }
  if (response.headers["set-token"]) {
    storage.setPuzlToken(response.headers["set-token"]);
    session.setPuzlToken(response.headers["set-token"]);
  }
  return response.data.error === false
    ? Promise.resolve(response)
    : Promise.reject(response);
};

const errorInterceptor = (error) => {
  error.config.retries = error.config.retries || {
    count: 0,
  };
  switch (error.response.status) {
    case 400:
      store.dispatch("auth/signOut");
      router.push({ name: "Login" });
      break;
    case 401:
      store.dispatch("auth/signOut");
      router.push({ name: "Login" });
      break;
    case 307:
      error.config.retries.count += 1;
      if (shouldRetry(error.config)) {
        axios.request(error.config);
      } else {
        store.dispatch("auth/signOut");
        router.push({ name: "Login" });
      }
      break;
  }

  return Promise.reject(error);
};

axios.interceptors.response.use(responseInterceptor, errorInterceptor);

// Criar nova instância do axios
export const createAxios = () => {
  const instance = axios.create({});
  instance.interceptors.request.use(requestInterceptor);
  instance.interceptors.response.use(responseInterceptor, errorInterceptor);
  return instance;
};
