<template>
  <div>
    <div class="container-fluid">
      <multi-filter ref="multi-filter" @fetch="init" :filter="filter"/>
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <PuzlEmptyData v-if="!payment_methods.length && !loadingSkeleton">
      </PuzlEmptyData>

      <div
        class="row card-wrapper"
        v-if="payment_methods.length && !loadingSkeleton"
      >
        <div
          class="col-lg-4"
          v-for="(payment_method, index) in payment_methods"
          :key="index"
        >
          <div class="card">
            <!-- Card header -->
            <div class="card-header pointer mb-3" style="padding: 0px !important; height: 22px">
              <div class="row">
                <div class="col-md-12">
                  <base-button @click="updateStatus(payment_method.id, payment_method.status)" size="sm"
                    :style="{ backgroundColor: payment_method.status ? '#149E57' : '#DB4539' }"
                    class="text-white text-uppercase btn-header" style="opacity: 100%; color: #4f40ff; border: none;"
                    block>
                    {{ payment_method.status ? "Ativo" : "Inativo" }}
                  </base-button>
                </div>
              </div>
            </div>
            <div class="row align-items-center mt-n3 p-4">
              <div class="col-8 p-0 mb-2 my-auto pl-3">
                <el-popover trigger="click" placement="right" class="p-0 pr-1">
                  <h5 slot="title" class="mb-0 ">Centrais</h5>
                  <div class="row align-items-center pb-0 mb-3">
                    <div class="col-md-12">
                      <div 
                        v-for="(payment_intermediary, index) in payment_method.payment_intermediaries"
                        :key="index"
                      >
                        <h4>
                          {{ payment_intermediary.name }}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <base-button 
                    outline slot="reference" size="sm" type="secundary"
                    class="p-0 m-0 shadow-none text-indigo"
                  >
                    <img src="/img/icons/escrow-process.png" width="32px" height="32px">
                  </base-button>
                </el-popover>
              </div>
              <div class="col-4 text-right">
                <base-dropdown menuOnRight>
                  <base-button
                    slot="title-container"
                    type="primary"
                    class="dropdown-toggle p-2 rounded m-0"
                  >
                    <i class="fas fa-cog"></i>
                  </base-button>
                  <a
                    class="dropdown-item"
                    @click="handleEdit(payment_method.uuid)"
                  >
                    <img src="/img/icons/create-new.png" width="22px" height="22px">
                    Editar
                  </a>
                  <div v-if="!payment_method.default" class="dropdown-divider p-0 m-0"></div>
                  <a v-if="!payment_method.default" class="dropdown-item" @click="handleDelete(payment_method.uuid)">
                    <i class="fas fa-times text-danger"></i>
                    Excluir
                  </a>
                </base-dropdown>
              </div>
            </div>
            <!-- Card body -->
            <div class="row p-4">
              <div class="col-md-12">
                <h5 class="h3 mb-2 mt-n4">
                  {{ payment_method.name }}
                  <span class="float-right">
                    <el-popover trigger="click" placement="right" class="p-0 pr-1">
                      <div class="row align-items-center pb-0 mb-3">
                        <div class="col-md-12">
                          <div class="py-2">
                            <span v-if="payment_method.allow_approve">
                              <img src="/img/icons/accept.png" width="23px" height="23px">
                            </span>
                            <span v-else>
                              <img src="/img/icons/close.png" width="23px" height="23px">
                            </span>
                            <span class="h4"> Permitir aprovação </span>
                          </div>
                          <div class="py-2">
                            <span v-if="payment_method.transaction_code">
                              <img src="/img/icons/accept.png" width="23px" height="23px">
                            </span>
                            <span v-else>
                              <img src="/img/icons/close.png" width="23px" height="23px">
                            </span>
                            <span class="h4"> Exige código de transação </span>
                          </div>
                          <div class="py-2">
                            <span v-if="payment_method.display_in_anticipation">
                              <img src="/img/icons/accept.png" width="23px" height="23px">
                            </span>
                            <span v-else>
                              <img src="/img/icons/close.png" width="23px" height="23px">
                            </span>
                            <span class="h4"> Exibir em antecipação </span>
                          </div>
                          <div class="py-2">
                            <span v-if="payment_method.is_manual_invoice_receipt">
                              <img src="/img/icons/accept.png" width="23px" height="23px">
                            </span>
                            <span v-else>
                              <img src="/img/icons/close.png" width="23px" height="23px">
                            </span>
                            <span class="h4"> Recebimento de fatura </span>
                          </div>
                          <div class="py-2">
                            <span v-if="payment_method.is_manual_receivable_confirmation">
                              <img src="/img/icons/accept.png" width="23px" height="23px">
                            </span>
                            <span v-else>
                              <img src="/img/icons/close.png" width="23px" height="23px">
                            </span>
                            <span class="h4"> Confirmação de recebíveis </span>
                          </div>
                          <div class="py-2">
                            <span v-if="payment_method.is_manual_supplier_payment">
                              <img src="/img/icons/accept.png" width="23px" height="23px">
                            </span>
                            <span v-else>
                              <img src="/img/icons/close.png" width="23px" height="23px">
                            </span>
                            <span class="h4"> Pagamento de fornecedor </span>
                          </div>
                        </div>
                      </div>
                      <base-button 
                        outline slot="reference" size="sm" type="secundary"
                        class="p-0 m-0 shadow-none text-indigo"
                      >
                        <img src="/img/icons/info.png" width="26px" height="26px">
                      </base-button>
                    </el-popover>
                  </span>
                  <div class="mb-3">
                    <div v-if="payment_method.payment_type ===  0">
                      Débito
                    </div>
                    <div v-else>
                      Crédito
                    </div>
                  </div>
                </h5>  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-edit ref="modalEdit"/>
    <loading-pagination :show="loading && !loadingSkeleton"/>
  </div>

</template>

<script>
import {mapGetters} from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import ModalEdit from "./_ModalEdit.vue";
import MultiFilter from "@/components/Utils/MultiFilterV2";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";

export default {
  name: "ListPaymentMethod",
  mixins: [cursorPaginate],
  components: {
    SkeletonPuzlGrid,
    PuzlEmptyData,
    ModalEdit,
    MultiFilter,
    LoadingPagination,
  },
  data() {
    return {
      loadingSkeleton: false,
      loadingPuzlConnect: false,
      filter: {},
    };
  },
  computed: {
    ...mapGetters({
      payment_methods: "paymentMethod/fetch"
    })
  },
  methods: {
    init(filter = null) {
      this.startCursor(filter)
      this.$Progress.start();
      this.$store
        .dispatch("paymentMethod/fetchItemsPaginate", {
          filter: this.filter,
          next_page: this.paginate.nextUrl
        })
        .then(response => {
          this.resolveCursor(response)
          this.$Progress.finish();
        })
        .catch(error => {
          this.resolveCursor()
          this.$Progress.finish();
        });
    },
    changePuzlCustomer(item) {
      this.loadingPuzlConnect = true;
      item.puzl_customer = !item.puzl_customer
      this.$store.dispatch("paymentMethod/changePuzlConnect", {
        id: item.id,
        puzl_connect: item.puzl_customer ? 1 : 0
      }).then(response => {
        this.$notify({
          type: response.error_type,
          message: response.message
        });
        this.loadingPuzlConnect = false;
      });

    },
    handleEdit(uuid) {
      this.$refs.modalEdit.openModal(uuid);
    },
    handleDelete(uuid) {
      this.$Swal
        .confirmDelete()
        .then(result => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação."
            });
            this.$store.dispatch("paymentMethod/destroy", uuid).then(response => {
              this.$notify({
                type: response.error_type,
                message: response.message
              });
              this.$Progress.finish();
            }).catch(error => {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              });
              this.$Progress.finish();
            })
          }
        })
        .catch(() => this.$Progress.finish());
    },
    /**
     * @param {number} id
     * @param {bool} status
     */
    updateStatus(id, status) {
      status = +!status;
      const payload = {
        id: id,
        status: status,
      };
      this.$store.dispatch("paymentMethod/updateStatus", payload).then(response => {
        this.$notify({
          type: response.error_type,
          message: response.message
        });
      }).catch((error) => {
        this.$notify({
          type: 'danger',
          message: error.data.message
        });
      });
    },
  },
  created() {
    this.init({});
  }
};
</script>

<style scoped></style>
