import * as types from './mutation_types'
import {destroy, update} from '@/store/baseStore'
import { createAxios } from "@/plugins/axios";
const endPoint = '/hardenedstatemold/'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
  },
  getters:{
    show: state => state.item,
    fetch: state => state.items,
    activeItems (state) {
      return state.items.filter(item => item.status === true)
    },
  },
  mutations: {
    [types.DESTROY] (state, uuid){
      destroy(state,uuid)
    },
    [types.UPDATE] (state, payload){
      update(state, payload)
    },
  },
  actions: {
    add({commit, state}, payload) {
      return createAxios().post(endPoint, payload)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    update({commit}, payload) {
      return createAxios().put(endPoint+payload.uuid, payload)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
  }
}
