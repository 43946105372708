<template>
  <div class="container-fluid">
    <div class="card-body mt-6 p-0">
      <div class="row">
        <div class="col-md-12 table-responsive">
          <table class="table table-sm table-bordered" width="100%" cellspacing="0">
            <thead>
              <tr class="fixed">
                <th class="text-center">
                  <div class="form-check">
                    <input
                      style="position: relative;margin-left: -1.5rem;"
                      @click="selectAll()"
                      :checked="selectedAll"
                      class="form-check-input" type="checkbox">
                  </div>
                </th>
                <th class="text-center">STATUS</th>
                <th class="text-center">DOCUMENTOS</th>
                <th class="text-center">FORNECEDOR</th>
                <th class="text-center">CPF/CNPJ</th>
                <th class="text-center">TIPO</th>
                <th class="text-center">VENCIMENTO</th>
                <th class="text-center">DÉBITO</th>
                <th class="text-center">VALOR</th>
                <th class="text-center">AÇÕES</th>
              </tr>
            </thead>
            <tbody v-show="loadingSkeleton">
              <tr v-for="item in 9">
                <th colspan="12">
                  <SkeletonPuzl type="button"/>
                </th>
              </tr>
            </tbody>
            <tbody v-if="bills_items.length && !loadingSkeleton">
              <tr v-for="(bills_item, index) in bills_items" :key="index">
                <td class="text-center">
                  <div class="form-check">
                    <input v-if="bills_item.status.status !== 1 && bills_item.status.status !== 3"
                      @click.prevent="verifyPaidAllotments(bills_item.id, index)"
                      class="form-check-input" type="checkbox">
                    <input v-if="paid_allotments.includes(bills_item.id)"
                      @click.prevent="verifyPaidAllotments(bills_item.id)"
                      class="form-check-input" type="checkbox" checked>
                  </div>
                </td>
                <td class="text-center">
                  <base-dropdown class="w-100" menuOnRight v-if="!bills_item.reconciled && !bills_item.identified">
                    <base-button
                      block
                      size="sm"
                      outline
                      style="opacity: 92%"
                      :loading="loadingInstallment"
                      :type="bills_item.status.color"
                      slot="title-container"
                      :disabled="bills_item.status.status === 1 ? !$hasPermission('bill_pay.reverse_installment') : false"
                      :class="bills_item.status.status === 1 && !$hasPermission('bill_pay.reverse_installment') ? '' : 'dropdown-toggle'"
                      class="active text-uppercase mr-0 btn"
                    >
                      <img
                        v-if="['Atrasado', 'Pendente', 'Cancelado', 'Aprovado'].includes(bills_item.status.text)"
                        width="15px"
                        class="ml-1 pointer"
                        :src="`/img/icons/`+getInstallmentStatusIcon(bills_item.status.text)"
                      />
                      {{ bills_item.status.text }}
                    </base-button>
                    <div>
                      <a
                        @click.prevent="changeInstallmentStatus(bills_item.id, status.value)"
                        :key="statusIndex"
                        v-for="(status, statusIndex) in availableStatuses(bills_item.status.status)"
                        class="dropdown-item"
                      >
                        {{ status.text }}
                      </a>
                    </div>
                  </base-dropdown>
                  <base-button
                      v-else
                      block
                      size="sm"
                      outline
                      style="opacity: 92%"
                      :loading="loadingInstallment"
                      :type="bills_item.status.color"
                      slot="title-container"
                      :disabled="bills_item.status.status === 1 ? !$hasPermission('bill_pay.reverse_installment') : false"
                      :class="bills_item.status.status === 1 && !$hasPermission('bill_pay.reverse_installment') ? '' : 'dropdown-toggle'"
                      class="active text-uppercase mr-0 btn"
                    >
                      <img
                        v-if="['Atrasado', 'Pendente', 'Cancelado', 'Aprovado'].includes(bills_item.status.text)"
                        width="15px"
                        class="ml-1 pointer"
                        :src="`/img/icons/`+getInstallmentStatusIcon(bills_item.status.text)"
                      />
                      {{ bills_item.status.text }}
                  </base-button>
                </td>
                <td>
                  <base-button
                    v-if="bills_item.document_quantity > 0"
                    @click.prevent="handleModalListEntriesLaunches(bills_item.bill_pay_id)"
                    outline
                    slot="reference"
                    size="sm"
                    type="secondary"
                    class="p-0 m-0 shadow-none d-inline"
                    style="border-color:#606062"
                    >
                    <div class="row m-0 pt-1 pb-1 pr-2 pl-2 d-flex align-items-center">
                      <img style="width: 20px;" src="/img/icons/list--v1-indigo-22.png">
                      <small  class="ml-2 font-weight-bold d-flex text-default">{{ bills_item.document_quantity > 1 ?  `Agrupadas (${bills_item.document_quantity })` : bills_item.document_numbers}}</small>
                    </div>
                  </base-button>
                  <el-popover v-if="bills_item.document_observations" trigger="hover" placement="bottom" class="p-0">
                    <span> {{ bills_item.document_observations }} </span>
                    <base-button outline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none">
                      <img class="ml-1"  style="width: 20px" src="/img/icons/speech-bubble-with-dots--v1-black.png">
                    </base-button>
                  </el-popover>
                  <base-button v-if="bills_item.attachment_url" @click.prevent="handleModalBillPayInstallmentAttachment(bills_item)" outline size="sm" type="secundary" class="p-0 m-0 shadow-none">
                    <img class="ml-1"  style="width: 20px" src="/img/icons/attach-blue-50.png">
                  </base-button>
                  <span v-show="bills_item.payment_method_id && [1, 4].includes(bills_item.payment_method_id)" @click.prevent="handleEntityAccount(bills_item.entity_id)">
                    <img
                      width="20px"
                      class="ml-1 pointer"
                      src="/img/icons/bank-building-red.png"
                    />
                  </span>
                  <!-- anexo -->
                  <base-button v-if="bills_item.document_photo" @click.prevent="handleModalAttachment(bills_item.document_photo)" outline size="sm" type="secundary" class="p-0 m-0 shadow-none">
                    <img class="ml-1"  style="width: 20px" src="/img/icons/attach-green.png">
                  </base-button>
                  <!-- Saldo -->
                  <el-popover v-show="bills_item.balance !== '0.00'" trigger="hover" placement="bottom"
                    class="p-0 pr-0">
                    <span>
                      Saldo: <b>{{ bills_item.balance | currency }} </b>
                    </span>
                    <base-button outline slot="reference" size="sm" type="secundary"
                      class="p-0 m-0 shadow-none text-success">
                      <i style="font-size: 20px !important" class="fas fa-dollar-sign" />
                    </base-button>
                  </el-popover>
                  <!-- Conciliação -->
                  <el-popover v-if="bills_item.reconciled" trigger="hover" placement="bottom" class="p-0 pr-0">
                    <span>Conciliado por: {{ bills_item.reconciled_user }} </span>
                    <base-button outline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none">
                      <i style="font-size: 20px !important" class="fas fa-link text-primary text-center" />
                    </base-button>
                  </el-popover>
                </td>
                <td>
                  <!-- modal de informações -->
                  <span
                    class="pointer"
                    @click.prevent="handleModalInfo(bills_item)"
                  >
                    <img class="ml-1 mr-1" style="width: 20px;padding-top:2px" src="/img/icons/info.png">
                  </span>
                  {{ bills_item.entity_name }}
                </td>
                <td>
                  {{ bills_item.entity_document }}
                </td>
                <td>
                  {{ bills_item.type_name }}
                </td>
                <td>
                  <div class="text-center" style="margin-top: -7px;margin-left: -10px">
                    <small>{{ bills_item.due_date | parseDate('DD MMM YYYY')}}</small>
                    <el-popover v-if="bills_item.bank_account_id"
                      trigger="hover"
                      placement="left"
                      class="p-0 pr-0">
                      <span> {{ bills_item.bank_account_name }} </span>
                      <img
                        slot="reference"
                        width="20px"
                        class="mr-1 ml-1 pointer"
                        src="/img/icons/merchant-account.png"
                      />
                    </el-popover>
                  </div>
                </td>
                <td>
                  <div class="text-center" style="margin-top: -7px;margin-left: -10px">
                    <small> {{ bills_item.paid_at }} </small>
                    <base-button
                      outline
                      slot="reference"
                      size="sm"
                      type="secundary"
                      v-if="['Pago', 'Aprovado'].includes(bills_item.status.text)"
                      @click="getPaymentAuthorizationProof(bills_item.id)"
                      class="p-0 mt-1 shadow-none text-indigo"
                      title="Imprimir Autorização"
                    >
                      <img
                        width="20px"
                        class="mr-1 ml-1 pointer"
                        src="/img/icons/print--v1-green-26.png"
                      />
                    </base-button>
                  </div>
                </td>
                <td>
                  {{ bills_item.total_value | currency() }}
                  <badge style="font-size: 12px !important;" type="primary" class="font-weight-500 text-white">
                      {{ bills_item.index + "/" + bills_item.last_index }}
                  </badge>
                </td>
                <td>
                  <base-dropdown menuOnRight>
                    <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                      <i class="fas fa-cog"></i>
                    </base-button>
                    <a class="dropdown-item" @click.prevent="handleHistory(bills_item.id)">
                      <i class="fas fa-history text-indigo main-icon-menu"></i>
                      Histórico
                    </a>
                    <a v-if="bills_item.status.status !== 1 && bills_item.status.status !== 3" class="dropdown-item"
                      @click.prevent="handleAgroup(bills_item.bill_pay_id, bills_item.entity_id)">
                      <i class="fas fa-layer-group text-primary main-icon-menu"></i>
                      Agrupar
                    </a>
                    <a @click.prevent="handleEditBillPay(bills_item.bill_pay_id, bills_item.index)" class="dropdown-item">
                      <i class="fas fa-edit text-warning main-icon-menu"></i>
                      Editar
                    </a>
                    <a v-if="bills_item.status.status !== 1" @click.prevent="handleDeleteBillPayInstallment(bills_item.id)"
                      class="dropdown-item">
                      <i class="fas fa-times text-danger main-icon-menu"></i>
                      Excluir
                    </a>
                    <a
                      v-if="['Atrasado', 'Pendente'].includes(bill_pay_installment_status[bills_item.status.status])"
                      @click.prevent="handleModalShareInstallment(bills_item)"
                      class="dropdown-item"
                    >
                        <i class="text-success fa-solid fa-arrows-split-up-and-left main-icon-menu"></i>
                        Dividir
                    </a>
                    <a
                      v-if="!['Pago', 'Cancelado'].includes(bill_pay_installment_status[bills_item.status.status]) && bills_item.document_quantity > 1"
                      @click.prevent="removeAgroup(bills_item.bill_pay_id)"
                      class="dropdown-item"
                    >
                      <i class="fa fa-undo main-icon-menu text-primary"></i>
                      Desagrupar
                    </a>
                  </base-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <loading-pagination :show="loading && !loadingSkeleton"/> 
    </div>
  </div>
</template>
<script>
import SkeletonPuzl from "@/components/SkeletonPuzl";
import LoadingPagination from "@/components/LoadingPagination";

export default {
  name: "TableBillPay",
  props: {
    loading: Boolean,
    bills_items: Array,
    paid_allotment: Boolean,
    paid_allotments: Array,
    loadingSkeleton: Boolean,
    loadingInstallment: Boolean,
    getInstallmentStatusIcon: { type: Function },
    availableStatuses: { type: Function },
    bill_pay_installment_status: Object,
    handleOpenModalPaidAllotments: { type: Function },
    handleHistory: { type: Function },
    handleEditBillPay: { type: Function },
    handleDeleteBillPayInstallment: { type: Function },
    handleModalShareInstallment: { type: Function },
    handleModalInfo: { type: Function },
    handleAgroup: { type: Function },
    handleModalListEntriesLaunches: { type: Function },
    handleModalAttachment: { type: Function },
    getPaymentAuthorizationProof: { type: Function },
    handleEntityAccount: { type: Function },
    changeInstallmentStatus: { type: Function },
    verifyPaidAllotments: { type: Function },
    removeAgroup: { type: Function },
    selectAll: { type: Function },
    selectedAll: Boolean,
  },
  components: {
    LoadingPagination,
    SkeletonPuzl
  },
};
</script>
<style scoped>

</style>
