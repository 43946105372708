<template>
  <modal size="lg" :show.sync="modal" class="pb-5">
    <template slot="header">
      <h5 class="modal-title p-0 m-0">
        <img src="/img/icons/icons8/ios/menu-squared-2-white.png" height="25" class="mr-2" />
        Bandeira de cartões
      </h5>
    </template>
    <div class="p-3">
      <!-- Adicionar item -->
      <AddItem />

      <div class="gray-divider mb-3 mt-4" />
      <div class="d-flex align-items-center">
        <img class="mr-1" width="25" height="25" src="/img/icons/icons8/ios/hierarchy_danger.png" alt="info-squared" />
        <span style="font-size: 18px; color: #2b2d32; font-weight: 500" class="ml-2 mr-3 text-nowrap">Cadastradas</span>
        <div class="gray-divider mb-2" />
      </div>

      <!-- Listagem -->
      <List />
    </div>
    <div class="modal-footer">
      <base-button type="danger" @click="closeModal()">
        <img src="/img/icons/cancel-white.png" width="22px" height="22px" class="mr-2" />
        Sair
      </base-button>
    </div>
  </modal>
</template>

<script>
import AddItem from "./_AddItem.vue";
import List from "./_List.vue";

export default {
  name: "ModalPaymentBrand",
  data() {
    return {
      modal: false,
    };
  },
  components: {
    AddItem,
    List,
  },
  methods: {
    async openModal() {
      const loader = this.$loading.show();
      await this.$store.dispatch("paymentBrand/fetchItems");
      loader.hide();
      this.modal = true;
    },
    closeModal() {
      this.modal = false;
    },
  },
};
</script>

<style scoped>
.gray-divider {
  background-color: #2b2d32;
  opacity: 0.1;
  color: #2b2d32;
  height: 1px;
  width: 100%;
}
</style>
