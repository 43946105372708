<template>
  <div>
    <modal :show.sync="modal.create" size="md">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form v-if="form_data" class="needs-validation" @submit.prevent="handleSubmit(saveFiles)" autocomplete="off">
            <div class="form-group row m-0 p-0">
              <label class="col-md-6 pb-0 pb-2 mb-1 col-form-label form-control-label">
                Data de Cancelamento
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-6">
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-group-classes="input-group-sm">
                    <input-date-time-picker v-model="form_data.cancellation_date" />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <label
                class="col-md-12 pb-2 mb-1 col-form-label form-control-label">
                Arquivo XML
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-12 text-right">
                <base-input input-group-classes="input-group-sm">
                  <InputFile
                    :accept="'.xml'"
                    :multiple="false"
                    :helpText="'Escolha o arquivo ou arraste e solte aqui'"
                    :errorTextType="'Tipo de arquivo inválido. Apenas arquivo com extensão .xml permitido'"
                    ref="inputXmlFile"
                  />
                </base-input>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <label
                class="col-md-12 pb-2 mb-1 col-form-label form-control-label">
                Arquivo PDF
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-12 text-right">
                <base-input input-group-classes="input-group-sm">
                  <InputFile
                    :accept="'.pdf'"
                    :multiple="false"
                    :helpText="'Escolha o arquivo ou arraste e solte aqui'"
                    :errorTextType="'Tipo de arquivo inválido. Apenas arquivo com extensão .pdf permitido'"
                    ref="inputPdfFile"
                  />
                </base-input>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" :disabled="invalid" :loading="isLoading">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import InputFile from "@/components/Utils/InputFile";
import InputDateTimePicker from "@/components/InputDateTimePickerV2";
import moment from "moment"
export default {
  name: "ModalNfseCanceledImport",
  components: {
    InputFile,
    InputDateTimePicker,
  },
  data() {
    return {
      modal: {
        title: 'Importar NFS-e "Cancelada"',
        create: false,
      },
      current_invoice: null,
      form_data: null,
      form_data_default: {
        invoice_id: null,
        cancellation_date: null,
        xml_file: null,
        pdf_file: null,
      },
      isLoading: false,
      reverse: 0,
    };
  },
  methods: {
    handleCreateModal(invoice, reverse = 0){
      if (this.$refs["inputPdfFile"]) this.$refs["inputPdfFile"].fileRecords = []
      if (this.$refs["inputXmlFile"]) this.$refs["inputXmlFile"].fileRecords = []
      this.form_data = this.$helper.cloneObject(this.form_data_default);
      this.form_data.invoice_id = invoice.id;
      this.current_invoice = invoice;
      this.reverse = reverse
      this.modal.create = true;
    },
    closeModal() {
      this.modal.create = false;
      this.$emit("close");
    },
    saveFiles(){
      const informedFiles = ((this.$refs["inputPdfFile"].fileRecords.length > 0) || (this.$refs["inputXmlFile"].fileRecords.length > 0));
      if (!informedFiles) {
        this.$notify({ type: "danger", message: "Arquivo PDF e XML são obrigatórios."});
        return;
      }

      let files = [];
      this.$refs["inputPdfFile"].fileRecords.forEach(el => {
        files.push(el.file)
      });

      this.form_data.pdf_file = this.$refs["inputPdfFile"].fileRecords[0].file;
      this.form_data.xml_file = this.$refs["inputXmlFile"].fileRecords[0].file;
      this.form_data.reverse = this.reverse;
      this.form_data.cancellation_date = moment(this.form_data.cancellation_date).format('YYYY-MM-DD HH:mm:ss')
      this.isLoading = true
      this.$Progress.start()
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      })
      this.$store
        .dispatch("nfse/saveImportedCanceledNfseXmlPdf", this.form_data)
        .then((response) => {
          this.isLoading = false
          this.$Progress.finish()
          this.$notify({
            type: "success",
            message: "Solicitação realizada com sucesso!",
          })
          this.current_invoice.nfse = this.form_data.nfse
          this.modal.create = false;
          this.$emit("wasCanceled", this.current_invoice.id);
        })
        .catch((error) => {
          let errors = error.status
            ? error.data.message
            : formatErrorValidation(error.response.data.errors);
          this.$notify({ type: "danger", message: errors });
          this.isLoading = false;
          this.$Progress.finish();
        })
    }
  },
};
</script>

<style scoped>

</style>
