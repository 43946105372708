<template>
  <div>
    <modal :show.sync="modal" size="lg">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(save)"
            autocomplete="off"
          >
            <div>
              <div class="card-header border-header-default-bottom p-1 mb-2 mt-2">
                <h6 class="h4 mb-0">Endereço</h6>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-2 col-form-label form-control-label">
                  Status
                </label>
                <div class="col-md-3 pt-2">
                  <base-switch v-model="payload.status" type="success" class="primary"></base-switch>
                </div>
                <label class="col-md-2 col-form-label form-control-label text-right">
                  Padrão
                </label>
                 <div class="col-md-3 pt-2 text-right">
                  <base-switch v-model="payload.default" type="success" class="primary"></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <div class="col-md-4">
                  <div class="pb-0 col-form-label form-control-label">
                    CEP<span class="text-danger">&nbsp;*</span>
                  </div>
                  <validation-provider rules="required|min:9" v-slot="{errors}">
                    <base-input input-group-classes="form-control-sm input-group-sm">
                      <input
                        type="text"
                        v-model="payload.postal_code"
                        class="form-control form-control-sm"
                        v-mask="['#####-###']"
                        v-on:blur="getAddressByCode"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>

                <div class="col-md-4">
                  <div class="pb-0 col-form-label form-control-label">
                    UF<span class="text-danger">&nbsp;*</span>
                  </div>
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <puzl-select
                        v-model="payload.state"
                        :items="states"
                        customKey="letter"
                        label="letter"
                        :disabled="checkAddress" />
                    </base-input>
                  </validation-provider>
                </div>

                <div class="col-md-4">
                  <div class="pb-0 pr-0 col-form-label form-control-label">
                    Cidade <span class="text-danger">&nbsp;*</span>
                  </div>
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <puzl-select
                        v-model="payload.city"
                        :items="cities"
                        label="title"
                        :disabled="checkAddress" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <div class="col-md-12">
                  <div class="pb-0 col-form-label form-control-label">
                    Endereço<span class="text-danger">&nbsp;*</span>
                  </div>
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="form-control-sm input-group-sm">
                      <input
                        type="text"
                        v-model="payload.address"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <div class="col-md-4">
                  <div class="pb-0 pr-0 col-form-label form-control-label">
                    N°<span class="text-danger">&nbsp;*</span>
                  </div>
                  <base-input input-group-classes="input-group-sm">
                    <input maxlength="5"
                           v-if="!without_number"
                           v-model="payload.number"
                           v-mask="'#####'"
                           inputmode="numeric"
                           class="form-control form-control-sm"
                    />
                    <input v-else maxlength="5"
                           v-model="payload.number"
                           disabled
                           type="text"
                           inputmode="numeric"
                           class="form-control form-control-sm"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0" style="text-transform: none">
                        <a href="#" @click.prevent="without_number = !without_number">
                          <i v-if="without_number" class="fa-solid fa-square-check"></i>
                          <i v-else class="fa-regular fa-square"></i>
                        </a> Sem número
                      </small>
                    </template>
                  </base-input>
                  <!--                  <validation-provider rules="required" v-slot="{errors}">-->
                  <!--                    <base-input input-group-classes="form-control-sm input-group-sm">-->
                  <!--                      <input-->
                  <!--                        type="text"-->
                  <!--                        v-model="construction.number"-->
                  <!--                        maxlength="10"-->
                  <!--                        input-group-classes="input-group-sm"-->
                  <!--                        class="form-control form-control-sm"-->
                  <!--                        :class="errors[0] ? 'is-invalid' : 'is-valid'"-->
                  <!--                      />-->
                  <!--                    </base-input>-->
                  <!--                    <template slot="append">-->
                  <!--                      <small class="input-group-sm p-0 m-0">-->
                  <!--                        /min. (por m<sup>3</sup>)-->
                  <!--                      </small>-->
                  <!--                    </template>-->
                  <!--                  </validation-provider>-->
                </div>
                <div class="col-md-4">
                  <div class="pb-0 col-form-label form-control-label">
                    Complemento
                  </div>
                  <base-input input-group-classes="form-control-sm input-group-sm">
                    <input
                      type="text"
                      v-model="payload.complement"
                      maxlength="20"
                      class="form-control form-control-sm"
                    />
                  </base-input>
                </div>

                <div class="col-md-4">
                  <div class="pb-0  pr-0 col-form-label form-control-label">
                    Bairro <span class="text-danger">&nbsp;*</span>
                  </div>
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="form-control-sm input-group-sm">
                      <input
                        type="text"
                        v-model="payload.district"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">
                Cancelar
              </base-button>
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                :loading="loadingSave"
              >
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
const {formatErrorValidation} = require("@/plugins")
import axios from 'axios'
import {viacep} from "@/helpers";
import PuzlSelect from "@/components/PuzlSelect";
export default {
  name: "ModalCreateAddress",
  components: {
    PuzlSelect,
  },
  data () {
    return {
      modal: false,
      title: 'Adicionar endereço da obra',
      without_number: false,
      payload: {
        construction_uuid: '',
        default: false,
        alias: '',
        postal_code: '',
        district: '',
        state: '',
        city: '',
        address: '',
        number: '',
        country: 'Brasil',
      },
      loadingSave: false,
      loadingStates: true,
      loadingCities: true,
      checkAddress: false,
    }
  },
  computed: {
    ...mapGetters({
      'states': 'localizations/states',
      'cities': 'localizations/cities',
    }),
  },
  watch: {
    'payload.state': function(value){
      this.getCities(value)
    },
    'without_number' : function (val) {
      if (val) {
        this.payload.number = 'SN'
      }
      else {
        this.payload.number = ''
      }
    },
  },
  methods: {
    closeModal () {
      this.modal = false
      this.checkAddress = false
    },
    handleCreateModal (constructionUuid) {
      this.without_number = false
      this.payload = {
          status: true,
          default: false,
          construction_uuid: constructionUuid,
          alias: '',
          postal_code: '',
          district: '',
          state: '',
          city: '',
          address: '',
          number: '',
          country: 'Brasil',
      }
      this.modal = true
    },
    save () {
      this.$Progress.start()
      this.loadingSave = true
      this.$store.dispatch('addressConstruction/add', this.payload)
      .then(response => {
        this.$emit('createAddress', response)
        this.$Progress.finish()
        this.$notify({ type: response.error_type, message: response.message })
        this.loadingSave = false
        if(!response.error){
        this.modal = false
        }
      })
      .catch(error =>{
        if (error.status) {
          this.$Progress.finish()
          this.loadingSave = false
          this.$notify({
            type: error.data.error_type,
            message: error.data.message
          })
        } else {
          if (error.response.status === 422) {
            let message = formatErrorValidation(error.response.data.errors)
            this.$notify({
              type: 'danger',
              message: message
            })
            this.$Progress.finish()
            this.loadingSave = false
          } else {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            })
            this.$Progress.finish()
            this.loadingSave = false
          }
        }
      })
    },
    getCities(state) {
      this.$Progress.start();

      this.$store.dispatch('localizations/fetchCities', { uf: state })
        .then((response) => {
          this.loadingCities = false
          this.$Progress.finish()
        })
        .catch((error) => {
          this.loadingCities = false
          this.$Progress.finish()
        });
    },
    getAddressByCode() {
      this.$Progress.start();
      let postal_code = this.payload.postal_code.replace(/[^\d]+/g, '')
      if (postal_code) {
        viacep(postal_code).then((response) => {
          if (!response.data.erro) {
            this.payload.state = response.data.uf
            this.payload.address = response.data.logradouro
            this.payload.state = response.data.uf
            this.payload.district = response.data.bairro
            this.payload.city = response.data.localidade
            this.checkAddress = true
            this.$notify({type: 'success', message: 'Dados carregados com sucesso.'})
            this.$Progress.finish()
          } else {
            this.$notify({type: 'danger', message: 'Não foi possível encontrar o endereço.'});
            this.checkAddress = false;
            this.$Progress.finish()
          }
        }).catch((error) => {
          this.$notify({type: 'danger', message: 'Não foi possível encontrar o endereço.'});
        })
      }
    }
  },
  mounted() {
    this.$refs.formValidator.validate();
    this.$store.dispatch('localizations/fetchStates').then(() => this.loadingStates=false)
  }
}
</script>

<style scoped>

</style>
