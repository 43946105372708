<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 col-form-label form-control-label">
                  Status
                </label>
                <div class="col-md-7 pt-2">
                  <base-switch v-model="payload.is_active" type="success" offText="inativo" onText="ativo" class="success"></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Nome
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7 pl-2">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="payload.name"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')">
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

  <script>
  import PuzlSelect from "@/components/PuzlSelect";
  const {formatErrorValidation} = require("@/plugins")
  export default {
    name: "Create",
    components: { PuzlSelect },
    data () {
      return {
        modal: false,
        title: 'Adicionar Anomalias',
        payload: {
          is_active: true,
          name: null,
        },
        loadingMoldingLocation: true,
        loadingMeasuredunit: true,
        loadingStore: false,
      }
    },
    methods: {
      closeModal () {
        this.modal= false
      },
      handleCreateModal () {
        this.modal= true
      },
      store () {
        this.$Progress.start()
        this.loadingStore = true
        this.$store.dispatch('anomaly/add', this.payload)
          .then(response => {
            this.loadingStore = false
            this.modal = false
            this.payload = {
              is_active: true,
              name: null,
            }
            this.$Progress.finish()
            this.$notify({ type: response.error_type, message: response.message })
          })
          .catch(error =>{
            if (error.status) {
              this.$Progress.finish()
              this.loadingStore = false
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              })
            } else {
              if (error.response.status === 422) {
                let message = formatErrorValidation(error.response.data.errors)
                this.$notify({
                  type: 'danger',
                  message: message
                })
              } else {
                this.$notify({
                  type: error.data.error_type,
                  message: error.data.message
                })
                this.$Progress.finish()
                this.loadingStore = false
              }
            }
          })
      },
    },
    mounted() {
      this.$refs.formValidator.validate();
    }
  }
  </script>

  <style scoped>

  </style>
