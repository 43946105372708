<template>
  <div>
    <modal size="md"
      :show.sync="isOpen"
      :backgroundColor="'#FEF9F2'"
      :showClose="false"
    >
      <template>
        <template slot="header">
          <div class="d-flex align-items-center container ml-1 w-100" style="gap: 15px;">
            <div>
              <img src="/img/icons/create-new.png" alt="create-new" width="20px">
            </div>
            <span class="text-title">
              {{ title }}
            </span>
            <div class="ml-auto">
              <img class="pointer" @click="isOpen = false;" src="/img/icons/icons8/ios/close-window.png" alt="close-window" width="17px">
            </div>
          </div>
        </template>
      </template>

      <OperationSourceForm
        :formData="formData"
        :isEdit="true"
        @onCancelClick="closeModal"
        @onSubmitClick="handleSubmitForm"
      />
    </modal>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "vue";
import { dialogs, progress } from "../../../../../helpers";
import { OperationSourceUpdateType, initOperationSourceUpdateType } from "../../types";
import operationSourceStore from "../../store";
import OperationSourceForm from "../shared/OperationSourceForm.vue";

const title = "Editar Tipo de Operação PUZL";

const isOpen = ref(false);

/** @type {OperationSourceUpdateType} */
const formData = reactive(initOperationSourceUpdateType());

/** Store Getters */
const isFinding = computed(() => operationSourceStore.getIsFinding());

/**
 * Abrir modal
 * @param {number} id
 */
function openModal(id) {
  setFormData(id);
  isOpen.value = true;
}

/** Fechar modal */
function closeModal() {
  isOpen.value = false;
}

/** Localizar registro por id e setar dados no formulário */
function setFormData(id) {
  progress.start();
  operationSourceStore.find(id)
    .then((data) => Object.assign(formData, data))
    .finally(() => progress.finish());
}

/** Atualizar registro */
function handleSubmitForm() {
  progress.start();
  const loader = progress.showLoader();
  operationSourceStore.update(formData, formData.id)
    .then(() => {
      dialogs.notify();
      closeModal();
    })
    .finally(() => {
      progress.finish();
      loader.hide();
    });
}

/**
 * @typedef {Object} OperationSourceEditModalExpose
 * @property {typeof openModal} openModal
 */
defineExpose({
  openModal,
});

</script>

<style scoped>
.text-title {
  font-size: 18px;
  font-weight: 500;
  color: #2B2D32;
}
</style>