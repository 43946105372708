import store from "../../../../shared/libraries/store";
import { SearchBarFilterType } from "../../../../components/AppGlobal";
import {
  OperationSourceListFilterType,
  OperationSourceListPagerType,
  OperationSourceViewType,
  initOperationSourceListPagerType,
} from "../types";
import { operationSourceStoreStateKeys } from "./operationSourceStoreStateKeys";

/** @returns {?OperationSourceViewType} */
const getItem = () => store.getState(operationSourceStoreStateKeys.ITEM);

/** @returns {OperationSourceListPagerType} */
const getListed = () =>
  store.getState(operationSourceStoreStateKeys.LISTED) ??
  initOperationSourceListPagerType();

/** @returns {boolean} */
const getIsListing = () =>
  store.getState(operationSourceStoreStateKeys.IS_LISTING) ?? false;

/** @returns {boolean} */
const getIsFinding = () =>
  store.getState(operationSourceStoreStateKeys.IS_FINDING) ?? false;

/** @returns {boolean} */
const getIsSaving = () =>
  store.getState(operationSourceStoreStateKeys.IS_SAVING) ?? false;

/** @returns {boolean} */
const getIsRemoving = () =>
  store.getState(operationSourceStoreStateKeys.IS_REMOVING) ?? false;

/**
 * @returns {{
 *   searchBarFilter: SearchBarFilterType,
 *   filter: OperationSourceListFilterType
 * }}
 */
const getPageState = () => store.getState(operationSourceStoreStateKeys.PAGE_STATES);

export default {
  getItem,
  getListed,
  getIsListing,
  getIsFinding,
  getIsSaving,
  getIsRemoving,
  getPageState,
};
