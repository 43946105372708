<template>
  <div class="container-fluid">
    <div class="row card-wrapper" v-show="isLoading">
      <SkeletonPuzlGrid v-for="(index) in 3" :key="index"/>
    </div>
    <div class="row">
      <div class="col-md-12">
        <PuzlEmptyData/>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4" v-for="index in 3">
        <div class="card card-border-top-success"><!---->
          <div class="card-body">
            <div class="row align-items-center mb-3">
              <div class="col-8"><h5 class="h4 mb-0"> Título </h5></div>
              <div class="col-4 text-right">
                <div class="dropdown">
                  <button type="button"
                          class="btn base-button dropdown-toggle p-2 rounded m-0 btn-primary"><i
                    class="fas fa-cog"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";

export default {
  name: "ListExample",
  components: {
    SkeletonPuzlGrid,
    PuzlEmptyData
  },
  data() {
    return {
      isLoading: true,
    }
  }
}
</script>

<style scoped>

</style>
