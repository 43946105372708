<template>
  <div>
    <modal :use-rerender="false" @close="close" size="md" :show.sync="show">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">{{ title }}</h5>
      </template>
      <div class="card-header border-header-default-bottom p-2 grid-col--fixed-left">
        <h5 class="mb-0" slot="title">Dados de emissão</h5>
      </div>
      <div class="row mt-2">
        <div class="col-md-12">
          <h5 class="mb-0">
                  <span class=""
                  >Cliente : <span class="font-weight-normal">{{ billet.entity_document }}</span>
                  </span>
          </h5>
          <h5 class="mb-0">
                  <span class=""
                  >Beneficiário : <span class="font-weight-normal">{{ billet.issuer_document }}</span>
                  </span>
          </h5>
          <h5 class="mb-0">
                  <span class=""
                  >Competência : <span class="font-weight-normal">{{
                      bill_receive.bill_receive.competence | parseDate('DD/MM/YYYY')
                    }}</span>
                  </span>
          </h5>
          <h5 v-if="bill_receive.bill_receive.bank_account" class="mb-0">
                  <span class=""
                  >Conta bancária : <span class="font-weight-normal">{{
                      bill_receive.bill_receive.bank_account.name
                    }}
                     | {{ bill_receive.bill_receive.bank_account.number_without_digit_account }} -
                  {{ bill_receive.bill_receive.bank_account.digit_account }}
                  </span>
                  </span>
          </h5>
        </div>
        <template v-if="bill_receive && bill_receive.installments">
          <div class="col-2"/>
          <div class="col-10 mt-2">
            <h5 class="mb-0 border-3 border-left border-primary">
                  <span class="ml-1 font-weight-normal"
                  >TOTAL = <span class="font-weight-bold numeric">{{
                      bill_receive.bill_receive.total_value | currency()
                    }}</span>
                  </span>
            </h5>
            <!--          <h5 class="mt-1 mb-0 border-3 border-left border-danger">-->
            <!--                  <span class="ml-1 text-danger font-weight-normal"-->
            <!--                  >RETENÇÃO ISS = <span class="font-weight-bold">{{ bill_receive.bill_receive.iss_retain | currency() }}</span>-->
            <!--                  </span>-->
            <!--          </h5>-->
            <h5 class="mt-1 mb-0 border-3 border-left border-success">
                  <span class="ml-1 font-weight-normal"
                  >VALOR FINAL = <span class="font-weight-bold numeric">{{
                      bill_receive.bill_receive.final_value | currency()
                    }}</span>
                  </span>
            </h5>
          </div>
          <div class="col-md-12 mt-2">
            <div v-if="bill_receive.bill_receive.document_date || bill_receive.bill_receive.document_number"
                 class="mb-2 card-header border-header-default-bottom p-2 grid-col--fixed-left">
              <h5 class="mb-0" slot="title">Documento</h5>
            </div>
            <div v-if="bill_receive.bill_receive.document_date || bill_receive.bill_receive.document_number"
                 class="row mt-2">
              <div class="col-md-12">
                <h5 v-if="bill_receive.bill_receive.document_date" class="mb-0">
                  <span class=""
                  >Data : <span class="font-weight-normal">{{
                      bill_receive.bill_receive.document_date | parseDate('DD/MM/YYYY')
                    }}</span>
                  </span>
                </h5>
                <h5 v-if="bill_receive.bill_receive.document_number" class="mb-0">
                  <span class=""
                  >Número : <span class="font-weight-normal">{{ bill_receive.bill_receive.document_number }}</span>
                  </span>
                </h5>
              </div>
            </div>
            <div class="mb-2 mt-2 card-header border-header-default-bottom p-2 grid-col--fixed-left">
              <h5 class="mb-0" slot="title">Parcelas</h5>
            </div>
            <div v-for="(item, index) in bill_receive.installments" class="timeline timeline-one-side">
              <div class="timeline-block"><span class="timeline-step badge-primary" style="border: 1px solid white"
              >
                <small><b style="color: white">{{ item.index }}</b> </small>
              </span>
                <div class="timeline-content">
                  <div class="row mb-2">
                    <div class="col-5"><b class="numeric" style="color: black;">{{
                        item.total_value | currency()
                      }}</b></div>
                    <div class="col-3 text-left">
                      <base-button title="Emitir" @click.prevent="generateBillet(index, item)"
                                   v-if="(!item.billets.length
                                         || item.billets[item.billets.length - 1].status === 2)
                                         && item.status !== 1"
                                   size="sm" type="success" outline>
                        EMITIR <i class="fa-solid fa-print"></i>
                      </base-button>
                    </div>
                    <div class="col-4"/>
                  </div>

                  <!--                  <small-->
                  <!--                  class=" font-weight-bold"><b>{{ item.total_value | currency() }}</b>-->
                  <!--                  <base-button v-if="item.billet_status === null" class="ml-2" size="sm" type="success" outline>Emitir-->
                  <!--                  </base-button>-->
                  <!--                </small>-->
                  <div class="row" v-for="(billet, billetIndex) in item.billets">
                    <div class="col-6 mt-1">
                      <!--                      <small><a :href="billet.file_path" target="_blank">-->
                      <!--                        <i style="font-size: 1.2rem" class="zoom fa-solid fa-print" :class="printColor(billet)"></i>-->
                      <!--                         {{billet.id}}-->
                      <!--                      </a>-->
                      <a :href="billet.file_path" target="_blank">
                        <img
                          class="mr-2"
                          style="width: 50px;"
                          src="https://cdn.awsli.com.br/1807/1807892/arquivos/anapuena-boleto.png"
                        />
                        <span class="text-default" style="font-size: 0.8rem; font-family: Gilroy,sans-serif !important">
                          {{ billet.expire_at | parseDate('DD/MM/YYYY') }}</span>
                        <br>
                      </a>
                    </div>
                    <div class="col-3 mt-2">
                      <template v-if="lastBilletCanChange(item.billets, billet)">
                        <a href="#" @click.prevent="updateBillet(billet, item.index, bill_receive.installments.length)">
                          <i style="font-size: 1.2rem" class="text-warning fa-solid fa-edit"></i>
                        </a>
                        <a href="#" @click.prevent="cancellBillet(billet.id, index, billetIndex)">
                          <i style="font-size: 1.2rem" class="ml-2 text-danger fa-solid fa-circle-xmark"></i>
                        </a>
                      </template>
                    </div>
                    <div class="col-3 mt-1 p-0">
                      <!--                      <badge type="primary" block>{{textStatus[billet.status]}}</badge>-->
                      <span class="display-4" style="font-size: 0.6rem">
                                          <mark v-if="billet.status !== 0" style="background-color: #f7f7f7 !important"
                                                :class="setType[billet.status]">{{ textStatus[billet.status] }}</mark>

                          <mark v-if="billet.status === 0" style="background-color: #f7f7f7 !important"
                                class="text-primary">GERANDO</mark>
                  </span>
                      <!--                      <base-button block size="sm" :type="setType[billet.status]">-->
                      <!--                       {{ textStatus[billet.status] }}-->
                      <!--                      </base-button>-->
                    </div>
                    <div class="col-md-12">
                      <hr style="margin-top: 12px;margin-bottom: 12px">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--          <div class="col-2 mt-4">-->
          <!--            <img-->
          <!--              style="width: 45px; border: 1px solid blue"-->
          <!--              src="https://w7.pngwing.com/pngs/984/151/png-transparent-computer-icons-barcode-scanners-scanner-qr-code-barcode-icon-text-logo-monochrome.png"-->
          <!--            />-->
          <!--          </div>-->
          <!--          <div class="col-10 mt-3 text-left">-->
          <!--            <h4 class="mb-0">-->
          <!--                  <span class="font-weight-normal"-->
          <!--                  >Parcela {{ item.index }} | <b>{{ item.value | currency() }}</b>-->
          <!--                  </span>-->
          <!--              <br>-->
          <!--              <small class="font-weight-normal">-->
          <!--                Vencimento: {{ item.due_date | parseDate('DD/MM/YYYY') }}-->
          <!--              </small>-->
          <!--            </h4>-->
          <!--          </div>-->
        </template>
      </div>
      <div class="modal-footer">
        <base-button type="secondary" @click="close()">
          Fechar
        </base-button>
      </div>
      <modal-generate-billet @setBillet="setBillet" ref="modalGenerateBillet"/>
      <modal-update-billet @close="onModalUpdateBilletClose" ref="modalUpdateBillet"/>
    </modal>
  </div>
</template>

<script>
import ModalGenerateBillet from './_ModalGenerateIndividualBillet'
import ModalUpdateBillet from './_ModalUpdateBillet'

export default {
  name: "ModalGenerateBilletHasGenerated",
  components: {
    ModalGenerateBillet,
    ModalUpdateBillet,
  },
  data() {
    return {
      title: 'Boletos',
      show: false,
      bill_receive: null,
      billet: null,
      loading: false,
      textStatus: {
        3: 'PAGO',
        1: 'ABERTO',
        0: 'GERANDO',
        4: 'VENCIDO',
        2: 'CANCELADO',
      },
      setType: {
        3: 'text-success',
        1: 'text-primary',
        0: 'text-secondary',
        4: 'text-warning',
        2: 'text-danger',
      }
    }
  },
  methods: {
    close() {
      this.show = false
    },
    badgeColor(billets) {
      const last = billets[billets.length - 1]
      switch (last.status) {
        case 0:
          return 'badge-secondary';
        case 3:
          return 'badge-success';
        case 1:
          return 'badge-primary';
        case 2:
          return 'badge-danger';
        case 4:
          return 'badge-warning';
        default:
          return 'badge-warning';
      }
    },
    printColor(item) {
      switch (item.status) {
        case 0:
          return 'text-muted';
        case 3:
          return 'text-success';
        case 1:
          return 'text-primary';
      }
    },
    lastBilletCanChange(billets, billet) {
      const last = billets[billets.length - 1]
      if ([1, 4].includes(last.status) && billet === last) {
        return true
      }
      return false
    },
    setBillet(params) {
      let finalValue = this.$helper.strToNum(this.bill_receive.bill_receive.final_value) - 
        this.$helper.strToNum(this.bill_receive.installments[params.index].total_value);
      finalValue += this.$helper.strToNum(params.billet.amount);
      this.bill_receive.bill_receive.final_value = finalValue;
      this.bill_receive.installments[params.index].total_value = this.$helper.strToNum(params.billet.amount);
      this.bill_receive.installments[params.index].billets.push(params.billet)
      this.$emit('fetch')
    },
    generateBillet(index, installment) {
      const billet = installment.billets[installment.billets.length - 1]
      billet.index = installment.index
      billet.total_index = this.bill_receive.total_index      
      this.$refs.modalGenerateBillet.openModal(billet, index, installment)
    },
    updateBillet(billet, index, totalInstallments) {
      this.$refs.modalUpdateBillet.openModal(billet, index, totalInstallments);
    },
    cancellBillet(billet_id, index, billetIndex) {
      this.$Progress.start()
      this.$Swal.confirmAction('Deseja realmente cancelar este boleto?', ['Sim', 'Não'])
        .then((result) => {
          if (result.isConfirmed) {
            let loader = this.$loading.show()
            this.$store.dispatch('bankBillet/cancel', billet_id).then(response => {
              this.$Progress.finish()
              loader.hide()
              this.$notify({
                type: response.error_type,
                message: response.message
              })
              if (this.$listeners && this.$listeners.fetch) {
                this.$emit('fetch')
              }
              this.bill_receive.installments[index].billets[billetIndex].status = 2
            }).catch(error => {
              loader.hide()
              this.$Progress.finish()
            })
          }
        }).catch(() => this.$Progress.finish())
    },
    async openModal(id, billet, bill_receive, index = null) {
      this.billet = billet
      this.bill_receive = bill_receive
      if (index !== null) {
        const installment = await this.bill_receive.installments.find(item => item.index === index)
        const last_billet = await installment.billets[installment.billets.length - 1]
        if (![1, 4].includes(last_billet.status)) {
          this.$notify({
            type: "danger",
            message: "Não é possível alterar o vencimento do boleto selecionado."
          });
        }
        this.show = true
        this.$nextTick(function () {
          this.updateBillet(last_billet, installment.index, this.bill_receive.installments.length)
        })
      }
      else {
        this.show = true
      }
    },
    onModalUpdateBilletClose() {
      this.$emit('fetch');
      this.close();
    }
  },
};
</script>

<style>
</style>
