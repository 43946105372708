<template>
  <div>
    <modal size="md"
      :show.sync="isOpen"
      :backgroundColor="'#F2F7F3'"
      :showClose="false"
    >
      <template>
        <template slot="header">
          <div class="d-flex align-items-center container ml-1 w-100" style="gap: 15px;">
            <div>
              <img src="/img/icons/icons8/ios/plus-math-success.png" alt="plus-math-success" width="20px">
            </div>
            <span class="text-title">
              {{ title }}
            </span>
            <div class="ml-auto">
              <img class="pointer" @click="isOpen = false;" src="/img/icons/icons8/ios/close-window.png" alt="close-window" width="17px">
            </div>
          </div>
        </template>
      </template>

      <AdjustmentTypeForm
        :formData="formData"
        @onCancelClick="closeModal"
        @onSubmitClick="handleSubmitForm"
      />
    </modal>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "vue";
import { dialogs, progress } from "../../../../../helpers";
import { AdjustmentTypeCreateType, initAdjustmentTypeCreateType } from "../../types";
import adjustmentTypeStore from "../../store";
import AdjustmentTypeForm from "../shared/AdjustmentTypeForm.vue";

const title = "Novo Ajuste por Tipo";

const isOpen = ref(false);

/** @type {AdjustmentTypeCreateType} */
const formData = reactive(initAdjustmentTypeCreateType());

/** Store Getters */
const isSaving = computed(() => adjustmentTypeStore.getIsSaving());

/** Abrir modal */
function openModal() {
  setFormData();
  isOpen.value = true;
}

/** Fechar modal */
function closeModal() {
  isOpen.value = false;
}

/** Resetar dados do formulário */
function setFormData() {
  Object.assign(formData, initAdjustmentTypeCreateType());
}

/** Criar registro */
function handleSubmitForm() {
  progress.start();
  const loader = progress.showLoader();
  adjustmentTypeStore.create(formData)
    .then(() => {
      dialogs.notify();
      closeModal();
    })
    .finally(() => {
      progress.finish();
      loader.hide();
    });
}

/**
 * @typedef {Object} AdjustmentTypeCreateModalExpose
 * @property {typeof openModal} openModal
 */
defineExpose({
  openModal,
});

</script>

<style scoped>
</style>