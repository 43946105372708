<template>
  <VueTimepicker
    :value="value"
    manual-input
    @close="$emit('close')"
    :disabled="disabled"
    @change="handleInput"
    :minute-interval="minuteInterval"
    input-width="100%"
  />
</template>

<script>
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
export default {
  name: "InputTimePicker",
  components: { VueTimepicker },
  methods: {
    handleInput(e) {
      this.$emit("input", e.displayTime);
    },
  },
  props: {
    minuteInterval: {
      type: String,
      default: () => "15",
      description: "Intervalo em minutos",
    },
    disabled: {
      type: Boolean,
      default: () => false,
      description: "Desabilitar input",
    },
    value: {
      type: [String, Date],
      default: () => null,
      description: "Valor",
    },
  },
};
</script>

<style>
.display-time {
  color: #606266 !important;
  border-radius: 0.375rem !important;
}
</style>
