<template>
  <div>
    <modal :show.sync="modal.open">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form
            class="needs-validation"
            @submit.prevent="save"
            autocomplete="off"
          >
            <div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 col-form-label form-control-label">
                  Status
                </label>
                <div class="col-md-7 pt-2">
                  <base-switch
                    v-model="puzl_admixture.status"
                    type="success"
                    offText="inativo"
                    onText="ativo"
                    class="success"
                  ></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-5 pb-0 mb-2 col-form-label form-control-label"
                >
                  Grupo
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <puzl-select 
                        v-model="puzl_admixture.cmc_group_id" 
                        :items="additions" 
                        label="group_name"
                        :disabled="loadingCmcGroup"
                        :loading="loadingCmcGroup" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pt-1 pb-1 col-form-label form-control-label">
                  Explicação técnica
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      v-model="puzl_admixture.technical_explanation"
                      class="form-control form-control-sm"
                    />
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                >
                  Faixa de dosagem
                </label>
              </div>
              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                >
                  De:
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        inputmode="numeric"
                        v-model="puzl_admixture.dosage_range_start"
                        class="form-control form-control-sm"
                        v-mask="['###,##', '##,##', '#,##']"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          kg/m<sup>3</sup>
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                >
                  Até
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        inputmode="numeric"
                        v-model="puzl_admixture.dosage_range_end"
                        class="form-control form-control-sm"
                        v-mask="['###,##', '##,##', '#,##']"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          kg/m<sup>3</sup>
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-5 pb-2 mb-1 col-form-label form-control-label">
                  Imagem
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <puzl-file v-model="file" title="imagem"></puzl-file>
                  </base-input>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" ref="baseButton"
                  >
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import PuzlFile from '../../../../../../components/PuzlFile.vue';
import {mapGetters} from 'vuex';
import PuzlSelect from "@/components/PuzlSelect";
const {formatErrorValidation} = require("@/plugins")
export default {
  components: {
    PuzlFile,
    PuzlSelect,
  },
  name: "Create",
  data() {
    return {
      modal: {
        title: 'Puzl Admix',
        open: false,
      },
      inValidated: true,
      puzl_admixture: {
        status: true,
        cmc_group_id: '',
        technical_explanation: '',
        dosage_range_start: '',
        dosage_range_end: '',
        image: '',
      },
      file: null,
      loadingSave: false,
      loadingCmcGroup: true,
    }
  },
  watch: {
    puzl_admixture: {
      handler(val) {
        if (val.cmc_group_id) {
          const dosageStart = parseFloat(val.dosage_range_start.replace(',', '.'))
          const dosageEnd = parseFloat(val.dosage_range_end.replace(',', '.'))
          if (dosageStart && dosageEnd) {
            if (dosageStart < dosageEnd) {
              return this.inValidated = false
            }
            return this.inValidated = true
          }
        }
        this.inValidated = true
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      'additions': 'cmcGroup/fetchAdditions',
    }),
  },
  methods: {
    closeModal() {
      this.modal.open = false
    },
    handleCreateModal() {
      this.modal.open = true
    },
    save() {
      this.$Progress.start()
      this.loadingSave = true

      const convertDecimals = (str) => {
        return str.replace(',', '.')
      }

      let formData = new FormData();
      formData.append('status', this.puzl_admixture.status);
      formData.append('cmc_group_id', this.puzl_admixture.cmc_group_id);
      formData.append('technical_explanation', this.puzl_admixture.technical_explanation);
      formData.append('dosage_range_start', convertDecimals(this.puzl_admixture.dosage_range_start));
      formData.append('dosage_range_end', convertDecimals(this.puzl_admixture.dosage_range_end));
      if (this.file) {
        formData.append('image', this.file);
      }

      this.$store.dispatch('mixPuzlAdmixture/add', formData,
        {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
        .then(response => {
          this.loadingSave = false
          this.modal.open = false
          this.puzl_admixture = {
            status: true,
            cmc_group_id: '',
            technical_explanation: '',
            dosage_range_start: '',
            dosage_range_end: '',
            image: '',
          }
          this.file = null

            this.$Progress.finish()
          this.$notify({type: response.error_type, message: response.message})
        })
        .catch(error => {
          if (error.status) {
            this.$Progress.finish()
            this.loadingSave = false
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            })
          } else {
            if (error.response.status === 422) {
              let message = formatErrorValidation(error.response.data.errors)
              this.$notify({
                type: 'danger',
                message: message
              })
              this.$Progress.finish()
              this.loadingSave = false
            } else {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              })
              this.$Progress.finish()
              this.loadingSave = false
            }
          }
        })
    },
  },
  mounted() {
    this.$refs.formValidator.validate();
    this.$store.dispatch('cmcGroup/fetchItems').then(() => {
      this.loadingCmcGroup = false;
    });
  },
  created() {
  }
}
</script>

<style scoped>

</style>
