<template>
  <div>
    <modal size="xl" :show="show">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div class="row mb-3 mt-n4">
        <div class="col-12">
          <div class=" p-4">
            <h5 class="h3 new-default-black-font p-1 mb-0">
              <img height="30px" src="/img/icons/icons8/ios/impossible-shapes_primary.png" class="mr-3"/>
              Padrão de moldagem
            </h5>
            <hr class="new-default-black mt-2 mb-3">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="card p-4">
                  <h5 class="h3 text-primary p-1 mb-0">
                    Novo ensaio
                  </h5>
                  <div class="row my-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 col-form-label new-default-black-font form-control-label">
                        Ensaio
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input input-classes="form-control-sm">
                        <puzl-select
                          filterable
                          v-model="test.hardenedState"
                          @change="getMold()"
                          :items="hardened_states"
                          :label="'short_description'"
                          :disabled="loadingHardenedState"
                          :loading="loadingHardenedState"/>
                      </base-input>
                    </div>
                  </div>
                  <div class="row my-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 col-form-label new-default-black-font form-control-label">
                        Molde
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input input-classes="form-control-sm">
                        <puzl-select
                          filterable
                          v-model="test.mold"
                          :items="hardened_state_molds"
                          :label="'description'"
                          :disabled="loadingMold || !hardened_state_molds.length || !test.hardenedState"
                          :loading="loadingMold"/>
                      </base-input>
                    </div>
                  </div>
                  <div class="row my-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 col-form-label new-default-black-font form-control-label">
                        Idade
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="row">
                        <div class="col-md-6">
                          <base-input input-group-classes="input-group-sm">
                            <input type="text" v-model="test.period" inputmode="numeric" v-mask="['#','##','###']"
                                   class="form-control form-control-sm" input-group-classes="input-group-sm">
                          </base-input>
                        </div>
                        <div class="col-md-6">
                          <base-input input-classes="form-control-sm">
                            <puzl-select
                              :clearable="false"
                              filterable
                              v-model="test.period_type"
                              :items="period_types"
                              :label="'label'"
                              :customKey="'value'"/>
                          </base-input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row my-2 align-items-center">
                    <div class="col-12 col-form-label form-control-label">
                      <base-button class="float-right py-2 px-4"
                                   :disabled="test.mold == '' || test.hardenedState == '' || test.period == '' || test.period_type==''"
                                   style="display: flex; align-items: center;"
                                   size="sm" type="success" @click="addMold()">
                        <img class="mr-2" src="/img/icons/plus--v1.png" width="16">
                        INCLUIR
                      </base-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6" style="overflow: auto;max-height: 60vh">
                <div class="px-4 pb-4">
                  <h5 class="h3 text-primary p-1 mb-0">
                    Ensaios
                  </h5>
                  <div class="m-2 mt-3" v-for="(itemHardenedState, index) in testHardenedStates"
                       :key="index">
                    <card class="my-0 border card--hover card-body-molde">
                      <div class="row align-items-center">
                        <div class="col-4">
                          <h6 class="p-0 m-0" style="display: flex;align-items: center;">
                            <i class="mr-2" style="font-style: normal; font-size: 14px;">{{ index + 1 }}.</i>
                            <i style="font-style: normal;font-size: 12px;">{{
                                itemHardenedState.description
                              }}</i>
                          </h6>
                        </div>
                        <div class="col-3">
                          <div v-if="itemHardenedState.period_type==1">
                            <h6 style="font-size: 12px;" class="p-0 m-0">{{ itemHardenedState.period }} Dias</h6>
                          </div>
                          <div v-if="itemHardenedState.period_type==2">
                            <h6 style="font-size: 12px;" class="p-0 m-0">{{ itemHardenedState.period }} Horas</h6>
                          </div>
                        </div>
                        <div class="col-4">
                          <h6 class="p-0 m-0" style="display: flex;align-items: center;">
                            {{ itemHardenedState.hardened_state }}
                          </h6>
                        </div>
                        <div class="col-1">
                          <img class="float-right pointer"
                               src="/img/icons/icons8/ios/cancel_danger.png"
                               width="14" @click="removeMold(index)">
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <base-button @click.prevent="cancel" type="danger"
                     style="width: 120px; display: flex; align-items: center;">
          <img class="float-right pointer mr-2" width="14" src="/img/icons/icons8/ios/cancel.png">
          Cancelar
        </base-button>
        <base-button type="success" style="width: 120px; display: flex; align-items: center;"
                     @click.prevent="save()"
                     native-type="submit" :loading="loadingSave">
          <img class="float-right pointer mr-2" width="14" src="/img/icons/save.png">
          Salvar
        </base-button>
      </div>
    </modal>
  </div>
</template>

<script>
import moment from "moment";
import {mapGetters} from "vuex";
import {period_types} from "@/views/Modules/Technology/Mix/helpers/mixe"
import PuzlSelect from "@/components/PuzlSelect";

export default {
  components: {
    PuzlSelect,
  },
  name: "ModalDefaultMolding",
  data() {
    return {
      format_mixe: period_types,
      loadingSave: false,
      test: {
        mold: '',
        hardenedState: '',
        period: '',
        period_type: '',
      },
      loadingHardenedState: false,
      loadingMold: false,
      testHardenedState: {},
      testHardenedStates: [],
      hardened_state_molds: [],
      show: false,
      title: 'Padrão de moldagem'
    }
  },
  computed: {
    ...mapGetters({
      'period_types': 'mixFeature/period_types',
      'hardened_states': 'testHardenedState/activeItems',
    }),
  },
  methods: {
    cancel() {
      this.show = false
    },
    save() {
      const testHardenedStates = this.testHardenedStates.filter(function (item) {
        if (!item.id) {
          return item
        }
      })
      this.loadingSave = true
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      let loader = this.$loading.show()
      this.$store.dispatch('defaultMolding/add', testHardenedStates).then((response) => {
        this.$notify({
          type: 'success',
          message: response.message
        });
      }).finally(() => {
        loader.hide()
        this.close()
        this.loadingSave = false
        this.testHardenedStates = []
      })
    },
    close() {
      this.show = false
    },
    async handleCreateModal() {
      this.testHardenedState = {}
      this.test = {
        mold: '',
        hardenedState: '',
        period: '',
        period_type: '',
      }
      let loader = this.$loading.show()
      await this.$store.dispatch('testHardenedState/fetchItems')
      await this.$store.dispatch('defaultMolding/fetchItems').then(response => {
        this.testHardenedStates = response.data
      })
      loader.hide()
      this.show = true
    },
    addMold() {
      this.testHardenedState = {};
      this.testHardenedState.hardened_state_id = this.test.hardenedState;
      let testMoldSelected = this.test.mold;

      let moldSelected = this.hardened_state_molds.filter(function (mold) {
        return mold.id === testMoldSelected;
      });

      let hardenedState = this.hardened_states.find(function (hardened_state) {
        return hardened_state.id === this.test.hardenedState;
      }, this);

      this.testHardenedState.hardened_state_mold_id = moldSelected[0].id;
      this.testHardenedState.description = moldSelected[0].description;
      this.testHardenedState.hardened_state = hardenedState.short_description;
      this.testHardenedState.period = parseInt(this.test.period);
      this.testHardenedState.period_type = this.test.period_type;
      this.testHardenedStates.push(this.testHardenedState);
    },
    removeMold(index) {
      this.testHardenedStates.splice(index, 1);
      if (this.testHardenedStates[index].id) {
        this.$notify({
          type: "info",
          message: "Estamos trabalhando em sua solicitação."
        });
        let loader = this.$loading.show()
        this.$store.dispatch('defaultMolding/destroy', this.testHardenedStates[index].id)
          .then((response) => {
            this.$notify({
              type: 'success',
              message: response.message
            });
            loader.hide()
          })
      }
    },
    getMold() {
      this.test.mold = '';

      let mold_default = {};
      let hardned_state = this.test.hardenedState
      let selected_hardened = this.hardened_states.filter(function (item) {
        return item.id === hardned_state;
      });

      this.hardened_state_molds = selected_hardened[0].molds;

      if (this.hardened_state_molds.length >= 1) {

        mold_default = this.hardened_state_molds.filter(function (mold) {
          return mold.default === true;
        });

        if (mold_default[0] !== undefined) {
          this.test.mold = mold_default[0].id;
        }

      }

      this.loadingMold = false;
    },
  }
}
</script>

<style scoped>

</style>
