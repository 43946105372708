<template>
  <div>
    <div
      v-if="widgets && !loadingWidgets"
      class="row row-cols-1 row-cols-md-4 p-2"
    >
      <div class="col card-margin-top card-margin-top-mobile">
        <div class="card height-card">
          <div class="card-body">
            <img
              src="/img/icons/clock--v1.png"
              width="25px"
              height="25px"
              class="mr-2"
            />
            <span class="card-title text-dark" style="font-size: 11.3px">
              Ciclo
            </span>
            <div class="text-dark card-text-border mb-2 mt-2 font-size-card">
              Total
              <span class="mr-1 float-right font-size-card">
                {{ formatTime(widgets.average_total_cycle) }}
              </span>
            </div>
            <div class="text-dark card-text-border font-size-card">
              Concreto
              <span class="mr-1 float-right font-size-card">
                {{ formatTime(widgets.average_concrete_cycle) }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col card-margin-top card-margin-top-mobile">
        <div class="card height-card">
          <div class="card-body">
            <img
              src="/img/icons/icons8/ios/brick-wall.png"
              width="25px"
              height="25px"
              class="mr-2"
            />
            <span class="card-title text-dark" style="font-size: 11.3px">
              OBRA
            </span>
            <div class="text-dark card-text-border mb-2 mt-2 font-size-card">
              Espera
              <span class="mr-1 float-right font-size-card">
                {{ formatTime(widgets.average_wait_start_discharge) }}
              </span>
            </div>
            <div class="text-dark card-text-border font-size-card">
              Permanência
              <span class="mr-1 float-right font-size-card">
                {{ formatTime(widgets.average_construction_stay_time) }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col card-margin-top card-margin-top-mobile">
        <div class="card height-card">
          <div class="card-body">
            <img
              src="/img/icons/icons8/ios/waypoint-map.png"
              width="26px"
              height="26px"
              class="mr-2"
            />
            <span class="card-title text-dark" style="font-size: 11.3px">
              Trajeto
            </span>
            <div class="text-dark card-text-border mb-2 mt-2 font-size-card">
              Ida
              <span class="mr-1 float-right font-size-card">
                {{ formatTime(widgets.average_send_route_time) }}
              </span>
            </div>
            <div class="text-dark card-text-border font-size-card">
              Retorno
              <span class="mr-1 float-right font-size-card">
                {{ formatTime(widgets.average_return_route_time) }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col card-margin-top card-margin-top-mobile">
        <div class="card height-card">
          <div class="card-body">
            <img
              src="/img/icons/chemical-plant.png"
              width="29px"
              height="29px"
              class="mr-2"
            />
            <span class="card-title text-dark" style="font-size: 11.3px">
              Central
            </span>
            <div class="text-dark card-text-border mb-2 mt-2 font-size-card">
              Carga
              <span class="mr-1 float-right font-size-card">
                {{ formatTime(widgets.average_load_truck_diff) }}
              </span>
            </div>
            <div class="text-dark card-text-border font-size-card">
              Lastro
              <span class="mr-1 float-right font-size-card">
                {{ formatTime(widgets.average_ballast) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Loading -->
    <div class="row card-wrapper" v-show="loadingWidgets">
      <div v-for="index in 4" :key="index" :class="'col-md-3'" class="mt-n2">
        <div class="card card-border-top-light bg-gray-content">
          <div style="padding: 2.4rem" class="card-body">
            <div class="row">
              <div class="col-md-5">
                <div><span class="bar button"></span></div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div><span class="bar button"></span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WidgetsHoraryResume",
  props: {
    widgets: {
      type: Object,
      required: true,
    },
    loadingWidgets: Boolean,
  },
  methods: {
    /**
     * Formata o tempo fornecido em minutos.
     * @param {number} minutes - O tempo em minutos.
     * @returns {string} - O tempo formatado (por exemplo, "1H 30 MIN").
     */
    formatTime(minutes) {
      if (minutes) {
        if (minutes > 60) {
          var hours = Math.floor(minutes / 60);
          var minutes_remaining = minutes % 60;
          return hours + "H " + minutes_remaining.toFixed(0) + " MIN";
        } else {
          return minutes.toFixed(0) + " MIN";
        }
      }
      return "0 MIN";
    },
  },
};
</script>

<style scoped>
.card-text-border {
  border: 2px solid #d3d3d3;
  border-radius: 10px;
  padding: 5px;
}
.card-margin-top {
  margin-top: -14px;
}
@media (max-width: 1190px) {
  .card-margin-top-mobile {
    margin-top: -19px;
  }
}
.card {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.height-card {
  height: 165px;
}
.font-size-card {
  font-size: 10px;
}
.card-body {
  background-color: white;
  color: black;
}
</style>
