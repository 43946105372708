<template>
  <div class="header-card" v-bind="$attrs">
    <AppFixedPageTitle
      title="Itens de checklist"
      icon="/img/icons/todo-list-white.png"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <div class="row">
          <div class="col-md-12">
            <span class="item-title" v-if="checklist.name">{{
              checklist.name
            }}</span>
          </div>
          <div class="col-md-12">
            <span
              class="item-subtitle"
              v-if="checklist.equipment_category_name"
              >{{ checklist.equipment_category_name }}</span
            >
          </div>
        </div>
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions>
          <AppPageHeaderActionsButton
            @click="add"
            text="novo"
            type="success"
            icon="/img/icons/plus-math--v1-white.png"
          />
          <base-button
            block
            type="light"
            class="new-puzl-default"
            style="border-radius: 50px !important"
            @click.prevent="$router.go(-1)"
          >
            <img width="19" src="/img/icons/circled-left.png" class="mr-1" />
            Voltar
          </base-button>
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>
    <div class="col-md-12 mt-5">
      <List
        :items="$_checklist_items"
        :loading="loading"
        @update-checklist-item="update"
      />
    </div>
    <ModalCreate ref="modalCreate" @refresh="init"></ModalCreate>
    <ModalEdit ref="modalEdit" @refresh="init"></ModalEdit>
  </div>
</template>

<script>
import {
  AppFixedPageTitle,
  AppPageHeader,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
} from "@/components/AppGlobal";
import { mapGetters } from "vuex";
import List from "./Shared/_List";
import ModalCreate from "./Shared/_ModalCreate";
import ModalEdit from "./Shared/_ModalEdit";

export default {
  name: "CheckListItem",
  inheritAttrs: false,
  components: {
    AppFixedPageTitle,
    AppPageHeader,
    AppPageHeaderActions,
    AppPageHeaderActionsButton,
    List,
    ModalCreate,
    ModalEdit,
  },
  data() {
    return {
      checklist: {},
      loading: false,
    };
  },
  methods: {
    add() {
      this.$refs.modalCreate.openModal();
    },
    update(uuid) {
      this.$Progress.start();
      let loader = this.$loading.show();
      this.$store
        .dispatch("itemCheckList/show", {checklistUuid: this.$route.params.uuid, uuid})
        .then((response) => {
          this.$Progress.finish();
          loader.hide();
          this.loading = false;
          this.$refs.modalEdit.openModal(response.data);
        })
        .catch(() => {
          this.loading = false;
          loader.hide();
        });
    },
    init() {
      let loader = this.$loading.show();
      this.loading = true;
      this.$store
        .dispatch("checklist/show", this.$route.params.uuid)
        .then((response) => {
          this.checklist = response.data;
          this.$store
            .dispatch("itemCheckList/fetch", this.$route.params.uuid)
            .then((response) => {
              this.loading = false;
              loader.hide();
            })
            .catch(() => {
              this.loading = false;
              loader.hide();
            });
        })
        .catch(() => {
          this.loading = false;
          loader.hide();
        });
    },
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      $_checklist_items: "itemCheckList/fetch",
    }),
  },
};
</script>

<style scoped>
span {
  font-family: "Fredoka";
}

span.item-title {
  font-size: 16px;
  line-height: 19.36px;
  font-weight: 500;
}

span.item-subtitle {
  font-size: 14px;
  line-height: 16.94px;
  font-weight: 400;
}
</style>
