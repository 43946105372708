import store from "../../../../shared/libraries/store";
import { SearchBarFilterType } from "../../../../components/AppGlobal";
import { BaseListFilterType } from "../../../../shared/types/BaseListFilterType";
import {
  ProductServiceHubTypeListType,
  initProductServiceHubTypeListPagerType,
} from "../types";
import { productServiceHubTypeStoreStateKeys } from "./productServiceHubTypeStoreStateKeys";

/** @returns {Array<ProductServiceHubTypeListType>} */
const getListed = () =>
  store.getState(productServiceHubTypeStoreStateKeys.LISTED) ??
  initProductServiceHubTypeListPagerType();

/** @returns {boolean} */
const getIsListing = () =>
  store.getState(productServiceHubTypeStoreStateKeys.IS_LISTING) ?? false;

/**
 * @returns {{
 *   searchBarFilter: SearchBarFilterType,
 *   filter: BaseListFilterType
 * }}
 */
const getPageState = () =>
  store.getState(productServiceHubTypeStoreStateKeys.PAGE_STATES);

export default {
  getListed,
  getIsListing,
  getPageState,
};
