export const BillReceiveInstallmentEnum = Object.freeze({
  PENDING: 0, // Pendente
  RECEIVED: 1, // Recebido
  OVERDUE: 2, // Atrasado
  CANCELED: 3, // Cancelado
  SERASA: 4, // Serasa
  REGISTRY: 5, // Cartório
  PROTESTED: 6, // Protestado
  LEGAL: 7, // Jurídico
  APPROVED: 8, // Aprovado
  DENIED: 9, // Negado
  REVERSED: 10
});
