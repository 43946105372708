<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
         <div class="col-lg-12">
          <h6 class="h2 text-gray d-inline-block mb-0">{{ $route.name }}</h6>
          <nav
            aria-label="breadcrumb"
            class="d-none d-md-inline-block ml-md-4 text-gray"
          >
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i></a></li>
              <li class="breadcrumb-item"><a href="#">Cadastro</a></li>
              <li class="breadcrumb-item" aria-current="page">{{ $route.name }}</li>
            </ol>
          </nav>
        </div>
      </div>
<!-- pr-6 pl-6 pt-3 pb-3 -->
      <!-- Header Menus -->
      <div class="row mb-3">
        <div class="col-md-2 mb-2">
          <base-button block size="lg" type="success" @click="handleCreate()">
            <i class="fas fa-plus"></i> NOVO
          </base-button>
        </div>
        <div class="col-md-3 col-lg-2 col-xlg-2 ml-3 mr-3 mb-3 ml-0 p-0">
          <router-link
            :to="{
              path: '/commercial/contract',
              name: 'commercial.contract-proposal.index',
              }"
            >
            <base-button block size="lg" type="light">
              VOLTAR
            </base-button>
          </router-link>
        </div>
      </div>

    </base-header>
    <multi-filter ref="multi-filter" @fetch="init" :filter="filter" />
<div class="container-fluid">
      <div class="row card-wrapper">
        <div
          class="col-lg-4"
          v-for="itemReasonCancellation in $_reasonsCancellation"
          :key="itemReasonCancellation.id">
          <!-- Basic with action button -->
          <card :class="itemReasonCancellation.status ? 'card-border-top-success' : 'card-border-top-danger'">
            <!-- Card header -->
            <div class="row align-items-center mb-3">
              <div class="col-8">
                <!-- Title -->
                <h5 class="h3 mb-0">{{ itemReasonCancellation.reason }}</h5>
              </div>

              <div class="col-4 text-right">
                <base-dropdown menuOnRight>
                  <base-button
                    slot="title-container"
                    type="primary"
                    class="dropdown-toggle p-2 rounded m-0"
                  >
                    <i class="fas fa-cog"></i>
                  </base-button>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleUpdate(itemReasonCancellation)">
                    <i class="fas fa-edit text-warning"></i>
                    Editar
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleDelete(itemReasonCancellation.id)">
                    <i class="fas fa-times text-danger"></i>
                    Excluir
                  </a>
                </base-dropdown>
              </div>
            </div>
            <!-- Card body -->

            <collapse class="border rounded p-0 mt-3">
                  <collapse-item
                    class="header-gray"
                    back-ground="border-header-primary-bottom"
                  >
                  <h5 slot="title" class="mb-0 ">Observações</h5>
                      {{ itemReasonCancellation.observation }}
                  </collapse-item>
                </collapse>


          </card>
        </div>
      </div>
    </div>
    <PuzlEmptyData v-if="!$_reasonsCancellation.length && !loadingSkeleton"></PuzlEmptyData>
    <add-reason-cancellation></add-reason-cancellation>
    <loading-pagination :show="loading && !loadingSkeleton"/>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import PuzlEmptyData from "@/components/PuzlEmptyData";
import MultiFilter from '@/components/Utils/MultiFilter.vue';
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import AddReasonCancellation from './components/ReasonCancellation/AddReasonCancellation.vue';
export default {
  name: "ListReasonCancellation",
  mixins: [cursorPaginate],
  components: {
    PuzlEmptyData,
    MultiFilter,
    cursorPaginate,
    LoadingPagination,
    AddReasonCancellation,
  },
  data() {
    return {
      loadingSkeleton: false,
      filter: {},
    }
  },
  computed: {
    ...mapGetters({
        '$_reasonsCancellation': 'reasonCancellation/fetch',
    }),
  },
  methods: {
    init(filter = null){
      this.startCursor(filter)
      this.$Progress.start();
      this.$store.dispatch('reasonCancellation/fetchItemsPaginate', {
          filter: this.filter,
          next_page: this.paginate.nextUrl
      })
        .then(response => {
          this.resolveCursor(response)
          this.$Progress.finish();
        })
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
          this.resolveCursor()
          this.$Progress.finish();
        });
    },
    handleCreate(){
      EventBus.$emit("handleCreateReasonCancellation");
    },
    handleUpdate(itemUpdate){
      EventBus.$emit("handleUpdateReasonCancellation", itemUpdate);
    },
    handleDelete(id){
      EventBus.$emit("handleDeleteReasonCancellation", id);
    }
  },
  created(){

  },
  mounted() {
    this.filter.status = 1;
    this.init({})
    EventBus.$on("getReasonCancellation", () => {
      this.init();
    });
  }
};

</script>
<style></style>
