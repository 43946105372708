<template>
<div>
    <base-header class="bg-gray-content">
        <div class="row align-items-center py-4">
            <div class="col-lg-6 col-7">
                <h6 class="h2 text-gray d-inline-block mb-0">{{ $route.name }}</h6>
                <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4 text-gray">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i></a></li>
                        <li class="breadcrumb-item"><a href="#">Cadastro</a></li>
                        <li class="breadcrumb-item" aria-current="page">{{ $route.name }}</li>
                    </ol>
                </nav>
            </div>
        </div>
        <!-- Header Menus -->
        <div class="row">
            <div class="col-md-2 mb-3">
                <base-button @click.prevent="handleCreateTemperature" class="pb-3 pt-3 text-uppercase" block size="lg" type="success">
                  <i class="fas fa-plus"></i> novo
                </base-button>
            </div>
            <div class="col-md-2 mb-3">
                <base-button @click.prevent="handleCreateTemperatureSetting" class="pb-3 pt-3 text-uppercase" block size="lg" type="dark">
                    automatizações
                </base-button>
            </div>
            <div class="col-md-2 mb-3">
                <base-button  class="pb-3 pt-3 text-uppercase" block size="lg" type="primary">
                    relatório
                </base-button>
            </div>
          <div class="col-md-2 mb-3">
            <BackButton />
          </div>
        </div>

    </base-header>
    <list-temperatures v-bind:added="this.added"> </list-temperatures>
    <modal-create ref="ModalCreateTemperature"> </modal-create>
    <modal-create-setting @createdTemperatureSetting="handleCreateTemperatureList" ref="ModalCreateTemperatureSetting"> </modal-create-setting>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'
import ListTemperatures from './Shared/_List'
import ModalCreate from './Shared/_ModalCreate'
import ModalCreateSetting from './Shared/_ModalCreateSetting'
import BackButton from "@/components/Utils/BackButton";

export default {
    name: "IndexTemperature",
    components: {
      BackButton,
      ListTemperatures,
      ModalCreate,
      ModalCreateSetting,
    },
    data() {
        return {
            added: false,
        }
    },
    methods: {
        handleCreateTemperature() {
            this.$refs.ModalCreateTemperature.handleCreateModal()
        },
        handleCreateTemperatureSetting() {
            this.$refs.ModalCreateTemperatureSetting.handleCreateModal()
        },
        handleCreateTemperatureList() {
            this.added = !this.added
        },
    },
};
</script>

<style scoped>
@media screen and (min-width: 768px) {
    .col-md-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 18%;
        flex: 0 0 18%;
        max-width: 18%;
    }
}
</style>
