const keys = Object.freeze({
  IN_CASH: '947bf95b-849b-473c-a9c0-8d9fc04eff19',
  CREDIT_CARD: '947bf91c-abd0-43d4-a0b7-97a50a481cdd',
  DEBIT_CARD: '947bf924-efbd-4263-809a-b2105f6674e2',
  BANK_BILLET: '947bf938-e098-4d1e-965a-c33fc9aa05d5',
  CHEQUE: '947d75db-57ea-4df5-b33a-4408dc047653',
  PIX: '947bf913-2723-4de7-bc49-a4554841a790',
  BANK_TRANSFER: '947bf92f-4030-49b1-94ed-b0b1f842bbd0',
  COMPENSATION: '546a2e8a-64ad-46d5-852f-2a4b6a615285',
});

const getTitle = (value) => {
  switch (value) {
    case keys.IN_CASH: return 'Dinheiro';
    case keys.CREDIT_CARD: return 'Cartão de Crédito';
    case keys.DEBIT_CARD: return 'Cartão de Débito';
    case keys.BANK_BILLET: return 'Boleto';
    case keys.CHEQUE: return 'Cheque';
    case keys.PIX: return 'PIX';
    case keys.BANK_TRANSFER: return 'Transf. Bancária';
    case keys.COMPENSATION: return 'Compensação';
    default: return "";
  }
};

export default {
  keys,
  getTitle,
};
