import axios from "axios";
import Vue from "vue";
/* Configurações iniciais */
const token = "eyJhbGciOiJIUzI1NiJ9.eyJhZXMtMTI4LWdjbSI6IndlYmp3dCJ9.-IFuYGc9x1jPWUaDjUe2lOM4ay_d8RHE9uVIcjmAI1U";
const accepted_domains = ["concrelagos"];
const current_domain = window.location.host.split(".")[0];
const totvs = axios.create({
  baseURL: `https://${current_domain}-totvs-ms.puzl.place/`,
  headers: {
    Authorization: `Bearer ${token}`,
    "X-localization": "pt_BR",
  },
});
export const hasTotvs = () => accepted_domains.includes(current_domain);
const notify_config = {
  timeout: 2000,
  icon: false,
  position: "rightBottom",
  showProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
};
const error = () => Vue.prototype.$snotify.error("Não foi possível efetuar a conexão com a Totvs", notify_config);
const success = () => Vue.prototype.$snotify.success("Comunicação com a Totvs realizada com sucesso", notify_config);

/* Relatório */
export const report = {
  index(data) {
    return hasTotvs() && totvs.get("report", {params : data});
  },
};

/* CPF/CNPJ - Cliente Fornecedor */
export const entity = {
  store(data) {
    return hasTotvs() &&
      totvs
        .post("configuration/entity", data)
        .then(() => success())
        .catch(() => error());
  },
  update(data) {
    hasTotvs() &&
      totvs
        .put(`configuration/entity/${data.id}`, data)
        .then(() => success())
        .catch(() => error());
  },
};

/* Fatura */
export const invoice = {
  store(data) {
    return hasTotvs() &&
      totvs
        .post("financial/invoice-integration-by-id", data)
        .then(() => success())
        .catch(() => error());
  },
  getIntegrationInfo(invoice_id) {
    return hasTotvs() &&
      totvs
        .get(`financial/invoice/info/${invoice_id}`)
        .then((response) => response.data)
        .catch(() => error());
  },
  generateBillet(invoice_id) {
    return hasTotvs() &&
      totvs
        .post(`financial/invoice-billet`, {invoice_id : invoice_id}, { headers: { 'Accept': 'application/json' }})
        .then((response) => response)
        .catch((error) => error);
  },
  updateConstrupay(invoice_id) {
    return hasTotvs() &&
      totvs
        .post("financial/invoice/construpay", {invoice_id : invoice_id})
        .then(() => success())
        .catch(() => error());
  },
};

/* Nota de remessa */
export const xml_travels = {
  store(data) {
    return hasTotvs() &&
      totvs
        .post("financial/xml", data)
        .then(() => success())
        .catch(() => error());
  },
};

/* Equipamento - Veículo */
export const equipment = {
  store(data) {
    hasTotvs() &&
      totvs
        .post("equipment/equipment", data)
        .then(() => success())
        .catch(() => error());
  },
  update(data) {
    hasTotvs() &&
      totvs
        .put(`equipment/equipment/${data.id}`, data)
        .then(() => success())
        .catch(() => error());
  },
};

/* Vendedores */
export const seller = {
  store(data) {
    hasTotvs() &&
      totvs
        .post("configuration/seller", data)
        .then(() => success())
        .catch(() => error());
  },
  update(data) {
    hasTotvs() &&
      totvs
        .put(`configuration/seller/${data.id}`, data)
        .then(() => success())
        .catch(() => error());
  },
};

/* Antecipação */
export const entity_bill_receive = {
  store(data) {
    return hasTotvs() &&
      totvs
        .post("financial/entity-bill-receive", data)
        .then(() => success())
        .catch(() => error());
  },
  getIntegrationInfo(entity_bill_receive_id) {
    return hasTotvs() &&
      totvs
        .get(`financial/entity-bill-receive/info/${entity_bill_receive_id}`)
        .then((response) => response.data)
        .catch(() => error());
  },
};

/* Obras */
export const construction = {
  store(data) {
    hasTotvs() &&
      totvs
        .post("commercial/construction", data)
        .then(() => success())
        .catch(() => error());
  },
  update(data) {
    hasTotvs() &&
      totvs
        .put(`commercial/construction/${data.id}`, data)
        .then(() => success())
        .catch(() => error());
  },
  destroy(id) {
    hasTotvs() &&
      totvs
        .delete(`commercial/construction/${id}`)
        .then(() => success())
        .catch(() => error());
  },
};
