<template>
  <div class="container-fluid">
    <div style="border-radius: 0 !important" class="card">
      <div class="card-body">
        <div class="col-md-12 p-0 table-responsive">
          <table class="table table-sm table-bordered">
            <thead>
            <tr class="fixed">
              <th class="text-center">Viagem</th>
              <th class="text-center">Volume</th>
              <th class="text-center">Acumulado</th>
              <th class="text-center">Previsto</th>
              <th class="text-center">Realizado</th>
              <th class="text-center">Documentos</th>
              <th class="text-center">Equipamento</th>
              <th class="text-center">Motorista</th>
              <th class="text-center">Ícones</th>
              <th class="text-center">Ações</th>
            </tr>
            </thead>
            <tbody>
            <template v-if="loadingSkeleton">
              <tr v-for="(item, skeletonIndex) in 10" :key="skeletonIndex">
                <th colspan="11">
                  <skeleton-puzl type="button"/>
                </th>
              </tr>
            </template>
            <template v-else>
              <tr v-for="(item, index) in $_items" :key="index">
                <th class="text-center">
                  <img
                    role="button"
                    :disabled="loadingSchedule || loadingSkeleton"
                    @click.prevent="$emit('handleTravelCycle', item.uuid, $_schedule.address_construction)"
                    width="26px"
                    :src="'/img/icons/icons8/ios/clock-' + getStatus(item.status) + '.png'"
                  />
                </th>
                <th
                  role="button"
                  @click.prevent="status[item.status] === 'released' && $emit('handleVolumeEdit', item)"
                  class="text-left"
                >
                  {{ item.volume }} m³
                </th>
                <th class="text-left">{{ item.accumulated }} m³</th>
                <th class="text-left">{{ item.load_truck_time.slice(-5) }}</th>
                <th class="text-left">
                  <div class="row m-0 d-flex align-items-center">
                    <div
                      style="font-weight: 500 !important; font-size: 0.7rem !important"
                      class="col-6 p-0"
                    >
                      {{ item.finished_at ? item.finished_at.slice(11, -3) : 'N/A' }}
                    </div>
                    <div class="col-6 p-0 text-right">
                      <img
                        @click.prevent="$router.push('/operational/schedule/charging/' + item.uuid)"
                        role="button"
                        class="ml-auto"
                        width="32"
                        src="/img/icons/icons8/ios/concrete-mixer-dark.png"
                      />
                    </div>
                  </div>
                </th>
                <th :class="status_color[item.status]" class="text-left" v-if="!item.xml && !item.number">
                  {{ getStatusLabel(item.status) }}
                </th>
                <th v-else class="text-center">
                  <!-- O.S -->
                  <a role="button" @click.prevent="downloadProof(item)" v-if="item.number" class="p-0" target="_blank">
                    <img width="35px" src="/img/icons/os.png"/>
                    <span class="small numeric"> nº {{ item.number }}</span>
                  </a>
                  <!-- Pdf -->
                  <a
                    v-if="item.xml && item.xml.pdf_file_path"
                    @click.prevent="downloadDanfe(item.uuid, item.xml)"
                    class="ml-3 p-0"
                  >
                    <img width="21px" src="/img/nfe.png"/>
                    <span class="small numeric"> nº {{ item.xml.number }}</span>
                  </a>
                </th>
                <th class="text-left">{{
                    item.equipment ? `${item.equipment.code} (${item.equipment.plate})` : "N/A"
                  }}
                </th>
                <th class="text-left">{{ item.driver || "N/A" }}</th>
                <th class="text-center">
                  <div>
                    <el-popover trigger="hover" placement="right" class="p-0 pr-0">
                      {{ item.send_plant.name }}
                      <base-button outline slot="reference" size="sm" type="secundary"
                                   class="p-0 m-0 shadow-none text-indigo col-2">
                        <img src="/img/icons/plant.png" width="25px" height="25px"/>
                      </base-button>
                    </el-popover>
                    <base-button
                      :style="(!item.isset_manual_dosage && 'opacity: 25%') || (item.concrete_left_over.length && 'opacity: 25%')"
                      @click.prevent="handleWaterControl(item.uuid, index)"
                      outline
                      slot="reference"
                      size="sm"
                      type="secundary"
                      class="p-0 m-0 shadow-none text-indigo col-2"
                    >
                      <i style="font-size: 2.2em" class="fa fa-tint text-info m-0"></i>
                      <span
                        v-show="!item.water_control || emptyWaterControl(item.water_control)"
                        style="position: absolute; margin-top: 6px; left: 10px"
                        class="badge badge-md badge-circle badge-floating badge-warning border-white"
                      >
                          <i class="fas fa-exclamation"/>
                        </span>
                    </base-button>
                    <base-button
                      :style="!item.isset_manual_dosage && 'opacity: 25%'"
                      outline
                      slot="reference"
                      size="sm"
                      type="secundary"
                      class="p-0 m-0 shadow-none text-indigo col-2"
                      @click.prevent="handleFreshStateTest(item.uuid, index)"
                    >
                      <img src="/img/icons/icons8/ios/vlc.png" width="25px" height="25px"/>
                      <span
                        v-show="item.has_empty_fresh_test"
                        style="position: relative; left: -18px; top: -6px"
                        class="badge badge-md badge-circle badge-floating badge-warning border-white"
                      >
                          <i class="fas fa-exclamation"/>
                        </span>
                    </base-button>
                    <base-button
                      @click.prevent="showAdjustments(item)"
                      outline
                      slot="reference"
                      size="sm"
                      type="secundary"
                      class="p-0 m-0 shadow-none text-indigo col-2"
                    >
                      <img src="/img/icons/banks/0.png" width="25px" height="25px"/>
                    </base-button>
                    <a
                      v-if="item.schedule_travel_cycle_attachment_id"
                      @click.prevent="handleModalScheduleTravelCycleAttchment(item.cycle.id)"
                      href="#"
                      class="col-2 p-0 ml-2"
                    >
                      <img src="/img/icons/attach-green.png" height="25px" />
                    </a>
                  </div>
                </th>
                <th class="text-center">
                  <base-dropdown menuOnRight>
                    <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                      <i class="fas fa-cog"></i>
                    </base-button>
                    <template v-if="item.status !== 7">
                      <router-link
                        class="dropdown-item"
                        :to="{
                            path: '/operational/schedule/charging',
                            name: 'operational.schedule.charging.index',
                            params: { travel_uuid: item.uuid },
                          }"
                      >
                        <i class="fa fa-balance-scale text-primary main-icon-menu"></i>
                        <a href="#" class="text-default">Carregamento</a>
                      </router-link>
                      <a
                        class="dropdown-item"
                        href="javascript:void(0)"
                        v-if="[1, 6].includes(item.status)"
                        @click.prevent="$emit('handleConcreteLeftover', item)"
                      >
                        <i class="fa fa-recycle text-success main-icon-menu"></i>
                        <span class="text-right">Sobra de Concreto</span>
                      </a>
                      <a
                        class="dropdown-item"
                        href="javascript:void(0)"
                        v-if="item.status === 1"
                        @click.prevent="$emit('handleCancelTravel', item)"
                      >
                        <i class="far fa-times-circle text-danger main-icon-menu"></i>
                        <span class="text-right">Cancelar</span>
                      </a>
                      <a
                        class="dropdown-item"
                        href="javascript:void(0)"
                        v-if="item.status === 4"
                        @click.prevent="handleReleaseTravel(item)"
                      >
                        <i class="fas fa-shipping-fast main-icon-menu"></i>
                        <span class="text-right">Liberar viagem</span>
                      </a>
                      <a
                        v-if="item.xml && item.xml.pdf_file_path"
                        class="dropdown-item"
                        target="_blank"
                        download
                        :href="base_url_ms + 'download-s3?url=' + item.xml.pdf_file_path"
                      >
                        <img class="" width="25px" src="https://img.icons8.com/color/480/nota-fiscal-eletronica.png"/>
                        <span class="text-right ml-1">Danfe</span>
                      </a>
                      <a
                        v-if="item.xml && item.xml.xml_file_path"
                        class="dropdown-item"
                        target="_blank"
                        download
                        :href="base_url_ms + 'download-s3?url=' + item.xml.xml_file_path"
                      >
                        <img class="" width="25px" src="/img/icons/nfe_xml.png"/>
                        <span class="text-right ml-1">XML</span>
                      </a>
                    </template>
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      v-if="[0, 7].includes(item.status)"
                      @click.prevent="setToUnrealized(item.id)"
                    >
                      <i
                        class="main-icon-menu"
                        :class="item.status === 0 ? 'fas fa-ban text-danger' : 'fas fa-undo-alt text-success '"
                      ></i>
                      <span class="text-right">{{ item.status === 0 ? "Não realizar" : "Retomar" }}</span>
                    </a>
                    <template v-if="!item.was_billed && $_operational_permission.can_clear_schedule_travel_mcc && item.has_mcc">
                    <a
                      @click.prevent="handleClearMcc(item.uuid)"
                      v-if="(item.has_mcc &&
                        item.automation_status !== 2 &&
                        ![5, 1, 6].includes(item.status) && ![0, 15].includes(item.schedule_status)) || $hasPrivilege(1) || item.status === 5 && ![2, 0].includes(item.automation_status)"
                      class="dropdown-item"

                      href="javascript:void(0)"
                    >
                      <img class="mr-2" width="19" src="/img/icons/icons8/ios/broom_danger.png"
                           alt="broom"/> LIMPAR
                    </a>
                    </template>
                  </base-dropdown>
                </th>
              </tr>
            </template>
            </tbody>
          </table>
          <PuzlEmptyData v-if="!loadingSkeleton && !$_items.length"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {base_url_ms} from "@/plugins";
import SkeletonPuzl from "@/components/SkeletonPuzl";

export default {
  name: "Table",
  components: {SkeletonPuzl},
  props: {
    loadingSkeleton: Boolean,
    loadingSchedule: Boolean,
    downloadProof: Function,
    handleReleaseTravel: Function,
    setToUnrealized: Function,
    copy: Function,
    handleWaterControl: Function,
    emptyWaterControl: Function,
    showAdjustments: Function,
    handleFreshStateTest: Function,
    handleModalScheduleTravelCycleAttchment: Function,
  },
  computed: {
    ...mapGetters({
      $_operational_permission: "operationalPermission/show",
      $_items: "travels/fetch",
      $_schedule: "schedule/show",
    }),
  },
  data() {
    return {
      base_url_ms,
      status: {
        0: "released",
        1: "finished",
        2: "loading",
        4: "waiting_for_release",
        5: "in_progress",
        6: "canceled",
        7: "unrealized",
        8: "has_pendency",
        9: "seller_request",
      },
      status_color: {
        0: "text-success-light",
        1: "text-primary",
        2: "text-indigo",
        3: "text-indigo",
        4: "text-warning",
        5: "text-info",
        6: "text-danger",
        7: "text-danger",
        8: "text-warning",
      },
    };
  },
  methods: {
    handleClearMcc(uuid) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          let loader = this.$loading.show()
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          this.$Progress.start()
          this.$store
            .dispatch("travels/deleteClearMcc", {uuid: uuid, force: false})
            .then(response => {
              this.$notify({type: 'success', message: response.message})
            })
            .catch(error => {
              this.$notify({type: 'danger', message: error.data.message})
            }).finally(() => {
            this.$Progress.finish();
            loader.hide()
            this.$parent.init()
          });
        }
      }).catch()
    },
    async downloadDanfe(schedule_travel_uuid, xml) {
      if (xml.status !== 1) {
        return;
      }
      let params = {
        uuid: schedule_travel_uuid,
        type: "danfe",
      };
      let loader = this.$loading.show();
      this.$store
        .dispatch("travels/download", params)
        .then(async (response) => {
          let blob = new Blob([response], {type: "application/pdf"});
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", `${xml.key}.pdf`);
          await link.click()
          this.$snotify.success('Download iniciado com sucesso!', {
            timeout: 1000,
            icon: false,
            position: "centerBottom",
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false
          });
          loader.hide();
        });
    },
    getStatus(status) {
      switch (this.status[status]) {
        case "released":
          return "success";
        case "finished":
        case "in_progress":
          return "primary";
        case "canceled":
          return "danger";
        default:
          return "light";
      }
    },
    getStatusLabel(status) {
      switch (this.status[status]) {
        case "released":
          return "Liberado";
        case "finished":
          return "Concluído";
        case "loading":
          return "Carregando";
        case "waiting_for_release":
          return "Aguardando liberação";
        case "in_progress":
          return "Em andamento";
        case "canceled":
          return "Cancelado";
        case "unrealized":
          return "Não realizado";
        case "has_pendency":
          return "Pendente";
        case "seller_request":
          return "Solicitação de vendedor";
      }
    },
  },
};
</script>

<style scoped>
td,
th {
  vertical-align: middle !important;
}

.text-success-light {
  color: #68b879 !important;
}
</style>
