<template>
  <modal size="xl" :show.sync="modal" class="pb-5 modal-custom">
    <template slot="header">
      <h5 class="modal-title p-0 m-0">
        <img src="/img/icons/icons8/ios/menu-squared-2-white.png" height="25" class="mr-2" />
        Lista de itens
      </h5>
    </template>
    <div class="p-3">
      <div class="d-block d-md-flex align-items-center justify-content-between">
        <div>
          <!-- INFORMAÇOES -->
          <div class="d-flex align-items-center">
            <img class="mr-1" width="25" height="25" src="/img/icons/info-squared-yellow.png" alt="info-squared" />
            <span style="font-size: 16px; color: #2B2D32; font-weight: 500" class="ml-2 mr-3">Informação</span>
            <div style="border:#E8E8E8 1px solid; width: 100%"></div>
          </div>
          <div class="mt-3 font-weight-600">
            <div style="font-size: 15px !important; color: #2B2D32">{{ entityBudget.entity_name }}</div>
            <div style="font-size: 15px !important; color: #2B2D32">{{ entityBudget.entity_document }}</div>
          </div>
        </div>
        <div class="d-flex mt-2 mt-md-0">
          <div class="header-cards mr-3" style="background-color: #fcf3f3;">
            <img class="mr-1" width="25" height="25" src="/img/icons/thai_baht_red.png" alt="info-squared" />
            <span style="font-size: 16px; color: #2B2D32; font-weight: 500" class="mt-2">VALOR BRUTO</span>
            <span style="font-size: 16px; color: #2B2D32; font-weight: 500">{{ grossValue | currency()
              }}</span>
          </div>
          <div class="header-cards mr-3" style="background-color: #f2f7f3;">
            <img class="mr-1" width="25" height="25" src="/img/icons/albanian_lek_green.png" alt="info-squared" />
            <span style="font-size: 16px; color: #2B2D32; font-weight: 500" class="mt-2">VALOR LÍQUIDO</span>
            <span style="font-size: 16px; color: #2B2D32; font-weight: 500">{{ netValue | currency() }}</span>
          </div>
          <div class="header-cards mr-3" style="background-color: #f2f4f9;">
            <img class="mr-1" width="25" height="25" src="/img/icons/icons8/ios/receipt-dollar_primary.png"
              alt="info-squared" />
            <span style="font-size: 16px; color: #2B2D32; font-weight: 500" class="mt-2">VALOR Final</span>
            <span style="font-size: 16px; color: #2B2D32; font-weight: 500">{{ finalValue | currency()
              }}</span>
          </div>
        </div>
      </div>
      <div class="my-3" style="border:#E8E8E8 1px solid; width: 100%"></div>
      <div class="d-block d-md-flex justify-content-between">
        <div>
          <!-- ITEMS -->
          <div>
            <div class="container-blocks mr-md-2" style="overflow: scroll; max-height: 500px">
              <div class="d-flex align-items-center">
                <img width="25" src="/img/icons/big-parcel-primary.png" class="mr-2" />
                <span style="font-size: 16px; color: #2B2D32; font-weight: 500" class="ml-2 mr-3">Itens</span>
                <div style="border:#E8E8E8 1px solid; width: 100%"></div>
              </div>

              <input :checked="priceWithThreeDecimalPlace" @change="handlePriceDecimalPlace" type="checkbox"
                class="mt-4" />
              <span class="text-dark"> Adotar preço com 3 casas decimais </span>

              <div class="d-flex align-items-center mt-3 mb-3 justify-content-between">
                <div>
                  <span style="font-size: 16px; color: #2B2D32; font-weight: 500" class="mr-3">Item</span>
                </div>
                <div class="text-nowrap">
                  <span class="header-table-items">QUANTIDADE</span>
                  <span class="header-table-items">PREÇO UNIT.</span>
                  <span class="header-table-items">PREÇO TOTAL</span>
                </div>
              </div>
              <div v-for="(item, index) in entityBudget.items" :key="index" class="d-flex justify-content-between mb-2">
                <div class="text-item-description">
                  {{ item.description }}
                </div>
                <div class="d-flex">
                  <!-- Quantidade -->
                  <div class="mr-3" style="width: 8rem !important;">
                    <div class="p-2 text-right"
                      style="background-color: #E8E8E8; border-radius: 5px; color: #606062; font-size: 11px;">
                      {{ item.quantity }} {{ item.input_unit }}
                    </div>
                  </div>
                  <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
                    <!-- Valor unitario -->
                    <div class="mr-3" style="width: 8rem !important;">
                      <base-input input-group-classes="input-group-sm">
                        <input v-money="money" v-model="entityBudget.items[index].unit_value" maxlength="13" type="text"
                          class="form-control form-control-sm" />
                        <template slot="prepend">
                          <small class="input-group-sm p-0 m-0"> R$ </small>
                        </template>
                      </base-input>
                    </div>
                  </validation-observer>
                  <!-- Valor bruto -->
                  <div class="mr-3" style="width: 8rem !important;">
                    <div class="p-2"
                      style="background-color: #E8E8E8; border-radius: 5px; color: #606062; font-size: 11px;">
                      {{ calculateTotalCost(entityBudget.items[index].unit_value, item.quantity) | currency() }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- OBSERVACOES -->
          <div>
            <div class="container-blocks mr-md-2 mt-2 mb-2 mb-md-0">
              <div class="d-flex align-items-center">
                <img width="25" src="/img/icons/icons8/ios/speech-bubble-with-dots--v1.png" class="mr-2" />
                <span style="font-size: 16px; color: #2B2D32; font-weight: 500" class="ml-2 mr-3">OBSERVAÇÃO</span>
                <div style="border:#E8E8E8 1px solid; width: 100%"></div>
              </div>
              <div class="mt-3">
                <base-input input-group-classes="input-group-sm">
                  <textarea type="text" rows="4" class="form-control form-control-sm" placeholder=" ...">
              </textarea>
                </base-input>
              </div>
            </div>
          </div>
        </div>
        <div class="container-blocks">
          <!-- TAXAS E DESCONTOS -->
          <div>
            <div class="d-flex align-items-center">
              <img height="30px" src="/img/icons/ledger.png" class="mr-2" />
              <span style="font-size: 16px; color: #2B2D32; font-weight: 500; text-wrap: nowrap;"
                class="ml-2 mr-3">TAXAS E
                DESCONTOS</span>
              <div style="border:#E8E8E8 1px solid; width: 100%"></div>
            </div>
            <div class="mt-3">
              <div class="d-flex justify-content-between mb-2">
                <div>
                  Desconto
                </div>
                <div>
                  <div style="width: 10rem !important;">
                    <base-input input-group-classes="input-group-sm">
                      <input v-money="money" v-model.lazy="entityBudget.discount" maxlength="13" type="text"
                        class="form-control form-control-sm" />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <div>
                  Ipi
                </div>
                <div>
                  <div style="width: 10rem !important;">
                    <base-input input-group-classes="input-group-sm">
                      <input v-money="money" v-model.lazy="entityBudget.ipi" maxlength="13" type="text"
                        class="form-control form-control-sm" />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <div>
                  Icms
                </div>
                <div>
                  <div style="width: 10rem !important;">
                    <base-input input-group-classes="input-group-sm">
                      <input v-money="money" v-model.lazy="entityBudget.icms" maxlength="13" type="text"
                        class="form-control form-control-sm" />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- TRANSPORTE E FRETE -->
          <div class="mt-3">
            <div class="d-flex align-items-center">
              <img height="30px" src="/img/icons/icons8/ios/in-transit_primary.png" class="mr-2" />
              <span style="font-size: 16px; color: #2B2D32; font-weight: 500; text-wrap: nowrap;"
                class="ml-2 mr-3">TRANSPORTE E FRETE</span>
              <div style="border:#E8E8E8 1px solid; width: 100%"></div>
            </div>
            <div class="mt-3">
              <div class="d-flex justify-content-between mb-2">
                <div>
                  Condição do frete
                </div>
                <div>
                  <div style="width: 10rem !important;">
                    <PuzlSelect v-model="entityBudget.shipping_condition" customKey="type"
                      :items="[{ type: 0, name: 'CIF' }, { type: 1, name: 'FOB' }]" class="input-info"
                      placeholder="Selecione" />
                  </div>
                </div>
              </div>
              <div v-if="entityBudget.shipping_condition !== 0" class="d-flex justify-content-between mb-2">
                <div>
                  Frente por conta do
                </div>
                <div>
                  <div style="width: 10rem !important;">
                    <PuzlSelect v-model="entityBudget.freight_modality_id" :items="freight_modalities"
                      class="input-info" placeholder="Selecione" />
                  </div>
                </div>
              </div>
              <div v-if="entityBudget.freight_modality_id !== 0 && entityBudget.shipping_condition !== 0"
                class="d-flex justify-content-between mb-2">
                <div>
                  Valor do frete
                </div>
                <div>
                  <div style="width: 10rem !important;">
                    <base-input input-group-classes="input-group-sm">
                      <input v-money="money" v-model.lazy="entityBudget.delivery_cost" maxlength="13" type="text"
                        class="form-control form-control-sm" />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                </div>
              </div>
              <div v-if="entityBudget.freight_modality_id !== 0 && entityBudget.shipping_condition !== 0"
                class="d-flex justify-content-between mb-2">
                <div>
                  Transportadora
                </div>
                <div>
                  <div style="width: 10rem !important;">
                    <base-input input-group-classes="input-group-sm">
                      <input v-model.lazy="entityBudget.delivery_company_name" maxlength="90" type="text"
                        class="form-control form-control-sm" />
                    </base-input>
                  </div>
                </div>
              </div>
              <div v-if="entityBudget.freight_modality_id !== 0 && entityBudget.shipping_condition !== 0"
                class="d-flex justify-content-between mb-2">
                <div>
                  cpf/cnpj
                </div>
                <div>
                  <div style="width: 10rem !important;">
                    <base-input input-group-classes="input-group-sm">
                      <input v-mask="['###.###.###-##', '##.###.###/####-##']"
                        v-model="entityBudget.delivery_company_document" type="text"
                        @blur="notifyInvalidDocument(entityBudget.delivery_company_document)"
                        class="form-control form-control-sm" :style="entityBudget.delivery_company_document
                          ? { borderColor: validityDocument(entityBudget.delivery_company_document) ? '#149E57' : '#DB4539' }
                          : {}" />
                    </base-input>
                  </div>
                </div>
              </div>
              <div v-if="entityBudget.freight_modality_id !== 0 && entityBudget.shipping_condition !== 0"
                class="d-flex justify-content-between mb-2">
                <div>
                  Contato
                </div>
                <div>
                  <div style="width: 10rem !important;">
                    <base-input input-group-classes="input-group-sm">
                      <input v-mask="['(##) ####-####', '(##) #####-####']" v-model.lazy="entityBudget.delivery_contact"
                        maxlength="30" type="text" class="form-control form-control-sm" />
                    </base-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- CONDIÇÃO DE PAGAMENTO -->
          <div class="mt-4">
            <div class="d-flex align-items-center">
              <img height="30px" src="/img/icons/icons8/ios/estimate_success.png" class="mr-2" />
              <span style="font-size: 16px; color: #2B2D32; font-weight: 500; text-wrap: nowrap;"
                class="ml-2 mr-3">CONDIÇÃO
                DE PAGAMENTO</span>
              <div style="border:#E8E8E8 1px solid; width: 100%"></div>
            </div>
            <div class="mt-3">
              <div class="d-flex justify-content-between mb-2">
                <div class="mr-3" style="text-wrap: nowrap;">
                  CONDIÇÃO DO PAGAMENTO
                </div>
                <div>
                  <div style="width: 10rem !important;">
                    <base-input input-group-classes="input-group-sm">
                      <input v-model.lazy="entityBudget.payment_method" type="text"
                        class="form-control form-control-sm" />
                    </base-input>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <div>
                  PRAZO DE ENTREGA
                </div>
                <div>
                  <div style="width: 10rem !important;">
                    <base-input input-group-classes="input-group-sm">
                      <InputDateTimePicker :disableMaxDate="false" :disableDate="new Date()"
                        v-model="entityBudget.delivery_deadline" class="input-info w-100" :mode="'date'" />
                    </base-input>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <div>
                  VALIDADE DA COTAÇÃO
                </div>
                <div>
                  <div style="width: 10rem !important;">
                    <base-input input-group-classes="input-group-sm">
                      <InputDateTimePicker :disableMaxDate="false" :disableDate="new Date()"
                        v-model="entityBudget.quotation_validity" class="input-info w-100" :mode="'date'" />
                    </base-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer pb-1 mt-2">
            <base-button type="secondary" @click="closeModal()">Cancelar</base-button>
            <base-button type="success" @click="save()">Salvar</base-button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>


<script>
import { VMoney } from "v-money";
import MultiFilter from "@/components/Utils/MultiFilter.vue";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import PuzlSelect from "@/components/PuzlSelect";
import { verifyDocument } from "../../../../../../../helpers/document";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import { moneyToFloat } from "@/helpers";

export default {
  name: "ModalDigitalSignatureList",
  components: { MultiFilter, PuzlEmptyData, PuzlSelect, InputDateTimePicker },
  data() {
    return {
      modal: false,
      cardOpenId: false,
      verifyDocument: verifyDocument,
      filter: {
        order_by: [
          {
            column: 'products_services.id',
          }
        ],
        type: null,
      },
      grossValue: 0,
      netValue: 0,
      finalValue: 0,
      freight_modalities: [
        { id: 1, name: "Por conta do emitente", code: "0" },
        { id: 2, name: "Por conta do destinatário/remetente", code: "1" },
        { id: 3, name: "Por conta de terceiros", code: "2" },
        { id: 4, name: "Transporte próprio por conta do remetente", code: "3" },
        { id: 5, name: "Transporte próprio por conta do destinatário", code: "4" },
        { id: 0, name: "Sem frete", code: "9" },
      ],
      entityBudget: [],
      totalInputs: [
        { description: 'VALOR BRUTO', type: 0 },
        { description: 'DESCONTO', type: 'discount' },
        { description: 'IPI', type: 'ipi' },
        { description: 'ICMS', type: 'icms' },
        { description: 'VALOR LÍQUIDO', type: 'net_value' },
      ],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      priceWithThreeDecimalPlace: false,
    }
  },
  directives: { money: VMoney },
  watch: {
    entityBudget: {
      handler() {
        this.setGrossValue();
        this.setNetValue();
        this.setFinalValue();
      },
      deep: true
    }
  },
  methods: {
    // Modal
    handleCreateModal(requestData) {
      let data = this.$helper.cloneObject(requestData);
      data.items.forEach(item => {
        if (this.priceWithThreeDecimalPlace) {
          item.unit_value = `${item.unit_value}000`;
        } else {
          item.unit_value = `${item.unit_value}00`
        }
      })
      this.modal = true;
      this.entityBudget = data;
    },
    closeModal() {
      this.modal = false
    },
    // Salvamento
    save() {
      let loader = this.$loading.show()
      this.entityBudget.gross_value = this.grossValue;
      this.entityBudget.net_value = this.netValue;
      this.entityBudget.final_value = this.finalValue;
      const data = this.formatValuesToSave(this.$helper.cloneObject(this.entityBudget));
      this.$store.dispatch('purchaseBudget/update', data)
        .then(() => {
          loader.hide();
          this.$notify({ type: 'success', message: 'Alteração salva com sucesso!' });
          this.$emit('fetchEntityBudgets');
          this.closeModal();
        })
        .catch(() => {
          loader.hide();
          this.$notify({ type: 'danger', message: 'Erro ao salvar alteração!' });
        });
    },
    formatValuesToSave(data) {
      data.delivery_cost = data.delivery_cost ? data.delivery_cost : "0.00";
      data.delivery_cost = Number(data.delivery_cost.replaceAll(".", "").replace(",", "."));
      data.icms = Number(data.icms.replaceAll(".", "").replace(",", "."));
      data.ipi = Number(data.ipi.replaceAll(".", "").replace(",", "."));
      data.discount = Number(data.discount.replaceAll(".", "").replace(",", "."));

      data.items.forEach(item => {
        if (item.unit_value) {
          const value = Number(item.unit_value.replaceAll(".", "").replace(",", "."))
          item.unit_value = value;
        }
      });

      return data
    },
    handlePriceDecimalPlace() {
      this.money.precision = this.money.precision === 2 ? 3 : 2;
      this.priceWithThreeDecimalPlace = !this.priceWithThreeDecimalPlace;
    },
    validityDocument(document) {
      if (document !== '' && verifyDocument(document)) {
        return true
      }
      return false;
    },
    notifyInvalidDocument(document) {
      if (this.validityDocument(document)) {
        return
      }
      this.$notify({
        type: 'danger',
        message: `O documento ${document} é inválido!`
      })
    },
    // Calculos de valor total, liquido, bruto e final
    calculateTotalCost(price, quantity) {
      const FloatPrice = price ? moneyToFloat(price.toString()) : 0;
      const NumQuantity = quantity ? parseFloat(quantity.toString().replace(',', '.')) : 0;
      return (FloatPrice * NumQuantity).toFixed(2);
    },
    setGrossValue() {
      let grossValue = 0;
      this.entityBudget?.items?.forEach(item => {
        if (item.unit_value && item.quantity) {
          const unitValue = moneyToFloat(item.unit_value.toString());
          const quantity = parseFloat(item.quantity.toString().replace(',', '.'));
          grossValue += unitValue * quantity;
        }
      });
      this.grossValue = grossValue.toFixed(2);
    },
    setNetValue() {
      const grossValue = this.formatValueToFloat(this.grossValue);
      const discount = this.formatValueToFloat(this.entityBudget.discount);
      const ipi = this.formatValueToFloat(this.entityBudget.ipi);
      const icms = this.formatValueToFloat(this.entityBudget.icms);
      const netValue = grossValue - discount + ipi + icms;

      this.netValue = netValue.toFixed(2);
    },
    setFinalValue() {
      const netValue = parseFloat(this.netValue);
      const deliveryCost = parseFloat(this.entityBudget.delivery_cost);
      const finalValue = netValue + deliveryCost;
      this.finalValue = finalValue.toFixed(2);
    },
    formatValueToFloat(value) {
      return value.toString().includes(",") ? moneyToFloat(value) : parseFloat(value);
    },
  }
}
</script>

<style scoped>
.header-table-items {
  font-size: 18px;
  color: #2B2D32;
  font-weight: 500;
  width: 9rem;
  display: inline-flex;
}

.header-table-items:first-of-type {
  margin-left: 13.85rem;
}

.header-cards {
  display: grid;
  box-shadow: 0px 5px 10px 0px #0000000D;
  border: 1px solid #E8E8E8;
  padding: 12px 32px 12px 12px;
  border-radius: 8px;
}

.container-blocks {
  box-shadow: 0px 5px 10px 0px #0000001A;
  border: 0.5px solid #E8E8E8;
  border-radius: 12px;
  padding: 24px 16px
}

.container-blocks .text-item-description {
  min-width: 200px;
  max-width: 200px;
  margin-right: 4rem;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.modal-custom>>>.modal-dialog {
  max-width: fit-content !important;
}
</style>
