import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    cache: {},
  },
  getters: {
    /**
     * Recupera um valor do cache por chave.
     * 
     * @param {Object} state
     * @returns {*}
     */
    get: (state) => (key) => {
      return state.cache[key];
    },
  },
  mutations: {
    /**
     * Define um valor no cache.
     * 
     * @param {Object} state - O estado do módulo de cache.
     * @param {Object} payload - O objeto de payload.
     * @param {string} payload.key - A chave para definir no cache.
     * @param {*} payload.value - O valor para definir no cache.
     */
    SET(state, { key, value }) {      
      Vue.set(state.cache, key, value);
    },
    /**
     * Limpa um valor do cache por chave
     * 
     * @param {Object} state - O estado do módulo de cache.
     * @param {string} key - A chave para limpar do cache.
     */
    CLEAR(state, key) {
      if (key) {
        Vue.delete(state.cache, key);
      }
    },
  },
  actions: {
    /**
     * Executa a mutação SET.
     * 
     * @param {Object} context - O contexto da ação do Vuex.
     * @param {Object} payload - O objeto de payload.
     * @param {string} payload.key - A chave para definir no cache.
     * @param {*} payload.value - O valor para definir no cache.
     */
    set({ commit }, { key, value }) {
      commit('SET', { key, value });
    },
    /**
     * Executa a mutação CLEAR.
     * 
     * @param {Object} context - O contexto da ação do Vuex.
     * @param {string} key - A chave para limpar do cache.
     */
    clear({ commit }, key) {
      commit('CLEAR', key);
    },
  },
};
