import * as types from "./mutation_types";
import { isNumber } from "../../../../helpers";
import { createAxios } from "@/plugins/axios";;
const endPoint = "/operational/schedule/resume/+microservice";
export default {
  namespaced: true,
  state: {
    items: [],
    scheduleResume: null,
  },
  getters: {
    fetch: (state) => state.items,
    statistics: (state) => state.statistics,
    scheduleResume: (state) => state.scheduleResume,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload;
    },
    [types.SET_COMPANY_PLANT_RESUME](state, payload) {
      if (isNumber(payload.parent_reference)) {
        let half = state.items.plants.splice(
          state.items.plants
            .map((i) => i.id)
            .indexOf(payload.parent_reference) + 1
        );
        state.items.plants = state.items.plants
          .concat(payload.data)
          .concat(half);
        return;
      }
      let half = state.items.plants.splice(
        state.items.plants
          .map((i) => i.uuid)
          .indexOf(payload.parent_reference) + 1
      );
      state.items.plants = state.items.plants.concat(payload.data).concat(half);
    },
    [types.CLEAR_RESUME](state) {
      state.items.plants = state.items.plants.filter(function (obj) {
        obj.opened = 0;
        return obj.type === 0;
      });
    },
    [types.CLEAR_RESUME_DETAILS](state) {
      state.items.plants = state.items.plants.filter(function (obj) {
        return obj.type !== 2;
      });
    },
    [types.SET_SCHEDULE_RESUME](state, payload) {
      state.scheduleResume = payload;
    },
  },
  actions: {
    fetchItems({ commit }, payload) {
      return createAxios()
        .get(`${endPoint}init`, { params: { filter: payload } })
        .then(({ data }) => {
          commit(types.SET, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getCompanyPlantResume({ commit }, payload) {
      commit(types.CLEAR_RESUME);
      return createAxios()
        .get(`${endPoint}company-plant/${payload.company_plant_uuid}`, {
          params: { filter: payload.filter },
        })
        .then(({ data }) => {
          commit(types.SET_COMPANY_PLANT_RESUME, {
            data: data.data,
            parent_reference: payload.company_plant_uuid,
          });
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    clearResume({ commit }) {
      commit(types.CLEAR_RESUME);
    },
    clearResumeDetails({ commit }) {
      commit(types.CLEAR_RESUME_DETAILS);
    },
    setResumeDetails({ commit }, payload) {
      commit(types.SET_COMPANY_PLANT_RESUME, payload);
    },
    getResumeExcelReport({ commit }, params) {
      return createAxios()
        .get("operational/schedule/resume/download-excel", {
          responseType: "blob",
          timeout: 60000,
          params: params,
        })
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getResumeFinishedTravelsExcelReport({ commit }, params) {
      return createAxios()
        .get(
          "/operational/schedule/resume/download-excel/resume-finished-travels-report",
          {
            responseType: "blob",
            timeout: 60000,
            params: params,
          }
        )
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getSumResumeFinishedTravels({ commit }, params) {
      return createAxios()
        .get(`/operational/schedule/resume/get-sum-resume-finished-travels`, {
          params: params,
        })
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getBySchedule({ commit }, id) {
      return createAxios()
        .get(`${endPoint}${id}`)
        .then(({ data }) => {
          commit(types.SET_SCHEDULE_RESUME, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
};
