import * as types from './mutation_types'
import { createAxios } from "@/plugins/axios";

const endPoint = '/financial/bank-billet'
export default {
  namespaced: true,
  state: {
    items: [],
    widgets: {},
  },
  getters: {
    fetch: state => state.items,
    fetchWidgets: state => state.widgets,
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
    [types.SET_WIDGETS](state, payload) {
      state.widgets = payload
    },
  },
  actions: {
    cancel({commit, dispatch}, id) {
      return createAxios().put(`${endPoint}/cancel/${id}`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getPdfFileUrl(_, bank_billet_batch_id) {
      return createAxios().get(`/financial/bank-billet-batch/get-pdf-file-url/${bank_billet_batch_id}`, {
        responseType: "blob",
        timeout: 30000,
      })
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    /**
     * Requisitar atualização de lote de boletos
     * Método utilizado quando lote de boleto é gerado erroneamente
     * @param {*} bank_billet_batch_id
     * @returns {Promise}
     */
     postBatchUpdate({}, bank_billet_batch_id){
      return createAxios().post(`/financial/bank-billet-batch/post-batch-update/${bank_billet_batch_id}`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchItemsPaginate({commit, state}, params) {
      return createAxios().patch(endPoint, params)
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchWidgets({commit, state}, params) {
      return createAxios().get(`${endPoint}/widgets`, {params: params},
        {params: params})
        .then(({data}) => {
          commit(types.SET_WIDGETS, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    update({}, payload) {
      return createAxios().put(`${endPoint}/${payload.id}`, payload)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
