<template>
  <div>
    <modal :show.sync="modal.open">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
            <div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 col-form-label form-control-label">
                  Status
                </label>
                <div class="col-md-7 pt-2">
                  <base-switch v-model="additionalClassification.status" type="success" offText="inativo" onText="ativo" class="success"></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Descrição Técnica
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="additionalClassification.technical_description"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Descrição Comercial
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="additionalClassification.commercial_description"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pb-1 col-form-label form-control-label">
                  Subtipo
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <puzl-select
                        :clearable="false"
                        v-model="additionalClassification.subtype_ids"
                        :items="subtypes"
                        label="technical_description"
                        :multiple="true" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">
                Cancelar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingSave">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import PuzlSelect from "@/components/PuzlSelect"

export default {
  name: "Edit",
  components: { PuzlSelect },
  data () {
    return {
      modal: {
        title: 'Classificação Adicional',
        open: false,
      },
      additionalClassification: {
        uuid: '',
        status: true,
        technical_description: null,
        commercial_description: null,
        subtype_ids: [],
      },
      loadingSave: false,
    }
  },
  computed: {
    ...mapGetters({
      'subtypes': 'mixSubtype/fetch',
    }),
  },
  methods: {
    closeModal () {
      this.modal.open = false
    },
    handleEditModal (uuid) {
      let loader = this.$loading.show()
      this.$store.dispatch('mixAdditionalClassifications/show', uuid)
        .then( (response) => {
          this.additionalClassification = {
            uuid: response.data.uuid || null,
            status: response.data.status || false,
            commercial_description: response.data.commercial_description || null ,
            technical_description: response.data.technical_description || null,
            subtype_ids: response.data.subtypes.map((item) => {return item.id}) || [],
          }
          this.modal.open = true
          loader.hide()
        }).catch((error) => {
          if(error.response.status===422){
            let errors = Object.values(error.response.data.errors)
            errors = errors.flat().toString()
            this.$notify({ type: 'danger', message: errors })
          }
          loader.hide()
          this.loadingSave = false
      })
    },
    save () {
      this.$Progress.start()
      this.loadingSave = true
      this.$store.dispatch('mixAdditionalClassifications/update', this.additionalClassification)
        .then(response => {
          this.loadingSave = false
          this.modal.open = false
          this.$Progress.finish()
          this.$notify({ type: response.error_type, message: response.message })
        })
        .catch(error =>{
          if(error.response.status===422){
            let errors = Object.values(error.response.data.errors)
            errors = errors.flat().toString().split(',').join("<br />")
            this.$notify({ type: 'danger', message: errors })
          }
          this.$Progress.finish()
          this.loadingSave = false
        })
    },
  },
  mounted () {
    this.$refs.formValidator.validate();
    this.$store.dispatch('mixSubtype/fetchItems')
  },
  created () {
  }
}
</script>

<style scoped>

</style>
