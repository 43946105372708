<template>
  <div>
    <List/>
  </div>
</template>
<script>

import BackButton from "@/components/Utils/BackButtonV2.vue";
import List from './Shared/_List.vue'

export default {
  components: {
    BackButton,
    List
  }
}
</script>
<style>

</style>
