import { PagerType, initPagerType } from "../../../../shared/types";

/**
 * Type para item da listagem
 *
 * @typedef {Object} StockLocationListType
 * @property {number} id
 * @property {string} name
 * @property {boolean} status
 * @property {string} observation
 * @property {string} created_at
 * @property {?string} updated_at
 * @property {number} created_by_user_id
 * @property {?number} updated_by_user_id
 * @property {string} created_by_user_name
 * @property {string} updated_by_user_name
 */
export const StockLocationListType = {};

/**
 * Type para retorno paginado da listagem
 *
 * @typedef {( PagerType & { items: Array<StockLocationListType> } )} StockLocationListPagerType
 */
export const StockLocationListPagerType = {};

/**
 * Inicializar StockLocationListPagerType
 * 
 * @returns {StockLocationListPagerType}
 */
export const initStockLocationListPagerType = () => {
  return {
    ...initPagerType(),
    items: [],
  }
};

