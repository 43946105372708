<template>
<div>
    <modal size="lg" :show.sync="modal">
        <template slot="header">
            <h5 class="modal-title pl-2">{{ title }}</h5>
        </template>
        <template v-if="adjustments">
            <div class="mb-4 ml-2">
                <base-button size="sm" @click.prevent="type = 2" :class="type === 2 && 'active'" outline type="primary">
                    Status
                </base-button>
                <base-button size="sm" @click.prevent="type = 1" :class="type === 1 && 'active'" outline type="primary">
                    Volume/Viagens
                </base-button>
                <base-button size="sm" @click.prevent="type = 3" :class="type === 3 && 'active'" outline type="primary">
                    Serviços
                </base-button>
            </div>
            <div v-show="type === 1">
                <div class="row">
                    <div class="col-12">
                        <div class=" table-responsive custom-table-schedule-vol">
                            <table class="table mb-0">
                                <thead>
                                    <tr class="thead-light">
                                        <th colspan="1" rowspan="1" class="el-table_1_column_1 is-leaf is-sortable">
                                            <div class="cell">
                                                Centrais<span class="caret-wrapper"><i class="sort-caret ascending"></i><i class="sort-caret descending"></i></span>
                                            </div>
                                        </th>
                                        <th colspan="1" rowspan="1" class="el-table_1_column_1 is-leaf is-sortable">
                                            <div class="cell">
                                                Volumes/Viagens<span class="caret-wrapper"><i class="sort-caret ascending"></i><i class="sort-caret descending"></i></span>
                                            </div>
                                        </th>
                                        <th colspan="1" rowspan="1" class="el-table_1_column_1 is-leaf is-sortable">
                                            <div class="cell">
                                                Usuário<span class="caret-wrapper"><i class="sort-caret ascending"></i><i class="sort-caret descending"></i></span>
                                            </div>
                                        </th>
                                        <th colspan="1" rowspan="1" class="el-table_1_column_1 is-leaf is-sortable">
                                            <div class="cell">
                                                Data de alteração<span class="caret-wrapper"><i class="sort-caret ascending"></i><i class="sort-caret descending"></i></span>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(volumeTravel, indexVolumeTravel) in volumeTravels" :key="indexVolumeTravel">

                                        <th>
                                            <div class="border-left border-primary border-3 p-2">
                                                <div v-for="(plant, indexPlant) in volumeTravel.title" :key="indexPlant">
                                                    {{plant}}
                                                </div>
                                            </div>
                                        </th>

                                        <th>
                                            <div v-for="(vol, indexVol) in volumeTravel.description" :key="indexVol">
                                                {{vol}}
                                            </div>
                                        </th>
                                        <th>{{volumeTravel.user}}</th>
                                        <th>{{volumeTravel.created_at}}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="type === 2">
                <div class="row">
                    <div class="col-12">
                        <div class=" table-responsive custom-table-schedule">
                            <table class="table mb-0">
                                <thead>
                                    <tr class="thead-light">
                                        <th colspan="1" rowspan="1" class="el-table_1_column_1     is-leaf is-sortable">
                                            <div class="cell">
                                                Status<span class="caret-wrapper"><i class="sort-caret ascending"></i><i class="sort-caret descending"></i></span>
                                            </div>
                                        </th>
                                        <th colspan="1" rowspan="1" class="el-table_1_column_1     is-leaf is-sortable">
                                            <div class="cell">
                                                Usuário<span class="caret-wrapper"><i class="sort-caret ascending"></i><i class="sort-caret descending"></i></span>
                                            </div>
                                        </th>
                                        <th colspan="1" rowspan="1" class="el-table_1_column_1     is-leaf is-sortable">
                                            <div class="cell">
                                                Data de alteração<span class="caret-wrapper"><i class="sort-caret ascending"></i><i class="sort-caret descending"></i></span>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(status, indexStatus) in statuses" :key="indexStatus">
                                        <th>
                                            <div v-if="status.status !== 16" :class="'border-left border-'+getColor(status.status) +' border-3 p-2'">
                                                <b>{{status.title}}</b>
                                                <br>
                                                {{status.description}}
                                            </div>
                                            <div v-if="status.status === 16" :class="'border-left border-'+getColor(status.status) +' border-3 p-2'">
                                                <b>{{status.title}}</b>
                                                <br>
                                                <div class="mt-1" v-if="status.contract_proposal_formulation">
                                                    <div class="row" >
                                                        <div class="col-md-12">
                                                            <h5 class="mb-0">
                                                               {{ status.contract_proposal_formulation.title }}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div class="mt-1 mb-0">
                                                        <h5 class="small mb-0">
                                                            {{ status.contract_proposal_formulation.body }}
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div class="mt-2 " v-if="status.contract_proposal_formulation.puzl_additions && status.contract_proposal_formulation.puzl_additions.length">
                                                    <div v-for="(puzl_addition, index) in status.contract_proposal_formulation.puzl_additions" :key="puzl_addition.id">
                                                        <h5 class="text-indigo mb-0">
                                                            {{ puzl_addition.name }}
                                                            ({{ puzl_addition.volume }} kg/m<sup>3</sup>)
                                                            <span class="text-indigo" v-if="index !=status.contract_proposal_formulation.puzl_additions.length -1">+</span>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </th>
                                        <th>{{status.user}}</th>
                                        <th>{{status.created_at}}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </modal>
</div>
</template>

<script>
import {
    mapGetters
} from "vuex";
import {
    period_types
} from "@/views/Modules/Technology/Mix/helpers/mixe";

export default {
    components: {},
    name: "ModalScheduleHistory",
    data() {
        return {
            format_mixe: period_types,
            modal: false,
            title: "Histórico",
            type: 0,
            technical_parameter: null,
            statuses: [],
            volumeTravels: [],
        };
    },
    computed: {
        ...mapGetters({
            adjustments: "travels/adjustment"
        })
    },
    methods: {
        openModal(uuid) {
            this.$Progress.start();
            this.$store.dispatch("schedule/getHistories", uuid).then(response => {
                this.$Progress.finish();
                this.$notify({
                    type: response.error_type,
                    message: response.message
                });
                this.statuses = response.data.filter(
                    item => item.type === 2
                );
                this.volumeTravels = response.data.filter(
                    item => item.type === 1
                );
                this.type = 2;
                this.modal = true;
            });

        },
        verifyNewLength(length) {
            return 2 - length;
        },
        close: function () {
            this.modal = false;
        },
        getColor(status) {
            let color = "";
            switch (status) {
                case 0:
                case 1:
                    color = "danger";
                    break;
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                    color = "warning";
                    break;
                case 8:
                    color = "warning-strength";
                    break;
                case 10:
                    color = "default";
                    break;
                case 11:
                case 15:
                    color = "primary";
                    break;
                case 12:
                case 13:
                    color = "success-light";
                    break;
                case 16:
                    color = 'indigo';
                    break;
                case 17:
                    color = 'light';
                    break;
            }
            return color;
        }
    }
};
</script>

<style scoped>
.custom-table-schedule th {
    width: 33%;
    vertical-align: middle !important;
}

.custom-table-schedule-vol th {
    width: 25%;
    vertical-align: middle !important;
}

.border-warning-strength {
    border-color: #68b879 !important;
}

.border-success-light {
    border-color: #8edb9f !important;
}

.border-success-default {
    border-color: #172b4d !important;
}
</style>
