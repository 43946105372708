import * as types from './mutation_types'
import { createAxios } from "@/plugins/axios";
import {destroy, update} from '@/store/baseStore'
const endPoint = 'operational/schedule/schedule-travel/left-over/+microservice'
export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    fetch: state => state.items,
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
  },
  actions: {
    create({commit, state}, params) {
      return createAxios().post(`${endPoint}`, params)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    autorize({commit, state}, params) {
      return createAxios().post(`${endPoint}autorize`, params)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    update({commit, state}, params) {
      return createAxios().put(`${endPoint}${params.uuid}`, params)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    show({commit, dispatch}, uuid) {
      return createAxios().get(endPoint+uuid)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    destroy({commit, state}, uuid) {
      return createAxios().delete(`${endPoint}${uuid}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    get({commit, state}, params) {
      return createAxios().get(`${endPoint}without-use`, {params: params})
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getValidLeftOver({commit, state}, params) {
      return createAxios().get(`${endPoint}valid/${params.key}`, {params: params})
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
